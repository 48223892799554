import CheckIcon from "@mui/icons-material/Check";
import { Box, TextField, Typography } from "@mui/material";
import { PersonnelsField } from "components/core/personnel-field/PersonnelsField";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import { authSection } from "utils/constants";
import { authSectionStyles } from "./AuthSectionStyles";

const SuccessAuthSection = ({
  personalDetailForm,
  valuePersonnelAuth,
  disablePersonnel = false
}) => {
  const { t } = useTranslation();
  const { authInfo } = useSelector(selectAuth);

  return (
    <>
      <Typography variant="h5">{t("common.personnelAuth")}</Typography>
      {valuePersonnelAuth === authSection?.personnelAuth && (
        <Box sx={authSectionStyles.personnelAuthStyles.boxStyle}>
          <Box sx={authSectionStyles.commonStyles.boxWidth}>
            <PersonnelsField
              label={t("common.personnel")}
              customerId={personalDetailForm?.values?.customer?.value}
              form={personalDetailForm?.values?.customer ? false : true}
              selectedPersonnel={personalDetailForm?.values?.personnel}
              disablePersonnel={disablePersonnel}
            />
          </Box>
          <Box>
            <Typography
              color="success"
              variant="subtitle1"
              sx={authSectionStyles.personnelAuthStyles.successAuth}
            >
              <CheckIcon />
              {t("common.authenticated")}
            </Typography>
          </Box>
        </Box>
      )}

      {valuePersonnelAuth === authSection?.bypass && (
        <Box sx={authSectionStyles.personnelAuthStyles.boxStyle}>
          <Box>
            <TextField
              label={t("common.bypassedBy")}
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              value={authInfo?.displayName}
              InputLabelProps={authSectionStyles.commonStyles.inputLabelProps}
            />
          </Box>

          <Box>
            <Typography
              color="success"
              variant="subtitle1"
              sx={authSectionStyles.personnelAuthStyles.successAuth}
            >
              <CheckIcon />
              {t("common.bypassed")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SuccessAuthSection;
