import { Stack } from "@mui/material";
import { PageTitle } from "components/shared";
import {MinimumEmptyTransports} from "components/customer-information/preferences/minimum-empty-transports/MinimumEmptyTransport";
import i18n from "i18n";
const MinimumEmptyTransport = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("preferences.minimumEmptyTransports.pageTitle")} />
      <MinimumEmptyTransports/>
    </Stack>
  );
};

export default MinimumEmptyTransport;
