/**
 * Issue Containers Tab Styles
 */
export const IssueContainersStyles = {
  FlexRowStart: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start"
  },
  RadioGroup: {
    columnGap: "1.25rem",
    "> label": { margin: 0 }
  },
  DataTable: {
    boxShadow: "none"
  },
  FlexColStart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start"
  },
  MYAuto: {
    marginTop: "auto",
    marginBottom: "auto"
  },
  CardContent: {
    padding: 0,
    "&:last-child": { paddingBottom: 0 },
    "*>.MuiFormHelperText-root.Mui-error": { textWrap: "nowrap" }
  },
  AddCircleIconFill: {
    fill: "var(--color-im-mid-blue, #1b75bc)"
  }
};

export const IssueClosedContainers_tableBody = "230px";
