import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";

import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH,
  WORK_MANAGEMENT
} from "routing/Paths";
import { useGetAllCustomersQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  DEFAULT_LOCALE,
  ERROR_TYPES,
  VALUE_ALL,
  VALUE_EMPTY_STRING,
  VALUE_Y,
  errorMsgs
} from "utils/constants";
import {
  verifyConstants,
  verifyDefaultValues,
  verifyFilterOptions,
  verifyTabFilterOptions,
  verifyTabId,
  workManagementRoleAccess
} from "utils/constants/open-media-processing/WorkManagement";
import {
  dateTimeFormatByLocale,
  dateTimeFormatByLocalewithSeconds,
  findErrorMessage,
  generateRandomSeed,
  getResponseData
} from "utils/helpers";
import { InventoryTabStyles } from "../WorkManagementStyles";
import SelectableRowTable from "./SelectableRowTable";
import VerifyTable from "./VerifyTable";
import VerifyTree from "./VerifyTree";

const VerifyTabLayout = ({ localeByBranch }) => {
  const location = useLocation();
  const { currentBranch } = useSelector(selectAuth);

  const [isLoading, setIsLoading] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);

  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState(VALUE_EMPTY_STRING);

  const [isClearDisabled, setIsClearDisabled] = useState(true);
  const [isFindDisabled, setIsFindDisabled] = useState(false);

  const [isFindClicked, setIsFindClicked] = useState(false);

  const [verifyFilter, setVerifyFilter] = useState(
    verifyFilterOptions.readyToVerify
  );
  const [numOfBatches, setNumOfBatches] = useState(
    verifyDefaultValues.lastBatches
  );
  const [createdIn, setCreatedIn] = useState(verifyDefaultValues.lastDays);
  const [selectedCustomer, setSelectedCustomer] = useState(ALL_SELECTION);

  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });

  const [sortedRespData, setSortedRespData] = useState([]);

  const [nodeCustomer, setNodeCustomer] = useState(VALUE_EMPTY_STRING);

  const [isVerifyBtnEnabled, setIsVerifyBtnEnabled] = useState(false);

  const [verifyAndCommit, setVerifyAndCommit] = useState({
    verify: false,
    commit: false
  });

  const { roleAccess } = useSelector(selectAuth);

  const getHasAccess = (code) => roleAccess.includes(code);

  let { code198, code199 } = workManagementRoleAccess;

  // ############## Tables
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);

  const defaultSelectTableData = {
    batch: [],
    list: []
  };

  const [selectableTableData, setSelectableTableData] = useState(
    defaultSelectTableData
  );

  const [isOneBatchChecked, setIsOneBatchChecked] = useState(false);
  const [isOneListChecked, setIsOneListChecked] = useState(false);

  const [isCommitBtnEnabled, setIsCommitBtnEnabled] = useState(false);
  const [isSkipBatchBtnEnabled, setIsSkipBatchBtnEnabled] = useState(false);

  // ############# Tree
  const [verifyTreeItems, setVerifyTreeItems] = useState([]);
  const [expanded, setExpanded] = useState([verifyConstants.zeroNode]);
  const [selectedNode, setSelectedNode] = useState(VALUE_EMPTY_STRING);

  const [tempTree, setTempTree] = useState({
    click: false,
    clickObj: null
  });

  const [tempBtn, setTempBtn] = useState({
    refresh: false,
    clear: false
  });
  const [filterCusTemp, setFilterCusTemp] = useState({
    filterSelection: null,
    customerSelection: null
  });

  const [noClicked, setNoClicked] = useState(false);

  const treeRef = useRef();

  useEffect(() => {
    if (
      verifyAndCommit?.commit === false &&
      verifyAndCommit?.verify === false &&
      noClicked
    ) {
      setNoClicked(false);

      // tree click
      if (tempTree.click) {
        treeRef?.current.executeHandleClick(tempTree?.clickObj);
        return;
      }

      // button clicks
      if (tempBtn.refresh) {
        handleRefresh();
        return;
      }

      if (tempBtn.clear) {
        clear();
        return;
      }

      // filter change
      if (filterCusTemp.filterSelection) {
        handleFilterChange(filterCusTemp);
      }

      // customer change
      if (filterCusTemp.customerSelection) {
        handleCustomerSelection(filterCusTemp);
      }
    }
    // eslint-disable-next-line
  }, [verifyAndCommit, noClicked, tempTree, tempBtn, filterCusTemp]);

  useEffect(() => {
    throwError && window.scrollTo(0, 0);
  }, [throwError]);

  useEffect(() => {
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    if (
      isOneBatchChecked &&
      isOneListChecked &&
      tableData3?.length === 0 &&
      tableData?.length > 0 &&
      tableData2?.length > 0 &&
      getHasAccess(code198)
    ) {
      setIsVerifyBtnEnabled(true);
    } else {
      setIsVerifyBtnEnabled(false);
    }

    const validList = tableData?.filter(
      (obj) =>
        obj?.verification_status_id === verifyConstants.statusID &&
        obj?.im_assigned_slot_flag === VALUE_Y
    );

    if (
      isOneBatchChecked &&
      tableData?.length === validList?.length &&
      tableData?.length > 0 &&
      getHasAccess(code199)
    ) {
      setIsSkipBatchBtnEnabled(true);
    } else {
      setIsSkipBatchBtnEnabled(false);
    }
    // eslint-disable-next-line
  }, [tableData3, tableData, tableData2, isOneBatchChecked, isOneListChecked]);

  const setWarning = async (code) => {
    const msg = await getMessageFromCode(code);
    const errorMsg = msg[0]?.descr;
    setShowWarning(true);
    setWarningMessage(errorMsg);
  };

  useEffect(() => {
    if (selectableTableData) {
      setIsCommitBtnEnabled(false);
    }
  }, [selectableTableData]);

  useEffect(() => {
    setTableData3([]);
    if (
      selectedNode === t("workManagement.verifyTab") ||
      selectedNode === VALUE_EMPTY_STRING
    ) {
      setTableData([]);
      setTableData2([]);
      setTableData3([]);
      setSelectableTableData(defaultSelectTableData);
      setNodeCustomer(VALUE_EMPTY_STRING);
    } else {
      const result = handleNodeClick(selectedNode);

      const table1Data =
        result?.otherVaultData?.filter((obj) => !obj?.skipped) ||
        result?.filter(
          (obj) =>
            obj.verification_status_id !== VALUE_EMPTY_STRING && !obj?.skipped
        ) ||
        [];

      const table2Data =
        result.imVaultData?.filter((obj) => !obj?.skipped) ||
        result?.filter(
          (obj) =>
            obj.verification_status_id === VALUE_EMPTY_STRING && !obj?.skipped
        ) ||
        [];

      if (table1Data?.length > 0) {
        setNodeCustomer({
          number: table1Data[0]?.customer_number,
          id: table1Data[0]?.customer_id
        });
      } else if (table2Data?.length > 0) {
        setNodeCustomer({
          number: table2Data[0]?.customer_number,
          id: table2Data[0]?.customer_id
        });
      } else {
        setNodeCustomer(VALUE_EMPTY_STRING);
      }

      setTableData(table1Data);
      setTableData2(table2Data);
    }
    // eslint-disable-next-line
  }, [selectedNode, sortedRespData]);

  const arrayMatch = (arr1, arr2) => {
    if (arr2?.length === 0) {
      return true;
    }

    if (
      arr1.length === arr2.length &&
      arr1.every((val, idx) => val === arr2[idx])
    ) {
      return true;
    }

    if (arr1.length > 0 && arr2.length === 1 && arr2[0] === "") {
      return true;
    }

    if (
      arr2.length === arr1.length + 1 &&
      arr2[0] === "" &&
      arr1.every((val, idx) => val === arr2[idx + 1])
    ) {
      return true;
    }

    return false;
  };

  const validateVerify = () => {
    const oList = Array.from(
      new Set(selectableTableData?.list?.map((obj) => obj.mlv?.trim()))
    )
      ?.slice()
      ?.sort();
    const oBatches = Array.from(
      new Set(selectableTableData?.batch?.map((obj) => obj.mlv?.trim()))
    )
      ?.slice()
      ?.sort();

    return arrayMatch(oBatches, oList);
  };

  const handleRefresh = () => {
    if (verifyAndCommit?.commit === false && verifyAndCommit?.verify === true) {
      setWarning(errorMsgs?.errorCode25161);
      setTempBtn({ refresh: true, clear: false });
    } else {
      clearTreeAndTables();
      onFindClick();
    }
  };

  const handleVerify = async () => {
    const isValid = validateVerify();
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);
    setIsCommitBtnEnabled(false);

    if (isValid) {
      setIsLoading(true);
      setIsVerifyBtnEnabled(false);

      try {
        const batchList = selectableTableData?.batch?.map((item) => {
          return item?.open_media_batch_id?.padEnd(10, " ");
        });

        const list = selectableTableData?.list?.map((item) => {
          return item?.customer_file_section_id?.padEnd(10, " ");
        });

        const lvID = Array.from(
          new Set(
            selectableTableData?.batch.map((obj) =>
              obj.logical_vault_id?.trim()
            )
          )
        );

        const reqBody = JSON.stringify({
          main_district_id: currentBranch?.district_id,
          logical_vault_id: lvID[0],
          batch_list: batchList?.join(""),
          dist_list: list?.join("")
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.openMediaProcessing?.workManagement.verify
            .verifydistributionlistsagainstmediabatches,
          1
        );

        const obj = dataSets?.data;

        if (obj) {
          if (obj[0][0]?.error && obj[0][0]?.error !== "0") {
            const msg = await getMessageFromCode(obj[0][0]?.error);
            const errorMsg = msg[0]?.descr;
            setThrowErrorMessage(errorMsg);
            setThrowError(true);
          } else {
            if (obj[0]?.length > 0) {
              setVerifyAndCommit({ verify: true, commit: false });

              setThrowErrorMessage(VALUE_EMPTY_STRING);
              setThrowError(false);
              const sorted = obj[0]?.sort((a, b) =>
                a[verifyConstants.tbl3Cols.media]?.localeCompare(
                  b[verifyConstants.tbl3Cols.media]
                )
              );
              setTableData3(sorted);
            }

            setIsCommitBtnEnabled(true);
          }
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsVerifyBtnEnabled(true);
      const msg = await getMessageFromCode(errorMsgs.errorCode26074);
      const errorMsg = msg[0]?.descr;
      setThrowErrorMessage(errorMsg);
      setThrowError(true);
    }
  };

  const handleSkip = async () => {
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    const isValid = validateVerify();

    if (isValid) {
      try {
        setIsLoading(true);

        const batchList = selectableTableData?.batch?.map((item) => {
          return item?.open_media_batch_id?.padEnd(10, " ");
        });

        const lvID = Array.from(
          new Set(
            selectableTableData?.batch.map((obj) =>
              obj.logical_vault_id?.trim()
            )
          )
        );

        const reqBody = JSON.stringify({
          main_district_id: currentBranch?.district_id,
          logical_vault_id: lvID[0],
          batch_list: batchList?.join(""),
          skip_auth_employee_id: nodeCustomer?.id
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.openMediaProcessing?.workManagement.verify
            .skipverificationformediabatches,
          1
        );

        const obj = dataSets?.data;

        if (obj && obj[0]?.length > 0) {
          if (obj[0][0]?.error && obj[0][0]?.error !== "0") {
            const msg = await getMessageFromCode(errorMsgs.errorCode26021);
            const errorMsg = msg[0]?.descr;
            setThrowErrorMessage(errorMsg);
            setThrowError(true);
          } else {
            const updatedTree = sortedRespData?.map((ob) => {
              const updatedItem = obj[0]?.find((update) => {
                return update?.open_media_batch_id === ob?.open_media_batch_id;
              });

              if (updatedItem) {
                return {
                  ...ob,
                  skipped: true,
                  third_level: `${dateTimeFormatByLocale(
                    ob.created_datetime,
                    localeByBranch || DEFAULT_LOCALE
                  )} ${ob.scanned_by_initials} (${updatedItem.status} ${t(
                    "workManagement.verify.by"
                  )} ${updatedItem.employee_initials}) [${
                    updatedItem.open_media_batch_id
                  }]`
                };
              }
              return ob;
            });

            setSortedRespData(updatedTree);
            const treeData = transformData(updatedTree);
            setVerifyTreeItems(treeData);

            setVerifyAndCommit({ verify: false, commit: false });
            setIsCommitBtnEnabled(false);

            setThrowErrorMessage(VALUE_EMPTY_STRING);
            setThrowError(false);
          }
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      const msg = await getMessageFromCode(errorMsgs.errorCode26074);
      const errorMsg = msg[0]?.descr;
      setThrowErrorMessage(errorMsg);
      setThrowError(true);
    }
  };

  const handleCommit = async () => {
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    setWarningMessage((prev) => VALUE_EMPTY_STRING);
    setShowWarning((prev) => false);

    setIsLoading(true);

    const batchList = selectableTableData?.batch?.map((item) => {
      return item?.open_media_batch_id?.padEnd(10, " ");
    });

    const list = selectableTableData?.list?.map((item) => {
      return item?.customer_file_section_id?.padEnd(10, " ");
    });

    const lvID = Array.from(
      new Set(
        selectableTableData?.batch.map((obj) => obj.logical_vault_id?.trim())
      )
    );

    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        logical_vault_id: lvID[0],
        batch_list: batchList?.join(""),
        employee_id: nodeCustomer?.id,
        dist_list: list?.join("")
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.workManagement.verify
          .commitverificationresultsandupdatemediaattributes,
        1
      );

      const obj = dataSets?.data;

      if (
        obj &&
        obj[0]?.length > 0 &&
        obj[0][0]?.error &&
        obj[0][0]?.error !== "0"
      ) {
        const msg = await getMessageFromCode(obj[0][0]?.error);
        const errorMsg = msg[0]?.descr;
        setThrowErrorMessage(errorMsg);
        setThrowError(true);

        setIsCommitBtnEnabled(true);
        setIsVerifyBtnEnabled(true);
      } else {
        setVerifyAndCommit({ verify: true, commit: true });
        clearTemps();
        setIsCommitBtnEnabled(false);
        setIsVerifyBtnEnabled(false);

        setThrowErrorMessage(VALUE_EMPTY_STRING);
        setThrowError(false);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to parse nodeId
  const parseNodeId = (nodeId) => {
    const [level = null, value = null] = nodeId?.split("=") || [];
    const parts = value?.split("#");
    if (level === verifyConstants.levels.customer) {
      return { level, customer_number: parts[0] };
    } else if (level === verifyConstants.levels.vault) {
      return { level, customer_number: parts[0], lv: parts[1] };
    } else if (level === verifyConstants.levels.record) {
      return {
        level,
        customer_number: parts[0],
        lv: parts[1],
        thirdLevelKey: parts[2]
      };
    }
  };

  // Function to get data based on nodeId
  const getDataByNodeId = (nodeId, originalData) => {
    const parsed = parseNodeId(nodeId);

    if (parsed.level === verifyConstants.levels.customer) {
      const customerData = originalData?.filter(
        (item) => item.customer_number?.trim() === parsed.customer_number
      );
      const imVaultData = customerData?.filter(
        (item) => item.verification_status_id === VALUE_EMPTY_STRING
      );
      const otherVaultData = customerData?.filter(
        (item) => item.verification_status_id !== VALUE_EMPTY_STRING
      );
      return { imVaultData, otherVaultData };
    } else if (parsed.level === verifyConstants.levels.vault) {
      return originalData.filter(
        (item) =>
          item.customer_number?.trim() === parsed.customer_number &&
          item.lv?.trim() === parsed.lv
      );
    } else if (parsed.level === verifyConstants.levels.record) {
      return originalData?.filter((item) => {
        return (
          item.customer_number?.trim() === parsed.customer_number &&
          item.lv?.trim() === parsed.lv &&
          item.third_level === parsed.thirdLevelKey
        );
      });
    }
  };

  const handleNodeClick = (nodeId) => {
    const data = getDataByNodeId(nodeId, sortedRespData);
    return data;
  };

  const clearTemps = () => {
    setTempTree({
      click: false,
      clickObj: null
    });

    setTempBtn({
      refresh: false,
      clear: false
    });
    setFilterCusTemp({
      filterSelection: null,
      customerSelection: null
    });
    setNoClicked(false);
  };

  const clear = () => {
    if (verifyAndCommit?.commit === false && verifyAndCommit?.verify === true) {
      setTempBtn({ refresh: false, clear: true });
      setWarning(errorMsgs?.errorCode25161);
      return;
    }

    setIsFindDisabled(false);
    setIsClearDisabled(true);
    setVerifyFilter(verifyFilterOptions.readyToVerify);
    setNumOfBatches(verifyDefaultValues.lastBatches);
    setCreatedIn(verifyDefaultValues.lastDays);
    setSelectedCustomer(ALL_SELECTION);
    clearTreeAndTables();
    setIsCommitBtnEnabled(false);
  };

  const clearTreeAndTables = () => {
    clearTemps();

    setVerifyAndCommit({ verify: false, commit: false });
    setVerifyTreeItems([]);
    setExpanded([]);
    setIsFindClicked(false);
    setSelectedNode(VALUE_EMPTY_STRING);
    setTableData([]);
    setTableData2([]);
    setTableData3([]);
    setSelectableTableData(defaultSelectTableData);
    setNodeCustomer(VALUE_EMPTY_STRING);
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);
  };

  const transformData = (data) => {
    const tree = data.reduce((acc, item) => {
      const { customer_number, lv, third_level } = item;
      const thirdLevelKey = third_level;

      if (!acc[customer_number]) {
        acc[customer_number] = {
          nodeId: `customer=${customer_number?.trim()}`,
          customer_number,
          logical_vaults: []
        };
      }

      let customer = acc[customer_number];
      let logicalVault = customer.logical_vaults.find(
        (vault) => vault.lv === lv
      );
      if (!logicalVault) {
        logicalVault = {
          nodeId: `vault=${customer_number?.trim()}#${lv?.trim()}`,
          lv,
          records: []
        };
        customer.logical_vaults.push(logicalVault);
      }

      let record = logicalVault.records.find(
        (record) => record.key === thirdLevelKey
      );
      if (!record) {
        record = {
          nodeId: `record=${customer_number?.trim()}#${lv?.trim()}#${thirdLevelKey}`,
          key: thirdLevelKey,
          data: []
        };
        logicalVault.records.push(record);
      }

      record.data.push(item);
      return acc;
    }, {});

    return Object.values(tree);
  };

  const onFindClick = async () => {
    setIsLoading(true);
    setVerifyTreeItems([]);
    setExpanded([verifyConstants.zeroNode]);
    setIsFindClicked(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        customer_id:
          selectedCustomer?.value === VALUE_ALL
            ? VALUE_EMPTY_STRING
            : selectedCustomer?.value,
        display_view: verifyFilter,
        num_last_batches:
          verifyFilter === verifyFilterOptions?.skippedBatches
            ? numOfBatches
            : VALUE_EMPTY_STRING,
        last_n_days:
          verifyFilter === verifyFilterOptions?.skippedBatches
            ? createdIn
            : VALUE_EMPTY_STRING
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.workManagement.verify
          .getOpenMediaBatchAndCustomerFileSection,
        1
      );

      const obj = dataSets?.data;
      if (obj && obj[0]?.length > 0) {
        setIsClearDisabled(false);

        const respData = obj[0].sort(function (a, b) {
          const dateA = new Date(a.created_datetime);
          const dateB = new Date(b.created_datetime);

          const customerCompare = a.customer_number.localeCompare(
            b.customer_number
          );
          if (customerCompare !== 0) return customerCompare;

          if (a.lv === verifyConstants.lvIM && b.lv === verifyConstants.lvIM) {
            return dateA - dateB;
          }

          if (a.lv === verifyConstants.lvIM) return 1;
          if (b.lv === verifyConstants.lvIM) return -1;
          if (a.lv !== b.lv) {
            return a.lv.localeCompare(b.lv);
          }

          return dateA - dateB;
        });

        const mediaBatchORListAdded = respData?.map((rec) => {
          const batch =
            rec?.open_media_batch_id !== ""
              ? `[${t("workManagement.verify.batch")} ${
                  rec?.open_media_batch_id
                }]`
              : "";
          return {
            ...rec,
            third_level: `${dateTimeFormatByLocalewithSeconds(
              rec.created_datetime,
              localeByBranch || DEFAULT_LOCALE
            )} ${rec.scanned_by_initials} (${rec.task_status}) ${batch}`,
            media_batch_list: `${dateTimeFormatByLocale(
              rec.created_datetime,
              localeByBranch || DEFAULT_LOCALE
            )} ${rec.scanned_by_initials}`,
            checked: true,
            seed: generateRandomSeed(),
            mlv:
              rec.logical_vault_id === verifyConstants.lvForListGrid
                ? ""
                : rec.lv,
            skipped: false
          };
        });

        setSortedRespData(mediaBatchORListAdded);

        const treeData = transformData(mediaBatchORListAdded);
        setVerifyTreeItems(treeData);

        setIsFindDisabled(true);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Table 1
  const columns1 = [
    {
      id: "checkbox",
      label: VALUE_EMPTY_STRING
    },
    {
      id: "mlv",
      label: t("workManagement.verify.lv")
    },
    {
      id: "media_batch_list",
      label: t("workManagement.verify.mediaBatch")
    },
    {
      id: "item_count",
      label: t("workManagement.verify.count")
    }
  ];

  // Table 2
  const columns2 = [
    {
      id: "checkbox",
      label: VALUE_EMPTY_STRING
    },
    {
      id: "mlv",
      label: t("workManagement.verify.lv")
    },
    {
      id: "media_batch_list",
      label: t("workManagement.verify.list")
    },
    {
      id: "item_count",
      label: t("workManagement.verify.count")
    }
  ];

  // Table 3
  const columns3 = [
    {
      id: "id",
      label: VALUE_EMPTY_STRING
    },
    {
      id: "media #",
      label: t("workManagement.verify.media")
    },
    {
      id: "media type",
      label: t("workManagement.verify.mediaType")
    },
    {
      id: "exception",
      label: t("workManagement.verify.exception")
    },
    {
      id: "scan seq.",
      label: t("workManagement.verify.scanSeq")
    },
    {
      id: "list",
      label: t("workManagement.verify.list")
    },
    {
      id: "employee_initials",
      label: t("workManagement.verify.batch")
    }
  ];

  const onNoClick = () => {
    setIsCommitBtnEnabled(false);
    setWarningMessage((prev) => VALUE_EMPTY_STRING);
    setShowWarning((prev) => false);
    setNoClicked(true);
    setVerifyAndCommit({ verify: false, commit: false });
  };

  const handleCustomerSelection = (newValue) => {
    if (verifyAndCommit?.commit === false && verifyAndCommit?.verify === true) {
      setFilterCusTemp({ filterSelection: null, customerSelection: newValue });
      setWarning(errorMsgs?.errorCode25161);
      return;
    }

    if (filterCusTemp.customerSelection) {
      newValue = filterCusTemp.customerSelection;
      setFilterCusTemp({ filterSelection: null, customerSelection: null });
    }

    setIsFindDisabled(false);
    setIsClearDisabled(true);
    setIsFindClicked(false);
    setNumOfBatches(verifyDefaultValues.lastBatches);
    setCreatedIn(verifyDefaultValues.lastBatches);

    clearTreeAndTables();
    setIsClearDisabled(false);
    setSelectedCustomer(newValue || ALL_SELECTION);
  };

  const handleFilterChange = (e) => {
    let value = e?.target?.value || filterCusTemp?.filterSelection;

    if (value) {
      if (
        verifyAndCommit?.commit === false &&
        verifyAndCommit?.verify === true
      ) {
        setFilterCusTemp((prevState) => ({
          filterSelection: value,
          customerSelection: null
        }));
        setWarning((prevState) => errorMsgs?.errorCode25161);
        return;
      }

      if (filterCusTemp.filterSelection) {
        setFilterCusTemp((prevState) => ({
          filterSelection: null,
          customerSelection: null
        }));
      }

      clear();
      setVerifyFilter((prevState) => value);
    }
  };

  useEffect(() => {
    // if the route state has tab value and filter value
    if (location.state?.tab === verifyTabId && location.state?.filter) {
      handleFilterChange({
        target: { name: "filterlabel", value: location.state.filter }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    // if the route state has tab value and state's filter value changed
    if (
      location.state?.tab === verifyTabId &&
      verifyFilter === location.state?.filter
    ) {
      onFindClick().then(() =>
        window.history.replaceState(
          null,
          "",
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyFilter]);

  return (
    <>
      {isLoading && <FullCircularLoader />}

      {showWarning && (
        <ConfirmPopup
          message={
            warningMessage === VALUE_EMPTY_STRING ? null : warningMessage
          }
          modalPopupOpen={showWarning}
          handleCancel={() => {
            setWarningMessage((prev) => VALUE_EMPTY_STRING);
            setShowWarning((prev) => false);
          }}
          handleYes={handleCommit}
          handleNo={onNoClick}
          showCancel={true}
          showNo={true}
        />
      )}

      {throwError && (
        <Alert
          sx={{ mt: 2 }}
          severity="error"
          icon={<img src={ErrorIcon} alt="error" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
                setThrowErrorMessage(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      )}
      <br />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="printerNameLabel">
              {t("workManagement.filter")}
            </InputLabel>
            <Select
              label={"filter"}
              id={"filterId"}
              name={"filterlabel"}
              value={verifyFilter}
              onChange={(e) => {
                handleFilterChange(e);
              }}
            >
              {verifyTabFilterOptions?.map((obj) => (
                <MenuItem value={obj?.value} key={obj?.value}>
                  {obj?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {verifyFilter === verifyFilterOptions.skippedBatches && (
          <>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  label={t("workManagement.verify.lastBatch")}
                  value={numOfBatches}
                  onChange={(e) => {
                    if (e.target.value?.length <= 2 && e.target.value > 0) {
                      setIsClearDisabled(false);
                      setNumOfBatches(e.target.value || VALUE_EMPTY_STRING);
                      setIsFindDisabled(false);
                      clearTreeAndTables();
                    }
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  label={t("workManagement.verify.lastDays")}
                  value={createdIn}
                  onChange={(e) => {
                    if (e.target.value?.length <= 2 && e.target.value > 0) {
                      setIsClearDisabled(false);
                      setCreatedIn(e.target.value || VALUE_EMPTY_STRING);
                      setIsFindDisabled(false);
                      clearTreeAndTables();
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <CustomersField
              options={[ALL_SELECTION, ...customers]}
              value={selectedCustomer}
              handleOnChange={(e, newValue) => {
                handleCustomerSelection(newValue);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <br />

      <Stack direction="row" gap={spacing.gap}>
        <Button
          disabled={isClearDisabled}
          id="newBtnfilter"
          variant="outlined"
          onClick={() => clear()}
        >
          {t("common.clear")}
        </Button>
        <Button
          id="saveBtnfilter"
          variant="contained"
          type="submit"
          disabled={isFindDisabled}
          onClick={onFindClick}
        >
          {t("common.find")}
        </Button>
      </Stack>
      <br />

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card sx={InventoryTabStyles.tree.card}>
              <CardContent>
                <VerifyTree
                  verifyList={verifyTreeItems}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  isFindClicked={isFindClicked}
                  setSelectedNode={setSelectedNode}
                  verifyAndCommit={verifyAndCommit}
                  setWarning={setWarning}
                  tempTree={tempTree}
                  setTempTree={setTempTree}
                  ref={treeRef}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={8}>
            <Stack gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack gap={2}>
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true
                      }}
                      label={t("common.customer")}
                      fullWidth
                      value={nodeCustomer?.number || VALUE_EMPTY_STRING}
                      InputLabelProps={{ shrink: true, style: { zIndex: 0 } }}
                    />

                    <Typography variant="h5">
                      {t("workManagement.verify.tableSecTitle")}
                    </Typography>
                  </Stack>
                </Grid>

                {/* Batch grid */}
                <Grid item xs={6}>
                  <SelectableRowTable
                    columns={columns1}
                    sortedList={tableData}
                    setTableData={setTableData}
                    setTableData3={setTableData3}
                    setIsOneBatchChecked={setIsOneBatchChecked}
                    title={t("workManagement.verify.totalCount")}
                    setSelectableTableData={setSelectableTableData}
                    setVerifyAndCommit={setVerifyAndCommit}
                  />
                </Grid>

                {/* List grid */}
                <Grid item xs={6}>
                  <SelectableRowTable
                    columns={columns2}
                    sortedList={tableData2}
                    setTableData={setTableData2}
                    setTableData3={setTableData3}
                    setIsOneListChecked={setIsOneListChecked}
                    setSelectableTableData={setSelectableTableData}
                    title={t("workManagement.verify.totalCount")}
                    setVerifyAndCommit={setVerifyAndCommit}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  sx={InventoryTabStyles.buttonList}
                  onClick={handleRefresh}
                >
                  {t("workManagement.verify.refreshBtn")}
                </Button>
                <Button
                  variant="outlined"
                  sx={InventoryTabStyles.buttonList}
                  disabled={!isSkipBatchBtnEnabled}
                  onClick={handleSkip}
                >
                  {t("workManagement.verify.skipBatchBtn")}
                </Button>
                <Button
                  variant="contained"
                  sx={InventoryTabStyles.buttonList}
                  disabled={!isVerifyBtnEnabled}
                  onClick={handleVerify}
                >
                  {t("workManagement.verify.verifyBtn")}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <VerifyTable
                  columns={columns3}
                  sortedList={tableData3}
                  isCommitBtnEnabled={isCommitBtnEnabled}
                  onClick={handleCommit}
                  title={t("workManagement.verify.verifyResults")}
                  localeByBranch={localeByBranch}
                />
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default VerifyTabLayout;
