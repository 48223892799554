import { ChevronLeft, ExpandMore, HighlightOff } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";

import { GridCloseIcon } from "@mui/x-data-grid";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { getAllCustomers } from "services/common";
import { selectAuth } from "store/slices";
import { setIsRefreshRequired } from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import {
  dateFormatWithTime,
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  errorMsgs,
  NO,
  ROLE_ACCESS_CODES,
  unauthorized,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER,
  YES
} from "utils/constants";
import { getPreferenceTypesPayloadForReport } from "utils/constants/customer-information/CustomerPreference";
import { hyphen } from "utils/constants/customer-information/PlaceHoldOnMediaDestructionRequests";
import {
  all,
  dataIndex,
  filterOptions,
  padStartLength,
  preferencesTypeIds,
  preferenceTypeMap,
  preferenceXML,
  preferenceXMLValues,
  systemId,
  zero
} from "utils/constants/customer-information/ScanOnSite";
import {
  escapeXml,
  escapeXmlForReport,
  formatDateByLocale,
  getAuthenticatedUserBranch,
  getResponseData
} from "utils/helpers";
import PreferencesLayout from "../preferences-main/PreferencesLayout";
import PreferencesReoprtDownloadModal from "../PreferencesReoprtDownloadModal";
import { PreferencesStyles } from "../PreferencesStyles";
import FilterModal from "./FilterModal";

const ScanOnSite = () => {
  const { state = {} } = useLocation();
  const { roleAccess, auth, localeByBranch } = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [destructCheckLoading, setDestructCheckLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [scanOnSitePickeUp, setScanOnSitePickUp] = useState(false);
  const [interactiveSiteScan, setInteractiveSiteScan] = useState(false);
  const [scanOnSiteDelivery, setScanOnSiteDelivery] = useState(false);
  const [comment, setComment] = useState("");
  const [initialComment, setInitialComment] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [parentData, setParentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [messageSuccess, setMessageSuccess] = useState(false);

  const [filterValue, setFilterValue] = useState(filterOptions[0]);
  const [isDownLoadModalOpen, setIsDownloadModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const navigate = useNavigate();
  const isUserHasPermission = roleAccess.includes(ROLE_ACCESS_CODES.CODE_341);
  const filterModalOpen = Boolean(anchorEl);

  const [errorMessage, setErrorMessage] = useState("");
  const [throwWarningMessage, setThrowWarningMessage] = useState(false);
  const [disableCheckboxState, setDisabledCheckboxState] = useState({
    scanOnSite: false,
    interactiveSiteScan: false
  });
  const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const customer_id = state?.customer_id;
  const dispatch = useDispatch();
  const districtId = getAuthenticatedUserBranch();

  const { isAuthenticated, authFormValues, defaultAuthBranchValues } =
    useSelector((state) => state.customerPreferences);

  let dataFromStore =
    defaultAuthBranchValues?.prefenceFilteredTableData?.find(
      (p) => p.preference_type_id === preferencesTypeIds[0]
    ) || {};

  let hasStoreData = Object.keys(dataFromStore).length;

  const handleFilterModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterModalClose = () => {
    setAnchorEl(null);
  };

  const handleFilterModalReset = () => {
    setFilterValue(filterOptions[0]);
    handleFilterModalChange(filterOptions[0]);
  };

  const handleFilterModalChange = (value) => {
    setFilterValue(value);
    filterData(value);
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setErrorMessage(t("common.systemErrorMessage"));
    }
  };

  // Handle error with error code
  const handleError = async (errorCode, replaceText) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);

    if (errorMsg) {
      if (replaceText) {
        setErrorMessage(errorMsg?.replace("|", replaceText));
      } else {
        setErrorMessage(errorMsg);
      }
    }
  };

  useEffect(() => {
    let freshValues = {
      [preferencesTypeIds[0]]: scanOnSitePickeUp,
      [preferencesTypeIds[1]]: interactiveSiteScan,
      [preferencesTypeIds[2]]: scanOnSiteDelivery,
      [preferencesTypeIds[3]]: comment
    };

    const isSame = _.isEqual(initialValues, freshValues);
    setIsSaveDisabled(isSame);
  }, [
    scanOnSitePickeUp,
    interactiveSiteScan,
    scanOnSiteDelivery,
    comment,
    initialValues
  ]);

  const handleScanOnSitePickup = (e) => {
    setScanOnSitePickUp(e.target.checked);
    setInteractiveSiteScan(false);
  };
  const handleInteractiveSiteScan = (e) => {
    setInteractiveSiteScan(e.target.checked);
  };
  const handleScanOnSiteDelivery = (e) => {
    setScanOnSiteDelivery(e.target.checked);
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (districtId) getDestructRequestStatus();
    //eslint-disable-next-line
  }, [districtId]);

  useEffect(() => {
    getSelectedCustomer();
    if (hasStoreData) {
      let flattendData = [dataFromStore, ...dataFromStore.children];
      saveDataToState(flattendData);
    } else {
      getPreferences();
    }
    //eslint-disable-next-line
  }, []);

  const getSelectedCustomer = async () => {
    try {
      setLoading(true);
      const customers = await getAllCustomers();
      let customer =
        customers.find((customer) => customer.customer_id === customer_id) ||
        {};
      setCustomer(customer);
    } catch (error) {
      handleCatchError(error);
    } finally {
      if (hasStoreData) {
        setLoading(false);
      }
    }
  };

  const getDestructRequestStatus = async () => {
    try {
      setDestructCheckLoading(true);
      const res = await getResponseData(
        {
          main_district_id: districtId,
          customer_id
        },
        CF_URLS.customerPreferences.getDestructionRequestStatus,
        2
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      if (Object.keys(data[0] || {}).length) {
        let DestructRequestSSStatusCount =
          data[0]?.Destruct_Request_SS_Status_Count;
        let FileSectionISSStatusCount = data[0]?.File_Section_ISS_Status_Count;
        if (DestructRequestSSStatusCount > 0) {
          await handleThrowWarningMessage(errorMsgs.errorCode80384);
          setDisabledCheckboxState((prev) => ({ ...prev, scanOnSite: true }));
        } else if (FileSectionISSStatusCount > 0) {
          await handleThrowWarningMessage(errorMsgs.errorCode80385);
          setDisabledCheckboxState((prev) => ({
            scanOnSite: true,
            interactiveSiteScan: true
          }));
        }
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setDestructCheckLoading(false);
    }
  };

  const handleThrowWarningMessage = async (code) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(code);
    setThrowWarningMessage(errorMsg);
  };

  const getPreferences = async () => {
    try {
      setLoading(true);
      const res = await getResponseData(
        {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          customer_id
        },
        CF_URLS.customerPreferences.getPreferenceValues,
        4
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      let preference = data[2];
      saveDataToState(preference);
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  const saveDataToState = (list) => {
    let initialValues = {};
    list.forEach((prefeence) => {
      let pId = prefeence.preference_type_id;
      if (preferencesTypeIds.includes(pId)) {
        let value = prefeence?.preference_value || undefined;
        let isChecked = value === Y_CHECKER;
        switch (pId) {
          case preferencesTypeIds[0]:
            setScanOnSitePickUp(isChecked);
            initialValues[preferencesTypeIds[0]] = isChecked;
            break;
          case preferencesTypeIds[1]:
            setInteractiveSiteScan(isChecked);
            initialValues[preferencesTypeIds[1]] = isChecked;
            break;
          case preferencesTypeIds[2]:
            setScanOnSiteDelivery(isChecked);
            initialValues[preferencesTypeIds[2]] = isChecked;
            break;
          case preferencesTypeIds[3]:
            setComment(value);
            setInitialComment(value);
            initialValues[preferencesTypeIds[3]] = value;
            break;
          default:
            break;
        }
      }
    });
    setInitialValues(initialValues);
  };

  const getValueWithPreferenceId = (pId) => {
    switch (pId) {
      case preferencesTypeIds[0]:
        return scanOnSitePickeUp ? VALUE_Y : VALUE_N;
      case preferencesTypeIds[1]:
        return interactiveSiteScan ? VALUE_Y : VALUE_N;
      case preferencesTypeIds[2]:
        return scanOnSiteDelivery ? VALUE_Y : VALUE_N;
      case preferencesTypeIds[3]:
        return escapeXml(comment);
      default:
        break;
    }
  };

  const onChangeAccordionExpand = (e, expanded) => {
    setIsHistoryExpanded(expanded);
    if (!expanded || parentData.length) return;
    getPreferencesHistory();
  };

  const getPreferencesHistory = async () => {
    setLoading(true);
    try {
      const res = await getResponseData(
        {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          customer_id,
          preference_type_id_list: preferencesTypeIds.reduce(
            (a, key) => (a += key.padStart(padStartLength, " ")),
            ""
          )
        },
        CF_URLS.customerPreferences.getCustomerPreferenceHistory,
        3
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      let parent = data[0];
      let child = data[1];

      const parentDataPromises = parent
        .sort((a, b) => {
          return dayjs(b.change_history_datetime).isAfter(
            dayjs(a.change_history_datetime)
          )
            ? 1
            : -1;
        })
        .map(async (parentItem) => {
          const formattedDate = await formatDateByLocale(
            dayjs(parentItem.change_history_datetime).utc(),
            localeByBranch || DEFAULT_LOCALE,
            true,
            true
          );

          const filteredChildren = child.filter(
            (childItem) =>
              childItem.change_history_id === parentItem.change_history_id
          );

          const changedValues = filteredChildren
            .map((childItem) => preferenceTypeMap[childItem.preference_type_id])
            .sort((a, b) => a.localeCompare(b))
            .join(", ");

          return {
            date: formattedDate,
            imEmployee: `${parentItem.employee_last_name}, ${parentItem.employee_first_name}`,
            authorizedBy: `${parentItem.personnel_last_name} ${parentItem.personnel_first_name}`,
            changedValues: changedValues,
            change_history_id: parentItem.change_history_id,
            children: filteredChildren
              .map((childItem) => {
                let previousValue = childItem.old_value;
                let newValue = childItem.new_value;
                if (childItem.preference_type_id !== preferencesTypeIds[3]) {
                  previousValue = previousValue === Y_CHECKER ? YES : NO;
                  newValue = newValue === Y_CHECKER ? YES : NO;
                }
                return {
                  name: preferenceTypeMap[childItem.preference_type_id],
                  previousValue,
                  newValue
                };
              })
              .sort((a, b) => a.name.localeCompare(b.name))
          };
        });

      Promise.all(parentDataPromises).then((resolvedParentData) => {
        setParentData(resolvedParentData);
        setFilteredData(resolvedParentData);
      });
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  const onCLickSavePreferences = async () => {
    setLoading(true);
    try {
      const res = await getResponseData(
        {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          customer_id,
          employee_id: auth?.user?.id,
          personnel_id: isAuthenticated ? authFormValues?.personnel?.value : "",
          system_id: systemId,
          preferences_xml: preferenceXML.replace("|", () => {
            return preferencesTypeIds
              .filter((id) =>
                id !== preferencesTypeIds[3]
                  ? true
                  : comment !== initialComment
                    ? true
                    : false
              )
              .map((key) => {
                return preferenceXMLValues
                  .replace("|", key)
                  .replace("|", getValueWithPreferenceId(key));
              })
              .join("");
          }),
          auth_bypass_reason: ""
        },
        CF_URLS.customerPreferences.updateCustomerPreferences,
        3
      );
      let data = res.data;

      if (data[0]) {
        let code = data[0][0]?.error;
        if (code === zero) {
          dispatch(setIsRefreshRequired(true));
          await getPreferences();
          if (isHistoryExpanded) {
            await getPreferencesHistory();
          }
          setFilterValue(filterOptions[0]);
          let { errorMsg } = await getTheFirestoreErrorMessage(
            errorMsgs.errorCode64095
          );
          setMessageSuccess(
            errorMsg
              .replace("|", preferenceTypeMap[preferencesTypeIds[0]])
              .replace("|", dayjs().utc().format(dateFormatWithTime))
          );
          return;
        }
        handleError(code);
        return;
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  const filterData = (filterValue) => {
    if (filterValue.value === all) {
      setFilteredData(parentData);
    } else {
      const filtered = parentData.filter((item) =>
        item.changedValues.includes(filterValue.label)
      );
      setFilteredData(filtered);
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  let columns = [
    {
      label: t("preferences.dateLabel"),
      name: dataIndex.date
    },
    {
      label: t("preferences.imEmployee"),
      name: dataIndex.imEmployee
    },
    {
      label: t("preferences.authorizedBy"),
      name: dataIndex.authorizedBy
    },
    {
      label: t("preferences.changedValueLabel"),
      name: dataIndex.changedValues
    }
  ];

  let nestedColumns = [
    {
      label: t("preferences.name"),
      name: dataIndex.name
    },
    {
      label: t("preferences.previousValueLabel"),
      name: dataIndex.previousValue
    },
    {
      label: t("preferences.newValueLabel"),
      name: dataIndex.newValue
    }
  ];
  const nestedTableOption = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: false,
    elevation: 1
  };

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: true,
    textLabels: {
      body: {
        noMatch: (
          <Box pb={spacing.gap}>
            <Typography style={PreferencesStyles.noDataText}>
              {t("common.noRecordsFound")}
            </Typography>
          </Box>
        )
      }
    },
    expandableRows: true,
    expandableRowsHeader: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const parentItem = parentData[rowMeta.dataIndex];
      return (
        <TableRow>
          <TableCell colSpan={5} sx={PreferencesStyles.nestedTableCell}>
            <MUIDataTable
              columns={nestedColumns}
              options={nestedTableOption}
              data={parentItem.children}
            />
          </TableCell>
        </TableRow>
      );
    },
    customToolbar: () => {
      return (
        <>
          <IconButton
            onClick={handleFilterModalOpen}
            title="Filter"
            id="filter-button"
          >
            <FilterListIcon />
          </IconButton>
          <FilterModal
            open={filterModalOpen}
            anchorEl={anchorEl}
            filterValue={filterValue}
            handleClose={handleFilterModalClose}
            handleChange={handleFilterModalChange}
            handleReset={handleFilterModalReset}
          />
        </>
      );
    }
  };

  const handleDownloadOpen = () => {
    setIsDownloadModalOpen(true);
  };
  const topTextFieldReportsPayload = [
    {
      key: t("preferences.scanOnSitePickup"),
      value: scanOnSitePickeUp ? t("common.yes") : t("common.no")
    },
    {
      key: t("preferences.interactiveScan"),
      value: interactiveSiteScan ? t("common.yes") : t("common.no")
    },
    {
      key: t("preferences.scanOnSiteDeliveery"),
      value: scanOnSiteDelivery ? t("common.yes") : t("common.no")
    },
    {
      key: t("preferences.customerPreferenceTypes29"),
      value: comment ? escapeXmlForReport(comment) : ""
    }
  ];

  const filteredPreferenceTypes = useMemo(() => {
    return getPreferenceTypesPayloadForReport().filter((type) =>
      preferencesTypeIds.includes(type.preference_type_id)
    );
  }, []);

  return (
    <PreferencesLayout>
      <Stack
        direction="column"
        gap={spacing.verticalMargin20}
        mb={spacing.verticalMargin32}
        mt={spacing.verticalMargin16}
      >
        {(loading || destructCheckLoading) && <FullCircularLoader />}
        {messageSuccess && (
          <SuccessPopup message={messageSuccess} close={setMessageSuccess} />
        )}
        {errorMessage && (
          <Box mb={spacing.gap}>
            <Alert
              severity="error"
              sx={PreferencesStyles.errorAlert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setErrorMessage(false)}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Box>
        )}

        {throwWarningMessage && (
          <Box mb={spacing.gap}>
            <Alert
              severity="warning"
              sx={PreferencesStyles.errorAlert}
              icon={<img src={WarningIcon} alt="warning" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowErrorMessage("");
                  }}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwWarningMessage}
            </Alert>
          </Box>
        )}

        <Grid container spacing={spacing.horizontalMargin20}>
          <Grid item xs={12}>
            <Box mb={spacing.verticalMargin20}>
              <Typography variant="subtitle1" color="textSecondary">
                {t("common.customer")}
              </Typography>
              <Typography variant="subtitle1">
                {customer?.number || hyphen}
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={PreferencesStyles.titleStyle}
              mb={spacing.verticalMargin20}
            >
              {t("preferences.scanOnSite")}
            </Typography>
            <Typography variant="subtitle1">
              {t("preferences.settingChangeMessage")}
            </Typography>
          </Grid>

          <Grid container component={Box} pl={spacing.gap} mt={spacing.gap}>
            <Grid item xs={4}>
              <Box mb={spacing.verticalMargin20}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("preferences.pickUp")}
                </Typography>

                <Stack px={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleScanOnSitePickup}
                        color="primary"
                        checked={scanOnSitePickeUp}
                        disabled={
                          isUserHasPermission
                            ? disableCheckboxState.scanOnSite
                              ? true
                              : false
                            : false
                        }
                      />
                    }
                    label={t("preferences.enableScanOnSite")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleInteractiveSiteScan}
                        color="primary"
                        checked={interactiveSiteScan}
                        disabled={
                          isUserHasPermission
                            ? scanOnSitePickeUp
                              ? disableCheckboxState.interactiveSiteScan
                                ? true
                                : false
                              : true
                            : false
                        }
                      />
                    }
                    label={t("preferences.enableInteractiveSiteScan")}
                  />
                </Stack>
                <Typography variant="caption">
                  {t("preferences.interactiveCheckboxMessage")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box mb={spacing.verticalMargin20}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label={t("preferences.comments")}
                  value={comment}
                  onChange={handleCommentChange}
                  inputProps={{ maxLength: 128 }}
                  disabled={!isUserHasPermission}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle1" color="textSecondary">
              {t("preferences.delivery")}
            </Typography>
            <Box px={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleScanOnSiteDelivery}
                    color="primary"
                    checked={scanOnSiteDelivery}
                    disabled={!isUserHasPermission}
                  />
                }
                label={t("preferences.enableScanOnSite")}
              />
            </Box>
          </Grid>
        </Grid>
        <Accordion
          sx={PreferencesStyles.accordian}
          onChange={onChangeAccordionExpand}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              variant="subtitle1"
              style={PreferencesStyles.subTitleStyle}
            >
              {t("preferences.history")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={PreferencesStyles.accordiaDetail}>
            <TableContainer sx={PreferencesStyles.tableContainer}>
              <MUIDataTable
                columns={columns}
                options={options}
                data={filteredData}
                title={
                  filterValue.value !== all && (
                    <Chip
                      label={filterValue.label}
                      sx={PreferencesStyles.chipColor}
                      variant="outlined"
                      onDelete={() => handleFilterModalChange(filterOptions[0])}
                      deleteIcon={
                        <HighlightOff sx={PreferencesStyles.chipDeleteIcon} />
                      }
                      size="small"
                    />
                  )
                }
              />
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Divider />
        <Stack mt={spacing.gap} gap={spacing.gap} direction="row">
          <Button
            variant="outlined"
            sx={PreferencesStyles.backButton}
            onClick={goBack}
          >
            <ChevronLeft /> {t("common.back")}
          </Button>

          <Button variant="outlined" onClick={handleDownloadOpen}>
            {t("common.print")}
          </Button>

          <Button
            variant="contained"
            onClick={onCLickSavePreferences}
            disabled={!isUserHasPermission || isSaveDisabled}
          >
            {t("common.save")}
          </Button>
        </Stack>
      </Stack>

      <PreferencesReoprtDownloadModal
        topTextFieldReportsPayload={topTextFieldReportsPayload}
        url={CF_URLS.printReports.customerInformation.scanonsiteReport}
        title={t("common.downloadReport")}
        showPrintModal={isDownLoadModalOpen}
        setCloseModal={setIsDownloadModalOpen}
        setIsLoading={setLoading}
        customerId={customer_id}
        preferenceTypeId={preferencesTypeIds.reduce(
          (a, key) => (a += key.padStart(padStartLength, " ")),
          ""
        )}
        preferenceTypesPayloadForReport={filteredPreferenceTypes}
      />
    </PreferencesLayout>
  );
};

export default ScanOnSite;
