import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  errorMsgs
} from "utils/constants";
import { zero } from "utils/constants/customer-information/PayInvoiceFilter";
import { buildType } from "utils/constants/disaster-module/EventSearchConstants";
import { findErrorMessage, getResponseData } from "utils/helpers";
import { disasterRecoveryStyles } from "../DisasterRecoveryStyles";

const DrpCode = ({
  customerSelected,
  setAddOpenMedia,
  setDrpChanges,
  RequestForm,
  addOpenMedia,
  eventRef
}) => {
  const { currentBranch } = useSelector(selectAuth);
  const [subTitle, setSubTitle] = useState(null);
  const [drpCodeData, setDrpCodeData] = useState([]);
  const [drpCodeList, setDrpCodeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drpCode, setDrpCode] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const getTitle = async () => {
    const drpCodeTitle = await getMessageFromCode(errorMsgs.errorCode26098);
    setSubTitle(drpCodeTitle[0]?.descr);
  };

  const getDrpCodeData = useCallback(async () => {
    try {
      setLoading(true);
      const reqbody = {
        main_district_id: currentBranch?.value,
        customer_id: customerSelected
      };

      let response = await getResponseData(
        reqbody,
        `${CF_URLS?.drpCode.getDrpDropdown}`,
        1
      );
      if (response.data[0][0]?.drp_code) {
        setDrpCodeData(
          response.data[0]?.sort((a, b) =>
            a?.drp_code?.localeCompare(b?.drp_code)
          )
        );
        setLoading(false);
      } else {
        setLoading(false);
        setThrowError(true);
        setThrowErrorMessage(t("disasterRecovery.eventDetail.drpCode.noDrp"));
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  }, [customerSelected, currentBranch]);
  const loadDrpList = async (data) => {
    try {
      setLoading(true);
      const reqbody = {
        main_district_id: currentBranch?.value,
        customer_id: customerSelected,
        drp_code: data?.drp_code
      };

      let response = await getResponseData(
        reqbody,
        `${CF_URLS?.drpCode.getDrpList}`,
        1
      );
      if (response.data[0][0]?.drp_code) {
        const result = response.data[0]?.map((obj) => {
          obj.selected = true;
          return obj;
        });
        setDrpChanges(true);
        setDrpCodeList(result);
        setLoading(false);
      } else {
        setLoading(false);
        setThrowError(true);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    tableBodyHeight: "200px",
    tableBodyMaxHeight: "200px",
    pagination: 0 & true,
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    }
  };

  const handleSelectChangeOpenMedia = (e, number) => {
    let checked = e.target.checked;
    let updatedIndex = drpCodeList.findIndex(
      (item) => item.volser?.trim() === number.trim()
    );
    let updatedOpenMediaList = [...drpCodeList];
    updatedOpenMediaList[updatedIndex].selected = checked;
    setDrpCodeList(updatedOpenMediaList);
  };

  const columnOpenMedia = [
    {
      name: "selected",
      label: t("disasterRecovery.eventDetail.manualEntry.selected"),
      options: {
        filter: true,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          let volserNumber = tableMeta?.rowData[1];
          return (
            <>
              {
                <Checkbox
                  checked={drpCodeList[tableMeta.rowIndex]?.selected}
                  onChange={(e) => handleSelectChangeOpenMedia(e, volserNumber)}
                />
              }
            </>
          );
        },
        customHeadLabelRender: (columnMeta) => <div>{columnMeta.label}</div>
      }
    },
    {
      name: "volser",
      label: t("disasterRecovery.eventDetail.manualEntry.openMedia")
    },
    {
      name: "om_status",
      label: t("disasterRecovery.eventDetail.manualEntry.status")
    },
    {
      name: "short_descr",
      label: t("disasterRecovery.eventDetail.manualEntry.mediaType")
    },
    {
      name: "drp_code",
      label: t("disasterRecovery.eventDetail.manualEntry.drpCode")
    },
    {
      name: "descr",
      label: t("disasterRecovery.eventDetail.manualEntry.description")
    }
  ];
  useEffect(() => {
    getTitle();
    getDrpCodeData();
  }, [getDrpCodeData]);
  const handleDrpCode = (_, val) => {
    setDrpCode(val);
    loadDrpList(val);
  };

  const handelAddRequest = () => {
    addAllSelectedOpenMedia();
    setDrpChanges(false);
  };
  useImperativeHandle(eventRef, () => ({
    handelAddRequest
  }));
  const addAllSelectedOpenMedia = () => {
    // Filter the selected items and map their openMedia to volser
    const selectedCustomerOpenMedia = drpCodeList
      ?.filter((o) => o.selected)
      ?.map((obj) => ({
        ...obj,
        openMedia: obj?.volser
      }));
    // Prevent adding duplicates based on openMedia
    const mergedOpenMedia = selectedCustomerOpenMedia.filter(
      (newItem) =>
        !addOpenMedia?.some(
          (existingItem) => existingItem.openMedia === newItem.openMedia
        )
    );
    // Update state by adding only non-duplicate items
    setAddOpenMedia((prev) => [...prev, ...mergedOpenMedia]);
    // Resetting other states
    setDrpCode(null);
    setDrpCodeList([]);
  };

  const handelClearSelected = () => {
    const updatedOpenMediaList = drpCodeList?.map((openMedia) => {
      openMedia.selected = false;
      return openMedia;
    });

    setDrpCodeList(updatedOpenMediaList);
  };

  const handelReviewAndGenerate = () => {
    addAllSelectedOpenMedia();
    RequestForm.setFieldValue("buildRequest", buildType.landingPage);
  };
  const getSelectedOpenMediaCount = () => {
    return drpCodeList?.filter((om) => !!om.selected)?.length || zero;
  };
  return (
    <>
      {throwError && (
        <Box my={spacing.gap} ml={spacing.gap}>
          <Alert
            severity="error"
            icon={
              <img
                style={disasterRecoveryStyles.drpCode.alertSize}
                src={ErrorIcon}
                alt="error"
              />
            }
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage(null);
                }}
              >
                <GridCloseIcon />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Box>
      )}
      {loading && <CircularLoaderFullPage loading={loading} />}
      <Stack
        gap={spacing.gap}
        ml={spacing.gap}
        sx={disasterRecoveryStyles.requestGeneratorStyles.rightPanelWrapper}
      >
        <Typography
          sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
          mb={spacing.gap10}
        >
          {t("disasterRecovery.eventDetail.drpCode.selectByDrpCode")}
        </Typography>
        <Typography mb={spacing.gap10}>{subTitle}</Typography>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            clearIcon={<GridCloseIcon />}
            id="drpCode"
            name="drpCode"
            disabled={drpCodeData?.length === errorMsgs.noError}
            options={drpCodeData}
            onChange={handleDrpCode}
            getOptionLabel={(option) => option.drp_code}
            value={drpCode}
            size="medium"
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("disasterRecovery.eventDetail.drpCode.drpCode")}
              />
            )}
          />
        </Grid>
        <Divider />
        <Typography
          sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
          mb={spacing.gap10}
        >
          {t("disasterRecovery.eventDetail.drpCode.drpCodeTitle")}
        </Typography>
        <Typography mb={spacing.gap10}>
          {t("disasterRecovery.eventDetail.drpCode.drpInstructions")}
        </Typography>

        <MUIDataTable
          options={options}
          columns={columnOpenMedia}
          data={drpCodeList}
          title={
            <Typography>
              {drpCodeList?.filter((obj) => obj.selected)?.length > 0 &&
                drpCodeList?.filter((obj) => obj.selected)?.length +
                  " " +
                  t("disasterRecovery.eventDetail.drpCode.openMediaSelected")}
            </Typography>
          }
        />
      </Stack>
      <Box
        sx={disasterRecoveryStyles.tableStyles.displayFlex}
        gap={spacing.gap}
        mt={spacing.gap}
        ml={spacing.gap}
      >
        <>
          <Button
            variant="outlined"
            id="add-request"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            disabled={drpCodeList?.filter((obj) => obj.selected)?.length === 0}
            onClick={handelAddRequest}
          >
            {t("disasterRecovery.eventDetail.addToRequest")}
          </Button>
          <Button
            variant="outlined"
            id="clear-selection"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            disabled={!(getSelectedOpenMediaCount() !== zero)}
            onClick={handelClearSelected}
          >
            {t("disasterRecovery.eventDetail.clearSelected")}
          </Button>
          <Button
            variant="contained"
            id="review-generate-request"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            onClick={handelReviewAndGenerate}
          >
            {t("disasterRecovery.eventDetail.reviewGenerateRequest")}
          </Button>
        </>
      </Box>
    </>
  );
};

export default DrpCode;
