export const billCycleInvoiceId = {
  eom: 1,
  hOto: 4,
  oto: 3
};

export const sortByValues = [
  { label: "Catalog Item", value: "I" },
  { label: "Customer", value: "C" }
  // { label: "Count Sheet", value: 3 }
];

export const transactionTypes = {
  auto: "Auto",
  man: "Man",
  adj: "Adj"
};

export const transactionTypeIds = {
  man: "1",
  auto: "2",
  adj: "3"
};

export const columns = [
  { id: "i", label: "", visible: true },
  {
    id: "customer",
    label: "CUSTOMER",
    visible: true
  },
  {
    id: "type",
    label: "TYPE",
    visible: true
  },
  {
    id: "service",
    label: "SERVICE",
    visible: true
  },
  {
    id: "catalogItem",
    label: "CATALOG ITEM",
    visible: true
  },
  {
    id: "description",
    label: "DESCRIPTION",
    visible: true
  },
  {
    id: "logicalVault",
    label: "LOGICAL VAULT",
    visible: true
  },
  {
    id: "quantity",
    label: "QUANTITY",
    visible: true
  },
  {
    id: "unitPrice",
    label: "UNIT PRICE",
    visible: true
  },
  {
    id: "po",
    label: "PO",
    visible: true
  },
  {
    id: "priorMonth1",
    label: "PRIOR MONTH 1",
    visible: true
  },
  {
    id: "priorMonth2",
    label: "PRIOR MONTH 2",
    visible: true
  },
  {
    id: "variance",
    label: "VARIANCE",
    visible: true
  },
  {
    id: "flatFee",
    label: "FLAT FEE",
    visible: true
  },
  {
    id: "printAdj",
    label: "PRINT ADJ",
    visible: true
  },
  {
    id: "error",
    label: "ERROR",
    visible: true
  },
  {
    id: "requestType",
    label: "REQUEST TYPE",
    visible: true
  },
  {
    id: "tripType",
    label: "TRIP TYPE",
    visible: true
  }
];

export const dateFormats = {
  dash: "YYYY-MM-DD",
  slash: "MM/DD/YYYY"
};

export const numValues = {
  thousand: "1000",
  thousandPlus1: "1001"
};

export const billingSystems = {
  arcVMS: "ArcVMS"
};

export const billingRunStatusIds = {
  third: "3",
  fifth: "5",
  sixth: "6"
};

export const messageCodes = {
  value10183: "10183",
  value10001: "10001",
  value20359: "20359",
  value20235: "20235",
  value20554: "20554"
};

export const defaultUnitPrice = "0.00000";
export const unitPriceMaxBase = 9999999;
