import { Stack } from "@mui/material";
import RescheduleServiceForm from "components/features/request-module/reschedule-service/reschedule-service-form/RescheduleServiceForm";
import { PageTitle } from "components/shared";
import i18n from "i18n";
function RescheduleService() {
  return (
    <Stack spacing={2}>
      <PageTitle
        title={i18n.t("rescheduleService.rescheduleServicePageTitle")}
      />
      <RescheduleServiceForm />
    </Stack>
  );
}

export default RescheduleService;
