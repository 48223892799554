import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearError,
  updateAuthFormValues,
  updateSearchFormValues
} from "store/slices/container-processing";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  dayMonthYearFormat,
  numberConstants
} from "utils/constants";
import { AuditStyles } from "./auditStyles";

const AuditSearch = ({
  auditTypes,
  auditStatusList,
  reqList,
  handleReset,
  isFindButtonDisabled,
  allCustomers,
  setIsFindButtonDisabled,
  minDate,
  maxDate,
  today,
  getAuditTypeList,
  onFindClick,
  isAuthenticated,
  authCustomers,
  initialAuthValues
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchFormValues } = useSelector(
    (state) => state.containerAuditSearch
  );

  const clearAuthForm = () => {
    if (!isAuthenticated) {
      dispatch(updateAuthFormValues(initialAuthValues));
    }
  };
  const handleInputChange = (name, value) => {
    const updatedValues = {
      auditId: "",
      [name]: value
    };
    dispatch(updateSearchFormValues(updatedValues));
    dispatch(clearError());
    clearAuthForm();
  };

  const handleReqChange = (newValue) => {
    const updatedValues = {
      ...searchFormValues,
      auditId: "",
      reqBy: newValue,
      auditType: ALL_SELECTION
    };
    dispatch(updateSearchFormValues(updatedValues));
    getAuditTypeList(newValue?.value);
    dispatch(clearError());
    clearAuthForm();
  };

  const handleAuditTypeChange = (newValue) => {
    const updatedValues = {
      ...searchFormValues,
      auditId: "",
      auditType: newValue
    };
    if (newValue.value === numberConstants.four) {
      updatedValues.customer = "";
    }
    dispatch(updateSearchFormValues(updatedValues));
    dispatch(clearError());
    clearAuthForm();
  };

  const onCustomerChange = (event, value) => {
    dispatch(clearError());
    const updatedValues = {
      auditId: "",
      customer: value
    };
    dispatch(updateSearchFormValues(updatedValues));
    dispatch(clearError());
    clearAuthForm();
  };

  return (
    <form>
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid item xs={3}>
              <TextField
                label={t("containerAuditSearch.auditId")}
                id="audit-id"
                fullWidth
                disabled={null}
                value={searchFormValues.auditId}
                onChange={(event, newValue) => {
                  const updatedValues = {
                    auditId: event.target.value
                  };
                  dispatch(updateSearchFormValues(updatedValues));
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
                inputProps={{ maxLength: 9 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="requested-by"
                disablePortal
                name={t("containerAuditSearch.requestedBy")}
                disabled={null}
                options={reqList}
                size="medium"
                value={searchFormValues.reqBy || t("common.all")}
                onChange={(event, newValue) => {
                  handleReqChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("containerAuditSearch.requestedBy")}
                  />
                )}
                sx={AuditStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="audit-type"
                disablePortal
                name={t("containerAuditSearch.auditType")}
                disabled={searchFormValues.reqBy === ALL_SELECTION}
                options={auditTypes}
                size="medium"
                value={searchFormValues.auditType || t("common.all")}
                onChange={(event, newValue) => {
                  handleAuditTypeChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("containerAuditSearch.auditType")}
                  />
                )}
                sx={AuditStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomersField
                disabled={
                  searchFormValues.auditType.value === numberConstants.four
                }
                options={isAuthenticated ? authCustomers : allCustomers}
                value={searchFormValues.customer}
                handleOnChange={onCustomerChange}
                label={t("containerAuditSearch.customer")}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="audit-status"
                disablePortal
                name={t("containerAuditSearch.auditStatus")}
                disabled={null}
                options={auditStatusList}
                size="medium"
                value={searchFormValues.auditStatus}
                onChange={(event, newValue) =>
                  handleInputChange("auditStatus", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("containerAuditSearch.auditStatus")}
                  />
                )}
                sx={AuditStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="audit-date"
                name="auditDate"
                label={t("containerAuditSearch.auditData")}
                value={
                  searchFormValues.auditDate || searchFormValues.auditTo || null
                }
                minDate={minDate}
                maxDate={today}
                format={dayMonthYearFormat}
                onChange={(value) => {
                  const updatedValues = {
                    ...searchFormValues,
                    auditDate: value
                  };
                  dispatch(updateSearchFormValues(updatedValues));
                  dispatch(clearError());
                }}
                sx={AuditStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="audit-to"
                name="auditTo"
                label={t("containerAuditSearch.auditDataTo")}
                value={
                  searchFormValues.auditTo || searchFormValues.auditDate || null
                }
                minDate={minDate}
                maxDate={today}
                format={dayMonthYearFormat}
                onChange={(value) => {
                  const updatedValues = {
                    ...searchFormValues,
                    auditTo: value
                  };
                  dispatch(updateSearchFormValues(updatedValues));
                  dispatch(clearError());
                }}
                sx={AuditStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="due-date"
                name="dueDate"
                label={t("containerAuditSearch.dueDate")}
                value={
                  searchFormValues.dueDate || searchFormValues.dueDateTo || null
                }
                minDate={minDate}
                maxDate={maxDate}
                format={dayMonthYearFormat}
                onChange={(value) => {
                  handleInputChange("dueDate", value);
                }}
                sx={AuditStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="due-to"
                name="dueDateTo"
                label={t("containerAuditSearch.dueDateTo")}
                value={
                  searchFormValues.dueDateTo || searchFormValues.dueDate || null
                }
                minDate={minDate}
                maxDate={maxDate}
                format={dayMonthYearFormat}
                onChange={(value) => {
                  handleInputChange("dueDateTo", value);
                }}
                sx={AuditStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="exceptionsOnly"
                    id="exceptions-only"
                    size="small"
                    color="primary"
                    style={AuditStyles?.checkBoxStyle}
                    checked={searchFormValues.exceptionsOnly}
                    onChange={(event) => {
                      if (event && event?.target) {
                        const updatedValues = {
                          auditId: "",
                          exceptionsOnly: event?.target?.checked
                        };
                        dispatch(updateSearchFormValues(updatedValues));
                        dispatch(clearError());
                        clearAuthForm();
                      }
                    }}
                  />
                }
                label={t("containerAuditSearch.exceptionsOnly")}
                sx={AuditStyles.fieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid id="clear-button-grid" item xs="auto">
              <Button
                id="clear-button"
                variant="outlined"
                type="button"
                onClick={handleReset}
              >
                {t("common.clear")}
              </Button>
            </Grid>
            <Grid id="find-button-grid" item xs="auto">
              <Button
                id="find-button"
                variant="contained"
                type="button"
                disabled={isFindButtonDisabled}
                onClick={onFindClick}
              >
                {t("common.find")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AuditSearch;
