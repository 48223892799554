import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import CreateOutlinedIcon from "assets/images/pencil.svg";
import {
  TABLE_ROWS_PER_PAGE,
  TABLE_ROWS_PER_PAGE_DEFAULT
} from "components/features/request-module/media-destruction-request-vault-itemized/Constants";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { NO, YES, Y_CHECKER } from "utils/constants";
import { recurringChargeTableColumns } from "utils/constants/biling-module/BillingRecurringChargeConstants";
import { dateFormatToMonthAndYear } from "utils/helpers";
import { billingTransactionsStyles } from "../billing-transactions/BillingTransactionsStyles";
import { billingRecurringChargeStyles } from "./BillingRecurringChargeStyles";

const BillingRecurringChargeTable = ({
  setIsCreating,
  setIsUpdating,
  updateList,
  newChargeList,
  resultSet,
  findCatalogItemByCode,
  setSelectedRow,
  localeByBranch,
  editingRowIndex,
  setEditingRowIndex,
  noResults,
  isFindEnabled,
  handlePrintClick
}) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_PER_PAGE_DEFAULT);

  const totalPages = Math.ceil(resultSet?.length / rowsPerPage) || 1;

  const handleJumpToPage = (event) => {
    setPage(Number(event.target.value));
  };

  const handleEditRow = (index) => {
    setIsUpdating(true);
    setIsCreating(false);

    const row = resultSet[index];
    row && setSelectedRow(row);

    setEditingRowIndex(index);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event?.target?.value);
    setPage(0);
  };

  useEffect(() => {
    setEditingRowIndex(null);
    setPage(0);
    setRowsPerPage(TABLE_ROWS_PER_PAGE_DEFAULT);

    // eslint-disable-next-line
  }, [resultSet]);

  return (
    <Stack
      gap={spacing.gap}
      sx={
        billingRecurringChargeStyles.tableSearchResultsAndCacheStyles.mainStack
      }
    >
      <Stack id="mainTable">
        <Box padding={spacing.padding}>
          <Box
            sx={
              billingRecurringChargeStyles.tableSearchResultsAndCacheStyles
                .topButtonsBox
            }
          >
            <Box>
              {!isFindEnabled && (
                <Typography variant="subtitle1">
                  {resultSet?.length} {t("billingRecurringCharge.recordsFound")}
                </Typography>
              )}
            </Box>

            <Box
              sx={
                billingRecurringChargeStyles.tableSearchResultsAndCacheStyles
                  .nextButtonsBox
              }
            >
              <Button
                onClick={handlePrintClick}
                disabled={resultSet?.length === 0}
                variant="outlined"
                style={
                  billingRecurringChargeStyles.tableSearchResultsAndCacheStyles
                    .buttons
                }
              >
                {t("common.print")}
              </Button>

              <Button
                variant="outlined"
                style={
                  billingRecurringChargeStyles.tableSearchResultsAndCacheStyles
                    .buttons
                }
                onClick={() => {
                  setIsCreating(true);
                  setIsUpdating(false);
                }}
                id="newchargebtn"
              >
                {t("billingRecurringCharge.newCharge")}
              </Button>
            </Box>
          </Box>
        </Box>

        <Paper
          style={
            billingRecurringChargeStyles.tableSearchResultsAndCacheStyles.paper
          }
        >
          <div style={billingRecurringChargeStyles.commonStyles.widthFull}>
            <TableContainer>
              <Table>
                <TableHead
                  style={
                    billingRecurringChargeStyles
                      .tableSearchResultsAndCacheStyles.tableHead
                  }
                >
                  <TableRow>
                    {recurringChargeTableColumns?.map(
                      (column, index) =>
                        column?.visible && (
                          <TableCell
                            key={column?.id}
                            align="left"
                            sx={
                              billingRecurringChargeStyles
                                .tableSearchResultsAndCacheStyles.tableCell
                            }
                            size="small"
                          >
                            <Box
                              id={`mainTableHead${index}`}
                              sx={
                                billingRecurringChargeStyles
                                  .tableSearchResultsAndCacheStyles.tableTitle
                              }
                            >
                              {column?.label}
                            </Box>
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!noResults && resultSet.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Stack
                          sx={{
                            ...billingRecurringChargeStyles.commonStyles
                              .widthFull,
                            ...billingRecurringChargeStyles.commonStyles
                              .alignCenter
                          }}
                        >
                          {t("billingRecurringCharge.tableBeforeClickFind")}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}

                  {noResults && resultSet.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Stack
                          sx={{
                            ...billingRecurringChargeStyles.commonStyles
                              .widthFull,
                            ...billingRecurringChargeStyles.commonStyles
                              .alignCenter
                          }}
                        >
                          {t("common.noDataFound")}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}

                  {(rowsPerPage > 0
                    ? resultSet?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : resultSet
                  )?.map((row, index) => (
                    <TableRow
                      key={index}
                      selected={editingRowIndex === index}
                      sx={
                        billingRecurringChargeStyles
                          .tableSearchResultsAndCacheStyles.tableRow
                      }
                    >
                      <TableCell size="small">
                        <Box
                          sx={
                            billingRecurringChargeStyles
                              .tableSearchResultsAndCacheStyles.tableCellBox
                          }
                        >
                          <Box
                            id={`mainTableEditBtn${index}`}
                            sx={
                              billingRecurringChargeStyles.commonStyles
                                .pointerCursor
                            }
                            onClick={() =>
                              editingRowIndex === null && handleEditRow(index)
                            }
                          >
                            <img
                              src={CreateOutlinedIcon}
                              alt={t(
                                "billingRecurringCharge.createOutlinedIconAlt"
                              )}
                            />
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell size="small">{row.number}</TableCell>
                      <TableCell size="small">
                        {findCatalogItemByCode(row.catalog_item_code)}
                      </TableCell>
                      <TableCell size="small">{row.quantity}</TableCell>
                      <TableCell size="small">{row.descr}</TableCell>
                      <TableCell size="small">
                        {dateFormatToMonthAndYear(
                          row.first_billing_month,
                          localeByBranch
                        )}
                      </TableCell>
                      <TableCell size="small">
                        {dateFormatToMonthAndYear(
                          row.final_billing_month,
                          localeByBranch
                        )}
                      </TableCell>
                      <TableCell size="small">
                        {row.monthly_review_flag === Y_CHECKER ? YES : NO}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Box
            sx={
              billingTransactionsStyles.tableSearchResultsAndCacheStyles
                .tableFooterBox
            }
          >
            <Typography
              sx={
                billingTransactionsStyles.tableSearchResultsAndCacheStyles
                  .tableFooterTypo
              }
            >
              {t("common.jumpToPage")}
            </Typography>
            <FormControl
              size="small"
              sx={
                billingTransactionsStyles.tableSearchResultsAndCacheStyles
                  .tableFooterFormControl
              }
            >
              <Select
                value={page}
                onChange={handleJumpToPage}
                sx={
                  billingTransactionsStyles.tableSearchResultsAndCacheStyles
                    .tableFooterSelect
                }
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <MenuItem key={index} value={index}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TablePagination
              style={
                billingRecurringChargeStyles.tableSearchResultsAndCacheStyles
                  .tablePagination
              }
              rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              component="div"
              count={resultSet?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default BillingRecurringChargeTable;
