import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import CreateOutlinedIcon from "assets/images/pencil.svg";

import TablePagination from "@mui/material/TablePagination";
import {
  TABLE_ROWS_PER_PAGE,
  TABLE_ROWS_PER_PAGE_DEFAULT
} from "components/features/request-module/media-destruction-request-vault-itemized/Constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { VALUE_EMPTY_STRING, VALUE_Y } from "utils/constants";
import { quantityLimit } from "utils/constants/biling-module/BillingRecurringChargeConstants";
import {
  defaultUnitPrice,
  transactionTypes
} from "utils/constants/biling-module/BillingTransactionsConstants";
import { formatNumberByLocale, formatNumberWithCommas } from "utils/helpers";
import { billingRecurringChargeStyles } from "../billing-recurring-charge/BillingRecurringChargeStyles";
import { billingTransactionsStyles } from "./BillingTransactionsStyles";

const BillingTransactionsTable = ({
  resultSet,
  setSelectedRow,
  selectedRow,
  isClearClicked,
  setIsClearClicked,
  editingRowIndex,
  setEditingRowIndex,
  noResults,
  setIsCreating,
  setIsUpdating,
  isNewBtnDisabled,
  handleNewButtonClick,
  tableColumns,
  handleAdjustButtonClick,
  newlyCreated,
  unitPrincePrecision,
  isFindButtonClicked,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handlePrintClick,
  disableAll,
  setSelectedIndex
}) => {
  const { t } = useTranslation();
  const { localeByBranch } = useSelector(selectAuth);

  const totalPages = Math.ceil(resultSet?.length / rowsPerPage) || 1;

  const handleJumpToPage = (event) => {
    setPage(Number(event.target.value));
  };

  const handleRowClick = (row, index) => {
    setSelectedRow(row);
    setSelectedIndex(index);
  };

  const handleEditRow = (index) => {
    index = index + page * rowsPerPage;

    const row = resultSet[index];
    row && setSelectedRow(row);

    setIsUpdating(true);

    setEditingRowIndex(index);
    setSelectedIndex(index);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event?.target?.value);
    setPage(0);
  };

  useEffect(() => {
    if (resultSet?.length > 0) {
      const row = resultSet[0];
      row && setSelectedRow(row);
      setEditingRowIndex(null);
    }
    // eslint-disable-next-line
  }, [resultSet]);

  useEffect(() => {
    if (isClearClicked) {
      setEditingRowIndex(null);
      setSelectedIndex(null);

      setPage(0);
      setRowsPerPage(TABLE_ROWS_PER_PAGE_DEFAULT);

      setIsClearClicked(false);
    }
    // eslint-disable-next-line
  }, [isClearClicked]);

  const calculateVariance = (quantity, varianceThreshold, priorMonth1) => {
    /**
     * Calculate variance(ebtcvariance)
     *
     * quantity empty && VarianceThreshold empty
     *  Variance = 0
     * Else if(PriorMonth1  empty )
     *  Variance = 1
     * Else
     *  Variance = ((PriorMonth1 - quantity)/ PriorMonth1 ) * -1
     */

    if (
      quantity === VALUE_EMPTY_STRING ||
      varianceThreshold === VALUE_EMPTY_STRING
    ) {
      return 0;
    } else if (
      priorMonth1 === VALUE_EMPTY_STRING ||
      Number(priorMonth1) === 0
    ) {
      return 1;
    } else {
      return (
        ((Number(priorMonth1) - Number(quantity)) / Number(priorMonth1)) * -1
      );
    }
  };

  // eslint-disable-next-line
  const colorValidator = (row) => {
    const variance = calculateVariance(
      row?.current_quantity,
      row?.variance_threshold_pct,
      row?.prev_1_quantity
    );

    /**
     * Row highlight in Red
     * (Type !== ‘Adj’  && ((Variance * 100 > VarianceThreshold)  ||
     * ( Variance< 0 && Variance * -100 > VarianceThreshold)))
     */

    const colored =
      row?.transaction_type_code?.trim() !== transactionTypes.adj &&
      (variance * 100 > Number(row?.variance_threshold_pct) ||
        (variance < 0 && variance * -100 > Number(row?.variance_threshold_pct)))
        ? "var(--color-im-red)"
        : "var(--color-black)";

    return colored;
  };

  const formatValue = (inputValue) => {
    let num = parseFloat(inputValue?.replace(/,/g, VALUE_EMPTY_STRING));
    if (isNaN(num)) {
      return VALUE_EMPTY_STRING;
    }
    num = Math.min(num, quantityLimit);
    num = Math.round(num * 100) / 100;
    return formatNumberByLocale(num, localeByBranch);
  };

  const isColumnVisible = (id) => {
    const column = tableColumns?.find((c) => c.id === id);
    return column ? column.visible : false;
  };

  const integerStringToDecimalString = (number) => {
    return parseFloat(number)?.toFixed(2) || VALUE_EMPTY_STRING;
  };

  return (
    <>
      <Stack
        gap={spacing.gap}
        sx={
          billingTransactionsStyles.tableSearchResultsAndCacheStyles.mainStack
        }
      >
        <Stack id="mainTable">
          <Box padding={spacing.padding}>
            <Box
              sx={
                billingTransactionsStyles.tableSearchResultsAndCacheStyles
                  .topButtonsBox
              }
            >
              <Box>
                {isFindButtonClicked && (
                  <Typography variant="subtitle1">
                    {resultSet?.length}{" "}
                    {t("billingRecurringCharge.recordsFound")}
                  </Typography>
                )}
              </Box>

              <Box
                sx={
                  billingTransactionsStyles.tableSearchResultsAndCacheStyles
                    .nextButtonsBox
                }
              >
                <Button
                  variant="outlined"
                  style={
                    billingTransactionsStyles.tableSearchResultsAndCacheStyles
                      .buttons
                  }
                  disabled={
                    selectedRow
                      ? !(
                          !disableAll &&
                          selectedRow?.current_quantity?.trim() !==
                            VALUE_EMPTY_STRING &&
                          selectedRow?.transaction_type_code?.trim() !==
                            transactionTypes.adj &&
                          selectedRow?.flat_fee_flag?.trim() !== VALUE_Y
                        )
                      : true
                  }
                  onClick={handleAdjustButtonClick}
                >
                  {t("billingTransactions.adjustmentBtn")}
                </Button>

                <Button
                  variant="outlined"
                  style={
                    billingTransactionsStyles.tableSearchResultsAndCacheStyles
                      .buttons
                  }
                  disabled={
                    selectedRow
                      ? !disableAll &&
                        selectedRow?.billing_transaction_id?.trim() !==
                          newlyCreated?.billing_transaction_id?.trim()
                      : true
                  }
                  onClick={() => {
                    setIsCreating({
                      new: false,
                      adjust: false,
                      repeat: true
                    });
                  }}
                >
                  {t("billingTransactions.repeat")}
                </Button>

                <Button
                  variant="outlined"
                  style={
                    billingTransactionsStyles.tableSearchResultsAndCacheStyles
                      .buttons
                  }
                  disabled={isNewBtnDisabled}
                  onClick={handleNewButtonClick}
                  id="newchargebtn"
                >
                  {t("common.new")}
                </Button>

                <Button
                  onClick={handlePrintClick}
                  variant="outlined"
                  disabled={resultSet?.length === 0}
                  style={
                    billingTransactionsStyles.tableSearchResultsAndCacheStyles
                      .buttons
                  }
                >
                  {t("common.print")}
                </Button>
              </Box>
            </Box>
          </Box>

          <Paper
            style={
              billingTransactionsStyles.tableSearchResultsAndCacheStyles.paper
            }
          >
            <div style={billingRecurringChargeStyles.commonStyles.widthFull}>
              <TableContainer>
                <Table>
                  <TableHead
                    style={
                      billingTransactionsStyles.tableSearchResultsAndCacheStyles
                        .tableHead
                    }
                  >
                    <TableRow>
                      {tableColumns?.map(
                        (column, index) =>
                          column?.visible && (
                            <TableCell
                              key={column?.id}
                              align="left"
                              sx={
                                billingTransactionsStyles
                                  .tableSearchResultsAndCacheStyles.tableCell
                              }
                              size="small"
                            >
                              <Box
                                id={`mainTableHead${index}`}
                                sx={
                                  billingRecurringChargeStyles
                                    .tableSearchResultsAndCacheStyles.tableTitle
                                }
                              >
                                {column?.label}
                              </Box>
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!noResults && resultSet?.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={
                            tableColumns?.filter((col) => col.visible === true)
                              ?.length || 0
                          }
                        >
                          <Stack
                            sx={{
                              ...billingTransactionsStyles.commonStyles
                                .widthFull,
                              ...billingTransactionsStyles.commonStyles
                                .alignCenter
                            }}
                          >
                            {t("billingRecurringCharge.tableBeforeClickFind")}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}

                    {noResults && resultSet?.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={
                            tableColumns?.filter((col) => col.visible === true)
                              ?.length || 0
                          }
                        >
                          <Stack
                            sx={{
                              ...billingTransactionsStyles.commonStyles
                                .widthFull,
                              ...billingTransactionsStyles.commonStyles
                                .alignCenter
                            }}
                          >
                            {t("common.noDataFound")}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}

                    {(rowsPerPage > 0
                      ? resultSet?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : resultSet
                    )?.map((row, index) => {
                      const colored = colorValidator(row);

                      return (
                        <TableRow
                          onClick={() => handleRowClick(row, index)}
                          key={index}
                          selected={editingRowIndex === index}
                          sx={{
                            backgroundColor:
                              selectedRow &&
                              selectedRow?.billing_transaction_id?.trim() ===
                                row?.billing_transaction_id?.trim()
                                ? "var(--color-im-light-blue-100)"
                                : VALUE_EMPTY_STRING,

                            ...billingTransactionsStyles
                              .tableSearchResultsAndCacheStyles.tableRow
                          }}
                        >
                          <TableCell size="small" sx={{ color: colored }}>
                            <Box
                              sx={
                                billingTransactionsStyles
                                  .tableSearchResultsAndCacheStyles.tableCellBox
                              }
                            >
                              {!disableAll && (
                                <Box
                                  id={`mainTableEditBtn${index}`}
                                  sx={
                                    billingTransactionsStyles.commonStyles
                                      .pointerCursor
                                  }
                                  onClick={() =>
                                    editingRowIndex === null &&
                                    handleEditRow(index)
                                  }
                                >
                                  <img
                                    src={CreateOutlinedIcon}
                                    alt={t(
                                      "billingRecurringCharge.createOutlinedIconAlt"
                                    )}
                                  />
                                </Box>
                              )}
                            </Box>
                          </TableCell>

                          {/* Customer */}
                          {isColumnVisible("customer") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.customer_number}
                            </TableCell>
                          )}
                          {/* Type */}
                          {isColumnVisible("type") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.transaction_type_code}
                            </TableCell>
                          )}
                          {/* Service */}
                          {isColumnVisible("service") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.billing_catalog_code}
                            </TableCell>
                          )}
                          {/* Catalog Item */}
                          {isColumnVisible("catalogItem") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.catalog_item_code}
                            </TableCell>
                          )}
                          {/* Description */}
                          {isColumnVisible("description") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.bill_trans_descr}
                            </TableCell>
                          )}
                          {/* Logical Vault */}
                          {isColumnVisible("logicalVault") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.logical_vault_code}
                            </TableCell>
                          )}
                          {/* Quantity */}
                          {isColumnVisible("quantity") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {formatValue(row?.current_quantity?.trim())}
                            </TableCell>
                          )}
                          {/* Unit Price */}
                          {isColumnVisible("unitPrice") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.unit_price !== undefined &&
                              row?.unit_price !== null &&
                              row?.unit_price.trim() !== VALUE_EMPTY_STRING
                                ? formatNumberWithCommas(
                                    row?.unit_price?.trim(),
                                    unitPrincePrecision
                                  )
                                : formatNumberWithCommas(
                                    defaultUnitPrice,
                                    unitPrincePrecision
                                  )}
                            </TableCell>
                          )}
                          {/* PO */}
                          {isColumnVisible("po") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.po_number}
                            </TableCell>
                          )}
                          {/* Prior Month 1 */}
                          {isColumnVisible("priorMonth1") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.prev_1_quantity &&
                              row?.prev_1_quantity?.trim() !==
                                VALUE_EMPTY_STRING
                                ? integerStringToDecimalString(
                                    row?.prev_1_quantity?.trim()
                                  )
                                : integerStringToDecimalString("0")}
                            </TableCell>
                          )}
                          {/* Prior Month 2 */}
                          {isColumnVisible("priorMonth2") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.prev_2_quantity &&
                              row?.prev_2_quantity?.trim() !==
                                VALUE_EMPTY_STRING
                                ? integerStringToDecimalString(
                                    row?.prev_2_quantity?.trim()
                                  )
                                : integerStringToDecimalString("0")}
                            </TableCell>
                          )}
                          {/* Variance */}
                          {isColumnVisible("variance") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {`${(
                                calculateVariance(
                                  row?.current_quantity?.trim(),
                                  row?.variance_threshold_pct?.trim(),
                                  row?.prev_1_quantity?.trim()
                                ) * 100
                              ).toFixed(2)}%`}
                            </TableCell>
                          )}
                          {/* Flat Fee */}
                          {isColumnVisible("flatFee") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.flat_fee_flag}
                            </TableCell>
                          )}
                          {/* Print Adj */}
                          {isColumnVisible("printAdj") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.print_adjustments_flag}
                            </TableCell>
                          )}
                          {/* Error */}
                          {isColumnVisible("error") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.message_descr}
                            </TableCell>
                          )}
                          {/* Request Type  */}
                          {isColumnVisible("requestType") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.request_type_descr}
                            </TableCell>
                          )}
                          {/* Trip Type */}
                          {isColumnVisible("tripType") && (
                            <TableCell size="small" sx={{ color: colored }}>
                              {row?.trip_type_descr}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <Box
              sx={
                billingTransactionsStyles.tableSearchResultsAndCacheStyles
                  .tableFooterBox
              }
            >
              <Typography
                sx={
                  billingTransactionsStyles.tableSearchResultsAndCacheStyles
                    .tableFooterTypo
                }
              >
                {t("common.jumpToPage")}
              </Typography>
              <FormControl
                size="small"
                sx={
                  billingTransactionsStyles.tableSearchResultsAndCacheStyles
                    .tableFooterFormControl
                }
              >
                <Select
                  value={page}
                  onChange={handleJumpToPage}
                  sx={
                    billingTransactionsStyles.tableSearchResultsAndCacheStyles
                      .tableFooterSelect
                  }
                >
                  {Array.from({ length: totalPages }, (_, index) => (
                    <MenuItem key={index} value={index}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TablePagination
                style={
                  billingRecurringChargeStyles.tableSearchResultsAndCacheStyles
                    .tablePagination
                }
                rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                component="div"
                count={resultSet?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default BillingTransactionsTable;
