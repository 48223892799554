import {
  cancelMDRVaultItemized,
  createMDRVaultItemized,
  getAllDestructMedia,
  getAllDestructMediaTypes,
  getAuthorizationPersonnels,
  getDestructRequestDetail,
  getExistingVaultItemizedMDR,
  getPersonnelAuth,
  getPersonnelSecurity,
  getPossibleMatchesList,
  getUnitOfMeasure,
  getValidateOpenMediaList,
  updateMDRVaultItemized
} from "services/common";
import { TAG_TYPES } from "utils/constants";
import { baseApi } from "../baseApi";

/**
 * @module vaultItemizedApi vault-itemized RTK Query endpoints
 */
export const vaultItemizedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPersonnel: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAuthorizationPersonnels(props);
          response?.sort((a, b) => a?.name.localeCompare(b?.name));
          return {
            data: response?.map(({ personnel_id, name }) => ({
              label: name,
              value: personnel_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customer]
    }),
    getPersonnelAuth: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getPersonnelAuth(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customer]
    }),
    getAllDestructMedia: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllDestructMedia(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customer]
    }),
    getUnitOfMeasure: builder.query({
      queryFn: async () => {
        try {
          const response = await getUnitOfMeasure();
          return {
            data: response?.map(({ unit_of_measure_id, display_descr }) => ({
              label: display_descr,
              value: unit_of_measure_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.vaultItemized]
    }),
    createMDRVaultItemized: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await createMDRVaultItemized(props);
          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.vaultItemized]
    }),
    getExistingVaultItemizedMDR: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getExistingVaultItemizedMDR(props);
          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.vaultItemized]
    }),
    getPersonnelSecurity: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getPersonnelSecurity(props);
          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.vaultItemized]
    }),
    cancelMDRVaultItemized: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await cancelMDRVaultItemized(props);
          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.vaultItemized]
    }),
    updateMDRVaultItemized: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await updateMDRVaultItemized(props);
          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.vaultItemized]
    }),
    getDestructRequestDetail: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getDestructRequestDetail(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customer]
    }),
    getValidateOpenMediaList: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getValidateOpenMediaList(props);

          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.vaultItemized]
    }),
    getPossibleMatchesList: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getPossibleMatchesList(props);

          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.vaultItemized]
    }),
    getAllDestructMediaTypes: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllDestructMediaTypes(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customer]
    })
  })
});
