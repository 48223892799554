import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";

import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useSelector } from "react-redux";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getAppSetting, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  VALUE_N,
  VALUE_Y,
  errorMsgs
} from "utils/constants";
import { labelPrinterSetup } from "utils/constants/label-printer-setup/LabelPrinterSetupConstant";
import {
  findErrorMessage,
  formatNumberByLocale,
  getAuthenticatedUserBranch
} from "utils/helpers";
import { labelPrinterSetupStyles } from "./LabelPrinterSetupStyles";
const initialPrinterJson = {
  printerName: "",
  ipAddress: "",
  labelStockId: "",
  description: "",
  leftOffset: "",
  topOffset: "",
  darkness: ""
};
const LabelPrinterSetupScreen = ({ loca }) => {
  const [newPrinterChange, setNewPrinterChange] = useState(false);
  const [openDeleteAlertModal, setDeleteAlertModal] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [printerNameData, setPrinterNameData] = useState([]);
  const [labelStockData, setLabelStockData] = useState([]);
  const [printerJson, setPrinterJson] = useState(initialPrinterJson);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [successMsg, setSuccessMsg] = useState(false);
  const [throwSuccessMessage, setThrowSuccessMessage] = useState(null);
  const [isLebelTestAlert, setLabelTestAlert] = useState(false);
  const [checkIpAddressChange, setCheckIpAddressChange] = useState("");
  const [labelTestMsg, setLabelTestMsg] = useState("");
  const { localeByBranch } = useSelector(selectAuth);

  const callCloudFunction = async (reqBody, url) => {
    const response = await callToCloudFunction(reqBody, url);
    return response;
  };

  const deleteModal = () => {
    if (printerNameData.length === 1) {
      setThrowError(true);
      setThrowErrorMessage(t("labelPrinterSetup.cannotDeletePrinter"));
    } else {
      setDeleteAlertModal(true);
    }
  };

  const getPrinterNameList = useCallback(async () => {
    try {
      setLoading(true);
      const districtId = getAuthenticatedUserBranch();
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_printer_id: "",
        district_id: districtId
      };
      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.printerNamelist}`
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      setErrorMessage({});
      let errorLogData = data?.data[0];
      if (errorLogData) {
        let sortPrinterData = errorLogData.sort((a, b) =>
          a.short_name.localeCompare(b.short_name)
        );
        setPrinterNameData(sortPrinterData);
        setLoading(false);
        const printerData = data?.data[0][0];
        setCheckIpAddressChange(printerData?.ip_address);
        setPrinterJson({
          printerName: printerData?.label_printer_id,
          ipAddress: printerData?.ip_address,
          labelStockId: printerData?.label_stock_id,
          leftOffset: printerData?.left_offset_mm,
          topOffset: printerData?.top_offset_mm,
          darkness: printerData?.darkness_adjustment,
          description: printerData?.location_description
        });
        return data?.data[0];
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  }, []);

  const getLabelStockList = useCallback(async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_stock_id: ""
      };
      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.labelStockList}`
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      if (data?.data[0]) {
        setLabelStockData(data?.data[0]);
        setLoading(false);
      } else if (data?.error) {
        setLoading(false);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  }, []);

  const newPrinter = () => {
    setIsEdit(true);
    setErrorMessage({});
    setThrowErrorMessage(null);
    setThrowError(false);
    setNewPrinterChange(true);
    setPrinterJson({ leftOffset: "0.0", topOffset: "0.0", darkness: "0.0" });
  };

  useEffect(() => {
    getPrinterNameList();
    getLabelStockList();
  }, [getPrinterNameList, getLabelStockList]);

  const cancel = () => {
    getPrinterNameList();
    setNewPrinterChange(false);
    setErrorMessage({});
    setThrowError(false);
    setThrowErrorMessage(null);
  };

  const validatePrinterFields = () => {
    const regexIp = labelPrinterSetup.regex.regexIp;
    const errors = {};
    if (!printerJson.printerName) {
      errors.printerName = t("labelPrinterSetup.printerNameReqErrMsg");
    }

    if (
      printerJson.printerName &&
      printerJson.printerName.length > labelPrinterSetup.printerNameLimit
    ) {
      errors.printerName = t("labelPrinterSetup.printerNameSizeErrMsg");
    }
    if (!printerJson.ipAddress) {
      errors.ipAddress = t("labelPrinterSetup.ipAddressReqErrMsg");
    } else if (
      !regexIp.test(printerJson.ipAddress) ||
      !printerJson.ipAddress
        .split(".")
        .every(
          (num) => num >= 0 && num <= labelPrinterSetup.ipAddressNumberCheck
        )
    ) {
      errors.ipAddress = t("labelPrinterSetup.ipAddressformatErrMsg");
    }
    if (!printerJson.labelStockId) {
      errors.labelStock = t("labelPrinterSetup.labelStockReqErrMsg");
    }

    if (!printerJson.description) {
      errors.description = t("labelPrinterSetup.descriptionReqErrMsg");
    }
    if (
      printerJson.description &&
      printerJson.description.length >
        labelPrinterSetup.descrptionCharacterLimit
    ) {
      errors.description = t("labelPrinterSetup.descriptionSizeErrMsg");
    }
    const offsetRegex = /^-?\d*\.?\d*$/; // Regular expression to match numbers and decimal numbers

    if (!offsetRegex.test(printerJson.leftOffset)) {
      errors.leftOffset = t("labelPrinterSetup.leftOffsetErr");
    }
    if (!offsetRegex.test(printerJson.topOffset)) {
      errors.topOffset = t("labelPrinterSetup.topOffsetErr");
    }
    if (!offsetRegex.test(printerJson.darkness)) {
      errors.darkness = t("labelPrinterSetup.darknessErr");
    }
    return errors;
  };
  const offsetRoundOff = (inputValue) => {
    let numericValue = parseFloat(inputValue);
    if (numericValue >= labelPrinterSetup.maxOffset) {
      numericValue = labelPrinterSetup.offsetMax;
    } else if (numericValue <= labelPrinterSetup.minOffset) {
      numericValue = labelPrinterSetup.offsetmin;
    }
    return (
      Math.floor(numericValue * labelPrinterSetup.maxOffset) /
      labelPrinterSetup.maxOffset
    ).toFixed(2);
  };
  const darknessRoundOff = (inputValue) => {
    let numericValue = parseFloat(inputValue);
    if (numericValue > labelPrinterSetup.maxDarknes) {
      numericValue = labelPrinterSetup.maxDarknes;
    } else if (numericValue <= labelPrinterSetup.minDarknes) {
      numericValue = labelPrinterSetup.minDarknes;
    }
    return (
      Math.floor(numericValue * labelPrinterSetup.maxOffset) /
      labelPrinterSetup.maxOffset
    ).toFixed(2);
  };
  const savePrinter = async () => {
    try {
      if (!printerJson || Object.keys(printerJson).length === 0) {
        setErrorMessage({
          description: t("labelPrinterSetup.descriptionReqErrMsg"),
          ipAddress: t("labelPrinterSetup.ipAddressReqErrMsg"),
          printerName: t("labelPrinterSetup.printerNameReqErrMsg"),
          labelStock: t("labelPrinterSetup.labelStockReqErrMsg")
        });
      } else {
        validatePrinterFields();
        if (Object.keys(validatePrinterFields()).length > 0) {
          setErrorMessage(validatePrinterFields());
        } else {
          setLoading(true);
          const districtId = getAuthenticatedUserBranch();
          const reqBody = {
            main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
            district_id: districtId,
            short_name: printerJson.printerName,
            ip_address: printerJson.ipAddress,
            port: "",
            location_description: printerJson.description,
            label_stock_id: printerJson.labelStockId,
            top_offset_mm: offsetRoundOff(printerJson.topOffset),
            left_offset_mm: offsetRoundOff(printerJson.leftOffset),
            darkness_adjustment: darknessRoundOff(printerJson.darkness),
            label_tested_flag: labelPrinterSetup.No,
            new_label_printer_id: ""
          };
          const response = await callCloudFunction(
            reqBody,
            `${CF_URLS.labelPrinterSetup.labelprinters}`
          );
          const data = await getDataFromFirestore(
            response,
            2,
            response.data.docId
          );
          if (data?.data[0][0]?.new_label_printer_id) {
            cancel();
            await getPrinterNameList().then((res) => {
              const printerData = res?.find(
                (obj) =>
                  obj.label_printer_id ===
                  data?.data[0][0]?.new_label_printer_id
              );
              setPrinterJson({
                printerName: printerData?.label_printer_id,
                ipAddress: printerData?.ip_address,
                labelStockId: printerData?.label_stock_id,
                leftOffset: printerData?.left_offset_mm,
                topOffset: printerData?.top_offset_mm,
                darkness: printerData?.darkness_adjustment,
                description: printerData?.location_description
              });
              if (printerJson.ipAddress === labelPrinterSetup.ipAddressCheck) {
                setThrowError(false);
                setThrowErrorMessage(null);
                setErrorMessage({});
                setLoading(false);
                setSuccessMsg(true);
                setThrowSuccessMessage(
                  t("labelPrinterSetup.successfulMessage")
                );
              } else {
                addPrinter(
                  printerData?.ip_address,
                  printerData?.label_printer_id,
                  districtId
                );
              }
            });
          } else {
            if (data.data[1][0].error === errorMsgs.errorCode2627) {
              const errDesc = await getMessageFromCode(
                errorMsgs.errorCode10085
              );
              setLoading(false);
              setThrowErrorMessage(errDesc[0]?.descr);
              setThrowError(true);
            } else {
              setThrowErrorMessage(t("labelPrinterSetup.failureMessage"));
              setThrowError(true);
              setLoading((prev) => false);
            }
          }
        }
      }
    } catch (error) {
      setThrowErrorMessage(t("labelPrinterSetup.failureMessage"));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  const findPrinterName = (value) => {
    const findPrinter = printerNameData.find(
      (obj) => obj.label_printer_id === value
    );
    return findPrinter.short_name;
  };

  const selectEnv = () => {
    if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.dev1) {
      return labelPrinterSetup.logicalNameEnv.dev1;
    } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.dev2) {
      return labelPrinterSetup.logicalNameEnv.dev2;
    } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.qa1) {
      return labelPrinterSetup.logicalNameEnv.qa1;
    } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.qa2) {
      return labelPrinterSetup.logicalNameEnv.qa2;
    } else if (process.env.REACT_APP_ENV === labelPrinterSetup.env.uat) {
      return labelPrinterSetup.logicalNameEnv.uat;
    } else {
      return "";
    }
  };
  const addPrinter = async (ip_address, label_printer_id, district_id) => {
    try {
      setLoading(true);
      const logicName = `sb${selectEnv()}-${label_printer_id}-${district_id}`;
      const templateType = await getAppSetting();
      const reqBody = {
        printerIP: ip_address,
        printerLogicalName: logicName,
        printerTemplateType: templateType
      };
      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.addPrinter}`
      );
      if (response?.data?.isSuccess) {
        setIsEdit(true);
        setThrowError(false);
        setThrowErrorMessage(null);
        setErrorMessage({});
        setLoading(false);
        setSuccessMsg(true);
        setThrowSuccessMessage(t("labelPrinterSetup.successfulMessage"));
      } else {
        setLoading(false);
        remove(false, label_printer_id);
        setThrowErrorMessage(t("labelPrinterSetup.failureMessage"));
        setThrowError(true);
        setLoading((prev) => false);
      }
    } catch (error) {
      if (
        error?.response?.data?.error[0]?.errorCode === errorMsgs.errorCode0212
      ) {
        setLoading(false);
        updatePrinter();
      } else {
        remove(false, label_printer_id);
        setThrowErrorMessage(t("labelPrinterSetup.failureMessage"));
        setThrowError(true);
        setLoading((prev) => false);
      }
    }
  };

  const deletePrinter = async (label_printer_id, district_id) => {
    try {
      const logicName = `sb${selectEnv()}-${label_printer_id}-${district_id}`;
      const reqBody = {
        printer: logicName
      };
      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.deleteCloudPrinter}`
      );
      if (response?.data?.isSuccess) {
        setThrowError(false);
        setThrowErrorMessage(null);
        setErrorMessage({});
        await getPrinterNameList();
        setLoading(false);
      } else {
        setLoading(false);
        setDeleteAlertModal(false);
      }
    } catch (error) {
      setDeleteAlertModal(false);
      setLoading((prev) => false);
    }
  };

  const updateCloudPrinter = async (
    ip_address,
    label_printer_id,
    district_id
  ) => {
    try {
      setLoading(true);
      const logicName = `sb${selectEnv()}-${label_printer_id}-${district_id}`;
      const reqBody = {
        printerIP: ip_address,
        printerLogicalName: logicName
      };
      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.updateCloudPrinter}`
      );
      if (response?.data?.isSuccess) {
        setIsEdit(true);
        setThrowError(false);
        setThrowErrorMessage(null);
        setErrorMessage({});
        setLoading(false);
        setSuccessMsg(true);
        setThrowSuccessMessage(t("labelPrinterSetup.successfulUpdateMessage"));
      } else {
        setThrowErrorMessage(t("labelPrinterSetup.failureMessageUpdate"));
        setThrowError(true);
        setLoading((prev) => false);
      }
    } catch (error) {
      if (
        error?.response?.data?.error[0]?.errorCode ===
          errorMsgs.errorCode0203 ||
        error?.response?.data?.error[0]?.errorCode === errorMsgs.errorCode0212
      ) {
        addPrinter(ip_address, label_printer_id, district_id);
      } else {
        setThrowErrorMessage(t("labelPrinterSetup.failureMessageUpdate"));
        setThrowError(true);
        setLoading((prev) => false);
      }
    }
  };

  const remove = async (deleteCheck, label_printer_id) => {
    try {
      const districtId = getAuthenticatedUserBranch();
      setLoading(true);
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_printer_id: deleteCheck
          ? printerJson.printerName
          : label_printer_id
      };

      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.deletePrinter}`
      );
      if (response.data.isSuccess) {
        setThrowError(false);
        setThrowErrorMessage(null);
        setErrorMessage({});
        setLoading(false);
        if (deleteCheck) {
          await getPrinterNameList();
          setLoading(false);
          setDeleteAlertModal(false);
          setIsEdit(true);
          setSuccessMsg(true);
          setThrowSuccessMessage(
            `${t("labelPrinterSetup.printer")} ${findPrinterName(
              printerJson.printerName
            )} ${t("labelPrinterSetup.successfullydeleted")}`
          );
          deletePrinter(printerJson.printerName, districtId);
        } else {
          getPrinterNameList();
        }
      } else {
        setThrowErrorMessage(t("labelPrinterSetup.printerDeleteFailure"));
        setThrowError(true);
        setLoading((prev) => false);
      }
    } catch (error) {
      setThrowErrorMessage(t("labelPrinterSetup.printerDeleteFailure"));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };
  const updatePrinter = async () => {
    const districtId = getAuthenticatedUserBranch();
    try {
      if (!printerJson || Object.keys(printerJson).length === 0) {
        setErrorMessage({
          description: t("labelPrinterSetup.descriptionReqErrMsg"),
          ipAddress: t("labelPrinterSetup.ipAddressReqErrMsg"),
          printerName: t("labelPrinterSetup.printerNameReqErrMsg"),
          labelStock: t("labelPrinterSetup.labelStockReqErrMsg")
        });
      } else {
        if (Object.keys(validatePrinterFields()).length > 0) {
          setErrorMessage(validatePrinterFields());
        } else {
          setLoading(true);
          const reqBody = {
            main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
            label_printer_id: printerJson.printerName,
            short_name: findPrinterName(printerJson.printerName),
            ip_address: printerJson.ipAddress,
            port: "",
            location_description: printerJson.description,
            label_stock_id: printerJson.labelStockId,
            top_offset_mm: offsetRoundOff(printerJson.topOffset),
            left_offset_mm: offsetRoundOff(printerJson.leftOffset),
            darkness_adjustment: darknessRoundOff(printerJson.darkness),
            label_tested_flag: labelPrinterSetup.No
          };
          const response = await callCloudFunction(
            reqBody,
            `${CF_URLS.labelPrinterSetup.updatePrinter}`
          );
          const data = await getDataFromFirestore(
            response,
            1,
            response?.data?.docId
          );
          if (data?.data[0]) {
            await getPrinterNameList().then((res) => {
              const printerData = res?.find(
                (obj) => obj.label_printer_id === printerJson.printerName
              );

              setCheckIpAddressChange(printerData?.ip_address);
              setPrinterJson({
                printerName: printerData?.label_printer_id,
                ipAddress: printerData?.ip_address,
                labelStockId: printerData?.label_stock_id,
                leftOffset: printerData?.left_offset_mm,
                topOffset: printerData?.top_offset_mm,
                darkness: printerData?.darkness_adjustment,
                description: printerData?.location_description
              });
              setIsEdit(true);
              if (printerJson.ipAddress !== checkIpAddressChange) {
                updateCloudPrinter(
                  printerData?.ip_address,
                  printerData?.label_printer_id,
                  districtId
                );
              } else {
                setLoading(false);
                setSuccessMsg(true);
                setThrowError(false);
                setThrowErrorMessage(null);
                setThrowSuccessMessage(
                  t("labelPrinterSetup.successfulUpdateMessage")
                );
              }
            });
          } else if (data?.error) {
            setLoading(false);
            setThrowError(true);
            setThrowErrorMessage(t("labelPrinterSetup.failureMessageUpdate"));
          }
        }
      }
    } catch (e) {
      setThrowErrorMessage(t("labelPrinterSetup.failureMessageUpdate"));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  const validatePrinterNameInput = (value) => {
    if (value.length === 0) {
      setErrorMessage((prev) => ({
        ...prev,
        printerName: t("labelPrinterSetup.printerNameReqErrMsg")
      }));
    } else if (value.length > labelPrinterSetup.printerNameLimit) {
      setErrorMessage((prev) => ({
        ...prev,
        printerName: t("labelPrinterSetup.printerNameSizeErrMsg")
      }));
    } else {
      setErrorMessage((prev) => ({ ...prev, printerName: "" }));
    }
  };
  const fetchTestLabelZpl = async (printer) => {
    const reqBody = {
      main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
      label_stock_id: printer.labelStockId
    };

    const response = await callCloudFunction(
      reqBody,
      `${CF_URLS.labelPrinterSetup.labelStockList}`
    );
    const data = await getDataFromFirestore(response, 1, response?.data?.docId);
    return data?.data[0][0];
  };
  const handleTestLabelClick = async () => {
    const regexIp = labelPrinterSetup.regex.regexIp;
    if (!printerJson.ipAddress) {
      setThrowError(true);
      setThrowErrorMessage(t("labelPrinterSetup.ipAddressReqErrMsg"));
      return;
    } else if (
      !regexIp.test(printerJson.ipAddress) ||
      !printerJson.ipAddress
        .split(".")
        .every(
          (num) => num >= 0 && num <= labelPrinterSetup.ipAddressNumberCheck
        )
    ) {
      setThrowError(true);
      setThrowErrorMessage(t("labelPrinterSetup.ipAddressformatErrMsg"));
      return;
    } else {
      setLoading(true);
      const response = await fetchTestLabelZpl(printerJson);
      const adjustedZpl = applyPrinterAdjustments(
        response.test_label_zpl,
        printerJson
      );
      if (printerJson.ipAddress !== labelPrinterSetup.ipAddressCheck) {
        await sendDataToPrinter(printerJson, adjustedZpl);
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          ipAddress: t("labelPrinterSetup.ignoretoPrint")
        }));
        setLoading(false);
      }
    }
  };
  const applyPrinterAdjustments = (testLabelZpl, printer) => {
    const leftOffSetDots = Math.floor(
      printer.leftOffset * labelPrinterSetup.dots
    );
    const topOffSetDots = Math.floor(
      printer.topOffset * labelPrinterSetup.dots
    );
    const pattern = labelPrinterSetup.regex.applyPrinterAdjustmentsPatten;
    const match = pattern.exec(testLabelZpl);
    if (match) {
      const xHome = Math.max(parseInt(match[1]) + leftOffSetDots, 0);
      const yHome = Math.max(parseInt(match[2]), 0);
      const newZpl = testLabelZpl.replace(pattern, `^LH${xHome},${yHome}`);
      let darknessAdjustment = 0;
      if (printer.darkness > 0) {
        darknessAdjustment = Math.min(
          printer.darkness,
          labelPrinterSetup.darknessAdjustment
        );
      } else {
        darknessAdjustment = Math.max(
          printer.darkness,
          -labelPrinterSetup.darknessAdjustment
        );
      }
      let topAdjustment = 0;
      if (topOffSetDots > 0) {
        topAdjustment = Math.min(
          topOffSetDots,
          labelPrinterSetup.topOffsetAdjustments
        );
      } else {
        topAdjustment = Math.max(
          topOffSetDots,
          -labelPrinterSetup.topOffsetAdjustments
        );
      }
      const sbFormat = `^XA^MD${darknessAdjustment}^LT${topAdjustment}`;
      return newZpl.replace("^XA", sbFormat);
    }

    return testLabelZpl;
  };

  const sendDataToPrinter = async (printer, adjustedLabel) => {
    try {
      const districtId = getAuthenticatedUserBranch();
      if (printer.ip_address === labelPrinterSetup.ipAddressCheck) return;
      const printerId = `sb${selectEnv()}-${printer.printerName}-${districtId}`;
      const stringToEncode = adjustedLabel;
      const encodedString = btoa(stringToEncode);
      const reqBody = {
        printerID: printerId,
        printType: "zpl",
        printData: encodedString,
        jobType: "label",
        jobName: "testjobZPL"
      };
      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.cloudPrint}`
      );
      if (response.data.isSuccess) {
        const msgDescr = await getMessageFromCode(errorMsgs.errorCode63902);
        setLabelTestMsg(msgDescr[0]?.descr);
        setThrowError(false);
        setThrowErrorMessage(null);
        setErrorMessage({});
        setLoading(false);
        setLabelTestAlert(true);
      } else {
        setLabelTestAlert(false);
        setThrowErrorMessage(t("labelPrinterSetup.labelTestError"));
        setThrowError(true);
        setLoading((prev) => false);
      }
    } catch (error) {
      setLabelTestAlert(false);
      setThrowErrorMessage(t("labelPrinterSetup.labelTestError"));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  const saveLabelTest = async (printer, isTested) => {
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_tested_flag: isTested ? VALUE_Y : VALUE_N,
        label_printer_id: printer.printerName
      };
      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.updatePrinter}`
      );
      if (response?.data?.isSuccess) {
        setThrowError(false);
        setThrowErrorMessage(null);
        setErrorMessage({});
        setLabelTestAlert(false);
        setLoading(false);
        setSuccessMsg(true);
        setThrowSuccessMessage(t("labelPrinterSetup.labelSuccessfullyDone"));
      } else {
        setLabelTestAlert(false);
        setThrowErrorMessage(t("labelPrinterSetup.labelTestError"));
        setThrowError(true);
        setLoading((prev) => false);
      }
    } catch (error) {
      setLabelTestAlert(false);
      setThrowErrorMessage(t("labelPrinterSetup.labelTestError"));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  const handleLabelTestAlertClose = (confirm) => {
    setLoading(true);
    if (confirm) {
      saveLabelTest(printerJson, true);
    } else {
      setLoading(false);
      setLabelTestAlert(false);
    }
  };

  const validateDescriptionInput = (value) => {
    if (value.length === labelPrinterSetup.zero) {
      setErrorMessage((prev) => ({
        ...prev,
        description: t("labelPrinterSetup.descriptionReqErrMsg")
      }));
    } else if (value.length > labelPrinterSetup.descrptionCharacterLimit) {
      setErrorMessage((prev) => ({
        description: t("labelPrinterSetup.descriptionSizeErrMsg")
      }));
    } else {
      setErrorMessage((prev) => ({ ...prev, description: "" }));
      return true;
    }
  };

  const validateIpAddress = (value) => {
    if (value.length === labelPrinterSetup.zero) {
      setErrorMessage((prev) => ({
        ...prev,
        ipAddress: t("labelPrinterSetup.ipAddressReqErrMsg")
      }));
    } else {
      setErrorMessage((prev) => ({ ...prev, ipAddress: "" }));
    }
  };

  const validateLabelStock = (value) => {
    if (value.length === labelPrinterSetup.zero) {
      setErrorMessage((prev) => ({
        ...prev,
        labelStock: t("labelPrinterSetup.labelStockReqErrMsg")
      }));
    } else {
      setErrorMessage((prev) => ({ ...prev, labelStock: "" }));
    }
  };

  const handlePrinterNameChange = (e) => {
    setIsEdit(false);
    setThrowError(false);
    setThrowErrorMessage(null);
    setErrorMessage({});
    const { name, value } = e.target;
    if (!newPrinterChange) {
      const printerData = printerNameData.find(
        (o) => o.label_printer_id === value
      );
      setCheckIpAddressChange(printerData?.ip_address);
      setPrinterJson({
        printerName: printerData?.label_printer_id,
        ipAddress: printerData?.ip_address,
        labelStockId: printerData?.label_stock_id,
        leftOffset: printerData?.left_offset_mm,
        topOffset: printerData?.top_offset_mm,
        darkness: printerData?.darkness_adjustment,
        description: printerData?.location_description
      });
    } else {
      setPrinterJson((prevState) => ({
        ...prevState,
        [name]: value
      }));
      validatePrinterNameInput(e.target.value);
    }
  };
  const handleLabelStockChange = (e) => {
    setIsEdit(false);
    const { name, value } = e.target;
    setPrinterJson((prevState) => ({
      ...prevState,
      [name]: value
    }));
    validateLabelStock(e.target.value);
  };
  const handleIpAddressChange = (e) => {
    setIsEdit(false);
    const { name, value } = e.target;
    setPrinterJson((prevState) => ({
      ...prevState,
      [name]: value
    }));
    validateIpAddress(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setIsEdit(false);
    const { name, value } = e.target;
    setPrinterJson((prevState) => ({
      ...prevState,
      [name]: value
    }));
    validateDescriptionInput(e.target.value);
  };

  const handleleftOffsetChange = (e) => {
    setIsEdit(false);
    const { name, value } = e.target;
    setPrinterJson((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleTopOffsetChange = (e) => {
    setIsEdit(false);
    const { name, value } = e.target;
    setPrinterJson((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleDarknessChange = (e) => {
    setIsEdit(false);
    const { name, value } = e.target;
    setPrinterJson((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <>
      {loading && <FullCircularLoader />}
      {successMsg && (
        <SuccessPopup message={throwSuccessMessage} close={setSuccessMsg} />
      )}
      {throwError && (
        <Alert
          severity="error"
          icon={
            <img
              style={labelPrinterSetupStyles.alertSize}
              src={ErrorIcon}
              alt="error"
            />
          }
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
                setThrowErrorMessage(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      )}
      <form>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              {!newPrinterChange ? (
                <>
                  <InputLabel id="printerNameLabel">
                    {t("labelPrinterSetup.printerNameLabel")}
                  </InputLabel>
                  <Select
                    labelId="printerNameId"
                    id="printerName"
                    name="printerName"
                    value={printerJson.printerName || ""}
                    onChange={(e) => handlePrinterNameChange(e)}
                    label={t("labelPrinterSetup.printerNameLabel")}
                  >
                    {printerNameData?.length > labelPrinterSetup.zero &&
                      printerNameData?.map((obj) => (
                        <MenuItem
                          value={obj?.label_printer_id}
                          key={obj?.label_printer_id}
                        >
                          {obj?.short_name}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              ) : (
                <TextField
                  labelId="printerNameId"
                  name="printerName"
                  label={t("labelPrinterSetup.printerNameLabel")}
                  value={printerJson.printerName || ""}
                  onChange={(e) => handlePrinterNameChange(e)}
                  error={errorMessage.printerName}
                  helperText={errorMessage.printerName}
                ></TextField>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                label={t("labelPrinterSetup.ipAddressLabel")}
                value={printerJson.ipAddress || ""}
                name="ipAddress"
                onChange={(e) => handleIpAddressChange(e)}
                error={errorMessage.ipAddress}
                helperText={errorMessage.ipAddress}
              ></TextField>
              <Typography sx={labelPrinterSetupStyles.ipAddressToolTip}>
                {t("labelPrinterSetup.ipAddressTooltip")}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth error={!!errorMessage.labelStock}>
              <>
                <InputLabel id="labelStockId">
                  {t("labelPrinterSetup.labelStock")}
                </InputLabel>
                <Select
                  labelId="labelStockId"
                  name="labelStockId"
                  value={printerJson?.labelStockId || ""}
                  label={t("labelPrinterSetup.labelStock")}
                  onChange={(e) => handleLabelStockChange(e)}
                  error={errorMessage.labelStock}
                  helperText={errorMessage.labelStock}
                >
                  {labelStockData?.length > 0 &&
                    labelStockData?.map((obj) => (
                      <MenuItem
                        value={obj?.label_stock_id}
                        key={obj?.short_name}
                      >
                        {obj?.short_name}
                      </MenuItem>
                    ))}
                </Select>
                {errorMessage.labelStock && (
                  <FormHelperText
                    style={labelPrinterSetupStyles.labelStockError}
                  >
                    {errorMessage.labelStock}
                  </FormHelperText>
                )}
              </>
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={labelPrinterSetupStyles.descriptionSize}
      >
        <FormControl fullWidth>
          <TextField
            label={t("labelPrinterSetup.decsriptionLabel")}
            multiline
            value={printerJson.description || ""}
            name="description"
            onChange={(e) => handleDescriptionChange(e)}
            rows={3}
            error={errorMessage.description}
            helperText={errorMessage.description}
          />
        </FormControl>
      </Grid>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                label={t("labelPrinterSetup.leftOffsetLabel")}
                value={
                  formatNumberByLocale(
                    printerJson.leftOffset,
                    localeByBranch
                  ) || ""
                }
                name="leftOffset"
                onChange={(e) => handleleftOffsetChange(e)}
                error={errorMessage.leftOffset}
                helperText={errorMessage.leftOffset}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                label={t("labelPrinterSetup.topOffset")}
                value={
                  formatNumberByLocale(printerJson.topOffset, localeByBranch) ||
                  ""
                }
                name="topOffset"
                onChange={(e) => handleTopOffsetChange(e)}
                error={errorMessage.topOffset}
                helperText={errorMessage.topOffset}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                label={t("labelPrinterSetup.darkness")}
                value={
                  formatNumberByLocale(printerJson.darkness, localeByBranch) ||
                  ""
                }
                name="darkness"
                onChange={(e) => handleDarknessChange(e)}
                error={errorMessage.darkness}
                helperText={errorMessage.darkness}
              ></TextField>
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <Divider />
      <Grid container gap={2}>
        <Button
          variant="outlined"
          onClick={() => cancel()}
          disabled={!newPrinterChange}
        >
          {t("labelPrinterSetup.cancel")}
        </Button>
        <Button
          variant="outlined"
          disabled={newPrinterChange}
          onClick={() => deleteModal()}
        >
          {t("labelPrinterSetup.remove")}
        </Button>

        <Button
          variant="outlined"
          disabled={newPrinterChange}
          onClick={handleTestLabelClick}
        >
          {t("labelPrinterSetup.labelTest")}
        </Button>
        <Button variant="outlined" onClick={() => newPrinter()}>
          {t("labelPrinterSetup.new")}
        </Button>
        {
          <Button
            disabled={isEdit}
            variant="contained"
            onClick={() => (newPrinterChange ? savePrinter() : updatePrinter())}
          >
            {t("labelPrinterSetup.save")}
          </Button>
        }
      </Grid>
      {openDeleteAlertModal && (
        <CommonModal
          open={openDeleteAlertModal}
          title={t("labelPrinterSetup.confirm")}
          body={
            <>
              <Typography>{`${t(
                "labelPrinterSetup.deleteWarningMsg"
              )} ${findPrinterName(printerJson.printerName)}${t(
                "labelPrinterSetup.deleteQuestionMark"
              )}`}</Typography>
            </>
          }
          buttons={
            <>
              <Button variant="outlined" onClick={() => remove(true)}>
                {t("common.yes")}
              </Button>
              <Button
                variant="contained"
                onClick={() => setDeleteAlertModal(false)}
              >
                {t("common.no")}
              </Button>
            </>
          }
        />
      )}

      {isLebelTestAlert && (
        <CommonModal
          open={isLebelTestAlert}
          title={t("labelPrinterSetup.confirm")}
          body={
            <>
              <Typography>{labelTestMsg}</Typography>
            </>
          }
          buttons={
            <>
              <Button
                variant="outlined"
                onClick={() => handleLabelTestAlertClose(true)}
              >
                {t("common.yes")}
              </Button>
              <Button
                variant="contained"
                onClick={() => handleLabelTestAlertClose(false)}
              >
                {t("common.no")}
              </Button>
            </>
          }
        />
      )}
    </>
  );
};
export default LabelPrinterSetupScreen;
