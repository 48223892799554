import { setLoading } from "store/slices/Core/loadingSlice";
const loadingMiddleware = (store) => (next) => (action) => {
  const isAsyncThunkAction = action.type.endsWith("/pending");

  if (isAsyncThunkAction) {
    //console.log("Calling.. " + action.meta.arg.endpointName);
    store.dispatch(setLoading(true));
    action.meta.requestId =
      action.meta.requestId || Math.random().toString(36).substring(7); // Assign a unique requestId
    return next(action);
  }

  if (action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected")) {
    store.dispatch(setLoading(false));
  }

  return next(action);
};

export default loadingMiddleware;
