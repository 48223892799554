import {
  Alert,
  Button,
  Card,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import i18n from "i18n";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CheckFilled from "assets/images/CheckFilled.svg";
import CloseFilledIcon from "assets/images/CloseFilled.svg";
import PencilIcon from "assets/images/pencil.svg";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getMessageFromCode } from "services/api/query";
import { DEFAULT_DATA_TABLE_OPTIONS, errorMsgs } from "utils/constants";
import { containerNumberMaxRecords } from "utils/constants/container-processing/Research";
import { containerResearchStyles } from "./ResearchStyle";

const dataTableOptions = {
  ...DEFAULT_DATA_TABLE_OPTIONS,
  selectableRows: "none",
  jumpToPage: false,
  pagination: false,
  responsive: "vertical",
  tableBodyHeight: "230px",
  tableBodyMaxHeight: "230px",
  textLabels: {
    body: {
      noMatch: i18n.t("mediaRequestDetail.noItemsToDisplay")
    }
  }
};

const FindContainerModal = (props) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "warning"
  });
  const [message63862, setMessage63862] = useState(""); // message 63862 value
  const [message63869, setMessage63869] = useState(""); // message 63869 value
  const [message10039, setMessage10039] = useState(""); // message 10039 value
  const [containerNumbers, setContainerNumbers] = useState(
    props?.existingValues
  ); // container numbers list
  const [selectedContainerNumber, setSetselectedContainerNumber] = useState({
    existing: "",
    new: ""
  }); // selected container_number
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [haveChanges, setHaveChanges] = useState(false);

  // column definition of the container number data table
  const tableColumnsDefinition = [
    {
      name: "container_number",
      label: " ",
      options: {
        setCellProps: () => ({
          style: containerResearchStyles.tableActionCell
        }),
        customBodyRender: (value, tableMeta) =>
          selectedContainerNumber.existing === value ? (
            <IconButton
              id={`btnUpdate${value}`}
              aria-label="update"
              onClick={() => handleUpdateExistingContainerNumber(value)}
              sx={ContainerProcessingStyles.tableIconButton}
            >
              <img src={CheckFilled} alt="update icon" />
            </IconButton>
          ) : (
            <IconButton
              id={`btnEdit${value}`}
              aria-label="edit"
              onClick={() =>
                setSetselectedContainerNumber((prevState) => ({
                  existing: value,
                  new: value
                }))
              }
              sx={ContainerProcessingStyles.tableIconButton}
            >
              <img src={PencilIcon} alt="edit icon" />
            </IconButton>
          )
      }
    },
    {
      name: "container_number_id",
      label: " ",
      options: {
        setCellProps: () => ({
          style: containerResearchStyles.tableActionCell
        }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() =>
              setContainerNumbers((prevState) =>
                prevState.filter((item) => {
                  if (item.container_number_id !== value) {
                    return true;
                  } else {
                    if (
                      selectedContainerNumber.existing === item.container_number
                    ) {
                      setSetselectedContainerNumber((prevState) => ({
                        existing: "",
                        new: ""
                      }));
                    }
                    return false;
                  }
                })
              )
            }
            sx={ContainerProcessingStyles.tableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "container_number",
      label: t("mediaRequestDetail.containerNumberCaps"),
      options: {
        customBodyRender: (value, tableMeta) =>
          selectedContainerNumber.existing === value ? (
            <>
              <TextField
                id="editContainerNumber"
                size="small"
                name="containerNumber"
                variant="standard"
                value={selectedContainerNumber.new}
                onChange={(e) =>
                  setSetselectedContainerNumber((prevState) => ({
                    ...prevState,
                    new: e.target.value
                  }))
                }
              />
            </>
          ) : (
            <>{value}</>
          )
      }
    }
  ];

  // form onSubmit event handler
  const handleFormSubmit = (values, helpers) => {
    let newContainer = {
      container_number_id: Math.round(Math.random() * 1000),
      container_number: values.containerNumber.trim()
    };

    if (containerNumbers.length > 0) {
      if (containerNumbers.length < containerNumberMaxRecords) {
        if (
          containerNumbers.findIndex(
            (item) =>
              item.container_number.trim() === newContainer.container_number
          ) >= 0
        ) {
          // duplicate container_number record
          setAlert((prevState) => ({
            show: true,
            message: message63862,
            severity: "warning"
          }));
          return;
        }
      } else {
        // container_number records exceeds max container_number count
        setAlert((prevState) => ({
          show: true,
          message: message63869
            .replace("|", containerNumbers.length)
            .replace("|", containerNumberMaxRecords),
          severity: "warning"
        }));
        return;
      }
    }

    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));
    helpers.resetForm();
    setContainerNumbers((prevState) => prevState.concat(newContainer));
    setHaveChanges((prevState) => true);
  };

  // find container form
  const findContainerForm = useFormik({
    initialValues: { containerNumber: "" },
    onSubmit: handleFormSubmit
  });

  // update selected container number of the table value event handler
  const handleUpdateExistingContainerNumber = (cellValue) => {
    if (
      containerNumbers.filter(
        (item) => item.container_number.trim() === cellValue
      ).length > 1 &&
      selectedContainerNumber.existing !== selectedContainerNumber.new
    ) {
      // duplicate container_number record
      setAlert((prevState) => ({
        show: true,
        message: message63862,
        severity: "warning"
      }));
      return;
    }

    setContainerNumbers((prevState) =>
      prevState.map((item) =>
        item.container_number === cellValue
          ? {
              ...item,
              container_number: selectedContainerNumber.new.trim()
            }
          : item
      )
    );
    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));
    setSetselectedContainerNumber((prevState) => ({
      existing: "",
      new: ""
    }));
  };

  // modal onClose event handler
  const handleOnClose = () => {
    if (haveChanges) {
      setShowConfirmationModal((prevState) => true);
    } else {
      props.onClose();
    }
  };

  // clear modal states
  const handleOnClear = () => {
    findContainerForm.resetForm();
    setContainerNumbers((prevState) => []);
    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));
  };

  // init page
  useEffect(() => {
    setHaveChanges((prevState) => false);
    getMessageFromCode(errorMsgs.errorCode63862).then((resp) =>
      setMessage63862((prevState) => resp[0]?.descr)
    );
    getMessageFromCode(errorMsgs.errorCode63869).then((resp) =>
      setMessage63869((prevState) => resp[0]?.descr)
    );
    getMessageFromCode(errorMsgs.errorCode10039).then((resp) =>
      setMessage10039((prevState) => resp[0]?.descr)
    );
  }, []);

  return (
    <>
      <CommonModal
        open={props.open}
        title={t("containerResearch.findContainer")}
        body={
          <>
            {/* Alert area */}
            {alert.show && (
              <Alert
                id="find-container-number-modal-alert"
                severity={alert.severity}
                onClose={() =>
                  setAlert((prevState) => ({
                    show: false,
                    message: ""
                  }))
                }
              >
                {alert.message}
              </Alert>
            )}

            <Card
              id="find-container-number-modal-card1"
              sx={containerResearchStyles.modalStyles.innerCard}
            >
              <Grid
                id="find-container-number-modal-grid4"
                container
                spacing={2}
                sx={containerResearchStyles.padding16}
              >
                {/* Container Number */}
                <Grid id="find-container-number-modal-grid5" item>
                  <TextField
                    id="find-container-number-modal-container-number"
                    size="small"
                    name="containerNumber"
                    label={t("containerResearch.containerNumber")}
                    variant="outlined"
                    required
                    value={findContainerForm.values?.containerNumber}
                    onChange={findContainerForm.handleChange}
                  />
                </Grid>

                {/* Add button */}
                <Grid id="find-container-number-modal-grid6" item>
                  <Button
                    id="find-container-number-modal-add-btb"
                    size="medium"
                    variant="outlined"
                    sx={containerResearchStyles.modalStyles.buttonStyle}
                    disabled={!findContainerForm.values?.containerNumber.trim()}
                    onClick={findContainerForm.handleSubmit}
                  >
                    {t("common.add")}
                  </Button>
                </Grid>
              </Grid>

              {/* Table need to go inside this */}
              <Grid
                id="find-container-number-modal-grid7"
                container
                spacing={2}
              >
                <Grid id="find-container-number-modal-grid8" item xs={12}>
                  <MUIDataTable
                    id="find-container-number-modal-datatable"
                    data={containerNumbers}
                    columns={tableColumnsDefinition}
                    options={dataTableOptions}
                  />
                </Grid>
              </Grid>
            </Card>
          </>
        }
        buttons={
          <>
            <Button
              id="find-container-number-modal-cancel-btn"
              variant="outlined"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="reset"
              onClick={handleOnClose}
            >
              {t("common.cancel")}
            </Button>
            <Button
              id="find-container-number-modal-clear-btn"
              variant="outlined"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              onClick={handleOnClear}
            >
              {t("common.clear")}
            </Button>
            <Button
              id="find-container-number-modal-ok-btn"
              variant="contained"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="submit"
              onClick={() => props.onSubmit(containerNumbers)}
            >
              {t("common.ok")}
            </Button>
          </>
        }
      />

      {showConfirmationModal && (
        <ConfirmPopup
          message={message10039}
          modalPopupOpen={showConfirmationModal}
          handleYes={() => props.onSubmit(containerNumbers)}
          handleNo={() => props.onClose()}
          showNo={true}
          handleCancel={() => setShowConfirmationModal((prevState) => false)}
          showCancel={true}
        />
      )}
    </>
  );
};

FindContainerModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  existingValues: PropTypes.array, // existing container numbers
  onClose: PropTypes.func, // handle modal close event
  onSubmit: PropTypes.func // handle modal submit event
};

export default FindContainerModal;
