import { Grid } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";

const AuditDetail = ({ tableData }) => {
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch:
          (!tableData || tableData.length === 0) && `${t("common.noDataFound")}`
      }
    }
  };
  const columns = [
    {
      name: "customer_number",
      label: t("containerAuditDetails.customer"),
      options: {
        customBodyRender: (value) => {
          return value?.trim() ? value : t("common.unknown");
        },
        sort: true
      }
    },
    {
      name: "container_number",
      label: t("containerAuditDetails.containerNumber"),
      options: {
        sort: true
      }
    },

    {
      name: "audit_detail_status",
      label: t("containerAuditDetails.exception"),
      options: {
        sort: true
      }
    },

    {
      name: "scan_location",
      label: t("containerAuditDetails.scanLocation"),
      options: {
        sort: true
      }
    },
    {
      name: "batch_id",
      label: t("containerAuditDetails.batchId"),
      options: {
        sort: true
      }
    }
  ];
  return (
    <Grid item xs={12} lg={3} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={tableData ? [...tableData] : []}
        options={options}
      />
    </Grid>
  );
};

export default AuditDetail;
