import {
  Alert,
  Button,
  Card,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import i18n from "i18n";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckFilled from "assets/images/CheckFilled.svg";
import CloseFilledIcon from "assets/images/CloseFilled.svg";
import PencilIcon from "assets/images/pencil.svg";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { getMessageFromCode } from "services/api/query";
import { DEFAULT_DATA_TABLE_OPTIONS, errorMsgs } from "utils/constants";
import { customerMediaIdMaxRecords } from "utils/constants/container-processing/Research";
import { containerResearchStyles } from "./ResearchStyle";

const dataTableOptions = {
  ...DEFAULT_DATA_TABLE_OPTIONS,
  selectableRows: "none",
  jumpToPage: false,
  pagination: false,
  responsive: "vertical",
  tableBodyHeight: "230px",
  tableBodyMaxHeight: "230px",
  textLabels: {
    body: {
      noMatch: i18n.t("mediaRequestDetail.noItemsToDisplay")
    }
  }
};

const FindCustomerMediaIDModal = (props) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "warning"
  });
  const [message63859, setMessage63859] = useState(""); // message 63859 value
  const [message63869, setMessage63869] = useState(""); // message 63869 value
  const [message10039, setMessage10039] = useState(""); // message 10039 value
  const [customerMediaIDs, setCustomerMediaIDs] = useState(
    props?.existingValues
  ); // customer_media ID list
  const [selectedCustomerMediaID, setSetselectedCustomerMediaID] = useState({
    existing: "",
    new: ""
  }); // selected customer_media
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [haveChanges, setHaveChanges] = useState(false);

  // column definition of the customer media ID data table
  const tableColumnsDefinition = [
    {
      name: "customer_media",
      label: " ",
      options: {
        setCellProps: () => ({
          style: containerResearchStyles.tableActionCell
        }),
        customBodyRender: (value, tableMeta) =>
          selectedCustomerMediaID.existing === value ? (
            <IconButton
              id={`btnUpdate${value}`}
              aria-label="update"
              onClick={() => handleUpdateExistingCustomerMediaID(value)}
              sx={ContainerProcessingStyles.tableIconButton}
            >
              <img src={CheckFilled} alt="update icon" />
            </IconButton>
          ) : (
            <IconButton
              id={`btnEdit${value}`}
              aria-label="edit"
              onClick={() =>
                setSetselectedCustomerMediaID((prevState) => ({
                  existing: value,
                  new: value
                }))
              }
              sx={ContainerProcessingStyles.tableIconButton}
            >
              <img src={PencilIcon} alt="edit icon" />
            </IconButton>
          )
      }
    },
    {
      name: "customer_media_id",
      label: " ",
      options: {
        setCellProps: () => ({
          style: containerResearchStyles.tableActionCell
        }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() =>
              setCustomerMediaIDs((prevState) =>
                prevState.filter((item) => {
                  if (item.customer_media_id !== value) {
                    return true;
                  } else {
                    if (
                      selectedCustomerMediaID.existing === item.customer_media
                    ) {
                      setSetselectedCustomerMediaID((prevState) => ({
                        existing: "",
                        new: ""
                      }));
                    }
                    return false;
                  }
                })
              )
            }
            sx={ContainerProcessingStyles.tableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "customer_media",
      label: t("mediaRequestDetail.customerMediaIdentifierCaps"),
      options: {
        customBodyRender: (value, tableMeta) =>
          selectedCustomerMediaID.existing === value ? (
            <>
              <TextField
                id="editCustomerMediaID"
                size="small"
                name="containerNumber"
                variant="standard"
                value={selectedCustomerMediaID.new}
                onChange={(e) =>
                  setSetselectedCustomerMediaID((prevState) => ({
                    ...prevState,
                    new: e.target.value
                  }))
                }
              />
            </>
          ) : (
            <>{value}</>
          )
      }
    }
  ];

  // form onSubmit event handler
  const handleFormSubmit = (values, helpers) => {
    let newCustomerMediaId = {
      customer_media_id: Math.round(Math.random() * 1000),
      customer_media: values.customerMedia.trim()
    };

    if (customerMediaIDs.length > 0) {
      if (customerMediaIDs.length < customerMediaIdMaxRecords) {
        if (
          customerMediaIDs.findIndex(
            (item) =>
              item.customer_media.trim() === newCustomerMediaId.customer_media
          ) >= 0
        ) {
          // duplicate customer_media_id record
          setAlert((prevState) => ({
            show: true,
            message: message63859.replace(
              "|",
              newCustomerMediaId.customer_media
            ),
            severity: "warning"
          }));
          return;
        }
      } else {
        // customer_media_id records exceeds max container_number count
        setAlert((prevState) => ({
          show: true,
          message: message63869
            .replace("|", customerMediaIDs.length)
            .replace("|", customerMediaIdMaxRecords),
          severity: "warning"
        }));
        return;
      }
    }

    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));
    helpers.resetForm();
    setCustomerMediaIDs((prevState) => prevState.concat(newCustomerMediaId));
    setHaveChanges((prevState) => true);
  };

  // find customer media id form
  const findCustomerMediaID = useFormik({
    initialValues: { customerMedia: "" },
    onSubmit: handleFormSubmit
  });

  // update selected container number of the table value event handler
  const handleUpdateExistingCustomerMediaID = (cellValue) => {
    if (
      customerMediaIDs.filter(
        (item) => item.customer_media.trim() === cellValue
      ).length > 1 &&
      selectedCustomerMediaID.existing !== selectedCustomerMediaID.new
    ) {
      // duplicate container_number record
      setAlert((prevState) => ({
        show: true,
        message: message63859.replace("|", selectedCustomerMediaID.new),
        severity: "warning"
      }));
      return;
    }

    setCustomerMediaIDs((prevState) =>
      prevState.map((item) =>
        item.customer_media === cellValue
          ? {
              ...item,
              customer_media: selectedCustomerMediaID.new.trim()
            }
          : item
      )
    );
    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));
    setSetselectedCustomerMediaID((prevState) => ({
      existing: "",
      new: ""
    }));
  };

  // modal onClose event handler
  const handleOnClose = () => {
    if (haveChanges) {
      setShowConfirmationModal((prevState) => true);
    } else {
      props.onClose();
    }
  };

  // clear modal states
  const handleOnClear = () => {
    findCustomerMediaID.resetForm();
    setCustomerMediaIDs((prevState) => []);
    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));
  };

  // init page
  useEffect(() => {
    setHaveChanges((prevState) => false);
    getMessageFromCode(errorMsgs.errorCode63859).then((resp) =>
      setMessage63859((prevState) => resp[0]?.descr)
    );
    getMessageFromCode(errorMsgs.errorCode63869).then((resp) =>
      setMessage63869((prevState) => resp[0]?.descr)
    );
    getMessageFromCode(errorMsgs.errorCode10039).then((resp) =>
      setMessage10039((prevState) => resp[0]?.descr)
    );
  }, []);

  return (
    <>
      <CommonModal
        open={props.open}
        title={t("containerResearch.findCustomerMediaIdentifier")}
        body={
          <>
            {/* Alert area */}
            {alert.show && (
              <Alert
                id="find-customer-media-id-modal-alert"
                severity={alert.severity}
                onClose={() =>
                  setAlert((prevState) => ({
                    show: false,
                    message: ""
                  }))
                }
              >
                {alert.message}
              </Alert>
            )}

            <Card
              id="find-customer-media-id-modal-card1"
              sx={containerResearchStyles.modalStyles.innerCard}
            >
              <Grid
                id="find-customer-media-id-modal-grid4"
                container
                spacing={2}
                sx={containerResearchStyles.padding16}
              >
                {/* Customer Media Identifier */}
                <Grid id="find-customer-media-id-modal-grid5" item>
                  <TextField
                    id="find-customer-media-id-modal-customer-media-id"
                    size="small"
                    name="customerMedia"
                    label={t("containerResearch.customerMediaId")}
                    variant="outlined"
                    required
                    value={findCustomerMediaID.values?.customerMedia}
                    onChange={findCustomerMediaID.handleChange}
                  />
                </Grid>

                {/* Add button */}
                <Grid id="find-customer-media-id-modal-grid6" item>
                  <Button
                    id="find-customer-media-id-modal-add-btn"
                    size="medium"
                    variant="outlined"
                    sx={containerResearchStyles.modalStyles.buttonStyle}
                    disabled={
                      !findCustomerMediaID.values?.customerMedia.trim()
                    }
                    onClick={findCustomerMediaID.handleSubmit}
                  >
                    {t("common.add")}
                  </Button>
                </Grid>
              </Grid>

              {/* Table need to go inside this */}
              <Grid
                id="find-customer-media-id-modal-grid7"
                container
                spacing={2}
              >
                <Grid id="find-customer-media-id-modal-grid8" item xs={12}>
                  <MUIDataTable
                    id="find-customer-media-id-modal-datatable"
                    data={customerMediaIDs}
                    columns={tableColumnsDefinition}
                    options={dataTableOptions}
                  />
                </Grid>
              </Grid>
            </Card>
          </>
        }
        buttons={
          <>
            <Button
              id="find-customer-media-id-modal-cancelbtn"
              variant="outlined"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="reset"
              onClick={handleOnClose}
            >
              {t("common.cancel")}
            </Button>
            <Button
              id="find-customer-media-id-modal-clear-btn"
              variant="outlined"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              onClick={handleOnClear}
            >
              {t("common.clear")}
            </Button>
            <Button
              id="find-customer-media-id-modal-ok-btn"
              variant="contained"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="submit"
              onClick={() => props.onSubmit(customerMediaIDs)}
            >
              {t("common.ok")}
            </Button>
          </>
        }
      />

      {showConfirmationModal && (
        <ConfirmPopup
          message={message10039}
          modalPopupOpen={showConfirmationModal}
          handleYes={() => props.onSubmit(customerMediaIDs)}
          handleNo={() => props.onClose()}
          showNo={true}
          handleCancel={() => setShowConfirmationModal((prevState) => false)}
          showCancel={true}
        />
      )}
    </>
  );
};

FindCustomerMediaIDModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  existingValues: PropTypes.array, // existing customer media Identifiers
  onClose: PropTypes.func, // handle modal close event
  onSubmit: PropTypes.func // handle modal submit event
};

export default FindCustomerMediaIDModal;
