import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ModalPopup from "components/core/modal-components/ModalPopup";
import { getMessageByIdFromMessage } from "services/common";
import { NO, YES_AUTHORIZE } from "utils/constants";
import { MESSAGE_20464 } from "utils/constants/request-module/MediaRequestDetailConstants";

const CancelRequestRequiresAuthModal = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    getMessageByIdFromMessage({ message_id: MESSAGE_20464 }).then((resp) =>
      setMessage((prevState) => resp[0]?.descr)
    );
  }, []);

  return (
    <>
      <ModalPopup
        title={t("mediaRequestDetail.cancelRequest")}
        modalPopupOpen={props.open}
        alertMessage={message}
        modalButton={[
          <Button
            variant="outlined"
            onClick={(e) => {
              props.onClick(YES_AUTHORIZE);
            }}
            key={YES_AUTHORIZE}
          >
            {t("mediaRequestDetail.yes")}
          </Button>,
          <Button
            variant="contained"
            onClick={(e) => {
              props.onClick(NO);
            }}
            key={NO}
          >
            {t("mediaRequestDetail.no")}
          </Button>
        ]}
      />
    </>
  );
};

export default CancelRequestRequiresAuthModal;
