import CloseIcon from "@mui/icons-material/Close";

import {
  Alert,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Typography
} from "@mui/material";

import ErrorIcon from "assets/images/warning-2.svg";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";

import MUIDataTable from "mui-datatables";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { printTapeLabelStyles } from "./PrintTapeLabelStyles";

const PrintTapeLabelLayoutTabel = ({
  labelFormatData,
  validateBarcode,
  parsedData,
  setParsedData,
  setFormError,
  setBarcodeHasSpecialCharsInfo
}) => {
  const { t } = useTranslation();
  const openMediaFileImportRef = useRef(null);
  const [throwError, setThrowError] = useState(false);
  const [noFileContent, setNoFileContent] = useState(false);
  const [readyToPrint, setReadyToPrint] = useState(false);
  let Max_Media_Number_Length = labelFormatData.max_media_number_length;

  // handle file import event handler
  const handleFileImport = async (event) => {
    const file = event.target.files[0];
    setThrowError(false);
    setFormError(false);
    setReadyToPrint(false);
    setBarcodeHasSpecialCharsInfo(false);
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          if (content.trim()) {
            parseFileContent(content);
            setNoFileContent(false);
          } else {
            setNoFileContent(true);
          }
          // Reset the input value to trigger the onChange event next time
          openMediaFileImportRef.current.value = null;
        };
        reader.readAsText(file);
      } catch (error) {
        setNoFileContent(false);
        setFormError(t("printTapeLabel.errorReadingTheFile"));
      }
    }
  };

  let getSortedParsedData = (data) => {
    let dataToBeSorted = [...data];
    dataToBeSorted.sort((a, b) => {
      if (a.statusMessage && !b.statusMessage) return -1;
      if (!a.statusMessage && b.statusMessage) return 1;
      if (a.statusMessage !== b.statusMessage)
        return a.statusMessage.localeCompare(b.statusMessage);
      if (a.customer_number !== b.customer_number)
        return a.customer_number.localeCompare(b.customer_number);
      return a.media_number.localeCompare(b.media_number);
    });
    return dataToBeSorted;
  };

  const parseFileContent = (content) => {
    const lines = content.split("\n").filter((line) => line.trim() !== "");
    const customerNumberRegex = /^[A-Z0-9]+$/;
    const mediaTypeRegex = /^[A-Z0-9]+$/;
    let data = [];
    let isThrowError = false;

    lines.forEach((line) => {
      const fields = line.split("\t");
      let entry = {};

      if (fields.length === 1) {
        let statusMessage = "";
        let mediaNumber = fields[0].trim()?.toUpperCase();

        let { isValid, statusMessageShort } = validateBarcode(
          mediaNumber,
          Max_Media_Number_Length
        );

        if (!isValid && !statusMessage) {
          statusMessage = statusMessageShort;
          isThrowError = true;
        }

        entry = {
          media_number: mediaNumber,
          customer_number: "",
          media_type: "",
          statusMessage
        };
      } else if (fields.length === 2) {
        const customerNumber = fields[0]?.trim()?.toUpperCase();
        const mediaNumber = fields[1]?.trim()?.toUpperCase();
        let statusMessage = "";
        if (!customerNumberRegex.test(customerNumber) && !statusMessage) {
          statusMessage = t("printTapeLabel.invalidCustomerNumber");
          isThrowError = true;
        }

        let { isValid, statusMessageShort } = validateBarcode(
          customerNumber + mediaNumber,
          Max_Media_Number_Length
        );

        if (!isValid && !statusMessage) {
          statusMessage = statusMessageShort;
          isThrowError = true;
        }
        entry = {
          customer_number: customerNumber,
          media_number: fields[1].trim()?.toUpperCase(),
          media_type: "",
          statusMessage
        };
      } else if (fields.length >= 3) {
        const customerNumber = fields[0].trim()?.toUpperCase();
        const mediaType = fields[1].trim()?.toUpperCase();
        const mediaNumber = fields[2]?.trim()?.toUpperCase();
        let statusMessage = "";
        if (!customerNumberRegex.test(customerNumber)) {
          statusMessage = t("printTapeLabel.invalidCustomerNumber");
          isThrowError = true;
        }
        if (
          (mediaType.length > 2 || !mediaTypeRegex.test(mediaType)) &&
          !statusMessage
        ) {
          statusMessage = t("printTapeLabel.invalidMediaType");
          isThrowError = true;
        }
        let { isValid, statusMessageShort } = validateBarcode(
          customerNumber + mediaType + mediaNumber,
          Max_Media_Number_Length
        );
        if (!isValid && !statusMessage) {
          statusMessage = statusMessageShort;
          isThrowError = true;
        }

        entry = {
          customer_number: customerNumber,
          media_type: mediaType,
          media_number: mediaNumber,
          statusMessage
        };
      }

      data.push(entry);
    });
    setThrowError(isThrowError);
    setReadyToPrint(isThrowError ? false : true);
    setParsedData(data);
  };

  const columns = [
    {
      name: "customer_number",
      label: t("printTapeLabel.colCustomer")
    },
    {
      name: "media_type",
      label: t("printTapeLabel.colType")
    },
    {
      name: "media_number",
      label: t("printTapeLabel.colMediaNumber")
    },
    {
      name: "statusMessage",
      label: t("printTapeLabel.colStatus"),
      options: {
        customBodyRender: (value) => {
          let hasError = value !== "";

          return (
            <Typography
              sx={{
                ...printTapeLabelStyles.printLabelTableStyles.statusText,
                color: hasError
                  ? printTapeLabelStyles.printLabelTableStyles.errorColor
                  : "initial"
              }}
            >
              {value || t("printTapeLabel.readyToPrintShort")}
            </Typography>
          );
        }
      }
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: { noMatch: noFileContent ? t("common.noDataFound") : "" }
    },
    pagination: parsedData?.length && true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            style={printTapeLabelStyles.printTableLabelTableList.buttons}
            onClick={() => {}}
            id="import-print-tape"
            component="label"
          >
            {t("printTapeLabel.import")}
            <input
              ref={openMediaFileImportRef}
              type="file"
              accept=".txt, .csv"
              style={
                printTapeLabelStyles.printTableLabelTableList.inputTypeFile
              }
              onChange={handleFileImport}
            />
          </Button>
        </Grid>
      </>
    )
  };

  return (
    <React.Fragment>
      <Paper sx={printTapeLabelStyles.printLabelTableStyles?.tablePaper}>
        {throwError && (
          <Collapse
            in={throwError}
            sx={printTapeLabelStyles.printLabelTableStyles.alert}
          >
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {t("printTapeLabel.invalidLabels")}
            </Alert>
          </Collapse>
        )}
        {readyToPrint && (
          <Collapse
            in={readyToPrint}
            sx={printTapeLabelStyles.printLabelTableStyles.alert}
          >
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setReadyToPrint(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {t("printTapeLabel.allLablesValid")}
            </Alert>
          </Collapse>
        )}
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={getSortedParsedData(parsedData || [])}
            title={
              <Typography
                style={printTapeLabelStyles.printLabelTableStyles.titleStyle}
              >
                {parsedData?.length} {t("common.recordsFound")}
              </Typography>
            }
          />
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
};

export default PrintTapeLabelLayoutTabel;
