import { FullCircularLoader } from "components/core";
import { onAuthStateChanged } from "firebase/auth";
import Layout from "layouts";
import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import useAuthService from "services/authService";
import { authentication } from "services/firebase";
import { selectAuth } from "store/slices";
import { LOGIN } from "./Paths";

function ProtectedRoute() {
  const dispatch = useDispatch();
  const authService = useAuthService();
  const { auth, isError } = useSelector(selectAuth);
  const [isLoading, setIsLoading] = useState(true);

  // init page
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      authentication,
      async (currentUser) => {
        // check whether the user have an existing session
        if (currentUser) {
          await authService.fetchLoggedUserData(currentUser, currentUser.email);
          setIsLoading(false);
        } else {
          if (!isError) {
            dispatch({ type: "Reset" });
          }
          setIsLoading(false);
        }
      }
    );

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // show app loading
  if (isLoading && !Object.keys(auth?.user).length) {
    return <FullCircularLoader loading={isLoading} />;
  }

  // If not authorized redirect to login page
  if (!isLoading && !Object.keys(auth?.user).length) {
    return <Navigate to={LOGIN} />;
  }

  // If authorized render the page requested
  return (
    <Layout>
      <Suspense fallback={<></>}>
        <Outlet />
      </Suspense>
    </Layout>
  );
}

export default ProtectedRoute;
