import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { DatePickerField } from "components/core";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  buildType,
  serviceLocation
} from "utils/constants/disaster-module/EventSearchConstants";
import AuthRequestModal from "./AuthRequestModal";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";
import OpenMediaContainerGrid from "./open-media-container-grid/OpenMediaContainerGrid";

const RequestGeneratorService = ({
  requestCommentMsg,
  RequestForm,
  personnelList,
  customerLocations,
  instMsgList,
  addOpenMedia,
  setAddOpenMedia,
  addContainer,
  setAddContainer,
  message26105,
  setShowGrid,
  showGrid
}) => {
  const { t } = useTranslation();
  const { localeByBranch, timeZoneOffset } = useSelector(selectAuth);
  const [authBySelected, setAuthBySelected] = useState();

  const [authModalSuccess, setAuthModalSuccess] = useState(false);
  const [authRequestModalVisible, setAuthRequestModalVisible] = useState(false);
  const [timeZoneOffsetChanged, setTimeZoneOffsetChanged] = useState(false); // timezone offset value changed
  const currentDate = useMemo(() => {
    let date = dayjs.utc(new Date());
    if (timeZoneOffset !== null) {
      date = date.utcOffset(parseFloat(timeZoneOffset));
    }
    setTimeZoneOffsetChanged((prevState) => !prevState);
    return date;
  }, [timeZoneOffset]);

  // handle auth request modal success event
  const handleAuthRequestModalSuccess = () => {
    setAuthRequestModalVisible((prevState) => false);
    RequestForm.handleChange({
      target: { name: "authorizedBy", value: authBySelected }
    });
  };

  // service date chnage event handler
  const handleServiceDateOnChange = async (date) => {
    if (date !== null) {
      await RequestForm.setFieldValue("serviceDate", date);
    } else {
      await RequestForm.setFieldValue("serviceDate", currentDate);
      setTimeZoneOffsetChanged((prevState) => !prevState);
    }
  };

  const handleServiceLocation = (event) => {
    RequestForm.handleChange({
      target: { name: "serviceLocation", value: event.target.value }
    });
    RequestForm.handleChange({
      target: { name: "customerLocation", value: customerLocations[0] }
    });
  };
  return (
    <>
      {/* Auth request modal */}
      {authRequestModalVisible && (
        <AuthRequestModal
          show={authRequestModalVisible}
          customer={RequestForm.values.customer}
          cancelModalWarning={authModalSuccess}
          setCancelModalWarning={setAuthModalSuccess}
          authorizedBy={authBySelected.value}
          personnel={authBySelected.label}
          timestamp={RequestForm.values?.timestamp}
          onClose={setAuthRequestModalVisible}
          onSubmit={handleAuthRequestModalSuccess}
        />
      )}
      <Stack
        gap={spacing.gap}
        ml={spacing.gap}
        sx={disasterRecoveryStyles.requestGeneratorStyles.rightPanelWrapper}
      >
        <Typography
          sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
          mb={spacing.gap10}
        >
          {t("disasterRecovery.eventDetail.serviceInfo")}
        </Typography>
        <Stack direction={"column"} gap={spacing.gap}>
          <Stack direction="row" gap={spacing.gap}>
            <Grid item xs={3}>
              <DatePickerField
                id="service-Date"
                key={timeZoneOffsetChanged}
                label={`${t(
                  "disasterRecovery.eventDetail.serviceDateLabel"
                )} *`}
                locale={localeByBranch}
                variant="input"
                value={RequestForm.values.serviceDate}
                minDate={currentDate}
                maxDate={currentDate.add(1, "year")}
                onChange={handleServiceDateOnChange}
                error={
                  Boolean(RequestForm.errors?.serviceDate) &&
                  Boolean(RequestForm.touched?.serviceDate)
                }
                helperText={
                  Boolean(RequestForm.touched?.serviceDate) &&
                  RequestForm.errors?.serviceDate
                }
              />
            </Grid>

            {/* ---------- Authorized By selection ---------- */}
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              popupIcon={<ArrowDown />}
              clearIcon={<CloseIcon />}
              id="authorizedBy"
              name="authorizedBy"
              options={personnelList}
              size="medium"
              value={RequestForm.values.authorizedBy}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              fullWidth
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setAuthBySelected(newValue);
                  setAuthRequestModalVisible(true);
                } else {
                  RequestForm.handleChange({
                    target: {
                      name: "authorizedBy",
                      value: { label: "", value: "" }
                    }
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={
                    Boolean(RequestForm.errors?.authorizedBy) &&
                    Boolean(RequestForm.touched?.authorizedBy)
                  }
                  helperText={
                    Boolean(RequestForm.touched?.authorizedBy) &&
                    RequestForm.errors?.authorizedBy
                  }
                  label={t("disasterRecovery.eventDetail.authorizedByLabel")}
                />
              )}
              sx={disasterRecoveryStyles.commonStyles.widthFixed}
            />
          </Stack>
          <Typography style={disasterRecoveryStyles.commonStyles.subHeading}>
            {t("disasterRecovery.eventDetail.serviceLocationLabel")}
          </Typography>
          <Stack direction="row" gap={spacing.gap}>
            {/* Radio Selection */}
            <Box>
              <FormControl>
                <RadioGroup
                  row
                  name="service-radio-buttons-group"
                  value={RequestForm.values.serviceLocation}
                  onChange={handleServiceLocation}
                >
                  <FormControlLabel
                    value={serviceLocation.recoverySite}
                    control={<Radio />}
                    label={t("disasterRecovery.eventDetail.recoverySiteLabel")}
                  />
                  <FormControlLabel
                    value={serviceLocation.customerLocation}
                    control={<Radio />}
                    label={t(
                      "disasterRecovery.eventDetail.customerLocationLabel"
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" gap={spacing.gap}>
            {/* ---------- Recovery Site ----------   */}
            {RequestForm.values.serviceLocation ===
              serviceLocation.recoverySite && (
              <TextField
                label={t("disasterRecovery.eventDetail.recoverySiteLabel")}
                id="recoverySite"
                name="recoverySite"
                size="medium"
                required
                error={
                  Boolean(RequestForm.errors?.recoverySite) &&
                  Boolean(RequestForm.touched?.recoverySite)
                }
                helperText={
                  Boolean(RequestForm.touched?.recoverySite) &&
                  RequestForm.errors?.recoverySite
                }
                sx={disasterRecoveryStyles.commonStyles.widthFixed}
                type="text"
                value={RequestForm.values.recoverySite}
                onChange={(e) => {
                  RequestForm.handleChange({
                    target: { name: "recoverySite", value: e.target.value }
                  });
                }}
                inputProps={{
                  maxLength: 255
                }}
                shrink={RequestForm.values.recoverySite}
                notched={RequestForm.values.recoverySite}
              />
            )}
            {/* ---------- Customer Location ----------  */}
            {RequestForm.values.serviceLocation ===
              serviceLocation.customerLocation && (
              <Autocomplete
                id="customerLocation"
                name="customerLocation"
                options={customerLocations}
                value={RequestForm.values.customerLocation}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="customerLocation1"
                    name="customerLocation1"
                    required
                    error={
                      Boolean(RequestForm.errors?.customerLocation) &&
                      Boolean(RequestForm.touched?.customerLocation)
                    }
                    helperText={
                      Boolean(RequestForm.touched?.customerLocation) &&
                      RequestForm.errors?.customerLocation
                    }
                    label={t(
                      "disasterRecovery.eventDetail.customerLocationLabel"
                    )}
                  />
                )}
                onChange={(event, newValue) => {
                  RequestForm.handleChange({
                    target: { name: "customerLocation", value: newValue }
                  });
                }}
                sx={disasterRecoveryStyles.commonStyles.widthFixed}
              />
            )}
          </Stack>
          <Typography style={disasterRecoveryStyles.commonStyles.subHeading}>
            {t("disasterRecovery.eventDetail.requestCommentsLabel")}
          </Typography>
          <Stack direction="row" gap={spacing.gap}>
            <FormLabel>
              {t("disasterRecovery.eventDetail.requestCommentsInst")}
            </FormLabel>
          </Stack>
          <Stack direction="row" gap={spacing.gap}>
            <TextField
              label={t("disasterRecovery.eventDetail.ironMountainComments")}
              id="ironMountain_comments"
              fullWidth
              multiline
              rows={3}
              value={RequestForm?.values?.imComment}
              onChange={(event) =>
                RequestForm.handleChange({
                  target: { name: "imComment", value: event.target.value }
                })
              }
              InputLabelProps={{
                shrink: RequestForm?.values?.imComment
              }}
              InputProps={{
                inputProps: {
                  maxLength: 255
                }
              }}
            />

            <TextField
              label={t("disasterRecovery.eventDetail.customerComments")}
              id="customer_comment"
              fullWidth
              multiline
              rows={3}
              value={RequestForm?.values?.customerComment}
              onChange={(event) =>
                RequestForm.handleChange({
                  target: { name: "customerComment", value: event.target.value }
                })
              }
              InputLabelProps={{
                shrink: RequestForm?.values?.customerComment
              }}
              InputProps={{
                inputProps: {
                  maxLength: 255
                }
              }}
            />
          </Stack>
          <Divider />
          <>
            {showGrid ? (
              <>
                <OpenMediaContainerGrid
                  title={t("disasterRecovery.eventDetail.summary")}
                  subTitle={message26105}
                  openMediaList={addOpenMedia}
                  setOpenMediaList={setAddOpenMedia}
                  containerList={addContainer}
                  setContainerList={setAddContainer}
                  page={buildType.landingPage}
                />
              </>
            ) : null}
            <>
              <Typography
                style={disasterRecoveryStyles.commonStyles.subHeading}
              >
                {t("disasterRecovery.eventDetail.howToDRrequest")}
              </Typography>
              <Stack direction="row" gap={spacing.gap}>
                {instMsgList?.length > 0 && (
                  <List sx={{ listStyleType: "none" }}>
                    {instMsgList?.map((inst) => {
                      return (
                        <>
                          <ListItem sx={{ display: "list-item" }}>
                            {inst}
                          </ListItem>
                        </>
                      );
                    })}
                  </List>
                )}
              </Stack>
            </>
          </>
        </Stack>
      </Stack>
    </>
  );
};

export default RequestGeneratorService;
