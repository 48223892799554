import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CF_URLS } from "services/api/endpoints";
import { ERROR_TYPES, downloadReportOptions } from "utils/constants";
import { numValues } from "utils/constants/biling-module/BillingTransactionsConstants";
import { findErrorMessage, getSettingsValue, printReport } from "utils/helpers";

export default function DownloadReportModal({
  isVisible,
  setIsVisible,
  mainDistrictId,
  setIsLoading,
  requestId,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage
}) {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);
  const [settingValue, setSettingValue] = useState(null);

  useEffect(() => {
    fetchSettingValues();
    // eslint-disable-next-line
  }, []);

  const fetchSettingValues = async () => {
    setIsLoading(true);
    try {
      const settngValue = await getSettingsValue(
        mainDistrictId,
        numValues.thousandPlus1
      );
      setSettingValue(settngValue);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        request_id_list: requestId.toString().padStart(10, "0"),
        container_id: "",
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        reqBody,
        `${
          settingValue?.toLowerCase() === "a4"
            ? CF_URLS.printReports.request.mrdAssignTransportA4Report
            : CF_URLS.printReports.request.mrdAssignTransportReport
        }`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setThrowError(false);
    setIsVisible(false);
  };

  return (
    <DownloadPopup
      title={t("common.downloadReport")}
      showPrintModal={isVisible}
      downloadOptions={downloadReportOptions}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
    />
  );
}
