import { Stack } from "@mui/material";
import NotificationLayout from "components/notification-configuration/NotificationLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const NotificationPage = () => {
  return (
    <>
      <Stack>
        <PageTitle title={i18n.t("menu.notificationConfiguration")} />
        <NotificationLayout />
      </Stack>
    </>
  );
};
export default NotificationPage;
