import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect } from "react";
import { VALUE_Y, dateFormatWithoutTime } from "utils/constants";
import {
  checkValue,
  generatedPicklist,
  missingValue
} from "utils/constants/container-processing/ContainerProcessing";

const EditPickingModal = ({
  madeChanges,
  setMadeChanges,
  localeByBranch,
  isModalOpen,
  handleModalClose,
  update,
  handleCheckboxChange,
  selectedRow,
  check,
  setCheck
}) => {
  const isCheckboxDisabled = (row) => {
    if (row.type !== generatedPicklist) {
      return true;
    }
    if (row.special_flag === VALUE_Y) {
      return true;
    }

    if (
      row.discrep_type_code.trim() !== "" &&
      row.indefinite_retention_flag === VALUE_Y
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    const checked =
      selectedRow?.discrep_system_function === missingValue ? checkValue : "";
    setCheck(checked);
  }, [selectedRow?.discrep_system_function, setCheck]);
  return (
    <>
      <CommonModal
        open={isModalOpen}
        title={t("containerProcessing.picking.editTitle")}
        body={
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="body1" color="textSecondary">
                    {t("containerProcessing.picking.customerId")}
                  </Typography>
                  <Typography variant="h6">
                    {selectedRow?.cust_number}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="body1" color="textSecondary">
                    {t("containerProcessing.picking.serviceDateEdit")}
                  </Typography>
                  <Typography variant="h6">
                    {dayjs(selectedRow?.run_date)
                      .locale(localeByBranch)
                      .format(dateFormatWithoutTime)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isCheckboxDisabled(selectedRow)}
                        name="notFound"
                        onChange={() => handleCheckboxChange()}
                        checked={check === checkValue}
                      />
                    }
                    label={t("containerProcessing.picking.notFoundEdit")}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="body1" color="textSecondary">
                    {t("containerProcessing.picking.discrepancyEdit")}
                  </Typography>
                  <Typography variant="h6">
                    {selectedRow?.discrep_type_code}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button onClick={handleModalClose} variant="outlined">
              {t("common.cancel")}
            </Button>
            <Button variant="contained" onClick={() => update()}>
              {t("common.update")}
            </Button>
          </>
        }
      ></CommonModal>
    </>
  );
};
export default EditPickingModal;
