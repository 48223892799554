import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";

import { activeChargesFilter } from "utils/constants/biling-module/BillingRecurringChargeConstants";
import { billingRecurringChargeStyles } from "./BillingRecurringChargeStyles";

const BillingRecurringChargeSearchFilter = ({
  mrdForm,
  customers,
  customerSelected,
  handleCustomerChange,
  catelogItems,
  catalogItemSelected,
  setCatalogItemSelected,
  setDisplayMarked,
  activeChargesFilterSelected,
  setActiveChargesFilterSelected,
  isFindEnabled,
  setIsFindEnabled,
  setIsClearClicked,
  setResultSet,
  displayMarked,
  setNoResults
}) => {
  const { t } = useTranslation();

  const customersFieldProps = {
    options: customers,
    value: customerSelected,
    required: true,
    handleOnChange: handleCustomerChange,
    sx: billingRecurringChargeStyles.commonStyles.widthFixed,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  return (
    <form onSubmit={mrdForm?.handleSubmit}>
      <Stack direction={"column"} gap={spacing.gap}>
        <Stack direction="row" gap={spacing.gap}>
          {/* ---------- Customer selection ----------   */}
          <CustomersField {...customersFieldProps} />

          {/* ---------- Catalog item selection ----------   */}
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="catalogItemList-filter"
            name={t("billingRecurringCharge.catalogItemLabel")}
            options={catelogItems}
            size="medium"
            value={catalogItemSelected}
            fullWidth
            onChange={(event, newValue) => {
              setIsFindEnabled(true);
              setResultSet([]);
              setNoResults(false);

              if (newValue) {
                setCatalogItemSelected(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("billingRecurringCharge.catalogItemLabelModal")}
              />
            )}
            sx={billingRecurringChargeStyles.commonStyles.widthFixed}
          />

          {/* ---------- Active charge filter selection ----------   */}
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            clearIcon={<CloseIcon />}
            id="activecharges-filter"
            popupIcon={<ArrowDown />}
            name={t("billingRecurringCharge.activeChargesFilter")}
            options={activeChargesFilter}
            size="medium"
            value={activeChargesFilterSelected}
            fullWidth
            onChange={(event, newValue) => {
              setIsFindEnabled(true);
              setResultSet([]);
              setNoResults(false);

              if (newValue) {
                setActiveChargesFilterSelected(newValue?.label);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("billingRecurringCharge.activeChargesFilter")}
              />
            )}
            sx={billingRecurringChargeStyles.commonStyles.widthFixed}
          />
        </Stack>

        <Stack direction="row">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayMarked}
                  onChange={(event) => {
                    setIsFindEnabled(true);
                    setResultSet([]);
                    setNoResults(false);

                    if (event && event?.target) {
                      setDisplayMarked(event?.target?.checked);
                    }
                  }}
                />
              }
              label={t("billingRecurringCharge.filterCheck")}
            />
          </FormGroup>
        </Stack>

        <Stack direction="row" gap={spacing.gap}>
          <Button
            id="newBtnfilter"
            variant="outlined"
            onClick={() => {
              setIsClearClicked(true);
              setNoResults(false);
            }}
          >
            {t("common.clear")}
          </Button>
          <Button
            id="saveBtnfilter"
            variant="contained"
            type="submit"
            disabled={!isFindEnabled}
          >
            {t("common.find")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default BillingRecurringChargeSearchFilter;
