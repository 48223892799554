import { Stack } from "@mui/material";
import WorkManagementTab from "components/open-media-processing/work-management/WorkManagementTabs";
import { PageTitle } from "components/shared";
import { t } from "i18next";
import { useState } from "react";

const WorkManagement = () => {
  const [tabName, setTabName] = useState(t("workManagement.pickingTab"));
  return (
    <Stack>
      <PageTitle title={`${t("workManagement.title")} - ${tabName}`} />
      <WorkManagementTab tabName={tabName} setTabName={setTabName} />
    </Stack>
  );
};
export default WorkManagement;
