import {
  Button,
  Grid,
  Paper,
  TableContainer,
  Tooltip,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  N_CHECKER,
  Y_CHECKER,
  dateFormatWithoutTime
} from "utils/constants";
import { BillingStatusStyles } from "./BillingStatusStyles";

const BillingStatusTable = ({
  billRunsForTheBranch,
  handleRowClick,
  selectedRow,
  handlePrintButtonClick,
  handleTransactionButtonClick,
  handleErrorLogButtonClick,
  handleCompleteClick,
  handleRunButtonClick,
  isCompleteButtonDisabled,
  isTransactionButtonDisabled,
  isErrorLogButtonDisabled,
  isRunButtonDisabled,
  handleResetButtonClick,
  isResetButtonDisabled,
  localeByBranch
}) => {
  //Table columns
  const columns = [
    {
      name: "",
      label: ""
    },
    {
      name: "descr",
      label: t("billingStatus.phase")
    },
    {
      name: "completed_date_time",
      label: t("billingStatus.date"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = billRunsForTheBranch[dataIndex];
          const { completed_date_time } = rowData;
          if (completed_date_time) {
            return dayjs(completed_date_time)
              .locale(localeByBranch)
              .format(dateFormatWithoutTime);
          }
        }
      }
    },
    {
      name: "completed_flag",
      label: t("billingStatus.status"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = billRunsForTheBranch[dataIndex];
          const { completed_flag, completed_date_time, started_date_time } =
            rowData;

          if (completed_flag === Y_CHECKER) {
            return t("billingStatus.complete");
          } else if (
            (completed_flag === N_CHECKER && completed_date_time) ||
            (completed_flag === N_CHECKER && started_date_time)
          ) {
            return t("billingStatus.inProgress");
          } else {
            return t("billingStatus.incomplete");
          }
        }
      }
    },
    {
      name: "message",
      label: t("billingStatus.information"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = billRunsForTheBranch[dataIndex];
          const { message } = rowData;

          // Truncate message to 75 characters
          const truncatedMessage =
            message.length > 200 ? `${message.slice(0, 75)}...` : message;

          return (
            <Tooltip title={message.length > 200 ? message : ""}>
              <span>{truncatedMessage}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "last_mod_user",
      label: t("billingStatus.user"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = billRunsForTheBranch[dataIndex];
          const { completed_flag, last_mod_user, started_date_time } = rowData;
          if (completed_flag === Y_CHECKER) {
            return last_mod_user;
          } else if (completed_flag === N_CHECKER && started_date_time) {
            return last_mod_user;
          }
        }
      }
    },
    {
      name: "elapsed_time",
      label: t("billingStatus.time"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = billRunsForTheBranch[dataIndex];
          const { completed_flag, elapsed_time } = rowData;
          if (completed_flag === Y_CHECKER) {
            return Number(elapsed_time).toFixed(2);
          }
        }
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: billRunsForTheBranch?.length && true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={isResetButtonDisabled()}
            onClick={handleResetButtonClick}
          >
            {t("billingStatus.reset")}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={isTransactionButtonDisabled()}
            onClick={handleTransactionButtonClick}
          >
            {t("billingStatus.transactions")}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={isErrorLogButtonDisabled()}
            onClick={handleErrorLogButtonClick}
          >
            {t("billingStatus.errorLog")}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={isCompleteButtonDisabled()}
            onClick={handleCompleteClick}
          >
            {t("billingStatus.complete")}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={isRunButtonDisabled()}
            onClick={handleRunButtonClick}
          >
            {t("billingStatus.run")}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={billRunsForTheBranch?.length === 0}
            onClick={handlePrintButtonClick}
          >
            {t("common.print")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          billRunsForTheBranch?.length === 0 &&
          `${t("common.tableTextWithoutInputs")}`
      }
    },
    // selectableRowsOnClick: true,
    onRowClick: handleRowClick,
    setRowProps: (row, dataIndex) => {
      const rowData = billRunsForTheBranch[dataIndex];
      const completedFlag = rowData ? rowData.completed_flag : null;

      return {
        style: {
          backgroundColor:
            dataIndex === selectedRow
              ? BillingStatusStyles?.selectedColor
              : completedFlag === Y_CHECKER
                ? BillingStatusStyles?.completeColor
                : ""
        }
      };
    }
  };

  return (
    <>
      <Paper sx={BillingStatusStyles?.tablePaper}>
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={billRunsForTheBranch}
            title={
              <Typography style={BillingStatusStyles.titleStyle}>
                {billRunsForTheBranch?.length} {t("common.recordsFound")}
              </Typography>
            }
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default BillingStatusTable;
