import { Box, Stack, TextField, Typography } from "@mui/material";
import { DatePickerField, SelectField } from "components/core";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { DEFAULT_LOCALE, Y_CHECKER } from "utils/constants";
import { MDRPickupFormStyles } from "../MediaDestructionRequestPickup.style";
import BoxTypes from "../boxtypes-form/BoxTypes";

const MDRPickupSchedule = ({
  diableSchedule,
  handleScheduleBoxTypes,
  scheduleBoxTypes,
  trackingRequirements,
  scheduleErrorMsg,
  scheduleServiceLocation,
  showScheduleModified,
  setBoxtypeWraningSchedule,
  boxtypeWraningSchedule,
  handleErrorWarning,
  localeByBranch
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const minDate = dayjs();
  const maxDate = dayjs(t("requestSearch.maximumDate"));
  const { authInfo } = useSelector(selectAuth);
  const { id } = useParams();
  const handleClickBoxTypeWraning = () => {
    setBoxtypeWraningSchedule(false);
  };
  const onHoldStatus =
    formik.values.mdr_on_hold_status === Y_CHECKER ? true : false;
  return (
    <>
      <Stack gap={spacing.horizontalMargin20}>
        {/* <<<<======= Schedule pickup =====>>>>*/}
        <Stack gap={spacing.horizontalMargin20}>
          <Typography sx={MDRPickupFormStyles.Heading} variant="h5">
            {t("pickup.scheduleService")}
          </Typography>
          <Stack gap={spacing.verticalMargin20} direction={"row"}>
            <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
              <SelectField
                disabled
                id="scheduleTrackingRequirements"
                labelId="schedule-trackingRequirements-label"
                label={t("pickup.trackingRequirements")}
                name="scheduleTrackingRequirements"
                options={trackingRequirements}
                value={formik.values.scheduleTrackingRequirements}
                onChange={(event) => {
                  formik.setFieldValue(
                    "scheduleTrackingRequirements",
                    event.target.value
                  );
                }}
                sx={MDRPickupFormStyles.widthPercentage}
              />
            </Box>
            <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
              <DatePickerField
                disabled={diableSchedule || onHoldStatus}
                label={t("pickup.pickupDateLabel")}
                name="schedulePreferredPickupDate"
                id="schedulePreferredPickupDate"
                locale={localeByBranch || DEFAULT_LOCALE}
                value={formik.values.schedulePreferredPickupDate}
                onChange={(newValue) => {
                  formik.setFieldValue("schedulePreferredPickupDate", newValue);
                }}
                sx={MDRPickupFormStyles.DatePickerField}
                minDate={minDate}
                maxDate={maxDate}
                error={
                  (scheduleErrorMsg ||
                    Boolean(formik.touched.schedulePreferredPickupDate)) &&
                  Boolean(formik.errors.schedulePreferredPickupDate)
                }
                onBlur={formik.handleBlur}
                helperText={
                  (scheduleErrorMsg ||
                    formik.touched.schedulePreferredPickupDate) &&
                  formik.errors.schedulePreferredPickupDate
                }
              />
            </Box>
            {formik.values.schedulePreferredPickupDate && (
              <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                <SelectField
                  disabled={diableSchedule || onHoldStatus}
                  id="scheduleServiceLocation"
                  labelId="scheduleServiceLocation-label"
                  label={t("pickup.serviceLocationLabel")}
                  name="scheduleServiceLocation"
                  options={scheduleServiceLocation}
                  value={formik.values.scheduleServiceLocation}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "scheduleServiceLocation",
                      event.target.value
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    scheduleErrorMsg &&
                    formik.touched.scheduleServiceLocation &&
                    Boolean(formik.errors.scheduleServiceLocation)
                  }
                  helperText={
                    scheduleErrorMsg &&
                    formik.touched.scheduleServiceLocation &&
                    formik.errors.scheduleServiceLocation
                  }
                />
              </Box>
            )}
          </Stack>
          {formik.values.schedulePreferredPickupDate && (
            <Box>
              <TextField
                required
                disabled={diableSchedule || onHoldStatus}
                label={t("pickup.pickupInstructions")}
                name="schedulePickupInstructions"
                id="schedulePickupInstructions"
                value={formik.values.schedulePickupInstructions}
                onBlur={formik.handleBlur}
                error={
                  (scheduleErrorMsg ||
                    formik.touched.schedulePickupInstructions) &&
                  Boolean(formik.errors.schedulePickupInstructions)
                }
                helperText={
                  (scheduleErrorMsg ||
                    formik.touched.schedulePickupInstructions) &&
                  formik.errors.schedulePickupInstructions
                }
                onChange={formik.handleChange}
                fullWidth
                multiline
                rows={3}
                style={MDRPickupFormStyles.widthPercentage}
              />
            </Box>
          )}
        </Stack>
        {/* <<<<=======  BoxTypes for Schedule Service =====>>>>*/}
        {formik.values.schedulePreferredPickupDate && (
          <Box>
            <Typography
              sx={MDRPickupFormStyles.Heading}
              variant="h5"
              id="boxtypelabel"
            >
              {t("pickup.boxTypeLabel")}
            </Typography>
          </Box>
        )}
        {/* ------------------- BoxType Alert ------------------- */}
        {boxtypeWraningSchedule &&
          handleErrorWarning(
            handleClickBoxTypeWraning,
            t("pickup.boxTypeError")
          )}
        {showScheduleModified && (
          <Box>
            <Stack gap="2em" direction={"row"}>
              <Typography
                disabled={diableSchedule || onHoldStatus}
                id="modifiedDate"
              >
                {t("pickup.modifiedDate")}:{" "}
                {formik.values.scheduleServiceModifiedDate}
              </Typography>
              <Typography
                disabled={diableSchedule || onHoldStatus}
                id="performedBy"
              >
                {t("pickup.performedBy")} :{" "}
                <span>
                  {" "}
                  {id && formik.values.loggedEmployeeName !== ""
                    ? formik.values.loggedEmployeeName
                    : authInfo?.displayName}
                </span>
              </Typography>
            </Stack>
          </Box>
        )}
        {formik.values.schedulePreferredPickupDate && (
          <BoxTypes
            boxTypes={scheduleBoxTypes}
            diableSITAD={diableSchedule || onHoldStatus}
            boxTypesVal={handleScheduleBoxTypes}
            calledFromSITAD={false}
            fieldName={"boxTypeValSchedule"}
          />
        )}
      </Stack>
    </>
  );
};

export default MDRPickupSchedule;
