import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { AddCircleIcon } from "assets/images";
import CloseFilledIcon from "assets/images/CloseFilled.svg";
import PencilIcon from "assets/images/pencil.svg";
import { CircularLoader, SearchField } from "components/core";
import { useFormik } from "formik";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import {
  useGetPricingContractFlagMutation,
  useGetRequiredInsertsCountMutation
} from "services/api";
import {
  getGlobalAttributeValueByTypeId,
  getMessageByIdFromMessage
} from "services/common";
import {
  addIssueContainerOrTransport,
  removeIssueContainerOrTransport,
  selectMediaRequest,
  setErrorMessage,
  updateIssueContainerOrTransport
} from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  GLOBAL_ATTRIBUTE_VALUES,
  MEDIA_PROGRAM_TYPES,
  N_CHECKER,
  REQUEST_NEW_ITEM_TYPE,
  Y_CHECKER
} from "utils/constants";
import {
  MESSAGE_10187,
  MESSAGE_63872,
  NOT_APPLICABLE
} from "utils/constants/request-module/MediaRequestDetailConstants";
import { limitFormikTextFieldMaxValue } from "utils/helpers";
import { MediaRequestDetailFormStyles } from "../../media-request-detail-form/MediaRequestDetailForm.Styles";
import {
  IssueClosedContainers_tableBody,
  IssueContainersStyles
} from "../IssueContainersTab.Styles";
import CustomerMediaIdentifierModal from "../customer-media-identifier-modal/CustomerMediaIdentifierModal";
import SelectInsertsModal from "../select-inserts-modal/SelectInsertsModal";

const formInitialValues = {
  id: 0,
  logical_vault_code: 0,
  logical_vault: "",
  media_type_id: 0,
  media_type_descr: "",
  insert_configuration: "",
  quantity: 0,
  have_locks: true,
  lock_type_code: "",
  lock_type: "",
  is_owned: false,
  is_managed: false,
  customer_owned_container_flag: N_CHECKER,
  customer_media_descr_list: "",
  is_managed_container_flag: N_CHECKER
};

const viewClosedContainersColumnsDefinition = [
  {
    name: "logical_vault_code",
    label: i18n.t("mediaRequestDetail.logicalVaultCaps")
  },
  {
    name: "media_type_descr",
    label: i18n.t("mediaRequestDetail.mediaTypeCaps")
  },
  {
    name: "insert_configuration",
    label: i18n.t("mediaRequestDetail.insertConfigCaps"),
    options: {
      customBodyRender: (value) => (value ? value : NOT_APPLICABLE)
    }
  },
  {
    name: "quantity",
    label: i18n.t("mediaRequestDetail.contQtyCaps")
  },
  {
    name: "lock_type_code",
    label: i18n.t("mediaRequestDetail.locksCaps"),
    options: {
      customBodyRender: (value) =>
        value
          ? i18n.t("mediaRequestDetail.yes")
          : i18n.t("mediaRequestDetail.no")
    }
  },
  {
    name: "lock_type_code",
    label: i18n.t("mediaRequestDetail.lockTypeCaps")
  },
  {
    name: "customer_owned_container_flag",
    label: i18n.t("mediaRequestDetail.ownedCaps"),
    options: {
      customBodyRender: (value) =>
        value === Y_CHECKER
          ? i18n.t("mediaRequestDetail.yes")
          : i18n.t("mediaRequestDetail.no")
    }
  }
];

const viewClosedContainersColumnsDefinition_withManagedContainer = [
  ...viewClosedContainersColumnsDefinition,
  {
    name: "is_managed_container_flag",
    label: i18n.t("mediaRequestDetail.managedCaps"),
    options: {
      customBodyRender: (value) =>
        value === Y_CHECKER
          ? i18n.t("mediaRequestDetail.yes")
          : i18n.t("mediaRequestDetail.no")
    }
  }
];

const IssueClosedContainers = (props) => {
  const { t } = useTranslation();
  const { issueContainers } = useSelector(selectMediaRequest); // select media request slice
  const dispatch = useDispatch(); // redux actions dispatcher
  const [selectedRow, setSelectedRow] = useState(-1);
  const message10187 = useMemo(async () => {
    const resp = await getMessageByIdFromMessage({
      message_id: MESSAGE_10187
    });
    return resp;
  }, []); // message 10187
  const [showCMI, setShowCMI] = useState(false); // show or hide CMI field
  const [showManagedContainer, setShowManagedContainer] = useState(false); // show or hide Managed Container field

  const dataTableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    selectableRows: t("mediaRequestDetail.none"),
    jumpToPage: false,
    pagination: false,
    tableBodyHeight: IssueClosedContainers_tableBody,
    tableBodyMaxHeight: IssueClosedContainers_tableBody,
    textLabels: {
      body: {
        noMatch: props.isLoading ? (
          <CircularLoader loading={props.isLoading} />
        ) : (
          t("mediaRequestDetail.noItemsToDisplay")
        )
      }
    }
  };

  const [getPricingContractFlag, { error: pricingContractFlagError }] =
    useGetPricingContractFlagMutation(); // get pricing contract active flag

  // set row data to the form
  const handleEditRow = (rowIndex) => {
    const data = issueContainers.containers[rowIndex];
    closedContainerForm.setValues({
      ...formInitialValues,
      logical_vault_code: data.logical_vault_code,
      logical_vault: data.logical_vault,
      media_type_id: data.media_type_id,
      media_type_descr: data.media_type_descr,
      insert_configuration: data?.insert_configuration,
      quantity: data.quantity,
      have_locks: data?.have_locks,
      lock_type_code: data?.lock_type_code,
      lock_type: data?.lock_type,
      is_owned: data?.is_owned,
      is_managed: data?.is_managed,
      customer_owned_container_flag: data?.customer_owned_container_flag,
      is_managed_container_flag: data?.is_managed_container_flag,
      customer_media_descr_list: data?.customer_media_descr_list
    });
    setInsertConfigs(data.insertConfigs);
    setMediaIdentifiers(data.mediaIdentifiers);
    setSelectedRow(rowIndex);
  };

  // column definition of the issue closed containers data table
  const closedContainersColumnsDefinition = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnEdit${value}`}
            aria-label="edit"
            onClick={() => handleEditRow(tableMeta.rowIndex)}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={PencilIcon} alt="edit icon" />
          </IconButton>
        )
      }
    },
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() =>
              dispatch(removeIssueContainerOrTransport(tableMeta.rowIndex))
            }
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "logical_vault",
      label: t("mediaRequestDetail.logicalVaultCaps")
    },
    {
      name: "media_type_descr",
      label: t("mediaRequestDetail.mediaTypeCaps")
    },
    {
      name: "insert_configuration",
      label: t("mediaRequestDetail.insertConfigCaps"),
      options: {
        customBodyRender: (value) => (value ? value : NOT_APPLICABLE)
      }
    },
    {
      name: "quantity",
      label: t("mediaRequestDetail.contQtyCaps")
    },
    {
      name: "have_locks",
      label: t("mediaRequestDetail.locksCaps"),
      options: {
        customBodyRender: (value) =>
          value ? t("mediaRequestDetail.yes") : t("mediaRequestDetail.no")
      }
    },
    {
      name: "lock_type_code",
      label: t("mediaRequestDetail.lockTypeCaps")
    },
    {
      name: "customer_owned_container_flag",
      label: t("mediaRequestDetail.ownedCaps"),
      options: {
        customBodyRender: (value) =>
          value === Y_CHECKER
            ? t("mediaRequestDetail.yes")
            : t("mediaRequestDetail.no")
      }
    }
  ];

  // column definition of the issue closed containers with CMI data table
  const closedContainersColumnsDefinition_withCMI = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnEdit${value}`}
            aria-label="edit"
            onClick={() => handleEditRow(tableMeta.rowIndex)}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={PencilIcon} alt="edit icon" />
          </IconButton>
        )
      }
    },
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() =>
              dispatch(removeIssueContainerOrTransport(tableMeta.rowIndex))
            }
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "logical_vault",
      label: t("mediaRequestDetail.logicalVaultCaps")
    },
    {
      name: "media_type_descr",
      label: t("mediaRequestDetail.mediaTypeCaps")
    },
    {
      name: "insert_configuration",
      label: t("mediaRequestDetail.insertConfigCaps"),
      options: {
        customBodyRender: (value) => (value ? value : NOT_APPLICABLE)
      }
    },
    {
      name: "quantity",
      label: t("mediaRequestDetail.contQtyCaps")
    },
    {
      name: "have_locks",
      label: t("mediaRequestDetail.locksCaps"),
      options: {
        customBodyRender: (value) =>
          value ? t("mediaRequestDetail.yes") : t("mediaRequestDetail.no")
      }
    },
    {
      name: "lock_type_code",
      label: t("mediaRequestDetail.lockTypeCaps")
    },
    {
      name: "customer_owned_container_flag",
      label: t("mediaRequestDetail.ownedCaps"),
      options: {
        customBodyRender: (value) =>
          value === Y_CHECKER
            ? t("mediaRequestDetail.yes")
            : t("mediaRequestDetail.no")
      }
    },
    {
      name: "customer_media_descr_list",
      label: t("mediaRequestDetail.customerMediaIdentifierCaps")
    }
  ];

  // column definition of the issue closed containers with Managed Containers data table
  const closedContainersColumnsDefinition_withManagedContainer = [
    ...closedContainersColumnsDefinition,
    {
      name: "is_managed_container_flag",
      label: t("mediaRequestDetail.managedCaps"),
      options: {
        customBodyRender: (value) =>
          value === Y_CHECKER
            ? t("mediaRequestDetail.yes")
            : t("mediaRequestDetail.no")
      }
    }
  ];

  // column definition of the issue closed containers with CMI and Managed Containers data table
  const closedContainersColumnsDefinition_withCMI_withManagedContainer = [
    ...closedContainersColumnsDefinition,
    {
      name: "customer_media_descr_list",
      label: t("mediaRequestDetail.customerMediaIdentifierCaps")
    },
    {
      name: "is_managed_container_flag",
      label: t("mediaRequestDetail.managedCaps"),
      options: {
        customBodyRender: (value) =>
          value === Y_CHECKER
            ? t("mediaRequestDetail.yes")
            : t("mediaRequestDetail.no")
      }
    }
  ];

  // Add a closed container form schema
  const closedContainerValidationSchema = Yup.object().shape({
    logical_vault_code: Yup.number()
      .moreThan(0, t("mediaRequestDetail.selectLogicalVault"))
      .required(t("mediaRequestDetail.commonRequiredError")),
    logical_vault: Yup.string(),
    media_type_id: Yup.number()
      .moreThan(0, t("mediaRequestDetail.selectMediaType"))
      .required(t("mediaRequestDetail.commonRequiredError")),
    media_type_descr: Yup.string(),
    insert_configuration: Yup.string().test(
      "insertConfigurationRequired",
      i18n.t("mediaRequestDetail.insertConfigurationRequired"),
      (value) => {
        if (requiredInsertsCount > 0) {
          return value !== undefined && value !== null && value !== "";
        }
        return true;
      }
    ),
    quantity: Yup.number()
      .moreThan(0, message10187[0]?.descr)
      .required(message10187[0]?.descr),
    have_locks: Yup.bool(),
    lock_type_code: Yup.string().test(
      "lockTypeRequired",
      i18n.t("mediaRequestDetail.lockTypeIsRequired"),
      (value, context) => {
        if (context.parent?.have_locks) {
          return value !== undefined && value !== null && value !== "";
        }
        return true;
      }
    ),
    lock_type: Yup.string(),
    is_owned: Yup.bool(),
    is_managed: Yup.bool(),
    customer_owned_container_flag: Yup.string(),
    customer_media_identifier: Yup.string()
  });

  const [openMediaIdentifierModal, setOpenMediaIdentifierModal] =
    useState(false);
  const [openInsertConfigModal, setOpenInsertConfigModal] = useState(false);
  const [insertConfigs, setInsertConfigs] = useState([]);
  const [mediaIdentifiers, setMediaIdentifiers] = useState([]);
  const closedContainerForm = useFormik({
    initialValues: formInitialValues,
    validationSchema: closedContainerValidationSchema,
    onSubmit: (values, formikHelpers) => {
      if (closedContainerForm.isValid) {
        const newRow = {
          ...values,
          insertConfigs: insertConfigs,
          customer_media_descr_list:
            values?.customer_media_descr_list.replaceAll(" ", ""),
          mediaIdentifiers: mediaIdentifiers,
          is_managed_container_flag: values?.is_managed ? Y_CHECKER : N_CHECKER,
          customer_owned_container_flag: values?.is_owned
            ? Y_CHECKER
            : N_CHECKER
        };
        if (selectedRow < 0) {
          dispatch(
            addIssueContainerOrTransport({
              ...newRow,
              id: Math.round(Math.random() * 100)
            })
          );
        } else {
          dispatch(
            updateIssueContainerOrTransport({
              index: selectedRow,
              data: newRow
            })
          );
          setSelectedRow(-1);
        }
        setInsertConfigs([]);
        resetRequiredInsertsCount();
        setMediaIdentifiers([]);
        formikHelpers.resetForm();
      }
    }
  });

  const [
    getRequiredInsertsCount,
    { data: requiredInsertsCount = 0, reset: resetRequiredInsertsCount }
  ] = useGetRequiredInsertsCountMutation();

  // init component
  useEffect(() => {
    getGlobalAttributeValueByTypeId({
      id: GLOBAL_ATTRIBUTE_VALUES.VALUE_96
    }).then((resp) => setShowCMI((prevState) => resp === Y_CHECKER));
  }, []);

  // customer value changed
  useEffect(() => {
    getPricingContractFlag({
      customerId: props.customerId
    })
      .then((resp) => {
        if (resp && resp?.data) {
          if (resp?.data?.pricing_contract_active_flag === Y_CHECKER) {
            setShowManagedContainer(true);
          } else {
            setShowManagedContainer(false);
          }
        } else {
          setShowManagedContainer(false);
        }
      })
      .catch(() => {
        dispatch(setErrorMessage(pricingContractFlagError));
      });
    // eslint-disable-next-line
  }, [props.customerId]);

  // check whether the item requires inserts
  useEffect(() => {
    if (Number(closedContainerForm.values?.media_type_id) > 0) {
      getRequiredInsertsCount({
        mediaTypeId: closedContainerForm.values?.media_type_id,
        mediaProgramTypeId: MEDIA_PROGRAM_TYPES.CONTAINER.ID
      })
        .unwrap()
        .then(async (resp) => {
          if (Number(resp) === 0) {
            await closedContainerForm.setFieldValue(
              "insert_configuration",
              NOT_APPLICABLE
            );
            setInsertConfigs((prevState) => []);
          }

          if (Number(resp) > 0 && selectedRow < 0) {
            await closedContainerForm.setFieldValue("insert_configuration", "");
            // if the row is new then show the insert configuration modal automatically
            setOpenInsertConfigModal((prevState) => true);
          }
        });
    } else {
      closedContainerForm.setFieldValue("insert_configuration", "");
      setInsertConfigs((prevState) => []);
      resetRequiredInsertsCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closedContainerForm.values?.media_type_id]);

  // clear next field values when a field is cleared, fire the dirty event when a value is added
  useEffect(() => {
    if (Number(closedContainerForm.values?.logical_vault_code) === 0) {
      closedContainerForm.resetForm();
      props.onFormDirty(false);
    } else {
      props.onFormDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closedContainerForm.values?.logical_vault_code]);

  // submit form if the tab is changed and the form is dirty
  useEffect(() => {
    if (
      props.startValidation &&
      Number(closedContainerForm.values.logical_vault_code) > 0
    ) {
      closedContainerForm.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.startValidation]);

  // set eror message when containers quantity is changed less than the media identifers enetered
  const handleQuantityOnChange = async (e) => {
    if (
      mediaIdentifiers.length > 0 &&
      e.target.value < mediaIdentifiers.length
    ) {
      const message63872 = await getMessageByIdFromMessage({
        message_id: MESSAGE_63872
      });
      dispatch(
        setErrorMessage(
          `${message63872[0]?.descr.replace("|", mediaIdentifiers.length)}`
        )
      );
      window.scrollTo({ top: 0 });
    } else {
      limitFormikTextFieldMaxValue(closedContainerForm, e, 150);
    }
  };

  return (
    <>
      {/* Add new container form */}
      <Grid
        id="issueClosedContainersGrid1"
        container
        rowSpacing={spacing.verticalMargin20}
        sx={{
          padding: "1rem"
        }}
      >
        <Grid
          id="issueClosedContainersGrid2"
          item
          xs={12}
          container
          columnSpacing={spacing.verticalMargin20}
          sx={{ marginLeft: "-20px" }}
        >
          {/* Logical Vault field */}
          <Grid id="issueClosedContainersGrid3" item xs={3}>
            <SearchField
              id="issueClosedContainersLogicalVaultCode"
              name="logical_vault_code"
              disabled={Boolean(props?.requestId)}
              size="small"
              loading={props.logicalVaultsLoading}
              options={issueContainers.logicalVaults.container}
              label={t("mediaRequestDetail.logicalVault")}
              required
              value={closedContainerForm.values?.logical_vault}
              error={
                closedContainerForm.touched.logical_vault_code &&
                Boolean(closedContainerForm.errors.logical_vault_code)
              }
              helperText={
                closedContainerForm.touched.logical_vault_code &&
                closedContainerForm.errors.logical_vault_code
              }
              onChange={(_, value) => {
                closedContainerForm.setFieldValue(
                  "logical_vault_code",
                  value?.value || 0
                );
                closedContainerForm.setFieldValue(
                  "logical_vault",
                  value?.label || ""
                );
              }}
            />
          </Grid>

          {/* Media Type field */}
          <Grid id="issueClosedContainersGrid4" item xs={3}>
            <SearchField
              id="issueClosedContainersMdiaTypeId"
              disabled={
                Boolean(props?.requestId) ||
                Boolean(closedContainerForm.values?.logical_vault_code === 0)
              }
              size="small"
              loading={props.mediaTypesLoading}
              options={issueContainers.mediaTypes.container}
              name="media_type_id"
              label={t("mediaRequestDetail.mediaType")}
              required
              value={closedContainerForm.values?.media_type_descr}
              error={
                closedContainerForm.touched.media_type_id &&
                Boolean(closedContainerForm.errors.media_type_id)
              }
              helperText={
                closedContainerForm.touched.media_type_id &&
                closedContainerForm.errors.media_type_id
              }
              onChange={(_, value) => {
                closedContainerForm.setFieldValue(
                  "media_type_id",
                  value?.value || 0
                );
                closedContainerForm.setFieldValue(
                  "media_type_descr",
                  value?.label || ""
                );
                closedContainerForm.setFieldValue(
                  "lock_type_code",
                  props.defaultLockType
                );
              }}
            />
          </Grid>

          {/* Insert Configuration */}
          <Grid id="issueClosedContainersGrid5" item xs={3}>
            <TextField
              id="issueClosedContainersInsertConfiguration"
              name="insert_configuration"
              disabled
              label={t("mediaRequestDetail.insertConfig")}
              placeholder={t("mediaRequestDetail.insertConfig")}
              value={closedContainerForm.values?.insert_configuration}
              size="small"
              fullWidth
              required={requiredInsertsCount > 0}
              error={
                closedContainerForm.touched.insert_configuration &&
                Boolean(closedContainerForm.errors.insert_configuration)
              }
              helperText={
                closedContainerForm.touched.insert_configuration &&
                closedContainerForm.errors.insert_configuration
              }
              InputProps={{
                endAdornment: requiredInsertsCount > 0 && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setOpenInsertConfigModal((prevState) => true)
                      }
                      disabled={Boolean(props?.requestId)}
                    >
                      <SvgIcon viewBox="0 0 24 24">
                        <AddCircleIcon
                          fill={"var(--color-im-mid-blue, #1b75bc)"}
                        />
                      </SvgIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          {/* Container quantity field */}
          <Grid id="issueClosedContainersGrid6" item xs={3}>
            <TextField
              id="issueClosedContainersContainerQty"
              disabled={
                Boolean(props?.requestId) ||
                Boolean(closedContainerForm.values?.insert_configuration === "")
              }
              name="quantity"
              type="number"
              label={t("mediaRequestDetail.contQty")}
              size="small"
              fullWidth
              required
              value={closedContainerForm.values?.quantity}
              error={
                closedContainerForm.touched.quantity &&
                Boolean(closedContainerForm.errors.quantity)
              }
              helperText={
                closedContainerForm.touched.quantity &&
                closedContainerForm.errors.quantity
              }
              onChange={handleQuantityOnChange}
            />
          </Grid>
        </Grid>
        <Grid
          id="issueClosedContainersGrid7"
          item
          xs={12}
          sx={{
            display: "flex",
            columnGap: spacing.verticalMargin20,
            alignItems: "start",
            "> label": { margin: 0 }
          }}
        >
          {/* Locks */}
          <Grid item>
            <FormControlLabel
              id="issueClosedContainersHaveLocksLabel"
              disabled={
                Boolean(props?.requestId) ||
                Boolean(closedContainerForm.values?.insert_configuration === "")
              }
              name="have_locks"
              control={
                <Checkbox
                  id="issueClosedContainersHaveLocks"
                  size="small"
                  color="primary"
                />
              }
              label={t("mediaRequestDetail.locks")}
              checked={
                closedContainerForm.values?.logical_vault?.length > 0 &&
                closedContainerForm.values?.have_locks
              }
              onChange={closedContainerForm.handleChange}
            />
          </Grid>

          {/* Lock Types */}
          <Grid item>
            <SearchField
              id="issueClosedContainersLockTypeCode"
              disabled={
                Boolean(props?.requestId) ||
                Boolean(closedContainerForm.values?.insert_configuration === "")
              }
              size="small"
              loading={props.lockTypesLoading}
              options={props.lockTypes}
              name="lock_type_code"
              label={
                closedContainerForm.values?.have_locks
                  ? t("mediaRequestDetail.lockTypeStarred")
                  : t("mediaRequestDetail.lockType")
              }
              value={closedContainerForm.values?.lock_type_code}
              error={
                closedContainerForm.touched.lock_type_code &&
                Boolean(closedContainerForm.errors.lock_type_code)
              }
              helperText={
                closedContainerForm.touched.lock_type_code &&
                closedContainerForm.errors.lock_type_code
              }
              sx={{ width: "18.8rem" }}
              onChange={(_, value) => {
                closedContainerForm.setFieldValue(
                  "lock_type_code",
                  value?.value || ""
                );
                closedContainerForm.setFieldValue(
                  "lock_type",
                  value?.label || ""
                );
              }}
            />
          </Grid>

          {/* Owned */}
          <Grid item>
            <FormControlLabel
              id="issueClosedContainersOwnedLabel"
              disabled={
                Boolean(props?.requestId) ||
                Boolean(closedContainerForm.values?.insert_configuration === "")
              }
              name="is_owned"
              control={
                <Checkbox
                  id="issueClosedContainersOwned"
                  size="small"
                  color="primary"
                />
              }
              label={t("mediaRequestDetail.owned")}
              checked={closedContainerForm.values?.is_owned}
              onChange={closedContainerForm.handleChange}
            />
          </Grid>

          {/* Customer Media Identifier */}
          {showCMI && (
            <Grid item>
              <TextField
                id="issueClosedContainersCstomerMediaIdentifier"
                name="customer_media_descr_list"
                disabled
                size="small"
                label={t("mediaRequestDetail.customerMediaIdentifier")}
                placeholder={t("mediaRequestDetail.customerMediaIdentifier")}
                value={closedContainerForm.values?.customer_media_descr_list}
                InputProps={{
                  endAdornment: closedContainerForm.values?.quantity > 0 && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setOpenMediaIdentifierModal((prevState) => true)
                        }
                        disabled={Boolean(props?.requestId)}
                      >
                        <SvgIcon viewBox="0 0 24 24">
                          <AddCircleIcon
                            {...IssueContainersStyles.AddCircleIconFill}
                          />
                        </SvgIcon>
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{ width: "18.8rem" }}
              />
            </Grid>
          )}

          {/* Managed Container */}
          {showManagedContainer && (
            <Grid item>
              <FormControlLabel
                id="issueClosedContainersManagedLabel"
                disabled={
                  Boolean(props?.requestId) ||
                  Boolean(
                    closedContainerForm.values?.insert_configuration === ""
                  )
                }
                name="is_managed"
                control={
                  <Checkbox
                    id="issueClosedContainersManaged"
                    size="small"
                    color="primary"
                  />
                }
                label={t("mediaRequestDetail.managed")}
                checked={closedContainerForm.values?.is_managed}
                onChange={closedContainerForm.handleChange}
              />
            </Grid>
          )}

          {/* Add button */}
          <Grid item>
            <Button
              id="issueClosedContainersAddButton"
              disabled={Boolean(props?.requestId)}
              variant="outlined"
              sx={{ width: "3.8rem" }}
              onClick={() => closedContainerForm.handleSubmit()}
            >
              {selectedRow < 0
                ? t("mediaRequestDetail.add")
                : t("mediaRequestDetail.update")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Containers data grid */}
      <MUIDataTable
        id="issueClosedContainersDatatable"
        columns={
          showManagedContainer
            ? props.requestId
              ? viewClosedContainersColumnsDefinition_withManagedContainer
              : showCMI
                ? closedContainersColumnsDefinition_withCMI_withManagedContainer
                : closedContainersColumnsDefinition_withManagedContainer
            : props.requestId
              ? viewClosedContainersColumnsDefinition
              : showCMI
                ? closedContainersColumnsDefinition_withCMI
                : closedContainersColumnsDefinition
        }
        data={
          issueContainers.containers[0]?.request_new_item_type_id !==
          REQUEST_NEW_ITEM_TYPE.INSERT.toString()
            ? issueContainers.containers
            : []
        }
        options={dataTableOptions}
      />

      {/* Insert Configurations modal */}
      {openInsertConfigModal && (
        <SelectInsertsModal
          open={openInsertConfigModal}
          requiredQuantity={Number(requiredInsertsCount)}
          selectedInsertConfigs={insertConfigs}
          onClose={(_) => setOpenInsertConfigModal((prevState) => !prevState)}
          onSubmit={(configs) => {
            setInsertConfigs(configs);
            closedContainerForm.setFieldValue(
              "insert_configuration",
              configs
                .map((item) => `${item.quantity} - ${item.insert_type}`)
                .toString()
                .replaceAll(",", " ")
            );
            setOpenInsertConfigModal((prevState) => false);
          }}
        />
      )}

      {/* Customer Media Identifier modal */}
      {openMediaIdentifierModal && (
        <CustomerMediaIdentifierModal
          open={openMediaIdentifierModal}
          containersRequested={closedContainerForm.values?.quantity}
          selectedMediaType={closedContainerForm.values?.media_type_descr}
          selectedIdentifiers={mediaIdentifiers}
          onClose={(_) =>
            setOpenMediaIdentifierModal((prevState) => !prevState)
          }
          onSubmit={(identifiers) => {
            setMediaIdentifiers(identifiers);
            closedContainerForm.setFieldValue(
              "customer_media_descr_list",
              identifiers
                .map((item) => item.mediaIdentifier)
                .toString()
                .replaceAll(",", " ")
            );
            setOpenMediaIdentifierModal((prevState) => false);
          }}
        />
      )}
    </>
  );
};

// Props definition of the component
IssueClosedContainers.propTypes = {
  customerId: PropTypes.number.isRequired, // ID of the customer
  requestId: PropTypes.number, // ID of the media request
  isLoading: PropTypes.bool, // loading containers of the media request
  onFormDirty: PropTypes.func, // if form is dirty event
  startValidation: PropTypes.bool, // start form validation or not
  logicalVaultsLoading: PropTypes.bool, // Logical vaults loading state
  mediaTypesLoading: PropTypes.bool, // Media types loading state
  lockTypesLoading: PropTypes.bool, // Lock types loading state
  defaultLockType: PropTypes.string // default lock type code
};

export default IssueClosedContainers;
