import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import InfoIcon from "assets/images/InfoOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { ConfrimPopupStyles } from "components/shared/confirm-popup/ConfirmPopupStyles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import { ERROR_TYPES, errorMsgs, numberConstants } from "utils/constants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData
} from "utils/helpers";

const DrEventReconciliationModal = ({
  modalPopupOpen,
  setReconciliationPopup,
  showCancel,
  setIsLoading,
  drId,
  sessonId,
  form
}) => {
  const { t } = useTranslation();
  const [throwInfoMessage, setThrowInfoMessage] = useState(null);
  const [getBodyText, setBodyText] = useState(null);
  const [emailDef, setEmailDef] = useState(null);
  const [emailError, setEmailError] = useState({ error: false, message: "" });
  //error message showing states
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  //warning message showing states
  const [throwWarning, setThrowWarning] = useState(false);
  const [throwWarningMessage, setThrowWarningMessage] = useState(false);

  //show warning message according to error code without changing message
  const getMessageBycode = async (code1, code2) => {
    const info = await getMessageFromCode(String(code1));
    const body = await getMessageFromCode(String(code2));
    if (info && body) {
      setThrowInfoMessage(info[0]?.descr);
      setBodyText(body[0]?.descr);
    }
  };

  //reset modal
  const resetModal = () => {
    setEmailDef(null);
    setEmailError({ error: false, message: "" });
    setThrowError(false);
    setThrowErrorMessage(false);
    setThrowWarning(false);
    setThrowWarningMessage(false);
  };

  //handle cancel modal
  const handleCancel = () => {
    setReconciliationPopup(false);
    resetModal();
  };

  //handle send email
  const handleSendEmail = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        dr_event_id: drId,
        email_list: form.values.email,
        session_guid: sessonId
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.sendEmail}`,
        1
      );
      if (data?.data) {
        const returnCode = data?.data[0][0].error;
        if (returnCode === numberConstants.zero) {
          handleCancel();
        } else if (returnCode === errorMsgs.errorCode20237) {
          const warningMsg = await getMessageFromCode(
            String(errorMsgs.errorCode20237)
          );
          setThrowWarning(true);
          setThrowWarningMessage(warningMsg[0]?.descr);
        }
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (emailDef) {
      setEmailError({ error: false, message: "" });
    }
  }, [emailDef]);

  //handle email change
  const handleEmailChange = (e) => {
    setEmailDef(e.target.value);
    form.handleChange({
      target: { name: "email", value: e.target.value }
    });
    if (e.target.value.length > 155) {
      setEmailError((pre) => ({
        ...pre,
        error: true,
        message: t("disasterRecovery.viewDrMediaPage.emailLength")
      }));
    }
  };

  useEffect(() => {
    getMessageBycode(errorMsgs.errorCode26169, errorMsgs.errorCode26170);
  }, []);

  return (
    <Paper elevation={24}>
      <Dialog
        open={modalPopupOpen}
        fullWidth={true}
        PaperProps={{
          sx: {
            ...ConfrimPopupStyles.dialogModal
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={ConfrimPopupStyles.paddingTitleStyle}
        >
          {/*Modal title*/}
          <Typography sx={ConfrimPopupStyles.titleStyle}>
            {t("disasterRecovery.viewDrMediaPage.drEventReconciliation")}
          </Typography>
        </DialogTitle>
        {/*Info Alert*/}
        <Collapse in={true}>
          <Alert severity="info" icon={<img src={InfoIcon} alt="info" />}>
            {throwInfoMessage}
          </Alert>
        </Collapse>
        <Divider />
        {/* ------------------ Body Section ------------------ */}{" "}
        <DialogContent sx={ConfrimPopupStyles.cardContentStyles}>
          {/* Error alert popup */}
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              sx={ConfrimPopupStyles.alert}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
          {/*Warning Alert*/}
          <Collapse in={throwWarning}>
            <Alert
              severity="warning"
              icon={<img src={WarningIcon} alt="warning" />}
              sx={ConfrimPopupStyles.alert}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowWarning(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwWarningMessage}
            </Alert>
          </Collapse>
          <Stack direction="column" spacing={spacing.horizontalMargin20}>
            {/*Body section text*/}
            <Typography
              sx={ConfrimPopupStyles.alertMessage}
              id="modal-description"
            >
              {getBodyText}
            </Typography>
            {/*EMail Textbox field*/}
            <TextField
              id="email"
              name="email"
              label={t("disasterRecovery.viewDrMediaPage.emailAddress")}
              fullWidth
              value={emailDef}
              onChange={(e) => handleEmailChange(e)}
              error={emailError.error}
              helperText={emailError.message}
              required
            />
          </Stack>
        </DialogContent>
        <Divider />
        {/* ------------------ Footer Section ------------------ */}{" "}
        <DialogActions sx={ConfrimPopupStyles.modalButton}>
          {/*Cancel button*/}
          {showCancel && (
            <Button variant="outlined" onClick={() => handleCancel()}>
              {t("common.cancel")}
            </Button>
          )}

          {/*Send button*/}
          <Button
            variant="contained"
            onClick={() => {
              handleSendEmail();
            }}
            disabled={!form.values.email}
          >
            {t("common.send")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default DrEventReconciliationModal;
