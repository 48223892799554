export const MediaListModalStyles = {
  buttonSX: {
    display: "flex",
    padding: "6px 16px !important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "unset",
    marginLeft: "0px !important"
  },
  tableStyles: {
    customScrollbar: {
      maxHeight: 300,
      overflow: "auto",
      padding: "10px",
      "&::-webkit-scrollbar": {
        width: "8px"
      },
      "&::-webkit-scrollbar-track": {
        background: "var(--color-om-scroll-track)"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "var(--color-om-scroll-thumb)",
        borderRadius: "10px"
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "var(--color-om-scroll-thumb-hover)"
      }
    }
  },
  editColumn: {
    width: "150px"
  },
  alertPadding: { marginBottom: "16px" },
  alertSize: { width: "100%" }
};
