import { Stack } from "@mui/material";
import GroupManagementLayout from "components/security-module/security-group-management/GroupManagementLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const SecurityGroupManagement = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("securityGroupManagement.pageTitle")} />
      <GroupManagementLayout />
    </Stack>
  );
};

export default SecurityGroupManagement;
