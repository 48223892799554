export const HolidayServiceStyles = {
  tablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  selectedColor: "var(--color-im-light-blue-100)",
  margin: {
    marginBottom: "20px"
  }
};
