import {
  assignTransportsToCustomer,
  getCslInfo,
  getCustLocationInfo,
  getRequestInfo,
  getRouteInfo,
  getRunInfo,
  getScheduleDate,
  rescheduleRequests
} from "services/common";
import { TAG_TYPES } from "utils/constants";
import { baseApi } from "../baseApi";

/**
 * @module rescheduleServiceApi reschedule service RTK Query endpoints
 */
export const rescheduleServiceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getScheduleDate: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getScheduleDate(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.scheduleDate]
    }),

    getCustLocationInfo: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getCustLocationInfo(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.custLocationInfo]
    }),
    getRequestInfo: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getRequestInfo(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestInfo]
    }),
    getCslInfo: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getCslInfo(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.cslInfo]
    }),
    getRouteInfo: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getRouteInfo(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.routeInfo]
    }),
    getRunInfo: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getRunInfo(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.runInfo]
    }),
    assignTransportsToCustomer: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await assignTransportsToCustomer(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.assignTransportsToCustomer]
    }),
    rescheduleRequests: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await rescheduleRequests(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.rescheduleRequests]
    })
  })
});
