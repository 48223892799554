import { Stack } from "@mui/material";
import EventRequestGeneratorLayout from "components/disaster-module/event-detail/EventRequestGeneratorLayout";
import { PageTitle } from "components/shared";
import { useParams } from "react-router-dom";
import i18n from "../../i18n";

const EventRequestGenerator = () => {
  const routeParams = useParams();
  const eventID = routeParams?.id;
  const customer = routeParams?.customerNumber;

  return (
    <Stack spacing={2}>
      <PageTitle
        title={i18n
          .t("disasterRecovery.eventDetail.requestGeneratorTitle")
          .replace("|", eventID)
          .replace("|", customer)}
      />
      <EventRequestGeneratorLayout />
    </Stack>
  );
};

export default EventRequestGenerator;
