const formCreateAndUpdateChargeStyles = {
  cardContentStack: { alignItems: "start", marginBottom: "20px" },
  tfInputLabelPtops: { shrink: true, style: { zIndex: 0 } },
  textFieldSX: { width: "310px", height: "64px" },
  buttonSX: {
    display: "flex",
    padding: "6px 16px !important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "unset",
    marginLeft: "0px !important"
  }
};

const tableSearchResultsAndCacheStyles = {
  mainStack: {
    boxShadow:
      "0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "9px",
    marginBottom: "5px",
    marginTop: "12px"
  },
  topButtonsBox: { display: "flex", justifyContent: "space-between" },
  nextButtonsBox: {
    display: "flex",
    gap: "20px"
  },
  tableContainer: { height: "300px", overflowY: "auto" },
  tableHead: {
    position: "sticky",
    top: 0,
    backgroundColor: "var(--color-white-100)"
  },
  tableCell: { color: "rgba(0,0,0,0.60)", fontWeight: 700 },
  tableTitle: { fontSize: "13px", width: "fit-content" },
  tableRow: {
    "&.Mui-selected": {
      backgroundColor: "var(--color-im-light-blue-100)"
    },
    "&.Mui-selected:hover": {
      backgroundColor: "var(--color-im-light-blue-100)"
    },
    "&:hover": {
      backgroundColor: "var(--color-im-grey-50)"
    }
  },
  tableCellBox: {
    minWidth: "0 !important",
    display: "flex",
    gap: "20px"
  },
  buttons: { textTransform: "none" },
  paper: {
    position: "relative",
    overflowX: "auto",
    boxShadow: "unset",
    borderRadius: "0 0 9px 9px !important"
  },
  tablePagination: { position: "sticky", bottom: 0 }
};

const commonStyles = {
  widthFixed: { width: "310px" },
  widthFull: { width: "100%" },
  widthFit: { width: "fit-content" },
  alignCenter: { alignItems: "center" },
  pointerCursor: { cursor: "pointer" },
  heightBox: { height: "20px" }
};

export const billingRecurringChargeStyles = {
  formCreateAndUpdateChargeStyles,
  tableSearchResultsAndCacheStyles,
  commonStyles
};
