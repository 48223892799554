import { Stack } from "@mui/material";
import CapacityBillingGenerationSearch from "components/billing-module/capacity-billing-generation-search-detail/CapacityBillingGenerationSearch";
import spacing from "styles/spacing";

const CapacityBillingGenerationSearchDetail = () => {
  return (
    <Stack spacing={spacing.horizontalMargin20}>
      <CapacityBillingGenerationSearch />
    </Stack>
  );
};

export default CapacityBillingGenerationSearchDetail;
