export const labelTemplateMaintanceStyles = {
  dividerMargin: { mt: 2 },
  testLabelDivider: { my: 4 },
  assignTransportsCard: { maxWidth: 600, margin: "20px auto", padding: 2 },
  assignTransportsBox: {
    display: "flex",
    alignItems: "center",
    margin: "10px"
  },
  assignTransportsAddButton: {
    marginLeft: 2,
    borderRadius: 2,
    border: "1px solid",
    textTransform: "none"
  },
  maintanence: {
    helper: { marginTop: "22px" },
    colorTitle: { color: "var(--color-im-grey-100)" }
  }
};
