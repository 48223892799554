import spacing from "styles/spacing";

const cardStyle_PRSPopUP = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "background.paper",
  boxShadow: 24
};

const mainTitle_PRSPopUP = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "32px",
  letterSpacing: "0.15px",
  color: "#1E417A",
  display: "flex",
  padding: "16px",
  alignItems: "center",
  alignSelf: "stretch",
  width: "552px",
  margin: 0
};

const modalCloseButton = {
  position: "absolute !important",
  top: "8px !important",
  right: "8px !important"
};

const modalClosweIcon = { width: "32px", height: "32px" };

const cardContentStyle_PRSPopUP = {
  display: "flex",
  height: "100%",
  padding: "20px 16px 20px 16px",
  margin: 0,
  flexDirection: "column",
  alignItems: "flex-start",
  flexShrink: 0,
  alignSelf: "stretch",
  rowGap: spacing.verticalMargin20
};

const infoLabel = {
  fontSize: "16px",
  lineHeight: "28px",
  color: "#000"
};

const innerCard = {
  display: "block",
  width: "100%"
};

const demoContainerStyle_PRSPopUP = {
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",
  alignSelf: "stretch",
  width: "568px"
};

const cardActionsStyle_PRSPopUP = {
  display: "flex",
  padding: "16px",
  alignItems: "flex-end",
  gap: "8px",
  alignSelf: "stretch"
};

const autocompleteStyle_PRSPopUP = {
  display: "flex",
  width: "270px",
  flexDirection: "column",
  alignItems: "flex-start"
};

const radioGroupStyle_PRSPopUP = {
  display: "flex",
  alignItems: "flex-start",
  gap: spacing.verticalMargin20,
  alignSelf: "stretch",
  width: "570px"
};

const buttonStyle_PRSPopUP = {
  display: "flex",
  padding: "6px 16px !important",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "unset",
  marginLeft: "0px !important"
};

const cardStyle_DRMPopUP = {
  ...cardStyle_PRSPopUP,
  width: "550px !important"
};

const textLinkButton = {
  textTransform: "none",
  color: "#1E417A",
  fontWeight: "500",
  paddingLeft: "16px",
  "&:hover": { backgroundColor: "transparent" }
};

export const assignTransportModalStyles = {
  autocompleteStyle_PRSPopUP,
  cardActionsStyle_PRSPopUP,
  cardContentStyle_PRSPopUP,
  innerCard,
  cardStyle_PRSPopUP,
  infoLabel,
  demoContainerStyle_PRSPopUP,
  mainTitle_PRSPopUP,
  modalCloseButton,
  modalClosweIcon,
  radioGroupStyle_PRSPopUP,
  buttonStyle_PRSPopUP,
  cardStyle_DRMPopUP,
  textLinkButton
};
