export let actionTypes = {
  customerChanged: "CUSTOMER_CHANGE",
  getStatus: "GET_STATUS",
  getLVs: "GET_LV",
  getDRP: "GET_DRP",
  reset: "RESET",
  changeStatus: "CHANGE_STATUS",
  changeLV: "CHANGE_lv",
  changeDRP: "CHANGE_DRP",
  changeMediaList: "CHANGE_MEDIA_LIST",
  changeDiscrepancy: "CHANGE_DISCREPANCY",
  changeDuplicates: "CHANGE_DUPLICATES",
  changeMediaClosedContainers: "CHANGE_MEDIA_CONTAINERS",
  chnageReturnDate: "CHANGE_RETURN_DATE",
  changeMediaStaringNumber: "CHANGE_MEDIA_STARTING_NUMBER",
  changeStartingSlot: "CHANGE_STARTING_SLOT",
  changeEndingSlot: "CHANGE_ENDING_SLOT",
  changeDescription: "CHANGE_DESCRIPTION",
  loader: "LOADER",
  customerCleared: "CLEARED_CUSTOMER",
  populateTable: "POPULATE_TABLE",
  clear: "CLEAR",
  rowSelect: "ROW_SELECT",
  changeMediaNumber: "CHANGE_MEDIA_NUMBER",
  dataUpdated: "DATA_UPDATED",
  updateTableData: "UPDATE_TABLE",
  tablePopulateClosedContainers: "TABLE_POPULATE_CC",
  initiateAfterBack: "INITIATE_AFTER_BACK",
  updateInProcess: "UPDATE_IN_PROCESS",
  blurStartingSlot: "BLUR_STARTING_SLOT",
  blurEndingSlot: "BLUR_ENDING_SLOT",
  foundMoreThanMaxRecords: "FOUND_MORETHEN_MAX"
};

export const dates = Object.freeze({
  minDate: "1990-01-01",
  maxDate: "2079-03-31"
});

export const getLogicalVaultsforCustomer = "getlogicalvaultsforcustomer";
export const getDrpCodes = "getdrpcodes";
export const getExchangeStatusValues = "getexchangestatusvalues";
export const getClosedContainerContents = "getclosedcontainercontents";
export const getOpenMediaAndAatrixForCustomer =
  "getopenmediaandmatrixforcustomer";
export const getlogicalvaultcodesforaudits = "getlogicalvaultcodesforaudits";
export const searchCustomerandVolserList = "searchCustomerandVolserList";
export const updateOpenMediaAttributesAndAssociations =
  "updateOpenMediaAttributesAndAssociations";

export const disabledOpacity = "0.3";
export const enabledOpacity = "1";
export const researchReportCountLimitFlag = "D";
export const totalNumberMatchingNumbers = "66";

export const fileFormatOptions = [
  { label: "Inventory file - Standard (Text)", value: 1 },
  { label: "Inventory file - Excel (CSV)", value: 2 }
];

export const delivered = "Delivered";
export const picking = "Picking";
export const atIM = "At Iron Mountain";
export const pendingDistribution = "Pending Distribution";
export const statusId10 = "10";
export const statusId20 = "20";
export const statusId80 = "80";
export const number1000 = "1000";
export const month = "month";
export const day = "day";
export const hour = "hour";
export const pdf = "PDF";
export const researchReportCountLimitFlagR = "R";
export const sortColumnCode = "9";
export const extensionTxt = ".txt";
export const extensionCsv = ".csv";
export const bitZero = "0";
export const bitOne = "1";
export const dateFromatForFileName = "DD-MMM-YYYY hhmmss";
export const textFileHeaderStarts = "Cust";
export const csvFileHeaderStarts = `"Cust`;
export const fileType = {
  csv: "text/csv",
  txt: "text/plain"
};
export const aTag = "a";
export const globalAttributTypeId = "96";
export const systemId = "60";
export const paddingRightLength = 20;
export const eventchangePage = "changePage";
export const eventchangeRowsPerPage = "changeRowsPerPage";
export const confirmEdit = "edit";
export const confirmCreateFile = "createFile";
export const confirmUpdate = "updateConfirm";
export const confirmPrint = "printConfirm";
