import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Modal,
  Stack,
  Typography
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

import WarningAmberOutlined from "assets/images/WarningAmberOutlined.svg";
import { CircularLoader } from "components/core";
import { MDRVaultItemizedStyles } from "components/features/request-module/media-destruction-request-vault-itemized/MDRVaultItemizedStyles.Styles";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetOpenMediaPossibleMatchesMutation } from "services/api";
import { getMessageByIdFromMessage } from "services/common";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  POSSIBLE_MATCHES_TXT
} from "utils/constants";
import {
  MESSAGE_26059,
  MESSAGE_26060
} from "utils/constants/request-module/MediaRequestDetailConstants";
import { MediaRequestDetailFormStyles } from "../media-request-detail-form/MediaRequestDetailForm.Styles";

const OpenMediaPossibleMatchesModal = (props) => {
  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState("");

  // Open media grid column definition view-only
  const possibleMatchesColumnsDefinition = [
    {
      name: "volser",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta) => (
          <FormControlLabel
            control={<Checkbox />}
            label=""
            sx={{
              marginRight: "0px !important",
              width: "18px !important",
              height: "18px !important"
            }}
            onChange={(_, checked) =>
              handleCheckboxOnChange(tableMeta.rowIndex, checked)
            }
          />
        )
      }
    },
    {
      name: "volser",
      label: t("mediaRequestDetail.mediaNumberCaps")
    },
    {
      name: "logical_vault_code",
      label: t("mediaRequestDetail.logicalVaultCodeCaps")
    },
    {
      name: "media_type_descr",
      label: t("mediaRequestDetail.mediaTypeCaps")
    },
    {
      name: "status",
      label: t("mediaRequestDetail.statusCaps")
    }
  ];

  const selectedVolser = useMemo(
    () => props.selectedMedia.trim(),
    [props.selectedMedia]
  ); // selected open media number for checking

  // construct title of the modal based on the followup status of selected volser
  useEffect(() => {
    if (props.selectedMedia && props.followUp) {
      getMessageByIdFromMessage({
        message_id:
          props.followUp === POSSIBLE_MATCHES_TXT
            ? MESSAGE_26059
            : MESSAGE_26060
      })
        .then((resp) => resp[0])
        .then((resp) => {
          setModalTitle((prevState) =>
            resp?.descr
              .replace("|", selectedVolser)
              .replace("|", selectedVolser)
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedMedia, props.followUp]);

  const [
    getOpenMediaPossibleMatches,
    { data: possibleMatches = [], isLoading: loadingPossibleMatches }
  ] = useGetOpenMediaPossibleMatchesMutation(); // open media possible matches api
  const [selectedOpenMedias, setSelectedOpenMedias] = useState([]); // selected open medias

  const dataTableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    rowsPerPageOptions: [25, 50],
    tableBodyHeight: "230px",
    tableBodyMaxHeight: "230px",
    setRowProps: (row) => ({
      style: {
        height: "32px"
      }
    }),
    textLabels: {
      body: {
        noMatch: loadingPossibleMatches ? (
          <CircularLoader loading={loadingPossibleMatches} />
        ) : (
          t("mediaRequestDetail.noItemsToDisplay")
        )
      }
    }
  };

  // table row checbox selected state
  const handleCheckboxOnChange = (rowIndex, checked) => {
    setSelectedOpenMedias((prevState) => {
      if (checked) {
        if (
          prevState.findIndex(
            (row) => row.volser === possibleMatches[rowIndex].volser
          ) < 0
        ) {
          return prevState.concat(possibleMatches[rowIndex]);
        }
      } else {
        return prevState.filter(
          (row) => row.volser !== possibleMatches[rowIndex].volser
        );
      }
      return prevState;
    });
  };

  // fetch possible matches/duplicates when props changes
  useEffect(() => {
    if (props.show) {
      getOpenMediaPossibleMatches({
        customerId: props.customerId,
        volserList: props.volserList,
        selectedVolser: selectedVolser,
        followUp: props.followUp
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, props.customerId, props.volserList, selectedVolser]);

  let sortedPossibleMatches = [...possibleMatches].sort((a, b) =>
    a.volser.localeCompare(b.volser)
  );
  return (
    <Modal
      id="selectPossibleMatchesModal"
      open={props.show}
      aria-labelledby="modal-possible-matches"
      aria-describedby="modal-possible-matches-open-media"
    >
      <Card
        id="selectPossibleMatchesCard"
        sx={MDRVaultItemizedStyles.SelectMediaModal_PopUp}
      >
        {/* ------------------ Title Section ------------------ */}

        <Typography
          id="selectPossibleMatchesTitle"
          variant="h6"
          sx={MDRVaultItemizedStyles.mainTitle_SelectMedia}
          color="text.secondary"
          gutterBottom
        >
          {t("mediaRequestDetail.selectMedia")}
        </Typography>

        <Divider />

        {/* ------------------ Body Section ------------------ */}
        <CardContent>
          <Stack gap={spacing.verticalMargin20}>
            <Typography variant="subtitle2">{modalTitle}</Typography>

            {/* 50 records limit warning message */}
            {possibleMatches.length >= 50 && (
              <Alert
                severity="warning"
                icon={<img src={WarningAmberOutlined} alt="Warning" />}
                onClose={() => {}}
              >
                {t("mediaRequestDetail.moreThan50RecordsFound")}
              </Alert>
            )}

            <Typography variant="subtitle2">
              {possibleMatches?.length} {t("mediaRequestDetail.recordsFound")}
            </Typography>

            <Box sx={MDRVaultItemizedStyles.tableContainer}>
              <MUIDataTable
                id="selectPossibleMatchesDataTable"
                data={sortedPossibleMatches}
                columns={possibleMatchesColumnsDefinition}
                options={dataTableOptions}
              />
            </Box>
          </Stack>
        </CardContent>

        <Divider />

        {/* ------------------ Footer Section ------------------ */}
        <CardActions sx={MDRVaultItemizedStyles.cardActionsStyle_PRSPopUP}>
          <Button
            id="selectPossibleMatchesModalOkBtn"
            variant="contained"
            size="medium"
            sx={{ textTransform: "initial" }}
            disabled={selectedOpenMedias.length === 0}
            onClick={() => props.onSubmit(selectedOpenMedias)}
          >
            {props.isLoading && (
              <CircularProgress
                color="inherit"
                sx={{
                  ...MediaRequestDetailFormStyles.ButtonCircularProgress,
                  marginRight: ".5rem"
                }}
              />
            )}
            {t("mediaRequestDetail.okCaps")}
          </Button>
          <Button
            id="selectPossibleMatchesModalCancelBtn"
            variant="outlined"
            onClick={() => props.onClose()}
            sx={MDRVaultItemizedStyles.buttonStyle_PRSPopUP}
          >
            {t("mediaRequestDetail.cancel")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

OpenMediaPossibleMatchesModal.propTypes = {
  show: PropTypes.bool.isRequired, // modal show/hide state
  volserList: PropTypes.string.isRequired, // volser list
  isLoading: PropTypes.bool, // is validating selected open medias
  selectedMedia: PropTypes.string.isRequired, // selected open media number
  customerId: PropTypes.number.isRequired, // ID of the customer
  followUp: PropTypes.string.isRequired, // duplicate or possible matches status
  onClose: PropTypes.func.isRequired, // modal close event handler
  onSubmit: PropTypes.func // OK button event handler
};

export default OpenMediaPossibleMatchesModal;
