import { Stack } from "@mui/material";
import { PersonnelSearchScreen } from "components/authorization";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const PersonnelSearch = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={i18n.t("menu.personalSearch")} />
      <PersonnelSearchScreen />
    </Stack>
  );
};

export default PersonnelSearch;
