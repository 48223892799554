import * as yup from "yup";
import i18n from "../../../../i18n";

export const filterSchema = yup.object().shape({
  fromDate: yup
    .date()
    .nullable()
    .typeError(i18n.t("requestSearch.invalidDate"))
    .test(
      "valid-date",
      i18n.t("requestSearch.invalidDate"),
      function (toDate, context) {
        const fromDate = context?.parent?.fromDate;
        const normalizeDate = (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return (
          fromDate &&
          !isNaN(fromDate.getTime()) &&
          normalizeDate(fromDate) >=
            normalizeDate(new Date(i18n.t("requestSearch.minimumDate"))) &&
          (!toDate ||
            normalizeDate(fromDate) <=
              normalizeDate(new Date(i18n.t("requestSearch.maximumDate"))))
        );
      }
    ),
  toDate: yup
    .date()
    .nullable()
    .typeError(i18n.t("requestSearch.invalidDate"))
    .test(
      "valid-date",
      i18n.t("requestSearch.invalidDate"),
      function (fromDate, context) {
        const toDate = context?.parent?.toDate;

        const normalizeDate = (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate());

        return (
          toDate &&
          !isNaN(toDate.getTime()) &&
          normalizeDate(toDate) >=
            normalizeDate(new Date(i18n.t("requestSearch.minimumDate"))) &&
          (!fromDate ||
            normalizeDate(toDate) <=
              normalizeDate(new Date(i18n.t("requestSearch.maximumDate"))))
        );
      }
    )
    .test("custom-validation", function (toDate, context) {
      const fromDate = context?.parent?.fromDate;
      const normalizeDate = (date) =>
        new Date(date.getFullYear(), date.getMonth(), date.getDate());

      if (
        !fromDate ||
        !toDate ||
        (normalizeDate(toDate) instanceof Date &&
          normalizeDate(fromDate) <= normalizeDate(toDate))
      ) {
        return true;
      }

      return this.createError({
        path: this.path,
        message: i18n.t("requestSearch.endDateGreaterThanStartDate")
      });
    }),
  customer: yup
    .mixed()
    .transform((originalValue) => {
      if (originalValue === null) {
        return { label: "", value: "" };
      }
      return originalValue;
    })
    .test(
      "customer-validation",
      i18n.t("requestSearch.customerFieldRequired"),
      function (value) {
        return value && value.value !== "";
      }
    )
});
