import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TableContainer
} from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";

const AdditionalInfoTable = ({ tableData }) => {
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS
  };
  const columns = [
    {
      name: "media_number",
      label: t("discrepancyUpdate.media")
    },
    {
      name: "resolved_flag",
      label: t("discrepancyUpdate.resolved")
    },

    {
      name: "additional_info",
      label: t("discrepancyUpdate.information")
    }
  ];
  return (
    <Grid item md={12} lg={12} xs={12}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12-content"
        >
          {t("discrepancyUpdate.additionalTitle")}
        </AccordionSummary>
        <AccordionDetails>
          <>
            <TableContainer>
              <Grid item xs={12} lg={12}>
                <MUIDataTable
                  columns={columns}
                  data={tableData ? [...tableData] : []}
                  options={options}
                />
              </Grid>
            </TableContainer>
          </>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AdditionalInfoTable;
