import { Stack } from "@mui/material";
import VaultDetails from "components/logical-vault-details/VaultDetails";
import { PageTitle } from "components/shared";
import i18n from "i18n";

const LogicalVaultDetails = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("logicalVaultDetails.title")} />
      <br />

      <VaultDetails />
    </Stack>
  );
};

export default LogicalVaultDetails;
