import * as React from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePickerField } from "components/core";
import dayjs from "dayjs";

import { Stack } from "@mui/material";
import { requestModuleStyles } from "../MediaRequestSearchModule.Styles";

import { CustomersField } from "components/core/customer-field/CustomersField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetAllCustomersQuery } from "services/api";
import { selectAuth } from "store/slices";
import { dateFormat } from "utils/constants";
import DownloadReportModal from "../download-report-modal/PrintReportModal";

const PrintRequestSearchModal = React.forwardRef((props, ref) => {
  const { currentBranch } = useSelector(selectAuth);
  const selectedBranchId = currentBranch?.district_id;
  const { t } = useTranslation();
  // modal visibility state
  const { fromDate, toDate, selectedCustomer } = props;

  const { data: customers = [] } = useGetAllCustomersQuery(
    { mainDistrictId: selectedBranchId },
    {
      selectFromResult: ({ data }) => ({
        data:
          [].concat(
            [{ label: t("requestSearch.all"), value: t("requestSearch.all") }],
            data
          ) ?? []
      })
    }
  );

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
    setDefaults();
  };
  // trigger method in a child component
  React.useImperativeHandle(ref, () => ({
    handleToggle
  }));
  // customer selection state

  const [customerSelect, setCustomerSelect] = useState(selectedCustomer);
  // date selection states
  const [dateFrom, setDateFrom] = useState(dayjs(fromDate));
  const [dateTo, setDateTo] = useState(dayjs(toDate));
  // report type selection state
  const [reportType, setReportType] = useState(t("requestSearch.standard"));
  // download report modal visibility state
  const [isVisible, setIsVisible] = useState(false);
  // handle fields visibility
  const handleChange = (event) => {
    setReportType(event.target.value);
  };
  // handle download report modal visibility
  const onClickHandler = () => {
    setIsVisible(true);
  };
  // setting default values
  const setDefaults = () => {
    setReportType(t("requestSearch.standard"));
    setCustomerSelect(selectedCustomer);
    setDateFrom(dayjs(fromDate));
    setDateTo(dayjs(toDate));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleToggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={requestModuleStyles.cardStyle_PRSPopUP}>
          {/* ------------------ Title Section ------------------ */}

          <Typography
            variant="h6"
            sx={requestModuleStyles.mainTitle_PRSPopUP}
            color="text.secondary"
            gutterBottom
          >
            {t("requestSearch.printRequestSearch")}
          </Typography>

          <Divider />
          {/* ------------------ Body Section ------------------ */}

          <CardContent sx={requestModuleStyles.cardContentStyle_PRSPopUP}>
            <Typography sx={{ fontWeight: "medium" }}>
              {t("requestSearch.report")}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={requestModuleStyles.radioGroupStyle_PRSPopUP}
                value={reportType}
                onChange={handleChange}
                defaultValue="standard"
              >
                <FormControlLabel
                  sx={requestModuleStyles.formControlLabel}
                  value="standard"
                  control={<Radio />}
                  label={t("requestSearch.standardReport")}
                />
                <FormControlLabel
                  sx={requestModuleStyles.formControlLabel}
                  value="special"
                  control={<Radio />}
                  label={t("requestSearch.specialOnly")}
                />
              </RadioGroup>
            </FormControl>

            {reportType === t("requestSearch.special") && (
              <>
                <Typography
                  styles={requestModuleStyles.printModalHeadingStyles}
                >
                  {t("requestSearch.SpecialOnlyCriteria")}
                </Typography>
                <CustomersField
                  noOptionsText={t("requestSearch.customerNotFound")}
                  form={{ touched: {}, errors: {} }}
                  options={customers}
                  label={t("requestSearch.customer")}
                  value={customerSelect}
                  handleOnChange={(event, newValue) => {
                    setCustomerSelect(newValue);
                  }}
                  sx={requestModuleStyles.autocompleteStyle_PRSPopUP}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack
                    direction="row"
                    sx={requestModuleStyles.demoContainerStyle_PRSPopUP}
                  >
                    <DatePickerField
                      label={t("requestSearch.dateFrom")}
                      value={dateFrom}
                      format="DD/MM/YYYY"
                      onChange={(newValue) => {
                        setDateFrom(newValue);
                        setDateTo(newValue);
                      }}
                      minDate={dayjs(t("requestSearch.minimumDate"))}
                      maxDate={dayjs(t("requestSearch.maximumDate"))}
                    />
                    <DatePickerField
                      label={t("requestSearch.dateTo")}
                      value={dateTo}
                      format="DD/MM/YYYY"
                      onChange={(newValue) => setDateTo(newValue)}
                      minDate={dayjs(t("requestSearch.minimumDate"))}
                      maxDate={dayjs(t("requestSearch.maximumDate"))}
                    />
                  </Stack>
                </LocalizationProvider>
              </>
            )}
          </CardContent>

          <Divider />
          {/* ------------------ Footer Section ------------------ */}

          <CardActions sx={requestModuleStyles.cardActionsStyle_PRSPopUP}>
            <Button
              variant="outlined"
              onClick={handleToggle}
              sx={requestModuleStyles.buttonStyle_PRSPopUP}
            >
              {t("common.close")}
            </Button>
            <Button
              variant="contained"
              sx={requestModuleStyles.buttonStyle_PRSPopUP}
              onClick={onClickHandler}
            >
              {t("common.print")}
            </Button>
          </CardActions>
        </Card>
      </Modal>

      {isVisible && (
        <DownloadReportModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          mainDistrictId={currentBranch?.district_id}
          setIsLoading={props.setIsLoading}
          throwError={props.throwError}
          setThrowError={props.setThrowError}
          throwErrorMessage={props.throwErrorMessage}
          setThrowErrorMessage={props.setThrowErrorMessage}
          formValues={props.formValues}
          spFromDate={dayjs(dateFrom).format(dateFormat.universalFormat)}
          spToDate={dayjs(dateTo).format(dateFormat.universalFormat)}
          spCustomer={customerSelect}
          reportType={reportType}
        />
      )}
    </>
  );
});

PrintRequestSearchModal.displayName = "PrintRequestSearchModal";

export default PrintRequestSearchModal;
