import { Autocomplete, TextField } from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { ReactComponent as ArrowDownGrey } from "assets/images/arrow-down.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetAllCustomersQuery } from "services/api";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

const CustomerSelectionVault = ({
  id,
  customerSelected,
  handleCustomerChange,
  mrdForm,
  isReadOnly,
  mainDistrictId
}) => {
  const { t } = useTranslation();

  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: mainDistrictId
  });

  const [selectedCustomer, setSelectedCustomer] = useState(
    customerSelected || ""
  );

  useEffect(() => {
    if (isReadOnly && customers?.length > 0) {
      // find customer by customerSelected in customers array

      const cust = customers?.find(
        (customer) => customer.value === customerSelected
      );

      if (cust) {
        setSelectedCustomer(cust);
      } else {
        setSelectedCustomer("");
      }
    } else {
      setSelectedCustomer(customerSelected || "");
    }
  }, [isReadOnly, customers, customerSelected]);

  return (
    <>
      <Autocomplete
        clearIcon={<CloseIcon />}
        popupIcon={
          selectedCustomer !== "" || isReadOnly ? (
            <ArrowDownGrey />
          ) : (
            <ArrowDown />
          )
        }
        sx={MDRVaultItemizedStyles.MUI_Autocomplete_Icon_Styles}
        disablePortal
        id="customerSelection"
        options={["", ...customers] || []}
        value={selectedCustomer}
        disabled={selectedCustomer !== "" || isReadOnly}
        onChange={handleCustomerChange}
        isOptionEqualToValue={id && ((option, value) => true)}
        renderInput={(params) => (
          <TextField
            {...params}
            name="customer"
            label={t("vaultItemized.customerLabel")}
            required
            InputLabelProps={
              MDRVaultItemizedStyles.CustomerSelectionVault_InputLabelProps
            }
            value={mrdForm.values?.customer}
            sx={MDRVaultItemizedStyles.CustomerSelectionVault_TF}
            error={mrdForm.touched.customer && Boolean(mrdForm.errors.customer)}
            helperText={mrdForm.touched.customer && mrdForm.errors.customer}
          />
        )}
      />
    </>
  );
};

export default CustomerSelectionVault;
