import { Box, CircularProgress, Fade } from "@mui/material";
import { FullCircularLoaderStyles } from "./FullCircularLoader.Styles";

const FullCircularLoader = () => {
  return (
    <Box sx={FullCircularLoaderStyles.root}>
      <Fade in={true} unmountOnExit>
        <Box sx={FullCircularLoaderStyles.loader}>
          <CircularProgress color="primary" />
        </Box>
      </Fade>
    </Box>
  );
};

export default FullCircularLoader;
