import { Stack } from "@mui/material";
import PrintTapeLabelLayout from "components/label-printing/print-tape-label/PrintTapeLabelLayout";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const BillingStatus = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("printTapeLabel.pageTitle")} />
      <PrintTapeLabelLayout />
    </Stack>
  );
};

export default BillingStatus;
