import { Box, Stack } from "@mui/material";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import ContainerAuditDetailScreen from "components/container-processing/container-audit-details/ContainerAuditDetailScreen";
import { PageTitle } from "components/shared";
import i18n from "i18n";
import { useState } from "react";
import spacing from "styles/spacing";
const ContainerAuditDetails = () => {
  const [isEmail, setIsEmail] = useState(false);
  const [isPossibleMatch, setIsPossibleMatch] = useState(false);

  const handleTitle = () => {
    if (isEmail) {
      return i18n.t("containerAuditDetails.emailRecipients");
    } else if (isPossibleMatch) {
      return i18n.t("containerAuditDetails.containerAuditPossibleMatches");
    } else {
      return i18n.t("containerAuditDetails.title");
    }
  };

  return (
    <>
      <Stack gap={spacing.gap}>
        <Box sx={ContainerProcessingStyles.flexItems}>
          <Box>
            <PageTitle title={handleTitle()} />
          </Box>
        </Box>
        <ContainerAuditDetailScreen
          setIsEmail={setIsEmail}
          isEmail={isEmail}
          isPossibleMatch={isPossibleMatch}
          setIsPossibleMatch={setIsPossibleMatch}
        />
      </Stack>
    </>
  );
};
export default ContainerAuditDetails;
