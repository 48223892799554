import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { t } from "i18next";
import { useState } from "react";
import { dayMonthYearFormat, numberConstants } from "utils/constants";
import {
  dateItem,
  dayItem,
  errorMessage2,
  monthItem,
  monthYearFormat,
  onCall
} from "utils/constants/customer-service-information/CustomerScheduleConst";
import { calendarUi } from "./CustomerScheduleStyles";

const StyledDateCalendar = styled(DateCalendar)(() => ({
  ".MuiIconButton-root": {
    display: "none" // Hide the calendar icon
  }
}));

const CalenderUi = ({
  selectedDateList,
  setSelectedDateList,
  handleError,
  serviceFrequency,
  startDate,
  adapterLocale
}) => {
  // Initialize with the current month
  const [startMonth, setStartMonth] = useState(dayjs().startOf(monthItem));

  // Function to move to the next 8 months
  const handleNextClick = () => {
    setStartMonth((prevMonth) =>
      prevMonth.add(Number(numberConstants?.eight), monthItem)
    );
  };

  const handlePrevClick = () => {
    setStartMonth((prevMonth) =>
      prevMonth.subtract(Number(numberConstants?.eight), monthItem)
    );
  };

  // Generate an array of 8 months starting from startMonth
  const months = Array.from({ length: Number(numberConstants?.eight) }).map(
    (_, index) => startMonth.add(index, monthItem)
  );
  const handleSelectDay = (day) => {
    let currentDate = startDate.format(dayMonthYearFormat);

    if (serviceFrequency === onCall) {
      handleError(errorMessage2, true); //will be changed in future
      return;
    }
    const date1 = dayjs(currentDate, dayMonthYearFormat);
    const date2 = dayjs(day, dayMonthYearFormat);
    const isBefore = date1.isBefore(date2);
    if (isBefore) {
      let findIndex = selectedDateList.findIndex((item) => item === day);
      if (findIndex >= 0) {
        selectedDateList.splice(findIndex, 1);
      } else {
        selectedDateList.push(day);
      }
      setSelectedDateList([...selectedDateList]);
      handleError("");
    }
  };
  const slotUi = ({ day, ...props }) => {
    let selected = dayjs(day).format(dayMonthYearFormat);
    return (
      <button
        style={
          selectedDateList?.includes(selected) && !props?.outsideCurrentMonth
            ? { ...calendarUi.buttonStyle, ...calendarUi.buttonActiveStyle }
            : calendarUi.buttonStyle
        }
        onClick={() => handleSelectDay(selected)}
      >
        {props?.outsideCurrentMonth ? "" : dayjs(day).get(dateItem)}
      </button>
    );
  };
  return (
    <Paper sx={{ margin: 2, padding: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid display="flex" flexDirection="row">
            <Typography variant="h5" sx={{ paddingLeft: 2 }}>
              {t("customerSchedule.calendar")}
            </Typography>
          </Grid>
          <Grid
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
          >
            <IconButton
              onClick={handlePrevClick}
              disabled={
                dayjs().format(monthYearFormat) ===
                dayjs(months[0]).format(monthYearFormat)
              }
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton onClick={handleNextClick}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={adapterLocale}
        >
          <Grid container spacing={2}>
            {months.map((month, index) => (
              <Grid item xs={4} sm={12} md={4} lg={3} key={index}>
                <StyledDateCalendar
                  value={month}
                  views={[dayItem]}
                  slots={{
                    day: slotUi // Use your custom slot for all days
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </LocalizationProvider>
      </Grid>
    </Paper>
  );
};

export default CalenderUi;
