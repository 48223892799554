import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import CloseFilledIcon from "assets/images/CloseFilled.svg";
import PencilIcon from "assets/images/pencil.svg";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectMediaRequest } from "store/slices";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { MediaRequestDetailFormStyles } from "../../media-request-detail-form/MediaRequestDetailForm.Styles";
import { customerMediaIdentifierModalStyles } from "./CustomerMediaIdentifierModal.Styles";
import { mediaIdetifierValidationSchema } from "./schema";

const formInitialValues = { mediaIdentifier: "" };

const dataTableOptions = {
  ...DEFAULT_DATA_TABLE_OPTIONS,
  jumpToPage: false,
  pagination: false,
  tableBodyHeight: "230px",
  tableBodyMaxHeight: "230px",
  textLabels: {
    body: {
      noMatch: i18n.t("mediaRequestDetail.noItemsToDisplay")
    }
  }
};

const CustomerMediaIdentifierModal = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ show: false, message: "" });
  const [selectedRow, setSelectedRow] = useState(-1);
  const { issueContainers } = useSelector(selectMediaRequest); // select media request slice

  // set row data to the form
  const handleEditRow = (rowIndex) => {
    const data = mediaIdetifiers[rowIndex];
    mediaIdetifierForm.setValues({
      mediaIdentifier: data.mediaIdentifier
    });
    setSelectedRow(rowIndex);
  };

  // column definition of the customer media identifiers data table
  const tableColumnsDefinition = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnEdit${value}`}
            aria-label="edit"
            onClick={() => handleEditRow(tableMeta.rowIndex)}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={PencilIcon} alt="edit icon" />
          </IconButton>
        )
      }
    },
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() => {
              const updatedMedia = mediaIdetifiers.filter(
                (_, index) => index !== tableMeta.rowIndex
              );
              setMediaIdentifiers(updatedMedia);
            }}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "mediaIdentifier",
      label: t("mediaRequestDetail.customerMediaIdentifierCaps")
    }
  ];

  const [mediaIdetifiers, setMediaIdentifiers] = useState([]);
  const mediaIdetifierForm = useFormik({
    initialValues: formInitialValues,
    validationSchema: mediaIdetifierValidationSchema,
    onSubmit: (values, formikHelpers) => {
      if (mediaIdetifierForm.isValid) {
        if (selectedRow < 0) {
          if (mediaIdetifiers.length < props.containersRequested) {
            setError({ show: false, message: "" });
            // Check if the value already exists in the previous state
            const alreadyExists = mediaIdetifiers.some(
              (item) =>
                item?.mediaIdentifier?.toLowerCase() ===
                values?.mediaIdentifier?.toLowerCase()
            );

            // If the value already exists, return the previous state unchanged
            if (alreadyExists) {
              setError((prevState) => ({
                show: true,
                message: `${t("mediaRequestDetail.identifierErrorMessage1")} ${
                  values?.mediaIdentifier
                } ${t("mediaRequestDetail.identifierErrorMessage2")}`
              }));
              return;
            }

            setMediaIdentifiers((prevState) =>
              prevState.concat({
                ...values,
                id: Math.round(Math.random() * 100)
              })
            );
            formikHelpers.resetForm();
          } else {
            setError((prevState) => ({
              show: true,
              message: `${t("mediaRequestDetail.mediaIdentifierErrorPart1")} ${
                props.containersRequested
              } ${t("mediaRequestDetail.mediaIdentifierErrorPart2")} ${
                props.containersRequested
              } ${t("mediaRequestDetail.mediaIdentifierErrorPart3")}`
            }));
          }
        } else {
          setError({ show: false, message: "" });
          setMediaIdentifiers((prevState) => {
            const data = [...prevState];
            data[selectedRow] = {
              ...data[selectedRow],
              mediaIdentifier: values.mediaIdentifier
            };
            return data;
          });
          setSelectedRow(-1);
          formikHelpers.resetForm();
        }
      }
    }
  });

  // Added identifiers reset event handler
  const handleReset = (_) => {
    setMediaIdentifiers((prevState) => []);
    setError((prevState) => ({ show: false, message: "" }));
  };

  // Added identifiers submit event handler
  const handleSubmit = () => {
    // Extracting the customer_media_descr_list values from issueContainers.containers
    const customerMediaDescrListValues = issueContainers?.containers?.flatMap(
      (container) => container.customer_media_descr_list.split(" ")
    );

    // Extracting the mediaIdentifier values from mediaIdentifiers
    const mediaIdentifierValues = mediaIdetifiers?.map(
      (identifier) => identifier.mediaIdentifier
    );

    // Checking for duplicates
    const duplicates = customerMediaDescrListValues?.some(
      (customerMediaDescrList) =>
        mediaIdentifierValues?.includes(customerMediaDescrList)
    );

    if (duplicates) {
      setError((prevState) => ({
        show: true,
        message: `${t("mediaRequestDetail.identifierErrorMessage1")} ${
          mediaIdetifiers[0]?.mediaIdentifier
        } ${t("mediaRequestDetail.identifierErrorMessage2")}`
      }));
    } else {
      props.onSubmit(mediaIdetifiers);
    }
  };

  // set selected identifiers to the state
  useEffect(() => {
    if (props.selectedIdentifiers.length > 0) {
      setMediaIdentifiers(props.selectedIdentifiers);
    }
  }, [props.selectedIdentifiers]);

  return (
    <Modal
      id="customerMediaIdentifierModal"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="customer-media-identifier-modal-title"
      aria-describedby="customer-media-identifier-modal-description"
    >
      <Card
        id="customerMediaIdentifierModalCard"
        sx={customerMediaIdentifierModalStyles.cardStyle_PRSPopUP}
      >
        <Typography
          id="customerMediaIdentifierModalTitle"
          variant="h6"
          sx={customerMediaIdentifierModalStyles.mainTitle_PRSPopUP}
          color="secondary"
          gutterBottom
        >
          {t("mediaRequestDetail.customerMediaIdentifier")}
        </Typography>

        <Divider />

        <CardContent
          sx={customerMediaIdentifierModalStyles.cardContentStyle_PRSPopUP}
        >
          <Grid container columnSpacing={8}>
            <Grid item xs={12}>
              <Typography
                id="customerMediaIdentifierMediaType"
                sx={customerMediaIdentifierModalStyles.infoLabel}
              >
                {t("mediaRequestDetail.mediaTypeColon")}{" "}
                {props.selectedMediaType}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                id="customerMediaIdentifierContainerRequested"
                sx={customerMediaIdentifierModalStyles.infoLabel}
              >
                {t("mediaRequestDetail.containerRequestedColon")}{" "}
                {props.containersRequested}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                id="customerMediaIdentifierEntered"
                sx={customerMediaIdentifierModalStyles.infoLabel}
              >
                {t("mediaRequestDetail.identifierEnteredColon")}{" "}
                {mediaIdetifiers.length}
              </Typography>
            </Grid>
          </Grid>

          {/* error message */}
          {error.show && (
            <Alert
              id="customerMediaIdentifierModalErrorAlert"
              severity="warning"
              onClose={() =>
                setError((prevState) => ({ show: false, message: "" }))
              }
            >
              {error.message}
            </Alert>
          )}

          <Card sx={customerMediaIdentifierModalStyles.innerCard}>
            <Grid container spacing={2} sx={{ padding: "16px" }}>
              {/* Customer media identifier */}
              <Grid item>
                <TextField
                  id="customerMediaIdentifierModalMediaIdentifier"
                  size="small"
                  name="mediaIdentifier"
                  label={t("mediaRequestDetail.customerMediaIdentifier")}
                  variant="outlined"
                  sx={{ width: "300px" }}
                  value={mediaIdetifierForm.values?.mediaIdentifier}
                  error={
                    mediaIdetifierForm.touched.mediaIdentifier &&
                    Boolean(mediaIdetifierForm.errors.mediaIdentifier)
                  }
                  helperText={
                    mediaIdetifierForm.touched.mediaIdentifier &&
                    mediaIdetifierForm.errors.mediaIdentifier
                  }
                  onChange={mediaIdetifierForm.handleChange}
                />
              </Grid>

              {/* Add button */}
              <Grid item>
                <Button
                  id="txtCustomerMediaIdentifierModalAddButton"
                  variant="outlined"
                  size="medium"
                  sx={customerMediaIdentifierModalStyles.buttonStyle_PRSPopUP}
                  disabled={!mediaIdetifierForm.isValid}
                  onClick={() => mediaIdetifierForm.handleSubmit()}
                >
                  {selectedRow < 0
                    ? t("mediaRequestDetail.add")
                    : t("mediaRequestDetail.update")}
                </Button>
              </Grid>
            </Grid>

            {/* customer media identifier list */}
            <MUIDataTable
              id="customerMediaIdentifierModalDataTable"
              data={mediaIdetifiers}
              columns={tableColumnsDefinition}
              options={dataTableOptions}
            />
          </Card>
        </CardContent>
        <Divider />
        <CardActions
          sx={customerMediaIdentifierModalStyles.cardActionsStyle_PRSPopUP}
        >
          <Button
            id="txtCustomerMediaIdentifierModalCancelButton"
            variant="outlined"
            sx={customerMediaIdentifierModalStyles.buttonStyle_PRSPopUP}
            onClick={props.onClose}
          >
            {t("mediaRequestDetail.cancel")}
          </Button>
          <Button
            id="txtCustomerMediaIdentifierModalClearButton"
            variant="outlined"
            sx={customerMediaIdentifierModalStyles.buttonStyle_PRSPopUP}
            type="reset"
            onClick={handleReset}
          >
            {t("mediaRequestDetail.clear")}
          </Button>
          <Button
            id="txtCustomerMediaIdentifierModalOkButton"
            variant="contained"
            sx={customerMediaIdentifierModalStyles.buttonStyle_PRSPopUP}
            type="submit"
            onClick={handleSubmit}
          >
            {t("mediaRequestDetail.ok")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CustomerMediaIdentifierModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  containersRequested: PropTypes.number.isRequired, // requested container count
  selectedMediaType: PropTypes.string.isRequired, // selected media type
  selectedIdentifiers: PropTypes.array, // selected identifiers
  onClose: PropTypes.func, // handle modal close event
  onSubmit: PropTypes.func // handle modal submit event
};

export default CustomerMediaIdentifierModal;
