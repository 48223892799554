import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/warning-2.svg";
import BranchField from "components/core/branch-field/BranchField";
import { PersonnelsField } from "components/core/personnel-field/PersonnelsField";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import spacing from "styles/spacing";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  personalDetailConstants
} from "utils/constants";
import { findErrorMessage, getSortedData } from "utils/helpers";
import DebouncedValue from "../../shared/Debounce";
import { authSectionStyles } from "./AuthSectionStyles";

const AuthenticationSection = ({
  allBranches,
  handleBranchChange,
  pageFormDetails,
  valuePersonnelAuth,
  handlePersonnelAuthChange,
  allCustomers,
  handleCustomerChange,
  handlePersonnelChange,
  onAuthenticateClick,
  isAuthenticationError,
  isAuthenticated,
  onBypassClick,
  programSecurityListArray,
  isBypassError,
  setIsLoading,
  setThrowError,
  setThrowErrorMessage
}) => {
  const { t } = useTranslation();
  const [authCustomers, setAuthCustomers] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchText = DebouncedValue(searchText, 500);
  const searchTextRef = useRef();
  searchTextRef.current = searchText;

  useEffect(() => {
    !allCustomers &&
      debouncedSearchText?.length > 0 &&
      getAuthCustomers(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  // customers using for authentication
  const getAuthCustomers = async (text) => {
    setIsLoading((prev) => true);
    try {
      var reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_filter:
          pageFormDetails?.values?.authenticationBranch ===
          DISTRICT_ID_GET_ALL_LOCATIONS
            ? ""
            : pageFormDetails?.values?.authenticationBranch,
        search_text: text,
        max_matches: personalDetailConstants.maxMatchesCust
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.personnelDetail.listOfCustomers
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const custData = getSortedData(data?.data[0], "number", "asc");
      let modCux = custData?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number?.trim()} - ${cus?.name?.trim()} - ${cus?.branch_name?.trim()}`,
          value: cus?.customer_id
        };
      });
      setAuthCustomers(modCux);
      setSearchText(null);
      setIsLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading((prev) => false);
    }
  };

  const showAuthenticationErrorMsg = () => {
    if (
      !pageFormDetails.values.authNumber &&
      !pageFormDetails.values.challengeResponse
    ) {
      return t("common.authNumberChallengeResponseMissing");
    }

    if (pageFormDetails.values.authNumber) {
      return t("common.invalidAuthNumber");
    }

    if (pageFormDetails.values.challengeResponse) {
      t("common.invalidChallengeResponse");
    }

    return t("common.invalidAuthNumberChallengeResponse");
  };

  return (
    <>
      {!isAuthenticated && (
        <Stack gap={spacing.gap}>
          <Box sx={authSectionStyles.personnelAuthStyles.boxStyle}>
            {/* Customer Selection */}
            <Box sx={authSectionStyles.commonStyles.boxWidth}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="customerSelectLabel"
                  options={
                    allCustomers?.length > 0 ? allCustomers : authCustomers
                  }
                  onChange={handleCustomerChange}
                  onInputChange={(event, newInputValue, reason) => {
                    if (!allCustomers) {
                      reason !== personalDetailConstants.autoCompleteReset &&
                        setSearchText(newInputValue);
                    }
                  }}
                  value={pageFormDetails?.values?.customer}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("personnelValidation.customer")}
                    />
                  )}
                />
              </FormControl>
            </Box>

            {/* Branch Selection */}
            <Box sx={authSectionStyles.commonStyles.boxWidth}>
              <BranchField
                options={allBranches}
                onChange={(e) => handleBranchChange(e)}
                value={
                  pageFormDetails.values.authenticationBranch ||
                  pageFormDetails.values.branch
                }
              />
            </Box>
          </Box>

          <Typography variant="h5">{t("common.personnelAuth")}</Typography>

          <Stack gap={spacing.gap}>
            {/* Radio Selection */}
            <Box>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={valuePersonnelAuth}
                  onChange={handlePersonnelAuthChange}
                >
                  <FormControlLabel
                    value="personnelAuth"
                    control={<Radio />}
                    label={t("common.personnelAuth")}
                  />
                  {programSecurityListArray.includes(317) && (
                    <FormControlLabel
                      value="bypass"
                      control={<Radio />}
                      label={t("common.bypass")}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Box>

            {/* -------------- Personnel Auth Selection --------------  */}
            {valuePersonnelAuth === "personnelAuth" && (
              <Stack gap={spacing.gap}>
                <Box sx={authSectionStyles.personnelAuthStyles.boxStyle}>
                  {/* Personnel Selection */}
                  <Box sx={authSectionStyles.commonStyles.boxWidth}>
                    <PersonnelsField
                      label={t("common.personnel")}
                      customerId={pageFormDetails?.values?.customer?.value}
                      form={pageFormDetails?.values?.customer ? false : true}
                      handlePersonnelOnChange={handlePersonnelChange}
                    />
                  </Box>
                </Box>

                {pageFormDetails?.values?.personnel?.value && (
                  <>
                    <Typography variant="body2" id="authInfo">
                      {t("common.authInfo")}
                    </Typography>

                    <Box sx={authSectionStyles.personnelAuthStyles.boxStyle}>
                      {/* Auth Number Field */}
                      <Box sx={authSectionStyles.commonStyles.boxWidth}>
                        <TextField
                          id="authNumber"
                          name="authNumber"
                          label={t("common.authNumber")}
                          fullWidth
                          inputProps={{
                            maxLength: 6 // Maximum input length set to 6 characters
                          }}
                          InputLabelProps={
                            authSectionStyles.commonStyles.inputLabelProps
                          }
                          disabled={pageFormDetails.values.challengeResponse}
                          onChange={pageFormDetails.handleChange}
                          value={pageFormDetails.values.authNumber}
                        />
                      </Box>

                      {pageFormDetails.values.challengeQuestion && (
                        <>
                          {/* Challange Question */}
                          <Box sx={authSectionStyles.commonStyles.boxWidth}>
                            <TextField
                              label={t("common.challengeQuestion")}
                              fullWidth
                              variant="standard"
                              InputProps={{
                                disableUnderline: true
                              }}
                              value={pageFormDetails.values.challengeQuestion}
                              InputLabelProps={
                                authSectionStyles.commonStyles.inputLabelProps
                              }
                            />
                          </Box>
                          {/* Challenge Response Field */}
                          <Box sx={authSectionStyles.commonStyles.boxWidth}>
                            <TextField
                              label={t("common.challengeResponse")}
                              type="password"
                              id="challengeResponse"
                              name="challengeResponse"
                              fullWidth
                              InputLabelProps={
                                authSectionStyles.commonStyles.inputLabelProps
                              }
                              inputProps={{
                                maxLength: 30 // Maximum input length set to 30 characters
                              }}
                              disabled={pageFormDetails.values.authNumber}
                              onChange={pageFormDetails.handleChange}
                              value={pageFormDetails.values.challengeResponse}
                            />
                          </Box>
                        </>
                      )}
                    </Box>

                    {/* Auth Button */}
                    <Box
                      sx={authSectionStyles.commonStyles.flex}
                      gap={spacing.gap}
                    >
                      <Button
                        variant="contained"
                        style={authSectionStyles.commonStyles.btn}
                        disabled={
                          !pageFormDetails.values?.customer ||
                          !pageFormDetails.values?.personnel
                        }
                        onClick={() => onAuthenticateClick()}
                      >
                        {t("common.authenticate")}
                      </Button>

                      {isAuthenticationError && (
                        <Alert
                          sx={authSectionStyles.personnelAuthStyles.alertWidth}
                          severity="error"
                          icon={<img src={WarningIcon} alt="Warning" />}
                        >
                          {showAuthenticationErrorMsg()}
                        </Alert>
                      )}
                    </Box>
                  </>
                )}
              </Stack>
            )}

            {/* -------------- Bypass Selection --------------  */}
            {valuePersonnelAuth === "bypass" && (
              <Stack gap={spacing.gap}>
                <Stack direction="row" gap={spacing.gap}>
                  {/* Reason Field */}
                  <Box>
                    <TextField
                      label={t("common.reason")}
                      size="medium"
                      variant="outlined"
                      multiline
                      fullWidth
                      rows={2}
                      sx={authSectionStyles.bypassStyles.tfReason}
                      id="bypassReason"
                      name="bypassReason"
                      onChange={pageFormDetails.handleChange}
                      value={pageFormDetails.values.bypassReason}
                    />
                  </Box>
                  {/* Bypassed by */}

                  <Box>
                    <TextField
                      label={t("common.bypassedBy")}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        disableUnderline: true
                      }}
                      value={pageFormDetails.values.bypassedBy}
                      InputLabelProps={
                        authSectionStyles.commonStyles.inputLabelProps
                      }
                    />
                  </Box>
                </Stack>

                {/* Bypass Button */}
                <Box sx={authSectionStyles.commonStyles.flex} gap={spacing.gap}>
                  <Button
                    variant="contained"
                    style={authSectionStyles.commonStyles.btn}
                    onClick={() => onBypassClick()}
                  >
                    {t("common.bypass")}
                  </Button>

                  {isBypassError && (
                    <Alert
                      sx={authSectionStyles.personnelAuthStyles.alertWidth}
                      severity="error"
                      icon={<img src={WarningIcon} alt="Warning" />}
                    >
                      {t("common.bypassResonRequired")}
                    </Alert>
                  )}
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default AuthenticationSection;
