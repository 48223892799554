import { Stack } from "@mui/material";
import { PageTitle } from "components/shared";
import i18n from "i18n";
import { useState } from "react";
import ContainerTabs from "../../components/container-processing/ContainerTabs";
const ContainerGeneral = () => {
  const [indexNumber, setIndexNumber] = useState(0);
  const handleTabIndexChange = (index) => {
    setIndexNumber(index);
  };
  return (
    <Stack>
      <PageTitle
        title={
          indexNumber === 0
            ? i18n.t("containerProcessing.picking.title")
            : indexNumber === 1
              ? i18n.t("containerProcessing.runprep.title")
              : indexNumber === 2
                ? i18n.t("containerProcessing.distribution.title")
                : i18n.t("containerProcessing.incompleteStops.title")
        }
      />
      <ContainerTabs handleTabIndexChange={handleTabIndexChange} />
    </Stack>
  );
};
export default ContainerGeneral;
