import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { canExitTabOrChangeIssueType } from "components/features/request-module/media-request-detail/utils";
import {
  selectMediaRequest,
  setActiveTab,
  setHaveChanges,
  setNextTab,
  setShowConfirmationModal
} from "store/slices";
import { HorizontalTabsStyles } from "./HorizontalTabs.Styles";

/**
 * Tab Panel container
 *
 * @param {children} node Tab Panel content
 * @param {activeTabIndex} number Currenlty active tab index
 * @param {tabIndex} number Tab index associated to this Tab Panel
 * @returns
 */
const TabPanel = ({ children, activeTabIndex, tabIndex }) => {
  return (
    <div
      role="tabpanel"
      hidden={activeTabIndex !== tabIndex}
      id={`tabpanel-${tabIndex}`}
      aria-labelledby={`tab-${tabIndex}`}
    >
      {activeTabIndex === tabIndex && (
        <Box id={`tabPanelContainer${tabIndex}`}>{children}</Box>
      )}
    </div>
  );
};

/**
 * Tab Panel props definition
 */
TabPanel.propTypes = {
  children: PropTypes.node,
  tabIndex: PropTypes.number.isRequired,
  activeTabIndex: PropTypes.number.isRequired
};

/**
 *
 * @param {index} number Tab Index
 * @returns
 */
const constructTabProps = (index) => {
  return {
    id: `tab${index}`,
    "aria-controls": `tabpanel-${index}`
  };
};

/**
 * Horizontal Tabs
 *
 * @param {string} id unique identifier
 * @param {string} ariaLabel
 * @param {boolean} disabled an array of tab content
 * @param {boolean} lockedTabIndex lock a particular tab using it's index
 * @param {array} tabs an array of tabs
 * @param {object} styles styles object
 */
const HorizontalTabs = (props) => {
  const { id, ariaLabel, tabs, disabled, styles } = props;
  const mediaRequestState = useSelector(selectMediaRequest); // select media request slice
  const dispatch = useDispatch(); // redux actions dispatcher

  // tab onChange event handler
  const handleTabOnChange = (_, tabIndex) => {
    if (!mediaRequestState.hasLockedTab) {
      // check the tab or issue type can be changed
      if (
        canExitTabOrChangeIssueType(mediaRequestState, props.extraItemsForm)
      ) {
        dispatch(setActiveTab(tabIndex));
        dispatch(setNextTab(-1));
        props.extraItemsForm.resetForm();
      } else {
        dispatch(setHaveChanges(true));
        dispatch(setShowConfirmationModal(true));
        dispatch(setNextTab(tabIndex));
      }
    }
  };

  return (
    <Box
      id={id}
      maxWidth="xl"
      sx={HorizontalTabsStyles.Container({
        disabled: disabled,
        styles: styles
      })}
    >
      {/* Tabs Wrapper*/}
      <Box
        id="horizontalTabsWrapper"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          id="horizontalTabsContainer"
          value={mediaRequestState.activeTab}
          onChange={handleTabOnChange}
          aria-label={ariaLabel}
        >
          {tabs.map(({ label, disabled }, index) => (
            <Tab
              id={`horizontalTabsTab${index}`}
              key={`tab${label}${index}`}
              sx={HorizontalTabsStyles.TextTransformNone}
              label={label} // disable a tab that is not locked
              {...constructTabProps(index)}
              disabled={disabled}
            />
          ))}
        </Tabs>
      </Box>

      {/* Tab Panel Area Start*/}
      {tabs.map(({ label, element, disabled }, index) => (
        <TabPanel
          id={`horizontalTabsTabPanel_${index}`}
          key={`tabPanel${label}${index}`}
          activeTabIndex={mediaRequestState.activeTab}
          tabIndex={index}
          disabled={disabled}
        >
          {element}
        </TabPanel>
      ))}
      {/* Tab Panel Area End*/}
    </Box>
  );
};

/**
 * Horizontal Tabs props definition
 */
HorizontalTabs.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      element: PropTypes.node.isRequired
    })
  ).isRequired,
  disabled: PropTypes.bool,
  extraItemsForm: PropTypes.object // formik instance for the extra locks and clips
};

export default HorizontalTabs;
