/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import ModalPopup from "components/core/modal-components/ModalPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_MODULE_DESTRUCTION_BASE_PATH
} from "routing/Paths";
import {
  useCancelDestructRequestMutation,
  useDestructRequestCommentInsertMutation,
  useGetAllCustomersQuery,
  useGetAuthorizationPersonnelsAuthenticationsMutation,
  useGetAuthorizationPersonnelsMutation,
  useGetCustomerDataByIdMutation,
  useGetDesReqCommentMutation,
  useGetDestructRequestDetailsByMutation,
  useGetMessageByIdFromMessageMutation,
  useGetServiceLocationByCustomerMutation,
  useGetServiceLocationByCustomerTriggerMutation,
  useGetValidateChallengeResponseMutation,
  usePostNewDestructRequestMutation,
  usePostUpdateDestructRequestMutation,
  useVerifyPersonnelPermissionStatusMutation
} from "services/api";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  Dates,
  DestructionComment,
  DestructionStatus,
  ERROR_TYPES,
  MessageCode,
  N_CHECKER,
  TrackingRequirement,
  Y_CHECKER,
  dayMonthYearFormat
} from "utils/constants";
import {
  findErrorMessage,
  formatDateByLocale,
  generateSHA256Hash,
  getAuthenticatedUserBranch,
  getLanguageId
} from "utils/helpers";
import { ReactComponent as ArrowDown } from "../../../../assets/images/ArrowDown.svg";
import { ReactComponent as ArrowDownDisabled } from "../../../../assets/images/ArrowDownDisabled.svg";
import { ReactComponent as ClosedIcon } from "../../../../assets/images/CloseIcon.svg";
import { MediaDestructionRequestDeliveryFormStyles } from "./MediaDestructionRequestDeliveryFormStyles";
import DownloadReportModal from "./PrintReportModal";
import CancelAuthenticationModal from "./authentication-modal/CancelAuthenticationModal";
import AuthorizationFormFields from "./authorizationFormFiels/AuthorizationFormFields";
import FormActionButtons from "./formActionButtons/FormActionButtons";
import ScheduleServiceForm from "./scheduleServiceForm/ScheduleServiceForm";
import {
  destructionDeliveryMdrdFormSchema,
  destructionDeliveryScheduleFormSchema,
  destructionDeliverySitadFormSchema
} from "./schema";
import SitadDeliveryForm from "./sitadDeliveryForm/SitadDeliveryForm";

const escapeJsonString = (value) =>
  value.replace(/'/g, "''").replace(/\\/g, "\\\\");

const MediaDestructionRequestDeliveryForm = ({
  destructionRqDetails,
  setDestructionId,
  destructionId,
  setDestructionRqDetails,
  isExistingDestructionRq,
  setIsExistingDestructionRq,
  setNewRequestSaved,
  newRequestSaved,
  setLoadingDestructionDetails
}) => {
  const mediaRequestInitialValues = {
    customer: "",
    authorizedBy: "",
    authorizationType: "personal",
    authNumber: "",
    authorizationChallengeQuestion: "",
    authorizationChallengeResponse: "",
    bypassReason: ""
  };

  const sitadFormInitialValues = {
    trackingRequirement: TrackingRequirement.Delivery,
    preferredDeliveryDate: null,
    serviceLocation: "",
    deliveryInstructions: ""
  };

  const scheduleServiceFormInitialValues = {
    scheduleTrackingRequirement: TrackingRequirement.Delivery,
    scheduleDeliveryDate: null,
    scheduleServiceLocation: "",
    scheduleDeliveryInstructions: ""
  };
  const mdrdForm = useFormik({
    initialValues: mediaRequestInitialValues,
    validationSchema: destructionDeliveryMdrdFormSchema
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const selectedBranch = getAuthenticatedUserBranch();
  const {
    auth,
    authInfo,
    currentBranch,
    roleAccess,
    localeByBranch,
    allLocations
  } = useSelector(selectAuth);

  const [pageLoading, setPageLoading] = useState(false);

  //get all customers from the query
  const { data: customers = [], isLoading: customersLoading } =
    useGetAllCustomersQuery({
      mainDistrictId: String(currentBranch?.district_id)
    });

  //get all authorize personals according to customer from the mutation
  const [getAuthorizationPersonnels, { data: authorizationPersonnels = [] }] =
    useGetAuthorizationPersonnelsMutation(); // get authorized

  //get authorized person data from the query
  const [
    getAuthorizationPersonnelsAuthentications,
    { data: personnelAuthrizationData = {} }
  ] = useGetAuthorizationPersonnelsAuthenticationsMutation();

  //get all service locations from the query by customer
  const [getServiceLocations, { data: serviceLocation = [] }] =
    useGetServiceLocationByCustomerMutation({
      customerId: String(mdrdForm.values?.customer)
    });

  //trigger verify personnel
  const [triggerVerifyPersonnel, { verifyPersonnel = [], isVerifyPersonnel }] =
    useVerifyPersonnelPermissionStatusMutation();

  const [messageId, setMessageId] = useState("");
  //get all personnel from the query
  const [getMessageById, { data: message = {}, isLoading: messageLoading }] =
    useGetMessageByIdFromMessageMutation();

  //save destruct request mutation
  const [triggerSave, { data = [], isLoadingSave }] =
    usePostNewDestructRequestMutation();

  //update destruct request mutation
  const [triggerUpdate, { updatedData = [], isLoadingUpdate }] =
    usePostUpdateDestructRequestMutation();

  //trigger service locations
  const [
    triggerServiceLocations,
    { triggerServiceLocationsData = [], isLoadingTriggerServiceLocation }
  ] = useGetServiceLocationByCustomerTriggerMutation();

  //Cancel destruct request mutation
  const [triggerCancel, { cancelData = [], isLoadingCancel }] =
    useCancelDestructRequestMutation();

  //get destruct request details mutation
  const [
    triggerGetDestructRqDetails,
    { destructData = [], isLoadingDestructData }
  ] = useGetDestructRequestDetailsByMutation();

  //get destruct request comment mutation
  const [
    getDestructionComment,
    { data: desReqComment = [], isLoading: desReqCommentLoading }
  ] = useGetDesReqCommentMutation();

  //insert destruction comment
  const [triggerInsertComment, { insertComment = [], isLoadingInsertComment }] =
    useDestructRequestCommentInsertMutation();

  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  //get customer data by id
  const [
    getCustomerDataById,
    { data: customerDataById = [], isLoading: isLoadingCustomerDataById }
  ] = useGetCustomerDataByIdMutation();

  //min date state
  const minDate = dayjs();
  //max date state
  const maxDate = dayjs(Dates.MaxDate);
  // eslint-disable-next-line no-unused-vars
  const [trackingRequirement, setTrackingRequirement] = useState([
    {
      label: `${t("mediaDestructionDelivery.trackingRequirementLabel")}`,
      value: TrackingRequirement.Delivery
    }
  ]);
  const initialDestructionRequestBody = {};
  const updateDestructionRequestBody = {};
  const pageStatus = useRef("none");
  const [scheduleTrackingRequirement, setScheduleTrackingRequirement] =
    useState([
      {
        label: `${t("mediaDestructionDelivery.trackingRequirementLabel")}`,
        value: TrackingRequirement.Delivery
      }
    ]);
  const [customersAfterAuth, setCustomersAfterAuth] = useState([]);
  const serviceLocationSelected = useRef("");
  const [scheduleServiceLocationSelected, setScheduleServiceLocationSelected] =
    useState("");
  const [authon, setAuth] = useState(false);
  const [authFail, setAuthFail] = useState(false);
  const [showAuthText, setShowAuthText] = useState(false);
  const [showPersonnelAuthorizeError, setShowPersonnelAuthorizeError] =
    useState(false);

  const [byPass, setBypass] = useState(false);
  const [disablePersonnel, setDisablePersonnel] = useState(false);
  const [authWarningAlert, setAuthWarningAlert] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [warningModalText, setWarningModalText] = useState("");
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openCancelAuthModal, setOpenCancelAuthModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [sitadFormDisable, setSitadFormDisable] = useState(false);
  const [resetButtonEnable, setResetButtonEnable] = useState(true);
  const [authorizedDate, setAuthorizedDate] = useState(null);
  const [cancelledDate, setCancelledDate] = useState(null);
  const [completedDate, setCompletedDate] = useState(null);
  const [sitadModDate, setSitadModDate] = useState(null);
  const [scheduleModDate, setScheduleModDate] = useState(null);

  //modified date by time
  const [sitadModifiedDate, setSitadModifiedDate] = useState("");
  const [scheduleServiceModifiedDate, setScheduleServiceModifiedDate] =
    useState("");

  //schedule service form showing/hide state
  const [showScheduleServiceForm, setShowScheduleServiceForm] = useState(false);
  //is form submit / not submitted
  const [formSubmitted, setFormSubmitted] = useState(false);
  // check whether new or reset click
  const [btnType, setBtnType] = useState("new");
  const customerDef = useRef("");
  const [authorizedByDef, setAuthorizedByDef] = useState("");

  const [preferredDeliveryDateDef, setPreferredDeliveryDateDef] = useState("");
  const [scheduleDeliveryDateDef, setScheduleDeliveryDateDef] = useState("");

  const [pageInitialButtonDisable, setPageInitialButtonDisable] =
    useState(true);
  const [saveEnable, setSaveEnable] = useState(true);
  const [cancelEnable, setCancelEnable] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [scheduleFormFields, setScheduleFormFields] = useState(false);
  const [newEnable, setNewEnable] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(false);

  //Warning Modal Open/Close
  const handleOpenWarningModal = () => setOpenWarningModal(true);
  const handleCloseWarningModal = () => setOpenWarningModal(false);

  const languageLocaleId = getLanguageId();

  //get verify personnel status
  const getVerifyPersonnelpermission = async (programId) => {
    try {
      const { data: triggeredData = [] } = await triggerVerifyPersonnel({
        customer_id: mdrdForm.values?.customer,
        personnel_id: mdrdForm.values?.authorizedBy,
        program_id: programId
      });
      return triggeredData;
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  //get employee permission according to employee program id s
  const getEmployeePermission = (employeeProgramId) => {
    const filter = roleAccess?.filter((id) => id == employeeProgramId);
    return filter.length ? true : false;
  };

  //check employee and personnel permission in different stages
  const checkPermission = async (personnelProgramId = "") => {
    if (pageStatus.current == "authenticated") {
      const verPer = await getVerifyPersonnelpermission(personnelProgramId);

      getEmployeePermission(345) && verPer
        ? setMessageId("")
        : setMessageId(String(MessageCode.InsufficientAuthorization));

      return getEmployeePermission(345) && verPer;
    } else if (pageStatus.current == "save") {
      return getEmployeePermission(347);
    } else if (pageStatus.current == "cancel") {
      const verPer = await getVerifyPersonnelpermission(personnelProgramId);
      return verPer;
    } else if (pageStatus.current == "showSchedule") {
      return getEmployeePermission(347);
    } else if (pageStatus.current == "new") {
      return getEmployeePermission(345);
    }
  };

  //show error message popup for permissions are not available
  const showErrorMessagePopup = (errmessageId) => {
    setShowPopup(true);
    getMessageById({
      message_id: String(errmessageId)
    });
  };

  //get page to new status
  const setPageToNew = () => {
    mdrdForm.resetForm();
    sitadForm.resetForm();
    scheduleServiceForm.resetForm();
    setLoadingDestructionDetails(false);
    setAuth(false);
    setShowScheduleServiceForm(false);
    setThrowError(false);
    setThrowErrorMessage(false);
    setCustomersAfterAuth([]);
    serviceLocationSelected.current = "";
    setScheduleServiceLocationSelected("");
    setShowAuthText(false);
    setAuthFail(false);
    setShowPersonnelAuthorizeError(false);
    setBypass(false);
    setDisablePersonnel(false);
    setSitadFormDisable(false);
    setOpenWarningModal(false);
    setCancelDisable(false);
    setWarningModalText("");
    setOpenPrintModal(false);
    setOpenCancelAuthModal(false);
    setIsButtonDisabled(false);
    setSitadModifiedDate("");
    setScheduleServiceModifiedDate("");
    setFormSubmitted(false);
    setBtnType("new");
    customerDef.current = "";
    setAuthorizedByDef("");
    setResetButtonEnable(true);
    setPreferredDeliveryDateDef("");
    setScheduleDeliveryDateDef("");
    setNewRequestSaved(false);
    pageStatus.current = "none";
    setSaveEnable(true);
    setCancelEnable(true);
    setShowPopup(false);
    setScheduleFormFields(false);
    setIsExistingDestructionRq("");
    setDestructionRqDetails("");
    setDestructionId("");
    setPageInitialButtonDisable(true);
    setNewEnable(false);
    setAuthorizedDate(false);
    setCancelledDate(false);
    setCompletedDate(false);
    setSitadModDate(false);
    setScheduleModDate(false);
    navigate(
      `${REQUEST_MODULE_BASE_PATH}/${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}`
    );
  };

  //fetch destruction details object
  const fetchDestructionDetails = async (destrutId) => {
    try {
      const { data: triggeredData = [] } = await triggerGetDestructRqDetails({
        main_district_id: String(currentBranch?.district_id),
        destruct_request_id: String(destrutId)
      });
      setDestructionRqDetails(triggeredData[0]);
      return triggeredData;
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  //handle set object when save click trigger
  const handleSetToObjectScheduleFormSave = async (form1, form2) => {
    initialDestructionRequestBody.main_district_id = String(
      currentBranch?.district_id
    );
    initialDestructionRequestBody.approx_bulk_count = "";
    initialDestructionRequestBody.pickup_instruction = "";
    initialDestructionRequestBody.volser_list = "";
    initialDestructionRequestBody.im_comment = "";
    initialDestructionRequestBody.customer_id = form1.values?.customer;
    initialDestructionRequestBody.requested_personnel_id =
      form1.values?.authorizedBy;
    initialDestructionRequestBody.business_email_addr = authInfo?.email;
    initialDestructionRequestBody.logged_in_employee_id =
      auth?.user?.employee_id;
    initialDestructionRequestBody.destruct_request_type_id =
      DestructionStatus.Authorized;
    initialDestructionRequestBody.preferred_service_date =
      form2.values?.preferredDeliveryDate?.format("YYYY-MM-DD 00:00:00");
    initialDestructionRequestBody.preferred_service_location_customer_id =
      form2.values?.serviceLocation;
    initialDestructionRequestBody.tracking_number = "";
    initialDestructionRequestBody.auth_bypass_reason =
      form1.values?.bypassReason;

    return initialDestructionRequestBody;
  };

  //handle set object when save click trigger
  const handleSetToObjectFormUpdate = async (form1) => {
    updateDestructionRequestBody.main_district_id = String(
      currentBranch?.district_id
    );
    updateDestructionRequestBody.service_result_id = "";
    updateDestructionRequestBody.system_id = "";
    updateDestructionRequestBody.destruct_request_id = destructionId;
    updateDestructionRequestBody.destruct_req_timestamp = "";
    updateDestructionRequestBody.day_of_week_code =
      form1.values?.scheduleDeliveryDate?.format("ddd").toUpperCase();
    updateDestructionRequestBody.tracking_number = "";
    updateDestructionRequestBody.bulk_weight = "";
    updateDestructionRequestBody.destruct_request_type_id =
      DestructionStatus.Authorized;
    updateDestructionRequestBody.to_destruct_request_status_id =
      DestructionStatus.Scheduled;
    updateDestructionRequestBody.logged_in_employee_id =
      auth?.user?.employee_id;
    updateDestructionRequestBody.unit_of_measure_id = "";
    updateDestructionRequestBody.approx_bulk_quantity = "";
    updateDestructionRequestBody.im_comment = "";
    updateDestructionRequestBody.actual_quantity = "";
    updateDestructionRequestBody.service_date =
      form1.values?.scheduleDeliveryDate.format("YYYY-MM-DD 00:00:00");
    updateDestructionRequestBody.customer_id_service_location =
      form1.values?.scheduleServiceLocation;

    return updateDestructionRequestBody;
  };

  //Handle save click button
  const handleSaveClick = async () => {
    if (
      destructionRqDetails?.destruct_request_status_id !=
      DestructionStatus.Authorized
    ) {
      if (!showScheduleServiceForm) {
        Object.keys(sitadForm.values).forEach((field) => {
          sitadForm.setFieldTouched(field, true);
        });
        if (Object.keys(sitadForm.errors).length === 0) {
          sitadForm.handleSubmit();
        }
      } else if (showScheduleServiceForm) {
        Object.keys(scheduleServiceForm.values).forEach((field) => {
          scheduleServiceForm.setFieldTouched(field, true);
        });
        if (Object.keys(scheduleServiceForm.errors).length === 0) {
          scheduleServiceForm.handleSubmit();
        }
      }
    } else {
      Object.keys(scheduleServiceForm.values).forEach((field) => {
        scheduleServiceForm.setFieldTouched(field, true);
        if (Object.keys(scheduleServiceForm.errors).length === 0) {
          scheduleServiceForm.handleSubmit();
        }
      });
    }
  };

  //Handle save SITAD form
  const handleSaveSitadForm = async () => {
    try {
      const { data: customerOnHold = [], error } = await getCustomerDataById({
        main_district_id: String(currentBranch?.district_id),
        customer_id: mdrdForm.values?.customer
      });
      if (error) {
        setThrowErrorMessage(error?.message);
        setThrowError(true);
      } else if (customerOnHold) {
        if (customerOnHold[0]?.mdr_on_hold_status == N_CHECKER) {
          pageStatus.current = "save";
          try {
            const permissionSave = await checkPermission();
            if (permissionSave) {
              setSaveEnable(true);
              setPageInitialButtonDisable(false);
              pageStatus.current = "none";
            } else {
              setSaveEnable(false);
              pageStatus.current = "none";
            }
          } catch (e) {
            setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            setThrowError(true);
          }

          const obj = await handleSetToObjectScheduleFormSave(
            mdrdForm,
            sitadForm,
            scheduleServiceForm
          );
          try {
            const { data: triggeredDataSave = [], error } =
              await triggerSave(obj);
            if (error) {
              setThrowErrorMessage(error);
              setThrowError(true);
            } else if (triggeredDataSave) {
              if (triggeredDataSave[0]?.destruct_request_id) {
                setCancelDisable(false);
                pageStatus.current = "cancel";
                const permissionCancel = await checkPermission(348);
                permissionCancel
                  ? setCancelEnable(true)
                  : setCancelEnable(false);
                pageStatus.current = "showSchedule";
                try {
                  const permissionScheduleForm = await checkPermission();
                  if (permissionScheduleForm) {
                    setScheduleFormFields(true);
                  } else {
                    setScheduleFormFields(false);
                  }
                } catch (e) {
                  setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
                  setThrowError(true);
                }

                setShowScheduleServiceForm(true);
                scheduleServiceForm.setValues({
                  ...scheduleServiceForm?.values,
                  scheduleServiceLocation: sitadForm.values?.serviceLocation,
                  scheduleDeliveryInstructions:
                    sitadForm.values?.deliveryInstructions
                });
                setDestructionId(true);
                setSitadModifiedDate(
                  dayjs(Date.now()).format(dayMonthYearFormat)
                );

                setDestructionId(
                  parseInt(triggeredDataSave[0]?.destruct_request_id)
                );
                const desCommentObj = {
                  main_district_id: String(currentBranch?.district_id),
                  destruct_request_id:
                    triggeredDataSave[0]?.destruct_request_id,
                  destruct_request_comment_type_id:
                    DestructionComment.PickupInstruction,
                  destruct_request_status_id: DestructionStatus.Authorized,
                  comment_text: escapeJsonString(
                    sitadForm.values?.deliveryInstructions
                  ),
                  last_mod_date: "",
                  last_mod_user: String(authInfo?.displayName)
                };
                const response = await triggerInsertComment(desCommentObj);
                if (response?.error) {
                  setThrowErrorMessage(response?.error?.message);
                  setThrowError(true);
                } else {
                  const { data: triggeredData = [] } = response;
                  if (triggeredData) {
                    setNewRequestSaved(true);
                    getCustomerDataById({
                      main_district_id: String(currentBranch?.district_id),
                      customer_id: mdrdForm.values?.customer
                    });
                    navigate(
                      `${REQUEST_MODULE_BASE_PATH}/${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}/${triggeredDataSave[0]?.destruct_request_id}`
                    );
                    setLoadingDestructionDetails(false);
                    const destructionData = await fetchDestructionDetails(
                      triggeredDataSave[0]?.destruct_request_id
                    );
                    if (destructionData) {
                      const filteredLocation = serviceLocation.filter(
                        (value) =>
                          value?.label.trim() ===
                          destructionData[0]?.preferred_service_location.trim()
                      );
                      setScheduleServiceLocationSelected(filteredLocation[0]);
                    }
                  }
                }
              } else if (triggeredDataSave[0]?.error) {
                showErrorMessagePopup(triggeredDataSave[0]?.error);
              }
            }
          } catch (e) {
            setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            setThrowError(true);
          }
        } else {
          setMessageId(String(MessageCode.InsufficientAuthorization));
          showErrorMessagePopup(String(MessageCode.InsufficientAuthorization));
        }
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  //Handle save schedule form
  const handleSaveScheduleForm = async () => {
    if (
      id &&
      destructionRqDetails?.destruct_request_status_id ==
        DestructionStatus.Authorized
    ) {
      setNewRequestSaved(true);
      try {
        const updateObj =
          await handleSetToObjectFormUpdate(scheduleServiceForm);
        const { data: triggeredData = [], error } =
          await triggerUpdate(updateObj);
        if (error) {
          setThrowErrorMessage(error);
          setThrowError(true);
        } else if (triggeredData) {
          if (triggeredData[0]?.request_id) {
            const desCommentObj = {
              main_district_id: String(currentBranch?.district_id),
              destruct_request_id: destructionId,
              destruct_request_comment_type_id:
                DestructionComment.PickupInstruction,
              destruct_request_status_id: DestructionStatus.Scheduled,
              comment_text: escapeJsonString(
                scheduleServiceForm.values?.scheduleDeliveryInstructions
              ),
              last_mod_date: "",
              last_mod_user: String(authInfo?.displayName)
            };
            const response = await triggerInsertComment(desCommentObj);
            if (response?.error) {
              setThrowErrorMessage(response?.error?.message);
              setThrowError(true);
            } else {
              const { data: triggeredData = [] } = response;
              if (triggeredData) {
                getCustomerDataById({
                  main_district_id: String(currentBranch?.district_id),
                  customer_id: mdrdForm.values?.customer
                });
                fetchDestructionDetails(destructionId);
              }
            }
          } else if (triggeredData[0]?.error) {
            showErrorMessagePopup(triggeredData[0]?.error);
          }
        }
      } catch (e) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    }
  };

  //SITAD form initial values
  const sitadForm = useFormik({
    initialValues: sitadFormInitialValues,
    onSubmit: handleSaveSitadForm,
    validationSchema: destructionDeliverySitadFormSchema
  });

  //schedule service form initial values
  const scheduleServiceForm = useFormik({
    initialValues: scheduleServiceFormInitialValues,
    onSubmit: handleSaveScheduleForm,
    validationSchema: destructionDeliveryScheduleFormSchema
  });

  //handle yes button
  const handleYes = async () => {
    if (btnType === "new") {
      setPageToNew();
      handleCloseWarningModal();
    } else if (btnType === "reset") {
      if (!showScheduleServiceForm) {
        sitadForm.setFieldValue("preferredDeliveryDate", null);
        sitadForm.setFieldValue("deliveryInstructions", "");
        sitadForm.setFieldTouched("deliveryInstructions", false);
        handleCloseWarningModal();
      } else if (showScheduleServiceForm) {
        scheduleServiceForm.setFieldValue("scheduleDeliveryDate", null);
        sitadForm.setFieldTouched("scheduleDeliveryDate", false);
        setFormSubmitted(false);
        handleCloseWarningModal();
      }
    } else if (btnType === "cancel") {
      if (isExistingDestructionRq && !newRequestSaved) {
        handleCloseWarningModal();
        handleOpenCancelAuthModal();
        setAuthWarningAlert(true);
      } else {
        handleCloseWarningModal();
        const obj = {
          main_district_id: String(currentBranch?.district_id),
          destruct_request_id: destructionId,
          cancel_personnel_id: String(mdrdForm.values?.authorizedBy),
          cancel_employee_id: String(auth?.user?.employee_id),
          destruct_cancel_reason_id: 1
        };
        try {
          const response = await triggerCancel(obj);
          if (response?.error) {
            setThrowErrorMessage(response?.error?.message);
            setThrowError(true);
          } else {
            const { data: triggeredData = [] } = response;
            if (triggeredData) {
              const afterCancelDesObj =
                await fetchDestructionDetails(destructionId);

              if (afterCancelDesObj[0]?.service_location == "") {
                setShowScheduleServiceForm(false);
              }
              getCustomerDataById({
                main_district_id: String(currentBranch?.district_id),
                customer_id: mdrdForm.values?.customer
              });
            }
          }
        } catch (e) {
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
        }
      }
    }
  };

  //Print modal open/close
  const handleOpenPrintModal = () => setOpenPrintModal(true);
  const handleClosePrintModal = () => setOpenPrintModal(false);

  //Cancel auth modal open/close
  const handleOpenCancelAuthModal = () => setOpenCancelAuthModal(true);
  const handleCloseCancelAuthModal = () => setOpenCancelAuthModal(false);

  //check customer and personnel authorization for create sitad after authenticate and change the customer
  const checkRequestCreateAfterAuthOn = async (customer) => {
    customerDef.current = customer;
    pageStatus.current = "authenticated";
    try {
      const { data: triggeredData = [] } = await triggerVerifyPersonnel({
        customer_id: customer.value,
        personnel_id: mdrdForm.values?.authorizedBy,
        program_id: 346
      });
      const serviceLocationsNew = await getServiceLocations({
        customerId: String(customer.value)
      });
      if (serviceLocationsNew.data) {
        sitadForm.handleChange({
          target: {
            name: "serviceLocation",
            value: serviceLocationsNew.data[0]?.value
          }
        });
        serviceLocationSelected.current = serviceLocationsNew.data[0];
      }
      if (getEmployeePermission(345) && triggeredData) {
        setMessageId("");
        setSitadFormDisable(false);
        setResetButtonEnable(false);
        setSaveEnable(true);
        setCancelDisable(true);
      } else {
        setMessageId(String(MessageCode.InsufficientAuthorization));
        setSitadFormDisable(true);
        setSaveEnable(false);
        showErrorMessagePopup(String(MessageCode.InsufficientAuthorization));
        setResetButtonEnable(true);
        setCancelDisable(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };
  //Authenticate personnel
  const handleAuthenticate = async () => {
    //generate hash code
    let validateChallengeResponse;
    if (mdrdForm.values?.authorizationChallengeResponse.length > 0) {
      let hashCode = await generateSHA256Hash(
        mdrdForm.values?.authorizationChallengeResponse.trim().toUpperCase()
      );

      //challenge response validation
      validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: String(currentBranch?.district_id),
        language_locale_id: languageLocaleId,
        personnel_id: mdrdForm.values?.authorizedBy,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          const error = data && data[0]?.error;
          //checking for the error
          if (error) {
            setThrowErrorMessage(findErrorMessage(data[0]?.error));
            setThrowError(true);
          } else {
            setThrowErrorMessage("");
            setThrowError(false);
            return data[0]?.is_match_flag;
          }
        })
        .catch((e) => {
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
        });
    }
    if (
      // eslint-disable-next-line no-mixed-operators
      (personnelAuthrizationData?.authNumber !== "" &&
        mdrdForm.values?.authorizationChallengeResponse !== "" &&
        mdrdForm.values?.authNumber !== "" &&
        mdrdForm.values?.authorizationChallengeResponse == "" &&
        // eslint-disable-next-line no-mixed-operators
        personnelAuthrizationData?.authNumber == mdrdForm.values?.authNumber) ||
      (mdrdForm.values?.authorizationChallengeResponse !== "" &&
        mdrdForm.values?.authNumber == "" &&
        validateChallengeResponse == Y_CHECKER) ||
      (mdrdForm.values?.authorizationChallengeResponse == "" &&
        mdrdForm.values?.authNumber !== "" &&
        mdrdForm.values?.authorizationChallengeResponse == "" &&
        // eslint-disable-next-line no-mixed-operators
        personnelAuthrizationData?.authNumber == mdrdForm.values?.authNumber)
    ) {
      setCancelDisable(true);
      setThrowError(false);
      setShowAuthText(true);
      setPageInitialButtonDisable(false);
      setAuthFail(false);
      const res = await getCustomerDataById({
        main_district_id: String(currentBranch?.district_id),
        customer_id: mdrdForm.values?.customer
      });
      if (res?.data.length > 0) {
        if (res?.data[0]?.mdr_on_hold_status == Y_CHECKER) {
          showErrorMessagePopup("63992");
          setDisablePersonnel(true);
          setSitadFormDisable(true);
        }
      }
      pageStatus.current = "authenticated";
      try {
        const permissionCreate = await checkPermission(346);
        pageStatus.current = "new";
        const permissionNew = await checkPermission();
        permissionNew ? setNewEnable(true) : setNewEnable(false);
        if (permissionCreate) {
          setAuth(true);
          setDisablePersonnel(true);
          const customersAfterAuthenticated = JSON.parse(
            personnelAuthrizationData?.customerList
          ).map((item) => ({
            label: `${item.number} - ${item.name}`,
            value: String(item.customer_Id),
            number: item.number
          }));
          setCustomersAfterAuth(customersAfterAuthenticated);
          const filteredLocation = serviceLocation.filter(
            (value) => value?.value === serviceLocation[0]?.value
          );
          sitadForm.handleChange({
            target: {
              name: "serviceLocation",
              value: serviceLocation[0]?.value
            }
          });
          serviceLocationSelected.current = filteredLocation[0];
          pageStatus.current = "none";
          setResetButtonEnable(false);
        } else {
          setSaveEnable(false);
          showErrorMessagePopup("64097");
        }
      } catch (e) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } else {
      pageStatus.current = "none";
      setAuthFail(true);
      setThrowError(false);
    }
  };

  //Handle service location onchange
  const handleServiceLocationChange = (event, newValue) => {
    if (newValue === null) {
      serviceLocationSelected.current = "";
    } else {
      serviceLocationSelected.current = newValue;
    }

    const filteredLocation = serviceLocation.filter(
      (value) => value?.value === newValue?.value
    );
    sitadForm.handleChange({
      target: { name: "serviceLocation", value: newValue?.value }
    });
    scheduleServiceForm.handleChange({
      target: {
        name: "scheduleServiceLocation",
        value: sitadForm.values?.serviceLocation
      }
    });

    setScheduleServiceLocationSelected(filteredLocation[0]);
  };

  //Handle schedule service location onchange
  const handleScheduleServiceLocationChange = (event, newValue) => {
    if (newValue === null) {
      setScheduleServiceLocationSelected("");
    } else {
      setScheduleServiceLocationSelected(newValue);
    }

    scheduleServiceForm.handleChange({
      target: { name: "scheduleServiceLocation", value: newValue?.value }
    });
  };

  //handle personnel change
  const handlePersonnelChange = (event, value) => {
    mdrdForm.handleChange({
      target: { name: "authorizedBy", value: value?.value }
    });
    mdrdForm.handleChange({
      target: {
        name: "authorizationChallengeQuestion",
        value: personnelAuthrizationData?.secretQuestion
      }
    });
    value ? setAuthorizedByDef(value) : setAuthorizedByDef("");

    setAuth(false);
    setAuthFail(false);
    mdrdForm.setFieldValue(
      "authorizationChallengeResponse",
      mdrdForm.initialValues.authorizationChallengeResponse
    );
    mdrdForm.setFieldValue("authNumber", mdrdForm.initialValues.authNumber);
    mdrdForm.setFieldValue(
      "authorizationChallengeQuestion",
      mdrdForm.initialValues.authorizationChallengeQuestion
    );
    mdrdForm.setFieldValue("bypassReason", mdrdForm.initialValues.bypassReason);
    setShowPopup(false);
    setSaveEnable(true);
    setShowAuthText(false);
  };

  //Handle reset click button
  const handleResetClick = () => {
    setWarningModalText(
      `${t("mediaDestructionDelivery.resetWarningModalText")}`
    );
    setBtnType("reset");
    handleOpenWarningModal();
  };

  //Handle new click button
  const handleNewClick = () => {
    setWarningModalText(`${t("mediaDestructionDelivery.newWarningModalText")}`);
    setBtnType("new");
    handleOpenWarningModal();
    if (id) {
      setFormSubmitted(true);
    }
  };

  //handle cancel click button
  const handleCancelClick = () => {
    setWarningModalText(
      `${t("mediaDestructionDelivery.cancelWarningModalText")}`
    );
    setBtnType("cancel");
    handleOpenWarningModal();
  };

  // Get Personnel
  useEffect(() => {
    getAuthorizationPersonnels({
      customerId: mdrdForm.values?.customer,
      select_all_personnel_flag: "Y"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdrdForm.values?.customer, currentBranch?.district_id]);

  //check customer on hold status
  useEffect(() => {
    setDisablePersonnel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdrdForm.values?.customer]);

  useEffect(() => {
    getServiceLocations({
      customerId: String(mdrdForm.values?.customer)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdrdForm.values?.customer]);
  //fetch get Personnel data mutation
  useEffect(() => {
    getAuthorizationPersonnelsAuthentications({
      personnelId: mdrdForm.values?.authorizedBy
    })
      .then((resp) => resp.data)
      .then((resp) => {
        mdrdForm.setFieldValue("authNumber", "");
        mdrdForm.setFieldValue(
          "authorizationChallengeQuestion",
          resp?.secretQuestion
        );
        mdrdForm.setFieldValue("authorizationChallengeResponse", "");
        mdrdForm.setFieldValue("bypassReason", "");
        mdrdForm.setFieldValue("bypassedBy", "");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdrdForm.values?.authorizedBy]);

  //fetch message mutation
  useEffect(() => {
    getMessageById({
      message_id: messageId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId]);

  //fetch destruct comment mutation
  useEffect(() => {
    getDestructionComment({
      main_district_id: String(currentBranch?.district_id),
      destruct_request_id: id,
      destruct_request_status_id: String(DestructionStatus.Authorized)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentBranch?.district_id]);

  // Update the disabled status whenever auth or byPass changes
  useEffect(() => {
    setIsButtonDisabled(authon || byPass);
  }, [authon, byPass]);

  //set logged in user data to the loggedUserData state
  useEffect(() => {
    sitadForm.setValues({
      ...sitadForm?.values,
      trackingRequirement: trackingRequirement[0]?.value
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Reset states when navigating from a route with id to a route without id
    if (
      !id &&
      location.pathname.includes(
        `${REQUEST_MODULE_BASE_PATH}/${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}`
      )
    ) {
      mdrdForm.resetForm();
      sitadForm.resetForm();
      scheduleServiceForm.resetForm();
      customerDef.current = "";
      setAuthorizedByDef("");
      setAuth("");
      setShowScheduleServiceForm(false);
      pageStatus.current = "none";
      setAuth(false);
      setShowAuthText(false);
      setPageInitialButtonDisable(true);
      setResetButtonEnable(true);
    }
  }, [id, location.pathname]);

  //set form fields for existing MDR
  useEffect(() => {
    if (!newRequestSaved && isExistingDestructionRq && destructionRqDetails) {
      const checkPermissionsForActions = async () => {
        try {
          pageStatus.current = "showSchedule";
          const permissionScheduleForm = await checkPermission();
          permissionScheduleForm
            ? setScheduleFormFields(true)
            : setScheduleFormFields(false);
          pageStatus.current = "cancel";
          const permissionCancel = await checkPermission(348);
          permissionCancel ? setCancelEnable(true) : setCancelEnable(false);
          pageStatus.current = "new";
          const permissionNew = await checkPermission();
          permissionNew ? setNewEnable(true) : setNewEnable(false);
          pageStatus.current = "save";
          const permissionSave = await checkPermission(347);
          if (permissionSave) {
            setSaveEnable(true);
            setPageInitialButtonDisable(false);
          } else {
            setSaveEnable(false);
          }
        } catch (e) {
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
        }
      };
      checkPermissionsForActions();
      const cust = customers?.find(
        (customer) => customer?.value == destructionRqDetails?.customer_id
      );
      customerDef.current = cust;
      mdrdForm.handleChange({
        target: {
          name: "customer",
          value: destructionRqDetails?.customer_id
        }
      });

      mdrdForm.handleChange({
        target: {
          name: "authorizedBy",
          value: destructionRqDetails?.requested_personnel_id
        }
      });
      setAuthorizedByDef(destructionRqDetails?.requested_personnel_name);
      setAuth(true);

      const fetchFormData = async () => {
        try {
          const { data: triggeredServiceLocations = [] } =
            await triggerServiceLocations({
              customerId: String(destructionRqDetails?.customer_id)
            });
          const serviceLocationValue = triggeredServiceLocations?.find(
            (location) =>
              location.label.trim() ==
              destructionRqDetails?.preferred_service_location.trim()
          );
          setPreferredDeliveryDateDef(
            destructionRqDetails?.preferred_service_date
              ? dayjs(destructionRqDetails?.preferred_service_date, {
                  strict: true
                }).toDate()
              : null
          );
          sitadForm.setValues({
            ...sitadForm?.values,
            preferredDeliveryDate: destructionRqDetails?.preferred_service_date
              ? dayjs(destructionRqDetails?.preferred_service_date, {
                  strict: true
                }).toDate()
              : null,
            deliveryInstructions: desReqComment[0]?.comment_text,
            serviceLocation: serviceLocationValue?.value
          });
          serviceLocationSelected.current = serviceLocationValue;
          const scheduleServiceLocationValue = triggeredServiceLocations?.find(
            (location) =>
              location?.label.trim() ==
              destructionRqDetails?.service_location.trim()
          );
          scheduleServiceForm.setValues({
            ...scheduleServiceForm?.values,
            scheduleDeliveryDate: destructionRqDetails?.service_date
              ? dayjs(destructionRqDetails?.service_date, {
                  strict: true
                }).toDate()
              : null,
            scheduleDeliveryInstructions:
              destructionRqDetails?.pickup_instruction,
            scheduleServiceLocation:
              destructionRqDetails?.service_location == ""
                ? serviceLocationValue?.value
                : scheduleServiceLocationValue?.value
          });
          setScheduleServiceLocationSelected(
            destructionRqDetails?.service_location == ""
              ? {
                  label: destructionRqDetails?.preferred_service_location,
                  value: serviceLocationValue?.value
                }
              : scheduleServiceLocationValue
          );

          setScheduleDeliveryDateDef(
            destructionRqDetails?.service_date
              ? dayjs(destructionRqDetails?.service_date, {
                  strict: true
                }).toDate()
              : null
          );
          setScheduleServiceModifiedDate(
            destructionRqDetails?.assignment_datetime_ltz !== ""
              ? dayjs(destructionRqDetails?.assignment_datetime_ltz).format(
                  dayMonthYearFormat
                )
              : ""
          );
        } catch (e) {
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
        }
      };
      if (
        destructionRqDetails?.destruct_request_status_id ==
        DestructionStatus.Authorized
      ) {
        setShowScheduleServiceForm(true);
        setFormSubmitted(false);
        setResetButtonEnable(false);
      } else if (
        destructionRqDetails?.destruct_request_status_id ==
        DestructionStatus.Scheduled
      ) {
        setShowScheduleServiceForm(true);
      } else if (
        destructionRqDetails?.destruct_request_status_id ==
          DestructionStatus.Complete ||
        DestructionStatus.InProcess
      ) {
        setShowScheduleServiceForm(true);
      } else if (
        destructionRqDetails?.destruct_request_status_id ==
        DestructionStatus.Cancelled
      ) {
        if (destructionRqDetails?.service_date == "") {
          setShowScheduleServiceForm(false);
        }
      }
      scheduleServiceForm.setFieldTouched("scheduleDeliveryDate", false);
      setSitadModifiedDate(true);
      fetchFormData();
    } else if (newRequestSaved && destructionRqDetails) {
      setPreferredDeliveryDateDef(
        destructionRqDetails?.preferred_service_date
          ? dayjs(
              new Date(destructionRqDetails?.preferred_service_date)
            ).toDate()
          : null
      );

      sitadForm.setValues({
        ...sitadForm?.values,
        preferredDeliveryDate: destructionRqDetails?.preferred_service_date
          ? dayjs(
              new Date(destructionRqDetails?.preferred_service_date)
            ).toDate()
          : null
      });

      setScheduleDeliveryDateDef(
        destructionRqDetails?.service_date
          ? dayjs(destructionRqDetails?.service_date, {
              strict: true
            }).toDate()
          : null
      );

      scheduleServiceForm.setValues({
        ...scheduleServiceForm?.values,
        scheduleDeliveryDate: destructionRqDetails?.service_date
          ? dayjs(destructionRqDetails?.service_date, {
              strict: true
            }).toDate()
          : null
      });
      setScheduleServiceModifiedDate(
        destructionRqDetails?.assignment_datetime_ltz !== ""
          ? dayjs(destructionRqDetails?.assignment_datetime_ltz).format(
              dayMonthYearFormat
            )
          : ""
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destructionRqDetails]);

  //set label dates to states
  const setDates = async () => {
    setAuthorizedDate(
      await formatDateByLocale(
        destructionRqDetails?.requested_datetime_ltz,
        localeByBranch
      )
    );
    setCancelledDate(
      await formatDateByLocale(
        destructionRqDetails?.cancelled_datetime_ltz,
        localeByBranch
      )
    );
    setCompletedDate(
      await formatDateByLocale(
        destructionRqDetails?.completed_datetime_ltz,
        localeByBranch
      )
    );
    setSitadModDate(
      await formatDateByLocale(
        destructionRqDetails?.requested_datetime_ltz,
        localeByBranch
      )
    );
    setScheduleModDate(
      await formatDateByLocale(
        destructionRqDetails?.assignment_datetime_ltz,
        localeByBranch
      )
    );
  };

  //set date labels
  useEffect(() => {
    setDates();
  }, [
    localeByBranch,
    destructionRqDetails?.requested_datetime_ltz,
    destructionRqDetails?.cancelled_datetime_ltz,
    destructionRqDetails?.completed_datetime_ltz,
    destructionRqDetails?.requested_datetime_ltz,
    destructionRqDetails?.assignment_datetime_ltz
  ]);

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="mediaRequestDetailFullPageLoader"
        loading={pageLoading}
      />

      <Box
        id="request_delivery_form_topper_box"
        display="flex"
        flexDirection="column"
        mt={spacing.horizontalMargin20}
      >
        <form
          onSubmit={
            scheduleServiceForm
              ? scheduleServiceForm.handleSubmit
              : sitadForm.handleSubmit
          }
        >
          <Collapse in={throwError}>
            <Alert
              severity="error"
              sx={{ marginBottom: spacing.horizontalMargin20 }}
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>

          <Collapse in={showPopup}>
            <Alert
              severity="warning"
              icon={<img src={WarningIcon} alt="error" />}
              sx={{ marginBottom: spacing.horizontalMargin20 }}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowPopup(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {message[0]?.descr}
            </Alert>
          </Collapse>

          <Box
            id="request_delivery_form_second_top_box"
            display="flex"
            flexDirection="column"
            gap={spacing.horizontalMargin20}
          >
            {/* Customer Form Controls */}
            <Grid
              id="request_delivery_customer_form_controls_main_grid"
              container
              spacing={spacing.horizontalMargin20}
            >
              <Grid
                id="request_delivery_customer_form_controls_second_main_grid_1"
                item
              >
                <CustomersField
                  form={mdrdForm}
                  options={authon ? customersAfterAuth : ["", ...customers]}
                  value={customerDef.current}
                  sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
                  handleOnChange={(e, value) => {
                    mdrdForm.handleChange({
                      target: { name: "customer", value: value?.value }
                    });
                    customerDef.current = value;
                    setShowPopup(false);
                    if (authon) {
                      sitadForm.handleReset();
                      checkRequestCreateAfterAuthOn(value);
                    } else {
                      setAuthFail(false);
                      setAuthorizedByDef("");
                      mdrdForm.setFieldTouched("authorizedBy", false);
                      mdrdForm.setFieldValue(
                        "authorizedBy",
                        mdrdForm.initialValues.authorizedBy
                      );
                      mdrdForm.setFieldValue(
                        "authNumber",
                        mdrdForm.initialValues.authNumber
                      );
                      mdrdForm.setFieldValue(
                        "authorizationChallengeResponse",
                        mdrdForm.initialValues.authorizationChallengeQuestion
                      );
                      mdrdForm.setFieldValue(
                        "authorizationChallengeResponse",
                        mdrdForm.initialValues.authorizationChallengeResponse
                      );
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value &&
                    option?.number === value?.number &&
                    option?.label === value?.label
                  }
                  disabled={Boolean(id)}
                  required
                />
              </Grid>

              <Grid
                id="request_delivery_customer_form_controls_second_main_grid_2"
                item
              >
                <Typography
                  id="request_delivery_7"
                  variant="subtitle1"
                  sx={MediaDestructionRequestDeliveryFormStyles.BranchText}
                >
                  {t("mediaDestructionDelivery.branch")}
                </Typography>
                <Typography id="request_delivery_8">
                  {currentBranch?.name}
                </Typography>
              </Grid>
            </Grid>

            {/* Status Section */}
            {destructionRqDetails && (
              <>
                <Grid
                  id="request_delivery_111"
                  display="flex"
                  flexDirection="row"
                  gap={spacing.verticalMargin20}
                >
                  <Grid
                    id="request_delivery_6"
                    item
                    sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
                  >
                    <Typography
                      id="request_delivery_7"
                      variant="subtitle1"
                      sx={MediaDestructionRequestDeliveryFormStyles.BranchText}
                    >
                      {t("mediaDestructionDelivery.status")}
                    </Typography>
                    <Typography id="request_delivery_8">
                      {destructionRqDetails?.destruct_request_status_descr}
                    </Typography>
                  </Grid>
                  <Grid
                    id="request_delivery_6"
                    item
                    sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
                  >
                    <Typography
                      id="request_delivery_7"
                      variant="subtitle1"
                      sx={MediaDestructionRequestDeliveryFormStyles.BranchText}
                    >
                      {t("mediaDestructionDelivery.authorizedBy")}
                    </Typography>
                    <Typography id="request_delivery_8">
                      {destructionRqDetails?.requested_personnel_name}
                    </Typography>
                  </Grid>
                  <Grid
                    id="request_delivery_6"
                    item
                    sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
                  >
                    <Typography
                      id="request_delivery_7"
                      variant="subtitle1"
                      sx={MediaDestructionRequestDeliveryFormStyles.BranchText}
                    >
                      {t("mediaDestructionDelivery.authorizedDate")}
                    </Typography>
                    <Typography id="request_delivery_8">
                      {authorizedDate}
                    </Typography>
                  </Grid>
                </Grid>

                {destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Cancelled && (
                  <Grid
                    display="flex"
                    flexDirection="row"
                    gap={spacing.verticalMargin20}
                  >
                    <Grid
                      id="request_delivery_104"
                      item
                      sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
                    >
                      <Typography
                        id="request_delivery_105"
                        variant="subtitle1"
                        sx={
                          MediaDestructionRequestDeliveryFormStyles.BranchText
                        }
                      >
                        {t("mediaDestructionDelivery.cancelledDate")}
                      </Typography>
                      <Typography id="request_delivery_106">
                        {cancelledDate}
                      </Typography>
                    </Grid>
                    <Grid
                      id="request_delivery_107"
                      item
                      sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
                    >
                      <Typography
                        id="request_delivery_108"
                        variant="subtitle1"
                        sx={
                          MediaDestructionRequestDeliveryFormStyles.BranchText
                        }
                      >
                        {t("mediaDestructionDelivery.cancelledReason")}
                      </Typography>
                      <Typography id="request_delivery_109">
                        {destructionRqDetails?.destruct_cancel_reason_descr}
                      </Typography>
                    </Grid>
                    <Grid
                      id="request_delivery_124"
                      item
                      sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
                    >
                      <Typography
                        id="request_delivery_125"
                        variant="subtitle1"
                        sx={
                          MediaDestructionRequestDeliveryFormStyles.BranchText
                        }
                      >
                        {t("mediaDestructionDelivery.cancelledBy")}
                      </Typography>
                      <Typography id="request_delivery_126">
                        {destructionRqDetails?.cancelled_personnel_name}
                      </Typography>
                    </Grid>
                    {destructionRqDetails?.service_request_id && (
                      <Grid id="request_delivery_6" item xs={3}>
                        <Typography
                          id="request_delivery_7"
                          variant="subtitle1"
                          sx={
                            MediaDestructionRequestDeliveryFormStyles.BranchText
                          }
                        >
                          {t("mediaDestructionDelivery.otherRequestId")}
                        </Typography>
                        <Typography id="request_delivery_8">
                          {destructionRqDetails?.service_request_id}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
                {(destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Scheduled ||
                  DestructionStatus.Complete ||
                  DestructionStatus.InProcess) && (
                  <>
                    {destructionRqDetails?.service_request_id && (
                      <Grid
                        display={"flex"}
                        flexDirection={"row"}
                        gap={spacing.horizontalMargin20}
                      >
                        <Grid
                          display="flex"
                          flexDirection={"column"}
                          sx={
                            MediaDestructionRequestDeliveryFormStyles.FieldSize
                          }
                        >
                          <Typography
                            id="request_delivery_7"
                            variant="subtitle1"
                            sx={
                              MediaDestructionRequestDeliveryFormStyles.BranchText
                            }
                          >
                            {t("mediaDestructionDelivery.otherRequestId")}
                          </Typography>
                          <Typography id="request_delivery_8">
                            {destructionRqDetails?.service_request_id}
                          </Typography>
                        </Grid>

                        {destructionRqDetails?.destruct_request_status_id ==
                          DestructionStatus.Complete &&
                          destructionRqDetails?.completed_datetime_ltz && (
                            <Grid
                              display="flex"
                              flexDirection={"column"}
                              sx={
                                MediaDestructionRequestDeliveryFormStyles.FieldSize
                              }
                            >
                              <Typography
                                id="request_delivery_7"
                                variant="subtitle1"
                                sx={
                                  MediaDestructionRequestDeliveryFormStyles.BranchText
                                }
                              >
                                {t("mediaDestructionDelivery.completedDate")}
                              </Typography>
                              <Typography id="request_delivery_8">
                                {completedDate}
                              </Typography>
                            </Grid>
                          )}
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}

            {/* Authorization Form Controls */}
            <Grid
              id="request_delivery_9"
              container
              display={"flex"}
              flexDirection={"column"}
              gap={spacing.verticalMargin20}
            >
              {/*Authorization title*/}
              <Typography id="request_delivery_10" variant="h5">
                {t("mediaDestructionDelivery.personnelAuthentication")}
              </Typography>
              {/*  */}

              <Grid
                id="request_delivery_11"
                display="flex"
                flexDirection="row"
                gap={spacing.verticalMargin20}
                alignItems="center"
              >
                {/* Authorized By field */}
                <Grid id="request_delivery_12" item>
                  <Autocomplete
                    id="request_delivery_13"
                    disablePortal
                    size="medium"
                    value={authorizedByDef}
                    clearIcon={<ClosedIcon />}
                    popupIcon={
                      Boolean(!mdrdForm.values.customer) ||
                      Boolean(id) ||
                      Boolean(disablePersonnel) ||
                      Boolean(authon) ? (
                        <ArrowDownDisabled />
                      ) : (
                        <ArrowDown />
                      )
                    }
                    sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
                    options={[
                      authorizedByDef,
                      ...(authorizationPersonnels?.filter(
                        (authPersonnel) =>
                          authPersonnel?.value !== authorizedByDef?.value
                      ) || [])
                    ]}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        id="request_delivery_14"
                        {...params}
                        name="authorizedBy"
                        label={t("mediaDestructionDelivery.personnelLabel")}
                        value={mdrdForm.values?.authorizedBy}
                        error={
                          mdrdForm.touched?.authorizedBy &&
                          Boolean(mdrdForm.errors?.authorizedBy)
                        }
                        onBlur={mdrdForm.handleBlur}
                        helperText={
                          mdrdForm.touched?.authorizedBy &&
                          mdrdForm.errors?.authorizedBy
                        }
                        required
                      />
                    )}
                    onChange={handlePersonnelChange}
                    disabled={
                      Boolean(!mdrdForm.values.customer) ||
                      Boolean(id) ||
                      Boolean(disablePersonnel) ||
                      Boolean(authon)
                    }
                  />
                </Grid>

                {showPersonnelAuthorizeError && (
                  <Grid
                    id="request_delivery_customer_form_controls_second_main_grid_2"
                    item
                  >
                    <Alert id="request_delivery_122" severity="error">
                      {t("mediaDestructionDelivery.insufficientAuthorize")}
                    </Alert>
                  </Grid>
                )}

                {/* Is Authenticated Label */}
                {showAuthText && (
                  <Grid id="request_delivery_15" item>
                    <Box
                      id="request_delivery_16"
                      variant="subtitle1"
                      sx={
                        MediaDestructionRequestDeliveryFormStyles.AuthenticatedText
                      }
                      display="flex"
                      alignItems={"center"}
                      gap="0.75em"
                    >
                      <CheckIcon id="request_delivery_17" />
                      <Typography id="request_delivery_18" variant="subtitle1">
                        {t("mediaDestructionDelivery.authenticated")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>

              {mdrdForm.values?.authorizedBy &&
                !showPersonnelAuthorizeError &&
                (authon ? (
                  <Box
                    id="request_delivery_27"
                    display="flex"
                    flexDirection="column"
                    gap={spacing.verticalMargin20}
                  >
                    {/* Create SITAD Delivery Section  */}
                    <Divider id="request_delivery_28" />

                    <SitadDeliveryForm
                      sitadModifiedDate={sitadModifiedDate}
                      destructionRqDetails={destructionRqDetails}
                      trackingRequirement={trackingRequirement}
                      sitadForm={sitadForm}
                      mdrdForm={mdrdForm}
                      preferredDeliveryDateDef={preferredDeliveryDateDef}
                      showScheduleServiceForm={showScheduleServiceForm}
                      serviceLocationSelected={serviceLocationSelected}
                      serviceLocation={serviceLocation}
                      handleServiceLocationChange={handleServiceLocationChange}
                      minDate={minDate}
                      maxDate={maxDate}
                      localeByBranch={localeByBranch}
                      sitadFormDisable={sitadFormDisable}
                      sitadModDate={sitadModDate}
                    />
                    {/* Schedule Service Section  */}

                    {showScheduleServiceForm && (
                      <>
                        <Divider id="request_delivery_30" />
                        <ScheduleServiceForm
                          scheduleServiceForm={scheduleServiceForm}
                          destructionRqDetails={destructionRqDetails}
                          scheduleServiceModifiedDate={
                            scheduleServiceModifiedDate
                          }
                          serviceLocation={serviceLocation}
                          scheduleTrackingRequirement={
                            scheduleTrackingRequirement
                          }
                          minDate={minDate}
                          maxDate={maxDate}
                          formSubmitted={formSubmitted}
                          scheduleFormFields={scheduleFormFields}
                          scheduleDeliveryDateDef={scheduleDeliveryDateDef}
                          scheduleServiceLocationSelected={
                            scheduleServiceLocationSelected
                          }
                          handleScheduleServiceLocationChange={
                            handleScheduleServiceLocationChange
                          }
                          localeByBranch={localeByBranch}
                          scheduleModDate={scheduleModDate}
                        />
                      </>
                    )}
                  </Box>
                ) : (
                  <>
                    {/* Authorization form fields*/}
                    <AuthorizationFormFields
                      byPass={byPass}
                      mdrdForm={mdrdForm}
                      authInfo={authInfo}
                      personnelAuthrizationData={personnelAuthrizationData}
                      handleAuthenticate={handleAuthenticate}
                      authFail={authFail}
                    />
                  </>
                ))}
            </Grid>

            {/* Bottom Action Buttons */}
            <Divider id="request_delivery_28" />
          </Box>
          <FormActionButtons
            handleSaveClick={handleSaveClick}
            destructionRqDetails={destructionRqDetails}
            saveEnable={saveEnable}
            customerDataById={customerDataById}
            pageInitialButtonDisable={pageInitialButtonDisable}
            isButtonDisabled={isButtonDisabled}
            newEnable={newEnable}
            handleNewClick={handleNewClick}
            pageStatus={pageStatus}
            handleResetClick={handleResetClick}
            cancelEnable={cancelEnable}
            handleCancelClick={handleCancelClick}
            handleOpenPrintModal={handleOpenPrintModal}
            destructionId={destructionId}
            resetButtonEnable={resetButtonEnable}
            cancelDisable={cancelDisable}
          />
          <ModalPopup
            title="Confirm"
            modalPopupOpen={openWarningModal}
            handleClick={handleCloseWarningModal}
            alertMessage={warningModalText}
            modalButton={[
              <Button
                variant="outlined"
                onClick={handleCloseWarningModal}
                key={t("mediaDestructionDelivery.close")}
              >
                {t("mediaDestructionDelivery.close")}
              </Button>,
              <Button
                variant="contained"
                onClick={handleYes}
                key={t("mediaDestructionDelivery.yes")}
              >
                {t("mediaDestructionDelivery.yes")}
              </Button>
            ]}
          />

          <DownloadReportModal
            isVisible={openPrintModal}
            setIsVisible={handleClosePrintModal}
            mainDistrictId={currentBranch?.district_id}
            districtName={currentBranch?.name}
            setIsLoading={setPageLoading}
            customerId={destructionRqDetails?.customer_id}
            destructRequestId={destructionId}
            throwError={throwError}
            setThrowError={setThrowError}
            throwErrorMessage={throwErrorMessage}
            setThrowErrorMessage={setThrowErrorMessage}
          />

          <CancelAuthenticationModal
            authorizationPersonnels={authorizationPersonnels}
            authorizedByDef={authorizedByDef}
            open={openCancelAuthModal}
            handleClose={handleCloseCancelAuthModal}
            personnelAuthrizationData={personnelAuthrizationData}
            setAuthorizedByDef={setAuthorizedByDef}
            mdrdForm={mdrdForm}
            destructionId={destructionId}
            fetchDestructionDetails={fetchDestructionDetails}
            setShowScheduleServiceForm={setShowScheduleServiceForm}
            authInfo={authInfo}
            setThrowErrorMessage={setThrowErrorMessage}
            setThrowError={setThrowError}
            findErrorMessage={findErrorMessage}
            auth={auth}
            authWarningAlert={authWarningAlert}
            setAuthWarningAlert={setAuthWarningAlert}
          />
        </form>
      </Box>
    </>
  );
};

export default MediaDestructionRequestDeliveryForm;
