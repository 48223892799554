import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData } from "services/api/query";
import { DISTRICT_ID_GET_ALL_LOCATIONS } from "utils/constants";

import {
  getAuthenticatedUserBranch,
  getResponseData,
  printReport
} from "utils/helpers";

// Get all Branchs
//sp Name: sb_fs_employee_branch_authorization_sp
export const getAllBranchs = async (mainDistrictId, employee_id) => {
  let reqBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,

    employee_id: employee_id
  };

  let res = await getResponseData(
    reqBody,
    CF_URLS.customerPreferences.accessingEmployeeDistricts,
    4
  );
  return res;
};

// get the preference table data
// Sp Name: sb_fs_customer_preference_l_sp

export const getPreferenceTabValues = async (mainDistrictId, customer_id) => {
  let reqBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    customer_id: customer_id
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.customerPreferences.getPreferenceValues,
    4
  );
  return res;
};

// get the vaultState grid Data
// Sp Name : sb_fs_personnel_l7_sp

export const getVaultsStatusTabData = async (mainDistrictId, customer_id) => {
  let reqBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    customer_id: customer_id
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.customerPreferences.personnellist,
    4
  );
  return res;
};

// sp Name: sb_fs_vault_status_report_save_sp

export const updateValutStatusReports = async (
  mainDistrictId,
  customer_id,
  vault_status_email,
  email_only_if_exceptions_flag,
  timeStamp,
  xmlPayload
) => {
  let reqBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    customer_id: customer_id,

    vault_status_email: vault_status_email,
    email_only_if_exceptions_flag: email_only_if_exceptions_flag,
    ts: timeStamp,
    vault_status_personnel_info_xml: xmlPayload
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.customerPreferences.updatevalutstatusreportpreferences,
    4
  );
  return res;
};

// get the all customers
export const getCustomers = async () => {
  const districtId = getAuthenticatedUserBranch();
  let result = await getCustomersQueryData(districtId);
  if (result) {
    const data = result?.map(({ number, name, customer_id }) => ({
      label: `${number} - ${name.trim()}`,
      value: customer_id,
      number: number
    }));
    return data;
  }
};

// sp Name : sb_fs_customer_preference_history_l_sp

export const getCustomerPreferenceHistory = async (
  mainDistrictId,
  customer_id,
  preference_type_id_list
) => {
  let reqBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    customer_id: customer_id,
    preference_type_id_list: preference_type_id_list
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.customerPreferences.getCustomerPreferenceHistory,
    4
  );
  return res;
};

// SP Name : sb_fs_customer_preference_update_sp

export const updateCustomerPreferences = async (
  mainDistrictId,
  customer_id,
  system_id,
  employee_id,
  preferences_xml,
  personnel_id,
  auth_bypass_reason
) => {
  let reqBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    customer_id: customer_id,
    employee_id: employee_id,
    personnel_id: personnel_id,
    system_id: system_id,
    preferences_xml: preferences_xml,
    auth_bypass_reason: ""
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.customerPreferences.updateCustomerPreferences,
    4
  );
  return res;
};

// customerpreferencesreport download  reports
// sp name: sb_fs_rp_customer_preference_l2_sp
// sp name: sb_fs_rp_personnel_l7_sp

export const customerPreferencesReport = async (payload) => {
  let reqBody = payload;
  let reqBodyString = JSON.stringify(reqBody);
  let res = await printReport(
    reqBodyString,
    CF_URLS.printReports.customerInformation.customerPreferencesReport
  );
  return res;
};
