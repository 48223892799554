import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  SvgIcon,
  Typography
} from "@mui/material";
import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  CUSTOMER_FILE_SEARCH,
  DISCREPANCY_MANAGEMENT_DETAIL,
  OPEN_MEDIA_AUDIT_DETAIL,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH
} from "routing/Paths";
import { selectAuth } from "store/slices";
import {
  setError,
  setType,
  setUpdateSelectedList
} from "store/slices/discrepancy-search";
import {
  dateFormatWithoutTime,
  dateFormatWithTimeWithSeconds,
  DEFAULT_DATA_TABLE_OPTIONS,
  N_CHECKER,
  numberConstants
} from "utils/constants";
import {
  changePage,
  descrepancyTypeTitle,
  fileSectionTypeIds,
  mediaTypes,
  mediaTypesTitle,
  openMeidaDiscrepancyTypes,
  resolutionTypes,
  suggestionTypeTitle
} from "utils/constants/discrepancy-management/DiscrepancySearchConstants";
import { DiscrepencyStyles } from "./discrepancyStyles";

const OpenMediaTable = ({
  tableData,
  setSelectedId,
  setSelectedRow,
  handleNavigationClick,
  selectedRow,
  isResetGrid,
  selectedContainerRow,
  setSelectedContainerRow,
  isOpenMediaSelected,
  setIsOpenMediaSelected,
  hasPermission327,
  hasUpdatePermission
}) => {
  const { localeByBranch } = useSelector(selectAuth);
  const { isAuthenticated } = useSelector((state) => state.discrepancyUpdate);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(null);
  const [selectedCheckedList, setSelectedCheckedList] = useState([]);
  const [selectedAllRows, setSelectedAllRows] = useState(null);
  const [omPageNo, setOmPageNo] = useState(0);
  const dispatch = useDispatch();
  const handleRowSelect = (event, tableMeta, rowData) => {
    const customerId = rowData.customerId;
    if (event.target.checked) {
      setSelectedAllRows(true);
      setSelectedCheckedList((prev) => [
        ...prev,
        rowData.openMediaDiscrepancyId
      ]);
      if (!selectedCustomerId || selectedCustomerId === customerId) {
        setSelectedCustomerId(customerId);
        setSelectedRow((prevRows) => {
          return Array.isArray(prevRows) ? [...prevRows, rowData] : [rowData];
        });
      }
      let uId =
        rowData?.customerId?.trim() +
        rowData?.discrepancyTypeCode?.trim() +
        rowData?.requestId?.trim();
      setIsDisabled(uId);
    } else {
      const updatedRows = selectedRow.filter(
        (row) => row.openMediaDiscrepancyId !== rowData.openMediaDiscrepancyId
      );
      setSelectedRow(updatedRows);
      let listItem = selectedCheckedList?.filter(
        (item) => item !== rowData.openMediaDiscrepancyId
      );
      setSelectedCheckedList([...listItem]);
      if (!listItem?.length) {
        setIsDisabled(null);
        setSelectedAllRows(false);
      }
      if (!updatedRows.some((row) => row.customer_id === customerId)) {
        setSelectedCustomerId(null);
      }
    }
  };
  useEffect(() => {
    if (selectedRow?.length === 0) {
      setSelectedCheckedList([]);
      setSelectedAllRows(false);
      setIsDisabled(null);
      setOmPageNo(0);
    }
  }, [selectedRow]);
  const handleRowClick = (value, tableMeta) => {
    setSelectedId(value?.id);
    setSelectedRow(mappedData[tableMeta?.rowIndex]);
    let type = t("discrepancyManagement.openMedia");
    let path = `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT_DETAIL}`;
    dispatch(setUpdateSelectedList([{ ...mappedData[tableMeta?.rowIndex] }]));
    dispatch(setType(type));
    handleNavigationClick({ value, path, type });
  };

  const convertDatebyLocale = (date, format) => {
    import(`dayjs/locale/${localeByBranch}.js`);
    let selectedFormat = format ? format : dateFormatWithoutTime;
    const formattedDate = date
      ? dayjs.utc(date).locale(localeByBranch).format(selectedFormat)
      : "";

    return formattedDate;
  };

  const mappedData =
    tableData &&
    tableData
      ?.map((row) => {
        let detectedValue = fileSectionTypeIds[row.file_section_type_id];

        if (row.file_section_type_id === numberConstants.sixteen) {
          detectedValue += ` #${row.om_audit_id}`;
        } else detectedValue += ` ${row.detected_in_datetime}`;

        return {
          fileSectionTypeId: row.file_section_type_id,
          detectedInDateTime: row.detected_in_datetime,
          omAuditId: row.om_audit_id,
          detectedValue: detectedValue,
          customerNumber: row.customer_number,
          mediaNumber: row.volser_number,
          discrepancyType:
            row.discrepancy_type_code &&
            openMeidaDiscrepancyTypes[
              descrepancyTypeTitle +
                row.discrepancy_type_code?.replace(/\s+/g, "") +
                row.file_section_type_id
            ],
          customerName: row.customer_name,
          lastModDate: row.last_mod_date,
          logicalVaultCode: row.logical_vault_code,
          doNotCallPrefValue: row.do_not_call_pref_value,
          districtId: row.district_id,
          lastKnownLocation: row.last_known_location,
          detectedDatetime: row.detected_datetime,
          discrepancyTypeCode: row.discrepancy_type_code,
          customerFileSectionId: row.customer_file_section_id,
          carId: row.car_id,
          resolvedDatetime: row.resolved_datetime,
          mediaTypeId: row.media_type_id,
          mediaType:
            row.media_type_id &&
            mediaTypes[mediaTypesTitle + row.media_type_id],
          discrepancyTypeContext: row.discrepancy_type_context,
          openMediaDiscrepancyId: row.open_media_discrepancy_id,
          volserNumber: row.volser_number,
          requestId: row.request_id,
          resolvedFlag: row.resolved_flag,
          suggestionTypeId: row.suggestion_type_id,
          requiresCourtesyCallFlag: row.requires_courtesy_call_flag,
          displayToCustomerDatetime: row.display_to_customer_datetime,
          suggestionTypeCode: row.suggestion_type_code,
          originalSystemFunctionId: row.original_system_function_id,
          carCustomerDisplayFlag: row.car_customer_display_flag,
          logicalVaultId: row.logical_vault_id,
          openMediaId: row.open_media_id,
          resolvedById: row.resolved_by_id,
          customerId: row.customer_id,
          detectedInContext: row.detected_in_context,
          resolvedByName: row.resolved_by_name,
          resolutionType:
            row.suggestion_type_code &&
            resolutionTypes[
              suggestionTypeTitle +
                row.suggestion_type_code?.replace(/\s+/g, "").toLowerCase()
            ]
        };
      })
      ?.sort((a, b) => {
        if (a.customerNumber < b.customerNumber) return -1;
        if (a.customerNumber > b.customerNumber) return 1;
        if (a.discrepancyType && b.discrepancyType) {
          return a.discrepancyType.localeCompare(b.discrepancyType);
        } else {
          return 0;
        }
      });

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: tableData?.length > 0,
    responsive: "standard",
    overflowX: "auto",
    sortOrder: {
      name: "name",
      direction: "asc"
    },
    onTableChange: (action, tableState) => {
      if (action === changePage) {
        setOmPageNo(tableState.page);
      }
    },
    page: omPageNo,
    textLabels: {
      body: {
        noMatch:
          isResetGrid && tableData.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  const columns = [
    {
      name: "",
      label: "",
      options: {
        customHeadRender: (columnMeta, updateValue) => {
          return (
            <Box sx={DiscrepencyStyles.checkHeaderStyles}>
              <Checkbox
                checked={selectedAllRows}
                disabled={
                  selectedContainerRow?.length > 0 || mappedData?.length === 0
                }
                onChange={(event) => {
                  if (event.target.checked) {
                    // Check if all rows have the same discrepancyTypeCode, requestId, and customerId
                    const allSame =
                      mappedData?.length > 0 &&
                      mappedData?.every((row) => {
                        return (
                          row.discrepancyTypeCode ===
                            mappedData[0]?.discrepancyTypeCode &&
                          row.requestId === mappedData[0].requestId &&
                          row.customerId === mappedData[0].customerId
                        );
                      });

                    if (allSame) {
                      setSelectedAllRows(true);
                      setSelectedRow(mappedData);
                      setIsDisabled(mappedData[0]?.customerNumber);
                      const newCheckedList = mappedData.map(
                        (row) => row.openMediaDiscrepancyId
                      );
                      setSelectedCheckedList((prev) => [
                        ...prev,
                        ...newCheckedList
                      ]);
                    } else {
                      const errMsg = t(
                        "discrepancyManagement.selectAllErrorMsg"
                      );
                      setSelectedRow([]);
                      setSelectedAllRows(false);
                      setSelectedCheckedList([]);
                      dispatch(setError(errMsg));
                      setIsDisabled(null);
                    }
                  } else {
                    setSelectedRow([]);
                    setSelectedCheckedList([]);
                    setSelectedAllRows(false);
                    setIsDisabled(null);
                  }
                }}
              />
            </Box>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          let row = tableMeta?.currentTableData[tableMeta.rowIndex];
          let dataIndex = row.index;
          let rowData = mappedData[dataIndex];
          let uId =
            rowData?.customerId?.trim() +
            rowData?.discrepancyTypeCode?.trim() +
            rowData?.requestId?.trim();
          return (
            <>
              {(rowData.resolvedFlag === N_CHECKER ||
                (isAuthenticated && !hasUpdatePermission) ||
                !hasPermission327) && (
                <Checkbox
                  checked={selectedCheckedList.includes(
                    rowData.openMediaDiscrepancyId
                  )}
                  onChange={(event) =>
                    handleRowSelect(event, tableMeta, rowData)
                  }
                  disabled={
                    selectedContainerRow?.length > 0
                      ? true
                      : selectedCheckedList.includes(
                            rowData.openMediaDiscrepancyId
                          )
                        ? false
                        : isDisabled
                          ? !(isDisabled === uId)
                          : false
                  }
                />
              )}
            </>
          );
        }
      }
    },
    {
      name: "",
      label: "",

      options: {
        sort: false,
        setCellProps: () => ({
          style: DiscrepencyStyles.actionColumnStyle
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = mappedData[tableMeta.rowIndex];

          return (
            <>
              <Button
                onClick={() => {
                  handleRowClick(rowData, tableMeta);
                }}
              >
                <SvgIcon component={ViewIcon} />
              </Button>
              {value}
            </>
          );
        }
      }
    },
    {
      name: "customerNumber",
      label: t("common.customer"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.actionColumnStyle
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value?.trim() ? value : t("common.unknown")}</>;
        }
      }
    },
    {
      name: "mediaNumber",
      label: t("discrepancyManagement.media#"),
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta) => {
          const rowData = mappedData[tableMeta.rowIndex];
          return (
            <Link
              onClick={() => {
                let type = t("discrepancyManagement.openMedia");
                let path = `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT_DETAIL}`;
                handleNavigationClick({ value: rowData, path, type });
              }}
            >
              {value}
            </Link>
          );
        },

        setCellProps: (value) => ({
          style: {
            ...DiscrepencyStyles.linkStyles,
            ...DiscrepencyStyles.columnStylesm
          }
        })
      }
    },

    {
      name: "discrepancyType",
      label: t("discrepancyManagement.discrepancyType"),
      options: {
        sortThirdClickReset: true,
        setCellProps: (value) => ({
          style:
            value?.trim() === t("discrepancyManagement.missing") ||
            value?.trim() === t("discrepancyManagement.notReceived") ||
            value?.trim() === t("discrepancyManagement.notAtIronMountain")
              ? {
                  ...DiscrepencyStyles.colorStyle,
                  ...DiscrepencyStyles.columnStyleLg
                }
              : DiscrepencyStyles.columnStyleLg
        })
      }
    },
    {
      name: "detectedValue",
      label: t("discrepancyManagement.detectedIn"),
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta) => {
          const rowData = mappedData[tableMeta.rowIndex];
          const [text, datePart] = (value ?? "").split(" ");

          let date = null;
          if (!text.includes(t("discrepancyManagement.audit"))) {
            date = dayjs(datePart);
          }
          const isValidDate = date ? date.isValid() : false;

          const convertedDate = isValidDate
            ? convertDatebyLocale(datePart)
            : datePart;

          const id = isValidDate ? selectedCustomerId : rowData?.omAuditId;

          const validLink =
            rowData?.fileSectionTypeId === numberConstants.one ||
            rowData?.fileSectionTypeId === numberConstants.two ||
            rowData?.fileSectionTypeId === numberConstants.four
              ? rowData?.customerFileSectionId > numberConstants.zero
                ? CUSTOMER_FILE_SEARCH
                : null
              : rowData?.fileSectionTypeId === numberConstants.sixteen
                ? OPEN_MEDIA_AUDIT_DETAIL
                : null;

          const path = `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${validLink}`;
          return (
            <>
              {validLink ? (
                <Link
                  onClick={() => {
                    handleNavigationClick({ id, path, value: rowData });
                  }}
                >
                  {text} {convertedDate}
                </Link>
              ) : (
                <>
                  {text} {convertedDate}
                </>
              )}
            </>
          );
        },
        setCellProps: (value) => ({
          style: {
            ...DiscrepencyStyles.linkStyles,
            ...DiscrepencyStyles.columnStyle
          }
        })
      }
    },
    {
      name: "detectedDatetime",
      label: t("discrepancyManagement.detectedDate"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        }),
        customBodyRender: (value) =>
          convertDatebyLocale(value, dateFormatWithTimeWithSeconds)
      }
    },
    {
      name: "resolutionType",
      label: t("discrepancyManagement.resolution"),
      options: {
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        })
      }
    },

    {
      name: "carId",
      label: t("discrepancyManagement.carId"),
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Link
              onClick={() => {
                const carId = value; // get id
                let path = `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`;
                handleNavigationClick({ carId, path });
              }}
            >
              {value}
            </Link>
          );
        },
        setCellProps: (value) => ({
          style: {
            ...DiscrepencyStyles.linkStyles,
            ...DiscrepencyStyles.columnStylesm
          }
        })
      }
    },
    {
      name: "logicalVaultCode",
      label: t("discrepancyManagement.logicalVault"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        })
      }
    },
    {
      name: "mediaTypeId",
      label: t("discrepancyManagement.mediaType"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        })
      }
    },
    {
      name: "requestId",
      label: t("discrepancyManagement.requestID"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        })
      }
    },
    {
      name: "displayToCustomerDatetime",
      label: t("discrepancyManagement.displayToCustomerDate"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyleLg
        }),
        customBodyRender: (value) =>
          convertDatebyLocale(value, dateFormatWithoutTime)
      }
    },
    {
      name: "resolvedDatetime",
      label: t("discrepancyManagement.resolvedDate"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        }),
        customBodyRender: (value) =>
          convertDatebyLocale(value, dateFormatWithoutTime)
      }
    },
    {
      name: "resolvedByName",
      label: t("discrepancyManagement.resolvedBy"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        })
      }
    },
    {
      name: "lastKnownLocation",
      label: t("discrepancyManagement.lastKnownLocation"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyleLg
        })
      }
    }
  ];

  return (
    <Grid item xs={12} lg={3} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={tableData?.length > 0 ? mappedData : []}
        options={options}
        title={
          isResetGrid ? (
            <Typography style={DiscrepencyStyles.titleStyle}>
              {tableData?.length} {t("common.recordsFound")}
            </Typography>
          ) : (
            ""
          )
        }
      />
    </Grid>
  );
};

export default OpenMediaTable;
