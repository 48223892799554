import { getDocs, query } from "firebase/firestore";
import {
  billing_cycle_collection,
  commission_type_collection
} from "services/firebase";
import { TAG_TYPES } from "utils/constants";
import { baseApi } from "../baseApi";

export const commonBillingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBillCycle: builder.query({
      queryFn: async () => {
        let result = [];
        const q = query(billing_cycle_collection);
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          result.push({ id: doc?.id, ...doc?.data() });
        });

        // Transform the data for the API response
        const response = result.map(
          ({ billing_cycle_code, descr, invoice_date_type_id }) => ({
            value: billing_cycle_code,
            label: descr,
            invoiceDateTypeId: invoice_date_type_id
          })
        );

        return { data: response };
      },
      providesTags: [TAG_TYPES.billingCycle]
    }),
    getAllCommissionType: builder.query({
      queryFn: async () => {
        let result = [];
        const q = query(commission_type_collection);
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          result.push({ id: doc?.id, ...doc?.data() });
        });

        // Transform the data for the API response
        const response = result.map(({ commission_type_id, descr }) => ({
          value: commission_type_id,
          label: descr
        }));

        return { data: response };
      },
      providesTags: [TAG_TYPES.commissionType]
    })
  })
});
