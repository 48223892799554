import styled from "@emotion/styled";
import { Tooltip, tooltipClasses } from "@mui/material";
import { MDRPickupFormStyles } from "../MediaDestructionRequestPickup.style";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    ...MDRPickupFormStyles.arrow
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ...MDRPickupFormStyles.cutomTooltipStyle
  }
}));

export default CustomTooltip;
