export const labelPrinterSetup = {
  descrptionCharacterLimit: 250,
  printerNameLimit: 40,
  ipAddressCheck: "0.0.0.0",
  ipAddressNumberCheck: 255,
  zero: 0,
  darknessAdjustment: 15,
  topOffsetAdjustments: 64,
  Yes: "Y",
  No: "N",
  dots: 8,
  regex: {
    regexIp: /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/,
    applyPrinterAdjustmentsPatten: /\^LH(\d+),(\d+)/
  },
  env: {
    dev1: "dev1",
    dev2: "dev2",
    qa1: "qa1",
    qa2: "qa2",
    prod: "prod",
    uat: "nonprod"
  },
  logicalNameEnv: {
    dev1: "DV1",
    dev2: "DV2",
    qa1: "QA1",
    qa2: "QA2",
    prod: "",
    uat: "PP1"
  },
  maxOffset: 100,
  maxDarknes: 15,
  offsetMax: 99.99,
  minOffset: -100,
  minDarknes: -15,
  offsetmin: -99.99
};
