export const logicalVaultConstants = {
  valueType3: "3",
  valueType5: "5",
  valueType4: "4",
  valueI: "I",
  valueC: "C",
  valueN: "N",
  noValue: "N",
  yesValue: "Y",
  valueVR: "VR",
  valueRI: "RI",
  defaultCapacityValue: 90,
  capacityMinLength: 50,
  capacityMaxLength: 99,
  catalogTypeHourly: "H",
  valueZero: "0",
  valueOne: "1",
  valueZeroDecimal: "0.00"
};

export const none = "none";
export const reg = /[0-9]/;
