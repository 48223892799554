import { Alert, Box, Button, Divider, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CircularLoaderFullPage, SelectField } from "components/core";
import { NonEditableField } from "components/shared";
import CommonModal from "components/shared/common-modal/CommonModal";
import { callToCloudFunction } from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  corporateAccessLevelDistrictId,
  errorMsgs,
  jobName,
  jobType,
  printType
} from "utils/constants";
import { containerLabelPrintersFilterKey } from "utils/constants/container-processing/Research";
import { labelPrinterSetup } from "utils/constants/label-printer-setup/LabelPrinterSetupConstant";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getAuthenticatedUserId,
  getAuthenticatedUserName,
  getResponseData,
  selectEnv
} from "utils/helpers";
import { containerResearchStyles } from "../research/ResearchStyle";

const RePrintLabelModal = (props) => {
  const { t } = useTranslation();
  const { currentBranch } = useSelector(selectAuth);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "warning"
  });
  const [pageLoading, setPageLoading] = useState(false); // page loading state
  const [printers, setPrinters] = useState([]); // printer options
  const [selectedPrinter, setSelectedPrinter] = useState(null); // selected printer state
  const [selectedPrinterInfo, setSelectedPrinterInfo] = useState(null); // selected printer details
  const [labelFormatData, setLabelFormatData] = useState({}); // label format data

  const getLabelFormatFromSP = async (value) => {
    setPageLoading((prevState) => true);

    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_format_id: "",
        label_type_code: value
      };

      const labelFormatGetData = await getResponseData(
        reqBody,
        `${CF_URLS.printTapeLabel.getLabelFormat}`,
        1
      );
      setLabelFormatData(labelFormatGetData.data[0][0]);
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        severity: "warning",
        message: findErrorMessage(ERROR_TYPES.ISSUE)
      }));
    } finally {
      setPageLoading((prevState) => false);
    }
  };

  // printer dropdown onChange event handler
  const handlePrinterOnChange = (e) => {
    setAlert((prevState) => ({
      show: false,
      severity: "warning",
      message: ""
    }));
    let printData = null;
    if (e.target.value) {
      const printer = printers?.find(
        (item) => Number(item?.value) === Number(e.target.value)
      );
      if (printer) {
        printData = printer;
      }
    }
    setSelectedPrinterInfo((prev) => printData);
    getLabelFormatFromSP(printData?.label_type_code);
    setSelectedPrinter((prevState) => e.target.value);
  };

  const applyPrinterAdjustments = (testLabelZpl) => {
    const leftOffSetDots = Math.floor(
      selectedPrinterInfo?.left_offset_mm * labelPrinterSetup.dots
    );
    const topOffSetDots = Math.floor(
      selectedPrinterInfo?.top_offset_mm * labelPrinterSetup.dots
    );
    const pattern = labelPrinterSetup.regex.applyPrinterAdjustmentsPatten;
    const match = pattern.exec(testLabelZpl);

    if (match) {
      const xHome = Math.max(parseInt(match[1]) + leftOffSetDots, 0);
      const yHome = Math.max(parseInt(match[2]), 0);
      const newZpl = testLabelZpl.replace(pattern, `^LH${xHome},${yHome}`);
      let darknessAdjustment = 0;
      if (selectedPrinterInfo?.darkness_adjustment > 0) {
        darknessAdjustment = Math.min(
          selectedPrinterInfo?.darkness_adjustment,
          labelPrinterSetup.darknessAdjustment
        );
      } else {
        darknessAdjustment = Math.max(
          selectedPrinterInfo?.darkness_adjustment,
          -labelPrinterSetup.darknessAdjustment
        );
      }
      let topAdjustment = 0;
      if (topOffSetDots > 0) {
        topAdjustment = Math.min(
          topOffSetDots,
          labelPrinterSetup.topOffsetAdjustments
        );
      } else {
        topAdjustment = Math.max(
          topOffSetDots,
          -labelPrinterSetup.topOffsetAdjustments
        );
      }
      const sbFormat = `^XA^MD${darknessAdjustment}^LT${topAdjustment}`;
      return newZpl.replace("^XA", sbFormat);
    }

    return testLabelZpl;
  };

  const auditInfoCreation = async (
    labelPrinterId,
    customerNumber,
    mediaType,
    containerNumber
  ) => {
    let auditInfo = t("continerIssurance.auditMsg");

    auditInfo = auditInfo.replace(/\{0\}/g, labelPrinterId);
    auditInfo = auditInfo.replace(/\{1\}/g, customerNumber);
    auditInfo = auditInfo.replace(/\{2\}/g, mediaType);
    auditInfo = auditInfo.replace(/\{3\}/g, containerNumber);

    return auditInfo;
  };

  const sendDataToPrinter = async (adjustedLabel) => {
    try {
      const districtId = getAuthenticatedUserBranch();
      const printerId = `sb${selectEnv()}-${
        selectedPrinterInfo?.label_printer_id
      }-${districtId}`;
      const stringToEncode = adjustedLabel;
      const encodedString = btoa(stringToEncode);
      const reqBody = {
        printerID: printerId,
        printType: printType,
        printData: encodedString,
        jobType: jobType,
        jobName: jobName
      };
      const response = await callToCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.cloudPrint}`
      );
      if (response.data.isSuccess) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        severity: "warning",
        message: findErrorMessage(ERROR_TYPES.ISSUE)
      }));
    }
  };

  // record an audit
  const executeAudit = async (body) => {
    try {
      const auditData = await getResponseData(
        body,
        `${CF_URLS.containerIssurance?.labelPrintAudit}`,
        1
      );
      const response = auditData?.data[0]; // get error code

      // if there is an error showing the error and exit from the process
      if (response[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getMessageFromCode(response[0]?.error);

        setAlert((prevState) => ({
          show: true,
          severity: "warning",
          message: errMsg[0]?.descr
        }));

        return;
      }
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        severity: "warning",
        message: findErrorMessage(ERROR_TYPES.ISSUE)
      }));
    }
  };

  const getContainerLabelZpl = (
    zpl,
    customerLabel_id,
    media_type,
    container_number,
    customerMediaIdentifier_id
  ) => {
    const paddedCustomerNumber = customerLabel_id.padStart(6, "0");
    const paddedContainerNumber = container_number.padStart(6, "0");
    const paddedMediaType = media_type.padStart(2, " ");

    let zplString = zpl;

    // Perform replacements
    zplString = zplString.replace(/\{0\}/g, customerLabel_id);
    zplString = zplString.replace(/\{1\}/g, container_number);
    zplString = zplString.replace(/\{2\}/g, media_type);
    zplString = zplString.replace(/\{3\}/g, customerMediaIdentifier_id);
    zplString = zplString.replace(/\{4\}/g, paddedCustomerNumber);
    zplString = zplString.replace(/\{5\}/g, paddedMediaType);
    zplString = zplString.replace(/\{6\}/g, paddedContainerNumber);

    return zplString;
  };

  // record the container transaction
  const recordContainerTransaction = async (containerId) => {
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        container_id: containerId,
        employee_id: getAuthenticatedUserId()
      };

      const dataSets = await getResponseData(
        reqBody,
        `${CF_URLS.containerProcessing?.containerTransactionInsertion}`,
        2
      );

      const obj = dataSets?.data;
      if (obj) {
        if (obj[1][0]?.error === errorMsgs.noError) {
          return;
        } else {
          if (obj[1][0]?.error === errorMsgs.errorCode61043) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode61043);
            setAlert((prevState) => ({
              show: true,
              severity: "warning",
              message: errDesc[0]?.descr
            }));
          }
        }
      }
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        severity: "warning",
        message: findErrorMessage(ERROR_TYPES.ISSUE)
      }));
      return;
    }
  };

  // print button onClick event handler
  const handlePrintOnClick = async () => {
    try {
      setPageLoading((prevState) => true);
      if (selectedPrinter) {
        setAlert((prevState) => ({
          show: false,
          severity: "warning",
          message: ""
        }));

        try {
          const zpl = labelFormatData?.label_template_zpl;

          // container for print
          const containerToPrint = props.container;

          const labelPrinterId = selectedPrinterInfo?.label_printer_id;
          const customerNumber = containerToPrint?.customer_number?.trim();
          const mediaType = containerToPrint?.type?.trim();
          const containerNumber = containerToPrint?.container_number?.trim();
          const customerMediaIdentifier = props.includeCMI
            ? containerToPrint?.customer_media_descr
            : VALUE_EMPTY_STRING;

          // container label
          const containerLabelZpl = await getContainerLabelZpl(
            zpl,
            customerNumber,
            mediaType,
            containerNumber,
            customerMediaIdentifier
          );

          const auditInfoMsg = await auditInfoCreation(
            labelPrinterId,
            customerNumber,
            mediaType,
            containerNumber
          );

          // audit
          const reqBody = {
            main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
            label_format_id: labelFormatData?.label_format_id?.trim(),
            audit_info: auditInfoMsg,
            customer_number: customerNumber,
            media_type: mediaType,
            container_number: containerNumber,
            media_number: VALUE_EMPTY_STRING
          };

          // record an audit
          await executeAudit(reqBody);

          // print
          const adjustedZpl = applyPrinterAdjustments(containerLabelZpl);

          if (
            selectedPrinterInfo?.ip_address !== labelPrinterSetup.ipAddressCheck
          ) {
            // selected printer ip address not 0.0.0.0
            // send data to the printer to start printing
            const printResponse = await sendDataToPrinter(adjustedZpl);

            if (printResponse) {
              // record a container transaction if print successfull
              await recordContainerTransaction(containerToPrint?.container_id);
              // show a alert after successfull printing
              setAlert((prevState) => ({
                show: true,
                severity: "success",
                message: t("containerResearchTranactionDetail.sentToPrinter")
              }));
              // clear the alert after three seconds
              const timeoutAlert = setTimeout(() => {
                setAlert((prevState) => ({
                  show: false,
                  severity: "warning",
                  message: ""
                }));
                clearTimeout(timeoutAlert);
              }, 3000);
            }
          }
        } catch (error) {
          setAlert((prevState) => ({
            show: true,
            severity: "warning",
            message: findErrorMessage(ERROR_TYPES.ISSUE)
          }));
        }
      } else {
        // No printer selected
        getMessageFromCode(errorMsgs.errorCode25365).then((resp) =>
          setAlert((prevState) => ({
            show: true,
            severity: "warning",
            message: resp[0]?.descr
          }))
        );
      }
    } catch (e) {
      setAlert((prevState) => ({
        show: true,
        severity: "warning",
        message: e.message || findErrorMessage(ERROR_TYPES.ISSUE)
      }));
    } finally {
      setPageLoading((prevState) => false);
    }
  };

  // init page
  useEffect(() => {
    // initialize data and options required for printing label
    const initializeData = async () => {
      setPageLoading((prevState) => true);

      // fetch printer list and set to state
      const requestBody = JSON.stringify({
        main_district_id: corporateAccessLevelDistrictId,
        label_printer_id: VALUE_EMPTY_STRING,
        district_id: getAuthenticatedUserBranch()
      });
      const response = await getResponseData(
        requestBody,
        `${CF_URLS.containerIssurance.getAllPrinters}`,
        2
      );

      const results = response.data[0];
      const sortedPrinters = results
        ?.filter((item) =>
          item?.label_type_code.startsWith(containerLabelPrintersFilterKey)
        )
        ?.sort(
          (a, b) => Number(a?.label_printer_id) - Number(b?.label_printer_id)
        );
      setPrinters((prevState) =>
        sortedPrinters?.map((item) => ({
          ...item,
          label: item?.short_name,
          value: item?.label_printer_id
        }))
      );

      setPageLoading((prevState) => false);
    };

    initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="reprint-labels-full-page-loader"
        loading={pageLoading}
      />

      <CommonModal
        open={props.open}
        title={t("containerResearchTranactionDetail.reprintLabel")}
        body={
          <Box sx={containerResearchStyles.modalStyles.containerWidth}>
            {/* Alert area */}
            {alert.show && (
              <Alert
                id="reprint-labels-modal-alert"
                severity={alert.severity}
                sx={{ marginBottom: spacing.verticalMargin16 }}
                onClose={() =>
                  setAlert((prevState) => ({
                    show: false,
                    message: ""
                  }))
                }
              >
                {alert.message}
              </Alert>
            )}

            <Grid
              id="reprint-labels-modal-grid1"
              container
              spacing={spacing.verticalMargin16}
            >
              <Grid
                id="reprint-labels-modal-grid2"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* Printers */}
                <Grid id="reprint-labels-modal-grid3" item md={6}>
                  <SelectField
                    id="reprint-labels-modal-printer"
                    name="newStatus"
                    label={t("containerResearchTranactionDetail.printer")}
                    options={printers}
                    value={selectedPrinter}
                    onChange={handlePrinterOnChange}
                  />
                </Grid>
              </Grid>

              {/* Printer Info */}
              <Grid id="reprint-labels-modal-grid5" item md={12}>
                <NonEditableField
                  id="reprint-labels-modal-printer-info"
                  label={t("containerResearchTranactionDetail.printerInfo")}
                  value={selectedPrinterInfo?.location_description}
                />
              </Grid>

              <Divider sx={containerResearchStyles.divider} />

              {/* ---------- Sub title Section ----------  */}
              <Grid id="reprint-labels-modal-grid6" item md={12}>
                <Typography
                  variant="subtitle1"
                  sx={containerResearchStyles.modalSubTitle}
                  gutterBottom
                >
                  {t("containerResearchTranactionDetail.labelFields")}
                </Typography>
              </Grid>

              <Grid
                id="reprint-labels-modal-grid7"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* Customer Number */}
                <Grid id="reprint-labels-modal-grid8" item md={6}>
                  <NonEditableField
                    id="reprint-labels-modal-customer-number"
                    label={t(
                      "containerResearchTranactionDetail.customerNumber"
                    )}
                    value={props.container?.customer_number}
                  />
                </Grid>

                {/* Media Type */}
                <Grid id="reprint-labels-modal-grid9" item md={6}>
                  <NonEditableField
                    id="reprint-labels-modal-media-type"
                    label={t("containerResearch.mediaType")}
                    value={props.container?.type}
                  />
                </Grid>
              </Grid>

              <Grid
                id="reprint-labels-modal-grid10"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* Media Number */}
                <Grid id="reprint-labels-modal-grid11" item md={6}>
                  <NonEditableField
                    id="reprint-labels-modal-media-number"
                    label={t("containerResearchTranactionDetail.mediaNumber")}
                    value={props.container?.container_number}
                  />
                </Grid>

                {/* Customer Media Identifier */}
                <Grid id="reprint-labels-modal-grid12" item md={6}>
                  <NonEditableField
                    id="reprint-labels-modal-customer-media-identifier"
                    label={t("containerResearch.customerMediaIdentifier")}
                    value={props.container?.customer_media_descr}
                  />
                </Grid>
              </Grid>

              <Grid
                id="reprint-labels-modal-grid13"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* Printed By */}
                <Grid id="reprint-labels-modal-grid14" item md={6}>
                  <NonEditableField
                    id="reprint-labels-modal-printed-by"
                    label={t("containerResearchTranactionDetail.printedBy")}
                    value={getAuthenticatedUserName()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
        buttons={
          <>
            <Button
              id="reprint-labels-modal-cancel-btn"
              variant="outlined"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="reset"
              onClick={() => props.onClose()}
            >
              {t("common.cancel")}
            </Button>
            <Button
              id="reprint-labels-modal-print-btn"
              variant="contained"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              onClick={handlePrintOnClick}
            >
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

RePrintLabelModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  container: PropTypes.object.isRequired, // container details
  includeCMI: PropTypes.bool.isRequired, // include CMI in the label
  onClose: PropTypes.func // handle modal close event
};

export default RePrintLabelModal;
