import { Stack } from "@mui/material";
import OpenMediaResearchDetail from "components/open-media-processing/research/OpenMediaResearchDetail";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const OpenMediaResearchDetailPage = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("openMediaResearch.DetaiPageTitle")} />
      <OpenMediaResearchDetail />
    </Stack>
  );
};

export default OpenMediaResearchDetailPage;
