/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton } from "@mui/material";
import SuccessIcon from "assets/images/SuccessIcon.svg";
import { useEffect } from "react";
import { successPopupStyles } from "./SuccessPopupStyles";

const SuccessPopup = ({ message, close }) => {
  useEffect(() => {
    //set time out for remove popup after 3 seconds
    setTimeout(() => {
      close(false);
    }, [3000]);
  }, []);
  return (
    <Alert
      severity="success"
      sx={successPopupStyles.position}
      icon={<img src={SuccessIcon} alt="success" />}
      action={
        <IconButton
          color="inherit"
          size="small"
          onClick={() => {
            close(false);
          }}
        >
          <CloseIcon
            fontSize="inherit"
            onClick={() => {
              close(false);
            }}
          />
        </IconButton>
      }
    >
      {message}
    </Alert>
  );
};

export default SuccessPopup;
