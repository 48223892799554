import { Stack } from "@mui/material";
import { MaintainBillingRules } from "components/billing-module";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const BillingInterfaceRules = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("billingInterfaceRules.pageTitle")} />
      <MaintainBillingRules />
    </Stack>
  );
};

export default BillingInterfaceRules;
