import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader, SelectField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { getErrorMessage } from "components/customer-information/route-management/helper";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import {
  getCustomersQueryData,
  getDistricts,
  getMessageFromCode
} from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  COMPLETE,
  CUSTOMER_PROCESS_ID,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  FAILED,
  IN_PROGRESS,
  NOT_COMPLETED,
  N_CHECKER,
  Y_CHECKER,
  errorMsgs
} from "utils/constants";
import {
  findErrorMessage,
  getResponseData,
  getSortedData
} from "utils/helpers";
import CustomerDetailTable from "./CustomerDetailTable";
import CustomerMoveDetailPopup from "./CustomerMoveDetailPopup";
import { CustomerMoveStyles } from "./CustomerMoveDetailStyles";

const CustomerMoveDetailLayout = () => {
  const { t } = useTranslation();
  const initialState = {
    sourceBranch: "",
    sourceCustomer: "",
    destinationBranch: "",
    sourceCustomerCheckBox: false,
    destinationCustomerCheckBox: false
  };

  const customerDetailForm = useFormik({
    initialValues: initialState
  });
  const [sourceBranchData, setSourceBranchData] = useState([]);
  const [openLogsPopup, setOpenLogsPopup] = useState(false);
  const [warningAlertOpen, setWarningAlertOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [allSourceCustomers, setAllSourceCustomers] = useState([]);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [sourceCustomerCheckBoxLabel, setSourceCustomerchcekBoxLabel] =
    useState("");
  const [customerMoveEventId, setCustomerMoveEventId] = useState("");
  const [customerMoveProcessId, setCustomerMoveProcessId] = useState("");
  const [destinationCustomerData, setDestinationCustomerData] = useState({
    customerId: "",
    customerName: ""
  });
  const [checkboxErrors, setCheckBoxErrors] = useState({
    sourceCustomerCheckbox: "",
    destinationCustomerCheckbox: ""
  });
  const [selectedSourceCustomerId, setSelectedSourceCustomerId] = useState("");
  const [selectedDestinationCustomerId, setSelectedDestinationCustomerId] =
    useState("");
  const [openRunPopup, setOpenRunPopup] = useState(false);
  const [processTableData, setProcessTableData] = useState([]);
  const [runBtnEnabled, setRunBtnEnabled] = useState(false);

  const [throwError, setThrowError] = useState(false);
  const [openDayOfMove, setOpenDayOfMove] = useState(false);
  const [gridRowData, setGridRowData] = useState();
  const [enableNew, setEnalbleNew] = useState(true);
  const { auth } = useSelector(selectAuth);
  const [selectedRadioValue, setSelectedRadioValue] = useState(Y_CHECKER);
  const [isDistributionListCreated, setIsDistributionListCreated] =
    useState("");
  const [copyMediaTransactionMsg, setCopyMediaTransactionMsg] = useState("");
  const [sourceBranchName, setSourceBranchName] = useState("");
  const [destinationBranchName, setDestinationBranchName] = useState("");
  const [logsData, setLogsData] = useState([]);
  const [responseData, setResponsedata] = useState(false);
  const params = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (params?.state) {
      const loadDataFromParams = async () => {
        customerDetailForm?.setFieldValue(
          "sourceBranch",
          params?.state?.source_district_id
        );
        customerDetailForm.setFieldValue(
          "sourceCustomer",
          params?.state?.source_customer_number
        );

        try {
          const dataValues = await getCustomersBasedOnBranch(
            params?.state?.source_district_id,
            "source_Branch",
            ""
          );
          const data =
            dataValues &&
            dataValues?.find(
              (el) => el?.value === params?.state?.source_customer_number
            );
          if (data) {
            const [, extractedString] = data?.label?.split(" - ") || [];
            const newExtractedString = extractedString.trim();
            setSourceCustomerchcekBoxLabel((prev) => newExtractedString);
            setSelectedSourceCustomerId(data?.id);
          }
          customerDetailForm?.setFieldValue(
            "destinationBranch",
            params?.state?.destination_district_id
          );
          await getCustomersBasedOnBranch(
            params?.state?.destination_district_id,
            "",
            params?.state?.source_customer_number
          );
          setSourceBranchName(params?.state?.source_district_name);
          setDestinationBranchName(params?.state?.destination_district_name);
          customerDetailForm?.setFieldValue("sourceCustomerCheckBox", true);
          customerDetailForm?.setFieldValue(
            "destinationCustomerCheckBox",
            true
          );
          const response = await validationsCheck();
          setResponsedata(response);
          if (response) {
            setProcessTableData([]);
            return;
          } else {
            setCustomerMoveProcessId(params?.state?.customer_move_process_id);
            setCustomerMoveEventId(params?.state?.customer_move_event_id);
            const responseForProcessInformation =
              await fetchCustomerProcessData();
            const processArray = responseForProcessInformation?.data[0];
            const processData = updateProcessStatus(
              params?.state?.customer_move_process_id,
              params?.state?.event_process_status,
              processArray
            );
            setProcessTableData(processData);
            setEnalbleNew(false);
          }
        } catch (error) {
          setThrowError(true);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        }

        navigate(params.pathname, { replace: true });
      };

      loadDataFromParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.state, responseData]);

  useEffect(() => {
    fetchBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBranches = async () => {
    try {
      setLoading(true);
      const branchesResponse = await getDistricts();
      const mappedSourceBranches = branchesResponse?.map((res) => ({
        value: res?.district_id,
        label: res?.name.trim().toUpperCase()
      }));
      const data = getSortedData(mappedSourceBranches, "label", "asc");
      setSourceBranchData(data);
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  // get customers based on the selected  branch
  const getCustomersBasedOnBranch = async (
    branch,
    branchStr = "",
    sourceCustomerValue = ""
  ) => {
    setLoading(true);
    try {
      const custList = await getCustomersQueryData(branch);
      let modCux = custList?.map((cus) => {
        return {
          label: `${cus?.number} - ${cus?.name} - ${cus?.customer_id}`,
          value: cus?.number,
          id: cus?.customer_id
        };
      });
      if (branchStr) {
        setAllSourceCustomers(modCux);
        if (params?.state) {
          return modCux;
        }
      } else {
        // Find the source customer in destination customers
        const data = modCux?.find((el) => el?.value === sourceCustomerValue);
        if (!data) {
          setDestinationCustomerData("");
          const message = await getErrorMessage(errorMsgs.errorCode80496);
          setThrowErrorMessage(message);
          setThrowError(true);
        } else {
          const [, extractedString] = data?.label?.split(" - ") || [];
          setSelectedDestinationCustomerId(data?.id);
          setDestinationCustomerData({
            customerId: data?.value || "",
            customerName: extractedString || ""
          });
          setThrowErrorMessage("");
          setThrowError(false);
        }
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  const fetchVerifyByEmployeeId = async () => {
    setLoading(true);
    try {
      const VerifyEmployeeReqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        employee_id: auth?.user?.id,
        src_district_id: customerDetailForm.values.sourceBranch,
        dest_district_id: customerDetailForm.values.destinationBranch
      };
      const res = await getResponseData(
        VerifyEmployeeReqBody,
        CF_URLS.customerMoveDetail.customerMoveUserAuthorityUrl,
        1
      );
      if (res?.data?.[0]?.[0].error === errorMsgs.errorCode20538) {
        setThrowError(true);
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode20538);
        setThrowErrorMessage(errorMsg[0]?.descr);
        customerDetailForm.resetForm();
      } else {
        if (res?.data?.[0]?.[0].error === errorMsgs.noError) {
          await fetchCustomerTableData();
        }
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomerTableData = async () => {
    setLoading(true);
    try {
      const CustomerTableReqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        employee_id: auth?.user?.id,
        source_district_id: customerDetailForm.values.sourceBranch,
        source_customer_number:
          customerDetailForm.values.sourceCustomer.replace(/\s+/g, ""),
        destination_district_id: customerDetailForm.values.destinationBranch,
        destination_customer_number: destinationCustomerData.customerId.replace(
          /\s+/g,
          ""
        ),
        process_id: "",
        active_only: ""
      };

      const responseForEventProcessStatus = await getResponseData(
        CustomerTableReqBody,
        CF_URLS.customerMoveDetail.checkIsCustomerMoveExistsUrl,
        2
      );

      const isActiveStatus = responseForEventProcessStatus?.data[0][0]
        ? responseForEventProcessStatus?.data[0][0].is_active
        : "";
      if (isActiveStatus === Y_CHECKER) {
        try {
          setCustomerMoveEventId(
            responseForEventProcessStatus?.data[0][0].customer_move_event_id
          );
          const customerMoveProcessId =
            responseForEventProcessStatus?.data[0][0].customer_move_process_id;
          setCustomerMoveProcessId(customerMoveProcessId);
          setIsDistributionListCreated(
            responseForEventProcessStatus?.data[0][0].is_distribution
          );
          const costumerStatus =
            responseForEventProcessStatus?.data[0][0].event_process_status;
          const responseForProcessInformation =
            await fetchCustomerProcessData();
          const processArray = responseForProcessInformation?.data[0];
          const processData = updateProcessStatus(
            customerMoveProcessId,
            costumerStatus,
            processArray
          );
          setProcessTableData(processData);
          setEnalbleNew(false);
        } catch (error) {
          setThrowError(true);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        } finally {
          setLoading(false);
        }
      } else if (isActiveStatus === N_CHECKER) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80501);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        setEnalbleNew(false);
      } else {
        try {
          const response = await saveCustomerMove();
          if (response?.data?.[0][0].rc === errorMsgs.noError) {
            const response1 = await isExistingCustomerMoveDetail();
            setCustomerMoveEventId(
              response1?.data[0][0]?.customer_move_event_id
            );
            const responseForProcessInformation =
              await fetchCustomerProcessData();
            const processArray = responseForProcessInformation?.data[0];
            const processData = gridDataForNoIsActiveStatus(processArray);
            setProcessTableData(processData);
            setEnalbleNew(false);
          } else {
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode80548);
            setThrowErrorMessage(errorMsg[0]?.descr);
            setThrowError(true);
            customerDetailForm?.resetForm();
          }
        } catch (error) {
          setThrowError(true);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      return false;
    } finally {
      setLoading(false);
    }
  };

  const gridDataForNoIsActiveStatus = (processArr) => {
    const sortedData = processArr.sort(
      (a, b) => a.customer_move_process_id - b.customer_move_process_id
    );

    const updatedData = sortedData.map((item) => ({
      ...item,
      process_status: NOT_COMPLETED
    }));
    return updatedData;
  };

  const updateProcessStatus = (
    customer_move_process_id,
    event_process_status,
    processArray
  ) => {
    const output = [];
    let status = "";
    const sortedProcessArr = processArray.sort(
      (a, b) => a.customer_move_process_id - b.customer_move_process_id
    );
    for (let i = 1; i <= sortedProcessArr.length; i++) {
      if (i < parseInt(customer_move_process_id)) {
        status = COMPLETE;
      } else if (
        i === parseInt(customer_move_process_id) &&
        event_process_status === COMPLETE
      ) {
        status = COMPLETE;
      } else if (
        i === parseInt(customer_move_process_id) &&
        event_process_status === IN_PROGRESS
      ) {
        status = IN_PROGRESS;
      } else if (
        i === parseInt(customer_move_process_id) &&
        event_process_status === FAILED
      ) {
        status = FAILED;
      } else {
        status = NOT_COMPLETED;
      }
      output.push({
        customer_move_process_id:
          sortedProcessArr[i - 1].customer_move_process_id,
        process_name: sortedProcessArr[i - 1].process_name,
        process_status: status
      });
    }

    return output;
  };

  const fetchCustomerProcessData = async () => {
    setLoading(true);
    try {
      const CustomerTableReqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      };

      const res = await getResponseData(
        CustomerTableReqBody,
        CF_URLS.customerMoveDetail.returnCustomerMoveProcessUrl,
        2
      );
      return res;
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };
  const handleSourceBranchChange = async (event, newValue) => {
    const { value } = event.target;
    setSourceBranchName(newValue?.label);
    setProcessTableData([]);
    setThrowError(false);
    customerDetailForm.setFieldValue("sourceBranch", value);
    customerDetailForm.setFieldValue("sourceCustomerCheckBox", false);
    customerDetailForm.setFieldValue("destinationCustomerCheckBox", false);
    customerDetailForm.setFieldValue("sourceCustomer", "");
    customerDetailForm.setFieldValue("destinationBranch", "");
    await getCustomersBasedOnBranch(value, "source_Branch", "");
  };

  const handleSourceCustomerChange = async (event, newValue) => {
    if (newValue) {
      setSelectedSourceCustomerId(newValue?.id);
      const [, extractedString] = newValue.label.split(" - ");
      const newExtractedString = extractedString.trim();
      customerDetailForm.setFieldValue("sourceCustomer", newValue?.value);
      setThrowError(false);
      customerDetailForm.setFieldValue("sourceCustomerCheckBox", false);
      customerDetailForm.setFieldValue("destinationCustomerCheckBox", false);
      setSourceCustomerchcekBoxLabel(newExtractedString);
      if (customerDetailForm?.values?.destinationBranch) {
        await getCustomersBasedOnBranch(
          customerDetailForm?.values?.destinationBranch,
          "",
          newValue?.value
        );
      }
    }
  };

  const handleDestinationBranchChange = async (event, newValue) => {
    const { value } = event.target;
    setDestinationBranchName(newValue?.label);
    customerDetailForm.setFieldValue("destinationCustomerCheckBox", false);
    customerDetailForm.setFieldValue("destinationBranch", value);
    setThrowError(false);
    await getCustomersBasedOnBranch(
      value,
      "",
      customerDetailForm?.values.sourceCustomer
    );
  };

  const filteredDestinationBranches = useMemo(() => {
    return sourceBranchData?.filter(
      (branch) => branch.value !== customerDetailForm?.values?.sourceBranch
    );
  }, [sourceBranchData, customerDetailForm?.values?.sourceBranch]);

  const validationsCheck = async () => {
    if (!customerDetailForm?.values?.sourceBranch) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("customerMoveDetail.source_Branch")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
      return true;
    } else if (
      customerDetailForm?.values?.sourceBranch &&
      !customerDetailForm?.values?.sourceCustomer
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("customerMoveDetail.sourceCustomer")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
      return true;
    } else if (
      customerDetailForm?.values?.sourceBranch &&
      customerDetailForm?.values?.sourceCustomer &&
      !customerDetailForm?.values?.destinationBranch
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("customerMoveDetail.destinationBranch")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
      return true;
    } else if (
      customerDetailForm?.values?.sourceBranch &&
      customerDetailForm?.values?.sourceCustomer &&
      customerDetailForm?.values?.destinationBranch &&
      !destinationCustomerData?.customerId
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("customerMoveDetail.destinationCustomer")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
      return true;
    } else if (
      customerDetailForm?.values?.sourceBranch &&
      customerDetailForm?.values?.sourceCustomer &&
      customerDetailForm?.values?.destinationBranch &&
      destinationCustomerData?.customerId &&
      !customerDetailForm?.values?.sourceCustomerCheckBox &&
      !customerDetailForm?.values?.destinationCustomerCheckBox
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText1 = errorMsg[0]?.descr.replace(
        "|",
        t("customerMoveDetail.source_customer_name")
      );
      let replacedText2 = errorMsg[0]?.descr.replace(
        "|",
        t("customerMoveDetail.destination_customer_Name")
      );
      setCheckBoxErrors({
        sourceCustomerCheckbox: replacedText1,
        destinationCustomerCheckbox: replacedText2
      });
      return true;
    } else if (
      customerDetailForm?.values?.sourceBranch &&
      customerDetailForm?.values?.sourceCustomer &&
      customerDetailForm?.values?.destinationBranch &&
      destinationCustomerData?.customerId &&
      !customerDetailForm?.values?.sourceCustomerCheckBox
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("customerMoveDetail.source_customer_name")
      );

      setCheckBoxErrors({
        sourceCustomerCheckbox: replacedText,
        destinationCustomerCheckbox: ""
      });
      return true;
    } else if (
      customerDetailForm?.values?.sourceBranch &&
      customerDetailForm?.values?.sourceCustomer &&
      customerDetailForm?.values?.destinationBranch &&
      destinationCustomerData?.customerId &&
      customerDetailForm?.values?.sourceCustomerCheckBox &&
      !customerDetailForm?.values?.destinationCustomerCheckBox
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("customerMoveDetail.destination_customer_Name")
      );

      setCheckBoxErrors({
        sourceCustomerCheckbox: "",
        destinationCustomerCheckbox: replacedText
      });
      return true;
    } else {
      return false;
    }
  };
  // new button click
  const handleNewClick = async () => {
    setGridRowData();
    setRunBtnEnabled(false);
    setThrowError(false);
    const response = await validationsCheck();
    if (response) {
      return;
    } else {
      await fetchVerifyByEmployeeId();
    }
  };

  //customer_move_event_i_sp
  const saveCustomerMove = async () => {
    setLoading(true);
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        src_district_id: customerDetailForm.values.sourceBranch,
        src_customer_number: customerDetailForm.values.sourceCustomer.replace(
          /\s+/g,
          ""
        ),
        dst_customer_number: destinationCustomerData?.customerId.replace(
          /\s+/g,
          ""
        ),
        dst_district_id: customerDetailForm.values.destinationBranch,
        rc: ""
      };

      const response = await getResponseData(
        reqBody,
        CF_URLS.customerMoveDetail.customerMoveEventIUrl,
        1
      );
      return response;
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  // cmu_customer_validation_wrap_sp
  const runCustomerValidation = async () => {
    setLoading(true);
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        destination_customer_id: selectedDestinationCustomerId,
        destination_district_id: customerDetailForm.values.destinationBranch
      };

      const response = await getResponseData(
        reqBody,
        CF_URLS.customerMoveDetail.customerValidationWrapLUrl,
        1
      );
      return response;
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };
  const handleClear = () => {
    customerDetailForm.resetForm();
    setCustomerMoveProcessId("");
    setProcessTableData([]);
    setThrowError(false);
    setCheckBoxErrors({
      sourceCustomerCheckbox: "",
      destinationCustomerCheckbox: ""
    });
    setEnalbleNew(true);
  };

  // cmu_ui_run_wrap_sp
  const runCustomerMoveProcessInformation = async (customerMoveProcessId) => {
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        src_cust_id: selectedSourceCustomerId,
        dst_cust_id: selectedDestinationCustomerId,
        src_district_id: customerDetailForm.values.sourceBranch,
        dst_district_id: customerDetailForm.values.destinationBranch,
        customer_move_process_id: customerMoveProcessId || "",
        customer_move_event_id: customerMoveEventId,
        move_open_media:
          selectedRadioValue === N_CHECKER ? Y_CHECKER : N_CHECKER,
        move_container:
          customerMoveProcessId === CUSTOMER_PROCESS_ID ? Y_CHECKER : "",
        login: auth?.user?.login
      };
      const response = await getResponseData(
        reqBody,
        CF_URLS.customerMoveDetail.getCustomerMoveWrapUrl,
        1
      );
      return response;
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  //customer_move_event_l_sp
  const getCustomerMoveLogs = async () => {
    setLoading(true);
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_move_event_id: customerMoveEventId
      };

      const response = await getResponseData(
        reqBody,
        CF_URLS.customerMoveDetail.customerMoveEventLUrl,
        2
      );
      return response;
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      return false;
    } finally {
      setLoading(false);
    }
  };

  //logs button click
  const handleLogs = async () => {
    setLogsData([]);
    setOpenLogsPopup(true);
    const response = await getCustomerMoveLogs();
    const data = response?.data?.[0];
    setLogsData(data);
  };

  //gridRow click
  const handleRowClick = (rowData, rowState) => {
    setGridRowData(rowState.dataIndex);
    isRunBtnEnabled(rowState.dataIndex);
  };

  //check for enabling/disabling run button
  const isRunBtnEnabled = (index) => {
    const selectedIndex = processTableData.findIndex(
      (process) => parseInt(process.customer_move_process_id) === index + 1
    );

    // If the selected process is not found or is the first one (no rows above it)
    if (
      selectedIndex === 0 &&
      (processTableData?.[selectedIndex]?.process_status === NOT_COMPLETED ||
        processTableData?.[selectedIndex]?.process_status === FAILED)
    ) {
      return setRunBtnEnabled(true);
    }

    // Check if all rows above the selected row have status "Complete"
    for (let i = 0; i < selectedIndex; i++) {
      if (processTableData[i].process_status !== COMPLETE) {
        return setRunBtnEnabled(false);
      }
    }
    // Check the status of the selected row
    const selectedProcessStatus =
      processTableData?.[selectedIndex]?.process_status;
    if (
      selectedProcessStatus === NOT_COMPLETED ||
      selectedProcessStatus === FAILED
    ) {
      return setRunBtnEnabled(true);
    } else {
      return setRunBtnEnabled(false);
    }
  };

  const handleRefreshBtnClick = async () => {
    setRunBtnEnabled(false);
    setThrowError(false);
    const response1 = await isExistingCustomerMoveDetail();
    const response = await fetchCustomerProcessData();
    const customerMoveProcessId =
      response1?.data[0][0].customer_move_process_id;
    const costumerStatus = response1?.data[0][0].event_process_status;

    const processArray = response?.data[0];
    const processData = updateProcessStatus(
      customerMoveProcessId,
      costumerStatus,
      processArray
    );
    setProcessTableData(processData);
    setGridRowData();
    setRunBtnEnabled(false);
  };

  // run button click
  const handleRunBtnClick = async () => {
    if (gridRowData === 2) {
      if (isDistributionListCreated === Y_CHECKER) {
        let errorMsg = await getMessageFromCode(errorMsgs.errorCode80495);
        setCopyMediaTransactionMsg(errorMsg[0]?.descr);
        setOpenRunPopup(true);
      } else {
        setLoading(true);
        //calling the api asynchoronusly as the response is not required to check.
        await runCustomerMoveProcessInformation(String(gridRowData + 1));
        handleRefreshBtnClick();
        setRunBtnEnabled(false);
        setGridRowData();
      }
    } else if (gridRowData === 1) {
      setThrowError(false);
      const response = await runCustomerValidation();
      if (response?.data[0][0]?.error === errorMsgs.noError) {
        setOpenDayOfMove(true);
      } else {
        if (response?.data?.[0]?.[0].error === errorMsgs.errorCode80498) {
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode80498);
          setThrowErrorMessage(errorMsg[0]?.descr);
          setThrowError(true);
          setRunBtnEnabled(false);
          setGridRowData();
        } else if (
          response?.data?.[0]?.[0].error === errorMsgs.errorCode80499
        ) {
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode80499);
          setThrowErrorMessage(errorMsg[0]?.descr);
          setThrowError(true);
          setRunBtnEnabled(false);
          setGridRowData();
        }
      }
    } else {
      setLoading(true);
      //calling the api synchoronusly as the response is not required to check.
      await runCustomerMoveProcessInformation(String(gridRowData + 1));
      handleRefreshBtnClick();
      setRunBtnEnabled(false);
      setGridRowData();
    }
  };
  const isExistingCustomerMoveDetail = async () => {
    setLoading(true);
    try {
      const CustomerTableReqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        employee_id: auth?.user?.id,
        source_district_id: customerDetailForm.values.sourceBranch,
        source_customer_number:
          customerDetailForm.values.sourceCustomer.replace(/\s+/g, ""),
        destination_district_id: customerDetailForm.values.destinationBranch,
        destination_customer_number: destinationCustomerData.customerId.replace(
          /\s+/g,
          ""
        ),
        process_id: "",
        active_only: ""
      };

      const responseForEventProcessStatus = await getResponseData(
        CustomerTableReqBody,
        CF_URLS.customerMoveDetail.checkIsCustomerMoveExistsUrl,
        2
      );
      return responseForEventProcessStatus;
    } catch (err) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleDayOfMoveRunBtnClick = async () => {
    setLoading(true);
    //calling the api asynchoronusly as the response is not required to check.
    await runCustomerMoveProcessInformation(String(gridRowData + 1));
    setOpenDayOfMove(false);
    handleRefreshBtnClick();
    setRunBtnEnabled(false);
    setGridRowData();
  };

  const handleYesMoveTransactionBtnClick = async () => {
    setLoading(true);
    setOpenRunPopup(false);
    //calling the api asynchoronusly as the response is not required to check.
    await runCustomerMoveProcessInformation(String(gridRowData + 1));
    handleRefreshBtnClick();
    setRunBtnEnabled(false);
    setGridRowData();
  };

  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      <Stack sx={CustomerMoveStyles.mainMargin} key={params?.state}>
        {throwError && (
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
        )}
      </Stack>

      <form>
        <Stack
          direction="column"
          gap={spacing.verticalMargin20}
          mb={spacing.verticalMargin32}
        >
          <Grid container spacing={spacing.horizontalMargin20}>
            <Grid item xs={3} id="source-branch">
              <SelectField
                label={t("customerMoveDetail.SourceBranch")}
                value={customerDetailForm.values.sourceBranch}
                options={sourceBranchData}
                onChange={handleSourceBranchChange}
                disabled={!enableNew}
                objectSelection={true}
                required
              />
            </Grid>

            <Grid item xs={3} id="destination-branch">
              <SelectField
                label={t("customerMoveDetail.destinationBranch")}
                value={customerDetailForm.values.destinationBranch}
                options={filteredDestinationBranches}
                onChange={handleDestinationBranchChange}
                disabled={
                  !customerDetailForm?.values?.sourceCustomer || !enableNew
                }
                objectSelection={true}
                required
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={spacing.horizontalMargin20}
            id="source-customer"
          >
            <Grid item xs={3}>
              <CustomersField
                options={allSourceCustomers}
                value={customerDetailForm.values.sourceCustomer}
                handleOnChange={handleSourceCustomerChange}
                form={customerDetailForm}
                disabled={
                  !customerDetailForm?.values?.sourceBranch || !enableNew
                }
                labelValue={t("customerMoveDetail.sourceCustomerRequired")}
              />
              {customerDetailForm.values.sourceCustomer &&
                sourceCustomerCheckBoxLabel && (
                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              customerDetailForm.values.sourceCustomerCheckBox
                            }
                            disabled={!enableNew}
                            onChange={(e) => {
                              customerDetailForm.setFieldValue(
                                "sourceCustomerCheckBox",
                                e.target.checked
                              );
                            }}
                          />
                        }
                        label={sourceCustomerCheckBoxLabel}
                      />
                    </FormGroup>
                  </Box>
                )}
              {checkboxErrors?.sourceCustomerCheckbox &&
                !customerDetailForm?.values.sourceCustomerCheckBox && (
                  <Typography variant="caption" color="error">
                    {checkboxErrors?.sourceCustomerCheckbox}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={3}>
              <Stack>
                <Typography variant="caption">
                  {t("customerMoveDetail.destinationCustomer")}
                </Typography>
                <Typography variant="h7">
                  {customerDetailForm?.values?.destinationBranch &&
                    destinationCustomerData?.customerId}
                </Typography>
              </Stack>

              {customerDetailForm.values.destinationBranch &&
                destinationCustomerData?.customerName && (
                  <Box paddingTop={CustomerMoveStyles.paddingTop}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              customerDetailForm.values
                                .destinationCustomerCheckBox
                            }
                            disabled={!enableNew}
                            onChange={(e) => {
                              customerDetailForm.setFieldValue(
                                "destinationCustomerCheckBox",
                                e.target.checked
                              );
                            }}
                          />
                        }
                        label={destinationCustomerData?.customerName}
                      />
                    </FormGroup>
                  </Box>
                )}
              {checkboxErrors?.destinationCustomerCheckbox &&
                !customerDetailForm?.values.destinationCustomerCheckBox && (
                  <Typography variant="caption" color="error">
                    {checkboxErrors?.destinationCustomerCheckbox}
                  </Typography>
                )}
            </Grid>
          </Grid>
        </Stack>
        <Stack>
          <Collapse
            in={warningAlertOpen}
            sx={{
              marginBottom: warningAlertOpen ? spacing.horizontalMargin20 : 0
            }}
          >
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setWarningAlertOpen(false)}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {t("requestSearch.searchRecordsLimitWarningMessage")}
            </Alert>
          </Collapse>
          <CustomerDetailTable
            handleRefreshBtnClick={handleRefreshBtnClick}
            processTableData={processTableData}
            setIsLoading={setLoading}
            throwError={throwError}
            setThrowError={setThrowError}
            throwErrorMessage={throwErrorMessage}
            setThrowErrorMessage={setThrowErrorMessage}
            customerMoveEventId={customerMoveEventId}
            destinationDistrictId={
              customerDetailForm?.values?.destinationBranch
            }
            destinationBranchDistrict={
              customerDetailForm?.values?.destinationBranch
            }
            handleRowClick={handleRowClick}
            gridRowData={gridRowData}
            customerMoveProcessId={customerMoveProcessId}
            runBtnEnabled={runBtnEnabled}
            openDayOfMove={openDayOfMove}
            setOpenDayOfMove={setOpenDayOfMove}
            handleRunBtnClick={handleRunBtnClick}
            setOpenRunPopup={setOpenRunPopup}
            openRunPopup={openRunPopup}
            selectedRadioValue={selectedRadioValue}
            setSelectedRadioValue={setSelectedRadioValue}
            handleDayOfMoveRunBtnClick={handleDayOfMoveRunBtnClick}
            copyMediaTransactionMsg={copyMediaTransactionMsg}
            handleYesMoveTransactionBtnClick={handleYesMoveTransactionBtnClick}
            isDistributionListCreated={isDistributionListCreated}
            setGridRowData={setGridRowData}
            setRunBtnEnabled={setRunBtnEnabled}
          />
        </Stack>
        <Stack
          id="mediaRequestDetailStack1"
          direction="row"
          spacing={spacing.verticalMargin20}
          sx={CustomerMoveStyles.BottomButtonsContainer}
        >
          <Grid container spacing={spacing.horizontalMargin20}>
            <Grid item>
              <Grid item>
                <Button variant="outlined" onClick={handleClear}>
                  {t("common.clear")}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                disabled={!processTableData.length}
                onClick={handleLogs}
              >
                {t("customerMoveDetail.logsBtn")}
              </Button>
            </Grid>

            <Grid item>
              <Button
                id="new-button"
                variant="contained"
                color="primary"
                size="medium"
                type="button"
                onClick={handleNewClick}
                disabled={!enableNew}
              >
                {t("common.new")}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </form>
      {openLogsPopup && processTableData.length > 0 && (
        <CustomerMoveDetailPopup
          isAssignModalOpen={openLogsPopup}
          handleAssignModalClose={setOpenLogsPopup}
          sourceBranch={sourceBranchName}
          destinationBranch={destinationBranchName}
          sourceCustomer={customerDetailForm?.values?.sourceCustomer}
          destinationCustomer={destinationCustomerData?.customerId}
          logsData={logsData}
        />
      )}
    </>
  );
};

export default CustomerMoveDetailLayout;
