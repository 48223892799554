import { Stack } from "@mui/material";
import SearchConfiguuration from "components/billing-module/capacity-billing-configuration-search/SearchConfigauration";
import { PageTitle } from "components/shared";
import i18n from "i18n";

const CapacityBillingConfigurationSearch = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("menu.capacityBillingConfigurationSearch")} />
      <SearchConfiguuration />
    </Stack>
  );
};

export default CapacityBillingConfigurationSearch;
