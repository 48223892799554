import { Stack } from "@mui/material";
import CustomerFileLayout from "components/open-media-processing/customer-file-search/CustomerFileLayout";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const CustomerFileSearchPage = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("CustomerFileSearch.pageTitle")} />
      <CustomerFileLayout />
    </Stack>
  );
};

export default CustomerFileSearchPage;
