const customScrollBar = {
  scrollbarWidth: "thin",
  scrollbarColor: "var(--color-light-grey) var(--color-im-transparent)",
  "&::-webkit-scrollbar": {
    width: "8px"
  },
  "&::-webkit-scrollbar-track": {
    background: "var(--color-im-transparent)"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "var(--color-light-grey)",
    borderRadius: "10px",
    border: "2px solid var(--color-im-transparent)"
  }
};
export const VaultConfigurationStyles = {
  subHeading: {
    fontSize: "24px"
  },
  iconStyles: {
    color: "var(--color-im-mid-blue)",

    "& svg": {
      fontSize: "32px",
      fontWeight: 400
    }
  },
  nodeLableContainer: {
    display: "flex",
    alignItems: "center"
  },
  iconSize: "small",
  iconStyle: {
    mr: 1
  },
  loaderStyle: {
    display: "inline",
    position: "absolute",
    top: "2px",
    left: "-25px"
  },
  loaderSize: 16,
  treePaper: {
    minHeight: "75vh",
    maxHeight: "75vh",
    overflow: "auto",
    marginRight: "18px",
    marginBottom: "18px",
    ...customScrollBar
  },
  radioButtonGroup: {
    marginLeft: "12px"
  },
  formFactorPaper: {
    padding: "10px 20px 15px 20px",
    height: "390px"
  },
  checkboxColor: {
    color: "primary.main"
  },
  formFactorHeader: {
    height: "15%"
  },
  formFactorBody: {
    height: "85%",
    overflow: "auto",
    ...customScrollBar
  },
  arrowButtonsGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  rightPanelWrapper: {
    flexDirection: "column",
    height: "100%"
  },
  treeWrapper: {
    height: "100%"
  },
  reportRadioGroup: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    padding: "10px"
  },
  labelFrom: {
    color: "var(--color-black)",
    fontSize: "20px",
    fontWeight: "500"
  },
  secLabelForm: {
    fontSize: "16px",
    color: "var(--color-im-grey-900)"
  },
  chevronIcon: {
    color: "var(--color-im-mid-blue)",
    position: "absolute",
    left: "-25px",
    fontSize: "18px"
  },
  errorIcon: {
    width: "22px"
  }
};
