import { Stack } from "@mui/material";
import InstructionFilesLayout from "components/disaster-module/instruction-files/InstructionFilesLayout";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const InstructionFiles = () => {
  return (
    <Stack>
      <PageTitle
        title={i18n.t("disasterRecovery.instructionFiles.pageTitle")}
      />
      <InstructionFilesLayout />
    </Stack>
  );
};

export default InstructionFiles;
