import { Tree } from "@minoru/react-dnd-treeview";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ArrowRight } from "assets/images/ArrowRight.svg";
import { useEffect, useState } from "react";
import { rootIds } from "utils/constants/customer-service-information/RouteManagementConstants";
import { TreeIcon } from "./TreeIcon";

const UnassignedTreeView = ({ unassignedTreeData, isUnassignedExpand }) => {
  const [treeData, setTreeData] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const root = rootIds.unassignedTasks;

  const handleDrop = () => {
    return;
  };

  useEffect(() => {
    setTreeData(unassignedTreeData);
    //eslint-disable-next-line
  }, [unassignedTreeData]);

  return (
    <div className="unassigned-tree-container">
      {treeData && (
        <Tree
          tree={treeData}
          rootId={root}
          onDrop={handleDrop}
          initialOpen={isUnassignedExpand}
          enableAnimateExpand={true}
          sort={(node1, node2) => {
            return (
              parseInt(node1?.data?.customer_schedule_id) -
                parseInt(node2?.data?.customer_schedule_id) ||
              node1?.data?.csl_flag - node2?.data?.csl_flag ||
              parseInt(node1?.data?.customer_id) -
                parseInt(node2?.data?.customer_id)
            );
          }}
          render={(node, { depth, isOpen, onToggle, hasChild }) => (
            <div
              style={{
                marginLeft: hasChild
                  ? `${depth * 20}px`
                  : `${depth * 20 + 20}px`
              }}
              className={`root-node ${
                node.id === selectedId ? "selected" : ""
              }`}
              onClick={() => setSelectedId(node.id)}
            >
              {hasChild && (
                <span onClick={onToggle} className="arrow-icon">
                  {isOpen ? <ArrowDown /> : <ArrowRight />}
                </span>
              )}
              <TreeIcon type={node.type} />
              <span className="node-text">{node.text}</span>
            </div>
          )}
        />
      )}
    </div>
  );
};
export default UnassignedTreeView;
