import { Button, Typography } from "@mui/material";
import ModalPopup from "components/core/modal-components/ModalPopup";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function RescheduleDialogBox({ values, handleClose, ...props }) {
  let alertMessage;
  let modalButton;

  const { t } = useTranslation();
  const originalDate =
    values.originalDate &&
    dayjs(values.originalDate).format(t("rescheduleService.isDateFormat"));
  const newDate =
    values.originalDate &&
    dayjs(values.newDate).format(t("rescheduleService.isDateFormat"));

  const dialogButton = (buttonText, variant) => {
    return (
      <Button
        disabled={buttonText === t("rescheduleService.yes") && props.error}
        variant={variant}
        onClick={() => {
          props.handleClick(props.modalButtonLabel + buttonText);
        }}
        autoFocus>
        {buttonText}
      </Button>
    );
  };
  switch (props.modalButtonLabel) {
    case "dateDialog":
      alertMessage = t("rescheduleService.dateDialogMsg");
      modalButton = dialogButton(t("rescheduleService.ok"), "contained");
      break;
    case "saveChangesDialog":
      alertMessage = t("rescheduleService.saveChangesDialogMsg");
      modalButton = (
        <>
          {dialogButton(t("rescheduleService.yes"), "outlined")}
          {dialogButton(t("rescheduleService.no"), "outlined")}
          {dialogButton(t("rescheduleService.cancel"), "contained")}
        </>
      );
      break;
    case "saveRescheduleDialog":
      alertMessage = (
        <Typography>
          {t("rescheduleService.saveRescheduleDialogMsg1")}{" "}
          {values?.customer?.number} {t("rescheduleService.for")} {originalDate}{" "}
          {t("rescheduleService.saveRescheduleDialogMsg2")} {newDate}
        </Typography>
      );
      modalButton = (
        <>
          {dialogButton(t("rescheduleService.ok"), "outlined")}
          {dialogButton(t("rescheduleService.cancel"), "contained")}
        </>
      );
      break;
    case "newServiceDialog":
      alertMessage = t("rescheduleService.newServiceDialogMsg");
      modalButton = dialogButton(t("rescheduleService.ok"), "contained");
      break;
    case "assignTransportDialog":
      alertMessage = (
        <>
          {t("rescheduleService.assignTransportDialogMsg")}
          <Typography>
            {values.transports && Array.isArray(values.transports)
              ? values.transports
                  .map((transport) => transport.containerNumber)
                  .join(", ")
              : ""}
          </Typography>
        </>
      );
      modalButton = (
        <>
          {dialogButton(t("rescheduleService.yes"), "outlined")}
          {dialogButton(t("rescheduleService.no"), "contained")}
        </>
      );
      break;
    default:
      break;
  }

  return (
    <ModalPopup
      title={t("rescheduleService.confirm")}
      alertMessage={alertMessage}
      modalButton={modalButton}
      modalPopupOpen={values.ModalPopupOpen}
      handleClick={props.handleClick}
    />
  );
}

export default RescheduleDialogBox;
