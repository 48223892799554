import { createSlice } from "@reduxjs/toolkit";
import { authSection, VALUE_EMPTY_STRING } from "utils/constants";

const initialAuthValues = {
  branch: VALUE_EMPTY_STRING,
  customer: VALUE_EMPTY_STRING,
  personnel: VALUE_EMPTY_STRING,
  authNumber: VALUE_EMPTY_STRING,
  challengeQuestion: VALUE_EMPTY_STRING,
  challengeResponse: VALUE_EMPTY_STRING,
  customerName: VALUE_EMPTY_STRING,
  bypassMessage: VALUE_EMPTY_STRING,
  personnelAuthValue: authSection?.personnelAuth
};
const intailBranchValues = {
  branch: VALUE_EMPTY_STRING,
  customer: VALUE_EMPTY_STRING,
  personnel: VALUE_EMPTY_STRING,
  prefenceFilteredTableData: [],
  preferenceTableResultSetOne: {},
  authenticatateBranch: [],
  findButtonDisable: true,
  isDisablePrint: true,
  loading: false,
  tableRowData: {}
};
const intialPreferenceTabData = {
  selectedCustomer: VALUE_EMPTY_STRING,
  selectedBranch: VALUE_EMPTY_STRING,
  prefenceFilteredTableData: [],
  findButtonDisable: true
};
const vaultStatusReportSlice = createSlice({
  name: "vaultStatusReport",
  initialState: {
    discrepancyAuthFormValues: initialAuthValues,
    isLoading: false,
    throwError: false,
    throwErrorMsg: VALUE_EMPTY_STRING,
    isAuthenticated: false,
    throwWarning: false,
    throwWarningMsg: VALUE_EMPTY_STRING,
    isAuthError: false,
    showAuthSection: false,
    emailNotificationHistory: [],
    dontCallHistory: [],
    preferenceTabData: intialPreferenceTabData,
    defaultAuthBranchValues: intailBranchValues,
    isRefreshRequired: false,
    resolutionType: ""
  },
  reducers: {
    updateDiscrepancyAuthFormValues(state, action) {
      state.discrepancyAuthFormValues = {
        ...state.discrepancyAuthFormValues,
        ...action.payload
      };
    },

    setError(state, action) {
      state.throwError = true;
      state.throwErrorMsg = action.payload;
    },

    clearError(state) {
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },

    updatePreferencesAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },

    updateDiscrepancyAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },

    setShowAuthSection(state, action) {
      state.showAuthSection = action.payload;
    },

    setDefaultBranchValues(state, action) {
      state.defaultAuthBranchValues = {
        ...state,
        ...state.defaultAuthBranchValues,
        ...action.payload
      };
    },

    setAuthTypeChange(state, action) {
      state.discrepancyAuthFormValues.personnelAuthValue = action.payload;
    },
    setBypassMesage(state, action) {
      state.discrepancyAuthFormValues.bypassMessage = action.payload;
    },
    setResolutionType(state, action) {
      state.resolutionType = action.payload;
    }
  }
});

export const {
  clearError,
  setAuthTypeChange,
  setDefaultBranchValues,
  setError,
  setShowAuthSection,
  updatePreferencesAuthentication,
  updateDiscrepancyAuthFormValues,
  updateDiscrepancyAuthentication,
  setBypassMesage,
  setResolutionType
} = vaultStatusReportSlice.actions;

export default vaultStatusReportSlice.reducer;
