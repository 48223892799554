import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/warning-2.svg";
import { useTranslation } from "react-i18next";
import { RESPONSE_LIMIT } from "../Constants";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

const PersonnelAuthentication = ({
  personnelAuthDetails,
  mrdForm,
  customerSelected,
  handleAuthenticate,
  enteredAuthNum,
  setEnteredAuthNum,
  enteredChallengeResponse,
  setEnteredChallengeResponse,
  isAuthFail
}) => {
  const { t } = useTranslation();

  return (
    <Stack gap="20px">
      <Typography variant="subtitle2">
        {t("vaultItemized.personnelAuthInstructions")}
      </Typography>

      <Stack direction="row" gap="20px">
        <Box sx={MDRVaultItemizedStyles.PersonnelAuthentication_AuthNum}>
          <TextField
            disabled={
              !personnelAuthDetails[0]?.auth_number ||
              Boolean(enteredChallengeResponse)
            }
            id="authNumber"
            label={t("vaultItemized.authNumber")}
            fullWidth
            sx={MDRVaultItemizedStyles.PersonnelAuthentication_AuthNum_TF}
            value={enteredAuthNum}
            onChange={(event) => setEnteredAuthNum(event.target.value)}
            InputLabelProps={
              MDRVaultItemizedStyles.PersonnelAuthentication_InputLabelProps
            }
          />
        </Box>

        {personnelAuthDetails[0]?.secret_question !== "" && (
          <Box>
            <TextField
              id="challengeQuestion"
              label={t("vaultItemized.challengeQuestion")}
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              style={MDRVaultItemizedStyles.PersonnelAuthentication_Question}
              value={personnelAuthDetails[0]?.secret_question || ""}
              InputLabelProps={
                MDRVaultItemizedStyles.PersonnelAuthentication_InputLabelProps
              }
            />
          </Box>
        )}

        {personnelAuthDetails[0]?.secret_question !== "" && (
          <Box>
            <TextField
              disabled={
                !personnelAuthDetails[0]?.secret_answer ||
                Boolean(enteredAuthNum)
              }
              label={t("vaultItemized.challengeResponse")}
              id="challengeResponse"
              type="password"
              fullWidth
              sx={MDRVaultItemizedStyles.PersonnelAuthentication_Answer}
              value={enteredChallengeResponse}
              onChange={(event) => {
                const inputValue = event.target.value;
                if (inputValue?.length <= RESPONSE_LIMIT) {
                  setEnteredChallengeResponse(inputValue);
                }
              }}
              InputLabelProps={
                MDRVaultItemizedStyles.PersonnelAuthentication_InputLabelProps
              }
            />
          </Box>
        )}
      </Stack>

      <Stack direction="row" gap="20px" alignItems="center">
        <Button
          disabled={customerSelected === ""}
          variant="contained"
          style={MDRVaultItemizedStyles.PersonnelAuthentication_AuthButton}
          onClick={handleAuthenticate}
          id="authenticateBtn"
        >
          {t("vaultItemized.authenticate")}
        </Button>
        {isAuthFail && (
          <Alert
            severity="error"
            icon={<img src={WarningIcon} alt="Warning" />}
          >
            {t("vaultItemized.authFailedAlert")}
          </Alert>
        )}
      </Stack>
    </Stack>
  );
};

export default PersonnelAuthentication;
