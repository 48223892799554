import { Button } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const PrintLabelModal = ({
  openPrintLabelModal,
  printLabelText,
  onNoClick,
  onYesClick
}) => {
  return (
    <>
      <CommonModal
        open={openPrintLabelModal}
        title={t("continerIssurance.title")}
        body={<>{printLabelText}</>}
        buttons={
          <>
            <Button variant="outlined" onClick={() => onYesClick()}>
              {t("common.yes")}
            </Button>
            <Button variant="contained" onClick={() => onNoClick()}>
              {t("common.no")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default PrintLabelModal;
