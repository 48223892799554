export const routeManagementStyles = {
  routeCardStyle: {
    margin: "20px 0px",
    width: "calc(35vw - 70px)"
  },
  unassignedCardStyle: {
    width: "calc(28vw - 70px)"
  },
  routeCardTitleStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "12px"
  },
  iconBoxStyle: {
    display: "flex"
  },
  iconStyle: {
    cursor: "pointer"
  },
  iconDisableStyle: {
    pointerEvents: "none",
    opacity: "0.5",
    display: "flex"
  },
  findCustomerStyle: {
    marginLeft: "1rem"
  },
  routeCardContentStyle: {
    paddingTop: "0px",
    paddingBottom: "10px !important"
  },
  routeCardHintStyle: {
    fontSize: "12px",
    color: "var(--color-im-grey-38)"
  },
  buttonRowStyle: {
    marginTop: "20px"
  },
  errorStyle: {
    marginBottom: "20px"
  },
  countStyle: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "1rem"
  },
  autoCompleteStyle: {
    height: "325px"
  },
  autoCompleteListStyle: {
    style: { maxHeight: 250 }
  },
  selectStyle: {
    marginTop: "10px"
  }
};
