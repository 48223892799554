import { Button } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const BillingStatusRollbackModal = ({
  rollbackPopupShow,
  rollbackPopupShowOTO,
  cancelClickRollback,
  cancelClickRollbackOTO,
  okClickRollback,
  okClickRollbackOTO
}) => {
  return (
    <>
      <CommonModal
        open={rollbackPopupShow || rollbackPopupShowOTO}
        title={"Rollback Message"}
        body={t("billingStatus.rollbackMsg")}
        buttons={
          <>
            <Button
              variant="outlined"
              onClick={
                rollbackPopupShow
                  ? cancelClickRollback
                  : rollbackPopupShowOTO
                    ? cancelClickRollbackOTO
                    : ""
              }
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={
                rollbackPopupShow
                  ? okClickRollback
                  : rollbackPopupShowOTO
                    ? okClickRollbackOTO
                    : ""
              }
            >
              {t("common.ok")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default BillingStatusRollbackModal;
