import spacing from "styles/spacing";

export const PreferencesStyles = {
  marginLeftAuto: {
    marginLeft: "auto"
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
    padding: "1.5rem 0"
  },
  spanStyle: {
    fontSize: "14px"
  },
  fontSize: "12px",
  dFlex: {
    display: "flex"
  },
  font13: {
    fontSize: "13px",
    color: "var(--color-im-grey-80)"
  },
  margingTop: {
    marginTop: "2rem"
  },
  borderBottom: {
    borderBottom: "1px solid var(--color-im-grey-50)"
  },
  textBold: {
    fontWeight: "bold",
    fontSize: "18px"
  },
  Span: {
    color: "var(--color-im-mid-blue)",
    fontWeight: 500,
    marginLeft: "auto",
    borderBottom: "1px solid var(--color-im-mid-blue)"
  },
  authenticationTextStyle: {
    display: "flex",
    marginLeft: "auto",
    marginTop: "3rem",
    gap: ".5rem"
  },
  noDataText: {
    fotSize: "14px",
    color: "var(--color-im-grey-400)"
  },
  backButton: {
    paddingLeft: "5px"
  },
  tableHeaderColor: {
    backgroundColor: "var(--color-white-100)"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  subTitleStyle: {
    color: "var(--color-im-grey-900)",
    fontSize: "18px"
  },
  accordian: {
    borderRadius: "12px !important",
    "&::before": {
      content: "none"
    }
  },
  tableContainer: {
    borderRadius: "12px"
  },
  nestedTableCell: {
    padding: "0 !important",
    paddingLeft: `${spacing.horizontalMargin47} !important`,
    "& .MuiPaper-elevation": {
      boxShadow: "none",
      borderRadius: 0
    }
  },
  errorAlert: {
    marginTop: "20px"
  },
  filterContainer: {
    padding: "0px 20px 20px 20px",
    width: "350px"
  },
  filterAutoComplete: {
    width: "250px"
  },
  closeIconButton: {
    m: 1,
    position: "absolute",
    right: "0px"
  },
  accordiaDetail: {
    paddingTop: "0"
  },
  buttonStyles: {
    display: "flex",
    gap: "1rem"
  },
  padding: {
    padding: "1rem"
  },
  buttonMargin: {
    margin: "1rem 1rem 0 0"
  },
  width44: {
    width: "44%"
  },
  width6: {
    width: "6%"
  },
  width25: {
    width: "25%"
  },

  chipColor: {
    color: "var(--color-im-mid-blue)",
    borderColor: "var(--color-im-mid-blue)"
  },
  chipDeleteIcon: {
    fill: "var(--color-im-mid-blue)"
  },

  tableIconStyles: {
    display: "flex",
    gap: "1rem",
    alignItems: "center"
  },

  iconsMargin: {
    marginTop: ".4rem",
    cursor: "pointer"
  },
  authCallerWrapper: {
    "&>div": {
      marginTop: "-56px !important"
    }
  },
  tableListStyle: {
    icons: {
      textTransform: "none",
      display: "flex",
      gap: "1rem",
      border: "none"
    },
    buttons: {
      textTransform: "none",
      display: "flex",
      gap: "1rem"
    },
    inputTypeFile: {
      display: "none"
    }
  }
};

export const emailStyles = {
  titleStyle: {
    color: "var(--color-im-grey-900)",
    margin: "20px 0px"
  },
  dividerStyle: {
    margin: "20px 0"
  },
  mbStyle20: {
    marginBottom: "20px"
  }
};
