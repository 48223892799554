import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  SvgIcon,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  EVENT,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH
} from "routing/Paths";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ROLE_ACCESS_CODES,
  SYSTEM_ID_SecureBase,
  VALUE_EMPTY_STRING,
  dateFormat,
  drprocedureAttributeId
} from "utils/constants";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setLocationValue } from "store/slices/disater-module/eventSearch";
import {
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  openMinimizedWindow,
  validateUserHasProgramId
} from "utils/helpers";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";
const EventSearchTable = ({
  resultSet,
  handlePrintClick,
  isFindEnabled,
  CustomBodyRenderComponent,
  localeByBranch,
  setIsLoading,
  customerSelected,
  statusItemSelected,
  drEventId,
  eventTypeSelected,
  fromDate,
  toDate,
  gridPage,
  gridSort,
  selectedRow
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isValidRole = validateUserHasProgramId(ROLE_ACCESS_CODES.code_257);
  const dispatch = useDispatch();

  const columns = [
    {
      name: "dr_event_id",
      label: t("requestSearch.emptyLabelHeader"),
      options: {
        filter: true,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Button
              onClick={() => {
                onNavigateToEdit(value, tableMeta);
              }}
              sx={{ opacity: !isValidRole ? 0.5 : 1 }}
              disabled={!isValidRole}
            >
              <SvgIcon component={ViewIcon} />
            </Button>
          );
        },
        customHeadLabelRender: (columnMeta) => <div>{columnMeta.label}</div>
      }
    },
    {
      name: "customer_number",
      label: t("disasterRecovery.eventSearch.customerLabel"),
      options: {
        sort: true,
        sortDirection: "none"
      }
    },
    {
      name: "dr_event_id",
      label: t("disasterRecovery.eventSearch.drEventIdLabel"),
      options: {
        sort: true,
        sortDirection: "none"
      }
    },
    {
      name: "hot_site_event_identifier",
      label: t("disasterRecovery.eventSearch.hotSiteEventTypeLabel"),
      options: {
        sort: true,
        sortDirection: "none"
      }
    },
    {
      name: "event_type",
      label: t("disasterRecovery.eventSearch.eventTypeLabel"),
      options: {
        sort: true,
        sortDirection: "none"
      }
    },
    {
      name: "dr_event_date",
      label: t("disasterRecovery.eventSearch.drEventDateLabel"),
      options: {
        customBodyRender: (value) => (
          <CustomBodyRenderComponent value={value} locale={localeByBranch} />
        ),
        sort: true,
        sortDirection: "none"
      }
    },
    {
      name: "status",
      label: t("disasterRecovery.eventSearch.statusLabel"),
      options: {
        sort: true,
        sortDirection: "none"
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    sortOrder: gridSort,
    page: gridPage,
    pagination: resultSet?.length && true,
    setRowProps: (row, dataIndex) => {
      const isSelected = selectedRow === row[2];
      return {
        style: {
          backgroundColor: isSelected && "var(--color-im-light-blue-100)"
        }
      };
    },
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={resultSet?.length === 0}
            onClick={() => handlePrintClick()}
          >
            {t("common.print")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          resultSet?.length === 0 && !isFindEnabled
            ? `${t("common.noRecordsFound")}`
            : `${t("disasterRecovery.eventSearch.noRecordsFound")}`
      }
    }
  };

  const onNavigateToEdit = (value, tableMeta) => {
    dispatch(
      setLocationValue({
        customerToFilter: customerSelected,
        statusFilter: statusItemSelected,
        drEventIdFilter: drEventId,
        eventTypeFilter: eventTypeSelected,
        fromDateFilter:
          fromDate === null
            ? VALUE_EMPTY_STRING
            : dayjs(fromDate).format(dateFormat.universalFormat),
        toDateFilter:
          toDate === null
            ? VALUE_EMPTY_STRING
            : dayjs(toDate).format(dateFormat.universalFormat),
        dr_event_id: drEventId ? drEventId : VALUE_EMPTY_STRING,
        gridSort: tableMeta?.tableState?.sortOrder,
        page: tableMeta?.tableState?.page,
        selectedRow: tableMeta?.rowData[2]
      })
    );
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}/${value}`,
      {
        state: {
          customerToFilter: customerSelected,
          statusFilter: statusItemSelected,
          drEventIdFilter: drEventId,
          eventTypeFilter: eventTypeSelected,
          fromDateFilter:
            fromDate === null
              ? VALUE_EMPTY_STRING
              : dayjs(fromDate).format(dateFormat.universalFormat),
          toDateFilter:
            toDate === null
              ? VALUE_EMPTY_STRING
              : dayjs(toDate).format(dateFormat.universalFormat),
          dr_event_id: drEventId ? drEventId : VALUE_EMPTY_STRING,
          gridSort: tableMeta?.tableState?.sortOrder,
          page: tableMeta?.tableState?.page,
          selectedRow: tableMeta?.rowData[2]
        }
      }
    );
  };

  const onNavigateToNewEvent = () => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}`
    );
  };

  // on click DR Procedure button
  const onDRProcedureClick = async () => {
    setIsLoading(true);
    const districtId = getAuthenticatedUserBranch();
    const data = await getGlobalAttributeValue(
      districtId,
      SYSTEM_ID_SecureBase,
      drprocedureAttributeId
    );
    const url = data[0];
    setIsLoading(false);
    openMinimizedWindow(url);
    setIsLoading(false);
    openMinimizedWindow(url);
  };

  return (
    <>
      <Stack id="mainTable">
        <Paper sx={disasterRecoveryStyles.tableStyles?.tablePaper}>
          <div style={disasterRecoveryStyles.commonStyles.widthFull}>
            <TableContainer>
              <MUIDataTable
                columns={columns}
                options={options}
                data={resultSet}
                title={
                  <Typography
                    style={disasterRecoveryStyles.tableStyles.titleStyle}
                  >
                    {resultSet?.length} {t("common.recordsFound")}
                  </Typography>
                }
              />
            </TableContainer>
          </div>
        </Paper>
      </Stack>
      <Divider />
      <Stack direction={"column"} gap={spacing.gap}>
        <Stack direction="row" gap={spacing.gap}>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            style={
              !isValidRole
                ? disasterRecoveryStyles.tableStyles.hiddenButton
                : disasterRecoveryStyles.tableStyles.buttons
            }
            onClick={() => onNavigateToNewEvent()}
          >
            {t("disasterRecovery.eventSearch.newEvent")}
          </Button>
          <Button
            variant="contained"
            size="medium"
            type="button"
            onClick={() => onDRProcedureClick()}
          >
            {t("disasterRecovery.eventSearch.drProcedure")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EventSearchTable;
