const { createTheme } = require("@mui/material");

export const DataTableTheme = createTheme({
  typography: {
    fontFamily: "Inter"
  },
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "42px",
          lineHeight: "30px"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: "52px"
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          height: "56px"
        }
      }
    }
  }
});

export const subTitleStyle = { display: "flex", width: "100%" };

export const titleTwoStyles = {
  color: "var(--color-black)",
  marginLeft: "20%"
};

export const typeColor = {
  color: "var(--color-im-grey-900)"
};
