/**
 * Delete Containers Tab Styles
 */
export const DeleteContainersStyles = {
  CardContent: { padding: 0, "&:last-child": { paddingBottom: 0 } },
  Box4: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    margin: "1rem",
    "> *": { marginLeft: "1rem" },
    "& :first-child": { marginLeft: "0" },
  },
  DataGrid: {
    border: 0,
    "& .--table-colhead-default-fill": {
      backgroundColor: "#F5F5F5",
      textTransform: "uppercase",
    },
  },
};
