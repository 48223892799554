import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const { researchBy } = require("utils/constants/container-processing/Research");

// research page initial state
const initialState = {
  searchOption: researchBy.customer,
  customers: [],
  mediaTypes: [],
  exchangeStatus: [],
  runs: [],
  filters: {
    customer: "",
    customerId: "",
    customerNumber: "",
    mediaType: "",
    containerNumbers: "",
    excludeTransports: false,
    exchangeStatus: -2,
    customerMediaIds: "",
    serviceDate: dayjs(),
    runCode: ""
  },
  containerNumbers: [],
  customerMediaIds: [],
  results: []
};

/**
 * @name containerProcessingResearchSlice
 * @description Preserve state of the Container Processing Research Page filters
 */
export const containerProcessingResearchSlice = createSlice({
  name: "containerProcessingResearch",
  initialState,
  reducers: {
    // reset state to default
    resetState: () => {
      return initialState;
    },
    // store local state values
    preserveState: (state, { payload }) => {
      state.customers = payload.customers;
      state.mediaTypes = payload.mediaTypes;
      state.exchangeStatuses = payload.exchangeStatuses;
      state.runs = payload.runs;
      state.searchOption = payload.searchOption;
      state.filters = payload.filters;
      state.containerNumbers = payload.containerNumbers;
      state.customerMediaIds = payload.customerMediaIds;
      state.results = payload.results;
    }
  }
});

// container processing research actions
export const { preserveState, resetState } =
  containerProcessingResearchSlice.actions;

export default containerProcessingResearchSlice.reducer;
