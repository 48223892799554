import { Button } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const SaveChangesModal = ({
  showSaveChangesModal,
  saveChangesWording,
  onCancelModalClick,
  onNoModalClick,
  onYesModalClick
}) => {
  return (
    <CommonModal
      open={showSaveChangesModal}
      body={`${saveChangesWording}`}
      buttons={
        <>
          <Button onClick={() => onYesModalClick()} variant="outlined">
            {t("common.yes")}
          </Button>
          <Button onClick={() => onNoModalClick()} variant="outlined">
            {t("common.no")}
          </Button>
          <Button onClick={() => onCancelModalClick()} variant="contained">
            {t("common.cancel")}
          </Button>
        </>
      }
    />
  );
};

export default SaveChangesModal;
