import { Box, Button, SvgIcon } from "@mui/material";
import { ReactComponent as Check } from "assets/images/CheckFilled.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { ReactComponent as EditIconBeforeTheSave } from "assets/images/EditIconBeforeSave.svg";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { transportationConstants } from "utils/constants/transportation-module/TransportationConstants";
import { RouteTimeTableStyles } from "./RouteTimeTableStyles.Styles";

const RouteTimeTable = ({
  setSelectedRouteForEdit,
  routeScheduleData,
  routeTableData,
  setRouteTableData,
  transportationMode,
  routeScheduleForm,
  vehicleList,
  employees,
  setSelctedId,
  setSelectedRow,
  selectedRouteForEdit,
  isEditIconDisabled
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleRowClick = (value, tableMeta) => {
    setSelectedIndex(tableMeta?.rowIndex);
    setSelectedRow(routeTableData[tableMeta?.rowIndex]);
  };
  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    jumpToPage: false,
    filter: false,
    print: false,
    search: false,
    sort: false,
    download: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    pagination: false,
    textLabels: {
      body: {
        noMatch:
          routeTableData && routeTableData.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    selectableRowsOnClick: true,
    onRowClick: handleRowClick,
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor:
          selectedRouteForEdit && dataIndex === selectedIndex
            ? RouteTimeTableStyles?.SelectedRowColor
            : ""
      }
    })
  };

  const CommonHeaderlabelRenderer = ({ columnMeta }) => (
    <div style={RouteTimeTableStyles.dataTableStyles.customHeader}>
      {columnMeta.label}
    </div>
  );

  const singleColumnOption = {
    options: {
      customHeadLabelRender: (columnMeta) => (
        <CommonHeaderlabelRenderer columnMeta={columnMeta} />
      )
    }
  };

  const handleEditModalOpen = (value, tableMeta) => {
    const { rowData } = tableMeta;
    setSelectedIndex(tableMeta?.rowIndex);
    const copyOfRouteTableData = JSON.parse(JSON.stringify(routeTableData));
    const selectedRow = copyOfRouteTableData.filter(
      (row) => row.day === rowData[0]
    );
    const selectedEmployee = employees?.data?.find(
      (employee) => employee?.value === selectedRow[0]?.service_rep_employee_id
    );
    const updates = {
      day: selectedRow[0]?.day,
      dayOfWeekCode: selectedRow[0]?.day_of_week_code,
      vehicleTypeCode: selectedRow[0]?.vehicle_type_code,
      vehicleNumber: selectedRow[0]?.vehicle_number,
      startTime: selectedRow[0]?.start_time,
      departTime: selectedRow[0]?.depart_time,
      arriveTime: selectedRow[0]?.arrive_time,
      stopTime: selectedRow[0]?.stop_time,
      serviceRepEmployeeId: selectedEmployee
    };
    routeScheduleForm.setValues(updates);
    setSelectedRouteForEdit(true);
  };

  const ironMountainColumns = [
    {
      name: "day",
      label: t("routeDetails.day"),
      ...singleColumnOption
    },
    {
      name: "start_time",
      label: t("routeDetails.start"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <CommonHeaderlabelRenderer columnMeta={columnMeta} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          const isSelectedRow = selectedIndex === tableMeta.rowIndex;
          const isEdited = routeTableData[tableMeta.rowIndex]?.isEdited;
          const iconComponent =
            isSelectedRow && selectedRouteForEdit
              ? Check
              : isEdited
                ? EditIconBeforeTheSave
                : EditIcon;
          return (
            <>
              <Button
                onClick={() => handleEditModalOpen(value, tableMeta)}
                disabled={isEditIconDisabled()}
                sx={
                  isEditIconDisabled()
                    ? RouteTimeTableStyles.disabledIconsOpacity
                    : null
                }
              >
                <SvgIcon component={iconComponent} />
              </Button>
              {value}
            </>
          );
        }
      }
    },
    {
      name: "depart_time",
      label: t("routeDetails.depart"),
      ...singleColumnOption
    },
    {
      name: "arrive_time",
      label: t("routeDetails.arrive"),
      ...singleColumnOption
    },
    {
      name: "stop_time",
      label: t("routeDetails.stop"),
      ...singleColumnOption
    },
    {
      name: "vehicle_number",
      label: t("routeDetails.veh"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <CommonHeaderlabelRenderer columnMeta={columnMeta} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          const vehicle = vehicleList?.data?.find(
            (vehicle) => vehicle?.value === value
          );
          const vehicleTypeCode = vehicle?.label;
          return <>{vehicleTypeCode}</>;
        }
      }
    },

    {
      name: "service_rep_employee",
      label: t("routeDetails.serviceRepEmployeeId"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <CommonHeaderlabelRenderer columnMeta={columnMeta} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          const label = value?.value || value; // Extract the label property
          return label;
        }
      }
    }
  ];

  const thirdPartyColumns = [
    {
      name: "day",
      label: t("routeDetails.day"),
      ...singleColumnOption
    },

    {
      name: "depart_time",
      label: t("routeDetails.depart"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <CommonHeaderlabelRenderer columnMeta={columnMeta} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          const isSelectedRow = selectedIndex === tableMeta.rowIndex;
          const isEdited = routeTableData[tableMeta.rowIndex]?.isEdited;
          const iconComponent =
            isSelectedRow && selectedRouteForEdit
              ? Check
              : isEdited
                ? EditIconBeforeTheSave
                : EditIcon;
          return (
            <>
              <Button
                onClick={() => handleEditModalOpen(value, tableMeta)}
                disabled={isEditIconDisabled()}
                sx={
                  isEditIconDisabled()
                    ? RouteTimeTableStyles.disabledIconsOpacity
                    : null
                }
              >
                <SvgIcon component={iconComponent} />
              </Button>
              {value}
            </>
          );
        }
      }
    }
  ];

  return (
    <Box sx={RouteTimeTableStyles.dataTableStyles.boxStyles}>
      <MUIDataTable
        options={options}
        columns={
          transportationMode === transportationConstants.mode.thirdParty
            ? thirdPartyColumns
            : ironMountainColumns
        }
        data={routeTableData}
        noRecord={true}
      />
    </Box>
  );
};

export default RouteTimeTable;
