import i18n from "i18n";

export const sourceTree = {
  unassigned: "UA",
  currentRoute: "CR"
};

export const nodeTypes = {
  time: "T",
  parentCustomer: "L",
  customer: "C",
  route: "R"
};

export const slotNames = {
  onCall: "On Call",
  onCallTime: "9999"
};

export const rootIds = {
  unassignedTasks: 500000,
  currentRouteTasks: 3500000
};

export const customerTypes = {
  parentCustomer: 1,
  independentCustomer: 2,
  dependentCustomer: 3
};

export const modalMinWidth = "60%";

export const spaceCode = "Space";

export const reportTypes = {
  day: i18n.t("routeManagement.day"),
  week: i18n.t("routeManagement.week")
};
