import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LABEL_TEMPLATE_MAINTENANCE,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH
} from "routing/Paths";
import { useGetAllCustomersQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  downloadReportOptions,
  ERROR_TYPES,
  N_CHECKER,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER
} from "utils/constants";
import { zeroChecker } from "utils/constants/container-processing/Distribution";
import {
  labelTemplateConstants,
  templateLimit
} from "utils/constants/customer-service-information/LabelTemplate";
import {
  findErrorMessage,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import { labelTemplateStyles } from "./LabelTemplateSearchStyles";
import LabelTemplateTable from "./LabelTemplateTable";

const LabelTemplateSearchScreen = () => {
  const navigate = useNavigate();
  const { currentBranch, auth } = useSelector(selectAuth);
  const [customer, setCustomer] = useState({
    customer: t("common.all"),
    customerId: ""
  });
  const [templateSearchData, setTemplateSearchData] = useState({
    volserLength: "",
    customerNumber: "",
    mediaType: ""
  });
  const [findBtnStatus, setFindBtnStatus] = useState(false);
  const [templateTableData, setTempLateTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [labelTemplateCustomers, setLabelTemplateCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const selectedValue = downloadReportOptions[0];

  const handleRowSelect = (value, tableMeta) => {
    setSelectedRows({
      ...templateTableData[tableMeta?.rowIndex],
      rowIndex: tableMeta?.rowIndex
    });
    const selectedCustomers = labelTemplateCustomers?.filter(
      (obj) =>
        obj?.label_template_id ===
        templateTableData[tableMeta?.rowIndex]?.label_template_id
    );
    let labels = {};
    labels.customers = selectedCustomers;
    labels.isEdit = true;
    labels.descr = templateTableData[tableMeta?.rowIndex]?.descr;
    labels.template = templateTableData[tableMeta?.rowIndex];
    if (templateTableData[tableMeta?.rowIndex]?.label_template_id)
      localStorage.setItem(
        labelTemplateConstants.labelTemplateId,
        JSON.stringify(labels)
      );
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${LABEL_TEMPLATE_MAINTENANCE}`
    );
  };
  const newLabelTemplate = () => {
    localStorage.clear();
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${LABEL_TEMPLATE_MAINTENANCE}`
    );
  };
  const handleChange = (e, newValue) => {
    setFindBtnStatus(false);
    setCustomer({
      customer: newValue?.label,
      customerId: newValue?.value
    });
  };
  const handleVolserChange = (e, newValue) => {
    const inputValue = e.target.value;
    // Allow only numbers and limit the value to 99
    if (/^\d*$/.test(inputValue) && Number(inputValue) <= templateLimit) {
      setFindBtnStatus(false);
      setTempLateTableData([]);
      setTemplateSearchData({
        ...templateSearchData,
        volserLength: inputValue
      });
    }
  };
  const handleCustomerNumberChange = (e, newValue) => {
    setFindBtnStatus(false);
    setTempLateTableData([]);
    if (templateSearchData?.customerNumber === VALUE_Y) {
      setTemplateSearchData({
        ...templateSearchData,
        customerNumber: VALUE_N
      });
    } else {
      setTemplateSearchData({
        ...templateSearchData,
        customerNumber: VALUE_Y
      });
    }
  };
  const clear = () => {
    setCustomer(t("common.all"));
    setTempLateTableData([]);
    setTemplateSearchData({
      volserLength: "",
      customerNumber: "",
      mediaType: ""
    });
    setFindBtnStatus(false);
  };

  const handleMediaTypeChange = (e, newValue) => {
    setFindBtnStatus(false);
    setTempLateTableData([]);
    if (templateSearchData?.mediaType === VALUE_Y) {
      setTemplateSearchData({
        ...templateSearchData,
        mediaType: VALUE_N
      });
    } else {
      setTemplateSearchData({
        ...templateSearchData,
        mediaType: VALUE_Y
      });
    }
  };
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  function removeDuplicates(arr) {
    const seen = new Set();
    return arr.filter((item) => {
      if (seen.has(item?.template)) {
        return false;
      } else {
        seen.add(item?.template);
        return true;
      }
    });
  }
  const fetchFind = async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        customer_id:
          customer.customerId === ALL_SELECTION.value
            ? ""
            : customer.customerId,
        cust_num_flag: templateSearchData?.customerNumber,
        media_type_flag: templateSearchData?.mediaType,
        volser_length: templateSearchData?.volserLength
      };

      const ReturnLabelTemplates = await getResponseData(
        reqBody,
        CF_URLS.labelTemplateSearch.getReturnLabelTemplates,
        2
      );
      if (ReturnLabelTemplates.data[0]) {
        const sortedData = ReturnLabelTemplates.data[0]?.sort(
          (a, b) => parseInt(a.order) - parseInt(b.order)
        );
        const uniqueTemplate = removeDuplicates(sortedData);
        setLabelTemplateCustomers(ReturnLabelTemplates.data[1]);
        setLoading(false);
        setTempLateTableData(uniqueTemplate);
        setFindBtnStatus(true);
      } else {
        setLoading(false);
        setThrowError(true);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  // download button click in download modal
  const onDownloadClick = async () => {
    setLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.value,
        customer_id:
          customer.customerId === ALL_SELECTION.value
            ? ""
            : customer.customerId,
        cust_num_flag:
          templateSearchData?.customerNumber === Y_CHECKER
            ? Y_CHECKER
            : N_CHECKER,
        media_type_flag:
          templateSearchData?.mediaType === Y_CHECKER ? Y_CHECKER : N_CHECKER,
        volser_length: !templateSearchData?.volserLength
          ? zeroChecker
          : templateSearchData?.volserLength,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });

      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.customerInformation.labelTemplateReport}`
      );

      if (!reportResponse.success) {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(reportResponse.error);
        setLoading(false);
      }
    } catch (e) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {throwError && (
        <Box my={spacing.gap}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Box>
      )}
      {loading && <CircularLoaderFullPage loading={loading} />}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <CustomersField
            options={[
              {
                label: t("common.all"),
                value: ""
              },

              ...customers
            ]}
            value={customer.customer}
            handleOnChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label={t("labelTemplateSearch.volserLength")}
            fullWidth
            variant="outlined"
            value={templateSearchData?.volserLength}
            onChange={(e) => handleVolserChange(e)}
            placeholder={t("labelTemplateSearch.placeHolderVolser")}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={templateSearchData?.customerNumber === VALUE_Y}
                onChange={(e) => handleCustomerNumberChange(e)}
                color="primary"
              />
            }
            label={t("labelTemplateSearch.customerNumberLabel")}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={templateSearchData?.mediaType === VALUE_Y}
                onChange={(e) => handleMediaTypeChange(e)}
                color="primary"
              />
            }
            label={t("labelTemplateSearch.mediaTypeLabel")}
          />
        </Grid>
      </Grid>

      <br />
      <Stack direction="row" gap={spacing.gap}>
        <Button id="new-btn-filter" variant="outlined" onClick={() => clear()}>
          {t("common.clear")}
        </Button>
        <Button
          id="save-btn-filter"
          variant="contained"
          type="submit"
          disabled={findBtnStatus}
          onClick={() => fetchFind()}
        >
          {t("common.find")}
        </Button>
      </Stack>
      <br />
      <LabelTemplateTable
        tableData={templateTableData}
        setShowPrintModal={setShowPrintModal}
        selectedRow={selectedRows}
        handleRowSelection={handleRowSelect}
      />
      <br />
      <Typography variant="body2" style={labelTemplateStyles.symbol}>
        {t("labelTemplateSearch.symbol")}
      </Typography>
      <br />
      <Typography variant="body2">
        {t("labelTemplateSearch.cust")} {t("labelTemplateSearch.customer")}{" "}
        &nbsp;&nbsp;{t("labelTemplateSearch.mt")} ={" "}
        {t("labelTemplateSearch.mediaType")} &nbsp;&nbsp;{" "}
        {t("labelTemplateSearch.v")} = {t("labelTemplateSearch.volser")}
        &nbsp;&nbsp; {`{}`}= {t("labelTemplateSearch.ignorePosition")}{" "}
        &nbsp;&nbsp; &lt;&gt; = {t("labelTemplateSearch.insertCharacters")}{" "}
        &nbsp;&nbsp; [] ={t("labelTemplateSearch.requiredCharacters")}
      </Typography>
      <Divider style={labelTemplateStyles.divider} />

      <Stack direction="row" gap={spacing.gap}>
        <Button
          variant="outlined"
          color="primary"
          style={labelTemplateStyles.newButton}
          onClick={() => newLabelTemplate()}
        >
          {t("common.new")}
        </Button>
      </Stack>
      <DownloadPopup
        throwError={showErrorAlert}
        setThrowError={setShowErrorAlert}
        throwErrorMessage={showErrorAlertMsg}
        setThrowErrorMessage={setShowErrorAlertMsg}
        title={t("common.downloadReport")}
        showPrintModal={showPrintModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => setShowPrintModal((prev) => !prev)}
        onDownloadClick={() => onDownloadClick()}
      />
    </>
  );
};
export default LabelTemplateSearchScreen;
