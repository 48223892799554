import { Box, Button, Stack, Typography } from "@mui/material";
import { FullCircularLoader } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import { DEACTIVATE, errorMsgs, N_CHECKER, Y_CHECKER } from "utils/constants";

const CustomerMoveSearchModal = ({
  actionType,
  handleYesClick,
  setIsModalVisible,
  isModalVisible
}) => {
  const { t } = useTranslation();

  const [titleMsg, setTitleMsg] = useState("");
  const [loading, setLoading] = useState(true);

  // Function to fetch and update title message

  useEffect(() => {
    const getTitleMessage = async () => {
      setLoading(true);
      try {
        const titleMessage = await getMessageFromCode(errorMsgs.errorCode80493);
        const toReplaceText =
          actionType === DEACTIVATE
            ? t("customerMoveSearch.deactivate").toLowerCase()
            : t("customerMoveSearch.activate").toLowerCase();
        let replacedText = titleMessage[0]?.descr.replace("|", toReplaceText);
        setTitleMsg(replacedText);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getTitleMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType]);

  return (
    <CommonModal
      open={isModalVisible}
      title={t("common.confirm")}
      body={
        <Box
          display="flex"
          flexDirection="column"
          gap={spacing.verticalMargin20}
        >
          <Stack>
            {loading ? (
              <FullCircularLoader />
            ) : (
              <Typography>{titleMsg}</Typography>
            )}
          </Stack>
        </Box>
      }
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              handleYesClick(actionType === DEACTIVATE ? N_CHECKER : Y_CHECKER);
              setIsModalVisible(false);
            }}
          >
            {t("common.yes")}
          </Button>
          <Button variant="contained" onClick={() => setIsModalVisible(false)}>
            {t("common.no")}
          </Button>
        </>
      }
    />
  );
};

export default CustomerMoveSearchModal;
