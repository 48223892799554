import {
  getAllMediaTypes,
  getAllRequestStatuses,
  getAuthNumber,
  getAuthorizationDetail,
  getAuthorizationPersonnelsAuthentications,
  getCustomerDataById,
  getDestructRequestDetails,
  getDestructionRequestDetail,
  getGlobalAttributeValueById,
  getGlobalAttributeValueByTypeId,
  getLocaleByBranch,
  getMessageById,
  getMessageByIdFromMessage,
  getPersonnelAuthCust,
  getServiceLocationByCustomer,
  getServiceLocations,
  getValidateChallengeResponse
} from "services/common";
import { TAG_TYPES } from "utils/constants";
import { baseApi } from "./baseApi";
/**
 * @module commonApi common RTK Query endpoints
 */
export const commonApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getServiceLocation: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getServiceLocations(props);
          return {
            data: response?.map(({ number }) => ({
              label: number,
              value: number
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.serviceLocation, id: arg.customerId }]
          : [TAG_TYPES.serviceLocation]
    }),
    getLocaleByBranch: builder.mutation({
      queryFn: async () => {
        try {
          const response = await getLocaleByBranch();
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.branch]
    }),
    getAuthorizationPersonnelsAuthentications: builder.mutation({
      queryFn: async (props) => {
        try {
          const response =
            await getAuthorizationPersonnelsAuthentications(props);
          return {
            data: {
              personnelId: response?.personnel_id,
              name: `${response?.first_name} ${response?.last_name}`,
              authNumber: response?.auth_number,
              secretQuestion: response?.secret_question,
              secretAnswer: response?.secret_answer,
              customerList: response?.customer_list
            }
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.authorizationPersonnel, id: arg.personnelId }]
          : [TAG_TYPES.authorizationPersonnel]
    }),
    getMediaTypes: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAllMediaTypes(props);
          return {
            data: response
              ?.map(({ descr, media_type_id }) => ({
                label: descr,
                value: media_type_id
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    //get service location by customer
    getServiceLocationByCustomer: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getServiceLocationByCustomer(props);

          return {
            data: response?.map(({ customer_id, number }) => ({
              label: number?.trim() ? number?.trim() : "",
              value: customer_id ? customer_id : ""
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestStatus]
    }),
    //get destruction details by query
    getDestructRequestDetails: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getDestructRequestDetails(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desReqComment]
    }),
    //get destruction details by mutation
    getDestructRequestDetailsBy: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getDestructionRequestDetail(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desReqComment]
    }),
    //get customer data by id
    getCustomerDataById: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getCustomerDataById(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desReqComment]
    }),
    //get message by id
    getMessageById: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getMessageById(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desReqComment]
    }),
    //get message by id from message collection
    getMessageByIdFromMessage: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getMessageByIdFromMessage(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desReqComment]
    }),
    getPersonnelAuthCust: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getPersonnelAuthCust(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.personnelAuthCust]
    }),
    getAuthNumber: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAuthNumber(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.authNumber]
    }),
    getAuthorizationDetail: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAuthorizationDetail(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.authorizationDetail]
    }),
    // get the customer Details
    getValidateChallengeResponse: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getValidateChallengeResponse(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.challengeResponse]
    }),
    getRequestStatuses: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAllRequestStatuses(props);
          return {
            data: response?.map(({ status_code, request_status_id }) => ({
              label: status_code,
              value: request_status_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getGlobalAttributeValueById: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getGlobalAttributeValueById(props);
          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.globalAttribute, id: arg }]
          : [TAG_TYPES.globalAttribute]
    }),
    getGlobalAttributeValueByTypeId: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getGlobalAttributeValueByTypeId(props);
          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.globalAttribute, id: arg }]
          : [TAG_TYPES.globalAttribute]
    })
  })
});
