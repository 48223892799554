import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  N_CHECKER,
  Y_CHECKER
} from "utils/constants";

import { setAllEmployeeData } from "store/slices/administration/notificationConfig";
import spacing from "styles/spacing";
import { NotificationStyles } from "./NotificationStyles";
const NotificationTable = ({
  selectedContainerRow,
  setSelectedContainerRow,
  isResetGrid,
  selectedRows,
  setSelectedRows,
  setUnselectedRows,
  setIsCreating
}) => {
  const dispatch = useDispatch();
  const { employeeData } = useSelector((state) => state.notificationConfig);

  const handleRowSelect = (event, tableMeta, rowData) => {
    let arr = [];
    employeeData.forEach((item) => {
      arr.push({
        ...item,
        isCheck:
          rowData?.recipient_id === item?.recipient_id
            ? !item?.isCheck
            : item?.isCheck
      });
    });
    dispatch(setAllEmployeeData(arr));
    const isSelected = event.target.checked;

    if (isSelected) {
      setSelectedRows((prevRows) => [...prevRows, rowData]);
      setUnselectedRows((prevRows) =>
        prevRows.filter((row) => row.recipient_id !== rowData.recipient_id)
      );
    } else {
      setUnselectedRows((prevRows) => [...prevRows, rowData]);
      setSelectedRows((prevRows) =>
        prevRows.filter((row) => row.recipient_id !== rowData.recipient_id)
      );
    }
  };

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    viewFilter: true,
    sort: employeeData?.length > 0,
    responsive: "standard",
    overflowX: "auto",
    page: 0,
    download: employeeData?.length > 0,
    search: employeeData?.length > 0,
    filter: employeeData?.length > 0,
    viewColumns: employeeData?.length > 0,
    customFilterDialogFooter: (currentFilterList, applyFilters) => {
      return <Box sx={NotificationStyles.filterOverlay}></Box>;
    },
    customToolbar: () => (
      <>
        {employeeData?.length > 0 && (
          <Grid
            item
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            gap={spacing.gap}
          >
            <Button
              variant="outlined"
              size="medium"
              type="button"
              disabled={null}
              onClick={() => {
                setIsCreating(true);
              }}
              sx={NotificationStyles.btn}
            >
              {t("common.new")}
            </Button>
          </Grid>
        )}
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          isResetGrid && employeeData.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (rowData, dataIndex) => {
      const dataRow = employeeData[dataIndex];
      return {
        style: {
          backgroundColor:
            dataRow?.is_selected_flag === Y_CHECKER
              ? NotificationStyles?.selectedRowColor
              : ""
        }
      };
    }
  };

  const columns = [
    {
      name: "",
      label: "",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          let row = tableMeta?.currentTableData[tableMeta.rowIndex];
          let dataIndex = row.index;
          let rowData = employeeData[dataIndex];
          return (
            <>
              <Checkbox
                checked={rowData?.isCheck}
                onChange={(event) => {
                  handleRowSelect(event, tableMeta, rowData);
                }}
                disabled={
                  employeeData?.length > 0 &&
                  rowData?.is_editable_flag === N_CHECKER
                    ? true
                    : false
                }
              />
            </>
          );
        }
      }
    },

    {
      name: "recipient_name",
      label: t("notifications.name"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : t("common.unknown")}</>;
        },
        filter: false,
        sortThirdClickReset: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: NotificationStyles.columnStyle
        })
      }
    },

    {
      name: "alert_recipient_type_descr",
      label: t("notifications.type"),
      options: {
        sortThirdClickReset: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: NotificationStyles.columnStyle
        })
      }
    },

    {
      name: "email_address",
      label: t("notifications.address"),
      options: {
        sortThirdClickReset: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: NotificationStyles.columnStyle
        }),
        filter: false
      }
    },

    {
      name: "alert_action_descr",
      label: t("notifications.action"),
      options: {
        sortThirdClickReset: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: NotificationStyles.columnStyle
        })
      }
    }
  ];

  return (
    <Grid item xs={12} lg={3} gap={spacing.gap}>
      <MUIDataTable
        columns={columns}
        data={employeeData}
        options={options}
        title={
          isResetGrid ? (
            <Typography style={NotificationStyles.titleStyle}>
              {employeeData?.length} {t("common.recordsFound")}
            </Typography>
          ) : (
            ""
          )
        }
      />
    </Grid>
  );
};

export default NotificationTable;
