import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  DEFAULT_LOCALE,
  ERROR_TYPES,
  VALUE_ALL,
  VALUE_EMPTY_STRING,
  dateFormat
} from "utils/constants";

import {
  customerFilter,
  findErrorMessage,
  formatDateByLocale,
  getAuthenticatedUserBranch,
  getResponseData,
  getSortedData,
  getUserName
} from "utils/helpers";
import EventSearchFilter from "./EventSearchFilter";
import DownloadReportModal from "./EventSearchPrintModal";
import { filterSchema } from "./schema";

import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import {
  EVENT_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH
} from "routing/Paths";
import {
  id,
  statusToFilter
} from "utils/constants/disaster-module/EventSearchConstants";
import { toDoLabelStyle } from "./DisasterRecoveryStyles";
import EventSearchTable from "./EventSearchTable";

const EventSearchLayout = () => {
  const { t } = useTranslation();

  const { currentBranch, auth, timeZoneOffset, localeByBranch } =
    useSelector(selectAuth);

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
    currentBranch?.district_id ||
    VALUE_EMPTY_STRING
  );

  // initial values ----------
  const customerSelectionAll = ALL_SELECTION;
  const statusItemsSelectionAll = ALL_SELECTION;
  const eventTypesSelectionAll = ALL_SELECTION;

  const initialValues = {
    customer: t("common.all"),
    statusItem: t("common.all"),
    eventType: t("common.all")
  };

  const initFilterState = {
    fromDate: dayjs(Date.now()),
    toDate: dayjs(Date.now())
  };

  const SearchFilterForm = useFormik({
    initialValues: initFilterState,
    validationSchema: filterSchema
  });

  // react states ----------
  const [isLoading, setIsLoading] = useState(false);

  const [statusItems, setStatusItems] = useState([statusItemsSelectionAll]);
  const [eventTypes, setEventTypes] = useState([eventTypesSelectionAll]);

  const [customerSelected, setCustomerSelected] = useState(ALL_SELECTION);
  const [statusItemSelected, setStatusItemSelected] = useState(ALL_SELECTION);
  const [eventTypeSelected, setEventTypeSelected] = useState(ALL_SELECTION);
  const [drEventId, setDrEventId] = useState("");
  const [isFindEnabled, setIsFindEnabled] = useState(true);
  const [isFindClicked, setIsFindClicked] = useState(false);
  const [isClearClicked, setIsClearClicked] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [gridPage, setGridPage] = useState(0);
  const [gridSort, setGridSort] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [prevValues, setPrevValues] = useState(false);

  const [resultSet, setResultSet] = useState([]);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  const [customers, setCustomers] = useState([]);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [printRequestBody, setPrintRequestBody] = useState(null);
  const [showTodoLabel, setShowTodoLabel] = useState(false);
  const { statusId } = useParams();
  const location = useLocation();
  const [timeZoneOffsetChanged, setTimeZoneOffsetChanged] = useState(false); // timezone offset value changed
  const currentDate = useMemo(() => {
    let date = dayjs.utc(new Date());
    if (timeZoneOffset !== null) {
      date = date.utcOffset(parseFloat(timeZoneOffset));
    }

    setTimeZoneOffsetChanged((prevState) => !prevState);
    return date;
  }, [timeZoneOffset]);

  // formik ----------

  const handleSubmit = async (values, actions) => {
    setIsFindClicked(true);
    setIsFindEnabled(false);
    setGridPage(0);
    setGridSort({});
    setSelectedRow(null);
    setResultSet([]);

    // Continue with Formik's default form submission logic
    actions.setSubmitting(false);
  };

  const mrdForm = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit
  });

  const CustomBodyRenderComponent = ({ value, locale }) => {
    const [formattedDate, setFormattedDate] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        const result = await formatDateByLocale(value, locale, false);
        setFormattedDate(result);
      };
      fetchData();
    }, [value, locale]);

    return formattedDate;
  };

  // useEffects ----------

  // load from TODO
  useEffect(() => {
    if (statusId === id) {
      setShowTodoLabel(true);
      fetchEventInformation(statusId);
    }
    window.history.replaceState(
      null,
      VALUE_EMPTY_STRING,
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT_SEARCH}`
    );
    // eslint-disable-next-line
  }, [statusId]);

  useEffect(() => {
    if (mainDistrictId) {
      setIsLoading(true);
      Promise.allSettled([
        getCustomers(),
        fetchEventTypes(),
        fetchStatusItems()
      ]).then(() => {
        if (location?.state) {
          Promise.allSettled([
            setCustomerSelected(location?.state?.customerToFilter),
            setStatusItemSelected(location?.state?.statusFilter),
            setDrEventId(location?.state?.drEventIdFilter),
            setEventTypeSelected(location?.state?.eventTypeFilter),
            SearchFilterForm.setFieldValue("fromDate", location?.state?.fromDateFilter ? dayjs(location?.state?.fromDateFilter) : currentDate),
            SearchFilterForm.setFieldValue("toDate", location?.state?.toDateFilter ? dayjs(location?.state?.toDateFilter) : currentDate),
            setFromDate(location?.state?.fromDateFilter ? dayjs(location?.state?.fromDateFilter) : null),
            setToDate(location?.state?.toDateFilter ? dayjs(location?.state?.toDateFilter) : null),
            setGridPage(location?.state?.page),
            setGridSort(location?.state?.gridSort),
            setSelectedRow(location?.state?.selectedRow)
          ]).then(() => {
            setPrevValues(true);
          });
        } else {
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [mainDistrictId]);

  useEffect(() => {
    if (prevValues) {
      setIsFindClicked(true);
    }// eslint-disable-next-line
  }, [prevValues]);

  useEffect(() => {
    if (Object.keys(SearchFilterForm.errors).length > 0) {
      setIsFindEnabled(false);
    } else {
      setIsFindEnabled(true);
    }
    // eslint-disable-next-line
  }, [fromDate, toDate, SearchFilterForm.errors]);

  useEffect(() => {
    if (isFindClicked) {
      fetchEventInformation(VALUE_EMPTY_STRING);
    }
    // eslint-disable-next-line
  }, [isFindClicked]);

  useEffect(() => {
    setIsFindEnabled(true);
    setResultSet([]);
    // eslint-disable-next-line
  }, [customerSelected]);

  // reset the page
  useEffect(() => {
    if (isClearClicked) {
      pageReset();
    }
    // eslint-disable-next-line
  }, [isClearClicked]);

  // CF calls ----------

  const getCustomers = async () => {
    try {
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);

      //Filter customers
      result = customerFilter(result);

      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));

        setCustomers((prev) => data);
      } else {
        setCustomers([]);
        setThrowErrorMessage(ERROR_TYPES.FAILED);
        setThrowError(true);
      }
    } catch (e) {
      setCustomers([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const fetchEventTypes = async () => {
    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getEventTypes}`,
        1
      );

      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(
            ({ dr_event_type, dr_event_type_code }) => ({
              label: dr_event_type,
              value: dr_event_type_code
            })
          );

          const fillList = [
            ALL_SELECTION,
            ...getSortedData(mapping, "label", "asc")
          ];
          setEventTypes((prev) => fillList);
        }
      } else if (data?.error) {
        setEventTypes((prev) => [ALL_SELECTION]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setEventTypes((prev) => [ALL_SELECTION]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const fetchStatusItems = async () => {
    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId
      });

      const data = await getResponseData(
        reqBody,
        CF_URLS.disasterRecovery.getStatusItems,
        1
      );

      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(
            ({ descr, dr_event_status_id }) => ({
              label: descr,
              value: dr_event_status_id
            })
          );
          const fillList = [
            ALL_SELECTION,
            ...getSortedData(mapping, "label", "asc")
          ];
          setStatusItems((prev) => fillList);
        }
      } else if (data?.error) {
        setStatusItems((prev) => [ALL_SELECTION]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setStatusItems((prev) => [ALL_SELECTION]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const fetchEventInformation = async (status) => {
    setIsLoading(true);

    try {
      const reqBody = {
        main_district_id: mainDistrictId,
        customer_id:
          customerSelected?.value === VALUE_ALL
            ? VALUE_EMPTY_STRING
            : customerSelected?.value,
        dr_event_status_id:
          statusItemSelected?.value === VALUE_ALL
            ? VALUE_EMPTY_STRING
            : statusItemSelected?.value,
        dr_event_type_code:
          eventTypeSelected?.value === VALUE_ALL
            ? VALUE_EMPTY_STRING
            : eventTypeSelected?.value,
        from_date:
          fromDate === null
            ? VALUE_EMPTY_STRING
            : fromDate.format(dateFormat.universalFormat),
        to_date:
          toDate === null
            ? VALUE_EMPTY_STRING
            : toDate.format(dateFormat.universalFormat),
        dr_event_id: drEventId ? drEventId : VALUE_EMPTY_STRING
      };

      setPrintRequestBody({ ...reqBody, user_name: getUserName(auth?.user) });

      const data = await getResponseData(
        JSON.stringify(reqBody),
        CF_URLS.disasterRecovery.fetchEventInformation,
        1
      );
      if (data?.data && data?.data[0]) {
        if (data?.data[0]?.length === 0) {
          setResultSet([]);
        } else {
          if (status === VALUE_EMPTY_STRING) {
            setResultSet(
              (prev) =>
                getDateSortedData(
                  data?.data[0],
                  "customer_number",
                  "dr_event_date"
                ) || []
            );
          } else {
            const sortedData =
              getDateSortedData(
                data?.data[0],
                "customer_number",
                "dr_event_date"
              ) || [];
            const filteredResultSet = sortedData.filter((item) =>
              statusToFilter.includes(item.status)
            );
            setResultSet(filteredResultSet);
          }
        }
      } else if (data?.error) {
        setResultSet([]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setResultSet([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
      setIsFindClicked(false);
      setIsFindEnabled(false);
    }
  };

  const getDateSortedData = (data, sortField, sortDateField) => {
    let sortedData = data.sort((a, b) => {
      // Sort by customer number
      if (a[sortField].toLowerCase() < b[sortField].toLowerCase()) return -1;
      if (a[sortField].toLowerCase() > b[sortField].toLowerCase()) return 1;

      // If customer number are equal, sort by date
      const date1 = new Date(a[sortDateField]);
      const date2 = new Date(b[sortDateField]);
      if (date1 < date2) return -1;
      if (date1 > date2) return 1;
      // If date are equal, sort by event id
      return a["dr_event_id"] - b["dr_event_id"];
    });
    return sortedData;
  };

  // handler functions ----------
  const handleCustomerChange = (event, newValue) => {
    if (newValue === null) {
      setCustomerSelected(ALL_SELECTION);
    } else {
      newValue = { label: newValue.label, value: newValue.value?.trim() };
      setCustomerSelected(newValue);
      setStatusItemSelected(ALL_SELECTION);
    }
  };

  const pageReset = () => {
    setCustomerSelected(ALL_SELECTION);
    setDrEventId("");
    setStatusItemSelected(ALL_SELECTION);
    setEventTypeSelected(ALL_SELECTION);
    setFromDate(null);
    setToDate(null);
    SearchFilterForm.resetForm();

    setIsFindEnabled(true);
    setIsFindClicked(false);

    setResultSet([]);

    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    setIsClearClicked(false);
  };

  const handlePrintClick = () => {
    setIsPrintModalOpen(true);
  };

  // props ----------
  const sectionSearchFilterProps = {
    mrdForm,
    customers: [customerSelectionAll, ...customers],
    customerSelected,
    handleCustomerChange,
    statusItems,
    statusItemSelected,
    setStatusItemSelected,
    eventTypes,
    eventTypeSelected,
    setEventTypeSelected,
    drEventId,
    setDrEventId,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    isFindEnabled,
    setIsFindEnabled,
    setResultSet,
    setIsClearClicked,
    SearchFilterForm,
    localeByBranch,
    DEFAULT_LOCALE,
    currentDate,
    timeZoneOffsetChanged
  };

  const tableSearchResultsProps = {
    resultSet,
    handlePrintClick,
    isFindEnabled,
    CustomBodyRenderComponent,
    localeByBranch,
    setIsLoading,
    customerSelected,
    statusItemSelected,
    drEventId,
    eventTypeSelected,
    fromDate,
    toDate,
    gridPage,
    gridSort,
    selectedRow
  };
  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {/*Report download modal */}
      {isPrintModalOpen && (
        <DownloadReportModal
          isVisible={isPrintModalOpen}
          setIsVisible={setIsPrintModalOpen}
          printRequestBody={printRequestBody}
          currentBranch={currentBranch}
          setIsLoading={setIsLoading}
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
        />
      )}

      <Stack
        direction="column"
        gap={spacing.gap}
        marginTop={spacing.margingTop20}
      >
        {throwError && !isPrintModalOpen && (
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
        )}

        {/* ---------- Filter Section ----------  */}
        <EventSearchFilter {...sectionSearchFilterProps} />

        {showTodoLabel && (
          <Grid item md={24} lg={12} sx={8}>
            <Typography
              id="toDOInProgessLabel"
              variant="h6"
              sx={toDoLabelStyle}
            >
              {t("disasterRecovery.eventSearch.toDoInProgressLabel")}
            </Typography>
          </Grid>
        )}

        {/* ---------- Table to Display Search Results ---------- */}
        <EventSearchTable {...tableSearchResultsProps} />
      </Stack>
    </>
  );
};

export default EventSearchLayout;
