export const ServiceWindowsTableStyles = {
  muiDataTableStyles: {
    boxShadow:
      "0px 2px 1px -1px var(--color-on-table), 0px 1px 1px 0px var(--color-on-box), 0px 1px 3px 0px var(--color-on-box-shadow)",
    borderRadius: "8px",
  },
  tableHeaderLabel: {
    fontWeight: "700",
    color: "var(--color-on-table-Header)",
  },
  tableBodyLabel: {
    color: "var(--color-on-table-body)",
    paddingLeft: "0px",
  },
  chip: {
    m: "0px 8px 8px 0px",
  },
  typography: {
    fontSize: "16px",
  },
  jumpToPage: {
    color: "var(--color-on-table-Header)",
  },
  customHeader: {
    fontWeight: "700",
    fontSize: "13px !important",
    color: "var(--color-on-table-Header)",
    backgroundColor: "var( --color-on-custome-header)",
  },
  printButton: {
    mb: "15px",
    mt: "10px",
  },
  customerHeaderTh: { paddingInline: "50px", paddingBottom: "25px" },
  customDataTd: { display: 'flex', justifyContent: 'space-between', width: '130px' },
  customDataTd1: { display: 'flex', justifyContent: 'space-between', width: '200px', paddingLeft: '20px' },
  LabelPadding: { paddingInline: "5px" },
  LabelPadding2: { paddingInline: "50px" },
  PaddingLeft: { paddingLeft: "50px" },
  PaddingTop1: { paddingTop: "44px" },
  PaddingTop2: { paddingTop: "20px" },
  Color: { color: "var(--color-grey)" },
  Color2: { color: "var(--color-blue" },
  DividerStyle: { border: "1px solid var(--color-light-grey)", margin: "0" },
  JustifyContent: { justifyContent: "flex-start", marginLeft: "2.5%" },
  TextTransform: { textTransform: "none" },
  TextFieldStyle: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--color-blue)",
      },
    },
  },
  PaperWidth: { width: "100%" },
  TableContainerHeight: { maxHeight: 440 },
  svgIconStyle : {height: '20px', lineHeight: '20px'}
};
