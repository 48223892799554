import { Stack } from "@mui/material";
import CreateDisributionList from "components/open-media-processing/create-distribution-list/CreateDisributionList";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const CreateDistributionList = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("createDistributionList.pageTitle")} />
      <CreateDisributionList />
    </Stack>
  );
};

export default CreateDistributionList;
