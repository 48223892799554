const BOX_MAIN = {
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
};

const CONTAINER_MAIN = {
  mt: "20px",
  mb: "20px",
  flexGrow: "1",
  maxWidth: "none !important",
  paddingLeft: "70px !important",
  paddingRight: "70px !important"
};

const topbarStyles = {
  display: "flex",
  alignItems: "flex-end",
  cursor: "pointer",
  justifyContent: "flex-end",
  margin: "5px 10px 20px 0"
};
const LAYOUT_STYLES = { BOX_MAIN, CONTAINER_MAIN, topbarStyles };

export default LAYOUT_STYLES;
