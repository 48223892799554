import {
  getAllCustomers,
  getAllRequestTypes,
  getAllRoutes,
  getAllSearchRequestStatuses,
  searchRequest
} from "services/common";
import { TAG_TYPES } from "utils/constants";
import { baseApi } from "../baseApi";

/**
 * @module requestSearchApi request search RTK Query endpoints
 */
export const requestSearchApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCustomers: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllCustomers(props);
          return {
            data: response?.map(({ number, name, customer_id }) => ({
              label: `${number} - ${name.trim()}`,
              value: customer_id,
              number: number
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customer]
    }),
    getAllRequestTypes: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllRequestTypes(props);
          return {
            data: response?.map(({ descr, request_type_id }) => ({
              label: descr,
              value: request_type_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.reqestType]
    }),
    getAllRoutes: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllRoutes(props);
          return {
            data: response?.map(({ route_code }) => ({
              label: route_code,
              value: route_code
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.route]
    }),
    getAllRequestStatuses: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllSearchRequestStatuses(props);
          return {
            data: response?.map(({ status_code, request_status_id }) => ({
              label: status_code,
              value: request_status_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestStatus]
    }),
    searchRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await searchRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    })
  })
});
