import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { vendorMangementStyles } from "./VendorMangementStyles";

const VendorManagementSearch = ({
  vendorSearchForm,
  cityList,
  stateList,
  vendorNameList,
  handleReset,
  isFindButtonDisabled,
  setIsFindButtonDisabled
}) => {
  const { t } = useTranslation();

  const handleInputChange = (name, value) => {
    vendorSearchForm.setFieldValue(name, value);
    setIsFindButtonDisabled(false);
  };

  return (
    <form
      onSubmit={vendorSearchForm.handleSubmit}
      onReset={vendorSearchForm.handleReset}
    >
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="vendor-name-filter"
                disablePortal
                name={t("vendorManagement.name")}
                disabled={null}
                options={vendorNameList}
                size="medium"
                value={vendorSearchForm.values.name || t("common.all")}
                onChange={(event, newValue) =>
                  handleInputChange("name", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label={t("vendorManagement.name")} />
                )}
                sx={vendorMangementStyles.fieldStyle}
              />
            </Grid>

            <Grid item xs={3}>
              {" "}
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="vendor-state-filter"
                disablePortal
                name={t("vendorManagement.province")}
                disabled={null}
                options={stateList}
                size="medium"
                value={
                  vendorSearchForm.values.stateProvinceId || t("common.all")
                }
                onChange={(event, newValue) =>
                  handleInputChange("stateProvinceId", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("vendorManagement.province")}
                  />
                )}
                sx={vendorMangementStyles.fieldStyle}
              />
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="vendor-city-filter"
                disablePortal
                name={t("vendorManagement.city")}
                disabled={null}
                options={cityList}
                size="medium"
                value={vendorSearchForm.values.city || t("common.all")}
                onChange={(event, newValue) =>
                  handleInputChange("city", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label={t("vendorManagement.city")} />
                )}
                sx={vendorMangementStyles.fieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid id="vendor-clear-button-grid" item xs="auto">
              <Button
                id="vendor-clear-button"
                variant="outlined"
                type="button"
                onClick={handleReset}
              >
                {t("common.clear")}
              </Button>
            </Grid>
            <Grid id="vendor-find-button-grid" item xs="auto">
              <Button
                id="vendor-find-button"
                variant="contained"
                type="submit"
                disabled={isFindButtonDisabled}
                onClick={null}
              >
                {t("common.find")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default VendorManagementSearch;
