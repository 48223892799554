export const CustomerMoveStyles = {
  mainMargin: { marginBottom: "20px", marginTop: "20px" },
  paddingTop: "10px",
  minWidth: "1440px",
  selectedColor: "var(--color-im-light-blue-100)",
  completeColor: "var(--color-im-mid-green)",
  customHeader: {
    fontWeight: "700",
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.6)",
    backgroundColor: "rgba(245, 245, 245, 1)"
  },
  muiDataTableStyles: {
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  BottomButtonsContainer: {
    paddingTop: "30px",
    borderTop: 1,
    borderColor: "#0000001F"
  },
  completeColorStatus: "#DCECC3",
  rowSelectedColorStatus: "var(--color-im-light-blue-100)",
  tableBodyHeight: "200px",
  tableBodyMaxHeight: "400px"
};
