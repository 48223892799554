import styled from "@emotion/styled";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { t } from "i18next";
import {
  newTypes,
  nodeIdPrefix,
  nodeTypes
} from "utils/constants/vault-configuration/VaultConfiguration";
import { VaultConfigurationStyles } from "./vaultConfigurationStyles";

const StyledTreeItem = styled(TreeItem)({
  "& .MuiTreeItem-iconContainer": VaultConfigurationStyles.iconStyles
});

const VaultConfigurationTree = ({
  physicalVaults,
  rows,
  racks,
  shelves,
  loaders,
  expanded,
  setLoaders,
  setExpanded,
  setIsPopulated,
  setNumberField,
  setInitialNumberField,
  getRackUnderRow,
  getShelfUnderRack,
  getShelfProperties,
  getRowUnderPhysicalVault,
  selected,
  onSelectedItemsChange,
  createMode,
  newlyAdded,
  loadedVaults
}) => {
  const handleExpand = (event, nodeId, isExpanded) => {
    if (isExpanded) {
      setExpanded((prev) => [...prev, nodeId]);
    } else {
      setExpanded((prev) => prev.filter((id) => id !== nodeId));
    }
  };

  const handleItemClick = (e, node) => {
    if (createMode) return;
    let nodeId = node?.nodeId;
    setIsPopulated(false);
    switch (node.type) {
      case nodeTypes.vault: {
        let vaultId = node?.data?.physicalVaultId;
        setNumberField(parseInt(node?.data?.physicalVaultNumber, 10));
        setInitialNumberField(parseInt(node?.data?.physicalVaultNumber, 10));
        if (rows.some((row) => row.physicalVaultId === vaultId)) return;
        setLoaders((prev) => [...prev, node.nodeId]);
        getRowUnderPhysicalVault(vaultId, nodeId);
        break;
      }

      case nodeTypes.row: {
        let rowId = node?.data?.rowId;
        let vaultId = node?.data?.physicalVaultId;
        setNumberField(parseInt(node?.data?.rowNumber, 10));
        setInitialNumberField(parseInt(node?.data?.rowNumber, 10));
        if (
          racks.some(
            (row) => row.physicalVaultId === vaultId && row.rowId === rowId
          )
        )
          return;
        setLoaders((prev) => [...prev, node.nodeId]);
        getRackUnderRow(vaultId, rowId, nodeId);
        break;
      }
      case nodeTypes.rack: {
        let rowId = node?.data?.rowId;
        let vaultId = node?.data?.physicalVaultId;
        let rackId = node?.data?.rackId;
        setNumberField(parseInt(node?.data?.rackNumber, 10));
        setInitialNumberField(parseInt(node?.data?.rackNumber, 10));
        if (
          shelves.some(
            (row) =>
              row.physicalVaultId === vaultId &&
              row.rowId === rowId &&
              row.rackId === rackId
          )
        )
          return;
        setLoaders((prev) => [...prev, node.nodeId]);
        getShelfUnderRack(vaultId, rowId, rackId, nodeId);
        break;
      }

      case nodeTypes.shelf:
        setNumberField(parseInt(node?.data?.shelfNumber, 10));
        setInitialNumberField(parseInt(node?.data?.shelfNumber, 10));
        getShelfProperties(node?.data?.shelfId);
        break;
      default:
        break;
    }
  };

  const buildTreeData = (vaults, rows, racks, shelves) => {
    const moveNewlyAddedToEnd = (arr, newlyAdded) => {
      const newlyAddedItems = arr.filter((item) =>
        newlyAdded.includes(item.nodeId)
      );
      const otherItems = arr.filter(
        (item) => !newlyAdded.includes(item.nodeId)
      );
      return [...otherItems, ...newlyAddedItems];
    };

    const moveNewlyAddingToEnd = (arr, newlyAdding) => {
      const newlyAddedItems = arr.filter((item) => {
        let id = item.nodeId.split("_").at(-1);
        return newlyAdding.includes(id);
      });
      const otherItems = arr.filter((item) => {
        let id = item.nodeId.split("_").at(-1);
        return !newlyAdding.includes(id);
      });
      return [...otherItems, ...newlyAddedItems];
    };
    let newKeys = Object.values(newTypes);

    const treeData = vaults.map((vault) => {
      const vaultRows = rows
        .filter((row) => row.physicalVaultId === vault.physicalVaultId)
        .map((row) => {
          const rowRacks = racks
            .filter(
              (rack) =>
                rack.rowId === row.rowId &&
                rack.physicalVaultId === vault.physicalVaultId
            )
            .map((rack) => {
              const rackShelves = shelves
                .filter(
                  (shelf) =>
                    shelf.rackId === rack.rackId &&
                    shelf.rowId === row.rowId &&
                    shelf.physicalVaultId === vault.physicalVaultId
                )
                .map((shelf) => ({
                  nodeId: `${nodeIdPrefix.shelf}${vault.physicalVaultNumber}_${row.rowNumber}_${rack.rackNumber}_${shelf.shelfNumber}`,
                  label:
                    shelf.shelfNumber !== newTypes.shelf
                      ? `${t("vaultConfiguration.shelf")} ${shelf.shelfNumber}`
                      : `${t("common.new")} ${t("vaultConfiguration.shelf")}`,
                  type: nodeTypes.shelf,
                  data: shelf
                }));

              return {
                nodeId: `${nodeIdPrefix.rack}${vault.physicalVaultNumber}_${row.rowNumber}_${rack.rackNumber}`,
                label:
                  rack.rackNumber !== newTypes.rack
                    ? `${t("vaultConfiguration.rack")} ${rack.rackNumber}`
                    : `${t("common.new")} ${t("vaultConfiguration.rack")}`,
                type: nodeTypes.rack,
                data: rack,
                children: moveNewlyAddingToEnd(
                  moveNewlyAddedToEnd(rackShelves, newlyAdded),
                  newKeys
                )
              };
            });

          return {
            nodeId: `${nodeIdPrefix.row}${vault.physicalVaultNumber}_${row.rowNumber}`,
            label:
              row.rowNumber !== newTypes.row
                ? `${t("vaultConfiguration.row")} ${row.rowNumber}`
                : `${t("common.new")} ${t("vaultConfiguration.row")}`,
            type: nodeTypes.row,
            data: row,
            children: moveNewlyAddingToEnd(
              moveNewlyAddedToEnd(rowRacks, newlyAdded),
              newKeys
            )
          };
        });

      return {
        nodeId: `${nodeIdPrefix.vault}${vault.physicalVaultNumber}`,
        label:
          vault?.physicalVaultNumber !== newTypes.vault
            ? `${t("vaultConfiguration.vault")} ${vault.physicalVaultNumber}`
            : `${t("common.new")} ${t("vaultConfiguration.vault")}`,
        type: nodeTypes.vault,
        data: vault,
        children: moveNewlyAddingToEnd(
          moveNewlyAddedToEnd(vaultRows, newlyAdded),
          newKeys
        )
      };
    });

    return [
      {
        nodeId: nodeTypes.branch,
        label: t("vaultConfiguration.branch"),
        type: nodeTypes.branch,
        children: moveNewlyAddingToEnd(
          moveNewlyAddedToEnd(treeData, newlyAdded),
          newKeys
        )
      }
    ];
  };

  const renderTreeItems = (nodes) => {
    return nodes.map((node) => (
      <>
        <StyledTreeItem
          key={node.nodeId}
          itemId={node.nodeId}
          onClick={(e) => handleItemClick(e, node)}
          label={
            <Box sx={VaultConfigurationStyles.nodeLableContainer}>
              {loaders.includes(node.nodeId) ? (
                <Box sx={VaultConfigurationStyles.loaderStyle}>
                  <CircularProgress
                    size={VaultConfigurationStyles.loaderSize}
                  />
                </Box>
              ) : (
                node?.type === nodeTypes.vault &&
                !loadedVaults.includes(node.nodeId) && (
                  <ChevronRightIcon sx={VaultConfigurationStyles.chevronIcon} />
                )
              )}
              {node.label}
            </Box>
          }
        >
          {node.children ? renderTreeItems(node.children) : null}
        </StyledTreeItem>
      </>
    ));
  };
  return (
    <Box pt={1}>
      <SimpleTreeView
        expandedItems={expanded}
        onItemExpansionToggle={handleExpand}
        onSelectedItemsChange={onSelectedItemsChange}
        selectedItems={selected}
      >
        {renderTreeItems(buildTreeData(physicalVaults, rows, racks, shelves))}
      </SimpleTreeView>
    </Box>
  );
};

export default VaultConfigurationTree;
