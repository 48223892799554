import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { FullCircularLoader } from "components/core";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import { ERROR_TYPES, VALUE_EMPTY_STRING } from "utils/constants";
import { exchLocationTypeCode } from "utils/constants/customer-service-information/service-windows/ServiceWindowsConstants";
import { findErrorMessage, getResponseData } from "utils/helpers";
import { ServiceWindowsTableStyles } from "./ServiceWindowsTableStyles";
import { GridCloseIcon } from "@mui/x-data-grid";
import spacing from "styles/spacing";

export default function ServiceWindowAdd({
  handleClose,
  open,
  setScheduleToSave,
  customer,
  daysOfWeek,
  customers,
  customerServiceWindows,
}) {
  const [dayOfWeek, setDayOfWeek] = useState();
  const { currentBranch } = useSelector(selectAuth);
  const [serviceLoc, setServiceLoc] = useState();
  const [window1From, setWindow1From] = useState();
  const [window1To, setWindow1To] = useState();
  const [window2From, setWindow2From] = useState();
  const [window2To, setWindow2To] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [validation, setValidation] = useState({
    day: false,
    serviceLoc: false,
    win1From: false,
    win1To: false,
  });
  const [cslCustomerScheduleId, setcslCustomerScheduleId] = useState();
  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const selectedBranchId = currentBranch?.district_id;

  const [location, setLocation] = useState([]);

  useEffect(() => {
    setDayOfWeek();
    setLocation();
  }, [customer]);

  const handleAdd = () => {
    let isday = false;
    let isserviceloc = false;
    let isfrom = false;
    let isto = false;
    let isvalid = true;

    if (
      dayOfWeek === VALUE_EMPTY_STRING ||
      dayOfWeek === null ||
      dayOfWeek === undefined
    ) {
      isday = true;
      isvalid = false;
    }
    if (
      serviceLoc === VALUE_EMPTY_STRING ||
      serviceLoc === null ||
      serviceLoc === undefined
    ) {
      isserviceloc = true;
      isvalid = false;
    }
    if (
      window1From === VALUE_EMPTY_STRING ||
      window1From === null ||
      window1From === undefined
    ) {
      isfrom = true;
      isvalid = false;
    }
    if (
      window1To === VALUE_EMPTY_STRING ||
      window1To === null ||
      window1To === undefined
    ) {
      isto = true;
      isvalid = false;
    }

    setValidation({
      day: isday,
      serviceLoc: isserviceloc,
      win1From: isfrom,
      win1To: isto,
    });

    if (isvalid) {
      const dataToAdd = {
        mainDistrictId: selectedBranchId,
        customerId: customer[0].value,
        cslCustomerId: serviceLoc,
        dayOfWeekCode: dayOfWeek,
        startTimeWindow1: getTime(window1From.$H, window1From.$m),
        endTimeWindow1: getTime(window1To.$H, window1To.$m),
        startTimeWindow2: window2From
          ? getTime(window2From.$H, window2From.$m)
          : "",
        endTimeWindow2: window2To ? getTime(window2To.$H, window2To.$m) : "",
        cslCustomerSchedId: cslCustomerScheduleId,
        exchLocationTypeCode: exchLocationTypeCode,
        isUnsaved: true,
        id: Date.now(),
      };
      setScheduleToSave(dataToAdd, true);
      setDayOfWeek();
      setLocation();
      setServiceLoc();
      setWindow1From();
      setWindow1To();
      setWindow2From();
      setWindow2To();
      if (typeof handleClose === "function") handleClose();
    }
  };

  const serviceLocationOnChange = (value) => {
    const loc = location?.find((item) => item.value.serviceLoc === value).value;
    if (loc) {
      setServiceLoc(loc.serviceLoc);
      let time = dayjs();
      if (loc.startTimeWindow1 && loc.startTimeWindow1 !== VALUE_EMPTY_STRING) {
        time = time
          .hour(loc.startTimeWindow1.slice(0, 2))
          .minute(loc.startTimeWindow1.slice(2));
        setWindow1From(time);
      }
      if (loc.endTimeWindow1 && loc.endTimeWindow1 !== VALUE_EMPTY_STRING) {
        time = time
          .hour(loc.endTimeWindow1.slice(0, 2))
          .minute(loc.endTimeWindow1.slice(2));
        setWindow1To(time);
      }
      if (loc.startTimeWindow2 && loc.startTimeWindow2 !== VALUE_EMPTY_STRING) {
        time = time
          .hour(loc.startTimeWindow2.slice(0, 2))
          .minute(loc.startTimeWindow2.slice(2));
        setWindow2From(time);
      } else {
        setWindow2From();
      }
      if (loc.endTimeWindow2 && loc.endTimeWindow2 !== VALUE_EMPTY_STRING) {
        time = time
          .hour(loc.endTimeWindow2.slice(0, 2))
          .minute(loc.endTimeWindow2.slice(2));
        setWindow2To(time);
      } else {
        setWindow2To();
      }
      if (
        loc.customerScheduleId &&
        loc.customerScheduleId !== VALUE_EMPTY_STRING
      ) {
        setcslCustomerScheduleId(loc.customerScheduleId);
      } else {
        setcslCustomerScheduleId(VALUE_EMPTY_STRING);
      }
    }
  };

  const getTime = (hours, minutes) => {
    return (
      "" +
      (hours < 10 ? "0" + hours : hours) +
      (minutes < 10 ? "0" + minutes : minutes)
    );
  };

  const fetchCustomerLoc = async (e) => {
    try {
      setIsloading(true);
      const getServiceLocationReqBody = {
        main_district_id: selectedBranchId,
        customer_id: customer[0].value,
        day_of_week_code: e,
      };
      const serviceApiData = await getResponseData(
        getServiceLocationReqBody,
        CF_URLS.serviceWindows?.customerGetServiceLocations,
        3
      );
      const location =
        serviceApiData.data[0]?.map((ele) => {
          const requiredcustomer = customers.filter(
            (ele2) => ele2.value === ele.Customer_Id
          )[0];

          return {
            label:
              requiredcustomer.number +
              "-" +
              ele.Route_Code +
              "-" +
              ele.Service_Time.slice(0, 2) + ":" + ele.Service_Time.slice(2),
            value: {
              serviceLoc: requiredcustomer.value,
              startTimeWindow1: ele.Start_Time_Window1,
              startTimeWindow2: ele.Start_Time_Window2,
              endTimeWindow1: ele.End_Time_Window1,
              endTimeWindow2: ele.End_Time_Window2,
              customerScheduleId: ele.Customer_Schedule_Id,
            },
          };
        }) || [];
      const lastOccurenceCurrentServiceLoc = customerServiceWindows.filter(
        (c) => c.tripInformation?.[1] === customer?.[0]?.value
      );
      lastOccurenceCurrentServiceLoc[
        lastOccurenceCurrentServiceLoc.length - 1
      ] = {
        startTimeWindow1:
          lastOccurenceCurrentServiceLoc[
            lastOccurenceCurrentServiceLoc.length - 1
          ]?.window1?.[0],
        endTimeWindow1:
          lastOccurenceCurrentServiceLoc[
            lastOccurenceCurrentServiceLoc.length - 1
          ]?.window1?.[1],
        startTimeWindow2:
          lastOccurenceCurrentServiceLoc[
            lastOccurenceCurrentServiceLoc.length - 1
          ]?.window2?.[0],
        endTimeWindow2:
          lastOccurenceCurrentServiceLoc[
            lastOccurenceCurrentServiceLoc.length - 1
          ]?.window2?.[1],
        serviceLoc: customers.find((item) => item.value === customer[0].value)
          ?.value,
        customerScheduleId: VALUE_EMPTY_STRING,
      };
      location.unshift({
        label:
          customer?.[0]?.label.split("-").shift() +
          `${customer?.[0]?.Route_Code ? -customer?.[0]?.Route_Code : ""}` +
          `${customer?.[0]?.Service_Time ? -customer?.[0]?.Service_Time : ""}`,
        value:
          lastOccurenceCurrentServiceLoc &&
          lastOccurenceCurrentServiceLoc[
            lastOccurenceCurrentServiceLoc?.length - 1
          ],
      });
      setLocation(location);
      setIsloading(false);
    } catch (e) {
      setErrorMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsErr(true);
      setIsloading(false);
    }
  };

  return (
    <Box>
      {isLoading && <FullCircularLoader />}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={ServiceWindowsTableStyles.Color2}>
          {t("serviceWindows.addCustomerServiceTitle")}
        </DialogTitle>
        <Divider style={ServiceWindowsTableStyles.DividerStyle} />
        <DialogContent>
          <Collapse
            in={isErr}
            sx={{
              marginBottom: isErr ? spacing.verticalMargin20 : 0,
            }}
          >
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsErr(false);
                  }}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMsg}
            </Alert>
          </Collapse>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                error={validation.day}
                select
                label={t("serviceWindows.labelDayOfWeek")}
                value={dayOfWeek}
                onChange={(e) => {
                  setDayOfWeek(e.target.value);
                  fetchCustomerLoc(e.target.value);
                }}
                fullWidth
                margin="normal"
                sx={ServiceWindowsTableStyles.TextFieldStyle}
              >
                {daysOfWeek.map((day) => (
                  <MenuItem key={day.day} value={day.value}>
                    {day.day}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                error={validation.serviceLoc}
                label={t("serviceWindows.serviceLocationLabel")}
                value={serviceLoc}
                onChange={(e) => serviceLocationOnChange(e.target.value)}
                fullWidth
                margin="normal"
                sx={ServiceWindowsTableStyles.TextFieldStyle}
              >
                {location && location.length ? (
                  location
                    .map((loc) => ({
                      label: loc.label,
                      value: loc.value.serviceLoc,
                    }))
                    .map((location) => (
                      <MenuItem key={location.label} value={location.value}>
                        {location.label}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem
                    value={`${customer?.[0]?.label.split("-").shift()} ${
                      customer?.[0]?.Route_Code
                        ? -customer?.[0]?.Route_Code
                        : ""
                    } ${
                      customer?.[0]?.Service_Time
                        ? -customer?.[0]?.Service_Time
                        : ""
                    }`}
                    key={customer?.[0]?.label.split("-").shift()}
                  >
                    {`${customer?.[0]?.label.split("-").shift()} ${
                      customer?.[0]?.Route_Code
                        ? -customer?.[0]?.Route_Code
                        : ""
                    } ${
                      customer?.[0]?.Service_Time
                        ? -customer?.[0]?.Service_Time
                        : ""
                    }`}
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={ServiceWindowsTableStyles.Color}>
                {t("serviceWindows.window1Label")}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["TimePicker"]}
                  sx={ServiceWindowsTableStyles.PaddingTop2}
                >
                  <TimeField
                    error={validation.win1From}
                    fullWidth
                    label={t("serviceWindows.fromLabel")}
                    format={t("serviceWindows.timeFormatLabel")}
                    value={window1From}
                    onChange={(e) => {
                      setWindow1From(e);
                    }}
                    disabled={serviceLoc !== customer?.[0].value}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["TimePicker"]}
                  sx={ServiceWindowsTableStyles.PaddingTop1}
                >
                  <TimeField
                    error={validation.win1To}
                    fullWidth
                    label={t("serviceWindows.toLabel")}
                    format={t("serviceWindows.timeFormatLabel")}
                    value={window1To}
                    onChange={(e) => setWindow1To(e)}
                    disabled={serviceLoc !== customer?.[0].value}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={ServiceWindowsTableStyles.Color}>
                {t("serviceWindows.window2Label")}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["TimePicker"]}
                  sx={ServiceWindowsTableStyles.PaddingTop2}
                >
                  <TimeField
                    fullWidth
                    label={t("serviceWindows.fromLabel")}
                    format={t("serviceWindows.timeFormatLabel")}
                    value={window2From}
                    onChange={(e) => setWindow2From(e)}
                    disabled={serviceLoc !== customer?.[0].value}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["TimePicker"]}
                  sx={ServiceWindowsTableStyles.PaddingTop1}
                >
                  <TimeField
                    fullWidth
                    label={t("serviceWindows.toLabel")}
                    format={t("serviceWindows.timeFormatLabel")}
                    value={window2To}
                    onChange={(e) => setWindow2To(e)}
                    disabled={serviceLoc !== customer?.[0].value}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider style={ServiceWindowsTableStyles.DividerStyle} />
        <DialogActions sx={ServiceWindowsTableStyles.JustifyContent}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={ServiceWindowsTableStyles.TextTransform}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleAdd}
            sx={ServiceWindowsTableStyles.TextTransform}
          >
            {t("serviceWindows.addButtonLabel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
