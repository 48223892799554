import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { ReactComponent as ArrowDownGrey } from "assets/images/arrow-down.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EMAIL_LIMIT, EMAIL_REGEX } from "../Constants";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

const isValidEmail = (email) => {
  // Regular expression for validating an Email
  const emailRegex = EMAIL_REGEX;
  return emailRegex.test(email);
};

const EmailAndWeight = ({
  isReadOnly,
  notifyEmail,
  handleEmailChange,
  isAuthSuccess,
  mrdForm,
  units,
  isWeightUpdatable,
  setEmailValid,
  unitOfMeasureObj,
  setUnitOfMeasureObj,
  weight,
  setWeight
}) => {
  const { t } = useTranslation();
  const [emailError, setEmailError] = useState(false);

  const validateAndHandleEmailChange = (event) => {
    const newValue = event.target.value;

    // Split emails by semicolon
    const emailsArray = newValue.split(";").map((email) => email.trim());

    // Check if all emails are valid
    const isValid = emailsArray.every(isValidEmail);

    // Update the state to show/hide the error
    setEmailError(!isValid && notifyEmail !== "");
    isValid ? setEmailValid(true) : setEmailValid(false);

    handleEmailChange(newValue);
  };

  useEffect(() => {
    validateAndHandleEmailChange({ target: { value: notifyEmail } });
    // eslint-disable-next-line
  }, [notifyEmail]);

  return (
    <Stack direction="row" gap="20px">
      <Box sx={MDRVaultItemizedStyles.EmailAndWeight_ContainerBox}>
        <TextField
          disabled={(!isReadOnly && !isAuthSuccess) || isReadOnly}
          label={t("vaultItemized.notifyViaEmail")}
          id="notifyViaEmail"
          fullWidth
          required
          sx={MDRVaultItemizedStyles.EmailAndWeight_Email_TF}
          value={notifyEmail}
          onChange={validateAndHandleEmailChange}
          InputLabelProps={
            MDRVaultItemizedStyles.EmailAndWeight_InputLabelProps
          }
          inputProps={{ maxLength: EMAIL_LIMIT }}
          error={(isAuthSuccess && notifyEmail === "") || emailError}
          helperText={
            (isAuthSuccess &&
              notifyEmail === "" &&
              t("vaultItemized.emailRequiredError")) ||
            (emailError && t("vaultItemized.invalidEmailError"))
          }
        />
      </Box>

      <Box sx={MDRVaultItemizedStyles.EmailAndWeight_Weight_Box}>
        <TextField
          disabled={!isWeightUpdatable}
          label={t("vaultItemized.weight")}
          id="weight"
          fullWidth
          sx={MDRVaultItemizedStyles.EmailAndWeight_Weight_TF}
          value={weight || ""}
          required={isWeightUpdatable && unitOfMeasureObj?.label !== ""}
          onChange={(event) => {
            // Allow only numeric input
            const numericValue = event.target.value.replace(/[^0-9]/g, "");

            mrdForm.handleChange({
              target: { name: "weight", value: numericValue }
            });
            setWeight(numericValue);
          }}
          InputLabelProps={
            MDRVaultItemizedStyles.EmailAndWeight_InputLabelProps
          }
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*"
          }}
        />
      </Box>

      <Box sx={MDRVaultItemizedStyles.EmailAndWeight_Unit_Box}>
        <Autocomplete
          clearIcon={<CloseIcon styles />}
          popupIcon={!isWeightUpdatable ? <ArrowDownGrey /> : <ArrowDown />}
          sx={MDRVaultItemizedStyles.MUI_Autocomplete_Icon_Styles}
          disablePortal
          disabled={!isWeightUpdatable}
          id="selectUnit"
          options={["", ...units] || []}
          value={unitOfMeasureObj?.label || ""}
          onChange={(e, newValue) => {
            mrdForm?.handleChange({
              target: { name: "unitOfMeasure", value: newValue?.label || "" }
            });

            setUnitOfMeasureObj(newValue || { label: "", value: "" });
          }}
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value && option?.label === value?.label
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name="unitOfMeasure"
              label={t("vaultItemized.selectUnit")}
              required={isWeightUpdatable && weight !== ""}
              InputLabelProps={
                MDRVaultItemizedStyles.PersonnelSelectionVault_InputLabelProps
              }
              value={mrdForm.values?.unitOfMeasure}
              sx={MDRVaultItemizedStyles.EmailAndWeight_Unit}
            />
          )}
        />
      </Box>
    </Stack>
  );
};

export default EmailAndWeight;
