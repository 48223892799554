import { Button } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const VaultDeleteModal = ({
  title,
  bodyOpen,
  bodyMessage,
  handleDeleteYes,
  handleDeleteNo
}) => {
  return (
    <CommonModal
      open={bodyOpen}
      body={bodyMessage}
      title={title}
      buttons={
        <>
          <Button onClick={handleDeleteYes} variant="outlined">
            {t("logicalVaultDetails.yes")}
          </Button>
          <Button onClick={handleDeleteNo} variant="contained">
            {t("logicalVaultDetails.no")}
          </Button>
        </>
      }
    />
  );
};

export default VaultDeleteModal;
