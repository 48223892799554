import { pickersDayClasses } from "@mui/x-date-pickers";
export const openMediaResearchStyles = {
  alertSize: { width: "100%" },
  alertPadding: { marginBottom: "16px" },
  backButton: {
    paddingLeft: "5px"
  },
  compareButton: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  compareButtonLoader: {
    color: "var(--color-white)"
  },
  checkboxWrapper: { display: "flex", alignItems: "flex-start" }
};

export const getBoxStyles = (bgColorScheduledSelectDate) => ({
  backgroundColor: bgColorScheduledSelectDate && "var(--color-im-orange)",
  borderRadius: "12px"
});

export const getPickersDayStyles = (bgColorNonScheduledSelectDate) => ({
  [`&&.${pickersDayClasses.selected}`]: {
    backgroundColor: "var(--color-im-green)"
  },
  [`&&.${pickersDayClasses.dayWithMargin}`]: {
    backgroundColor: bgColorNonScheduledSelectDate
      ? "var(--color-im-orange)"
      : "normal",
    border: "none"
  }
});
