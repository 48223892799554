const CorrectiveActionRequestSearchStyles = {
    typography: {
        position: "absolute",
        top: "28%",
        right: "6%",
        color: "var(--color-im-mid-blue)",
        textDecoration: "underline",
        display: "flex",
        alignItems: "center",
    },
    img: {
        width: "20px",
    },
    typographySpan: {
        marginLeft: 10,
    },
    formControlLabel: {
        mt: 1,
        ml: 2,
    },
    buttonContainer: {
        mt: 2,
        display: "flex",
        justifyContent: "flex-start",
        gap: 2,
        mb: 2,
    },
    tableContainer: {
        mt: 4,
        borderRadius: "12px",
    },
    tableHead: {
        bgcolor: "var(--color-white-100)",
    },
    tableCellHeader: {
        font: "Inter",
        fontWeight: 500,
        fontSize: 13,
        color: "var(--color-im-grey-900)",
    },
    titleMessage: {
        color: "var(--color-im-grey-900)",
    },
    tableCellMessage: {
        textAlign: "center",
        color: "var(--color-im-grey-300)",
    },
    radioGroup: {
        mt: 1,
        gap: 1,
    },
    buttonDisabled: {
        mt: 2,
        gap: 2,
        display: "flex",
        justifyContent: "flex-start",
    },
};

export default CorrectiveActionRequestSearchStyles;
