export const ContainerProcessingStyles = {
  workManagementTabBox1: { width: "100%" },
  modalMainGrid: { width: "592px" },
  tablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  completeColor: "var(--color-im-mid-green)",
  selectedColor: "var(--color-im-light-blue-100)",
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  marginStyles: {
    margin: "10px"
  },
  marginTop25: {
    marginTop: "25px"
  },
  marginTop15: {
    marginTop: "15px"
  },
  fontColor: {
    color: "var(--color-im-grey-400)"
  },
  treeCardContent: { padding: "153px" },
  tabBox: { width: "100%" },
  tabBox1: { borderBottom: 1, borderColor: "divider", marginBottom: "1rem" },
  formControllabelSx: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  formControllabelStart: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start"
  },
  BorderStyles: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "1rem 0rem 0rem 0rem",
    margin: "1rem 0rem .5rem 0rem"
  },
  BorderStyles1: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: ".5rem ",
    marginTop: ".4rem "
  },
  authentiCateText: {
    marginLeft: "auto"
  },
  textStyles: {
    color: "var(--color-im-mid-blue)",
    textDecoration: "underline"
  },
  flexItems: {
    display: "flex",
    alignItems: "center",
    padding: "1rem 0"
  },
  buttonStyles: {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap"
  },
  rearchTransportBorderBottom: {
    borderBottom: "1px solid var(--color-im-grey-50)"
  },
  tableRecords: {
    display: "flex",
    padding: ".7rem"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  gridTextBorder: {
    border: "1px solid  var(--color-im-grey-50)",
    height: "18vh",
    width: "100%",
    padding: ".7rem",
    overflowY: "auto"
  },
  tableIconButton: {
    width: "18px !important",
    height: "18px !important"
  },
  missingMediaSnapBar: {
    Container: {
      position: "relative"
    },

    Span: {
      color: "var(--color-im-info-content)",
      fontWeight: 500,
      paddingLeft: "10px"
    },

    ContainerStack: {
      position: "relative",
      maxHeight: "200px",
      backgroundColor: "var(--color-im-info-fill)",
      color: "blue",
      borderRadius: "10px"
    },

    SecondBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px"
    },

    ErrorIcon: { color: "var(--color-im-info-outlined)" },
    CloseIcon: { color: "var(--color-im-info-content)" },

    Title: { paddingLeft: "40px" },

    TableContainer: {
      paddingLeft: "40px",
      paddingRight: "30px",
      paddingBottom: "10px",
      paddingTop: "20px",
      overflowX: "auto",
      backgroundColor: "var(--color-im-info-fill)",
      "&::-webkit-scrollbar": {
        backgroundColor: "var(--color-im-info-fill)"
      },
      boxShadow: "unset"
    },

    Table: { tableLayout: "fixed", minWidth: "100%" },
    TableCellHead: {
      borderBottom: "none",
      padding: "0px !important",
      height: "0px !important",
      width: "90%",
      overflowY: "auto",
      color: "var(--color-im-info-content)",
      fontWeight: "bold",
      "&::-webkit-scrollbar": {
        backgroundColor: "var(--color-im-info-fill)"
      }
    },

    TableCell: {
      borderBottom: "none",
      padding: "0px !important",
      height: "0px !important",
      width: "90%",
      overflowY: "auto",
      color: "var(--color-im-info-content)",
      fontWeight: 500,
      "&::-webkit-scrollbar": {
        backgroundColor: "var(--color-im-info-fill)"
      }
    },

    TableCellError: {
      borderBottom: "none",
      padding: "0px",
      width: "10%"
    },

    Box: {
      height: "30px",
      width: "100%",
      backgroundColor: "transparent"
    }
  }
};
