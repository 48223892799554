import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import { ERROR_TYPES, downloadReportOptions } from "utils/constants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";

export default function DownloadReportModal({
  isVisible,
  setIsVisible,
  mainDistrictId,
  districtId,
  billCycleCode,
  billDate,
  setIsLoading,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage
}) {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        district_id: districtId,
        billing_cycle_code: billCycleCode,
        billing_date: billDate,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.billing.errorLogReport}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setThrowError(false);
    setIsVisible(false);
  };

  return (
    <DownloadPopup
      title={t("billingErrorLog.billingErrorLogReport")}
      showPrintModal={isVisible}
      downloadOptions={downloadReportOptions}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
    />
  );
}
