import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import CommonModal from "components/shared/common-modal/CommonModal";

import { DatePickerField } from "components/core";
import dayjs from "dayjs";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  BOOLEAN_STRING_VALUES,
  Dates,
  ERROR_TYPES,
  errorMsgs,
  N_CHECKER,
  ROLE_ACCESS_CODES,
  YES,
  ymdDateFormat
} from "utils/constants";
import {
  atIM,
  day,
  delivered,
  month,
  pendingDistribution,
  picking,
  statusId10,
  statusId20,
  statusId80
} from "utils/constants/open-media-processing/Research";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData
} from "utils/helpers";
import { WorkManagementStyles } from "../work-management/WorkManagementStyles";
import { dateFormat, HighlightScheduledDates } from "./HeighlightScheduledDays";
import { openMediaResearchStyles } from "./openMediaResearchStyles";
const { TRUE, FALSE } = BOOLEAN_STRING_VALUES;

const minDate = dayjs(); // minimum date for service date
const maxDate = dayjs(Dates.MaxDate); // maximum date for service date

const UpdateModal = ({
  isModalOpen,
  handleModalClose,
  selectedRowData,
  statusList,
  mediaTypeOptions,
  lvModalOptions,
  isUserHasUpdatePermission,
  onClickUpdate,
  localeByBranch,
  hasMultipleMediaNumbers,
  updateDataIds
}) => {
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const { roleAccess } = useSelector(selectAuth);
  const [selectedLv, setSelectedLv] = useState(null);
  const [selectedMediaType, setSelectedMediaType] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [indefiniteRetension, setIndefiniteRetension] = useState(false);
  const [serviceDates, setServiceDates] = useState([]);
  const [returnDate, setReturnDate] = useState();
  const [isScheduledServiceDatesLoading, setIsScheduledServiceDatesLoading] =
    useState(false);
  const [initialState, setInitialState] = useState({});

  const {
    current_status_id = "",
    customer_assigned_flag_bit = FALSE,
    unresolved_discrepancies_flag_bit = FALSE,
    active_in_mdr_bit = FALSE,
    current_status_code = "",
    in_inventory_flag_bit = FALSE,
    return_date = ""
  } = selectedRowData || {};

  const setDefault = () => {
    const initialSelectedLv = {
      value: selectedRowData.logical_vault_id,
      label: selectedRowData.logical_vault_code
    };
    const initialSelectedMediaType = {
      value: selectedRowData.media_type_id,
      label: selectedRowData.media_type_media_descr
    };
    const initialSelectedNewStatus = {
      value: selectedRowData.new_status_id || "",
      label: selectedRowData.new_status_code || ""
    };
    const initialReturnDate = dayjs(selectedRowData.return_date);
    const initialIndefiniteRetension = getIndiniteInitialState();
    setSelectedLv(initialSelectedLv);
    setSelectedMediaType(initialSelectedMediaType);
    setReturnDate(initialReturnDate);
    setIndefiniteRetension(initialIndefiniteRetension);
    setNewStatus(initialSelectedNewStatus);
    setInitialState({
      selectedLv: initialSelectedLv,
      selectedMediaType: initialSelectedMediaType,
      returnDate: initialReturnDate,
      indefiniteRetension: initialIndefiniteRetension,
      newStatus: initialSelectedNewStatus
    });
  };

  const getIndiniteInitialState = () => {
    if (updateDataIds.includes(selectedRowData.open_media_id))
      return [TRUE, YES].includes(active_in_mdr_bit);

    return (
      (current_status_code !== delivered &&
        [TRUE, YES].includes(active_in_mdr_bit)) ||
      (!return_date && current_status_code !== delivered)
    );
  };
  useEffect(() => {
    if (isModalOpen) setDefault();
    // eslint-disable-next-line
  }, [selectedRowData, isModalOpen]);

  const isUserHasNewStatusPermission = roleAccess.includes(
    ROLE_ACCESS_CODES.CODE_252
  );

  const reset = () => {
    setThrowError(false);
    setThrowErrorMessage(null);
    setSelectedLv(null);
    setSelectedMediaType(null);
    setNewStatus(null);
    setIndefiniteRetension(false);
    setServiceDates([]);
    setReturnDate();
    setInitialState({});
  };

  const getCheckBoxDisableStatus = () => {
    if (
      unresolved_discrepancies_flag_bit === TRUE ||
      active_in_mdr_bit === TRUE ||
      current_status_code === delivered ||
      newStatus?.label === delivered
    ) {
      return true;
    } else if (in_inventory_flag_bit === TRUE) {
      return false;
    }
  };

  const isInValidSatus = (status) =>
    [atIM, pendingDistribution].includes(status);

  const getNewStatusDisabledSatus = () => {
    if (
      unresolved_discrepancies_flag_bit === TRUE ||
      active_in_mdr_bit === TRUE ||
      !isUserHasNewStatusPermission
    ) {
      return true;
    } else if (isInValidSatus(current_status_code)) {
      return false;
    } else {
      return true;
    }
  };

  const getReturnDateDisabledSatus = () => {
    if (
      unresolved_discrepancies_flag_bit === TRUE ||
      active_in_mdr_bit === TRUE ||
      current_status_code === delivered ||
      !isUserHasUpdatePermission ||
      newStatus?.label === delivered
    ) {
      return true;
    } else if (in_inventory_flag_bit === TRUE) {
      return false;
    } else {
      return true;
    }
  };

  const getLvDisabledStatus = () => {
    if (
      unresolved_discrepancies_flag_bit === TRUE ||
      active_in_mdr_bit === TRUE ||
      current_status_code === delivered ||
      current_status_code === picking ||
      !isUserHasUpdatePermission ||
      newStatus?.label === delivered ||
      hasMultipleMediaNumbers
    ) {
      return true;
    } else if (
      isInValidSatus(current_status_code) &&
      customer_assigned_flag_bit === FALSE
    ) {
      return false;
    }
  };

  const getMediaTypeDisabledStatus = () => {
    if (
      unresolved_discrepancies_flag_bit === TRUE ||
      active_in_mdr_bit === TRUE ||
      current_status_code === delivered ||
      !isUserHasUpdatePermission ||
      newStatus?.label === delivered ||
      hasMultipleMediaNumbers
    ) {
      return true;
    }
    if (current_status_code !== delivered) {
      return false;
    }
  };

  const getNewStatusOptions = () => {
    const optionsArray = [];

    if (current_status_id === statusId10) {
      optionsArray.push(
        statusList.find((status) => status.value === statusId80)
      );
    }
    if (
      customer_assigned_flag_bit === FALSE &&
      current_status_id === statusId20
    ) {
      optionsArray.push(
        statusList.find((status) => status.value === statusId10)
      );
      optionsArray.push(
        statusList.find((status) => status.value === statusId80)
      );
    }

    return optionsArray;
  };

  const getMediaTypeOptions = () => {
    return mediaTypeOptions
      .filter((mt) => mt.Logical_Vault_Id === selectedLv?.value)
      .map((mt) => ({
        label: mt.Media_Type_Media_Descr,
        value: mt.Media_Type_Id
      }));
  };

  const getScheduledDates = async ({ fromDate, toDate }) => {
    try {
      setIsScheduledServiceDatesLoading(true);
      const requestBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        customer_id: selectedRowData.customer_id,
        from_date: fromDate,
        to_date: toDate,
        reschedule_flag: N_CHECKER,
        addon_cutoff_flag: N_CHECKER
      });
      let URL = `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnrequestidandscheduleddateforcustomer`;

      let data = await getResponseData(requestBody, URL, 1);
      setServiceDates(
        data.data[0].map((item) => dateFormat(item.service_date))
      );
    } catch (err) {
      setThrowErrorMessage(err.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsScheduledServiceDatesLoading(false);
    }
  };

  const onChangeReturnDate = async (val) => {
    if (!isUserHasUpdatePermission) return;

    if (serviceDates.includes(dateFormat(val))) {
      setThrowError(false);
      setThrowErrorMessage(null);
      setReturnDate(val || "");
      setIndefiniteRetension(false);
    } else {
      const msg = await getMessageFromCode(errorMsgs.errorCode20229);
      let errorMsg = msg[0]?.descr;
      setThrowError(true);
      setThrowErrorMessage(errorMsg);
    }
  };
  const handleFromDate = (value) => {
    return dayjs(value).startOf(month);
  };

  const handleToDate = (value) => {
    return dayjs(value).endOf(month);
  };

  const getLvOptions = () => {
    if (current_status_id === statusId10) {
      return [selectedLv];
    }
    return lvModalOptions.map((lv) => ({
      label: lv.Code,
      value: lv.Logical_Vault_Id
    }));
  };

  const onChangeNewStatus = (val) => {
    if (!isUserHasNewStatusPermission) return;

    if (val?.label === delivered) {
      setDefault();
      setIndefiniteRetension(false);
      setReturnDate(null);
    }
    setNewStatus(val);
  };

  const hasChanges = useCallback(() => {
    return (
      initialState.selectedLv?.value !== selectedLv?.value ||
      initialState.selectedMediaType?.value !== selectedMediaType?.value ||
      initialState.returnDate?.format(ymdDateFormat) !==
        returnDate?.format(ymdDateFormat) ||
      initialState.indefiniteRetension !== indefiniteRetension ||
      initialState.newStatus?.value !== newStatus?.value
    );
  }, [
    initialState,
    selectedLv,
    selectedMediaType,
    returnDate,
    indefiniteRetension,
    newStatus
  ]);

  const handleUpdateClick = () => {
    onClickUpdate(
      selectedRowData,
      {
        newStatus,
        indefiniteRetension,
        selectedMediaType,
        selectedLv,
        returnDate
      },
      reset
    );
  };
  const onCancelModal = () => {
    reset();
    handleModalClose();
  };

  const onChangeIndefiniteRetension = (e) => {
    if (e.target.checked && !getNewStatusDisabledSatus()) {
      setReturnDate(null);
    }
    setIndefiniteRetension(e.target.checked);
  };
  if (!selectedRowData) return null;
  return (
    <>
      <CommonModal
        open={isModalOpen}
        title={t("openMediaResearch.editModalTitle")}
        body={
          <>
            {throwError && (
              <Alert
                severity="warning"
                style={openMediaResearchStyles.alertPadding}
                icon={
                  <img
                    style={openMediaResearchStyles.alertSize}
                    src={ErrorIcon}
                    alt="error"
                  />
                }
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Customer
                </Typography>
                <Typography variant="subtitle1">
                  {selectedRowData?.customer_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Media #
                </Typography>
                <Typography variant="subtitle1">
                  {selectedRowData?.volser}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  clearIcon={<CloseIcon />}
                  options={getLvOptions()}
                  value={selectedLv}
                  onChange={(e, val) => setSelectedLv(val)}
                  id="logical-vault"
                  name="logical-vault"
                  size="medium"
                  disabled={getLvDisabledStatus()}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("openMediaResearch.logicalVault")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  disableClearable={true}
                  clearIcon={<CloseIcon />}
                  options={getMediaTypeOptions()}
                  value={selectedMediaType}
                  disabled={getMediaTypeDisabledStatus()}
                  onChange={(e, val) => setSelectedMediaType(val)}
                  id="media-type"
                  name="media-type"
                  size="medium"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("openMediaResearch.mediaType")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePickerField
                  id="serviceDateId"
                  name="serviceDate"
                  minDate={minDate}
                  maxDate={maxDate}
                  error={false}
                  value={returnDate || null}
                  onChange={onChangeReturnDate}
                  disabled={getReturnDateDisabledSatus()}
                  label={t("openMediaResearch.returnDate")}
                  loading={isScheduledServiceDatesLoading}
                  locale={localeByBranch}
                  renderLoading={() => <CircularProgress />}
                  shouldDisableDate={(date) =>
                    dayjs(date).isBefore(dayjs(), day)
                  }
                  onOpen={() =>
                    getScheduledDates({
                      fromDate: handleFromDate(dayjs()),
                      toDate: handleToDate(dayjs())
                    })
                  }
                  onMonthChange={(val) => {
                    getScheduledDates({
                      fromDate: handleFromDate(val),
                      toDate: handleToDate(val)
                    });
                  }}
                  onYearChange={(val) => {
                    getScheduledDates({
                      fromDate: handleFromDate(val),
                      toDate: handleToDate(val)
                    });
                  }}
                  highlightScheduleDays={(props) => (
                    <HighlightScheduledDates
                      {...props}
                      selectedDate={dayjs()}
                      hightlightedDates={serviceDates}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  disabled={getNewStatusDisabledSatus()}
                  value={newStatus}
                  options={getNewStatusOptions()}
                  onChange={(e, val) => onChangeNewStatus(val)}
                  popupIcon={<ArrowDown />}
                  clearIcon={<CloseIcon />}
                  id="new-status"
                  name="new-status"
                  size="medium"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("openMediaResearch.newStatus")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  sx={WorkManagementStyles.formControllabelSx}
                  control={
                    <Checkbox
                      color="primary"
                      checked={indefiniteRetension}
                      onChange={onChangeIndefiniteRetension}
                      disabled={getCheckBoxDisableStatus()}
                    />
                  }
                  label={t("openMediaResearch.indefinteRetenstion")}
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button onClick={onCancelModal} variant="outlined">
              {t("common.cancel")}
            </Button>
            <Button
              onClick={handleUpdateClick}
              variant="contained"
              disabled={!hasChanges()}
            >
              {t("common.update")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default UpdateModal;
