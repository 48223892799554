import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EVENT,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH
} from "routing/Paths";
import { useGetAllCustomersQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  dateFormatWithoutTime,
  dateFormatWithTime,
  dmyDateFormatTime,
  downloadReportOptions,
  ERROR_TYPES,
  errorMsgs
} from "utils/constants";
import { secureSyncSource } from "utils/constants/disaster-module/EventSearchConstants";
import {
  all,
  fileType,
  globalAttributeTypeId88,
  globalAttributeTypeIdCalFuture,
  initialValues,
  severityError,
  sourceList,
  systemId
} from "utils/constants/disaster-module/InstructionFilesCons";
import {
  downloadFileFromFB,
  extractEventNumber,
  findErrorMessage,
  formatDateByLocale,
  getDrInstructionMainFolderPath,
  getGlobalAttributeValue,
  getGsFilePath,
  getResponseData,
  getSSDrInstructionFolderPath,
  getUserName,
  openMinimizedWindow,
  printReport
} from "utils/helpers";
import DataTable from "./DataTable";
import { Styles } from "./Styles";

const InstructionFilesLayout = () => {
  const { currentBranch, auth } = useSelector(selectAuth);
  const { data: allCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  let location = useLocation();

  const formik = useFormik({
    initialValues: initialValues
  });
  const { values, setFieldValue } = formik;
  const [isLoading, setIsLoading] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [severity, setSeverity] = useState(severityError);
  const [tableDataList, setTableDataList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [activePopup, setActivePopup] = useState({
    tableRetrievePopup: false
  });
  const [cancelPopup, setCancelPopup] = useState({
    modalPopupOpen: false,
    showOk: false,
    showCancel: false,
    message: ""
  });
  const [isFindEnabled, setIsFindEnabled] = useState(true);
  const [drEventId, setDrEventId] = useState(null);
  const [showBack, setShowBack] = useState(false);
  const navigate = useNavigate();
  const { localeByBranch } = useSelector(selectAuth);
  const pdfFormat = downloadReportOptions[0].type.toLocaleUpperCase();
  const userName = getUserName(auth?.user);
  const [customerId, setCustomerId] = useState("");
  const [drInstructionTypeId, setDrInstructionTypeId] = useState("");
  const pathParts = location?.pathname?.split("/");
  const idPart = pathParts?.find((part) => part?.startsWith("customerId="));
  useEffect(() => {
    const id = idPart ? idPart.split("=")[1] : null;
    if (id) {
      let data = allCustomers.find((item) => item?.value === id);
      setFieldValue("customerId", data);
      if (data?.value) {
        handleFind(id);
        setDrEventId(
          location?.state?.from ||
            JSON.parse(sessionStorage.getItem("dr_event_id"))
        );
      }
      setShowBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPart, allCustomers?.length]);
  const CustomBodyRenderComponent = ({ value, locale }) => {
    const [formattedDate, setFormattedDate] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        const result = await formatDateByLocale(
          dayjs(value, dmyDateFormatTime),
          locale,
          true
        );
        setFormattedDate(result);
      };
      fetchData();
    }, [value, locale]);

    return formattedDate;
  };

  const handleFind = async (id = false) => {
    let customer_id = id ? id : "";
    let dr_instruction_type_id = "";
    if (id) {
      setCustomerId(id);
    }
    if (values?.customerId?.value !== all?.value && !id) {
      customer_id = values?.customerId?.value;
      setCustomerId(customer_id);
    }
    if (values?.sourceId !== all?.value) {
      dr_instruction_type_id = values?.sourceId;
      setDrInstructionTypeId(dr_instruction_type_id);
    }
    try {
      await import(`dayjs/locale/${localeByBranch}.js`);
      setIsLoading(true);
      setSelectedRows([]);
      const res = await getResponseData(
        JSON.stringify({
          customer_id,
          main_district_id: currentBranch?.value,
          dr_instruction_type_id
        }),
        `${CF_URLS.disasterRecovery.customeridanddrInstruction}`,
        2
      );
      if (res?.data[0]) {
        let resSetData = [];
        res?.data[0]?.forEach((element) => {
          let obj = {
            ...element
          };
          let date = dayjs
            .utc(element?.last_mod_date)
            .locale(localeByBranch)
            .format(dateFormatWithoutTime);
          obj["source"] = element?.source.replace("|", date);
          obj["last_mod_date"] = dayjs
            .utc(element?.last_mod_date)
            .locale(localeByBranch)
            .format(dateFormatWithTime);
          resSetData.push(obj);
        });
        let sorting = resSetData?.sort((a, b) => {
          if (a.number > b.number) {
            return 1;
          } else if (a.number < b.number) {
            return -1;
          } else {
            if (a?.source > b?.source) {
              return 1;
            } else if (a.source < b.source) {
              return -1;
            } else {
              return 0;
            }
          }
        });
        setTableDataList([...sorting]);
        setIsFindEnabled(false);
      }
      setIsLoading(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setSeverity(severityError);
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setFieldValue("customerId", all);
    setFieldValue("sourceId", all?.value);
    setTableDataList([]);
    setIsFindEnabled(true);
    setSelectedRows([]);
  };

  const handlePrintClick = () => {
    setIsDownload(true);
  };

  const handleRowSelection = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    if (selectedRows.includes(rowsSelected[0])) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rowsSelected);
    }
  };

  const handleRetrieveClick = async () => {
    setIsLoading(true);
    const errorMsg = await getMessageFromCode(errorMsgs?.errorCode26205);
    if (errorMsg[0]?.descr) {
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr?.replace(
          "|",
          t("disasterRecovery.instructionFiles.text")
        )
      });
      setActivePopup({
        ...activePopup,
        tableRetrievePopup: true
      });
    }
    setIsLoading(false);
  };
  const handleDrTemplate = async (id) => {
    setIsLoading(true);
    const districtId = currentBranch?.value;
    const attributeData = await getGlobalAttributeValue(
      districtId,
      systemId,
      id
    );
    if (attributeData) {
      openMinimizedWindow(attributeData);
    }
    setIsLoading(false);
  };

  const handleDRInstructionsTemplate = async () => {
    try {
      setIsLoading(true);
      const selectedRecord = tableDataList[selectedRows[0]];
      const res = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.value,
          dr_instruction_id: selectedRecord?.dr_instruction_id
        }),
        `${CF_URLS.disasterRecovery.drinstructionSandDrevents}`,
        2
      );
      if (res?.data[0][0]) {
        let { dr_instruction_name, dr_instruction } = res?.data[0][0] || {};
        const source = selectedRecord?.source;
        if (dr_instruction) {
          const blob = new Blob([dr_instruction], { type: fileType });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = dr_instruction_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          setIsLoading(false);
        } else {
          let folderPath = "";
          if (source === secureSyncSource) {
            folderPath = getSSDrInstructionFolderPath(
              values?.customerId?.value,
              selectedRecord?.dr_instruction_id,
              dr_instruction_name
            );
          } else {
            const eventId = extractEventNumber(source);
            folderPath = getDrInstructionMainFolderPath(
              values?.customerId?.value,
              `${eventId}/${dr_instruction_name}`
            );
          }
          await downloadFileFromFB(
            getGsFilePath(folderPath),
            dr_instruction_name,
            () => setIsLoading(false)
          );
        }
      }

      setIsLoading(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setSeverity(severityError);
      setIsLoading(false);
    }
  };
  const handleCancel = () => {
    setCancelPopup({
      modalPopupOpen: false,
      showOk: false,
      showCancel: false,
      message: ""
    });
    setActivePopup({
      tableRetrievePopup: false
    });
  };
  const handleYes = () => {
    if (activePopup.tableRetrievePopup) {
      handleDRInstructionsTemplate();
      handleCancel();
    }
  };
  const handleDownloadClick = async () => {
    const requestBody = {
      main_district_id: currentBranch?.district_id,
      customer_id: customerId,
      dr_instruction_type_id: drInstructionTypeId,
      source_criteria: "",
      user_name: userName,
      report_format: pdfFormat
    };

    try {
      setIsLoading(true);
      const reqBody = JSON.stringify(requestBody);

      const reportResponse = await printReport(
        reqBody,
        CF_URLS.printReports.disasterRecovery.instructionFilesReport
      );
      if (!reportResponse.success) {
        setThrowErrorMessage((prevState) => reportResponse.error);
        setThrowError((prevState) => true);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage((prevState) => findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError((prevState) => true);
    } finally {
      setIsLoading((prevState) => false);
    }
    return;
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      <ConfirmPopup
        modalPopupOpen={cancelPopup?.modalPopupOpen}
        handleCancel={() => handleCancel()}
        showOk={cancelPopup?.showOk}
        message={cancelPopup?.message}
        showCancel={cancelPopup?.showCancel}
        handleYes={() => handleYes()}
      />
      <DownloadPopup
        title={t("disasterRecovery.instructionFiles.downloadReportTitle")}
        showPrintModal={isDownload}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => {
          setIsDownload(false);
        }}
        onDownloadClick={() => {
          handleDownloadClick();
        }}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
      {throwErrorMessage && (
        <Collapse in={!!throwErrorMessage}>
          <Alert
            severity={severity}
            sx={Styles.errorStyle}
            icon={
              <img
                src={severity === severityError ? ErrorIcon : WarningIcon}
                alt="error"
                style={Styles.iconStyle}
              />
            }
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setSeverity(severityError);
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Grid container spacing={2}>
        <Grid item md={6} lg={3} xs={12}>
          <CustomersField
            options={[all, ...allCustomers]}
            value={values?.customerId?.label}
            handleOnChange={(val, newValue) => {
              setFieldValue("customerId", newValue);
              setIsFindEnabled(true);
              setTableDataList([]);
              setSelectedRows([]);
            }}
            label={t("disasterRecovery.instructionFiles.customer")}
          />
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="source">
              {t("disasterRecovery.instructionFiles.source")}
            </InputLabel>
            <Select
              labelId="source"
              value={values?.sourceId}
              label={t("disasterRecovery.instructionFiles.source")}
              onChange={(e) => {
                setFieldValue("sourceId", e.target.value);
                setIsFindEnabled(true);
                setTableDataList([]);
                setSelectedRows([]);
              }}
            >
              {sourceList?.map((item) => (
                <MenuItem
                  value={item?.value}
                  key={item?.value}
                  name={item?.label}
                >
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <Grid
            item
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="start"
            gap={spacing.verticalMargin20}
          >
            <Button
              id="clear-button"
              variant="outlined"
              type="button"
              onClick={() => handleClear()}
            >
              {t("common.clear")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              type="button"
              disabled={!isFindEnabled}
              onClick={() => handleFind()}
            >
              {t("common.find")}
            </Button>
          </Grid>
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <List sx={Styles.listStyle}>
            <ListItem>
              <ListItemText>
                {t("disasterRecovery.instructionFiles.point1")}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("disasterRecovery.instructionFiles.point2")}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("disasterRecovery.instructionFiles.point3")}
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <DataTable
            resultSet={[...tableDataList]}
            handlePrintClick={handlePrintClick}
            selectedRows={selectedRows}
            handleRowSelection={handleRowSelection}
            handleRetrieveClick={handleRetrieveClick}
            isFindEnabled={isFindEnabled}
            CustomBodyRenderComponent={CustomBodyRenderComponent}
            localeByBranch={localeByBranch}
          />
          <Grid item md={12} lg={12} xs={12} marginTop={2}>
            <Divider />
          </Grid>
          <Grid item md={12} lg={12} xs={12} marginTop={2}>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="start"
              gap={spacing.verticalMargin20}
            >
              {showBack && (
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  onClick={() => {
                    navigate(
                      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}/${drEventId}`,
                      {
                        state: location?.state
                      }
                    );
                  }}
                >
                  {t("common.back")}
                </Button>
              )}
              <Button
                variant="outlined"
                size="medium"
                type="button"
                onClick={() => handleDrTemplate(globalAttributeTypeIdCalFuture)}
              >
                {t("disasterRecovery.instructionFiles.dRTemplate")}
              </Button>
              <Button
                variant="contained"
                size="medium"
                type="button"
                onClick={() => handleDrTemplate(globalAttributeTypeId88)}
              >
                {t("disasterRecovery.instructionFiles.DrProcedures")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstructionFilesLayout;
