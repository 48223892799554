/**
 * @module auth authentication requests module
 */
import { TEST_USER } from "utils/constants";

export const auth = {
  /**
   *
   * @param {object} props
   * @param {string} props.userId id of the user
   * @param {string} props.password password of the user
   * @param {string} props.branch id of the selected branch
   * @returns
   */
  login: async ({ userId, password, branch }) => {
    if (userId === TEST_USER.userId && password === TEST_USER.password) {
      return { success: true, data: { username: userId, branch: branch } };
    } else {
      throw Error("error occured");
    }
  }
};
