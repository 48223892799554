import { ExpandMore, HighlightOff } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import {
  all,
  dataIndex,
  filterOptions
} from "utils/constants/customer-information/PayInvoiceFilter";
import { PreferencesStyles } from "../PreferencesStyles";
import FilterTable from "./FilterTable";

const PayInvoiceTable = ({
  filteredData,
  parentData,
  onChange,
  setFilteredData
}) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState(filterOptions[0]);

  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setOpenFilter(false);
    setAnchorEl(null);
  };

  const handleFilterModalReset = () => {
    setFilterValue(filterOptions[0]);
    handleFilterModalChange(filterOptions[0]);
  };

  const handleFilterModalChange = (value) => {
    setFilterValue(value);
    filterData(value);
  };

  const filterData = (filterValue) => {
    if (filterValue.value === all) {
      setFilteredData(parentData);
    } else {
      const filtered = parentData.filter((item) =>
        item.changedValues.includes(filterValue.label)
      );
      setFilteredData(filtered);
    }
  };

  const handleFilter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(true);
  };

  let nestedColumns = [
    {
      label: t("preferences.name"),
      name: dataIndex.name
    },
    {
      label: t("preferences.previousValueLabel"),
      name: dataIndex.previousValue
    },
    {
      label: t("preferences.newValueLabel"),
      name: dataIndex.newValue
    }
  ];
  const nestedTableOption = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: false,
    elevation: 1
  };

  const columns = [
    {
      name: dataIndex.date,
      label: t(`preferences.date`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: dataIndex.imEmployee,
      label: t(`preferences.imEmployee`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: dataIndex.authorizedBy,
      label: t(`preferences.authorizedBy`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: dataIndex.changedValues,
      label: t(`preferences.payInvoice.changedValues`),
      options: {
        filter: true,
        sort: false
      }
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    page: 1,
    pagination: filteredData?.length && true,
    textLabels: {
      body: {
        noMatch: (
          <Box pb={spacing.gap}>
            <Typography style={PreferencesStyles.noDataText}>
              {t("common.noRecordsFound")}
            </Typography>
          </Box>
        )
      }
    },
    expandableRows: true,
    expandableRowsHeader: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const parentItem = parentData[rowMeta?.dataIndex];
      return (
        <TableRow>
          <TableCell colSpan={10} sx={PreferencesStyles.nestedTableCell}>
            <MUIDataTable
              columns={nestedColumns}
              options={nestedTableOption}
              data={parentItem?.children}
            />
          </TableCell>
        </TableRow>
      );
    },
    customToolbar: () => (
      <Grid
        item
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
      >
        <Button
          component="label"
          variant="outlined"
          style={PreferencesStyles.tableListStyle.icons}
          id="filter"
          onClick={handleFilter}
        >
          <FilterListIcon />
        </Button>
        <FilterTable
          open={openFilter}
          anchorEl={anchorEl}
          filterValue={filterValue}
          handleClose={handleClose}
          handleChange={handleFilterModalChange}
          handleReset={handleFilterModalReset}
        />
      </Grid>
    )
  };
  return (
    <>
      <Accordion sx={PreferencesStyles.accordian} onChange={onChange}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography
            variant="subtitle1"
            style={commonStyles.tableRecordsFoundTitleStyle}
          >
            {t("preferences.history")}
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={PreferencesStyles.accordiaDetail}>
          <TableContainer sx={PreferencesStyles.tableContainer}>
            <MUIDataTable
              disableColumnSelector={false}
              columns={columns}
              options={options}
              data={filteredData}
              editMode="row"
              rowModesModel={null}
              onRowModesModelChange={null}
              onRowEditStop={null}
              processRowUpdate={null}
              title={
                filterValue.value !== all && (
                  <Chip
                    label={filterValue.label}
                    sx={PreferencesStyles.chipColor}
                    variant="outlined"
                    onDelete={() => handleFilterModalChange(filterOptions[0])}
                    deleteIcon={
                      <HighlightOff sx={PreferencesStyles.chipDeleteIcon} />
                    }
                    size="small"
                  />
                )
              }
            />
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PayInvoiceTable;
