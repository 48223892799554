import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ModalPopup from "components/core/modal-components/ModalPopup";
import { getMessageByIdFromMessage } from "services/common";
import { CANCEL, NO, YES } from "utils/constants";
import { MESSAGE_63075 } from "utils/constants/request-module/MediaRequestDetailConstants";

const CancellingMediaDestructionModal = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    getMessageByIdFromMessage({ message_id: MESSAGE_63075 }).then((resp) =>
      setMessage((prevState) => resp[0]?.descr)
    );
  }, []);

  return (
    <>
      <ModalPopup
        title={t("mediaRequestDetail.confirm")}
        modalPopupOpen={props.isVisible}
        alertMessage={message}
        modalButton={[
          <Button
            variant="outlined"
            onClick={(e) => {
              props.handleButtonClick(YES);
            }}
            key={YES}
          >
            {t("mediaRequestDetail.yes")}
          </Button>,
          <Button
            variant="contained"
            onClick={(e) => {
              props.handleButtonClick(NO);
            }}
            key={NO}
          >
            {t("mediaRequestDetail.no")}
          </Button>,
          <Button
            variant="contained"
            onClick={(e) => {
              props.handleButtonClick(CANCEL);
            }}
            key={CANCEL}
          >
            {t("mediaRequestDetail.cancel")}
          </Button>
        ]}
      />
    </>
  );
};

export default CancellingMediaDestructionModal;
