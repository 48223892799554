import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/warning-2.svg";
import BranchField from "components/core/branch-field/BranchField";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { PersonnelsField } from "components/core/personnel-field/PersonnelsField";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import { setBypassMesage } from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import { authSection } from "utils/constants";
import { authSectionStyles } from "./AuthSectionStyles";

const AuditAuthForm = ({
  allBranches,
  handleBranchChange,
  valuePersonnelAuth,
  handlePersonnelAuthChange,
  handleCustomerChange,
  handlePersonnelChange,
  onAuthenticateClick,
  isAuthenticationError,
  isAuthenticated,
  programSecurityListArray,
  onCancelAuthCliked,
  handleChange,
  setError,
  setLoading,
  authFormValues,
  selectedBranch,
  authCustomers,
  disableBranch = true,
  onBypassClick,
  isBypassError,
  showBypass = false,
  handleByPassMessage = () => {}
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { authInfo } = useSelector(selectAuth);

  const showAuthenticationErrorMsg = () => {
    if (!authFormValues.authNumber && !authFormValues.challengeResponse) {
      return t("common.authNumberChallengeResponseMissing");
    }

    if (authFormValues.authNumber) {
      return t("common.invalidAuthNumber");
    }

    if (authFormValues.challengeResponse) {
      t("common.invalidChallengeResponse");
    }

    return t("common.invalidAuthNumberChallengeResponse");
  };

  return (
    <>
      {!isAuthenticated && (
        <Grid container spacing={spacing.gap}>
          <Grid item xs={12}>
            <Grid container spacing={spacing.gap}>
              {/* Customer Selection */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <CustomersField
                    options={authCustomers}
                    value={authFormValues?.customer}
                    handleOnChange={handleCustomerChange}
                    label={t("openMediaAuditSearch.customer")}
                    required
                  />
                </FormControl>
              </Grid>

              {/* Branch Selection */}
              <Grid item xs={3}>
                <BranchField
                  options={allBranches}
                  disabled={disableBranch}
                  onChange={(e) => handleBranchChange(e)}
                  value={
                    authFormValues?.branch
                      ? authFormValues?.branch
                      : selectedBranch?.value
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">{t("common.personnelAuth")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={spacing.gap}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={valuePersonnelAuth}
                    onChange={handlePersonnelAuthChange}
                  >
                    <FormControlLabel
                      value="personnelAuth"
                      control={<Radio />}
                      label={t("common.personnelAuth")}
                    />
                    {showBypass && (
                      <FormControlLabel
                        value="bypass"
                        control={<Radio />}
                        label={t("common.bypass")}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {valuePersonnelAuth === authSection?.personnelAuth && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={spacing.gap}>
                  {/* Personnel Selection */}
                  <Grid item xs={3}>
                    <PersonnelsField
                      label={t("common.personnel")}
                      customerId={authFormValues?.customer?.value}
                      form={authFormValues}
                      handlePersonnelOnChange={handlePersonnelChange}
                      selectedPersonnel={authFormValues?.personnel}
                    />
                  </Grid>

                  {authFormValues?.personnel?.value && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body2" id="auth-info">
                          {t("common.authInfo")}
                        </Typography>
                      </Grid>{" "}
                      {/* Auth Number Field */}
                      <Grid item xs={3}>
                        <TextField
                          id="auth-number"
                          name="authNumber"
                          label={t("common.authNumber")}
                          fullWidth
                          inputProps={{
                            maxLength: 6 // Maximum input length set to 6 characters
                          }}
                          InputLabelProps={
                            authSectionStyles.commonStyles.inputLabelProps
                          }
                          disabled={authFormValues.challengeResponse}
                          onChange={(e) =>
                            handleChange("authNumber", e.target.value)
                          }
                          value={authFormValues.authNumber}
                        />
                      </Grid>
                      {authFormValues.challengeQuestion && (
                        <>
                          {/* Challange Question */}

                          <Grid item xs={3}>
                            <TextField
                              label={t("common.challengeQuestion")}
                              fullWidth
                              variant="standard"
                              InputProps={{
                                disableUnderline: true
                              }}
                              value={authFormValues.challengeQuestion}
                              InputLabelProps={
                                authSectionStyles.commonStyles.inputLabelProps
                              }
                            />
                          </Grid>
                          {/* Challenge Response Field */}

                          <Grid item xs={3}>
                            <TextField
                              label={t("common.challengeResponse")}
                              type="password"
                              id="challenge-response"
                              name="challengeResponse"
                              fullWidth
                              InputLabelProps={
                                authSectionStyles.commonStyles.inputLabelProps
                              }
                              inputProps={{
                                maxLength: 30 // Maximum input length set to 30 characters
                              }}
                              disabled={authFormValues.authNumber}
                              onChange={(e) =>
                                handleChange(
                                  "challengeResponse",
                                  e.target.value
                                )
                              }
                              value={authFormValues.challengeResponse}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        {isAuthenticationError && (
                          <Alert
                            sx={authSectionStyles.commonStyles.alertWidth}
                            severity="error"
                            icon={<img src={WarningIcon} alt="Warning" />}
                          >
                            {showAuthenticationErrorMsg()}
                          </Alert>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              {/* Auth Button */}
              <Grid item xs={12}>
                <Button
                  id="clear-button"
                  variant="outlined"
                  type="button"
                  onClick={onCancelAuthCliked}
                  style={authSectionStyles.commonStyles.btn}
                >
                  {t("common.cancel")}
                </Button>

                {authFormValues?.personnel?.value && (
                  <Button
                    variant="contained"
                    style={authSectionStyles.commonStyles.btn}
                    disabled={
                      !authFormValues?.customer || !authFormValues?.personnel
                    }
                    onClick={() => onAuthenticateClick()}
                  >
                    {t("common.authenticate")}
                  </Button>
                )}
              </Grid>
            </>
          )}

          {valuePersonnelAuth === authSection?.bypass && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={spacing.gap}>
                  <Grid item xs={6}>
                    <TextField
                      label={t("common.reason")}
                      size="medium"
                      variant="outlined"
                      multiline
                      fullWidth
                      rows={2}
                      sx={authSectionStyles.bypassStyles.tfReason}
                      id="bypassReason"
                      name="bypassReason"
                      onChange={(e) => {
                        dispatch(setBypassMesage(e.target.value));
                        handleByPassMessage(e.target.value);
                      }}
                      value={authFormValues.bypassMessage}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      label={t("common.bypassedBy")}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        disableUnderline: true
                      }}
                      value={authInfo?.displayName}
                      InputLabelProps={
                        authSectionStyles.commonStyles.inputLabelProps
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  style={authSectionStyles.commonStyles.btn}
                  onClick={() => onBypassClick()}
                >
                  {t("common.bypass")}
                </Button>

                {isBypassError && (
                  <Alert
                    sx={authSectionStyles.personnelAuthStyles.alertWidth}
                    severity="error"
                    icon={<img src={WarningIcon} alt="Warning" />}
                  >
                    {t("common.bypassResonRequired")}
                  </Alert>
                )}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default AuditAuthForm;
