/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  createTheme,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  ThemeProvider,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ClosedIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { CircularLoaderFullPage, SearchField } from "components/core";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { Formik, useFormik } from "formik";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  errorMsgs,
  filterTypes,
  numberConstants,
  ROLE_ACCESS_CODES
} from "utils/constants";
import { eventStatus } from "utils/constants/disaster-module/EventSearchConstants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getSortedData,
  validateUserHasProgramId
} from "utils/helpers";
import { viewDrMediaStyles } from "../DisasterRecoveryStyles";
import DrEventPrintMedia from "./DrEventPrintMedia";
import DrEventReconciliationModal from "./DrEventReconciliationModal";
const ViewDrMedia = () => {
  const { drId, customerId } = useParams();
  const { auth } = useSelector(selectAuth);

  const navigate = useNavigate();
  const location = useLocation();

  //form initial state
  const initialViewDrMedia = {
    personnel: "",
    filter: filterTypes.notReturn,
    atCustomer: false,
    email: "",
    reportType: numberConstants.one,
    printEmail: ""
  };

  //form initialize using useFormik
  const viewDrMediaForm = useFormik({
    initialValues: initialViewDrMedia
  });

  //table styles
  const globalTheme = useTheme();
  const dataTableTheme = createTheme(globalTheme, {
    components: viewDrMediaStyles.dataTableStyles
  });

  const [personnelList, setPersonnelList] = useState([]);
  const [personnelDef, setPersonnelDef] = useState("");
  const [showPersonnelField, setShowPersonnelField] = useState({
    visible: false,
    disable: true
  });

  const [eventStatusData, setEventStatusData] = useState({
    label: "",
    value: ""
  });
  const [drRequestPageData, setDrRequestPageData] = useState(null);
  const [isNotRadio, setIsNotRadio] = useState({ label: "", count: 0 });

  const [personnelError, setPersonnelError] = useState({
    error: false,
    message: ""
  });
  const [isRadio, setIsRadio] = useState({ label: "", count: 0 });
  const [setSelectedAtCustomerBtn, setSetSelectedAtCustomerBtn] = useState({
    visible: false,
    disable: true
  });
  const [inboundCompleteBtn, setInboundCompleteBtn] = useState({
    visible: false,
    disable: true
  });

  const [confirmInboundPopup, setConfirmInboundPopup] = useState({
    message: "",
    open: false
  });
  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false);
  const [openMediaGridDataNotReturn, setOpenMediaGridDataNotReturn] = useState(
    []
  );
  const [containersGridDataNotReturn, setContainersGridDataNotReturn] =
    useState([]);
  const [openMediaGridDataReturn, setOpenMediaGridDataReturn] = useState([]);
  const [containersGridDataReturn, setContainersGridDataReturn] = useState([]);

  const [openMediaTableCount, setOpenMediaTableCount] = useState(0);
  const [containersTableCount, setContainersTableCount] = useState(0);
  const [payloadTexts, setPayloadTexts] = useState({ omText: "", conText: "" });
  const [atCustomerCount, setAtCustomerCount] = useState(0);
  const [
    inboundCompleteInstructionsLabel,
    setInboundCompleteInstructionsLabel
  ] = useState({ text: "", visible: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModalsData, setIsLoadingModalsData] = useState(false);
  //error message showing states
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  //warning message showing states
  const [throwWarning, setThrowWarning] = useState(false);
  const [throwWarningMessage, setThrowWarningMessage] = useState(false);
  //warning inside inboud confirm modal message showing states
  const [throwWarningInbound, setThrowWarningInbound] = useState(false);
  const [throwWarningInboundMessage, setThrowWarningInboundMessage] =
    useState(false);
  //error inside inboud confirm modal message showing states
  const [throwErrorInbound, setThrowErrorInbound] = useState(false);
  const [throwErrorInboundMessage, setThrowErrorInboundMessage] =
    useState(null);

  //states for DR Event Reconciliation modal
  const [reconciliationPopup, setReconciliationPopup] = useState(false);

  //print modal states
  const [printModal, setPrintModal] = useState(false);

  // Set / Clear All At Customer checkbox's
  // TODO: set selected customers toggle
  // const [allAtCheckboxChecked, setAllAtCheckboxChecked] = useState(false);

  //reset page
  const resetPage = () => {
    viewDrMediaForm.resetForm();
    setPersonnelError({
      error: false,
      message: ""
    });
    setThrowWarningInboundMessage(false);
    setThrowErrorInbound(false);
    setThrowErrorInboundMessage(null);
    setReconciliationPopup(false);
    setThrowWarningInbound(false);
    setPersonnelList([]);
    setContainersTableCount(0);
    setOpenMediaTableCount(0);
    setConfirmCancelPopup(false);
    setIsLoadingModalsData(false);
    setOpenMediaGridDataNotReturn([]);
    setContainersGridDataNotReturn([]);
    setOpenMediaGridDataReturn([]);
    setContainersGridDataReturn([]);
    setDrRequestPageData(null);
    setAtCustomerCount(0);
    setPayloadTexts({ omText: "", conText: "" });
    setPersonnelDef("");
    setShowPersonnelField(false);
    setEventStatusData({ label: "", value: "" });
    setIsNotRadio({ label: "", count: 0 });
    setIsRadio({ label: "", count: 0 });
    setSetSelectedAtCustomerBtn({
      visible: false,
      disable: true
    });
    setInboundCompleteBtn({
      visible: false,
      disable: true
    });
    setConfirmInboundPopup({
      message: "",
      open: false
    });
    setIsLoading(false);
    setThrowError(false);
    setThrowErrorMessage(null);
    setThrowWarning(false);
    setThrowWarningMessage(false);
    setInboundCompleteInstructionsLabel("");
  };

  //handle at customer select text box
  const handleAtCustomerColumn = (e) => {
    if (e.target.checked) {
      setAtCustomerCount((pre) => pre + 1);
    } else {
      if (atCustomerCount < 0) {
        setAtCustomerCount(0);
        setAtCustomerCount((pre) => pre - 1);
      } else {
        setAtCustomerCount((pre) => pre - 1);
      }
    }
  };

  //Table columns
  const columns = [
    {
      name: "Media_Number",
      label: t("disasterRecovery.viewDrMediaPage.media#"),
      options: {
        customBodyRender: (value) => (
          <Typography sx={viewDrMediaStyles.cellStyle}>{value}</Typography>
        )
      }
    },
    {
      name: "Media_Status",
      label: t("disasterRecovery.viewDrMediaPage.drStatus")
    },
    {
      name: "Request_Id",
      label: t("disasterRecovery.viewDrMediaPage.requestId"),
      options: {
        display: eventStatusData.value === eventStatus.outBound
      }
    },
    {
      name: "Service_Location",
      label: t("disasterRecovery.viewDrMediaPage.serviceLocation"),
      options: {
        display: eventStatusData.value === eventStatus.outBound
      }
    },

    {
      name: "At_Customer",
      label: t("disasterRecovery.viewDrMediaPage.atCustomer"),
      options: {
        customBodyRender: () => (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleAtCustomerColumn(e)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </FormGroup>
        ),
        display:
          eventStatusData.value === eventStatus.inBound &&
          validateUserHasProgramId(ROLE_ACCESS_CODES.code_267) &&
          viewDrMediaForm.values.filter === filterTypes.notReturn
      }
    },
    {
      name: "Reconciliation",
      label: t("disasterRecovery.viewDrMediaPage.reconciliation"),
      options: {
        display:
          (eventStatusData.value === eventStatus.reconciled ||
            eventStatusData.value === eventStatus.complete) &&
          (viewDrMediaForm.values.filter === filterTypes.notReturn ||
            viewDrMediaForm.values.filter === filterTypes.all)
      }
    }
  ];

  //Open Media Table Options
  const openMediaOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination:
      (openMediaGridDataNotReturn?.length || openMediaGridDataReturn?.length) &&
      true,
    textLabels: {
      body: {
        noMatch:
          (openMediaGridDataNotReturn?.length ||
            openMediaGridDataReturn?.length) === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  //Containers Table Options
  const containersOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination:
      (containersGridDataNotReturn?.length ||
        containersGridDataReturn?.length) &&
      true,
    textLabels: {
      body: {
        noMatch:
          (containersGridDataNotReturn?.length ||
            containersGridDataReturn?.length) === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };

  //handle personnel change
  const handlePersonnel = (event, value) => {
    setPersonnelDef(value);
    viewDrMediaForm.handleChange({
      target: { name: "personnel", value: value?.value }
    });
  };

  useEffect(() => {
    if (personnelDef) {
      setPersonnelError({ error: false, message: "" });
    }
  }, [personnelDef]);

  // get personnel list according to customer id
  const getPersonnelList = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: ROLE_ACCESS_CODES.CODE_50, //getAuthenticatedUserBranch(),
        customer_id: customerId,
        select_all_personnel_flag: ""
      });
      const data = await getResponseData(
        reqBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/authorizedby`,
        1
      );
      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(({ personnel_id, name }) => ({
            label: name,
            value: personnel_id
          }));

          setPersonnelList((prev) => getSortedData(mapping, "label", "asc"));
        }
      } else if (data?.error) {
        setPersonnelList((prev) => []);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setPersonnelList((prev) => []);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // get DR media summary details
  const getViewDrRequestData = async (refreshData) => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        dr_event_id: drId,
        refresh_data:
          viewDrMediaForm.values.filter === filterTypes.notReturn
            ? numberConstants.one
            : numberConstants.zero,
        filter_type:
          viewDrMediaForm.values.filter === filterTypes.notReturn
            ? numberConstants.zero
            : numberConstants.one,
        session_guid: auth.sessionId
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getmediastatussummary}`,
        3
      );
      if (data?.data) {
        let setData = data?.data[2][0];
        setDrRequestPageData(setData);

        //grid data binding
        if (viewDrMediaForm.values.filter === filterTypes.notReturn) {
          const sortedMediaData = getSortedData(
            data?.data[0],
            "Media_Number",
            "asc"
          );
          setOpenMediaGridDataNotReturn(sortedMediaData);
          const sortedContainersData = getSortedData(
            data?.data[1],
            "Media_Number",
            "asc"
          );
          setContainersGridDataNotReturn(sortedContainersData);
        } else {
          const sortedMediaData = getSortedData(
            data?.data[0],
            "Media_Number",
            "asc"
          );
          setOpenMediaGridDataReturn(sortedMediaData);
          const sortedContainersData = getSortedData(
            data?.data[1],
            "Media_Number",
            "asc"
          );
          setContainersGridDataReturn(sortedContainersData);
        }

        //set personnels to personnel search field
        await getPersonnelList();
        setShowPersonnelField({
          visible:
            (setData?.dr_event_status_id === eventStatus.inBound &&
              viewDrMediaForm.values.filter === filterTypes.notReturn) ||
            setData?.dr_event_status_id === eventStatus.reconciled ||
            setData?.dr_event_status_id === eventStatus.complete
              ? true
              : false,
          disable: !(setData?.dr_event_status_id === eventStatus.inBound &&
          validateUserHasProgramId(ROLE_ACCESS_CODES.code_267)
            ? true
            : false)
        });
        if (
          setData?.personnel_contacted_name &&
          setData?.personnel_contacted_id
        ) {
          viewDrMediaForm.setValues({
            ...viewDrMediaForm?.values,
            personnel: setData?.personnel_contacted_id
          });
          setPersonnelDef({
            label: setData?.personnel_contacted_name,
            value: setData?.personnel_contacted_id
          });
        }

        //change default status according to event id data
        setEventStatusData({
          label: setData?.event_status,
          value: setData?.dr_event_status_id
        });
        setIsNotRadio({
          label:
            setData?.dr_event_status_id === eventStatus.outBound
              ? `${t(
                  "disasterRecovery.viewDrMediaPage.notAvailableInInventory"
                )}`
              : `${t(
                  "disasterRecovery.viewDrMediaPage.notReturnedToIronMountain"
                )}`,
          count: setData?.type_0_summary
        });
        setIsRadio({
          label:
            setData?.dr_event_status_id === eventStatus.outBound
              ? `${t("disasterRecovery.viewDrMediaPage.availableInInventory")}`
              : `${t(
                  "disasterRecovery.viewDrMediaPage.returnedToIronMountain"
                )}`,
          count: setData?.type_1_summary
        });
        setSetSelectedAtCustomerBtn({
          visible:
            setData?.dr_event_status_id === eventStatus.inBound &&
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_267) &&
            viewDrMediaForm.values.filter === filterTypes.notReturn
              ? true
              : false,
          disable: true
        });
        setInboundCompleteBtn({
          visible:
            setData?.dr_event_status_id === eventStatus.inBound &&
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_267) &&
            viewDrMediaForm.values.filter === filterTypes.notReturn
              ? true
              : false,
          disable: false
        });

        //get inbound complete instruction label text
        const errorMsgPart1 = await getMessageFromCode(
          String(errorMsgs.errorCode26165)
        );
        const errorMsgPart2 = await getMessageFromCode(
          String(errorMsgs.errorCode26166)
        );

        if (errorMsgPart1 && errorMsgPart2) {
          const errorMessage = `${errorMsgPart1[0]?.descr} ${errorMsgPart2[0]?.descr}`;
          setInboundCompleteInstructionsLabel({
            text: errorMessage,
            visible:
              setData?.dr_event_status_id === eventStatus.inBound &&
              viewDrMediaForm.values.filter === filterTypes.notReturn
                ? true
                : false
          });
        }
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //create new text for inbound complete payload
  const createNewText = (dataArray) => {
    let res = "";
    for (let i = 0; i < dataArray.length; i++) {
      res += dataArray[i] + " ".repeat(14);
    }
    return res;
  };

  //inbound complete button click
  const handleInboundButtonClick = async () => {
    if (
      (!viewDrMediaForm.values.personnel || !personnelDef) &&
      atCustomerCount > 0
    ) {
      const errorMsg = await getMessageFromCode(
        String(errorMsgs.errorCode20259)
      );
      window.scrollTo({ top: 0 });
      if (errorMsg) {
        const replacedSentence = errorMsg[0]?.descr.replace(
          "|",
          t("disasterRecovery.viewDrMediaPage.personalContactedError")
        );
        viewDrMediaForm.setFieldError("personnel", replacedSentence);
        setPersonnelError({ error: true, message: replacedSentence });
      }
    } else {
      const errorMsg = await getMessageFromCode(
        String(errorMsgs.errorCode26168)
      );
      if (errorMsg) {
        const replacedSentence = errorMsg[0]?.descr
          .replace("|", atCustomerCount)
          .replace("|", drRequestPageData?.type_0_summary);
        setConfirmInboundPopup({ message: replacedSentence, open: true });
      }

      const openMediaCol = createNewText(
        openMediaGridDataNotReturn.map((val) => val?.Media_Number)
      );

      const containersCol = createNewText(
        containersGridDataNotReturn.map((val) => val?.Media_Number)
      );

      setPayloadTexts((pre) => ({ ...pre, omText: openMediaCol }));
      setPayloadTexts((pre) => ({ ...pre, conText: containersCol }));
    }
  };

  //show warning message according to error code without changing message
  const warningMessageShowCommon = async (code) => {
    const warningMsg = await getMessageFromCode(String(code));
    if (warningMsg) {
      const replacedSentence = warningMsg[0]?.descr;
      setThrowWarningInbound(true);
      setThrowWarningInboundMessage(replacedSentence);
    }
  };

  // confirm inbound popup
  const handleConfirmInbound = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        dr_event_id: drId,
        om_at_cust_txt: payloadTexts.omText,
        cont_at_cust_txt: payloadTexts.conText,
        contact_person_id: viewDrMediaForm.values.personnel
          ? viewDrMediaForm.values.personnel
          : drRequestPageData?.personnel_contacted_id,
        session_guid: auth.sessionId
      });
      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.mediaInboundComplete}`,
        1
      );
      if (data?.data) {
        const returnCode = data?.data[0][0].error;
        if (returnCode === numberConstants.zero) {
          //Display the reconciliation Modal
          setConfirmInboundPopup(false);
          await getViewDrRequestData();
          setReconciliationPopup(true);
          if (confirmCancelPopup) {
            resetPage();
            navigate(-1, {
              state: location?.state
            });
          }
        } else if (returnCode === errorMsgs.errorCode10001) {
          warningMessageShowCommon(errorMsgs.errorCode10001);
        } else if (returnCode === errorMsgs.errorCode26167) {
          warningMessageShowCommon(errorMsgs.errorCode26167);
        } else if (returnCode === errorMsgs.errorCode26151) {
          warningMessageShowCommon(errorMsgs.errorCode26151);
        } else if (returnCode === errorMsgs.errorCode26152) {
          warningMessageShowCommon(errorMsgs.errorCode26152);
        } else if (returnCode === errorMsgs.errorCode26153) {
          warningMessageShowCommon(errorMsgs.errorCode26153);
        } else if (returnCode === errorMsgs.errorCode26158) {
          warningMessageShowCommon(errorMsgs.errorCode26158);
        } else if (returnCode === errorMsgs.errorCode26159) {
          warningMessageShowCommon(errorMsgs.errorCode26159);
        } else if (returnCode === errorMsgs.errorCode26160) {
          warningMessageShowCommon(errorMsgs.errorCode26160);
        } else if (returnCode === errorMsgs.errorCode26161) {
          warningMessageShowCommon(errorMsgs.errorCode26161);
        } else if (returnCode === errorMsgs.errorCode26163) {
          warningMessageShowCommon(errorMsgs.errorCode26163);
        } else if (returnCode === errorMsgs.errorCode20237) {
          warningMessageShowCommon(errorMsgs.errorCode20237);
        }
      } else if (data?.error) {
        setPersonnelList((prev) => []);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setPersonnelList((prev) => []);
      if (confirmInboundPopup.open) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } else {
        setThrowErrorInboundMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowErrorInbound(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  //handle confirm yes in cancel click
  const handleInboundYes = async () => {
    await handleConfirmInbound();
    setConfirmInboundPopup({
      message: "",
      open: false
    });
  };

  //Cancel Button
  const handleCancelClick = () => {
    if (
      viewDrMediaForm.values.personnel ||
      viewDrMediaForm.values.filter !== filterTypes.notReturn ||
      atCustomerCount > 0
    ) {
      setConfirmCancelPopup(true);
    } else {
      resetPage();
      navigate(-1, {
        state: location?.state
      });
    }
  };

  //handle confirm yes in cancel click
  const handleCancelYes = async () => {
    setConfirmCancelPopup(false);
    await handleInboundButtonClick();
  };

  //handle confirm no in cancel click
  const handleCancelNo = () => {
    resetPage();
    navigate(-1, {
      state: location?.state
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    getViewDrRequestData(
      viewDrMediaForm.values.filter === filterTypes.return
        ? numberConstants.zero
        : numberConstants.one
    );
  }, [viewDrMediaForm.values.filter]);

  useEffect(() => {
    setOpenMediaTableCount(() => {
      if (viewDrMediaForm.values.filter === numberConstants.zero) {
        return openMediaGridDataNotReturn?.length;
      }
      if (viewDrMediaForm.values.filter === numberConstants.one) {
        return openMediaGridDataReturn?.length;
      }
      if (viewDrMediaForm.values.filter === numberConstants.two) {
        return [...openMediaGridDataReturn, ...openMediaGridDataNotReturn]
          ?.length;
      }
    });
    setContainersTableCount(() => {
      if (viewDrMediaForm.values.filter === numberConstants.zero) {
        return containersGridDataNotReturn?.length;
      }
      if (viewDrMediaForm.values.filter === numberConstants.one) {
        return containersGridDataReturn?.length;
      }
      if (viewDrMediaForm.values.filter === numberConstants.two) {
        return [...containersGridDataReturn, ...containersGridDataNotReturn]
          ?.length;
      }
    });
  }, [
    openMediaGridDataReturn,
    openMediaGridDataNotReturn,
    containersGridDataReturn,
    containersGridDataNotReturn
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    resetPage();
    getViewDrRequestData(
      viewDrMediaForm.values.filter === filterTypes.return
        ? numberConstants.zero
        : numberConstants.one
    );
    viewDrMediaForm.setValues({
      ...viewDrMediaForm?.values,
      filter: filterTypes.notReturn
    });
  }, []);

  const handleBackButtonClick = () => {
    resetPage();
    navigate(-1, {
      state: location?.state
    });
  };

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage loading={isLoading || isLoadingModalsData} />

      {/* Error alert popup */}
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      {/*Warinng alert box*/}
      <Collapse in={throwWarning}>
        <Alert
          severity="warning"
          icon={<img src={WarningIcon} alt="warning" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowWarning(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {throwWarningMessage}
        </Alert>
      </Collapse>

      <Formik>
        <form>
          <Grid
            display={"flex"}
            direction={"column"}
            flexDirection={"column"}
            gap={spacing.verticalMargin20}
          >
            {/* personnel section */}

            <Grid container spacing={spacing.verticalMargin20}>
              {showPersonnelField.visible && (
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  {/*Security group search field*/}

                  <SearchField
                    label={t(
                      "disasterRecovery.viewDrMediaPage.personalContacted"
                    )}
                    labelId="security-group-search"
                    options={personnelList}
                    clearIcon={<ClosedIcon />}
                    popupIcon={<ArrowDown />}
                    value={personnelDef}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={handlePersonnel}
                    error={personnelError.error}
                    helperText={personnelError.message}
                    required
                    disable={showPersonnelField.disable}
                  />
                </Grid>
              )}
              <Grid item>
                <Typography
                  variant="subtitle1"
                  sx={viewDrMediaStyles.eventStatus}
                >
                  {t("disasterRecovery.viewDrMediaPage.eventStatus")}
                </Typography>
                <Typography id="request_delivery_8">
                  {eventStatusData.label}
                </Typography>
              </Grid>
            </Grid>

            {/*filter section*/}
            <FormControl>
              <RadioGroup
                id="radio-group"
                name="filter"
                column
                value={viewDrMediaForm.values.filter}
                onChange={viewDrMediaForm.handleChange}
              >
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <Typography sx={viewDrMediaStyles.filterTitle}>
                      {t("disasterRecovery.viewDrMediaPage.filter")}
                    </Typography>
                  </Grid>
                  <Typography sx={viewDrMediaStyles.filterTitle}>
                    {t("disasterRecovery.viewDrMediaPage.count")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <FormControlLabel
                      id="radio-form-control-1"
                      value={filterTypes.notReturn}
                      control={<Radio />}
                      label={isNotRadio.label}
                    />
                  </Grid>
                  <Typography>{isNotRadio.count}</Typography>
                </Grid>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <FormControlLabel
                      id="radio-form-control-1"
                      value={filterTypes.return}
                      control={<Radio />}
                      label={isRadio.label}
                    />
                  </Grid>
                  <Typography>{isRadio.count}</Typography>
                </Grid>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <FormControlLabel
                      id="radio-form-control-1"
                      value={filterTypes.all}
                      control={<Radio />}
                      label={t("disasterRecovery.viewDrMediaPage.all")}
                    />
                  </Grid>
                  <Typography>
                    {Number(isRadio.count) + Number(isNotRadio.count)}
                  </Typography>
                </Grid>
              </RadioGroup>
            </FormControl>
            {/*instructions section*/}
            {inboundCompleteInstructionsLabel.visible && (
              <Typography sx={viewDrMediaStyles.instructions}>
                {inboundCompleteInstructionsLabel.text}
              </Typography>
            )}
            <Divider />

            {/*open media table*/}

            <Typography sx={viewDrMediaStyles.tableTitle}>
              {t("disasterRecovery.viewDrMediaPage.openMedia")}
            </Typography>
            <ThemeProvider theme={dataTableTheme}>
              <MUIDataTable
                columns={columns}
                options={openMediaOptions}
                data={
                  (viewDrMediaForm.values.filter === numberConstants.zero &&
                    openMediaGridDataNotReturn) ||
                  (viewDrMediaForm.values.filter === numberConstants.one &&
                    openMediaGridDataReturn) ||
                  (viewDrMediaForm.values.filter === numberConstants.two && [
                    ...openMediaGridDataReturn,
                    ...openMediaGridDataNotReturn
                  ])
                }
                title={
                  <Typography style={viewDrMediaStyles.titleStyle}>
                    {openMediaTableCount >= 1
                      ? `${openMediaTableCount}  ${t("common.recordFound")}`
                      : `${openMediaTableCount}  ${t("common.recordsFound")}`}
                  </Typography>
                }
              />
            </ThemeProvider>

            {/*containers media table*/}

            <Typography sx={viewDrMediaStyles.tableTitle}>
              {t("disasterRecovery.viewDrMediaPage.containers")}
            </Typography>
            <ThemeProvider theme={dataTableTheme}>
              <MUIDataTable
                columns={columns}
                options={containersOptions}
                data={
                  (viewDrMediaForm.values.filter === numberConstants.zero &&
                    containersGridDataNotReturn) ||
                  (viewDrMediaForm.values.filter === numberConstants.one &&
                    containersGridDataReturn) ||
                  (viewDrMediaForm.values.filter === numberConstants.two && [
                    ...containersGridDataReturn,
                    ...containersGridDataNotReturn
                  ])
                }
                title={
                  <Typography style={viewDrMediaStyles.titleStyle}>
                    {containersTableCount >= 1
                      ? `${containersTableCount}  ${t("common.recordFound")}`
                      : `${containersTableCount}  ${t("common.recordsFound")}`}
                  </Typography>
                }
              />
            </ThemeProvider>
            <Divider />

            {/* Button Section */}
            <Stack
              id="view-dr-media-button-section"
              direction="row"
              spacing={spacing.verticalMargin20}
              marginTop={spacing.verticalMargin32}
            >
              {/* Cancel button */}
              {inboundCompleteBtn.visible ? (
                <Button
                  id="view-dr-media-cancel"
                  variant="outlined"
                  size="medium"
                  type="button"
                  onClick={handleCancelClick}
                >
                  {t("common.cancel")}
                </Button>
              ) : (
                <Button
                  id="view-dr-media-back"
                  variant="outlined"
                  size="medium"
                  type="button"
                  onClick={handleBackButtonClick}
                >
                  {t("common.back")}
                </Button>
              )}
              {/* Print button */}

              <Button
                id="view-dr-media-print"
                variant="outlined"
                size="medium"
                type="button"
                disabled={
                  !(drRequestPageData?.dr_event_status_id ===
                  eventStatus.inBound
                    ? drRequestPageData?.personnel_contacted_id ||
                      atCustomerCount > 0
                      ? true
                      : false
                    : true)
                }
                onClick={() => setPrintModal(true)}
              >
                {t("common.print")}
              </Button>

              {/* Set Selected At Customer button */}
              {setSelectedAtCustomerBtn.visible && (
                <Button
                  id="view-dr-media-cancel"
                  variant="outlined"
                  size="medium"
                  type="button"
                  disabled={atCustomerCount <= 1}
                  // TODO:
                  // onClick={handleAllAtCustomerChange}
                >
                  {t("disasterRecovery.viewDrMediaPage.setSelectedAtCustomer")}
                </Button>
              )}
              {/* Inbound Complete button */}
              {inboundCompleteBtn.visible && (
                <Button
                  id="view-dr-media-cancel"
                  variant="contained"
                  size="medium"
                  type="button"
                  disabled={inboundCompleteBtn.disable}
                  onClick={handleInboundButtonClick}
                >
                  {t("disasterRecovery.viewDrMediaPage.inboundComplete")}
                </Button>
              )}
            </Stack>
          </Grid>
          {/*DR Event Reconciliation Modal*/}
          <DrEventReconciliationModal
            modalPopupOpen={reconciliationPopup}
            setReconciliationPopup={setReconciliationPopup}
            showNo={true}
            showCancel={true}
            setIsLoading={setIsLoadingModalsData}
            form={viewDrMediaForm}
            drId={drId}
            sessonId={auth.sessionId}
          />
          {/*DR Event Print Media Modal*/}

          <DrEventPrintMedia
            open={printModal}
            setPrintModal={setPrintModal}
            form={viewDrMediaForm}
            statusId={eventStatusData.value}
            setIsLoading={setIsLoadingModalsData}
            drId={drId}
            sessonId={auth.sessionId}
            drRequestPageData={drRequestPageData}
          />
        </form>
      </Formik>

      {/*Confirm popup after inbound button click*/}
      <ConfirmPopup
        modalPopupOpen={confirmInboundPopup.open}
        message={confirmInboundPopup.message}
        handleYes={() => handleInboundYes()}
        showNo={false}
        showCancel={true}
        handleCancel={() =>
          setConfirmInboundPopup({
            message: "",
            open: false
          })
        }
        showOk={true}
        throwWarning={throwWarningInbound}
        throwWarningMessage={throwWarningInboundMessage}
        setThrowWarning={setThrowWarningInbound}
        throwError={throwErrorInbound}
        throwErrorMessage={throwErrorInboundMessage}
        setThrowError={setThrowErrorInbound}
      />
      {/*Confirm popup after cancel button click*/}
      <ConfirmPopup
        modalPopupOpen={confirmCancelPopup}
        handleYes={() => handleCancelYes()}
        handleNo={() => handleCancelNo()}
        handleCancel={() => setConfirmCancelPopup(false)}
        showNo={true}
        showCancel={true}
      />
    </>
  );
};

export default ViewDrMedia;
