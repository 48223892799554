import { Stack } from "@mui/material";
import CustomerScheduleLayout from "components/customer-information/customer-schedule/CustomerScheduleLayout";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const CustomerSchedule = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("customerSchedule.customerScheduleTitle")} />
      <CustomerScheduleLayout />
    </Stack>
  );
};

export default CustomerSchedule;
