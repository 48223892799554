import { Grid, Paper, TableContainer, Typography } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { dateFormatEu, DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { DetailsStyles } from "./DetailsStyles";

export const DiscrepancyDetailsTable = ({ details }) => {
  const columns = [
    {
      name: "detected_datetime",
      label: t("discrepancyManagementDetail.detectedDateTime"),
      options: {
        customBodyRender: (value) => {
          return dayjs.utc(value).format(dateFormatEu);
        }
      }
    },
    {
      name: "file_section_type_descr",
      label: t("discrepancyManagementDetail.detectedIn")
    },
    {
      name: "discrepancy_type_code",
      label: t("discrepancyManagementDetail.discrepancyType")
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true
  };

  return (
    <>
      <Grid item md={6} lg={3} xs={12}>
        <Typography style={DetailsStyles.subHeading}>
          {t("discrepancyManagementDetail.discrepancyDetails")}
        </Typography>
      </Grid>
      <Paper>
        <Typography style={DetailsStyles.subHeading2}>
          {details?.length} records found
        </Typography>
        <TableContainer>
          <Grid item xs={12} lg={12} marginTop={4}>
            <MUIDataTable
              columns={columns}
              data={[...details]}
              options={options}
            />
          </Grid>
        </TableContainer>
      </Paper>
    </>
  );
};
