import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import { t } from "i18next";
import { useMemo } from "react";
import { TAG_TYPES } from "utils/constants";
import CustomerDistribution from "./CustomerDistribution";
import Distribution from "./Distribution";
import LocationForm from "./LocationForm";
import LogicalVaultFrom from "./LogicalVaultFrom";

const Branch = ({
  values,
  requestedListBy,
  handleChangeOnChange,
  auditTypeList,
  customers,
  setIsLoading,
  currentBranch,
  setThrowError,
  setThrowErrorMessage,
  handleReSetAudit,
  auditDetailForm,
  isViewMode,
  viewData,
  localeByBranch,
  allAuditData,
  setSeverity,
  directionData
}) => {
  const handleGetComponent = () => {
    switch (values?.auditType) {
      case t("openMediaAuditDetail.customerDistribution"):
        return (
          <CustomerDistribution
            customers={customers}
            setIsLoading={setIsLoading}
            currentBranch={currentBranch}
            setThrowError={setThrowError}
            setThrowErrorMessage={setThrowErrorMessage}
            auditDetailForm={auditDetailForm}
            isViewMode={!isViewMode}
            viewData={viewData}
            localeByBranch={localeByBranch}
            allAuditData={allAuditData}
          />
        );
      case t("openMediaAuditDetail.distribution"):
        return (
          <Distribution
            customers={customers}
            setIsLoading={setIsLoading}
            currentBranch={currentBranch}
            setThrowError={setThrowError}
            setThrowErrorMessage={setThrowErrorMessage}
            handleReSetAudit={handleReSetAudit}
            auditDetailForm={auditDetailForm}
            isViewMode={!isViewMode}
            viewData={viewData}
            localeByBranch={localeByBranch}
            allAuditData={allAuditData}
            directionData={directionData}
          />
        );
      case t("openMediaAuditDetail.location"):
        return (
          <LocationForm
            customers={customers}
            setIsLoading={setIsLoading}
            currentBranch={currentBranch}
            setThrowError={setThrowError}
            setThrowErrorMessage={setThrowErrorMessage}
            auditDetailForm={auditDetailForm}
            isViewMode={isViewMode}
            viewData={viewData}
            localeByBranch={localeByBranch}
            allAuditData={allAuditData}
          />
        );
      case t("openMediaAuditDetail.logicalVault"):
        return (
          <LogicalVaultFrom
            customers={customers}
            setIsLoading={setIsLoading}
            currentBranch={currentBranch}
            setThrowError={setThrowError}
            setThrowErrorMessage={setThrowErrorMessage}
            auditDetailForm={auditDetailForm}
            isViewMode={!isViewMode}
            viewData={viewData}
            localeByBranch={localeByBranch}
            allAuditData={allAuditData}
            setSeverity={setSeverity}
          />
        );
      default:
        return <></>;
    }
  };
  const auditTypeBaseComponent = useMemo(
    () => handleGetComponent(),
    //eslint-disable-next-line
    [
      values?.auditType,
      auditDetailForm,
      viewData,
      isViewMode,
      localeByBranch,
      allAuditData,
      directionData
    ]
  );
  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={isViewMode}>
            <InputLabel id="requested-by-label">
              {t("openMediaAuditDetail.requestedBy")}
            </InputLabel>
            <Select
              labelId="requested-by-label"
              id="requested-by"
              value={values?.requestedBy}
              name="requestedBy"
              label={t("openMediaAuditDetail.requestedBy")}
              onChange={(e) => handleChangeOnChange(e.target, true)}
            >
              {requestedListBy?.map((item) => {
                return (
                  <MenuItem value={item?.label} key={item?.name}>
                    {item?.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={Boolean(
              !values?.requestedBy ||
                values?.requestedBy === TAG_TYPES?.customer ||
                isViewMode
            )}
          >
            <InputLabel id="audit-label-type">
              {t("openMediaAuditDetail.auditType")}
            </InputLabel>
            <Select
              labelId="audit-label-type"
              id="audit-type"
              name="auditType"
              value={values?.auditType}
              label={t("openMediaAuditDetail.auditType")}
              onChange={(e) => handleChangeOnChange(e.target)}
            >
              {auditTypeList?.map((item) => {
                return (
                  <MenuItem value={item?.label} key={item?.name}>
                    {item?.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {auditTypeBaseComponent}
      </Grid>
    </Stack>
  );
};

export default Branch;
