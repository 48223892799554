import {
  cancelDestructRequest,
  destructRequestCommentDelete,
  destructRequestCommentInsert,
  getAllDestructionStatusTypes,
  getAllPersonnels,
  getCustomerMdrDetails,
  getDesReqComment,
  getServiceLocation,
  getServiceLocationByCustomerTrigger,
  postNewDestructRequest,
  postUpdateDestructRequest,
  verifyPersonnelPermissionStatus
} from "services/common";
import { TAG_TYPES } from "utils/constants";
import { baseApi } from "../baseApi";

export const requestDetailDeliveryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    //get service location by customer
    getServiceLocation: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getServiceLocation(props);
          // console.log(response, "response==");
          return {
            data: response?.map(({ number, customerId }) => ({
              label: number,
              value: customerId
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.authorizationPersonnel, id: arg.personnelId }]
          : [TAG_TYPES.authorizationPersonnel]
    }),
    //get service location by customer
    getServiceLocationByCustomerTrigger: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getServiceLocationByCustomerTrigger(props);
          return {
            data: response?.map(({ customer_id, number }) => ({
              label: number?.trim() ? number?.trim() : "",
              value: customer_id ? customer_id : ""
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.authorizationPersonnel, id: arg.personnelId }]
          : [TAG_TYPES.authorizationPersonnel]
    }),
    //get customer media destruction details
    getCustomerMdrDetails: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getCustomerMdrDetails(props);
          return {
            data: response?.map((obj) => obj)
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.customer, id: arg.customerId }]
          : [TAG_TYPES.customer]
    }),
    //get all personals
    getAllPersonnels: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllPersonnels(props);
          return {
            data: response?.map(({ name, personnelId, customerId }) => ({
              label: name,
              value: personnelId,
              customerId: customerId
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.authorizationPersonnel }]
          : [TAG_TYPES.authorizationPersonnel]
    }),
    //get all destruction status types
    getAllDestructionStatusTypes: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllDestructionStatusTypes(props);

          return {
            data: response?.map(({ descr, destruct_request_status_id }) => ({
              label: descr ? descr : "",
              value: destruct_request_status_id
                ? destruct_request_status_id
                : ""
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestStatus]
    }),
    //get destruction request comment
    getDesReqComment: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getDesReqComment(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desReqComment]
    }),
    //verify personnel permission
    verifyPersonnelPermissionStatus: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await verifyPersonnelPermissionStatus(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.verifyPermission]
    }),
    //post destruction request
    postNewDestructRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await postNewDestructRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.destructRequestSave]
    }),
    //update destruction request
    postUpdateDestructRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await postUpdateDestructRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desSave]
    }),
    //cancel destruction request
    cancelDestructRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await cancelDestructRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desSave]
    }),
    //destruct request comment insert
    destructRequestCommentInsert: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await destructRequestCommentInsert(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desSave]
    }),
    //destruct request comment delete
    destructRequestCommentDelete: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await destructRequestCommentDelete(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.desSave]
    })
  })
});
