import { Stack } from "@mui/material";
import { PageTitle } from "components/shared";
import BranchOutagePickListScreen from "../../components/open-media-processing/branch-outage-pick-list-completion/BranchOutagePickListScreen";
import i18n from "../../i18n";

const BranchOutagePickListCompletion = () => {
  return (
    <>
      <Stack>
        <PageTitle title={i18n.t("branchOutagePickList.title")} />
        <BranchOutagePickListScreen />
      </Stack>
    </>
  );
};
export default BranchOutagePickListCompletion;
