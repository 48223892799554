import dayjs from "dayjs";
import { getDocs, query } from "firebase/firestore";
import AxiosInstance from "services/axiosService";
import {
  api_results_collection_resultset0,
  request_type_collection
} from "services/firebase";

/**
 * @module requestSearch request media detail search feature specific requests module
 */

export const requestSearch = {
  /**
   * Get all request types
   *
   * @param {object} props
   * @param {string} props.dreFlag dre_flag = Y (IF the request type dropdown has Disaster Recovery option)
   * @returns
   */
  getAllRequestTypes: async ({ dreFlag }) => {
    let result = [];
    const q = query(request_type_collection);
    //fetch request types collection
    const querySnapshot = await getDocs(q);
    //loop through the snapshot and add it to a variable
    querySnapshot.forEach((doc) => {
      const item = { id: doc?.id, ...doc?.data() };
      if (dreFlag === "N") {
        if (item.request_type_id !== "6") result.push(item);
      } else {
        result.push(item);
      }
    });
    // sort request types alphabetically ASC
    return result.sort((a, b) =>
      a.descr.localeCompare(b.descr, "en", { sensitivity: "base" })
    );
  },
  /**
   * Get all routes
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.dayCodes day codes generate based on from and to date, for more info refer comments of src\utils\helpers\generateDayPattern.js
   * @returns
   */

  getAllRoutes: async ({ dayCodes, mainDistrictId }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      day_codes: dayCodes
    });

    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getvalidscheduledroutes`,
      requestBody
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },

  getAllSearchRequestStatuses: async ({ mainDistrictId }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId
    });

    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getallrequeststatusrows`,
      requestBody
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },
  /**
   * Media request search
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.requestId id of the selected request
   * @param {string} props.notCompletedFlag not completed flag
   * @param {string} props.limitRowCountFlag limit row count flag
   * @param {string} props.serviceDateFrom start search from service date
   * @param {string} props.serviceDateTo service date
   * @returns
   */
  searchRequest: async ({
    customerId = "",
    requestStatusId = "",
    requestTypeId = "",
    routeName = "",
    serviceDateFrom,
    serviceDateTo,
    mainDistrictId
  }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      service_date_from: dayjs(serviceDateFrom).format("YYYY-MM-DD"),
      service_date_to: dayjs(serviceDateTo).format("YYYY-MM-DD"),
      request_type_id: requestTypeId,
      request_status_id: requestStatusId,
      customer_id: customerId,
      route_code: routeName,
      not_completed_flag: "N",
      limit_row_count_flag: "N"
    });

    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getsummaryrequestinfo`,
      requestBody
    ).then((resp) => resp.data);
    // Assuming docId is a property in cfResponse, update the following line
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => {
      response.push(doc.data());
    });

    // Sort the response array based on service_date as priority 1 and request_id as priority 2
    response.sort((a, b) => {
      // both service dates equals
      if (dayjs(a.service_date).isSame(dayjs(b.service_date))) {
        return Number(a.request_id) - Number(b.request_id);
      }

      // compare service_date
      return dayjs(a.service_date).isAfter(dayjs(b.service_date)) ? 1 : -1;
    });
    return response;
  }
};
