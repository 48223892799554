import spacing from "styles/spacing";

export const GenerationSearch = {
  generationDetail: {
    width: "208px"
  },
  tableColumn: {
    flex: 1,
    minWidth: "200px"
  },
  TitleStyle: { mt: spacing.horizontalMargin20 }
};
