import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  downloadReportOptions,
  errorMsgs,
  strZerosIdWithZero
} from "utils/constants";

import {
  clear,
  hash,
  star,
  trChecker
} from "utils/constants/container-processing/ReassignTransport";

import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getUserName
} from "utils/helpers";
import { ContainerProcessingStyles } from "../ContainerProcessingStyles";
import {
  getAvailableTransports,
  getCustomerDetails,
  getReassignTransports,
  transportRepots
} from "./Services";

const Transport = () => {
  // manage states for Data fetching
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [downLoadModal, setDownLoadMoadal] = useState(false);
  const [failureTransportModal, setFailureTransportModal] = useState(false);
  const [errorTextMessage, setErrorTextMessage] = useState("");
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [isEnabledOldTransportInput, setIsEnabledOldTransportInput] =
    useState(true);
  const [isEnabledNewTransportInput, setIsEnabledNewTransportInput] =
    useState(true);
  const [oldContainerId, setOldContainerId] = useState("");
  const [isEnableSave, setIsEnableSave] = useState(true);
  const [getNewTransports, setGetNewTransports] = useState([]);
  const [newTransportId, setNewTransportId] = useState("");
  const [selectedNewTranport, setSelectedNewTransport] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [oldTransportInput, setOldTransportInput] = useState("");
  const [throwError, setThrowError] = useState(false);
  const { currentBranch } = useSelector(selectAuth);
  const [customers, setCustomers] = useState([ALL_SELECTION]);
  const [isOpen, setIsOpen] = useState(false);
  const [reprintShipModal, setReprintShipModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [reaAssignTransportData, setReAssignTransportData] = useState({});
  const [selectedDoc, setSelectedDoc] = useState(downloadReportOptions[0]);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  const { auth } = useSelector(selectAuth);
  //  getCustomerDetails SP and its cheking selected customer is valid or not
  const CustomerSelctedCall = async (selctedCustomer) => {
    setIsLoading(true);
    try {
      const res = await getCustomerDetails(selctedCustomer, mainDistrictId);
      if (res.data) {
        if (Number(res.data[0][0]?.error) > 0) {
          const errorMsg = await getMessageFromCode(res.data[0][0]?.error);
          let replaceMessage = errorMsg[0]?.descr.replace(
            "|",
            selctedCustomer.label.split(" ")[0]
          );
          setThrowErrorMessage(
            replaceMessage || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
          setOldTransportInput("");
          setIsEnabledOldTransportInput(true);
          setSelectedNewTransport("");
          setIsEnabledNewTransportInput(true);
        } else {
          setThrowError(false);
          setIsEnabledOldTransportInput(false);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  // this function handing selected a valid the Customer and enable the old transport Feild
  const handleCustomerChange = async (e, newValue, reason) => {
    if (reason === clear) {
      if (oldTransportInput !== "" && selectedNewTranport !== "") {
        setConfirmPopup(true);
      } else {
        setThrowError(false);
        setIsEnabledOldTransportInput(true);
        setIsEnabledNewTransportInput(true);
        setIsEnableSave(true);
        setOldTransportInput("");
        setSelectedNewTransport("");
        setSelectedCustomer("");
      }
      return;
    }
    if (newValue === null) {
      setThrowError(false);
      setIsEnabledOldTransportInput(true);
      setIsEnabledNewTransportInput(true);
      setIsEnableSave(true);
      setOldTransportInput("");
      setSelectedNewTransport("");
      setSelectedCustomer("");
    } else if (oldTransportInput !== "" && selectedNewTranport === "") {
      setIsEnabledOldTransportInput(true);
      setIsEnabledNewTransportInput(true);
      setIsEnableSave(true);
      setOldTransportInput("");
      setSelectedNewTransport("");
      await CustomerSelctedCall(newValue);
      setSelectedCustomer(newValue);
    } else if (oldTransportInput !== "" && selectedNewTranport !== "") {
      setConfirmPopup(true);
    } else if (newValue !== null && oldTransportInput && selectedNewTranport) {
      setConfirmPopup(true);
    } else {
      newValue = { label: newValue.label, value: newValue.value.trim() };
      await CustomerSelctedCall(newValue);
      setSelectedCustomer(newValue);
    }
  };
  // sorting function
  const sortTransportItems = (items) => {
    // Defensive check for empty items
    if (!Array.isArray(items)) return [];

    const extractSortingInfo = (item) => {
      // Ensure item and container_number are defined
      if (!item || !item.container_number) {
        return { baseNumber: 0, type: "", original: item };
      }

      const baseNumber =
        parseInt(item.container_number.replace(/\D/g, ""), 10) || 0;
      const type =
        item.temp_transport_flag === Y_CHECKER
          ? star
          : item.container_number.includes(hash)
            ? hash
            : "";
      return { baseNumber, type, original: item };
    };

    return items
      .map(extractSortingInfo)
      .sort((infoA, infoB) => {
        // Sort by type first: numeric, '*', '#'
        if (infoA.type !== infoB.type) {
          if (infoA.type === "") return -1;
          if (infoB.type === "") return 1;
          if (infoA.type === star && infoB.type === hash) return -1;
          if (infoA.type === hash && infoB.type === star) return 1;
        }

        // If types are the same, sort by base number
        return infoA.baseNumber - infoB.baseNumber;
      })
      .map((info) => info.original);
  };
  //  old transport input handler
  const handleInputOldTransportChange = async (event) => {
    const value = event.target.value.trim();
    setOldTransportInput(value);
    setThrowError(false);
    if (!value) {
      setSelectedNewTransport("");
      setIsEnabledNewTransportInput(true);
      setIsEnableSave(true);
      return;
    }
    // Validate input immediately
    if (!validateInput(value)) {
      setThrowError(true);
      setThrowErrorMessage(
        `${t("reassignTransportContents.oldTransportErrorMessage")}`
      );
      setIsEnabledNewTransportInput(true);
      return; // Exit function if validation fails
    }
    try {
      setIsLoading(true);
      let res = await getAvailableTransports(
        value,
        mainDistrictId,
        selectedCustomer
      );
      if (res.data) {
        setOldContainerId(res.data[2][0].old_container_id);
        if (Array.isArray(res?.data[1]) && res.data[1]?.length > 0) {
          const sortedItems = sortTransportItems(res?.data[1]);
          setGetNewTransports(sortedItems);
          setIsEnabledNewTransportInput(false);
        }

        if (Number(res.data[0][0]?.message_id) > 0) {
          const errorMsg = await getMessageFromCode(res.data[0][0].message_id);
          let pipvalues = [
            res.data[0][0]?.pipe_val_1,
            res.data[0][0]?.pipe_val_2,
            res.data[0][0]?.pipe_val_3
          ];
          let replacedText = errorMsg[0]?.descr || "";
          pipvalues.forEach((pipval) => {
            if (pipval) {
              replacedText = replacedText.replace("|", pipval);
            }
          });
          setThrowErrorMessage(
            replacedText || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        }
        if (res.data[0]?.length === 0 && res.data[1]?.length === 0) {
          const errorMsg = await getMessageFromCode(
            errorMsgs.messageCodes26019
          );
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  //  old Transport Input Validations
  const validateInput = (value) => {
    if (value.length !== 6) {
      return false;
    }
    // Check that it starts with 'TR'
    if (!value.startsWith(trChecker)) {
      return false;
    }
    // Check that the last 4 characters are alphanumeric
    if (!/^[A-Z0-9]{4}$/.test(value.slice(2))) {
      return false;
    }
    return true;
  };

  // selcte the new Transports
  const handleNewTransportChange = async (event) => {
    const newValue = event.target.value;
    if (newValue === null) {
      setThrowError(false);
      setSelectedNewTransport("");
      setIsEnableSave(true);
    } else {
      const selectedTransport = getNewTransports.find(
        (item) => item.container_number === newValue
      );
      setNewTransportId(selectedTransport.container_id);
      setSelectedNewTransport(newValue);
      setIsEnableSave(false);
    }
  };
  //  This Function Handling fetching all the customers
  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));
        setCustomers((prev) => data);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  // customers Feild props for common TextField

  const customersFieldProps = {
    options: customers,
    handleOnChange: handleCustomerChange,
    value: selectedCustomer,
    required: true,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };
  // this function Hnadling Open the save modal ans display the error Message
  const onSaveHandler = async () => {
    try {
      setIsLoading(true);
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode25151);
      let replaceOldContainerNumber = errorMsg[0]?.descr.replace(
        "|",
        oldTransportInput
      );
      let replacedNewContainerNumber = replaceOldContainerNumber.replace(
        "|.",
        selectedNewTranport
      );
      setErrorTextMessage(
        replacedNewContainerNumber || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setIsLoading(false);
      setIsOpen(true);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // this function handling reassign transport SP  logics
  const handleReassignTransport = async () => {
    try {
      setIsLoading(true);
      let res = await getReassignTransports(
        mainDistrictId,
        oldContainerId,
        newTransportId
      );
      if (res.data) {
        setReAssignTransportData(res.data[1][0]);
        if (
          res.data[0][0]?.error_code &&
          Number(res.data[0][0]?.error_code) > 0
        ) {
          setFailureTransportModal(true);
          setIsOpen(false);
          const errorMsg = await getMessageFromCode(res.data[0][0].error_code);
          setErrorTextMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
        } else {
          const errorMsg = await getMessageFromCode(errorMsgs.errorCode25138);
          setErrorTextMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );

          setIsOpen(false);
          setReprintShipModal(true);
          setIsLoading(false);
        }
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerate = async () => {
    if (reaAssignTransportData?.print_label_flag === Y_CHECKER) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorcode25369);
      setErrorTextMessage(
        errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setReprintShipModal(false);
      setErrorModal(true);
    } else {
      setReprintShipModal(false);
      setSelectedCustomer("");
      setOldTransportInput("");
      setSelectedNewTransport("");
      setResetModal(false);
      setIsEnableSave(true);
      setIsEnabledNewTransportInput(true);
      setIsEnabledOldTransportInput(true);
    }
  };
  //final case or success case Resets the form state to initial values
  const handleResetForm = () => {
    setSelectedCustomer("");
    setOldTransportInput("");
    setSelectedNewTransport("");
    setResetModal(false);
    setIsEnableSave(true);
    setIsEnabledNewTransportInput(true);
    setIsEnabledOldTransportInput(true);
  };
  //  Handles confirming customer selection with potential unsaved changes
  const handleConfirmCustomer = async () => {
    setConfirmPopup(false);
    onSaveHandler();
  };
  const handleNo = () => {
    setSelectedCustomer("");
    setOldTransportInput("");
    setSelectedNewTransport("");
    setResetModal(false);
    setIsEnableSave(true);
    setIsEnabledNewTransportInput(true);
    setIsEnabledOldTransportInput(true);
    setConfirmPopup(false);
  };

  function padIdWithZeros(request_id) {
    const idStr = request_id.toString();
    const paddedId =
      strZerosIdWithZero.substring(
        0,
        Math.min(10 - idStr.length, strZerosIdWithZero.length)
      ) + idStr;
    return paddedId;
  }
  const downloadHandler = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      let res = await transportRepots(
        mainDistrictId,
        newTransportId,
        padIdWithZeros(reaAssignTransportData.request_id),
        getUserName(auth?.user),
        selectedDoc?.type?.toUpperCase()
      );
      if (!res.success) {
        setThrowErrorMessage(res.error);
        setThrowError(true);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleGeneratModalClose = () => {
    setErrorModal(false);
    setReprintShipModal(false);
    setDownLoadMoadal(true);
  };
  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {downLoadModal && (
        <DownloadPopup
          title={t("reassignTransportContents.downloadReport")}
          showPrintModal={downLoadModal}
          downloadOptions={downloadReportOptions}
          onCancleClick={() => {
            setDownLoadMoadal(false);
            setIsEnableSave(true);
            setIsEnabledNewTransportInput(true);
            setIsEnabledOldTransportInput(true);
            setSelectedCustomer("");
            setOldTransportInput("");
            setSelectedNewTransport("");
            setThrowError(false);
            setErrorModal(false);
            setReprintShipModal(false);
          }}
          onDownloadClick={downloadHandler}
          setSelectedValue={setSelectedDoc}
        />
      )}
      {failureTransportModal && (
        <ConfirmPopup
          modalPopupOpen={failureTransportModal}
          message={errorTextMessage}
          handleYes={() => {
            setFailureTransportModal(false);
            setIsEnableSave(true);
            setIsEnabledNewTransportInput(true);
            setIsEnabledOldTransportInput(true);
            setSelectedCustomer("");
            setOldTransportInput("");
            setSelectedNewTransport("");
          }}
          handleCancel={() => {
            setFailureTransportModal(false);
          }}
          showCancel={true}
          showNo={false}
          showOk={true}
        />
      )}
      {confirmPopup && (
        <ConfirmPopup
          modalPopupOpen={confirmPopup}
          handleYes={handleConfirmCustomer}
          handleCancel={() => setConfirmPopup(false)}
          handleNo={handleNo}
          showCancel={true}
          showNo={true}
          showOk={true}
        />
      )}
      {resetModal && (
        <ConfirmPopup
          modalPopupOpen={resetModal}
          message={errorTextMessage}
          handleYes={handleResetForm}
          showCancel={false}
          showNo={false}
          showOk={true}
        />
      )}
      {isOpen && (
        <ConfirmPopup
          modalPopupOpen={isOpen}
          message={errorTextMessage}
          handleCancel={() => {
            setIsOpen(false);
          }}
          handleYes={handleReassignTransport}
          showCancel={true}
          showNo={false}
          showOk={true}
        />
      )}
      {reprintShipModal && (
        <ConfirmPopup
          modalPopupOpen={reprintShipModal}
          message={errorTextMessage}
          handleYes={handleGenerate}
          showCancel={false}
          showNo={false}
          showOk={true}
        />
      )}
      {errorModal && (
        <ConfirmPopup
          modalPopupOpen={errorModal}
          message={errorTextMessage}
          handleYes={handleGeneratModalClose}
          handleCancel={() => {
            setDownLoadMoadal(false);
            setIsEnableSave(true);
            setIsEnabledNewTransportInput(true);
            setIsEnabledOldTransportInput(true);
            setSelectedCustomer("");
            setOldTransportInput("");
            setSelectedNewTransport("");
            setThrowError(false);
            setErrorModal(false);
          }}
          showCancel={true}
          showNo={false}
          showOk={true}
        />
      )}
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Box sx={ContainerProcessingStyles.marginTop25}>
        <Grid container spacing={2} alignItems="">
          <Grid item md={6} lg={3} xs={12}>
            <CustomersField {...customersFieldProps} />
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            <FormControl fullWidth variant="outlined">
              <>
                <TextField
                  type="text"
                  disabled={isEnabledOldTransportInput}
                  value={oldTransportInput}
                  onChange={(e) => handleInputOldTransportChange(e)}
                  label={t("reassignTransportContents.oldTransport")}
                  inputProps={{ maxLength: 6 }}
                />
              </>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            <FormControl fullWidth>
              <>
                <InputLabel>
                  {t("reassignTransportContents.newTransport")}
                </InputLabel>
                <Select
                  value={selectedNewTranport}
                  label={t("reassignTransportContents.newTransport")}
                  onChange={handleNewTransportChange}
                  disabled={isEnabledNewTransportInput}
                >
                  {getNewTransports &&
                    getNewTransports.length > 0 &&
                    getNewTransports.map((item) => {
                      return (
                        <MenuItem
                          key={item.container_id}
                          value={item.container_number}
                        >
                          {item.temp_transport_flag === Y_CHECKER
                            ? `${item.container_number} ${t(
                                "reassignTransportContents.star"
                              )}`
                            : item.container_number ===
                                `${item.container_number} ${hash}`
                              ? `${item.container_number} ${t(
                                  "reassignTransportContents.hash"
                                )}`
                              : item.container_number}
                        </MenuItem>
                      );
                    })}
                </Select>
              </>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography>
          {t("reassignTransportContents.temporaryTransport")}
        </Typography>
        <br />
        <Typography
          sx={ContainerProcessingStyles.rearchTransportBorderBottom}
        ></Typography>
        <br />
        <Stack direction="row" gap={spacing.gap}>
          <Button
            variant="outlined"
            onClick={onSaveHandler}
            disabled={isEnableSave}
          >
            {t("common.save")}
          </Button>
        </Stack>
      </Box>
    </>
  );
};
export default Transport;
