const personnelAuthStyles = {
  boxStyle: { display: "flex", gap: "20px" },
  successAuth: { color: "var(--color-add-ons)", display: "flex" },
  alertWidth: { minWidth: "600px" }
};

const commonStyles = {
  boxWidth: { width: "310px" },
  inputLabelProps: { shrink: true, style: { zIndex: 0 } },
  flex: { display: "flex" },
  btn: { textTransform: "none", minWidth: "110px", marginRight: "10px" }
};

const bypassStyles = {
  tfReason: { width: "640px", height: "80px" }
};

const mainDivStyles = {
  display: "flex",
  flexDirection: "row-reverse",
  marginTop: "-70px"
};

export const authSectionStyles = {
  personnelAuthStyles,
  commonStyles,
  bypassStyles,
  mainDivStyles
};
