import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TableContainer
} from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  N_CHECKER,
  active_vaults,
  enabledOpacity
} from "utils/constants";
import { CustomerServiceInformationStyles } from "./CustomerServiceInformationStyle";

const CustomerServiceInformationModal = ({
  isOpen,
  closeModal,
  selectedCustomer,
  vaultData,
  vaultModalType,
  onAddButtonClickInVaultModal,
  onVaultSelect,
  selectedVaultsList,
  onVaultRemove,
  onSaveClickVaults,
  vaultsErrorShow,
  setVaultsErrorShow,
  selectedVault
}) => {
  const columns = [
    {
      name: "",
      options: {
        setCellProps: () => ({
          style: CustomerServiceInformationStyles?.tableStyles?.tableColumnWidth
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                onClick={(e) => {
                  onVaultRemove(tableMeta.rowIndex, vaultModalType);
                }}
                sx={{ opacity: enabledOpacity }}
              >
                <SvgIcon component={DeleteIcon} />
              </Button>
            </>
          );
        }
      }
    },

    {
      name: "label",
      label: t(`customerServiceInformation.physicalVault`).toUpperCase(),
      options: {
        filter: true,
        sort: false
      }
    }
  ];
  return (
    <>
      <CommonModal
        open={isOpen}
        onClose={closeModal}
        title={
          vaultModalType === active_vaults
            ? t(
                "customerServiceInformation.containerSlottingActivePhysicalVaultsModalHeading"
              )
            : t(
                "customerServiceInformation.containerSlottingLongTermPhysicalVaultsModalHeading"
              )
        }
        body={
          <Box component={Paper}>
            {vaultsErrorShow && (
              <Collapse in={vaultsErrorShow}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  sx={CustomerServiceInformationStyles.errorStyle}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setVaultsErrorShow(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {t("customerServiceInformation.vaultsError")}
                </Alert>
              </Collapse>
            )}

            <Box
              display="flex"
              alignItems="center"
              p={2}
              sx={CustomerServiceInformationStyles?.widthWithHeight}
            >
              {t("customerServiceInformation.customer")}
              <br />
              {selectedCustomer}
            </Box>
            <Grid
              container
              ml={2}
              sx={CustomerServiceInformationStyles?.widthFixed}
              gap={CustomerServiceInformationStyles?.gap?.gap}
            >
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <InputLabel id="branch-selectLabel">
                    {t("customerServiceInformation.physicalVault")}
                  </InputLabel>
                  <Select
                    labelId="billCycleSelectLabel"
                    id="bill-cycle-select"
                    label={t("customerServiceInformation.physicalVault")}
                    onChange={onVaultSelect}
                    value={selectedVault}
                  >
                    {vaultData?.length > 0 &&
                      vaultData
                        ?.filter(
                          (v) =>
                            v?.Physical_Vault_Selected_Flag === N_CHECKER &&
                            !v?.isChanged
                        )
                        ?.map((b) => {
                          return (
                            <MenuItem value={b} key={b?.label}>
                              {b?.label}
                            </MenuItem>
                          );
                        })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1}>
                <Button
                  p={4}
                  id="AddBtn-modal"
                  variant="outlined"
                  onClick={() => {
                    onAddButtonClickInVaultModal(selectedVault, vaultModalType);
                  }}
                  fullWidth
                  size="large"
                  sx={CustomerServiceInformationStyles.buttonsRow.add}
                  disabled={!selectedVault}
                >
                  {t("common.add")}
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                ...CustomerServiceInformationStyles.tableStyles.customScrollbar
              }}
            >
              <TableContainer>
                <MUIDataTable
                  columns={columns}
                  options={{
                    ...DEFAULT_DATA_TABLE_OPTIONS,
                    pagination: 0,
                    textLabels: {
                      body: { noMatch: "" }
                    }
                  }}
                  data={selectedVaultsList}
                />
              </TableContainer>
            </Box>
          </Box>
        }
        buttons={
          <>
            <Button
              id="CancelBtn-modal"
              variant="outlined"
              onClick={() => {
                closeModal();
              }}
              sx={
                CustomerServiceInformationStyles.customerServiceInformationModal
                  .buttonSX
              }
            >
              {t("common.cancel")}
            </Button>

            <Button
              id="OkBtn-modal"
              variant="contained"
              onClick={() => {
                onSaveClickVaults();
              }}
              sx={
                CustomerServiceInformationStyles.customerServiceInformationModal
                  .buttonSX
              }
            >
              {t("common.ok")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default CustomerServiceInformationModal;
