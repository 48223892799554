import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { useTranslation } from "react-i18next";
import { personnelDetailStyles } from "./PersonnelDetailStyles";
// import { ReactComponent as ArrowDownDisabled } from "assets/images/ArrowDownDisabled.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import spacing from "styles/spacing";

const PDIdentificationSection = ({
  personalDetailForm,
  handleEnableSecureSyncChange,
  allCountries,
  onCountryChange,
  isPersonnelDisabled,
  requiredFieldValidations,
  handleFirstNameField,
  handleLastNameField,
  handlebusinessEmailField,
  handleFieldChanges
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5">
        {t("personnelDetail.identificationTitle")}
      </Typography>

      <Grid container spacing={spacing.gap}>
        {/* User Id */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="userId"
              name="userId"
              label={t("personnelDetail.userIdLabel")}
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              value={personalDetailForm.values.userId}
              InputLabelProps={
                personnelDetailStyles.commonStyles.inputLabelProps
              }
              disabled={isPersonnelDisabled()}
            />
          </Box>
        </Grid>

        {/* First Name */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="firstName"
              name="firstName"
              label={t("personnelDetail.fnameLabelMando")}
              fullWidth
              value={personalDetailForm.values.firstName}
              onChange={(e) => {
                handleFirstNameField(e);
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 30
              }}
              error={requiredFieldValidations?.firstName}
              helperText={
                requiredFieldValidations?.firstName &&
                t("personnelDetail.requiredFeildFirstName")
              }
            />
          </Box>
        </Grid>

        {/* Middle Name */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="middleName"
              name="middleName"
              label={t("personnelDetail.midnameLabel")}
              fullWidth
              value={personalDetailForm.values.middleName}
              onChange={(e) => {
                handleFieldChanges(e, "middleName");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 1
              }}
              error={requiredFieldValidations?.middleName}
            />
          </Box>
        </Grid>

        {/* Last Name */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="lastName"
              name="lastName"
              label={t("personnelDetail.lnameLabelLabelMando")}
              fullWidth
              value={personalDetailForm.values.lastName}
              onChange={(e) => handleLastNameField(e)}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 30
              }}
              error={requiredFieldValidations?.lastName}
              helperText={
                requiredFieldValidations?.lastName &&
                t("personnelDetail.requiredFeildLastName")
              }
            />
          </Box>
        </Grid>

        {/* Home Email */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="homeEmail"
              name="homeEmail"
              label={t("personnelDetail.homeEmailLabel")}
              fullWidth
              value={personalDetailForm.values.homeEmail}
              onChange={personalDetailForm.handleChange}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 70
              }}
            />
          </Box>
        </Grid>

        {/* Confirm Home Email */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="confirmHomeEmail"
              name="confirmHomeEmail"
              label={t("personnelDetail.confirmHEmailLabel")}
              fullWidth
              value={personalDetailForm.values.confirmHomeEmail}
              onChange={(e) => {
                handleFieldChanges(e, "confirmHomeEmail");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 70
              }}
              error={requiredFieldValidations?.confirmHomeEmail}
            />
          </Box>
        </Grid>

        {/* Business Email */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="businessEmail"
              name="businessEmail"
              label={t("personnelDetail.businessEmailLabelMando")}
              fullWidth
              value={personalDetailForm.values.businessEmail}
              onChange={(e) => handlebusinessEmailField(e)}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 70
              }}
              error={requiredFieldValidations?.businessEmail}
              helperText={
                requiredFieldValidations?.businessEmail &&
                t("personnelDetail.requiredFeildBusinessEmail")
              }
            />
          </Box>
        </Grid>

        {/* Confirm Business Email */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="confirmBusinessEmail"
              name="confirmBusinessEmail"
              label={
                !personalDetailForm.values.businessEmail
                  ? t("personnelDetail.confirmBEmailLabel")
                  : t("personnelDetail.confirmBEmailLabelMando")
              }
              fullWidth
              value={personalDetailForm.values.confirmBusinessEmail}
              onChange={(e) => {
                handleFieldChanges(e, "confirmBusinessEmail");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 70,
                onPaste: (e) => e.preventDefault() // Prevent paste action
              }}
              error={requiredFieldValidations?.confirmBusinessEmail}
            />
          </Box>
        </Grid>

        {/* Country Selection */}
        <Grid item xs={3} sm={3}>
          <Box>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              clearIcon={<CloseIcon />}
              id="countryList"
              name="countryList"
              options={allCountries}
              onChange={(event, newValue) => {
                onCountryChange(event, newValue);
              }}
              value={personalDetailForm.values.selectedCountry}
              size="medium"
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("personnelDetail.countryLabel")}
                />
              )}
              disabled={isPersonnelDisabled()}
            />
          </Box>
        </Grid>

        {/* Home Phone */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="homePhone"
              name="homePhone"
              label={t("personnelDetail.homePhoneLabel")}
              fullWidth
              value={personalDetailForm.values.homePhone}
              onChange={(e) => {
                handleFieldChanges(e, "homePhone");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 25
              }}
              error={requiredFieldValidations?.homePhone}
            />
          </Box>
        </Grid>

        {/* Cellular */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="cellular"
              name="cellular"
              label={t("personnelDetail.cellularLabel")}
              fullWidth
              value={personalDetailForm.values.cellular}
              onChange={(e) => {
                handleFieldChanges(e, "cellular");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 25
              }}
              error={requiredFieldValidations?.cellular}
            />
          </Box>
        </Grid>

        <Grid item xs={3} sm={3}>
          <Box></Box>
        </Grid>

        {/* Pager */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="pager"
              name="pager"
              label={t("personnelDetail.pagerLabel")}
              fullWidth
              value={personalDetailForm.values.pager}
              onChange={(e) => {
                handleFieldChanges(e, "pager");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 25
              }}
              error={requiredFieldValidations?.pager}
            />
          </Box>
        </Grid>

        {/* Pager PIN */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="pagerPin"
              name="pagerPin"
              label={t("personnelDetail.pagerPinLabel")}
              fullWidth
              value={personalDetailForm.values.pagerPin}
              onChange={(e) => {
                handleFieldChanges(e, "pagerPin");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 10
              }}
              error={requiredFieldValidations?.pagerPin}
            />
          </Box>
        </Grid>
      </Grid>

      {/* SecureSync User Toggle */}
      <Box sx={personnelDetailStyles.commonStyles.boxWidth}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={personalDetailForm.values.isSecureSyncEnabled}
                onChange={handleEnableSecureSyncChange}
                inputProps={{ "aria-label": "controlled" }}
                disabled={isPersonnelDisabled()}
              />
            }
            label={t("personnelDetail.enableSecureSyncLabel")}
          />
        </FormGroup>

        <Stack>
          <Typography variant="caption">
            {t("personnelDetail.toggleOnSS")}
          </Typography>
          <Typography variant="caption">
            {t("personnelDetail.toggleOffSS")}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default PDIdentificationSection;
