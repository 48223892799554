import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stateToPersist: {},
  customerSelected: {},
  localeByBranch: "",
  selectedRowIndex: [],
  page: 0,
  rowsPerPage: 25
};

export const openMediaResearchSlice = createSlice({
  name: "openMediaResearch",
  initialState,
  reducers: {
    saveBeforeNavigate: (state, { payload }) => {
      state.stateToPersist = { ...payload.state };
      state.customerSelected = payload.customer;
      state.localeByBranch = payload.localeByBranch;
      state.selectedRowIndex = payload.selectedRowIndex;
      state.page = payload.page;
      state.rowsPerPage = payload.rowsPerPage;
    },
    resetPersistedData: (state) => {
      return { ...initialState };
    }
  }
});

export default openMediaResearchSlice.reducer;
