import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid
} from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { dayMonthYearFormat } from "utils/constants";

const UploadErrorList = ({ handleRowSelection, dataList }) => {
  const columns = [
    {
      name: "Run_Name",
      label: t("discrepancyUpdate.run")
    },
    {
      name: "Customer_Number",
      label: t("discrepancyUpdate.customer")
    },
    {
      name: "scanned_barcode",
      label: t("discrepancyUpdate.scannedBarcode")
    },
    {
      name: "return_date",
      label: t("discrepancyUpdate.pickupReturnDate"),
      options: {
        customBodyRender: (value) => {
          return value ? dayjs(value).format(dayMonthYearFormat) : value;
        }
      }
    },
    {
      name: "personnel_name",
      label: t("discrepancyUpdate.personnel")
    }
  ];
  const options = {
    filter: false,
    jumpToPage: true,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    selectableRows: "none"
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
      >
        {t("discrepancyUpdate.uploadErrorList")}
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12} lg={12} marginTop={4}>
          <MUIDataTable
            data={dataList}
            options={options}
            columns={[
              {
                name: "unknown_container_id",
                label: t("discrepancyUpdate.selected"),
                options: {
                  filter: false,
                  sort: false,
                  download: false,
                  viewColumns: true,
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  handleRowSelection(value);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                          />
                        </FormGroup>
                      </>
                    );
                  },
                  customHeadLabelRender: (columnMeta) => (
                    <div>{columnMeta.label}</div>
                  )
                }
              },
              ...columns
            ]}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UploadErrorList;
