import { Box, Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import { local, ymdDateFormatTime } from "utils/constants";
import { modalTextLimit } from "utils/constants/corrective-action-request";
import { CorrectiveActionRequestStyles } from "./CorrectiveActionRequestStyles";

const {
  default: CommonModal
} = require("components/shared/common-modal/CommonModal");

const AddResolutionCommentModal = ({
  isAddCommentModal,
  onClose,
  getDate,
  setSaveBtnStatus,
  resolutionCommentList,
  setResolvedCommentList
}) => {
  const [comments, setComments] = useState("");
  const { auth } = useSelector(selectAuth);
  const submit = async () => {
    const date = getDate;
    setResolvedCommentList([
      ...resolutionCommentList,
      {
        comment: `${auth?.user?.first_name}, ${auth?.user?.last_name} ${dayjs
          .utc(date?.local_getdate)
          .format(ymdDateFormatTime)} :${comments}`,
        local: local
      }
    ]);
    setComments("");
    setSaveBtnStatus(false);
    onClose();
  };
  const maxCount = (max, comment) => {
    const result = max - comment;
    const safeResult = result < 0 ? 0 : result; // Ensures result does not go negative
    return safeResult;
  };
  return (
    <CommonModal
      open={isAddCommentModal}
      title={t("correctiveActionRequest.addResolutionPhase")}
      body={
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap={20}
            sx={CorrectiveActionRequestStyles.modalStyle}
          >
            <TextField
              label="Comments"
              multiline
              rows={5}
              maxRows={8}
              variant="outlined"
              fullWidth
              inputProps={{
                maxLength: maxCount(
                  modalTextLimit,
                  resolutionCommentList
                    ?.map((o) => o?.comment)
                    ?.map((obj) => obj?.toString())
                    .join("")?.length
                )
              }}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Box>
        </>
      }
      buttons={
        <>
          <Button variant="outlined" disabled={!comments} onClick={submit}>
            {t("common.add")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setComments("");
              onClose();
            }}
          >
            {t("common.cancel")}
          </Button>
        </>
      }
    />
  );
};
export default AddResolutionCommentModal;
