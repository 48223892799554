import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import authenticateImg from "../../../assets/images/AuthenticatedCaller.svg";
import noAuthenticateImg from "../../../assets/images/NoAuthenticateCaller.svg";
import { authSectionStyles } from "./AuthSectionStyles";

const AuthenticationCallerButton = ({
  isAuthenticated,
  onAuthenticatedCallerCliked
}) => {
  const { t } = useTranslation();

  return (
    <div style={authSectionStyles?.mainDivStyles}>
      <Button
        startIcon={
          isAuthenticated ? (
            <img src={authenticateImg} alt="authenticateImg" />
          ) : (
            <img src={noAuthenticateImg} alt="noAuthenticateImg" />
          )
        }
        onClick={() => onAuthenticatedCallerCliked()}
      >
        {isAuthenticated
          ? t("personnelDetail.clearAuthenticatedCaller")
          : t("personnelDetail.noAuthenticatedCaller")}
      </Button>
    </div>
  );
};

export default AuthenticationCallerButton;
