import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Paper,
  TableContainer
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import AuditDetail from "./AuditDetail";
import BatchDetail from "./BatchDetail";

const DetailTable = ({ viewData }) => {
  const [auditData, setAuditData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  useEffect(() => {
    let audits = viewData[2]?.sort((a, b) =>
      b.customer_number.localeCompare(a.customer_number)
    );
    setAuditData(audits);
    let batches = viewData[3]?.sort((a, b) =>
      b.batch_id.localeCompare(a.batch_id)
    );
    setBatchData(batches);
  }, [viewData]);

  return (
    <>
      <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
        <Divider />
      </Grid>

      <Accordion defaultExpanded={viewData && viewData[2]}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12-content"
        >
          {t("containerAuditDetails.auditDetail")}
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Paper>
              <TableContainer>
                <AuditDetail tableData={auditData} />
              </TableContainer>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={viewData && viewData[3]}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
        >
          {t("containerAuditDetails.batchDetail")}
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Paper>
              <TableContainer>
                <BatchDetail tableData={batchData} />
              </TableContainer>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DetailTable;
