import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PrintPickModalStyles } from "./PrintPickModalStyles";

const PrintPickModal = (props) => {
  const { t } = useTranslation();

  const handleOk = () => {
    props.handlePickPrint(true);
  };
  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={PrintPickModalStyles.cardStyleDRMPopUP}>
          {/* ------------------ Title Section ------------------ */}
          <Typography
            variant="h6"
            sx={PrintPickModalStyles.mainTitlePopUP}
            color="text.secondary"
            gutterBottom
          >
            {t("vaultItemized.titlePrintPickModal")}
          </Typography>
          <Divider />
          {/* ------------------ Body Section ------------------ */}
          <CardContent>
            <Autocomplete
              value={props.selectedPickListSize}
              onChange={(event, newValue) => {
                props.setSelectedPickListSize(newValue);
              }}
              disabled={props.isPickSizeDisabled}
              id="downloadExportType"
              options={props.pickListSizes || []}
              sx={{ width: "270px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("vaultItemized.splitPickSizeLabel")}
                />
              )}
            />
          </CardContent>
          <Divider />
          {/* ------------------ Footer Section ------------------ */}
          <CardActions sx={PrintPickModalStyles.cardActionsStylePopUP}>
            <Button
              variant="outlined"
              onClick={() => props.setOpen(false)}
              sx={PrintPickModalStyles.buttonStylePopUP}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              sx={PrintPickModalStyles.buttonStylePopUP}
              onClick={handleOk}
            >
              {t("common.ok")}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default PrintPickModal;
