import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useFormik } from "formik";
import { useCallbackPrompt } from "hooks";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllCustomersQuery } from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getEmployeeDetails } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  N_CHECKER,
  Y_CHECKER,
  downloadReportOptions
} from "utils/constants";
import {
  findErrorMessage,
  getSortedData,
  getUserName,
  printReport
} from "utils/helpers";
import CustomerBillingInformationModal from "./CustomerBillingInformationModal";
import { customerBillingInformationStyles } from "./CustomerBillingInformationStyles";
import CustomerBillingInformationTable from "./CustomerBillingInformationTable";
import CustomerBillingInformationUnsavedChangesModal from "./CustomerBillingInformationUnsavedChangesModal";

const CustomerBillingInformation = () => {
  const { auth, currentBranch } = useSelector(selectAuth); // selected branch from the top navigation bar
  const [loading, setLoading] = useState(false);
  const [billingInformation, setBillingInformation] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedAccountOwner, setSelectedAccountOwner] = useState("");
  const [isNothingChanged, setIsNothingChanged] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [tableUpdateSuccessMessage, setTableUpdateSuccessMessage] =
    useState("");
  const [tableUpdateSuccess, setTableUpdateSuccess] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const haveUnsavedChanges = useMemo(() => {
    if (billingInformation.length > 0) {
      return billingInformation.filter((info) => info?.isEdited).length > 0;
    } else {
      return false;
    }
  }, [billingInformation]); // page have unsaved changes or not state
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveUnsavedChanges); // browser back and forward blocker hook
  const [selectedPrintType, setSelectedPrintType] = useState(
    downloadReportOptions[0]
  );

  let initialValues = {
    customer: "",
    manualFeeFlagChanged: false,
    billContainerHandlingFlagChanged: false
  };

  const customerBillingInformationForm = useFormik({
    initialValues: initialValues
  });

  // getting the Data from useGetAllCustomersQuery
  const { data: customers = [], isLoading } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });

  // Handle Customer Change
  const handleCustomerChange = (event, newValue) => {
    if (haveUnsavedChanges) {
      setShowUnsavedChangesModal(true);
      return;
    }
    customerBillingInformationForm.handleChange({
      target: { name: "customer", value: newValue }
    });
    setBillingInformation([]);
    setThrowErrorMessage("");
    setThrowError(false);
    setTableUpdateSuccessMessage("");
    setTableUpdateSuccess(false);
    setSelectedAccountOwner("");
  };

  // handle manualFeeFlag
  const handleManualProcessingChange = (e) => {
    customerBillingInformationForm?.setFieldValue(
      "manualFeeFlag",
      e?.target?.checked === true ? Y_CHECKER : N_CHECKER
    );
    customerBillingInformationForm?.setFieldValue("manualFeeFlagChanged", true);
  };

  // handle billContainerHandlingFlag
  const handleContainerHandlingChange = (e) => {
    customerBillingInformationForm?.setFieldValue(
      "billContainerHandlingFlag",
      e?.target?.checked === true ? Y_CHECKER : N_CHECKER
    );
    customerBillingInformationForm?.setFieldValue(
      "billContainerHandlingFlagChanged",
      true
    );
  };

  // handle Row Click
  const handleRowClick = (value, tableMeta) => {
    setSelectedRow(billingInformation[tableMeta?.rowIndex]);
    setIsModalOpen((prev) => !prev);
  };

  // close the modal
  const handleModalClose = () => {
    setSelectedRow(0);
    setIsModalOpen((prev) => !prev);
  };

  // change the description of the selected row
  const onDescriptionChange = (value) => {
    setSelectedRow((prev) => ({ ...prev, description: value, isEdited: true }));
  };

  // save the changes in the modal
  const onSaveClickOnModal = async () => {
    setBillingInformation(
      billingInformation?.map((item) => {
        if (item?.i === selectedRow?.i) {
          return selectedRow;
        } else {
          return item;
        }
      })
    );
    setSelectedRow(0);
    setIsModalOpen((prev) => !prev);
    return;
  };

  const updateCustomersBillingInformation = async (row) => {
    setLoading(true);
    try {
      let requestBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        descr: row?.description,
        pricing_contract_code:
          customerBillingInformationForm?.values?.pricingContractCode,
        catalog_item_code: row?.catalogItem,
        billing_catalog_code: row?.catalog
      });

      const response = await getResponseData(
        requestBody,
        `${CF_URLS?.customerBillingInformation?.insertPricingContractTtemdesc}`,
        2
      );

      let updatedCustomerDetails = response?.data[1];

      if (updatedCustomerDetails[0]?.error === "0") {
        setTableUpdateSuccess(true);
        setTableUpdateSuccessMessage(
          t("customerBillingInformation.updateSuccess")
        );
      } else if (updatedCustomerDetails[0]?.error !== "0") {
        setThrowErrorMessage(t("common.errorMessage"));
        setThrowError(true);
      } else if (response?.error) {
        setThrowErrorMessage(response.error);
        setThrowError(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // print button click
  const onPrintButtonCLick = () => {
    setShowPrintModal((prev) => !prev);
  };

  // on main save button click
  const onSaveButtonClick = async (exitPage = false) => {
    setLoading(true);
    setIsNothingChanged(false);
    setThrowErrorMessage("");
    setThrowError(false);
    setTableUpdateSuccessMessage("");
    setTableUpdateSuccess(false);
    let editedRows = billingInformation?.filter((row) => row?.isEdited);

    if (
      !customerBillingInformationForm?.values?.manualFeeFlagChanged &&
      !customerBillingInformationForm?.values
        ?.billContainerHandlingFlagChanged &&
      customerBillingInformationForm?.values?.employee?.employee_id ===
        selectedAccountOwner?.employee_id &&
      editedRows?.length === 0
    ) {
      setIsNothingChanged(true);
      setLoading(false);
      return;
    }

    if (editedRows?.length > 0) {
      for (const row of editedRows) {
        try {
          await updateCustomersBillingInformation(row); // Wait for each promise to resolve
        } catch (error) {
          setLoading(false);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
        }
      }
      let updatedBillingInfo = billingInformation?.map((info) => {
        return { ...info, isEdited: false };
      });
      setBillingInformation(updatedBillingInfo);
    }

    if (
      customerBillingInformationForm?.values?.manualFeeFlagChanged ||
      customerBillingInformationForm?.values
        ?.billContainerHandlingFlagChanged ||
      customerBillingInformationForm?.values?.employee?.employee_id !==
        selectedAccountOwner?.employee_id
    ) {
      await updateCustomerInformation();
    }

    if (exitPage) {
      // exit page after saving
      confirmNavigation();
    }

    setShowUnsavedChangesModal(false);
  };

  // updade customer information
  const updateCustomerInformation = async () => {
    setLoading(true);
    try {
      let requestBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        customer_id: customerBillingInformationForm?.values?.customer?.value,
        salesperson_employee_id: selectedAccountOwner?.employee_id,
        bill_manual_process_flag:
          customerBillingInformationForm?.values?.manualFeeFlag,
        bill_cont_handling_flag:
          customerBillingInformationForm?.values?.billContainerHandlingFlag,
        timestamp: customerBillingInformationForm?.values?.timestamp
      });

      const response = await getResponseData(
        requestBody,
        `${CF_URLS?.customerBillingInformation?.updateCustomerBillinginfo}`,
        2
      );

      let updatedCustomerDetails = response?.data[1];

      if (updatedCustomerDetails[0]?.error === "0") {
        setTableUpdateSuccess(true);
        setTableUpdateSuccessMessage(
          t("customerBillingInformation.updateSuccess")
        );
      } else if (response?.error) {
        setThrowErrorMessage(response.error);
        setThrowError(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // loading employee data from firestore => employee collection
  const getEmployeesData = async () => {
    setLoading(true);
    try {
      const result = await getEmployeeDetails();
      const response = result?.map((res) => {
        return {
          active_flag: res?.active_flag,
          employee_id: res?.employee_id,
          employee_name: `${res?.last_name}, ${res?.first_name}`,
          is_under_op_center_flag: "",
          locale_id: res?.locale_id,
          label: `${res?.last_name}, ${res?.first_name}`,
          value: `${res?.last_name}, ${res?.first_name}`
        };
      });
      setEmployees(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // get selected customer billing information
  const getRemotecustomerbillinfo = async () => {
    try {
      setLoading(true);

      let requestBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        customer_id: customerBillingInformationForm?.values?.customer?.value
      });

      const response = await getResponseData(
        requestBody,
        `${CF_URLS?.customerBillingInformation?.remoteCustomerBillinfo}`,
        2
      );

      let personalDetails = response?.data[0];
      if (personalDetails) {
        customerBillingInformationForm?.setFieldValue(
          "billContainerHandlingFlag",
          personalDetails[0]?.bill_container_handling_flag?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "billingCycleCode",
          personalDetails[0]?.billing_cycle_code?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "customerParentNumber",
          personalDetails[0]?.customer_parent_number?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "manualFeeFlag",
          personalDetails[0]?.manual_fee_flag?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "pricingContractCode",
          personalDetails[0]?.pricing_contract_code?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "salespersonEmployeeId",
          personalDetails[0]?.salesperson_employee_id?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "salespersonName",
          personalDetails[0]?.salesperson_name?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "sicDescr",
          personalDetails[0]?.sic_descr?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "terminationDate",
          personalDetails[0]?.termination_date?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "transCatalogItemCode",
          personalDetails[0]?.trans_catalog_item_code?.trim()
        );
        customerBillingInformationForm?.setFieldValue(
          "timestamp",
          personalDetails[0]?.timestamp?.trim()
        );
      } else if (response?.error) {
        setThrowErrorMessage(response.error);
        setThrowError(true);
      }

      let customerBillingInformation = response?.data[1];
      if (customerBillingInformation) {
        let billingInfo = customerBillingInformation?.map((info, i) => {
          return {
            i,
            classification: info?.billing_classification_descr,
            catalogItem: info?.catalog_item_code,
            catalog: info?.billing_catalog_code,
            description: info?.descr,
            isEdited: false
          };
        });
        const billingInfoSorted = getSortedData(
          billingInfo,
          "catalogItem",
          "asc"
        );
        setBillingInformation(billingInfoSorted);
      } else if (response?.error) {
        setThrowErrorMessage(response.error);
        setThrowError(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // get employee details
  const getEmployeeData = async () => {
    try {
      setLoading((prev) => true);
      let requestBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        employee_id:
          customerBillingInformationForm?.values?.salespersonEmployeeId?.trim()
      });

      const response = await getResponseData(
        requestBody,
        `${CF_URLS?.customerBillingInformation?.getEmployeeData}`,
        1
      );

      let employeeDetails = response?.data[0];

      if (employeeDetails) {
        let selectedSalesPerson = {
          ...employeeDetails[0],
          label: employeeDetails[0]?.employee_name,
          value: employeeDetails[0]?.employee_name
        };

        customerBillingInformationForm?.setFieldValue(
          "employee",
          selectedSalesPerson
        );
        setEmployees((prev) => [...prev, selectedSalesPerson]);
      } else if (response?.error) {
        setThrowErrorMessage(response.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setLoading((prev) => false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // call to cloud function and get data from firestore
  const getResponseData = async (requestBody, url, count) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(
      response,
      count,
      response.data.docId
    );
    return data;
  };

  // handle confirmation popup "onYes" event handler
  const handleConfirmationOnYes = async () => await onSaveButtonClick(true);

  // handle confirmation popup "onNo" event handler
  const handleConfirmationOnNo = () => confirmNavigation();

  // handle confirmation popup "onCancel" event handler
  const handleConfirmationOnCancel = () => cancelNavigation();

  // load employees data from the firestore in the initial page load
  useEffect(() => {
    getEmployeesData();
  }, []);

  // get customer bill info
  useEffect(() => {
    customerBillingInformationForm?.values?.customer &&
      getRemotecustomerbillinfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerBillingInformationForm?.values?.customer]);

  // get employee details
  useEffect(() => {
    if (customerBillingInformationForm?.values?.salespersonEmployeeId) {
      getEmployeeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerBillingInformationForm?.values?.salespersonEmployeeId]);

  // employee selection
  useEffect(() => {
    if (
      employees?.length > 0 &&
      customerBillingInformationForm?.values?.employee
    ) {
      setLoading(true);
      let employee = employees?.filter(
        (emp) =>
          emp?.employee_id ===
          customerBillingInformationForm?.values?.employee?.employee_id
      );
      if (employee?.length > 0) {
        setSelectedAccountOwner(employee[0]);
      } else {
        setSelectedAccountOwner(
          customerBillingInformationForm?.values?.employee
        );
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerBillingInformationForm?.values?.employee]);

  useEffect(() => {
    setIsNothingChanged(false);
  }, [
    customerBillingInformationForm?.values?.customer,
    customerBillingInformationForm?.values?.manualFeeFlag,
    customerBillingInformationForm?.values?.billContainerHandlingFlag,
    customerBillingInformationForm?.values?.employee
  ]);

  const handleCancelPrint = () => {
    setThrowError(false);
    setShowPrintModal(false);
  };

  //handle download
  const handleDownload = async () => {
    try {
      setLoading(true);
      setThrowError(false);
      let customerId = customerBillingInformationForm?.values?.customer?.value;
      let customerDetail = customers.find((item) => item.value === customerId);
      const reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: customerId,
        customer_number: customerDetail.number,
        user_name: getUserName(auth?.user),
        report_format: selectedPrintType?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.billing.customerInfoReport}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };

  const onCancelClickInUnsavedChanges = () => {
    setShowUnsavedChangesModal(false);
  };

  const onNoClickInUnsavedChanges = () => {
    customerBillingInformationForm.setFieldValue("customer", null);
    setBillingInformation([]);
    setShowUnsavedChangesModal(false);
  };

  return (
    <>
      {/* Page Loader */}
      {(isLoading || loading) && <FullCircularLoader />}

      {isNothingChanged && (
        <Alert
          severity="warning"
          icon={<img src={WarningIcon} alt="warning" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setIsNothingChanged(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {t("customerBillingInformation.noChanges")}
        </Alert>
      )}

      {throwError && (
        <Alert
          severity="error"
          icon={<img src={ErrorIcon} alt="error" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
                setThrowErrorMessage("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      )}

      {/*Success message popup*/}
      {tableUpdateSuccess && (
        <SuccessPopup
          message={tableUpdateSuccessMessage}
          close={setTableUpdateSuccess}
        />
      )}

      <form>
        <Stack
          direction={customerBillingInformationStyles?.Direction?.column}
          gap={customerBillingInformationStyles?.Gap?.gap}
        >
          <Stack
            direction={customerBillingInformationStyles?.Direction?.row}
            gap={customerBillingInformationStyles?.Gap?.gap}
          >
            {/* Customers Field */}
            <Grid sx={customerBillingInformationStyles?.FieldSize}>
              <CustomersField
                options={customers}
                value={customerBillingInformationForm?.values?.customer}
                handleOnChange={handleCustomerChange}
                form={customerBillingInformationForm}
                label={t("personnelValidation.customer")}
                required
              />
            </Grid>

            {customerBillingInformationForm?.values?.customer && (
              <>
                {/* Customer Parent Field */}
                <Grid sx={customerBillingInformationStyles?.FieldSize}>
                  <TextField
                    id="customer-parent"
                    name="customerParent"
                    label={t("customerBillingInformation.customerParent")}
                    size="medium"
                    variant="standard"
                    sx={customerBillingInformationStyles?.TextBoarderHide}
                    value={
                      customerBillingInformationForm?.values
                        ?.customerParentNumber || ""
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>

                {/* SIC Description Field */}
                <Grid sx={customerBillingInformationStyles?.FieldSize}>
                  <TextField
                    id="sic-description"
                    name="sICDescription"
                    label={t("customerBillingInformation.sICDescription")}
                    size="medium"
                    variant="standard"
                    sx={customerBillingInformationStyles?.TextBoarderHide}
                    value={
                      customerBillingInformationForm?.values?.sicDescr || ""
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>

                {/* Pricing Contract Field */}
                <Grid sx={customerBillingInformationStyles?.FieldSize}>
                  <TextField
                    id="pricing-contract"
                    name="pricingContract"
                    label={t("customerBillingInformation.pricingContract")}
                    size="medium"
                    variant="standard"
                    sx={customerBillingInformationStyles?.TextBoarderHide}
                    value={
                      customerBillingInformationForm?.values
                        ?.pricingContractCode || ""
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Stack>

          {customerBillingInformationForm?.values?.customer && (
            <Stack
              direction={customerBillingInformationStyles?.Direction?.row}
              gap={customerBillingInformationStyles?.Gap?.gap}
            >
              {/* Trans Catalog Item Field */}
              <Grid sx={customerBillingInformationStyles?.FieldSize}>
                <TextField
                  id="trans-catalogItem"
                  name="transCatalogItem"
                  label={t("customerBillingInformation.transCatalogItem")}
                  size="medium"
                  variant="standard"
                  sx={customerBillingInformationStyles?.TextBoarderHide}
                  value={
                    customerBillingInformationForm?.values
                      ?.transCatalogItemCode || ""
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                    focused: false
                  }}
                  fullWidth
                />
              </Grid>

              {/* Bill Cycle Field */}
              <Grid sx={customerBillingInformationStyles?.FieldSize}>
                <TextField
                  id="bill-cycle"
                  name="billCycle"
                  label={t("customerBillingInformation.billCycle")}
                  size="medium"
                  variant="standard"
                  sx={customerBillingInformationStyles?.TextBoarderHide}
                  value={
                    customerBillingInformationForm?.values?.billingCycleCode ||
                    ""
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                    focused: false
                  }}
                  fullWidth
                />
              </Grid>

              {/* Termination Date Field */}
              <Grid sx={customerBillingInformationStyles?.FieldSize}>
                <TextField
                  id="termination-date"
                  name="terminationDate"
                  label={t("customerBillingInformation.terminationDate")}
                  size="medium"
                  variant="standard"
                  sx={customerBillingInformationStyles?.TextBoarderHide}
                  value={
                    customerBillingInformationForm?.values?.terminationDate ||
                    ""
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                    focused: false
                  }}
                  fullWidth
                />
              </Grid>

              {/* Account Owner Field */}
              <Grid sx={customerBillingInformationStyles?.FieldSize}>
                <FormControl fullWidth>
                  <InputLabel id="account-owner">
                    {t("customerBillingInformation.accountOwner")}
                  </InputLabel>
                  <Select
                    labelId="accountOwner"
                    id="account-owner"
                    value={selectedAccountOwner || ""}
                    label={t("customerBillingInformation.accountOwner")}
                    onChange={(event, newValue) => {
                      setSelectedAccountOwner(event?.target?.value);
                    }}
                  >
                    {employees?.map((employee, i) => {
                      return (
                        <MenuItem key={i} value={employee}>
                          {employee?.employee_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Stack>
          )}

          {customerBillingInformationForm?.values?.customer && (
            <Stack
              direction={customerBillingInformationStyles?.Direction?.row}
              gap={customerBillingInformationStyles?.Gap?.gap}
            >
              {/* Manual Fee Flag Checkbox */}
              <Grid sx={customerBillingInformationStyles?.FieldSize}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        customerBillingInformationForm?.values
                          ?.manualFeeFlag === Y_CHECKER
                          ? true
                          : false
                      }
                      onChange={handleManualProcessingChange}
                    />
                  }
                  label={t("customerBillingInformation.manualProcessing")}
                  id="manual-fee-flag"
                />
              </Grid>

              {/* Bill Container Handling Flag Checkbox */}
              <Grid sx={customerBillingInformationStyles?.FieldSize}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        customerBillingInformationForm?.values
                          ?.billContainerHandlingFlag === Y_CHECKER
                          ? true
                          : false
                      }
                      onChange={handleContainerHandlingChange}
                    />
                  }
                  label={t("customerBillingInformation.containerHandling")}
                  id="bill-container-handling-flag"
                />
              </Grid>
            </Stack>
          )}

          <Stack
            direction={customerBillingInformationStyles?.Direction?.row}
            gap={customerBillingInformationStyles?.Gap?.gap}
            style={customerBillingInformationStyles?.StackMarginTop}
          >
            <CustomerBillingInformationTable
              billingInformation={billingInformation}
              selectedRow={selectedRow}
              handleRowClick={handleRowClick}
              onPrintButtonCLick={onPrintButtonCLick}
              isModalOpen={isModalOpen}
            />
          </Stack>

          <Stack
            direction={customerBillingInformationStyles?.Direction?.row}
            gap={customerBillingInformationStyles?.Gap?.gap}
          >
            {customerBillingInformationForm?.values?.customer && (
              <Button
                variant="contained"
                onClick={onSaveButtonClick}
                size="medium"
                type="button"
                disabled={billingInformation?.length === 0}
              >
                {t("common.save")}
              </Button>
            )}
          </Stack>
        </Stack>
      </form>

      {/* Customer Billing Information Modal */}
      <CustomerBillingInformationModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        selectedRow={selectedRow}
        onDescriptionChange={onDescriptionChange}
        onSaveClickOnModal={onSaveClickOnModal}
      />

      {/* Download Popup */}
      <DownloadPopup
        title={t("menu.customerBillingInformation")}
        showPrintModal={showPrintModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={handleCancelPrint}
        onDownloadClick={handleDownload}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />

      <CustomerBillingInformationUnsavedChangesModal
        showUnsavedChangesModal={showUnsavedChangesModal}
        onCancelClickInUnsavedChanges={onCancelClickInUnsavedChanges}
        onNoClickInUnsavedChanges={onNoClickInUnsavedChanges}
        handleOnSave={onSaveButtonClick}
      />

      {/* Save Changes COnfirmation Modal */}
      {showPrompt && (
        <ConfirmPopup
          modalPopupOpen={showPrompt}
          handleYes={handleConfirmationOnYes}
          handleNo={handleConfirmationOnNo}
          showNo={true}
          handleCancel={handleConfirmationOnCancel}
          showCancel={true}
        />
      )}
    </>
  );
};

export default CustomerBillingInformation;
