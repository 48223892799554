import i18n from "i18n";
import { t } from "i18next";

/**
 * Tag Types for the caching of requests
 */
export const TAG_TYPES = {
  customer: "Customer",
  customerWithDetails: "CustomerWithDetails",
  reqestType: "RequestType",
  serviceLocation: "ServiceLocation",
  tripType: "TripType",
  transportNumber: "TransportNumber",
  logicalVault: "LogicalVault",
  mediaType: "MediaType",
  insertType: "InsertType",
  lockType: "LockType",
  requestStatus: "RequestStatus",
  route: "Route",
  searchRequest: "SearchRequest",
  branch: "Branch",
  personnelAuth: "PersonnelAuth",
  personnelAuthCust: "PersonnelAuthCust",
  scheduleDate: "ScheduleDate",
  authNumber: "AuthNumber",
  authorizationDetail: "AuthorizationDetail",
  personnelDetail: "PersonnelDetail",
  custLocationInfo: "CustLocationInfo",
  requestInfo: "RequestInfo",
  routeInfo: "RouteInfo",
  runInfo: "RunInfo",
  cslInfo: "CslInfo",
  loginSuccess: "loginSuccess",
  personnel: "Personnel",
  trackingTypes: "TrackingTypes",
  dispositionServices: "DispositionServices",
  authorizationPersonnel: "AuthorizationPersonnel",
  deletableContainer: "DeletableContainer",
  mediaRequest: "MediaRequest",
  mediaRequestOpenMedia: "MediaRequestOpenMedia",
  mediaRequestContainer: "MediaRequestContainer",
  mediaRequestIssueContainer: "MediaRequestIssueContainer",
  mediaRequestDeleteContainer: "MediaRequestDeleteContainer",
  service: "",
  desReqComment: "DesReqComment",
  holidays: "Holidays",
  holidayYears: "HolidayYears",
  destructionRequestStatus: "destructionRequestStatus",
  destructionRequestTypes: "destructionRequestType",
  vaultItemized: "VaultItemized",
  destructRequestSave: "DestructRequestSave",
  verifyPermission: "verifyPermission",
  boxTypes: "BoxTypes",
  customerPermission: "CustomerPermission",
  destructDetail: "DestructDetail",
  specialHandling: "SpecialHandling",
  requestComments: "RequestComments",
  boxTypesCount: "BoxtypesCount",
  unitOfMeasures: "UnitOfMeasures",
  sitadRequest: "SitadRequest",
  requestBoxTypeCount: "RequestBoxTypeCount",
  deleteRrequestBoxTypeCount: "DeleteRrequestBoxTypeCount",
  requestSpecialHandling: "RequestSpecialHandling",
  requestComment: "RequestComment",
  scheduleRequest: "ScheduleRequest",
  request: "Request",
  runRequestStatus: "RunRequestStatus",
  getRequestComments: "GetRequestComments",
  deleteRequestComment: "DeleteRequestComment",
  assignTransportsToCustomer: "AssignTransportsToCustomer",
  rescheduleRequests: "RescheduleRequests",
  commissionType: "CommissionType",
  challengeResponse: "ChallengeResponse",
  billingCycle: "billingCycle",
  globalAttribute: "GlobalAttribute",
  branchBillingSystem: "BranchBillingSystem",
  uploadFile: "UploadFile"
};

/**
 * Flags and Constants
 */
export const Y_CHECKER = "Y";
export const startingSlot = "999999";
export const bulk = "bulk";
export const N_CHECKER = "N";
export const A_CHECKER = "A";
export const ON_CALL = "ON Call";
export const YES = "Yes";
export const YES_AUTHORIZE = "Yes Authorize";
export const NO = "No";
export const CANCEL = "Cancel";
export const ARC_VMS = "ArcVMS";
export const TRANS = "TRANS";
export const POSSIBLE_MATCHES_TXT = "Possible Matches";
export const DUPLICATES_TXT = "Duplicates";
export const FALSE_CHECKER = "F";
export const NOT_APPLICABLE = "Not Applicable";
export const IN_PROGRESS = "In Progress";
export const NOT_COMPLETED = "Not Completed";
export const FAILED = "Failed";
export const TransportsPendingSelectionCriteria = "n/a";

export const DOUBLE_EQUALS = "==";
export const NOT_EQUAL = "!=";
export const IN = "in";
export const DEACTIVATE = "deactivate";
export const COMPLETE = "Complete";
export const CLEANUP = "Clean Up";
export const CUSTOMER_PROCESS_ID = "3";
export const FEEDBACKRECEIPTID = "FeedBackRecipients_537";
export const CANCELLED = "Canceled";

export const TEST_USER = {
  userId: "corporate_test",
  password: "test"
};

/**
 * Media Program Types
 */
export const MEDIA_PROGRAM_TYPES = Object.freeze({
  CONTAINER: { ID: "3", TYPE: "Container" },
  TRANSPORT: { ID: "4", TYPE: "Transport" }
});

/**
 * Request Types
 */
export const REQUEST_TYPES = Object.freeze({
  SCHEDULED_SERVICE: 1,
  ADD_ON: 2,
  RE_RUN: 3,
  STANDARD_SPECIAL: 4,
  CRITICAL_SPECIAL: 5,
  DISASTER_RECOVERY: 6
});

/**
 * Media Request Actions
 */
export const REQUEST_ACTIONS = Object.freeze({
  MEDIA_PICKUP_DELIVERY: { ID: 1, ACTION: "Media Pickup/Delivery" },
  ISSUE_CONTAINER_LOCK_INSERT: { ID: 4, ACTION: "Issue Container/Lock/Insert" },
  ISSUE_TRANSPORT_LOCK: { ID: 5, ACTION: "Issue Transport/Lock" },
  DELETE_CONTAINERS: { ID: 6, ACTION: "Delete Containers" },
  OTHER: { ID: 8, ACTION: "Other" }
});

/**
 * Request New Item Types
 */
export const REQUEST_NEW_ITEM_TYPE = Object.freeze({
  CONTAINER: 1,
  TRANSPORT_EMPTY: 2,
  TRANSPORT_PERMANENT: 3,
  INSERT: 4,
  LOCKS: 5,
  CLIPS: 6
});

// Media Request Detail - Bypass id
export const MRD_BYPASS_IM_PERSONNEL_ID = 98;

/**
 * Request Status
 */
export const REQUEST_STATUS = Object.freeze({
  SCHEDULED: 1,
  NEEDS_AUTHORIZATION: 2,
  CANCELED: 3,
  COMPLETE: 4,
  NEEDS_SCHEDULING: 5,
  FUTURE_SCHEDULING: 8,
  DELETED: 9
});

export const SUPPORTED_LANGUAGES = {
  ENGLISH_US: { id: 1, code: "en-US", name: "English (United States)" },
  FRENCH_CA: { id: 3, code: "fr-CA", name: "Français (Canada)" },
  SPANISH_MX: { id: 4, code: "es-MX", name: "Español (Latin America)" },
  SPANISH_ES: { id: 5, code: "es-ES", name: "Español (Spain)" },
  FRENCH_FR: { id: 6, code: "fr-FR", name: "Français (France)" },
  ENGLISH_GB: { id: 7, code: "en-GB", name: "English (United Kingdom)" },
  PORTUGUESE_BR: { id: 8, code: "pt-BR", name: "Português (Brazil)" },
  CZECH_CS: { id: 9, code: "cs-CZ", name: "Ceština (Czech Republic)" },
  GERMAN_DE: { id: 30, code: "de-DE", name: "Deutsch (Germany)" },
  DUTCH_NL: { id: 31, code: "nl-NL", name: "Dutch (Netherlands)" }
};

/* map the iso_locale_code to dayjs supported locale
 * NDS = dayjs v1.11.10 not directly supported locale
 */
export const DAYJS_LOCALES = {
  "en-US": "en", // NDS
  "en-CA": "en-ca",
  "fr-CA": "fr-ca",
  "es-MX": "es-mx",
  "es-ES": "es",
  "fr-FR": "fr",
  "en-GB": "en-gb",
  "pt-BR": "pt-br",
  "cs-CZ": "cs",
  "nn-NO": "nn",
  "en-IN": "en-in",
  "es-PE": "es", // NDS
  "zh-HK": "zh-hk",
  "zh-SG": "zh", // NDS
  "en-AU": "en-au",
  "en-IE": "en-ie",
  "en-NZ": "en-nz",
  "zh-CN": "zh-cn",
  "es-AR": "es", // NDS
  "en-PH": "en", // NDS
  "es-PR": "es-pr",
  "ar-BH": "ar", // NDS
  "id-ID": "id",
  "nl-BE": "nl-be",
  "fr-BE": "fr", // NDS
  "de-BE": "de", // NDS
  "de-DE": "de", // NDS
  "nl-NL": "nl",
  "de-CH": "de-ch",
  "fr-CH": "fr-ch",
  "it-CH": "it-ch",
  "el-GR": "el",
  "it-IT": "it",
  "sv-SE": "sv",
  "bg-BG": "bg",
  "ro-RO": "ro",
  "de-AT": "de-at",
  "hu-HU": "hu",
  "sk-SK": "sk",
  "fr-MC": "fr", // NDS
  "pl-PL": "pl",
  "zh-TW": "zh-tw",
  "af-ZA": "af", // NDS
  "ru-RU": "ru",
  "fi-FI": "fi",
  "ja-JP": "ja",
  "ar-KW": "ar-kw",
  "da-DK": "da", // NDS
  "es-CL": "es", // NDS
  "ar-AE": "ar", // NDS
  "de-LU": "de", // NDS
  "ms-MY": "ms-my",
  "es-CR": "es", // NDS
  "he-IL": "he", // NDS
  "ar-IL": "ar" // NDS
};

export const DISTRICT_ID_GET_ALL_LOCATIONS = "50";
export const DROPDOWN_LIST_TYPE_ID = "8";
export const DEFAULT_LOCALE = "en";
export const DEFAULT_LOCALE_CODE = "en-US";

export const TXT_CONTENT_TYPE = "Content-Type";
export const APPLICATION_JSON = "application/json";
export const TXT_REGION = "region";
export const TXT_AUTHORIZATION = "Authorization";
export const TXT_ENVIRONMENT = "environment";
export const TXT_BRANCH = "branch";
export const TXT_WORKER_ID = "workerid";
export const WORKER_ID = "1001191";
export const TXT_BEARER = "Bearer";
export const TXT_AUTH_TOKEN = "authToken";
export const REQUEST_SAVE_STATUS_ID = "1";
export const SYSTEM_ID_SecureBase = "60";
export const SYSTEM_ID_SecureBaseWeb = "69";

export const PROGRAM_ID_VAULT_ITEMIZED = 297;
export const SECURE_SYNC_FLAG_Y = "Y";
export const CANCELLED_BY_CUSTOMER_ID = 1;

export const GLOBAL_TYPE_BILLALL = "110";
export const GLOBAL_TYPE_TURNROUNDOFF = "75";

export const ERROR_TYPES = {
  FAILED: "failed",
  EXCEPTION: "exception",
  ISSUE: "issue",
  SQLERROR: "sqlerror"
};

/**
 * Firebase field names
 */
export const FIREBASE_FIELD_NAMES = {
  DISTRICT_ID: "district_id",
  ACTIVE_FLAG: "active_flag",
  BUSINESS_EMAIL_ADDR: "business_email_addr",
  BUSINESS_EMAIL_ADDR_LOWER: "lower_business_email_addr",
  SECURITY_GROUP_ID: "security_group_id",
  IS_SECURE_BASE: "is_secure_base",
  CUSTOMER_ID: "customer_id",
  CSL_CUSTOMER_ID: "csl_customer_id",
  PERSONNEL_ID: "personnel_id",
  MEDIA_TYPE_ID: "media_type_id",
  MEDIA_PROGRAM_TYPE_ID: "media_program_type_id",
  MESSAGE_ID: "message_id",
  GLOBAL_ATTRIBUTE_TYPE_ID: "global_attribute_type_id",
  FILE_SECTION_STATUS_ID: "file_section_status_id",
  SYSTEM_ID: "system_id",
  SECURITY_Group: "security_group_id",
  EMP_END_DATE: "employment_end_date",
  NAME: "name",
  STATE_PROVINCE_ID: "state_province_id",
  CITY: "city",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  JOB_TITLE_ID: "job_title_id",
  TIME_TYPE_ID: "time_type_id",
  COST_CENTER_ID: "cost_center_id",
  WORK_CATEGORY_ID: "work_category_id",
  WORK_CENTER_ID: "work_center_id",
  EMERGENCY_CONTACT_FLAG: "emergency_contact_flag",
  DROPDOWN_LIST_TYPE_ID: "dropdown_list_type_id",
  BILL_TRANSPORT_FLAG: "bill_temporary_transport_flag",
  EMPLOYEE_ID: "employee_id",
  COUNTRY_ID: "country_id",
  HOME_EMAIL_ADDR: "home_email_addr",
  CODE: "code",
  ISO_CODE: "iso_locale_code",
  LOCALE_ID: "locale_id",
  ENVIRONMENT: "environment",
  MODULE_ID: "module_id",
  PROGRAM_ID: "program_id",
  GLOBAL_ATTRIBUTE_ID: "global_attribute_id",
  COUNTRY_ATTRIBUTE_TYPE_ID: "country_attribute_type_id",
  SETTING_TYPE_ID: "setting_type_id",
  GROUP_CODE: "group_code",
  EXCHANGE_STATUS_ID: "exchange_status_id",
  ON_HOLD: "on_hold",
  SERVICE_SOURCE_ID: "service_source_id",
  YEAR: "year",
  SUGGESTION_TYPE_ID: "suggestion_type_id",
  EMAIL_GROUP_CODE_DISTRICT_ID: "email_group_code_dist_id",
  LOGICAL_VAULT_ID: "logical_vault_id"
};

export const FIREBASE_COLLECTION_NAMES = {
  SUPPORTED_DOMAINS: "supported_domains",
  CIAM: "CIAM",
  EMAIL_CONFIGS: "email_configs"
};

export const SECURE_BASE_VALUE = "1";
export const EXCHANGE_LOCATION_TYPE_SL = "SL";
export const local = "local";
export const openMedia = "Open Media";
export const container = "Container";
export const transport = "Transport";
export const unknown = "Unknown";
export const todoContainers = "todo";

export const SECURITY_GROUPS = {
  TERMINATED_USER: "45"
};

export const LOGIN_METHOD = "email";

export const GLOBAL_ATTRIBUTE_VALUES = {
  VALUE_81: "81",
  VALUE_94: "94",
  VALUE_96: "96",
  VALUE_187: "187",
  VALUE_189: "189",
  VALUE_60: "60",
  VALUE_118: "118",
  VALUE_87: "87"
};

/**
 * Default MUI Data Table Options
 */
export const DEFAULT_DATA_TABLE_OPTIONS = {
  search: false,
  download: false,
  print: false,
  viewColumns: false,
  sort: false,
  filter: false,
  filterType: "dropdown",
  selectableRowsHeader: false,
  selectableRows: "none",
  jumpToPage: true,
  pagination: true,
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  responsive: "vertical"
};

export const ARRAY_FORMAT_SPACING = 20;

/**
 * Date helper functions
 */
export const DATE_FORMAT = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false // Set to false to use 24-hour format
};

export const OPTIONS_DATE = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric"
};

export const OPTIONS_TIME = {
  hour: "numeric",
  minute: "numeric"
};

export const SHORT_DATE = { month: "short", year: "numeric" };

export const shortMonth = { month: "short" };
export const longMonth = { month: "long" };

export const INVALID_DATE = "Invalid date";

export const BOOLEAN_STRING_VALUES = {
  TRUE: "true",
  FALSE: "false"
};

export const ALL_SELECTION = {
  label: i18n.t("common.all"),
  value: i18n.t("common.all")
};
export const capitalAll = "All";

export const VALUE_ALL = "<All>";
export const SELECT = "<Select>";
export const VALUE_Y = "Y";
export const VALUE_N = "N";
export const VALUE_EMPTY_STRING = "";
export const CLEAR = "clear";
export const notFound = "Not Found";
export const NOT_IN_INVENTORY = "Not Currently in Inventory";
export const found = "Found";
export const notApplicable = "n/a";

export const EMPTY_SELECTION = {
  label: VALUE_EMPTY_STRING,
  value: VALUE_EMPTY_STRING
};

export const type = {
  boolean: "boolean",
  string: "string"
};

export const ROLE_ACCESS_CODES = {
  CODE_48: "48",
  CODE_12: "12",
  CODE_301: "301",
  CODE_302: "302",
  CODE_303: "303",
  CODE_217: "217",
  CODE_280: "280",
  CODE_50: "50",
  CODE_172: "172",
  CODE_170: "170",
  CODE_254: "254",
  CODE_171: "171",
  CODE_182: "182",
  CODE_252: "252",
  CODE_184: "184",
  CODE_183: "183",
  CODE_47: "47",
  CODE_253: "253",
  CODE_276: "276",
  CODE_279: "279",
  CODE_341: "341",
  CODE_335: "335",
  CODE_41: "41",
  CODE_285: "285",
  CODE_323: "323",
  code_284: "284",
  code_257: "257",
  code_258: "258",
  code_259: "259",
  code_260: "260",
  code_261: "261",
  code_262: "262",
  code_263: "263",
  code_264: "264",
  code_265: "265",
  code_266: "266",
  code_267: "267",
  code_268: "268",
  code_111: "111"
};
export const TrackingRequirement = Object.freeze({
  Delivery: 3
});

export const Dates = Object.freeze({
  MinDate: "1996-01-31",
  MaxDate: "2079-05-31"
});

export const datesForCorrectiveSearch = Object.freeze({
  minDate: "2000-01-01",
  maxDate: Date.now()
});

export const DestructionStatus = Object.freeze({
  Authorized: 3,
  Scheduled: 4,
  Cancelled: 5,
  InProcess: 6,
  Complete: 7
});

export const DestructionComment = Object.freeze({
  PickupInstruction: 3
});

export const DestructionCancelReason = Object.freeze({
  ByCustomer: 1
});

export const dateFormat = Object.freeze({
  universalFormat: "YYYY-MM-DD",
  dateTime: "MM/DD/YYYY HH:mm"
});

export const flags = Object.freeze({
  yflag: "Y"
});

/**
 * Message Codes
 */
export const messageCode = {
  recordLimitExeed: 10161,
  pricingContractNotFound: 80447,
  requiredField: 20259,
  selectValidStartingGeneration: 80449,
  unableToComplete: 20355,
  thereAreNoTransactions: 20260,
  doNotHaveSecurityAccess: 80155,
  alreadyExistPricingContract: 80454,
  branchIsRequired: 10074,
  reportIsNotValid: 20265,
  txtCsvMessageId: 80350,
  rtfMessageId: 26156
};

/**
 * Message Codes
 */
export const MessageCode = {
  RecordLimitExeed: 10161,
  PricingContractNotFound: 80447,
  RequiredField: 20259,
  SelectValidStartingGeneration: 80449,
  InsufficientAuthorization: 64097
};

//default report name as per existing system
export const downloadFileName = {
  defaultPricingUseReport: "rp_default_pricing_use",
  capacityBillingGenerationSearchDetail: "rp_suffix_configurations"
};
//date format
export const dayMonthYearFormat = "DD/MM/YYYY";
export const dayMonthYearTimeFormat = "DD/MM/YYYY  HH:mm:ss";
export const dayMintMonthYearFormat = "DD/MM/YYYY  HH:mm";
export const monthDayYear = "MM/DD/YYYY";

//datetime format
export const yearMonthDayFormat = "YYYY-MM-DD";

//day constant
export const dayConst = "day";

//download options
// commenting the options because only pdf print is available for now
export const downloadReportOptions = [
  {
    label: "Portable Doc Format * .PDF",
    type: "pdf",
    value: 1
  }
  // {
  //   label: "Word * .DOC",
  //   type: "doc",
  //   value: 2
  // },
  // {
  //   label: "Excel * .XLS",
  //   type: "xls",
  //   value: 3
  // },
  // {
  //   label: "Text* .TXT",
  //   type: "txt",
  //   value: 4
  // },
  // {
  //   label: "Rich Text File * . RTF",
  //   type: "rtf",
  //   value: 5
  // }
];

export const corporateAccessLevelDistrictId = "50";

export const falseValue = 0;
export const trueValue = 1;

export const conversionFactorInputProps = {
  step: 0.01,
  min: 0,
  max: 999.99
};
export const generationLimit = { maxLength: 3 };
export const regextoValidConversionFactor = /^\d{0,3}(\.\d{0,2})?$/;
export const initialSearchCount = 0;

/**
 * @description Application's regions
 */
export const APP_REGIONS = Object.freeze({
  IME: "ime",
  IMNA: "imna",
  IMLA: "imla",
  IMAU: "imau"
});

export const minDate = "1997-01-01";

export const dateFormatWithTime = "L LT";
export const dateFormatWithoutTime = "L";
export const dateFormatWithTimeWithSeconds = "L LTS";
export const maxCount = 999;
export const TXT_None = "None";

export const billingCycle = Object.freeze({
  eom: "EOM",
  oto: "OTO"
});

export const billingCommon = Object.freeze({
  oleDate: "01-01-1999"
});

export const billingSystemInfo = Object.freeze({
  clarus: "CLARUS",
  arcvms: "ArcVMS"
});

export const minimumDate = "1996-01-31";
export const maximumDate = "2079-05-31";
export const ymdDateFormat = "YYYY-MM-DD";
export const dmyDateFormat = "DD/MM/YYYY";
export const dmyDateFormatTime = "DD/MM/YYYY hh:mm";
export const mdyDateFormat = "MM/DD/YYYY";
export const myYearMonthDayFormat = "YYYY/MM/DD";

export const ymdDateFormatTime = "YYYY-MM-DD hh:mm";
export const ymdDateFormatTimeSec = "YYYY-MM-DD hh:mm:ss";
export const dateFileNameFormat = "MMDDYYYY HHmmss";

export const padStartLength = 10;
export const hyphen = "-";
export const numberZero = 0;
export const errorMsgs = {
  errorCode26098: "26098",
  errorCode63149: "63149",
  errorCode26102: "26102",
  errorCode25101: "25101",
  errorCode25110: "25110",
  errorCode25103: "25103",
  errorCode25236: "25236",
  errorCode25052: "25052",
  errorCode64097: "64097",
  errorCode62518: "62518",
  errorCode25116: "25116",
  errorCode25237: "25237",
  errorCode25129: "25129",
  errorCode25130: "25130",
  errorCode20343: "20343",
  errorCode20345: "20345",
  errorCode20373: "20373",
  errorCode20295: "20295",
  errorCode25152: "25152",
  errorCode25291: "25291",
  errorCode25381: "25381",
  errorCode25395: "25395",
  errorCode25349: "25349",
  errorCode25354: "25354",
  errorCode21060: "21060",
  errorCode26037: "26037",
  errorCode20260: "20260",
  errorCode20261: "20261",
  errorCode20233: "20233",
  errorCode20224: "20224",
  errorCode25097: "25097",
  errorCode25197: "25197",
  errorCode20472: "20472",
  errorCode20485: "20485",
  errorCode20369: "20369",
  errorCode20361: "20361",
  errorCode20367: "20367",
  errorCode20370: "20370",
  errorCode20368: "20368",
  errorCode20418: "20418",
  errorCode20372: "20372",
  errorCode62527: "62527",
  errorCode25330: "25330",
  errorCode25331: "25331",
  noError: "0",
  errorCode64049: "64049",
  errorCode61014: "61014",
  errorCode10039: "10039",
  errorCode63455: "63455",
  errorCode20480: "20480",
  errorCode20264: "20264",
  errorCode25346: "25346",
  errorCode20460: "20460",
  errorCode20410: "20410",
  errorCode20411: "20411",
  errorCode20462: "20462",
  errorCode20463: "20463",
  errorCode20454: "20454",
  errorCode20406: "20406",
  errorCode20404: "20404",
  errorCode20403: "20403",
  errorCode80108: "80108",
  errorCode80107: "80107",
  errorCode80109: "80109",
  errorCode10137: "10137",
  errorCode10085: "10085",
  errorCode20400: "20400",
  errorCode20405: "20405",
  errorCode20385: "20385",
  errorCode20457: "20457",
  errorCode20465: "20465",
  errorCode26039: "26039",
  sqlErrorStart: 1,
  sqlErrorEnd: 999,
  errorCode64095: "64095",
  errorCode80483: "80483",
  errorCode80169: "80169",
  errorCode80246: "80246",
  errorCode64077: "64077",
  errorCode63973: "63973",
  errorCode63625: "63625",
  errorCode63626: "63626",
  errorCode80247: "80247",
  errorCode20458: "20458",
  errorCode532: "532",
  errorCode547: "547",
  errorCode20504: "20504",
  errorCode20547: "20547",
  errorCode20289: "20289",
  errorCode20296: "20296",
  errorCode20297: "20297",
  errorCode10001: "10001",
  errorCode20365: "20365",
  errorCode20366: "20366",
  errorCode20301: "20301",
  errorCode20424: "20424",
  errorCode63688: "63688",
  errorCode80488: "80488",
  errorCode80243: "80243",
  errorCode64110: "64110",
  errorCode80486: "80486",
  errorCode80487: "80487",
  errorCode64082: "64082",
  errorCode80248: "80248",
  errorCode80491: "80491",
  errorCode80484: "80484",
  errorCode80229: "80229",
  errorCode80485: "80485",
  errorCode80408: "80408",
  errorCode64111: "64111",
  errorCode80489: "80489",
  errorCode80242: "80242",
  errorCode80232: "80232",
  errorCode80233: "80233",
  errorCode0212: "0212",
  errorCode0203: "0203",
  errorCode63919: "63919",
  errorCode63920: "63920",
  errorCode25377: "25377",
  errorCode62512: "62512",
  errorCode20389: "20389",
  errorCode20342: "20342",
  errorCode25385: "25385",
  errorCode63902: "63902",
  errorCode2627: "2627",
  errorCode62546: "62546",
  errorCode25290: "25290",
  errorCode25084: "25084",
  errorCode25086: "25086",
  errorCode25235: "25235",
  errorCode64079: "64079",
  errorCode80273: "80273",
  errorCode25388: "25388",
  errorCode25286: "25286",
  errorCode64080: "64080",
  errorCode80490: "80490",
  errorCode20354: "20354",
  errorCode63581: "63581",
  errorCode80103: "80103",
  errorCode64093: "64093",
  errorCode20360: "20360",
  errorCode64081: "64081",
  errorCode80421: "80421",
  errorCode80422: "80422",
  errorCode63771: "63771",
  errorCode63764: "63764",
  errorCode80241: "80241",
  errorCode80251: "80251",
  errorCode80168: "80168",
  errorCode80165: "80165",
  errorCode80482: "80482",
  errorCode64108: "64108",
  errorCode25138: "25138",
  errorCode25151: "25151",
  errorcode25369: "25369",
  errorCode26074: "26074",
  errorCode26021: "26021",
  errorCode25161: "25161",
  errorCode25168: "25168",
  errorCode80419: "80419",
  errorCode62521: "62521",
  errorCode62547: "62547",
  errorCode62536: "62536",
  errorCode25365: "25365",
  errorCode63901: "63901",
  errorCode62531: "62531",
  errorCode10152: "10152",
  errorCode26211: "26211",
  errorCode20382: "20382",
  errorCode63945: "63945",
  errorCode80236: "80236",
  errorCode64004: "64004",
  errorCode80222: "80222",
  errorCode64005: "64005",
  errorCode64021: "64021",
  errorCode20229: "20229",
  errorCode20391: "20391",
  errorCode20392: "20392",
  errorCode25382: "25382",
  errorCode25313: "25313",
  errorCode10163: "10163",
  errorCode25314: "25314",
  errorCode25394: "25394",
  errorCode63862: "63862",
  errorCode63869: "63869",
  errorCode26077: "26077",
  errorCode20234: "20234",
  errorCode20271: "20271",
  errorCode64113: "64113",
  errorCode63957: "63957",
  errorCode63953: "63953",
  errorCode80185: "80185",
  errorCode25004: "25004",
  errorCode25088: "25088",
  errorCode80226: "80226",
  errorCode80124: "80124",
  errorCode80170: "80170",
  errorCode80177: "80177",
  errorCode80178: "80178",
  errorCode80179: "80179",
  errorCode80180: "80180",
  messageCodes80175: "80175",
  infoCode80472: "80472",
  infoCode80466: "80466",
  successCode80473: "80473",
  successCode80467: "80467",
  errorCode20401: "20401",
  errorCode26017: "26017",
  messageCodes26019: "26019",
  errorCode25166: "25166",
  errorCode25284: "25284",
  errorCode63860: "63860",
  errorCode20353: "20353",
  errorCode20359: "20359",
  errorCode80176: "80176",
  errorCode25367: "25367",
  errorCode25368: "25368",
  errorCode20375: "20375",
  errorCode25283: "25283",
  errorCode20394: "20394",
  errorCode61043: "61043",
  errorCode80116: "80116",
  errorCode25087: "25087",
  errorCode20259: "20259",
  errorCode25125: "25125",
  errorCode25123: "25123",
  errorCode25124: "25124",
  errorCode25239: "25239",
  errorCode25122: "25122",
  errorCode25246: "25246",
  errorCode25126: "25126",
  errorCode25127: "25127",
  errorCode25128: "25128",
  errorCode20407: "20407",
  errorCode20520: "20520",
  errorCode25384: "25384",
  errorCode20560: "20560",
  errorCode80342: "80342",
  errorCode20284: "20284",
  errorCode20276: "20276",
  errorCode26213: "26213",
  errorCode20286: "20286",
  errorCode20285: "20285",
  errorCode20412: "20412",
  errorCode63864: "63864",
  errorCode20376: "20376",
  errorCode80117: "80117",
  errorCode26018: "26018",
  errorCode59028: "59028",
  errorCode80384: "80384",
  errorCode80385: "80385",
  errorCode62503: "62503",
  errorCode62519: "62519",
  errorCode26205: "26205",
  errorCode80280: "80280",
  errorCode80276: "80276",
  errorCode62520: "62520",
  errorCode20538: "20538",
  errorCode80493: "80493",
  errorCode80494: "80494",
  errorCode62511: "62511",
  errorCode62510: "62510",
  errorCode80285: "80285",
  errorCode20456: "20456",
  errorCode80496: "80496",
  errorCode80501: "80501",
  errorCode80548: "80548",
  errorCode80495: "80495",
  errorCode80498: "80498",
  errorCode80499: "80499",
  errorCode10091: "10091",
  errorCode10092: "10092",
  errorCode20532: "20532",
  errorCode10227: "10227",
  errorCode20237: "20237",
  errorCode80288: "80288",
  errorCode80149: "80149",
  errorCode80225: "80225",
  errorCode25090: "25090",
  errorCode25274: "25274",
  errorCode25114: "25114",
  errorCode25104: "25104",
  errorCode20350: "20350",
  errorCode25095: "25095",
  errorCode25107: "25107",
  errorCode25141: "25141",
  errorCode63917: "63917",
  errorCode63647: "63647",
  errorCode26075: "26075",
  errorCode26168: "26168",
  errorCode26165: "26165",
  errorCode26166: "26166",
  errorCode26167: "26167",
  errorCode26151: "26151",
  errorCode26152: "26152",
  errorCode26153: "26153",
  errorCode26158: "26158",
  errorCode26159: "26159",
  errorCode26160: "26160",
  errorCode26161: "26161",
  errorCode26163: "26163",
  errorCode10229: "10229",
  errorCode25092: "25092",
  errorCode80181: "80181",
  errorCode80182: "80182",
  errorCode80183: "80183",
  errorCode26099: "26099",
  errorCode26100: "26100",
  errorCode26101: "26101",
  errorCode80300: "80300",
  errorCode80299: "80299",
  errorCode26169: "26169",
  errorCode26170: "26170",
  errorCode63111: "63111",
  errorCode80079: "80079",
  errorCode26078: "26078",
  errorCode63679: "63679",
  errorCode63680: "63680",
  errorCode80078: "80078",
  errorCode26107: "26107",
  errorCode26108: "26108",
  errorCode26109: "26109",
  errorCode10094: "10094",
  errorCode26087: "26087",
  errorCode26095: "26095",
  errorCode26114: "26114",
  errorCode26096: "26096",
  errorCode26112: "26112",
  errorCode26097: "26097",
  errorCode20445: "20445",
  errorCode20494: "20494",
  errorCode26103: "26103",
  errorCode10015: "10015",
  errorCode26044: "26044",
  errorCode26084: "26084",
  errorCode63121: "63121",
  errorCode26106: "26106",
  errorCode26202: "26202",
  errorCode26164: "26164",
  errorCode26155: "26155",
  errorCode80106: "80106",
  errorCode25165: "25165",
  errorCode26206: "26206",
  errorCode64032: "64032",
  errorCode64034: "64034",
  errorCode20278: "20278"
};

export const billCycles = {
  EOM: "EOM",
  OTO: "OTO",
  HOTO: "HOLD OTO"
};

export const billCycleIDs = {
  EOM: "1",
  OTO: "3",
  HOTO: "4"
};

export const billingPhaseIDs = {
  phaseOne: "1",
  phaseTwo: "2",
  phaseThree: "3",
  phaseFour: "4",
  phaseFive: "5",
  phaseSix: "6",
  phaseSeven: "7"
};

export const emailSendOptions = {
  clusterName: "IRM MDS Development",
  fromEmail: "chanuga.jayathilaka.contr@ironmountain.com",
  emailTemplateProperties: [
    {
      name: "Company Id",
      value: "25460"
    }
  ]
};

export const branchSortOption = "code";
export const branchSortOrder = "asc";
export const snackBarAutoHideDuration = 10000;
export const dateFormatEu = "DD/MM/YYYY HH:mm";
export const dateFormatMDY = "MM/DD/YYYY HH:mm";

export const securityGroups = {
  terminated: "45"
};

export const dropdownListTypeId = {
  emailDomain: "7"
};

export const securityGroupsFilterId = {
  zero: "0",
  one: "1"
};

export const number100 = "100";
export const weekFr = {
  Su: "D",
  Mo: "L",
  Tu: "M",
  We: "M",
  Th: "J",
  Fr: "V",
  Sa: "S"
};
export const localizationeEsPE = "es-PE";

export const numberConstants = {
  zero: "0",
  one: "1",
  ten: "10",
  tweleve: "12",
  minusOne: "-1",
  thirteen: "13",
  two: "2",
  three: "3",
  four: "4",
  five: "5",
  six: "6",
  seven: "7",
  eight: "8",
  nine: "9",
  twentyFour: "24",
  twentyFive: "25",
  sixteen: "16",
  eighteen: "18",
  seventeethree: "73",
  fourTeen: "14",
  eleven: "11",
  fiveTeen: "15"
};

export const authSection = {
  bypass: "bypass",
  personnelAuth: "personnelAuth"
};

export const roletypes = {
  roleTypeOne: "1",
  roleTypeTwo: "2"
};

export const initPw = {
  initialPw:
    "0xFF45959DB9E4CF325EF39A5526C071FF523DFDE70E4EFCE2EDDA9FC370AA8D7C"
};

export const personnel_locale_id = "1";
export const business_locale_id = "1";

export const secureSyncUser = {
  yes: "1",
  no: "0"
};

export const system_id = "60";
export const personalDetailConstants = {
  globalAttributeTypeIdMax: "161",
  systemIdMax: "69",
  maxMatchesCust: "49",
  autoCompleteReset: "reset"
};

export const globalAttributeTypeIdCustomer = "162";
export const globalAttributeTypeIdNine = "9";
export const globalAttributeTypeIdMedia = "94";
export const systemIdMedia = "60";
export const globalAttributeIdCustomer = "178";
export const systemIdCustomer = "69";
export const globalAttributeTypeIdURL = "160";
export const globalAttributeIdURL = "176";
export const systemIdURL = "69";
export const authType = "auth";
export const confirmation = "confirmation";
export const unauthorized = "Unauthorized";
export const customerPersonnelId = "customer_personnel_id";

export const moduleSettingsEnvs = {
  dev: { name: "dev", environment: "DEV-WFE" },
  qa: { name: "qa", environment: "QA-WFE" },
  prod: { name: "prod", environment: "PROD-WFE" }
};

export const security_program_id = "101";

export const security_program_id_108 = "108";
export const security_program_id_222 = "222";
export const security_program_id_118 = "118";
export const NUMBER_9999 = "9999";
export const notAvailableValue = "N/A";

export const createDistributionListTableConstants = {
  mediaNumber: "mediaNumber",
  returnDate: "returnDate",
  slot: "slot",
  drpCode: "drpCode",
  description: "description"
};

export const C = "C";
export const B = "B";
export const M = "M";
export const E = "E";
export const media_program_type_id_3 = "3";
export const media_program_type_id_4 = "4";

export const system_function_id = "1";
export const current_exchange_status_id = "0";

export const printType = "zpl";
export const jobType = "label";
export const jobName = "testjobZPL";

export const service_source_id_2 = "2";

export const incomplete_stops_comment = "Comment";
export const incomplete_stops_resolution = "Resolution";

export const default_report_date = "1900-01-01";

export const disabledOpacity = "0.3";
export const enabledOpacity = "1";
export const strZerosIdWithZero = "0000000000";

export const accountOnHold = "AccountOnHold";
export const accountClosure = "AccountClosure";
export const exchange_location_type_code = "SL";
export const active_vaults = "activeVaults";
export const page_number = "0";
export const no_of_records = "0";

export const attribute_id = "1";
export const attribute_id_postal_code = "3";

export const minDateServiceInfo = "1975-01-01";
export const ironmountainURL = "www.ironmountain.com";

export const dropdownListValue = "11";

export const all = "all";
export const allUnresolved = "allUnresolved";
export const preferenceTypeIdList = "        39";
export const validationErrorId = "1";

export const systemTypeId = "1004";
export const globalAttributeTypeIdMaxRows = "161";
export const globalAttributeTypeIdCustomerMediaIdentifiers = "96";

export const monthMap = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11
};

export const detectedIn = ["Picking", "UploadForRunPrep", "UploadForArrival"];
export const discrepancystatus = "Unresolved";
export const mediaType = ["OpenMedia", "Container"];
export const discrepancyType = "ALL";
export const carTypeId = 16;
export const carStatusId = 0;
export const maxRowCount = 1000;
export const numberTwo = 2;
export const allCapital = "ALL";
export const inactivityWarningTimeInSec = 20;

export const customerMoveSearch = {
  sourceBranch: "sourceBranch",
  destinationBranch: "destinationBranch",
  destinationCustomer: "destinationCustomer",
  sourceCustomer: "sourceCustomer",
  lastProcessPerformed: "lastProcessPerformed",
  deactivate: "deactivate"
};
export const minimizedWindowWidth = 800;
export const minimizedWindowHeight = 500;
export const sbHelpAttributeId = "78";
export const customerAuthorizationId = "115";
export const drprocedureAttributeId = "88";

/*Filter types*/
export const filterTypes = Object.freeze({
  notReturn: "0",
  return: "1",
  all: "2"
});
/*report Filter types*/
export const reportFilterType = Object.freeze({
  notReturn: "Not Returned",
  return: "Returned",
  all: "All"
});
/*print types*/
export const printReportTypes = Object.freeze({
  drMedia: "1",
  reconciliation: "2"
});
/*report print types*/
export const reportPrintType = Object.freeze({
  drMedia: "DR Media",
  reconciliation: "Reconciliation"
});
export const removeingText = /Click 'OK' to continue/;
export const systemName = "SecureBase";
export const numbersFromZeroToNine = "0123456789";
export const backspaceText = "Backspace";

/**
 * @name alertSeverity
 * @description severity types of an alert
 */
export const alertSeverity = Object.freeze({
  info: "info",
  warning: "warning",
  error: "error",
  success: "success"
});

export const elapsedTime = "3.48";

export const operationTypes = [
  {
    operation_type_id: "1",
    description: t("preferences.operationsServiceIndicatorTypes1")
  },
  {
    operation_type_id: "2",
    description: t("preferences.operationsServiceIndicatorTypes2")
  },
  {
    operation_type_id: "3",
    description: t("preferences.operationsServiceIndicatorTypes3")
  },
  {
    operation_type_id: "4",
    description: t("preferences.operationsServiceIndicatorTypes4")
  },
  {
    operation_type_id: "5",
    description: t("preferences.operationsServiceIndicatorTypes5")
  },
  {
    operation_type_id: "6",
    description: t("preferences.operationsServiceIndicatorTypes6")
  },
  {
    operation_type_id: "7",
    description: t("preferences.operationsServiceIndicatorTypes7")
  },
  {
    operation_type_id: "9",
    description: t("preferences.operationsServiceIndicatorTypes9")
  },
  {
    operation_type_id: "51",
    description: t("preferences.operationsHolidayServiceTypes51")
  },
  {
    operation_type_id: "52",
    description: t("preferences.operationsHolidayServiceTypes52")
  },
  {
    operation_type_id: "53",
    description: t("preferences.operationsHolidayServiceTypes53")
  },
  {
    operation_type_id: "54",
    description: t("preferences.operationsHolidayServiceTypes54")
  },
  {
    operation_type_id: "55",
    description: t("preferences.operationsHolidayServiceTypes55")
  },
  {
    operation_type_id: "56",
    description: t("preferences.operationsHolidayServiceTypes56")
  }
];
