import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@mui/material";
import { EmployeeSearchStyles } from "components/employee-module/employee-search/EmployeeSearchStyles";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { N_CHECKER, Y_CHECKER } from "utils/constants";
const DayOfMovePopup = ({
  openDayOfMove,
  setOpenDayOfMove,
  selectedRadioValue,
  setSelectedRadioValue,
  handleDayOfMoveRunBtnClick,
  isDistributionListCreated,
  setGridRowData,
  setRunBtnEnabled
}) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    setSelectedRadioValue(e.target.value);
  };

  const checkSelectedValue = () => {
    if (isDistributionListCreated === "") {
      return setSelectedRadioValue(Y_CHECKER); // Default to Yes
    } else if (isDistributionListCreated === Y_CHECKER) {
      return setSelectedRadioValue(Y_CHECKER); // Default to Yes
    } else {
      return setSelectedRadioValue(N_CHECKER); // Default to No
    }
  };
  useEffect(() => {
    checkSelectedValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setOpenDayOfMove(false);
    setGridRowData();
    setRunBtnEnabled(false);
  };
  return (
    <CommonModal
      open={openDayOfMove}
      title={t("customerMoveDetail.r2c1")}
      body={
        <Grid
          display={"flex"}
          direction={"column"}
          gap={spacing.verticalMargin20}
        >
          <Grid item gap={spacing.horizontalMargin20}>
            <FormControl id="radio-control">
              <RadioGroup
                id="radio-group"
                name="printType"
                column
                aria-labelledby="Media Request Authorization Type"
                value={selectedRadioValue}
                onChange={handleChange}
                sx={EmployeeSearchStyles.printModalRadio}
              >
                <FormControlLabel
                  id="radio-form-control-1"
                  value={Y_CHECKER}
                  control={<Radio />}
                  label={t("customerMoveDetail.createDistributionList")}
                />

                <FormControlLabel
                  id="radio-form-control-2"
                  value={N_CHECKER}
                  control={<Radio />}
                  label={t("customerMoveDetail.moveOpenMedia")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      }
      buttons={
        <>
          <Button variant="outlined" onClick={handleCancel}>
            {t("common.cancel")}
          </Button>
          <Button variant="contained" onClick={handleDayOfMoveRunBtnClick}>
            {t("customerMoveDetail.run")}
          </Button>
        </>
      }
    ></CommonModal>
  );
};

export default DayOfMovePopup;
