import { Tree } from "@minoru/react-dnd-treeview";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ArrowRight } from "assets/images/ArrowRight.svg";
import { useEffect, useState } from "react";
import {
  nodeTypes,
  rootIds
} from "utils/constants/transportation/RunManagementConstants";
import RequestSummaryModal from "./RequestSummaryModal";
import { TreeIcon } from "./TreeIcon";
import { getRequestColor } from "./helper";

const UnassignedTreeView = ({
  customerData,
  unassignedTreeData,
  isUnassignedExpand,
  mainDistrictId,
  localeByBranch,
  setIsLoading,
  setThrowError,
  setThrowErrorMessage
}) => {
  const [treeData, setTreeData] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [isShowRequestModal, setIsShowRequestModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const root = rootIds.unassignedTasks;

  const handleDrop = () => {
    return;
  };

  useEffect(() => {
    setTreeData(unassignedTreeData);
    //eslint-disable-next-line
  }, [unassignedTreeData]);

  const handleRequestClick = (node) => {
    setRequestId(node.data.request_id);
    setIsShowRequestModal(true);
  };

  return (
    <div className="unassigned-tree-container">
      {treeData && (
        <Tree
          tree={treeData}
          rootId={root}
          onDrop={handleDrop}
          initialOpen={isUnassignedExpand}
          enableAnimateExpand={true}
          sort={(node1, node2) => {
            return (
              parseInt(node1?.data?.sort_order) -
              parseInt(node2?.data?.sort_order)
            );
          }}
          render={(node, { depth, isOpen, onToggle, hasChild }) => (
            <div
              style={{
                marginLeft: hasChild
                  ? `${depth * 20}px`
                  : `${depth * 20 + 20}px`
              }}
              className={`root-node ${
                node.id === selectedId ? "selected" : ""
              }`}
              onClick={() => setSelectedId(node.id)}
            >
              {hasChild && (
                <span onClick={onToggle} className="arrow-icon">
                  {isOpen ? (
                    <ArrowDown
                      id={
                        node.type === nodeTypes.date
                          ? `${node.text}`
                          : `${node.data.service_date + " " + node.text}`
                      }
                    />
                  ) : (
                    <ArrowRight
                      id={
                        node.type === nodeTypes.date
                          ? `${node.text}`
                          : `${node.data.service_date + " " + node.text}`
                      }
                    />
                  )}
                </span>
              )}
              <TreeIcon type={node.type} />
              <span
                className="node-text"
                style={{ color: getRequestColor(node?.data?.request_type_id) }}
                onDoubleClick={() =>
                  node.type === nodeTypes.request && handleRequestClick(node)
                }
              >
                {node.text}
              </span>
            </div>
          )}
        />
      )}

      {isShowRequestModal && (
        <RequestSummaryModal
          setIsShowRequestModal={setIsShowRequestModal}
          mainDistrictId={mainDistrictId}
          requestId={requestId}
          customerData={customerData}
          localeByBranch={localeByBranch}
          setIsLoading={setIsLoading}
          setThrowError={setThrowError}
          setThrowErrorMessage={setThrowErrorMessage}
        />
      )}
    </div>
  );
};
export default UnassignedTreeView;
