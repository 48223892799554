import { Button, Grid, SvgIcon, Tooltip, Typography } from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/images/CloseIcon.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";
import { CircularLoaderFullPage } from "components/core";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { vendorMangementStyles } from "./VendorMangementStyles";

const VendorManagementTable = ({
  setIsCreating,
  setIsViewing,
  setIsUpdating,
  setIsDeleting,
  setSelectedRow,
  isResetGrid,
  setSelctedId,
  isLoading,
  stateList,
  searchedData,
  isUpdating,
  selectedRow
}) => {
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleRowClick = (value, tableMeta) => {
    setSelctedId(value?.transportation_vendor_id);
    setSelectedIndex(tableMeta?.rowIndex);
    setSelectedRow(searchedData[tableMeta?.rowIndex]);
  };

  const getProvinceName = (provinceId) => {
    const province = stateList.find((item) => item.value === provinceId);
    return province ? province.label : "";
  };

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={null}
            onClick={() => {
              setIsCreating(true);
            }}
          >
            {t("common.new")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          isResetGrid && searchedData.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    selectableRowsOnClick: true,
    onRowClick: handleRowClick,
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor:
          isUpdating && dataIndex === selectedIndex
            ? vendorMangementStyles?.SelectedRowColor
            : ""
      }
    })
  };

  const columns = [
    {
      name: "",
      label: "",

      options: {
        setCellProps: () => ({
          style: vendorMangementStyles.actionColumnStyle
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = searchedData[tableMeta.rowIndex];

          return (
            <>
              {" "}
              <Tooltip
                placement="top"
                arrow
                title={
                  <div>
                    <div>{`${t("vendorManagement.view")}`}</div>
                  </div>
                }
              >
                <Button
                  onClick={() => {
                    setIsViewing(true);
                    handleRowClick(rowData, tableMeta);
                  }}
                >
                  <SvgIcon component={ViewIcon} />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                arrow
                title={
                  <div>
                    <div>{`${t("vendorManagement.edit")}`}</div>
                  </div>
                }
              >
                <Button
                  onClick={() => {
                    setIsUpdating(true);
                    handleRowClick(rowData, tableMeta);
                  }}
                >
                  <SvgIcon component={EditIcon} />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                arrow
                title={
                  <div>
                    <div>{`${t("vendorManagement.delete")}`}</div>
                  </div>
                }
              >
                <Button
                  onClick={() => {
                    setIsDeleting(true);
                    handleRowClick(rowData, tableMeta);
                  }}
                >
                  <SvgIcon component={DeleteIcon} />
                </Button>
              </Tooltip>
            </>
          );
        }
      }
    },
    {
      name: "name",
      label: t("vendorManagement.name")
    },
    {
      name: "contact_name",
      label: t("vendorManagement.contact")
    },
    {
      name: "account_number",
      label: t("vendorManagement.account")
    },
    {
      name: "city",
      label: t("vendorManagement.city")
    },
    {
      name: "state_province_id",
      label: t("vendorManagement.province"),
      options: {
        customBodyRender: (value) => {
          return getProvinceName(value);
        }
      }
    }
  ];

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      <Grid
        display={"flex"}
        direction={"column"}
        gap={spacing.verticalMargin20}
      >
        <Grid item xs={12} lg={3} marginTop={spacing.margingTop20}>
          <MUIDataTable
            columns={columns}
            options={options}
            data={searchedData}
            title={
              isResetGrid ? (
                <Typography style={vendorMangementStyles.titleStyle}>
                  {searchedData?.length} {t("common.recordsFound")}
                </Typography>
              ) : (
                ""
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VendorManagementTable;
