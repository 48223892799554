import { Stack } from "@mui/material";
import OpenMediaAuditSearchLayout from "components/open-media-processing/open-media-audit-search/openMediaAuditSearchLayout";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const OpenMediaAuditSearch = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("openMediaAuditSearch.pageTitle")} />
      <OpenMediaAuditSearchLayout />
    </Stack>
  );
};

export default OpenMediaAuditSearch;
