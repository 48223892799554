import { getDocs, query } from "firebase/firestore";
import AxiosInstance from "services/axiosService";
import {
  api_results_collection_resultset0,
  destruct_request_status_collection,
  destruct_request_type_collection
} from "services/firebase";

export const destructionRequestSearch = {
  /**
   * Media destruction request search
   *
   * @param {object} props
   * @param {string} props.fromDate filter from date
   * @param {string} props.toDate filter to date
   * @param {string} props.customerId filter customer id
   * @param {string} props.requestType filter request type id
   * @param {string} props.requestStatus filter request status id
   * @param {string} props.trackingNumber filter tracking number
   * @param {string} props.destructRequestId filter tracking number
   * @returns
   */
  /**
   * Get all request types
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.dreFlag dre flag
   * @returns
   */
  //Getting Request types dropdown values from destruction request type collection
  mediaDestructionGetAllRequestTypes: async () => {
    try {
      let result = [];
      const q = query(destruct_request_type_collection);
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const item = { id: doc?.id, ...doc?.data() };

        result.push(item);
      });
      return result;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  //Getting Request status dropdown values from destruction request status collection n
  mediaDestructionGetAllRequestStatus: async () => {
    let result = [];
    try {
      const q = query(destruct_request_status_collection);
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const item = { id: doc?.id, ...doc?.data() };

        result.push(item);
      });
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  mediaDestructionRequestSearch: async (props) => {
    var requestBody = JSON.stringify(props);

    let response = [];
    // send the parameters to the CF and wait for the response

    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/getdestructmediarequests`,
      requestBody
    ).then((resp) => resp.data);

    // Assuming docId is a property in cfResponse, update the following line
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns

    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );

    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));

    return response;
  }
};
