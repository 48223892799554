import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SvgIcon,
  Tooltip,
  Typography
} from "@mui/material";
import { ReactComponent as CreateOutlinedIcon } from "assets/images/pencil.svg";
import MUIDataTable from "mui-datatables";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { authSection } from "utils/constants";
import { ReactComponent as infoCircleEnable } from "../../../assets/images/infoCircleEnable.svg";

const PersonnelDetailTable = ({
  customersList,
  onCustomerNameSelect,
  personnalID,
  onCustomerSelect,
  onCustomerSelectAll,
  selectedCustomers,
  isAuthenticated,
  personnelCustomerIds,
  valuePersonnelAuth,
  isEditClick
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      name: "customer",
      label: t("personnelDetail.customerTblCol"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = customersList[dataIndex];
          return (
            <>
              {rowData.customer_number}
              {rowData.authorization_comment && (
                <Tooltip title={rowData.authorization_comment}>
                  <SvgIcon component={infoCircleEnable} />
                </Tooltip>
              )}
            </>
          );
        },
        sort: true
      }
    },
    {
      name: "name",
      label: t("personnelDetail.nameTblCol"),
      options: {
        sort: true
      }
    },
    {
      name: "branch",
      label: t("personnelDetail.branchTblCol"),
      options: {
        sort: true
      }
    },
    {
      name: "interactionAuth",
      label: t("personnelDetail.interactionAuthTblCol"),
      options: {
        sort: true
      }
    },
    {
      name: "drAuth",
      label: t("personnelDetail.drAuthTblCol"),
      options: {
        sort: true
      }
    },
    {
      name: "businessPhone",
      label: t("personnelDetail.businessPhoneTblCol"),
      options: {
        sort: true
      }
    },
    {
      name: "extension",
      label: t("personnelDetail.extensionTblCol"),
      options: {
        sort: true
      }
    }
  ];

  const handleRowSelection = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    setSelectedRows((prev) => rowsSelected);
  };

  const options = {
    filter: false,
    jumpToPage: true,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    sort: false,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    onRowSelectionChange: handleRowSelection,
    selectableRows: "none",
    rowsSelected: selectedRows,
    setRowProps: (row, dataIndex) => {
      const isSelected = selectedRows?.includes(dataIndex);
      return {
        style: {
          backgroundColor: isSelected && "var(--color-im-light-blue-100)"
        }
      };
    }
  };

  let sortedCustomerList = useMemo(
    () => customersList.sort((a, b) => a?.customer.localeCompare(b?.customer)),
    [customersList]
  );
  return (
    <>
      <Typography variant="h5">
        {!isEditClick
          ? t("personnelDetail.tableTitle")
          : t("personnelDetail.tableTitleCustAcc")}
      </Typography>
      {/* Selectable (checkbox) || editable (pencil) table with pagination */}
      {
        <MUIDataTable
          data={sortedCustomerList}
          columns={[
            {
              name: "row_id",
              label: isAuthenticated ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          selectedCustomers.length === customersList.length
                        }
                        onChange={(e) => onCustomerSelectAll(e)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </FormGroup>
              ) : (
                " "
              ),
              options: {
                filter: false,
                sort: false,
                download: false,
                viewColumns: true,
                customBodyRender: (value, tableMeta) => {
                  const rowData = customersList[tableMeta?.rowIndex];
                  return (
                    <>
                      {isAuthenticated ? (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  selectedCustomers.filter(
                                    (f) => f.customer_id === rowData.customer_id
                                  ).length
                                }
                                onChange={(e) => onCustomerSelect(e, tableMeta)}
                                inputProps={{ "aria-label": "controlled" }}
                                disabled={
                                  valuePersonnelAuth ===
                                    authSection?.personnelAuth &&
                                  personnalID &&
                                  !personnelCustomerIds.includes(
                                    rowData?.customer_id
                                  )
                                }
                              />
                            }
                          />
                        </FormGroup>
                      ) : personnalID ? (
                        <Button
                          disabled={
                            isAuthenticated &&
                            valuePersonnelAuth === authSection?.personnelAuth &&
                            personnalID &&
                            !personnelCustomerIds.includes(rowData?.customer_id)
                          }
                          onClick={() => {
                            onCustomerNameSelect(
                              value,
                              tableMeta?.tableData[tableMeta?.rowIndex]
                            );
                          }}
                        >
                          <SvgIcon component={CreateOutlinedIcon} />
                        </Button>
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => onCustomerSelect(e, tableMeta)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                          />
                        </FormGroup>
                      )}
                    </>
                  );
                },
                customHeadLabelRender: (columnMeta) => (
                  <div>{columnMeta.label}</div>
                )
              }
            },
            ...columns
          ]}
          options={
            !personnalID ? options : { selectableRows: "none", ...options }
          }
        />
      }
    </>
  );
};

export default PersonnelDetailTable;
