/* Yup validation schemas */
import i18n from "i18n";
import * as Yup from "yup";

/* Add extra insert form schema */
export const extraInsertValidationSchema = Yup.object().shape({
  insert_type_id: Yup.number()
    .moreThan(0, i18n.t("mediaRequestDetail.insertTypeIsRequired"))
    .required(i18n.t("mediaRequestDetail.insertTypeIsRequired")),
  insert_type: Yup.string(),
  quantity: Yup.number()
    .moreThan(0, i18n.t("mediaRequestDetail.quantityMustBeGreaterThanZero"))
    .required(i18n.t("mediaRequestDetail.quantityMustBeGreaterThanZero"))
});
