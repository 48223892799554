import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

const InvalidMediasSnapBar = ({
  setShowInvalidMediasSnapBar,
  invalidMedias
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setShowInvalidMediasSnapBar(false);
  };

  return (
    <>
      <Box style={MDRVaultItemizedStyles.InvalidMediasSnapBarContainer}>
        <Stack sx={MDRVaultItemizedStyles.InvalidMediasSnapBarContainerStack}>
          <Box sx={MDRVaultItemizedStyles.InvalidMediasSnapBar_SecondBox}>
            <span>
              <IconButton
                size="small"
                sx={MDRVaultItemizedStyles.InvalidMediasSnapBar_ErrorIcon}
                onClick={handleClose}
              >
                <ErrorOutlineRoundedIcon fontSize="small" />
              </IconButton>
              <span style={MDRVaultItemizedStyles.InvalidMediasSnapBarSpan}>
                {t("vaultItemized.snapBarTitle")}
              </span>
            </span>
            <IconButton
              size="small"
              sx={MDRVaultItemizedStyles.InvalidMediasSnapBar_CloseIcon}
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box sx={MDRVaultItemizedStyles.InvalidMediasSnapBar_Title}>
            <span style={MDRVaultItemizedStyles.InvalidMediasSnapBarSpan}>
              {t("vaultItemized.snapBarSubTitle")}
            </span>
          </Box>

          <TableContainer
            component={Paper}
            sx={MDRVaultItemizedStyles.InvalidMediasSnapBar_TableContainer}
          >
            <Table sx={MDRVaultItemizedStyles.InvalidMediasSnapBar_Table}>
              <TableBody>
                {invalidMedias.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={MDRVaultItemizedStyles.InvalidMediasSnapBar_TableCell}
                    >
                      {row.volser}
                    </TableCell>
                    <TableCell
                      sx={
                        MDRVaultItemizedStyles.InvalidMediasSnapBar_TableCell_error
                      }
                    >
                      {row.error}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={MDRVaultItemizedStyles.InvalidMediasSnapBar_Box}></Box>
        </Stack>
      </Box>
    </>
  );
};

export default InvalidMediasSnapBar;
