import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { t } from "i18next";

import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  BOOLEAN_STRING_VALUES,
  downloadReportOptions,
  notApplicable,
  Y_CHECKER
} from "utils/constants";
import {
  dailyBillableActivityCountsSummary,
  detail,
  disasterRecoveryActivityCounts,
  monthlyBillableActivityCounts,
  monthlyManagedContainerContentsCount,
  scanAtCustomerBillingActivityCounts,
  zero
} from "utils/constants/reports-module/Reports";
import { getUserName } from "utils/helpers";
import { reportsDownloader } from "./Services";

const CommonReoprtDownloadModal = ({
  title,
  showPrintModal,
  setCloseModal,
  reportName,
  callbackStateData,
  setThrowErrorMessage,
  throwErrorMessage,
  throwError,
  setThrowError,
  mainDistrictId,
  setIsLoading
}) => {
  const selectedValue = downloadReportOptions;
  const { auth, currentBranch } = useSelector(selectAuth);
  const user_name = getUserName(auth?.user);
  const pdfFormat = selectedValue[0].type.toLocaleUpperCase();
  // download button click in download modal
  const updateFunctions = {
    setThrowErrorMessage,
    setThrowError,
    setIsLoading
  };
  const commonPayload = {
    main_district_id: mainDistrictId,
    user_name: user_name,
    report_format: pdfFormat
  };

  const onDownloadClick = async () => {
    switch (reportName) {
      case t("reports.UnassignedTerminatedAccountOwnerTitle"):
        await reportsDownloader(
          updateFunctions,
          commonPayload,
          CF_URLS.printReports.reports.displayTerminatedSalesPerson
        );
        break;
      case t("reports.transportLocationTitle"):
        await reportsDownloader(
          updateFunctions,
          commonPayload,
          CF_URLS.printReports.reports.reportTransportLocation
        );
        break;
      case t("reports.temporaryTransportHistoryTitle"):
        await reportsDownloader(
          updateFunctions,
          {
            ...commonPayload,
            container_id: callbackStateData?.transportNumber?.container_id,
            customer_id: callbackStateData?.cutomers?.value,
            from_date: callbackStateData.retalDateFrom,
            to_date: callbackStateData.retalDateTo,
            selected_by: callbackStateData?.selectedBy
          },
          CF_URLS.printReports.reports.temporyTransportHistoryReport
        );
        break;

      case t("reports.customerScheduleExpirationTitle"):
        await reportsDownloader(
          updateFunctions,
          {
            ...commonPayload,
            expiry_date: callbackStateData.expriationDate
          },
          CF_URLS.printReports.reports.customerScheduleExpirationReport
        );
        break;
      case t("reports.customerAuthorizationListTitle"):
        await reportsDownloader(
          updateFunctions,
          {
            ...commonPayload,
            customer_id: callbackStateData?.value,
            customer_number: callbackStateData?.label
          },
          CF_URLS.printReports.reports.customerAuthorizationListReport
        );
        break;
      case t("reports.inHouseAuthorizationListTitle"):
        await reportsDownloader(
          updateFunctions,
          commonPayload,
          CF_URLS.printReports.reports.inHouseAuthorizationList
        );
        break;
      case t("reports.runsAuthorizationListTitle"):
        await reportsDownloader(
          updateFunctions,
          commonPayload,
          CF_URLS.printReports.reports.runsAuthorizationListReport
        );
        break;
      case t("reports.inHouseAuthorizationUpdateTitle"):
        await reportsDownloader(
          updateFunctions,
          commonPayload,
          CF_URLS.printReports.reports.inHouseAuthorizationListuReport
        );
        break;
      case t("reports.containerInventoryTitle"):
        await reportsDownloader(
          updateFunctions,
          {
            ...commonPayload,
            cust_number_start: callbackStateData?.customerFrom,
            cust_number_end: callbackStateData?.customerTo,
            delete_status: callbackStateData?.containerStatus
          },
          callbackStateData?.selectedReports === detail
            ? CF_URLS.printReports.reports.containerInventoryDetailReport
            : CF_URLS.printReports.reports.containerinventorysummaryreport
        );
        break;
      case t("reports.runsAuthorizationUpdateTitle"):
        await reportsDownloader(
          updateFunctions,
          commonPayload,
          CF_URLS.printReports.reports.runsAuthorizationListUpdate
        );
        break;
      case t("reports.uploadErrorsTitle"):
        await reportsDownloader(
          updateFunctions,
          {
            ...commonPayload,
            show_deleted: Y_CHECKER,
            run_id: zero,
            from_date: callbackStateData?.fromDate,
            to_date: callbackStateData?.toDate,
            batch_id: zero
          },
          CF_URLS.printReports.reports.uploadErrors
        );
        break;
      case t("reports.emptyTransportDeficitTitle"):
        await reportsDownloader(
          updateFunctions,
          {
            ...commonPayload,
            report_type: callbackStateData?.transportLocation
          },
          CF_URLS.printReports.reports.emptyTransportDeficit
        );
        break;
      case t("reports.runArrivalTitle"):
        await reportsDownloader(
          updateFunctions,
          {
            ...commonPayload,
            run_id: callbackStateData?.run?.run_id
          },
          CF_URLS.printReports.reports.runArrivalReport
        );
        break;
      case t("reports.distributionListHistoryTitle"):
        await reportsDownloader(
          updateFunctions,
          {
            ...commonPayload,
            run_id: callbackStateData?.run?.run_id,
            report_title: t("reports.distributionListHistory.title"),
            display_container_slot: BOOLEAN_STRING_VALUES.TRUE,
            selection_criteria: notApplicable,
            run_name: callbackStateData?.run?.run_name,
            service_date: callbackStateData?.serviceDate
          },
          CF_URLS.printReports.reports.reportDist
        );
        break;
      case t("reports.billableActivityCounts"):
        if (
          disasterRecoveryActivityCounts ===
          callbackStateData?.breakout_handling
        ) {
          await reportsDownloader(
            updateFunctions,
            {
              ...commonPayload,
              date_from: callbackStateData?.date_from,
              date_to: callbackStateData?.date_to,
              customer_id: callbackStateData?.customer_id ?? "",
              customer_number: callbackStateData?.customer_number ?? "",
              dr_event_id: callbackStateData?.dr_event_id,
              district_id: mainDistrictId,
              district_name: currentBranch?.name
            },
            CF_URLS.printReports.reports.disasterrecoveryactivitycountsreport
          );
        } else if (
          dailyBillableActivityCountsSummary ===
          callbackStateData?.breakout_handling
        ) {
          await reportsDownloader(
            updateFunctions,
            {
              ...commonPayload,
              date_from: callbackStateData?.date_from,
              date_to: callbackStateData?.date_to,
              customer_id: callbackStateData?.customer_id ?? "",
              customer_number: callbackStateData?.customer_number ?? "",
              district_id: mainDistrictId,
              district_name: currentBranch?.name,
              breakout_handling: callbackStateData?.breakout_handling
            },
            CF_URLS.printReports.reports.dailybillableactivitycount
          );
        } else if (
          callbackStateData?.breakout_handling ===
          scanAtCustomerBillingActivityCounts
        ) {
          await reportsDownloader(
            updateFunctions,
            {
              ...commonPayload,
              date_from: callbackStateData?.date_from,
              date_to: callbackStateData?.date_to,
              customer_id: callbackStateData?.customer_id ?? "",
              district_id: mainDistrictId
            },
            CF_URLS.printReports.reports.scanAtCustomerActivityCountsReport
          );
        } else if (
          monthlyBillableActivityCounts === callbackStateData?.breakout_handling
        ) {
          await reportsDownloader(
            updateFunctions,
            {
              ...commonPayload,
              date_from: callbackStateData?.date_from,
              date_to: callbackStateData?.date_to,
              customer_id: callbackStateData?.customer_id ?? "",
              customer_number: callbackStateData?.customer_number ?? "",
              district_id: mainDistrictId,
              district_name: currentBranch?.name,
              report_title: callbackStateData?.breakout_handling
            },
            CF_URLS.printReports.reports.monthlyBillableActivityCountsReport
          );
        } else if (
          callbackStateData?.breakout_handling ===
          monthlyManagedContainerContentsCount
        ) {
          await reportsDownloader(
            updateFunctions,
            {
              ...commonPayload,
              date_from: callbackStateData?.date_from,
              date_to: callbackStateData?.date_to,
              customer_id: callbackStateData?.customer_id ?? ""
            },
            CF_URLS.printReports.reports
              .monthlyManagedContainerContentsCountReport
          );
        }

        break;
      default:
        break;
    }
  };
  return (
    <>
      <DownloadPopup
        title={title}
        showPrintModal={showPrintModal}
        downloadOptions={selectedValue}
        onCancleClick={setCloseModal}
        onDownloadClick={onDownloadClick}
        throwErrorMessage={throwErrorMessage}
        throwError={throwError}
        setThrowError={setThrowError}
      />
    </>
  );
};

export default CommonReoprtDownloadModal;
