import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import spacing from "styles/spacing";
import { routeManagementStyles } from "./RouteManagementStyle";
import { getCustomerList } from "./helper";

const FindCustomerModal = (props) => {
  const {
    isShowFindCustomerModal,
    setIsShowFindCustomerModal,
    setThrowError,
    routeTreeData,
    openCustomer
  } = props;

  const initalRouteState = {
    customer: null
  };

  const routeForm = useFormik({
    initialValues: initalRouteState
  });

  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    let customerList = getCustomerList(routeTreeData);
    setCustomerData(customerList);
    //eslint-disable-next-line
  }, []);

  const handleCancelClick = () => {
    setThrowError(false);
    setIsShowFindCustomerModal(false);
  };

  const handleApplyClick = () => {
    openCustomer(routeForm.values.customer);
    setIsShowFindCustomerModal(false);
  };

  return (
    <>
      <CommonModal
        open={isShowFindCustomerModal}
        title={t("routeManagement.findCustomerTitle")}
        body={
          <Stack direction="column">
            <Grid container spacing={spacing.gap}>
              <Grid item xs={12} sx={routeManagementStyles.autoCompleteStyle}>
                <Autocomplete
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  clearIcon={<CloseIcon />}
                  options={customerData}
                  size="medium"
                  fullWidth
                  open={true}
                  ListboxProps={routeManagementStyles.autoCompleteListStyle}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("routeManagement.assignedCustomers")}
                    />
                  )}
                  onChange={(e, newValue) => {
                    routeForm.setFieldValue(
                      "customer",
                      newValue?.split(" ")[0]
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Typography component="small" sx={routeManagementStyles.countStyle}>
              {t("routeManagement.count")}: {customerData.length}
            </Typography>
          </Stack>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={handleCancelClick}>
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleApplyClick}
              disabled={!routeForm.values.customer}
            >
              {t("common.apply")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default FindCustomerModal;
