import {
  Box,
  Divider,
  Grid,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from "@mui/material";
import { DatePickerField, SelectField } from "components/core";
import MultiSelectCheckBox from "components/core/multi-select-checkbox/MultiSelectCheckBox";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { DEFAULT_LOCALE, Y_CHECKER } from "utils/constants";
import { ReactComponent as infoCircleEnable } from "../../../../../assets/images/infoCircleEnable.svg";
import { RequestStatusIds } from "../Constants";
import { MDRPickupFormStyles } from "../MediaDestructionRequestPickup.style";
import BoxTypes from "../boxtypes-form/BoxTypes";
import CustomTooltip from "./CustomToolTip";

const SitadDeliveryRequest = ({
  authSuccess,
  diableSITAD,
  sitadErrorMsg,
  trackingRequirement,
  serviceLocation,
  scheduleErrorMsg,
  saveSitad,
  SITADBoxTypes,
  handleSITADBoxTypes,
  dispositionServices,
  setBoxtypeWraning,
  boxtypeWraning,
  handleErrorWarning,
  localeByBranch
}) => {
  const [isDispositionTypeOpen, setIsDispositionTypeOpen] = useState(false);
  const [isDispositionCommentOpen, setIsDispositionCommentOpen] =
    useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const formik = useFormikContext();
  const { t } = useTranslation();
  const minDate = dayjs();
  const maxDate = dayjs(t("requestSearch.maximumDate"));
  const { authInfo } = useSelector(selectAuth);
  const { id } = useParams();
  const handleClickBoxTypeWraning = () => {
    setBoxtypeWraning(false);
  };
  const onHoldStatus =
    formik.values.mdr_on_hold_status === Y_CHECKER ? true : false;
  return (
    <>
      {(authSuccess || Boolean(id)) && <Divider />}
      {/* <<<<======= SITAD  Delivery Request >> STARTS =====>>>>*/}
      {(authSuccess || Boolean(id)) && (
        <Stack gap={spacing.horizontalMargin20}>
          <Typography sx={MDRPickupFormStyles.Heading} variant="h5">
            {t("pickup.titleSitad")}
          </Typography>

          <Stack direction={"row"} gap={spacing.verticalMargin20}>
            <Box sx={MDRPickupFormStyles.notifyViaEmail}>
              <TextField
                required
                disabled={diableSITAD || onHoldStatus || Boolean(id)}
                label={t("vaultItemized.notifyViaEmail")}
                fullWidth
                style={MDRPickupFormStyles.widthPercentage}
                name={t("pickup.notifyViaEmail")}
                id={t("pickup.notifyViaEmail")}
                value={formik.values.notifyViaEmail}
                onChange={formik.handleChange}
                placeholder={t("pickup.emailPlaceHolder")}
                InputLabelProps={{ shrink: true }}
                onBlur={formik.handleBlur}
                error={
                  !id &&
                  (sitadErrorMsg || formik.touched.notifyViaEmail) &&
                  Boolean(formik.errors.notifyViaEmail)
                }
                helperText={
                  !id &&
                  (sitadErrorMsg || formik.touched.notifyViaEmail) &&
                  Boolean(formik.errors.notifyViaEmail)
                    ? formik.errors.notifyViaEmail
                    : t("pickup.emailHelperText")
                }
              />
            </Box>
            <Box>
              <Box sx={MDRPickupFormStyles.trackingRequirement}>
                <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                  <SelectField
                    disabled={diableSITAD || onHoldStatus || Boolean(id)}
                    id="trackingRequirements"
                    labelId="trackingRequirements-label"
                    label={t("pickup.trackingRequirements")}
                    name="TrackingRequirements"
                    options={trackingRequirement}
                    value={formik.values.trackingRequirements}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "trackingRequirements",
                        event.target.value
                      );
                    }}
                    sx={MDRPickupFormStyles.widthPercentage}
                  />
                </Box>
                <Box>
                  <CustomTooltip
                    placement="right"
                    arrow
                    title={
                      <div style={MDRPickupFormStyles.Tooltip}>
                        <div>{`${t("pickup.tooltipScanOnsite")}`}</div>
                        <div style={MDRPickupFormStyles.TextDesign}>{`${t(
                          "pickup.tooltipInteractiveScanOnSite"
                        )}`}</div>
                        <div style={MDRPickupFormStyles.TextDesign}>{`${t(
                          "pickup.toolCustomerPrefernce"
                        )}`}</div>
                      </div>
                    }
                  >
                    <Box>
                      <SvgIcon component={infoCircleEnable} />
                    </Box>
                  </CustomTooltip>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <Box sx={MDRPickupFormStyles.personnelLabelBox}>
                  <Typography
                    variant="caption"
                    id="branchLabel"
                    sx={MDRPickupFormStyles.BranchLabel}
                  >
                    {t("vaultItemized.requestedByLabel")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    id="selectedBranch"
                    sx={MDRPickupFormStyles.branchName}
                  >
                    {formik.values.personnel}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>

          <Stack direction={"row"} gap={spacing.verticalMargin20}>
            <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
              <DatePickerField
                label={t("pickup.preferredPickupDate")}
                name="preferredPickupDate"
                id="preferredPickupDate"
                locale={localeByBranch || DEFAULT_LOCALE}
                value={formik.values.preferredPickupDate}
                onChange={(newValue) => {
                  formik.setFieldValue("preferredPickupDate", newValue);
                }}
                onBlur={formik.handleBlur}
                error={
                  !id &&
                  (sitadErrorMsg || formik.touched.preferredPickupDate) &&
                  Boolean(formik.errors.preferredPickupDate)
                }
                helperText={
                  !id &&
                  (sitadErrorMsg || formik.touched.preferredPickupDate) &&
                  formik.errors.preferredPickupDate
                }
                sx={MDRPickupFormStyles.DatePickerField}
                minDate={minDate}
                maxDate={maxDate}
                disabled={diableSITAD || onHoldStatus || Boolean(id)}
              />
            </Box>
            <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
              <SelectField
                disabled={diableSITAD || onHoldStatus || Boolean(id)}
                id="serviceLocation"
                labelId="serviceLocation-label"
                label={t("pickup.serviceLocationLabel")}
                name="serviceLocation"
                options={serviceLocation}
                value={
                  formik.values.selectedCustomer
                    ? formik.values.serviceLocation
                    : ""
                }
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                onBlur={formik.handleBlur}
                error={
                  !id &&
                  (sitadErrorMsg || formik.touched.serviceLocation) &&
                  Boolean(formik.errors.serviceLocation)
                }
                helperText={
                  !id &&
                  (sitadErrorMsg || formik.touched.serviceLocation) &&
                  formik.errors.serviceLocation
                }
                sx={MDRPickupFormStyles.widthPercentage}
              />
            </Box>
            <Box sx={MDRPickupFormStyles.pickupInstructions}>
              <TextField
                required
                disabled={diableSITAD || onHoldStatus || Boolean(id)}
                label={t("pickup.pickupInstructions")}
                name="pickupInstructions"
                id="pickupInstructions"
                value={formik.values.pickupInstructions}
                onChange={(event) => {
                  formik.handleChange(event);
                  formik.setFieldValue(
                    "schedulePickupInstructions",
                    event.target.value
                  );
                }}
                fullWidth
                style={MDRPickupFormStyles.widthPercentage}
                onBlur={formik.handleBlur}
                error={
                  !id &&
                  (sitadErrorMsg || formik.touched.pickupInstructions) &&
                  Boolean(formik.errors.pickupInstructions)
                }
                helperText={
                  !id &&
                  (sitadErrorMsg || formik.touched.pickupInstructions) &&
                  formik.errors.pickupInstructions
                }
              />
            </Box>
          </Stack>
          <Stack direction={"row"} gap={spacing.verticalMargin20}>
            <Grid sx={MDRPickupFormStyles.FieldWidthMedium}>
              <TextField
                required
                disabled={
                  formik.values.statusId === RequestStatusIds.Scheduled ||
                  formik.values.statusId === RequestStatusIds.Cancelled ||
                  formik.values.statusId === RequestStatusIds.InProcess ||
                  formik.values.statusId === RequestStatusIds.Completed ||
                  formik.values.statusId === RequestStatusIds.PendingReview ||
                  formik.values.statusId ===
                    RequestStatusIds.PendingConfirmation ||
                  onHoldStatus
                    ? true
                    : false
                }
                label={t("pickup.trackingNumberLabel")}
                name="trackingNumber"
                id="trackingNumber"
                value={formik.values.trackingNumber}
                onChange={formik.handleChange}
                fullWidth
                style={MDRPickupFormStyles.widthPercentage}
                onBlur={formik.handleBlur}
                error={
                  (sitadErrorMsg ||
                    scheduleErrorMsg ||
                    formik.touched.trackingNumber) &&
                  Boolean(formik.errors.trackingNumber)
                }
                helperText={
                  (sitadErrorMsg ||
                    scheduleErrorMsg ||
                    formik.touched.trackingNumber) &&
                  formik.errors.trackingNumber
                }
              />
            </Grid>
            <CustomTooltip
              title={<div>{`${formik.values.dispositionServices}`}</div>}
              arrow
              placement="right"
              id="tooltipDispositionService"
              open={
                formik.values.dispositionServices
                  ? isDispositionTypeOpen
                  : false
              }
              onClose={() => setIsDispositionTypeOpen(false)}
            >
              <Grid sx={MDRPickupFormStyles.pickupInstructions}>
                <MultiSelectCheckBox
                  multiple
                  fullWidth
                  renderValue={(selected) => (
                    <Box
                      sx={MDRPickupFormStyles.overflow}
                      onMouseEnter={() => setIsDispositionTypeOpen(true)}
                      onMouseLeave={() => setIsDispositionTypeOpen(false)}
                    >
                      {selected.join(", ")}
                    </Box>
                  )}
                  disabled={diableSITAD || onHoldStatus || Boolean(id)}
                  id="dispositionService"
                  labelId="dispositionService-label"
                  label={t("pickup.dispositionServiceLabel")}
                  name="dispositionService"
                  options={dispositionServices}
                  value={formik.values.dispositionServices}
                  onChange={(event, val) => {
                    const {
                      target: { value }
                    } = event;
                    const selectedData = dispositionServices.filter((item) =>
                      event.target.value.includes(item.label)
                    );
                    formik.setFieldValue("selectedDisposition", selectedData);
                    formik.setFieldValue(
                      "dispositionServices",
                      // On autofill we get a stringified value.
                      typeof value === "string" ? value.split(",") : value
                    );
                    formik.setFieldValue(
                      "dispositionService",
                      typeof value === "string" ? value.split(",") : value
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    !id &&
                    (sitadErrorMsg || formik.touched.dispositionServices) &&
                    Boolean(formik.errors.dispositionServices)
                  }
                  helperText={
                    !id &&
                    (sitadErrorMsg || formik.touched.dispositionServices) &&
                    formik.errors.dispositionServices
                  }
                />
              </Grid>
            </CustomTooltip>
          </Stack>
          <Stack direction={"row"} gap={spacing.verticalMargin20}>
            <Grid sx={MDRPickupFormStyles.pickupInstructions}>
              <CustomTooltip
                title={
                  <div
                    style={MDRPickupFormStyles.TooltipDespoition}
                  >{`${formik.values.dispositionServiceComments}`}</div>
                }
                arrow
                placement="right"
                id="tooltipDispositionServiceComments"
                open={
                  formik.values.dispositionServiceComments !== ""
                    ? isDispositionCommentOpen
                    : false
                }
                onClose={() => setIsDispositionCommentOpen(false)}
              >
                <TextField
                  required
                  disabled={diableSITAD || onHoldStatus || Boolean(id)}
                  label={t("pickup.dispositionServiceComments")}
                  name="dispositionServiceComments"
                  id="dispositionServiceComments"
                  fullWidth
                  style={MDRPickupFormStyles.widthPercentage}
                  onChange={formik.handleChange}
                  onMouseOver={() =>
                    !isFocused && setIsDispositionCommentOpen(true)
                  }
                  onMouseLeave={() => {
                    setIsDispositionCommentOpen(false);
                  }}
                  value={formik.values.dispositionServiceComments}
                  onFocus={() => {
                    setIsFocused(true);
                    setIsDispositionCommentOpen(false);
                  }}
                  onBlur={() => {
                    setIsFocused(false);
                    formik.setFieldTouched("dispositionServiceComments", true);
                  }}
                  error={
                    !id &&
                    (sitadErrorMsg ||
                      formik.touched.dispositionServiceComments) &&
                    Boolean(formik.errors.dispositionServiceComments)
                  }
                  helperText={
                    !id &&
                    (sitadErrorMsg ||
                      formik.touched.dispositionServiceComments) &&
                    formik.errors.dispositionServiceComments
                  }
                />
              </CustomTooltip>
            </Grid>
          </Stack>
        </Stack>
      )}
      {/* <<<<======= Box Type Section=====>>>>*/}
      {(authSuccess || Boolean(id)) && (
        <Box>
          <Typography
            sx={MDRPickupFormStyles.Heading}
            variant="h5"
            id="boxtypelabel"
          >
            {t("pickup.boxTypeLabel")}
          </Typography>
        </Box>
      )}
      {/* ------------------- BoxType Alert ------------------- */}
      {boxtypeWraning &&
        handleErrorWarning(handleClickBoxTypeWraning, t("pickup.boxTypeError"))}
      {/* <<<<=======  ModifiedDate section  =====>>>>*/}
      {saveSitad && (
        <Box>
          <Stack gap="2em" direction={"row"}>
            <Typography
              disabled={diableSITAD || onHoldStatus || Boolean(id)}
              id="modifiedDate"
            >
              {t("pickup.modifiedDate")}: {formik.values.sitadModifiedDate}
            </Typography>
            <Typography
              disabled={diableSITAD || onHoldStatus || Boolean(id)}
              id="performedBy"
            >
              {t("pickup.performedBy")} :{" "}
              <span>
                {id && formik.values.loggedEmployeeName !== ""
                  ? formik.values.loggedEmployeeName
                  : authInfo?.displayName}
              </span>
            </Typography>
          </Stack>
        </Box>
      )}
      {(authSuccess || Boolean(id)) && (
        <BoxTypes
          boxTypes={SITADBoxTypes}
          diableSITAD={diableSITAD || onHoldStatus || Boolean(id)}
          boxTypesVal={handleSITADBoxTypes}
          calledFromSITAD={true}
          fieldName={"boxTypesVal"}
        />
      )}
    </>
  );
};

export default SitadDeliveryRequest;
