import { Stack } from "@mui/material";
import { PageTitle } from "components/shared";
import RunManagement from "components/transportation-module/run-management/RunManagement";
import i18n from "../../i18n";

const RunManagementPage = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("runManagement.pageTitle")} />
      <RunManagement />
    </Stack>
  );
};

export default RunManagementPage;
