import { Card, CardContent, Typography } from "@mui/material";
import { t } from "i18next";
import exclamation from "../../../assets/images/Exclamation.svg";
import vector from "../../../assets/images/Vector.svg";
import { ToDoStyles } from "./ToDoStyles";

const ToDoItem = ({ labelText, number, onCardClick }) => {
  return (
    <>
      <Card
        sx={{
          ...ToDoStyles.ItemCardWidth,
          cursor: number > 0 ? "pointer" : "default"
        }}
        onClick={number > 0 ? () => onCardClick(labelText) : null}
      >
        <CardContent sx={ToDoStyles.CardContentStyle}>
          <img
            src={number > 0 ? exclamation : vector}
            alt={t("dashboard.todoImg")}
            style={ToDoStyles.ImageMargin}
          />
          <Typography
            sx={{
              fontWeight:
                number > 0 ? ToDoStyles.typoBold : ToDoStyles?.typoNormal
            }}
          >
            {number} {labelText}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default ToDoItem;
