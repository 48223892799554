export const runManagementStyles = {
  searchGridStyle: {
    position: "relative"
  },
  searchFieldStyle: {
    width: "310px",
    height: "56px"
  },
  hintStyle: {
    position: "absolute",
    right: "0px",
    bottom: "0px",
    display: "flex"
  },
  hintTextStyle: {
    marginRight: "20px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px"
  },
  hintCircleStyle: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: "5px"
  },
  runCardStyle: {
    margin: "20px 0px",
    width: "calc(35vw - 70px)"
  },
  unassignedCardStyle: {
    width: "calc(28vw - 70px)"
  },
  runCardTitleStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "12px"
  },
  iconBoxStyle: {
    display: "flex"
  },
  iconStyle: {
    cursor: "pointer"
  },
  findCustomerStyle: {
    marginLeft: "1rem"
  },
  runCardContentStyle: {
    paddingTop: "0px",
    paddingBottom: "10px !important"
  },
  runCardHintStyle: {
    fontSize: "12px",
    color: "var(--color-im-grey-38)"
  },
  buttonRowStyle: {
    marginTop: "20px"
  },
  errorIconStyle: {
    marginBottom: "20px"
  },
  errorStyle: {
    paddingLeft: "20px"
  },
  radioLabelStyle: {
    marginTop: "20px",
    fontWeight: "600",
    color: "#000"
  },
  radioStyle: {
    marginBottom: "10px"
  },
  countStyle: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "1rem"
  },
  mediaCardStyle: {
    marginTop: "5px"
  },
  mediaGridStyle: {
    height: "380px"
  },
  commentTitleStyle: {
    fontWeight: "700"
  },
  commentStyle: {
    height: "80px",
    overflow: "auto"
  },
  recordStyle: {
    padding: ".5rem 1rem"
  },
  autoCompleteStyle: {
    height: "325px"
  },
  autoCompleteListStyle: {
    style: { maxHeight: 250 }
  },
  iconKey1: { color: "var(--color-primary)" }
};
