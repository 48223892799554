export const ReportsStyles = {
  listItemsStyle: {
    textDecoration: "underline",
    margin: ".5rem 0 .5rem 0",
    color: "#1B75BC"
  },
  reportsFlexItems: {
    display: "flex",
    gap: ".5rem"
  },
  reportsTopBorder: {
    borderTop: "1px solid var(--color-im-grey-50)",
    padding: ".8rem 0 1rem 0",
    marginTop: "1rem",
    fontSize: "19px"
  },

  cursorPointer: {
    cursor: "pointer"
  },
  radioButton: {
    marginRight: "20px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHght: "150%" /* 24px */,
    letterSpacing: "0.15px"
  },
  inventoryFileExtractStyles:{
    paddingTop: "20px",

  },
  billingModalStyles:{
    paddingTop1 :{paddingTop: "15px"},
    paddingTop2: {paddingTop: "20px"},
    paddingTop3: {paddingTop: "10px"}
  }
};
