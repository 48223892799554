import { Box } from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { dayMonthYearFormat } from "utils/constants";
import { getBoxStyles, getPickersDayStyles } from "./openMediaResearchStyles";

// Handle date format change
export const dateFormat = (date) => {
  return dayjs(date).format(dayMonthYearFormat);
};

// Highlighting the Scheduled Service dates
export const HighlightScheduledDates = ({
  day,
  outsideCurrentMonth,
  selectedDate,
  hightlightedDates,
  ...params
}) => {
  const isSelected =
    !outsideCurrentMonth && hightlightedDates.includes(dateFormat(day));
  const bgColorScheduledSelectDate =
    dateFormat(day) === dateFormat(selectedDate) &&
    hightlightedDates.includes(dateFormat(day));
  const bgColorNonScheduledSelectDate =
    dateFormat(day) === dateFormat(selectedDate) && !isSelected;

  return (
    <Box sx={getBoxStyles(bgColorScheduledSelectDate)}>
      <PickersDay
        {...params}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
        sx={getPickersDayStyles(bgColorNonScheduledSelectDate)}
      />
    </Box>
  );
};
