import CheckIcon from "@mui/icons-material/Check";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { ReactComponent as ArrowDownGrey } from "assets/images/arrow-down.svg";
import { useTranslation } from "react-i18next";
import { useGetAllPersonnelQuery } from "services/api";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

const PersonnelSelectionVault = ({
  customerSelected,
  isReadOnly,
  requestedBySelected,
  handleRequestedByChange,
  mrdForm,
  id,
  isAuthSuccess
}) => {
  const { t } = useTranslation();

  const { data: personnels = [] } = useGetAllPersonnelQuery({
    customerId: mrdForm.values?.customer
  });

  return (
    <Stack direction="row" gap="20px" alignItems="center">
      <Box sx={MDRVaultItemizedStyles.PersonnelSelectionVault_Box}>
        <Autocomplete
          clearIcon={<CloseIcon />}
          popupIcon={
            customerSelected === "" || isReadOnly ? (
              <ArrowDownGrey />
            ) : (
              <ArrowDown />
            )
          }
          disablePortal
          disabled={customerSelected === "" || isReadOnly}
          id="requestedBySelection"
          options={["", ...personnels] || []}
          sx={MDRVaultItemizedStyles.PersonnelSelectionVault_TF}
          value={requestedBySelected}
          onChange={handleRequestedByChange}
          isOptionEqualToValue={id && ((option, value) => true)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("vaultItemized.requestedByLabel")}
              InputLabelProps={
                MDRVaultItemizedStyles.PersonnelSelectionVault_InputLabelProps
              }
              required
              value={mrdForm.values?.requestedBy}
              error={
                mrdForm.touched.requestedBy &&
                Boolean(mrdForm.errors.requestedBy)
              }
              helperText={
                mrdForm.touched.requestedBy && mrdForm.errors.requestedBy
              }
            />
          )}
        />
      </Box>

      <Stack direction="row" gap="5px">
        {isAuthSuccess && (
          <>
            <Typography
              color="success"
              variant="subtitle1"
              sx={MDRVaultItemizedStyles.PersonnelSelectionVault_Auth}
            >
              <CheckIcon />
              {t("vaultItemized.authenticated")}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default PersonnelSelectionVault;
