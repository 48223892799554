import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Stack
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADMIN_MODULE_AUTH_BASE_PATH,
  ADMIN_MODULE_BASE_PATH,
  PERSONAL_DETAIL
} from "routing/Paths";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import spacing from "styles/spacing";
import {
  DEFAULT_LOCALE_CODE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  numberConstants
} from "utils/constants";
import { findErrorMessage } from "utils/helpers";
import { personnelDetailStyles } from "./PersonnelDetailStyles";
import PersonnelHistoryTable from "./PersonnelHistoryTable";

import { useLocation } from "react-router-dom";
const PersonnelDetailHistory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { firstName, lastName } = location.state;
  const [historyData, setHistoryData] = useState([]);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(numberConstants.one);
  const [totalRecords, setTotalRecords] = useState(numberConstants.zero);
  const [lastFetchedPage, setLastFetchedPage] = useState(0);

  let initialValues = {
    personnel: ""
  };

  const personalHistoryForm = useFormik({
    initialValues: initialValues
  });

  const handleBackButtonClick = () => {
    navigate(
      `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_AUTH_BASE_PATH}/${PERSONAL_DETAIL}/${id}`
    );
  };

  useEffect(() => {
    if (currentPage > lastFetchedPage) {
      getHistoryData();
      setLastFetchedPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const getResponseData = async (reqBody, url, resultSetCount) => {
    setThrowError(false);
    setThrowErrorMessage(null);
    try {
      const response = await callToCloudFunction(reqBody, url);
      const dataSets = await getDataFromFirestore(
        response,
        resultSetCount,
        response?.data?.docId
      );

      return dataSets;
    } catch (error) {
      return null;
    }
  };

  const handleChangePage = (page) => {
    const nextPage = page + parseInt(numberConstants.one);
    setCurrentPage(nextPage);
  };

  const getHistoryData = async () => {
    setIsLoading(true);
    try {
      const startIndex =
        (currentPage - parseInt(numberConstants.one)) *
          parseInt(numberConstants.tweleve) +
        parseInt(numberConstants.one);
      const endIndex = currentPage * parseInt(numberConstants.tweleve);
      const reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: id,
        iso_locale_code: DEFAULT_LOCALE_CODE,
        start_index: startIndex.toString(),
        end_index: endIndex.toString(),
        sort_property: numberConstants.zero,
        sort_order: numberConstants.zero,
        customer_number_filter: numberConstants.minusOne
      });

      const response = await getResponseData(
        reqBody,
        CF_URLS.personnalHistory.getPersonalHistory,
        3
      );
      if (response) {
        if (response.error) {
          setThrowErrorMessage(response.error);
          setThrowError(true);
        } else {
          const dataSets = response.data;
          const newData = dataSets[0];
          if (newData[0]?.error) {
            setThrowErrorMessage(newData[0]?.error);
            setThrowError(true);
          } else {
            newData?.sort((a, b) =>
              a?.customer_number?.localeCompare(b?.customer_number)
            );

            setTotalRecords(dataSets[1][0]?.total_rows);
            if (currentPage === numberConstants.one) {
              setHistoryData(newData);
            } else setHistoryData((prevData) => [...prevData, ...newData]);
            setIsResetGrid(false);
          }
        }
      } else {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e?.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
    setIsLoading(false);
  };

  const tableProps = {
    historyData,
    isResetGrid,
    isLoading,
    handleChangePage,
    totalRecords,
    personalHistoryForm,
    firstName,
    lastName
  };
  return (
    <>
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            sx={{ marginBottom: spacing.margingTop20 }}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Stack gap={spacing.gap}>
        <PersonnelHistoryTable {...tableProps} />
        <Divider />
        <Box sx={personnelDetailStyles.buttonStyles.containerBox}>
          <Button variant="outlined" onClick={handleBackButtonClick}>
            {t("common.back")}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default PersonnelDetailHistory;
