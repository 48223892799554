import styled from "@emotion/styled";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress, SvgIcon, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { ReactComponent as BatchIcon } from "assets/images/BatchIcon.svg";
import { ReactComponent as CustomerIcon } from "assets/images/CustomerIcon.svg";
import { ReactComponent as DRIcon } from "assets/images/DRIcon.svg";
import { ReactComponent as IntervaultIcon } from "assets/images/IntervaultIcon.svg";
import { ReactComponent as NotepadIcon } from "assets/images/NotepadIcon.svg";
import { ReactComponent as RequestIcon } from "assets/images/RequestIcon.svg";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { DEFAULT_LOCALE, Y_CHECKER } from "utils/constants";
import {
  customerPrefix,
  iconType1,
  iconType2,
  iconType3,
  iconType4,
  iconType5,
  logicalVaultPrefix,
  pickingNodeId,
  pickingOptioncompletedBatches,
  statusStarted,
  typeCustomer,
  typeLogicalVault,
  typePick
} from "utils/constants/open-media-processing/WorkManagement";
import { dateTimeFormatByLocale } from "utils/helpers";
import { WorkManagementTreeStyles } from "../WorkManagementStyles";

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": WorkManagementTreeStyles.checkboxColor
});

const StyledTreeItem = styled(TreeItem)({
  "& .MuiTreeItem-iconContainer": WorkManagementTreeStyles.iconStyles
});

const PickingTree = ({
  localeByBranch,
  loadTablePicking,
  customerList,
  fetchSecondLevel,
  pickingList,
  pickingListLoaders,
  checked,
  setChecked,
  expanded,
  setExpanded,
  pickingFilter
}) => {
  const [pickingListWithNode, setPickingListWithNode] = useState({});
  const [awaitToggleEvent, setAwaitToggleEvent] = useState([]);
  const [awaitExpand, setAwaitExpand] = useState([]);

  // To add node and label to picking list required for Tree
  useEffect(() => {
    let pickingListWithNode = {};
    for (const key in pickingList) {
      if (pickingList[key].length) {
        pickingListWithNode[key] = pickingList[key]?.map((pick, index) => {
          return {
            ...pick,
            label: getThirdLevelLabel(pick),
            nodeId: getThirdLevelNodeId(pick, index)
          };
        });
      } else {
        pickingListWithNode[key] = pickingList[key];
      }
    }

    setPickingListWithNode(pickingListWithNode);
    //eslint-disable-next-line
  }, [pickingList]);

  useEffect(() => {
    setExpanded([pickingNodeId]);
    //eslint-disable-next-line
  }, [customerList]);

  // Checkbox toggle
  const handleToggle = useCallback(
    (nodeId, customerId, type, customer, logicalVaultCode) => {
      const currentIndex = checked.indexOf(nodeId);

      if (type === typeCustomer && !pickingList[customerId]?.length) {
        fetchSecondLevel(customerId);
        setAwaitExpand((prev) => [...prev, nodeId]);
        setAwaitToggleEvent((prev) => [
          ...prev,
          {
            nodeId,
            customerId,
            type,
            customer,
            logicalVaultCode
          }
        ]);
        return;
      }

      let newChecked = [...checked];

      // Helper functions
      const getUnderNodes = () => {
        if (type === typeCustomer) {
          return nodesOfThisCustomer?.map((pick) => pick.nodeId) || [];
        } else if (type === typeLogicalVault) {
          return getGroupedNodesOfGivenLogicalVault(logicalVaultCode);
        }
        return [];
      };

      const getGroupedNodesOfGivenLogicalVault = (lv) =>
        groupedWithLogicalVaults[lv]?.map((pick) => pick.nodeId) || [];

      const getCheckedCount = (list) =>
        list.filter((pickNode) => newChecked.includes(pickNode)).length;

      const nodesOfThisCustomer = pickingListWithNode[customerId] || [];
      const groupedWithLogicalVaults = getGroupedPickings(nodesOfThisCustomer);
      const logicalVaults = Object.keys(groupedWithLogicalVaults);
      const pickNodes = getGroupedNodesOfGivenLogicalVault(logicalVaultCode);

      const underNodes = getUnderNodes();

      const updateNewChecked = (isAdd) => {
        if (isAdd) {
          if (type === typeCustomer) {
            logicalVaults.forEach((lv) => {
              newChecked.push(getSecondLevelNode(customer, lv));
            });
          } else if (type === typeLogicalVault) {
            newChecked.push(getNode(customer));
          }
          newChecked.push(...underNodes, nodeId);

          if (
            type === typePick &&
            pickNodes.length === getCheckedCount(pickNodes)
          ) {
            newChecked.push(
              getSecondLevelNode(customer, logicalVaultCode),
              getNode(customer)
            );
          }
        } else {
          if (type === typeCustomer) {
            logicalVaults.forEach((lv) => {
              underNodes.push(getSecondLevelNode(customer, lv));
            });
          } else if (type === typeLogicalVault) {
            underNodes.push(getNode(customer));
          }

          if (type === typePick && getCheckedCount(pickNodes) === 1) {
            underNodes.push(
              getSecondLevelNode(customer, logicalVaultCode),
              getNode(customer)
            );
          }

          newChecked = newChecked.filter(
            (item) => !underNodes.concat(nodeId).includes(item)
          );
        }
      };

      updateNewChecked(currentIndex === -1);

      setChecked(newChecked);
      loadTablePicking(newChecked.filter((id) => !isNaN(parseInt(id))));
    },
    [
      checked,
      pickingList,
      pickingListWithNode,
      fetchSecondLevel,
      loadTablePicking,
      setChecked
    ]
  );

  // Tree expand event Handler
  const handleExpand = useCallback(
    (event, nodeId, isExpanded) => {
      setExpanded((prev) =>
        isExpanded ? [...prev, nodeId] : prev.filter((id) => id !== nodeId)
      );
    },
    [setExpanded]
  );

  useEffect(() => {
    if (awaitToggleEvent.length) {
      awaitToggleEvent.forEach((event) => {
        if (pickingListWithNode[event.customerId]?.length) {
          let { nodeId, customerId, type, customer, logicalVaultCode } = event;
          handleToggle(nodeId, customerId, type, customer, logicalVaultCode);

          setAwaitToggleEvent((prev) =>
            prev.filter((e) => event.nodeId !== e.nodeId)
          );
        }
      });
    }
    if (awaitExpand.length) {
      awaitExpand.forEach((id) => {
        handleExpand(null, id, true);

        setAwaitExpand((prev) => prev.filter((eid) => eid !== id));
      });
    }
  }, [
    pickingListWithNode,
    awaitExpand,
    awaitToggleEvent,
    handleExpand,
    handleToggle
  ]);

  const handleClick = useCallback(
    (e, id, nodeId) => {
      fetchSecondLevel(id);
      if (pickingList[id]?.length === 0) {
        setAwaitExpand((prev) => [...prev, nodeId]);
      }
    },
    [fetchSecondLevel, pickingList]
  );

  // Get intermediate checkbox state
  const getCheckboxIntermediate = (
    type,
    nodeId,
    customerId,
    logicalVaultCode
  ) => {
    if (type === typePick) {
      return false;
    }

    const getPickNodes = () => {
      const nodesOfThisCustomer = pickingListWithNode[customerId] || [];
      if (type === typeCustomer) {
        return nodesOfThisCustomer;
      } else {
        return getGroupedPickings(nodesOfThisCustomer)[logicalVaultCode] || [];
      }
    };

    const pickNodes = getPickNodes();
    const checkedCount = pickNodes.filter((pickNode) =>
      checked.includes(pickNode.nodeId)
    ).length;

    return checkedCount > 0 && checkedCount !== pickNodes.length;
  };

  // Get node ID for second level
  const getSecondLevelNode = (customer, key) =>
    `${logicalVaultPrefix}${customer.customer_number}${customer.task_status}${key}`;

  // get lable typography
  const getLabelTypography = (label, isRed) => (
    <Typography
      sx={
        isRed && pickingFilter !== pickingOptioncompletedBatches
          ? WorkManagementTreeStyles.textColorRed
          : {}
      }
    >
      {label}
    </Typography>
  );

  // Get label for second level
  const getSecondLevelLabel = (customer, key) => {
    let nodeId = getSecondLevelNode(customer, key);
    const label = expanded.includes(nodeId)
      ? `${key}`
      : `${key} (${customer.task_status})`;

    let isRed = !(
      customer.task_status === statusStarted || expanded.includes(nodeId)
    );
    return getLabelTypography(label, isRed);
  };

  // Get node ID for first level
  let getNode = (customer) => {
    return `${customerPrefix}${customer.customer_number}${customer.task_status}`;
  };

  // Get label for first level
  let getLable = (customer) => {
    let nodeId = getNode(customer);
    let labelText = expanded.includes(nodeId)
      ? `${customer.customer_number}`
      : `${customer.customer_number} (${customer.task_status})`;

    let isRed = !(
      customer.task_status === statusStarted || expanded.includes(nodeId)
    );
    return getLabelTypography(labelText, isRed);
  };

  // Get icon based on type ID
  const getIcon = (iconTypeId) => {
    switch (iconTypeId) {
      case iconType1:
        return NotepadIcon;
      case iconType2:
        return RequestIcon;
      case iconType3:
        return IntervaultIcon;
      case iconType4:
        return BatchIcon;
      case iconType5:
        return DRIcon;
      default:
        return null;
    }
  };
  // Get node ID for third level
  const getThirdLevelNodeId = (pick, index) =>
    `${pick?.batch_id || pick?.picking_request_id}-${index}`;

  const getThirdLevelLabel = (pick) => {
    const formatDateTime = (datetime) =>
      dateTimeFormatByLocale(datetime, localeByBranch || DEFAULT_LOCALE);

    let label;
    if (pick.batch_id) {
      label =
        pick.mdr_flag === Y_CHECKER
          ? `${t("workManagement.picking.destruction")} ${formatDateTime(
              pick.created_datetime
            )}`
          : `${t("workManagement.picking.batch")} ${pick.batch_id}${
              pick.scanned_by_initials ? ` ${pick.scanned_by_initials}` : ""
            } (${pick?.task_status})`;
    } else {
      label = pick.logical_vault_id
        ? dateTimeFormatByLocale(
            pick.created_datetime,
            localeByBranch || DEFAULT_LOCALE
          ) + `(${pick?.task_status})`
        : pick.mdr_flag === Y_CHECKER
          ? `${t("workManagement.picking.destruction")} ${formatDateTime(
              pick.created_datetime
            )}`
          : pick.request_type +
            dateTimeFormatByLocale(
              pick.created_datetime,
              localeByBranch || DEFAULT_LOCALE
            ) +
            `(${pick?.task_status})`;
    }

    return (
      <Typography
        sx={
          pickingFilter !== pickingOptioncompletedBatches
            ? WorkManagementTreeStyles.textColorRed
            : {}
        }
      >
        {label}
      </Typography>
    );
  };

  const getGroupedPickings = (list) => {
    return list.reduce((acc, item) => {
      const key =
        item.logical_vault_code?.trim() ||
        t("workManagement.picking.otherDeliveryRequests");
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const getSecondLevelChildren = (customer) => {
    const groupedPickings = getGroupedPickings(
      pickingListWithNode[customer.customer_id] || []
    );
    return Object.keys(groupedPickings).map((key) => {
      const label = getSecondLevelLabel(customer, key);
      const nodeId = getSecondLevelNode(customer, key);

      const children = groupedPickings[key].map((pick) => ({
        ...pick,
        icon: getIcon(pick.icon_type_id),
        type: typePick,
        id: customer.customer_id,
        customer,
        logicalVaultCode: key
      }));

      return {
        label,
        nodeId,
        type: typeLogicalVault,
        id: customer.customer_id,
        customer,
        children,
        logicalVaultCode: key
      };
    });
  };

  const treeData = [
    {
      nodeId: pickingNodeId,
      label: t("workManagement.picking.label"),
      children: customerList?.map((customer, i) => ({
        nodeId: getNode(customer),
        label: getLable(customer),
        id: customer.customer_id,
        type: typeCustomer,
        icon: CustomerIcon,
        customer,
        children: getSecondLevelChildren(customer)
      }))
    }
  ];

  const renderTreeItems = (nodes) => {
    return nodes.map((node) => (
      <>
        <StyledTreeItem
          key={node.nodeId}
          itemId={node.nodeId}
          onClick={(e) => handleClick(e, node.id, node.nodeId)}
          slots={{
            collapseIcon: () => (
              <ExpandMoreIcon id={`collapse-icon-${node.nodeId}`} />
            ),
            expandIcon: () => (
              <ChevronRightIcon id={`expand-icon-${node.nodeId}`} />
            )
          }}
          label={
            <Box sx={WorkManagementTreeStyles.nodeLableContainer}>
              {pickingListLoaders.includes(node.id) &&
                node.type === typeCustomer && (
                  <CircularProgress
                    size={WorkManagementTreeStyles.loaderSize}
                  />
                )}
              {node?.type === typeCustomer && !node?.children?.length && (
                <ChevronRightIcon
                  sx={WorkManagementTreeStyles.chevronIcon}
                  id={`expand-icon-${node.nodeId}`}
                />
              )}
              {node.nodeId !== pickingNodeId && (
                <StyledCheckbox
                  checked={checked.includes(node.nodeId)}
                  onClick={(event) => event.stopPropagation()}
                  indeterminate={getCheckboxIntermediate(
                    node.type,
                    node.nodeId,
                    node.id,
                    node.logicalVaultCode
                  )}
                  onChange={(event) =>
                    handleToggle(
                      node.nodeId,
                      node.id,
                      node.type,
                      node.customer,
                      node.logicalVaultCode
                    )
                  }
                />
              )}
              {node.icon && (
                <SvgIcon
                  component={node.icon}
                  fontSize={WorkManagementTreeStyles.iconSize}
                  sx={WorkManagementTreeStyles.iconStyle}
                />
              )}
              {node.label}
            </Box>
          }
        >
          {node.children && renderTreeItems(node.children)}
        </StyledTreeItem>
      </>
    ));
  };

  return (
    <Box>
      <SimpleTreeView
        expandedItems={expanded}
        onItemExpansionToggle={handleExpand}
      >
        {renderTreeItems(treeData)}
      </SimpleTreeView>
    </Box>
  );
};

export default PickingTree;
