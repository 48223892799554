import { Stack } from "@mui/material";
import ServiceIndicator from "components/customer-information/preferences/service-indicator/ServiceIndicator";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const ServiceIndicatorScreen = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("preferences.detailsTitle")}`} />
        <ServiceIndicator />
      </Stack>
    </>
  );
};

export default ServiceIndicatorScreen;
