import { t } from "i18next";
export const missing = t("discrepancyUpdate.missing");
export const unexpected = t("discrepancyUpdate.unexpected");
export const noOrInvalidReturnDate = t(
  "discrepancyUpdate.noOrInvalidReturnDate"
);
export const incompleteStop = t("discrepancyUpdate.incompleteStop");
export const byPass = { key: "98", value: t("discrepancyUpdate.iMPersonnel") };
export const programId = "330";
export const TR_String = "TR";
export const number_67 = "67";
export const number_63 = "63";
export const number_73 = "73";
export const operationalModeId = "4";
export const formatWithSeconds = "YYYY-MM-DD HH:mm:ss:SSS";

export const searchCount500 = 500;
export const severityError = "error";
export const severityWarning = "warning";

export const suggestionTypes = Object.freeze({
  Operations_SuggestionTypes_CustReRun: t(
    "discrepancyManagement.atCustomerCreateReRun"
  ),
  Operations_SuggestionTypes_CustLeave: t(
    "discrepancyManagement.atCustomerLeave"
  ),
  Operations_SuggestionTypes_CustMS: t(
    "discrepancyManagement.atCustomerMisScan"
  ),
  Operations_SuggestionTypes_AutoResolv: t(
    "discrepancyManagement.autoResolved"
  ),
  Operations_SuggestionTypes_ReRun: t("discrepancyManagement.createReRun"),
  Operations_SuggestionTypes_SpStd: t(
    "discrepancyManagement.createSpecialStandard"
  ),
  Operations_SuggestionTypes_Delete: t("discrepancyManagement.deleteMedia"),
  Operations_SuggestionTypes_OrigRun: t(
    "discrepancyManagement.getMediaOnOriginalRun"
  ),
  Operations_SuggestionTypes_OnRun: t("discrepancyManagement.getMediaOnRun"),
  Operations_SuggestionTypes_KeepDelete: t(
    "discrepancyManagement.keepDeleteStatus"
  ),
  Operations_SuggestionTypes_KeepExist: t(
    "discrepancyManagement.keepExistingReturnDate"
  ),
  Operations_SuggestionTypes_MSVaultId: t(
    "discrepancyManagement.misScanVaultId"
  ),
  Operations_SuggestionTypes_MSVaultPD: t(
    "discrepancyManagement.misScanVaultPD"
  ),
  Operations_SuggestionTypes_MSVaultDt: t(
    "discrepancyManagement.misScanVaultDt"
  ),
  Operations_SuggestionTypes_PendingRes: t(
    "discrepancyManagement.pendingResolution"
  ),
  Operations_SuggestionTypes_VaultList: t(
    "discrepancyManagement.putInVaultListInfo"
  ),
  Operations_SuggestionTypes_VaultInd: t(
    "discrepancyManagement.putInVaultIndefiniteRetention"
  ),
  Operations_SuggestionTypes_VaultPD: t(
    "discrepancyManagement.putInVaultPendingDistribution"
  ),
  Operations_SuggestionTypes_VaultPDt: t(
    "discrepancyManagement.putInVaultPendingReturnDate"
  ),
  Operations_SuggestionTypes_VaultDt: t(
    "discrepancyManagement.putInVaultReturnDate"
  ),
  Operations_SuggestionTypes_VaultOMDt: t(
    "discrepancyManagement.putOpenMediaInVaultReturnDate"
  )
});

export const resolutionTypes = Object.freeze({
  Operations_SuggestionTypes_custrerun: t(
    "discrepancyManagement.atCustomerCreateReRun"
  ),
  Operations_SuggestionTypes_custleave: t(
    "discrepancyManagement.atCustomerLeave"
  ),
  Operations_SuggestionTypes_custms: t(
    "discrepancyManagement.atCustomerMisScan"
  ),
  Operations_SuggestionTypes_autoresolv: t(
    "discrepancyManagement.autoResolved"
  ),
  Operations_SuggestionTypes_rerun: t("discrepancyManagement.createReRun"),
  Operations_SuggestionTypes_spstd: t(
    "discrepancyManagement.createSpecialStandard"
  ),
  Operations_SuggestionTypes_delete: t("discrepancyManagement.deleteMedia"),
  Operations_SuggestionTypes_origrun: t(
    "discrepancyManagement.getMediaOnOriginalRun"
  ),
  Operations_SuggestionTypes_onrun: t("discrepancyManagement.getMediaOnRun"),
  Operations_SuggestionTypes_keepdelete: t(
    "discrepancyManagement.keepDeleteStatus"
  ),
  Operations_SuggestionTypes_keepexist: t(
    "discrepancyManagement.keepExistingReturnDate"
  ),
  Operations_SuggestionTypes_msvaultid: t(
    "discrepancyManagement.misScanVaultId"
  ),
  Operations_SuggestionTypes_msvaultpd: t(
    "discrepancyManagement.misScanVaultPD"
  ),
  Operations_SuggestionTypes_msvaultdt: t(
    "discrepancyManagement.misScanVaultDt"
  ),
  Operations_SuggestionTypes_pendingres: t(
    "discrepancyManagement.pendingResolution"
  ),
  Operations_SuggestionTypes_vaultlist: t(
    "discrepancyManagement.putInVaultListInfo"
  ),
  Operations_SuggestionTypes_vaultind: t(
    "discrepancyManagement.putInVaultIndefiniteRetention"
  ),
  Operations_SuggestionTypes_vaultpd: t(
    "discrepancyManagement.putInVaultPendingDistribution"
  ),
  Operations_SuggestionTypes_vaultpdt: t(
    "discrepancyManagement.putInVaultPendingReturnDate"
  ),
  Operations_SuggestionTypes_vaultdt: t(
    "discrepancyManagement.putInVaultReturnDate"
  ),

  Operations_SuggestionTypes_accept: t("discrepancyManagement.accept"),

  "Operations_SuggestionTypes_vault-ind": t(
    "discrepancyManagement.putInVaultIndefiniteRetention"
  ),
  "Operations_SuggestionTypes_Vault-Ind": t(
    "discrepancyManagement.putInVaultIndefiniteRetention"
  ),
  "Operations_SuggestionTypes_cust-leave": t(
    "discrepancyManagement.atCustomerLeave"
  ),
  "Operations_SuggestionTypes_cust-Leave": t(
    "discrepancyManagement.atCustomerLeave"
  ),
  "Operations_SuggestionTypes_cust-ms": t(
    "discrepancyManagement.atCustomerMisScan"
  ),
  "Operations_SuggestionTypes_cust-rerun": t(
    "discrepancyManagement.atCustomerCreateReRun"
  ),
  "Operations_SuggestionTypes_ms-vaultdt": t(
    "discrepancyManagement.misScanVaultDt"
  ),
  "Operations_SuggestionTypes_ms-vaultid": t(
    "discrepancyManagement.misScanVaultId"
  ),
  "Operations_SuggestionTypes_ms-vaultpd": t("discrepancyManagement.msvaultpd"),
  "Operations_SuggestionTypes_sp-std": t(
    "discrepancyManagement.createSpecialStandard"
  ),
  "Operations_SuggestionTypes_vault-dt": t(
    "discrepancyManagement.putInVaultReturnDate"
  ),
  "Operations_SuggestionTypes_vault-list": t(
    "discrepancyManagement.putInVaultListInfo"
  ),
  "Operations_SuggestionTypes_vault-omdt": t(
    "discrepancyManagement.putOpenMediaInVaultReturnDate"
  ),
  "Operations_SuggestionTypes_vault-pdt": t(
    "discrepancyManagement.putInVaultPendingReturnDate"
  )
});

export const printPdfLabel = {
  label_report_title: t("discrepancyUpdate.labelReportTitle"),
  label_customer_number_caption: t(
    "discrepancyUpdate.labelCustomerNumberCaption"
  ),
  label_media_number_caption: t("discrepancyUpdate.labelMediaNumberCaption"),
  label_branch_caption: t("discrepancyUpdate.labelBranchCaption"),
  label_discrepancy_type_caption: t(
    "discrepancyUpdate.labelDiscrepancyTypeCaption"
  ),
  label_detected_in_caption: t("discrepancyUpdate.labelDetectedInCaption"),
  label_service_date_caption: t("discrepancyUpdate.labelServiceDateCaption"),
  label_run_caption: t("discrepancyUpdate.labelRunCaption"),
  label_run_status_caption: t("discrepancyUpdate.labelRunStatusCaption"),
  label_pending_return_date_caption: t(
    "discrepancyUpdate.labelPendingReturnDateCaption"
  ),
  label_customer_media_identifier_caption: t(
    "discrepancyUpdate.labelCustomerMediaIdentifierCaption"
  ),
  label_additional_system_information_caption1: t(
    "discrepancyUpdate.labelAdditionalSystemInformationCaption1"
  ),
  label_media_number_caption1: t("discrepancyUpdate.labelMediaNumberCaption1"),
  label_resolved_caption: t("discrepancyUpdate.labelResolvedCaption"),
  label_resolution_information_caption1: t(
    "discrepancyUpdate.labelResolutionInformationCaption1"
  ),
  label_page: t("discrepancyUpdate.labelPage"),
  label_of: t("discrepancyUpdate.labelOf")
};
export const reportType = {
  o: "O",
  c: "C"
};

export const returnDate = "01/01/0001";
