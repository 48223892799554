import spacing from "styles/spacing";

const commonStyles = {
  widthFixed: { width: "310px" },
  textLabel: {
    "& .MuiInput-input.Mui-disabled": {
      "-webkit-text-fill-color": "rgba(0, 0, 0, 1.38)"
    },
    width: "210px"
  },
  widthFull: { width: "100%" },
  widthFit: { width: "fit-content" },
  alignCenter: { alignItems: "center" },
  pointerCursor: { cursor: "pointer" },
  heightBox: { height: "20px" },
  subHeading: { fontSize: "24px" },
  requestDesc: { fontSize: "14px" },
  requestType: { fontSize: "12px" },
  requestDescHelper: { fontSize: "14px", color: "var(--color-im-grey-400)" },
  hideArrows: {
    input: {
      "-moz-appearance": "textfield"
    },
    "input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  radioGroup: {
    gap: spacing.horizontalMargin47,
    paddingLeft: spacing.gap10
  },
  errorIcon: {
    width: "22px"
  }
};

const tableStyles = {
  tablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  bodyTitleStyle: {
    color: "var(--color-im-grey-400)"
  },
  displayFlex: {
    display: "flex"
  },
  textColor: {
    color: "var(--color-im-green)"
  },
  gapSpacing: "20px",
  directionRow: "row",
  directionColumn: "column",
  noBorder: {
    border: "none"
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    rowGap: spacing?.verticalMargin20
  },
  gridStyle: {
    display: "flex",
    flexDirection: "column"
  },
  buttons: { textTransform: "none" },
  buttonsSpace: { "white-space": "nowrap" },
  backButton: { paddingLeft: "5px" },
  hiddenButton: { display: "none" },
  inputTypeFile: { display: "none" },
  selectedCount: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.15px"
  }
};

const requestGeneratorStyles = {
  subHeading: {
    fontSize: "24px"
  },
  rightPanelDisplay: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr"
  },
  rightPanelWrapper: {
    flexDirection: "column"
  },
  treeWrapper: {
    height: "100%"
  },
  treePaper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  treeBoxHeight: {
    mx: "auto",
    height: 320
  },
  treeBoxCenter: {
    width: 100,
    alignSelf: "center",
    height: "120px",
    display: "flex",
    alignItems: "flex-end"
  },
  alertSize: { width: "20px" },

  subTitle: {
    color: "grey"
  },
  authenticatedText: {
    display: "flex",
    color: "var(--success-light, #4CAF50)",
    marginTop: 1
  },
  rightWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%"
  }
};

export const viewDrMediaStyles = {
  eventStatus: {
    opacity: "60%",
    fontSize: "12px"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  filterTitle: {
    fontSize: "16px",
    fontWeight: 400,
    color: "rgba(1,0,0,0.6)"
  },
  instructions: {
    fontSize: "14px",
    fontWeight: 400
  },
  cellStyle: {
    width: "200px",
    fontSize: "14px",
    fontWeight: 400
  },
  dataTableStyles: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          padding: "16px 24px !important"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "16px 24px !important"
        }
      }
    }
  },
  tableTitle: {
    fontSize: "24px",
    fontWeight: 400
  }
};

export const drPrintMediaStyles = {
  customerResultText: {
    fontSize: "14px",
    fontWeight: 400
  },
  alertBox: {
    marginBottom: spacing.horizontalMargin20,
    marginTop: spacing.horizontalMargin20
  },
  printModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "background.paper",
    borderRadius: "9px",
    boxShadow: 24
  },
  printModalBody: {
    padding: "16px 16px",
    border: "1px solid var(--color-im-grey-100)"
  },
  printModalTitle: {
    padding: "16px"
  },
  printModalButton: {
    margin: "1em"
  },
  printModalBranchField: {
    width: "300px"
  },
  alertIcon: {
    paddingLeft: "20px"
  }
};
const inboundDate = {
  noteText: {
    display: "inline-block"
  },
  tableContainer: {
    "& td": {
      padding: "0 16px !important"
    },
    "& th": {
      padding: "0 16px !important"
    }
  },
  actionColumn: {
    flexDirection: "row",
    width: "45px",
    alignItems: "center"
  },
  mainWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: "20px"
  }
};
const drpCode = {
  alertSize: { width: "20px" }
};
const manualEntry = {
  checkbox: {
    padding: "5px"
  },
  tableContainer: {
    width: "50vw",
    padding: "16px 0 0 16px",
    "& table:first-child ": {
      minHeight: "160px"
    },
    "& .MuiToolbar-gutters": {
      height: "auto !important",
      minHeight: "36px !important"
    },
    "& td": {
      padding: "0 16px !important",
      height: "auto !important"
    },
    "& th": {
      padding: "0 16px !important",
      height: "38px !important"
    }
  },
  instuctions: {
    padding: "0 16px 16px 32px",
    "& p": {
      fontSize: "14px"
    },
    "& li::marker": {
      fontSize: "12px !important"
    }
  },
  errorIcon: {
    width: "22px"
  }
};
export const disasterRecoveryStyles = {
  tableStyles,
  commonStyles,
  requestGeneratorStyles,
  inboundDate,
  manualEntry,
  drpCode
};
