import spacing from "styles/spacing";

const alertBox = {
  marginBottom: spacing.horizontalMargin20
};
const titleStyle = {
  color: "var(--color-im-grey-900)"
};
const dataTableStyles = {
  MUIDataTableHeadCell: {
    styleOverrides: {
      root: {
        padding: "16px 24px !important"
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "16px 24px !important"
      }
    }
  }
};
const formControlisActive = {
  display: "flex",
  alignItems: "center"
};

const errorStyle = {
  marginBottom: "20px"
};
const tableStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  gap: spacing.verticalMargin20
};
export const CustomerSearchStyles = {
  alertBox,
  titleStyle,
  dataTableStyles,
  formControlisActive,
  errorStyle,
  tableStyle
};
