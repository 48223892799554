import { t } from "i18next";
export const severityError = "error";
export const severityWarning = "warning";
export const searchCount500 = 500;

export const reportLable = {
  label_customer_number: t("discrepancyDetailReportLables.labelCustomerNumber"),
  label_media_number: t("discrepancyDetailReportLables.labelMediaNumber"),
  label_discrepancy_type: t(
    "discrepancyDetailReportLables.labelDiscrepancyType"
  ),
  label_logical_vault: t("discrepancyDetailReportLables.labelLogicalVault"),
  label_service_date: t("discrepancyDetailReportLables.labelServiceDate"),
  label_run: t("discrepancyDetailReportLables.labelRun"),
  label_resolved_date: t("discrepancyDetailReportLables.labelResolvedDate"),
  label_authorized_by: t("discrepancyDetailReportLables.labelAuthorizedBy"),
  label_last_known_location: t(
    "discrepancyDetailReportLables.labelLastKnownLocation"
  ),
  label_customer_name: t("discrepancyDetailReportLables.labelCustomerName"),
  label_media_type: t("discrepancyDetailReportLables.labelMediaType"),
  label_detected_date: t("discrepancyDetailReportLables.labelDetectedDate"),
  label_request_id: t("discrepancyDetailReportLables.labelRequestId"),
  label_pending_return_date: t(
    "discrepancyDetailReportLables.labelPendingReturnDate"
  ),
  label_resolved_by: t("discrepancyDetailReportLables.labelResolvedBy"),
  label_resolution: t("discrepancyDetailReportLables.labelResolution"),
  label_location: t("discrepancyDetailReportLables.labelLocation"),
  label_branch: t("discrepancyDetailReportLables.labelBranch"),
  label_card_id: t("discrepancyDetailReportLables.labelCardId"),
  label_detected_in: t("discrepancyDetailReportLables.labelDetectedIn"),
  label_courtesy_contact: t(
    "discrepancyDetailReportLables.labelCourtesyContact"
  ),
  label_display_to_customer_date: t(
    "discrepancyDetailReportLables.labelDisplayToCustomerDate"
  ),
  label_customer_media_identifier: t(
    "discrepancyDetailReportLables.labelCustomerMediaIdentifier"
  ),
  label_courtesy_contact_information: t(
    "discrepancyDetailReportLables.labelCourtesyContactInformation"
  ),
  label_person_contacted: t(
    "discrepancyDetailReportLables.labelPersonContacted"
  ),
  label_contact_date: t("discrepancyDetailReportLables.labelContactDate"),
  label_contacted_by: t("discrepancyDetailReportLables.labelContactedBy"),
  label_contact_method: t("discrepancyDetailReportLables.labelCustomerNumber"),
  label_bypass_reason: t("discrepancyDetailReportLables.labelBypassReason"),
  label_om_discrepancy_details_caption: t(
    "discrepancyDetailReportLables.labelOmDiscrepancyDetailsCaption"
  ),
  label_of: t("discrepancyDetailReportLables.labelOf"),
  label_page: t("discrepancyDetailReportLables.labelPage"),
  label_detected_datetime_caption1: t(
    "discrepancyDetailReportLables.labelDetectedDatetimeCaption1"
  ),
  label_system_function_caption1: t(
    "discrepancyDetailReportLables.labelSystemFunctionCaption1"
  ),
  label_discrepancy_type_caption1: t(
    "discrepancyDetailReportLables.labelDiscrepancyTypeCaption1"
  ),
  label_customer_comments: t(
    "discrepancyDetailReportLables.labelCustomerComments"
  ),
  label_container_upload_errors: t(
    "discrepancyDetailReportLables.labelContainerUploadErrors"
  ),
  label_run_caption3: t("discrepancyDetailReportLables.labelRunCaption3"),
  label_customer_caption3: t(
    "discrepancyDetailReportLables.labelCustomerCaption3"
  ),
  label_scanned_barcode_caption3: t(
    "discrepancyDetailReportLables.labelScannedBarcodeCaption3"
  ),
  label_pickup_returned_date_caption3: t(
    "discrepancyDetailReportLables.labelPickupReturnedDateCaption3"
  ),
  label_personnel_caption3: t(
    "discrepancyDetailReportLables.labelPersonnelCaption3"
  ),
  label_additional_system_comments: t(
    "discrepancyDetailReportLables.labelAdditionalSystemComments"
  ),
  label_report_title: t("discrepancyDetailReportLables.labelReportTitle"),
  label_print_date: t("discrepancyDetailReportLables.labelPrintDate"),
  label_print_by_name: t("discrepancyDetailReportLables.labelPrintByName"),
  label_report_name: t("discrepancyDetailReportLables.labelReportName")
};
