// ID's of the Error/Info/Warning Messages
export const MESSAGE_63075 = "63075";
export const MESSAGE_20355 = "20355";
export const MESSAGE_25170 = "25170";
export const MESSAGE_20464 = "20464";
export const MESSAGE_20535 = "20535";
export const MESSAGE_26022 = "26022";
export const MESSAGE_26059 = "26059";
export const MESSAGE_10187 = "10187";
export const MESSAGE_63872 = "63872";
export const MESSAGE_63944 = "63944";
export const MESSAGE_20349 = "20349";
export const MESSAGE_20528 = "20528";
export const MESSAGE_20529 = "20529";
export const MESSAGE_26060 = "26060";
export const MESSAGE_20222 = "20222";
export const MESSAGE_80105 = "80105";
export const MESSAGE_64028 = "64028";
export const MESSAGE_64032 = "64032";
export const MESSAGE_63673 = "63673";
export const MESSAGE_20452 = "20452";
export const MESSAGE_63674 = "63674";
export const MESSAGE_25334 = "25334";
export const MESSAGE_63857 = "63857";

export const NOT_APPLICABLE = "Not Applicable";
export const NO_LONGER_TIED_TO_REQUEST = "No longer tied to request";
export const COTAINER_EXHCANGE_STATUS_TYPE = Object.freeze({
  PICKUP: "Pickup"
});
export const SYSTEM_FUNCTION_ID_SAVE = "57";
export const SF_ASSIGN_CODE = "23";
export const SF_REMOVE_CODE = "32";
export const CUSTOMER_TYPE_D = "D";
export const AUTHORIZATION_BYPASS_PROGRAM_ID = 312;
export const MONTH_DATE_YEAR_FORMAT = "MM-DD-YYYY";
export const statusesToFilter = ["Authorized", "Pending Review", "In Process"];
