import { useTheme } from "@emotion/react";
import CheckIcon from "@mui/icons-material/Check";
import { Checkbox, ThemeProvider, createTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import WarningIcon from "assets/images/warning-2.svg";
import { requestModuleStyles } from "components/features/request-module/media-request-search/MediaRequestSearchModule.Styles";
import { NonEditableField } from "components/shared";
import i18n from "i18n";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useCancelMediaRequestMutation,
  useCheckCancelRequestMutation,
  useGetAuthorizationPersonnelsAuthenticationsMutation,
  useGetMessageByIdFromMessageMutation,
  useGetValidateChallengeResponseMutation
} from "services/api";
import {
  removeErrorMessage,
  selectMediaRequest,
  setErrorMessage
} from "store/slices";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS, REQUEST_ACTIONS } from "utils/constants";
import {
  AUTHORIZATION_BYPASS_PROGRAM_ID,
  MESSAGE_20355,
  MESSAGE_25170
} from "utils/constants/request-module/MediaRequestDetailConstants";
import {
  generateSHA256Hash,
  getAuthenticatedUserBranch,
  getLanguageId,
  validateUserHasProgramId
} from "utils/helpers";
import { MediaRequestDetailFormStyles } from "../media-request-detail-form/MediaRequestDetailForm.Styles";
import { CancelRequestModalStyles } from "./CancelRequestModal.Styles";

const dataTableOptions = {
  ...DEFAULT_DATA_TABLE_OPTIONS,
  responsive: "vertical",
  tableBodyHeight: "230px",
  tableBodyMaxHeight: "230px",
  setRowProps: (row) => ({
    style: {
      height: "32px"
    }
  }),
  textLabels: {
    body: {
      noMatch: i18n.t("mediaRequestDetail.noItemsToDisplay")
    }
  }
};

const CancelRequestModal = (props) => {
  const { actionId } = useSelector(selectMediaRequest);
  const dispatch = useDispatch();
  const globalTheme = useTheme();
  const searchDataTableTheme = createTheme(globalTheme, {
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            lineHeight: "30px"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            height: "54px"
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontSize: "13px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "0.17000000178813934px",
            textAlign: "left",
            height: "60px",
            paddingLeft: "16px",
            paddingRight: "16px",
            color: "rgba(0, 0, 0, 0.6) !important",
            backgroundColor: "var(--color-white-100)",
            textRendering: "optimizeLegibility"
          }
        }
      }
    }
  });
  const { t } = useTranslation();

  const [resetAutorizedBy, setResetAutorizedBy] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState("");

  //checkbox default check
  const CheckboxCell = ({ value }) => {
    const [checked, setChecked] = useState(value === "Y");

    const handleCheckboxChange = () => {
      setChecked(!checked);
    };

    return <Checkbox checked={checked} onChange={handleCheckboxChange} />;
  };

  // container grid column definition
  const containersColumnDefinition = [
    {
      name: "new_retention_flag",
      label: t("mediaRequestDetail.indefiniteRetentionFlagCaps"),
      options: {
        customBodyRender: (value) => <CheckboxCell value={value} />
      }
    },
    {
      name: "container_number",
      label: t("mediaRequestDetail.mediaNumberCaps")
    },
    {
      name: "media_type_descr",
      label: t("mediaRequestDetail.mediaTypeCaps")
    },
    {
      name: "new_service_date",
      label: t("mediaRequestDetail.serviceDateCaps")
    },
    {
      name: "comment",
      label: t("mediaRequestDetail.commentsCaps")
    }
  ];

  const [
    getAuthorizationPersonnelsData,
    {
      data: authroizedPersonnelsData = {},
      reset: resetAuthroizedPersonnelsData
    }
  ] = useGetAuthorizationPersonnelsAuthenticationsMutation(); // get authorized personnel's details
  const [
    triggerValidateChallengeResponse,
    { isLoading: validatingChallengeResponse }
  ] = useGetValidateChallengeResponseMutation(); //checking the challenge response
  const [
    checkCancelRequest,
    {
      data: requestMediaContainerTransports = [],
      isLoading: checkingCancelRequest,
      reset: resetRequestAttachedMediaContainerTransports
    }
  ] = useCheckCancelRequestMutation();
  const [cancelMediaRequest, { isLoading: cancellingMediaRequest }] =
    useCancelMediaRequestMutation();

  const [
    getMessageByIdFromMessage,
    { isError: isMessageByIdError, error: messageByIdError }
  ] = useGetMessageByIdFromMessageMutation();

  const [authorizationStatus, setAuthorizationStatus] = useState({
    authorizedBy: 0,
    bypassedBy: 0,
    authorizationType: t("mediaRequestDetail.personnel"),
    isAuthenticated: false,
    isByPassed: false,
    isError: false
  });

  const [authDetails, setAuthDetails] = useState({
    authNumber: "",
    challengeQuestion: "",
    challengeResponse: "",
    bypassReason: ""
  });

  // fetch selected authorized personnel's details
  useEffect(() => {
    if (authorizationStatus.authorizedBy > 0) {
      getAuthorizationPersonnelsData({
        personnelId: authorizationStatus?.authorizedBy
      })
        .then((resp) => resp.data)
        .then((resp) => {
          setAuthorizationStatus((prevState) => ({
            ...prevState,
            isAuthenticated: false,
            bypassedBy: t("mediaRequestDetail.imPersonnelCaps"),
            isError: false
          }));
          setAuthDetails((prevState) => ({
            ...prevState,
            authNumber: "",
            challengeQuestion: resp.secretQuestion,
            challengeResponse: "",
            bypassReason: ""
          }));
        });
    } else {
      resetAuthroizedPersonnelsData();
      resetRequestAttachedMediaContainerTransports();
      setAuthorizationStatus((prevState) => ({
        ...prevState,
        isAuthenticated: false,
        bypassedBy: t("mediaRequestDetail.imPersonnelCaps"),
        isError: false
      }));
      setAuthDetails((prevState) => ({
        ...prevState,
        authNumber: "",
        challengeQuestion: "",
        challengeResponse: "",
        bypassReason: ""
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationStatus?.authorizedBy]);

  // call the CF to cancel the request
  const handleCancelRequest = async () => {
    await cancelMediaRequest({
      requestId: props.requestId,
      cancelPersonnelId: authorizationStatus.authorizedBy,
      cancelBypassReason: authDetails.bypassReason,
      timestamp: props.timestamp
    }).then((resp) => {
      props.onSubmit();
    });
  };

  // check whether the request has container or errors when canceling
  const handleCheckCancelRequest = async () => {
    props.setCancelModalWarning(false);
    if (
      actionId === REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID ||
      actionId === REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID ||
      actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID ||
      actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID
    ) {
      const data = await checkCancelRequest(props.requestId).unwrap();
      if (Number(data[0].error) === 20355) {
        setShowTable(false);
        getMessageByIdFromMessage({ message_id: MESSAGE_20355 })
          .then((resp) => resp?.data[0])
          .then((resp) => {
            setAuthorizationStatus((prevState) => ({
              authorizedBy: 0,
              bypassedBy: 0,
              authorizationType: t("mediaRequestDetail.personnel"),
              isAuthenticated: false,
              isByPassed: false,
              isError: false
            }));
            setResetAutorizedBy((prevState) => !prevState);
            const replacedSentence = resp?.descr
              .replace("|", t("mediaRequestDetail.cancelRequest").toLowerCase())
              .replace("|", t("mediaRequestDetail.run"))
              .replace("|", t("mediaRequestDetail.greaterThanRunPrep"));
            setError((prevState) => replacedSentence);
          });
      } else if (Number(data[0].error) > 1) {
        setShowTable(false);
        getMessageByIdFromMessage({ message_id: MESSAGE_25170 })
          .then((resp) => resp?.data[0])
          .then((resp) => {
            setAuthorizationStatus((prevState) => ({
              authorizedBy: 0,
              bypassedBy: 0,
              authorizationType: t("mediaRequestDetail.personnel"),
              isAuthenticated: false,
              isByPassed: false,
              isError: false
            }));
            setResetAutorizedBy((prevState) => !prevState);
            setError((prevState) => resp?.descr);
          });
      } else if (Number(data[0].error) === 1) {
        setShowTable(false);
        setError((prevState) => "");
        await handleCancelRequest();
      } else if (data.length > 0 && Number(data[0].error) === 0) {
        setShowTable(true);
      }

      if (data.length === 0) {
        setShowTable(false);
        setError((prevState) => "");
        await handleCancelRequest();
      }
    } else {
      setShowTable(false);
      setError((prevState) => "");
      await handleCancelRequest();
    }
  };

  // handle request authroization event
  const handleAuthorization = async ({ target }) => {
    switch (target.id) {
      case "mediaRequestDetailAuthenticateButton": {
        //generate hash code
        let validateChallengeResponse;
        if (authDetails.challengeResponse?.length > 0) {
          let hashCode = await generateSHA256Hash(
            authDetails.challengeResponse.trim().toUpperCase()
          );

          //challenge response validation
          validateChallengeResponse = await triggerValidateChallengeResponse({
            main_district_id: getAuthenticatedUserBranch(),
            language_locale_id: getLanguageId(),
            personnel_id: authorizationStatus.authorizedBy,
            challenge_response: hashCode
          })
            .unwrap()
            .then((data) => {
              if (data[0]?.is_match_flag) {
                return data[0].is_match_flag;
              }
            });
        }

        if (
          authroizedPersonnelsData?.authNumber === authDetails?.authNumber ||
          validateChallengeResponse === "Y"
        ) {
          await handleCheckCancelRequest();
          setAuthorizationStatus((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            isByPassed: false,
            isError: false
          }));
        } else {
          setAuthorizationStatus((prevState) => ({
            ...prevState,
            isAuthenticated: false,
            isByPassed: false,
            isError: true
          }));
        }
        break;
      }
      case "mediaRequestDetailBypassButton":
        await handleCheckCancelRequest();
        setAuthorizationStatus((prevState) => ({
          ...prevState,
          authorizedBy: authorizationStatus?.authorizedBy,
          isAuthenticated: true,
          isByPassed: true,
          isError: false
        }));
        break;
      default:
        break;
    }
  };

  // cancel button click event handler
  const handleOnClick = (event) => {
    switch (event.target.id) {
      case "confirmExitModalCancel":
        props.onClose(false);
        props.setCancelModalWarning(false);
        break;
      case "mediaRequestDetailRejectButton":
        props.onClose(false);
        break;
      case "mediaRequestDetailAcceptButton":
        handleCancelRequest();
        break;
      default:
        break;
    }
  };

  // set error message in redux if one occurs
  useEffect(() => {
    let message = "";

    if (isMessageByIdError) {
      message = `${t("mediaRequestDetail.errorInMessageId")} ${
        messageByIdError.message
      }`;
    }
    // if message has set error message to redux, if not removed error message from redux
    if (message) {
      dispatch(setErrorMessage(message));
    } else {
      dispatch(removeErrorMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageByIdError]);

  return (
    <>
      <Modal
        open={props.show}
        aria-labelledby="cancel-request-modal"
        aria-describedby="cancel-request-modal-content"
        sx={CancelRequestModalStyles.modal}
      >
        <Card sx={CancelRequestModalStyles.card}>
          {/* ------------------ Title Section ------------------ */}
          <Typography
            variant="h6"
            sx={requestModuleStyles.mainTitle_PRSPopUP}
            marginBottom={"0"}
            gutterBottom
          >
            {t("mediaRequestDetail.cancelRequest")}
          </Typography>

          <Divider />

          {/* warning message */}
          {props.cancelModalWarning && (
            <Alert severity="warning" sx={CancelRequestModalStyles.alert}>
              {t("mediaRequestDetail.cancelMessageAlert")}
            </Alert>
          )}

          {error && (
            <Alert
              id="mediaRequestDetailErrorAlert"
              icon={<img src={WarningIcon} alt="Warning" />}
              severity="error"
              color="error"
              onClose={() => setError("")}
              sx={CancelRequestModalStyles.error}
            >
              {error}
            </Alert>
          )}

          {/* ------------------ Request Authorization ------------------ */}
          <CardContent>
            {/*Authorization title*/}
            <Typography
              id="typography3"
              color="primary"
              variant="h5"
              sx={CancelRequestModalStyles.authorizationTitle}
            >
              {t("mediaRequestDetail.personnelAuthentication")}
            </Typography>

            {/* Authorization Form Controls */}
            <Grid
              id="mediaRequestDetailGrid15"
              container
              spacing={spacing.verticalMargin20}
            >
              <Grid
                id="mediaRequestDetailGrid16"
                item
                container
                xs={12}
                columnSpacing={spacing.verticalMargin20}
              >
                {/* Authorized By field */}
                <Grid
                  id="mediaRequestDetailGrid17"
                  item
                  xs={10}
                  md={6}
                  lg={3.5}
                >
                  <Autocomplete
                    id="media_request_authorizedBy"
                    key={resetAutorizedBy}
                    size="medium"
                    options={props.personnels}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="authorizedBy"
                        label="Authorized By"
                        value={authorizationStatus.authorizedBy}
                      />
                    )}
                    onChange={(e, value) =>
                      setAuthorizationStatus((prevState) => ({
                        ...prevState,
                        authorizedBy: value?.value || 0
                      }))
                    }
                  />
                </Grid>

                {/* Request authorization status */}
                <Grid id="mediaRequestDetailGrid20" item xs={3.5}>
                  {/* Authenticated status */}
                  {authorizationStatus.isAuthenticated &&
                    !authorizationStatus.isByPassed && (
                      <Typography
                        id="mediaRequestDetailAuthenticationStatus"
                        variant="subtitle1"
                        color="success"
                        sx={MediaRequestDetailFormStyles.AuthenticatedText}
                      >
                        <CheckIcon id="icn" marginRight={"4px"} />
                        {t("mediaRequestDetail.authenticated")}
                      </Typography>
                    )}
                  {/* Bypassed status */}
                  {authorizationStatus.isAuthenticated &&
                    authorizationStatus.isByPassed && (
                      <Typography
                        id="mediaRequestDetailAuthenticationStatus"
                        variant="subtitle1"
                        color="success"
                        sx={MediaRequestDetailFormStyles.AuthenticatedText}
                      >
                        <CheckIcon id="icn" marginRight={"4px"} />
                        {t("mediaRequestDetail.bypassed")}
                      </Typography>
                    )}
                </Grid>
              </Grid>

              {authorizationStatus.authorizedBy > 0 &&
                !authorizationStatus.isAuthenticated && (
                  <>
                    {/* Authorization Type Selector*/}
                    <FormControl id="mediaRequestDetailAuthenticationTypeControl">
                      <RadioGroup
                        id="mediaRequestDetailAuthorizationType"
                        name="authorizationType"
                        sx={CancelRequestModalStyles.authorizationType}
                        row
                        aria-labelledby="Media-Request-Authorization-Type"
                        value={authorizationStatus.authorizationType}
                        onChange={(e) =>
                          setAuthorizationStatus((prevState) => ({
                            ...prevState,
                            authorizationType: e.target.value
                          }))
                        }
                      >
                        <FormControlLabel
                          id="mediaRequestDetailAuthorizationTypePersonnel"
                          value={t("mediaRequestDetail.personnel")}
                          control={<Radio color="secondary" />}
                          label={t("mediaRequestDetail.personnelAuth")}
                        />
                        <FormControlLabel
                          id="mediaRequestDetailAuthorizationTypeBypass"
                          value={t("mediaRequestDetail.bypass")}
                          disabled={
                            !validateUserHasProgramId(
                              AUTHORIZATION_BYPASS_PROGRAM_ID
                            )
                          }
                          control={<Radio color="secondary" />}
                          label={t("mediaRequestDetail.bypassCaps")}
                        />
                      </RadioGroup>
                    </FormControl>

                    {authorizationStatus.authorizationType ===
                    t("mediaRequestDetail.personnel") ? (
                      <>
                        {/* Personal Authentication Helper Text */}
                        <Grid id="mediaRequestDetailGrid21" item xs={12}>
                          <Typography
                            id="mediaRequestDetailAuthHelper"
                            variant="body2"
                          >
                            {t("mediaRequestDetail.personnelAuthHelper")}
                          </Typography>
                        </Grid>

                        {/* Auth Number field */}
                        <Grid
                          id="mediaRequestDetailGrid22"
                          item
                          xs={12}
                          md={6}
                          lg={3.5}
                        >
                          <TextField
                            id="mediaRequestDetailAuthNumber"
                            name="authNumber"
                            label={t("mediaRequestDetail.authNumber")}
                            disabled={Boolean(authDetails.challengeResponse)}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={authDetails.authNumber}
                            onChange={(e) =>
                              setAuthDetails((prevState) => ({
                                ...prevState,
                                authNumber: e.target.value
                              }))
                            }
                          />
                        </Grid>

                        {/* Authorization Challenge Question field */}
                        {Boolean(authDetails.challengeQuestion) && (
                          <Grid
                            id="mediaRequestDetailGrid23"
                            item
                            xs={12}
                            md={6}
                            lg={3.5}
                          >
                            <NonEditableField
                              id="mediaRequestDetailChallengeQuestion"
                              label={t("mediaRequestDetail.challengeQuestion")}
                              value={authDetails.challengeQuestion}
                            />
                          </Grid>
                        )}

                        {/* Authorization Challenge Response field */}
                        {Boolean(authDetails.challengeQuestion) && (
                          <Grid
                            id="mediaRequestDetailGrid24"
                            item
                            xs={12}
                            md={6}
                            lg={3.5}
                          >
                            <TextField
                              id="mediaRequestDetailChallengeResponse"
                              name="authorizationChallengeResponse"
                              type="password"
                              disabled={Boolean(authDetails.authNumber)}
                              label={t("mediaRequestDetail.challengeResponse")}
                              size="medium"
                              sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                              variant="outlined"
                              value={authDetails.challengeResponse}
                              onChange={(e) =>
                                setAuthDetails((prevState) => ({
                                  ...prevState,
                                  challengeResponse: e.target.value
                                }))
                              }
                            />
                          </Grid>
                        )}

                        {/* Authentication failed error message */}
                        {authorizationStatus.isError && (
                          <Grid id="mediaRequestDetailGrid26" item xs={12}>
                            <Alert
                              id="mediaRequestDetailAuthenticateErrorAlert"
                              severity="error"
                              color="error"
                              sx={{ borderRadius: "9px" }}
                            >
                              {t("mediaRequestDetail.authenticateFailed")}
                            </Alert>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <>
                        <Grid
                          id="mediaRequestDetailGrid27"
                          container
                          columnSpacing={spacing.verticalMargin20}
                          item
                          xs={12}
                        >
                          {/* Authorization Bypass Reason field */}
                          <Grid
                            id="mediaRequestDetailGrid28"
                            item
                            xs={12}
                            md={6}
                            lg={4.5}
                          >
                            <TextField
                              id="mediaRequestDetailBypassReason"
                              name="bypassReason"
                              label={t("mediaRequestDetail.reason")}
                              size="medium"
                              sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                              variant="outlined"
                              multiline
                              rows={2}
                              value={authDetails.bypassReason}
                              onChange={(e) =>
                                setAuthDetails((prevState) => ({
                                  ...prevState,
                                  bypassReason: e.target.value
                                }))
                              }
                            />
                          </Grid>

                          {/* Bypassed By field */}
                          <Grid
                            id="mediaRequestDetailGrid29"
                            item
                            xs={12}
                            md={6}
                            lg={3}
                          >
                            <Typography
                              id="mediaRequestDetailBypassedByLabel"
                              variant="subtitle1"
                              sx={MediaRequestDetailFormStyles.BypassedByText}
                            >
                              {t("mediaRequestDetail.bypassedBy")}
                            </Typography>
                            <Typography id="mediaRequestDetailBypassedBy">
                              {authorizationStatus.bypassedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
            </Grid>

            {/* Containers of the Request to be canceled */}
            {showTable && (
              <>
                {/*Request title*/}
                <Typography
                  id="typography3"
                  color="primary"
                  variant="h5"
                  sx={CancelRequestModalStyles.requestTitle}
                >
                  {t("mediaRequestDetail.request")}
                </Typography>

                <Grid
                  id="mediaRequestDetailGrid27"
                  container
                  columnSpacing={spacing.verticalMargin20}
                  paddingBottom={spacing.verticalMargin20}
                  item
                  xs={12}
                >
                  {/* Request Id */}
                  <Grid
                    id="mediaRequestDetailGrid29"
                    item
                    xs={12}
                    md={6}
                    lg={3}
                  >
                    <NonEditableField
                      id="mediaRequestDetailRequestId"
                      label={t("mediaRequestDetail.requestId")}
                      value={props.requestId}
                    />
                  </Grid>

                  {/* Request Type */}
                  <Grid
                    id="mediaRequestDetailGrid29"
                    item
                    xs={12}
                    md={6}
                    lg={3}
                  >
                    <NonEditableField
                      id="mediaRequestDetailRequestType"
                      label={t("mediaRequestDetail.requestType")}
                      value={props.requestType?.label}
                    />
                  </Grid>
                </Grid>

                {/* Medias attached to the request table */}
                {showTable && (
                  <ThemeProvider theme={searchDataTableTheme}>
                    <MUIDataTable
                      id="deliveryMediaTabContainerDataTable19"
                      data={requestMediaContainerTransports}
                      columns={containersColumnDefinition}
                      options={dataTableOptions}
                    />
                  </ThemeProvider>
                )}
              </>
            )}
          </CardContent>

          <Divider />

          <CardActions sx={requestModuleStyles.cardActionsStyle_PRSPopUP}>
            {((!authorizationStatus.isAuthenticated &&
              !authorizationStatus.isByPassed &&
              requestMediaContainerTransports.length === 0) ||
              ((authorizationStatus.isAuthenticated ||
                authorizationStatus.isByPassed) &&
                error !== "")) && (
              <>
                {/* Cancel Button */}
                <Button
                  id="confirmExitModalCancel"
                  sx={requestModuleStyles.buttonStyle_PRSPopUP}
                  variant="outlined"
                  type="button"
                  onClick={handleOnClick}
                >
                  {t("mediaRequestDetail.cancel")}
                </Button>
                {/* Authenticate or Bypass Button */}
                {authorizationStatus.authorizationType ===
                t("mediaRequestDetail.personnel") ? (
                  <Button
                    id="mediaRequestDetailAuthenticateButton"
                    sx={requestModuleStyles.buttonStyle_PRSPopUP}
                    variant="contained"
                    type="button"
                    onClick={handleAuthorization}
                  >
                    {(checkingCancelRequest ||
                      validatingChallengeResponse ||
                      cancellingMediaRequest) && (
                      <CircularProgress
                        sx={CancelRequestModalStyles.progressIcon}
                      />
                    )}
                    {t("mediaRequestDetail.authenticate")}
                  </Button>
                ) : (
                  <Button
                    id="mediaRequestDetailBypassButton"
                    sx={requestModuleStyles.buttonStyle_PRSPopUP}
                    disabled={!authDetails.bypassReason}
                    variant="contained"
                    type="button"
                    onClick={handleAuthorization}
                  >
                    {(checkingCancelRequest ||
                      validatingChallengeResponse ||
                      cancellingMediaRequest) && (
                      <CircularProgress
                        sx={CancelRequestModalStyles.progressIcon}
                      />
                    )}
                    {t("mediaRequestDetail.bypassLabel")}
                  </Button>
                )}
              </>
            )}

            {showTable && (
              <>
                {/* Reject Button */}
                <Button
                  id="mediaRequestDetailRejectButton"
                  sx={requestModuleStyles.buttonStyle_PRSPopUP}
                  variant="outlined"
                  type="button"
                  onClick={handleOnClick}
                >
                  {t("mediaRequestDetail.reject")}
                </Button>

                {/* Accept Button */}
                <Button
                  id="mediaRequestDetailAcceptButton"
                  sx={requestModuleStyles.buttonStyle_PRSPopUP}
                  variant="contained"
                  type="button"
                  onClick={handleOnClick}
                >
                  {cancellingMediaRequest && (
                    <CircularProgress
                      sx={CancelRequestModalStyles.progressIcon}
                    />
                  )}
                  {t("mediaRequestDetail.accept")}
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

CancelRequestModal.propTypes = {
  show: PropTypes.bool, // show/hide modal state
  authorizedBy: PropTypes.string, // id of the authorized personnel
  regularRateSpecial: PropTypes.bool, // regular rate special flag
  redelivery: PropTypes.bool, // redelivery  flag
  personnels: PropTypes.array, // personnels of the customer
  requestId: PropTypes.number.isRequired, // id of the request
  requestType: PropTypes.string.isRequired, // media request type
  timestamp: PropTypes.string.isRequired, // timestamp of the request
  onClose: PropTypes.func, // handle modal close event
  onSubmit: PropTypes.func // handle modal submit event
};

export default CancelRequestModal;
