/* Yup validation schemas goes here */

import i18n from "../../i18n";
import * as yup from "yup";

export const createDistributionModalFormSchema = yup.object().shape({
  mediaNumber: yup
    .string()
    .trim()
    .required(i18n.t("createDistributionList.mediaNumberRequiredError"))
    .max(20, "Media Number must be less than or equal to 20 characters"),
  returnDate: yup
    .date()
    .required(i18n.t("createDistributionList.returnDateRequiredError")),
  slot: yup
    .string()
    .matches(/^\d+$/, "Slot should contain only numbers")
    .required(i18n.t("createDistributionList.slotRequiredError"))
    .max(6, i18n.t("createDistributionList.slotValidationError")),
  drpCode: yup
    .string()
    .required(i18n.t("createDistributionList.drpCodeRequiredError"))
    .max(6, i18n.t("createDistributionList.drpCodeMaxLengthError")),
  description: yup
    .string()
    .required(i18n.t("createDistributionList.descriptionRequiredError"))
    .max(80, i18n.t("createDistributionList.descriptionMaxLengthError")),
});

export const createDistributionListFormSchema = yup.object().shape({
  reason: yup
    .string()
    .required(i18n.t("createDistributionList.reasonRequiredError")),
  logicalVault: yup
    .string()
    .required(i18n.t("createDistributionList.logicalVaultRequiredError")),
});
