import { getAllBranches, login } from "services/common";
import { TAG_TYPES } from "utils/constants";
import { baseApi } from "./baseApi";

/**
 * @module authApi authentication RTK Query endpoints
 */
export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBranches: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllBranches(props);
          return {
            data: response?.map(({ name, district_id }) => ({
              label: name ? name : "",
              value: district_id ? district_id : ""
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.branch]
    }),
    login: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await login(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    })
  })
});
