import { Button, Typography } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const RemoveCustomerModal = ({
  showRemoveCustomerModal,
  setShowRemoveCustomerModal,
  onRemoveCustomerYesClick
}) => {
  return (
    <CommonModal
      open={showRemoveCustomerModal}
      title={t("common.confirm")}
      body={
        <>
          <Typography>{t("personnelDetail.deleteMsgOne")}</Typography>
          <Typography>{t("personnelDetail.deleteMsgTwo")}</Typography>
        </>
      }
      buttons={
        <>
          <Button variant="outlined" onClick={() => onRemoveCustomerYesClick()}>
            {t("common.ok")}
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowRemoveCustomerModal(false)}
          >
            {t("common.cancel")}
          </Button>
        </>
      }
    />
  );
};

export default RemoveCustomerModal;
