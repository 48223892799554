import { CF_URLS } from "services/api/endpoints";
import {
  typeDelete,
  typeInsert,
  zero
} from "utils/constants/vault-configuration/VaultConfiguration";
import { getResponseData } from "utils/helpers";

const fetchData = async (
  url,
  districtId,
  params = {},
  extraParams = {},
  cb = null
) => {
  let reqBody = { main_district_id: districtId, ...params };
  let res = await getResponseData(reqBody, url, 2);

  if (cb && extraParams.nodeId) {
    cb(extraParams.nodeId);
  }

  return res;
};

export const getPhysicalVaultsService = async (districtId) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.listPhysicalVaultsInBranch,
    districtId
  );
};

export const getRowUnderPhysicalVaultService = async (
  districtId,
  vaultId,
  nodeId,
  cb
) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.listRowsInVaultOrBranch,
    districtId,
    { physical_vault_id: vaultId },
    { nodeId },
    cb
  );
};

export const getRackUnderRowService = async (
  districtId,
  vaultId,
  rowId,
  nodeId,
  cb
) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.listRacksInRowOrVault,
    districtId,
    { physical_vault_id: vaultId, row_id: rowId },
    { nodeId },
    cb
  );
};

export const getShelfUnderRackService = async (
  districtId,
  vaultId,
  rowId,
  rackId,
  nodeId,
  cb
) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.getallshelvesonrack,
    districtId,
    { physical_vault_id: vaultId, row_id: rowId, rack_id: rackId },
    { nodeId },
    cb
  );
};

export const getShelfPropertiesService = async (districtId, shelfId) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.shelfPropertiesList,
    districtId,
    { shelf_id: shelfId }
  );
};

export const getFormFactors = async (districtId, containerMediaFlag) => {
  return await fetchData(CF_URLS.vaultConfiguration.formFactors, districtId, {
    container_media_flag: containerMediaFlag
  });
};

export const getFormFactorsWithShelf = async (districtId, shelfId) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.formFactorsWithShelf,
    districtId,
    {
      shelf_id: shelfId
    }
  );
};

export const createPhysicalVaultService = async (
  districtId,
  physicalVaultNumber
) => {
  return await fetchData(CF_URLS.vaultConfiguration.physicalvault, districtId, {
    physical_vault_number: physicalVaultNumber
  });
};

export const createRowService = async (
  districtId,
  physicalVaultId,
  newRowNumber
) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.newrowcreation,
    districtId,
    {
      physical_vault_id: physicalVaultId,
      row_number: newRowNumber
    }
  );
};

export const createRackService = async (districtId, rowId, newRackNumber) => {
  return await fetchData(CF_URLS.vaultConfiguration.createnewrack, districtId, {
    row_id: rowId,
    rack_number: newRackNumber
  });
};

export const createShelfService = async (
  districtId,
  rackId,
  customerId,
  capacity,
  longTermFlag,
  containerMediaFlag,
  shelfNumber
) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.newshelfcreation,
    districtId,
    {
      shelf_number: shelfNumber,
      rack_id: rackId,
      customer_id: customerId || zero,
      capacity: capacity || zero,
      long_term_flag: longTermFlag,
      container_media_flag: containerMediaFlag
    }
  );
};

export const updateShelfService = async (
  districtId,
  shelfId,
  shelfNumber,
  customerId,
  capacity,
  longTermFlag,
  containerMediaFlag,
  timestamp
) => {
  return await fetchData(CF_URLS.vaultConfiguration.shelpupdation, districtId, {
    shelf_id: shelfId,
    shelf_number: shelfNumber,
    customer_id: customerId || zero,
    new_capacity: capacity,
    new_long_term_flag: longTermFlag,
    new_cont_media_flag: containerMediaFlag,
    shelf_timestamp: timestamp
  });
};

export const deleteVaultService = async (districtId, vaultId) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.deletePhysicalVault,
    districtId,
    {
      physical_vault_id: vaultId
    }
  );
};
export const deleteRowService = async (districtId, rowId) => {
  return await fetchData(CF_URLS.vaultConfiguration.deleteRow, districtId, {
    row_id: rowId
  });
};
export const deleteRackService = async (districtId, rackId) => {
  return await fetchData(CF_URLS.vaultConfiguration.deleteRack, districtId, {
    rack_id: rackId
  });
};
export const deleteShelfService = async (districtId, shelfId) => {
  return await fetchData(CF_URLS.vaultConfiguration.shelfDeletion, districtId, {
    shelf_id: shelfId
  });
};

export const releaseVaultService = async (
  districtId,
  vaultId,
  relseaseFormFactorFlag
) => {
  return await fetchData(CF_URLS.vaultConfiguration.releaseVault, districtId, {
    release_form_factors: relseaseFormFactorFlag,
    physical_vault_id: vaultId
  });
};

export const releaseRowService = async (
  districtId,
  rowId,
  relseaseFormFactorFlag
) => {
  return await fetchData(CF_URLS.vaultConfiguration.releaseRow, districtId, {
    release_form_factors: relseaseFormFactorFlag,
    row_id: rowId
  });
};
export const releaseRackService = async (
  districtId,
  rackId,
  relseaseFormFactorFlag
) => {
  return await fetchData(CF_URLS.vaultConfiguration.releaseRack, districtId, {
    release_form_factors: relseaseFormFactorFlag,
    rack_id: rackId
  });
};
export const releaseShelfService = async (
  districtId,
  shelfId,
  relseaseFormFactorFlag
) => {
  return await fetchData(CF_URLS.vaultConfiguration.releaseShelf, districtId, {
    release_form_factors: relseaseFormFactorFlag,
    shelf_id: shelfId
  });
};

export const handleFactorMoveService = async (
  districtId,
  shelfId,
  list,
  type
) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.insertDeleteFormFactorShelf,
    districtId,
    {
      shelf_id: shelfId,
      insert_form_factor_id_list: type === typeInsert ? list : "",
      delete_form_factor_id_list: type === typeDelete ? list : ""
    }
  );
};

export const checkAuditActiveService = async (districtId) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.checkAuditActive,
    districtId
  );
};

export const updatePhysicalVaultService = async (
  districtId,
  vaultId,
  vaultNumber,
  ts
) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.physicalVaultUpdation,
    districtId,
    {
      physical_vault_id: vaultId,
      physical_vault_number: vaultNumber,
      physical_vault_timestamp: ts
    }
  );
};

export const updateRowService = async (districtId, rowId, rowNumber, ts) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.existingRowUpdation,
    districtId,
    {
      row_id: rowId,
      row_number: rowNumber,
      row_timestamp: ts
    }
  );
};

export const updateRackService = async (districtId, rackId, rackNumber, ts) => {
  return await fetchData(
    CF_URLS.vaultConfiguration.existingRackUpdation,
    districtId,
    {
      rack_id: rackId,
      rack_number: rackNumber,
      rack_timestamp: ts
    }
  );
};
