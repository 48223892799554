import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DatePickerField } from "components/core";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { getAuditSampleMethod } from "services/api/query";
import {
  ERROR_TYPES,
  dayMintMonthYearFormat,
  maximumDate
} from "utils/constants";
import {
  getOptions,
  status2
} from "utils/constants/open-media-processing/OpenMediaAuditConstants";
import { findErrorMessage } from "utils/helpers";
import { OpenMediaAuditStyles } from "./OpenMediaAuditDetailStyles";

const today = dayjs();
//max date state
const maxDate = dayjs(maximumDate);

const Distribution = ({
  setIsLoading,
  setThrowError,
  setThrowErrorMessage,
  auditDetailForm,
  isViewMode,
  viewData,
  localeByBranch,
  allAuditData,
  directionData
}) => {
  const [sampleMethodList, setSampleMethodList] = useState([]);
  const [viewDataList, setViewDataList] = useState([]);
  const { values, setFieldValue } = auditDetailForm;

  useEffect(() => {
    if (allAuditData && allAuditData[3]?.length) {
      const arr = allAuditData[3].sort(
        (a, b) =>
          new Date(a.completed_datetime) - new Date(b.completed_datetime)
      );
      setViewDataList([...arr]);
    }
  }, [allAuditData]);

  useEffect(() => {
    setFieldValue("due_date", dayjs());
    handleGetSmpleData();
    //eslint-disable-next-line
  }, []);
  const handleGetSmpleData = async () => {
    setIsLoading(true);
    try {
      const dataSets = await getAuditSampleMethod();
      const list = getOptions(dataSets, "descr", "id");
      setSampleMethodList(list);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Grid item xs={4} sm={6} md={4} lg={3}>
        <FormControl fullWidth>
          <DatePickerField
            id="due-date"
            name="dueDate"
            label={t("openMediaAuditDetail.dueDate")}
            value={dayjs()}
            minDate={isViewMode ? today : values?.due_date}
            maxDate={maxDate}
            defaultValue={today}
            locale={localeByBranch}
            onChange={(value) => {}}
            disabled={true}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={5}>
        <FormControl fullWidth>
          <TextField
            labelId="comments"
            label={t("openMediaAuditDetail.comments")}
            value={values?.comment}
            onChange={(event) => {
              setFieldValue("comment", event.target.value);
            }}
            fullWidth
            multiline
            rows={4}
            name="review-comments"
            id="review-comments"
            disabled={status2?.includes(viewData?.audit_status)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={5}>
        <Typography style={OpenMediaAuditStyles.commentSectionText}>
          {t("openMediaAuditDetail.pastComments")}
        </Typography>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={OpenMediaAuditStyles.commentSection}
        >
          {allAuditData[1]?.length
            ? allAuditData[1]?.map((item) => {
                return (
                  <Typography marginBottom={2} key={item?.employee_name}>
                    {" "}
                    {dayjs(item?.comment_date_ltz).format(
                      dayMintMonthYearFormat
                    )}{" "}
                    {item?.employee_name}-{item?.comment}
                  </Typography>
                );
              })
            : ""}
        </Grid>
      </Grid>

      <Grid container spacing={2} lg={12} margin={0}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          marginTop={2}
          marginBottom={2}
        >
          <Divider />
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <>
            {isViewMode ? (
              <>
                <FormControl fullWidth>
                  <InputLabel id="sample-method">
                    {t("openMediaAuditDetail.sampleMethod")}
                  </InputLabel>
                  <Select
                    labelId="sample-method"
                    id="sample-method-id"
                    name="sampleMethod"
                    value={
                      values?.audit_sample_method_id?.name ||
                      sampleMethodList[0]?.label
                    }
                    label={t("openMediaAuditDetail.sampleMethod")}
                    onChange={(e) => {
                      setFieldValue("audit_sample_method_id", e.target.value);
                    }}
                  >
                    {sampleMethodList?.map((item) => {
                      return (
                        <MenuItem value={item.label} key={item.label}>
                          {item.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                {t("openMediaAuditDetail.sampleMethod")}
                <Typography>{viewData?.audit_sample_method_descr} </Typography>
              </>
            )}
          </>
        </Grid>

        <Grid item xs={4} sm={6} md={4} lg={3}>
          <Typography variant="caption">
            {t("openMediaAuditDetail.batchCount")}{" "}
          </Typography>
          <Typography>
            {viewDataList?.length || directionData?.media_count}{" "}
          </Typography>
        </Grid>
        {!isViewMode ? (
          <>
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Typography variant="caption">
                {t("openMediaAuditDetail.batchFrom")}{" "}
              </Typography>
              <Typography>
                {viewDataList[0]?.completed_datetime
                  ? dayjs(viewDataList[0]?.completed_datetime).format(
                      dayMintMonthYearFormat
                    )
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Typography variant="caption">
                {t("openMediaAuditDetail.batchTo")}{" "}
              </Typography>
              <Typography>
                {viewDataList[viewDataList?.length - 1]?.completed_datetime
                  ? dayjs(
                      viewDataList[viewDataList?.length - 1]?.completed_datetime
                    ).format(dayMintMonthYearFormat)
                  : ""}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Typography variant="caption">
                {t("openMediaAuditDetail.oldestBatch")}{" "}
              </Typography>
              <Typography>
                {dayjs(directionData?.oldest_batch_datetime).format(
                  dayMintMonthYearFormat
                )}{" "}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Typography variant="caption">
                {t("openMediaAuditDetail.distributionTasks")}{" "}
              </Typography>
              <Typography>-</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Distribution;
