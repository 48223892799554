import { Box } from "@mui/material";
import { PickersDay, pickersDayClasses } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { dayMonthYearFormat } from "utils/constants";

//handle date format change
export const dateFormat = (date) => {
  return dayjs(date).format(dayMonthYearFormat);
};

export const formattedDate = (date) => {
  return dayjs.utc(date).format(dayMonthYearFormat);
};

// Higlighting the Scheduled Service dates
export const HighlightScheduledDates = ({
  day,
  outsideCurrentMonth,
  selectedDate,
  hightlightedDates,
  ...params
}) => {
  const serviceDates = useMemo(
    () => hightlightedDates.map((item) => formattedDate(item.service_date)),
    [hightlightedDates]
  );
  const isSelected =
    !outsideCurrentMonth && serviceDates.includes(dateFormat(day));
  const bgColorScheduledSelectDate =
    dateFormat(day) === dateFormat(selectedDate) &&
    serviceDates.includes(dateFormat(day));
  const bgColorNonScheduledSelectDate =
    dateFormat(day) === dateFormat(selectedDate) && !isSelected;

  return (
    <Box
      sx={{
        backgroundColor: bgColorScheduledSelectDate && "#F7931E",
        borderRadius: "12px"
      }}
    >
      <PickersDay
        {...params}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: "#8DC13F"
          },
          [`&&.${pickersDayClasses.dayWithMargin}`]: {
            backgroundColor: bgColorNonScheduledSelectDate
              ? "#F7931E"
              : "normal",
            border: "none"
          }
        }}
      />
    </Box>
  );
};

HighlightScheduledDates.propTypes = {
  day: PropTypes.string, // date passed from the date-picker
  outsideCurrentMonth: PropTypes.bool, // passed from the date-picker
  selectedDate: PropTypes.string, // selected date
  hightlightedDates: PropTypes.array // dates need to highlighted
};
