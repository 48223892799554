import { Grid, TextField } from "@mui/material";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

const ReadOnlyFields = ({ textFields, customerSelected }) => {
  return (
    <>
      {textFields?.some((textField) => textField.value !== "") && (
        <Grid container spacing={2}>
          {textFields?.map((textField, index) => (
            <Grid item xs={3} key={index}>
              {textField.value !== "" && (
                <TextField
                  id={`readyOnly${index}`}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled={customerSelected === ""}
                  label={textField.label}
                  fullWidth
                  style={MDRVaultItemizedStyles.ReadOnlyFields_TF}
                  value={textField.value}
                  InputLabelProps={
                    MDRVaultItemizedStyles.ReadOnlyFields_InputLabelProps
                  }
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ReadOnlyFields;
