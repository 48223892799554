import { Box, Stack } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Title } from "components/core";
import { MediaRequestDetailForm } from "components/features/request-module";
import Typography from "components/shared/Typography";
import UnauthorizedPage from "components/unauthorizedComponent/UnauthorizedPage";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { ROLE_ACCESS_CODES } from "utils/constants";
import { validateUserHasProgramId } from "utils/helpers";
const MediaRequestDetail = () => {
  const { t } = useTranslation();
  const routeParams = useParams();
  const pageIsViewOnly = useMemo(() => Boolean(routeParams?.id), [routeParams]);

  return (
    <>
      {validateUserHasProgramId(ROLE_ACCESS_CODES.CODE_48) ? (
        <Stack spacing={spacing.verticalMargin20}>
          <Box
            id="mediaRequestDetailPageHeaderContainer"
            sx={MediaRequestDetailStyles.wrapper}
          >
            <Title
              id="mediaRequestDetailPageHeader"
              text={t("mediaRequestDetail.title")}
              variant="h4"
            />
            {pageIsViewOnly && (
              <Typography variant="h5" sx={MediaRequestDetailStyles.requestId}>
                {t("mediaRequestDetail.requestIdColon")} {routeParams?.id}
              </Typography>
            )}
          </Box>
          <MediaRequestDetailForm />
        </Stack>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
};

const MediaRequestDetailStyles = {
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: spacing.verticalMargin20
  },
  requestId: {
    color: "var(--chart-harmonious-blue-900, #1A325D)",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "29px",
    letterSpacing: "0.005em"
  }
};

export default MediaRequestDetail;
