/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ClosedIcon } from "assets/images/CloseIcon.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, SearchField } from "components/core";
import { Formik, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import {
  getEmployeeSearchDetails,
  getJobTitles,
  getOperationalCenters
} from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  corporateAccessLevelDistrictId,
  ERROR_TYPES,
  N_CHECKER,
  Y_CHECKER
} from "utils/constants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getSortedData
} from "utils/helpers";
import EmployeeSearchPrintModal from "./EmployeeSearchPrintModal";
import { EmployeeSearchStyles } from "./EmployeeSearchStyles";
import EmployeeSearchTable from "./EmployeeSearchTable";
import { employeeSearchFormSchema } from "./Schema";
const EmployeeSearchLayout = () => {
  const { t } = useTranslation();
  const districtId = getAuthenticatedUserBranch();
  const { currentBranch, auth } = useSelector(selectAuth);
  //form initial state
  const initialEmployeeSearch = {
    firstName: "",
    lastName: "",
    district: t("common.all"),
    jobTitle: t("common.all"),
    defaultOperationalCenter: t("common.all"),
    emergencyContact: false,
    printType: t("employeeSearch.printEmergencyContact")
  };

  //form initialize using useFormik
  const employeeSearchForm = useFormik({
    initialValues: initialEmployeeSearch,
    validationSchema: employeeSearchFormSchema
  });

  const [loading, setLoading] = useState(false);
  const [loadingJobTitle, setLoadingJobTitle] = useState(false);
  const [loadingOperationalCenter, setLoadingOperationalCenter] =
    useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [disableFind, setDisableFind] = useState(false);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [warningMessage, setWarningMessage] = useState(null);
  const [warningAlertPopup, setWarningAlertPopup] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [isVisiblePrint, setIsVisiblePrint] = useState(false);

  //dropdown states
  const [districts, setDistricts] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [operationalCenter, setOperationalCenter] = useState([]);

  //dropdown default states
  const [districtsDef, setDistrictsDef] = useState(ALL_SELECTION);
  const [jobTitlesDef, setJobTitlesDef] = useState(ALL_SELECTION);
  const [operationalCenterDef, setOperationalCenterDef] =
    useState(ALL_SELECTION);

  //handle change dropdown
  const handleDropdownChange = () => {
    setSearchResults([]);
    setLoading(false);
    setThrowError(false);
    setIsResetGrid(true);
    setDisableFind(false);
    setWarningAlertPopup(false);
    setWarningMessage(null);
  };

  //handle clear
  const handleReset = () => {
    employeeSearchForm.resetForm();
    handleDropdownChange();
    setDistrictsDef(ALL_SELECTION);
    setJobTitlesDef(ALL_SELECTION);
    setOperationalCenterDef(ALL_SELECTION);
    setLoadingJobTitle(false);
    setLoadingOperationalCenter(false);
  };

  //get districts
  const fetchDistricts = async () => {
    try {
      setLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: corporateAccessLevelDistrictId
      });
      const data = await getResponseData(
        reqBody,
        `${CF_URLS.employeeSearch.getLoadingValues}`,
        1
      );
      if (data?.data) {
        const districtArray = data?.data[0];
        const mappedDistrictsData = districtArray.map((res) => ({
          value: res.district_id,
          label: res.code.trim().toUpperCase()
        }));
        setDistricts(
          [].concat(
            [{ label: t("common.all") || "", value: t("common.all") || "" }],
            getSortedData(mappedDistrictsData, "label", "asc")
          )
        );
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };

  //get job titles
  const fetchJobTitles = async () => {
    try {
      setLoadingJobTitle(true);
      const result = await getJobTitles(districtId);
      //set job titles to state
      const mappedJobTitleData = result.map(({ job_title_id, title }) => ({
        label: title.trim(),
        value: job_title_id
      }));

      const sortedData = mappedJobTitleData.sort((a, b) => {
        const fieldA = a.label.toLowerCase();
        const fieldB = b.label.toLowerCase();
        if (fieldA < fieldB) {
          return -1;
        }
        if (fieldA > fieldB) {
          return 1;
        }
        return 0;
      });
      setJobTitles(
        [].concat(
          [{ label: t("common.all") || "", value: t("common.all") || "" }],
          sortedData
        )
      );
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoadingJobTitle(false);
    }
  };

  //get job titles
  const fetchOperationalCenters = async () => {
    try {
      setLoadingOperationalCenter(true);
      const result = await getOperationalCenters(districtId);
      //set operational center to state
      const mappedOperationalCenterData = result.map(
        ({ name, operational_center_id }) => ({
          label: name.trim(),
          value: operational_center_id
        })
      );
      const sortedData = mappedOperationalCenterData.sort((a, b) => {
        const fieldA = a.label.toLowerCase();
        const fieldB = b.label.toLowerCase();
        if (fieldA < fieldB) {
          return -1;
        }
        if (fieldA > fieldB) {
          return 1;
        }
        return 0;
      });
      setOperationalCenter(
        [].concat(
          [{ label: t("common.all") || "", value: t("common.all") || "" }],
          sortedData
        )
      );
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoadingOperationalCenter(false);
    }
  };

  //fetch table data
  const fetchTableData = async () => {
    try {
      setLoading(true);
      const emergencyContactFlag = employeeSearchForm.values.emergencyContact
        ? Y_CHECKER
        : N_CHECKER;
      const result = await getEmployeeSearchDetails();

      //filter data by select values
      const filterData = result.filter((emp) => {
        return (
          (employeeSearchForm.values.firstName.toLowerCase() === "" ||
            emp.first_name
              .toLowerCase()
              .includes(employeeSearchForm.values.firstName.toLowerCase())) &&
          (employeeSearchForm.values.lastName === "" ||
            emp.last_name
              .toLowerCase()
              .includes(employeeSearchForm.values.lastName.toLowerCase())) &&
          (employeeSearchForm.values.district === t("common.all") ||
            emp.district_id === employeeSearchForm.values.district) &&
          (employeeSearchForm.values.jobTitle === t("common.all") ||
            emp.job_title_id === employeeSearchForm.values.jobTitle) &&
          (employeeSearchForm.values.defaultOperationalCenter ===
            t("common.all") ||
            emp.operational_center_id ===
              employeeSearchForm.values.defaultOperationalCenter) &&
          (emergencyContactFlag === Y_CHECKER
            ? emp.emergency_contact_flag === emergencyContactFlag
            : emp.emergency_contact_flag === Y_CHECKER || N_CHECKER)
        );
      });

      //sort list by last name
      let sortByLastName = filterData.sort((a, b) => {
        const lastNameA = a.last_name.trim().toLowerCase();
        const lastNameB = b.last_name.trim().toLowerCase();
        if (lastNameA < lastNameB) return -1;
        if (lastNameA > lastNameB) return 1;
        return 0;
      });
      setSearchResults(sortByLastName);
      setDisableFind(true);
      setIsResetGrid(false);
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };
  //handle find
  const handleFind = async () => {
    await fetchTableData();
  };

  //handle security group change
  const handleDistrictChange = (event, value) => {
    handleDropdownChange();
    setDistrictsDef(value);
    employeeSearchForm.handleChange({
      target: { name: "district", value: value?.value }
    });
  };

  //handle job title change
  const handleJobTitleChange = (event, value) => {
    handleDropdownChange();
    setJobTitlesDef(value);
    employeeSearchForm.handleChange({
      target: { name: "jobTitle", value: value?.value }
    });
  };

  //handle operational center change
  const handleOperationalCenterChange = (event, value) => {
    handleDropdownChange();
    setOperationalCenterDef(value);
    employeeSearchForm.handleChange({
      target: { name: "defaultOperationalCenter", value: value?.value }
    });
  };

  //handle first name change
  const handleFirstName = (event, value) => {
    handleDropdownChange();
    employeeSearchForm.handleChange({
      target: { name: "firstName", value: event.target.value }
    });
  };
  //handle last name change
  const handleLastName = (event, value) => {
    handleDropdownChange();
    employeeSearchForm.handleChange({
      target: { name: "lastName", value: event.target.value }
    });
  };
  useEffect(() => {
    if (districtId) {
      fetchDistricts();
      fetchJobTitles();
      fetchOperationalCenters();
    }
  }, [districtId]);

  return (
    <>
      {/*Circular loader*/}
      <CircularLoaderFullPage loading={loading} />
      {/*error alert box*/}
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            sx={EmployeeSearchStyles.alertBox}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      {/*Warnning message popup*/}
      <Collapse in={warningAlertPopup}>
        <Alert
          severity="warning"
          icon={<img src={WarningIcon} alt="warning" />}
          sx={EmployeeSearchStyles.alertBox}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setWarningAlertPopup(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {warningMessage}
        </Alert>
      </Collapse>
      <Formik>
        <form>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={spacing.verticalMargin20}
          >
            <Grid container spacing={spacing.verticalMargin20}>
              {/*First Name field*/}
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <TextField
                  id="first-name"
                  name="firstName"
                  label={t("employeeSearch.firstName")}
                  size="medium"
                  variant="outlined"
                  value={employeeSearchForm.values.firstName}
                  onChange={handleFirstName}
                  error={Boolean(employeeSearchForm.errors.firstName)}
                  helperText={employeeSearchForm.errors.firstName}
                  fullWidth
                />
              </Grid>

              {/*Last Name field*/}
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <TextField
                  id="last-name"
                  name="lastName"
                  label={t("employeeSearch.lastName")}
                  size="medium"
                  variant="outlined"
                  value={employeeSearchForm.values.lastName}
                  onChange={handleLastName}
                  error={Boolean(employeeSearchForm.errors.lastName)}
                  helperText={employeeSearchForm.errors.lastName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                {/*District search field*/}
                <SearchField
                  label={t("employeeSearch.district")}
                  labelId="district-search"
                  options={districts}
                  clearIcon={<ClosedIcon />}
                  popupIcon={<ArrowDown />}
                  value={districtsDef}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={handleDistrictChange}
                  error={!!employeeSearchForm.errors.district}
                  helperText={employeeSearchForm.errors.district}
                  required
                />
              </Grid>

              {/*Job title search field*/}
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SearchField
                  label={t("employeeSearch.jobTitle")}
                  labelId="job-title-search"
                  options={jobTitles}
                  clearIcon={<ClosedIcon />}
                  popupIcon={<ArrowDown />}
                  value={jobTitlesDef}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={handleJobTitleChange}
                  error={!!employeeSearchForm.errors.securityGroup}
                  helperText={employeeSearchForm.errors.securityGroup}
                  loading={loadingJobTitle}
                  required
                />
              </Grid>

              {/*Default Operational Center search field*/}
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SearchField
                  label={t("employeeSearch.defaultOperationalCenter")}
                  labelId="default-operational-center-search"
                  options={operationalCenter}
                  clearIcon={<ClosedIcon />}
                  popupIcon={<ArrowDown />}
                  value={operationalCenterDef}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={handleOperationalCenterChange}
                  error={!!employeeSearchForm.errors.defaultOperationalCenter}
                  helperText={
                    employeeSearchForm.errors.defaultOperationalCenter
                  }
                  loading={loadingOperationalCenter}
                  required
                />
              </Grid>
              {/*Emergency Contact field*/}
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <FormControlLabel
                  id="emergencyContact"
                  control={
                    <Checkbox
                      id="emergencyContact"
                      size="small"
                      color="primary"
                      checked={employeeSearchForm.values.emergencyContact}
                      onChange={(event) => {
                        employeeSearchForm.setFieldValue(
                          "emergencyContact",
                          event.target.checked
                        );
                        handleDropdownChange();
                      }}
                    />
                  }
                  label={t("employeeSearch.emergencyContact")}
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={spacing.verticalMargin20}>
              {/*Clear button*/}

              <Button
                id="security-group-clear"
                variant="outlined"
                onClick={handleReset}
              >
                {t("common.clear")}
              </Button>
              {/*Find button*/}
              <Button
                id="security-group-find"
                variant="contained"
                type="button"
                onClick={handleFind}
                disabled={
                  disableFind ||
                  !employeeSearchForm.values.district ||
                  !employeeSearchForm.values.jobTitle ||
                  !employeeSearchForm.values.defaultOperationalCenter ||
                  Boolean(employeeSearchForm.errors.firstName) ||
                  Boolean(employeeSearchForm.errors.lastName)
                }
              >
                {t("common.find")}
              </Button>
            </Stack>

            <EmployeeSearchTable
              searchResults={searchResults}
              isResetGrid={isResetGrid}
              setIsVisiblePrint={setIsVisiblePrint}
              jobTitle={jobTitles}
              setWarningAlertPopup={setWarningAlertPopup}
              setWarningMessage={setWarningMessage}
              setLoading={setLoading}
              setThrowErrorMessage={setThrowErrorMessage}
              setThrowError={setThrowError}
            />
            <EmployeeSearchPrintModal
              employeeSearchForm={employeeSearchForm}
              isVisiblePrint={isVisiblePrint}
              setIsVisiblePrint={setIsVisiblePrint}
              setLoading={setLoading}
            />
          </Grid>
        </form>
      </Formik>
    </>
  );
};

export default EmployeeSearchLayout;
