import { ChevronLeft } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import InfoIcon from "assets/images/InfoOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useCallbackPrompt } from "hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  EVENT,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  N_CHECKER,
  REQUEST_ACTIONS,
  VALUE_EMPTY_STRING,
  errorMsgs,
  yearMonthDayFormat
} from "utils/constants";
import {
  buildType,
  instMsgCodes,
  serviceLocation
} from "utils/constants/disaster-module/EventSearchConstants";
import { generateOtherRequestSystemFunctionId } from "utils/constants/disaster-module/OtherRequest";
import { zero } from "utils/constants/disaster-module/logicalVaultRequest";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getAuthenticatedUserId,
  getAuthenticatedUserName,
  getDayOfWeekCode,
  getResponseData,
  getSortedData
} from "utils/helpers";
import * as Yup from "yup";
import CustomerList from "./CustomerList";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";
import DrFile from "./DrFile";
import InboundDateRequest from "./InboundDateRequest";
import LogicalVaultRequest from "./LogicalVaultRequest";
import RequestGeneratorService from "./RequestGeneratorService";
import DrpCode from "./drp-code/DrpCode";
import ManualEntry from "./manual-entry/ManualEntry";

const EventRequestGeneratorLayout = () => {
  const eventRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const routeParams = useParams();
  const eventId = routeParams?.id;
  const customerSelected = routeParams?.customerId;
  const { timeZoneOffset, currentBranch } = useSelector(selectAuth);

  const [isLoading, setIsLoading] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [ShowSuccessMessage, setShowSuccessMessage] = useState("");
  const [buildRequestMsg, setBuildRequestMsg] = useState("");
  const [requestCommentMsg, setRequestCommentMsg] = useState("");
  const [personnelList, setPersonnelList] = useState([]);
  const [customerLocations, setCustomerLocations] = useState([]);
  const [instMsgList, setInstMsgList] = useState([]);
  const [addOpenMedia, setAddOpenMedia] = useState([]);
  const [addContainer, setAddContainer] = useState([]);
  const [isClearSelectedEnable, setIsClearSelectedEnable] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    severity: "warning",
    message: VALUE_EMPTY_STRING
  });
  const [drpChanges, setDrpChanges] = useState(false);
  const [drpChangesModal, setDrpChangesModal] = useState(false);
  const [redirectScreen, setRedirectScreen] = useState("");
  const [message20259, setMessage20259] = useState("");
  const [message26105, setMessage26105] = useState("");
  const [message26106, setMessage26106] = useState("");
  const [message26109, setMessage26109] = useState("");
  const [haveChanges, setHaveChanges] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges);

  const currentDate = useMemo(() => {
    let date = dayjs.utc(new Date());
    if (timeZoneOffset !== null) {
      date = date.utcOffset(parseFloat(timeZoneOffset));
    }
    return date;
  }, [timeZoneOffset]);

  //dr file states start
  const [fileSelectNameId, setFileSelectNameId] = useState(null);
  const [infoText, setInfoText] = useState({ visible: false, message: "" });
  const [filesDetails, setFilesDetails] = useState(null);
  const [buttonsFieldsDisable, setButtonsFiledsDisable] = useState({
    fileSelectionNameField: true,
    creationTimeField: true,
    fileNameField: true,
    descriptionField: false,
    drpCodeField: false,
    applyFilterButton: true,
    clearButton: false,
    clearSelectedButton: false
  });
  const [omTableData, setOmTableData] = useState([]);
  const [customerListOpenMediaList, setCustomerListOpenMediaList] = useState(
    []
  );
  const [selectedOmDrFile, setSelectedOmDrFile] = useState([]);
  const [tableMaxLength, setTableMaxLength] = useState(null);
  const [drFileTableData, setDrFileTableData] = useState([]);
  const [showDrFileMediaTable, setShowDrFileMediaTable] = useState(false);
  const [confirmPopupMessage, setConfirmPopupMessage] = useState(null);
  const { localeByBranch } = useSelector(selectAuth);
  const filterError = useRef(false);
  const invalidCharachter = useRef({
    description: VALUE_EMPTY_STRING,
    drpCode: VALUE_EMPTY_STRING
  });
  //dr file states end

  //confirm modal states start
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [builtRequestTypeConfirmClick, setBuiltRequestTypeConfirmClick] =
    useState(null);
  //confirm modal states start end

  const initRequestState = {
    buildRequest: buildType.landingPage,
    serviceDate: currentDate,
    authorizedBy: {
      label: VALUE_EMPTY_STRING,
      value: VALUE_EMPTY_STRING
    },
    serviceLocation: serviceLocation.recoverySite,
    fileSelectionName: VALUE_EMPTY_STRING,
    customerList: VALUE_EMPTY_STRING,
    creationTime: VALUE_EMPTY_STRING,
    fileName: VALUE_EMPTY_STRING,
    description: VALUE_EMPTY_STRING,
    drpCode: VALUE_EMPTY_STRING,
    recoverySite: VALUE_EMPTY_STRING,
    customerLocation: VALUE_EMPTY_STRING,
    imComment: VALUE_EMPTY_STRING,
    customerComment: VALUE_EMPTY_STRING,
    byPassReason: VALUE_EMPTY_STRING,
    openMedia: VALUE_EMPTY_STRING,
    container: VALUE_EMPTY_STRING,
    hasLogicalVaultDataToAdd: false,
    hasInboundDateDataToAdd: false,
    hasManualEntryDateToAdd: false
  };

  // form validation schema
  const formValidationSchema = Yup.object().shape({
    serviceDate: Yup.date().required(
      message20259.replace(
        "|",
        t("disasterRecovery.eventDetail.serviceDateLabel")
      )
    ),
    authorizedBy: Yup.object().test(
      "personnel-required",
      message20259.replace("|", t("common.authorizedBy")),
      (personnel) =>
        personnel?.value !== undefined &&
        personnel?.value !== VALUE_EMPTY_STRING
    ),
    recoverySite: Yup.string().test(
      "recovery-site-required",
      message20259.replace(
        "|",
        t("disasterRecovery.eventDetail.recoverySiteLabel")
      ),
      (location, context) => {
        if (context.parent?.serviceLocation === serviceLocation.recoverySite) {
          return location !== undefined && location !== VALUE_EMPTY_STRING;
        }
        return true;
      }
    ),
    customerLocation: Yup.object().test(
      "service-location-required",
      message20259.replace(
        "|",
        t("disasterRecovery.eventDetail.customerLocationLabel")
      ),
      (location, context) => {
        if (
          context.parent?.serviceLocation === serviceLocation.customerLocation
        ) {
          return location !== undefined && location !== VALUE_EMPTY_STRING;
        }
        return true;
      }
    )
  });

  // form submit event handler
  const handleFormOnSubmit = async () => {
    if (
      RequestForm.values.openMedia === VALUE_EMPTY_STRING &&
      RequestForm.values.container === VALUE_EMPTY_STRING
    ) {
      setThrowErrorMessage(message26106);
      setThrowError(true);
    } else {
      setIsLoading((prevState) => true);
      const success = await generateRequest();
      setIsLoading((prevState) => false);
      if (success) {
        onGoBack();
        confirmNavigation();
      }
    }
  };

  const RequestForm = useFormik({
    initialValues: initRequestState,
    validationSchema: formValidationSchema,
    onSubmit: handleFormOnSubmit
  });

  //if the Page with view mode and change the  values the save button will enabled
  //const [formInitVal, setFormInitVal] = useState(initRequestState);

  // init
  useEffect(() => {
    if (routeParams?.id && routeParams?.customerId) {
      setIsLoading((prevState) => true);
      Promise.allSettled([
        fetchPersonnelList(),
        getCustomerLocations(customerSelected)
      ]).then((responses) => {
        setIsLoading((prevState) => false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams]);

  useEffect(() => {
    const fetchMsg = async () => {
      const buildReqMsg = await handleInstMessage(instMsgCodes.msgCode26083);
      setBuildRequestMsg((prev) => buildReqMsg);
      const requestMsg = await handleInstMessage(instMsgCodes.msgCode26086);
      setRequestCommentMsg((prev) => requestMsg);
      const instMsg1 = await handleInstMessage(instMsgCodes.msgCode26088);
      const instMsg2 = await handleInstMessage(instMsgCodes.msgCode26089);
      const instMsg3 = await handleInstMessage(instMsgCodes.msgCode26090);
      const instMsg4 = await handleInstMessage(instMsgCodes.msgCode26091);
      const instMsg5 = await handleInstMessage(instMsgCodes.msgCode26092);
      const instMsg6 = await handleInstMessage(instMsgCodes.msgCode26113);
      setInstMsgList((prev) => [
        instMsg1,
        instMsg2,
        instMsg3 + " " + instMsg4,
        instMsg5 + " " + instMsg6
      ]);
      const infoMsg26105 = await handleInstMessage(instMsgCodes.msgCode26105);
      setMessage26105(infoMsg26105);
      const errorMsg26106 = await handleInstMessage(errorMsgs.errorCode26106);
      setMessage26106(errorMsg26106);
      const errorMsg26109 = await handleInstMessage(errorMsgs.errorCode26109);
      setMessage26109(errorMsg26109);
      const errorMsg20259 = await handleInstMessage(errorMsgs.errorCode20259);
      setMessage20259(errorMsg20259);
    };
    fetchMsg();
    // eslint-disable-next-line
  }, []);

  //check open media is exists in added list or not
  const checkOpenMediaExist = (omObj) => {
    const checkFormValue = RequestForm.values.openMedia
      ?.split(" ")
      .filter((om) => om?.trim() !== "");
    if (checkFormValue.includes(omObj?.volser)) {
      return omObj;
    }
  };
  useEffect(() => {
    const selectedCustomerOpenMedia = addOpenMedia.filter(
      (openMedia) => openMedia.selected === true
    );
    const selectedContainer = addContainer.filter(
      (container) => container.selected === true
    );

    if (selectedCustomerOpenMedia.length > 0 || selectedContainer.length > 0) {
      setIsClearSelectedEnable(true);
    } else {
      setIsClearSelectedEnable(false);
    }

    if (selectedCustomerOpenMedia.length > 0) {
      let openMediaList = "";
      selectedCustomerOpenMedia.map((om) => {
        openMediaList = openMediaList + makeTwentyCharts(om.volser);
        return om;
      });
      RequestForm.setFieldValue("openMedia", openMediaList);
      setIsClearSelectedEnable(true);
      setShowGrid(true);
    } else if (!(selectedOmDrFile.length > 0)) {
      RequestForm.setFieldValue("openMedia", VALUE_EMPTY_STRING);
    }
    if (selectedContainer.length > 0) {
      let containerList = selectedContainer.reduce(
        (acc, next) =>
          acc + next.container_number.trim().toString().padEnd(15, " "),
        VALUE_EMPTY_STRING
      );
      RequestForm.setFieldValue("container", containerList);
    } else {
      RequestForm.setFieldValue("container", VALUE_EMPTY_STRING);
    }
    // eslint-disable-next-line
  }, [addOpenMedia, addContainer]);

  //add DR file section selected open medias to the form
  const addDrFileOpenMediasToForm = () => {
    let openMediaList = "";
    //check whether there is added open media in the selected list if yes remove them and create a new array
    const exist = selectedOmDrFile.map((om) => checkOpenMediaExist(om));
    const clearUndefined = exist.filter((om) => om !== undefined);
    const uniqueOm1 = selectedOmDrFile.filter(
      (om1) => !clearUndefined.some((om2) => om1?.volser === om2?.volser)
    );
    const uniqueOm2 = clearUndefined.filter(
      (om2) => !selectedOmDrFile.some((om1) => om2?.volser === om1?.volser)
    );
    const removeExistingOms = [...uniqueOm1, ...uniqueOm2];
    if (removeExistingOms.length > 0) {
      setAddOpenMedia((pre) => [...pre, ...removeExistingOms]);
      removeExistingOms.map((om) => {
        openMediaList = openMediaList + makeTwentyCharts(om.volser);
        return om;
      });
      RequestForm.setFieldValue(
        "openMedia",
        RequestForm.values.openMedia + openMediaList
      );
    }
  };
  const makeTwentyCharts = (item) => {
    if (item.length < 20) {
      return item + " ".repeat(20 - item.length);
    } else {
      return item;
    }
  };

  // form values changed
  useEffect(() => {
    setHaveChanges(
      (prevState) =>
        !(
          RequestForm.values?.serviceDate === initRequestState.serviceDate &&
          RequestForm.values?.authorizedBy === initRequestState.authorizedBy &&
          RequestForm.values?.serviceLocation ===
            initRequestState.serviceLocation &&
          RequestForm.values?.recoverySite === initRequestState.recoverySite &&
          RequestForm.values?.customerLocation ===
            initRequestState.customerLocation &&
          RequestForm.values?.imComment === initRequestState.imComment &&
          RequestForm.values?.customerComment ===
            initRequestState.customerComment &&
          RequestForm.values?.byPassReason === initRequestState.byPassReason &&
          RequestForm.values?.openMedia === initRequestState.openMedia &&
          RequestForm.values?.container === initRequestState.container
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequestForm.values]);

  const fetchPersonnelList = async () => {
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        customer_id: customerSelected,
        select_all_personnel_flag: ""
      });
      /* To do - Use this api endpoint once backend fixed the SQL DB issue.
          const data = await getResponseData(
            reqBody,
            `${CF_URLS.disasterRecovery.getPersonnelList}`,
            1
          );*/
      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getAuthorizedby}`,
        1
      );
      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(({ personnel_id, name }) => ({
            label: name,
            value: personnel_id
          }));

          setPersonnelList((prev) => getSortedData(mapping, "label", "asc"));
        }
      } else if (data?.error) {
        setPersonnelList((prev) => []);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setPersonnelList((prev) => []);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const handleInstMessage = async (messageId, replaceValue) => {
    setIsLoading(true);
    let msg = await getMessageFromCode(messageId)
      .then((data) => {
        if (data.length > 0 && data[0].descr.includes("|") && replaceValue) {
          return data[0].descr.replace("|", replaceValue);
        } else if (data.length > 0) {
          return data[0].descr;
        }
      })
      .catch((error) => {
        setThrowError(true);
        setThrowErrorMessage(error);
        return;
      });
    setIsLoading(false);
    return msg;
  };

  const getCustomerLocations = async (customerId) => {
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        customer_id: customerId
      });
      const data = await getResponseData(
        reqBody,
        `${CF_URLS?.disasterRecovery.getCustomerLocations}`,
        1
      );
      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(({ number, customer_id }) => ({
            label: number?.trim(),
            value: customer_id
          }));
          setCustomerLocations((prev) =>
            getSortedData(mapping, "label", "asc")
          );
        }
      } else if (data?.error) {
        setCustomerLocations((prev) => []);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(error);
    }
  };

  const handleBuildRequest = async (event) => {
    //if blocks works only when navigate from dr file section without adding open media to the form
    if (drpChanges) {
      setDrpChangesModal(true);
      setRedirectScreen(event.target.value);
    } else {
      if (
        (RequestForm.values.fileSelectionName !== VALUE_EMPTY_STRING &&
          event.target.value !== buildType.drFile) ||
        (RequestForm.values.customerList !== VALUE_EMPTY_STRING &&
          event.target.value !== buildType.customerList) ||
        (RequestForm.values.hasLogicalVaultDataToAdd &&
          event.target.value !== buildType.logicalVault) ||
        (RequestForm.values.hasInboundDateDataToAdd &&
          event.target.value !== buildType.inboundDate) ||
        (RequestForm.values.hasManualEntryDateToAdd &&
          event.target.value !== buildType.manualEntryImport)
      ) {
        const message = await getMessageFromCode(
          String(errorMsgs.errorCode26084)
        );
        setConfirmPopupMessage(message[0]?.descr);
        setConfirmPopup(true);
        setBuiltRequestTypeConfirmClick(event.target.value);
      } else {
        RequestForm.handleChange({
          target: { name: "buildRequest", value: event.target.value }
        });
        resetMessages();
      }
    }
  };

  const resetMessages = () => {
    setThrowError(false);
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setInfoText({ visible: false, message: "" });
    setAlert({
      show: false,
      severity: "warning",
      message: VALUE_EMPTY_STRING
    });
    setShowSuccess(false);
    setShowSuccessMessage("");
  };

  const requestServiceProps = {
    requestCommentMsg,
    RequestForm,
    personnelList,
    customerLocations,
    instMsgList,
    addOpenMedia,
    setAddOpenMedia,
    addContainer,
    setAddContainer,
    message26105,
    setShowGrid,
    showGrid
  };

  const handelClearSelected = () => {
    const updatedOpenMediaList = addOpenMedia.map((openMedia) => {
      openMedia.selected = false;
      return openMedia;
    });
    setAddOpenMedia(updatedOpenMediaList);
    setAddContainer(
      addContainer.map((c) => ({
        ...c,
        selected: false
      }))
    );
  };
  // generate request
  const generateRequest = async () => {
    try {
      const requestBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        customer_id: customerSelected,
        day_of_week_code: getDayOfWeekCode(RequestForm.values.serviceDate),
        service_date: RequestForm.values.serviceDate.format(yearMonthDayFormat),
        employee_id: getAuthenticatedUserId(),
        employee_comment: RequestForm.values.imComment,
        personnel_id: RequestForm.values.authorizedBy.value,
        personnel_comment: VALUE_EMPTY_STRING,
        csl_customer_id:
          RequestForm.values.serviceLocation === serviceLocation.recoverySite
            ? customerSelected
            : RequestForm.values.customerLocation?.value,
        system_function_id: generateOtherRequestSystemFunctionId,
        open_media_list: RequestForm.values.openMedia,
        container_list: RequestForm.values.container,
        last_mod_user: getAuthenticatedUserName(),
        dr_event_id: eventId,
        dr_site_name:
          RequestForm.values?.serviceLocation === zero
            ? RequestForm.values?.recoverySite
            : "",
        request_action_id: REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID.toString(),
        comment: VALUE_EMPTY_STRING
      });
      const response = await getResponseData(
        requestBody,
        CF_URLS.disasterRecovery.createRequest,
        1
      );
      const error = response.error;
      if (error) {
        const messageResponse = await getMessageFromCode(error);
        setAlert((prevState) => ({
          show: true,
          severity: "warning",
          message: messageResponse[0]?.descr
        }));
        window.scrollTo({ top: 0 });
        return false;
      }

      setHaveChanges((prevState) => false);
      return true;
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        message: findErrorMessage(ERROR_TYPES.ISSUE),
        severity: "error"
      }));
      window.scrollTo({ top: 0 });
      return false;
    }
  };

  // save changes confirm modal YES click event handler
  const handleSaveChangesOnYesClick = async () => {
    cancelNavigation();
    window.scrollTo({ top: 0 });
    RequestForm.submitForm();
  };

  // save changes confirm modal NO click event handler
  const handleSaveChangesOnNoClick = () => {
    confirmNavigation();
  };

  const handleGenerateRequest = () => {
    if (!showGrid) {
      setShowGrid(true);
    } else {
      window.scrollTo({ top: 0 });
      RequestForm.submitForm();
    }
  };

  const onGoBack = () => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}/${eventId}`,
      {
        state: location?.state
      }
    );
  };

  let { buildRequest: buildRequestOption } = RequestForm.values;

  //Dr file functions
  //get table data
  const getOmDrForFileSelection = async (fileSelectionId) => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        customer_file_section_id: fileSelectionId,
        descr: RequestForm.values.description,
        drp_code: RequestForm.values.drpCode
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getOmDrForFileSelection}`,
        2
      );
      if (data?.data) {
        const response = data?.data;
        if (response[0]?.length > 0) {
          if (tableMaxLength < response[0]?.length) {
            const message = await getMessageFromCode(
              String(errorMsgs.errorCode26084)
            );
            setConfirmPopupMessage(message[0]?.descr);
            setConfirmPopup(true);
            setDrFileTableData(response[0]);
          } else {
            setShowDrFileMediaTable(true);
            const sortTableData = getSortedData(response[0], "volser", "asc");
            setOmTableData(sortTableData);
          }
        }
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  //validate decsription/drp code filed
  const validateFiled = async () => {
    const invalidChars = [`"`, `'`];
    const foundInvalidCharDescription = invalidChars.find((char) =>
      RequestForm.values.description.includes(char)
    );
    const foundInvalidCharDrp = invalidChars.find((char) =>
      RequestForm.values.drpCode.includes(char)
    );

    if (foundInvalidCharDescription) {
      const message = await getMessageFromCode(
        String(errorMsgs.errorCode25087)
      );
      if (message) {
        const replacedSentence = message[0]?.descr.replace(
          "|",
          foundInvalidCharDescription
        );
        filterError.current = true;

        invalidCharachter.current = {
          ...invalidCharachter.current,
          description: replacedSentence
        };
      }
    }
    if (foundInvalidCharDrp) {
      const message = await getMessageFromCode(
        String(errorMsgs.errorCode25087)
      );
      if (message) {
        const replacedSentence = message[0]?.descr.replace(
          "|",
          foundInvalidCharDrp
        );
        filterError.current = true;

        invalidCharachter.current = {
          ...invalidCharachter.current,
          drpCode: replacedSentence
        };
      }
    }
  };

  //dr file props
  const drFileProps = {
    customerSelected,
    RequestForm,
    initRequestState,
    localeByBranch,
    filesDetails,
    buttonsFieldsDisable,
    omTableData,
    showDrFileMediaTable,
    selectedOmDrFile,
    invalidCharachter,
    filterError,
    validateFiled,
    setAddOpenMedia,
    addDrFileOpenMediasToForm,
    makeTwentyCharts,
    setTableMaxLength,
    setDrFileTableData,
    setSelectedOmDrFile,
    setBuiltRequestTypeConfirmClick,
    setShowDrFileMediaTable,
    setOmTableData,
    setButtonsFiledsDisable,
    setFilesDetails,
    setFileSelectNameId,
    setInfoText,
    setThrowErrorMessage,
    setThrowError,
    setConfirmPopupMessage,
    setConfirmPopup,
    getOmDrForFileSelection
  };

  const addSelectedToRequest = () => {
    if (
      RequestForm.values.hasLogicalVaultDataToAdd ||
      RequestForm.values.hasInboundDateDataToAdd ||
      RequestForm.values.hasManualEntryDateToAdd
    ) {
      setAddOpenMedia(RequestForm.values.openMedia || []);
      setAddContainer(RequestForm.values.container || []);
      setConfirmPopup(false);
      RequestForm.handleChange({
        target: { name: "buildRequest", value: builtRequestTypeConfirmClick }
      });
      RequestForm.setFieldValue("hasInboundDateDataToAdd", false);
      RequestForm.setFieldValue("hasLogicalVaultDataToAdd", false);
      RequestForm.setFieldValue("hasManualEntryDateToAdd", false);
    }
  };

  /*DR File screen confim modal handle change functions start*/
  //handle confirm yes
  const handleConfirmModalYes = () => {
    addSelectedToRequest();
    if (builtRequestTypeConfirmClick !== buildType.drFile) {
      if (builtRequestTypeConfirmClick !== buildType.customerList) {
        setConfirmPopup(false);
        addAllSelectedCustomerListOpenMedia();
        addDrFileOpenMediasToForm();
        RequestForm.handleChange({
          target: { name: "buildRequest", value: builtRequestTypeConfirmClick }
        });
      }
      setConfirmPopup(false);
      addDrFileOpenMediasToForm();
      addAllSelectedCustomerListOpenMedia();
      RequestForm.handleChange({
        target: { name: "buildRequest", value: builtRequestTypeConfirmClick }
      });
      resetMessages();
    } else {
      RequestForm.setFieldValue("fileSelectionName", fileSelectNameId);
      //set creation time , file name fields
      const selectedFileDetails = filesDetails.filter(
        (val) => val?.customer_file_section_id === fileSelectNameId
      );
      setButtonsFiledsDisable((pre) => ({
        ...pre,
        creationTimeField: false,
        fileNameField: false
      }));
      RequestForm.setFieldValue(
        "creationTime",
        dateTimeFormatByLocale(
          selectedFileDetails[0]?.created_datetime,
          localeByBranch
        )
      );
      RequestForm.setFieldValue("fileName", selectedFileDetails[0]?.file_name);
      addDrFileOpenMediasToForm();
      setConfirmPopup(false);
      getOmDrForFileSelection(fileSelectNameId);
    }
  };

  //handle confirm no
  const handleConfirmModalNo = async () => {
    RequestForm.setFieldValue("hasInboundDateDataToAdd", false);
    RequestForm.setFieldValue("hasLogicalVaultDataToAdd", false);
    RequestForm.setFieldValue("hasManualEntryDateToAdd", false);
    if (builtRequestTypeConfirmClick !== buildType.drFile) {
      if (builtRequestTypeConfirmClick !== buildType.customerList) {
        RequestForm.setFieldValue("customerList", VALUE_EMPTY_STRING);
        RequestForm.handleChange({
          target: { name: "buildRequest", value: builtRequestTypeConfirmClick }
        });
        setConfirmPopup(false);
        resetMessages();
      }
      RequestForm.setFieldValue("fileSelectionName", VALUE_EMPTY_STRING);
      setConfirmPopup(false);
      RequestForm.handleChange({
        target: { name: "buildRequest", value: builtRequestTypeConfirmClick }
      });
    } else if (builtRequestTypeConfirmClick === buildType.drFile) {
      await validateFiled();
      setConfirmPopup(false);
      RequestForm.setFieldValue("fileSelectionName", fileSelectNameId);
      //set creation time , file name fields
      const selectedFileDetails = filesDetails.filter(
        (val) => val?.customer_file_section_id === fileSelectNameId
      );
      setButtonsFiledsDisable((pre) => ({
        ...pre,
        creationTimeField: false,
        fileNameField: false
      }));
      RequestForm.setFieldValue(
        "creationTime",
        dateTimeFormatByLocale(
          selectedFileDetails[0]?.created_datetime,
          localeByBranch
        )
      );
      RequestForm.setFieldValue("fileName", selectedFileDetails[0]?.file_name);
      await getOmDrForFileSelection(fileSelectNameId);
    }

    //if DR file open media table data is greater than max length
    if (tableMaxLength < drFileTableData.length) {
      const unselectOpenMedia = drFileTableData.map((row) => ({
        ...row,
        is_selected_flag: N_CHECKER
      }));
      setOmTableData(unselectOpenMedia);

      RequestForm.setFieldValue(
        "fileSelectionName",
        RequestForm.values.fileSelectionName
      );
      //set creation time , file name fields
      const selectedFileDetails = filesDetails.filter(
        (val) =>
          val?.customer_file_section_id === RequestForm.values.fileSelectionName
      );
      setButtonsFiledsDisable((pre) => ({
        ...pre,
        creationTimeField: false,
        fileNameField: false
      }));
      RequestForm.setFieldValue(
        "creationTime",
        dateTimeFormatByLocale(
          selectedFileDetails[0]?.created_datetime,
          localeByBranch
        )
      );
      RequestForm.setFieldValue("fileName", selectedFileDetails[0]?.file_name);
      setShowDrFileMediaTable(true);
    }
  };

  //handle confirm cancel
  const handleConfirmModalCancel = () => {
    setConfirmPopup(false);
    setBuiltRequestTypeConfirmClick(null);
  };
  /*DR File screen confim modal handle change functions end*/

  const addAllSelectedCustomerListOpenMedia = () => {
    const selectedCustomerOpenMedia = customerListOpenMediaList.filter(
      (openMedia) => openMedia.selected === true
    );
    selectedCustomerOpenMedia.map((selectedMedia) => {
      let existingOpenMedia = addOpenMedia.filter(
        (openMedia) => openMedia.volser === selectedMedia.volser
      );
      if (existingOpenMedia.length === 0) {
        selectedMedia.selected = true;
        setAddOpenMedia((prev) => [...prev, selectedMedia]);
      }
      return selectedMedia;
    });
    setCustomerListOpenMediaList([]);
    RequestForm.setFieldValue("customerList", VALUE_EMPTY_STRING);
  };

  const handleDrpChanges = async () => {
    eventRef.current.handelAddRequest();
    if (
      (RequestForm.values.fileSelectionName !== VALUE_EMPTY_STRING &&
        redirectScreen !== buildType.drFile) ||
      (RequestForm.values.customerList !== VALUE_EMPTY_STRING &&
        redirectScreen !== buildType.customerList)
    ) {
      const message = await getMessageFromCode(
        String(errorMsgs.errorCode26084)
      );
      setConfirmPopupMessage(message[0]?.descr);
      setConfirmPopup(true);
      setBuiltRequestTypeConfirmClick(redirectScreen);
    } else {
      RequestForm.handleChange({
        target: { name: "buildRequest", value: redirectScreen }
      });
    }
    setDrpChangesModal(false);
    setDrpChanges(false);
  };
  /*Get local functions end */
  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {showSuccess && (
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setShowSuccess(false);
                setShowSuccessMessage("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {ShowSuccessMessage}
        </Alert>
      )}

      {alert.show && (
        <Collapse in={alert.show}>
          <Alert
            severity={alert.severity}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setAlert((prevState) => ({
                    show: false,
                    severity: "warning",
                    message: VALUE_EMPTY_STRING
                  }));
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alert.message}
          </Alert>
        </Collapse>
      )}
      {/*Info Alert*/}
      <Collapse in={infoText.visible}>
        <Alert
          severity="info"
          icon={<img src={InfoIcon} alt="info" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setInfoText((pre) => ({ ...pre, visible: false }));
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {infoText.message}
        </Alert>
      </Collapse>

      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Box sx={disasterRecoveryStyles.requestGeneratorStyles.rightPanelDisplay}>
        <Stack
          gap={spacing.gap}
          sx={disasterRecoveryStyles.requestGeneratorStyles.treeWrapper}
        >
          <Paper
            sx={disasterRecoveryStyles.requestGeneratorStyles.treePaper}
            mr={3}
            mb={3}
          >
            <Box>
              <Box sx={{ p: 2, pb: 0 }}>
                <Typography
                  sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
                >
                  {t("disasterRecovery.eventDetail.deliveryRequest")}
                </Typography>
                <Box sx={{ py: 2 }}>
                  {buildRequestMsg}
                  {/* Radio Selection */}
                  <Box sx={{ p: 1, pb: 0 }}>
                    <FormControl>
                      <RadioGroup
                        name="service-radio-buttons-group"
                        value={RequestForm.values.buildRequest}
                        onChange={handleBuildRequest}
                      >
                        <FormControlLabel
                          value={buildType.customerList}
                          control={<Radio />}
                          label={t("disasterRecovery.eventDetail.customerList")}
                        />
                        <FormControlLabel
                          value={buildType.drFile}
                          control={<Radio />}
                          label={t("disasterRecovery.eventDetail.drFile")}
                        />
                        <FormControlLabel
                          value={buildType.DRPCode}
                          control={<Radio />}
                          label={t("disasterRecovery.eventDetail.DRPCode")}
                        />
                        <FormControlLabel
                          value={buildType.inboundDate}
                          control={<Radio />}
                          label={t("disasterRecovery.eventDetail.inboundDate")}
                        />
                        <FormControlLabel
                          value={buildType.logicalVault}
                          control={<Radio />}
                          label={t("disasterRecovery.eventDetail.logicalVault")}
                        />
                        <FormControlLabel
                          value={buildType.manualEntryImport}
                          control={<Radio />}
                          label={t(
                            "disasterRecovery.eventDetail.manualEntryImport"
                          )}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                <Typography
                  sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
                >
                  {t("disasterRecovery.eventDetail.summary")}
                </Typography>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("disasterRecovery.eventDetail.rgOpenMedia")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("disasterRecovery.eventDetail.containers")}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {addOpenMedia.length}
                    </Grid>
                    <Grid item>{addContainer.length}</Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              sx={disasterRecoveryStyles.requestGeneratorStyles.treeBoxCenter}
              pb={2}
            >
              <Button
                variant="outlined"
                id="cancel-request"
                style={disasterRecoveryStyles.tableStyles.backButton}
                onClick={onGoBack}
              >
                <ChevronLeft /> {t("common.back")}
              </Button>
            </Box>
          </Paper>
        </Stack>
        <Box sx={disasterRecoveryStyles.tableStyles.gridStyle} mb={2}>
          {buildRequestOption === buildType.landingPage && (
            <RequestGeneratorService {...requestServiceProps} />
          )}
          {buildRequestOption === buildType.manualEntryImport && (
            <ManualEntry
              {...requestServiceProps}
              setAddOpenMedia={setAddOpenMedia}
              addOpenMedia={addOpenMedia}
              RequestForm={RequestForm}
              setAddContainer={setAddContainer}
              addContainer={addContainer}
            />
          )}

          {RequestForm.values.buildRequest === buildType.DRPCode && (
            <DrpCode
              customerSelected={customerSelected}
              setAddOpenMedia={setAddOpenMedia}
              setAddContainer={setAddContainer}
              RequestForm={RequestForm}
              addOpenMedia={addOpenMedia}
              setDrpChanges={setDrpChanges}
              eventRef={eventRef}
            />
          )}
          {buildRequestOption === buildType.customerList && (
            <CustomerList
              customerSelected={customerSelected}
              setAddOpenMedia={setAddOpenMedia}
              addOpenMedia={addOpenMedia}
              RequestForm={RequestForm}
              setOpenMediaList={setCustomerListOpenMediaList}
              openMediaList={customerListOpenMediaList}
            />
          )}
          {buildRequestOption === buildType.inboundDate ? (
            <InboundDateRequest
              customerSelected={customerSelected}
              setAddOpenMedia={setAddOpenMedia}
              setAddContainer={setAddContainer}
              RequestForm={RequestForm}
            />
          ) : null}
          {buildRequestOption === buildType.logicalVault ? (
            <LogicalVaultRequest
              customerSelected={customerSelected}
              setAddOpenMedia={setAddOpenMedia}
              setAddContainer={setAddContainer}
              RequestForm={RequestForm}
            />
          ) : null}
          {RequestForm.values.buildRequest === buildType.drFile ? (
            <DrFile {...drFileProps} />
          ) : null}
          {buildRequestOption === buildType.landingPage && (
            <Box
              sx={disasterRecoveryStyles.tableStyles.displayFlex}
              gap={spacing.gap}
              ml={spacing.gap}
              mt={spacing.gap}
            >
              <Button
                variant="outlined"
                id="cancel-request"
                style={
                  buildRequestOption === buildType.landingPage
                    ? disasterRecoveryStyles.tableStyles.buttons
                    : disasterRecoveryStyles.tableStyles.hiddenButton
                }
                onClick={onGoBack}
              >
                {t("common.cancel")}
              </Button>
              {buildRequestOption === buildType.landingPage &&
                addOpenMedia.length > 0 && (
                  <Button
                    variant="outlined"
                    id="clear-selection"
                    style={disasterRecoveryStyles.tableStyles.buttonsSpace}
                    disabled={!isClearSelectedEnable}
                    onClick={handelClearSelected}
                  >
                    {t("disasterRecovery.eventDetail.clearSelected")}
                  </Button>
                )}
              <Button
                variant="contained"
                id="review-generate-request"
                style={
                  buildRequestOption === buildType.landingPage
                    ? disasterRecoveryStyles.tableStyles.buttons
                    : disasterRecoveryStyles.tableStyles.hiddenButton
                }
                type="submit"
                onClick={handleGenerateRequest}
              >
                {!showGrid
                  ? t("disasterRecovery.eventDetail.reviewGenerateRequest")
                  : t("disasterRecovery.eventDetail.generateRequestButton")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {/* Confirm Save Changes Modal */}
      <ConfirmPopup
        modalPopupOpen={showPrompt}
        message={message26109}
        handleYes={handleSaveChangesOnYesClick}
        showNo={true}
        handleNo={handleSaveChangesOnNoClick}
        showCancel={false}
      />

      {/*Confirm popup Dr file*/}
      <ConfirmPopup
        message={confirmPopupMessage}
        modalPopupOpen={confirmPopup}
        handleYes={() => handleConfirmModalYes()}
        handleNo={() => handleConfirmModalNo()}
        handleCancel={() => handleConfirmModalCancel()}
        showNo={true}
        showCancel={true}
      />
      <ConfirmPopup
        message={t("disasterRecovery.eventDetail.drpCode.drpChangesErr")}
        modalPopupOpen={drpChangesModal}
        handleYes={() => handleDrpChanges()}
        handleNo={async () => {
          if (
            (RequestForm.values.fileSelectionName !== VALUE_EMPTY_STRING &&
              redirectScreen !== buildType.drFile) ||
            (RequestForm.values.customerList !== VALUE_EMPTY_STRING &&
              redirectScreen !== buildType.customerList)
          ) {
            const message = await getMessageFromCode(
              String(errorMsgs.errorCode26084)
            );
            setConfirmPopupMessage(message[0]?.descr);
            setConfirmPopup(true);
            setBuiltRequestTypeConfirmClick(redirectScreen);
          } else {
            RequestForm.handleChange({
              target: { name: "buildRequest", value: redirectScreen }
            });
          }
          setDrpChangesModal(false);
          setDrpChanges(false);
        }}
        handleCancel={() => setDrpChangesModal()}
        showNo={true}
        showCancel={true}
      />
    </>
  );
};

export default EventRequestGeneratorLayout;
