import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { TAG_TYPES } from "utils/constants";
/**
 * @module baseApi base api configuration for RTK Query
 */
export const baseApi = createApi({
  reducerPath: "api", // Optional if the reducer path is "api"
  baseQuery: fakeBaseQuery(),
  tagTypes: [Object.values(TAG_TYPES).toString()], // caching keys for the requests
  endpoints: (builder) => ({}),
});
