import { Box, Button, Grid } from "@mui/material";
import DataTable from "components/core/data-table/DataTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import {
  COMPLETE,
  DEFAULT_DATA_TABLE_OPTIONS,
  FAILED,
  IN_PROGRESS,
  NOT_COMPLETED
} from "utils/constants";
import CustomerMoveDetailRunPopup from "./CustomerMoveDetailRunPopup";
import { CustomerMoveStyles } from "./CustomerMoveDetailStyles";
import DayOfMovePopup from "./DayOfMovePopup";
import DownloadReportModal from "./DownloadReportModal";

const CustomerDetailTable = ({
  processTableData = [],
  setIsLoading,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage,
  customerMoveEventId,
  destinationDistrictId,
  destinationBranchDistrict,
  handleRowClick,
  gridRowData,
  customerMoveProcessId,
  runBtnEnabled,
  setOpenDayOfMove,
  openDayOfMove,
  handleRunBtnClick,
  setOpenRunPopup,
  openRunPopup,
  selectedRadioValue,
  setSelectedRadioValue,
  handleDayOfMoveRunBtnClick,
  copyMediaTransactionMsg,
  handleYesMoveTransactionBtnClick,
  handleRefreshBtnClick,
  isDistributionListCreated,
  setGridRowData,
  setRunBtnEnabled
}) => {
  const { t } = useTranslation();

  const [showPrintModal, setShowPrintModal] = useState(false);

  const noRecord = processTableData?.length === 0;

  const getRowStyle = (row, dataIndex) => {
    return {
      style: {
        backgroundColor:
          row[1] === COMPLETE
            ? CustomerMoveStyles.completeColorStatus
            : !isNaN(gridRowData) &&
                gridRowData === dataIndex &&
                (row[1] === NOT_COMPLETED ||
                  row[1] === FAILED ||
                  row[1] === IN_PROGRESS)
              ? CustomerMoveStyles.rowSelectedColorStatus
              : "inherit"
      }
    };
  };
  const columns = [
    {
      name: "process_name",
      label: t("customerMoveDetail.process"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={CustomerMoveStyles.customHeader}>
            {columnMeta.label}
          </span>
        )
      }
    },
    {
      name: "process_status",
      label: t("customerMoveDetail.status"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={CustomerMoveStyles.customHeader}>
            {columnMeta.label}
          </span>
        )
      }
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: false,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={!processTableData.length}
            onClick={handleRefreshBtnClick}
          >
            {t("customerMoveDetail.refresh")}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={!(parseInt(customerMoveProcessId) > 1)}
            onClick={() => setShowPrintModal(true)}
          >
            {t("customerMoveDetail.reconcilliationReport")}
          </Button>

          <Button
            variant="contained"
            size="medium"
            type="button"
            disabled={!processTableData.length || !runBtnEnabled}
            onClick={handleRunBtnClick}
          >
            {t("customerMoveDetail.run")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    },
    onRowClick: handleRowClick,
    setRowProps: (row, dataIndex) => {
      return getRowStyle(row, dataIndex);
    }
  };
  return (
    <>
      <Box sx={CustomerMoveStyles.muiDataTableStyles}>
        <DataTable
          options={options}
          noRecord={noRecord}
          data={processTableData.length > 0 ? processTableData : []}
          columns={columns}
          showTitle={false}
        />
      </Box>
      {gridRowData === 2 && openRunPopup && (
        <CustomerMoveDetailRunPopup
          setOpenRunPopup={setOpenRunPopup}
          openRunPopup={openRunPopup}
          copyMediaTransactionMsg={copyMediaTransactionMsg}
          handleYesMoveTransactionBtnClick={handleYesMoveTransactionBtnClick}
          setGridRowData={setGridRowData}
          setRunBtnEnabled={setRunBtnEnabled}
        />
      )}
      {gridRowData === 1 && openDayOfMove && (
        <DayOfMovePopup
          openDayOfMove={openDayOfMove}
          setOpenDayOfMove={setOpenDayOfMove}
          selectedRadioValue={selectedRadioValue}
          setSelectedRadioValue={setSelectedRadioValue}
          handleDayOfMoveRunBtnClick={handleDayOfMoveRunBtnClick}
          isDistributionListCreated={isDistributionListCreated}
          setGridRowData={setGridRowData}
          setRunBtnEnabled={setRunBtnEnabled}
        />
      )}
      {showPrintModal && (
        <DownloadReportModal
          showPrintModal={showPrintModal}
          setShowPrintModal={setShowPrintModal}
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          customerMoveEventId={customerMoveEventId}
          destinationDistrictId={destinationDistrictId}
          setThrowErrorMessage={setThrowErrorMessage}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default CustomerDetailTable;
