import { createSlice } from "@reduxjs/toolkit";

// slice initial state
const initialState = {
  haveChanges: false,
  showConfirmationModal: false
};

/**
 * @name routeSlice
 */
export const routeSlice = createSlice({
  name: "routeSlice",
  initialState,
  reducers: {
    // reset state to default
    resetRouteState: () => {
      return initialState;
    },
    setHaveChanges: (state, { payload }) => {
      state.haveChanges = payload;
    },
    setShowConfirmationModal: (state, { payload }) => {
      state.showConfirmationModal = payload;
    },
    cancelConfirmation: (state) => {
      state.showConfirmationModal = false;
    }
  }
});

export default routeSlice.reducer;
