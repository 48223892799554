import { Stack } from "@mui/material";
import DontCallResolveDesc from "components/customer-information/preferences/DontCallResolveDesc";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const DontCallDesc = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("preferences.detailsTitle")}`} />
        <DontCallResolveDesc />
      </Stack>
    </>
  );
};

export default DontCallDesc;
