import ModalPopup from "components/core/modal-components/ModalPopup";

const WarningModalCommon = (props) => {
  return (
    <ModalPopup
      title={props.title}
      modalPopupOpen={props.modalPopupOpen}
      handleClick={props.handleClick}
      alertMessage={props.alertMessage}
      modalButton={props.modalButton}
    />
  );
};

export default WarningModalCommon;
