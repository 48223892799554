import { Button, Grid } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  dayMonthYearFormat
} from "utils/constants";

const UnexpectedMediaDetail = ({ tableData, handlePossibleMatch }) => {
  const [tableDataList, setTableData] = useState([]);

  useEffect(() => {
    if (tableData?.length) {
      let sortingData = tableData.sort(
        (a, b) =>
          a?.scanned_location.split("-").join("") -
          b?.scanned_location.split("-").join("")
      );
      setTableData([...sortingData]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS
  };
  const columns = [
    {
      name: "scanned_barcode",
      label: t("openMediaAuditDetail.scannedBarcode")
    },
    {
      name: "customer_number",
      label: t("openMediaAuditDetail.customer")
    },
    {
      name: "scanned_location",
      label: t("openMediaAuditDetail.scannedLocation")
    },
    {
      name: "logical_vault_code",
      label: t("openMediaAuditDetail.logicalVault")
    },
    {
      name: "Follow Up",
      label: t("openMediaAuditDetail.followUp"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let index = tableMeta?.rowIndex;
          return (
            <Button
              onClick={() => {
                handlePossibleMatch(tableData[index]);
              }}
            >
              {t("openMediaAuditDetail.possibleMatch")}
            </Button>
          );
        }
      }
    },
    {
      name: "media_match_number",
      label: t("openMediaAuditDetail.matchMedia")
    },
    {
      name: "media_match_location",
      label: t("openMediaAuditDetail.matchMediaLoc")
    },
    {
      name: "media_match_status_type",
      label: t("openMediaAuditDetail.matchMediaStatus")
    },
    {
      name: "resolved_by_emp_name",
      label: t("openMediaAuditDetail.resolvedBy")
    },
    {
      name: "resolved_datetime",
      label: t("openMediaAuditDetail.dateResolved"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? dayjs(value).format(dayMonthYearFormat) : ""}</>;
        }
      }
    },
    {
      name: "batch_id",
      label: t("openMediaAuditDetail.batchId")
    }
  ];
  return (
    <Grid item xs={12} lg={3} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={[...tableDataList]}
        options={options}
      />
    </Grid>
  );
};

export default UnexpectedMediaDetail;
