/* Yup validation schemas goes here */
import i18n from "i18n";
import * as Yup from "yup";

/* Add extra items form schema */
export const extraItemsValidationSchema = Yup.object().shape({
  lock_quantity: Yup.number().test(
    "lockQuantityRequired",
    i18n.t("mediaRequestDetail.extraLockQuantityIsRequired"),
    (value, context) => {
      const lockTypeCode = context.parent?.lock_type_code;
      if (
        lockTypeCode !== undefined &&
        lockTypeCode !== null &&
        lockTypeCode !== ""
      ) {
        return value > 0;
      }
      return true;
    }
  ),
  lock_type_code: Yup.string().test(
    "lockTypeRequired",
    i18n.t("mediaRequestDetail.extraLockTypeIsRequired"),
    (value, context) => {
      const lockQuantity = Number(context.parent?.lock_quantity);

      if (value === undefined || value === null || value === "") {
        return lockQuantity === 0;
      }
      return true;
    }
  ),
  clip_quantity: Yup.number()
});
