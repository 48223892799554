import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import MultiSelectCheckBox from "components/core/multi-select-checkbox/MultiSelectCheckBox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import {
  clearError,
  clearWarning,
  setDontCallLabel,
  setSearchResults,
  updateSearchFormValues
} from "store/slices/discrepancy-search";
import { updateDiscrepancyAuthFormValues } from "store/slices/discrepancy-update";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  C,
  detectedIn,
  M,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import {
  discrepancyMediaList,
  discrepancyStatusList
} from "utils/constants/discrepancy-management/DiscrepancySearchConstants";
import { removeDuplicates } from "utils/helpers";
import { DiscrepencyStyles } from "./discrepancyStyles";

const DiscrepencySearch = ({
  handleReset,
  isFindButtonDisabled,
  allCustomers,
  setIsFindButtonDisabled,
  minDate,
  maxDate,
  today,
  onFindClick,
  initialAuthValues,
  getLogicalVaults,
  getListOfRuns,
  logicalVaults,
  discrepancyTypesOptions,
  setDiscrepancyTypesOptions,
  detectedTypesOptions,
  setDetectedTypesOptions,
  runOptions,
  setRunOptions,
  detectedInValues,
  setDetectedInValues,
  setLogicalVaults,
  customerMediaIndentifiersFlag,
  setPrevValues
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localeByBranch } = useSelector(selectAuth);
  const {
    searchFormValues,
    isAuthenticated,
    authCustomers,
    discrepancyTypes,
    detectedInTypes,
    logicalVaultsOptions
  } = useSelector((state) => state.discrepancySearch);
  const handleSelectAll = (optionValue) => {
    dispatch(
      updateSearchFormValues({
        detectedIn: searchFormValues.detectedIn.includes(ALL_SELECTION?.value)
          ? []
          : detectedTypesOptions.map((option) => option.label)
      })
    );
  };

  const handleDetectedChange = (event, optionValue) => {
    setPrevValues(false);
    dispatch(clearWarning());
    if (event === detectedIn) {
      if (optionValue.value === ALL_SELECTION?.value) {
        handleSelectAll(optionValue);
      }
      return;
    }
    const { value } = event.target;
    if (optionValue.props.value === ALL_SELECTION?.value) {
      handleSelectAll();
    } else {
      let newSelectedOptions = [...(searchFormValues?.detectedIn || null)];
      const optionValueString = optionValue.props.value;
      if (newSelectedOptions.includes(optionValueString)) {
        newSelectedOptions = newSelectedOptions.filter(
          (value) => value !== optionValueString
        );
      } else {
        newSelectedOptions.push(optionValueString);
      }
      dispatch(updateSearchFormValues({ detectedIn: newSelectedOptions }));
      if (newSelectedOptions.length === detectedTypesOptions.length - 1) {
        if (value?.includes(ALL_SELECTION?.value)) {
          newSelectedOptions = newSelectedOptions.filter(
            (value) => value !== ALL_SELECTION?.value
          );
        } else
          newSelectedOptions = [...newSelectedOptions, ALL_SELECTION?.value];
      } else if (newSelectedOptions.includes(ALL_SELECTION?.value)) {
        newSelectedOptions = newSelectedOptions.filter(
          (value) => value !== ALL_SELECTION?.value
        );
      }
      dispatch(updateSearchFormValues({ detectedIn: newSelectedOptions }));
    }
  };

  const renderValue = (selected) => {
    if (selected.includes(ALL_SELECTION?.value)) {
      return ALL_SELECTION?.value;
    }
    return selected.join(", ");
  };

  const clearAuthForm = () => {
    if (!isAuthenticated) {
      dispatch(updateDiscrepancyAuthFormValues(initialAuthValues));
    }
  };
  const handleInputChange = (name, value) => {
    setPrevValues(false);
    const updatedValues = {
      [name]: value
    };
    dispatch(updateSearchFormValues(updatedValues));
    dispatch(clearError());
    clearAuthForm();
    const updateData = { containerData: [], openMediaData: [] };
    dispatch(setSearchResults(updateData));
    dispatch(setDontCallLabel(null));
    dispatch(clearWarning());
  };

  const onCustomerChange = (event, value) => {
    setPrevValues(false);
    dispatch(clearError());
    const updatedValues = {
      auditId: "",
      customer: value
    };
    dispatch(updateSearchFormValues(updatedValues));
    if (value?.value !== t("common.all")) getLogicalVaults(value);

    dispatch(setDontCallLabel(VALUE_EMPTY_STRING));
    dispatch(clearError());
    clearAuthForm();
    const updateData = { containerData: [], openMediaData: [] };
    dispatch(setSearchResults(updateData));

    dispatch(clearWarning());
  };

  return (
    <form>
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid item xs={3}>
              <CustomersField
                options={isAuthenticated ? authCustomers : allCustomers}
                value={searchFormValues.customer}
                handleOnChange={onCustomerChange}
                label={t("common.customer")}
                required={false}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={null}
                id="discrepancy-media"
                disablePortal
                name={t("discrepancyManagement.media")}
                disabled={null}
                options={discrepancyMediaList}
                size="medium"
                value={searchFormValues.media}
                onChange={(event, newValue) => {
                  handleInputChange("media", newValue);
                  handleInputChange("discrepancyType", ALL_SELECTION);
                  handleInputChange("logicalVault", ALL_SELECTION);

                  switch (newValue?.value) {
                    case t("discrepancyManagement.container"): {
                      setDiscrepancyTypesOptions([
                        ALL_SELECTION,
                        ...discrepancyTypes[1]
                      ]);
                      setDetectedTypesOptions([
                        ALL_SELECTION,
                        ...detectedInTypes[1]
                      ]);

                      let detectedValues = [
                        ALL_SELECTION,
                        ...detectedInTypes[1]
                      ].map((option) => option.label);
                      dispatch(
                        updateSearchFormValues({ detectedIn: detectedValues })
                      );
                      let logicalContainer = logicalVaultsOptions.filter(
                        (vault) => vault.type === C
                      );
                      setLogicalVaults([ALL_SELECTION, ...logicalContainer]);
                      break;
                    }
                    case t("discrepancyManagement.openMedia"): {
                      setDiscrepancyTypesOptions([
                        ALL_SELECTION,
                        ...discrepancyTypes[2]
                      ]);
                      setDetectedTypesOptions([
                        ALL_SELECTION,
                        ...detectedInTypes[2]
                      ]);

                      let detectedValue = [
                        ALL_SELECTION,
                        ...detectedInTypes[2]
                      ].map((option) => option.label);
                      dispatch(
                        updateSearchFormValues({ detectedIn: detectedValue })
                      );

                      let logicalOM = logicalVaultsOptions.filter(
                        (vault) => vault.type === M
                      );
                      setLogicalVaults([ALL_SELECTION, ...logicalOM]);
                      break;
                    }
                    default: {
                      const typeOptions = [
                        ALL_SELECTION,
                        ...discrepancyTypes[1],
                        ...discrepancyTypes[2]
                      ];
                      const discrepancyTypesOptions = removeDuplicates(
                        typeOptions,
                        "label"
                      );
                      setDiscrepancyTypesOptions(discrepancyTypesOptions);
                      setDetectedTypesOptions([
                        ALL_SELECTION,
                        ...detectedInTypes[1],
                        ...detectedInTypes[2]
                      ]);

                      let detectedValuesAll = [
                        ALL_SELECTION,
                        ...detectedInTypes[1],
                        ...detectedInTypes[2]
                      ].map((option) => option.label);
                      dispatch(
                        updateSearchFormValues({
                          detectedIn: detectedValuesAll
                        })
                      );

                      setLogicalVaults(logicalVaultsOptions);
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("discrepancyManagement.media")}
                  />
                )}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="discrepancy-status"
                disablePortal
                name={t("discrepancyManagement.discrepancyStatus")}
                disabled={null}
                options={discrepancyStatusList}
                size="medium"
                value={searchFormValues.discrepancyStatus}
                onChange={(event, newValue) =>
                  handleInputChange("discrepancyStatus", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("discrepancyManagement.discrepancyStatus")}
                  />
                )}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="discrepancy-type"
                disabled={
                  !searchFormValues.customer ||
                  searchFormValues.customer.value === t("common.all")
                }
                name={t("discrepancyManagement.logicalVault")}
                options={logicalVaults}
                size="medium"
                value={searchFormValues.logicalVault}
                onChange={(event, newValue) => {
                  handleInputChange("logicalVault", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("discrepancyManagement.logicalVault")}
                  />
                )}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="discrepancy-type"
                disablePortal
                name={t("discrepancyManagement.discrepancyType")}
                options={discrepancyTypesOptions}
                size="medium"
                value={searchFormValues.discrepancyType}
                onChange={(event, newValue) => {
                  handleInputChange("discrepancyType", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("discrepancyManagement.discrepancyType")}
                  />
                )}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <MultiSelectCheckBox
                multiple
                fullWidth
                id="detectedIn"
                labelId="discrepancyManagement-label"
                label={t("discrepancyManagement.detectedIn")}
                name="detectedIn"
                options={detectedTypesOptions}
                disablePortal
                renderValue={renderValue}
                value={searchFormValues.detectedIn}
                onChange={(event, newValue) => {
                  handleDetectedChange(event, newValue);
                }}
                error={null}
                helperText={null}
                MenuProps={{
                  PaperProps: {
                    sx: DiscrepencyStyles.menuStyles
                  }
                }}
              />
            </Grid>
            {(searchFormValues?.media.value === t("common.all") ||
              searchFormValues?.media.value ===
                t("discrepancyManagement.container")) && (
              <>
                <Grid item xs={3}>
                  <DatePickerField
                    id="discrepancy-service-date"
                    name="serviceDate"
                    label={t("discrepancyManagement.serviceDate")}
                    value={searchFormValues.serviceDate || null}
                    minDate={minDate}
                    maxDate={today}
                    locale={localeByBranch}
                    onChange={(value) => {
                      const updatedValues = {
                        ...searchFormValues,
                        serviceDate: value
                      };
                      dispatch(updateSearchFormValues(updatedValues));
                      handleInputChange("run", ALL_SELECTION);
                      getListOfRuns(value);
                      dispatch(clearError());
                    }}
                    sx={DiscrepencyStyles.fieldStyle}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="discrepancy-run"
                    disabled={!searchFormValues?.serviceDate}
                    name={t("discrepancyManagement.run")}
                    options={runOptions}
                    size="medium"
                    value={searchFormValues.run}
                    onChange={(event, newValue) => {
                      handleInputChange("run", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("discrepancyManagement.run")}
                      />
                    )}
                    sx={DiscrepencyStyles.fieldStyle}
                  />
                </Grid>{" "}
              </>
            )}
            {/* //to do ShortDateFormat */}
            <Grid item xs={3}>
              <DatePickerField
                id="discrepancy-date"
                name="detectedDateFrom"
                label={t("discrepancyManagement.detectedDateFrom")}
                value={searchFormValues.detectedDateFrom || null}
                minDate={minDate}
                maxDate={today}
                locale={localeByBranch}
                onChange={(value) => {
                  const updatedValues = {
                    ...searchFormValues,
                    detectedDateFrom: value
                  };
                  dispatch(updateSearchFormValues(updatedValues));
                  dispatch(clearError());
                  setPrevValues(false);
                  dispatch(clearWarning());
                }}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="discrepancy-to"
                name="detectedDateTo"
                label={t("discrepancyManagement.detectedDateTo")}
                value={searchFormValues.detectedDateTo || null}
                minDate={minDate}
                maxDate={today}
                locale={localeByBranch}
                onChange={(value) => {
                  const updatedValues = {
                    ...searchFormValues,
                    detectedDateTo: value
                  };
                  dispatch(updateSearchFormValues(updatedValues));
                  dispatch(clearError());
                }}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("discrepancyManagement.media#")}
                id="discrepancy-id"
                fullWidth
                disabled={null}
                value={searchFormValues.mediaNumber}
                onChange={(event, newValue) => {
                  const updatedValues = {
                    mediaNumber: event.target.value
                  };
                  dispatch(updateSearchFormValues(updatedValues));
                  setPrevValues(false);
                  dispatch(clearWarning());
                }}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>

            {customerMediaIndentifiersFlag === Y_CHECKER && (
              <Grid item xs={3}>
                <TextField
                  label={t("discrepancyManagement.customerMediaIdentifier")}
                  id="discrepancy-id"
                  fullWidth
                  disabled={null}
                  value={searchFormValues.customerMediaIdentifier}
                  onChange={(event, newValue) => {
                    const updatedValues = {
                      customerMediaIdentifier: event.target.value
                    };
                    dispatch(updateSearchFormValues(updatedValues));
                    setPrevValues(false);
                    dispatch(clearWarning());
                  }}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <DatePickerField
                id="due-date"
                name="resolvedDateFrom"
                label={t("discrepancyManagement.resolvedDateFrom")}
                value={searchFormValues.resolvedDateFrom || null}
                minDate={minDate}
                maxDate={today}
                locale={localeByBranch}
                onChange={(value) => {
                  handleInputChange("resolvedDateFrom", value);
                }}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="resolve-to"
                name="resolvedDateTo"
                label={t("discrepancyManagement.resolvedDateTo")}
                value={searchFormValues.resolvedDateTo || null}
                minDate={minDate}
                maxDate={today}
                locale={localeByBranch}
                onChange={(value) => {
                  handleInputChange("resolvedDateTo", value);
                }}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="excludeAutoResolved"
                    id="excludeAutoResolved"
                    size="small"
                    color="primary"
                    style={DiscrepencyStyles?.checkBoxStyle}
                    checked={searchFormValues.excludeAutoResolved}
                    onChange={(event) => {
                      if (event && event?.target) {
                        const updatedValues = {
                          auditId: "",
                          excludeAutoResolved: event?.target?.checked
                        };
                        dispatch(updateSearchFormValues(updatedValues));
                        dispatch(clearError());
                        clearAuthForm();
                        dispatch(clearWarning());
                        setPrevValues(false);
                      }
                    }}
                  />
                }
                label={t("discrepancyManagement.excludeAutoResolved")}
                sx={DiscrepencyStyles.fieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid id="clear-button-grid" item xs="auto">
              <Button
                id="clear-button"
                variant="outlined"
                type="button"
                onClick={handleReset}
              >
                {t("common.clear")}
              </Button>
            </Grid>
            <Grid id="find-button-grid" item xs="auto">
              <Button
                id="find-button"
                variant="contained"
                type="button"
                disabled={isFindButtonDisabled}
                onClick={() => {
                  dispatch(clearError());
                  onFindClick();
                }}
              >
                {t("common.find")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default DiscrepencySearch;
