import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CustomersField } from "components/core/customer-field/CustomersField";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  callToCloudFunction,
  getDataFromFirestore,
  getResultSet1ErrorStatus
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER,
  shortMonth
} from "utils/constants";
import {
  bugCheckerTemp,
  defaultQuantity,
  descriptionTFInputProps,
  finalBillingMonthTemp,
  monthMapping,
  quantityLimit,
  quantityTFInputProps
} from "utils/constants/biling-module/BillingRecurringChargeConstants";
import {
  convertDateToEnglish,
  dateFormatToMonthAndYear,
  findErrorMessage,
  formatNumberByLocale
} from "utils/helpers";
import { billingRecurringChargeStyles } from "./BillingRecurringChargeStyles";

import { ReactComponent as ArrowDownDisabled } from "assets/images/ArrowDownDisabled.svg";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { getMessageFromCode } from "services/api/query";

const BillingRecurringChargeModal = ({
  customers,
  selectedRow,
  isUpdating,
  isCreating,
  findCatalogItemByCode,
  localeByBranch,
  setIsUpdating,
  setIsCreating,
  mainDistrictId,
  setEditingRowIndex,
  setSelectedRow,
  currentEOMBillingDate,
  setIsLoading,
  setCreatedOrUpdated,
  createdOrUpdated,
  setIsFindClicked,
  resultSet
}) => {
  const { t } = useTranslation();

  const [customerSelected, setCustomerSelected] = useState(VALUE_EMPTY_STRING);
  const [catalogItemSelected, setCatalogItemSelected] =
    useState(VALUE_EMPTY_STRING);
  const [quantity, setQuantity] = useState(defaultQuantity);
  const [descriptionSection, setDescriptionSection] =
    useState(VALUE_EMPTY_STRING);
  const [reviewMonthly, setReviewMonthly] = useState(false);
  const [firstBillingMonthList, setFirstBillingMonthList] = useState([]);
  const [selectedfirstBillingMonth, setSelectedfirstBillingMonth] =
    useState(VALUE_EMPTY_STRING);

  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [isStopClicked, setIsStopClicked] = useState(false);
  const [isEnabledStop, setIsEnabledStop] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const [onStopRC, setOnStopRC] = useState(false);

  const [isCancelClicked, setIsCancelClicked] = useState(false);

  const [existingCharge, setExistingCharge] = useState({});

  const [catelogItems, setCatelogItems] = useState([]);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] =
    useState(VALUE_EMPTY_STRING);

  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState(VALUE_EMPTY_STRING);

  const getResponseData = async (reqBody, url, resultSetCount) => {
    try {
      const response = await callToCloudFunction(reqBody, url);

      const dataSets = await getDataFromFirestore(
        response,
        resultSetCount,
        response?.data?.docId
      );

      return dataSets;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    setThrowError(false);
    if (customerSelected === VALUE_EMPTY_STRING) {
      setCatalogItemSelected(VALUE_EMPTY_STRING);
    }
    if (customerSelected && customerSelected !== VALUE_EMPTY_STRING) {
      fetchRecurringCatalogItems();
    }
    // eslint-disable-next-line
  }, [customerSelected]);

  useEffect(() => {
    setExistingCharge((prev) => selectedRow || {});
  }, [selectedRow]);

  useEffect(() => {
    if (currentEOMBillingDate !== VALUE_EMPTY_STRING) {
      let monthList = getNextFiveMonthsFromDate(currentEOMBillingDate);
      setFirstBillingMonthList(monthList);
      !isUpdating && setSelectedfirstBillingMonth(monthList[0]);
    }
    // eslint-disable-next-line
  }, [currentEOMBillingDate, localeByBranch]);

  useEffect(() => {
    if (isSaveClicked) {
      if (isUpdating) {
        updateRecurringTransactions();
      }

      if (isCreating) {
        insertRecurringTransactions();
      }
    }
    // eslint-disable-next-line
  }, [isSaveClicked]);

  // save button validations
  useEffect(() => {
    setWarningMessage((prev) => VALUE_EMPTY_STRING);
    setShowWarning((prev) => false);
    setOnStopRC((prev) => false);
    /**
     * when creating : enable save
     * when updating:
     *        if stoped : disable save
     *        else :
     *            if value changed: enable save
     *            else : disable save
     */
    if (isCreating) {
      setIsSaveEnabled(true);
    }

    if (isUpdating) {
      if (existingCharge?.final_billing_month !== VALUE_EMPTY_STRING) {
        setIsSaveEnabled(false);
      } else {
        //check if the values are changed or not
        if (
          quantity?.replace(/,/g, VALUE_EMPTY_STRING) !==
            existingCharge?.quantity ||
          reviewMonthly !==
            (existingCharge?.monthly_review_flag === Y_CHECKER) ||
          descriptionSection !== existingCharge?.descr ||
          selectedfirstBillingMonth !==
            dateFormatToMonthAndYear(
              existingCharge?.first_billing_month,
              localeByBranch
            )
        ) {
          setIsSaveEnabled(true);
        } else {
          setIsSaveEnabled(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    isCreating,
    isUpdating,
    quantity,
    reviewMonthly,
    descriptionSection,
    existingCharge,
    selectedfirstBillingMonth
  ]);

  useEffect(() => {
    if (isCreating) {
      setIsEnabledStop(false);
    }

    if (isUpdating) {
      if (existingCharge?.final_billing_month !== VALUE_EMPTY_STRING) {
        setIsEnabledStop((prev) => false);
      }
      if (
        !isStopClicked &&
        existingCharge?.final_billing_month === VALUE_EMPTY_STRING
      ) {
        setIsEnabledStop((prev) => true);
      }
      if (
        isStopClicked &&
        existingCharge?.final_billing_month === VALUE_EMPTY_STRING
      ) {
        setIsEnabledStop((prev) => false);
      }
    }
  }, [isStopClicked, isCreating, isUpdating, existingCharge]);

  useEffect(() => {
    if (
      isUpdating &&
      currentEOMBillingDate &&
      existingCharge?.first_billing_month
    ) {
      setSelectedfirstBillingMonth(
        dateFormatToMonthAndYear(
          existingCharge?.first_billing_month,
          localeByBranch
        )
      );
    }
    // eslint-disable-next-line
  }, [isUpdating, existingCharge?.first_billing_month, currentEOMBillingDate]);

  const fetchRecurringCatalogItems = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        customer_id: customerSelected?.value
      });

      const data = await getResponseData(
        reqBody,
        CF_URLS.billingRecurringCharge.getCatalogItems,
        1
      );

      if (data?.data) {
        if (data?.data[0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          if (data?.data[0]?.length === 0) {
            setThrowErrorMessage(
              t("billingRecurringCharge.noCatalogItemsForTheCustomer")
            );
            setThrowError(true);

            setCatelogItems((prev) => []);
            setCatalogItemSelected(VALUE_EMPTY_STRING);
            setDescriptionSection(VALUE_EMPTY_STRING);
          } else {
            const mapping = data?.data[0]?.map(
              ({ invoice_line_descr, catalog_item_code }) => ({
                label: invoice_line_descr,
                value: catalog_item_code
              })
            );

            setCatelogItems((prev) => mapping);

            setCatalogItemSelected(mapping[0]);
            setDescriptionSection(mapping[0].label);
          }
        }
      } else if (data?.error) {
        setCatelogItems((prev) => []);
        setCatalogItemSelected(VALUE_EMPTY_STRING);
        setDescriptionSection(VALUE_EMPTY_STRING);

        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setCatelogItems((prev) => []);
      setCatalogItemSelected(VALUE_EMPTY_STRING);
      setDescriptionSection(VALUE_EMPTY_STRING);

      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const replaceMonthInDate = (dateStr, monthYearStr) => {
    const [month, year] = monthYearStr.split(" ");
    return `${year}-${monthMapping[month]}-${dateStr?.substring(8, 10)}`;
  };

  const insertRecurringTransactions = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        customer_id: customerSelected?.value,
        catalog_item_code: catalogItemSelected?.value,
        quantity: quantity?.replace(/,/g, VALUE_EMPTY_STRING),
        descr: descriptionSection,
        first_billing_month: convertDateToEnglish(
          selectedfirstBillingMonth,
          localeByBranch
        ),
        monthly_review_flag: reviewMonthly ? VALUE_Y : VALUE_N
      });

      const data = await getResponseData(
        reqBody,
        CF_URLS.billingRecurringCharge.insertRecurringCharge,
        1
      );

      if (data?.data && data?.data[0]) {
        if (!data?.data[0][0]?.error) {
          setCreatedOrUpdated((prev) => prev + 1);
          setExistingCharge((prev) => data?.data[0][0]);

          setIsCreating(false);
          setIsUpdating(true);
        } else {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        }
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsSaveClicked(false);
      setIsLoading(false);
    }
  };

  const updateRecurringTransactions = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        recurring_transaction_id: existingCharge?.recurring_transaction_id,
        quantity: quantity?.replace(/,/g, VALUE_EMPTY_STRING),
        descr: descriptionSection,
        first_billing_month: replaceMonthInDate(
          existingCharge?.first_billing_month,
          convertDateToEnglish(selectedfirstBillingMonth, localeByBranch)
        ),
        monthly_review_flag: reviewMonthly ? VALUE_Y : VALUE_N,
        ts1: existingCharge?.timestamp
      });

      const data = await getResponseData(
        reqBody,
        CF_URLS.billingRecurringCharge.updateRecurringCharge,
        1
      );

      if (data?.data) {
        if (!data?.data[0]?.error) {
          setCreatedOrUpdated((prev) => prev + 1);

          setExistingCharge((prev) => data?.data[0][0]);
        } else {
          const errorMsg = await getMessageFromCode(data?.data[0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        }
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsSaveClicked(false);
      setIsLoading(false);
    }
  };

  const cancelRecurringTransactions = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        recurring_transaction_id: existingCharge?.recurring_transaction_id,
        ts1: existingCharge?.timestamp
      });

      const data = await getResultSet1ErrorStatus(
        reqBody,
        CF_URLS.billingRecurringCharge.cancelRecurringCharge
      );

      if (data && data[0] && data[0]?.error !== "0") {
        if (Number(data[0]?.error) > 0) {
          const errorMsg = await getMessageFromCode(data?.data[0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
        } else {
          setThrowErrorMessage(
            data[0]?.error || findErrorMessage(ERROR_TYPES.ISSUE)
          );
        }

        setThrowError(true);
      } else {
        setCreatedOrUpdated((prev) => prev + 1);
        setIsStopClicked((prev) => true);
        const newValue = {
          ...existingCharge,
          final_billing_month: finalBillingMonthTemp
        };
        setExistingCharge((prev) => newValue);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setQuantity(isUpdating ? existingCharge?.quantity : defaultQuantity);
    setDescriptionSection(
      isUpdating ? existingCharge?.descr : VALUE_EMPTY_STRING
    );
    setReviewMonthly(
      isUpdating ? existingCharge?.monthly_review_flag === VALUE_Y : false
    );
    // eslint-disable-next-line
  }, [existingCharge]);

  /**
   * handle customer mui autocomplete component selected value change
   * @param {object} event - not used but needed to get the second input param
   * @param {string} newValue
   */
  const handleCustomerChange = (event, newValue) => {
    // user can clear the field and it will return null
    if (newValue === null) {
      setCustomerSelected(VALUE_EMPTY_STRING);
      setDescriptionSection(VALUE_EMPTY_STRING);
    } else {
      // setting the new value to the customerSelected state
      setCustomerSelected(newValue);
    }
  };

  /**
   * To get the next five months based on the date recived
   * @param {string} dateString
   * @returns {array}
   */
  const getNextFiveMonthsFromDate = (dateString) => {
    // dateString will be like '2024-01-01T00:00:00Z'
    const [year, month] = (dateString?.split("T")[0]?.split("-") || []).map(
      (part) => parseInt(part, 10)
    );

    const monthNames = [];
    for (let i = 0; i < 12; i++) {
      monthNames.push(
        new Intl.DateTimeFormat(localeByBranch, shortMonth).format(
          new Date(2000, i) // just passing year 2000 as a placeholder
        )
      );
    }

    const months = [];
    for (let i = 0; i < 6; i++) {
      const currentMonth = (month - 1 + i) % 12;
      const currentYear = year + Math.floor((month - 1 + i) / 12);
      months.push(`${monthNames[currentMonth]} ${currentYear}`);
    }

    // return all 6 months as an array
    return months;
  };

  const handleCancelClick = () => {
    setIsCancelClicked(false);

    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    setCustomerSelected(VALUE_EMPTY_STRING);
    setCatalogItemSelected(VALUE_EMPTY_STRING);
    setCatelogItems([]);
    setQuantity(defaultQuantity);
    setDescriptionSection(VALUE_EMPTY_STRING);
    setReviewMonthly(false);

    setEditingRowIndex(null);
    setSelectedRow(null);

    setIsCreating(false);
    setIsUpdating(false);
    setIsStopClicked(false);

    setSelectedfirstBillingMonth(
      firstBillingMonthList[0] || VALUE_EMPTY_STRING
    );

    if (createdOrUpdated > 0) {
      if (resultSet?.length > 0) {
        setIsFindClicked(true);
      }

      setCreatedOrUpdated(0);
    }
  };

  const handleNewClick = () => {
    setQuantity(defaultQuantity);
    setDescriptionSection(VALUE_EMPTY_STRING);
    setReviewMonthly(false);

    setCustomerSelected(VALUE_EMPTY_STRING);
    setCatalogItemSelected(VALUE_EMPTY_STRING);
    setCatelogItems([]);
    setSelectedfirstBillingMonth(
      firstBillingMonthList[0] || VALUE_EMPTY_STRING
    );

    setEditingRowIndex(null);
    setSelectedRow(null);

    setIsCreating(true);
    setIsUpdating(false);
    setIsSaveClicked(false);

    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);
  };

  const handleSaveClick = () => {
    setWarningMessage((prev) => VALUE_EMPTY_STRING);
    setShowWarning((prev) => false);
    setOnStopRC((prev) => false);

    if (!isUpdating && customerSelected === VALUE_EMPTY_STRING) {
      setThrowErrorMessage(t("billingRecurringCharge.customerNumberRequired"));
      setThrowError(true);
      return;
    } else if (
      Number(quantity?.replace(/,/g, VALUE_EMPTY_STRING)) === 0 ||
      quantity?.replace(/,/g, VALUE_EMPTY_STRING) > bugCheckerTemp // bugCheckerTemp - is a temporary value. Legacy app cannot add more that 999.99
    ) {
      setThrowErrorMessage(t("billingRecurringCharge.quantityError"));
      setThrowError(true);
      return;
    } else if (descriptionSection === VALUE_EMPTY_STRING) {
      setThrowErrorMessage(t("billingRecurringCharge.descriptionRequired"));
      setThrowError(true);
      return;
    } else if (
      isCreating &&
      customerSelected !== VALUE_EMPTY_STRING &&
      catalogItemSelected === VALUE_EMPTY_STRING
    ) {
      setThrowErrorMessage(
        t("billingRecurringCharge.noCatalogItemsForTheCustomer")
      );
      setThrowError(true);
      return;
    } else {
      setThrowErrorMessage(VALUE_EMPTY_STRING);
      setThrowError(false);
    }

    setIsSaveClicked(true);
  };

  const formatValue = (inputValue) => {
    let num = parseFloat(inputValue?.replace(/,/g, VALUE_EMPTY_STRING));
    if (isNaN(num)) {
      return defaultQuantity;
    }
    num = Math.min(num, quantityLimit);
    num = Math.round(num * 100) / 100;
    return formatNumberByLocale(num, localeByBranch);
  };

  const handleYes = () => {
    setWarningMessage((prev) => VALUE_EMPTY_STRING);
    setShowWarning((prev) => false);
    setOnStopRC((prev) => false);
    if (isCreating && customerSelected !== VALUE_EMPTY_STRING) {
      handleNewClick();
    }
    isUpdating && cancelRecurringTransactions();
    isCancelClicked && handleCancelClick();
  };

  const handleNo = () => {
    if (isCancelClicked) {
      handleCancelClick();
    } else {
      handleNewClick();
      setWarningMessage((prev) => VALUE_EMPTY_STRING);
      setShowWarning((prev) => false);
      setOnStopRC((prev) => false);
    }
  };

  const customersFieldProps = {
    options: customers,
    value: isUpdating
      ? existingCharge?.number
        ? existingCharge?.number
        : customers?.filter(
            (cus) => cus?.value === existingCharge?.customer_id
          )[0]?.number || VALUE_EMPTY_STRING
      : customerSelected,
    disabled: isUpdating,
    required: true,
    handleOnChange: handleCustomerChange,
    sx: billingRecurringChargeStyles.commonStyles.widthFixed,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  return (
    <>
      {showWarning && !onStopRC && (
        <ConfirmPopup
          modalPopupOpen={showWarning}
          handleCancel={() => {
            setWarningMessage((prev) => VALUE_EMPTY_STRING);
            setShowWarning((prev) => false);
            setOnStopRC((prev) => false);
          }}
          handleNo={handleNo}
          handleYes={handleSaveClick}
          showCancel={true}
          showNo={true}
        />
      )}

      {showWarning && onStopRC && (
        <ConfirmPopup
          modalPopupOpen={showWarning}
          message={warningMessage}
          handleNo={() => {
            setWarningMessage((prev) => VALUE_EMPTY_STRING);
            setShowWarning((prev) => false);
            setOnStopRC((prev) => false);
          }}
          handleYes={handleYes}
          showCancel={false}
          showNo={true}
        />
      )}

      <CommonModal
        open={isUpdating || isCreating}
        title={
          isUpdating
            ? t("billingRecurringCharge.updateTitle")
            : t("billingRecurringCharge.createTitle")
        }
        body={
          <Stack
            direction="column"
            gap={spacing.gap}
            sx={
              billingRecurringChargeStyles.formCreateAndUpdateChargeStyles
                .cardContentStack
            }
          >
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Stack direction="row" gap={spacing.gap}>
              {/* ---------- Customer selection ----------   */}
              <CustomersField {...customersFieldProps} />

              {/* ---------- Catalog item selection ----------   */}
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                clearIcon={<CloseIcon />}
                id="catalogItem-list"
                name={t("billingRecurringCharge.catalogItemLabel")}
                options={catelogItems}
                size="medium"
                value={
                  isUpdating
                    ? findCatalogItemByCode(
                        existingCharge?.catalog_item_code
                      ) || VALUE_EMPTY_STRING
                    : catalogItemSelected
                }
                fullWidth
                disabled={
                  isUpdating ||
                  (!isUpdating && catalogItemSelected === VALUE_EMPTY_STRING)
                }
                popupIcon={
                  isUpdating ||
                  (!isUpdating &&
                    catalogItemSelected === VALUE_EMPTY_STRING) ? (
                    <ArrowDownDisabled />
                  ) : (
                    <ArrowDown />
                  )
                }
                onChange={(e, value) => {
                  if (value) {
                    setCatalogItemSelected(value);
                    setDescriptionSection(value?.label);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("billingRecurringCharge.catalogItemLabelModal")}
                  />
                )}
                sx={billingRecurringChargeStyles.commonStyles.widthFixed}
              />
            </Stack>

            <Stack direction="row" gap={spacing.gap}>
              <TextField
                label={t("billingRecurringCharge.quantityLabelModal")}
                id="quantity"
                fullWidth
                sx={billingRecurringChargeStyles.commonStyles.widthFixed}
                disabled={
                  isUpdating &&
                  existingCharge?.final_billing_month !== VALUE_EMPTY_STRING
                }
                inputProps={quantityTFInputProps}
                value={quantity}
                onBlur={(e) => {
                  setQuantity((prev) => formatValue(quantity));
                }}
                onChange={(e, value) => {
                  setQuantity((prev) => e?.target?.value || VALUE_EMPTY_STRING);
                }}
                InputLabelProps={
                  billingRecurringChargeStyles.formCreateAndUpdateChargeStyles
                    .tfInputLabelPtops
                }
              />

              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                clearIcon={<CloseIcon />}
                id="first-billing-month"
                name={t("billingRecurringCharge.firstBillingMonthLabel")}
                options={firstBillingMonthList || []}
                size="medium"
                value={selectedfirstBillingMonth || VALUE_EMPTY_STRING}
                disabled={
                  isUpdating &&
                  existingCharge?.final_billing_month !== VALUE_EMPTY_STRING
                    ? true
                    : isUpdating
                      ? parseInt(
                          existingCharge?.first_billing_month?.substring(5, 7),
                          10
                        ) < parseInt(currentEOMBillingDate?.substring(5, 7), 10)
                      : false
                }
                popupIcon={isUpdating ? <ArrowDownDisabled /> : <ArrowDown />}
                fullWidth
                onChange={(e, newValue) => {
                  newValue
                    ? setSelectedfirstBillingMonth(newValue)
                    : setSelectedfirstBillingMonth(firstBillingMonthList[0]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("billingRecurringCharge.firstBillingMonthLabel")}
                  />
                )}
                sx={billingRecurringChargeStyles.commonStyles.widthFixed}
              />
            </Stack>

            <Stack direction="row" gap={spacing.gap}>
              <TextField
                label={t("billingRecurringCharge.decsriptionLabel")}
                id="description"
                fullWidth
                multiline
                disabled={
                  isUpdating &&
                  existingCharge?.final_billing_month !== VALUE_EMPTY_STRING
                }
                sx={
                  billingRecurringChargeStyles.formCreateAndUpdateChargeStyles
                    .textFieldSX
                }
                inputProps={descriptionTFInputProps}
                rows={2}
                value={descriptionSection}
                onChange={(e, value) => {
                  setDescriptionSection(e.target.value);
                }}
                InputLabelProps={
                  billingRecurringChargeStyles.formCreateAndUpdateChargeStyles
                    .tfInputLabelPtops
                }
              />

              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reviewMonthly}
                        disabled={
                          isUpdating &&
                          existingCharge?.final_billing_month !==
                            VALUE_EMPTY_STRING
                        }
                        onChange={(event) => {
                          if (event && event?.target) {
                            setReviewMonthly(event?.target?.checked);
                          }
                        }}
                      />
                    }
                    label={t("billingRecurringCharge.reviewMonthlyLabel")}
                  />
                </FormGroup>
              </Box>
            </Stack>

            <Box sx={billingRecurringChargeStyles.commonStyles.heightBox}></Box>
          </Stack>
        }
        buttons={
          <>
            <Button
              id="CancelBtn"
              variant="outlined"
              onClick={() => {
                setIsCancelClicked(true);

                if (isCreating && customerSelected !== VALUE_EMPTY_STRING) {
                  setWarningMessage((prev) => t("common.saveChangesNow"));
                  setShowWarning((prev) => true);
                  setOnStopRC((prev) => false);
                }

                if (isCreating && customerSelected === VALUE_EMPTY_STRING) {
                  handleCancelClick();
                }

                if (isUpdating) {
                  if (isSaveEnabled) {
                    setWarningMessage((prev) => t("common.saveChangesNow"));
                    setShowWarning((prev) => true);
                    setOnStopRC((prev) => false);
                  } else {
                    handleCancelClick();
                  }
                }
              }}
              sx={
                billingRecurringChargeStyles.formCreateAndUpdateChargeStyles
                  .buttonSX
              }
            >
              {t("common.cancel")}
            </Button>

            <Button
              id="newBtn"
              variant="outlined"
              onClick={() => {
                if (isCreating && customerSelected !== VALUE_EMPTY_STRING) {
                  setWarningMessage((prev) => t("common.saveChangesNow"));
                  setShowWarning((prev) => true);
                  setOnStopRC((prev) => false);
                }

                if (isCreating && customerSelected === VALUE_EMPTY_STRING) {
                  handleNewClick();
                }

                if (isUpdating) {
                  if (isSaveEnabled) {
                    setWarningMessage((prev) => t("common.saveChangesNow"));
                    setShowWarning((prev) => true);
                    setOnStopRC((prev) => false);
                  } else {
                    handleNewClick();
                  }
                }
              }}
              sx={
                billingRecurringChargeStyles.formCreateAndUpdateChargeStyles
                  .buttonSX
              }
            >
              {t("common.new")}
            </Button>

            <Button
              id="saveBtn"
              variant="contained"
              disabled={!isSaveEnabled}
              onClick={handleSaveClick}
              sx={
                billingRecurringChargeStyles.formCreateAndUpdateChargeStyles
                  .buttonSX
              }
            >
              {t("common.save")}
            </Button>

            <Button
              id="stopChargeBtn"
              variant="outlined"
              onClick={() => {
                setOnStopRC((prev) => true);
                setWarningMessage((prev) =>
                  t("billingRecurringCharge.cancelChargeWarning")
                );
                setShowWarning((prev) => true);
              }}
              disabled={!isEnabledStop}
              sx={
                billingRecurringChargeStyles.formCreateAndUpdateChargeStyles
                  .buttonSX
              }
            >
              {t("billingRecurringCharge.stopChargeBtn")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default BillingRecurringChargeModal;
