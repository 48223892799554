import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { SelectField } from "components/core";
import { useTranslation } from "react-i18next";
import { transportationConstants } from "utils/constants/transportation-module/TransportationConstants";
import { RouteTimeTableStyles } from "./RouteTimeTableStyles.Styles";

export const RouteTransportationInformation = ({
  currentFormState,
  transportationMode,
  setTransportationMode,
  vendorsList,
  transportationVendorId,
  handleFieldValueChange,
  routeDetailsForm
}) => {
  const { t } = useTranslation();

  /**
   *
   * @param {String} checkboxName
   * @returns {Boolean} true | false to determin whether the option checkboxes are disabled.
   */
  const isDisabled = (checkboxName) => {
    switch (currentFormState) {
      case transportationConstants.formStatus.initial:
        return true;
      case transportationConstants.formStatus.search:
        if (
          checkboxName === transportationConstants.mode.ironMountain &&
          transportationMode === transportationConstants.mode.thirdParty
        ) {
          return true;
        }
        if (
          checkboxName === transportationConstants.mode.thirdParty &&
          transportationMode === transportationConstants.mode.ironMountain
        ) {
          return true;
        }
        return false;
      case transportationConstants.formStatus.edit:
        if (
          checkboxName === transportationConstants.mode.ironMountain &&
          transportationMode === transportationConstants.mode.thirdParty
        ) {
          return true;
        }
        if (
          checkboxName === transportationConstants.mode.thirdParty &&
          transportationMode === transportationConstants.mode.ironMountain
        ) {
          return true;
        }
        return transportationMode === transportationConstants.mode.thirdParty
          ? false
          : true;
      case transportationConstants.formStatus.new:
        if (
          !routeDetailsForm.values?.routeCode ||
          routeDetailsForm.values?.routeCode === "" ||
          routeDetailsForm.values?.name === ""
        ) {
          return true;
        }
        return false;
      default:
        break;
    }
  };

  return (
    <Grid item container sx={RouteTimeTableStyles.marginTop2EM}>
      <Grid item xs="12">
        <h3>{t("routeDetails.transportation")}</h3>
      </Grid>
      <Grid item xs="12" lg="6" sx={RouteTimeTableStyles.paddingTop2EM}>
        <RadioGroup
          row
          aria-labelledby="transportation-mode-radio-button-group"
          name="transportation-mode"
          value={transportationMode}
          onChange={(e) => {
            setTransportationMode(e.target.value);
            if (e.target.value === transportationConstants.mode.thirdParty) {
              routeDetailsForm.setFieldValue("minutesPerStopGoal", "");
            }
          }}
        >
          <Grid item xs={6} lg={6}>
            <FormControlLabel
              id="iron_mountain"
              value="iron_mountain"
              control={<Radio />}
              label={t("routeDetails.ironMountain")}
              disabled={isDisabled("iron_mountain")}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              id="third_party"
              value={"third_party"}
              control={<Radio />}
              label={t("routeDetails.thirdParty")}
              disabled={isDisabled("third_party")}
            />
          </Grid>
        </RadioGroup>
      </Grid>
      <Grid item xs="12" lg="3" sx={RouteTimeTableStyles.paddingTop2EM}>
        {transportationMode === transportationConstants.mode.thirdParty && (
          <SelectField
            name="transportationVendorId"
            label={t("routeDetails.vendor")}
            required
            options={vendorsList.data}
            loading={vendorsList.isLoading}
            value={routeDetailsForm?.values?.transportationVendorId}
            onChange={handleFieldValueChange}
          />
        )}
      </Grid>
      <Grid item xs="12" lg="3" sx={RouteTimeTableStyles.paddingTop2EM}></Grid>
    </Grid>
  );
};
