import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { TABLE_ROWS_PER_PAGE_DEFAULT } from "components/features/request-module/media-destruction-request-vault-itemized/Constants";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { ALL_SELECTION, EMPTY_SELECTION } from "utils/constants";
import { sortByValues } from "utils/constants/biling-module/BillingTransactionsConstants";
import { billingTransactionsStyles } from "./BillingTransactionsStyles";

const BillingTransactionsSearchFilter = ({
  mrdForm,
  setSelectedRow,
  setDisableAll,

  isBranchDisabled,
  branchList,
  selectedBranch,
  setSelectedBranch,

  isBillCycleDisabled,
  billCycleList,
  selectedBillCycle,
  setSelectedBillCycle,

  billDate,

  isServiceDisabled,
  servicesList,
  selectedService,
  setSelectedService,
  setSelectedServiceNew,

  customerList,
  setSelectedCustomer,
  selectedCustomer,
  isCustomerDisabled,

  isClassificationDisabled,
  classificationList,
  selectedClassification,
  setSelectedClassification,

  isCatalogItemDisabled,
  catalogItemsList,
  selectedCatalogItem,
  setSelectedCatalogItem,

  selectedSortBy,
  sortByList,
  setSelectedSortBy,

  isCheckBoxDisabled,
  isChecked,
  setIsChecked,

  isFindBtnDisabled,

  reset,
  setTableData,
  setIsFindButtonClicked,
  setEditingRowIndex,
  setPage,
  setRowsPerPage,
  setSelectedIndex
}) => {
  const { t } = useTranslation();

  const customersFieldProps = {
    options: [ALL_SELECTION, ...customerList],
    value: selectedCustomer,
    disabled: isCustomerDisabled,
    handleOnChange: (e, newValue) => {
      setSelectedCustomer(newValue || ALL_SELECTION);
      resetTable();
    },
    sx: billingTransactionsStyles.commonStyles.widthFull,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  const resetTable = () => {
    setTableData([]);
    setIsFindButtonClicked(false);
    setSelectedRow(null);
    setDisableAll(false);
    setEditingRowIndex(null);
    setPage(0);
    setRowsPerPage(TABLE_ROWS_PER_PAGE_DEFAULT);
    setSelectedIndex(null);
  };

  return (
    <form onSubmit={mrdForm?.handleSubmit}>
      <Stack direction="column" gap={spacing.gap}>
        {/* Stack1 */}
        <Stack direction="row" gap={spacing.gap}>
          {/* ---------- Branch Selection ----------  */}
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="branch-filter"
            name={t("billingTransactions.branch")}
            disabled={isBranchDisabled}
            options={branchList || []}
            size="medium"
            value={selectedBranch}
            fullWidth
            onChange={(event, newValue) => {
              setSelectedBranch(newValue || EMPTY_SELECTION);
              resetTable();
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("billingTransactions.branch")} />
            )}
            sx={billingTransactionsStyles.commonStyles.widthFull}
          />

          {/* ---------- Bill Cycle Selection ----------  */}
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="billcycle-filter"
            disabled={isBillCycleDisabled}
            name={t("billingTransactions.billCycle")}
            options={billCycleList || []}
            size="medium"
            value={selectedBillCycle}
            fullWidth
            onChange={(event, newValue) => {
              setSelectedBillCycle(newValue || selectedBillCycle);
              resetTable();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("billingTransactions.billCycle")}
              />
            )}
            sx={billingTransactionsStyles.commonStyles.widthFull}
          />

          {/* ---------- Bill Date (Read only) ----------  */}
          <TextField
            id="billdate"
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
            label={t("billingTransactions.billDate")}
            fullWidth
            style={billingTransactionsStyles.commonStyles.widthFull}
            value={billDate}
            InputLabelProps={billingTransactionsStyles.commonStyles.inputProps}
          />

          {/* ---------- Service Selection ----------  */}
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="service-filter"
            name={t("billingTransactions.service")}
            disabled={isServiceDisabled}
            options={[ALL_SELECTION, ...servicesList] || []}
            size="medium"
            value={selectedService}
            fullWidth
            onChange={(event, newValue) => {
              setSelectedService(newValue || ALL_SELECTION);
              resetTable();
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("billingTransactions.service")} />
            )}
            sx={billingTransactionsStyles.commonStyles.widthFull}
          />
        </Stack>

        {/* Stack2 */}
        <Stack direction="row" gap={spacing.gap}>
          {/* ---------- Customer Selection ----------  */}
          <CustomersField {...customersFieldProps} />

          {/* ---------- Classification Selection ----------  */}
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="classification-filter"
            disabled={isClassificationDisabled}
            name={t("billingTransactions.classification")}
            options={classificationList}
            size="medium"
            value={selectedClassification}
            fullWidth
            onChange={(event, newValue) => {
              setSelectedClassification(newValue || ALL_SELECTION);
              resetTable();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("billingTransactions.classification")}
              />
            )}
            sx={billingTransactionsStyles.commonStyles.widthFull}
          />

          {/* ---------- Catalog Item Selection ----------  */}
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="catalogitem-filter"
            disabled={isCatalogItemDisabled}
            name={t("billingTransactions.catalogItem")}
            options={catalogItemsList}
            size="medium"
            value={selectedCatalogItem}
            fullWidth
            onChange={(event, newValue) => {
              setSelectedCatalogItem(newValue || ALL_SELECTION);
              resetTable();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("billingTransactions.catalogItem")}
              />
            )}
            sx={billingTransactionsStyles.commonStyles.widthFull}
          />

          {/* ---------- Sort By Selection ----------  */}
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="sortby-filter"
            name={t("billingTransactions.sortBy")}
            options={sortByValues || []}
            size="medium"
            value={selectedSortBy}
            fullWidth
            onChange={(event, newValue) => {
              setSelectedSortBy(newValue || selectedSortBy);
              resetTable();
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("billingTransactions.sortBy")} />
            )}
            sx={billingTransactionsStyles.commonStyles.widthFull}
          />
        </Stack>

        {/* ---------- Counted Items CheckBox ----------  */}
        <Stack direction="row">
          <FormGroup>
            <FormControlLabel
              disabled={isCheckBoxDisabled}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(event) => {
                    setIsChecked((prev) => !prev);
                    setTableData([]);
                  }}
                />
              }
              label={t("billingTransactions.countedItemCheck")}
            />
          </FormGroup>
        </Stack>

        {/* ---------- Button Section ----------  */}
        <Stack direction="row" gap={spacing.gap}>
          <Button
            id="newBtnfilter"
            variant="outlined"
            onClick={() => {
              reset();
            }}
          >
            {t("common.clear")}
          </Button>
          <Button
            id="saveBtnfilter"
            disabled={isFindBtnDisabled}
            variant="contained"
            type="submit"
          >
            {t("common.find")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default BillingTransactionsSearchFilter;
