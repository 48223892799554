import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { popupStyles } from "components/core/modal-components/Popup.Style";
import { useTranslation } from "react-i18next";
function PrintModalPopup({
  title,
  modalPopupOpen,
  handleClose,
  handlePrint,
  printOption,
  setPrintOption
}) {
  const { t } = useTranslation();
  return (
    <Paper elevation={24}>
      <Dialog
        open={modalPopupOpen}
        fullWidth={true}
        PaperProps={{
          sx: {
            ...popupStyles.dialogModal
          }
        }}
      >
        <DialogTitle id="alertDialogTitle" sx={popupStyles.paddingTitleStyle}>
          <Typography sx={popupStyles.titleStyle}>{title}</Typography>
        </DialogTitle>
        <Divider />
        {/* ------------------ Body Section ------------------ */}{" "}
        <DialogContent sx={popupStyles.cardContentStyles}>
          <RadioGroup
            aria-label="printOption"
            name="printOption"
            value={printOption}
            onChange={(e) => setPrintOption(e.target.value)}
          >
            <FormControlLabel
              value="summary"
              control={<Radio />}
              label={t("correctiveActionRequestSearch.summary")}
            />
            <FormControlLabel
              value="detail"
              control={<Radio />}
              label={t("correctiveActionRequestSearch.detail")}
            />
          </RadioGroup>
        </DialogContent>
        <Divider />
        <DialogActions sx={popupStyles.modalButton}>
          <Button
            id="modal-print-btn"
            variant="outlined"
            sx={popupStyles.modalButton}
            onClick={handleClose}
          >
            {t("common.close")}
          </Button>
          <Button
            id="modalPrintBtn"
            variant="contained"
            sx={popupStyles.modalButton}
            onClick={handlePrint}
          >
            {t("common.print")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default PrintModalPopup;
