import i18n from "i18n";
export const tableConstants = Object.freeze({
  defaultTableRowData: [
    {
      day: "Monday",
      day_of_week_code: "Mon",
      start_time: "",
      depart_time: "",
      arrive_time: "",
      stop_time: "",
      vehicle_type_code: "",
      service_rep_employee_id: ""
    },
    {
      day: "Tuesday",
      day_of_week_code: "Tue",
      start_time: "",
      depart_time: "",
      arrive_time: "",
      stop_time: "",
      vehicle_type_code: "",
      service_rep_employee_id: ""
    },
    {
      day: "Wednesday",
      day_of_week_code: "Wed",
      start_time: "",
      depart_time: "",
      arrive_time: "",
      stop_time: "",
      vehicle_type_code: "",
      service_rep_employee_id: ""
    },
    {
      day: "Thursday",
      day_of_week_code: "Thu",
      start_time: "",
      depart_time: "",
      arrive_time: "",
      stop_time: "",
      vehicle_type_code: "",
      service_rep_employee_id: ""
    },
    {
      day: "Friday",
      day_of_week_code: "Fri",
      start_time: "",
      depart_time: "",
      arrive_time: "",
      stop_time: "",
      vehicle_type_code: "",
      service_rep_employee_id: ""
    },
    {
      day: "Saturday",
      day_of_week_code: "Sat",
      start_time: "",
      depart_time: "",
      arrive_time: "",
      stop_time: "",
      vehicle_type_code: "",
      service_rep_employee_id: ""
    },
    {
      day: "Sunday",
      day_of_week_code: "Sun",
      start_time: "",
      depart_time: "",
      arrive_time: "",
      stop_time: "",
      vehicle_type_code: "",
      service_rep_employee_id: ""
    }
  ],
  defaultTableRowDataThirdParty: [
    { day: "Monday", day_of_week_code: "Mon", depart_time: "" },
    { day: "Tuesday", day_of_week_code: "Tue", depart_time: "" },
    { day: "Wednesday", day_of_week_code: "Wed", depart_time: "" },
    { day: "Thursday", day_of_week_code: "Thu", depart_time: "" },
    { day: "Friday", day_of_week_code: "Fri", depart_time: "" },
    { day: "Saturday", day_of_week_code: "Sat", depart_time: "" },
    { day: "Sunday", day_of_week_code: "Sun", depart_time: "" }
  ],
  thirdPartyHeadersNames: ["day", "depart_time"]
});

export const transportationConstants = Object.freeze({
  mode: {
    thirdParty: "third_party",
    ironMountain: "iron_mountain"
  },
  formStatus: {
    initial: "initial",
    new: "new",
    edit: "edit",
    search: "search"
  },
  numbers: {
    zero: "0",
    one: "1",
    thousand: "1000"
  },
  defaultRouteType: {
    label: i18n.t("routeDetails.scheduled"),
    value: i18n.t("routeDetails.scheduled")
  }
});