import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { DatePickerField, FullCircularLoader } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADMIN_MODULE_BASE_PATH,
  ADMIN_MODULE_BILLING_BASE_PATH,
  BILLING_ERROR_LOG,
  BILLING_STATUS,
  BILLING_TRANSACTIONS
} from "routing/Paths";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getCustomName, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  ERROR_TYPES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  VALUE_N,
  Y_CHECKER,
  billCycleIDs,
  billCycles,
  billingPhaseIDs,
  branchSortOption,
  branchSortOrder,
  dateFormatWithoutTime,
  downloadReportOptions,
  elapsedTime,
  errorMsgs,
  maximumDate,
  minimumDate,
  removeingText,
  ymdDateFormat
} from "utils/constants";
import { billingStatusReports } from "utils/constants/biling-module";
import {
  findErrorMessage,
  formatBillDateByLocale,
  formatDateByLocale,
  getSortedData,
  getUserName,
  printReport,
  sendEmail
} from "utils/helpers";
import BillingResetModal from "./BillingResetModal";
import BillingStatusRollbackModal from "./BillingStatusRollbackModal";
import { BillingStatusStyles } from "./BillingStatusStyles";
import BillingStatusTable from "./BillingStatusTable";
import ConfirmationModal from "./ConfirmationModal";
import InitiateBillCycleModal from "./InitiateBillCycleModal";

//min date state
const minDate = dayjs(minimumDate);
//today date state
const today = dayjs();
//max date state
const maxDate = dayjs(maximumDate);

const BillingStatus = () => {
  const { currentBranch, auth, localeByBranch } = useSelector(selectAuth);
  const [selectedRow, setSelectedRow] = useState(null);
  const [billRunsForTheBranch, setBillRunsForTheBranch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [isHomeOfficeUser, setIsHomeOfficeUser] = useState(VALUE_N);
  const [billingCycles, setBillingCycles] = useState([]);
  const navigate = useNavigate();
  const [showPrintCountSheetModal, setShowPrintCountSheetModal] =
    useState(false);
  const [showVaultingCountSheetModal, setShowVaultingCountSheetModal] =
    useState(false);
  const [showCountSheetModal, setShowCountSheetModal] = useState(false);
  const [showTransportCountSheetModal, setShowTransportCountSheetModal] =
    useState(false);
  const [showVarianceReportModal, setShowVarianceReportModal] = useState(false);
  const [showZeroCountReportModal, setShowZeroCountReportModal] =
    useState(false);
  const [
    recurringChargeReviewReportModal,
    setRecurringChargeReviewReportModal
  ] = useState(false);
  const [
    openMediaPerHourHandlingReviewReportModal,
    setOpenMediaPerHourHandlingReviewReportModal
  ] = useState(false);
  const [rollbackPopupShow, setRollbackPopupShow] = useState(false);
  const [rollbackPopupShowOTO, setRollbackPopupShowOTO] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [phaseTwoReportDownload, setPhaseTwoReportDownload] = useState([
    { phaseTwoReportOne: false },
    { phaseTwoReportTwo: false },
    { phaseTwoReportThree: false }
  ]);
  const [phaseFiveReportDownload, setPhaseFiveReportDownload] = useState([
    { phaseFiveReportOne: false },
    { phaseFiveReportTwo: false }
  ]);

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const [resetPopupShow, setResetPopupShow] = useState(false);
  const [resetPhase, setResetPhase] = useState({});

  const [initiateNewBillCycle, setInitiateNewBillCycle] = useState(false);
  const [initiateNewBillCyclePopupShow, setInitiateNewBillCyclePopupShow] =
    useState(false);
  const [isTransactionButtonClicked, setIsTransactionButtonClicked] =
    useState(false);
  const [isErrorLogButtonClicked, setIsErrorLogButtonClicked] = useState(false);
  const [isPrintButtonClicked, setIsPrintButtonClicked] = useState(false);
  const [isCompleteButtonClicked, setIsCompleteButtonClicked] = useState(false);
  // print format type
  const [selectedPrintType, setSelectedPrintType] = useState(
    downloadReportOptions[0]
  );
  const [throwReportError, setThrowReportError] = useState(false);
  const [throwReportErrorMessage, setThrowReportErrorMessage] = useState(null);

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showConfirmationMsg, setShowConfirmationMsg] = useState("");

  const { selectedBranch, selectedBillCycle, selectedBillDate } = useParams();

  if (selectedBranch && selectedBillCycle && selectedBillDate) {
    window.history.replaceState(
      {},
      "",
      `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_STATUS}`
    );
  }

  // Billing Status Form Initial Values
  const initialValues = {
    branch: "",
    allBranches: [],
    billingCycles: [],
    billCycle: ""
  };

  // Billing Status Form
  const billingStatusForm = useFormik({
    initialValues: initialValues
  });

  // row click
  const handleRowClick = (rowData, rowState) => {
    setSelectedRow(rowState.dataIndex);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setThrowError(false);
    setThrowErrorMessage("");
  };

  // branch change
  const onBranchChange = (branch) => {
    billingStatusForm?.handleChange({
      target: { name: "branch", value: branch?.target?.value }
    });
    setBillRunsForTheBranch([]);
    billingStatusForm?.handleChange({
      target: { name: "billCycle", value: "" }
    });
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setThrowError(false);
    setThrowErrorMessage("");
    setSelectedRow(null);
  };

  // bill cycle change
  const onBillCycleChange = (cycle) => {
    billingStatusForm?.handleChange({
      target: { name: "billCycle", value: cycle?.target?.value }
    });
    setBillRunsForTheBranch([]);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setThrowError(false);
    setThrowErrorMessage("");
    setSelectedRow(null);
  };

  // bill date change
  const onBillDateChanges = (selectedDate) => {
    billingStatusForm?.handleChange({
      target: {
        name: "billDate",
        value: selectedDate?.target?.value?.value
      }
    });
    setBillRunsForTheBranch([]);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setThrowError(false);
    setThrowErrorMessage("");
    setSelectedRow(null);
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  // handle transaction button click
  const handleTransactionButtonClick = () => {
    let billingDate =
      billingStatusForm?.values?.billDatePicker?.format(ymdDateFormat);
    // if HOLD OTO selected
    if (
      billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
      billCycles?.HOTO
    ) {
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_TRANSACTIONS}/${
          billingStatusForm?.values?.branch?.district_id
        }/${billingStatusForm?.values?.billCycle?.invoice_date_type_id?.trim()}/${
          billingStatusForm?.values?.billDate
        }`
      );
    } else if (
      billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
      billCycles?.EOM
    ) {
      // need to pass branch / bill cycle / bill date
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_TRANSACTIONS}/${
          billingStatusForm?.values?.branch?.district_id
        }/${billingStatusForm?.values?.billCycle?.invoice_date_type_id?.trim()}/${
          billingStatusForm?.values?.billDate
        }`
      );
    } else {
      // need to pass branch / bill cycle / bill date
      if (initiateNewBillCycle) {
        setIsTransactionButtonClicked(true);
        setInitiateNewBillCyclePopupShow(true);
      } else {
        navigate(
          `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_TRANSACTIONS}/${
            billingStatusForm?.values?.branch?.district_id
          }/${billingStatusForm?.values?.billCycle?.invoice_date_type_id?.trim()}/${billingDate}`
        );
      }
    }
  };

  // handle error log button click
  const handleErrorLogButtonClick = () => {
    // should navigate to the billing error log screen
    if (initiateNewBillCycle) {
      setIsErrorLogButtonClicked(true);
      setInitiateNewBillCyclePopupShow(true);
    } else {
      let billingDate =
        billingStatusForm?.values?.billDatePicker?.format(ymdDateFormat);
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_ERROR_LOG}?param=${
          billingStatusForm?.values?.branch?.district_id
        }/${billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim()}/${
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingDate
        }`
      );
    }
  };

  // print button click
  const handlePrintButtonClick = () => {
    if (initiateNewBillCycle) {
      setIsPrintButtonClicked(true);
      setInitiateNewBillCyclePopupShow(true);
    } else {
      setShowPrintModal(true); // enable the print modal show
    }
  };

  // run button functionality
  const handleRunButtonClick = async () => {
    setThrowReportError(false);
    if (selectedRow !== null) {
      setLoading((prev) => true);
      setShowErrorAlert(false);
      setShowErrorAlertMsg("");
      setThrowError(false);
      setThrowErrorMessage("");

      // check steps after selected steps are compleated or not to rolleback
      const completedPhases = await checkPhasesAfterSelectedAreComplete();

      if (completedPhases) {
        setLoading((prev) => false);
        setRollbackPopupShow(true);
        return;
      }

      let phase = billRunsForTheBranch[selectedRow];

      if (phase?.billing_phase_id === billingPhaseIDs?.phaseOne) {
        onPhaseOneRunClik(phase);
      }

      if (phase?.billing_phase_id === billingPhaseIDs?.phaseTwo) {
        const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
        if (prevPhaseStatus) {
          onPhaseTwoRunClik(phase);
        } else {
          setLoading((prev) => false);
        }
      }

      if (phase?.billing_phase_id === billingPhaseIDs?.phaseFour) {
        const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
        if (prevPhaseStatus) {
          onPhaseFourClick(phase);
        } else {
          setLoading((prev) => false);
        }
      }

      if (phase?.billing_phase_id === billingPhaseIDs?.phaseFive) {
        setPhaseFiveReportDownload([
          { phaseFiveReportOne: false },
          { phaseFiveReportTwo: false }
        ]);
        const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
        if (prevPhaseStatus) {
          onPhaseFiveClick(phase);
        } else {
          setLoading((prev) => false);
        }
      }

      if (phase?.billing_phase_id === billingPhaseIDs?.phaseSix) {
        const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
        if (prevPhaseStatus) {
          onPhaseSixClick(phase);
        } else {
          setLoading((prev) => false);
        }
      }

      if (phase?.billing_phase_id === billingPhaseIDs?.phaseSeven) {
        const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
        if (prevPhaseStatus) {
          onPhaseSevenClick(phase);
        } else {
          setLoading((prev) => false);
        }
      }
    }
  };

  // reset button click
  const handleResetButtonClick = () => {
    let inprogressPhases = billRunsForTheBranch?.filter(
      (b) =>
        b?.completed_flag === N_CHECKER &&
        b?.started_date_time &&
        b?.billing_phase_id !== billingPhaseIDs?.phaseFive
    );
    setResetPhase(inprogressPhases[0]);
    setResetPopupShow(true);
  };

  // cancel button click on reset
  const cancelClickReset = async () => {
    setResetPopupShow(false);
    setResetPhase({});
  };

  // ok button click on reset
  const okClickReset = async () => {
    setLoading((prev) => true);
    try {
      const reqBody = {
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        billing_date:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingStatusForm?.values?.billDatePicker?.format(ymdDateFormat),
        billing_phase_id: resetPhase?.billing_phase_id,
        district_id: billingStatusForm?.values?.branch?.district_id,
        file_create_user: auth?.user?.login,
        main_district_id: billingStatusForm?.values?.branch?.district_id
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.billingResetFunctionality}`,
        2
      );

      let resetError = data?.data[1];

      if (resetError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(resetError[0]?.error);
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setResetPopupShow(false);
        setLoading((prev) => false);
        return;
      }

      let updatedDataAfterResetCompletion = data?.data[0];
      updatedDataAfterResetCompletion?.sort((a, b) => {
        return a.billing_phase_id - b.billing_phase_id;
      });
      setBillRunsForTheBranch(updatedDataAfterResetCompletion);
      setResetPopupShow(false);
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setResetPopupShow(false);
      setLoading((prev) => false);
    }
  };

  // cancel button click on eom rollback
  const cancelClickRollback = async () => {
    setRollbackPopupShow(false);
  };

  // ok button click on eom rollback
  const okClickRollback = async () => {
    setRollbackPopupShow(false);

    let phase = billRunsForTheBranch[selectedRow];

    if (phase?.billing_phase_id === billingPhaseIDs?.phaseOne) {
      onPhaseOneRunClik(phase);
    }

    if (phase?.billing_phase_id === billingPhaseIDs?.phaseTwo) {
      const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
      if (prevPhaseStatus) {
        setPhaseTwoReportDownload([
          { phaseTwoReportOne: false },
          { phaseTwoReportTwo: false },
          { phaseTwoReportThree: false }
        ]);
        onPhaseTwoRunClik(phase);
      } else {
        setLoading((prev) => false);
      }
    }

    if (phase?.billing_phase_id === billingPhaseIDs?.phaseFour) {
      const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
      const isStatusComplete =
        phase?.completed_flag === Y_CHECKER ? true : false;
      if (prevPhaseStatus) {
        onPhaseFourClick(phase, isStatusComplete);
      } else {
        setLoading((prev) => false);
      }
    }

    if (phase?.billing_phase_id === billingPhaseIDs?.phaseFive) {
      const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
      if (prevPhaseStatus) {
        onPhaseFiveClick(phase);
      } else {
        setLoading((prev) => false);
      }
    }

    if (phase?.billing_phase_id === billingPhaseIDs?.phaseSix) {
      const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
      if (prevPhaseStatus) {
        onPhaseSixClick(phase);
      } else {
        setLoading((prev) => false);
      }
    }

    if (phase?.billing_phase_id === billingPhaseIDs?.phaseSeven) {
      const prevPhaseStatus = await checkPreviousPhaseIsCompleted();
      if (prevPhaseStatus) {
        onPhaseSevenClick(phase);
      } else {
        setLoading((prev) => false);
      }
    }
  };

  // cancel button click on oto rollback
  const cancelClickRollbackOTO = async () => {
    setRollbackPopupShowOTO(false);
  };

  // ok button click on oto rollback
  const okClickRollbackOTO = async () => {
    setRollbackPopupShowOTO(false);
    setLoading((prev) => true);
    try {
      let phase = billRunsForTheBranch[selectedRow];

      let reqBody = JSON.stringify({
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_date:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingStatusForm?.values?.billDatePicker?.format(ymdDateFormat),
        file_create_user: auth?.user?.login,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        update_start_time_flag: Y_CHECKER,
        completed_flag: phase?.completed_flag,
        timestamp: phase?.timestamp
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphasecomplete}`,
        2
      );

      let phaseHasError = data?.data[1];

      if (
        parseInt(phaseHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
        parseInt(phaseHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
      ) {
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
        setShowErrorAlert(true);
        setLoading((prev) => false);
        return;
      }

      if (phaseHasError[0]?.error === errorMsgs?.errorCode26037) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode26037
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseHasError[0]?.error === errorMsgs?.errorCode20260) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode20260
        );
        let messageArray = errMsg?.errorMsg?.split("|");
        let splitDate =
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? await formatDateByLocale(
                billingStatusForm?.values?.billDate,
                localeByBranch,
                false
              )
            : await formatDateByLocale(
                billingStatusForm?.values?.billDatePicker,
                localeByBranch,
                false
              );
        messageArray?.splice(1, 0, splitDate);
        messageArray?.splice(
          3,
          0,
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim()
        );
        let finalMsg = messageArray.join("");
        finalMsg = finalMsg?.replace(removeingText, "").trim();
        setShowErrorAlert(true);
        setShowErrorAlertMsg(finalMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseHasError[0]?.error === errorMsgs?.errorCode20261) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode20261
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseHasError[0]?.error === errorMsgs?.noError) {
        let updatedDataAfterPhaseOneCompletion = data?.data[0];
        updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });
        setBillRunsForTheBranch(updatedDataAfterPhaseOneCompletion);
        setLoading((prev) => false);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }

      if (
        billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM &&
        phase?.billing_phase_id === billingPhaseIDs?.phaseFive
      ) {
        // send an email
        const isEmailSend = await emailSendingTask();
        if (isEmailSend) {
          setLoading((prev) => false);
          return;
        } else {
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
          setLoading((prev) => false);
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // complete click on phase 3 and 5
  const handleCompleteClick = async () => {
    if (selectedRow !== null) {
      setLoading((prev) => true);
      setShowErrorAlert(false);
      setShowErrorAlertMsg("");
      setThrowError(false);
      setThrowErrorMessage("");

      let phase = billRunsForTheBranch[selectedRow];

      if (
        phase?.billing_phase_id === billingPhaseIDs?.phaseThree ||
        phase?.billing_phase_id === billingPhaseIDs?.phaseFive
      ) {
        if (
          (billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
            billCycles?.OTO &&
            billRunsForTheBranch[5]?.completed_flag === Y_CHECKER) ||
          (billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
            billCycles?.EOM &&
            billRunsForTheBranch[5]?.completed_flag === Y_CHECKER)
        ) {
          const errMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode20233
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setLoading((prev) => false);
          return;
        }

        // check after phases are completed to roll back
        const completedPhases = await checkPhasesAfterSelectedAreComplete();

        // rollback modal show
        if (completedPhases) {
          setLoading((prev) => false);
          setRollbackPopupShowOTO(true);
          return;
        }

        if (
          initiateNewBillCycle &&
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
            billCycles?.OTO
        ) {
          setLoading((prev) => false);
          setIsCompleteButtonClicked(true);
          setInitiateNewBillCyclePopupShow(true);
        } else {
          setLoading((prev) => true);
          try {
            let reqBody = JSON.stringify({
              billing_cycle_code:
                billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
              district_id: billingStatusForm?.values?.branch?.district_id,
              billing_date:
                billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
                billCycles?.EOM
                  ? billingStatusForm?.values?.billDate
                  : dayjs(billingStatusForm?.values?.billDatePicker).format(
                      dateFormatWithoutTime
                    ),
              file_create_user: auth?.user?.login,
              main_district_id: billingStatusForm?.values?.branch?.district_id,
              billing_phase_id: phase?.billing_phase_id,
              update_start_time_flag: Y_CHECKER,
              completed_flag: phase?.completed_flag,
              timestamp: phase?.timestamp
            });

            const data = await getResponseData(
              reqBody,
              `${CF_URLS.billingStatus?.eombillingphasecomplete}`,
              2
            );

            let phaseHasError = data?.data[1]; // error code getting

            if (
              parseInt(phaseHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
              parseInt(phaseHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
            ) {
              setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
              setShowErrorAlert(true);
              setLoading((prev) => false);
              return;
            }

            if (phaseHasError[0]?.error === errorMsgs?.errorCode26037) {
              const errMsg = await getTheFirestoreErrorMessage(
                errorMsgs?.errorCode26037
              );
              setShowErrorAlert(true);
              setShowErrorAlertMsg(errMsg?.errorMsg);
              setLoading((prev) => false);
              return;
            }

            if (phaseHasError[0]?.error === errorMsgs?.errorCode20260) {
              const errMsg = await getTheFirestoreErrorMessage(
                errorMsgs?.errorCode20260
              );
              if (
                billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
                billCycles?.EOM
              ) {
                let messageArray = errMsg?.errorMsg?.split("|");
                let splitDate =
                  billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
                  billCycles?.EOM
                    ? await formatDateByLocale(
                        billingStatusForm?.values?.billDate,
                        localeByBranch,
                        false
                      )
                    : await formatDateByLocale(
                        billingStatusForm?.values?.billDatePicker,
                        localeByBranch,
                        false
                      );
                messageArray?.splice(1, 0, splitDate);
                messageArray?.splice(
                  3,
                  0,
                  billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim()
                );
                let finalMsg = messageArray.join("");
                setShowConfirmationMsg(finalMsg);
                setShowConfirmationPopup(true);
                setLoading((prev) => false);
                return;
              } else if (
                billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
                billCycles?.OTO
              ) {
                let messageArray = errMsg?.errorMsg?.split("|");
                let splitDate =
                  billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
                  billCycles?.EOM
                    ? await formatDateByLocale(
                        billingStatusForm?.values?.billDate,
                        localeByBranch,
                        false
                      )
                    : await formatDateByLocale(
                        billingStatusForm?.values?.billDatePicker,
                        localeByBranch,
                        false
                      );
                messageArray?.splice(1, 0, splitDate);
                messageArray?.splice(
                  3,
                  0,
                  billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim()
                );
                let finalMsg = messageArray.join("");
                finalMsg = finalMsg?.replace(removeingText, "").trim();
                setShowErrorAlert(true);
                setShowErrorAlertMsg(finalMsg);
                setLoading((prev) => false);
                return;
              }
            }

            if (phaseHasError[0]?.error === errorMsgs?.errorCode20261) {
              const errMsg = await getTheFirestoreErrorMessage(
                errorMsgs?.errorCode20261
              );
              setShowErrorAlert(true);
              setShowErrorAlertMsg(errMsg?.errorMsg);
              setLoading((prev) => false);
              return;
            }

            if (phaseHasError[0]?.error === errorMsgs?.noError) {
              let updatedDataAfterPhaseOneCompletion = data?.data[0];
              updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
                return a.billing_phase_id - b.billing_phase_id;
              });
              setBillRunsForTheBranch(updatedDataAfterPhaseOneCompletion);
              setLoading((prev) => false);
            } else if (data?.error) {
              setThrowErrorMessage(data.error);
              setThrowError(true);
              setLoading((prev) => false);
            }

            if (
              billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
                billCycles?.EOM &&
              phase?.billing_phase_id === billingPhaseIDs?.phaseFive
            ) {
              // send an email
              const isEmailSend = await emailSendingTask();
              if (isEmailSend) {
                setLoading((prev) => false);
                return;
              } else {
                setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
                setThrowError(true);
                setLoading((prev) => false);
              }
            }
            setLoading((prev) => false);
          } catch (error) {
            setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            setThrowError(true);
            setLoading((prev) => false);
          }
        }
      }
    }
  };

  const cancelClickOnConfirmation = async () => {
    const rollbackValues = await billingStatusRollback(
      billingPhaseIDs?.phaseThree
    );
    setBillRunsForTheBranch(rollbackValues);
    setShowConfirmationPopup(false);
    setShowConfirmationMsg("");
  };

  const okClickOnConfirmation = async () => {
    setLoading((prev) => true);
    try {
      const phase = billRunsForTheBranch[selectedRow];

      const reqBody = JSON.stringify({
        completed_flag: Y_CHECKER,
        file_create_user: auth?.user?.login,
        billing_date: billingStatusForm?.values?.billDate,
        completed_date_time: phase?.completed_date_time,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        elapsed_time: elapsedTime,
        update_start_time_flag: Y_CHECKER
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.billingResetPhase3okClick}`,
        2
      );

      let phaseHasError = data?.data[1]; // error code getting

      if (phaseHasError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          phaseHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      let updatedDataAfterPhaseOneCompletion = data?.data[0];
      updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
        return a.billing_phase_id - b.billing_phase_id;
      });
      setBillRunsForTheBranch(updatedDataAfterPhaseOneCompletion);
      setShowConfirmationPopup(false);
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // email sending task
  const emailSendingTask = async () => {
    setLoading((prev) => true);
    try {
      const sendMailAddresses = await getSendEmailAddress();

      const mailBody = `${billingStatusForm?.values?.branch?.code} ${t(
        "billingStatus.mailBodyPartOne"
      )} ${billingStatusForm?.values?.billDate} ${t(
        "billingStatus.mailBodyPartTwo"
      )}`;

      const mailSubject = `${t("billingStatus.mailSubject")}`;

      const response = await sendEmail(
        sendMailAddresses,
        mailBody,
        mailSubject
      );

      if (response?.data?.isSuccess) {
        // email sent
        setLoading((prev) => false);
        return true;
      } else {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
        setLoading((prev) => false);
        return false;
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // get email address to send the mail
  const getSendEmailAddress = async () => {
    try {
      setLoading((prev) => true);
      let reqBody = {
        file_create_user: auth?.user?.login,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        district_id: billingStatusForm?.values?.branch?.district_id
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.billingcoordinatormail}`,
        2
      );

      let getEmailHasError = data?.data[1];

      if (getEmailHasError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          getEmailHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (getEmailHasError[0]?.error === errorMsgs?.noError) {
        let custName = await getCustomName();

        let emailAddresses = data?.data[0];
        let emailObj = emailAddresses?.map((mail) => {
          return {
            emailAddress: mail?.address,
            name: custName[0]
          };
        });
        return emailObj;
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // check the phases ater the selected phases are completed to show rollback msg
  const checkPhasesAfterSelectedAreComplete = () => {
    const completedPhasesAfterSelectedPhase = billRunsForTheBranch?.filter(
      (item) =>
        item?.billing_phase_id >
          billRunsForTheBranch[selectedRow]?.billing_phase_id &&
        item.completed_flag === Y_CHECKER
    );

    if (completedPhasesAfterSelectedPhase?.length === 0) {
      if (billRunsForTheBranch[selectedRow]?.completed_flag === Y_CHECKER) {
        return 1;
      }
    }

    return completedPhasesAfterSelectedPhase?.length;
  };

  // check if previous phase is completed or not befor run the phase (except 1 phase)
  const checkPreviousPhaseIsCompleted = () => {
    let phase = billRunsForTheBranch[selectedRow];
    if (
      (phase?.billing_phase_id === billingPhaseIDs?.phaseOne &&
        billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM) ||
      (phase?.billing_phase_id === billingPhaseIDs?.phaseThree &&
        billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.OTO)
    ) {
      return;
    }

    return billRunsForTheBranch[selectedRow - 1]?.completed_flag === Y_CHECKER
      ? true
      : false;
  };

  // phase 1 run
  const onPhaseOneRunClik = async (phase) => {
    setLoading((prev) => true);
    try {
      let reqBody = JSON.stringify({
        completed_flag: phase?.completed_flag
          ? phase?.completed_flag
          : N_CHECKER,
        file_create_user: auth?.user?.login,
        billing_date: billingStatusForm?.values?.billDate,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        update_start_time_flag: Y_CHECKER,
        timestamp: phase?.timestamp
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphase1}`,
        2
      );

      if (resetPopupShow) {
        setResetPopupShow(false);
        setResetPhase({});
      }

      let phaseOneHasError = data?.data[1];

      if (phaseOneHasError?.length === 1) {
        if (
          parseInt(phaseOneHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
          parseInt(phaseOneHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
        ) {
          setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
          setShowErrorAlert(true);
          setLoading((prev) => false);
          return;
        }

        if (phaseOneHasError[0]?.error !== errorMsgs?.noError) {
          const errMsg = await getTheFirestoreErrorMessage(
            phaseOneHasError[0]?.error
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setLoading((prev) => false);
          return;
        }
      } else {
        let allErrors = [];
        const errorMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode26039
        );
        allErrors.push(`${errorMsg?.errorMsg} `);
        for (let index = 0; index < phaseOneHasError.length; index++) {
          const errorCodes = phaseOneHasError[index];
          const errMsg = await getTheFirestoreErrorMessage(errorCodes?.error);
          allErrors.push(`${errMsg?.errorMsg} `);
        }
        setShowErrorAlert(true);
        setShowErrorAlertMsg(allErrors);
        setLoading((prev) => false);
        return;
      }

      if (phaseOneHasError[0]?.error === errorMsgs?.noError) {
        let updatedDataAfterPhaseOneCompletion = data?.data[0];
        updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });
        setBillRunsForTheBranch(updatedDataAfterPhaseOneCompletion);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // phase 2 run
  const onPhaseTwoRunClik = async (phase) => {
    setLoading((prev) => true);
    try {
      let reqBody = JSON.stringify({
        completed_flag: phase?.completed_flag
          ? phase?.completed_flag
          : N_CHECKER,
        file_create_user: auth?.user?.login,
        billing_date: billingStatusForm?.values?.billDate,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        update_start_time_flag: Y_CHECKER,
        timestamp: phase?.timestamp
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphase2}`,
        2
      );

      if (resetPopupShow) {
        setResetPopupShow(false);
        setResetPhase({});
      }

      let phaseTowHasError = data?.data[1];

      if (
        parseInt(phaseTowHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
        parseInt(phaseTowHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
      ) {
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
        setShowErrorAlert(true);
        setLoading((prev) => false);
        return;
      }

      if (phaseTowHasError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          phaseTowHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseTowHasError[0]?.error === errorMsgs?.noError) {
        let updatedDataAfterPhaseTwoCompletion = data?.data[0];
        updatedDataAfterPhaseTwoCompletion?.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });
        setBillRunsForTheBranch(updatedDataAfterPhaseTwoCompletion);
        setShowPrintCountSheetModal(true);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // phase 4 run
  const onPhaseFourClick = async (phase, isStatusComplete) => {
    setLoading((prev) => true);
    try {
      let reqBody = JSON.stringify({
        completed_flag: phase?.completed_flag
          ? phase?.completed_flag
          : N_CHECKER,
        file_create_user: auth?.user?.login,
        billing_date:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingStatusForm?.values?.billDatePicker,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        update_start_time_flag: Y_CHECKER,
        validate_job_flag: Y_CHECKER,
        timestamp: phase?.timestamp
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphase4}`,
        2
      );

      if (resetPopupShow) {
        setResetPopupShow(false);
        setResetPhase({});
      }

      let phaseFourHasError = data?.data[1];

      if (
        parseInt(phaseFourHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
        parseInt(phaseFourHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
      ) {
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
        setShowErrorAlert(true);
        setLoading((prev) => false);
        return;
      }

      if (phaseFourHasError[0]?.error === errorMsgs?.errorCode26037) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode26037
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseFourHasError[0]?.error === errorMsgs?.errorCode25346) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode25346
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseFourHasError[0]?.error > errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          phaseFourHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        // if not eom we need to bind the data
        let updatedDataAfterPhaseFourCompletion = data?.data[0];
        if (updatedDataAfterPhaseFourCompletion?.length) {
          updatedDataAfterPhaseFourCompletion?.sort((a, b) => {
            return a.billing_phase_id - b.billing_phase_id;
          });
          setBillRunsForTheBranch(updatedDataAfterPhaseFourCompletion);
        }
        setLoading((prev) => false);
        return;
      }

      if (phaseFourHasError[0]?.error === errorMsgs?.noError) {
        if (
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
        ) {
          // print reports
          isStatusComplete
            ? setOpenMediaPerHourHandlingReviewReportModal(true)
            : setRecurringChargeReviewReportModal(true);
        }

        // if not eom we need to bind the data
        let updatedDataAfterPhaseFourCompletion = data?.data[0];
        updatedDataAfterPhaseFourCompletion?.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });
        setBillRunsForTheBranch(updatedDataAfterPhaseFourCompletion);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // phase 5 run
  const onPhaseFiveClick = async (phase) => {
    setLoading((prev) => true);
    try {
      let reqBody = JSON.stringify({
        completed_flag: phase?.completed_flag
          ? phase?.completed_flag
          : N_CHECKER,
        file_create_user: auth?.user?.login,
        billing_date: billingStatusForm?.values?.billDate,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        update_start_time_flag: Y_CHECKER,
        timestamp: phase?.timestamp
      });

      const data1 = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphase5}`,
        2
      );

      if (resetPopupShow) {
        setResetPopupShow(false);
        setResetPhase({});
      }

      let phaseFiveHasError = data1?.data[1];

      if (
        parseInt(phaseFiveHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
        parseInt(phaseFiveHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
      ) {
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
        setShowErrorAlert(true);
        setLoading((prev) => false);
        return;
      }

      if (phaseFiveHasError[0]?.error === errorMsgs?.errorCode26037) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode26037
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseFiveHasError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          phaseFiveHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseFiveHasError[0]?.error === errorMsgs?.noError) {
        setShowVarianceReportModal(true);
      } else if (data1?.error) {
        setThrowErrorMessage(data1.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // phase 6 run
  const onPhaseSixClick = async (phase) => {
    setLoading((prev) => true);
    try {
      let reqBody = JSON.stringify({
        completed_flag: phase?.completed_flag
          ? phase?.completed_flag
          : N_CHECKER,
        file_create_user: auth?.user?.login,
        billing_date:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingStatusForm?.values?.billDatePicker,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        timestamp: phase?.timestamp
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphase6}`,
        2
      );

      if (resetPopupShow) {
        setResetPopupShow(false);
        setResetPhase({});
      }

      let phaseSixHasError = data?.data[1];
      if (
        parseInt(phaseSixHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
        parseInt(phaseSixHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
      ) {
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
        setShowErrorAlert(true);
        setLoading((prev) => false);
        return;
      }

      if (phaseSixHasError[0]?.error === errorMsgs?.errorCode26037) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode26037
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseSixHasError[0]?.error === errorMsgs?.errorCode20260) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode20260
        );

        let messageArray = errMsg?.errorMsg?.split("|");
        let splitDate =
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? await formatDateByLocale(
                billingStatusForm?.values?.billDate,
                localeByBranch,
                false
              )
            : await formatDateByLocale(
                billingStatusForm?.values?.billDatePicker,
                localeByBranch,
                false
              );
        messageArray?.splice(1, 0, splitDate);
        messageArray?.splice(
          3,
          0,
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim()
        );
        let finalMsg = messageArray.join("");
        finalMsg = finalMsg?.replace(removeingText, "").trim();
        setShowErrorAlert(true);
        setShowErrorAlertMsg(finalMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseSixHasError[0]?.error === errorMsgs?.errorCode20261) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode20261
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseSixHasError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          phaseSixHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseSixHasError[0]?.error === errorMsgs?.noError) {
        let updatedDataAfterPhaseOneCompletion = data?.data[0];
        updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });
        setBillRunsForTheBranch(updatedDataAfterPhaseOneCompletion);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }

      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // phase 7 run
  const onPhaseSevenClick = async (phase) => {
    setLoading((prev) => true);
    try {
      let reqBody = JSON.stringify({
        completed_flag: phase?.completed_flag
          ? phase?.completed_flag
          : N_CHECKER,
        file_create_user: auth?.user?.login,
        billing_date:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingStatusForm?.values?.billDatePicker,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        timestamp: phase?.timestamp
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphase7}`,
        2
      );

      if (resetPopupShow) {
        setResetPopupShow(false);
        setResetPhase({});
      }

      let phaseOneHasError = data?.data[1];

      if (
        parseInt(phaseOneHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
        parseInt(phaseOneHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
      ) {
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
        setShowErrorAlert(true);
        setLoading((prev) => false);
        return;
      }

      if (phaseOneHasError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          phaseOneHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseOneHasError[0]?.error === errorMsgs?.noError) {
        let updatedDataAfterPhaseOneCompletion = data?.data[0];
        updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });
        setBillRunsForTheBranch(updatedDataAfterPhaseOneCompletion);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // rollback sp call
  const billingStatusRollback = async (phaseId) => {
    setLoading((prev) => true);
    try {
      let reqBody = {
        completed_flag: N_CHECKER,
        file_create_user: auth?.user?.login,
        billing_date: billingStatusForm?.values?.billDate,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phaseId
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphaserollback}`,
        2
      );

      let rollbackHasAIssue = data?.data[1];

      if (
        parseInt(rollbackHasAIssue[0]?.error) >= errorMsgs?.sqlErrorStart &&
        parseInt(rollbackHasAIssue[0]?.error) <= errorMsgs?.sqlErrorEnd
      ) {
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
        setShowErrorAlert(true);
        setLoading((prev) => false);
        return;
      }

      if (rollbackHasAIssue[0]?.error === errorMsgs?.noError) {
        let updatedDataAfterPhaseOneCompletion = data?.data[0];
        updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });
        setLoading((prev) => false);
        return updatedDataAfterPhaseOneCompletion;
      }

      if (rollbackHasAIssue[0]?.error !== errorMsgs?.noError) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // button visibility start
  // find button
  const isFindButtonDisabled = () => {
    if (
      !billingStatusForm?.values?.branch ||
      !billingStatusForm?.values?.billCycle ||
      billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
        billCycles?.HOTO
    ) {
      return true;
    }
    return false;
  };

  // run button
  const isRunButtonDisabled = () => {
    let phaseSix = billRunsForTheBranch?.find(
      (b) => b?.billing_phase_id === billingPhaseIDs?.phaseSix
    ); // get phase six details
    let phase = billRunsForTheBranch[selectedRow]; // selected phase
    let previousPhase = billRunsForTheBranch[selectedRow - 1]; // phase before the selected phase
    let phaseSeven = billRunsForTheBranch?.find(
      (b) => b?.billing_phase_id === billingPhaseIDs?.phaseSeven
    );

    if (phaseSeven?.completed_flag === Y_CHECKER) {
      return true;
    }

    if (phase?.billing_phase_id === billingPhaseIDs?.phaseThree) {
      return true;
    }

    if (
      previousPhase?.completed_flag === N_CHECKER ||
      previousPhase?.completed_flag === VALUE_EMPTY_STRING
    ) {
      return true;
    }

    if (
      phase?.billing_phase_id === billingPhaseIDs?.phaseSeven &&
      phase?.completed_flag === N_CHECKER &&
      phaseSix?.completed_flag === Y_CHECKER
    ) {
      return false;
    }

    if (phaseSix?.completed_flag === Y_CHECKER) {
      return true;
    }

    if (
      phase?.billing_phase_id === billingPhaseIDs?.phaseFive &&
      phase?.completed_flag === Y_CHECKER
    ) {
      return false;
    }

    if (selectedRow === null) {
      return true;
    }

    return false;
  };

  // complete button
  const isCompleteButtonDisabled = () => {
    let phaseSix = billRunsForTheBranch?.find(
      (b) => b?.billing_phase_id === billingPhaseIDs?.phaseSix
    ); // get phase six details
    let phase = billRunsForTheBranch[selectedRow]; // selected phase
    let previousPhase = billRunsForTheBranch[selectedRow - 1]; // phase before the selected phase

    if (
      phase?.billing_phase_id === billingPhaseIDs?.phaseOne ||
      phase?.billing_phase_id === billingPhaseIDs?.phaseTwo ||
      phase?.billing_phase_id === billingPhaseIDs?.phaseFour ||
      phase?.billing_phase_id === billingPhaseIDs?.phaseSix ||
      phase?.billing_phase_id === billingPhaseIDs?.phaseSeven
    ) {
      return true;
    }

    if (
      phase?.billing_phase_id === billingPhaseIDs?.phaseFive &&
      phase?.completed_flag === Y_CHECKER
    ) {
      return true;
    }

    if (
      phase?.billing_phase_id === billingPhaseIDs?.phaseFive &&
      phase?.completed_date_time === ""
    ) {
      return true;
    }

    if (previousPhase?.completed_flag === N_CHECKER) {
      return true;
    }

    if (
      phaseSix?.billing_phase_id === billingPhaseIDs?.phaseSix &&
      phaseSix?.completed_flag === Y_CHECKER
    ) {
      return true;
    }

    if (selectedRow === null) {
      return true;
    }

    return false;
  };

  // transaction button
  const isTransactionButtonDisabled = () => {
    let phaseTwo = billRunsForTheBranch?.find(
      (b) => b?.billing_phase_id === billingPhaseIDs?.phaseTwo
    );
    let phaseSeven = billRunsForTheBranch?.find(
      (b) => b?.billing_phase_id === billingPhaseIDs?.phaseSeven
    );

    if (
      billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
        billCycles?.OTO &&
      billRunsForTheBranch?.length
    ) {
      return false;
    }

    if (phaseSeven?.completed_flag === Y_CHECKER) {
      return true;
    }

    if (phaseTwo?.completed_flag === Y_CHECKER) {
      return false;
    }

    if (
      billingStatusForm?.values?.billCycle?.invoice_date_type_id ===
      billCycleIDs?.HOTO
    ) {
      return false;
    }

    return true;
  };

  // Error Log button
  const isErrorLogButtonDisabled = () => {
    let phaseTwo = billRunsForTheBranch?.find(
      (b) => b?.billing_phase_id === billingPhaseIDs?.phaseTwo
    );
    let phaseSeven = billRunsForTheBranch?.find(
      (b) => b?.billing_phase_id === billingPhaseIDs?.phaseSeven
    );

    if (
      billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
        billCycles?.OTO &&
      billRunsForTheBranch?.length
    ) {
      return false;
    }

    if (phaseSeven?.completed_flag === Y_CHECKER) {
      return true;
    }

    if (phaseTwo?.completed_flag === Y_CHECKER) {
      return false;
    }

    return true;
  };

  // reset button
  const isResetButtonDisabled = () => {
    let inprogressPhases = billRunsForTheBranch?.filter(
      (b) =>
        b?.completed_flag === N_CHECKER &&
        b?.started_date_time &&
        b?.billing_phase_id !== billingPhaseIDs?.phaseFive
    );

    if (inprogressPhases?.length) {
      return false;
    }

    return true;
  };
  // button visibility end

  // Fetch Billing Initial Information
  const getBillingInitialData = async () => {
    try {
      setLoading((prev) => true);
      //load the billing info
      let requestBody = JSON.stringify({
        main_district_id: currentBranch?.value
      });

      const data = await getResponseData(
        requestBody,
        `${CF_URLS.billingStatus?.getbillinginfoexcludeholdoto}`,
        1
      );

      let branchData = data?.data[0];
      if (branchData) {
        // sort the branch data
        let sortedData = getSortedData(
          branchData,
          branchSortOption,
          branchSortOrder
        );
        const branchOptions = sortedData?.map((branch) => ({
          ...branch,
          value: branch.district_id,
          label: branch.code
        }));

        // store all the branches
        setAllBranches(branchOptions);
        billingStatusForm?.handleChange({
          target: { name: "allBranches", value: branchOptions }
        });
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // Billing Interface Logon Information
  const getBillingInterfaceLogonInformation = async () => {
    try {
      setLoading((prev) => false);

      let requestBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        login: auth?.user?.login
      });

      const data = await getResponseData(
        requestBody,
        `${CF_URLS.billingStatus?.billinginterfacelogon}`,
        1
      );

      const logOnDetails = data?.data[0];

      if (logOnDetails) {
        // saving the home office user value
        setIsHomeOfficeUser(logOnDetails[0]?.valid);
        billingStatusForm?.handleChange({
          target: { name: "isHomeOfficeUser", value: logOnDetails[0]?.valid }
        });
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // Loading Billing cycles based on the branch
  const getBillingCycles = async () => {
    try {
      setLoading((prev) => true);
      var requestBody = JSON.stringify({
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        district_id: billingStatusForm?.values?.branch?.district_id
      });

      const data = await getResponseData(
        requestBody,
        `${CF_URLS?.billingStatus?.execbillingcyclel1bydistrict}`,
        1
      );

      const billCycles = data?.data[0];

      if (billCycles) {
        const billCycleOptions = billCycles?.map((bill) => {
          return {
            ...bill,
            value: bill?.billing_cycle_code?.trim(),
            label: bill?.descr?.trim(),
            billing_cycle_code: bill?.billing_cycle_code?.trim()
          };
        });

        setBillingCycles(billCycles);
        billingStatusForm?.handleChange({
          target: { name: "billingCycles", value: billCycleOptions }
        });
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // Get billing run dates for eom and set the default date as this month last date
  const getBillingRunDates = async () => {
    try {
      setLoading((prev) => true);
      var requestBody = JSON.stringify({
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim()
      });

      const data = await getResponseData(
        requestBody,
        `${CF_URLS?.billingStatus?.executebillingrunl2}`,
        1
      );

      const billingPassedDates = data?.data[0];

      if (billingPassedDates) {
        const formattedBillingDates = billingPassedDates?.map((billingDate) => {
          let bDate = billingDate.billing_date?.split("T")[0];
          let year = bDate?.split("-")[0];
          let month = bDate?.split("-")[1];
          let day = bDate?.split("-")[2];
          const value = `${year}-${month}-${day}`;
          const label = `${year}/${month}/${day}`;
          return { value, label };
        });

        const billDateUpcommingOptions = calculateBillDate().map(
          (billDate, i) => ({
            key: i,
            value: billDate.value,
            label: billDate.label
          })
        );

        const monthsForBillingDates = [
          ...formattedBillingDates,
          ...billDateUpcommingOptions
        ]?.sort((a, b) => {
          // Convert the date strings to Date objects
          const dateA = new Date(a.value);
          const dateB = new Date(b.value);

          // Compare the Date objects
          return dateA - dateB;
        });

        let datesFormatted = await formatBillDateByLocale(
          monthsForBillingDates,
          localeByBranch,
          false
        );

        billingStatusForm?.setFieldValue("billingDates", datesFormatted);

        // Get current date
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1

        // // Find object for current month's date
        const currentMonthObject = monthsForBillingDates?.find((m) => {
          const [year, month] = m.value.split("-");
          return Number(year) === currentYear && Number(month) === currentMonth;
        });
        billingStatusForm?.setFieldValue("billDate", currentMonthObject?.value);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // create the up coming billing dates for eom
  const calculateBillDate = () => {
    const today = new Date();
    const options = [];
    for (let i = 0; i < 12; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() + i + 1, 0);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has 2 digits
      const day = date.getDate().toString().padStart(2, "0"); // Ensure day has 2 digits
      options.push({
        value: `${year}-${month}-${day}`,
        label: `${year}/${month}/${day}`
      });
    }
    return options;
  };

  // Find button click
  const onFindButtonClick = async () => {
    try {
      setLoading((prev) => true);
      setShowErrorAlert(false);
      setShowErrorAlertMsg("");
      setThrowError(false);
      setThrowErrorMessage("");
      let reqBody = JSON.stringify({
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        billing_date:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingStatusForm?.values?.billDatePicker.format(ymdDateFormat),
        district_id: billingStatusForm?.values?.branch?.district_id
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.billingstatusphasefind}`,
        2
      );

      let findClickIssues = data?.data[1];
      let localDate = data?.data[0];

      if (findClickIssues[0]?.error === errorMsgs?.errorCode20264) {
        const errMsg = await getTheFirestoreErrorMessage(
          findClickIssues[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (findClickIssues[0]?.error > errorMsgs?.noError) {
        let splitDate = await formatDateByLocale(
          localDate[0]?.active_billing_date,
          localeByBranch,
          false
        );
        const errMsg = await getTheFirestoreErrorMessage(
          findClickIssues[0]?.error
        );
        let msg = errMsg?.errorMsg?.replace("|", `${splitDate}`);
        setShowErrorAlert(true);
        setShowErrorAlertMsg(msg);
        setLoading((prev) => false);
        return;
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
        return;
      }
      let sortedRunDetails = data?.data[0]?.sort((a, b) => {
        return a.billing_phase_id - b.billing_phase_id;
      });

      if (
        billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
        billCycles?.OTO
      ) {
        let initiateNewBillCycle = sortedRunDetails?.filter(
          (c) => c?.completed_flag === "N" || c?.completed_flag === ""
        );
        if (initiateNewBillCycle?.length === 4) {
          setInitiateNewBillCycle(true);
        }
      }

      setBillRunsForTheBranch(sortedRunDetails);
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // fetch billing runs for the selected bill cycle and date
  const getBillingRuns = async () => {
    try {
      let requestBody = JSON.stringify({
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        bill_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        bill_date: billingStatusForm?.values?.billDate,
        district_id: billingStatusForm?.values?.branch?.district_id
      });

      const data = await getResponseData(
        requestBody,
        `${CF_URLS.billingStatus?.checkbillrunphasel1}`,
        1
      );

      let billRunDetails = data?.data[0];
      if (billRunDetails) {
        // sort the details based on billing_phase_id
        let sortedRunDetails = billRunDetails.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });

        return sortedRunDetails;
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  // download reports in different phases start
  const onCancelClickInDownloadCountSheets = async () => {
    setShowPrintCountSheetModal(false);
    setShowVaultingCountSheetModal(true);
  };

  const onDownloadClickInDownloadCountSheets = async () => {
    let response = await handleDownloadReport(
      billingStatusReports.handlingCounts
    );
    if (response.success) {
      const val = phaseTwoReportDownload?.map((report) => {
        const key = Object.keys(report)[0];
        if (key === "phaseTwoReportOne") {
          return { ...report, phaseTwoReportOne: true };
        }
        return report;
      });
      setPhaseTwoReportDownload(val);
    }
  };

  const onCancelClickInDownloadVaultingCountSheets = async () => {
    setShowVaultingCountSheetModal(false);
    setShowCountSheetModal(true);
  };

  const onDownloadClickInDownloadVaultingCountSheets = async () => {
    let response = await handleDownloadReport(
      billingStatusReports.vaultingCounts
    );
    if (response.success) {
      const val = phaseTwoReportDownload?.map((report) => {
        const key = Object.keys(report)[0];
        if (key === "phaseTwoReportTwo") {
          return { ...report, phaseTwoReportTwo: true };
        }
        return report;
      });
      setPhaseTwoReportDownload(val);
    }
  };

  const onCancelClickInCountSheets = async () => {
    if (
      phaseTwoReportDownload[0]?.phaseTwoReportOne ||
      phaseTwoReportDownload[1]?.phaseTwoReportTwo ||
      phaseTwoReportDownload[2]?.phaseTwoReportThree
    ) {
      setShowTransportCountSheetModal(true);
    } else {
      const rollbackValues = await billingStatusRollback(
        billingPhaseIDs?.phaseTwo
      );
      setBillRunsForTheBranch(rollbackValues);
    }
    setShowCountSheetModal(false);
  };

  const onDownloadClickInCountSheets = async () => {
    let response = await handleDownloadReport(billingStatusReports.countSheet);
    if (response.success) {
      const val = phaseTwoReportDownload?.map((report) => {
        const key = Object.keys(report)[0];
        if (key === "phaseTwoReportThree") {
          return { ...report, phaseTwoReportThree: true };
        }
        return report;
      });
      setPhaseTwoReportDownload(val);
    }
  };

  const onCancelClickInTransportCountSheets = async () => {
    setShowTransportCountSheetModal(false);
  };

  const onDownloadClickInTransportCountSheets = async () => {
    await handleDownloadReport(billingStatusReports.transCountSheet);
  };

  const checkPhaseFiveStatus = async () => {
    setLoading(true);
    if (
      phaseFiveReportDownload[0]?.phaseFiveReportOne ||
      phaseFiveReportDownload[1]?.phaseFiveReportTwo
    ) {
      let phase = billRunsForTheBranch[selectedRow];

      let reqBody = JSON.stringify({
        completed_flag: phase?.completed_flag,
        file_create_user: auth?.user?.login,
        billing_date: billingStatusForm?.values?.billDate,
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        district_id: billingStatusForm?.values?.branch?.district_id,
        billing_phase_id: phase?.billing_phase_id,
        update_start_time_flag: Y_CHECKER,
        message_id: errorMsgs?.errorCode20224,
        timestamp: phase?.timestamp
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.eombillingphase5step3}`,
        2
      );

      let phaseFiveStepThreeHasError = data?.data[1];

      if (phaseFiveStepThreeHasError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          phaseFiveStepThreeHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (phaseFiveStepThreeHasError[0]?.error === errorMsgs?.noError) {
        let updatedDataAfterPhaseOneCompletion = data?.data[0];
        updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
          return a.billing_phase_id - b.billing_phase_id;
        });
        setBillRunsForTheBranch(updatedDataAfterPhaseOneCompletion);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } else {
      const rollbackValues = await billingStatusRollback(
        billingPhaseIDs?.phaseFive
      );
      setBillRunsForTheBranch(rollbackValues);
    }
    setLoading(false);
  };

  const onCancelClickInVarianceReport = async () => {
    if (phaseFiveReportDownload[0]?.phaseFiveReportOne) {
      setShowZeroCountReportModal(true);
    } else {
      await checkPhaseFiveStatus();
    }
    setShowVarianceReportModal(false);
  };

  const onDownloadClickInVarianceReport = async () => {
    let response = await handleDownloadReport(
      billingStatusReports.billingVariance
    );
    if (response.success) {
      const val = phaseFiveReportDownload?.map((report) => {
        const key = Object.keys(report)[0];
        if (key === "phaseFiveReportOne") {
          return { ...report, phaseFiveReportOne: true };
        }
        return report;
      });
      setPhaseFiveReportDownload(val);
    }
  };

  const onCancelClickInZeroCountReport = async () => {
    await checkPhaseFiveStatus();
    setShowZeroCountReportModal(false);
  };

  const onDownloadClickInZeroCountReport = async () => {
    let response = await handleDownloadReport(billingStatusReports.zeroCount);
    if (response.success) {
      const val = phaseFiveReportDownload?.map((report) => {
        const key = Object.keys(report)[0];
        if (key === "phaseFiveReportTwo") {
          return { ...report, phaseFiveReportTwo: true };
        }
        return report;
      });
      setPhaseFiveReportDownload(val);
    }
  };

  const onCancelClickInRecurringChargeReviewReport = async () => {
    setRecurringChargeReviewReportModal(false);
    setOpenMediaPerHourHandlingReviewReportModal(true);
  };

  const onDownloadClickInRecurringChargeReviewReport = async () => {
    await handleDownloadReport(billingStatusReports.recurringChargeReview);
  };

  const onCancelClickInOpenMediaPerHourHandlingReviewReport = async () => {
    setOpenMediaPerHourHandlingReviewReportModal(false);
  };

  const onDownloadClickInOpenMediaPerHourHandlingReviewReport = async () => {
    let response = await handleDownloadReport(
      billingStatusReports.openMediaHandlingReview
    );
    if (response.success) {
      const billingRuns = await getBillingRuns();
      setBillRunsForTheBranch(billingRuns);
    }
  };
  // download reports in different phases end

  // call to cloud function and get data from firestore
  const getResponseData = async (requestBody, url, count) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(
      response,
      count,
      response.data.docId
    );
    return data;
  };

  const yesClickInitiateBillCycle = async () => {
    setInitiateNewBillCycle(false);
    setInitiateNewBillCyclePopupShow(false);

    let phase = billRunsForTheBranch[selectedRow];
    let billingDate =
      billingStatusForm?.values?.billDatePicker?.format(ymdDateFormat);

    if (isTransactionButtonClicked) {
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_TRANSACTIONS}/${
          billingStatusForm?.values?.branch?.district_id
        }/${billingStatusForm?.values?.billCycle?.invoice_date_type_id?.trim()}/${billingDate}`
      );
    }

    if (isErrorLogButtonClicked) {
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_ERROR_LOG}?param=${
          billingStatusForm?.values?.branch?.district_id
        }/${billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim()}/${
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingDate
        }`
      );
    }

    if (isPrintButtonClicked) {
      setShowPrintModal(true); // enable the print modal show
    }

    if (isCompleteButtonClicked) {
      setLoading((prev) => true);
      try {
        let reqBody = JSON.stringify({
          billing_cycle_code:
            billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
          district_id: billingStatusForm?.values?.branch?.district_id,
          billing_date:
            billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
            billCycles?.EOM
              ? billingStatusForm?.values?.billDate
              : billingStatusForm?.values?.billDatePicker,
          file_create_user: auth?.user?.login,
          main_district_id: billingStatusForm?.values?.branch?.district_id,
          billing_phase_id: phase?.billing_phase_id,
          update_start_time_flag: Y_CHECKER,
          completed_flag: N_CHECKER,
          timestamp: phase?.timestamp
        });

        const data = await getResponseData(
          reqBody,
          `${CF_URLS.billingStatus?.eombillingphasecomplete}`,
          2
        );

        if (resetPopupShow) {
          setResetPopupShow(false);
          setResetPhase({});
        }

        let phaseHasError = data?.data[1]; // get error code

        if (
          parseInt(phaseHasError[0]?.error) >= errorMsgs?.sqlErrorStart &&
          parseInt(phaseHasError[0]?.error) <= errorMsgs?.sqlErrorEnd
        ) {
          setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.SQLERROR));
          setShowErrorAlert(true);
          setLoading((prev) => false);
          return;
        }

        if (phaseHasError[0]?.error === errorMsgs?.errorCode26037) {
          const errMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode26037
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setLoading((prev) => false);
          return;
        }

        if (phaseHasError[0]?.error === errorMsgs?.errorCode20260) {
          const errMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode20260
          );
          let messageArray = errMsg?.errorMsg?.split("|");
          let splitDate =
            billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
            billCycles?.EOM
              ? await formatDateByLocale(
                  billingStatusForm?.values?.billDate,
                  localeByBranch,
                  false
                )
              : await formatDateByLocale(
                  billingStatusForm?.values?.billDatePicker,
                  localeByBranch,
                  false
                );
          messageArray?.splice(1, 0, splitDate);
          messageArray?.splice(
            3,
            0,
            billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim()
          );
          let finalMsg = messageArray.join("");
          finalMsg = finalMsg?.replace(removeingText, "").trim();
          setShowErrorAlert(true);
          setShowErrorAlertMsg(finalMsg);
          setLoading((prev) => false);
          return;
        }

        if (phaseHasError[0]?.error === errorMsgs?.errorCode20261) {
          const errMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode20261
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setLoading((prev) => false);
          return;
        }

        if (phaseHasError[0]?.error !== errorMsgs?.noError) {
          const errMsg = await getTheFirestoreErrorMessage(
            phaseHasError[0]?.error
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setLoading((prev) => false);
          return;
        }

        if (phaseHasError[0]?.error === errorMsgs?.noError) {
          let updatedDataAfterPhaseOneCompletion = data?.data[0];
          updatedDataAfterPhaseOneCompletion?.sort((a, b) => {
            return a.billing_phase_id - b.billing_phase_id;
          });
          setBillRunsForTheBranch(updatedDataAfterPhaseOneCompletion);
          setLoading((prev) => false);
        } else if (data?.error) {
          setThrowErrorMessage(data.error);
          setThrowError(true);
          setLoading((prev) => false);
        }
        setLoading((prev) => false);
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
        setLoading((prev) => false);
      }
    }
  };

  const noClickInitiateBillCycle = () => {
    setInitiateNewBillCyclePopupShow(false);
  };

  // Load initial data to the application
  useEffect(() => {
    if (currentBranch?.value) {
      getBillingInitialData();
      getBillingInterfaceLogonInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch?.value]);

  // Select the default branch from the branch list
  useEffect(() => {
    if (isHomeOfficeUser && allBranches) {
      let selectedBranch = allBranches?.find(
        (branch) => branch?.district_id === currentBranch?.value
      );

      billingStatusForm?.handleChange({
        target: { name: "branch", value: selectedBranch }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHomeOfficeUser, allBranches]);

  // Load Billing Cycles
  useEffect(() => {
    if (billingStatusForm?.values?.branch) {
      getBillingCycles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingStatusForm?.values?.branch]);

  // Load the initial bill date
  useEffect(() => {
    if (
      billingStatusForm?.values?.billCycle?.invoice_date_type_id ===
      billCycleIDs?.OTO
    ) {
      selectedBillCycle
        ? billingStatusForm.setFieldValue(
            "billDatePicker",
            dayjs(selectedBillDate)
          )
        : billingStatusForm?.handleChange({
            target: { name: "billDatePicker", value: today }
          });
    } else if (
      billingStatusForm?.values?.billCycle?.invoice_date_type_id ===
      billCycleIDs?.HOTO
    ) {
      getBillingRunDates();
    } else if (
      billingStatusForm?.values?.billCycle?.invoice_date_type_id ===
      billCycleIDs?.EOM
    ) {
      getBillingRunDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingStatusForm?.values?.billCycle]);

  useEffect(() => {
    setLoading((prev) => true);
  }, []);

  useEffect(() => {
    if (
      selectedBranch &&
      selectedBillCycle &&
      selectedBillDate &&
      allBranches.length &&
      billingCycles.length
    ) {
      let bra = allBranches?.find(
        (branch) => branch?.district_id === selectedBranch
      );
      let cycle = billingCycles?.find(
        (cyc) => cyc?.billing_cycle_code?.trim() === selectedBillCycle
      );
      billingStatusForm.setFieldValue("branch", bra);
      billingStatusForm.setFieldValue("billCycle", cycle);
    }
    //eslint-disable-next-line
  }, [allBranches, billingCycles]);

  useEffect(() => {
    if (selectedBillCycle === billCycles?.EOM) {
      billingStatusForm.setFieldValue("billDate", selectedBillDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingStatusForm?.values?.billingDates]);

  useEffect(() => {
    if (
      selectedBranch &&
      selectedBillCycle &&
      selectedBillDate &&
      !initialDataLoaded
    ) {
      loadInitialDataBasedOnTheParams();
      setInitialDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billingStatusForm?.values?.billCycle,
    billingStatusForm?.values?.billDatePicker
  ]);

  const loadInitialDataBasedOnTheParams = async () => {
    try {
      setLoading((prev) => true);
      setShowErrorAlert(false);
      setShowErrorAlertMsg("");
      setThrowError(false);
      setThrowErrorMessage("");
      let reqBody = JSON.stringify({
        main_district_id: selectedBranch,
        billing_cycle_code: selectedBillCycle,
        billing_date: selectedBillDate,
        district_id: selectedBranch
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.billingStatus?.billingstatusphasefind}`,
        2
      );

      let findClickIssues = data?.data[1];
      let localDate = data?.data[0];

      if (findClickIssues[0]?.error === errorMsgs?.errorCode20264) {
        const errMsg = await getTheFirestoreErrorMessage(
          findClickIssues[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setLoading((prev) => false);
        return;
      }

      if (findClickIssues[0]?.error > errorMsgs?.noError) {
        let splitDate = localDate[0]?.active_billing_date?.split("T"); // get the YYYY-MM-DD only from the local date
        const errMsg = await getTheFirestoreErrorMessage(
          findClickIssues[0]?.error
        );
        let msg = errMsg?.errorMsg?.replace("|", `${splitDate[0]}`);
        setShowErrorAlert(true);
        setShowErrorAlertMsg(msg);
        setLoading((prev) => false);
        return;
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
        return;
      }
      let sortedRunDetails = data?.data[0]?.sort((a, b) => {
        return a.billing_phase_id - b.billing_phase_id;
      });
      setBillRunsForTheBranch(sortedRunDetails);
      setLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading((prev) => false);
    }
  };

  //handle download
  const handleDownloadReport = async (reportName) => {
    try {
      setLoading(true);
      setThrowReportError(false);
      let requestBody = {
        main_district_id: billingStatusForm?.values?.branch?.district_id,
        district_id: billingStatusForm?.values?.branch?.district_id,
        district_name: currentBranch.name.replace("_", " "),
        billing_cycle_code:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim(),
        billing_date:
          billingStatusForm?.values?.billCycle?.billing_cycle_code?.trim() ===
          billCycles?.EOM
            ? billingStatusForm?.values?.billDate
            : billingStatusForm?.values?.billDatePicker.format(ymdDateFormat),
        user_name: getUserName(auth?.user),
        report_format: selectedPrintType?.type?.toUpperCase()
      };
      let url;
      if (reportName === billingStatusReports.billingStatus) {
        url = `${CF_URLS.printReports.billing.billingStatusReport}`;
      } else if (reportName === billingStatusReports.handlingCounts) {
        url = `${CF_URLS.printReports.billing.handlingCountsReport}`;
      } else if (reportName === billingStatusReports.vaultingCounts) {
        url = `${CF_URLS.printReports.billing.vaultingCountsReport}`;
      } else if (reportName === billingStatusReports.countSheet) {
        requestBody.sort = "";
        url = `${CF_URLS.printReports.billing.countSheetReport}`;
      } else if (reportName === billingStatusReports.transCountSheet) {
        url = `${CF_URLS.printReports.billing.transCountSheetReport}`;
      } else if (reportName === billingStatusReports.recurringChargeReview) {
        requestBody = {
          main_district_id: billingStatusForm?.values?.branch?.district_id,
          district_name: currentBranch.name.replace("_", " "),
          user_name: getUserName(auth?.user),
          report_format: selectedPrintType?.type?.toUpperCase()
        };
        url = `${CF_URLS.printReports.billing.recurringChargeReviewReport}`;
      } else if (reportName === billingStatusReports.openMediaHandlingReview) {
        url = `${CF_URLS.printReports.billing.openMediaHandlingReviewReport}`;
      } else if (reportName === billingStatusReports.billingVariance) {
        url = `${CF_URLS.printReports.billing.billingVarianceReport}`;
      } else if (reportName === billingStatusReports.zeroCount) {
        url = `${CF_URLS.printReports.billing.zeroCountReport}`;
      }

      let reportResponse = await printReport(JSON.stringify(requestBody), url);
      if (!reportResponse.success) {
        setThrowReportErrorMessage(reportResponse.error);
        setThrowReportError(true);
      }
      return reportResponse;
    } catch (e) {
      setThrowReportErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowReportError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* phases error msges */}
      {showErrorAlert && (
        <Alert
          severity="warning"
          icon={<img src={WarningIcon} alt="warning" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setShowErrorAlert(false);
                setShowErrorAlertMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {/* {showErrorAlertMsg} */}
          {Array.isArray(showErrorAlertMsg)
            ? showErrorAlertMsg.map((message, index) => (
                <>
                  {index === 0 ? <b>{message}</b> : `${index}) ${message}`}
                  <br />
                </>
              ))
            : showErrorAlertMsg}
        </Alert>
      )}

      {/* phases error msges */}
      {throwError && (
        <Alert
          severity="error"
          icon={<img src={ErrorIcon} alt="error" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
                setThrowErrorMessage("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      )}

      {/* Loader */}
      {loading && <FullCircularLoader />}
      <form style={BillingStatusStyles?.formStyle}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth disabled={isHomeOfficeUser !== Y_CHECKER}>
              <InputLabel id="branchSelectLabel">
                {t("billingStatus.branch")}
              </InputLabel>
              <Select
                labelId="branchSelectLabel"
                id="branchSelect"
                value={billingStatusForm?.values?.branch || ""}
                label={t("billingStatus.branch")}
                onChange={onBranchChange}
              >
                {allBranches?.length > 0 &&
                  allBranches?.map((b) => {
                    return (
                      <MenuItem value={b} key={b?.code}>
                        {b?.code}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          {billingStatusForm?.values?.branch && (
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="branchSelectLabel">
                  {t("billingStatus.billCycle")}
                </InputLabel>
                <Select
                  labelId="billCycleSelectLabel"
                  id="billCycleSelect"
                  value={billingStatusForm?.values?.billCycle || ""}
                  label={t("billingStatus.billCycle")}
                  onChange={onBillCycleChange}
                >
                  {billingCycles?.length > 0 &&
                    billingCycles?.map((b) => {
                      return (
                        <MenuItem value={b} key={b?.descr}>
                          {b?.descr}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {billingStatusForm?.values?.branch &&
            billingStatusForm?.values.billCycle && (
              <Grid item xs={4} sm={6} md={4} lg={3}>
                {billingStatusForm?.values?.billCycle?.invoice_date_type_id ===
                  billCycleIDs?.OTO && (
                  <DatePickerField
                    id="bill_Date"
                    name="billDatePicker"
                    label={t("billingStatus.billDate")}
                    value={billingStatusForm.values.billDatePicker}
                    minDate={minDate}
                    maxDate={maxDate}
                    defaultValue={today}
                    locale={localeByBranch}
                    onChange={(value) => {
                      billingStatusForm.setFieldValue("billDatePicker", value);
                      setBillRunsForTheBranch([]);
                      setShowErrorAlert(false);
                      setShowErrorAlertMsg("");
                      setThrowError(false);
                      setThrowErrorMessage("");
                      setSelectedRow(null);
                    }}
                  />
                )}

                {billingStatusForm?.values?.billCycle?.invoice_date_type_id ===
                  billCycleIDs?.HOTO && (
                  <FormControl fullWidth>
                    <InputLabel id="branchSelectLabel">
                      {t("billingStatus.billDate")}
                    </InputLabel>
                    <Select
                      labelId="billCycleSelectLabel"
                      id="billCycleSelect"
                      value={
                        billingStatusForm?.values?.billingDates?.find(
                          (billdate) =>
                            billdate?.value ===
                            billingStatusForm?.values?.billDate
                        ) || ""
                      }
                      label={t("billingStatus.billDate")}
                      onChange={onBillDateChanges}
                      disabled
                    >
                      {billingStatusForm?.values?.billingDates?.length > 0 &&
                        billingStatusForm?.values?.billingDates?.map((b) => {
                          return (
                            <MenuItem value={b} key={b?.label}>
                              {b?.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}

                {billingStatusForm?.values?.billCycle?.invoice_date_type_id ===
                  billCycleIDs?.EOM && (
                  <FormControl fullWidth>
                    <InputLabel id="branchSelectLabel">
                      {t("billingStatus.billDate")}
                    </InputLabel>
                    <Select
                      labelId="billCycleSelectLabel"
                      id="billCycleSelect"
                      value={
                        billingStatusForm?.values?.billingDates?.find(
                          (billdate) =>
                            billdate?.value ===
                            billingStatusForm?.values?.billDate
                        ) || ""
                      }
                      label={t("billingStatus.billDate")}
                      onChange={onBillDateChanges}
                    >
                      {billingStatusForm?.values?.billingDates?.length > 0 &&
                        billingStatusForm?.values?.billingDates?.map((b) => {
                          return (
                            <MenuItem value={b} key={b?.label}>
                              {b?.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            )}
        </Grid>
      </form>
      <Grid container>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => onFindButtonClick()}
            disabled={isFindButtonDisabled()}
          >
            {t("common.find")}
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <BillingStatusTable
          billRunsForTheBranch={billRunsForTheBranch}
          handleRowClick={handleRowClick}
          selectedRow={selectedRow}
          handlePrintButtonClick={handlePrintButtonClick}
          handleTransactionButtonClick={handleTransactionButtonClick}
          handleErrorLogButtonClick={handleErrorLogButtonClick}
          handleCompleteClick={handleCompleteClick}
          handleRunButtonClick={handleRunButtonClick}
          isCompleteButtonDisabled={isCompleteButtonDisabled}
          isTransactionButtonDisabled={isTransactionButtonDisabled}
          isErrorLogButtonDisabled={isErrorLogButtonDisabled}
          isRunButtonDisabled={isRunButtonDisabled}
          handleResetButtonClick={handleResetButtonClick}
          isResetButtonDisabled={isResetButtonDisabled}
          localeByBranch={localeByBranch}
        />
      </Grid>

      {/* Download popup */}
      <DownloadPopup
        title={t("billingStatus.statusReport")}
        showPrintModal={showPrintModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => setShowPrintModal((prev) => !prev)}
        onDownloadClick={() =>
          handleDownloadReport(billingStatusReports.billingStatus)
        }
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* Handling Count Sheets popup */}
      <DownloadPopup
        title={t("billingStatus.handlingCountSheets")}
        showPrintModal={showPrintCountSheetModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClickInDownloadCountSheets}
        onDownloadClick={onDownloadClickInDownloadCountSheets}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* Vaulting Count Sheets popup */}
      <DownloadPopup
        title={t("billingStatus.vaultingCountSheets")}
        showPrintModal={showVaultingCountSheetModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClickInDownloadVaultingCountSheets}
        onDownloadClick={onDownloadClickInDownloadVaultingCountSheets}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* Count Sheets popup */}
      <DownloadPopup
        title={t("billingStatus.countSheets")}
        showPrintModal={showCountSheetModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClickInCountSheets}
        onDownloadClick={onDownloadClickInCountSheets}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* Transport Count Sheets popup */}
      <DownloadPopup
        title={t("billingStatus.transportCountSheet")}
        showPrintModal={showTransportCountSheetModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClickInTransportCountSheets}
        onDownloadClick={onDownloadClickInTransportCountSheets}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* Variance Report popup */}
      <DownloadPopup
        title={t("billingStatus.varianceReport")}
        showPrintModal={showVarianceReportModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClickInVarianceReport}
        onDownloadClick={onDownloadClickInVarianceReport}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* Zero Count Report popup */}
      <DownloadPopup
        title={t("billingStatus.zeroCountReport")}
        showPrintModal={showZeroCountReportModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClickInZeroCountReport}
        onDownloadClick={onDownloadClickInZeroCountReport}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* Print Recurring Charge review Report popup */}
      <DownloadPopup
        title={t("billingStatus.recurringChargeReviewReport")}
        showPrintModal={recurringChargeReviewReportModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClickInRecurringChargeReviewReport}
        onDownloadClick={onDownloadClickInRecurringChargeReviewReport}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* Open Media Per Hour Handling Review Report */}
      <DownloadPopup
        title={t("billingStatus.openMediaPerHourHandlingReviewReport")}
        showPrintModal={openMediaPerHourHandlingReviewReportModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClickInOpenMediaPerHourHandlingReviewReport}
        onDownloadClick={onDownloadClickInOpenMediaPerHourHandlingReviewReport}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
      />

      {/* rollback msg show modal */}
      <BillingStatusRollbackModal
        rollbackPopupShow={rollbackPopupShow}
        rollbackPopupShowOTO={rollbackPopupShowOTO}
        cancelClickRollback={cancelClickRollback}
        cancelClickRollbackOTO={cancelClickRollbackOTO}
        okClickRollback={okClickRollback}
        okClickRollbackOTO={okClickRollbackOTO}
      />

      <BillingResetModal
        resetPopupShow={resetPopupShow}
        cancelClickReset={cancelClickReset}
        phaseDescription={resetPhase?.descr}
        okClickReset={okClickReset}
      />

      <InitiateBillCycleModal
        initiateModalShow={initiateNewBillCyclePopupShow}
        yesClickInitiateBillCycle={yesClickInitiateBillCycle}
        noClickInitiateBillCycle={noClickInitiateBillCycle}
      />

      <ConfirmationModal
        showConfirmationPopup={showConfirmationPopup}
        showConfirmationMsg={showConfirmationMsg}
        cancelClickOnConfirmation={cancelClickOnConfirmation}
        okClickOnConfirmation={okClickOnConfirmation}
      />
    </>
  );
};

export default BillingStatus;
