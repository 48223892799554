import i18n from "../../i18n";
import * as Path from "../../routing/Paths";

export const Menus = [
  { name: i18n.t("menu.dashboard"), to: Path.ROOT, values: [] },
  {
    name: i18n.t("menu.administration"),
    to: Path.UNDER_CONSTRUCTION,
    values: [
      {
        subName: i18n.t("menu.payroll"),
        to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.PAYROLL}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.billing"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.billingStatus"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.BILLING_STATUS}`
          },
          {
            label: i18n.t("menu.billingTransactions"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.BILLING_TRANSACTIONS}`
          },
          {
            label: i18n.t("menu.billingErrorLog"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.BILLING_ERROR_LOG}`
          },
          {
            label: i18n.t("menu.enterpriseBillingSummary"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.ENTERPRISE_BILLING_SUMMARY}`
          },
          {
            label: i18n.t("menu.maintainBillingRules"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.MAINTAIN_BILLING_RULES}`
          },
          {
            label: i18n.t("menu.customerBillingInformation"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.CUSTOMER_BILLING_INFORMATION}`
          },
          {
            label: i18n.t("menu.defaultPricingUseReport"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.DEFAULT_PRICING_USE_REPORT}`
          },
          {
            label: i18n.t("menu.recurringChangeSearch"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.RECURRING_CHANGE_SEARCH}`
          },
          {
            label: i18n.t("menu.recurringChangeDetail"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.RECURRING_CHANGE_DETAIL}`
          },
          {
            label: i18n.t("menu.capacityBillingConfigurationSearch"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.CAPACITY_BILLING_CONFIGURATION_SEARCH}`
          },
          {
            label: i18n.t("menu.capacityBillingGenerationSearch"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.CAPACITY_BILLING_GENERATION_SEARCH}`
          }
        ]
      },
      {
        subName: i18n.t("menu.authorization"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.personalDetail"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_AUTH_BASE_PATH}/${Path.PERSONAL_DETAIL}`
          },
          {
            label: i18n.t("menu.personalSearch"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_AUTH_BASE_PATH}/${Path.PERSONAL_SEARCH}`
          },
          {
            label: i18n.t("menu.personalValidation"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_AUTH_BASE_PATH}/${Path.PERSONAL_VALIDATION}`
          }
        ]
      },
      {
        subName: i18n.t("menu.labelPrinting"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.printTapeLabel"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_LABEL_BASE_PATH}/${Path.PRINT_TAPE_LABEL}`
          },
          {
            label: i18n.t("menu.labelPrinterSetup"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_LABEL_BASE_PATH}/${Path.LABEL_PRINTER_SETUP}`
          }
        ]
      },
      {
        subName: i18n.t("menu.security"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.employee"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_SECURITY_BASE_PATH}/${Path.EMPLOYEE}`
          },
          {
            label: i18n.t("menu.groupEmployee"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_SECURITY_BASE_PATH}/${Path.GROUP_EMPLOYEE}`
          },
          {
            label: i18n.t("menu.groupManagement"),
            to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_SECURITY_BASE_PATH}/${Path.GROUP_MANAGEMENT}`
          }
        ]
      },
      {
        subName: i18n.t("menu.employeeDetail"),
        to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.EMPLOYEE_DETAIL}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.employeeSearch"),
        to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.EMPLOYEE_SEARCH}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.notificationConfiguration"),
        to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.NOTIFICATION_CONFIGURATION}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.customerMoveSearch"),
        to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.CUSTOMER_MOVE_SEARCH}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.customerMoveDetail"),
        to: `${Path.ADMIN_MODULE_BASE_PATH}/${Path.CUSTOMER_MOVE_DETAIL}`,
        subValues: []
      }
    ]
  },
  {
    name: i18n.t("menu.requests"),
    to: Path.UNDER_CONSTRUCTION,
    values: [
      {
        subName: i18n.t("menu.requestSearch"),
        to: `${Path.REQUEST_MODULE_BASE_PATH}/${Path.REQUEST_SEARCH}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.mediaRequestDetail"),
        to: `${Path.REQUEST_MODULE_BASE_PATH}/${Path.MEDIA_REQUEST_DETAIL}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.rescheduleService"),
        to: `${Path.REQUEST_MODULE_BASE_PATH}/${Path.RESCHEDULE_SERVICE}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.mediaDestructionRequestSearch"),
        to: `${Path.REQUEST_MODULE_BASE_PATH}/${Path.MEDIA_DESTRUCTION_REQUEST_SEARCH}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.mediaDestructionRequestDetail"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.delivery"),
            to: `${Path.REQUEST_MODULE_BASE_PATH}/${Path.REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}`
          },
          {
            label: i18n.t("menu.pickup"),
            to: `${Path.REQUEST_MODULE_BASE_PATH}/${Path.REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP}`
          },
          {
            label: i18n.t("menu.vaultItemized"),
            to: `${Path.REQUEST_MODULE_BASE_PATH}/${Path.REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED}`
          }
        ]
      }
    ]
  },
  {
    name: i18n.t("menu.operations"),
    to: Path.UNDER_CONSTRUCTION,
    values: [
      {
        subName: i18n.t("menu.customerInformation"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.serviceInformation"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.SERVICE_INFORMATION}`
          },
          {
            label: i18n.t("menu.schedule"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.SCHEDULE}`
          },
          {
            label: i18n.t("menu.serviceWindows"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.SERVICE_WINDOWS}`
          },
          {
            label: i18n.t("menu.routeManagement"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.ROUTE_MANAGEMENT}`
          },
          {
            label: i18n.t("menu.labelTemplateSearch"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.LABEL_TEMPLATE_SEARCH}`
          },
          {
            label: i18n.t("menu.preferences"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.PREFERENCES}`
          }
        ]
      },
      {
        subName: i18n.t("menu.disasterRecovery"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.instructionFiles"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${Path.INSTRUCTION_FILES}`
          },
          {
            label: i18n.t("menu.event"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${Path.EVENT}`
          },
          {
            label: i18n.t("menu.eventSearch"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${Path.EVENT_SEARCH}`
          }
        ]
      },
      {
        subName: i18n.t("menu.discrepancyManagement"),
        to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.DISCREPANCY_MANAGEMENT}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.logicalVaultManagement"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.logicalVaultDetail"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH}/${Path.LOGICAL_VAULT_DETAILS}`
          },
          {
            label: i18n.t("menu.lVAuditSearch"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH}/${Path.LOGICAL_VAULT_AUDIT_SEARCH}`
          }
        ]
      },
      {
        subName: i18n.t("menu.containerProcessing"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.containerIssuance"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.CONTAINER_ISSUANCE}`
          },
          {
            label: i18n.t("menu.containerProcessingResearch"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.CONTAINER_PROCESSING_RESEARCH}`
          },
          {
            label: i18n.t("menu.reassignTransportContents"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.REASSIGN_TRANSPORT_CONTENTS}`
          },
          {
            label: i18n.t("menu.containerInventoryAuditSearch"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.CONTAINER_INVENTORY_AUDIT_SEARCH}`
          },
          {
            label: i18n.t("menu.containerInventoryAuditDetail"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.CONTAINER_INVENTORY_AUDIT_DETAIL}`
          }
        ]
      },
      {
        subName: i18n.t("menu.openMediaProcessing"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.workManagement"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.WORK_MANAGEMENT}`
          },
          {
            label: i18n.t("menu.openMediaProcessingResearch"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.OPEN_MEDIA_PROCESSING_RESEARCH}`
          },
          {
            label: i18n.t("menu.branchOutagePickListCompletion"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.BRANCH_OUTAGE_PICK_LIST_COMPLETION}`
          },
          {
            label: i18n.t("menu.createDistributionList"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.CREATE_DISTRIBUTION_LIST}`
          },
          {
            label: i18n.t("menu.customerFileSearch"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.CUSTOMER_FILE_SEARCH}`
          },
          {
            label: i18n.t("menu.openMediaAuditSearch"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.OPEN_MEDIA_AUDIT_SEARCH}`
          },
          {
            label: i18n.t("menu.openMediaAuditDetail"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.OPEN_MEDIA_AUDIT_DETAIL}`
          }
        ]
      },
      {
        subName: i18n.t("menu.correctiveActionRequests"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.detail"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${Path.CORRECTIVE_ACTION_REQUESTS_DETAIL}`
          },
          {
            label: i18n.t("menu.search"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${Path.CORRECTIVE_ACTION_REQUESTS_SEARCH}`
          }
        ]
      },
      {
        subName: i18n.t("menu.transportation"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.routeDetail"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${Path.ROUTE_DETAIL}`
          },
          {
            label: i18n.t("menu.runManagement"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${Path.RUN_MANAGEMENT}`
          },
          {
            label: i18n.t("menu.vendorManagement"),
            to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${Path.VENDOR_MANAGEMENT}`
          }
        ]
      },
      {
        subName: i18n.t("menu.vaultConfiguration"),
        to: `${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.VAULT_CONFIGURATION}`,
        subValues: []
      }
    ]
  },
  {
    name: i18n.t("menu.reports"),
    to: Path.UNDER_CONSTRUCTION,
    values: [
      {
        subName: i18n.t("menu.admin"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.createInventoryExtract"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.CREATE_INVENTORY_EXTRACT}`
          },
          {
            label: i18n.t("menu.customerScheduleExpiration"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.CUSTOMER_SCHEDULE_EXPIRATION}`
          },
          {
            label: i18n.t("menu.customerAuthorizationForm"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.CUSTOMER_AUTHORIZATION_FORM}`
          },
          {
            label: i18n.t("menu.customerAuthorizationList"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.CUSTOMER_AUTHORIZATION_LIST}`
          },
          {
            label: i18n.t("menu.inHouseAuthorizationList"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.IN_HOUSE_AUTHORIZATION_LIST}`
          },
          {
            label: i18n.t("menu.inHouseAuthorizationUpdate"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.IN_HOUSE_AUTHORIZATION_UPDATE}`
          },
          {
            label: i18n.t("menu.runsAuthorizationList"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.RUNS_AUTHORIZATION_LIST}`
          },
          {
            label: i18n.t("menu.runsAuthorizationUpdate"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.RUNS_AUTHORIZATION_UPDATE}`
          },
          {
            label: i18n.t("menu.unassignedTerminatedAccountOwner"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_ADMIN_BASE_PATH}/${Path.UNASSIGNED_ACCOUNT_OWNER}`
          }
        ]
      },
      {
        subName: i18n.t("menu.billing"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.billingActivityCounts"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_BILLING_ACTIVITY_PATH}/${Path.BILLING_ACTIVITY_COUNTS}`
          }
        ]
      },
      {
        subName: i18n.t("menu.container"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.containerInventory"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_CONTAINER_PATH}/${Path.CONTAINER_INVENTORY}`
          },
          {
            label: i18n.t("menu.transportLocations"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_CONTAINER_PATH}/${Path.TRANSPORT_LOCATIONS}`
          },
          {
            label: i18n.t("menu.tempTransportHistory"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_CONTAINER_PATH}/${Path.TEMP_TRANSPORT_HISTORY}`
          },
          {
            label: i18n.t("menu.emptyTransportDeficit"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_CONTAINER_PATH}/${Path.EMPTY_TRANSPORT_DEFICIT}`
          }
        ]
      },
      {
        subName: i18n.t("menu.reportsEmployee"),
        to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_EMPLOYEE}`,
        subValues: []
      },
      {
        subName: i18n.t("menu.reportsVaultManagement"),
        to: Path.UNDER_CONSTRUCTION,
        subValues: [
          {
            label: i18n.t("menu.containerDistributionListHistory"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_VAULT_PATH}/${Path.CONTAINER_DISTRIBUTION_LIST_HISTORY}`
          },
          {
            label: i18n.t("menu.vaultManagement"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_VAULT_PATH}/${Path.VAULT_MANAGEMENT}`
          },
          {
            label: i18n.t("menu.vaultStatusReport"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_VAULT_PATH}/${Path.VAULT_STATUS_REPORT}`
          },
          {
            label: i18n.t("menu.uploadErrorsHistory"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_VAULT_PATH}/${Path.UPLOAD_ERRORS_HISTORY}`
          },
          {
            label: i18n.t("menu.runArrival"),
            to: `${Path.REPORTS_MODULE_BASE_PATH}/${Path.REPORTS_MODULE_VAULT_PATH}/${Path.RUN_ARRIVAL}`
          }
        ]
      }
    ]
  }
];
