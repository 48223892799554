import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import spacing from "styles/spacing";
import {
  BOOLEAN_STRING_VALUES,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  errorMsgs,
  unauthorized
} from "utils/constants";
import { labelPrinterSetup } from "utils/constants/label-printer-setup/LabelPrinterSetupConstant";
import {
  constantM,
  darknessAdjustmentConstant,
  label_type_code_NA,
  maxNumber,
  minXandYHome,
  mmToDotsConversionConstant,
  numberZero,
  specialCharMap,
  topAdjustmentConstant,
  typeFile,
  typeRange,
  typeSingleLabel
} from "utils/constants/label-printing";
import {
  fieldNames,
  labelSourceOptions
} from "utils/constants/label-printing/PrintTapeLabelLayout";
import { getAuthenticatedUserBranch } from "utils/helpers";
import PrintTapeLabelLayoutTabel from "./PrintTapeLabelLayoutTabel";
import { printTapeLabelStyles } from "./PrintTapeLabelStyles";

const validateBarcode = (barcode, MAX_MEDIA_NUMBER_LENGTH, type) => {
  const nonStandardCharRegex = /[^A-Z0-9 \-.$/+%]/g;
  const barcodeLength = barcode.length;
  const nonStandardCharCount = (barcode.match(nonStandardCharRegex) || [])
    .length;
  const adjustedMaxMediaNumberLength =
    parseInt(MAX_MEDIA_NUMBER_LENGTH) - nonStandardCharCount;
  let statusMessage = "";
  let statusMessageShort = "";
  let isValid = true;

  // Validation logic
  if (barcodeLength > adjustedMaxMediaNumberLength) {
    const overLength = barcodeLength - adjustedMaxMediaNumberLength;
    if (type === typeSingleLabel) {
      statusMessage = t("printTapeLabel.longMediaLabel", {
        maxChars: MAX_MEDIA_NUMBER_LENGTH
      });
    } else if (type === typeRange) {
      statusMessage = t("printTapeLabel.overLengthError", {
        overLength: MAX_MEDIA_NUMBER_LENGTH
      });
    }
    statusMessageShort = t("printTapeLabel.invalidBarcode", { overLength });
    isValid = false;
  } else {
    statusMessage = t("printTapeLabel.readyToPrint");
  }
  return { statusMessage, isValid, statusMessageShort };
};

// Validation for Customer Number and Media Type
const validateAlphanumericMaxLength = (value, maxLength) => {
  const alphanumericRegex = /^[A-Z0-9]*$/;
  const isValid =
    alphanumericRegex.test(value) &&
    (maxLength === undefined || value.length <= maxLength);
  return isValid;
};

// Convert ZPL string to a Base64 string
function zplToBase64(zpl) {
  const byteArray = new TextEncoder().encode(zpl);
  const base64String = btoa(String.fromCharCode(...byteArray));
  return base64String;
}

// returns list of media numbers after the increment
const getMediaNumberFromRange = (
  mediaNumberPrefix,
  mediaNumberIncrementer,
  mediaNumberSuffix,
  quantity
) => {
  const mediaNumbers = [];

  for (let i = 0; i < quantity; i++) {
    const incrementedNumber = parseInt(mediaNumberIncrementer, 10) + i;
    const mediaNumber = `${mediaNumberPrefix}${incrementedNumber}${mediaNumberSuffix}`;
    mediaNumbers.push(mediaNumber);
  }

  return mediaNumbers;
};

const printerFilter = (data) => {
  return data?.filter((printer) =>
    printer.label_type_code.startsWith(constantM)
  );
};

const selectEnv = () => {
  if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.dev1) {
    return labelPrinterSetup.logicalNameEnv.dev1;
  } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.dev2) {
    return labelPrinterSetup.logicalNameEnv.dev2;
  } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.qa1) {
    return labelPrinterSetup.logicalNameEnv.qa1;
  } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.qa2) {
    return labelPrinterSetup.logicalNameEnv.qa2;
  } else if (process.env.REACT_APP_ENV === labelPrinterSetup.env.uat) {
    return labelPrinterSetup.logicalNameEnv.uat;
  } else {
    return "";
  }
};

const formBarcode = (
  customerNumber,
  mediaType,
  mediaNumber,
  includeHyphen = false,
  isForLabel = false
) => {
  // Replace special characters in mediaNumber
  let transformedMediaNumber = "";
  if (isForLabel) {
    transformedMediaNumber = mediaNumber;
  } else {
    for (const char of mediaNumber) {
      transformedMediaNumber += specialCharMap[char] || char;
    }
  }

  if (includeHyphen) {
    if (!customerNumber && !mediaType) {
      return `${mediaNumber}`;
    } else if (!customerNumber) {
      return `${mediaType}-${mediaNumber}`;
    } else if (!mediaType) {
      return `${customerNumber}-${mediaNumber}`;
    } else {
      return `${customerNumber}-${mediaType}-${mediaNumber}`;
    }
  } else {
    return `${customerNumber}${mediaType}${transformedMediaNumber}`;
  }
};

const hasSpecialCharMapping = (str) => {
  for (let i = 0; i < str.length; i++) {
    if (specialCharMap[str[i]]) {
      return true;
    }
  }
  return false;
};

const PrintTapeLabelLayout = () => {
  const { t } = useTranslation();
  const [sourceSelected, setSourceSelected] = useState(
    labelSourceOptions.find((option) => option.value === typeSingleLabel)
  );

  const [formError, setFormError] = useState("");
  const [labelSentSuccess, setLabelSentSuccess] = useState(false);
  const [barcodeHasSpecialCharsInfo, setBarcodeHasSpecialCharsInfo] =
    useState(false);

  const districtId = String(getAuthenticatedUserBranch());

  // Printer list
  const [printerListOptions, setprinterListOptions] = useState([]);
  const [printerList, setPrinterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const handlePrinterChange = (_, va) => {
    setSelectedPrinterOption(va);
    setFormError(false);
    setBarcodeHasSpecialCharsInfo(false);
  };

  // Selected Printer
  const [selectedPrinterOption, setSelectedPrinterOption] = useState(null);
  const [selectedPrinter, setSelectedPrinter] = useState({});
  // Label Format data
  const [labelFormatData, setLabelFormatData] = useState({});
  // Customer Number
  const [customerNumber, setCustomerNumber] = useState("");
  const handleCustomerNumberChange = (e) => {
    let value = e.target.value.trim();
    // Check if the input is alphanumeric and does not contain whitespace
    const isValid = validateAlphanumericMaxLength(
      value.toUpperCase(),
      maxNumber.customerNumber
    );
    if (isValid) {
      setCustomerNumber(value.toUpperCase());
    }
  };

  // Media Number
  const [mediaNumber, setMediaNumber] = useState("");
  const [mediaNumberError, setMediaNumberError] = useState(false);
  const handleMediaNumberChange = (e) => {
    let value = e.target.value.trim();
    if (value.length <= maxNumber.mediaNumber) {
      setMediaNumber(value.toUpperCase());
    }
    setMediaNumberError(false);
  };

  // Media Type
  const [mediaType, setMediaType] = useState("");
  const handleMediaTypeChange = (e) => {
    let value = e.target.value.trim();

    // Check if the input is alphanumeric and does not contain whitespace
    const isValid = validateAlphanumericMaxLength(
      value.toUpperCase(),
      maxNumber.mediaType
    );

    if (isValid) {
      setMediaType(value.toUpperCase());
    }
  };

  // Quantity
  const [quantity, setQuantity] = useState("");
  const [quantityError, setQuantityError] = useState(false);
  const handleQuantityChange = (e) => {
    let value = e.target.value;
    // Check if the input is an integer and within the max length
    const isValid = /^[0-9]*$/.test(value);
    if (isValid && value.length <= maxNumber.quantity) {
      setQuantity(value);
      setQuantityError(false);
    }
  };

  // Media Number Prefix
  const [mediaNumberPrefix, setMediaNumberPrefix] = useState("");
  const handleMediaNumberPrefixChange = (e) => {
    let value = e.target.value.trim();
    if (value.length <= maxNumber.mediaNumberSuffix) {
      setMediaNumberPrefix(value?.toUpperCase());
    }
  };

  // Media Number Incrementer
  const [mediaNumberIncrementer, setMediaNumberIncrementer] = useState("");
  const [mediaNumberIncrementerError, setMediaNumberIncrementerError] =
    useState(false);
  const handleMediaNumberIncrementerChange = (e) => {
    let value = e.target.value;
    // Check if the input is an integer and within the max length
    const isValid = /^[0-9]*$/.test(value);
    if (isValid && value.length <= maxNumber.mediaNumberIncrementer) {
      setMediaNumberIncrementerError(false);
      setMediaNumberIncrementer(value);
    }
  };

  // Media Number Suffix
  const [mediaNumberSuffix, setMediaNumberSuffix] = useState("");
  const handleMediaNumberSuffixChange = (e) => {
    let value = e.target.value.trim();
    if (value.length <= maxNumber.mediaNumberPrefix) {
      setMediaNumberSuffix(value?.toUpperCase());
    }
  };

  // Parsed data from the file
  const [parsedData, setParsedData] = useState([]);

  // Reset Fields
  const resetFields = () => {
    setCustomerNumber("");
    setMediaNumber("");
    setMediaType("");
    setQuantity("");
    setMediaNumberPrefix("");
    setMediaNumberIncrementer("");
    setMediaNumberSuffix("");
    setFormError(false);
    setBarcodeHasSpecialCharsInfo(false);
    setMediaNumberError(false);
    setQuantityError(false);
    setMediaNumberIncrementerError(false);
  };

  // On change of label source change
  const handleLabelSource = (_, val) => {
    setSourceSelected(val);
    resetFields();
    setParsedData([]);
  };

  // call to cloud function and get data from firestore
  const getResponseData = useCallback(async (requestBody, url, count) => {
    const response = await callToCloudFunction(requestBody, url);
    if (response.data.docId) {
      let data = await getDataFromFirestore(
        response,
        count,
        response.data.docId
      );
      return data;
    } else {
      return true;
    }
  }, []);

  let auditRequestConstantsBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    label_format_id: labelFormatData.label_format_id,
    container_number: ""
  };

  // To get the list of printers
  useEffect(() => {
    if (!districtId) return;
    (async () => {
      setLoading(true);
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_printer_id: "",
        district_id: districtId
      };
      let URL = `${CF_URLS.printTapeLabel.getLabelPrinter}`;

      try {
        let data = await getResponseData(reqBody, URL, 1);

        // Filter printer whose label_type_code starts with "M"
        let filterData = printerFilter(data.data[0]);
        setPrinterList(data.data[0]);
        let labelPrinterOptions = filterData.map((printer) => ({
          label: printer.short_name,
          id: printer.label_printer_id
        }));
        setSelectedPrinterOption(labelPrinterOptions[0]);

        setprinterListOptions(
          labelPrinterOptions.sort((a, b) => a.label.localeCompare(b.label))
        );
        setLoading(false);
        setFormError("");
      } catch (error) {
        setLoading(false);
        if (error?.response?.data === unauthorized) {
          setFormError(t("printTapeLabel.sessionLoggedOutMessage"));
        } else {
          setFormError(t("printTapeLabel.systemError"));
        }
      }
    })();
  }, [districtId, getResponseData, t]);

  // To get the Details of selected Printer and Format details
  useEffect(() => {
    if (!selectedPrinterOption?.id || !districtId) return;
    (async () => {
      setLoading(true);
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_printer_id: selectedPrinterOption?.id,
        district_id: districtId
      };
      let URL = `${CF_URLS.printTapeLabel.getLabelPrinter}`;
      try {
        let data = await getResponseData(reqBody, URL, 1);

        let printer = data.data[0][0];
        setSelectedPrinter(printer);

        reqBody = {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          label_format_id: "",
          label_type_code: printer.label_type_code
        };
        URL = `${CF_URLS.printTapeLabel.getLabelFormat}`;
        let labelFormatData = await getResponseData(reqBody, URL, 1);
        setLabelFormatData(labelFormatData.data[0][0]);
        setLoading(false);
        setFormError("");
      } catch (error) {
        setLoading(false);
        if (error?.response?.data === unauthorized) {
          setFormError(t("printTapeLabel.sessionLoggedOutMessage"));
        } else {
          setFormError(t("printTapeLabel.systemError"));
        }
      }
    })();
  }, [selectedPrinterOption, districtId, getResponseData, t]);

  // On click of Print
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormError("");
    setBarcodeHasSpecialCharsInfo(false);
    if (!printerListOptions.length) {
      setFormError(t("printTapeLabel.noLabelFoundError"));
      return;
    }
    let areFieldsValid = true;
    // Check for Label Format avaibale or not
    const isLabelFormatFound = labelFormatData.label_format_id !== "";

    // Get the offset values of printer
    let { darkness_adjustment, top_offset_mm, left_offset_mm } =
      printerList.find(
        (printer) => printer.label_printer_id === selectedPrinterOption.id
      );
    if (!isLabelFormatFound) {
      setFormError(t("printTapeLabel.noLabelFoundError"));
      areFieldsValid = false;
    }
    if (sourceSelected?.value === typeSingleLabel) {
      const isCustomerNumberValid = validateAlphanumericMaxLength(
        customerNumber,
        maxNumber.customerNumber
      );
      if (!isCustomerNumberValid) {
        setFormError(t("printTapeLabel.customerNumberInvalidError"));
      }
      // Validate for Media number
      if (!mediaNumber) {
        setMediaNumberError(true);
        areFieldsValid = false;
      }
      if (!areFieldsValid) {
        return;
      }
      let barcode = formBarcode(customerNumber, mediaType, mediaNumber);

      let lableText = formBarcode(
        customerNumber,
        mediaType,
        mediaNumber,
        labelFormatData.label_type_code === label_type_code_NA,
        BOOLEAN_STRING_VALUES.TRUE
      );
      const { isValid, statusMessage } = validateBarcode(
        barcode,
        labelFormatData.max_media_number_length,
        sourceSelected?.value
      );

      if (!isValid) {
        setFormError(statusMessage);
        return;
      }

      const hasSpecialChars = hasSpecialCharMapping(mediaNumber);

      let zpl = labelFormatData.label_template_zpl
        .replace("{0}", barcode)
        .replace("{1}", lableText);

      setLoadingPrint(true);
      executePrint(zpl, {
        darkness_adjustment,
        top_offset_mm,
        left_offset_mm
      })
        .then((isPrintSuccess) => {
          if (isPrintSuccess) {
            setLabelSentSuccess(true);
            setLoadingPrint(false);
            setBarcodeHasSpecialCharsInfo(hasSpecialChars);
          }
        })
        .catch((error) => {
          setLoadingPrint(false);
          const errCode = error.response.data.error[0].errorCode;
          if (errCode === errorMsgs.errorCode0203) {
            setFormError(t("printTapeLabel.printerNotExistError"));
          } else {
            setFormError(t("printTapeLabel.systemError"));
          }
        });

      // Audit request body
      let auditRequestBody = {
        ...auditRequestConstantsBody,
        audit_info: t("printTapeLabel.auditInfo", {
          selectedPrinterId: selectedPrinterOption.id,
          customerNumber,
          mediaType,
          mediaNumber
        }),
        customer_number: customerNumber,
        media_type: mediaType,
        media_number: mediaNumber
      };

      executeAudit(auditRequestBody);
    } else if (sourceSelected?.value === typeRange) {
      if (!quantity) {
        setQuantityError(true);
        return;
      }
      if (!mediaNumberIncrementer?.trim()) {
        setMediaNumberIncrementerError(true);
        return;
      }

      const mediaNumberRange = getMediaNumberFromRange(
        mediaNumberPrefix,
        mediaNumberIncrementer,
        mediaNumberSuffix,
        quantity
      );

      const barcodes = mediaNumberRange.map((mediaNumber) => {
        let barcode = formBarcode(customerNumber, mediaType, mediaNumber);

        let lableText = formBarcode(
          customerNumber,
          mediaType,
          mediaNumber,
          labelFormatData.label_type_code === label_type_code_NA,
          BOOLEAN_STRING_VALUES.TRUE
        );

        return { barcode, lableText };
      });

      let barcodesValidity = barcodes.map((barcode) => {
        const { isValid, statusMessage } = validateBarcode(
          barcode.barcode,
          labelFormatData.max_media_number_length,
          sourceSelected?.value
        );

        return { isValid, statusMessage };
      });

      if (!barcodesValidity.every((validity) => validity.isValid)) {
        let invalidBarcode = barcodesValidity.find(
          (barcode) => !barcode.isValid
        );
        setFormError(invalidBarcode?.statusMessage);
        return;
      }

      const zplRange = barcodes.map((barcode) =>
        labelFormatData.label_template_zpl
          .replace("{0}", barcode.barcode)
          .replace("{1}", barcode.lableText)
      );

      const hasSpecialChars = hasSpecialCharMapping(
        mediaNumberPrefix + mediaNumberSuffix
      );

      const printLabels = async () => {
        setLoadingPrint(true);

        try {
          for (const zpl of zplRange) {
            const isPrintSuccess = await executePrint(zpl, {
              darkness_adjustment,
              top_offset_mm,
              left_offset_mm
            });
            if (isPrintSuccess) {
              setLabelSentSuccess(true);
              setBarcodeHasSpecialCharsInfo(hasSpecialChars);
            }
          }
        } catch (error) {
          const errCode = error?.response?.data?.error[0]?.errorCode;
          if (errCode === errorMsgs.errorCode0203) {
            setFormError(t("printTapeLabel.printerNotExistError"));
          } else {
            setFormError(t("printTapeLabel.systemError"));
          }
        } finally {
          setLoadingPrint(false);
        }
      };

      printLabels();

      mediaNumberRange.forEach(async (mediaNumber) => {
        let auditRequestBody = {
          ...auditRequestConstantsBody,
          audit_info: t("printTapeLabel.auditInfo", {
            selectedPrinterId: selectedPrinterOption.id,
            customerNumber,
            mediaType,
            mediaNumber
          }),
          customer_number: customerNumber,
          media_type: mediaType,
          media_number: mediaNumber
        };

        executeAudit(auditRequestBody);
      });
    } else if (sourceSelected?.value === typeFile) {
      // If no file selected show Error
      if (!parsedData.length) {
        setFormError(t("printTapeLabel.importFileError"));
      }
      // Please import a file containing valid labels.
      let validParsedData = parsedData.filter(
        (line) => line.statusMessage === ""
      );
      let barcodes = validParsedData.map((line) => {
        let customerNumber = line.customer_number.toUpperCase();
        let mediaType = line.media_type.toUpperCase();
        let mediaNumber = line.media_number.toUpperCase();
        let barcode = formBarcode(customerNumber, mediaType, mediaNumber);

        let lableText = formBarcode(
          customerNumber,
          mediaType,
          mediaNumber,
          labelFormatData.label_type_code === label_type_code_NA,
          BOOLEAN_STRING_VALUES.TRUE
        );

        return { barcode, lableText };
      });

      if (!barcodes.length) {
        setFormError(t("printTapeLabel.importValidLabel"));
      }

      const zplfileList = barcodes.map((barcode) =>
        labelFormatData.label_template_zpl
          .replace("{0}", barcode.barcode)
          .replace("{1}", barcode.lableText)
      );
      const printLabels = async () => {
        setLoadingPrint(true);

        try {
          for (const zpl of zplfileList) {
            const isPrintSuccess = await executePrint(zpl, {
              darkness_adjustment,
              top_offset_mm,
              left_offset_mm
            });
            if (isPrintSuccess) {
              setLabelSentSuccess(true);
            }
          }
        } catch (error) {
          const errCode = error?.response?.data?.error[0]?.errorCode;
          if (errCode === errorMsgs.errorCode0203) {
            setFormError(t("printTapeLabel.printerNotExistError"));
          } else {
            setFormError(t("printTapeLabel.systemError"));
          }
        } finally {
          setLoadingPrint(false);
        }
      };

      printLabels();

      validParsedData.forEach(async (parsedLine) => {
        let auditRequestBody = {
          ...auditRequestConstantsBody,
          audit_info: t("printTapeLabel.auditInfo", {
            selectedPrinterId: selectedPrinterOption.id,
            customerNumber: parsedLine.customer_number,
            mediaType: parsedLine.media_type,
            mediaNumber: parsedLine.media_number
          }),
          customer_number: parsedLine.customer_number,
          media_type: parsedLine.media_type,
          media_number: parsedLine.media_number
        };

        executeAudit(auditRequestBody);
      });
    }
  };

  // Send request to print the Label
  const executePrint = async (zpl, printerSettings) => {
    const printerID = `sb${selectEnv()}-${
      selectedPrinter.label_printer_id
    }-${districtId}`;

    let adjustedZpl = applyPrinterAdjustments(zpl, printerSettings);
    const reqBody = {
      printerID,
      printType: "zpl",
      jobType: "label",
      jobName: "testjobZPL",
      printData: zplToBase64(adjustedZpl)
    };
    let URL = `${CF_URLS.printTapeLabel.print}`;
    let isPrintSuccess = await getResponseData(reqBody, URL, 1);
    return isPrintSuccess;
  };

  const applyPrinterAdjustments = (zpl, printerSettings) => {
    const lhPattern = /\^LH(\d+),(\d+)/;
    const match = zpl.match(lhPattern);
    if (!match) {
      return zpl;
    }

    const firstValue = parseInt(match[1], 10);
    const secondValue = parseInt(match[2], 10);

    // Convert offset values from mm to dots
    const leftOffSetDots =
      printerSettings.left_offset_mm * mmToDotsConversionConstant;
    const topOffSetDots =
      printerSettings.top_offset_mm * mmToDotsConversionConstant;

    // Compute new xHome and yHome values
    const xHome = Math.max(firstValue + leftOffSetDots, minXandYHome);
    const yHome = Math.max(secondValue, minXandYHome);

    // Construct the new ^LH command
    const newLH = `^LH${xHome},${yHome}`;
    const newZpl = zpl.replace(lhPattern, newLH);

    // Initial format instructions
    let sbFormat = "^XA";

    // Adjust darkness setting
    let darknessAdjustment;
    if (printerSettings.darkness_adjustment > numberZero) {
      darknessAdjustment = Math.min(
        printerSettings.darkness_adjustment,
        darknessAdjustmentConstant.positive
      );
    } else {
      darknessAdjustment = Math.max(
        printerSettings.darkness_adjustment,
        darknessAdjustmentConstant.negative
      );
    }
    sbFormat += `^MD${darknessAdjustment}`;

    let topAdjustment;
    if (topOffSetDots > numberZero) {
      topAdjustment = Math.min(topOffSetDots, topAdjustmentConstant.positive);
    } else {
      topAdjustment = Math.max(topOffSetDots, topAdjustmentConstant.negative);
    }
    sbFormat += `^LT${topAdjustment}`;

    const finalZpl = newZpl.replace("^XA", sbFormat);
    return finalZpl;
  };

  const executeAudit = async (body) => {
    try {
      let URL = `${CF_URLS.printTapeLabel.audit}`;
      await getResponseData(body, URL, 1);
    } catch (error) {
      if (error?.response?.data === unauthorized) {
        setFormError(t("printTapeLabel.sessionLoggedOutMessage"));
      } else {
        setFormError(t("printTapeLabel.systemError"));
      }
    }
  };

  return (
    <Stack
      direction="column"
      gap={spacing.gap}
      marginTop={spacing.margingTop20}
    >
      {/* Loader */}
      {loading && <FullCircularLoader />}
      {/*Success message popup*/}
      {labelSentSuccess && (
        <SuccessPopup
          message={t("printTapeLabel.sentToPrint")}
          close={setLabelSentSuccess}
        />
      )}
      {!!formError && (
        <Collapse in={!!formError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setFormError("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {formError}
          </Alert>
        </Collapse>
      )}
      {!!barcodeHasSpecialCharsInfo && (
        <Collapse in={!!barcodeHasSpecialCharsInfo}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setBarcodeHasSpecialCharsInfo(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {t("printTapeLabel.barcodeHasSpecialChars")}
          </Alert>
        </Collapse>
      )}
      <form onSubmit={handleFormSubmit}>
        <Stack direction={"column"} gap={spacing.gap}>
          <Stack direction="row" gap={spacing.gap}>
            {/* ---------- Printer Selection  ---------- */}
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              popupIcon={<ArrowDown />}
              clearIcon={<CloseIcon />}
              id="print-lable"
              name={fieldNames.printerName}
              size="medium"
              fullWidth
              value={selectedPrinterOption}
              options={printerListOptions}
              onChange={handlePrinterChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("printTapeLabel.printerNameLabel")}
                />
              )}
              sx={printTapeLabelStyles.commonStyles.widthFixed}
            />

            {/* ---------- Printer Info  ---------- */}
            <Box
              sx={
                printTapeLabelStyles.formSaveAndCreatePrinterStyles
                  .descriptionContainer
              }
            >
              <TextField
                label={t("printTapeLabel.printerInfo")}
                name={fieldNames.printerInfo}
                fullWidth
                sx={
                  printTapeLabelStyles.formSaveAndCreatePrinterStyles
                    .textFieldNoBorder
                }
                multiline
                rows={2}
                value={selectedPrinter?.location_description || ""}
                InputLabelProps={{
                  shrink: selectedPrinter?.location_description
                }}
              />
            </Box>
          </Stack>
          <Divider />

          {/* --------  Label Fields Section  -------- */}
          <Grid container spacing={spacing.gap}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {t("printTapeLabel.labelFields")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={null}
                options={labelSourceOptions}
                getOptionLabel={(option) => option.label}
                onChange={handleLabelSource}
                value={sourceSelected}
                id="print-lable"
                name={fieldNames.labelSource}
                size="medium"
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("printTapeLabel.labelSource")}
                  />
                )}
              />
            </Grid>
            {sourceSelected?.value === typeFile ? null : (
              <React.Fragment>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <TextField
                    name={fieldNames.customerNumber}
                    label={t("printTapeLabel.customerNumber")}
                    size="medium"
                    variant="outlined"
                    fullWidth
                    value={customerNumber}
                    onChange={handleCustomerNumberChange}
                    inputProps={{
                      maxLength: 35
                    }}
                  />
                </Grid>
                {sourceSelected?.value === typeSingleLabel ? (
                  <React.Fragment>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <TextField
                        name={fieldNames.mediaType}
                        label={t("printTapeLabel.mediaType")}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={mediaType}
                        onChange={handleMediaTypeChange}
                        inputProps={{
                          maxLength: 35
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <TextField
                        name={fieldNames.mediaNumber}
                        label={t("printTapeLabel.mediaNumber")}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={mediaNumber}
                        error={mediaNumberError}
                        helperText={
                          mediaNumberError
                            ? t("printTapeLabel.mediaNumberRequired")
                            : ""
                        }
                        onChange={handleMediaNumberChange}
                        inputProps={{
                          maxLength: 35
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <TextField
                        name={fieldNames.mediaType}
                        label={t("printTapeLabel.mediaType")}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={mediaType}
                        onChange={handleMediaTypeChange}
                        inputProps={{
                          maxLength: 35
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <TextField
                        name={fieldNames.quantity}
                        label={t("printTapeLabel.quantity")}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={quantity}
                        onChange={handleQuantityChange}
                        error={quantityError}
                        helperText={
                          quantityError
                            ? t("printTapeLabel.quantityIsRequired")
                            : ""
                        }
                        inputProps={{
                          maxLength: 35
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <TextField
                        name={fieldNames.mediaNumberPrefix}
                        label={t("printTapeLabel.mediaNumberPrefix")}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={mediaNumberPrefix}
                        onChange={handleMediaNumberPrefixChange}
                        inputProps={{
                          maxLength: 35
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <TextField
                        name={fieldNames.mediaNumberIncrementer}
                        label={t("printTapeLabel.mediaNumberIncrementer")}
                        size="medium"
                        variant="outlined"
                        value={mediaNumberIncrementer}
                        onChange={handleMediaNumberIncrementerChange}
                        error={mediaNumberIncrementerError}
                        helperText={
                          mediaNumberIncrementerError
                            ? t("printTapeLabel.incrementerIsRequired")
                            : ""
                        }
                        fullWidth
                        inputProps={{
                          maxLength: 35
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <TextField
                        name={fieldNames.mediaNumberSuffix}
                        label={t("printTapeLabel.mediaNumberSuffix")}
                        size="medium"
                        variant="outlined"
                        value={mediaNumberSuffix}
                        onChange={handleMediaNumberSuffixChange}
                        fullWidth
                        inputProps={{
                          maxLength: 35
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Grid>
        </Stack>
        {sourceSelected?.value === typeFile ? (
          <>
            <PrintTapeLabelLayoutTabel
              labelFormatData={labelFormatData}
              validateBarcode={validateBarcode}
              parsedData={parsedData}
              setFormError={setFormError}
              setBarcodeHasSpecialCharsInfo={setBarcodeHasSpecialCharsInfo}
              setParsedData={setParsedData}
              formError={formError}
            />
            <Divider />
          </>
        ) : null}

        <Stack
          direction="row"
          spacing={spacing.margingTop20}
          sx={printTapeLabelStyles.printButtonContainer}
        >
          <Button
            id="print-tape-label-print"
            variant="contained"
            type="submit"
            disabled={loadingPrint}
            sx={printTapeLabelStyles.printButton}
          >
            {t("common.print")}{" "}
            {loadingPrint ? (
              <CircularProgress
                size={18}
                sx={printTapeLabelStyles.printButtonLoader}
              />
            ) : null}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default PrintTapeLabelLayout;
