export const labelPrinterSetupStyles = {
  tablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  alertSize: { width: "20px" },
  ipAddressToolTip: { color: "gray", fontSize: "0.875rem", margin: "10px" },
  descriptionSize: { maxWidth: { sm: "100%", lg: "75%" } },
  labelStockError: { with: "#d32f2f" },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  completeColor: "var(--color-im-mid-green)",
  selectedColor: "var(--color-im-light-blue-100)",
  formStyle: { display: "flex", flexDirection: "row" }
};
