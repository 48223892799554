import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { CircularLoader } from "components/core";
import MediaDestructionRequestDeliveryForm from "components/features/request-module/media-destruction-request-delivery/MediaDestructionRequestDeliveryForm";
import { MediaDestructionRequestDeliveryFormStyles } from "components/features/request-module/media-destruction-request-delivery/MediaDestructionRequestDeliveryFormStyles";
import withLoading from "components/hoc/withLoading";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetDestructRequestDetailsByMutation } from "services/api";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES } from "utils/constants";

function MediaDestructionRequestDelivery() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { currentBranch } = useSelector(selectAuth);
  // eslint-disable-next-line no-unused-vars
  const [destructionId, setDestructionId] = useState(id);
  const [loadingDestructionDetails, setLoadingDestructionDetails] =
    useState(false);
  const [newRequestSaved, setNewRequestSaved] = useState(false);

  //get destruct request details mutation
  const [triggerGetDestructRqDetails] =
    useGetDestructRequestDetailsByMutation();

  const [destructionRqDetails, setDestructionRqDetails] = useState("");
  const [isExistingDestructionRq, setIsExistingDestructionRq] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  //find error message
  const findErrorMessage = (error) => {
    let relatedErrorMessage = "";
    switch (error) {
      case ERROR_TYPES.FAILED:
        relatedErrorMessage = t("mediaDestructionDelivery.failedError");
        break;
      case ERROR_TYPES.EXCEPTION:
        relatedErrorMessage = t("mediaDestructionDelivery.errorOccurred");
        break;
      case ERROR_TYPES.ISSUE:
        relatedErrorMessage = t("mediaDestructionDelivery.somethingWrong");
        break;
      default:
        break;
    }

    return relatedErrorMessage;
  };
  //fetch destruction details object
  const fetchDestructionDetails = async (destrutId) => {
    try {
      newRequestSaved
        ? setLoadingDestructionDetails(false)
        : setLoadingDestructionDetails(true);
      const { data: triggeredData = [] } = await triggerGetDestructRqDetails({
        main_district_id: String(currentBranch?.district_id),
        destruct_request_id: String(destrutId)
      });
      setDestructionRqDetails(triggeredData[0]);
      setIsExistingDestructionRq(triggeredData[0] ? true : false);
      return triggeredData;
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoadingDestructionDetails(false);
    }
  };

  //fetch data when change id and branch
  useEffect(() => {
    fetchDestructionDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentBranch?.district_id]);

  return (
    <CircularLoader loading={loadingDestructionDetails}>
      <Stack id="request_delivery_main_stack">
        <Grid
          id="request_delivery_main_grid_1"
          display="flex"
          direction="row"
          marginTop={spacing.horizontalMargin20}
        >
          <Typography variant="h4">
            {t("mediaDestructionDelivery.pageTitle")}
          </Typography>
          {destructionId && (
            <Typography
              sx={MediaDestructionRequestDeliveryFormStyles.DestructionId}
            >
              {t("mediaDestructionDelivery.destructionIdText")} {destructionId}
            </Typography>
          )}
        </Grid>
        <Collapse in={throwError}>
          <Alert
            severity="warning"
            sx={{ marginBottom: "2em" }}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
        <MediaDestructionRequestDeliveryForm
          setDestructionId={setDestructionId}
          destructionId={destructionId}
          destructionRqDetails={destructionRqDetails}
          setDestructionRqDetails={setDestructionRqDetails}
          isExistingDestructionRq={isExistingDestructionRq}
          setIsExistingDestructionRq={setIsExistingDestructionRq}
          newRequestSaved={newRequestSaved}
          setNewRequestSaved={setNewRequestSaved}
          setLoadingDestructionDetails={setLoadingDestructionDetails}
        />
      </Stack>
    </CircularLoader>
  );
}

export default withLoading(MediaDestructionRequestDelivery);
