export const createDisributionListStyles = {
  fieldSize: {
    minWidth: "310px",
  },
  direction: {
    column: "column",
    row: "row",
  },
  gap: {
    gap: "20px",
  },
  widthFixed: { width: "350px" },
  widthFull: { width: "100%" },
  widthFit: { width: "fit-content" },
  alignCenter: { alignItems: "center" },
  pointerCursor: { cursor: "pointer" },
  heightBox: { height: "20px" },
  disributionTableList: {
    buttons: { textTransform: "none" },
  },
  createDistributionModal: {
    cardContentStack: {
      alignItems: "start",
      marginBottom: "20px",
      minWidth: "720px",
      maxWidth: "720px",
    },
    tfInputLabelPtops: { shrink: true, style: { zIndex: 0 } },
    textFieldSX: { width: "350px", height: "64px" },
    buttonSX: {
      display: "flex",
      padding: "6px 16px !important",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "unset",
      marginLeft: "0px !important",
    },
  },
  modal: {
    description: { marginBottom: "10px !important" },
    box: { height: "30px" },
  },
  tableStyles: {
    tablePaper: {
      width: "100%",
      overflow: "hidden",
    },
    titleStyle: {
      color: "var(--color-im-grey-900)",
    },
    completeColor: "var(--color-im-mid-green)",
    selectedColor: "var(--color-im-light-blue-100)",
    formStyle: { display: "flex", flexDirection: "row" },

    tableColumnWidth: {
      width: "200px",
    },
    slotWidth: {
      width: "300px",
    },
  },
  tableListStyle: {
    buttons: { textTransform: "none" },
    inputTypeFile: {
      display: "none",
    },
  },
  saveBtnStyle: {
    marginTop: "12px",
    gap: "20px",
  },
  textStyle: {
    width: "100%",
    marginBottom: "20px",
  },
  fieldStyle: {
    width: "310px",
    height: "56px",
  },
  errorStyle: {
    marginBottom: "20px",
    gap: "20px",
  },

  FieldWidthMedium: {
    width: "310px",
  },
};

