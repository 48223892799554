import { Stack } from "@mui/material";
import CustomerMoveDetailLayout from "components/customer-detail-module/CustomerMoveDetailLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const CustomerMoveDetail = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("customerMoveDetail.pageTitle")} />
      <CustomerMoveDetailLayout />
    </Stack>
  );
};

export default CustomerMoveDetail;
