import { createSlice } from "@reduxjs/toolkit";
import {
  N_CHECKER,
  REQUEST_ACTIONS,
  REQUEST_NEW_ITEM_TYPE,
  Y_CHECKER
} from "utils/constants";

// deliver media tab initial state
const deliverMedia = {
  openMedias: [],
  containers: []
};

// issue containers tab initial state
const issueContainers = {
  requestNewItemType: REQUEST_NEW_ITEM_TYPE.CONTAINER,
  nextRequestNewItemType: -1, // request new item type which is stopped changing
  billForInsertsFlag: Y_CHECKER,
  logicalVaults: {
    container: [],
    transport: []
  },
  mediaTypes: {
    container: [],
    transport: []
  },
  containers: [],
  extraInserts: [],
  extraItems: { lock_quantity: 0, lock_type_code: "", clip_quantity: 0 }
};

// media request initial state
const initialState = {
  clearRequest: false,
  refetch: false,
  error: { show: false, message: "" },
  info: { show: false, title: "", message: "" },
  activeTab: 0,
  nextTab: -1, // tab index which is stopped changing
  hasLockedTab: false,
  actionId: REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID,
  deliverMedia,
  issueContainers,
  deletableContainers: [],
  transports: { available: [], assigned: [] },
  otherComment: ""
};

/**
 * @name mediaRequestDetailSlice
 */
export const mediaRequestDetailSlice = createSlice({
  name: "mediaRequestDetail",
  initialState,
  reducers: {
    // reset state to default
    resetMediaRequestDetail: () => {
      window.scrollTo({ top: 0 });
      return initialState;
    },
    setClearRequest: (state, { payload }) => {
      state.clearRequest = payload;
      state.refetch = false;
    },
    setRefetch: (state, { payload }) => {
      state.refetch = payload;
    },
    // hide exit confirmation modal
    cancelExitProcedure: (state) => {
      state.nextTab = -1; // clear next tab
      state.issueContainers.nextRequestNewItemType = -1; // clear next request_new_item_type
    },
    // cancel save and clear data
    cancelSaveProcedure: (state) => {
      if (state.nextTab < 0) {
        if (state.issueContainers.nextRequestNewItemType > 0) {
          state.issueContainers.requestNewItemType =
            state.issueContainers.nextRequestNewItemType;
          state.issueContainers.nextRequestNewItemType = -1; // clear next request_new_item_type
        } else {
          window.scrollTo({ top: 0 }); // scroll to the top if the page is cleared
          state.clearRequest = true;
          state.issueContainers.requestNewItemType =
            state.issueContainers.nextRequestNewItemType; // set request_new_item_type to default
        }
      } else {
        state.activeTab = state.nextTab; // set active tab
      }
      state.nextTab = -1; // clear next tab

      // clear state of the current tab
      state.deliverMedia.openMedias = [];
      state.deliverMedia.containers = [];
      state.issueContainers.containers = [];
      state.issueContainers.transports = [];
      state.issueContainers.extraInserts = [];
      state.issueContainers.extraItems = {
        lock_quantity: 0,
        lock_type_code: "",
        clip_quantity: 0
      };
      state.deletableContainers = [];
      state.otherComment = "";
    },
    // set error message and show state
    setErrorMessage: (state, { payload }) => {
      state.error.show = true;
      state.error.message = payload;
      window.scrollTo({ top: 0 });
    },
    // set error message and show state
    setInfoMessage: (state, { payload }) => {
      state.info.show = true;
      state.info.title = payload?.title;
      state.info.message = payload.message;
      window.scrollTo({ top: 0 });
    },
    // remove error message and show state
    removeErrorMessage: (state) => {
      state.error.show = false;
      state.error.message = "";
    },
    // remove error message and show state
    removeInfoMessage: (state) => {
      state.info.show = false;
      state.info.message = "";
      state.info.title = "";
    },
    // set active tab index
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
      if (state.activeTab === 0) {
        state.actionId = REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID;
      } else if (state.activeTab === 1) {
        state.actionId = REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID;
      } else if (state.activeTab === 2) {
        state.actionId = REQUEST_ACTIONS.DELETE_CONTAINERS.ID;
      } else if (state.activeTab === 3) {
        state.actionId = REQUEST_ACTIONS.OTHER.ID;
      }

      if (state.activeTab !== 1)
        state.issueContainers.requestNewItemType =
          REQUEST_NEW_ITEM_TYPE.CONTAINER;
    },
    // set next tab index
    setNextTab: (state, { payload }) => {
      state.nextTab = payload;
    },
    setLockedTab: (state, { payload }) => {
      state.actionId = Number(payload);
      if (state.actionId === REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID) {
        state.activeTab = 0;
      } else if (
        state.actionId === REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID ||
        state.actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID
      ) {
        state.activeTab = 1;
      } else if (state.actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID) {
        state.activeTab = 2;
      } else if (state.actionId === REQUEST_ACTIONS.OTHER.ID) {
        state.activeTab = 3;
      }
      state.hasLockedTab = true;
    },
    // set action id of the request
    setActionId: (state, { payload }) => {
      state.actionId = payload;
    },
    // Deliver Media Tab actions
    setOpenMedias: (state, { payload }) => {
      state.deliverMedia.openMedias = payload;
    },
    addOpenMedia: (state, { payload }) => {
      state.deliverMedia.openMedias.push(payload);
    },
    addOpenMedias: (state, { payload }) => {
      state.deliverMedia.openMedias =
        state.deliverMedia.openMedias.concat(payload);
    },
    // add open medias selected from possible matches and remove the previous volser
    addOpenMediaSelectedFromPossibleMatches: (state, { payload }) => {
      state.deliverMedia.openMedias = state.deliverMedia.openMedias
        .filter((media) => media.volser !== payload.selectedVolser)
        .concat(payload.medias);
    },
    removeOpenMedia: (state, { payload }) => {
      state.deliverMedia.openMedias = state.deliverMedia.openMedias.splice(
        payload,
        1
      );
    },
    setContainers: (state, { payload }) => {
      state.deliverMedia.containers = payload;
    },
    addContainer: (state, { payload }) => {
      state.deliverMedia.containers.push(payload);
    },
    setTransports: (state, { payload }) => {
      state.transports.available = payload.available;
      state.transports.assigned = payload.assigned;
    },
    // Issue Containers/Locks/Inserts Tab actions
    setRequestNewItemType: (state, { payload }) => {
      state.issueContainers.requestNewItemType = payload;
    },
    setNextRequestNewItemType: (state, { payload }) => {
      state.issueContainers.nextRequestNewItemType = payload;
    },
    setContainerLogicalVaults: (state, { payload }) => {
      state.issueContainers.logicalVaults.container = payload;
    },
    setTransportLogicalVaults: (state, { payload }) => {
      state.issueContainers.logicalVaults.transport = payload;
    },
    setContainerMediaTypes: (state, { payload }) => {
      state.issueContainers.mediaTypes.container = payload;
    },
    setTransportMediaTypes: (state, { payload }) => {
      state.issueContainers.mediaTypes.transport = payload;
    },
    setIssueContainersOrTransports: (state, { payload }) => {
      state.issueContainers.containers = payload;
    },
    addIssueContainerOrTransport: (state, { payload }) => {
      state.issueContainers.containers.push(payload);
    },
    updateIssueContainerOrTransport: (state, { payload }) => {
      state.issueContainers.containers[payload.index] = payload.data;
    },
    removeIssueContainerOrTransport: (state, { payload }) => {
      state.issueContainers.containers.splice(payload, 1);
    },
    setBillForInsertsFlag: (state, { payload }) => {
      state.issueContainers.billForInsertsFlag = payload;
    },
    setExtraInserts: (state, { payload }) => {
      state.issueContainers.extraInserts = payload;
    },
    addExtraInsert: (state, { payload }) => {
      state.issueContainers.extraInserts.push(payload);
    },
    updateExtraInsert: (state, { payload }) => {
      state.issueContainers.extraInserts[payload.index] = payload.data;
    },
    removeExtraInsert: (state, { payload }) => {
      state.issueContainers.extraInserts.splice(payload, 1);
    },
    setExtraItems: (state, { payload }) => {
      state.issueContainers.extraItems = payload;
    },
    setIssueContainersOrTransportsAndInserts: (state, { payload }) => {
      state.issueContainers.containers = payload.containers;
      state.issueContainers.extraInserts = payload.extraInserts;
      state.issueContainers.extraItems = payload.extraItems;

      // set requestNewItemType id based on the fetched containers resultset
      if (state.issueContainers.containers.length > 0) {
        if (
          state.issueContainers.containers[0].request_new_item_type_id ===
          REQUEST_NEW_ITEM_TYPE.CONTAINER.toString()
        ) {
          state.issueContainers.requestNewItemType =
            REQUEST_NEW_ITEM_TYPE.CONTAINER;
        } else if (
          state.issueContainers.containers[0].request_new_item_type_id ===
          REQUEST_NEW_ITEM_TYPE.TRANSPORT_PERMANENT.toString()
        ) {
          state.issueContainers.requestNewItemType =
            REQUEST_NEW_ITEM_TYPE.TRANSPORT_PERMANENT;
        } else if (
          state.issueContainers.containers[0].request_new_item_type_id ===
          REQUEST_NEW_ITEM_TYPE.TRANSPORT_EMPTY.toString()
        ) {
          state.issueContainers.requestNewItemType =
            REQUEST_NEW_ITEM_TYPE.TRANSPORT_EMPTY;
        }
      }

      // set requestNewItemType id based on the fetched extra items resultset
      if (
        state.issueContainers.extraItems?.lock_quantity > 0 ||
        state.issueContainers.extraItems?.clip_quantity > 0
      ) {
        state.issueContainers.requestNewItemType =
          REQUEST_NEW_ITEM_TYPE.CONTAINER;
      }

      // set requestNewItemType id based on the fetched extra inserts resultset
      if (state.issueContainers.extraInserts.length > 0) {
        state.issueContainers.requestNewItemType =
          REQUEST_NEW_ITEM_TYPE.CONTAINER;
        state.issueContainers.billForInsertsFlag = Y_CHECKER;

        // set bill for inserts flag
        if (
          state.issueContainers.extraInserts[0]?.bill_for_inserts_flag ===
          N_CHECKER
        ) {
          state.issueContainers.billForInsertsFlag = N_CHECKER;
        }
      }
    },
    // Delete Containers Tab actions
    setDeletableContainers: (state, { payload }) => {
      state.deletableContainers = payload;
    },
    addDeletableContainer: (state, { payload }) => {
      state.deletableContainers.push(payload);
    },
    removeDeletableContainer: (state, { payload }) => {
      state.deletableContainers.splice(payload, 1);
    },
    setOtherComment: (state, { payload }) => {
      state.otherComment = payload;
    }
  }
});

export default mediaRequestDetailSlice.reducer;
