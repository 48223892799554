import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { DatePickerField, FullCircularLoader } from "components/core";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  VALUE_EMPTY_STRING,
  alertSeverity,
  dateFormat,
  errorMsgs,
  unauthorized
} from "utils/constants";
import {
  buildType,
  customerListMinDate,
  customerListType,
  instMsgCodes
} from "utils/constants/disaster-module/EventSearchConstants";
import {
  formatDateByLocale,
  getAuthenticatedUserBranch,
  getResponseData,
  getTheFirestoreErrorMessage
} from "utils/helpers";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";
import OpenMediaContainerGrid from "./open-media-container-grid/OpenMediaContainerGrid";
import { custometListValidateSchema } from "./schema";

let defaultOption = {
  label: " ",
  value: VALUE_EMPTY_STRING
};

const CustomerList = ({
  customerSelected,
  setAddOpenMedia,
  addOpenMedia,
  RequestForm,
  setOpenMediaList,
  openMediaList
}) => {
  const { localeByBranch, timeZoneOffset, auth } = useSelector(selectAuth);
  const [listFiles, setListFiles] = useState([{ ...defaultOption }]);
  const [selectedListFile, setSelectedListFile] = useState({
    ...defaultOption
  });

  const [newMediaType, setNewMediaType] = useState(
    customerListType.distributionList
  );
  const districtId = getAuthenticatedUserBranch();
  const [alert, setAlert] = useState({
    show: false,
    severity: alertSeverity.error,
    message: VALUE_EMPTY_STRING
  });
  const [isLoading, setIsLoading] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [note, setNote] = useState("");
  const [isFindEnable, setIsFindEnable] = useState(false);
  const [isChanged, setIsChanged] = useState(true);
  const [isListFileSelected, setIsListFileSelected] = useState(false);
  const [isClearSelectedEnable, setIsClearSelectedEnable] = useState(false);
  const [timeZoneOffsetChanged, setTimeZoneOffsetChanged] = useState(false); // timezone offset value changed
  const currentDate = useMemo(() => {
    let date = dayjs.utc(new Date());
    if (timeZoneOffset !== null) {
      date = date.utcOffset(parseFloat(timeZoneOffset));
    }
    setTimeZoneOffsetChanged((prevState) => !prevState);
    return date;
  }, [timeZoneOffset]); // minimum date

  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);

  const initFilterState = {
    dateFrom: "",
    dateTo: ""
  };

  const CustomerListForm = useFormik({
    initialValues: initFilterState,
    validationSchema: custometListValidateSchema
  });

  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    window.scrollTo({ top: 0 });
    if (error?.response?.data === unauthorized) {
      setAlert({
        show: true,
        severity: alertSeverity.error,
        message: t("printTapeLabel.sessionLoggedOutMessage")
      });
    } else {
      setAlert({
        show: true,
        severity: alertSeverity.error,
        message: t("printTapeLabel.systemError")
      });
    }
  };

  useEffect(() => {
    if (districtId) {
      getLabel();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const selectedCustomerOpenMedia = openMediaList.filter(
      (openMedia) => openMedia.selected === true
    );
    if (selectedCustomerOpenMedia.length > 0) {
      setIsClearSelectedEnable(true);
    } else {
      setIsClearSelectedEnable(false);
    }
    // eslint-disable-next-line
  }, [openMediaList]);

  useEffect(() => {
    if (Object.keys(CustomerListForm.errors).length > 0) {
      setIsFindEnable(true);
    } else {
      setIsFindEnable(false);
    }
    // eslint-disable-next-line
  }, [dateFrom, dateTo, CustomerListForm.errors, newMediaType]);

  const getLabel = async () => {
    setIsLoading(true);
    let { errorMsg: helperText } = await getTheFirestoreErrorMessage(
      instMsgCodes.msgCode26093
    );
    let { errorMsg: note } = await getTheFirestoreErrorMessage(
      instMsgCodes.msgCode26094
    );
    setHelperText(helperText);
    setNote(note);
    setIsLoading(false);
  };

  const onChangeNewMediaType = (val) => {
    setNewMediaType(val);
    setIsChanged(true);
  };

  const onChangeDateFrom = async (newDateFrom) => {
    const fromDate = newDateFrom;
    CustomerListForm.setFieldValue("dateFrom", fromDate);
    setDateFrom(fromDate);
    setIsChanged(true);
  };

  const onChangeDateTo = (newDateTo) => {
    const toDate = dayjs(newDateTo);
    CustomerListForm.setFieldValue("dateTo", toDate);
    setDateTo(toDate);
    setIsChanged(true);
  };

  const getListFile = async () => {
    try {
      const dateDifference = dateTo.diff(dateFrom, "day");
      if (dateDifference > 30) {
        let { errorMsg: helperText } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode63121
        );
        setAlert({
          show: true,
          severity: alertSeverity.error,
          message: helperText
        });
        setIsFindEnable(true);
        window.scrollTo({ top: 0 });
        return;
      }
      let reqBody = {
        main_district_id: districtId,
        customer_id: customerSelected,
        file_section_type_id: newMediaType,
        from_date: dateFrom.format(dateFormat.universalFormat),
        to_date: dateTo.format(dateFormat.universalFormat),
        session_guid: auth.sessionId
      };
      setIsLoading(true);
      let url = CF_URLS.disasterRecovery.getfilesectionnamesforcustlist;
      let res = await getResponseData(reqBody, url, 1);
      if (res?.data[0][0]?.error) {
        let { errorMsg: helperText } = await getTheFirestoreErrorMessage(
          res?.data[0][0]?.error
        );
        setAlert({
          show: true,
          severity: alertSeverity.error,
          message: helperText
        });
        setIsFindEnable(true);
        window.scrollTo({ top: 0 });
      } else if (res?.data[0].length === 0) {
        let { errorMsg: helperText } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26095
        );
        setAlert({
          show: true,
          severity: alertSeverity.error,
          message: helperText
        });
        setIsFindEnable(true);
        window.scrollTo({ top: 0 });
      } else {
        let lists = await Promise.all(
          res?.data?.[0].map(async (list) => ({
            label: await formatDateByLocale(
              dayjs.utc(list.Created_Datetime),
              localeByBranch
            ),
            value: list.Customer_File_Section_Id,
            date: dayjs.utc(list.Created_Datetime)
          }))
        );
        lists?.sort((a, b) => a.date - b.date);
        setListFiles((prev) => [...prev, ...lists]);
        setIsFindEnable(true);
        setIsChanged(false);
        setAlert({
          show: false,
          severity: alertSeverity.error,
          message: VALUE_EMPTY_STRING
        });
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeListFile = (val) => {
    if (val) {
      setSelectedListFile(val);
      if (val.value !== VALUE_EMPTY_STRING) {
        getCustomerListGrid(val.value);
      } else {
        setIsListFileSelected(false);
      }
    } else {
      setSelectedListFile({ ...defaultOption });
      setIsListFileSelected(false);
    }
  };

  const getCustomerListGrid = async (customerFileSectionId) => {
    try {
      let reqBody = {
        main_district_id: districtId,
        customer_file_section_id: customerFileSectionId,
        file_section_type_id: newMediaType,
        session_guid: auth.sessionId
      };
      setIsLoading(true);
      let url = CF_URLS.disasterRecovery.getCustomerlistMediaData;
      let res = await getResponseData(reqBody, url, 1);

      if (res?.data[0][0]?.error) {
        let { errorMsg: helperText } = await getTheFirestoreErrorMessage(
          res?.data[0][0]?.error
        );
        setAlert({
          show: true,
          severity: alertSeverity.error,
          message: helperText
        });
      } else {
        let openMediaData = res?.data[0].map((openMedia) => {
          openMedia.selected = true;
          return openMedia;
        });
        setOpenMediaList(openMediaData);
        RequestForm.setFieldValue("customerList", "hasOpenMediaData");
        setIsListFileSelected(true);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handelAddRequest = () => {
    onChangeListFile(null);
    addAllSelectedOpenMedia();
  };

  const addAllSelectedOpenMedia = () => {
    const selectedCustomerOpenMedia = openMediaList.filter(
      (openMedia) => openMedia.selected === true
    );
    selectedCustomerOpenMedia.map((selectedMedia) => {
      let existingOpenMedia = addOpenMedia.filter(
        (openMedia) => openMedia.volser === selectedMedia.volser
      );
      if (existingOpenMedia.length === 0) {
        selectedMedia.selected = true;
        setAddOpenMedia((prev) => [...prev, selectedMedia]);
      }
      return selectedMedia;
    });
    setOpenMediaList([]);
    RequestForm.setFieldValue("customerList", VALUE_EMPTY_STRING);
  };

  const handelClearSelected = () => {
    const updatedOpenMediaList = openMediaList.map((openMedia) => {
      openMedia.selected = false;
      return openMedia;
    });
    setOpenMediaList(updatedOpenMediaList);
  };

  const handelReviewAndGenerate = () => {
    addAllSelectedOpenMedia();
    RequestForm.setFieldValue("buildRequest", buildType.landingPage);
  };

  const setClear = () => {
    setIsFindEnable(false);
    setIsChanged(true);
    setIsListFileSelected(false);
    CustomerListForm.resetForm();
    setDateFrom(currentDate);
    setDateTo(currentDate);
    setListFiles([{ ...defaultOption }]);
    setSelectedListFile({ ...defaultOption });
    setNewMediaType(customerListType.distributionList);
    setAlert({
      show: false,
      severity: alertSeverity.error,
      message: VALUE_EMPTY_STRING
    });
  };

  return (
    <>
      {isLoading && <FullCircularLoader />}
      <Box sx={disasterRecoveryStyles.requestGeneratorStyles.rightWrapper}>
        <Box
          ml={spacing.gap}
          sx={disasterRecoveryStyles.requestGeneratorStyles.rightPanelWrapper}
        >
          {alert.show && (
            <Collapse in={alert.show}>
              <Alert
                severity={alert.severity}
                iconMapping={{
                  error: (
                    <img
                      src={ErrorIcon}
                      alt={alert.severity}
                      style={disasterRecoveryStyles.commonStyles.errorIcon}
                    />
                  )
                }}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlert({
                        show: false,
                        severity: alertSeverity.error,
                        message: VALUE_EMPTY_STRING
                      });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {alert.message}
              </Alert>
            </Collapse>
          )}
          <Grid container gap={spacing.gap}>
            <Grid item sx={12} md={12} xl={12}>
              <Stack gap={spacing.gap10}>
                <Typography
                  style={disasterRecoveryStyles.commonStyles.subHeading}
                >
                  {t("disasterRecovery.eventDetail.selectCustomerList")}
                </Typography>
                <FormLabel>
                  <span>{helperText}</span>
                  <span style={disasterRecoveryStyles.inboundDate.noteText}>
                    {note}
                  </span>
                </FormLabel>
              </Stack>
            </Grid>
            <Grid item sx={4}>
              <Stack direction="row" gap={spacing.gap}>
                <FormControl>
                  <FormLabel component="legend">
                    {t("disasterRecovery.eventDetail.customerListType")}
                  </FormLabel>
                  <RadioGroup
                    row
                    name="media-radio-buttons-group"
                    value={newMediaType}
                    onChange={(e) => onChangeNewMediaType(e.target.value)}
                    sx={disasterRecoveryStyles.commonStyles.radioGroup}
                  >
                    <FormControlLabel
                      value={customerListType.distributionList}
                      control={<Radio />}
                      label={t("disasterRecovery.eventDetail.distributionList")}
                    />
                    <FormControlLabel
                      value={customerListType.inventoryFile}
                      control={<Radio />}
                      label={t("disasterRecovery.eventDetail.inventoryFile")}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormLabel component="legend">
                {t("disasterRecovery.eventDetail.dateRange")}
              </FormLabel>
            </Grid>
            <Grid container gap={spacing.gap} wrap="nowrap">
              <Grid item xs={4}>
                <DatePickerField
                  id="dateFrom"
                  name="dateFrom"
                  key={timeZoneOffsetChanged}
                  value={dateFrom}
                  minDate={customerListMinDate}
                  maxDate={currentDate}
                  locale={localeByBranch}
                  onChange={(val) => onChangeDateFrom(val)}
                  label={t("disasterRecovery.eventDetail.dateFrom")}
                  error={!!CustomerListForm.errors.dateFrom}
                  helperText={CustomerListForm.errors.dateFrom}
                />
              </Grid>
              <Grid item xs={4}>
                <DatePickerField
                  id="dateTo"
                  name="dateTo"
                  value={dateTo}
                  minDate={customerListMinDate}
                  maxDate={currentDate}
                  locale={localeByBranch}
                  onChange={(val) => onChangeDateTo(val)}
                  label={t("disasterRecovery.eventDetail.dateTo")}
                  error={!!CustomerListForm.errors.dateTo}
                  helperText={CustomerListForm.errors.dateTo}
                />
              </Grid>
            </Grid>
            <Grid container gap={spacing.gap} wrap="nowrap">
              <Grid item xs={0}>
                <Button onClick={() => setClear()} variant="outlined">
                  {t("common.clear")}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => getListFile()}
                  variant="contained"
                  disabled={isFindEnable}
                >
                  {t("common.find")}
                </Button>
              </Grid>
            </Grid>
            <Grid container gap={spacing.gap}>
              {!isChanged && <Divider />}
            </Grid>
            <Grid container gap={spacing.gap} wrap="nowrap">
              {!isChanged && (
                <Grid item xs={4}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="list-file"
                    disablePortal
                    name={"listFile"}
                    onChange={(e, val) => onChangeListFile(val)}
                    options={listFiles}
                    size="medium"
                    value={selectedListFile}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("disasterRecovery.eventDetail.listFiels")}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid mt={spacing.gap}>
            {" "}
            {!isChanged && isListFileSelected && <Divider />}{" "}
          </Grid>
          {!isChanged && isListFileSelected && (
            <>
              <OpenMediaContainerGrid
                title={
                  newMediaType === customerListType.distributionList
                    ? t("disasterRecovery.eventDetail.distributionList")
                    : t("disasterRecovery.eventDetail.inventoryFile")
                }
                subTitle={t(
                  "disasterRecovery.eventDetail.customerListSubTitle"
                )}
                includeNonIM={true}
                openMediaList={openMediaList}
                setOpenMediaList={setOpenMediaList}
                page={RequestForm.values.buildRequest}
              />
            </>
          )}
        </Box>
        <Box
          sx={disasterRecoveryStyles.tableStyles.displayFlex}
          gap={spacing.gap}
          ml={spacing.gap}
          mt={spacing.gap}
        >
          {!isChanged && isListFileSelected && (
            <>
              <Button
                variant="outlined"
                id="add-request"
                style={disasterRecoveryStyles.tableStyles.buttonsSpace}
                disabled={!isClearSelectedEnable}
                onClick={handelAddRequest}
              >
                {t("disasterRecovery.eventDetail.addToRequest")}
              </Button>
              <Button
                variant="outlined"
                id="clear-selection"
                style={disasterRecoveryStyles.tableStyles.buttonsSpace}
                disabled={!isClearSelectedEnable}
                onClick={handelClearSelected}
              >
                {t("disasterRecovery.eventDetail.clearSelected")}
              </Button>
            </>
          )}
          <Button
            variant="contained"
            id="review-generate-request"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            onClick={handelReviewAndGenerate}
          >
            {t("disasterRecovery.eventDetail.reviewGenerateRequest")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CustomerList;
