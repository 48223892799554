import { t } from "i18next";
export const discrepancyStatusList = [
  {
    label: t("common.all"),
    value: t("common.all")
  },
  {
    label: t("discrepancyManagement.unresolved"),
    value: t("discrepancyManagement.unresolved")
  },
  {
    label: t("discrepancyManagement.notStarted"),
    value: t("discrepancyManagement.notStarted")
  },
  {
    label: t("discrepancyManagement.pendingResolution"),
    value: t("discrepancyManagement.pendingResolution")
  },
  {
    label: t("discrepancyManagement.resolved"),
    value: t("discrepancyManagement.resolved")
  }
];
export const discrepancyMediaList = [
  {
    label: t("common.all"),
    value: t("common.all")
  },
  {
    label: t("discrepancyManagement.container"),
    value: t("discrepancyManagement.container")
  },
  {
    label: t("discrepancyManagement.openMedia"),
    value: t("discrepancyManagement.openMedia")
  }
];
export const discrepancyTypeOrder = [
  t("discrepancyManagement.missing"),
  t("discrepancyManagement.noReturnDateEntered"),
  t("discrepancyManagement.unexpected"),
  t("discrepancyManagement.incompleteStop")
];
export const inventoryAuditSystemFunctionId = "73";
export const defaultStatus = {
  label: t("discrepancyManagement.unresolved"),
  value: t("discrepancyManagement.unresolved")
};
export const indefiniteDate = "01/01/0001";
export const changePage = "changePage";
export const byPassText = "bypass";
export const UploadForStaging = "UploadForStaging";
export const uploadForRunStaging = t("discrepancyManagement.uploadForStaging");
export const UploadForRunPrep = "UploadForRunPrep";
export const uploadForRunPrep = t("discrepancyManagement.uploadForRunPrep");
export const UploadForArrival = "UploadForArrival";
export const uploadForArrival = t("discrepancyManagement.uploadForArrival");
export const Picking = "Picking";
export const picking = t("discrepancyManagement.picking");
export const detectedIn = "detectedIn";
export const inventoryAudit = t("discrepancyManagement.inventoryAudit");
export const audit = t("discrepancyManagement.audit");
export const OMText = "(OM)";
export const TRtext = "TR";
export const tableBodyMaxHeight = "800px";
export const customerMediaDescr = "customerMediaDescr";
export const programSecurityCode326 = "326";
export const programSecurityCode327 = "327";
export const programSecurityCode331 = "331";
export const mediaTypesTitle = "Operations_MediaTypes_";
export const detectedinTitle =
  "Operations_DetectedInForOpenMedia_FileSectionType_";
export const systemFunctionTitle =
  "Operations_DetectedInForContainers_SystemFunction_";
export const descrepancyTypeTitle = "Operations_OpenMediaDiscrepancyTypes_";
export const containerDiscrepancyTitle =
  "Operations_ContainerDiscrepancyTypes_";
export const suggestionTypeTitle = "Operations_SuggestionTypes_";

const discrepancyResolutionTypesLookup = {
  acceptOnRun: "AcceptOnRun",
  autoResolved: "AutoResolved",
  atCustomerLeaveThere: "AtCustomerLeaveThere",
  atCustomerMisScan: "AtCustomerMisScan",
  atCustomerCreateReRun: "AtCustomerCreateReRun",
  deleteMedia: "DeleteMedia",
  keepInDeleteStatus: "KeepInDeleteStatus",
  keepInVaultwithExistingReturnDate: "KeepInVaultwithExistingReturnDate",
  misScanPutInVaultWithReturnDate: "MisScanPutInVaultWithReturnDate",
  misScanPutInVaultWithIndefiniteRetention:
    "MisScanPutInVaultWithIndefiniteRetention",
  misScanPutInVaultWithPendingDistribution:
    "MisScanPutInVaultWithPendingDistribution",
  getTheMediaOnRun: "GetTheMediaOnRun",
  getMediaOnOriginalRun: "GetMediaOnOriginalRun",
  pendingResolution: "PendingResolution",
  createAReRun: "CreateAReRun",
  createASpecialStandard: "CreateASpecialStandard",
  putInVaultWithReturnDate: "PutInVaultWithReturnDate",
  putInVaultWithIndefiniteRetention: "PutInVaultWithIndefiniteRetention",
  putInVaultUsingListInfo: "PutInVaultUsingListInfo",
  putOpenMediaInVaultWithReturnDate: "PutOpenMediaInVaultWithReturnDate",
  putInVaultWithPendingReturnDate: "PutInVaultWithPendingReturnDate",
  putInVaultWithPendingDistribution: "PutInVaultWithPendingDistribution"
};
export const discrepancyResolutionTypes = Object.freeze({
  [discrepancyResolutionTypesLookup.acceptOnRun]: t(
    "discrepancyManagement.accept"
  ),
  [discrepancyResolutionTypesLookup.autoResolved]: t(
    "discrepancyManagement.autoresolv"
  ),
  [discrepancyResolutionTypesLookup.atCustomerLeaveThere]: t(
    "discrepancyManagement.custLeave"
  ),
  [discrepancyResolutionTypesLookup.atCustomerMisScan]: t(
    "discrepancyManagement.custMS"
  ),
  [discrepancyResolutionTypesLookup.atCustomerCreateReRun]: t(
    "discrepancyManagement.custRerun"
  ),
  [discrepancyResolutionTypesLookup.deleteMedia]: t(
    "discrepancyManagement.delete"
  ),
  [discrepancyResolutionTypesLookup.keepInDeleteStatus]: t(
    "discrepancyManagement.keepdelete"
  ),
  [discrepancyResolutionTypesLookup.keepInVaultwithExistingReturnDate]: t(
    "discrepancyManagement.keepexist"
  ),
  [discrepancyResolutionTypesLookup.misScanPutInVaultWithReturnDate]: t(
    "discrepancyManagement.msVaultdt"
  ),
  [discrepancyResolutionTypesLookup.misScanPutInVaultWithIndefiniteRetention]:
    t("discrepancyManagement.msVaultid"),
  [discrepancyResolutionTypesLookup.misScanPutInVaultWithPendingDistribution]:
    t("discrepancyManagement.msVaultpd"),
  [discrepancyResolutionTypesLookup.getTheMediaOnRun]: t(
    "discrepancyManagement.onRun"
  ),
  [discrepancyResolutionTypesLookup.getMediaOnOriginalRun]: t(
    "discrepancyManagement.origRun"
  ),
  [discrepancyResolutionTypesLookup.pendingResolution]: t(
    "discrepancyManagement.pendingres"
  ),
  [discrepancyResolutionTypesLookup.createAReRun]: t(
    "discrepancyManagement.reRun"
  ),
  [discrepancyResolutionTypesLookup.createASpecialStandard]: t(
    "discrepancyManagement.spSTD"
  ),
  [discrepancyResolutionTypesLookup.putInVaultWithReturnDate]: t(
    "discrepancyManagement.vaultDT"
  ),
  [discrepancyResolutionTypesLookup.putInVaultWithIndefiniteRetention]: t(
    "discrepancyManagement.vaultIND"
  ),
  [discrepancyResolutionTypesLookup.putInVaultUsingListInfo]: t(
    "discrepancyManagement.vaultList"
  ),
  [discrepancyResolutionTypesLookup.putOpenMediaInVaultWithReturnDate]: t(
    "discrepancyManagement.vaultOMDT"
  ),
  [discrepancyResolutionTypesLookup.putInVaultWithPendingReturnDate]: t(
    "discrepancyManagement.vaultPDT"
  ),
  [discrepancyResolutionTypesLookup.putInVaultWithPendingDistribution]: t(
    "discrepancyManagement.vaultpd"
  )
});

export const fileSectionTypeIds = Object.freeze({
  1: t("discrepancyManagement.picking"),
  2: t("discrepancyManagement.distribution"),
  3: t("discrepancyManagement.redistribution"),
  4: t("discrepancyManagement.inventory"),
  5: t("discrepancyManagement.dr"),
  6: t("discrepancyManagement.update"),
  8: t("discrepancyManagement.junk"),
  9: t("discrepancyManagement.pickingIntervault"),
  10: t("discrepancyManagement.vaultStatus"),
  11: t("discrepancyManagement.manual"),
  12: t("discrepancyManagement.schedulerContainer"),
  13: t("discrepancyManagement.schedulerOM"),
  14: t("discrepancyManagement.pickingDestruction"),
  16: t("discrepancyManagement.audit"),
  17: t("discrepancyManagement.interactiveSiteScan"),
  18: t("discrepancyManagement.distributionSkipBatch")
}); //use number constants

export const suggestionTypes = Object.freeze({
  Operations_SuggestionTypes_CustReRun: t(
    "discrepancyManagement.atCustomerCreateReRun"
  ),
  Operations_SuggestionTypes_CustLeave: t(
    "discrepancyManagement.atCustomerLeave"
  ),
  Operations_SuggestionTypes_CustMS: t(
    "discrepancyManagement.atCustomerMisScan"
  ),
  Operations_SuggestionTypes_AutoResolv: t(
    "discrepancyManagement.autoResolved"
  ),
  Operations_SuggestionTypes_ReRun: t("discrepancyManagement.createReRun"),
  Operations_SuggestionTypes_SpStd: t(
    "discrepancyManagement.createSpecialStandard"
  ),
  Operations_SuggestionTypes_Delete: t("discrepancyManagement.deleteMedia"),
  Operations_SuggestionTypes_OrigRun: t(
    "discrepancyManagement.getMediaOnOriginalRun"
  ),
  Operations_SuggestionTypes_OnRun: t("discrepancyManagement.getMediaOnRun"),
  Operations_SuggestionTypes_KeepDelete: t(
    "discrepancyManagement.keepDeleteStatus"
  ),
  Operations_SuggestionTypes_KeepExist: t(
    "discrepancyManagement.keepExistingReturnDate"
  ),
  Operations_SuggestionTypes_MSVaultId: t(
    "discrepancyManagement.misScanVaultId"
  ),
  Operations_SuggestionTypes_MSVaultPD: t(
    "discrepancyManagement.misScanVaultPD"
  ),
  Operations_SuggestionTypes_MSVaultDt: t(
    "discrepancyManagement.misScanVaultDt"
  ),
  Operations_SuggestionTypes_PendingRes: t(
    "discrepancyManagement.pendingResolution"
  ),
  Operations_SuggestionTypes_VaultList: t(
    "discrepancyManagement.putInVaultListInfo"
  ),
  Operations_SuggestionTypes_VaultInd: t(
    "discrepancyManagement.putInVaultIndefiniteRetention"
  ),
  Operations_SuggestionTypes_VaultPD: t(
    "discrepancyManagement.putInVaultPendingDistribution"
  ),
  Operations_SuggestionTypes_VaultPDt: t(
    "discrepancyManagement.putInVaultPendingReturnDate"
  ),
  Operations_SuggestionTypes_VaultDt: t(
    "discrepancyManagement.putInVaultReturnDate"
  ),
  Operations_SuggestionTypes_VaultOMDt: t(
    "discrepancyManagement.putOpenMediaInVaultReturnDate"
  )
});

export const resolutionTypes = Object.freeze({
  Operations_SuggestionTypes_custrerun: t(
    "discrepancyManagement.atCustomerCreateReRun"
  ),
  Operations_SuggestionTypes_custleave: t(
    "discrepancyManagement.atCustomerLeave"
  ),
  Operations_SuggestionTypes_custms: t(
    "discrepancyManagement.atCustomerMisScan"
  ),
  Operations_SuggestionTypes_autoresolv: t(
    "discrepancyManagement.autoResolved"
  ),
  Operations_SuggestionTypes_rerun: t("discrepancyManagement.createReRun"),
  Operations_SuggestionTypes_spstd: t(
    "discrepancyManagement.createSpecialStandard"
  ),
  Operations_SuggestionTypes_delete: t("discrepancyManagement.deleteMedia"),
  Operations_SuggestionTypes_origrun: t(
    "discrepancyManagement.getMediaOnOriginalRun"
  ),
  Operations_SuggestionTypes_onrun: t("discrepancyManagement.getMediaOnRun"),
  Operations_SuggestionTypes_keepdelete: t(
    "discrepancyManagement.keepDeleteStatus"
  ),
  Operations_SuggestionTypes_keepexist: t(
    "discrepancyManagement.keepExistingReturnDate"
  ),
  Operations_SuggestionTypes_msvaultid: t(
    "discrepancyManagement.misScanVaultId"
  ),
  Operations_SuggestionTypes_msvaultpd: t(
    "discrepancyManagement.misScanVaultPD"
  ),
  Operations_SuggestionTypes_msvaultdt: t(
    "discrepancyManagement.misScanVaultDt"
  ),
  Operations_SuggestionTypes_pendingres: t(
    "discrepancyManagement.pendingResolution"
  ),
  Operations_SuggestionTypes_vaultlist: t(
    "discrepancyManagement.putInVaultListInfo"
  ),
  Operations_SuggestionTypes_vaultind: t(
    "discrepancyManagement.putInVaultIndefiniteRetention"
  ),
  Operations_SuggestionTypes_vaultpd: t(
    "discrepancyManagement.putInVaultPendingDistribution"
  ),
  Operations_SuggestionTypes_vaultpdt: t(
    "discrepancyManagement.putInVaultPendingReturnDate"
  ),
  Operations_SuggestionTypes_vaultdt: t(
    "discrepancyManagement.putInVaultReturnDate"
  ),

  Operations_SuggestionTypes_accept: t("discrepancyManagement.accept"),

  "Operations_SuggestionTypes_vault-ind": t(
    "discrepancyManagement.putInVaultIndefiniteRetention"
  ),
  "Operations_SuggestionTypes_Vault-Ind": t(
    "discrepancyManagement.putInVaultIndefiniteRetention"
  ),
  "Operations_SuggestionTypes_cust-leave": t(
    "discrepancyManagement.atCustomerLeave"
  ),
  "Operations_SuggestionTypes_cust-Leave": t(
    "discrepancyManagement.atCustomerLeave"
  ),
  "Operations_SuggestionTypes_cust-ms": t(
    "discrepancyManagement.atCustomerMisScan"
  ),
  "Operations_SuggestionTypes_cust-rerun": t(
    "discrepancyManagement.AtCustomerCreateReRun"
  ),
  "Operations_SuggestionTypes_ms-vaultdt": t(
    "discrepancyManagement.misScanVaultDt"
  ),
  "Operations_SuggestionTypes_ms-vaultid": t(
    "discrepancyManagement.misScanVaultId"
  ),
  "Operations_SuggestionTypes_ms-vaultpd": t("discrepancyManagement.msvaultpd"),
  "Operations_SuggestionTypes_sp-std": t(
    "discrepancyManagement.createSpecialStandard"
  ),
  "Operations_SuggestionTypes_vault-dt": t(
    "discrepancyManagement.putInVaultReturnDate"
  ),
  "Operations_SuggestionTypes_vault-list": t(
    "discrepancyManagement.putInVaultListInfo"
  ),
  "Operations_SuggestionTypes_vault-omdt": t(
    "discrepancyManagement.putOpenMediaInVaultReturnDate"
  ),
  "Operations_SuggestionTypes_vault-pdt": t(
    "discrepancyManagement.putInVaultPendingReturnDate"
  )
});
export const openMeidaDiscrepancyTypes = Object.freeze({
  Operations_OpenMediaDiscrepancyTypes_Missing: t(
    "discrepancyManagement.missing"
  ),
  Operations_OpenMediaDiscrepancyTypes_Missing1: t(
    "discrepancyManagement.missing"
  ),
  Operations_OpenMediaDiscrepancyTypes_Missing2: t(
    "discrepancyManagement.notReceived"
  ),
  Operations_OpenMediaDiscrepancyTypes_Missing4: t(
    "discrepancyManagement.notCurrentlyInInventory"
  ),
  Operations_OpenMediaDiscrepancyTypes_Missing9: t(
    "discrepancyManagement.missing"
  ),
  Operations_OpenMediaDiscrepancyTypes_Missing10: t(
    "discrepancyManagement.notCurrentlyInInventory"
  ),
  Operations_OpenMediaDiscrepancyTypes_Missing14: t(
    "discrepancyManagement.missing"
  ),
  Operations_OpenMediaDiscrepancyTypes_Missing16: t(
    "discrepancyManagement.missing"
  ),
  Operations_OpenMediaDiscrepancyTypes_Unexpected: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_OpenMediaDiscrepancyTypes_Unexpected2: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_OpenMediaDiscrepancyTypes_Unexpected3: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_OpenMediaDiscrepancyTypes_Unexpected4: t(
    "discrepancyManagement.notOnCustomerInventory"
  ),
  Operations_OpenMediaDiscrepancyTypes_Unexpected16: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_OpenMediaDiscrepancyTypes_Unexpected17: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_ContainerDiscrepancyTypes_NoReturnDate: t(
    "discrepancyManagement.noReturnDateEntered"
  ),
  Operations_ContainerDiscrepancyTypes_NoReturnDate13: t(
    "discrepancyManagement.noReturnDateEntered"
  )
});

export const containerDescripancyTypes = Object.freeze({
  Operations_ContainerDiscrepancyTypes_incompletestop: t(
    "discrepancyManagement.incompleteStop"
  ),
  Operations_ContainerDiscrepancyTypes_incompletestop13: t(
    "discrepancyManagement.incompleteStop"
  ),
  Operations_ContainerDiscrepancyTypes_missing: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_missing1: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_missing4: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_missing7: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_missing11: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_missing13: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_missing63: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_missing67: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_missing73: t(
    "discrepancyManagement.missing"
  ),
  Operations_ContainerDiscrepancyTypes_unexpected: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_ContainerDiscrepancyTypes_unexpected7: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_ContainerDiscrepancyTypes_unexpected11: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_ContainerDiscrepancyTypes_unexpected13: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_ContainerDiscrepancyTypes_unexpected15: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_ContainerDiscrepancyTypes_unexpected73: t(
    "discrepancyManagement.unexpected"
  ),
  Operations_ContainerDiscrepancyTypes_noreturndate: t(
    "discrepancyManagement.noReturnDateEntered"
  ),
  Operations_ContainerDiscrepancyTypes_noreturndate13: t(
    "discrepancyManagement.noReturnDateEntered"
  )
});
export const detectedInType = Object.freeze({
  Operations_DetectedInForOpenMedia_FileSectionType_1: t(
    "discrepancyManagement.picking"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_2: t(
    "discrepancyManagement.distribution"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_3: t(
    "discrepancyManagement.redistribution"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_4: t(
    "discrepancyManagement.inventory"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_5: t(
    "discrepancyManagement.dr"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_6: t(
    "discrepancyManagement.update"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_8: t(
    "discrepancyManagement.junk"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_9: t(
    "discrepancyManagement.pickingIntervault"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_10: t(
    "discrepancyManagement.vaultStatus"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_12: t(
    "discrepancyManagement.schedulerContainer"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_13: t(
    "discrepancyManagement.schedulerOM"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_14: t(
    "discrepancyManagement.pickingDestruction"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_16: t(
    "discrepancyManagement.audit"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_17: t(
    "discrepancyManagement.interactiveSiteScan"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_18: t(
    "distributionSkipBatch"
  ),
  Operations_DetectedInForContainers_SystemFunction_18: t(
    "discrepancyManagement.addOnRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_29: t(
    "discrepancyManagement.assignMediaToRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_47: t(
    "discrepancyManagement.assignOpenMediaToTransport"
  ),
  Operations_DetectedInForContainers_SystemFunction_17: t(
    "discrepancyManagement.assignRequestToRun"
  ),
  Operations_DetectedInForContainers_SystemFunction_20: t(
    "discrepancyManagement.atIMOSDP"
  ),
  Operations_DetectedInForContainers_SystemFunction_16: t(
    "discrepancyManagement.audit"
  ),
  Operations_DetectedInForContainers_SystemFunction_74: t(
    "discrepancyManagement.autoCompleteRun"
  ),
  Operations_DetectedInForContainers_SystemFunction_56: t(
    "discrepancyManagement.branchOutageRSGP"
  ),
  Operations_DetectedInForContainers_SystemFunction_60: t(
    "discrepancyManagement.branchOutagePickListCompletion"
  ),
  Operations_DetectedInForContainers_SystemFunction_49: t(
    "discrepancyManagement.cancelOpenMediaBatch"
  ),
  Operations_DetectedInForContainers_SystemFunction_19: t(
    "discrepancyManagement.cancelRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_21: t(
    "discrepancyManagement.changeContainerReturnDateStatus"
  ),
  Operations_DetectedInForContainers_SystemFunction_52: t(
    "discrepancyManagement.clientConnectivityParser"
  ),
  Operations_DetectedInForContainers_SystemFunction_53: t(
    "discrepancyManagement.clientConnectivityParserUpdate"
  ),
  Operations_DetectedInForContainers_SystemFunction_76: t(
    "discrepancyManagement.createOMDistributionListCustomerSiteScan"
  ),
  Operations_DetectedInForContainers_SystemFunction_51: t(
    "discrepancyManagement.createOpenMediaDistributionList"
  ),
  Operations_DetectedInForContainers_SystemFunction_50: t(
    "discrepancyManagement.createOpenMediaPickList"
  ),
  Operations_DetectedInForContainers_SystemFunction_72: t(
    "discrepancyManagement.destructionPickingLoad"
  ),
  Operations_DetectedInForContainers_SystemFunction_57: t(
    "discrepancyManagement.disasterRecoveryRequestGeneration"
  ),
  Operations_DetectedInForContainers_SystemFunction_28: t(
    "discrepancyManagement.discrepancyResolution"
  ),
  Operations_DetectedInForContainers_SystemFunction_10: t(
    "discrepancyManagement.downloadForRunPrep"
  ),
  Operations_DetectedInForContainers_SystemFunction_6: t(
    "discrepancyManagement.downloadForStaging"
  ),
  Operations_DetectedInForContainers_SystemFunction_64: t(
    "discrepancyManagement.drEventOutboundComplete"
  ),
  Operations_DetectedInForContainers_SystemFunction_63: t(
    "discrepancyManagement.drEventReconciliation"
  ),
  Operations_DetectedInForContainers_SystemFunction_31: t(
    "discrepancyManagement.finalDownloadForRunPrep"
  ),
  Operations_DetectedInForContainers_SystemFunction_12: t(
    "discrepancyManagement.findArrivalContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_14: t(
    "discrepancyManagement.findDistributionContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_2: t(
    "discrepancyManagement.findPicklistContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_8: t(
    "discrepancyManagement.findRunPrepContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_5: t(
    "discrepancyManagement.findStagingContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_9: t(
    "discrepancyManagement.generateRunList"
  ),
  Operations_DetectedInForContainers_SystemFunction_73: t(
    "discrepancyManagement.inventoryAudit"
  ),
  Operations_DetectedInForContainers_SystemFunction_1: t(
    "discrepancyManagement.issueContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_71: t(
    "discrepancyManagement.mediaDestructionRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_69: t(
    "discrepancyManagement.mediaTypeChangeLabelReprint"
  ),
  Operations_DetectedInForContainers_SystemFunction_44: t(
    "discrepancyManagement.openMediaDiscrepancyResolution"
  ),
  Operations_DetectedInForContainers_SystemFunction_41: t(
    "discrepancyManagement.openMediaDistributionUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_43: t(
    "discrepancyManagement.openMediaDistributionVerify"
  ),
  Operations_DetectedInForContainers_SystemFunction_61: t(
    "discrepancyManagement.openMediaDRDistributionUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_62: t(
    "discrepancyManagement.openMediaDRDistributionUploadExpired"
  ),
  Operations_DetectedInForContainers_SystemFunction_38: t(
    "discrepancyManagement.openMediaIntervaultDownload"
  ),
  Operations_DetectedInForContainers_SystemFunction_40: t(
    "discrepancyManagement.openMediaIntervaultUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_45: t(
    "discrepancyManagement.openMediaInventoryCompare"
  ),
  Operations_DetectedInForContainers_SystemFunction_37: t(
    "discrepancyManagement.openMediaPickingDownload"
  ),
  Operations_DetectedInForContainers_SystemFunction_39: t(
    "discrepancyManagement.openMediaPickingUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_48: t(
    "discrepancyManagement.openMediaResearch"
  ),
  Operations_DetectedInForContainers_SystemFunction_58: t(
    "discrepancyManagement.openMediaScannedPickListUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_15: t(
    "discrepancyManagement.printDistributionList"
  ),
  Operations_DetectedInForContainers_SystemFunction_42: t(
    "discrepancyManagement.printOpenMediaDistributionReport"
  ),
  Operations_DetectedInForContainers_SystemFunction_36: t(
    "discrepancyManagement.printOpenMediaIntervaultReport"
  ),
  Operations_DetectedInForContainers_SystemFunction_35: t(
    "discrepancyManagement.printOpenMediaPickReport"
  ),
  Operations_DetectedInForContainers_SystemFunction_3: t(
    "discrepancyManagement.printPicklist"
  ),
  Operations_DetectedInForContainers_SystemFunction_46: t(
    "discrepancyManagement.publishSkipPublishVaultStatus"
  ),
  Operations_DetectedInForContainers_SystemFunction_34: t(
    "discrepancyManagement.reassignTransportContentsAssign"
  ),
  Operations_DetectedInForContainers_SystemFunction_59: t(
    "discrepancyManagement.reassignTransportContentsRemove"
  ),
  Operations_DetectedInForContainers_SystemFunction_33: t(
    "discrepancyManagement.releaseMediaFromSlot"
  ),
  Operations_DetectedInForContainers_SystemFunction_32: t(
    "discrepancyManagement.removeMediaFromRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_30: t(
    "discrepancyManagement.removeMediaFromRun"
  ),
  Operations_DetectedInForContainers_SystemFunction_65: t(
    "discrepancyManagement.reprintContainerTransportLabel"
  ),
  Operations_DetectedInForContainers_SystemFunction_25: t(
    "discrepancyManagement.requestContainerDeletion"
  ),
  Operations_DetectedInForContainers_SystemFunction_23: t(
    "discrepancyManagement.requestMediaDelivery"
  ),
  Operations_DetectedInForContainers_SystemFunction_26: t(
    "discrepancyManagement.requestMediaDeliveryAtCustomer"
  ),
  Operations_DetectedInForContainers_SystemFunction_24: t(
    "discrepancyManagement.requestMediaPickup"
  ),
  Operations_DetectedInForContainers_SystemFunction_22: t(
    "discrepancyManagement.requestNewContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_27: t(
    "discrepancyManagement.rescheduleRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_55: t(
    "discrepancyManagement.runManagementMove"
  ),
  Operations_DetectedInForContainers_SystemFunction_54: t(
    "discrepancyManagement.runManagementRSGP"
  ),
  Operations_DetectedInForContainers_SystemFunction_75: t(
    "discrepancyManagement.uploadAssignTransport"
  ),
  Operations_DetectedInForContainers_SystemFunction_13: t(
    "discrepancyManagement.uploadForArrival"
  ),
  Operations_DetectedInForContainers_SystemFunction_67: t(
    "discrepancyManagement.uploadForDelivery"
  ),
  Operations_DetectedInForContainers_SystemFunction_66: t(
    "discrepancyManagement.uploadForDistribution"
  ),
  Operations_DetectedInForContainers_SystemFunction_68: t(
    "discrepancyManagement.uploadForPickup"
  ),
  Operations_DetectedInForContainers_SystemFunction_11: t(
    "discrepancyManagement.uploadForRunPrep"
  ),
  Operations_DetectedInForContainers_SystemFunction_7: t(
    "discrepancyManagement.uploadForStaging"
  ),
  Operations_DetectedInForContainers_SystemFunction_4: t(
    "discrepancyManagement.verifyPicklist"
  )
});
export const mediaTypes = Object.freeze({
  Operations_MediaTypes_1: t(
    "discrepancyManagement.mediaTypes.mediaIndicator1"
  ),
  Operations_MediaTypes_2: t(
    "discrepancyManagement.mediaTypes.mediaIndicator2"
  ),
  Operations_MediaTypes_3: t(
    "discrepancyManagement.mediaTypes.mediaIndicator3"
  ),
  Operations_MediaTypes_149: t(
    "discrepancyManagement.mediaTypes.mediaIndicator149"
  ),
  Operations_MediaTypes_4: t(
    "discrepancyManagement.mediaTypes.mediaIndicator4"
  ),
  Operations_MediaTypes_5: t(
    "discrepancyManagement.mediaTypes.mediaIndicator5"
  ),
  Operations_MediaTypes_6: t(
    "discrepancyManagement.mediaTypes.mediaIndicator6"
  ),
  Operations_MediaTypes_7: t(
    "discrepancyManagement.mediaTypes.mediaIndicator7"
  ),
  Operations_MediaTypes_165: t(
    "discrepancyManagement.mediaTypes.mediaIndicator165"
  ),
  Operations_MediaTypes_8: t(
    "discrepancyManagement.mediaTypes.mediaIndicator8"
  ),
  Operations_MediaTypes_189: t(
    "discrepancyManagement.mediaTypes.mediaIndicator189"
  ),
  Operations_MediaTypes_156: t(
    "discrepancyManagement.mediaTypes.mediaIndicator156"
  ),
  Operations_MediaTypes_9: t(
    "discrepancyManagement.mediaTypes.mediaIndicator9"
  ),
  Operations_MediaTypes_167: t(
    "discrepancyManagement.mediaTypes.mediaIndicator167"
  ),
  Operations_MediaTypes_10: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10"
  ),
  Operations_MediaTypes_11: t(
    "discrepancyManagement.mediaTypes.mediaIndicator11"
  ),
  Operations_MediaTypes_187: t(
    "discrepancyManagement.mediaTypes.mediaIndicator187"
  ),
  Operations_MediaTypes_12: t(
    "discrepancyManagement.mediaTypes.mediaIndicator12"
  ),
  Operations_MediaTypes_13: t(
    "discrepancyManagement.mediaTypes.mediaIndicator13"
  ),
  Operations_MediaTypes_14: t(
    "discrepancyManagement.mediaTypes.mediaIndicator14"
  ),
  Operations_MediaTypes_15: t(
    "discrepancyManagement.mediaTypes.mediaIndicator15"
  ),
  Operations_MediaTypes_16: t(
    "discrepancyManagement.mediaTypes.mediaIndicator16"
  ),
  Operations_MediaTypes_17: t(
    "discrepancyManagement.mediaTypes.mediaIndicator17"
  ),
  Operations_MediaTypes_18: t(
    "discrepancyManagement.mediaTypes.mediaIndicator18"
  ),
  Operations_MediaTypes_19: t(
    "discrepancyManagement.mediaTypes.mediaIndicator19"
  ),
  Operations_MediaTypes_20: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20"
  ),
  Operations_MediaTypes_21: t(
    "discrepancyManagement.mediaTypes.mediaIndicator21"
  ),
  Operations_MediaTypes_190: t(
    "discrepancyManagement.mediaTypes.mediaIndicator190"
  ),
  Operations_MediaTypes_22: t(
    "discrepancyManagement.mediaTypes.mediaIndicator22"
  ),
  Operations_MediaTypes_23: t(
    "discrepancyManagement.mediaTypes.mediaIndicator23"
  ),
  Operations_MediaTypes_24: t(
    "discrepancyManagement.mediaTypes.mediaIndicator24"
  ),
  Operations_MediaTypes_25: t(
    "discrepancyManagement.mediaTypes.mediaIndicator25"
  ),
  Operations_MediaTypes_26: t(
    "discrepancyManagement.mediaTypes.mediaIndicator26"
  ),
  Operations_MediaTypes_191: t(
    "discrepancyManagement.mediaTypes.mediaIndicator191"
  ),
  Operations_MediaTypes_27: t(
    "discrepancyManagement.mediaTypes.mediaIndicator27"
  ),
  Operations_MediaTypes_168: t(
    "discrepancyManagement.mediaTypes.mediaIndicator168"
  ),
  Operations_MediaTypes_28: t(
    "discrepancyManagement.mediaTypes.mediaIndicator28"
  ),
  Operations_MediaTypes_29: t(
    "discrepancyManagement.mediaTypes.mediaIndicator29"
  ),
  Operations_MediaTypes_118: t(
    "discrepancyManagement.mediaTypes.mediaIndicator118"
  ),
  Operations_MediaTypes_166: t(
    "discrepancyManagement.mediaTypes.mediaIndicator166"
  ),
  Operations_MediaTypes_157: t(
    "discrepancyManagement.mediaTypes.mediaIndicator157"
  ),
  Operations_MediaTypes_163: t(
    "discrepancyManagement.mediaTypes.mediaIndicator163"
  ),
  Operations_MediaTypes_88: t(
    "discrepancyManagement.mediaTypes.mediaIndicator88"
  ),
  Operations_MediaTypes_89: t(
    "discrepancyManagement.mediaTypes.mediaIndicator89"
  ),
  Operations_MediaTypes_90: t(
    "discrepancyManagement.mediaTypes.mediaIndicator90"
  ),
  Operations_MediaTypes_91: t(
    "discrepancyManagement.mediaTypes.mediaIndicator91"
  ),
  Operations_MediaTypes_92: t(
    "discrepancyManagement.mediaTypes.mediaIndicator92"
  ),
  Operations_MediaTypes_93: t(
    "discrepancyManagement.mediaTypes.mediaIndicator93"
  ),
  Operations_MediaTypes_125: t(
    "discrepancyManagement.mediaTypes.mediaIndicator125"
  ),
  Operations_MediaTypes_127: t(
    "discrepancyManagement.mediaTypes.mediaIndicator127"
  ),
  Operations_MediaTypes_30: t(
    "discrepancyManagement.mediaTypes.mediaIndicator30"
  ),
  Operations_MediaTypes_169: t(
    "discrepancyManagement.mediaTypes.mediaIndicator169"
  ),
  Operations_MediaTypes_94: t(
    "discrepancyManagement.mediaTypes.mediaIndicator94"
  ),
  Operations_MediaTypes_95: t(
    "discrepancyManagement.mediaTypes.mediaIndicator95"
  ),
  Operations_MediaTypes_96: t(
    "discrepancyManagement.mediaTypes.mediaIndicator96"
  ),
  Operations_MediaTypes_31: t(
    "discrepancyManagement.mediaTypes.mediaIndicator31"
  ),
  Operations_MediaTypes_98: t(
    "discrepancyManagement.mediaTypes.mediaIndicator98"
  ),
  Operations_MediaTypes_32: t(
    "discrepancyManagement.mediaTypes.mediaIndicator32"
  ),
  Operations_MediaTypes_99: t(
    "discrepancyManagement.mediaTypes.mediaIndicator99"
  ),
  Operations_MediaTypes_138: t(
    "discrepancyManagement.mediaTypes.mediaIndicator138"
  ),
  Operations_MediaTypes_33: t(
    "discrepancyManagement.mediaTypes.mediaIndicator33"
  ),
  Operations_MediaTypes_34: t(
    "discrepancyManagement.mediaTypes.mediaIndicator34"
  ),
  Operations_MediaTypes_35: t(
    "discrepancyManagement.mediaTypes.mediaIndicator35"
  ),
  Operations_MediaTypes_36: t(
    "discrepancyManagement.mediaTypes.mediaIndicator36"
  ),
  Operations_MediaTypes_155: t(
    "discrepancyManagement.mediaTypes.mediaIndicator155"
  ),
  Operations_MediaTypes_196: t(
    "discrepancyManagement.mediaTypes.mediaIndicator196"
  ),
  Operations_MediaTypes_197: t(
    "discrepancyManagement.mediaTypes.mediaIndicator197"
  ),
  Operations_MediaTypes_37: t(
    "discrepancyManagement.mediaTypes.mediaIndicator37"
  ),
  Operations_MediaTypes_38: t(
    "discrepancyManagement.mediaTypes.mediaIndicator38"
  ),
  Operations_MediaTypes_39: t(
    "discrepancyManagement.mediaTypes.mediaIndicator39"
  ),
  Operations_MediaTypes_135: t(
    "discrepancyManagement.mediaTypes.mediaIndicator135"
  ),
  Operations_MediaTypes_40: t(
    "discrepancyManagement.mediaTypes.mediaIndicator40"
  ),
  Operations_MediaTypes_170: t(
    "discrepancyManagement.mediaTypes.mediaIndicator170"
  ),
  Operations_MediaTypes_41: t(
    "discrepancyManagement.mediaTypes.mediaIndicator41"
  ),
  Operations_MediaTypes_42: t(
    "discrepancyManagement.mediaTypes.mediaIndicator42"
  ),
  Operations_MediaTypes_171: t(
    "discrepancyManagement.mediaTypes.mediaIndicator171"
  ),
  Operations_MediaTypes_123: t(
    "discrepancyManagement.mediaTypes.mediaIndicator123"
  ),
  Operations_MediaTypes_148: t(
    "discrepancyManagement.mediaTypes.mediaIndicator148"
  ),
  Operations_MediaTypes_131: t(
    "discrepancyManagement.mediaTypes.mediaIndicator131"
  ),
  Operations_MediaTypes_153: t(
    "discrepancyManagement.mediaTypes.mediaIndicator153"
  ),
  Operations_MediaTypes_160: t(
    "discrepancyManagement.mediaTypes.mediaIndicator160"
  ),
  Operations_MediaTypes_120: t(
    "discrepancyManagement.mediaTypes.mediaIndicator120"
  ),
  Operations_MediaTypes_195: t(
    "discrepancyManagement.mediaTypes.mediaIndicator195"
  ),
  Operations_MediaTypes_43: t(
    "discrepancyManagement.mediaTypes.mediaIndicator43"
  ),
  Operations_MediaTypes_44: t(
    "discrepancyManagement.mediaTypes.mediaIndicator44"
  ),
  Operations_MediaTypes_159: t(
    "discrepancyManagement.mediaTypes.mediaIndicator159"
  ),
  Operations_MediaTypes_45: t(
    "discrepancyManagement.mediaTypes.mediaIndicator45"
  ),
  Operations_MediaTypes_172: t(
    "discrepancyManagement.mediaTypes.mediaIndicator172"
  ),
  Operations_MediaTypes_162: t(
    "discrepancyManagement.mediaTypes.mediaIndicator162"
  ),
  Operations_MediaTypes_173: t(
    "discrepancyManagement.mediaTypes.mediaIndicator173"
  ),
  Operations_MediaTypes_134: t(
    "discrepancyManagement.mediaTypes.mediaIndicator134"
  ),
  Operations_MediaTypes_154: t(
    "discrepancyManagement.mediaTypes.mediaIndicator154"
  ),
  Operations_MediaTypes_145: t(
    "discrepancyManagement.mediaTypes.mediaIndicator145"
  ),
  Operations_MediaTypes_122: t(
    "discrepancyManagement.mediaTypes.mediaIndicator122"
  ),
  Operations_MediaTypes_194: t(
    "discrepancyManagement.mediaTypes.mediaIndicator194"
  ),
  Operations_MediaTypes_174: t(
    "discrepancyManagement.mediaTypes.mediaIndicator174"
  ),
  Operations_MediaTypes_46: t(
    "discrepancyManagement.mediaTypes.mediaIndicator46"
  ),
  Operations_MediaTypes_175: t(
    "discrepancyManagement.mediaTypes.mediaIndicator175"
  ),
  Operations_MediaTypes_47: t(
    "discrepancyManagement.mediaTypes.mediaIndicator47"
  ),
  Operations_MediaTypes_48: t(
    "discrepancyManagement.mediaTypes.mediaIndicator48"
  ),
  Operations_MediaTypes_49: t(
    "discrepancyManagement.mediaTypes.mediaIndicator49"
  ),
  Operations_MediaTypes_133: t(
    "discrepancyManagement.mediaTypes.mediaIndicator133"
  ),
  Operations_MediaTypes_132: t(
    "discrepancyManagement.mediaTypes.mediaIndicator132"
  ),
  Operations_MediaTypes_158: t(
    "discrepancyManagement.mediaTypes.mediaIndicator158"
  ),
  Operations_MediaTypes_164: t(
    "discrepancyManagement.mediaTypes.mediaIndicator164"
  ),
  Operations_MediaTypes_100: t(
    "discrepancyManagement.mediaTypes.mediaIndicator100"
  ),
  Operations_MediaTypes_101: t(
    "discrepancyManagement.mediaTypes.mediaIndicator101"
  ),
  Operations_MediaTypes_102: t(
    "discrepancyManagement.mediaTypes.mediaIndicator102"
  ),
  Operations_MediaTypes_103: t(
    "discrepancyManagement.mediaTypes.mediaIndicator103"
  ),
  Operations_MediaTypes_104: t(
    "discrepancyManagement.mediaTypes.mediaIndicator104"
  ),
  Operations_MediaTypes_105: t(
    "discrepancyManagement.mediaTypes.mediaIndicator105"
  ),
  Operations_MediaTypes_50: t(
    "discrepancyManagement.mediaTypes.mediaIndicator50"
  ),
  Operations_MediaTypes_128: t(
    "discrepancyManagement.mediaTypes.mediaIndicator128"
  ),
  Operations_MediaTypes_106: t(
    "discrepancyManagement.mediaTypes.mediaIndicator106"
  ),
  Operations_MediaTypes_51: t(
    "discrepancyManagement.mediaTypes.mediaIndicator51"
  ),
  Operations_MediaTypes_150: t(
    "discrepancyManagement.mediaTypes.mediaIndicator150"
  ),
  Operations_MediaTypes_107: t(
    "discrepancyManagement.mediaTypes.mediaIndicator107"
  ),
  Operations_MediaTypes_108: t(
    "discrepancyManagement.mediaTypes.mediaIndicator108"
  ),
  Operations_MediaTypes_52: t(
    "discrepancyManagement.mediaTypes.mediaIndicator52"
  ),
  Operations_MediaTypes_109: t(
    "discrepancyManagement.mediaTypes.mediaIndicator109"
  ),
  Operations_MediaTypes_176: t(
    "discrepancyManagement.mediaTypes.mediaIndicator176"
  ),
  Operations_MediaTypes_136: t(
    "discrepancyManagement.mediaTypes.mediaIndicator136"
  ),
  Operations_MediaTypes_110: t(
    "discrepancyManagement.mediaTypes.mediaIndicator110"
  ),
  Operations_MediaTypes_192: t(
    "discrepancyManagement.mediaTypes.mediaIndicator192"
  ),
  Operations_MediaTypes_53: t(
    "discrepancyManagement.mediaTypes.mediaIndicator53"
  ),
  Operations_MediaTypes_177: t(
    "discrepancyManagement.mediaTypes.mediaIndicator177"
  ),
  Operations_MediaTypes_54: t(
    "discrepancyManagement.mediaTypes.mediaIndicator54"
  ),
  Operations_MediaTypes_55: t(
    "discrepancyManagement.mediaTypes.mediaIndicator55"
  ),
  Operations_MediaTypes_142: t(
    "discrepancyManagement.mediaTypes.mediaIndicator142"
  ),
  Operations_MediaTypes_119: t(
    "discrepancyManagement.mediaTypes.mediaIndicator119"
  ),
  Operations_MediaTypes_126: t(
    "discrepancyManagement.mediaTypes.mediaIndicator126"
  ),
  Operations_MediaTypes_56: t(
    "discrepancyManagement.mediaTypes.mediaIndicator56"
  ),
  Operations_MediaTypes_111: t(
    "discrepancyManagement.mediaTypes.mediaIndicator111"
  ),
  Operations_MediaTypes_129: t(
    "discrepancyManagement.mediaTypes.mediaIndicator129"
  ),
  Operations_MediaTypes_57: t(
    "discrepancyManagement.mediaTypes.mediaIndicator57"
  ),
  Operations_MediaTypes_58: t(
    "discrepancyManagement.mediaTypes.mediaIndicator58"
  ),
  Operations_MediaTypes_147: t(
    "discrepancyManagement.mediaTypes.mediaIndicator147"
  ),
  Operations_MediaTypes_59: t(
    "discrepancyManagement.mediaTypes.mediaIndicator59"
  ),
  Operations_MediaTypes_112: t(
    "discrepancyManagement.mediaTypes.mediaIndicator112"
  ),
  Operations_MediaTypes_178: t(
    "discrepancyManagement.mediaTypes.mediaIndicator178"
  ),
  Operations_MediaTypes_179: t(
    "discrepancyManagement.mediaTypes.mediaIndicator179"
  ),
  Operations_MediaTypes_130: t(
    "discrepancyManagement.mediaTypes.mediaIndicator130"
  ),
  Operations_MediaTypes_113: t(
    "discrepancyManagement.mediaTypes.mediaIndicator113"
  ),
  Operations_MediaTypes_60: t(
    "discrepancyManagement.mediaTypes.mediaIndicator60"
  ),
  Operations_MediaTypes_114: t(
    "discrepancyManagement.mediaTypes.mediaIndicator114"
  ),
  Operations_MediaTypes_124: t(
    "discrepancyManagement.mediaTypes.mediaIndicator124"
  ),
  Operations_MediaTypes_61: t(
    "discrepancyManagement.mediaTypes.mediaIndicator61"
  ),
  Operations_MediaTypes_115: t(
    "discrepancyManagement.mediaTypes.mediaIndicator115"
  ),
  Operations_MediaTypes_62: t(
    "discrepancyManagement.mediaTypes.mediaIndicator62"
  ),
  Operations_MediaTypes_63: t(
    "discrepancyManagement.mediaTypes.mediaIndicator63"
  ),
  Operations_MediaTypes_64: t(
    "discrepancyManagement.mediaTypes.mediaIndicator64"
  ),
  Operations_MediaTypes_65: t(
    "discrepancyManagement.mediaTypes.mediaIndicator65"
  ),
  Operations_MediaTypes_66: t(
    "discrepancyManagement.mediaTypes.mediaIndicator66"
  ),
  Operations_MediaTypes_67: t(
    "discrepancyManagement.mediaTypes.mediaIndicator67"
  ),
  Operations_MediaTypes_68: t(
    "discrepancyManagement.mediaTypes.mediaIndicator68"
  ),
  Operations_MediaTypes_69: t(
    "discrepancyManagement.mediaTypes.mediaIndicator69"
  ),
  Operations_MediaTypes_70: t(
    "discrepancyManagement.mediaTypes.mediaIndicator70"
  ),
  Operations_MediaTypes_71: t(
    "discrepancyManagement.mediaTypes.mediaIndicator71"
  ),
  Operations_MediaTypes_151: t(
    "discrepancyManagement.mediaTypes.mediaIndicator151"
  ),
  Operations_MediaTypes_72: t(
    "discrepancyManagement.mediaTypes.mediaIndicator72"
  ),
  Operations_MediaTypes_73: t(
    "discrepancyManagement.mediaTypes.mediaIndicator73"
  ),
  Operations_MediaTypes_152: t(
    "discrepancyManagement.mediaTypes.mediaIndicator152"
  ),
  Operations_MediaTypes_74: t(
    "discrepancyManagement.mediaTypes.mediaIndicator74"
  ),
  Operations_MediaTypes_188: t(
    "discrepancyManagement.mediaTypes.mediaIndicator188"
  ),
  Operations_MediaTypes_75: t(
    "discrepancyManagement.mediaTypes.mediaIndicator75"
  ),
  Operations_MediaTypes_76: t(
    "discrepancyManagement.mediaTypes.mediaIndicator76"
  ),
  Operations_MediaTypes_77: t(
    "discrepancyManagement.mediaTypes.mediaIndicator77"
  ),
  Operations_MediaTypes_78: t(
    "discrepancyManagement.mediaTypes.mediaIndicator78"
  ),
  Operations_MediaTypes_79: t(
    "discrepancyManagement.mediaTypes.mediaIndicator79"
  ),
  Operations_MediaTypes_80: t(
    "discrepancyManagement.mediaTypes.mediaIndicator80"
  ),
  Operations_MediaTypes_81: t(
    "discrepancyManagement.mediaTypes.mediaIndicator81"
  ),
  Operations_MediaTypes_200: t(
    "discrepancyManagement.mediaTypes.mediaIndicator200"
  ),
  Operations_MediaTypes_137: t(
    "discrepancyManagement.mediaTypes.mediaIndicator137"
  ),
  Operations_MediaTypes_146: t(
    "discrepancyManagement.mediaTypes.mediaIndicator146"
  ),
  Operations_MediaTypes_82: t(
    "discrepancyManagement.mediaTypes.mediaIndicator82"
  ),
  Operations_MediaTypes_180: t(
    "discrepancyManagement.mediaTypes.mediaIndicator180"
  ),
  Operations_MediaTypes_116: t(
    "discrepancyManagement.mediaTypes.mediaIndicator116"
  ),
  Operations_MediaTypes_83: t(
    "discrepancyManagement.mediaTypes.mediaIndicator83"
  ),
  Operations_MediaTypes_84: t(
    "discrepancyManagement.mediaTypes.mediaIndicator84"
  ),
  Operations_MediaTypes_143: t(
    "discrepancyManagement.mediaTypes.mediaIndicator143"
  ),
  Operations_MediaTypes_205: t(
    "discrepancyManagement.mediaTypes.mediaIndicator205"
  ),
  Operations_MediaTypes_121: t(
    "discrepancyManagement.mediaTypes.mediaIndicator121"
  ),
  Operations_MediaTypes_202: t(
    "discrepancyManagement.mediaTypes.mediaIndicator202"
  ),
  Operations_MediaTypes_85: t(
    "discrepancyManagement.mediaTypes.mediaIndicator85"
  ),
  Operations_MediaTypes_182: t(
    "discrepancyManagement.mediaTypes.mediaIndicator182"
  ),
  Operations_MediaTypes_86: t(
    "discrepancyManagement.mediaTypes.mediaIndicator86"
  ),
  Operations_MediaTypes_181: t(
    "discrepancyManagement.mediaTypes.mediaIndicator181"
  ),
  Operations_MediaTypes_193: t(
    "discrepancyManagement.mediaTypes.mediaIndicator193"
  ),
  Operations_MediaTypes_161: t(
    "discrepancyManagement.mediaTypes.mediaIndicator161"
  ),
  Operations_MediaTypes_183: t(
    "discrepancyManagement.mediaTypes.mediaIndicator183"
  ),
  Operations_MediaTypes_97: t(
    "discrepancyManagement.mediaTypes.mediaIndicator97"
  ),
  Operations_MediaTypes_117: t(
    "discrepancyManagement.mediaTypes.mediaIndicator117"
  ),
  Operations_MediaTypes_87: t(
    "discrepancyManagement.mediaTypes.mediaIndicator87"
  ),
  Operations_MediaTypes_144: t(
    "discrepancyManagement.mediaTypes.mediaIndicator144"
  ),
  Operations_MediaTypes_199: t(
    "discrepancyManagement.mediaTypes.mediaIndicator199"
  ),
  Operations_MediaTypes_204: t(
    "discrepancyManagement.mediaTypes.mediaIndicator204"
  ),
  Operations_MediaTypes_139: t(
    "discrepancyManagement.mediaTypes.mediaIndicator139"
  ),
  Operations_MediaTypes_203: t(
    "discrepancyManagement.mediaTypes.mediaIndicator203"
  ),
  Operations_MediaTypes_198: t(
    "discrepancyManagement.mediaTypes.mediaIndicator198"
  ),
  Operations_MediaTypes_140: t(
    "discrepancyManagement.mediaTypes.mediaIndicator140"
  ),
  Operations_MediaTypes_201: t(
    "discrepancyManagement.mediaTypes.mediaIndicator201"
  ),
  Operations_MediaTypes_141: t(
    "discrepancyManagement.mediaTypes.mediaIndicator141"
  ),
  Operations_MediaTypes_186: t(
    "discrepancyManagement.mediaTypes.mediaIndicator186"
  ),
  Operations_MediaTypes_185: t(
    "discrepancyManagement.mediaTypes.mediaIndicator185"
  ),
  Operations_MediaTypes_184: t(
    "discrepancyManagement.mediaTypes.mediaIndicator184"
  ),
  Operations_MediaTypes_10001: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10001"
  ),
  Operations_MediaTypes_10002: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10002"
  ),
  Operations_MediaTypes_10003: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10003"
  ),
  Operations_MediaTypes_10004: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10004"
  ),
  Operations_MediaTypes_10005: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10005"
  ),
  Operations_MediaTypes_10006: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10006"
  ),
  Operations_MediaTypes_10007: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10007"
  ),
  Operations_MediaTypes_10008: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10008"
  ),
  Operations_MediaTypes_10009: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10009"
  ),
  Operations_MediaTypes_10010: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10010"
  ),
  Operations_MediaTypes_10011: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10011"
  ),
  Operations_MediaTypes_10012: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10012"
  ),
  Operations_MediaTypes_10013: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10013"
  ),
  Operations_MediaTypes_10014: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10014"
  ),
  Operations_MediaTypes_10015: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10015"
  ),
  Operations_MediaTypes_10033: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10033"
  ),
  Operations_MediaTypes_10034: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10034"
  ),
  Operations_MediaTypes_10035: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10035"
  ),
  Operations_MediaTypes_10036: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10036"
  ),
  Operations_MediaTypes_10037: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10037"
  ),
  Operations_MediaTypes_10038: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10038"
  ),
  Operations_MediaTypes_10039: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10039"
  ),
  Operations_MediaTypes_10040: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10040"
  ),
  Operations_MediaTypes_10041: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10041"
  ),
  Operations_MediaTypes_10042: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10042"
  ),
  Operations_MediaTypes_10043: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10043"
  ),
  Operations_MediaTypes_10044: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10044"
  ),
  Operations_MediaTypes_10045: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10045"
  ),
  Operations_MediaTypes_10046: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10046"
  ),
  Operations_MediaTypes_10047: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10047"
  ),
  Operations_MediaTypes_10048: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10048"
  ),
  Operations_MediaTypes_10049: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10049"
  ),
  Operations_MediaTypes_10050: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10050"
  ),
  Operations_MediaTypes_10051: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10051"
  ),
  Operations_MediaTypes_10052: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10052"
  ),
  Operations_MediaTypes_10053: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10053"
  ),
  Operations_MediaTypes_10054: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10054"
  ),
  Operations_MediaTypes_10055: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10055"
  ),
  Operations_MediaTypes_10056: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10056"
  ),
  Operations_MediaTypes_10057: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10057"
  ),
  Operations_MediaTypes_10058: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10058"
  ),
  Operations_MediaTypes_10059: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10059"
  ),
  Operations_MediaTypes_10060: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10060"
  ),
  Operations_MediaTypes_10061: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10061"
  ),
  Operations_MediaTypes_10062: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10062"
  ),
  Operations_MediaTypes_10063: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10063"
  ),
  Operations_MediaTypes_10064: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10064"
  ),
  Operations_MediaTypes_10065: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10065"
  ),
  Operations_MediaTypes_10066: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10066"
  ),
  Operations_MediaTypes_10067: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10067"
  ),
  Operations_MediaTypes_10068: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10068"
  ),
  Operations_MediaTypes_10016: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10016"
  ),
  Operations_MediaTypes_10021: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10021"
  ),
  Operations_MediaTypes_10017: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10017"
  ),
  Operations_MediaTypes_10018: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10018"
  ),
  Operations_MediaTypes_10019: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10019"
  ),
  Operations_MediaTypes_10020: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10020"
  ),
  Operations_MediaTypes_10022: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10022"
  ),
  Operations_MediaTypes_10023: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10023"
  ),
  Operations_MediaTypes_10024: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10024"
  ),
  Operations_MediaTypes_10025: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10025"
  ),
  Operations_MediaTypes_10026: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10026"
  ),
  Operations_MediaTypes_10069: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10069"
  ),
  Operations_MediaTypes_10070: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10070"
  ),
  Operations_MediaTypes_10071: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10071"
  ),
  Operations_MediaTypes_10072: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10072"
  ),
  Operations_MediaTypes_10073: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10073"
  ),
  Operations_MediaTypes_10074: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10074"
  ),
  Operations_MediaTypes_10075: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10075"
  ),
  Operations_MediaTypes_10076: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10076"
  ),
  Operations_MediaTypes_10077: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10077"
  ),
  Operations_MediaTypes_10078: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10078"
  ),
  Operations_MediaTypes_10079: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10079"
  ),
  Operations_MediaTypes_10080: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10080"
  ),
  Operations_MediaTypes_10081: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10081"
  ),
  Operations_MediaTypes_10082: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10082"
  ),
  Operations_MediaTypes_10083: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10083"
  ),
  Operations_MediaTypes_10084: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10084"
  ),
  Operations_MediaTypes_10085: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10085"
  ),
  Operations_MediaTypes_10086: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10086"
  ),
  Operations_MediaTypes_10087: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10087"
  ),
  Operations_MediaTypes_10088: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10088"
  ),
  Operations_MediaTypes_10089: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10089"
  ),
  Operations_MediaTypes_10090: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10090"
  ),
  Operations_MediaTypes_10091: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10091"
  ),
  Operations_MediaTypes_10092: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10092"
  ),
  Operations_MediaTypes_10093: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10093"
  ),
  Operations_MediaTypes_10094: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10094"
  ),
  Operations_MediaTypes_10095: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10095"
  ),
  Operations_MediaTypes_10096: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10096"
  ),
  Operations_MediaTypes_10097: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10097"
  ),
  Operations_MediaTypes_10098: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10098"
  ),
  Operations_MediaTypes_10099: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10099"
  ),
  Operations_MediaTypes_10100: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10100"
  ),
  Operations_MediaTypes_10101: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10101"
  ),
  Operations_MediaTypes_10102: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10102"
  ),
  Operations_MediaTypes_10103: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10103"
  ),
  Operations_MediaTypes_10104: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10104"
  ),
  Operations_MediaTypes_10027: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10027"
  ),
  Operations_MediaTypes_10028: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10028"
  ),
  Operations_MediaTypes_10029: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10029"
  ),
  Operations_MediaTypes_10030: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10030"
  ),
  Operations_MediaTypes_10031: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10031"
  ),
  Operations_MediaTypes_10032: t(
    "discrepancyManagement.mediaTypes.mediaIndicator10032"
  ),
  Operations_MediaTypes_20001: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20001"
  ),
  Operations_MediaTypes_20002: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20002"
  ),
  Operations_MediaTypes_20003: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20003"
  ),
  Operations_MediaTypes_20004: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20004"
  ),
  Operations_MediaTypes_20005: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20005"
  ),
  Operations_MediaTypes_20006: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20006"
  ),
  Operations_MediaTypes_20007: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20007"
  ),
  Operations_MediaTypes_20008: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20008"
  ),
  Operations_MediaTypes_20009: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20009"
  ),
  Operations_MediaTypes_20010: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20010"
  ),
  Operations_MediaTypes_20011: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20011"
  ),
  Operations_MediaTypes_20012: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20012"
  ),
  Operations_MediaTypes_20013: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20013"
  ),
  Operations_MediaTypes_20014: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20014"
  ),
  Operations_MediaTypes_20015: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20015"
  ),
  Operations_MediaTypes_20016: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20016"
  ),
  Operations_MediaTypes_20017: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20017"
  ),
  Operations_MediaTypes_20018: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20018"
  ),
  Operations_MediaTypes_20019: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20019"
  ),
  Operations_MediaTypes_20020: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20020"
  ),
  Operations_MediaTypes_20021: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20021"
  ),
  Operations_MediaTypes_20022: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20022"
  ),
  Operations_MediaTypes_20023: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20023"
  ),
  Operations_MediaTypes_20024: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20024"
  ),
  Operations_MediaTypes_20025: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20025"
  ),
  Operations_MediaTypes_20026: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20026"
  ),
  Operations_MediaTypes_20027: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20027"
  ),
  Operations_MediaTypes_20028: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20028"
  ),
  Operations_MediaTypes_20029: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20029"
  ),
  Operations_MediaTypes_20030: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20030"
  ),
  Operations_MediaTypes_20031: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20031"
  ),
  Operations_MediaTypes_20032: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20032"
  ),
  Operations_MediaTypes_20033: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20033"
  ),
  Operations_MediaTypes_20034: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20034"
  ),
  Operations_MediaTypes_20035: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20035"
  ),
  Operations_MediaTypes_20036: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20036"
  ),
  Operations_MediaTypes_20037: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20037"
  ),
  Operations_MediaTypes_20038: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20038"
  ),
  Operations_MediaTypes_20039: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20039"
  ),
  Operations_MediaTypes_20040: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20040"
  ),
  Operations_MediaTypes_20041: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20041"
  ),
  Operations_MediaTypes_20042: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20042"
  ),
  Operations_MediaTypes_20043: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20043"
  ),
  Operations_MediaTypes_20044: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20044"
  ),
  Operations_MediaTypes_20045: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20045"
  ),
  Operations_MediaTypes_20046: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20046"
  ),
  Operations_MediaTypes_20047: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20047"
  ),
  Operations_MediaTypes_20048: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20048"
  ),
  Operations_MediaTypes_20049: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20049"
  ),
  Operations_MediaTypes_20050: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20050"
  ),
  Operations_MediaTypes_20051: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20051"
  ),
  Operations_MediaTypes_20052: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20052"
  ),
  Operations_MediaTypes_20053: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20053"
  ),
  Operations_MediaTypes_20054: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20054"
  ),
  Operations_MediaTypes_20055: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20055"
  ),
  Operations_MediaTypes_20056: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20056"
  ),
  Operations_MediaTypes_20057: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20057"
  ),
  Operations_MediaTypes_20058: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20058"
  ),
  Operations_MediaTypes_20059: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20059"
  ),
  Operations_MediaTypes_20060: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20060"
  ),
  Operations_MediaTypes_20061: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20061"
  ),
  Operations_MediaTypes_20062: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20062"
  ),
  Operations_MediaTypes_20063: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20063"
  ),
  Operations_MediaTypes_20064: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20064"
  ),
  Operations_MediaTypes_20065: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20065"
  ),
  Operations_MediaTypes_20066: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20066"
  ),
  Operations_MediaTypes_20067: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20067"
  ),
  Operations_MediaTypes_20068: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20068"
  ),
  Operations_MediaTypes_20069: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20069"
  ),
  Operations_MediaTypes_20070: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20070"
  ),
  Operations_MediaTypes_20071: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20071"
  ),
  Operations_MediaTypes_20072: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20072"
  ),
  Operations_MediaTypes_20073: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20073"
  ),
  Operations_MediaTypes_20074: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20074"
  ),
  Operations_MediaTypes_20075: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20075"
  ),
  Operations_MediaTypes_20076: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20076"
  ),
  Operations_MediaTypes_20077: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20077"
  ),
  Operations_MediaTypes_20078: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20078"
  ),
  Operations_MediaTypes_20079: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20079"
  ),
  Operations_MediaTypes_20080: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20080"
  ),
  Operations_MediaTypes_20081: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20081"
  ),
  Operations_MediaTypes_20082: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20082"
  ),
  Operations_MediaTypes_20083: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20083"
  ),
  Operations_MediaTypes_20084: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20084"
  ),
  Operations_MediaTypes_20085: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20085"
  ),
  Operations_MediaTypes_20086: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20086"
  ),
  Operations_MediaTypes_20087: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20087"
  ),
  Operations_MediaTypes_20088: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20088"
  ),
  Operations_MediaTypes_20089: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20089"
  ),
  Operations_MediaTypes_20090: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20090"
  ),
  Operations_MediaTypes_20091: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20091"
  ),
  Operations_MediaTypes_20092: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20092"
  ),
  Operations_MediaTypes_20093: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20093"
  ),
  Operations_MediaTypes_20094: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20094"
  ),
  Operations_MediaTypes_20095: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20095"
  ),
  Operations_MediaTypes_20096: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20096"
  ),
  Operations_MediaTypes_20097: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20097"
  ),
  Operations_MediaTypes_20098: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20098"
  ),
  Operations_MediaTypes_20099: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20099"
  ),
  Operations_MediaTypes_20100: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20100"
  ),
  Operations_MediaTypes_20101: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20101"
  ),
  Operations_MediaTypes_20102: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20102"
  ),
  Operations_MediaTypes_20103: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20103"
  ),
  Operations_MediaTypes_20104: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20104"
  ),
  Operations_MediaTypes_20105: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20105"
  ),
  Operations_MediaTypes_20106: t(
    "discrepancyManagement.mediaTypes.mediaIndicator20106"
  ),
  Operations_MediaTypes_206: t(
    "discrepancyManagement.mediaTypes.mediaIndicator206"
  ),
  Operations_MediaTypes_207: t(
    "discrepancyManagement.mediaTypes.mediaIndicator207"
  ),
  Operations_MediaTypes_208: t(
    "discrepancyManagement.mediaTypes.mediaIndicator208"
  ),
  Operations_MediaTypes_209: t(
    "discrepancyManagement.mediaTypes.mediaIndicator209"
  ),
  Operations_MediaTypes_210: t(
    "discrepancyManagement.mediaTypes.mediaIndicator210"
  ),
  Operations_MediaTypes_211: t(
    "discrepancyManagement.mediaTypes.mediaIndicator211"
  ),
  Operations_MediaTypes_212: t(
    "discrepancyManagement.mediaTypes.mediaIndicator212"
  ),
  Operations_MediaTypes_213: t(
    "discrepancyManagement.mediaTypes.mediaIndicator213"
  ),
  Operations_MediaTypes_214: t(
    "discrepancyManagement.mediaTypes.mediaIndicator214"
  ),
  Operations_MediaTypes_215: t(
    "discrepancyManagement.mediaTypes.mediaIndicator215"
  ),
  Operations_MediaTypes_216: t(
    "discrepancyManagement.mediaTypes.mediaIndicator216"
  ),
  Operations_MediaTypes_217: t(
    "discrepancyManagement.mediaTypes.mediaIndicator217"
  ),
  Operations_MediaTypes_218: t(
    "discrepancyManagement.mediaTypes.mediaIndicator218"
  ),
  Operations_MediaTypes_219: t(
    "discrepancyManagement.mediaTypes.mediaIndicator219"
  ),
  Operations_MediaTypes_220: t(
    "discrepancyManagement.mediaTypes.mediaIndicator220"
  )
});

export const loaderTypes = {
  branch: "branch",
  dataLoaded: "dataLoaded",
  discrepancy: "discrepancy",
  runs: "runs",
  preference: "preference",
  customer: "customer"
};

export const reportConstantPayload = {
  label_report_title: t("discrepancySearchReportLabels.labelReportTitle"),
  label_custome_number_caption: t(
    "discrepancySearchReportLabels.labelCustomeNumberCaption"
  ),
  label_discrepancy_type_caption: t(
    "discrepancySearchReportLabels.labelDiscrepancyTypeCaption"
  ),
  label_meida_type_caption: t(
    "discrepancySearchReportLabels.labelMeidaTypeCaption"
  ),
  label_dateced_from_date_caption: t(
    "discrepancySearchReportLabels.labelDatecedFromDateCaption"
  ),
  label_resolved_from_date_caption: t(
    "discrepancySearchReportLabels.labelResolvedFromDateCaption"
  ),
  label_customer_media_identifier_caption: t(
    "discrepancySearchReportLabels.labelCustomerMediaIdentifierCaption"
  ),
  label_discrepancy_status_caption: t(
    "discrepancySearchReportLabels.labelDiscrepancyStatusCaption"
  ),
  label_detected_in_caption: t(
    "discrepancySearchReportLabels.labelDetectedInCaption"
  ),
  label_media_number_caption: t(
    "discrepancySearchReportLabels.labelMediaNumberCaption"
  ),
  label_detected_to_date_caption: t(
    "discrepancySearchReportLabels.labelDetectedToDateCaption"
  ),
  label_resolved_to_date_caption: t(
    "discrepancySearchReportLabels.labelResolvedToDateCaption"
  ),
  label_branch_caption: t("discrepancySearchReportLabels.labelBranchCaption"),
  label_logical_valult_caption: t(
    "discrepancySearchReportLabels.labelLogicalValultCaption"
  ),
  label_service_date_caption: t(
    "discrepancySearchReportLabels.labelServiceDateCaption"
  ),
  label_run_caption: t("discrepancySearchReportLabels.labelRunCaption"),
  label_exclude_auto_resolved_caption: t(
    "discrepancySearchReportLabels.labelExcludeAutoResolvedCaption"
  ),

  label_container_discrepancy_search_caption: t(
    "discrepancySearchReportLabels.labelContainerDiscrepancySearchCaptio2"
  ),
  label_customer_number_caption2: t(
    "discrepancySearchReportLabels.labelCustomerNumberCaption2"
  ),
  label_media_number_caption2: t(
    "discrepancySearchReportLabels.labelMediaNumberCaption2"
  ),
  label_media_type_caption2: t(
    "discrepancySearchReportLabels.labelMediaTypeCaption2"
  ),
  label_discrepancy_type_caption2: t(
    "discrepancySearchReportLabels.labelDiscrepancyTypeCaption2"
  ),
  label_detected_date_caption2: t(
    "discrepancySearchReportLabels.labelDetectedDateCaption2"
  ),
  label_detected_in_caption2: t(
    "discrepancySearchReportLabels.labelDetectedInCaption2"
  ),
  label_pending_return_date_caption2: t(
    "discrepancySearchReportLabels.labelPendingReturnDateCaption2"
  ),
  label_car_id_caption2: t("discrepancySearchReportLabels.labelCarIdCaption2"),
  label_resolved_date_caption2: t(
    "discrepancySearchReportLabels.labelResolvedDateCaption2"
  ),
  label_resolution_caption2: t(
    "discrepancySearchReportLabels.labelResolutionCaption2"
  ),
  label_customer_media_identifier_caption2: t(
    "discrepancySearchReportLabels.labelCustomerMediaIdentifierCaption2"
  ),
  label_display_to_customer_date_caption2: t(
    "discrepancySearchReportLabels.labelDisplayToCustomerDateCaption2"
  ),

  label_om_discrepancy_search_caption: t(
    "discrepancySearchReportLabels.labelOmDiscrepancySearchCaption"
  ),
  label_customer_number_caption1: t(
    "discrepancySearchReportLabels.labelCustomerNumberCaption1"
  ),
  label_media_number_caption1: t(
    "discrepancySearchReportLabels.labelMediaNumberCaption1"
  ),
  label_discrepancy_type_caption1: t(
    "discrepancySearchReportLabels.labelDiscrepancyTypeCaption1"
  ),
  label_detected_in_caption1: t(
    "discrepancySearchReportLabels.labelDetectedInCaption1"
  ),
  label_detected_date_caption1: t(
    "discrepancySearchReportLabels.labelDetectedDateCaption1"
  ),
  label_resolution_caption1: t(
    "discrepancySearchReportLabels.labelResolutionCaption1"
  ),
  label_car_id_caption1: t("discrepancySearchReportLabels.labelCarIdCaption1"),
  label_logical_vault_caption1: t(
    "discrepancySearchReportLabels.labelLogicalVaultCaption1"
  ),
  label_media_type_caption1: t(
    "discrepancySearchReportLabels.labelMediaTypeCaption1"
  ),
  label_display_to_customer_date_caption1: t(
    "discrepancySearchReportLabels.labelDisplayToCustomerDateCaption1"
  ),
  label_resolved_date_caption1: t(
    "discrepancySearchReportLabels.labelResolvedDateCaption1"
  ),

  label_print_date: t("discrepancySearchReportLabels.labelPrintDate"),
  label_print_by_name: t("discrepancySearchReportLabels.labelPrintByName"),
  label_report_name: t("discrepancySearchReportLabels.labelReportName"),
  label_of: t("discrepancySearchReportLabels.labelOf"),
  label_page: t("discrepancySearchReportLabels.labelPage")
};

export const textXml = "text/xml";
export const detectedInId = "DetectedIn";

export const detectedInIdKey = "Detected_In_Id";

export const detectedInDescrKey = "Detected_In_Descr";
export const detectedInContextKey = "Detected_In_Context";
