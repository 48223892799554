import { Button, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_MODULE_DESTRUCTION_BASE_PATH
} from "routing/Paths";
import spacing from "styles/spacing";
import { RequestStatusIds } from "../Constants";
import { MDRPickupFormStyles } from "../MediaDestructionRequestPickup.style";

const MDRPickupButtons = ({
  statusId,
  disableButton,
  authSuccess,
  personnelId,
  handleSave,
  handleCancelCF,
  setWarningModalText,
  setBtnType,
  handleOpenWarningModal,
  VerifyAuthentication,
  handleOpenPrintModal,
  handleNew,
  setEnableCustomerAuth,
  setAuthFailedCancel,
  setBoxtypeWraningSchedule,
  setBoxtypeWraning,
  handleReadyToBill
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormikContext();
  const handleReset = () => {
    setWarningModalText(t("pickup.resetAlert"));
    setBtnType("reset");
    handleOpenWarningModal();
  };
  const handleCancel = async () => {
    //checking if the customer is authenticated ,if not showing the cancel modal for authenticate to cancel
    if (!authSuccess) {
      setEnableCustomerAuth(true);
      setAuthFailedCancel(true);
    } else {
      //checking is the customer having permission to cancel
      let verify = await VerifyAuthentication(
        RequestStatusIds.Cancelled,
        personnelId
      );
      //calling the CF of the handle cancel
      handleCancelCF(personnelId, verify);
    }
  };
  const handelNew = () => {
    //resetting the page
    setBoxtypeWraningSchedule(false);
    setBoxtypeWraning(false);
    navigate(
      `${REQUEST_MODULE_BASE_PATH}/${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP}`
    );
    formik.resetForm();
    handleNew();
  };
  return (
    <Stack
      direction="row"
      gap={spacing.verticalMargin20}
      sx={MDRPickupFormStyles.buttonStyle}
    >
      {/* reset button */}
      <Button
        variant="outlined"
        disabled={disableButton(t("pickup.reset"), statusId)}
        onClick={handleReset}
        id="resetButton"
      >
        {t("pickup.reset")}
      </Button>
      {/* cancel button */}
      <Button
        variant="outlined"
        disabled={disableButton(t("pickup.cancel"), statusId)}
        onClick={handleCancel}
        id="cancelButton"
      >
        {t("pickup.cancel")}
      </Button>
      {/* ReadytoBill */}
      <Button
        variant="outlined"
        disabled={disableButton(t("pickup.readyToBill"), statusId)}
        onClick={handleReadyToBill}
        id="cancelButton"
      >
        {t("pickup.readyToBillButton")}
      </Button>
      {/* print button */}
      <Button
        variant="outlined"
        onClick={handleOpenPrintModal}
        disabled={disableButton(t("pickup.print"), statusId)}
        id="printButton"
      >
        {t("pickup.print")}
      </Button>
      {/* new button */}
      <Button
        variant="outlined"
        disabled={disableButton(t("pickup.new"), statusId)}
        onClick={handelNew}
        id="newButton"
      >
        {t("pickup.new")}
      </Button>

      {/* save button */}
      <Button
        variant="contained"
        disabled={disableButton(t("pickup.save"), statusId)}
        onClick={handleSave}
        id="saveButton"
      >
        {t("pickup.save")}
      </Button>
    </Stack>
  );
};

export default MDRPickupButtons;
