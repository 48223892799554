import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { DEFAULT_LOCALE } from "@mui/x-date-pickers";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { getAllCustomers } from "services/common";
import { selectAuth } from "store/slices";
import {
  clearError,
  setIsRefreshRequired,
  setShowAuthSection
} from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import {
  authSection,
  dateFormatWithTime,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  errorMsgs,
  NO,
  unauthorized,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER,
  YES
} from "utils/constants";
import { getPreferenceTypesPayloadForReport } from "utils/constants/customer-information/CustomerPreference";
import {
  awaited,
  edit,
  hyphen,
  padStartLength,
  preferencesTypeIds,
  preferenceTypeMap,
  preferenceXML,
  preferenceXMLValues,
  programSecurityCode356,
  save,
  systemId,
  zero
} from "utils/constants/customer-information/PayInvoiceFilter";
import {
  escapeXml,
  escapeXmlForReport,
  formatDateByLocale,
  getResponseData
} from "utils/helpers";
import PreferencesLayout from "../preferences-main/PreferencesLayout";
import PreferencesReoprtDownloadModal from "../PreferencesReoprtDownloadModal";
import { PreferencesStyles } from "../PreferencesStyles";
import PayInvoiceTable from "./PayInvoiceTable";

const PayInvoice = () => {
  const { localeByBranch, auth } = useSelector(selectAuth);
  const { state = {} } = useLocation();
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [parentData, setParentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isshow, setIsShow] = useState(false);
  const customer_id = state?.customer_id;
  const [customer, setCustomer] = useState(customer_id);
  const { authFormValues, isAuthenticated } = useSelector(
    (state) => state.customerPreferences
  );

  const dispatch = useDispatch();
  const [comments, setComments] = useState("");

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [isDownLoadModalOpen, setIsDownloadModalOpen] = useState(false);

  const [isEditButtonClicked, setIsEditButtonClicked] = useState(edit);

  useEffect(() => {
    const programSecurityList = auth?.user?.program_list;
    const programSecurityListArray = programSecurityList.split(",").map(Number);
    const isProgramIdAvailable = programSecurityListArray.includes(
      programSecurityCode356
    );
    if (isProgramIdAvailable) {
      setIsShow(true);
    } else {
      dispatch(setShowAuthSection(true));
      getTheFirestoreErrorMessage(errorMsgs.errorCode64097);
    }
    //eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
    dispatch(setIsRefreshRequired(true));
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setThrowErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setThrowErrorMessage(t("common.systemErrorMessage"));
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");

  const getSelectedCustomer = async () => {
    try {
      const customers = await getAllCustomers();
      let customer =
        customers.find((customer) => customer.customer_id === customer_id) ||
        {};
      setCustomer(customer);
    } catch (error) {
      handleCatchError(error);
    }
  };

  // Handle error with error code
  const handleError = async (errorCode, replaceText) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);

    if (errorMsg) {
      if (replaceText) {
        setThrowErrorMessage(errorMsg?.replace("|", replaceText));
      } else {
        setThrowErrorMessage(errorMsg);
      }
    }
  };

  const getPreferencesHistory = async () => {
    try {
      setIsLoading(true);
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: customer_id,
        preference_type_id_list: preferencesTypeIds.reduce(
          (a, key) => (a += key.padStart(padStartLength, " ")),
          ""
        )
      };

      const response = await getResponseData(
        reqBody,
        `${CF_URLS.customerPreferences.getCustomerPreferenceHistory}`,
        2
      );
      let data = response.data;
      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      let parent = data[0];
      let child = data[1];

      const parentDataPromises = parent
        .sort((a, b) => {
          return dayjs(b.change_history_datetime).isAfter(
            dayjs(a.change_history_datetime)
          )
            ? 1
            : -1;
        })
        .map(async (parentItem) => {
          const formattedDate = await formatDateByLocale(
            dayjs(parentItem.change_history_datetime).utc(),
            localeByBranch || DEFAULT_LOCALE,
            true
          );
          const filteredChildren = child.filter(
            (childItem) =>
              childItem.change_history_id === parentItem.change_history_id
          );

          const changedValues = filteredChildren
            .map((childItem) => preferenceTypeMap[childItem.preference_type_id])
            .sort((a, b) => a.localeCompare(b))
            .join(", ");

          return {
            date: formattedDate.substring(0, formattedDate.length - 2),
            imEmployee:
              parentItem?.employee_last_name || parentItem?.employee_first_name
                ? `${parentItem?.employee_last_name}, ${parentItem?.employee_first_name}`
                : null,
            authorizedBy:
              parentItem.personnel_first_name || parentItem.personnel_last_name
                ? `${parentItem?.personnel_last_name}, ${parentItem?.personnel_first_name}`
                : "",
            changedValues: changedValues,
            change_history_id: parentItem.change_history_id,
            children: filteredChildren
              .map((childItem) => {
                let previousValue = childItem.old_value;
                let newValue = childItem.new_value;
                if (childItem.preference_type_id === preferencesTypeIds[0]) {
                  previousValue = previousValue === Y_CHECKER ? YES : NO;
                  newValue = newValue === Y_CHECKER ? YES : NO;
                }
                return {
                  name: preferenceTypeMap[childItem.preference_type_id],
                  previousValue,
                  newValue
                };
              })
              .sort((a, b) => a.name.localeCompare(b.name))
          };
        });

      Promise.all(parentDataPromises).then((resolvedParentData) => {
        setParentData(resolvedParentData);
        setFilteredData(resolvedParentData);
      });
    } catch (error) {
      handleCatchError();
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSelectedCustomer();
    getPreferences();
    //eslint-disable-next-line
  }, []);

  const getPreferences = async () => {
    try {
      setIsLoading(true);
      const res = await getResponseData(
        {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          customer_id
        },
        CF_URLS.customerPreferences.getPreferenceValues,
        4
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      let preference = data[2];

      preference.forEach((pref) => {
        let pId = pref.preference_type_id;
        if (preferencesTypeIds.includes(pId)) {
          let value = pref?.preference_value || undefined;
          let isChecked = value === Y_CHECKER;
          switch (pId) {
            case preferencesTypeIds[0]:
              setIsCheckboxChecked(isChecked);
              break;
            case preferencesTypeIds[1]:
              setComments(value);
              break;

            default:
              break;
          }
        }
      });
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getValueWithPreferenceId = (pId) => {
    switch (pId) {
      case preferencesTypeIds[0]:
        return isCheckboxChecked ? VALUE_Y : VALUE_N;
      case preferencesTypeIds[1]:
        return escapeXml(comments);
      default:
        break;
    }
  };

  const handleDownloadOpen = () => {
    setIsDownloadModalOpen(true);
  };

  const onChangeAccordionExpand = (e, expanded) => {
    if (!expanded || parentData.length) return;
    getPreferencesHistory();
  };

  const onCheckboxSelected = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleEditEvent = (e) => {
    if (isAuthenticated) {
      setIsEditButtonClicked(save);
    } else {
      dispatch(setShowAuthSection(true));
      setIsEditButtonClicked(awaited);
    }
  };

  useEffect(() => {
    if (isAuthenticated && isEditButtonClicked === awaited) {
      setIsEditButtonClicked(save);
    } else {
      setIsEditButtonClicked(awaited);
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);

  const handleSaveEvent = async () => {
    setIsLoading(true);
    try {
      const response = await getResponseData(
        {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          customer_id: customer_id,
          employee_id: auth?.user?.employee_id,
          personnel_id:
            authFormValues?.personnelAuthValue === authSection?.personnelAuth
              ? authFormValues?.personnel?.value
              : "",
          system_id: systemId,
          preferences_xml: preferenceXML.replace("|", () => {
            return preferencesTypeIds
              .filter((id) =>
                id === preferencesTypeIds[0]
                  ? isCheckboxChecked
                    ? VALUE_Y
                    : VALUE_N
                  : comments
              )
              .map((key) => {
                return preferenceXMLValues
                  .replace("|", key)
                  .replace("|", getValueWithPreferenceId(key));
              })
              .join("");
          }),
          auth_bypass_reason:
            authFormValues?.personnelAuthValue === authSection?.bypass
              ? authFormValues?.bypassMessage
              : ""
        },
        CF_URLS.customerPreferences.updateCustomerPreferences,
        3
      );
      let data = response.data;
      if (data[0]) {
        let code = data[0][0]?.error;
        if (code === zero) {
          let { errorMsg } = await getTheFirestoreErrorMessage(
            errorMsgs.errorCode64095
          );
          setMessageSuccess(
            errorMsg
              .replace("|", preferenceTypeMap[preferencesTypeIds[0]])
              .replace("|", dayjs().utc().format(dateFormatWithTime))
          );
          setIsEditButtonClicked(edit);
          getPreferences();
          getPreferencesHistory();
          return;
        }
        handleError(code);
        return;
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const topTextFieldReportsPayload = [
    {
      key: t("preferences.payInvoice.payInvoice"),
      value: isCheckboxChecked ? t("common.yes") : t("common.no")
    },
    {
      key: t("preferences.comments"),
      value: comments ? escapeXmlForReport(comments) : ""
    }
  ];

  const filteredPreferenceTypes = useMemo(() => {
    return getPreferenceTypesPayloadForReport().filter((type) =>
      preferencesTypeIds.includes(type.preference_type_id)
    );
  }, []);

  return (
    <PreferencesLayout showBypass={isshow}>
      {isshow && (
        <>
          <Stack
            direction="column"
            gap={spacing.verticalMargin20}
            mb={spacing.verticalMargin32}
            mt={spacing.verticalMargin16}
          >
            {isLoading && <FullCircularLoader />}
            {messageSuccess && (
              <SuccessPopup
                message={messageSuccess}
                close={setMessageSuccess}
              />
            )}
            {throwError && (
              <Box mb={spacing.gap}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" width="20" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        dispatch(clearError());
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Box>
            )}

            <Grid container spacing={spacing.horizontalMargin20}>
              <Grid item md={24} lg={12} sx={8}>
                <Box mb={spacing.verticalMargin20}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("common.customer")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {customer?.number || hyphen}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={24} lg={12} sx={8}>
                <Divider />
              </Grid>
              <Grid item md={24} lg={12} sx={8}>
                <Typography variant="h5">
                  {t("preferences.payInvoice.setting")}
                </Typography>
              </Grid>
              <Grid item md={24} lg={12} sx={8}>
                <span style={PreferencesStyles.spanStyle}>
                  {t("preferences.payInvoice.payInvoiceLinkText")}
                </span>
              </Grid>

              <Grid item md={4} lg={3} sx={2}>
                <Stack direction="row">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <>
                          <Switch
                            disabled={isEditButtonClicked !== save}
                            onChange={onCheckboxSelected}
                            checked={isCheckboxChecked}
                          />
                        </>
                      }
                      label={t("preferences.payInvoice.viewPayInvoiceLink")}
                    />
                    <Stack>
                      <Typography
                        style={PreferencesStyles.font13}
                        variant="caption"
                      >
                        {t("preferences.payInvoice.toggleOn")}
                      </Typography>
                      <Typography
                        style={PreferencesStyles.font13}
                        variant="caption"
                      >
                        {t("preferences.payInvoice.toggleOff")}
                      </Typography>
                    </Stack>
                  </FormGroup>
                </Stack>
              </Grid>
              <Grid item md={12} lg={6} sx={4}>
                <TextField
                  label={t("preferences.payInvoice.comments")}
                  id="comments"
                  disabled={isEditButtonClicked !== save}
                  fullWidth
                  multiline
                  rows={3}
                  maxRows={4}
                  value={comments}
                  onChange={(event, newValue) =>
                    setComments(event.target.value)
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength: 1014
                    }
                  }}
                />
              </Grid>
              <Grid item md={24} lg={12} sx={8}>
                <Divider />
                <br />
              </Grid>
            </Grid>

            <PayInvoiceTable
              filteredData={filteredData}
              parentData={parentData}
              setFilteredData={setFilteredData}
              onChange={onChangeAccordionExpand}
            />

            <Grid item md={24} lg={12} sx={8}>
              <Divider />
            </Grid>

            <Grid item md={24} lg={12} sx={8}>
              <Stack direction="row" gap={spacing.gap}>
                <Button
                  id="backBtn"
                  variant="outlined"
                  style={PreferencesStyles.tableListStyle.buttons}
                  type="submit"
                  disabled={false}
                  onClick={goBack}
                >
                  {t("common.back")}
                </Button>

                <Button
                  id="printBtn"
                  variant="outlined"
                  style={PreferencesStyles.tableListStyle.buttons}
                  type="submit"
                  disabled={false}
                  onClick={handleDownloadOpen}
                >
                  {t("common.print")}
                </Button>
                {isEditButtonClicked === save ? (
                  <Button
                    id="saveBtn"
                    type="submit"
                    style={PreferencesStyles.tableListStyle.buttons}
                    variant="contained"
                    onClick={handleSaveEvent}
                  >
                    {t("common.save")}
                  </Button>
                ) : (
                  <Button
                    id="editBtn"
                    variant="contained"
                    style={PreferencesStyles.tableListStyle.buttons}
                    type="submit"
                    onClick={handleEditEvent}
                  >
                    {t("common.edit")}
                  </Button>
                )}
              </Stack>
            </Grid>
          </Stack>

          <PreferencesReoprtDownloadModal
            topTextFieldReportsPayload={topTextFieldReportsPayload}
            url={CF_URLS.printReports.customerInformation.payinvoiceLinkReport}
            title={t("common.downloadReport")}
            showPrintModal={isDownLoadModalOpen}
            setCloseModal={setIsDownloadModalOpen}
            setIsLoading={setIsLoading}
            customerId={customer_id}
            preferenceTypeId={preferencesTypeIds.reduce(
              (a, key) => (a += key.padStart(padStartLength, " ")),
              ""
            )}
            preferenceTypesPayloadForReport={filteredPreferenceTypes}
          />
        </>
      )}
    </PreferencesLayout>
  );
};

export default PayInvoice;
