import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    requestDetailDeliveryObjHeader: {},
    existingSITADObj: {},
    deliveryInstructionComment: {}
};

export const destructionDetailDeliverySlice = createSlice({
    name: "requestDetailDelivery",
    initialState,
    reducers: {
        detailDeliveryRequestHeaderObj: (state, action) => {
            state.requestDetailDeliveryObj = action.payload;
        },
        detailDeliveryRequestSaveFetch: (state, action) => {
            state.existingSITADObj = action.payload;
        },
        deliveryInstructionCommentFetch: (state, action) => {
            state.deliveryInstructionComment = action.payload;
        }
    }
});


export default destructionDetailDeliverySlice.reducer;
