import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import { ERROR_TYPES, downloadReportOptions } from "utils/constants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";

export default function DownloadReportModal(props) {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);

  //handle download
  const handleDownload = async () => {
    try {
      props.setIsLoading(true);
      props.setThrowError(false);
      const requestBody = JSON.stringify({
        main_district_id: props.currentBranch.district_id,
        district_name: props.currentBranch.name.replace("_", " "),
        billing_classification_code: props.classification,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        requestBody,
        `${CF_URLS.printReports.billing.interfaceRulesReport}`
      );
      if (!reportResponse.success) {
        props.setThrowErrorMessage(reportResponse.error);
        props.setThrowError(true);
      }
    } catch (e) {
      props.setThrowErrorMessage(
        e.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      props.setThrowError(true);
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleCancel = () => {
    props.setThrowError(false);
    props.setIsVisible(false);
  };

  return (
    <DownloadPopup
      title={t("billingInterfaceRules.downloadReportTitle")}
      showPrintModal={props.isVisible}
      downloadOptions={downloadReportOptions}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={props.setThrowError}
      throwError={props.throwError}
      throwErrorMessage={props.throwErrorMessage}
    />
  );
}
