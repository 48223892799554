import { Button, Typography } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const CustomerMoveDetailRunPopup = ({
  setOpenRunPopup,
  openRunPopup,
  copyMediaTransactionMsg,
  handleYesMoveTransactionBtnClick,
  setGridRowData,
  setRunBtnEnabled
}) => {
  const handleClose = () => {
    setOpenRunPopup(false);
    setGridRowData();
    setRunBtnEnabled(false);
  };
  return (
    <CommonModal
      open={openRunPopup}
      title={t("common.confirm")}
      body={
        <>
          <Typography>{copyMediaTransactionMsg || ""}</Typography>
        </>
      }
      buttons={
        <>
          <Button variant="outlined" onClick={handleYesMoveTransactionBtnClick}>
            {t("common.yes")}
          </Button>
          <Button variant="contained" onClick={handleClose}>
            {t("common.no")}
          </Button>
        </>
      }
    />
  );
};

export default CustomerMoveDetailRunPopup;
