import { t } from "i18next";

export const customer = "Customer";
export const transport = "Transport";
export const day = "day";
export const year = "year";
export const month = "month";
export const twoYears = 2;
export const fiveYears = 5;
export const oneMonth = 1;
export const months = "months";
export const constAll = "<All>";
export const zero = "0";
export const minusKey = "-";
export const arrowDownKey = "ArrowDown";
export const exchangeStatusId = 12;
export const oneChecker = "1";
export const twoChecker = "2";
export const withOutTransportLocations = t(
  "reports.emptyTransportDefilt.withOutTransportLocations"
);
export const withTransportLocations = t(
  "reports.emptyTransportDefilt.withTransportLocations"
);
export const active = "active";
export const deleteKey = "Deleted";
export const allkey = "all";
export const summary = "summary";
export const detail = "detail";
export const minusDate = "2002-03-01";
export const uploadErrorsMinimumDate = "1997-03-01";
export const systemIdSixty = "60";
export const monthlyManagedContainerCountSettingTypeId = "1005";
export const scanAtCustomerCountreportSettingtypeId = "1013";

export const reportRadio = "reportRadioButton";
export const fileExportRadio = "fileExportRadioButton";
export const csvFormat = "text/csv";
export const branch = "Branch";
export const ForeverSafeBillingSummaryFileName = "ForeverSafe_Billing_Summary_";
export const aTag = "a";

export const dailyBillableActivityCountsbyLogicalVault = t(
  "reports.dailyBillableActivityCountsbyLogicalVault"
);
export const dailyBillableActivityCountsSummary = t(
  "reports.dailyBillableActivityCountsSummary"
);
export const disasterRecoveryActivityCounts = t(
  "reports.disasterRecoveryActivityCounts"
);
export const monthlyBillableActivityCounts = t(
  "reports.monthlyBillableActivityCounts"
);
export const monthlyManagedContainerContentsCount = t(
  "reports.monthlyManagedContainerContentsCount"
);
export const foreverSafeBillingSummary = t("reports.foreverSafeBillingSummary");
export const scanAtCustomerBillingActivityCounts = t(
  "reports.scanAtCustomerBillingActivityCounts"
);

export const dropDownValues = [
  { label: dailyBillableActivityCountsbyLogicalVault },
  { label: dailyBillableActivityCountsSummary },
  { label: disasterRecoveryActivityCounts },
  { label: monthlyBillableActivityCounts },
  { label: foreverSafeBillingSummary }
];

export const dataHeadersForeverSafeBillingSummary = [
  {
    line_out:
      "Branch ,Bill Date ,Customer Number ,Pricing Contract ,Catalog Item Code ,Media Type ,OM Logical Vault Code ,Logical Vault Desc ,Terabyte Billing (Y/N) ,High Count ,Category ,Conversion Factor ,Billed Qty\r\n"
  }
]; //should it be in language file
