import { Stack } from "@mui/material";
import { BillingStatusScreen } from "components/billing-module";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const BillingStatus = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={i18n.t("menu.billingStatus")} />
      <BillingStatusScreen />
    </Stack>
  );
};

export default BillingStatus;
