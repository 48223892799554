import { Stack } from "@mui/material";
import { PersonnelDetailScreen } from "components/authorization";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const PersonnelValidation = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={i18n.t("menu.personalDetail")} />
      <PersonnelDetailScreen />
    </Stack>
  );
};

export default PersonnelValidation;
