export const customerBillingInformationStyles = {
  FieldSize: {
    minWidth: "310px"
  },
  Direction: {
    column: "column",
    row: "row"
  },
  Gap: {
    gap: "20px"
  },
  Paper: {
    width: "100%",
    overflow: "hidden",
    padding: "10px"
  },
  StackDirectionRow: "row",
  StackAllignItemsCenter: "center",
  StackJustifyContent: "space-between",
  TablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  TableHead: {
    position: "sticky",
    top: "0"
  },
  TableRowAlignCenter: "center",
  TableCellAlignCenter: "center",
  TextBoarderHide: {
    border: "none"
  },
  SelectedRowColor: "var(--color-im-light-blue-100)"
};
