import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MEDIA_LIMIT } from "../Constants";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";
import PossibleMatchesTableRewrite from "../tables/PossibleMatchesTable";

const PossibleMatchesModal = ({
  possibleMatchesModalVisible,
  setPossibleMatchesModalVisible,
  selectedMedia,
  filteredList,
  tableMedia,
  setSelectedRowsMedia,
  customerSelected,
  mainDistrictId
}) => {
  const { t } = useTranslation();

  const [selectedRowsMedias, setSelectedRowsMedias] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <Modal
        open={possibleMatchesModalVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          id="selectMediaModalCard"
          sx={MDRVaultItemizedStyles.SelectMediaModal_PopUp}
        >
          {/* ------------------ Title Section ------------------ */}

          <Typography
            variant="h6"
            sx={MDRVaultItemizedStyles.mainTitle_SelectMedia}
            color="text.secondary"
            gutterBottom
          >
            {t("vaultItemized.titleSelectMediaModal")}
          </Typography>

          <Divider />
          {/* ------------------ Body Section ------------------ */}

          <CardContent>
            <Stack gap="10px">
              <Typography variant="subtitle2">
                {/* There should be a better way to do this */}
                {t("vaultItemized.messagePart1")}
                {String(selectedMedia?.volser).toUpperCase()}
                {t("vaultItemized.messagePart2")}
                {String(selectedMedia?.volser).toUpperCase()}
                {t("vaultItemized.messagePart3")}
              </Typography>

              <Typography variant="subtitle2">
                {tableData?.length} {t("vaultItemized.record")}
              </Typography>

              {isError && (
                <Alert
                  severity="warning"
                  icon={<img src={WarningIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsError(false);
                        setErrorMessage("");
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {errorMessage}
                </Alert>
              )}

              <Box>
                <PossibleMatchesTableRewrite
                  selectedMedia={selectedMedia}
                  filteredList={filteredList}
                  setSelectedRowsMedia={setSelectedRowsMedias}
                  tableMedia={tableMedia}
                  setTableData={setTableData}
                  customerSelected={customerSelected}
                  mainDistrictId={mainDistrictId}
                  possibleMatchesModalVisible={possibleMatchesModalVisible}
                  isError={isError}
                  setIsError={setIsError}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
              </Box>
            </Stack>
          </CardContent>

          <Divider />
          {/* ------------------ Footer Section ------------------ */}

          <CardActions sx={MDRVaultItemizedStyles.cardActionsStyle_PRSPopUP}>
            <Button
              id="selectMediaModalCancelBtn"
              variant="outlined"
              onClick={() => {
                setPossibleMatchesModalVisible(false);
                setErrorMessage("");
                setIsError(false);
              }}
              sx={MDRVaultItemizedStyles.buttonStyle_PRSPopUP}
            >
              {t("vaultItemized.cancel")}
            </Button>
            <Button
              id="selectMediaModalOkBtn"
              variant="contained"
              sx={MDRVaultItemizedStyles.buttonStyle_PRSPopUP}
              onClick={() => {
                if (
                  tableMedia?.length + selectedRowsMedias?.length - 1 >
                  MEDIA_LIMIT
                ) {
                  setIsError(true);
                  setErrorMessage(t("vaultItemized.mediaListExceeds"));
                } else {
                  setSelectedRowsMedia(selectedRowsMedias);
                  setPossibleMatchesModalVisible(false);
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
            >
              {t("vaultItemized.ok")}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default PossibleMatchesModal;
