import { Checkbox } from "@mui/material";
import { t } from "i18next";
import { DEFAULT_DATA_TABLE_OPTIONS, Y_CHECKER } from "utils/constants";
export const preferenceColumns = (handlerediectpage) => [
  {
    name: "name",
    label: t("preferences.addCutOffDeadline.name")
  },
  {
    name: "settings",
    label: t("preferences.addCutOffDeadline.settings")
  },
  {
    name: "authorizedBy",
    label: t("preferences.authorizedByLabel")
  },
  {
    name: "authorizedDate",
    label: t("preferences.addCutOffDeadline.date")
  },
  {
    name: "imEmployee",
    label: t("preferences.addCutOffDeadline.imEmployee")
  }
];

export const vaultStatusReportColumns = (handleTableCheckboxChange) => [
  {
    name: "personnelName",
    label: t("preferences.personnelName")
  },
  {
    name: "businessEmail",
    label: t("preferences.businessEmail")
  },
  {
    name: "sendReport",
    label: t("preferences.sendReport"),
    options: {
      customBodyRender: (value, tableMeta) => {
        const isChecked = value === Y_CHECKER;
        return (
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleTableCheckboxChange(e, tableMeta.rowIndex)}
          />
        );
      }
    }
  },
  {
    name: "discrepancyContact",
    label: t("preferences.discrepancyContact")
  }
];
export const tableOptions = (data) => ({
  ...DEFAULT_DATA_TABLE_OPTIONS,
  sort: true,
  pagination: 0 && true,
  textLabels: {
    body: {
      noMatch: data?.length === 0 && `${t("common.noRecordsFound")}`
    }
  },
  setRowProps: (row, dataIndex) => {
    const rowData = data[dataIndex];
    const completedFlag = rowData ? rowData.completed_flag : null;
    return {
      style: {
        backgroundColor: completedFlag
      }
    };
  }
});

export const AddCutOffDeadlineTableColumns = [
  {
    name: "date",
    label: t("preferences.addCutOffDeadline.date")
  },
  {
    name: "imEmployee",
    label: t("preferences.addCutOffDeadline.imEmployee")
  },
  {
    name: "authorizedBy",
    label: t("preferences.addCutOffDeadline.authorizedBy")
  },
  {
    name: "previousValue",
    label: t("preferences.addCutOffDeadline.previousValue")
  },
  {
    name: "newValue",
    label: t("preferences.addCutOffDeadline.newValue")
  }
];

const createPersonnelInfoXml = (personnelInfo) => {
  return `${personnelInfo
    .map(
      (info) =>
        `<ObjectValue Object_Id="${info.personnel_id}" Object_Value="${info.sendReport}" Version="${info.last_mod_date}"/>`
    )
    .join("")}`;
};

export const createXmlPyload = (data, tableData) => {
  return `<ObjectValues>${createPersonnelInfoXml(
    tableData.vaultStatusTableData
  )}</ObjectValues>`;
};
export const addCutOffDeadlineXml = (data) => {
  return ` <Root>
  <CustomerId>${data?.selectedCustomer?.value}</CustomerId>
  <VaultStatusPersonnelInfoXml>${createPersonnelInfoXml(
    data?.vaultStatusTableData
  )}</VaultStatusPersonnelInfoXml>
  <Ts>${data?.preferenceFirstResultSet?.timestamp}</Ts>
  <EmailOnlyIfExceptionsFlag>${
    data?.preferenceFirstResultSet?.email_only_if_exceptions_flag
  }</EmailOnlyIfExceptionsFlag>
  <VaultStatusEmail>${
    data?.preferenceFirstResultSet?.vault_status_email
  }</VaultStatusEmail>
</Root>`;
};
