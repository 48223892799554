import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import BranchField from "components/core/branch-field/BranchField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  billingCycle,
  dateFormat,
  downloadReportOptions,
  messageCode
} from "utils/constants";
import {
  billingRunStatus,
  billingSummaryPrintTypes
} from "utils/constants/biling-module";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getUserName,
  printReport
} from "utils/helpers";
import { enterpriseBillingSummaryLayoutStyles } from "./BillingSummaryStyles";
const BillingSummaryPrintModal = ({
  open,
  handleClose,
  searchPanelForm,
  setIsLoading,
  branchList
}) => {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const districtId = getAuthenticatedUserBranch();

  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);
  const [showPrintModal, setShowPrintModal] = useState(false);

  //error message showing states
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  //warning message showing states
  const [throwWarning, setThrowWarning] = useState(false);
  const [throwWarningMessage, setThrowWarningMessage] = useState(false);
  //options for branch field
  const allBranches = branchList?.map((branch) => ({
    value: branch?.district_id,
    label: branch?.code?.toUpperCase()
  }));
  //handle print button click
  const handlePrint = async () => {
    try {
      //setShowPrintModal((prev) => !prev);
      if (
        searchPanelForm.values.printType ===
          billingSummaryPrintTypes.billingVariance ||
        searchPanelForm.values.printType ===
          billingSummaryPrintTypes.zeroCountReport
      ) {
        if (searchPanelForm?.values?.branch === VALUE_EMPTY_STRING) {
          const errorMsg = await getMessageFromCode(
            String(messageCode.branchIsRequired)
          );
          if (errorMsg) {
            setThrowWarningMessage(errorMsg[0]?.descr);
            setThrowWarning(true);
          }
        } else {
          setIsLoading(true);
          const reqBody = JSON.stringify({
            main_district_id: districtId,
            billing_run_status_id: String(billingRunStatus.archived),
            district_id: searchPanelForm?.values?.branch,
            billing_cycle_code: searchPanelForm?.values?.billCycle.trim()
          });
          const response = await callToCloudFunction(
            reqBody,
            CF_URLS.billingSummary.billingActiveStatus
          );

          const activeBillDate = await getDataFromFirestore(
            response,
            1,
            response.data.docId
          );
          const returnBillData =
            activeBillDate?.data[0][0]?.billing_date.split("T")[0];

          const selectedDate =
            searchPanelForm?.values?.billCycle.trim() === billingCycle.eom
              ? searchPanelForm?.values?.billDateEom
              : dayjs(searchPanelForm?.values?.billDateOto).format(
                  dateFormat.universalFormat
                );
          if (returnBillData === selectedDate) {
            setShowPrintModal((prev) => !prev);
          } else {
            const errorMsg = await getMessageFromCode(
              String(messageCode.reportIsNotValid)
            );
            if (errorMsg) {
              setThrowWarningMessage(errorMsg[0]?.descr);
              setThrowWarning(true);
            }
          }
        }
      } else if (
        searchPanelForm.values.printType ===
        billingSummaryPrintTypes.enterpriseBillingSummary
      ) {
        setShowPrintModal((prev) => !prev);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setShowPrintModal((prev) => !prev);

      let url;
      let request;
      const reqBody = {
        main_district_id: districtId,
        billing_cycle_code: searchPanelForm?.values?.billCycle.trim(),
        billing_date:
          searchPanelForm?.values?.billCycle.trim() === billingCycle.eom
            ? searchPanelForm?.values?.billDateEom
            : dayjs(searchPanelForm?.values?.billDateOto).format(
                dateFormat.universalFormat
              ),
        report_format: selectedValue?.type?.toUpperCase(),
        user_name: getUserName(auth?.user)
      };

      if (
        searchPanelForm.values.printType ===
        billingSummaryPrintTypes.enterpriseBillingSummary
      ) {
        //call cloud function for get document path

        request = JSON.stringify(reqBody);
        url = CF_URLS.printReports.billing.enterpriseBillingSummaryReport;
      } else if (
        searchPanelForm.values.printType ===
        billingSummaryPrintTypes.billingVariance
      ) {
        //call cloud function for get document path

        request = JSON.stringify({
          ...reqBody,
          district_id: searchPanelForm?.values?.branch
        });
        url = CF_URLS.printReports.billing.billingVarianceReport;
      } else if (
        searchPanelForm.values.printType ===
        billingSummaryPrintTypes.zeroCountReport
      ) {
        //call cloud function for get document path
        request = JSON.stringify({
          ...reqBody,
          district_id: searchPanelForm?.values?.branch
        });
        url = CF_URLS.printReports.billing.zeroCountReport;
      }
      let reportResponse = await printReport(request, url);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //handle branch change
  const handleBranchChange = (event, newValue) => {
    setThrowWarning(false);
    searchPanelForm.setFieldValue("branch", event.target.value);
  };

  //handle close select print type modal
  const handleSelectPrintType = () => {
    handleClose();
    setThrowWarning(false);
    setThrowError(false);
    setThrowWarningMessage("");
    setThrowErrorMessage("");
  };
  return (
    <>
      {/* Download Popup */}

      <DownloadPopup
        title={t("enterpriseBillingSummary.enterpriseSummaryReport")}
        showPrintModal={showPrintModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => setShowPrintModal((prev) => !prev)}
        onDownloadClick={handleDownload}
        setSelectedValue={setSelectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
      <Modal
        id="print-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          id="print-modal-box1"
          sx={enterpriseBillingSummaryLayoutStyles.printModalContainer}
        >
          {/*Print Modal title*/}
          <Typography
            id="print-modal-title"
            variant="h6"
            sx={enterpriseBillingSummaryLayoutStyles.printModalTitle}
          >
            {t("enterpriseBillingSummary.printModalTitle")}
          </Typography>

          <Grid
            id="print-modal-stack"
            direction="column"
            spacing={spacing.verticalMargin10}
            sx={enterpriseBillingSummaryLayoutStyles.printModalBody}
          >
            <Collapse in={throwError}>
              {/*Error alert box*/}
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Collapse>
            <Collapse in={throwWarning}>
              {/*Warinng alert box*/}
              <Alert
                severity="warning"
                icon={<img src={WarningIcon} alt="warning" />}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowWarning(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography sx={enterpriseBillingSummaryLayoutStyles.alertIcon}>
                  {throwWarningMessage}
                </Typography>
              </Alert>
            </Collapse>
            {/*Print modal subtitle*/}
            <Grid item>
              <Typography>{t("enterpriseBillingSummary.report")}</Typography>
            </Grid>

            <Grid id="radio-grid" item>
              {/*Print modal radio buttons*/}
              <FormControl id="radio-control">
                <RadioGroup
                  id="radio-group"
                  name="printType"
                  column
                  value={searchPanelForm.values.printType}
                  onChange={searchPanelForm.handleChange}
                >
                  <FormControlLabel
                    id="radio-form-control-1"
                    value={t(
                      "enterpriseBillingSummary.printEnterpriseBillingSummary"
                    )}
                    control={<Radio />}
                    label={t("enterpriseBillingSummary.printRadioEnterprise")}
                  />

                  <FormControlLabel
                    id="radio-form-control-2"
                    value={t("enterpriseBillingSummary.printBillingVariance")}
                    control={<Radio />}
                    label={t("enterpriseBillingSummary.printRadioVariance")}
                    disabled={
                      searchPanelForm?.values?.billCycle.trim() ===
                      billingCycle.oto
                    }
                  />
                  <FormControlLabel
                    id="radio-form-control-3"
                    value={t("enterpriseBillingSummary.printZeroCountReport")}
                    control={<Radio />}
                    label={t("enterpriseBillingSummary.printRadioZeroCount")}
                    disabled={
                      searchPanelForm?.values?.billCycle.trim() ===
                      billingCycle.oto
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/*Print modal branch field*/}
            <Grid
              id="branch-field"
              item
              sx={enterpriseBillingSummaryLayoutStyles.printModalBranchField}
            >
              <BranchField
                label={"Branch"}
                options={allBranches || []}
                disabled={
                  searchPanelForm.values.printType ===
                  billingSummaryPrintTypes.enterpriseBillingSummary
                }
                value={searchPanelForm.values.branch}
                onChange={handleBranchChange}
              />
            </Grid>
          </Grid>

          <Stack
            id="print-buttons"
            direction="row"
            spacing={spacing.horizontalMargin20}
            sx={enterpriseBillingSummaryLayoutStyles.printModalButton}
          >
            {/* Close button */}
            <Button
              id="print-close-btn"
              variant="outlined"
              size="medium"
              type="button"
              onClick={handleSelectPrintType}
            >
              {t("enterpriseBillingSummary.close")}
            </Button>
            {/* Download button */}
            <Button
              id="print-btn"
              variant="contained"
              size="medium"
              type="submit"
              onClick={handlePrint}
            >
              {t("enterpriseBillingSummary.print")}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default BillingSummaryPrintModal;
