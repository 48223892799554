export const DetailsStyles = {
  mainDivStyles: {
    marginTop: "20px"
  },
  fontColor: {
    color: "var(--color-im-grey-400)"
  },
  fontColor2: {
    color: "var(--color-im-grey-900)"
  },
  subHeading: {
    fontSize: "24px",
    color: "var(--color-im-grey-900)",
    fontWeight: 400,
    marginBottom: "10px"
  },
  subHeading2: {
    fontSize: "16px",
    color: "var(--color-im-grey-900)",
    fontWeight: 400,
    padding: "5px 0px 0px 10px"
  },
  sectionStyle: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "1rem",
    minHeight: " 10rem",
    width: " 100%",
    borderColor: "var(--color-im-dark-blue-500)",
    border: "1px solid",
    borderRadius: "8px",
    padding: "1rem"
  }
};
