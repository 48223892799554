import dayjs from "dayjs";
import i18n from "i18n";
import {
  maxDate,
  requestMaxDate
} from "utils/constants/disaster-module/EventSearchConstants";
import * as yup from "yup";

export const filterSchema = yup.object().shape({
  drEventDate: yup
    .date()
    .nullable()
    .typeError(i18n.t("disasterRecovery.eventSearch.invalidDate"))
    .test(
      "valid-date",
      i18n.t("disasterRecovery.eventSearch.invalidDate"),
      function (toDate, context) {
        const drEventDate = context?.parent?.drEventDate;
        const normalizeDate = (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return (
          drEventDate &&
          !isNaN(drEventDate.getTime()) &&
          normalizeDate(drEventDate) >=
            normalizeDate(
              new Date(i18n.t("disasterRecovery.eventSearch.minimumDate"))
            ) &&
          (!toDate ||
            normalizeDate(drEventDate) <= normalizeDate(new Date(maxDate)))
        );
      }
    )
});

export const filterRequestSchema = yup.object().shape({
  serviceDate: yup
    .date()
    .nullable()
    .typeError(i18n.t("disasterRecovery.eventSearch.invalidDate"))
    .test(
      "valid-date",
      i18n.t("disasterRecovery.eventSearch.invalidDate"),
      function (toDate, context) {
        const serviceDate = context?.parent?.serviceDate;
        const normalizeDate = (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return (
          serviceDate &&
          !isNaN(serviceDate.getTime()) &&
          normalizeDate(serviceDate) >=
            normalizeDate(
              new Date(i18n.t("disasterRecovery.eventSearch.minimumDate"))
            ) &&
          (!toDate ||
            normalizeDate(serviceDate) <=
              normalizeDate(new Date(requestMaxDate)))
        );
      }
    )
});

export const custometListValidateSchema = yup.object().shape({
  dateFrom: yup
    .date()
    .nullable()
    .typeError(i18n.t("disasterRecovery.eventSearch.invalidDate"))
    .test(
      "valid-from-date",
      i18n.t("disasterRecovery.eventSearch.invalidDate"),
      function (fromDate) {
        if (!fromDate || isNaN(fromDate.getTime())) return true;

        return (
          dayjs().isSameOrAfter(dayjs(fromDate)) &&
          dayjs().subtract(2, "years").isSameOrBefore(dayjs(fromDate), "day")
        );
      }
    ),
  dateTo: yup
    .date()
    .nullable()
    .typeError(i18n.t("disasterRecovery.eventSearch.invalidDate"))
    .test(
      "valid-to-date",
      i18n.t("disasterRecovery.eventSearch.invalidDate"),
      function (toDate, context) {
        const fromDate = context?.parent?.dateFrom;

        if (!toDate || isNaN(toDate.getTime())) return true;

        if (fromDate && !isNaN(fromDate.getTime())) {
          if (dayjs(toDate).isBefore(dayjs(fromDate), "day")) {
            return this.createError({
              path: "dateTo",
              message: i18n.t(
                "disasterRecovery.eventSearch.endDateGreaterThanStartDate"
              )
            });
          }
        }

        return (
          dayjs().isSameOrAfter(dayjs(toDate)) &&
          dayjs().subtract(2, "years").isSameOrBefore(dayjs(toDate), "day")
        );
      }
    )
});
