import spacing from "styles/spacing";

export const enterpriseBillingSummaryLayoutStyles = {
  alertBox: {
    marginBottom: spacing.horizontalMargin20,
    marginTop: spacing.horizontalMargin20
  },
  printModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "background.paper",
    borderRadius: "9px",
    boxShadow: 24
  },
  printModalBody: {
    padding: "16px",
    border: "1px solid var(--color-im-grey-100)"
  },
  printModalTitle: {
    padding: "16px"
  },
  printModalButton: {
    margin: "1em"
  },
  printModalBranchField: {
    width: "300px"
  },
  alertIcon: {
    paddingLeft: "20px"
  }
};
