import { FullCircularLoader } from "components/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const withLoading = (WrappedComponent) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const loadingState = useSelector((state) => state.loading.isLoading);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      setIsLoading(loadingState); // Update local state based on Redux state
    }, [loadingState]);

    return (
      <React.Fragment>
        {isLoading && <FullCircularLoader />}
        <WrappedComponent {...props} />
      </React.Fragment>
    );
  };
};

export default withLoading;
