export const DetailsStyles = {
  errorStyle: {
    marginBottom: "20px"
  },
  mainDivStyles: {
    marginTop: "20px"
  },
  tablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  completeColor: "var(--color-im-mid-green)",
  selectedColor: "var(--color-im-light-blue-100)",
  formStyle: { display: "flex", flexDirection: "row" },
  iconStyle: { width: 20 }
};

export const OpenMediaAuditStyles = {
  checkBoxStyle: {
    margin: "9px"
  },
  mainDivStyles: {
    marginTop: "20px"
  },
  boxWidth: { width: "310px" },
  inputLabelProps: { shrink: true, style: { zIndex: 0 } },
  flex: { display: "flex" },
  btn: { textTransform: "none", minWidth: "110px" },
  boxStyle: { display: "flex", gap: "20px" },
  alertWidth: { minWidth: "600px" },
  actionColumnStyle: { textAlign: "left" },
  fontColor: {
    color: "var(--color-im-grey-400)"
  },
  pmFont: {
    color: "var(--color-im-grey-900)"
  },
  subHeading: {
    fontSize: "24px"
  },
  listStyle: {
    listStyleType: "disc",
    pl: 2,
    lineHeight: 0,
    "& .MuiListItem-root": {
      display: "list-item",
      lineHeight: 0,
      p: 0
    }
  },
  commentSection: {
    maxHeight: 150,
    overflow: "auto"
  },
  commentSectionText: {
    color: "var( --color-im-grey-400)"
  }
};
