import {
  Button,
  Grid,
  Paper,
  SvgIcon,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as Check } from "assets/images/CheckFilled.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { ReactComponent as EditIconBeforeTheSave } from "assets/images/EditIconBeforeSave.svg";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { errorLogStyles } from "../billing-error-log/BillingErrorLogStyle";
import { customerBillingInformationStyles } from "./CustomerBillingInformationStyles";

const CustomerBillingInformationTable = ({
  billingInformation,
  selectedRow,
  handleRowClick,
  onPrintButtonCLick,
  isModalOpen
}) => {
  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    customToolbar: billingInformation?.length
      ? () => (
          <>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              gap={spacing.verticalMargin20}
            >
              <Button
                variant="outlined"
                size="medium"
                type="button"
                disabled={billingInformation?.length === 0}
                onClick={onPrintButtonCLick}
              >
                {t("common.print")}
              </Button>
            </Grid>
          </>
        )
      : null,
    textLabels: {
      body: {
        noMatch:
          billingInformation?.length === 0
            ? `${t("customerBillingInformation.provideInput")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor: isModalOpen
          ? dataIndex === selectedRow?.i
            ? customerBillingInformationStyles?.SelectedRowColor
            : ""
          : ""
      }
    })
  };

  //Table columns
  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta) => {
          const isSelectedRow =
            selectedRow && selectedRow.i === tableMeta.rowIndex;
          const isEdited = billingInformation[tableMeta.rowIndex]?.isEdited;
          const iconComponent =
            isSelectedRow && isModalOpen
              ? Check
              : isEdited
                ? EditIconBeforeTheSave
                : EditIcon;
          return (
            <Button
              onClick={() => {
                handleRowClick(value, tableMeta);
              }}
            >
              <SvgIcon component={iconComponent} />
            </Button>
          );
        }
      }
    },
    {
      name: "classification",
      label: t("customerBillingInformation.classification")
    },
    {
      name: "catalogItem",
      label: t("customerBillingInformation.catalogItem")
    },
    {
      name: "catalog",
      label: t("customerBillingInformation.catalog")
    },
    {
      name: "description",
      label: t("customerBillingInformation.description")
    }
  ];

  return (
    <>
      <Paper sx={customerBillingInformationStyles?.TablePaper}>
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={billingInformation}
            title={
              <Typography style={errorLogStyles.titleStyle}>
                {billingInformation?.length} {t("common.recordsFound")}
              </Typography>
            }
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default CustomerBillingInformationTable;
