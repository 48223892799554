import { Button, Grid, Stack, Typography } from "@mui/material";
import { DatePickerField } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { dayMonthYearFormat } from "utils/constants";
import { CustomerFileStyles } from "./CustomerFileStyles";
const CustomerFileModal = ({
  isUpdating,
  isDeleting,
  isViewing,
  handleCancelClick,
  selectedRow,
  customerFileSearchForm,
  updateCustomerFile,
  handleActivate,
  viewText,
  handleView,
  activateText,
  minDate,
  today,
  viewPath,
  handleViewPath,
  isUpdateDisable,
  setUpdateDisable
}) => {
  const { t } = useTranslation();
  const selectedDate = selectedRow?.Service_Date
    ? dayjs(selectedRow?.Service_Date)
    : null;

  const handleInputChange = (name, value) => {
    setUpdateDisable(false);
    customerFileSearchForm.setFieldValue(name, value);
  };

  return (
    <>
      <CommonModal
        open={isUpdating || isDeleting || isViewing}
        title={
          isUpdating
            ? t("CustomerFileSearch.editTitle")
            : isViewing
              ? t("CustomerFileSearch.viewFile")
              : isDeleting && t("common.confirm")
        }
        body={
          <>
            {isViewing ? (
              <>
                <Stack direction="row" gap={spacing.gap}>
                  <p>
                    {viewPath ? <a href={viewPath}>{viewPath}</a> : viewText}
                  </p>
                </Stack>
              </>
            ) : isDeleting ? (
              <>
                <Stack direction="row" gap={spacing.gap}>
                  <p>{activateText}</p>
                </Stack>
              </>
            ) : (
              <>
                <Grid container spacing={spacing.gap}>
                  <Grid item xs={6}>
                    <Typography variant="caption" component="small">
                      {t("CustomerFileSearch.customer")}
                    </Typography>
                    <Typography> {selectedRow?.customer_number}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="caption" component="small">
                      {t("CustomerFileSearch.fileSource")}
                    </Typography>

                    <Typography> {selectedRow?.file_source}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={spacing.gap}>
                  <Grid
                    id="serviceDate"
                    item
                    xs={6}
                    marginTop={spacing.margingTop20}
                  >
                    <DatePickerField
                      id="serviceDate"
                      name="serviceDate"
                      label={t("CustomerFileSearch.serviceDate")}
                      value={
                        customerFileSearchForm?.values.serviceDate ||
                        selectedDate
                      }
                      minDate={today}
                      onChange={(value) =>
                        handleInputChange("serviceDate", value)
                      }
                      format={dayMonthYearFormat}
                      sx={CustomerFileStyles.fieldStyle}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        }
        buttons={
          <>
            {isDeleting ? (
              <>
                <Button
                  id="saveBtn-modal"
                  variant="outlined"
                  disabled={null}
                  onClick={handleCancelClick}
                  sx={null}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  id="saveBtn-modal"
                  variant="contained"
                  disabled={null}
                  onClick={handleActivate}
                  sx={null}
                >
                  {t("common.ok")}
                </Button>
              </>
            ) : isViewing ? (
              <>
                <Button
                  id="saveBtn-modal"
                  variant="outlined"
                  disabled={null}
                  onClick={handleCancelClick}
                  sx={null}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  id="CancelBtn-modal"
                  variant="contained"
                  onClick={viewPath ? handleViewPath : handleView}
                  sx={null}
                >
                  {t("common.ok")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  id="saveBtn-modal"
                  variant="outlined"
                  disabled={null}
                  onClick={handleCancelClick}
                  sx={null}
                >
                  {t("common.cancel")}
                </Button>

                <Button
                  id="CancelBtn-modal"
                  variant="contained"
                  onClick={isUpdating ? updateCustomerFile : handleCancelClick}
                  sx={null}
                  disabled={isUpdateDisable}
                >
                  {t("common.update")}
                </Button>
              </>
            )}
          </>
        }
      />
    </>
  );
};
export default CustomerFileModal;
