import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ModalPopup from "components/core/modal-components/ModalPopup";
import spacing from "styles/spacing";
import {
  deliveryRequest,
  otherRequest
} from "utils/constants/disaster-module/EventSearchConstants";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";

const DRRequestGeneratorModal = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [requestType, setRequestType] = useState(deliveryRequest);

  return (
    <ModalPopup
      title={t("disasterRecovery.eventDetail.generateRequest")}
      modalPopupOpen={open}
      handleClick={onClose}
      alertMessage={
        <>
          <Box>
            <Typography style={disasterRecoveryStyles.commonStyles.requestDesc}>
              {t("disasterRecovery.eventDetail.selectRequestLabel")}
            </Typography>
            <Typography mt={spacing.horizontalMargin16}>
              {t("disasterRecovery.eventDetail.requestType")}
            </Typography>
            <FormControl>
              <RadioGroup
                name="requesttype-radio-buttons-group"
                aria-labelledby="requesttype-radio-buttons-group-label"
                value={requestType}
                onChange={(e) => setRequestType((prevState) => e.target.value)}
              >
                <Box mb={spacing.gap10} ml={spacing.gap10}>
                  <FormControlLabel
                    value={deliveryRequest}
                    control={<Radio />}
                    label={t("disasterRecovery.eventDetail.deliveryRequest")}
                  />
                  <Typography
                    style={
                      disasterRecoveryStyles.commonStyles.requestDescHelper
                    }
                  >
                    {t("disasterRecovery.eventDetail.deliveryRequestHelpText")}
                  </Typography>
                </Box>
                <Box ml={spacing.gap10}>
                  <FormControlLabel
                    value={otherRequest}
                    control={<Radio />}
                    label={t("disasterRecovery.eventDetail.otherRequest")}
                  />
                  <Typography
                    style={
                      disasterRecoveryStyles.commonStyles.requestDescHelper
                    }
                  >
                    {t("disasterRecovery.eventDetail.otherRequestHelpText")}
                  </Typography>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        </>
      }
      modalButton={
        <Stack gap={spacing.gap} direction={"row"}>
          <Button variant="outlined" onClick={onClose}>
            {t("common.close")}
          </Button>
          <Button variant="contained" onClick={() => onSubmit(requestType)}>
            {t("common.ok")}
          </Button>
        </Stack>
      }
    />
  );
};

export default DRRequestGeneratorModal;
