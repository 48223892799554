import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  dayMintMonthYearFormat
} from "utils/constants";

const BatchDetail = ({ tableData }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (tableData?.length) {
      let sortData = tableData?.sort((a, b) => a?.batch_id - b?.batch_id);
      setData([...sortData]);
    }
  }, [tableData]);
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS
  };
  const columns = [
    {
      name: "batch_id",
      label: t("openMediaAuditDetail.batchId")
    },
    {
      name: "created_datetime_ltz",
      label: t("openMediaAuditDetail.batchDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>{value ? dayjs.utc(value).format(dayMintMonthYearFormat) : ""}</>
          );
        }
      }
    },
    {
      name: "employee_name",
      label: t("openMediaAuditDetail.employee")
    },
    {
      name: "audit_batch_status",
      label: t("openMediaAuditDetail.batchStatus")
    },

    {
      name: "scan_count",
      label: t("openMediaAuditDetail.scanCount")
    },

    {
      name: "missing_count",
      label: t("openMediaAuditDetail.missing")
    },

    {
      name: "unexpected_count",
      label: t("openMediaAuditDetail.unexpected")
    }
  ];
  return (
    <Grid item xs={12} lg={3} marginTop={4}>
      <MUIDataTable columns={columns} data={[...data]} options={options} />
    </Grid>
  );
};

export default BatchDetail;
