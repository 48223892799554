import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { t } from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GENERAL,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH
} from "routing/Paths";
import { getMessageFromCode } from "services/api/query";
import { VALUE_EMPTY_STRING, errorMsgs } from "utils/constants";
import { ContainerProcessingStyles } from "./ContainerProcessingStyles";
import Distribution from "./general-module/distribution/Distribution";
import IncompleteStops from "./general-module/incompleteStops/IncompleteStops";
import Picking from "./general-module/picking/Picking";
import RunPrep from "./general-module/runPrep/RunPrep";

const ContainerPocessingTabs = [
  {
    label: t("containerProcessing.pickingTab"),
    value: t("containerProcessing.pickingTab")
  },
  {
    label: t("containerProcessing.runprepTab"),
    value: t("containerProcessing.runprepTab")
  },
  {
    label: t("containerProcessing.distributionTab"),
    value: t("containerProcessing.distributionTab")
  },
  {
    label: t("containerProcessing.incompleteStopsTab"),
    value: t("containerProcessing.incompleteStopsTab")
  }
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const ContainerTabs = ({ handleTabIndexChange }) => {
  const { id, date } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const pickRef = React.useRef();
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState(VALUE_EMPTY_STRING);
  const [tempMemory, setTempMemory] = useState({ process: null, data: null });
  const [madeChanges, setMadeChanges] = useState(false); // Initialize as false
  const [check, setCheck] = useState("");
  const [madeChangesModal, setMadeChangesModal] = useState(false);
  const [nextTabIndex, setNextTabIndex] = useState(null); // Track the next tab index
  const [verifyTabMsg, setVerifyTabMsg] = useState("");
  const [openVerifyTabModal, setOpenVerifyTabModal] = useState(false);

  const isSaveCheck = async (event, newValue) => {
    if (value === 0) {
      if (check) {
        setMadeChangesModal(true);
        setNextTabIndex(newValue);
      } else if (madeChanges) {
        const verifyDescr = await getMessageFromCode(errorMsgs.errorCode20343);
        const msg = verifyDescr[0]?.descr?.replace(
          "|",
          t("containerProcessing.pickingTab")
        );
        setVerifyTabMsg(msg);
        setNextTabIndex(newValue);
        setOpenVerifyTabModal(true);
      } else {
        setMadeChanges(false);
        handleChange(newValue);
      }
    } else {
      setMadeChanges(false);
      handleChange(newValue);
    }
  };

  const handleChange = (newValue) => {
    if (newValue !== 2 && newValue !== 0 && newValue !== 3) {
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${GENERAL}`
      );
    }
    setValue(newValue);
    handleTabIndexChange(newValue);
  };

  const yes = async () => {
    await pickRef.current.verify();
    setMadeChanges(false);
    setMadeChangesModal(false);
    if (nextTabIndex !== null) {
      handleChange(nextTabIndex);
    }
  };

  useEffect(() => {
    if (id && !date) {
      handleChange(parseInt(id));
    } else if (date && id) {
      handleChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, date]);

  return (
    <>
      <Box sx={ContainerProcessingStyles.tabBox}>
        <Box sx={ContainerProcessingStyles.tabBox1}>
          <Tabs
            value={value}
            onChange={isSaveCheck}
            aria-label="basic tabs example"
          >
            {ContainerPocessingTabs.map((obj, index) => {
              return (
                <Tab key={index} label={obj.label} {...a11yProps(index)} />
              );
            })}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Picking
            pickRef={pickRef}
            setMadeChanges={setMadeChanges}
            madeChanges={madeChanges}
            setMadeChangesModal={setMadeChangesModal}
            madeChangesModal={madeChangesModal}
            check={check}
            setCheck={setCheck}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RunPrep
            isUnsavedChanges={isUnsavedChanges}
            setIsUnsavedChanges={setIsUnsavedChanges}
            handleChange={handleChange}
            showWarning={showWarning}
            setShowWarning={setShowWarning}
            warningMessage={warningMessage}
            setWarningMessage={setWarningMessage}
            tempMemory={tempMemory}
            setTempMemory={setTempMemory}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Distribution />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <IncompleteStops />
        </CustomTabPanel>
      </Box>
      {madeChangesModal && (
        <ConfirmPopup
          modalPopupOpen={madeChangesModal}
          message={t("containerProcessing.picking.saveErr")}
          handleNo={() => {
            setMadeChangesModal(false);
            setMadeChanges(false);
            if (nextTabIndex !== null) {
              handleChange(nextTabIndex);
            }
          }}
          handleYes={yes}
          handleCancel={() => setMadeChangesModal(false)}
          showCancel={true}
          showNo={true}
        />
      )}
      {openVerifyTabModal && (
        <ConfirmPopup
          modalPopupOpen={openVerifyTabModal}
          message={verifyTabMsg}
          handleNo={() => {
            setOpenVerifyTabModal(false);
            if (nextTabIndex !== null) {
              handleChange(nextTabIndex);
            }
          }}
          handleYes={() => pickRef.current.verify()}
          handleCancel={() => setOpenVerifyTabModal(false)}
          showCancel={true}
          showNo={true}
        />
      )}
    </>
  );
};

ContainerTabs.propTypes = {
  handleTabIndexChange: PropTypes.func.isRequired
};

export default ContainerTabs;
