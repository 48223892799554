import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { DatePickerField } from "components/core";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import {
  eventStatus,
  expiredMedia,
  returnOnMinDate,
  unexpiredMedia
} from "utils/constants/disaster-module/EventSearchConstants";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";

const Distribution = ({
  EventDetailForm,
  localeByBranch,
  DEFAULT_LOCALE,
  handleDrEventDate,
  isValid257Role,
  currentDate,
  timeZoneOffsetChanged
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction={"column"} gap={spacing.gap}>
        <Typography style={disasterRecoveryStyles.commonStyles.subHeading}>
          {t("disasterRecovery.eventDetail.distribution")}
        </Typography>
        <Stack direction="row" gap={spacing.gap}>
          <FormControl>
            <FormLabel id="unexpiredOMRDates">
              {t("disasterRecovery.eventDetail.unexpiredOMRDate")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="unexpiredOMRDates-radio-buttons-group-label"
              defaultValue={EventDetailForm.values.retainReturnDateFlag}
              onChange={(event) => {
                EventDetailForm.setFieldValue(
                  "retainReturnDateFlag",
                  event.target.value
                );
              }}
              name="unexpiredOMRDates-radio-buttons-group"
            >
              <FormControlLabel
                value={unexpiredMedia.retainOpenMedia}
                control={
                  <Radio
                    disabled={
                      !(
                        (EventDetailForm.values.drEventStatusId ===
                          eventStatus.newEvent ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.logged ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.outBound) &&
                        isValid257Role
                      )
                    }
                    checked={
                      EventDetailForm.values.retainReturnDateFlag ===
                      unexpiredMedia.retainOpenMedia
                    }
                  />
                }
                label={t("disasterRecovery.eventDetail.retainExistingOMR")}
              />
              <FormControlLabel
                value={unexpiredMedia.indefiniteRetention}
                control={
                  <Radio
                    disabled={
                      !(
                        (EventDetailForm.values.drEventStatusId ===
                          eventStatus.newEvent ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.logged ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.outBound) &&
                        isValid257Role
                      )
                    }
                    checked={
                      EventDetailForm.values.retainReturnDateFlag ===
                      unexpiredMedia.indefiniteRetention
                    }
                  />
                }
                label={t("disasterRecovery.eventDetail.indefiniteRetention")}
              />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel id="expiredMedia">
              {t("disasterRecovery.eventDetail.expiredMedia")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="expiredMedia-radio-buttons-group-label"
              defaultValue={EventDetailForm.values.expiredMediaOption}
              onChange={(event) => {
                EventDetailForm.setFieldValue(
                  "expiredMediaOption",
                  event.target.value
                );
              }}
              name="expiredMedia-radio-buttons-group"
            >
              <FormControlLabel
                value={expiredMedia.returnNextDay}
                control={
                  <Radio
                    disabled={
                      !(
                        (EventDetailForm.values.drEventStatusId ===
                          eventStatus.newEvent ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.logged ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.outBound) &&
                        isValid257Role
                      )
                    }
                    checked={
                      EventDetailForm.values.expiredMediaOption ===
                      expiredMedia.returnNextDay
                    }
                  />
                }
                label={t("disasterRecovery.eventDetail.returnNextDay")}
              />
              <FormControlLabel
                value={expiredMedia.returnOnNextSchedule}
                control={
                  <Radio
                    disabled={
                      !(
                        (EventDetailForm.values.drEventStatusId ===
                          eventStatus.newEvent ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.logged ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.outBound) &&
                        isValid257Role
                      )
                    }
                    checked={
                      EventDetailForm.values.expiredMediaOption ===
                      expiredMedia.returnOnNextSchedule
                    }
                  />
                }
                label={t("disasterRecovery.eventDetail.returnOnNextSchedule")}
              />
              <FormControlLabel
                value={expiredMedia.returnOn}
                control={
                  <Radio
                    disabled={
                      !(
                        (EventDetailForm.values.drEventStatusId ===
                          eventStatus.newEvent ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.logged ||
                          EventDetailForm.values.drEventStatusId ===
                            eventStatus.outBound) &&
                        isValid257Role
                      )
                    }
                    checked={
                      EventDetailForm.values.expiredMediaOption ===
                      expiredMedia.returnOn
                    }
                  />
                }
                label={t("disasterRecovery.eventDetail.returnOn")}
              />
              <DatePickerField
                id="returnDate"
                locale={localeByBranch || DEFAULT_LOCALE}
                variant="input"
                key={timeZoneOffsetChanged}
                value={
                  EventDetailForm.values.expiredMediaReturnDate &&
                  EventDetailForm.values.expiredMediaOption ===
                    expiredMedia.returnOn
                    ? EventDetailForm.values.expiredMediaReturnDate
                    : returnOnMinDate
                }
                sx={
                  EventDetailForm.values.expiredMediaOption ===
                  expiredMedia.returnOn
                    ? disasterRecoveryStyles.commonStyles.widthFixed
                    : disasterRecoveryStyles.tableStyles.hiddenButton
                }
                disabled={
                  !(
                    EventDetailForm.values.expiredMediaOption ===
                      expiredMedia.returnOn && isValid257Role
                  )
                }
                minDate={currentDate.add(1, "day")}
                maxDate={currentDate.add(30, "day")}
                onChange={async (date) => {
                  // Update toDate without triggering touched event
                  const toDateWithoutTouched = (date) => {
                    EventDetailForm.setFieldValue(
                      "expiredMediaReturnDate",
                      date
                    );
                  };

                  toDateWithoutTouched(date);
                }}
                error={!!EventDetailForm.errors.expiredMediaReturnDate}
                helperText={EventDetailForm.errors.expiredMediaReturnDate}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
};

export default Distribution;
