import spacing from "styles/spacing";

export const VaultManagementReportsStyles = {
  wrapper: {
    minHeight: "50vh"
  },
  gridWrapper: {
    minWidth: "55vw"
  },
  labelFrom: {
    color: "var(--color-black)",
    fontSize: "20px",
    fontWeight: "500"
  },
  reportRadioGroup: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    padding: "10px"
  },
  secLabelForm: {
    fontSize: "16px",
    color: "var(--color-im-grey-900)"
  },
  checkboxColor: {
    color: "primary.main"
  },
  includeDetailsWrapper: {
    display: "flex",
    alignItems: "center"
  },
  perSymbol: {
    fontSize: "22px",
    fontWeight: "500",
    paddingRight: spacing.gap10
  },
  errorIcon: {
    width: "22px"
  }
};
