/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import { dateFormat } from "utils/constants";
import { billingCycle } from "utils/constants/biling-module";
import {
  formatDateByLocale,
  getAuthenticatedUserBranch,
  handleDefaultDate
} from "utils/helpers";
import DatePickerField from "../date-picker-field/DatePickerField";
import SelectField from "../select-field/SelectField";

export const BillDateField = ({
  billCycle,
  billDate,
  setThrowErrorMessage,
  setThrowError,
  minDate,
  maxDate,
  handleDatePickerChange,
  handleBillDateChange,
  error,
  helperText,
  form,
  value
}) => {
  const selectedBranch = getAuthenticatedUserBranch();
  const { localeByBranch } = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);

  const trimmedBillCycle = billCycle.trim();

  const calculateDefaultEOMValue = () => {
    const currentDate = dayjs();
    const defaultEOMDate = currentDate.subtract(1, "month").endOf("month");
    return defaultEOMDate.format(dateFormat.universalFormat);
  };

  const defaultEOMValue = billDate ? billDate : calculateDefaultEOMValue();
  const defaultOTOValue = Date.now();
  const [selectedDate, setSelectedDate] = useState(
    trimmedBillCycle === billingCycle.eom ? defaultEOMValue : defaultOTOValue
  );
  const [billDates, setBillDates] = useState([]);

  const getBillingDates = async () => {
    setLoading(true);
    try {
      var reqBody = JSON.stringify({
        main_district_id: selectedBranch,
        billing_cycle_code: billingCycle.eom
      });
      const response = await callToCloudFunction(
        reqBody,
        `${CF_URLS.BillingCommon.BillRun}`
      );

      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const billingDates = data?.data[0];

      if (billingDates) {
        const datesArray = [];
        //helper function for extract date from return data from the sp
        const extractDate = (obj) => {
          const date = obj.billing_date.substring(0, 10);
          datesArray.push(date);
        };
        //looping from return data from the sp and push new dates
        billingDates.forEach((date) => extractDate(date));

        //sorting dates
        datesArray.sort();

        //getting future 12 months
        const futureDates = calculateBillDate();

        //concat past 6 months and future 12 months
        const allDatesArray = datesArray.concat(futureDates);

        //create options label,value array array using allDatesArray
        let optionsArray = [];
        optionsArray.length === 0 ? setLoading(true) : setLoading(false);

        allDatesArray.forEach(async (date) =>
          optionsArray.push({
            value: date,
            label: await formatDateByLocale(date, localeByBranch, false)
          })
        );
        //set sorted dates array for bill dates state

        setBillDates(optionsArray);
        setLoading(false);
      } else if (data?.error) {
        setThrowErrorMessage(data?.error);
        setThrowError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const calculateBillDate = () => {
    const today = new Date();
    const options = [];
    for (let i = 0; i < 12; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() + i + 1, 0);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has 2 digits
      const day = date.getDate().toString().padStart(2, "0"); // Ensure day has 2 digits
      options.push(`${year}-${month}-${day}`);
    }
    return options;
  };

  const handleSelectedDateChange = (e) => {
    setSelectedDate(e.target.value);
    handleBillDateChange(e.target.value); // Call the function from props to update Selected Date value
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    handleDatePickerChange(e); // Call the function from props to update DatePickerField value
  };

  useEffect(() => {
    setSelectedDate(
      trimmedBillCycle === billingCycle.eom ? defaultEOMValue : defaultOTOValue
    );
  }, [billCycle]);

  useEffect(() => {
    if (trimmedBillCycle === billingCycle.eom && billDates.length) {
      handleDefaultDate(form, defaultEOMValue);
    }
  }, [billDates]);

  useEffect(() => {
    selectedBranch && getBillingDates();
  }, [selectedBranch, localeByBranch, billCycle]);

  return (
    <>
      {trimmedBillCycle === billingCycle.eom ? (
        <SelectField
          label={t("common.billDate")}
          disabled={!billCycle}
          value={selectedDate}
          onChange={handleSelectedDateChange}
          options={billDates}
          defaultValue={defaultEOMValue}
          loading={loading}
        />
      ) : trimmedBillCycle === billingCycle.oto ? (
        <DatePickerField
          id="bill_Date"
          name="billDatePicker"
          label={t("common.billDate")}
          locale={localeByBranch}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleDateChange}
          error={error}
          helperText={helperText}
        />
      ) : (
        <TextField label={t("common.billDate")} value={""} disabled fullWidth />
      )}
    </>
  );
};
