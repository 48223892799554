import { createSlice } from "@reduxjs/toolkit";

const openMediaAuditDetailsSlice = createSlice({
  name: "openMediaAuditDetails",
  initialState: {
    viewModeId: null,
    isReload: false
  },
  reducers: {
    setViewModeId(state, action) {
      state.viewModeId = action?.payload;
    },
    setIsReload(state, action) {
      state.isReload = action?.payload;
    }
  }
});

export const { setViewModeId, setIsReload } =
  openMediaAuditDetailsSlice.actions;

export default openMediaAuditDetailsSlice.reducer;
