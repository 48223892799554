import { Grid,Typography } from "@mui/material";
import { t } from "i18next";
import ToDoItem from "./ToDoItem";

const RequestRuns = ({
    allUnassignedRequests,
    requiringConfirmation,
    drEvents,
    customerToConfirm,
    branchToSchedule,
    runsNotCompleted,
    incompleteStops,
    onCardClick
  }) => {
    return (
        <>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">{t("dashboard.requests")}</Typography>
          <ToDoItem
            labelText={t("dashboard.allUnassignedRequests")}
            number={allUnassignedRequests}
            onCardClick={onCardClick}
          />
          <ToDoItem
            labelText={t("dashboard.requiringConfirmation")}
            number={requiringConfirmation}
            onCardClick={onCardClick}
          />
          <ToDoItem
            labelText={t("dashboard.DREventsinProgress")}
            number={drEvents}
            onCardClick={onCardClick}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            {t("dashboard.mediaDestructionRequests")}
          </Typography>
          <ToDoItem
            labelText={t("dashboard.customerToConfirm")}
            number={customerToConfirm}
            onCardClick={onCardClick}
          />
          <ToDoItem
            labelText={t("dashboard.branchtoScheduleComplete")}
            number={branchToSchedule}
            onCardClick={onCardClick}
          />
          <Typography mt={4} variant="h6">
            {t("dashboard.runs")}
          </Typography>
          <ToDoItem
            labelText={t("dashboard.runsNotCompleted")}
            number={runsNotCompleted}
            onCardClick={onCardClick}
          />
          <ToDoItem
            labelText={t("dashboard.incompleteStops")}
            number={incompleteStops}
            onCardClick={onCardClick}
          />
        </Grid>
      </>
    );
  };
  
  export default RequestRuns;