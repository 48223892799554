import { Box, Stack, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import spacing from "styles/spacing";
import { maxCount } from "utils/constants";
import { MDRPickupFormStyles } from "../MediaDestructionRequestPickup.style";

function BoxTypes({ ...props }) {
  const formik = useFormikContext();
  useEffect(() => {
    // setting the values from props
    const value = props.boxTypes;
    formik.setFieldValue(props.fieldName, value);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.boxTypes, props.fieldName]);
  // Handler to update values
  const handleValueChange = (index) => (event) => {
    // updating the box type count on boxes
    formik.setFieldTouched(`${props.fieldName}[${index}].value`, true, false);
    if (event.target.value <= maxCount) {
      formik.setFieldValue(
        `${props.fieldName}[${index}].value`,
        Math.ceil(event.target.value).toString()
      );
      props.boxTypesVal(Math.ceil(event.target.value).toString(), index);
    }
  };

  return (
    <Box>
      <Stack direction="row" gap={spacing.verticalMargin20}>
        {formik.values[props.fieldName]?.map((value, index) => (
          <Box sx={MDRPickupFormStyles.FieldWidthMedium} key={index}>
            <TextField
              key={index}
              disabled={props.diableSITAD}
              label={`${value.boxType}`}
              id={`${value.boxType}`}
              inputProps={{ min: 0, max: 999, width: "310px" }}
              value={value.value}
              onChange={handleValueChange(index)}
              error={Boolean(formik.errors[props.fieldName]?.[index]?.value)}
              helperText={formik.errors[props.fieldName]?.[index]?.value}
              onBlur={formik.handleBlur}
              fullWidth
              type="number"
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default BoxTypes;
