import { useCallback, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import { DISTRICT_ID_GET_ALL_LOCATIONS, ERROR_TYPES } from "utils/constants";
import { findErrorMessage, getResponseData } from "utils/helpers";

const useFetchData = () => {
    const [typesData, setTypesData] = useState([]);
    const [failurePoints, setFailurePoints] = useState([]);
    const [rootCauseData, setRootCauseData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [throwErrorMessage, setThrowErrorMessage] = useState(null);
    const [rootCauseEmployee, setRootCauseEmployee] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSystemError = () => {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    };

    const fetchTypes = useCallback(async (selectedBranchId) => {
        try {
            setLoading(true);
            const reqBody = {
                main_district_id: selectedBranchId,
                display_manual_only_flag: null,
            };
            const typesResponse = await getResponseData(reqBody, CF_URLS.correctiveActionRequest.typesUrl, 1);
            const mappedTypes = typesResponse?.data?.[0]
                ?.map((res) => ({
                    value: res?.corrective_action_request_type_id,
                    label: res?.corrective_action_request_type_descr,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setTypesData(mappedTypes);
            setLoading(false);
        } catch (error) {
            handleSystemError();
            setLoading(false);
        }
    }, []);

    const fetchFailurePoints = useCallback(async () => {
        try {
            setLoading(true);
            const reqBody = {
                main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                show_active_only_flag: null,
            };
            const failurePointResponse = await getResponseData(
                reqBody,
                CF_URLS.correctiveActionRequest.failurePointUrl,
                1
            );
            const mappedFailurePoints = failurePointResponse?.data?.[0]
                ?.map((res) => ({
                    value: res?.failure_point_id,
                    label: res?.failure_point_descr,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setFailurePoints(mappedFailurePoints);
            setLoading(false);
        } catch (error) {
            handleSystemError();
            setLoading(false);
        }
    }, []);

    const fetchRootCause = useCallback(async () => {
        try {
            setLoading(true);
            const reqBody = {
                main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                show_active_only_flag: null,
            };
            const rootCauseResponse = await getResponseData(reqBody, CF_URLS.correctiveActionRequest.rootCauseUrl, 1);
            const mappedRootCause = rootCauseResponse?.data?.[0]
                ?.map((res) => ({
                    value: res?.root_cause_id,
                    label: res?.root_cause_descr,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setRootCauseData(mappedRootCause);
            setLoading(false);
        } catch (error) {
            handleSystemError();
            setLoading(false);
        }
    }, []);

    const fetchStatus = useCallback(async () => {
        try {
            setLoading(true);
            const reqBody = {
                main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
            };
            const statusResponse = await getResponseData(reqBody, CF_URLS.correctiveActionRequest.statusUrl, 1);
            const mappedStatus = statusResponse?.data?.[0]?.map((res) => ({
                value: res?.corrective_action_request_status_id,
                label: res?.corrective_action_request_status_descr,
            })).sort((a, b) => a.label.localeCompare(b.label));
            setStatusData(mappedStatus);
            setLoading(false);
        } catch (error) {
            handleSystemError();
            setLoading(false);
        }
    }, []);

    const fetchRootCauseEmployee = useCallback(async (namePartial) => {
        try {
            setLoading(true);
            const reqBody = {
                main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                name_partial: namePartial,
                filter_by_op_center: null,
                active_only: null,
            };
            const rootCauseEmployeeResponse = await getResponseData(
                reqBody,
                CF_URLS.securityEmployeeData.rootCauseEmployeeUrl,
                1
            );
            const mappedRootcauseEmployee = rootCauseEmployeeResponse?.data?.[0]?.map((res) => ({
                value: res?.employee_id,
                label: res?.employee_name,
            }));
            setRootCauseEmployee(mappedRootcauseEmployee);
            setLoading(false);
        } catch (error) {
            handleSystemError();
            setLoading(false);
        }
    }, []);

    return {
        typesData,
        failurePoints,
        rootCauseData,
        statusData,
        throwErrorMessage,
        rootCauseEmployee,
        loading,
        setLoading,
        setRootCauseEmployee,
        fetchTypes,
        fetchFailurePoints,
        fetchRootCauseEmployee,
        fetchStatus,
        fetchRootCause,
    };
};

export default useFetchData;
