import dayjs from "dayjs";
import { t } from "i18next";
import { getMessageFromCode } from "services/api/query";
import {
  VALUE_EMPTY_STRING,
  dayMonthYearFormat,
  ironmountainURL,
  numberConstants
} from "..";
import { ALL_SELECTION, errorMsgs } from "../index";

export const programSecurityCode299 = "299";
export const programSecurityCode306 = "306";
export const globalAttributeTypeIdMaxRowLimit = "134";
export const globalAttributeTypeIdCalFuture = "141";
export const globalAttributeTypeIdCalPast = "142";

export const pendingResolution = "Pending Resolution";
export const checkFilterOptions = {
  Branch: "B",
  Customer: "C"
};
export const EMPTY_SELECTION = {
  label: VALUE_EMPTY_STRING,
  value: numberConstants.minusOne
};
export const defaultStatus = {
  label: t("containerAuditSearch.scheduled"),
  value: numberConstants.one
};

export const getOptions = (response, value, label) => {
  const options = response.map((res) => ({
    value: res[value],
    label: res[label].trim()
  }));
  return options;
};

export const initialValues = {
  requestedBy: "",
  auditType: "",
  comment: "",
  due_date: null,
  vault_from: ALL_SELECTION?.value,
  vault_to: ALL_SELECTION?.value,
  row_from: ALL_SELECTION?.value,
  row_to: ALL_SELECTION?.value,
  rack_from: ALL_SELECTION?.value,
  rack_to: ALL_SELECTION?.value,
  shelf_from: ALL_SELECTION?.value,
  shelf_to: ALL_SELECTION?.value,
  audit_sample_method_id: "",
  customer_id: "",
  logical_vault_id_list: [],
  om_batch_id_list: []
};

export const containerAuditDetailConst = {
  dropdownListTypeId: "6",
  auditEnabled: "Container Audit Enabled",
  systemId: "60",
  typeId: "133",
  constantM: "M",
  requestor_code: "requestor_code",
  requestor_Id24: "24",
  audit_type_id: "audit_type_id",
  logical_vault: "Logical Vault",
  location: "Location",
  distribution: "Distribution",
  customer_distribution: "Customer Distribution",
  requested_by_label: "requestedBy",
  audit_type: "auditType",
  due_date: "due_date",
  audit_sample_method_id: "audit_sample_method_id",
  vault_from: "vault_from",
  vault_to: "vault_to",
  row_from: "row_from",
  row_to: "row_to",
  shelf_to: "shelf_to",
  shelf_from: "shelf_from",
  rack_from: "rack_from",
  rack_to: "rack_to",
  customer_id: "customer_id",
  ironMountainLink: "IronMountainLink",
  comment: "comment"
};

// message

export const messageCodes = {
  //logical vault error message code
  messageCodes80237: "80237",
  messageCodes80190: "80190",
  messageCodes80189: "80189",
  //customer distribution error message code
  messageCodes80238: "80238",
  messageCodes80192: "80192",
  messageCodes80191: "80191",

  messageCodes62521: "62521",
  messageCode80193: "80193",
  messageCode80194: "80194",
  messageCodes80175: "80175"
};

export const statusList = ["New", "Completed", "Canceled"];
export const CompletedStatus = "Completed";
export const requestedByCustomer = "Customer";
export const system_id = "69";
export const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
export const severityError = "error";
export const severityWarning = "warning";

export const getTheFirestoreErrorMessage = async (msgId) => {
  const msg = await getMessageFromCode(msgId);
  return { errorMsg: msg[0]?.descr };
};

export const handleGetnameFormatting = (name, date) => {
  return t("openMediaAuditDetail.nameDatelable", {
    name: name,
    date: dayjs.utc(date).format(dayMonthYearFormat)
  });
};

export const getTheEmailBody = async (name, linkData) => {
  let body =
    '<html><head><style type="text/css">p, a, body, td {font-family: Arial, Helvetica, sans-serif;font-size: x-small;}</style></head><body>';
  const actionText = await getTheFirestoreErrorMessage(
    errorMsgs.errorCode64004
  );
  body += actionText?.errorMsg;
  const actionText2 = await getTheFirestoreErrorMessage(
    errorMsgs.errorCode80124
  );
  body += actionText2?.errorMsg?.replace("#%1%", name);
  const actionText3 = await getTheFirestoreErrorMessage(
    errorMsgs.errorCode64005
  );
  body += actionText3?.errorMsg;
  if (linkData?.length) {
    const link = await getTheFirestoreErrorMessage(errorMsgs.errorCode64021);
    const url = linkData[0]?.setting_value;
    const linkTag = `<a href=${url}>${ironmountainURL}</a>`;
    body += link?.errorMsg?.replace("%1%", linkTag);
  }
  body += "</body></html>";
  return body;
};

export const unknownMedia = "Unknown Media";
export const letterV = "V";
export const True = "True";
export const addNewEmail = "addNewEmail";
export const hyphenSymbol = "-";
export const searchCount500 = 500;
export const maxCommentLength = 2048;
export const checkAuditStatus = {
  completed: "Completed",
  scheduled: "Scheduled",
  started: "Started",
  canceled: "Canceled"
};

export const specialCharRex = /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi;
