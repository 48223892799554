const cardStyle_PRSPopUP = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  boxShadow: 24
};

const mainTitle_PRSPopUP = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "32px",
  letterSpacing: "0.15px",
  color: "#1E417A",
  display: "flex",
  padding: "16px 24px",
  alignItems: "center",
  alignSelf: "stretch",
  width: "552px"
};

const cardContentStyle_PRSPopUP = {
  display: "flex",
  height: "fit-content",
  padding: "20px 16px 16px 16px",
  flexDirection: "column",
  alignItems: "flex-start",
  flexShrink: 0,
  alignSelf: "stretch",
  rowGap: "20px"
};

const demoContainerStyle_PRSPopUP = {
  display: "flex",
  alignItems: "flex-start",
  gap: "20px",
  alignSelf: "stretch",
  width: "568px"
};

const cardActionsStyle_PRSPopUP = {
  display: "flex",
  padding: "16px",
  alignItems: "flex-end",
  gap: "8px",
  alignSelf: "stretch"
};

const autocompleteStyle_PRSPopUP = {
  display: "flex",
  width: "270px",
  flexDirection: "column",
  alignItems: "flex-start"
};

const radioGroupStyle_PRSPopUP = {
  display: "flex",
  alignItems: "flex-start",
  gap: "20px",
  alignSelf: "stretch",
  width: "570px"
};

const buttonStyle_PRSPopUP = {
  display: "flex",
  padding: "6px 16px !important",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "initial",
  marginLeft: "0px !important"
};

const cardStyle_DRMPopUP = {
  ...cardStyle_PRSPopUP,
  width: "550px !important"
};

const formControlLabel = {
  width: "270px"
};

const printModalHeadingStyles = {
  fontWeight: "medium",
  paddingTop: "10px",
  paddingBottom: "10px"
};

export const requestModuleStyles = {
  autocompleteStyle_PRSPopUP,
  cardActionsStyle_PRSPopUP,
  cardContentStyle_PRSPopUP,
  cardStyle_PRSPopUP,
  demoContainerStyle_PRSPopUP,
  mainTitle_PRSPopUP,
  radioGroupStyle_PRSPopUP,
  buttonStyle_PRSPopUP,
  cardStyle_DRMPopUP,
  formControlLabel,
  printModalHeadingStyles
};
