/* eslint-disable no-unused-vars */
import { Button, Grid, SvgIcon, Tooltip, Typography } from "@mui/material";
import { ReactComponent as RemoveRedEyeFilled } from "assets/images/RemoveRedEyeFilled.svg";
import dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_MODULE_BASE_PATH,
  ADMIN_MODULE_BILLING_BASE_PATH,
  BILLING_STATUS
} from "routing/Paths";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  dateFormat,
  flags,
  messageCode
} from "utils/constants";
import {
  billEndTime,
  billingCycle,
  billingRunStatus
} from "utils/constants/biling-module";
import {
  formatDateByLocale,
  formatDateByLocaleWithTime,
  getAuthenticatedUserBranch
} from "utils/helpers";
const BillingSummaryTable = ({
  searchData,
  setThrowErrorMessage,
  findErrorMessage,
  setThrowError,
  searchPanelForm,
  handleOpenPrintModal,
  printEnable,
  clickedRowData,
  setClickedRowData,
  setIsLoading,
  fetchTableData,
  findEnable,
  setSelectedRow,
  selectedRow,
  localeByBranch,
  setThrowWarning,
  setThrowWarningMessage,
  pageToZero
}) => {
  const { t } = useTranslation();
  const { auth } = useSelector(selectAuth);
  const navigate = useNavigate();
  const districtId = getAuthenticatedUserBranch();

  const searchResultsCount = useMemo(() => searchData?.length, [searchData]);

  //handle row click helper function
  const handleRowClick = (rowData, rowMeta) => {
    const index = rowMeta.dataIndex;
    setSelectedRow(index);
    setClickedRowData(searchData[index]);
  };

  //handle build RA file click
  const handleBuildRAClick = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: districtId,
        billing_cycle_code: searchPanelForm?.values?.billCycle.trim(),
        billing_date:
          searchPanelForm?.values?.billCycle.trim() === billingCycle.eom
            ? searchPanelForm?.values?.billDateEom
            : dayjs(searchPanelForm?.values?.billDateOto).format(
                dateFormat.universalFormat
              ),
        completed_flag: flags.yflag,
        district_id: clickedRowData?.district_id,
        file_create_user: auth?.user?.login
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.billingSummary.buildRAFile
      );

      const buildRAData = await getDataFromFirestore(
        response,
        2,
        response.data.docId
      );
      const dataWithError = buildRAData?.data[0];
      const dataWithoutError = buildRAData?.data[1];

      if (Number(dataWithoutError[0]?.error) === 0) {
        setSelectedRow(null);
        setClickedRowData(null);
        fetchTableData();
      } else if (Number(dataWithError[0].error) !== 0) {
        if (
          Number(dataWithError[0].error) === messageCode.thereAreNoTransactions
        ) {
          const errorMsg = await getMessageFromCode(
            String(messageCode.thereAreNoTransactions)
          );
          if (errorMsg) {
            const replacedSentence = errorMsg[0]?.descr
              .replace(
                "|",
                searchPanelForm?.values?.billCycle.trim() === billingCycle.eom
                  ? await formatDateByLocale(
                      searchPanelForm?.values?.billDateEom,
                      localeByBranch,
                      false
                    )
                  : `${await formatDateByLocale(
                      dayjs(searchPanelForm?.values?.billDateOto).format(
                        dateFormat.universalFormat
                      ),
                      localeByBranch,
                      false
                    )} ${billEndTime}`
              )
              .replace("|", searchPanelForm?.values?.billCycle.trim());

            setThrowWarningMessage(replacedSentence);
            setThrowWarning(true);
          }
        } else {
          const errorMsg = await getMessageFromCode(
            String(dataWithError[0].error)
          );
          if (errorMsg) {
            setThrowWarningMessage(errorMsg[0]?.descr);
            setThrowWarning(true);
          }
        }
      } else if (buildRAData?.error) {
        setThrowErrorMessage(buildRAData?.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //handle archive file click
  const handleArchiveFileClick = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: districtId,
        billing_cycle_code: searchPanelForm?.values?.billCycle.trim(),
        billing_date:
          searchPanelForm?.values?.billCycle.trim() === billingCycle.eom
            ? searchPanelForm?.values?.billDateEom
            : dayjs(searchPanelForm?.values?.billDateOto).format(
                dateFormat.universalFormat
              ),
        district_id: clickedRowData?.district_id,
        file_create_user: auth?.user?.login
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.billingSummary.archiveFile
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const archiveFileData = data.data[0];
      if (archiveFileData) {
        setSelectedRow(null);
        setClickedRowData(null);
        fetchTableData();
      } else if (data?.error) {
        setThrowErrorMessage(archiveFileData.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onNavigate = (value) => {
    navigate(
      `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_STATUS}/${value}/${searchPanelForm?.values?.billCycle.trim()}/${
        searchPanelForm?.values?.billCycle.trim() === billingCycle.eom
          ? searchPanelForm?.values?.billDateEom
          : dayjs(searchPanelForm?.values?.billDateOto).format(
              dateFormat.universalFormat
            )
      }`
    );
  };
  const CustomBodyRenderComponent = ({ value, locale }) => {
    const changeValue = value.replace("T", " ").replace("Z", "");
    const [formattedDate, setFormattedDate] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        const result = await formatDateByLocaleWithTime(changeValue, locale);
        setFormattedDate(result);
      };
      fetchData();
    }, [value, locale, changeValue]);
    return formattedDate;
  };

  //Table columns
  const columns = [
    {
      name: "district_id",
      label: t("enterpriseBillingSummary.emptyLabelHeader"),
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value) => (
          <Button
            onClick={() => {
              onNavigate(value);
            }}
          >
            <Tooltip title={t("common.viewDetails")}>
              <SvgIcon component={RemoveRedEyeFilled} />
            </Tooltip>
          </Button>
        ),
        customHeadLabelRender: (columnMeta) => <div>{columnMeta.label}</div>
      }
    },
    {
      name: "code",
      label: t("enterpriseBillingSummary.branch")
    },
    {
      name: "billing_run_status_descr",
      label: t("enterpriseBillingSummary.status")
    },
    {
      name: "row_count",
      label: t("enterpriseBillingSummary.recs"),
      options: {
        customBodyRender: (value, tableMeta) =>
          tableMeta.rowData[5] !== "" && value !== String(0) ? (
            <>{value}</>
          ) : null
      }
    },
    {
      name: "file_name",
      label: t("enterpriseBillingSummary.fileName"),
      options: {
        customBodyRender: (value, tableMeta) =>
          tableMeta.rowData[5] !== "" ? <>{value}</> : null
      }
    },
    {
      name: "file_create_date_time",
      label: t("enterpriseBillingSummary.fileDateTime"),
      options: {
        customBodyRender: (value) =>
          value ? (
            <CustomBodyRenderComponent value={value} locale={localeByBranch} />
          ) : null
      }
    },
    {
      name: "file_create_user",
      label: t("enterpriseBillingSummary.user"),
      options: {
        customBodyRender: (value, tableMeta) =>
          tableMeta.rowData[5] !== "" ? <>{value}</> : null
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: pageToZero && 0,
    pagination: searchResultsCount && true,
    customToolbar: searchResultsCount
      ? () => (
          <>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              gap={spacing.verticalMargin20}
            >
              <Button
                variant="outlined"
                size="medium"
                type="button"
                disabled={
                  !(
                    Number(clickedRowData?.billing_run_status_id) ===
                    billingRunStatus.ready
                  )
                }
                onClick={handleBuildRAClick}
              >
                {t("enterpriseBillingSummary.buildRAFileButton")}
              </Button>

              <Button
                variant="outlined"
                size="medium"
                type="button"
                disabled={
                  !(
                    Number(clickedRowData?.billing_run_status_id) ===
                    billingRunStatus.complete
                  )
                }
                onClick={handleArchiveFileClick}
              >
                {t("enterpriseBillingSummary.archiveFile")}
              </Button>
              <Button
                variant="outlined"
                size="medium"
                type="button"
                disabled={printEnable}
                onClick={handleOpenPrintModal}
              >
                {t("enterpriseBillingSummary.printButton")}
              </Button>
            </Grid>
          </>
        )
      : null,
    textLabels: {
      body: {
        noMatch:
          !findEnable && searchResultsCount === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor:
          dataIndex === selectedRow ? "var(--color-im-light-blue-100)" : ""
      }
    }),
    onRowClick: handleRowClick
  };

  return (
    <>
      <MUIDataTable
        columns={columns}
        options={options}
        data={searchData}
        title={
          !findEnable && (
            <Typography style={commonStyles.tableRecordsFoundTitleStyle}>
              {searchResultsCount} {t("common.recordsFound")}
            </Typography>
          )
        }
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default BillingSummaryTable;
