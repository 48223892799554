import {
  Box,
  Divider,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from "@mui/material";
import { DatePickerField, SelectField } from "components/core";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { DEFAULT_LOCALE, Y_CHECKER } from "utils/constants";
import { ReactComponent as infoCircleEnable } from "../../../../../assets/images/infoCircleEnable.svg";
import { WEIGHT_MIN_MAX_INPUTPROPS } from "../Constants";
import { MDRPickupFormStyles } from "../MediaDestructionRequestPickup.style";
import BoxTypes from "../boxtypes-form/BoxTypes";
import CustomTooltip from "./CustomToolTip";

const MDRReviewApprovalRequest = ({
  diableSchedule,
  handleReviewApproveBoxTypes,
  reviewApproveBoxTypes,
  trackingRequirements,
  reviewErrorMsg,
  reviewApproveServiceLocation,
  setBoxtypeWraningReviewApprove,
  boxtypeWraningReviewApprove,
  handleErrorWarning,
  unitOfMeasures,
  reviewSection,
  enableWeightPanel,
  showReviewApprovedModified,
  localeByBranch
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const maxDate = dayjs(t("requestSearch.maximumDate"));
  const { authInfo } = useSelector(selectAuth);
  const { id } = useParams();
  const handleClickBoxTypeWraning = () => {
    setBoxtypeWraningReviewApprove(false);
  };
  const onHoldStatus =
    formik.values.mdr_on_hold_status === Y_CHECKER ? true : false;
  return (
    <>
      <Divider />
      <Stack gap={spacing.horizontalMargin20}>
        {/* <<<<======= Review Approve Request =====>>>>*/}
        <Stack gap={spacing.horizontalMargin20}>
          <Typography sx={MDRPickupFormStyles.Heading} variant="h5">
            {t("pickup.ReviewApproveRequest")}
          </Typography>
          <Stack gap={spacing.verticalMargin20} direction={"row"}>
            <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
              <SelectField
                disabled
                id="review-approve-tracking-requirements"
                labelId="review-approve-trackingRequirements-label"
                label={t("pickup.trackingRequirements")}
                name="review-approve-tracking-requirements"
                options={trackingRequirements}
                value={formik.values.reviewApproveTrackingRequirements}
                onChange={(event) => {
                  formik.setFieldValue(
                    "reviewApproveTrackingRequirements",
                    event.target.value
                  );
                }}
                sx={MDRPickupFormStyles.widthPercentage}
              />
            </Box>
            <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
              <DatePickerField
                disabled
                label={t("pickup.reviewDate")}
                name="review-date"
                id="review-date"
                locale={localeByBranch || DEFAULT_LOCALE}
                value={formik.values.reviewDate}
                onChange={(newValue) => {
                  formik.setFieldValue("reviewDate", newValue);
                }}
                sx={MDRPickupFormStyles.DatePickerField}
                maxDate={maxDate}
              />
            </Box>
            <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
              <SelectField
                disabled
                id="review-approve-service-location"
                labelId="review-approve-service-location-label"
                label={t("pickup.serviceLocationLabel")}
                name="review-approve-service-location"
                options={reviewApproveServiceLocation}
                value={formik.values.reviewApproveServiceLocation}
                onChange={(event) => {
                  formik.setFieldValue(
                    "reviewApproveServiceLocation",
                    event.target.value
                  );
                }}
              />
            </Box>
          </Stack>
          <Box>
            <TextField
              required
              disabled={reviewSection || onHoldStatus}
              label={t("pickup.reviewComments")}
              value={formik.values.reviewComments}
              onChange={(event) => {
                formik.handleChange(event);
                formik.setFieldValue("reviewComments", event.target.value);
              }}
              fullWidth
              multiline
              rows={3}
              style={MDRPickupFormStyles.widthPercentage}
              name="review-comments"
              id="review-comments"
              onBlur={formik.handleBlur}
              error={
                (formik.touched.reviewComments || reviewErrorMsg) &&
                Boolean(formik.errors.reviewComments)
              }
              helperText={
                (formik.touched.reviewComments || reviewErrorMsg) &&
                formik.errors.reviewComments
              }
            />
          </Box>
        </Stack>
        {/* <<<<=======  Quantity section =====>>>>*/}
        <Stack gap={spacing.verticalMargin20} direction={"row"}>
          <Box>
            <TextField
              id="requested-quantity"
              label={t("pickup.requestedQuantity")}
              size="medium"
              variant="standard"
              value={formik.values.requestedQuantity}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true
              }}
              InputLabelProps={{
                shrink: true,
                focused: false
              }}
            />
          </Box>
          <Box>
            <TextField
              id="pickedup-quantity"
              label={t("pickup.pickedupQuantity")}
              size="medium"
              variant="standard"
              value={formik.values.pickedUpQuantity}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true
              }}
              InputLabelProps={{
                shrink: true,
                focused: false
              }}
            />
          </Box>
          <Box>
            <TextField
              id="final-quantity"
              label={t("pickup.finalQuantity")}
              size="medium"
              variant="standard"
              value={formik.values.finalQuantity}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true
              }}
              InputLabelProps={{
                shrink: true,
                focused: false
              }}
            />
          </Box>
        </Stack>
        {/* <<<<======= Weight Section =====>>>>*/}
        <Stack gap={spacing.verticalMargin20} direction={"row"}>
          <Box sx={MDRPickupFormStyles.weightStyle}>
            <TextField
              label={t("pickup.weight")}
              id={"weight"}
              inputProps={WEIGHT_MIN_MAX_INPUTPROPS}
              value={formik.values.weight}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.weight)}
              helperText={formik.errors.weight}
              onBlur={formik.handleBlur}
              disabled={enableWeightPanel || onHoldStatus}
              fullWidth
              type="number"
            />
          </Box>
          <Box sx={MDRPickupFormStyles.weightDropdownStyle}>
            <SelectField
              id="weight-dropdown"
              labelId="weight-dropdown-label"
              name="weight-dropdown"
              options={unitOfMeasures}
              value={formik.values.selectedUnitOfMeasure}
              onChange={(event) => {
                formik.setFieldValue(
                  "selectedUnitOfMeasure",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.selectedUnitOfMeasure)}
              helperText={formik.errors.selectedUnitOfMeasure}
              disabled={enableWeightPanel || onHoldStatus}
            />
          </Box>
          <Box sx={MDRPickupFormStyles.tooltipStyle}>
            <CustomTooltip
              placement="right"
              arrow
              title={
                <div style={MDRPickupFormStyles.Tooltip}>
                  <div>{`${t("pickup.tooltipUnitofMeasure")}`}</div>
                </div>
              }
            >
              <Box>
                <SvgIcon component={infoCircleEnable} />
              </Box>
            </CustomTooltip>
          </Box>
        </Stack>
        {/* <<<<=======  BoxTypes for Schedule Service =====>>>>*/}
        <Box>
          <Typography
            sx={MDRPickupFormStyles.Heading}
            variant="h5"
            id="boxtypelabel"
          >
            {t("pickup.boxTypeLabel")}
          </Typography>
        </Box>
        {/* ------------------- BoxType Alert ------------------- */}
        {boxtypeWraningReviewApprove &&
          handleErrorWarning(
            handleClickBoxTypeWraning,
            t("pickup.boxTypeError")
          )}
        {showReviewApprovedModified && (
          <Box>
            <Stack gap="2em" direction={"row"}>
              <Typography disabled={diableSchedule} id="modifiedDate">
                {t("pickup.modifiedDate")}: {formik.values.reviewedModifiedDate}
              </Typography>
              <Typography disabled={diableSchedule} id="performedBy">
                {t("pickup.performedBy")} :{" "}
                <span>
                  {" "}
                  {id && formik.values.reviewedEmployeeName !== ""
                    ? formik.values.reviewedEmployeeName
                    : authInfo?.displayName}
                </span>
              </Typography>
            </Stack>
          </Box>
        )}
        <BoxTypes
          boxTypes={reviewApproveBoxTypes}
          diableSITAD={reviewSection || onHoldStatus}
          boxTypesVal={handleReviewApproveBoxTypes}
          calledFromSITAD={false}
          fieldName={"boxTypeValReviewApprove"}
        />
      </Stack>
    </>
  );
};

export default MDRReviewApprovalRequest;
