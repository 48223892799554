import Button from "@mui/material/Button";
import ModalPopup from "components/core/modal-components/ModalPopup";
import { useTranslation } from "react-i18next";

const ImportUnsupportedMediaFileModal = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalPopup
        title={t("mediaRequestDetail.confirm")}
        modalPopupOpen={props.isVisible}
        alertMessage={t("mediaRequestDetail.unSupportedFileFormat")}
        modalButton={[
          <Button
            variant="contained"
            onClick={() => {
              props.onOk();
            }}
            key={t("mediaRequestDetail.ok")}
          >
            {t("mediaRequestDetail.ok")}
          </Button>
        ]}
      />
    </>
  );
};

export default ImportUnsupportedMediaFileModal;
