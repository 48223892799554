import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";

import { DatePickerField, FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllCustomersQuery } from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import {
  openMediaResearchPersit,
  resetPersistedData,
  saveBeforeNavigate,
  selectAuth
} from "store/slices";
import {
  initialRearchState,
  researchReducer
} from "store/slices/open-media-research";
import spacing from "styles/spacing";
import {
  DAYJS_LOCALES,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  NO,
  ROLE_ACCESS_CODES,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  YES,
  downloadReportOptions,
  errorMsgs,
  mdyDateFormat,
  unauthorized,
  ymdDateFormat
} from "utils/constants";
import {
  actionTypes,
  bitOne,
  bitZero,
  confirmCreateFile,
  confirmEdit,
  confirmPrint,
  confirmUpdate,
  dates,
  delivered,
  getClosedContainerContents,
  getDrpCodes,
  getExchangeStatusValues,
  getLogicalVaultsforCustomer,
  getOpenMediaAndAatrixForCustomer,
  getlogicalvaultcodesforaudits,
  number1000,
  paddingRightLength,
  researchReportCountLimitFlag,
  researchReportCountLimitFlagR,
  searchCustomerandVolserList,
  sortColumnCode,
  totalNumberMatchingNumbers,
  updateOpenMediaAttributesAndAssociations
} from "utils/constants/open-media-processing/Research";
import {
  findErrorMessage,
  formatDateByLocale,
  getAuthenticatedUserBranch,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import CreateFileModal from "./CreateFileModal";
import MediaListModal from "./MediaListModal";
import ResearchDataTable from "./ResearchDataTable";
import { ResearchTableStyles } from "./ResearchTableStyles";
import UpdateModal from "./UpdateModal";
import { openMediaResearchStyles } from "./openMediaResearchStyles";

let customerDefaultOption = { label: t("common.all"), value: "" };

function OpenMediaResearch() {
  const { currentBranch, auth, roleAccess } = useSelector(selectAuth);
  const isUserHasDetailViewPermission = roleAccess.includes(
    ROLE_ACCESS_CODES.CODE_184
  );
  const isUserHasCreateeFileermission = roleAccess.includes(
    ROLE_ACCESS_CODES.CODE_183
  );

  const isUserHasUpdatePermission = roleAccess.includes(
    ROLE_ACCESS_CODES.CODE_182
  );

  const persistedState = useSelector(openMediaResearchPersit);

  const dispatchStore = useDispatch();

  const [reducerState, dispatch] = useReducer(
    researchReducer,
    initialRearchState
  );

  const {
    statusList,
    selectedSatus,
    lvList,
    selectedLv,
    disabledLV,
    drpList,
    disabledDrp,
    selectedDRP,
    mediaList,
    showDiscrepancy,
    disabledDiscrepancy,
    showDuplicates,
    disabledDuplicates,
    showMediaClosedContainers,
    disabledMediaClosedContainers,
    returnDate,
    mediaStartingNumber,
    startingSlot,
    endingSlot,
    description,
    loading,
    disablefindButton,
    disableSaveButton,
    disablePrintButton,
    disableDetailButton,
    disableCreateFileButton,
    tableData,
    omList,
    mediaTypeOptions,
    lvModalOptions,
    mediaNumber,
    searchMade,
    resultCount
  } = reducerState;
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (Object.keys(persistedState?.stateToPersist || {}).length) {
      dispatch({
        type: actionTypes.initiateAfterBack,
        payload: persistedState?.stateToPersist
      });
      setSelectedCustomer(persistedState?.customerSelected);
      setLocaleByBranch(persistedState?.localeByBranch);
      setSelectedRows(persistedState?.selectedRowIndex);
      dispatchStore(resetPersistedData());
    }
    // eslint-disable-next-line
  }, []);

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDownLoadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [openCreateFileModal, setOpenCreateFileModal] = useState(false);
  const [includeColumnHeaders, setIncludeColumnHeaders] = useState(false);
  const [
    excludeTransitAndDeliverDestroyed,
    setExcludeTransitAndDeliverDestroyed
  ] = useState(true);
  const [updateDataIds, setUpdateDataIds] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState("");
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [localeByBranch, setLocaleByBranch] = useState("");
  const [allLocations, setAllLocations] = useState([]);
  const [confirmUpdateOpen, setConfrimUpdateOpen] = useState("");
  const [awaitFieldValue, setAwaitFieldValue] = useState({});
  const [confirmMessage, setConfirmMessage] = useState("");

  const handleRowSelection = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    setSelectedRows((prev) => rowsSelected);
  };

  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  const districtId = getAuthenticatedUserBranch();

  const [selectedCustomer, setSelectedCustomer] = useState({
    ...customerDefaultOption
  });

  const minDate = dayjs(dates.minDate);
  const maxDate = dayjs(dates.maxDate);

  const handleClear = (e, skipCheck) => {
    if (!checkIfUpdateInProcess() && skipCheck !== VALUE_Y) {
      setAwaitFieldValue("");
      return;
    }
    setSelectedCustomer({ ...customerDefaultOption });
    dispatch({ type: actionTypes.reset });
    setErrorMessage("");
    setThrowErrorMessage("");
    setThrowError(false);
    setConfrimUpdateOpen("");
  };

  const handleOpenModal = () => {
    if (!checkIfUpdateInProcess()) return;
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUpdateModalClose = () => {
    setIsUpdateModalOpen(false);
  };

  const handleDownloadOpen = () => {
    setIsDownloadModalOpen(true);
  };

  const handleDownloadCancel = () => {
    setIsDownloadModalOpen(false);
    setThrowErrorMessage("");
    setThrowError(false);
  };

  const handleCloseCreateFileModal = () => {
    setOpenCreateFileModal(false);
  };

  const { loader, customerCleared, customerChanged, foundMoreThanMaxRecords } =
    actionTypes;

  const handleCustomerChange = (e, newValue, skipCheck) => {
    if (!checkIfUpdateInProcess() && skipCheck !== VALUE_Y) {
      setAwaitFieldValue(newValue);
      return;
    }
    setSelectedCustomer(newValue);
    if (newValue?.value) {
      syncLogicalVaultAndDrpList(newValue.value);
    } else {
      dispatch({ type: customerCleared });
    }
    setErrorMessage("");
  };

  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    dispatch({ type: loader, payload: false });
    if (error?.response?.data === unauthorized) {
      setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setErrorMessage(t("printTapeLabel.systemError"));
    }
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };
  // Handle error with error code
  const handleError = async (errorCode, replaceText) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: loader, payload: false });
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);

    if (errorMsg) {
      if (replaceText) {
        setErrorMessage(errorMsg?.replace("|", replaceText));
      } else {
        setErrorMessage(errorMsg);
      }
    }
  };

  const getAllLocations = async () => {
    dispatch({ type: loader, payload: true });

    try {
      const reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      const response = await callToCloudFunction(
        reqBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.COMMON.getalllocations}`
      );

      const data = await getDataFromFirestore(response, 1, response.data.docId);

      if (data?.data[0]) {
        setAllLocations((prev) => data?.data[0]);
      } else if (data?.error) {
        setAllLocations([]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setAllLocations([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      dispatch({ type: loader, payload: false });
    }
  };

  useEffect(() => {
    if (allLocations) {
      if (allLocations.length > 0) {
        const selectedLocationDetails = allLocations.filter(
          (location) => location.district_id === districtId
        );
        setLocaleByBranch(
          DAYJS_LOCALES[selectedLocationDetails[0]?.iso_locale_code] ||
            DEFAULT_LOCALE
        );
      }
    }
    // eslint-disable-next-line
  }, [allLocations, districtId]);

  const saveToPersistBeforeNavigate = ({ page, rowsPerPage }) => {
    dispatchStore(
      saveBeforeNavigate({
        state: reducerState,
        customer: selectedCustomer,
        localeByBranch,
        selectedRowIndex: [...selectedRows],
        page,
        rowsPerPage
      })
    );
  };

  const syncLogicalVaultAndDrpList = async (cus) => {
    try {
      dispatch({ type: loader, payload: true });
      let reqBody = {
        main_district_id: districtId,
        customer_id: cus,
        display_all: VALUE_Y,
        display_media_type: VALUE_Y
      };
      let lvData = await getResponseData(
        reqBody,
        getURL(getLogicalVaultsforCustomer),
        1
      );
      lvData = lvData.data;

      if (lvData[0] && lvData[0][0]?.error) {
        handleError(lvData[0][0]?.error);
        return;
      }

      let lvList = lvData[0]
        .sort((a, b) => a.code.localeCompare(b.code))
        .map((lv) => ({
          label: lv.code?.split("(")[0],
          value: lv.logical_vault_id
        }));

      reqBody = {
        main_district_id: districtId,
        customer_id: cus
      };

      let dpRdata = await getResponseData(reqBody, getURL(getDrpCodes), 1);

      dpRdata = dpRdata.data;
      if (dpRdata[0] && dpRdata[0][0]?.error) {
        handleError(dpRdata[0][0]?.error);
        return;
      }

      let drpList = dpRdata[0].map((drp) => ({
        label: drp.drp_code.trim(),
        value: drp.drp_code.trim()
      }));

      dispatch({
        type: customerChanged,
        payload: { lvList, drpList }
      });
    } catch (error) {
      handleCatchError(error);
    }
  };

  const getURL = (slug) => CF_URLS.openMediaProcessing.research[slug];

  const getStatus = async () => {
    if (Object.keys(persistedState?.stateToPersist || {}).length) {
      return;
    }
    try {
      dispatch({ type: loader, payload: true });

      let reqBody = {
        main_district_id: districtId
      };
      let data = await getResponseData(
        reqBody,
        getURL(getExchangeStatusValues),
        1
      );
      data = data.data;

      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }

      let statusOptions = data[0].sort(
        (a, b) => a.media_exchange_status_id - b.media_exchange_status_id
      );

      [statusOptions[1], statusOptions[2]] = [
        statusOptions[2],
        statusOptions[1]
      ];
      statusOptions = statusOptions.map((status) => ({
        label: status.type,
        value: status.media_exchange_status_id
      }));
      dispatch({ type: actionTypes.getStatus, payload: statusOptions });
    } catch (error) {
      handleCatchError(error);
    }
  };

  useEffect(() => {
    if (!selectedCustomer) {
      setSelectedCustomer({ ...customerDefaultOption });
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (districtId) getStatus();
    if (!persistedState?.localeByBranch) getAllLocations();
    //eslint-disable-next-line
  }, [districtId]);

  const checkIfUpdateInProcess = () => {
    if (updateDataIds.length) {
      setConfrimUpdateOpen(confirmUpdate);
      return;
    }
    return true;
  };

  const handleValChange = (payload, type, cb) => {
    if (!checkIfUpdateInProcess()) {
      setAwaitFieldValue({ payload, type, cb });
      return;
    }

    dispatch({ type, payload, cb });
  };

  const triggerEndingSlotMustbeLarge = async () => {
    let { errorMsg } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode62546
    );
    setErrorMessage(
      errorMsg
        .replace("|", t("openMediaResearch.endingSlot"))
        .replace("|", t("openMediaResearch.startingSlot"))
    );
  };

  const isDateValidAndInRange = (dateString) => {
    const date = dayjs(dateString, localeByBranch, true);

    if (!date.isValid()) {
      return false;
    }

    return date.isAfter(minDate) && date.isBefore(maxDate);
  };

  const getCreateButtonStatus = () => {
    if (selectedCustomer?.value) {
      return disableCreateFileButton;
    } else {
      return true;
    }
  };
  const handleFind = async () => {
    dispatch({ type: loader, payload: true });
    setErrorMessage("");
    setSelectedRows([]);

    let reqBody = {
      main_district_id: districtId,
      customer_id: "",
      volser_list: getPaddedMediaList(),
      report_flag: VALUE_N,
      sort_column_code: bitZero,
      sort_direction_bit: bitOne,
      exact_search_flag: VALUE_N,
      return_all_records_flag: VALUE_N,
      found_count: "",
      not_found_count: "",
      ga_type_descr_display_val: "",
      ga_type_descr_report_val: "",
      select_criteria: "",
      district_name: currentBranch?.name,
      report_user_name: getUserName(auth?.user)
    };
    try {
      if (returnDate && !isDateValidAndInRange(returnDate)) {
        await handleError(errorMsgs.errorCode10152);
        return;
      }

      if (showMediaClosedContainers) {
        reqBody = {
          ...reqBody,
          customer_id: selectedCustomer?.value
        };

        //eslint-disable-next-line
        let { data } = await getResponseData(
          reqBody,
          getURL(getClosedContainerContents),
          3
        );

        let list = data[0];
        let metaData = data[1][0];
        let errorData = data[2][0];
        if (errorData.error !== bitZero) {
          handleError(errorData.error);
          return;
        }

        let {
          found_count = 0,
          openmedia_research_max_display = "",
          openmedia_reaseach_max_report = ""
        } = metaData || {};
        if (parseInt(found_count) > parseInt(openmedia_research_max_display)) {
          dispatch({ type: foundMoreThanMaxRecords });
          await handleError(errorMsgs.errorCode26211, number1000);
          return;
        }

        let listToTable = list
          .map((item) => ({
            media_number: item.media_number,
            container_number: item.container_number
          }))
          .sort((a, b) => a.media_number.localeCompare(b.media_number))
          .sort((a, b) => a.container_number.localeCompare(b.container_number));

        dispatch({
          type: actionTypes.tablePopulateClosedContainers,
          payload: {
            gridData: list,
            tableData: listToTable,
            resultCount: {
              found:
                parseInt(found_count) > parseInt(openmedia_research_max_display)
                  ? `${openmedia_research_max_display} >`
                  : found_count,
              not_found: undefined,
              actualCount: found_count,
              maxDisplayCount: openmedia_research_max_display,
              maxReportCount: openmedia_reaseach_max_report
            }
          }
        });

        dispatch({ type: loader, payload: false });
      } else if (mediaList.length) {
        let reqBody = {
          main_district_id: districtId,
          customer_id: selectedCustomer?.value,
          volser_list: getPaddedMediaList(),
          found: bitZero,
          not_found: bitZero,
          report_flag: researchReportCountLimitFlag
        };

        let { data } = await getResponseData(
          reqBody,
          getURL(searchCustomerandVolserList),
          3
        );
        let errorData = data[2][0];
        if (errorData.error !== bitZero) {
          handleError(errorData.error);
          return;
        }

        let gridData = data[0];
        let resultCount = data[1][0];
        dispatch({
          type: populateTable,
          payload: {
            gridData,
            lvModalOptions: [],
            mediaTypeOptions: [],
            localeByBranch,
            isCustomerSelected: !!selectedCustomer?.value,
            resultCount
          }
        });
      } else {
        let reqBody = {
          main_district_id: districtId,
          customer_id: selectedCustomer?.value,
          show_om_in_discrepancy_only: showDiscrepancy ? bitOne : bitZero,
          research_report_count_limit_flag: researchReportCountLimitFlag,
          logical_vault_id: selectedLv?.value || "",
          drp_code: selectedDRP?.value || "",
          media_exchange_status_id: selectedSatus?.value || "",
          return_date: returnDate
            ? dayjs(returnDate).format(ymdDateFormat)
            : "",
          open_media_volser: mediaNumber,
          sort_column_code: bitOne,
          sort_direction_bit: bitOne,
          total_number_matching_numbers: totalNumberMatchingNumbers,
          show_dup_only_flag_bit: showDuplicates ? bitOne : bitZero,
          start_slot_number: startingSlot,
          descr: description,
          start_volser_number: mediaStartingNumber,
          end_slot_number: endingSlot
        };

        let data = await getResponseData(
          reqBody,
          getURL(getOpenMediaAndAatrixForCustomer),
          5
        );
        data = data.data;
        let gridData = data[0];
        let maxCount = data[3];
        let { OpenMediaResearchMaxDisplay, OpenMediaResearchMaxReport } =
          maxCount[0];
        let count = data[4];
        let { total_number_matching_numbers } = count[0];

        let resultCount = {
          found:
            parseInt(total_number_matching_numbers) >
            parseInt(OpenMediaResearchMaxDisplay)
              ? `${OpenMediaResearchMaxDisplay} >`
              : total_number_matching_numbers,
          not_found: undefined,
          actualCount: total_number_matching_numbers,
          maxDisplayCount: OpenMediaResearchMaxDisplay,
          maxReportCount: OpenMediaResearchMaxReport
        };

        if (
          parseInt(total_number_matching_numbers) >
          parseInt(OpenMediaResearchMaxDisplay)
        ) {
          dispatch({ type: foundMoreThanMaxRecords, payload: resultCount });
          await handleError(errorMsgs.errorCode26211, number1000);
          return;
        }
        dispatch({
          type: populateTable,
          payload: {
            gridData,
            lvModalOptions: data[2],
            mediaTypeOptions: data[1],
            localeByBranch,
            isCustomerSelected: !!selectedCustomer?.value,
            resultCount
          }
        });
      }
    } catch (error) {
      handleCatchError(error);
    }
  };

  const getPaddedMediaList = () => {
    return mediaList
      .map((item) => {
        let spacesToAdd = paddingRightLength - item.number.length;
        return item.number + " ".repeat(spacesToAdd);
      })
      .join("");
  };

  const confirmBeforePrint = async () => {
    if (mediaList.length > 0 || showMediaClosedContainers) {
      handleDownloadOpen();
    } else {
      if (
        parseInt(resultCount.actualCount) >
        parseInt(resultCount.maxDisplayCount)
      ) {
        let msg = await getMessageFromCode(errorMsgs.errorCode25368);
        let message = msg[0]?.descr;
        setConfirmMessage(
          message
            .replace("|", resultCount.maxDisplayCount)
            .replace("|", resultCount.maxReportCount)
        );
        setConfrimUpdateOpen(confirmPrint);
      } else {
        handleDownloadOpen();
      }
    }
  };

  const getConfirmProps = () => {
    switch (confirmUpdateOpen) {
      case confirmUpdate:
        return {
          modalPopupOpen: !!confirmUpdateOpen,
          handleYes: onClickEditSave,
          handleNo: handleDeniedUpdate,
          handleCancel: () => setConfrimUpdateOpen(""),
          showCancel: true,
          showNo: true
        };

      case confirmPrint: {
        return {
          modalPopupOpen: !!confirmUpdateOpen,
          handleYes: () => {
            setConfrimUpdateOpen("");
            handleDownloadOpen();
          },
          handleCancel: () => setConfrimUpdateOpen(""),
          showCancel: true,
          showNo: false,
          showOk: true,
          message: confirmMessage
        };
      }
      default:
        return {
          modalPopupOpen: false
        };
    }
  };

  const onClickPrintButtonClick = async () => {
    dispatch({ type: loader, payload: true });
    setThrowError(false);
    setThrowErrorMessage("");
    let commonPayload = {
      main_district_id: districtId,
      customer_id: selectedCustomer?.value,

      user_name: getUserName(auth?.user),
      report_format: selectedValue?.type?.toUpperCase()
    };

    if (showMediaClosedContainers) {
      //eslint-disable-next-line
      let reqBody = JSON.stringify({
        ...commonPayload,
        volser_list: getPaddedMediaList(),
        report_flag: VALUE_N,
        sort_column_code: bitZero,
        sort_direction_bit: bitOne,
        exact_search_flag: VALUE_N,
        return_all_records_flag: VALUE_N
      });

      let URL = CF_URLS.printReports.openMediaProcessing.closeContainerContent;
      downloadReport(reqBody, URL);
    } else if (mediaList.length > 0) {
      let reqBody = JSON.stringify({
        ...commonPayload,
        district_id: districtId,
        volser_list: getPaddedMediaList(),
        found: "",
        not_found: "",
        report_flag: researchReportCountLimitFlagR,
        district_name: currentBranch?.name?.replace("_", " ")
      });
      let URL = CF_URLS.printReports.openMediaProcessing.openMediaResearch;
      downloadReport(reqBody, URL);
    } else {
      let reqBody = JSON.stringify({
        ...commonPayload,
        show_om_in_discrepancy_only: showDiscrepancy ? bitOne : bitZero,
        research_report_count_limit_flag: researchReportCountLimitFlagR,
        logical_vault_id: selectedLv?.value || "",
        drp_code: selectedDRP?.value || "",
        media_exchange_status_id: selectedSatus?.value || "",
        return_date: returnDate ? dayjs(returnDate).format(ymdDateFormat) : "",
        open_media_volser: mediaNumber,
        sort_column_code: sortColumnCode,
        sort_direction_bit: bitOne,
        total_number_matching_numbers: totalNumberMatchingNumbers,
        show_dup_only_flag_bit: showDuplicates ? bitOne : bitZero,
        start_slot_number: startingSlot,
        descr: description,
        start_volser_number: mediaStartingNumber,
        end_slot_number: endingSlot,
        district_name: currentBranch?.name?.replace("_", " ")
      });

      let URL = CF_URLS.printReports.openMediaProcessing.omResearchReport;
      downloadReport(reqBody, URL);
    }
  };

  const downloadReport = async (reqBody, URL) => {
    try {
      let reportResponse = await printReport(reqBody, URL);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse?.error);
        setThrowError(true);
        return;
      }
      setIsDownloadModalOpen(false);
    } catch (error) {
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    } finally {
      dispatch({ type: loader, payload: false });
    }
  };
  const onCreateFileModalOpen = async () => {
    if (
      selectedSatus.value ||
      returnDate ||
      mediaList.length ||
      mediaNumber ||
      startingSlot ||
      endingSlot ||
      description ||
      showDiscrepancy ||
      showDuplicates ||
      selectedDRP?.value
    ) {
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode25284
      );
      setConfirmMessage(errorMsg);
      setShowConfirmModal(confirmCreateFile);
    } else {
      setOpenCreateFileModal(true);
    }
  };

  const {
    changeStatus,
    changeLV,
    changeDRP,
    changeDiscrepancy,
    changeDuplicates,
    changeMediaClosedContainers,
    chnageReturnDate,
    changeMediaStaringNumber,
    changeStartingSlot,
    changeEndingSlot,
    changeDescription,
    populateTable,
    changeMediaNumber,
    dataUpdated,
    blurStartingSlot,
    blurEndingSlot
  } = actionTypes;

  const isMediaListExists = mediaList?.length > 0;

  const onClickUpdate = async (row, data, cb) => {
    const newStatus = data.newStatus || { label: "", value: "" };
    const indefiniteRetension = data.indefiniteRetension;
    const selectedMediaType = data.selectedMediaType || {
      label: "",
      value: ""
    };
    const selectedLv = data.selectedLv || { label: "", value: "" };

    setUpdateDataIds((prev) => [...prev, row.open_media_id]);
    const index = tableData.findIndex(
      (item) => item.open_media_id === row.open_media_id
    );

    const omListindex = omList.findIndex(
      (item) => item.open_media_id === row.open_media_id
    );

    let returnDate = await formatDateByLocale(
      data?.returnDate,
      localeByBranch || DEFAULT_LOCALE,
      false
    );

    if (index !== -1) {
      tableData[index].new_status_code = newStatus?.label;
      tableData[index].new_status_id = newStatus?.value;
      tableData[index].active_in_mdr_bit = indefiniteRetension ? YES : NO;
      tableData[index].media_type_type_descr = selectedMediaType?.label;
      tableData[index].logical_vault_code = selectedLv?.label;
      tableData[index].media_type_id = selectedMediaType?.value;
      tableData[index].logical_vault_id = selectedLv?.value;
      tableData[index].return_date_changed =
        tableData[index].return_date !== returnDate;
      tableData[index].return_date = returnDate;
    }

    let omReutunDate = data?.returnDate || "";
    if (omListindex !== -1) {
      omList[omListindex].new_status_code = newStatus?.label;
      omList[omListindex].new_status_id = newStatus?.value;
      omList[omListindex].active_in_mdr_bit = indefiniteRetension ? YES : NO;
      omList[omListindex].media_type_type_descr = selectedMediaType?.label;
      omList[omListindex].logical_vault_code = selectedLv?.label;
      omList[omListindex].media_type_id = selectedMediaType?.value;
      omList[omListindex].logical_vault_id = selectedLv?.value;
      omList[omListindex].return_date_changed =
        omList[omListindex].return_date !== omReutunDate;
      omList[omListindex].return_date = omReutunDate;
    }

    dispatch({
      type: actionTypes.updateTableData,
      payload: { tableData, omList }
    });
    dispatch({ type: dataUpdated });
    cb();
    setIsUpdateModalOpen(false);
  };

  const onClickEditSave = async () => {
    dispatch({ type: loader, payload: true });
    setErrorMessage("");
    setConfrimUpdateOpen("");
    let updateQueue = omList
      .filter((item) => updateDataIds.includes(item.open_media_id))
      .filter(
        (item) =>
          !item.return_date &&
          item.active_in_mdr_bit === NO &&
          item.new_status_code !== delivered
      );
    if (updateQueue.length) {
      handleError(errorMsgs.errorCode20392, updateQueue[0].volser);
      return;
    }

    try {
      let reqBody = {
        main_district_id: districtId,
        customer_id_list: selectedCustomer?.value
      };

      let data = await getResponseData(
        reqBody,
        getURL(getlogicalvaultcodesforaudits),
        1
      );
      data = data.data;
      if (data[0] && data[0][0]?.error) {
        if (data[0][0].error === errorMsgs.errorCode25382) {
          setShowConfirmModal(confirmEdit);
          let { errorMsg } = await getTheFirestoreErrorMessage(
            errorMsgs.errorCode25382
          );
          setErrorMessage(
            errorMsg.replace("|", t("openMediaResearch.logicalVaultinSudit"))
          );
          return;
        }
        handleError(data[0][0]?.error);
        return;
      }
      setUpdateDataIds([]);
      updateMediaList();
    } catch (error) {
      handleCatchError(error);
    }
  };

  useEffect(() => {
    if (updateDataIds.length) {
      dispatch({ type: actionTypes.updateInProcess });
    }
  }, [updateDataIds]);

  const handleDeniedUpdate = () => {
    if (Object.keys(awaitFieldValue).length) {
      if (awaitFieldValue.label) {
        handleCustomerChange(null, awaitFieldValue, VALUE_Y);
      } else {
        dispatch({ ...awaitFieldValue });
      }
      dispatch({ type: loader, payload: false });
    } else if (awaitFieldValue === "") {
      handleClear(null, VALUE_Y);
      dispatch({ type: loader, payload: false });
    } else {
      handleFind();
    }
    setAwaitFieldValue({});
    setUpdateDataIds([]);

    setConfrimUpdateOpen("");
  };
  const updateMediaList = async () => {
    let updateQueue = omList.filter((item) =>
      updateDataIds.includes(item.open_media_id)
    );
    try {
      let promises = updateQueue.map(async (item) => {
        let reqBody = {
          main_district_id: districtId,
          customer_id: item.customer_id,
          open_media_id: item.open_media_id,
          new_logical_vault_id: item.logical_vault_id,
          new_media_type_id: item.media_type_id,
          return_date_change_flag_bit: item.return_date_changed
            ? bitOne
            : bitZero,
          new_return_date: dayjs(item.return_date).isValid()
            ? dayjs(item.return_date).format(mdyDateFormat)
            : VALUE_EMPTY_STRING,
          new_request_id: item.request_type_id,
          new_status_id: item.new_status_id,
          open_media_ts: item.timestamp,
          last_mod_user: auth?.user?.login
        };

        let resData = await getResponseData(
          reqBody,
          getURL(updateOpenMediaAttributesAndAssociations),
          2
        );

        resData = resData.data;
        if (resData[0] && resData[0][0]?.error !== bitZero) {
          handleError(resData[0][0]?.error);
          throw new Error();
        }
      });

      await Promise.all(promises);
      handleDeniedUpdate();
      setUpdateDataIds([]);
      setSelectedRows([]);
    } catch (error) {
      handleCatchError(error);
    }
  };

  let fieldsData = {
    selectedSatus,
    selectedLv,
    selectedDRP,
    mediaNumber,
    returnDate,
    showDuplicates,
    startingSlot,
    description,
    mediaStartingNumber,
    endingSlot
  };

  const getConfirmModalContent = () => {
    switch (showConfirmModal) {
      case confirmEdit:
        return (
          <>
            <Button
              variant="contained"
              onClick={() => updateMediaList()}
              sx={openMediaResearchStyles.compareButton}
            >
              {t("common.yes")}
            </Button>
            <Button variant="outlined" onClick={() => setShowConfirmModal("")}>
              {t("common.no")}
            </Button>
          </>
        );
      case confirmCreateFile:
        return (
          <>
            <Button
              variant="contained"
              onClick={() => {
                setShowConfirmModal("");
                setOpenCreateFileModal(true);
              }}
              sx={openMediaResearchStyles.compareButton}
            >
              {t("common.ok")}
            </Button>
            <Button variant="outlined" onClick={() => setShowConfirmModal("")}>
              {t("common.cancel")}
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  const getBody = () => {
    switch (showConfirmModal) {
      case confirmEdit:
        return <Typography>{errorMessage}</Typography>;

      case confirmCreateFile:
        return <Typography>{confirmMessage}</Typography>;

      default:
        return null;
    }
  };

  return (
    <>
      <form>
        {loading && <FullCircularLoader />}
        {/*Success message popup*/}
        {messageSuccess && (
          <SuccessPopup message={messageSuccess} close={setMessageSuccess} />
        )}
        {errorMessage && (
          <Box mb={spacing.gap}>
            <Alert
              severity="error"
              sx={ResearchTableStyles.errorAlert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setErrorMessage(false)}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Box>
        )}

        <Stack
          direction="column"
          gap={spacing.verticalMargin20}
          mb={spacing.verticalMargin32}
        >
          <Grid container spacing={spacing.horizontalMargin20}>
            {/* ----------- Customers Field ----------- */}

            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <CustomersField
                  options={[
                    {
                      label: t("common.all"),
                      value: ""
                    },
                    ...customers
                  ]}
                  value={selectedCustomer || null}
                  handleOnChange={handleCustomerChange}
                />
              </FormControl>
            </Grid>

            {/* ----------- Status Field ----------- */}

            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={null}
                options={[
                  {
                    label: t("common.all"),
                    value: ""
                  },
                  ...statusList
                ]}
                getOptionLabel={(option) => option.label}
                onChange={(e, val) => handleValChange(val, changeStatus)}
                value={selectedSatus}
                disabled={showMediaClosedContainers || isMediaListExists}
                id="status"
                name="status"
                size="medium"
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("openMediaResearch.status")}
                  />
                )}
              />
            </Grid>

            {/* ----------- Logical Vault ----------- */}

            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                options={[
                  {
                    label: t("common.all"),
                    value: ""
                  },
                  ...lvList
                ]}
                getOptionLabel={(option) => option.label}
                onChange={(e, val) => handleValChange(val, changeLV)}
                value={selectedLv}
                disabled={
                  disabledLV || showMediaClosedContainers || isMediaListExists
                }
                id="logical-vault"
                name="logical-vault"
                size="medium"
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("openMediaResearch.logicalVault")}
                  />
                )}
              />
            </Grid>

            {/* ----------- DRP Code ----------- */}

            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                options={[
                  {
                    label: t("common.all"),
                    value: ""
                  },
                  ...drpList
                ]}
                disabled={
                  disabledDrp || showMediaClosedContainers || isMediaListExists
                }
                getOptionLabel={(option) => option.label}
                onChange={(e, val) => handleValChange(val, changeDRP)}
                value={selectedDRP}
                id="drp-code"
                name="drp-code"
                size="medium"
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("openMediaResearch.drpCode")}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={spacing.horizontalMargin20}>
            {/* ----------- Date Picker ----------- */}

            <Grid item xs={3}>
              <DatePickerField
                label={t("openMediaResearch.returnDate")}
                value={returnDate}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => handleValChange(date, chnageReturnDate)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                disabled={showMediaClosedContainers || isMediaListExists}
                locale={localeByBranch}
              />
            </Grid>

            {/* ----------- Media Number ----------- */}
            <Grid item xs={3}>
              <TextField
                fullWidth
                label={t("openMediaResearch.mediaNumber")}
                value={
                  mediaNumber ||
                  mediaList.reduce((a, n) => a + `${n.number}\t`, "")
                }
                onChange={(e) =>
                  !mediaList.length
                    ? handleValChange(e.target.value, changeMediaNumber)
                    : {}
                }
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={handleOpenModal}
                      sx={ResearchTableStyles.mediaNumberAdd}
                    >
                      <AddCircleOutlineIcon
                        sx={ResearchTableStyles.mediaNumberAddIcon}
                      />
                    </IconButton>
                  )
                }}
              />
            </Grid>

            {/* ----------- Starting Media # ----------- */}

            <Grid item xs={3}>
              <TextField
                fullWidth
                label={t("openMediaResearch.startingMediaNumber")}
                value={mediaStartingNumber}
                disabled={showMediaClosedContainers || isMediaListExists}
                onChange={(e) =>
                  handleValChange(e.target.value, changeMediaStaringNumber)
                }
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            {/* ----------- Starting Slot ----------- */}

            <Grid item xs={1.5}>
              <TextField
                fullWidth
                label={t("openMediaResearch.startingSlot")}
                value={startingSlot}
                disabled={showMediaClosedContainers || isMediaListExists}
                onChange={(e) => {
                  setErrorMessage("");
                  handleValChange(e.target.value, changeStartingSlot);
                }}
                onBlur={(e) => {
                  setErrorMessage("");
                  handleValChange(e.target.value, blurStartingSlot);
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 6);
                }}
              />
            </Grid>

            {/* ----------- Ending Slot ----------- */}

            <Grid item xs={1.5}>
              <TextField
                fullWidth
                label={t("openMediaResearch.endingSlot")}
                value={endingSlot}
                disabled={showMediaClosedContainers || isMediaListExists}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 6);
                }}
                onChange={(e) => {
                  setErrorMessage("");
                  handleValChange(e.target.value, changeEndingSlot);
                }}
                onBlur={(e) => {
                  setErrorMessage("");
                  handleValChange(
                    e.target.value,
                    blurEndingSlot,
                    triggerEndingSlotMustbeLarge
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={spacing.horizontalMargin20}>
            {/* ----------- Description ----------- */}

            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label={t("common.description")}
                value={description}
                disabled={showMediaClosedContainers || isMediaListExists}
                onChange={(e) =>
                  handleValChange(e.target.value, changeDescription)
                }
                inputProps={{ maxLength: 1024 }}
              />
            </Grid>
            {/* ----------- Show Media in Discrepancy Only ----------- */}

            <Grid item container xs={6} spacing={spacing.horizontalMargin20}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDiscrepancy}
                      disabled={
                        disabledDiscrepancy ||
                        showMediaClosedContainers ||
                        isMediaListExists
                      }
                      onChange={(e) =>
                        handleValChange(e.target.checked, changeDiscrepancy)
                      }
                    />
                  }
                  label={t("openMediaResearch.showMediaDiscrepancy")}
                />
              </Grid>
              {/* ----------- Show Duplicates Only ----------- */}

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDuplicates}
                      disabled={
                        disabledDuplicates ||
                        showMediaClosedContainers ||
                        isMediaListExists
                      }
                      onChange={(e) =>
                        handleValChange(e.target.checked, changeDuplicates)
                      }
                    />
                  }
                  label={t("openMediaResearch.showDuplicatesOnly")}
                />
              </Grid>

              {/* ----------- Show Media in Closed Containers Only ----------- */}

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showMediaClosedContainers}
                      disabled={disabledMediaClosedContainers}
                      onChange={(e) =>
                        handleValChange(
                          e.target.checked,
                          changeMediaClosedContainers
                        )
                      }
                    />
                  }
                  label={t("openMediaResearch.showMediaClosedContainersOnly")}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* ----------- Clear and Find Buttons ----------- */}

          <Grid item container xs={6} spacing={spacing.horizontalMargin20}>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFind}
                disabled={disablefindButton}
              >
                Find
              </Button>
            </Grid>
          </Grid>
          <Stack>
            {modalOpen && (
              <MediaListModal
                open={modalOpen}
                onClose={handleCloseModal}
                mediaList={mediaList}
                dispatch={dispatch}
              />
            )}

            <ResearchDataTable
              disableSaveButton={disableSaveButton}
              disablePrintButton={disablePrintButton}
              disableDetailButton={disableDetailButton}
              disableCreateFileButton={getCreateButtonStatus()}
              searchResults={tableData}
              setIsUpdateModalOpen={setIsUpdateModalOpen}
              confirmBeforePrint={confirmBeforePrint}
              selectedRows={selectedRows}
              handleRowSelection={handleRowSelection}
              editIndex={editIndex}
              setEditIndex={setEditIndex}
              dispatch={dispatch}
              onCreateFileModalOpen={onCreateFileModalOpen}
              showMediaClosedContainers={showMediaClosedContainers}
              isUserHasUpdatePermission={isUserHasUpdatePermission}
              isUserHasDetailViewPermission={isUserHasDetailViewPermission}
              isUserHasCreateeFileermission={isUserHasCreateeFileermission}
              onClickEditSave={onClickEditSave}
              localeByBranch={localeByBranch}
              searchMade={searchMade}
              saveToPersistBeforeNavigate={saveToPersistBeforeNavigate}
              persistedState={persistedState}
              resultCount={resultCount}
              customers={customers}
            />
          </Stack>
        </Stack>
      </form>
      <CommonModal
        open={!!showConfirmModal}
        buttons={getConfirmModalContent()}
        title={t("common.confirm")}
        body={getBody()}
      />
      <UpdateModal
        isModalOpen={isUpdateModalOpen}
        handleModalClose={handleUpdateModalClose}
        selectedRowData={omList?.find(
          (item) =>
            item?.volser === editIndex?.volser &&
            item?.customer_number === editIndex?.customer_number &&
            isUpdateModalOpen
        )}
        statusList={statusList}
        mediaTypeOptions={mediaTypeOptions}
        lvModalOptions={lvModalOptions}
        isUserHasUpdatePermission={isUserHasUpdatePermission}
        onClickUpdate={onClickUpdate}
        localeByBranch={localeByBranch}
        hasMultipleMediaNumbers={!!mediaList.length}
        updateDataIds={updateDataIds}
      />
      <CreateFileModal
        open={openCreateFileModal}
        onClose={handleCloseCreateFileModal}
        excludeTransitAndDeliverDestroyed={excludeTransitAndDeliverDestroyed}
        includeColumnHeaders={includeColumnHeaders}
        setIncludeColumnHeaders={setIncludeColumnHeaders}
        setExcludeTransitAndDeliverDestroyed={
          setExcludeTransitAndDeliverDestroyed
        }
        selectedCustomer={selectedCustomer}
        selectedSatus={selectedSatus}
        dispatch={dispatch}
        setMessageSuccess={setMessageSuccess}
        handleError={handleError}
        fieldsData={fieldsData}
        localeByBranch={localeByBranch}
        resultCount={resultCount}
      />
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={isDownLoadModalOpen}
        downloadOptions={downloadReportOptions}
        onCancleClick={handleDownloadCancel}
        onDownloadClick={onClickPrintButtonClick}
        setSelectedValue={setSelectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
      {confirmUpdateOpen && <ConfirmPopup {...getConfirmProps()} />}
    </>
  );
}

export default OpenMediaResearch;
