export const numberZero = 0;
export const maxNumber = {
  customerNumber: 6,
  mediaType: 2,
  mediaNumber: 30,
  mediaNumberIncrementer: 10,
  quantity: 3,
  mediaNumberSuffix: 25,
  mediaNumberPrefix: 25
};

export const mmToDotsConversionConstant = 8;

export const minXandYHome = 0;

export const darknessAdjustmentConstant = {
  positive: 15,
  negative: -15
};

export const topAdjustmentConstant = {
  positive: 64,
  negative: -64
};

export const constantM = "M";

export const label_type_code_NA = "M_NA";
export const label_type_code_IME = "M_IME";

export const typeSingleLabel = "single_label";
export const typeRange = "range";
export const typeFile = "file";

export const specialCharMap = {
  "!": "(A",
  '"': "(B",
  "#": "(C",
  $: "(D",
  "%": "(E",
  "&": "(F",
  "'": "(G",
  "(": "(H",
  ")": "(I",
  "*": "(J",
  ":": "(Z",
  ";": "'F",
  "<": "'G",
  "=": "'H",
  ">": "'I",
  "?": "'J",
  "@": "'V",
  "[": "'K",
  "\\": "'L'L",
  "]": "'M",
  "^": "'N",
  _: "'O",
  "{": "'P",
  "|": "'Q",
  "}": "'R",
  "~": "'S",
  "`": "'W"
};
