import { createSlice } from "@reduxjs/toolkit";
import { authSection, VALUE_EMPTY_STRING } from "utils/constants";

const initialAuthValues = {
  branch: VALUE_EMPTY_STRING,
  customer: VALUE_EMPTY_STRING,
  personnel: VALUE_EMPTY_STRING,
  authNumber: VALUE_EMPTY_STRING,
  challengeQuestion: VALUE_EMPTY_STRING,
  challengeResponse: VALUE_EMPTY_STRING,
  customerName: VALUE_EMPTY_STRING,
  bypassMessage: VALUE_EMPTY_STRING,
  personnelAuthValue: authSection?.personnelAuth
};
const intailBranchValues = {
  branch: VALUE_EMPTY_STRING,
  customer: VALUE_EMPTY_STRING,
  personnel: VALUE_EMPTY_STRING,
  prefenceFilteredTableData: [],
  preferenceTableResultSetOne: {},
  setTablePayloadData: [],
  authenticatateBranch: [],
  findButtonDisable: true,
  isDisablePrint: true,
  loading: false,
  tableRowData: {}
};
const intialPreferenceTabData = {
  selectedCustomer: VALUE_EMPTY_STRING,
  selectedBranch: VALUE_EMPTY_STRING,
  prefenceFilteredTableData: [],
  findButtonDisable: true
};
const customerPreferencesSlice = createSlice({
  name: "customerPreferences",
  initialState: {
    authFormValues: initialAuthValues,
    isLoading: false,
    throwError: false,
    throwErrorMsg: VALUE_EMPTY_STRING,
    isAuthenticated: false,
    throwWarning: false,
    throwWarningMsg: VALUE_EMPTY_STRING,
    isAuthError: false,
    showAuthSection: false,
    emailNotificationHistory: [],
    dontCallHistory: [],
    preferenceTabData: intialPreferenceTabData,
    defaultAuthBranchValues: intailBranchValues,
    isRefreshRequired: false,
    vendorResponseList: []
  },
  reducers: {
    updateAuthFormValues(state, action) {
      state.authFormValues = { ...state.authFormValues, ...action.payload };
    },

    resetAuthForm(state) {
      state.authFormValues = initialAuthValues;
      state.searchResults = [];
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },

    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.throwError = true;
      state.throwErrorMsg = action.payload;
    },
    setWarning(state, action) {
      state.throwWarning = true;
      state.throwWarningMsg = action.payload;
    },
    clearError(state) {
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },
    clearWarning(state, action) {
      state.throwWarning = false;
      state.throwWarningMsg = VALUE_EMPTY_STRING;
    },
    updatePreferencesAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },
    setAuthCustomers(state, action) {
      state.authCustomers = action.payload;
    },
    setShowAuthSection(state, action) {
      state.showAuthSection = action.payload;
    },
    setEmailNotificationHistory(state, action) {
      state.emailNotificationHistory = action.payload;
    },
    setDontCallHistory(state, action) {
      state.dontCallHistory = action.payload;
    },
    setDefaultBranchValues(state, action) {
      state.defaultAuthBranchValues = {
        ...state,
        ...state.defaultAuthBranchValues,
        ...action.payload
      };
    },
    setPreferenceTabData(state, action) {
      state.preferenceTabData = {
        ...state.preferenceTabData,
        ...action.payload
      };
    },
    setIsRefreshRequired(state, action) {
      state.isRefreshRequired = action.payload;
    },
    setAuthTypeChange(state, action) {
      state.authFormValues.personnelAuthValue = action.payload;
    },
    setBypassMesage(state, action) {
      state.authFormValues.bypassMessage = action.payload;
    },
    setVendorResponseList(state, action) {
      state.vendorResponseList = action.payload;
    }
  }
});

export const {
  setLoading,
  setError,
  clearError,
  updateAuthFormValues,
  resetAuthForm,
  updatePreferencesAuthentication,
  setWarning,
  clearWarning,
  setAuthCustomers,
  setShowAuthSection,
  setEmailNotificationHistory,
  setDontCallHistory,
  setDefaultBranchValues,
  setPreferenceTabData,
  setIsRefreshRequired,
  setAuthTypeChange,
  setVendorResponseList,
  setBypassMesage
} = customerPreferencesSlice.actions;

export default customerPreferencesSlice.reducer;
