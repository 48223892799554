import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ErrorIcon from "assets/images/warning-2.svg";
import {
  DatePickerField,
  FullCircularLoader,
  SelectField
} from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DataTable from "components/core/data-table/DataTable";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  CORRECTIVE_ACTION_REQUESTS_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  all,
  allUnresolved,
  dateFormat,
  datesForCorrectiveSearch,
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  downloadReportOptions,
  ERROR_TYPES,
  errorMsgs,
  N_CHECKER,
  numberConstants,
  Y_CHECKER
} from "utils/constants";
import {
  containersConst,
  maximumRowsCount,
  openMediaConst
} from "utils/constants/corrective-action-request";
import {
  findErrorMessage,
  formatDateByLocale,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import { ReactComponent as ArrowDown } from "../../assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/RemoveRedEyeFilled.svg";
import styles from "./CorrectiveActionRequestSearch.Style";
import PrintModalPopup from "./PrintModalPopup";
import { filterSchema } from "./schema";
import useFetchData from "./useFetchData";

const CorrectiveActionRequestSearchComponent = () => {
  const {
    typesData,
    failurePoints,
    rootCauseData,
    statusData,
    loading,
    setLoading,
    rootCauseEmployee,
    fetchTypes,
    fetchFailurePoints,
    fetchRootCause,
    fetchRootCauseEmployee,
    fetchStatus
  } = useFetchData();
  const { t } = useTranslation();
  const { carTypeId, carStatusId, maxRowCount } = useParams();
  const [carId, setCarId] = useState(null);
  const [carSummaryData, setCarSummaryData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [isPrintButtonDisabled, setIsPrintButtonDisabled] = useState(true);
  const carIdRef = useRef(null);
  const [isFindButtonDisabled, setIsFindButtonDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isNotBilledDisabled, setIsNotBilledDisabled] = useState(true);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [radioButonEvent, setRadioButonEvent] = useState(true);
  const [tableKey, setTableKey] = useState(0);
  const [messageOptions, setMessageOptions] = useState({
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    textLabels: {
      body: {
        noMatch: t("correctiveActionRequestSearch.viewReportMessage")
      }
    }
  });
  const location = useLocation();
  const ignoreCarsWithUnresolvedDiscrepancies =
    location?.state?.ignore_cars_with_unresolved_discrepancies;
  const showCarsReadyForMgmtReviewOnlyFlag =
    location?.state?.show_cars_ready_for_mgmt_review_only_flag;
  const statusIdCar = location?.state?.car_status_id;
  const navigate = useNavigate();
  const { currentBranch, localeByBranch } = useSelector(selectAuth);
  const selectedBranchId = currentBranch?.district_id;
  const minDate = dayjs(datesForCorrectiveSearch.minDate);
  const maxDate = dayjs(datesForCorrectiveSearch.maxDate);
  const [mediaNumber, setMediaNumber] = useState(null);
  const [billableFlag, setBillableFlag] = useState(null);
  const [
    rootCauseEmployeeDropdownDisabled,
    setRootCauseEmployeeDropdownDisabled
  ] = useState(true);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(
    t("correctiveActionRequestSearch.openMedia")
  );
  const [isDefaultSearch, setIsDefaultSearch] = useState(true);
  const [showLongProcessMessageModal, setShowLongProcessMessageModal] =
    useState(false);
  const [printOption, setPrintOption] = useState(
    t("correctiveActionRequestSearch.summaryOption")
  );
  const [showReportDownloadPopup, setShowReportDownloadPopup] = useState(false);
  const { auth } = useSelector(selectAuth);
  const pdfFormat = downloadReportOptions[0].type.toLocaleUpperCase();
  const customerId = location?.state?.record?.customer_id;
  const userName = getUserName(auth?.user);

  useEffect(() => {
    const fetchData = () => {
      if (carTypeId && carStatusId && maxRowCount) {
        fetchCarSummaryData();
      }
      if (ignoreCarsWithUnresolvedDiscrepancies) {
        fetchCarSummaryData();
        SearchFilterForm.setFieldValue(
          "status",
          `${statusIdCar}` === numberConstants.zero
            ? allUnresolved
            : `${statusIdCar}`
        );
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [
    carTypeId,
    carStatusId,
    maxRowCount,
    statusIdCar,
    ignoreCarsWithUnresolvedDiscrepancies,
    showCarsReadyForMgmtReviewOnlyFlag
  ]);

  useEffect(() => {
    const fetchData = () => {
      if (customerId) {
        fetchCarSummaryData(customerId);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading, isLoadingCustomers]);

  useEffect(() => {
    if (carIdRef.current) {
      carIdRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setIsPrintButtonDisabled(carSummaryData.length === 0);
  }, [carSummaryData]);

  const handleSystemError = () => {
    setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
  };
  const fetchCarSummaryData = async (customer_id) => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.district_id,
        car_id: carId,
        car_type_id: carTypeId
          ? carTypeId
          : SearchFilterForm.values.type === all
            ? null
            : SearchFilterForm.values.type,
        customer_id:
          SearchFilterForm.values.customer?.value === t("common.all")
            ? null
            : SearchFilterForm.values.customer?.value || customer_id,
        from_date:
          SearchFilterForm.values.fromDate &&
          SearchFilterForm.values.fromDate.isValid()
            ? SearchFilterForm.values.fromDate.format(
                dateFormat.universalFormat
              )
            : SearchFilterForm.values.toDate &&
                !SearchFilterForm.values.fromDate
              ? SearchFilterForm.values.toDate.format(
                  dateFormat.universalFormat
                )
              : null,
        to_date:
          SearchFilterForm.values.toDate &&
          SearchFilterForm.values.toDate.isValid()
            ? SearchFilterForm.values.toDate.format(dateFormat.universalFormat)
            : SearchFilterForm.values.fromDate &&
                SearchFilterForm.values.fromDate.isValid()
              ? SearchFilterForm.values.fromDate.format(
                  dateFormat.universalFormat
                )
              : null,
        failure_point_id:
          SearchFilterForm.values.failurePoints === all
            ? null
            : SearchFilterForm.values.failurePoints,
        root_cause_id:
          SearchFilterForm.values.rootCause === all
            ? null
            : SearchFilterForm.values.rootCause,
        car_status_id: carStatusId
          ? carStatusId
          : statusIdCar
            ? `${statusIdCar}`
            : SearchFilterForm.values.status === allUnresolved
              ? numberConstants.zero
              : SearchFilterForm.values.status === all
                ? null
                : SearchFilterForm.values.status,
        days_at_status: null,
        max_row_count: maxRowCount || maximumRowsCount,
        billable_flag: billableFlag,
        media_number: mediaNumber,
        om_container_flag: isDefaultSearch
          ? null
          : selectedRadio === t("correctiveActionRequestSearch.openMedia")
            ? openMediaConst
            : containersConst,
        sort_order: null,
        asc_desc: null,
        ignore_cars_with_unresolved_discrepancies:
          ignoreCarsWithUnresolvedDiscrepancies || N_CHECKER,
        show_cars_ready_for_mgmt_review_only_flag:
          showCarsReadyForMgmtReviewOnlyFlag || N_CHECKER
      };

      const carSummarayResponse = await getResponseData(
        reqBody,
        CF_URLS.correctiveActionRequest.carSearchUrl,
        1
      );
      if (carSummarayResponse?.data?.[0].length === 0) {
        setMessageOptions({
          ...messageOptions,
          textLabels: {
            body: {
              noMatch: t("common.noRecordsFound")
            }
          }
        });
      } else {
        setMessageOptions({
          ...messageOptions,
          textLabels: {
            body: {
              noMatch: t("correctiveActionRequestSearch.viewReportMessage")
            }
          }
        });
      }

      window.history.replaceState(
        null,
        "",
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`
      );
      const sortedCarSummaryData = carSummarayResponse?.data?.[0]?.sort(
        (a, b) => {
          const loggedDateCompare =
            new Date(b?.ltz_logged_datetime) - new Date(a?.ltz_logged_datetime);
          if (loggedDateCompare !== 0) return loggedDateCompare;

          const customerCompare = a?.customer_number?.localeCompare(
            b?.customer_number
          );
          if (customerCompare !== 0) return customerCompare;

          return b?.car_id - a?.car_id;
        }
      );
      const carSummaryData = sortedCarSummaryData.map((res) => ({
        car_id: Number(res.car_id),
        car_status_descr: res.car_status_descr,
        car_type_descr: res.car_type_descr,
        customer_id: res.customer_id,
        customer_number: res.customer_number,
        days_at_status: Number(res.days_at_status),
        failure_point_descr: res.failure_point_descr,
        logged_by_employee: res.logged_by_employee,
        ltz_logged_datetime: res.ltz_logged_datetime,
        root_cause_descr: res.root_cause_descr
      }));
      let displayedCarSummaryData = carSummaryData;
      if (carSummaryData.length > 1000) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode26077);
        setThrowErrorMessage(errorMsg[0]?.descr);
        displayedCarSummaryData = carSummaryData.slice(0, 1000);
      }
      setCarSummaryData(displayedCarSummaryData);
      setLoading(false);
    } catch (error) {
      handleSystemError();
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFailurePoints();
    fetchRootCause();
    fetchStatus();
    fetchRootCauseEmployee("");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedBranchId) {
      fetchTypes(selectedBranchId);
      getCustomersBasedOnTheSelectedBranch();
    }
    // eslint-disable-next-line
  }, [selectedBranchId]);

  const allTypesOption = {
    value: all,
    label: `${t("common.all")}`
  };

  const allFailurePointsOption = {
    value: all,
    label: `${t("common.all")}`
  };

  const allStatusOption = {
    value: allUnresolved,
    label: `${t("correctiveActionRequestSearch.allUnresolved")}`
  };

  const allRootCauseOption = {
    value: all,
    label: `${t("common.all")}`
  };

  const allRootCauseEmployeeOption = {
    value: all,
    label: `${t("common.all")}`
  };
  const modifiedTypesOptions = [allTypesOption, ...typesData];
  const modifiedFailurePointsOptions = [
    allFailurePointsOption,
    ...failurePoints
  ];
  const modifiedRootCauseOptions = [allRootCauseOption, ...rootCauseData];
  const modifiedRootCauseEmployeeOptions = [
    allRootCauseEmployeeOption,
    ...rootCauseEmployee
  ];
  const modifiedStatusOptions = [
    allRootCauseOption,
    allStatusOption,
    ...statusData
  ];

  const initFilterState = useRef({
    fromDate: null,
    toDate: null,
    customer: t("common.all"),
    requestType: 0,
    routeName: 0,
    requestStatus: 1,
    type: all,
    failurePoints: all,
    rootCause: all,
    rootCauseEmployee: all,
    status: allUnresolved
  });
  const SearchFilterForm = useFormik({
    initialValues: initFilterState.current,
    validationSchema: filterSchema
  });

  const handleNewCar = () => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`
    );
  };
  const handleViewIcon = (record) => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
      { state: { record } }
    );
  };
  const handlePrintButtonClick = () => {
    setShowLongProcessMessageModal(true);
  };

  const handleModalClose = () => {
    setShowLongProcessMessageModal(false);
  };

  const handlePrint = () => {
    if (printOption === t("correctiveActionRequestSearch.summaryOption")) {
      setShowReportDownloadPopup(true);
    }
    if (printOption === t("correctiveActionRequestSearch.detailOption")) {
      setShowReportDownloadPopup(true);
    }
  };
  const handleCarIdChange = (event) => {
    setCarId(event.target.value);
    setIsFindButtonDisabled(false);
  };
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    setBillableFlag(event.target.checked ? N_CHECKER : Y_CHECKER);
    setIsFindButtonDisabled(false);
  };
  const handleFindClick = async () => {
    if (SearchFilterForm.isValid) {
      if (!SearchFilterForm.values.fromDate && SearchFilterForm.values.toDate) {
        await SearchFilterForm.setFieldValue(
          "fromDate",
          SearchFilterForm.values.toDate
        );
      }
      await fetchCarSummaryData();
      setIsFindButtonDisabled(true);
      setRadioButonEvent(true);
      setMessageOptions((prevOptions) => ({
        ...prevOptions,
        sort: true
      }));
      setTableKey((prevKey) => prevKey + 1);
    }
  };
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };
  const handleClearClick = () => {
    SearchFilterForm.resetForm();
    setMediaNumber(null);
    setCarId(null);
    setThrowErrorMessage(null);
    setRadioButonEvent(true);
    setRootCauseEmployeeDropdownDisabled(true);
    setIsNotBilledDisabled(true);
    setIsPrintButtonDisabled(true);
    setChecked();
    setIsFindButtonDisabled(false);
    setMessageOptions((prevOptions) => ({
      ...prevOptions,
      sort: false
    }));
    setCarSummaryData([]);
    if (carIdRef.current) {
      carIdRef.current.focus();
    }
  };
  const CustomBodyRenderComponent = ({ value, locale }) => {
    const [formattedDate, setFormattedDate] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        const result = await formatDateByLocale(value, locale, false);
        setFormattedDate(result);
      };
      fetchData();
    }, [value, locale]);

    return formattedDate;
  };
  const columns = [
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta) => {
          const record = carSummaryData[tableMeta.rowIndex];
          return <EyeIcon onClick={() => handleViewIcon(record)} />;
        }
      }
    },
    {
      name: "customer_number",
      label: t("correctiveActionRequestSearch.upperCustomer"),
      options: { filter: true, sort: true }
    },
    {
      name: "car_id",
      label: t("correctiveActionRequestSearch.carId"),
      options: { filter: true, sort: true }
    },
    {
      name: "ltz_logged_datetime",
      label: t("correctiveActionRequestSearch.loggedDate"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <CustomBodyRenderComponent
            value={value}
            locale={localeByBranch || DEFAULT_LOCALE}
          />
        )
      }
    },
    {
      name: "car_status_descr",
      label: t("correctiveActionRequestSearch.upperStatus"),
      options: { filter: true, sort: true }
    },
    {
      name: "days_at_status",
      label: t("correctiveActionRequestSearch.daysAtStatus"),
      options: { filter: true, sort: true }
    },
    {
      name: "car_type_descr",
      label: t("correctiveActionRequestSearch.upperType"),
      options: { filter: true, sort: true }
    },
    {
      name: "root_cause_descr",
      label: t("correctiveActionRequestSearch.upperRootCause"),
      options: { filter: true, sort: true }
    },
    {
      name: "failure_point_descr",
      label: t("correctiveActionRequestSearch.upperFailurePoint"),
      options: { filter: true, sort: true }
    },
    {
      name: "logged_by_employee",
      label: t("correctiveActionRequestSearch.loggedB"),
      options: { filter: true, sort: true }
    }
  ];
  const handleTypesChange = (event) => {
    const { value } = event.target;
    SearchFilterForm.setFieldValue("type", value);
    setIsFindButtonDisabled(false);
    setCarSummaryData([]);
    if (value === numberConstants.seven) {
      setIsNotBilledDisabled(false);
    } else {
      setChecked();
      setIsNotBilledDisabled(true);
    }
  };
  const handleFailurePointsChange = (event) => {
    const { value } = event.target;
    SearchFilterForm.setFieldValue("failurePoints", value);
    setIsFindButtonDisabled(false);
    setCarSummaryData([]);
  };
  const handleRootCauseChange = (event) => {
    const { value } = event.target;
    SearchFilterForm.setFieldValue("rootCause", value);
    setCarSummaryData([]);
    setIsFindButtonDisabled(false);
    if (value === numberConstants.two) {
      setRootCauseEmployeeDropdownDisabled(false);
    } else {
      setRootCauseEmployeeDropdownDisabled(true);
    }
  };
  const getSelectedEmployeeValue = () => {
    return modifiedRootCauseEmployeeOptions.find(
      (option) => option.value === SearchFilterForm.values.rootCauseEmployee
    );
  };
  const handleEmployeeSearchInput = (event, newInputValue) => {
    if (newInputValue.length > 0) {
      fetchRootCauseEmployee(newInputValue);
    }
  };
  const handleEmployeeChange = (event, newValue) => {
    SearchFilterForm.setFieldValue("rootCauseEmployee", newValue?.value);
    setIsFindButtonDisabled(false);
  };
  const handleStatusChange = (event) => {
    const { value } = event.target;
    SearchFilterForm.setFieldValue("status", value);
    setIsFindButtonDisabled(false);
    setCarSummaryData([]);
  };
  const handleMediaChange = async (event) => {
    const { value } = event.target;
    if (!value) {
      setRadioButonEvent(true);
    } else {
      setRadioButonEvent(false);
    }
    if (SearchFilterForm.values.customer === t("common.all")) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode63917);
      SearchFilterForm.setFieldError("customer", errorMsg[0]?.descr);
      setThrowErrorMessage(errorMsg[0]?.descr);
      setIsFindButtonDisabled(true);
    } else {
      setIsFindButtonDisabled(false);
      setThrowErrorMessage("");
    }
    setMediaNumber(value);
    setIsDefaultSearch(false);
    setCarSummaryData([]);
  };
  const handleDateValidation = async (fromDate, toDate) => {
    if (
      fromDate &&
      (!toDate ||
        dayjs(toDate).isBefore(dayjs(datesForCorrectiveSearch.minDate)))
    ) {
      toDate = fromDate;
    }

    if (!fromDate || !dayjs(fromDate).isValid()) return;

    if (fromDate && toDate && dayjs(fromDate).isAfter(dayjs(toDate))) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode63647);
      setThrowErrorMessage(errorMsg[0]?.descr);
      setIsFindButtonDisabled(true);
    } else {
      setThrowErrorMessage("");
      setIsFindButtonDisabled(false);
    }
  };
  const handleFromDateChange = async (newDate) => {
    setIsFindButtonDisabled(false);
    setCarSummaryData([]);
    await SearchFilterForm.setFieldValue("fromDate", newDate);
    if (!dayjs(newDate).isValid()) {
      return;
    }
    await handleDateValidation(newDate, SearchFilterForm.values.toDate);
    if (
      !SearchFilterForm.values.toDate ||
      dayjs(SearchFilterForm.values.toDate).isBefore(
        dayjs(datesForCorrectiveSearch.minDate)
      )
    ) {
      SearchFilterForm.setFieldValue("toDate", newDate);
    }
  };
  const handleToDateChange = async (newDate) => {
    setIsFindButtonDisabled(false);
    setCarSummaryData([]);
    await SearchFilterForm.setFieldValue("toDate", newDate);
    if (!dayjs(SearchFilterForm.values.fromDate).isValid()) {
      return;
    }
    await handleDateValidation(SearchFilterForm.values.fromDate, newDate);
  };

  const getCustomersBasedOnTheSelectedBranch = async () => {
    setIsLoadingCustomers(true);
    try {
      const custList = await getCustomersQueryData(selectedBranchId);
      const formattedCustomers = custList.map((cust) => ({
        label: `${cust.number.trim()} - ${cust.name.trim()}`,
        value: cust.customer_id
      }));

      setCustomerData(formattedCustomers);
    } catch (error) {
      handleSystemError();
      setLoading(false);
    } finally {
      setIsLoadingCustomers(false);
    }
  };
  const handleDownloadClick = async () => {
    if (printOption === t("correctiveActionRequestSearch.summaryOption")) {
      const requestBody = {
        main_district_id: currentBranch?.district_id,
        car_id: carId || "",
        car_type_id: carTypeId
          ? carTypeId
          : SearchFilterForm.values.type === all
            ? ""
            : SearchFilterForm.values.type,
        failure_point_id:
          SearchFilterForm.values.failurePoints === all
            ? null
            : SearchFilterForm.values.failurePoints,
        root_cause_id:
          SearchFilterForm.values.rootCause === all
            ? ""
            : SearchFilterForm.values.rootCause,
        root_cause_emp_id: "",
        car_status_id: carStatusId
          ? carStatusId
          : statusIdCar
            ? `${statusIdCar}`
            : SearchFilterForm.values.status === allUnresolved
              ? numberConstants.zero
              : SearchFilterForm.values.status === all
                ? ""
                : SearchFilterForm.values.status,
        car_type_id_desc: "",
        failure_point_id_desc: "",
        root_cause_id_desc: "",
        root_cause_emp_id_desc: "",
        car_status_id_desc: "",
        customer_id:
          SearchFilterForm.values.customer === t("common.all")
            ? ""
            : SearchFilterForm.values.customer,
        from_date:
          SearchFilterForm.values.fromDate &&
          SearchFilterForm.values.fromDate.isValid()
            ? SearchFilterForm.values.fromDate.format(
                dateFormat.universalFormat
              )
            : SearchFilterForm.values.toDate &&
                !SearchFilterForm.values.fromDate
              ? SearchFilterForm.values.toDate.format(
                  dateFormat.universalFormat
                )
              : "",
        to_date:
          SearchFilterForm.values.toDate &&
          SearchFilterForm.values.toDate.isValid()
            ? SearchFilterForm.values.toDate.format(dateFormat.universalFormat)
            : SearchFilterForm.values.fromDate &&
                SearchFilterForm.values.fromDate.isValid()
              ? SearchFilterForm.values.fromDate.format(
                  dateFormat.universalFormat
                )
              : "",
        days_at_status: "",
        max_row_count: maxRowCount || maximumRowsCount,
        billable_flag: billableFlag,
        media_number: mediaNumber,
        om_container_flag: isDefaultSearch
          ? ""
          : selectedRadio === t("correctiveActionRequestSearch.openMedia")
            ? openMediaConst
            : containersConst,
        sort_order: "",
        asc_desc: "",
        ignore_cars_with_unresolved_discrepancies:
          ignoreCarsWithUnresolvedDiscrepancies || N_CHECKER,
        show_cars_ready_for_mgmt_review_only_flag:
          showCarsReadyForMgmtReviewOnlyFlag || N_CHECKER,
        user_name: userName,
        report_format: pdfFormat
      };
      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestBody);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.correctiveAction.correctiveActionSummary
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage((prevState) =>
          findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
      return;
    }
    if (printOption === t("correctiveActionRequestSearch.detailOption")) {
      const carIds = carSummaryData?.map((item) => item?.car_id);
      const formattedCarIds = carIds.map((id) => {
        const strId = id.toString();
        return strId.padStart(10, " ");
      });
      const requestBody = {
        main_district_id: currentBranch?.district_id,
        district_id: currentBranch?.district_id,
        car_id_list: formattedCarIds.join(""),
        user_name: userName,
        report_format: pdfFormat,
        district_name: currentBranch?.name
      };

      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestBody);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.correctiveAction.correctiveActionRequestDetail
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage((prevState) =>
          findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
      return;
    }
  };

  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      {throwErrorMessage && (
        <Box my={spacing.gap}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            type="number"
            label={t("correctiveActionRequestSearch.carId")}
            variant="outlined"
            value={carId !== null ? carId : ""}
            onChange={handleCarIdChange}
            inputRef={carIdRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <SelectField
              label={t("correctiveActionRequestSearch.type")}
              value={SearchFilterForm.values.type}
              options={modifiedTypesOptions}
              onChange={handleTypesChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <CustomersField
              isOptionEqualToValue={(option, value) => {
                return option?.value === value;
              }}
              noOptionsText={t("requestSearch.customerNotFound")}
              form={{ touched: {}, errors: {} }}
              options={customerData}
              label={t("requestSearch.customer")}
              value={SearchFilterForm.values.customer || null}
              handleOnChange={(event, newValue) => {
                const selectedCustomer = customerData.find(
                  (customer) =>
                    customer?.value === newValue?.value &&
                    customer.label === newValue?.label &&
                    customer.number === newValue?.number
                );
                setIsFindButtonDisabled(false);
                setCarSummaryData([]);
                setThrowErrorMessage("");
                SearchFilterForm.setFieldValue("customer", selectedCustomer);
              }}
            />
          </FormControl>
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={3}>
            <DatePickerField
              id="fromDate"
              label={t("correctiveActionRequestSearch.from")}
              variant="input"
              value={SearchFilterForm.values.fromDate}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleFromDateChange}
              error={!!SearchFilterForm.errors.fromDate}
              helperText={SearchFilterForm.errors.fromDate}
              locale={localeByBranch}
            />
          </Grid>

          <Grid item xs={3}>
            <DatePickerField
              id="toDate"
              label={t("correctiveActionRequestSearch.to")}
              variant="input"
              value={SearchFilterForm.values.toDate}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleToDateChange}
              error={!!SearchFilterForm.errors.toDate}
              helperText={SearchFilterForm.errors.toDate}
              locale={localeByBranch}
            />
          </Grid>
        </LocalizationProvider>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <SelectField
              label={t("correctiveActionRequestSearch.failurePoint")}
              value={SearchFilterForm.values.failurePoints}
              options={modifiedFailurePointsOptions}
              onChange={handleFailurePointsChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <SelectField
              label={t("correctiveActionRequestSearch.status")}
              value={SearchFilterForm.values.status}
              options={modifiedStatusOptions}
              onChange={handleStatusChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <SelectField
              label={t("correctiveActionRequestSearch.rootCause")}
              value={SearchFilterForm.values.rootCause}
              options={modifiedRootCauseOptions}
              onChange={handleRootCauseChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <Autocomplete
              disabled={rootCauseEmployeeDropdownDisabled}
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              clearIcon={<CloseIcon />}
              popupIcon={<ArrowDown />}
              options={modifiedRootCauseEmployeeOptions}
              getOptionLabel={(option) => option?.label || ""}
              value={getSelectedEmployeeValue()}
              onInputChange={handleEmployeeSearchInput}
              onChange={handleEmployeeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("correctiveActionRequestSearch.rootCauseEmployee")}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            disabled={isNotBilledDisabled}
            control={
              <Checkbox onChange={handleCheckboxChange} checked={checked} />
            }
            label={t("correctiveActionRequestSearch.notBilledOnly")}
            sx={styles.formControlLabel}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={t("correctiveActionRequestSearch.mediaNumber")}
            variant="outlined"
            inputProps={{ maxLength: 20 }}
            value={mediaNumber !== null ? mediaNumber : ""}
            onChange={handleMediaChange}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              sx={styles.radioGroup}
              value={selectedRadio}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                disabled={radioButonEvent}
                value={t("correctiveActionRequestSearch.openMedia")}
                control={<Radio />}
                label={t("correctiveActionRequestSearch.openMedia")}
              />
              <FormControlLabel
                disabled={radioButonEvent}
                value={t("correctiveActionRequestSearch.containers")}
                control={<Radio />}
                label={t("correctiveActionRequestSearch.containers")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={styles.buttonContainer}>
        <Button variant="outlined" onClick={handleClearClick}>
          {t("common.clear")}
        </Button>
        <Button
          variant="contained"
          disabled={
            !SearchFilterForm.isValid ||
            SearchFilterForm.isSubmitting ||
            isFindButtonDisabled
          }
          onClick={handleFindClick}
        >
          {t("common.find")}
        </Button>
      </Box>
      <DataTable
        key={tableKey}
        columns={columns}
        title={
          <Typography style={styles.titleMessage}>
            {carSummaryData?.length} {t("common.recordsFound")}
          </Typography>
        }
        options={messageOptions}
        data={carSummaryData}
      />
      <Box sx={styles.buttonDisabled}>
        <Button
          disabled={isPrintButtonDisabled}
          onClick={handlePrintButtonClick}
          variant="outlined"
        >
          {t("common.print")}
        </Button>
        <Button variant="outlined" onClick={handleNewCar}>
          {t("correctiveActionRequestSearch.newCar")}
        </Button>
      </Box>
      {showLongProcessMessageModal && (
        <PrintModalPopup
          title={t("correctiveActionRequestSearch.printTitle")}
          modalPopupOpen={showLongProcessMessageModal}
          handleClose={handleModalClose}
          handlePrint={handlePrint}
          printOption={printOption}
          setPrintOption={setPrintOption}
        />
      )}
      {showReportDownloadPopup && (
        <DownloadPopup
          title={t("correctiveActionRequestSearch.downloadReport")}
          showPrintModal={showReportDownloadPopup}
          downloadOptions={downloadReportOptions}
          onCancleClick={() => {
            setShowReportDownloadPopup(false);
            setThrowError(false);
            setThrowErrorMessage("");
          }}
          setThrowError={setThrowError}
          onDownloadClick={handleDownloadClick}
          throwErrorMessage={throwErrorMessage}
          throwError={throwError}
        />
      )}
    </>
  );
};

export default CorrectiveActionRequestSearchComponent;
