export const containerResearchStyles = {
  mainContent: {
    ".MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium": {
      height: "3.5rem !important",
      padding: "0 0.63rem !important",
      textWrap: "nowrap"
    },
    ".MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium": {
      height: "3.25rem !important",
      padding: "0 0.63rem !important",
      textWrap: "nowrap"
    }
  },
  searchBySection: {
    gap: "1.25rem",
    marginY: "1.25rem"
  },
  filterSectionButtons: {
    gap: "1.25rem",
    margin: "1.25rem 0 2rem 0"
  },
  alertSection: {
    display: "grid",
    rowGap: "1.25rem",
    marginBottom: "1.25rem"
  },
  tableActionSection: {
    display: "flex",
    margin: "1.13rem 1rem",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tableActionButtonsSection: {
    display: "flex",
    columnGap: "1.25rem"
  },
  searchResultsCount: {
    color: "var(--color-im-grey-900)"
  },
  tableActionButtons: {
    gap: "1.25rem"
  },
  tableActionCell: { width: "24px" },
  padding16: { padding: "16px" },
  gap16: { gap: "16px" },
  modalStyles: {
    containerWidth: { width: "560px" },
    infoLabel: {
      fontSize: "16px",
      lineHeight: "28px",
      color: "#000"
    },

    innerCard: {
      display: "block",
      width: "100%"
    },

    buttonStyle: {
      display: "flex",
      padding: "6px 16px !important",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "unset",
      marginLeft: "0px !important"
    }
  },
  editContainerModalParentGrid: {
    width: "100%",
    rowSpacing: "1.25rem"
  },
  editContainerModalChildGrid: {
    columnSpacing: "1.25rem"
  },
  grayCellBackgroundColor: "rgba(1,0,0,0.1)",
  selectedCellBackgroundColor: "var(--color-im-light-blue-100)",
  lavenderCellBackgroundColor: "var(--color-lavender)",
  tableLegendSection: {
    marginTop: "20px"
  },
  tableLegenText: {
    fontSize: "12px",
    lineHeight: "166%",
    color: "#000"
  },
  divider: {
    marginTop: "1.25rem",
    marginBottom: "2rem"
  },
  modalSubTitle: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "0.15px",
    width: "100% !important"
  },
  checkboxWrapper: { display: "flex", alignItems: "flex-start" }
};
