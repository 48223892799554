import { Button, Grid, TextField } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useEffect } from "react";
import { shelvesTotalCount } from "utils/constants/assign-shelves/AssignShelves";

const AssignShelvesModal = ({
  isAssignModalOpen,
  onClose,
  selectedShelves,
  editAssignData,
  setEditAssignData,
  acceptRows
}) => {
  useEffect(() => {
    setEditAssignData({
      location: selectedShelves?.location,
      duration: selectedShelves?.duration,
      capacity: selectedShelves?.capacity,
      startingSlot: selectedShelves?.first_slot_number
    });
    //eslint-disable-next-line
  }, []);
  return (
    <CommonModal
      open={isAssignModalOpen}
      title={t("assignShelves.newAssignShelves")}
      body={
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <TextField
                id="location"
                name="location"
                label={t("assignShelves.location")}
                disabled
                value={editAssignData?.location}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                id="duration"
                name="duration"
                disabled
                label={t("assignShelves.duration")}
                value={editAssignData?.duration}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                id="capacity"
                name="capacity"
                disabled
                label={t("assignShelves.capacity")}
                value={editAssignData?.capacity}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="slot"
                name="slot"
                label={t("assignShelves.slot")}
                value={editAssignData?.startingSlot}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const numericValue = newValue.replace(/\s/g, "");
                  // Allow digits and spaces
                  if (
                    /^[\d\s]*$/.test(numericValue) &&
                    (!numericValue ||
                      parseInt(numericValue) <= shelvesTotalCount)
                  ) {
                    setEditAssignData({
                      ...editAssignData,
                      startingSlot: e.target.value
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        </>
      }
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            {t("common.close")}
          </Button>
          <Button variant="contained" onClick={acceptRows}>
            {t("common.add")}
          </Button>
        </>
      }
    />
  );
};
export default AssignShelvesModal;
