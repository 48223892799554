import {
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";

import { useEffect, useState } from "react";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  VALUE_EMPTY_STRING
} from "utils/constants";
import { VerifyTabStyles } from "../WorkManagementStyles";

const SelectableRowTable = ({
  columns,
  sortedList,
  title,
  setTableData,
  setTableData3,
  setIsOneBatchChecked,
  setIsOneListChecked,
  setSelectableTableData,
  setVerifyAndCommit
}) => {
  const [tempData, setTempData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    DEFAULT_DATA_TABLE_OPTIONS.rowsPerPage
  );

  useEffect(() => {
    setTempData(sortedList);
  }, [sortedList]);

  useEffect(() => {
    if (tempData?.length > 0) {
      if (setIsOneBatchChecked) {
        setSelectableTableData((prev) => ({
          batch: tempData?.filter((ob) => ob.checked),
          list: prev?.list
        }));

        const oneChecked = tempData?.filter(
          (obj) =>
            obj?.checked && obj?.verification_status_id !== VALUE_EMPTY_STRING
        );
        if (oneChecked && oneChecked?.length > 0) {
          setIsOneBatchChecked(true);
        } else {
          setIsOneBatchChecked(false);
        }
      } else {
        setSelectableTableData((prev) => ({
          batch: prev?.batch,
          list: tempData?.filter((ob) => ob.checked)
        }));

        const oneListChecked = tempData?.filter(
          (obj) =>
            obj?.checked && obj?.verification_status_id === VALUE_EMPTY_STRING
        );
        if (oneListChecked && oneListChecked?.length > 0) {
          setIsOneListChecked(true);
        } else {
          setIsOneListChecked(false);
        }
      }
    }

    // eslint-disable-next-line
  }, [tempData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCheckEvent = (e, row) => {
    setTableData3([]);
    setVerifyAndCommit({ verify: false, commit: false });

    const updateObj = {
      ...row,
      checked: e.target.checked
    };

    const newData = tempData?.map((obj) =>
      obj?.seed === updateObj?.seed ? updateObj : obj
    );

    setTempData(newData);
  };

  return (
    <Paper id="selectTable" style={VerifyTabStyles.paper}>
      <Typography variant="h5" sx={VerifyTabStyles.selectTable.title}>
        {title +
          (tempData?.length > 0
            ? tempData
                ?.filter((obj) => obj?.checked)
                ?.reduce((acc, obj) => acc + Number(obj.item_count), 0)
                .toString()
            : "")}
      </Typography>
      <TableContainer style={VerifyTabStyles.selectTable.tableContainer}>
        <Table>
          <TableHead style={VerifyTabStyles.tableHead}>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  id={`matchSort${index}`}
                  key={column.id}
                  align="left"
                  sx={{
                    width: index === 0 ? "30px" : undefined,
                    ...VerifyTabStyles.tableHeaderTypo
                  }}
                  size="small"
                >
                  <span style={VerifyTabStyles.span}>
                    {column.label?.toUpperCase()}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? tempData?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ) || []
              : tempData
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={VerifyTabStyles.possibleMatchTableRowMuiCustom}
              >
                {columns?.map((column) => (
                  <>
                    <TableCell
                      size="small"
                      sx={VerifyTabStyles.tableCell}
                      key={column.id}
                    >
                      {column.id === "checkbox" ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={row?.checked}
                              onChange={(e) => handleCheckEvent(e, row)}
                            />
                          }
                          label=""
                          sx={{
                            ...VerifyTabStyles.formControlLabel,
                            pl: "10px"
                          }}
                        />
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  </>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={VerifyTabStyles.tablePagination}
        rowsPerPageOptions={DEFAULT_DATA_TABLE_OPTIONS.rowsPerPageOptions}
        component="div"
        count={tempData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default SelectableRowTable;
