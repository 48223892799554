import { t } from "i18next";
import { CF_URLS } from "services/api/endpoints";
import { BOOLEAN_STRING_VALUES, N_CHECKER, Y_CHECKER } from "utils/constants";
import {
  stringChecker,
  zeroChecker
} from "utils/constants/container-processing/Distribution";
import { getResponseData, printReport } from "utils/helpers";

export const getBatchScanService = async (mainDistrictId) => {
  const reqBody = {
    main_district_id: mainDistrictId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution.returnbatchrecords,
    1
  );
  return res;
};
export const getArrivalService = async (mainDistrictId) => {
  const reqBody = {
    main_district_id: mainDistrictId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution
      .populatecontainerprocessingdistributiontab,
    1
  );
  return res;
};

export const validateDistributionNotArrival = async (
  mainDistrictId,
  batch_id
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    batch_id: batch_id
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution
      .validatedistributionnotarrival,
    2
  );
  return res;
};
export const validateBatchImport = async (mainDistrictId) => {
  const reqBody = {
    main_district_id: mainDistrictId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution.validatebatchimport,
    5
  );
  return res;
};
export const validateBatchImportTwo = async (mainDistrictId, batch_id) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    batch_id: batch_id
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution.validatebatchimporttwo,
    5
  );
  return res;
};
export const UploadErrors = async (mainDistrictId, batch_id) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    batch_id: batch_id,
    show_deleted: N_CHECKER,
    run_id: zeroChecker,
    from_date: "",
    to_date: ""
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution.uploaderrors,
    2
  );
  return res;
};

export const distributeallcontainersbatch = async (
  mainDistrictId,
  batch_id
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    batch_id: batch_id
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution
      .distributeallcontainersbatch,
    2
  );
  return res;
};

export const notArrivalBatch = async (mainDistrictId, batch_id) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    batch_id: batch_id
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution.notarrivalbatch,
    2
  );
  return res;
};

export const getcommondata = async (mainDistrictId) => {
  const reqBody = {
    main_district_id: mainDistrictId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.general.distribution.getcommondata,
    2
  );
  return res;
};
export const commonLogic = (data, getCommonData) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data?.map((item) => {
    if (
      item &&
      getCommonData &&
      item.slotting_location &&
      typeof item.slotting_location === stringChecker
    ) {
      let parts = item.slotting_location.split("-");
      let slotting_location;
      if (getCommonData.display_container_slot_flag === Y_CHECKER) {
        // Transform "99-001-001-01-151" to "vv-www-xxx-yy-zzz"
        slotting_location = `${parts[0]}-${parts[1]}-${parts[2]}-${parts[3]}-${parts[4]}`;
      } else if (getCommonData.display_container_slot_flag === N_CHECKER) {
        // Transform "99-001-001-01-151" to "vv-www-xxx-yy"
        slotting_location = `${parts[0]}-${parts[1]}-${parts[2]}-${parts[3]}`;
      } else {
        slotting_location = item.slotting_location;
      }
      return {
        ...item,
        slotting_location: slotting_location
      };
    } else {
      return item;
    }
  });
};

export const distributionRepots = async (
  main_district_id,
  user_name,
  report_format,
  batch_id,
  batch_type_flag
) => {
  let reqBody = {
    main_district_id: main_district_id,
    batch_id: batch_id,
    report_title: t("containerProcessing.distribution.reportTitle"),
    display_container_slot: BOOLEAN_STRING_VALUES.TRUE,
    selection_criteria: t("containerProcessing.distribution.notApplicable"),
    user_name: user_name,
    report_format: report_format,
    batch_type_flag: batch_type_flag
  };
  let res = await printReport(
    reqBody,
    `${CF_URLS.printReports.containerProcessing.distributionReport}`
  );
  return res;
};

export const distributionErrorReport = async (
  main_district_id,
  user_name,
  batch_id,
  report_format
) => {
  let reqBody = {
    main_district_id: main_district_id,
    batch_id: batch_id,
    user_name: user_name,
    report_format: report_format,
    show_deleted: "",
    run_id: zeroChecker,
    from_date: "",
    to_date: ""
  };
  let res = await printReport(
    reqBody,
    `${CF_URLS.printReports.reports.uploadErrors}`
  );
  return res;
};
