export const DetailsStyles = {
  fontColor: {
    color: "var(--color-im-grey-400)"
  },
  fontColor2: {
    color: "var(--color-im-grey-900)"
  },
  subHeading: {
    fontSize: "24px",
    color: "var(--color-im-grey-900)",
    fontWeight: 400,
    marginBottom: "10px"
  },
  subHeading2: {
    fontSize: "16px",
    color: "var(--color-im-grey-900)",
    fontWeight: 400,
    padding: "5px 0px 0px 10px"
  },
  mainDivStyles: {
    marginTop: "20px"
  },
  authCallerWrapper: {
    "&>div": {
      marginTop: "-56px !important"
    }
  }
};
