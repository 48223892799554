export const Styles = {
  separatorContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    marginTop: "20px"
  },
  signInOrDiv: {
    padding: "5px"
  },
  lineSeparator: {
    height: "1px",
    flex: "1",
    backgroundColor: "#3C577299"
  },
  separatorBody: {
    paddingLeft: "7px",
    paddingRight: "7px"
  },
  boxStyles: {
    width: "clamp(450px, 30%, 600px)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff"
  },
  sbLogoStyls: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "10px 10px 20px 10px"
  },
  materialButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#1B75BC",
    border: "1px solid #14477D",
    borderRadius: "10px",
    cursor: "pointer",
    width: "100%",
    backgroundColor: "#fff"
  },
  materialIcon: {
    marginRight: "8px",
    marginTop: "4px"
  },
  googleImgStyles: {
    width: "40px",
    height: "40px"
  },
  hoverEffect: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#1B75BC",
    border: "1px solid #14477D",
    borderRadius: "10px",
    cursor: "pointer",
    width: "100%",
    backgroundColor: "rgba(20, 71, 125, 0.04)"
  },
  loginButtonDivStyles: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "15px"
  },
  loginButton: {
    width: "10rem"
  },
  loginCircularProgress: {
    color: "#fff"
  }
};
