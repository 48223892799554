export const calendarUiRoadNet = {
  buttonStyle: {
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1px"
  },
  buttonActiveStyle: {
    backgroundColor: "var(--color-selectable)",
    color: "var(--color-white)"
  },
  buttonInActiveStyle: {
    backgroundColor: "var(--color-nonselactable)",
    color: "var(--color-white)"
  },
  bottomTextGrid: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px"
  },
  nonSelectableDateBoxStyles: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center"
  },
  selectableDateBoxStyles: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center"
  },
  btnSelectedColor: {
    backgroundColor: "var(--color-btnSelected)"
  },
  userSelectedColor: {
    color: "white"
  },
  userNotSelectedColor: {
    color: "inherit"
  },
  buttonDefaultColor: {
    backgroundColor: "transparent"
  }
};
