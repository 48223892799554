export const mediaProgramTypeId = "5";
export const ecmtCONTAINER = "3";
export const ecmtTRANSPORT = " 4";
export const ecmtCONTAINER_AND_TRANSPORTS = "0";
export const correctiveActionRequestId1 = "1";
export const correctiveActionRequestId2 = "2";
export const correctiveActionRequestId3 = "3";
export const correctiveActionRequestId10 = "10";
export const correctiveActionRequestId12 = "12";
export const correctiveActionRequestId14 = "14";
export const correctiveActionRequestId4 = "4";
export const correctiveActionRequestId5 = "5";

export const correctiveActionRequestId11 = "11";
export const correctiveActionRequestId6 = "6";
export const correctiveActionRequestId7 = "7";
export const correctiveActionRequestId8 = "8";
export const attribututeGlobalType20 = "20";
export const attribututeGlobalType18 = "18";
export const mediaLength1600 = 1600;
export const openMediaConst = "O";
export const containersConst = "C";
export const maximumRowsCount = "1001";
export const correctiveActionRequestId18 = "18";
export const correctiveActionRequestId17 = "17";
export const correctiveActionRequestId16 = "16";
export const correctiveActionRequestId15 = "15";
export const modalTextLimit = 2015;
export const bill = "bill";
export const doNotBill = "doNotBill";
export const none = "none";
export const auto = "auto";
export const inherit = "inherit";
