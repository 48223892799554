import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { NonEditableField } from "components/shared";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetAuthorizationPersonnelsAuthenticationsMutation,
  useGetValidateChallengeResponseMutation
} from "services/api";
import spacing from "styles/spacing";
import { Y_CHECKER } from "utils/constants";
import {
  generateSHA256Hash,
  getAuthenticatedUserBranch,
  getLanguageId
} from "utils/helpers";
import { PersonnelAuthorizationSectionStyles } from "./PersonnelAuthorizationSection.Styles";

const PersonnelAuthorizationSection = (props) => {
  const { t } = useTranslation();

  const [
    getAuthorizationPersonnelsData,
    {
      data: authroizedPersonnelsData = {},
      reset: resetAuthroizedPersonnelsData
    }
  ] = useGetAuthorizationPersonnelsAuthenticationsMutation(); // get authorized personnel's details
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation(); //checking the challenge response

  const [authorizationStatus, setAuthorizationStatus] = useState({
    authorizedBy: 0,
    isError: false
  });

  const [authDetails, setAuthDetails] = useState({
    authNumber: "",
    challengeQuestion: "",
    challengeResponse: ""
  });

  // authorized personnel changed
  useEffect(() => {
    setAuthorizationStatus((prevState) => ({
      ...prevState,
      authorizedBy: props?.authorizedBy || 0
    }));
  }, [props.authorizedBy]);

  // fetch selected authorized personnel's details
  useEffect(() => {
    if (authorizationStatus.authorizedBy > 0) {
      props.setLoading(true);
      getAuthorizationPersonnelsData({
        personnelId: authorizationStatus?.authorizedBy
      })
        .then((resp) => resp.data)
        .then((resp) => {
          setAuthorizationStatus((prevState) => ({
            ...prevState,
            isError: false
          }));
          setAuthDetails((prevState) => ({
            ...prevState,
            authNumber: "",
            challengeQuestion: resp.secretQuestion,
            challengeResponse: ""
          }));
          props.setLoading(false);
        })
        .catch(() => props.setLoading(false));
    } else {
      resetAuthroizedPersonnelsData();
      setAuthorizationStatus((prevState) => ({
        ...prevState,
        isError: false
      }));
      setAuthDetails((prevState) => ({
        ...prevState,
        authNumber: "",
        challengeQuestion: "",
        challengeResponse: ""
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationStatus?.authorizedBy]);

  // handle request authroization event
  const handleAuthorization = async ({ target }) => {
    //generate hash code
    let validateChallengeResponse;
    if (authDetails.challengeResponse?.length > 0) {
      props.setLoading(true);
      let hashCode = await generateSHA256Hash(
        authDetails.challengeResponse.trim().toUpperCase()
      );

      //challenge response validation
      validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: getAuthenticatedUserBranch(),
        language_locale_id: getLanguageId(),
        personnel_id: authorizationStatus.authorizedBy,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          props.setLoading(false);
          if (data[0]?.is_match_flag) {
            return data[0].is_match_flag;
          }
        })
        .catch(() => props.setLoading(false));
    }

    if (
      authroizedPersonnelsData?.authNumber === authDetails?.authNumber ||
      validateChallengeResponse === Y_CHECKER
    ) {
      setAuthorizationStatus((prevState) => ({
        ...prevState,
        isError: false
      }));
      props.onSubmit();
    } else {
      setAuthorizationStatus((prevState) => ({
        ...prevState,
        isError: true
      }));
    }
  };

  return (
    <Grid item xs={12}>
      {/* Authorization Form Controls */}
      <Grid
        id="eventDetailAuthGrid15"
        container
        spacing={spacing.verticalMargin20}
      >
        {/* Personal Authentication Helper Text */}
        <Grid id="eventDetailAuthGrid21" item xs={12}>
          <Typography id="eventDetailAuthHelper" variant="body2">
            {t("common.authInfo")}
          </Typography>
        </Grid>

        {/* Auth Number field */}
        <Grid id="eventDetailGrid22" item xs={12} md={6} lg={3}>
          <TextField
            id="eventDetailAuthNumber"
            name="authNumber"
            label={t("common.authNumber")}
            disabled={Boolean(authDetails.challengeResponse)}
            size="medium"
            variant="outlined"
            fullWidth
            value={authDetails.authNumber}
            onChange={(e) =>
              setAuthDetails((prevState) => ({
                ...prevState,
                authNumber: e.target.value
              }))
            }
          />
        </Grid>

        {/* Authorization Challenge Question field */}
        {Boolean(authDetails.challengeQuestion) && (
          <Grid id="eventDetailAuthGrid23" item xs={12} md={6} lg={3}>
            <NonEditableField
              id="eventDetailAuthChallengeQuestion"
              label={t("common.challengeQuestion")}
              value={authDetails.challengeQuestion}
            />
          </Grid>
        )}

        {/* Authorization Challenge Response field */}
        {Boolean(authDetails.challengeQuestion) && (
          <Grid id="eventDetailAuthGrid24" item xs={12} md={6} lg={3}>
            <TextField
              id="eventDetailAuthChallengeResponse"
              name="authorizationChallengeResponse"
              type="password"
              disabled={Boolean(authDetails.authNumber)}
              label={t("common.challengeResponse")}
              size="medium"
              sx={PersonnelAuthorizationSectionStyles.FlexShrinkNoGrow}
              variant="outlined"
              value={authDetails.challengeResponse}
              onChange={(e) =>
                setAuthDetails((prevState) => ({
                  ...prevState,
                  challengeResponse: e.target.value
                }))
              }
            />
          </Grid>
        )}

        {/* Authentication failed error message */}
        {authorizationStatus.isError && (
          <Grid id="eventDetailAuthGrid26" item xs={12}>
            <Alert
              id="eventDetailAuthenticateErrorAlert"
              severity="error"
              color="error"
              sx={PersonnelAuthorizationSectionStyles.errorAlert}
            >
              {t("common.authenticateFailed")}
            </Alert>
          </Grid>
        )}
      </Grid>
      <Box sx={PersonnelAuthorizationSectionStyles.cardActionsStyle_PRSPopUP}>
        {/* Authenticate Button */}
        <Button
          id="eventDetailAuthenticateButton"
          sx={PersonnelAuthorizationSectionStyles.buttonStyle_PRSPopUP}
          variant="contained"
          type="button"
          onClick={handleAuthorization}
        >
          {t("mediaRequestDetail.authenticate")}
        </Button>
      </Box>
    </Grid>
  );
};

PersonnelAuthorizationSection.propTypes = {
  setLoading: PropTypes.func, // set loading of the section
  authorizedBy: PropTypes.string, // personnel's Id
  onSubmit: PropTypes.func // handle submit event
};

export default PersonnelAuthorizationSection;
