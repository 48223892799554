import { createSlice } from "@reduxjs/toolkit";
import { VALUE_EMPTY_STRING } from "utils/constants";

const initialFormValues = {
  notification: VALUE_EMPTY_STRING,
  branch: VALUE_EMPTY_STRING,
  employee: VALUE_EMPTY_STRING,
  action: VALUE_EMPTY_STRING
};
const initialAuthValues = {
  branch: VALUE_EMPTY_STRING,
  customer: VALUE_EMPTY_STRING,
  personnel: VALUE_EMPTY_STRING,
  authNumber: VALUE_EMPTY_STRING,
  challengeQuestion: VALUE_EMPTY_STRING,
  challengeResponse: VALUE_EMPTY_STRING
};
const notificationConfigSlice = createSlice({
  name: "notificationConfig",
  initialState: {
    notificationConfigFormValues: initialFormValues,
    notificationAuthFormValues: initialAuthValues,
    isLoading: false,
    throwError: false,
    throwErrorMsg: VALUE_EMPTY_STRING,
    isAuthenticated: false,
    throwWarning: false,
    throwWarningMsg: VALUE_EMPTY_STRING,
    authCustomers: [],
    notificationTypes: [],
    employeeOptions: [],
    branchOptions: [],
    actionTypes: [],
    employeeData: []
  },
  reducers: {
    updateNotificationConfigFormValues(state, action) {
      state.notificationConfigFormValues = {
        ...state.notificationConfigFormValues,
        ...action.payload
      };
    },

    updateNotificationAuthFormValues(state, action) {
      state.notificationAuthFormValues = {
        ...state.notificationAuthFormValues,
        ...action.payload
      };
    },
    resetNotificationConfigForm(state, action) {
      state.notificationConfigFormValues = initialFormValues;
      state.employeeData = [];
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },
    resetNotificationAuthForm(state) {
      state.notificationAuthFormValues = initialAuthValues;
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.throwError = true;
      state.throwErrorMsg = action.payload;
    },
    setWarning(state, action) {
      state.throwWarning = true;
      state.throwWarningMsg = action.payload;
    },
    clearError(state) {
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },
    clearWarning(state) {
      state.throwWarning = false;
      state.throwWarningMsg = VALUE_EMPTY_STRING;
    },
    updateNotificationAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },
    setAuthCustomers(state, action) {
      state.authCustomers = action.payload;
    },
    setAllEmployeeOptions(state, action) {
      state.employeeOptions = action.payload;
    },
    setAllEmployeeData(state, action) {
      state.employeeData = action.payload;
    },
    setNotificationTypes(state, action) {
      state.notificationTypes = action.payload;
    },
    setBranchTypes(state, action) {
      state.branchOptions = action.payload;
    },
    setActionTypes(state, action) {
      state.actionTypes = action.payload;
    }
  }
});

export const {
  updateNotificationConfigFormValues,
  resetNotificationConfigForm,
  setSearchResults,
  setLoading,
  setError,
  clearError,
  updateNotificationAuthFormValues,
  resetNotificationAuthForm,
  updateNotificationAuthentication,
  setWarning,
  clearWarning,
  setAuthCustomers,
  setNotificationTypes,
  setAllEmployeeData,
  setBranchTypes,
  setActionTypes,
  setAllEmployeeOptions
} = notificationConfigSlice.actions;

export default notificationConfigSlice.reducer;
