import { Stack } from "@mui/material";
import DiscrepancyManagementDetailLayout from "components/discrepancy-management/detail/DiscrepancyManagementDetailLayout";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const DiscrepancyManagementDetail = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={i18n.t("discrepancyManagementDetail.title")} />
      <DiscrepancyManagementDetailLayout />
    </Stack>
  );
};

export default DiscrepancyManagementDetail;
