import { Box, Button, Chip, SvgIcon } from "@mui/material";
import DataTable from "components/core/data-table/DataTable";
import dayjs from "dayjs";
import { TableFilterList } from "mui-datatables";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MEDIA_REQUEST_DETAIL, REQUEST_MODULE_BASE_PATH } from "routing/Paths";
import { dateFormatWithoutTime } from "utils/constants";
import { ReactComponent as EditIcon } from "../../../../../assets/images/EditIcon.svg";
import { SearchTableStyles } from "./SearchTable.Styles";

const SearchTable = ({
  searchResults,
  status,
  buttonClicked,
  locale,
  paramsDataSet,
  timeZoneOffset
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Limiting the results to the first 100 records
  const slicedSearchResults = searchResults.slice(0, 100);
  const [isSearchResultsCountReady, setIsSearchResultsCountReady] =
    useState(false);
  const searchResultsCount = useMemo(
    () => slicedSearchResults?.length,
    [slicedSearchResults]
  );

  useLayoutEffect(() => {
    setIsSearchResultsCountReady(true);
  }, [slicedSearchResults]);

  const noRecord = slicedSearchResults?.length === 0 && true;
  const CustomBodyRenderComponent = ({ value, locale }) => {
    const [formattedDate, setFormattedDate] = useState(null);

    useEffect(() => {
      const formattedDate = dayjs
        .utc(value)
        .locale(locale)
        .format(dateFormatWithoutTime);
      setFormattedDate(formattedDate);
    }, [value, locale]);
    return formattedDate;
  };
  const columns = [
    {
      name: "request_id",
      label: t("requestSearch.emptyLabelHeader"),
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value) => (
          <Button
            onClick={() => {
              onNavigate(value);
            }}
          >
            <SvgIcon component={EditIcon} />
          </Button>
        ),
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "service_date",
      label: t("requestSearch.serviceDateHeader"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        ),
        customBodyRender: (value) => (
          <CustomBodyRenderComponent value={value} locale={locale} />
        )
      }
    },
    {
      name: "route_name",
      label: t("requestSearch.routeHeader"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "customer_number",
      label: t("requestSearch.customerHeader"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "request_type",
      label: t("requestSearch.requestTypeHeader"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "request_status",
      label: t("requestSearch.requestStatsesHeader"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "request_action",
      label: t("requestSearch.requestActionHeader"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "request_id",
      label: t("requestSearch.requestIdHeader"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "personnel_name",
      label: t("requestSearch.personnelHeader"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    }
  ];

  const options = {
    search: !noRecord && true,
    download: !noRecord && true,
    viewColumns: !noRecord && true,
    filter: !noRecord && true,
    pagination: !noRecord && true,
    print: false,
    filterType: "dropdown",
    selectableRowsHeader: false,
    selectableRows: "none",
    jumpToPage: true,
    downloadOptions: {
      filename: "rp_sbreq_search.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    textLabels: {
      body: {
        noMatch:
          buttonClicked === "Find" && isSearchResultsCountReady ? (
            <React.Fragment key="noMatch">
              {status === "fulfilled" && slicedSearchResults?.length === 0 && (
                <span key="tableTextWithoutInputs">
                  {t("common.noDataFound")}
                </span>
              )}
            </React.Fragment>
          ) : (
            <span key="tableTextWithoutInputs">
              {t("common.tableTextWithoutInputs")}
            </span>
          )
      },
      pagination: {
        jumpToPage: (
          <span style={SearchTableStyles.jumpToPage}>
            {t("requestSearch.jumpToPage")}
          </span>
        )
      }
    }
  };

  const CustomChip = ({ label, onDelete }) => {
    return (
      <Chip
        variant="outlined"
        color="primary"
        label={label?.toString()}
        size="medium"
        sx={SearchTableStyles.chip}
        onDelete={onDelete}
      />
    );
  };

  const CustomFilterList = (props) => {
    return (
      !noRecord && <TableFilterList {...props} ItemComponent={CustomChip} />
    );
  };

  const onNavigate = (value) => {
    navigate(`${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}/${value}`);
  };

  return (
    <>
      <Box sx={SearchTableStyles.muiDataTableStyles}>
        <DataTable
          options={options}
          noRecord={noRecord}
          data={slicedSearchResults}
          searchResultsCount={searchResultsCount}
          columns={columns}
          components={{
            TableFilterList: CustomFilterList
          }}
          paramsDataSet={paramsDataSet}
        />
      </Box>
    </>
  );
};

export default SearchTable;
