const personnelAuthStyles = {
  boxStyle: { display: "flex", gap: "20px" },
  successAuth: { color: "var(--color-add-ons)", display: "flex" },
  alertWidth: { minWidth: "600px" }
};

const customerDetailStyles = {
  alertStyle: { mt: 1, minWidth: "668px" },
  subTitleColor: { color: "var(--color-im-grey-400)" }
};

const bypassStyles = {
  tfReason: { width: "640px", height: "80px" }
};

const commonStyles = {
  boxWidth: { width: "310px" },
  inputLabelProps: { shrink: true, style: { zIndex: 0 } },
  flex: { display: "flex" },
  btn: { textTransform: "none", minWidth: "110px" }
};

const buttonStyles = {
  containerBox: { display: "flex", gap: "20px" }
};
const personalHistoryStyles = {
  authenticationStyles: {
    margin: "12px 0"
  },
  historyTableFilterStyles: {
    "& MuiGridListTile": {
      root: {
        "&:nth-child(3n)": {
          width: "100%"
        }
      }
    }
  }
};

export const personnelDetailStyles = {
  commonStyles,
  personnelAuthStyles,
  bypassStyles,
  customerDetailStyles,
  buttonStyles,
  personalHistoryStyles
};
