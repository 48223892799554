import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CORRECTIVE_ACTION_REQUESTS_SEARCH,
  DISCREPANCY_MANAGEMENT,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import { ERROR_TYPES, N_CHECKER, Y_CHECKER } from "utils/constants";
import { findErrorMessage, getResponseData } from "utils/helpers";
import ToDoItem from "./ToDoItem";

const CarDiscrepency = ({
  setIsLoading,
  setShowErrorAlert,
  setShowErrorAlertMsg
}) => {
  const { t } = useTranslation();
  const { currentBranch } = useSelector(selectAuth);
  const navigate = useNavigate();
  const [carDiscrepencyToDos, setCarDiscrepencyToDos] = useState({
    unresolvedCars: 0,
    carsWithAssociatedDiscrepResolved: 0,
    carsReadyToReview: 0,
    unresolvedDiscrep: 0
  }); // to-do open media counts state

  // fetch CAR/Discrepency to-do's
  const fetchTodoCarDiscrepency = async (districtId) => {
    try {
      setIsLoading(true);
      const reqBody = {
        main_district_id: districtId,
        spid: ""
      };

      const carDiscrepencyResponse = await getResponseData(
        reqBody,
        `${CF_URLS.dashboard.todo.todoCarDiscrepency}`,
        1
      );

      const carDiscrepencyData = carDiscrepencyResponse.data[0][0];
      setCarDiscrepencyToDos((prevState) => ({
        unresolvedCars: carDiscrepencyData?.unresolved_count,
        carsWithAssociatedDiscrepResolved: carDiscrepencyData?.resolved_count,
        carsReadyToReview: carDiscrepencyData?.car_ready_for_mgmt_review,
        unresolvedDiscrep: carDiscrepencyData?.unresolved_discrepancies
      }));
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setIsLoading(false);
    }
  };

  // card items click event handler
  const onItemClick = async (labelText) => {
    switch (labelText) {
      case t("dashboard.unresolvedCorrectiveActionRequests"):
        // redirect to CAR search
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`,
          {
            state: {
              ignore_cars_with_unresolved_discrepancies: N_CHECKER,
              car_status_id: 0
            }
          }
        );
        break;
      case t("dashboard.CARSwithAllAssociatedDiscrepanciesResolved"):
        // redirect to CAR search
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`,
          {
            state: {
              ignore_cars_with_unresolved_discrepancies: Y_CHECKER,
              car_status_id: 0
            }
          }
        );
        break;
      case t("dashboard.CARSReadyforManagementReview"):
        // redirect to CAR search
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`,
          {
            state: {
              ignore_cars_with_unresolved_discrepancies: N_CHECKER,
              show_cars_ready_for_mgmt_review_only_flag: Y_CHECKER,
              car_status_id: 2
            }
          }
        );
        break;
      case t("dashboard.unresolvedDiscrepancies"):
        // redirect to discrepency search
        navigate(`${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`, {
          state: {
            carProps: {
              discrepancy_status: t("discrepancyManagement.unresolved"),
              media: t("common.all"),
              detected_in: t("common.all"),
              discrepency_type: t("common.all")
            }
          }
        });
        break;
      default:
        // default
        break;
    }
  };

  // init
  useEffect(() => {
    fetchTodoCarDiscrepency(currentBranch?.district_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.unresolvedCorrectiveActionRequests")}
          number={carDiscrepencyToDos.unresolvedCars}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.CARSwithAllAssociatedDiscrepanciesResolved")}
          number={carDiscrepencyToDos.carsWithAssociatedDiscrepResolved}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.CARSReadyforManagementReview")}
          number={carDiscrepencyToDos.carsReadyToReview}
          onCardClick={onItemClick}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.unresolvedDiscrepancies")}
          number={carDiscrepencyToDos.unresolvedDiscrep}
          onCardClick={onItemClick}
        />
      </Grid>
    </>
  );
};

CarDiscrepency.propTypes = {
  setIsLoading: PropTypes.func.isRequired, // loading indicator show/hide
  setShowErrorAlert: PropTypes.func.isRequired, // alert show/hide
  setShowErrorAlertMsg: PropTypes.func.isRequired // error message
};

export default CarDiscrepency;
