import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import {
  CircularLoaderFullPage,
  DatePickerField,
  SelectField
} from "components/core";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useCallbackPrompt } from "hooks";
import {
  EVENT,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getCustomerByID, getMessageFromCode } from "services/api/query";
import {
  getAuthorizationPersonnels,
  getEligibleRequestLocations
} from "services/common";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  errorMsgs,
  N_CHECKER,
  numberConstants,
  REQUEST_ACTIONS,
  VALUE_EMPTY_STRING,
  yearMonthDayFormat
} from "utils/constants";
import { serviceLocation } from "utils/constants/disaster-module/EventSearchConstants";
import { generateOtherRequestSystemFunctionId } from "utils/constants/disaster-module/OtherRequest";
import {
  findErrorMessage,
  getAuthenticatedUserId,
  getAuthenticatedUserName,
  getDayOfWeekCode,
  getResponseData
} from "utils/helpers";
import { disasterRecoveryStyles } from "../DisasterRecoveryStyles";
import PersonnelAuthorizationModal from "./PersonnelAuthorizationSection";

const OtherRequest = ({ setRouteData }) => {
  const { t } = useTranslation();
  const routeParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const drEventId = useMemo(() => routeParams?.id, [routeParams]); // DR event id from the route
  const customerId = useMemo(() => routeParams?.customerId, [routeParams]); // customer id from the route
  const { currentBranch, timeZoneOffset, localeByBranch } =
    useSelector(selectAuth); // authenticated user slice in redux
  const [pageLoading, setPageLoading] = useState(false); // page loading state
  const [alert, setAlert] = useState({
    show: false,
    severity: "warning",
    message: VALUE_EMPTY_STRING
  }); // top alert state
  const [message20259, setMessage20259] = useState(""); // message 20259 state
  const [message26108, setMessage26108] = useState(""); // message 26108 state
  const [message26109, setMessage26109] = useState(""); // message 26109 state
  const [timeZoneOffsetChanged, setTimeZoneOffsetChanged] = useState(false); // timezone offset value changed
  const currentDate = useMemo(() => {
    let date = dayjs.utc(new Date());
    if (timeZoneOffset !== null) {
      date = date.utcOffset(parseFloat(timeZoneOffset));
    }
    setTimeZoneOffsetChanged((prevState) => !prevState);
    return date;
  }, [timeZoneOffset]); // minimum date
  const maximumDate = useMemo(() => currentDate.add(1, "year"), [currentDate]); // maximum date
  const [personnels, setPersonnels] = useState([]); // personnels associated with customer
  const [serviceLocations, setServiceLocations] = useState([]); // customer associated service locations
  const [authorizedByKey, setAuthorizedByKey] = useState(false); // authorized by field key
  const [selectedAuthorizedBy, setSelectedAuthorizedBy] = useState({
    open: false,
    personnelId: VALUE_EMPTY_STRING,
    personnelName: VALUE_EMPTY_STRING,
    isAuthenticated: false
  });
  const [haveChanges, setHaveChanges] = useState(false); // form values changed from initial values or not
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges); // browser back and forward blocker hook

  // form initial values
  const formInitialValues = {
    serviceDate: currentDate,
    authorizedBy: {
      label: VALUE_EMPTY_STRING,
      value: VALUE_EMPTY_STRING
    },
    serviceLocation: serviceLocation.recoverySite,
    customerLocation: VALUE_EMPTY_STRING,
    requestComments: VALUE_EMPTY_STRING
  };

  // form validation schema
  const formValidationSchema = Yup.object().shape({
    serviceDate: Yup.date().test(
      "service-date-invalid",
      t("disasterRecovery.eventDetail.serviceDateError"),
      (serviceDate) => {
        if (
          serviceDate !== undefined &&
          serviceDate !== VALUE_EMPTY_STRING &&
          serviceDate !== null
        ) {
          return (
            dayjs(serviceDate).isSameOrAfter(currentDate, "day") &&
            dayjs(serviceDate).isSameOrBefore(maximumDate, "day")
          );
        }
        return false;
      }
    ),
    authorizedBy: Yup.object().test(
      "personnel-required",
      message20259.replace("|", t("common.authorizedBy")),
      (personnel) =>
        personnel?.value !== undefined &&
        personnel?.value !== VALUE_EMPTY_STRING &&
        selectedAuthorizedBy.isAuthenticated
    ),
    serviceLocation: Yup.string(),
    recoverySite: Yup.string(),
    customerLocation: Yup.string().test(
      "service-location-required",
      message20259.replace(
        "|",
        t("disasterRecovery.eventDetail.customerLocationLabel")
      ),
      (location, context) => {
        if (
          context.parent?.serviceLocation === serviceLocation.customerLocation
        ) {
          return location !== undefined && location !== VALUE_EMPTY_STRING;
        }
        return true;
      }
    ),
    requestComments: Yup.string().required(
      message20259.replace(
        "|",
        t("disasterRecovery.eventDetail.otherDeliveryInfo")
      )
    )
  });

  // form submit event handler
  const handleFormOnSubmit = async (values) => {
    setPageLoading((prevState) => true);
    const success = await generateRequest(values);
    setPageLoading((prevState) => false);
    if (success) {
      handleCancelButtonOnClick();
      confirmNavigation();
    }
  };

  // form
  const otherRequestForm = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: handleFormOnSubmit
  });

  // init page title
  const initPageTitle = async () => {
    if (Number(customerId) > 0) {
      const customer = await getCustomerByID(customerId);
      setRouteData((prevState) => ({
        eventId: drEventId,
        customerNumber: customer[0]?.number?.trim()
      }));
    }
  };

  // fetch customer associated authorize personnels
  const fetchPersonnels = async () => {
    if (Number(customerId) > 0) {
      try {
        const response = await getAuthorizationPersonnels({ customerId });
        setPersonnels((prevState) =>
          response.map((item) => ({
            label: item?.name,
            value: item?.personnel_id
          }))
        );
      } catch (error) {
        setAlert((prevState) => ({
          show: true,
          message: findErrorMessage(ERROR_TYPES.ISSUE),
          severity: "error"
        }));
      }
    } else {
      // customer_id is not available
      const response = await getMessageFromCode(errorMsgs.errorCode10094);
      setAlert((prevState) => ({
        show: true,
        severity: "warning",
        message: response[0]?.descr
      }));
    }
  };

  // fetch customer associated service locations
  const fetchServiceLocations = async () => {
    if (Number(customerId) > 0) {
      try {
        const response = await getEligibleRequestLocations({
          customerId,
          dayOfWeekCode: getDayOfWeekCode(otherRequestForm.values.serviceDate)
        });
        setServiceLocations((prevState) =>
          response.map((item) => ({
            label: item?.customer_number?.trim(),
            value: item?.customer_id
          }))
        );
        // set customer_id as defult selected
        await otherRequestForm.setFieldValue("customerLocation", customerId);
      } catch (error) {
        setAlert((prevState) => ({
          show: true,
          message: findErrorMessage(ERROR_TYPES.ISSUE),
          severity: "error"
        }));
      }
    }
  };

  // service date chnage event handler
  const handleServiceDateOnChange = async (date) => {
    if (date !== null) {
      await otherRequestForm.setFieldValue("serviceDate", date);
      await otherRequestForm.setFieldTouched("serviceDate", true);
    } else {
      await otherRequestForm.setFieldValue("serviceDate", currentDate);
      setTimeZoneOffsetChanged((prevState) => !prevState);
    }
  };

  // authorized by personnel change event handler
  const handleAuthorizedByOnChange = async (_, newSelection) => {
    setAlert((prevState) => ({
      show: false,
      message: VALUE_EMPTY_STRING,
      severity: "warning"
    }));

    if (newSelection !== null) {
      try {
        setPageLoading((prevState) => true);
        const requestBody = JSON.stringify({
          main_district_id: currentBranch?.district_id,
          request_type_id: numberConstants.six,
          request_action_id: numberConstants.eight,
          customer_id: customerId,
          personnel_id: newSelection?.value
        });
        const checkAuthResponse = await getResponseData(
          requestBody,
          CF_URLS.requestDetail.checkAuthorization,
          2
        );
        const checkAuthData = checkAuthResponse.data[0];
        if (checkAuthData[0]?.valid_authorization_flag === N_CHECKER) {
          // selected personnel doesn't have necessary permission
          const messageResponse = await getMessageFromCode(
            errorMsgs.errorCode26087
          );
          setAlert((prevState) => ({
            show: true,
            severity: "warning",
            message: messageResponse[0]?.descr
          }));
          return;
        }
      } catch (error) {
        setAlert((prevState) => ({
          show: true,
          message: findErrorMessage(ERROR_TYPES.ISSUE),
          severity: "error"
        }));
        return;
      } finally {
        setPageLoading((prevState) => false);
      }

      setSelectedAuthorizedBy((prevState) => ({
        open: true,
        personnelId: newSelection?.value,
        personnelName: newSelection?.label,
        isAuthenticated: false
      }));
      await otherRequestForm.setFieldValue("authorizedBy", newSelection, false);
    } else {
      setSelectedAuthorizedBy((prevState) => ({
        open: false,
        personnelId: VALUE_EMPTY_STRING,
        personnelName: VALUE_EMPTY_STRING,
        isAuthenticated: false
      }));
      await otherRequestForm.setFieldValue(
        "authorizedBy",
        {
          label: VALUE_EMPTY_STRING,
          value: VALUE_EMPTY_STRING
        },
        false
      );
      setAuthorizedByKey((prevState) => !prevState);
    }
  };

  // auth request modal submit event handler
  const handleAuthRequestModalOnSubmit = async () => {
    setSelectedAuthorizedBy((prevState) => ({
      ...prevState,
      open: false,
      isAuthenticated: true
    }));
    setAuthorizedByKey((prevState) => !prevState);
    await otherRequestForm.setTouched();
  };

  // cancel button click event handler
  const handleCancelButtonOnClick = () => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}/${drEventId}`,
      {
        replace: true,
        state: location?.state
      }
    );
  };

  // generate request passing form values
  const generateRequest = async (formValues) => {
    try {
      const requestBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        customer_id: customerId,
        day_of_week_code: getDayOfWeekCode(formValues.serviceDate),
        service_date: formValues.serviceDate.format(yearMonthDayFormat),
        employee_id: getAuthenticatedUserId(),
        employee_comment: VALUE_EMPTY_STRING,
        personnel_id: formValues.authorizedBy.value,
        personnel_comment: VALUE_EMPTY_STRING,
        csl_customer_id:
          formValues.serviceLocation === serviceLocation.recoverySite
            ? customerId
            : formValues.customerLocation,
        system_function_id: generateOtherRequestSystemFunctionId,
        open_media_list: VALUE_EMPTY_STRING,
        container_list: VALUE_EMPTY_STRING,
        last_mod_user: getAuthenticatedUserName(),
        dr_event_id: drEventId,
        dr_site_name: VALUE_EMPTY_STRING,
        request_action_id: REQUEST_ACTIONS.OTHER.ID.toString(),
        comment: formValues.requestComments
      });
      const response = await getResponseData(
        requestBody,
        CF_URLS.disasterRecovery.createRequest,
        1
      );
      const error = response.error;
      if (error) {
        const messageResponse = await getMessageFromCode(error);
        setAlert((prevState) => ({
          show: true,
          severity: "warning",
          message: messageResponse[0]?.descr
        }));
        window.scrollTo({ top: 0 });
        return false;
      }

      setHaveChanges((prevState) => false);
      return true;
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        message: findErrorMessage(ERROR_TYPES.ISSUE),
        severity: "error"
      }));
      window.scrollTo({ top: 0 });
      return false;
    }
  };

  // save changes confirm modal YES click event handler
  const handleSaveChangesOnYesClick = async () => {
    cancelNavigation();
    otherRequestForm.submitForm();
  };

  // save changes confirm modal NO click event handler
  const handleSaveChangesOnNoClick = () => {
    confirmNavigation();
  };

  // init
  useEffect(() => {
    if (routeParams?.id && routeParams?.customerId) {
      setPageLoading((prevState) => true);
      Promise.allSettled([
        initPageTitle(),
        fetchPersonnels(),
        fetchServiceLocations(),
        getMessageFromCode(errorMsgs.errorCode20259),
        getMessageFromCode(errorMsgs.errorCode26107),
        getMessageFromCode(errorMsgs.errorCode26108),
        getMessageFromCode(errorMsgs.errorCode26109)
      ]).then((responses) => {
        // set message 20259 to state
        const response3 = responses[3].value;
        setMessage20259((prevState) => response3[0]?.descr);

        // set message 26017 to form
        const response4 = responses[4].value;
        otherRequestForm.setFieldValue("recoverySite", response4[0]?.descr);

        // set message 26108 to state
        const response5 = responses[5].value;
        setMessage26108((prevState) => response5[0]?.descr);

        // set message 26109 to state
        const response6 = responses[6].value;
        setMessage26109((prevState) => response6[0]?.descr);

        setPageLoading((prevState) => false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams]);

  // form values changed
  useEffect(() => {
    setHaveChanges(
      (prevState) =>
        !(
          otherRequestForm.values?.serviceDate ===
            formInitialValues.serviceDate &&
          otherRequestForm.values?.authorizedBy?.value ===
            formInitialValues.authorizedBy.value &&
          otherRequestForm.values?.serviceLocation ===
            formInitialValues.serviceLocation &&
          otherRequestForm.values?.recoverySite ===
            formInitialValues.recoverySite &&
          otherRequestForm.values?.customerLocation ===
            formInitialValues.customerLocation &&
          otherRequestForm.values?.requestComments ===
            formInitialValues.requestComments
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherRequestForm.values]);

  return (
    <>
      {/* Page loading */}
      {pageLoading && <CircularLoaderFullPage loading={pageLoading} />}

      {/* Collapsing Alert */}
      {alert.show && (
        <Collapse in={alert.show}>
          <Alert
            severity={alert.severity}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setAlert((prevState) => ({
                    show: false,
                    severity: "warning",
                    message: VALUE_EMPTY_STRING
                  }));
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alert.message}
          </Alert>
        </Collapse>
      )}

      <Grid container>
        <Grid container gap={spacing.gap}>
          <Typography
            sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
            mb={spacing.gap10}
          >
            {t("disasterRecovery.eventDetail.serviceInfo")}
          </Typography>

          <Grid container direction={"column"} gap={spacing.gap}>
            <Grid container gap={spacing.gap}>
              {/* ---------- Service Date ---------- */}
              <Grid item xs={12} md={6} lg={3}>
                <DatePickerField
                  id="serviceDate"
                  name="serviceDate"
                  key={timeZoneOffsetChanged}
                  label={`${t(
                    "disasterRecovery.eventDetail.serviceDateLabel"
                  )} *`}
                  locale={localeByBranch}
                  variant="input"
                  value={otherRequestForm.values.serviceDate}
                  minDate={currentDate}
                  maxDate={maximumDate}
                  onChange={handleServiceDateOnChange}
                  error={
                    Boolean(otherRequestForm.errors?.serviceDate) &&
                    Boolean(otherRequestForm.touched?.serviceDate)
                  }
                  helperText={
                    Boolean(otherRequestForm.touched?.serviceDate) &&
                    otherRequestForm.errors?.serviceDate
                  }
                />
              </Grid>

              {/* ---------- Authorized By selection ---------- */}
              <Grid item xs={12} md={6} lg={3}>
                <Autocomplete
                  id="authorizedBy"
                  name="authorizedBy"
                  key={authorizedByKey}
                  options={personnels}
                  size="medium"
                  value={otherRequestForm.values.authorizedBy}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  noOptionsText={t(
                    "disasterRecovery.eventDetail.noPersonnelRecordsFound"
                  )}
                  fullWidth
                  onChange={handleAuthorizedByOnChange}
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  clearIcon={<CloseIcon />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        "disasterRecovery.eventDetail.authorizedByLabel"
                      )}
                      required
                      error={
                        Boolean(otherRequestForm.errors?.authorizedBy) &&
                        Boolean(otherRequestForm.touched?.authorizedBy)
                      }
                      helperText={
                        Boolean(otherRequestForm.touched?.authorizedBy) &&
                        otherRequestForm.errors?.authorizedBy
                      }
                    />
                  )}
                />
              </Grid>

              {/* Request authorization status */}
              {selectedAuthorizedBy.isAuthenticated && (
                <Grid id="mediaRequestDetailGrid20" item xs={12} md={6} lg={3}>
                  <Typography
                    id="mediaRequestDetailAuthenticationStatus"
                    variant="subtitle1"
                    color="success"
                    sx={
                      disasterRecoveryStyles.requestGeneratorStyles
                        .authenticatedText
                    }
                  >
                    <CheckIcon id="icnAuthorize" marginRight={"4px"} />
                    {t("common.authenticated")}
                  </Typography>
                </Grid>
              )}
            </Grid>

            {selectedAuthorizedBy.open && (
              <Grid container>
                {/* Authorization Modal */}
                <PersonnelAuthorizationModal
                  setLoading={setPageLoading}
                  authorizedBy={selectedAuthorizedBy.personnelId}
                  onSubmit={handleAuthRequestModalOnSubmit}
                />
              </Grid>
            )}

            {/* Service Location Selection */}
            <Grid container>
              <Grid item xs={12}>
                <FormLabel
                  style={disasterRecoveryStyles.commonStyles.textLabel}
                >
                  {t("disasterRecovery.eventDetail.serviceLocationLabel")}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    name="serviceLocation"
                    value={otherRequestForm.values.serviceLocation}
                    onChange={otherRequestForm.handleChange}
                  >
                    <FormControlLabel
                      value={serviceLocation.recoverySite}
                      control={<Radio />}
                      label={t(
                        "disasterRecovery.eventDetail.recoverySiteLabel"
                      )}
                    />
                    <FormControlLabel
                      value={serviceLocation.customerLocation}
                      control={<Radio />}
                      label={t(
                        "disasterRecovery.eventDetail.customerLocationLabel"
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container gap={spacing.gap}>
              {/* ---------- Recovery Site ----------   */}
              {otherRequestForm.values.serviceLocation ===
                serviceLocation.recoverySite && (
                <Grid item xs={12} md={6} lg={3}>
                  <Typography>
                    {otherRequestForm.values.recoverySite}
                  </Typography>
                </Grid>
              )}

              {/* ---------- Customer Location ----------  */}
              {otherRequestForm.values.serviceLocation ===
                serviceLocation.customerLocation && (
                <Grid item xs={12} md={6} lg={3}>
                  <SelectField
                    id="customerLocation"
                    name="customerLocation"
                    required
                    label={t(
                      "disasterRecovery.eventDetail.customerLocationLabel"
                    )}
                    options={serviceLocations}
                    value={otherRequestForm.values.customerLocation}
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    onChange={otherRequestForm.handleChange}
                    error={
                      Boolean(otherRequestForm.errors?.customerLocation) &&
                      Boolean(otherRequestForm.touched?.customerLocation)
                    }
                    helperText={
                      Boolean(otherRequestForm.touched?.customerLocation) &&
                      otherRequestForm.errors?.customerLocation
                    }
                  />
                </Grid>
              )}
            </Grid>

            <Typography style={disasterRecoveryStyles.commonStyles.subHeading}>
              {t("disasterRecovery.eventDetail.otherRequestOrPickup")}
            </Typography>

            <Grid container gap={spacing.gap}>
              <Grid item xs={12}>
                <FormLabel>{message26108}</FormLabel>
              </Grid>
              <Grid item xs={12} gap={spacing.gap}>
                <TextField
                  label={t("disasterRecovery.eventDetail.comments")}
                  id="requestComments"
                  name="requestComments"
                  fullWidth
                  multiline
                  required
                  rows={3}
                  value={otherRequestForm?.values?.requestComments}
                  onChange={otherRequestForm.handleChange}
                  InputLabelProps={{
                    shrink: otherRequestForm?.values?.requestComments
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength: 254
                    }
                  }}
                  error={
                    Boolean(otherRequestForm.errors?.requestComments) &&
                    Boolean(otherRequestForm.touched?.requestComments)
                  }
                  helperText={
                    Boolean(otherRequestForm.touched?.requestComments) &&
                    otherRequestForm.errors?.requestComments
                  }
                />
              </Grid>
            </Grid>
            <Divider />
            {/* Bottom Action Buttons */}
            <Stack direction="row" spacing={spacing.gap}>
              {/* Find Request button */}
              <Button
                id="cancelButton"
                variant="outlined"
                color="primary"
                size="medium"
                type="button"
                onClick={handleCancelButtonOnClick}
              >
                {t("common.cancel")}
              </Button>

              {/* Assign Transport button */}
              <Button
                id="generateRequestButton"
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                onClick={otherRequestForm.submitForm}
              >
                {t("disasterRecovery.eventDetail.generateRequestText")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      {/* Confirm Save Changes Modal */}
      <ConfirmPopup
        modalPopupOpen={showPrompt}
        message={message26109}
        handleYes={handleSaveChangesOnYesClick}
        showNo={true}
        handleNo={handleSaveChangesOnNoClick}
        showCancel={false}
      />
    </>
  );
};

export default OtherRequest;
