import { Stack } from "@mui/material";
import Issurance from "components/container-processing/container-issuance/Issuance";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const ContainerIssuerance = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("continerIssurance.title")} />
      <Issurance />
    </Stack>
  );
};
export default ContainerIssuerance;
