/**
 * Exported data fetching functions to use with rtk query
 */
import { auth } from "./auth";
import { common } from "./common";
import { customer } from "./customer";
import { destructionRequestSearch } from "./request-module/destructionRequestSearch";
import { mediaDestruction } from "./request-module/mediaDestruction";
import { requestDetail } from "./request-module/requestDetail";
import { requestDetailDelivery } from "./request-module/requestDetailDelivery";
import { requestSearch } from "./request-module/requestSearch";
import { rescheduleService } from "./request-module/rescheduleService";
import { vaultItemized } from "./request-module/vaultItemized";

// common data fetching functions
export const {
  getAllBranches,
  getAllInsertTypes,
  getAllLockTypes,
  getAllRequestStatuses,
  getAllMediaTypes,
  getLoginSuccessInfo,
  getServiceLocations,
  getAuthorizationPersonnelsAuthentications,
  getServiceLocationByCustomer,
  getDestructionRequestDetail,
  getCustomerDataById,
  getMessageById,
  getPersonnelAuthCust,
  getAuthNumber,
  getAuthorizationDetail,
  getValidateChallengeResponse,
  getGlobalAttributeValueById,
  getGlobalAttributeValueByTypeId,
  getMessageByIdFromMessage,
  getLocaleByBranch
} = common;

// authentication functions
export const { login } = auth;

// customer specific data fetching functions
export const {
  getPersonnelAuth,
  getAllDestructMedia,
  getDestructRequestDetail,
  getAllDestructMediaTypes,
  getAuthorizationPersonnels,
  getAllCustomers
} = customer;

// data fetching functions specific to request media detail feature
export const {
  checkRequestAuthorization,
  getScheduledServiceDates,
  getServiceAuthComments,
  getAddonCutoffValues,
  getBranchBillingSystem,
  getEligibleRequestLocations,
  getAllLogicalVaults,
  getAllServiceLocations,
  getAllTripTypes,
  validateDeliverMediaOpenMediaNumber,
  getOpenMediaPossibleMatches,
  validateDeliverMediaContainerNumber,
  getRequiredInsertsCount,
  validateDeleteContainerNumber,
  getAllDeletableContainers,
  saveMediaRequest,
  updateMediaRequest,
  findMediaRequest,
  loadMediaRequestOpenMedias,
  loadMediaRequestContainers,
  loadMediaRequestTransports,
  loadMediaRequestIssueContainers,
  loadMediaRequestDeleteContainers,
  assignTransportToRequest,
  checkCancelRequest,
  cancelMediaRequest,
  getPricingContractFlag,
  checkDiscrepancies,
  getBypassReason
} = requestDetail;

// data fetching functions specific to request media detail delivery feature
export const {
  getServiceLocation,
  getCustomerMdrDetails,
  getAllPersonnels,
  getAllDestructionStatusTypes,
  getDesReqComment,
  postNewDestructRequest,
  postUpdateDestructRequest,
  cancelDestructRequest,
  destructRequestCommentInsert,
  destructRequestCommentDelete,
  verifyPersonnelPermissionStatus,
  getServiceLocationByCustomerTrigger
} = requestDetailDelivery;

// data fetching functions specific to request media detail search feature
export const {
  getAllRequestTypes,
  getAllRoutes,
  searchRequest,
  getAllSearchRequestStatuses
} = requestSearch;

export const {
  getTrackingRequirements,
  getDispositionServices,
  getBoxTypes,
  getCustomerInfo,
  getDestructRequestDetails,
  getSpecialHandling,
  getBoxTypesCount,
  getRequestComments,
  getUnitOfMeasures,
  getCustomerServiceLocation,
  saveSitadRequest,
  saveScheduleRequest,
  insertRequestBoxTypeCount,
  insertRequestComment,
  insertRequestSpecialHandling,
  deleteRequestBoxTypeCount,
  deleteRequestComment,
  cancelRequest,
  getRunRequestStatus,
  verifyPersonnelPermission,
  uploadFile
} = mediaDestruction;

// data fetching functions specific to media destruction request media detail search feature
export const {
  mediaDestructionRequestSearch,

  mediaDestructionGetAllRequestStatus,
  mediaDestructionGetAllRequestTypes
} = destructionRequestSearch;

//data fetching functions specific to reschedule service detail feature
export const {
  getScheduleDate,
  getCustLocationInfo,
  getRequestInfo,
  getRouteInfo,
  getRunInfo,
  getCslInfo,
  assignTransportsToCustomer,
  rescheduleRequests
} = rescheduleService;

export const {
  getUnitOfMeasure,
  getValidateOpenMediaList,
  getPossibleMatchesList,
  createMDRVaultItemized,
  getExistingVaultItemizedMDR,
  cancelMDRVaultItemized,
  updateMDRVaultItemized,
  getPersonnelSecurity
} = vaultItemized;
