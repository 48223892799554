import spacing from "styles/spacing";

export const AuthRequestModalStyles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto !important"
  },
  card: {
    marginX: "auto",
    marginY: spacing.gap10,
    bgcolor: "background.paper",
    boxShadow: 24,
    width: "1000px !important"
  },
  alert: { marginTop: "10px", fontWeight: "500" },
  error: {
    borderRadius: "9px",
    marginTop: spacing.horizontalMargin20
  },
  authorizationTitle: {
    color: "var(--color-black)",
    fontFamily: "Roboto, sans-serif",
    margin: 0,
    fontSize: "24px",
    fontWeight: "400",
    textTransform: "capitalize",
    lineHeight: "133.4%",
    paddingTop: "0",
    paddingBottom: spacing.verticalMargin20
  },
  authorizationType: {
    marginTop: "1.25rem",
    marginLeft: "2rem",
    marginBottom: "0.625px"
  },
  requestTitle: {
    color: "var(--color-black)",
    fontFamily: "Roboto, sans-serif",
    margin: 0,
    fontSize: "24px",
    fontWeight: "400",
    textTransform: "capitalize",
    lineHeight: "133.4%",
    paddingTop: spacing.verticalMargin20,
    paddingBottom: spacing.verticalMargin20
  },
  progressIcon: {
    color: "inherit",
    width: "20px !important",
    height: "20px !important",
    marginRight: ".5rem"
  },
  FlexShrinkNoGrow: { display: "flex", flexGrow: "0", flexShrink: "1" },
  AuthenticatedText: {
    display: "flex",
    color: "var(--success-light, #4CAF50)",
    marginTop: 1
  },
  cardActionsStyle_PRSPopUP: {
    display: "flex",
    padding: "16px",
    alignItems: "flex-end",
    gap: "8px",
    alignSelf: "stretch"
  },
  buttonStyle_PRSPopUP: {
    display: "flex",
    padding: "6px 16px !important",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "initial",
    marginLeft: "0px !important"
  },
  alertStyle: {
    borderRadius: "9px"
  }
};
