import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
// import { ReactComponent as ArrowDownDisabled } from "assets/images/ArrowDownDisabled.svg";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { authSection } from "utils/constants";
import { personnelDetailStyles } from "./PersonnelDetailStyles";

const PDCustomerDetailsSection = ({
  personalDetailForm,
  allCountries,
  onCustomerDetailsCountryChange,
  handleChangeContractOwner,
  handleChangeDiscrepancyContact,
  handleChangeDRAOne,
  handleChangeDRATwo,
  handleChangeDRAThree,
  handleChangeDRAFour,
  handleChangeIAOne,
  handleChangeIATwo,
  handleChangeIAThree,
  handleChangeIAFour,
  handleChangeIAFive,
  handleChangeIASix,
  valuePersonnelAuth,
  allBranches,
  onBranchChange,
  allCustomers,
  onCustomerChange,
  isFildesDissabled,
  isPersonnelDisabled,
  isInteractionEditable,
  isDREditable,
  handlebusinessPhoneField,
  requiredFieldValidations,
  personnalID,
  isAuthenticated,
  selectedCustomers,
  isAddingANewCustomer,
  handleFieldChanges
}) => {
  const { t } = useTranslation();

  const showBranchAndCustomer = () => {
    if (valuePersonnelAuth === authSection?.bypass) {
      return true;
    }

    if (valuePersonnelAuth === authSection?.personnelAuth && !personnalID) {
      return false;
    }

    if (valuePersonnelAuth === authSection?.personnelAuth && isAuthenticated) {
      return false;
    }

    return true;
  };

  const showCheckBoxes = () => {
    if (valuePersonnelAuth === authSection?.personnelAuth && !personnalID) {
      return false;
    }

    if (valuePersonnelAuth === authSection?.personnelAuth && isAuthenticated) {
      return false;
    }

    return true;
  };

  const dissableCheckBoxesIA = () => {
    if (
      !personalDetailForm?.values?.customerDetailCustomer &&
      selectedCustomers?.length === 0
    ) {
      return true;
    }

    if (
      isAuthenticated &&
      personnalID &&
      valuePersonnelAuth === authSection?.personnelAuth &&
      !selectedCustomers?.length
    ) {
      return true;
    }

    if (
      isAuthenticated &&
      !personnalID &&
      valuePersonnelAuth === authSection?.personnelAuth &&
      !selectedCustomers?.length
    ) {
      return true;
    }

    if (
      isAuthenticated &&
      personnalID &&
      valuePersonnelAuth === authSection?.bypass &&
      personalDetailForm?.values?.customerDetailCustomer === null
    ) {
      return true;
    }

    if (
      !personnalID &&
      valuePersonnelAuth === authSection?.bypass &&
      personalDetailForm?.values?.customerDetailCustomer !== null &&
      Object.keys(personalDetailForm.values.customerDetailCustomer).length === 0
    ) {
      return true;
    }

    if (
      !personnalID &&
      valuePersonnelAuth === authSection?.bypass &&
      (personalDetailForm?.values?.customerDetailCustomer === null ||
        personalDetailForm?.values?.customerDetailCustomer === "")
    ) {
      return true;
    }

    if (isFildesDissabled() || !isInteractionEditable) {
      return true;
    }

    return false;
  };

  const dissableCheckBoxesDR = () => {
    if (
      !personalDetailForm?.values?.customerDetailCustomer &&
      selectedCustomers?.length === 0
    ) {
      return true;
    }

    if (
      isAuthenticated &&
      personnalID &&
      valuePersonnelAuth === authSection?.personnelAuth &&
      !selectedCustomers?.length
    ) {
      return true;
    }

    if (
      isAuthenticated &&
      !personnalID &&
      valuePersonnelAuth === authSection?.personnelAuth &&
      !selectedCustomers?.length
    ) {
      return true;
    }

    if (
      isAuthenticated &&
      personnalID &&
      valuePersonnelAuth === authSection?.bypass &&
      personalDetailForm?.values?.customerDetailCustomer === null
    ) {
      return true;
    }

    if (
      !personnalID &&
      valuePersonnelAuth === authSection?.bypass &&
      personalDetailForm?.values?.customerDetailCustomer !== null &&
      Object.keys(personalDetailForm.values.customerDetailCustomer).length === 0
    ) {
      return true;
    }

    if (
      !personnalID &&
      valuePersonnelAuth === authSection?.bypass &&
      (personalDetailForm?.values?.customerDetailCustomer === null ||
        personalDetailForm?.values?.customerDetailCustomer === "")
    ) {
      return true;
    }

    if (isFildesDissabled() || !isDREditable) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Typography variant="h5">
        {t("personnelDetail.customerDetailsTitle")}
      </Typography>

      <Grid container spacing={spacing.gap}>
        {showBranchAndCustomer() && (
          <>
            <Grid item xs={3} sm={3}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="branchSelectLabel">
                    {t("personalSearch.branch")}
                  </InputLabel>
                  <Select
                    labelId="branchSelectLabel"
                    id="branchSelect"
                    value={personalDetailForm.values.customerDetailBranch}
                    label={t("personalSearch.branch")}
                    onChange={onBranchChange}
                    disabled={isPersonnelDisabled()}
                  >
                    {allBranches?.length > 0 &&
                      allBranches?.map((b) => {
                        return (
                          <MenuItem value={b} key={b?.label}>
                            {b?.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={3} sm={3}>
              <Box>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="customerSelectLabel"
                    options={allCustomers}
                    onChange={(event, newValue) => {
                      onCustomerChange(
                        event,
                        newValue,
                        "customerDetailCustomer"
                      );
                    }}
                    value={personalDetailForm.values.customerDetailCustomer}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("personnelDetail.customer")}
                        error={requiredFieldValidations?.customerDetailCustomer}
                        helperText={
                          requiredFieldValidations?.customerDetailCustomer &&
                          t("personnelDetail.requiredFeildCustomer")
                        }
                      />
                    )}
                    disabled={isPersonnelDisabled()}
                  />
                </FormControl>
              </Box>
            </Grid>
          </>
        )}

        {/* Customer Employee Number */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="customerEmployeeNumber"
              name="customerEmployeeNumber"
              label={t("personnelDetail.customerEmpNumLabel")}
              fullWidth
              value={personalDetailForm.values.customerEmployeeNumber}
              onChange={personalDetailForm.handleChange}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 15
              }}
            />
          </Box>
        </Grid>

        {valuePersonnelAuth === authSection?.bypass && (
          <Grid item xs={3} sm={3}></Grid>
        )}

        {/* Country/Phone Code Selection */}
        <Grid item xs={3} sm={3}>
          <Box>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              clearIcon={<CloseIcon />}
              id="customers-list"
              name="customer"
              options={allCountries}
              onChange={(event, newValue) => {
                onCustomerDetailsCountryChange(event, newValue);
              }}
              value={personalDetailForm.values.customerDetailSelectedCountry}
              size="medium"
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("personnelDetail.countryLabel")}
                />
              )}
              disabled={isPersonnelDisabled()}
            />
          </Box>
        </Grid>

        {/* Business Phone */}
        <Grid item xs={2} sm={2}>
          <Box>
            <TextField
              id="businessPhone"
              name="businessPhone"
              label={t("personnelDetail.businessPhoneLabelMando")}
              fullWidth
              value={personalDetailForm.values.businessPhone}
              onChange={(e) => handlebusinessPhoneField(e)}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 25
              }}
              error={requiredFieldValidations?.businessPhone}
              helperText={
                requiredFieldValidations?.businessPhone &&
                t("personnelDetail.requiredFeildBusinessPhone")
              }
              shrink={personalDetailForm.values.businessPhone}
              notched={personalDetailForm.values.businessPhone}
            />
          </Box>
        </Grid>

        {/* Extension */}
        <Grid item xs={1} sm={1}>
          <Box>
            <TextField
              id="extension"
              name="extension"
              label={t("personnelDetail.extensionLabel")}
              fullWidth
              value={personalDetailForm.values.extension}
              onChange={(e) => {
                handleFieldChanges(e, "extension");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 6
              }}
              error={requiredFieldValidations?.extension}
            />
          </Box>
        </Grid>

        {/* Business Fax */}
        <Grid item xs={3} sm={3}>
          <Box>
            <TextField
              id="businessFax"
              name="businessFax"
              label={t("personnelDetail.businessFaxLabel")}
              fullWidth
              value={personalDetailForm.values.businessFax}
              onChange={(e) => {
                handleFieldChanges(e, "businessFax");
              }}
              disabled={isPersonnelDisabled()}
              inputProps={{
                maxLength: 25
              }}
              error={requiredFieldValidations?.businessFax}
            />
          </Box>
        </Grid>

        {showCheckBoxes() && (
          <>
            <Grid item xs={3} sm={3}>
              <Box></Box>
            </Grid>

            {valuePersonnelAuth !== authSection?.bypass && (
              <Grid item xs={3} sm={3}>
                <Box></Box>
              </Grid>
            )}
          </>
        )}

        {/* Contract Owner checkbox */}
        <Grid item xs={3} sm={3}>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={personalDetailForm.values.isContractOwnerChecked}
                    onChange={handleChangeContractOwner}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={isPersonnelDisabled()}
                  />
                }
                label={t("personnelDetail.contractOwnerLabel")}
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Discrepancy Contact checkbox */}
        <Grid item xs={3} sm={3}>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      personalDetailForm.values.isDiscrepancyContactChecked
                    }
                    onChange={handleChangeDiscrepancyContact}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={isPersonnelDisabled()}
                  />
                }
                label={t("personnelDetail.discrepancyContactLabel")}
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={spacing.gap}>
        {/* -------------- Interaction Authorization -------------- */}
        <Grid item xs={6} sm={6}>
          <Typography
            variant="body2"
            sx={personnelDetailStyles.customerDetailStyles.subTitleColor}
          >
            {t("personnelDetail.interactionAuthMando")}
          </Typography>

          <Stack container ml={1}>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isIAOneChecked}
                      onChange={handleChangeIAOne}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesIA()}
                    />
                  }
                  label={t("personnelDetail.ALabel")}
                />
              </FormGroup>
            </Box>

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isIATwoChecked}
                      onChange={handleChangeIATwo}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesIA()}
                    />
                  }
                  label={t("personnelDetail.BLabel")}
                />
              </FormGroup>
            </Box>

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isIAThreeChecked}
                      onChange={handleChangeIAThree}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesIA()}
                    />
                  }
                  label={t("personnelDetail.CLabel")}
                />
              </FormGroup>
            </Box>

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isIAFourChecked}
                      onChange={handleChangeIAFour}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesIA()}
                    />
                  }
                  label={t("personnelDetail.DLabel")}
                />
              </FormGroup>
            </Box>

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isIAFiveChecked}
                      onChange={handleChangeIAFive}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesIA()}
                    />
                  }
                  label={t("personnelDetail.ELabel")}
                />
              </FormGroup>
            </Box>

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isIASixChecked}
                      onChange={handleChangeIASix}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesIA()}
                      sx={
                        personalDetailForm.values.isIATwoChecked && {
                          "&.Mui-checked": {
                            color: "red"
                          }
                        }
                      }
                    />
                  }
                  label={t("personnelDetail.RLabel")}
                />
              </FormGroup>
            </Box>

            {personalDetailForm.values.isIATwoChecked &&
              personalDetailForm.values.isIASixChecked && (
                <Alert
                  sx={personnelDetailStyles.customerDetailStyles.alertStyle}
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                    ></IconButton>
                  }
                >
                  {t("personnelDetail.brError")}
                </Alert>
              )}
          </Stack>
        </Grid>

        {/* -------------- DR Authorization -------------- */}
        <Grid item xs={6} sm={6}>
          <Typography
            variant="body2"
            sx={personnelDetailStyles.customerDetailStyles.subTitleColor}
          >
            {t("personnelDetail.drAuthLabelMando")}
          </Typography>

          <Stack container ml={1}>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isDRAOneChecked}
                      onChange={handleChangeDRAOne}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesDR()}
                    />
                  }
                  label={t("personnelDetail.OneLabel")}
                />
              </FormGroup>
            </Box>

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isDRATwoChecked}
                      onChange={handleChangeDRATwo}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesDR()}
                    />
                  }
                  label={t("personnelDetail.TwoLabel")}
                />
              </FormGroup>
            </Box>

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isDRAThreeChecked}
                      onChange={handleChangeDRAThree}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesDR()}
                    />
                  }
                  label={t("personnelDetail.ThreeLabel")}
                />
              </FormGroup>
            </Box>

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={personalDetailForm.values.isDRAFourChecked}
                      onChange={handleChangeDRAFour}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={dissableCheckBoxesDR()}
                    />
                  }
                  label={t("personnelDetail.FourLabel")}
                />
              </FormGroup>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default PDCustomerDetailsSection;
