export const billingRulesInterfaceStyles = {
  classificationStyles: {
    findButton: {
      "&:disabled": {
        color: "#FFFFFF",
        backgroundColor: "rgba(0, 0, 0, 0.12)"
      }
    },
    gridContainer: {
      mb: 3
    },
    itemContainer: {
      width: "100%",
      maxWidth: "310px"
    }
  },
  dataTableStyles: {
    muiDataTableStyles: {
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      borderRadius: "9px"
    },
    boxStyles: {
      mt: "33px"
    },
    tableHeaderLabel: {
      fontWeight: "700",
      color: "rgba(0, 0, 0, 0.60)"
    },
    tableBodyLabel: {
      color: "rgba(0, 0, 0, 0.87)",
      paddingLeft: "0px"
    },
    chip: {
      m: "0px 8px 8px 0px"
    },
    typography: {
      fontSize: "16px"
    },
    jumpToPage: {
      color: "rgba(0, 0, 0, 0.60)"
    },
    customHeader: {
      fontWeight: "700",
      fontSize: "14px !important",
      color: "rgba(0, 0, 0, 0.6)",
      backgroundColor: "rgba(245, 245, 245, 1)",
      padding: "6px 0px"
    },
    PrintButton: {
      mb: "15px",
      mt: "10px"
    }
  }
};
