/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Stack,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import { CircularLoaderFullPage } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  downloadReportOptions,
  errorMsgs
} from "utils/constants";
import {
  batchTypeFlagForArrival,
  batchTypeFlagForBatchs,
  fifteen,
  hashChecker,
  keyValues,
  seventeen,
  twenty
} from "utils/constants/container-processing/Distribution";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getUserName
} from "utils/helpers";
import {
  UploadErrors,
  commonLogic,
  distributeallcontainersbatch,
  distributionRepots,
  getArrivalService,
  getBatchScanService,
  getcommondata,
  notArrivalBatch,
  validateBatchImport,
  validateBatchImportTwo,
  validateDistributionNotArrival
} from "./Services";

import UploadErrorsPage from "./UploadErrorsPage";
const { default: MUIDataTable } = require("mui-datatables");

const Distribution = () => {
  // intialstates
  const [isLoading, setIsLoading] = useState(false);
  const [batchScanDropdownData, setBatchScanDropdownData] = useState([]);
  const [arrivalDropdownData, setArrivalDropdownData] = useState([]);
  const [selectedBatchScan, setSelectedBatchScan] = useState("");
  const [selectedArrival, setSelectedArrival] = useState("");
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [tableGridData, setTableGridData] = useState([]);
  const [throwError, setThrowError] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [flag, setIsFlag] = useState({
    printButtonDisable: false,
    printEnable: false,
    findButtonDisable: false
  });
  const [errorTextMessage, setErrorTextMessage] = useState("");
  const [errorScreen, setErrorScreen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(keyValues.batchScan);
  const [getCommonData, setGetCommonData] = useState({});
  const [warningModal, setWarningModal] = useState(false);
  const [updatedContainerNumbers, setUpdatedContainerNumbers] = useState("");
  const [inValidContailnersModal, setInValidContainersModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(downloadReportOptions[0]);
  const { currentBranch, auth, localeByBranch } = useSelector(selectAuth);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  //  get batchScreen dropdown data service
  const getBatchScanRes = async () => {
    setIsLoading(true);
    try {
      let res = await getBatchScanService(mainDistrictId);
      if (res?.data) {
        let sortedDataDescending = res?.data[0]?.sort(
          (a, b) =>
            new Date(b.generated_datetime) - new Date(a.generated_datetime)
        );
        setBatchScanDropdownData(sortedDataDescending);
      }
      if (res?.data[0].length > 0) {
        setSelectedOption(keyValues.batchScan);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBatchScanRes();
  }, [mainDistrictId]);

  //  get Arrival dropdown data service
  const getArrivalRes = async () => {
    setIsLoading(true);
    try {
      let res = await getArrivalService(mainDistrictId);
      if (res?.data) {
        let sortedDataDescending = res?.data[0]?.sort(
          (a, b) => new Date(b.batch_datetime) - new Date(a.batch_datetime)
        );
        setArrivalDropdownData(sortedDataDescending);
      }
      if (res?.data[0].length > 0) {
        setSelectedOption(keyValues.arrival);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getArrivalRes();
  }, [mainDistrictId]);
  // this function is get the formaters  for legends
  const getCommonDataService = async () => {
    try {
      setIsLoading(true);
      let res = await getcommondata(mainDistrictId);
      if (res) {
        setGetCommonData(res?.data[0][0]);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCommonDataService();
  }, [mainDistrictId]);

  const HandleChange = async (value) => {
    setSelectedOption(value);
    setThrowError(false);
    setTableGridData([]);
    setSelectedBatchScan("");
    setSelectedArrival("");
    setIsFlag((prev) => ({
      ...prev,
      printEnable: false,
      findButtonDisable: false
    }));
    if (value === keyValues.batchScan && mainDistrictId) {
      await getBatchScanRes();
    } else {
      if (mainDistrictId) {
        await getArrivalRes();
      }
    }
  };
  // clear functionality
  const handleClear = async () => {
    if (selectedOption === keyValues.batchScan) {
      setTableGridData([]);
      setBatchScanDropdownData([]);
      setSelectedBatchScan("");
      setThrowError(false);
      await getBatchScanRes();
      setSelectedOption(keyValues.batchScan);
    } else {
      setTableGridData([]);
      setArrivalDropdownData([]);
      setSelectedArrival("");
      setThrowError(false);
      await getArrivalRes();
      if (arrivalDropdownData.length <= 0) {
        setSelectedOption(keyValues.batchScan);
      } else {
        setSelectedOption(keyValues.arrival);
      }
    }
  };
  //   batch scan table data grid sp
  const batchScanService = async () => {
    try {
      setIsLoading(true);
      if (selectedBatchScan) {
        let res =
          selectedBatchScan.batch_id > 0
            ? await validateBatchImportTwo(
                mainDistrictId,
                selectedBatchScan.batch_id
              )
            : validateBatchImport(mainDistrictId);
        if (res) {
          if (res?.data) {
            let updatedData = commonLogic(res?.data[1] || [], getCommonData);

            setTableGridData(updatedData);
          }
          if (Number(res?.data[0][0]?.error_code) > 0) {
            let errorMsg = await getMessageFromCode(res?.data[0]?.error_code);
            let replacedText = errorMsg[0]?.descr.replace(
              "|",
              res?.data[0]?.error_descr
            );

            setThrowErrorMessage(
              replacedText || findErrorMessage(ERROR_TYPES.ISSUE)
            );
            setThrowError(true);
          }
        }
        if (res?.data[2]?.length > 0) {
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode80419);

          let replacedText = errorMsg[0]?.descr.replace(
            "|",
            t("containerProcessing.distribution.invalidStatus")
          );
          setThrowErrorMessage(
            replacedText || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);

          setPrintModal(true);
          setIsFlag((prev) => ({ ...prev, printEnable: true }));
        }
        if (res?.data[3]?.length > 0) {
          let formatedText = res?.data[3].map(
            (item) =>
              `${t("containerProcessing.distribution.custHash")} ${
                item.container_number
              } - ${t("containerProcessing.distribution.customerhash")} ${
                item.customer_number
              }\n`
          );
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode62536);
          let replacedText = errorMsg[0]?.descr.replace("|", formatedText);
          setThrowErrorMessage(
            replacedText || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setInValidContainersModal(true);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  // arrival  table data grid Sp
  const arrivalService = async () => {
    try {
      setIsLoading(true);
      if (selectedArrival) {
        let res = await validateDistributionNotArrival(
          mainDistrictId,
          selectedArrival.batch_id
        );

        if (res) {
          if (res?.data) {
            let updatedData = commonLogic(res?.data[0] || [], getCommonData);
            setTableGridData(updatedData);
          }
          if (Number(res?.data[0][0]?.error_code) > 0) {
            let errorMsg = await getMessageFromCode(res?.data[0]?.error_code);
            setThrowErrorMessage(
              errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
            );
            setThrowError(true);
          }
        }
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  // upload error screen table data grid Sp
  const uploadErrorsService = async (batch_id, run_name) => {
    try {
      setIsLoading(true);
      let res = await UploadErrors(mainDistrictId, batch_id, run_name);
      if (res) {
        if (res?.data) {
          let updatedData = commonLogic(res?.data[0] || [], getCommonData);
          setTableGridData(updatedData);
        }
        if (res?.data[0]?.length > 0) {
          setErrorScreen(true);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  // find button functionality
  const handleFindClick = async () => {
    if (selectedOption === keyValues.batchScan) {
      if (!selectedBatchScan?.employee_name) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
        let batchScanError = errorMsg[0]?.descr.replace(
          "|",
          t("containerProcessing.distribution.batch")
        );
        setThrowErrorMessage(
          batchScanError || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError(true);
      } else {
        await batchScanService();
      }
    } else {
      if (!selectedArrival?.employee_name) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
        let batchScanError = errorMsg[0]?.descr.replace(
          "|",
          t("containerProcessing.distribution.arrival")
        );
        setThrowErrorMessage(
          batchScanError || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError(true);
      } else {
        await arrivalService();
      }
    }
  };
  //  batch Scan onChange function
  const handleBatchScanChange = (event) => {
    let value = event.target.value;
    setIsFlag((prev) => ({ ...prev, findButtonDisable: false }));
    setSelectedBatchScan(value);
    if (value) {
      setThrowError(false);
      setTableGridData([]);
    }
  };
  //  additional  action for the batch and arrival selections
  const handleItemSelection = () => {
    setThrowError(false);
    setTableGridData([]);
    setIsFlag((prev) => ({
      ...prev,
      findButtonDisable: false,
      printButtonDisable: false,
      printEnable: false
    }));
  };

  //  arrival onChange functionality
  const handleArrivalChange = async (event) => {
    let value = event.target.value;
    setTableGridData([]);
    setIsFlag((prev) => ({ ...prev, findButtonDisable: false }));
    setSelectedArrival(value);
    if (value) {
      setThrowError(false);
      await uploadErrorsService(value.batch_id, value.run_name);
    }
  };
  //  print functionality SP
  const distributeallcontainersbatchService = async () => {
    try {
      setIsLoading(true);
      let res = selectedBatchScan.batch_id
        ? await distributeallcontainersbatch(
            mainDistrictId,
            selectedBatchScan.batch_id
          )
        : await notArrivalBatch(mainDistrictId, selectedArrival.batch_id);
      if (res) {
        if (res?.data[0]?.length <= 0) {
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode62521);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);

          setTableGridData([]);
        } else {
          let showWarningModal;
          const updatedPromise = res?.data[0]?.map(async (item) => {
            let slottingLocation = item?.slotting_location;
            const isCart = item?.Cart_Flag === Y_CHECKER;
            const isDeleted = item?.Exchange_Status_Id === fifteen;
            const displaySlotFlag = getCommonData?.display_container_slot_flag;

            if (!slottingLocation) {
              let reprintMessage = await getMessageFromCode(
                errorMsgs.errorCode80419
              );

              let replacedText = reprintMessage[0]?.descr.replace(
                "|",
                t("containerProcessing.distribution.distribution")
              );
              setThrowErrorMessage(
                replacedText || findErrorMessage(ERROR_TYPES.ISSUE)
              );
              let errorMsg = await getMessageFromCode(errorMsgs.errorCode62547);
              setErrorTextMessage(
                errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
              );
              setUpdatedContainerNumbers(
                item.customer_number?.includes(hashChecker)
                  ? item?.customer_number
                  : `${t("containerProcessing.distribution.custHash")} ${
                      item?.customer_number
                    } - ${
                      item.container_number?.includes(hashChecker)
                        ? item?.container_number
                        : `${t(
                            "containerProcessing.distribution.containerHash"
                          )} ${item?.container_number}`
                    }`
              );

              setThrowError(true);

              showWarningModal = true;
            }
            if (isCart || isDeleted || slottingLocation === "") {
              slottingLocation = t("containerProcessing.distribution.noSlots");
            } else if (displaySlotFlag === Y_CHECKER) {
              if (slottingLocation.length === twenty) {
                slottingLocation = slottingLocation.slice(0, -3);
              }
            } else if (displaySlotFlag === N_CHECKER) {
              if (slottingLocation.length >= seventeen) {
                // Get the first 13 characters
                slottingLocation = slottingLocation.slice(0, 13);
              }
            }

            return {
              ...item,
              slotting_location: slottingLocation
            };
          });
          const updatedData = await Promise.all(updatedPromise);
          if (updatedData.length > 0) {
            setTableGridData(updatedData);
            setIsFlag((prev) => ({ ...prev, printEnable: false }));
          } else {
            setTableGridData([]);
          }

          if (showWarningModal) {
            setWarningModal(true);
          } else {
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode80419);
            let replacedText = errorMsg[0]?.descr.replace(
              "|",
              t("containerProcessing.distribution.distribution")
            );
            setThrowErrorMessage(
              replacedText || findErrorMessage(ERROR_TYPES.ISSUE)
            );

            setThrowError(true);
            if (updatedData.length > 0) {
              setPrintModal(true);
            }
          }
        }
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  // print
  const handlePrint = async () => {
    await distributeallcontainersbatchService();
  };
  const handleWarning = async () => {
    let errorMsg = await getMessageFromCode(errorMsgs.errorCode80419);
    let replacedText = errorMsg[0]?.descr.replace(
      "|",
      t("containerProcessing.distribution.distribution")
    );
    setThrowErrorMessage(replacedText || findErrorMessage(ERROR_TYPES.ISSUE));
    setWarningModal(false);
    setPrintModal(true);
  };

  //  columns for table
  const batchScanColumns = [
    {
      name: "customer_number",
      label: t("containerProcessing.distribution.customer")
    },
    {
      name: "short_descr",
      label: t("containerProcessing.distribution.mediaType")
    },
    {
      name: "container_number",
      label: t("containerProcessing.distribution.cont")
    },
    {
      name: "slotting_location",
      label: t("containerProcessing.distribution.location")
    },
    {
      name: "exchange_status_type",
      label: t("containerProcessing.distribution.status")
    },
    {
      name: "discrepancy_descr",
      label: t("containerProcessing.distribution.discrepancy")
    }
  ];
  const arrivalColumns = [
    {
      name: "customer_number",
      label: t("containerProcessing.distribution.customer")
    },
    {
      name: "short_descr",
      label: t("containerProcessing.distribution.mediaType")
    },
    {
      name: "container_number",
      label: t("containerProcessing.distribution.cont")
    },
    {
      name: "slotting_location",
      label: t("containerProcessing.distribution.location")
    },
    {
      name: "exchange_status_type",
      label: t("containerProcessing.distribution.status")
    },
    {
      name: "discrepancy",
      label: t("containerProcessing.distribution.discrepancy")
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: 0 && true,
    textLabels: {
      body: {
        noMatch:
          tableGridData?.length === 0 && `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (row, dataIndex) => {
      const rowData = tableGridData[dataIndex];
      const completedFlag = rowData ? rowData.completed_flag : null;
      return {
        style: {
          backgroundColor:
            dataIndex === 2
              ? ContainerProcessingStyles?.selectedColor
              : completedFlag === Y_CHECKER
                ? ContainerProcessingStyles?.completeColor
                : ""
        }
      };
    }
  };
  //  download functionality
  const downloadHandler = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      let res = await distributionRepots(
        mainDistrictId,
        getUserName(auth?.user),
        selectedDoc?.type?.toUpperCase(),
        selectedBatchScan?.batch_id
          ? selectedBatchScan.batch_id
          : selectedArrival.batch_id,
        selectedBatchScan?.batch_id
          ? batchTypeFlagForBatchs
          : batchTypeFlagForArrival
      );
      if (!res.success) {
        setThrowErrorMessage(res.error);
        setThrowError(true);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // print modal close handler
  const handlePrintClose = async () => {
    setPrintModal(false);
    setThrowError(false);

    if (flag.printEnable) {
      setIsFlag((prev) => ({ ...prev, printButtonDisable: false }));
    } else {
      setIsFlag((prev) => ({
        ...prev,
        printButtonDisable: true,
        findButtonDisable: true
      }));
    }
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {inValidContailnersModal && (
        <ConfirmPopup
          modalPopupOpen={inValidContailnersModal}
          message={throwErrorMessage}
          handleYes={() => setInValidContainersModal(false)}
          showCancel={false}
          showNo={false}
          showOk={true}
        />
      )}

      {warningModal && (
        <CommonModal
          open={warningModal}
          title={t("containerProcessing.distribution.containerDistribution")}
          body={
            <Stack direction="column">
              <Alert severity="error" icon={null}>
                {errorTextMessage}
              </Alert>
              <Grid spacing={2} sx={ContainerProcessingStyles.gridTextBorder}>
                <Grid item={12}>{updatedContainerNumbers}</Grid>
              </Grid>
            </Stack>
          }
          buttons={
            <>
              <Button variant="contained" onClick={handleWarning}>
                {t("common.ok")}
              </Button>
            </>
          }
        />
      )}

      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="warning"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      {printModal && (
        <DownloadPopup
          title={t("containerProcessing.distribution.downloadReport")}
          showPrintModal={printModal}
          downloadOptions={downloadReportOptions}
          onCancleClick={handlePrintClose}
          onDownloadClick={downloadHandler}
          setSelectedValue={setSelectedDoc}
        />
      )}
      {errorScreen ? (
        <>
          <UploadErrorsPage
            options={options}
            mainDistrictId={mainDistrictId}
            userName={getUserName(auth?.user)}
            batchId={selectedArrival.batch_id}
            reportFormat={selectedDoc?.type?.toUpperCase()}
            setErrorScreen={setErrorScreen}
            data={tableGridData}
            setTableGridData={setTableGridData}
            setSelectedDoc={setSelectedDoc}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </>
      ) : (
        <>
          <Typography sx={ContainerProcessingStyles.fontColor}>
            {t("containerProcessing.distribution.process")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6} lg={2} xs={12}>
              <FormControlLabel
                value={t("containerProcessing.distribution.batchScan")}
                control={
                  <Radio
                    checked={selectedOption === keyValues.batchScan}
                    onChange={() => HandleChange(keyValues.batchScan)}
                  />
                }
                label={t("containerProcessing.distribution.batchScan")}
              />
            </Grid>
            <Grid item md={6} lg={2} xs={12}>
              <FormControlLabel
                value={t("containerProcessing.distribution.arrival")}
                control={
                  <Radio
                    checked={selectedOption === keyValues.arrival}
                    onChange={() => HandleChange(keyValues.arrival)}
                  />
                }
                label={t("containerProcessing.distribution.arrival")}
              />
            </Grid>
          </Grid>
          <br />
          {selectedOption === keyValues.batchScan && (
            <Grid container spacing={2}>
              <Grid item md={8} lg={3} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <>
                    <InputLabel>
                      {t("containerProcessing.distribution.batchScanStar")}
                    </InputLabel>
                    <Select
                      label={t(
                        "containerProcessing.distribution.batchScanStar"
                      )}
                      name={"Alllabel"}
                      value={selectedBatchScan}
                      onChange={handleBatchScanChange}
                    >
                      {batchScanDropdownData?.map((item) => {
                        const dateFormate = dateTimeFormatByLocale(
                          item?.generated_datetime,
                          localeByBranch
                        );

                        return (
                          <MenuItem
                            value={item}
                            onClick={handleItemSelection}
                            key={item?.employee_name}
                          >
                            {dateFormate} - {item?.employee_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {selectedOption === keyValues.arrival && (
            <Grid container spacing={2}>
              <Grid item md={8} lg={3} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <>
                    <InputLabel>
                      {t("containerProcessing.distribution.arrivalStar")}
                    </InputLabel>
                    <Select
                      label={t("containerProcessing.distribution.arrivalStar")}
                      name={"Arrival"}
                      value={selectedArrival}
                      onChange={handleArrivalChange}
                    >
                      {arrivalDropdownData?.map((item) => {
                        return (
                          <MenuItem
                            value={item}
                            onClick={handleItemSelection}
                            key={item?.employee_name}
                          >
                            {item?.batch_datetime} - {item?.employee_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <br />
          <Stack direction="row" gap={spacing.gap}>
            <Button variant="outlined" onClick={handleClear}>
              {t("common.clear")}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={flag.findButtonDisable}
              onClick={handleFindClick}
            >
              {t("common.find")}
            </Button>
          </Stack>
          <br />

          <Paper>
            {tableGridData?.length > 0 && (
              <Box sx={ContainerProcessingStyles.tableRecords}>
                <Typography>
                  {tableGridData?.length === 1
                    ? `${tableGridData?.length}  ${t(
                        "containerProcessing.distribution.recordFound"
                      )}`
                    : `${tableGridData?.length}  ${t(
                        "containerProcessing.distribution.recordsFound"
                      )}`}
                </Typography>
                <Box sx={ContainerProcessingStyles.marginLeftAuto}>
                  <Button
                    variant="outlined"
                    disabled={flag.printButtonDisable}
                    onClick={handlePrint}
                  >
                    {t("common.print")}
                  </Button>
                </Box>
              </Box>
            )}
            <TableContainer>
              <MUIDataTable
                columns={
                  selectedOption === keyValues.batchScan
                    ? batchScanColumns
                    : arrivalColumns
                }
                options={options}
                data={tableGridData}
              />
            </TableContainer>
          </Paper>

          <Box sx={ContainerProcessingStyles.marginTop15}>
            {getCommonData?.display_container_slot_flag === N_CHECKER && (
              <>{t("containerProcessing.distribution.n_legend")}</>
            )}
            {getCommonData?.display_container_slot_flag === Y_CHECKER && (
              <>{t("containerProcessing.distribution.y_legend")}</>
            )}
          </Box>
        </>
      )}
    </>
  );
};
export default Distribution;
