import { t } from "i18next";

export const loaderTypes = {
  dropdowns: "dropdowns",
  employees: "employees",
  employeeList: "employeeList",
  save: "save",
  add: "add"
};
export const securityGroupText = t("notifications.securityGroup");
export const employeeText = t("notifications.employee");
export const failureText = t("notifications.failureText");
export const severityInfo = "info";
export const severityError = "error";
export const severitySuccess = "success";
