import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { Form, Formik } from "formik";
import i18n from "i18n";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import {
  getCustomersQueryData,
  getMessageFromCode,
  getReasonsQueryData
} from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  GLOBAL_ATTRIBUTE_VALUES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  errorMsgs,
  messageCode,
  system_id
} from "utils/constants";
import { SYSTEM_FUNCTION_ID_SAVE_OPEN_MEDIA } from "utils/constants/open-media-processing/CreateDistributionListConstants";
import {
  customerFilter,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getResponseData
} from "utils/helpers";
import { createDisributionListStyles } from "./CreateDisributionListStyles";
import CreateDistributionListModal from "./CreateDistributionListModal";
import CreateDistributionListTable from "./CreateDistributionListTable";

const CreateDisributionList = () => {
  const [isPageViewOnly, setIsPageViewOnly] = useState(true);

  const [isLogicalVaultSelected, setIsLogicalVaultSelected] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [reasonError, setReasonError] = useState(null);
  const [vaultError, setVaultError] = useState(null);
  const [slotRequiredError, setSlotRequiredError] = useState(null);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);

  // checkbox handling throughout the module
  const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  // New Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // state holding customers
  const [customerList, setCustomerList] = useState([]);

  // state holding customer's vault list
  const [vaultList, setVaultList] = useState([]);

  // state holding reasons
  const [reasonList, setReasonList] = useState([]);

  // state holding selected customer
  const [selectedCustomer, setSelectedCustomer] = useState({});

  // state holding whether customer selected
  const [isSelectedCustomer, setIsSelectedCustomer] = useState(false);

  // state holding selected reasons
  const [selectedReason, setSelectedReason] = useState(null);

  // state holding selected vault
  const [selectedVault, setSelectedVault] = useState();

  // flag for slot available or not
  const [isSlotAvailable, setIsSlotAvailable] = useState(false);

  // array holding table data
  const [tableData, setTableData] = useState([]);

  // media validation error message
  const [mediaValidation, setMediaValidation] = useState({
    message: "",
    maxLength: ""
  });

  // media required msg
  const [mediaRequired, setMediaRequired] = useState(null);

  // slot validation message
  const [slotValidation, setSlotValidation] = useState("");
  const [clearSlot, setClearSlot] = useState(false);

  // dialog box
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [dialogMessage, setDialogMessage] = useState("");

  // confirm pop up
  const [isSavebuttonClicked, setIsSaveButtonClicked] = useState(false);

  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState(VALUE_EMPTY_STRING);

  // state for N checker so enable/disable the checkbox
  const [hasNChecker, setHasNchecker] = useState(false);

  // edit modal
  const [isEdit, setIsEdit] = useState(false);
  const [editRowData, setEditRowData] = useState({});

  const { currentBranch } = useSelector(selectAuth);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  // create a distributionBlock string
  function createDistributionBlock(gridItems) {
    let distributionBlock = "";
    gridItems.forEach((item) => {
      let mediaNumber = item?.mediaNumber ?? "";
      let returnDate = item?.returnDate ?? "";
      let slot = item?.slot ?? "";
      let drpCode = item?.drpCode ?? "";
      let description = item?.description ?? "";
      mediaNumber += " ".repeat(20 - mediaNumber?.length);
      returnDate += " ".repeat(10 - returnDate?.length);
      slot += " ".repeat(6 - slot?.length);
      drpCode += " ".repeat(10 - drpCode?.length);
      description += " ".repeat(80 - description?.length);
      distributionBlock +=
        mediaNumber + returnDate + slot + drpCode + description;
    });

    return distributionBlock;
  }

  const validateDistributionList = useCallback(() => {
    let error = {
      reason: false,
      vault: false,
      slot: false,
      media: false
    };

    if (!selectedReason?.value) {
      error.reason = true;
      setReasonError(i18n.t("createDistributionList.reasonRequiredError"));
    } else if (selectedReason?.value) {
      error.reason = false;
      setReasonError("");
    }
    if (!selectedVault?.value && isLogicalVaultSelected) {
      error.vault = true;
      setVaultError(i18n.t("createDistributionList.logicalVaultRequiredError"));
    } else if (selectedVault?.value) {
      error.vault = false;
      setVaultError("");
    }
    const missingSlot =
      tableData.length === 0 || tableData.some((row) => !row.slot);
    const missingMediaNumber =
      tableData.length === 0 || tableData.some((row) => !row.mediaNumber);

    if (missingSlot && isLogicalVaultSelected) {
      setSlotRequiredError(i18n.t("createDistributionList.slotRequiredError"));
      error.slot = true;
    } else {
      error.slot = false;
      setSlotRequiredError("");
    }
    if (missingMediaNumber) {
      setMediaRequired(
        i18n.t("createDistributionList.mediaNumberRequiredError")
      );
      error.media = true;
    } else {
      error.media = false;
      setMediaRequired("");
    }

    return error;
  }, [selectedReason, selectedVault, isLogicalVaultSelected, tableData]);

  // Load customers
  const getCustomers = async () => {
    setIsLoading(true);
    try {
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      //Filter customers
      result = customerFilter(result);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id.trim(),
          number: number.trim(),
          districtIid: districtId
        }));
        setCustomerList((prev) => data);
        setIsLoading(false);
      } else {
        setCustomerList([]);
        setIsLoading(false);
      }
    } catch (e) {
      setCustomerList([]);
      setIsLoading(false);
      setThrowError(true);
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getReasons = async () => {
    try {
      let result = await getReasonsQueryData();
      if (result) {
        const data = result?.map((value) => ({
          label: value?.descr.trim(),
          value: value?.dropdown_list_id.trim()
        }));
        setReasonList((prev) => data);
      }
    } catch (error) {
      setReasonList([]);
    }
  };

  const getMediaNumberMaxLength = useCallback(async () => {
    const missingMediaNumber =
      tableData.length === 0 || tableData.some((row) => !row.mediaNumber);
    if (tableData.length && missingMediaNumber) {
      try {
        let maxLength = await getGlobalAttributeValue(
          mainDistrictId,
          GLOBAL_ATTRIBUTE_VALUES.VALUE_60,
          GLOBAL_ATTRIBUTE_VALUES.VALUE_94
        );
        let mediaMessageRequired = await getMessageFromCode(
          messageCode.requiredField
        );
        let slot = await getMessageFromCode(errorMsgs.errorCode25349);
        setMediaValidation({
          message: t("createDistributionList.volserMaxMsg"),
          maxLength: maxLength
        });
        setMediaRequired(
          mediaMessageRequired[0]?.descr.replace(
            "|",
            t("createDistributionList.mediaNumber")
          )
        );
        setSlotValidation(slot[0]?.descr);
      } catch (error) {
        setReasonList([]);
        setIsLoading(false);
        setSelectedReason(null);
        setThrowError(true);
        setThrowErrorMessage(
          error.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
      }
    }
  }, [tableData, mainDistrictId]);

  const onLogicalVaultCheckboxSelected = (e) => {
    setIsCheckboxChecked(e.target.checked);
    if (e.target.checked) {
      setIsLogicalVaultSelected(true);
      setSelectedVault(null);
      setHasNchecker(false); // Disable Import button, enable New button
    } else {
      setIsLogicalVaultSelected(false);
      setIsSlotAvailable(false);
      setHasNchecker(true); // Enable both buttons
    }
  };

  const getVaultDataPerCustomer = async (value) => {
    try {
      setIsPageViewOnly(false);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        customer_id: value?.value,
        display_all: Y_CHECKER,
        display_media_type: Y_CHECKER
      });

      setIsPageViewOnly(false);
      return await getResponseData(
        requestBody,
        `${CF_URLS.openMediaProcessing.logicalVaultAuditSearch}`,
        1
      );
    } catch (err) {
      return err;
    }
  };
  const handleCustomerSelect = async (e, value) => {
    if (
      tableData?.length &&
      tableData?.length !== 0 &&
      isSavebuttonClicked === false
    ) {
      await getMessageFromCode(errorMsgs.errorCode10039)
        .then((res) => {
          setShowWarning(true);
          setWarningMessage(res[0]?.descr);
        })
        .catch((e) => console.log(e));
    } else {
      try {
        setIsLoading(true);
        setThrowError(false);
        setVaultList([]);
        setSelectedVault();
        setIsLogicalVaultSelected(false);
        setIsSlotAvailable(false);
        setIsSelectedCustomer(true);
        setSelectedCustomer(value);
        setTableData([]);
        setEditRowData({});
        setIsCheckboxChecked(false);
        setSelectedReason(null);
        setHasNchecker(false);

        if (value && value?.value) {
          let data = await getVaultDataPerCustomer(value);
          await getReasons();

          if (data && data?.data && data?.data[0] && data?.data[0]?.length) {
            const vaultData = data?.data[0];
            setOpenDialogBox(false);
            setDialogMessage("");
            const allYChecker = vaultData.every(
              (vault) => vault.customer_assigned_slot_flag === Y_CHECKER
            );
            const hasNChecker = vaultData.some(
              (vault) => vault.customer_assigned_slot_flag === N_CHECKER
            );

            const res = vaultData
              ?.map((value) =>
                value.customer_assigned_slot_flag === Y_CHECKER
                  ? {
                      label: value?.code,
                      value: value?.logical_vault_id
                    }
                  : null
              )
              .filter((value) => value !== null);
            setVaultList((prev) => res);
            vaultData.forEach((v) => {
              if (allYChecker) {
                setIsLogicalVaultSelected(true);
                setHasNchecker(false);
                setIsCheckboxChecked(true);
                setIsCheckboxVisible(false);
                setIsLoading(false);
                return;
              } else if (hasNChecker) {
                setHasNchecker(true);
                setIsSlotAvailable(false);
                setIsLogicalVaultSelected(false);
                setIsCheckboxVisible(true);
                setIsCheckboxChecked(false);
                setIsLoading(false);
                return;
              } else {
                setIsLogicalVaultSelected(false);
                setHasNchecker(false);
                setIsSlotAvailable(false);
                setIsCheckboxChecked(false);
                setIsCheckboxVisible(true);
                setIsLoading(false);
                return;
              }
            });
          } else if (data?.error && data.error !== 0 && data.error !== null) {
            getMessageFromCode(errorMsgs.errorCode61014)
              .then((res) => {
                setOpenDialogBox(true);
                setDialogMessage(res[0]?.descr);
                setIsLoading(false);
              })
              .catch((e) => console.log(e));
            setIsSlotAvailable(false);
            setIsLogicalVaultSelected(false);
            return;
          } else {
            getMessageFromCode(errorMsgs.errorCode63455)
              .then((res) => {
                setOpenDialogBox(true);
                setDialogMessage(res[0]?.descr);
                setIsLoading(false);
              })
              .catch((e) => console.log(e));
            setIsSlotAvailable(false);
            setIsLogicalVaultSelected(false);
            setHasNchecker(false);
            setIsCheckboxChecked(false);
            setIsCheckboxVisible(false);
            return;
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setThrowError(true);
        setThrowErrorMessage(
          error?.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        return;
      }
    }
  };

  const customersFieldProps = {
    options: customerList,
    handleOnChange: handleCustomerSelect,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label,
    value: isSelectedCustomer ? selectedCustomer : "",
    disabled: false,
    required: true
  };

  useEffect(() => {
    getMediaNumberMaxLength();
    getCustomers();
    //eslint-disable-next-line
  }, []);

  // Reason select event
  const handleReasonSelect = (e, value) => {
    setSelectedReason(value);
    setReasonError("");
  };

  //  Vault select event
  const handleVaultSelect = (e, value) => {
    setSelectedVault(value);
    setIsLogicalVaultSelected(true);
    setHasNchecker(false);
    setIsSlotAvailable(true);
    setVaultError("");
  };

  // handle click NO event
  const handleNo = () => {
    setIsPageViewOnly(false);
    setShowWarning(false);
    setTableData([]);
    setSelectedReason(null);
    setIsLogicalVaultSelected(false);
    setIsSlotAvailable(false);
    setSaveButtonEnabled(false);
    setIsCheckboxChecked(false);
    setHasNchecker(false);
    setThrowError(false);
    setWarningMessage(null);
    setIsLoading(false);
    setEditRowData({});
  };

  // form on Save event handler
  const handleOnSave = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);
    let distribution_item_block = await createDistributionBlock(tableData);
    if (isLogicalVaultSelected) {
      let vaultReqBody = JSON.stringify({
        logical_vault_id: isLogicalVaultSelected ? selectedVault?.value : "",
        customer_assigned_slot_flag: isLogicalVaultSelected
          ? Y_CHECKER
          : N_CHECKER,
        main_district_id: currentBranch?.id
      });
      let vaultValidation = await getResponseData(
        vaultReqBody,
        `${CF_URLS.openMediaProcessing.logicalVaultValidation}`,
        1
      );
      let errorcode = vaultValidation.data[0][0]?.error;
      if (errorcode) {
        if (
          errorcode === errorMsgs.errorCode20472 ||
          errorcode === errorMsgs.errorCode25097
        ) {
          await getMessageFromCode(errorcode)
            .then((res) => setVaultError(res[0]?.descr))
            .catch((e) => setThrowErrorMessage(e));
          await getVaultDataPerCustomer(selectedCustomer);
          setIsLoading(false);
          return;
        }
        if (errorcode === errorMsgs.errorCode25330) {
          await getMessageFromCode(errorMsgs.errorCode25330)
            .then((res) => {
              setThrowError(true);
              setVaultError(res[0]?.descr);
            })
            .catch((e) => setThrowErrorMessage(e));
          setClearSlot(true);
          setIsSlotAvailable(false);
          setIsLoading(false);
          return;
        } else if (errorcode === errorMsgs.errorCode25331) {
          setIsSlotAvailable(true);
          setIsLoading(false);
        }
      }
    }
    try {
      let { reason, vault, slot, media } = await validateDistributionList();
      if (reason || vault || slot || media) {
        setThrowError(true);
        setIsLoading(false);
        return;
      } else {
        setIsLoading(true);
        setThrowError(false);
        setReasonError("");
        setVaultError("");
        setSlotRequiredError("");
        setMediaRequired("");
        let reqBody = JSON.stringify({
          main_district_id: currentBranch?.id,
          customer_id: selectedCustomer?.value,
          system_id: system_id,
          system_function_id: SYSTEM_FUNCTION_ID_SAVE_OPEN_MEDIA,
          logical_vault_id: isLogicalVaultSelected ? selectedVault?.value : "",
          data_row_count: String(tableData?.length),
          distribution_item_block,
          customer_file_id: "",
          customer_file_section_definition_id: "",
          file_section_type_id: "",
          intervault_file_section_id: "",
          error_occurred_flag: "",
          delete_allowed_flag: "",
          picking_request_id: "",
          file_section_status_id: "",
          completed_date: "",
          reason_id: selectedReason?.value
        });

        await getResponseData(
          reqBody,
          `${CF_URLS.openMediaProcessing?.createDistributionList}`,
          1
        )
          .then(async (response) => {
            setThrowError(false);
            if (response?.data[0][0]?.error !== "0") {
              setIsLoading(false);
              setThrowError(true);
              const errMsg = await getMessageFromCode(
                response?.data[0][0]?.error
              );
              setThrowErrorMessage(errMsg[0]?.descr);
              return;
            }
            setIsSaveButtonClicked(true);
            setShowWarning(false);
            setWarningMessage(null);
            setIsLoading(false);
            setIsShowSuccessPopup(true);
            setThrowError(false);
            setSelectedReason(null);
            setSelectedCustomer(null);
            setTableData([]);
            setEditRowData({});
            setSelectedVault(null);
            setIsSlotAvailable(false);
            setIsLogicalVaultSelected(false);
            setSaveButtonEnabled(false);
            setIsCheckboxChecked(false);
          })
          .catch((error) => {
            setThrowError(true);
            setThrowErrorMessage(error || error[0]);
          });
      }
    } catch (err) {
      setIsLoading(false);
      setThrowError(true);
      setThrowErrorMessage(err);
    }
  };

  useEffect(() => {
    if (isLogicalVaultSelected && selectedVault) {
      setHasNchecker(true);
    } else if (isLogicalVaultSelected) {
      setHasNchecker(false);
    }
  }, [selectedVault, isLogicalVaultSelected]);

  const getThrowErrorMessageVisible = () =>
    throwErrorMessage ||
    reasonError ||
    vaultError ||
    slotRequiredError ||
    mediaRequired;

  useEffect(() => {
    const isSaveButtonEnable = async () => {
      if (tableData?.length !== 0) {
        setSaveButtonEnabled(false);
      }
    };
    isSaveButtonEnable();
  }, [tableData]);

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      {/*Success message popup*/}
      {isShowSuccessPopup && (
        <SuccessPopup
          message={t("CustomerFileSearch.updateMessage")}
          close={setIsShowSuccessPopup}
        />
      )}

      {showWarning && (
        <ConfirmPopup
          message={warningMessage}
          modalPopupOpen={showWarning}
          handleCancel={() => {
            setWarningMessage((prev) => VALUE_EMPTY_STRING);
            setShowWarning((prev) => false);
            setIsLogicalVaultSelected(false);
          }}
          handleNo={handleNo}
          handleYes={handleOnSave}
          showCancel={true}
          showNo={true}
        />
      )}

      {openDialogBox && (
        <Collapse in={openDialogBox}>
          <Alert
            severity="warning"
            icon={<img src={WarningIcon} alt="warning" />}
            sx={createDisributionListStyles.errorStyle}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenDialogBox(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {dialogMessage}
          </Alert>
        </Collapse>
      )}

      {throwError && getThrowErrorMessageVisible() && (
        <Collapse in={throwError && getThrowErrorMessageVisible()}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            sx={createDisributionListStyles.errorStyle}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage?.length ? throwErrorMessage : null} &nbsp;
            {reasonError?.length ? reasonError : null} &nbsp;
            {vaultError?.length ? vaultError : null} &nbsp;
            {slotRequiredError?.length ? slotRequiredError : null}&nbsp;
            {mediaRequired?.length ? mediaRequired : null}
          </Alert>
        </Collapse>
      )}
      <Formik>
        <Form>
          <Stack
            direction={createDisributionListStyles?.direction?.column}
            gap={createDisributionListStyles?.gap?.gap}
            marginTop={spacing.verticalMargin10}
          >
            <Stack
              direction={createDisributionListStyles?.direction?.row}
              gap={createDisributionListStyles?.gap?.gap}
            >
              {/* Customers Field */}
              <Grid sx={createDisributionListStyles?.fieldSize}>
                <CustomersField {...customersFieldProps} />
              </Grid>

              <Grid sx={createDisributionListStyles?.fieldSize}>
                <Autocomplete
                  disabled={isPageViewOnly}
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  clearIcon={<CloseIcon />}
                  id="reason"
                  name={t("common.reason")}
                  options={reasonList}
                  value={selectedReason}
                  onChange={(e, value) => {
                    handleReasonSelect(e, value);
                  }}
                  size="medium"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("common.reason")}
                      required
                    />
                  )}
                  sx={createDisributionListStyles.widthFull}
                />
              </Grid>
              {/* ---------- Counted Items CheckBox ----------  */}
              <Stack direction="row">
                <FormGroup>
                  <FormControlLabel
                    disabled={!isCheckboxVisible}
                    control={
                      <Checkbox
                        checked={isCheckboxChecked}
                        onChange={(e) => {
                          onLogicalVaultCheckboxSelected(e);
                        }}
                      />
                    }
                    label={t(
                      "createDistributionList.showCustomerAssignedSlotLogicalVaults"
                    )}
                  />
                </FormGroup>
              </Stack>
            </Stack>
            {isLogicalVaultSelected && (
              <Stack
                direction={createDisributionListStyles?.direction?.row}
                gap={createDisributionListStyles?.gap?.gap}
              >
                <Grid sx={createDisributionListStyles?.fieldSize}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="logical-vault"
                    name={t("createDistributionList.logicalVault")}
                    options={vaultList}
                    size="medium"
                    fullWidth
                    value={selectedVault}
                    onChange={(e, value) => {
                      handleVaultSelect(e, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("createDistributionList.logicalVault")}
                      />
                    )}
                    sx={createDisributionListStyles.widthFull}
                  />
                </Grid>
              </Stack>
            )}

            <CreateDistributionListTable
              handleModalOpen={handleModalOpen}
              isSlotAvailable={isSlotAvailable}
              tableData={tableData}
              setTableData={setTableData}
              setThrowError={setThrowError}
              setThrowErrorMessage={setThrowErrorMessage}
              throwError={throwError}
              setIsEdit={setIsEdit}
              EditRowData={editRowData}
              setEditRowData={setEditRowData}
              hasNChecker={hasNChecker}
              clearSlot={clearSlot}
            />
            <CreateDistributionListModal
              open={isModalOpen}
              closeModal={closeModal}
              setTableData={setTableData}
              isSlotAvailable={isSlotAvailable}
              mediaValidation={mediaValidation}
              slotValidation={slotValidation}
              mediaRequired={mediaRequired}
              tableData={tableData}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              editRowData={editRowData}
              setEditRowData={setEditRowData}
              isLogicalVaultSelected={isLogicalVaultSelected}
              clearSlot={clearSlot}
              vaultId={selectedVault?.value}
            />
          </Stack>
          <Divider />

          <Stack direction="row" sx={createDisributionListStyles.saveBtnStyle}>
            <Button
              id="saveBtn"
              variant="contained"
              style={createDisributionListStyles.saveBtnStyle}
              type="submit"
              onClick={(e) => handleOnSave(e)}
              disabled={saveButtonEnabled}
            >
              {t("common.save")}
            </Button>
          </Stack>
        </Form>
      </Formik>
    </>
  );
};

export default CreateDisributionList;
