import { Stack, Tooltip } from "@mui/material";
import LAYOUT_STYLES from "layouts/MainLayout.Styles";
import { Link } from "react-router-dom";
import {
  CONTAINER_PROCESSING_RESEARCH,
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  CORRECTIVE_ACTION_REQUESTS_SEARCH,
  CREATE_DISTRIBUTION_LIST,
  DISCREPANCY_MANAGEMENT,
  GENERAL,
  MEDIA_REQUEST_DETAIL,
  OPEN_MEDIA_PROCESSING_RESEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH,
  REPORTS_MODULE_BASE_PATH,
  REPORTS_MODULE_VAULT_PATH,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_SEARCH,
  RESCHEDULE_SERVICE,
  RUN_MANAGEMENT,
  SCHEDULE,
  VAULT_STATUS_REPORT,
  WORK_MANAGEMENT
} from "routing/Paths";
import * as Icons from "../../assets/images";

const iconAltText = [
  "Container Processing",
  "Open Media Work Management ",
  "Container Research",
  "Open Media Reseach",
  "Run Management",
  "Customer Schedule",
  "Request Search",
  "Reschedule Service",
  "Media Request",
  "Create Distribution List",
  "Corrective Action Request Search",
  "Corrective Action Request Detail",
  "Discrepancy Search",
  "Vault Status Report"
];

const routerLink = [
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${GENERAL}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_PROCESSING_RESEARCH}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_PROCESSING_RESEARCH}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${RUN_MANAGEMENT}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SCHEDULE}`,
  `${REQUEST_MODULE_BASE_PATH}/${REQUEST_SEARCH}`,
  `${REQUEST_MODULE_BASE_PATH}/${RESCHEDULE_SERVICE}`,
  `${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${CREATE_DISTRIBUTION_LIST}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
  `${REPORTS_MODULE_BASE_PATH}/${REPORTS_MODULE_VAULT_PATH}/${VAULT_STATUS_REPORT}`
];
const TopBar = () => {
  const icons = [
    Icons.BoxTimeIcon,
    Icons.ClipboardText,
    Icons.BoxSearch,
    Icons.WalletSearch,
    Icons.Vector,
    Icons.CalendarEdit,
    Icons.SMSSearch,
    Icons.TruckTime,
    Icons.UserTag,
    Icons.MenuBoard,
    Icons.ClipBoardTick,
    Icons.TaskSquare,
    Icons.RepeatCircle,
    Icons.Coin
  ];

  return (
    <Stack direction="row" spacing={3} sx={LAYOUT_STYLES.topbarStyles}>
      {icons.map((Icon, index) => (
        <Link to={routerLink[index]} key={routerLink[index]}>
          <Tooltip key={index} title={iconAltText[index]} arrow>
            <Icon alt={iconAltText[index]} />
          </Tooltip>
        </Link>
      ))}
    </Stack>
  );
};

export default TopBar;
