import { Stack } from "@mui/material";
import CustomerPreferences from "components/customer-information/preferences/preferences-main/CustomerPreferences";
import { PageTitle } from "components/shared";
import i18n from "i18n";

const Preferences = () => {
  return (
    <>
      <Stack>
        <PageTitle title={i18n.t("preferences.title")} />
        <CustomerPreferences />
      </Stack>
    </>
  );
};

export default Preferences;
