import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import CommonModal from "components/shared/common-modal/CommonModal";

import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCustomersQueryData } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  ALL_SELECTION,
  CLEAR,
  dateFormat,
  ERROR_TYPES,
  VALUE_EMPTY_STRING
} from "utils/constants";
import {
  customer,
  day,
  minusDate,
  month,
  transport
} from "utils/constants/reports-module/Reports";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";
import { temporaryTransport } from "../Services";

const TemporaryTransportHistory = ({
  isOpen,
  onCloseModal,
  setCommonDownloadModal,
  getCallbackData
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([ALL_SELECTION]);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const { localeByBranch, currentBranch } = useSelector(selectAuth);
  const [selectedOption, setSelectedOption] = useState(customer);
  const [trasnportNumbers, setTransportNumbers] = useState([]);
  const [selectedTransport, setSelectedTransport] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  const today = dayjs();
  const Yesterday = today.subtract(1, day);
  const minDateFrom = dayjs(minusDate); // Today minus one day
  const maxDateFrom = Yesterday; // Fixed max date for From date picker
  const defaultValueFrom = today.startOf(month); // Start of current month

  const minDateTo = today.startOf(month); // Start of current month
  const maxDateTo = Yesterday; // Today minus one day
  const defaultValueTo = Yesterday; // Yesterday's date

  const [selectedFromRentalDate, setSelectedFromRentalDate] =
    useState(defaultValueFrom);
  const [selectedToRentalDate, setSelectedToRentalDate] =
    useState(defaultValueTo);

  // handle Print functionality
  const handlePrint = () => {
    const fromDate = dayjs(selectedFromRentalDate).format(
      dateFormat.universalFormat
    );
    const toDate = dayjs(selectedToRentalDate).format(
      dateFormat.universalFormat
    );

    let propData = {
      cutomers: selectedCustomer,
      transportNumber: selectedTransport,
      retalDateFrom: fromDate,
      retalDateTo: toDate,
      selectedBy: selectedOption
    };
    getCallbackData(propData);
    setCommonDownloadModal(true);
  };

  // get all customers
  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));
        setCustomers((prev) => data);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCustomers();
  }, []);

  // onchange for all customers
  const handleCustomerChange = (e, newValue, reason) => {
    if (reason === CLEAR) {
      setSelectedCustomer(VALUE_EMPTY_STRING);
      setIsDisable(true);
    }
    if (newValue) {
      newValue = { label: newValue.label, value: newValue.value.trim() };
      setSelectedCustomer(newValue);
      setIsDisable(false);
    }
  };
  // customers feild props
  const customersFieldProps = {
    options: customers,
    handleOnChange: handleCustomerChange,
    value: selectedCustomer,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  // handechange for radio buttons
  const handleChange = async (val) => {
    setSelectedOption(val);
    if (val === transport) {
      await temporaryTransportHistory();
    }
    setSelectedTransport(null);
    setSelectedCustomer("");
    setIsDisable(true);
  };
  //  select  rental From Date to
  const handleDateChange = (date) => {
    setSelectedFromRentalDate(date);
  };
  // select  rental DateTo
  const handleToDateChange = (date) => {
    setSelectedToRentalDate(date);
  };
  // reset Form
  const handleReset = async () => {
    setSelectedCustomer(VALUE_EMPTY_STRING);
    setCustomers([]);
    await getCustomers();
    setSelectedOption(customer);
    setSelectedToRentalDate(defaultValueTo);
    setSelectedFromRentalDate(defaultValueFrom);
    setIsDisable(true);
  };
  const temporaryTransportHistory = async () => {
    setIsLoading(true);
    try {
      let res = await temporaryTransport(mainDistrictId);
      if (res) {
        let sortedDate = res?.data[0]?.sort(
          (a, b) => a?.container_id - b?.container_id
        );
        setTransportNumbers(sortedDate);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
    setIsLoading(false);
  };
  const handleTransportChange = (event, newValue, reason) => {
    if (reason === CLEAR) {
      setSelectedTransport(null);
      setIsDisable(true);
    }
    if (newValue) {
      setSelectedTransport(newValue);
      setIsDisable(false);
    }
  };

  return (
    <>
      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.temporaryTransportHistory.title")}
        body={
          <>
            {isLoading && <CircularLoaderFullPage loading={isLoading} />}
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}
            <Typography>
              {t("reports.temporaryTransportHistory.selectBy")}
            </Typography>
            <br />
            <Grid container>
              <Grid item lg={4} md={4} xs={12}>
                <FormControlLabel
                  value={t("reports.temporaryTransportHistory.customer")}
                  control={
                    <Radio
                      checked={selectedOption === customer}
                      onChange={() => handleChange(customer)}
                    />
                  }
                  label={t("reports.temporaryTransportHistory.customer")}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <FormControlLabel
                  value={t("reports.temporaryTransportHistory.transport")}
                  control={
                    <Radio
                      checked={selectedOption === transport}
                      onChange={() => handleChange(transport)}
                    />
                  }
                  label={t("reports.temporaryTransportHistory.transport")}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} xs={12}>
                {selectedOption === customer ? (
                  <FormControl fullWidth>
                    <CustomersField {...customersFieldProps} />
                  </FormControl>
                ) : (
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    name={t("reports.temporaryTransportHistory.transport")}
                    disabled={null}
                    options={trasnportNumbers}
                    getOptionLabel={(option) => option?.number}
                    size="medium"
                    value={selectedTransport}
                    onChange={handleTransportChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("reports.temporaryTransportHistory.transport")}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <DatePickerField
                    name="rentalDateFrom"
                    label={t(
                      "reports.temporaryTransportHistory.rentalDateFrom"
                    )}
                    value={dayjs(selectedFromRentalDate)}
                    minDate={minDateFrom}
                    maxDate={maxDateFrom}
                    defaultValue={defaultValueFrom}
                    onChange={handleDateChange}
                    locale={localeByBranch}
                    todayText={[]}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <DatePickerField
                    name="rentalDateTo"
                    label={t("reports.temporaryTransportHistory.rentalDateTo")}
                    value={dayjs(selectedToRentalDate)}
                    minDate={minDateTo}
                    maxDate={maxDateTo}
                    defaultValue={defaultValueTo}
                    onChange={handleToDateChange}
                    locale={localeByBranch}
                    todayText={[]}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button variant="outlined" onClick={handleReset}>
              {t("common.reset")}
            </Button>
            <Button
              variant="contained"
              onClick={handlePrint}
              disabled={isDisable}
            >
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default TemporaryTransportHistory;
