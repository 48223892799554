import spacing from "styles/spacing";
import { MediaRequestDetailFormStyles } from "../media-request-detail-form/MediaRequestDetailForm.Styles";

export const CancelRequestModalStyles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto !important"
  },
  card: {
    marginX: "auto",
    marginY: spacing.gap10,
    bgcolor: "background.paper",
    boxShadow: 24,
    width: "1000px !important"
  },
  alert: { marginTop: "10px", fontWeight: "500" },
  error: {
    borderRadius: "9px",
    marginTop: spacing.horizontalMargin20
  },
  authorizationTitle: {
    ...MediaRequestDetailFormStyles.AuthorizationHeaderTitle,
    paddingTop: "0",
    paddingBottom: spacing.verticalMargin20
  },
  authorizationType: {
    marginTop: "1.25rem",
    marginLeft: "2rem",
    marginBottom: "0.625px"
  },
  requestTitle: {
    ...MediaRequestDetailFormStyles.AuthorizationHeaderTitle,
    paddingTop: spacing.verticalMargin20,
    paddingBottom: spacing.verticalMargin20
  },
  progressIcon: {
    ...MediaRequestDetailFormStyles.ButtonCircularProgress,
    marginRight: ".5rem"
  }
};
