import { Stack } from "@mui/material";
import ReportsMain from "components/reports-module/ReportsMain";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const Reports = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("reports.title")}`} />
        <ReportsMain />
      </Stack>
    </>
  );
};

export default Reports;
