/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { CircularLoaderFullPage, SelectField } from "components/core";
import { Formik } from "formik";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useRef, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  errorMsgs,
  globalAttributeTypeIdNine,
  N_CHECKER,
  numberConstants,
  systemIdMedia,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import { buildType } from "utils/constants/disaster-module/EventSearchConstants";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getResponseData,
  getSortedData
} from "utils/helpers";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";

const DrFile = ({
  customerSelected,
  setInfoText,
  setThrowErrorMessage,
  setThrowError,
  RequestForm,
  initRequestState,
  setConfirmPopupMessage,
  setConfirmPopup,
  localeByBranch,
  setFileSelectNameId,
  setFilesDetails,
  filesDetails,
  buttonsFieldsDisable,
  setButtonsFiledsDisable,
  setOmTableData,
  omTableData,
  showDrFileMediaTable,
  setShowDrFileMediaTable,
  getOmDrForFileSelection,
  setBuiltRequestTypeConfirmClick,
  setSelectedOmDrFile,
  selectedOmDrFile,
  setTableMaxLength,
  setDrFileTableData,
  addDrFileOpenMediasToForm,
  invalidCharachter,
  validateFiled,
  filterError
}) => {
  const [drSubTitle, setDrSubTitle] = useState(null);
  const [filterSectionText, setFilterSectionText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileSelectionName, setFileSelectionName] = useState([]);
  const includeNotAt = useRef(true);
  const [includeNotAtChecked, setIncludeNotAtChecked] = useState(true);

  //reset page
  const resetPage = () => {
    setDrSubTitle(null);
    setFileSelectNameId(null);
    setConfirmPopup(false);
    setOmTableData([]);
    setConfirmPopupMessage(null);
    setFileSelectionName([]);
    setTableMaxLength(null);
    setDrFileTableData([]);
    setBuiltRequestTypeConfirmClick(null);
    filterError.current = true;
    includeNotAt.current = true;
    setIncludeNotAtChecked(true);
    setSelectedOmDrFile([]);
    setFilterSectionText(null);
    invalidCharachter.current = {
      description: VALUE_EMPTY_STRING,
      drpCode: VALUE_EMPTY_STRING
    };

    setIsLoading(false);
    setThrowError(false);
    setThrowErrorMessage(null);
    setButtonsFiledsDisable({
      fileSelectionNameField: true,
      creationTimeField: true,
      fileNameField: true,
      descriptionField: false,
      drpCodeField: false,
      applyFilterButton: true,
      clearButton: false,
      clearSelectedButton: false
    });
    setShowDrFileMediaTable(false);
    setFilesDetails(null);
    setInfoText({ visible: false, message: VALUE_EMPTY_STRING });
  };

  //handle clear button
  const handleClearButton = () => {
    filterError.current = true;
    includeNotAt.current = true;

    invalidCharachter.current = {
      description: VALUE_EMPTY_STRING,
      drpCode: VALUE_EMPTY_STRING
    };
    setButtonsFiledsDisable((pre) => ({ ...pre, applyFilterButton: true }));
    RequestForm.setFieldValue("description", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("drpCode", VALUE_EMPTY_STRING);
  };

  //handle clear selected button
  const clearSelectedButton = () => {
    const newGridData = omTableData.map((row) => ({
      ...row,
      is_selected_flag: N_CHECKER
    }));
    setOmTableData(newGridData);
  };

  //handle selected column
  const handleSelctedColumn = (e, tableMeta) => {
    setOmTableData((pre) => {
      const updateOmTableArray = [...pre];
      updateOmTableArray[tableMeta.rowIndex] = {
        ...updateOmTableArray[tableMeta.rowIndex],
        is_selected_flag: e.target.checked ? Y_CHECKER : N_CHECKER
      };
      return updateOmTableArray;
    });
  };

  //Table columns
  const columns = [
    {
      name: "is_selected_flag",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value === Y_CHECKER}
                  onChange={(e) => handleSelctedColumn(e, tableMeta)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </FormGroup>
        )
      }
    },
    {
      name: "volser",
      label: t("disasterRecovery.eventDetail.drFileOpenMedia")
    },
    {
      name: "om_status",
      label: t("disasterRecovery.eventDetail.status")
    },
    {
      name: "short_descr",
      label: t("disasterRecovery.eventDetail.mediaType")
    },
    {
      name: "drp_code",
      label: t("disasterRecovery.eventDetail.drpCodeCol")
    },
    {
      name: "descr",
      label: t("disasterRecovery.eventDetail.description")
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: 0,
    textLabels: {
      body: {
        noMatch:
          omTableData.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };

  //message load
  const getMessageBycode = async (code, setstate) => {
    const message = await getMessageFromCode(String(code));
    if (message) {
      setstate(message[0]?.descr);
    }
  };

  //get file selection names and table max length
  const getFileSelectionNames = async () => {
    setIsLoading(true);
    try {
      //get table max length
      const attributeData = await getGlobalAttributeValue(
        getAuthenticatedUserBranch(),
        systemIdMedia,
        globalAttributeTypeIdNine
      );
      setTableMaxLength(attributeData);

      //get file selection names
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        customer_id: customerSelected
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getFileSelectionName}`,
        3
      );
      if (data?.data) {
        const response = data?.data;
        if (
          response[1][0].error === numberConstants.zero &&
          response[0]?.length === 0
        ) {
          const message = await getMessageFromCode(
            String(errorMsgs.errorCode26112)
          );

          setInfoText((pre) => ({ visible: true, message: message[0]?.descr }));
          setButtonsFiledsDisable((pre) => ({
            ...pre,
            clearButton: true,
            descriptionField: true,
            drpCodeField: true
          }));
        } else {
          setButtonsFiledsDisable((pre) => ({
            ...pre,
            fileSelectionNameField: false
          }));
          setFilesDetails(response[0]);
          const filesArray = response[0]?.map((val) => ({
            label: val?.file_section_name,
            value: val?.customer_file_section_id
          }));
          const sortFilesNames = getSortedData(filesArray, "label", "asc");
          setFileSelectionName(sortFilesNames);
        }
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  //handle Change file selection dropdown
  const handleChangeFileSelection = async (e) => {
    if (RequestForm.values.fileSelectionName !== VALUE_EMPTY_STRING) {
      if (selectedOmDrFile.length === 0) {
        await validateFiled();
        RequestForm.setFieldValue("fileSelectionName", e.target.value);
        //set creation time , file name fields
        const selectedFileDetails = filesDetails.filter(
          (val) => val?.customer_file_section_id === e.target.value
        );
        setButtonsFiledsDisable((pre) => ({
          ...pre,
          creationTimeField: false,
          fileNameField: false
        }));
        RequestForm.setFieldValue(
          "creationTime",
          dateTimeFormatByLocale(
            selectedFileDetails[0]?.created_datetime,
            localeByBranch
          )
        );
        RequestForm.setFieldValue(
          "fileName",
          selectedFileDetails[0]?.file_name
        );
        await getOmDrForFileSelection(e.target.value);
      } else {
        const message = await getMessageFromCode(
          String(errorMsgs.errorCode26084)
        );
        setFileSelectNameId(e.target.value);
        setConfirmPopupMessage(message[0]?.descr);
        setConfirmPopup(true);
      }
    } else {
      await validateFiled();
      setBuiltRequestTypeConfirmClick(buildType.drFile);
      RequestForm.setFieldValue("fileSelectionName", e.target.value);
      //set creation time , file name fields
      const selectedFileDetails = filesDetails.filter(
        (val) => val?.customer_file_section_id === e.target.value
      );
      setButtonsFiledsDisable((pre) => ({
        ...pre,
        creationTimeField: false,
        fileNameField: false
      }));
      RequestForm.setFieldValue(
        "creationTime",
        dateTimeFormatByLocale(
          selectedFileDetails[0]?.created_datetime,
          localeByBranch
        )
      );
      RequestForm.setFieldValue("fileName", selectedFileDetails[0]?.file_name);
      await getOmDrForFileSelection(e.target.value);
    }
  };

  //handle change description
  const handleChangeDescription = (e) => {
    if (e.target.value?.length > 40) {
      e.target.value = e.target.value.slice(0, 40);
      RequestForm.setFieldValue("description", e.target.value);
    } else {
      invalidCharachter.current = {
        ...invalidCharachter.current,
        description: VALUE_EMPTY_STRING
      };
      filterError.current = false;
      RequestForm.setFieldValue("description", e.target.value);
    }
  };

  //handle change drp code
  const handleChangeDrpCode = (e) => {
    if (e.target.value?.length > 10) {
      e.target.value = e.target.value.slice(0, 10);
      RequestForm.setFieldValue("drpCode", e.target.value);
    } else {
      invalidCharachter.current = {
        ...invalidCharachter.current,
        drpCode: VALUE_EMPTY_STRING
      };
      filterError.current = false;
      RequestForm.setFieldValue("drpCode", e.target.value);
    }
  };

  //handle apply filter click button
  const handleOnClickApplyFilter = async () => {
    await validateFiled();
    if (!filterError.current) {
      await getOmDrForFileSelection(RequestForm.values.fileSelectionName);
      setButtonsFiledsDisable((pre) => ({
        ...pre,
        applyFilterButton: true
      }));
    }
  };

  //handle include not at IRM
  const handleIncludeNotAtIrm = () => {
    includeNotAt.current = includeNotAt.current ? false : true;
    setIncludeNotAtChecked((pre) => !pre);
    const filterNotAtIrm = omTableData.filter(
      (row) => row?.at_irm_flag === N_CHECKER
    );
    if (filterNotAtIrm.length > 0) {
      if (!includeNotAt.current) {
        const changeSelected = filterNotAtIrm.map((row) => ({
          ...row,
          is_selected_flag: N_CHECKER
        }));
        setOmTableData(changeSelected);
      } else {
        const changeSelected = filterNotAtIrm.map((row) => ({
          ...row,
          is_selected_flag: Y_CHECKER
        }));
        setOmTableData(changeSelected);
      }
    }
  };

  const handelAddRequest = () => {
    //setAddOpenMedia((pre) => [...pre, ...selectedOmDrFile]);
    addDrFileOpenMediasToForm();
    RequestForm.setFieldValue("fileSelectionName", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("creationTime", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("fileName", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("description", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("drpCode", VALUE_EMPTY_STRING);
    setShowDrFileMediaTable(false);
  };

  const handelReviewAndGenerate = () => {
    //setAddOpenMedia((pre) => [...pre, ...selectedOmDrFile]);
    addDrFileOpenMediasToForm();
    RequestForm.setFieldValue("fileSelectionName", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("creationTime", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("fileName", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("description", VALUE_EMPTY_STRING);
    RequestForm.setFieldValue("drpCode", VALUE_EMPTY_STRING);
    setShowDrFileMediaTable(false);
    RequestForm.setFieldValue("buildRequest", buildType.landingPage);
  };

  //set selected open medias to selectedOm state
  useEffect(() => {
    const selectedList = omTableData.filter(
      (row) => row.is_selected_flag === Y_CHECKER
    );
    setSelectedOmDrFile(selectedList);
  }, [omTableData]);

  useEffect(() => {
    if (selectedOmDrFile.length <= 0) {
      setButtonsFiledsDisable((pre) => ({
        ...pre,
        clearSelectedButton: true
      }));
    } else {
      setButtonsFiledsDisable((pre) => ({
        ...pre,
        clearSelectedButton: false
      }));
    }
  }, [selectedOmDrFile]);

  useEffect(() => {
    if (RequestForm.values.description || RequestForm.values.drpCode) {
      if (showDrFileMediaTable) {
        setButtonsFiledsDisable((pre) => ({
          ...pre,
          applyFilterButton: false
        }));
      } else {
        setButtonsFiledsDisable((pre) => ({
          ...pre,
          applyFilterButton: true
        }));
      }
    } else {
      setButtonsFiledsDisable((pre) => ({
        ...pre,
        applyFilterButton: true
      }));
    }
  }, [RequestForm.values.description, RequestForm.values.drpCode]);

  useEffect(() => {
    resetPage();
    RequestForm.setValues({
      ...RequestForm?.values,
      fileSelectionName: VALUE_EMPTY_STRING,
      creationTime: VALUE_EMPTY_STRING,
      fileName: VALUE_EMPTY_STRING,
      description: VALUE_EMPTY_STRING,
      drpCode: VALUE_EMPTY_STRING
    });
    getMessageBycode(errorMsgs.errorCode26096, setDrSubTitle);
    getMessageBycode(errorMsgs.errorCode26097, setFilterSectionText);
    getFileSelectionNames();
  }, []);

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage loading={isLoading} />
      <Formik>
        <form style={disasterRecoveryStyles.requestGeneratorStyles.treeWrapper}>
          <Box
            ml={spacing.gap}
            sx={{
              ...disasterRecoveryStyles.requestGeneratorStyles
                .rightPanelWrapper,
              ...disasterRecoveryStyles.requestGeneratorStyles.rightWrapper
            }}
          >
            <Box>
              <Grid
                display={"flex"}
                direction={"column"}
                flexDirection={"column"}
                gap={spacing.verticalMargin20}
              >
                <Grid item xs={12} md={12} xl={12}>
                  <Stack gap={spacing.gap10}>
                    <Typography
                      style={disasterRecoveryStyles.commonStyles.subHeading}
                    >
                      {t("disasterRecovery.eventDetail.drFileTitle")}
                    </Typography>
                    <FormLabel>{drSubTitle}</FormLabel>
                  </Stack>
                </Grid>

                <Grid container gap={spacing.verticalMargin20}>
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <SelectField
                      options={fileSelectionName}
                      labelId="fileSection"
                      id="file-section-name"
                      label={t("disasterRecovery.eventDetail.fileSectionName")}
                      value={RequestForm.values.fileSelectionName}
                      onChange={handleChangeFileSelection}
                      disabled={buttonsFieldsDisable.fileSelectionNameField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <TextField
                      name="creationTime"
                      label={t("disasterRecovery.eventDetail.creationTime")}
                      value={RequestForm.values.creationTime}
                      disabled={buttonsFieldsDisable.creationTimeField}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={6.2} lg={6.2} xl={3.2}>
                    <TextField
                      name="fileName"
                      label={t("disasterRecovery.eventDetail.fileName")}
                      value={RequestForm.values.fileName}
                      disabled={buttonsFieldsDisable.fileNameField}
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Divider />
                <Typography>{filterSectionText}</Typography>
                <Grid container gap={spacing.verticalMargin20}>
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <TextField
                      name="description"
                      label={t("disasterRecovery.eventDetail.description")}
                      value={RequestForm.values.description}
                      onChange={handleChangeDescription}
                      disabled={buttonsFieldsDisable.descriptionField}
                      error={invalidCharachter.current.description.length}
                      helperText={invalidCharachter.current.description}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <TextField
                      name="drpCode"
                      label={t("disasterRecovery.eventDetail.drpCodeCol")}
                      value={RequestForm.values.drpCode}
                      onChange={handleChangeDrpCode}
                      disabled={buttonsFieldsDisable.drpCodeField}
                      error={invalidCharachter.current.drpCode.length}
                      helperText={invalidCharachter.current.drpCode}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Stack gap={spacing.gap} mt={spacing.gap} direction="row">
                <Button
                  variant="outlined"
                  onClick={handleClearButton}
                  disabled={buttonsFieldsDisable.clearButton}
                >
                  {t("common.clear")}
                </Button>
                <Button
                  variant="contained"
                  disabled={buttonsFieldsDisable.applyFilterButton}
                  onClick={handleOnClickApplyFilter}
                >
                  {t("disasterRecovery.eventDetail.applyFilter")}
                </Button>
              </Stack>
              {/* DR file Table Section */}
              {showDrFileMediaTable && (
                <>
                  <Box my={spacing.gap}>
                    <Divider />
                  </Box>

                  <Stack gap={spacing.gap10}>
                    <Typography
                      style={disasterRecoveryStyles.commonStyles.subHeading}
                    >
                      {t("disasterRecovery.eventDetail.drFile")}
                    </Typography>
                    <FormLabel>
                      <span style={disasterRecoveryStyles.inboundDate.noteText}>
                        {t("disasterRecovery.eventDetail.clickAddRequestText")}
                      </span>
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeNotAtChecked}
                            onChange={handleIncludeNotAtIrm}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={t("disasterRecovery.eventDetail.includeMedia")}
                      />
                    </FormGroup>
                    <Typography
                      style={disasterRecoveryStyles.commonStyles.subHeading}
                    >
                      {t("disasterRecovery.eventDetail.rgOpenMedia")}
                    </Typography>
                    <MUIDataTable
                      columns={columns}
                      options={options}
                      data={omTableData}
                      title={
                        <Typography>
                          {selectedOmDrFile.length >= 1 &&
                            `${selectedOmDrFile.length}  ${t(
                              "disasterRecovery.eventDetail.openMediaSelected"
                            )}`}
                        </Typography>
                      }
                    />
                    <Stack
                      gap={spacing.gap}
                      mt={spacing.gap}
                      direction="row"
                    ></Stack>
                  </Stack>
                </>
              )}
            </Box>
            <Box
              sx={disasterRecoveryStyles.tableStyles.displayFlex}
              gap={spacing.gap}
            >
              {showDrFileMediaTable && (
                <>
                  <Button
                    variant="outlined"
                    id="add-request"
                    style={disasterRecoveryStyles.tableStyles.buttonsSpace}
                    disabled={selectedOmDrFile.length === 0}
                    onClick={handelAddRequest}
                  >
                    {t("disasterRecovery.eventDetail.addToRequest")}
                  </Button>
                  <Button
                    variant="outlined"
                    id="clear-selection"
                    style={disasterRecoveryStyles.tableStyles.buttonsSpace}
                    disabled={buttonsFieldsDisable.clearSelectedButton}
                    onClick={clearSelectedButton}
                  >
                    {t("disasterRecovery.eventDetail.clearSelected")}
                  </Button>
                </>
              )}
              <Button
                variant="contained"
                id="review-generate-request"
                style={disasterRecoveryStyles.tableStyles.buttonsSpace}
                onClick={handelReviewAndGenerate}
              >
                {t("disasterRecovery.eventDetail.reviewGenerateRequest")}
              </Button>
            </Box>
          </Box>
        </form>
      </Formik>
    </>
  );
};

export default DrFile;
