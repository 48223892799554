import { Stack } from "@mui/material";
import LabelTemplateMaintenanceScreen from "components/customer-information/label-template-maintenance/LabelTemplateMaintanceScreen";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const LabelTemplateMaintenance = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("labelTemplateMaintenance.title")}`} />
        <LabelTemplateMaintenanceScreen />
      </Stack>
    </>
  );
};

export default LabelTemplateMaintenance;
