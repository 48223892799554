import { Stack } from "@mui/material";
import CustomerServiceWindows from "components/customer-information/service-windows/ServiceWindows";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const serviceWindows = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("serviceWindows.pageTitle")} />
      <CustomerServiceWindows />
    </Stack>
  );
};

export default serviceWindows;
