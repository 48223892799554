import { ExpandMore } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";

import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import { t } from "i18next";

import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import { getCustomerByID, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  setDefaultBranchValues,
  setIsRefreshRequired
} from "store/slices/customer-preferences";
import {
  dayMonthYearTimeFormat,
  ERROR_TYPES,
  errorMsgs,
  ROLE_ACCESS_CODES
} from "utils/constants";
import {
  getPreferenceTypesPayloadForReport,
  pattern,
  preferenceTypeId24,
  preferenceTypeId29,
  systemId
} from "utils/constants/customer-information/CustomerPreference";
import {
  dateTimeFormatByLocalewithSeconds,
  escapeXml,
  escapeXmlForReport,
  findErrorMessage
} from "utils/helpers";
import PreferencesLayout from "../preferences-main/PreferencesLayout";
import {
  getCustomerPreferenceHistory,
  getPreferenceTabValues,
  updateCustomerPreferences
} from "../preferences-main/Services";
import PreferencesReoprtDownloadModal from "../PreferencesReoprtDownloadModal";
import { PreferencesStyles } from "../PreferencesStyles";
import { MinimumEmptyTransportsTable } from "./MinimumEmptyTransportsTable";

export const MinimumEmptyTransports = () => {
  const { defaultAuthBranchValues, isAuthenticated } = useSelector(
    (state) => state.customerPreferences
  );
  const { roleAccess, localeByBranch, timeZoneOffset } =
    useSelector(selectAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const [expanded, setExpanded] = useState(false);
  const [transportText, setTransportText] = useState("");
  const [comments, setComments] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [success, setSuccess] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();

  const [successMessage, setSuccessMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState({
    tranportTextFeid: false,
    commentsTextFeild: false
  });
  const redirecToPreviousPage = () => {
    navigate(-1);
    const updatedData = {
      ...defaultAuthBranchValues,
      isDisablePrint: false
    };

    dispatch(setDefaultBranchValues(updatedData));
  };
  const getSelectedCustomer = async (customer_id) => {
    setIsLoading(true);
    try {
      const customer = await getCustomerByID(customer_id);
      setSelectedCustomer(customer[0]);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (stateData?.customer_id) getSelectedCustomer(stateData?.customer_id);
  }, [stateData?.customer_id]);
  const transportHandler = (e) => {
    let newValue = e.target.value;
    if (pattern.test(newValue) && newValue.length <= 3) {
      setTransportText(newValue);
      setThrowError(false);
    }
  };
  const handleComments = (e) => {
    let newValue = e.target.value;
    setComments(newValue);
  };

  useEffect(() => {
    if (roleAccess) {
      if (!roleAccess.includes(ROLE_ACCESS_CODES.code_284)) {
        setIsDisabled((prev) => ({
          ...prev,
          tranportTextFeid: true,
          commentsTextFeild: true
        }));
      }
    }
  }, [roleAccess]);
  useEffect(() => {
    let initialValues = {};
    if (stateData?.rowdata) {
      const mappedData = stateData?.rowdata?.children?.map(
        (item) => item?.settings
      );
      const initialValue = stateData?.rowdata?.settings;

      // Check if the initial value is numeric
      if (pattern.test(initialValue)) {
        setTransportText(initialValue);
        initialValues.transport = initialValue;
      } else {
        setTransportText("");
        initialValues.transport = "";
      }
      initialValues.comment = mappedData?.join("");
      setComments(mappedData?.join(""));
    }
    setInitialValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData?.rowdata]);

  useEffect(() => {
    let isSame = _.isEqual(initialValues, {
      transport: transportText,
      comment: comments
    });
    setIsSaveDisabled(isSame);
  }, [transportText, comments, initialValues, success]);

  //  Create the padded string with spaces
  const generatePaddedString = (value, totalLength) => {
    value = String(value);
    const spacesNeed = totalLength - value.length;
    const paddedString = " ".repeat(spacesNeed) + value;

    return paddedString;
  };
  const preferenceTypeId = stateData?.rowdata?.preference_type_id || "";
  const childPreferenceTypeId =
    stateData?.rowdata?.children[0]?.preference_type_id || ""; // Fallback to empty string if undefined

  const parentPaddedString = generatePaddedString(preferenceTypeId, 10);
  const childPaddedString = generatePaddedString(childPreferenceTypeId, 10);
  const paddedString = `${parentPaddedString}${childPaddedString}`;

  // get the changed value column Name
  const getPreferenceName = (resutset2, newKey) => {
    // Map over children to get the appropriate name based on conditions
    const newData = resutset2.children.map((item) => {
      if (item.preference_type_id === preferenceTypeId24) {
        return newKey?.name;
      } else {
        return newKey?.children[0]?.name;
      }
    });

    return newData.join(" , ");
  };

  const mergeData = (resultSet1, resultSet2, newKey) => {
    // Create a map of change_history_id to preferences from resultSet2
    const preferencesMap = resultSet2.reduce((map, item) => {
      if (!map[item.change_history_id]) {
        map[item.change_history_id] = [];
      }
      const name =
        item.preference_type_id === preferenceTypeId24
          ? newKey?.name
          : newKey?.children[0]?.name;

      map[item.change_history_id].push({ ...item, name });
      return map;
    }, {});

    return resultSet1.map((item) => ({
      ...item,
      children: preferencesMap[item.change_history_id] || []
    }));
  };
  // call history Sp
  const gethistoryTableData = async () => {
    try {
      setIsLoading(true);
      let res = await getCustomerPreferenceHistory(
        stateData?.mainDistrictId,
        selectedCustomer.id,
        paddedString
      );
      if (res && res.data) {
        // Ensure res.data is an array with at least two elements
        let resultSetOne = res.data[0];
        let resultSetTwo = res.data[1];
        const mergedData = mergeData(
          resultSetOne,
          resultSetTwo,
          stateData?.rowdata
        );

        const updatedData = mergedData.map((item) => {
          const dateFormate = dateTimeFormatByLocalewithSeconds(
            item?.change_history_datetime,
            localeByBranch
          );

          return {
            ...item,
            date: dateFormate,
            imEmployee: `${item.employee_last_name} ${item.employee_first_name}`,
            authorizedBy: `${item.personnel_last_name} ${item.personnel_first_name}`,
            changedValues: getPreferenceName(item, stateData?.rowdata)
          };
        });
        // Sort the data by date
        const sortedData = updatedData.sort((a, b) => {
          const dateA = new Date(
            a.date.split(" ")[0].split("/").reverse().join("-") +
              "T" +
              a.date.split(" ")[1]
          );
          const dateB = new Date(
            b.date.split(" ")[0].split("/").reverse().join("-") +
              "T" +
              b.date.split(" ")[1]
          );
          return dateB - dateA;
        });

        setTableData(sortedData);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const onChangeAccordionExpand = async (e, expanded) => {
    if (!expanded) {
      return;
    }
    await gethistoryTableData();
  };

  // xml creation
  const createXmlPayload = () => {
    const objectValues = `<ObjectValue Object_Id="${preferenceTypeId24}" Object_Value="${transportText}" Version=""/><ObjectValue Object_Id="${preferenceTypeId29}" Object_Value="${escapeXml(
      comments
    )}" Version=""/>`;
    return `
    <ObjectValues>${objectValues}</ObjectValues>
  `;
  };
  const xmlPayload = createXmlPayload();
  const personnelId = isAuthenticated
    ? defaultAuthBranchValues?.personnel_id
    : "";
  //  save Functionality
  const onSaveUpdatePreference = async () => {
    try {
      setIsLoading(true);
      let res = await updateCustomerPreferences(
        stateData?.mainDistrictId,
        selectedCustomer.id,
        systemId,
        stateData?.employee_id,
        xmlPayload,
        personnelId
      );
      if (res) {
        dispatch(setIsRefreshRequired(true));
        setExpanded(false);
        if (stateData?.customer_id) {
          if (Number(res.data[0][0]?.error).length > 0) {
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode59028);

            setThrowErrorMessage(
              errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
            );

            setThrowError(true);
          } else {
            if (stateData?.customer_id) {
              await getPreferenceTabValues(
                stateData?.mainDistrictId,
                stateData?.customer_id
              );
            }
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode64095);

            let replacedText = errorMsg[0]?.descr.replace(
              "|",
              t("preferences.minimumEmptyTransports.subTitle")
            );
            await import(`dayjs/locale/${localeByBranch}.js`);
            const formattedDate = dayjs
              .utc()
              .add(timeZoneOffset, "hour")
              .locale(localeByBranch)
              .format(dayMonthYearTimeFormat);
            const replaceWithDate = replacedText.replace("|", formattedDate);
            setSuccessMessage(
              replaceWithDate || findErrorMessage(ERROR_TYPES.ISSUE)
            );
            setInitialValues({
              transport: transportText,
              comment: comments
            });
            setSuccess(true);
          }
        }
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSave = async () => {
    if (transportText === "") {
      let errorMsg = await getMessageFromCode(errorMsgs?.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("preferences.minimumEmptyTransports.hashOfTransport")
      );
      setThrowErrorMessage(replacedText || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } else {
      await onSaveUpdatePreference();
    }
  };

  const topTextFieldReportsPayload = [
    {
      key: t("preferences.customerPreferenceTypes24"),
      value: transportText
    },
    {
      key: t("preferences.customerPreferenceTypes29"),
      value: escapeXmlForReport(comments)
    }
  ];

  const filteredPreferenceTypes = useMemo(() => {
    return getPreferenceTypesPayloadForReport().filter((type) =>
      [preferenceTypeId, childPreferenceTypeId].includes(
        type.preference_type_id
      )
    );
  }, [childPreferenceTypeId, preferenceTypeId]);

  return (
    <>
      <PreferencesLayout>
        {isLoading && <CircularLoaderFullPage loading={isLoading} />}
        <PreferencesReoprtDownloadModal
          title={t("common.downloadReport")}
          showPrintModal={downloadModal}
          setCloseModal={setDownloadModal}
          setIsLoading={setIsLoading}
          customerId={stateData?.customer_id}
          preferenceTypeId={paddedString}
          url={
            CF_URLS.printReports.customerInformation
              .minimumEmptytransportsRequiredatCustomersiteReport
          }
          topTextFieldReportsPayload={topTextFieldReportsPayload}
          preferenceTypesPayloadForReport={filteredPreferenceTypes}
        />
        {success && (
          <SuccessPopup message={successMessage} close={setSuccess} />
        )}
        {throwError && (
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
        )}

        <Box>
          <Typography sx={PreferencesStyles.titleStyle}>Customer</Typography>
          <Typography>{selectedCustomer?.number?.trim()}</Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h5">
            {t("preferences.minimumEmptyTransports.subTitle")}
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item md={6} lg={3} xs={12}>
              <FormControl fullWidth variant="outlined">
                <>
                  <TextField
                    type="text"
                    label={t(
                      "preferences.minimumEmptyTransports.NumberOfTransport"
                    )}
                    disabled={isDisabled.tranportTextFeid}
                    value={transportText}
                    onChange={transportHandler}
                    inputProps={{ maxLength: 3 }}
                  />
                </>
              </FormControl>
            </Grid>
            <Grid item md={6} lg={5} xs={12}>
              <FormControl fullWidth variant="outlined">
                <>
                  <TextField
                    label={t("preferences.minimumEmptyTransports.comments")}
                    type="text"
                    disabled={isDisabled.commentsTextFeild}
                    value={comments}
                    onChange={handleComments}
                    variant="outlined"
                    multiline
                    rows={3}
                  />
                </>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
        </Box>
        <Accordion
          sx={PreferencesStyles.accordian}
          expanded={expanded}
          onChange={(e, newExpanded) => {
            setExpanded(newExpanded);
            onChangeAccordionExpand(e, newExpanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              variant="subtitle1"
              style={PreferencesStyles.subTitleStyle}
            >
              {t("preferences.history")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MinimumEmptyTransportsTable
              data={tableData}
              rowData={stateData?.rowdata}
            />
          </AccordionDetails>
        </Accordion>
        <br />
        <Box sx={PreferencesStyles.buttonStyles}>
          <Button
            variant="outlined"
            type="submit"
            onClick={redirecToPreviousPage}
          >
            <ChevronLeftIcon /> {t("common.back")}
          </Button>
          <Button
            variant="outlined"
            type="submit"
            onClick={() => setDownloadModal(true)}
          >
            {t("common.print")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={
              !roleAccess.includes(ROLE_ACCESS_CODES.code_284) || isSaveDisabled
            }
            onClick={handleSave}
          >
            {t("common.save")}
          </Button>
        </Box>
      </PreferencesLayout>
    </>
  );
};

export default MinimumEmptyTransports;
