import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { TransferItemStyles } from "./Styles";
import { logicalVaultConstants } from "./constants";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferListItem(props) {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    if (props.customerId === "") {
      return;
    }
    props.handleMediaClickItem();
    if (
      isNaN(props.capacityState) ||
      parseInt(props.capacityState) > logicalVaultConstants.capacityMaxLength ||
      parseInt(props.capacityState) < logicalVaultConstants.capacityMinLength
    ) {
      return;
    } else {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (props.customerId === "") {
      return;
    }
    props.handleMediaClickItem();
    if (
      isNaN(props.capacityState) ||
      parseInt(props.capacityState) > logicalVaultConstants.capacityMaxLength ||
      parseInt(props.capacityState) < logicalVaultConstants.capacityMinLength
    ) {
      return;
    } else {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const mediaAssignedTapes = [];
    rightChecked.forEach((r) => {
      const checkMediaAssigned = props.selectedMediaTypes.filter(
        (e) => e.descr === r
      );

      if (
        checkMediaAssigned[0]?.media_assigned_flag ===
        logicalVaultConstants.yesValue
      ) {
        mediaAssignedTapes.push(checkMediaAssigned);
      }
    });

    if (mediaAssignedTapes.length === 0) {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    } else {
      props.handleMediaClickRightToLeft(mediaAssignedTapes);
    }
  };

  useEffect(() => {
    props.handleMediaTypesChecked(right);
    // eslint-disable-next-line
  }, [right]);

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": t("logicalVaultDetails.allItemsText")
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${t(
          "logicalVaultDetails.selected"
        )}`}
      />
      <Divider />
      <List sx={TransferItemStyles.listStyle} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  const getSorted = (arr) => {
    return arr.toSorted((a, b) => {
      if (a["descr"].toLowerCase() < b["descr"].toLowerCase()) {
        return -1;
      }
      if (a["descr"].toLowerCase() > b["descr"].toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    const sortedTypes = getSorted(props.selectedMediaTypes);
    setRight(sortedTypes.map((e) => e.descr));
  }, [props.selectedMediaTypes]);

  useEffect(() => {
    const sortedTypes = getSorted(props.availableMediaTypes);
    setLeft(sortedTypes.map((e) => e.descr));
  }, [props.availableMediaTypes]);

  return (
    <Grid container spacing={6} sx={TransferItemStyles.cStyle}>
      <Grid item>{customList(t("logicalVaultDetails.available"), left)}</Grid>
      <Grid item>
        <Grid container direction="column" sx={TransferItemStyles.centerStyle}>
          <Button
            sx={TransferItemStyles.btnStyle}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={TransferItemStyles.btnStyle}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(t("logicalVaultDetails.selected"), right)}</Grid>
    </Grid>
  );
}
