import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Typography
} from "@mui/material";
import ModalPopup from "components/core/modal-components/ModalPopup";
import MUIDataTable from "mui-datatables";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  INSTRUCTION_FILES,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH
} from "routing/Paths";
import { getMessageFromCode } from "services/api/query";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  messageCode,
  ROLE_ACCESS_CODES
} from "utils/constants";
import {
  drInstructionsDelete,
  drInstructionsExist,
  drInstructionsNew,
  drInstructionsUpdate,
  RTF
} from "utils/constants/disaster-module/EventSearchConstants";
import { isRTFContent, validateUserHasProgramId } from "utils/helpers";
import { replaceValueFromMessageDescr } from "../../../utils/constants/disaster-module/EventSearchConstants";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";

const EventDetailInstructions = ({
  instMsgList,
  instructionsList,
  setInstructionsList,
  EventDetailForm,
  setThrowError,
  setThrowErrorMessage,
  setIsLoading,
  setSelectedRow,
  selectedRow,
  setClickedRowData,
  clickedRowData,
  fetchInstructionFileFromDB,
  enableAttach,
  enableDelete,
  enableView,
  enableInst,
  pageIsViewOnly,
  customerSelected,
  instDeleteWarning,
  instReplaceWarning
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  //open wraining modal
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [isDelete, setIsDelete] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [updatedFileData, setUpdatedFileData] = useState("");
  const [existingInstructionData, setExistingInstructionData] = useState(null);
  //warining modal
  const handleOpenWarningModal = () => setOpenWarningModal(true);
  const handleCloseWarningModal = () => setOpenWarningModal(false);
  const [wariningMsg, setWarningMsg] = useState("");

  useEffect(() => {
    if (existingInstructionData && isNew) {
      handleReplaceExistingData(drInstructionsNew);
    }
    // eslint-disable-next-line
  }, [isNew, existingInstructionData]);

  const handleYes = () => {
    handleCloseWarningModal();
    if (isDelete) {
      if (existingInstructionData.status === drInstructionsNew) {
        setInstructionsList((prev) => {
          return prev.filter(
            (instruction) =>
              instruction.dr_instruction_name !==
              clickedRowData.dr_instruction_name
          );
        });
      } else if (existingInstructionData.status === drInstructionsExist) {
        handleReplaceExistingData(drInstructionsDelete);
      }
    } else if (isUpdate) {
      handleReplaceExistingData(drInstructionsUpdate);
    }
    setSelectedRow(null);
    setClickedRowData(null);
    setExistingInstructionData(null);
    setUpdatedFileData("");
    setIsDelete(false);
    setIsUpdate(false);
    setIsNew(false);
  };

  const handleReplaceExistingData = (status) => {
    if (
      existingInstructionData.status === drInstructionsExist &&
      status === drInstructionsUpdate
    ) {
      const newInstructionsData = {
        dr_instruction_id: existingInstructionData.dr_instruction_id,
        dr_instruction_name: existingInstructionData.dr_instruction_name,
        date_time: "",
        dr_instruction: updatedFileData,
        status: status
      };
      setInstructionsList((prev) => [...prev, newInstructionsData]);
    } else if (
      existingInstructionData.status === drInstructionsNew &&
      status === drInstructionsDelete
    ) {
      setInstructionsList((prev) => {
        return prev.filter(
          (instruction) => instruction !== existingInstructionData
        );
      });
    } else {
      setInstructionsList((prev) => {
        return prev.map((instruction) => {
          if (
            instruction.dr_instruction_name ===
              existingInstructionData.dr_instruction_name &&
            instruction.status !== drInstructionsDelete
          ) {
            if (status !== drInstructionsDelete) {
              // Update Date/Time
              instruction.date_time = "";
              instruction.dr_instruction = updatedFileData;
            }
            if (instruction.status !== drInstructionsNew) {
              instruction.status = status;
            }
            return instruction;
          } else {
            // The rest haven't changed
            return instruction;
          }
        });
      });
    }
  };

  const handleNo = () => {
    //closing the modals
    handleCloseWarningModal();
    setIsDelete(false);
    setIsUpdate(false);
  };

  const columns = [
    {
      name: "dr_instruction_name",
      label: t(`disasterRecovery.eventDetail.drInstructionsLabel`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "date_time",
      label: t(`disasterRecovery.eventDetail.drDateTimeLabel`),
      options: {
        customBodyRender: (value) => {
          if (value) {
            return value;
          } else {
            return "";
          }
        },
        filter: true,
        sort: false
      }
    }
  ];

  const openMediaFileImportRef = useRef(null);

  // handle Message
  const handleMessage = async (messageId, replaceValue) => {
    await getMessageFromCode(messageId)
      .then((data) => {
        const messageDescr = data[0]?.descr;
        if (messageDescr.includes(replaceValueFromMessageDescr)) {
          setThrowErrorMessage(
            messageDescr.replace(replaceValueFromMessageDescr, replaceValue)
          );
        } else {
          setThrowErrorMessage(messageDescr);
        }
      })
      .catch((error) => {
        setThrowError(true);
        setThrowErrorMessage(error);
      })
      .finally(() => window.scrollTo({ top: 0 }));
  };

  const filterDeletedList = () => {
    return instructionsList?.filter(
      (instruction) => instruction.status !== drInstructionsDelete
    );
  };

  //handle row click helper function
  const handleRowClick = (rowData, rowMeta) => {
    const index = rowMeta.dataIndex;
    setSelectedRow(index);
    const data = filterDeletedList();
    setClickedRowData(data[index]);
    setExistingInstructionData(data[index]);
  };

  const handleDelete = () => {
    setIsDelete(true);
    setWarningMsg(instDeleteWarning);
    handleOpenWarningModal();
  };

  const handleUpdate = () => {
    setIsUpdate(true);
    setWarningMsg(instReplaceWarning);
    handleOpenWarningModal();
  };
  // handle file import event handler
  const handleFileImport = async (event) => {
    const file = event?.target?.files[0];
    setThrowError(false);
    if (file) {
      try {
        if (!file.name.toLowerCase().endsWith(RTF)) {
          setThrowError(true);
          handleMessage(messageCode.rtfMessageId.toString());
          // Clear the file input
          openMediaFileImportRef.current.value = null;
          return;
        }

        // read and process file content
        try {
          const reader = new FileReader();
          reader.onload = (e) => {
            const content = e.target.result;
            if (content.trim()) {
              parseFileContent(file.name, content);
            }
            // Reset the input value to trigger the onChange event next time
            openMediaFileImportRef.current.value = null;
            setIsLoading(false);
          };
          reader.readAsText(file);
        } catch (error) {
          setThrowError(true);
          setThrowErrorMessage(t("mediaRequestDetail.errorReadingTheFile"));
        }
      } catch (error) {
        setThrowError(true);
        setThrowErrorMessage(t("printTapeLabel.errorReadingTheFile"));
      }
    } else {
      setThrowError(false);
    }
  };

  const parseFileContent = async (fileName, content) => {
    setIsLoading(true);
    if (!isRTFContent(content)) {
      setThrowError(true);
      setIsLoading(false);
      await handleMessage(messageCode.rtfMessageId.toString());
      return;
    }

    let existingInstructionList = [];
    if (instructionsList) {
      existingInstructionList = instructionsList.filter(
        (instruction) =>
          instruction.dr_instruction_name === fileName &&
          instruction.status !== drInstructionsDelete
      );
    }

    if (existingInstructionList.length > 0) {
      setUpdatedFileData(content);
      setExistingInstructionData(existingInstructionList[0]);
      if (existingInstructionList[0].status === drInstructionsExist) {
        handleUpdate();
      } else {
        setIsNew(true);
      }
    } else {
      const newInstructionsData = {
        dr_instruction_id: "",
        dr_instruction_name: fileName,
        date_time: "",
        dr_instruction: content,
        status: drInstructionsNew
      };
      setInstructionsList((prev) => [...prev, newInstructionsData]);
    }
    setThrowError(false);
  };

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: 1,
    pagination: instructionsList?.length && true,
    textLabels: {
      body: {
        noMatch:
          pageIsViewOnly === true
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor:
          dataIndex === selectedRow ? "var(--color-im-light-blue-100)" : ""
      }
    }),
    onRowClick: handleRowClick,
    customToolbar: () => (
      <Grid
        item
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        gap={spacing.verticalMargin20}
      >
        <Button
          variant="outlined"
          style={
            enableDelete &&
            (validateUserHasProgramId(ROLE_ACCESS_CODES.code_257) ||
              validateUserHasProgramId(ROLE_ACCESS_CODES.code_259))
              ? disasterRecoveryStyles.tableStyles.buttons
              : disasterRecoveryStyles.tableStyles.hiddenButton
          }
          id="delete-instructions-list"
          onClick={handleDelete}
        >
          {t("disasterRecovery.eventDetail.deleteInstructionsLabel")}
        </Button>

        <Button
          variant="outlined"
          style={
            enableView
              ? disasterRecoveryStyles.tableStyles.buttons
              : disasterRecoveryStyles.tableStyles.hiddenButton
          }
          id="view-instructions-list"
          onClick={fetchInstructionFileFromDB}
        >
          {t("disasterRecovery.eventDetail.viewInstructionsLabel")}
        </Button>

        <Button
          component="label"
          variant="outlined"
          role={undefined}
          disabled={!enableAttach}
          style={
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_257) ||
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_258)
              ? disasterRecoveryStyles.tableStyles.buttons
              : disasterRecoveryStyles.tableStyles.hiddenButton
          }
          id="new-instructions-list-import"
        >
          {t("disasterRecovery.eventDetail.attachInstructionsLabel")}
          <input
            ref={openMediaFileImportRef}
            type="file"
            accept={RTF}
            style={disasterRecoveryStyles.tableStyles.inputTypeFile}
            onChange={handleFileImport}
          />
        </Button>

        <Button
          variant="contained"
          style={
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_268)
              ? disasterRecoveryStyles.tableStyles.buttons
              : disasterRecoveryStyles.tableStyles.hiddenButton
          }
          id="new-instructions-list"
          disabled={!enableInst}
          onClick={onNavigate}
        >
          {t("disasterRecovery.eventDetail.instructionFilesLabel")}
        </Button>
      </Grid>
    )
  };

  const onNavigate = () => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${INSTRUCTION_FILES}/customerId=${customerSelected}`,
      {
        state: { from: EventDetailForm?.values?.drEventId, ...location?.state }
      }
    );
  };

  return (
    <form>
      <ModalPopup
        title={t("pickup.confirm")}
        modalPopupOpen={openWarningModal}
        handleClick={handleCloseWarningModal}
        alertMessage={wariningMsg}
        modalButton={
          <>
            <Button variant="outlined" onClick={handleYes}>
              {t("common.yes")}
            </Button>
            <Button variant="outlined" onClick={handleNo}>
              {t("common.no")}
            </Button>
          </>
        }
      />
      <Stack direction={"column"} gap={spacing.gap}>
        <Typography style={disasterRecoveryStyles.commonStyles.subHeading}>
          {t("disasterRecovery.eventDetail.instructionsLabel")}
        </Typography>
        {instMsgList?.length > 0 && (
          <List sx={{ listStyleType: "disc" }}>
            {instMsgList?.map((inst) => {
              return (
                <>
                  <ListItem sx={{ display: "list-item" }}>{inst}</ListItem>
                </>
              );
            })}
          </List>
        )}

        <MUIDataTable
          columns={columns}
          options={options}
          data={filterDeletedList()}
          editMode="row"
          rowModesModel={null}
          onRowModesModelChange={null}
          onRowEditStop={null}
          processRowUpdate={null}
          onRowClick={handleRowClick}
          title={
            <Typography style={commonStyles.tableRecordsFoundTitleStyle}>
              {filterDeletedList()?.length} {t("common.recordsFound")}
            </Typography>
          }
        />
      </Stack>
      <Divider />
    </form>
  );
};

export default EventDetailInstructions;
