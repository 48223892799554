import { Stack } from "@mui/material";
import { DefaultPricingUsageReport } from "components/billing-module";

const DefaultPricingUsageReportPage = () => {
  return (
    <Stack spacing={2}>
      <DefaultPricingUsageReport />
    </Stack>
  );
};

export default DefaultPricingUsageReportPage;
