import i18n from "i18n";
import { numberConstants } from "..";

export const customerSources = [
  {
    label: i18n.t("common.all"),
    value: numberConstants.zero
  },
  {
    label: i18n.t("CustomerFileSearch.applicationEnteredOnly"),
    value: numberConstants.two
  },
  {
    label: i18n.t("CustomerFileSearch.transmittedOnly"),
    value: numberConstants.one
  }
];
export const isManagerCode = "165";
