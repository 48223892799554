import { Box, CircularProgress, Fade, Modal, Typography } from "@mui/material";
import PropTypes from "prop-types";

const CircularLoaderFullPage = (props) => {
  const { loading, message } = props;
  return (
    <Modal open={loading} disableRestoreFocus sx={Styles.modal}>
      <Box sx={Styles.root}>
        <Fade in={loading} unmountOnExit>
          <Box sx={Styles.loader}>
            <Typography variant="h5" sx={Styles.typography}>
              {message}
            </Typography>
            <CircularProgress color="primary" />
          </Box>
        </Fade>
      </Box>
    </Modal>
  );
};

CircularLoaderFullPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]),
  loading: PropTypes.any
};

const Styles = {
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  loader: {
    position: "relative",
    textAlign: "center"
  },
  typography: {
    marginBottom: 3
  },
  modal: { zIndex: 9999 }
};

export default CircularLoaderFullPage;
