import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ArrowDownDisabled } from "assets/images/ArrowDownDisabled.svg";
import { ReactComponent as ClosedIcon } from "assets/images/CloseIcon.svg";
import { default as ErrorIcon } from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetPersonnelAuthCustomerMutation,
  useGetPersonnelCustomerMutation,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES } from "utils/constants";
import { generateSHA256Hash, getLanguageId } from "utils/helpers";
import { BypassId, RequestStatusIds } from "../Constants";
import { MDRPickupFormStyles } from "../MediaDestructionRequestPickup.style";
import { pickupSchema } from "../schema";
import { PersonnelAuthenticationFormStyles } from "./PersonnelAuthenticationFormStyles.style";

const personnelAuthState = {
  personnel: "",
  bypassReason: "",
  authFailedAlertOpen: false,
  bypassedBy: "",
  authSuccess: false,
  openAuth: false,
  challengeQuestion: "",
  challengeResponse: "",
  resultChallengeAns: "",
  resultAuth: "",
  authNumber: "",
  authorizationType: "personnelAuthentication",
  authSuccessType: "",
  personnelValue: "",
  disablePersonnel: false,
  customer_list: [],
  business_email_addr: "",
  bypassError: false
};
const PersonnelAuthentication = ({
  value,
  customer,
  onAuthSubmit,
  enableCustomerAuth,
  setIsError,
  setErrorMessage,
  isError,
  errorMessage,
  setAuthFailedCancel,
  ...props
}) => {
  const formikRef = useRef();
  const { t } = useTranslation();
  // loading indicator
  const [pageLoading, setPageLoading] = useState(false);
  //setting the personnel id
  const [personnelIds, setPersonnelIds] = useState([]);

  // authInfo
  const { authInfo, currentBranch } = useSelector(selectAuth);
  // perssonel Ids for specifi customer
  const [triggerPersonnelIds] = useGetPersonnelCustomerMutation({});
  // getting the personnel details
  const [triggerAuthNoQuesInfo] = useGetPersonnelAuthCustomerMutation({});
  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  const selectedBranch = currentBranch?.district_id;
  const languageLocaleId = getLanguageId();
  useEffect(() => {
    // getting the customer personnel details
    if (customer) {
      setPageLoading((prevState) => true);
      triggerPersonnelIds({
        customerId: customer?.value
      })
        .unwrap()
        .then((personnelIds) => {
          const error = personnelIds && personnelIds[0]?.error;
          // handle
          if (error) {
            setIsError(true);
            setErrorMessage(findErrorMessage(personnelIds[0]?.error));
          } else {
            // setting the personnel Ids
            setPersonnelIds(personnelIds);
          }
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        })
        .finally(() => setPageLoading((prevState) => false));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  //handling the errors
  const findErrorMessage = (error) => {
    let relatedErrorMessage = "";

    switch (error) {
      case ERROR_TYPES.FAILED:
        relatedErrorMessage = t("vaultItemized.failedError");
        break;
      case ERROR_TYPES.EXCEPTION:
        relatedErrorMessage = t("vaultItemized.errorOccurred");
        break;
      case ERROR_TYPES.ISSUE:
        relatedErrorMessage = t("vaultItemized.somethingWrong");
        break;
      default:
        relatedErrorMessage = error;
        break;
    }

    return relatedErrorMessage;
  };

  useEffect(() => {
    // if auth is not verified, changing the customer resetting the fields
    formikRef.current.setFieldValue("openAuth", false);
    if (!props.authVerified) {
      formikRef.current.setFieldValue("authorizedBy", "");
      formikRef.current.setFieldValue("challengeQuestion", "");
      formikRef.current.setFieldValue("challengeResponse", "");
      formikRef.current.setFieldValue("resultAuth", "");
      formikRef.current.setFieldValue("authNumber", "");
      formikRef.current.setFieldValue("authSuccess", false);
      formikRef.current.setFieldValue("resultChallengeAns", "");
      formikRef.current.setFieldValue("authFailedAlertOpen", false);
      formikRef.current.setFieldValue("personnel", "");
      formikRef.current.setFieldValue("personnelValue", null);
      formikRef.current.setFieldValue("disablePersonnel", false);
      formikRef.current.setFieldValue("authSuccessType", "");
      formikRef.current.setFieldValue("bypassReason", "");

      formikRef.current.setFieldValue(
        "authorizationType",
        "personnelAuthentication"
      );
      setPersonnelIds([]);
      enableCustomerAuth && setAuthFailedCancel(true);

      formikRef.current?.setErrors({});
      formikRef.current?.setTouched({});
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const successMsg = (value) => {
    return (
      <Box sx={MDRPickupFormStyles.successWithCheckIcon} id="successLabel">
        <CheckIcon sx={MDRPickupFormStyles.SuccessColor} id="checkIcon" />
        <Typography
          variant="subtitle1"
          sx={PersonnelAuthenticationFormStyles.successValue}
          id="successValue"
        >
          {value}
        </Typography>
      </Box>
    );
  };

  const cancelButton = () => {
    return (
      <Button
        id="cancel"
        variant="outlined"
        color="primary"
        size="medium"
        sx={MDRPickupFormStyles.cancelButton}
        onClick={() => {
          props.setEnableCustomerAuth(false);
        }}
      >
        Cancel
      </Button>
    );
  };
  const handleAfterAutheticate = (authSuccessType) => {
    //setting the state after clicked
    formikRef.current.setFieldValue("authSuccess", true);
    enableCustomerAuth && setAuthFailedCancel(true);
    formikRef.current.setFieldValue("openAuth", false);
    formikRef.current.setFieldValue("authSuccessType", authSuccessType);
    formikRef.current.setFieldValue("authFailedAlertOpen", false);
    onAuthSubmit(formikRef.current.values);
  };

  const handleAuthenticate = async () => {
    setPageLoading((prevState) => true);
    const values = formikRef.current?.values;
    const value = values.challengeResponse;

    let validateChallengeResponse;
    //challenge response validation
    if (values.challengeResponse.length > 0) {
      //generate hash code
      let hashCode = await generateSHA256Hash(value.trim().toUpperCase());
      validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: selectedBranch,
        language_locale_id: languageLocaleId,
        personnel_id: values.personnel,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          const error = data && data[0]?.error;
          //checking for the error
          if (error) {
            setIsError(true);
            setErrorMessage(findErrorMessage(data[0]?.error));
          } else {
            return data[0].is_match_flag;
          }
        })
        .catch((e) => {
          setIsError(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        })
        .finally(() => setPageLoading((prevState) => true));
    }

    // checking if the authnumber
    if (
      (values.authNumber.length > 0 &&
        values.authNumber === values.resultAuth) ||
      (values.challengeResponse.length > 0 && validateChallengeResponse === "Y")
    ) {
      if (enableCustomerAuth) {
        //checking whether navigate from diff screen - if so handle dialogbox functionality
        let verify = await props.verifyAuth(
          RequestStatusIds.Cancelled,
          values.personnel
        );
        //verify whether the personnel has an perpermission on cancel
        if (verify) {
          handleAfterAutheticate("authenticate", values);
        } else {
          props.setEnableCustomerAuth(false);
        }
      } else {
        handleAfterAutheticate("authenticate", values);
      }
    } else {
      formikRef.current.setFieldValue("authFailedAlertOpen", true);
      enableCustomerAuth && setAuthFailedCancel(false);
    }
    setPageLoading((prevState) => false);
  };
  const handleBypass = async () => {
    //checking if the bypass reason is filled
    const values = formikRef.current?.values;
    const errors = formikRef.current?.errors;
    if (!errors.bypassReason && !errors.personnel) {
      //checking whether navigate from diff screen - if so handle dialogbox functionality
      if (enableCustomerAuth) {
        let verify = await props.verifyAuth(
          RequestStatusIds.Cancelled,
          BypassId
        );
        //verify whether the personnel has an perpermission on cancel
        if (verify) {
          handleAfterAutheticate("Bypass", values);
          formikRef.current.setFieldValue("bypassError", false);
        } else {
          props.setEnableCustomerAuth(false);
        }
      } else {
        // if the customer is authenticate
        handleAfterAutheticate("Bypass", values);
        formikRef.current.setFieldValue("bypassError", false);
      }
    } else {
      // showing error in form field for bypass reason
      formikRef.current.setFieldValue("personnel", true);
      formikRef.current.setFieldValue("bypassError", true);
    }
  };
  const handlePersonnelChange = (e, value) => {
    // setting the state when new personnel is clicked
    formikRef.current.setFieldValue("personnelValue", value);
    formikRef.current.setFieldValue("personnel", value?.value);

    formikRef.current.setFieldValue("authNumber", "");
    formikRef.current.setFieldValue("challengeResponse", "");
    formikRef.current.setFieldValue("bypassReason", "");
    // call sp's to get the authentication details  of the customer
    if (value?.value) {
      setPageLoading((prevState) => true);
      triggerAuthNoQuesInfo({
        personnelId: value?.value.toString()
      })
        .unwrap()
        .then((authVerficationInfo) => {
          const error = authVerficationInfo && authVerficationInfo[0]?.error;
          if (error) {
            setIsError(true);
            setErrorMessage(findErrorMessage(authVerficationInfo[0]?.error));
          } else {
            //ChallengeQuestion
            const challengeQuestion = authVerficationInfo.find(
              (i) => i.personnel_id.toString() === value?.value.toString()
            );
            formikRef.current.setFieldValue(
              "challengeQuestion",
              challengeQuestion?.secret_question
            );
            // setting the email
            formikRef.current.setFieldValue(
              "business_email_addr",
              authVerficationInfo[0]?.business_email_addr
            );

            challengeQuestion &&
              formikRef.current.setFieldValue(
                "resultChallengeAns",
                challengeQuestion?.secret_answer
              );
            //Auth Number
            const resultAuth = authVerficationInfo.find(
              (i) => i.personnel_id.toString() === value?.value.toString()
            );
            resultAuth &&
              formikRef.current.setFieldValue(
                "resultAuth",
                resultAuth?.auth_number
              );
            formikRef.current.setFieldValue("openAuth", true);
            formikRef.current.setFieldValue("bypassError", false);
            // setting the customer list
            formikRef.current.setFieldValue(
              "customer_list",
              JSON.parse(authVerficationInfo[0]?.customer_list)
            );
          }
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        })
        .finally(() => setPageLoading((prevState) => false));
    } else {
      formikRef.current.setFieldValue("openAuth", false);
    }
  };
  const byPassedName = () => {
    return readOnlyTextField(t("pickup.bypassedBy"), authInfo?.displayName);
  };
  const authenticationWraning = (value, authorizationType) => {
    return (
      <Collapse in={value}>
        <Alert icon={<img src={ErrorIcon} alt="Warning" />} severity="error">
          {t("rescheduleService.authAlert")}
        </Alert>
      </Collapse>
    );
  };
  const readOnlyTextField = (label, value) => {
    return (
      <Box sx={MDRPickupFormStyles.readOnlyTextField}>
        <TextField
          id={label.toLowerCase().replace(/ /g, "-")}
          label={label}
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            sx: { ...MDRPickupFormStyles.fontStyle }
          }}
          InputLabelProps={{
            shrink: true,
            focused: false,
            sx: { ...MDRPickupFormStyles.fontStyle }
          }}
          value={value}
        />
      </Box>
    );
  };
  return (
    <Formik
      initialValues={personnelAuthState}
      validationSchema={pickupSchema}
      innerRef={formikRef}
    >
      {({
        values,
        errors,
        handleBlur,
        touched,
        handleChange,
        setFieldValue
      }) => {
        return (
          <>
            {/* Full page loader */}
            <CircularLoaderFullPage
              id="container-research-full-page-loader"
              loading={pageLoading}
            />

            <Form>
              {/* <<<<======= Authentication warning in dialog box of cancel  =====>>>>*/}
              {enableCustomerAuth &&
                values.authFailedAlertOpen &&
                values.authorizationType !== "Bypass" && (
                  <Box sx={{ marginTop: "20px" }}>
                    {" "}
                    {authenticationWraning(
                      values.authFailedAlertOpen,
                      values.authorizationType
                    )}
                  </Box>
                )}
              {/* <<<<======= Error Alert =====>>>>*/}
              {
                <>
                  {isError && enableCustomerAuth && (
                    <Grid
                      item
                      xs={12}
                      sx={PersonnelAuthenticationFormStyles.errorAlert}
                    >
                      <Alert
                        severity="error"
                        id="warning"
                        icon={<img src={ErrorIcon} alt="error" />}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            id="closeButton"
                            size="small"
                            onClick={() => {
                              setIsError(false);
                              setErrorMessage("");
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMessage}
                      </Alert>
                    </Grid>
                  )}
                </>
              }
              <Stack
                gap={spacing.horizontalMargin20}
                sx={
                  enableCustomerAuth
                    ? MDRPickupFormStyles.paddingStyle
                    : undefined
                }
              >
                {/* <<<<======= Personnel Authetication  =====>>>>*/}
                {!enableCustomerAuth && (
                  <Stack>
                    <Typography variant="h5">
                      {t("rescheduleService.personnelAuth")}
                    </Typography>
                  </Stack>
                )}

                {/* <<<<======= Personnel Field Section  =====>>>>*/}
                <Stack
                  direction={"row"}
                  gap={spacing.verticalMargin20}
                  sx={MDRPickupFormStyles.successBox}
                >
                  <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                    <Autocomplete
                      id="Personnel"
                      labelId="Personnel-label"
                      options={personnelIds ? personnelIds : []}
                      value={values.personnelValue}
                      onChange={handlePersonnelChange}
                      clearIcon={<ClosedIcon />}
                      popupIcon={
                        customer === null ||
                        (enableCustomerAuth !== undefined &&
                          !enableCustomerAuth) ||
                        props.authVerified ? (
                          <ArrowDownDisabled />
                        ) : (
                          <ArrowDown />
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          id="personnel_textfield"
                          {...params}
                          name="personnel"
                          label="Personnel"
                          value={params?.label || ""}
                          error={touched.personnel && Boolean(errors.personnel)}
                          helperText={touched.personnel && errors.personnel}
                          onBlur={handleBlur}
                        />
                      )}
                      disabled={
                        customer === null ||
                        (enableCustomerAuth !== undefined &&
                          !enableCustomerAuth) ||
                        props.authVerified
                      }
                      sx={MDRPickupFormStyles.FieldWidthMedium}
                    />
                  </Box>
                  {/* <<<<======= Bypass success Section  =====>>>>*/}

                  {values.authSuccessType === "Bypass" &&
                    !enableCustomerAuth && (
                      <Box>
                        <Stack
                          direction={"row"}
                          gap={spacing.verticalMargin20}
                          sx={MDRPickupFormStyles.successBox}
                        >
                          <Box
                            sx={MDRPickupFormStyles.BranchLabelBoxNonTextField}
                          >
                            <Typography
                              variant="caption"
                              id="bypassedby"
                              sx={MDRPickupFormStyles.BranchLabel}
                            >
                              {t("pickup.bypassedBy")}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              id="bypassName"
                              sx={MDRPickupFormStyles.branchName}
                            >
                              {authInfo?.displayName}
                            </Typography>
                          </Box>

                          <Box>{successMsg("Bypassed")}</Box>
                        </Stack>
                      </Box>
                    )}
                  {/* <<<<======= Authenticated success Section  =====>>>>*/}
                  {values.authSuccessType === "authenticate" &&
                    !enableCustomerAuth && (
                      <Box>{successMsg("Authenticated")}</Box>
                    )}
                </Stack>

                {(values.openAuth || enableCustomerAuth) && (
                  <Box>
                    <FormControl disabled={customer === null}>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={values.authorizationType}
                        sx={MDRPickupFormStyles.radioGroup}
                        onChange={(_, value) => {
                          setFieldValue("authorizationType", value);
                          value === "Bypass" &&
                            enableCustomerAuth &&
                            setAuthFailedCancel(true);
                          value !== "Bypass" &&
                            enableCustomerAuth &&
                            values.authFailedAlertOpen &&
                            setAuthFailedCancel(false);
                        }}
                      >
                        <FormControlLabel
                          id="personnel-authentication"
                          value="personnelAuthentication"
                          control={<Radio />}
                          sx={MDRPickupFormStyles.radioButton}
                          label="Personnel Authentication"
                        />
                        <FormControlLabel
                          id="bypass"
                          value="Bypass"
                          control={<Radio />}
                          sx={MDRPickupFormStyles.radioButton}
                          label="Bypass"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}
                {/* Personnel Authentication */}
                {values.authorizationType === "personnelAuthentication" &&
                  (values.openAuth || enableCustomerAuth) && (
                    <>
                      <Box>
                        <Typography variant="body2" id="authInfo">
                          {t("rescheduleService.authInfo")}
                        </Typography>
                      </Box>
                      <Stack
                        direction={enableCustomerAuth ? "column" : "row"}
                        gap={spacing.horizontalMargin20}
                      >
                        <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                          <TextField
                            id="authNumber"
                            label={t("rescheduleService.authNumber")}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={values.authNumber}
                            onChange={handleChange}
                            error={Boolean(errors.authNumber)}
                            helperText={errors.authNumber}
                            disabled={
                              customer === null ||
                              Boolean(values.challengeResponse)
                            }
                            sx={MDRPickupFormStyles.FieldWidthMedium}
                          />
                        </Box>
                        {values.challengeQuestion && (
                          <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                            <TextField
                              id="challengeQuestion"
                              label={t("rescheduleService.challengeQuestion")}
                              size="medium"
                              variant="standard"
                              value={values.challengeQuestion}
                              error={Boolean(errors.challengeQuestion)}
                              helperText={errors.challengeQuestion}
                              fullWidth
                              onChange={handleChange}
                              InputProps={{
                                readOnly: true,
                                disableUnderline: true
                              }}
                              InputLabelProps={{
                                shrink: true,
                                focused: false
                              }}
                            />
                          </Box>
                        )}
                        {values.challengeQuestion && (
                          <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                            <TextField
                              id="challengeResponse"
                              label={t("rescheduleService.challengeResponse")}
                              type="password"
                              disabled={
                                Boolean(!values.challengeQuestion) ||
                                Boolean(values.authNumber)
                              }
                              InputProps={{
                                readOnly: !values.challengeQuestion
                              }}
                              size="medium"
                              variant="outlined"
                              value={values.challengeResponse}
                              error={Boolean(errors.challengeResponse)}
                              helperText={errors.challengeResponse}
                              fullWidth
                              onChange={handleChange}
                            />
                          </Box>
                        )}
                      </Stack>
                      {enableCustomerAuth &&
                        values.authorizationType ===
                          "personnelAuthentication" && (
                          <Divider sx={MDRPickupFormStyles.dividerPadding} />
                        )}
                      <Stack direction={"row"}>
                        {enableCustomerAuth && (
                          <Box
                            sx={PersonnelAuthenticationFormStyles.cancelButton}
                          >
                            {cancelButton()}
                          </Box>
                        )}
                        <Box>
                          <Button
                            id="authenticate"
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={PersonnelAuthenticationFormStyles.Button}
                            onClick={handleAuthenticate}
                            disabled={customer === null}
                          >
                            {t("rescheduleService.authenticate")}
                          </Button>
                        </Box>
                        {!enableCustomerAuth &&
                          values.authorizationType !== "Bypass" && (
                            <Box sx={{ ml: spacing.horizontalMargin20 }}>
                              {" "}
                              {authenticationWraning(
                                values.authFailedAlertOpen,
                                values.authorizationType
                              )}
                            </Box>
                          )}
                      </Stack>
                    </>
                  )}
                {/* Bypass section */}
                {values.authorizationType === "Bypass" &&
                  (values.openAuth || enableCustomerAuth) && (
                    <Stack
                      direction={enableCustomerAuth ? "column" : "row"}
                      gap={spacing.horizontalMargin20}
                    >
                      {enableCustomerAuth && byPassedName()}
                      <Box
                        sx={
                          enableCustomerAuth
                            ? MDRPickupFormStyles.challengeBox
                            : MDRPickupFormStyles.pickupInstructions
                        }
                      >
                        <TextField
                          name="bypassReason"
                          id="bypassReason"
                          label="Bypass Reason"
                          size="medium"
                          variant="outlined"
                          multiline
                          fullWidth
                          rows={2}
                          value={values.bypassReason}
                          onChange={handleChange}
                          error={
                            (touched.bypassReason || values.bypassError) &&
                            Boolean(errors.bypassReason)
                          }
                          helperText={
                            (touched.bypassReason || values.bypassError) &&
                            errors.bypassReason
                          }
                          disabled={customer === null}
                          onBlur={handleBlur}
                        />
                      </Box>
                      {!enableCustomerAuth && byPassedName()}
                    </Stack>
                  )}

                {/* button */}

                {enableCustomerAuth &&
                  values.authorizationType === "Bypass" && (
                    <Divider sx={MDRPickupFormStyles.dividerPadding} />
                  )}

                {values.authorizationType === "Bypass" &&
                  (values.openAuth || enableCustomerAuth) && (
                    <Stack direction={"row"}>
                      {enableCustomerAuth && (
                        <Box
                          sx={PersonnelAuthenticationFormStyles.cancelButton}
                        >
                          {cancelButton()}
                        </Box>
                      )}
                      <Button
                        id="Bypass"
                        variant="contained"
                        color="primary"
                        size="medium"
                        sx={MDRPickupFormStyles.bypassButton}
                        onClick={handleBypass}
                        disabled={customer === null}
                      >
                        {t("pickup.bypassButton")}
                      </Button>
                    </Stack>
                  )}
              </Stack>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default PersonnelAuthentication;
