import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import { SvgIcon, TableSortLabel } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import { PreferencesStyles } from "../PreferencesStyles";
import { preferenceColumns } from "./TableColumns";
function Row({ row, columns, handlerediectpage, level = 0 }) {
  const { roleAccess } = useSelector(selectAuth);
  const [open, setOpen] = React.useState(false);
  const children = row.children;
  // Determine if icons should be displayed for the parent row
  const shouldShowParentIcons = row.roleAccess && row.roleAccess.length > 0;
  const hasAccess = row?.roleAccess?.some((role) => roleAccess.includes(role));
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Box sx={PreferencesStyles.tableIconStyles}>
            <Box>
              {children.length ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : null}
            </Box>
            <Box sx={PreferencesStyles.marginLeftAuto}>
              {columns.map((column) => {
                return (
                  <Box key={column.name}>
                    {column.name === "name" && shouldShowParentIcons ? (
                      <>
                        {hasAccess ? (
                          <SvgIcon
                            sx={PreferencesStyles.iconsMargin}
                            component={EditIcon}
                            onClick={() => handlerediectpage(row)}
                          />
                        ) : (
                          <SvgIcon
                            sx={PreferencesStyles.iconsMargin}
                            component={ViewIcon}
                            onClick={() => handlerediectpage(row)}
                          />
                        )}
                      </>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </TableCell>
        {columns.map((column) => {
          return <TableCell key={column.name}>{row[column.name]}</TableCell>;
        })}
      </TableRow>
      {children.length && open
        ? children.map((child) => (
            <Row
              key={child.preference_type_id}
              row={child}
              columns={columns}
              level={level + 1} // Increase indentation level for child rows
            />
          ))
        : null}
    </React.Fragment>
  );
}

export const CollapsibleTable = ({ data, handlerediectpage }) => {
  const columns = preferenceColumns();
  const [sortConfig, setSortConfig] = React.useState({
    key: "",
    direction: "asc"
  });

  const sortedData = React.useMemo(() => {
    if (sortConfig.key === "") {
      return data;
    }
    let sortableData = [...data];
    sortableData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sortableData;
  }, [data, sortConfig]);

  const handleSort = (column) => {
    const direction = sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: column.name, direction });
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={PreferencesStyles.tableHeaderColor}>
            <TableCell />
            {columns.map((column) => (
              <TableCell key={column.name}>
                <TableSortLabel onClick={() => handleSort(column)}>
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row) => (
            <Row
              key={row.preference_type_id}
              row={row}
              columns={columns}
              handlerediectpage={handlerediectpage}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
