import { Stack } from "@mui/material";
import LabelTemplateSearchScreen from "components/customer-information/label-template-search/LabelTemplateSearchScreen";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const LabelTemplateSearch = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("labelTemplateSearch.title")}`} />
        <LabelTemplateSearchScreen />
      </Stack>
    </>
  );
};

export default LabelTemplateSearch;
