import { Autocomplete, Button, Grid, Stack, TextField } from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { ALL_SELECTION, VALUE_ALL } from "utils/constants";
import { eventTypeIDInputProps } from "utils/constants/disaster-module/EventSearchConstants";

const EventSearchFilter = ({
  mrdForm,
  customers,
  customerSelected,
  handleCustomerChange,
  statusItems,
  statusItemSelected,
  setStatusItemSelected,
  eventTypes,
  eventTypeSelected,
  setEventTypeSelected,
  drEventId,
  setDrEventId,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  isFindEnabled,
  setIsFindEnabled,
  setResultSet,
  setIsClearClicked,
  SearchFilterForm,
  localeByBranch,
  DEFAULT_LOCALE,
  currentDate,
  timeZoneOffsetChanged
}) => {
  const { t } = useTranslation();
  const minDate = dayjs(t("disasterRecovery.eventSearch.minimumDate"));

  const customersFieldProps = {
    options: customers,
    value: customerSelected,
    required: false,
    handleOnChange: handleCustomerChange,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  return (
    <form onSubmit={mrdForm?.handleSubmit}>
      <Stack direction={"column"} gap={spacing.gap}>
        <Grid container spacing={spacing.horizontalMargin20}>
          {/* ---------- Customer selection ----------   */}
          <Grid item xs={3}>
            <CustomersField {...customersFieldProps} />
          </Grid>

          {/* ---------- Status selection ----------   */}
          <Grid item xs={3}>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              popupIcon={<ArrowDown />}
              clearIcon={<CloseIcon />}
              id="status-filter"
              name={t("disasterRecovery.eventSearch.statusLabel")}
              options={statusItems}
              size="medium"
              value={statusItemSelected}
              onChange={(event, newValue) => {
                setIsFindEnabled(true);
                setResultSet([]);
                if (newValue) {
                  setStatusItemSelected(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("disasterRecovery.eventSearch.statusLabel")}
                />
              )}
            />
          </Grid>

          {/* ---------- DR Event Id filter ----------   */}
          <Grid item xs={3}>
            <TextField
              label={t("disasterRecovery.eventSearch.drEventIdLabel")}
              id="drEventId"
              size="medium"
              fullWidth
              value={drEventId}
              onChange={(drEvent) => {
                if (/^[0-9]*$/.test(drEvent.target.value)) {
                  if (drEvent.target.value.length > 9) {
                    drEvent.target.value = drEvent.target.value.slice(0, 9);
                  } else {
                    setDrEventId(drEvent.target.value);
                    setIsFindEnabled(true);
                    setResultSet([]);
                    setStatusItemSelected(ALL_SELECTION);
                  }
                }
              }}
              inputProps={eventTypeIDInputProps}
            />
          </Grid>

          {/* ---------- Event Type selection filter ----------   */}
          <Grid item xs={3}>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              popupIcon={<ArrowDown />}
              clearIcon={<CloseIcon />}
              id="event-type-filter"
              name={t("disasterRecovery.eventSearch.eventTypeLabel")}
              options={eventTypes}
              size="medium"
              value={eventTypeSelected}
              fullWidth
              onChange={(event, newValue) => {
                setIsFindEnabled(true);
                setStatusItemSelected(ALL_SELECTION);
                setResultSet([]);
                if (newValue) {
                  setEventTypeSelected(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("disasterRecovery.eventSearch.eventTypeLabel")}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={spacing.horizontalMargin20}>
          <Grid item xs={3}>
            <DatePickerField
              id="fromDate"
              label={t("disasterRecovery.eventSearch.dateFromLabel")}
              locale={localeByBranch || DEFAULT_LOCALE}
              variant="input"
              key={timeZoneOffsetChanged}
              value={fromDate}
              minDate={minDate}
              maxDate={currentDate.add(6, "month")}
              onChange={async (date) => {
                setResultSet([]);
                // Update toDate without triggering touched event
                const toDateWithoutTouched = (date) => {
                  SearchFilterForm.setFieldValue("toDate", date);
                  setToDate(date);
                };

                await new Promise((resolve) => {
                  // Update fromDate and trigger touched event
                  SearchFilterForm.setFieldValue("fromDate", date);
                  setFromDate(date);
                  resolve();
                });

                toDateWithoutTouched(date);

                statusItemSelected?.value !== VALUE_ALL &&
                  setStatusItemSelected(ALL_SELECTION);
              }}
              error={!!SearchFilterForm.errors.fromDate}
              helperText={SearchFilterForm.errors.fromDate}
            />
          </Grid>

          <Grid item xs={3}>
            <DatePickerField
              id="toDate"
              label={t("disasterRecovery.eventSearch.dateToLabel")}
              locale={localeByBranch || DEFAULT_LOCALE}
              variant="input"
              key={timeZoneOffsetChanged}
              value={toDate}
              minDate={minDate}
              maxDate={currentDate.add(6, "month")}
              onChange={async (date) => {
                setResultSet([]);
                await new Promise((resolve) => {
                  SearchFilterForm.setFieldValue("toDate", date);
                  setToDate(date);
                  resolve();
                });
                statusItemSelected?.value !== VALUE_ALL &&
                  setStatusItemSelected(ALL_SELECTION);
              }}
              error={!!SearchFilterForm.errors.toDate}
              helperText={SearchFilterForm.errors.toDate}
            />
          </Grid>
        </Grid>

        <Stack direction="row" gap={spacing.gap}>
          <Button
            id="newBtnfilter"
            variant="outlined"
            onClick={() => {
              setIsClearClicked(true);
            }}
          >
            {t("common.clear")}
          </Button>
          <Button
            id="findBtnfilter"
            variant="contained"
            type="submit"
            disabled={!isFindEnabled}
          >
            {t("common.find")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default EventSearchFilter;
