import dayjs from "dayjs";
import { t } from "i18next";
import {
  BOOLEAN_STRING_VALUES,
  DEFAULT_LOCALE,
  falseValue,
  NO,
  YES
} from "utils/constants";
import {
  actionTypes,
  delivered
} from "utils/constants/open-media-processing/Research";
import { dateTimeFormatByLocale } from "utils/helpers";

let defaultAllOption = { label: t("common.all"), value: "" };
const { TRUE, FALSE } = BOOLEAN_STRING_VALUES;

const initialRearchState = {
  statusList: [],
  selectedSatus: { ...defaultAllOption },
  lvList: [],
  selectedLv: null,
  disabledLV: true,
  drpList: [],
  disabledDrp: true,
  selectedDRP: null,
  mediaList: [],
  mediaNumber: "",
  showDiscrepancy: false,
  disabledDiscrepancy: false,
  showDuplicates: false,
  disabledDuplicates: true,
  showMediaClosedContainers: false,
  disabledMediaClosedContainers: true,
  returnDate: null,
  mediaStartingNumber: "",
  startingSlot: "",
  endingSlot: "",
  description: "",
  loading: false,
  disablefindButton: false,
  disableSaveButton: true,
  disablePrintButton: true,
  disableDetailButton: true,
  disableCreateFileButton: true,
  tableData: [],
  omList: [],
  lvModalOptions: [],
  mediaTypeOptions: [],
  searchMade: false,
  resultCount: {
    found: undefined,
    not_found: undefined,
    actualCount: "",
    maxDisplayCount: "",
    maxReportCount: ""
  }
};

const fieldsReset = {
  mediaNumber: "",
  returnDate: null,
  mediaStartingNumber: "",
  startingSlot: "",
  endingSlot: "",
  showDiscrepancy: false,
  showDuplicates: false,
  showMediaClosedContainers: false,
  description: "",
  mediaList: []
};
const gridReset = {
  tableData: [],
  omList: [],
  disableSaveButton: true,
  disablePrintButton: true,
  disableDetailButton: true,
  disableCreateFileButton: true,
  resultCount: {
    found: undefined,
    not_found: undefined,
    actualCount: "",
    maxDisplayCount: "",
    maxReportCount: ""
  }
};

const researchReducer = (state, { type, payload, cb }) => {
  switch (type) {
    case actionTypes.customerChanged:
      return {
        ...state,
        ...fieldsReset,
        ...gridReset,
        ...payload,
        loading: false,
        disabledLV: false,
        disabledDrp: false,
        disabledDuplicates: false,
        disabledMediaClosedContainers: false,
        selectedLv: { ...defaultAllOption },
        selectedDRP: { ...defaultAllOption },
        mediaList: [],
        tableData: [],
        omList: [],
        disablefindButton: false
      };

    case actionTypes.customerCleared:
      return {
        ...state,
        ...fieldsReset,
        disabledLV: true,
        disabledDrp: true,
        disabledDuplicates: true,
        disabledMediaClosedContainers: true,
        lvList: [],
        mediaList: [],
        drpList: [],
        selectedLv: null,
        selectedDRP: null,
        ...gridReset
      };

    case actionTypes.getStatus:
      return { ...state, statusList: payload, loading: false };
    case actionTypes.changeStatus:
      return {
        ...state,
        selectedSatus: payload,
        disablefindButton: false,
        ...gridReset
      };
    case actionTypes.changeLV:
      return {
        ...state,
        selectedLv: payload,
        disablefindButton: false,
        ...gridReset
      };
    case actionTypes.changeDRP:
      return {
        ...state,
        selectedDRP: payload,
        disablefindButton: false,
        ...gridReset
      };

    case actionTypes.changeMediaList:
      if (payload.length) {
        return {
          ...state,
          mediaList: payload,
          selectedSatus: null,
          selectedLv: null,
          selectedDRP: null,
          returnDate: null,
          mediaNumber: "",
          disablefindButton: false,
          ...gridReset
        };
      }
      return {
        ...state,
        mediaList: payload,
        disablefindButton: false,
        ...gridReset
      };

    case actionTypes.changeDiscrepancy:
      return {
        ...state,
        showDiscrepancy: payload,
        disablefindButton: false,
        ...gridReset
      };
    case actionTypes.changeDuplicates:
      return {
        ...state,
        showDuplicates: payload,
        disablefindButton: false,
        ...gridReset
      };
    case actionTypes.changeMediaClosedContainers:
      return {
        ...state,
        ...fieldsReset,
        showMediaClosedContainers: payload,
        disablefindButton: false,
        ...gridReset,
        selectedLv: { ...defaultAllOption },
        selectedDRP: { ...defaultAllOption },
        selectedSatus: { ...defaultAllOption }
      };
    case actionTypes.chnageReturnDate:
      return {
        ...state,
        returnDate: payload,
        disablefindButton: false,
        ...gridReset
      };
    case actionTypes.changeMediaStaringNumber:
      return {
        ...state,
        mediaStartingNumber: payload,
        disablefindButton: false,
        ...gridReset,
        mediaNumber: ""
      };
    case actionTypes.changeStartingSlot: {
      let val = isNaN(payload) ? "" : payload;
      let endingSlot = state.endingSlot || falseValue;
      endingSlot = val;
      return {
        ...state,
        startingSlot: val,
        endingSlot,
        disablefindButton: false,
        ...gridReset
      };
    }
    case actionTypes.blurStartingSlot: {
      let slotVal = isNaN(payload) ? "" : payload;
      let endingSlotVal = state.endingSlot || falseValue;
      if (!slotVal && endingSlotVal) {
        slotVal = state.endingSlot;
      }
      return {
        ...state,
        startingSlot: slotVal,
        disablefindButton: false,
        ...gridReset
      };
    }
    case actionTypes.changeEndingSlot: {
      let val = isNaN(payload) ? "" : payload;
      return {
        ...state,
        endingSlot: val,
        disablefindButton: false,
        ...gridReset
      };
    }
    case actionTypes.blurEndingSlot: {
      let val = isNaN(payload) ? "" : payload;
      let startingSlot = state.startingSlot || falseValue;
      if (!val && startingSlot) {
        val = state.startingSlot;
      } else if (val && !startingSlot) {
        startingSlot = val;
      } else if (parseInt(startingSlot) > parseInt(val)) {
        cb();
        val = state.startingSlot;
      }
      return {
        ...state,
        startingSlot,
        endingSlot: val,
        disablefindButton: false,
        ...gridReset
      };
    }
    case actionTypes.changeDescription:
      return {
        ...state,
        description: payload,
        disablefindButton: false,
        ...gridReset
      };
    case actionTypes.loader:
      return {
        ...state,
        loading: payload
      };

    case actionTypes.populateTable: {
      let tableList = payload?.gridData
        ?.map((item) => ({
          customer_number: item.customer_number,
          volser: item.volser,
          logical_vault_code: item.logical_vault_code,
          media_type_type_descr: item.media_type_media_descr,
          location:
            item.current_status_code !== delivered &&
            item.part_of_dr_flag_bit === FALSE
              ? item.location
              : "",
          active_in_mdr_bit:
            (item.current_status_code !== delivered &&
              item.active_in_mdr_bit === TRUE) ||
            (!item.return_date && item.current_status_code !== delivered)
              ? YES
              : NO,
          return_date: dayjs(item.return_date).isValid()
            ? dateTimeFormatByLocale(
                item.return_date,
                payload.localeByBranch || DEFAULT_LOCALE
              ).split(" ")[0]
            : "",
          current_status_code: item.current_status_code,
          drp_code: item.drp_code,
          descr: item.descr,
          open_media_id: item.open_media_id,
          hasDiscrepancies: item.unresolved_discrepancies_flag_bit === TRUE
        }))
        .sort((a, b) => a.customer_number.localeCompare(b.customer_number));

      if (payload.isCustomerSelected) {
        tableList = tableList.sort((a, b) => a.volser.localeCompare(b.volser));
      }

      return {
        ...state,
        tableData: tableList,
        loading: false,
        disablePrintButton: tableList.length ? false : true,
        disableCreateFileButton: tableList.length ? false : true,
        omList: payload?.gridData.sort((a, b) =>
          a.volser.localeCompare(b.volser)
        ),
        lvModalOptions: payload.lvModalOptions,
        mediaTypeOptions: payload.mediaTypeOptions,
        disableSaveButton: true,
        disablefindButton: true,
        searchMade: true,
        resultCount: { ...payload.resultCount }
      };
    }
    case actionTypes.reset:
      return { ...initialRearchState, statusList: state.statusList };

    case actionTypes.rowSelect:
      if (state.showMediaClosedContainers) return { ...state };
      return { ...state, disableDetailButton: payload };
    case actionTypes.changeMediaNumber:
      return {
        ...state,
        mediaNumber: payload,
        disablefindButton: false,
        ...gridReset,
        mediaStartingNumber: ""
      };
    case actionTypes.dataUpdated:
      return { ...state, disableSaveButton: false };
    case actionTypes.updateTableData:
      return { ...state, ...payload };

    case actionTypes.tablePopulateClosedContainers:
      return {
        ...state,
        ...payload,
        disableCreateFileButton: true,
        disableDetailButton: true,
        searchMade: true,
        disablePrintButton: !payload?.tableData?.length
      };

    case actionTypes.initiateAfterBack:
      return {
        ...payload
      };

    case actionTypes.updateInProcess:
      return {
        ...state,
        disableCreateFileButton: true,
        disableDetailButton: true,
        disablePrintButton: true
      };
    case actionTypes.foundMoreThanMaxRecords:
      return {
        ...state,
        disablePrintButton: false,
        disableCreateFileButton: false,
        resultCount: { ...payload }
      };
    default:
      return state;
  }
};

export { initialRearchState, researchReducer };
