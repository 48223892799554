import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as FloatingPlus } from "assets/images/Plus.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { getResponseData } from "components/transportation-module/run-management/helper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import {
  ERROR_TYPES,
  NO,
  VALUE_EMPTY_STRING,
  YES,
  errorMsgs
} from "utils/constants";
import {
  columnDelimiter,
  recordDelimiter
} from "utils/constants/open-media-processing/BranchOutagePickListContants";
import { complete } from "utils/constants/open-media-processing/WorkManagement";
import { findErrorMessage } from "utils/helpers";
import AssignTransportModal from "./AssignTransportModal";
import { branchOutagePickListCompletionStyles } from "./BranchOutagePickListStyles";

const BranchOutagePickListModal = ({
  isModalOpen,
  selectedRow,
  setSelectedRow,
  mainDistrictId,
  customerId,
  setIsLoading,
  setThrowError,
  setThrowErrorMessage,
  setInfoBarDetails,
  isUpdateBtnEnabled,
  setIsUpdateBtnEnabled,
  setIsModalOpen,
  isSaved,
  setIsSaved
}) => {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [tempData, setTempData] = useState([]);
  const [recordList, setRecordList] = useState(VALUE_EMPTY_STRING);
  const { t } = useTranslation();

  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState(VALUE_EMPTY_STRING);

  const [displayText, setDisaplyText] = useState(VALUE_EMPTY_STRING);

  useEffect(() => {
    setTempData(selectedRow);
    setChecked(false);
    setIsUpdateBtnEnabled(false);

    // eslint-disable-next-line
  }, [selectedRow]);

  useEffect(() => {
    if (tempData?.assignTransportListArr?.length > 2) {
      const str = `${tempData?.assignTransportListArr
        ?.slice(0, 2)
        .map((item) => item["value"])
        ?.join(", ")}...`;
      setDisaplyText(str);
    } else {
      const str = tempData?.assignTransportListArr?.map(function (item) {
        return item["value"];
      });
      setDisaplyText(str);
    }
  }, [tempData]);

  useEffect(() => {
    const list =
      tempData?.assignTransportListArr?.length > 0
        ? tempData?.assignTransportListArr?.map((obj) => obj?.value)
        : [];
    const recordListData = `${
      selectedRow?.customer_file_section_id
    };${list.join(";")}|`;

    setRecordList(recordListData);

    // eslint-disable-next-line
  }, [tempData]);

  useEffect(() => {
    if (isSaved) {
      setSelectedRow(tempData);
    }
    // eslint-disable-next-line
  }, [isSaved, tempData]);

  useEffect(() => {
    if (isModalOpen) {
      setIsUpdateBtnEnabled(checked);
      const selectedRowUpdate = {
        ...tempData,
        completeList: checked ? YES : NO,
        task_status: checked ? complete : tempData?.task_status
      };
      setTempData(selectedRowUpdate);
    }

    // eslint-disable-next-line
  }, [checked]);

  // close the modal
  const handleModalClose = () => {
    if (isUpdateBtnEnabled) {
      // show unsaved warning
      setWarningMessage((prev) => t("common.saveChangesNow"));
      setShowWarning((prev) => !prev);
    } else {
      setIsModalOpen((prev) => !prev);
    }
  };

  // handle Row Click
  const handleRowClick = (value, tableMeta) => {
    checked && setIsAssignModalOpen((prev) => !prev);
  };

  const handleOnCheck = (event) => {
    setChecked(event.target.checked);
  };

  const onSaveClickOnModal = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        recordlist: recordList,
        record_delimiter: recordDelimiter,
        column_delimiter: columnDelimiter,
        max_record_length: (recordList?.length + 1)?.toString()
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.completeOutagePickList,
        3
      );

      const obj = dataSets?.data;

      let details = VALUE_EMPTY_STRING;
      let msg = VALUE_EMPTY_STRING;

      if (obj) {
        if (obj[0]?.length > 0) {
          /**
           * Dataset 0 - Array of TR list data: Display details in the infoBar
           * Dataset 1 - Get the batch_id and update the row
           * Dataset 2 - Use error code to display the infoBar message
           */
          if (obj && obj[0]?.length > 0) {
            details = obj[0];
          }

          if (obj && obj[1]?.length > 0) {
            setIsSaved(true);

            setTempData((prev) => ({
              ...prev,
              batch_id: obj[1][0]?.batch_id
            }));
          }

          if (obj && obj[2]?.length > 0) {
            const msgId = obj[2][0]?.error;

            if (obj[2][0]?.error === errorMsgs.errorCode25385) {
              const msgData = await getMessageFromCode(msgId);
              msg = msgData[0]?.descr;
            }
          }
        } else {
          /**
           * Dataset 0 - Empty
           * Dataset 1 - Get the batch_id and update the row
           * Dataset 2 - error 0 if success
           */

          if (obj && obj[1]?.length > 0) {
            setIsSaved(true);

            setTempData((prev) => ({
              ...prev,
              batch_id: obj[1][0]?.batch_id
            }));
          }
        }
      }

      const infoBarData = {
        msg: msg,
        details: details
      };
      setInfoBarDetails(infoBarData);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);

      setShowWarning((prev) => false);
      setIsModalOpen((prev) => false);
    }
  };

  // close the modal
  const handleAssignModalClose = (tempList) => {
    setIsAssignModalOpen((prev) => false);
  };

  const onOkClick = (tempList) => {
    setIsAssignModalOpen(false);
    setTempData((prev) => ({
      ...prev,
      assignTransportListArr: tempList
    }));
  };

  const onNoClick = () => {
    setShowWarning((prev) => false);
    setIsModalOpen((prev) => false);
  };

  return (
    <>
      {showWarning && (
        <ConfirmPopup
          message={
            warningMessage === VALUE_EMPTY_STRING ? null : warningMessage
          }
          modalPopupOpen={showWarning}
          handleCancel={() => {
            setWarningMessage((prev) => VALUE_EMPTY_STRING);
            setShowWarning((prev) => false);
          }}
          handleYes={onSaveClickOnModal}
          handleNo={onNoClick}
          showCancel={true}
          showNo={true}
        />
      )}

      <CommonModal
        open={isModalOpen}
        title={t("branchOutagePickList.updateBranchOutagePickList")}
        body={
          <>
            <Grid
              container
              spacing={2}
              sx={branchOutagePickListCompletionStyles.pickListModalMainGrid}
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("branchOutagePickList.customer")}
                </Typography>
                <Typography variant="subtitle1">
                  {tempData?.customer_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("branchOutagePickList.list")}
                </Typography>
                <Typography variant="subtitle1">{tempData?.list}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("branchOutagePickList.batch")}
                </Typography>
                <Typography variant="subtitle1">
                  {tempData?.batch_id}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("branchOutagePickList.serviceDate")}
                </Typography>
                <Typography variant="subtitle1">
                  {tempData.formattedServiceDate}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleOnCheck} />
                  }
                  label={t("branchOutagePickList.label")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={branchOutagePickListCompletionStyles.transportAddBox1}>
                  <Box
                    sx={branchOutagePickListCompletionStyles.transportAddBox1}
                  >
                    <Box
                      sx={branchOutagePickListCompletionStyles.transportAddBox2}
                    >
                      <TextField
                        disabled={!checked}
                        label={t(
                          "branchOutagePickList.updateBranchOutagePickList"
                        )}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true, style: { zIndex: 0 } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleRowClick}
                                sx={
                                  branchOutagePickListCompletionStyles.addbutton
                                }
                              >
                                <SvgIcon component={FloatingPlus} />
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: branchOutagePickListCompletionStyles.wrapStyles
                        }}
                        value={displayText}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button onClick={handleModalClose} variant="outlined">
              {t("common.cancel")}
            </Button>
            <Button
              onClick={onSaveClickOnModal}
              variant="contained"
              disabled={!isUpdateBtnEnabled}
            >
              {t("common.update")}
            </Button>
          </>
        }
      />
      <AssignTransportModal
        isAssignModalOpen={isAssignModalOpen}
        handleAssignModalClose={handleAssignModalClose}
        mainDistrictId={mainDistrictId}
        customerId={customerId}
        selectedRow={tempData}
        setIsLoading={setIsLoading}
        onOkClick={onOkClick}
      />
    </>
  );
};
export default BranchOutagePickListModal;
