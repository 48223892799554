import { Typography } from "@mui/material";

function Title({ variant, component, text, styles, ...props }) {
  return (
    <Typography variant={variant} component={component} sx={styles} {...props}>
      {text}
    </Typography>
  );
}

export default Title;
