import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Typography
} from "@mui/material";
import { popupStyles } from "./Popup.Style";
function ModalPopup({
  title,
  modalPopupOpen,
  handleClick,
  alertMessage,
  modalButton
}) {
  return (
    <Paper elevation={24}>
      <Dialog
        open={modalPopupOpen}
        fullWidth={true}
        PaperProps={{
          sx: {
            ...popupStyles.dialogModal
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={popupStyles.paddingTitleStyle}>
          <Typography sx={popupStyles.titleStyle}>{title}</Typography>
        </DialogTitle>
        <Divider />
        {/* ------------------ Body Section ------------------ */}{" "}
        <DialogContent sx={popupStyles.cardContentStyles}>
          <Typography sx={popupStyles.alertMessage} id="modal-description">
            {alertMessage}
          </Typography>{" "}
        </DialogContent>
        <Divider />
        {/* ------------------ Footer Section ------------------ */}{" "}
        <DialogActions sx={popupStyles.modalButton}>
          {modalButton}{" "}
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default ModalPopup;
