import { t } from "i18next";
export const pickingOptionReadyToPickList = "1";
export const pickingOptionReadyToScan = "2";
export const pickingOptionNeedTransport = "3";
export const pickingOptionnonCompletedTask = "4";
export const pickingOptioncompletedBatches = "5";
// Distribute;
export const distributeOptionReadyToScan = "S";
export const distributeOptionReadyToDistribute = "D";
export const distributeOptionAll = "A";
export const distributeOptioncompletedBatches = "C";
export const distributeOptioncompletedCustomer = "B";
export const distributeAll = "0";
export const distributListReadyToScan = "1";
export const distributePastDue = "2";
export const distribution = "distribution";
export const Distribution = "Distribution";
export const initialServiceDays = "2";
export const close = "close";
export const sortEmptySLot = "ZZZZZZ";
// Verify
export const verifyFilterOptions = {
  readyToVerify: "R",
  batchesWaitingForList: "W",
  all: "A",
  skippedBatches: "S"
};
export const verifyDefaultValues = {
  lastBatches: "5",
  lastDays: "1"
};

export const verifyConstants = {
  lvForListGrid: "-1",
  statusID: "1",
  recVal: "rec",
  lvVal: "lv",
  custVal: "cust",
  zeroNode: t("workManagement.verifyTab"),
  levels: {
    customer: "customer",
    vault: "vault",
    record: "record"
  },
  lvIM: "IM and Customer Distribution Lists",
  tbl3Cols: {
    media: "media #",
    mType: "media type",
    scan: "scan seq.",
    empIni: "employee_initials"
  }
};

export const pickingFilterOptions = [
  {
    label: t("workManagement.picking.readyToPickList"),
    value: pickingOptionReadyToPickList
  },
  {
    label: t("workManagement.picking.readyToScan"),
    value: pickingOptionReadyToScan
  },
  {
    label: t("workManagement.picking.needTransport"),
    value: pickingOptionNeedTransport
  },
  {
    label: t("workManagement.picking.nonCompletedTask"),
    value: pickingOptionnonCompletedTask
  },
  {
    label: t("workManagement.picking.completedBatches"),
    value: pickingOptioncompletedBatches
  }
];

//Tabs
export const pickingTab = "Picking";
export const distributionTab = "Distribution";
export const verifyTab = "Verify";
export const inventoryTab = "Inventory";

export const pickingTabId = 0;
export const distributionTabId = 1;
export const verifyTabId = 2;
export const inventoryTabId = 3;
//distribute
export const distributeFilterOptions = [
  {
    label: t("workManagement.distribution.readyToScan"),
    value: distributeOptionReadyToScan
  },
  {
    label: t("workManagement.distribution.readyToDistribute"),
    value: distributeOptionReadyToDistribute
  },
  {
    label: t("workManagement.distribution.allOption"),
    value: distributeOptionAll
  },
  {
    label: t("workManagement.distribution.completedBatches"),
    value: distributeOptioncompletedBatches
  }
];

//verify tab
export const verifyTabFilterOptions = [
  {
    label: t("workManagement.verify.readyToVerify"),
    value: verifyFilterOptions.readyToVerify
  },
  {
    label: t("workManagement.verify.batchesWaitList"),
    value: verifyFilterOptions.batchesWaitingForList
  },
  {
    label: t("workManagement.verify.all"),
    value: verifyFilterOptions.all
  },
  {
    label: t("workManagement.verify.skippedBatch"),
    value: verifyFilterOptions.skippedBatches
  }
];

export const limit = 10;
export const insertSizeDefaultValue = "20";
export const typeCustomer = "customer";
export const typePick = "pick";
export const typeLogicalVault = "logicalVault";
export const customerPrefix = "cust-";
export const pendingCapcity = "Pending Capacity Increase";
export const logicalVaultPrefix = "cust-logicalVault-";
export const statusStarted = "Started";
export const iconType1 = "1";
export const iconType2 = "2";
export const iconType3 = "3";
export const iconType4 = "4";
export const iconType5 = "5";
export const pickingNodeId = "picking";
export const notStarted = "Not Started";
export const complete = "Complete";
export const started = "Started";
export const maxWithinDays = 30;
export const serviceDays10 = 10;
export const serviceDays2 = 2;
export const dayCount = 1;
export const all = "all";

// Inventory
export const InventoryCompareResultsColumns = [
  {
    name: "volser",
    label: t("workManagement.inventory.columnMediaNumber")
  },
  {
    name: "type",
    label: t("workManagement.inventory.columnMediaType")
  },
  {
    name: "exception",
    label: t("workManagement.inventory.columnException")
  },

  {
    name: "original_detected_datetime",
    label: t("workManagement.inventory.columnOriginallyDetectedOn")
  }
];

export const actionTypes = {
  setInfo: "SET_INFO",
  reset: "RESET"
};

export const global_attribute_id = "34";
export const typeItem = "item";
export const inventoryNodeId = "inventory";

export const discrepancyTypeCodeMissing = "Missing";
export const discrepancyTypeCodeUnexpected = "Unexpected";
export const sortOrderOptions = [
  { label: "Location", value: 1 },
  { label: "Media #", value: 2 }
];

export const workManagementRoleAccess = {
  code176: "176", // Inventory access
  code197: "197", // Verify Access
  code174: "174", // Distribution Access
  code169: "169", // Picking Access
  code198: "198",
  code199: "199"
};

export const buttonTasks = {
  printPickRpt: "Print Pick Rpt",
  printShiplistRpt: "Print ShipList Rpt",
  cancelBatch: "Cancel Batch",
  assignTransport: "Assign Transport",
  printLabel: "Print Label",
  changeServiceDate: "Change Service Date"
};

export const taskStatuses = {
  notStarted: "Not Started",
  started: "Started",
  pickReportPrinted: "Pick Report Printed",
  complete: "Complete",
  needsTransport: "Needs Transport"
};

export const modalCase = {
  printPickRpt: "printPickReport",
  invalidCancelRpt: "includeInvalidcancelReport",
  cancelRpt: "cancelReport",
  splitList: "splitList",
  printShipList: "printShipList",
  printLabel: "printLabel"
};

export const paperType = {
  a4: "A4",
  letter: "Letter"
};

export const notePad = "NotePad";
export const zero = "0";
export const strZeros_PadIdwithZero = "0000000000";
export const system_function_id = "49";
export const requestType6 = 6;
export const defaultInsertSize20 = "20";
export const setting_type_id = "1001";
export const rptTransportLabelA4 = "rp_transport_labels_a4";
export const rptTransportLabel = "rp_transport_labels";
export const landscape = "L";

// Open Media - Work Management tab values
export const tabValues = {
  picking: "picking",
  distribution: "distribution",
  verify: "verify",
  inventory: "inventory"
};
