import { ExpandMore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableContainer,
  TextField,
  Typography
} from "@mui/material";
import Link from "@mui/material/Link";
import ChevronLeftFilled from "assets/images/ChevronLeftFilled.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import {
  getCustomerByID,
  getHolidayServiceTypes,
  getMessageFromCode
} from "services/api/query";
import { selectAuth } from "store/slices";
import { updateAuthFormValues } from "store/slices/container-processing";
import {
  setDefaultBranchValues,
  setIsRefreshRequired,
  setShowAuthSection,
  updatePreferencesAuthentication
} from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import {
  authSection,
  dateFormatWithoutTime,
  dateFormatWithTime,
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  errorMsgs,
  N_CHECKER,
  preferenceTypeIdList,
  type,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import { getPreferenceTypesPayloadForReport } from "utils/constants/customer-information/CustomerPreference";
import { systemId } from "utils/constants/open-media-processing/OpenMediaAuditSearchConstants";
import { findErrorMessage, getResponseData } from "utils/helpers";
import PreferencesLayout from "../preferences-main/PreferencesLayout";
import PreferencesReoprtDownloadModal from "../PreferencesReoprtDownloadModal";
import { PreferencesStyles } from "../PreferencesStyles";
import { HolidayServiceStyles } from "./HolidayServiceStyles";

const HolidayService = () => {
  const { state = {} } = useLocation();
  const { isLoading, isAuthenticated, authFormValues } = useSelector(
    (state) => state.customerPreferences
  );
  const { auth, localeByBranch } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const [holidayServiceTypesList, setHolidayServiceTypesList] = useState([]);
  const [selectedHolidaySerType, setSelectedHolidaySerType] = useState(null);
  const [showPrintReportModal, setShowPrintReportModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [successInsertMessage, setSuccessInsertMessage] = useState("");
  const [selectedFeildName, setSelectedFeildName] = useState("");
  const columns = [
    {
      name: "",
      label: ""
    },
    {
      name: "change_history_datetime",
      label: t("holidayService.dateCol"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = tableData[dataIndex];
          const { change_history_datetime } = rowData;
          if (change_history_datetime) {
            const formattedDate = dayjs(change_history_datetime)
              .locale(localeByBranch || DEFAULT_LOCALE)
              .format(dateFormatWithoutTime);
            const timePart = change_history_datetime.split("T")[1];
            const result = timePart.split(".")[0];
            return `${formattedDate} ${result}`;
          }
        }
      }
    },
    {
      name: "iMEmployee",
      label: t("holidayService.imEmpCol")
    },
    {
      name: "authorizedBy",
      label: t("holidayService.authByCol")
    },
    {
      name: "oldValue",
      label: t("holidayService.preValCol")
    },
    {
      name: "newValue",
      label: t("holidayService.newValCol")
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: tableData?.length && true,
    textLabels: {
      body: {
        noMatch: tableData?.length === 0 && `${t("common.noRecordsFound")}`
      }
    },
    setRowProps: (row, dataIndex) => {
      return {
        style: {
          backgroundColor:
            dataIndex === selectedRow ? HolidayServiceStyles?.selectedColor : ""
        }
      };
    },
    onRowClick: (row, dataIndex) => handleRowClick(row, dataIndex)
  };

  // Row click handler
  const handleRowClick = (rowData, rowState) => {
    setSelectedRow(rowState.dataIndex);
  };

  const getSelectedCustomer = async (customer_id) => {
    setIsDataLoading(true);
    try {
      const customer = await getCustomerByID(customer_id);
      setSelectedCustomer(customer[0]);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsDataLoading(false);
    }
  };

  const getDropdownValues = async () => {
    setIsDataLoading(true);
    try {
      const holidayServiceTypes = await getHolidayServiceTypes();
      const mappedData = holidayServiceTypes?.map((h) => {
        return {
          ...h,
          label: h?.descr,
          value: h?.dropdown_list_id
        };
      });
      mappedData.sort((a, b) => a.display_order - b.display_order);
      setHolidayServiceTypesList(mappedData);
      setIsDataLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsDataLoading(false);
    }
  };

  const onSelectFieldChange = (item, fieldName, difVal = false) => {
    let pid = typeof item === type.string ? item : item?.target?.value;
    const findSelectedValue = holidayServiceTypesList.find(
      (option) => option.value === pid
    );
    setSelectedFeildName(findSelectedValue?.label);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    if (difVal) {
      setSelectedHolidaySerType(item);
    } else {
      setSelectedHolidaySerType(item?.target?.value);
    }
  };

  // navigate back to preference screen
  const goBack = () => {
    navigate(-1);
  };

  // print button click
  const onPrintButtonClick = () => {
    setShowPrintReportModal(true);
  };

  const onChangeAccordionExpand = (e, expanded) => {
    if (!expanded) {
      setTableData([]);
      return;
    }
    getPreferencesHistory();
  };

  const getPreferencesHistory = async () => {
    setIsDataLoading(true);
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: selectedCustomer?.id,
        preference_type_id_list: preferenceTypeIdList
      };

      const holidayResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerPreferences?.getHistoryData}`,
        3
      );

      const holidayError = holidayResponse?.data[2]; // get error code

      // if there is an error showing the error and exit from the process
      if (holidayError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          holidayError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsDataLoading(false);
        return;
      }

      const changeHistoryLookup = Object.fromEntries(
        holidayResponse?.data[1]?.map((item) => [item.change_history_id, item])
      );

      const result = holidayResponse?.data[0]?.map((item) => ({
        ...item,
        ...changeHistoryLookup[item.change_history_id]
      }));

      const mappedData = result?.map((d) => {
        const oldValue = holidayServiceTypesList?.find(
          (h) => h?.id === d?.old_value
        );
        const newValue = holidayServiceTypesList?.find(
          (h) => h?.id === d?.new_value
        );
        return {
          ...d,
          iMEmployee: d?.employee_last_name
            ? `${d?.employee_last_name}, ${d?.employee_first_name}`
            : "",
          authorizedBy: d?.personnel_last_name
            ? `${d?.personnel_last_name}, ${d?.personnel_first_name}`
            : "",
          oldValue: oldValue?.descr,
          newValue: newValue?.descr
        };
      });

      // Sorting function
      mappedData.sort((a, b) => {
        if (b.change_history_datetime < a.change_history_datetime) {
          return -1;
        }
        if (b.change_history_datetime > a.change_history_datetime) {
          return 1;
        }
        return 0;
      });

      setTableData(mappedData);
      setIsDataLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsDataLoading(false);
    }
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  const onEditButtonClick = () => {
    if (!isAuthenticated) {
      dispatch(setShowAuthSection(true));
    }
  };

  let initialAuthValues = {
    customer: VALUE_EMPTY_STRING,
    personnel: VALUE_EMPTY_STRING,
    authNumber: VALUE_EMPTY_STRING,
    challengeQuestion: VALUE_EMPTY_STRING,
    challengeResponse: VALUE_EMPTY_STRING,
    bypassMessage: VALUE_EMPTY_STRING
  };
  const clearAuth = async () => {
    if (isAuthenticated) {
      dispatch(updateAuthFormValues(initialAuthValues));
      dispatch(updatePreferencesAuthentication(false));
      dispatch(setShowAuthSection(false));
      dispatch(
        setDefaultBranchValues({
          isAuthenticated: false,
          authenticatateBranch: []
        })
      );
    }
  };
  const onSaveButtonClick = async () => {
    setIsDataLoading(true);
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: state?.customer_id,
        employee_id: auth?.user?.id,
        personnel_id:
          authFormValues?.personnelAuthValue === authSection?.personnelAuth
            ? authFormValues?.personnel?.value
            : "",
        system_id: systemId,
        preferences_xml: `<ObjectValues><ObjectValue Object_Id="39" Object_Value="${String(
          selectedHolidaySerType
        )}" Version=""/></ObjectValues>`,
        auth_bypass_reason:
          authFormValues?.personnelAuthValue === authSection?.bypass
            ? authFormValues?.bypassMessage
            : ""
      };

      const saveResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerPreferences?.updateCustomerPreferences}`,
        1
      );

      const saveError = saveResponse?.data[0]; // get error code

      if (saveError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(saveError[0]?.error);
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsDataLoading(false);
        return;
      }

      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode64095
      );
      setSuccessInsertMessage(
        errorMsg
          .replace("|", t("holidayService.subPageTitle"))
          .replace("|", dayjs().utc().format(dateFormatWithTime))
      );
      setMessageSuccess(true);
      dispatch(setIsRefreshRequired(true));
      clearAuth();
      const checkRotationScheduleRequestRes =
        await checkRotationScheduleRequest();

      if (checkRotationScheduleRequestRes === Y_CHECKER) {
        await regenerateCustomerRotationSchedule();
      }

      getPreferencesHistory();
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const checkRotationScheduleRequest = async () => {
    try {
      const reqBody = {
        main_district_id: state?.mainDistrictId,
        customer_id: selectedCustomer?.id
      };

      const rotationScheduleRequestResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerPreferences?.rotationScheduleExistsForCustomerId}`,
        2
      );

      const rotationScheduleRequestError =
        rotationScheduleRequestResponse?.data[1]; // get error code

      if (rotationScheduleRequestError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          rotationScheduleRequestError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsDataLoading(false);
        return;
      }

      const rotationScheduleRequestRes =
        rotationScheduleRequestResponse?.data[0]; // get error code

      return rotationScheduleRequestRes[0]?.exists;
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsDataLoading(false);
    }
  };

  const regenerateCustomerRotationSchedule = async () => {
    try {
      const reqBody = {
        main_district_id: state?.mainDistrictId,
        customer_id: selectedCustomer?.id,
        review_required_flag: N_CHECKER
      };

      const generatesNewSetOfDatesResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerPreferences?.generatesNewSetOfDates}`,
        1
      );

      const generatesNewSetOfDatesResponseError =
        generatesNewSetOfDatesResponse?.data[0]; // get error code

      if (
        generatesNewSetOfDatesResponseError[0]?.error !== errorMsgs?.noError
      ) {
        const errMsg = await getTheFirestoreErrorMessage(
          generatesNewSetOfDatesResponseError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsDataLoading(false);
        return;
      }
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    if (state?.customer_id) {
      getSelectedCustomer(state?.customer_id);
    }
  }, [state?.customer_id]);

  useEffect(() => {
    if (selectedCustomer) {
      getDropdownValues();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (state?.rowdata?.preference_value && holidayServiceTypesList?.length) {
      const defaultValue = holidayServiceTypesList?.find(
        (h) => h?.dropdown_list_id === state?.rowdata?.preference_value
      );

      onSelectFieldChange(defaultValue?.value, "holidayServiceType", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.rowdata?.preference_value, holidayServiceTypesList]);

  // field enabling logic
  const isHolidayServiceDropdownEnabled = () => {
    if (isAuthenticated) {
      return false;
    }

    return true;
  };
  const topTextFieldReportsPayload = [
    {
      key: t("preferences.customerPreferenceTypes39"),
      value: selectedFeildName
    }
  ];

  const filteredPreferenceTypes = useMemo(() => {
    return getPreferenceTypesPayloadForReport().filter(
      (type) => type.preference_type_id === preferenceTypeIdList.trim()
    );
  }, []);

  return (
    <>
      {(isLoading || isDataLoading) && (
        <CircularLoaderFullPage loading={isLoading || isDataLoading} />
      )}

      {showErrorAlert && (
        <Alert
          severity="error"
          icon={<img src={ErrorIcon} alt="error" width="20" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setShowErrorAlert(false);
                setShowErrorAlertMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          style={HolidayServiceStyles?.margin}
        >
          {Array.isArray(showErrorAlertMsg)
            ? showErrorAlertMsg.map((message, index) => (
                <>
                  {index === 0 ? <b>{message}</b> : `${index}) ${message}`}
                  <br />
                </>
              ))
            : showErrorAlertMsg}
        </Alert>
      )}

      {/*Success message popup*/}
      {messageSuccess && (
        <SuccessPopup
          message={successInsertMessage}
          close={setMessageSuccess}
        />
      )}

      <PreferencesLayout showBypass={true}>
        <Stack gap={spacing.gap}>
          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
            label={t("common.customer")}
            fullWidth
            value={selectedCustomer?.number?.trim()}
            InputLabelProps={{ shrink: true, style: { zIndex: 0 } }}
          />

          <Divider />

          <Typography variant="h5">
            {t("holidayService.subPageTitle")}
          </Typography>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel
                  id="printerNameLabel"
                  shrink={selectedHolidaySerType}
                >
                  {t("holidayService.holidayServiceType")}
                </InputLabel>
                <Select
                  label={t("holidayService.holidayServiceType")}
                  id="holidaySerType"
                  name="holidaySerType"
                  value={selectedHolidaySerType}
                  onChange={(e) => onSelectFieldChange(e, "holidayServiceType")}
                  notched={selectedHolidaySerType}
                  disabled={isHolidayServiceDropdownEnabled()}
                >
                  {holidayServiceTypesList?.map((obj) => (
                    <MenuItem key={obj?.value} value={obj?.value}>
                      {obj?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <Link href="/"> {t("holidayService.irmHolidaysPage")}</Link>
            </Grid>
          </Grid>

          <Accordion
            sx={PreferencesStyles.accordian}
            onChange={onChangeAccordionExpand}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                variant="subtitle1"
                style={PreferencesStyles.subTitleStyle}
              >
                {t("preferences.history")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer sx={PreferencesStyles.tableContainer}>
                <MUIDataTable
                  columns={columns}
                  options={options}
                  data={tableData}
                />
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Divider />

          <Grid container gap={2}>
            <Button
              type="button"
              variant="outlined"
              startIcon={
                <img src={ChevronLeftFilled} alt={t("common.backIcon")} />
              }
              onClick={() => goBack()}
            >
              {t("common.back")}
            </Button>

            <Button
              type="button"
              variant="outlined"
              onClick={() => onPrintButtonClick()}
            >
              {t("common.print")}
            </Button>

            {!isAuthenticated && (
              <Button
                type="button"
                variant="contained"
                onClick={() => onEditButtonClick()}
              >
                {t("common.edit")}
              </Button>
            )}

            {isAuthenticated && (
              <Button
                type="button"
                variant="contained"
                onClick={() => onSaveButtonClick()}
              >
                {t("common.save")}
              </Button>
            )}
          </Grid>

          {/* Report download */}
          <PreferencesReoprtDownloadModal
            title={t("common.downloadReport")}
            showPrintModal={showPrintReportModal}
            setCloseModal={setShowPrintReportModal}
            setIsLoading={setIsDataLoading}
            customerId={state?.customer_id}
            preferenceTypeId={preferenceTypeIdList}
            url={CF_URLS.printReports.customerInformation.holidayServiceReport}
            topTextFieldReportsPayload={topTextFieldReportsPayload}
            preferenceTypesPayloadForReport={filteredPreferenceTypes}
          />
        </Stack>
      </PreferencesLayout>
    </>
  );
};

export default HolidayService;
