import { Stack } from "@mui/material";
import AddCutOffDeadline from "components/customer-information/preferences/addCutoffDeadline/AddCutOffDeadline";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const AddCutOffDeadlineMain = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("preferences.addCutOffDeadline.title")}`} />
        <AddCutOffDeadline />
      </Stack>
    </>
  );
};

export default AddCutOffDeadlineMain;
