/* eslint-disable eqeqeq */
import { getDocs, query, where } from "firebase/firestore";
import AxiosInstance from "services/axiosService";
import {
  api_results_collection_resultset0,
  authorization_code_collection,
  destruct_request_status_collection,
  personnel_customer_authorization_collection,
  program_security_collection,
  service_location_collection
} from "services/firebase";
import { ERROR_TYPES, FIREBASE_FIELD_NAMES } from "utils/constants";
import { getAuthenticatedUserBranch } from "utils/helpers";
/**
 * @module requestDetailDelivery request media detail feature specific requests module
 */

export const requestDetailDelivery = {
  /**
   * Get all request types
   *
   * @param {object} props
   * @returns
   */
  getAllDestructionStatusTypes: async () => {
    try {
      let allRequestTypes = [];
      const q = query(destruct_request_status_collection);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        allRequestTypes.push({ id: doc?.id, ...doc?.data() });
      });
      return allRequestTypes;
    } catch (err) {
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  },

  /**
   * Get personnel authorization for create a request
   *
   * @param {object} props
   * @param {string} props.customer_id
   * @param {string} props.personnel_id
   * @param {string} props.program_id
   * @returns
   */
  verifyPersonnelPermissionStatus: async ({
    customer_id,
    personnel_id,
    program_id
  }) => {
    try {
      let personnelCustomerAuth = [];
      let authorizationCodes = [];
      let allAuthprogramId = [];

      const q1 = query(
        personnel_customer_authorization_collection,
        where("customer_id", "==", customer_id),
        where("personnel_id", "==", personnel_id)
      );
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc) => {
        personnelCustomerAuth.push({ id: doc?.id, ...doc?.data() });
      });

      await Promise.all(
        personnelCustomerAuth.map(async (customerPersonnel) => {
          const q2 = query(
            authorization_code_collection,
            where(
              "authorization_code_id",
              "==",
              customerPersonnel?.authorization_code_id
            )
          );
          const querySnapshot2 = await getDocs(q2);
          querySnapshot2.forEach((doc) => {
            authorizationCodes.push({ id: doc?.id, ...doc?.data() });
          });
        })
      );

      await Promise.all(
        authorizationCodes.map(async (customerPersonnel) => {
          const q5 = query(
            program_security_collection,
            where(
              "security_group_id",
              "==",
              customerPersonnel?.security_group_id
            )
          );
          const querySnapshot5 = await getDocs(q5);
          querySnapshot5.forEach((doc) => {
            allAuthprogramId.push({ id: doc?.id, ...doc?.data() });
          });
        })
      );

      const authStatus = allAuthprogramId.filter(
        (program) => program.program_id == program_id
      );

      return authStatus.length > 0;
    } catch (err) {
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  },

  /**
   * Get destruction request comment
   * @param {object} props
   * @returns
   */
  getDesReqComment: async (props) => {
    const districtId = getAuthenticatedUserBranch();
    const object = JSON.stringify({
      main_district_id: districtId,
      destruct_request_id: props?.destruct_request_id,
      destruct_request_status_id: props?.destruct_request_status_id
    });
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/returndestructrequestcomments`,
      object
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },
  /**
   * Save object
   *
   * @param {object} props
   * @param {object} props
   * @returns
   */
  postNewDestructRequest: async (props) => {
    const districtId = getAuthenticatedUserBranch();
    const object = JSON.stringify({
      main_district_id: districtId,
      function: "sb_fs_destruct_request_i_sp",
      customer_id: props?.customer_id,
      requested_personnel_id: props?.requested_personnel_id,
      approx_bulk_count: "",
      pickup_instruction: props?.pickup_instruction,
      volser_list: "",
      im_comment: "",
      business_email_addr: props?.business_email_addr,
      logged_in_employee_id: props?.logged_in_employee_id,
      destruct_request_type_id: props?.destruct_request_type_id,
      preferred_service_date: props?.preferred_service_date,
      preferred_service_location_customer_id:
        props?.preferred_service_location_customer_id,
      tracking_number: "",
      auth_bypass_reason: props?.auth_bypass_reason
    });

    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/createnewmediadestructionrequest`,
      object
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Update object
   *
   * @param {object} props
   * @param {object} props
   * @returns
   */
  postUpdateDestructRequest: async (props) => {
    const districtId = getAuthenticatedUserBranch();
    const object = JSON.stringify({
      main_district_id: districtId,
      function: "sb_fs_destruct_request_u2_sp",
      service_result_id: props?.service_result_id,
      system_id: props?.system_id,
      destruct_request_id: props?.destruct_request_id,
      destruct_req_timestamp: props?.destruct_req_timestamp,
      day_of_week_code: props?.day_of_week_code,
      tracking_number: props?.tracking_number,
      bulk_weight: props?.bulk_weight,
      destruct_request_type_id: props?.destruct_request_type_id,
      to_destruct_request_status_id: props?.to_destruct_request_status_id,
      logged_in_employee_id: props?.logged_in_employee_id,
      unit_of_measure_id: props?.unit_of_measure_id,
      approx_bulk_quantity: props?.approx_bulk_quantity,
      im_comment: props?.im_comment,
      actual_quantity: props?.actual_quantity,
      service_date: props?.service_date,
      customer_id_service_location: props?.customer_id_service_location
    });

    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/updatemediadestruction`,
      object
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Cancel destruction request
   *
   * @param {object} props
   * @param {object} props
   * @returns
   */
  cancelDestructRequest: async (props) => {
    const districtId = getAuthenticatedUserBranch();
    const object = JSON.stringify({
      main_district_id: districtId,
      destruct_request_id: props?.destruct_request_id,
      cancel_personnel_id: props?.cancel_personnel_id,
      cancel_employee_id: props?.cancel_employee_id,
      destruct_cancel_reason_id: props?.destruct_cancel_reason_id,
      last_mod_user: props?.last_mod_user
    });
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/canceldestructrequest`,
      object
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },
  /**
   * destruction request comment insert
   *
   * @param {object} props
   * @param {object} props
   * @returns
   */
  destructRequestCommentInsert: async (props) => {
    const districtId = getAuthenticatedUserBranch();
    const object = JSON.stringify({
      main_district_id: districtId,
      function: "sb_fs_destruct_request_comment_i_sp",
      destruct_request_id: props?.destruct_request_id,
      destruct_request_comment_type_id: props?.destruct_request_comment_type_id,
      destruct_request_status_id: props?.destruct_request_status_id,
      comment_text: props?.comment_text,
      last_mod_date: props?.last_mod_date,
      last_mod_user: props?.last_mod_user
    });

    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/destructrequestcommentinsert`,
      object
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * destruction request comment delete
   *
   * @param {object} props
   * @param {object} props
   * @returns
   */
  destructRequestCommentDelete: async (props) => {
    try {
      const districtId = getAuthenticatedUserBranch();
      const object = JSON.stringify({
        main_district_id: districtId,
        function: "sb_fs_destruct_request_comment_d_sp",
        destruct_request_id: props?.destruct_request_id,
        destruct_request_comment_type_id:
          props?.destruct_request_comment_type_id,
        destruct_request_status_id: props?.destruct_request_status_id
      });
      let response = [];
      const cfResponse = await AxiosInstance.post(
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/destructrequestcommentdelete`,
        object
      ).then((resp) => resp.data);
      const docId = cfResponse.docId;
      const collectionSnap = await getDocs(
        api_results_collection_resultset0(docId)
      );
      collectionSnap.forEach((doc) => {
        response.push({ id: doc?.id, ...doc?.data() });
      });
      return response;
    } catch (err) {
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  },

  /**
   * Get service location trigger filtering to customerId
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @returns
   */
  getServiceLocationByCustomerTrigger: async ({ customerId }) => {
    try {
      let result = [];
      const q = query(
        service_location_collection,
        where(FIREBASE_FIELD_NAMES.CSL_CUSTOMER_ID, "==", customerId),
        where(
          FIREBASE_FIELD_NAMES.DISTRICT_ID,
          "==",
          getAuthenticatedUserBranch()
        )
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({ id: doc?.id, ...doc?.data() });
      });

      return result;
    } catch (err) {
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  }
};
