import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import { t } from "i18next";
import { TAG_TYPES } from "utils/constants";
import LogicalVaultFrom from "./LogicalVaultFrom";

const Customer = ({
  values,
  requestedListBy,
  handleChangeOnChange,
  auditTypeList,
  customers,
  setIsLoading,
  currentBranch,
  setThrowError,
  setThrowErrorMessage,
  auditDetailForm,
  isViewMode,
  localeByBranch,
  viewData,
  allAuditData,
  setSeverity
}) => {
  return (
    <>
      <Stack>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth disabled={false || isViewMode}>
              <InputLabel id="requested-by-label">
                {t("openMediaAuditDetail.requestedBy")}
              </InputLabel>
              <Select
                labelId="requested-by-label"
                id="requested-by"
                value={values?.requestedBy}
                name="requestedBy"
                label={t("openMediaAuditDetail.requestedBy")}
                onChange={(e) => handleChangeOnChange(e.target, true)}
              >
                {requestedListBy?.map((item) => {
                  return (
                    <MenuItem value={item?.label} key={item?.name}>
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl
              fullWidth
              disabled={Boolean(
                !values?.requestedBy ||
                  values?.requestedBy === TAG_TYPES?.customer ||
                  isViewMode
              )}
            >
              <InputLabel id="audit-label-type">
                {t("openMediaAuditDetail.auditType")}
              </InputLabel>
              <Select
                labelId="audit-label-type"
                id="audit-label"
                name="auditType"
                value={values?.auditType}
                label={t("openMediaAuditDetail.auditType")}
                onChange={(e) => handleChangeOnChange(e.target)}
              >
                {auditTypeList?.map((item) => {
                  return (
                    <MenuItem value={item?.label} key={item?.name}>
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <LogicalVaultFrom
            customers={customers}
            setIsLoading={setIsLoading}
            currentBranch={currentBranch}
            setThrowError={setThrowError}
            setThrowErrorMessage={setThrowErrorMessage}
            values={values}
            auditDetailForm={auditDetailForm}
            isViewMode={!isViewMode}
            localeByBranch={localeByBranch}
            viewData={viewData}
            allAuditData={allAuditData}
            setSeverity={setSeverity}
          />
        </Grid>
      </Stack>
    </>
  );
};

export default Customer;
