import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_DATA_TABLE_OPTIONS, DEFAULT_LOCALE } from "utils/constants";
import { verifyConstants } from "utils/constants/open-media-processing/WorkManagement";
import { dateTimeFormatByLocale } from "utils/helpers";
import { VerifyTabStyles } from "../WorkManagementStyles";

const VerifyTable = ({
  columns,
  sortedList,
  title,
  isCommitBtnEnabled,
  localeByBranch,
  onClick
}) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    DEFAULT_DATA_TABLE_OPTIONS.rowsPerPage
  );

  const totalPages = Math.ceil(sortedList?.length / rowsPerPage) || 1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleJumpToPage = (event) => {
    setPage(Number(event.target.value));
  };

  // eslint-disable-next-line
  const handleCheckboxChange = (row) => {};

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper id="selectverifyTable" style={VerifyTabStyles.paper}>
      <Typography variant="h5" sx={VerifyTabStyles.selectTable.title}>
        {title}
      </Typography>

      <Box sx={VerifyTabStyles.table.btnBox}>
        <Button
          variant="outlined"
          disabled={!isCommitBtnEnabled}
          onClick={onClick}
        >
          {t("workManagement.verify.commitBtn")}
        </Button>
      </Box>
      <TableContainer style={VerifyTabStyles.selectTable.tableContainer}>
        <Table>
          <TableHead style={VerifyTabStyles.tableHead}>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  id={`matchSort${index}`}
                  key={column.id}
                  align="left"
                  sx={{
                    width: index === 0 ? "30px" : undefined,
                    ...VerifyTabStyles.tableHeaderTypo
                  }}
                  size="small"
                >
                  <span style={VerifyTabStyles.span}>
                    {column.label?.toUpperCase()}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? sortedList?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ) || []
              : sortedList
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={VerifyTabStyles.possibleMatchTableRowMuiCustom}
              >
                <TableCell size="small">{row?.id}</TableCell>
                <TableCell size="small">
                  {row[verifyConstants.tbl3Cols.media]}
                </TableCell>
                <TableCell size="small">
                  {row[verifyConstants.tbl3Cols.mType]}
                </TableCell>
                <TableCell size="small">
                  {row?.exception === ""
                    ? row?.exception
                    : row?.exception
                        ?.split(" ")
                        ?.map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                </TableCell>
                <TableCell size="small">
                  {row[verifyConstants.tbl3Cols.scan]}
                </TableCell>
                <TableCell size="small">
                  {dateTimeFormatByLocale(
                    row?.list,
                    localeByBranch || DEFAULT_LOCALE
                  )}
                </TableCell>
                <TableCell size="small">{`${dateTimeFormatByLocale(
                  row?.created_datetime,
                  localeByBranch || DEFAULT_LOCALE
                )} ${row[verifyConstants.tbl3Cols.empIni]}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={VerifyTabStyles.tableFooterBox}>
        <Typography sx={VerifyTabStyles.tableFooterTypo}>
          {t("common.jumpToPage")}
        </Typography>
        <FormControl size="small" sx={VerifyTabStyles.tableFooterFormControl}>
          <Select
            value={page}
            onChange={handleJumpToPage}
            sx={VerifyTabStyles.tableFooterSelect}
          >
            {Array.from({ length: totalPages }, (_, index) => (
              <MenuItem key={index} value={index}>
                {index + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TablePagination
          style={VerifyTabStyles.tablePagination}
          rowsPerPageOptions={DEFAULT_DATA_TABLE_OPTIONS.rowsPerPageOptions}
          component="div"
          count={sortedList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  );
};

export default VerifyTable;
