import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack
} from "@mui/material";
import { Y_YES } from "components/features/request-module/media-destruction-request-vault-itemized/Constants";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAuth } from "store/slices";
import { setIsContainerReload } from "store/slices/container-processing/containerAuditDetails";
import {
  setIsReload,
  setViewModeId
} from "store/slices/open-media-audit-detail";
import { SYSTEM_ID_SecureBase } from "utils/constants";
import { containerAuditDetailConst } from "utils/constants/container-processing/ContainerAuditConstants";
import { openMediaAuditDetailConst } from "utils/constants/open-media-processing/OpenMediaAuditConstants.js";
import { getGlobalAttributeValue } from "utils/helpers";
import * as Path from "../../routing/Paths";
import HEADER_STYLES from "./Header.Styles.js";
import { Menus as data } from "./Menus.js";
import { LIST_ITEM_ONHOVER_COLOR } from "./NavigationUI.Styles";

const NavigationUI = () => {
  const { t } = useTranslation();
  const { roleAccess, currentBranch } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const selectedBranchId = currentBranch?.district_id;

  const [number, setNumber] = useState([]);
  const [showMenus, setShowMenus] = useState([
    false,
    false,
    false,
    false,
    false
  ]);
  const [selectedSubMenuIndex, setSelectedSubMenuIndex] = useState(null);
  const [globalAttribute, setGlobalAttribute] = useState(null);
  const [containerAttribute, setContainerAttribute] = useState(null);
  const subMenuRef = useRef(null);

  const handleMenuEnter = (index) => {
    const updatedMenus = [false, false, false, false, false];
    updatedMenus[index] = true;
    setShowMenus(updatedMenus);
  };

  const handleMenuLeave = () => {
    setShowMenus([false, false, false, false, false]);
    setSelectedSubMenuIndex(null);
  };

  const handleSubMenuEnter = (index) => {
    setSelectedSubMenuIndex(index);
  };

  const handleSubMenuLeave = () => {
    setSelectedSubMenuIndex(null);
  };

  const handleButtonClick = (index) => {
    const updatedMenus = Array(data.length).fill(false);
    updatedMenus[index] = !showMenus[index];
    setShowMenus(updatedMenus);
  };

  useEffect(() => {
    document.addEventListener("click", handleMenuLeave);

    return () => {
      document.removeEventListener("click", handleMenuLeave);
    };
  }, []);

  useEffect(() => {
    if (roleAccess.length > 0 && selectedBranchId) {
      handleGetGlobalAttributeData();
      handleContainerAttributeData();
      setNumber(roleAccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleAccess, selectedBranchId]);
  const handleGetGlobalAttributeData = async () => {
    const attributeData = await getGlobalAttributeValue(
      selectedBranchId,
      openMediaAuditDetailConst?.systemId,
      openMediaAuditDetailConst?.typeId
    );
    setGlobalAttribute(attributeData === Y_YES);
  };

  const handleContainerAttributeData = async () => {
    const attributeData = await getGlobalAttributeValue(
      selectedBranchId,
      SYSTEM_ID_SecureBase,
      containerAuditDetailConst?.typeId
    );
    setContainerAttribute(attributeData === Y_YES);
  };
  return (
    <>
      {/* dashboard changes as per the role access */}
      <Stack direction="row">
        {/* Dashboard Component */}
        <div
          key={0}
          className="menu-container"
          style={{
            display: "inline-block",
            margin: "5px",
            position: "relative"
          }}
          onMouseEnter={() => handleMenuEnter(0)}
          onMouseLeave={handleMenuLeave}
        >
          <Link to={Path.ROOT}>
            <Button key={0} sx={HEADER_STYLES.NAV_BUTTON}>
              {t("menu.dashboard")}
            </Button>
          </Link>
        </div>

        {/* Administration Component */}
        {[
          "24",
          "11",
          "32",
          "33",
          "34",
          "35",
          "24",
          "11",
          "12",
          "13",
          "160",
          "316",
          "361",
          "362"
        ].some((value) => number.includes(value)) && (
          <div
            key={1}
            className="menu-container"
            style={{
              display: "inline-block",
              margin: "5px",
              position: "relative"
            }}
            onMouseEnter={() => handleMenuEnter(1)}
            onMouseLeave={handleMenuLeave}
          >
            <Button
              sx={HEADER_STYLES.NAV_BUTTON}
              onClick={() => handleButtonClick(1)}
            >
              {t("menu.administration")}
              {showMenus[1] ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              {showMenus[1] && (
                <List
                  style={HEADER_STYLES.MENU_LIST}
                  onMouseEnter={() => handleMenuEnter(1)}
                  onMouseLeave={handleMenuLeave}
                >
                  {/* default for all users */}
                  {/* billing */}
                  {["32"].some((value) => number.includes(value)) && (
                    <ListItem
                      key={1}
                      onMouseEnter={() => handleSubMenuEnter(1)}
                      onMouseLeave={handleSubMenuLeave}
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center"
                      }}
                      sx={LIST_ITEM_ONHOVER_COLOR}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center"
                        }}
                      >
                        <ListItemText
                          primary={
                            <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                              {t("menu.billing")}
                            </Link>
                          }
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            whiteSpace: "nowrap"
                          }}
                        />
                        <ListItemIcon
                          sx={{
                            minWidth: "0 !important",
                            paddingLeft: "20px"
                          }}
                        >
                          <PlayArrowIcon color="disabled" />
                        </ListItemIcon>{" "}
                        {selectedSubMenuIndex === 1 && (
                          <List
                            ref={subMenuRef}
                            style={HEADER_STYLES.SUB_MENU_LIST}
                            onMouseEnter={() => handleSubMenuEnter(1)}
                            onMouseLeave={handleSubMenuLeave}
                          >
                            {/* billingStatus */}
                            {number.includes("33") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.BILLING_STATUS}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.billingStatus")}
                                </ListItem>
                              </Link>
                            )}

                            {/* billingTransactions */}
                            {number.includes("34") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.BILLING_TRANSACTIONS}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.billingTransactions")}
                                </ListItem>
                              </Link>
                            )}

                            {/* billingErrorLog */}
                            {number.includes("35") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.BILLING_ERROR_LOG}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.billingErrorLog")}
                                </ListItem>
                              </Link>
                            )}

                            {/* enterpriseBillingSummary */}
                            {selectedBranchId && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.ENTERPRISE_BILLING_SUMMARY}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.enterpriseBillingSummary")}
                                </ListItem>
                              </Link>
                            )}

                            {/* maintainBillingRules */}
                            {selectedBranchId && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.MAINTAIN_BILLING_RULES}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.maintainBillingRules")}
                                </ListItem>
                              </Link>
                            )}

                            {/* customerBillingInformation */}
                            {/* default for all users */}
                            <Link
                              to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.CUSTOMER_BILLING_INFORMATION}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.customerBillingInformation")}
                              </ListItem>
                            </Link>

                            {/* defaultPricingUseReport */}
                            {/* default for all users */}
                            <Link
                              to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.DEFAULT_PRICING_USE_REPORT}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.defaultPricingUseReport")}
                              </ListItem>
                            </Link>

                            {/* recurringChangeSearch */}
                            {number.includes("160") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.RECURRING_CHANGE_SEARCH}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.recurringChangeSearch")}
                                </ListItem>
                              </Link>
                            )}

                            {/* recurringChangeDetail */}
                            {number.includes("160") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.RECURRING_CHANGE_DETAIL}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.recurringChangeDetail")}
                                </ListItem>
                              </Link>
                            )}

                            {/* capacityBillingConfigurationSearch */}
                            <Link
                              to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.CAPACITY_BILLING_CONFIGURATION_SEARCH}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.capacityBillingConfigurationSearch")}
                              </ListItem>
                            </Link>

                            {/* capacityBillingGenerationSearch */}
                            <Link
                              to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_BILLING_BASE_PATH}/${Path.CAPACITY_BILLING_GENERATION_SEARCH}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.capacityBillingGenerationSearch")}
                              </ListItem>
                            </Link>
                          </List>
                        )}
                      </div>
                    </ListItem>
                  )}

                  {/* authorization */}
                  {["24", "11", "316"].some((value) =>
                    number.includes(value)
                  ) && (
                    <ListItem
                      key={2}
                      onMouseEnter={() => handleSubMenuEnter(2)}
                      onMouseLeave={handleSubMenuLeave}
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center"
                      }}
                      sx={LIST_ITEM_ONHOVER_COLOR}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center"
                        }}
                      >
                        <ListItemText
                          primary={
                            <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                              {t("menu.authorization")}
                            </Link>
                          }
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            whiteSpace: "nowrap"
                          }}
                        />
                        <ListItemIcon
                          sx={{
                            minWidth: "0 !important",
                            paddingLeft: "20px"
                          }}
                        >
                          <PlayArrowIcon color="disabled" />
                        </ListItemIcon>{" "}
                        {selectedSubMenuIndex === 2 && (
                          <List
                            ref={subMenuRef}
                            style={HEADER_STYLES.SUB_MENU_LIST}
                            onMouseEnter={() => handleSubMenuEnter(2)}
                            onMouseLeave={handleSubMenuLeave}
                          >
                            {/* personalDetail */}
                            {number.includes("24") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_AUTH_BASE_PATH}/${Path.PERSONAL_DETAIL}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.personalDetail")}
                                </ListItem>
                              </Link>
                            )}
                            {/* personalSearch */}
                            {number.includes("11") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_AUTH_BASE_PATH}/${Path.PERSONAL_SEARCH}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.personalSearch")}
                                </ListItem>
                              </Link>
                            )}

                            {/* personalValidation */}
                            {number.includes("316") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_AUTH_BASE_PATH}/${Path.PERSONAL_VALIDATION}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.personalValidation")}
                                </ListItem>
                              </Link>
                            )}
                          </List>
                        )}
                      </div>
                    </ListItem>
                  )}

                  {/* labelPrinting */}
                  <ListItem
                    key={3}
                    onMouseEnter={() => handleSubMenuEnter(3)}
                    onMouseLeave={handleSubMenuLeave}
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center"
                    }}
                    sx={LIST_ITEM_ONHOVER_COLOR}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center"
                      }}
                    >
                      <ListItemText
                        primary={
                          <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                            {t("menu.labelPrinting")}
                          </Link>
                        }
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          whiteSpace: "nowrap"
                        }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: "0 !important",
                          paddingLeft: "20px"
                        }}
                      >
                        <PlayArrowIcon color="disabled" />
                      </ListItemIcon>{" "}
                      {selectedSubMenuIndex === 3 && (
                        <List
                          ref={subMenuRef}
                          style={HEADER_STYLES.SUB_MENU_LIST}
                          onMouseEnter={() => handleSubMenuEnter(3)}
                          onMouseLeave={handleSubMenuLeave}
                        >
                          {/* printTapeLabel */}
                          {number.includes("354") && (
                            <Link
                              to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_LABEL_BASE_PATH}/${Path.PRINT_TAPE_LABEL}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.printTapeLabel")}
                              </ListItem>
                            </Link>
                          )}

                          {/* labelPrinterSetup */}
                          {number.includes("353") && (
                            <Link
                              to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_LABEL_BASE_PATH}/${Path.LABEL_PRINTER_SETUP}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={1}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.labelPrinterSetup")}
                              </ListItem>
                            </Link>
                          )}
                        </List>
                      )}
                    </div>
                  </ListItem>

                  {/* security */}
                  {["26", "27", "28"].some((value) =>
                    number.includes(value)
                  ) && (
                    <ListItem
                      key={4}
                      onMouseEnter={() => handleSubMenuEnter(4)}
                      onMouseLeave={handleSubMenuLeave}
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center"
                      }}
                      sx={LIST_ITEM_ONHOVER_COLOR}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center"
                        }}
                      >
                        <ListItemText
                          primary={
                            <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                              {t("menu.security")}
                            </Link>
                          }
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            whiteSpace: "nowrap"
                          }}
                        />
                        <ListItemIcon
                          sx={{
                            minWidth: "0 !important",
                            paddingLeft: "20px"
                          }}
                        >
                          <PlayArrowIcon color="disabled" />
                        </ListItemIcon>{" "}
                        {selectedSubMenuIndex === 4 && (
                          <List
                            ref={subMenuRef}
                            style={HEADER_STYLES.SUB_MENU_LIST}
                            onMouseEnter={() => handleSubMenuEnter(4)}
                            onMouseLeave={handleSubMenuLeave}
                          >
                            {/* groupEmployee */}
                            {number.includes("27") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_SECURITY_BASE_PATH}/${Path.GROUP_EMPLOYEE}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.groupEmployee")}
                                </ListItem>
                              </Link>
                            )}

                            {/* groupManagement */}
                            {number.includes("28") && (
                              <Link
                                to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.ADMIN_MODULE_SECURITY_BASE_PATH}/${Path.GROUP_MANAGEMENT}`}
                                style={HEADER_STYLES.NAV_LINK}
                              >
                                <ListItem
                                  key={0}
                                  style={{ whiteSpace: "nowrap" }}
                                  sx={LIST_ITEM_ONHOVER_COLOR}
                                >
                                  {t("menu.groupManagement")}
                                </ListItem>
                              </Link>
                            )}
                          </List>
                        )}
                      </div>
                    </ListItem>
                  )}

                  {/* employeeDetail */}
                  {number.includes("12") && (
                    <Link
                      to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.EMPLOYEE_DETAIL}`}
                      style={HEADER_STYLES.NAV_LINK}
                    >
                      <ListItem
                        key={0}
                        onMouseEnter={() => handleSubMenuEnter(0)}
                        onMouseLeave={handleSubMenuLeave}
                        style={{
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center"
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(142, 216, 248, 0.40)"
                          }
                        }}
                      >
                        <ListItemText
                          primary={<>{t("menu.employeeDetail")}</>}
                          style={{ whiteSpace: "nowrap" }}
                        />
                      </ListItem>
                    </Link>
                  )}

                  {/* employeeSearch */}
                  {number.includes("13") && (
                    <Link
                      to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.EMPLOYEE_SEARCH}`}
                      style={HEADER_STYLES.NAV_LINK}
                    >
                      <ListItem
                        key={0}
                        onMouseEnter={() => handleSubMenuEnter(0)}
                        onMouseLeave={handleSubMenuLeave}
                        style={{
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center"
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(142, 216, 248, 0.40)"
                          }
                        }}
                      >
                        <ListItemText
                          primary={<>{t("menu.employeeSearch")}</>}
                          style={{ whiteSpace: "nowrap" }}
                        />
                      </ListItem>
                    </Link>
                  )}

                  {/* notificationConfiguration */}
                  {number.includes("304") && (
                    <Link
                      to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.NOTIFICATION_CONFIGURATION}`}
                      style={HEADER_STYLES.NAV_LINK}
                    >
                      <ListItem
                        key={0}
                        onMouseEnter={() => handleSubMenuEnter(0)}
                        onMouseLeave={handleSubMenuLeave}
                        style={{
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center"
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(142, 216, 248, 0.40)"
                          }
                        }}
                      >
                        <ListItemText
                          primary={<>{t("menu.notificationConfiguration")}</>}
                          style={{ whiteSpace: "nowrap" }}
                        />
                      </ListItem>
                    </Link>
                  )}

                  {/* customerMoveSearch */}
                  {number.includes("361") && (
                    <Link
                      to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.CUSTOMER_MOVE_SEARCH}`}
                      style={HEADER_STYLES.NAV_LINK}
                    >
                      <ListItem
                        key={0}
                        onMouseEnter={() => handleSubMenuEnter(0)}
                        onMouseLeave={handleSubMenuLeave}
                        style={{
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center"
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(142, 216, 248, 0.40)"
                          }
                        }}
                      >
                        <ListItemText
                          primary={<>{t("menu.customerMoveSearch")}</>}
                          style={{ whiteSpace: "nowrap" }}
                        />
                      </ListItem>
                    </Link>
                  )}

                  {/* customerMoveDetail */}
                  {number.includes("362") && (
                    <Link
                      to={`${Path.ADMIN_MODULE_BASE_PATH}/${Path.CUSTOMER_MOVE_DETAIL}`}
                      style={HEADER_STYLES.NAV_LINK}
                    >
                      <ListItem
                        key={0}
                        onMouseEnter={() => handleSubMenuEnter(0)}
                        onMouseLeave={handleSubMenuLeave}
                        style={{
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center"
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(142, 216, 248, 0.40)"
                          }
                        }}
                      >
                        <ListItemText
                          primary={<>{t("menu.customerMoveDetail")}</>}
                          style={{ whiteSpace: "nowrap" }}
                        />
                      </ListItem>
                    </Link>
                  )}
                </List>
              )}
            </Button>
          </div>
        )}

        {/* Requests Component */}
        <div
          key={2}
          className="menu-container"
          style={{
            display: "inline-block",
            margin: "5px",
            position: "relative"
          }}
          onMouseEnter={() => handleMenuEnter(2)}
          onMouseLeave={handleMenuLeave}
        >
          <Button
            sx={HEADER_STYLES.NAV_BUTTON}
            onClick={() => handleButtonClick(2)}
          >
            {t("menu.requests")}
            {showMenus[2] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {showMenus[2] && (
              <List
                style={HEADER_STYLES.MENU_LIST}
                onMouseEnter={() => handleMenuEnter(2)}
                onMouseLeave={handleMenuLeave}
              >
                {/* requestSearch */}
                {number.includes("49") && (
                  <Link
                    to={`${Path.REQUEST_MODULE_BASE_PATH}/${Path.REQUEST_SEARCH}`}
                    style={HEADER_STYLES.NAV_LINK}
                  >
                    <ListItem
                      key={0}
                      onMouseEnter={() => handleSubMenuEnter(0)}
                      onMouseLeave={handleSubMenuLeave}
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center"
                      }}
                      sx={LIST_ITEM_ONHOVER_COLOR}
                    >
                      <ListItemText
                        primary={<>{t("menu.requestSearch")}</>}
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </ListItem>
                  </Link>
                )}

                {/* default */}
                {/* mediaRequestDetail */}
                <Link
                  to={`${Path.REQUEST_MODULE_BASE_PATH}/${Path.MEDIA_REQUEST_DETAIL}`}
                  style={HEADER_STYLES.NAV_LINK}
                >
                  <ListItem
                    key={0}
                    onMouseEnter={() => handleSubMenuEnter(0)}
                    onMouseLeave={handleSubMenuLeave}
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center"
                    }}
                    sx={LIST_ITEM_ONHOVER_COLOR}
                  >
                    <ListItemText
                      primary={<>{t("menu.mediaRequestDetail")}</>}
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </ListItem>
                </Link>

                {/* default */}
                {/* rescheduleService */}
                <Link
                  to={`${Path.REQUEST_MODULE_BASE_PATH}/${Path.RESCHEDULE_SERVICE}`}
                  style={HEADER_STYLES.NAV_LINK}
                >
                  <ListItem
                    key={0}
                    onMouseEnter={() => handleSubMenuEnter(0)}
                    onMouseLeave={handleSubMenuLeave}
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center"
                    }}
                    sx={LIST_ITEM_ONHOVER_COLOR}
                  >
                    <ListItemText
                      primary={<>{t("menu.rescheduleService")}</>}
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </ListItem>
                </Link>

                {/* mediaDestructionRequestSearch */}
                {number.includes("287") && (
                  <Link
                    to={`${Path.REQUEST_MODULE_BASE_PATH}/${Path.MEDIA_DESTRUCTION_REQUEST_SEARCH}`}
                    style={HEADER_STYLES.NAV_LINK}
                  >
                    <ListItem
                      key={0}
                      onMouseEnter={() => handleSubMenuEnter(0)}
                      onMouseLeave={handleSubMenuLeave}
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center"
                      }}
                      sx={LIST_ITEM_ONHOVER_COLOR}
                    >
                      <ListItemText
                        primary={<>{t("menu.mediaDestructionRequestSearch")}</>}
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </ListItem>
                  </Link>
                )}

                {/* mediaDestructionRequestDetail */}
                {["342", "290", "288"].some((value) =>
                  number.includes(value)
                ) && (
                  <ListItem
                    key={1}
                    onMouseEnter={() => handleSubMenuEnter(1)}
                    onMouseLeave={handleSubMenuLeave}
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center"
                    }}
                    sx={LIST_ITEM_ONHOVER_COLOR}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center"
                      }}
                    >
                      <ListItemText
                        primary={
                          <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                            {t("menu.mediaDestructionRequestDetail")}
                          </Link>
                        }
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          whiteSpace: "nowrap"
                        }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: "0 !important",
                          paddingLeft: "20px"
                        }}
                      >
                        <PlayArrowIcon color="disabled" />
                      </ListItemIcon>{" "}
                      {selectedSubMenuIndex === 1 && (
                        <List
                          ref={subMenuRef}
                          style={HEADER_STYLES.SUB_MENU_LIST}
                          onMouseEnter={() => handleSubMenuEnter(1)}
                          onMouseLeave={handleSubMenuLeave}
                        >
                          {/* delivery */}
                          {number.includes("342") && (
                            <Link
                              to={`${Path.REQUEST_MODULE_BASE_PATH}/${Path.REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.delivery")}
                              </ListItem>
                            </Link>
                          )}

                          {/* pickup */}
                          {number.includes("290") && (
                            <Link
                              to={`${Path.REQUEST_MODULE_BASE_PATH}/${Path.REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.pickup")}
                              </ListItem>
                            </Link>
                          )}

                          {/* vaultItemized */}
                          {number.includes("288") && (
                            <Link
                              to={`${Path.REQUEST_MODULE_BASE_PATH}/${Path.REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.vaultItemized")}
                              </ListItem>
                            </Link>
                          )}
                        </List>
                      )}
                    </div>
                  </ListItem>
                )}
              </List>
            )}
          </Button>
        </div>

        {/* Operations Component */}
        <div
          key={3}
          className="menu-container"
          style={{
            display: "inline-block",
            margin: "5px",
            position: "relative"
          }}
          onMouseEnter={() => handleMenuEnter(3)}
          onMouseLeave={handleMenuLeave}
        >
          <Button
            sx={HEADER_STYLES.NAV_BUTTON}
            onClick={() => handleButtonClick(3)}
          >
            {t("menu.operations")}
            {showMenus[3] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {showMenus[3] && (
              <List
                style={HEADER_STYLES.MENU_LIST}
                onMouseEnter={() => handleMenuEnter(3)}
                onMouseLeave={handleMenuLeave}
              >
                {/* customerInformation */}
                <ListItem
                  key={1}
                  onMouseEnter={() => handleSubMenuEnter(1)}
                  onMouseLeave={handleSubMenuLeave}
                  style={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center"
                  }}
                  sx={LIST_ITEM_ONHOVER_COLOR}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center"
                    }}
                  >
                    <ListItemText
                      primary={
                        <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                          {t("menu.customerInformation")}
                        </Link>
                      }
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        whiteSpace: "nowrap"
                      }}
                    />
                    <ListItemIcon
                      sx={{
                        minWidth: "0 !important",
                        paddingLeft: "20px"
                      }}
                    >
                      <PlayArrowIcon color="disabled" />
                    </ListItemIcon>{" "}
                    {selectedSubMenuIndex === 1 && (
                      <List
                        ref={subMenuRef}
                        style={HEADER_STYLES.SUB_MENU_LIST}
                        onMouseEnter={() => handleSubMenuEnter(1)}
                        onMouseLeave={handleSubMenuLeave}
                      >
                        {/* serviceInformation */}
                        {number.includes("39") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.SERVICE_INFORMATION}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.serviceInformation")}
                            </ListItem>
                          </Link>
                        )}

                        {/* schedule */}
                        {number.includes("43") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.SCHEDULE}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.schedule")}
                            </ListItem>
                          </Link>
                        )}

                        {/* serviceWindows */}
                        {number.includes("43") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.SERVICE_WINDOWS}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.serviceWindows")}
                            </ListItem>
                          </Link>
                        )}

                        {/* routeManagement */}
                        {number.includes("51") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.ROUTE_MANAGEMENT}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.routeManagement")}
                            </ListItem>
                          </Link>
                        )}

                        {/* labelTemplateSearch */}
                        {number.includes("161") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.LABEL_TEMPLATE_SEARCH}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.labelTemplateSearch")}
                            </ListItem>
                          </Link>
                        )}

                        {/* default */}
                        {/* preferences */}
                        <Link
                          to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.PREFERENCES}`}
                          style={HEADER_STYLES.NAV_LINK}
                        >
                          <ListItem
                            key={0}
                            style={{ whiteSpace: "nowrap" }}
                            sx={LIST_ITEM_ONHOVER_COLOR}
                          >
                            {t("menu.preferences")}
                          </ListItem>
                        </Link>
                      </List>
                    )}
                  </div>
                </ListItem>

                {/* disasterRecovery */}
                {["268", "257", "256"].some((value) =>
                  number.includes(value)
                ) && (
                  <ListItem
                    key={2}
                    onMouseEnter={() => handleSubMenuEnter(2)}
                    onMouseLeave={handleSubMenuLeave}
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center"
                    }}
                    sx={LIST_ITEM_ONHOVER_COLOR}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center"
                      }}
                    >
                      <ListItemText
                        primary={
                          <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                            {t("menu.disasterRecovery")}
                          </Link>
                        }
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          whiteSpace: "nowrap"
                        }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: "0 !important",
                          paddingLeft: "20px"
                        }}
                      >
                        <PlayArrowIcon color="disabled" />
                      </ListItemIcon>{" "}
                      {selectedSubMenuIndex === 2 && (
                        <List
                          ref={subMenuRef}
                          style={HEADER_STYLES.SUB_MENU_LIST}
                          onMouseEnter={() => handleSubMenuEnter(2)}
                          onMouseLeave={handleSubMenuLeave}
                        >
                          {/* instructionFiles */}
                          {number.includes("268") && (
                            <Link
                              to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${Path.INSTRUCTION_FILES}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.instructionFiles")}
                              </ListItem>
                            </Link>
                          )}

                          {number.includes("257") && (
                            <Link
                              to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${Path.EVENT}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.event")}
                              </ListItem>
                            </Link>
                          )}

                          {number.includes("256") && (
                            <Link
                              to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${Path.EVENT_SEARCH}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.eventSearch")}
                              </ListItem>
                            </Link>
                          )}
                        </List>
                      )}
                    </div>
                  </ListItem>
                )}

                {/* discrepancyManagement */}
                {number.includes("326") && (
                  <Link
                    to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.DISCREPANCY_MANAGEMENT}`}
                    style={HEADER_STYLES.NAV_LINK}
                  >
                    <ListItem
                      key={0}
                      onMouseEnter={() => handleSubMenuEnter(0)}
                      onMouseLeave={handleSubMenuLeave}
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center"
                      }}
                      sx={LIST_ITEM_ONHOVER_COLOR}
                    >
                      <ListItemText
                        primary={<>{t("menu.discrepancyManagement")}</>}
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </ListItem>
                  </Link>
                )}

                {/* logicalVaultManagement */}
                {["42", "190", "186", "166"].some((value) =>
                  number.includes(value)
                ) && (
                  <ListItemText
                    primary={
                      <Link
                        to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH}/${Path.LOGICAL_VAULT_DETAILS}`}
                        style={HEADER_STYLES.NAV_LINK}
                      >
                        <ListItem
                          key={0}
                          style={{ whiteSpace: "nowrap" }}
                          sx={LIST_ITEM_ONHOVER_COLOR}
                        >
                          {t("menu.logicalVaultDetail")}
                        </ListItem>
                      </Link>
                    }
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      whiteSpace: "nowrap"
                    }}
                  />
                )}

                {/* containerProcessing */}
                <ListItem
                  key={4}
                  onMouseEnter={() => handleSubMenuEnter(4)}
                  onMouseLeave={handleSubMenuLeave}
                  style={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center"
                  }}
                  sx={LIST_ITEM_ONHOVER_COLOR}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center"
                    }}
                  >
                    <ListItemText
                      primary={
                        <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                          {t("menu.containerProcessing")}
                        </Link>
                      }
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        whiteSpace: "nowrap"
                      }}
                    />
                    <ListItemIcon
                      sx={{
                        minWidth: "0 !important",
                        paddingLeft: "20px"
                      }}
                    >
                      <PlayArrowIcon color="disabled" />
                    </ListItemIcon>{" "}
                    {selectedSubMenuIndex === 4 && (
                      <List
                        ref={subMenuRef}
                        style={HEADER_STYLES.SUB_MENU_LIST}
                        onMouseEnter={() => handleSubMenuEnter(4)}
                        onMouseLeave={handleSubMenuLeave}
                      >
                        {/* default */}
                        {/* general */}
                        <Link
                          to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.GENERAL}`}
                          style={HEADER_STYLES.NAV_LINK}
                        >
                          <ListItem
                            key={0}
                            style={{ whiteSpace: "nowrap" }}
                            sx={LIST_ITEM_ONHOVER_COLOR}
                          >
                            {t("menu.general")}
                          </ListItem>
                        </Link>

                        {/* containerIssuance */}
                        {number.includes("40") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.CONTAINER_ISSUANCE}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.containerIssuance")}
                            </ListItem>
                          </Link>
                        )}

                        {/* containerProcessingResearch */}
                        {number.includes("46") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.CONTAINER_PROCESSING_RESEARCH}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.containerProcessingResearch")}
                            </ListItem>
                          </Link>
                        )}

                        {/* reassignTransportContents */}
                        {number.includes("175") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.REASSIGN_TRANSPORT_CONTENTS}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.reassignTransportContents")}
                            </ListItem>
                          </Link>
                        )}

                        {/* containerInventoryAuditSearch */}
                        {number.includes("300") && containerAttribute && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.CONTAINER_INVENTORY_AUDIT_SEARCH}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.containerInventoryAuditSearch")}
                            </ListItem>
                          </Link>
                        )}

                        {number.includes("299") && containerAttribute && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${Path.CONTAINER_INVENTORY_AUDIT_DETAIL}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                              onClick={() => {
                                dispatch(setIsContainerReload(true));
                              }}
                            >
                              {t("menu.containerInventoryAuditDetail")}
                            </ListItem>
                          </Link>
                        )}
                      </List>
                    )}
                  </div>
                </ListItem>

                {/* openMediaProcessing */}
                <ListItem
                  key={5}
                  onMouseEnter={() => handleSubMenuEnter(5)}
                  onMouseLeave={handleSubMenuLeave}
                  style={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center"
                  }}
                  sx={LIST_ITEM_ONHOVER_COLOR}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center"
                    }}
                  >
                    <ListItemText
                      primary={
                        <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                          {t("menu.openMediaProcessing")}
                        </Link>
                      }
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        whiteSpace: "nowrap"
                      }}
                    />
                    <ListItemIcon
                      sx={{
                        minWidth: "0 !important",
                        paddingLeft: "20px"
                      }}
                    >
                      <PlayArrowIcon color="disabled" />
                    </ListItemIcon>{" "}
                    {selectedSubMenuIndex === 5 && (
                      <List
                        ref={subMenuRef}
                        style={HEADER_STYLES.SUB_MENU_LIST}
                        onMouseEnter={() => handleSubMenuEnter(5)}
                        onMouseLeave={handleSubMenuLeave}
                      >
                        {/* workManagement */}
                        {["169", "190", "174", "176", "178"].some((value) =>
                          number.includes(value)
                        ) && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.WORK_MANAGEMENT}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.workManagement")}
                            </ListItem>
                          </Link>
                        )}

                        {/* openMediaProcessingResearch */}
                        {number.includes("181") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.OPEN_MEDIA_PROCESSING_RESEARCH}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.openMediaProcessingResearch")}
                            </ListItem>
                          </Link>
                        )}

                        {/* branchOutagePickListCompletion */}
                        {number.includes("206") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.BRANCH_OUTAGE_PICK_LIST_COMPLETION}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.branchOutagePickListCompletion")}
                            </ListItem>
                          </Link>
                        )}

                        {/* createDistributionList */}
                        {number.includes("202") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.CREATE_DISTRIBUTION_LIST}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.createDistributionList")}
                            </ListItem>
                          </Link>
                        )}

                        {/* customerFileSearch */}
                        {number.includes("201") && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.CUSTOMER_FILE_SEARCH}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.customerFileSearch")}
                            </ListItem>
                          </Link>
                        )}

                        {/* openMediaAuditSearch */}
                        {number.includes("307") && globalAttribute && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.OPEN_MEDIA_AUDIT_SEARCH}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                            >
                              {t("menu.openMediaAuditSearch")}
                            </ListItem>
                          </Link>
                        )}

                        {/* openMediaAuditDetail */}
                        {number.includes("308") && globalAttribute && (
                          <Link
                            to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.OPEN_MEDIA_AUDIT_DETAIL}`}
                            style={HEADER_STYLES.NAV_LINK}
                          >
                            <ListItem
                              key={0}
                              style={{ whiteSpace: "nowrap" }}
                              sx={LIST_ITEM_ONHOVER_COLOR}
                              onClick={() => {
                                dispatch(setViewModeId(null));
                                dispatch(setIsReload(true));
                              }}
                            >
                              {t("menu.openMediaAuditDetail")}
                            </ListItem>
                          </Link>
                        )}
                      </List>
                    )}
                  </div>
                </ListItem>

                {/* correctiveActionRequests */}
                {["247", "249"].some((value) => number.includes(value)) && (
                  <ListItem
                    key={6}
                    onMouseEnter={() => handleSubMenuEnter(6)}
                    onMouseLeave={handleSubMenuLeave}
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center"
                    }}
                    sx={LIST_ITEM_ONHOVER_COLOR}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center"
                      }}
                    >
                      <ListItemText
                        primary={
                          <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                            {t("menu.correctiveActionRequests")}
                          </Link>
                        }
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          whiteSpace: "nowrap"
                        }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: "0 !important",
                          paddingLeft: "20px"
                        }}
                      >
                        <PlayArrowIcon color="disabled" />
                      </ListItemIcon>{" "}
                      {selectedSubMenuIndex === 6 && (
                        <List
                          ref={subMenuRef}
                          style={HEADER_STYLES.SUB_MENU_LIST}
                          onMouseEnter={() => handleSubMenuEnter(6)}
                          onMouseLeave={handleSubMenuLeave}
                        >
                          {/* detail */}
                          {number.includes("249") && (
                            <Link
                              to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${Path.CORRECTIVE_ACTION_REQUESTS_DETAIL}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.detail")}
                              </ListItem>
                            </Link>
                          )}

                          {/* search */}
                          {number.includes("247") && (
                            <Link
                              to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${Path.CORRECTIVE_ACTION_REQUESTS_SEARCH}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.search")}
                              </ListItem>
                            </Link>
                          )}
                        </List>
                      )}
                    </div>
                  </ListItem>
                )}

                {/* transportation */}
                {["38"].some((value) => number.includes(value)) && (
                  <ListItem
                    key={7}
                    onMouseEnter={() => handleSubMenuEnter(7)}
                    onMouseLeave={handleSubMenuLeave}
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center"
                    }}
                    sx={LIST_ITEM_ONHOVER_COLOR}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center"
                      }}
                    >
                      <ListItemText
                        primary={
                          <Link to={"#"} style={HEADER_STYLES.NAV_LINK}>
                            {t("menu.transportation")}
                          </Link>
                        }
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          whiteSpace: "nowrap"
                        }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: "0 !important",
                          paddingLeft: "20px"
                        }}
                      >
                        <PlayArrowIcon color="disabled" />
                      </ListItemIcon>{" "}
                      {selectedSubMenuIndex === 7 && (
                        <List
                          ref={subMenuRef}
                          style={HEADER_STYLES.SUB_MENU_LIST}
                          onMouseEnter={() => handleSubMenuEnter(7)}
                          onMouseLeave={handleSubMenuLeave}
                        >
                          {/* routeDetail */}
                          {number.includes("38") && (
                            <Link
                              to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${Path.ROUTE_DETAIL}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.routeDetail")}
                              </ListItem>
                            </Link>
                          )}

                          {/* runManagement */}
                          {number.includes("52") && (
                            <Link
                              to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${Path.RUN_MANAGEMENT}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.runManagement")}
                              </ListItem>
                            </Link>
                          )}

                          {/* vendorManagement */}
                          {number.includes("44") && (
                            <Link
                              to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${Path.VENDOR_MANAGEMENT}`}
                              style={HEADER_STYLES.NAV_LINK}
                            >
                              <ListItem
                                key={0}
                                style={{ whiteSpace: "nowrap" }}
                                sx={LIST_ITEM_ONHOVER_COLOR}
                              >
                                {t("menu.vendorManagement")}
                              </ListItem>
                            </Link>
                          )}
                        </List>
                      )}
                    </div>
                  </ListItem>
                )}

                {/* vaultConfiguration */}
                {["159", "158"].some((value) => number.includes(value)) && (
                  <Link
                    to={`${Path.OPERATIONS_MODULE_BASE_PATH}/${Path.VAULT_CONFIGURATION}`}
                    style={HEADER_STYLES.NAV_LINK}
                  >
                    <ListItem
                      key={0}
                      onMouseEnter={() => handleSubMenuEnter(0)}
                      onMouseLeave={handleSubMenuLeave}
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center"
                      }}
                      sx={LIST_ITEM_ONHOVER_COLOR}
                    >
                      <ListItemText
                        primary={<>{t("menu.vaultConfiguration")}</>}
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </ListItem>
                  </Link>
                )}
              </List>
            )}
          </Button>
        </div>

        {/* Reports Component */}
        <div
          key={4}
          className="menu-container"
          style={{
            display: "inline-block",
            margin: "5px",
            position: "relative"
          }}
          onMouseEnter={() => handleMenuEnter(4)}
          onMouseLeave={handleMenuLeave}
        >
          <Link
            to={`${Path.REPORTS_MODULE_BASE_PATH}`}
            style={HEADER_STYLES.NAV_LINK}
          >
            <Button sx={HEADER_STYLES.NAV_BUTTON}> {t("menu.reports")}</Button>
          </Link>
        </div>
      </Stack>
    </>
  );
};

export default NavigationUI;
