import { t } from "i18next";
import SelectField from "../select-field/SelectField";

//The value prop is used for BranchField values
//The onChange prop is used when changing the selectField dropdown values.
function BranchField({
  label,
  labelId,
  options,
  error,
  helperText,
  notched,
  shrink,
  value,
  onChange,
  disabled,
  ...props
}) {
  return (
    <SelectField
      error={error}
      helperText={helperText}
      label={t("common.branch")}
      id="Branch"
      value={value}
      disabled={disabled}
      options={options}
      onChange={onChange}
      {...props}
    />
  );
}
export default BranchField;
