import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";

import spacing from "styles/spacing";

const MissinMediaSnapbar = ({ setMissingMedia, missingMediaList }) => {
  const handleClose = () => {
    setMissingMedia(false);
  };

  return (
    <>
      <Box
        mb={spacing.gap}
        style={ContainerProcessingStyles.missingMediaSnapBar.Container}
      >
        <Stack
          sx={ContainerProcessingStyles.missingMediaSnapBar.ContainerStack}
        >
          <Box sx={ContainerProcessingStyles.missingMediaSnapBar.SecondBox}>
            <span>
              <IconButton
                size="small"
                sx={ContainerProcessingStyles.missingMediaSnapBar.ErrorIcon}
                onClick={handleClose}
              >
                <ErrorOutlineRoundedIcon fontSize="small" />
              </IconButton>
              <span style={ContainerProcessingStyles.missingMediaSnapBar.Span}>
                {missingMediaList?.title}
              </span>
            </span>
            <IconButton
              size="small"
              sx={ContainerProcessingStyles.missingMediaSnapBar.CloseIcon}
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              ...ContainerProcessingStyles.missingMediaSnapBar.TableContainer,
              paddingTop: "0"
            }}
          >
            <Table sx={ContainerProcessingStyles.missingMediaSnapBar.Table}>
              <TableHead>
                <TableRow>
                  {missingMediaList?.columns?.map((col) => (
                    <TableCell
                      sx={
                        ContainerProcessingStyles.missingMediaSnapBar
                          .TableCellHead
                      }
                      key={col}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {missingMediaList?.list?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={
                        ContainerProcessingStyles.missingMediaSnapBar.TableCell
                      }
                    >
                      {row.number}
                    </TableCell>
                    <TableCell
                      sx={
                        ContainerProcessingStyles.missingMediaSnapBar.TableCell
                      }
                    >
                      {row.run_name}
                    </TableCell>
                    <TableCell
                      sx={
                        ContainerProcessingStyles.missingMediaSnapBar.TableCell
                      }
                    >
                      {row.volser}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={ContainerProcessingStyles.missingMediaSnapBar.Box}></Box>
        </Stack>
      </Box>
    </>
  );
};

export default MissinMediaSnapbar;
