import { Stack } from "@mui/material";
import EmployeeDetailLayout from "components/employee-module/employee-detail/EmployeeDetailLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const EmployeeDetail = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("employeeDetail.pageTitle")} />
      <EmployeeDetailLayout />
    </Stack>
  );
};

export default EmployeeDetail;
