import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  TextField
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import {
  conversionFactorInputProps,
  generationLimit
} from "utils/constants";
import { GenerationSearch } from "./CapacityBillingGenerationSearch.style";
import { selectAuth } from "store/slices";
import { useSelector } from "react-redux";
import { decimalSymbolOfNumber, numberFilter } from "utils/helpers";
const CapacityBillingGenerationDetailModal = ({
  open,
  handleClose,
  generationDetailFormik,
  suffixes,
  clickOnEdit
}) => {
  const { localeByBranch } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [alert, setAlert] = useState(false);

  const handleQuantityOnKeyUp = (event, decimalPointPrecision) => {
    const decimalSeparator = decimalSymbolOfNumber(localeByBranch);
  
    if (numberFilter(event.key, localeByBranch) && event.keyCode !== 13) {
      const value = event.target.value;
      const caretPosition = event.target.selectionStart;
      const res = value.split(decimalSeparator);
  
      const integerPartWithCommas = res[0]; 
      const decimalPart = res.length > 1 ? res[1] : ''; 
  
      const totalLength = integerPartWithCommas.length + decimalPart.length + (res.length > 1 ? 1 : 0);
  
      if (totalLength >= 6 && event.keyCode !== 8) {
        return event.preventDefault();
      }
  
      if (
        value.length > 0 &&
        event.key === decimalSeparator &&
        value.indexOf(decimalSeparator) !== -1
      ) {
        return event.preventDefault();
      }
  
      const decimalIndex = value.indexOf(decimalSeparator);
      if (
        decimalPart.length >= Number(decimalPointPrecision) &&
        caretPosition > decimalIndex &&
        event.keyCode !== 8 &&
        event.keyCode !== 13
      ) {
        return event.preventDefault();
      }
    } else if (event.keyCode !== 13) {
      return event.preventDefault();
    }
  };  

  //handling on change of conversion factor
  const handleOnChageConversionfactor = (event) => {
      generationDetailFormik.setFieldValue(
        "conversionFactor",
        event.target.value
      );
  };
  //handling on change of Generation suffix
  const handleOnChangeAddNew = (event) => {
    setAlert(false);
    generationDetailFormik.setFieldValue(
      "generationAddNew",
      event.target.value
    );
  };
  //handling on blue for add new
  const handleBlurOnAddNew = (e) => {
    //checking if the value is grater than 0
    if (e.target.value.length > 0) {
      //adding new
      if (!clickOnEdit) {
        //setting the value to uppercase
        generationDetailFormik.setFieldValue(
          "generationAddNew",
          e.target.value.toUpperCase()
        );
        //checking the value is already exists
        let suffixesLabel = suffixes.map((i) => i.label);
        let existSuffixes = suffixesLabel.includes(
          e.target.value.toUpperCase()
        );
        //if already exists opening the alert and making the textfield to empty
        existSuffixes && setAlert(true);
        existSuffixes &&
          generationDetailFormik.setFieldValue("generationAddNew", "");
        generationDetailFormik.handleBlur(e);
      }
    }
  };
  //handle on blur for conversion factor
  const handleOnBlurConversionfactor = (event) => {

    if (event.target.value.length > 0) {
      const valueWithDot = event.target.value.replace(",", ".");
      
      const parsedValue = parseFloat(valueWithDot);
      
      if (!isNaN(parsedValue)) {
        const formattedValue = parsedValue.toFixed(2).replace(".", ",");
        generationDetailFormik.setFieldValue("conversionFactor", formattedValue);
      }
    }
  
    generationDetailFormik.handleBlur(event);
  };  
  //clicking on new/update
  const handleOnSubmit = (event) => {
    event.preventDefault();
    setAlert(false);
    generationDetailFormik.handleSubmit();
  };
  return (
    <>
      <CommonModal
        open={open}
        title={t("generationSearchDetail.modalTitle")}
        body={
          <>
            {/* Error Field */}

            <Stack
              id="generation-body-main"
              direction="column"
              spacing={spacing.horizontalMargin20}
            >
              {alert && (
                <Box>
                  <Alert
                    severity="error"
                    icon={<img src={ErrorIcon} alt="error" />}
                    id="button-wraning"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        id="button-wraning-close-btn"
                        onClick={() => {
                          setAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {t("generationSearchDetail.generationExistsAlert")}
                  </Alert>
                </Box>
              )}
              <Box>
                <TextField
                  label={t("generationSearchDetail.generationLabel")}
                  id="generation-add-new"
                  name="generation-add-new"
                  size="small"
                  sx={GenerationSearch.generationDetail}
                  disabled={clickOnEdit}
                  value={generationDetailFormik.values.generationAddNew}
                  onChange={(event) => {
                    handleOnChangeAddNew(event);
                  }}
                  error={
                    generationDetailFormik.touched.generationAddNew &&
                    Boolean(generationDetailFormik.errors.generationAddNew)
                  }
                  onBlur={async (e) => {
                    handleBlurOnAddNew(e);
                  }}
                  helperText={
                    generationDetailFormik.touched.generationAddNew &&
                    generationDetailFormik.errors.generationAddNew
                  }
                  inputProps={generationLimit}
                />
              </Box>
              <Box>
                <TextField
                  label={t("generationSearchDetail.conversionLabel")}
                  id="conversion-factor"
                  type="text"
                  size="small"
                  sx={GenerationSearch.generationDetail}
                  value={generationDetailFormik.values.conversionFactor}
                  onChange={handleOnChageConversionfactor}
                  onKeyDown={(e) =>
                    handleQuantityOnKeyUp(e, 2)
                  }
                  error={
                    generationDetailFormik.touched.conversionFactor &&
                    Boolean(generationDetailFormik.errors.conversionFactor)
                  }
                  onBlur={handleOnBlurConversionfactor}
                  helperText={
                    generationDetailFormik.touched.conversionFactor &&
                    generationDetailFormik.errors.conversionFactor
                  }
                  inputProps={conversionFactorInputProps}
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active-checkbox-oneditUpdate"
                      checked={generationDetailFormik.values.active}
                      onChange={(event) => {
                        generationDetailFormik.setFieldValue(
                          "active",
                          event.target.checked
                        );
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Active"
                />
              </Box>
            </Stack>
          </>
        }
        buttons={
          <Stack
            id="buttons"
            direction="row"
            spacing={spacing.horizontalMargin8}
          >
            {/* cancel button */}
            <Button
              id="cancel-button"
              variant="outlined"
              size="small"
              type="button"
              onClick={() => {
                setAlert(false);
                handleClose();
              }}
            >
              {!clickOnEdit
                ? t("common.cancel")
                : t("generationSearchDetail.cancelEdit")}
            </Button>
            {/* New button / Update button */}
            <Button
              id="addupdate-button"
              variant="contained"
              size="small"
              type="submit"
              onClick={handleOnSubmit}
            >
              {!clickOnEdit
                ? t("generationSearchDetail.addNew")
                : t("generationSearchDetail.update")}
            </Button>
          </Stack>
        }
      />
    </>
  );
};

export default CapacityBillingGenerationDetailModal;
