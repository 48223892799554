import { Typography as MUITypography } from '@mui/material';

const Typography = ({ children, variant, sx }) => {
    return (
        <MUITypography variant={variant} sx={sx}>
            {children}
        </MUITypography>
    );
};

export default Typography;
