import { Button } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const BillingResetModal = ({
  resetPopupShow,
  cancelClickReset,
  phaseDescription,
  okClickReset
}) => {
  return (
    <>
      <CommonModal
        open={resetPopupShow}
        body={`${t(
          "billingStatus.resetMsgFirstPart"
        )} "${phaseDescription}" ${t("billingStatus.resetMsgSecondPart")}`}
        buttons={
          <>
            <Button onClick={() => cancelClickReset()} variant="outlined">
              {t("common.cancel")}
            </Button>
            <Button onClick={() => okClickReset()} variant="contained">
              {t("common.ok")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default BillingResetModal;
