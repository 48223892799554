import { Grid } from "@mui/material";
import CustomerOnHold from "components/dashboard/customer-on-hold/CustomerOnHold";
import IronMountainHolidays from "components/dashboard/holidays/IronMountainHolidays";
import SystemRequests from "components/dashboard/system-requests/SystemRequests";
import ToDo from "components/dashboard/to-do/ToDo";

function Dashboard() {
  return (
    <>
      <Grid container spacing={2} alignItems="">
        <Grid item xs={12} sm={12}>
          <ToDo />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomerOnHold />
        </Grid>
        <Grid item md={12} lg={5} sx={4}>
          <IronMountainHolidays />
        </Grid>
        <Grid item md={12} lg={1} sx={4}></Grid>
        <Grid item md={12} lg={6} sx={4}>
          <SystemRequests />
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
