export const ResearchTableStyles = {
  completeColor: "var(--color-im-mid-green)",
  selectedColor: "var(--color-im-light-blue-100)",
  errorAlert: {
    marginTop: "20px"
  },
  mediaNumberAdd: {
    width: "45px",
    height: "45px"
  },
  mediaNumberAddIcon: {
    fontSize: "24px",
    color: "var(--color-im-dark-blue-500)"
  },
  noDataText: {
    fotSize: "14px"
  }
};
