import { t } from "i18next";
export let reportTypes = {
  capacity: "capacity",
  configuration: "configuration",
  management: "management"
};

export const programTypes = {
  container: "container",
  openMedia: "openMedia",
  c: "C",
  m: "M",
  all: "all",
  a: "A",
  openMediaCode: "5"
};

export const vaults = {
  all: "all",
  branch: "branch",
  customer: "customer",
  a: "A",
  b: "B",
  c: "C"
};
export const numberOfPeriods = "5";

export const defaultCapacityPercentage = {
  low: "20",
  high: "80"
};

export const percentageMax = "2";

export const fields = {
  programType: "programType",
  row: "row",
  vault: "vault",
  programTypeAll: "programTypeAll",
  customer: "customer",
  logicalVaultField: "logicalVaultField",
  rack: "rack",
  formFactor: "formFactor",
  programTypeOM: "programTypeOM"
};

export const na = "n/a";

export const percentage = "%";

export const options = {
  showAllCapacities: t("vaultManagementReports.showAllCapacities"),
  capacityGreaterThan: t("vaultManagementReports.capacityGreaterThan"),
  capacityLessThan: t("vaultManagementReports.capacityLessThan"),
  capacityHistory: t("vaultManagementReports.capacityHistory"),
  configurationSummary: t("vaultManagementReports.configurationSummary"),
  configurationDetail: t("vaultManagementReports.configurationDetail"),
  inboundOutboundInventory: t(
    "vaultManagementReports.inboundOutboundInventory"
  ),
  summary: t("vaultManagementReports.summary"),
  detail: t("vaultManagementReports.detail"),
  unassignedShelves: t("vaultManagementReports.unassignedShelves"),
  rackingNearCapacity: t("vaultManagementReports.rackingNearCapacity"),
  availableCapacity: t("vaultManagementReports.availableCapacity"),
  containersIncorrectShelfDuration: t(
    "vaultManagementReports.containersWithIncorrectShelfDuration"
  ),
  containerSlotPhysicalVaultCustomerMap: t(
    "vaultManagementReports.containerSlottingPhysicalVaultCustomerMap"
  ),
  logicalVaultsIncorrectShelfDuration: t(
    "vaultManagementReports.logicalVaultsWithIncorrectShelfDuration"
  ),
  nonContiguousLogicalVaults: t(
    "vaultManagementReports.nonContiguousLogicalVaults"
  ),
  logicalVaultsHourlyHandlingNoReviewMonthly: t(
    "vaultManagementReports.logicalVaultsWHourlyHandlingNoReviewMonthly"
  )
};

export const dropDownOptions = {
  capacity: [
    options.showAllCapacities,
    options.capacityGreaterThan,
    options.capacityLessThan,
    options.rackingNearCapacity,
    options.capacityHistory,
    options.unassignedShelves,
    options.availableCapacity
  ],
  configuration: [options.summary, options.detail],
  management: [
    options.containersIncorrectShelfDuration,
    options.containerSlotPhysicalVaultCustomerMap,
    options.logicalVaultsIncorrectShelfDuration,
    options.nonContiguousLogicalVaults,
    options.logicalVaultsHourlyHandlingNoReviewMonthly,
    options.inboundOutboundInventory
  ]
};

export const minimumDate = "1997-01-01";
export const globalAttributeId = "96";
export const systemId = "60";
