import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";

import { FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH,
  WORK_MANAGEMENT
} from "routing/Paths";
import { useGetAllCustomersQuery } from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_LOCALE,
  ERROR_TYPES,
  VALUE_N,
  VALUE_Y,
  downloadReportOptions,
  errorMsgs,
  ymdDateFormatTime
} from "utils/constants";
import { distribution } from "utils/constants/open-media-processing/OpenMediaAuditConstants";
import {
  all,
  close,
  complete,
  distributListReadyToScan,
  distributeAll,
  distributeFilterOptions,
  distributeOptionAll,
  distributeOptionReadyToDistribute,
  distributeOptionReadyToScan,
  distributeOptioncompletedBatches,
  distributePastDue,
  distributionTabId,
  initialServiceDays,
  limit,
  notStarted,
  sortEmptySLot,
  started,
  zero
} from "utils/constants/open-media-processing/WorkManagement";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import WorkManagementDataTable from "../WorkManagementDataTable";
import { WorkManagementStyles } from "../WorkManagementStyles";
import DistributionTree from "./DistributionTree";
import PrintDistributionModal from "./PrintDistrubtionModal";
const Distribution = ({ localeByBranch }) => {
  const location = useLocation();
  const { currentBranch } = useSelector(selectAuth);
  const [findBtnStatus, setFindBtnStatus] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [distributeFilter, setdistributeFilter] = useState(
    distributeOptionReadyToDistribute
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [distributeFilterData, setdistributeFilterData] = useState({
    all: distributeAll,
    customerId: "",
    customerLabel: t("common.all"),
    serviceDays: initialServiceDays
  });
  const [checked, setChecked] = useState([]);
  const [showTree, setShowTree] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [serviceDaysModal, setServiceDaysModal] = useState(false);
  const [serviceDaysModalMsg, setServiceDaysModalMsg] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  const [size, setSize] = useState("");
  const [vault, setVualt] = useState(all);
  const [row, setRow] = useState(all);
  const [rack, setRack] = useState(all);
  const [shelf, setShelf] = useState(all);

  const [checkedDistributionData, setCheckedDistributionData] = useState([]);
  const [distributionList, setDistributionList] = useState([]);
  const [logicalVaultList, setLogicalVaultList] = useState([]);
  const [priorToTodayFlag, setPriotToTodayFlag] = useState(VALUE_Y);
  const [readyToScan, setToReadyToScan] = useState("");
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [printValidationModal, setPrintValidationModal] = useState(false);
  const [printValidationData, setPrintValidationData] = useState("");
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [selectLogicalVault, setSelectLoagicalVualt] = useState("");
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);
  const [printErrModal, setPrintErrorModal] = useState(false);
  const { auth } = useSelector(selectAuth);
  const [logicalVaultErr, setLogicalVaultErr] = useState("");
  const [logicalVaultErrModal, setLogicalVaultErrModal] = useState(false);
  const [printOmList, setPrintOmList] = useState([]);

  const slotData = [];
  const clear = () => {
    setThrowError(false);
    setThrowErrorMessage(null);
    setPriotToTodayFlag(VALUE_N);
    if (distributeFilter === distributeOptionReadyToScan) {
      setdistributeFilterData({
        all: distributListReadyToScan,
        customerId: "",
        customerLabel: t("common.all"),
        serviceDays: initialServiceDays
      });
    } else {
      setdistributeFilterData({
        all: distributeAll,
        customerId: "",
        customerLabel: t("common.all"),
        serviceDays: initialServiceDays
      });
    }
    setTableData([]);
    setExpanded([]);
    setChecked([]);
    setFindBtnStatus(false);
    setShowTree(false);
    setCustomerList([]);
    setDistributionList([]);
  };

  const refresh = () => {
    setTableData([]);
    setChecked([]);
    setExpanded([distribution]);
  };
  const loadTablePicking = (checked, data) => {
    setCheckedDistributionData(checked);
    setTableData(data);
  };
  const handleRowSelection = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    setSelectedRows((prev) => rowsSelected);
  };
  const tableOptions = {
    sort: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    onRowSelectionChange: handleRowSelection,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    hideHeader: true,
    rowsSelected: selectedRows,
    setRowProps: (row, dataIndex) => {
      const isSelected = selectedRows?.includes(dataIndex);
      return {
        style: {
          backgroundColor: isSelected && "var(--color-im-light-blue-100)"
        }
      };
    }
  };

  function groupAndFormatData(data) {
    const groupedByCustomer = {};
    const groupedByLogicalVault = {};

    data.forEach((entry) => {
      const customerId = entry.customer_id;
      const logicalVaultId = entry.logical_vault_id;

      if (!groupedByCustomer[customerId]) {
        groupedByCustomer[customerId] = [];
      }
      groupedByCustomer[customerId].push(entry);

      if (!groupedByLogicalVault[logicalVaultId]) {
        groupedByLogicalVault[logicalVaultId] = [];
      }
      groupedByLogicalVault[logicalVaultId].push(entry);
    });

    const result = {
      0: [],
      1: [],
      2: []
    };

    // Populating "0" index
    Object.values(groupedByCustomer).forEach((customerEntries) => {
      customerEntries.forEach((entry) => {
        result["0"].push(entry);
      });
    });

    // Populating "1" index
    Object.entries(groupedByCustomer).forEach(
      ([customerId, customerEntries]) => {
        const customerInfo = {
          customer_number: customerEntries[0].customer_number,
          count: customerEntries.reduce(
            (sum, entry) => sum + parseInt(entry.count),
            0
          ),
          customer_id: customerId
        };
        result["1"].push(customerInfo);
      }
    );

    // Populating "2" index
    Object.entries(groupedByLogicalVault).forEach(
      ([logicalVaultId, vaultEntries]) => {
        const firstEntry = vaultEntries[0];
        const vaultInfo = {
          customer_number: firstEntry.customer_number,
          customer_id: firstEntry.customer_id,
          logical_vault_code: firstEntry.logical_vault_code,
          logical_vault_id: logicalVaultId,
          sort_column: firstEntry?.sort_column
        };

        // Dynamically include other fields if they exist
        if (
          Object.prototype.hasOwnProperty.call(
            firstEntry,
            "interactive_distribution_scan_flag"
          )
        ) {
          vaultInfo["interactive_distribution_scan_flag"] =
            firstEntry["interactive_distribution_scan_flag"];
        }
        if (
          Object.prototype.hasOwnProperty.call(firstEntry, "manual_count_flag")
        ) {
          vaultInfo["manual_count_flag"] = firstEntry["manual_count_flag"];
        }
        if (Object.prototype.hasOwnProperty.call(firstEntry, "sort_column")) {
          vaultInfo["sort_column"] = firstEntry["sort_column"];
        }

        result["2"].push(vaultInfo);
      }
    );

    return result;
  }

  function removeDuplicates(arr) {
    const seen = new Set();
    return arr.filter((item) => {
      if (seen.has(item.customer_id)) {
        return false;
      } else {
        seen.add(item.customer_id);
        return true;
      }
    });
  }
  const fetchFindFilter = async () => {
    const districtId = await getAuthenticatedUserBranch();
    try {
      const days = parseInt(distributeFilterData.serviceDays, 10);
      if (days < 0 || days > 30) {
        const serviceErrorDescr = await getMessageFromCode(
          errorMsgs.errorCode21060
        );
        const description = serviceErrorDescr[0].descr
          .replace("|", t("workManagement.picking.numberOfDays"))
          .replace("|", t("workManagement.picking.oneDays"))
          .replace("|", t("workManagement.picking.thirtyDays"));
        setServiceDaysModalMsg(description);
        setServiceDaysModal(true);
      } else {
        setLoading(true);
        const reqBody = {
          main_district_id: districtId,
          display_view: distributeFilter,
          customer_id: distributeFilterData?.customerId,
          prior_to_today_flag: priorToTodayFlag,
          prior_num_days:
            distributeFilter === distributeOptioncompletedBatches
              ? distributeFilterData?.serviceDays
              : "",
          ready_scan_result_bit:
            distributeFilter === distributeOptionReadyToScan ? readyToScan : ""
        };
        const response = await callToCloudFunction(
          reqBody,
          `${CF_URLS.openMediaProcessing?.workManagement.picking.getPopulateDailyDistributionTasks}`
        );
        const data = await getDataFromFirestore(
          response,
          3,
          response.data.docId
        );
        const list = data.data[1];
        const logicalVaultList = data.data[2].sort((a, b) =>
          a.sort_column.localeCompare(b.sort_column)
        );
        if (
          distributeFilter === distributeOptionReadyToDistribute ||
          distributeFilter === distributeOptioncompletedBatches
        ) {
          const res = groupAndFormatData(data.data[0]);

          const commonCustomers = res[1].filter((item1) =>
            res[0].some((item2) => item1.customer_id === item2.customer_id)
          );
          const sortedData = commonCustomers?.sort((a, b) =>
            a.customer_number.localeCompare(b.customer_number)
          );

          setCustomerList(sortedData);
          setLogicalVaultList(res[2]);
          const distributionList = customerSorting(data.data[0]);
          setDistributionList(distributionList);
        } else {
          const customerIds1 = data.data[0].map((item) => item.customer_id);
          const customerIds2 = data.data[1].map((item) => item.customer_id);
          const notInArray2 = customerIds1.filter(
            (id) => !customerIds2.includes(id)
          );
          const removeDublicateCustomer = [...new Set(notInArray2)];
          const missingCustomer = data.data[0].filter((item) =>
            removeDublicateCustomer.includes(item.customer_id)
          );
          const validCustomer = removeDuplicates(missingCustomer);
          const customers = [...data.data[1], ...validCustomer];
          const logicalVaults = [...logicalVaultList, ...validCustomer];
          const sortedData = customers?.sort((a, b) =>
            a.customer_number.localeCompare(b.customer_number)
          );
          if (!list[0]?.error) {
            setCustomerList(sortedData);
          }

          setLogicalVaultList(logicalVaults);
          const distributionList = customerSorting(data.data[0]);
          setDistributionList(distributionList);
        }
        if (response.data.isSuccess) {
          setFindBtnStatus(true);
          setShowTree(true);
          setLoading(false);
          setThrowError(false);
          setThrowErrorMessage(null);
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const columns = [
    {
      name: "customer_number",
      label: t("branchOutagePickList.customer")
    },
    {
      name: "logical_vault_code",
      label: t("branchOutagePickList.logicalVault")
    },
    {
      name: "task",
      label: t("workManagement.distribution.task")
    },
    {
      name: "task_status",
      label: t("branchOutagePickList.taskStatus")
    },
    {
      name: "count",
      label: t("branchOutagePickList.count")
    }
  ];
  const removeDublicateBatch = (data) => {
    const uniqueBatches = [
      ...new Map(
        data?.map((item) => [item?.open_media_batch_id, item])
      ).values()
    ];
    return uniqueBatches;
  };
  const handleDownload = async () => {
    const districtId = await getAuthenticatedUserBranch();

    try {
      setLoading(true);
      setThrowError(false);
      const reqBody = {
        main_district_id: districtId,
        district_id: districtId,
        reprint_flag: checkedTableData.some((o) => o.task_status === complete)
          ? VALUE_Y
          : VALUE_N,
        open_media_batch_id_list: printOmList
          ?.map((o) => o.open_media_batch_id)
          ?.map((batch) => batch.toString().padStart(10, " "))
          .join(""),
        insert_size: size,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      };
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.openMediaProcessing.distributionReport}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };
  const handleFilter = (e, { props }) => {
    setdistributeFilter(props?.value);
    if (props?.value === distributeOptionReadyToScan) {
      setdistributeFilterData({
        all: distributListReadyToScan,
        customerId: "",
        customerLabel: t("common.all"),
        serviceDays: initialServiceDays
      });
    } else {
      setdistributeFilterData({
        all: distributeAll,
        customerId: "",
        customerLabel: t("common.all"),
        serviceDays: initialServiceDays
      });
    }
    setPriotToTodayFlag((prevState) => VALUE_N);
    setTableData([]);
    setExpanded([]);
    setChecked([]);
    setFindBtnStatus(false);
    setShowTree(false);
    setCustomerList([]);
    setDistributionList([]);
    setLogicalVaultList([]);
    setToReadyToScan(distributListReadyToScan);
  };

  const printValidationModalSubmit = (printSlot) => {
    if (printSlot) {
      setPrintValidationModal(false);
      setOpenPrintModal(false);
      setShowPrintModal(true);
    } else {
      setPrintValidationModal(false);
      setOpenPrintModal(true);
    }
  };

  const handleLogicalVualtYes = () => {
    setLogicalVaultErrModal(false);
    assignSlot(false);
  };
  const handleLogicalVualtNo = () => {
    setLogicalVaultErrModal(false);
    assignSlot(true);
  };

  const handleServiceDays = (e) => {
    setFindBtnStatus(false);
    setShowTree(false);
    setTableData([]);
    setChecked([]);
    setExpanded([]);
    setCustomerList([]);
    let value = e.target.value;
    if (value.length > 2) {
      value = value.slice(0, 2);
    }
    const numValue = parseInt(value, 10);
    setdistributeFilterData((prev) => ({
      ...prev,
      serviceDays: numValue
    }));
  };
  const handleFilterAll = (event) => {
    setFindBtnStatus(false);
    setShowTree(false);
    setTableData([]);
    setChecked([]);
    setExpanded([]);
    setCustomerList([]);
    if (event.target.value === distributeAll) {
      setToReadyToScan(event.target.value);
      setPriotToTodayFlag(VALUE_N);
    }
    if (event.target.value === distributListReadyToScan) {
      setToReadyToScan(event.target.value);
      setPriotToTodayFlag(VALUE_N);
    }
    if (event.target.value === distributePastDue) {
      setToReadyToScan(event.target.value);
      setPriotToTodayFlag(VALUE_N);
    }
    if (event.target.value === VALUE_Y) {
      setPriotToTodayFlag(event.target.value);
      setToReadyToScan("");
    }
    setdistributeFilterData((prev) => ({
      ...prev,
      all: event.target.value
    }));
  };
  const handleFilterCustomer = (e, newValue) => {
    setdistributeFilterData((prev) => ({
      ...prev,
      customerId: newValue?.value,
      customerLabel: newValue?.label
    }));

    setTableData([]);
    setExpanded([]);
    setChecked([]);
    setFindBtnStatus(false);
    setShowTree(false);
    setCustomerList([]);
    setDistributionList([]);
    setLogicalVaultList([]);
  };
  const customerSorting = (data) => {
    return data?.sort((a, b) => {
      const customerNumberA = a.customer_number?.trim();
      const customerNumberB = b.customer_number?.trim();

      if (customerNumberA === customerNumberB) {
        const created_datetimeA = a.created_datetime || sortEmptySLot;
        const created_datetimeB = b.created_datetime || sortEmptySLot;
        return created_datetimeA.localeCompare(created_datetimeB);
      }

      return customerNumberA.localeCompare(customerNumberB);
    });
  };
  const checkedTableData = customerSorting(tableData)
    ?.map((dl) => {
      dl.nodeId =
        dl.created_datetime + dl.task_status + dl.customer_file_section_id;
      dl.task =
        (dl?.task_status === notStarted || dl?.task_status === started
          ? t("workManagement.distribution.list")
          : t("workManagement.distribution.mediaBatch")) +
        " " +
        dateTimeFormatByLocale(
          dl?.created_datetime,
          localeByBranch || DEFAULT_LOCALE
        ) +
        " " +
        dl.scanned_by_initials;
      return dl;
    })
    .filter((pick) => checkedDistributionData.includes(pick.nodeId));
  const handleRowClick = (rowData, rowState) => {
    const printStatus = checkedTableData[rowState.dataIndex];
    return printStatus;
  };
  const areAllTasksNotStarted = (dataArray) => {
    if (checkedTableData?.length === 0) {
      return true;
    }
    return checkedTableData?.every(
      (item) => item.task_status === notStarted || item.task_status === complete
    );
  };
  const areAllTasksCompleted = (dataArray) => {
    if (checkedTableData?.length === 0) {
      return false;
    }
    return checkedTableData?.every((item) => item.task_status === complete);
  };
  const openPrint = async () => {
    if (checkedTableData.some((item) => item.task_status === notStarted)) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode25152);
      const formatData = (dataArray) => {
        const displayedData = dataArray.slice(0, limit).join("\n");
        const suffix = dataArray.length > limit ? "\n..." : "";
        return displayedData + suffix;
      };
      const printErr = formatData(
        checkedTableData
          .filter((obj) => obj?.task_status === notStarted)
          ?.map((item) => {
            const dateTimeFormatted = dayjs(item.created_datetime).format(
              ymdDateFormatTime
            );
            return `${item.customer_number?.trim()} ${item.logical_vault_code?.trim()} ${dateTimeFormatted}`;
          }) || []
      );
      const msg = errDesc[0].descr.replace("|", printErr);
      setPrintValidationData(msg);
      setPrintValidationModal(true);
    } else {
      setOpenPrintModal(true);
    }
  };
  const logicalVualtCheck = async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        open_media_batch_id_list: removeDublicateBatch(checkedTableData)
          ?.map((o) => o.open_media_batch_id)
          ?.map((batch) => batch.toString().padStart(10, " "))
          .join("")
      };
      const response = await getResponseData(
        reqBody,
        `${CF_URLS.openMediaProcessing?.workManagement.picking.logicalVualtCheck}`,
        2
      );
      return response.data;
    } catch (error) {
      setLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };
  function getMatchingData(firstArray, secondArray) {
    const result = [];

    firstArray.forEach((item1) => {
      const matches = secondArray.filter((item2) => {
        if (item2.customer_id === item1.customer_id) {
          // Update task_status if new_batch_status is "Completed"
          if (item1.new_batch_status === complete) {
            item2.task_status = complete;
          }
          return true;
        }
        return false;
      });
      result.push(...matches);
    });

    return result;
  }
  const assignSlot = async () => {
    try {
      const searchLocation = `${vault === all ? "" : vault}-${
        row === all ? "" : row
      }-${rack === all ? "" : rack}-${shelf === all ? "" : shelf}`;
      const reqBody = {
        main_district_id: currentBranch?.value,
        open_media_batch_id_list: removeDublicateBatch(checkedTableData)
          ?.map((o) => o.open_media_batch_id)
          ?.map((batch) => batch.toString().padEnd(10, " "))
          .join(""),
        startinglocation: searchLocation === "---" ? "" : searchLocation
      };
      const response = await getResponseData(
        reqBody,
        `${CF_URLS.openMediaProcessing?.workManagement.picking.assignSlot}`,
        2
      );
      if (response.data[0]) {
        for (let i = 0; i < response.data[0].length; i++) {
          if (response.data[0][i]?.success_or_error) {
            if (response.data[0][i]?.success_or_error !== zero) {
              slotData.push(response.data[0][i]);
            }
          }
        }
        if (slotData.length > 0) {
          setLoading(false);
          setPrintErrorModal(true);
          const errCodeMessage = await getMessageFromCode(
            errorMsgs.errorCode25291
          );
          const err = errCodeMessage[0].descr.replace(
            "|",
            checkedTableData.map((obj) => obj.task)
          );
          setPrintValidationData(err);
          setPrintValidationModal(true);
        } else {
          const updatedTableData = getMatchingData(
            response.data[0],
            checkedTableData
          );
          setTableData(updatedTableData);
          setOpenPrintModal(false);
          setShowPrintModal(true);
          setLoading(false);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };
  const handlePrint = async () => {
    try {
      setLoading(true);
      if (checkedTableData.some((o) => o.task_status === complete)) {
        setLoading(false);
        setShowPrintModal(true);
      } else {
        const logicalVault = await logicalVualtCheck();
        if (logicalVault[1][0].error === zero) {
          await assignSlot();
          setPrintOmList(removeDublicateBatch(checkedTableData));
        } else {
          const errmsg = await getMessageFromCode(errorMsgs.errorCode25381);
          setLogicalVaultErr(
            errmsg[0].descr.replace(
              "|",
              checkedTableData?.map((obj) => obj?.logical_vault_id)
            )
          );
          setLogicalVaultErrModal(true);

          setLoading(false);
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setLoading(false);
    }
  };
  const countData = tableData
    .filter((o) => checkedDistributionData.includes(o.nodeId))
    ?.reduce((acc, cur) => acc + parseInt(cur.count), 0);
  const tableTitleSection = (
    <Box pt={spacing.padding}>
      <Typography variant="h5" style={WorkManagementStyles.titleStyle}>
        {t("workManagement.distribution.selectedTask")}{" "}
        {checkedTableData.length}
      </Typography>

      <Stack
        direction="row"
        spacing={10}
        mt={spacing.verticalMargin32}
        mb={spacing.verticalMargin16}
      >
        <Typography variant="body1" style={WorkManagementStyles.titleStyle}>
          {t("workManagement.distribution.totalCount")}
          {countData}
        </Typography>
      </Stack>
    </Box>
  );

  useEffect(() => {
    // if the route state has tab value and filter value
    if (location.state?.tab === distributionTabId) {
      // if filter is available in the route state
      if (location.state?.filter) {
        handleFilter(null, {
          props: { value: location.state.filter }
        });
      }

      // if prior_to_today_flag is available in the route state
      if (location.state?.prior_to_today_flag) {
        handleFilterAll({
          target: { value: location.state?.prior_to_today_flag }
        });
      }

      // if option is available in the route state
      if (location.state?.option) {
        handleFilterAll({
          target: { value: location.state?.option }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    // if the route state has tab value and state's filter value changed
    if (location.state?.tab === distributionTabId) {
      if (
        distributeFilter === location.state?.filter &&
        (priorToTodayFlag === location.state?.prior_to_today_flag ||
          readyToScan === location.state?.option)
      ) {
        fetchFindFilter().then(() => {
          window.history.replaceState(
            null,
            "",
            `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributeFilter, priorToTodayFlag, readyToScan]);

  return (
    <>
      {loading && <FullCircularLoader />}

      {!openPrintModal && !showPrintModal && throwError && (
        <Alert
          severity="error"
          icon={<img src={ErrorIcon} alt="error" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
                setThrowErrorMessage(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      )}
      <br />
      <Grid container spacing={2} alignItems="">
        <Grid item md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="printerNameLabel">
              {t("workManagement.picking.filter")}
            </InputLabel>
            <Select
              label={"filter"}
              id={"filterId"}
              name={"filterlabel"}
              value={distributeFilter}
              onChange={handleFilter}
            >
              {distributeFilterOptions?.map((obj) => (
                <MenuItem value={obj?.value} key={obj?.value}>
                  {obj?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {distributeFilter !== distributeOptionAll &&
          distributeFilter !== distributeOptioncompletedBatches && (
            <Grid item md={6} lg={4}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={distributeFilterData.all}
                  onChange={handleFilterAll}
                >
                  <FormControlLabel
                    value={distributeAll}
                    control={<Radio />}
                    label={t("workManagement.distribution.all")}
                  />
                  {distributeFilter === distributeOptionReadyToScan && (
                    <FormControlLabel
                      value={distributListReadyToScan}
                      control={<Radio />}
                      label={t("workManagement.distribution.listReadyToScan")}
                    />
                  )}
                  {distributeFilter === distributeOptionReadyToScan ? (
                    <FormControlLabel
                      value={distributePastDue}
                      control={<Radio />}
                      label={t("workManagement.distribution.listPastDue")}
                    />
                  ) : (
                    <FormControlLabel
                      value="Y"
                      control={<Radio />}
                      label={t("workManagement.distribution.priorToToday")}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        {distributeFilter === distributeOptioncompletedBatches && (
          <Grid item md={6} lg={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                type="number"
                label={t("workManagement.picking.withinLastDays")}
                value={distributeFilterData?.serviceDays}
                onChange={handleServiceDays}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item md={6} lg={3}>
          <FormControl fullWidth variant="outlined">
            <CustomersField
              options={[
                {
                  label: t("common.all"),
                  value: ""
                },
                ...customers
              ]}
              value={distributeFilterData?.customerLabel}
              handleOnChange={handleFilterCustomer}
            />
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Stack direction="row" gap={spacing.gap}>
        <Button id="newBtnfilter" variant="outlined" onClick={() => clear()}>
          {t("common.clear")}
        </Button>
        <Button
          id="saveBtnfilter"
          variant="contained"
          type="submit"
          disabled={findBtnStatus}
          onClick={() => fetchFindFilter()}
        >
          {t("common.find")}
        </Button>
      </Stack>
      <br />
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <CardContent sx={WorkManagementStyles.treeCardContent}>
                {showTree && (
                  <DistributionTree
                    localeByBranch={localeByBranch}
                    setSelectLoagicalVualt={setSelectLoagicalVualt}
                    customerList={customerList}
                    logicalVaultList={logicalVaultList}
                    distributionList={distributionList}
                    loadTablePicking={loadTablePicking}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    checked={checked}
                    setChecked={setChecked}
                    distributeFilter={distributeFilter}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <WorkManagementDataTable
              customOptions={tableOptions}
              columns={columns}
              tableData={checkedTableData}
              handleRowClick={handleRowClick}
              title={checkedTableData?.length > 0 && tableTitleSection}
              customToolbar={() => (
                <>
                  <Grid
                    item
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="end"
                    gap={spacing.verticalMargin20}
                  >
                    <Button
                      variant="outlined"
                      size="medium"
                      type="button"
                      disabled={customerList?.length === 0}
                      onClick={() => refresh()}
                    >
                      {t("workManagement.distribution.refresh")}
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      type="button"
                      disabled={areAllTasksNotStarted()}
                      onClick={() => openPrint()}
                    >
                      {t("workManagement.distribution.printDistributionRpt")}
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      type="button"
                      disabled={!areAllTasksCompleted()}
                      onClick={() => openPrint()}
                    >
                      {t("workManagement.distribution.rePrintDistributionRpt")}
                    </Button>
                  </Grid>
                </>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      {printValidationModal && (
        <CommonModal
          open={printValidationModal}
          title={t("workManagement.distribution.serviceModalTitle")}
          body={
            <>
              <Typography>{printValidationData}</Typography>
            </>
          }
          buttons={
            <>
              <Button
                variant="contained"
                onClick={() => printValidationModalSubmit(printErrModal)}
              >
                {t("common.yes")}
              </Button>
              <Button
                variant="outlined"
                onClick={() => setPrintValidationModal(false)}
              >
                {t("common.no")}
              </Button>
            </>
          }
        />
      )}
      {logicalVaultErrModal && (
        <CommonModal
          open={logicalVaultErrModal}
          title={t("workManagement.distribution.serviceModalTitle")}
          body={
            <>
              <Typography>{logicalVaultErr}</Typography>
            </>
          }
          buttons={
            <>
              <Button
                variant="contained"
                onClick={() => handleLogicalVualtYes()}
              >
                {t("common.yes")}
              </Button>
              <Button variant="outlined" onClick={() => handleLogicalVualtNo()}>
                {t("common.no")}
              </Button>
            </>
          }
        />
      )}
      {serviceDaysModal && (
        <CommonModal
          open={serviceDaysModal}
          title={t("workManagement.distribution.serviceModalTitle")}
          body={
            <>
              <Typography>{serviceDaysModalMsg}</Typography>
            </>
          }
          buttons={
            <>
              <Button
                variant="contained"
                onClick={() => setServiceDaysModal(false)}
              >
                {t("common.yes")}
              </Button>
            </>
          }
        />
      )}
      {openPrintModal && (
        <PrintDistributionModal
          vault={vault}
          setVualt={setVualt}
          row={row}
          setRow={setRow}
          setRack={setRack}
          rack={rack}
          shelf={shelf}
          setShelf={setShelf}
          size={size}
          setSize={setSize}
          tableData={checkedTableData}
          openPrintModal={openPrintModal}
          selectLogicalVault={selectLogicalVault}
          logicalVualtCheck={handlePrint}
          onClose={(e) => {
            if (e === close) {
              setOpenPrintModal(false);
            } else {
              setOpenPrintModal(false);
              setShowPrintModal(true);
            }
          }}
          setThrowError={setThrowError}
          setThrowErrorMessage={setThrowErrorMessage}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
        />
      )}
      <DownloadPopup
        throwError={throwError}
        setThrowError={setThrowError}
        throwErrorMessage={throwErrorMessage}
        setThrowErrorMessage={setThrowErrorMessage}
        title={t("common.downloadReport")}
        showPrintModal={showPrintModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => setShowPrintModal((prev) => !prev)}
        onDownloadClick={() => handleDownload()}
        setSelectedValue={setSelectedValue}
      />
    </>
  );
};
export default Distribution;
