import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Stack
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ClosedIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader, SelectField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { Formik, useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADMIN_MODULE_BASE_PATH,
  CUSTOMER_MOVE_DETAIL,
  CUSTOMER_MOVE_SEARCH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import {
  getCustomersQueryData,
  getDistricts,
  getMessageFromCode
} from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  BOOLEAN_STRING_VALUES,
  customerMoveSearch,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  errorMsgs,
  N_CHECKER,
  Y_CHECKER
} from "utils/constants";
import {
  findErrorMessage,
  getResponseData,
  getSortedData
} from "utils/helpers";
import CustomerMoveSearchTable from "./CustomerMoveSearchTable";
import { CustomerSearchStyles } from "./CustomerSearchStyles";

const CustomerSearchLayout = () => {
  const { auth, currentBranch } = useSelector(selectAuth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeOnlyFlag } = useParams();
  const [loading, setIsLoading] = useState(false);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const [sourceCustomer, setSourceCustomer] = useState([]);
  const [destinationCustomer, setDestinationCustomer] = useState([]);
  const [lastProcess, setLastProcess] = useState([]);
  const [throwError, setThrowError] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loadDataForParams, setLoadDataForParams] = useState(false);
  const [showProcessButton, setShowProcessButton] = useState(false);
  const [processButtonEnabled, setProcessButtonEnabled] = useState(false);
  const [showDeactivateButton, setShowDeactivateButton] = useState(false);
  const [showActivateButton, setShowActivateButton] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const initialCustomerMoveSearchValues = {
    sourceBranch: "",
    destinationBranch: "",
    sourceCustomer: "",
    destinationCustomer: "",
    lastProcessPerformed: t("customerMoveSearch.all"),
    isActiveOnly: ""
  };

  const customerMoveForm = useFormik({
    initialValues: initialCustomerMoveSearchValues
  });

  const fetchBranches = useCallback(async () => {
    setIsLoading(true);
    try {
      const allBranchesData = await getDistricts();

      let transformedBranch = allBranchesData?.map((b) => {
        return {
          ...b,
          label: b?.name.trim(),
          value: b?.district_id
        };
      });
      const branchData = getSortedData(transformedBranch, "name", "asc");
      setAllBranches(branchData);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  }, []);

  const getCustomersBasedOnBranch = useCallback(async (branch, sourceName) => {
    setIsLoading((prev) => true);
    try {
      const custList = await getCustomersQueryData(branch);
      let modCux = custList?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number} - ${cus?.name}`,
          value: cus?.id
        };
      });

      const updatedModCux = modCux?.filter((item) => {
        return (
          item.active_flag === Y_CHECKER &&
          item.on_hold === BOOLEAN_STRING_VALUES.FALSE
        );
      });

      //  set data according to source branch /dest branch
      sourceName === customerMoveSearch.sourceBranch
        ? setSourceCustomer(updatedModCux)
        : setDestinationCustomer(updatedModCux);

      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  }, []);

  const lastProcessPerformed = useCallback(async () => {
    setIsLoading(true);
    const lastProcessReqBody = {
      main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
      district_id: currentBranch?.value
    };

    try {
      const res = await getResponseData(
        lastProcessReqBody,
        CF_URLS.customerSearchInformation.returncustomermoveprocess,
        1
      );

      let lastProcessData = res?.data["0"].map((b) => {
        return {
          ...b,
          label: b?.process_name.trim(),
          value: b?.customer_move_process_id
        };
      });

      const lastProcessDropdownData = [
        { label: t("customerMoveSearch.all"), value: "All" },
        ...getSortedData(lastProcessData, "label", "desc")
      ];

      setLastProcess(lastProcessDropdownData);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch?.value]);

  const processTableData = (tableData) => {
    const bran = tableData?.data?.[0]?.map((b) => ({
      ...b,
      label: b?.process_name.trim(),
      process_name: b?.process_name
    }));
    const rowsWithName = bran.filter((b) => b.process_name);
    const rowsWithoutName = bran.filter((b) => !b.process_name);
    const sortedWithName = getSortedData(rowsWithName, "process_name", "desc");
    const combinedSortedData = [...rowsWithoutName, ...sortedWithName];
    return getSortedData(combinedSortedData, "is_active", "desc");
  };
  const fetchVerifyByEmployeeId = async () => {
    setIsLoading(true);
    try {
      if (
        customerMoveForm.values.sourceBranch ||
        customerMoveForm.values.destinationBranch
      ) {
        const VerifyEmployeeReqBody = {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          district_id: currentBranch?.value,
          employee_id: auth?.user?.id,
          src_district_id: customerMoveForm.values.sourceBranch,
          dest_district_id: customerMoveForm.values.destinationBranch
        };

        const res = await getResponseData(
          VerifyEmployeeReqBody,
          CF_URLS.customerSearchInformation.verifyemployeeid,
          1
        );

        if (res?.data?.[0]?.[0].error === errorMsgs.errorCode20538) {
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode20538);
          setShowErrorAlertMsg(errorMsg[0]?.descr);
          setThrowError(true);
          customerMoveForm.resetForm();
        } else if (res?.data?.[0]?.[0].error === errorMsgs.noError) {
          const tableData = await fetchCustomerTableData();
          const mappedTableData = processTableData(tableData);
          setSearchResults(mappedTableData);
          setIsResetGrid(false);
        }
      } else {
        const tableData = await fetchCustomerTableData();
        const mappedTableData = processTableData(tableData);
        setSearchResults(mappedTableData);
        setIsResetGrid(false);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      return false;
    }
  };

  const fetchCustomerTableData = async () => {
    setIsLoading(true);
    setProcessButtonEnabled(false);
    setShowProcessButton(false);
    setShowDeactivateButton(false);
    setShowActivateButton(false);
    setSelectedIndex(null);
    try {
      const CustomerTableReqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: currentBranch?.value,
        employee_id: auth?.user?.id,
        source_district_id: customerMoveForm.values.sourceBranch,
        source_customer_number: customerMoveForm.values.sourceCustomer,
        destination_district_id: customerMoveForm.values.destinationBranch,
        destination_customer_number:
          customerMoveForm.values.destinationCustomer,
        process_id:
          customerMoveForm.values.lastProcessPerformed ===
          t("customerMoveSearch.all")
            ? ""
            : customerMoveForm.values.lastProcessPerformed,
        active_only: customerMoveForm.values.isActiveOnly
      };

      const res = await getResponseData(
        CustomerTableReqBody,
        CF_URLS.customerSearchInformation.getcustomermoverelateddata,
        1
      );

      setIsLoading(false);
      if (activeOnlyFlag) {
        setSearchResults(res?.data[0]);
        setIsResetGrid(false);
      } else {
        return res;
      }
    } catch (error) {
      setIsLoading(false);
      setThrowError(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      return false;
    }
  };

  /* Activate/Deactivate grid entries*/
  const fetchChangeStatus = async (actionType) => {
    setIsLoading(true);
    try {
      const changeStatusReqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: currentBranch?.value,
        customer_move_event_id: selectedRow?.customer_move_event_id,
        cmu_status: actionType,
        rc: ""
      };

      const res = await getResponseData(
        changeStatusReqBody,
        CF_URLS.customerSearchInformation.updateStatus,
        1
      );

      if (res?.data?.[0]?.[0].rc === errorMsgs.errorCode80494) {
        let errorMsg = await getMessageFromCode(errorMsgs.errorCode80494);
        const toReplaceText =
          actionType === N_CHECKER
            ? t("customerMoveSearch.deactivate").toLowerCase()
            : t("customerMoveSearch.activate").toLowerCase();

        let replacedText = errorMsg[0]?.descr.replace("|", toReplaceText);

        setShowErrorAlertMsg(
          replacedText || findErrorMessage(ERROR_TYPES.ISSUE)
        );
      } else {
        if (res?.data?.[0]?.[0].rc === errorMsgs.noError) {
          const tableData = await fetchCustomerTableData();
          const mappedTableData = processTableData(tableData);
          setSearchResults(mappedTableData);
          setIsResetGrid(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setThrowError(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const handleSourceBranch = (event) => {
    setSearchResults([]);
    customerMoveForm.setFieldValue(
      customerMoveSearch.sourceBranch,
      event.target.value
    );
    getCustomersBasedOnBranch(
      event.target.value,
      customerMoveSearch.sourceBranch
    );
  };

  const handleSourceDestination = (event) => {
    customerMoveForm.setFieldValue(
      customerMoveSearch.destinationBranch,
      event.target.value
    );
    getCustomersBasedOnBranch(
      event?.target?.value,
      customerMoveSearch.destinationBranch
    );
  };

  const filteredDestinationBranches = useMemo(() => {
    return allBranches.filter(
      (branch) => branch.value !== customerMoveForm?.values?.sourceBranch
    );
  }, [allBranches, customerMoveForm?.values?.sourceBranch]);

  const handleConfirm = (actionType) => {
    fetchChangeStatus(actionType);
  };

  const handleNewClick = () => {
    navigate(`${ADMIN_MODULE_BASE_PATH}/${CUSTOMER_MOVE_DETAIL}`);
  };

  useEffect(() => {
    fetchBranches();
    lastProcessPerformed();
  }, [fetchBranches, lastProcessPerformed]);

  useEffect(() => {
    if (activeOnlyFlag) {
      customerMoveForm.setFieldValue("isActiveOnly", Y_CHECKER);
      setLoadDataForParams(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOnlyFlag]);

  useEffect(() => {
    if (loadDataForParams) {
      fetchCustomerTableData();
      window.history.replaceState(
        null,
        "",
        `${ADMIN_MODULE_BASE_PATH}/${CUSTOMER_MOVE_SEARCH}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDataForParams]);

  return (
    <>
      {loading && <FullCircularLoader />}
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            sx={CustomerSearchStyles.errorStyle}
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {showErrorAlertMsg}
          </Alert>
        </Collapse>
      )}

      <Formik>
        <form>
          <Box
            display="flex"
            flexDirection="column"
            gap={spacing.verticalMargin20}
          >
            <Grid container spacing={spacing.verticalMargin20}>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SelectField
                  label={t("customerMoveSearch.sourceBranch")}
                  options={allBranches}
                  value={customerMoveForm.values.sourceBranch}
                  onChange={handleSourceBranch}
                  objectSelection={true}
                  clearIcon={<ClosedIcon />}
                  popupIcon={<ArrowDown />}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SelectField
                  options={filteredDestinationBranches}
                  value={customerMoveForm.values.destinationBranch}
                  onChange={handleSourceDestination}
                  label={t("customerMoveSearch.destinationBranch")}
                  clearIcon={<ClosedIcon />}
                  popupIcon={<ArrowDown />}
                  objectSelection={true}
                />
              </Grid>
            </Grid>

            <Grid container spacing={spacing.verticalMargin20}>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <CustomersField
                  labelValue={t("customerMoveSearch.sourceCustomer")}
                  options={sourceCustomer}
                  form={customerMoveForm}
                  value={customerMoveForm.values.sourceCustomer}
                  disabled={!customerMoveForm.values.sourceBranch}
                  handleOnChange={(_, newValue) => {
                    customerMoveForm.setFieldValue(
                      customerMoveSearch.sourceCustomer,
                      newValue?.label
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3} xl={3}>
                <CustomersField
                  labelValue={t("customerMoveSearch.destinationCustomer")}
                  options={destinationCustomer}
                  value={customerMoveForm.values.destinationCustomer}
                  handleOnChange={(_, newValue) => {
                    customerMoveForm.setFieldValue(
                      customerMoveSearch.destinationCustomer,
                      newValue?.label
                    );
                  }}
                  disabled={!customerMoveForm.values.destinationBranch}
                />
              </Grid>
            </Grid>

            <Grid container spacing={spacing.verticalMargin20}>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SelectField
                  label={t("customerMoveSearch.lastProcessPerformed")}
                  value={customerMoveForm.values.lastProcessPerformed}
                  onChange={(e) =>
                    customerMoveForm.setFieldValue(
                      customerMoveSearch.lastProcessPerformed,
                      e.target.value
                    )
                  }
                  options={lastProcess}
                  clearIcon={<ClosedIcon />}
                  popupIcon={<ArrowDown />}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                xl={3}
                sx={CustomerSearchStyles.formControlisActive}
              >
                <FormControlLabel
                  id="isActiveOnly"
                  control={
                    <Checkbox
                      id="isActiveOnly"
                      size="small"
                      color="primary"
                      checked={customerMoveForm?.values?.isActiveOnly}
                      onChange={(e) => {
                        const newValue = e.target.checked ? Y_CHECKER : "";
                        customerMoveForm.setFieldValue(
                          "isActiveOnly",
                          newValue
                        );
                      }}
                    />
                  }
                  label={t("customerMoveSearch.activeOnlyLabel")}
                />
              </Grid>
            </Grid>

            <Stack direction="row" spacing={spacing.verticalMargin20}>
              {/* Clear button */}
              <Button
                id="search-panel-clear-button"
                variant="outlined"
                type="reset"
                onClick={() => {
                  customerMoveForm.resetForm();
                  setSearchResults([]);
                  setIsResetGrid(true);
                }}
              >
                {t("common.clear")}
              </Button>

              {/* Find Button */}
              <Button
                id="find-button"
                variant="contained"
                onClick={() => {
                  fetchVerifyByEmployeeId();
                }}
              >
                {t("common.find")}
              </Button>
            </Stack>
          </Box>

          <Stack mt={spacing.verticalMargin32}>
            <CustomerMoveSearchTable
              isResetGrid={isResetGrid}
              searchResults={searchResults}
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              handleYesClick={handleConfirm}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              processButtonEnabled={processButtonEnabled}
              setProcessButtonEnabled={setProcessButtonEnabled}
              showDeactivateButton={showDeactivateButton}
              setShowDeactivateButton={setShowDeactivateButton}
              showActivateButton={showActivateButton}
              setShowActivateButton={setShowActivateButton}
              showProcessButton={showProcessButton}
              setShowProcessButton={setShowProcessButton}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
          </Stack>
          <Stack direction="row" mt={spacing.verticalMargin32}>
            <Button
              id="new-button"
              variant="outlined"
              type="new"
              onClick={() => handleNewClick()}
            >
              {t("common.new")}
            </Button>
          </Stack>
        </form>
      </Formik>
    </>
  );
};

export default CustomerSearchLayout;
