import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Paper,
  TableContainer
} from "@mui/material";
import { t } from "i18next";
import BatchDetail from "./BatchDetail";
import MissingMediaDetail from "./MissingMediaDetail";
import UnexpectedMediaDetail from "./UnexpectedMediaDetail";

const DetailTable = ({ viewData, handlePossibleMatch }) => {
  return (
    <>
      <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
        <Divider />
      </Grid>
      <Accordion defaultExpanded={viewData && viewData[4]?.length}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
        >
          {t("openMediaAuditDetail.unexpectedMediaDetail")}
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Paper>
              <TableContainer>
                <UnexpectedMediaDetail
                  tableData={viewData[4] ?? []}
                  handlePossibleMatch={handlePossibleMatch}
                />
              </TableContainer>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={viewData && viewData[5]?.length}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12-content"
        >
          {t("openMediaAuditDetail.missingMediaDetail")}
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Paper>
              <TableContainer>
                <MissingMediaDetail tableData={viewData[5] ?? []} />
              </TableContainer>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={viewData && viewData[6]?.length}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
        >
          {t("openMediaAuditDetail.batchDetail")}
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Paper>
              <TableContainer>
                <BatchDetail tableData={viewData[6] ?? []} />
              </TableContainer>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DetailTable;
