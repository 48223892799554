import i18n from "i18n";
import {
  assignTransportToRequest,
  cancelMediaRequest,
  checkCancelRequest,
  checkDiscrepancies,
  checkRequestAuthorization,
  findMediaRequest,
  getAddonCutoffValues,
  getAllCustomers,
  getAllDeletableContainers,
  getAllInsertTypes,
  getAllLockTypes,
  getAllLogicalVaults,
  getAllServiceLocations,
  getAllTripTypes,
  getAuthorizationPersonnels,
  getBranchBillingSystem,
  getEligibleRequestLocations,
  getOpenMediaPossibleMatches,
  getPricingContractFlag,
  getRequiredInsertsCount,
  getScheduledServiceDates,
  getServiceAuthComments,
  loadMediaRequestContainers,
  loadMediaRequestDeleteContainers,
  loadMediaRequestIssueContainers,
  loadMediaRequestOpenMedias,
  loadMediaRequestTransports,
  saveMediaRequest,
  updateMediaRequest,
  validateDeleteContainerNumber,
  validateDeliverMediaContainerNumber,
  validateDeliverMediaOpenMediaNumber
} from "services/common";
import {
  DUPLICATES_TXT,
  POSSIBLE_MATCHES_TXT,
  REQUEST_NEW_ITEM_TYPE,
  TAG_TYPES
} from "utils/constants";
import { formatDate } from "utils/helpers";
import { baseApi } from "../baseApi";

/**
 * @module requestDetailApi request media detail RTK Query endpoints
 */
export const requestDetailApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    checkRequestAuthorization: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await checkRequestAuthorization(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getAllCustomersWithDetails: builder.query({
      queryFn: async () => {
        try {
          const response = await getAllCustomers();
          return {
            data: response?.map(
              ({
                number,
                name,
                customer_id,
                authorization_comment,
                service_comment,
                district_id
              }) => ({
                label: `${number} - ${name}`,
                value: customer_id,
                number: number,
                authorizationComment: authorization_comment,
                serviceComment: service_comment,
                districtId: district_id
              })
            )
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customerWithDetails]
    }),
    getScheduledServiceDates: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getScheduledServiceDates(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getServiceAuthComments: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getServiceAuthComments(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getAddonCutoffValues: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAddonCutoffValues(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getBranchBillingSystem: builder.query({
      queryFn: async () => {
        try {
          const response = await getBranchBillingSystem();
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getEligibleRequestLocations: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getEligibleRequestLocations(props);
          return {
            data: response?.map(({ customer_number, customer_id }) => ({
              label: customer_number,
              value: customer_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getPricingContractFlag: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getPricingContractFlag(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getTripTypes: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAllTripTypes(props);
          return {
            data: response?.map(({ descr, trip_type_id }) => ({
              label: descr,
              value: trip_type_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getServiceLocations: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAllServiceLocations(props);
          return {
            data: response?.map(({ number, customer_id }) => ({
              label: number?.trim(),
              value: customer_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getTransportNumbers: builder.query({
      queryFn: async (props) => {
        try {
          const response = [];
          return {
            data: response?.map(({ number }) => number)
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.transportNumber, id: arg.customerId }]
          : [TAG_TYPES.transportNumber]
    }),
    getLogicalVaults: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAllLogicalVaults(props);
          return {
            data: response?.map(
              ({ code, logical_vault_id, media_program_type_id }) => ({
                label: code,
                value: logical_vault_id,
                mediaProgramTypeId: media_program_type_id
              })
            )
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getInsertTypes: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllInsertTypes({returnNoneValue: true});
          return {
            data: response
              ?.map(({ descr, insert_type_id }) => ({
                label: descr,
                value: insert_type_id
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.insertType]
    }),
    getLockTypes: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getAllLockTypes(props);
          return {
            data: response
              ?.map(({ lock_type_code }) => ({
                label: lock_type_code,
                value: lock_type_code
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG_TYPES.lockType, id: arg.customerId }]
          : [TAG_TYPES.lockType]
    }),
    getAuthorizationPersonnels: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAuthorizationPersonnels(props);
          return {
            data: response?.map(({ name, personnel_id }) => ({
              label: name,
              value: personnel_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    validateDeliverMediaOpenMediaNumber: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await validateDeliverMediaOpenMediaNumber(props);
          let data = null;

          if (response) {
            const error = response.errors[0]?.error || 0;
            switch (Number(error)) {
              case 0:
                data = {
                  medias: response.medias.map((media) => {
                    let followUp = "";
                    if (media.follow_up !== "" && media.follow_up_date !== "") {
                      followUp = `${media.follow_up} ${formatDate(
                        media.follow_up_date
                      ).substring(0, 10)}`;
                    } else if (
                      media.follow_up === POSSIBLE_MATCHES_TXT ||
                      media.follow_up === DUPLICATES_TXT ||
                      media.follow_up !== ""
                    ) {
                      followUp = media.follow_up;
                    }

                    return {
                      ...media,
                      follow_up: followUp
                    };
                  })
                };
                break;
              case 64034:
                data = {
                  volsersInMdr: response.volsersInMdr[0].volsers_in_mdr
                };
                break;
              default:
                break;
            }

            return {
              data: data
            };
          } else {
            return {
              data: {
                error: i18n.t(
                  "mediaRequestDetail.unableToProcessBcauseMediaStatusNotFound"
                )
              }
            };
          }
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getOpenMediaPossibleMatches: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getOpenMediaPossibleMatches(props);
          return {
            data: response.map(
              ({ volser, media_exchange_status, ...rest }) => ({
                ...rest,
                volser: volser,
                status: media_exchange_status
              })
            )
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    validateDeliverMediaContainerNumber: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await validateDeliverMediaContainerNumber(props);
          let data = null;

          const { msg_code } = response.message[0];
          if (Number(msg_code) !== 0) {
            if (Number(msg_code) === 80103) {
              data = {
                error: i18n.t("mediaRequestDetail.statusOfContainerIsNotFound")
              };
            } else if (Number(msg_code) === 80116) {
              data = {
                error: i18n.t("mediaRequestDetail.mediaAssignedHighPriority")
              };
            } else {
              if (Number(msg_code) !== 20355) {
                data = {
                  error: i18n.t(
                    "mediaRequestDetail.statusOfContainerIsNotFound"
                  )
                };
              } else {
                // get the container data
                const container = response.container[0];

                data = {
                  error: `${i18n.t("mediaRequestDetail.statusOfContainerIs")} ${
                    container?.exchange_status_type
                  }`
                };
              }
            }
          } else {
            // get the container data
            const container = response.container[0];
            data = {
              id: container?.container_id,
              container_id: container?.container_id,
              container_number: container?.number,
              status: container?.exchange_status_type,
              run_id: container?.run_id
            };
          }

          return {
            data: data
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getRequiredInsertsCount: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getRequiredInsertsCount(props);
          return {
            data: response[0]?.inserts_required || 0
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    validateDeleteContainerNumber: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await validateDeleteContainerNumber(props);
          let data = null;

          const { err_msg, container_status_text } = response.message[0];
          if (Number(err_msg) !== 0) {
            if (Number(err_msg) !== 20355) {
              data = {
                error: i18n.t(
                  "mediaRequestDetail.unableToProcessBcauseContainerStatusIsNotFound"
                )
              };
            } else {
              data = {
                error: `${i18n.t(
                  "mediaRequestDetail.unableToProcessBcauseContainerStatusIs"
                )} ${container_status_text}}`
              };
            }
          } else {
            data = response.container[0];
          }

          return {
            data: data
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    getAllDeletableContainers: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAllDeletableContainers(props);
          return {
            data: response.sort((a, b) => {
              // parse numbers to number
              const numA = parseInt(a.number, 10);
              const numB = parseInt(b.number, 10);

              // check whether values are numbers or not
              if (isNaN(numA) && isNaN(numB)) {
                // sort numerically
                return numA - numB;
              } else {
                // sort alphabetically
                return a.number.toString().localeCompare(b.number);
              }
            })
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    saveMediaRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await saveMediaRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    updateMediaRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await updateMediaRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    findMediaRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await findMediaRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    loadMediaRequestOpenMedias: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await loadMediaRequestOpenMedias(props);
          return {
            data: response.map(({ volser, ...rest }) => ({
              ...rest,
              volser,
              id: volser
            }))
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    loadMediaRequestContainers: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await loadMediaRequestContainers(props);
          return {
            data: response.map((cont) => ({
              id: cont.container_id,
              container_number: cont.number,
              status: cont.exchange_status
            }))
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    loadMediaRequestTransports: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await loadMediaRequestTransports(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    loadMediaRequestIssueContainers: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await loadMediaRequestIssueContainers(props);
          let responseData = {
            extraItems: {
              lock_quantity: 0,
              lock_type_code: "",
              clip_quantity: 0
            },
            containers: [],
            extraInserts: []
          };

          let inserts = response.inserts.map(
            ({ request_new_item_id, insert_type_descr, quantity }) => ({
              id: request_new_item_id,
              insert_type: insert_type_descr,
              quantity: quantity
            })
          );

          response.containers.forEach((cont) => {
            // if the row is a type of extra lock add it to the extra lock
            if (
              Number(cont.request_new_item_type_id) ===
              REQUEST_NEW_ITEM_TYPE.LOCKS
            ) {
              responseData.extraItems = {
                ...responseData.extraItems,
                lock_type_code: cont.lock_type_code,
                lock_quantity: Number(cont.quantity)
              };
            }

            // if the row is a type of extra clip add it to the extra clip
            if (
              Number(cont.request_new_item_type_id) ===
              REQUEST_NEW_ITEM_TYPE.CLIPS
            ) {
              responseData.extraItems = {
                ...responseData.extraItems,
                clip_quantity: Number(cont.quantity)
              };
            }

            // if the row is a type of extra insert, set the bill_for_inserts_flag to the appropriate extra insert
            if (
              Number(cont.request_new_item_type_id) ===
              REQUEST_NEW_ITEM_TYPE.INSERT
            ) {
              inserts = inserts.map((insert) => {
                if (Number(insert.id) === Number(cont.request_new_item_id)) {
                  return {
                    ...insert,
                    bill_for_inserts_flag: cont.bill_for_inserts_flag
                  };
                } else {
                  return insert;
                }
              });
            }

            // if the row is a container/transport add it to the container array
            if (
              Number(cont.request_new_item_type_id) ===
                REQUEST_NEW_ITEM_TYPE.CONTAINER ||
              Number(cont.request_new_item_type_id) ===
                REQUEST_NEW_ITEM_TYPE.TRANSPORT_EMPTY ||
              Number(cont.request_new_item_type_id) ===
                REQUEST_NEW_ITEM_TYPE.TRANSPORT_PERMANENT
            ) {
              let insert_configuration = "";
              // remove container/transport insert configurations from the inserts
              inserts = inserts.filter((insert) => {
                if (Number(insert.id) === Number(cont.request_new_item_id)) {
                  insert_configuration += `${insert.quantity} - ${insert.insert_type} `;
                  return false;
                } else {
                  return true;
                }
              });

              responseData.containers.push({ ...cont, insert_configuration });
            }
          });

          // add the extra inserts to the response object
          responseData.extraInserts = inserts;

          return {
            data: responseData
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    loadMediaRequestDeleteContainers: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await loadMediaRequestDeleteContainers(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    assignTransportToRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await assignTransportToRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    checkCancelRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await checkCancelRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    cancelMediaRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await cancelMediaRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    }),
    checkDiscrepancies: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await checkDiscrepancies(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      }
    })
  })
});
