import { Box } from "@mui/material";
import { PickersDay, pickersDayClasses } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { dateFormatScheduledServices } from "utils/constants/container-processing/Research";

//handle date format change
export const dateFormat = (date) => {
  return dayjs(date).format(dateFormatScheduledServices);
};

// Higlighting the Scheduled Service dates
export const HighlightScheduledDates = ({
  day,
  outsideCurrentMonth,
  selectedDate,
  hightlightedDates,
  ...params
}) => {
  const isSelected =
    !outsideCurrentMonth && hightlightedDates.includes(dateFormat(day));
  const bgColorScheduledSelectDate =
    dateFormat(day) === dateFormat(selectedDate) &&
    hightlightedDates.includes(dateFormat(day));
  const bgColorNonScheduledSelectDate =
    dateFormat(day) === dateFormat(selectedDate) && !isSelected;

  return (
    <Box
      sx={{
        backgroundColor: bgColorScheduledSelectDate && "var(--color-im-orange)",
        borderRadius: "12px"
      }}
    >
      <PickersDay
        {...params}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: "var(--color-im-green)"
          },
          [`&&.${pickersDayClasses.dayWithMargin}`]: {
            backgroundColor: bgColorNonScheduledSelectDate
              ? "var(--color-im-orange)"
              : "normal",
            border: "none"
          }
        }}
      />
    </Box>
  );
};

HighlightScheduledDates.propTypes = {
  day: PropTypes.string, // date passed from the date-picker
  outsideCurrentMonth: PropTypes.bool, // passed from the date-picker
  selectedDate: PropTypes.string, // selected date
  hightlightedDates: PropTypes.array // dates need to highlighted
};
