import { ThemeProvider } from "@mui/material";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as LocalizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { RouterConfig } from "routing";
import { muiTheme } from "styles/muiTheme";
import "./styles/global.css";

// dayjs extensions
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <RouterConfig />
    </ThemeProvider>
  );
}

export default App;
