import { GoogleAuthProvider, getAuth } from "@firebase/auth";
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  appId: process.env.REACT_APP_FB_APP_ID
};

const dbId = process.env.REACT_APP_FB_DB_ID;
const storageBucket = process.env.REACT_APP_FB_STORAGE_BUCKET;
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp, dbId);

const isQA2Sandbox =
  process.env.REACT_APP_CF_ENV === "qa2" &&
  process.env.REACT_APP_ENV === "sandbox";
const isNonProd = process.env.REACT_APP_ENV === "nonprod";
const isImnaRegion = process.env.REACT_APP_CF_REGION === "imna";

if (isImnaRegion && (isQA2Sandbox || isNonProd)) {
  getPerformance(firebaseApp);
}

export const storage = getStorage(firebaseApp, storageBucket);
export const authentication = getAuth(firebaseApp);

export const googleAuthProvider = new GoogleAuthProvider(firebaseApp);

export const branches_collection = collection(db, "branches");
// messages
export const message_collection = collection(db, "messages");
// massage
export const message_by_code_collection = collection(db, "message");

export const templates_collection = collection(db, "templates");

export const destruct_request_type_collection = collection(
  db,
  "destruct_request_type"
);
export const destruct_request_status_collection = collection(
  db,
  "destruct_request_status"
);
export const request_type_collection = collection(db, "request_type");

export const request_status_collection = collection(db, "request_status");
export const personnel_cust_pers_collection = collection(
  db,
  "personnel_customer"
);
export const personnel_details_collection = collection(db, "personnel");

export const unit_of_measure_collection = collection(db, "unit_of_measure");

export const customers_collection = collection(db, "customer");

export const reasons_collection = collection(db, "dropdown_list");

export const service_location_collection = collection(db, "service_location");

export const service_frequency_collection = collection(db, "service_frequency");

export const day_of_week_collection = collection(db, "day_of_week");

export const week_of_month_collection = collection(db, "week_of_month");

/**
 * @description Collection reference to "resultSet_0" of the api_results collection's specific docuemnt id that passed into the params
 *
 * @param {string} documentId firestore document id
 * @returns
 */
export const api_results_collection_resultset0 = (documentId) =>
  collection(db, "api_results", documentId, "resultSet_0");

/**
 * @description Collection reference to "resultSet_1" of the api_results collection's specific docuemnt id that passed into the params
 *
 * @param {string} documentId firestore document id
 * @returns
 */
export const api_results_collection_resultset1 = (documentId) =>
  collection(db, "api_results", documentId, "resultSet_1");

export const api_results_collection_resultset2 = (documentId) =>
  collection(db, "api_results", documentId, "resultSet_2");

export const api_results_collection_resultset3 = (documentId) =>
  collection(db, "api_results", documentId, "resultSet_3");

export const holidays_collection = collection(db, "branch_holidays");

export const holidays_years_collection = collection(db, "branch_holiday_years");

export const employee_collection = collection(db, "employee");

export const program_security_collection = collection(db, "program_security");

export const operational_mode_collection = collection(db, "operational_mode");

export const destruct_Request_Handling_Type_collection = collection(
  db,
  "destruct_request_handling_type"
);
export const destruct_request_box_type_collection = collection(
  db,
  "destruct_request_box_type"
);
export const personnel_customer_authorization_collection = collection(
  db,
  "personnel_customer_authorization"
);

export const authorization_code_collection = collection(
  db,
  "authorization_code"
);

export const media_type_collection = collection(db, "media_type");

export const global_attribute_collection = collection(db, "global_attribute");

export const appsettings_collection = collection(db, "appSettings");

export const module_settings_collection = collection(db, "module_settings");

/************* Billing Module *********************/

export const billing_classification_collection = collection(
  db,
  "billing_classification"
);

export const billing_cycle_collection = collection(db, "billing_cycle");

export const commission_type_collection = collection(db, "commission_type");

export const capacity_billing_suffix_collection = collection(
  db,
  "capacity_billing_suffix"
);

export const billing_error_action_collection = collection(
  db,
  "billing_error_action"
);

/************* Security Module *********************/

export const security_groups = collection(db, "security_group");
export const program_security = collection(db, "program_security");

/************ Transportation Vendor Module ****************/

export const vendor_transport = collection(db, "vendor_transport");
export const state_province = collection(db, "state_province");

/************* Employee Module *********************/

export const employee = collection(db, "employee");
export const branches = collection(db, "branches");
export const job_title = collection(db, "job_title");
export const operational_center = collection(db, "operational_center");
export const dropdown_list = collection(db, "dropdown_list");
export const country = collection(db, "country");
export const locale_collection = collection(db, "locale");

/************* Transport Module *********************/

export const global_attribute = collection(db, "global_attribute");

export const country_attribute_format_collection = collection(
  db,
  "country_attribute_format"
);

/************* Work Management *********************/

export const setting_type = collection(db, "setting_type");

/************* Open Media Audit Detail Module *********************/

export const audit_sample_method = collection(db, "audit_sample_method");
export const audit_type_collection = collection(db, "audit_type");
export const audit_status_collection = collection(db, "audit_status");

/************* Container Processing Module *********************/
export const exchange_status_collection = collection(db, "exchange_status");
export const service_result_collection = collection(db, "service_result");
export const service_result_source_collection = collection(
  db,
  "service_result_source"
);

/************* Customer Service *********************/
export const lock_types_collection = collection(db, "lock_type");
export const service_result_source_complete_collection = collection(
  db,
  "service_result_source_complete"
);
export const service_indicator_type = collection(db, "service_indicator_type");

export const file_section_status = collection(db, "file_section_status");
export const suggestion_type = collection(db, "suggestion_type");
export const alertType = collection(db, "alert_type");
export const email_group_address = collection(db, "email_group_address");
export const media_program_type = collection(db, "media_program_type");
export const root_cause_type = collection(db, "root_cause");
export const lv_media_type = collection(db, "lv_media_type");
