import {
  Alert,
  Button,
  Collapse,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/warning-2.svg";
import { t } from "i18next";

import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { NonEditableField } from "components/shared";
import {
  useGetAuthorizationDetailMutation,
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { selectAuth } from "store/slices";
import { generateSHA256Hash, getLanguageId } from "utils/helpers";
import { RescheduleServiceFormStyles } from "../reschedule-service-form/RescheduleServiceFormStyles";
import { PersonnelAuthenticationFormStyles } from "./PersonnelAuthenticationFormStyles.style";

const AuthInfoSection = ({
  handleChange,
  errors,
  customer,
  rescheduleFormformikVal,
  personnelId,
  onAuthSubmit
}) => {
  const { currentBranch } = useSelector(selectAuth);
  const { data: personnelAuth = [], refetch: refetchPersonnelAuth } =
    useGetPersonnelAuthQuery({
      personnelId: personnelId
    });
  const [triggerAuthDetail] = useGetAuthorizationDetailMutation({});
  const selectedBranch = currentBranch?.district_id;
  const languageLocaleId = getLanguageId();
  const authFormikRef = useFormikContext();
  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  const fetchAuthInfo = () => {
    authFormikRef.setFieldValue("authorizedBy", personnelId);
    authFormikRef.setFieldValue("authNumber", "");
    authFormikRef.setFieldValue("challengeResponse", "");
    authFormikRef.setFieldValue("authSuccess", false);

    if (personnelAuth.length > 0) {
      //ChallengeQuestion
      authFormikRef.setFieldValue(
        "challengeQuestion",
        personnelAuth[0]?.secret_question
      );
      authFormikRef.setFieldValue(
        "resultChallengeAns",
        personnelAuth[0]?.secret_answer
      );

      //Auth Number
      authFormikRef.setFieldValue("resultAuth", personnelAuth[0]?.auth_number);
      authFormikRef.setFieldValue("openAuth", true);
    } else {
      authFormikRef.setFieldValue("openAuth", false);
    }

    runAuthDetail();
  };

  const runAuthDetail = () => {
    triggerAuthDetail({
      personnelId: personnelId,
      customerId: customer?.value?.toString(),
      requestTypeId: rescheduleFormformikVal?.requestDetail?.request_id,
      requestActionId: rescheduleFormformikVal?.requestDetail?.request_action_id
    }).catch((error) => {
      console.error(t("requestSearch.unexpectedError"), error);
    });
  };
  const authenticateClicked = async () => {
    //generate hash code
    let validateChallengeResponse;
    if (authFormikRef?.values?.challengeResponse?.length > 0) {
      let hashCode = await generateSHA256Hash(
        authFormikRef?.values?.challengeResponse.trim().toUpperCase()
      );
      //challenge response validation
      validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: selectedBranch,
        language_locale_id: languageLocaleId,
        personnel_id: personnelId,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          return data[0].is_match_flag;
        })
        .catch((e) => {
          console.error(t("requestSearch.unexpectedError"), e);
        });
    }
    if (
      (authFormikRef?.values?.authNumber?.length > 0 &&
        authFormikRef?.values?.authNumber ===
          authFormikRef?.values?.resultAuth) ||
      (authFormikRef?.values?.challengeResponse?.length > 0 &&
        validateChallengeResponse === "Y")
    ) {
      authFormikRef.setFieldValue("authSuccess", true);
      authFormikRef.setFieldValue("authFailedAlertOpen", false);
      onAuthSubmit(authFormikRef?.values?.authorizedBy);
    } else {
      authFormikRef.setFieldValue("authFailedAlertOpen", true);
      authFormikRef.setFieldValue("authSuccess", false);
    }
  };

  useEffect(() => {
    personnelAuth.length > 0 && fetchAuthInfo();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelAuth]);

  useEffect(() => {
    if (
      customer == null ||
      customer === undefined ||
      personnelId == null ||
      personnelId === undefined
    ) {
      authFormikRef.setFieldValue("openAuth", false);
    }
    refetchPersonnelAuth();
    fetchAuthInfo();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelId, customer, refetchPersonnelAuth]);

  return (
    <>
      {authFormikRef?.values?.openAuth &&
        !authFormikRef?.values?.authSuccess && (
          <>
            <Grid item xs={12}>
              <Typography variant="body2">
                {t("rescheduleService.authInfo")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="authNumber"
                label={t("rescheduleService.authNumber")}
                size="medium"
                variant="outlined"
                fullWidth
                value={authFormikRef?.values?.authNumber}
                onChange={handleChange}
                error={Boolean(errors?.authNumber)}
                helperText={errors?.authNumber}
                disabled={
                  customer === null ||
                  rescheduleFormformikVal?.customerNoRqst ||
                  Boolean(authFormikRef?.values?.challengeResponse)
                }
              />
            </Grid>
            {authFormikRef?.values?.challengeQuestion && (
              <>
                <Grid item xs={3} sx={RescheduleServiceFormStyles.MarginLeft20}>
                  <NonEditableField
                    id="challengeQuestion"
                    label={t("rescheduleService.challengeQuestion")}
                    value={authFormikRef?.values?.challengeQuestion}
                    error={Boolean(errors?.challengeQuestion)}
                    helperText={errors?.challengeQuestion}
                    disabled={
                      customer === null ||
                      rescheduleFormformikVal?.customerNoRqst
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3} sx={RescheduleServiceFormStyles.MarginLeft20}>
                  <TextField
                    id="challengeResponse"
                    label={t("rescheduleService.challengeResponse")}
                    size="medium"
                    variant="outlined"
                    value={authFormikRef?.values?.challengeResponse}
                    error={Boolean(errors?.challengeResponse)}
                    helperText={errors?.challengeResponse}
                    fullWidth
                    disabled={
                      customer === null ||
                      rescheduleFormformikVal?.customerNoRqst ||
                      Boolean(authFormikRef?.values?.authNumber)
                    }
                    type="password"
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                alignItems="center"
                spacing={2}
              >
                {/* Authenticate Button */}
                <Grid item>
                  <Button
                    id="authenticate"
                    variant="contained"
                    color="primary"
                    size="medium"
                    sx={PersonnelAuthenticationFormStyles.Button}
                    onClick={authenticateClicked}
                    disabled={
                      customer === null ||
                      rescheduleFormformikVal?.customerNoRqst
                    }
                  >
                    {t("rescheduleService.authenticate")}
                  </Button>
                </Grid>

                {/* Alert */}
                <Grid item>
                  <Collapse in={authFormikRef?.values?.authFailedAlertOpen}>
                    <Alert
                      severity="error"
                      icon={<img src={WarningIcon} alt="Warning" />}
                    >
                      {t("rescheduleService.authAlert")}
                    </Alert>
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
    </>
  );
};

export default AuthInfoSection;
