import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { PersonnelsField } from "components/core/personnel-field/PersonnelsField";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllCustomersQuery,
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import { ERROR_TYPES, Y_CHECKER, errorMsgs } from "utils/constants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getLanguageId
} from "utils/helpers";
import { personalValidationStyles } from "./PersonelValidationStyles";

const PersonnelValidation = () => {
  const { currentBranch } = useSelector(selectAuth);
  const languageLocaleId = getLanguageId();
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);

  // getting the Data from useGetAllCustomersQuery
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });

  let initialValues = {
    customer: "",
    personnel: "",
    authNumber: "",
    challengeQuestion: "",
    challengeResponse: "",
    isAuthenticated: false,
    isAuthError: false,
    authenticateMsg: "",
    isLoading: false
  };

  const personalValidationForm = useFormik({
    initialValues: initialValues
  });

  //   Handle Personnel
  const handlePersonnelChange = (selectedValue) => {
    personalValidationForm.handleChange({
      target: { name: "personnel", value: selectedValue }
    });
    personalValidationForm.handleChange({
      target: { name: "isAuthError", value: false }
    });
    personalValidationForm.handleChange({
      target: { name: "errorMsg", value: "" }
    });
    personalValidationForm.handleChange({
      target: { name: "isAuthenticated", value: false }
    });
    personalValidationForm.handleChange({
      target: { name: "authenticateMsg", value: "" }
    });
    personalValidationForm.handleChange({
      target: { name: "challengeResponse", value: "" }
    });
    personalValidationForm.handleChange({
      target: { name: "authNumber", value: "" }
    });
  };

  // Handle Customer
  const handleCustomerChange = (event, newValue) => {
    personalValidationForm.handleChange({
      target: { name: "customer", value: newValue }
    });

    personalValidationForm.handleChange({
      target: { name: "personnel", value: "" }
    });

    personalValidationForm.handleChange({
      target: { name: "isAuthError", value: false }
    });
    personalValidationForm.handleChange({
      target: { name: "errorMsg", value: "" }
    });
    personalValidationForm.handleChange({
      target: { name: "isAuthenticated", value: false }
    });
    personalValidationForm.handleChange({
      target: { name: "authenticateMsg", value: "" }
    });
    personalValidationForm.handleChange({
      target: { name: "challengeResponse", value: "" }
    });
    personalValidationForm.handleChange({
      target: { name: "authNumber", value: "" }
    });
  };

  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: personalValidationForm.values?.personnel?.value
  });

  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  // authentication
  const onAuthenticateClick = async () => {
    if (personalValidationForm?.values?.isAuthError) {
      personalValidationForm.handleChange({
        target: { name: "isAuthError", value: false }
      });
    }
    if (personalValidationForm?.values?.isAuthenticated) {
      personalValidationForm.handleChange({
        target: { name: "isAuthenticated", value: false }
      });
    }
    personalValidationForm.handleChange({
      target: { name: "isLoading", value: true }
    });

    if (
      !personalValidationForm.values?.authNumber?.length &&
      !personalValidationForm.values?.challengeResponse?.length
    ) {
      const msg = await personnelValidationError();
      personalValidationForm.handleChange({
        target: { name: "isAuthError", value: true }
      });
      personalValidationForm.handleChange({
        target: { name: "errorMsg", value: msg?.data }
      });
      personalValidationForm.handleChange({
        target: { name: "isLoading", value: false }
      });
      return;
    }
    // authenticate with authNumber
    if (personalValidationForm.values?.authNumber?.length) {
      if (
        personalValidationForm.values?.authNumber ===
        personnelAuth[0]?.auth_number
      ) {
        // Custom hook to fetch Validation detail
        const msg = await personnelValidation();
        personalValidationForm.handleChange({
          target: { name: "isAuthenticated", value: true }
        });
        personalValidationForm.handleChange({
          target: {
            name: "authenticateMsg",
            value: msg?.data ? msg?.data : ""
          }
        });
        personalValidationForm.handleChange({
          target: { name: "isLoading", value: false }
        });
        return;
      } else {
        const msg = await personnelValidationError();
        personalValidationForm.handleChange({
          target: { name: "isAuthError", value: true }
        });
        personalValidationForm.handleChange({
          target: { name: "errorMsg", value: msg?.data }
        });
        personalValidationForm.handleChange({
          target: { name: "isLoading", value: false }
        });
        return;
      }
    }

    if (personalValidationForm.values?.challengeResponse?.length) {
      let hashCode = await generateSHA256Hash(
        personalValidationForm.values?.challengeResponse.trim().toUpperCase()
      );

      //challenge response validation
      let validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: String(currentBranch?.district_id),
        language_locale_id: languageLocaleId,
        personnel_id: personalValidationForm.values?.personnel?.value,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          return data[0]?.is_match_flag;
        })
        .catch((e) => {
          console.error(e);
          setThrowErrorMessage(e.error);
          setThrowError(true);
          return;
        });
      if (validateChallengeResponse === Y_CHECKER) {
        const msg = await personnelValidation();
        personalValidationForm.handleChange({
          target: { name: "isAuthenticated", value: true }
        });
        personalValidationForm.handleChange({
          target: { name: "authenticateMsg", value: msg?.data ? msg?.data : "" }
        });
        personalValidationForm.handleChange({
          target: { name: "isLoading", value: false }
        });
        return;
      } else {
        const msg = await personnelValidationError();
        personalValidationForm.handleChange({
          target: { name: "isAuthError", value: true }
        });
        personalValidationForm.handleChange({
          target: { name: "errorMsg", value: msg?.data }
        });
        personalValidationForm.handleChange({
          target: { name: "isLoading", value: false }
        });
        return;
      }
    }
  };

  // personal validation
  const personnelValidation = async () => {
    try {
      var requestBody = JSON.stringify({
        main_district_id: String(currentBranch?.district_id),
        customer_id: personalValidationForm?.values?.customer?.value,
        personnel_id: personalValidationForm?.values?.personnel?.value
      });

      const response = await getResponseData(
        requestBody,
        `${CF_URLS?.autorization?.authorizationCodes}`,
        1
      );

      if (response.error) {
        setThrowErrorMessage(response.error);
        setThrowError(true);
        return;
      }

      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode64049
      );
      const authCodeOne = response?.data?.[0];
      let modifiedStr = errMsg?.errorMsg?.replace("|", "");
      let finalStr =
        modifiedStr +
        " - " +
        (authCodeOne[0]?.authorization_code
          ? authCodeOne[0].authorization_code
          : "") +
        (authCodeOne[1]?.authorization_code
          ? " - " + authCodeOne[1].authorization_code
          : "");
      return { data: finalStr };
    } catch (error) {
      console.error(error);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // get the error msg from firestore
  const personnelValidationError = async () => {
    try {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20480
      );
      return { data: errMsg?.errorMsg };
    } catch (error) {
      console.error(error);
      setThrowErrorMessage(error);
      setThrowError(true);
      return;
    }
  };

  // call to cloud function and get data from firestore
  const getResponseData = async (requestBody, url, count) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(
      response,
      count,
      response.data.docId
    );
    return data;
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  // get challange question
  useEffect(() => {
    if (personnelAuth?.length) {
      personalValidationForm.handleChange({
        target: {
          name: "challengeQuestion",
          value: personnelAuth[0]?.challenge_question
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelAuth]);

  return (
    <>
      {/* Page Loader */}
      {personalValidationForm.values.isLoading && <FullCircularLoader />}

      {/* Start Form Section */}
      <form>
        <Stack
          direction={personalValidationStyles?.direction?.column}
          gap={personalValidationStyles?.gap?.gap}
        >
          {/* Success message  */}
          <Stack
            direction={personalValidationStyles?.direction?.row}
            gap={personalValidationStyles?.gap?.gap}
          >
            {personalValidationForm.values.isAuthenticated && (
              <Stack
                direction={personalValidationStyles?.direction?.row}
                gap={personalValidationStyles?.gap?.gap}
              >
                <Alert
                  severity="success"
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        personalValidationForm.handleChange({
                          target: { name: "isAuthenticated", value: false }
                        });
                        personalValidationForm.handleChange({
                          target: { name: "authenticateMsg", value: "" }
                        });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {personalValidationForm.values.authenticateMsg}
                </Alert>
              </Stack>
            )}

            {throwError && (
              <Stack
                direction={personalValidationStyles?.direction?.row}
                gap={personalValidationStyles?.gap?.gap}
              >
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                        setThrowErrorMessage("");
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Stack>
            )}
          </Stack>

          <Stack
            direction={personalValidationStyles?.direction?.row}
            gap={personalValidationStyles?.gap?.gap}
          >
            <Grid sx={personalValidationStyles?.fieldSize}>
              <CustomersField
                options={customers}
                value={personalValidationForm?.values?.customer}
                handleOnChange={handleCustomerChange}
                form={personalValidationForm}
                label={t("personnelValidation.customer")}
                required
              />
            </Grid>

            <Grid sx={personalValidationStyles?.fieldSize}>
              <PersonnelsField
                handlePersonnelOnChange={handlePersonnelChange}
                customerId={personalValidationForm?.values?.customer?.value}
                selectedPersonnel={personalValidationForm?.values?.personnel}
                form={personalValidationForm?.values?.customer ? false : true}
                label={t("common.authorizedByRequired")}
              />
            </Grid>
          </Stack>

          {personalValidationForm.values.personnel && (
            <>
              <Stack
                direction={personalValidationStyles?.direction?.row}
                gap={personalValidationStyles?.gap?.gap}
              >
                <Typography>
                  {t(
                    "personnelValidation.authNumberAndChallengeResponseMessage"
                  )}
                </Typography>
              </Stack>

              <Stack
                direction={personalValidationStyles?.direction?.row}
                gap={personalValidationStyles?.gap?.gap}
              >
                <Grid sx={personalValidationStyles?.fieldSize}>
                  <TextField
                    id="authNumber"
                    name="authNumber"
                    label={t("personnelValidation.authNumber")}
                    size="medium"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      maxLength: 6 // Maximum input length set to 6 characters
                    }}
                    disabled={personalValidationForm.values.challengeResponse}
                    onChange={personalValidationForm.handleChange}
                    value={personalValidationForm.values.authNumber}
                  />
                </Grid>

                {personalValidationForm.values.challengeQuestion && (
                  <>
                    <Grid sx={personalValidationStyles?.fieldSize}>
                      <TextField
                        id="challengeQuestion"
                        name="challengeQuestion"
                        label={t("personnelValidation.challengeQuestion")}
                        size="medium"
                        variant="standard"
                        sx={{
                          border: "none"
                        }}
                        value={personalValidationForm.values.challengeQuestion}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true
                        }}
                        InputLabelProps={{
                          shrink: true,
                          focused: false
                        }}
                        fullWidth
                        onChange={personalValidationForm.handleChange}
                      />
                    </Grid>

                    <Grid sx={personalValidationStyles?.fieldSize}>
                      <TextField
                        type="password"
                        id="challengeResponse"
                        name="challengeResponse"
                        label={t("personnelValidation.challengeResponse")}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          maxLength: 30 // Maximum input length set to 6 characters
                        }}
                        disabled={personalValidationForm.values.authNumber}
                        onChange={personalValidationForm.handleChange}
                        value={personalValidationForm.values.challengeResponse}
                      />
                    </Grid>
                  </>
                )}
              </Stack>
            </>
          )}

          <Stack
            direction={personalValidationStyles?.direction?.row}
            gap={personalValidationStyles?.gap?.gap}
          >
            <Grid>
              <Button
                variant="contained"
                onClick={() => onAuthenticateClick()}
                disabled={
                  !personalValidationForm.values?.customer ||
                  !personalValidationForm.values?.personnel
                }
              >
                {t("personnelValidation.authenticate")}
              </Button>
            </Grid>
            {personalValidationForm.values?.isAuthError && (
              <Grid>
                <Alert severity="error">
                  {personalValidationForm.values?.errorMsg}
                </Alert>
              </Grid>
            )}
          </Stack>
        </Stack>
      </form>
    </>
  );
};

export default PersonnelValidation;
