import spacing from "styles/spacing";

/**
 * Media Request Detail Styles
 */
export const MediaRequestDetailFormStyles = {
  PageHeader: {
    py: 1,
    margin: ".6rem 1.5rem 0 2rem",
    fontWeight: "normal",
    textTransform: "capitalize",
    fontSize: "34px"
  },
  PageContainer: {
    ".MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#0000000A !important"
    },
    "tr.MuiTableRow-root.MuiTableRow-head": {
      height: "36px !important"
    },
    ".MuiTableCell-root.MuiTableCell-body": {
      padding: "0px 5px !important"
    },
    ".MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium": {
      height: "32px !important"
    },
    ".MuiButtonBase-root.MuiCheckbox-root": {
      color: "var(--color-im-mid-blue, #1b75bc)"
    },
    ".MuiButtonBase-root.MuiCheckbox-root.Mui-disabled": {
      color: "var(--color-im-grey-100, #cbcccc) !important"
    },
    ".MuiButtonBase-root.MuiRadio-root": {
      color: "var(--color-im-mid-blue, #1b75bc)"
    }
  },
  FormWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: spacing.verticalMargin20
  },
  FlexShrinkNoGrow: { display: "flex", flexGrow: "0", flexShrink: "1" },
  AuthorizationHeaderTitle: {
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
    margin: 0,
    fontSize: "24px",
    fontWeight: "400",
    textTransform: "capitalize",
    lineHeight: "133.4%"
  },
  AuthenticatedText: {
    display: "flex",
    color: "var(--success-light, #4CAF50)",
    marginTop: 1
  },
  DividerBox: { height: "1px", backgroundColor: "#0000001F" },
  BypassedByText: {
    opacity: "60%",
    fontSize: "12px"
  },
  CommentsHeaderTitle: {
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "400",
    fontSize: "24px"
  },
  BlackOutlinedButton: { height: 40, m: 1, textTransform: "none" },
  TextTransformNone: { textTransform: "initial" },
  BottomButtonsContainer: {
    paddingTop: "30px",
    borderTop: 1,
    borderColor: "#0000001F"
  },
  ButtonCircularProgress: {
    color: "inherit",
    width: "20px !important",
    height: "20px !important"
  },
  TableIconButton: {
    width: "18px !important",
    height: "18px !important"
  }
};
