import { Tree } from "@minoru/react-dnd-treeview";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ArrowRight } from "assets/images/ArrowRight.svg";
import AsyncConfirm from "components/shared/confirm-popup/AsyncConfirmPopup";
import { useEffect, useState } from "react";
import { errorMsgs, N_CHECKER, Y_CHECKER } from "utils/constants";
import {
  nodeTypes,
  rootIds,
  slotNames,
  sourceTree
} from "utils/constants/customer-service-information/RouteManagementConstants";
import { TreeIcon } from "./TreeIcon";
import {
  getCurrentRouteInfo,
  getErrorMessage,
  getNode,
  getServiceTime,
  hasChildWithCustomerId,
  isOutsideTimeWindow
} from "./helper";

const RouteTreeView = ({
  routeTreeData,
  setRouteTreeData,
  getError,
  setIsLoading,
  isRouteExpand,
  setThrowError,
  selectedNodeIdMain,
  setSelectedNodeIdMain,
  selectedNodeIdSub,
  setSelectedNodeIdSub,
  setIsSaveEnabled,
  unsavedNodes,
  mainTree,
  setCurrentRouteInfoMain,
  setCurrentRouteInfoSub,
  expandIds,
  updateOpenNodes,
  deleteUnassignedTreeNode,
  setInsertApiData,
  setUpdateApiData
}) => {
  const [treeData, setTreeData] = useState(null);
  const [getConfirmation, Confirmation] = AsyncConfirm();
  const root = rootIds.currentRouteTasks;

  useEffect(() => {
    setTreeData(routeTreeData);
    //eslint-disable-next-line
  }, [routeTreeData]);

  const handleDrop = async (newTreeData, dropNodeDetails) => {
    setIsLoading(true);
    setThrowError(false);
    let dragSource = dropNodeDetails.dragSource;
    let dropTarget = dropNodeDetails.dropTarget;

    if (!dropTarget || dragSource?.type === nodeTypes.time) {
      setIsLoading(false);
      return;
    }

    if (
      dragSource?.data.route_code === dropTarget?.data.route_code &&
      dragSource?.data.service_time === getServiceTime(dropTarget?.text)
    ) {
      setIsLoading(false);
      return;
    }

    let sourceParent = getNode(treeData, dragSource.parent);
    if (dragSource?.source === sourceTree.currentRoute) {
      // If the drop target is not a time slot or On Call slot
      if (sourceParent?.type === nodeTypes.parentCustomer) {
        setIsLoading(false);
        return;
      }
    }

    // When Dropping an unassigned request
    if (dragSource?.source === sourceTree.unassigned) {
      // If the drop target is not a time slot or On Call slot
      if (dropTarget?.type !== nodeTypes.time) {
        setIsLoading(false);
        return;
      }
    }

    // Check if customer already exists in same slot
    if (
      hasChildWithCustomerId(
        treeData,
        dropTarget?.id,
        dragSource?.data?.customer_id
      )
    ) {
      getError(errorMsgs.errorCode20454);
      return;
    }

    // Check the drop time slot and make sure it follows the On Call Rules
    if (dropTarget?.text === slotNames.onCall) {
      if (dragSource?.data.on_call_flag === N_CHECKER) {
        getError(errorMsgs.errorCode20410);
        return;
      }
    }

    // Check the drop customer and make sure it follows the On Call Rules
    if (dragSource?.data.on_call_flag === Y_CHECKER) {
      if (dropTarget?.text !== slotNames.onCall) {
        getError(errorMsgs.errorCode20411);
        return;
      }
    }

    // Check the drop time is between the service window start and end time
    if (dragSource?.data.on_call_flag === N_CHECKER) {
      let timeWindowDetail = isOutsideTimeWindow(dragSource, dropTarget);
      let outsideTimeWindow = timeWindowDetail.outsideTimeWindow;
      let timeWindow = timeWindowDetail.timeWindow;
      if (outsideTimeWindow) {
        let message = await getErrorMessage(
          errorMsgs.errorCode20462,
          timeWindow
        );
        setIsLoading(false);
        let selection = await getConfirmation(message);
        if (selection === false) {
          return;
        }
      }
    }

    setIsLoading(false);
    setIsSaveEnabled(true);
    setRouteTreeData(newTreeData);
    if (dragSource.source === sourceTree.unassigned) {
      deleteUnassignedTreeNode && deleteUnassignedTreeNode(dragSource);
      setInsertApiData && setInsertApiData(dragSource, dropTarget);
    } else if (dragSource.source === sourceTree.currentRoute) {
      setUpdateApiData && setUpdateApiData(dragSource, dropTarget);
    }
  };

  const handleSelect = (node) => {
    setThrowError(false);
    mainTree ? setSelectedNodeIdMain(node.id) : setSelectedNodeIdSub(node.id);
    let routeInfo = getCurrentRouteInfo(node.data);
    mainTree
      ? setCurrentRouteInfoMain(routeInfo)
      : setCurrentRouteInfoSub(routeInfo);
  };

  return (
    <div className="tree-container">
      {treeData && (
        <Tree
          tree={treeData}
          rootId={root}
          onDrop={handleDrop}
          sort={(node1, node2) => {
            if (node1.type === nodeTypes.route) {
              return node1?.data?.route_code.localeCompare(
                node2?.data?.route_code
              );
            } else {
              return unsavedNodes?.includes(node1?.id)
                ? -1
                : unsavedNodes?.includes(node2?.id)
                  ? +1
                  : parseInt(node1?.data?.sequence_id) -
                    parseInt(node2?.data?.sequence_id);
            }
          }}
          initialOpen={expandIds.length > 0 ? expandIds : isRouteExpand}
          enableAnimateExpand={true}
          onChangeOpen={(items) => {
            updateOpenNodes(items);
          }}
          render={(node, { depth, isOpen, onToggle, hasChild }) => (
            <div
              id={`${node.id}`}
              style={{
                marginLeft: hasChild
                  ? `${depth * 20}px`
                  : `${depth * 20 + 20}px`
              }}
              className={`root-node ${
                (mainTree && node.id === selectedNodeIdMain) ||
                (!mainTree && node.id === selectedNodeIdSub) ||
                ((node.type === nodeTypes.customer ||
                  node.type === nodeTypes.parentCustomer) &&
                  node.id === expandIds[0])
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSelect(node)}
            >
              {hasChild && (
                <span onClick={onToggle} className="arrow-icon">
                  {isOpen ? <ArrowDown /> : <ArrowRight />}
                </span>
              )}
              <TreeIcon type={node.type} />
              <span
                className={`node-text ${
                  unsavedNodes?.includes(node?.id) ||
                  unsavedNodes?.includes(node?.parent)
                    ? "selected"
                    : ""
                }`}
              >
                {node.text}
              </span>
            </div>
          )}
        />
      )}

      {/* Confimation popup */}
      <Confirmation />
    </div>
  );
};
export default RouteTreeView;
