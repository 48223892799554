import { Stack } from "@mui/material";
import EventDetailLayout from "components/disaster-module/event-detail/EventDetailLayout";
import { PageTitle } from "components/shared";
import { useMemo, useState } from "react";
import i18n from "../../i18n";

const EventDetail = () => {
  const [routeData, setRouteData] = useState({
    eventId: "",
    customerNumber: ""
  });

  const pageTitle = useMemo(() => {
    return i18n
      .t("disasterRecovery.eventDetail.editPageTitle")
      .replace("|", routeData.eventId)
      .replace("|", routeData.customerNumber);
    //eslint-disable-next-line
  }, [routeData]); // constructed page title

  return (
    <Stack spacing={2}>
      {routeData.eventId ? (
        <PageTitle title={pageTitle} />
      ) : (
        <PageTitle title={i18n.t("disasterRecovery.eventDetail.pageTitle")} />
      )}
      <EventDetailLayout setRouteData={setRouteData} />
    </Stack>
  );
};

export default EventDetail;
