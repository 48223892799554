import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  TableContainer,
  Typography
} from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { DetailsStyles } from "./Styles";
const ExceptionDetails = ({ data, tableList, handleBack }) => {
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch:
          (!tableList || tableList.length === 0) && `${t("common.noDataFound")}`
      }
    }
  };
  const columns = [
    {
      name: "volser",
      label: t("reports.vaultStatusReport.media#"),
      options: {
        sort: true
      }
    },
    {
      name: "detected_datetime",
      label: t("reports.vaultStatusReport.detectedDatetime")
    },
    {
      name: "task_datetime",
      label: t("reports.vaultStatusReport.task")
    },

    {
      name: "system_function",
      label: t("reports.vaultStatusReport.systemFunction")
    },

    {
      name: "logical_vault_code",
      label: t("reports.vaultStatusReport.logicalVault")
    },
    {
      name: "request_type",
      label: t("reports.vaultStatusReport.requestType")
    },
    {
      name: "service_date",
      label: t("reports.vaultStatusReport.serviceDate")
    },
    {
      name: "additional_information",
      label: t("reports.vaultStatusReport.additionalInformation")
    },
    {
      name: "car_id",
      label: t("reports.vaultStatusReport.carID")
    }
  ];
  return (
    <>
      <Grid item md={6} lg={3} xs={12}>
        {t("common.Customer")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.customer_number}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("reports.vaultStatusReport.detectedIn")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.detected_in}{" "}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("reports.vaultStatusReport.exceptionType")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.exception_type}{" "}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item md={12} lg={12} xs={12}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12-content"
          >
            {t("reports.vaultStatusReport.accountSummary")}
          </AccordionSummary>
          <AccordionDetails>
            <Paper>
              <Typography style={DetailsStyles.subHeading2}>
                {tableList?.length}{" "}
                {t("reports.vaultStatusReport.recordsFound")}
              </Typography>
              <TableContainer>
                <Grid item xs={12} lg={12} marginTop={4}>
                  <MUIDataTable
                    columns={columns}
                    data={tableList ? [...tableList] : []}
                    options={options}
                  />
                </Grid>
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={spacing.gap}>
          <Grid id="clear-button-grid" item xs="auto">
            <Button
              id="clear-button"
              variant="outlined"
              type="button"
              onClick={() => {
                handleBack();
              }}
            >
              {t("common.back")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ExceptionDetails;
