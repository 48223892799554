export const MediaDestructionRequestDeliveryFormStyles = {
  PageHeader: {
    py: 1,
    margin: ".6rem 1.5rem 0 2rem",
    fontWeight: "normal",
    textTransform: "capitalize",
    fontSize: "34px"
  },
  PageContainer: {
    paddingTop: "10rem",
    paddingBottom: "1.25rem"
  },
  FlexShrinkNoGrow: { display: "flex", flexGrow: "0", flexShrink: "1" },
  HeaderTitle: {
    color: "#000000",
    fontSize: "24px",
    fontWeight: 400,
    LineHeight: "32.02px",
    textTransform: "capitalize"
  },

  subSentence: {
    color: "#000000",
    fontSize: 16,
    fontWeight: 400
  },
  BranchText: {
    opacity: "60%",
    fontSize: "12px"
  },
  SubTitle: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterApacing: "0.15px"
  },
  AuthenticatedText: {
    display: "flex",
    color: "var(--success-light, #4CAF50)"
  },
  BlackOutlinedButton: { height: 40, m: 1, textTransform: "none" },
  TextTransformNone: {
    textTransform: "initial",
    fontWeight: "600",
    borderColor: "1px solid rgba(0,0,0,0.5)"
  },
  BottomButtonsContainer: {
    paddingTop: "1.25rem",
    borderTop: 1,
    borderColor: "#0000001F",
    marginBottom: "2em"
  },
  BoxContainer: {
    borderBottom: "1px solid rgba(0,0,0,.12)",
    borderTop: "1px solid rgba(0,0,0,.12)",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    marginLeft: "2em",
    paddingBottom: "2em"
  },
  inputError: {
    border: "1.5px solid red"
  },
  FieldSize: {
    width: "310px",
    height: "56px"
  },
  InstructionFieldSize: {
    width: "640px",
    height: "80px"
  },
  DestructionId: {
    color: "var(--chart-harmonious-blue-900)",
    ml: "auto",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "29px",
    letterSpacing: "0.005em",
    textAlign: "right",
    alignSelf: "center"
  }
};
