/* Yup validation schemas goes here */
import dayjs from "dayjs";
import * as yup from "yup";
/* Reschedule schema */
export const rescheduleSchema = yup.object().shape({
  customer: yup.object().required("Customer field is required"),
  newDate: yup
    .date()
    .required("Date is required")
    .test(
      "customer-validation",
      "Invalid date entered.Please re-type.",
      (value) => {
        return (
          !value ||
          ((dayjs(value).isSame(dayjs(), "day") ||
            dayjs(value).isAfter(dayjs())) &&
            (dayjs(value).isSame(dayjs("05-31-2079"), "day") ||
              dayjs(value).isBefore(dayjs("05-31-2079"))))
        );
      }
    ),
  originalDate: yup
    .date()
    .required("Date is required")
    .test(
      "customer-validation",
      "Invalid date entered.Please re-type.",
      (value) => {
        return (
          !value ||
          ((dayjs(value).isSame(dayjs(), "day") ||
            dayjs(value).isAfter(dayjs())) &&
            (dayjs(value).isSame(dayjs("05-31-2079"), "day") ||
              dayjs(value).isBefore(dayjs("05-31-2079"))))
        );
      }
    )
});
