import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AccountCircleFilledIcon from "assets/images/AccountCircleFilled.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES } from "utils/constants";
import { findErrorMessage, getResponseData } from "utils/helpers";
import { CustomerOnHoldStyles } from "./CustomerOnHold.style";

const CustomerOnHold = () => {
  const { t } = useTranslation();
  const { currentBranch } = useSelector(selectAuth);
  const [isLoading, setIsLoading] = useState(false); // loading state
  const [errorAlert, setErrorAlert] = useState({ show: false, message: "" }); // error alert state
  const [onHoldCustomers, setOnHoldCustomers] = useState([]); // on-hold customers state
  const onHoldCustomersCount = useMemo(
    () => onHoldCustomers.length,
    [onHoldCustomers]
  ); // number of on-hold customers

  // fetch on-hold customers list
  const fetchOnHoldCustomers = async (districtId) => {
    try {
      setErrorAlert((prevState) => ({ show: false, message: "" }));
      setIsLoading((prevState) => true);
      const reqBody = {
        main_district_id: districtId
      };

      const onHoldCustomersResponse = await getResponseData(
        reqBody,
        `${CF_URLS.dashboard.onHoldCustomers}`,
        1
      );

      const data = onHoldCustomersResponse.data[0];
      // sort results
      const sortedCustomersList = data?.sort(
        (a, b) => Number(a?.customer_id) - Number(b?.customer_id)
      );
      // construct on-hold customer items
      const customersList = sortedCustomersList?.map(
        ({ customer_name, customer_number }) =>
          `${customer_number?.trim()} - ${customer_name?.trim()}`
      );

      setOnHoldCustomers((prevState) => customersList);
    } catch (error) {
      setErrorAlert((prevState) => ({
        show: true,
        message: findErrorMessage(ERROR_TYPES.ISSUE)
      }));
    } finally {
      setIsLoading((prevState) => false);
    }
  };

  // init
  useEffect(() => {
    if (currentBranch?.district_id)
      fetchOnHoldCustomers(currentBranch?.district_id);
  }, [currentBranch?.district_id]);

  return (
    <Box sx={CustomerOnHoldStyles.container}>
      {isLoading && <FullCircularLoader loading={isLoading} />}

      {/* Section Title */}
      <Typography sx={CustomerOnHoldStyles.title}>
        {`${t("dashboard.customerOnHold")} (${onHoldCustomersCount})`}
      </Typography>

      {/* Error Alert */}
      {errorAlert.show && (
        <Grid container justifyContent="center" mt={3}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" width="20" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() =>
                  setErrorAlert((prevState) => ({ show: false, message: "" }))
                }
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            style={CustomerOnHoldStyles?.alertMargin}
          >
            {errorAlert.message}
          </Alert>
        </Grid>
      )}

      {/* On-Hold Customers List */}
      {onHoldCustomersCount > 0 && (
        <Grid container sx={CustomerOnHoldStyles.listContainer}>
          <Grid container columns={3}>
            {onHoldCustomers.map((customer) => (
              <Grid item md={1} key={customer}>
                <MenuItem sx={CustomerOnHoldStyles.menuItem}>
                  <Box sx={CustomerOnHoldStyles.item}>
                    <img
                      src={AccountCircleFilledIcon}
                      alt="customer avatar"
                      style={{ marginRight: spacing.horizontalMargin8 }}
                    />
                    <p>{customer}</p>
                  </Box>
                </MenuItem>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CustomerOnHold;
