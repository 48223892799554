import axios from "axios";

import {
  APPLICATION_JSON,
  TXT_BEARER,
  TXT_BRANCH,
  TXT_CONTENT_TYPE,
  TXT_ENVIRONMENT,
  TXT_REGION,
  TXT_WORKER_ID,
  WORKER_ID
} from "utils/constants";
import {
  getAuthenticatedUserBranch,
  getAuthenticatedUserToken
} from "utils/helpers";

/**
 * @name AxiosInstance
 * @description Creating Axios API Instance
 *
 */
const AxiosInstance = axios.create();

/**
 * @description Adding Request Interceptor for each Cloud Function Call, it will add basic configurations to each API call before sending the request
 *
 */
AxiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await getAuthenticatedUserToken();
    config.headers[TXT_CONTENT_TYPE] = APPLICATION_JSON;
    config.headers.Accept = APPLICATION_JSON;
    config.headers[TXT_REGION] = process.env.REACT_APP_CF_REGION;
    config.headers[TXT_ENVIRONMENT] = process.env.REACT_APP_CF_ENV;
    config.headers[TXT_BRANCH] = getAuthenticatedUserBranch();
    config.headers[TXT_WORKER_ID] = WORKER_ID;
    config.headers.Authorization = `${TXT_BEARER} ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default AxiosInstance;
