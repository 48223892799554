import spacing from "styles/spacing";

const commonStyles = {
  widthFixed: { width: "310px" },
  widthFull: { width: "100%" },
  widthFit: { width: "fit-content" },
  alignCenter: { alignItems: "center" },
  pointerCursor: { cursor: "pointer" },
  heightBox: { height: "20px" },
  hideArrows: {
    "input": {
        "-moz-appearance": "textfield"
    },
    "input::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
    },
    "input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    width: "310px"
  }
};

const tableStyles = {
  tablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  displayFlex: {
    display: "flex"
  },
  textColor: {
    color: "var(--color-im-green)"
  },
  gapSpacing: "20px",
  directionRow: "row",
  directionColumn: "column",
  noBorder: {
    border: "none"
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    rowGap: spacing?.verticalMargin20
  },
  buttons: { textTransform: "none" },
  hiddenButton: { display: "none" }
};

export const disasterRecoveryStyles = {
  tableStyles,
  commonStyles
};
export const toDoLabelStyle = {
  textAlign: "center",
};
