import {
  Box,
  Container,
  Divider,
  Link,
  Stack,
  Typography
} from "@mui/material";
import IRMLogo from "assets/images/IRM_Logo.svg";
import { Styles } from "./Footer.Styles";

function Footer() {
  var currentTime = new Date();
  var year = currentTime.getFullYear();
  return (
    <Container component="footer" sx={Styles.Container}>
      <Divider />
      <Box sx={Styles.Layout}>
        <Stack direction="row" spacing={1} alignItems="center">
          <img alt="IRM Logo" src={IRMLogo} id="footer-logo" />
          <Typography variant="caption">
            © {year} Iron Mountain Inc.
          </Typography>
        </Stack>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <Link component="button" variant="caption" onClick={() => {}}>
            Terms and Conditions
          </Link>
          <Link component="button" variant="caption" onClick={() => {}}>
            Privacy Policy
          </Link>
        </Stack>
      </Box>
    </Container>
  );
}

export default Footer;
