import { t } from "i18next";

export const transportTableColumns = [
  {
    name: "date",
    label: t("preferences.minimumEmptyTransports.date")
  },
  {
    name: "imEmployee",
    label: t("preferences.minimumEmptyTransports.imEmployee")
  },
  {
    name: "authorizedBy",
    label: t("preferences.minimumEmptyTransports.authorizedBy")
  },
  {
    name: "changedValues",
    label: t("preferences.minimumEmptyTransports.changedValues")
  }
];
