import { Stack } from "@mui/material";
import CustomerSearchLayout from "components/customer-move-search/CustomerSearchLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const CustomerSearch = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("customerMoveSearch.pageTitle")} />
      <CustomerSearchLayout />
    </Stack>
  );
};

export default CustomerSearch;
