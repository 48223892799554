/* eslint-disable eqeqeq */
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { DestructionStatus, Y_CHECKER } from "utils/constants";

const FormActionButtons = ({
  handleSaveClick,
  destructionRqDetails,
  saveEnable,
  customerDataById,
  pageInitialButtonDisable,
  handleNewClick,
  pageStatus,
  handleResetClick,
  cancelEnable,
  handleCancelClick,
  handleOpenPrintModal,
  destructionId,
  resetButtonEnable,
  cancelDisable
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      id="request_delivery_71"
      direction="row"
      spacing={spacing.verticalMargin20}
      marginTop={spacing.verticalMargin32}
    >
      {/* Reset Requests button */}
      <Button
        id="request_delivery_75"
        variant="outlined"
        size="medium"
        type="button"
        disabled={
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Scheduled
          ) ||
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Cancelled
          ) ||
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Complete
          ) ||
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.InProcess
          ) ||
          Boolean(customerDataById[0]?.mdr_on_hold_status == Y_CHECKER) ||
          resetButtonEnable
        }
        onClick={handleResetClick}
      >
        {t("mediaDestructionDelivery.reset")}
      </Button>
      {/* Cancel button */}
      <Button
        id="request_delivery_77"
        variant="outlined"
        size="medium"
        type="button"
        disabled={
          (!(
            Boolean(
              destructionRqDetails?.destruct_request_status_id ==
                DestructionStatus.Authorized
            ) ||
            Boolean(
              destructionRqDetails?.destruct_request_status_id ==
                DestructionStatus.Scheduled
            )
          ) &&
            !cancelEnable &&
            customerDataById[0]?.mdr_on_hold_status == "Y") ||
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Cancelled
          ) ||
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Complete
          ) ||
          Boolean(pageStatus.current == "none") ||
          cancelDisable
        }
        onClick={handleCancelClick}
      >
        {t("mediaDestructionDelivery.cancel")}
      </Button>
      {/* Print button */}
      <Button
        id="request_delivery_78"
        variant="outlined"
        size="medium"
        type="button"
        onClick={handleOpenPrintModal}
        disabled={
          Boolean(pageStatus.current == "none") || Boolean(!destructionId)
        }
      >
        {t("mediaDestructionDelivery.print")}
      </Button>

      {/* New button */}
      <Button
        id="request_delivery_74"
        variant="outlined"
        size="medium"
        type="button"
        onClick={handleNewClick}
      >
        {t("mediaDestructionDelivery.new")}
      </Button>
      {/* Save button */}
      <Button
        id="request_delivery_72"
        variant="contained"
        size="medium"
        type="button"
        onClick={handleSaveClick}
        disabled={
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Scheduled
          ) ||
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Cancelled
          ) ||
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Complete
          ) ||
          Boolean(
            destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.InProcess
          ) ||
          !saveEnable ||
          Boolean(customerDataById[0]?.mdr_on_hold_status == Y_CHECKER) ||
          pageInitialButtonDisable
        }
      >
        {t("mediaDestructionDelivery.save")}
      </Button>
    </Stack>
  );
};

export default FormActionButtons;
