import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CircularLoaderFullPage } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  dayConst,
  dayMonthYearFormat,
  ERROR_TYPES,
  errorMsgs,
  mdyDateFormat,
  VALUE_EMPTY_STRING
} from "utils/constants";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";
import { getRoadNetAdhocData, lastGenRunDate } from "../Services";
import RoadNetConfirmationPopup from "./RoadNetDataConfirmationModal";
import { calendarUiRoadNet } from "./RoadNetDataStyles";

const Dot = styled(Box)(({ theme, color }) => ({
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: color,
  marginRight: theme.spacing(1),
  marginTop: "8px"
}));

const StyledDateCalendar = styled(DateCalendar)(() => ({
  "MuiSvgIcon-root": {
    display: "none"
  }
}));

const RoadNetData = ({
  isOpen,
  onCloseModal,
  setThrowRoadNetError,
  setThrowErrorMessage,
  setMessageSuccess
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultSelectedDates, setDefaultSelectedDates] = useState([]);
  const [userSelectedDates, setUserSelectedDates] = useState([]);
  const [minDate, setMinDate] = useState(dayjs()); // Initialize as current date
  const [maxDate, setMaxDate] = useState("");
  const { currentBranch } = useSelector(selectAuth);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  useEffect(() => {
    serviceInoformationlastGenRun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adhocRequestCheck = async (dateTime) => {
    try {
      setIsLoading(true);
      const resp = await getRoadNetAdhocData(
        mainDistrictId,
        currentBranch?.name,
        dateTime
      );
      return resp;
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowRoadNetError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const dateSlots = (lastGenDate) => {
    const parsedLastGenDate = dayjs(lastGenDate?.last_gen_run_date);
    const formattedDate = parsedLastGenDate.format(mdyDateFormat);
    const today = dayjs().startOf(dayConst);
    const todayPlus6Days = today.add(6, dayConst);
    let maxSelectableDate;
    const parsedFormattedDate = dayjs(formattedDate, mdyDateFormat);
    if (parsedFormattedDate.isBefore(today)) {
      setMinDate(parsedFormattedDate);
      setMaxDate(parsedFormattedDate);
      setDefaultSelectedDates([]); // Set to empty array if date is in the past
      return; // Exit early
    }
    if (parsedFormattedDate.isAfter(todayPlus6Days)) {
      maxSelectableDate = todayPlus6Days;
    } else {
      maxSelectableDate = parsedFormattedDate.isValid()
        ? parsedFormattedDate
        : today;
    }
    setMinDate(today);
    setMaxDate(maxSelectableDate);
    const enabledDates = getAllDatesInRange(today, maxSelectableDate); // Use maxSelectableDate instead of maxDate
    setDefaultSelectedDates(enabledDates || []);
  };

  const serviceInoformationlastGenRun = async () => {
    try {
      setIsLoading(true);
      let res = await lastGenRunDate(mainDistrictId);
      if (res) {
        return dateSlots(res.data[0][0]);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowRoadNetError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllDatesInRange = (minDate, maxDate) => {
    const dates = [];
    let currentDate = minDate.clone(); // Clone to avoid mutating minDate

    while (currentDate.isBefore(maxDate) || currentDate.isSame(maxDate)) {
      dates.push(currentDate.format(dayMonthYearFormat)); // Change format as needed
      currentDate = currentDate.add(1, dayConst); // Move to the next day
    }

    return dates;
  };

  const handleSelectDay = (day) => {
    const formattedDate = dayjs(day).format(dayMonthYearFormat);
    if (defaultSelectedDates.includes(formattedDate)) {
      if (userSelectedDates.includes(formattedDate)) {
        // Remove date if already selected
        setUserSelectedDates((prev) =>
          prev.filter((date) => date !== formattedDate)
        );
      } else {
        // Add date if not already selected
        setUserSelectedDates((prev) => [...prev, formattedDate]);
      }
    }
  };

  const slotUi = ({ day }) => {
    const formattedDate = dayjs(day).format(dayMonthYearFormat);
    const isDefaultSelected = defaultSelectedDates.includes(formattedDate);
    const isUserSelected = userSelectedDates.includes(formattedDate);
    const isInRange = day.isBetween(minDate, maxDate, null, "[]");

    let backgroundColor = calendarUiRoadNet.buttonDefaultColor.backgroundColor;

    // Check if there are no default selected dates
    if (defaultSelectedDates.length === 0) {
      backgroundColor = calendarUiRoadNet.buttonInActiveStyle.backgroundColor;
    } else {
      if (isUserSelected) {
        backgroundColor = calendarUiRoadNet.btnSelectedColor.backgroundColor;
      } else if (isDefaultSelected) {
        backgroundColor = calendarUiRoadNet.buttonActiveStyle.backgroundColor;
      } else if (!isInRange) {
        backgroundColor = calendarUiRoadNet.buttonInActiveStyle.backgroundColor;
      }
    }

    return (
      <button
        style={{
          ...calendarUiRoadNet.buttonStyle,
          backgroundColor,
          color: isUserSelected
            ? calendarUiRoadNet.userSelectedColor.color
            : calendarUiRoadNet.userNotSelectedColor.color
        }}
        onClick={() => handleSelectDay(day)}
      >
        {dayjs(day).date()}
      </button>
    );
  };

  const handleClear = () => {
    setUserSelectedDates([]);
  };

  const handleOkClick = async () => {
    setOpenConfirmationPopup(false);
    const arr = [];
    for (let i = 0; i < userSelectedDates.length; i++) {
      const result = await adhocRequestCheck(userSelectedDates[i]);
      arr.push({ error: result?.data[0][0].error, date: userSelectedDates[i] });
    }

    onCloseModal(false);
    const hasError = arr?.some((item) => item.error !== errorMsgs.noError);

    if (hasError) {
      // If there is any error
      const errorDates = arr
        .filter((item) => item.error !== errorMsgs.noError)
        .map((item) => item.date);
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80300);
      let replacedText = errorMsg[0]?.descr.replace("|", errorDates.join(","));
      setThrowErrorMessage(replacedText);
      setThrowRoadNetError(true);
    } else {
      // If all are successful, print all success dates
      const successDates = arr.map((item) => item.date);
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80299);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        successDates.join(",")
      );
      setMessageSuccess(replacedText);
    }
    setDefaultSelectedDates({});
  };

  return (
    <>
      <CommonModal
        open={isOpen}
        minWidth={700}
        title={t("reports.roadNetData.title")}
        body={
          <>
            {isLoading && <CircularLoaderFullPage loading={isLoading} />}
            <Typography>{t("reports.roadNetData.mainSubTitle")}</Typography>
            <Typography>{t("reports.roadNetData.subTitle")}</Typography>
            <br />
            <Grid container justifyContent="center" spacing={3}>
              <Grid item lg={7} md={7} xs={12}>
                <Card>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDateCalendar
                      slots={{ day: slotUi }}
                      showDaysOutsideCurrentMonth
                      minDate={minDate}
                      maxDate={maxDate}
                      views={[dayConst]}
                      value={minDate}
                    />
                  </LocalizationProvider>
                </Card>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={3}>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
                {...calendarUiRoadNet.bottomTextGrid}
              >
                <Box {...calendarUiRoadNet.nonSelectableDateBoxStyles}>
                  <Dot color="var(--color-nonselactable)" />
                  <Typography>
                    {t("reports.roadNetData.nonSelectableDate")}
                  </Typography>
                </Box>
                <Box {...calendarUiRoadNet.selectableDateBoxStyles}>
                  <Dot color="var(--color-selectable)" />
                  <Typography>
                    {t("reports.roadNetData.selectableDate")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button variant="outlined" onClick={handleClear}>
              {t("common.reset")}
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenConfirmationPopup(true)}
              disabled={!userSelectedDates.length}
            >
              {t("common.ok")}
            </Button>
          </>
        }
      />
      {openConfirmationPopup && (
        <RoadNetConfirmationPopup
          handleOkClick={handleOkClick}
          openConfirmationPopup={openConfirmationPopup}
          setOpenConfirmationPopup={setOpenConfirmationPopup}
        />
      )}
    </>
  );
};

export default RoadNetData;
