import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoader } from "components/core";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getHolidaysForBranchAndYear,
  getYearsForHolidays
} from "services/api/query";
import { selectAuth } from "store/slices";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  monthMap
} from "utils/constants";
import { findErrorMessage } from "utils/helpers";
import { IRMHolydaysStyles } from "./IronMountainHolidays.style";

const IronMountainHolidays = () => {
  const { t } = useTranslation();
  const { currentBranch, branches } = useSelector(selectAuth);
  const [holidayYears, setHolidayYears] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [branchSelected, setBranchSelected] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentYear = new Date().getFullYear();

  const dataColomns = [
    {
      label: `${t("dashboard.irmHolidays")}`,
      name: "holiday_name"
    },
    {
      label: selectedYear,
      name: "holiday_date"
    }
  ];

  const dataTableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    jumpToPage: false,
    pagination: false,
    setRowProps: (row) => ({
      style: {
        height: IRMHolydaysStyles?.rowHeight
      }
    }),
    textLabels: {
      body: {
        noMatch: `${t("common.noItemsToDisplay")}`
      }
    }
  };

  const onYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const onBranchChange = (e) => {
    setBranchSelected(e.target.value);
  };

  const getHolidayYears = async () => {
    setIsLoading(true);
    try {
      const years = await getYearsForHolidays(branchSelected);
      const mappedData = years?.map((y) => {
        return { ...y, label: y?.year, value: y?.year };
      });
      setHolidayYears(mappedData);
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getHolidayListForBranchAndYear = async () => {
    setIsLoading(true);
    try {
      const holidays = await getHolidaysForBranchAndYear(
        branchSelected,
        selectedYear
      );

      const mappedData = holidays?.map((h) => {
        return {
          ...h,
          holiday_date: h?.holiday_date,
          holiday_name: h?.holiday_name
        };
      });
      mappedData?.sort((a, b) => {
        const dateA = parseHolidayDate(a.holiday_date, a.year);
        const dateB = parseHolidayDate(b.holiday_date, b.year);
        return dateA - dateB;
      });
      setHolidayList(mappedData);
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const parseHolidayDate = (dateStr, year) => {
    const [day, month] = dateStr.split(" ");
    const monthIndex = monthMap[month.toLowerCase()];
    return new Date(year, monthIndex, parseInt(day, 10));
  };

  useEffect(() => {
    if (holidayYears?.length) {
      const diffYear = holidayYears?.find((y) => y?.year === `${currentYear}`);
      setSelectedYear(diffYear?.value || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holidayYears]);

  useEffect(() => {
    if (currentBranch) {
      setBranchSelected(currentBranch?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch]);

  useEffect(() => {
    if (branchSelected) {
      getHolidayYears();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchSelected]);

  useEffect(() => {
    if (selectedYear && branchSelected) {
      getHolidayListForBranchAndYear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, branchSelected]);

  return (
    <Grid id="irmHolidaysGrid1" columnSpacing={IRMHolydaysStyles?.colSpace}>
      <Grid id="irmHolidaysGrid2" item>
        <div style={IRMHolydaysStyles.headerDivStyles}>
          <Typography sx={IRMHolydaysStyles.fontStyles}>
            {t("dashboard.irmHolidays")}
          </Typography>
        </div>

        {showErrorAlert && (
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" width="20" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setShowErrorAlert(false);
                  setShowErrorAlertMsg("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            style={IRMHolydaysStyles?.margin}
          >
            {Array.isArray(showErrorAlertMsg)
              ? showErrorAlertMsg.map((message, index) => (
                  <>
                    {index === 0 ? <b>{message}</b> : `${index}) ${message}`}
                    <br />
                  </>
                ))
              : showErrorAlertMsg}
          </Alert>
        )}

        <form>
          <Grid
            id="irmHolidaysGrid1"
            container
            columnSpacing={IRMHolydaysStyles?.marginBottom}
            style={{ marginBottom: IRMHolydaysStyles?.marginBottom }}
          >
            <Grid id="irmHolidaysGrid4" item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="branch-selectLabel">
                  {t("common.branch")}
                </InputLabel>
                <Select
                  labelId="branch-selectLabel"
                  id="branch-select"
                  label={t("common.branch")}
                  value={branchSelected || ""}
                  onChange={onBranchChange}
                >
                  {branches?.length > 0 &&
                    branches?.map((b) => (
                      <MenuItem value={b.value} key={b.value}>
                        {b.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid id="irmHolidaysGrid5" item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="year-selectLabel">
                  {t("common.year")}
                </InputLabel>
                <Select
                  labelId="year-selectLabel"
                  id="year-select"
                  label={t("common.year")}
                  value={selectedYear || ""}
                  onChange={onYearChange}
                >
                  {holidayYears?.length > 0 &&
                    holidayYears?.map((y) => (
                      <MenuItem key={y.value} value={y.value}>
                        {y.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>

        <Grid id="irmHolidaysGrid7" xs={12}>
          <CircularLoader loading={isLoading}>
            <MUIDataTable
              id="irmHolidaysTable"
              data={holidayList}
              columns={dataColomns}
              options={dataTableOptions}
            />
          </CircularLoader>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IronMountainHolidays;
