import { Stack } from "@mui/material";
import DiscrepencySearchLayout from "components/discrepancy-management/discrepancy-search/DiscrepancySearchLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const DiscrepencySearchPage = () => {
  return (
    <>
      <Stack>
        <PageTitle title={i18n.t("discrepancyManagement.title")} />
        <DiscrepencySearchLayout />
      </Stack>
    </>
  );
};
export default DiscrepencySearchPage;
