import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import i18n from "i18n";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CloseFilledIcon from "assets/images/CloseFilled.svg";
import PencilIcon from "assets/images/pencil.svg";
import { CircularLoader, SearchField } from "components/core";
import { useGetInsertTypesQuery } from "services/api";
import {
  addExtraInsert,
  removeExtraInsert,
  selectMediaRequest,
  setBillForInsertsFlag,
  setErrorMessage,
  updateExtraInsert
} from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  N_CHECKER,
  Y_CHECKER
} from "utils/constants";
import { limitFormikTextFieldMaxValue } from "utils/helpers";
import { MediaRequestDetailFormStyles } from "../../media-request-detail-form/MediaRequestDetailForm.Styles";
import { sortInsertTypes } from "../../utils";
import { IssueContainersStyles } from "../IssueContainersTab.Styles";
import { extraInsertValidationSchema } from "./schema";

const extraInsertsFormInitialValues = {
  insert_type_id: 0,
  insert_type: "",
  quantity: 0
};

// column definition of the extra insert data table view only
const viewExtraInsertsColumnsDefinition = [
  {
    name: "insert_type",
    label: i18n.t("mediaRequestDetail.insertTypeCaps")
  },
  {
    name: "quantity",
    label: i18n.t("mediaRequestDetail.quantityCaps")
  }
];

const ExtraInserts = (props) => {
  const { t } = useTranslation();
  const { issueContainers } = useSelector(selectMediaRequest); // select media request slice
  const dispatch = useDispatch(); // redux actions dispatcher
  const [selectedRow, setSelectedRow] = useState(-1);
  const [showError, setShowError] = useState(false);

  const dataTableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    selectableRows: t("mediaRequestDetail.none"),
    jumpToPage: false,
    pagination: false,
    tableBodyHeight: "230px",
    tableBodyMaxHeight: "230px",
    textLabels: {
      body: {
        noMatch: props.isLoading ? (
          <CircularLoader loading={props.isLoading} />
        ) : (
          t("mediaRequestDetail.noItemsToDisplay")
        )
      }
    }
  };

  // set row data to the form
  const handleEditRow = (rowIndex) => {
    const data = issueContainers.extraInserts[rowIndex];
    extraInsersForm.setValues(data);
    setSelectedRow(rowIndex);
  };

  // column definition of the extra insert data table
  const extraInsertsColumnsDefinition = [
    {
      name: "insert_type_id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnEdit${value}`}
            aria-label="edit"
            onClick={() => handleEditRow(tableMeta.rowIndex)}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={PencilIcon} alt="edit icon" />
          </IconButton>
        )
      }
    },
    {
      name: "insert_type_id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() => dispatch(removeExtraInsert(tableMeta.rowIndex))}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "insert_type",
      label: t("mediaRequestDetail.insertTypeCaps")
    },
    {
      name: "quantity",
      label: t("mediaRequestDetail.quantityCaps")
    }
  ];

  // extra inserts form
  const extraInsersForm = useFormik({
    initialValues: extraInsertsFormInitialValues,
    validationSchema: extraInsertValidationSchema,
    onSubmit: (values, formikHelpers) => {
      if (extraInsersForm.isValid) {
        if (selectedRow < 0) {
          setShowError(false);
          const sum = issueContainers?.extraInserts?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.quantity,
            0
          );

          if (sum + values?.quantity > 1000) {
            setShowError((prev) => !prev);
            return;
          }

          dispatch(
            addExtraInsert({
              ...values,
              id: Math.round(Math.random() * 100)
            })
          );
        } else {
          setShowError(false);

          const updatedExtraInserts = issueContainers?.extraInserts?.map(
            (container) => {
              if (container?.id === values?.id) {
                return { ...container, quantity: values?.quantity };
              }
              return container;
            }
          );

          const sum = updatedExtraInserts?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.quantity,
            0
          );

          if (sum > 1000) {
            setShowError((prev) => !prev);
            return;
          }

          dispatch(
            updateExtraInsert({
              index: selectedRow,
              data: values
            })
          );
          setSelectedRow(-1);
        }
        formikHelpers.resetForm();
      }
    }
  });

  const {
    data: extraInsertTypes = [],
    isLoading: isExtraInsertTypesLoading,
    isError: isExtraInsertTypesError,
    error: extraInsertTypesError
  } = useGetInsertTypesQuery(); // fetch extra insert types

  // set error message in redux if one occurs
  useEffect(() => {
    if (isExtraInsertTypesError) {
      dispatch(setErrorMessage(extraInsertTypesError.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExtraInsertTypesError]);

  useEffect(() => {
    // fire the dirty event when the quantity cleared
    if (Number(props.extraItemsForm.values?.lock_quantity) === 0) {
      props.onFormDirty(false);
    } else {
      props.onFormDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.extraItemsForm.values?.lock_quantity]);

  useEffect(() => {
    // submit form if the tab is changed and the form is dirty
    if (
      props.startValidation &&
      Number(props.extraItemsForm.values.lock_quantity) > 0
    ) {
      props.extraItemsForm.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.startValidation]);

  return (
    <Grid id="issueExtraInsertsGrid1" item xs={12}>
      <Card id="issueExtraInsertsCard">
        <CardHeader
          id="issueExtraInsertsCardHeader"
          title={t("mediaRequestDetail.extraInserts")}
        />
        <CardContent
          id="issueExtraInsertsCardContent"
          sx={IssueContainersStyles.CardContent}
        >
          {/* Radio Buttons Container*/}
          <Grid
            id="issueExtraInsertsBox1"
            item
            container
            xs={12}
            sx={{
              display: "flex",
              alignItems: "start",
              columnGap: spacing.verticalMargin20
            }}
          >
            {/* Extra Inserts Type selector */}
            <Grid item>
              <RadioGroup
                id="issueExtraInsertsInsertType"
                row
                aria-labelledby="issue-container-radio-button-group"
                name="issue-container-radio-button-group"
                sx={{
                  columnGap: spacing.verticalMargin20,
                  "> label": { margin: 0 }
                }}
                value={issueContainers.billForInsertsFlag}
                onChange={(e) =>
                  dispatch(setBillForInsertsFlag(e.target.value))
                }
              >
                <FormControlLabel
                  id="issueExtraInsertsPurchase"
                  disabled={Boolean(props?.requestId)}
                  value={Y_CHECKER}
                  control={<Radio />}
                  label={t("mediaRequestDetail.purchase")}
                />
                <FormControlLabel
                  id="issueExtraInsertsExisting"
                  disabled={Boolean(props?.requestId)}
                  value={N_CHECKER}
                  control={<Radio />}
                  label={t("mediaRequestDetail.exchangeForExisting")}
                />
              </RadioGroup>
            </Grid>

            <Grid item>
              {issueContainers.billForInsertsFlag === N_CHECKER && (
                <Alert severity="info">
                  {t("mediaRequestDetail.informCustomerToPickupInserts")}
                </Alert>
              )}
            </Grid>
          </Grid>

          {/* Add extra inserts form container*/}
          <Grid
            id="issueExtraInsertsGrid3"
            item
            container
            xs={12}
            sx={{
              display: "flex",
              alignItems: "start",
              columnGap: spacing.verticalMargin20,
              padding: spacing.verticalMargin20
            }}
          >
            {/* error message */}
            {showError && (
              <Alert
                id="customerMediaIdentifierModalErrorAlert"
                severity="warning"
                onClose={() => setShowError((prevState) => !prevState)}
                style={{ marginBottom: "20px" }}
              >
                {t("mediaRequestDetail.extraInsertsErrorMsg")}
              </Alert>
            )}

            {/* Extra inserts type field */}
            <Grid id="issueExtraInsertsGrid5" item>
              <SearchField
                id="issueExtraInsertsExtraInsertType"
                name="insert_type_id"
                disabled={Boolean(props?.requestId)}
                size="small"
                options={sortInsertTypes(extraInsertTypes)}
                loading={isExtraInsertTypesLoading}
                label={t("mediaRequestDetail.insertType")}
                value={extraInsersForm.values?.insert_type}
                error={
                  extraInsersForm.touched.insert_type_id &&
                  Boolean(extraInsersForm.errors.insert_type_id)
                }
                helperText={
                  extraInsersForm.touched.insert_type_id &&
                  extraInsersForm.errors.insert_type_id
                }
                onChange={(_, value) => {
                  extraInsersForm.setFieldValue(
                    "insert_type_id",
                    value?.value || 0
                  );
                  extraInsersForm.setFieldValue(
                    "insert_type",
                    value?.label || ""
                  );
                }}
                sx={{ width: "18.8rem" }}
              />
            </Grid>

            {/* Extra inserts quantity field */}
            <Grid id="issueExtraInsertsGrid6" item>
              <TextField
                id="issueExtraInsertsGridQuantity"
                disabled={Boolean(props?.requestId)}
                name="quantity"
                size="small"
                type="number"
                label={t("mediaRequestDetail.quantity")}
                placeholder={t("mediaRequestDetail.quantity")}
                required
                value={extraInsersForm.values?.quantity}
                error={
                  extraInsersForm.touched.quantity &&
                  Boolean(extraInsersForm.errors.quantity)
                }
                helperText={
                  extraInsersForm.touched.quantity &&
                  extraInsersForm.errors.quantity
                }
                onChange={(event) =>
                  limitFormikTextFieldMaxValue(extraInsersForm, event, 150)
                }
                sx={{ width: "6.25rem" }}
              />
            </Grid>

            {/* Add button */}
            <Grid id="issueExtraInsertsGrid7" item>
              <Button
                id="issueExtraInsertsGridAddButton"
                disabled={Boolean(props?.requestId)}
                variant="outlined"
                sx={{ width: "3.8rem" }}
                onClick={() => extraInsersForm.handleSubmit()}
              >
                {t("mediaRequestDetail.add")}
              </Button>
            </Grid>
          </Grid>

          <Grid
            id="issueExtraInsertsGrid4"
            item
            xs={12}
            container
            columnSpacing={"64px"}
          >
            {/* Extra inserts data table */}
            <Grid id="issueExtraInsertsTableWrapper" item xs={6}>
              <MUIDataTable
                id="issueExtraInsertsDataTable"
                data={issueContainers.extraInserts}
                columns={
                  props.requestId
                    ? viewExtraInsertsColumnsDefinition
                    : extraInsertsColumnsDefinition
                }
                sx={IssueContainersStyles.DataTable}
                options={dataTableOptions}
              />
            </Grid>

            <Grid
              id="issueExtraInsertsGrid8"
              item
              container
              xs={3}
              rowGap={spacing.verticalMargin20}
              alignItems={"flex-start"}
              alignContent={"flex-start"}
            >
              {/* Extra lock quantity field */}
              <TextField
                id="issueExtraInsertsGridExtraLockQuantity"
                name="lock_quantity"
                disabled={Boolean(props?.requestId)}
                size="small"
                type="number"
                label={t("mediaRequestDetail.extraLockQuantity")}
                value={props.extraItemsForm.values?.lock_quantity}
                required={Boolean(props.extraItemsForm.values?.lock_type_code)}
                error={
                  props.extraItemsForm.touched.lock_quantity &&
                  Boolean(props.extraItemsForm.errors.lock_quantity)
                }
                helperText={
                  props.extraItemsForm.touched.lock_quantity &&
                  props.extraItemsForm.errors.lock_quantity
                }
                onChange={(event) =>
                  limitFormikTextFieldMaxValue(
                    props.extraItemsForm,
                    event,
                    32767
                  )
                }
                fullWidth
              />

              {/* Extra lock type field */}
              <SearchField
                id="issueExtraInsertsGridExtraLockType"
                disabled={Boolean(props?.requestId)}
                size="small"
                loading={props?.lockTypesLoading}
                options={props?.lockTypes}
                label={t("mediaRequestDetail.extraLockType")}
                value={props.extraItemsForm.values?.lock_type_code}
                error={
                  props.extraItemsForm.touched.lock_type_code &&
                  Boolean(props.extraItemsForm.errors.lock_type_code)
                }
                helperText={
                  props.extraItemsForm.touched.lock_type_code &&
                  props.extraItemsForm.errors.lock_type_code
                }
                onChange={(_, value) =>
                  props.extraItemsForm.setFieldValue(
                    "lock_type_code",
                    value?.value || ""
                  )
                }
                fullWidth
              />

              {/* Clip quantity input */}
              <TextField
                id="issueExtraInsertsGridExtraClipsQuantity"
                name="clip_quantity"
                disabled={Boolean(props?.requestId)}
                size="small"
                type="number"
                label={t("mediaRequestDetail.extraClipQuantity")}
                value={props.extraItemsForm.values?.clip_quantity}
                onChange={(event) =>
                  limitFormikTextFieldMaxValue(
                    props.extraItemsForm,
                    event,
                    32767
                  )
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

// Props definition of the component
ExtraInserts.propTypes = {
  requestId: PropTypes.number, // ID of the media request
  inserts: PropTypes.array, // extra inserts of a media request
  isLoading: PropTypes.bool, // fetching inserts of a media request
  onFormDirty: PropTypes.func, // if form is dirty event
  startValidation: PropTypes.bool, // start form validation or not
  lockTypes: PropTypes.array.isRequired, // Lock types
  lockTypesLoading: PropTypes.bool, // Lock types loading state
  extraItemsForm: PropTypes.object // formik instance for the extra locks and clips
};

export default ExtraInserts;
