import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthService from "services/authService";
import { removeAuthInfo } from "store/slices";
import { inactivityWarningTimeInSec } from "utils/constants";
import TimeoutWarningModal from "./TimeoutWarningModal";

const InactivityTimeoutComponent = () => {
  const authService = useAuthService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [remainingTimeInSec, setRemainingTimeInSec] = useState(
    inactivityWarningTimeInSec
  );
  const inactivityTime = process.env.REACT_APP_TIMEOUT_IN_MIN * 60 * 1000;
  const eventTypes = [
    "keypress",
    "mousemove",
    "mousedown",
    "scroll",
    "touchmove",
    "pointermove"
  ];

  const updateLastActivity = () => {
    localStorage.setItem("lastActivity", Date.now().toString());
  };

  const addEventListeners = () => {
    eventTypes.forEach((type) => {
      window.addEventListener(type, updateLastActivity);
    });
  };

  const removeEventListeners = () => {
    eventTypes.forEach((type) => {
      window.removeEventListener(type, updateLastActivity);
    });
  };

  useEffect(() => {
    // Update lastActivity immediately when the component is mounted
    updateLastActivity();

    //Set lastActivity timestamp on any user interaction
    addEventListeners();

    //Check inactivity every minute
    const inetrval = setInterval(() => {
      const lastActivity = parseInt(localStorage.getItem("lastActivity"), 10);
      const currentTime = Date.now();
      const timeElapsed = currentTime - lastActivity;
      if (timeElapsed >= inactivityTime) {
        setShowWarningModal(true);
      }
    }, 60000);

    //Cleanup listeners on compoennet unmount
    return () => {
      if (!showWarningModal) {
        removeEventListeners();
        clearInterval(inetrval);
      }
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showWarningModal) {
      const timer = setInterval(() => {
        setRemainingTimeInSec(remainingTimeInSec - 1);
      }, 1000);

      if (remainingTimeInSec === 0) {
        localStorage.removeItem("lastActivity");
        clearInterval(timer);
        authService.logOut();
        dispatch({ type: "Reset" });
        dispatch(removeAuthInfo());
        navigate("/login");
      }

      return () => {
        clearInterval(timer);
      };
    }
  });

  const onCloseModal = () => {
    setRemainingTimeInSec(inactivityWarningTimeInSec);
    setShowWarningModal(false);
    updateLastActivity();
  };

  return (
    showWarningModal && (
      <TimeoutWarningModal
        isModalOpen={showWarningModal}
        onCloseModal={() => onCloseModal()}
        remainingTimeInSec={remainingTimeInSec}
      />
    )
  );
};

export default InactivityTimeoutComponent;
