import { ThemeProvider } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  Collapse,
  createTheme,
  IconButton,
  SvgIcon,
  useTheme
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllCustomersQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  errorMsgs,
  local
} from "utils/constants";
import { findErrorMessage, getResponseData } from "utils/helpers";
import { labelTemplateMaintanceStyles } from "./LabelTemplateMaintenanceStyles";

const LabelAssignCustomerModal = ({
  isAssignModalOpen,
  onClose,
  labelId,
  selectedRow,
  assignedCustomers,
  setAssignedCustomers,
  deleteAlertModal,
  setDeleteAlertModal,
  setDeleteAlertMsg
}) => {
  const [customer, setCustomer] = useState({});
  const { currentBranch } = useSelector(selectAuth);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "number",
      label: t("labelTemplateMaintenance.assignedCustomers"),
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Button
                sx={{ padding: 0 }}
                onClick={() =>
                  unAssign(
                    assignedCustomers[tableMeta?.rowIndex],
                    tableMeta?.rowIndex
                  )
                }
              >
                <SvgIcon component={CloseIcon} />
              </Button>
              {assignedCustomers[tableMeta?.rowIndex]?.number}
            </>
          );
        }
      }
    }
  ];
  const globalTheme = useTheme();
  const commonDataTableTheme = createTheme(globalTheme, {
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: "15px"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: "12px"
          }
        }
      }
    }
  });
  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    tableBodyHeight: "200px",
    tableBodyMaxHeight: "200px",
    pagination: 0 & true,
    textLabels: {
      body: {
        noMatch:
          selectedRow?.assignTransportListArr?.length === 0 &&
          `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  const handleChange = (e, newValue) => {
    setCustomer({
      customer: newValue?.label,
      customerId: newValue?.value
    });
  };

  const unAssign = async (customer, index) => {
    const { customer_id } = customer || {};
    try {
      if (customer?.local === local) {
        const updatedAssignedCustomers = [...assignedCustomers]; // Create a shallow copy of the array
        updatedAssignedCustomers.splice(index, 1); // Modify the copy
        setAssignedCustomers(updatedAssignedCustomers); // Set the new state
      } else {
        setLoading(true);
        const reqBody = {
          main_district_id: currentBranch?.value,
          label_template_id: labelId,
          customer_id: customer_id
        };
        const unAssignCustomers = await getResponseData(
          reqBody,
          CF_URLS.labelTemplateMaintanence.unAssignCustomers,
          1
        );
        if (unAssignCustomers.data[0]) {
          if (assignedCustomers.length === 1) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode25116);
            setDeleteAlertMsg(errDesc[0]?.descr);
            setDeleteAlertModal(true);
          }
          setLoading(false);
          setAssignedCustomers(unAssignCustomers.data[0]);
          setThrowError(false);
        } else if (unAssignCustomers.data[0][0].error) {
          setLoading(false);
          const errdescr = await getMessageFromCode(
            unAssignCustomers.data[0][0]?.error
          );
          const errMsg = errdescr[0]?.descr;
          setThrowError(true);
          setThrowErrorMessage(errMsg);
        } else if (unAssignCustomers.data[0][0]?.error === 1) {
          setLoading(false);
          setThrowError(true);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        } else {
          setLoading(false);
          setThrowError(true);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const assign = () => {
    if (
      customer?.customerId &&
      assignedCustomers.every((obj) => obj?.customer_id !== customer.customerId)
    ) {
      setAssignedCustomers([
        ...assignedCustomers,
        {
          number: customer.customer.split(" - ")[0],
          customer_id: customer.customerId,
          local: local
        }
      ]);
      setCustomer({});
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const results = await Promise.all(
        assignedCustomers
          .filter((obj) => obj?.local === local)
          ?.map(async (item) => {
            // Perform API call for each item in the array
            const reqBody = {
              main_district_id: currentBranch?.value,
              label_template_id: labelId,
              customer_id: item.customer_id
            };
            const assignCustomers = await getResponseData(
              reqBody,
              CF_URLS.labelTemplateMaintanence.assignCustomers,
              1
            );
            if (assignCustomers.data[0][0].label_template_id) {
              setLoading(false);
              setAssignedCustomers(assignCustomers.data[0]);
              onClose();
              setThrowError(false);
              setThrowErrorMessage(null);
              setCustomer("");
            } else if (assignCustomers.data[0][0].error) {
              setLoading(false);
              const errdescr = await getMessageFromCode(
                assignCustomers.data[0][0]?.error
              );
              const errMsg = errdescr[0]?.descr;
              setThrowError(true);
              setThrowErrorMessage(errMsg);
            } else if (assignCustomers.data[0]?.error === 1) {
              setLoading(false);
              setThrowError(true);
              setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            } else {
              setLoading(false);
              setThrowError(true);
              setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            }
            return item;
          })
      );
      return results;
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  return (
    <CommonModal
      open={isAssignModalOpen}
      title={t("labelTemplateMaintenance.assignedCustomers")}
      body={
        <>
          {loading && <CircularLoaderFullPage loading={loading} />}
          {throwError && (
            <Collapse in={throwError}>
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Collapse>
          )}

          <Card sx={labelTemplateMaintanceStyles.assignTransportsCard}>
            <Box pb={2} sx={labelTemplateMaintanceStyles.assignTransportsBox}>
              <CustomersField
                options={customers}
                value={customer.customer}
                handleOnChange={handleChange}
              />
              <Button
                sx={labelTemplateMaintanceStyles.assignTransportsAddButton}
                onClick={() => assign()}
              >
                {t("labelTemplateMaintenance.assign")}
              </Button>
            </Box>

            <div style={labelTemplateMaintanceStyles.tableDiv}>
              <ThemeProvider theme={commonDataTableTheme}>
                <MUIDataTable
                  columns={columns}
                  options={options}
                  data={assignedCustomers?.sort((a, b) => {
                    // Trim whitespace and compare the numbers
                    return a.number.trim().localeCompare(b.number.trim());
                  })}
                />
              </ThemeProvider>
            </div>
          </Card>
        </>
      }
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              const cancelSelectedCust = [
                ...assignedCustomers.filter((rem) => rem?.local !== local)
              ];
              setAssignedCustomers(cancelSelectedCust);
              setCustomer("");
              onClose();
              setThrowError(false);
              setThrowErrorMessage(null);
            }}
          >
            {t("common.close")}
          </Button>
          <Button
            variant="contained"
            disabled={
              assignedCustomers?.filter((obj) => obj?.local === local)
                .length === 0
            }
            onClick={() => {
              handleSubmit();
            }}
          >
            {t("common.ok")}
          </Button>
        </>
      }
    />
  );
};
export default LabelAssignCustomerModal;
