import { useTranslation } from "react-i18next";
import { useGetAllBillCycleQuery } from "services/api";
import SelectField from "../select-field/SelectField";

const BillCycleField = ({
  cycleOptions,
  selectedValue,
  handleOnChange,
  disabledField,
  filterParams,
  ...props
}) => {
  const { t } = useTranslation();
  const { data: billCycles = [] } = useGetAllBillCycleQuery();

  const options =
    filterParams?.length > 0
      ? billCycles?.filter((cycle) =>
          filterParams?.includes(cycle?.invoiceDateTypeId)
        )
      : billCycles;

  return (
    <SelectField
      options={cycleOptions ? cycleOptions : options}
      labelId="billCycles"
      label={t("common.commonBillCycleLabel")}
      value={selectedValue}
      onChange={handleOnChange}
      disabled={disabledField}
      {...props}
    />
  );
};

export default BillCycleField;
