import { ChevronLeft, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TableContainer,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { FullCircularLoader } from "components/core";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { getAllCustomers } from "services/common";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  NO,
  unauthorized,
  Y_CHECKER,
  YES
} from "utils/constants";
import { getPreferenceTypesPayloadForReport } from "utils/constants/customer-information/CustomerPreference";
import {
  dataIndex,
  hyphen,
  padStartLength,
  preferenceTypeId
} from "utils/constants/customer-information/PlaceHoldOnMediaDestructionRequests";
import { formatDateByLocale, getResponseData } from "utils/helpers";
import PreferencesLayout from "../preferences-main/PreferencesLayout";
import PreferencesReoprtDownloadModal from "../PreferencesReoprtDownloadModal";
import { PreferencesStyles } from "../PreferencesStyles";

const PlaceHoldOnMediaDestructionRequests = () => {
  const { localeByBranch } = useSelector(selectAuth);
  const { state = {} } = useLocation();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [preference, setPreference] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isDownLoadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const { defaultAuthBranchValues } = useSelector(
    (state) => state.customerPreferences
  );

  const customer_id = state?.customer_id;

  let dataFromStore =
    defaultAuthBranchValues?.prefenceFilteredTableData?.find(
      (p) => p.preference_type_id === preferenceTypeId
    ) || {};

  let hasStoreData = Object.keys(dataFromStore).length;
  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setErrorMessage(t("common.systemErrorMessage"));
    }
  };

  // Handle error with error code
  const handleError = async (errorCode, replaceText) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);

    if (errorMsg) {
      if (replaceText) {
        setErrorMessage(errorMsg?.replace("|", replaceText));
      } else {
        setErrorMessage(errorMsg);
      }
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  let columns = [
    {
      label: t("preferences.dateLabel"),
      name: dataIndex.changeHistoryDatetime
    },
    {
      label: t("preferences.authorizedByLabel"),
      name: dataIndex.authorizedBy
    },
    {
      label: t("preferences.previousValueLabel"),
      name: dataIndex.oldValue
    },
    {
      label: t("preferences.newValueLabel"),
      name: dataIndex.newValue
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: true,
    textLabels: {
      body: {
        noMatch: (
          <Box pb={spacing.gap}>
            <Typography style={PreferencesStyles.noDataText}>
              {t("common.noRecordsFound")}
            </Typography>
          </Box>
        )
      }
    }
  };

  useEffect(() => {
    getSelectedCustomer();
    if (hasStoreData) {
      saveDataToState(dataFromStore);
    } else {
      getPreferences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedCustomer = async () => {
    try {
      setLoading(true);
      const customers = await getAllCustomers();
      let customer =
        customers.find((customer) => customer.customer_id === customer_id) ||
        {};
      setCustomer(customer);
    } catch (error) {
      handleCatchError(error);
    } finally {
      if (hasStoreData) {
        setLoading(false);
      }
    }
  };

  const getPreferences = async () => {
    try {
      setLoading(true);
      const res = await getResponseData(
        { main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS, customer_id },
        CF_URLS.customerPreferences.getPreferenceValues,
        4
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      saveDataToState(data[2]);
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  const saveDataToState = (preference) => {
    if (Object.keys(preference).length) setPreference(preference);
  };
  const onChangeAccordionExpand = (e, expanded) => {
    if (!expanded || tableData.length) return;
    getPreferencesHistory();
  };

  const getPreferencesHistory = async () => {
    setLoading(true);
    try {
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: customer_id,
        preference_type_id_list: preferenceTypeId.padStart(padStartLength, " ")
      };

      //eslint-disable-next-line
      const res = await getResponseData(
        reqBody,
        CF_URLS.customerPreferences.getCustomerPreferenceHistory,
        2
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      let parent = data[0];
      let child = data[1];

      const combinedData = parent
        .map((item1) => {
          const matchedItem = child.find(
            (item2) => item2.change_history_id === item1.change_history_id
          );
          return {
            ...item1,
            ...matchedItem
          };
        })
        .sort((a, b) => {
          return dayjs(b.change_history_datetime).isAfter(
            dayjs(a.change_history_datetime)
          )
            ? 1
            : -1;
        });
      const promises = combinedData.map(async (item) => {
        const formattedDate = await formatDateByLocale(
          dayjs(item.change_history_datetime).utc(),
          localeByBranch || DEFAULT_LOCALE,
          true,
          true
        );

        return {
          change_history_id: item.change_history_id,
          change_history_datetime: formattedDate,
          authorized_By: `${item.personnel_last_name}, ${item.personnel_first_name}`,
          old_value: item.old_value === Y_CHECKER ? YES : NO,
          new_value: item.new_value === Y_CHECKER ? YES : NO
        };
      });

      // Wait for all promises to resolve and then set the table data
      Promise.all(promises).then((resolvedData) => {
        setTableData(resolvedData);
      });
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  const getSettingsValue = () => {
    if (!preference.preference_value) return hyphen;
    if (preference.preference_value === Y_CHECKER) {
      return YES;
    }
    return NO;
  };

  const handleDownloadOpen = () => {
    setIsDownloadModalOpen(true);
  };

  const topTextFieldReportsPayload = [
    {
      key: t("preferences.customerPreferenceTypes21"),
      value: getSettingsValue()
    }
  ];

  const filteredPreferenceTypes = useMemo(() => {
    return getPreferenceTypesPayloadForReport().filter(
      (type) => preferenceTypeId === type.preference_type_id
    );
  }, []);

  return (
    <PreferencesLayout>
      <Stack
        direction="column"
        gap={spacing.verticalMargin20}
        mb={spacing.verticalMargin32}
        mt={spacing.verticalMargin16}
      >
        {loading && <FullCircularLoader />}

        {errorMessage && (
          <Box mb={spacing.gap}>
            <Alert
              severity="error"
              sx={PreferencesStyles.errorAlert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setErrorMessage(false)}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Box>
        )}

        <Grid container spacing={spacing.horizontalMargin20}>
          <Grid item xs={12}>
            <Box mb={spacing.verticalMargin20}>
              <Typography variant="subtitle1" color="textSecondary">
                {t("common.customer")}
              </Typography>
              <Typography variant="subtitle1">
                {customer?.number || hyphen}
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={PreferencesStyles.titleStyle}>
              {t("preferences.placeHoldRequests")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box mb={spacing.verticalMargin20}>
              <Typography variant="subtitle1" color="textSecondary">
                {t("preferences.settings")}
              </Typography>
              <Typography variant="subtitle1">{getSettingsValue()}</Typography>
            </Box>
            <Divider />
          </Grid>
        </Grid>
        <Accordion
          sx={PreferencesStyles.accordian}
          onChange={onChangeAccordionExpand}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              variant="subtitle1"
              style={PreferencesStyles.subTitleStyle}
            >
              {t("preferences.history")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer sx={PreferencesStyles.tableContainer}>
              <MUIDataTable
                columns={columns}
                options={options}
                data={tableData}
              />
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Divider />
        <Stack mt={spacing.gap} gap={spacing.gap} direction="row">
          <Button
            variant="outlined"
            sx={PreferencesStyles.backButton}
            onClick={goBack}
          >
            <ChevronLeft /> {t("common.back")}
          </Button>

          <Button variant="outlined" onClick={handleDownloadOpen}>
            {t("common.print")}
          </Button>
        </Stack>
      </Stack>

      <PreferencesReoprtDownloadModal
        title={t("common.downloadReport")}
        showPrintModal={isDownLoadModalOpen}
        setCloseModal={setIsDownloadModalOpen}
        setIsLoading={setLoading}
        customerId={customer_id}
        preferenceTypeId={preferenceTypeId.padStart(padStartLength, " ")}
        url={
          CF_URLS.printReports.customerInformation
            .placeholdOnMediaDestructionRequestsReport
        }
        topTextFieldReportsPayload={topTextFieldReportsPayload}
        preferenceTypesPayloadForReport={filteredPreferenceTypes}
      />
    </PreferencesLayout>
  );
};

export default PlaceHoldOnMediaDestructionRequests;
