import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  useGetLockTypesQuery,
  useGetLogicalVaultsMutation,
  useGetMediaTypesMutation,
  useLoadMediaRequestIssueContainersMutation
} from "services/api";
import {
  removeErrorMessage,
  selectMediaRequest,
  setActionId,
  setContainerLogicalVaults,
  setContainerMediaTypes,
  setErrorMessage,
  setHaveChanges,
  setIssueContainersOrTransportsAndInserts,
  setNextRequestNewItemType,
  setRequestNewItemType,
  setShowConfirmationModal,
  setTransportLogicalVaults,
  setTransportMediaTypes
} from "store/slices";
import {
  MEDIA_PROGRAM_TYPES,
  N_CHECKER,
  REQUEST_ACTIONS,
  REQUEST_NEW_ITEM_TYPE,
  Y_CHECKER
} from "utils/constants";
import { canExitTabOrChangeIssueType } from "../utils";
import { IssueContainersStyles } from "./IssueContainersTab.Styles";
import EmptyTransports from "./empty-transports/EmptyTransports";
import ExtraInserts from "./extra-inserts/ExtraInserts";
import IssueClosedContainers from "./issue-closed-containers/IssueClosedContainers";
import IssuePermanentTransports from "./issue-permanent-transports/IssuePermanentTransports";

const IssueContainersTab = (props) => {
  const { t } = useTranslation();
  const mediaRequestState = useSelector(selectMediaRequest); // select media request slice
  const dispatch = useDispatch(); // redux actions dispatcher
  const [optionDirty, setOptionDirty] = useState(false); // child component form dirty status
  const requestNewItemType = useMemo(() => {
    setOptionDirty((prevState) => false);
    return Number(mediaRequestState.issueContainers.requestNewItemType);
  }, [mediaRequestState.issueContainers?.requestNewItemType]); // request item type

  const [
    loadContainers,
    {
      data: containersOrTransports,
      isLoading,
      isError: isContainersError,
      error: containersError
    }
  ] = useLoadMediaRequestIssueContainersMutation({
    selectFromResult: ({ data, isError, ...rest }) => ({
      data: data ? [].concat(data) : [],
      isError,
      ...rest
    })
  }); // load containers, transports, inserts & locks of a request
  const [
    getLogicalVaults,
    {
      isLoading: isLogicalVaultsLoading,
      isError: isLogicalVaultsError,
      error: logicalVaultsError
    }
  ] = useGetLogicalVaultsMutation({
    selectFromResult: ({ data, isError, ...rest }) => ({
      data: data ? [].concat(data) : [],
      isError,
      ...rest
    })
  }); // fetch logical vaults
  const [
    getMediaTypes,
    {
      isLoading: isMediaTypesLoading,
      isError: isMediaTypesError,
      error: mediaTypesError
    }
  ] = useGetMediaTypesMutation({
    selectFromResult: ({ data, isError, ...rest }) => ({
      data: data ? [].concat(data) : [],
      isError,
      ...rest
    })
  }); // fetch media types
  const {
    data: lockTypes = [],
    isLoading: isLockTypesLoading,
    isError: isLockTypesError,
    error: lockTypesError
  } = useGetLockTypesQuery(
    {
      customerId: props.customerId
    },
    {
      selectFromResult: ({ data, isError, ...rest }) => ({
        data: data ? [].concat(data) : [],
        isError,
        ...rest
      })
    }
  ); // fetch lock types

  // fetch containers/locks/inserts of the media request if a request_id passed as a prop
  useEffect(() => {
    if (props?.requestId) {
      loadContainers(props.requestId)
        .unwrap()
        .then((resp) =>
          dispatch(setIssueContainersOrTransportsAndInserts(resp))
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.requestId]);

  // Based on the selected request action logical vaults and/or media types will change
  useEffect(() => {
    if (!props.requestId || mediaRequestState.refetch) {
      if (
        mediaRequestState.actionId ===
        REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID
      ) {
        // logical vaults not in redux
        if (
          mediaRequestState.issueContainers.logicalVaults.container.length === 0
        ) {
          getLogicalVaults({
            customerId: props.customerId,
            mediaProgramType: MEDIA_PROGRAM_TYPES.CONTAINER.TYPE
          }).then((resp) => dispatch(setContainerLogicalVaults(resp.data)));
        }

        // media types not in redux
        if (
          mediaRequestState.issueContainers.mediaTypes.container.length === 0
        ) {
          getMediaTypes({
            customerId: props.customerId,
            mediaProgramTypeId: MEDIA_PROGRAM_TYPES.CONTAINER.ID,
            permanentIssueFlag: N_CHECKER
          }).then((resp) => dispatch(setContainerMediaTypes(resp.data)));
        }
      } else if (
        mediaRequestState.actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID
      ) {
        // logical vaults not in redux
        if (
          mediaRequestState.issueContainers.logicalVaults.transport.length === 0
        ) {
          getLogicalVaults({
            customerId: props.customerId,
            mediaProgramType: MEDIA_PROGRAM_TYPES.TRANSPORT.TYPE
          }).then((resp) => dispatch(setTransportLogicalVaults(resp.data)));
        }

        // media types not in redux
        if (
          mediaRequestState.issueContainers.mediaTypes.transport.length === 0
        ) {
          getMediaTypes({
            customerId: props.customerId,
            mediaProgramTypeId: MEDIA_PROGRAM_TYPES.TRANSPORT.ID,
            permanentIssueFlag: Y_CHECKER
          }).then((resp) => dispatch(setTransportMediaTypes(resp.data)));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mediaRequestState.refetch,
    mediaRequestState.actionId,
    mediaRequestState.issueContainers.requestNewItemType,
    props.customerId
  ]);

  // set error message in redux if one occurs
  useEffect(() => {
    let message = "";
    if (isContainersError) {
      if (mediaRequestState.actionId === REQUEST_NEW_ITEM_TYPE.CONTAINER) {
        message = `${t("mediaRequestDetail.errorInIssueClosedContainers")} ${
          containersError.message
        }`;
      } else if (
        requestNewItemType === REQUEST_NEW_ITEM_TYPE.TRANSPORT_PERMANENT
      ) {
        message = `${t("mediaRequestDetail.errorInEmptyTransports")} ${
          containersError.message
        }`;
      } else if (
        mediaRequestState.actionId === REQUEST_NEW_ITEM_TYPE.TRANSPORT_EMPTY
      ) {
        message = `${t("mediaRequestDetail.errorInPermanentTransports")} ${
          containersError.message
        }`;
      }
    } else if (isLogicalVaultsError) {
      message = `${t("mediaRequestDetail.errorInPermanentTransports")} ${
        logicalVaultsError.message
      }`;
    } else if (isMediaTypesError) {
      message = `${t("mediaRequestDetail.errorInMediaTypes")} ${
        mediaTypesError.message
      }`;
    } else if (isLockTypesError) {
      message = `${t("mediaRequestDetail.errorInLockTypes")} ${
        lockTypesError.message
      }`;
    }

    // if message has set error message to redux, if not removed error message from redux
    if (message) {
      dispatch(setErrorMessage(message));
    } else {
      dispatch(removeErrorMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isContainersError,
    isLogicalVaultsError,
    isMediaTypesError,
    isLockTypesError
  ]);

  // issue new item type onChange event handler
  const handleRequestNewItemTypeOnChange = (e) => {
    props.onValidate((prevState) => false);
    if (optionDirty) {
      props.onValidate((prevState) => true);
    } else {
      props.onValidate((prevState) => false);
      if (!mediaRequestState.hasLockedTab) {
        const newItemType = Number(e.target.value);
        // check whether the tab or issue type can be changed
        if (
          canExitTabOrChangeIssueType(mediaRequestState, props.extraItemsForm)
        ) {
          dispatch(setRequestNewItemType(newItemType));
          dispatch(setNextRequestNewItemType(-1));
          props.extraItemsForm.resetForm();
          if (newItemType === REQUEST_NEW_ITEM_TYPE.CONTAINER) {
            dispatch(
              setActionId(REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID)
            );
          } else {
            dispatch(setActionId(REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID));
          }
        } else {
          dispatch(setHaveChanges(true));
          dispatch(setShowConfirmationModal(true));
          dispatch(setNextRequestNewItemType(newItemType));
        }
      }
    }
  };

  return (
    <Grid id="issueContainersLocksTabGrid1" container rowSpacing={"2rem"}>
      {/* Containers */}
      <Grid id="issueContainersLocksTabGrid2" item xs={12}>
        <Card id="issueContainersLocksTabCard1">
          {requestNewItemType === REQUEST_NEW_ITEM_TYPE.CONTAINER ? (
            <CardHeader
              id="issueContainersLocksTabCardHeader1"
              title={t("mediaRequestDetail.containers")}
            />
          ) : requestNewItemType ===
            REQUEST_NEW_ITEM_TYPE.TRANSPORT_PERMANENT ? (
            <CardHeader
              id="issueContainersLocksTabCardHeader1"
              title={t("mediaRequestDetail.transports")}
            />
          ) : (
            <CardHeader
              id="issueContainersLocksTabCardHeader1"
              title={t("mediaRequestDetail.emptyTransports")}
            />
          )}

          <CardContent
            id="issueContainersLocksTabCardContent1"
            sx={IssueContainersStyles.CardContent}
          >
            {/* Radio buttons */}
            <Box
              id="issueContainersLocksTabBox1"
              sx={IssueContainersStyles.FlexRowStart}
            >
              {/* Issue Containers/ transports action selector */}
              <RadioGroup
                id="issueContainersLocksTabOptions"
                row
                aria-labelledby="issue-container-radio-button-group"
                value={requestNewItemType}
                onChange={handleRequestNewItemTypeOnChange}
                sx={IssueContainersStyles.RadioGroup}
              >
                <FormControlLabel
                  id="issueContainersLocksTabClosedContainersOption"
                  disabled={Boolean(props?.requestId)}
                  value={REQUEST_NEW_ITEM_TYPE.CONTAINER}
                  control={<Radio />}
                  label={t("mediaRequestDetail.issueContainersOption1")}
                />
                <FormControlLabel
                  id="issueContainersLocksTabPermanentTransportsOption"
                  disabled={Boolean(props?.requestId)}
                  value={REQUEST_NEW_ITEM_TYPE.TRANSPORT_PERMANENT}
                  control={<Radio />}
                  label={t("mediaRequestDetail.issueContainersOption2")}
                />
                <FormControlLabel
                  id="issueContainersLocksTabEmptyTransportsOption"
                  disabled={Boolean(props?.requestId)}
                  value={REQUEST_NEW_ITEM_TYPE.TRANSPORT_EMPTY}
                  control={<Radio />}
                  label={t("mediaRequestDetail.issueContainersOption3")}
                />
              </RadioGroup>
            </Box>

            {/* issue closed containers */}
            {requestNewItemType === REQUEST_NEW_ITEM_TYPE.CONTAINER && (
              <IssueClosedContainers
                requestId={props.requestId}
                isLoading={isLoading}
                onFormDirty={setOptionDirty}
                startValidation={props.validate}
                customerId={props.customerId}
                logicalVaultsLoading={isLogicalVaultsLoading}
                mediaTypesLoading={isMediaTypesLoading}
                lockTypes={lockTypes}
                lockTypesLoading={isLockTypesLoading}
                defaultLockType={props.defaultLockType}
              />
            )}

            {/* issue permanent transports */}
            {requestNewItemType ===
              REQUEST_NEW_ITEM_TYPE.TRANSPORT_PERMANENT && (
              <IssuePermanentTransports
                requestId={props.requestId}
                isLoading={isLoading}
                onFormDirty={setOptionDirty}
                startValidation={props.validate}
                customerId={props.customerId}
                logicalVaultsLoading={isLogicalVaultsLoading}
                mediaTypesLoading={isMediaTypesLoading}
              />
            )}

            {/* deliver empty permanent or temporary transport */}
            {requestNewItemType === REQUEST_NEW_ITEM_TYPE.TRANSPORT_EMPTY && (
              <EmptyTransports
                requestId={props.requestId}
                isLoading={isLoading}
                onFormDirty={setOptionDirty}
                startValidation={props.validate}
                mediaTypesLoading={isMediaTypesLoading}
              />
            )}
          </CardContent>
        </Card>
      </Grid>

      {/* Extra Inserts */}
      {requestNewItemType === REQUEST_NEW_ITEM_TYPE.CONTAINER && (
        <ExtraInserts
          requestId={props.requestId}
          inserts={containersOrTransports?.extraInserts}
          isLoading={isLoading}
          onFormDirty={setOptionDirty}
          startValidation={props.validate}
          lockTypes={lockTypes}
          lockTypesLoading={isLockTypesLoading}
          extraItemsForm={props.extraItemsForm}
        />
      )}
    </Grid>
  );
};

// Props definition of the component
IssueContainersTab.propTypes = {
  customerId: PropTypes.number.isRequired, // ID of the customer
  requestId: PropTypes.number, // ID of the media request
  extraItemsForm: PropTypes.object, // formik instance for the extra locks and clips
  validate: PropTypes.bool, // validation needs state
  onValidate: PropTypes.func, // validation needs change event
  defaultLockType: PropTypes.string // default lock type code
};

export default IssueContainersTab;
