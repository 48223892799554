import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";

import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  SERVICE_INFORMATION,
  SERVICE_WINDOWS
} from "routing/Paths";
import { useGetAllCustomersQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { getDayOfWeekCollection, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import { setViewModeId } from "store/slices/customer-schedule";
import spacing from "styles/spacing";
import {
  A_CHECKER,
  ERROR_TYPES,
  N_CHECKER,
  Y_CHECKER,
  dayMintMonthYearFormat,
  dayMonthYearFormat,
  downloadReportOptions,
  errorMsgs,
  maximumDate,
  numberConstants,
  ymdDateFormat
} from "utils/constants";
import {
  attributeTypeId,
  buttonList,
  dayIndex,
  dayItem,
  dayName,
  daysPerWeek,
  everyOtherWeek,
  fieldName,
  initialValues,
  listOfDates,
  mainDistrictId,
  monthItem,
  monthlyByDay,
  monthlyByWeek,
  onCall,
  reg,
  serviceFrequencyList,
  severityError,
  severityWarning,
  skipDay,
  systemId,
  validationError,
  validationError2,
  weekOfMonthList,
  yearItem,
  yearMonthFormat
} from "utils/constants/customer-service-information/CustomerScheduleConst";
import {
  findErrorMessage,
  getGlobalAttributeValue,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import CalenderUi from "./CalenderUi";
import { customerScheduleStyle } from "./CustomerScheduleStyles";
import MultipleCustomerSelectModal from "./MultipleCustomerSelectModal";
import {
  customerButtonStyles,
  customerGridStyles
} from "./MultipleCustomerSelectModalStyle";

//today date state
const today = dayjs();
//max date state
const maxDate = dayjs(maximumDate);

const CustomerScheduleLayout = () => {
  const { navigationData } = useParams();
  const prevCustomersRef = useRef([]);

  const formDetails = useFormik({
    initialValues: initialValues
  });
  const { currentBranch, auth } = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { viewModeId } = useSelector(
    ({ customerSchedule }) => customerSchedule
  );
  const { localeByBranch } = useSelector(selectAuth);

  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });

  const { values, setFieldValue, resetForm } = formDetails;

  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [isDownload, setIsDownload] = useState(false);
  const [severity, setSeverity] = useState(severityError);
  const [isLoadingValue, setIsLoading] = useState(false);
  const [selectedDateList, setSelectedDateList] = useState([]);
  const [weekDayListArray, setWeekDayList] = useState([]);
  const [buttonListArray, setButtonList] = useState([...buttonList]);
  const [serviceFrequencyValue, setServiceFrequencyValue] = useState(null);
  const [customerTempValue, setCustomerTempValue] = useState(false);
  const [savePopup, setSavePopup] = useState({ first: false, second: false });
  const [originalDataSet, setOriginalDataSet] = useState({});
  const [dayDataTemp, setDayDataTemp] = useState(null);
  const [dayWeekDataTemp, setWeekDayDataTemp] = useState(null);
  const [weekDataTemp, setWeekDataTemp] = useState(null);
  const [dayTemp, setDayTemp] = useState(null);
  const [serviceFrequencyChanges, setServiceFrequencyChanges] = useState(false);
  const [temCustomer, setTempCustomer] = useState(null);
  const [endGenDate, setEndGenDate] = useState(null);
  const [activeFiledPopup, setActiveFieldPopup] = useState({
    cslPopup: false,
    svcPopup: false,
    serviceFrequency: false,
    rotationScheduleExists: false,
    dayFieldPopup: false,
    dayFieldPopup2: false,
    weekFieldPopup: false,
    weekFieldPopup2: false,
    weekDayFieldPopup: false,
    weekDayFieldPopup2: false,
    dayFieldPopupCheckBox: false,
    dayFieldPopupCheckBox2: false,
    businessDayPopup: false,
    businessDayPopup2: false,
    savePopup: false,
    savePopup2: false,
    savePopup3: false,
    savePopup4: false,
    customerPopup: false,
    dateListPopup1: false,
    dateListPopup2: false
  });

  const [cancelPopup, setCancelPopup] = useState({
    modalPopupOpen: false,
    showOk: false,
    showCancel: false,
    showNo: false,
    message: ""
  });
  const [filteredCustomersChecked, setFilteredCustomersChecked] =
    useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [regenerateCustomers, setRegenerateCustomers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [regenerateModalOpen, setRegenerateModalOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    throwErrorMessage && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [throwErrorMessage]);

  useEffect(() => {
    if (
      selectedDateList?.length &&
      values?.serviceFrequency === listOfDates &&
      buttonListArray[4]?.disabled &&
      (serviceFrequencyChanges || originalDataSet?.comment !== values?.comment)
    ) {
      buttonListArray[4].disabled = false;
      setButtonList([...buttonListArray]);
    } else if (
      !selectedDateList?.length &&
      values?.serviceFrequency !== onCall
    ) {
      buttonListArray[4].disabled = true;
      setButtonList([...buttonListArray]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateList]);

  useEffect(() => {
    if (viewModeId) {
      handleCustomer(viewModeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewModeId]);

  const handleSystemError = () => {
    setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    setSeverity(severityError);
    setIsLoading(false);
  };

  const handleCustomer = async (value) => {
    if (!value) {
      resetForm();
      setFieldValue(fieldName?.customerField, value);
      setIsLoading(false);
      handleCancelPopup();
      setCustomerTempValue(null);
      setButtonList([...buttonList]);
      setSelectedDateList([]);
      return;
    }
    try {
      setIsLoading(true);
      const response = await getResponseData(
        JSON.stringify({
          customer_id: value?.value,
          main_district_id: mainDistrictId
        }),
        `${CF_URLS.customerInformationSchedule.customerLocationRowCount}`,
        2
      );
      const lastGenDate = await getGlobalAttributeValue(
        currentBranch?.value,
        systemId,
        attributeTypeId
      );
      if (lastGenDate) {
        setEndGenDate(lastGenDate);
      }
      resetForm();
      if (response?.data[0][0]?.count === numberConstants?.zero) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode20407);
        setButtonList([...buttonList]);
        setActiveFieldPopup({ ...activeFiledPopup, customerPopup: true });
        setCancelPopup({
          modalPopupOpen: true,
          showOk: true,
          showCancel: false,
          message: errorMsg[0]?.descr,
          showNo: false
        });
        setTempCustomer(value?.value);
      } else {
        setFieldValue(fieldName?.customerField, value);
        setCustomerTempValue(null);
        setServiceFrequencyChanges(false);
        const [responseCustomer, responseSchedule, responseCustomerIdExist] =
          await Promise.allSettled([
            getResponseData(
              JSON.stringify({
                customer_id: value?.value,
                main_district_id: currentBranch?.value
              }),
              `${CF_URLS.customerInformationSchedule.rotationScheduleExistsForCustomerId}`,
              2
            ),
            getResponseData(
              JSON.stringify({
                customer_id: value?.value,
                main_district_id: currentBranch?.value
              }),
              `${CF_URLS.customerInformationSchedule.allRotationSchedulesNames}`,
              2
            ),
            getResponseData(
              JSON.stringify({
                customer_id: value?.value,
                main_district_id: currentBranch?.value
              }),
              `${CF_URLS.customerInformationSchedule.isCustomerIdExist}`,
              2
            )
          ]);

        if (responseCustomer?.value?.data[0][0]?.exists === Y_CHECKER) {
          setFieldValue(fieldName?.rotationScheduleExists, true);
        } else {
          setFieldValue(fieldName?.rotationScheduleExists, false);
        }
        if (
          responseSchedule?.value?.data[0][0]?.count > numberConstants?.zero
        ) {
          setFieldValue(fieldName?.mbDayOfNthWeekRSExist, true);
        } else {
          setFieldValue(fieldName?.mbDayOfNthWeekRSExist, false);
        }
        if (responseCustomerIdExist?.value?.data[0][0]?.exists === N_CHECKER) {
          setFieldValue(fieldName?.clsField, false);
          setFieldValue(fieldName?.serviceFrequency, daysPerWeek);
          buttonList[1].disabled = true;
          buttonList[2].disabled = true;
          setSelectedDateList([]);
        } else if (
          responseCustomerIdExist?.value?.data[0][0]?.exists === Y_CHECKER
        ) {
          let selectedDateListItem = [];
          const [response, res, lastDate] = await Promise.allSettled([
            getResponseData(
              JSON.stringify({
                customer_id: value?.value,
                main_district_id: currentBranch?.value
              }),
              `${CF_URLS.customerInformationSchedule.customerServiceFrequencyInformation}`,
              2
            ),
            getResponseData(
              JSON.stringify({
                customer_id: value?.value,
                main_district_id: currentBranch?.value
              }),
              `${CF_URLS.customerInformationSchedule.customeridValidRequsets}`,
              2
            ),
            getResponseData(
              JSON.stringify({
                main_district_id: currentBranch?.value
              }),
              `${CF_URLS.customerInformationSchedule.lastGenrunDate}`,
              2
            )
          ]);
          if (
            res?.value?.data[0][0]?.need_to_prompt_user === numberConstants.zero
          ) {
            setFieldValue(fieldName?.bSvcTripsExist, true);
          }
          if (response?.value?.data[0][0]) {
            const {
              comment,
              csl_flag,
              generated_date_start,
              generated_date_end,
              service_frequency_code,
              day_of_week_code,
              week_of_month_code,
              day_of_month,
              on_business_day,
              timestamp
            } = response?.value?.data[0][0] || {};
            setOriginalDataSet(response?.value?.data[0][0]);
            buttonList[3].disabled = true;
            if (service_frequency_code.trim() !== onCall) {
              response?.value?.data[1]?.forEach((item) => {
                if (
                  dayjs(
                    lastDate?.value?.data[0][0]?.last_gen_run_date
                  ).isBefore(item?.service_date) &&
                  dayjs(generated_date_start).isBefore(item?.service_date)
                ) {
                  selectedDateListItem.push(
                    dayjs.utc(item?.service_date).format(dayMonthYearFormat)
                  );
                }
              });
            }
            setSelectedDateList([...selectedDateListItem]);
            setFieldValue(fieldName?.comment, comment);
            setFieldValue(
              fieldName?.lastDate,
              dayjs.utc(lastDate?.value?.data[0][0]?.last_gen_run_date)
            );
            setFieldValue(fieldName?.clsField, csl_flag === Y_CHECKER);
            if (service_frequency_code.trim() === onCall) {
              setFieldValue(
                fieldName?.from,
                dayjs.utc(lastDate?.value?.data[0][0]?.last_gen_run_date)
              );
            } else {
              setFieldValue(fieldName?.from, dayjs.utc(generated_date_start));
            }
            setFieldValue(fieldName?.endDate, dayjs.utc(generated_date_end));
            setFieldValue(fieldName?.timestamp, timestamp);
            setFieldValue(
              fieldName?.serviceFrequency,
              service_frequency_code.trim()
            );
            if (
              service_frequency_code.trim() === daysPerWeek ||
              service_frequency_code.trim() === everyOtherWeek
            ) {
              let temp = response?.value?.data[0][0];
              let dayList = [];
              for (let key in dayIndex) {
                if (temp[key] !== numberConstants.zero) {
                  dayList.push(dayIndex[key]);
                }
              }
              getWeekOfDayList();
              setFieldValue(fieldName.weekDayList, dayList);
            }
            if (service_frequency_code.trim() === monthlyByWeek) {
              getWeekOfDayList();
              setFieldValue(fieldName.weekDay, day_of_week_code);
              setFieldValue(fieldName.week, week_of_month_code);
            }
            if (service_frequency_code.trim() === monthlyByDay) {
              setFieldValue(fieldName.day, day_of_month);
              setFieldValue(
                fieldName.businessDay,
                on_business_day !== numberConstants.zero
              );
            }
            if (service_frequency_code.trim() === onCall) {
              setFieldValue(fieldName.onCallCustomer, true);
              buttonList[2].disabled = true;
            } else {
              buttonList[2].disabled = false;
            }
            if (
              service_frequency_code.trim() !== onCall &&
              !selectedDateListItem?.length
            ) {
              const errorMsg = await getMessageFromCode(
                errorMsgs.errorCode20286
              );
              setCancelPopup({
                modalPopupOpen: true,
                showOk: true,
                showCancel: false,
                message: errorMsg[0]?.descr,
                showNo: false
              });
            }
            if (
              service_frequency_code.trim() !== onCall &&
              response?.value?.data[1].length &&
              !selectedDateListItem?.length
            ) {
              buttonList[3].disabled = false;
            }
            if (service_frequency_code.trim() === listOfDates) {
              buttonList[3].disabled = false;
            }
          }
        }
        dispatch(setViewModeId(null));
        buttonList[0].disabled = false;
        buttonList[4].disabled = true;
        buttonList[1].disabled = false;
        setButtonList([...buttonList]);
        setIsLoading(false);
      }
    } catch (err) {
      handleSystemError();
    }
    setIsLoading(false);
  };
  const handleCustomerChange = async (event, newValue) => {
    setIsLoading(true);
    if (
      selectedDateList.length &&
      (serviceFrequencyChanges || originalDataSet?.comment !== values?.comment)
    ) {
      setCustomerTempValue(newValue);
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode10039);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        showNo: true,
        message: errorMsg[0]?.descr
      });
      savePopup.first = true;
      setSavePopup({ ...savePopup });
      setIsLoading(false);
    } else {
      handleCustomer(newValue);
    }
  };

  const handleCancelPopup = () => {
    setCancelPopup({
      modalPopupOpen: false,
      showOk: false,
      showCancel: false,
      showNo: false,
      message: ""
    });
    setActiveFieldPopup({
      cslPopup: false,
      svcPopup: false,
      serviceFrequency: false,
      rotationScheduleExists: false,
      dayFieldPopup: false,
      dayFieldPopup2: false,
      weekFieldPopup: false,
      weekFieldPopup2: false,
      weekDayFieldPopup: false,
      weekDayFieldPopup2: false,
      dayFieldPopupCheckBox: false,
      dayFieldPopupCheckBox2: false,
      businessDayPopup: false,
      businessDayPopup2: false,
      savePopup: false,
      savePopup2: false,
      savePopup3: false,
      savePopup4: false,
      customerPopup: false,
      dateListPopup1: false,
      dateListPopup2: false
    });
    setCustomerTempValue(null);
  };

  const handleServiceFrequency = async (value) => {
    setIsLoading(true);
    if (selectedDateList?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setServiceFrequencyValue(value);
      setActiveFieldPopup({ ...activeFiledPopup, serviceFrequency: true });
    } else {
      handleServiceFrequencyAction(value);
      setActiveFieldPopup({ ...activeFiledPopup, serviceFrequency: false });
    }
    setIsLoading(false);
  };

  const handleServiceFrequencyAction = async (value) => {
    let data = value || serviceFrequencyValue;
    if (values?.mbDayOfNthWeekRSExist && data !== daysPerWeek) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20560);
      setThrowErrorMessage(errorMsg[0]?.descr);
      setSeverity(severityWarning);
      setFieldValue(fieldName.serviceFrequency, value);
      if (value === onCall) {
        buttonList[4].disabled = false;
      }
      buttonList[2].disabled = true;
      buttonList[3].disabled = true;
      setServiceFrequencyChanges(true);
      setButtonList([...buttonList]);
    } else if (
      originalDataSet?.serviceFrequency === onCall &&
      data !== onCall
    ) {
      if (values?.rotationScheduleExists) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80342);
        const formattedString = errorMsg[0]?.descr
          .split("|")
          .map((line, index) => (
            <>
              {line}
              <br />
            </>
          ));
        setCancelPopup({
          modalPopupOpen: true,
          showOk: true,
          showCancel: true,
          message: formattedString,
          showNo: false
        });
        setActiveFieldPopup({
          ...activeFiledPopup,
          rotationScheduleExists: true
        });
      }
    } else if (data === onCall) {
      setFieldValue(fieldName.clsField, false);
      setFieldValue(fieldName?.serviceFrequency, data);
      setFieldValue(fieldName.defaultValue, true);
      setFieldValue(fieldName.from, values?.lastDate.add(1, dayItem));
    } else {
      setFieldValue(fieldName?.serviceFrequency, data);
      setFieldValue(fieldName.from, values?.lastDate.add(1, dayItem));
      setServiceFrequencyValue(null);
      handleCancelPopup();
    }
    handleConfirm(data);
  };

  const getWeekOfDayList = async () => {
    try {
      setIsLoading(true);
      let data = await getDayOfWeekCollection();
      const sortingData = data?.sort(
        (a, b) => a.sequence_number - b.sequence_number
      );
      setWeekDayList([...sortingData.slice(1), sortingData[0]]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const handleGenerate = async (isTrue = false, obj, isCustomerIdExist) => {
    const {
      serviceFrequency,
      weekDayList,
      week,
      weekDay,
      day,
      businessDay,
      from,
      lastDate
    } = isTrue ? obj : values;
    setIsLoading(true);
    let last_gen_run_date = dayjs(lastDate).add(1, dayItem);
    const weekSkip = {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true
    };

    if ([onCall].includes(serviceFrequency)) {
      setIsLoading(false);
      return;
    } else if (listOfDates === serviceFrequency) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({
        ...activeFiledPopup,
        dateListPopup1: true
      });
      setIsLoading(false);
      return;
    }
    if (serviceFrequency === monthlyByDay) {
      if (day > 31) {
        setCancelPopup({
          modalPopupOpen: true,
          showOk: true,
          showCancel: false,
          message: validationError,
          showNo: false
        });
        setIsLoading(false);
        return;
      } else if (businessDay && day > 20) {
        setCancelPopup({
          modalPopupOpen: true,
          showOk: true,
          showCancel: false,
          message: validationError2,
          showNo: false
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(true);
    let currentDate = dayjs(last_gen_run_date); // Current date
    const endDate = currentDate.add(Number(endGenDate), yearItem); // Two years from now
    setFieldValue(fieldName.from, last_gen_run_date);
    let arr = [],
      weekNumber = null,
      dayOfWeek = null;
    if (serviceFrequency === monthlyByWeek) {
      currentDate = dayjs.utc(currentDate).startOf(monthItem);
      weekNumber = weekOfMonthList?.findIndex(
        (item) => item?.week_of_month_code === week
      );
      dayOfWeek = weekDayListArray?.find((item) => item?.id === weekDay);
    } else if (serviceFrequency === everyOtherWeek) {
      let day = Number(currentDate.get(dayItem)) + 1;
      if (weekDayList.includes(`${day}`)) {
        for (let i = 0; i < 8; i++) {
          if (day !== i) {
            weekSkip[i] = false;
          } else {
            weekSkip[i] = true;
          }
        }
      }
    }
    while (currentDate.isBefore(endDate)) {
      if (daysPerWeek === serviceFrequency) {
        let day = Number(currentDate.get(dayItem)) + 1;
        if (
          weekDayList?.includes(`${day}`) &&
          last_gen_run_date.isBefore(currentDate)
        ) {
          arr.push(currentDate.format(dayMonthYearFormat));
        }
        currentDate = currentDate.add(Number(numberConstants.one), dayItem);
      } else if (serviceFrequency === everyOtherWeek) {
        let day = "";
        day += Number(currentDate.get(dayItem)) + 1;
        if (
          weekSkip[day] &&
          weekDayList.includes(day) &&
          dayjs(last_gen_run_date).isSameOrBefore(dayjs(currentDate))
        ) {
          weekSkip[day] = false;
          arr.push(currentDate.format(dayMonthYearFormat));
        } else if (
          weekDayList.includes(day) &&
          dayjs(last_gen_run_date).isSameOrBefore(dayjs(currentDate))
        ) {
          weekSkip[day] = true;
        }
        currentDate = currentDate.add(Number(numberConstants.one), dayItem);
      } else if (serviceFrequency === monthlyByWeek) {
        let firstDayOfMonth = currentDate.startOf(monthItem);
        const endDayOfMonth = currentDate.endOf(monthItem);
        let temWeek = 0,
          temDay = null;
        while (firstDayOfMonth.isBefore(endDayOfMonth)) {
          let tem = Number(firstDayOfMonth.get(dayItem)) + 1;
          if (
            tem === Number(dayOfWeek?.sequence_number) &&
            weekNumber === temWeek &&
            dayjs(last_gen_run_date).isBefore(dayjs(firstDayOfMonth))
          ) {
            arr.push(firstDayOfMonth.format(dayMonthYearFormat));
            break;
          } else if (tem === Number(dayOfWeek?.sequence_number)) {
            if (tem === Number(dayOfWeek?.sequence_number)) {
              temDay = firstDayOfMonth.format(dayMonthYearFormat);
            }
            temWeek++;
          }
          firstDayOfMonth = firstDayOfMonth.add(
            Number(numberConstants.one),
            dayItem
          );
        }
        if (
          !firstDayOfMonth.isBefore(endDayOfMonth) &&
          weekNumber === temWeek &&
          !arr.includes(temDay)
        ) {
          arr.push(temDay);
        }

        currentDate = currentDate.add(Number(numberConstants.one), monthItem);
      } else if (serviceFrequency === monthlyByDay) {
        let dayNumber = 0;
        let firstDayOfMonth = currentDate.startOf(monthItem);
        const endDayOfMonth = currentDate.endOf(monthItem);
        while (firstDayOfMonth.isBefore(endDayOfMonth)) {
          if (businessDay) {
            let day = firstDayOfMonth.get(dayItem);
            if (!skipDay.includes(day)) {
              dayNumber++;
            }
          } else {
            dayNumber++;
          }
          if (dayNumber === Number(day)) {
            if (from.isBefore(firstDayOfMonth)) {
              arr.push(firstDayOfMonth.format(dayMonthYearFormat));
            }
            dayNumber++;
          }
          firstDayOfMonth = firstDayOfMonth.add(
            Number(numberConstants.one),
            dayItem
          );
        }
        currentDate = currentDate.add(Number(numberConstants.one), monthItem);
      }
    }
    setSelectedDateList([...arr]);
    if (!isTrue) {
      buttonListArray[4].disabled = false;
      buttonListArray[3].disabled = true;
      setButtonList([...buttonListArray]);
      setIsLoading(false);
    } else {
      if (Object.keys(obj)?.length) {
        handleSaveAction(false, false, obj, arr, isCustomerIdExist);
      }
    }
  };

  const handleClear = () => {
    resetForm();
    setSelectedDateList([]);
    setServiceFrequencyChanges(false);
    setSeverity(severityError);
    let btnList = buttonList.map((item) => {
      return {
        ...item,
        disabled: true
      };
    });
    setButtonList([...btnList]);
    setIsLoading(false);
    setThrowErrorMessage(null);
  };

  const handleClick = (label) => {
    if (label === buttonList[3]?.label) {
      handleGenerate();
    } else if (label === buttonList[0]?.label) {
      handleClear();
    } else if (label === buttonList[2]?.label) {
      dispatch(setViewModeId(values?.customerField));
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_WINDOWS}/${values?.customerField?.value}`
      );
    } else if (label === buttonList[4]?.label) {
      handleSave();
    } else if (label === buttonList[1]?.label) {
      setIsDownload(true);
    }
  };

  const handleChangeDay = async (data) => {
    setIsLoading(true);
    if (selectedDateList?.length && values?.rotationScheduleExists) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80342);
      const formattedString = errorMsg[0]?.descr
        .split("|")
        .map((line, index) => (
          <>
            {line}
            <br />
          </>
        ));
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: formattedString,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, dayFieldPopupCheckBox: true });
      setDayDataTemp(data);
      setIsLoading(false);
    } else if (selectedDateList?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({
        ...activeFiledPopup,
        dayFieldPopupCheckBox2: true
      });
      setDayDataTemp(data);
      setIsLoading(false);
    } else {
      handleDayCheckBox(data);
    }
  };

  const handleDayCheckBox = async (data) => {
    setIsLoading(true);
    if (selectedDateList?.length && !activeFiledPopup?.dayFieldPopupCheckBox2) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({
        ...activeFiledPopup,
        dayFieldPopupCheckBox2: true
      });
      setDayDataTemp(data);
    } else {
      let temp = data || dayDataTemp;
      let dataList = [...(values?.weekDayList || [])];
      if (values?.weekDayList?.includes(temp?.value)) {
        let newArray = dataList?.filter((item) => item !== temp?.value);
        dataList = [...newArray];
      } else {
        dataList.push(temp?.value);
      }
      if (dataList?.length) {
        buttonListArray[0].disabled = false;
        buttonListArray[3].disabled = false;
        buttonListArray[4].disabled = true;
      } else {
        buttonListArray[3].disabled = true;
        buttonListArray[4].disabled = true;
      }
      buttonListArray[2].disabled = true;
      setButtonList([...buttonListArray]);
      setFieldValue(fieldName?.weekDayList, dataList);
      setServiceFrequencyChanges(true);

      setSelectedDateList([]);
      handleCancelPopup();
      setDayDataTemp(null);
      setActiveFieldPopup({
        ...activeFiledPopup,
        dayFieldPopupCheckBox2: false
      });
    }
    setIsLoading(false);
  };

  const handleError = (message, isPopup = false) => {
    if (isPopup) {
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: false,
        showNo: false,
        message: message
      });
    } else {
      setThrowErrorMessage(message);
      setSeverity(severityError);
    }
  };

  const handleCls = async (clsField) => {
    try {
      if (clsField) {
        setIsLoading(true);
        let res = await getResponseData(
          JSON.stringify({
            customer_id: values?.customerField?.value,
            main_district_id: currentBranch?.value
          }),
          `${CF_URLS.customerInformationSchedule.getCslChildren}`,
          2
        );
        if (res?.data[0][0]?.customer_id) {
          const errorMsg = await getMessageFromCode(errorMsgs.errorCode25384);
          setCancelPopup({
            modalPopupOpen: true,
            showOk: true,
            showCancel: false,
            message: errorMsg[0]?.descr.replace(
              "|",
              values?.customerField?.label
            ),
            showNo: false
          });
          setActiveFieldPopup({ ...activeFiledPopup, svcPopup: true });
        }
      } else {
        let res = await getResponseData(
          JSON.stringify({
            customer_id: values?.customerField?.value,
            main_district_id: currentBranch?.value
          }),
          `${CF_URLS.customerInformationSchedule.cslsReturns}`,
          2
        );
        if (res?.data[0][0]?.customer_id) {
          const errorMsg = await getMessageFromCode(errorMsgs.errorCode20520);
          setThrowErrorMessage(errorMsg[0]?.descr);
          setSeverity(severityWarning);
          setIsLoading(false);
        }
      }
      setFieldValue(fieldName?.clsField, clsField);
      buttonList[0].disabled = false;
      buttonList[3].disabled = false;
      setButtonList([...buttonList]);
      setIsLoading(false);
    } catch (err) {
      handleSystemError();
    }
  };

  const handleClsField = async (value = true) => {
    setIsLoading(true);
    if (selectedDateList.length && value) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, cslPopup: true });
      setIsLoading(false);
    } else {
      handleCls(!values?.clsField);
      handleCancelPopup();
      setActiveFieldPopup({ ...activeFiledPopup, cslPopup: false });
    }
  };

  const handleWeekNumber = async (value) => {
    if (
      values?.mbDayOfNthWeekRSExist &&
      values?.serviceFrequency !== daysPerWeek
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20560);
      setThrowErrorMessage(errorMsg[0]?.descr);
      setSeverity(severityWarning);
      setFieldValue(fieldName?.week, value);
    }
    if (selectedDateList?.length && values?.rotationScheduleExists) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80342);
      const formattedString = errorMsg[0]?.descr
        .split("|")
        .map((line, index) => (
          <>
            {line}
            <br />
          </>
        ));
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: formattedString,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, weekFieldPopup: true });
      setWeekDataTemp(value);
      setIsLoading(false);
    } else if (selectedDateList?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, weekFieldPopup2: true });
      setDayDataTemp(value);
      setIsLoading(false);
      setWeekDataTemp(value);
    } else {
      handleWeekSetNumber(value);
    }
  };

  const handleWeekSetNumber = async (value) => {
    setIsLoading(true);
    if (selectedDateList?.length && !activeFiledPopup?.weekFieldPopup2) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, weekFieldPopup2: true });
      setDayDataTemp(value);
      setIsLoading(false);
      setWeekDataTemp(value);
    } else {
      let temp = value || weekDataTemp;
      setFieldValue(fieldName?.week, temp);
      buttonList[2].disabled = true;
      buttonList[3].disabled = false;
      if (!values?.weekDay) {
        buttonList[3].disabled = true;
      }
      buttonList[4].disabled = true;
      setButtonList([...buttonList]);
      setServiceFrequencyChanges(true);
      setWeekDataTemp(null);
      setActiveFieldPopup({ ...activeFiledPopup, weekFieldPopup2: false });
      handleCancelPopup();
      setSelectedDateList([]);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleWeekDayMonth = async (value) => {
    setIsLoading(true);
    if (
      values?.mbDayOfNthWeekRSExist &&
      values?.serviceFrequency !== daysPerWeek
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20560);
      setThrowErrorMessage(errorMsg[0]?.descr);
      setSeverity(severityWarning);
      setFieldValue(fieldName?.weekDay, value);
      setIsLoading(false);
    }
    if (selectedDateList?.length && values?.rotationScheduleExists) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80342);
      const formattedString = errorMsg[0]?.descr
        .split("|")
        .map((line, index) => (
          <>
            {line}
            <br />
          </>
        ));
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: formattedString,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, weekDayFieldPopup: true });
      setWeekDayDataTemp(value);
      setIsLoading(false);
    } else if (selectedDateList?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, weekDayFieldPopup2: true });
      setWeekDayDataTemp(value);
      setIsLoading(false);
    } else {
      handleWeekDaySetNumber(value);
    }
  };
  const handleWeekDaySetNumber = async (value) => {
    setIsLoading(true);
    if (selectedDateList?.length && !activeFiledPopup?.weekDayFieldPopup2) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, weekDayFieldPopup2: true });
      setWeekDayDataTemp(value);
      setIsLoading(false);
    } else {
      let temp = value || dayWeekDataTemp;
      setFieldValue(fieldName?.weekDay, temp);
      setWeekDayDataTemp(null);
      setActiveFieldPopup({ ...activeFiledPopup, weekDayFieldPopup2: false });
      handleCancelPopup();
      setSelectedDateList([]);
      if (buttonListArray[3]?.disabled) {
        buttonListArray[3].disabled = false;
      }
      buttonListArray[2].disabled = true;
      setServiceFrequencyChanges(true);
      setButtonList([...buttonListArray]);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const handleConfirm = (value) => {
    setFieldValue(fieldName?.serviceFrequency, value);
    if (value === onCall) {
      buttonList[4].disabled = false;
      setButtonList([...buttonList]);
    }
    setServiceFrequencyChanges(true);
    setSelectedDateList([]);
    setFieldValue(fieldName.week, "");
    setFieldValue(fieldName.weekDay, "");
    setFieldValue(fieldName.day, "");
    setFieldValue(fieldName.businessDay, false);
    if (
      [daysPerWeek, everyOtherWeek, monthlyByWeek].includes(value) &&
      weekDayListArray?.length === 0
    ) {
      getWeekOfDayList();
    }
    handleCancelPopup();
    setServiceFrequencyValue(null);
    setFieldValue(fieldName?.weekDayList, []);
    let buttonListData = buttonList.map((item) => {
      if (value === onCall) {
        return {
          ...item,
          disabled: ![buttonList[0]?.label, buttonList[4]?.label].includes(
            item?.label
          )
        };
      } else {
        return {
          ...item,
          disabled: item?.label !== buttonList[0]?.label
        };
      }
    });
    setButtonList([...buttonListData]);
  };
  const handleSave = async () => {
    setIsLoading(true);
    if (
      values?.mbDayOfNthWeekRSExist &&
      values?.serviceFrequency !== daysPerWeek
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20560);
      const formattedString = errorMsg[0]?.descr
        .split("|")
        .map((line, index) => (
          <>
            {line}
            <br />
          </>
        ));
      setThrowErrorMessage(formattedString);
      setSeverity(severityWarning);
      buttonList[4].disabled = false;
      setButtonList([...buttonList]);
    }
    if (
      originalDataSet?.serviceFrequency !== values?.serviceFrequency &&
      serviceFrequencyChanges
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20284);
      const formattedString = errorMsg[0]?.descr
        .split("|")
        .map((line, index) => (
          <>
            {line}
            <br />
          </>
        ));
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: formattedString,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, savePopup: true });
    } else {
      handleSaveAction();
      handleCancelPopup();
    }
    setIsLoading(false);
  };
  const handleSaveAction = async (
    render = false,
    callGet = true,
    obj,
    selectableDay,
    loader
  ) => {
    const {
      serviceFrequency,
      customerField,
      clsField,
      from,
      weekDay,
      week,
      day,
      businessDay,
      comment,
      weekDayList,
      timestamp,
      customerService
    } = callGet ? values : obj;
    let res = null;
    let weekDayListData = weekDayList?.length ? [...weekDayList] : [];
    if (serviceFrequency === listOfDates) {
      weekDayListData = [];
      selectedDateList?.forEach((item) => {
        let currentDate = dayjs(item, dayMonthYearFormat);
        let day = "";
        day = Number(currentDate.get(dayItem)) + 1;
        if (!weekDayListData.includes(`${day}`)) {
          weekDayListData.push(`${day}`);
        }
      });
    }
    let reqBody = {
      main_district_id: currentBranch?.value,
      mode: A_CHECKER,
      new_serv_freq_code: serviceFrequency,
      customer_id: customerField.value,
      csl_flag: clsField ? Y_CHECKER : N_CHECKER,
      new_gen_date_start: from.format(ymdDateFormat),
      new_gen_date_end: clsField
        ? from.add(4, yearItem).format(ymdDateFormat)
        : from.add(2, yearItem).format(ymdDateFormat),
      day_of_week_code: weekDay,
      week_of_month_code: week,
      day_of_month_code: day,
      on_business_day: businessDay
        ? numberConstants.minusOne
        : numberConstants.zero,
      on_monday: weekDayListData?.includes(numberConstants.two)
        ? numberConstants.minusOne
        : numberConstants.zero,
      on_tuesday: weekDayListData?.includes(numberConstants.three)
        ? numberConstants.minusOne
        : numberConstants.zero,
      on_wednesday: weekDayListData?.includes(numberConstants.four)
        ? numberConstants.minusOne
        : numberConstants.zero,
      on_thursday: weekDayListData?.includes(numberConstants.five)
        ? numberConstants.minusOne
        : numberConstants.zero,
      on_friday: weekDayListData?.includes(numberConstants.six)
        ? numberConstants.minusOne
        : numberConstants.zero,
      on_saturday: weekDayListData?.includes(numberConstants.seven)
        ? numberConstants.minusOne
        : numberConstants.zero,
      on_sunday: weekDayListData?.includes(numberConstants.one)
        ? numberConstants.minusOne
        : numberConstants.zero,
      comment: comment,
      csf_timestamp: timestamp ? timestamp : ""
    };
    if (customerService || selectedDateList?.length || selectableDay?.length) {
      let dateList = selectedDateList?.length
        ? [...selectedDateList]
        : [...selectableDay];
      let index = 0;
      let startMonth = dayjs.utc(from);
      let endMonth = dayjs.utc(from).add(50, monthItem);
      while (startMonth?.isBefore(endMonth)) {
        let value = "";
        index++;
        value = startMonth.format(yearMonthFormat);
        let firstDayOfMonth = startMonth.startOf(monthItem);
        const endDayOfMonth = startMonth.endOf(monthItem);
        while (firstDayOfMonth.isBefore(endDayOfMonth)) {
          if (dateList.includes(firstDayOfMonth.format(dayMonthYearFormat))) {
            value += numberConstants.one;
          } else {
            value += numberConstants.zero;
          }
          firstDayOfMonth = firstDayOfMonth.add(1, dayItem);
        }
        reqBody[`month${index}_bitstring`] = value;
        startMonth = startMonth.add(1, monthItem);
      }
    }

    try {
      setIsLoading(true);
      if (serviceFrequencyChanges || !callGet) {
        res = await getResponseData(
          JSON.stringify({ ...reqBody }),
          `${CF_URLS.customerInformationSchedule.saveSchedule}`,
          2
        );
      } else {
        res = await getResponseData(
          JSON.stringify({
            main_district_id: currentBranch?.value,
            customer_id: customerField.value,
            comment: comment,
            timestamp: values?.timestamp
          }),
          `${CF_URLS.customerInformationSchedule.commentFieldUpdation}`,
          2
        );
      }
      if (res?.data) {
        if (
          res?.data[0][0]?.timestamp ||
          res?.data[0][0]?.error === numberConstants.zero
        ) {
          let customer = customerTempValue || customerField;
          if (!render || serviceFrequency === onCall) {
            if (callGet) {
              handleCustomer(customer);
            }
          } else if (render) {
            navigate(
              `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_WINDOWS}/${values?.customerField?.value}`
            );
          }
        } else if (res?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(res?.data[0][0]?.error);
          if (errorMsg[0]?.descr) {
            setThrowErrorMessage(errorMsg[0]?.descr);
          } else {
            handleSystemError();
          }
          setSeverity(severityError);
          setIsLoading(false);
        }
        if (!callGet) {
          setIsLoading(loader);
          if (!loader && loader !== undefined) {
            getRegenarateCustomers();
          }
        }
      }
    } catch (err) {
      handleSystemError();
    }
  };

  const handleOnChangeDate = async (number) => {
    setIsLoading(true);
    if (selectedDateList?.length && values?.rotationScheduleExists) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80342);
      const formattedString = errorMsg[0]?.descr
        .split("|")
        .map((line, index) => (
          <>
            {line}
            <br />
          </>
        ));
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: formattedString,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, dayFieldPopup: true });
      setDayTemp(number);
      setIsLoading(false);
    } else if (selectedDateList?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, dayFieldPopup2: true });
      setDayTemp(number);
      setIsLoading(false);
    } else {
      handleSetNumber(number);
    }
  };
  const handleSetNumber = async (value) => {
    setIsLoading(true);
    if (selectedDateList?.length && !activeFiledPopup?.weekDayFieldPopup2) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, weekDayFieldPopup2: true });
      setWeekDayDataTemp(value);
      setIsLoading(false);
    } else {
      let temp = value || dayTemp;
      setFieldValue(fieldName.day, temp);
      setDayTemp(null);
      setActiveFieldPopup({ ...activeFiledPopup, weekDayFieldPopup2: false });
      handleCancelPopup();
      setSelectedDateList([]);
      if (buttonListArray[3]?.disabled && temp) {
        buttonListArray[3].disabled = false;
        setButtonList([...buttonListArray]);
      }
      if (!temp) {
        buttonListArray[3].disabled = true;
        setButtonList([...buttonListArray]);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleBusinessDay = async (value) => {
    if (
      values?.mbDayOfNthWeekRSExist &&
      values?.serviceFrequency !== daysPerWeek
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20560);
      setThrowErrorMessage(errorMsg[0]?.descr);
      setSeverity(severityWarning);
      setFieldValue(fieldName?.businessDay, !values?.businessDay);
    }
    if (selectedDateList?.length && values?.rotationScheduleExists) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80342);
      const formattedString = errorMsg[0]?.descr
        .split("|")
        .map((line, index) => (
          <>
            {line}
            <br />
          </>
        ));
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: formattedString,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, businessDayPopup: true });
      setIsLoading(false);
    } else if (selectedDateList?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, businessDayPopup2: true });
      setIsLoading(false);
    } else {
      handleBusinessDaySet(value);
    }
  };

  const handleBusinessDaySet = async () => {
    if (selectedDateList?.length && !activeFiledPopup?.businessDayPopup2) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20276);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({ ...activeFiledPopup, businessDayPopup2: true });
      setIsLoading(false);
    } else {
      setFieldValue(fieldName?.businessDay, !values?.businessDay);
      setActiveFieldPopup({ ...activeFiledPopup, businessDayPopup2: false });
      handleCancelPopup();
      setSelectedDateList([]);
      buttonListArray[0].disabled = false;
      buttonListArray[3].disabled = false;
      buttonListArray[4].disabled = true;
      setButtonList([...buttonListArray]);
      setServiceFrequencyChanges(true);
    }
  };

  const handleCustomerValid = async () => {
    setIsLoading(true);
    handleCancelPopup();
    if (values?.bSvcTripsExist) {
      const res = await getResponseData(
        JSON.stringify({
          customer_id: values?.customerField?.value,
          main_district_id: currentBranch?.value
        }),
        `${CF_URLS.customerInformationSchedule.customerScheduleAndRouteCustomerSchedule}`,
        2
      );
      let temp = [];
      if (res?.data) {
        let day = res?.data[0]?.map(
          (item) => dayName[item?.day_of_week_code?.toLowerCase()]
        );
        day?.forEach((item) => {
          let checkSeqId = weekDayListArray?.find(
            (dayItem) =>
              dayItem?.name?.toUpperCase()?.trim() ===
              item?.toUpperCase()?.trim()
          );
          if (!values?.weekDayList?.includes(checkSeqId?.sequence_number)) {
            temp.push(item);
          }
        });
        setFieldValue(fieldName?.bSvcTripsExist, true);
        day = temp?.join(",");
        if (
          temp?.length &&
          originalDataSet?.service_frequency_code?.trim() !== onCall
        ) {
          const errorMsg = await getMessageFromCode(errorMsgs.errorCode20285);
          setCancelPopup({
            modalPopupOpen: true,
            showOk: false,
            showCancel: false,
            message: errorMsg[0]?.descr?.replace("|", day),
            showNo: true
          });
          setActiveFieldPopup({
            ...activeFiledPopup,
            savePopup2: false,
            savePopup3: true,
            savePopup: false
          });
        } else {
          const errorMsg = await getMessageFromCode(errorMsgs.errorCode20412);
          setCancelPopup({
            modalPopupOpen: true,
            showOk: true,
            showCancel: false,
            message: errorMsg[0]?.descr,
            showNo: true
          });
          setActiveFieldPopup({
            ...activeFiledPopup,
            savePopup2: false,
            savePopup4: true
          });
        }
      } else {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode20412);
        setCancelPopup({
          modalPopupOpen: true,
          showOk: true,
          showCancel: false,
          message: errorMsg[0]?.descr,
          showNo: true
        });
        setActiveFieldPopup({
          ...activeFiledPopup,
          savePopup2: false,
          savePopup4: true
        });
      }
    } else {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode26213);
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: true,
        message: errorMsg[0]?.descr,
        showNo: false
      });
      setActiveFieldPopup({
        ...activeFiledPopup,
        savePopup: false,
        savePopup2: true
      });
    }
    setIsLoading(false);
  };

  const handleCustomerScheduleAndRouteCustomerSchedule = async () => {
    try {
      let isLoadingValue = false;
      setIsLoading(true);
      const res = await getResponseData(
        JSON.stringify({
          customer_id: values?.customerField?.value,
          main_district_id: currentBranch?.value
        }),
        `${CF_URLS.customerInformationSchedule.customerScheduleAndRouteCustomerSchedule}`,
        2
      );
      const res2 = await getResponseData(
        JSON.stringify({
          customer_id: values?.customerField?.value,
          main_district_id: currentBranch?.value
        }),
        `${CF_URLS.customerInformationSchedule.customerServiceFrequencyInformationOfCustomer}`,
        2
      );
      if (res2?.data) {
        setFieldValue(fieldName?.customerService, res2?.data[0][0]);
      }
      if (res?.data) {
        let day = res?.data[0]?.map(
          (item) => dayName[item?.day_of_week_code?.toLowerCase()]
        );
        let temp = [];
        day?.forEach((item) => {
          let checkSeqId = weekDayListArray?.find(
            (dayItem) =>
              dayItem?.name?.toUpperCase()?.trim() ===
              item?.toUpperCase()?.trim()
          );
          if (!values?.weekDayList?.includes(checkSeqId?.sequence_number)) {
            temp.push(item);
          }
        });
        setFieldValue(fieldName?.bSvcTripsExist, true);
        day = temp?.join(",");
        if (
          temp?.length &&
          originalDataSet?.service_frequency_code?.trim() !== onCall
        ) {
          const errorMsg = await getMessageFromCode(errorMsgs.errorCode20285);
          setCancelPopup({
            modalPopupOpen: true,
            showOk: false,
            showCancel: false,
            message: errorMsg[0]?.descr?.replace("|", day),
            showNo: true
          });
          setActiveFieldPopup({
            ...activeFiledPopup,
            savePopup2: false,
            savePopup3: true,
            savePopup: false
          });
        } else {
          const errorMsg = await getMessageFromCode(errorMsgs.errorCode20412);
          setCancelPopup({
            modalPopupOpen: true,
            showOk: true,
            showCancel: false,
            message: errorMsg[0]?.descr,
            showNo: true
          });
          setActiveFieldPopup({
            ...activeFiledPopup,
            savePopup2: false,
            savePopup4: true
          });
        }
      } else {
        const res3 = await getResponseData(
          JSON.stringify({
            customer_id: values?.customerField?.value,
            main_district_id: currentBranch?.value
          }),
          `${CF_URLS.customerInformationSchedule.promptuserTrueOrFalse}`,
          2
        );
        if (res3?.data[0][0]?.prompt_user !== numberConstants.zero) {
          const errorMsg = await getMessageFromCode(errorMsgs.errorCode20412);
          setCancelPopup({
            modalPopupOpen: true,
            showOk: true,
            showCancel: false,
            message: errorMsg[0]?.descr,
            showNo: true
          });
          setActiveFieldPopup({
            ...activeFiledPopup,
            savePopup2: false,
            savePopup4: true
          });
        } else {
          isLoadingValue = true;
          handleSaveAction();
        }
        setIsLoading(isLoadingValue);
      }
    } catch (err) {
      handleSystemError();
    }
    setIsLoading(false);
  };
  const handleSchedulePopup = async () => {
    const errorMsg = await getMessageFromCode(errorMsgs.errorCode20278);
    setCancelPopup({
      modalPopupOpen: true,
      showOk: true,
      showCancel: true,
      message: errorMsg[0]?.descr,
      showNo: false
    });
    setActiveFieldPopup({
      ...activeFiledPopup,
      dateListPopup1: false,
      dateListPopup2: true
    });
    setIsLoading(false);
  };
  const handleYesConfirmPopup = () => {
    if (values?.serviceFrequency === onCall && activeFiledPopup?.savePopup) {
      setSelectedDateList([]);
      handleCancelPopup();
      handleSaveAction();
    } else if (activeFiledPopup?.cslPopup) {
      setSelectedDateList([]);
      handleClsField(false);
    } else if (activeFiledPopup?.svcPopup) {
      setActiveFieldPopup({
        ...activeFiledPopup,
        svcPopup: false
      });
      dispatch(setViewModeId(values?.customerField));
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_WINDOWS}/${values?.customerField?.value}`
      );
    } else if (activeFiledPopup?.serviceFrequency) {
      setSelectedDateList([]);
      handleServiceFrequencyAction();
      setActiveFieldPopup({ ...activeFiledPopup, serviceFrequency: false });
    } else if (activeFiledPopup?.rotationScheduleExists) {
      setFieldValue(fieldName?.serviceFrequency, serviceFrequencyValue);
      setServiceFrequencyValue(null);
      setActiveFieldPopup({
        ...activeFiledPopup,
        rotationScheduleExists: false
      });
      handleCancelPopup();
    } else if (activeFiledPopup?.dayFieldPopupCheckBox) {
      setActiveFieldPopup({
        ...activeFiledPopup,
        dayFieldPopupCheckBox: false
      });
      handleDayCheckBox(dayDataTemp);
    } else if (activeFiledPopup?.dayFieldPopupCheckBox2) {
      handleDayCheckBox(dayDataTemp);
    } else if (activeFiledPopup?.weekFieldPopup) {
      setActiveFieldPopup({
        ...activeFiledPopup,
        weekFieldPopup: false
      });
      handleWeekSetNumber(weekDataTemp);
    } else if (activeFiledPopup?.weekFieldPopup2) {
      handleWeekSetNumber(weekDataTemp);
    } else if (activeFiledPopup?.weekDayFieldPopup) {
      setActiveFieldPopup({
        ...activeFiledPopup,
        weekDayFieldPopup: false
      });
      handleWeekDaySetNumber(dayWeekDataTemp);
    } else if (activeFiledPopup?.weekDayFieldPopup2) {
      handleWeekDaySetNumber(dayWeekDataTemp);
    } else if (activeFiledPopup?.dayFieldPopup) {
      setActiveFieldPopup({
        ...activeFiledPopup,
        dayFieldPopup: false
      });
      handleSetNumber(dayTemp);
    } else if (activeFiledPopup?.dayFieldPopup2) {
      handleSetNumber(dayTemp);
    } else if (activeFiledPopup?.businessDayPopup) {
      setActiveFieldPopup({
        ...activeFiledPopup,
        businessDayPopup: false
      });
      handleBusinessDaySet();
    } else if (activeFiledPopup?.businessDayPopup2) {
      handleBusinessDaySet();
    } else if (activeFiledPopup?.savePopup && !activeFiledPopup?.savePopup4) {
      setActiveFieldPopup({
        ...activeFiledPopup,
        savePopup: false
      });
      handleCustomerValid();
    } else if (activeFiledPopup?.savePopup2) {
      setActiveFieldPopup({
        ...activeFiledPopup,
        savePopup2: false
      });
      handleCancelPopup();
      handleCustomerScheduleAndRouteCustomerSchedule();
    } else if (savePopup?.first) {
      handleSaveAction(true);
      setSavePopup({ first: false, second: false });
      handleCancelPopup();
    } else if (activeFiledPopup?.savePopup3 || activeFiledPopup?.savePopup4) {
      handleSaveAction(true);
      setActiveFieldPopup({
        ...activeFiledPopup,
        savePopup3: false,
        savePopup4: false
      });
      handleCancelPopup();
    } else if (activeFiledPopup?.customerPopup) {
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_INFORMATION}/${temCustomer}`
      );
      handleCancelPopup();
    } else if (activeFiledPopup?.dateListPopup1) {
      handleSchedulePopup();
    } else if (activeFiledPopup?.dateListPopup2) {
      setSelectedDateList([]);
      handleCancelPopup();
      buttonList[3].disabled = true;
      setButtonList([...buttonList]);
      setServiceFrequencyChanges(true);
    } else {
      handleCancelPopup();
    }
  };
  const handleNoConfirmPopup = () => {
    if (
      activeFiledPopup?.savePopup4 ||
      (activeFiledPopup?.savePopup3 && values?.serviceFrequency === listOfDates)
    ) {
      handleCancelPopup();
      handleSaveAction();
    } else if (activeFiledPopup?.savePopup3) {
      setFieldValue(fieldName?.bSvcTripsExist, false);
      buttonList[0].disabled = false;
      buttonList[4].disabled = false;
      setActiveFieldPopup({
        ...activeFiledPopup,
        savePopup3: false
      });
      setCancelPopup({
        modalPopupOpen: false,
        showOk: false,
        showCancel: false,
        showNo: false,
        message: ""
      });
      setButtonList([...buttonList]);
    } else {
      handleCancelPopup();
      handleCustomer(customerTempValue);
    }
  };
  const handleDownloadReport = async () => {
    setIsLoading(true);
    const {
      on_sunday,
      on_friday,
      on_monday,
      on_tuesday,
      on_wednesday,
      on_thursday,
      on_saturday,
      service_frequency_code
    } = originalDataSet;
    const { first_name } = auth?.user || {};
    let requestBody = {
      main_district_id: currentBranch?.value,
      customer_id: values?.customerField?.value,
      customer_number: t("customerSchedule.customerNumber", {
        customerNumber: values?.customerField?.number
      }),
      label_branch: localeByBranch,
      duration: t("customerSchedule.ScheduleFrom", {
        fromDate: dayjs(originalDataSet.generated_date_start).format(
          dayMonthYearFormat
        )
      }),
      label_report_date: t("customerSchedule.reportDate", {
        date: dayjs().format(dayMintMonthYearFormat)
      }),
      label_printed_by: t("customerSchedule.labelPrintedBy", {
        name: getUserName(auth?.user)
      }),
      label_user_name: t("customerSchedule.userName", {
        name: first_name
      }),
      label_report_name: t("customerSchedule.reportName"),
      label_page: t("customerSchedule.page"),
      label_of: t("customerSchedule.of"),
      user_name: getUserName(auth?.user),
      report_format: downloadReportOptions[0].type?.toUpperCase(),
      is_sunday: numberConstants.zero,
      is_monday: numberConstants.zero,
      is_tuesday: numberConstants.zero,
      is_wednesday: numberConstants.zero,
      is_thursday: numberConstants.zero,
      is_friday: numberConstants.zero,
      is_saturday: numberConstants.zero
    };
    if (
      [daysPerWeek, everyOtherWeek, monthlyByWeek].includes(
        service_frequency_code.trim()
      )
    ) {
      requestBody = {
        ...requestBody,
        is_sunday:
          on_sunday === numberConstants.zero
            ? numberConstants.zero
            : numberConstants.one,
        is_monday:
          on_monday === numberConstants.zero
            ? numberConstants.zero
            : numberConstants.one,
        is_tuesday:
          on_tuesday === numberConstants.zero
            ? numberConstants.zero
            : numberConstants.one,
        is_wednesday:
          on_wednesday === numberConstants.zero
            ? numberConstants.zero
            : numberConstants.one,
        is_thursday:
          on_thursday === numberConstants.zero
            ? numberConstants.zero
            : numberConstants.one,
        is_friday:
          on_friday === numberConstants.zero
            ? numberConstants.zero
            : numberConstants.one,
        is_saturday:
          on_saturday === numberConstants.zero
            ? numberConstants.zero
            : numberConstants.one
      };
    }
    try {
      const url =
        CF_URLS?.printReports?.customerInformation?.customerSheduleReport;
      let reportResponse = await printReport(
        JSON.stringify({
          ...requestBody
        }),
        url
      );
      if (!reportResponse.success) {
        handleSystemError();
      }
      setIsLoading(false);
      setIsDownload(false);
    } catch (err) {
      handleSystemError();
    }
  };

  const onCustomerWithExpiringSchedulesClick = () => {
    setRegenerateModalOpen(true);
  };

  const onCancleClick = () => {
    setRegenerateModalOpen(false);
    setSearchText("");
    const selectAll = regenerateCustomers?.map((c) => {
      return { ...c, isSelected: false };
    });
    setRegenerateCustomers((prev) => selectAll);
  };

  const getSelectedCustomers = async () => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value
      };

      const customersData = await getResponseData(
        reqBody,
        CF_URLS.customerInformationSchedule
          ?.getCslcustinForTodoSelectedCustomer,
        1
      );

      const numbersToMatch = customersData?.data[0]?.map((item) =>
        item?.number.trim()
      );

      const matchingObjects = customers?.filter((item) =>
        numbersToMatch?.includes(item?.number.trim())
      );

      matchingObjects?.sort((a, b) => a?.number?.localeCompare(b?.number));

      setSelectedCustomers(matchingObjects);
      setIsLoading(false);
    } catch (error) {
      handleSystemError();
    }
  };

  const getRegenarateCustomers = async () => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        expire_count_flag: N_CHECKER
      };

      const customersData = await getResponseData(
        reqBody,
        CF_URLS.customerInformationSchedule?.getCslCustinForTodo,
        1
      );

      const numbersToMatch = customersData?.data[0]?.map((item) =>
        item?.customer_id.trim()
      );

      const matchingObjects = customers?.filter((item) =>
        numbersToMatch?.includes(item?.value.trim())
      );

      matchingObjects?.sort((a, b) => a?.number?.localeCompare(b?.number));

      const mapped = matchingObjects?.map((c, i) => {
        return { ...c, key: i, isSelected: false };
      });

      setRegenerateCustomers(mapped);
      if (navigationData) {
        setRegenerateModalOpen(true);
      }
      setIsLoading(false);
    } catch (error) {
      handleSystemError();
    }
  };

  const handleSelectAllChange = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      const selectAll = regenerateCustomers?.map((c) => {
        return { ...c, isSelected: true };
      });
      setRegenerateCustomers((prev) => selectAll);
      setAllSelected(true);
    } else {
      const selectAll = regenerateCustomers?.map((c) => {
        return { ...c, isSelected: false };
      });
      setRegenerateCustomers((prev) => selectAll);
      setAllSelected(false);
    }
  };

  const handleCustomerSelect = (value) => {
    const checked = regenerateCustomers?.map((cus) => {
      if (cus?.key === value) {
        return { ...cus, isSelected: !cus?.isSelected };
      } else {
        return cus;
      }
    });

    setRegenerateCustomers((prev) => checked);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const onRegenerateClick = async () => {
    setIsLoading(true);
    let errorList = [],
      customerReList = [];
    const checkedCustomers = regenerateCustomers?.filter((c) => c?.isSelected);
    for (let index = 0; index < checkedCustomers.length; index++) {
      const selectedCustomer = checkedCustomers[index];
      const returnedData = await getCustomerServiceFrequency(selectedCustomer);
      if (
        returnedData?.comment ||
        listOfDates === returnedData?.service_frequency_code?.trim()
      ) {
        let label = selectedCustomer?.label?.split("-");
        let str = label[0]?.trim();
        if (returnedData?.comment) {
          str += t("customerSchedule.commentText");
        } else {
          str += t("customerSchedule.listOfDatesText");
        }
        errorList.push(str);
      } else {
        customerReList.push(selectedCustomer);
      }
    }
    onCancleClick();
    if (errorList?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode20494);
      let message = errorMsg[0]?.descr?.replace("|", errorList.join(" "));
      setCancelPopup({
        modalPopupOpen: true,
        showOk: true,
        showCancel: false,
        showNo: false,
        message: message
      });
      if (!customerReList?.length) {
        setIsLoading(false);
      }
    }
    if (customerReList?.length) {
      for (let i = 0; i < customerReList?.length; i++) {
        let obj = {
            day_of_month_code: "",
            day_of_week_code: "",
            week_of_month_code: "",
            weekDayList: []
          },
          selectedDateListItem = [];
        try {
          setIsLoading(true);
          obj["customerField"] = customerReList[i];
          const [responseCustomer, responseSchedule, responseCustomerIdExist] =
            await Promise.allSettled([
              getResponseData(
                JSON.stringify({
                  customer_id: customerReList[i]?.value,
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.rotationScheduleExistsForCustomerId}`,
                2
              ),
              getResponseData(
                JSON.stringify({
                  customer_id: customerReList[i]?.value,
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.allRotationSchedulesNames}`,
                2
              ),
              getResponseData(
                JSON.stringify({
                  customer_id: customerReList[i]?.value,
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.isCustomerIdExist}`,
                2
              )
            ]);
          if (responseCustomer?.value?.data[0][0]?.exists === Y_CHECKER) {
            obj["rotationScheduleExists"] = true;
          } else {
            obj["rotationScheduleExists"] = false;
          }
          if (
            responseSchedule?.value?.data[0][0]?.count > numberConstants?.zero
          ) {
            obj["mbDayOfNthWeekRSExist"] = true;
          } else {
            obj["mbDayOfNthWeekRSExist"] = false;
          }
          if (
            responseCustomerIdExist?.value?.data[0][0]?.exists === N_CHECKER
          ) {
            obj["clsField"] = false;
            obj["serviceFrequency"] = daysPerWeek;
          } else if (
            responseCustomerIdExist?.value?.data[0][0]?.exists === Y_CHECKER
          ) {
            const [response, res, lastDate] = await Promise.allSettled([
              getResponseData(
                JSON.stringify({
                  customer_id: customerReList[i]?.value,
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.customerServiceFrequencyInformation}`,
                2
              ),
              getResponseData(
                JSON.stringify({
                  customer_id: customerReList[i]?.value,
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.customeridValidRequsets}`,
                2
              ),
              getResponseData(
                JSON.stringify({
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.lastGenrunDate}`,
                2
              )
            ]);
            if (
              res?.value?.data[0][0]?.need_to_prompt_user ===
              numberConstants.zero
            ) {
              obj["bSvcTripsExist"] = true;
            }
            if (response?.value?.data[0][0]) {
              const {
                comment,
                csl_flag,
                generated_date_start,
                generated_date_end,
                service_frequency_code,
                day_of_week_code,
                week_of_month_code,
                day_of_month,
                on_business_day,
                timestamp
              } = response?.value?.data[0][0] || {};
              setOriginalDataSet(response?.value?.data[0][0]);
              if (service_frequency_code.trim() !== onCall) {
                response?.value?.data[1]?.forEach((item) => {
                  if (
                    dayjs(
                      lastDate?.value?.data[0][0]?.last_gen_run_date
                    ).isBefore(item?.service_date) &&
                    dayjs(generated_date_start).isBefore(item?.service_date)
                  ) {
                    selectedDateListItem.push(
                      dayjs.utc(item?.service_date).format(dayMonthYearFormat)
                    );
                  }
                });
              }
              obj["comment"] = comment;
              obj["lastDate"] = dayjs.utc(
                lastDate?.value?.data[0][0]?.last_gen_run_date
              );
              obj["clsField"] = csl_flag === Y_CHECKER;
              if (service_frequency_code.trim() === onCall) {
                obj["from"] = dayjs.utc(
                  lastDate?.value?.data[0][0]?.last_gen_run_date
                );
              } else {
                let last_gen_run_date = dayjs(
                  lastDate?.value?.data[0][0]?.last_gen_run_date
                ).add(1, dayItem);
                obj["from"] = dayjs.utc(last_gen_run_date);
              }
              obj["endDate"] = dayjs.utc(generated_date_end);
              obj["timestamp"] = timestamp;
              obj["serviceFrequency"] = service_frequency_code.trim();
              if (
                service_frequency_code.trim() === daysPerWeek ||
                service_frequency_code.trim() === everyOtherWeek
              ) {
                let temp = response?.value?.data[0][0];
                let dayList = [];
                for (let key in dayIndex) {
                  if (temp[key] !== numberConstants.zero) {
                    dayList.push(dayIndex[key]);
                  }
                }
                obj["weekDayList"] = dayList;
              }
              if (service_frequency_code.trim() === monthlyByWeek) {
                obj["weekDay"] = day_of_week_code;
                obj["week"] = week_of_month_code;
              }
              if (service_frequency_code.trim() === monthlyByDay) {
                obj["day"] = day_of_month;
                obj["businessDay"] = on_business_day !== numberConstants.zero;
              }
              if (service_frequency_code.trim() === onCall) {
                obj["onCallCustomer"] = true;
              }
              handleGenerate(true, obj, i !== customerReList?.length - 1);
            }
          }
        } catch (err) {
          handleSystemError();
        }
      }
    }
  };

  const getCustomerServiceFrequency = async (selectedCustomer) => {
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        customer_id: selectedCustomer?.value
      };

      const customerFrequencyResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerInformationSchedule?.customerServiceFrequencyInformation}`,
        3
      );

      const customerFrequencyError = customerFrequencyResponse?.data[2]; // get error code
      if (customerFrequencyError[0]?.error !== errorMsgs?.noError) {
        const errorMsg = await getMessageFromCode(
          customerFrequencyError[0]?.error
        );
        if (errorMsg[0]?.descr) {
          setThrowErrorMessage(errorMsg[0]?.descr);
        } else {
          handleSystemError();
        }
        setSeverity(severityError);
        setIsLoading(false);
        return;
      }

      return customerFrequencyResponse?.data[0][0];
    } catch (error) {
      handleSystemError();
    }
  };

  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    return a.every((item, index) => item === b[index]);
  };

  useEffect(() => {
    if (!arraysEqual(customers, prevCustomersRef.current)) {
      if (customers.length) {
        getSelectedCustomers();
        getRegenarateCustomers();
      }
      prevCustomersRef.current = customers;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);
  useEffect(() => {
    if (navigationData) {
      setFilteredCustomersChecked(true);
      window.history.replaceState(
        null,
        "",
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_WINDOWS}`
      );
    }
  }, [navigationData]);

  return (
    <>
      <ConfirmPopup
        modalPopupOpen={cancelPopup?.modalPopupOpen}
        handleCancel={() => {
          handleCancelPopup();
        }}
        showOk={cancelPopup?.showOk}
        message={cancelPopup?.message}
        showNo={cancelPopup?.showNo}
        showCancel={cancelPopup?.showCancel}
        handleYes={() => {
          handleYesConfirmPopup();
        }}
        handleNo={() => {
          handleNoConfirmPopup();
        }}
      />
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={isDownload}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => {
          setIsDownload(false);
        }}
        onDownloadClick={() => {
          handleDownloadReport();
        }}
      />

      <MultipleCustomerSelectModal
        title={t("common.regenerate")}
        regenerateModalOpen={regenerateModalOpen}
        onCancleClick={onCancleClick}
        regenerateCustomers={regenerateCustomers}
        handleSelectAllChange={handleSelectAllChange}
        handleCustomerSelect={handleCustomerSelect}
        handleSearchChange={handleSearchChange}
        searchText={searchText}
        onRegenerateClick={onRegenerateClick}
        allSelected={allSelected}
      />

      <CircularLoaderFullPage loading={isLoadingValue} />
      {throwErrorMessage && (
        <Collapse in={!!throwErrorMessage}>
          <Alert
            severity={severity}
            sx={customerScheduleStyle.errorStyle}
            icon={
              <img
                src={severity === severityError ? ErrorIcon : WarningIcon}
                alt="error"
                style={customerScheduleStyle.iconStyle}
              />
            }
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setSeverity(severityError);
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}

      <Stack gap={spacing.gap} sx={customerScheduleStyle.mainDivStyles}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <CustomersField
                options={
                  filteredCustomersChecked ? selectedCustomers : customers
                }
                value={values?.customerField}
                handleOnChange={handleCustomerChange}
                label={t("personnelValidation.customer")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredCustomersChecked}
                    onChange={(e) => {
                      setFilteredCustomersChecked(e.target.checked);
                      handleCustomerChange("", null);
                    }}
                    disabled={!selectedCustomers?.length}
                  />
                }
                label={t("dashboard.withSchedulesRequiringServiceWindows")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} style={customerGridStyles}>
            <Button
              style={customerButtonStyles}
              onClick={() => onCustomerWithExpiringSchedulesClick()}
              disabled={!regenerateCustomers?.length}
            >
              {t("customerSchedule.customerWithExpiringSchedules")}
            </Button>
          </Grid>

          {values?.customerField && (
            <>
              <Grid item xs={4} sm={6} md={4} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="serviceFrequency">
                    {t("customerSchedule.serviceFrequency")}
                  </InputLabel>
                  <Select
                    labelId="serviceFrequency"
                    id="serviceFrequency-id"
                    value={values?.serviceFrequency}
                    name="serviceFrequency"
                    label={t("customerSchedule.serviceFrequency")}
                    onChange={(e) => {
                      handleServiceFrequency(e.target.value);
                    }}
                  >
                    {serviceFrequencyList?.map((item) => {
                      return (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.service_frequency_code}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {[daysPerWeek, everyOtherWeek].includes(
                values?.serviceFrequency
              ) &&
                weekDayListArray?.map((day) => (
                  <Grid
                    item
                    xs={1.28}
                    sm={1.28}
                    md={1.28}
                    lg={1.28}
                    key={day?.name}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values?.weekDayList?.includes(
                            day.sequence_number
                          )}
                          inputProps={{ "aria-label": "controlled" }}
                          onChange={(e) => {
                            handleChangeDay(e.target);
                          }}
                          value={day?.sequence_number}
                        />
                      }
                      label={day?.name}
                    />
                  </Grid>
                ))}
              {monthlyByWeek === values?.serviceFrequency && (
                <>
                  <Grid item xs={4} sm={6} md={4} lg={3}>
                    <FormControl fullWidth>
                      <InputLabel id="week">
                        {t("customerSchedule.week")}
                      </InputLabel>
                      <Select
                        labelId="week"
                        id="week-id"
                        value={values?.week}
                        name="week"
                        label={t("customerSchedule.week")}
                        onChange={(e) => {
                          handleWeekNumber(e.target.value);
                        }}
                      >
                        {weekOfMonthList?.map((item) => {
                          return (
                            <MenuItem
                              value={item?.week_of_month_code}
                              key={item?.week_of_month_code}
                            >
                              {item?.week_of_month_code}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} lg={3}>
                    <FormControl fullWidth disabled={!values?.week}>
                      <InputLabel id="serviceFrequency">
                        {t("customerSchedule.weekDay")}
                      </InputLabel>
                      <Select
                        labelId="weekDay"
                        id="weekDay-id"
                        value={values?.weekDay}
                        name="weekDay"
                        label={t("customerSchedule.weekDay")}
                        onChange={(e) => {
                          handleWeekDayMonth(e.target.value);
                        }}
                      >
                        {weekDayListArray?.map((item) => {
                          return (
                            <MenuItem value={item?.id} key={item?.id}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4} lg={3}></Grid>
                </>
              )}
              {monthlyByDay === values?.serviceFrequency && (
                <>
                  <Grid item xs={4} sm={12} md={4} lg={3}>
                    <FormControl fullWidth>
                      <TextField
                        labelId="day"
                        label={t("customerSchedule.dayOfMonth")}
                        value={values?.day}
                        onChange={(event) => {
                          handleOnChangeDate(event.target.value);
                        }}
                        onKeyPress={(event) => {
                          if (!reg.test(event.key)) {
                            event.preventDefault(); // Prevent non-numeric key presses
                          }
                        }}
                        fullWidth
                        name="review-day"
                        id="review-day"
                        inputProps={{
                          maxLength: numberConstants.two
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5} sm={7} md={5} lg={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values?.businessDay}
                            inputProps={{ "aria-label": "controlled" }}
                            onChange={() => {
                              handleBusinessDay();
                            }}
                          />
                        }
                        label={t("customerSchedule.businessDay")}
                      />
                    </FormGroup>
                  </Grid>
                </>
              )}
              {onCall === values?.serviceFrequency && (
                <>
                  <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                </>
              )}
              {listOfDates === values?.serviceFrequency && (
                <>
                  <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                </>
              )}

              <Grid item xs={3} sm={3} md={3} lg={3}>
                <FormControl fullWidth>
                  <DatePickerField
                    disabled={true}
                    id="due-date"
                    name="dueDate"
                    label={t("customerSchedule.fromDate")}
                    value={values?.from}
                    minDate={values?.from}
                    maxDate={maxDate}
                    defaultValue={today}
                    locale={localeByBranch}
                    onChange={(value) => {}}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values?.clsField}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={() => {
                          handleClsField();
                        }}
                        disabled={values?.serviceFrequency === onCall}
                      />
                    }
                    label={t("customerSchedule.csl")}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    labelId="comments"
                    label={t("customerSchedule.comments")}
                    value={values?.comment}
                    onChange={(event) => {
                      setFieldValue(fieldName.comment, event.target.value);
                      if (
                        buttonListArray[4].disabled &&
                        values?.comment?.trim()
                      ) {
                        buttonListArray[4].disabled = false;
                        setButtonList([...buttonListArray]);
                      }
                    }}
                    fullWidth
                    multiline
                    rows={4}
                    name="review-comments"
                    id="review-comments"
                    inputProps={{ maxLength: 225 }}
                  />
                </FormControl>
              </Grid>

              <CalenderUi
                selectedDateList={selectedDateList}
                setSelectedDateList={setSelectedDateList}
                handleError={handleError}
                serviceFrequency={values?.serviceFrequency}
                startDate={values?.from}
                adapterLocale={localeByBranch}
              />
            </>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="start"
              gap={spacing.verticalMargin20}
            >
              {buttonListArray?.map((btn) => (
                <Button
                  id="clear-button"
                  variant={btn?.variant}
                  type="button"
                  onClick={() => {
                    handleClick(btn?.label);
                  }}
                  disabled={btn?.disabled || !values?.customerField?.value}
                  key={btn?.label}
                >
                  {btn?.label}
                </Button>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default CustomerScheduleLayout;
