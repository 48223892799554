import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { SelectField } from "components/core";
import TimePickerField from "components/core/time-picker-field/TimePickerField";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { transportationConstants } from "utils/constants/transportation-module/TransportationConstants";
import { RouteTimeTableStyles } from "./RouteTimeTableStyles.Styles";
const RouteDetailsEditableModal = ({
  currentFormState,
  selectedRouteForEdit,
  setSelectedRouteForEdit,
  routeScheduleForm,
  routeDetailsForm,
  vehicleList,
  handleUpdateScheduleRowData,
  transportationMode,
  employees,
  setModalError,
  setScheduleError,
  scheduleErrorMsg,
  scheduleError,
  modalError,
  selectedRouteCode,
  routeScheduleFormValues,
  setCurrentFormState,
  setIsFieldUpdated
}) => {
  const { t } = useTranslation();
  const day = routeScheduleForm?.values?.day;
  const handleInputChange = (name, value) => {
    routeScheduleForm.setFieldValue(name, value);
    if (currentFormState !== transportationConstants.formStatus.new) {
      setCurrentFormState(transportationConstants.formStatus.edit);
      setIsFieldUpdated(true);
    }
  };

  return (
    <>
      <CommonModal
        open={selectedRouteForEdit}
        title={t("routeDetails.editDay", { day: day })}
        body={
          <>
            {scheduleError && (
              <Collapse in={scheduleError}>
                <Alert
                  severity="error"
                  sx={RouteTimeTableStyles.marginBottom2EM}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={null}
                    >
                      <CloseIcon
                        fontSize="inherit"
                        onClick={() => setScheduleError(false)}
                      />
                    </IconButton>
                  }
                >
                  {scheduleErrorMsg}
                </Alert>
              </Collapse>
            )}
            <Grid container spacing={2}>
              {transportationMode ===
              transportationConstants.mode.ironMountain ? (
                <>
                  <Grid
                    item
                    xs="12"
                    lg="3"
                    md="3"
                    sm="12"
                    spacing={spacing.gap}
                  >
                    <TimePickerField
                      label={t("routeDetails.start")}
                      name="startTime"
                      minutesStep={15}
                      required
                      value={
                        routeScheduleForm?.values?.startTime === ""
                          ? null
                          : dayjs(
                              routeScheduleForm?.values?.startTime,
                              "HHmm"
                            ) || null
                      }
                      onChange={(value) =>
                        handleInputChange("startTime", value)
                      }
                      disabled={null}
                    />
                  </Grid>
                  <Grid
                    item
                    xs="12"
                    lg="3"
                    md="3"
                    sm="12"
                    spacing={spacing.gap}
                  >
                    <TimePickerField
                      label={t("routeDetails.depart")}
                      name="departTime"
                      minutesStep={15}
                      required
                      value={
                        routeScheduleForm?.values?.departTime === ""
                          ? null
                          : dayjs(
                              routeScheduleForm?.values?.departTime,
                              "HHmm"
                            ) || null
                      }
                      onChange={(value) =>
                        handleInputChange("departTime", value)
                      }
                      disabled={null}
                      fullwidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs="12"
                    lg="3"
                    md="3"
                    sm="12"
                    spacing={spacing.gap}
                  >
                    <TimePickerField
                      label={t("routeDetails.arrive")}
                      name="arriveTime"
                      required={true}
                      minutesStep={15}
                      value={
                        routeScheduleForm?.values?.arriveTime === ""
                          ? null
                          : dayjs(
                              routeScheduleForm?.values?.arriveTime,
                              "HHmm"
                            ) || null
                      }
                      onChange={(value) =>
                        handleInputChange("arriveTime", value)
                      }
                      disabled={null}
                    />
                  </Grid>
                  <Grid
                    item
                    xs="12"
                    lg="3"
                    md="3"
                    sm="12"
                    spacing={spacing.gap}
                  >
                    <TimePickerField
                      label={t("routeDetails.stop")}
                      name="stopTime"
                      minutesStep={15}
                      required={true}
                      value={
                        routeScheduleForm?.values?.stopTime === ""
                          ? null
                          : dayjs(
                              routeScheduleForm?.values?.stopTime,
                              "HHmm"
                            ) || null
                      }
                      onChange={(value) => handleInputChange("stopTime", value)}
                      disabled={null}
                    />
                  </Grid>
                  <Grid
                    item
                    xs="12"
                    lg="6"
                    md="6"
                    sm="12"
                    padding={spacing.gap}
                  >
                    <SelectField
                      label={t("routeDetails.veh")}
                      name="vehicleNumber"
                      value={routeScheduleForm.values.vehicleNumber}
                      disabled={null}
                      options={vehicleList.data || []}
                      loading={vehicleList.isLoading}
                      onChange={(e) =>
                        handleInputChange("vehicleNumber", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs="12"
                    lg="6"
                    md="6"
                    sm="12"
                    padding={spacing.gap}
                  >
                    <Autocomplete
                      componentsProps={{
                        clearIndicator: ArrowDown
                      }}
                      name="serviceRepEmployeeId"
                      popupIcon={<ArrowDown />}
                      clearIcon={<CloseIcon />}
                      id="serviceRepEmployeeId"
                      disabled={null}
                      options={employees?.data || []}
                      size="medium"
                      value={routeScheduleForm?.values?.serviceRepEmployeeId}
                      onChange={(e, newValue) =>
                        handleInputChange("serviceRepEmployeeId", newValue)
                      }
                      sx={RouteTimeTableStyles.autocompleteDropdownStyles}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("routeDetails.serviceRepEmployeeId")}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs="12" lg="12" md="12" sm="12">
                  <TimePickerField
                    label={t("routeDetails.depart")}
                    name="departTime"
                    required={true}
                    value={
                      routeScheduleForm?.values?.departTime === ""
                        ? null
                        : dayjs(
                            routeScheduleForm?.values?.departTime,
                            "HHmm"
                          ) || null
                    }
                    onChange={(value) => handleInputChange("departTime", value)}
                    disabled={null}
                  />
                </Grid>
              )}
            </Grid>
          </>
        }
        buttons={
          <>
            <Grid item xs="auto" lg="auto" md="auto" sm="auto">
              <Button
                id="cancel-update"
                variant="outlined"
                onClick={() => {
                  setSelectedRouteForEdit(false);
                  routeScheduleForm.setValues(routeScheduleFormValues);
                  setModalError(false);
                  setScheduleError(false);
                }}
              >
                {t("common.cancel")}
              </Button>
            </Grid>
            <Grid item xs="auto" lg="auto" md="auto" sm="auto">
              <Button
                id="update"
                variant="contained"
                onClick={handleUpdateScheduleRowData}
                disabled={null}
              >
                {t("common.update")}
              </Button>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default RouteDetailsEditableModal;
