import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS, ROLE_ACCESS_CODES } from "utils/constants";
import { eventStatus } from "utils/constants/disaster-module/EventSearchConstants";
import { validateUserHasProgramId } from "utils/helpers";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";

const RequestInfo = ({
  requestInfo,
  EventDetailForm,
  handleOnSave,
  handleDrRequestGenerator,
  pageIsViewOnly,
  handleViewDrMedia
}) => {
  const { t } = useTranslation();
  const isRequestGeneratorAccessable =
    validateUserHasProgramId(ROLE_ACCESS_CODES.code_257) ||
    validateUserHasProgramId(ROLE_ACCESS_CODES.code_265);
  const columns = [
    {
      name: "request_id",
      label: t(`disasterRecovery.eventDetail.requestId`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "request_type",
      label: t(`disasterRecovery.eventDetail.requestType`),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        }
      }
    },
    {
      name: "service_location",
      label: t(`disasterRecovery.eventDetail.serviceLocation`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "request_status",
      label: t(`disasterRecovery.eventDetail.status`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "num_open_media",
      label: t(`disasterRecovery.eventDetail.openMedia`),
      options: {
        customBodyRender: (value) => {
          if (value) {
            return value;
          } else {
            return 0;
          }
        },
        filter: true,
        sort: false
      }
    },
    {
      name: "om_pick_status",
      label: t(`disasterRecovery.eventDetail.omPickingStatus`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "num_containers",
      label: t(`disasterRecovery.eventDetail.container`),
      options: {
        customBodyRender: (value) => {
          if (value) {
            return value;
          } else {
            return 0;
          }
        },
        filter: true,
        sort: false
      }
    },
    {
      name: "container_processing_status",
      label: t(`disasterRecovery.eventDetail.containerProcessingStatus`),
      options: {
        filter: true,
        sort: false
      }
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: 1,
    pagination: requestInfo?.length && true,
    textLabels: {
      body: {
        noMatch:
          pageIsViewOnly === true
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    customToolbar: () => (
      <Grid
        item
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        gap={spacing.verticalMargin20}
      >
        {/* View DR Media button */}
        <Button
          variant="outlined"
          id="view-dr-media"
          style={
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_257) ||
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_266)
              ? disasterRecoveryStyles.tableStyles.buttons
              : disasterRecoveryStyles.tableStyles.hiddenButton
          }
          onClick={handleViewDrMedia}
          disabled={
            (EventDetailForm.values.drEventStatusId === eventStatus.logged ||
              EventDetailForm.values.drEventStatusId === eventStatus.cancel ||
              EventDetailForm.values.drEventStatusId ===
                eventStatus.newEvent) &&
            (validateUserHasProgramId(ROLE_ACCESS_CODES.code_257) ||
              validateUserHasProgramId(ROLE_ACCESS_CODES.code_266))
          }
        >
          {t("disasterRecovery.eventDetail.viewDrMedia")}
        </Button>
        <Button
          variant="contained"
          style={
            isRequestGeneratorAccessable
              ? disasterRecoveryStyles.tableStyles.buttons
              : disasterRecoveryStyles.tableStyles.hiddenButton
          }
          disabled={
            !(
              isRequestGeneratorAccessable &&
              (EventDetailForm.values.drEventStatusId ===
                eventStatus.outBound ||
                EventDetailForm.values.drEventStatusId === eventStatus.inBound)
            )
          }
          id="generate-request"
          onClick={() => handleDrRequestGenerator(false)}
        >
          {t("disasterRecovery.eventDetail.generateRequest")}
        </Button>

        <Button
          variant="outlined"
          style={
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_257) &&
            validateUserHasProgramId(ROLE_ACCESS_CODES.code_261)
              ? disasterRecoveryStyles.tableStyles.buttons
              : disasterRecoveryStyles.tableStyles.hiddenButton
          }
          disabled={
            !(
              EventDetailForm.values.drEventStatusId === eventStatus.logged &&
              validateUserHasProgramId(ROLE_ACCESS_CODES.code_257) &&
              validateUserHasProgramId(ROLE_ACCESS_CODES.code_261)
            )
          }
          id="initiate-event"
          onClick={() => handleOnSave("2")}
        >
          {t("disasterRecovery.eventDetail.initiateEvent")}
        </Button>
      </Grid>
    )
  };
  return (
    <>
      <Stack direction={"column"} gap={spacing.gap}>
        <Typography style={disasterRecoveryStyles.commonStyles.subHeading}>
          {t("disasterRecovery.eventDetail.requestInfo")}
        </Typography>

        <MUIDataTable
          columns={columns}
          options={options}
          data={requestInfo}
          editMode="row"
          rowModesModel={null}
          onRowModesModelChange={null}
          onRowEditStop={null}
          processRowUpdate={null}
          onRowClick={null}
          title={
            <Typography style={commonStyles.tableRecordsFoundTitleStyle}>
              {requestInfo?.length} {t("common.recordsFound")}
            </Typography>
          }
        />
      </Stack>
      <Divider />
    </>
  );
};

export default RequestInfo;
