import { Paper, TableContainer } from "@mui/material";

import { Box } from "@mui/material";
import { t } from "i18next";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { WorkManagementStyles } from "./WorkManagementStyles";
const { default: MUIDataTable } = require("mui-datatables");

const WorkManagementDataTable = ({
  tableData,
  selectedRow,
  columns,
  isModalOpen,
  handleRowClick,
  title,
  customToolbar,
  customOptions
}) => {
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    ...customOptions,
    pagination: tableData.length && true,
    sort: true,
    customToolbar,
    textLabels: {
      body: {
        noMatch:
          tableData?.length === 0 && `${t("common.tableTextWithoutInputs")}`
      }
    },
    onRowClick: handleRowClick

    // setRowProps: (row, dataIndex) => {
    //   const rowData = tableData[dataIndex];
    //   const completedFlag = rowData ? rowData.completed_flag : null;
    //   return {
    //     style: {
    //       backgroundColor:
    //         dataIndex === selectedRow
    //           ? WorkManagementStyles?.selectedColor
    //           : completedFlag === Y_CHECKER
    //             ? WorkManagementStyles?.completeColor
    //             : ""
    //     }
    //   };
    // }
  };
  return (
    <>
      <Paper sx={WorkManagementStyles?.tablePaper}>
        <TableContainer>
          <Box
            sx={WorkManagementStyles.getTableWrapperStyles(tableData?.length)}
          >
            <MUIDataTable
              columns={columns}
              options={options}
              data={tableData}
              title={title}
            />
          </Box>
        </TableContainer>
      </Paper>
    </>
  );
};
export default WorkManagementDataTable;
