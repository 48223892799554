import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { requestModuleStyles } from "components/features/request-module/media-request-search/MediaRequestSearchModule.Styles";
import i18n from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MEDIA_REQUEST_DETAIL, REQUEST_MODULE_BASE_PATH } from "routing/Paths";
import { useFindMediaRequestMutation } from "services/api";
import { MediaRequestDetailFormStyles } from "../media-request-detail-form/MediaRequestDetailForm.Styles";

const findRequestValidationSchema = Yup.object().shape({
  requestId: Yup.string().required(i18n.t("mediaRequestDetail.makeAnEntry"))
});

const FindRequestModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [requestNotFound, setRequestNotFound] = useState(false);
  const [
    findMediaRequest,
    { isLoading: loadingMediaRequest, reset: resetMediaRequest }
  ] = useFindMediaRequestMutation(); // fetch details for a request id
  const findRequestForm = useFormik({
    initialValues: { requestId: "" },
    validationSchema: findRequestValidationSchema,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      if (findRequestForm.isValid) {
        findMediaRequest(values?.requestId).then(({ data }) => {
          if (data) {
            navigate(
              `${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}/${values.requestId}`,
              {
                replace: true,
                state: { mediaRequest: data }
              }
            );
            props.onClose((prevState) => false);
          } else {
            setRequestNotFound((prevState) => true);
          }
        });
      }
      formikHelpers.setSubmitting(false);
    }
  });

  // cancel button click event handler
  const handleOnCancel = () => {
    if (loadingMediaRequest) {
      resetMediaRequest(); // cancel fetching request
    }
    props.onClose(false);
  };

  return (
    <>
      <Modal
        id="findMediaRequestModal"
        open={props.show}
        aria-labelledby="find-request-modal"
        aria-describedby="find-request-modal-content"
      >
        <Card sx={requestModuleStyles.cardStyle_DRMPopUP}>
          {/* ------------------ Title Section ------------------ */}

          <Typography
            id="findMediaRequestModalTitle"
            variant="h6"
            sx={requestModuleStyles.mainTitle_PRSPopUP}
            color="text.secondary"
            gutterBottom
          >
            {t("mediaRequestDetail.findRequest")}
          </Typography>

          <Divider />
          {/* ------------------ Body Section ------------------ */}

          <CardContent>
            {requestNotFound && (
              <Alert
                id="findMediaRequestModalError"
                severity="warning"
                color="warning"
                sx={{ borderRadius: "9px", marginBottom: "20px" }}
                onClose={() => setRequestNotFound((prevState) => false)}
              >
                {t("mediaRequestDetail.noRecordsFound")}
              </Alert>
            )}
            <TextField
              id="findMediaRequestModalRequestId"
              name="requestId"
              label={t("mediaRequestDetail.requestId")}
              size="medium"
              variant="outlined"
              sx={{ width: "310px" }}
              value={findRequestForm.values.requestId}
              onChange={findRequestForm.handleChange}
              error={
                findRequestForm.touched.requestId &&
                Boolean(findRequestForm.errors.requestId)
              }
              helperText={
                findRequestForm.touched.requestId &&
                findRequestForm.errors.requestId
              }
            />
          </CardContent>

          <Divider />
          {/* ------------------ Footer Section ------------------ */}

          <CardActions sx={requestModuleStyles.cardActionsStyle_PRSPopUP}>
            <Button
              id="findMediaRequestModalCancelButton"
              sx={requestModuleStyles.buttonStyle_PRSPopUP}
              variant="outlined"
              type="button"
              onClick={handleOnCancel}
            >
              {t("mediaRequestDetail.cancel")}
            </Button>
            <Button
              id="findMediaRequestModalFindButton"
              sx={requestModuleStyles.buttonStyle_PRSPopUP}
              variant="contained"
              type="button"
              onClick={() => findRequestForm.handleSubmit()}
            >
              {loadingMediaRequest && (
                <CircularProgress
                  sx={{
                    ...MediaRequestDetailFormStyles.ButtonCircularProgress,
                    marginRight: ".5rem"
                  }}
                />
              )}
              {t("mediaRequestDetail.find")}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

FindRequestModal.propTypes = {
  show: PropTypes.bool, // show/hide modal state
  onClose: PropTypes.func // handle modal close event
};

export default FindRequestModal;
