import { Stack } from "@mui/material";
import GroupEmployeeLayout from "components/security-module/security-group-employee/GroupEmployeeLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const SecurityGroupEmployee = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("securityGroupEmployee.pageTitle")} />
      <GroupEmployeeLayout />
    </Stack>
  );
};

export default SecurityGroupEmployee;
