import { ThemeProvider, useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  createTheme
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import { getResponseData } from "components/transportation-module/run-management/helper";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  VALUE_ALL,
  VALUE_EMPTY_STRING
} from "utils/constants";
import { findErrorMessage } from "utils/helpers";
import { branchOutagePickListCompletionStyles } from "./BranchOutagePickListStyles";
const AssignTransportModal = ({
  isAssignModalOpen,
  handleAssignModalClose,
  onOkClick,
  selectedRow,
  setIsLoading,
  mainDistrictId,
  customerId
}) => {
  const [tfValue, setTfValue] = useState(VALUE_EMPTY_STRING);
  const [isAddEnable, setIsAddEnable] = useState(false);
  const [tempList, setTempList] = useState([]);
  const [isListUpdated, setIsListUpdated] = useState(false);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] =
    useState(VALUE_EMPTY_STRING);

  const [trList, setTrList] = useState([]);

  useEffect(() => {
    isAssignModalOpen && setTempList(selectedRow?.assignTransportListArr);
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    // eslint-disable-next-line
  }, [selectedRow, isAssignModalOpen]);

  useEffect(() => {
    if (selectedRow?.assignTransportListArr?.length !== tempList?.length) {
      setIsListUpdated(true);
    } else {
      setIsListUpdated(false);
    }
  }, [selectedRow, tempList]);

  const handleTfChange = (event) => {
    const inputValue = event.target.value.toUpperCase();

    if (inputValue.length <= 6) {
      if (inputValue.length === 1 && inputValue !== "T") {
        return;
      }

      // Ensure first two characters are "TR"
      if (
        inputValue.length < 2 ||
        (inputValue.length >= 2 && inputValue.substring(0, 2) === "TR")
      ) {
        // Ensure next characters (after "TR") are alphanumeric up to length 4
        if (
          inputValue.length <= 2 ||
          /^[A-Z0-9]*$/.test(
            inputValue.substring(2, Math.min(4, inputValue.length))
          )
        ) {
          // Ensure last characters (after "TR" + alphanumeric) are numeric up to length 6
          if (
            inputValue.length <= 4 ||
            /^[0-9]*$/.test(inputValue.substring(4))
          ) {
            setTfValue(inputValue); // Update the input value if valid
          }
        }
      }

      setThrowError(false);
      setThrowErrorMessage(VALUE_EMPTY_STRING);

      if (inputValue?.length === 6) {
        // check if it's already there in the table
        const isAlreadyAdded = tempList?.find(
          (item) => item.value === inputValue
        );
        if (isAlreadyAdded) {
          setThrowError(true);
          setThrowErrorMessage(t("branchOutagePickList.alreadyExists"));
        } else {
          setIsAddEnable(true);
        }
      } else {
        setIsAddEnable(false);
      }
    }
  };

  const onClose = () => {
    handleAssignModalClose(
      isListUpdated ? selectedRow?.assignTransportListArr : cleanArray(tempList)
    );
    setTempList([]);
    setIsListUpdated(false);
    setTfValue(VALUE_EMPTY_STRING);
  };

  const onSaveClick = () => {
    onOkClick(cleanArray(tempList));
    setTempList([]);
    setIsListUpdated(false);
    setTfValue(VALUE_EMPTY_STRING);
  };

  const handleRemoveRow = (tableMeta) => {
    const newData = tempList.filter(
      (item) => item.value !== cleanParam(tempList[tableMeta.rowIndex].value)
    );
    setTempList(newData);
  };

  const cleanParam = (param) => {
    return param.replace(/[*#]/g, VALUE_EMPTY_STRING);
  };

  const cleanArray = (array) => {
    return array.map((item) => ({
      value: cleanParam(item.value)
    }));
  };

  const validateTransport = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        transport_number: tfValue,
        customer_id:
          customerId === VALUE_ALL ? selectedRow?.customer_id : customerId,
        request_id: selectedRow?.picking_request_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.validateAssignTransports,
        1
      );

      const obj = dataSets?.data;

      if (obj[0][0]?.error) {
        const msg = await getMessageFromCode(obj[0][0]?.error);
        const errorMsg = msg[0]?.descr;
        setThrowErrorMessage(errorMsg.replace("|", tfValue));
        setThrowError(true);
      } else {
        setThrowErrorMessage(VALUE_EMPTY_STRING);
        setThrowError(false);

        const transportValue = {
          value: tfValue
        };

        setTrList((prev) => [...prev, obj[0][0]]);
        setTempList((prev) => [...prev, transportValue]);

        setTfValue(VALUE_EMPTY_STRING);
        setIsAddEnable(false);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = () => {
    validateTransport();
  };

  const globalTheme = useTheme();
  const commonDataTableTheme = createTheme(globalTheme, {
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: "15px"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: "12px"
          }
        }
      }
    }
  });

  const columns = [
    {
      name: "value",
      label: t("branchOutagePickList.assignedTransports"),
      options: {
        customBodyRender: (value, tableMeta) => {
          /**
           * Transport_Flag (0- Customer owned, 1- Temporary Transport, 2- CSL Owned)
           * Temporary Transports are displayed with an asterisk (*).
           * CSL are displayed with a #.
           */
          let formattedValue = value;
          const transportFlag = trList?.find(
            (item) => item.transport_number === value
          )?.transport_flag;
          if (transportFlag === "1") {
            formattedValue = `${value}*`;
          }

          if (transportFlag === "2") {
            formattedValue = `${value}#`;
          }
          return (
            <>
              <Button
                sx={{ padding: 0 }}
                onClick={() => handleRemoveRow(tableMeta)}
              >
                <SvgIcon component={CloseIcon} />
              </Button>
              {formattedValue}
            </>
          );
        }
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    tableBodyHeight: "200px",
    tableBodyMaxHeight: "200px",
    pagination: 0 & true,
    textLabels: {
      body: {
        noMatch:
          selectedRow?.assignTransportListArr?.length === 0 &&
          `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  return (
    <CommonModal
      open={isAssignModalOpen}
      title={t("branchOutagePickList.assignTransport")}
      body={
        <>
          {throwError && (
            <Collapse in={throwError}>
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Collapse>
          )}

          <Card sx={branchOutagePickListCompletionStyles.assignTransportsCard}>
            <Box
              pb={2}
              sx={branchOutagePickListCompletionStyles.assignTransportsBox}
            >
              <TextField
                label={t("branchOutagePickList.transportNumber")}
                variant="outlined"
                value={tfValue}
                size="small"
                onChange={handleTfChange}
                inputProps={{
                  maxLength: 6
                }}
                InputLabelProps={{ shrink: true, style: { zIndex: 0 } }}
              />
              <Button
                sx={
                  branchOutagePickListCompletionStyles.assignTransportsAddButton
                }
                onClick={handleAdd}
                disabled={!isAddEnable}
              >
                {t("branchOutagePickList.add")}
              </Button>
            </Box>

            <div style={branchOutagePickListCompletionStyles.tableDiv}>
              <ThemeProvider theme={commonDataTableTheme}>
                <MUIDataTable
                  columns={columns}
                  options={options}
                  data={tempList}
                />
              </ThemeProvider>
            </div>

            <Box mt={3}>
              <Typography variant="subtitle2">
                {t("branchOutagePickList.assignTModalLabel")}
              </Typography>
            </Box>
          </Card>
        </>
      }
      buttons={
        <>
          <Button onClick={onClose} variant="outlined">
            {t("common.close")}
          </Button>
          <Button
            onClick={onSaveClick}
            variant="contained"
            disabled={!isListUpdated}
          >
            {t("common.ok")}
          </Button>
        </>
      }
    />
  );
};
export default AssignTransportModal;
