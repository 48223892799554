import { Stack } from "@mui/material";
import { ErrorLog } from "components/billing-module";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const BillingErrorLog = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("billingErrorLog.pageTitle")} />
      <ErrorLog />
    </Stack>
  );
};

export default BillingErrorLog;
