import CloseIcon from "@mui/icons-material/Close";
import { Alert, Divider, IconButton, Stack } from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";
import { getResponseData } from "components/transportation-module/run-management/helper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OPEN_MEDIA_AUDIT_DETAIL,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH
} from "routing/Paths";
import {
  useGetAllCustomersQuery,
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { CF_URLS } from "services/api/endpoints";
import {
  getAudiType,
  getAuditStatusData,
  getDistricts,
  getMessageFromCode,
  getRequestorTypeData
} from "services/api/query";
import { selectAuth, setAuthenticationState } from "store/slices";
import {
  clearError,
  clearWarning,
  fetchDataFailure,
  fetchDataRequest,
  fetchDataSuccess,
  resetForm,
  setAuthCustomers,
  setError,
  setLoading,
  setSearchResults,
  setWarning,
  updateAuthFormValues,
  updateAuthentication,
  updateFormValues
} from "store/slices/open-media-audit-search";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  DEFAULT_LOCALE_CODE,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER,
  authSection,
  dateFormat,
  downloadReportOptions,
  errorMsgs,
  numberConstants
} from "utils/constants";
import {
  B,
  C,
  auditTypesBranch,
  auditTypesCustomer,
  globalAttributeTypeIdCalFuture,
  globalAttributeTypeIdCalPast,
  globalAttributeTypeIdMaxRowLimit,
  notCompletedSelection,
  programSecurityCode308,
  systemId
} from "utils/constants/open-media-processing/OpenMediaAuditSearchConstants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getLanguageId,
  getSortedData,
  getUserName,
  printReport
} from "utils/helpers";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import AuditAuthForm from "components/shared/auth/AuditAuthSection";
import { getAuthorizationPersonnelsAuthentications } from "services/common";
import { OpenMediaAuditSearchStyles } from "./OpenMediaAuditSearchStyles";
import OpenMediaAuditSearch from "./openMediaAuditSearchForm";
import OpenMediaAuditSearchTable from "./openMediaAuditSearchTable";
const OpenMediaAuditSearchLayout = () => {
  const {
    formValues,
    searchResults,
    isLoading,
    throwError,
    throwErrorMsg,
    authFormValues,
    isAuthenticated,
    throwWarning,
    throwWarningMsg,
    authCustomers
  } = useSelector((state) => state.openMediaAuditSearch);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = location?.state?.from || null;
  const { currentBranch, auth } = useSelector(selectAuth);
  const today = dayjs();
  const programSecurityList = auth?.user?.program_list;
  const programSecurityListArray = programSecurityList.split(",").map(Number);
  const isNewVisible = programSecurityList.includes(programSecurityCode308);

  const dispatch = useDispatch();
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const [showAuthenticationSection, setShowAuthenticationSection] =
    useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [valuePersonnelAuth] = useState(authSection?.personnelAuth);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const languageLocaleId = getLanguageId();
  const [auditTypes, setAuditTypes] = useState([]);
  const [auditStatusList, setAuditStatusList] = useState([
    notCompletedSelection
  ]);
  const [reqList, setReqList] = useState([ALL_SELECTION]);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [searchText, setSearchText] = useState(null);
  const [isFindButtonDisabled, setIsFindButtonDisabled] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [selectedPrintFormat, setSelectedPrintFormat] = useState(
    downloadReportOptions[0]
  );
  const [throwPrintError, setThrowPrintError] = useState(false);
  const [throwPrintErrorMessage, setThrowPrintErrorMessage] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [maxRecordMsg, setMaxRecordMsg] = useState("");

  let initialAuthValues = {
    customer: "",
    personnel: "",
    authNumber: "",
    challengeQuestion: "",
    challengeResponse: ""
  };

  const openMediaAuditSearchForm = useFormik({
    initialValues: formValues,
    onSubmit: (values) => {
      onFindClick();
    }
  });

  const resetValues = () => {
    const updates = {
      reqBy: ALL_SELECTION,
      auditType: ALL_SELECTION,
      auditStatus: ALL_SELECTION,
      dueDate: null,
      dueTo: null,
      exceptionsOnly: false,
      auditDate: null,
      auditTo: null,
      media_number: ""
    };
    dispatch(updateFormValues(updates));
  };

  const openMediaAuthForm = useFormik({
    initialValues: authFormValues
  });

  // selected personnel auth details
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: authFormValues?.personnel?.value
  });

  // getting the Data from useGetAllCustomersQuery
  const { data: allCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  // getting the Customers on branch change in authentication
  const { data: authenticatedCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: authFormValues?.branch
  });

  const getOMauditMaxDispaly = async () => {
    const auditSearchMaxDisplay = await getGlobalAttributeValue(
      currentBranch?.value,
      systemId,
      globalAttributeTypeIdMaxRowLimit
    );
    return auditSearchMaxDisplay;
  };

  const getMinDate = async () => {
    const auditSearchMinDate = await getGlobalAttributeValue(
      currentBranch?.value,
      systemId,
      globalAttributeTypeIdCalPast
    );
    const minDateNumber =
      parseInt(auditSearchMinDate) / numberConstants.tweleve;
    const minDateFormatted = today.subtract(minDateNumber, "year");
    setMinDate(minDateFormatted);
  };

  const getMaxDate = async () => {
    const auditSearchMaxDate = await getGlobalAttributeValue(
      currentBranch?.value,
      systemId,
      globalAttributeTypeIdCalFuture
    );
    const maxDateNumber =
      parseInt(auditSearchMaxDate) / numberConstants.tweleve;
    const maxDateFormatted = today.add(maxDateNumber, "year");
    setMaxDate(maxDateFormatted);
  };

  useEffect(() => {
    if (throwError) {
      window.scrollTo(0, 0);
    }
  }, [throwError]);

  useEffect(() => {
    getAllBarnchesFromFireStore();
    getMaxDate();
    getMinDate();
    getAuditStatusList();
    getReqTypeList();
    if (!prevLocation) {
      handleReset();
      dispatch(clearError());
      dispatch(clearWarning());
      dispatch(setSearchResults([]));
    } else {
      setIsDataLoaded(false);
      onFindClick();
    }
    // eslint-disable-next-line
  }, []);

  // set the default branch into the auth section
  useEffect(() => {
    if (allBranches && allBranches?.length) {
      dispatch(setLoading(true));
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      const updates = {
        branch: selectedLocationDetails?.district_id
      };
      dispatch(updateAuthFormValues(updates));

      dispatch(setLoading(false));
    }
    // eslint-disable-next-line
  }, [allBranches]);

  useEffect(() => {
    if (authFormValues?.branch) {
      if (isAuthenticated) {
        getAllocatedCustomers();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authFormValues?.branch, isAuthenticated]);
  // get challange question
  useEffect(() => {
    if (personnelAuth?.length) {
      const updates = {
        ...authFormValues,
        challengeQuestion: personnelAuth[0]?.challenge_question
      };
      dispatch(updateAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [personnelAuth]);

  const getAuditTypeList = async (code) => {
    try {
      const result = await getAudiType(code);
      const response = result?.map((res) => ({
        value: res.audit_type_id,
        label: res.descr
      }));
      const responseWithAll = [ALL_SELECTION].concat(response);
      responseWithAll.sort((a, b) => a.label.localeCompare(b.label));
      setAuditTypes(responseWithAll);
    } catch (err) {
      dispatch(dispatch(setError(ERROR_TYPES.ISSUE)));
    }
  };
  const handleRequestor = (requestor) => {
    if (requestor === C) {
      setAuditTypes([ALL_SELECTION, ...auditTypesCustomer]);
    } else if (requestor === B) {
      setAuditTypes([ALL_SELECTION, ...auditTypesBranch]);
    }
  };
  const getAuditStatusList = async () => {
    try {
      const result = await getAuditStatusData();
      const response = result.map((res) => ({
        value: res.audit_status_id,
        label: res.descr
      }));

      response.sort((a, b) => b.label.localeCompare(a.label));
      const responseWithAll = [
        ALL_SELECTION,
        ...response,
        notCompletedSelection
      ];

      setAuditStatusList(responseWithAll);
    } catch (err) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const getReqTypeList = async () => {
    try {
      const result = await getRequestorTypeData(numberConstants?.six);
      const response = result.map((res) => ({
        value:
          res.dropdown_list_id === numberConstants?.twentyFour
            ? B
            : res.dropdown_list_id === numberConstants?.twentyFive
              ? C
              : res.dropdown_list_id,
        label: res.descr
      }));
      const responseWithAll = [ALL_SELECTION].concat(response);
      responseWithAll.sort((a, b) => a.label.localeCompare(b.label));
      setReqList(responseWithAll);
    } catch (err) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const onFindClick = async () => {
    dispatch(fetchDataSuccess([]));
    dispatch(clearError());
    dispatch(fetchDataRequest());
    setMaxRecordMsg("");
    const {
      auditId,
      auditType,
      auditStatus,
      auditDate,
      auditTo,
      customer,
      reqBy,
      dueDate,
      dueDateTo,
      media_number,
      exceptionsOnly
    } = formValues;
    const maxRowLimit = await getOMauditMaxDispaly();
    try {
      const auditToDate = auditTo ? auditTo : auditDate;
      const dueToDate = dueDateTo ? dueDateTo : dueDate;
      if (auditTo && auditTo < auditDate) {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80165);
        const errorMsg = errorObj[0]?.descr;
        const errorString = errorMsg?.replace(
          "{0}",
          t("openMediaAuditSearch.auditDate")
        );
        dispatch(
          fetchDataFailure(errorString || findErrorMessage(ERROR_TYPES.ISSUE))
        );
        return;
      }
      if (dueDateTo && dueDateTo < dueDate) {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80165);
        const errorMsg = errorObj[0]?.descr;
        const errorString = errorMsg?.replace(
          "{0}",
          t("openMediaAuditSearch.dueDate")
        );
        dispatch(
          fetchDataFailure(errorString || findErrorMessage(ERROR_TYPES.ISSUE))
        );
        return;
      }
      let reqBody = null;
      let customersList = "";
      if (isAuthenticated) {
        const ids = authCustomers
          .map((cust) => cust.value)
          .filter((value) => value !== t("common.all"));
        customersList = customer
          ? customer?.value === t("common.all")
            ? ids.join(",")
            : customer.value
          : "";
      } else {
        customersList = customer
          ? customer?.value === t("common.all")
            ? ""
            : customer.value
          : "";
      }
      if (auditId && auditId > 0) {
        reqBody = JSON.stringify({
          main_district_id:
            authFormValues?.branch?.district_id || mainDistrictId,
          om_audit_id: auditId,
          customer_id_list: customersList,
          iso_locale_code: DEFAULT_LOCALE_CODE,
          with_exception_only_flag: VALUE_N,
          row_limit: maxRowLimit
        });
      } else {
        reqBody = JSON.stringify({
          main_district_id:
            authFormValues?.branch?.district_id || mainDistrictId,
          om_audit_id: null,
          audit_type_id:
            auditType?.value === t("common.all") ? "" : auditType?.value,
          customer_id_list: customersList,
          audit_date_from:
            auditDate && dayjs(auditDate).format(dateFormat.universalFormat),
          audit_date_to:
            auditToDate &&
            dayjs(auditToDate).format(dateFormat.universalFormat),
          requestor_code: reqBy?.value === t("common.all") ? "" : reqBy?.value,
          audit_status_id:
            auditStatus?.value === t("common.all") ? "" : auditStatus?.value,
          due_date_from:
            dueDate && dayjs(dueDate).format(dateFormat.universalFormat),
          due_date_to:
            dueToDate && dayjs(dueToDate).format(dateFormat.universalFormat),
          iso_locale_code: DEFAULT_LOCALE_CODE,
          with_exception_only_flag: exceptionsOnly ? VALUE_Y : VALUE_N,
          media_number: media_number,
          row_limit: maxRowLimit
        });
      }

      let res = await getResponseData(
        reqBody,
        `${CF_URLS.openMediaAuditSearch.openmediaauditsearch}`,
        4
      );

      dispatch(setLoading(false));
      if (res) {
        const data0 = res?.data[0];
        data0.sort((a, b) => a.due_date.localeCompare(b.due_date));

        setIsDataLoaded(true);
        setIsResetGrid(false);
        if (data0?.length > maxRowLimit) {
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80168);
          const errorMsg = errorObj[0]?.descr;
          const errorString = errorMsg?.replace("{0}", maxRowLimit);
          dispatch(fetchDataFailure(errorString));
          const msg =
            t("openMediaAuditSearch.matchRecordCount") + " > " + maxRowLimit;
          setMaxRecordMsg(msg);
          return;
        }
        dispatch(fetchDataSuccess(data0));

        if (res?.data[0][0]?.error) {
          const errorObj = await getMessageFromCode(res?.data[0][0]?.error);
          const errorMsg = errorObj[0]?.descr;
          fetchDataFailure(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE));
        } else if (res?.error) {
          dispatch(fetchDataFailure(res?.error));
        }
        if (auditId && auditId > 0) {
          resetValues();
        }
      }
    } catch (e) {
      dispatch(fetchDataFailure(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    dispatch(setLoading(true));
    try {
      const branches = await getDistricts();
      let bran = branches?.map((b) => {
        return {
          ...b,
          label: b?.name,
          value: b?.district_id
        };
      });
      const branchData = getSortedData(bran, "name", "asc");
      setAllBranches(branchData);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.EXCEPTION)));
      dispatch(setLoading(false));
    }
  };

  // if authenticated get the allocated customers
  const getAllocatedCustomers = async () => {
    dispatch(setLoading(true));
    try {
      if (authenticatedCustomers) {
        const data = await getAuthorizationPersonnelsAuthentications({
          personnelId: authFormValues?.personnel?.value
        });
        const personalCustomers = JSON.parse(data?.customer_list);
        const personalCustomersList = personalCustomers?.map(
          ({ number, name, customer_Id }) => ({
            label: `${number} - ${name.trim()}`,
            value: customer_Id,
            number: number
          })
        );
        personalCustomersList?.sort((a, b) =>
          String(a.value).localeCompare(String(b.value))
        );
        if (personalCustomersList.length) {
          const allCux = [ALL_SELECTION, ...personalCustomersList];
          dispatch(setAuthCustomers(allCux));
          const updatedValues = {
            auditId: "",
            customer: authFormValues?.customer
          };
          dispatch(updateFormValues(updatedValues));

          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80251);
          const errorMsg = errorObj[0]?.descr;

          dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
        } else {
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80241);
          const errorMsg = errorObj[0]?.descr;

          dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
          dispatch(setAuthCustomers([]));
        }
      } else {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80241);
        const errorMsg = errorObj[0]?.descr;

        dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
        const updatedValues = {
          ...authFormValues,
          authNumber: "",
          challengeQuestion: "",
          challengeResponse: ""
        };
        dispatch(updateAuthFormValues(updatedValues));
        setShowAuthenticationSection(true);
        dispatch(updateAuthentication(false));
      }
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      dispatch(setLoading(false));
    }
  };

  const handlePersonnelAuthChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue,
      authNumber: "",
      challengeQuestion: "",
      challengeResponse: ""
    };
    dispatch(updateAuthFormValues(updatedValues));
    dispatch(updateAuthentication(false));
    setIsAuthenticationError(false);
    dispatch(clearError());
  };
  const isShowAuthenticationSection = () => {
    if (showAuthenticationSection) {
      return true;
    }
    return false;
  };

  const onAuthenticatedCallerCliked = () => {
    if (isAuthenticated) {
      dispatch(setAuthenticationState(null));
      dispatch(updateAuthFormValues(initialAuthValues));
      dispatch(updateAuthentication(false));
      dispatch(resetForm());
      dispatch(setSearchResults([]));
    } else if (showAuthenticationSection) {
      setShowAuthenticationSection(false);
    } else {
      setShowAuthenticationSection(true);
    }
    dispatch(clearError());
    dispatch(clearWarning());
  };

  const onCancelAuthCliked = () => {
    setShowAuthenticationSection(false);
  };

  // handle branch change
  const handleBranchChange = (e) => {
    const updatedValues = {
      branch: e.target.value,
      customer: "",
      personnel: ""
    };

    dispatch(updateAuthFormValues(updatedValues));
  };

  // customer selection in auth section
  const handleCustomerChange = (event, newValue) => {
    dispatch(clearError());
    const updatedValues = {
      ...authFormValues,
      customer: newValue,
      personnel: ""
    };

    dispatch(updateAuthFormValues(updatedValues));
  };
  // handle Personnel selection in auth section
  const handlePersonnelChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue
    };
    dispatch(updateAuthFormValues(updatedValues));
  };

  const handleAuthFieldsChange = (name, value) => {
    const updatedValues = {
      ...authFormValues,
      [name]: value
    };
    dispatch(updateAuthFormValues(updatedValues));
  };
  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  // authentication click
  const onAuthenticateClick = async () => {
    dispatch(setLoading(true));
    setIsAuthenticationError(false);
    try {
      // Check if either authNumber or challengeResponse is provided
      if (
        !authFormValues?.authNumber?.length &&
        !authFormValues?.challengeResponse?.length
      ) {
        setIsAuthenticationError(true);
        return;
      }

      // Authenticate with authNumber
      if (authFormValues?.authNumber?.length) {
        if (authFormValues.authNumber === personnelAuth[0]?.auth_number) {
          dispatch(updateAuthentication(true));

          dispatch(setSearchResults([]));
        } else {
          setIsAuthenticationError(true);
        }
      }

      // Authenticate with challengeResponse
      if (authFormValues?.challengeResponse?.length) {
        const hashCode = await generateSHA256Hash(
          authFormValues.challengeResponse.trim().toUpperCase()
        );

        const validateChallengeResponse =
          await triggerValidateChallengeResponse({
            main_district_id: String(currentBranch?.district_id),
            language_locale_id: languageLocaleId,
            personnel_id: authFormValues?.personnel?.value,
            challenge_response: hashCode
          });

        if (validateChallengeResponse?.data[0]?.is_match_flag === Y_CHECKER) {
          dispatch(updateAuthentication(true));
          dispatch(setSearchResults([]));
        } else {
          setIsAuthenticationError(true);
        }
      }
      openMediaAuthForm.setValues(authFormValues);
      const updates = {
        auditId: "",
        media_number: "",
        reqBy: ALL_SELECTION,
        auditType: ALL_SELECTION,
        auditStatus: notCompletedSelection,
        customer: null,
        auditDate: "",
        auditTo: "",
        dueDate: "",
        dueDateTo: "",
        exceptionsOnly: false
      };
      if (isAuthenticated) {
        updates.customer = authFormValues?.customer;
      }
      dispatch(updateFormValues(updates));
    } catch (error) {
      setIsAuthenticationError(true);
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const isShowSearchSection = () => {
    if (isAuthenticated) {
      return true;
    }
    if (showAuthenticationSection) {
      return false;
    }
    return true;
  };

  const handleReset = () => {
    if (isAuthenticated) {
      const updates = {
        reqBy: ALL_SELECTION,
        auditType: ALL_SELECTION,
        auditStatus: notCompletedSelection,
        dueDate: null,
        dueTo: null,
        exceptionsOnly: false,
        auditDate: null,
        auditTo: null,
        auditId: null,
        customer: authFormValues?.customer
      };
      dispatch(updateFormValues(updates));
    } else {
      dispatch(resetForm());
    }
    setIsResetGrid(true);
  };

  const handleNavigationClick = (id) => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_AUDIT_DETAIL}/${id}`,
      { state: { id } }
    );
  };

  const handlePrintButtonClick = () => {
    setShowPrintModal(true);
  };

  //handle download
  const handleDownload = async () => {
    try {
      dispatch(setLoading(true));
      setThrowPrintError(false);
      const {
        auditId,
        auditType,
        auditStatus,
        auditDate,
        auditTo,
        customer,
        reqBy,
        dueDate,
        dueDateTo,
        media_number,
        exceptionsOnly
      } = formValues;
      const maxRowLimit = await getOMauditMaxDispaly();
      const auditToDate = auditTo ? auditTo : auditDate;
      const dueToDate = dueDateTo ? dueDateTo : dueDate;
      let customersList = "";
      if (isAuthenticated) {
        const ids = authCustomers
          .map((cust) => cust.value)
          .filter((value) => value !== t("common.all"));
        customersList = customer
          ? customer?.value === t("common.all")
            ? ids.join(",")
            : customer.value
          : "";
      } else {
        customersList = customer
          ? customer?.value === t("common.all")
            ? ""
            : customer.value
          : "";
      }
      const reqBody = JSON.stringify({
        main_district_id: authFormValues?.branch?.district_id || mainDistrictId,
        om_audit_id: auditId && auditId > 0 ? auditId : "",
        audit_type_id:
          auditType?.value === t("common.all") ? "" : auditType?.value,
        customer_id_list: customersList,
        audit_date_from:
          auditDate && dayjs(auditDate).format(dateFormat.universalFormat),
        audit_date_to:
          auditToDate && dayjs(auditToDate).format(dateFormat.universalFormat),
        requestor_code: reqBy?.value === t("common.all") ? "" : reqBy?.value,
        audit_status_id:
          auditStatus?.value === t("common.all") ? "" : auditStatus?.value,
        due_date_from:
          dueDate && dayjs(dueDate).format(dateFormat.universalFormat),
        due_date_to:
          dueToDate && dayjs(dueToDate).format(dateFormat.universalFormat),
        iso_locale_code: DEFAULT_LOCALE_CODE,
        with_exception_only_flag: exceptionsOnly ? VALUE_Y : VALUE_N,
        media_number: media_number,
        row_limit: maxRowLimit,
        district_name: currentBranch.name.replace("_", " "),
        user_name: getUserName(auth?.user),
        report_format: selectedPrintFormat?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.openMediaProcessing.auditSummaryReport}`
      );
      if (!reportResponse.success) {
        setThrowPrintErrorMessage(reportResponse.error);
        setThrowPrintError(true);
      }
    } catch (e) {
      setThrowPrintErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowPrintError(true);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleCancel = () => {
    setThrowPrintError(false);
    setShowPrintModal(false);
  };

  return (
    <>
      {prevLocation
        ? (isLoading || !isDataLoaded) && (
            <FullCircularLoader loading={isLoading || !isDataLoaded} />
          )
        : isLoading && <FullCircularLoader loading={isLoading} />}
      <form>
        <Stack gap={spacing.gap} sx={OpenMediaAuditSearchStyles.mainDivStyles}>
          <AuthenticationCallerButton
            isAuthenticated={isAuthenticated}
            onAuthenticatedCallerCliked={onAuthenticatedCallerCliked}
          />
          {throwWarning && (
            <Alert
              severity="warning"
              icon={<img src={WarningIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(clearWarning());
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwWarningMsg}
            </Alert>
          )}
          {throwError && (
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(clearError());
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMsg}
            </Alert>
          )}

          {isShowAuthenticationSection() && (
            <>
              {/* Personnel Authentication Section */}
              {!isAuthenticated && (
                <>
                  <AuditAuthForm
                    allBranches={allBranches}
                    handleBranchChange={handleBranchChange}
                    openMediaAuthForm={openMediaAuthForm}
                    valuePersonnelAuth={valuePersonnelAuth}
                    allCustomers={allCustomers}
                    handleCustomerChange={handleCustomerChange}
                    handlePersonnelChange={handlePersonnelChange}
                    onAuthenticateClick={onAuthenticateClick}
                    isAuthenticationError={isAuthenticationError}
                    isAuthenticated={isAuthenticated}
                    onCancelAuthCliked={onCancelAuthCliked}
                    programSecurityListArray={programSecurityListArray}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    setError={setError}
                    isLoading={isLoading}
                    handleChange={handleAuthFieldsChange}
                    setLoading={setLoading}
                    authFormValues={authFormValues}
                    authCustomers={authenticatedCustomers}
                  />
                </>
              )}
            </>
          )}

          {isAuthenticated && (
            <>
              <SuccessAuthSection
                personalDetailForm={openMediaAuthForm}
                valuePersonnelAuth={valuePersonnelAuth}
                handlePersonnelOnChange={handlePersonnelAuthChange}
                disablePersonnel={true}
              />
              <Divider />
            </>
          )}
          {/* Table Section */}
          {isShowSearchSection() && (
            <>
              <OpenMediaAuditSearch
                openMediaAuditSearchForm={openMediaAuditSearchForm}
                auditTypes={auditTypes}
                auditStatusList={auditStatusList}
                reqList={reqList}
                allCustomers={allCustomers}
                minDate={minDate}
                maxDate={maxDate}
                today={today}
                onFindClick={onFindClick}
                searchText={searchText}
                setSearchText={setSearchText}
                authCustomers={authCustomers}
                handleReset={handleReset}
                isFindButtonDisabled={isFindButtonDisabled}
                setIsFindButtonDisabled={setIsFindButtonDisabled}
                isAuthenticated={isAuthenticated}
                getAuditTypeList={getAuditTypeList}
                handleRequestor={handleRequestor}
                updateFormValues={updateFormValues}
                initialAuthValues={initialAuthValues}
              />
              <OpenMediaAuditSearchTable
                searchResults={searchResults}
                isResetGrid={isResetGrid}
                isNewVisible={isNewVisible}
                setSelectedRow={setSelectedRow}
                selectedRow={selectedRow}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                handleNavigationClick={handleNavigationClick}
                handlePrintButtonClick={handlePrintButtonClick}
                maxRecordMsg={maxRecordMsg}
              />
            </>
          )}
          {/* Download popup */}
          <DownloadPopup
            title={t("common.downloadReport")}
            showPrintModal={showPrintModal}
            downloadOptions={downloadReportOptions}
            onCancleClick={handleCancel}
            onDownloadClick={handleDownload}
            setSelectedValue={setSelectedPrintFormat}
            setThrowError={setThrowPrintError}
            throwError={throwPrintError}
            throwErrorMessage={throwPrintErrorMessage}
          />
        </Stack>
      </form>
    </>
  );
};
export default OpenMediaAuditSearchLayout;
