import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import CloseFilledIcon from "assets/images/CloseFilled.svg";
import PencilIcon from "assets/images/pencil.svg";
import { SelectField } from "components/core";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import {
  useGetGlobalAttributeValueByIdQuery,
  useGetInsertTypesQuery
} from "services/api";
import { getMessageByIdFromMessage } from "services/common";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  GLOBAL_ATTRIBUTE_VALUES
} from "utils/constants";
import {
  MESSAGE_63673,
  MESSAGE_63674
} from "utils/constants/request-module/MediaRequestDetailConstants";
import { limitFormikTextFieldMaxValue } from "utils/helpers";
import { MediaRequestDetailFormStyles } from "../../media-request-detail-form/MediaRequestDetailForm.Styles";
import { selectInsertsModalStyles } from "./SelectInsertsModal.Styles";
import { insertTypeValidationSchema } from "./schema";
import { sortInsertTypes } from "../../utils";

const formInitialValues = { insert_type_id: 0, insert_type: "", quantity: 0 };

const dataTableOptions = {
  ...DEFAULT_DATA_TABLE_OPTIONS,
  selectableRows: "none",
  jumpToPage: false,
  pagination: false,
  responsive: "vertical",
  tableBodyHeight: "230px",
  tableBodyMaxHeight: "230px",
  textLabels: {
    body: {
      noMatch: i18n.t("mediaRequestDetail.noItemsToDisplay")
    }
  }
};

const SelectInsertsModal = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ show: false, message: "" });
  const [selectedRow, setSelectedRow] = useState(-1);
  const {
    data: globalAttribute81Value = "",
    isLoading: isLoadingGlobalAttribute81,
    isError: isGlobalAttribute81Error
  } = useGetGlobalAttributeValueByIdQuery(GLOBAL_ATTRIBUTE_VALUES.VALUE_81);

  // set row data to the form
  const handleEditRow = (rowIndex) => {
    const data = insertConfigurations[rowIndex];
    selectInsertsForm.setValues({
      insert_type_id: data.insert_type_id,
      insert_type: data.insert_type,
      quantity: data.quantity
    });
    setSelectedRow(rowIndex);
  };

  // column definition of the insert configurations data table
  const tableColumnsDefinition = [
    {
      name: "insert_type_id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnEdit${value}`}
            aria-label="edit"
            onClick={() => handleEditRow(tableMeta.rowIndex)}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={PencilIcon} alt="edit icon" />
          </IconButton>
        )
      }
    },
    {
      name: "insert_type_id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() =>
              setInsertConfigurations((prevState) =>
                prevState.filter(
                  (insert) => Number(insert.insert_type_id) !== Number(value)
                )
              )
            }
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "insert_type",
      label: t("mediaRequestDetail.insertTypeCaps")
    },
    {
      name: "quantity",
      label: t("mediaRequestDetail.quantityCaps")
    }
  ];

  const [insertConfigurations, setInsertConfigurations] = useState([]);
  const quantityEntered = useMemo(() => {
    let quantity = 0;
    if (insertConfigurations.length > 0) {
      insertConfigurations.forEach((item) => (quantity += item.quantity));
    }
    return quantity;
  }, [insertConfigurations]); // added inserts quantity

  const selectInsertsForm = useFormik({
    initialValues: formInitialValues,
    validationSchema: insertTypeValidationSchema,
    onSubmit: (values, formikHelpers) => {
      if (selectInsertsForm.isValid) {
        if (selectedRow < 0) {
          setInsertConfigurations((prevState) => prevState.concat(values));
        } else {
          setInsertConfigurations((prevState) =>
            prevState.map((insert, index) =>
              index === selectedRow ? values : insert
            )
          );
          setSelectedRow(-1);
        }
        formikHelpers.resetForm();
      }
    }
  });

  // insert type on change event handler
  const handleInsertTypeOnChange = (event) => {
    selectInsertsForm.setValues({
      insert_type_id: event.target.value,
      insert_type: extraInsertTypes.find(
        (item) => item.value === event.target.value
      )?.label,
      quantity: Number(
        insertConfigurations.length === 0 ? props.requiredQuantity : 0
      ) // set quantity as the required quantity if a insert selected for the first time
    });
  };

  // Added inserts reset event handler
  const handleReset = (_) => {
    setInsertConfigurations((prevState) => []);
    selectInsertsForm.resetForm();
  };

  // Added inserts submit event handler
  const handleSubmit = async (_) => {
    if (quantityEntered > props.requiredQuantity) {
      const message = await getMessageByIdFromMessage({
        message_id: MESSAGE_63674
      });
      setError((prevState) => ({
        show: true,
        message: message[0]?.descr
      }));
    } else if (quantityEntered < props.requiredQuantity) {
      const message = await getMessageByIdFromMessage({
        message_id: MESSAGE_63673
      });
      setError((prevState) => ({
        show: true,
        message: message[0]?.descr
      }));
    } else {
      props.onSubmit(insertConfigurations);
    }
  };

  // set selected inserts to the state
  useEffect(() => {
    if (props.selectedInsertConfigs != null) {
      setInsertConfigurations(props.selectedInsertConfigs);
    }
  }, [props.selectedInsertConfigs]);

  const { data: extraInsertTypes = [], isLoading: isExtraInsertTypesLoading } =
    useGetInsertTypesQuery(); // fetch extra insert types

  return (
    <Modal
      id="selectInsertsModal"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="customer-media-identifier-modal-title"
      aria-describedby="customer-media-identifier-modal-description"
    >
      <Card
        id="selectInsertsModalCard"
        sx={selectInsertsModalStyles.cardStyle_PRSPopUP}
      >
        <Typography
          id="selectInsertsModalTitle"
          variant="h6"
          sx={selectInsertsModalStyles.mainTitle_PRSPopUP}
          color="secondary"
          gutterBottom
        >
          {t("mediaRequestDetail.selectInserts")}
        </Typography>

        <Divider />

        <CardContent
          id="selectInsertsModalCardContent"
          sx={selectInsertsModalStyles.cardContentStyle_PRSPopUP}
        >
          <Grid id="selectInsertsModalGrid1" container columnSpacing={8}>
            <Grid id="selectInsertsModalGrid2" item>
              <Typography
                id="selectInsertsModalQuantityRequired"
                sx={selectInsertsModalStyles.infoLabel}
              >
                {t("mediaRequestDetail.quantityRequired")}{" "}
                {props.requiredQuantity}
              </Typography>
            </Grid>
            <Grid id="selectInsertsModalGrid3" item>
              <Typography
                id="selectInsertsModalQuantityEntered"
                sx={selectInsertsModalStyles.infoLabel}
              >
                {t("mediaRequestDetail.quantityEntered")} {quantityEntered}
              </Typography>
            </Grid>
          </Grid>

          {/* error message */}
          {error.show && (
            <Alert
              id="selectInsertsModalErrorAlert"
              severity="warning"
              onClose={() =>
                setError((prevState) => ({ show: false, message: "" }))
              }
            >
              {error.message}
            </Alert>
          )}

          <Card
            id="selectInsertsModalCard1"
            sx={selectInsertsModalStyles.innerCard}
          >
            <Grid
              id="selectInsertsModalGrid4"
              container
              spacing={2}
              sx={{ padding: "16px" }}
            >
              {/* Insert type */}
              <Grid id="selectInsertsModalGrid5" item>
                <SelectField
                  id="selectInsertsModalInsertType"
                  name="insert_type_id"
                  size="small"
                  options={sortInsertTypes(extraInsertTypes, true)}
                  loading={isExtraInsertTypesLoading}
                  label={t("mediaRequestDetail.insertType")}
                  placeholder={t("mediaRequestDetail.insertType")}
                  value={selectInsertsForm.values?.insert_type_id}
                  error={
                    selectInsertsForm.touched.insert_type_id &&
                    Boolean(selectInsertsForm.errors.insert_type_id)
                  }
                  helperText={
                    selectInsertsForm.touched.insert_type_id &&
                    selectInsertsForm.errors.insert_type_id
                  }
                  onChange={handleInsertTypeOnChange}
                  sx={selectInsertsModalStyles.insertTypeText}
                />
              </Grid>

              {/* quantity */}
              <Grid id="selectInsertsModalGrid5" item>
                <TextField
                  id="selectInsertsModalInsertQuantity"
                  size="small"
                  name="quantity"
                  label={t("mediaRequestDetail.quantity")}
                  type="number"
                  variant="outlined"
                  value={selectInsertsForm.values?.quantity}
                  error={
                    selectInsertsForm.touched.quantity &&
                    Boolean(selectInsertsForm.errors.quantity)
                  }
                  helperText={
                    selectInsertsForm.touched.quantity &&
                    selectInsertsForm.errors.quantity
                  }
                  onChange={(event) =>
                    limitFormikTextFieldMaxValue(selectInsertsForm, event, 99)
                  }
                  sx={selectInsertsModalStyles.quantityText}
                />
              </Grid>

              {/* Add button */}
              <Grid id="selectInsertsModalGrid6" item>
                <Button
                  id="selectInsertsModalAddButton"
                  size="medium"
                  variant="outlined"
                  sx={selectInsertsModalStyles.buttonStyle_PRSPopUP}
                  onClick={() => selectInsertsForm.handleSubmit()}
                >
                  {selectedRow < 0
                    ? t("mediaRequestDetail.add")
                    : t("mediaRequestDetail.update")}
                </Button>
              </Grid>
            </Grid>

            {/* Table need to go inside this */}
            <Grid id="selectInsertsModalGrid7" container spacing={2}>
              <Grid id="selectInsertsModalGrid8" item xs={12}>
                <MUIDataTable
                  id="selectInsertsModalDataTable"
                  data={insertConfigurations}
                  columns={tableColumnsDefinition}
                  options={dataTableOptions}
                />
              </Grid>
            </Grid>
          </Card>
        </CardContent>
        <Divider />
        <Grid id="selectInsertsModalCard1HelpGrid">
          <Button
            color="primary"
            target="popup"
            disableElevation
            disableRipple
            disabled={isLoadingGlobalAttribute81 || isGlobalAttribute81Error}
            sx={selectInsertsModalStyles.textLinkButton}
            onClick={() => {
              window.open(
                globalAttribute81Value,
                "popup",
                "width=600,height=600,align=center"
              );
              return false;
            }}
          >
            {t("mediaRequestDetail.needHelpSelectingInsertType")}
          </Button>
        </Grid>
        <CardActions
          id="selectInsertsModalCard1Actions"
          sx={selectInsertsModalStyles.cardActionsStyle_PRSPopUP}
        >
          <Button
            id="selectInsertsModalCancelButton"
            variant="outlined"
            sx={selectInsertsModalStyles.buttonStyle_PRSPopUP}
            type="reset"
            onClick={props.onClose}
          >
            {t("mediaRequestDetail.cancel")}
          </Button>
          <Button
            id="selectInsertsModalClearButton"
            variant="outlined"
            sx={selectInsertsModalStyles.buttonStyle_PRSPopUP}
            onClick={handleReset}
          >
            {t("mediaRequestDetail.clear")}
          </Button>
          <Button
            id="selectInsertsModalOkButton"
            variant="contained"
            sx={selectInsertsModalStyles.buttonStyle_PRSPopUP}
            type="submit"
            onClick={handleSubmit}
          >
            {t("mediaRequestDetail.ok")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SelectInsertsModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  requiredQuantity: PropTypes.number.isRequired, // required inserts quantity
  selectedInsertConfigs: PropTypes.array, // selected insert configs
  onClose: PropTypes.func, // handle modal close event
  onSubmit: PropTypes.func // handle modal submit event
};

export default SelectInsertsModal;
