import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  location: null
};

export const eventSearchSlice = createSlice({
  name: "eventSearch",
  initialState,
  reducers: {
    setLocationValue: (state, action) => {
      state.location = action.payload;
    }
  }
});

export const { setLocationValue } = eventSearchSlice.actions;

export default eventSearchSlice.reducer;
