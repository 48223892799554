import dayjs from "dayjs";
import { dateFormat } from "utils/constants";
import { fieldNames } from "utils/constants/employee/EmployeeDetailConstant";

export const getFormData = (data) => {
  let formData = {
    [fieldNames.employeeId]: (data && data[fieldNames.employeeId]) || "",
    [fieldNames.firstName]: (data && data[fieldNames.firstName]) || "",
    [fieldNames.middleName]: (data && data[fieldNames.middleName]) || "",
    [fieldNames.lastName]: (data && data[fieldNames.lastName]) || "",
    [fieldNames.employmentStartDate]:
      (data && data[fieldNames.employmentStartDate]) || "",
    [fieldNames.employmentEndDate]:
      (data && data[fieldNames.employmentEndDate]) || "",
    [fieldNames.jobTitleId]: (data && data[fieldNames.jobTitleId]) || "",
    [fieldNames.operationalCenterId]:
      (data && data[fieldNames.operationalCenterId]) || "",
    [fieldNames.businessEmailAddr]:
      (data && data[fieldNames.businessEmailAddr]) || "",

    [fieldNames.businessPhone]: (data && data[fieldNames.businessPhone]) || "",
    [fieldNames.extention]: (data && data[fieldNames.businessPhnExt]) || "",
    [fieldNames.country]: (data && data[fieldNames.country]) || "",
    [fieldNames.phoneCode]: (data && data[fieldNames.phoneCode]) || "",
    [fieldNames.cellularPhone]: (data && data[fieldNames.cellularPhone]) || "",
    [fieldNames.pagerPhone]: (data && data[fieldNames.pagerPhone]) || "",
    [fieldNames.homeEmailAddr]: (data && data[fieldNames.homeEmailAddr]) || "",
    [fieldNames.homePhone]: (data && data[fieldNames.homePhone]) || "",
    [fieldNames.payrollId]: (data && data[fieldNames.payrollId]) || "",
    [fieldNames.emergencyContactFlag]:
      (data && data[fieldNames.emergencyContactFlag]) || "",
    [fieldNames.defaultBranchId]: (data && data[fieldNames.districtId]) || "",
    [fieldNames.assignedBranchList]:
      (data && data[fieldNames.districtList]) || "",
    [fieldNames.activeBranchList]:
      (data && data[fieldNames.localEmployeeDistrictList]) || "",
    [fieldNames.securityGroupId]:
      (data && data[fieldNames.securityGroupId]) || "",
    [fieldNames.loggedInEmployeeId]:
      (data && data[fieldNames.loggedInEmployeeId]) || "",
    [fieldNames.localeId]: (data && data[fieldNames.localeId]) || ""
  };
  return formData;
};

export const formattedDate = (value) => {
  return dayjs(value).format(dateFormat.universalFormat);
};

export const getCountryPhoneCode = (value) => {
  return value?.split("-");
};

export const convertToString = (arrayValue) => {
  return arrayValue?.toString().replace(/,/g, "|");
};

export const setDateValue = async (value) => {
  return dayjs(
    dayjs(value, {
      strict: true
    }).toDate()
  );
};

let regexOnlyString = /^[a-zA-Z\s]*$/;
let regexOnlyNumber = /^[0-9]*$/;
let regexEmail = /^[^@]*$/;

export const isTextValid = (str) => regexOnlyString.test(str);
export const isNumValid = (num) => regexOnlyNumber.test(num);
export const isEmailValid = (str) => regexEmail.test(str);
