import { Stack } from "@mui/material";
import CorrectiveActionRequestScreen from "components/corrective-action-request/CorrectiveActionRequestScreen";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const CorrectiveActionRequest = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("correctiveActionRequest.title")} />
      <CorrectiveActionRequestScreen />
    </Stack>
  );
};

export default CorrectiveActionRequest;
