import { Stack } from "@mui/material";
import LabelPrinterSetupScreen from "components/label-printing/label-printer-setup/LabelPrinterSetupScreen";
import { PageTitle } from "components/shared";
import i18n from "i18n";

const LabelPrinterSetup = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={i18n.t("labelPrinterSetup.title")} />
      <LabelPrinterSetupScreen />
    </Stack>
  );
};
export default LabelPrinterSetup;
