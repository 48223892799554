import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Tooltip
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { ReactComponent as CollapseAll } from "assets/images/CollapseAll.svg";
import { ReactComponent as ExpandAll } from "assets/images/ExpandAll.svg";
import { ReactComponent as Find } from "assets/images/Find.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader, SelectField } from "components/core";
import Typography from "components/shared/Typography";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useFormik } from "formik";
import { useCallbackPrompt } from "hooks";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  ROUTE_MANAGEMENT
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import {
  getCustomersQueryData,
  getDayOfWeekCollection,
  getMessageFromCode
} from "services/api/query";
import {
  cancelExitProcedure,
  resetRouteState,
  selectRoute,
  setCurrentBranch,
  setHaveChanges
} from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES, VALUE_EMPTY_STRING, errorMsgs } from "utils/constants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData
} from "utils/helpers";
import FindCustomerModal from "./FindCustomerModal";
import { routeManagementStyles } from "./RouteManagementStyle";
import RoutePrintModal from "./RoutePrintModal";
import RouteTreeView from "./RouteTreeView";
import UnassignedTreeView from "./UnassignedTreeView";
import {
  getListOfExpandedNodes,
  getListOfExpandedNodesNewIds,
  getOpenNodes,
  getRouteList,
  getRouteTreeData,
  getSequenceNumber,
  getServiceTime,
  getUnassignedTreeData
} from "./helper";
import "./treeStyle.css";

const RouteManagement = () => {
  const { navigationData } = useParams();
  const [dayOfWeekOptions, setDayOfWeekOptions] = useState([]);
  const [unassignedCslCount, setUnassignedCslCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [routeData, setRouteData] = useState([]);
  const [unassignedData, setUnassignedData] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [isRouteExpand, setIsRouteExpand] = useState(false);
  const [isUnassignedExpand, setIsUnassignedExpand] = useState(false);
  const [selectedNodeIdMain, setSelectedNodeIdMain] = useState(null);
  const [selectedNodeIdSub, setSelectedNodeIdSub] = useState(null);
  const [currentRouteInfoMain, setCurrentRouteInfoMain] = useState(null);
  const [currentRouteInfoSub, setCurrentRouteInfoSub] = useState(null);
  const [unassignedTreeData, setUnassignedTreeData] = useState();
  const [routeTreeData, setRouteTreeData] = useState();
  const [unsavedNodes, setUnsavedNodes] = useState([]);
  const [isShowFindCustomerModal, setIsShowFindCustomerModal] = useState(false);
  const [isShowPrintModal, setIsShowPrintModal] = useState(false);
  // Handle Open State of Main Route Tree
  const [expandIdsMain, setExpandIdsMain] = useState([]);
  const [routeTreeExpandedNodesMain, setRouteTreeExpandedNodesMain] = useState(
    []
  );
  // Handle Open State of Sub Route Tree
  const [expandIdsSub, setExpandIdsSub] = useState([]);
  const [routeTreeExpandedNodesSub, setRouteTreeExpandedNodesSub] = useState(
    []
  );
  // API Data
  const [insertData, setInsertData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  // Handle navigation with unsaved changes
  const [exitAfterSave, setExitAfterSave] = useState(false);
  const { haveChanges } = useSelector(selectRoute);
  const dispatch = useDispatch();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges);

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      setCurrentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const initalDayState = {
    selectedDay: null,
    dayOfWeek: null
  };

  const dayOfWeekForm = useFormik({
    initialValues: initalDayState
  });

  useEffect(() => {
    throwError && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [throwError]);

  useEffect(() => {
    if (isSaveEnabled) {
      dispatch(setHaveChanges(true));
    } else {
      dispatch(setHaveChanges(false));
    }
    //eslint-disable-next-line
  }, [isSaveEnabled]);

  useEffect(() => {
    if (mainDistrictId) {
      getDaysOfWeek();
      getUnassignedCslCount();
    }
    //eslint-disable-next-line
  }, [mainDistrictId]);

  useEffect(() => {
    openOldNodes();
    //eslint-disable-next-line
  }, [routeTreeData]);

  useEffect(() => {
    if (unassignedData.length === 0) {
      setUnassignedTreeData(unassignedData);
    } else if (unassignedData.length > 0 && customerData.length > 0) {
      setUnassignedTreeData(
        getUnassignedTreeData(unassignedData, customerData)
      );
    }
    //eslint-disable-next-line
  }, [customerData, unassignedData]);

  useEffect(() => {
    if (routeData.length === 0) {
      setRouteTreeData(routeData);
    } else if (routeData.length > 0 && customerData.length > 0) {
      setRouteTreeData(getRouteTreeData(routeData, customerData));
      setRouteList(getRouteList(routeData));
    }
    //eslint-disable-next-line
  }, [customerData, routeData]);

  const handleOpenCustomer = (customerName) => {
    let openNodeData = getOpenNodes(routeTreeData, customerName);
    setIsRouteExpand(false);
    setExpandIdsMain(openNodeData.openNodeList);
    window.setTimeout(function () {
      document.getElementById(openNodeData.scrollToId).scrollIntoView();
    }, 1000);
  };

  const sortValue = (value) => {
    return ((parseInt(value) + 5) % 7) + 1;
  };

  const getDaysOfWeek = async () => {
    try {
      setIsLoading(true);
      let data = await getDayOfWeekCollection();
      let dayOptions = [];
      let sortedData = navigationData
        ? data?.sort(
            (a, b) =>
              sortValue(a?.sequence_number) - sortValue(b?.sequence_number)
          )
        : data?.sort((a, b) => a?.sequence_number - b?.sequence_number);
      sortedData.forEach(async (day) =>
        dayOptions.push({
          value: day.day_of_week_code,
          label: day.name.trim()
        })
      );
      setDayOfWeekOptions(dayOptions);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getUnassignedCslCount = async () => {
    try {
      setIsLoading(true);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        customer_id: VALUE_EMPTY_STRING,
        day_of_week_code: VALUE_EMPTY_STRING
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.routeManagement.getUnassignedCslCount}`,
        2
      );
      if (data?.data[1][0]?.error === errorMsgs.noError) {
        setUnassignedCslCount(data?.data[0][0]?.count);
      } else {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const handleDayChange = async (e) => {
    setThrowError(false);
    let value = e.target?.value;
    dayOfWeekForm.setFieldValue("dayOfWeek", value);
    if (isSaveEnabled) {
      setShowConfirmPopup(true);
    } else {
      proceedDayChange(value);
    }
  };

  const proceedDayChange = (dayOfWeekCode) => {
    setShowConfirmPopup(false);
    setIsSaveEnabled(false);
    dayOfWeekForm.setFieldValue("selectedDay", dayOfWeekCode);
    setData(dayOfWeekCode);
    setIsRouteExpand(false);
    setExpandIdsMain([]);
    setRouteTreeExpandedNodesMain([]);
    setExpandIdsSub([]);
    setRouteTreeExpandedNodesSub([]);
    handleConfirmationOnNo();
  };

  const reset = () => {
    setThrowError(false);
    setIsLoading(true);
    setIsUnassignedExpand(false);
    setExpandIdsMain([]);
    setExpandIdsSub([]);
    setSelectedNodeIdMain(null);
    setSelectedNodeIdSub(null);
    setCurrentRouteInfoMain(null);
    setCurrentRouteInfoSub(null);
    setInsertData([]);
    setUpdateData([]);
  };

  const setData = async (dayOfWeekCode) => {
    reset();
    !customerData.length && getCustomerData();
    await Promise.all([
      getUnassignedCustomers(dayOfWeekCode),
      getAssignedRoutes(dayOfWeekCode)
    ]);
    setIsLoading(false);
  };

  const getCustomerData = async () => {
    try {
      let data = await getCustomersQueryData(mainDistrictId);
      setCustomerData(data);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getUnassignedCustomers = async (dayOfWeekCode) => {
    try {
      reset();
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        day_of_week_code: dayOfWeekCode
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.routeManagement.getUnassignedCustomers}`,
        2
      );
      let custData = data?.data;
      if (custData[1][0]?.error !== errorMsgs.noError) {
        setThrowErrorMessage(ERROR_TYPES.ISSUE);
        setThrowError(true);
      } else {
        setUnassignedData(custData[0]);
      }
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getAssignedRoutes = async (dayOfWeekCode) => {
    try {
      reset();
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        day_of_week_code: dayOfWeekCode
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.routeManagement.getAssignedRoutes}`,
        2
      );
      let routeData = data?.data;
      if (routeData[1][0]?.error !== errorMsgs.noError) {
        setThrowErrorMessage(ERROR_TYPES.ISSUE);
        setThrowError(true);
      } else {
        setRouteData(routeData[0]);
      }
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getError = async (msgCode, fieldName) => {
    const errorMsg = await getMessageFromCode(String(msgCode));
    if (errorMsg) {
      let message = errorMsg[0]?.descr.replace("|", fieldName);
      setThrowErrorMessage(message);
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const deleteNode = (tree, id, fieldName) => {
    let treeData = tree;
    var nodePosition = treeData
      .map((item) => {
        return item[fieldName];
      })
      .indexOf(id);
    if (nodePosition > -1) {
      treeData.splice(nodePosition, 1);
    }
    return tree;
  };

  const deleteUnassignedTreeNode = (node) => {
    let treeData = deleteNode(unassignedTreeData, node.id, "id");
    setUnassignedTreeData(treeData);
  };

  const setInsertApiData = (sourceNode, destinationNode) => {
    let serviceTime = getServiceTime(destinationNode?.text);
    let sequenceNumber = getSequenceNumber(
      routeTreeData,
      sourceNode,
      destinationNode
    );
    let data = {
      main_district_id: mainDistrictId,
      customer_schedule_id: sourceNode?.data?.customer_schedule_id,
      route_code: destinationNode?.data?.route_code.trim(),
      service_time: serviceTime,
      sequence_number: sequenceNumber.sequenceNumber.toString()
    };
    let node = [...unsavedNodes];
    node.push(sourceNode?.id);
    setUnsavedNodes(node);

    let curInsertData = deleteNode(
      insertData,
      data.customer_schedule_id,
      "customer_schedule_id"
    );
    curInsertData.push(data);
    setInsertData(curInsertData);
  };

  const setUpdateApiData = (sourceNode, destinationNode) => {
    let serviceTime = getServiceTime(destinationNode?.text);
    let sequenceNumber = getSequenceNumber(
      routeTreeData,
      sourceNode,
      destinationNode
    );
    let data = {
      main_district_id: mainDistrictId,
      route_customer_schedule_id: sourceNode?.data?.route_customer_schedule_id,
      new_route_code: destinationNode?.data?.route_code.trim(),
      new_service_time: serviceTime,
      new_sequence_number: sequenceNumber.sequenceNumber.toString(),
      ts1: destinationNode?.data?.timestamp_value
    };
    let node = [...unsavedNodes];
    node.push(sourceNode?.id);
    setUnsavedNodes(node);

    let curUpdateData = deleteNode(
      updateData,
      data.route_customer_schedule_id,
      "route_customer_schedule_id"
    );
    curUpdateData.push(data);
    setUpdateData(curUpdateData);
  };

  const handleSave = async () => {
    try {
      handleConfirmationOnYes();
      showConfirmPopup && setShowConfirmPopup(false);
      setIsLoading(true);
      for (const item of insertData) {
        let data = await getResponseData(
          JSON.stringify(item),
          `${CF_URLS.routeManagement.insertRoutes}`,
          2
        );
        if (data?.data[1][0]?.error !== errorMsgs.noError) {
          setIsLoading(false);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
          return;
        }
      }
      for (const item of updateData) {
        let data = await getResponseData(
          JSON.stringify(item),
          `${CF_URLS.routeManagement.updateRoutes}`,
          2
        );
        if (data?.data[1][0]?.error !== errorMsgs.noError) {
          setIsLoading(false);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
          return;
        }
      }
      await Promise.all([
        getUnassignedCustomers(dayOfWeekForm?.values?.dayOfWeek),
        getAssignedRoutes(dayOfWeekForm?.values?.dayOfWeek)
      ]);
      setUnsavedNodes([]);
      setInsertData([]);
      setUpdateData([]);
      setIsSaveEnabled(false);
      setIsLoading(false);
      if (exitAfterSave) {
        confirmNavigation();
        setExitAfterSave(false);
        return;
      }
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const openOldNodes = () => {
    if (routeTreeExpandedNodesMain.length > 0) {
      let newExpandedIDs = getListOfExpandedNodesNewIds(
        routeTreeData,
        routeTreeExpandedNodesMain
      );
      setExpandIdsMain(newExpandedIDs);
    }
    if (routeTreeExpandedNodesSub.length > 0) {
      let newExpandedIDs = getListOfExpandedNodesNewIds(
        routeTreeData,
        routeTreeExpandedNodesSub
      );
      setExpandIdsSub(newExpandedIDs);
    }
  };

  const updateOpenNodesMain = (items) => {
    setRouteTreeExpandedNodesMain(getListOfExpandedNodes(routeTreeData, items));
  };
  const updateOpenNodesSub = (items) => {
    setRouteTreeExpandedNodesSub(getListOfExpandedNodes(routeTreeData, items));
  };

  const handlePrint = async () => {
    setIsShowPrintModal(true);
  };

  // handle confirmation popup "Yes" click event handler
  const handleConfirmationOnYes = () => {
    setExitAfterSave(false);
    if (showPrompt) {
      setExitAfterSave(true);
      cancelNavigation();
    }
    dispatch(cancelExitProcedure());
  };

  // handle confirmation popup "No" event handler
  const handleConfirmationOnNo = () => {
    dispatch(resetRouteState());
    if (showPrompt) {
      confirmNavigation();
    }
  };

  // if navigation triggered by browser button
  const handleConfirmationOnCancel = () => {
    if (showPrompt) {
      cancelNavigation();
    }
  };

  useEffect(() => {
    if (navigationData && dayOfWeekOptions?.length) {
      dayOfWeekForm.setFieldValue(
        "dayOfWeek",
        dayOfWeekOptions[dayOfWeekOptions?.length - 1]?.value
      );
      if (isSaveEnabled) {
        setShowConfirmPopup(true);
      } else {
        proceedDayChange(dayOfWeekOptions[0]?.value);
      }
      window.history.replaceState(
        null,
        "",
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${ROUTE_MANAGEMENT}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationData, dayOfWeekOptions]);

  const routeTreeProps = {
    routeTreeData,
    setRouteTreeData,
    getError,
    setIsLoading,
    isRouteExpand,
    setThrowError,
    selectedNodeIdMain,
    setSelectedNodeIdMain,
    selectedNodeIdSub,
    setSelectedNodeIdSub,
    setIsSaveEnabled,
    unsavedNodes
  };

  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      {throwError && !isShowPrintModal && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            sx={routeManagementStyles.errorStyle}
            icon={<img src={ErrorIcon} alt="error" width="20" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Grid container spacing={spacing.gap}>
        <Grid item xs={6} md={3} lg={3} xl={3}>
          <SelectField
            label={t("routeManagement.dayOfWeek")}
            value={dayOfWeekForm?.values?.dayOfWeek}
            onChange={handleDayChange}
            options={dayOfWeekOptions}
            shrink={dayOfWeekForm?.values?.dayOfWeek}
            notched={dayOfWeekForm?.values?.dayOfWeek}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={3} xl={3}>
          <Typography variant="caption" component="small">
            {t("routeManagement.unassignedCustomers")}
          </Typography>
          <Typography variant="body1">{unassignedCslCount}</Typography>
        </Grid>
      </Grid>
      <Grid container columnSpacing={"2rem"}>
        <Grid item>
          <Card sx={routeManagementStyles.routeCardStyle}>
            <CardContent sx={routeManagementStyles.routeCardContentStyle}>
              <Typography
                variant="h5"
                sx={routeManagementStyles.routeCardTitleStyle}
              >
                {t("routeManagement.currentRoute")}
                <Box
                  sx={
                    routeData.length === 0
                      ? routeManagementStyles.iconDisableStyle
                      : routeManagementStyles.iconBoxStyle
                  }
                  disabled={routeData.length === 0}
                >
                  {!isRouteExpand ? (
                    <Tooltip title={t("common.expandAll")}>
                      <Box
                        sx={routeManagementStyles.iconStyle}
                        onClick={() => {
                          setExpandIdsMain([]);
                          setExpandIdsSub([]);
                          setIsRouteExpand(true);
                        }}
                      >
                        <ExpandAll />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t("common.collapseAll")}>
                      <Box
                        sx={routeManagementStyles.iconStyle}
                        onClick={() => {
                          setExpandIdsMain([]);
                          setExpandIdsSub([]);
                          setIsRouteExpand(false);
                        }}
                      >
                        <CollapseAll />
                      </Box>
                    </Tooltip>
                  )}
                  <Tooltip title={t("routeManagement.findCustomer")}>
                    <Box
                      sx={{
                        ...routeManagementStyles.iconStyle,
                        ...routeManagementStyles.findCustomerStyle
                      }}
                      onClick={() => setIsShowFindCustomerModal(true)}
                    >
                      <Find />
                    </Box>
                  </Tooltip>
                </Box>
              </Typography>
              <Typography
                sx={{
                  ...routeManagementStyles.routeCardHintStyle,
                  visibility: currentRouteInfoMain ? "visible" : "hidden"
                }}
              >
                {t("routeManagement.currentRoute") +
                  ": " +
                  currentRouteInfoMain}
              </Typography>
              <RouteTreeView
                {...routeTreeProps}
                mainTree={true}
                setCurrentRouteInfoMain={setCurrentRouteInfoMain}
                expandIds={expandIdsMain}
                updateOpenNodes={updateOpenNodesMain}
                deleteUnassignedTreeNode={deleteUnassignedTreeNode}
                setInsertApiData={setInsertApiData}
                setUpdateApiData={setUpdateApiData}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={routeManagementStyles.routeCardStyle}>
            <CardContent sx={routeManagementStyles.routeCardContentStyle}>
              <Typography
                variant="h5"
                sx={routeManagementStyles.routeCardTitleStyle}
              >
                {t("routeManagement.currentRoute")}
              </Typography>
              <Typography
                sx={{
                  ...routeManagementStyles.routeCardHintStyle,
                  visibility: currentRouteInfoSub ? "visible" : "hidden"
                }}
              >
                {t("routeManagement.currentRoute") + ": " + currentRouteInfoSub}
              </Typography>
              <RouteTreeView
                {...routeTreeProps}
                mainTree={false}
                setCurrentRouteInfoSub={setCurrentRouteInfoSub}
                expandIds={expandIdsSub}
                updateOpenNodes={updateOpenNodesSub}
                deleteUnassignedTreeNode={deleteUnassignedTreeNode}
                setInsertApiData={setInsertApiData}
                setUpdateApiData={setUpdateApiData}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card
            sx={{
              ...routeManagementStyles.routeCardStyle,
              ...routeManagementStyles.unassignedCardStyle
            }}
          >
            <CardContent sx={routeManagementStyles.routeCardContentStyle}>
              <Typography
                variant="h5"
                sx={routeManagementStyles.routeCardTitleStyle}
              >
                {t("routeManagement.customers")}
                <Box
                  sx={
                    unassignedData.length === 0
                      ? routeManagementStyles.iconDisableStyle
                      : routeManagementStyles.iconBoxStyle
                  }
                >
                  {!isUnassignedExpand ? (
                    <Tooltip title={t("common.expandAll")}>
                      <Box
                        sx={routeManagementStyles.iconStyle}
                        onClick={() => {
                          setIsUnassignedExpand(true);
                        }}
                      >
                        <ExpandAll />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t("common.collapseAll")}>
                      <Box
                        sx={routeManagementStyles.iconStyle}
                        onClick={() => {
                          setIsUnassignedExpand(false);
                        }}
                      >
                        <CollapseAll />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </Typography>
              <Typography sx={routeManagementStyles.routeCardHintStyle}>
                {t("routeManagement.dragHintText")}
              </Typography>
              <UnassignedTreeView
                unassignedTreeData={unassignedTreeData}
                isUnassignedExpand={isUnassignedExpand}
              />
              <Typography sx={routeManagementStyles.routeCardHintStyle}>
                {t("routeManagement.swHintText")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <Stack
        direction="row"
        spacing={spacing.verticalMargin20}
        sx={routeManagementStyles.buttonRowStyle}
      >
        <Button
          id="print-button"
          variant="outlined"
          color="primary"
          size="medium"
          type="button"
          onClick={handlePrint}
          disabled={routeList.length === 0}
        >
          {t("common.print")}
        </Button>
        <Button
          id="refresh-button"
          variant="contained"
          color="primary"
          size="medium"
          type="button"
          onClick={handleSave}
          disabled={!isSaveEnabled}
        >
          {t("common.save")}
        </Button>
      </Stack>
      {isShowFindCustomerModal && (
        <FindCustomerModal
          isShowFindCustomerModal={isShowFindCustomerModal}
          setIsShowFindCustomerModal={setIsShowFindCustomerModal}
          setThrowError={setThrowError}
          routeTreeData={routeTreeData}
          openCustomer={handleOpenCustomer}
        />
      )}
      {isShowPrintModal && (
        <RoutePrintModal
          isShowPrintModal={isShowPrintModal}
          setIsShowPrintModal={setIsShowPrintModal}
          routeList={routeList}
          dayOfWeekCode={dayOfWeekForm?.values?.dayOfWeek}
          mainDistrictId={mainDistrictId}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
          setThrowError={setThrowError}
          setIsLoading={setIsLoading}
        />
      )}

      {/* Confimation popup */}
      <ConfirmPopup
        modalPopupOpen={showConfirmPopup || showPrompt}
        handleCancel={() => {
          setShowConfirmPopup(false);
          dayOfWeekForm.setFieldValue(
            "dayOfWeek",
            dayOfWeekForm?.values?.selectedDay
          );
          handleConfirmationOnCancel();
        }}
        handleNo={() => proceedDayChange(dayOfWeekForm?.values?.dayOfWeek)}
        handleYes={() => handleSave()}
        showCancel={true}
        showNo={true}
      />
    </>
  );
};
export default RouteManagement;
