import { Box, Button, TextField } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { modalTextLimit } from "utils/constants/corrective-action-request";
import { CorrectiveActionRequestStyles } from "./CorrectiveActionRequestStyles";

const {
  default: CommonModal
} = require("components/shared/common-modal/CommonModal");

const CustomerComments = ({ isAddCommentModal, commentList, onClose }) => {
  const [comments, setComments] = useState("");
  useEffect(() => {
    setComments(commentList);
  }, [commentList]);
  return (
    <CommonModal
      open={isAddCommentModal}
      title={t("correctiveActionRequest.customerComments")}
      body={
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap={20}
            sx={CorrectiveActionRequestStyles.modalStyle}
          >
            <TextField
              label="Comments"
              multiline
              rows={5}
              maxRows={8}
              variant="outlined"
              fullWidth
              value={comments}
              disabled={true}
              inputProps={{
                maxLength: modalTextLimit
              }}
              onChange={(e) => setComments(e.target.value)}
            />
          </Box>
        </>
      }
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            {t("common.close")}
          </Button>
        </>
      }
    />
  );
};
export default CustomerComments;
