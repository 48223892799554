/* eslint-disable no-unused-vars */
import { Box, ThemeProvider, Typography, createTheme } from "@mui/material";

import { useTheme } from "@emotion/react";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  NO,
  YES,
  Y_CHECKER
} from "utils/constants";
import { GroupManagementStyles } from "./GroupManagementStyles";

const GroupManagementTable = ({ searchResults, isResetGrid }) => {
  const globalTheme = useTheme();
  const dataTableTheme = createTheme(globalTheme, {
    components: GroupManagementStyles.dataTableStyles
  });

  //Table columns
  const columns = [
    {
      name: "security_group_id",
      label: t("securityGroupManagement.enabled"),
      options: {
        customBodyRender: (value) =>
          value !== "" ? (
            <Typography sx={GroupManagementStyles.cellStylesBlue}>
              {YES}
            </Typography>
          ) : (
            <Typography sx={GroupManagementStyles.cellStylesBlue}>
              {NO}
            </Typography>
          )
      }
    },
    {
      name: "descr",
      label: t("securityGroupManagement.function"),
      options: {
        customBodyRender: (value) => (
          <Typography sx={GroupManagementStyles.cellStylesBlue}>
            {value}
          </Typography>
        )
      }
    },
    {
      name: "insert_flag",
      label: t("securityGroupManagement.insert"),
      options: {
        customBodyRender: (value, tableMeta) =>
          value === Y_CHECKER ? (
            <Typography
              sx={
                tableMeta.rowData[5] === Y_CHECKER
                  ? GroupManagementStyles.cellStylesBlue
                  : GroupManagementStyles.cellStylesGray
              }
            >
              {YES}
            </Typography>
          ) : (
            <Typography
              sx={
                tableMeta.rowData[5] === Y_CHECKER
                  ? GroupManagementStyles.cellStylesBlue
                  : GroupManagementStyles.cellStylesGray
              }
            >
              {NO}
            </Typography>
          )
      }
    },
    {
      name: "update_flag",
      label: t("securityGroupManagement.update"),
      options: {
        customBodyRender: (value, tableMeta) =>
          value === Y_CHECKER ? (
            <Typography
              sx={
                tableMeta.rowData[6] === Y_CHECKER
                  ? GroupManagementStyles.cellStylesBlue
                  : GroupManagementStyles.cellStylesGray
              }
            >
              {YES}
            </Typography>
          ) : (
            <Typography
              sx={
                tableMeta.rowData[6] === Y_CHECKER
                  ? GroupManagementStyles.cellStylesBlue
                  : GroupManagementStyles.cellStylesGray
              }
            >
              {NO}
            </Typography>
          )
      }
    },
    {
      name: "delete_flag",
      label: t("securityGroupManagement.delete"),
      options: {
        customBodyRender: (value, tableMeta) =>
          value === Y_CHECKER ? (
            <Typography
              sx={
                tableMeta.rowData[7] === Y_CHECKER
                  ? GroupManagementStyles.cellStylesBlue
                  : GroupManagementStyles.cellStylesGray
              }
            >
              {YES}
            </Typography>
          ) : (
            <Typography
              sx={
                tableMeta.rowData[7] === Y_CHECKER
                  ? GroupManagementStyles.cellStylesBlue
                  : GroupManagementStyles.cellStylesGray
              }
            >
              {NO}
            </Typography>
          )
      }
    },
    {
      name: "enable_insert_flag",
      options: {
        display: false
      }
    },
    {
      name: "enable_update_flag",
      options: {
        display: false
      }
    },
    {
      name: "enable_delete_flag",
      options: {
        display: false
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: 0,
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults?.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };

  return (
    <ThemeProvider theme={dataTableTheme}>
      <MUIDataTable
        columns={columns}
        options={options}
        data={searchResults}
        title={
          <Box sx={GroupManagementStyles.titleBox}>
            <Typography style={GroupManagementStyles.titleStyle}>
              {t("securityGroupManagement.tableTitle")}
            </Typography>
            <Typography style={GroupManagementStyles.headerText}>
              {t("securityGroupManagement.tableSubTitle")}
            </Typography>
          </Box>
        }
      />
    </ThemeProvider>
  );
};

export default GroupManagementTable;
