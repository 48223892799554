import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { SelectField } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import spacing from "styles/spacing";
import { Y_CHECKER, errorMsgs, messageCode } from "utils/constants";
import {
  spaceCode,
  vendorTypes
} from "utils/constants/transportation/RunManagementConstants";
import { runManagementStyles } from "./RunManagementStyle";
import {
  formatServiceTime,
  getAllRunNames,
  getDriverInfo,
  getVendorInfo
} from "./helper";

const RunModal = (props) => {
  const {
    isShowRunModal,
    vehicleData,
    driverData,
    vendorData,
    setIsShowRunModal,
    setIsLoading,
    throwError,
    throwErrorMessage,
    setThrowError,
    getError,
    runData,
    editRunInfo,
    saveRun
  } = props;

  const [isUpdateRequired, setIsUpdateRequired] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [vendorType, setVendorType] = useState(
    editRunInfo?.data?.transportation_vendor_name
      ? vendorTypes.thirdPartyVendor
      : vendorTypes.ironMountain
  );
  const _ = require("lodash");

  const initalRunState = {
    runId: editRunInfo?.data?.run_id || null,
    runName: editRunInfo?.data?.run_name || null,
    serviceTime: null,
    vendorType: vendorType,
    driver: editRunInfo?.data?.service_rep_employee_id
      ? getDriverInfo(driverData, editRunInfo?.data)
      : null,
    vehicle: editRunInfo?.data?.vehicle_number || null,
    vendor: editRunInfo?.data?.transportation_vendor_name
      ? getVendorInfo(vendorData, editRunInfo?.data)
      : null
  };

  const runForm = useFormik({
    initialValues: initalRunState
  });

  useEffect(() => {
    let isEqualForm = _.isEqual(runForm?.values, runForm?.initialValues);
    if (!isEqualForm) {
      runForm.setFieldValue("vendorType", vendorType);
      setIsUpdateRequired(true);
    } else {
      setIsUpdateRequired(false);
    }
    //eslint-disable-next-line
  }, [runForm.values]);

  const handleVendorTypeChange = (e) => {
    setVendorType(e.target.value);
  };

  const onKeyDown = (event) => {
    if (event.code === spaceCode) event.preventDefault();
  };

  const handleFieldChange = async (e) => {
    setIsError(false);
    const { name, value } = e.target;
    setThrowError(false);
    if (name === "serviceTime") {
      let regex = /^[0-9:]*$/;
      if (!regex.test(value)) {
        return;
      }
    }
    runForm.setFieldValue(name, value);
  };

  const handleOnBlur = async (e) => {
    const { name, value } = e.target;
    if (name === "serviceTime") {
      if (value !== "") {
        let inputValue = value.replace(":", "");
        let serviceTimeString = formatServiceTime(inputValue);
        if (serviceTimeString.error) {
          setIsLoading(true);
          await getError(errorMsgs.errorCode10137);
          setIsError(true);
        }
        runForm.setFieldValue(name, serviceTimeString.value);
      }
    } else if (name === "runName") {
      let runNames = getAllRunNames(runData);
      for (let i = 0; i < runNames.length; i++) {
        if (value.toLowerCase() === runNames[i].toLowerCase()) {
          setIsLoading(true);
          await getError(errorMsgs.errorCode10085);
          setIsError(true);
        }
      }
    }
    setIsLoading(false);
  };

  const handleCancelClick = () => {
    let isEqualForm = _.isEqual(runForm?.values, runForm?.initialValues);
    if (!isEqualForm) {
      setShowConfirmPopup(true);
    } else {
      setIsShowRunModal(false);
    }
  };

  const validateForm = async () => {
    if (
      (!runForm?.values?.runName || !runForm?.values?.serviceTime) &&
      !editRunInfo
    ) {
      let fieldName = !runForm?.values?.runName
        ? t("runManagement.run")
        : t("runManagement.serviceTime");
      setIsLoading(true);
      await getError(messageCode.requiredField, fieldName);
      showConfirmPopup && setShowConfirmPopup(false);
      setIsLoading(false);
    } else {
      saveRun(runForm);
    }
  };

  const handleUpdateClick = () => {
    validateForm();
  };

  const handleYesClick = () => {
    validateForm();
  };

  const handleNoClick = () => {
    setIsShowRunModal(false);
  };

  return (
    <>
      <CommonModal
        open={isShowRunModal}
        title={
          editRunInfo
            ? t("runManagement.editRunTitle")
            : t("runManagement.createRunTitle")
        }
        body={
          <Stack direction="column">
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  sx={runManagementStyles.errorIconStyle}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Grid container spacing={spacing.gap}>
              <Grid item xs={6}>
                <TextField
                  name="runName"
                  label={t("runManagement.runRequired")}
                  size="medium"
                  variant="outlined"
                  fullWidth
                  value={runForm.values.runName}
                  onChange={handleFieldChange}
                  onBlur={handleOnBlur}
                  onKeyDown={onKeyDown}
                  disabled={editRunInfo}
                  inputProps={{
                    maxLength: 10
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="serviceTime"
                  label={t("runManagement.serviceTimeRequired")}
                  size="medium"
                  variant="outlined"
                  fullWidth
                  value={runForm.values.serviceTime}
                  onChange={handleFieldChange}
                  onBlur={handleOnBlur}
                  onKeyDown={onKeyDown}
                  disabled={editRunInfo}
                  inputProps={{
                    maxLength: 5
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={spacing.gap}>
              <Grid item xs={12}>
                <FormControl>
                  <Typography sx={runManagementStyles.radioLabelStyle}>
                    {t("runManagement.transportationVendor")}
                  </Typography>
                  <RadioGroup
                    row
                    sx={runManagementStyles.radioStyle}
                    value={vendorType}
                    onChange={handleVendorTypeChange}
                  >
                    <FormControlLabel
                      value={vendorTypes.ironMountain}
                      control={<Radio />}
                      label={t("runManagement.ironMountain")}
                      disabled={
                        editRunInfo?.data?.assign_to_prep_for_run_stat ===
                        Y_CHECKER
                      }
                    />
                    <FormControlLabel
                      value={vendorTypes.thirdPartyVendor}
                      control={<Radio />}
                      label={t("runManagement.thirdPartyVendor")}
                      disabled={
                        editRunInfo?.data?.assign_to_prep_for_run_stat ===
                        Y_CHECKER
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            {vendorType === vendorTypes.ironMountain ? (
              <Grid container spacing={spacing.gap}>
                <Grid item xs={6}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    options={driverData}
                    size="medium"
                    value={runForm.values.driver}
                    fullWidth
                    disabled={
                      editRunInfo?.data?.assign_to_prep_for_run_stat ===
                      Y_CHECKER
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("runManagement.driver")}
                      />
                    )}
                    onChange={(e, newValue) => {
                      runForm.setFieldValue("driver", newValue);
                    }}
                    renderOption={(props, item) => (
                      <li {...props} key={item.employee_id}>
                        {item.employee_name}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    name="vehicle"
                    label={t("runManagement.vehicle")}
                    options={vehicleData}
                    value={runForm.values.vehicle}
                    onChange={handleFieldChange}
                    disabled={
                      editRunInfo?.data?.assign_to_prep_for_run_stat ===
                      Y_CHECKER
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={spacing.gap}>
                <Grid item xs={6}>
                  <SelectField
                    name="vendor"
                    label={t("runManagement.thirdPartyVendor")}
                    options={vendorData}
                    value={runForm.values.vendor}
                    onChange={handleFieldChange}
                    disabled={
                      editRunInfo?.data?.assign_to_prep_for_run_stat ===
                      Y_CHECKER
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Stack>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={handleCancelClick}>
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateClick}
              disabled={
                !isUpdateRequired ||
                isError ||
                editRunInfo?.data?.assign_to_prep_for_run_stat === Y_CHECKER
              }
            >
              {editRunInfo ? t("common.update") : t("common.save")}
            </Button>
          </>
        }
      />
      {/* Confimation popup */}
      {showConfirmPopup && (
        <ConfirmPopup
          modalPopupOpen={showConfirmPopup}
          handleCancel={() => {
            showConfirmPopup && setShowConfirmPopup(false);
          }}
          handleNo={handleNoClick}
          handleYes={handleYesClick}
          showCancel={showConfirmPopup}
          showNo={true}
        />
      )}
    </>
  );
};

export default RunModal;
