import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TableContainer,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { DatePickerField, FullCircularLoader } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  VALUE_EMPTY_STRING,
  VALUE_N,
  Y_CHECKER,
  dateFormat,
  errorMsgs,
  type,
  unauthorized
} from "utils/constants";
import { buildType } from "utils/constants/disaster-module/EventSearchConstants";
import {
  containerColumnsList,
  openMediaColumnsList,
  programTypes,
  zero
} from "utils/constants/disaster-module/InboundDateConst";
import {
  attributeTypeId,
  systemId
} from "utils/constants/disaster-module/logicalVaultRequest";
import {
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getResponseData,
  getTheFirestoreErrorMessage
} from "utils/helpers";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";

const minDate = dayjs().subtract(2, "year").startOf("day");
const maxDate = dayjs().endOf("day");

let defaultOption = {
  label: t("common.all"),
  value: VALUE_EMPTY_STRING
};

const InboundDateRequest = ({
  customerSelected,
  setAddOpenMedia,
  setAddContainer,
  RequestForm
}) => {
  const { localeByBranch } = useSelector(selectAuth);
  const [dateFrom, setDateFrom] = useState(dayjs());
  const [dateTo, setDateTo] = useState(dayjs());
  const [logicalVaults, setLogicalVaults] = useState([]);
  const [mediaTypes, setMediaTypes] = useState([]);
  const [selectedLogicalVault, setSelectedLogicalVault] = useState({
    ...defaultOption
  });

  const [selectedMediaType, setSelectedMediaType] = useState({
    ...defaultOption
  });
  const [programType, setProgramType] = useState(programTypes.openMedia);
  const districtId = getAuthenticatedUserBranch();
  const [errorMessage, setErrorMessage] = useState(VALUE_EMPTY_STRING);
  const [isLoading, setIsLoading] = useState(false);
  const [helperText, setHelperText] = useState(VALUE_EMPTY_STRING);
  const [note, setNote] = useState(VALUE_EMPTY_STRING);
  const [openMediaList, setOpenMediaList] = useState([]);
  const [containers, setContainers] = useState([]);
  const [maxDataCount, setMaxDataCount] = useState(0);
  const [includeNotAtIRM, setIncludeNotAtIRM] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [isFindDisabled, setIsFindDisabled] = useState(false);
  const [foundFieldsState, setFoundFieldsState] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(VALUE_EMPTY_STRING);
  const [resData, setResData] = useState(null);
  const [confirmPopupMessage, setConfirmPopupMessage] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [awaitEvent, setAwaitEvent] = useState(null);
  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setErrorMessage(t("printTapeLabel.systemError"));
    }
  };

  const getDateFormatted = (date) =>
    date ? dayjs(date).format(dateFormat.universalFormat) : VALUE_EMPTY_STRING;

  useEffect(() => {
    let { openMedia, o, c } = programTypes;
    let foundFieldsStateString = JSON.stringify(foundFieldsState);
    let presetFieldsStateString = JSON.stringify({
      dateFrom: getDateFormatted(dateFrom),
      dateTo: getDateFormatted(dateTo),
      lv: selectedLogicalVault?.value || VALUE_EMPTY_STRING,
      mt: selectedMediaType?.value || VALUE_EMPTY_STRING,
      pt: programType === openMedia ? o : c
    });

    if (foundFieldsStateString !== presetFieldsStateString) {
      setIsFindDisabled(false);
    } else {
      setIsFindDisabled(true);
    }
    // eslint-disable-next-line
  }, [dateFrom, dateTo, selectedLogicalVault, selectedMediaType, programType]);

  const handleError = async (errorCode, replaceText) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);
    if (errorMsg) {
      if (replaceText) {
        if (typeof replaceText === type.string) {
          errorMsg = errorMsg.replaceAll("|", replaceText);
        } else if (Array.isArray(replaceText)) {
          replaceText.forEach((text) => {
            errorMsg = errorMsg.replace("|", text);
          });
        }
      }
      setErrorMessage(errorMsg);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (districtId) {
      getLabel();
      getLogicalVaults();
      getMaxCountForGrid();
    }
    // eslint-disable-next-line
  }, []);

  const getSelectedOpenMediaCount = useCallback(() => {
    return openMediaList.filter((om) => !!om.selected)?.length || zero;
  }, [openMediaList]);

  const getSelectedContainersCount = useCallback(() => {
    return containers.filter((c) => !!c.selected)?.length || zero;
  }, [containers]);

  const hasSelected =
    getSelectedOpenMediaCount() !== zero ||
    getSelectedContainersCount() !== zero;

  const getMaxCountForGrid = async () => {
    try {
      const globalAttributeValue = await getGlobalAttributeValue(
        districtId,
        systemId,
        attributeTypeId
      );
      setMaxDataCount(globalAttributeValue);
    } catch (error) {
      handleCatchError(error);
    }
  };

  const getLabel = async () => {
    setIsLoading(true);
    let { errorMsg: helperText } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode26099
    );
    let { errorMsg: note } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode26100
    );
    setHelperText(helperText);
    setNote(note);
  };

  const onChangeDateFrom = (newDateFrom) => {
    const changeEvent = () => {
      const fromDate = dayjs(newDateFrom);
      setDateFrom(fromDate);
    };
    if (hasSelected) {
      openConfirmPopup();
      setAwaitEvent(() => changeEvent);
    } else {
      changeEvent();
    }
  };

  const onChangeDateTo = (newDateTo) => {
    const changeEvent = () => {
      const toDate = dayjs(newDateTo);
      setDateTo(toDate);
    };
    if (hasSelected) {
      openConfirmPopup();
      setAwaitEvent(() => changeEvent);
    } else {
      changeEvent();
    }
  };

  const onClickClear = () => {
    setProgramType(programTypes.openMedia);
    setDateFrom(dayjs());
    setDateTo(dayjs());
    setSelectedLogicalVault({ ...defaultOption });
    setSelectedMediaType({ ...defaultOption });
    setIsFindDisabled(false);
    setOpenMediaList([]);
    setContainers([]);
    setShowGrid(false);
    setFoundFieldsState({});
    RequestForm.setFieldValue("openMedia", []);
    RequestForm.setFieldValue("container", []);
    RequestForm.setFieldValue("hasInboundDateDataToAdd", false);
  };

  const generateInbounds = async () => {
    if (dateFrom > dateTo) {
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode25165
      );
      let messageFirstPart = errorMsg.split(".");
      messageFirstPart = messageFirstPart.length > 0 ? messageFirstPart[0] : "";
      if (messageFirstPart) setErrorMessage(messageFirstPart + ".");
      return;
    }

    const dateDifference = dateTo.diff(dateFrom, "day");
    if (dateDifference > 30) {
      handleError(errorMsgs.errorCode63121);
      return;
    }

    let { openMedia, o, c } = programTypes;
    setOpenMediaList([]);
    setContainers([]);
    try {
      setIsLoading(true);
      const reqBody = {
        main_district_id: districtId,
        cont_or_om_flag: programType === openMedia ? o : c,
        customer_id: customerSelected,
        from_date: getDateFormatted(dateFrom),
        to_date: getDateFormatted(dateTo),
        logical_vault_id: selectedLogicalVault?.value || VALUE_EMPTY_STRING,
        media_type_id: selectedMediaType?.value || VALUE_EMPTY_STRING,
        show_om_count_only_flag: VALUE_N
      };

      let URL = CF_URLS.disasterRecovery.getOMorContByInboundDate;
      const res = await getResponseData(reqBody, URL, 2);
      let data = res.data;

      if (data[0] && data[0][0]?.error && data[0][0]?.error !== zero) {
        handleError(data[0][0]?.error);
        setIsLoading(false);
        return;
      }

      let resultCount = data[0].length;
      if (!resultCount) {
        setShowGrid(false);
        setIsFindDisabled(true);
        await handleError(errorMsgs.errorCode26095);
      }

      if (resultCount > maxDataCount) {
        let { errorMsg } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode26114
        );
        errorMsg = errorMsg.replace("|", resultCount.toString());
        setConfirmMessage(errorMsg);
        setShowConfirmModal(true);
        setResData(data[0]);
        return;
      }

      mapDataFromRes(data[0]);
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const mapDataFromRes = (data) => {
    let { openMedia, o, c } = programTypes;

    if (data.length) {
      setFoundFieldsState({
        dateFrom: getDateFormatted(dateFrom),
        dateTo: getDateFormatted(dateTo),
        lv: selectedLogicalVault?.value || VALUE_EMPTY_STRING,
        mt: selectedMediaType?.value || VALUE_EMPTY_STRING,
        pt: programType === openMedia ? o : c
      });
      setShowGrid(true);
      setIsFindDisabled(true);
    }
    if (programType === openMedia) {
      let list = data
        .map((item) => ({
          selected: true,
          mediaType: item.short_descr,
          description: item.om_descr,
          drpCode: item.drp_code?.trim(),
          openMedia: item.volser?.trim(),
          status: item.om_status,
          isAtIRM: item.at_irm_flag,
          ...item
        }))
        .sort((a, b) => a.openMedia.localeCompare(b.openMedia));
      setOpenMediaList(list);
      RequestForm.setFieldValue("openMedia", list);
    } else {
      let list = data
        .map((item) => {
          let number = item.container_number?.trim();
          return {
            selected: true,
            containerNumber: number,
            status: item.exchange_status_type,
            description: item.media_type_descr,
            value: item.container_id,
            isAtIRM: item.at_irm_flag,
            ...item
          };
        })
        .sort((a, b) => a.containerNumber.localeCompare(b.containerNumber));
      setContainers(list);
      RequestForm.setFieldValue("container", list);
    }
  };

  const getLogicalVaults = async () => {
    try {
      let reqBody = {
        main_district_id: districtId,
        customer_id: customerSelected
      };
      setIsLoading(true);

      let url = CF_URLS.disasterRecovery.getVaultMeadiaType;
      let res = await getResponseData(reqBody, url, 2);

      let data = res.data;
      if (data[0] && data[0][0]?.error && data[0][0]?.error !== zero) {
        handleError(data[0][0]?.error);
        setIsLoading(false);
        return;
      }

      let lvList = res?.data?.[0];
      let mtList = res?.data?.[1];
      lvList = lvList.map((lv) => ({
        label: lv.logical_vault_code,
        value: lv.logical_vault_id,
        type: lv.om_or_container_lv_flag
      }));
      setLogicalVaults(lvList.sort((a, b) => a.label.localeCompare(b.label)));

      mtList = mtList.map((mt) => ({
        label: mt.short_descr,
        value: mt.media_type_id,
        vaultId: mt.logical_vault_id
      }));
      setMediaTypes(mtList.sort((a, b) => a.label.localeCompare(b.label)));
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const openConfirmPopup = async () => {
    let { errorMsg } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode26084
    );
    setConfirmPopupMessage(errorMsg);
    setConfirmPopup(true);
  };

  const onChangeLogicalVault = (val) => {
    const changeEvent = () => {
      if (val?.value) {
        setSelectedLogicalVault(val);
      } else {
        setSelectedLogicalVault({ ...defaultOption });
      }
      setSelectedMediaType({ ...defaultOption });
    };

    if (hasSelected) {
      openConfirmPopup();
      setAwaitEvent(() => changeEvent);
    } else {
      changeEvent();
    }
  };

  const onChangeMediaType = (val) => {
    let changeEvent = () => {
      if (val?.value) {
        setSelectedMediaType(val);
      } else {
        setSelectedMediaType({ ...defaultOption });
      }
    };
    if (hasSelected) {
      openConfirmPopup();
      setAwaitEvent(() => changeEvent);
    } else {
      changeEvent();
    }
  };

  const getLogicalVaultsOptions = () => {
    let list = [];
    if (programType === programTypes.openMedia) {
      list = logicalVaults.filter((lv) => lv.type === programTypes.o);
    } else if (programType === programTypes.container) {
      list = logicalVaults.filter((lv) => lv.type === programTypes.c);
    }
    return [
      {
        label: t("common.all"),
        value: VALUE_EMPTY_STRING
      },
      ...list
    ];
  };

  const getMediaTypeOptions = () => {
    if (selectedLogicalVault.value)
      return mediaTypes.filter(
        (mt) => mt.vaultId === selectedLogicalVault.value
      );
    else {
      let lvs = getLogicalVaultsOptions()
        .filter((lv) => !!lv.value)
        .map((lv) => lv.value);
      return mediaTypes.filter((m) => lvs.includes(m.vaultId));
    }
  };

  const onChangeProgramType = (val) => {
    const changeEvent = () => {
      setProgramType(val);
      setSelectedLogicalVault({ ...defaultOption });
      setSelectedMediaType({ ...defaultOption });
    };

    if (hasSelected) {
      openConfirmPopup();
      setAwaitEvent(() => changeEvent);
    } else {
      changeEvent();
    }
  };

  const checkForValidDate = (date) => {
    const dateToCheck = dayjs(date).startOf("day");
    return (
      dateToCheck.isValid() &&
      dateToCheck.isBetween(minDate, maxDate, null, "[]")
    );
  };

  const onClickFindButton = async () => {
    let isFromValid = checkForValidDate(dateFrom);
    let isToValid = checkForValidDate(dateTo);
    if (!isFromValid || !isToValid) {
      await handleError(errorMsgs.errorCode26095);
      return;
    }
    setErrorMessage(VALUE_EMPTY_STRING);
    await generateInbounds();
  };

  const onChnageIncludeMediaNotAtIRM = (event) => {
    let checked = event.target.checked;
    setIncludeNotAtIRM(checked);
    let updatedOpenMediaList = [...openMediaList];
    updatedOpenMediaList.forEach((om) => {
      if (om.isAtIRM === Y_CHECKER) {
        om.selected = checked;
      }
    });
    setOpenMediaList(updatedOpenMediaList);
  };

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: true,
    sort: true,
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    }
  };

  const handleSelectChangeOpenMedia = (e, number) => {
    let checked = e.target.checked;
    let updatedIndex = openMediaList.findIndex(
      (item) => item.openMedia === number
    );
    let updatedOpenMediaList = [...openMediaList];
    updatedOpenMediaList[updatedIndex].selected = checked;
    let selectedList = updatedOpenMediaList.filter((om) => !!om.selected);
    RequestForm.setFieldValue("openMedia", selectedList);
    setOpenMediaList(updatedOpenMediaList);
  };

  const openMediaColumns = [
    {
      name: "selected",
      options: {
        customHeadLabelRender: () => VALUE_EMPTY_STRING,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          let volserNumber = tableMeta?.rowData[1];
          return (
            <Stack sx={disasterRecoveryStyles.inboundDate.actionColumn}>
              <Checkbox
                checked={value}
                onChange={(e) => handleSelectChangeOpenMedia(e, volserNumber)}
              />
            </Stack>
          );
        },
        headerNoWrap: true
      },
      label: VALUE_EMPTY_STRING
    },
    ...openMediaColumnsList
  ];

  const handleSelectChange = (e, number) => {
    let checked = e.target.checked;
    let updatedIndex = containers.findIndex(
      (item) => item.containerNumber === number
    );
    let updatedConatiners = [...containers];
    updatedConatiners[updatedIndex].selected = checked;
    let selectedList = updatedConatiners.filter((c) => !!c.selected);
    RequestForm.setFieldValue("container", selectedList);
    setContainers(updatedConatiners);
  };

  const containerColumns = [
    {
      name: "selected",
      options: {
        customHeadLabelRender: () => VALUE_EMPTY_STRING,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          let containerNumber = tableMeta?.rowData[1];
          return (
            <Stack sx={disasterRecoveryStyles.inboundDate.actionColumn}>
              <Checkbox
                checked={value}
                onChange={(e) => handleSelectChange(e, containerNumber)}
              />
            </Stack>
          );
        },
        headerNoWrap: true
      },
      label: VALUE_EMPTY_STRING
    },
    ...containerColumnsList
  ];

  const onCLickYesFromConfirm = async () => {
    mapDataFromRes(resData);
    setShowConfirmModal(false);
    setConfirmMessage(VALUE_EMPTY_STRING);
  };

  const onClickNoFromConfirm = () => {
    setShowConfirmModal(false);
    setConfirmMessage(VALUE_EMPTY_STRING);
  };

  const getModalButtons = () => {
    return (
      <>
        <Button variant="outlined" onClick={onCLickYesFromConfirm}>
          {t("common.yes")}
        </Button>
        <Button variant="contained" onClick={onClickNoFromConfirm}>
          {t("common.no")}
        </Button>
      </>
    );
  };

  const handelAddRequest = () => {
    addAllSelectedOpenMedia();
    onClickClear();
  };

  const addAllSelectedOpenMedia = () => {
    const selectedCustomerOpenMedia = openMediaList.filter((o) => o.selected);

    const selectedContainers = containers.filter((c) => c.selected);

    setAddOpenMedia((prev) => [...prev, ...selectedCustomerOpenMedia]);
    setAddContainer((prev) => [...prev, ...selectedContainers]);
  };

  const handelClearSelected = () => {
    const updatedOpenMediaList = openMediaList.map((openMedia) => {
      openMedia.selected = false;
      return openMedia;
    });

    const updatedContainers = containers.map((c) => ({
      ...c,
      selected: false
    }));
    setContainers(updatedContainers);
    setOpenMediaList(updatedOpenMediaList);
  };

  const handelReviewAndGenerate = () => {
    addAllSelectedOpenMedia();
    RequestForm.setFieldValue("buildRequest", buildType.landingPage);
  };

  const handleConfirmModalCancel = () => {
    setConfirmPopup(false);
  };

  const handleConfirmModalNo = () => {
    setIsFindDisabled(false);
    setOpenMediaList([]);
    setContainers([]);
    setShowGrid(false);
    setFoundFieldsState({});
    RequestForm.setFieldValue("openMedia", []);
    RequestForm.setFieldValue("container", []);
    RequestForm.setFieldValue("hasInboundDateDataToAdd", false);
    if (typeof awaitEvent === "function") awaitEvent();
    setConfirmPopup(false);
  };

  const handleConfirmModalYes = () => {
    addAllSelectedOpenMedia();
    handleConfirmModalNo();
  };
  useEffect(() => {
    if (hasSelected) {
      RequestForm.setFieldValue("hasInboundDateDataToAdd", true);
    } else {
      RequestForm.setFieldValue("hasInboundDateDataToAdd", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMediaList, containers]);

  return (
    <>
      {isLoading && <FullCircularLoader />}
      <CommonModal
        open={showConfirmModal}
        buttons={getModalButtons()}
        title={t("common.confirm")}
        body={<Typography>{confirmMessage}</Typography>}
      />
      <Box
        ml={spacing.gap}
        sx={{
          ...disasterRecoveryStyles.requestGeneratorStyles.rightPanelWrapper,
          ...disasterRecoveryStyles.inboundDate.mainWrapper
        }}
      >
        <Box>
          <Box mb={spacing.gap10}>
            {!!errorMessage && (
              <Collapse in={!!errorMessage}>
                <Alert
                  severity="error"
                  icon={
                    <img
                      src={ErrorIcon}
                      alt="error"
                      style={disasterRecoveryStyles.commonStyles.errorIcon}
                    />
                  }
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setErrorMessage(VALUE_EMPTY_STRING);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {errorMessage}
                </Alert>
              </Collapse>
            )}
          </Box>
          <Grid container gap={spacing.gap}>
            <Grid item sx={12} md={12} xl={12}>
              <Stack gap={spacing.gap10}>
                <Typography
                  style={disasterRecoveryStyles.commonStyles.subHeading}
                >
                  {t("disasterRecovery.eventDetail.inboundDateHeading")}
                </Typography>
                <FormLabel>
                  <span>{helperText}</span>
                  <span style={disasterRecoveryStyles.inboundDate.noteText}>
                    {note}
                  </span>
                </FormLabel>
              </Stack>
            </Grid>
            <Grid item sx={4}>
              <Stack direction="row" gap={spacing.gap}>
                <FormControl>
                  <FormLabel component="legend">
                    {t("disasterRecovery.eventDetail.media")}
                  </FormLabel>
                  <RadioGroup
                    row
                    name="media-radio-buttons-group"
                    value={programType}
                    onChange={(e) => onChangeProgramType(e.target.value)}
                    sx={disasterRecoveryStyles.commonStyles.radioGroup}
                  >
                    <FormControlLabel
                      value={programTypes.openMedia}
                      control={<Radio />}
                      label={t("disasterRecovery.eventDetail.rgOpenMedia")}
                    />
                    <FormControlLabel
                      value={programTypes.container}
                      control={<Radio />}
                      label={t("disasterRecovery.eventDetail.containers")}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormLabel component="legend">
                {t("disasterRecovery.eventDetail.dateRange")}
              </FormLabel>
            </Grid>
            <Grid container gap={spacing.gap} wrap="nowrap">
              <Grid item xs={4}>
                <DatePickerField
                  id="date-from"
                  name="dateFrom"
                  value={dateFrom}
                  minDate={minDate}
                  maxDate={maxDate}
                  locale={localeByBranch}
                  onChange={(val) => onChangeDateFrom(val)}
                  label={t("disasterRecovery.eventDetail.dateFrom")}
                />
              </Grid>
              <Grid item xs={4}>
                <DatePickerField
                  id="date-to"
                  name="dateTo"
                  value={dateTo}
                  minDate={minDate}
                  maxDate={maxDate}
                  locale={localeByBranch}
                  onChange={(val) => onChangeDateTo(val)}
                  label={t("disasterRecovery.eventDetail.dateTo")}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="logical-vault"
                disablePortal
                name={"logicalVault"}
                onChange={(e, val) => onChangeLogicalVault(val)}
                options={getLogicalVaultsOptions()}
                size="medium"
                value={selectedLogicalVault}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("disasterRecovery.eventDetail.logicalVault")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="media-type"
                disablePortal
                name={"mediaType"}
                onChange={(e, val) => onChangeMediaType(val)}
                options={[defaultOption, ...getMediaTypeOptions()]}
                size="medium"
                value={selectedMediaType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("disasterRecovery.eventDetail.mediaType")}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Stack gap={spacing.gap} mt={spacing.gap} direction="row">
            <Button variant="outlined" onClick={onClickClear}>
              {t("common.clear")}
            </Button>
            <Button
              variant="contained"
              onClick={onClickFindButton}
              disabled={isFindDisabled}
            >
              {t("common.find")}
            </Button>
          </Stack>

          {showGrid ? (
            <>
              <Box my={spacing.gap}>
                <Divider />
              </Box>

              <Stack gap={spacing.gap10}>
                <Typography
                  style={disasterRecoveryStyles.commonStyles.subHeading}
                >
                  {t("disasterRecovery.eventDetail.inboundDate")}
                </Typography>
                <FormLabel>
                  <span style={disasterRecoveryStyles.inboundDate.noteText}>
                    {t("disasterRecovery.eventDetail.addToRequestText")}
                  </span>
                </FormLabel>
                <Box pl={spacing.gap10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeNotAtIRM}
                        onChange={onChnageIncludeMediaNotAtIRM}
                      />
                    }
                    label={t(
                      "disasterRecovery.eventDetail.includeMedaiNotAtIRM"
                    )}
                  />
                </Box>
              </Stack>

              <Stack gap={spacing.gap10} my={spacing.gap}>
                <Typography
                  style={disasterRecoveryStyles.commonStyles.subHeading}
                >
                  {t("disasterRecovery.eventDetail.rgOpenMedia")}
                </Typography>
                <Paper>
                  <TableContainer>
                    <Box sx={disasterRecoveryStyles.inboundDate.tableContainer}>
                      <MUIDataTable
                        columns={openMediaColumns}
                        options={options}
                        data={openMediaList}
                        title={
                          <Typography variant="subtitle1">
                            {getSelectedOpenMediaCount()}{" "}
                            {t(
                              "disasterRecovery.eventDetail.openMediaSelected"
                            )}
                          </Typography>
                        }
                      />
                    </Box>
                  </TableContainer>
                </Paper>
              </Stack>

              <Stack gap={spacing.gap10}>
                <Typography
                  style={disasterRecoveryStyles.commonStyles.subHeading}
                >
                  {t("disasterRecovery.eventDetail.containers")}
                </Typography>
                <Paper>
                  <TableContainer>
                    <Box sx={disasterRecoveryStyles.inboundDate.tableContainer}>
                      <MUIDataTable
                        columns={containerColumns}
                        options={options}
                        data={containers}
                        title={
                          <Typography variant="subtitle1">
                            {getSelectedContainersCount()}{" "}
                            {t("disasterRecovery.eventDetail.containers")}
                          </Typography>
                        }
                      />
                    </Box>
                  </TableContainer>
                </Paper>
              </Stack>
            </>
          ) : null}
        </Box>
        <Box
          sx={disasterRecoveryStyles.tableStyles.displayFlex}
          gap={spacing.gap}
          mt={spacing.gap}
        >
          {showGrid ? (
            <>
              <Button
                variant="outlined"
                id="add-request"
                style={disasterRecoveryStyles.tableStyles.buttonsSpace}
                disabled={!hasSelected}
                onClick={handelAddRequest}
              >
                {t("disasterRecovery.eventDetail.addToRequest")}
              </Button>
              <Button
                variant="outlined"
                id="clear-selection"
                style={disasterRecoveryStyles.tableStyles.buttonsSpace}
                disabled={!hasSelected}
                onClick={handelClearSelected}
              >
                {t("disasterRecovery.eventDetail.clearSelected")}
              </Button>
            </>
          ) : null}
          <Button
            variant="contained"
            id="review-generate-request"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            onClick={handelReviewAndGenerate}
          >
            {t("disasterRecovery.eventDetail.reviewGenerateRequest")}
          </Button>
        </Box>
      </Box>
      <ConfirmPopup
        message={confirmPopupMessage}
        modalPopupOpen={confirmPopup}
        handleYes={() => handleConfirmModalYes()}
        handleNo={() => handleConfirmModalNo()}
        handleCancel={() => handleConfirmModalCancel()}
        showNo={true}
        showCancel={true}
      />
    </>
  );
};

export default InboundDateRequest;
