import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";

import { GridCloseIcon } from "@mui/x-data-grid";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";

import CheckIcon from "@mui/icons-material/Check";
import { CircularLoaderFullPage } from "components/core";
import { WorkManagementStyles } from "components/open-media-processing/work-management/WorkManagementStyles";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DUPLICATES_TXT,
  ERROR_TYPES,
  N_CHECKER,
  POSSIBLE_MATCHES_TXT,
  Y_CHECKER,
  container,
  errorMsgs,
  found,
  globalAttributeTypeIdMedia,
  local,
  notFound,
  numberZero,
  openMedia,
  systemIdMedia,
  validationErrorId
} from "utils/constants";
import {
  attribututeGlobalType18,
  attribututeGlobalType20,
  correctiveActionRequestId1,
  correctiveActionRequestId2,
  mediaLength1600
} from "utils/constants/corrective-action-request";
import {
  findErrorMessage,
  getGlobalAttributeValue,
  getResponseData
} from "utils/helpers";
import { CorrectiveActionRequestStyles } from "./CorrectiveActionRequestStyles";
const {
  default: CommonModal
} = require("components/shared/common-modal/CommonModal");
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const mediaTabs = [
  {
    label: openMedia,
    value: correctiveActionRequestId1
  },
  {
    label: container,
    value: correctiveActionRequestId2
  }
];
const AddMediaModal = ({
  isOpenMediaModal,
  onClose,
  selectedRow,
  openMediaList,
  setOpenMediaList,
  customer,
  setSaveBtnStatus,
  mediaData,
  setMediaData
}) => {
  const { currentBranch, auth } = useSelector(selectAuth);
  const [customerMedia, setCustomerMedia] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [canChangeTab, setCanChangeTab] = React.useState(true);
  const [editData, setEditData] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [selectRows, setSelectRows] = useState("");

  const getFilterData = () => {
    if (value === 0) {
      return mediaData?.filter((obj) => obj?.type === openMedia);
    } else {
      return mediaData?.filter((obj) => obj?.type === container);
    }
  };
  const findDublicate = getFilterData()?.filter(
    (obj) => obj?.media_number === customerMedia
  );
  const findDublicateEdit = getFilterData()
    ?.filter((obj, index) => index !== selectRows)
    ?.filter((obj) => obj?.media_number === editData);
  const findDublicateContainer = getFilterData()?.filter(
    (obj) => obj?.media_number === customerMedia && obj?.type === container
  );
  const findDublicateEditContainer = getFilterData()
    ?.filter((obj, index) => index !== selectRows)
    ?.filter(
      (obj) => obj?.media_number === editData && obj?.type === container
    );
  const edit = async (name, index) => {
    if (value === 0) {
      if (editOpen) {
        // Create a shallow copy of mediaData to avoid direct mutation
        const updatedMediaData = [...getFilterData()];
        // Update the specific item at the given index
        if (findDublicateEdit.length > 0) {
          setLoading(false);
          const errDescr = await getMessageFromCode(errorMsgs.errorCode20382);
          setThrowError(true);
          setThrowErrorMessage(
            errDescr[0]?.descr.replace("|", t("correctiveActionRequest.media"))
          );
          return;
        } else {
          if (updatedMediaData[index]) {
            updatedMediaData[index].media_number = editData;
            setThrowError(false);
          }
        }
        // Close the edit mode
        setEditOpen(false);
        setSelectRows("");
      } else {
        // Open the edit mode and set the current name to edit
        setEditOpen(true);
        setEditData(name);
        setSelectRows(index);
      }
    } else {
      if (editOpen) {
        // Create a shallow copy of mediaData to avoid direct mutation
        const updatedMediaData = [...getFilterData()];
        // Update the specific item at the given index
        if (findDublicateEditContainer.length > 0) {
          setLoading(false);
          const errDescr = await getMessageFromCode(errorMsgs.errorCode20382);
          setThrowError(true);
          setThrowErrorMessage(
            errDescr[0]?.descr.replace(
              "|",
              t("correctiveActionRequest.container")
            )
          );
          return;
        } else {
          if (updatedMediaData[index]) {
            updatedMediaData[index].media_number = editData;
            setThrowError(false);
          }
        }
        // Close the edit mode
        setEditOpen(false);
        setSelectRows("");
      } else {
        // Open the edit mode and set the current name to edit
        setEditOpen(true);
        setEditData(name);
        setSelectRows(index);
      }
    }
  };
  const columns = [
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Button
                sx={{ padding: 0 }}
                onClick={() =>
                  edit(
                    getFilterData()[tableMeta?.rowIndex]?.media_number,
                    tableMeta?.rowIndex
                  )
                }
              >
                <SvgIcon
                  component={
                    editOpen && tableMeta?.rowIndex === selectRows
                      ? CheckIcon
                      : EditIcon
                  }
                />
              </Button>
              <Button
                sx={{ padding: 0 }}
                onClick={() => removeMedia(tableMeta?.rowIndex)}
              >
                <SvgIcon component={CloseIcon} />
              </Button>
            </>
          );
        }
      }
    },
    {
      name: "media_number",
      label:
        value === 0
          ? t("correctiveActionRequest.media#")
          : t("correctiveActionRequest.container#"),
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {selectRows === tableMeta?.rowIndex ? (
                <TextField
                  variant="outlined"
                  value={editData}
                  onChange={(e) => setEditData(e.target.value)}
                />
              ) : (
                getFilterData()[tableMeta?.rowIndex]?.media_number
              )}
            </>
          );
        }
      }
    },
    {
      name: "status",
      label: t("correctiveActionRequest.status")
    }
  ];
  useEffect(() => {
    setMediaData(openMediaList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    tableBodyHeight: "200px",
    tableBodyMaxHeight: "200px",
    pagination: 0 & true,
    textLabels: {
      body: {
        noMatch:
          selectedRow?.assignTransportListArr?.length === 0 &&
          `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  const handleCustomerMedia = (e) => {
    setCustomerMedia(e.target.value);
  };
  const removeMedia = (index) => {
    // Create a shallow copy of the original media data
    const updatedMedia = [...mediaData];

    // Filter data based on the current tab
    const filteredData = getFilterData();

    // Get the index of the item to be removed in the original array
    const mediaIndex = mediaData.findIndex(
      (obj) => obj.media_number === filteredData[index].media_number
    );

    if (mediaIndex > -1) {
      // Remove the media item from the original media data array
      updatedMedia.splice(mediaIndex, 1);

      // Update the mediaData state with the new array
      setMediaData(updatedMedia);
    }
  };

  const add = async () => {
    try {
      setLoading(true);
      const localeId = auth?.user?.locale_id;
      if (value === 0) {
        let obj = {};
        if (customerMedia.length > (await getAttributes())) {
          setLoading(false);
          setThrowError(true);
          setThrowErrorMessage(t("correctiveActionRequest.mediaErr"));
          return;
        } else if (findDublicate.length > 0) {
          setLoading(false);
          const errDescr = await getMessageFromCode(errorMsgs.errorCode20382);
          setThrowError(true);
          setThrowErrorMessage(
            errDescr[0]?.descr.replace("|", t("correctiveActionRequest.media"))
          );
          return;
        } else if (mediaData?.length > mediaLength1600) {
          setLoading(false);
          const errDescr = await getMessageFromCode(errorMsgs.errorCode63945);
          setThrowError(true);
          setThrowErrorMessage(errDescr[0]?.descr);
          return;
        } else {
          const reqBody = {
            main_district_id: currentBranch?.value,
            customer_id: customer?.value,
            volser_list: customerMedia.toString().padEnd(20, " "),
            is_ssie_flag: N_CHECKER,
            locale_id: localeId,
            language_locale_id: validationErrorId,
            at_iron_mtn_status: "",
            in_transit_status: "",
            not_at_iron_mtn_status: "",
            media_not_found_status: "",
            possible_matches_status: POSSIBLE_MATCHES_TXT,
            duplicates_status: DUPLICATES_TXT,
            sent_to_text: "",
            find_mdr_flag: Y_CHECKER,
            media_in_mdr_status: "",
            media_in_mdr_followup: ""
          };
          const response = await getResponseData(
            reqBody,
            `${CF_URLS?.correctiveActionRequest.validatepicklists}`,
            1
          );
          if (response.data[0][0]?.volser) {
            obj.media_number = customerMedia;
            obj.status =
              response.data[0][0]?.status === notFound ? notFound : found;
            obj.type = openMedia;
            obj.local = local;
            setMediaData((prev) => [...prev, obj]);
            setCustomerMedia("");
            setThrowError(false);
            setLoading(false);
          } else {
            setLoading(false);
            setThrowError(true);
            setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          }
        }
      } else {
        let obj = {};
        if (customerMedia.length > attribututeGlobalType18) {
          setLoading(false);
          setThrowError(true);
          setThrowErrorMessage(t("correctiveActionRequest.containerErr"));
          return;
        } else if (findDublicateContainer.length > 0) {
          setLoading(false);
          const errDescr = await getMessageFromCode(errorMsgs.errorCode20382);
          setThrowError(true);
          setThrowErrorMessage(
            errDescr[0]?.descr.replace(
              "|",
              t("correctiveActionRequest.container")
            )
          );
          return;
        } else if (mediaData?.length > mediaLength1600) {
          setLoading(false);
          const errDescr = await getMessageFromCode(errorMsgs.errorCode63945);
          setThrowError(true);
          setThrowErrorMessage(errDescr[0]?.descr);
          return;
        } else {
          const reqBody = {
            main_district_id: currentBranch?.value,
            customer_id: customer?.value,
            container_number: customerMedia.toString().padEnd(20, " ")
          };
          const response = await getResponseData(
            reqBody,
            `${CF_URLS?.correctiveActionRequest.validateContainer}`,
            1
          );
          if (response.data[0][0]?.container_id) {
            obj.media_number = customerMedia;
            obj.status =
              response.data[0][0]?.container_id === errorMsgs.noError
                ? notFound
                : found;
            obj.type = container;
            obj.local = local;
            setMediaData((prev) => [...prev, obj]);
            setCustomerMedia("");
            setThrowError(false);
            setLoading(false);
          } else {
            setLoading(false);
            setThrowError(true);
            setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getAttributes = async () => {
    const attributeValue = await getGlobalAttributeValue(
      currentBranch?.value,
      systemIdMedia,
      globalAttributeTypeIdMedia
    );

    return attributeValue ? attributeValue : attribututeGlobalType20;
  };
  const sumbit = () => {
    mediaData?.map((obj) => delete obj?.local);
    setOpenMediaList(mediaData);
    onClose();
    setSelectRows("");
    setThrowError(false);
    setEditOpen(false);
    setEditData("");
  };
  const cancel = () => {
    const cancelSelectedMedia = [
      ...mediaData.filter((rem) => rem?.local !== local)
    ];
    cancelSelectedMedia?.length > 0 && setOpenMediaList(cancelSelectedMedia);
    onClose();
    setSaveBtnStatus(false);
    setSelectRows("");
    setThrowError(false);
    setEditOpen(false);
    setEditData("");
    setMediaData(cancelSelectedMedia);
    setCustomerMedia("");
    setThrowError("");
  };
  const handleChange = (event, newValue, allowChangeTab) => {
    if (canChangeTab || allowChangeTab) {
      setValue(newValue);
      setCanChangeTab(true);
    }
  };

  return (
    <CommonModal
      open={isOpenMediaModal}
      title={t("correctiveActionRequest.addMediaToCar")}
      body={
        <>
          {" "}
          {loading && <CircularLoaderFullPage loading={loading} />}
          {throwError && (
            <Collapse in={throwError}>
              <Alert
                severity="error"
                icon={
                  <img
                    style={CorrectiveActionRequestStyles.alertSize}
                    src={ErrorIcon}
                    alt="error"
                  />
                }
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <GridCloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Collapse>
          )}
          {
            <>
              <Typography>
                {mediaData?.length > 0 &&
                  mediaData?.length +
                    " " +
                    t("correctiveActionRequest.records")}
              </Typography>
            </>
          }
          <br />
          <Box sx={WorkManagementStyles.tabBox1}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {mediaTabs.map((obj, index) => {
                return (
                  <Tab
                    label={obj.label}
                    {...a11yProps(index)}
                    key={obj.label}
                  />
                );
              })}
            </Tabs>
          </Box>
          <br />
          <Card>
            <Box pb={2} sx={CorrectiveActionRequestStyles.assignTransportsBox}>
              <TextField
                id="location"
                name="location"
                label={t("correctiveActionRequest.customerMediaIdentifier")}
                value={customerMedia}
                onChange={(e) => handleCustomerMedia(e)}
              />
              <Button
                sx={CorrectiveActionRequestStyles.assignTransportsAddButton}
                disabled={!customerMedia}
                onClick={add}
              >
                {t("correctiveActionRequest.validateAndAdd")}
              </Button>
            </Box>

            <div style={CorrectiveActionRequestStyles.tableDiv}>
              <MUIDataTable
                columns={columns}
                options={options}
                data={getFilterData()}
              />
            </div>
          </Card>
        </>
      }
      buttons={
        <>
          <Button variant="outlined" onClick={cancel}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="outlined"
            disabled={getFilterData()?.length === numberZero}
            onClick={() => {
              setMediaData([]);
              setCustomerMedia("");
              setThrowError("");
            }}
          >
            {t("common.clear")}
          </Button>
          <Button
            variant="contained"
            disabled={getFilterData()?.length === numberZero}
            onClick={sumbit}
          >
            {t("common.ok")}
          </Button>
        </>
      }
    />
  );
};
export default AddMediaModal;
