import { createSlice } from "@reduxjs/toolkit";
import { ALL_SELECTION, VALUE_EMPTY_STRING } from "utils/constants";
import { defaultStatus } from "utils/constants/discrepancy-management/DiscrepancySearchConstants";

const initialSearchValues = {
  customer: null,
  customerMediaIdentifier: VALUE_EMPTY_STRING,
  detectedDateFrom: VALUE_EMPTY_STRING,
  detectedDateTo: VALUE_EMPTY_STRING,
  detectedIn: [ALL_SELECTION?.label],
  discrepancyType: ALL_SELECTION,
  discrepancyStatus: defaultStatus,
  logicalVault: ALL_SELECTION,
  media: ALL_SELECTION,
  mediaNumber: VALUE_EMPTY_STRING,
  run: VALUE_EMPTY_STRING,
  resolvedDateFrom: VALUE_EMPTY_STRING,
  resolvedDateTo: VALUE_EMPTY_STRING,
  serviceDate: VALUE_EMPTY_STRING,
  excludeAutoResolved: false
};
const initialAuthValues = {
  branch: VALUE_EMPTY_STRING,
  customer: VALUE_EMPTY_STRING,
  personnel: VALUE_EMPTY_STRING,
  authNumber: VALUE_EMPTY_STRING,
  challengeQuestion: VALUE_EMPTY_STRING,
  challengeResponse: VALUE_EMPTY_STRING
};
const discrepancySearchSlice = createSlice({
  name: "discrepancySearch",
  initialState: {
    searchFormValues: initialSearchValues,
    isLoading: false,
    throwError: false,
    throwErrorMsg: VALUE_EMPTY_STRING,
    isAuthenticated: false,
    throwWarning: false,
    throwWarningMsg: VALUE_EMPTY_STRING,
    authCustomers: [],
    discrepancyTypes: [],
    detectedInTypes: [],
    logicalVaultsOptions: [],
    allServiceOptionData: [],
    containerData: [],
    openMediaData: [],
    dontCallLabel: "",
    selectedValues: [],
    type: "",
    mediaList: [],
    hasInsufficientRights: false
  },
  reducers: {
    updateSearchFormValues(state, action) {
      state.searchFormValues = { ...state.searchFormValues, ...action.payload };
    },

    resetSearchForm(state, action) {
      state.searchFormValues = {
        ...initialSearchValues,
        detectedIn: action?.payload?.map((option) => option.label)
      };
      state.containerData = [];
      state.openMediaData = [];
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },
    resetDiscrepancyAuthForm(state) {
      state.discrepancyAuthFormValues = initialAuthValues;
      state.containerData = [];
      state.openMediaData = [];
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },
    setSearchResults(state, action) {
      state.containerData = action.payload?.containerData;
      state.openMediaData = action.payload?.openMediaData;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.throwError = true;
      state.throwErrorMsg = action.payload;
    },
    setWarning(state, action) {
      state.throwWarning = true;
      state.throwWarningMsg = action.payload;
    },
    clearError(state) {
      state.throwError = false;
      state.throwErrorMsg = VALUE_EMPTY_STRING;
    },
    clearWarning(state) {
      state.throwWarning = false;
      state.throwWarningMsg = VALUE_EMPTY_STRING;
    },
    updateDiscrepancyAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },
    setAuthCustomers(state, action) {
      state.authCustomers = action.payload;
    },
    setAllServiceOptionData(state, action) {
      state.allServiceOptionData = action.payload;
    },
    setDiscrepancyTypes(state, action) {
      state.discrepancyTypes = action.payload;
    },
    setDetectedInTypes(state, action) {
      state.detectedInTypes = action.payload;
    },
    setLogicalVaultsOptions(state, action) {
      state.logicalVaultsOptions = action.payload;
    },
    setDontCallLabel(state, action) {
      state.dontCallLabel = action.payload;
    },
    setUpdateSelectedList(state, action) {
      state.selectedValues = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setMediaList(state, action) {
      state.mediaList = action.payload;
    },
    setHasInsufficientRights(state, action) {
      state.hasInsufficientRights = action.payload;
    }
  }
});

export const {
  updateSearchFormValues,
  resetSearchForm,
  setSearchResults,
  setLoading,
  setError,
  clearError,
  resetAuthForm,
  setWarning,
  clearWarning,
  setAuthCustomers,
  setDiscrepancyTypes,
  setDetectedInTypes,
  setLogicalVaultsOptions,
  setAllServiceOptionData,
  setDontCallLabel,
  setUpdateSelectedList,
  setType,
  setMediaList,
  setHasInsufficientRights
} = discrepancySearchSlice.actions;

export default discrepancySearchSlice.reducer;
