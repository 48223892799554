import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { NonEditableFieldStyles } from "./NonEditableField.Styles";

/**
 * 
 * Display content area contains label and value
 * 
 * @param {object} props
 * @param {string} props.id element id
 * @param {string} props.label display label
 * @param {string} props.value display value
 * @returns 
 */
const NonEditableField = (props) => {
    const { id, label, value } = props;
    return (
        <Box id={id} sx={NonEditableFieldStyles.Container}>
            <Typography
                id={`none_editable_field_label_${id}`}
                variant="subtitle1"
                sx={NonEditableFieldStyles.Label}
            >
                {label}
            </Typography>
            <Typography
                id={`none_editable_field_value_${id}`}
                sx={NonEditableFieldStyles.Value}
            >
                {value}
            </Typography>
        </Box>
    );
};

NonEditableField.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number])
};

export default NonEditableField;
