import { Autocomplete, FormControl, TextField } from "@mui/material";

function SearchField({ label, error, onBlur, helperText, onSelect, ...props }) {
  return (
    <FormControl fullWidth>
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            helperText={helperText}
            onBlur={onBlur}
            error={error}
          />
        )}
        {...props}
      />
    </FormControl>
  );
}

export default SearchField;
