import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import InfoIcon from "assets/images/InfoOutlined.svg";
import SuccessIcon from "assets/images/SuccessIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import { Y_YES } from "components/features/request-module/media-destruction-request-vault-itemized/Constants";
import AuditAuthForm from "components/shared/auth/AuditAuthSection";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllCustomersQuery,
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { CF_URLS } from "services/api/endpoints";
import {
  getDistricts,
  getEmployeeDetails,
  getMessageFromCode
} from "services/api/query";
import { selectAuth, setAuthenticationState } from "store/slices";
import {
  clearError,
  clearWarning,
  resetNotificationAuthForm,
  resetNotificationConfigForm,
  setActionTypes,
  setAllEmployeeData,
  setAllEmployeeOptions,
  setBranchTypes,
  setError,
  setLoading,
  setNotificationTypes,
  setWarning,
  updateNotificationAuthentication,
  updateNotificationAuthFormValues,
  updateNotificationConfigFormValues
} from "store/slices/administration/notificationConfig";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  authSection,
  dateFormatWithTime,
  DEFAULT_LOCALE_CODE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  EMPTY_SELECTION,
  ERROR_TYPES,
  errorMsgs,
  numberConstants,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import {
  employeeText,
  failureText,
  loaderTypes,
  securityGroupText,
  severityError,
  severityInfo,
  severitySuccess
} from "utils/constants/notificationConfigConstants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getAuthenticatedUserBranch,
  getLanguageId,
  getResponseData,
  getSortedData
} from "utils/helpers";
import NotificationModal from "./NotificationModal";
import { NotificationStyles } from "./NotificationStyles";
import NotificationTable from "./NotificationTable";

const NotificationLayout = () => {
  const {
    actionTypes,
    branchOptions,
    employeeOptions,
    employeeData,
    isAuthenticated,
    isLoading,
    notificationAuthFormValues,
    notificationConfigFormValues,
    notificationTypes,
    throwError,
    throwErrorMsg,
    throwWarning,
    throwWarningMsg
  } = useSelector((state) => state.notificationConfig);

  let initialAuthValues = {
    branch: VALUE_EMPTY_STRING,
    customer: VALUE_EMPTY_STRING,
    personnel: VALUE_EMPTY_STRING,
    authNumber: VALUE_EMPTY_STRING,
    challengeQuestion: VALUE_EMPTY_STRING,
    challengeResponse: VALUE_EMPTY_STRING
  };
  const notificationConfigAuthForm = useFormik({
    initialValues: initialAuthValues
  });
  const dispatch = useDispatch();

  const { auth, currentBranch, localeByBranch, timeZoneOffset } =
    useSelector(selectAuth);
  const programSecurityList = auth?.user?.program_list;
  const programSecurityListArray = programSecurityList.split(",").map(Number);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const [allBranches, setAllBranches] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [loaders, setLoaders] = useState([]);
  const [showAuthenticationSection, setShowAuthenticationSection] =
    useState(false);
  const languageLocaleId = getLanguageId();
  const [valuePersonnelAuth] = useState(authSection?.personnelAuth);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [unselectedRows, setUnselectedRows] = useState([]);
  const [filteredEmployeeOptions, setFilteredEmployeeOptions] = useState([]);
  const [severity, setSeverity] = useState(severityError);
  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  // getting the Data from useGetAllCustomersQuery
  const { data: allCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  // selected personnel auth details
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: notificationAuthFormValues?.personnel?.value
  });
  // getting the Customers on branch change in authentication
  const { data: authenticatedCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId:
      notificationAuthFormValues?.branch === ALL_SELECTION?.value
        ? DISTRICT_ID_GET_ALL_LOCATIONS
        : notificationAuthFormValues?.branch
  });
  const notificationAuthForm = useFormik({
    initialValues: notificationAuthFormValues
  });

  const isShowAuthenticationSection = () => {
    if (showAuthenticationSection) {
      return true;
    }
    return false;
  };
  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    setLoaders((prev) => [...prev, loaderTypes.branch]);
    try {
      const branches = await getDistricts();
      let branchList = branches?.map((branch) => {
        return {
          ...branch,
          label: branch?.name,
          value: branch?.district_id
        };
      });
      const branchData = getSortedData(branchList, "name", "asc");
      setAllBranches([ALL_SELECTION, ...branchData]);
      const filteredBranches = branchData.filter(
        (br) => br.district_id !== mainDistrictId
      );

      dispatch(setBranchTypes([EMPTY_SELECTION, ...filteredBranches]));
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.EXCEPTION)));
    } finally {
      setLoaders((prev) => prev.filter((type) => type !== loaderTypes.branch));
    }
  };

  const onAuthenticatedCallerCliked = () => {
    if (isAuthenticated) {
      dispatch(setAuthenticationState(null));
      dispatch(updateNotificationAuthFormValues(initialAuthValues));
      const updates = !isAuthenticated;

      dispatch(updateNotificationAuthentication(updates));
      dispatch(resetNotificationAuthForm());
      dispatch(clearWarning());
    } else if (showAuthenticationSection) {
      setShowAuthenticationSection(false);
    } else {
      setShowAuthenticationSection(true);
    }
    dispatch(clearError());
  };

  const onCancelAuthCliked = () => {
    setShowAuthenticationSection(false);
  };
  // handle branch change
  const handleBranchChange = (e) => {
    const updatedValues = {
      branch: e.target.value,
      customer: VALUE_EMPTY_STRING,
      personnel: VALUE_EMPTY_STRING
    };

    dispatch(updateNotificationAuthFormValues(updatedValues));
    notificationAuthForm.setValues(updatedValues);
  };
  // customer selection in auth section
  const handleCustomerChange = (event, newValue) => {
    dispatch(clearError());
    const updatedValues = {
      ...notificationAuthFormValues,
      customer: newValue,
      personnel: VALUE_EMPTY_STRING
    };
    dispatch(updateNotificationAuthFormValues(updatedValues));
    notificationAuthForm.setValues(updatedValues);
  };
  // handle Personnel selection in auth section
  const handlePersonnelChange = (selectedValue) => {
    const updatedValues = {
      ...notificationAuthFormValues,
      personnel: selectedValue
    };
    dispatch(updateNotificationAuthFormValues(updatedValues));

    notificationAuthForm.setValues(updatedValues);
  };

  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  // authentication click
  const onAuthenticateClick = async () => {
    dispatch(setLoading(true));
    setIsAuthenticationError(false);
    try {
      // Check if either authNumber or challengeResponse is provided
      if (
        !notificationAuthFormValues?.authNumber?.length &&
        !notificationAuthFormValues?.challengeResponse?.length
      ) {
        setIsAuthenticationError(true);
        return;
      }

      // Authenticate with authNumber
      if (notificationAuthFormValues?.authNumber?.length) {
        if (
          notificationAuthFormValues.authNumber ===
          personnelAuth[0]?.auth_number
        ) {
          dispatch(updateNotificationAuthentication(true));
        } else {
          setIsAuthenticationError(true);
        }
      }

      // Authenticate with challengeResponse
      if (notificationAuthFormValues?.challengeResponse?.length) {
        const hashCode = await generateSHA256Hash(
          notificationAuthFormValues.challengeResponse.trim().toUpperCase()
        );

        const validateChallengeResponse =
          await triggerValidateChallengeResponse({
            main_district_id: String(currentBranch?.district_id),
            language_locale_id: languageLocaleId,
            personnel_id: notificationAuthFormValues?.personnel?.value,
            challenge_response: hashCode
          });

        if (validateChallengeResponse?.data[0]?.is_match_flag === Y_CHECKER) {
          dispatch(updateNotificationAuthentication(true));
        } else {
          setIsAuthenticationError(true);
        }
      }
      notificationConfigAuthForm.setValues(notificationAuthFormValues);

      //dispatch(updateSearchFormValues(updates));
    } catch (error) {
      setIsAuthenticationError(true);
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handlePersonnelAuthChange = (selectedValue) => {
    const updatedValues = {
      ...notificationAuthFormValues,
      personnel: selectedValue,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING
    };
    dispatch(updateNotificationAuthFormValues(updatedValues));
    notificationAuthForm.setValues(updatedValues);
    dispatch(updateNotificationAuthentication(false));
    setIsAuthenticationError(false);
    dispatch(clearError());
  };
  const handleAuthFieldsChange = (name, value) => {
    const updatedValues = {
      ...notificationAuthFormValues,
      [name]: value
    };
    dispatch(updateNotificationAuthFormValues(updatedValues));
    notificationAuthForm.setValues(updatedValues);
  };

  const getNotificationsList = async () => {
    try {
      setLoaders((prev) => [...prev, loaderTypes.dropdowns]);
      const req = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        iso_locale_code: DEFAULT_LOCALE_CODE
      };
      const res = await getResponseData(
        req,
        CF_URLS.notificationConfig.getDropdownListValues,
        3
      );
      let data = res?.data;
      let notifications =
        data[0] &&
        data[0]?.map((item) => {
          return {
            label: item.alert_type_descr,
            value: item.alert_type_id
          };
        });
      notifications?.sort((a, b) => a.value.localeCompare(b.value));
      dispatch(setNotificationTypes(notifications));

      let sortedActions =
        data[1] &&
        data[1]?.sort((a, b) => a.alert_type_id.localeCompare(b.alert_type_id));
      let actions =
        sortedActions &&
        sortedActions?.map((item) => {
          return {
            alert_type_id: item.alert_type_id,
            label: item.alert_action_descr,
            value: item.alert_action_id
          };
        });

      dispatch(setActionTypes(actions));
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setLoaders((prev) =>
        prev.filter((type) => type !== loaderTypes.dropdowns)
      );
    }
  };

  // loading employee data from firestore => employee collection
  const getEmployeesOptions = async () => {
    setLoaders((prev) => [...prev, loaderTypes.employees]);
    try {
      const result = await getEmployeeDetails();
      const response = result?.map((res) => {
        return {
          active_flag: res?.active_flag,
          business_email_addr: res?.business_email_addr,
          district_id: res?.district_id,
          employee_id: res?.employee_id,
          employee_name: `${res?.last_name}, ${res?.first_name}`,
          is_under_op_center_flag: "",
          locale_id: res?.locale_id,
          label: `${res?.last_name}, ${res?.first_name}`,
          value: `${res?.last_name}, ${res?.first_name}`
        };
      });
      response?.sort((a, b) => a.label.localeCompare(b.label));
      dispatch(setAllEmployeeOptions(response));
    } catch (error) {
      setError(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoaders((prev) =>
        prev.filter((type) => type !== loaderTypes.employees)
      );
    }
  };

  const getEmployeeList = async (notification) => {
    try {
      setLoaders((prev) => [...prev, loaderTypes.employeeList]);
      dispatch(setAllEmployeeData([]));
      const req = {
        main_district_id: mainDistrictId,
        iso_locale_code: DEFAULT_LOCALE_CODE,
        alert_type_id: notification.value
      };
      const res = await getResponseData(
        req,
        CF_URLS.notificationConfig.getallvalidemployeeresult,
        1
      );
      let data = res?.data[0];
      data.sort((a, b) => {
        if (
          a.is_selected_flag === Y_CHECKER &&
          b.is_selected_flag !== Y_CHECKER
        )
          return -1;
        if (
          a.is_selected_flag !== Y_CHECKER &&
          b.is_selected_flag === Y_CHECKER
        )
          return 1;
        return a.recipient_name.localeCompare(b.recipient_name);
      });
      let arr = [];
      data.forEach((item) => {
        arr.push({ ...item, isCheck: item?.is_selected_flag === Y_CHECKER });
      });
      dispatch(setAllEmployeeData(arr));
      setUnselectedRows([]);
      setSelectedRows([]);
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setLoaders((prev) =>
        prev.filter((type) => type !== loaderTypes.employeeList)
      );
    }
  };

  const handleSaveClick = async () => {
    try {
      setLoaders((prev) => [...prev, loaderTypes.save]);
      const employeeIdListToAdd =
        selectedRows?.length > 0 &&
        selectedRows
          .filter(
            (rowItem) => rowItem.alert_recipient_type_descr === employeeText
          )
          .map((row) => {
            const recipientId = row.recipient_id;
            const numSpaces = 10 - recipientId?.length;
            const alertSpaces = 3 - row?.alert_action_id?.length;
            const formattedRecipientId = " ".repeat(numSpaces) + recipientId;
            const alertActionId =
              " ".repeat(alertSpaces) + row?.alert_action_id;
            return formattedRecipientId + alertActionId;
          })
          .join("");

      const securityGroupIdListToAdd =
        selectedRows?.length > 0 &&
        selectedRows
          .filter(
            (rowItem) =>
              rowItem.alert_recipient_type_descr === securityGroupText
          )
          .map((row) => {
            const recipientId = row.recipient_id;
            const numSpaces = 10 - recipientId.length;
            const alertSpaces = 3 - row.alert_action_id.length;
            const formattedRecipientId = " ".repeat(numSpaces) + recipientId;
            const alertActionId = " ".repeat(alertSpaces) + row.alert_action_id;
            return formattedRecipientId + alertActionId;
          })
          .join("");
      const alertRecipientIdListToDelete =
        unselectedRows?.length > 0 &&
        unselectedRows
          .map((row) => {
            const recipientId = row.recipient_id;
            const numSpaces = 10 - recipientId.length;
            const formattedRecipientId = " ".repeat(numSpaces) + recipientId;
            return formattedRecipientId;
          })
          .join(",");
      const req = {
        main_district_id: mainDistrictId,
        alert_type_id: notificationConfigFormValues?.notification?.value,
        employee_id_list_to_add: employeeIdListToAdd || VALUE_EMPTY_STRING,
        security_group_id_list_to_add:
          securityGroupIdListToAdd || VALUE_EMPTY_STRING,
        alert_recipient_id_list_to_delete:
          alertRecipientIdListToDelete || VALUE_EMPTY_STRING
      };
      const res = await getResponseData(
        req,
        CF_URLS.notificationConfig.insertRecordIntoAlert,
        1
      );
      const data = res?.data[0];
      if (
        data &&
        (data[0]?.error === numberConstants.zero ||
          data[0]?.rc === numberConstants.zero)
      ) {
        getEmployeesOptions();
        getEmployeeList(notificationConfigFormValues?.notification);
        await import(`dayjs/locale/${localeByBranch}.js`);
        const formattedDate = dayjs
          .utc()
          .add(timeZoneOffset, "hour")
          .locale(localeByBranch)
          .format(dateFormatWithTime);
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80181);
        let errorMessage = errorMsg[0]?.descr?.replace("{0}", formattedDate);

        setSuccessMessage(errorMessage);
        setIsShowSuccessPopup(true);
      } else {
        const response = data?.error;
        if (response) {
          const failureMsg =
            failureText + response?.error + ": " + response?.FailureMessage;

          dispatch(setError(failureMsg));
        } else dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      }
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      setLoaders((prev) => prev.filter((type) => type !== loaderTypes.save));
    }
  };

  const handleAddClick = async () => {
    try {
      setLoaders((prev) => [...prev, loaderTypes.add]);
      const { employee, action } = notificationConfigFormValues;
      let data = {
        is_editable_flag: Y_YES,
        recipient_name: employee?.value,
        email_address: employee?.business_email_addr,
        alert_action_descr: action?.label,
        alert_recipient_type_descr: employeeText,
        recipient_id: employee?.employee_id,
        alert_action_id: action?.value,
        isCheck: true
      };
      let updatedData = [data, ...employeeData];
      updatedData.sort((a, b) => {
        if (a.isCheck && !b.isCheck) return -1;
        if (!a.isCheck && b.isCheck) return 1;
        return a.recipient_name.localeCompare(b.recipient_name);
      });
      setSelectedRows((prevRows) => [...prevRows, data]);
      dispatch(setAllEmployeeData(updatedData));
      setIsCreating(false);
      const updatedValues = {
        branch: VALUE_EMPTY_STRING,
        employee: VALUE_EMPTY_STRING,
        action: VALUE_EMPTY_STRING
      };
      dispatch(updateNotificationConfigFormValues(updatedValues));
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      setLoaders((prev) => prev.filter((type) => type !== loaderTypes.add));
    }
  };

  useEffect(() => {
    if (throwError || throwWarning || isShowSuccessPopup) {
      window.scrollTo(0, 0);
    }
  }, [throwError, throwWarning, isShowSuccessPopup]);

  useEffect(() => {
    getNotificationsList();
    getEmployeesOptions();
    getAllBarnchesFromFireStore();
    dispatch(clearError());
    dispatch(clearWarning());
    dispatch(resetNotificationConfigForm());
    setUnselectedRows([]);
    setSelectedRows([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get challange question
  useEffect(() => {
    if (personnelAuth?.length) {
      const updates = {
        ...notificationAuthFormValues,
        challengeQuestion: personnelAuth[0]?.challenge_question
      };
      dispatch(updateNotificationAuthFormValues(updates));
      notificationAuthForm.setValues(updates);
    }
    // eslint-disable-next-line
  }, [personnelAuth]);

  const authFormProps = {
    allBranches,
    handleBranchChange,
    valuePersonnelAuth,
    allCustomers,
    handleCustomerChange,
    handlePersonnelChange,
    onAuthenticateClick,
    isAuthenticationError,
    isAuthenticated,
    onCancelAuthCliked,
    programSecurityListArray,
    setError,
    isLoading,
    setLoading,
    authFormValues: notificationAuthFormValues,
    handleChange: handleAuthFieldsChange,
    selectedBranch: currentBranch,
    authCustomers: authenticatedCustomers,
    disableBranch: false
  };
  const isShowSearchSection = () => {
    if (isAuthenticated) {
      return true;
    }
    if (showAuthenticationSection) {
      return false;
    }
    return true;
  };
  const handleCancelClick = () => {
    setIsCreating(false);
  };
  const handleClearEvent = async () => {
    setIsCreating(false);
    setSelectedRows([]);
    setUnselectedRows([]);
    dispatch(setAllEmployeeData([]));
    dispatch(
      updateNotificationConfigFormValues({
        notification: VALUE_EMPTY_STRING
      })
    );
    const errorMsg = await getMessageFromCode(errorMsgs.errorCode80182);
    let errorMessage = errorMsg[0]?.descr;
    setSeverity(severityInfo);
    dispatch(setError(errorMessage));
  };
  const modalProps = {
    setIsCreating,
    isCreating,
    handleCancelClick,
    branchOptions,
    allBranches,
    employeeOptions,
    handleAddClick,
    filteredEmployeeOptions,
    setFilteredEmployeeOptions
  };
  const tableProps = {
    setIsCreating,
    setSelectedRows,
    setUnselectedRows,
    selectedRows
  };

  const handleNotificationChange = async (value) => {
    dispatch(clearError());
    dispatch(
      updateNotificationConfigFormValues({
        notification: value
      })
    );
    const filteredActionTypes =
      actionTypes &&
      actionTypes?.filter((action) => action.alert_type_id === value?.value);

    dispatch(setActionTypes([EMPTY_SELECTION, ...filteredActionTypes]));
    if (selectedRows.length > 0 || unselectedRows.length > 0) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80183);
      let errorMessage = errorMsg[0]?.descr;
      dispatch(setWarning(errorMessage));
    }
    getEmployeeList(value);
  };
  return (
    <>
      {loaders?.length > 0 && (
        <FullCircularLoader loading={loaders?.length > 0} />
      )}

      <form>
        <Stack gap={spacing.gap} sx={NotificationStyles.mainDivStyles}>
          <AuthenticationCallerButton
            isAuthenticated={isAuthenticated}
            onAuthenticatedCallerCliked={onAuthenticatedCallerCliked}
          />
          <Stack>
            <NotificationModal {...modalProps} />
          </Stack>
          {isShowSuccessPopup && loaders?.length === 0 && (
            <SuccessPopup
              message={successMessage}
              close={setIsShowSuccessPopup}
            />
          )}
          {throwWarning && (
            <Alert
              severity="warning"
              icon={<img src={WarningIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(clearWarning());
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwWarningMsg}
            </Alert>
          )}

          {throwError && (
            <Alert
              severity={severity}
              icon={
                <img
                  src={
                    severity === severityInfo
                      ? InfoIcon
                      : severity === severitySuccess
                        ? SuccessIcon
                        : ErrorIcon
                  }
                  alt="error"
                  width="20"
                />
              }
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(clearError());
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMsg}
            </Alert>
          )}

          {isShowAuthenticationSection() && (
            <>
              {/* Personnel Authentication Section  */}
              {!isAuthenticated && <AuditAuthForm {...authFormProps} />}
            </>
          )}

          {isAuthenticated && (
            <>
              <SuccessAuthSection
                personalDetailForm={notificationAuthForm}
                valuePersonnelAuth={valuePersonnelAuth}
                handlePersonnelOnChange={handlePersonnelAuthChange}
                disablePersonnel={true}
              />
              <Divider />
            </>
          )}
          {/* Page Section */}
          {isShowSearchSection() && (
            <>
              <Grid container spacing={spacing.gap}>
                <Grid item md={6} lg={3} xs={12}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={null}
                    id="notification-types"
                    disablePortal
                    name={t("notifications.notification")}
                    disabled={null}
                    options={notificationTypes || []}
                    size="medium"
                    value={notificationConfigFormValues?.notification}
                    onChange={(e, value) => {
                      handleNotificationChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("notifications.notification")}
                      />
                    )}
                    sx={NotificationStyles.fieldStyle}
                  />
                </Grid>
                <Grid item md={12} lg={12} xs={12}>
                  <Divider />
                </Grid>
              </Grid>

              <Stack>
                <NotificationTable {...tableProps} />
              </Stack>
              <Grid item md={12} lg={12} xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="start"
                gap={spacing.verticalMargin20}
              >
                <Button
                  disabled={null}
                  id="clear-button"
                  variant="outlined"
                  type="button"
                  onClick={handleClearEvent}
                >
                  {t("common.cancel")}
                </Button>

                <Button
                  variant="contained"
                  size="medium"
                  type="button"
                  onClick={handleSaveClick}
                  disabled={!notificationConfigFormValues?.notification}
                >
                  {t("common.save")}
                </Button>
              </Grid>
            </>
          )}
        </Stack>
      </form>
    </>
  );
};
export default NotificationLayout;
