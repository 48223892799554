import { Button, Typography } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const RoadNetConfirmationPopup = ({
  handleOkClick,
  openConfirmationPopup,
  setOpenConfirmationPopup
}) => {
  const handleClose = () => {
    setOpenConfirmationPopup(false);
  };
  return (
    <CommonModal
      open={openConfirmationPopup}
      title={t("common.confirm")}
      body={
        <>
          <Typography>
            {t("reports.roadNetData.confirmationText") || ""}
          </Typography>
        </>
      }
      buttons={
        <>
          <Button variant="outlined" onClick={handleOkClick}>
            {t("common.yes")}
          </Button>
          <Button variant="contained" onClick={handleClose}>
            {t("common.no")}
          </Button>
        </>
      }
    />
  );
};

export default RoadNetConfirmationPopup;
