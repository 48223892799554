import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Autocomplete,
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  TextField,
  Tooltip
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import logo from "assets/images/SB_Logo.svg";
import { FullCircularLoader } from "components/core";
import InactivityTimeoutComponent from "components/core/inactivity-component/InactivityTimeout";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ROOT } from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import useAuthService from "services/authService";
import {
  selectAuth,
  setBranchLocale,
  setCurrentBranch,
  setError,
  setLocations,
  setTimeZoneOffset
} from "store/slices";
import {
  setDefaultBranchValues,
  updatePreferencesAuthentication
} from "store/slices/customer-preferences";
import { updateDiscrepancyAuthentication } from "store/slices/discrepancy-update";
import {
  resetForm,
  updateAuthentication
} from "store/slices/open-media-audit-search";
import {
  DAYJS_LOCALES,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  errorMsgs,
  sbHelpAttributeId,
  SYSTEM_ID_SecureBase
} from "utils/constants";
import {
  getGlobalAttributeValue,
  getLocalTimeZoneOffset,
  getResponseData,
  openMinimizedWindow
} from "utils/helpers";
import AboutSecureBase from "./AboutSecureBase";
import HEADER_STYLES from "./Header.Styles";
import NavigationUI from "./NavigationUI";

const Header = () => {
  const navigate = useNavigate();
  const authService = useAuthService();
  const { branches, auth, currentBranch } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const imageUrl = auth?.googleUser?.photoURL;
  const firstName = auth?.user?.first_name;
  const lastName = auth?.user?.last_name;
  const loginEmail = auth?.user?.business_email_addr;

  const [branchSelected, setBranchSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [anchorHelp, setAnchorHelp] = useState(null);
  const [anchorProfile, setAnchorProfile] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //states for open/close about securebase modal
  const [openAbout, setOpenAbout] = useState(false);

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };
  const handleHelpClose = () => {
    setAnchorHelp(null);
  };

  useEffect(() => {
    if (branches?.length && Object.keys(auth?.user)) {
      const localBranchId = localStorage.getItem("branch");
      // set previously selected branch
      if (localBranchId !== null) {
        const localBranch = branches.find(
          (branch) => branch?.district_id === localBranchId
        );
        if (localBranch) {
          setBranchSelected(localBranch);
          dispatch(setCurrentBranch(localBranch));
          return;
        }
      }

      const defaultBranches = branches?.filter(
        (branch) => branch?.district_id === auth?.user?.district_id
      );
      // employee doesn't have a default branch
      if (defaultBranches?.length === 0) {
        setBranchSelected(branches[0]);
        dispatch(setCurrentBranch(branches[0]));
        return;
      }

      // set default branch
      setBranchSelected(defaultBranches[0]);
      dispatch(setCurrentBranch(defaultBranches[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, auth, allLocations]);

  useEffect(() => {
    getAllLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentBranch?.id && allLocations?.length)
      dispatch(setBranchLocale(getLocaleForBranch(currentBranch?.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch, allLocations]);

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      let URL = `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.COMMON.getalllocations}`;
      let data = await getResponseData(reqBody, URL, 1);
      if (data?.data[0]) {
        setAllLocations(data?.data[0]);
        dispatch(setLocations(data?.data[0]));
      } else if (data?.error) {
        setAllLocations([]);
      }
      dispatch(setBranchLocale(getLocaleForBranch(currentBranch?.id)));
    } catch (e) {
      setAllLocations([]);
      dispatch(setError(t("common.systemErrorMessage")));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentBranch) {
      setIsLoading((prevState) => true);
      getTimeZoneOffset().then(() => setIsLoading((prevState) => false));
    }
    // eslint-disable-next-line
  }, [currentBranch]);

  const getTimeZoneOffset = async () => {
    const localDateTimeResponse = await getLocalTimeZoneOffset(currentBranch);
    if (
      localDateTimeResponse &&
      localDateTimeResponse[1] &&
      localDateTimeResponse[1][0] &&
      localDateTimeResponse[1][0].error !== errorMsgs?.noError
    ) {
      dispatch(setError(localDateTimeResponse[1][0].error));
    } else if (
      localDateTimeResponse &&
      localDateTimeResponse[0] &&
      localDateTimeResponse[0][0]
    ) {
      dispatch(setTimeZoneOffset(localDateTimeResponse[0][0]));
    }
  };

  const clearAuthOnBranchChange = async () => {
    dispatch(updateAuthentication(false));
    const module = await import("store/slices/container-processing");
    const updateContainerAuthentication = module.updateContainerAuthentication;
    dispatch(updateContainerAuthentication(false));
    dispatch(updatePreferencesAuthentication(false));
    dispatch(updateDiscrepancyAuthentication(false));
  };

  const getLocaleForBranch = (id) => {
    if (allLocations?.length > 0) {
      const selectedLocationDetails = allLocations.filter(
        (location) => location.district_id === id
      );

      return (
        DAYJS_LOCALES[selectedLocationDetails[0]?.iso_locale_code] ||
        DEFAULT_LOCALE
      );
    }

    return DEFAULT_LOCALE;
  };

  const handleBranchOnChange = async (event, newValue) => {
    setBranchSelected(newValue);
    dispatch(setCurrentBranch(newValue));
    navigate("/");
    dispatch(resetForm());
    Promise.allSettled([clearAuthOnBranchChange(), getTimeZoneOffset()]);
    dispatch(
      setDefaultBranchValues({
        prefenceFilteredTableData: [],
        findButtonDisable: true,
        customer: "",
        preferenceTableResultSetOne: {}
      })
    );
  };

  const handleHelpClick = async () => {
    setIsLoading(true);
    const url = await getGlobalAttributeValue(
      currentBranch?.value,
      SYSTEM_ID_SecureBase,
      sbHelpAttributeId
    );
    setIsLoading(false);
    openMinimizedWindow(url);
  };

  //handle open about securebase modal
  const handleOpenAboutModal = () => {
    setOpenAbout((pre) => true);
  };
  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      <AppBar position="relative" sx={HEADER_STYLES.APP_BAR}>
        <Toolbar disableGutters>
          <Container maxWidth="xl" sx={HEADER_STYLES.HEADER_CONTAINER}>
            <Box sx={HEADER_STYLES.BOX}>
              <Link component={Link} to={ROOT}>
                <img alt="go to homepage" src={logo} id="header-logo" />
              </Link>
            </Box>
            <Stack direction="row">
              <Box sx={HEADER_STYLES.BOX}>
                {/* ---------- Navigation ---------- */}
                <NavigationUI />
              </Box>

              {/* -------------------- branch select ----------------- */}
              <Box sx={HEADER_STYLES.BOX_BRANCH}>
                <Autocomplete
                  disabled={branches?.length > 1 ? false : true}
                  disableClearable={true}
                  value={branchSelected || null}
                  onChange={handleBranchOnChange}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="branch-selector"
                  options={branches || []}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  sx={HEADER_STYLES.AUTO_COMPONENT}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      autoComplete="off"
                    />
                  )}
                />
              </Box>

              {/* ----------------------- help --------------------- */}
              <Box sx={HEADER_STYLES.BOX_HELP}>
                <ListItemIcon
                  sx={{ minWidth: "0 !important", cursor: "pointer" }}
                  onClick={({ currentTarget }) => setAnchorHelp(currentTarget)}
                >
                  <HelpOutlineIcon fontSize="large" />
                </ListItemIcon>

                <Menu
                  id="profile-menu"
                  anchorEl={anchorHelp}
                  open={Boolean(anchorHelp)}
                  onClose={handleHelpClose}
                  MenuListProps={{
                    "aria-labelledby": "requests-button"
                  }}
                >
                  <List component="nav">
                    <ListItem button>
                      <ListItemText
                        primary={t("menu.sbHelp")}
                        onClick={() => handleHelpClick()}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary={t("menu.aboutSB")}
                        onClick={() => handleOpenAboutModal()}
                      />
                    </ListItem>
                  </List>
                </Menu>
              </Box>
              {/* ------------- profile / log out ------------- */}
              <Box sx={HEADER_STYLES.BOX_PROFILE}>
                <Tooltip
                  title={
                    <div style={HEADER_STYLES.TOOLTIP_MAIN_DIV}>
                      <div style={HEADER_STYLES.NAME_MAIN_DIV}>
                        <b>{`${firstName} ${lastName}`}</b>
                      </div>
                      <div
                        style={HEADER_STYLES.EMAIL_DIV}
                      >{`${loginEmail}`}</div>
                    </div>
                  }
                  arrow
                  sx={{ borderRadius: 0 }}
                >
                  {imageUrl ? (
                    <Avatar
                      alt={firstName}
                      src={imageUrl || ""}
                      onClick={({ currentTarget }) =>
                        setAnchorProfile(currentTarget)
                      }
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    <Avatar
                      sx={{ bgcolor: "#1B75BC", cursor: "pointer" }}
                      alt={firstName}
                      onClick={({ currentTarget }) =>
                        setAnchorProfile(currentTarget)
                      }
                    >
                      {firstName[0].toUpperCase()}
                    </Avatar>
                  )}
                </Tooltip>

                <Menu
                  id="profile-menu"
                  anchorEl={anchorProfile}
                  open={Boolean(anchorProfile)}
                  onClose={handleProfileClose}
                  MenuListProps={{
                    "aria-labelledby": "requests-button"
                  }}
                >
                  <List component="nav">
                    {/* TODO - need to add change password option with ui changes and user login option */}
                    {/* <ListItem button>
                      <ListItemText primary="Change Password" />
                    </ListItem> */}
                    <ListItem button>
                      <ListItemText
                        primary="Logout"
                        onClick={() => {
                          authService.logOut();
                        }}
                      />
                    </ListItem>
                  </List>
                </Menu>
              </Box>
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
      {openAbout && (
        <AboutSecureBase openAbout={openAbout} setOpenAbout={setOpenAbout} />
      )}
      <InactivityTimeoutComponent />
    </>
  );
};

export default Header;
