export const NonEditableFieldStyles = {
    Container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    Label: {
        color: "#00000099",
        lineHeight: "12px",
        letterSpacing: "0.15px",
        fontSize: "12px"
    },
    Value: {
        marginTop: "5.5px",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        fontStyle: "normal"
    }
};
