import { Box, Stack } from "@mui/material";
import Research from "components/container-processing/research/Research";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const ContainerResearch = () => {
  return (
    <>
      <Stack>
        <Box>
          <PageTitle title={i18n.t("containerResearch.title")} />
        </Box>
        <Research />
      </Stack>
    </>
  );
};
export default ContainerResearch;
