import { ExpandMore } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";

import { CircularLoaderFullPage } from "components/core";

import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import { getCustomerByID, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  setDefaultBranchValues,
  setIsRefreshRequired
} from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import {
  dayMonthYearTimeFormat,
  ERROR_TYPES,
  errorMsgs,
  ROLE_ACCESS_CODES
} from "utils/constants";
import {
  getPreferenceTypesPayloadForReport,
  preferenceId,
  systemId
} from "utils/constants/customer-information/CustomerPreference";
import {
  dateTimeFormatByLocalewithSeconds,
  findErrorMessage
} from "utils/helpers";
import PreferencesLayout from "../preferences-main/PreferencesLayout";
import {
  getCustomerPreferenceHistory,
  getPreferenceTabValues,
  updateCustomerPreferences
} from "../preferences-main/Services";
import {
  AddCutOffDeadlineTableColumns,
  tableOptions
} from "../preferences-main/TableColumns";
import PreferencesReoprtDownloadModal from "../PreferencesReoprtDownloadModal";
import { PreferencesStyles } from "../PreferencesStyles";

const AddCutOffDeadline = () => {
  const { roleAccess, timeZoneOffset, localeByBranch } =
    useSelector(selectAuth);
  const location = useLocation();
  const stateData = location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedSettingsTime, setSelectedSettingsTime] = useState("00:00");
  const [tableData, setTableData] = useState([]);
  const [isCheckbox, setIsCheckBox] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState({
    previousDayCheckbox: false,
    settingsTime: false
  });
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { defaultAuthBranchValues, isAuthenticated } = useSelector(
    (state) => state.customerPreferences
  );
  const redirecToPreviousPage = () => {
    navigate(-1);
    const updatedData = {
      ...defaultAuthBranchValues,
      isDisablePrint: false
    };

    dispatch(setDefaultBranchValues(updatedData));
  };
  const getSelectedCustomer = async (customer_id) => {
    setIsLoading(true);
    try {
      const customer = await getCustomerByID(customer_id);
      setSelectedCustomer(customer[0]);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (stateData?.customer_id) getSelectedCustomer(stateData?.customer_id);
  }, [stateData?.customer_id]);

  const timeOptions = [];
  for (let i = 0; i < 24; i++) {
    // Pad hours with leading zero if necessary
    let hours = i.toString().padStart(2, "0");
    timeOptions.push(`${hours}:00`);
  }
  const handleCheckBoxChange = (e) => {
    let isChecked = e.target.checked;
    setIsCheckBox(isChecked);
  };
  useEffect(() => {
    if (roleAccess) {
      setIsCheckBox(roleAccess.includes(ROLE_ACCESS_CODES.CODE_335));
      if (!roleAccess.includes(ROLE_ACCESS_CODES.CODE_335)) {
        setIsDisabled((prev) => ({
          ...prev,
          previousDayCheckbox: true,
          settingsTime: true
        }));
      }
    }
  }, [roleAccess]);
  //default Settings Time
  useEffect(() => {
    let extracted_value;
    if (stateData?.rowdata?.preference_type_id === preferenceId) {
      if (stateData?.rowdata?.preference_value.length > 5) {
        extracted_value = stateData?.rowdata?.preference_value.substring(0, 5);
      }
    }
    setSelectedSettingsTime(extracted_value || "00:00");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData?.rowdata?.preference_type_id]);

  //  Create the padded string with spaces
  const generatePaddedString = (value, totalLength) => {
    value = String(value);
    const spacesNeed = totalLength - value.length;
    const paddedString = " ".repeat(spacesNeed) + value;

    return paddedString;
  };
  const preferenceTypeId = stateData?.rowdata?.preference_type_id || ""; // Fallback to empty string if undefined
  const paddedString = generatePaddedString(preferenceTypeId, 10);
  const gethistoryTableData = async () => {
    try {
      setIsLoading(true);
      let res = await getCustomerPreferenceHistory(
        stateData?.mainDistrictId,
        selectedCustomer?.id,
        paddedString
      );
      if (res && res.data) {
        // Ensure res.data is an array with at least two elements
        let resultSetOne = res.data[0];
        let resultSetTwo = res.data[1];
        let resultSetOneMap = new Map(
          resultSetOne.map((item) => [item.change_history_id, item])
        );
        let updatedData = resultSetTwo.map((item) => {
          const historyData = resultSetOneMap.get(item.change_history_id);
          const dateFormate = dateTimeFormatByLocalewithSeconds(
            historyData?.change_history_datetime,
            localeByBranch
          );
          return {
            ...historyData,
            ...item,
            date: dateFormate,
            imEmployee: `${historyData?.employee_last_name} ${historyData?.employee_first_name} `,
            authorizedBy: `${historyData?.personnel_last_name} ${historyData?.personnel_first_name} `,
            previousValue: item?.old_value,
            newValue: item?.new_value
          };
        });
        updatedData.sort((a, b) => {
          const dateA = new Date(a.change_history_datetime);
          const dateB = new Date(b.change_history_datetime);
          return dateB - dateA; // Descending order
        });
        setTableData(updatedData);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handeSettingTimeChange = (e) => {
    let value = e.target.value;
    setSelectedSettingsTime(value);
    setThrowErrorMessage("");
    setThrowError(false);
  };

  // xml creation
  const createXmlPayload = () => {
    const settingTimes = isCheckbox
      ? `${selectedSettingsTime}, ${t(
          "preferences.addCutOffDeadline.previousDay"
        )}`
      : selectedSettingsTime;

    const objectValues = `<ObjectValue Object_Id="${preferenceTypeId}" Object_Value="${settingTimes}" Version=""/>`;

    return `
      <ObjectValues>${objectValues}</ObjectValues>
    `;
  };
  const xmlPayload = createXmlPayload();
  const personnelId = isAuthenticated
    ? defaultAuthBranchValues?.personnel_id
    : "";
  // onSaveUpdatePreference handler
  const onSaveUpdatePreference = async () => {
    try {
      setIsLoading(true);
      let res = await updateCustomerPreferences(
        stateData?.mainDistrictId,
        selectedCustomer?.id,
        systemId,
        stateData?.employee_id,
        xmlPayload,
        personnelId
      );
      if (res) {
        setExpanded(false);
        dispatch(setIsRefreshRequired(true));
        if (stateData?.customer_id) {
          if (Number(res.data[0][0]?.error).length > 0) {
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode59028);

            setThrowErrorMessage(
              errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
            );

            setThrowError(true);
          } else {
            if (stateData?.customer_id) {
              await getPreferenceTabValues(
                stateData?.mainDistrictId,
                stateData?.customer_id
              );
            }
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode64095);

            let replacedText = errorMsg[0]?.descr.replace(
              "|",
              t("preferences.addCutOffDeadline.title")
            );
            await import(`dayjs/locale/${localeByBranch}.js`);
            const formattedDate = dayjs
              .utc()
              .add(timeZoneOffset, "hour")
              .locale(localeByBranch)
              .format(dayMonthYearTimeFormat);
            const replaceWithDate = replacedText.replace("|", formattedDate);
            setSuccessMessage(
              replaceWithDate || findErrorMessage(ERROR_TYPES.ISSUE)
            );
            setSuccess(true);
          }
        }
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeAccordionExpand = async (e, expanded) => {
    if (!expanded) {
      return;
    }
    await gethistoryTableData();
  };
  const topTextFieldReportsPayload = [
    {
      key: t("preferences.addCutOffDeadline.title"),
      value: `${selectedSettingsTime}, ${
        isCheckbox ? `${t("preferences.addCutOffDeadline.previousDay")}` : ""
      }`
    }
  ];

  const filteredPreferenceTypes = useMemo(() => {
    return getPreferenceTypesPayloadForReport().filter(
      (type) => type.preference_type_id === preferenceTypeId
    );
  }, [preferenceTypeId]);

  return (
    <>
      <PreferencesLayout>
        {isLoading && <CircularLoaderFullPage loading={isLoading} />}

        <PreferencesReoprtDownloadModal
          title={t("common.downloadReport")}
          showPrintModal={downloadModal}
          setCloseModal={setDownloadModal}
          setIsLoading={setIsLoading}
          customerId={stateData?.customer_id}
          preferenceTypeId={paddedString}
          url={`${CF_URLS.printReports.customerInformation.addOnCutOffDeadlineReport}`}
          topTextFieldReportsPayload={topTextFieldReportsPayload}
          preferenceTypesPayloadForReport={filteredPreferenceTypes}
        />

        {success && (
          <SuccessPopup message={successMessage} close={setSuccess} />
        )}
        {throwError && (
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
        )}

        <Box mb={spacing.verticalMargin20}>
          <Typography variant="subtitle1" color="textSecondary">
            {t("common.customer")}
          </Typography>
          <Typography variant="subtitle1">
            {selectedCustomer?.number?.trim()}
          </Typography>
        </Box>

        <Box sx={PreferencesStyles.borderBottom}></Box>
        <br />
        <Grid container spacing={2}>
          <Grid item md={6} lg={3} xs={12}>
            <FormControl fullWidth variant="outlined">
              <>
                <InputLabel>
                  {t("preferences.addCutOffDeadline.settingTime")}
                </InputLabel>
                <Select
                  label={t("preferences.addCutOffDeadline.settingTime")}
                  name={"settingTime"}
                  value={selectedSettingsTime}
                  onChange={handeSettingTimeChange}
                  disabled={isDisabled.settingsTime}
                >
                  {timeOptions &&
                    timeOptions?.map((record) => {
                      return (
                        <MenuItem value={record} key={record}>
                          {record} {t("preferences.hours")}
                        </MenuItem>
                      );
                    })}
                </Select>
              </>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckbox}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={handleCheckBoxChange}
                    disabled={isDisabled.previousDayCheckbox}
                  />
                }
                label={t("preferences.addCutOffDeadline.previousDay")}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <br />
        <Box> {t("preferences.addCutOffDeadline.discription1")}</Box>
        <br />
        <Box> {t("preferences.addCutOffDeadline.discription2")}</Box>
        <br />
        <Box> {t("preferences.addCutOffDeadline.discription3")}</Box>
        <br />
        <Box sx={PreferencesStyles.borderBottom}></Box>
        <br />

        <Accordion
          sx={PreferencesStyles.accordian}
          expanded={expanded}
          onChange={(e, newExpanded) => {
            setExpanded(newExpanded);
            onChangeAccordionExpand(e, newExpanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              variant="subtitle1"
              style={PreferencesStyles.subTitleStyle}
            >
              {t("preferences.history")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <MUIDataTable
                columns={AddCutOffDeadlineTableColumns}
                options={tableOptions(tableData)}
                data={tableData}
              />
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <br />
        <Box sx={PreferencesStyles.borderBottom}></Box>
        <br />
        <Box sx={PreferencesStyles.buttonStyles}>
          <Button
            variant="outlined"
            type="submit"
            onClick={redirecToPreviousPage}
          >
            <ChevronLeftIcon /> {t("common.back")}
          </Button>
          <Button
            variant="outlined"
            type="submit"
            onClick={() => setDownloadModal(true)}
          >
            {t("common.print")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!roleAccess.includes(ROLE_ACCESS_CODES.CODE_335)}
            onClick={onSaveUpdatePreference}
          >
            {t("common.save")}
          </Button>
        </Box>
        <br />
      </PreferencesLayout>
    </>
  );
};

export default AddCutOffDeadline;
