import dayjs from "dayjs";
import { t } from "i18next";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";

import { getApp } from "firebase/app";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes
} from "firebase/storage";
import i18n from "i18n";
import {
  getCustomName,
  getDoNotReplyConfiguration,
  getFromEmail,
  getMessageFromCode
} from "services/api/query";
import { authentication, storage } from "services/firebase";
import {
  ARRAY_FORMAT_SPACING,
  BOOLEAN_STRING_VALUES,
  DATE_FORMAT,
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_CODE,
  ERROR_TYPES,
  INVALID_DATE,
  OPTIONS_DATE,
  OPTIONS_TIME,
  SUPPORTED_LANGUAGES,
  SYSTEM_ID_SecureBase,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  backspaceText,
  billingCycle,
  dateFormatWithTime,
  dateFormatWithTimeWithSeconds,
  dateFormatWithoutTime,
  emailSendOptions,
  longMonth,
  minimizedWindowHeight,
  minimizedWindowWidth,
  numbersFromZeroToNine,
  shortMonth
} from "utils/constants";
import { unitPriceMaxBase } from "utils/constants/biling-module/BillingTransactionsConstants";
import {
  drInstructionsDelete,
  drInstructionsExist
} from "utils/constants/disaster-module/EventSearchConstants";
import { labelPrinterSetup } from "utils/constants/label-printer-setup/LabelPrinterSetupConstant";
import store from "../../store";

export const getCurrentDateTime = () => {
  // 10/01/2024 12:07
  return new Date()
    .toLocaleString(SUPPORTED_LANGUAGES.ENGLISH_GB.code, DATE_FORMAT)
    .replace(",", ""); // Remove the comma
};

export const formatDate = (inputDateString) => {
  try {
    const inputDate = new Date(inputDateString);

    const formattedDate = `${inputDate.toLocaleString(
      SUPPORTED_LANGUAGES.ENGLISH_GB.code,
      OPTIONS_DATE
    )} ${inputDate.toLocaleString(
      SUPPORTED_LANGUAGES.ENGLISH_GB.code,
      OPTIONS_TIME
    )}`;

    return formattedDate;
  } catch (error) {
    return INVALID_DATE;
  }
};

export const formatDateWithoutTime = (inputDateString) => {
  try {
    const inputDate = new Date(inputDateString);

    const formattedDate = `${inputDate.toLocaleString(
      SUPPORTED_LANGUAGES.ENGLISH_GB.code,
      OPTIONS_DATE
    )}`;

    return formattedDate;
  } catch (error) {
    return INVALID_DATE;
  }
};

export const formatArrayAsString = (arr) => {
  const spacing = ARRAY_FORMAT_SPACING;
  let resultString = "";

  for (const value of arr) {
    resultString += value.toString().padEnd(spacing);
  }

  return resultString;
};

/**
 * @name GetAuthenticatedUserBranch
 * @description Get authenticated user's branch id from redux store
 *
 * @returns
 */
export const getAuthenticatedUserBranch = () => {
  const currentBranch = store.getState().auth.currentBranch;
  return currentBranch?.district_id || "";
};

/**
 * @name GetAuthenticatedUserId
 * @description Get authenticated user's id from redux store
 *
 * @returns
 */
export const getAuthenticatedUserId = () => {
  const loggedUser = store.getState().auth.auth.user;
  return loggedUser?.id || 0;
};

/**
 * @name GetAuthenticatedUserName
 * @description Generate authenticated user's name from redux store as (Lastname, Firstname)
 * @example
 * Fistname => John
 * Lastname => Doe
 * Result => Doe, John
 *
 * @returns
 */
export const getAuthenticatedUserName = () => {
  const loggedUser = store.getState().auth.auth.user;
  return `${loggedUser?.last_name}, ${loggedUser?.first_name}` || 0;
};

/**
 * @name GetAuthenticatedUserLogin
 * @description Get authenticated user's login from redux store
 *
 * @returns
 */
export const getAuthenticatedUserLogin = () => {
  const loggedUser = store.getState().auth.auth.user;
  return loggedUser?.login || VALUE_EMPTY_STRING;
};

/**
 * @name GetAuthenticatedUserToken
 * @description Get authenticated user's id from firebase auth instance
 *
 * @returns
 */
export const getAuthenticatedUserToken = async () => {
  const loggedUserToken = await authentication.currentUser.getIdToken();
  return loggedUserToken;
};

// To get the language code based on the browser's selected language
export function getLanguageId() {
  // Get the browser's selected language
  const browserLanguage = navigator?.language || navigator?.userLanguage;

  // Search for a matching language in the supportedLanguages object
  for (const key in SUPPORTED_LANGUAGES) {
    if (SUPPORTED_LANGUAGES[key]?.code === browserLanguage) {
      return SUPPORTED_LANGUAGES[key]?.id;
    }
  }

  // Default to English (United States) if no match is found
  return SUPPORTED_LANGUAGES.ENGLISH_US.id;
}

/**
 * @name formatDateByLocale
 * @description Format datetime string based on the given locale
 * @param {string} date string value of a date
 * @param {string} locale string value of the locale
 * @param {boolean} withTime date needs to include time also
 * @returns
 */
export const formatDateByLocale = async (
  date,
  locale = DEFAULT_LOCALE,
  withTime = true,
  withSec = false
) => {
  let dateObject = date;
  if (!dayjs.isDayjs(date)) {
    // converting date if not a dayjs object
    dateObject = dayjs(date);
  }
  // import the locale file based on the parameter and set it to dayjs locale
  await import(`dayjs/locale/${locale}.js`).catch((error) => {
    dayjs.locale(DEFAULT_LOCALE);
  });
  // Check and return if the parsed object is a valid date, if not return an empty string
  return dateObject.isValid()
    ? dateObject
        .locale(locale)
        .format(
          withTime
            ? withSec
              ? dateFormatWithTimeWithSeconds
              : dateFormatWithTime
            : dateFormatWithoutTime
        )
    : "";
};

export const billDateFormatByLocale = async (dateString, locale) => {
  try {
    const dateObject = dayjs(
      dateString
        .split("/")
        .map((num) => num.padStart(2, "0"))
        .join("/")
    );
    // import the locale file based on the parameter and set it to dayjs locale
    await import(`dayjs/locale/${locale}.js`);
    // Check and return if the parsed object is a valid date, if not return an empty string
    return dateObject.isValid()
      ? dateObject.locale(locale).format(dateFormatWithoutTime)
      : VALUE_EMPTY_STRING;
  } catch (error) {
    return VALUE_EMPTY_STRING;
  }
};

export const formatBillDateByLocale = async (
  dates,
  locale = DEFAULT_LOCALE,
  withTime = true
) => {
  try {
    let optionsArray = []; // modified dates

    for (let index = 0; index < dates.length; index++) {
      const element = dates[index]?.value;

      // Parsing the string into a dayjs object
      const dateObject = dayjs(element);
      // import the locale file based on the parameter and set it to dayjs locale
      await import(`dayjs/locale/${locale}.js`);
      // Check and return if the parsed object is a valid date, if not return an empty string
      let date = dateObject.isValid()
        ? dateObject
            .locale(locale)
            .format(withTime ? dateFormatWithTime : dateFormatWithoutTime)
        : "";

      optionsArray.push({
        ...dates[index],
        value: dates[index]?.value,
        label: date
      });
    }

    return optionsArray;
  } catch (error) {
    return [];
  }
};

/**
 * @name formatDateByLocale
 * @description Format datetime string based on the given locale
 * @param {string} date string value of a date
 * @param {string} locale string value of the locale
 * @returns
 */
export const formatDateByLocaleWithTime = async (
  date,
  locale = DEFAULT_LOCALE
) => {
  // Parsing the string into a dayjs object
  const dateObject = dayjs(date);
  // import the locale file based on the parameter and set it to dayjs locale
  await import(`dayjs/locale/${locale}.js`);
  // Check and return if the parsed object is a valid date, if not return an empty string
  return dateObject.isValid()
    ? dateObject.locale(locale).format("L HH:mm")
    : "";
};
/**
 * @name getDayOfWeekCode
 * @description Get the day of week code of a given date
 * @param {string} date date value
 * @returns
 */
export const getDayOfWeekCode = (date) => {
  // Parsing the string into a dayjs object
  const dateObject = dayjs(date);
  // get the day from the date and substring
  return dayjs()
    .weekday(dateObject.day())
    .toString()
    .substring(0, 3)
    .toUpperCase();
};

/**
 * @name limitFormikTextFieldLength
 * @description event handler for limit text field character length in a formik form
 *
 * @param {object} props
 * @param {Event} props.form formik object
 * @param {Event} props.event event handler
 * @param {number} props.length maximum length
 */
export const limitFormikTextFieldLength = (form, event, length) => {
  if (event.target.value.length <= length) {
    form.handleChange(event);
  }
};

/**
 * @name limitFormikTextFieldMaxValue
 * @description event handler for limit number field maximum value in a formik form
 *
 * @param {object} props
 * @param {Event} props.form formik object
 * @param {Event} props.event event handler
 * @param {number} props.maxValue maximum value
 * @param {number} props.minValue minimum value
 */
export const limitFormikTextFieldMaxValue = (
  form,
  event,
  maxValue,
  minValue = 0
) => {
  if (
    Number(event.target.value) <= maxValue &&
    Number(event.target.value) >= minValue
  ) {
    form.handleChange(event);
  }
};

/**
 * @name generateSHA256Hash
 * @description Convert a string into a hashcode using SHA256
 * @param {string} str string value of a challenge response
 * @returns
 */
export function generateSHA256Hash(value) {
  // Returns a SHA1 hash in the format returned by the SQL HASHBYTES function
  // Note: upper and lower case will yield different values.
  let normalizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
  //converting the string to hash code
  return crypto.subtle
    .digest("SHA-256", new TextEncoder().encode(normalizedValue))
    .then((buffer) => {
      let hashArray = Array.from(new Uint8Array(buffer));
      let hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
      return hashHex.substring(0, 50).toUpperCase();
    });
}

/**
 * @name validateUserHasProgramId
 * @description Validate whether authenticated user has the program id from redux store
 * @param {Number} programId program_id to be checked
 * @returns
 */
export const validateUserHasProgramId = (programId) =>
  store.getState().auth.roleAccess.includes(String(programId));

/**
 * @description Filtering customer list
 * @param {Array} customerList
 * @returns
 */
export const customerFilter = (customerList) => {
  if (customerList?.length > 0) {
    try {
      const filteredList = customerList?.filter(
        (cust) =>
          cust.active_flag === Y_CHECKER &&
          cust.on_hold === BOOLEAN_STRING_VALUES.FALSE &&
          cust.customer_id !== "" &&
          cust.customer_parent_id !== "" &&
          cust.district_id !== "" &&
          cust.billing_cycle_code !== ""
      );

      return filteredList;
    } catch (error) {
      return customerList;
    }
  } else {
    return customerList;
  }
};

// helper function for bill date field default value set to the form
export const handleDefaultDate = (form, value) => {
  if (form?.values?.billCycle.trim() === billingCycle.eom) {
    form.setValues({
      ...form?.values,
      billDateEom: value
    });
  }
};

//find error message
export const findErrorMessage = (error) => {
  let relatedErrorMessage = "";
  switch (error) {
    case ERROR_TYPES.FAILED:
      relatedErrorMessage = t("mediaDestructionDelivery.failedError");
      break;
    case ERROR_TYPES.EXCEPTION:
      relatedErrorMessage = t("mediaDestructionDelivery.errorOccurred");
      break;
    case ERROR_TYPES.ISSUE:
      relatedErrorMessage = t("common.systemErrorMessage");
      break;
    case ERROR_TYPES.SQLERROR:
      relatedErrorMessage = t("mediaDestructionDelivery.sqlError");
      break;
    default:
      break;
  }
  return relatedErrorMessage;
};

/**
 * @description Format date with locale to show only - Month YYYY
 * @param {string} dateString
 * @param {string} locale
 * @returns {string}
 */
export const dateFormatToMonthAndYear = (dateString, locale) => {
  try {
    if (dateString && dateString !== "") {
      const [year, month] = dateString?.split("T")[0]?.split("-") || [];
      const monthFormat = new Intl.DateTimeFormat(
        locale,
        shortMonth
      ).formatToParts(new Date(year, month - 1));
      const monthName = monthFormat.find((part) => part.type === "month").value;
      return `${monthName} ${year}`;
    } else {
      return dateString;
    }
  } catch (error) {
    return dateString;
  }
};

export const dateTimeFormatByLocale = (dateString, locale) => {
  try {
    if (dateString && dateString !== "") {
      const date = new Date(dateString);

      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");

      const formattedDate = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false // use 24-hour
      }).format(new Date(`${year}-${month}-${day}T${hours}:${minutes}`));

      return formattedDate.replace(",", "");
    } else {
      return dateString;
    }
  } catch (error) {
    return dateString;
  }
};
// date Formate date month and Year time and seconds

export const dateTimeFormatByLocalewithSeconds = (dateString, locale) => {
  try {
    if (dateString && dateString !== "") {
      const date = new Date(dateString);

      // Extracting year, month, day, hours, minutes, and seconds
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      const seconds = String(date.getUTCSeconds()).padStart(2, "0");

      // Creating a date string in ISO format including seconds
      const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

      // Formatting the date with Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // use 24-hour format
        timeZone: "UTC" // Ensure the date is treated as UTC
      }).format(new Date(isoDateString));

      return formattedDate.replace(",", "");
    } else {
      return dateString;
    }
  } catch (error) {
    return dateString;
  }
};
//  Formate date month and Year

export const dateTimeFormatByDateMonthrYear = (dateString, locale) => {
  try {
    if (dateString && dateString !== "") {
      const date = new Date(dateString);

      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");

      const formattedDate = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour12: false // use 24-hour
      }).format(new Date(`${year}-${month}-${day}T${hours}:${minutes}`));

      return formattedDate.replace(",", "");
    } else {
      return dateString;
    }
  } catch (error) {
    return dateString;
  }
};

/**
 * @description Converts the date to English
 * @param {string} dateStr
 * @param {string} locale
 * @returns {string}
 */
export const convertDateToEnglish = (dateStr, locale) => {
  const englshMonths = Array.from({ length: 12 }, (_, index) =>
    new Intl.DateTimeFormat(DEFAULT_LOCALE_CODE, longMonth).format(
      new Date(2000, index)
    )
  );

  const year = dateStr.match(/\d{4}/)[0];

  const monthIndex = englshMonths.findIndex((_, i) =>
    dateStr.includes(
      new Intl.DateTimeFormat(locale, shortMonth).format(new Date(2000, i))
    )
  );

  return `${englshMonths[monthIndex]} ${year}`;
};

// Function to sort array
export const getSortedData = (data, sortField, sortOrder) => {
  let order = sortOrder === "asc" ? 1 : -1;
  let sortedData = data.sort((a, b) => {
    return a[sortField].toLowerCase() > b[sortField].toLowerCase()
      ? order
      : a[sortField].toLowerCase() < b[sortField].toLowerCase()
        ? -order
        : 0;
  });
  return sortedData;
};

// Send Email Functionality
export const sendEmail = async (
  sendMailAddresses,
  mailBody,
  mailSubject,
  attechment
) => {
  try {
    let custName = await getCustomName();
    let fromEmail = await getFromEmail();
    let doNotReply = await getDoNotReplyConfiguration();
    let fromMailObject = fromEmail?.map((m) => {
      return { emailAddress: m, name: custName[0] };
    });

    let reqBody = {
      body: mailBody,
      cc: [],
      emailTemplateProperties: emailSendOptions?.emailTemplateProperties,
      from: fromMailObject[0],
      languageCode: DEFAULT_LOCALE,
      replyTo: doNotReply[0],
      subject: mailSubject,
      to: sendMailAddresses
    };
    if (attechment?.documentPath) {
      reqBody = {
        ...reqBody,
        attachment: [attechment?.documentPath]
      };
    }
    const response = await callToCloudFunction(
      reqBody,
      `${CF_URLS?.notification?.sendMail}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// sort large chunk of data while avoid freezing
export const asyncSort = (objArray, compareFunc) => {
  return new Promise((resolve) => {
    const nonBlockingSort = (array, compare, callback) => {
      setTimeout(() => {
        const sortedArray = array.sort(compare);
        callback(sortedArray);
      }, 0);
    };

    nonBlockingSort(objArray, compareFunc, resolve);
  });
};

export const formatNumber = (value, precision, update) => {
  const number = parseFloat(value);

  const maxBase = unitPriceMaxBase;
  const maxLimit = parseFloat(`${maxBase}.${"9".repeat(Number(precision))}`);

  if (number > maxLimit) {
    return update
      ? formatNumber(update, precision)
      : `0.${"0".repeat(Number(precision))}`;
  }

  let [integerPart, decimalPart = ""] = number.toString().split(".");
  decimalPart = decimalPart.padEnd(Number(precision), "0");

  return `${integerPart}.${decimalPart}`;
};

export const isValidUnitPriceNumInput = (input, precision) => {
  const regex = new RegExp(`^\\d*(\\.\\d{0,${precision}})?$`);
  return regex.test(input);
};

export const formatNumberWithCommas = (value, precision) => {
  const number = parseFloat(value);

  const maxMainPart = unitPriceMaxBase;
  const maxDecimalPart = parseFloat(
    (
      (Math.pow(10, Number(precision)) - 1) /
      Math.pow(10, Number(precision))
    ).toFixed(Number(precision))
  );
  const maxValue = parseFloat(
    `${maxMainPart}.${maxDecimalPart.toString().split(".")[1]}`
  );

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: Number(precision),
    maximumFractionDigits: Number(precision)
  });

  if (number > maxValue) {
    return formatter.format(maxValue);
  }

  return formatter.format(number);
};

//convert url to blob type
export const convertUrlToBlob = (url, fileName) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = (event) => {
    const blob = xhr.response;
    const urlb = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = urlb;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent("click"));
  };
  xhr.open("GET", url);
  xhr.send();
};

export function toFileTimeUtc(date) {
  // Define the offset between January 1, 1601 and January 1, 1970
  const EPOCH_OFFSET = 11644473600000; // in milliseconds
  // Number of 100-nanosecond intervals per millisecond
  const TICKS_PER_MILLISECOND = 10000;
  const milliseconds = date.valueOf();
  const fileTimeUtc = (milliseconds + EPOCH_OFFSET) * TICKS_PER_MILLISECOND;

  const ticksSinceLastMillisecond =
    (date.millisecond() % 1) * TICKS_PER_MILLISECOND;
  return fileTimeUtc + Math.round(ticksSinceLastMillisecond);
}
// print report
export const printReport = async (reqBody, reportName, isDownload = true) => {
  try {
    const response = await callToCloudFunction(reqBody, reportName);
    if (!response?.data?.isSuccess) {
      return {
        success: false,
        error: i18n.t("common.printError")
      };
    } else {
      const documentPath = JSON.parse(response?.request?.response).data
        ?.response?.gcs_path;
      const fileName = response.data.data.response.result.filename;
      if (documentPath) {
        //get public url for the document
        const starsRef = ref(storage, documentPath);
        const downloadUrl = await getDownloadURL(starsRef);
        if (downloadUrl) {
          if (isDownload) {
            await convertUrlToBlob(downloadUrl, fileName);
          }
          return { success: true, documentPath };
        }
      }
    }
  } catch (e) {
    return {
      success: false,
      error: i18n.t("common.printError")
    };
  }
};

// Get user name for reports
export const getUserName = (user) => {
  return (
    user?.first_name.charAt(0).toUpperCase() +
    ". " +
    user?.last_name.charAt(0).toUpperCase() +
    user?.last_name.slice(1)
  );
};

// returns a random 10 digit number string. eg: "9839184466"
export const generateRandomSeed = () => {
  return Math.floor(Math.random() * 1e10)
    .toString()
    .padStart(10, "0");
};

// call to cloud function and get data from firestore
export const getResponseData = async (requestBody, url, count) => {
  const response = await callToCloudFunction(requestBody, url);
  const data = await getDataFromFirestore(response, count, response.data.docId);
  return data;
};

export const getLocalTimeZoneOffset = async (currentBranch) => {
  try {
    let reqBody = {
      main_district_id: currentBranch?.value
    };
    const localDateAndTime = await getResponseData(
      reqBody,
      `${CF_URLS.containerProcessing?.getLocalDateTime}`,
      2
    );
    const localDateTimeResponse = localDateAndTime?.data;
    return localDateTimeResponse;
  } catch (error) {
    return false;
  }
};
export const getSettingsValue = async (mainDistrictId, typeId) => {
  const reqBody = JSON.stringify({
    main_district_id: mainDistrictId,
    district_id: mainDistrictId,
    system_id: SYSTEM_ID_SecureBase
  });

  const dataSets = await getResponseData(
    reqBody,
    CF_URLS.billingTransactions.getSettingValues,
    1
  );

  if (dataSets && dataSets?.data && dataSets?.data[0]?.length > 0) {
    const filteredItem = dataSets?.data[0]?.filter(
      (item) => item.setting_type_id === typeId
    );

    const filteredValue = filteredItem[0]?.value;
    return filteredValue;
  }
};

export const selectEnv = () => {
  if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.dev1) {
    return labelPrinterSetup.logicalNameEnv.dev1;
  } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.dev2) {
    return labelPrinterSetup.logicalNameEnv.dev2;
  } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.qa1) {
    return labelPrinterSetup.logicalNameEnv.qa1;
  } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.qa2) {
    return labelPrinterSetup.logicalNameEnv.qa2;
  } else if (process.env.REACT_APP_ENV === labelPrinterSetup.env.uat) {
    return labelPrinterSetup.logicalNameEnv.uat;
  } else {
    return "";
  }
};

export const findArrayElementByValue = (array, value) => {
  return array.find((element) => {
    return element.value === value;
  });
};

export const removeDuplicates = (data, uniqueId) => {
  if (!data) {
    return;
  }
  if (!Array.isArray(data)) {
    data = [data];
  }
  const uniqueData = {};
  data.forEach((item) => {
    const id = item[uniqueId];
    if (!uniqueData[id]) {
      uniqueData[id] = item;
    }
  });
  const uniqueArr = Object.values(uniqueData);
  return uniqueArr;
};
export const getOptions = (data, id, name) => {
  if (!data) {
    return;
  }
  if (!Array.isArray(data)) {
    data = [data];
  }
  const dataOptions = data.map((item) => {
    return {
      alternateId: item.alternate_id,
      label: item[name],
      value: item[id]
    };
  });
  return dataOptions;
};
// get the error msg from firestore msg collection
export const getTheFirestoreErrorMessage = async (msgId) => {
  const msg = await getMessageFromCode(msgId);
  return { errorMsg: msg[0]?.descr };
};

// open minimized window
export const openMinimizedWindow = (url) => {
  var left = window.screen.width / 2 - minimizedWindowWidth / 2;
  var top = window.screen.height / 2 - minimizedWindowHeight / 2;
  window.open(
    url,
    "targetWindow",
    `toolbar=no,
    location=no,
    status=no,
    menubar=no,
    scrollbars=yes,
    resizable=yes,
    width=${minimizedWindowWidth},
    height=${minimizedWindowHeight},
    top=${top},
    left=${left}`
  );
};

export const getGlobalAttributeValue = async (
  districtId,
  systemId,
  globalAttributeId
) => {
  let reqBody = {
    main_district_id: districtId,
    system_id: systemId,
    global_attribute_type_id: globalAttributeId
  };
  let URL = CF_URLS.vaultConfiguration.getGlobalAttributeValue;
  const res = await getResponseData(reqBody, URL, 1);
  let data = res.data?.[0];
  let globalAttributeVaule = data?.[0]?.global_attribute_value;
  return globalAttributeVaule;
};

/**
 * @name decimalSymbolOfNumber
 * @description get the decimal point character passing the locale
 * @param {string} locale language locale
 * @returns
 */
export const decimalSymbolOfNumber = (locale) => {
  const number = 0.1;
  return number.toLocaleString(locale).substring(1, 2);
};

/**
 * @name numberFilter
 * @description check for numbers or locale based decimal character or 'backspace'
 * @param {string} key key of the input
 * @returns
 */
export const numberFilter = (key, locale) => {
  const decimalSperator = decimalSymbolOfNumber(locale);
  // This is to allow for globalization of numbers
  // since the decimal seperator varies with different regional settings.
  // So the decimal point was removed and replace with the global regional decimal
  // character.
  const searchText = numbersFromZeroToNine + decimalSperator;
  if (searchText.indexOf(key) + 1 === 0 && key !== backspaceText) {
    return false;
  }
  return true;
};

/**
 * @name isRTFContent
 * @description check if the content starts with the RTF header
 * @param {string} content text content to check
 * @returns
 */
export const isRTFContent = (content) => {
  const rtfHeaderRegex = /^{\\rtf/i;
  return rtfHeaderRegex.test(content);
};

/**
 *
 * @param {number} number number to be formatted
 * @param {string} locale locale used for the formatting
 * @param {number} minimumFractionDigits minimum decimal points
 * @param {*} maximumFractionDigits maximum decimal points
 * @returns
 */
export const formatNumberByLocale = (
  number,
  locale = DEFAULT_LOCALE_CODE,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  // Parse the input to ensure it's a valid number, including negative numbers
  const parsedNumber = parseFloat(number);

  // Return empty string for null, undefined, empty, or invalid inputs (but allow negative numbers)
  if (isNaN(parsedNumber)) {
    return number; // Handle non-numeric inputs
  }

  // Format the valid number (including negative numbers)
  return Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits
  }).format(parsedNumber);
};

/**
 * Identifies and returns the duplicate elements in an array.
 * @param {Array} arr - The input array containing elements to check for duplicates.
 * @returns {Array} An array containing the duplicate elements found in the input array.
 *
 */
export const getDuplicates = (arr) => {
  const seen = new Set();
  const duplicates = arr.reduce((acc, item) => {
    if (seen.has(item)) {
      acc.add(item);
    } else {
      seen.add(item);
    }
    return acc;
  }, new Set());

  return [...duplicates];
};

/**
 * @param {array} files files array file name and file content should apply
 * @param {string} customerId selected customer Id
 * @param {string} eventId created event id
 * @returns
 */
export const uploadDrInstructionFilesToGcs = async (
  files,
  customerId,
  eventId
) => {
  const firebaseApp = getApp();
  const storage = getStorage(
    firebaseApp,
    process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC
  );

  try {
    const uploadPath = getDrInstructionMainFolderPath(customerId, eventId);

    // Validate the upload path
    if (!uploadPath || typeof uploadPath !== "string") {
      throw new Error(t("gcpFileUpload.invalidPath"));
    }

    const uploadPromises = files
      .filter(
        (file) =>
          file.status !== drInstructionsDelete &&
          file.status !== drInstructionsExist
      )
      .map(async (file) => {
        const storageRef = ref(
          storage,
          `${uploadPath}/${file.dr_instruction_name}`
        );

        // Use the file's actual type
        const blob = new Blob([file.dr_instruction], {
          type: file.dr_instruction_type || "application/rtf"
        });

        // Upload the Blob
        await uploadBytes(storageRef, blob);
      });

    // Wait for all files to finish uploading
    await Promise.all(uploadPromises);

    return { failed: false, uploadPath };
  } catch (error) {
    // Check if the error is an instance of Error
    if (error instanceof Error) {
      switch (error.code) {
        case "storage/object-not-found":
          return { failed: true, message: t("gcpFileUpload.fileNotExist") };

        case "storage/unauthorized":
          return { failed: true, message: t("gcpFileUpload.noAccessToFile") };

        default:
          return { failed: true, message: t("gcpFileUpload.unknownError") };
      }
    } else {
      // Handle non-error rejected values
      return { failed: true, message: t("gcpFileUpload.unknownError") };
    }
  }
};

/**
 * @param {array} files files array file name and file content should apply
 * @param {string} customerId selected customer Id
 * @param {string} eventId created event id
 * @param {*} onComplete function
 * @returns
 */
export const deleteMultipleFilesFromFB = async (
  files,
  customerId,
  eventId,
  onComplete = () => {}
) => {
  const deleteFiles = files.filter(
    (file) => file.status === drInstructionsDelete
  );
  if (deleteFiles.length === 0) {
    return { success: true, message: t("gcpFileUpload.noFilesToDelete") };
  }
  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp);
  const mainPath = getDrInstructionMainFolderPath(customerId, eventId);
  const deletePromises = deleteFiles.map((file) => {
    const fbFilePath = getGsFilePath(`${mainPath}/${file.dr_instruction_name}`);
    const fileRef = ref(storage, fbFilePath);
    return deleteObject(fileRef);
  });

  try {
    await Promise.all(deletePromises);
    onComplete();
    return {
      success: true,
      message: t("gcpFileUpload.filesDeletedSuccessfully")
    };
  } catch (error) {
    onComplete();
    switch (error.code) {
      case "storage/object-not-found":
        return { failed: true, message: t("gcpFileUpload.fileNotExist") };

      case "storage/unauthorized":
        return { failed: true, message: t("gcpFileUpload.noAccessToFile") };

      default:
        return { failed: true, message: t("gcpFileUpload.unknownError") };
    }
  }
};

/**
 * @param {string} fbFilePath
 * @param {string} fileName
 * @param {*} onComplete function
 * @returns
 */
export const downloadFileFromFB = async (
  fbFilePath,
  fileName,
  onComplete = () => {}
) => {
  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp);
  try {
    const url = await getDownloadURL(ref(storage, fbFilePath));
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
      const urlb = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = urlb;
      link.download = fileName;
      link.dispatchEvent(new MouseEvent("click"));
      onComplete();
    };
    xhr.open("GET", url);
    xhr.send();
  } catch (error) {
    onComplete();
    switch (error.code) {
      case "storage/object-not-found":
        return { failed: true, message: t("gcpFileUpload.fileNotExist") };

      case "storage/unauthorized":
        return { failed: true, message: t("gcpFileUpload.noAccessToFile") };

      default:
        return { failed: true, message: t("gcpFileUpload.unknownError") };
    }
  }
};

export const getDrInstructionMainFolderPath = (customerId, eventId) =>
  `DR_INSTRUCTION_FILES/DR_EVENT_FILES/${customerId}/${eventId}`;

export const getSSDrInstructionFolderPath = (customerId, fileId, fileName) =>
  `DR_INSTRUCTION_FILES/UPLOAD/${customerId}/${fileId}_${fileName}`;

export const getGsFilePath = (filePath) => {
  return `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}/${filePath}`;
};

export const extractEventNumber = (str) => {
  const match = str.match(/Event (\d+)/);
  return match ? parseInt(match[1], 10) : null;
};

export const escapeXml = (text) => {
  const escapeChars = {
    "<": "&lt;",
    ">": "&gt;",
    "&": "&amp;",
    '"': "&quot;",
    "'": "&apos;"
  };

  return text.replace(/[<>&"']/g, (char) => escapeChars[char]);
};

export const escapeXmlForReport = (text) => {
  const escapeChars = {
    "&": "%26",
    "@": "%40",
    "#": "%23",
    $: "%24",
    "%": "%25",
    "^": "%5E",
    '"': "%22",
    "<": "%3C",
    ">": "%3E",
    "?": "%3F",
    ",": "%2C",
    "'": "%27"
  };

  return text.replace(/[<>&@#%^"',?]/g, (char) => escapeChars[char]);
};
