import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { DatePickerField } from "components/core";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { errorMsgs, ROLE_ACCESS_CODES, VALUE_Y } from "utils/constants";
import {
  eventStatus,
  phoneInputProps
} from "utils/constants/disaster-module/EventSearchConstants";
import { validateUserHasProgramId } from "utils/helpers";
import AuthRequestModal from "./AuthRequestModal";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";

const EventDetailsTable = ({
  customers,
  customerSelected,
  customerElKey,
  eventCoordinator,
  handleCustomerChange,
  handleEventCoordinator,
  handleDrEventDate,
  handleEventTypeChange,
  handleHotSiteEventID,
  handleCustomerContact,
  handleCountryCode,
  handlePrimaryPhoneField,
  handlePrimaryCellular,
  handleSecondaryPhone,
  handleSecondaryCellular,
  handleErrorMessage,
  eventTypes,
  personnelList,
  allCountries,
  EventDetailForm,
  localeByBranch,
  DEFAULT_LOCALE,
  setAuthModalSuccess,
  authModalSuccess,
  setAuthRequestModalVisible,
  authRequestModalVisible,
  setAuthRequestForDeclared,
  authRequestForDeclared,
  setAuthRequestForVerified,
  authRequestForVerified,
  setPersonnelSelected,
  personnelSelected,
  isValid257Role,
  currentDate,
  timeZoneOffsetChanged,
  showDeclaredByPersonnalError,
  showAuthSuccess
}) => {
  const { t } = useTranslation();
  // handle auth request modal success event
  const handleAuthRequestModalSuccess = () => {
    setAuthRequestModalVisible((prevState) => false);
    if (authRequestForDeclared) {
      showAuthSuccess(
        t("disasterRecovery.eventDetail.declaredByPersonnelLabel")
      );
      EventDetailForm.handleChange({
        target: { name: "declaredByPersonnel", value: personnelSelected }
      });
    } else if (authRequestForVerified) {
      showAuthSuccess(
        t("disasterRecovery.eventDetail.verifiedByPersonnelLabel")
      );
      EventDetailForm.handleChange({
        target: { name: "verifiedByPersonnel", value: personnelSelected }
      });
    }
    setAuthRequestForDeclared(false);
    setAuthRequestForVerified(false);
  };

  return (
    <>
      <form onSubmit={EventDetailForm?.handleSubmit}>
        <Stack direction={"column"} gap={spacing.gap}>
          <Grid container gap={spacing.gap} wrap="nowrap">
            <Grid item xs={3} sm={3}>
              {/* ---------- Customer selection ---------- */}
              <Autocomplete
                id="customerSelectLabel"
                key={customerElKey}
                options={customers}
                value={EventDetailForm?.values?.customer}
                fullWidth
                disabled={
                  !(
                    EventDetailForm?.values?.drEventStatusId ===
                      eventStatus.newEvent && isValid257Role
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("disasterRecovery.eventDetail.customerLabel")}
                  />
                )}
                onChange={(event, newValue) => {
                  handleCustomerChange(event, newValue);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="drEventDate"
                label={t("disasterRecovery.eventDetail.drEventDateLabel")}
                locale={localeByBranch || DEFAULT_LOCALE}
                key={timeZoneOffsetChanged}
                variant="input"
                value={EventDetailForm?.values?.drEventDate}
                disabled={
                  !(
                    (EventDetailForm?.values?.drEventStatusId ===
                      eventStatus.newEvent ||
                      EventDetailForm?.values?.drEventStatusId ===
                        eventStatus.logged) &&
                    isValid257Role
                  )
                }
                minDate={currentDate}
                maxDate={currentDate.add(6, "month")}
                onChange={async (date) => {
                  // Update toDate without triggering touched event
                  const toDateWithoutTouched = (date) => {
                    EventDetailForm.setFieldValue("drEventDate", date);
                    handleDrEventDate(date);
                  };
                  toDateWithoutTouched(date);
                }}
                error={!!EventDetailForm.errors.drEventDate}
                helperText={EventDetailForm.errors.drEventDate}
              />
            </Grid>

            {/* ---------- Event Coordinator selection filter ----------   */}
            <Grid item xs={3} sm={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="event-coordinator-filter"
                name={t("disasterRecovery.eventDetail.eventCoordinatorLabel")}
                options={eventCoordinator}
                size="medium"
                value={EventDetailForm?.values?.eventCoordinator}
                disabled={
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.complete ||
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.cancel ||
                  !isValid257Role ||
                  !validateUserHasProgramId(ROLE_ACCESS_CODES.code_260)
                }
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                fullWidth
                onChange={(event, newValue) => {
                  handleEventCoordinator(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      "disasterRecovery.eventDetail.eventCoordinatorLabelRequired"
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Typography style={disasterRecoveryStyles.commonStyles.subHeading}>
            {t("disasterRecovery.eventDetail.eventTypeLabel")}
          </Typography>

          <Stack gap={spacing.gap}>
            {/* Radio Selection */}
            <Box>
              <FormControl>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={EventDetailForm?.values?.eventType}
                  onChange={handleEventTypeChange}
                >
                  {eventTypes.map((item) => (
                    <FormControlLabel
                      value={item.value}
                      key={item.value}
                      control={
                        <Radio
                          disabled={
                            !(
                              (EventDetailForm?.values?.drEventStatusId ===
                                eventStatus.newEvent ||
                                EventDetailForm?.values?.drEventStatusId ===
                                  eventStatus.logged) &&
                              isValid257Role
                            )
                          }
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Stack>

          <Divider />

          <Typography variant="h5">
            {t("disasterRecovery.eventDetail.customerEventCoordinationLabel")}
          </Typography>

          <Grid container gap={spacing.gap} wrap="nowrap">
            <Grid item xs={3} sm={3}>
              {/* ---------- Declared By Personnel selection filter ---------- */}
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="declared-by-personnel-filter"
                name={t(
                  "disasterRecovery.eventDetail.declaredByPersonnelLabel"
                )}
                options={personnelList}
                size="medium"
                value={EventDetailForm?.values?.declaredByPersonnel}
                disabled={
                  !(
                    (EventDetailForm?.values?.drEventStatusId ===
                      eventStatus.newEvent ||
                      EventDetailForm?.values?.drEventStatusId ===
                        eventStatus.logged) &&
                    isValid257Role
                  )
                }
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                fullWidth
                onFocus={() => {
                  if (!customerSelected) {
                    handleErrorMessage(errorMsgs.errorCode10094);
                  }
                }}
                onChange={(event, newValue) => {
                  if (newValue != null) {
                    if (
                      newValue === EventDetailForm?.values?.verifiedByPersonnel
                    ) {
                      showDeclaredByPersonnalError();
                    } else {
                      setPersonnelSelected(newValue);
                      setAuthRequestModalVisible(true);
                      setAuthRequestForDeclared(true);
                    }
                  } else {
                    EventDetailForm.handleChange({
                      target: {
                        name: "declaredByPersonnel",
                        value: { label: "", value: "" }
                      }
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t(
                      "disasterRecovery.eventDetail.declaredByPersonnelLabel"
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              {/* ---------- Verified By Personnel selection filter ---------- */}
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="verified-by-personnel-filter"
                name={t(
                  "disasterRecovery.eventDetail.verifiedByPersonnelLabel"
                )}
                options={personnelList}
                size="medium"
                value={EventDetailForm?.values?.verifiedByPersonnel}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                disabled={
                  !(
                    (EventDetailForm?.values?.drEventStatusId ===
                      eventStatus.newEvent ||
                      EventDetailForm?.values?.drEventStatusId ===
                        eventStatus.logged) &&
                    isValid257Role
                  )
                }
                fullWidth
                onFocus={() => {
                  if (!customerSelected) {
                    handleErrorMessage(errorMsgs.errorCode10094);
                  }
                }}
                onChange={(event, newValue) => {
                  if (newValue != null) {
                    if (
                      newValue === EventDetailForm?.values?.declaredByPersonnel
                    ) {
                      showDeclaredByPersonnalError();
                    } else {
                      setAuthRequestForVerified(true);
                      setPersonnelSelected(newValue);
                      setAuthRequestModalVisible(true);
                    }
                  } else {
                    EventDetailForm.handleChange({
                      target: {
                        name: "verifiedByPersonnel",
                        value: { label: "", value: "" }
                      }
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t(
                      "disasterRecovery.eventDetail.verifiedByPersonnelLabel"
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} sm={2}>
              {/* ---------- Hot Site Event ID filter ----------   */}
              <TextField
                label={t("disasterRecovery.eventDetail.hotSiteEventIdLabel")}
                id="hotSiteEventId"
                size="medium"
                type="text"
                value={EventDetailForm?.values?.hotSiteEventID}
                disabled={
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.complete ||
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.cancel ||
                  !isValid257Role
                }
                onChange={(e) => {
                  handleHotSiteEventID(e);
                }}
                shrink={EventDetailForm?.values?.hotSiteEventID}
                notched={EventDetailForm?.values?.hotSiteEventID}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              {/* ---------- Customer Contact selection filter ---------- */}
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="customer-contact-filter"
                name={t("disasterRecovery.eventDetail.customerContactLabel")}
                options={personnelList}
                size="medium"
                value={EventDetailForm?.values?.customerContact}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                disabled={
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.complete ||
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.cancel ||
                  !isValid257Role
                }
                fullWidth
                onFocus={() => {
                  if (!customerSelected) {
                    handleErrorMessage(errorMsgs.errorCode10094);
                  }
                }}
                onChange={(event, newValue) => {
                  handleCustomerContact(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      "disasterRecovery.eventDetail.customerContactLabel"
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container gap={spacing.gap} wrap="nowrap">
            <Grid item xs={3} sm={3}>
              {/* ---------- Country/Phone Code selection filter ---------- */}
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="country-code-filter"
                name={t("disasterRecovery.eventDetail.countryCodeLabel")}
                options={allCountries}
                size="medium"
                value={EventDetailForm?.values?.countryCode}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                disabled={
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.complete ||
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.cancel ||
                  !isValid257Role
                }
                fullWidth
                onChange={(event, newValue) => {
                  handleCountryCode(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("disasterRecovery.eventDetail.countryCodeLabel")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} sm={3} display={"flex"} gap={2}>
              <TextField
                id="primaryPhone"
                name="primaryPhone"
                label={t("disasterRecovery.eventDetail.primaryPhoneLabel")}
                value={EventDetailForm?.values?.primaryPhone}
                onChange={(e) => handlePrimaryPhoneField(e)}
                disabled={
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.complete ||
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.cancel ||
                  !isValid257Role
                }
                inputProps={phoneInputProps}
                shrink={EventDetailForm?.values?.primaryPhone}
                notched={EventDetailForm?.values?.primaryPhone}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        EventDetailForm?.values?.primaryCellular === VALUE_Y
                      }
                      onChange={(e) => handlePrimaryCellular(e)}
                      disabled={
                        EventDetailForm?.values?.drEventStatusId ===
                          eventStatus.complete ||
                        EventDetailForm?.values?.drEventStatusId ===
                          eventStatus.cancel ||
                        !isValid257Role
                      }
                    />
                  }
                  label={t("disasterRecovery.eventDetail.cellularLabel")}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={3} sm={3} display={"flex"} gap={2}>
              <TextField
                id="secondaryPhone"
                name="secondaryPhone"
                label={t("disasterRecovery.eventDetail.secondaryPhoneLabel")}
                value={EventDetailForm?.values?.secondaryPhone}
                onChange={(e) => handleSecondaryPhone(e)}
                disabled={
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.complete ||
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.cancel ||
                  !isValid257Role
                }
                inputProps={phoneInputProps}
                shrink={EventDetailForm?.values?.secondaryPhone}
                notched={EventDetailForm?.values?.secondaryPhone}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        EventDetailForm?.values?.secondaryCellular === VALUE_Y
                      }
                      onChange={(e) => handleSecondaryCellular(e)}
                      disabled={
                        EventDetailForm?.values?.drEventStatusId ===
                          eventStatus.complete ||
                        EventDetailForm?.values?.drEventStatusId ===
                          eventStatus.cancel ||
                        !isValid257Role
                      }
                    />
                  }
                  label={t("disasterRecovery.eventDetail.cellularLabel")}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Divider />
        </Stack>
      </form>
      {/* Auth request modal */}
      {authRequestModalVisible && (
        <AuthRequestModal
          show={authRequestModalVisible}
          customer={EventDetailForm?.values?.customer}
          cancelModalWarning={authModalSuccess}
          setCancelModalWarning={setAuthModalSuccess}
          authorizedBy={personnelSelected.value}
          personnel={personnelSelected.label}
          timestamp={EventDetailForm.values?.timestamp}
          onClose={setAuthRequestModalVisible}
          onSubmit={handleAuthRequestModalSuccess}
        />
      )}
    </>
  );
};

export default EventDetailsTable;
