import { Button } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const InitiateBillCycleModal = ({
  initiateModalShow,
  yesClickInitiateBillCycle,
  noClickInitiateBillCycle
}) => {
  return (
    <CommonModal
      open={initiateModalShow}
      body={`${t("billingStatus.initiateBillingCycle")}`}
      buttons={
        <>
          <Button
            onClick={() => yesClickInitiateBillCycle()}
            variant="outlined"
          >
            {t("common.yes")}
          </Button>
          <Button
            onClick={() => noClickInitiateBillCycle()}
            variant="contained"
          >
            {t("common.no")}
          </Button>
        </>
      }
    />
  );
};

export default InitiateBillCycleModal;
