import { createSlice } from "@reduxjs/toolkit";
import { ALL_SELECTION } from "utils/constants";
import { notCompletedSelection } from "utils/constants/open-media-processing/OpenMediaAuditSearchConstants";

const initialSearchValues = {
  auditId: "",
  media_number: "",
  reqBy: ALL_SELECTION,
  auditType: ALL_SELECTION,
  auditStatus: notCompletedSelection,
  customer: null,
  auditDate: "",
  auditTo: "",
  dueDate: "",
  dueDateTo: "",
  exceptionsOnly: false,
  authCustomers: []
};
const initialAuthValues = {
  branch: "",
  customer: "",
  personnel: "",
  authNumber: "",
  challengeQuestion: "",
  challengeResponse: ""
};
const openMediaAuditSearchSlice = createSlice({
  name: "openMediaAuditSearch",
  initialState: {
    formValues: initialSearchValues,
    authFormValues: initialAuthValues,
    searchResults: [],
    isLoading: false,
    throwError: false,
    throwErrorMsg: "",
    isAuthenticated: false,
    throwWarning: false,
    throwWarningMsg: ""
  },
  reducers: {
    fetchDataRequest(state) {
      state.isLoading = true;
      state.throwError = null;
    },
    fetchDataSuccess(state, action) {
      state.isLoading = false;
      state.searchResults = action.payload;
    },
    fetchDataFailure(state, action) {
      state.isLoading = false;
      state.throwError = true;
      state.throwErrorMsg = action.payload;
    },

    updateFormValues(state, action) {
      state.formValues = { ...state.formValues, ...action.payload };
    },

    updateAuthFormValues(state, action) {
      state.authFormValues = { ...state.authFormValues, ...action.payload };
    },
    resetForm(state) {
      state.formValues = initialSearchValues;
      state.searchResults = [];
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = "";
    },
    resetAuthForm(state) {
      state.authFormValues = initialAuthValues;
      state.searchResults = [];
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = "";
    },
    setSearchResults(state, action) {
      state.searchResults = action.payload;
      state.throwError = false;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.throwError = true;
      state.throwErrorMsg = action.payload;
    },
    setWarning(state, action) {
      state.throwWarning = true;
      state.throwWarningMsg = action.payload;
    },
    clearError(state) {
      state.throwError = false;
      state.throwErrorMsg = "";
    },
    clearWarning(state, action) {
      state.throwWarning = false;
      state.throwWarningMsg = "";
    },
    updateAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },
    setAuthCustomers(state, action) {
      state.authCustomers = action.payload;
    }
  }
});

export const {
  updateFormValues,
  resetForm,
  setSearchResults,
  setLoading,
  setError,
  clearError,
  fetchDataRequest,
  fetchDataSuccess,
  fetchDataFailure,
  updateAuthFormValues,
  resetAuthForm,
  updateAuthentication,
  setWarning,
  clearWarning,
  setAuthCustomers
} = openMediaAuditSearchSlice.actions;

export default openMediaAuditSearchSlice.reducer;
