import { Grid } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";

const CustomerWithDiscrepancyDetectedMostFrequently = ({ tableData }) => {
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch:
          (!tableData || tableData.length === 0) && `${t("common.noDataFound")}`
      }
    }
  };
  const columns = [
    {
      name: "customer_number",
      label: t("reports.vaultStatusReport.customer")
    },
    {
      name: "discrepancy_days_count",
      label: t("reports.vaultStatusReport.daysWithDiscrepancies")
    },

    {
      name: "service_days_count",
      label: t("reports.vaultStatusReport.daysServiced")
    },

    {
      name: "discrepancy_frequency",
      label: t("reports.vaultStatusReport.frequency")
    },
    {
      name: "discrepancy_count",
      label: t("reports.vaultStatusReport.totalDiscrepancies")
    }
  ];
  return (
    <Grid item xs={12} lg={12} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={tableData ? [...tableData] : []}
        options={options}
      />
    </Grid>
  );
};

export default CustomerWithDiscrepancyDetectedMostFrequently;
