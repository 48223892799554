import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { errorMsgs, numberConstants } from "utils/constants";
import { customerSources } from "utils/constants/open-media-processing/CustomerFileSearchConstants";
import { CustomerFileStyles } from "./CustomerFileStyles";

const CustomerFileSearch = ({
  customers,
  customerFileSearchForm,
  isFindDisabled,
  setIsFindDisabled,
  handleClearEvent,
  errorMsg,
  setThrowError,
  yesterday,
  today,
  minDate,
  setSearchResults,
  setIsPrintDisabled,
  setPrevValues
}) => {
  const { t } = useTranslation();
  const { currentBranch, localeByBranch } = useSelector(selectAuth);

  const handleCustomerChange = (event, val) => {
    if (val === null) {
      customerFileSearchForm.setFieldValue("customerId", null);
    } else {
      let newValue = { label: val?.label, value: val?.value?.trim() };
      customerFileSearchForm.setFieldValue("customerId", newValue);
      setIsFindDisabled(false);
      setIsPrintDisabled(true);
      setSearchResults([]);
      setPrevValues(false);
    }
  };

  const handleInputChange = (name, value) => {
    setThrowError(false);
    if (name === "fromDate") {
      if (value > customerFileSearchForm?.values?.toDate) {
        errorMsg(
          errorMsgs.errorCode62546,
          t("CustomerFileSearch.toDate"),
          t("CustomerFileSearch.fromDate")
        );
        setThrowError(true);
        return;
      }
    }
    if (name === "toDate") {
      if (value < customerFileSearchForm?.values?.fromDate) {
        errorMsg(
          errorMsgs.errorCode62546,
          t("CustomerFileSearch.toDate"),
          t("CustomerFileSearch.fromDate")
        );
        setThrowError(true);
        return;
      }
    }
    customerFileSearchForm.setFieldValue(name, value);
    setIsFindDisabled(false);
    setIsPrintDisabled(true);
    setSearchResults([]);
    setPrevValues(false);
  };

  const customersFieldProps = {
    options: customers,
    value: customerFileSearchForm?.values?.customerId,
    disabled: null,
    required: true,
    handleOnChange: handleCustomerChange,
    sx: CustomerFileStyles.fieldStyle,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  return (
    <form
      onSubmit={customerFileSearchForm.handleSubmit}
      onReset={customerFileSearchForm.handleReset}
    >
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid item xs={3}>
              <CustomersField {...customersFieldProps} />
            </Grid>

            <Grid id="Date_To" item xs={3}>
              <DatePickerField
                id="fromDate"
                name="fromDate"
                label={t("CustomerFileSearch.from")}
                value={customerFileSearchForm?.values.fromDate || yesterday}
                minDate={minDate}
                maxDate={today}
                defaultValue={yesterday}
                locale={localeByBranch}
                onChange={(value) => {
                  handleInputChange("fromDate", value);
                }}
                sx={CustomerFileStyles.fieldStyle}
              />
            </Grid>

            <Grid id="Date_To" item xs={3}>
              <DatePickerField
                id="toDate"
                name="toDate"
                label={t("CustomerFileSearch.to")}
                value={customerFileSearchForm?.values.toDate || today}
                minDate={minDate}
                maxDate={today}
                locale={localeByBranch}
                defaultValue={today}
                onChange={(value) => {
                  handleInputChange("toDate", value);
                }}
                sx={CustomerFileStyles.fieldStyle}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                name="branch_name"
                label={t("CustomerFileSearch.branchName")}
                value={currentBranch?.name}
                onChange={null}
                sx={{
                  ...CustomerFileStyles.fieldStyle,
                  ...CustomerFileStyles.inputBorderHide
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="customer-file-search"
                disablePortal
                name="sourceCode"
                disabled={null}
                options={customerSources}
                size="medium"
                value={customerFileSearchForm?.values?.sourceCode}
                onChange={(event, newValue) => {
                  handleInputChange("sourceCode", newValue);
                  if (newValue?.value === numberConstants.one) {
                    customerFileSearchForm.setFieldValue(
                      "includeAllSectionsFlag",
                      false
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("CustomerFileSearch.source")}
                  />
                )}
                sx={CustomerFileStyles.fieldStyle}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4} xl={4}>
              <FormControlLabel
                id="null"
                control={
                  <Checkbox
                    disabled={
                      customerFileSearchForm?.values?.sourceCode?.value ===
                      numberConstants.one
                    }
                    name="includeAllSectionsFlag"
                    id="null"
                    size="small"
                    color="primary"
                    style={{ margin: "9px" }}
                    checked={
                      customerFileSearchForm?.values?.includeAllSectionsFlag
                    }
                    onChange={(event) => {
                      setIsFindDisabled(false);
                      setSearchResults([]);
                      setPrevValues(false);
                      if (event && event?.target) {
                        customerFileSearchForm.setFieldValue(
                          "includeAllSectionsFlag",
                          event?.target?.checked
                        );
                      }
                    }}
                  />
                }
                label={t("CustomerFileSearch.checkboxText")}
                sx={CustomerFileStyles.fieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid id="customer-clear-button-grid" item xs="auto">
              <Button
                id="customer-clear-button"
                variant="outlined"
                type="button"
                onClick={handleClearEvent}
              >
                {t("common.clear")}
              </Button>
            </Grid>
            <Grid id="customer-find-button-grid" item xs="auto">
              <Button
                id="customer-find-button"
                variant="contained"
                type="submit"
                disabled={isFindDisabled}
                onClick={null}
              >
                {t("common.find")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomerFileSearch;
