export const vendorMangementStyles = {
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  textStyle: {
    width: "100%",
    marginBottom: "20px"
  },
  fieldStyle: {
    marginTop: "10px"
  },
  inputBorderHide: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none"
      }
    }
  },
  textAreaStyle: {
    marginTop: "10px"
  },
  colorStyle: { color: "var(--color-im-red)" },
  actionColumnStyle: { width: "300px" },
  typographyStyles: { wordWrap: "break-word" },
  SelectedRowColor: "var(--color-im-light-blue-100)"
};
