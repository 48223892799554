import { CF_URLS } from "services/api/endpoints";
import { getResponseData, printReport } from "utils/helpers";

export const getCustomerDetails = async (customer_id, mainDistrictId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    customer_id: customer_id.value
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.reassaignTransport
      .checkcustomeractivetransports,
    1
  );
  return res;
};

export const getAvailableTransports = async (
  oldContainerNumber,
  mainDistrictId,
  selectedCustomer
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    customer_id: selectedCustomer.value,
    old_container_number: oldContainerNumber,
    old_container_id: ""
  };
  const res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.reassaignTransport.getalltransportlist,
    3
  );
  return res;
};

export const getReassignTransports = async (
  mainDistrictId,
  oldContainerId,
  newContainerId
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    old_container_id: oldContainerId,
    new_container_id: newContainerId
  };
  const res = await getResponseData(
    reqBody,
    CF_URLS.containerProcessing.reassaignTransport.reassigntransportcontents,
    2
  );
  return res;
};

export const transportRepots = async (
  mainDistrictId,
  container_id,
  request_id_list,
  user_name,
  report_format
) => {
  let reqBody = {
    main_district_id: mainDistrictId,
    container_id: container_id,
    request_id_list: request_id_list,
    user_name: user_name,
    report_format: report_format
  };
  let res = await printReport(
    reqBody,
    `${CF_URLS.printReports.containerProcessing.reassignTransportReport}`
  );
  return res;
};
