import dayjs from "dayjs";
import { t } from "i18next";
import { ALL_SELECTION, VALUE_EMPTY_STRING } from "..";

export const reportType = [
  t("reports.vaultStatusReport.individualCustomerVSR"),
  t("reports.vaultStatusReport.customersWithMostDiscrepancies"),
  t(
    "reports.vaultStatusReport.customersWithMostDuplicateMediaNumberAtDistribution"
  ),
  t("reports.vaultStatusReport.customersWithMostInboundMediaProcessed"),
  t("reports.vaultStatusReport.customersWithMostOutboundMediaProcessed"),
  t("reports.vaultStatusReport.customersWithDiscrepancyDetectedMostFrequently")
];

export const discrepancyMediaList = [
  {
    label: t("common.all"),
    value: t("common.all")
  },
  {
    label: t("discrepancyManagement.container"),
    value: t("discrepancyManagement.container")
  },
  {
    label: t("discrepancyManagement.openMedia"),
    value: t("discrepancyManagement.openMedia")
  }
];

export const discrepancyDetectedMostFrequently = t(
  "reports.vaultStatusReport.customersWithDiscrepancyDetectedMostFrequently"
);

export const reg = /[0-9]/;
export const severityError = "error";
export const severityWarning = "warning";
export const formDataValue = {
  reportType: t("reports.vaultStatusReport.individualCustomerVSR"),
  customer: VALUE_EMPTY_STRING,
  fromDate: dayjs(),
  toDate: dayjs(),
  media: ALL_SELECTION?.value,
  detectedIn: [ALL_SELECTION?.value],
  discrepancyType: ALL_SELECTION?.value,
  isCheck: true,
  number: 10
};

export const detectedInType = Object.freeze({
  Operations_DetectedInForOpenMedia_FileSectionType_1: t(
    "discrepancyManagement.picking"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_2: t(
    "discrepancyManagement.distribution"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_3: t(
    "discrepancyManagement.redistribution"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_4: t(
    "discrepancyManagement.inventory"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_5: t(
    "discrepancyManagement.dr"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_6: t(
    "discrepancyManagement.update"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_8: t(
    "discrepancyManagement.junk"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_9: t(
    "discrepancyManagement.pickingIntervault"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_10: t(
    "discrepancyManagement.vaultStatus"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_12: t(
    "discrepancyManagement.schedulerContainer"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_13: t(
    "discrepancyManagement.schedulerOM"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_14: t(
    "discrepancyManagement.pickingDestruction"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_16: t(
    "discrepancyManagement.audit"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_17: t(
    "discrepancyManagement.interactiveSiteScan"
  ),
  Operations_DetectedInForOpenMedia_FileSectionType_18: t(
    "distributionSkipBatch"
  ),
  Operations_DetectedInForContainers_SystemFunction_18: t(
    "discrepancyManagement.addOnRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_29: t(
    "discrepancyManagement.assignMediaToRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_47: t(
    "discrepancyManagement.assignOpenMediaToTransport"
  ),
  Operations_DetectedInForContainers_SystemFunction_17: t(
    "discrepancyManagement.assignRequestToRun"
  ),
  Operations_DetectedInForContainers_SystemFunction_20: t(
    "discrepancyManagement.atIMOSDP"
  ),
  Operations_DetectedInForContainers_SystemFunction_16: t(
    "discrepancyManagement.audit"
  ),
  Operations_DetectedInForContainers_SystemFunction_74: t(
    "discrepancyManagement.autoCompleteRun"
  ),
  Operations_DetectedInForContainers_SystemFunction_56: t(
    "discrepancyManagement.branchOutageRSGP"
  ),
  Operations_DetectedInForContainers_SystemFunction_60: t(
    "discrepancyManagement.branchOutagePickListCompletion"
  ),
  Operations_DetectedInForContainers_SystemFunction_49: t(
    "discrepancyManagement.cancelOpenMediaBatch"
  ),
  Operations_DetectedInForContainers_SystemFunction_19: t(
    "discrepancyManagement.cancelRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_21: t(
    "discrepancyManagement.changeContainerReturnDateStatus"
  ),
  Operations_DetectedInForContainers_SystemFunction_52: t(
    "discrepancyManagement.clientConnectivityParser"
  ),
  Operations_DetectedInForContainers_SystemFunction_53: t(
    "discrepancyManagement.clientConnectivityParserUpdate"
  ),
  Operations_DetectedInForContainers_SystemFunction_76: t(
    "discrepancyManagement.createOMDistributionListCustomerSiteScan"
  ),
  Operations_DetectedInForContainers_SystemFunction_51: t(
    "discrepancyManagement.createOpenMediaDistributionList"
  ),
  Operations_DetectedInForContainers_SystemFunction_50: t(
    "discrepancyManagement.createOpenMediaPickList"
  ),
  Operations_DetectedInForContainers_SystemFunction_72: t(
    "discrepancyManagement.destructionPickingLoad"
  ),
  Operations_DetectedInForContainers_SystemFunction_57: t(
    "discrepancyManagement.disasterRecoveryRequestGeneration"
  ),
  Operations_DetectedInForContainers_SystemFunction_28: t(
    "discrepancyManagement.discrepancyResolution"
  ),
  Operations_DetectedInForContainers_SystemFunction_10: t(
    "discrepancyManagement.downloadForRunPrep"
  ),
  Operations_DetectedInForContainers_SystemFunction_6: t(
    "discrepancyManagement.downloadForStaging"
  ),
  Operations_DetectedInForContainers_SystemFunction_64: t(
    "discrepancyManagement.drEventOutboundComplete"
  ),
  Operations_DetectedInForContainers_SystemFunction_63: t(
    "discrepancyManagement.drEventReconciliation"
  ),
  Operations_DetectedInForContainers_SystemFunction_31: t(
    "discrepancyManagement.finalDownloadForRunPrep"
  ),
  Operations_DetectedInForContainers_SystemFunction_12: t(
    "discrepancyManagement.findArrivalContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_14: t(
    "discrepancyManagement.findDistributionContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_2: t(
    "discrepancyManagement.findPicklistContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_8: t(
    "discrepancyManagement.findRunPrepContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_5: t(
    "discrepancyManagement.findStagingContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_9: t(
    "discrepancyManagement.generateRunList"
  ),
  Operations_DetectedInForContainers_SystemFunction_73: t(
    "discrepancyManagement.inventoryAudit"
  ),
  Operations_DetectedInForContainers_SystemFunction_1: t(
    "discrepancyManagement.issueContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_71: t(
    "discrepancyManagement.mediaDestructionRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_69: t(
    "discrepancyManagement.mediaTypeChangeLabelReprint"
  ),
  Operations_DetectedInForContainers_SystemFunction_44: t(
    "discrepancyManagement.openMediaDiscrepancyResolution"
  ),
  Operations_DetectedInForContainers_SystemFunction_41: t(
    "discrepancyManagement.openMediaDistributionUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_43: t(
    "discrepancyManagement.openMediaDistributionVerify"
  ),
  Operations_DetectedInForContainers_SystemFunction_61: t(
    "discrepancyManagement.openMediaDRDistributionUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_62: t(
    "discrepancyManagement.openMediaDRDistributionUploadExpired"
  ),
  Operations_DetectedInForContainers_SystemFunction_38: t(
    "discrepancyManagement.openMediaIntervaultDownload"
  ),
  Operations_DetectedInForContainers_SystemFunction_40: t(
    "discrepancyManagement.openMediaIntervaultUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_45: t(
    "discrepancyManagement.openMediaInventoryCompare"
  ),
  Operations_DetectedInForContainers_SystemFunction_37: t(
    "discrepancyManagement.openMediaPickingDownload"
  ),
  Operations_DetectedInForContainers_SystemFunction_39: t(
    "discrepancyManagement.openMediaPickingUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_48: t(
    "discrepancyManagement.openMediaResearch"
  ),
  Operations_DetectedInForContainers_SystemFunction_58: t(
    "discrepancyManagement.openMediaScannedPickListUpload"
  ),
  Operations_DetectedInForContainers_SystemFunction_15: t(
    "discrepancyManagement.printDistributionList"
  ),
  Operations_DetectedInForContainers_SystemFunction_42: t(
    "discrepancyManagement.printOpenMediaDistributionReport"
  ),
  Operations_DetectedInForContainers_SystemFunction_36: t(
    "discrepancyManagement.printOpenMediaIntervaultReport"
  ),
  Operations_DetectedInForContainers_SystemFunction_35: t(
    "discrepancyManagement.printOpenMediaPickReport"
  ),
  Operations_DetectedInForContainers_SystemFunction_3: t(
    "discrepancyManagement.printPicklist"
  ),
  Operations_DetectedInForContainers_SystemFunction_46: t(
    "discrepancyManagement.publishSkipPublishVaultStatus"
  ),
  Operations_DetectedInForContainers_SystemFunction_34: t(
    "discrepancyManagement.reassignTransportContentsAssign"
  ),
  Operations_DetectedInForContainers_SystemFunction_59: t(
    "discrepancyManagement.reassignTransportContentsRemove"
  ),
  Operations_DetectedInForContainers_SystemFunction_33: t(
    "discrepancyManagement.releaseMediaFromSlot"
  ),
  Operations_DetectedInForContainers_SystemFunction_32: t(
    "discrepancyManagement.removeMediaFromRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_30: t(
    "discrepancyManagement.removeMediaFromRun"
  ),
  Operations_DetectedInForContainers_SystemFunction_65: t(
    "discrepancyManagement.reprintContainerTransportLabel"
  ),
  Operations_DetectedInForContainers_SystemFunction_25: t(
    "discrepancyManagement.requestContainerDeletion"
  ),
  Operations_DetectedInForContainers_SystemFunction_23: t(
    "discrepancyManagement.requestMediaDelivery"
  ),
  Operations_DetectedInForContainers_SystemFunction_26: t(
    "discrepancyManagement.requestMediaDeliveryAtCustomer"
  ),
  Operations_DetectedInForContainers_SystemFunction_24: t(
    "discrepancyManagement.requestMediaPickup"
  ),
  Operations_DetectedInForContainers_SystemFunction_22: t(
    "discrepancyManagement.requestNewContainers"
  ),
  Operations_DetectedInForContainers_SystemFunction_27: t(
    "discrepancyManagement.rescheduleRequest"
  ),
  Operations_DetectedInForContainers_SystemFunction_55: t(
    "discrepancyManagement.runManagementMove"
  ),
  Operations_DetectedInForContainers_SystemFunction_54: t(
    "discrepancyManagement.runManagementRSGP"
  ),
  Operations_DetectedInForContainers_SystemFunction_75: t(
    "discrepancyManagement.uploadAssignTransport"
  ),
  Operations_DetectedInForContainers_SystemFunction_13: t(
    "discrepancyManagement.uploadForArrival"
  ),
  Operations_DetectedInForContainers_SystemFunction_67: t(
    "discrepancyManagement.uploadForDelivery"
  ),
  Operations_DetectedInForContainers_SystemFunction_66: t(
    "discrepancyManagement.uploadForDistribution"
  ),
  Operations_DetectedInForContainers_SystemFunction_68: t(
    "discrepancyManagement.uploadForPickup"
  ),
  Operations_DetectedInForContainers_SystemFunction_11: t(
    "discrepancyManagement.uploadForRunPrep"
  ),
  Operations_DetectedInForContainers_SystemFunction_7: t(
    "discrepancyManagement.uploadForStaging"
  ),
  Operations_DetectedInForContainers_SystemFunction_4: t(
    "discrepancyManagement.verifyPicklist"
  )
});

export const systemFunctionTitle =
  "Operations_DetectedInForContainers_SystemFunction_";

export const systemFunction = "SystemFunction";
export const omSystemFunctionId = "41";
export const omExchangeStatusIdList = "10";
export const ccSystemFunctionId = "13";
export const ccExchangeStatusId = "12";
export const ccSystemFunctionId_11 = "11";
export const ccExchangeStatusId_9 = "9";
export const omExchangeStatusIdList_7080 = "70,80";
export const openMedia = "Open Media";
export const container = "Container";
export const number_50 = "50";
