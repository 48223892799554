import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { t } from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectAuth } from "store/slices";
import { DEFAULT_LOCALE } from "utils/constants";
import {
  distributionTabId,
  inventoryTabId,
  pickingTabId,
  tabValues,
  verifyTabId,
  workManagementRoleAccess
} from "utils/constants/open-media-processing/WorkManagement";
import Inventory from "./Inventory/Inventory";
import { WorkManagementStyles } from "./WorkManagementStyles";
import Distribution from "./distribution-module/Distribution";
import Picking from "./picking-module/Picking";
import VerifyTabLayout from "./verify-tab/VerifyTabLayout";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const WorkManagementTab = ({ setTabName }) => {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [localeByBranch, setLocaleByBranch] = useState(DEFAULT_LOCALE);
  // Below states used to prevent tab change from Inventory in case of on going compare process
  const [canChangeTab, setCanChangeTab] = React.useState(true);
  const [changeTabTriggered, setChangeTabTriggered] = React.useState(undefined);

  const { roleAccess } = useSelector(selectAuth);

  const getHasAccess = (code) => roleAccess.includes(code);

  let { code176, code197, code174, code169 } = workManagementRoleAccess;

  const workManagementTabs = [
    {
      label: t("workManagement.pickingTab"),
      value: tabValues.picking,
      hasAccess: getHasAccess(code169)
    },
    {
      label: t("workManagement.distributionTab"),
      value: tabValues.distribution,
      hasAccess: getHasAccess(code174)
    },
    {
      label: t("workManagement.verifyTab"),
      value: tabValues.verify,
      hasAccess: getHasAccess(code197)
    },
    {
      label: t("workManagement.inventoryTab"),
      value: tabValues.inventory,
      hasAccess: getHasAccess(code176)
    }
  ];

  const handleChange = (event, newValue, allowChangeTab) => {
    if (canChangeTab || allowChangeTab) {
      if (newValue === pickingTabId) {
        setTabName(t("workManagement.pickingTab"));
      } else if (newValue === distributionTabId) {
        setTabName(t("workManagement.distributionTab"));
      } else if (newValue === verifyTabId) {
        setTabName(t("workManagement.verifyTab"));
      } else if (newValue === inventoryTabId) {
        setTabName(t("workManagement.inventoryTab"));
      }
      setValue(newValue);
      setChangeTabTriggered(undefined);
      setCanChangeTab(true);
    } else {
      setChangeTabTriggered(newValue);
    }
  };

  const updateCanChangeTab = (status) => {
    setCanChangeTab(status);
  };

  useEffect(() => {
    switch (location.state?.tab) {
      case pickingTabId:
        handleChange(null, pickingTabId, true);
        break;
      case distributionTabId:
        handleChange(null, distributionTabId, true);
        break;
      case verifyTabId:
        handleChange(null, verifyTabId, true);
        break;
      default:
        // default
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <Box sx={WorkManagementStyles.tabBox}>
      <Box sx={WorkManagementStyles.tabBox1}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {workManagementTabs.map((obj, index) => {
            return (
              <Tab
                label={obj.label}
                {...a11yProps(index)}
                disabled={!obj.hasAccess}
                key={obj.label}
              />
            );
          })}
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={pickingTabId}>
        <Picking
          localeByBranch={localeByBranch}
          setLocaleByBranch={setLocaleByBranch}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={distributionTabId}>
        <Distribution
          localeByBranch={localeByBranch}
          setLocaleByBranch={setLocaleByBranch}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={verifyTabId}>
        <VerifyTabLayout localeByBranch={localeByBranch} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={inventoryTabId}>
        <Inventory
          updateCanChangeTab={updateCanChangeTab}
          changeTabTriggered={changeTabTriggered}
          handleTabChange={handleChange}
          setChangeTabTriggered={setChangeTabTriggered}
          localeByBranch={localeByBranch}
        />
      </CustomTabPanel>
    </Box>
  );
};
export default WorkManagementTab;
