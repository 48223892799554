import i18n from "i18n";
import * as yup from "yup";

export const searchConfigurationSchema = yup.object().shape({
  pricingContract: yup
    .mixed()
    .transform((originalValue) => {
      if (originalValue === null) {
        return { label: "", value: "" };
      }
      return originalValue;
    })
    .test(
      "pricing-contract",
      i18n.t("capacityBillingConfigurationSearch.pricingContractRequired"),
      function (value) {
        return value && value.value !== "";
      }
    ),
  startingGeneration: yup
    .mixed()
    .transform((originalValue) => {
      if (originalValue === null) {
        return { label: "", value: "" };
      }
      return originalValue;
    })
    .test(
      "starting-Generation",
      i18n.t("capacityBillingConfigurationSearch.startingGenerationError"),
      function (value) {
        return value && value.value !== "";
      }
    )
});
