import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { rpType } from "utils/constants/container-processing/RunPrepConstants";

const RunReportsModal = ({
  runReportModal,
  setRunReportModal,
  selectedRun,
  reportType,
  setReportType,
  runReportPrintProcess
}) => {
  return (
    <>
      <CommonModal
        open={runReportModal}
        title={t("containerProcessing.runprep.runReports")}
        body={
          <>
            <Grid container sx={ContainerProcessingStyles.modalMainGrid}>
              <Stack spacing={2}>
                <Typography variant="body2">
                  {`${t("containerProcessing.runprep.reportsFor")}${
                    selectedRun?.run_name
                  }`}
                </Typography>

                <Box pl={2}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={reportType}
                      onChange={(e) => {
                        setReportType(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        id="radio-form-control-1"
                        value={rpType.runReport}
                        control={<Radio />}
                        label={t("containerProcessing.runprep.runReports")}
                      />
                      <FormControlLabel
                        id="radio-form-control-2"
                        value={rpType.withDirections}
                        control={<Radio />}
                        label={t(
                          "containerProcessing.runprep.runReportsDirections"
                        )}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Stack>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button onClick={() => setRunReportModal(false)} variant="outlined">
              {t("common.cancel")}
            </Button>
            <Button onClick={runReportPrintProcess} variant="contained">
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default RunReportsModal;
