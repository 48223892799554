import { Grid } from "@mui/material";
import { t } from "i18next";
import ToDoItem from "./ToDoItem";

const Customers = ({
  requiringServiceInformation,
  withServiceInformation,
  withSchedulesRequiringServiceWindows,
  withSchedulesRequiringRoutes,
  requiringPricingContracts,
  withCatalogItemsmissing,
  withExpiringSchedules,
  requiringAuthorizationPersonnel,
  viewCustomerMoves,
  onCardClick
}) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.requiringServiceInformation")}
          number={requiringServiceInformation}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.withServiceInformation")}
          number={withServiceInformation}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.withSchedulesRequiringServiceWindows")}
          number={withSchedulesRequiringServiceWindows}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.withSchedulesRequiringRoutes")}
          number={withSchedulesRequiringRoutes}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.viewCustomerMoves")}
          number={viewCustomerMoves}
          onCardClick={onCardClick}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.requiringPricingContracts")}
          number={requiringPricingContracts}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.withCatalogItemsMissing")}
          number={withCatalogItemsmissing}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.withExpiringSchedules")}
          number={withExpiringSchedules}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.requiringAuthorizationPersonnel")}
          number={requiringAuthorizationPersonnel}
          onCardClick={onCardClick}
        />
      </Grid>
    </>
  );
};

export default Customers;
