/* 
Footer Styles 
*/
export const Styles = {
  Container: {
    position: "relative",
    pb: 3,
    bottom: 0,
    left: 0,
    right: 0,
    bgcolor: "#fff",
    zIndex: 1100,
    height: "min-content",
    flexGrow: "0",
    maxWidth: "none !important",
    paddingLeft: "70px !important",
    paddingRight: "70px !important",
    marginBottom: "20px !important",
    paddingBottom: "0 !important"
  },
  Layout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 2
  }
};
