import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid
} from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularLoaderFullPage, SelectField } from "components/core";
import ModalPopup from "components/core/modal-components/ModalPopup";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  errorMsgs
} from "utils/constants";
import {
  allValue,
  anchorTagName,
  createFileDateFormat,
  csvFileHeaderStarts,
  fileFormatOptions,
  fileTypes,
  maxRowNumbersCount,
  textFileHeaderStarts
} from "utils/constants/container-processing/Research";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData
} from "utils/helpers";
import { containerResearchStyles } from "./ResearchStyle";

const CreateFileModal = (props) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "warning"
  });
  const [pageLoading, setPageLoading] = useState(false); // page loading state
  const [message25367, setMessage25367] = useState(""); // message 25367 value
  const [selectedFileFormat, setSelectedFileFormat] = useState(
    fileFormatOptions[0].value
  ); // selected file format
  const [includeColumnHeaders, setIncludeColumnHeaders] = useState(false); // include column headers
  const [
    excludeTransitDeliveredDeletedCont,
    setExcludeTransitDeliveredDeletedCont
  ] = useState(true); // exclude transit,delivered and deleted containers
  const branchId = getAuthenticatedUserBranch(); // id of the selected branch
  const [showLongProcessMessageModal, setShowLongProcessMessageModal] =
    useState(false); // show/hide long process message modal

  // construct datetime for the file name
  const getBranchDateTime = async () => {
    dayjs.locale(props.branchLocale);
    return dayjs
      .utc()
      .add(props.timezoneOffset, "hour")
      .format(createFileDateFormat);
  };

  // file format onChange event handler
  const handleFileFormatOnChange = (event) => {
    const selection = event.target.value;
    if (selection === fileFormatOptions[1].value) {
      setIncludeColumnHeaders((prevState) => false);
    }
    setSelectedFileFormat((prevState) => selection);
  };

  // create container inverntory record and download file
  const createContainerInventory = async () => {
    try {
      let containerList = props.filters?.containerNumbers;
      if (props.containerNumbers.length > 0) {
        containerList = props.containerNumbers
          .map((cont) => cont.container_number)
          .toString()
          .replaceAll(",", "|");
      }

      let mediaIdentifiers = props.filters?.customerMediaIds;
      if (props.customerMediaIDs.length > 0) {
        mediaIdentifiers = props.customerMediaIDs
          .map((media) => media.customer_media)
          .toString()
          .replaceAll(",", "|");
      }

      const requestBody = JSON.stringify({
        main_district_id: branchId,
        customer_id: props.filters?.customerId,
        media_type_id:
          props.filters?.mediaType === allValue
            ? VALUE_EMPTY_STRING
            : props.filters?.mediaType,
        run_id: props.filters?.runCode,
        container_list: containerList,
        customer_media_descr_list: mediaIdentifiers,
        exclude_transports_flag: props.filters?.excludeTransports
          ? Y_CHECKER
          : N_CHECKER,
        status_id: String(props.filters?.exchangeStatus),
        file_format_type: String(selectedFileFormat),
        include_column_headings_flag: includeColumnHeaders
          ? Y_CHECKER
          : N_CHECKER,
        include_in_transit_not_at_im_flag: excludeTransitDeliveredDeletedCont
          ? N_CHECKER
          : Y_CHECKER,
        called_from_secure_sync_flag: N_CHECKER,
        include_cmi_column_flag: props.includeCMI ? Y_CHECKER : N_CHECKER
      });

      const response = await getResponseData(
        requestBody,
        CF_URLS.containerProcessing.createContainerInventory,
        3
      );

      const results = response.data[1];
      if (results.length === 0) {
        const message = await getMessageFromCode(errorMsgs.errorCode25283);
        setAlert((prevState) => ({
          message: message[0]?.descr,
          show: true,
          severity: "warning"
        }));
      } else {
        const branchDateTime = await getBranchDateTime();
        const customerNumber = props.filters?.customerNumber;
        const fileExtension = fileFormatOptions.find(
          (opt) => opt.value === selectedFileFormat
        )?.ext;
        const fileName = `${customerNumber?.trim()}-${branchDateTime}${fileExtension}`;

        // remove empty rows
        const filteredData = results.filter(
          (item) => item.line_out?.length > 0
        );
        // sort rows
        let sortedData = filteredData.sort((a, b) => {
          let itemA = extractThirdItem(a.line_out)?.toLowerCase();
          let itemB = extractThirdItem(b.line_out)?.toLowerCase();
          return itemA?.localeCompare(itemB);
        });
        // extract headers
        let headerIndex = sortedData?.findIndex(
          (item) =>
            item?.line_out?.startsWith(textFileHeaderStarts) ||
            item?.line_out?.startsWith(csvFileHeaderStarts)
        );

        if (headerIndex > 0 && sortedData.length > headerIndex) {
          let item = sortedData.splice(headerIndex, 1)[0];
          sortedData.unshift(item);
        }
        handleDownloadCSV(sortedData, fileName);

        const message = await getMessageFromCode(errorMsgs.errorCode26017);
        props.onSubmit(message[0]?.descr);
      }
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        severity: "error",
        message: findErrorMessage(ERROR_TYPES.ISSUE)
      }));
    } finally {
      setPageLoading((prevState) => false);
    }
  };

  // OK button onClick event handler
  const handleOkOnClick = async () => {
    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));

    if (props.numbOfRecords === maxRowNumbersCount) {
      setShowLongProcessMessageModal((prevState) => true);
      return;
    }

    setPageLoading((prevState) => true);
    await createContainerInventory();
  };

  const extractThirdItem = (lineOut) => {
    let items = lineOut.split('","');
    return items[2];
  };

  const handleDownloadCSV = (data, fileName) => {
    let content = "";
    let blob;

    content = data.map((item) => item.line_out).join("");

    if (selectedFileFormat === fileFormatOptions[1].value) {
      blob = new Blob([content], { type: fileTypes.csv });
    } else if (selectedFileFormat === fileFormatOptions[0].value) {
      blob = new Blob([content], { type: fileTypes.txt });
    }

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement(anchorTagName);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  // Long process message popup OK button onClick event handler
  const handleLongProcessOkOnClick = async () => {
    setShowLongProcessMessageModal((prevState) => false);
    setPageLoading((prevState) => true);
    await createContainerInventory();
  };

  // init page
  useEffect(() => {
    const initPage = async () => {
      setPageLoading((prevState) => true);
      const messageValue = await getMessageFromCode(errorMsgs.errorCode25367);
      setMessage25367((prevState) => messageValue[0]?.descr);
      setPageLoading((prevState) => false);
    };

    initPage();
  }, []);

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="reprint-labels-full-page-loader"
        loading={pageLoading}
      />

      <CommonModal
        open={props.open}
        title={t("containerResearch.createFile")}
        body={
          <Box sx={containerResearchStyles.modalStyles.containerWidth}>
            {/* Alert area */}
            {alert.show && (
              <Alert
                id="create-file-modal-alert"
                severity={alert.severity}
                sx={{ marginBottom: spacing.verticalMargin16 }}
                onClose={() =>
                  setAlert((prevState) => ({
                    show: false,
                    message: ""
                  }))
                }
              >
                {alert.message}
              </Alert>
            )}

            <Grid
              id="create-file-modal-grid1"
              container
              spacing={spacing.verticalMargin16}
            >
              <Grid
                id="create-file-modal-grid2"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* File Format */}
                <Grid id="create-file-modal-grid3" item md={6}>
                  <SelectField
                    id="create-file-modal-printer"
                    name="fileFormat"
                    label={t("containerResearch.fileFormat")}
                    options={fileFormatOptions}
                    value={selectedFileFormat}
                    onChange={handleFileFormatOnChange}
                  />
                </Grid>

                {/* Include Column Headers */}
                <Grid id="create-file-modal-grid6" item md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="create-file-modal-include-column-headers"
                        color="primary"
                        name="includeColumnHeaders"
                        disabled={
                          selectedFileFormat !== VALUE_EMPTY_STRING &&
                          selectedFileFormat !== fileFormatOptions[0].value
                        }
                        checked={includeColumnHeaders}
                        onChange={(_, checked) =>
                          setIncludeColumnHeaders((prevState) => checked)
                        }
                      />
                    }
                    label={t("containerResearch.includeColumnHeaders")}
                  />
                </Grid>
              </Grid>

              <Grid id="create-file-modal-grid5" item md={12}>
                {/* Exclude In Transit, Delivered and Deleted Containers */}
                <Grid id="create-file-modal-grid6" item md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="create-file-modal-exclude-intransit"
                        color="primary"
                        name="excludeIntransit"
                        checked={excludeTransitDeliveredDeletedCont}
                        onChange={(_, checked) =>
                          setExcludeTransitDeliveredDeletedCont(
                            (prevState) => checked
                          )
                        }
                      />
                    }
                    label={t(
                      "containerResearch.excludeIntransitDeliveredAndDeletedContainers"
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
        buttons={
          <>
            <Button
              id="create-file-modal-cancel-btn"
              variant="outlined"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="reset"
              onClick={() => props.onClose()}
            >
              {t("common.cancel")}
            </Button>
            <Button
              id="create-file-modal-print-btn"
              variant="contained"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="submit"
              onClick={handleOkOnClick}
            >
              {t("common.ok")}
            </Button>
          </>
        }
      />

      {/* Long process message popup */}
      <ModalPopup
        modalPopupOpen={showLongProcessMessageModal}
        title={t("common.confirm")}
        alertMessage={message25367}
        modalButton={
          <>
            <Box>
              <Button
                id="edit-container-research-modal-ok-btn"
                variant="outlined"
                sx={containerResearchStyles.modalStyles.buttonStyle}
                type="submit"
                onClick={handleLongProcessOkOnClick}
              >
                {t("common.ok")}
              </Button>
            </Box>
            <Box>
              <Button
                id="edit-container-research-modal-cancel-btn"
                variant="contained"
                sx={containerResearchStyles.modalStyles.buttonStyle}
                type="reset"
                onClick={() =>
                  setShowLongProcessMessageModal((prevState) => false)
                }
              >
                {t("common.cancel")}
              </Button>
            </Box>
          </>
        }
      />
    </>
  );
};

CreateFileModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  branchLocale: PropTypes.string.isRequired, // locale of the selected branch
  includeCMI: PropTypes.bool.isRequired, // include Customer Media Identifier in file
  filters: PropTypes.object.isRequired, // filter properties
  containerNumbers: PropTypes.array, // container number list
  customerMediaIds: PropTypes.array, // customer media identifier list
  filterWithStatus: PropTypes.bool.isRequired, // filtered with exchange status or not
  timezoneOffset: PropTypes.number.isRequired, // local timezone offset value
  numbOfRecords: PropTypes.number.isRequired, // number of records in the search
  onSubmit: PropTypes.func, // handle modal submit event
  onClose: PropTypes.func // handle modal close event
};

export default CreateFileModal;
