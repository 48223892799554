import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Collapse,
  Grid,
  IconButton,
  Stack,
  TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import ErrorIcon from "assets/images/warning-2.svg";
import { SelectField } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { errorLogStyles } from "./BillingErrorLogStyle";

const BillingErrorModal = (props) => {
  const {
    isUpdateModalOpen,
    errorOptions,
    onChange,
    value,
    handleUpdateClick,
    handleCancelEdit,
    selectedRowData,
    throwError,
    throwErrorMessage,
    setThrowError
  } = props;

  const [isUpdateRequired, setIsUpdateRequired] = useState(false);

  useEffect(() => {
    if (value && value !== selectedRowData.billing_error_action_id) {
      setIsUpdateRequired(true);
    } else {
      setIsUpdateRequired(false);
    }
    //eslint-disable-next-line
  }, [value]);

  return (
    <>
      <CommonModal
        open={isUpdateModalOpen}
        title={t("billingErrorLog.updateErrorLog")}
        body={
          <Stack direction="column">
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  sx={errorLogStyles.errorStyle}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Grid spacing={2}>
              <Grid item={4}>
                <TextField
                  label={t("common.customer")}
                  disabled
                  value={selectedRowData?.number}
                  style={errorLogStyles.textStyle}
                />
              </Grid>
              <Grid item={4}>
                <SelectField
                  label={t("billingErrorLog.errorResol")}
                  value={value || selectedRowData?.billing_error_action_id}
                  options={errorOptions}
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </Stack>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={handleCancelEdit}>
              {t("common.cancelEdit")}
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateClick}
              disabled={!isUpdateRequired}
            >
              {t("common.update")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default BillingErrorModal;
