import CloseIcon from "@mui/icons-material/Close";
import { Alert, Collapse, IconButton, Stack } from "@mui/material";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { ErrorMessage, useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  BOOLEAN_STRING_VALUES,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  VALUE_N,
  Y_CHECKER,
  dateFormat,
  downloadReportOptions,
  errorMsgs,
  numberConstants
} from "utils/constants";
import { isManagerCode } from "utils/constants/open-media-processing/CustomerFileSearchConstants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getUserName,
  printReport
} from "utils/helpers";
import CustomerFileModal from "./CustomerFileModal";
import CustomerFileSearch from "./CustomerFileSearch";
import CustomerFileTable from "./CustomerFileTable";

const CustomerFileLayout = () => {
  const { currentBranch, auth } = useSelector(selectAuth);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  const location = useLocation();
  const prevValue = location?.state?.value;

  const today = dayjs();
  const minDate = today.subtract(6, "month");
  const yesterday = today.subtract(1, "day");

  const programList = auth?.user?.program_list;
  const isManager = programList.includes(isManagerCode);
  const [searchResults, setSearchResults] = useState([]);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [isFindDisabled, setIsFindDisabled] = useState(true);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [throwModalError, setThrowModalError] = useState(false);
  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedId, setSelctedId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [isPrintDisabled, setIsPrintDisabled] = useState(true);
  const [viewText, setViewText] = useState("");
  const [activateText, setActivateText] = useState("");
  const [isReactivateVisible, setIsReactivateVisible] = useState(false);
  const [viewPath, setViewPath] = useState("");
  const [isUpdateDisable, setUpdateDisable] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const selectedValue = downloadReportOptions[0];
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [prevValues, setPrevValues] = useState(false);

  const initialSearchValues = {
    sourceCode: t("common.all"),
    customerId: "",
    fromDate: yesterday,
    toDate: today,
    includeAllSectionsFlag: true,
    mainDistrictId: mainDistrictId,
    deactivatedFlag: "",
    customerFileSectionId: ""
  };

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    onFindClick();
  };

  const customerFileSearchForm = useFormik({
    initialValues: initialSearchValues,
    onSubmit: handleSubmit
  });

  const isViewDisabled = () => {
    const fileNameLength = selectedRow?.origin_file_name?.length;
    return !(fileNameLength > 0);
  };

  const isEditDisabled = (id) => {
    const statusId = selectedRow?.File_Section_Status_Id;
    const typeId = selectedRow?.file_section_type_id;
    const custId = selectedRow?.customer_file_section_id;

    if (
      isManager &&
      statusId === numberConstants.one &&
      typeId === numberConstants.two &&
      custId === id
    ) {
      return false;
    } else {
      return true;
    }
  };
  const canBeDeactivated = () => {
    const deleteAllowedFlag = selectedRow?.Delete_Allowed_Flag;
    const statusId = selectedRow?.File_Section_Status_Id;
    if (
      isManager &&
      statusId === numberConstants.one &&
      deleteAllowedFlag === Y_CHECKER
    ) {
      return false;
    } else {
      return true;
    }
  };
  const canBeReactivated = () => {
    const statusId = selectedRow?.File_Section_Status_Id;
    const containerMediaFlag = selectedRow?.Container_Media_Flag;
    const sectionTypeId = selectedRow?.file_section_type_id;
    if (
      containerMediaFlag === BOOLEAN_STRING_VALUES.FALSE &&
      statusId === numberConstants.five &&
      (sectionTypeId === numberConstants.one ||
        sectionTypeId === numberConstants.two ||
        sectionTypeId === numberConstants.four ||
        sectionTypeId === numberConstants.five)
    ) {
      setIsReactivateVisible(true);
      return false;
    } else {
      setIsReactivateVisible(false);
      return true;
    }
  };
  useEffect(() => {
    if (selectedIndex !== null && searchResults.length) {
      setSelectedRow(searchResults[selectedIndex]);
    }
    // eslint-disable-next-line
  }, [searchResults]);

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (throwError || isShowSuccessPopup) {
      window.scrollTo(0, 0);
    }
    //eslint-disable-next-line
  }, [throwError, isShowSuccessPopup]);

  useEffect(() => {
    if (prevValue) {
      setIsDataLoaded(true);
      const updateFormAndFetchData = async () => {
        const initialValues = {
          sourceCode: t("common.all"),
          customerId: prevValue
            ? {
                label:
                  `${prevValue.customerNumber}` +
                  "-" +
                  `${prevValue.customerName}`,
                value: prevValue.customerId
              }
            : VALUE_EMPTY_STRING,
          fromDate: dayjs(prevValue.detectedInDateTime),
          toDate: dayjs(prevValue.detectedInDateTime),
          includeAllSectionsFlag: true,
          mainDistrictId: mainDistrictId,
          deactivatedFlag: "",
          customerFileSectionId: prevValue
            ? prevValue?.customerFileSectionId
            : VALUE_EMPTY_STRING
        };

        setPrevValues(true);
        setIsDataLoaded(true);
        customerFileSearchForm.setValues(initialValues);
      };
      updateFormAndFetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue]);
  useEffect(() => {
    if (prevValues) {
      getSearchedData();
    } // eslint-disable-next-line
  }, [prevValues]);

  const getTheFirestoreErrorMessage = async (msgId) => {
    try {
      const msg = await getMessageFromCode(msgId);
      return { errorMsg: msg[0]?.descr };
    } catch (error) {
      setThrowErrorMessage(error || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const errorMsg = async (errorCode, fieldName, fieldName2) => {
    setIsLoading(true);
    const errorObj = await getTheFirestoreErrorMessage(errorCode);
    let errorString;
    if (fieldName && fieldName2) {
      errorString = errorObj?.errorMsg
        ?.replace("|", fieldName)
        ?.replace("|", fieldName2);
    } else if (fieldName) {
      errorString = errorObj?.errorMsg.replaceAll("|", fieldName);
    } else {
      errorString = errorObj?.errorMsg || findErrorMessage(ERROR_TYPES.ISSUE);
    }
    setThrowErrorMessage(errorString);
    setIsLoading(false);
    return errorString;
  };

  const getCustomers = async () => {
    setIsLoading(true);
    setThrowError(false);
    try {
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name}`,
          value: customer_id,
          number: number
        }));
        data.sort((a, b) => a.label.localeCompare(b.label));
        setCustomers((prev) => data);
        setIsLoading(false);
      } else {
        setCustomers([]);
        setThrowErrorMessage(ERROR_TYPES.FAILED);
        setThrowError(true);
      }
    } catch (e) {
      setCustomers([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getResponseData = async (reqBody, url, resultSetCount) => {
    try {
      const response = await callToCloudFunction(reqBody, url);
      const dataSets = await getDataFromFirestore(
        response,
        resultSetCount,
        response?.data?.docId
      );
      return dataSets;
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getSearchedData = async () => {
    setThrowError(false);
    setIsFindDisabled(true);
    const { fromDate, toDate, sourceCode, customerId, includeAllSectionsFlag } =
      customerFileSearchForm.values;
    const customerID = prevValues ? prevValue.customerId : customerId;
    const from = prevValues ? prevValue.detectedInDateTime : fromDate;
    const to = prevValues ? prevValue.detectedInDateTime : toDate;

    if (customerID === "" || !customerID) {
      setIsDataLoaded(false);
      errorMsg(errorMsgs?.errorCode20259, t("CustomerFileSearch.customer"));
      setThrowError(true);
      return;
    }
    if (from > to) {
      setIsDataLoaded(false);
      errorMsg(
        errorMsgs.errorCode62546,
        t("CustomerFileSearch.toDate"),
        t("CustomerFileSearch.fromDate")
      );
      setThrowError(true);
      return;
    }
    try {
      setIsLoading(true);
      const reqBody = JSON.stringify({
        source_code: sourceCode?.value,
        customer_id: customerID?.value,
        from_date: dayjs(from).format(dateFormat.universalFormat),
        to_date: dayjs(to).format(dateFormat.universalFormat),
        include_all_sections_flag: includeAllSectionsFlag
          ? numberConstants.zero
          : numberConstants.one,
        main_district_id: mainDistrictId,
        deactivated_flag: VALUE_N,
        customer_file_section_id: prevValues
          ? prevValue?.customerFileSectionId
          : VALUE_EMPTY_STRING
      });
      let res = await getResponseData(
        reqBody,
        `${CF_URLS.customerFileSearch.getcustomerfiledata}`,
        4
      );

      if (res) {
        const data0 = res?.data[0];
        data0.sort((a, b) =>
          b.Created_Datetime.localeCompare(a.Created_Datetime)
        );
        setSearchResults(data0);
        setIsResetGrid(false);
        if (res?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(res?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else if (res?.error) {
          setThrowErrorMessage(res?.error);
          setThrowError(true);
        }
      }
      setIsLoading(false);
      setIsDataLoaded(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const updateCustomerFile = async () => {
    const { serviceDate } = customerFileSearchForm.values;
    try {
      setThrowError(false);
      setIsLoading(true);
      const reqBody = JSON.stringify({
        customer_file_section_id: selectedRow?.customer_file_section_id,
        service_date: dayjs(serviceDate).format(dateFormat.universalFormat),
        ts_file_section: selectedRow?.Timestamp,
        main_district_id: mainDistrictId
      });
      let res = await getResponseData(
        reqBody,
        `${CF_URLS.customerFileSearch.updatefilesectionstatus}`,
        1
      );

      setIsLoading(false);
      setIsUpdating(false);
      setUpdateDisable(true);
      if (res?.data[0][0]?.error) {
        if (res?.data[0][0]?.error === numberConstants.zero) {
          await getSearchedData();
          setSuccessMessage(t("CustomerFileSearch.updateMessage"));
          setIsShowSuccessPopup(true);
        } else {
          const errorMsg = await getMessageFromCode(res?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        }
      } else if (res?.error) {
        setThrowErrorMessage(res?.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const handleActivate = async () => {
    try {
      setThrowError(false);
      setIsLoading(true);
      const reqBody = JSON.stringify({
        customer_file_section_id: selectedRow?.customer_file_section_id,
        warning_option: numberConstants?.one,
        ts_file_section: selectedRow?.Timestamp,
        main_district_id: mainDistrictId
      });
      let URL = isReactivateVisible
        ? `${CF_URLS.customerFileSearch.reactivatefilesection}`
        : `${CF_URLS.customerFileSearch.deactivatefilesection}`;
      let res = await getResponseData(reqBody, URL, 2);
      setIsLoading(false);
      setIsDeleting(false);
      if (res?.data[1][0]?.error) {
        if (res?.data[1][0]?.error === numberConstants.zero) {
          await getSearchedData();
          const Msg = await getMessageFromCode(errorMsgs?.errorCode25235);
          if (isReactivateVisible) {
            setSuccessMessage(
              Msg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
            );
            setIsShowSuccessPopup(true);
          }
          setIsReactivateVisible((prev) => !prev);
        } else {
          const errorMsg = await getMessageFromCode(res?.data[1][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        }
      } else if (res?.error) {
        setThrowErrorMessage(res?.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const handleView = () => {
    const path = selectedRow?.archive_path;
    setViewPath(path);
  };

  const handleViewPath = () => {
    try {
      if (viewPath) {
        const url = `${viewPath}`;
        window.open(url, "_blank");
      }
      setIsViewing(false);
      setViewPath("");
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };
  const handleCancelClick = () => {
    setIsUpdating(false);
    setIsViewing(false);
    setIsDeleting(false);
    setSelectedRow(null);
    setSelctedId(null);
    setSaveClicked(false);
    setThrowError(false);
    setViewPath("");
    setUpdateDisable(true);
    customerFileSearchForm.setFieldValue("serviceDate", null);
  };

  const handleClearEvent = () => {
    customerFileSearchForm.resetForm();
    setSearchResults([]);
    setSelectedRow(null);
    setIsPrintDisabled(true);
    setIsFindDisabled(true);
    setSelectedIndex(null);
    setThrowError(false);
    setIsResetGrid(true);
    setPrevValues(false);
  };
  const onFindClick = async () => {
    setSelectedRow(null);
    getSearchedData();
    setIsReactivateVisible(false);
    setSelectedIndex(null);
  };

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        customer_file_section_id: selectedRow?.customer_file_section_id,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });

      let url =
        CF_URLS.printReports.openMediaProcessing.customerFileSearchReport;

      let reportResponse = await printReport(requestBody, url);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const modalProps = {
    customers,
    isUpdating,
    isDeleting,
    isViewing,
    handleCancelClick,
    ErrorMessage,
    selectedRow,
    saveClicked,
    throwModalError,
    throwErrorMessage,
    setThrowModalError,
    updateCustomerFile,
    customerFileSearchForm,
    handleActivate,
    isReactivateVisible,
    viewText,
    activateText,
    handleView,
    today,
    minDate,
    viewPath,
    handleViewPath,
    isUpdateDisable,
    setUpdateDisable,
    setPrevValues
  };

  const tableProps = {
    searchResults,
    setIsUpdating,
    isUpdating,
    selectedId,
    selectedRow,
    setSelectedRow,
    setSelctedId,
    isResetGrid,
    setIsDeleting,
    isManager,
    isViewDisabled,
    isPrintDisabled,
    canBeDeactivated,
    canBeReactivated,
    setIsPrintDisabled,
    isReactivateVisible,
    handleActivate,
    setIsViewing,
    errorMsg,
    setViewText,
    setActivateText,
    setThrowErrorMessage,
    setViewPath,
    isEditDisabled,
    selectedIndex,
    setSelectedIndex,
    handleDownload,
    setThrowError,
    throwError,
    throwErrorMessage,
    showPrintModal,
    setShowPrintModal
  };
  const searchFilterProps = {
    customers,
    customerFileSearchForm,
    handleClearEvent,
    isFindDisabled,
    setIsFindDisabled,
    today,
    minDate,
    yesterday,
    errorMsg,
    setThrowError,
    setSearchResults,
    setIsPrintDisabled,
    setPrevValues
  };
  return (
    <>
      {prevValues
        ? isDataLoaded && <FullCircularLoader loading={isDataLoaded} />
        : isLoading && <FullCircularLoader loading={isLoading} />}
      {isShowSuccessPopup && (
        <SuccessPopup message={successMessage} close={setIsShowSuccessPopup} />
      )}
      {throwError && !showPrintModal && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            sx={{ marginBottom: spacing.margingTop20 }}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Stack>
        <CustomerFileSearch {...searchFilterProps} />
      </Stack>
      <Stack>
        <CustomerFileTable {...tableProps} />
      </Stack>

      <Stack>
        <CustomerFileModal {...modalProps} />
      </Stack>
    </>
  );
};
export default CustomerFileLayout;
