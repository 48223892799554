import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import DebouncedValue from "components/shared/Debounce";
import AuditAuthForm from "components/shared/auth/AuditAuthSection";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DISCREPANCY_MANAGEMENT,
  OPEN_MEDIA_AUDIT_DETAIL,
  OPEN_MEDIA_AUDIT_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH,
  VIEW_CAR
} from "routing/Paths";
import {
  useGetAllCustomersQuery,
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  getAudiTypeMedia,
  getCustomers,
  getDistricts,
  getMessageFromCode,
  getRequestType
} from "services/api/query";
import { selectAuth, setAuthenticationState } from "store/slices";
import {
  setLoading,
  setSearchResults,
  updateAuthFormValues,
  updateAuthentication,
  updateFormValues
} from "store/slices/open-media-audit-search";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  B,
  C,
  DAYJS_LOCALES,
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_CODE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  N_CHECKER,
  SUPPORTED_LANGUAGES,
  TAG_TYPES,
  VALUE_ALL,
  VALUE_N,
  Y_CHECKER,
  authSection,
  dateFormat,
  dayMonthYearTimeFormat,
  downloadReportOptions,
  errorMsgs,
  numberConstants,
  personalDetailConstants
} from "utils/constants";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { getAuthorizationPersonnelsAuthentications } from "services/common";
import {
  setIsReload,
  setViewModeId
} from "store/slices/open-media-audit-detail";
import {
  findErrorMessage,
  generateSHA256Hash,
  getLanguageId,
  getSortedData,
  getUserName,
  printReport,
  sendEmail
} from "utils/helpers";
import {
  CompletedStatus,
  distribution,
  district_id,
  emailRegex,
  getOptions,
  getResponseData,
  getTheEmailBody,
  getTheFirestoreErrorMessage,
  handleGetnameFormatting,
  initialValues,
  locationDefaultValue,
  messageCodes,
  openMediaAuditDetailConst,
  reportTypeHistory,
  reportTypeInitial,
  requestedByCustomer,
  requestedByLabel,
  scheduled,
  searchCount500,
  severityError,
  severityWarning,
  specialCharRex,
  started,
  status,
  status2,
  system_id,
  unknownMedia
} from "../../../utils/constants/open-media-processing/OpenMediaAuditConstants";
import Branch from "./BranchComponent";
import Customer from "./Customer";
import DetailTable from "./DetailTable";
import EmailPublish from "./EmailPublish";
import MediaCount from "./MediaCount";
import { DetailsStyles } from "./OpenMediaAuditDetailStyles";
import PossibleMatches from "./PossibleMatches";

let initialAuthValues = {
  branch: "",
  customer: "",
  personnel: "",
  authNumber: "",
  challengeQuestion: "",
  challengeResponse: ""
};

const OpenMediaAuditDetailScreen = ({
  setIsEmail,
  isEmail,
  isPossibleMatch,
  setIsPossibleMatch
}) => {
  const { currentBranch, auth, branches } = useSelector(selectAuth);
  const { viewModeId, isReload } = useSelector(
    (state) => state?.openMediaAuditDetails
  );

  const location = useLocation();
  const navigate = useNavigate();
  let omAuditId = location?.state?.id;
  const currentPath = location?.pathname;
  const todoProps = location?.state?.todoProps;
  const { formValues, authFormValues, isAuthenticated, isLoading } =
    useSelector((state) => state.openMediaAuditSearch);
  const dispatch = useDispatch();
  const languageLocaleId = getLanguageId();
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: authFormValues?.personnel?.value
  });
  const programSecurityList = auth?.user?.program_list;
  const programSecurityListArray = programSecurityList.split(",").map(Number);
  const selectedValue = downloadReportOptions[0];
  const auditDetailForm = useFormik({
    initialValues: initialValues
  });
  const [isLoadingValue, setIsLoading] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [requestedListBy, setRequestedListBy] = useState([]);
  const [auditTypeList, setAuditTypeList] = useState([]);
  const [dropDownDataLists, setDropDownData] = useState({});
  const [allBranches, setAllBranches] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [directionData, setDirectionData] = useState("");
  const [reportType, setReportType] = useState(false);
  const [volserList, setVolserList] = useState([]);

  const [showAuthenticationSection, setShowAuthenticationSection] =
    useState(false);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const [valuePersonnelAuth] = useState(authSection?.personnelAuth);
  const [viewData, setViewData] = useState("");
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});
  const [allLocations, setAllLocations] = useState([]);
  const [localeByBranch, setLocaleByBranch] = useState(DEFAULT_LOCALE);
  const [emailRecipientsList, setEmailRecipientsList] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [isPossibleMatchDownload, setIsPossibleMatchDownload] = useState(false);

  const [auditDetails, setAuditDetails] = useState("");
  const [possibleMatches, setPossibleMatches] = useState("");
  const [severity, setSeverity] = useState(severityError);
  const debouncedSearchText = DebouncedValue(searchText, searchCount500);
  const { values, setFieldValue, resetForm } = auditDetailForm;
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [cancelPopup, setCancelPopup] = useState({
    modalPopupOpen: false,
    showOk: false,
    showCancel: false,
    message: ""
  });
  const [customerList, setCustomerList] = useState([]);
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  const openMediaAuthForm = useFormik({
    initialValues: authFormValues
  });
  // getting the Customers on branch change in authentication
  const { data: authenticatedCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: authFormValues?.branch
  });
  useEffect(() => {
    if (customers.length) {
      let branch = branches?.find(
        (batch) => batch?.district_id === currentBranch?.district_id
      );
      let addBranch = customers.map((item) => {
        return {
          ...item,
          label: item?.label + " " + branch?.name
        };
      });
      setCustomerList(addBranch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, branches]);
  useEffect(() => {
    if (allLocations) {
      if (allLocations.length > 0) {
        const selectedLocationDetails = allLocations.filter(
          (location) => location.district_id === currentBranch?.district_id
        );
        setLocaleByBranch(
          DAYJS_LOCALES[selectedLocationDetails[0]?.iso_locale_code] ||
            DEFAULT_LOCALE
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLocations]);

  useEffect(() => {
    let id = omAuditId || viewModeId;
    dispatch(setIsReload(false));
    if (id && currentBranch?.district_id) {
      handleGetAuditDetail(omAuditId);
      dispatch(setViewModeId(omAuditId));
    } else if (!id) {
      handleNew();
    }
    //eslint-disable-next-line
  }, [omAuditId, currentBranch?.district_id, isReload]);

  useEffect(() => {
    getAllLocations();
    handleGetRequestByList();
    getAllBarnchesFromFireStore();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (omAuditId && !auditDetails) {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingValue, omAuditId]);
  useEffect(() => {
    throwError && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [throwError]);

  useEffect(() => {
    debouncedSearchText?.length &&
      getCustomersBasedOnTheSelectedBranch(debouncedSearchText);
    // eslint-disable-next-line
  }, [debouncedSearchText]);

  useEffect(() => {
    if (personnelAuth?.length) {
      const updates = {
        ...authFormValues,
        challengeQuestion: personnelAuth[0]?.challenge_question
      };
      dispatch(updateAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [personnelAuth]);

  // set the default branch into the auth section
  useEffect(() => {
    if (allBranches && allBranches?.length) {
      setIsLoading(true);
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      const updates = {
        branch: selectedLocationDetails?.district_id
      };
      dispatch(updateAuthFormValues(updates));

      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [allBranches]);

  useEffect(() => {
    if (todoProps) {
      setFieldValue("auditType", todoProps?.auditType);
      setFieldValue("requestedBy", todoProps?.requestedBy);
      handleDistribution("auditType", todoProps?.auditType);
    }
    //eslint-disable-next-line
  }, [todoProps]);

  const getAllLocations = async () => {
    setIsLoading((prev) => true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      const response = await callToCloudFunction(
        reqBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.COMMON.getalllocations}`
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      if (data?.data[0]) {
        setAllLocations((prev) => data?.data[0]);
      } else if (data?.error) {
        setAllLocations([]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setIsLoading((prev) => false);
      setAllLocations([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const handleGetRequestByList = async () => {
    try {
      setIsLoading(true);
      const dropdownItem = await getRequestType();
      setDropDownData({ ...dropDownDataLists, requestedListBy: dropdownItem });
      const list = getOptions(dropdownItem, "id", "descr");
      setRequestedListBy(list);
      if (!omAuditId) {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(e?.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };
  const handleGetAuditType = async () => {
    try {
      setIsLoading(true);
      const dropdownItem = await getAudiTypeMedia();
      const dropDownList = dropdownItem?.filter(
        (item) => item?.group_code === openMediaAuditDetailConst?.constantM
      );
      setDropDownData({ ...dropDownDataLists, auditTypeList: dropDownList });
      const list = getOptions(dropDownList, "id", "descr");
      setAuditTypeList(list);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(e?.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };
  const handleChangeOnChange = async (data, isDropDown) => {
    const { value, name } = data;
    const { requestedBy } = values;
    let requestedByValue = requestedBy;
    if (value === TAG_TYPES?.customer) {
      resetForm();
      if (!isAuthenticated) {
        onAuthenticatedCallerCliked();
      }
      setFieldValue("auditType", t("openMediaAuditDetail.logicalVault"));
      setFieldValue("requestedBy", requestedByValue);
      setFieldValue(openMediaAuditDetailConst.customerId, formValues?.customer);
    } else if (isDropDown) {
      resetForm();
    }
    if (name === requestedByLabel) {
      handleGetAuditType();
    } else {
      resetForm();
      setFieldValue("requestedBy", requestedByValue);
    }
    if (value === distribution) {
      handleDistribution(name, value);
    } else {
      setFieldValue(name, value);
      setThrowError(false);
      setThrowErrorMessage(null);
    }
  };
  const handleDistribution = async (name, value) => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        oldest_batch_datetime: dayjs()
      });
      const response = await getResponseData(
        reqBody,
        `${CF_URLS.openMediaProcessing?.auditdetail?.getauditdetails}`,
        3
      );
      if (Number(response?.data[0][0]?.error)) {
        const errorMsg = await getMessageFromCode(response?.data[0][0]?.error);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      } else if (!response?.data[0]?.length) {
        const errorMsg = await getMessageFromCode(
          messageCodes.messageCodes80223
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      } else {
        setDirectionData({
          oldest_batch_datetime: response?.data[1][0]?.oldest_batch_datetime,
          media_count: response?.data[0]?.length
        });
        setFieldValue(name, value);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const handleBack = () => {
    resetForm();
    handleReset();
  };
  const handleReSetAudit = () => {
    setFieldValue("auditType", "");
  };
  const handleLocationValidation = async () => {
    const {
      vault_from,
      vault_to,
      row_from,
      row_to,
      rack_from,
      rack_to,
      shelf_from,
      shelf_to
    } = values;
    let isError = false;
    if (
      vault_from > vault_to ||
      (!isNaN(vault_from) && vault_to === ALL_SELECTION?.value)
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80165);
      setThrowErrorMessage(
        errorMsg[0]?.descr?.replace(
          "{0}",
          t("workManagement.distribution.vault")
        )
      );
      isError = true;
    } else if (
      row_from > row_to ||
      (!isNaN(row_from) && row_to === ALL_SELECTION?.value)
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80165);
      setThrowErrorMessage(
        errorMsg[0]?.descr?.replace("{0}", t("workManagement.distribution.row"))
      );

      isError = true;
    } else if (
      rack_from > rack_to ||
      (!isNaN(rack_from) && rack_to === ALL_SELECTION?.value)
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80165);
      setThrowErrorMessage(
        errorMsg[0]?.descr?.replace(
          "{0}",
          t("workManagement.distribution.rack")
        )
      );
      isError = true;
    } else if (
      shelf_from > shelf_to ||
      (!isNaN(shelf_from) && shelf_to === ALL_SELECTION?.value)
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80165);
      setThrowErrorMessage(
        errorMsg[0]?.descr?.replace(
          "{0}",
          t("workManagement.distribution.shelf")
        )
      );
      isError = true;
    }
    return isError;
  };

  const handleSaveAction = async () => {
    const {
      requestedBy,
      auditType,
      due_date,
      vault_from,
      vault_to,
      row_from,
      row_to,
      rack_from,
      rack_to,
      shelf_from,
      shelf_to,
      comment,
      audit_sample_method_id,
      customer_id,
      logical_vault_id_list,
      om_batch_id_list
    } = values;
    let isError = false;
    setIsLoading(true);
    setThrowError(false);
    setSeverity(severityError);
    let reqBody = {
      main_district_id: currentBranch?.district_id,
      comment,
      vault_from: "",
      vault_to: "",
      row_from: "",
      row_to: "",
      rack_from: "",
      rack_to: "",
      shelf_from: "",
      shelf_to: ""
    };
    if (!isAuthenticated && requestedBy === TAG_TYPES?.customer && !omAuditId) {
      const errorObj = await getMessageFromCode(
        messageCodes?.messageCodes80226
      );
      const errorMsg = errorObj[0]?.descr;
      setThrowErrorMessage(errorMsg);
      setSeverity(severityError);
      setThrowError(true);
      setShowAuthForm(true);
      setIsLoading(false);
      return;
    }
    if (omAuditId || viewModeId) {
      reqBody = {
        ...reqBody,
        employee_id: auth?.user?.employee_id
      };
    } else {
      reqBody = {
        ...reqBody,
        logged_in_employee_id: auth?.user?.employee_id
      };
    }

    if (
      (requestedBy === TAG_TYPES?.customer &&
        openMediaAuthForm?.values?.personnel?.value) ||
      (viewData && viewData[0][0]?.requested_by_personnel_id)
    ) {
      reqBody = {
        ...reqBody,
        requested_personnel_id:
          openMediaAuthForm?.values?.personnel?.value ||
          viewData[0][0]?.requested_by_personnel_id
      };
    }
    if (!requestedBy) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
      setThrowErrorMessage(
        errorMsg[0]?.descr?.replace("|", t("openMediaAuditDetail.requestedBy"))
      );
      setIsLoading(false);
      setThrowError(true);
      return;
    }
    if (!auditType) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
      setThrowErrorMessage(
        errorMsg[0]?.descr?.replace("|", t("openMediaAuditDetail.auditType"))
      );
      setIsLoading(false);
      setThrowError(true);
      return;
    }
    if (!due_date) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
      setThrowErrorMessage(
        errorMsg[0]?.descr?.replace("|", t("openMediaAuditDetail.dueDate"))
      );
      setIsLoading(false);
      setThrowError(true);
      return;
    }

    dropDownDataLists?.requestedListBy?.forEach((item) => {
      if (item?.descr === requestedBy) {
        reqBody[openMediaAuditDetailConst?.requestor_code] =
          item?.id === openMediaAuditDetailConst?.requestor_Id24 ? B : C;
      }
    });
    dropDownDataLists?.auditTypeList?.forEach((item) => {
      if (item?.descr === auditType && !omAuditId) {
        reqBody[openMediaAuditDetailConst?.audit_type_id] = item?.audit_type_id;
      }
    });
    if (values?.auditType === openMediaAuditDetailConst?.logical_vault) {
      if (!customer_id && !customer_id?.value && !omAuditId) {
        const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
        setThrowErrorMessage(
          errorMsg[0]?.descr?.replace("|", t("openMediaAuditDetail.customer"))
        );
        isError = true;
      } else if (!logical_vault_id_list?.length && !omAuditId) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCode80193
        );
        setThrowErrorMessage(`${errorMsg[0]?.descr}`);
        isError = true;
      }
      reqBody = {
        ...reqBody,
        customer_id: customer_id?.value || customer_id,
        logical_vault_id_list: logical_vault_id_list
          ?.map((item) => item?.logical_vault_id)
          ?.join("")
      };
    } else if (values?.auditType === openMediaAuditDetailConst?.location) {
      isError = await handleLocationValidation();
      reqBody = {
        ...reqBody,
        vault_from,
        vault_to,
        row_from,
        row_to,
        rack_from,
        rack_to,
        shelf_from,
        shelf_to
      };
    } else if (values?.auditType === openMediaAuditDetailConst?.distribution) {
      if (!audit_sample_method_id) {
        const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
        setThrowErrorMessage(
          errorMsg[0]?.descr?.replace(
            "|",
            t("openMediaAuditDetail.auditSample")
          )
        );
        isError = true;
      }
      reqBody = {
        ...reqBody,
        audit_sample_method_id
      };
    } else if (
      values?.auditType === openMediaAuditDetailConst?.customer_distribution
    ) {
      if (!customer_id && !customer_id?.value && !omAuditId) {
        const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
        setThrowErrorMessage(
          errorMsg[0]?.descr?.replace("|", t("openMediaAuditDetail.customer"))
        );
        isError = true;
      } else if (!om_batch_id_list?.length) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCode80194
        );
        setThrowErrorMessage(`${errorMsg[0]?.descr}`);
        isError = true;
      }
      reqBody = {
        ...reqBody,
        customer_id: customer_id?.value || customer_id,
        om_batch_id_list: om_batch_id_list
          ?.map((item) => item?.open_media_batch_id)
          ?.join(",")
      };
    }
    if (isError) {
      setIsLoading(false);
      setThrowError(true);
      return;
    }
    try {
      let dataSets = null;
      if (omAuditId || viewModeId) {
        const jsonData = JSON.stringify({
          ...reqBody,
          om_audit_id: omAuditId || viewModeId,
          location_changed_flag: VALUE_N,
          om_audit_timestamp: "",
          due_date: dayjs(due_date).format(dateFormat.universalFormat)
        });
        dataSets = await getResponseData(
          jsonData,
          CF_URLS.openMediaProcessing?.auditdetail?.updateomaudit,
          2
        );
      } else {
        dataSets = await getResponseData(
          JSON.stringify({
            ...reqBody,
            due_date: dayjs(due_date).format(dateFormat.universalFormat)
          }),
          CF_URLS.openMediaProcessing?.auditdetail?.omauditcreate,
          2
        );
      }
      const { data } = dataSets;
      if (Number(data[0][0]?.error || data[1][0]?.error)) {
        const errorMsg = await getMessageFromCode(
          data[0][0]?.error || data[1][0]?.error
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        setIsLoading(false);
        return;
      } else if (data[0][0]?.om_audit_id) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80169);
        let errorMessage = errorMsg[0]?.descr?.replace(
          "{0}",
          dayjs().format(dayMonthYearTimeFormat)
        );
        handleGetAuditDetail(
          data[0][0]?.om_audit_id,
          currentBranch?.district_id,
          errorMessage
        );
        dispatch(setViewModeId(data[0][0]?.om_audit_id));
      } else if (data[0][0]?.om_audit_id || viewModeId) {
        let om_audit_id = data[0][0]?.om_audit_id || omAuditId || viewModeId;
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80169);
        let errorMessage = errorMsg[0]?.descr?.replace(
          "{0}",
          dayjs().format(dayMonthYearTimeFormat)
        );
        handleGetAuditDetail(
          om_audit_id,
          currentBranch?.district_id,
          errorMessage
        );
      } else {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
        setIsLoading(false);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };
  const handleGetAuditDetail = async (
    om_audit_id,
    main_district_id,
    successMessage
  ) => {
    setIsLoading(true);
    if (!om_audit_id) {
      setIsLoading(false);
      return;
    }
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: main_district_id || currentBranch?.district_id,
          om_audit_id: om_audit_id,
          iso_locale_code: DEFAULT_LOCALE_CODE
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getomauditpagedetailsinfo,
        8
      );
      const { data } = dataSets;
      if (Number(data[1][0]?.error)) {
        const errorMsg = await getMessageFromCode(data[1][0]?.error);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        return;
      } else {
        data[1] = data[1]?.sort(
          (a, b) =>
            new Date(b?.comment_date_ltz) - new Date(a?.comment_date_ltz)
        );
        setViewData(data);
        setAuditDetails(data[0][0]);
        handleGetAuditType();
        const {
          requestor_localized,
          audit_type,
          due_date,
          audit_sample_method_id,
          vault_from,
          vault_to,
          row_from,
          row_to,
          shelf_from,
          shelf_to,
          rack_from,
          rack_to,
          customer_id
        } = data[0][0];
        setFieldValue(openMediaAuditDetailConst?.audit_type, audit_type);
        setFieldValue(
          openMediaAuditDetailConst?.requested_by_label,
          requestor_localized
        );
        setFieldValue(openMediaAuditDetailConst?.comment, "");
        setFieldValue(openMediaAuditDetailConst?.audit_type, audit_type);
        setFieldValue(openMediaAuditDetailConst?.due_date, dayjs(due_date));
        setFieldValue(
          openMediaAuditDetailConst?.audit_sample_method_id,
          audit_sample_method_id
        );
        let vaultFrom = vault_from.includes(locationDefaultValue)
          ? ALL_SELECTION?.value
          : vault_from;
        let vaultTo = vault_to.includes(locationDefaultValue)
          ? ALL_SELECTION?.value
          : vault_to;
        let rowFrom = row_from.includes(locationDefaultValue)
          ? ALL_SELECTION?.value
          : row_from;
        let rowTo = row_to.includes(locationDefaultValue)
          ? ALL_SELECTION?.value
          : row_to;
        let shelfTo = shelf_to.includes(locationDefaultValue)
          ? ALL_SELECTION?.value
          : shelf_to;
        let shelfFrom = shelf_from.includes(locationDefaultValue)
          ? ALL_SELECTION?.value
          : shelf_from;
        let rackFrom = rack_from.includes(locationDefaultValue)
          ? ALL_SELECTION?.value
          : rack_from;
        let rackTo = rack_to.includes(locationDefaultValue)
          ? ALL_SELECTION?.value
          : rack_to;
        setFieldValue(openMediaAuditDetailConst?.vault_from, vaultFrom);
        setFieldValue(openMediaAuditDetailConst?.vault_to, vaultTo);
        setFieldValue(openMediaAuditDetailConst?.row_from, rowFrom);
        setFieldValue(openMediaAuditDetailConst?.row_to, rowTo);
        setFieldValue(openMediaAuditDetailConst?.shelf_to, shelfTo);
        setFieldValue(openMediaAuditDetailConst?.shelf_from, shelfFrom);
        setFieldValue(openMediaAuditDetailConst?.rack_from, rackFrom);
        setFieldValue(openMediaAuditDetailConst?.rack_to, rackTo);
        setFieldValue(openMediaAuditDetailConst?.customerId, customer_id);
        if (successMessage) {
          setToastMessage(successMessage);
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (authFormValues?.branch) {
      if (isAuthenticated) {
        getAllocatedCustomers();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authFormValues?.branch, isAuthenticated]);

  const onAuthenticatedCallerCliked = () => {
    if (isAuthenticated) {
      let branch = branches?.find(
        (batch) => batch?.district_id === currentBranch?.district_id
      );
      let addBranch = customers.map((item) => {
        return {
          ...item,
          label: item?.label + " " + branch?.name
        };
      });
      setShowAuthForm(false);
      dispatch(setAuthenticationState(null));
      dispatch(updateAuthFormValues(initialAuthValues));
      const updates = !isAuthenticated;
      dispatch(updateAuthentication(updates));
      setCustomerList(addBranch);
      handleNew();
      resetForm();
    } else if (showAuthenticationSection) {
      dispatch(updateAuthFormValues(initialAuthValues));
      setShowAuthenticationSection(false);
      setShowAuthForm(true);
    } else {
      setShowAuthenticationSection(true);
      setShowAuthForm(true);
    }
  };
  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    setIsLoading(true);
    try {
      const branches = await getDistricts();
      let bran = branches?.map((b) => {
        return {
          ...b,
          label: b?.name,
          value: b?.district_id
        };
      });
      const branchData = getSortedData(bran, "name", "asc");
      setAllBranches(branchData);
      if (!omAuditId) {
        setIsLoading(false);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.EXCEPTION));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const handleBranchChange = (e) => {
    const updatedValues = {
      branch: e.target.value,
      customer: "",
      personnel: ""
    };

    dispatch(updateAuthFormValues(updatedValues));
  };

  const handleCustomerChange = (event, newValue) => {
    const updatedValues = {
      ...authFormValues,
      customer: newValue,
      personnel: ""
    };
    dispatch(updateAuthFormValues(updatedValues));
  };
  // handle Personnel selection in auth section
  const handlePersonnelChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue
    };
    dispatch(updateAuthFormValues(updatedValues));
  };
  const onAuthenticateClick = async () => {
    setIsLoading(true);
    setIsAuthenticationError(false);
    setThrowErrorMessage("");
    setThrowError(false);
    try {
      // Check if either authNumber or challengeResponse is provided
      if (
        !authFormValues?.authNumber?.length &&
        !authFormValues?.challengeResponse?.length
      ) {
        setIsAuthenticationError(true);
        return;
      }

      // Authenticate with authNumber
      if (authFormValues?.authNumber?.length) {
        if (authFormValues.authNumber === personnelAuth[0]?.auth_number) {
          dispatch(updateAuthentication(true));
          dispatch(setSearchResults([]));
        } else {
          setIsAuthenticationError(true);
        }
      }

      // Authenticate with challengeResponse
      if (authFormValues?.challengeResponse?.length) {
        const hashCode = await generateSHA256Hash(
          authFormValues.challengeResponse.trim().toUpperCase()
        );

        const validateChallengeResponse =
          await triggerValidateChallengeResponse({
            main_district_id: String(currentBranch?.district_id),
            language_locale_id: languageLocaleId,
            personnel_id: authFormValues?.personnel?.value,
            challenge_response: hashCode
          });
        if (validateChallengeResponse?.data[0]?.is_match_flag === Y_CHECKER) {
          dispatch(updateAuthentication(true));
          dispatch(setSearchResults([]));
          setShowAuthForm(false);
          handleNew();
          resetForm();
        } else {
          setIsAuthenticationError(true);
        }
      }
      const data = await getAuthorizationPersonnelsAuthentications({
        personnelId: authFormValues?.personnel?.value
      });
      const array = JSON.parse(data?.customer_list)?.map((item) => {
        let branch = branches?.find(
          (batch) => batch?.district_id === currentBranch?.district_id
        );
        return {
          label: `${item.number} - ${item.name}  ${branch?.name}`,
          number: `${item.number}`,
          value: `${item.customer_Id}`
        };
      });
      if (array.length) {
        setCustomerList([...array]);
      } else {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCode80241
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        setSeverity(severityError);
      }
      openMediaAuthForm.setValues(authFormValues);
      setFieldValue(
        openMediaAuditDetailConst?.customerId,
        authFormValues?.customer
      );
      const updatedValues = {
        customer: authFormValues?.customer
      };
      dispatch(updateFormValues(updatedValues));
    } catch (error) {
      setIsAuthenticationError(true);
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuthFieldsChange = (name, value) => {
    const updatedValues = {
      ...authFormValues,
      [name]: value
    };
    dispatch(updateAuthFormValues(updatedValues));
  };

  const onCancelAuthCliked = () => {
    // dispatch(updateAuthFormValues(initialAuthValues));
    dispatch(updateAuthentication(false));
    setShowAuthenticationSection(false);
    setShowAuthForm(false);
  };
  // if not authenticated get the customers for the branch
  const getCustomersBasedOnTheSelectedBranch = async (text) => {
    setIsLoading(true);
    try {
      var reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_filter:
          formValues?.branch?.district_id === DISTRICT_ID_GET_ALL_LOCATIONS
            ? ""
            : formValues?.branch?.district_id,
        search_text: text,
        max_matches: personalDetailConstants.maxMatchesCust
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.personnelDetail.listOfCustomers
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const custData = getSortedData(data?.data[0], "number", "asc");
      let modCux = custData?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number?.trim()} - ${cus?.name?.trim()} - ${cus?.branch_name?.trim()}`,
          value: cus?.customer_id,
          number: cus?.number?.trim()
        };
      });
      setAllCustomers(modCux);
      setIsLoading(false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  // if authenticated get the allocated customers
  const getAllocatedCustomers = async () => {
    setIsLoading(true);
    try {
      const jsonString = personnelAuth[0]?.customer_list;
      const distinctCustomers = JSON.parse(jsonString);
      // Extract distinct customer values
      const distinctCustomerIds = distinctCustomers.reduce((acc, obj) => {
        if (!acc.includes(obj.customer_Id)) {
          acc.push(`${obj.customer_Id}`);
        }
        return acc;
      }, []);

      let customerList = await getCustomers(distinctCustomerIds);
      const custData = getSortedData(customerList, "number", "asc");
      let modCux = custData?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number} - ${cus?.name}`,
          value: cus?.id
        };
      });
      if (modCux?.length > 1) {
        modCux.unshift({
          label: VALUE_ALL,
          value: "",
          customer_id: "",
          number: ""
        });
      }
      if (authenticatedCustomers) {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80251);
        const errorMsg = errorObj[0]?.descr;
        setThrowErrorMessage(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE));
        setSeverity(severityWarning);
        setThrowError(true);
        setIsLoading(false);
      } else {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80241);
        const errorMsg = errorObj[0]?.descr;

        setThrowErrorMessage(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
        const updatedValues = {
          ...authFormValues,
          authNumber: "",
          challengeQuestion: "",
          challengeResponse: ""
        };
        dispatch(updateAuthFormValues(updatedValues));
        setShowAuthenticationSection(true);
        dispatch(updateAuthentication(false));
        setIsLoading(false);
      }
    } catch (errorMsge) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const handlePersonnelAuthChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue,
      authNumber: "",
      challengeQuestion: "",
      challengeResponse: ""
    };
    dispatch(updateAuthFormValues(updatedValues));
    dispatch(updateAuthentication(false));
    setIsAuthenticationError(false);
  };
  const handleCancel = async () => {
    setIsLoading(true);
    if (!isAuthenticated && values?.requestedBy === TAG_TYPES?.customer) {
      const errorObj = await getMessageFromCode(
        messageCodes?.messageCodes80226
      );
      const errorMsg = errorObj[0]?.descr;
      setThrowErrorMessage(errorMsg);
      setSeverity(severityError);
      setThrowError(true);
      setShowAuthForm(true);
      setIsLoading(false);
      setCancelPopup({
        modalPopupOpen: false,
        showOk: false,
        showCancel: false,
        message: ""
      });
      return;
    }
    let personnel_id =
      authFormValues?.personnel?.value ||
      auditDetails?.requested_by_personnel_id;
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.district_id,
          om_audit_id: auditDetails?.om_audit_id,
          iso_locale_code: DEFAULT_LOCALE_CODE,
          cancel_employee_id: auth?.user?.employee_id,
          cancel_personnel_id: personnel_id ? personnel_id : ""
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.cancelomaudit,
        2
      );
      const { data } = dataSets;
      if (Number(data[1][0]?.error)) {
        const errorMsg = await getMessageFromCode(data[1][0]?.error);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        return;
      } else if (auditDetails?.om_audit_id || viewModeId) {
        setThrowError(false);
        const errorMsg = await getMessageFromCode(
          messageCodes.messageCodes80170
        );
        let errorMessage = errorMsg[0]?.descr?.replace(
          "{0}",
          dayjs().format(dayMonthYearTimeFormat)
        );
        let auditId = auditDetails?.om_audit_id || viewModeId;
        handleGetAuditDetail(auditId, currentBranch?.district_id, errorMessage);
        dispatch(setViewModeId(""));
        setCancelPopup({
          modalPopupOpen: false,
          showOk: false,
          showCancel: false,
          message: ""
        });
      } else {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
        setIsLoading(false);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const handleCancelPopup = async () => {
    setIsLoading(true);
    let cancelPopupData = {
      ...cancelPopup,
      modalPopupOpen: true,
      showCancel: true
    };
    const { audit_status, audit_type } = auditDetails;
    if (audit_status === started && audit_type === distribution) {
      const errorMsg = await getMessageFromCode(messageCodes.messageCodes80234);
      cancelPopupData = {
        ...cancelPopupData,
        showOk: false,
        message: errorMsg[0]?.descr
      };
    } else if (audit_status === scheduled) {
      const errorMsg = await getMessageFromCode(messageCodes.messageCodes25088);
      cancelPopupData = {
        ...cancelPopupData,
        showOk: true,
        message: errorMsg[0]?.descr
      };
    } else {
      const errorMsg = await getMessageFromCode(messageCodes.messageCodes25004);
      cancelPopupData = {
        ...cancelPopupData,
        showOk: true,
        message: errorMsg[0]?.descr
      };
    }
    setCancelPopup({ ...cancelPopupData });
    setIsLoading(false);
  };

  const handleNew = async () => {
    setIsLoading(true);
    setViewData("");
    setAuditDetails("");
    dispatch(setViewModeId(""));
    resetForm();
    setThrowErrorMessage("");
    setThrowError(false);
    setSeverity(severityError);
    setTimeout(() => {
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_AUDIT_DETAIL}`
      );
    }, 1000);
    setIsLoading(false);
  };

  const handlePublish = async () => {
    setIsLoading(true);
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: district_id,
          customer_id: auditDetails?.customer_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getpersonnellist,
        2
      );
      const { data } = dataSets;
      if (Array.isArray(data[0])) {
        setIsEmail(true);
        let arr = [];
        data[0].forEach((item) => {
          // eslint-disable-next-line
          let name = `${item?.first_name},${item?.last_name}`;
          if (emailRegex.test(item?.business_email_addr)) {
            arr.push({ ...item, isCheck: false, first_name: name });
          }
        });
        const sortByFirstNameDescending = (a, b) => {
          const nameA = a.first_name.split(",").join("").toUpperCase();
          const nameB = b.first_name.split(",").join("").toUpperCase();
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }
          return 0;
        };
        let sorted = [...arr].sort(sortByFirstNameDescending);
        setEmailRecipientsList([...sorted]);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const emailSendingTask = async (
    recipients,
    emailSubject,
    mailBody,
    attechment
  ) => {
    setIsLoading(true);
    try {
      const response = await sendEmail(
        recipients,
        mailBody,
        emailSubject,
        attechment
      );
      if (response?.data?.isSuccess) {
        // email sent
        setIsLoading(false);
        return true;
      } else {
        setThrowError(true);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };
  const handleGetLink = async () => {
    setIsLoading(true);
    try {
      const dataSets2 = await getResponseData(
        JSON.stringify({
          district_id: district_id,
          main_district_id: district_id,
          system_id: system_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getcorpsettings,
        1
      );
      const cropList = dataSets2.data[0];
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: district_id,
          system_id: system_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getsystemglobalattributes,
        2
      );
      const { data } = dataSets;
      let res = [...data[0], ...cropList]?.filter((item) => {
        let key =
          item?.setting_type_descr ===
            openMediaAuditDetailConst?.ironMountainLink ||
          item?.global_attribute_type_descr ===
            openMediaAuditDetailConst?.ironMountainLink;
        return key;
      });
      return res;
    } catch (errorMsg) {
      setThrowErrorMessage(ERROR_TYPES.ISSUE);
      setThrowError(true);
      setLoading(false);
    }
  };
  const handleSend = async () => {
    setIsLoading(true);
    let data = emailRecipientsList?.filter((item) => item?.isCheck);
    if (!data?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.messageCodes80175);
      if (errorMsg[0]?.descr) {
        setIsEmail(false);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setSeverity(severityWarning);
        setThrowError(true);
      }
      setIsLoading(false);
      return;
    }
    let recipient = [];
    data?.forEach((item) => {
      recipient.push({
        emailAddress: item?.business_email_addr,
        name: item?.first_name.replace(specialCharRex, "")
      });
    });
    const attechment = await handlePrint(false);
    try {
      let emailSubjectAuth = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode80236
      );
      const link = await handleGetLink();
      const nameFormatting = await handleGetnameFormatting(
        auditDetails?.requested_by_personnel_name,
        auditDetails?.started_datetime_ltz
      );
      const mailBodyAuth = await getTheEmailBody(nameFormatting, link);
      if (mailBodyAuth) {
        const isEmailSendAuth = await emailSendingTask(
          recipient,
          emailSubjectAuth?.errorMsg?.replace("%1%", viewModeId),
          mailBodyAuth,
          attechment
        );
        if (isEmailSendAuth) {
          setIsEmail(false);
          handlePublishComment(recipient);
        } else {
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const handlePrint = async (isLink = true) => {
    setIsLoading(true);
    try {
      const requestBody = JSON.stringify({
        main_district_id: currentBranch?.id,
        customer_id: auditDetails?.customer_id,
        district_id: currentBranch?.district_id,
        om_audit_id: auditDetails?.om_audit_id,
        iso_locale_code:
          allLocations[0]?.iso_locale_code ||
          `"${SUPPORTED_LANGUAGES?.ENGLISH_US?.code}"`,
        district_name: currentBranch?.name,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase(),
        is_publised: isLink ? numberConstants.zero : numberConstants.one
      });

      const url = CF_URLS?.printReports?.auditdetail?.omauditdetailsreport;
      let reportResponse = await printReport(requestBody, url, isLink);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
      if (!isLink) {
        return reportResponse;
      } else {
        setIsLoading(false);
      }

      setIsDownload(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };
  const handlePrintPossible = async () => {
    setIsLoading(true);
    setSeverity(severityError);
    try {
      let requestBody = {
        main_district_id: currentBranch?.id,
        district_id: currentBranch?.district_id,
        om_audit_detail_id: possibleMatches?.om_audit_detail_id,
        barcode: possibleMatches?.scanned_barcode,
        scanned_location: possibleMatches?.scanned_location,
        audit_id: auditDetails?.om_audit_id,
        customer_number: possibleMatches?.customer_number.trim(),
        user_name: getUserName(auth?.user),
        customer_id: possibleMatches?.customer_id,
        report_format: selectedValue?.type?.toUpperCase(),
        report_type: "",
        volser_list: "",
        templates: ""
      };
      if (reportType) {
        requestBody = {
          ...requestBody,
          report_type: reportTypeInitial
        };
      } else {
        let templates = [],
          volser_list = [];
        volserList?.forEach((item) => {
          if (item?.volser !== unknownMedia) {
            if (item?.volser) {
              volser_list.push(item?.volser);
            } else {
              volser_list.push(N_CHECKER);
            }

            if (item?.match_template) {
              templates.push(item?.match_template);
            } else {
              templates.push(N_CHECKER);
            }
          }
        });
        requestBody = {
          ...requestBody,
          report_type: reportTypeHistory,
          volser_list: volser_list.join(","),
          templates: templates.join(",")
        };
      }
      const url = CF_URLS?.printReports?.auditdetail?.auditposiblematchreport;
      let reportResponse = await printReport(JSON.stringify(requestBody), url);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
      setIsLoading(false);
      setIsDownload(false);
      setIsPossibleMatchDownload(false);
      setReportType(false);
      setVolserList([]);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };
  const handlePossibleMatch = (data) => {
    setPossibleMatches(data);
    setIsPossibleMatch(true);
  };
  const handlePublishComment = async (recipient) => {
    setIsLoading(true);
    try {
      const errorMsg = await getMessageFromCode(messageCodes.messageCodes80180);
      let mail = recipient?.map((item) => item.emailAddress).join(",");
      let comment = errorMsg[0]?.descr?.replace(
        "{0}",
        dayjs().format(dayMonthYearTimeFormat)
      );
      comment = comment?.replace("{1}", mail);
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.id,
          om_audit_id: auditDetails?.om_audit_id,
          publish_comment: comment,
          employee_id: auth?.user?.employee_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.openmediaauditpublish,
        1
      );
      const { data } = dataSets;
      if (data[0][0]?.error !== numberConstants.zero) {
        const errorMsg = await getMessageFromCode(data[0][0]?.error);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      } else {
        setThrowError(false);
        const errorMsg = await getMessageFromCode(
          messageCodes.messageCodes80178
        );
        let errorMessage = errorMsg[0]?.descr?.replace(
          "{0}",
          dayjs().format(dayMonthYearTimeFormat)
        );
        handleGetAuditDetail(auditDetails?.om_audit_id, "", errorMessage);
      }
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const handlePrintMatch = (reportType, volcer) => {
    setIsPossibleMatchDownload(true);
    setReportType(reportType);
    setVolserList(volcer);
    setIsDownload(true);
    setThrowError(false);
  };

  const handleBackToEmail = async () => {
    const errorMsg = await getMessageFromCode(messageCodes?.messageCodes80177);
    setThrowErrorMessage(errorMsg[0]?.descr);
    setSeverity(severityWarning);
    setThrowError(true);
    setIsEmail(false);
  };
  const handleReset = () => {
    setThrowErrorMessage("");
    setSeverity(severityError);
    setThrowError(false);
    setLoading(false);
  };
  const ButtonComponent = () => {
    return (
      <>
        <MediaCount viewData={auditDetails} />
        <Stack>
          <DetailTable
            viewData={viewData}
            handlePossibleMatch={handlePossibleMatch}
          />
        </Stack>
        <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
          <Divider />
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="start"
          gap={spacing.verticalMargin20}
        >
          <Button
            id="clear-button"
            variant="outlined"
            type="button"
            onClick={() => {
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_AUDIT_SEARCH}`,
                { state: { from: omAuditId ? currentPath : null } }
              );
            }}
          >
            {t("openMediaAuditDetail.search")}
          </Button>
          <Button
            disabled={
              !(
                auditDetails?.discrepancy_exist_flag === Y_CHECKER &&
                auditDetails?.discrepancy_exist_flag
              )
            }
            id="clear-button"
            variant="outlined"
            type="button"
            onClick={() => {
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
                {
                  state: { omAuditId: omAuditId }
                }
              );
            }}
          >
            {t("containerAuditSearch.auditDetails.viewDiscrepancies")}
          </Button>
          <Button
            disabled={!auditDetails?.car_id || true}
            id="clear-button"
            variant="outlined"
            type="button"
            onClick={() => {
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${VIEW_CAR}`
              );
            }}
          >
            {t("containerAuditSearch.auditDetails.viewCar")}
          </Button>
          <Button
            disabled={
              !(
                auditDetails?.audit_status === CompletedStatus &&
                auditDetails?.requestor_localized === requestedByCustomer
              )
            }
            id="clear-button"
            variant="outlined"
            type="button"
            onClick={() => {
              handlePublish();
            }}
          >
            {t("openMediaAuditDetail.publish")}
          </Button>
          <Button
            disabled={!(auditDetails?.audit_type && viewData[6]?.length > 0)}
            id="clear-button"
            variant="outlined"
            type="button"
            onClick={() => {
              setIsDownload(true);
            }}
          >
            {t("openMediaAuditDetail.print")}
          </Button>
          <Button
            disabled={
              auditDetails?.audit_status
                ? status.includes(auditDetails?.audit_status)
                : true
            }
            id="clear-button"
            variant="outlined"
            type="button"
            onClick={() => {
              handleCancelPopup();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            id="clear-button"
            variant="outlined"
            type="button"
            onClick={() => {
              handleNew();
            }}
          >
            {t("containerAuditSearch.auditDetails.new")}
          </Button>
          <Button
            variant="contained"
            size="medium"
            type="button"
            onClick={() => handleSaveAction()}
            disabled={
              auditDetails?.audit_status &&
              status2.includes(auditDetails?.audit_status)
            }
          >
            {t("common.save")}
          </Button>
        </Grid>
      </>
    );
  };

  const handleComponentRender = () => {
    if (showAuthForm && !isAuthenticated) {
      return (
        <AuditAuthForm
          allBranches={allBranches}
          handleBranchChange={handleBranchChange}
          openMediaAuthForm={openMediaAuthForm}
          valuePersonnelAuth={valuePersonnelAuth}
          allCustomers={allCustomers}
          handleCustomerChange={handleCustomerChange}
          handlePersonnelChange={handlePersonnelChange}
          onAuthenticateClick={onAuthenticateClick}
          isAuthenticationError={isAuthenticationError}
          isAuthenticated={isAuthenticated}
          onCancelAuthCliked={onCancelAuthCliked}
          programSecurityListArray={programSecurityListArray}
          setError={setThrowErrorMessage}
          isLoading={isLoading}
          getCustomersBasedOnTheSelectedBranch={
            getCustomersBasedOnTheSelectedBranch
          }
          handleChange={handleAuthFieldsChange}
          setLoading={setLoading}
          authFormValues={authFormValues}
          searchText={searchText}
          setSearchText={setSearchText}
          authCustomers={authenticatedCustomers}
        />
      );
    } else if (isEmail) {
      return (
        <EmailPublish
          handleBack={() => {
            handleBackToEmail();
          }}
          emailRecipientsList={emailRecipientsList}
          handleSend={handleSend}
          setEmailRecipientsList={setEmailRecipientsList}
          setThrowErrorMessage={setThrowErrorMessage}
          setThrowError={setThrowError}
          setSeverity={setSeverity}
        />
      );
    } else if (isPossibleMatch) {
      return (
        <PossibleMatches
          possibleMatches={possibleMatches}
          handleBack={() => {
            setPossibleMatches("");
            setIsPossibleMatch(false);
            handleReset();
          }}
          currentBranch={currentBranch}
          setThrowError={setThrowError}
          setIsLoading={setIsLoading}
          setThrowErrorMessage={setThrowErrorMessage}
          auth={auth}
          handleGetAuditDetail={handleGetAuditDetail}
          allLocations={
            allLocations[0]?.iso_locale_code ||
            SUPPORTED_LANGUAGES?.ENGLISH_US?.code
          }
          setSeverity={setSeverity}
          allAuditData={viewData}
          setToastMessage={setToastMessage}
          handlePrintMatch={handlePrintMatch}
        />
      );
    } else if (values?.requestedBy === TAG_TYPES?.customer) {
      return (
        <>
          <Customer
            handleBack={handleBack}
            requestedListBy={requestedListBy}
            values={values}
            setFieldValue={setFieldValue}
            handleChangeOnChange={handleChangeOnChange}
            auditTypeList={auditTypeList}
            setIsLoading={setIsLoading}
            currentBranch={currentBranch}
            setThrowError={setThrowError}
            setThrowErrorMessage={setThrowErrorMessage}
            auditDetailForm={auditDetailForm}
            viewData={auditDetails || viewModeId}
            isViewMode={!!auditDetails?.audit_type}
            localeByBranch={localeByBranch}
            allAuditData={viewData}
            setSeverity={setSeverity}
            customers={customerList}
          />
          <ButtonComponent />
        </>
      );
    } else {
      return (
        <>
          <Branch
            requestedListBy={requestedListBy}
            values={values}
            setFieldValue={setFieldValue}
            handleChangeOnChange={handleChangeOnChange}
            auditTypeList={auditTypeList}
            customers={customerList}
            setIsLoading={setIsLoading}
            currentBranch={currentBranch}
            setThrowError={setThrowError}
            setThrowErrorMessage={setThrowErrorMessage}
            handleReSetAudit={handleReSetAudit}
            auditDetailForm={auditDetailForm}
            viewData={auditDetails || viewModeId}
            isViewMode={!!auditDetails?.audit_type}
            localeByBranch={localeByBranch}
            allAuditData={viewData}
            setSeverity={setSeverity}
            directionData={directionData}
          />
          <ButtonComponent />
        </>
      );
    }
  };

  return (
    <>
      {(isLoading || isLoadingValue) && (
        <CircularLoaderFullPage loading={isLoading || isLoadingValue} />
      )}
      <ConfirmPopup
        modalPopupOpen={cancelPopup?.modalPopupOpen}
        handleCancel={() => {
          setCancelPopup({
            modalPopupOpen: false,
            showOk: false,
            showCancel: false,
            message: ""
          });
        }}
        showOk={cancelPopup?.showOk}
        message={cancelPopup?.message}
        showCancel={cancelPopup?.showCancel}
        handleYes={() => {
          handleCancel();
        }}
      />
      <DownloadPopup
        title={t("customerSchedule.downloadReportTitle")}
        showPrintModal={isDownload}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => {
          setIsDownload(false);
          setIsPossibleMatchDownload(false);
        }}
        onDownloadClick={() => {
          if (isPossibleMatchDownload) {
            handlePrintPossible();
          } else {
            handlePrint();
          }
        }}
      />
      <form>
        <Stack gap={spacing.gap} sx={DetailsStyles.mainDivStyles}>
          <AuthenticationCallerButton
            isAuthenticated={isAuthenticated}
            onAuthenticatedCallerCliked={() => onAuthenticatedCallerCliked()}
          />
          {toastMessage && (
            <SuccessPopup
              message={toastMessage}
              close={() => {
                setToastMessage("");
              }}
            />
          )}
          {throwError && (
            <Collapse in={throwError}>
              <Alert
                severity={severity}
                sx={DetailsStyles.errorStyle}
                icon={
                  <img
                    src={severity === severityError ? ErrorIcon : WarningIcon}
                    alt="error"
                    style={DetailsStyles.iconStyle}
                  />
                }
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setSeverity(severityError);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Collapse>
          )}
          {isAuthenticated && (
            <>
              <SuccessAuthSection
                personalDetailForm={openMediaAuthForm}
                valuePersonnelAuth={valuePersonnelAuth}
                handlePersonnelOnChange={handlePersonnelAuthChange}
                disablePersonnel={true}
              />
              <Divider />
            </>
          )}
          <>{handleComponentRender()}</>
        </Stack>
      </form>
    </>
  );
};

export default OpenMediaAuditDetailScreen;
