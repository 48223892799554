// logical_vault_l_sp
import { CF_URLS } from "services/api/endpoints";
import { getResponseData } from "utils/helpers";
import { logicalVaultConstants } from "./constants";

export const getVaultNameDetails = async (mainDistrictId, customerId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    customer_id: customerId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.vaultNameDetails,
    2
  );
  return res;
};

export const getLogicalVaultInfo = async (mainDistrictId, vaultId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: vaultId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.logicalVaultInfo,
    2
  );
  return res;
};

export const getSelectedVaultMediaTypes = async (mainDistrictId, vaultId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: vaultId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.logicalVaultSelectedMediaTypes,
    2
  );
  return res;
};

export const getAvailableVaultMediaTypes = async (mainDistrictId, vaultId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: vaultId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.logicalVaultAvailableMediaTypes,
    2
  );
  return res;
};

export const getMediaTypeByProgramType = async (
  mainDistrictId,
  programTypeId
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    media_program_type_id: programTypeId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.logicalVaultSelectMediaByProgramType,
    2
  );
  return res;
};

export const getHandlingCatalogItems = async (mainDistrictId, customerId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    customer_id: customerId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.handlingCatalogItems,
    2
  );
  return res;
};

export const getActiveCatalogItems = async (mainDistrictId, customerId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    customer_id: customerId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.activeCatalogItems,
    2
  );
  return res;
};

export const createLogicalVaultRecord = async (
  mainDistrictId,
  customerId,
  formObj
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    customer_id: customerId,
    code: formObj.values.vaultName,
    media_program_type_id: formObj.values.vaultProgramType,
    manual_count_flag:
      formObj.values.vaultMediaTracking === ""
        ? logicalVaultConstants.noValue
        : formObj.values.vaultMediaTracking,
    capacity_threshold:
      formObj.values.vaultCapacityNotify !== ""
        ? formObj.values.vaultCapacityNotify
        : 0,
    long_term_flag: formObj.values.vaultDuration,
    customer_assigned_slot_flag:
      formObj.values.vaultSlotAssignment === logicalVaultConstants.valueC
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    display_slot_flag:
      formObj.values.vaultSlotAssignment === logicalVaultConstants.valueI
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    interactive_distribution_scan_flag:
      formObj.values.vaultDistribution.includes("RI")
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    inventory_file_provided_flag:
      formObj.values.vaultInventory.length === 0
        ? logicalVaultConstants.noValue
        : logicalVaultConstants.yesValue,
    distribution_verification_required_flag:
      formObj.values.vaultDistribution.includes(logicalVaultConstants.valueVR)
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    vault_catalog_item_code: formObj.values.vaultingCatalogItem,
    invoice_descr:
      formObj.values.vaultDesc === ""
        ? formObj.values.vaultName.toLowerCase()
        : formObj.values.vaultDesc,
    default_handling_hours:
      formObj.values.vaultHandlingHours === ""
        ? logicalVaultConstants.valueZero
        : formObj.values.vaultHandlingHours,
    review_handling_hours_flag:
      formObj.values.vaultReviewMonthly.length === 0
        ? logicalVaultConstants.noValue
        : logicalVaultConstants.yesValue,
    handle_catalog_item_code: formObj.values.vaultHandlingItem,
    no_handling_flag:
      formObj.values.vaultHandlingItem !== ""
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    bill_all_active_volsers_flag:
      formObj.values.vaultBillAll.length === 0
        ? logicalVaultConstants.noValue
        : formObj.values.vaultBillAll.toString(),
    billing_rounding_override_flag:
      formObj.values.vaultTurnRoundingFlag.length === 0
        ? logicalVaultConstants.noValue
        : logicalVaultConstants.yesValue,
    bill_round_inc: formObj.values.vaultTurnRoundingValue
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.createLogicalVaultRecord,
    2
  );
  return res;
};

export const updateLogicalVaultRecord = async (
  mainDistrictId,
  timestamp,
  formObj
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: formObj.values.vaultName,
    ts1: timestamp,
    manual_count_flag:
      formObj.values.vaultMediaTracking === ""
        ? logicalVaultConstants.noValue
        : formObj.values.vaultMediaTracking,
    capacity_threshold:
      formObj.values.vaultCapacityNotify !== ""
        ? formObj.values.vaultCapacityNotify
        : 0,
    long_term_flag: formObj.values.vaultDuration,
    customer_assigned_slot_flag:
      formObj.values.vaultSlotAssignment === logicalVaultConstants.valueC
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    display_slot_flag:
      formObj.values.vaultSlotAssignment === logicalVaultConstants.valueI
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    interactive_distribution_scan_flag:
      formObj.values.vaultDistribution.includes(logicalVaultConstants.valueRI)
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    inventory_file_provided_flag:
      formObj.values.vaultInventory.length === 0
        ? logicalVaultConstants.noValue
        : logicalVaultConstants.yesValue,
    distribution_verification_required_flag:
      formObj.values.vaultDistribution.includes(logicalVaultConstants.valueVR)
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    vault_catalog_item_code: formObj.values.vaultingCatalogItem,
    invoice_descr:
      formObj.values.vaultDesc === ""
        ? formObj.values.vaultName.toLowerCase()
        : formObj.values.vaultDesc,
    default_handling_hours:
      formObj.values.vaultHandlingHours === ""
        ? logicalVaultConstants.valueZero
        : formObj.values.vaultHandlingHours,
    review_handling_hours_flag:
      formObj.values.vaultReviewMonthly.length === 0
        ? logicalVaultConstants.noValue
        : logicalVaultConstants.yesValue,
    handle_catalog_item_code: formObj.values.vaultHandlingItem,
    no_handling_flag:
      formObj.values.vaultHandlingItem !== ""
        ? logicalVaultConstants.yesValue
        : logicalVaultConstants.noValue,
    bill_all_active_volsers_flag:
      formObj.values.vaultBillAll.length === 0
        ? logicalVaultConstants.noValue
        : logicalVaultConstants.yesValue,
    billing_rounding_override_flag:
      formObj.values.vaultTurnRoundingFlag.length === 0
        ? logicalVaultConstants.noValue
        : logicalVaultConstants.yesValue,
    bill_round_inc: formObj.values.vaultTurnRoundingValue,
    dist_service_note: formObj.values.vaultDistributionServiceNotes,
    pick_service_note: formObj.values.vaultServiceNotes
  };

  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.updateLogicalVaultRecord,
    2
  );
  return res;
};

export const addMediaTypes = async (mainDistrictId, vaultId, mediaTypeId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: vaultId,
    media_type_id: mediaTypeId,
    print_timestamp_flag: logicalVaultConstants.yesValue
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.addMediaTypes,
    2
  );
  return res;
};

export const deleteMediaTypes = async (
  mainDistrictId,
  vaultId,
  mediaTypeId
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: vaultId,
    media_type_id: mediaTypeId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.deleteMediaTypes,
    2
  );
  return res;
};

export const deleteLogicalVault = async (mainDistrictId, vaultId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: vaultId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.deleteLogicalVault,
    2
  );
  return res;
};

export const renameLogicalVault = async (
  mainDistrictId,
  vaultId,
  newCode,
  timestamp
) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: vaultId,
    new_code: newCode,
    timestamp: timestamp
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.renameLogicalVault,
    2
  );
  return res;
};

export const getOpenMediaAuditsCheck = async (mainDistrictId, vaultId) => {
  const reqBody = {
    main_district_id: mainDistrictId,
    logical_vault_id: vaultId
  };
  let res = await getResponseData(
    reqBody,
    CF_URLS.logicalVaultDetail.checkOpenMediaAudits,
    2
  );
  return res;
};
