import AssignShelves from "components/assign-shelves/AssignShelves";
import TestLabelTemplate from "components/customer-information/label-template-maintenance/TestLabelTemplate";
import UnauthorizedPage from "components/unauthorizedComponent/UnauthorizedPage";
import {
  BillingErrorLog,
  BillingRecurringCharge,
  BillingStatus,
  BillingTransactions,
  CapacityBillingConfigurationSearch,
  CustomerBillingInformation,
  CustomerSearch,
  EmployeeDetail,
  EnterpriseBillingSummary,
  MediaDestructionRequestDelivery,
  MediaDestructionRequestDetailVaultItemized,
  MediaDestructionRequestPickup,
  PersonnelDetail,
  PersonnelHistory,
  PersonnelSearch,
  PersonnelValidation,
  Preferences,
  PrintTapeLabel,
  RequestSearch,
  RouteManagement,
  RunManagement,
  VendorManagement
} from "pages";
import NotificationPage from "pages/administration/NotificationConfiguration";
import ContainerAuditDetails from "pages/container-processing/ContainerAuditDetails";
import ContainerAuditSearch from "pages/container-processing/ContainerAuditSearch";
import ContainerGeneral from "pages/container-processing/ContainerGeneral";
import ContainerIssuerance from "pages/container-processing/ContainerIssuance";
import ContainerResearch from "pages/container-processing/ContainerResearch";
import ContainerResearchTransactionDetail from "pages/container-processing/ContainerResearchTransactionDetail";
import ReassianTransport from "pages/container-processing/ReassianTransport";
import CorrectiveActionRequestSearchPage from "pages/corrective-action-request/CorrectiveActionRequestSearchPage";
import CorrectiveActionRequest from "pages/corrective-request-detail/CorrectiveActionRequest";
import CustomerMoveDetail from "pages/customer-move/CustomerMoveDetail";
import CustomerSchedule from "pages/customer-service-information/CustomerSchedule";
import CustomerServiceInformationPage from "pages/customer-service-information/CustomerServiceInformationPage";
import LabelTemplateMaintenance from "pages/customer-service-information/LabelTemplateMaintenance";
import LabelTemplateSearch from "pages/customer-service-information/LabelTemplateSearch";
import MinimumEmptyTransport from "pages/customer-service-information/MinimumEmptyTransport";
import AddCutOffDeadlineMain from "pages/customer-service-information/preferences/AddCutOffDeadlineMain";
import DontCallDesc from "pages/customer-service-information/preferences/DontCallDesc";
import EmailNotifications from "pages/customer-service-information/preferences/EmailNotifications";
import HolidayServicePage from "pages/customer-service-information/preferences/HolidayServicePage";
import PayInvoiceScreen from "pages/customer-service-information/preferences/PayInvoice";
import PlaceHoldOnMediaDestructionRequestsScreen from "pages/customer-service-information/preferences/PlaceHoldOnMediaDestructionRequests";
import ScanOnSiteScreen from "pages/customer-service-information/preferences/ScanOnSite";
import ServiceIndicatorScreen from "pages/customer-service-information/preferences/ServiceIndicator";
import ServiceWindows from "pages/customer-service-information/ServiceWindows";
import Event from "pages/disaster-module/EventDetail";
import EventRequestGenerator from "pages/disaster-module/EventRequestGenerator";
import EventRequestGeneratorOther from "pages/disaster-module/EventRequestGeneratorOther";
import EventSearch from "pages/disaster-module/EventSearch";
import EventViewDrMedia from "pages/disaster-module/EventViewDrMedia";
import InstructionFiles from "pages/disaster-module/InstructionFiles";
import DiscrepancyManagementDetail from "pages/discrepancy-management/DiscrepancyManagementDetail";
import DiscrepencySearchPage from "pages/discrepancy-management/DiscrepancySearch";
import DiscrepancyUpdate from "pages/discrepancy-management/DiscrepancyUpdate";
import EmployeeSearch from "pages/employee-module/EmployeeSearch";
import LabelPrinterSetup from "pages/label-printing/LabelPrinterSetup";
import LogicalVaultDetails from "pages/logical-vault-details/LogicalVaultDetails";
import BranchOutagePickListCompletion from "pages/open-media-processing/BranchOutagePickList";
import CreateDistributionList from "pages/open-media-processing/CreateDistributionList";
import CustomerFileSearchPage from "pages/open-media-processing/CustomerFileSearchPage";
import OpenMediaAuditDetail from "pages/open-media-processing/OpenMediaAuditDetail";
import OpenMediaAuditSearch from "pages/open-media-processing/OpenMediaAuditSearch";
import OpenMediaResearchDetailPage from "pages/open-media-processing/OpenMediaResearchDetailPage";
import OpenMediaResearchPage from "pages/open-media-processing/OpenMediaResearchPage";
import WorkManagement from "pages/open-media-processing/WorkManagement";
import Reports from "pages/reports-module/Reports";
import VaultStatusReport from "pages/reports-module/VaultStatusReport";
import SecurityGroupEmployee from "pages/security-module/SecurityGroupEmployee";
import SecurityGroupManagement from "pages/security-module/SecurityGroupManagement";
import RouteDetails from "pages/transportation-module/route-detail/RouteDetails";
import VaultConfigurationScreen from "pages/vault-configuration/VaultConfiguration";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import * as Path from "./Paths";

function RouteElement({ path, role, multipleRole }) {
  const { roleAccess } = useSelector(selectAuth);
  let returnElement = undefined;
  switch (path) {
    case Path.REQUEST_SEARCH:
      roleAccess.includes(role) && (returnElement = <RequestSearch />);
      break;
    case Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP:
      roleAccess.includes(role) &&
        (returnElement = <MediaDestructionRequestPickup />);
      break;
    case Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY:
      roleAccess.includes(role) &&
        (returnElement = <MediaDestructionRequestDelivery />);
      break;
    case Path.MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED:
      roleAccess.includes(role) &&
        (returnElement = <MediaDestructionRequestDetailVaultItemized />);
      break;
    case Path.BILLING_STATUS:
      roleAccess.includes(role) && (returnElement = <BillingStatus />);
      break;

    case Path.PERSONAL_VALIDATION:
      roleAccess.includes(role) && (returnElement = <PersonnelValidation />);
      break;

    case Path.PERSONAL_SEARCH:
      roleAccess.includes(role) && (returnElement = <PersonnelSearch />);
      break;
    case Path.PERSONAL_DETAIL:
      roleAccess.includes(role) && (returnElement = <PersonnelDetail />);
      break;
    case Path.PERSONAL_HISTORY:
      roleAccess.includes(role) && (returnElement = <PersonnelHistory />);
      break;
    case Path.BILLING_TRANSACTIONS:
      roleAccess.includes(role) && (returnElement = <BillingTransactions />);
      break;
    case `${Path.OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${Path.CORRECTIVE_ACTION_REQUESTS_SEARCH}`:
      roleAccess.includes(role) &&
        (returnElement = <CorrectiveActionRequestSearchPage />);
      break;
    case Path.ENTERPRISE_BILLING_SUMMARY:
      returnElement = <EnterpriseBillingSummary />;
      break;
    case Path.CUSTOMER_BILLING_INFORMATION:
      returnElement = <CustomerBillingInformation />;
      break;
    case Path.CAPACITY_BILLING_CONFIGURATION_SEARCH:
      returnElement = <CapacityBillingConfigurationSearch />;
      break;
    case Path.RECURRING_CHANGE_SEARCH:
      roleAccess.includes(role) && (returnElement = <BillingRecurringCharge />);
      break;
    case Path.BILLING_ERROR_LOG:
      roleAccess.includes(role) && (returnElement = <BillingErrorLog />);
      break;
    case Path.GROUP_EMPLOYEE:
      roleAccess.includes(role) && (returnElement = <SecurityGroupEmployee />);
      break;
    case Path.GROUP_MANAGEMENT:
      roleAccess.includes(role) &&
        (returnElement = <SecurityGroupManagement />);
      break;

    case Path.RUN_MANAGEMENT:
      roleAccess.includes(role) && (returnElement = <RunManagement />);
      break;

    case Path.ROUTE_DETAIL:
      roleAccess.includes(role) && (returnElement = <RouteDetails />);
      break;
    case Path.VENDOR_MANAGEMENT:
      roleAccess.includes(role) && (returnElement = <VendorManagement />);
      break;

    case Path.EMPLOYEE_SEARCH:
      roleAccess.includes(role) && (returnElement = <EmployeeSearch />);
      break;

    case Path.EMPLOYEE_DETAIL:
      roleAccess.includes(role) && (returnElement = <EmployeeDetail />);
      break;
    case Path.LABEL_PRINTER_SETUP:
      roleAccess.includes(role) && (returnElement = <LabelPrinterSetup />);
      break;

    case Path.CREATE_DISTRIBUTION_LIST:
      roleAccess.includes(role) && (returnElement = <CreateDistributionList />);
      break;

    case Path.SERVICE_INFORMATION:
      roleAccess.includes(role) &&
        (returnElement = <CustomerServiceInformationPage />);
      break;
    case Path.LABEL_TEMPLATE_SEARCH:
      roleAccess.includes(role) && (returnElement = <LabelTemplateSearch />);
      break;
    case Path.LABEL_TEMPLATE_MAINTENANCE:
      roleAccess.includes(role) &&
        (returnElement = <LabelTemplateMaintenance />);
      break;
    case Path.TEST_LABEL_TEMPLATE:
      roleAccess.includes(role) && (returnElement = <TestLabelTemplate />);
      break;
    case Path.BRANCH_OUTAGE_PICK_LIST_COMPLETION:
      roleAccess.includes(role) &&
        (returnElement = <BranchOutagePickListCompletion />);
      break;

    case Path.CUSTOMER_FILE_SEARCH:
      roleAccess.includes(role) && (returnElement = <CustomerFileSearchPage />);
      break;

    case Path.PRINT_TAPE_LABEL:
      roleAccess.includes(role) && (returnElement = <PrintTapeLabel />);
      break;

    case Path.WORK_MANAGEMENT:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <WorkManagement />);
      break;

    case Path.GENERAL:
      returnElement = <ContainerGeneral />;
      break;
    case Path.CONTAINER_ISSUANCE:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <ContainerIssuerance />);
      break;
    case Path.CONTAINER_PROCESSING_RESEARCH:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <ContainerResearch />);
      break;
    case `${Path.CONTAINER_PROCESSING_RESEARCH}/${Path.CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <ContainerResearchTransactionDetail />);
      break;
    case Path.REASSIGN_TRANSPORT_CONTENTS:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <ReassianTransport />);
      break;
    case Path.CONTAINER_INVENTORY_AUDIT_SEARCH:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <ContainerAuditSearch />);
      break;
    case Path.CONTAINER_INVENTORY_AUDIT_DETAIL:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <ContainerAuditDetails />);
      break;
    case Path.OPEN_MEDIA_AUDIT_DETAIL:
      roleAccess.includes(role) && (returnElement = <OpenMediaAuditDetail />);
      break;
    case Path.OPEN_MEDIA_AUDIT_SEARCH:
      roleAccess.includes(role) && (returnElement = <OpenMediaAuditSearch />);
      break;
    case `${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.OPEN_MEDIA_PROCESSING_RESEARCH}`:
      roleAccess.includes(role) && (returnElement = <OpenMediaResearchPage />);
      break;
    case `${Path.OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${Path.OPEN_MEDIA_PROCESSING_RESEARCH_DETAILS}`:
      roleAccess.includes(role) &&
        (returnElement = <OpenMediaResearchDetailPage />);
      break;
    case Path.SCHEDULE:
      roleAccess.includes(role) && (returnElement = <CustomerSchedule />);
      break;
    case Path.PREFERENCES:
      returnElement = <Preferences />;
      break;
    case Path.ASSIGN_SHELVES:
      returnElement = <AssignShelves />;
      break;
    case `${Path.PREFERENCES}/${Path.PAYINVOICE}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <PayInvoiceScreen />);
      break;
    case `${Path.PREFERENCES}/${Path.PREFERENCES_PLACE_HOLD_ON_MEDIA_DESTRUCTION_REQUESTS}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <PlaceHoldOnMediaDestructionRequestsScreen />);
      break;
    case `${Path.PREFERENCES}/${Path.PREFERENCES_SCAN_ON_SITE}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <ScanOnSiteScreen />);
      break;
    case `${Path.PREFERENCES}/${Path.PREFERENCES_HOLIDAY_SERVICE}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <HolidayServicePage />);
      break;
    case `${Path.PREFERENCES}/${Path.PREFERENCES_SERVICE_INDICATOR}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <ServiceIndicatorScreen />);
      break;
    case `${Path.PREFERENCES}/${Path.PREFERENCES_EMAIL_NOTIFICATION}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <EmailNotifications />);
      break;
    case `${Path.PREFERENCES}/${Path.PREFERENCES_DONT_CALL_DESC}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <DontCallDesc />);
      break;
    case `${Path.PREFERENCES}/${Path.PREFERENCES_ADD_ONCUTOFF_DEADLINE}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <AddCutOffDeadlineMain />);
      break;
    case `${Path.PREFERENCES}/${Path.PREFERENCES_MINIMUM_EMPTY_TRANSPORTS_REQUIRED_AT_CUSTOMER_SITE}`:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <MinimumEmptyTransport />);
      break;
    case Path.VAULT_CONFIGURATION:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <VaultConfigurationScreen />);
      break;
    case Path.EVENT_REQUEST_GENERATOR_DELIVERY:
      roleAccess.includes(role) && (returnElement = <EventRequestGenerator />);
      break;
    case Path.EVENT_REQUEST_GENERATOR_OTHER:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <EventRequestGeneratorOther />);
      break;
    case Path.VIEW_DR_MEDIA:
      roleAccess.includes(role) && (returnElement = <EventViewDrMedia />);
      break;
    case Path.EVENT:
      roleAccess.includes(role) && (returnElement = <Event />);
      break;
    case Path.EVENT_SEARCH:
      roleAccess.includes(role) && (returnElement = <EventSearch />);
      break;
    case Path.DISCREPANCY_MANAGEMENT_DETAIL:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <DiscrepancyManagementDetail />);
      break;
    case Path.DISCREPANCY_MANAGEMENT:
      multipleRole.some((value) => roleAccess.includes(value)) &&
        (returnElement = <DiscrepencySearchPage />);
      break;
    case Path.SERVICE_WINDOWS:
      roleAccess.includes(role) && (returnElement = <ServiceWindows />);
      break;
    case Path.INSTRUCTION_FILES:
    case Path.INSTRUCTION_FILES_CUSTOMER:
      roleAccess.includes(role) && (returnElement = <InstructionFiles />);
      break;
    case Path.LOGICAL_VAULT_DETAILS:
      roleAccess.includes(role) && (returnElement = <LogicalVaultDetails />);
      break;
    case `${Path.OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${Path.ROUTE_MANAGEMENT}`:
      roleAccess.includes(role) && (returnElement = <RouteManagement />);
      break;
    case `${Path.CORRECTIVE_ACTION_REQUESTS_DETAIL}`:
      roleAccess.includes(role) &&
        (returnElement = <CorrectiveActionRequest />);
      break;
    case `${Path.REPORTS_MODULE_BASE_PATH}`:
      returnElement = <Reports />;
      break;

    case Path.DISCREPANCY_MANAGEMENT_UPDATE:
      roleAccess.includes(role) && (returnElement = <DiscrepancyUpdate />);
      break;
    case Path.CUSTOMER_MOVE_SEARCH:
      roleAccess.includes(role) && (returnElement = <CustomerSearch />);
      break;
    case Path.CUSTOMER_MOVE_DETAIL:
      roleAccess.includes(role) && (returnElement = <CustomerMoveDetail />);
      break;
    case Path.VAULT_STATUS_REPORT:
      roleAccess.includes(role) && (returnElement = <VaultStatusReport />);
      break;
    case Path.NOTIFICATION_CONFIGURATION:
      roleAccess.includes(role) && (returnElement = <NotificationPage />);
      break;

    default:
      returnElement = <UnauthorizedPage />;
  }
  return returnElement ? returnElement : <UnauthorizedPage />;
}

export default RouteElement;
