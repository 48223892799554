import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import i18n from "i18n";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CloseFilledIcon from "assets/images/CloseFilled.svg";
import { CircularLoader } from "components/core";
import {
  useCheckDiscrepanciesMutation,
  useGetAllDeletableContainersMutation,
  useGetMessageByIdFromMessageMutation,
  useLoadMediaRequestDeleteContainersMutation,
  useValidateDeleteContainerNumberMutation
} from "services/api";
import {
  addDeletableContainer,
  removeDeletableContainer,
  removeErrorMessage,
  selectMediaRequest,
  setDeletableContainers,
  setErrorMessage
} from "store/slices";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  REQUEST_ACTIONS,
  Y_CHECKER
} from "utils/constants";
import { MESSAGE_20535 } from "utils/constants/request-module/MediaRequestDetailConstants";
import { limitFormikTextFieldLength } from "utils/helpers";
import { MediaRequestDetailFormStyles } from "../media-request-detail-form/MediaRequestDetailForm.Styles";
import { DeleteContainersStyles } from "./DeleteContainersTab.Styles";

// Columns definition of delete containers data grid view-only
const viewDeleteContainersColumnsDefinition = [
  {
    name: "number",
    label: i18n.t("mediaRequestDetail.containerNumberCaps")
  },
  {
    name: "descr",
    label: i18n.t("mediaRequestDetail.mediaTypeCaps")
  },
  {
    name: "exchange_status",
    label: i18n.t("mediaRequestDetail.statusCaps")
  }
];

const DeleteContainersTab = (props) => {
  const { t } = useTranslation();
  const { refetch, actionId, deletableContainers } =
    useSelector(selectMediaRequest); // select media request slice
  const dispatch = useDispatch(); // redux actions dispatcher

  const [
    getMessageByIdFromMessage,
    { isError: isMessageByIdError, error: messageByIdError }
  ] = useGetMessageByIdFromMessageMutation();

  const [
    getCheckDiscrepancies,
    {
      isLoading: checkingDiscrepencies,
      isError: isDiscrepanciesError,
      error: discrepanciesError
    }
  ] = useCheckDiscrepanciesMutation();

  // Columns definition of delete containers data grid
  const deleteContainersColumnsDefinition = [
    {
      name: "container_id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() => {
              dispatch(removeDeletableContainer(tableMeta.rowIndex));
              setDeleteAllChecked((prevState) =>
                prevState ? false : prevState
              );
            }}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="delete icon" />
          </IconButton>
        )
      }
    },
    {
      name: "number",
      label: t("mediaRequestDetail.containerNumberCaps")
    },
    {
      name: "descr",
      label: t("mediaRequestDetail.mediaTypeCaps")
    },
    {
      name: "exchange_status_type",
      label: t("mediaRequestDetail.statusCaps")
    }
  ];

  const [
    loadDeleteContainers,
    { isSuccess: deleteContainersFound, isLoading: loadingContainers }
  ] = useLoadMediaRequestDeleteContainersMutation();
  const [
    validateDeleteContainerNumber,
    { isLoading: validatingDeleteContainerNumber }
  ] = useValidateDeleteContainerNumberMutation();
  const [getAllDeletableContainers, { isLoading: loadingAllContainers }] =
    useGetAllDeletableContainersMutation();

  const [deleteAllChecked, setDeleteAllChecked] = useState(false);
  const deleteContainerForm = useFormik({
    initialValues: { number: "" },
    onSubmit: (values, formikHelpers) => {
      if (deleteContainerForm.isValid && !validatingDeleteContainerNumber) {
        if (
          deletableContainers.findIndex(
            (cont) => cont.number.trim() === values.number.trim()
          ) < 0
        ) {
          validateDeleteContainerNumber({
            customerId: props.customerId,
            containerNumber: values.number
          }).then((resp) => {
            if (resp.data.error) {
              deleteContainerForm.setFieldError("number", resp.data.error);
            } else {
              deleteContainerForm.resetForm();
              dispatch(addDeletableContainer(resp.data));
            }
          });
        } else {
          deleteContainerForm.setFieldError(
            "number",
            t("mediaRequestDetail.containerAlreadyExists")
          );
        }
      }
    }
  });

  // handle delete all containers action
  const handleDeleteAll = (e, checked) => {
    setDeleteAllChecked((prevState) => checked);
    if (checked) {
      getCheckDiscrepancies({ customerId: String(props.customerId) }).then(
        (res) => {
          if (res?.data[0]?.status === Y_CHECKER) {
            getMessageByIdFromMessage({ message_id: MESSAGE_20535 })
              .unwrap()
              .then((resp) => {
                dispatch(setErrorMessage(resp[0]?.descr));
              });
          } else {
            getAllDeletableContainers({ customerId: props.customerId })
              .unwrap()
              .then((resp) => dispatch(setDeletableContainers(resp)));
          }
        }
      );
    } else {
      dispatch(setDeletableContainers([]));
    }
  };

  // table options
  const dataTableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    tableBodyHeight: "230px",
    tableBodyMaxHeight: "230px",
    setRowProps: (row) => ({
      style: {
        height: "32px"
      }
    }),
    textLabels: {
      body: {
        noMatch:
          loadingAllContainers || loadingContainers ? (
            <CircularLoader
              loading={
                loadingAllContainers ||
                checkingDiscrepencies ||
                loadingContainers
              }
            />
          ) : (
            t("mediaRequestDetail.noItemsToDisplay")
          )
      }
    }
  };

  // fetch containers which requested to delete if a request_id passed as a prop
  useEffect(() => {
    if (
      (Boolean(props.requestId) &&
        actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID) ||
      refetch
    ) {
      loadDeleteContainers({ requestId: props.requestId }).then((resp) =>
        dispatch(setDeletableContainers(resp.data))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestId, actionId, refetch]);

  // set error message in redux if one occurs
  useEffect(() => {
    let message = "";
    if (isMessageByIdError) {
      message = `${t("mediaRequestDetail.errorInMessageId")} ${
        messageByIdError.message
      }`;
    } else if (isDiscrepanciesError) {
      message = `${t("mediaRequestDetail.errorDiscrepancies")} ${
        discrepanciesError.message
      }`;
    }

    // if message has set error message to redux, if not removed error message from redux
    if (message) {
      dispatch(setErrorMessage(message));
    } else {
      dispatch(removeErrorMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageByIdError]);
  return (
    <Grid id="deleteContainersGrid1" container>
      <Grid id="deleteContainersGrid12" item xs={12}>
        {/* Delete Container Card */}
        <Card id="deleteContainersCard1">
          <CardHeader
            id="deleteContainersCardHeader1"
            title={t("mediaRequestDetail.deleteContainers")}
          />
          <CardContent
            id="deleteContainersCardContent1"
            sx={DeleteContainersStyles.CardContent}
          >
            <Box id="deletecontainersBox1" sx={DeleteContainersStyles.Box4}>
              {/* container number field */}
              <TextField
                id="deleteContainerTabContainerNumber"
                disabled={deleteContainersFound}
                name="number"
                label={t("mediaRequestDetail.containerNumber")}
                required
                size="small"
                value={deleteContainerForm.values?.number}
                onChange={(event) =>
                  limitFormikTextFieldLength(deleteContainerForm, event, 18)
                }
                error={
                  deleteContainerForm.touched.number &&
                  Boolean(deleteContainerForm.errors.number)
                }
                helperText={deleteContainerForm.errors?.number}
                sx={{ width: "18.75rem" }}
              />

              {/* Validate & Add button */}
              <Button
                id="deleteContainersTabValidateAdd"
                disabled={
                  !deleteContainerForm.values?.number ||
                  deleteAllChecked ||
                  deleteContainersFound
                }
                variant="outlined"
                size="medium"
                onClick={deleteContainerForm.handleSubmit}
                sx={{ textTransform: "initial" }}
              >
                {validatingDeleteContainerNumber && (
                  <CircularProgress
                    color="primary"
                    sx={{
                      ...MediaRequestDetailFormStyles.ButtonCircularProgress,
                      marginRight: ".5rem"
                    }}
                  />
                )}
                {t("mediaRequestDetail.validateAndAdd")}
              </Button>

              {/* Delete all checkbox */}
              <FormControlLabel
                id="deleteContainersTabDeleteAll"
                disabled={loadingAllContainers || deleteContainersFound}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={deleteAllChecked}
                    onChange={handleDeleteAll}
                  />
                }
                label={t("mediaRequestDetail.deleteAllContainers")}
              />

              {deletableContainers.length !== 0 && (
                <Typography alignSelf="center" sx={{ ml: "auto" }}>
                  {deletableContainers.length}{" "}
                  {t("mediaRequestDetail.deleteContainersRowItems")}
                </Typography>
              )}
            </Box>
            <MUIDataTable
              id="deleteContainersTabDataGrid"
              columns={
                props.requestId
                  ? viewDeleteContainersColumnsDefinition
                  : deleteContainersColumnsDefinition
              }
              data={deletableContainers}
              options={dataTableOptions}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

// Props definition of the component
DeleteContainersTab.propTypes = {
  customerId: PropTypes.number.isRequired, // ID of the customer
  requestId: PropTypes.number // ID of the media request
};

export default DeleteContainersTab;
