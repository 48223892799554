import { Title } from "components/core";

function PageTitle({ title }) {
  return (
    <Title
      variant="pageTitle"
      text={title}
      styles={{ color: "var(--primary-main, #1E417A)" }}
    />
  );
}

export default PageTitle;
