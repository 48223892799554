/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton
} from "@mui/material";
import Button from "@mui/material/Button";
import ErrorIcon from "assets/images/warning-2.svg";

import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ClosedIcon } from "assets/images/CloseIcon.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { SearchField, SelectField } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import { ERROR_TYPES, messageCode } from "utils/constants";
import { regionDistrict } from "utils/constants/biling-module";
import { findErrorMessage } from "utils/helpers";
import DebouncedValue from "../../shared/Debounce";

const AddOrEditModal = (props) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState(null);
  const [pricingContract, setPricingContract] = useState([]);

  //error message showing states
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  //when loading keep form data
  const formloadingValues = useRef();

  const debouncedSearchText = DebouncedValue(searchText, 500);
  const searchTextRef = useRef();
  searchTextRef.current = searchText;

  //fetch not added pricing contract
  const fetchPricingContract = async (text) => {
    try {
      props.setIsLoadingPricingNew(true);
      var reqBody = JSON.stringify({
        main_district_id: regionDistrict,
        pricing_contract: text,
        isused: ""
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.capacityBillingConfigurationSearch.pricingContracts
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);

      const pricingContractReturn = data?.data[0];
      if (pricingContractReturn.length) {
        const singlePricingContract =
          await props.getSinglePricingContractDetail(text);
        if (
          singlePricingContract.length &&
          props.buttonClicked === props.buttonClick.new
        ) {
          const errorMsg = await getMessageFromCode(
            String(messageCode.alreadyExistPricingContract)
          );
          if (errorMsg) {
            const replacedSentence = errorMsg[0]?.descr.replace("|", text);
            props.setWarningMessagePopup(true);
            props.setWarningMessage(replacedSentence);
          }
        } else {
          props.SearchConfigurationForm.setFieldError(
            "row.pricingContract",
            false
          );
          props.setIsLoadingPricingNew(true);
          if (pricingContractReturn.length > 100) {
            const errorMsg = await getMessageFromCode(
              String(messageCode.recordLimitExeed)
            );
            if (errorMsg) {
              const message = errorMsg[0]?.descr.replace(
                "|",
                t("capacityBillingConfigurationSearch.pricingContractLimit")
              );
              props.setWarningMessagePopup(true);
              props.setWarningMessage(message);
            }
          }
          const mappedResponseData = pricingContractReturn.map(
            ({ pricing_contract_code }) => ({
              label: pricing_contract_code,
              value: pricing_contract_code
            })
          );
          setPricingContract(mappedResponseData);
          props.setIsLoadingPricingNew(false);
        }
      } else {
        const errorMsg = await getMessageFromCode(
          String(messageCode.pricingContractNotFound)
        );
        if (errorMsg) {
          props.SearchConfigurationForm.setFieldError(
            "row.pricingContract",
            errorMsg[0]?.descr
          );
        }
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      props.setIsLoadingPricingNew(false);
    }
  };

  //close modal
  const closeModal = () => {
    if (props.buttonClicked !== props.buttonClick.edit) {
      if (
        formloadingValues.current?.row[0].pricingContract !==
        props.SearchConfigurationForm.values?.row[0]?.pricingContract
      ) {
        props.setShowConfirm(true);
      } else {
        props.setIsVisible(false);
        props.setSelectedRowIndex(null);
        props.setWarningMessagePopup(false);
        props.setWarningMessage("");
      }
    } else {
      if (
        formloadingValues.current?.editedRow[0].startingGeneration !==
          props.SearchConfigurationForm.values?.editedRow[0]
            ?.startingGeneration ||
        formloadingValues.current?.editedRow[0].isActive !==
          props.SearchConfigurationForm.values.editedRow[0].isActive
      ) {
        props.setShowConfirm(true);
      } else {
        props.setIsVisible(false);
        props.setSelectedRowIndex(null);
      }
    }
  };

  //fetch pricing contact when search text changed
  useEffect(() => {
    setPricingContract([]);
    debouncedSearchText?.length > 0 &&
      fetchPricingContract(debouncedSearchText);
  }, [debouncedSearchText]);

  //when loaing form save form values
  useEffect(() => {
    formloadingValues.current = props.SearchConfigurationForm.values;
    props.SearchConfigurationForm.setFieldError("row.pricingContract", false);
  }, []);
  return (
    <CommonModal
      open={
        props.buttonClicked === props.buttonClick.new ||
        props.buttonClicked === props.buttonClick.edit
      }
      title={t(
        "capacityBillingConfigurationSearch.capacityBillingConfigurationDetailTitle"
      )}
      body={
        <Formik>
          <form>
            <Grid
              display={"flex"}
              flexDirection={"column"}
              gap={spacing.verticalMargin20}
            >
              <Grid container spacing={spacing.verticalMargin20}>
                {(props.throwErrorModal || throwError) && (
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Collapse in={props.throwErrorModal || throwError}>
                      <Alert
                        severity="error"
                        icon={<img src={ErrorIcon} alt="error" />}
                        action={
                          <IconButton
                            aria-label={t("common.close")}
                            color="inherit"
                            size="small"
                            onClick={() => {
                              props.setThrowErrorModal(false);
                              setThrowError(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {props.throwErrorMessageModal || throwErrorMessage}
                      </Alert>
                    </Collapse>
                  </Grid>
                )}

                {props.warningMessagePopup && (
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Collapse in={props.warningMessagePopup}>
                      <Alert
                        severity="warning"
                        icon={<img src={WarningIcon} alt="warning" />}
                        action={
                          <IconButton
                            aria-label={t("common.close")}
                            color="inherit"
                            size="small"
                            onClick={() => {
                              props.setWarningMessagePopup(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {props.warningMessage}
                      </Alert>
                    </Collapse>
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <SearchField
                    label={t(
                      "capacityBillingConfigurationSearch.pricingContractLabel"
                    )}
                    labelId="pricingContract"
                    options={pricingContract}
                    clearIcon={<ClosedIcon />}
                    popupIcon={<ArrowDown />}
                    value={
                      props.buttonClicked !== props.buttonClick.edit
                        ? props.SearchConfigurationForm.values.row[0]
                            ?.pricingContract
                        : props.SearchConfigurationForm.values.editedRow[0]
                            ?.pricingContract
                    }
                    isOptionEqualToValue={(option, value) => {
                      return option?.value === value;
                    }}
                    onInputChange={(event, newInputValue) => {
                      props.buttonClicked === props.buttonClick.new &&
                        props.SearchConfigurationForm.setFieldValue(
                          "row[0].pricingContract",
                          newInputValue
                        );
                      setSearchText(newInputValue);
                      props.setWarningMessagePopup(false);
                    }}
                    disabled={
                      props.buttonClicked === props.buttonClick.edit && true
                    }
                    error={
                      props.buttonClicked === props.buttonClick.new &&
                      !!props.SearchConfigurationForm?.errors?.row
                        ?.pricingContract
                    }
                    helperText={
                      props.buttonClicked === props.buttonClick.new &&
                      props.SearchConfigurationForm?.errors?.row
                        ?.pricingContract
                    }
                    loading={props.isLoadingPricingNew}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <SelectField
                    label={t(
                      "capacityBillingConfigurationSearch.catalogItemCodeLabel"
                    )}
                    labelId="catalogItemCode"
                    options={props.itemCode}
                    value={
                      props.buttonClicked !== props.buttonClick.edit
                        ? props.SearchConfigurationForm.values.row[0]
                            ?.catalogItemCode
                        : props.SearchConfigurationForm.values.editedRow[0]
                            ?.catalogItemCode
                    }
                    onChange={(event) => {
                      props.SearchConfigurationForm.setFieldValue(
                        props.buttonClicked !== props.buttonClick.edit
                          ? "row[0].catalogItemCode"
                          : "editedRow[0].catalogItemCode",
                        event.target.value
                      );
                    }}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <SearchField
                    options={props.capacityNew}
                    labelId="startingGeneration"
                    clearIcon={<ClosedIcon />}
                    popupIcon={<ArrowDown />}
                    label={t(
                      "capacityBillingConfigurationSearch.startingGenerationLabel"
                    )}
                    value={
                      props.buttonClicked !== props.buttonClick.edit
                        ? props.SearchConfigurationForm.values.row[0]
                            ?.startingGeneration
                        : props.SearchConfigurationForm.values.editedRow[0]
                            ?.startingGeneration
                    }
                    onChange={(event, value) => {
                      props.SearchConfigurationForm.setFieldValue(
                        props.buttonClicked !== props.buttonClick.edit
                          ? "row[0].startingGeneration"
                          : "editedRow[0].startingGeneration",
                        value
                      );
                    }}
                    error={
                      !!props.SearchConfigurationForm.errors.row
                        ?.startingGeneration
                    }
                    helperText={
                      props.SearchConfigurationForm.errors.row
                        ?.startingGeneration
                    }
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <FormControlLabel
                    id="isActiveLabel"
                    control={
                      <Checkbox
                        labelId="isActive"
                        size="small"
                        color="primary"
                        checked={
                          props.buttonClicked !== props.buttonClick.edit
                            ? props.SearchConfigurationForm.values.row[0]
                                ?.isActive
                            : props.SearchConfigurationForm.values.editedRow[0]
                                ?.isActive
                        }
                        onChange={(_, value) => {
                          props.SearchConfigurationForm.setFieldValue(
                            props.buttonClicked !== props.buttonClick.edit
                              ? "row[0].isActive"
                              : "editedRow[0].isActive",
                            value
                          );
                        }}
                      />
                    }
                    label={t("capacityBillingConfigurationSearch.activeLabel")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Formik>
      }
      buttons={
        <>
          {props.buttonClicked === props.buttonClick.new && (
            <>
              <Button variant="outlined" onClick={closeModal}>
                {t("common.cancel")}
              </Button>
              <Button variant="contained" onClick={props.handleInsert}>
                {t("common.addNew")}
              </Button>
            </>
          )}

          {props.buttonClicked === props.buttonClick.edit && (
            <>
              <Button variant="outlined" onClick={closeModal}>
                {t("common.cancelEdit")}
              </Button>
              <Button variant="contained" onClick={props.handleInsert}>
                {t("common.update")}
              </Button>
            </>
          )}
        </>
      }
    />
  );
};

export default AddOrEditModal;
