import { createTheme } from "@mui/material/styles";

export const datePickerFieldTheme = createTheme({
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          minHeight: "200px !important"
        }
      },
      slots: {}
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          height: "330px !important"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: "8px",
          borderColor: "var(--color-im-dark-blue-500)"
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent"
          },
          "&:active, &:focus": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "flex-end"
        }
      }
    }
  }
});
