import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import withLoading from "components/hoc/withLoading";
import { PageTitle } from "components/shared";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_MODULE_DESTRUCTION_BASE_PATH
} from "routing/Paths";
import {
  useCancelMDRVaultItemizedMutation,
  useCreateMDRVaultItemizedMutation,
  useGetAllDestructMediaTypesQuery,
  useGetExistingVaultItemizedMDRMutation,
  useGetPersonnelAuthQuery,
  useGetUnitOfMeasureQuery,
  useGetValidateChallengeResponseMutation,
  useUpdateMDRVaultItemizedMutation
} from "services/api";
import { getModuleSettings } from "services/api/query";
import {
  getLocaleByBranch,
  getMessageByIdFromMessage,
  getPersonnelSecurity
} from "services/common";
import { selectAuth } from "store/slices";
import {
  DAYJS_LOCALES,
  DEFAULT_LOCALE,
  ERROR_TYPES,
  SUPPORTED_LANGUAGES,
  SYSTEM_ID_SecureBase,
  Y_CHECKER
} from "utils/constants";
import {
  formatDate,
  formatDateByLocale,
  formatDateWithoutTime,
  generateSHA256Hash,
  getAuthenticatedUserBranch,
  getAuthenticatedUserId,
  getLanguageId
} from "utils/helpers";
import * as Yup from "yup";
import {
  COMMENT_LIMIT,
  MDRVaultItemizedStatusIds,
  NOT_ELIGIBLE_LABEL,
  ONE_SECOND_IN_MILLISECONDS,
  defaultDestructRequestId,
  destructRequestTypeIdForVaultItemized
} from "./Constants";
import { MDRVaultItemizedStyles } from "./MDRVaultItemizedStyles.Styles";
import InvalidMediasSnapBar from "./modals/InvalidMediasSnapBar";
import PersonnelAuthModal from "./modals/PersonnelAuthModal";
import PossibleMatchesModalRewrite from "./modals/PossibleMatchesModal";
import PrintPickModal from "./modals/PrintPickModal";
import DownloadReportModal from "./modals/PrintReportModal";
import WarningModalCommon from "./modals/WarningModalCommon";
import EmailAndWeightRewrite from "./sections/EmailAndWeight";
import OpenMediaSectionRewrite from "./sections/OpenMediaSection";
import PersonnelAuthenticationRewrite from "./sections/PersonnelAuthentication";
import ReadOnlyFieldsRewrite from "./sections/ReadOnlyFields";
import CustomerSelectionCommonRewrite from "./selections/CustomerSelectionVault";
import PersonnelSelectionCommonRewrite from "./selections/PersonnelSelectionVault";

const MDRVaultItemizedForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentBranch } = useSelector(selectAuth);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() || currentBranch?.district_id || ""
  );

  const vaultItemizedFormInitialVaules = {
    mdrId: "",
    mdrStatus: t("vaultItemized.new"),
    customer: "",
    requestedBy: "",
    emails: "",
    weight: "",
    unitOfMeasure: "",
    comment: "",
    authorizedBy: "",
    authorizedDate: "",
    rejectedBy: "",
    rejectedDate: "",
    loggedBy: "",
    loggedDate: "",
    pickDate: "",
    certifiedDate: "",
    cancelledBy: "",
    cancelledDate: "",
    medias: []
  };

  const initialTextFieldValues = [
    { label: t("vaultItemized.authorizedBy"), value: "" },
    { label: t("vaultItemized.authorizedDate"), value: "" },
    { label: t("vaultItemized.rejectedBy"), value: "" },
    { label: t("vaultItemized.rejectedDate"), value: "" },
    { label: t("vaultItemized.loggedBy"), value: "" },
    { label: t("vaultItemized.loggedDate"), value: "" },
    { label: t("vaultItemized.pickDate"), value: "" },
    { label: t("vaultItemized.certifiedDate"), value: "" },
    { label: t("vaultItemized.cancelledBy"), value: "" },
    { label: t("vaultItemized.cancelledDate"), value: "" }
  ];

  // Page controls

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isWeightUpdatable, setIsWeightUpdatable] = useState(false);
  const [prevComment, setPrevComment] = useState("");

  // Validation controls
  const [mdrStatus, setMdrStatus] = useState(MDRVaultItemizedStatusIds.edrsNew);
  const [isNewFormClick, setIsNewFormClick] = useState(false);

  // Form controls
  const [customerSelected, setCustomerSelected] = useState("");
  const [requestedBySelected, setRequestedBySelected] = useState("");

  const [personnelAuthDetails, setPersonnelAuthDetails] = useState([]);
  const [enteredAuthNum, setEnteredAuthNum] = useState("");
  const [enteredChallengeResponse, setEnteredChallengeResponse] = useState("");

  const [isAuthSuccess, setIsAuthSuccess] = useState(false);
  const [isAuthFail, setAuthFail] = useState(false);

  const [notifyEmail, setNotifyEmail] = useState("");
  const [pickListFlag, setPickListFlag] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [comment, setComment] = useState("");

  const [textFields, setTextFields] = useState(initialTextFieldValues);

  const [selectedMedia, setSelectedMedia] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [selectedRowsMedia, setSelectedRowsMedia] = useState([]);
  const [tableMedia, setTableMedia] = useState([]);
  const [mdrTableData, setMdrTableData] = useState([]);
  const [volserList, setVolserList] = useState([]);

  const [resetCalled, setResetCalled] = useState(false);

  const [invalidMedias, setInvalidMedias] = useState([]);
  const [cancelPersonnelId, setCancelPersonnelId] = useState("");

  const [weight, setWeight] = useState("");
  const [destructReqTimestamp, setDestructReqTimestamp] = useState("");
  const [unitOfMeasureObj, setUnitOfMeasureObj] = useState({
    label: "",
    value: ""
  });
  const [alreadyHasWeight, setAlreadyHasWeight] = useState(false);

  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [cancelButtonEnabled, setCancelButtonEnabled] = useState(false);
  const [printButtonEnabled, setPrintButtonEnabled] = useState(false);
  const [printPickButtonEnabled, setPrintPickButtonEnabled] = useState(false);

  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [cancelButtonClicked, setCancelButtonClicked] = useState(false);
  const [isCanUpdate, setIsCanUpdate] = useState(false);

  const [isError, setIsError] = useState(false);
  const [isErrorTop, setIsErrorTop] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isCommentChanged, setIsCommentChanged] = useState(false);
  const [fetchCalled, setFetchCalled] = useState(false);

  const [unitId, setUnitId] = useState("");
  const [onCancel, setOnCancel] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // modal controls
  const [possibleMatchesModalVisible, setPossibleMatchesModalVisible] =
    useState(false);
  const [showInvalidMediasSnapBar, setShowInvalidMediasSnapBar] =
    useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [PrintPickUpModalWarningVisible, setPrintPickUpModalWarningVisible] =
    useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [printPickModalVisible, setPrintPickModalVisible] = useState(false);

  const [isPersonnelAuthModalVisible, setIsPersonnelAuthModalVisible] =
    useState(false);

  const [isPickPrint, setIsPickPrint] = useState(false);
  const [pickListSizes, isPickListSizes] = useState([]);
  const [selectedPickListSize, setSelectedPickListSize] = useState("");
  const [validRowCount, setValidRowCount] = useState(0);
  const [isPickSizeDisabled, setIsPickSizeDisabled] = useState(false);

  // Formik
  const vaultItemizedValidationSchema = Yup.object().shape({
    customer: Yup.string().required(t("vaultItemized.commonRequiredError")),
    requestedBy: Yup.string().required(t("vaultItemized.commonRequiredError")),
    emails: Yup.string().required(t("vaultItemized.commonRequiredError"))
  });

  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  const handleSubmit = async (values, actions) => {
    setSaveButtonClicked(true);

    await handleSaveButtonClick();

    // Continue with Formik's default form submission logic
    actions.setSubmitting(false);
  };

  const mrdForm = useFormik({
    initialValues: vaultItemizedFormInitialVaules,
    validationSchema: vaultItemizedValidationSchema,
    onSubmit: handleSubmit
  });

  // Query
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: mrdForm.values?.requestedBy
  });
  const { data: units = [] } = useGetUnitOfMeasureQuery();
  const { data: mediasTypes = [] } = useGetAllDestructMediaTypesQuery({
    customerId: mrdForm.values?.customer
  });

  const loggedInEmployeeId = getAuthenticatedUserId();
  const destructRequestId = defaultDestructRequestId;
  const destructRequestTypeId = destructRequestTypeIdForVaultItemized;

  const [triggerMDRCreate] = useCreateMDRVaultItemizedMutation();
  const [triggerMDRUpdate] = useUpdateMDRVaultItemizedMutation();
  const [triggerMDRCancel] = useCancelMDRVaultItemizedMutation();
  const [triggerMDRFetch] = useGetExistingVaultItemizedMDRMutation();

  const languageLocaleId = getLanguageId();

  const systemId = SYSTEM_ID_SecureBase;

  /**
   * If the page url has an id, then the page is read only.
   * Depending on mdrStatus, the page can be updatable.
   */
  useEffect(() => {
    if (id === undefined) {
      setIsReadOnly(false);
      setIsWeightUpdatable(false);
      setPrintButtonEnabled(false);
    } else {
      // print pick button
      if (
        mdrStatus === MDRVaultItemizedStatusIds.edrsAuthorized ||
        mdrStatus === MDRVaultItemizedStatusIds.edrsInProcess ||
        mdrStatus === MDRVaultItemizedStatusIds.edrsScheduled ||
        mdrStatus === MDRVaultItemizedStatusIds.edrsCompleted
      ) {
        setPrintPickButtonEnabled(true);
      } else {
        setPrintPickButtonEnabled(false);
      }

      // print button
      setPrintButtonEnabled(true);
      setIsNewFormClick(false);
      setIsReadOnly(true);

      //for weight options
      if (
        (mdrStatus === MDRVaultItemizedStatusIds.edrsInProcess ||
          mdrStatus === MDRVaultItemizedStatusIds.edrsCompleted) &&
        weight === ""
      ) {
        setIsWeightUpdatable(true);
      } else {
        setIsWeightUpdatable(false);
      }
    }

    //updatable page validation
    if (id && mdrStatus !== MDRVaultItemizedStatusIds.edrsCancelled) {
      // setSaveButtonEnabled(true);
      setIsCanUpdate(true);
    } else {
      setIsCanUpdate(false);
    }

    // Cancel button validation
    if (
      mdrStatus !== MDRVaultItemizedStatusIds.edrsNew &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsCompleted &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsInProcess &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsCancelled &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsRejected &&
      pickListFlag !== Y_CHECKER
    ) {
      setCancelButtonEnabled(true);
    } else {
      setCancelButtonEnabled(false);
    }
    // eslint-disable-next-line
  }, [id, mdrStatus, mrdForm?.values?.status, isCanUpdate, triggerMDRFetch]);

  useEffect(() => {
    if (
      id &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsNew &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsCompleted &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsInProcess &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsCancelled &&
      mdrStatus !== MDRVaultItemizedStatusIds.edrsRejected
    ) {
      // validate all the medias. if all of them not eligible then get details again
      if (tableMedia?.length > 0) {
        const allItemsIncludeNotEligibleLabel =
          !tableMedia ||
          tableMedia.every((item) =>
            item?.item_status_descr?.includes(NOT_ELIGIBLE_LABEL)
          );

        if (
          allItemsIncludeNotEligibleLabel &&
          mdrStatus !== MDRVaultItemizedStatusIds.edrsNew &&
          mdrStatus !== MDRVaultItemizedStatusIds.edrsCancelled
        ) {
          setTimeout(() => {
            setFetchCalled(true);
          }, ONE_SECOND_IN_MILLISECONDS);
        }
      }
    }
  }, [id, mdrStatus, tableMedia]);

  useEffect(() => {
    if (!id) {
      newDoc();
      setIsNewFormClick(true);
      return;
    }
    // eslint-disable-next-line
  }, [id]);

  // validations for update comment and weight
  useEffect(() => {
    if (isCanUpdate) {
      const weightChanged = alreadyHasWeight
        ? false
        : weight !== "" && unitOfMeasureObj?.label !== "";
      const isDataChanged = prevComment !== comment || weightChanged;
      setSaveButtonEnabled(isDataChanged);

      if (prevComment !== comment) {
        setIsCommentChanged(true);
      } else {
        setIsCommentChanged(false);
      }
    }
  }, [
    isCanUpdate,
    prevComment,
    isWeightUpdatable,
    weight,
    comment,
    unitOfMeasureObj,
    alreadyHasWeight
  ]);

  useEffect(() => {
    if (personnelAuth?.length > 0) {
      setPersonnelAuthDetails(personnelAuth);
    }

    if (personnelAuth) {
      mrdForm.handleChange({
        target: {
          name: "challengeQuestion",
          value: personnelAuth[0]?.secret_question
        }
      });
    }
    // eslint-disable-next-line
  }, [personnelAuth]);

  useEffect(() => {
    setVolserList(tableMedia?.map((item) => item.volser));
  }, [tableMedia]);

  // save button validation
  useEffect(() => {
    if (!id) {
      setIsCanUpdate(false);
      const hasItemNotEligible = tableMedia?.some(
        (item) => !item?.item_status_descr?.includes(NOT_ELIGIBLE_LABEL)
      );

      if (hasItemNotEligible && emailValid) {
        //At least one object has item_status_descr not containing 'Not Eligible'
        setSaveButtonEnabled(true);
      } else {
        setSaveButtonEnabled(false);
      }
    }
    // eslint-disable-next-line
  }, [tableMedia, emailValid, id]);

  // saving and updating mdr
  useEffect(() => {
    if (saveButtonClicked && !isCanUpdate) {
      const fetchData = async () => {
        try {
          const response = await triggerMDRCreate({
            mainDistrictId,
            customerId: customerSelected?.value,
            requestedPersonnelId: requestedBySelected?.value,
            volserList,
            imComment: comment,
            businessEmailAddr: notifyEmail,
            loggedInEmployeeId,
            destructRequestId,
            destructRequestTypeId,
            saveButtonClicked,
            isCanUpdate
          });

          if (response?.data) {
            setSaveButtonClicked(false);

            if (response?.data[0]?.error) {
              setSaveButtonClicked(false);
              setErrorMessage(findErrorMessage(response?.data[0]?.error));
              setIsError(true);
            } else {
              setErrorMessage("");
              setIsError(false);
            }

            //navigate to the id page
            if (response?.data[0]?.destruct_request_id) {
              const pageId = response?.data[0]?.destruct_request_id;
              navigate(
                `${REQUEST_MODULE_BASE_PATH}/${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED}/${pageId}`
              );
            }
          } else if (!response?.data) {
            setSaveButtonClicked(false);

            setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            setIsError(true);
          }
        } catch (e) {
          setSaveButtonClicked(false);

          setErrorMessage(findErrorMessage(ERROR_TYPES.EXCEPTION));
          setIsError(true);
        }
      };

      fetchData();
    } else if (saveButtonClicked && isCanUpdate) {
      //update
      const fetchData = async () => {
        try {
          const response = await triggerMDRUpdate({
            mainDistrictId,
            imComment: isCommentChanged ? comment : null,
            loggedInEmployeeId,
            unitOfMeasureId: unitOfMeasureObj?.value,
            destructReqTimestamp,
            destructRequestId: id,
            weight,
            systemId,
            saveButtonClicked,
            isCanUpdate
          });

          if (response?.data) {
            setSaveButtonClicked(false);

            if (response?.data[0]?.error) {
              setSaveButtonClicked(false);

              setErrorMessage(findErrorMessage(response?.data[0]?.error));
              setIsError(true);
            } else {
              setErrorMessage("");
              setIsError(false);

              setFetchCalled(true);
            }
          } else if (!response?.data) {
            setSaveButtonClicked(false);

            setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            setIsError(true);
          }
        } catch (e) {
          setSaveButtonClicked(false);

          setErrorMessage(findErrorMessage(ERROR_TYPES.EXCEPTION));
          setIsError(true);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [saveButtonClicked, triggerMDRCreate, triggerMDRUpdate]);

  // populate existing MDR details
  useEffect(() => {
    if (id && mainDistrictId !== "" && isReadOnly) {
      fetchDataExistingMDR(id);
    }
    // eslint-disable-next-line
  }, [mainDistrictId, id, isReadOnly]);

  useEffect(() => {
    if (fetchCalled && id && mainDistrictId !== "" && isReadOnly) {
      fetchDataExistingMDR(id);
    }
    // eslint-disable-next-line
  }, [fetchCalled, triggerMDRFetch, id, mainDistrictId, isReadOnly]);

  // fetch and return locale of the selected branch
  const fetchBranchLocale = async () => {
    const branchLocale = await getLocaleByBranch();
    if (branchLocale) {
      const languageCode =
        branchLocale[0]?.iso_locale_code ||
        SUPPORTED_LANGUAGES?.ENGLISH_US?.code;
      return DAYJS_LOCALES[languageCode] || DEFAULT_LOCALE;
    }
    return DEFAULT_LOCALE;
  };

  const getPickListSizes = async () => {
    setIsLoading(true);
    try {
      const results = await getModuleSettings();

      if (results) {
        const xmlString = results[0]?.settings_xml;

        // Parse the XML string
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "text/xml");

        // Extract the value of splitDestructPickListValues
        const pickListValue = xmlDoc.getElementsByTagName(
          "splitDestructPickListValues"
        )[0].textContent;

        // Convert the value to an array
        const splitPickListSizes = pickListValue.split(",").map((e) => {
          return { label: e, value: e };
        });
        isPickListSizes(splitPickListSizes);
        setSelectedPickListSize(splitPickListSizes[0]);

        setIsPickSizeDisabled(
          validRowCount <= Number(splitPickListSizes[0]?.value)
        );
      }
    } catch (e) {
      setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataExistingMDR = async (idx) => {
    setIsLoading(true);
    try {
      const response = await triggerMDRFetch({
        mainDistrictId,
        destructRequestId: idx,
        languageLocaleId,
        systemId,
        isReadOnly,
        customerID: customerSelected?.value
      });

      if (response?.data) {
        setFetchCalled(false);
        if (!response?.data[0]?.error) {
          resetForm();
          response.currentData = [];
          const localeByBranch = await fetchBranchLocale(); // locale of the selected branch

          const mdrDetails = response?.data[0];
          const mdrVolsers = response?.data[1];

          let existMdrStatus = mdrDetails?.destruct_request_status_descr || "";
          if (mdrDetails?.destruct_cancel_reason_descr !== "") {
            existMdrStatus = `${existMdrStatus} - ${mdrDetails?.destruct_cancel_reason_descr}`;
          }

          const mappedDetails = {
            mdrId: mdrDetails?.destruct_request_id || "",
            mdrStatus: existMdrStatus || "",
            customer: mdrDetails?.customer_id || "",
            requestedBy: mdrDetails?.requested_personnel_name || "",
            emails: mdrDetails?.requestor_email_addr || "",
            weight: mdrDetails?.bulk_weight || "",
            unitOfMeasure: mdrDetails?.unit_of_measure_id || "",
            comment: mdrDetails?.im_comment || "",
            authorizedBy: mdrDetails?.authorized_personnel_name || "",
            authorizedDate:
              mdrDetails?.authorized_datetime_ltz !== ""
                ? formatDate(mdrDetails?.authorized_datetime_ltz)
                : "",
            rejectedBy: mdrDetails?.rejected_personnel_name,
            rejectedDate:
              mdrDetails?.rejected_datetime_ltz !== ""
                ? formatDate(mdrDetails?.rejected_datetime_ltz)
                : "",
            loggedBy: mdrDetails?.logged_employee_name,
            loggedDate:
              mdrDetails?.requested_datetime_ltz !== ""
                ? formatDate(mdrDetails?.requested_datetime_ltz)
                : "",
            pickDate:
              mdrDetails?.completed_datetime_ltz !== ""
                ? formatDate(mdrDetails?.completed_datetime_ltz)
                : "",
            certifiedDate:
              mdrDetails?.certified_datetime_ltz !== ""
                ? formatDateWithoutTime(mdrDetails?.certified_datetime_ltz)
                : "",
            cancelledBy: mdrDetails?.cancelled_personnel_name,
            cancelledDate:
              mdrDetails?.cancelled_datetime_ltz !== ""
                ? formatDate(mdrDetails?.cancelled_datetime_ltz)
                : "",
            medias: mdrVolsers
          };

          mrdForm.setValues({
            ...vaultItemizedFormInitialVaules,
            ...mappedDetails
            // additional values from the API response if needed
          });

          setDestructReqTimestamp(mdrDetails?.destruct_req_timestamp || "");

          if (mdrDetails?.unit_of_measure_id !== "") {
            setAlreadyHasWeight(true);
          } else {
            setAlreadyHasWeight(false);
          }

          handleSetUnitOfMeasureObj(mdrDetails?.unit_of_measure_id || "");
          setUnitId(mdrDetails?.unit_of_measure_id || "");

          setWeight(mdrDetails?.bulk_weight || "");

          // set status
          getStatusById(mdrDetails?.destruct_request_status_id);

          setCustomerSelected(mdrDetails?.customer_id || "");
          setRequestedBySelected(mdrDetails?.requested_personnel_name || "");

          //set pick list Flag
          setPickListFlag(mdrDetails?.pick_list_printed_flag || "");
          setNotifyEmail(mdrDetails?.requestor_email_addr || "");
          // set weight and unit of measure
          setComment(mdrDetails?.im_comment || "");
          setPrevComment(mdrDetails?.im_comment || "");
          //set read only values
          const updatedTextFields = await Promise.all(
            textFields?.map(async (field) => {
              switch (field.label) {
                case t("vaultItemized.authorizedBy"):
                  return {
                    ...field,
                    value: mdrDetails?.authorized_personnel_name
                  };
                case t("vaultItemized.authorizedDate"):
                  return {
                    ...field,
                    value:
                      mdrDetails?.authorized_datetime_ltz !== ""
                        ? await formatDateByLocale(
                            mdrDetails?.authorized_datetime_ltz,
                            localeByBranch
                          )
                        : ""
                  };
                case t("vaultItemized.rejectedBy"):
                  return {
                    ...field,
                    value: mdrDetails?.rejected_personnel_name
                  };
                case t("vaultItemized.rejectedDate"):
                  return {
                    ...field,
                    value:
                      mdrDetails?.rejected_datetime_ltz !== ""
                        ? await formatDateByLocale(
                            mdrDetails?.rejected_datetime_ltz,
                            localeByBranch
                          )
                        : ""
                  };
                case t("vaultItemized.loggedBy"):
                  return {
                    ...field,
                    value: mdrDetails?.logged_employee_name
                  };
                case t("vaultItemized.loggedDate"):
                  return {
                    ...field,
                    value:
                      mdrDetails?.requested_datetime_ltz !== ""
                        ? await formatDateByLocale(
                            mdrDetails?.requested_datetime_ltz,
                            localeByBranch
                          )
                        : ""
                  };
                case t("vaultItemized.pickDate"):
                  return {
                    ...field,
                    value:
                      mdrDetails?.completed_datetime_ltz !== ""
                        ? await formatDateByLocale(
                            mdrDetails?.completed_datetime_ltz,
                            localeByBranch
                          )
                        : ""
                  };
                case t("vaultItemized.certifiedDate"):
                  return {
                    ...field,
                    value:
                      mdrDetails?.certified_datetime_ltz !== ""
                        ? await formatDateByLocale(
                            mdrDetails?.certified_datetime_ltz,
                            localeByBranch,
                            false
                          )
                        : ""
                  };
                case t("vaultItemized.cancelledBy"):
                  return {
                    ...field,
                    value: mdrDetails?.cancelled_personnel_name
                  };
                case t("vaultItemized.cancelledDate"):
                  return {
                    ...field,
                    value:
                      mdrDetails?.cancelled_datetime_ltz !== ""
                        ? await formatDateByLocale(
                            mdrDetails?.cancelled_datetime_ltz,
                            localeByBranch
                          )
                        : ""
                  };

                default:
                  return field;
              }
            })
          );
          setTextFields((prevState) => updatedTextFields);

          setMdrTableData(mdrVolsers);

          // Get the valid row number
          if (mdrVolsers) {
            const validRows = mdrVolsers?.filter((val) => {
              return !val?.destruct_item_status_descr?.includes(
                t("vaultItemized.notEligibleNotFound")
              );
            });

            setValidRowCount(validRows?.length || 0);
          }
        } else if (response?.data[0]?.error) {
          setErrorMessage(findErrorMessage(response?.data[0]?.error));
          setIsErrorTop(true);
        } else {
          setErrorMessage("");
          setIsErrorTop(false);
        }
      } else if (!response?.data) {
        setFetchCalled(false);

        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setIsErrorTop(true);
      }
    } catch (e) {
      setFetchCalled(false);
      setErrorMessage(findErrorMessage(ERROR_TYPES.EXCEPTION));
      setIsErrorTop(true);
    } finally {
      getPickListSizes();
    }
  };

  useEffect(() => {
    if (errorMessage === "") {
      setIsErrorTop(false);
      setIsError(false);
    }
  }, [isErrorTop, errorMessage]);

  useEffect(() => {
    if (units?.length > 0) {
      handleSetUnitOfMeasureObj(unitId);
    }
    // eslint-disable-next-line
  }, [units, unitId]);

  // cancelling
  useEffect(() => {
    if (cancelButtonClicked) {
      const fetchData = async () => {
        try {
          const response = await triggerMDRCancel({
            mainDistrictId,
            cancelPersonnelId,
            loggedInEmployeeId,
            destructRequestId: id ? id : "",
            cancelButtonClicked
          });

          if (response?.data) {
            setCancelButtonClicked(false);

            if (response?.data[0]?.error) {
              setErrorMessage(findErrorMessage(response?.data[0]?.error));
              setIsError(true);
            } else {
              setIsError(false);
              setErrorMessage("");
            }

            setFetchCalled(true);
          } else if (!response?.data) {
            setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            setIsError(true);
          }
        } catch (e) {
          setErrorMessage(findErrorMessage(ERROR_TYPES.EXCEPTION));
          setIsError(true);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [cancelButtonClicked, cancelPersonnelId, triggerMDRCancel]);

  const handleCustomerChange = (event, newValue) => {
    if (newValue) {
      setIsNewFormClick(false);
      setCustomerSelected(newValue);
      mrdForm.handleChange({
        target: { name: "customer", value: newValue?.value }
      });
    }
  };

  const handleRequestedByChange = (event, newValue) => {
    setRequestedBySelected(newValue || "");
    mrdForm.handleChange({
      target: { name: "requestedBy", value: newValue?.value || "" }
    });

    setEnteredAuthNum("");
    setEnteredChallengeResponse("");
    setIsAuthSuccess(false);
    setAuthFail(false);

    setNotifyEmail("");
    mrdForm.handleChange({
      target: { name: "emails", value: "" }
    });
  };

  const handleAuthenticate = async () => {
    // generate hash code
    let validateChallengeResponse;
    if (enteredChallengeResponse.length > 0) {
      let hashCode = await generateSHA256Hash(
        enteredChallengeResponse.trim().toUpperCase()
      );

      //challenge response validation
      validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: mainDistrictId,
        language_locale_id: languageLocaleId,
        personnel_id: mrdForm.values?.requestedBy,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          const error = data && data[0]?.error;
          //checking for the error
          if (error) {
            setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            setIsErrorTop(true);
          } else {
            return data[0].is_match_flag;
          }
        })
        .catch((e) => {
          setErrorMessage(findErrorMessage(ERROR_TYPES.EXCEPTION));
          setIsErrorTop(true);
        });
    }

    if (
      (personnelAuthDetails[0]?.auth_number !== "" &&
        personnelAuthDetails[0]?.auth_number === enteredAuthNum) ||
      (personnelAuthDetails[0]?.secret_answer !== "" &&
        validateChallengeResponse === Y_CHECKER)
    ) {
      setIsLoading(true);
      const personalSecurity = await getPersonnelSecurity({
        mainDistrictId,
        customerId: customerSelected.value,
        personnelId: mrdForm.values?.requestedBy,
        userLogin: personnelAuthDetails[0]?.business_email_addr,
        getPersonnelSecurity: true
      });

      if (
        personalSecurity[0]?.result &&
        personalSecurity[0]?.result === Y_CHECKER
      ) {
        setIsAuthSuccess(true);
        setAuthFail(false);

        mrdForm.handleChange({
          target: {
            name: "emails",
            value: personnelAuthDetails[0]?.business_email_addr
          }
        });

        setNotifyEmail(personnelAuthDetails[0]?.business_email_addr || "");
      } else {
        const message = await getMessageByIdFromMessage({
          message_id: "20468"
        });

        if (message && message[0]?.descr) {
          setIsErrorTop(true);
          setErrorMessage(message[0].descr);
          window.scrollTo({ top: 0 });
          setRequestedBySelected("");
          setIsAuthSuccess(false);
          setAuthFail(true);
        }
      }
      setIsLoading(false);
    } else {
      setIsAuthSuccess(false);
      setAuthFail(true);
    }
    setIsLoading(false);
  };

  const handleEmailChange = (newValue) => {
    setNotifyEmail(newValue || "");
    mrdForm.handleChange({
      target: { name: "emails", value: newValue }
    });
  };

  const handleCommentChange = (event) => {
    const newComment = event.target.value;

    // Check if the new comment exceeds the character limit
    if (newComment.length > COMMENT_LIMIT) {
      const truncatedComment = newComment?.slice(0, COMMENT_LIMIT);

      setComment(truncatedComment);

      mrdForm.handleChange({
        target: { name: "comment", value: truncatedComment }
      });
    } else {
      setComment(newComment);

      mrdForm.handleChange({
        target: { name: "comment", value: newComment }
      });
    }
  };

  const onClickHandlerMainTableEye = (media) => {
    setSelectedMedia(media);

    const list = mediasTypes?.filter((item) =>
      String(item.volser)?.includes(String(media.volser))
    );

    setFilteredList(list);
    setPossibleMatchesModalVisible(true);
  };

  const handleSaveButtonClick = async () => {
    if (id && !isCanUpdate) {
      navigate(
        `${REQUEST_MODULE_BASE_PATH}/${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED}/${id}`
      );
    }
  };

  const handleNewButtonClick = () => {
    if (id) {
      newDoc();
      setIsNewFormClick(true);
      return;
    }

    if (!id) {
      // check if the table has at least one value. then open the warning model
      if (tableMedia?.length > 0 || isAuthSuccess || comment !== "") {
        setWarningMessage(t("common.saveChangesNow"));
        setWarningModalVisible(true);
      } else {
        setIsNewFormClick(true);
        newDoc();
      }
      return;
    }
  };

  const newDoc = () => {
    setErrorMessage("");
    setIsError(false);
    setIsErrorTop(false);

    setUnitOfMeasureObj({
      label: "",
      value: ""
    });
    setWeight("");

    setResetCalled(true);

    setTimeout(() => {
      setResetCalled(false);
    }, ONE_SECOND_IN_MILLISECONDS);

    resetForm();
    if (id) {
      navigate(
        `${REQUEST_MODULE_BASE_PATH}/${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED}`
      );
    } else {
      setIsReadOnly(true);
      setTimeout(() => {
        setIsReadOnly(false);
      }, ONE_SECOND_IN_MILLISECONDS);
    }
  };

  const handleCancelButtonClick = () => {
    setWarningMessage(t("vaultItemized.cancelWarning"));
    setWarningModalVisible(true);
    setOnCancel(true);
  };

  const handleWarningModalYes = () => {
    setWarningModalVisible(false);
    setWarningMessage("");

    if (onCancel) {
      setIsPersonnelAuthModalVisible(true);
      setOnCancel(false);
    } else {
      newDoc();
    }
  };

  const handleWarningModalNo = () => {
    setWarningMessage("");
    setWarningModalVisible(false);
    setOnCancel(false);
  };

  const handleWarningPrintPickUpModalNo = () => {
    setPrintPickUpModalWarningVisible(false);
  };

  const handleWarningPrintPickUpModalYes = () => {
    setIsPickPrint(true);
    setPrintModalVisible(true);
    setPrintPickModalVisible(false);
    setPrintPickUpModalWarningVisible(false);
  };
  const resetReadOnlyValues = () => {
    setTextFields(
      initialTextFieldValues.map((field) => ({ ...field, value: "" }))
    );
  };

  const handleSetUnitOfMeasureObj = (unitID) => {
    if (unitID !== "") {
      setUnitOfMeasureObj(
        {
          label: units?.filter((item) => item?.value === unitID)[0]?.label,
          value: unitID
        } || { label: "", value: "" }
      );
    }
  };

  // common print modal requirements - handleClose
  const handleClosePrintModal = () => {
    setPrintModalVisible(false);
  };

  const getStatusById = (id) => {
    switch (Number(id)) {
      case 0:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsNew);
        break;
      case 1:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsPendingConfirmation);
        break;
      case 2:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsRejected);
        break;
      case 3:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsAuthorized);
        break;
      case 4:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsScheduled);
        break;
      case 5:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsCancelled);
        break;
      case 6:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsInProcess);
        break;
      case 7:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsCompleted);
        break;
      case 8:
        setMdrStatus(MDRVaultItemizedStatusIds.edrsPendingReview);
        break;
      default:
        break;
    }
  };

  const resetForm = () => {
    mrdForm.resetForm();

    resetReadOnlyValues();
    setMdrStatus(MDRVaultItemizedStatusIds.edrsNew);
    setCustomerSelected("");
    setRequestedBySelected("");
    setPersonnelAuthDetails([]);
    setEnteredAuthNum("");
    setEnteredChallengeResponse("");
    setIsAuthSuccess(false);
    setAuthFail(false);
    setNotifyEmail("");
    setPickListFlag("");
    setEmailValid(false);
    setComment("");
    setTextFields(initialTextFieldValues);
    setSelectedMedia("");
    setFilteredList([]);
    setSelectedRowsMedia([]);
    setTableMedia([]);
    setMdrTableData([]);
    setVolserList([]);

    setPossibleMatchesModalVisible(false);
    setShowInvalidMediasSnapBar(false);
    setInvalidMedias([]);
    setSaveButtonClicked(false);

    setResetCalled(true);
  };

  const onPrintPick = () => {
    setIsPickPrint(true);
    setPrintModalVisible(true);
  };

  const findErrorMessage = (error) => {
    let relatedErrorMessage = "";

    switch (error) {
      case ERROR_TYPES.FAILED:
        relatedErrorMessage = t("vaultItemized.failedError");
        break;
      case ERROR_TYPES.EXCEPTION:
        relatedErrorMessage = t("vaultItemized.errorOccurred");
        break;
      case ERROR_TYPES.ISSUE:
        relatedErrorMessage = t("vaultItemized.somethingWrong");
        break;
      default:
        relatedErrorMessage = error;
        break;
    }

    return relatedErrorMessage;
  };

  // Props
  const CustomerSelectionCommonRewriteProps = {
    id,
    customerSelected,
    handleCustomerChange,
    mrdForm,
    isReadOnly,
    mainDistrictId
  };

  const PersonnelSelectionCommonRewriteProps = {
    customerSelected,
    isReadOnly,
    requestedBySelected,
    handleRequestedByChange,
    mrdForm,
    id,
    isAuthSuccess
  };

  const PersonnelAuthenticationRewriteProps = {
    personnelAuthDetails,
    mrdForm,
    customerSelected,
    handleAuthenticate,
    enteredAuthNum,
    setEnteredAuthNum,
    enteredChallengeResponse,
    setEnteredChallengeResponse,
    isAuthFail
  };

  const EmailAndWeightRewriteProps = {
    requestedBySelected,
    isReadOnly,
    notifyEmail,
    handleEmailChange,
    isAuthSuccess,
    mrdForm,
    units,
    isWeightUpdatable,
    setEmailValid,
    unitOfMeasureObj,
    setUnitOfMeasureObj,
    weight,
    setWeight
  };

  const ReadOnlyFieldsRewriteProps = {
    textFields,
    customerSelected
  };

  const OpenMediaTableRewriteProps = {
    customerSelected,
    onClickHandler: onClickHandlerMainTableEye,
    selectedRowsMedia,
    selectedMedia,
    isReadOnly,
    setTableMedia,
    mdrTableData,
    resetCalled,
    setInvalidMedias,
    setShowInvalidMediasSnapBar,
    isNewFormClick,
    mainDistrictId
  };

  const PossibleMatchesModalRewriteProps = {
    possibleMatchesModalVisible,
    setPossibleMatchesModalVisible,
    selectedMedia,
    filteredList,
    setSelectedRowsMedia,
    tableMedia,
    customerSelected,
    mainDistrictId
  };

  const InvalidMediasSnapBarProps = {
    setShowInvalidMediasSnapBar,
    invalidMedias
  };

  const WarningModalCommonRewriteProps = {
    title: t("vaultItemized.confirm"),
    modalPopupOpen: warningModalVisible,
    handleClick: () => handleWarningModalNo(),
    alertMessage: warningMessage,
    modalButton: [
      <Button
        size="small"
        variant="outlined"
        onClick={handleWarningModalNo}
        key={t("vaultItemized.close")}
      >
        {t("vaultItemized.close")}
      </Button>,
      <Button
        size="small"
        variant="contained"
        onClick={handleWarningModalYes}
        key={t("vaultItemized.yes")}
      >
        {t("vaultItemized.yes")}
      </Button>
    ]
  };

  const splitPickListWarningProps = {
    title: t("vaultItemized.confirm"),
    modalPopupOpen: PrintPickUpModalWarningVisible,
    handleClick: () => handleWarningPrintPickUpModalNo(),
    alertMessage: t("vaultItemized.splitPickListWarning"),
    modalButton: [
      <Button
        size="small"
        variant="outlined"
        onClick={handleWarningPrintPickUpModalNo}
        key={t("common.no")}
      >
        {t("common.no")}
      </Button>,
      <Button
        size="small"
        variant="contained"
        onClick={handleWarningPrintPickUpModalYes}
        key={t("common.yes")}
      >
        {t("common.yes")}
      </Button>
    ]
  };

  const handlePickPrint = (value) => {
    setPrintPickUpModalWarningVisible(value);
  };

  const PersonnelAuthModalProps = {
    customerSelected,
    isPersonnelAuthModalVisible,
    setIsPersonnelAuthModalVisible,
    setCancelButtonClicked,
    setCancelPersonnelId,
    mainDistrictId
  };

  const PrintPickModalCommonProps = {
    open: printPickModalVisible,
    setOpen: setPrintPickModalVisible,
    handlePickPrint,
    pickListSizes,
    selectedPickListSize,
    setSelectedPickListSize,
    validRowCount,
    isPickSizeDisabled
  };

  return (
    <form onSubmit={mrdForm.handleSubmit}>
      {isLoading && (
        <CircularLoaderFullPage
          id="MDRPickupFullPageLoader"
          loading={isLoading}
        />
      )}

      <Stack direction="column" gap="20px" marginTop="20px">
        {/* ------------------- Page Title ------------------- */}

        <Stack
          direction="row"
          sx={MDRVaultItemizedStyles.MDRVaultItemizedForm_Stack1}
        >
          <PageTitle variant="h4" title={t("vaultItemized.pageTitle")} />

          {id && (
            <Typography
              variant="h5"
              sx={MDRVaultItemizedStyles.MDRVaultItemizedForm_Id}
            >
              {t("vaultItemized.destructionId")}
              {id}
            </Typography>
          )}
        </Stack>

        {isErrorTop && (
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                  setIsErrorTop(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        )}

        {/* ------------------- Customer selection------------------- */}
        <Stack direction="row" gap="20px">
          <Box sx={MDRVaultItemizedStyles.MDRVaultItemizedForm_Customer}>
            <CustomerSelectionCommonRewrite
              {...CustomerSelectionCommonRewriteProps}
            />
          </Box>

          {/* ------------------- MDR Status ------------------- */}
          <Box>
            <TextField
              id="status"
              disabled={customerSelected === ""}
              label={t("vaultItemized.statusLabel")}
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              style={MDRVaultItemizedStyles.MDRVaultItemizedForm_Status}
              value={mrdForm.values?.mdrStatus}
              InputLabelProps={
                MDRVaultItemizedStyles.MDRVaultItemizedForm_InputLabelProps
              }
            />
          </Box>
        </Stack>

        <Typography variant="h5">
          {t("vaultItemized.personnelAuthentication")}
        </Typography>

        {/* ------------------- Requested By (Personnel) selection ------------------- */}
        <PersonnelSelectionCommonRewrite
          {...PersonnelSelectionCommonRewriteProps}
        />

        {/* ------------------- Personnel Authentication ------------------- */}
        {requestedBySelected !== "" && !isAuthSuccess && !isReadOnly && (
          <PersonnelAuthenticationRewrite
            {...PersonnelAuthenticationRewriteProps}
          />
        )}

        <Divider />

        {/* ------------------- Email and Weight section ------------------- */}
        <EmailAndWeightRewrite {...EmailAndWeightRewriteProps} />

        {/* ------------------- Read Only Fields ------------------- */}
        {
          //   isReadOnly &&
          <ReadOnlyFieldsRewrite {...ReadOnlyFieldsRewriteProps} />
        }

        {/* ------------------- Comment section ------------------- */}
        <Stack gap="20px">
          <Typography variant="h5">
            {t("vaultItemized.requestComments")}
          </Typography>

          <TextField
            disabled={
              customerSelected === "" ||
              mdrStatus === MDRVaultItemizedStatusIds.edrsCancelled ||
              mdrStatus === MDRVaultItemizedStatusIds.edrsCompleted
            }
            label={t("vaultItemized.ironMountainComments")}
            id="ironMountainComments"
            variant="outlined"
            multiline
            rows={4}
            sx={MDRVaultItemizedStyles.MDRVaultItemizedForm_Comment}
            value={comment}
            onChange={handleCommentChange}
            InputLabelProps={
              MDRVaultItemizedStyles.MDRVaultItemizedForm_InputLabelProps
            }
          />
        </Stack>

        {/* ------------------- Open media section ------------------- */}

        {/* prettier-ignore */}

        {showInvalidMediasSnapBar && (
          <InvalidMediasSnapBar {...InvalidMediasSnapBarProps} />
        )}

        <OpenMediaSectionRewrite {...OpenMediaTableRewriteProps} />

        <Divider />

        {isError && (
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                  setIsError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        )}

        {/* ------------------- Buttons section ------------------- */}
        <Stack
          direction="row"
          gap="20px"
          sx={MDRVaultItemizedStyles.MDRVaultItemizedForm_BtnStack}
        >
          <Button
            id="cancelBtn"
            variant="outlined"
            disabled={!cancelButtonEnabled}
            style={MDRVaultItemizedStyles.MDRVaultItemizedForm_Btn}
            onClick={handleCancelButtonClick}
          >
            {t("vaultItemized.cancel")}
          </Button>
          <Button
            id="reprintPickReportBtn"
            variant="outlined"
            disabled={!printPickButtonEnabled}
            style={MDRVaultItemizedStyles.MDRVaultItemizedForm_Btn}
            onClick={() => {
              pickListFlag === Y_CHECKER
                ? onPrintPick()
                : setPrintPickModalVisible(true);
            }}
          >
            {pickListFlag === Y_CHECKER
              ? t("vaultItemized.rePrintPickRpt")
              : t("vaultItemized.printPickRpt")}
          </Button>
          <Button
            id="printBtn"
            variant="outlined"
            disabled={!printButtonEnabled}
            style={MDRVaultItemizedStyles.MDRVaultItemizedForm_Btn}
            onClick={() => {
              setIsPickPrint(false);
              setPrintModalVisible(true);
            }}
          >
            {t("vaultItemized.print")}
          </Button>
          <Button
            id="newBtn"
            variant="outlined"
            style={MDRVaultItemizedStyles.MDRVaultItemizedForm_Btn}
            onClick={handleNewButtonClick}
          >
            {t("vaultItemized.new")}
          </Button>
          <Button
            id="saveBtn"
            variant="contained"
            style={MDRVaultItemizedStyles.MDRVaultItemizedForm_Btn}
            type="submit"
            disabled={!saveButtonEnabled}
          >
            {t("vaultItemized.save")}
          </Button>
        </Stack>

        {/* ------------------- Modals ------------------- */}
        <PossibleMatchesModalRewrite {...PossibleMatchesModalRewriteProps} />
        <DownloadReportModal
          isVisible={printModalVisible}
          setIsVisible={handleClosePrintModal}
          mainDistrictId={currentBranch?.district_id}
          districtName={currentBranch?.name}
          systemId={SYSTEM_ID_SecureBase}
          customerId={mrdForm.values?.customer}
          languageLocaleId={languageLocaleId}
          destructRequestId={id}
          setIsLoading={setIsLoading}
          throwError={isError}
          setThrowError={setIsError}
          throwErrorMessage={errorMessage}
          setThrowErrorMessage={setErrorMessage}
          isPickPrint={isPickPrint}
          setIsPickPrint={setIsPickPrint}
          isPickSizeDisabled={isPickSizeDisabled}
          selectedPickListSize={selectedPickListSize}
        />

        <PersonnelAuthModal {...PersonnelAuthModalProps} />
        <WarningModalCommon {...WarningModalCommonRewriteProps} />
        <WarningModalCommon {...splitPickListWarningProps} />

        <PrintPickModal {...PrintPickModalCommonProps} />
      </Stack>
    </form>
  );
};

export default withLoading(MDRVaultItemizedForm);
