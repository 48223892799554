import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TableSortLabel
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { t } from "i18next";
import * as React from "react";
import { useEffect } from "react";
import { PreferencesStyles } from "../PreferencesStyles";
import { transportTableColumns } from "./TableColumns";

function Row({ row, columns, expandedRowId, setExpandedRowId }) {
  const children = row.children;

  const isOpen = expandedRowId.includes(row.change_history_id);
  const handleExpandClick = () => {
    if (isOpen) {
      setExpandedRowId(
        expandedRowId.filter((id) => id !== row.change_history_id)
      );
    } else {
      setExpandedRowId([...expandedRowId, row.change_history_id]);
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Box sx={PreferencesStyles.tableIconStyles}>
            <Box>
              {children?.length ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={handleExpandClick}
                >
                  {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : null}
            </Box>
            <Box sx={PreferencesStyles.marginLeftAuto}></Box>
          </Box>
        </TableCell>
        {columns?.map((column) => {
          return <TableCell key={column.name}>{row[column.name]}</TableCell>;
        })}
      </TableRow>
      {children?.length && isOpen ? (
        <TableRow>
          <TableCell colSpan={columns.length + 1}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small" aria-label="children">
                  <TableHead sx={PreferencesStyles.tableHeaderColor}>
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        {t("preferences.minimumEmptyTransports.name")}
                      </TableCell>
                      <TableCell>
                        {t("preferences.minimumEmptyTransports.previousValue")}
                      </TableCell>
                      <TableCell>
                        {t("preferences.minimumEmptyTransports.newValue")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {children?.map((child) => (
                      <TableRow key={child.change_history_id}>
                        <TableCell sx={PreferencesStyles.width6} />
                        <TableCell sx={PreferencesStyles.width44}>
                          {child?.name}
                        </TableCell>
                        <TableCell sx={PreferencesStyles.width25}>
                          {child?.old_value}
                        </TableCell>
                        <TableCell sx={PreferencesStyles.width25}>
                          {child?.new_value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}

export const MinimumEmptyTransportsTable = ({ data, rowData }) => {
  const columns = transportTableColumns;
  const [expandedRowId, setExpandedRowId] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [sortConfig, setSortConfig] = React.useState({
    key: "",
    direction: "asc"
  });
  // Store raw data
  const [rawData, setRawData] = React.useState(data);

  // Update raw data when API data changes
  useEffect(() => {
    setRawData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // Sorting data directly in render
  const sortedData = [...rawData].sort((a, b) => {
    if (sortConfig.key === "") {
      return 0; // No sorting applied
    }
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column.name && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: column.name, direction });
  };
  const handleSearchSelect = (e) => {
    let val = e.target.value;
    setSelectedValue(val);
    setExpandedRowId([]);
  };
  useEffect(() => {
    setSelectedValue(t("preferences.minimumEmptyTransports.all"));
  }, []);
  const filterData =
    selectedValue === t("preferences.minimumEmptyTransports.all")
      ? sortedData
      : sortedData.filter((item) => item.changedValues.includes(selectedValue));

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={PreferencesStyles.tableHeaderColor}>
            <TableCell />
            {columns.map((column) => (
              <TableCell key={column.name}>
                <TableSortLabel
                  active={sortConfig.key === column.name}
                  direction={
                    sortConfig.key === column.name
                      ? sortConfig.direction
                      : "asc"
                  }
                  onClick={() => handleSort(column)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell>
              <FormControl fullWidth variant="outlined">
                <>
                  <Select
                    value={selectedValue}
                    placeholder={t("preferences.minimumEmptyTransports.all")}
                    onChange={handleSearchSelect}
                  >
                    <MenuItem
                      value={t("preferences.minimumEmptyTransports.all")}
                    >
                      {t("preferences.minimumEmptyTransports.all")}
                    </MenuItem>
                    <MenuItem value={rowData?.children[0]?.name}>
                      {rowData?.children[0]?.name}
                    </MenuItem>
                    <MenuItem value={rowData?.name}>{rowData?.name}</MenuItem>
                  </Select>
                </>
              </FormControl>
            </TableCell>
          </TableRow>

          {filterData?.length > 0 ? (
            filterData?.map((row) => (
              <Row
                key={row.preference_type_id}
                row={row}
                columns={columns}
                rowData={rowData}
                expandedRowId={expandedRowId}
                setExpandedRowId={setExpandedRowId}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell>{t("common.noRecordsFound")}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
