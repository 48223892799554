/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { NonEditableField } from "components/shared";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useCancelDestructRequestMutation,
  useGetAuthorizationPersonnelsAuthenticationsMutation,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { DestructionCancelReason, ERROR_TYPES } from "utils/constants";
import { generateSHA256Hash, getLanguageId } from "utils/helpers";
import { MediaDestructionRequestDeliveryFormStyles } from "../MediaDestructionRequestDeliveryFormStyles";
import { CancelAuthenticationModalStyles } from "./CancelAuthenticationModalStyles";

const CancelAuthenticationModal = ({
  open,
  handleClose,
  authorizationPersonnels,
  authorizedByDef,
  mdrdForm,
  destructionId,
  fetchDestructionDetails,
  setShowScheduleServiceForm,
  findErrorMessage,
  setThrowErrorMessage,
  setThrowError,
  auth,
  setAuthWarningAlert,
  authWarningAlert
}) => {
  const { t } = useTranslation();
  const { currentBranch } = useSelector(selectAuth);
  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});
  const languageLocaleId = getLanguageId();
  const [authon, setAuthon] = useState("");
  const [authFail, setAuthFail] = useState("");

  const [requestedBySelectedOnCancel, setRequestedBySelectedOnCancel] =
    useState("");

  const [personnelAuthDetailsOnCancel, setPersonnelAuthDetailsOnCancel] =
    useState({});

  // get authorized personnel's details
  const [
    getAuthorizationPersonnelsAuthentications,
    {
      data: personnelAuthrizationData = {},
      isLoading: isAuthroizedPersonnelsDataLoading
    }
  ] = useGetAuthorizationPersonnelsAuthenticationsMutation();

  //Cancel destruct request mutation
  const [triggerCancel, { cancelData = [], isLoadingCancel }] =
    useCancelDestructRequestMutation();

  const handleRequestedByChangeOnCancel = (event, newValue) => {
    setRequestedBySelectedOnCancel(newValue);
  };

  //handle authenticate personnel
  const handleAuthenticate = async () => {
    //generate hash code
    let validateChallengeResponse;
    if (mdrdForm.values?.authorizationChallengeResponse.length > 0) {
      let hashCode = await generateSHA256Hash(
        mdrdForm.values?.authorizationChallengeResponse.trim().toUpperCase()
      );

      //challenge response validation
      validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: currentBranch.district_id,
        language_locale_id: languageLocaleId,
        personnel_id: String(requestedBySelectedOnCancel?.value),
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          const error = data && data[0]?.error;
          //checking for the error
          if (error) {
            setThrowErrorMessage(findErrorMessage(data[0]?.error));
            setThrowError(true);
          } else {
            return data[0].is_match_flag;
          }
        })
        .catch((e) => {
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowError(true);
        });
    }
    if (
      // eslint-disable-next-line no-mixed-operators
      (personnelAuthrizationData?.authNumber !== "" &&
        mdrdForm.values?.authorizationChallengeResponse !== "" &&
        mdrdForm.values?.authNumber !== "" &&
        mdrdForm.values?.authorizationChallengeResponse == "" &&
        // eslint-disable-next-line no-mixed-operators
        personnelAuthrizationData?.authNumber == mdrdForm.values?.authNumber) ||
      (mdrdForm.values?.authorizationChallengeResponse !== "" &&
        mdrdForm.values?.authNumber == "" &&
        validateChallengeResponse == "Y") ||
      (mdrdForm.values?.authorizationChallengeResponse == "" &&
        mdrdForm.values?.authNumber !== "" &&
        mdrdForm.values?.authorizationChallengeResponse == "" &&
        // eslint-disable-next-line no-mixed-operators
        personnelAuthrizationData?.authNumber == mdrdForm.values?.authNumber)
    ) {
      setThrowError(false);
      setAuthFail(false);
      handleClose();
      const obj = {
        main_district_id: String(currentBranch?.district_id),
        destruct_request_id: destructionId,
        cancel_personnel_id: requestedBySelectedOnCancel?.value,
        cancel_employee_id: auth?.user?.employee_id,
        destruct_cancel_reason_id: DestructionCancelReason.ByCustomer
      };
      try {
        const response = await triggerCancel(obj);
        if (response?.error) {
          setThrowErrorMessage(response?.error?.message);
          setThrowError(true);
        } else {
          const { data: triggeredData = [] } = response;
          if (triggeredData) {
            const afterCancelDesObj =
              await fetchDestructionDetails(destructionId);
            if (afterCancelDesObj[0]?.service_location == "") {
              setShowScheduleServiceForm(false);
            }
            mdrdForm.setValues({
              ...mdrdForm?.values,
              authNumber: "",
              authorizationChallengeQuestion: "",
              authorizationChallengeResponse: "",
              bypassReason: ""
            });
          }
        }
      } catch (e) {
        handleCloseModal();
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } else {
      setThrowError(false);
      setAuthFail(true);
    }
  };

  //handle close cancel modal
  const handleCloseModal = () => {
    handleClose();
    setAuthon("");
    setAuthFail("");
    setRequestedBySelectedOnCancel("");
    setAuthWarningAlert(true);
  };

  // Get Personnel data
  useEffect(() => {
    getAuthorizationPersonnelsAuthentications({
      personnelId: String(requestedBySelectedOnCancel?.value)
    }).then((resp) => {
      setPersonnelAuthDetailsOnCancel(resp?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedBySelectedOnCancel?.value]);

  useEffect(() => {
    mdrdForm.setValues({
      ...mdrdForm?.values,
      authNumber: "",
      authorizationChallengeQuestion: "",
      authorizationChallengeResponse: "",
      bypassReason: ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      id="media_rq_cancel_req_modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        id="media_rq_cancel_req_modal_box1"
        sx={CancelAuthenticationModalStyles.CancelModalContainer}
      >
        <Typography
          id="media_rq_print_modal_title"
          sx={CancelAuthenticationModalStyles.CancelTitle}
        >
          {t("mediaDestructionDelivery.cancelAuthMessage")}
        </Typography>
        <Stack
          id="media_rq_print_modal_stack"
          direction="column"
          spacing={spacing.verticalMargin20}
          sx={CancelAuthenticationModalStyles.CancelModal}
        >
          <Collapse
            id="media_rq_print_modal_warning_modal"
            in={authWarningAlert}
          >
            <Alert
              severity="warning"
              sx={{ marginTop: "2em" }}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAuthWarningAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {t("mediaDestructionDelivery.cancelAuthMessage")}
            </Alert>
          </Collapse>
          <Typography
            id="request_delivery_10"
            sx={CancelAuthenticationModalStyles.CancelSubTitle}
          >
            {t("mediaDestructionDelivery.personnelAuthentication")}
          </Typography>
          <Grid id="media_rq_cancel_req_modal_grid1" item>
            <Autocomplete
              id="media_rq_cancel_req_modal_autocomplete"
              disablePortal
              size="medium"
              value={requestedBySelectedOnCancel}
              sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
              options={authorizationPersonnels || []}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  id="media_rq_cancel_req_modal_autocomplete_textfield"
                  {...params}
                  name="authorizedBy"
                  label={t("mediaDestructionDelivery.personnelLabel")}
                  value={authorizedByDef?.label || ""}
                />
              )}
              onChange={handleRequestedByChangeOnCancel}
            />
          </Grid>

          {authon && (
            <Grid id="media_rq_cancel_req_modal_grid2" item xs={9}>
              <Box
                id="media_rq_cancel_req_modal_box2"
                variant="subtitle1"
                sx={MediaDestructionRequestDeliveryFormStyles.AuthenticatedText}
                display="flex"
                alignItems={"center"}
              >
                <CheckIcon id="media_rq_cancel_req_modal_checkicon1" />
                <Typography
                  id="media_rq_cancel_req_modal_typo1"
                  variant="subtitle1"
                >
                  {t("mediaDestructionDelivery.authenticated")}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid
            id="media_rq_cancel_req_17"
            sx={{
              margin: "1em"
            }}
            item
            xs={12}
          >
            {/* Personal Authentication Helper Text */}
            <Typography id="media_rq_cancel_req_18" variant="body2">
              {t("mediaDestructionDelivery.authSectionInstructions")}
            </Typography>
          </Grid>

          <Grid
            id="media_rq_cancel_req_55"
            container
            gap={spacing.horizontalMargin20}
          >
            <Grid id="media_rq_cancel_req_56" item>
              <TextField
                id="media_rq_cancel_req_21"
                name="authNumber"
                label={t("mediaDestructionDelivery.authNumber")}
                size="medium"
                variant="outlined"
                sx={CancelAuthenticationModalStyles.CancelModalTextField}
                value={mdrdForm.values.authNumber}
                onChange={mdrdForm.handleChange}
                error={
                  mdrdForm.touched.authNumber &&
                  Boolean(mdrdForm.errors.authNumber)
                }
                helperText={
                  mdrdForm.touched.authNumber && mdrdForm.errors.authNumber
                }
                disabled={Boolean(
                  mdrdForm.values?.authorizationChallengeResponse
                )}
              />
            </Grid>
            {personnelAuthDetailsOnCancel?.secretAnswer !== undefined && (
              <>
                {personnelAuthDetailsOnCancel?.secretAnswer !== "" && (
                  <>
                    <Grid id="media_rq_cancel_req_57" item>
                      <NonEditableField
                        value={
                          personnelAuthDetailsOnCancel?.secretQuestion || ""
                        }
                        label={t("mediaDestructionDelivery.challengeQuestions")}
                        id={"request_delivery_CancelAuth_59"}
                      />
                    </Grid>
                    <Grid id="media_rq_cancel_req_58" item>
                      <TextField
                        id="media_rq_cancel_req_25"
                        name="authorizationChallengeResponse"
                        label={t("mediaDestructionDelivery.challengeResponse")}
                        size="medium"
                        sx={
                          CancelAuthenticationModalStyles.CancelModalTextField
                        }
                        variant="outlined"
                        type="password"
                        value={mdrdForm.values.authorizationChallengeResponse}
                        onChange={mdrdForm.handleChange}
                        error={
                          mdrdForm.touched.authorizationChallengeResponse &&
                          Boolean(
                            mdrdForm.errors.authorizationChallengeResponse
                          )
                        }
                        helperText={
                          mdrdForm.touched.authorizationChallengeResponse &&
                          mdrdForm.errors.authorizationChallengeResponse
                        }
                        disabled={Boolean(
                          !personnelAuthrizationData?.secretAnswer ||
                            Boolean(mdrdForm.values?.authNumber)
                        )}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Stack>

        <Stack
          id="media_rq_cancel_req_32"
          direction="row"
          spacing={spacing.verticalMargin20}
          sx={CancelAuthenticationModalStyles.CancelModalButton}
        >
          {/* Close button */}
          <Button
            id="media_rq_cancel_req_33"
            variant="outlined"
            size="medium"
            type="button"
            onClick={handleCloseModal}
          >
            {t("mediaDestructionDelivery.close")}
          </Button>

          {/* Authenticate button */}
          <Button
            id="media_rq_print_modal_btn2"
            variant="contained"
            size="medium"
            type="submit"
            onClick={handleAuthenticate}
          >
            {t("mediaDestructionDelivery.authenticate")}
          </Button>

          <Grid id="media_rq_cancel_req_30" sx={{ margin: "1em" }}>
            {authFail && (
              <Alert id="media_rq_cancel_req_31" severity="error">
                {t("mediaDestructionDelivery.authenticationError")}
              </Alert>
            )}
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CancelAuthenticationModal;
