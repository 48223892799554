import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TableContainer,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  VALUE_N,
  Y_CHECKER,
  dayMonthYearTimeFormat,
  errorMsgs,
  securityGroupsFilterId
} from "utils/constants";
import {
  False,
  True,
  angleBracketsClose,
  angleBracketsOpen,
  bracesOpen,
  copyLabel,
  generatePatterns,
  injectLabel,
  messageCodes,
  severityWarning,
  squareBracketsClose,
  squareBracketsOpen,
  unknownMedia
} from "utils/constants/open-media-processing/OpenMediaAuditConstants";
import { findErrorMessage, getResponseData } from "utils/helpers";
import { OpenMediaAuditStyles } from "./OpenMediaAuditDetailStyles";

const PossibleMatches = ({
  possibleMatches,
  handleBack,
  currentBranch,
  setThrowError,
  setIsLoading,
  setThrowErrorMessage,
  auth,
  handleGetAuditDetail,
  allLocations,
  setSeverity,
  allAuditData,
  setToastMessage,
  handlePrintMatch
}) => {
  const [customData, setCustomData] = useState(null);
  const [labeltemplates, setLableTemplates] = useState([]);
  const [regList, setRegexList] = useState([]);
  const [volcerList, setVolcerList] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  useEffect(() => {
    if (!possibleMatches?.resolved_datetime) {
      handleDataSet(labeltemplates, regList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [possibleMatches]);

  const handleDataSet = () => {
    let dataFormat = [];
    const { unknown_om_flag } = possibleMatches;
    dataFormat.push({
      volser: unknownMedia,
      is_selected: unknown_om_flag,
      logical_vault_code: "",
      open_media_id: securityGroupsFilterId?.zero,
      media_type_short_descr: "",
      scanned_location: "",
      media_exchange_status_type: "",
      existing_discrep_id: "",
      template: ""
    });
    setVolcerList([...dataFormat]);
  };
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS
  };
  const columns = [
    {
      name: "volser",
      label: t("openMediaAuditDetail.media")
    },
    {
      name: "logical_vault_code",
      label: t("openMediaAuditDetail.logicalVault")
    },
    {
      name: "media_type_short_descr",
      label: t("openMediaAuditDetail.MediaType")
    },

    {
      name: "location",
      label: t("openMediaAuditDetail.sbLocation*")
    },

    {
      name: "media_exchange_status_type",
      label: t("openMediaAuditDetail.status")
    },
    {
      name: "existing_discrep_id",
      label: t("openMediaAuditDetail.discrepancy")
    },
    {
      name: "match_template",
      label: t("openMediaAuditDetail.template")
    }
  ];
  useEffect(() => {
    if (possibleMatches?.resolved_datetime) {
      handleOpenMediaAudit();
    } else {
      handleGetCustomData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDataFormat = async (data) => {
    let dataList = [],
      dataSetVolcer = [];
    await data.forEach((item) => {
      let template = generatePatterns(
        item?.template,
        customData?.number?.trim()
      );
      dataList.push({ ...item, matchPattern: template });
    });
    let barCode =
      "^" +
      possibleMatches?.scanned_barcode
        .split("")
        ?.map((item) => "[\\s\\S]")
        .join("") +
      "$";
    dataList?.forEach((item) => {
      if (item?.matchPattern === barCode) {
        let posInBarcode = 0;
        let mode = copyLabel;
        let templateText = item?.template;
        let barcode = possibleMatches?.scanned_barcode;
        let Volser = "";
        for (let pos = 0; pos < templateText.length; pos++) {
          let templateChar = templateText.substr(pos, 1);
          switch (templateChar) {
            // Process characters with special meaning.
            case squareBracketsOpen:
              mode = copyLabel;
              break;
            case squareBracketsClose:
              mode = copyLabel;
              break;
            case angleBracketsOpen:
              mode = injectLabel;
              break;
            case angleBracketsClose:
              mode = copyLabel;
              break;
            case bracesOpen:
              // skip one character
              posInBarcode = posInBarcode + 1;
              mode = copyLabel;
              break;
            default:
              // It's not a special character so take the appropriate action.
              switch (mode) {
                case copyLabel:
                  Volser = Volser + barcode.substr(posInBarcode, 1);
                  posInBarcode = posInBarcode + 1;
                  break;
                case injectLabel:
                  Volser = Volser + templateChar;
                  break;
                default:
                  break;
              }
              break;
          }
        }
        dataSetVolcer.push({ ...item, Volser });
      }
    });
    setIsLoading(false);
    setRegexList(dataSetVolcer);
    handleGetPossibleMatch(dataSetVolcer);
  };
  const handleGetPossibleMatch = async (data) => {
    setIsLoading(true);
    let volser_list = data?.map((item) => item?.Volser)?.join(",");
    try {
      const dataSets4 = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.id,
          customer_id: possibleMatches?.customer_id,
          volser_list,
          iso_locale_code: allLocations
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.matchlist,
        2
      );
      if (dataSets4?.data[0]?.length) {
        const { unknown_om_flag } = possibleMatches;
        let addTemplet = [
          {
            volser: unknownMedia,
            is_selected: unknown_om_flag,
            logical_vault_code: "",
            open_media_id: securityGroupsFilterId?.zero,
            media_type_short_descr: "",
            scanned_location: "",
            media_exchange_status_type: "",
            existing_discrep_id: "",
            match_template: ""
          }
        ];
        await dataSets4?.data[0]?.forEach((item) => {
          let template = data?.find((data) => data?.Volser === item?.volser);
          addTemplet.push({ ...item, match_template: template?.template });
        });
        setVolcerList(addTemplet);
      }
      setIsLoading(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };
  const handleGetCustomData = async () => {
    let activeomtypelist = [];
    setIsLoading(true);
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.id,
          customer_id: possibleMatches?.customer_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getcustomdata,
        2
      );
      setCustomData(dataSets?.data[0][0]);
      const dataSets2 = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.id,
          customer_id: possibleMatches?.customer_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.activeomtypelist,
        2
      );
      activeomtypelist.push(dataSets2?.data[0][0]);
      const dataSets3 = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.id,
          customer_id: possibleMatches?.customer_id,
          include_transport_templates: VALUE_N
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getlabeltemplates,
        2
      );

      setLableTemplates(dataSets3?.data[0]);
      handleDataFormat(dataSets3?.data[0]);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };
  const handleOpenMediaAudit = async () => {
    setIsLoading(true);
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.id,
          customer_id: possibleMatches?.customer_id,
          om_audit_detail_id: possibleMatches?.om_audit_detail_id,
          iso_locale_code: allLocations
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.matchomauditdetails,
        1
      );
      let arr = dataSets?.data[0]
        .map((item) => {
          return {
            ...item,
            isDisable: true,
            isCheck: item.is_selected === Y_CHECKER
          };
        })
        .sort(
          (a, b) =>
            a?.location?.split("-")?.join("") -
            b?.location?.split("-")?.join("")
        );
      setVolcerList([...arr]);
      setIsLoading(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };
  const handleSave = async () => {
    setIsLoading(true);
    let om_audit_possible_match_xml = "<ROOT>";
    await volcerList.forEach((item, index) => {
      let check = item?.isCheck ? True : False;
      if (item?.volser === unknownMedia) {
        om_audit_possible_match_xml += `<OMAuditPossibleMatch Open_Media_Id="0" Media_Exchange_Status_Id="" Logical_Vault_Id="" Media_Type_Id="" OM_Discrepancy_Id="" Location="" Is_Selected="${check}" Label_Template=""> </OMAuditPossibleMatch>`;
      } else {
        om_audit_possible_match_xml += `<OMAuditPossibleMatch Open_Media_Id="${item.open_media_id}" Media_Exchange_Status_Id="${item.media_exchange_status_id}" Logical_Vault_Id="${item.logical_vault_id}" Media_Type_Id="${item.media_type_id}" OM_Discrepancy_Id="" Location="${item.location}" Is_Selected="${check}" Label_Template="${item?.match_template}"> </OMAuditPossibleMatch>`;
      }
    });
    om_audit_possible_match_xml += `</ROOT>`;
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          resolving_emp_id: auth?.user?.employee_id,
          main_district_id: currentBranch?.id,
          om_audit_detail_id: possibleMatches?.om_audit_detail_id,
          om_audit_possible_match_xml
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.matchlistinsertion,
        1
      );
      const { data } = dataSets;
      if (Number(data[0][0]?.error)) {
        const errorMsg = await getMessageFromCode(data[0][0]?.error);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        setIsLoading(false);
      } else {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80169);
        let errorMessage = errorMsg[0]?.descr?.replace(
          "{0}",
          dayjs().format(dayMonthYearTimeFormat)
        );
        handleGetAuditDetail(allAuditData[0][0]?.om_audit_id, "", errorMessage);
        handleBack();
      }
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };
  const handleCheck = (data, isCheck, index) => {
    if (isCheck) {
      volcerList[index] = {
        ...data,
        isCheck: false
      };
    } else {
      volcerList[index] = {
        ...data,
        isCheck: true
      };
    }
    setVolcerList([...volcerList]);
  };
  useEffect(() => {
    let checked = false;
    volcerList.forEach((item) => {
      if (item?.is_selected === Y_CHECKER) {
        checked = true;
        setIsDisable(true);
      }
    });
    if (checked) {
      handleGetError();
    }
    // eslint-disable-next-line
  }, [volcerList]);

  const handleGetError = async () => {
    setIsLoading(true);
    try {
      const errorMsg = await getMessageFromCode(messageCodes.messageCodes80217);
      setThrowErrorMessage(errorMsg[0]?.descr);
      setThrowError(true);
      setSeverity(severityWarning);
      setIsLoading(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Grid container spacing={2} sx={OpenMediaAuditStyles.fontColor}>
        <Grid item md={6} lg={3} xs={12}>
          {t("common.Customer")}
          <Typography style={OpenMediaAuditStyles.pmFont}>
            {possibleMatches?.customer_number}{" "}
          </Typography>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.auditId")}
          <Typography style={OpenMediaAuditStyles.pmFont}>
            {" "}
            {allAuditData && allAuditData[0] && allAuditData[0][0]
              ? allAuditData[0][0]?.om_audit_id
              : ""}
          </Typography>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.barcode")}
          <Typography style={OpenMediaAuditStyles.pmFont}>
            {possibleMatches?.scanned_barcode}
          </Typography>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.scannedLocation")}
          <Typography style={OpenMediaAuditStyles.pmFont}>
            {possibleMatches?.scanned_location}
          </Typography>
        </Grid>
        <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
          <Divider />
        </Grid>
      </Grid>
      {!isDisable ? (
        <Grid item md={12} lg={12} xs={12}>
          <Typography style={OpenMediaAuditStyles.subHeading}>
            {t("openMediaAuditDetail.opssibleMatchTitle")}
          </Typography>
          <List sx={OpenMediaAuditStyles.listStyle}>
            <ListItem>
              <ListItemText>
                {t("openMediaAuditDetail.opssibleMatch1")}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("openMediaAuditDetail.opssibleMatch2")}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("openMediaAuditDetail.opssibleMatch3")}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("openMediaAuditDetail.opssibleMatch4")}
              </ListItemText>
            </ListItem>
          </List>
          <Typography style={OpenMediaAuditStyles.subHeading}>
            {t("openMediaAuditDetail.opssibleMatchTitle1")}
          </Typography>
          <List sx={OpenMediaAuditStyles.listStyle}>
            <ListItem>
              <ListItemText>
                {t("openMediaAuditDetail.opssibleMatch6")}
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      ) : (
        ""
      )}
      <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
        <Paper>
          <Grid
            item
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            gap={spacing.verticalMargin20}
            padding={2}
          >
            <Button
              id="clear-button"
              variant="outlined"
              type="button"
              onClick={() => {
                handlePrintMatch(
                  possibleMatches?.resolved_datetime,
                  volcerList
                );
              }}
            >
              {t("openMediaAuditDetail.print")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              type="button"
              disabled={!!possibleMatches?.resolved_datetime}
              onClick={() => {
                handleSave();
              }}
            >
              {t("common.save")}
            </Button>
          </Grid>
          <TableContainer>
            <MUIDataTable
              columns={[
                {
                  name: "row_id",
                  label: t("openMediaAuditDetail.selected"),
                  options: {
                    filter: false,
                    sort: false,
                    download: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                      const { tableData, rowIndex } = tableMeta;
                      let data = tableData[rowIndex];
                      return (
                        <>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data?.is_selected === Y_CHECKER ||
                                    data?.isCheck
                                  }
                                  onChange={(e) => {
                                    handleCheck(data, data?.isCheck, rowIndex);
                                  }}
                                  inputProps={{ "aria-label": "controlled" }}
                                  disabled={
                                    data?.is_selected === Y_CHECKER ||
                                    data?.isDisable
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        </>
                      );
                    },
                    customHeadLabelRender: (columnMeta) => (
                      <div>{columnMeta.label}</div>
                    )
                  }
                },
                ...columns
              ]}
              data={[...volcerList]}
              options={options}
            />
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
        <Divider />
      </Grid>
      <Grid
        item
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
        gap={spacing.verticalMargin20}
      >
        <Button
          id="clear-button"
          variant="outlined"
          type="button"
          onClick={() => {
            handleBack();
          }}
        >
          {t("containerAuditDetails.back")}
        </Button>
      </Grid>
    </>
  );
};

export default PossibleMatches;
