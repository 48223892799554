/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ClosedIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, SearchField } from "components/core";
import { Formik, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getSecurityGroups } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES, securityGroupsFilterId } from "utils/constants";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";
import { GroupEmployeeStyles } from "./GroupEmployeeStyles";
import GroupEmployeeTable from "./GroupEmployeeTable";
import { securityGroupSchema } from "./Schema";
const GroupEmployeeLayout = () => {
  const { t } = useTranslation();
  const districtId = getAuthenticatedUserBranch();
  const { auth } = useSelector(selectAuth);
  //form initial state
  const initialGroupEmployee = {
    securityGroup: ""
  };

  //form initialize using useFormik
  const securityGroupForm = useFormik({
    initialValues: initialGroupEmployee,
    validationSchema: securityGroupSchema
  });

  const [securityGroups, setSecurityGroups] = useState([]);
  const [securityGroupDefault, setSecurityGroupDefault] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [disableFind, setDisableFind] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSecurityGroups, setLoadingSecurityGroups] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);

  //get security groups
  const fetchSecurityGroup = async () => {
    try {
      setLoadingSecurityGroups(true);
      const result = await getSecurityGroups(districtId);
      const filterByFilterFlag = result.filter(
        (secGroup) => secGroup?.filter_flag === securityGroupsFilterId.zero
      );

      //sort data by security sort id
      const sortedSecurityGroups = filterByFilterFlag.sort(
        (a, b) => parseInt(a?.sort_id) - parseInt(b?.sort_id)
      );
      const response = sortedSecurityGroups.map((res) => ({
        value: res?.security_group_id,
        label: res?.code.trim()
      }));

      setSecurityGroups(response);
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoadingSecurityGroups(false);
    }
  };
  //fetch table data
  const fetchTableData = async () => {
    try {
      setLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: districtId,
        logged_in_employee_id: auth.user.employee_id,
        group_id: securityGroupForm.values?.securityGroup
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.securityGroupEmployee.getSecurityGroupEmployeeDetails
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const securityGroupEmployeeData = data.data[0];
      if (securityGroupEmployeeData) {
        //sort list by last name
        let sortByLastName = securityGroupEmployeeData.sort((a, b) => {
          const lastNameA = a.employee_name.split(",")[0].trim().toLowerCase();
          const lastNameB = b.employee_name.split(",")[0].trim().toLowerCase();
          if (lastNameA < lastNameB) return -1;
          if (lastNameA > lastNameB) return 1;
          return 0;
        });
        setSearchResults(sortByLastName);
        setDisableFind(true);
        setIsResetGrid(false);
      } else if (data?.error) {
        setThrowErrorMessage(data?.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };
  //handle clear
  const handleReset = () => {
    securityGroupForm.resetForm();
    setSecurityGroupDefault("");
    setSearchResults([]);
    setLoading(false);
    setThrowError(false);
    setIsResetGrid(true);
    setDisableFind(false);
  };
  //handle security group change
  const handleSecurityGroupChange = (event, value) => {
    handleReset();
    setSecurityGroupDefault(value);
    securityGroupForm.handleChange({
      target: { name: "securityGroup", value: value?.value }
    });
  };
  //handle find
  const handleFind = async () => {
    await fetchTableData();
  };
  useEffect(() => {
    districtId && fetchSecurityGroup();
  }, [districtId]);
  return (
    <>
      {/*Circular loader*/}
      <CircularLoaderFullPage loading={loading} />
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            sx={GroupEmployeeStyles.alertBox}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Formik>
        <form>
          <Grid
            display={"flex"}
            direction={"column"}
            flexDirection={"column"}
            gap={spacing.verticalMargin20}
          >
            <Grid container spacing={spacing.verticalMargin20}>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                {/*Security group search field*/}
                <SearchField
                  label={t("securityGroupEmployee.securityGroup")}
                  labelId="security-group-search"
                  options={securityGroups}
                  clearIcon={<ClosedIcon />}
                  popupIcon={<ArrowDown />}
                  value={securityGroupDefault}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={handleSecurityGroupChange}
                  defaultValue=""
                  error={!!securityGroupForm.errors.securityGroup}
                  helperText={securityGroupForm.errors.securityGroup}
                  loading={loadingSecurityGroups}
                  required
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={spacing.verticalMargin20}>
              {/*Clear button*/}
              <Button
                id="security-group-clear"
                variant="outlined"
                onClick={handleReset}
              >
                {t("common.clear")}
              </Button>
              {/*Find button*/}
              <Button
                id="security-group-find"
                variant="contained"
                type="button"
                onClick={handleFind}
                disabled={
                  disableFind ||
                  Boolean(!securityGroupForm.values?.securityGroup)
                }
              >
                {t("common.find")}
              </Button>
            </Stack>

            <GroupEmployeeTable
              searchResults={searchResults}
              isResetGrid={isResetGrid}
            />
          </Grid>
        </form>
      </Formik>
    </>
  );
};

export default GroupEmployeeLayout;
