import { Stack } from "@mui/material";
import EmailNotification from "components/customer-information/preferences/EmailNotification";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const EmailNotifications = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("preferences.detailsTitle")}`} />
        <EmailNotification />
      </Stack>
    </>
  );
};

export default EmailNotifications;
