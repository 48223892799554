/* Yup validation schemas goes here */

import * as yup from "yup";
import i18n from "../../../../i18n";

export const destructionDeliveryMdrdFormSchema = yup.object().shape({
  customer: yup
    .string()
    .required(i18n.t("mediaDestructionDelivery.customerReqError")),
  authorizedBy: yup
    .string()
    .required(i18n.t("mediaDestructionDelivery.authorizedByReqError"))
});

export const destructionDeliverySitadFormSchema = yup.object().shape({
  preferredDeliveryDate: yup
    .date()
    .required(i18n.t("mediaDestructionDelivery.preferredDeliveryDateReqError"))
    .typeError(
      i18n.t("mediaDestructionDelivery.preferredDeliveryDateTypeError")
    ),
  serviceLocation: yup
    .string()
    .required(i18n.t("mediaDestructionDelivery.serviceLocationReqErro")),
  deliveryInstructions: yup
    .string()
    .required(i18n.t("mediaDestructionDelivery.deliveryInstructionsReqError"))
    .max(
      256,
      i18n.t("mediaDestructionDelivery.deliveryInstructionsLengthError")
    )
});

export const destructionDeliveryScheduleFormSchema = yup.object().shape({
  scheduleDeliveryDate: yup
    .date()
    .required(i18n.t("mediaDestructionDelivery.scheduleDeliveryDateReqError"))
    .typeError(
      i18n.t("mediaDestructionDelivery.scheduleDeliveryDateTypeError")
    ),
  scheduleServiceLocation: yup
    .string()
    .required(
      i18n.t("mediaDestructionDelivery.scheduleServiceLocationReqError")
    ),
  scheduleDeliveryInstructions: yup
    .string()
    .required(
      i18n.t("mediaDestructionDelivery.scheduleDeliveryInstructionsReqError")
    )
    .max(
      256,
      i18n.t("mediaDestructionDelivery.scheduleDeliveryInstructionsLengthError")
    )
});
