import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  dayMintMonthYearFormat,
  maximumDate,
  numberConstants
} from "utils/constants";
import {
  getResponseData,
  messageCodes,
  scheduled,
  severityError,
  severityWarning,
  status2
} from "utils/constants/open-media-processing/OpenMediaAuditConstants";
import { findErrorMessage } from "utils/helpers";
import { OpenMediaAuditStyles } from "./OpenMediaAuditDetailStyles";
//today date state
const today = dayjs();
//max date state
const maxDate = dayjs(maximumDate);
const LogicalVaultFrom = ({
  customers,
  setIsLoading,
  currentBranch,
  setThrowError,
  setThrowErrorMessage,
  auditDetailForm,
  isViewMode,
  viewData,
  localeByBranch,
  allAuditData,
  setSeverity
}) => {
  const { values, setFieldValue } = auditDetailForm;
  const [logicalVaultList, setLogicalVault] = useState([]);

  useEffect(() => {
    if (values?.customer_id && !viewData?.om_audit_id) {
      handleCustomerChange("", values?.customer_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.customer_id]);

  const handleCustomerChange = async (event, newValue) => {
    setIsLoading(true);
    setSeverity(severityError);
    if (!viewData?.customer_id) {
      setFieldValue("customer_id", newValue);
    }
    setLogicalVault([]);
    setThrowErrorMessage("");
    setThrowError(false);
    if (!newValue) {
      setIsLoading(false);
      return;
    }
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id,
      customer_id: newValue?.value || newValue
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getActiveLogicalVaultsList,
        2
      );
      const { data } = dataSets;
      if (data[1]?.[0]?.error !== numberConstants.zero) {
        const errorMsg = await getMessageFromCode(dataSets[1]?.[0]?.error);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        setIsLoading(false);
        return;
      }
      let resultArray = [];
      resultArray = data[0]?.filter(
        (logicalVault) =>
          logicalVault?.media_program_type_id === numberConstants.five &&
          logicalVault?.slot_count
      );
      if (viewData?.om_audit_id && viewData?.audit_status !== "Canceled") {
        let newResult = [];
        newResult = resultArray?.filter(
          (item) => item?.current_audit_id === viewData?.om_audit_id
        );
        resultArray = newResult;
      }
      if (!resultArray?.length && !viewData?.om_audit_id) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCodes80237
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        setIsLoading(false);
        return;
      }
      let nullArray = [],
        dataSet = [];
      resultArray.forEach((item) => {
        let isDisable = false,
          isCheck = false;
        if (!item?.current_audit_id) {
          nullArray.push(item);
        } else {
          isDisable = true;
        }
        dataSet.push({ ...item, isDisable, isCheck });
      });
      if (!nullArray.length && !viewData?.om_audit_id) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCodes80190
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      } else if (
        nullArray.length &&
        nullArray.length !== resultArray?.length &&
        !viewData?.om_audit_id
      ) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCodes80189
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        setSeverity(severityWarning);
      }
      setLogicalVault(dataSet);
      if (!isViewMode) {
        setFieldValue("logical_vault_id_list", dataSet);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS
  };

  const columns = [
    {
      name: "",
      label: ""
    },
    {
      name: "logical_vault_code",
      label: t("mediaRequestDetail.logicalVaultCodeCaps")
    },
    {
      name: "media_count",
      label: t("openMediaAuditDetail.mediaCount")
    },
    {
      name: "slot_count",
      label: t("openMediaAuditDetail.slotCount")
    }
  ];
  const handleCheckBox = (value) => {
    let updateLogicalVault = logicalVaultList?.map((item) => {
      if (item?.logical_vault_id === value?.logical_vault_id) {
        return {
          ...item,
          isCheck: !value?.isCheck
        };
      }
      return {
        ...item
      };
    });
    setLogicalVault([...updateLogicalVault]);
    let selectedLogicalVault = updateLogicalVault.filter(
      (item) => item?.isCheck
    );
    setFieldValue("logical_vault_id_list", selectedLogicalVault);
  };
  return (
    <>
      <Grid item xs={4} sm={6} md={4} lg={3}>
        <FormControl
          fullWidth
          disabled={
            isViewMode ? !isViewMode : viewData?.audit_status !== scheduled
          }
        >
          <DatePickerField
            disabled={
              isViewMode ? !isViewMode : viewData?.audit_status !== scheduled
            }
            id="due-date"
            name="dueDate"
            label={t("openMediaAuditDetail.dueDate")}
            value={values?.due_date}
            minDate={isViewMode ? today : values?.due_date}
            maxDate={maxDate}
            defaultValue={today}
            locale={localeByBranch}
            onChange={(value) => {
              setFieldValue("due_date", value);
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4} sm={6} md={4} lg={3}>
        {isViewMode ? (
          <FormControl fullWidth>
            <CustomersField
              options={customers}
              value={values?.customer_id}
              handleOnChange={handleCustomerChange}
              label={t("personnelValidation.customer")}
            />
          </FormControl>
        ) : (
          <>
            {t("personnelValidation.customer")}
            <Typography>{viewData?.customer_number}</Typography>
          </>
        )}
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={5}>
        <FormControl fullWidth>
          <TextField
            labelId="comments"
            label={t("openMediaAuditDetail.comments")}
            value={values?.comment}
            onChange={(event) => {
              setFieldValue("comment", event.target.value);
            }}
            fullWidth
            multiline
            rows={4}
            name="review-comments"
            id="review-comments"
            disabled={status2?.includes(viewData?.audit_status)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={5}>
        <Typography style={OpenMediaAuditStyles.commentSectionText}>
          {t("openMediaAuditDetail.pastComments")}
        </Typography>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={OpenMediaAuditStyles.commentSection}
        >
          {allAuditData[1]?.length
            ? allAuditData[1]?.map((item) => {
                return (
                  <Typography marginBottom={2} key={item?.employee_name}>
                    {" "}
                    {dayjs(item?.comment_date_ltz).format(
                      dayMintMonthYearFormat
                    )}{" "}
                    {item?.employee_name}-{item?.comment}
                  </Typography>
                );
              })
            : ""}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12} marginTop={2} marginBottom={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} lg={12}>
        <MUIDataTable
          columns={[
            {
              name: "row_id",
              label: " ",
              options: {
                filter: false,
                sort: false,
                download: false,
                viewColumns: true,
                customBodyRender: (value, tableMeta) => {
                  const { tableData, rowIndex } = tableMeta;
                  let data = tableData[rowIndex];
                  return (
                    isViewMode && (
                      <>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  handleCheckBox(data);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                                disabled={data?.isDisable}
                                checked={data?.isCheck}
                              />
                            }
                          />
                        </FormGroup>
                      </>
                    )
                  );
                },
                customHeadLabelRender: (columnMeta) => (
                  <div>{columnMeta.label}</div>
                )
              }
            },
            ...columns
          ]}
          data={allAuditData ? [...allAuditData[2]] : [...logicalVaultList]}
          options={options}
        />
      </Grid>
    </>
  );
};

export default LogicalVaultFrom;
