export const errorLogStyles = {
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  textStyle: {
    width: "100%",
    marginBottom: "20px"
  },
  fieldStyle: {
    width: "310px",
    height: "56px"
  },
  errorStyle: {
    marginBottom: "20px"
  }
};
