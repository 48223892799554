import { Button, Grid, SvgIcon, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMessageFromCode } from "services/api/query";
import { setErrorMessage } from "store/slices";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS, messageCode } from "utils/constants";
import {
  CSV,
  TXT,
  replaceValueFromMessageDescr
} from "../../../utils/constants/open-media-processing/CreateDistributionListConstants";
import { createDisributionListStyles } from "./CreateDisributionListStyles";

import { ReactComponent as DeleteIcon } from "assets/images/CloseIcon.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { CircularLoaderFullPage } from "components/core";
export const getRandomNumber = () => Math.random().toString(36).slice(2);

const CreateDistributionListTable = ({
  handleModalOpen,
  isSlotAvailable,
  tableData,
  setTableData,
  setThrowError,
  setThrowErrorMessage,
  setIsEdit,
  setEditRowData,
  hasNChecker,
  clearSlot
}) => {
  const { t } = useTranslation();

  // handle delete event
  const handleDelete = (id) => {
    setTableData(tableData.filter((data, index) => index !== id));
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (clearSlot) {
      tableData.map((t) => (t.slot = ""));
    }
  }, [clearSlot, tableData]);

  const columns = [
    {
      name: "",
      options: {
        setCellProps: () => ({
          style: createDisributionListStyles?.tableStyles?.tableColumnWidth
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button onClick={() => handleEdit(tableMeta)}>
                <SvgIcon component={EditIcon} />
              </Button>
              <Button
                onClick={(e) => {
                  handleDelete(tableMeta.rowIndex);
                }}
              >
                <SvgIcon component={DeleteIcon} />
              </Button>
            </>
          );
        }
      }
    },

    {
      name: "mediaNumber",
      label: t(`createDistributionList.mediaNumber`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "returnDate",
      label: t(`createDistributionList.returnDate`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "drpCode",
      label: t(`createDistributionList.drpCode`),
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "description",
      label: t(`createDistributionList.description`),
      options: {
        filter: true,
        sort: false
      }
    }
  ];

  if (isSlotAvailable === true) {
    columns.splice(3, 0, {
      name: "slot",
      label: t(`createDistributionList.slot`),
      options: {
        setCellProps: () => ({
          style: createDisributionListStyles?.tableStyles?.slotWidth
        })
      }
    });
  }

  const openMediaFileImportRef = useRef(null);

  // checking is media number exists in tableData
  function compareMediaNumbers(tableData, newData) {
    function extractMediaNumbers(array) {
      return array.map((item) => item.mediaNumber);
    }

    const mediaNumbers1 = extractMediaNumbers(tableData);
    const mediaNumbers2 = extractMediaNumbers(newData);

    // Create a Set to store unique matching mediaNumbers
    const uniqueMatches = new Set();

    // Compare mediaNumbers and add exact matches to the Set
    mediaNumbers1.forEach((number1) => {
      mediaNumbers2.forEach((number2) => {
        if (number1 === number2) {
          uniqueMatches.add(number1);
        }
      });
    });

    // Convert the Set to an array and return it
    return Boolean(uniqueMatches.size);
  }

  // handle Message
  const handleMessage = async (messageId, replaceValue) => {
    await getMessageFromCode(messageId)
      .then((data) => {
        if (data.data[0].descr.includes(replaceValueFromMessageDescr)) {
          setThrowErrorMessage(
            data.data[0].descr.replace(
              replaceValueFromMessageDescr,
              replaceValue
            )
          );
        } else {
          setThrowErrorMessage(data.data[0].descr);
        }
      })
      .catch((error) => {
        setThrowError(true);
        setThrowErrorMessage(error);
      });
  };

  // handle file import event handler
  const handleFileImport = async (event) => {
    const file = event?.target?.files[0];
    setThrowError(false);
    if (file) {
      try {
        if (
          !(
            file.name.toLowerCase().endsWith(TXT) ||
            file.name.toLowerCase().endsWith(CSV)
          )
        ) {
          setThrowError(true);
          handleMessage(messageCode.txtCsvMessageId);
          // Clear the file input
          openMediaFileImportRef.current.value = null;
          return;
        } else {
          try {
            const reader = new FileReader();
            reader.onload = (e) => {
              const content = e.target.result;
              if (content.trim()) {
                parseFileContent(content);
              }
              // Reset the input value to trigger the onChange event next time
              openMediaFileImportRef.current.value = null;
              setIsLoading(false);
            };
            reader.readAsText(file);
          } catch (error) {
            setThrowError(true);
            setErrorMessage(t("mediaRequestDetail.errorReadingTheFile"));
          }
        }
      } catch (error) {
        setThrowError(true);
        setThrowErrorMessage(t("printTapeLabel.errorReadingTheFile"));
      }
    } else {
      setThrowError(false);
    }
  };

  const parseFileContent = (content) => {
    setIsLoading(true);
    const lines = content?.split("\n");
    const newData = [];
    let volserList = [];
    let currentObj = {
      index: null,
      mediaNumber: null,
      returnDate: null,
      slot: null,
      drpCode: null,
      description: null
    };

    setThrowError(false);

    lines.forEach((line, index) => {
      if (line) {
        const columns = line.split("\t");

        // Check if mediaNumber (first column) is present
        if (columns[0].trim() && isSlotAvailable) {
          currentObj.mediaNumber = columns[0].trim().toUpperCase();
          currentObj.returnDate = columns[1] ? columns[1].trim() : null;
          currentObj.slot = columns[2] ? columns[2].trim() : null;
          currentObj.drpCode = columns[3]
            ? columns[3].trim().toUpperCase()
            : null;
          currentObj.description = columns[4] ? columns[4].trim() : null;
          newData.push({ ...currentObj, index: getRandomNumber() });
          volserList.push(currentObj.mediaNumber.padEnd(20));
          currentObj = {
            index: null,
            mediaNumber: null,
            returnDate: null,
            slot: null,
            drpCode: null,
            description: null
          };
        } else if (columns[0].trim()) {
          currentObj.mediaNumber = columns[0].trim().toUpperCase();
          currentObj.returnDate = columns[1] ? columns[1].trim() : null;
          currentObj.drpCode = columns[2]
            ? columns[2].trim().toUpperCase()
            : null;
          currentObj.description = columns[3] ? columns[3].trim() : null;
          newData.push({ ...currentObj, index: getRandomNumber() });
          volserList.push(currentObj.mediaNumber.padEnd(20));
          currentObj = {
            index: null,
            mediaNumber: null,
            returnDate: null,
            slot: null,
            drpCode: null,
            description: null
          };
        }
      }
    });

    if (compareMediaNumbers(tableData, newData)) {
      setThrowError(true);
      setIsLoading(false);
      setThrowErrorMessage(
        t(`createDistributionList.mediaNumberDuplicacyError`)
      );
      return;
    }
    setTableData([...tableData, ...newData]);
  };

  // handle edit event
  const handleEdit = (table) => {
    setEditRowData(tableData[table.rowIndex]);
    setIsEdit(true);
  };
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: 1,
    pagination: tableData?.length && true,
    fixedSelectColumn: { clearSlot },
    textLabels: {
      body: {
        noMatch:
          tableData?.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    customToolbar: () => (
      <Grid
        item
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        gap={spacing.verticalMargin20}
      >
        <Button
          disabled={!hasNChecker}
          variant="outlined"
          style={createDisributionListStyles.disributionTableList.buttons}
          onClick={() => {
            handleModalOpen();
          }}
          id="new-disrtibution-list"
        >
          {t("common.new")}
        </Button>

        <Button
          component="label"
          disabled={!hasNChecker}
          variant="outlined"
          role={undefined}
          style={createDisributionListStyles.disributionTableList.buttons}
          id="new-disrtibution-list-import"
        >
          {t("createDistributionList.import")}
          <input
            ref={openMediaFileImportRef}
            type="file"
            accept=".txt, .csv"
            style={createDisributionListStyles.tableListStyle.inputTypeFile}
            onChange={handleFileImport}
          />
        </Button>
      </Grid>
    )
  };
  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      <MUIDataTable
        disableColumnSelector={clearSlot}
        columns={columns}
        options={options}
        data={tableData}
        editMode="row"
        rowModesModel={null}
        onRowModesModelChange={null}
        onRowEditStop={null}
        processRowUpdate={null}
        title={
          <Typography style={commonStyles.tableRecordsFoundTitleStyle}>
            {tableData?.length} {t("common.recordsFound")}
          </Typography>
        }
      />
    </>
  );
};

export default CreateDistributionListTable;
