import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { GridCloseIcon } from "@mui/x-data-grid";
import WarningIcon from "assets/images/warning-2.svg";
import { NonEditableField } from "components/shared";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetAuthorizationPersonnelsAuthenticationsMutation,
  useGetValidateChallengeResponseMutation
} from "services/api";
import spacing from "styles/spacing";
import { VALUE_Y } from "utils/constants";
import {
  generateSHA256Hash,
  getAuthenticatedUserBranch,
  getLanguageId
} from "utils/helpers";
import { AuthRequestModalStyles } from "./AuthRequestModal.Styles";

const AuthRequestModal = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const [
    getAuthorizationPersonnelsData,
    {
      data: authroizedPersonnelsData = {},
      reset: resetAuthroizedPersonnelsData
    }
  ] = useGetAuthorizationPersonnelsAuthenticationsMutation(); // get authorized personnel's details
  const [
    triggerValidateChallengeResponse,
    { isLoading: validatingChallengeResponse }
  ] = useGetValidateChallengeResponseMutation(); //checking the challenge response

  const [authorizationStatus, setAuthorizationStatus] = useState({
    authorizedBy: 0,
    isAuthenticated: false,
    isError: false
  });

  const [authDetails, setAuthDetails] = useState({
    authNumber: "",
    challengeQuestion: "",
    challengeResponse: ""
  });

  useEffect(() => {
    setAuthorizationStatus((prevState) => ({
      ...prevState,
      authorizedBy: props?.authorizedBy || 0
    }));
  }, [props.authorizedBy]);

  // fetch selected authorized personnel's details
  useEffect(() => {
    if (authorizationStatus.authorizedBy > 0) {
      getAuthorizationPersonnelsData({
        personnelId: authorizationStatus?.authorizedBy
      })
        .then((resp) => resp.data)
        .then((resp) => {
          setAuthorizationStatus((prevState) => ({
            ...prevState,
            isAuthenticated: false,
            isError: false
          }));
          setAuthDetails((prevState) => ({
            ...prevState,
            authNumber: "",
            challengeQuestion: resp.secretQuestion,
            challengeResponse: ""
          }));
        });
    } else {
      resetAuthroizedPersonnelsData();
      setAuthorizationStatus((prevState) => ({
        ...prevState,
        isAuthenticated: false,
        isError: false
      }));
      setAuthDetails((prevState) => ({
        ...prevState,
        authNumber: "",
        challengeQuestion: "",
        challengeResponse: ""
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationStatus?.authorizedBy]);

  // handle request authroization event
  const handleAuthorization = async ({ target }) => {
    //generate hash code
    let validateChallengeResponse;
    if (authDetails.challengeResponse?.length > 0) {
      let hashCode = await generateSHA256Hash(
        authDetails.challengeResponse.trim().toUpperCase()
      );

      //challenge response validation
      validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: getAuthenticatedUserBranch(),
        language_locale_id: getLanguageId(),
        personnel_id: authorizationStatus.authorizedBy,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          if (data[0]?.is_match_flag) {
            return data[0].is_match_flag;
          }
        });
    }

    if (
      authroizedPersonnelsData?.authNumber === authDetails?.authNumber ||
      validateChallengeResponse === VALUE_Y
    ) {
      props.onSubmit();
      setAuthorizationStatus((prevState) => ({
        ...prevState,
        isAuthenticated: true,
        isError: false
      }));
    } else {
      setAuthorizationStatus((prevState) => ({
        ...prevState,
        isAuthenticated: false,
        isError: true
      }));
    }
  };

  // cancel button click event handler
  const handleCancelClick = (event) => {
    props.onClose(false);
    props.setCancelModalWarning(false);
  };

  return (
    <>
      <Modal
        open={props.show}
        aria-labelledby="auth-request-modal"
        aria-describedby="auth-request-modal-content"
        sx={AuthRequestModalStyles.modal}
      >
        <Card sx={AuthRequestModalStyles.card}>
          {error && (
            <Alert
              id="eventDetailErrorAlert"
              icon={<img src={WarningIcon} alt="Warning" />}
              severity="error"
              color="error"
              onClose={() => setError("")}
              sx={AuthRequestModalStyles.error}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          )}

          {/* ------------------ Request Authorization ------------------ */}
          <CardContent>
            {/*Authorization title*/}
            <Typography
              id="typography3"
              color="primary"
              variant="h5"
              sx={AuthRequestModalStyles.authorizationTitle}
            >
              {t("disasterRecovery.eventDetail.personnelValidation")}
            </Typography>

            <Grid
              id="eventDetailAuthGrid27"
              container
              columnSpacing={spacing.verticalMargin64}
              paddingBottom={spacing.verticalMargin20}
              item
              xs={12}
            >
              {/* Customer */}
              {props.customer?.label && (
                <Grid
                  id="eventDetailAuthGrid29"
                  columnSpacing={spacing.verticalMargin64}
                  item
                  xs={12}
                  md={6}
                  lg={3}
                >
                  <NonEditableField
                    id="eventDetailCustomerSelected"
                    label={t("disasterRecovery.eventDetail.customerLabel")}
                    value={props.customer?.label}
                  />
                </Grid>
              )}

              {/* Personnel */}
              <Grid id="eventDetailAuthGrid29" item xs={12} md={6} lg={3}>
                <NonEditableField
                  id="eventDetailAuthPersonnel"
                  label={t("disasterRecovery.eventDetail.personnel")}
                  value={props.personnel}
                />
              </Grid>
            </Grid>

            {/* Authorization Form Controls */}
            <Grid
              id="eventDetailAuthGrid15"
              container
              spacing={spacing.verticalMargin20}
            >
              {authorizationStatus.authorizedBy > 0 &&
                !authorizationStatus.isAuthenticated && (
                  <>
                    {/* Personal Authentication Helper Text */}
                    <Grid id="eventDetailAuthGrid21" item xs={12}>
                      <Typography id="eventDetailAuthHelper" variant="body2">
                        {t("common.authInfo")}
                      </Typography>
                    </Grid>

                    {/* Auth Number field */}
                    <Grid id="eventDetailGrid22" item xs={12} md={6} lg={3.5}>
                      <TextField
                        id="eventDetailAuthNumber"
                        name="authNumber"
                        label={t("common.authNumber")}
                        disabled={Boolean(authDetails.challengeResponse)}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={authDetails.authNumber}
                        onChange={(e) =>
                          setAuthDetails((prevState) => ({
                            ...prevState,
                            authNumber: e.target.value
                          }))
                        }
                      />
                    </Grid>

                    {/* Authorization Challenge Question field */}
                    {Boolean(authDetails.challengeQuestion) && (
                      <Grid
                        id="eventDetailAuthGrid23"
                        item
                        xs={12}
                        md={6}
                        lg={3.5}
                      >
                        <NonEditableField
                          id="eventDetailAuthChallengeQuestion"
                          label={t("common.challengeQuestion")}
                          value={authDetails.challengeQuestion}
                        />
                      </Grid>
                    )}

                    {/* Authorization Challenge Response field */}
                    {Boolean(authDetails.challengeQuestion) && (
                      <Grid
                        id="eventDetailAuthGrid24"
                        item
                        xs={12}
                        md={6}
                        lg={3.5}
                      >
                        <TextField
                          id="eventDetailAuthChallengeResponse"
                          name="authorizationChallengeResponse"
                          type="password"
                          disabled={Boolean(authDetails.authNumber)}
                          label={t("common.challengeResponse")}
                          size="medium"
                          sx={AuthRequestModalStyles.FlexShrinkNoGrow}
                          variant="outlined"
                          value={authDetails.challengeResponse}
                          onChange={(e) =>
                            setAuthDetails((prevState) => ({
                              ...prevState,
                              challengeResponse: e.target.value
                            }))
                          }
                        />
                      </Grid>
                    )}

                    {/* Authentication failed error message */}
                    {authorizationStatus.isError && (
                      <Grid id="eventDetailAuthGrid26" item xs={12}>
                        <Alert
                          id="eventDetailAuthenticateErrorAlert"
                          severity="error"
                          color="error"
                          sx={AuthRequestModalStyles.alertStyle}
                        >
                          {t("common.authenticateFailed")}
                        </Alert>
                      </Grid>
                    )}
                  </>
                )}
            </Grid>
          </CardContent>

          <Divider />

          <CardActions sx={AuthRequestModalStyles.cardActionsStyle_PRSPopUP}>
            {/* Cancel Button */}
            <Button
              id="confirmExitModalCancel"
              sx={AuthRequestModalStyles.buttonStyle_PRSPopUP}
              variant="outlined"
              type="button"
              onClick={handleCancelClick}
            >
              {t("mediaRequestDetail.cancel")}
            </Button>
            {/* Authenticate Button */}
            <Button
              id="eventDetailAuthenticateButton"
              sx={AuthRequestModalStyles.buttonStyle_PRSPopUP}
              variant="contained"
              type="button"
              onClick={handleAuthorization}
            >
              {validatingChallengeResponse && (
                <CircularProgress sx={AuthRequestModalStyles.progressIcon} />
              )}
              {t("mediaRequestDetail.authenticate")}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

AuthRequestModal.propTypes = {
  show: PropTypes.bool, // show/hide modal state
  customer: PropTypes.string, // id of the authorized personnel
  personnels: PropTypes.string, // personnels of the customer
  timestamp: PropTypes.string.isRequired, // timestamp of the request
  onClose: PropTypes.func, // handle modal close event
  onSubmit: PropTypes.func // handle modal submit event
};

export default AuthRequestModal;
