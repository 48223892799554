import CheckIcon from "@mui/icons-material/Check";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { PersonnelsField } from "components/core/personnel-field/PersonnelsField";
import { Form, Formik } from "formik";
import { t } from "i18next";
import { useEffect, useRef } from "react";
import { RescheduleServiceFormStyles } from "../reschedule-service-form/RescheduleServiceFormStyles";
import AuthInfoSection from "./AuthInfoSection";
import { PersonnelAuthenticationFormStyles } from "./PersonnelAuthenticationFormStyles.style";
const personnelAuthState = {
  authorizedBy: "",
  authFailedAlertOpen: false,
  authSuccess: false,
  openAuth: false,
  challengeQuestion: "",
  challengeResponse: "",
  resultChallengeAns: "",
  resultAuth: "",
  authNumber: ""
};
const PersonnelAuthentication = ({
  value,
  customer,
  onAuthSubmit,
  error,
  saveSuccess
}) => {
  const formikRef = useRef();
  const rescheduleFormformikVal = value;

  const handlePersonnelChange = (selectedValue) => {
    if (selectedValue !== null && selectedValue !== undefined) {
      // Set formik values
      formikRef.current.setFieldValue("authorizedBy", selectedValue.value);
      formikRef.current.setFieldValue("personnelId", selectedValue.value);
      formikRef.current.setFieldValue("authNumber", "");
      formikRef.current.setFieldValue("challengeResponse", "");
      formikRef.current.setFieldValue("authFailedAlertOpen", false);
    } else {
      // Reset formik values
      formikRef.current.setFieldValue("authorizedBy", "");
      formikRef.current.setFieldValue("personnelId", null);
    }
  };

  useEffect(() => {
    if (saveSuccess) {
      formikRef.current.setFieldValue("authSuccess", false);
      formikRef.current.setFieldValue("openAuth", false);
    }
  }, [saveSuccess]);

  useEffect(() => {
    formikRef.current.resetForm();
  }, [customer?.value]);

  return (
    <Formik initialValues={personnelAuthState} innerRef={formikRef}>
      {({ values, errors, handleBlur, handleChange }) => {
        return (
          <Form>
            <Stack spacing={1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {t("rescheduleService.personnelAuth")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <PersonnelsField
                    handlePersonnelOnChange={handlePersonnelChange}
                    customerId={customer?.value}
                    value={values.personnelId}
                    form={{ touched: {}, errors: { error } }}
                    key={saveSuccess}
                  />
                </Grid>
                {values.authSuccess && (
                  <Grid item sx={RescheduleServiceFormStyles.MarginLeft20}>
                    <Box
                      sx={{
                        ...PersonnelAuthenticationFormStyles.AuthSuccess
                      }}
                    >
                      <CheckIcon
                        sx={{
                          ...PersonnelAuthenticationFormStyles.SuccessColor,
                          mr: 1
                        }}
                      />
                      <Typography
                        variant="subtitle1"
                        sx={PersonnelAuthenticationFormStyles.SuccessColor}
                      >
                        {t("rescheduleService.authenticated")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <AuthInfoSection
                  rescheduleFormformikVal={rescheduleFormformikVal}
                  onAuthSubmit={onAuthSubmit}
                  personnelId={values.personnelId}
                  customer={customer}
                  handleChange={handleChange}
                />
              </Grid>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonnelAuthentication;
