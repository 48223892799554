import dayjs from "dayjs";

const today = new Date();
export const maxDate = dayjs(today.setMonth(today.getMonth() + 6));

const future = new Date();
export const returnOnMaxDate = dayjs(future.setDate(future.getDate() + 30));

const tomorrow = new Date();
export const returnOnMinDate = dayjs(tomorrow.setDate(tomorrow.getDate() + 1));

const requestDate = new Date();
export const requestMaxDate = dayjs(
  requestDate.setFullYear(requestDate.getFullYear() + 1)
);

export const customerListMinDate = dayjs().subtract("2", "years");

export const eventTypeIDInputProps = { min: 0, max: 999999999 };

export const phoneInputProps = { maxLength: 10 };

export const phoneRegex = /^[0-9()+-]*$/;

export const RTF = ".rtf";

export const replaceValueFromMessageDescr = "| |";

export const drInstructionsNew = "new";
export const drInstructionsUpdate = "update";
export const drInstructionsDelete = "delete";
export const drInstructionsExist = "exist";

export const drInstructionsDownloadWarningMessageCode = "26205";
export const drOutBoundMessageCode = "26204";
export const instMsgCodes = {
  msgCode26180: "26180",
  msgCode26181: "26181",
  msgCode26182: "26182",
  msgCode26183: "26183",
  msgCode26184: "26184",
  msgCode26185: "26185",
  msgCode26186: "26186",
  msgCode26187: "26187",
  msgCode26188: "26188",
  msgCode26189: "26189",
  msgCode26190: "26190",
  msgCode26191: "26191",
  msgCode26192: "26192",
  msgCode26193: "26193",
  msgCode26194: "26194",
  msgCode26195: "26195",
  msgCode26196: "26196",
  msgCode26197: "26197",
  msgCode26198: "26198",
  msgCode26199: "26199",
  msgCode26083: "26083",
  msgCode26086: "26086",
  msgCode26088: "26088",
  msgCode26089: "26089",
  msgCode26090: "26090",
  msgCode26091: "26091",
  msgCode26092: "26092",
  msgCode26098: "26098",
  msgCode26093: "26093",
  msgCode26094: "26094",
  msgCode26095: "26095",
  msgCode26105: "26105",
  msgCode26113: "26113"
};

export const defaultCountryCode = {
  id: "1",
  country_id: "1",
  alt_name: "United States",
  iso2_code: "US",
  date_style: "101",
  name: "United States of America",
  iso3_code: "USA",
  active_flag: "Y",
  phone_country_code: "1",
  value: "1",
  label: "United States of America - 1"
};

export const id = "2";
export const statusToFilter = ["Inbound", "Outbound", "Reconciled"];
export const eventStatus = {
  newEvent: "0",
  logged: "1",
  outBound: "2",
  inBound: "3",
  reconciled: "4",
  complete: "5",
  cancel: "6"
};
export const unexpiredMedia = {
  retainOpenMedia: "Y",
  indefiniteRetention: "N"
};
export const expiredMedia = {
  returnNextDay: "N",
  returnOnNextSchedule: "S",
  returnOn: "D"
};
export const deliveryRequest = "deliveryRequest";
export const otherRequest = "otherRequest";
export const reqGenType = {
  deliveryRequest: "D",
  otherRequest: "O"
};
export const buildType = {
  landingPage: "-1",
  customerList: "0",
  drFile: "1",
  DRPCode: "2",
  inboundDate: "3",
  logicalVault: "4",
  manualEntryImport: "5"
};
export const serviceLocation = {
  recoverySite: "0",
  customerLocation: "1"
};
export const customerListType = {
  distributionList: "2",
  inventoryFile: "4"
};

export const secureSyncSource = "SecureSync";
