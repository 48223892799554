import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";

import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import { ALL_SELECTION, CLEAR, ERROR_TYPES, errorMsgs } from "utils/constants";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";
import { ReportsStyles } from "../ReportsStyles";
import { openMediaContainerInfo } from "../Services";

export const InventoryFileExtract = ({
  isOpen,
  onCloseModal,
  mainDistrictId
}) => {
  const exactInventoryBy = t("reports.inventoryFileExtract.exactInventory", {
    returnObjects: true
  });
  const extractContent = t(
    "reports.inventoryFileExtract.exactInventoryContent",
    {
      returnObjects: true
    }
  );

  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([ALL_SELECTION]);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedInventoryBy, setSelectedInventoryBy] = useState(null);
  const [selectedExtractContent, setSelectedExtractContent] = useState(null);

  const handlePrint = async () => {
    if (!selectedInventoryBy) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("reports.extractInventoryBy")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
    } else if (!selectedExtractContent) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("reports.extractContent")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
    } else if (!selectedCustomer) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      let replacedText = errorMsg[0]?.descr.replace("|", t("common.customer"));
      setThrowErrorMessage(replacedText);
      setThrowError(true);
    } else {
      const data = {
        extractInventoryBy: selectedInventoryBy,
        customers: selectedCustomer,
        extractContent: selectedExtractContent
      };

      const customers = data?.customers;
      const customerLabels = customers.label.split("-")?.[0]?.trim(" ");
      try {
        setIsLoading(true);
        let res = await openMediaContainerInfo(mainDistrictId, data);

        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const currentDate = `${year}${month}${day}`;
        if (res) {
          const rows = res?.data?.[1].map((row) => {
            const values = row?.extract_line
              .split(",")
              .map((value) => value.trim());
            return values;
          });
          const csvOutput = rows.join("\n");
          const csvContent = csvOutput;
          const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;"
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${customerLabels}_audit_${currentDate}.csv`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
        onCloseModal(false);
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));
        setCustomers((prev) => data);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCustomers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCustomerChange = (e, newValue, reason) => {
    if (reason === CLEAR) {
      setSelectedCustomer("");
    }
    if (newValue) {
      newValue = { label: newValue?.label, value: newValue.value.trim() };
      setSelectedCustomer(newValue);
      setThrowError(false);
    }
  };
  const handleExtractContent = (event, value) => {
    setSelectedExtractContent(value);
    setThrowError(false);
  };
  const handleExtractInventoryBy = (event, value) => {
    setSelectedInventoryBy(value);
    setThrowError(false);
  };
  const handleReset = () => {
    setSelectedExtractContent(null);
    setSelectedInventoryBy(null);
    setSelectedCustomer("");
    setThrowError(false);
  };

  const customersFieldProps = {
    options: customers,
    handleOnChange: handleCustomerChange,
    value: selectedCustomer,
    required: true,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.inventoryExtractFileCardTitle")}
        body={
          <>
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}
            {throwError && <br />}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  clearIcon={<CloseIcon />}
                  name={t("reports.extractInventoryBy")}
                  disabled={null}
                  options={exactInventoryBy}
                  getOptionLabel={(option) => option.name}
                  size="medium"
                  value={selectedInventoryBy}
                  onChange={handleExtractInventoryBy}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("reports.extractInventoryBy")}
                    />
                  )}
                  required
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <CustomersField {...customersFieldProps} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={ReportsStyles.inventoryFileExtractStyles}
            >
              <Grid item xs={6}>
                <Autocomplete
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  clearIcon={<CloseIcon />}
                  name={t("reports.extractContent")}
                  disabled={false}
                  options={extractContent}
                  getOptionLabel={(option) => option.name}
                  value={selectedExtractContent}
                  onChange={handleExtractContent}
                  size="medium"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("reports.extractContent")}
                    />
                  )}
                  required
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button variant="outlined" onClick={handleReset}>
              {t("common.reset")}
            </Button>
            <Button variant="contained" onClick={handlePrint}>
              {t("common.createFile")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default InventoryFileExtract;
