import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";

import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  CLEAR,
  dateFileNameFormat,
  dateFormatWithoutTime,
  downloadReportOptions,
  ERROR_TYPES,
  errorMsgs,
  numberConstants,
  VALUE_N,
  VALUE_Y,
  yearMonthDayFormat,
  ymdDateFormat
} from "utils/constants";
import {
  arrowDownKey,
  aTag,
  branch,
  constAll,
  csvFormat,
  dailyBillableActivityCountsbyLogicalVault,
  dailyBillableActivityCountsSummary,
  dataHeadersForeverSafeBillingSummary,
  day,
  disasterRecoveryActivityCounts,
  dropDownValues,
  fileExportRadio,
  foreverSafeBillingSummary,
  ForeverSafeBillingSummaryFileName,
  minusKey,
  month,
  monthlyBillableActivityCounts,
  monthlyManagedContainerContentsCount,
  monthlyManagedContainerCountSettingTypeId,
  reportRadio,
  scanAtCustomerBillingActivityCounts,
  scanAtCustomerCountreportSettingtypeId,
  year
} from "utils/constants/reports-module/Reports";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getUserName,
  printReport,
  toFileTimeUtc
} from "utils/helpers";
import { ReportsStyles } from "../ReportsStyles";
import { getSettingsValueReportDropdown } from "../Services";

export const BillableActivity = ({
  isOpen,
  onCloseModal,
  setCommonDownloadModal,
  getCallbackData
}) => {
  // intialStates
  const selectedValue = downloadReportOptions;
  const { localeByBranch, currentBranch, auth } = useSelector(selectAuth);
  const userName = getUserName(auth?.user);
  const pdfFormat = selectedValue[0].type.toLocaleUpperCase();
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState(ALL_SELECTION);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [selectedReports, setSelectedReports] = useState(
    dailyBillableActivityCountsbyLogicalVault
  );
  const [showPrintModal, setShowPrintModal] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [reportsList, setReportsList] = useState([]);
  const today = dayjs();
  const minDate = today.subtract(24, year);
  const defaultDate = today.subtract(1, day);
  const [selectedToDate, setSelectedToDate] = useState(defaultDate);
  const [selectedFromDate, setSelectedFromDate] = useState(defaultDate);
  const [dailyCheckBox, setDailyCheckBox] = useState(false);
  const [selectedRadioButton, setSelectedRadioButton] = useState(reportRadio);
  const [drEventId, setDrEventId] = useState("");
  const [allbranchsCheckBox, setAllBranchsCheckBox] = useState(false);
  const [isDateDisable, setIsDateDisable] = useState(false);

  const exportCfCall = async () => {
    if (selectedReports === dailyBillableActivityCountsSummary) {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.value,
        date_from: dayjs(selectedFromDate).format(ymdDateFormat),
        date_to: dayjs(selectedToDate).format(ymdDateFormat),
        customer_id:
          selectedCustomer?.value === constAll ? "" : selectedCustomer?.value,
        breakout_handling: VALUE_N
      });
      return await getResponseData(
        reqBody,
        CF_URLS.reports.billingActivityCountExport,
        4
      );
    } else if (selectedReports === monthlyBillableActivityCounts) {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.value,
        date_from: dayjs(selectedFromDate).format(ymdDateFormat),
        date_to: dayjs(selectedToDate).format(ymdDateFormat),
        customer_id:
          selectedCustomer?.value === constAll ? "" : selectedCustomer?.value
      });
      return await getResponseData(
        reqBody,
        CF_URLS.reports.monthlyBillableActivityCountsExport,
        4
      );
    } else if (selectedReports === foreverSafeBillingSummary) {
      if (allbranchsCheckBox) {
        const reqBody = JSON.stringify({
          main_district_id: currentBranch?.value,
          eom_billing_date: ""
        });
        return await getResponseData(
          reqBody,
          CF_URLS.reports.getCapacityBillingCountAll,
          4
        );
      } else {
        const reqBody = JSON.stringify({
          main_district_id: currentBranch?.value,
          customer_id:
            selectedCustomer?.value === constAll ? "" : selectedCustomer?.value,
          eom_billing_date: ""
        });
        return await getResponseData(
          reqBody,
          CF_URLS.reports.getCapacityBillingCount,
          4
        );
      }
    }
  };
  function extractThirdItem(lineOut) {
    let items = lineOut.split(",");
    return items[2].replace(/^(="|")|("$)/g, "");
  }

  const handleDownloadCSV = (data, fileName) => {
    let content = "";
    let blob;

    if (data?.length > 0) {
      content = data.map((item) => item.line_out).join("");
    } else {
      content = dataHeadersForeverSafeBillingSummary
        ?.map((item) => item.line_out)
        .join("");
    }
    blob = new Blob([content], { type: csvFormat });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement(aTag);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleExport = async () => {
    try {
      setIsLoading(true);
      const dataSets = await exportCfCall();
      if (!dataSets) {
        if (selectedReports === monthlyManagedContainerContentsCount) {
          handlePrint();
        }
        return;
      }
      if (selectedReports === dailyBillableActivityCountsSummary) {
        exportToTxt(dataSets.data[0]);
      } else if (selectedReports === monthlyBillableActivityCounts) {
        exportToTxt(dataSets.data[1]);
      } else if (selectedReports === foreverSafeBillingSummary) {
        let dataToCreateFile = dataSets?.data[0] || [];

        let headerIndex = dataToCreateFile?.findIndex((item) =>
          item?.line_out?.startsWith(branch)
        );

        let headerItem = "";
        if (headerIndex > 0 && dataToCreateFile.length > headerIndex) {
          headerItem = dataToCreateFile.splice(headerIndex, 1)[0];
        } else {
          let [first, ...rest] = dataToCreateFile;
          headerItem = first;
          dataToCreateFile = rest;
        }
        let sortedData = dataToCreateFile?.sort((a, b) => {
          let itemA = extractThirdItem(a.line_out)?.toLowerCase();
          let itemB = extractThirdItem(b.line_out)?.toLowerCase();
          return itemA?.localeCompare(itemB);
        });
        if (headerItem) {
          sortedData.unshift(headerItem);
        }
        handleDownloadCSV(
          sortedData,
          `${ForeverSafeBillingSummaryFileName}${dayjs().format(
            dateFileNameFormat
          )}`
        );
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      let reportResponse = null;
      if (selectedReports === dailyBillableActivityCountsbyLogicalVault) {
        const reqBody = JSON.stringify({
          main_district_id: currentBranch?.value,
          district_id: currentBranch?.value,
          date_from: dayjs(selectedFromDate).format(ymdDateFormat),
          date_to: dayjs(selectedToDate).format(ymdDateFormat),
          customer_id: selectedCustomer?.value,
          customer_number: selectedCustomer?.label?.split("-")[0],
          include_detail: dailyCheckBox
            ? numberConstants.one
            : numberConstants.zero,
          district_name: currentBranch?.name,
          user_name: userName,
          report_format: pdfFormat
        });
        reportResponse = await printReport(
          reqBody,
          `${CF_URLS.printReports.reports.dailyBillableActivityCountsbyLogicalVaultReport}`
        );
      } else {
        const reqBody = JSON.stringify({
          main_district_id: currentBranch?.value,
          date_from: dayjs(selectedFromDate).format(ymdDateFormat),
          date_to: dayjs(selectedToDate).format(ymdDateFormat),
          customer_id:
            selectedCustomer === constAll ? "" : selectedCustomer?.value,
          export_flag: VALUE_Y
        });
        reportResponse = await printReport(
          reqBody,
          `${CF_URLS.reports.monthlyManagerCountsExport}`
        );
      }
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      } else {
        setShowPrintModal(false);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const convertToTabSeparatedText = (data) => {
    // Define headers based on the required format
    if (selectedReports === dailyBillableActivityCountsSummary) {
      const headers = [
        t("billingActivity.customer"),
        t("billingActivity.dateFrom"),
        t("billingActivity.dateTo"),
        t("billingActivity.trips"),
        t("billingActivity.3Party"),
        t("billingActivity.holidayTrips"),
        t("billingActivity.criticalSpecial"),
        t("billingActivity.criticalSpecialCharge"),
        t("billingActivity.standardSpecial"),
        t("billingActivity.criticalSpecialCharge"),
        t("billingActivity.containerHandling"),
        t("billingActivity.transportHandling"),
        t("billingActivity.openMediaHandling"),
        t("billingActivity.locks"),
        t("billingActivity.clips"),
        t("billingActivity.insert")
      ];

      // Map each data object to match the header order
      const rows = data.map((row) => [
        `"${row.customer_number || ""}"`,
        `"${
          dayjs(row?.date_from)
            ?.locale(localeByBranch)
            .format(dateFormatWithoutTime) || ""
        }"`,
        `"${
          dayjs(row?.date_to)
            ?.locale(localeByBranch)
            .format(dateFormatWithoutTime) || ""
        }"`,
        `"${row.im_trip_count || "0"}"`,
        `"${row.third_party_trip_count || "0"}"`,
        `"${row.holiday_trip_count || "0"}"`,
        `"${row.critical_nocharge_count || "0"}"`,
        `"${row.critical_charge_count || "0"}"`,
        `"${row.standard_nocharge_count || "0"}"`,
        `"${row.standard_charge_count || "0"}"`,
        `"${row.container_handling_ct || "0"}"`,
        `"${row.transport_handling_ct || "0"}"`,
        `"${row.om_scheduled_ct || "0"}"`,
        `"${row.locks_count || "0"}"`,
        `"${row.clips_count || "0"}"`,
        `"${row.inserts_count || "0"}"`
      ]);

      // Join headers and rows with tabs and newlines
      const headerRow = headers.map((header) => `"${header}"`).join("\t");
      const dataRows = rows.map((row) => row.join("\t")).join("\n");

      return `${headerRow}\n${dataRows}`;
    } else if (selectedReports === monthlyBillableActivityCounts) {
      const headers = [
        t("billingActivity.customer"),
        t("billingActivity.dateFrom"),
        t("billingActivity.dateTo"),
        ...new Set(data.map((item) => item.header_name).filter((name) => name))
      ];

      const allowedItems = [
        t("billingActivity.customer"),
        t("billingActivity.dateFrom"),
        t("billingActivity.dateTo"),
        t("billingActivity.openMediaVault")
      ];

      const initialData = headers.filter((item) => allowedItems.includes(item));
      const containerItems = headers
        ?.filter((item) =>
          item.toLowerCase().includes(t("billingActivity.container"))
        )
        ?.sort((a, b) => a.localeCompare(b));
      const restItems = headers
        ?.filter(
          (item) =>
            !item.toLowerCase().includes(t("billingActivity.container")) &&
            !allowedItems?.includes(item)
        )
        ?.sort((a, b) => a.localeCompare(b));
      const combineData = [...initialData, ...containerItems, ...restItems];
      // Group data by `customer_number` and create a mapping for each customer entry
      const customerMap = data.reduce((acc, item) => {
        const { customer_number, header_name, vaulting_count } = item;

        if (!acc[customer_number]) {
          acc[customer_number] = {
            [t("billingActivity.customer")]: `"${customer_number}"`,
            [t("billingActivity.dateFrom")]: `"${dayjs(selectedFromDate)
              .locale(localeByBranch)
              .format(dateFormatWithoutTime)}"`,
            [t("billingActivity.dateTo")]: `"${dayjs(selectedToDate)
              .locale(localeByBranch)
              .format(dateFormatWithoutTime)}"`
          };
        }
        // Assign the vaulting_count to the correct header_name column
        acc[customer_number][header_name] = `"${vaulting_count}"`;
        return acc;
      }, {});

      // Generate rows by aligning data with headers
      const rows = Object.values(customerMap).map((row) =>
        combineData
          .map((header) => row[header] || '""') // Default empty string for missing data
          .join("\t")
      );

      // Join headers with tab separation
      const headerRow = combineData.map((header) => `"${header}"`).join("\t");
      // Combine header row and data rows into the final output
      return `${headerRow}\n${rows.join("\n")}`;
    }
  };

  // Export data to TXT
  const exportToTxt = (data) => {
    const textData = convertToTabSeparatedText(data);
    const blob = new Blob([textData], { type: "text/plain;charset=utf-8;" });
    const fileName = `${toFileTimeUtc(dayjs.utc())}.txt`;
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  const handlePrint = async () => {
    if (!selectedCustomer) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode20259);
      const replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("common.customer")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
      return;
    } else if (
      selectedToDate.isBefore(selectedFromDate) &&
      selectedFromDate.isAfter(selectedToDate) &&
      !selectedFromDate.isSame(selectedToDate)
    ) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode63111);
      const replacedDateTo = errorMsg[0]?.descr.replace(
        "|",
        t("reports.dateTo")
      );
      const replaceDateFrom = replacedDateTo.replace(
        "|",
        t("reports.dateFrom")
      );
      setThrowErrorMessage(replaceDateFrom);
      setThrowError(true);
      return;
    } else if (
      selectedReports === disasterRecoveryActivityCounts ||
      selectedReports === monthlyManagedContainerContentsCount
    ) {
      const fromDate = dayjs(selectedFromDate);
      const toDate = dayjs(selectedToDate);

      const differenceInMonths = toDate.diff(fromDate, month, true);

      if (differenceInMonths > 6) {
        let errorMsg = await getMessageFromCode(errorMsgs.errorCode63149);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
        setSelectedToDate(selectedFromDate); // Set To Date to From Date
        return;
      }
    }
    setThrowError(false);
    if ([dailyBillableActivityCountsbyLogicalVault].includes(selectedReports)) {
      setShowPrintModal(true);
    } else {
      const propData = {
        date_from: dayjs(selectedFromDate).format(yearMonthDayFormat),
        date_to: dayjs(selectedToDate).format(yearMonthDayFormat),
        customer_id:
          selectedCustomer?.value === ALL_SELECTION?.label
            ? ""
            : selectedCustomer?.value,
        customer_number:
          selectedCustomer?.value === ALL_SELECTION?.label
            ? ""
            : selectedCustomer?.label?.split("-")[0],
        breakout_handling: selectedReports,
        dr_event_id: drEventId
      };
      setCommonDownloadModal(true);
      getCallbackData(propData);
    }
    setIsLoading(false);
  };

  const hanldeRadioChecked = (value) => {
    setSelectedRadioButton(value);
  };
  const handleReportChange = (event, newValue, reason) => {
    if (reason === CLEAR) {
      setSelectedReports("");
      setThrowError(false);
    }
    if (newValue) {
      newValue = { label: newValue.label };
      setSelectedReports(newValue.label);
      setThrowError(false);
    }
    setSelectedRadioButton(reportRadio);
  };
  const handleToDateChange = async (date) => {
    setSelectedToDate(date);
    setThrowError(false);
  };
  const handleFromDateChange = async (date) => {
    setSelectedFromDate(date);
    setThrowError(false);
  };
  // get all Customers
  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));
        const defaultSelection =
          selectedReports === dailyBillableActivityCountsbyLogicalVault
            ? ""
            : ALL_SELECTION;
        setCustomers((prev) => [defaultSelection, ...data]);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCustomers();
    const defaultCustmerSelection =
      selectedReports === dailyBillableActivityCountsbyLogicalVault
        ? ""
        : ALL_SELECTION.value;

    setSelectedCustomer(defaultCustmerSelection);
    const startOfMonth = today.startOf(month);
    if (selectedReports === monthlyManagedContainerContentsCount) {
      setSelectedFromDate(startOfMonth);
      setSelectedToDate(today);
    } else if (selectedReports === disasterRecoveryActivityCounts) {
      setSelectedFromDate(startOfMonth);
      setSelectedToDate(today);
    } else {
      setSelectedFromDate(defaultDate);
      setSelectedToDate(defaultDate);
    }
    setIsDateDisable(false);
    setDrEventId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReports]);

  //defautDate

  useEffect(() => {
    if (
      selectedToDate.isBefore(selectedFromDate) &&
      selectedFromDate.isAfter(selectedToDate)
    ) {
      setSelectedToDate(selectedFromDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throwError]);

  // onChange for Customer Selection
  const handleCustomerChange = (e, newValue, reason) => {
    if (reason === CLEAR) {
      setSelectedCustomer("");
    }

    if (newValue) {
      newValue = { label: newValue.label, value: newValue.value.trim() };
      setSelectedCustomer(newValue);
      setThrowError(false);
    }
  };
  // customer feild  props
  const customersFieldProps = {
    options: customers,
    handleOnChange: handleCustomerChange,
    value: selectedCustomer,
    disabled: isDateDisable,
    required: true,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };
  //  get the Reports
  const activeReports = async () => {
    try {
      setIsLoading(true);
      const data = await getSettingsValueReportDropdown();
      const settingList = data?.data?.[0] || [];

      const showManagedContainerCollection = settingList.some(
        (setting) =>
          setting.setting_type_id ===
            monthlyManagedContainerCountSettingTypeId &&
          setting.value === VALUE_Y
      );

      const showsSanCustomerCollection = settingList.some(
        (setting) =>
          setting.setting_type_id === scanAtCustomerCountreportSettingtypeId &&
          setting.value === VALUE_Y
      );

      const reportsToAdd = [];
      if (showManagedContainerCollection) {
        reportsToAdd.push({ label: monthlyManagedContainerContentsCount });
      }

      if (showsSanCustomerCollection) {
        reportsToAdd.push({ label: scanAtCustomerBillingActivityCounts });
      }

      const updatedDropDownValues = [...dropDownValues];
      const lastElement = updatedDropDownValues.pop();

      const updatedData = [
        ...updatedDropDownValues,
        ...reportsToAdd,
        lastElement
      ];

      setReportsList(updatedData);

      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    activeReports();
  }, []);
  const handleDrEventChange = async (e) => {
    const value = e.target.value;
    setDrEventId(value);
    // Check if field is empty
    if (!value) {
      setIsDateDisable(false);
      return true; // Empty input is valid, return true
    }

    // Check if value is numeric and less than or equal to 0
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue < 0) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode20360);
      const replaceText = errorMsg[0]?.descr.replace(
        "|",
        t("reports.dREventId")
      );
      setThrowErrorMessage(replaceText);
      setThrowError(true);
      return false; // Invalid input
    } else {
      if (numericValue > 0) {
        setIsDateDisable(true);
      } else {
        setIsDateDisable(false);
      }
    }

    // If all checks are passed, hide the error
    setThrowError(false);
  };

  const handleKeyDown = (e) => {
    // Prevent entering a minus sign
    if (e.key === minusKey || e.key === arrowDownKey) {
      e.preventDefault();
    }
  };

  // reste Functionality
  const handleReset = () => {
    setDailyCheckBox(false);
    setSelectedCustomer(ALL_SELECTION.value);
    setSelectedReports(dailyBillableActivityCountsbyLogicalVault);
    setThrowError(false);
  };

  const onChangeAllbranchCheckBox = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedCustomer(ALL_SELECTION);
    }
    setIsDateDisable(checked);
    setAllBranchsCheckBox(checked);
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.billableActivityCountsReportsCardTitle")}
        body={
          <>
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}
            <br />
            <Grid item lg={6} md={6} xs={12} spacing={spacing.gap}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                name={t("reports.title")}
                disabled={null}
                options={reportsList}
                size="medium"
                value={selectedReports}
                onChange={handleReportChange}
                renderInput={(params) => (
                  <TextField {...params} label={t("reports.title")} />
                )}
                required
              >
                {reportsList.map((val) => (
                  <MenuItem key={val.label} value={val.value}>
                    {val.label}
                  </MenuItem>
                ))}
              </Autocomplete>
              <br />
              <Typography style={ReportsStyles.paddingTop1}>
                {t("reports.selectionCriteria")}
              </Typography>
              <Grid
                container
                spacing={2}
                style={ReportsStyles.billingModalStyles.paddingTop1}
              >
                <Grid item xs={6}>
                  <CustomersField {...customersFieldProps} />
                </Grid>
                <Grid item xs={6}>
                  {selectedReports ===
                    dailyBillableActivityCountsbyLogicalVault && (
                    <>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={dailyCheckBox}
                              onChange={(e) =>
                                setDailyCheckBox(e.target.checked)
                              }
                            />
                          }
                          label={t("reports.includeDailyDetail")}
                        />
                      </FormGroup>
                    </>
                  )}
                  {selectedReports === foreverSafeBillingSummary && (
                    <>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={allbranchsCheckBox}
                              onChange={(e) => onChangeAllbranchCheckBox(e)}
                            />
                          }
                          label={t("reports.allBranches")}
                        />
                      </FormGroup>
                    </>
                  )}
                  {selectedReports === disasterRecoveryActivityCounts && (
                    <>
                      {
                        <TextField
                          type="number"
                          label={t("reports.drEventId")}
                          fullWidth
                          value={drEventId}
                          onChange={handleDrEventChange}
                          onKeyDown={handleKeyDown}
                          disabled={null}
                          inputProps={{ maxLength: 20 }}
                        />
                      }
                    </>
                  )}
                </Grid>
              </Grid>
              {selectedReports === monthlyManagedContainerContentsCount && (
                <Typography
                  style={ReportsStyles.billingModalStyles.paddingTop3}
                >
                  {t("reports.drEventDateRange")}
                </Typography>
              )}
              {selectedReports !== foreverSafeBillingSummary && (
                <Grid
                  container
                  spacing={2}
                  style={ReportsStyles.billingModalStyles.paddingTop2}
                >
                  <Grid item xs={6}>
                    <DatePickerField
                      label={t("reports.dateFrom")}
                      name="dateFrom"
                      value={dayjs(selectedFromDate)}
                      disabled={isDateDisable}
                      minDate={minDate}
                      maxDate={today}
                      onChange={handleFromDateChange}
                      locale={localeByBranch}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerField
                      label={t("reports.dateTo")}
                      value={dayjs(selectedToDate)}
                      disabled={isDateDisable}
                      minDate={minDate}
                      maxDate={today}
                      onChange={handleToDateChange}
                      locale={localeByBranch}
                    />
                  </Grid>
                </Grid>
              )}
              {(selectedReports === dailyBillableActivityCountsSummary ||
                selectedReports === monthlyBillableActivityCounts ||
                selectedReports === monthlyManagedContainerContentsCount) && (
                <>
                  <Typography
                    style={ReportsStyles.billingModalStyles.paddingTop3}
                  >
                    {t("reports.output")}
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    style={ReportsStyles.billingModalStyles.paddingTop3}
                  >
                    <Grid item xs={6}>
                      <FormControlLabel
                        id="personnel-authentication"
                        value={"reportRadioButton"}
                        control={
                          <Radio
                            checked={selectedRadioButton === reportRadio}
                            onChange={() => hanldeRadioChecked(reportRadio)}
                          />
                        }
                        sx={ReportsStyles.radioButton}
                        label={t("reports.reportTitle")}
                      />
                      <FormControlLabel
                        id="personnel-authentication"
                        value={"fileExportRadioButton"}
                        control={
                          <Radio
                            checked={selectedRadioButton === fileExportRadio}
                            onChange={() => hanldeRadioChecked(fileExportRadio)}
                          />
                        }
                        sx={ReportsStyles.radioButton}
                        label={t("reports.fileExport")}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button variant="outlined" onClick={handleReset}>
              {t("common.reset")}
            </Button>

            <Button
              variant="contained"
              onClick={
                selectedReports === foreverSafeBillingSummary ||
                selectedRadioButton === fileExportRadio
                  ? handleExport
                  : handlePrint
              }
            >
              {selectedReports === foreverSafeBillingSummary ||
              selectedRadioButton === fileExportRadio
                ? t("common.export")
                : t("common.print")}
            </Button>
          </>
        }
      />
      {showPrintModal && (
        <DownloadPopup
          title={t("common.downloadReport")}
          showPrintModal={showPrintModal}
          downloadOptions={downloadReportOptions}
          onDownloadClick={handleDownload}
          onCancleClick={() => setShowPrintModal((prev) => !prev)}
        />
      )}
    </>
  );
};

export default BillableActivity;
