// Inventory

import { actionTypes } from "utils/constants/open-media-processing/WorkManagement";

// Initial state for Info messages
export const initialInfoState = {
  errorCode: "",
  showConfirmModal: false,
  infoMessage: ""
};

// Reducer function
export const inforeducer = (state, { type, code, show, message }) => {
  switch (type) {
    case actionTypes.setInfo:
      return { errorCode: code, showConfirmModal: show, infoMessage: message };
    case actionTypes.reset:
      return initialInfoState;
    default:
      return state;
  }
};
