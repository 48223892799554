export const fieldNames = {
  employeeId: "employee_id",
  firstName: "first_name",
  middleName: "middle_name",
  lastName: "last_name",
  employmentStartDate: "employment_start_date",
  employmentEndDate: "employment_end_date",
  jobTitleId: "job_title_id",
  operationalCenterId: "operational_center_id",
  businessEmailAddr: "business_email_addr",
  businessPhone: "business_phone",
  extention: "extention",
  country: "country",
  phoneCode: "phone_code",
  cellularPhone: "cellular_phone",
  pagerPhone: "pager_phone",
  homeEmailAddr: "home_email_addr",
  homePhone: "home_phone",
  payrollId: "payroll_id",
  emergencyContactFlag: "emergency_contact_flag",
  defaultBranchId: "default_branch_id",
  assignedBranchList: "assigned_branch_list",
  activeBranchList: "active_branch_list",
  securityGroupId: "security_group_id",
  loggedInEmployeeId: "logged_in_employee_id",
  districtId: "district_id",
  districtList: "district_list",
  localEmployeeDistrictList: "local_employee_district_list",
  localeId: "locale_id",
  email: "email",
  domain: "domain",
  startDate: "start_date",
  endDate: "end_date",
  countryPhnCode: "country_phn_code",
  businessPhnExt: "business_phone_ext"
};

export const systemAdmin = "SysAd";
export const manager = "Manager";
export const securityOptionValue = "security_group_id";
export const operationManager = "Operations Manager";
export const generalManager = "General Manager";
export const terminatedCode = "Terminated";
export const payrollLengthParam = {
  systemId: "60",
  settingTypeId: "1016"
};

export const empFailureCode = [50009, 63888];

export const empMsgCode = {
  endDate: 10141,
  jobTitle: 10075,
  payrollId: 10076,
  payrollIdValidation: 10080,
  payrollIdLengthNew: 80301,
  payrollIdLengthUpdate: 80302,
  ext: 50011,
  homePhone: 20354,
  phoneFormat: 63688,
  empFailure1: 20552,
  empFailure2: 63888,
  opCenter: 20553,
  confirm: 10039
};

export const phnType = {
  valid: "0",
  business: "1",
  cellular: "2",
  pager: "3",
  home: "4"
};

export const inputFormat = {
  string: "string",
  number: "number"
};
