import { Button, Grid, Stack, Typography } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import spacing from "styles/spacing";
import {
  dayMonthYearTimeFormat,
  DEFAULT_DATA_TABLE_OPTIONS
} from "utils/constants";
import { CustomerMoveStyles } from "./CustomerMoveDetailStyles";

const CustomerMoveDetailPopup = ({
  isAssignModalOpen,
  handleAssignModalClose,
  sourceBranch,
  destinationBranch,
  sourceCustomer,
  destinationCustomer,
  logsData
}) => {
  const sortedLogsData = logsData.sort((a, b) =>
    dayjs(b.last_mod_date).isAfter(dayjs(a.last_mod_date)) ? 1 : -1
  );

  const columns = [
    {
      name: "process_name",
      label: t("customerMoveDetail.processName")
    },
    { name: "message_type", label: t("customerMoveDetail.messageType") },
    { name: "message", label: t("customerMoveDetail.message") },
    {
      name: "event_process_status",
      label: t("customerMoveDetail.eventProcessStatus")
    },
    {
      name: "last_mod_date",
      label: t("customerMoveDetail.lastModifiedData"),
      options: {
        customBodyRender: (value) => {
          if (value) {
            return dayjs.utc(value).format(dayMonthYearTimeFormat);
          } else {
            return "";
          }
        },
        filter: false,
        sort: false
      }
    },
    {
      name: "login",
      label: t("customerMoveDetail.lastModifiedUser")
    }
  ];

  //export button click
  const handleExport = () => {
    const headers = columns.map((col) => col.label).join(",");

    const rows = sortedLogsData
      .map((row) =>
        columns.map((col) => `"${(row[col.name] || "").trim()}"`).join(",")
      )
      .join("\n");

    const csvContent = `${headers}\n${rows}`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "Customer_Move_Log.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  };

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    tableBodyHeight: CustomerMoveStyles.tableBodyHeight,
    tableBodyMaxHeight: CustomerMoveStyles.tableBodyMaxHeight,
    pagination: true,
    textLabels: {
      body: {
        noMatch:
          sortedLogsData?.length === 0 &&
          `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  return (
    <CommonModal
      open={isAssignModalOpen}
      title={t("customerMoveDetail.customerMoveDetailLog")}
      body={
        <>
          <Stack
            direction="column"
            gap={spacing.verticalMargin20}
            mb={spacing.verticalMargin32}
          >
            <Grid container spacing={spacing.horizontalMargin20}>
              <Grid item xs={3} id="source-customer-branch">
                <Stack>
                  <Typography variant="caption">
                    {t("customerMoveDetail.SourceBranch")}
                  </Typography>
                  <Typography variant="h7">{sourceBranch || ""}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={3} id="destination-customer-branch">
                <Stack>
                  <Typography variant="caption">
                    {t("customerMoveDetail.destinationBranch")}
                  </Typography>
                  <Typography variant="h7">
                    {destinationBranch || ""}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={spacing.horizontalMargin20}>
              <Grid item xs={3}>
                <Stack>
                  <Typography variant="caption">
                    {t("customerMoveDetail.sourceCustomer")}
                  </Typography>
                  <Typography variant="h7">{sourceCustomer || ""}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <Typography variant="caption">
                    {t("customerMoveDetail.destinationCustomer")}
                  </Typography>
                  <Typography variant="h7">
                    {destinationCustomer || ""}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>

          <MUIDataTable
            columns={columns}
            options={options}
            data={sortedLogsData || []}
            title={
              <Typography style={CustomerMoveStyles.titleStyle}>
                {sortedLogsData.length} {t("common.recordsFound")}
              </Typography>
            }
          />
        </>
      }
      buttons={
        <>
          <Button
            onClick={() => handleAssignModalClose(false)}
            variant="outlined"
          >
            {t("common.close")}
          </Button>
          <Button
            onClick={handleExport}
            variant="contained"
            disabled={!sortedLogsData.length}
          >
            {t("customerMoveDetail.export")}
          </Button>
        </>
      }
      minWidth={CustomerMoveStyles.minWidth}
    />
  );
};
export default CustomerMoveDetailPopup;
