import styled from "@emotion/styled";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { ReactComponent as CustomerIcon } from "assets/images/CustomerIcon.svg";
import { ReactComponent as NotepadIcon } from "assets/images/NotepadIcon.svg";
import { useEffect, useState } from "react";

import { SimpleTreeView } from "@mui/x-tree-view";
import { t } from "i18next";
import { DEFAULT_LOCALE } from "utils/constants";
import {
  inventoryNodeId,
  typeCustomer,
  typeItem,
  typeLogicalVault
} from "utils/constants/open-media-processing/WorkManagement";
import { dateTimeFormatByLocale } from "utils/helpers";
import { WorkManagementTreeStyles } from "../WorkManagementStyles";

const StyledTreeItem = styled(TreeItem)({
  "& .MuiTreeItem-iconContainer": WorkManagementTreeStyles.iconStyles
});

const InventoryTree = ({
  inventoryList,
  handleFocusInventoryChange,
  hasCompareList,
  localeByBranch
}) => {
  const [expanded, setExpanded] = useState([]);
  let customerIdList = [
    ...new Set(inventoryList?.map((inventory) => inventory.customer_id))
  ];

  useEffect(() => {
    if (!inventoryList.length) {
      setExpanded([]);
    } else {
      setExpanded([inventoryNodeId]);
    }
  }, [inventoryList]);

  // Grouping the Data under customer in-turn logical vault
  let groupedWithLogicalVault = {};
  inventoryList.forEach((inventory) => {
    if (!groupedWithLogicalVault[inventory.customer_id]) {
      groupedWithLogicalVault[inventory.customer_id] = {};
    }
    if (!groupedWithLogicalVault[inventory.customer_id][inventory.code]) {
      groupedWithLogicalVault[inventory.customer_id][inventory.code] = [];
    }
    groupedWithLogicalVault[inventory.customer_id][inventory.code] = [
      ...groupedWithLogicalVault[inventory.customer_id][inventory.code],
      inventory
    ];
  });

  // Tree expand event Handler
  const handleExpand = (event, nodeId, isExpanded) => {
    if (hasCompareList) return;
    if (isExpanded) {
      setExpanded((prev) => [...prev, nodeId]);
    } else {
      setExpanded((prev) => prev.filter((id) => id !== nodeId));
    }
  };

  const getDateWithFormat = (date) => {
    return date
      ? `${dateTimeFormatByLocale(date, localeByBranch || DEFAULT_LOCALE)}`
      : "-";
  };

  const getSecondLevelChildren = (logicalVaults, customerId) => {
    return Object.keys(logicalVaults).map((key) => {
      let label = key;
      let nodeId = `${customerId}-${key}`;
      let type = typeLogicalVault;
      let id = key;
      let children = logicalVaults[key]
        .sort(
          (a, b) => new Date(a.created_datetime) - new Date(b.created_datetime)
        )
        .map((inventory, i) => ({
          nodeId: `${inventory.created_datetime}-${i}`,
          label: t("workManagement.inventory.dateAndStatusLabel", {
            dateTime: getDateWithFormat(inventory.created_datetime)
          }),
          icon: NotepadIcon,
          data: inventory,
          type: typeItem
        }));

      return {
        label,
        nodeId,
        type,
        id,
        children
      };
    });
  };

  const getCustomerLabel = (id) =>
    inventoryList.find((inventory) => inventory.customer_id === id).number;

  const treeData = [
    {
      nodeId: inventoryNodeId,
      label: t("workManagement.inventory.inventory"),

      children: customerIdList
        ?.sort((a, b) => a.localeCompare(b))
        .map((customerId) => ({
          nodeId: customerId,
          label: getCustomerLabel(customerId),
          id: customerId,
          icon: CustomerIcon,
          type: typeCustomer,
          children: getSecondLevelChildren(
            groupedWithLogicalVault[customerId],
            customerId
          )
        }))
    }
  ];

  const handleItemClick = (e, type, inventoryItem) => {
    if (type === typeItem) {
      handleFocusInventoryChange(inventoryItem);
    } else {
      handleFocusInventoryChange({});
      e.stopPropagation();
    }
  };

  const renderTreeItems = (nodes) => {
    return nodes.map((node) => (
      <>
        <StyledTreeItem
          key={node.nodeId}
          itemId={node.nodeId}
          slots={{
            collapseIcon: () => (
              <ExpandMoreIcon id={`collapse-icon-${node.nodeId}`} />
            ),
            expandIcon: () => (
              <ChevronRightIcon id={`expand-icon-${node.nodeId}`} />
            )
          }}
          onClick={(e) => handleItemClick(e, node.type, node.data)}
          label={
            <Box sx={WorkManagementTreeStyles.nodeLableContainer}>
              {node.icon && (
                <SvgIcon
                  component={node.icon}
                  fontSize={WorkManagementTreeStyles.iconSize}
                  sx={WorkManagementTreeStyles.iconStyle}
                />
              )}
              {node.label}
            </Box>
          }
        >
          {node.children ? renderTreeItems(node.children) : null}
        </StyledTreeItem>
      </>
    ));
  };

  return (
    <Box>
      <SimpleTreeView
        expandedItems={expanded}
        onItemExpansionToggle={handleExpand}
      >
        {renderTreeItems(treeData)}
      </SimpleTreeView>
    </Box>
  );
};

export default InventoryTree;
