import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import { t } from "i18next";
import spacing from "styles/spacing";
import {
  branchSectionstyle,
  cardActionStyles,
  cardContentStyles,
  cardStyle,
  countTextStyle,
  dividerStyle,
  popupTitle
} from "./MultipleCustomerSelectModalStyle";

const MultipleCustomerSelectModal = ({
  title,
  regenerateModalOpen,
  onCancleClick,
  regenerateCustomers,
  handleSelectAllChange,
  handleCustomerSelect,
  handleSearchChange,
  searchText,
  onRegenerateClick,
  allSelected
}) => {
  return (
    <Modal
      open={regenerateModalOpen}
      onClose={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={cardStyle}>
        {/* modal header start */}
        <Typography
          variant="h6"
          sx={popupTitle}
          color="text.secondary"
          gutterBottom
        >
          {title}
        </Typography>
        {/* modal header start */}

        <Divider />

        {/* modal content start */}
        <CardContent sx={cardContentStyles}>
          <Grid container spacing={spacing.gap}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={spacing.gap}>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <TextField
                        label={t("menu.search")}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={searchText || ""}
                        onChange={handleSearchChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} xl={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            onChange={(e) => handleSelectAllChange(e)}
                            checked={allSelected}
                          />
                        }
                        label={t("common.selectAll")}
                      />
                    </Grid>
                    <Typography sx={countTextStyle}>
                      {
                        regenerateCustomers?.filter((c) => c?.isSelected)
                          ?.length
                      }{" "}
                      {t("customerSchedule.customersSelected")}
                    </Typography>
                  </Grid>
                  <Divider sx={dividerStyle} />
                  <Grid container spacing={spacing.gap} sx={branchSectionstyle}>
                    {searchText !== ""
                      ? regenerateCustomers
                          ?.filter((i) =>
                            i.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          )
                          ?.map((item) => (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={4}
                              xl={4}
                              key={item.value}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={item?.isSelected}
                                    onChange={() =>
                                      handleCustomerSelect(item?.key)
                                    }
                                  />
                                }
                              />
                              {item.label}
                            </Grid>
                          ))
                      : regenerateCustomers?.map((item) => (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            xl={4}
                            key={item.value}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item?.isSelected}
                                  onChange={() =>
                                    handleCustomerSelect(item?.key)
                                  }
                                />
                              }
                            />
                            {item.label}
                          </Grid>
                        ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        {/* modal content end */}

        <Divider />

        {/* modal footer start */}
        <CardActions sx={cardActionStyles}>
          <Button variant="outlined" onClick={() => onCancleClick()}>
            {t("common.close")}
          </Button>
          <Button
            variant="contained"
            disabled={
              !regenerateCustomers?.filter((c) => c?.isSelected)?.length
            }
            onClick={() => onRegenerateClick()}
          >
            {t("common.regenerate")}
          </Button>
        </CardActions>
        {/* modal footer end */}
      </Card>
    </Modal>
  );
};

export default MultipleCustomerSelectModal;
