import { Button, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import { SearchTableStyles } from "components/features/request-module/media-request-search/search-table/SearchTable.Styles";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import MUIDataTable from "mui-datatables";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import {
  BOOLEAN_STRING_VALUES,
  DEFAULT_DATA_TABLE_OPTIONS,
  NO,
  YES,
  type
} from "utils/constants";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
const SearchTable = (props) => {
  const { t } = useTranslation();

  //handle confirm modal yes
  const handleYesConfirm = () => {
    props.setShowConfirm(false);
    window.scrollTo({ top: 0 });
    props.handleInsert();
    props.setWarningMessagePopup(false);
    props.setWarningMessage("");
  };

  //hanle confirm modal no
  const handleNoConfirm = () => {
    props.setIsVisible(false);
    props.setShowConfirm(false);
    props.setSelectedRowIndex(null);
    props.setWarningMessagePopup(false);
    props.setWarningMessage("");
  };

  //handle confirm modal cancel
  const handleCancelConfirm = () => {
    props.setShowConfirm(false);
  };
  const handleNew = () => {
    props?.SearchConfigurationForm?.setFieldValue(
      "row",
      props?.SearchConfigurationForm?.initialValues.row
    );
    props.handleClickedButton(props.buttonClick.new);
    props.handleOpenModal(true);
  };

  const searchResultsCount = useMemo(
    () => props.SearchConfigurationForm.values.searchResults?.length,
    [props.SearchConfigurationForm.values.searchResults]
  );

  const noRecord = props.SearchConfigurationForm.values.searchResults
    ? props.SearchConfigurationForm.values.searchResults?.length === 0 && true
    : true;

  //handle edit function
  const handleEdit = (tableMeta) => {
    props.handleClickedButton(props.buttonClick.edit);
    const clickedRowData = {
      pricingContract: tableMeta.rowData[1],
      catalogItemCode: tableMeta.rowData[3],
      startingGeneration: props.capacityNew.find(
        (item) => item.label === tableMeta.rowData[2]
      ),
      isActive:
        tableMeta.rowData[4] === BOOLEAN_STRING_VALUES.TRUE ? true : false
    };
    props.SearchConfigurationForm.setFieldValue("editedRow", [clickedRowData]);
    props.setSelectedRowIndex(tableMeta.rowIndex);
    props.handleOpenModal(true);
  };

  const columns = [
    {
      name: "pricing_contract_code",
      label: t("requestSearch.emptyLabelHeader"),
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Button onClick={() => handleEdit(tableMeta)}>
              <SvgIcon component={EditIcon} />
            </Button>
          );
        },
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "pricing_contract_code",
      label: t("capacityBillingConfigurationSearch.pricingContract"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "capacity_billing_suffix",
      label: t("capacityBillingConfigurationSearch.startingGeneration"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "catalog_item_code",
      label: t("capacityBillingConfigurationSearch.catalogItemCode"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    },
    {
      name: "active_flag",
      label: t("capacityBillingConfigurationSearch.activeFlag"),
      options: {
        customBodyRender: (value) => {
          const convertToBoolean = (value) => {
            if (typeof value === type.boolean) {
              return value; // If it's already a boolean, return as is
            } else if (typeof value === type.string) {
              // Convert string representations of boolean values to actual boolean values
              return value.toLowerCase() === BOOLEAN_STRING_VALUES.TRUE;
            } else {
              // Handle other data types (e.g., numbers, objects, etc.) as needed
              return Boolean(value);
            }
          };

          const booleanValue = convertToBoolean(value);

          return booleanValue ? YES : NO; // Display "Yes" for true and "No" for false
        },
        customHeadLabelRender: (columnMeta) => (
          <span style={SearchTableStyles.customHeader}>{columnMeta.label}</span>
        )
      }
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: props.pageToZero && 0,
    pagination: !noRecord && true,
    customToolbar: () => (
      <Stack
        direction="row"
        gap={spacing.horizontalMargin20}
        display={"flex"}
        justifyContent="end"
      >
        <Grid
          direction="row"
          padding={spacing.horizontalMargin16}
          gap={spacing.horizontalMargin20}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          {!noRecord && (
            <>
              <Button
                variant="outlined"
                onClick={() => props.handleDownloadModalOpen(true)}
              >
                {t("common.print")}
              </Button>
            </>
          )}

          <Button
            variant="outlined"
            onClick={() => {
              handleNew();
            }}
          >
            {t("common.new")}
          </Button>
        </Grid>
      </Stack>
    ),
    textLabels: {
      body: {
        noMatch:
          props.disableFindButton && searchResultsCount === 0 ? (
            <React.Fragment key="noMatch">
              <span key="tableTextWithoutInputs">
                {t("common.noDataFound")}
              </span>
            </React.Fragment>
          ) : (
            <span key="tableTextWithoutInputs">
              {t("common.tableTextWithoutInputs")}
            </span>
          )
      }
    },
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor:
          dataIndex === props.selectedRowIndex
            ? "var(--color-im-light-blue-100)"
            : ""
      }
    })
  };
  return (
    <>
      {/*Table*/}

      <MUIDataTable
        columns={columns}
        options={options}
        data={props.SearchConfigurationForm.values.searchResults}
        title={
          props.disableFindButton && (
            <Typography style={commonStyles.tableRecordsFoundTitleStyle}>
              {searchResultsCount} {t("common.recordsFound")}
            </Typography>
          )
        }
      />

      {/*Confim modal*/}
      {props.showConfirm && (
        <ConfirmPopup
          modalPopupOpen={props.showConfirm}
          handleCancel={handleCancelConfirm}
          handleNo={handleNoConfirm}
          handleYes={handleYesConfirm}
          showCancel={true}
          showNo={true}
        />
      )}
    </>
  );
};

export default SearchTable;
