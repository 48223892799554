import { Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { selectMediaRequest, setOtherComment } from "store/slices";

const OtherTab = (props) => {
  const { t } = useTranslation();
  const { otherComment } = useSelector(selectMediaRequest); // select media request slice
  const dispatch = useDispatch(); // redux actions dispatcher

  // limit text length to 254 characters event handler
  const limitCharLength = (event) => {
    if (event.target.value.length <= 254) {
      dispatch(setOtherComment(event.target.value));
    }
  };

  return (
    <Grid id="other_Tab_container_Grid">
      <Grid id="other_Tab_container_Grid2" item xs={8}>
        <TextField
          id="other_tab_otherComments"
          disabled={Boolean(props.requestId)}
          name="comment"
          label="Other Comments"
          size="medium"
          fullWidth
          multiline
          rows={3}
          value={otherComment}
          onChange={limitCharLength}
          error={props.hasError}
          helperText={
            props.hasError
              ? props.errorMessage
              : t("mediaRequestDetail.maximumCharsIs254")
          }
        />
      </Grid>
    </Grid>
  );
};

// Props definition of the component
OtherTab.propTypes = {
  requestId: PropTypes.number, // ID of the media request
  hasError: PropTypes.bool, // other comment has an error
  errorMessage: PropTypes.string // other comment error message
};

export default OtherTab;
