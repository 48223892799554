const commonStyles = {
  widthFixed: { width: "310px" },
  widthFull: { width: "100%" },
  widthFit: { width: "fit-content" },
  alignCenter: { alignItems: "center" },
  pointerCursor: { cursor: "pointer" },
  heightBox: { height: "20px" }
};

const formSaveAndCreatePrinterStyles = {
  descriptionContainer: {
    flex: 1
  },
  textFieldNoBorder: {
    border: "none",
    "& fieldset": { border: "none" },
    pointerEvents: "none"
  }
};

const printButtonContainer = {
  margin: "25px 0"
};

const printButtonLoader = {
  color: "#FFF"
};

const printButton = {
  display: "flex",
  alignItems: "center",
  gap: "10px"
};
const printTableLabelTableList = {
  buttons: { textTransform: "none" },
  inputTypeFile: {
    display: "none"
  }
};

const printLabelTableStyles = {
  tablePaper: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    margin: "26px 0"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  completeColor: "var(--color-im-mid-green)",
  selectedColor: "var(--color-im-light-blue-100)",
  errorColor: "var(--color-im-red)",
  statusText: {
    fontSize: "14px"
  },
  alert: {
    position: "absolute",
    zIndex: 1,
    right: 0,
    top: "50px",
    boxShadow: "0px 1px 18px 0px #0000001F"
  }
};

export const printTapeLabelStyles = {
  commonStyles,
  formSaveAndCreatePrinterStyles,
  printButtonContainer,
  printTableLabelTableList,
  printLabelTableStyles,
  printButtonLoader,
  printButton
};
