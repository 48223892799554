import { FormHelperText } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ArrowDownDisabled } from "assets/images/ArrowDownDisabled.svg";
export default function MultiSelectCheckBox({
  label,
  labelId,
  options = [],
  error,
  helperText,
  notched,
  shrink,
  MenuProps,
  ...props
}) {
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id={labelId} error={error} shrink={shrink}>
          {label}
        </InputLabel>
        <Select
          notched={notched}
          labelId={labelId}
          label={label}
          error={error}
          IconComponent={props.disabled ? ArrowDownDisabled : ArrowDown}
          {...props}
          MenuProps={MenuProps}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.label}>
              <Checkbox checked={props?.value?.indexOf(option.label) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}
