export const DiscrepencyStyles = {
  checkBoxStyle: {
    margin: "9px 0px "
  },
  mainDivStyles: {
    marginTop: "20px"
  },
  boxWidth: { width: "310px" },
  inputLabelProps: { shrink: true, style: { zIndex: 0 } },
  flex: { display: "flex" },
  btn: { textTransform: "none", margin: "5px 10px" },
  boxStyle: { display: "flex", gap: "20px" },
  alertWidth: { minWidth: "600px" },
  actionColumnStyle: { textAlign: "left", width: "80px" },
  columnStyle: { minWidth: "200px" },
  columnStyleLg: { minWidth: "250px" },
  columnStylesm: { minWidth: "150px" },
  colorStyle: { color: "var(--color-im-red)" },
  menuStyles: {
    maxHeight: 500,
    overflowY: "auto"
  },
  linkStyles: { cursor: "pointer" },
  displayAccordionStyles: { display: "none" },
  maxHeightStyles: {
    display: "block"
  },
  checkHeaderStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "var(--color-white-100)",
    padding: "16px 10px",
    height: "100%",
    borderBottom: "1px solid rgba(224, 224, 224, 1)"
  }
};
