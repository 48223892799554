import { Stack } from "@mui/material";
import AuditLayout from "components/container-processing/container-audit-search/AuditLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const ContainerAuditSearch = () => {
  return (
    <>
      <Stack>
        <PageTitle title={i18n.t("containerAuditSearch.title")} />
        <AuditLayout />
      </Stack>
    </>
  );
};
export default ContainerAuditSearch;
