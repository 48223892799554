import { Box, Button, Grid, SvgIcon, Typography } from "@mui/material";
import DataTable from "components/core/data-table/DataTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { ReactComponent as DeleteIcon } from "../../../assets/images/CloseIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/pencil.svg";
import ServiceWindowAdd from "./ServiceWindowAdd";
import ServiceWindowEdit from "./ServiceWindowEdit";
import { ServiceWindowsTableStyles } from "./ServiceWindowsTableStyles";

const ServiceWindowsTable = ({
  customerServiceWindows,
  scheduleToSave,
  setScheduleToSave,
  customer,
  daysOfWeek,
  setdeleteSchedules,
  getDayOfWeek,
  isPrintButtonDisabled,
  isResetCSLDisabled,
  resetCSLButtonClick,
  customers,
  handleClickPrint
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const { currentBranch } = useSelector(selectAuth);
  const [editData, setEditData] = useState({});

  const selectedBranchId = currentBranch?.district_id;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickEdit = (value, tableMeta) => {
    setEditData({
      editedTime: Date.now(),
      ...value,
      rowIndex: tableMeta?.rowIndex
    });
    setEditOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleAdd = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    setEditOpen(false);
  };

  const handleDelete = (value, isUnsaved) => {
    const dataToDelete = {
      main_district_id: selectedBranchId,
      customer_schedule_id: value.customerScheduleId
    };
    setdeleteSchedules(dataToDelete, {
      isUnsaved,
      id: value.id,
      day: value?.dayOfWeekCode
    });
  };

  const columns = [
    {
      name: "data",
      label: t("serviceWindows.tripInformationLabel"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <>
            <tr>
              <th
                colSpan={3}
                style={ServiceWindowsTableStyles.customerHeaderTh}
              >
                <span style={ServiceWindowsTableStyles.customHeader}></span>
              </th>
            </tr>
          </>
        ),
        customBodyRender: (value, tableMeta) =>
          !value.isSaved ? (
            <>
              <tr>
                <td style={ServiceWindowsTableStyles.LabelPadding}>
                  <Button
                    onClick={() => {
                      handleClickEdit(value, tableMeta);
                    }}
                  >
                    <SvgIcon
                      component={EditIcon}
                      style={ServiceWindowsTableStyles.svgIconStyle}
                    />
                  </Button>
                </td>
                <td style={ServiceWindowsTableStyles.LabelPadding}>
                  <Button onClick={() => handleDelete(value, value.isUnsaved)}>
                    <SvgIcon component={DeleteIcon} />
                  </Button>
                </td>
              </tr>
            </>
          ) : (
            <></>
          )
      }
    },
    {
      name: "tripInformation",
      label: t("serviceWindows.tripInformationLabel"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <>
            <tr>
              <th
                colSpan={3}
                style={ServiceWindowsTableStyles.customerHeaderTh}
              >
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.tripInformationLabel")}
                </span>
              </th>
            </tr>
            <tr>
              <th style={ServiceWindowsTableStyles.LabelPadding}>
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.dayOfWeekLabel")}
                </span>
              </th>
              <th style={ServiceWindowsTableStyles.LabelPadding2}>
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.seriveLocLabel")}
                </span>
              </th>
            </tr>
          </>
        ),
        customBodyRender: (value, data) => (
          <>
            <tr style={ServiceWindowsTableStyles.customDataTd1}>
              <td>{getDayOfWeek(value[0])}</td>
              <td>
                {
                  customers.filter((ele2) => {
                    return ele2.value === value[1];
                  })[0]?.number
                }
              </td>
            </tr>
          </>
        )
      }
    },
    {
      name: "window1",
      label: t("serviceWindows.window1Label"),
      options: {
        customHeadLabelRender: () => (
          <>
            <tr>
              <th
                colSpan={3}
                style={ServiceWindowsTableStyles.customerHeaderTh}
              >
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.window1Label")}
                </span>
              </th>
            </tr>
            <tr>
              <th style={ServiceWindowsTableStyles.LabelPadding}>
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.fromLabel")}
                </span>
              </th>
              <th style={ServiceWindowsTableStyles.LabelPadding2}>
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.toLabel")}
                </span>
              </th>
            </tr>
          </>
        ),
        customBodyRender: (value) => (
          <div>
            <tr
              style={{
                ...ServiceWindowsTableStyles.customDataTd
              }}
            >
              <td>
                {value[0] !== ""
                  ? value[0].slice(0, 2) + ":" + value[0].slice(2)
                  : value[0]}
              </td>
              <td>
                {value[1] !== ""
                  ? value[1].slice(0, 2) + ":" + value[1].slice(2)
                  : value[1]}
              </td>
            </tr>
          </div>
        )
      }
    },
    {
      name: "window2",
      label: t("serviceWindows.window2Label"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div>
            <tr>
              <th
                colSpan={3}
                style={ServiceWindowsTableStyles.customerHeaderTh}
              >
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.window2Label")}
                </span>
              </th>
            </tr>
            <tr>
              <th style={ServiceWindowsTableStyles.LabelPadding}>
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.fromLabel")}
                </span>
              </th>
              <th style={ServiceWindowsTableStyles.PaddingLeft}>
                <span style={ServiceWindowsTableStyles.customHeader}>
                  {t("serviceWindows.toLabel")}
                </span>
              </th>
            </tr>
          </div>
        ),
        customBodyRender: (value) => (
          <div>
            <tr style={ServiceWindowsTableStyles.customDataTd}>
              <td>
                {value[0] !== ""
                  ? value[0].slice(0, 2) + ":" + value[0].slice(2)
                  : value[0]}
              </td>
              <td>
                {value[1] !== ""
                  ? value[1].slice(0, 2) + ":" + value[1].slice(2)
                  : value[1]}
              </td>
            </tr>
          </div>
        )
      }
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: true,
    customToolbar: () =>
      customerServiceWindows && customerServiceWindows.length > 0 ? (
        <>
          <Grid
            item
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            gap={spacing.verticalMargin20}
          >
            <Button
              variant="outlined"
              size="medium"
              type="button"
              disabled={isPrintButtonDisabled}
              onClick={handleClickPrint}
            >
              {t("serviceWindows.printButtonLabel")}
            </Button>
            <Button
              variant="outlined"
              size="medium"
              type="button"
              onClick={handleClickOpen}
            >
              {t("serviceWindows.newButtonLabel")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              type="button"
              disabled={isResetCSLDisabled}
              onClick={resetCSLButtonClick}
            >
              {t("serviceWindows.resetCSLButtonLabel")}
            </Button>
          </Grid>
        </>
      ) : customer && customerServiceWindows.length === 0 ? (
        <>
          <Grid>
            <Button
              variant="outlined"
              size="medium"
              type="button"
              onClick={handleClickOpen}
            >
              {t("serviceWindows.newButtonLabel")}
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      ),
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    }
  };

  return (
    <>
      <Box sx={ServiceWindowsTableStyles.muiDataTableStyles}>
        {
          <ServiceWindowAdd
            setScheduleToSave={setScheduleToSave}
            customer={customer}
            setOpen={setOpen}
            handleAdd={handleAdd}
            handleClose={handleClose}
            handleClickOpen={handleClickOpen}
            open={open}
            daysOfWeek={daysOfWeek}
            customers={customers}
            customerServiceWindows={customerServiceWindows}
          />
        }
        {
          <ServiceWindowEdit
            setScheduleToUpdate={setScheduleToSave}
            customer={customer}
            setOpen={setEditOpen}
            handleAdd={handleEdit}
            handleClose={handleEditClose}
            handleClickOpen={handleClickEdit}
            open={openEdit}
            daysOfWeek={daysOfWeek}
            editData={editData}
            getDayofWeek={getDayOfWeek}
            customers={customers}
            customerServiceWindows={customerServiceWindows}
          />
        }
        <DataTable
          options={options}
          data={customerServiceWindows}
          title={
            customerServiceWindows &&
            customerServiceWindows.length > 0 && (
              <Typography style={commonStyles.tableRecordsFoundTitleStyle}>
                {customerServiceWindows.length} {t("common.recordsFound")}
              </Typography>
            )
          }
          columns={columns}
        />
      </Box>
    </>
  );
};

export default ServiceWindowsTable;
