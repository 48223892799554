export const logicalVaultDetailesStyles = {
  flexItem: {
    display: "flex",
    gap: "1rem"
  },
  descProps: {
    maxLength: 8,
    style: { textTransform: "uppercase" }
  },
  capacityProps: {
    maxLength: 2
  },
  hoursProps: {
    maxLength: 10
  },
  turnRoundingProps: {
    maxLength: 2
  }
};

export const TransferItemStyles = {
  listStyle: {
    width: 450,
    height: 230,
    bgcolor: "background.paper",
    overflow: "auto"
  },
  cStyle: {
    justifyContent: "left",
    alignItems: "center"
  },
  centerStyle: {
    alignItems: "center"
  },
  btnStyle: {
    my: 0.5
  }
};
