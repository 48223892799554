export const CorrectiveActionRequestStyles = {
  dividerMargin: { mt: 2 },
  testLabelDivider: { my: 4 },
  cardStyle: { maxWidth: 2000 },
  titleStyle: {
    marginTop: "20px",
    color: "var(--color-im-grey-900)",
    fontWeight: 500
  },
  cardHeader: { paddingLeft: "10px" },
  employeeSignOff: { marginLeft: "10px" },
  treeCardContent: {
    overflow: "auto",
    minHeight: "calc(100px + 64px)"
  },
  cardScroll: {
    maxHeight: 300, // Set maximum height for the scrollable area
    overflowY: "auto" // Enable vertical scroll
  },
  maintanence: {
    helper: { marginTop: "22px" },
    colorTitle: { color: "gray" }
  },
  carHead: { margin: "1px", padding: "12px" },
  assignTransportsCard: { maxWidth: 600, margin: "10px auto", padding: 2 },
  assignTransportsBox: {
    display: "flex",
    alignItems: "center",
    margin: "10px"
  },
  alertSize: { width: "20px" },

  assignTransportsAddButton: {
    marginLeft: 2,
    borderRadius: 2,
    border: "1px solid",
    textTransform: "none"
  },
  modalStyle: {
    width: "600px !important"
  },
  tableFlex: { display: "flex", alignItems: "center" }
};
