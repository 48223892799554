import { Stack } from "@mui/material";
import VaultStatusReportLayout from "components/reports-module/vaultStatusReport/VaultStatusReportLayout";
import { PageTitle } from "components/shared";
import { t } from "i18next";
import { useState } from "react";

const VaultStatusReport = () => {
  const [showTitle, setShowTitle] = useState(false);
  return (
    <>
      <Stack>
        <PageTitle
          title={
            showTitle
              ? t("reports.vaultStatusReport.title1")
              : t("reports.vaultStatusReport.title")
          }
        />
        <VaultStatusReportLayout
          setShowTitle={setShowTitle}
          showTitle={showTitle}
        />
      </Stack>
    </>
  );
};

export default VaultStatusReport;
