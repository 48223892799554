export const labelTemplateStyles = {
  assignTransportsCard: { maxWidth: 600, margin: "20px auto", padding: 2 },
  assignTransportsBox: {
    display: "flex",
    alignItems: "center",
    margin: "10px"
  },
  assignTransportsAddButton: {
    marginLeft: 2,
    borderRadius: 2,
    border: "1px solid",
    textTransform: "none"
  },
  transportAddBox1: {
    width: "100%",
    maxWidth: 500
  },
  transportAddBox2: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px"
  },
  tablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  completeColor: "var(--color-im-mid-green)",
  selectedColor: "var(--color-im-light-blue-100)",
  formStyle: { display: "flex", flexDirection: "row" },
  infoBar: {
    container: { position: "relative" },
    containerStack: {
      position: "relative",
      maxHeight: "300px",
      backgroundColor: "var(--color-im-info-fill)",
      color: "blue",
      borderRadius: "4px"
    },
    secondBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px"
    },
    errorIcon: { color: "var(--color-im-info-outlined)" },
    closeIcon: { color: "var(--color-im-info-content)" },
    span: {
      color: "var(--color-im-info-content)",
      fontWeight: 500,
      paddingLeft: "10px"
    },
    span2: {
      color: "var(--color-im-info-content)",
      paddingLeft: "10px"
    },
    title: { paddingLeft: "40px" },
    box: {
      paddingLeft: "40px",
      mt: 3,
      mb: 2
    },
    spanMargins: {
      ml5: { marginLeft: "5px" },
      ml30Pre: { marginLeft: "30px", whiteSpace: "pre" },
      ml30: { marginLeft: "30px" }
    }
  },
  tableDiv: { height: "200px", overflow: "auto" },
  pickListModalMainGrid: { width: "592px" },
  wrapStyles: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  symbol: { marginTop: "10px" },
  divider: { margin: "20px 0" },
  newButton: { marginTop: "20px" },
  tableScroll: { maxHeight: 400, overflowX: "auto" }
};
