import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DatePickerField } from "components/core";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import {
  ALL_SELECTION,
  ERROR_TYPES,
  dayMintMonthYearFormat,
  maximumDate
} from "utils/constants";
import {
  getOptions,
  getResponseData,
  messageCodes,
  scheduled,
  status2
} from "utils/constants/open-media-processing/OpenMediaAuditConstants";
import { findErrorMessage } from "utils/helpers";
import { OpenMediaAuditStyles } from "./OpenMediaAuditDetailStyles";

//today date state
const today = dayjs();
//max date state
const maxDate = dayjs(maximumDate);

const LocationForm = ({
  setIsLoading,
  currentBranch,
  setThrowError,
  setThrowErrorMessage,
  auditDetailForm,
  isViewMode,
  viewData,
  localeByBranch,
  allAuditData
}) => {
  const allSelection = ALL_SELECTION?.value;
  const { values, setFieldValue } = auditDetailForm;
  const [fromVaultList, setFromVaultList] = useState([ALL_SELECTION]);
  const [fromRowList, setFromRowList] = useState([ALL_SELECTION]);
  const [fromRackList, setFromRackList] = useState([ALL_SELECTION]);
  const [fromShelfList, setFromShelfList] = useState([ALL_SELECTION]);
  const [disableMode, setDisableMode] = useState({
    rowVault: true,
    rackVault: true,
    shelfVault: true
  });
  useEffect(() => {
    if (!isViewMode) {
      handleGetLocationRowData();
    }
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isViewMode) {
      const {
        vault_from,
        vault_to,
        row_to,
        row_from,
        rack_from,
        rack_to,
        shelf_from,
        shelf_to
      } = values;
      setFromVaultList([
        {
          value: vault_to ? vault_to : allSelection,
          label: vault_to ? vault_to : allSelection
        },
        {
          value: vault_from ? vault_from : allSelection,
          label: vault_from ? vault_from : allSelection
        }
      ]);
      setFromRowList([
        {
          value: row_to ? row_to : allSelection,
          label: row_to ? row_to : allSelection
        },
        {
          value: row_from ? row_from : allSelection,
          label: row_from ? row_from : allSelection
        }
      ]);
      setFromRackList([
        {
          value: rack_from ? rack_from : allSelection,
          label: rack_from ? rack_from : allSelection
        },
        {
          value: rack_to ? rack_to : allSelection,
          label: rack_to ? rack_to : allSelection
        }
      ]);
      setFromShelfList([
        {
          value: shelf_from ? shelf_from : allSelection,
          label: shelf_from ? shelf_from : allSelection
        },
        {
          value: shelf_to ? shelf_to : allSelection,
          label: shelf_to ? shelf_to : allSelection
        }
      ]);
    }
    //eslint-disable-next-line
  }, [isViewMode]);
  const handleGetLocationRowData = async () => {
    setIsLoading(true);
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getphysicalvaults,
        1
      );
      let obj = {};
      for (let key in dataSets.data) {
        dataSets.data[key].forEach((item) => {
          obj[item.physical_vault_id] = {
            physical_vault_id: item.physical_vault_id,
            physical_vault_number: item.physical_vault_number,
            timestamp: item.timestamp
          };
        });
      }
      if (!Object.values(obj).length && !viewData?.om_audit_id) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCodes62521
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      }
      let setArray = getOptions(
        [...Object.values(obj)],
        "physical_vault_id",
        "physical_vault_number"
      ).sort((a, b) => a?.value - b?.value);
      setFromVaultList([ALL_SELECTION, ...setArray]);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetRowData = async (id) => {
    setIsLoading(true);
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id,
      physical_vault_id: id
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getrows,
        1
      );
      let obj = {};
      for (let key in dataSets.data) {
        dataSets.data[key].forEach((item) => {
          obj[item.row_id] = {
            row_id: item.row_id,
            row_number: item.row_number
          };
        });
      }

      if (!Object.values(obj).length && !viewData?.om_audit_id) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCodes62521
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      }
      let setArray = getOptions(
        [...Object.values(obj)],
        "row_id",
        "row_number"
      ).sort((a, b) => a?.value - b?.value);
      if (setArray.length) {
        setDisableMode({ ...disableMode, rowVault: false });
      }
      setFromRowList([ALL_SELECTION, ...setArray]);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetRackList = async (id, rowId) => {
    setIsLoading(true);
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id,
      physical_vault_id: id,
      row_id: rowId
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getracks,
        1
      );
      let obj = {};
      for (let key in dataSets.data) {
        dataSets.data[key].forEach((item) => {
          obj[item.rack_id] = {
            rack_id: item.rack_id,
            rack_number: item.rack_number
          };
        });
      }
      if (!Object.values(obj).length && !viewData?.om_audit_id) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCodes62521
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      }
      let setArray = getOptions(
        [...Object.values(obj)],
        "rack_id",
        "rack_number"
      ).sort((a, b) => a?.value - b?.value);
      if (setArray.length) {
        setDisableMode({ ...disableMode, rackVault: false });
      }
      setFromRackList([ALL_SELECTION, ...setArray]);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleGetShelfVault = async (id, rowId, rackId) => {
    setIsLoading(true);
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id,
      physical_vault_id: id,
      row_id: rowId,
      rack_id: rackId
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getshelvesonarack,
        1
      );
      let obj = {};
      for (let key in dataSets.data) {
        dataSets.data[key].forEach((item) => {
          obj[item.shelf_id] = {
            shelf_id: item.shelf_id,
            shelf_number: item.shelf_number
          };
        });
      }
      if (!Object.values(obj).length && !viewData?.om_audit_id) {
        const errorMsg = await getMessageFromCode(
          messageCodes?.messageCodes62521
        );
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      }
      let setArray = getOptions(
        [...Object.values(obj)],
        "shelf_id",
        "shelf_number"
      ).sort((a, b) => a?.value - b?.value);
      if (setArray.length) {
        setDisableMode({ ...disableMode, shelfVault: false });
      }
      setFromShelfList([ALL_SELECTION, ...setArray]);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangesValue = (e, key) => {
    const { value, name } = e.target;
    let fromNewData = { ...values };
    if (["vault_from", "vault_to"].includes(name)) {
      setDisableMode({ rowVault: true, rackVault: true, shelfVault: true });
      fromNewData = {
        ...fromNewData,
        row_from: allSelection,
        row_to: allSelection,
        rack_from: allSelection,
        rack_to: allSelection,
        shelf_from: allSelection,
        shelf_to: allSelection
      };
      if (name === "vault_to" && fromNewData?.vault_from === allSelection) {
        fromNewData = {
          ...fromNewData,
          vault_from: value
        };
      } else if (name === "vault_from" && value === allSelection) {
        fromNewData = {
          ...fromNewData,
          vault_to: allSelection
        };
      }
    } else if (["row_from", "row_to"].includes(name)) {
      setDisableMode({ ...disableMode, rackVault: true, shelfVault: true });
      fromNewData = {
        ...fromNewData,
        rack_from: allSelection,
        rack_to: allSelection,
        shelf_from: allSelection,
        shelf_to: allSelection
      };
      if (name === "row_to" && fromNewData?.row_from === allSelection) {
        fromNewData = {
          ...fromNewData,
          row_from: value
        };
      } else if (name === "row_from" && value === allSelection) {
        fromNewData = {
          ...fromNewData,
          row_to: allSelection
        };
      }
    } else if (["rack_from", "rack_to"].includes(name)) {
      setDisableMode({ ...disableMode, shelfVault: true });
      fromNewData = {
        ...fromNewData,
        shelf_from: allSelection,
        shelf_to: allSelection
      };
      if (name === "rack_to" && fromNewData?.rack_from === allSelection) {
        fromNewData = {
          ...fromNewData,
          rack_from: value
        };
      } else if (name === "rack_from" && value === allSelection) {
        fromNewData = {
          ...fromNewData,
          rack_to: allSelection
        };
      }
    } else {
      if (name === "shelf_to" && fromNewData?.shelf_from === allSelection) {
        fromNewData = {
          ...fromNewData,
          shelf_from: value
        };
      } else if (name === "shelf_from" && value === allSelection) {
        fromNewData = {
          ...fromNewData,
          shelf_to: allSelection
        };
      }
    }
    for (let key in fromNewData) {
      setFieldValue(key, fromNewData[key]);
    }

    setFieldValue(name, value);
  };

  useEffect(() => {
    const { vault_from, vault_to, row_from, row_to, rack_from, rack_to } =
      values;
    if (isViewMode) {
      return;
    }
    if (
      vault_from === vault_to &&
      vault_from !== allSelection &&
      vault_to !== allSelection &&
      disableMode?.rowVault
    ) {
      handleGetRowData(vault_from);
    }
    if (
      disableMode?.rackVault &&
      row_from === row_to &&
      row_from !== allSelection &&
      row_to !== allSelection
    ) {
      handleGetRackList(vault_from, row_to);
    }
    if (
      disableMode?.shelfVault &&
      rack_from === rack_to &&
      rack_from !== allSelection &&
      rack_to !== allSelection
    ) {
      handleGetShelfVault(vault_from, row_to, rack_from);
    }
    //eslint-disable-next-line
  }, [values]);

  return (
    <>
      <Grid item xs={4} sm={6} md={4} lg={3}>
        <FormControl fullWidth>
          <DatePickerField
            disabled={
              isViewMode ? viewData?.audit_status !== scheduled : isViewMode
            }
            id="due-date"
            name="dueDate"
            label={t("openMediaAuditDetail.dueDate")}
            value={values?.due_date}
            minDate={isViewMode ? values?.due_date : today}
            maxDate={maxDate}
            defaultValue={today}
            locale={localeByBranch}
            onChange={(value) => {
              setFieldValue("due_date", value);
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={5}>
        <FormControl fullWidth>
          <TextField
            labelId="comments"
            label={t("openMediaAuditDetail.comments")}
            value={values?.comment}
            onChange={(event) => {
              setFieldValue("comment", event.target.value);
            }}
            fullWidth
            multiline
            rows={4}
            name="review-comments"
            id="review-comments"
            disabled={status2?.includes(viewData?.audit_status)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={5}>
        <Typography style={OpenMediaAuditStyles.commentSectionText}>
          {t("openMediaAuditDetail.pastComments")}
        </Typography>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={OpenMediaAuditStyles.commentSection}
        >
          {allAuditData[1]?.length
            ? allAuditData[1]?.map((item) => {
                return (
                  <Typography marginBottom={2} key={item?.employee_name}>
                    {" "}
                    {dayjs(item?.comment_date_ltz).format(
                      dayMintMonthYearFormat
                    )}{" "}
                    {item?.employee_name}-{item?.comment}
                  </Typography>
                );
              })
            : ""}
        </Grid>
      </Grid>
      <Grid container spacing={2} lg={12} margin={0}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          marginTop={2}
          marginBottom={2}
        >
          <Divider />
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={fromVaultList?.length === 1 || isViewMode}
          >
            <InputLabel id="from-vault">
              {t("openMediaAuditDetail.fromVault")}
            </InputLabel>
            <Select
              labelId="from-vault"
              id="location-from-vault"
              name="vault_from"
              value={values?.vault_from ? values?.vault_from : allSelection}
              label={t("openMediaAuditDetail.fromVault")}
              onChange={handleChangesValue}
            >
              {fromVaultList?.map((item) => (
                <MenuItem
                  value={item?.value}
                  key={item?.value}
                  name={item?.label}
                >
                  {item?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={fromVaultList?.length === 1 || isViewMode}
          >
            <InputLabel id="to">{t("CustomerFileSearch.to")}</InputLabel>
            <Select
              labelId="to"
              id="location-to"
              name="vault_to"
              value={values?.vault_to ? values?.vault_to : allSelection}
              label={t("CustomerFileSearch.to")}
              onChange={handleChangesValue}
            >
              {fromVaultList?.map((item) => (
                <MenuItem
                  value={item?.value}
                  key={item?.value}
                  name={item?.label}
                >
                  {" "}
                  {item?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={disableMode?.rowVault || isViewMode}>
            <InputLabel id="from-row">
              {t("openMediaAuditDetail.fromRow")}
            </InputLabel>
            <Select
              labelId="from-row"
              id="location-from-row"
              name="row_from"
              value={values?.row_from ? values?.row_from : allSelection}
              label={t("openMediaAuditDetail.fromRow")}
              onChange={handleChangesValue}
            >
              {fromRowList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={disableMode?.rowVault || isViewMode}>
            <InputLabel id="from-to-row">
              {t("CustomerFileSearch.to")}
            </InputLabel>
            <Select
              labelId="from-to-row"
              id="location-from-to-row"
              name="row_to"
              value={values?.row_to ? values?.row_to : allSelection}
              label={t("CustomerFileSearch.to")}
              onChange={handleChangesValue}
            >
              {fromRowList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={disableMode?.rackVault || isViewMode}
          >
            <InputLabel id="from-rack">
              {t("openMediaAuditDetail.fromRack")}
            </InputLabel>
            <Select
              labelId="from-rack"
              id="location-from-rack"
              name="rack_from"
              value={values?.rack_from ? values?.rack_from : allSelection}
              label={t("openMediaAuditDetail.rack_from")}
              onChange={handleChangesValue}
            >
              {fromRackList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={disableMode?.rackVault || isViewMode}
          >
            <InputLabel id="from-to-rack">
              {t("CustomerFileSearch.to")}
            </InputLabel>
            <Select
              labelId="from-to-rack"
              id="location-from-to-rack"
              name="rack_to"
              value={values?.rack_to ? values?.rack_to : allSelection}
              label={t("CustomerFileSearch.to")}
              onChange={handleChangesValue}
            >
              {fromRackList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={disableMode?.shelfVault || isViewMode}
          >
            <InputLabel id="from-shelf">
              {t("openMediaAuditDetail.fromShelf")}
            </InputLabel>
            <Select
              labelId="from-shelf"
              id="from-shelf-id"
              name="shelf_from"
              value={values?.shelf_from ? values?.shelf_from : allSelection}
              label={t("openMediaAuditDetail.fromShelf")}
              onChange={handleChangesValue}
            >
              {fromShelfList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={disableMode?.shelfVault || isViewMode}
          >
            <InputLabel id="from-shelf-to">
              {t("CustomerFileSearch.to")}
            </InputLabel>
            <Select
              labelId="from-shelf-to"
              id="from-shelf-to-id"
              name="shelf_to"
              value={values?.shelf_to ? values?.shelf_to : allSelection}
              label={t("CustomerFileSearch.to")}
              onChange={handleChangesValue}
            >
              {fromShelfList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default LocationForm;
