import { Button, Grid, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { CircularLoaderFullPage } from "components/core";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  dateFormatWithTimeWithSeconds,
  dayMonthYearFormat,
  downloadReportOptions,
  errorMsgs
} from "utils/constants";
import { CustomerFileStyles } from "./CustomerFileStyles";

const CustomerFileTable = ({
  setIsViewing,
  setIsUpdating,
  isUpdating,
  setIsDeleting,
  searchResults,
  setSelectedRow,
  isResetGrid,
  setSelctedId,
  isLoading,
  isManager,
  isViewDisabled,
  canBeDeactivated,
  canBeReactivated,
  selectedRow,
  isReactivateVisible,
  errorMsg,
  setViewText,
  setActivateText,
  handleActivate,
  setThrowErrorMessage,
  setViewPath,
  isEditDisabled,
  selectedIndex,
  setSelectedIndex,
  handleDownload,
  setThrowError,
  throwError,
  throwErrorMessage,
  showPrintModal,
  setShowPrintModal
}) => {
  const { localeByBranch } = useSelector(selectAuth);

  const { t } = useTranslation();
  const handlePrintButtonClick = () => {
    setThrowErrorMessage(null);
    setShowPrintModal((prev) => !prev); // enable the print modal show
  };
  const handleViewButton = async () => {
    setThrowErrorMessage(null);
    const msg = await errorMsg(errorMsgs.errorCode25290);
    setViewText(msg);
    setIsViewing(true);
  };
  const handleDectivateButton = async () => {
    setThrowErrorMessage(null);
    let msg = "";
    const reqId = selectedRow?.Picking_Request_Id;
    if (reqId) {
      msg = await errorMsg(errorMsgs.errorCode25084, reqId);
    } else msg = await errorMsg(errorMsgs.errorCode25086);
    setActivateText(msg);
    setIsDeleting(true);
  };

  const handleRowClick = (rowData, tableMeta) => {
    setViewPath("");
    const rowSelected = searchResults[tableMeta?.dataIndex];
    setSelectedIndex(tableMeta?.dataIndex);
    setSelectedRow(rowSelected);
  };

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    download: searchResults?.length > 0,
    search: searchResults?.length > 0,
    filter: searchResults?.length > 0,
    viewColumns: searchResults?.length > 0,
    responsive: "standard",
    overflowX: "auto",
    sort: true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={!selectedRow}
            onClick={handlePrintButtonClick}
            sx={CustomerFileStyles.PrintModalButton}
          >
            {t("CustomerFileSearch.printDetails")}
          </Button>
          {isManager && (
            <>
              <Button
                variant="outlined"
                size="medium"
                type="button"
                disabled={isViewDisabled()}
                onClick={handleViewButton}
                sx={CustomerFileStyles.PrintModalButton}
              >
                {t("CustomerFileSearch.viewFile")}
              </Button>
              {searchResults?.length > 0 && (
                <>
                  {isReactivateVisible || !canBeReactivated() ? (
                    <Button
                      variant="outlined"
                      size="medium"
                      type="button"
                      disabled={canBeReactivated()}
                      onClick={handleActivate}
                      sx={CustomerFileStyles.PrintModalButton}
                    >
                      {t("CustomerFileSearch.reactivate")}
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      size="medium"
                      type="button"
                      disabled={canBeDeactivated()}
                      onClick={handleDectivateButton}
                    >
                      {t("CustomerFileSearch.deactivate")}
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults?.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    selectableRowsOnClick: false,
    onRowClick: (rowData, rowMeta) => handleRowClick(rowData, rowMeta),
    setRowProps: (row, dataIndex) => ({
      style: {
        cursor: "pointer",
        backgroundColor:
          selectedRow && dataIndex === selectedIndex
            ? CustomerFileStyles?.SelectedRowColor
            : "",
        "&:hover": {
          backgroundColor: CustomerFileStyles?.SelectedRowColor
        }
      }
    })
  };

  const columns = [
    {
      name: "",
      label: "",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = searchResults[tableMeta.rowIndex];
          let id = rowData?.customer_file_section_id;
          return (
            <>
              <Button
                disabled={isEditDisabled(id)}
                onClick={() => {
                  setIsUpdating(true);
                }}
                sx={
                  isEditDisabled(id)
                    ? CustomerFileStyles.disabledIconsOpacity
                    : null
                }
              >
                <SvgIcon component={EditIcon} />
              </Button>
            </>
          );
        }
      }
    },
    {
      name: "customer_number",
      label: t("CustomerFileSearch.customer"),
      options: {
        filter: false,
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        })
      }
    },
    {
      name: "file_source",
      label: t("CustomerFileSearch.fileSource"),
      options: {
        filter: false,
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        })
      }
    },
    {
      name: "Section_Type",
      label: t("CustomerFileSearch.sectionType"),
      options: {
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        })
      }
    },
    {
      name: "Created_Datetime",
      label: t("CustomerFileSearch.dateTime"),
      options: {
        filter: false,
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        }),
        customBodyRender: (value) => {
          const formattedDate = value
            ? dayjs
                .utc(value)
                .locale(localeByBranch)
                .format(dateFormatWithTimeWithSeconds)
            : "";
          return formattedDate;
        }
      }
    },
    {
      name: "Service_Date",
      label: t("CustomerFileSearch.serviceDate"),
      options: {
        filter: false,
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        }),
        customBodyRender: (value) => {
          const formattedDate = value
            ? dayjs(value).format(dayMonthYearFormat)
            : "";
          return formattedDate;
        }
      }
    },
    {
      name: "Section_Name",
      label: t("CustomerFileSearch.section"),
      options: {
        filter: false,
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        })
      }
    },
    {
      name: "Logical_Vault",
      label: t("CustomerFileSearch.logicalVault"),
      options: {
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        })
      }
    },
    {
      name: "file_section_status",
      label: t("CustomerFileSearch.status"),
      options: {
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        })
      }
    },
    {
      name: "Data_Row_Count",
      label: t("CustomerFileSearch.itemCount"),
      options: {
        filter: false,
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        })
      }
    },
    {
      name: "Error_Count",
      label: t("CustomerFileSearch.errors"),
      options: {
        filterType: "checkbox",
        filterOptions: {
          names: [t("CustomerFileSearch.showErrorsTitle")],
          logic: (value) => value !== 0
        },
        setCellProps: () => ({
          style: CustomerFileStyles.actionColumnStyle
        })
      }
    }
  ];
  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      <Grid
        display={"flex"}
        direction={"column"}
        gap={spacing.verticalMargin20}
      >
        <Grid item xs={12} lg={3} marginTop={spacing.margingTop20}>
          <MUIDataTable
            columns={columns}
            options={options}
            data={searchResults}
            title={
              !isResetGrid && (
                <Typography style={CustomerFileStyles.titleStyle}>
                  {searchResults?.length} {t("common.recordsFound")}
                </Typography>
              )
            }
          />
        </Grid>

        <Grid id="media_Des_Req_Search_Req_Date_To" item xs={3}></Grid>

        <DownloadPopup
          title={t("CustomerFileSearch.printReportTitle")}
          showPrintModal={showPrintModal}
          downloadOptions={downloadReportOptions}
          onCancleClick={() => {
            setShowPrintModal((prev) => !prev);
            setThrowError(false);
          }}
          onDownloadClick={handleDownload}
          setThrowError={setThrowError}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
        />
      </Grid>
    </>
  );
};

export default CustomerFileTable;
