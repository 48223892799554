import dayjs from "dayjs";
import { t } from "i18next";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { getMessageFromCode } from "services/api/query";
import {
  ALL_SELECTION,
  dayMonthYearFormat,
  errorMsgs,
  numberConstants
} from "../index";
export const getOptions = (response, value, label) => {
  const options = response.map((res) => ({
    value: res[value],
    label: res[label].trim()
  }));
  return options;
};

export const initialValues = {
  requestedBy: "",
  auditType: "",
  comment: "",
  due_date: null,
  vault_from: ALL_SELECTION?.value,
  vault_to: ALL_SELECTION?.value,
  row_from: ALL_SELECTION?.value,
  row_to: ALL_SELECTION?.value,
  rack_from: ALL_SELECTION?.value,
  rack_to: ALL_SELECTION?.value,
  shelf_from: ALL_SELECTION?.value,
  shelf_to: ALL_SELECTION?.value,
  audit_sample_method_id: "",
  customer_id: "",
  logical_vault_id_list: [],
  om_batch_id_list: []
};

export const openMediaAuditDetailConst = {
  systemId: "60",
  typeId: "149",
  dropdownListTypeId: "6",
  constantM: "M",
  requestor_code: "requestor_code",
  requestor_Id24: "24",
  audit_type_id: "audit_type_id",
  logical_vault: "Logical Vault",
  location: "Location",
  distribution: "Distribution",
  customer_distribution: "Customer Distribution",
  requested_by_label: "requestedBy",
  audit_type: "auditType",
  due_date: "due_date",
  audit_sample_method_id: "audit_sample_method_id",
  vault_from: "vault_from",
  vault_to: "vault_to",
  row_from: "row_from",
  row_to: "row_to",
  shelf_to: "shelf_to",
  shelf_from: "shelf_from",
  rack_from: "rack_from",
  rack_to: "rack_to",
  customerId: "customer_id",
  ironMountainLink: "IronMountainLink",
  comment: "comment"
};

// message

export const messageCodes = {
  //logical vault error message code
  messageCodes80237: "80237",
  messageCodes80190: "80190",
  messageCodes80189: "80189",
  //customer distribution error message code
  messageCodes80238: "80238",
  messageCodes80192: "80192",
  messageCodes80191: "80191",

  messageCodes62521: "62521",
  messageCode80193: "80193",
  messageCode80194: "80194",
  messageCodes80175: "80175",
  messageCodes80170: "80170",
  messageCodes80217: "80217",
  messageCodes80176: "80176",
  messageCodes80180: "80180",
  messageCode80241: "80241",
  messageCodes80178: "80178",
  messageCodes80226: "80226",
  messageCodes80177: "80177",
  messageCodes80223: "80223",
  messageCodes80234: "80234",
  messageCodes25088: "25088",
  messageCodes25004: "25004"
};

export const getResponseData = async (requestBody, url, resultCount) => {
  const response = await callToCloudFunction(requestBody, url);
  const data = await getDataFromFirestore(
    response,
    resultCount,
    response.data.docId
  );
  return data;
};

export const notCompletedSelection = {
  label: t("openMediaAuditSearch.notCompleted"),
  value: numberConstants.minusOne
};

export const status = ["New", "Completed", "Canceled", "PendingResolution"];
export const status2 = ["Completed", "Canceled"];

export const CompletedStatus = "Completed";
export const requestedByCustomer = "Customer";
export const system_id = "69";
export const district_id = "50";

export const getTheFirestoreErrorMessage = async (msgId) => {
  const msg = await getMessageFromCode(msgId);
  return { errorMsg: msg[0]?.descr };
};

export const getTheEmailBody = async (name, linkData) => {
  let body =
    '<html><head><style type="text/css">p, a, body, td {font-family: Arial, Helvetica, sans-serif;font-size: x-small;}</style></head><body>';
  const actionText = await getTheFirestoreErrorMessage(
    errorMsgs.errorCode64004
  );
  body += actionText?.errorMsg;
  const actionText2 = await getTheFirestoreErrorMessage(
    errorMsgs.errorCode80222
  );
  body += actionText2?.errorMsg?.replace("#%1%", name);
  const actionText3 = await getTheFirestoreErrorMessage(
    errorMsgs.errorCode64005
  );
  body += actionText3?.errorMsg;
  if (linkData?.length) {
    const link = await getTheFirestoreErrorMessage(errorMsgs.errorCode64021);
    const url = linkData[0]?.setting_value;
    const urlName = linkData[0]?.setting_type_descr;
    const linkTag = `<a href=${url}>${urlName}</a>`;
    body += link?.errorMsg?.replace("%1%", linkTag);
  }
  body += "</body></html>";
  return body;
};

export const unknownMedia = "Unknown Media";
export const letterV = "V";
export const True = "True";
export const False = "False";
export const addNewEmail = "addNewEmail";
export const hyphenSymbol = "-";
export const searchCount500 = 500;
export const severityError = "error";
export const severityWarning = "warning";
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const mediaTypes = "P";
export const distribution = "Distribution";
export const started = "Started";
export const requestedByLabel = "requestedBy";
export const copyLabel = "copy";
export const injectLabel = "inject";
export const squareBracketsOpen = "[";
export const squareBracketsClose = "]";
export const angleBracketsOpen = "<";
export const angleBracketsClose = ">";
export const bracesOpen = "{";
export const scheduled = "Scheduled";
export const reportTypeInitial = "Initial";
export const reportTypeHistory = "History";

export const generatePatterns = (template, customerNumber) => {
  // Step 1: Pad customer number to 6 characters
  const paddedCustomerNumber = customerNumber?.padStart(6, "0");

  // Step 2: Replace CUST with padded customer number
  let templateWithCustomer = template?.replace("CUST", paddedCustomerNumber);

  // Step 3: Replace V with _
  templateWithCustomer = templateWithCustomer?.replace(/V/g, "_");

  // Step 4: Generate patterns by replacing MT with each media type
  // Replace MT with media type
  let mediaTemplate = templateWithCustomer?.replace("MT", mediaTypes);
  // Step 5: Convert literal markers like [123] to 123
  let regexPattern = mediaTemplate?.replace(/\[(.*?)\]/g, "$1");
  // Step 6: Remove {} placeholders and replace with _
  regexPattern = regexPattern?.replace(/{}/g, "_");

  // Step 7: Remove <xx> character injectors
  regexPattern = regexPattern?.replace(/<[^>]*>/g, "");

  // Step 8: Replace _ with [\s\S]
  regexPattern = regexPattern?.replace(/_/g, "[\\s\\S]");

  // Step 9: Add start and end anchors
  regexPattern = `^${regexPattern}$`;
  return regexPattern;
};
// eslint-disable-next-line
export const specialCharRex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;

export const handleGetnameFormatting = (name, date) => {
  return t("openMediaAuditDetail.nameDatelable", {
    name: name,
    date: dayjs.utc(date).format(dayMonthYearFormat)
  });
};

export const locationDefaultValue = "<A";
