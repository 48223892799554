const spacing = {
  verticalMargin10: "10px",
  verticalMargin16: "16px",
  verticalMargin20: "20px",
  verticalMargin25: "25px",
  verticalMargin32: "32px",
  verticalMargin64: "64px",
  horizontalMargin6: "4px",
  horizontalMargin8: "8px",
  horizontalMargin16: "16px",
  horizontalMargin20: "20px",
  horizontalMargin24: "24px",
  horizontalMargin47: "47px",
  horizontalMargin70: "70px",
  horizontalMargin132: "132px",
  gap: "20px",
  gap10: "10px",
  padding: "20px",
  margingTop20: "20px"
};

export default spacing;
