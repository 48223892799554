import { useTheme } from "@emotion/react";
import { Checkbox, ThemeProvider, createTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import WarningIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { requestModuleStyles } from "components/features/request-module/media-request-search/MediaRequestSearchModule.Styles";
import { NonEditableField } from "components/shared";
import i18n from "i18n";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useCancelMediaRequestMutation,
  useCheckCancelRequestMutation,
  useGetMessageByIdFromMessageMutation
} from "services/api";
import {
  removeErrorMessage,
  selectMediaRequest,
  setErrorMessage
} from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  REQUEST_ACTIONS,
  Y_CHECKER
} from "utils/constants";
import {
  MESSAGE_20355,
  MESSAGE_25170
} from "utils/constants/request-module/MediaRequestDetailConstants";
import { CancelRequestModalStyles } from "./CancelRequestModal.Styles";

const dataTableOptions = {
  ...DEFAULT_DATA_TABLE_OPTIONS,
  responsive: "vertical",
  tableBodyHeight: "230px",
  tableBodyMaxHeight: "230px",
  setRowProps: (row) => ({
    style: {
      height: "32px"
    }
  }),
  textLabels: {
    body: {
      noMatch: i18n.t("mediaRequestDetail.noItemsToDisplay")
    }
  }
};

const CancelRequestWithoutAuthorizationModal = (props) => {
  const { actionId } = useSelector(selectMediaRequest);
  const dispatch = useDispatch();
  const globalTheme = useTheme();
  const searchDataTableTheme = createTheme(globalTheme, {
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            lineHeight: "30px"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            height: "54px"
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontSize: "13px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "0.17000000178813934px",
            textAlign: "left",
            height: "60px",
            paddingLeft: "16px",
            paddingRight: "16px",
            color: "rgba(0, 0, 0, 0.6) !important",
            backgroundColor: "var(--color-white-100)",
            textRendering: "optimizeLegibility"
          }
        }
      }
    }
  });
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(true); // full page loading before showing modal
  const [error, setError] = useState("");

  //checkbox default check
  const CheckboxCell = ({ value }) => {
    const [checked, setChecked] = useState(value === Y_CHECKER);

    const handleCheckboxChange = () => {
      setChecked(!checked);
    };

    return <Checkbox checked={checked} onChange={handleCheckboxChange} />;
  };

  // container grid column definition
  const containersColumnDefinition = [
    {
      name: "new_retention_flag",
      label: t("mediaRequestDetail.indefiniteRetentionFlagCaps"),
      options: {
        customBodyRender: (value) => <CheckboxCell value={value} />
      }
    },
    {
      name: "container_number",
      label: t("mediaRequestDetail.mediaNumberCaps")
    },
    {
      name: "media_type_descr",
      label: t("mediaRequestDetail.mediaTypeCaps")
    },
    {
      name: "new_service_date",
      label: t("mediaRequestDetail.serviceDateCaps")
    },
    {
      name: "comment",
      label: t("mediaRequestDetail.commentsCaps")
    }
  ];

  const [checkCancelRequest, { data: requestMediaContainerTransports = [] }] =
    useCheckCancelRequestMutation();
  const [cancelMediaRequest, { isLoading: cancellingMediaRequest }] =
    useCancelMediaRequestMutation();
  const [
    getMessageByIdFromMessage,
    { isError: isMessageByIdError, error: messageByIdError }
  ] = useGetMessageByIdFromMessageMutation();

  // call the CF to cancel the request
  const handleCancelRequest = async (requestId, timestamp) => {
    await cancelMediaRequest({
      requestId: requestId,
      cancelPersonnelId: "",
      cancelBypassReason: "",
      timestamp: timestamp
    }).then((resp) => props.onSubmit());
  };

  // check whether the request has container or errors when canceling
  const handleCheckCancelRequest = async (requestId, timestamp) => {
    setPageLoading((prevState) => true);
    if (
      actionId === REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID ||
      actionId === REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID ||
      actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID ||
      actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID
    ) {
      const data = await checkCancelRequest(requestId).unwrap();
      if (Number(data[0].error) === 20355) {
        // setShowTable(false);
        getMessageByIdFromMessage({ message_id: MESSAGE_20355 })
          .then((resp) => resp?.data[0])
          .then((resp) => {
            const replacedSentence = resp?.descr
              .replace("|", t("mediaRequestDetail.cancelRequest").toLowerCase())
              .replace("|", t("mediaRequestDetail.run"))
              .replace("|", t("mediaRequestDetail.greaterThanRunPrep"));
            setError((prevState) => replacedSentence);
          });
      } else if (Number(data[0].error) > 1) {
        // setShowTable(false);
        getMessageByIdFromMessage({ message_id: MESSAGE_25170 })
          .then((resp) => resp?.data[0])
          .then((resp) => {
            setPageLoading((prevState) => false);
            setError((prevState) => resp?.descr);
          });
      } else if (Number(data[0].error) === 1) {
        // setShowTable(false);
        setError((prevState) => "");
        await handleCancelRequest(requestId, timestamp).then(() =>
          props.onSubmit()
        );
      } else if (data.length > 0 && Number(data[0].error) === 0) {
        // setShowTable(true);
        setPageLoading((prevState) => false);
      }

      if (data.length === 0) {
        // setShowTable(false);
        setError((prevState) => "");
        await handleCancelRequest(requestId, timestamp).then(() =>
          props.onSubmit()
        );
      }
    } else {
      // setShowTable(false);
      setError((prevState) => "");
      await handleCancelRequest(requestId, timestamp).then(() =>
        props.onSubmit()
      );
    }
  };

  // cancel button click event handler
  const handleOnClick = (event) => {
    switch (event.target.id) {
      case "mediaRequestDetailRejectButton":
        props.onClose(false);
        break;
      case "mediaRequestDetailAcceptButton":
        handleCancelRequest(props.requestId, props.timestamp);
        break;
      default:
        break;
    }
  };

  // init run cancel request check
  useEffect(() => {
    handleCheckCancelRequest(props.requestId, props.timestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set error message in redux if one occurs
  useEffect(() => {
    let message = "";

    if (isMessageByIdError) {
      message = `${t("mediaRequestDetail.errorInMessageId")} ${
        messageByIdError.message
      }`;
    }
    // if message has set error message to redux, if not removed error message from redux
    if (message) {
      dispatch(setErrorMessage(message));
    } else {
      dispatch(removeErrorMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageByIdError]);

  return pageLoading ? (
    <CircularLoaderFullPage loading={pageLoading} />
  ) : (
    <Modal
      open={props.show}
      aria-labelledby="cancel-request-modal"
      aria-describedby="cancel-request-modal-content"
      sx={CancelRequestModalStyles.modal}
    >
      <Card sx={CancelRequestModalStyles.card}>
        {/* ------------------ Title Section ------------------ */}
        <Typography
          variant="h6"
          sx={requestModuleStyles.mainTitle_PRSPopUP}
          marginBottom={"0"}
          gutterBottom
        >
          {t("mediaRequestDetail.cancelRequest")}
        </Typography>

        <Divider />

        {/* error message */}
        {error && (
          <Alert
            id="mediaRequestDetailErrorAlert"
            icon={<img src={WarningIcon} alt="Warning" />}
            severity="error"
            color="error"
            onClose={() => setError("")}
            sx={CancelRequestModalStyles.error}
          >
            {error}
          </Alert>
        )}

        {/* Containers of the Request to be canceled */}
        <CardContent>
          <Grid
            id="mediaRequestDetailGrid27"
            container
            columnSpacing={spacing.verticalMargin20}
            paddingBottom={spacing.verticalMargin20}
            item
            xs={12}
          >
            {/* Request Id */}
            <Grid id="mediaRequestDetailGrid29" item xs={12} md={6} lg={3}>
              <NonEditableField
                id="mediaRequestDetailRequestId"
                label={t("mediaRequestDetail.requestId")}
                value={props.requestId}
              />
            </Grid>

            {/* Request Type */}
            <Grid id="mediaRequestDetailGrid29" item xs={12} md={6} lg={3}>
              <NonEditableField
                id="mediaRequestDetailRequestType"
                label={t("mediaRequestDetail.requestType")}
                value={props.requestType?.label}
              />
            </Grid>
          </Grid>

          {/* Medias attached to the request table */}
          <ThemeProvider theme={searchDataTableTheme}>
            <MUIDataTable
              id="deliveryMediaTabContainerDataTable19"
              data={requestMediaContainerTransports}
              columns={containersColumnDefinition}
              options={dataTableOptions}
            />
          </ThemeProvider>
        </CardContent>

        <Divider />

        <CardActions sx={requestModuleStyles.cardActionsStyle_PRSPopUP}>
          {/* Reject Button */}
          <Button
            id="mediaRequestDetailRejectButton"
            sx={requestModuleStyles.buttonStyle_PRSPopUP}
            variant="outlined"
            type="button"
            onClick={handleOnClick}
          >
            {t("mediaRequestDetail.reject")}
          </Button>

          {/* Accept Button */}
          <Button
            id="mediaRequestDetailAcceptButton"
            sx={requestModuleStyles.buttonStyle_PRSPopUP}
            variant="contained"
            type="button"
            onClick={handleOnClick}
          >
            {cancellingMediaRequest && (
              <CircularProgress sx={CancelRequestModalStyles.progressIcon} />
            )}
            {t("mediaRequestDetail.accept")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CancelRequestWithoutAuthorizationModal.propTypes = {
  show: PropTypes.bool, // show/hide modal state
  requestId: PropTypes.number.isRequired, // id of the request
  requestType: PropTypes.string.isRequired, // media request type
  timestamp: PropTypes.string.isRequired, // timestamp of the request
  onClose: PropTypes.func, // handle modal close event
  onSubmit: PropTypes.func // handle modal submit event
};

export default CancelRequestWithoutAuthorizationModal;
