import { Alert, Box, Button, IconButton, TextField } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ErrorIcon from "assets/images/warning-2.svg";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  errorMsgs,
  type,
  VALUE_EMPTY_STRING,
  VALUE_N
} from "utils/constants";
import { zero } from "utils/constants/disaster-module/InboundDateConst";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getTheFirestoreErrorMessage
} from "utils/helpers";
import { disasterRecoveryStyles } from "../DisasterRecoveryStyles";

const {
  default: CommonModal
} = require("components/shared/common-modal/CommonModal");

const AddContainer = ({
  open,
  onClose,
  setContainerData,
  customerLocations,
  containerData,
  handleError,
  setLoading,
  setErrorMessage,
  selectedContainer,
  isUpdateMode,
  deleteContainer,
  setIsUpdateMode,
  RequestForm
}) => {
  const [containerNumber, setContainerNumber] = useState(VALUE_EMPTY_STRING);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const districtId = getAuthenticatedUserBranch();

  const textRef = useRef(null);
  useEffect(() => {
    if (isUpdateMode && selectedContainer) {
      setContainerNumber(selectedContainer);
    } else {
      setContainerNumber(VALUE_EMPTY_STRING);
    }
  }, [isUpdateMode, selectedContainer]);

  useEffect(() => {
    textRef.current?.focus();
  }, []);

  const checkForDuplicate = (number) => {
    return !!containerData.find((c) => c.number?.trim() === number?.trim())
      ?.number;
  };

  const addOrUpdateContainer = async () => {
    try {
      let errCode = VALUE_EMPTY_STRING;
      if (containerNumber.length > 18) {
        errCode = errorMsgs.errorCode20360;
      }
      let hasDuplicate = checkForDuplicate(containerNumber);
      if (hasDuplicate && !isUpdateMode) {
        errCode = errorMsgs.errorCode20382;
      }
      if (errCode) {
        let { errorMsg } = await getTheFirestoreErrorMessage(errCode);
        setThrowError(true);
        setThrowErrorMessage(
          errorMsg.replace(
            "|",
            t("disasterRecovery.eventDetail.manualEntry.containerNumber")
          )
        );
        return;
      }
      setErrorMessage(VALUE_EMPTY_STRING);
      setThrowError(false);
      setLoading(true);
      const reqbody = {
        main_district_id: districtId,
        customer_id: customerLocations[0]?.value,
        container_number: containerNumber,
        logical_vault_id: VALUE_EMPTY_STRING,
        media_program_type_id: VALUE_EMPTY_STRING,
        secure_sync_flag: VALUE_N,
        redelivery_flag: VALUE_N,
        new_request_type_id: zero,
        page_number: VALUE_EMPTY_STRING,
        no_of_records: VALUE_EMPTY_STRING,
        last_mod_user: VALUE_EMPTY_STRING
      };

      let response = await getResponseData(
        reqbody,
        `${CF_URLS?.disasterRecovery.manualEntry.validateContainer}`,
        2
      );
      let data = response.data;
      if (data[0] && data[0][0]?.error && data[0][0]?.error !== zero) {
        handleError(data[0][0]?.error);
        return;
      }

      if (data[0].length && data[0][0]?.number) {
        setLoading(false);
        let hasError =
          typeof data[1][0].msg_code === type.string &&
          data[1][0].msg_code !== zero;
        if (hasError) {
          handleError(data[1][0].msg_code, [
            t("disasterRecovery.eventDetail.manualEntry.processRequest"),
            t("disasterRecovery.eventDetail.manualEntry.container"),
            data[1][0].exchange_status_type
          ]);
          onClose();
          return;
        }

        if (isUpdateMode) {
          let list = containerData.map((c) =>
            c?.number?.trim() === selectedContainer
              ? {
                  ...response.data[0][0],
                  selected: true,
                  container_number: c?.number?.trim()
                }
              : c
          );
          setContainerData(list);
          RequestForm.setFieldValue("container", list);

          setIsUpdateMode(false);
        } else {
          let list = [...containerData, response.data[0][0]].map((c) => ({
            ...c,
            selected: true,
            container_number: c?.number?.trim()
          }));
          RequestForm.setFieldValue("container", list);
          setContainerData(list);
        }
        onClose();
        setContainerNumber(VALUE_EMPTY_STRING);
      } else {
        handleError(errorMsgs.errorCode10015);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  const onChangeContainer = (val) => {
    setContainerNumber(val);
  };

  return (
    <CommonModal
      open={open}
      title={
        isUpdateMode
          ? t("disasterRecovery.eventDetail.manualEntry.updateContainer")
          : t("disasterRecovery.eventDetail.manualEntry.newContainer")
      }
      body={
        <>
          {throwError && (
            <Box mb={spacing.gap}>
              <Alert
                severity="error"
                icon={
                  <img
                    src={ErrorIcon}
                    alt="error"
                    style={disasterRecoveryStyles.manualEntry.errorIcon}
                  />
                }
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <GridCloseIcon />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Box>
          )}

          <TextField
            label={t(
              "disasterRecovery.eventDetail.manualEntry.containerNumber"
            )}
            inputRef={textRef}
            variant="outlined"
            fullWidth
            value={containerNumber}
            onChange={(e) => onChangeContainer(e.target.value)}
          />
        </>
      }
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            disabled={!containerNumber}
            onClick={addOrUpdateContainer}
          >
            {isUpdateMode
              ? t("common.update")
              : t("disasterRecovery.eventDetail.manualEntry.validateAndAdd")}
          </Button>
        </>
      }
    />
  );
};
export default AddContainer;
