import {
  cancelRequest,
  deleteRequestBoxTypeCount,
  deleteRequestComment,
  getAuthorizationPersonnels,
  getBoxTypes,
  getBoxTypesCount,
  getCustomerInfo,
  getDestructRequestDetails,
  getDispositionServices,
  getPersonnelAuth,
  getRequestComments,
  getRunRequestStatus,
  getServiceLocationByCustomer,
  getSpecialHandling,
  getTrackingRequirements,
  getUnitOfMeasures,
  insertRequestBoxTypeCount,
  insertRequestComment,
  insertRequestSpecialHandling,
  saveScheduleRequest,
  saveSitadRequest,
  uploadFile,
  verifyPersonnelPermission
} from "services/common";
import { TAG_TYPES } from "utils/constants";
import { baseApi } from "../baseApi";
/**
 * @module mediaDestructionApi request media detail RTK Query endpoints
 */
export const mediaDestructionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // get personnal for customers
    getPersonnelCustomer: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getAuthorizationPersonnels(props);
          return {
            data: response?.map(({ personnel_id, name }) => ({
              label: name,
              value: personnel_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customer]
    }),
    // get the personnel Auth info for personnel
    getPersonnelAuthCustomer: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getPersonnelAuth(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customer]
    }),
    // get the tracking requirements
    getTrackingRequirements: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getTrackingRequirements(props);
          // filter the values only having the pickup in the values
          return {
            data: response
              ?.filter(({ descr }) => descr.includes("Pickup"))
              .map(({ descr, destruct_request_type_id }) => ({
                label: descr,
                value: destruct_request_type_id
              }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.trackingTypes]
    }),
    //get the box types
    getBoxTypes: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getBoxTypes(props);
          return {
            data: response?.map(
              ({ box_type, destruct_request_box_type_id }) => ({
                boxType: box_type,
                id: destruct_request_box_type_id,
                value: 0
              })
            )
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.boxTypes]
    }),
    //get the unit of measure
    getUnitOfMeasures: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getUnitOfMeasures(props);
          return {
            data: response?.map(({ display_descr, unit_of_measure_id }) => ({
              label: display_descr,
              value: unit_of_measure_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.unitOfMeasures]
    }),
    // get the disposition services
    getDispositionServices: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getDispositionServices(props);
          return {
            data: response?.map(
              ({
                handling_instruction,
                destruct_request_handling_type_id
              }) => ({
                label: handling_instruction,
                value: destruct_request_handling_type_id
              })
            )
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.dispositionServices]
    }),
    // get the customer Details
    getCustomerInfo: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getCustomerInfo(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.customerPermission]
    }),
    // get the customer service location
    getCustomerServiceLocation: builder.query({
      queryFn: async (props) => {
        try {
          const response = await getServiceLocationByCustomer(props);
          return {
            data: response?.map(({ number, customer_id }) => ({
              label: number?.trim(),
              value: number?.trim(),
              id: customer_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.serviceLocation]
    }),
    // get the special handling
    getSpecialHandling: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getSpecialHandling(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.specialHandling]
    }),
    // get the comment test for the request
    getRequestComments: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getRequestComments(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.getRequestComments]
    }),
    // get the box types
    getBoxTypesCount: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getBoxTypesCount(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.boxTypesCount]
    }),
    // get the destruct details
    getDestructDetails: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getDestructRequestDetails(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.destructDetail]
    }),
    //save sitad
    saveSitadRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await saveSitadRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.sitadRequest]
    }),
    //delete request box type count
    deleteRequestBoxTypeCount: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await deleteRequestBoxTypeCount(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.deleteRrequestBoxTypeCount]
    }),
    // insert request box count
    insertRequestBoxTypeCount: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await insertRequestBoxTypeCount(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestBoxTypeCount]
    }),
    //insert request special handling
    insertRequestSpecialHandling: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await insertRequestSpecialHandling(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestSpecialHandling]
    }),
    // delete request comment text
    deleteRequestComment: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await deleteRequestComment(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.deleteRequestComment]
    }),
    // insert request comment text
    insertRequestComment: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await insertRequestComment(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestComment]
    }),
    // save schedule request
    saveScheduleRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await saveScheduleRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.scheduleRequest]
    }),
    // cancel request
    cancelRequest: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await cancelRequest(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.request]
    }),
    // get other request status Id
    getRunRequestStatus: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await getRunRequestStatus(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.runRequestStatus]
    }),
    // verify personnel permission
    verifyPersonnelPermission: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await verifyPersonnelPermission(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.verifyPermission]
    }),
    // uploadFile
    uploadFile: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await uploadFile(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.uploadFile]
    })
  })
});
