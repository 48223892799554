import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import ModalPopup from "components/core/modal-components/ModalPopup";
import dayjs from "dayjs";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_MODULE_DESTRUCTION_BASE_PATH
} from "routing/Paths";
import {
  useCancelRequestMutation,
  useDeleteRequestBoxTypeCountMutation,
  useDeleteRequestCommentMutation,
  useGetAllCustomersQuery,
  useGetBoxTypesCountMutation,
  useGetBoxTypesQuery,
  useGetCustomerInfoQuery,
  useGetCustomerServiceLocationQuery,
  useGetDestructDetailsMutation,
  useGetDispositionServicesQuery,
  useGetGlobalAttributeValueByTypeIdQuery,
  useGetMessageByIdFromMessageMutation,
  useGetRequestCommentsMutation,
  useGetRunRequestStatusMutation,
  useGetSpecialHandlingMutation,
  useGetTrackingRequirementsQuery,
  useGetUnitOfMeasuresQuery,
  useInsertRequestBoxTypeCountMutation,
  useInsertRequestCommentMutation,
  useInsertRequestSpecialHandlingMutation,
  useSaveScheduleRequestMutation,
  useSaveSitadRequestMutation,
  useUploadFileMutation,
  useVerifyPersonnelPermissionMutation
} from "services/api";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  GLOBAL_ATTRIBUTE_VALUES,
  N_CHECKER,
  SYSTEM_ID_SecureBaseWeb,
  Y_CHECKER
} from "utils/constants";
import { formatDateByLocale, getLanguageId } from "utils/helpers";
import { ONE_SECOND_IN_MILLISECONDS } from "../media-destruction-request-vault-itemized/Constants";
import {
  BypassId,
  CommentType,
  CustomerOnHoldMessageId,
  DateFormat,
  DispositionServiceCommentTypeId,
  InSufficientAuthorizationMessageId,
  OtherRequestStatus,
  ProgramId,
  RequestStatusIds,
  ReviewCommentTypeId,
  SecureSyncSysytemId,
  TrackingRequirementIds,
  schedulePickupInstructionsCommentTypeId
} from "./Constants";
import { MDRPickupFormStyles } from "./MediaDestructionRequestPickup.style";
import DownloadReportModal from "./PrintReportModal";
import PersonnelAuthentication from "./personnel-authentication-form/PersonnelAuthentication";
import { pickupSchema } from "./schema";
import MDRPickupButtons from "./sections/MDRPickupButtons";
import MDRPickupSchedule from "./sections/MDRPickupSchedule";
import MDRPickupUploadFile from "./sections/MDRPickupUploadFile";
import MDRReviewApprovalRequest from "./sections/MDRReviewApprovalRequest";
import SitadDeliveryRequest from "./sections/SitadDeliveryRequest";

function MediaRequestPickupDetail() {
  const initialValues = {
    selectedCustomer: null,
    notifyViaEmail: "",
    trackingRequirements: "",
    preferredPickupDate: null,
    serviceLocation: "",
    pickupInstructions: "",
    trackingNumber: "",
    dispositionServices: [],
    dispositionService: [],
    selectedDisposition: [],
    dispositionServiceComments: "",
    schedulePreferredPickupDate: null,
    scheduleServiceLocation: "",
    scheduleTrackingRequirements: "",
    schedulePickupInstructions: "",
    scheduleServiceModifiedDate: dayjs(Date.now()).format(DateFormat),
    sitadModifiedDate: dayjs(Date.now()).format(DateFormat),
    loggedEmployeeName: "",
    AssignmentEmployeeName: "",
    status: "",
    statusId: RequestStatusIds.None,
    authorizedPersonnelName: "",
    authorizedDatetimeLTZ: "",
    otherRequestId: "",
    cancelledDate: "",
    cancelledReason: "",
    cancelledBy: "",
    destructRequestId: "",
    cancelledId: "",
    boxTypesVal: [],
    boxTypeValSchedule: [],
    boxTypeValReviewApprove: [],
    destruct_req_timestamp: "",
    personnel: "",
    authType: "personnelAuth",
    mdr_on_hold_status: "",
    interactive_site_scan_flag: "",
    scan_on_site_flag: "",
    isVerified: "",
    verifyErrorMessage: "",
    onHoldErrorMessage: "",
    authVerified: false,
    preTrackingNumber: "",
    preNotifyViaEmail: "",
    reviewApproveTrackingRequirements: "",
    reviewDate: null,
    reviewApproveServiceLocation: "",
    reviewComments: "",
    requestedQuantity: 0,
    finalQuantity: 0,
    pickedUpQuantity: 0,
    weight: 0,
    selectedUnitOfMeasure: 0,
    certifiedDate: null,
    pickupDate: null,
    completedDate: null,
    reviewedEmployeeName: "",
    reviewedModifiedDate: "",
    uploadFileRequired: false,
    cfs_created_datetime_ltz: null,
    cfs_data_row_count: 0,
    preReviewComments: "",
    reviewApproveBoxtypeCount: 0
  };
  const { auth, currentBranch, roleAccess, localeByBranch } =
    useSelector(selectAuth);
  const selectedBranch = currentBranch?.district_id;
  const { id } = useParams();

  // loading indicator
  const [pageLoading, setPageLoading] = useState(false);
  const [personnelId, setPersonnelId] = useState("");
  //successfully crated the delivery request for sitad
  const [saveSitad, setSaveSitad] = useState(false);
  // to handle the validator error message on fields when clicking on the save button
  const [sitadErrorMsg, setSitadErrorMsg] = useState(false);
  //handle disabling the field once the request is created successfully
  const [diableSITAD, setdiableSITAD] = useState(false);
  // to handle the validator error message on fields when clicking on the schedule save
  const [scheduleErrorMsg, setScheduleErrorMsg] = useState(false);
  //handle disablinf of the fields of schedule, once schedule request created successfully
  const [diableSchedule, setDiableSchedule] = useState(false);
  // handle the status section of both schedule and sitad section
  const [statusAuthorized, setStatusAuthorized] = useState(false);
  //boxtypes for sitad and schedule
  const [SITADBoxTypes, setSITADBoxTypes] = useState([]);
  const [scheduleBoxTypes, setScheduleBoxTypes] = useState([]);
  const [reviewApproveBoxTypes, setReviewApproveBoxTypes] = useState([]);
  //handle the modified date section for schedule
  const [showScheduleModified, setShowScheduleModified] = useState(false);
  const [showReviewApprovedModified, setShowReviewApprovedModified] =
    useState(false);
  //handle to show the dialog box of the personnel auth
  const [enableCustomerAuth, setEnableCustomerAuth] = useState(false);
  //service loaction
  const [serviceLocation, setServiceLocation] = useState([]);
  //schedule service location
  const [scheduleServiceLocation, setScheduleServiceLocation] = useState([]);
  //revire approve service location
  const [reviewApproveServiceLocation, setReviewApproveServiceLocation] =
    useState([]);
  //Tracking Requirements
  const [trackingRequirement, setTrackingRequirement] = useState([]);
  //error handling on CF
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //cutomer list
  const [customerList, setCustomerList] = useState([]);
  //handle personnel authentication success state
  const [authSuccess, setAuthSuccess] = useState(false);
  //to show alert message when user is on hold
  const [onHoldAlert, setOnHoldAlert] = useState(false);
  //to show alert message for boxtype is empty
  const [boxtypeWraning, setBoxtypeWraning] = useState(false);
  //to show alert message for boxtype is empty on schedule
  const [boxtypeWraningSchedule, setBoxtypeWraningSchedule] = useState(false);
  //to show alert message for boxtype is empty on review approve
  const [boxtypeWraningReviewApprove, setBoxtypeWraningReviewApprove] =
    useState(false);
  //to show alert message when user is not authenticate
  const [alertMessage, setAlertMessage] = useState(false);
  //handle proint modal
  const [openPrintModal, setOpenPrintModal] = useState(false);
  //open wraining modal
  const [openWarningModal, setOpenWarningModal] = useState(false);
  // wraning text for modal
  const [warningModalText, setWarningModalText] = useState("");
  // handle modal on which btn type
  const [btnType, setBtnType] = useState("new");
  //enabling button on Tracking Type = interactive scan on site
  const [otherRequestStatusId, setOtherRequestStatusId] = useState(null);
  //VerifyAuthentication for each status
  const [bValid, setBvalid] = useState(false);
  // handling auth failed message on cancal
  const [authFailedCancel, setAuthFailedCancel] = useState(true);
  //unit of measures
  const [unitOfMeasures, setUnitOfMeasures] = useState([]);
  //to view the review panel
  const [visibiltyReviewPanel, setVisibiltyReviewPanel] = useState(false);
  //upload file table
  const [visibiltyUploadFile, setVisibiltyUploadFile] = useState(false);
  //enable weight panel
  const [enableWeightPanel, SetEnablwWeightPanel] = useState(false);
  const [reviewSection, setReviewSection] = useState(false);
  //total count on file upload
  const [itemCount, setItemCount] = useState(0);
  //review error message
  const [reviewErrorMsg, setReviewErrorMsg] = useState(false);
  //uploaded date
  const [dateUploaded, setDateUploaded] = useState(null);
  //volser list
  const [volserList, setVolserList] = useState("");
  //result set of getdestrucctbyid
  const [resultData, setResultData] = useState([]);
  const [loadingDestructionDetails, setLoadingDestructionDetails] =
    useState(false);

  //print modal
  const handleOpenPrintModal = () => setOpenPrintModal(true);
  const handleClosePrintModal = () => setOpenPrintModal(false);
  //warining modal
  const handleOpenWarningModal = () => setOpenWarningModal(true);
  const handleCloseWarningModal = () => setOpenWarningModal(false);

  // handle new
  const handleNew = () => {
    setPersonnelId("");
    setSaveSitad(false);
    setSitadErrorMsg(false);
    setdiableSITAD(false);
    setScheduleErrorMsg(false);
    setDiableSchedule(false);
    setStatusAuthorized(false);
    setSITADBoxTypes(boxTypes);
    setScheduleBoxTypes(boxTypes);
    setReviewApproveBoxTypes(boxTypes);
    setShowScheduleModified(false);
    setEnableCustomerAuth(false);
    setServiceLocation(serviceLocations);
    setScheduleServiceLocation(serviceLocations);
    setReviewApproveServiceLocation(serviceLocation);
    setIsError(false);
    setErrorMessage("");
    setCustomerList(customers);
    setAuthSuccess(false);
    setOnHoldAlert(false);
    setAlertMessage(false);
    setOpenPrintModal(false);
    setOpenWarningModal(false);
    setWarningModalText("");
    setBtnType("new");
    setOtherRequestStatusId(null);
    setBvalid(false);
    setVisibiltyReviewPanel(false);
    setVolserList("");
    setDateUploaded(null);
    setReviewErrorMsg(false);
    setItemCount(0);
    setReviewSection(false);
    SetEnablwWeightPanel(false);
    setVisibiltyUploadFile(false);
    setShowReviewApprovedModified(false);
    setBoxtypeWraningReviewApprove(false);
  };

  const branchPrevious = useRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const languageLocaleId = getLanguageId();
  //formik with inital values and validator
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: pickupSchema
  });
  //getting the customer
  const {
    data: customers = [],
    isError: customerError,
    isLoading: iscutomerLoading
  } = useGetAllCustomersQuery({
    mainDistrictId: selectedBranch
  });
  //Location Details
  const { data: serviceLocations = [], isError: locationError } =
    useGetCustomerServiceLocationQuery({
      customerId: String(formik.values?.selectedCustomer?.value)
    });
  // destruct_request_handling_type_l_sp
  const { data: trackingRequirements = [], isError: trackingReqError } =
    useGetTrackingRequirementsQuery();
  //destruct_request_type_l_sp(despostion services)
  const { data: dispositionServices = [], isError: dispositionError } =
    useGetDispositionServicesQuery();
  //boxtypes
  const {
    data: boxTypes = [],
    isError: boxTypeError,
    isLoading: isLoadingboxtypes
  } = useGetBoxTypesQuery({});
  //customer_19_sp_mockdata - customerInfo
  const {
    data: customerInfo = [],
    refetch: loadCustomerInfo,
    isError: customerInfoError
  } = useGetCustomerInfoQuery({
    customer_id: parseInt(formik.values?.selectedCustomer?.value),
    main_district_id: selectedBranch,
    language_locale_id: languageLocaleId
  });

  const {
    data: globalAttributeValue = "",
    isLoading: isLoadingGlobalAttribute,
    isError: isGlobalAttributeError
  } = useGetGlobalAttributeValueByTypeIdQuery({
    id: GLOBAL_ATTRIBUTE_VALUES.VALUE_187,
    system_id: SYSTEM_ID_SecureBaseWeb
  });
  const UPLOAD_FILE_MEDIA_TYPE_ID =
    globalAttributeValue !== "" ||
    !isGlobalAttributeError ||
    !isLoadingGlobalAttribute
      ? Number(globalAttributeValue)
      : 0;

  //destruct_request_detail_l_sp - destruction details
  const [triggerDestructRequestDetail] = useGetDestructDetailsMutation({});
  //destruct_request_special_handling_l_sp - desposition values
  const [triggerSpecialHandling] = useGetSpecialHandlingMutation({});
  //destruct_request_comment_l_sp - comments
  const [triggerRequestComment] = useGetRequestCommentsMutation({});
  //destruct_request_box_type_count_l_sp - boxtype counts
  const [triggerBoxTypesCount] = useGetBoxTypesCountMutation({});
  //destruct_request_i_sp(save Sitad)
  const [triggerSaveSitadRequest] = useSaveSitadRequestMutation({});
  //destruct_request_box_type_count_d_sp
  const [triggerDeleteRequestBoxTypeCount] =
    useDeleteRequestBoxTypeCountMutation({});
  //destruct_request_box_type_count_i_sp
  const [triggerInsertRequestBoxTypeCount] =
    useInsertRequestBoxTypeCountMutation({});
  //destruct_request_special_handling_i_sp
  const [triggerInsertRequestSpecialHandling] =
    useInsertRequestSpecialHandlingMutation({});
  //destruct_request_comment_d_sp
  const [triggerDeleteRequestComment] = useDeleteRequestCommentMutation({});
  //destruct_request_comment_i_sp
  const [triggerInsertRequestComment] = useInsertRequestCommentMutation({});
  //destruct_request_u2_sp(save schedule)
  const [triggerSaveScheduleRequest] = useSaveScheduleRequestMutation({});
  //file_section_interactive_site_item_i_sp(upload file)
  const [triggerUploadFile] = useUploadFileMutation({});

  // print
  const [triggerRunRequestStatus] = useGetRunRequestStatusMutation({});
  const { data: unitMeasures = [] } = useGetUnitOfMeasuresQuery();

  useEffect(() => {
    if (unitMeasures.length > 0) {
      const newValue = [{ label: t("pickup.n/a"), value: 0 }, ...unitMeasures];
      setUnitOfMeasures(newValue);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitMeasures]);
  // destruct_request_cancel_sp(cancel)
  const [triggerCancelRequest] = useCancelRequestMutation({});

  //get the message accodring to the id
  const [triggerMessage] = useGetMessageByIdFromMessageMutation();

  //  //trigger verify personnel
  const [triggerVerifyPersonnel] = useVerifyPersonnelPermissionMutation();
  //verifying Employee Permission
  const verifyEmployeePermission = (employeeProgramId) => {
    const filter = roleAccess?.filter(
      (id) => id === employeeProgramId.toString()
    );
    return filter.length ? true : false;
  };

  //get verify personnel status
  const VerifyPersonnelPermission = async (programId, personnelId) => {
    try {
      const { data: triggeredData } = await triggerVerifyPersonnel({
        customer_id: String(formik.values?.selectedCustomer.value),
        personnel_id: String(personnelId),
        program_id: String(programId)
      });
      return triggeredData;
    } catch (error) {
      setIsError(true);
      setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  //checking page is authorized
  const IsPageAuthorized = () => {
    return (
      (formik.values.selectedCustomer != null &&
        formik.values.personnelId !== "") ||
      authSuccess
    );
  };
  //CF to get the messages
  const handleMessageCF = (messageId, setAlertMessage, messageFrom) => {
    triggerMessage({
      message_id: messageId
    })
      .unwrap()
      .then((data) => {
        formik.setFieldValue(messageFrom, data[0].descr);
        setAlertMessage(true);
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };
  // to handle whether the customer having permission to do the actions according to the status an personnel
  const VerifyAuthentication = async (
    statusId,
    personnelId,
    forbuttonVerification
  ) => {
    var bValid = false;
    //verifying if the customer is selected
    if (formik.values.selectedCustomer === null) return false;

    switch (statusId) {
      //verifying whether the customer is having permission to create the pickup request if the status in "None"
      case RequestStatusIds.None:
        //emplyee permission verification
        bValid = await verifyEmployeePermission(ProgramId.PickupCreateEmployee);
        // if employee is verified and the page is authorized verifying for the personnel permission
        if (bValid && IsPageAuthorized()) {
          const personnelValid =
            personnelId.toString() === BypassId.toString()
              ? true
              : await VerifyPersonnelPermission(
                  ProgramId.PickupCreatePersonnel,
                  personnelId
                );
          bValid = personnelValid;
        }
        break;
      //verifying permission on Authorized and scheduled status
      case RequestStatusIds.Authorized:
      case RequestStatusIds.Scheduled:
        bValid = await verifyEmployeePermission(
          ProgramId.PickupScheduleEmployee
        );
        break;
      //verifying permission on PendingReview and PendingConfirmation status
      case RequestStatusIds.PendingReview:
      case RequestStatusIds.PendingConfirmation:
        bValid = await verifyEmployeePermission(ProgramId.PickupCreateEmployee);
        break;
      //verifying permission on Cancelled status
      case RequestStatusIds.Cancelled:
        bValid =
          personnelId.toString() === BypassId.toString()
            ? true
            : await VerifyPersonnelPermission(
                ProgramId.PickupCancelPersonnel,
                personnelId
              );
        break;
      //verifying permission on Completed and InProcess status
      case RequestStatusIds.Completed:
      case RequestStatusIds.InProcess:
        bValid =
          personnelId.toString() === BypassId.toString()
            ? true
            : await VerifyPersonnelPermission(
                ProgramId.ReadytoBill,
                personnelId
              );
        break;
      //verifying permission on Rejected status
      case RequestStatusIds.Rejected:
        bValid = false;
        break;
      default:
        setIsError(true);
        setErrorMessage(
          findErrorMessage(t("pickup.invalidRequestId") + formik.values.status)
        );
        break;
    }
    setBvalid(bValid);
    //if has Insufficient authentication showing alert message
    if (!bValid && !forbuttonVerification) {
      handleMessageCF(
        InSufficientAuthorizationMessageId,
        setAlertMessage,
        t("pickup.verifyErrorMessage")
      );
    }
    return bValid;
  };

  useEffect(() => {
    if (
      customerError ||
      dispositionError ||
      customerInfoError ||
      trackingReqError ||
      boxTypeError ||
      locationError
    ) {
      setIsError(true);
      setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerError,
    dispositionError,
    customerInfoError,
    trackingReqError,
    boxTypeError,
    locationError
  ]);
  useEffect(() => {
    if (customers.length > 0 && serviceLocations.length > 0) {
      // setting state of the service loctions
      setServiceLocation(serviceLocations);
      setScheduleServiceLocation(serviceLocations);
      setReviewApproveServiceLocation(serviceLocations);
      //setting Initial value for service location
      !id && formik.setFieldValue("serviceLocation", serviceLocations[0].value);
      !id &&
        formik.setFieldValue(
          "scheduleServiceLocation",
          serviceLocations[0].value
        );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceLocations]);

  useEffect(() => {
    //checking the branch is changed, if so navigate to dashboard
    if (branchPrevious.current !== selectedBranch) {
      // selectedBranch !== undefined &&
      branchPrevious.current !== undefined && navigate("/");
    }
    branchPrevious.current = selectedBranch;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBranch]);

  useEffect(() => {
    // setting the boxtypes
    boxTypes.length > 0 && setSITADBoxTypes(boxTypes);
    boxTypes.length > 0 && setReviewApproveBoxTypes(boxTypes);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxTypes]);
  useEffect(() => {
    // setting the customer list
    customers.length > 0 && setCustomerList(customers);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);
  useEffect(() => {
    if (customerInfo?.length > 0) {
      //setting the response value of customerOnhold , interactiveScanOnsite status
      formik.setFieldValue(
        "mdr_on_hold_status",
        customerInfo[0]?.mdr_on_hold_status
      );
      formik.setFieldValue(
        "scan_on_site_flag",
        customerInfo[0]?.scan_on_site_flag
      );
      formik.setFieldValue(
        "interactive_site_scan_flag",
        customerInfo[0]?.interactive_site_scan_flag
      );

      // filtering the tracking requirements for the pickup page using status of the customers
      let val = [];
      trackingRequirements?.filter((i) => {
        if (
          parseInt(i.value) === TrackingRequirementIds.PickupBulk ||
          parseInt(i.value) === TrackingRequirementIds.PickupItemized
        ) {
          val.push(i);
        } else if (
          parseInt(i.value) === TrackingRequirementIds.PickupScanonSite &&
          customerInfo[0]?.scan_on_site_flag === Y_CHECKER
        ) {
          val.push(i);
        } else if (
          parseInt(i.value) ===
            TrackingRequirementIds.PickupInteractiveSiteScan &&
          customerInfo[0]?.scan_on_site_flag === Y_CHECKER &&
          customerInfo[0]?.interactive_site_scan_flag === Y_CHECKER
        ) {
          val.push(i);
        }
        return val;
      });

      setTrackingRequirement(val);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo]);
  useEffect(() => {
    // enabling the status section of authorized section
    window.scrollTo(0, 0);
    id && setStatusAuthorized(true);

    if (!id) {
      formik.resetForm();
      handleNew();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const loadingDetails = async () => {
    await loadDestructionRequestDetail(id);
  };

  useEffect(() => {
    // hadling , if pickup is navigate from delivery seacrh screen to show the datas
    if (
      id &&
      selectedBranch &&
      !authSuccess &&
      customers.length > 0 &&
      boxTypes.length > 0
    ) {
      id ? setEnableCustomerAuth(false) : setEnableCustomerAuth(true);
      //loading the details according to the id in the param
      loadCustomerInfo();
      loadingDetails();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedBranch, iscutomerLoading, isLoadingboxtypes, localeByBranch]);

  useEffect(() => {
    if (customerInfo.length > 0) {
      if (
        authSuccess ||
        (formik.values.selectedCustomer != null && formik.values.personnelId)
      ) {
        customerInfo[0]?.mdr_on_hold_status === Y_CHECKER &&
          handleMessageCF(
            CustomerOnHoldMessageId,
            setOnHoldAlert,
            t("pickup.onHoldErrorMessage")
          );
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.selectedCustomer,
    formik.values.personnelId,
    authSuccess,
    customerInfo
  ]);
  const settingSITADValues = async (data) => {
    formik.setFieldValue(
      "authorizedPersonnelName",
      data[0]?.authorized_personnel_name
    );
    formik.setFieldValue(
      "authorizedDatetimeLTZ",
      await formatDateByLocale(data[0]?.authorized_datetime_ltz, localeByBranch)
    );
    formik.setFieldValue("personnel", data[0]?.requested_personnel_name);
    formik.setFieldValue(
      "preferredPickupDate",
      dayjs(data[0]?.preferred_service_date)
    );
    formik.setFieldValue("notifyViaEmail", data[0]?.requestor_email_addr);
    formik.setFieldValue(
      "trackingRequirements",
      data[0]?.destruct_request_type_id
    );
    formik.setFieldValue(
      "trackingNumber",
      data[0]?.tracking_number ? data[0]?.tracking_number : ""
    );
    formik.setFieldValue(
      "preTrackingNumber",
      data[0]?.tracking_number ? data[0]?.tracking_number : ""
    );
    formik.setFieldValue(
      "serviceLocation",
      data[0].preferred_service_location.trim()
    );
    formik.setFieldValue(
      "scheduleServiceLocation",
      data[0].preferred_service_location.trim()
    );
    formik.setFieldValue(
      "sitadModifiedDate",
      await formatDateByLocale(
        data[0]?.requested_datetime_ltz,
        localeByBranch,
        false
      )
    );
    formik.setFieldValue("loggedEmployeeName", data[0]?.logged_employee_name);
    formik.setFieldValue("requestedQuantity", data[0]?.approx_bulk_quantity);
  };
  const settingScheduleValues = async (data) => {
    //showing Modified date and personnel detials
    setShowScheduleModified(true);
    //otherRequestId
    formik.setFieldValue("otherRequestId", data[0]?.service_request_id);
    formik.setFieldValue(
      "schedulePreferredPickupDate",
      dayjs(data[0]?.service_date)
    );

    formik.setFieldValue(
      "scheduleServiceModifiedDate",
      await formatDateByLocale(
        data[0]?.assignment_datetime_ltz,
        localeByBranch,
        false
      )
    );
    formik.setFieldValue(
      "assignmentEmployeeName",
      data[0]?.assignment_employee_name
    );
    formik.setFieldValue(
      "destruct_req_timestamp",
      data[0]?.destruct_req_timestamp
    );
    formik.setFieldValue(
      "scheduleServiceLocation",
      data[0].service_location.trim()
    );
    formik.setFieldValue(
      "cfs_created_datetime_ltz",
      await formatDateByLocale(
        data[0]?.cfs_created_datetime_ltz,
        localeByBranch,
        false
      )
    );
    formik.setFieldValue("cfs_data_row_count", data[0]?.cfs_data_row_count);
  };
  //setting review approve panel values
  const settingReviewApproveValues = async (data) => {
    setVisibiltyReviewPanel(true);
    formik.setFieldValue(
      "reviewApproveTrackingRequirements",
      data[0]?.destruct_request_type_id
    );
    formik.setFieldValue(
      "reviewApproveServiceLocation",
      data[0].service_location.trim()
    );
    parseInt(data[0]?.destruct_request_status_id) !== RequestStatusIds.Completed
      ? formik.setFieldValue(
          "reviewDate",
          data[0]?.reviewed_datetime_ltz
            ? dayjs(data[0]?.reviewed_datetime_ltz)
            : dayjs()
        )
      : formik.setFieldValue(
          "reviewDate",
          data[0]?.reviewed_datetime_ltz
            ? dayjs(data[0]?.reviewed_datetime_ltz)
            : null
        );
    formik.setFieldValue("pickedUpQuantity", data[0]?.actual_bulk_quantity);
    formik.setFieldValue("requestedQuantity", data[0]?.approx_bulk_quantity);
    formik.setFieldValue(
      "reviewedModifiedDate",
      data[0]?.reviewed_datetime_ltz
        ? await formatDateByLocale(
            data[0]?.reviewed_datetime_ltz,
            localeByBranch,
            false
          )
        : ""
    );
    formik.setFieldValue(
      "reviewedEmployeeName",
      data[0]?.reviewed_by_employee_name
    );
    data[0]?.bulk_weight &&
      formik.setFieldValue("weight", data[0]?.bulk_weight);
    data[0]?.unit_of_measure_id &&
      formik.setFieldValue(
        "selectedUnitOfMeasure",
        data[0]?.unit_of_measure_id
      );
  };
  //setting completed,pickup,certified dates
  const settingDates = async (data) => {
    formik.setFieldValue(
      "pickupDate",
      data[0]?.service_date
        ? await formatDateByLocale(data[0]?.service_date, localeByBranch)
        : null
    );
    formik.setFieldValue(
      "completedDate",
      data[0]?.completed_datetime_ltz
        ? await formatDateByLocale(
            data[0]?.completed_datetime_ltz,
            localeByBranch
          )
        : null
    );
    formik.setFieldValue(
      "certifiedDate",
      data[0]?.certified_datetime_ltz
        ? await formatDateByLocale(
            data[0]?.certified_datetime_ltz,
            localeByBranch
          )
        : null
    );
  };
  const loadDestructionRequestDetail = async (destructionId) => {
    //CF for destruction request details
    setLoadingDestructionDetails(true);
    await triggerDestructRequestDetail({
      destructRequestId: destructionId,
      systemId: SecureSyncSysytemId,
      mainDistrictId: selectedBranch,
      language_locale_id: languageLocaleId
    })
      .unwrap()
      .then(async (data) => {
        const error = data && data[0]?.error;
        //checking for the error
        if (error) {
          setIsError(true);
          setErrorMessage(findErrorMessage(data[0]?.error));
        } else {
          setResultData(data[0]);
          formik.setFieldValue("destructionId", data[0]?.destruct_request_id);
          formik.setFieldValue(
            "statusId",
            parseInt(data[0]?.destruct_request_status_id)
          );
          await formik.setFieldValue(
            "status",
            data[0]?.destruct_request_status_descr
          );
          formik.setFieldValue(
            "destructRequestId",
            data[0]?.destruct_request_id
          );
          const statusId = parseInt(data[0]?.destruct_request_status_id);
          // According to the status , enabling and disabling the form fields
          switch (parseInt(data[0]?.destruct_request_status_id)) {
            case RequestStatusIds.Cancelled: //cancelled
              //handling the state of save whether its is schedule save or sitad save
              if (data[0]?.service_request_id) {
                setSaveSitad(true);
              } else {
                setSaveSitad(false);
              }
              //disabled both sitad and schedule form
              setdiableSITAD(true);
              setDiableSchedule(true);
              //handle field error message for schedule
              setScheduleErrorMsg(false);
              //setting value in the form fields for sitad and schedule
              settingSITADValues(data);
              settingScheduleValues(data);
              //if its cancelled or scheduled not showing the error field
              formik.setFieldTouched("schedulePreferredPickupDate", false);
              break;
            case RequestStatusIds.Scheduled: //scheduled
              //disabled both sitad and schedule form
              setdiableSITAD(true);
              setDiableSchedule(true);
              //handlle error message on fields
              setScheduleErrorMsg(false);
              //setting value in the form
              settingSITADValues(data);
              settingScheduleValues(data);
              //handling to show the schedule
              setSaveSitad(true);
              //if its cancelled or scheduled not showing the error field
              formik.setFieldTouched("schedulePreferredPickupDate", false);
              break;
            case RequestStatusIds.Authorized: //authorized
              //enable sitad
              setdiableSITAD(true);
              //unvisible error fields
              setDiableSchedule(false);
              //setting value in the form
              settingSITADValues(data);
              //handling the state of save
              setSaveSitad(true);

              break;
            case RequestStatusIds.PendingReview:
            case RequestStatusIds.InProcess:
            case RequestStatusIds.PendingConfirmation:
            case RequestStatusIds.Completed:
              //disabled both sitad and schedule form
              setdiableSITAD(true);
              setDiableSchedule(true);
              //handle field error message for schedule
              setScheduleErrorMsg(false);
              //setting value in the form
              settingSITADValues(data);
              settingScheduleValues(data);
              settingReviewApproveValues(data);
              //setting dates
              settingDates(data);
              //handling to show the schedule
              setSaveSitad(true);
              formik.setFieldTouched("schedulePreferredPickupDate", false);
              break;
            default:
              break;
          }
          parseInt(data[0]?.destruct_request_type_id) ===
          TrackingRequirementIds.PickupInteractiveSiteScan
            ? setVisibiltyUploadFile(true)
            : setVisibiltyUploadFile(false);
          //enabling the weight panel
          SetEnablwWeightPanel(
            statusId === RequestStatusIds.Scheduled ||
              statusId === RequestStatusIds.PendingConfirmation ||
              (statusId === RequestStatusIds.PendingReview &&
                verifyEmployeePermission(ProgramId.ReadytoBill) &&
                statusId !== RequestStatusIds.Completed &&
                statusId !== RequestStatusIds.Cancelled &&
                authSuccess) ||
              statusId === RequestStatusIds.InProcess ||
              statusId === RequestStatusIds.Rejected ||
              statusId === RequestStatusIds.Cancelled ||
              statusId === RequestStatusIds.Completed
          );
          //enable the review section(boxtypes,reviewcomments)
          statusId === RequestStatusIds.PendingReview &&
          statusId !== RequestStatusIds.Completed &&
          statusId !== RequestStatusIds.Cancelled &&
          (data[0].reviewed_by_employee_name === null ||
            data[0].reviewed_by_employee_name.trim() === "") &&
          verifyEmployeePermission(ProgramId.ReviewSection)
            ? setReviewSection(false)
            : setReviewSection(true);
          //disabling the review section
          data[0].reviewed_by_employee_name === null ||
          data[0].reviewed_by_employee_name.trim() === ""
            ? setShowReviewApprovedModified(false)
            : setShowReviewApprovedModified(true);

          //handle error messages on fields (disabling)
          setSitadErrorMsg(false);
          //setting the state to shoe the schedule tracking requirements
          const customer = customers?.filter(
            (i) => i.value.toString() === data[0]?.customer_id.toString()
          );
          customer.length > 0 &&
            formik.setFieldValue("selectedCustomer", customer[0]);
          formik.setFieldValue(
            "scheduleTrackingRequirements",
            data[0]?.destruct_request_type_id
          );
          // setting the cancelled details
          formik.setFieldValue(
            "cancelledBy",
            data[0]?.cancelled_personnel_name
          );
          data[0]?.cancelled_datetime_ltz &&
            formik.setFieldValue(
              "cancelledDate",
              await formatDateByLocale(
                data[0]?.cancelled_datetime_ltz,
                localeByBranch
              )
            );
          formik.setFieldValue(
            "cancelledReason",
            data[0]?.destruct_cancel_reason_descr
          );
          formik.setFieldValue(
            "cancelledId",
            data[0]?.destruct_cancel_reasonId
          );
          // loading box types
          await loadBoxtypeCounts(
            destructionId,
            parseInt(data[0]?.destruct_request_status_id),
            data[0]?.reviewed_datetime_ltz ? data[0]?.reviewed_datetime_ltz : ""
          );
          //loading desposition services field
          await loadSpecialHandling(destructionId);
          //loading comments
          await loadComments(
            destructionId,
            parseInt(data[0]?.destruct_request_status_id)
          );
          // setting personnelId
          setPersonnelId(data[0]?.requested_personnel_id);
          //OtherRequestStatus Id CF
          handleRequestStatusCF(data[0]?.service_request_id);
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      })
      .finally(() => {
        setLoadingDestructionDetails(false);
      });
  };

  useEffect(() => {
    // verification on every status changes and personnel changes
    if ((authSuccess || id) && formik.values.selectedCustomer && personnelId) {
      VerifyAuthentication(formik.values.statusId, personnelId, true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.statusId, personnelId, formik.values.selectedCustomer]);

  const loadSpecialHandling = async (destructionId) => {
    //desposition service
    await triggerSpecialHandling({
      main_district_id: selectedBranch,
      destruct_request_id: destructionId,
      language_locale_id: languageLocaleId
    })
      .unwrap()
      .then((data) => {
        const error = data && data[0]?.error;
        //erroe checking
        if (error) {
          setIsError(true);
          setErrorMessage(findErrorMessage(data[0]?.error));
        }
        if (data) {
          // setting dispositionServices values
          const resultArray = data?.map((item) =>
            item.Handling_Instruction.trim()
          );
          formik.setFieldValue(
            "dispositionServices",
            resultArray.length > 0 ? [resultArray.join(", ")] : []
          );
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };
  const handleCommentText = (data, statusId, commentTypeId) => {
    // According to statusId and commentTypeId ,
    // setting commentstext for pickupInstruction in sitad and schedule and disposition comments
    const commentText = data
      ?.filter((i) => {
        return (
          i.destruct_request_status_id === statusId.toString() &&
          i.destruct_request_comment_type_id === commentTypeId
        );
      })
      ?.map((i) => i.comment_text);

    return commentText?.length > 0 ? commentText[0] : "";
  };
  const loadComments = async (destructionId, statusId) => {
    //comments
    await triggerRequestComment({
      main_district_id: selectedBranch,
      destruct_request_id: destructionId,
      language_locale_id: languageLocaleId
    })
      .unwrap()
      .then((data) => {
        const error = data && data[0]?.error;
        //error handling
        if (error) {
          setIsError(true);
          setErrorMessage(findErrorMessage(data[0]?.error));
        } else {
          // According to the status , handing the commentText
          switch (statusId) {
            case RequestStatusIds.Authorized: //authorized
              formik.setFieldValue(
                "schedulePickupInstructions",
                handleCommentText(
                  data,
                  RequestStatusIds.Authorized,
                  schedulePickupInstructionsCommentTypeId
                )
              );
              break;
            case RequestStatusIds.Scheduled: //scheduled
              formik.setFieldValue(
                "schedulePickupInstructions",
                handleCommentText(
                  data,
                  RequestStatusIds.Scheduled,
                  schedulePickupInstructionsCommentTypeId
                )
              );
              break;
            case RequestStatusIds.InProcess:
            case RequestStatusIds.Completed:
            case RequestStatusIds.PendingReview:
            case RequestStatusIds.PendingConfirmation:
              formik.setFieldValue(
                "schedulePickupInstructions",
                handleCommentText(
                  data,
                  RequestStatusIds.Scheduled,
                  schedulePickupInstructionsCommentTypeId
                )
              );
              formik.setFieldValue(
                "reviewComments",
                handleCommentText(
                  data,
                  RequestStatusIds.PendingReview,
                  ReviewCommentTypeId
                )
              );
              formik.setFieldValue(
                "preReviewComments",
                handleCommentText(
                  data,
                  RequestStatusIds.PendingReview,
                  ReviewCommentTypeId
                )
              );
              break;
            default:
              formik.setFieldValue(
                "schedulePickupInstructions",
                handleCommentText(
                  data,
                  RequestStatusIds.Authorized,
                  schedulePickupInstructionsCommentTypeId
                )
              );
              break;
          }
          formik.setFieldValue(
            "dispositionServiceComments",
            handleCommentText(
              data,
              RequestStatusIds.Authorized,
              DispositionServiceCommentTypeId
            )
          );
          formik.setFieldValue(
            "pickupInstructions",
            handleCommentText(
              data,
              RequestStatusIds.Authorized,
              schedulePickupInstructionsCommentTypeId
            )
          );
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };
  //handling the final value - Adding the box type count
  const handleFinalValue = (boxType) => {
    const sum = boxType.reduce((acc, i) => acc + parseInt(i.value, 10), 0);
    formik.setFieldValue("finalQuantity", sum);
  };

  const handleBoxtypeCount = (data, destructRequestStatusId) => {
    // According to destructRequestStatusId , setting  boxtypes for schedule and sitad
    const dataSet = data?.filter(
      (i) => i.destruct_request_status_id === destructRequestStatusId.toString()
    );
    const newArray = boxTypes?.map((originalObj) => {
      const updateObj = dataSet?.find(
        (updateObj) => updateObj.destruct_request_box_type_id === originalObj.id
      );
      if (updateObj) {
        return { ...originalObj, value: updateObj.quantity };
      }
      return originalObj;
    });
    return newArray;
  };
  const loadBoxtypeCounts = (destructionId, statusId, reviewedModifiedDate) => {
    triggerBoxTypesCount({
      main_district_id: selectedBranch,
      destruct_request_id: destructionId,
      language_locale_id: languageLocaleId
    })
      .unwrap()
      .then((data) => {
        const error = data && data[0]?.error;
        if (error) {
          setIsError(true);
          setErrorMessage(findErrorMessage(data[0]?.error));
        } else {
          let sitabBoxtypeCount = handleBoxtypeCount(
            data,
            RequestStatusIds.Authorized
          );
          const scheduleBoxtypeCount = handleBoxtypeCount(
            data,
            RequestStatusIds.Scheduled
          );
          const ReviewApproveBoxtypeCount = handleBoxtypeCount(
            data,
            RequestStatusIds.PendingReview
          );
          formik.setFieldValue(
            "reviewApproveBoxtypeCount",
            handleBoxTypeCount(ReviewApproveBoxtypeCount) > 0
              ? ReviewApproveBoxtypeCount
              : scheduleBoxtypeCount
          );
          //According to status , the box count value is setting
          switch (statusId) {
            case RequestStatusIds.Authorized: //authorized
              setScheduleBoxTypes(
                sitabBoxtypeCount.length > 0 ? sitabBoxtypeCount : boxTypes
              );
              break;
            case RequestStatusIds.Cancelled:
            case RequestStatusIds.Scheduled: //scheduled
              setScheduleBoxTypes(
                scheduleBoxtypeCount.length > 0
                  ? scheduleBoxtypeCount
                  : boxTypes
              );
              break;
            case RequestStatusIds.InProcess:
            case RequestStatusIds.Completed:
            case RequestStatusIds.PendingReview:
            case RequestStatusIds.PendingConfirmation:
              setScheduleBoxTypes(
                scheduleBoxtypeCount.length > 0
                  ? scheduleBoxtypeCount
                  : boxTypes
              );
              reviewedModifiedDate !== ""
                ? setReviewApproveBoxTypes(
                    ReviewApproveBoxtypeCount.length > 0
                      ? ReviewApproveBoxtypeCount
                      : boxTypes
                  )
                : setReviewApproveBoxTypes(
                    scheduleBoxtypeCount.length > 0
                      ? scheduleBoxtypeCount
                      : boxTypes
                  );
              reviewedModifiedDate !== ""
                ? handleFinalValue(
                    ReviewApproveBoxtypeCount.length > 0
                      ? ReviewApproveBoxtypeCount
                      : boxTypes
                  )
                : handleFinalValue(
                    scheduleBoxtypeCount.length > 0
                      ? scheduleBoxtypeCount
                      : boxTypes
                  );
              break;
            default:
              break;
          }
          setSITADBoxTypes(
            sitabBoxtypeCount.length > 0 ? sitabBoxtypeCount : boxTypes
          );
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };

  const handleAuthSubmit = async (values) => {
    setPageLoading((prevState) => true);
    //closing and setting message to empty for the Insufficient auth message and onhold if the personnel is changed
    setAlertMessage(false);
    formik.values.verifyErrorMessage &&
      formik.setFieldValue("verifyErrorMessage", "");

    // setting the personnel or bypass Id according to the authorizationType
    await setPersonnelId(
      values.authorizationType === t("pickup.Bypass")
        ? BypassId
        : values.personnel
    );
    const personnelId =
      values.authorizationType === t("pickup.Bypass")
        ? BypassId
        : values.personnel;

    // Verifing the customer is having the perimission before showing the sitad or schedule section
    const data = await VerifyAuthentication(RequestStatusIds.None, personnelId);
    if (data) {
      // once personnel is authenticated , the peer value of the customer has been replaced with customer list
      let customerList = values.customer_list
        ?.filter((i) => i.district_id.toString() === selectedBranch.toString())
        ?.map(({ number, name, customer_Id }) => ({
          label: `${number} - ${name}`,
          value: customer_Id,
          number: number
        }));
      // setting if autheticate the customer value  is peer of the personnel value
      values.authorizationType !== t("pickup.Bypass")
        ? setCustomerList(customerList)
        : setCustomerList(customers);
      //setting the state of the email when the authenticate is clicked
      values.authorizationType !== t("pickup.Bypass") &&
        formik.setFieldValue("notifyViaEmail", values.business_email_addr);
      values.authorizationType !== t("pickup.Bypass") &&
        formik.setFieldValue("preNotifyViaEmail", values.business_email_addr);
      //setting personnel value
      values.authorizationType === t("pickup.Bypass")
        ? formik.setFieldValue("personnel", t("pickup.imPersonnel"))
        : formik.setFieldValue("personnel", values.personnelValue?.label);
      //setting bypass reason
      formik.setFieldValue("auth_bypass_reason", values.bypassReason);
      setAuthSuccess(true);
      formik.setFieldValue("authVerified", true);
      // setting Inital trackingNUmber
      if (!id) {
        formik.setFieldValue(
          "trackingRequirements",
          trackingRequirements[0].value
        );
      }
    }
    setEnableCustomerAuth(false);
    setPageLoading((prevState) => false);
  };
  // handing the box type on change for sitad
  const handleSITADBoxTypes = (val, index) => {
    const newValues = [...SITADBoxTypes];
    newValues[index] = { ...newValues[index], value: val };
    setSITADBoxTypes(newValues);
  };
  // handing the box type on change for schedule service
  const handleScheduleBoxTypes = (val, index) => {
    const newValues = [...scheduleBoxTypes];
    newValues[index] = { ...newValues[index], value: val };
    setScheduleBoxTypes(newValues);
  };
  // handing the box type on change for review / approve panel
  const handleReviewApproveBoxTypes = (val, index) => {
    const newValues = [...reviewApproveBoxTypes];
    newValues[index] = { ...newValues[index], value: val };
    setReviewApproveBoxTypes(newValues);
    handleFinalValue(newValues);
  };
  // calculating the approx box count
  const handleBoxTypeCount = (value) => {
    const boxType = value.reduce((sum, item) => sum + parseInt(item.value), 0);
    return boxType;
  };
  //handle save sitad
  const handleSaveSitad = async () => {
    //checking if there is an error
    const sitadFieldsFilled =
      !formik.errors?.trackingRequirements &&
      !formik.errors?.preferredPickupDate &&
      !formik.errors?.serviceLocation &&
      !formik.errors?.pickupInstructions &&
      !formik.errors?.notifyViaEmail &&
      !formik.errors?.dispositionServices &&
      !formik.errors.dispositionServiceComments &&
      !formik.errors.trackingNumber &&
      !formik.errors.boxTypesVal;
    setSitadErrorMsg(true);
    //checking if the box count > 0
    const SITADboxCount = handleBoxTypeCount(SITADBoxTypes);
    if (SITADboxCount <= 0) {
      sitadFieldsFilled && setBoxtypeWraning(true);
    }
    // verifying if the customer having the permission to create a sitad deliver request
    let verify = await VerifyAuthentication(
      RequestStatusIds.Authorized,
      personnelId
    );
    //verifying the onhold status if so showing the error message
    let customerOnHold = handleCustomerOnHold();
    //  checking all fields are filled, verfied and not on cutomer on hold
    if (SITADboxCount > 0 && sitadFieldsFilled && verify && customerOnHold) {
      setLoadingDestructionDetails(true);
      setBoxtypeWraning(false);
      // restting the errors and error field message state to false
      setSitadErrorMsg(false);
      resetErrorFieldSITAD();

      //sp calling for save
      const values = formik.values;
      //destruct_request_i_sp
      let destructRequest = {};
      destructRequest.main_district_id = selectedBranch;
      destructRequest.language_locale_id = languageLocaleId;
      destructRequest.customer_id = values.selectedCustomer?.value;
      destructRequest.requested_personnel_id = personnelId;
      destructRequest.approx_bulk_count = SITADboxCount;
      destructRequest.pickup_instruction = "";
      destructRequest.volser_list = "";
      destructRequest.im_comment = "";
      destructRequest.business_email_addr = values.notifyViaEmail;
      destructRequest.logged_in_employee_id = auth?.user?.employee_id;
      destructRequest.destruct_request_id = 0;
      destructRequest.destruct_request_type_id = values.trackingRequirements;
      destructRequest.preferred_service_date = dayjs(values.preferredPickupDate)
        .startOf(t("pickup.day"))
        .format(t("pickup.dateFormat"));
      destructRequest.preferred_service_location_customer_id =
        serviceLocation.filter(
          (i) => i.value === formik.values.serviceLocation
        )[0].id;
      destructRequest.tracking_number = values.trackingNumber;
      destructRequest.auth_bypass_reason = values.auth_bypass_reason;
      triggerSaveSitadRequest(destructRequest)
        .unwrap()
        .then(async (data) => {
          const error = data && data[0]?.error;
          //handle error
          if (error) {
            setIsError(true);
            setErrorMessage(findErrorMessage(data[0]?.error));
          } else {
            const destructionId = data[0]?.destruct_request_id;
            navigate(
              `${REQUEST_MODULE_BASE_PATH}/${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP}/${data[0]?.destruct_request_id}`
            );
            formik.setFieldValue(
              "destructRequestId",
              data[0]?.destruct_request_id
            );
            //setting the datas for schedule service
            formik.setFieldValue(
              "scheduleTrackingRequirements",
              formik.values.trackingRequirements
            );
            setScheduleBoxTypes(SITADBoxTypes);
            //Enable the status
            setStatusAuthorized(true);
            //setting status as authorized
            formik.setFieldValue("status", t("pickup.authorized"));
            setSaveSitad(true);
            //disable the fields of sitad
            setdiableSITAD(true);
            // setting the status to authorized once they saved successfully
            formik.setFieldValue("statusId", RequestStatusIds.Authorized);
            const statusId = RequestStatusIds.Authorized;
            // deleting the boxtypes and inserting the new values
            await handleDeleteBoxTypeCF(statusId, SITADBoxTypes, destructionId);
            //nserting
            dispositionServices
              .filter((item) =>
                formik.values.dispositionServices.includes(item.label)
              )
              .map((i) => {
                let specialHandling = {};
                specialHandling.destruct_request_id = destructionId;
                specialHandling.destruct_request_handling_type_id = i.value;
                specialHandling.main_district_id = selectedBranch;
                specialHandling.language_locale_id = languageLocaleId;
                //after inserting the boxtype , inserting the special handling values
                triggerInsertRequestSpecialHandling(specialHandling)
                  .unwrap()
                  .then((data) => {
                    const error = data && data[0]?.error;
                    //handle error
                    if (error) {
                      setIsError(true);
                      setErrorMessage(findErrorMessage(data[0]?.error));
                    }
                  })
                  .catch((e) => {
                    setIsError(true);
                    setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
                  });
                return specialHandling;
              });
            // deleting and inserting the commentsTexts for PickupInstruction
            await handleComment(
              statusId,
              CommentType.Pickup,
              values,
              destructionId
            );
            // deleting and inserting the commentsTexts for despoistion service comments
            await handleComment(
              statusId,
              CommentType.SpecialHandling,
              values,
              destructionId
            );
          }
        })
        .catch((e) => {
          setIsError(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        })
        .finally(() => {
          setLoadingDestructionDetails(false);
        });
    }
  };
  const handleComment = (statusId, item, values, destructionId) => {
    let commentTypeD = {};
    commentTypeD.main_district_id = selectedBranch;
    commentTypeD.destruct_request_id = destructionId;
    commentTypeD.destruct_request_status_id = statusId;
    commentTypeD.destruct_request_comment_type_id = item;
    commentTypeD.language_locale_id = languageLocaleId;
    //destruct_request_comment_d_sp
    triggerDeleteRequestComment(commentTypeD)
      .unwrap()
      .then((data) => {
        const error = data && data[0]?.error;
        //handle error
        if (error) {
          setIsError(true);
          setErrorMessage(findErrorMessage(data[0]?.error));
        } else {
          //destruct_request_comment_i_sp
          let commentTypeI = {};
          commentTypeI.main_district_id = selectedBranch;
          commentTypeI.destruct_request_id = destructionId;
          commentTypeI.destruct_request_status_id = statusId;
          commentTypeI.destruct_request_comment_type_id = item;
          commentTypeI.language_locale_id = languageLocaleId;
          switch (item) {
            case CommentType.SpecialHandling: //DespositionService Comments
              commentTypeI.comment_text = values.dispositionServiceComments;
              break;
            case CommentType.Pickup: //schedule pickup instruction comments
              commentTypeI.comment_text = values.schedulePickupInstructions;
              break;
            case CommentType.General: //schedule pickup instruction comments
              commentTypeI.comment_text = values.reviewComments;
              break;
            default:
              //pickupInstruction comments
              commentTypeI.comment_text = values.pickupInstructions;
              break;
          }
          //inserting the commentText
          triggerInsertRequestComment(commentTypeI)
            .unwrap()
            .then((data) => {
              const error = data && data[0]?.error;
              if (error) {
                setIsError(true);
                setErrorMessage(findErrorMessage(data[0]?.error));
              }
            })
            .catch((e) => {
              setIsError(true);
              setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
            });
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };
  const handleBoxTypeCF = (statusId, item, destructionId) => {
    //destruct_request_box_type_count_i_sp
    let requestBoxTypeCountI = {};
    requestBoxTypeCountI.main_district_id = selectedBranch;
    requestBoxTypeCountI.destruct_request_id = destructionId;
    requestBoxTypeCountI.destruct_request_status_id = statusId;
    requestBoxTypeCountI.destruct_request_box_type_id = item.id;
    requestBoxTypeCountI.quantity = item.value;
    requestBoxTypeCountI.language_locale_id = languageLocaleId;
    //inserting the boxtypes count
    triggerInsertRequestBoxTypeCount(requestBoxTypeCountI)
      .unwrap()
      .then((data) => {
        const error = data && data[0]?.error;
        //handle error
        if (error) {
          setIsError(true);
          setErrorMessage(findErrorMessage(data[0]?.error));
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };
  const handleDeleteBoxTypeCF = (statusId, boxTypes, destructionId) => {
    // destruct_request_box_type_count_d_sp
    let requestBoxTypeCountD = {};
    requestBoxTypeCountD.main_district_id = selectedBranch;
    requestBoxTypeCountD.destruct_request_id = destructionId;
    requestBoxTypeCountD.destruct_request_status_id = statusId;
    requestBoxTypeCountD.language_locale_id = languageLocaleId;
    //deleting the boxType count
    triggerDeleteRequestBoxTypeCount(requestBoxTypeCountD)
      .unwrap()
      .then((data) => {
        const error = data && data[0]?.error;
        if (error) {
          setIsError(true);
          setErrorMessage(findErrorMessage(data[0]?.error));
        } else {
          //destruct_request_box_type_count_i_sp
          for (const item of boxTypes) {
            if (parseInt(item.value) > 0) {
              statusId === RequestStatusIds.Scheduled
                ? handleBoxTypeCF(statusId, item, destructionId) // scheduled
                : handleBoxTypeCF(statusId, item, destructionId);
            }
          }
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };
  const handleCustomerOnHold = () => {
    return formik.values.mdr_on_hold_status === N_CHECKER
      ? true
      : handleMessageCF(
          CustomerOnHoldMessageId,
          setOnHoldAlert,
          t("pickup.onHoldErrorMessage")
        );
  };
  const updateRequestObj = (
    boxCount = 0,
    trackingRequirements,
    requestStatusIds,
    preferredPickupDate,
    serLocation,
    unit_of_measure_id,
    bulk_weight
  ) => {
    let values = formik.values;
    //destruct_request_u2_sp
    let destructRequestU2 = {};
    destructRequestU2.main_district_id = selectedBranch;
    destructRequestU2.destruct_request_id = values.destructionId;
    destructRequestU2.destruct_req_timestamp = values.destruct_req_timestamp;
    destructRequestU2.im_comment = "";
    destructRequestU2.service_result_id = "";
    destructRequestU2.approx_bulk_quantity = boxCount;
    destructRequestU2.actual_quantity = null;
    destructRequestU2.bulk_weight = bulk_weight ? bulk_weight : 0;
    destructRequestU2.unit_of_measure_id = unit_of_measure_id
      ? unit_of_measure_id
      : "";
    destructRequestU2.volser_list = "";
    destructRequestU2.tracking_number = values.trackingNumber;
    destructRequestU2.system_id = SecureSyncSysytemId;
    destructRequestU2.destruct_request_type_id = trackingRequirements;
    destructRequestU2.to_destruct_request_status_id = requestStatusIds;
    destructRequestU2.language_locale_id = languageLocaleId;
    destructRequestU2.request_id = formik.values.service_request_id
      ? formik.values.service_request_id
      : 0;
    destructRequestU2.service_date = preferredPickupDate
      ? dayjs(preferredPickupDate)
          .startOf(t("pickup.day"))
          .format(t("pickup.dateFormat"))
      : null;
    destructRequestU2.day_of_week_code = dayjs().format(
      t("pickup.dayWeekCode")
    );
    destructRequestU2.customer_id_service_location = serviceLocation.filter(
      (i) => i.value === serLocation
    )[0]?.id;
    destructRequestU2.logged_in_employee_id = auth?.user?.employee_id;
    return destructRequestU2;
  };
  const handleUploadFileCF = async (request_id) => {
    await triggerUploadFile({
      main_district_id: "537",
      function: "sb_fs_file_section_interactive_site_item_i_sp",
      request_id: request_id,
      iss_volser_list: volserList,
      media_type_id: UPLOAD_FILE_MEDIA_TYPE_ID
    })
      .then((data) => {
        const error = data && data[0]?.error;
        //handle error
        if (error) {
          setIsError(true);
          setErrorMessage(findErrorMessage(data[0]?.error));
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };
  const handleSaveSchedule = async () => {
    const status = formik.values.statusId;

    //on clicking showing all error field which is not filled
    (status === RequestStatusIds.Authorized ||
      status === RequestStatusIds.None) &&
      setScheduleErrorMsg(true);
    //checking the interactive site scan is true and item count is 0
    const uploadFile =
      (status === RequestStatusIds.Authorized ||
        status === RequestStatusIds.None) &&
      visibiltyUploadFile
        ? Boolean(itemCount === 0)
        : false;
    formik.setFieldValue("uploadFileRequired", uploadFile);

    //save for schedule
    const scheduleFieldsFilled =
      (status === RequestStatusIds.Authorized ||
      status === RequestStatusIds.None
        ? !formik.errors?.schedulePreferredPickupDate
        : true) &&
      !formik.errors?.scheduleServiceLocation &&
      !formik.errors?.schedulePickupInstructions &&
      !formik.errors?.boxTypesVal &&
      !formik.errors.trackingNumber &&
      !uploadFile;

    //checking the boxcount is greater than 0
    const scheduleboxCount = handleBoxTypeCount(scheduleBoxTypes);
    if (scheduleboxCount <= 0) {
      scheduleFieldsFilled && setBoxtypeWraningSchedule(true);
    }
    // verifying the customer has permission to save schedule
    let verify = await VerifyAuthentication(
      RequestStatusIds.Scheduled,
      personnelId
    );
    // verifying the on hold status
    let customerOnHold = handleCustomerOnHold();
    if (
      scheduleboxCount > 0 &&
      scheduleFieldsFilled &&
      verify &&
      customerOnHold
    ) {
      setLoadingDestructionDetails(true);
      setBoxtypeWraning(false);
      let values = formik.values;

      const destructRequestU2 = updateRequestObj(
        scheduleboxCount,
        values.scheduleTrackingRequirements,
        RequestStatusIds.Scheduled,
        values.schedulePreferredPickupDate,
        formik.values.scheduleServiceLocation
      );

      await triggerSaveScheduleRequest(destructRequestU2)
        .unwrap()
        .then(async (data) => {
          const error = data && data[0]?.error;
          //handle error
          if (error) {
            setIsError(true);
            setErrorMessage(findErrorMessage(data[0]?.error));
          } else {
            setIsError(false);
            setErrorMessage("");
            //making the schedule form to be disabled
            setDiableSchedule(true);
            //modified date to show
            setShowScheduleModified(true);
            //setting the sitad form errors to false
            setSitadErrorMsg(false);
            //setting the status
            formik.setFieldValue(
              "reviewApproveServiceLocation",
              serviceLocation.filter(
                (i) => i.value === formik.values.scheduleServiceLocation
              )[0]?.id
            );
            const statusId = RequestStatusIds.Scheduled; //scheduled
            // destruct_request_box_type_count_d_sp
            //delete the box types
            await handleDeleteBoxTypeCF(
              statusId,
              scheduleBoxTypes,
              formik.values.destructRequestId
            );
            //destruct_request_comment(handle comment text for pickup instruction)
            await handleComment(
              statusId,
              CommentType.Pickup,
              values,
              formik.values.destructRequestId
            );

            if (visibiltyUploadFile && volserList.length > 0) {
              handleUploadFileCF(data[0].request_id);
            }

            // reloading the datas after saving
            setTimeout(() => {
              loadDestructionRequestDetail(formik.values.destructRequestId);
            }, ONE_SECOND_IN_MILLISECONDS);
          }
        })
        .catch((e) => {
          setIsError(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        })
        .finally(() => {
          setLoadingDestructionDetails(false);
        });
    }
  };
  const handleSaveReviewPanel = async () => {
    //on clicking showing all error field which is not filled
    setReviewErrorMsg(true);
    //save for schedule
    const reviewApproveFieldsFilled =
      !formik.errors?.reviewApproveTrackingRequirements &&
      !formik.errors?.reviewDate &&
      !formik.errors?.reviewApproveServiceLocation &&
      !formik.errors?.reviewComments &&
      !formik.errors.finalQuantity &&
      !formik.errors.selectedUnitOfMeasure &&
      !formik.errors.weight;
    //checking the boxcount is greater than 0
    const reviewBoxCount = handleBoxTypeCount(reviewApproveBoxTypes);
    if (reviewBoxCount <= 0) {
      reviewApproveFieldsFilled && setBoxtypeWraningReviewApprove(true);
    }
    // verifying the customer has permission to save schedule
    let verify = await VerifyAuthentication(
      RequestStatusIds.PendingReview,
      personnelId
    );
    // verifying the on hold status
    let customerOnHold = handleCustomerOnHold();
    if (
      reviewBoxCount > 0 &&
      reviewApproveFieldsFilled &&
      verify &&
      customerOnHold
    ) {
      setLoadingDestructionDetails(true);
      setBoxtypeWraning(false);
      let values = formik.values;
      const destructRequestU2 = updateRequestObj(
        reviewBoxCount,
        values.reviewApproveTrackingRequirements,
        RequestStatusIds.PendingReview,
        values.reviewDate,
        values.reviewApproveServiceLocation,
        values.selectedUnitOfMeasure,
        values.weight,
        values.pickedUpQuantity
      );
      await triggerSaveScheduleRequest(destructRequestU2)
        .unwrap()
        .then(async (data) => {
          const error = data && data[0]?.error;
          //handle error
          if (error) {
            setIsError(true);
            setErrorMessage(findErrorMessage(data[0]?.error));
          } else {
            setIsError(false);
            setErrorMessage("");
            //making the schedule form to be disabled
            setDiableSchedule(true);
            //modified date to show
            setShowScheduleModified(true);
            //setting the sitad form errors to false
            setSitadErrorMsg(false);
            //setting the status
            formik.setFieldValue(
              "reviewApproveServiceLocation",
              serviceLocation.filter(
                (i) => i.value === formik.values.reviewApproveServiceLocation
              )[0]?.id
            );
            const statusId = RequestStatusIds.PendingReview;
            // destruct_request_box_type_count_d_sp
            //delete the box types
            await handleDeleteBoxTypeCF(
              statusId,
              reviewApproveBoxTypes,
              formik.values.destructRequestId
            );
            //destruct_request_comment(handle comment text for review comments)
            await handleComment(
              statusId,
              CommentType.General,
              values,
              formik.values.destructRequestId
            );
            // reloading the datas after saving
            setTimeout(() => {
              loadDestructionRequestDetail(formik.values.destructRequestId);
            }, ONE_SECOND_IN_MILLISECONDS);
          }
        })
        .catch((e) => {
          setIsError(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        })
        .finally(() => {
          setLoadingDestructionDetails(false);
        });
    }
  };
  const handleReadyToBill = async () => {
    const readyToBillFieldsFilled =
      !formik.errors.selectedUnitOfMeasure && !formik.errors.weight;
    // verifying the on hold status
    let customerOnHold = handleCustomerOnHold();
    if (customerOnHold && readyToBillFieldsFilled) {
      setLoadingDestructionDetails(true);
      let values = formik.values;
      const destructRequestU2 = updateRequestObj(
        values.finalQuantity,
        values.reviewApproveTrackingRequirements,
        RequestStatusIds.Completed,
        null,
        "",
        values.selectedUnitOfMeasure,
        values.weight
      );
      await triggerSaveScheduleRequest(destructRequestU2)
        .unwrap()
        .then(async (data) => {
          const error = data && data[0]?.error;
          //handle error
          if (error) {
            setIsError(true);
            setErrorMessage(findErrorMessage(data[0]?.error));
          } else {
            setIsError(false);
            setErrorMessage("");

            // reloading the datas after saving
            setTimeout(() => {
              loadDestructionRequestDetail(formik.values.destructRequestId);
            }, ONE_SECOND_IN_MILLISECONDS);
          }
        })
        .catch((e) => {
          setIsError(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        })
        .finally(() => {
          setLoadingDestructionDetails(false);
        });
    }
  };
  const handleSave = () => {
    const status = formik.values.statusId;
    //save for SITAD
    if (status === RequestStatusIds.None) {
      handleSaveSitad();
    }
    // Status checking if its schedule while saving
    if (
      status === RequestStatusIds.Authorized ||
      status === RequestStatusIds.Scheduled
    ) {
      handleSaveSchedule();
    }
    if (status === RequestStatusIds.PendingReview) {
      handleSaveReviewPanel();
    }
  };

  const resetErrorFieldSITAD = () => {
    // reset the touch , error to empty on resetting
    formik.setTouched({});
    formik.setErrors({});
    setSitadErrorMsg(false);
  };

  const resetErrorFieldSchedule = () => {
    // reset the touch on resetting
    formik.setTouched({});
  };

  const handleResetFormSITAD = () => {
    // on resetting , reset to the initial values for sitad
    const fieldsToReset = [
      "notifyViaEmail",
      "pickupInstructions",
      "dispositionServiceComments",
      "trackingNumber"
    ];
    fieldsToReset.forEach((field) => {
      formik.setFieldValue(field, "");
    });
    formik.setFieldValue("preferredPickupDate", null);
    formik.setFieldValue(
      "trackingRequirements",
      trackingRequirements[0]?.value
    );
    formik.setFieldValue(
      "notifyViaEmail",
      authSuccess && !id ? formik.values.preNotifyViaEmail : ""
    );

    formik.setFieldValue("serviceLocation", serviceLocations[0]?.value);
    formik.setFieldValue("dispositionServices", []);
    setSITADBoxTypes(boxTypes);
  };

  const handleResetFormSchedule = () => {
    // on resetting , reset to the initial values for sitad
    formik.setFieldValue("serviceLocation", serviceLocations[0].value);
    formik.setFieldValue("schedulePreferredPickupDate", null);
    formik.setFieldValue(
      "schedulePickupInstructions",
      formik.values.pickupInstructions
    );
    formik.setFieldValue("trackingNumber", formik.values.preTrackingNumber);
    setScheduleBoxTypes(SITADBoxTypes);
  };
  const handleResetFormReviewPanel = () => {
    formik.setFieldValue("reviewComments", formik.values.preReviewComments);
    setReviewApproveBoxTypes(formik.values.reviewApproveBoxtypeCount);
    formik.setFieldValue("weight", resultData?.bulk_weight);
    formik.setFieldValue(
      "selectedUnitOfMeasure",
      resultData?.unit_of_measure_id ? resultData?.unit_of_measure_id : 0
    );
    formik.setFieldValue(
      "finalQuantity",
      handleBoxTypeCount(formik.values.reviewApproveBoxtypeCount)
    );
  };

  const handleReset = () => {
    //checking if the status is none, if so resettingt he sitad form  or schedule form
    if (formik.values.statusId === RequestStatusIds.None) {
      handleResetFormSITAD();
      resetErrorFieldSITAD();
    }
    if (
      formik.values.statusId === RequestStatusIds.Authorized ||
      formik.values.statusId === RequestStatusIds.Scheduled
    ) {
      handleResetFormSchedule();
      resetErrorFieldSchedule();
    }
    if (formik.values.statusId === RequestStatusIds.PendingReview) {
      handleResetFormReviewPanel();
      reSetting();
    }
  };
  const reSetting = async () => {
    await formik.setErrors({});
    await formik.setTouched({});
  };
  // error handling
  const findErrorMessage = (error) => {
    let relatedErrorMessage = "";

    switch (error) {
      case ERROR_TYPES.FAILED:
        relatedErrorMessage = t("vaultItemized.failedError");
        break;
      case ERROR_TYPES.EXCEPTION:
        relatedErrorMessage = t("vaultItemized.errorOccurred");
        break;
      case ERROR_TYPES.ISSUE:
        relatedErrorMessage = t("vaultItemized.somethingWrong");
        break;
      default:
        relatedErrorMessage = error;
        break;
    }

    return relatedErrorMessage;
  };
  // handling CF for cancel
  const handleCancelCF = async (personnel_id, verify) => {
    let requestCancelled = {};
    requestCancelled.destruct_request_id = formik.values.destructRequestId;
    requestCancelled.cancel_personnel_id = personnel_id;
    requestCancelled.cancel_employee_id = auth?.user?.employee_id;
    requestCancelled.destruct_cancel_reason_id = 1;
    requestCancelled.main_district_id = selectedBranch;
    requestCancelled.language_locale_id = languageLocaleId;
    // verify on hold status
    let customerOnHold = handleCustomerOnHold();
    // if the customer is verifyed and customer on hold in "N" allow to cancel
    if (verify && customerOnHold) {
      setLoadingDestructionDetails(true);
      triggerCancelRequest(requestCancelled)
        .unwrap()
        .then((data) => {
          const error = data && data[0]?.error;
          if (error) {
            setIsError(true);
            setErrorMessage(findErrorMessage(data[0]?.error));
          } else {
            // setting the cancel after it successfully saved
            formik.setFieldValue("status", t("pickup.cancelled"));
            formik.setFieldValue("statusId", RequestStatusIds.Cancelled);
            setTimeout(() => {
              // refetching to reload the data
              loadDestructionRequestDetail(formik.values.destructRequestId);
            }, ONE_SECOND_IN_MILLISECONDS);
          }
        })
        .catch((e) => {
          setIsError(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        })
        .finally(() => {
          setLoadingDestructionDetails(false);
        });
    }
  };

  const IsOtherRequestValid = (request_status_id) => {
    //checking for OtherRequestStatus is Valid or not
    if (
      parseInt(request_status_id) === OtherRequestStatus.Canceled ||
      parseInt(request_status_id) === OtherRequestStatus.Complete ||
      parseInt(request_status_id) === OtherRequestStatus.Deleted
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleRequestStatusCF = async (service_request_id) => {
    // checking if the otherRequestStatusId is null
    if (otherRequestStatusId === null) {
      let otherRequestStatusId = OtherRequestStatus.Scheduled; // scheduled
      // checking if it has destruction Id is ""
      if (service_request_id === "") {
        setOtherRequestStatusId(otherRequestStatusId);
      } else {
        // calling the otherrequestStatus CF
        await triggerRunRequestStatus({
          request_id: service_request_id,
          main_district_id: selectedBranch,
          language_locale_id: languageLocaleId
        })
          .unwrap()
          .then((data) => {
            const error = data && data[0]?.error;
            //handle error
            if (error) {
              setIsError(true);
              setErrorMessage(findErrorMessage(data[0]?.error));
            } else {
              // setting in the value
              setOtherRequestStatusId(data[0]?.request_status_id);
            }
          })
          .catch((e) => {
            setIsError(true);
            setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          });
      }
    }

    return;
  };

  const disableButton = (val, statusId) => {
    let button = true;
    let isEnabled = true;
    if (val === t("pickup.new")) {
      //enabling under these conditions
      isEnabled = verifyEmployeePermission(ProgramId.PickupCreateEmployee);
      button = !isEnabled;
    }
    if (formik.values.selectedCustomer) {
      switch (val) {
        case t("pickup.save"):
          // cheching the status is schedule and tracking req is "InteractiveScanOnSite"
          if (
            parseInt(formik.values.trackingRequirements) ===
              TrackingRequirementIds.PickupInteractiveSiteScan &&
            statusId === RequestStatusIds.Scheduled
          ) {
            //  enable by checking otherrequeststatus
            isEnabled = IsOtherRequestValid(otherRequestStatusId);
          } else {
            // enabling the button under these conditions
            isEnabled =
              statusId !== RequestStatusIds.Scheduled &&
              statusId !== RequestStatusIds.Cancelled &&
              statusId !== RequestStatusIds.Completed &&
              statusId !== RequestStatusIds.InProcess &&
              (authSuccess || id) &&
              verifyEmployeePermission(ProgramId.PickupCreateEmployee) &&
              customerInfo[0]?.mdr_on_hold_status === N_CHECKER;
          }
          if (isEnabled) {
            // enabling the button, on verifying the permission on status
            isEnabled = bValid;
          }
          if (isEnabled && statusId === RequestStatusIds.PendingReview) {
            isEnabled = verifyEmployeePermission(ProgramId.ReviewSection);
          }

          if (statusId === RequestStatusIds.PendingConfirmation && isEnabled) {
            isEnabled = false;
          }
          button = !isEnabled;
          break;
        case t("pickup.reset"):
          //enabling under these conditions
          isEnabled =
            statusId !== RequestStatusIds.Scheduled &&
            statusId !== RequestStatusIds.Cancelled &&
            statusId !== RequestStatusIds.InProcess &&
            statusId !== RequestStatusIds.Completed &&
            (authSuccess || id) &&
            verifyEmployeePermission(ProgramId.PickupCreateEmployee) &&
            customerInfo[0]?.mdr_on_hold_status === N_CHECKER;
          if (isEnabled) {
            // enabling the button, on verifying the permission on status
            isEnabled = bValid;
          }
          if (statusId === RequestStatusIds.PendingConfirmation && isEnabled) {
            isEnabled = false;
          }

          button = !isEnabled;
          break;
        case t("pickup.print"):
          //enabling under these conditions
          isEnabled =
            (authSuccess || id) &&
            !(
              formik.values.destructRequestId === "" &&
              statusId === RequestStatusIds.None
            );
          button = !isEnabled;
          break;
        case t("pickup.cancel"):
          //enabling under these conditions
          isEnabled =
            (id || authSuccess) &&
            (statusId === RequestStatusIds.Authorized ||
              statusId === RequestStatusIds.Scheduled ||
              statusId === RequestStatusIds.IsPendingConfirmation) &&
            verifyEmployeePermission(ProgramId.PickupCancelEmployee) &&
            customerInfo[0]?.mdr_on_hold_status === N_CHECKER;
          // after enabled checking for otherRequestStatus
          if (isEnabled && statusId === RequestStatusIds.Scheduled) {
            isEnabled = IsOtherRequestValid(otherRequestStatusId);
          }
          if (isEnabled) {
            // enabling the button, on verifying the permission on status
            isEnabled = bValid;
          }
          button = !isEnabled;
          break;
        case t("pickup.readyToBill"):
          isEnabled =
            statusId === RequestStatusIds.PendingReview &&
            formik.values.reviewApproveServiceLocation !== "" &&
            formik.values.reviewComments !== "" &&
            formik.values.finalQuantity !== 0 &&
            formik.values.reviewApproveTrackingRequirements !== "" &&
            formik.values.reviewDate !== null &&
            formik.values.reviewedEmployeeName !== "" &&
            verifyEmployeePermission(ProgramId.ReadytoBill) &&
            customerInfo[0]?.mdr_on_hold_status === N_CHECKER;
          button = !isEnabled;
          break;
        default:
          break;
      }
    }
    return button;
  };

  const handleErrorWarning = (onClick, message) => {
    return (
      <Grid>
        <Alert
          severity="error"
          id="boxtypeAlert"
          icon={<img src={ErrorIcon} alt="error" />}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              id="boxtypeAlert"
              onClick={onClick}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Grid>
    );
  };
  const MDRPickupSitadProps = {
    authSuccess,
    diableSITAD,
    sitadErrorMsg,
    trackingRequirement,
    serviceLocation,
    scheduleErrorMsg,
    saveSitad,
    SITADBoxTypes,
    handleSITADBoxTypes,
    dispositionServices,
    setBoxtypeWraning,
    boxtypeWraning,
    handleErrorWarning,
    localeByBranch
  };

  //handle yes button
  const handleYes = async () => {
    if (btnType === "reset") {
      setBoxtypeWraningSchedule(false);
      setBoxtypeWraning(false);
      handleReset();
      handleCloseWarningModal();
    }
  };

  const MDRPickupUploadProps = {
    itemCount,
    setItemCount,
    triggerMessage,
    setIsError,
    setErrorMessage,
    findErrorMessage,
    dateUploaded,
    setDateUploaded,
    setVolserList,
    IsOtherRequestValid,
    otherRequestStatusId,
    localeByBranch
  };
  const MDRPickupScheduleProps = {
    diableSchedule,
    handleScheduleBoxTypes,
    scheduleBoxTypes,
    trackingRequirements,
    scheduleErrorMsg,
    scheduleServiceLocation,
    showScheduleModified,
    authSuccess,
    setBoxtypeWraningSchedule,
    boxtypeWraningSchedule,
    handleErrorWarning,
    localeByBranch
  };
  const MDRPickupReviewApproveProps = {
    diableSchedule,
    handleReviewApproveBoxTypes,
    reviewApproveBoxTypes,
    trackingRequirements,
    reviewErrorMsg,
    reviewApproveServiceLocation,
    showScheduleModified,
    authSuccess,
    setBoxtypeWraningReviewApprove,
    boxtypeWraningReviewApprove,
    handleErrorWarning,
    unitOfMeasures,
    reviewSection,
    enableWeightPanel,
    showReviewApprovedModified,
    localeByBranch
  };
  const MDRPickupButtonsProps = {
    statusId: formik.values.statusId,
    disableButton,
    authSuccess,
    personnelId,
    handleSave,
    handleCancelCF,
    setWarningModalText,
    setBtnType,
    handleOpenWarningModal,
    VerifyAuthentication,
    handleOpenPrintModal,
    handleNew,
    setEnableCustomerAuth,
    setAuthFailedCancel,
    setBoxtypeWraningSchedule,
    setBoxtypeWraning,
    handleReadyToBill
  };

  //handle on customer change
  const handleOnChangeOfCustomer = (event, newValue) => {
    //finding the customer with value,label and number
    const selectedCustomer = customerList.find(
      (customer) =>
        customer.value === newValue?.value &&
        customer.label === newValue?.label &&
        customer.number === newValue?.number
    );
    formik.setFieldValue("selectedCustomer", selectedCustomer);
    //closing the warning message
    setAlertMessage(false);
    setOnHoldAlert(false);
    // on change of the customer if not authenticate, empty the personnelId, if not reset the values to intial values
    if (!authSuccess) {
      setPersonnelId("");
    } else {
      setBoxtypeWraningSchedule(false);
      setBoxtypeWraning(false);
      handleReset();
    }
    // removing the error messages on change of the customer
    formik.values.onHoldErrorMessage &&
      formik.setFieldValue("onHoldErrorMessage", "");
    formik.values.verifyErrorMessage &&
      formik.setFieldValue("verifyErrorMessage", "");
  };

  const readOnlyTextField = (label, value) => {
    return (
      <Grid item xs={3} sx={MDRPickupFormStyles.readOnlyTextField}>
        <TextField
          id={label.toLowerCase().replace(/ /g, "-")}
          label={label}
          fullWidth
          variant="standard"
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            sx: { ...MDRPickupFormStyles.fontStyle }
          }}
          InputLabelProps={{
            shrink: true,
            focused: false,
            sx: { ...MDRPickupFormStyles.fontStyle }
          }}
          value={value}
        />
      </Grid>
    );
  };
  const handleErrorOnclick = () => {
    setIsError(false);
    setErrorMessage("");
  };
  return (
    <>
      <CircularLoaderFullPage
        id="MDRPickupFullPageLoader"
        loading={pageLoading || loadingDestructionDetails}
      />
      <FormikProvider value={formik}>
        <form>
          {/* ------------------- Page Title ------------------- */}

          <Stack gap={spacing.horizontalMargin20}>
            <Stack direction="row" sx={MDRPickupFormStyles.TitleStyle}>
              <Typography variant="h4">{t("pickup.MDRPickUpTitle")}</Typography>
              {formik.values.destructRequestId !== "" && (
                <Typography
                  sx={MDRPickupFormStyles.DestructionIdTest}
                  color="var(--chart-harmonious-blue-900)"
                  id="destructionId"
                >
                  Destruction ID : {formik.values.destructRequestId}
                </Typography>
              )}
            </Stack>
            {/* ------------------- Verification Alert ------------------- */}
            {alertMessage && (
              <Grid>
                <Alert
                  severity="warning"
                  id="verifyWraning"
                  icon={<img src={WarningIcon} alt="warning" />}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      id="VerifyWraningCloseIcon"
                      onClick={() => {
                        setAlertMessage(false);
                        formik.setFieldValue("verifyErrorMessage", "");
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {formik.values.verifyErrorMessage}
                </Alert>
              </Grid>
            )}
            {/* ------------------- Wraning Alert ------------------- */}
            {isError && handleErrorWarning(handleErrorOnclick, errorMessage)}
            {/* ------------------- Customer Onhold Alert ------------------- */}
            {onHoldAlert && (
              <Grid>
                <Alert
                  severity="warning"
                  id="onHoldWarning"
                  icon={<img src={WarningIcon} alt="warning" />}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      id="onHoldCloseIcon"
                      onClick={() => {
                        setOnHoldAlert(false);
                        formik.setFieldValue("onHoldErrorMessage", "");
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {formik.values.onHoldErrorMessage}
                </Alert>
              </Grid>
            )}
            {/* ------------------- Customer Section------------------- */}
            {
              <Stack direction="row" gap={spacing.verticalMargin20}>
                <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                  {/*TODO using common component customer, unable to populating the error  */}
                  <CustomersField
                    isOptionEqualToValue={(option, value) => {
                      return option?.value === value && option?.label === value;
                    }}
                    required
                    noOptionsText={t("requestSearch.customerNotFound")}
                    form={{ touched: formik.touched, errors: formik.errors }}
                    options={customerList}
                    label={t("requestSearch.customer")}
                    name="customer"
                    id="customer"
                    value={formik.values.selectedCustomer}
                    disabled={formik.values.status !== ""}
                    handleOnChange={handleOnChangeOfCustomer}
                  />
                </Box>
                <Box>
                  {readOnlyTextField(t("pickup.branch"), currentBranch?.name)}
                </Box>
              </Stack>
            }
            {/* ------------------- status Section ------------------- */}
            {statusAuthorized && (
              <>
                <Stack gap={spacing.verticalMargin20} direction={"row"}>
                  {readOnlyTextField(
                    t("vaultItemized.statusLabel"),
                    formik.values.status
                  )}
                  {readOnlyTextField(
                    t("vaultItemized.authorizedBy"),
                    formik.values.authorizedPersonnelName
                  )}
                  {readOnlyTextField(
                    t("vaultItemized.authorizedDate"),
                    formik.values.authorizedDatetimeLTZ
                  )}
                  {(formik.values.statusId === RequestStatusIds.Scheduled ||
                    formik.values.statusId === RequestStatusIds.PendingReview ||
                    formik.values.statusId === RequestStatusIds.Completed ||
                    formik.values.statusId === RequestStatusIds.InProcess ||
                    (formik.values.otherRequestId &&
                      formik.values.statusId === RequestStatusIds.Cancelled)) &&
                    readOnlyTextField(
                      t("pickup.otherRequestId"),
                      formik.values.otherRequestId
                    )}
                </Stack>
              </>
            )}

            {formik.values.statusId === RequestStatusIds.Cancelled && (
              <>
                <Stack gap={spacing.verticalMargin20} direction={"row"}>
                  {readOnlyTextField(
                    t("vaultItemized.cancelledDate"),
                    formik.values.cancelledDate
                  )}
                  {readOnlyTextField(
                    t("pickup.cancelledReason"),
                    formik.values.cancelledReason
                  )}
                  {readOnlyTextField(
                    t("vaultItemized.cancelledBy"),
                    formik.values.cancelledBy
                  )}
                </Stack>
              </>
            )}
            {(formik.values.statusId === RequestStatusIds.PendingReview ||
              formik.values.statusId === RequestStatusIds.InProcess ||
              formik.values.statusId === RequestStatusIds.Completed) && (
              <>
                <Stack gap={spacing.verticalMargin20} direction={"row"}>
                  {readOnlyTextField(
                    t("pickup.completedDate"),
                    formik.values.completedDate
                  )}
                  {readOnlyTextField(
                    t("pickup.pickupDate"),
                    formik.values.pickupDate
                  )}
                  {formik.values.certifiedDate &&
                    readOnlyTextField(
                      t("pickup.certifiedDate"),
                      formik.values.certifiedDate
                    )}
                </Stack>
              </>
            )}
            {/* ------------------- Personnel Authentication ------------------- */}
            {(!id || (Boolean(id) && authSuccess)) && (
              <PersonnelAuthentication
                value={formik.values}
                customer={formik.values.selectedCustomer}
                onAuthSubmit={handleAuthSubmit}
                authVerified={authSuccess}
                setIsError={setIsError}
                setErrorMessage={setErrorMessage}
                isError={isError}
                errorMessage={errorMessage}
              />
            )}
            {/* <<<<======= Cancel Dialog Box  =====>>>>*/}
            {enableCustomerAuth && (
              <Paper elevation={24}>
                <Dialog
                  open={enableCustomerAuth}
                  fullWidth={true}
                  PaperProps={{
                    sx: {
                      ...MDRPickupFormStyles.dialogModal
                    }
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={MDRPickupFormStyles.paddingTitleStyle}
                  >
                    {t("pickup.cancelTitle")}
                  </DialogTitle>
                  <Divider />

                  {/* <<<<======= Cancel failed Wraning  =====>>>>*/}
                  {authFailedCancel && (
                    <Grid item xs={12} sx={MDRPickupFormStyles.cancelWraning}>
                      <Alert
                        severity="warning"
                        icon={<img src={WarningIcon} alt="warning" />}
                        id="authWarning"
                      >
                        {t("pickup.cancelNoauthMsg")}
                      </Alert>
                    </Grid>
                  )}

                  {/* <<<<======= Personnel authentication section  =====>>>>*/}
                  <Box gap={spacing.horizontalMargin20}>
                    <PersonnelAuthentication
                      value={formik.values}
                      customer={formik.values.selectedCustomer}
                      authVerified={authSuccess}
                      verifyAuth={VerifyAuthentication}
                      setIsError={setIsError}
                      setErrorMessage={setErrorMessage}
                      isError={isError}
                      errorMessage={errorMessage}
                      onAuthSubmit={async (values) => {
                        // closing the dialogbox
                        setEnableCustomerAuth(false);
                        setIsError(false);
                        setErrorMessage("");
                        const personnelId =
                          values.authorizationType === t("pickup.Bypass")
                            ? BypassId
                            : values.personnel;

                        //destruct_request_cancel_sp(handling the cancel)
                        handleCancelCF(personnelId, true);
                      }}
                      setEnableCustomerAuth={() => {
                        //closing the dialogbox
                        setEnableCustomerAuth(false);
                      }}
                      enableCustomerAuth={enableCustomerAuth}
                      setAuthFailedCancel={setAuthFailedCancel}
                    />
                  </Box>
                </Dialog>
              </Paper>
            )}
            {/* ------------------- SITAD Section ------------------- */}

            <SitadDeliveryRequest {...MDRPickupSitadProps} />

            {saveSitad && <Divider />}
            {/* ------------------- schedule Section ------------------- */}

            {saveSitad && <MDRPickupSchedule {...MDRPickupScheduleProps} />}
            {saveSitad && visibiltyUploadFile && (
              <MDRPickupUploadFile {...MDRPickupUploadProps} />
            )}

            {/* ------------------- Review And approve Section ------------------- */}
            {visibiltyReviewPanel && (
              <MDRReviewApprovalRequest {...MDRPickupReviewApproveProps} />
            )}
            <Divider />
            {/* ------------------- Buttons ------------------- */}

            <MDRPickupButtons {...MDRPickupButtonsProps} />
            <DownloadReportModal
              isVisible={openPrintModal}
              setIsVisible={handleClosePrintModal}
              mainDistrictId={currentBranch?.district_id}
              districtName={currentBranch?.name}
              systemId={SecureSyncSysytemId}
              customerId={formik.values?.selectedCustomer?.value}
              languageLocaleId={languageLocaleId}
              destructRequestId={id}
              pickupInstructions={formik.values?.pickupInstructions}
              setIsLoading={setLoadingDestructionDetails}
              throwError={isError}
              setThrowError={setIsError}
              throwErrorMessage={errorMessage}
              setThrowErrorMessage={setErrorMessage}
            />
            <ModalPopup
              title={t("pickup.confirm")}
              modalPopupOpen={openWarningModal}
              handleClick={handleCloseWarningModal}
              alertMessage={warningModalText}
              modalButton={[
                <Button
                  variant="outlined"
                  onClick={handleYes}
                  key={t("common.yes")}
                >
                  {t("common.yes")}
                </Button>,
                <Button
                  variant="contained"
                  onClick={handleCloseWarningModal}
                  key={t("common.no")}
                >
                  {t("common.no")}
                </Button>
              ]}
            />
          </Stack>
        </form>
      </FormikProvider>
    </>
  );
}

export default MediaRequestPickupDetail;
