export function generateDayPattern(fromDate, toDate) {
  // This routine takes a date range and returns a pattern of 1's and 0's based on the presence of the
  // days of the week in the date range.
  // For example, given the range 1/1/97 (A Wednesday) to 1/3/97, the pattern returned would be "0001110".
  // This is illustrated by the following table:
  // Sun Mon Tue Wed Thu Fri Sat
  //  0   0   0   1   1   1   0
  // As another example, the pattern for the date range 1/1/97 to 2/1/97 would be returned as "1111111" since
  // all days of the week are represented in the range. Note that as soon as we have a full pattern we can
  // stop checking the range and can return to the caller.

  const WorkArray = new Array(7).fill(0);
  let newDate = new Date(0);
  let sReturnVal = "";

  let i = 1;
  newDate = new Date(fromDate);
  for (i = 1; i <= 7; i++) {
    if (newDate > new Date(toDate)) {
      break;
    }

    WorkArray[newDate.getDay()] = -1;
    newDate.setDate(newDate.getDate() + 1);
  }

  for (i = 1; i <= 7; i++) {
    sReturnVal += WorkArray[i - 1] !== 0 ? "1" : "0";
  }

  return sReturnVal;
}
