/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Collapse, Grid, IconButton } from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES, dateFormat } from "utils/constants";
import {
  billingCycle,
  billingSummaryPrintTypes
} from "utils/constants/biling-module";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getSortedData
} from "utils/helpers";
import BillingSummaryPrintModal from "./BillingSummaryPrintModal";
import BillingSummarySearch from "./BillingSummarySearch";
import { enterpriseBillingSummaryLayoutStyles } from "./BillingSummaryStyles";
import BillingSummaryTable from "./BillingSummaryTable";

const BillingSummaryLayout = () => {
  const billingSummaryInitialValues = {
    billCycle: "",
    billDateEom: "",
    billDateOto: dayjs(Date.now()),
    printType: billingSummaryPrintTypes.enterpriseBillingSummary,
    branch: ""
  };

  const searchPanelForm = useFormik({
    initialValues: billingSummaryInitialValues
  });

  const { t } = useTranslation();
  const { localeByBranch } = useSelector(selectAuth);
  const districtId = getAuthenticatedUserBranch();
  //search data state
  const [searchData, setSearchData] = useState([]);
  //error message showing states
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  //warning message showing states
  const [throwWarning, setThrowWarning] = useState(false);
  const [throwWarningMessage, setThrowWarningMessage] = useState(false);
  //find button enable disable
  const [findEnable, setFindEnable] = useState(true);
  //print button enable disable
  const [printEnable, setPrintEnable] = useState(true);
  //print modal open/close
  const [openPrintModal, setOpenPrintModal] = useState(false);
  //clicked row data
  const [clickedRowData, setClickedRowData] = useState(null);

  //loader states
  const [isLoading, setIsLoading] = useState(false);

  //selected row
  const [selectedRow, setSelectedRow] = useState(null);
  //page status
  const [pageToZero, setPageToZero] = useState(false);

  const [allLocations, setAllLocations] = useState([]);

  const selectedBranch = getAuthenticatedUserBranch();

  //Print modal open/close
  const handleOpenPrintModal = () => setOpenPrintModal(true);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
    searchPanelForm.setValues({
      ...searchPanelForm?.values,
      printType: billingSummaryPrintTypes.enterpriseBillingSummary,
      branch: ""
    });
  };

  //handle Reset screen
  const handleReset = () => {
    setFindEnable(true);
    setSearchData([]);
    setPrintEnable(true);
    setClickedRowData(null);
    setThrowErrorMessage(false);
    setThrowError(false);
    setThrowWarningMessage(false);
    setThrowWarning(false);
    setIsLoading(false);
    setSelectedRow(null);
    setPageToZero(true);
  };

  //fetch table data
  const fetchTableData = async () => {
    try {
      setIsLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: districtId,
        billing_cycle_code: searchPanelForm?.values?.billCycle.trim(),
        billing_date:
          searchPanelForm?.values?.billCycle.trim() === billingCycle.eom
            ? searchPanelForm?.values?.billDateEom
            : dayjs(searchPanelForm?.values?.billDateOto).format(
                dateFormat.universalFormat
              )
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.billingSummary.getBillingSummary
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const tableData = data?.data[0];
      if (tableData) {
        let sortedData = getSortedData(tableData, "code", "asc");
        setSearchData(sortedData);
        setFindEnable(false);
        setPrintEnable(false);
      } else if (data?.error) {
        setThrowErrorMessage(tableData.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //handle find
  const handleFind = async () => {
    fetchTableData();
    setPageToZero(false);
  };

  return (
    <>
      <CircularLoaderFullPage loading={isLoading} />
      <Collapse in={throwError}>
        {/*Error alert box*/}
        <Alert
          severity="error"
          icon={<img src={ErrorIcon} alt="error" />}
          sx={enterpriseBillingSummaryLayoutStyles.alertBox}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      </Collapse>
      <Collapse in={throwWarning}>
        {/*Warinng alert box*/}
        <Alert
          severity="warning"
          sx={enterpriseBillingSummaryLayoutStyles.alertBox}
          icon={<img src={WarningIcon} alt="warning" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowWarning(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {throwWarningMessage}
        </Alert>
      </Collapse>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        gap={spacing.verticalMargin20}
      >
        <Grid id="search-panel" item>
          {/*search panel component*/}
          <BillingSummarySearch
            handleFind={handleFind}
            searchPanelForm={searchPanelForm}
            setFindEnable={setFindEnable}
            findEnable={findEnable}
            setSearchData={setSearchData}
            handleReset={handleReset}
            setClickedRowData={setClickedRowData}
            setIsLoading={setIsLoading}
            setThrowErrorMessage={setThrowErrorMessage}
            setThrowError={setThrowError}
            allLocations={allLocations}
          />
        </Grid>
        <Grid id="summary-table" item>
          {/*table component*/}
          <BillingSummaryTable
            searchData={searchData}
            setSearchData={setSearchData}
            setThrowErrorMessage={setThrowErrorMessage}
            setThrowError={setThrowError}
            findErrorMessage={findErrorMessage}
            searchPanelForm={searchPanelForm}
            setFindEnable={setFindEnable}
            handleOpenPrintModal={handleOpenPrintModal}
            printEnable={printEnable}
            clickedRowData={clickedRowData}
            setClickedRowData={setClickedRowData}
            setIsLoading={setIsLoading}
            fetchTableData={fetchTableData}
            findEnable={findEnable}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            localeByBranch={localeByBranch}
            setThrowWarning={setThrowWarning}
            setThrowWarningMessage={setThrowWarningMessage}
            pageToZero={pageToZero}
          />
        </Grid>
        {/*print modal component*/}
        <BillingSummaryPrintModal
          open={openPrintModal}
          handleClose={handleClosePrintModal}
          data={[]}
          form={searchPanelForm}
          setSearchData={setSearchData}
          searchPanelForm={searchPanelForm}
          setIsLoading={setIsLoading}
          branchList={searchData}
        />
      </Grid>
    </>
  );
};

export default BillingSummaryLayout;
