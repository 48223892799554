import { Grid } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";

const MissingMediaDetail = ({ tableData }) => {
  const [dataSorting, setDataSorting] = useState([]);
  useEffect(() => {
    if (tableData.length) {
      let sortData = tableData?.sort(
        (a, b) =>
          a?.sb_location?.split("-")?.join("") -
          b?.sb_location?.split("-")?.join("")
      );
      setDataSorting([...sortData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS
  };
  const columns = [
    {
      name: "customer_number",
      label: t("openMediaAuditDetail.customer")
    },
    {
      name: "om_volser",
      label: t("openMediaAuditDetail.media")
    },
    {
      name: "logical_vault_code",
      label: t("openMediaAuditDetail.logicalVault")
    },

    {
      name: "media_type_short_descr",
      label: t("openMediaAuditDetail.mediaType")
    },

    {
      name: "sb_location",
      label: t("openMediaAuditDetail.sbLocation")
    },
    {
      name: "batch_id",
      label: t("openMediaAuditDetail.batchId")
    }
  ];
  return (
    <Grid item xs={12} lg={3} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={[...dataSorting]}
        options={options}
      />
    </Grid>
  );
};

export default MissingMediaDetail;
