import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Stack } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "store/slices/customer-preferences";

import { FullCircularLoader } from "components/core";
import { emailStyles } from "../PreferencesStyles";
import PreferencesAuth from "./PreferencesAuth";

const PreferencesLayout = ({ children, showBypass = false }) => {
  const {
    showAuthSection,
    throwError,
    throwErrorMsg,
    isAuthenticated,
    isLoading
  } = useSelector((state) => state.customerPreferences);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <>
      <Stack>
        {isLoading && <FullCircularLoader />}
        <PreferencesAuth showBypass={showBypass} />
        {throwError && (
          <Alert
            sx={emailStyles.itemStyle}
            severity="error"
            icon={<img src={ErrorIcon} alt="error" width="20" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(clearError());
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMsg}
          </Alert>
        )}
        <Stack sx={emailStyles.itemStyle}>
          {(!showAuthSection || isAuthenticated) && children}
        </Stack>
      </Stack>
    </>
  );
};
export default PreferencesLayout;
