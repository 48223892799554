export const sourceList = [
  {
    value: "<All>",
    label: "<All>"
  },
  {
    value: "1",
    label: "SecureSyn"
  },
  {
    value: "2",
    label: "DR Events"
  }
];

export const all = {
  value: "<All>",
  label: "<All>"
};

export const initialValues = {
  customerId: all,
  sourceId: "<All>"
};
export const severityError = "error";
export const severityWarning = "warning";
export const systemId = "60";
export const globalAttributeTypeIdCalFuture = "90";
export const globalAttributeTypeId88 = "88";
export const fileType = "application/rtf";
