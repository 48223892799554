import { t } from "i18next";

export const clear = "clear";
export const employeeAccess = "0";
export const personnelAccess = "0";

export const requirmentCode = "R";
export const parnentId = 23;
export const interBranch = 2;
export const mediaCare = 3;
export const systemId = "69";
export const preferenceTypeId24 = "24";
export const preferenceTypeId29 = "29";
export const preferenceId = "33";
export const fourtyOne = "41";
export const pattern = /^\d*$/;
export const preferencesTypeIds = {
  payInvoice: "41",
  scanOnSite: "35",
  placeHoldOnMediaDestructionRequests: "21",
  holidayService: "39",
  emailNotification: "20",
  dontCallResolveDesc: "18",
  addCutOffDeadlineRequest: "33",
  serviceIndicator: "23",
  minimumEmptyTransports: "24"
};
export const payloadHash = "#";
export const payloadPersentageTwentyThree = "%23";
export const roleAccessMappings = [
  { editRoleAccess: ["282", "284"], preference_type_id: "41" },
  { editRoleAccess: ["349"], preference_type_id: "39" }, // holidayServiceRoleForEditMode
  { editRoleAccess: ["view"], preference_type_id: ["20", "18", "21"] }, // resolveDiscrepancies, customerDeliveryPickup, DestructionRequest
  { editRoleAccess: ["284"], preference_type_id: "24" }, // minimumEmptyTrnasPortEditMode
  { editRoleAccess: ["335"], preference_type_id: "33" }, // addCutOffDeadlineEditMode
  { editRoleAccess: ["341"], preference_type_id: "35" }, // scanOnSiteEditMode
  { editRoleAccess: ["322", "325"], preference_type_id: "23" } // serviceIndicatorEditMode
];
export const operationsLookup = {
  serviceIndicatorType1: "Operations_ServiceIndicatorTypes_1",
  serviceIndicatorType2: "Operations_ServiceIndicatorTypes_2",
  serviceIndicatorType3: "Operations_ServiceIndicatorTypes_3",
  serviceIndicatorType4: "Operations_ServiceIndicatorTypes_4",
  serviceIndicatorType5: "Operations_ServiceIndicatorTypes_5",
  serviceIndicatorType6: "Operations_ServiceIndicatorTypes_6",
  serviceIndicatorType7: "Operations_ServiceIndicatorTypes_7",
  serviceIndicatorType9: "Operations_ServiceIndicatorTypes_9",
  operationsHolidayServiceTypes51: "Operations_HolidayServiceTypes_51",
  operationsHolidayServiceTypes52: "Operations_HolidayServiceTypes_52",
  operationsHolidayServiceTypes53: "Operations_HolidayServiceTypes_53",
  operationsHolidayServiceTypes54: "Operations_HolidayServiceTypes_54",
  operationsHolidayServiceTypes55: "Operations_HolidayServiceTypes_55",
  operationsHolidayServiceTypes56: "Operations_HolidayServiceTypes_56"
};

export const resourceFilekeys = {
  // Service Indicator Types
  [operationsLookup.serviceIndicatorType1]: t(
    "preferences.operationsServiceIndicatorTypes1"
  ),
  [operationsLookup.serviceIndicatorType2]: t(
    "preferences.operationsServiceIndicatorTypes2"
  ),
  [operationsLookup.serviceIndicatorType3]: t(
    "preferences.operationsServiceIndicatorTypes3"
  ),
  [operationsLookup.serviceIndicatorType4]: t(
    "preferences.operationsServiceIndicatorTypes4"
  ),
  [operationsLookup.serviceIndicatorType5]: t(
    "preferences.operationsServiceIndicatorTypes5"
  ),
  [operationsLookup.serviceIndicatorType6]: t(
    "preferences.operationsServiceIndicatorTypes6"
  ),
  [operationsLookup.serviceIndicatorType7]: t(
    "preferences.operationsServiceIndicatorTypes7"
  ),
  [operationsLookup.serviceIndicatorType9]: t(
    "preferences.operationsServiceIndicatorTypes9"
  ),

  // Holiday Service Types
  [operationsLookup.operationsHolidayServiceTypes51]: t(
    "preferences.operationsHolidayServiceTypes51"
  ),
  [operationsLookup.operationsHolidayServiceTypes52]: t(
    "preferences.operationsHolidayServiceTypes52"
  ),
  [operationsLookup.operationsHolidayServiceTypes53]: t(
    "preferences.operationsHolidayServiceTypes53"
  ),
  [operationsLookup.operationsHolidayServiceTypes54]: t(
    "preferences.operationsHolidayServiceTypes54"
  ),
  [operationsLookup.operationsHolidayServiceTypes55]: t(
    "preferences.operationsHolidayServiceTypes55"
  ),
  [operationsLookup.operationsHolidayServiceTypes56]: t(
    "preferences.operationsHolidayServiceTypes56"
  )
};
export const descriptionLookUp = {
  customerPreferenceTypes25: "Customer_CustomerPreferenceTypes_25",
  customerPreferenceTypes27: "Customer_CustomerPreferenceTypes_27",
  customerPreferenceTypes29: "Customer_CustomerPreferenceTypes_29",
  customerPreferenceTypes20: "Customer_CustomerPreferenceTypes_20",
  customerPreferenceTypes18: "Customer_CustomerPreferenceTypes_18",
  customerPreferenceTypes22: "Customer_CustomerPreferenceTypes_22",
  customerPreferenceTypes24: "Customer_CustomerPreferenceTypes_24",
  customerPreferenceTypes21: "Customer_CustomerPreferenceTypes_21",
  customerPreferenceTypes23: "Customer_CustomerPreferenceTypes_23",
  customerPreferenceTypes26: "Customer_CustomerPreferenceTypes_26",
  customerPreferenceTypes28: "Customer_CustomerPreferenceTypes_28",
  customerPreferenceTypes39: "Customer_CustomerPreferenceTypes_39"
};

export const resourceFileDesecriptionKeys = {
  [descriptionLookUp.customerPreferenceTypes25]: t(
    "preferences.customerPreferenceTypes25"
  ),
  [descriptionLookUp.customerPreferenceTypes27]: t(
    "preferences.customerPreferenceTypes27"
  ),
  [descriptionLookUp.customerPreferenceTypes29]: t(
    "preferences.customerPreferenceTypes29"
  ),
  [descriptionLookUp.customerPreferenceTypes20]: t(
    "preferences.customerPreferenceTypes20"
  ),
  [descriptionLookUp.customerPreferenceTypes18]: t(
    "preferences.customerPreferenceTypes18"
  ),
  [descriptionLookUp.customerPreferenceTypes22]: t(
    "preferences.customerPreferenceTypes22"
  ),
  [descriptionLookUp.customerPreferenceTypes24]: t(
    "preferences.customerPreferenceTypes24"
  ),
  [descriptionLookUp.customerPreferenceTypes21]: t(
    "preferences.customerPreferenceTypes21"
  ),
  [descriptionLookUp.customerPreferenceTypes23]: t(
    "preferences.customerPreferenceTypes23"
  ),
  [descriptionLookUp.customerPreferenceTypes26]: t(
    "preferences.customerPreferenceTypes26"
  ),
  [descriptionLookUp.customerPreferenceTypes28]: t(
    "preferences.customerPreferenceTypes28"
  ),

  [descriptionLookUp.customerPreferenceTypes39]: t(
    "preferences.customerPreferenceTypes39"
  )
};

export const operationsTransportationVendors =
  "Operations.TransportationVendors";

export const getPreferenceTypesPayloadForReport = () => [
  {
    preference_type_id: "23",
    description: t("preferences.serviceIndicator.serviceIndicatorType")
  },
  {
    preference_type_id: "25",
    description: t("preferences.serviceIndicator.3rdPartyShipper")
  },
  {
    preference_type_id: "26",
    description: t("preferences.serviceIndicator.serviceLevel")
  },
  {
    preference_type_id: "28",
    description: t("preferences.serviceIndicator.shipperNumber")
  },
  {
    preference_type_id: "27",
    description: t("preferences.serviceIndicator.billToCustomer")
  },
  {
    preference_type_id: "18",
    description: t("preferences.customerPreferenceTypes18")
  },
  {
    preference_type_id: "20",
    description: t("preferences.customerPreferenceTypes20")
  },
  {
    preference_type_id: "33",
    description: t("preferences.addCutOffDeadline.title")
  },
  {
    preference_type_id: "39",
    description: t("preferences.customerPreferenceTypes39")
  },
  {
    preference_type_id: "24",
    description: t("preferences.customerPreferenceTypes24")
  },
  {
    preference_type_id: "41",
    description: t("preferences.payInvoice.comments")
  },
  {
    preference_type_id: "42",
    description: t("preferences.payInvoice.payInvoice")
  },
  {
    preference_type_id: "21",
    description: t("preferences.customerPreferenceTypes21")
  },
  {
    preference_type_id: "35",
    description: t("preferences.scanOnSitePickup")
  },
  {
    preference_type_id: "36",
    description: t("preferences.interactiveScan")
  },
  {
    preference_type_id: "37",
    description: t("preferences.scanOnSiteDeliveery")
  },
  {
    preference_type_id: "38",
    description: t("preferences.comments")
  },
  {
    preference_type_id: "29",
    description: t("preferences.comments")
  }
];
