import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useFormik } from "formik";
import { useCallbackPrompt } from "hooks";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ASSIGN_SHELVES,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import { selectAuth, selectRoute, setHaveChanges } from "store/slices";
import spacing from "styles/spacing";
import {
  downloadReportOptions,
  ERROR_TYPES,
  errorMsgs,
  GLOBAL_TYPE_BILLALL,
  GLOBAL_TYPE_TURNROUNDOFF,
  ROLE_ACCESS_CODES,
  SYSTEM_ID_SecureBase,
  VALUE_EMPTY_STRING
} from "utils/constants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getUserName,
  printReport
} from "utils/helpers";
import { logicalVaultConstants, none, reg } from "./constants";
import {
  addMediaTypes,
  createLogicalVaultRecord,
  deleteLogicalVault,
  deleteMediaTypes,
  getActiveCatalogItems,
  getAvailableVaultMediaTypes,
  getHandlingCatalogItems,
  getLogicalVaultInfo,
  getMediaTypeByProgramType,
  getOpenMediaAuditsCheck,
  getSelectedVaultMediaTypes,
  getVaultNameDetails,
  renameLogicalVault,
  updateLogicalVaultRecord
} from "./services";
import { logicalVaultDetailesStyles } from "./Styles";
import TransferListItem from "./TransferListItem";
import VaultDeleteModal from "./VaultDeleteModal";
import VaultRenameModal from "./VaultRenameModal";

const VaultDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentBranch, auth, roleAccess } = useSelector(selectAuth);
  const { haveChanges } = useSelector(selectRoute);
  const programType41 = roleAccess.includes(ROLE_ACCESS_CODES.CODE_41);
  const programType285 = roleAccess.includes(ROLE_ACCESS_CODES.CODE_285);
  const programType323 = roleAccess.includes(ROLE_ACCESS_CODES.CODE_323);

  const valueType3 = logicalVaultConstants.valueType3;
  const valueType5 = logicalVaultConstants.valueType5;
  const valueType4 = logicalVaultConstants.valueType4;
  const valueI = logicalVaultConstants.valueI;
  const valueC = logicalVaultConstants.valueC;
  const valueN = logicalVaultConstants.valueN;
  const noValue = logicalVaultConstants.noValue;
  const yesValue = logicalVaultConstants.yesValue;
  const valueVR = logicalVaultConstants.valueVR;
  const valueRI = logicalVaultConstants.valueRI;
  const [showPrintModal, setShowPrintModal] = useState(false);
  const selectedValue = downloadReportOptions[0];
  const vaultDefaultObj = {
    media_assigned_flag: logicalVaultConstants.noValue
  };
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges);

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const handleFieldChange = (event) => {
    if (checkCapacityError(event)) {
      return;
    }
    const { name, value } = event.target;
    logicalVaultDetailForm.setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSlotAssignmentChange = (event) => {
    if (checkCapacityError(event)) {
      return;
    }

    if (
      logicalVaultDetailForm.values.vaultSlotAssignment ===
        logicalVaultConstants.valueC &&
      !data.isVaultNameNew
    ) {
      setData((prev) => ({
        ...prev,
        vaultSlotAssignmentCustomer: event.target.value
      }));
    } else {
      logicalVaultDetailForm.setValues((prev) => ({
        ...prev,
        vaultSlotAssignment: event.target.value
      }));
    }
  };

  const getReviewMonthlyState = (itemCode) => {
    if (itemCode === "") {
      return false;
    }

    const typeCode = data.handlingCatalogItems.filter(
      (e) => e.catalog_item_code === itemCode
    );
    if (
      typeCode.length > 0 &&
      typeCode[0].handle_type_code !== undefined &&
      typeCode[0].handle_type_code === logicalVaultConstants.catalogTypeHourly
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCatalogItemChange = (event) => {
    if (checkCapacityError(event)) {
      return;
    }
    const { name, value } = event.target;
    getReviewMonthlyState(value);
    logicalVaultDetailForm.setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleCheckBoxChange = (event) => {
    if (checkCapacityError(event)) {
      return;
    }
    const { name, value } = event.target;

    if (logicalVaultDetailForm.values[name].includes(value)) {
      const filtered = logicalVaultDetailForm.values[name].filter(
        (e) => e !== value
      );
      logicalVaultDetailForm.setValues((prevValues) => ({
        ...prevValues,
        [name]: [...filtered]
      }));
    } else {
      logicalVaultDetailForm.setValues((prevValues) => ({
        ...prevValues,
        [name]: [...logicalVaultDetailForm.values[name], value]
      }));
    }
  };

  const logicalVaultDetailForm = useFormik({
    initialValues: {
      vaultName: "",
      vaultDesc: "",
      vaultProgramType: valueType5,
      vaultDuration: noValue,
      vaultMediaTracking: noValue,
      vaultSlotAssignment: valueI,
      vaultDistribution: [valueVR],
      vaultInventory: [],
      vaultCapacityNotify: logicalVaultConstants.defaultCapacityValue,
      vaultReviewMonthly: [],
      vaultHandlingHours: "",
      vaultHandlingItem: "",
      vaultingCatalogItem: "",
      vaultBillAll: [],
      vaultServiceNotes: "",
      vaultDistributionServiceNotes: "",
      vaultTurnRoundingFlag: [],
      vaultTurnRoundingValue: "",
      vaultMediaTypes: noValue
    },
    handleChange: handleFieldChange
  });
  const [isLoading, setIsLoading] = useState(false);
  const [canEdit] = useState(programType41);
  const [customer, setCustomer] = useState({
    customer: "",
    customerId: ""
  });
  const [data, setData] = useState({
    customers: [],
    throwErrorMessage: "",
    throwError: false,
    isVaultNameNew: false,
    vaults: [],
    selectedVault: "",
    selectedVaultInfo: vaultDefaultObj,
    selectedMediaTypes: [],
    availableMediaTypes: [],
    activeCatalogItems: [],
    handlingCatalogItems: [],
    vaultDeletedMediaTypes: [],
    vaultAddedMediaTypes: [],
    isVaultDelete: false,
    isVaultRename: false,
    vaultSuccessMessage: "",
    vaultStoredValues: logicalVaultDetailForm.initialValues,
    isUnsavedVaultChange: false,
    unsavedObj: { values: "", type: "" },
    vaultCapacityState: logicalVaultConstants.defaultCapacityValue,
    reviewMonthlyEnabled: false,
    verificationReqEnabled: false,
    isDuplicateVaultName: false,
    vaultSlotAssignmentCustomer: "",
    isTurnRoundOffAllowed: noValue,
    isBillAllAllowed: noValue,
    activeMediaAuditForVault: false,
    errorMessages: {
      vaultRequiredAlert: "",
      capacityRequiredAlert: "",
      hoursRequiredAlert: "",
      mediaTypeRequiredAlert: "",
      deleteMediaAlert: "",
      deleteNonMediaAlert: "",
      duplicateVaultAlert: "",
      slotAssignmentAlert: "",
      duplicateVaultAlertInfo: ""
    }
  });
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");

  const getAllCustomers = async () => {
    try {
      setIsLoading(true);
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));
        setData((prev) => ({ ...prev, customers: data }));
      }
      setIsLoading(false);
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const getLoadedErrorMessages = async () => {
    const vaultRequiredAlert = await getMessageFromCode(
      errorMsgs.errorCode10229
    );

    const capacityRequiredAlert = await getMessageFromCode(
      errorMsgs.errorCode25095
    );
    const hoursRequiredAlert = await getMessageFromCode(
      errorMsgs.errorCode25114
    );
    const mediaTypeRequiredAlert = await getMessageFromCode(
      errorMsgs.errorCode20350
    );
    const deleteMediaAlert = await getMessageFromCode(errorMsgs.errorCode25107);
    const deleteNonMediaAlert = await getMessageFromCode(
      errorMsgs.errorCode62518
    );
    const duplicateVaultAlert = await getMessageFromCode(
      errorMsgs.errorCode25274
    );
    const slotAssignmentAlert = await getMessageFromCode(
      errorMsgs.errorCode25090
    );
    const duplicateVaultAlertInfo = await getMessageFromCode(
      errorMsgs.errorCode25092
    );

    setData((prev) => ({
      ...prev,
      errorMessages: {
        vaultRequiredAlert: vaultRequiredAlert[0]?.descr,
        capacityRequiredAlert: capacityRequiredAlert[0]?.descr,
        hoursRequiredAlert: hoursRequiredAlert[0]?.descr,
        mediaTypeRequiredAlert: mediaTypeRequiredAlert[0]?.descr.replace(
          "|",
          t("logicalVaultDetails.errMsg4")
        ),
        deleteMediaAlert: deleteMediaAlert[0]?.descr,
        deleteNonMediaAlert: deleteNonMediaAlert[0]?.descr.replace(
          "|",
          t("logicalVaultDetails.logicalVaultText")
        ),
        duplicateVaultAlert: duplicateVaultAlert[0]?.descr,
        slotAssignmentAlert: slotAssignmentAlert[0]?.descr,
        duplicateVaultAlertInfo: duplicateVaultAlertInfo[0]?.descr
      }
    }));
  };

  const getGlobalData = async () => {
    const districtId = getAuthenticatedUserBranch();

    const turnRoundAttributeData = await getGlobalAttributeValue(
      districtId,
      SYSTEM_ID_SecureBase,
      GLOBAL_TYPE_TURNROUNDOFF
    );

    const billAttributeData = await getGlobalAttributeValue(
      districtId,
      SYSTEM_ID_SecureBase,
      GLOBAL_TYPE_BILLALL
    );

    let turnRoundFieldCheck =
      turnRoundAttributeData > Number(logicalVaultConstants.valueZero);

    setData((prev) => ({
      ...prev,
      isTurnRoundOffAllowed: turnRoundFieldCheck ? yesValue : noValue,
      isBillAllAllowed: billAttributeData
    }));
  };

  useEffect(() => {
    getAllCustomers();
    getLoadedErrorMessages();
    getGlobalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainDistrictId]);

  const getSortedVault = (arr) => {
    if (arr.length === 0) {
      return [];
    }

    return arr.toSorted((a, b) => {
      if (a["code"].toLowerCase() < b["code"].toLowerCase()) {
        return -1;
      }
      if (a["code"].toLowerCase() > b["code"].toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  const loadVaultDetails = async (custId) => {
    try {
      if (!data.isVaultNameNew) {
        setIsLoading(true);
      }

      let result = await getVaultNameDetails(mainDistrictId, custId);
      if (result) {
        setData((prev) => ({
          ...prev,
          vaults: getSortedVault(result?.data[0]),
          selectedVault: "",
          selectedVaultInfo: vaultDefaultObj
        }));
      }
      if (!data.isVaultNameNew) {
        setIsLoading(false);
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const getVaultFormattedObj = (vaultResponseData) => {
    let vaultDistributionSelection = [];

    if (
      vaultResponseData.distribution_verification_required_flag === yesValue
    ) {
      vaultDistributionSelection.push(valueVR);
    }

    if (vaultResponseData.interactive_distribution_scan_flag === yesValue) {
      vaultDistributionSelection.push(valueRI);
    }

    return {
      vaultName: data.selectedVault,
      vaultDesc: vaultResponseData.invoice_descr.trim(),
      vaultProgramType: vaultResponseData.media_program_type_id,
      vaultDuration: vaultResponseData.long_term_flag,
      vaultMediaTracking: vaultResponseData.manual_count_flag,
      vaultSlotAssignment:
        vaultResponseData.customer_assigned_slot_flag === yesValue
          ? valueC
          : vaultResponseData.display_slot_flag === yesValue
            ? valueI
            : noValue,
      vaultCapacityNotify: vaultResponseData.capacity_threshold,
      vaultDistribution: vaultDistributionSelection,
      vaultInventory: vaultResponseData.inventory_file_provided_flag.split(""),
      vaultReviewMonthly:
        vaultResponseData.review_handling_hours_flag.split(""),
      vaultHandlingHours: vaultResponseData.default_handling_hours,
      vaultHandlingItem: vaultResponseData.handle_catalog_item_code,
      vaultingCatalogItem: vaultResponseData.vault_catalog_item_code,
      vaultBillAll: vaultResponseData.bill_all_active_volsers_flag.split(""),
      vaultServiceNotes: vaultResponseData.pick_service_note,
      vaultDistributionServiceNotes: vaultResponseData.dist_service_note,
      vaultTurnRoundingFlag:
        vaultResponseData.billing_rounding_override_flag.split(""),
      vaultTurnRoundingValue:
        vaultResponseData.bill_round_inc ||
        vaultResponseData.default_rounding_inc,
      vaultMediaTypes: noValue
    };
  };

  const loadLogicalVaultInfo = async (vaultId) => {
    try {
      setIsLoading(true);
      let result = await getLogicalVaultInfo(mainDistrictId, vaultId);
      if (result) {
        const vaultResponseData = result.data[0][0];
        const valueObj = getVaultFormattedObj(vaultResponseData);
        logicalVaultDetailForm.setValues(valueObj);

        setData((prev) => ({
          ...prev,
          selectedVaultInfo: vaultResponseData,
          vaultStoredValues: valueObj
        }));
      }
      setIsLoading(false);
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const loadSelectedMediaTypes = async (vaultId) => {
    try {
      let result = await getSelectedVaultMediaTypes(mainDistrictId, vaultId);

      if (result) {
        loadAvailableMediaTypes(vaultId, result?.data[0]);
        setData((prev) => ({ ...prev, selectedMediaTypes: result?.data[0] }));
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const loadAvailableMediaTypes = async (vaultId, selectedMediaTypesValues) => {
    try {
      setIsLoading(true);

      let result = await getAvailableVaultMediaTypes(mainDistrictId, vaultId);

      if (result) {
        const filteredAvailableMediaTypes = result?.data[0].filter((e) => {
          return (
            selectedMediaTypesValues.filter(
              (r) => r.media_type_id === e.media_type_id
            ).length === 0
          );
        });
        setData((prev) => ({
          ...prev,
          availableMediaTypes: filteredAvailableMediaTypes
        }));
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const loadMediaTypeByProgramType = async (programTypeId) => {
    try {
      setIsLoading(true);

      let result = await getMediaTypeByProgramType(
        mainDistrictId,
        programTypeId
      );

      if (result) {
        setData((prev) => ({
          ...prev,
          availableMediaTypes: [...(result?.data[0] ?? [])]
        }));
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data.selectedVault === "" && data.isVaultNameNew) {
      loadMediaTypeByProgramType(
        logicalVaultDetailForm.values.vaultProgramType
      );

      setData({
        ...data,
        vaultAddedMediaTypes: [],
        selectedMediaTypes: []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logicalVaultDetailForm.values.vaultProgramType, data.isVaultNameNew]);

  const handleVaultChange = (e) => {
    if (
      handleUnsavedChanges(e.target.value, t("logicalVaultDetails.vaultName"))
    ) {
      return;
    } else {
      setData((prev) => ({
        ...prev,
        selectedVault: e.target.value
      }));

      logicalVaultDetailForm.setValues((prevValues) => ({
        ...prevValues,
        vaultName: e.target.value
      }));
    }
  };

  useEffect(() => {
    if (data.selectedVault !== "" && !data.isVaultNameNew) {
      verifyOpenMediaAudits(data.selectedVault);
      loadSelectedMediaTypes(data.selectedVault);
      loadLogicalVaultInfo(data.selectedVault);
    }

    setData((prev) => ({
      ...prev,
      throwErrorMessage: "",
      throwError: false
    }));
    // eslint-disable-next-line
  }, [data.selectedVault]);

  const handleUnsavedChanges = (newValue, fieldType) => {
    if (data.isVaultNameNew) {
      if (!checkEqualValuesforFormNew()) {
        setData((prev) => ({
          ...prev,
          isUnsavedVaultChange: true,
          unsavedObj: { values: newValue, type: fieldType }
        }));
        return true;
      }

      return false;
    } else {
      if (!checkEqualValuesforFormUpdate() && data.selectedVault !== "") {
        setData((prev) => ({
          ...prev,
          isUnsavedVaultChange: true,
          unsavedObj: { values: newValue, type: fieldType }
        }));
        return true;
      }

      return false;
    }
  };

  const handleCustomerChange = (event, newValue) => {
    if (handleUnsavedChanges(newValue, t("logicalVaultDetails.customer"))) {
      return;
    } else {
      if (newValue === null) {
        setCustomer({
          customer: "",
          customerId: ""
        });
      } else {
        newValue = { label: newValue?.label, value: newValue.value };
        if (newValue) {
          setCustomer({
            customer: newValue?.label,
            customerId: newValue?.value
          });
        }
      }
    }
  };
  const customersFieldProps = {
    required: true,
    options: [...data.customers],
    handleOnChange: handleCustomerChange,
    value: customer.customer === "" ? null : customer.customer
  };
  const handleNew = () => {
    if (handleUnsavedChanges("", t("common.new"))) {
      return;
    }

    setData((prev) => ({
      ...prev,
      isVaultNameNew: true,
      selectedMediaTypes: [],
      selectedVault: "",
      selectedVaultInfo: vaultDefaultObj,
      handlingCatalogItems: [],
      activeCatalogItems: []
    }));

    setCustomer({
      customer: "",
      customerId: ""
    });
    logicalVaultDetailForm.resetForm();
  };

  const handleDelete = () => {
    if (data.activeMediaAuditForVault) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: t("logicalVaultDetails.mediaAuditMessageAlert"),
        throwError: true
      }));
    } else {
      setData((prev) => ({ ...prev, isVaultDelete: true }));
    }
  };

  const handleRename = () => {
    if (handleUnsavedChanges("", t("common.rename"))) {
      return;
    }
    setData((prev) => ({ ...prev, isVaultRename: true }));
  };

  const handleCancel = () => {
    if (handleUnsavedChanges("", t("common.cancel"))) {
      return;
    }

    logicalVaultDetailForm.resetForm();
    setData((prev) => ({
      ...prev,
      isVaultNameNew: false,
      vaults: [],
      selectedVault: "",
      selectedVaultInfo: vaultDefaultObj,
      isUnsavedVaultChange: false,
      unsavedObj: { label: "", values: "" },
      vaultStoredValues: logicalVaultDetailForm.values
    }));

    setCustomer((values) => ({ customer: "", customerId: "" }));
  };

  const getVaultCode = (vaultId = "") => {
    if (vaultId !== "") {
      return data.vaults.filter((e) => e.logical_vault_id === vaultId)[0]?.code;
    } else {
      return data.vaults.filter(
        (e) => e.logical_vault_id === data.selectedVault
      )[0]?.code;
    }
  };

  const handleValidate = () => {
    let errorMessage = "";
    const fieldValues = logicalVaultDetailForm.values;

    if (fieldValues.vaultName === "") {
      errorMessage = data.errorMessages.vaultRequiredAlert;
    } else if (
      data.isVaultNameNew &&
      data.vaults.filter(
        (r) =>
          r.code.trim().toLowerCase() === fieldValues.vaultName.toLowerCase()
      ).length !== 0
    ) {
      errorMessage = data.errorMessages.duplicateVaultAlertInfo;
    } else if (fieldValues.vaultProgramType === "") {
      errorMessage = t("logicalVaultDetails.errMsg2");
    } else if (
      typeof parseInt(fieldValues.vaultCapacityNotify) !== "number" ||
      parseInt(data.vaultCapacityState) >
        logicalVaultConstants.capacityMaxLength ||
      parseInt(data.vaultCapacityState) <
        logicalVaultConstants.capacityMinLength
    ) {
      errorMessage = data.errorMessages.capacityRequiredAlert;
    } else if (typeof parseInt(fieldValues.vaultHandlingHours) !== "number") {
      errorMessage = data.errorMessages.hoursRequiredAlert;
    } else if (
      data.isVaultNameNew &&
      data.vaultAddedMediaTypes.length === 0 &&
      logicalVaultDetailForm.values.vaultProgramType === valueType5
    ) {
      errorMessage = data.errorMessages.mediaTypeRequiredAlert;
    }

    setData((prev) => ({
      ...prev,
      throwErrorMessage: errorMessage,
      throwError: errorMessage !== ""
    }));

    return errorMessage;
  };

  const checkDiffObjects = (obj1, obj2) => {
    let diffKeys = [];

    const obj1Keys = Object.keys(obj1).sort();
    const obj2Keys = Object.keys(obj2).sort();

    obj1Keys.forEach((key, index) => {
      const objValue1 = obj1[key];
      const objValue2 = obj2[obj2Keys[index]];
      if (objValue1 !== objValue2) {
        diffKeys.push(key);
      }
    });
    return diffKeys;
  };

  const setUpdatedMediaTypes = () => {
    const toAddMediaTypes = data.vaultAddedMediaTypes.map((obj) => obj.descr);

    const toDeleteMediaTypes = data.vaultDeletedMediaTypes.map(
      (obj) => obj.descr
    );

    const updatedSelectedMediaTypes = data.selectedMediaTypes.filter(
      (m) => !toDeleteMediaTypes.includes(m.descr)
    );

    const updatedAvailableMediaTypes = data.availableMediaTypes.filter(
      (m) => !toAddMediaTypes.includes(m.descr)
    );

    const updatedSelectedMediaTypesObj = [
      ...updatedSelectedMediaTypes,
      ...data.vaultAddedMediaTypes
    ];

    const updatedAvailableMediaTypesObj = [
      ...updatedAvailableMediaTypes,
      ...data.vaultDeletedMediaTypes
    ];

    setData((prev) => ({
      ...prev,
      availableMediaTypes: updatedAvailableMediaTypesObj,
      selectedMediaTypes: updatedSelectedMediaTypesObj
    }));
  };

  const handleUpdatedResults = async (resultObj, exitAfterSave) => {
    if (resultObj !== null && resultObj.error) {
      const errDesc = await getMessageFromCode(resultObj.error);
      setData((prev) => ({
        ...prev,
        throwError: true,
        throwErrorMessage: errDesc[0]?.descr
      }));
      setIsLoading(false);
    } else {
      const updatedVaultInfo = { ...data.selectedVaultInfo };

      if (resultObj !== null) {
        updatedVaultInfo["timestamp"] = resultObj.timestamp;
      }

      logicalVaultDetailForm.setValues((prev) => ({
        ...prev,
        vaultMediaTypes: noValue
      }));

      const updatedVaultStoredValues = {
        ...logicalVaultDetailForm.values
      };
      updatedVaultStoredValues["vaultMediaTypes"] = noValue;

      setData((prev) => ({
        ...prev,
        selectedVaultInfo: updatedVaultInfo,
        vaultStoredValues: updatedVaultStoredValues,
        vaultSuccessMessage: t("logicalVaultDetails.updatedSuccessMessage")
      }));
      setIsLoading(false);
      if (exitAfterSave) {
        confirmNavigation();
        dispatch(setHaveChanges(false));
        return;
      }
    }
  };

  const handleSave = async (exitAfterSave = false) => {
    let errors = handleValidate();

    if (errors === "") {
      try {
        setIsLoading(true);

        if (data.isVaultNameNew) {
          if (logicalVaultDetailForm?.values?.vaultHandlingItem === none) {
            logicalVaultDetailForm.values.vaultHandlingItem = "";
          }
          if (logicalVaultDetailForm?.values?.vaultingCatalogItem === none) {
            logicalVaultDetailForm.values.vaultingCatalogItem = "";
          }

          let result = await createLogicalVaultRecord(
            mainDistrictId,
            customer.customerId,
            logicalVaultDetailForm
          );
          if (result) {
            if (result.data[0][0].error) {
              const errDesc = await getMessageFromCode(result.data[0][0].error);
              setData((prev) => ({
                ...prev,
                throwError: true,
                throwErrorMessage: errDesc[0]?.descr
              }));
            } else {
              const vaultResponse = result.data[0][0];
              await handleUpdatingMediaTypes(
                vaultResponse.logical_vault_id
              ).then((updatedTimeStamp) => {
                if (updatedTimeStamp !== "") {
                  setUpdatedMediaTypes();
                }
              });

              const newVaultCode = logicalVaultDetailForm.values.vaultName;
              const updatedVaults = [
                ...data.vaults,
                {
                  code: newVaultCode,
                  logical_vault_id: vaultResponse.logical_vault_id
                }
              ];

              setData((prev) => ({
                ...prev,
                isVaultNameNew: false,
                vaultSuccessMessage: t("logicalVaultDetails.addSuccessMessage"),
                vaults: updatedVaults,
                selectedVault: vaultResponse.logical_vault_id
              }));

              logicalVaultDetailForm.values.vaultName =
                vaultResponse.logical_vault_id;
            }
          }
        } else {
          if (logicalVaultDetailForm?.values?.vaultHandlingItem === none) {
            logicalVaultDetailForm.values.vaultHandlingItem = "";
          }

          const diffKeys = checkDiffObjects(
            logicalVaultDetailForm.values,
            data.vaultStoredValues
          );

          if (
            diffKeys.length === 1 &&
            diffKeys[0] === t("logicalVaultDetails.vaultMediaFieldName")
          ) {
            await handleUpdatingMediaTypes(data.selectedVault).then(
              (updatedTimeStamp) => {
                if (updatedTimeStamp !== "") {
                  const resultObj = { timestamp: updatedTimeStamp };
                  handleUpdatedResults(resultObj, exitAfterSave);
                  setUpdatedMediaTypes();
                }
              }
            );
          } else {
            await handleUpdatingMediaTypes(data.selectedVault).then(
              async (updatedTimeStamp) => {
                if (updatedTimeStamp !== "") {
                  let result = await updateLogicalVaultRecord(
                    mainDistrictId,
                    updatedTimeStamp,
                    logicalVaultDetailForm
                  );
                  if (result) {
                    setUpdatedMediaTypes();
                    handleUpdatedResults(result?.data[0][0], exitAfterSave);
                  }
                }
              }
            );
          }
        }
      } catch (e) {
        setData((prev) => ({
          ...prev,
          throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
          throwError: true
        }));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const loadActiveCatalogItems = async (customerId) => {
    try {
      let result = await getActiveCatalogItems(mainDistrictId, customerId);

      if (result) {
        setData((prev) => ({ ...prev, activeCatalogItems: result?.data[0] }));
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    }
  };

  const loadHandlingCatalogItems = async (customerId) => {
    try {
      let result = await getHandlingCatalogItems(mainDistrictId, customerId);

      if (result) {
        let sortedItems = [];
        if (result?.data[0].length > 0) {
          sortedItems = result?.data[0].sort((a, b) => {
            if (a["catalog_item_code"] < b["catalog_item_code"]) {
              return -1;
            }
            if (a["catalog_item_code"] > b["catalog_item_code"]) {
              return 1;
            }
            return 0;
          });
        }
        setData((prev) => ({ ...prev, handlingCatalogItems: sortedItems }));
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    }
  };

  const handleErrorModalCancel = () => {
    setData((prev) => ({
      ...prev,
      throwErrorMessage: "",
      throwError: false
    }));
  };
  const onDownloadClick = async () => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        logical_vault_id: data.selectedVaultInfo?.logical_vault_id,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      };
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.logicalVaultDetail.printVault}`
      );

      if (!reportResponse.success) {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(reportResponse.error);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteOk = async () => {
    try {
      setIsLoading(true);
      setData((prev) => ({
        ...prev,
        isVaultDelete: false
      }));
      let result = await deleteLogicalVault(mainDistrictId, data.selectedVault);

      if (result.data[0][0].error === logicalVaultConstants.valueZero) {
        logicalVaultDetailForm.resetForm();
        setData((prev) => ({
          ...prev,
          vaults: [],
          selectedVault: "",
          selectedVaultInfo: vaultDefaultObj,
          isUnsavedVaultChange: false,
          vaultStoredValues: logicalVaultDetailForm.values,
          unsavedObj: { label: "", values: "" },
          vaultSuccessMessage: t("logicalVaultDetails.deletedSuccessMessage")
        }));

        setCustomer({
          customer: "",
          customerId: ""
        });
      } else {
        const errDesc = await getMessageFromCode(result.data[0][0].error);

        setData((prev) => ({
          ...prev,
          isVaultDelete: false,
          throwErrorMessage: errDesc[0]?.descr,
          throwError: true
        }));
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setData((prev) => ({
      ...prev,
      isVaultDelete: false
    }));
  };

  const handleRenameClose = () => {
    setData((prev) => ({
      ...prev,
      isVaultRename: false
    }));
  };

  const handleRenameSave = async (newCode) => {
    try {
      setIsLoading(true);
      setData((prev) => ({
        ...prev,
        isVaultRename: false
      }));

      let result = await renameLogicalVault(
        mainDistrictId,
        data.selectedVault,
        newCode,
        data.selectedVaultInfo.timestamp
      );

      if (result) {
        if (result.data[0][0].error) {
          const errDesc = await getMessageFromCode(result.data[0][0].error);
          setData((prev) => ({
            ...prev,
            throwError: true,
            throwErrorMessage: errDesc[0]?.descr,
            isVaultRename: false
          }));
        } else if (result.data[0][0].timestamp) {
          const newUpdatedObj = data.vaults.map((e) => {
            if (e.logical_vault_id === data.selectedVault) {
              e.code = newCode;
            }
            return e;
          });

          logicalVaultDetailForm.setValues((prev) => ({
            ...prev,
            vaultDesc: result.data[0][0]["invoice_descr"].trim()
          }));

          const updatedVaultInfo = { ...data.selectedVaultInfo };
          updatedVaultInfo["timestamp"] = result?.data[0][0].timestamp;

          const updatedVaultStoredValues = { ...data.vaultStoredValues };
          updatedVaultStoredValues["vaultDesc"] =
            result?.data[0][0]["invoice_descr"].trim();

          setData((prev) => ({
            ...prev,
            vaults: newUpdatedObj,
            selectedVaultInfo: updatedVaultInfo,
            vaultStoredValues: updatedVaultStoredValues,
            selectedVault: logicalVaultDetailForm.values.vaultName,
            vaultSuccessMessage: t("logicalVaultDetails.updatedSuccessMessage")
          }));
        }
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customer.customerId !== "") {
      loadVaultDetails(customer.customerId);
    }

    if (customer.customerId !== "") {
      loadActiveCatalogItems(customer.customerId);
      loadHandlingCatalogItems(customer.customerId);
    }

    setData((prev) => ({
      ...prev,
      throwErrorMessage: "",
      throwError: false
    }));
    // eslint-disable-next-line
  }, [customer.customerId]);

  const verifyOpenMediaAudits = async (selectedVault) => {
    try {
      let result = await getOpenMediaAuditsCheck(mainDistrictId, selectedVault);

      if (
        result?.data[0][0]["audit_active_bit"] ===
        logicalVaultConstants.valueZero
      ) {
        setData((prev) => ({ ...prev, activeMediaAuditForVault: false }));
      } else if (
        result?.data[0][0]["audit_active_bit"] ===
        logicalVaultConstants.valueOne
      ) {
        setData((prev) => ({ ...prev, activeMediaAuditForVault: true }));
      }
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleMediaClickItem = () => {
    logicalVaultDetailForm.setValues((prev) => ({
      ...prev,
      vaultMediaTypes: yesValue
    }));

    if (checkCapacityError(null)) {
      return;
    }
  };

  const handleMediaTypesChecked = (vaultPickedMediaTypes) => {
    const selectedMediaTypesNames = data.selectedMediaTypes.map(
      (obj) => obj.descr
    );
    const toDeleteMediaTypes = selectedMediaTypesNames.filter(
      (m) => !vaultPickedMediaTypes.includes(m)
    );

    const toAddMediaTypes = vaultPickedMediaTypes.filter(
      (e) => !selectedMediaTypesNames.includes(e)
    );

    const unionMediaTypes = [
      ...data.availableMediaTypes,
      ...data.selectedMediaTypes
    ];

    const toDeleteMediaTypesObj = unionMediaTypes.filter((f) =>
      toDeleteMediaTypes.includes(f.descr)
    );
    const toAddMediaTypesObj = unionMediaTypes.filter((f) =>
      toAddMediaTypes.includes(f.descr)
    );

    setData((prev) => ({
      ...prev,
      vaultDeletedMediaTypes: toDeleteMediaTypesObj,
      vaultAddedMediaTypes: toAddMediaTypesObj
    }));
  };

  const handleMediaClickRightToLeft = async (mediaAssigned) => {
    if (mediaAssigned.length > 0) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode25104);
      setData((prev) => ({
        ...prev,
        throwErrorMessage: errDesc[0]?.descr,
        throwError: true
      }));
    }
  };

  const handleAddingMediaTypes = async (mediaTypeId, vaultId) => {
    try {
      let result = await addMediaTypes(mainDistrictId, vaultId, mediaTypeId);
      return result.data[1][0].logical_vault_timestamp;
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
      return null;
    }
  };

  const handleDeletingMediaTypes = async (mediaTypeId) => {
    try {
      let result = await deleteMediaTypes(
        mainDistrictId,
        data.selectedVault,
        mediaTypeId
      );
      return result.data[0][0].logical_vault_timestamp;
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
      return null;
    }
  };

  const handleUpdatingMediaTypes = async (vaultId) => {
    let updatedTimeStamp = data.selectedVaultInfo.timestamp;

    if (data.vaultAddedMediaTypes.length !== 0) {
      for (let i in data.vaultAddedMediaTypes) {
        updatedTimeStamp = await handleAddingMediaTypes(
          data.vaultAddedMediaTypes[i].media_type_id,
          vaultId
        );
      }
    }

    if (data.vaultDeletedMediaTypes.length !== 0) {
      for (let i in data.vaultDeletedMediaTypes) {
        updatedTimeStamp = await handleDeletingMediaTypes(
          data.vaultDeletedMediaTypes[i].media_type_id
        );
      }
    }

    return updatedTimeStamp;
  };

  useEffect(() => {
    const rEnabled =
      canEdit &&
      logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
      getReviewMonthlyState(logicalVaultDetailForm.values.vaultHandlingItem);

    setData((prev) => ({
      ...prev,
      reviewMonthlyEnabled: rEnabled
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logicalVaultDetailForm.values.vaultHandlingItem]);

  useEffect(() => {
    const vEnabled =
      canEdit &&
      logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
      logicalVaultDetailForm.values.vaultMediaTracking !== yesValue &&
      logicalVaultDetailForm.values.vaultSlotAssignment === valueI &&
      programType323;

    setData((prev) => ({
      ...prev,
      verificationReqEnabled: vEnabled
    }));

    if (!checkEqualValuesforFormUpdate()) {
      if (logicalVaultDetailForm.values.vaultMediaTracking === yesValue) {
        logicalVaultDetailForm.setValues((prev) => ({
          ...prev,
          vaultSlotAssignment: "",
          vaultCapacityNotify: ""
        }));
      }

      if (logicalVaultDetailForm.values.vaultMediaTracking === noValue) {
        logicalVaultDetailForm.setValues((prev) => ({
          ...prev,
          vaultSlotAssignment: valueI,
          vaultCapacityNotify: logicalVaultConstants.defaultCapacityValue
        }));
      }

      if (logicalVaultDetailForm.values.vaultProgramType !== valueType5) {
        logicalVaultDetailForm.setValues((prev) => ({
          ...prev,
          vaultSlotAssignment: "",
          vaultCapacityNotify: "",
          vaultMediaTracking: "",
          vaultDuration: ""
        }));
      }

      if (
        logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
        logicalVaultDetailForm.values.vaultMediaTracking === ""
      ) {
        logicalVaultDetailForm.setValues((prev) => ({
          ...prev,
          vaultSlotAssignment:
            logicalVaultDetailForm.initialValues.vaultSlotAssignment,
          vaultCapacityNotify:
            logicalVaultDetailForm.initialValues.vaultCapacityNotify,
          vaultMediaTracking:
            logicalVaultDetailForm.initialValues.vaultMediaTracking,
          vaultDuration: logicalVaultDetailForm.initialValues.vaultDuration
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logicalVaultDetailForm.values.vaultMediaTracking,
    logicalVaultDetailForm.values.vaultProgramType
  ]);

  useEffect(() => {
    const vEnabled =
      canEdit &&
      logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
      logicalVaultDetailForm.values.vaultMediaTracking !== yesValue &&
      logicalVaultDetailForm.values.vaultSlotAssignment === valueI &&
      programType323;

    setData((prev) => ({
      ...prev,
      verificationReqEnabled: vEnabled
    }));

    if (!checkEqualValuesforFormUpdate()) {
      if (
        logicalVaultDetailForm.values.vaultSlotAssignment ===
        logicalVaultConstants.valueN
      ) {
        logicalVaultDetailForm.setValues((prev) => ({
          ...prev,
          vaultDistribution: [],
          vaultInventory: []
        }));
      }

      if (
        logicalVaultDetailForm.values.vaultSlotAssignment ===
        logicalVaultConstants.valueC
      ) {
        logicalVaultDetailForm.setValues((prev) => ({
          ...prev,
          vaultDistribution: ["VR"],
          vaultInventory: [logicalVaultConstants.yesValue]
        }));
      }

      if (
        logicalVaultDetailForm.values.vaultSlotAssignment ===
        logicalVaultConstants.valueI
      ) {
        logicalVaultDetailForm.setValues((prev) => ({
          ...prev,
          vaultDistribution: ["VR"],
          vaultInventory: []
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logicalVaultDetailForm.values.vaultSlotAssignment]);

  const activeAuditMediaCheck = async (activeMedAudit) => {
    if (activeMedAudit) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode80225);
      setData((prev) => ({
        ...prev,
        throwErrorMessage: errDesc[0]?.descr,
        throwError: true
      }));
    }
  };

  useEffect(() => {
    activeAuditMediaCheck(data.activeMediaAuditForVault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.activeMediaAuditForVault]);

  const mediaTrackingEnabled =
    programType285 &&
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    data.selectedVaultInfo.media_assigned_flag === noValue;
  const durationEnabled =
    canEdit && logicalVaultDetailForm.values.vaultProgramType === valueType5;
  const capacityEnabled =
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    logicalVaultDetailForm.values.vaultMediaTracking !== yesValue;
  const hoursEnabled =
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    logicalVaultDetailForm.values.vaultHandlingItem !== "" &&
    data.handlingCatalogItems.filter(
      (e) =>
        e.catalog_item_code === logicalVaultDetailForm.values.vaultHandlingItem
    )[0]?.handle_type_code === logicalVaultConstants.catalogTypeHourly;
  const vaultingEnabled = data.isVaultNameNew
    ? canEdit &&
      logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
      data.vaultAddedMediaTypes.length !== 0 &&
      data.activeCatalogItems.length > 0
    : canEdit &&
      logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
      data.activeCatalogItems.length > 0;
  const billAllActiveVolsersEnabled =
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    logicalVaultDetailForm.values.vaultMediaTracking === noValue &&
    logicalVaultDetailForm.values.vaultingCatalogItem !== "";

  const handlingItemEnabled =
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    data.handlingCatalogItems.length > 0;
  const interactiveEnabled =
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    logicalVaultDetailForm.values.vaultSlotAssignment !== valueN &&
    logicalVaultDetailForm.values.vaultMediaTracking !== yesValue &&
    logicalVaultDetailForm.values.vaultDistribution.indexOf(valueVR) !== -1;

  const inventoryEnabled =
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    logicalVaultDetailForm.values.vaultSlotAssignment !== valueN &&
    logicalVaultDetailForm.values.vaultSlotAssignment !== valueC &&
    logicalVaultDetailForm.values.vaultMediaTracking !== yesValue;

  const shelveRedirection = () => {
    if (handleUnsavedChanges("", t("common.shelves"))) {
      return;
    }

    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH}/${ASSIGN_SHELVES}`,
      {
        state: {
          vaultDetails: {
            ...data.selectedVaultInfo,
            code:
              data.vaultStoredValues.vaultDesc || data.selectedVaultInfo.code
          }
        }
      }
    );
  };
  const vaultProgramTypeEnabled = canEdit && data.isVaultNameNew;
  const turnRoundingOffEnabled =
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    logicalVaultDetailForm.values.vaultMediaTracking === noValue &&
    logicalVaultDetailForm.values.vaultingCatalogItem !== "";
  const roundingOffEnabled =
    canEdit &&
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    logicalVaultDetailForm.values.vaultMediaTracking === noValue &&
    logicalVaultDetailForm.values.vaultingCatalogItem !== "" &&
    !logicalVaultDetailForm.values.vaultTurnRoundingFlag.includes(yesValue);
  const shelvesButtonDisabled =
    data.selectedVault === "" ||
    data.isVaultNameNew ||
    logicalVaultDetailForm.values.vaultProgramType !== valueType5;
  const notesEnabled =
    logicalVaultDetailForm.values.vaultProgramType === valueType5 &&
    logicalVaultDetailForm.values.vaultMediaTracking === noValue;

  const handleCloseSuccessMessage = () => {
    setData((prev) => ({
      ...prev,
      vaultSuccessMessage: ""
    }));
  };

  const checkEqualValuesforFormNew = () => {
    const obj1 = logicalVaultDetailForm.initialValues;
    const obj2 = logicalVaultDetailForm.values;
    const obj1Keys = Object.keys(obj1).sort();
    const obj2Keys = Object.keys(obj2).sort();
    let isShow = obj1Keys.every((key, index) => {
      const objValue1 = obj1[key];
      const objValue2 = obj2[obj2Keys[index]];
      return objValue1 === objValue2;
    });
    dispatch(setHaveChanges(!isShow));
    return isShow;
  };

  const checkEqualValuesforFormUpdate = () => {
    const obj1 = logicalVaultDetailForm.values;
    const obj2 = data.vaultStoredValues;
    const obj1Keys = Object.keys(obj1).sort();
    const obj2Keys = Object.keys(obj2).sort();
    let isShow = obj1Keys.every((key, index) => {
      const objValue1 = obj1[key];
      const objValue2 = obj2[obj2Keys[index]];
      return objValue1 === objValue2;
    });
    dispatch(setHaveChanges(!isShow));
    return isShow;
  };

  const handleVaultNameBlur = (e) => {
    if (logicalVaultDetailForm.values.vaultDesc === "") {
      const vaultNameText = data.isVaultNameNew
        ? logicalVaultDetailForm.values.vaultName.toLowerCase()
        : data.vaults.filter(
            (e) =>
              e.logical_vault_id === logicalVaultDetailForm.values.vaultName
          )[0].code;
      logicalVaultDetailForm.setValues((prevValues) => ({
        ...prevValues,
        vaultDesc: vaultNameText
      }));
    }
  };

  const handleUnsavedNo = () => {
    if (data.unsavedObj.type === t("logicalVaultDetails.customer")) {
      const newValue = {
        label: data.unsavedObj.values?.label,
        value: data.unsavedObj.values.value
      };
      if (newValue) {
        setCustomer({
          customer: newValue?.label,
          customerId: newValue?.value
        });
      }

      setData((prev) => ({
        ...prev,
        isUnsavedVaultChange: false,
        unsavedObj: { type: "", values: "" },
        vaultStoredValues: logicalVaultDetailForm.initialValues
      }));

      logicalVaultDetailForm.setValues(logicalVaultDetailForm.initialValues);
    }

    if (data.unsavedObj.type === t("logicalVaultDetails.vaultName")) {
      setData((prev) => ({
        ...prev,
        selectedVault: data.unsavedObj.values,
        isUnsavedVaultChange: false,
        unsavedObj: { type: "", values: "" }
      }));

      logicalVaultDetailForm.setValues((prevValues) => ({
        ...prevValues,
        vaultName: data.unsavedObj.values.value
      }));
    }

    if (data.unsavedObj.type === t("common.new")) {
      setData((prev) => ({
        ...prev,
        isVaultNameNew: true,
        selectedMediaTypes: [],
        selectedVault: "",
        selectedVaultInfo: vaultDefaultObj,
        isUnsavedVaultChange: false
      }));

      setCustomer({
        customer: "",
        customerId: ""
      });
      logicalVaultDetailForm.resetForm();
    }

    if (data.unsavedObj.type === t("common.rename")) {
      loadSelectedMediaTypes(data.selectedVault);
      loadLogicalVaultInfo(data.selectedVault);

      setData((prev) => ({
        ...prev,
        isVaultRename: true,
        isUnsavedVaultChange: false
      }));
    }

    if (data.unsavedObj.type === t("common.shelves")) {
      setData((prev) => ({
        ...prev,
        isUnsavedVaultChange: false
      }));

      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH}/${ASSIGN_SHELVES}`,
        {
          state: {
            vaultDetails: data.selectedVaultInfo
          }
        }
      );
    }

    if (data.unsavedObj.type === t("common.cancel")) {
      logicalVaultDetailForm.resetForm();
      setData((prev) => ({
        ...prev,
        isVaultNameNew: false,
        vaults: [],
        selectedVault: "",
        selectedVaultInfo: vaultDefaultObj,
        isUnsavedVaultChange: false,
        unsavedObj: { label: "", values: "" },
        vaultStoredValues: logicalVaultDetailForm.values
      }));

      setCustomer((values) => ({ customer: "", customerId: "" }));
    }
  };

  const handleUnsavedYes = () => {
    handleSave();

    if (data.unsavedObj.type === t("common.rename")) {
      setData((prev) => ({
        ...prev,
        isVaultRename: true,
        isUnsavedVaultChange: false,
        unsavedObj: { type: "", values: "" }
      }));
    }

    if (data.unsavedObj.type === t("common.shelves")) {
      setData((prev) => ({
        ...prev,
        isUnsavedVaultChange: false,
        unsavedObj: { type: "", values: "" }
      }));

      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH}/${ASSIGN_SHELVES}`,
        {
          state: {
            vaultDetails: data.selectedVaultInfo
          }
        }
      );
    }

    if (
      data.unsavedObj.type !== t("common.rename") &&
      data.unsavedObj.type !== t("common.shelves")
    ) {
      setData((prev) => ({
        ...prev,
        isUnsavedVaultChange: false,
        unsavedObj: { type: "", values: "" }
      }));
    }
  };

  const handleUnsavedCancel = () => {
    setData((prev) => ({
      ...prev,
      isUnsavedVaultChange: false,
      unsavedObj: { label: "", values: "" }
    }));
  };

  const checkCapacityError = (e) => {
    if (
      isNaN(data.vaultCapacityState) ||
      parseInt(data.vaultCapacityState) >
        logicalVaultConstants.capacityMaxLength ||
      parseInt(data.vaultCapacityState) <
        logicalVaultConstants.capacityMinLength
    ) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: data.errorMessages.capacityRequiredAlert,
        throwError: true
      }));
      document.getElementById("vaultCapacityNotify").focus();
      if (e !== null) {
        e.preventDefault();
        e.stopPropagation();
      }
      return true;
    } else {
      return false;
    }
  };

  const handleCapacityBlur = (e) => {
    logicalVaultDetailForm.setValues((prev) => ({
      ...prev,
      vaultCapacityNotify: data.vaultCapacityState
    }));

    checkCapacityError(e);
  };

  const handleCapacityChange = (e) => {
    const capVal =
      e.target.value === ""
        ? logicalVaultConstants.defaultCapacityValue
        : e.target.value;
    setData((prev) => ({
      ...prev,
      vaultCapacityState: capVal
    }));
    logicalVaultDetailForm.setValues((prev) => ({
      ...prev,
      vaultCapacityNotify: e.target.value
    }));
  };

  const isDisplayVaultFields = data.selectedVault !== "" || data.isVaultNameNew;

  const handleDuplicateVaultCheck = () => {
    const vaultNameValue = logicalVaultDetailForm.values.vaultName;

    if (vaultNameValue === "" || customer.customerId === "") {
      return;
    }

    const convertedName = vaultNameValue.trim().toUpperCase();
    const filteredVault = data.vaults.filter((e) => {
      const code = e.code.trim().toUpperCase();
      return code === convertedName;
    }).length;

    if (filteredVault > 0) {
      setData((prev) => ({
        ...prev,
        isDuplicateVaultName: true
      }));
    }
  };

  const handleDuplicateVaultYes = () => {
    const vaultNameValue = logicalVaultDetailForm.values.vaultName;
    const vaultObj = data.vaults.filter(
      (e) => e.code.trim().toLowerCase() === vaultNameValue.trim().toLowerCase()
    )[0];

    setData((prev) => ({
      ...prev,
      selectedVault: vaultObj.logical_vault_id,
      isDuplicateVaultName: false,
      isVaultNameNew: false
    }));
  };

  const handleDuplicateVaultNo = () => {
    logicalVaultDetailForm.values.vaultName = "";
    setData((prev) => ({
      ...prev,
      isDuplicateVaultName: false
    }));
  };

  const handleSlotAssignmentNo = () => {
    setData((prev) => ({
      ...prev,
      vaultSlotAssignmentCustomer: ""
    }));
  };

  const handleSlotAssignmentYes = () => {
    logicalVaultDetailForm.setValues((prev) => ({
      ...prev,
      vaultSlotAssignment: data.vaultSlotAssignmentCustomer
    }));

    setData((prev) => ({
      ...prev,
      vaultSlotAssignmentCustomer: ""
    }));
  };

  const handleHoursValidation = (e) => {
    if (isNaN(e.target.value)) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: data.errorMessages.hoursRequiredAlert,
        throwError: true
      }));
    }
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      {data.throwError && (
        <Box my={spacing.gap}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={handleErrorModalCancel}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {data.throwErrorMessage}
          </Alert>
        </Box>
      )}
      {data.vaultSuccessMessage && (
        <SuccessPopup
          message={data.vaultSuccessMessage}
          close={handleCloseSuccessMessage}
        />
      )}
      {/* Save Changes COnfirmation Modal */}
      <ConfirmPopup
        modalPopupOpen={showPrompt}
        handleYes={() => {
          handleSave(true);
        }}
        handleNo={() => {
          confirmNavigation();
          dispatch(setHaveChanges(false));
        }}
        showNo={true}
        handleCancel={() => {
          cancelNavigation();
          dispatch(setHaveChanges(false));
        }}
        showCancel={true}
      />
      <Grid container spacing={2} alignItems="">
        <Grid item md={6} lg={3} xs={12}>
          <CustomersField {...customersFieldProps} />
        </Grid>

        <Grid item md={6} lg={3} xs={12}>
          {data.isVaultNameNew ? (
            <TextField
              label={`${t("logicalVaultDetails.vaultName")} *`}
              id={"vaultName"}
              name={"vaultName"}
              value={logicalVaultDetailForm.values.vaultName}
              onChange={handleFieldChange}
              onBlur={handleDuplicateVaultCheck}
              inputProps={logicalVaultDetailesStyles.descProps}
              error={
                logicalVaultDetailForm.values.vaultName === "" &&
                !checkEqualValuesforFormNew()
              }
              helperText={
                logicalVaultDetailForm.values.vaultName === "" &&
                !checkEqualValuesforFormNew()
                  ? t("logicalVaultDetails.errMsg1")
                  : " "
              }
            />
          ) : !data.isVaultNameNew && customer.customer !== "" ? (
            <FormControl fullWidth>
              <InputLabel id={"vaultNameLabel"}>
                {`${t("logicalVaultDetails.vaultName")} *`}
              </InputLabel>
              <Select
                labelId={"vaultNameLabel"}
                id={"vaultName"}
                name={"vaultName"}
                value={data.selectedVault}
                label={`${t("logicalVaultDetails.vaultName")} *`}
                onChange={handleVaultChange}
              >
                {data.vaults.map((e, index) => {
                  return (
                    <MenuItem
                      key={`vault_${index}`}
                      value={e.logical_vault_id}
                      selected={e.logical_vault_id === data.selectedVault}
                    >
                      {e.code.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            ""
          )}
        </Grid>

        {isDisplayVaultFields && (
          <Grid item md={6} lg={3} xs={12}>
            <TextField
              label={t("logicalVaultDetails.description")}
              id="vaultDesc"
              name="vaultDesc"
              value={logicalVaultDetailForm.values.vaultDesc}
              disabled={!canEdit}
              onBlur={handleVaultNameBlur}
              onChange={handleFieldChange}
            />
          </Grid>
        )}
      </Grid>
      <br />
      {isDisplayVaultFields && (
        <Grid container spacing={2} alignItems="">
          <Grid item md={6} lg={3} xs={12}>
            <FormControl>
              <FormLabel id="vaultProgramTypeLabel" required>
                {t("logicalVaultDetails.programType")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="vaultProgramTypeLabel"
                name="vaultProgramType"
                id="vaultProgramType"
                value={logicalVaultDetailForm.values.vaultProgramType}
                onChange={handleFieldChange}
              >
                <FormControlLabel
                  disabled={!vaultProgramTypeEnabled}
                  value={valueType3}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultProgramType ===
                        valueType3
                      }
                    />
                  }
                  label={t("logicalVaultDetails.container")}
                />
                <FormControlLabel
                  disabled={!vaultProgramTypeEnabled}
                  value={valueType5}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultProgramType ===
                        valueType5
                      }
                    />
                  }
                  label={t("logicalVaultDetails.openMedia")}
                />
                <FormControlLabel
                  disabled={!vaultProgramTypeEnabled}
                  value={valueType4}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultProgramType ===
                        valueType4
                      }
                    />
                  }
                  label={t("logicalVaultDetails.transport")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            <FormControl>
              <FormLabel id="vaultDurationLabel" required>
                {t("logicalVaultDetails.duration")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="vaultDurationLabel"
                value={logicalVaultDetailForm.values.vaultDuration}
                name="vaultDuration"
                id="vaultDuration"
                onChange={handleFieldChange}
              >
                <FormControlLabel
                  value={noValue}
                  disabled={!durationEnabled}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultDuration === noValue
                      }
                    />
                  }
                  label={t("logicalVaultDetails.active")}
                />
                <FormControlLabel
                  value={yesValue}
                  disabled={!durationEnabled}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultDuration === yesValue
                      }
                    />
                  }
                  label={t("logicalVaultDetails.longTerm")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            <FormControl>
              <FormLabel id="mediaTrackingLabel" required>
                {t("logicalVaultDetails.mediaTracking")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="mediaTrackingLabel"
                value={logicalVaultDetailForm.values.vaultMediaTracking}
                name="vaultMediaTracking"
                id="vaultMediaTracking"
                onChange={handleFieldChange}
              >
                <FormControlLabel
                  value={noValue}
                  disabled={!mediaTrackingEnabled}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultMediaTracking ===
                        noValue
                      }
                    />
                  }
                  label={t("logicalVaultDetails.automation")}
                />
                <FormControlLabel
                  value={yesValue}
                  disabled={!mediaTrackingEnabled}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultMediaTracking ===
                        yesValue
                      }
                    />
                  }
                  label={t("logicalVaultDetails.manual")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {isDisplayVaultFields && <br />}
      {isDisplayVaultFields && <Divider />}
      {isDisplayVaultFields && <br />}
      {isDisplayVaultFields && (
        <label>
          <b>{t("logicalVaultDetails.mediaTypes")}*</b>
        </label>
      )}
      {isDisplayVaultFields && <br />}
      {isDisplayVaultFields && (
        <Grid item md={6} lg={3} xs={12}>
          <TransferListItem
            customerId={customer.customerId}
            capacityState={data.vaultCapacityState}
            handleMediaClickItem={handleMediaClickItem}
            handleMediaTypesChecked={handleMediaTypesChecked}
            availableMediaTypes={data.availableMediaTypes}
            selectedMediaTypes={data.selectedMediaTypes}
            handleMediaClickRightToLeft={handleMediaClickRightToLeft}
          />
          <input
            type="hidden"
            name={"vaultMediaTypes"}
            id={"vaultMediaTypes"}
            value={logicalVaultDetailForm.values.vaultMediaTypes}
          />
        </Grid>
      )}
      {isDisplayVaultFields && <br />}
      {isDisplayVaultFields && <Divider />}
      {isDisplayVaultFields && <br />}
      {isDisplayVaultFields && (
        <Grid container spacing={2} alignItems="">
          <Grid item md={6} lg={3} xs={12}>
            <FormControl>
              <FormLabel id="slotAssignmentLabel" required>
                {t("logicalVaultDetails.slotAssignment")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="slotAssignmentLabel"
                value={logicalVaultDetailForm.values.vaultSlotAssignment}
                name="vaultSlotAssignment"
                id="vaultSlotAssignment"
                onChange={handleSlotAssignmentChange}
              >
                <FormControlLabel
                  value={valueI}
                  disabled={!mediaTrackingEnabled}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultSlotAssignment ===
                        valueI
                      }
                    />
                  }
                  label={t("logicalVaultDetails.imosdp")}
                />
                <FormControlLabel
                  value={valueC}
                  disabled={!mediaTrackingEnabled}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultSlotAssignment ===
                        valueC
                      }
                    />
                  }
                  label={t("logicalVaultDetails.customer")}
                />
                <FormControlLabel
                  value={valueN}
                  disabled={!mediaTrackingEnabled}
                  control={
                    <Radio
                      checked={
                        logicalVaultDetailForm.values.vaultSlotAssignment ===
                        valueN
                      }
                    />
                  }
                  label={t("logicalVaultDetails.none")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item md={6} lg={3} xs={12}>
            <FormControl>
              <FormLabel id="vaultDistributionLabel" required>
                {t("logicalVaultDetails.distribution")}
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    id="vaultDistribution"
                    name={"vaultDistribution"}
                    value={valueVR}
                    disabled={!data.verificationReqEnabled}
                    checked={
                      logicalVaultDetailForm.values.vaultDistribution.indexOf(
                        valueVR
                      ) !== -1
                    }
                  />
                }
                label={t("logicalVaultDetails.verificationRequired")}
                onChange={handleCheckBoxChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="vaultDistribution"
                    name={"vaultDistribution"}
                    value={valueRI}
                    disabled={!interactiveEnabled}
                    checked={
                      logicalVaultDetailForm.values.vaultDistribution.indexOf(
                        valueRI
                      ) !== -1
                    }
                  />
                }
                label={t("logicalVaultDetails.requiredInteractiveScan")}
                onChange={handleCheckBoxChange}
              />
            </FormControl>
          </Grid>

          <Grid item md={6} lg={3} xs={12}>
            <FormControl>
              <FormLabel id="vaultInventoryLabel" required>
                {t("logicalVaultDetails.inventory")}
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    name="vaultInventory"
                    id="vaultInventory"
                    disabled={!inventoryEnabled}
                    value={yesValue}
                    checked={logicalVaultDetailForm.values.vaultInventory.includes(
                      yesValue
                    )}
                  />
                }
                label={t("logicalVaultDetails.inventoryFileTransmitted")}
                onChange={handleCheckBoxChange}
              />
            </FormControl>
          </Grid>

          <Grid item md={6} lg={3} xs={12}>
            <FormControl>
              <FormLabel id="vaultCapacityNotifyLabel">
                {t("logicalVaultDetails.capacityNotification")}
              </FormLabel>
              <TextField
                id="vaultCapacityNotify"
                name={"vaultCapacityNotify"}
                value={logicalVaultDetailForm.values.vaultCapacityNotify}
                disabled={!capacityEnabled}
                onChange={handleCapacityChange}
                onBlur={handleCapacityBlur}
                inputProps={logicalVaultDetailesStyles.capacityProps}
                onKeyPress={(event) => {
                  if (!reg.test(event.key)) {
                    event.preventDefault(); // Prevent non-numeric key presses
                  }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {isDisplayVaultFields && <br />}
      {isDisplayVaultFields && (
        <Grid container spacing={3} alignItems="">
          <Grid item md={12} lg={6} xs={24}>
            <FormLabel required>{t("logicalVaultDetails.handling")}</FormLabel>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            <FormLabel required>{`${t(
              "logicalVaultDetails.vaulting"
            )}`}</FormLabel>
          </Grid>
        </Grid>
      )}
      {isDisplayVaultFields && <br />}
      {isDisplayVaultFields && (
        <Grid container spacing={3} alignItems="">
          <Grid item md={6} lg={3} xs={12}>
            <FormControl fullWidth>
              <>
                <InputLabel id="vaultHandlingLabel">
                  {`${t("logicalVaultDetails.catalogItem")}`}
                </InputLabel>
                <Select
                  labelId={"vaultHandlingLabel"}
                  name={"vaultHandlingItem"}
                  id={"vaultHandlingItem"}
                  label={`${t("logicalVaultDetails.catalogItem")}*`}
                  value={
                    logicalVaultDetailForm.values.vaultHandlingItem
                      ? logicalVaultDetailForm.values.vaultHandlingItem
                      : none
                  }
                  onChange={handleCatalogItemChange}
                  disabled={!handlingItemEnabled}
                >
                  <MenuItem value={none}>
                    {t("logicalVaultDetails.noneHandling")}
                  </MenuItem>
                  {data.handlingCatalogItems.map((e) => {
                    return e.handle_type_code ===
                      logicalVaultConstants.catalogTypeHourly ? (
                      <MenuItem value={e.catalog_item_code}>
                        {`${e.catalog_item_code} - ${t(
                          "logicalVaultDetails.hourly"
                        )}`}
                      </MenuItem>
                    ) : (
                      <MenuItem value={e.catalog_item_code}>
                        {`${e.catalog_item_code} - ${t(
                          "logicalVaultDetails.perTape"
                        )}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            </FormControl>
          </Grid>

          <Grid item md={2} lg={1} xs={4}>
            <TextField
              id={"vaultHandlingHours"}
              name={"vaultHandlingHours"}
              value={
                logicalVaultDetailForm.values.vaultHandlingHours ===
                logicalVaultConstants.valueZeroDecimal
                  ? ""
                  : hoursEnabled
                    ? logicalVaultDetailForm.values.vaultHandlingHours
                    : ""
              }
              disabled={!hoursEnabled}
              onChange={handleFieldChange}
              onBlur={handleHoursValidation}
              label={t("logicalVaultDetails.hours")}
              inputProps={logicalVaultDetailesStyles.hoursProps}
            />
          </Grid>

          <Grid item md={4} lg={2} xs={8}>
            <FormGroup>
              <FormControlLabel
                name={"vaultReviewMonthly"}
                id={"vaultReviewMonthly"}
                disabled={!data.reviewMonthlyEnabled}
                control={
                  <Checkbox
                    value={yesValue}
                    checked={logicalVaultDetailForm.values.vaultReviewMonthly.includes(
                      yesValue
                    )}
                  />
                }
                label={t("logicalVaultDetails.reviewMonthly")}
                onChange={handleCheckBoxChange}
              />
            </FormGroup>
          </Grid>

          <Grid item md={4} lg={2} xs={8}>
            <FormControl fullWidth>
              <InputLabel id="vaultingCatalogItemLabel">
                {t("logicalVaultDetails.catalogItem")}
              </InputLabel>
              <Select
                labelId={"vaultingCatalogItemLabel"}
                value={
                  logicalVaultDetailForm.values.vaultingCatalogItem !== ""
                    ? logicalVaultDetailForm.values.vaultingCatalogItem
                    : ""
                }
                id={"vaultingCatalogItem"}
                name={"vaultingCatalogItem"}
                disabled={!vaultingEnabled}
                onChange={handleFieldChange}
                label={t("logicalVaultDetails.catalogItem")}
              >
                {data.activeCatalogItems.map((e) => {
                  return (
                    <MenuItem
                      value={e.catalog_item_code}
                      key={e.catalog_item_code}
                    >
                      {e.catalog_item_code}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6} lg={4} xs={12}>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="start"
              gap={spacing.verticalMargin20}
            >
              <Grid item>
                {data.isTurnRoundOffAllowed === yesValue && (
                  <FormGroup>
                    <FormControlLabel
                      name={"vaultTurnRoundingFlag"}
                      id={"vaultTurnRoundingFlag"}
                      control={
                        <Checkbox
                          value={yesValue}
                          checked={logicalVaultDetailForm.values.vaultTurnRoundingFlag.includes(
                            yesValue
                          )}
                        />
                      }
                      label={t("logicalVaultDetails.turnRoundingOff")}
                      onChange={handleCheckBoxChange}
                      disabled={!turnRoundingOffEnabled}
                    />
                  </FormGroup>
                )}
                {data.isBillAllAllowed === yesValue &&
                  logicalVaultDetailForm.values.vaultMediaTracking ===
                    noValue && (
                    <FormGroup>
                      <FormControlLabel
                        name={"vaultBillAll"}
                        id={"vaultBillAll"}
                        disabled={!billAllActiveVolsersEnabled}
                        control={
                          <Checkbox
                            value={yesValue}
                            checked={logicalVaultDetailForm.values.vaultBillAll.includes(
                              yesValue
                            )}
                          />
                        }
                        label={t("logicalVaultDetails.billAllActiveVolsers")}
                        onChange={handleCheckBoxChange}
                      />
                    </FormGroup>
                  )}
              </Grid>
              <Grid item md={4} lg={2}>
                <TextField
                  type="number"
                  id={"vaultTurnRoundingValue"}
                  name={"vaultTurnRoundingValue"}
                  value={logicalVaultDetailForm.values.vaultTurnRoundingValue}
                  disabled={!roundingOffEnabled}
                  onChange={handleFieldChange}
                  label={t("logicalVaultDetails.value")}
                  inputProps={logicalVaultDetailesStyles.turnRoundingProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {isDisplayVaultFields && <br />}
      {isDisplayVaultFields && !data.isVaultNameNew && notesEnabled && (
        <Divider />
      )}

      {isDisplayVaultFields && !data.isVaultNameNew && notesEnabled && <br />}
      {isDisplayVaultFields && !data.isVaultNameNew && notesEnabled && (
        <label>
          <b>{t("logicalVaultDetails.serviceNotes")}*</b>
        </label>
      )}
      {isDisplayVaultFields && !data.isVaultNameNew && notesEnabled && <br />}
      {isDisplayVaultFields && !data.isVaultNameNew && notesEnabled && (
        <Grid container spacing={2} alignItems="">
          <Grid item md={12} lg={6} sx={4}>
            <TextField
              label={t("logicalVaultDetails.pickingServiceNotes")}
              name={"vaultServiceNotes"}
              id={"vaultServiceNotes"}
              value={logicalVaultDetailForm.values.vaultServiceNotes}
              fullWidth
              multiline
              rows={3}
              maxRows={4}
              onChange={handleFieldChange}
            />
          </Grid>
          <Grid item md={12} lg={6} sx={4}>
            <TextField
              label={t("logicalVaultDetails.distributionServiceNotes")}
              name={"vaultDistributionServiceNotes"}
              id={"vaultDistributionServiceNotes"}
              value={
                logicalVaultDetailForm.values.vaultDistributionServiceNotes
              }
              fullWidth
              multiline
              rows={3}
              maxRows={4}
              onChange={handleFieldChange}
            />
          </Grid>
        </Grid>
      )}

      {isDisplayVaultFields && <br />}
      <Divider />

      <br />
      <Typography>{t("logicalVaultDetails.note")}</Typography>
      <br />
      <Divider />
      <br />
      <Box sx={logicalVaultDetailesStyles.flexItem}>
        <Button
          variant="outlined"
          disabled={
            data.selectedVault === "" ||
            data.isVaultNameNew ||
            !checkEqualValuesforFormUpdate()
          }
          onClick={() => {
            setShowPrintModal(true);
          }}
        >
          {t("common.print")}
        </Button>
        {data.isVaultNameNew ? (
          <Button
            variant="outlined"
            onClick={handleCancel}
            disabled={data.selectedVault !== ""}
          >
            {t("common.cancel")}
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={handleDelete}
            disabled={
              data.selectedVault === "" || data.activeMediaAuditForVault
            }
          >
            {t("common.delete")}
          </Button>
        )}
        <Button
          variant="outlined"
          disabled={shelvesButtonDisabled}
          onClick={() => shelveRedirection()}
        >
          {t("common.shelves")}
        </Button>
        <Button
          variant="outlined"
          disabled={
            data.selectedVault === "" ||
            data.isVaultNameNew ||
            data.activeMediaAuditForVault
          }
          onClick={handleRename}
        >
          {t("common.rename")}
        </Button>
        <Button variant="outlined" onClick={handleNew}>
          {t("common.new")}
        </Button>
        <Button
          variant="contained"
          disabled={
            data.isVaultNameNew || data.selectedVault === ""
              ? checkEqualValuesforFormNew()
              : checkEqualValuesforFormUpdate()
          }
          onClick={handleSave}
        >
          {t("common.save")}
        </Button>
      </Box>
      <VaultDeleteModal
        bodyOpen={data.isVaultDelete}
        bodyMessage={
          logicalVaultDetailForm.values.vaultProgramType === valueType5
            ? data.errorMessages.deleteMediaAlert
            : data.errorMessages.deleteNonMediaAlert
        }
        title={""}
        handleDeleteYes={handleDeleteOk}
        handleDeleteNo={handleDeleteCancel}
      />
      <VaultRenameModal
        bodyOpen={data.isVaultRename}
        title={t("logicalVaultDetails.rename")}
        currentVaultName={getVaultCode()}
        handleRenameClose={handleRenameClose}
        handleRenameSave={handleRenameSave}
      />
      <ConfirmPopup
        modalPopupOpen={data.isUnsavedVaultChange}
        handleNo={handleUnsavedNo}
        handleYes={handleUnsavedYes}
        handleCancel={handleUnsavedCancel}
        showCancel={true}
        showNo={true}
      />
      <DownloadPopup
        throwError={showErrorAlert}
        setThrowError={setShowErrorAlert}
        throwErrorMessage={showErrorAlertMsg}
        setThrowErrorMessage={setShowErrorAlertMsg}
        title={t("common.downloadReport")}
        showPrintModal={showPrintModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => setShowPrintModal((prev) => !prev)}
        onDownloadClick={() => onDownloadClick()}
      />
      <ConfirmPopup
        modalPopupOpen={
          data.vaultSlotAssignmentCustomer !== "" || data.isDuplicateVaultName
        }
        handleNo={
          data.isDuplicateVaultName
            ? handleDuplicateVaultNo
            : handleSlotAssignmentNo
        }
        handleYes={
          data.isDuplicateVaultName
            ? handleDuplicateVaultYes
            : handleSlotAssignmentYes
        }
        showCancel={false}
        showNo={true}
        message={
          data.isDuplicateVaultName
            ? data.errorMessages.duplicateVaultAlert
            : data.errorMessages.slotAssignmentAlert
        }
      />
    </>
  );
};

export default VaultDetails;
