import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboardRequest",
  initialState: {
    lastFeedBackText: ""
  },
  reducers: {
    setLastFeedBack(state, action) {
      state.lastFeedBackText = action?.payload;
    }
  }
});

export const { setLastFeedBack } = dashboardSlice.actions;

export default dashboardSlice.reducer;
