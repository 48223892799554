import { Stack } from "@mui/material";
import RouteManagement from "components/customer-information/route-management/RouteManagement";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const RouteManagementPage = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("routeManagement.pageTitle")} />
      <RouteManagement />
    </Stack>
  );
};

export default RouteManagementPage;
