import { Box, Button, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ADMIN_MODULE_BASE_PATH, CUSTOMER_MOVE_DETAIL } from "routing/Paths";
import {
  CLEANUP,
  COMPLETE,
  DEFAULT_DATA_TABLE_OPTIONS,
  N_CHECKER,
  NO,
  Y_CHECKER,
  YES
} from "utils/constants";
import CustomerMoveSearchModal from "./CustomerMoveSearchModal";
import { CustomerSearchStyles } from "./CustomerSearchStyles";

const CustomerMoveSearchTable = ({
  searchResults,
  isResetGrid,
  setSelectedRow,
  handleYesClick,
  isModalVisible,
  setIsModalVisible,
  selectedRow,
  showProcessButton,
  setShowProcessButton,
  processButtonEnabled,
  setProcessButtonEnabled,
  showDeactivateButton,
  setShowDeactivateButton,
  showActivateButton,
  setShowActivateButton,
  selectedIndex,
  setSelectedIndex
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [actionType, setActionType] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = [
    {
      name: "source_district_name",
      label: t("customerMoveSearch.sourceBranch")
    },
    {
      name: "source_customer_number",
      label: t("customerMoveSearch.sourceCustomer")
    },
    {
      name: "destination_district_name",
      label: t("customerMoveSearch.destinationBranch")
    },
    {
      name: "destination_customer_number",
      label: t("customerMoveSearch.destinationCustomer")
    },
    {
      name: "process_name",
      label: t("customerMoveSearch.lastProcessPerformed")
    },
    { name: "is_active", label: t("customerMoveSearch.isActive") }
  ];

  const handleRowClick = useCallback(
    (_, rowMeta) => {
      const index = rowMeta.dataIndex;
      setSelectedIndex(index);
      setShowProcessButton(true);
      const selectedGridEntry = searchResults[index];
      const isActive = selectedGridEntry.is_active === N_CHECKER;
      const processName = selectedGridEntry.process_name === CLEANUP;
      const processStatus = selectedGridEntry.event_process_status === COMPLETE;

      const shouldEnableProcessButton =
        (processName && processStatus) || isActive;
      setProcessButtonEnabled(shouldEnableProcessButton);

      if (
        (selectedGridEntry.is_active === Y_CHECKER ||
          selectedGridEntry.is_active === N_CHECKER) &&
        processName &&
        processStatus
      ) {
        setShowDeactivateButton(false);
        setShowActivateButton(false);
      } else if (selectedGridEntry.is_active === Y_CHECKER) {
        setShowDeactivateButton(true);
        setShowActivateButton(false);
      } else if (selectedGridEntry.is_active === N_CHECKER) {
        setShowDeactivateButton(false);
        setShowActivateButton(true);
      }
      setSelectedRow(selectedGridEntry);
    },
    [
      searchResults,
      setProcessButtonEnabled,
      setSelectedIndex,
      setSelectedRow,
      setShowActivateButton,
      setShowDeactivateButton,
      setShowProcessButton
    ]
  );

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    customToolbar: searchResults?.length
      ? () => (
          <Box sx={CustomerSearchStyles.tableStyle}>
            {showProcessButton && (
              <>
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  disabled={processButtonEnabled}
                  onClick={() => handleProcessClick()}
                >
                  {t("customerMoveSearch.process")}
                </Button>
              </>
            )}
            {showDeactivateButton && (
              <Button
                variant="outlined"
                size="medium"
                type="button"
                onClick={() => handleActionClick("deactivate")}
              >
                {t("customerMoveSearch.deactivate")}
              </Button>
            )}
            {showActivateButton && (
              <Button
                variant="outlined"
                size="medium"
                type="button"
                onClick={() => handleActionClick("activate")}
              >
                {t("customerMoveSearch.activate")}
              </Button>
            )}
            <Button
              variant="outlined"
              size="medium"
              type="button"
              disabled={searchResults?.length === 0}
              onClick={handleExportCsv}
            >
              {t("customerMoveSearch.export")}
            </Button>
          </Box>
        )
      : null,
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults?.length === 0
            ? t("common.noDataFound")
            : t("common.tableTextWithoutInputs")
      }
    },
    setRowProps: (_, dataIndex) => ({
      style: {
        backgroundColor:
          dataIndex === selectedIndex ? "var(--color-im-light-blue-100)" : ""
      }
    }),
    onRowClick: handleRowClick
  };

  const handleActionClick = (statusType) => {
    if (statusType === "deactivate" || statusType === "activate") {
      setActionType(statusType);
      setIsModalVisible(true);
    }
  };

  const handleProcessClick = useCallback(() => {
    navigate(`${ADMIN_MODULE_BASE_PATH}/${CUSTOMER_MOVE_DETAIL}`, {
      state: selectedRow
    });
  }, [navigate, selectedRow]);

  const handleExportCsv = useCallback(() => {
    const headers = columns.map((col) => col.label).join(",");
    const rows = searchResults
      .map((row) =>
        columns.map((col) => `"${(row[col.name] || "").trim()}"`).join(",")
      )
      .join("\n");

    const csvContent = `${headers}\n${rows}`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "Customer_Move_Search.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  }, [searchResults, columns]);

  const customerMoveGridTableData = useMemo(() => {
    return searchResults?.map((row) => ({
      ...row,
      is_active: row.is_active === Y_CHECKER ? YES : NO
    }));
  }, [searchResults]);

  return (
    <>
      <MUIDataTable
        columns={columns}
        options={options}
        data={customerMoveGridTableData}
        title={
          !isResetGrid && (
            <Typography style={CustomerSearchStyles.titleStyle}>
              {searchResults?.length} {t("common.recordsFound")}
            </Typography>
          )
        }
      />
      <CustomerMoveSearchModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        actionType={actionType}
        handleYesClick={handleYesClick}
      />
    </>
  );
};

export default CustomerMoveSearchTable;
