import spacing from "styles/spacing";

export const Styles = {
  errorStyle: {
    marginBottom: "20px"
  },
  iconStyle: { width: 20 },
  widthFull: { width: "100%" },
  tableStyles: {
    tablePaper: {
      width: "100%",
      overflow: "hidden"
    },
    titleStyle: {
      color: "var(--color-im-grey-900)"
    },
    displayFlex: {
      display: "flex"
    },
    textColor: {
      color: "var(--color-im-green)"
    },
    gapSpacing: "20px",
    directionRow: "row",
    directionColumn: "column",
    noBorder: {
      border: "none"
    },
    formStyle: {
      display: "flex",
      flexDirection: "column",
      rowGap: spacing?.verticalMargin20
    }
  },

  listStyle: {
    listStyleType: "disc",
    pl: 2,
    lineHeight: 0,
    "& .MuiListItem-root": {
      display: "list-item",
      lineHeight: 0,
      p: 0
    }
  }
};
