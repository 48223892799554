import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Stack
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";

import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllCustomersQuery } from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import { setLoading } from "store/slices/Core/loadingSlice";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  DAYJS_LOCALES,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  NO,
  VALUE_ALL,
  VALUE_EMPTY_STRING,
  YES,
  errorMsgs
} from "utils/constants";
import {
  displayCode,
  minDateBranchOutage,
  serviceDays
} from "utils/constants/open-media-processing/BranchOutagePickListContants";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getAuthenticatedUserBranch
} from "utils/helpers";
import BranchOutagePickListModal from "./BranchOutagePickListModal";
import { branchOutagePickListCompletionStyles } from "./BranchOutagePickListStyles";
import BranchOutagePickListTable from "./BranchOutagePickListTable";
import DownloadReportModal from "./PrintReportModal";

const BranchOutagePickListScreen = () => {
  const { currentBranch } = useSelector(selectAuth);

  const [customer, setCustomer] = useState(ALL_SELECTION);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({ rowIndex: 0 });
  const [serviceDate, setServiceDate] = useState(Date.now());
  const [defaultDate, setDefaultDate] = useState(Date.now());
  const [pickListData, setPickListData] = useState([]);

  const [isFindBtnEnabled, setIsFindBtnEnabled] = useState(true);
  const [isFindClicked, setIsFindClicked] = useState(false);

  const [isUpdateBtnEnabled, setIsUpdateBtnEnabled] = useState(false);
  const [isPrintShipListBtnEnabled, setIsPrintShipListBtnEnabled] =
    useState(false);

  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState(VALUE_EMPTY_STRING);

  const [isLoading, setIsLoading] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  const [isSaved, setIsSaved] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [localDateTimeForTimeZone, setLocalDateTimeForTimeZone] = useState(
    Date.now()
  );
  const minDate = minDateBranchOutage;
  const [lastRunDate, setLastRunDate] = useState(Date.now());

  const [allLocations, setAllLocations] = useState([]);
  const [localeByBranch, setLocaleByBranch] = useState(DEFAULT_LOCALE);
  const selectedBranchWhenLogin = getAuthenticatedUserBranch();

  const [openInfoBar, setOpenInfoBar] = useState(false);
  const [infoBarDetails, setInfoBarDetails] = useState({
    msg: VALUE_EMPTY_STRING,
    details: []
  });

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const [printList, setPrintList] = useState([]);

  useEffect(() => {
    mainDistrictId && getLocalDateTimeForTimeZone();
    // eslint-disable-next-line
  }, [mainDistrictId]);

  useEffect(() => {
    if (allLocations) {
      if (allLocations.length > 0) {
        const selectedLocationDetails = allLocations.filter(
          (location) => location.district_id === selectedBranchWhenLogin
        );
        setLocaleByBranch(
          DAYJS_LOCALES[selectedLocationDetails[0]?.iso_locale_code] ||
            DEFAULT_LOCALE
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLocations]);

  useEffect(() => {
    const printableList = pickListData?.filter(
      (obj) => obj?.completeList === YES
    );

    setIsPrintShipListBtnEnabled(printableList?.length > 0);
    setPrintList(createBatchIDString(printableList));
    // eslint-disable-next-line
  }, [pickListData]);

  useEffect(() => {
    if (infoBarDetails.details?.length > 0) {
      setOpenInfoBar(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [infoBarDetails]);

  useEffect(() => {
    if (selectedRow?.completeList === YES && isSaved) {
      setIsSaved(false);

      let selectedRowUpdate = selectedRow;

      if (selectedRow?.assignTransportListArr?.length > 0) {
        const transports = selectedRow?.assignTransportListArr?.map(
          function (item) {
            return item["value"];
          }
        );

        selectedRowUpdate = {
          ...selectedRow,
          assignTransportList: transports?.toString()
        };
      }

      // updated the table row
      const newData = pickListData?.map((obj) =>
        obj?.picking_request_id === selectedRowUpdate?.picking_request_id
          ? selectedRowUpdate
          : obj
      );

      setPickListData((prev) => newData);
    }
    // eslint-disable-next-line
  }, [isSaved, selectedRow]);

  const createBatchIDString = (array) => {
    return array
      .map((obj) => obj.batch_id?.padStart(10, "0"))
      .join(VALUE_EMPTY_STRING);
  };

  const getAllLocations = async () => {
    setIsLoading((prev) => true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      const response = await callToCloudFunction(
        reqBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.COMMON.getalllocations}`
      );

      const data = await getDataFromFirestore(response, 1, response.data.docId);

      if (data?.data[0]) {
        setAllLocations((prev) => data?.data[0]);
      } else if (data?.error) {
        setAllLocations([]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setLoading((prev) => false);
      setAllLocations([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });

  const getResponseData = async (requestBody, url, count) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(
      response,
      count,
      response.data.docId
    );
    return data;
  };

  // handle Row Click
  const handleRowSelect = (value, tableMeta) => {
    setSelectedRow({
      ...pickListData[tableMeta?.dataIndex],
      rowIndex: tableMeta?.dataIndex
    });
  };

  const handleRowClick = () => {
    setIsModalOpen((prev) => !prev);
  };

  // Default Date
  const getLocalDateTimeForTimeZone = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.getLocalDateTimeForTimeZone,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        const localDate = obj[0][0]?.local_getdate?.split("T")[0];
        setLocalDateTimeForTimeZone((prev) => localDate);
        setServiceDate((prev) => localDate);
        setDefaultDate((prev) => localDate);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      mainDistrictId && getLastRunDate();
    }
  };

  // Max Date
  const getLastRunDate = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.getLastRunDate,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        const runDate = obj[0][0]?.last_gen_run_date?.split("T")[0];
        setLastRunDate((prev) => runDate);

        /**
         * If today’s date > Max Date 
              Set the Max date to be the selected value of the calendar
         */
        if (dayjs().isAfter(runDate)) {
          setServiceDate((prev) => runDate);
          setDefaultDate((prev) => runDate);
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      getAllLocations();
    }
  };

  const onFindButtonClick = async () => {
    setIsLoading(true);
    setIsFindClicked(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        display_code: displayCode,
        service_date: VALUE_EMPTY_STRING,
        customer_id:
          customer?.value === VALUE_ALL ? VALUE_EMPTY_STRING : customer?.value,
        service_days: serviceDays,
        run_id: VALUE_EMPTY_STRING,
        get_customers: VALUE_EMPTY_STRING
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.populateDailyPickingTasks,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0) {
        const respData = obj[0].sort(function (a, b) {
          const dateA = new Date(a.created_datetime);
          const dateB = new Date(b.created_datetime);
          return (
            a.customer_number.localeCompare(b.customer_number) || dateA - dateB
          );
        });

        const tableData = respData?.map((items) => {
          return {
            ...items,
            list: `${items.request_type.trim()} ${dateTimeFormatByLocale(
              items.created_datetime,
              localeByBranch
            )}`,
            formattedServiceDate: dateTimeFormatByLocale(
              items.request_service_date,
              localeByBranch
            ).split(" ")[0],
            completeList: NO,
            assignTransportList: VALUE_EMPTY_STRING,
            assignTransportListArr: []
          };
        });

        setPickListData(tableData);
        setIsFindBtnEnabled(false);
        setSelectedRow({ rowIndex: 0 });
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e, newValue) => {
    setPickListData([]);
    setIsFindBtnEnabled(true);
    setIsFindClicked(false);
    setCustomer(newValue || ALL_SELECTION);

    clearMsg();
  };

  const clear = () => {
    setCustomer(ALL_SELECTION);
    setServiceDate(defaultDate);
    setPickListData([]);
    setIsFindBtnEnabled(true);
    setIsFindClicked(false);

    clearMsg();
  };

  const clearMsg = () => {
    setWarningMessage((prev) => VALUE_EMPTY_STRING);
    setShowWarning((prev) => false);
    setThrowError(false);

    setOpenInfoBar(false);
    setInfoBarDetails({
      msg: VALUE_EMPTY_STRING,
      details: []
    });
  };

  const handlePrintShipBtn = async () => {
    const msgData = await getMessageFromCode(errorMsgs.errorCode25152);

    /**
     * Build the message
     * Only first 10 rows that not completeList !== "Yes"
     */

    const filtered = getFirst10NotPrintableList(pickListData);
    const concatenatedStr = createConcatenatedString(filtered);

    const message = `${msgData[0]?.descr} ${concatenatedStr} ...`;

    setWarningMessage((prev) => message.replace(/[|,]/g, " "));
    setShowWarning((prev) => true);
  };

  const getFirst10NotPrintableList = (array) => {
    const resultArray = [];
    for (const obj of array) {
      if (obj.completeList !== YES) {
        resultArray.push(obj);
      }
      if (resultArray?.length === 10) {
        break;
      }
    }
    return resultArray;
  };

  const createConcatenatedString = (array) => {
    return array.map(
      (obj) =>
        `${obj.customer_number?.trim()} - ${obj.logical_vault_code?.trim()} - ${obj.task_status?.trim()}`
    );
  };

  const handleYes = () => {
    // open the download modal
    setIsPrintModalOpen(true);

    setWarningMessage((prev) => VALUE_EMPTY_STRING);
    setShowWarning((prev) => false);
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {showWarning && (
        <ConfirmPopup
          message={warningMessage}
          modalPopupOpen={showWarning}
          handleYes={handleYes}
          handleNo={() => {
            setWarningMessage((prev) => VALUE_EMPTY_STRING);
            setShowWarning((prev) => false);
          }}
          showNo={true}
        />
      )}

      {/* Error Alert */}
      {throwError && (
        <Collapse in={throwError} sx={{ mb: 3 }}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}

      <form style={branchOutagePickListCompletionStyles?.formStyle}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <DatePickerField
                id="serviceDateId"
                name="serviceDate"
                label={t("branchOutagePickList.date")}
                onChange={(e) => {
                  setPickListData([]);
                  setIsFindBtnEnabled(true);
                  setIsFindClicked(false);
                  setServiceDate(e);

                  clearMsg();
                }}
                value={dayjs(serviceDate)}
                minDate={dayjs(minDate)}
                maxDate={dayjs(lastRunDate)}
                defaultValue={dayjs(localDateTimeForTimeZone)}
                locale={localeByBranch}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <CustomersField
                options={[ALL_SELECTION, ...customers]}
                value={customer}
                handleOnChange={handleChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <br />
      <Stack direction="row" gap={spacing.gap}>
        <Button
          id="newBtnfilter"
          variant="outlined"
          disabled={!isFindClicked}
          onClick={clear}
        >
          {t("common.clear")}
        </Button>
        <Button
          id="saveBtnfilter"
          variant="contained"
          type="submit"
          disabled={!isFindBtnEnabled}
          onClick={() => onFindButtonClick()}
        >
          {t("common.find")}
        </Button>
      </Stack>

      {openInfoBar && infoBarDetails?.msg && (
        <Box
          mt={2}
          style={branchOutagePickListCompletionStyles.infoBar.container}
        >
          <Stack
            sx={branchOutagePickListCompletionStyles.infoBar.containerStack}
          >
            <Box sx={branchOutagePickListCompletionStyles.infoBar.secondBox}>
              <span>
                <IconButton
                  size="small"
                  sx={branchOutagePickListCompletionStyles.infoBar.errorIcon}
                >
                  <ErrorOutlineRoundedIcon fontSize="small" />
                </IconButton>
                <span style={branchOutagePickListCompletionStyles.infoBar.span}>
                  {t("branchOutagePickList.infoBarTitle")}
                </span>
              </span>
              <IconButton
                size="small"
                sx={branchOutagePickListCompletionStyles.infoBar.closeIcon}
                onClick={() => setOpenInfoBar(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box sx={branchOutagePickListCompletionStyles.infoBar.title}>
              <span style={branchOutagePickListCompletionStyles.infoBar.span2}>
                {infoBarDetails.msg}
              </span>
            </Box>

            <Stack sx={branchOutagePickListCompletionStyles.infoBar.box}>
              {/* `${t(common.customer)} customer_number ${list} ${discr_object} ${message}` */}

              {infoBarDetails.details?.length > 0 &&
                infoBarDetails.details?.map((element) => (
                  <span
                    style={{
                      ...branchOutagePickListCompletionStyles.infoBar.span2
                    }}
                    key={element?.customer_number}
                  >
                    <span>{t("common.Customer")}</span>
                    <span
                      style={
                        branchOutagePickListCompletionStyles.infoBar.spanMargins
                          .ml5
                      }
                    >
                      {element?.customer_number?.trim()}
                    </span>
                    <span
                      style={
                        branchOutagePickListCompletionStyles.infoBar.spanMargins
                          .ml30Pre
                      }
                    >
                      {element?.list}
                    </span>
                    <span
                      style={
                        branchOutagePickListCompletionStyles.infoBar.spanMargins
                          .ml30
                      }
                    >
                      {element?.discr_object}
                    </span>
                    <span
                      style={
                        branchOutagePickListCompletionStyles.infoBar.spanMargins
                          .ml30
                      }
                    >
                      {element?.message}
                    </span>
                  </span>
                ))}
            </Stack>
          </Stack>
        </Box>
      )}

      <br />

      {/*Report download modal*/}
      {isPrintModalOpen && (
        <DownloadReportModal
          isVisible={isPrintModalOpen}
          setIsVisible={setIsPrintModalOpen}
          mainDistrictId={mainDistrictId}
          printList={printList}
          setIsLoading={setIsLoading}
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
        />
      )}

      <BranchOutagePickListTable
        pickListData={pickListData}
        selectedRow={selectedRow}
        handleRowClick={handleRowClick}
        isModalOpen={isModalOpen}
        handleRowSelect={handleRowSelect}
        isFindClicked={isFindClicked}
        isPrintShipListBtnEnabled={isPrintShipListBtnEnabled}
        handlePrintShipBtn={handlePrintShipBtn}
      />
      <BranchOutagePickListModal
        isModalOpen={isModalOpen}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        pickListData={pickListData}
        mainDistrictId={currentBranch?.district_id}
        customerId={customer?.value}
        setIsLoading={setIsLoading}
        setThrowErrorMessage={setThrowErrorMessage}
        setThrowError={setThrowError}
        setInfoBarDetails={setInfoBarDetails}
        isUpdateBtnEnabled={isUpdateBtnEnabled}
        setIsUpdateBtnEnabled={setIsUpdateBtnEnabled}
        setIsModalOpen={setIsModalOpen}
        isSaved={isSaved}
        setIsSaved={setIsSaved}
      />
    </>
  );
};
export default BranchOutagePickListScreen;
