/* eslint-disable react/jsx-no-duplicate-props */
import { Autocomplete, TextField } from "@mui/material";
import { t } from "i18next";
import { ReactComponent as ArrowDown } from "../../../assets/images/ArrowDown.svg";
import { ReactComponent as ArrowDownDisabled } from "../../../assets/images/ArrowDownDisabled.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/CloseIcon.svg";

// The form prop is used for error handling
//The value prop is used for customerField values
//The handleOnChange prop is used when changing the Autocomplete dropdown values.
export const CustomersField = ({
  form,
  options,
  value,
  handleOnChange,
  noOptionsText,
  sx,
  isOptionEqualToValue,
  disabled,
  required,
  labelValue
}) => {
  return (
    <Autocomplete
      componentsProps={{
        clearIndicator: ArrowDown
      }}
      clearIcon={<CloseIcon />}
      popupIcon={disabled ? <ArrowDownDisabled /> : <ArrowDown />}
      id="customers-list"
      name="customer"
      options={options}
      size="medium"
      value={value}
      fullWidth
      onChange={handleOnChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            required
              ? t("common.customerRequired")
              : labelValue
                ? labelValue
                : t("common.customer")
          }
          error={form?.touched?.customer && Boolean(form?.errors?.customer)}
          helperText={form?.touched?.customer && form?.errors?.customer}
        />
      )}
      noOptionsText={noOptionsText}
      sx={sx}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
