import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  ERROR_TYPES,
  GLOBAL_ATTRIBUTE_VALUES,
  SYSTEM_ID_SecureBase,
  downloadReportOptions,
  numberConstants
} from "utils/constants";
import {
  landscape,
  modalCase,
  paperType,
  setting_type_id
} from "utils/constants/open-media-processing/WorkManagement";
import {
  findErrorMessage,
  getGlobalAttributeValue,
  getSettingsValue,
  getUserName,
  printReport
} from "utils/helpers";

export default function DownloadReportModal({
  isVisible,
  setIsVisible,
  reportName,
  printRequestBody,
  currentBranch,
  onClickRefresh,
  setIsLoading,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage
}) {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);
  const [settingValue, setSettingValue] = useState(null);
  const [isLandscape, setIsLandscape] = useState(null);
  const [isDownloaded, setIsDownloaded] = useState(false);

  useEffect(() => {
    if (reportName === modalCase.printLabel && printRequestBody) {
      fetchSettingValues();
    } else if (reportName === modalCase.printPickRpt && printRequestBody) {
      fetchAttributeValues();
    }
    // eslint-disable-next-line
  }, [reportName, printRequestBody]);

  const fetchSettingValues = async () => {
    setIsLoading(true);
    try {
      const settngValue = await getSettingsValue(
        printRequestBody?.main_district_id,
        setting_type_id
      );
      setSettingValue(settngValue);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAttributeValues = async () => {
    setIsLoading(true);
    try {
      const attributeData = await getGlobalAttributeValue(
        currentBranch?.value,
        SYSTEM_ID_SecureBase,
        GLOBAL_ATTRIBUTE_VALUES.VALUE_189
      );
      let islandscape =
        attributeData === landscape
          ? numberConstants.one
          : numberConstants.zero;
      setIsLandscape(islandscape);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      let reqBody = printRequestBody;
      let url;
      if (reportName === modalCase.printShipList) {
        url = `${CF_URLS.printReports.openMediaProcessing.shipListReport}`;
      } else if (reportName === modalCase.printLabel) {
        reqBody = {
          ...printRequestBody,
          container_id: ""
        };
        url =
          settingValue?.toLowerCase() === paperType.a4.toLowerCase()
            ? CF_URLS.printReports.request.mrdAssignTransportA4Report
            : CF_URLS.printReports.request.mrdAssignTransportReport;
      } else if (reportName === modalCase.printPickRpt) {
        reqBody = {
          ...printRequestBody,
          islandscape: isLandscape
        };
        if (printRequestBody.sort_code === numberConstants.zero) {
          url =
            isLandscape === numberConstants.one
              ? CF_URLS.printReports.openMediaProcessing.pickLocationLReport
              : CF_URLS.printReports.openMediaProcessing.pickLocationPReport;
        } else {
          url =
            isLandscape === numberConstants.one
              ? CF_URLS.printReports.openMediaProcessing.pickMediaLReport
              : CF_URLS.printReports.openMediaProcessing.pickMediaPReport;
        }
      }
      reqBody = JSON.stringify({
        ...reqBody,
        district_name: currentBranch.name.replace("_", " "),
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(reqBody, url);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      } else {
        setIsDownloaded(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setThrowError(false);
    setIsVisible(false);
    if (
      (isDownloaded && reportName === modalCase.printLabel) ||
      reportName === modalCase.printPickRpt
    ) {
      onClickRefresh();
    }
    setIsDownloaded(false);
  };

  return (
    <DownloadPopup
      title={
        reportName === modalCase.printLabel
          ? t("workManagement.picking.reportTitleTransportLabel")
          : t("common.downloadReport")
      }
      showPrintModal={isVisible}
      downloadOptions={downloadReportOptions}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
    />
  );
}
