import { Stack } from "@mui/material";
import EmployeeSearchLayout from "components/employee-module/employee-search/EmployeeSearchLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";
const EmployeeSearch = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("employeeSearch.pageTitle")} />
      <EmployeeSearchLayout />
    </Stack>
  );
};

export default EmployeeSearch;
