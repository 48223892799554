import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Grid,
  IconButton,
  SvgIcon,
  Typography
} from "@mui/material";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import BillCycleField from "components/core/bill-cycle-field/BillCycleField";
import { BillDateField } from "components/core/bill-date-field/BillDateField";
import BranchField from "components/core/branch-field/BranchField";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetAllBillCycleQuery } from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  getBillingErrorQueryData,
  getMessageFromCode
} from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  billCycles,
  dateFormat
} from "utils/constants";
import { billingCycle, dates } from "utils/constants/biling-module";
import {
  findErrorMessage,
  formatNumberByLocale,
  getAuthenticatedUserBranch,
  getSortedData
} from "utils/helpers";
import DownloadReportModal from "./BillingErrorLogPrintModal";
import { errorLogStyles } from "./BillingErrorLogStyle";
import BillingErrorModal from "./BillingErrorModal";

const ErrorLog = () => {
  const { localeByBranch, allLocations } = useSelector(selectAuth);
  const [branchOption, setBranchOption] = useState([]);
  const [billingError, setBillingError] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedErrorResoleValue, setSelectedErrorResoleValue] =
    useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [isFindButtonDisabled, setIsFindButtonDisabled] = useState(true);
  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);
  const [billCycleOptions, setBillCycleOptions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentBranch } = useSelector(selectAuth);
  const { data: billCyclesData = [] } = useGetAllBillCycleQuery();
  const billCycleFilterParams = ["1", "3"];
  const today = dayjs();
  const minBillDate = dayjs(dates.minDate);
  const maxBillDate = dayjs(dates.maxDate);
  //page status
  const [pageToZero, setPageToZero] = useState(false);

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const urlParams = searchParams.get("param")?.split("/");

  const initalBillingErrorLogState = {
    branch: "",
    billCycle: "",
    billDateEom: "",
    billDateOto: today,
    error: ""
  };

  const formik = useFormik({
    initialValues: initalBillingErrorLogState
  });
  useEffect(() => {
    if (urlParams && allLocations.length && branchOption.length) {
      let cycleData = billCyclesData.map((item) => {
        return { ...item, value: item.value.trim() };
      });
      setBillCycleOptions(cycleData);
      formik.setFieldValue("branch", urlParams[0]);
      formik.setFieldValue("billCycle", urlParams[1].toUpperCase());
      urlParams[1].toLowerCase() === billCycles?.EOM.toLowerCase()
        ? formik.setFieldValue("billDateEom", urlParams[2])
        : formik.setFieldValue("billDateOto", dayjs(urlParams[2]));
    }
    //eslint-disable-next-line
  }, [allLocations, branchOption]);

  useEffect(() => {
    urlParams && setIsLoading(true);
    mainDistrictId && getBillingInfo();
    getBillingError();
    //eslint-disable-next-line
  }, [mainDistrictId]);

  useEffect(() => {
    if (
      urlParams &&
      formik.values.branch &&
      formik?.values?.billCycle &&
      (formik?.values?.billDateEom || formik?.values?.billDateOto)
    ) {
      handleFindClick();
    }
    if (
      formik.values.branch &&
      formik.values.billCycle &&
      ((formik?.values?.billCycle.trim() === billingCycle.eom &&
        formik.values.billDateEom) ||
        (formik?.values?.billCycle.trim() === billingCycle.oto &&
          formik.values.billDateOto))
    ) {
      setIsFindButtonDisabled(false);
      !urlParams && setIsLoading(false);
    } else {
      setIsFindButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [
    formik.values.branch,
    formik.values.billCycle,
    formik.values.billDateEom,
    formik.values.billDateOto
  ]);

  const getResponseData = async (requestBody, url) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(response, 1, response.data.docId);
    return data;
  };

  const getBillingInfo = async () => {
    try {
      setIsLoading(true);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.billingErrorLog.getBillingInfo}`
      );
      let branchData = data?.data[0];
      if (branchData) {
        let sortedData = getSortedData(branchData, "code", "asc");
        const branchOptions = sortedData.map((branch) => ({
          value: branch.district_id,
          label: branch.code
        }));
        setBranchOption(branchOptions);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      (!isResetGrid || !urlParams) && setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getBillingError = async () => {
    try {
      const result = await getBillingErrorQueryData();
      const response = result.map((res) => ({
        value: res.billing_error_action_id,
        label: res.descr
      }));
      setBillingError(response);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const handleFindClick = async () => {
    try {
      setPageToZero(false);
      setIsLoading(true);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        district_id: formik.values.branch,
        billing_date: formattedDate(),
        billing_cycle_code: formik.values.billCycle.trim()
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.billingErrorLog.getErrorLog}`
      );
      let errorLogData = data?.data[0];
      if (errorLogData) {
        errorLogData = errorLogData.map((e) => {
          e.quantity = formatNumberByLocale(e.quantity, localeByBranch);
          return e;
        });
        let sortedData = getSortedData(errorLogData, "number", "asc");
        setSearchResults(sortedData);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setIsResetGrid(false);
      setIsFindButtonDisabled(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const deleteParams = () => {
    searchParams.delete("param");
    setSearchParams(searchParams);
  };

  const handleReset = () => {
    deleteParams();
    formik.resetForm();
    setSearchResults([]);
    setIsResetGrid(true);
    setThrowError(false);
    setThrowErrorMessage(null);
    setPageToZero(true);
  };

  const formattedDate = () => {
    let billDate =
      formik?.values?.billCycle.trim() === billingCycle.eom
        ? formik?.values?.billDateEom
        : formik?.values?.billDateOto;
    return dayjs(billDate).format(dateFormat.universalFormat);
  };

  const handleEditClick = (tableMeta) => {
    formik.setFieldValue("error", null);
    setSelectedRowIndex(tableMeta.rowIndex);
    setSelectedRowData(searchResults[tableMeta.rowIndex]);
    setIsUpdateModalOpen(true);
  };

  //Table columns
  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Button onClick={() => handleEditClick(tableMeta)}>
            <SvgIcon component={EditIcon} />
          </Button>
        )
      }
    },
    {
      name: "number",
      label: t("billingErrorLog.customer")
    },
    {
      name: "billing_catalog_code",
      label: t("billingErrorLog.service")
    },
    {
      name: "classification_descr",
      label: t("billingErrorLog.class")
    },
    {
      name: "type",
      label: t("billingErrorLog.identifierValue")
    },
    {
      name: "catalog_item_code",
      label: t("billingErrorLog.catalogItem")
    },
    {
      name: "quantity",
      label: t("billingErrorLog.quantity")
    },
    {
      name: "message_descr",
      label: t("billingErrorLog.error")
    },
    {
      name: "error_action_descr",
      label: t("billingErrorLog.billingErrorAction")
    }
  ];

  const handleBranchChange = (e) => {
    handleReset();
    formik.setFieldValue("branch", e.target?.value);
  };

  const handleBillCycleChange = (e) => {
    deleteParams();
    formik.setFieldValue("billCycle", e.target?.value);
    setSearchResults([]);
    setIsResetGrid(true);
    setIsLoading(true);
  };

  const handleBillDateChange = (newValue) => {
    deleteParams();
    formik.setFieldValue("billDateEom", newValue);
    setSearchResults([]);
    setIsResetGrid(true);
  };

  const handleDatePickerChange = (newValue) => {
    deleteParams();
    formik.setFieldValue("billDateOto", newValue);
  };

  const checkIsError = async () => {
    let requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      district_id: formik.values.branch,
      customer_id: selectedRowData.customer_id,
      catalog_item_code: selectedRowData.catalog_item_code,
      catalog_code: selectedRowData.billing_catalog_code,
      billing_error_action_id: selectedErrorResoleValue
    });
    let data = await getResponseData(
      requestBody,
      `${CF_URLS.billingErrorLog.checkError}`
    );
    if (data?.data[0][0]?.err_msg !== "0") {
      const errorMsg = await getMessageFromCode(data?.data[0][0]?.err_msg);
      setIsLoading(false);
      setThrowErrorMessage(errorMsg[0]?.descr);
      setThrowError(true);
    } else {
      updateErrorLog();
    }
  };

  const updateErrorLog = async () => {
    try {
      setIsLoading(true);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        district_id: formik.values.branch,
        billing_date: formattedDate(),
        billing_cycle_code: formik.values.billCycle.trim(),
        customer_id: selectedRowData.customer_id,
        billing_class_descr: selectedRowData.classification_descr,
        catalog_item_code: selectedRowData.catalog_item_code,
        billing_catalog_code: selectedRowData.billing_catalog_code,
        billing_error_action_id: selectedErrorResoleValue
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.billingErrorLog.saveErrorLog}`
      );
      let updateData = data?.data[0];
      if (updateData) {
        let errorData = billingError.filter(
          (el) => el.value === selectedErrorResoleValue
        );
        let results = JSON.parse(JSON.stringify(searchResults));
        results[selectedRowIndex].billing_error_action_id =
          selectedErrorResoleValue;
        results[selectedRowIndex].error_action_descr = errorData[0]?.label;
        setSearchResults(results);
        setIsUpdateModalOpen(false);
        setSelectedRowIndex(null);
        setThrowError(false);
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
      setIsLoading(false);
      setIsShowSuccessPopup(true);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const handleUpdateClick = async () => {
    try {
      setIsLoading(true);
      await checkIsError();
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // Cancel edit function
  const handleCancelEdit = () => {
    setIsUpdateModalOpen(false);
    setSelectedRowIndex(null);
    setThrowError(false);
  };

  const handlePrintClick = () => {
    setIsPrintModalOpen(true);
  };

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: pageToZero && 0,
    pagination: searchResults.length && true,
    customToolbar: searchResults.length
      ? () => (
          <>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              gap={spacing.verticalMargin20}
            >
              <Button
                variant="outlined"
                size="medium"
                type="button"
                disabled={searchResults.length === 0}
                onClick={handlePrintClick}
              >
                {t("common.print")}
              </Button>
            </Grid>
          </>
        )
      : null,
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor:
          dataIndex === selectedRowIndex ? "var(--color-im-light-blue-100)" : ""
      }
    })
  };

  // Handler for changing the selected error resolution
  const handleSelectedErrorChange = (e) => {
    setThrowError(false);
    formik.setFieldValue("error", e.target.value);
    setSelectedErrorResoleValue(e.target.value);
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      {/*Success message popup*/}
      {isShowSuccessPopup && (
        <SuccessPopup
          message={t("common.updateMessage")}
          close={setIsShowSuccessPopup}
        />
      )}
      {throwError && !isUpdateModalOpen && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            sx={errorLogStyles.errorStyle}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Grid container spacing={spacing.verticalMargin20} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <BranchField
            value={formik.values.branch}
            options={branchOption}
            onChange={(e) => handleBranchChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <BillCycleField
            cycleOptions={urlParams && billCycleOptions}
            selectedValue={formik.values.billCycle}
            handleOnChange={(e) => handleBillCycleChange(e)}
            disabledField={!formik.values.branch}
            filterParams={billCycleFilterParams}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <BillDateField
            billCycle={formik?.values?.billCycle}
            billDate={urlParams ? formik?.values?.billDateEom : null}
            minDate={minBillDate}
            maxDate={maxBillDate}
            today={today}
            form={formik}
            defaultValue={today}
            handleDatePickerChange={handleDatePickerChange}
            handleBillDateChange={handleBillDateChange}
            value={formik?.values?.billDateOto}
            allLocations={allLocations}
          />
        </Grid>
      </Grid>
      <Grid display={"flex"} direction={"row"} gap={spacing.verticalMargin20}>
        <Grid id="error-log-clear-button-grid" item xs="auto">
          <Button
            id="error-log-clear-button"
            variant="outlined"
            type="reset"
            onClick={() => {
              handleReset();
            }}
          >
            {t("common.clear")}
          </Button>
        </Grid>
        <Grid id="error-log-find-button-grid" item xs="auto">
          <Button
            id="error-log-find-button"
            variant="contained"
            type="submit"
            disabled={isFindButtonDisabled}
            onClick={handleFindClick}
          >
            {t("common.find")}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3} marginTop={4}>
        <MUIDataTable
          columns={columns}
          options={options}
          data={searchResults}
          title={
            !isResetGrid && (
              <Typography style={errorLogStyles.titleStyle}>
                {searchResults?.length} {t("common.recordsFound")}
              </Typography>
            )
          }
        />
      </Grid>
      {isUpdateModalOpen && (
        <BillingErrorModal
          isUpdateModalOpen={isUpdateModalOpen}
          value={formik.values.error}
          errorOptions={billingError}
          onChange={handleSelectedErrorChange}
          handleUpdateClick={handleUpdateClick}
          handleCancelEdit={handleCancelEdit}
          selectedRowData={selectedRowData}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
          setThrowError={setThrowError}
        />
      )}
      {/*Report download modal*/}
      {isPrintModalOpen && (
        <DownloadReportModal
          isVisible={isPrintModalOpen}
          setIsVisible={setIsPrintModalOpen}
          mainDistrictId={mainDistrictId}
          districtId={formik.values.branch}
          billCycleCode={formik.values.billCycle.trim()}
          billDate={formattedDate()}
          setIsLoading={setIsLoading}
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
        />
      )}
    </>
  );
};
export default ErrorLog;
