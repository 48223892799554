export const keyValues = {
  batchScan: "batchScan",
  arrival: "arrival"
};
export const stringChecker = "string";
export const zeroChecker = "0";
export const fifteen = 15;
export const twenty = 20;
export const seventeen = 17;
export const batchTypeFlagForArrival = "A";
export const batchTypeFlagForBatchs = "B";
export const hashChecker = "#";
