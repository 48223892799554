import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetAllPersonnelQuery,
  useGetPersonnelAuthQuery,
  useGetPersonnelSecurityQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { selectAuth } from "store/slices";
import { ERROR_TYPES, Y_CHECKER } from "utils/constants";
import { generateSHA256Hash, getLanguageId } from "utils/helpers";
import { RESPONSE_LIMIT, Y_YES } from "../Constants";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

// Custom hook
const useGetPersonnelSecurity = (
  mainDistrictId,
  customerId,
  requestedBySelectedOnCancel,
  personnelAuthDetailsOnCancel,
  getPersonnelSecurity
) => {
  return useGetPersonnelSecurityQuery({
    mainDistrictId,
    customerId,
    personnelId: requestedBySelectedOnCancel?.value,
    userLogin: personnelAuthDetailsOnCancel
      ? personnelAuthDetailsOnCancel[0]?.login
      : "",
    getPersonnelSecurity
  });
};

const PersonnelAuthModal = ({
  customerSelected,
  isPersonnelAuthModalVisible,
  setIsPersonnelAuthModalVisible,
  setCancelButtonClicked,
  setCancelPersonnelId,
  mainDistrictId
}) => {
  const { t } = useTranslation();

  const { data: personnels = [] } = useGetAllPersonnelQuery({
    customerId: customerSelected
  });

  const [authButtonEnabled, setAuthButtonEnabled] = useState(false);

  const customerId = customerSelected;

  const [getPersonnelSecurity, setGetPersonnelSecurity] = useState(false);

  const [personnelAuthDetailsOnCancel, setPersonnelAuthDetailsOnCancel] =
    useState({});
  const [enteredAuthNumOnCancel, setEnteredAuthNumOnCancel] = useState("");
  const [
    enteredChallengeResponseOnCancel,
    setEnteredChallengeResponseOnCancel
  ] = useState("");
  const [isAuthFailOnCancel, setIsAuthFailOnCancel] = useState(false);
  const [requestedBySelectedOnCancel, setRequestedBySelectedOnCancel] =
    useState("");

  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: requestedBySelectedOnCancel?.value
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [authFailedCancel, setAuthFailedCancel] = useState(true);

  const getPersonnelSecurityDetails = useGetPersonnelSecurity(
    mainDistrictId,
    customerId,
    requestedBySelectedOnCancel,
    personnelAuthDetailsOnCancel,
    getPersonnelSecurity
  );

  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  const { currentBranch } = useSelector(selectAuth);
  const selectedBranch = currentBranch?.district_id;
  const languageLocaleId = getLanguageId();

  const handleAuthenticateOnCancel = async () => {
    //generate hash code
    let validateChallengeResponse;
    if (enteredChallengeResponseOnCancel.length > 0) {
      let hashCode = await generateSHA256Hash(
        enteredChallengeResponseOnCancel?.trim()?.toUpperCase()
      );

      //challenge response validation
      validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: selectedBranch,
        language_locale_id: languageLocaleId,
        personnel_id: requestedBySelectedOnCancel?.value,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          const error = data && data[0]?.error;
          //checking for the error
          if (error) {
            setIsAuthFailOnCancel(true);
            setErrorMessage(findErrorMessage(error));
          } else {
            setIsAuthFailOnCancel(false);
            setErrorMessage("");
            return data[0]?.is_match_flag;
          }
        })
        .catch((e) => {
          setIsAuthFailOnCancel(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        });
    }

    if (
      (personnelAuthDetailsOnCancel[0]?.auth_number !== "" &&
        personnelAuthDetailsOnCancel[0]?.auth_number ===
          enteredAuthNumOnCancel) ||
      (personnelAuthDetailsOnCancel[0]?.secret_answer !== "" &&
        validateChallengeResponse === Y_CHECKER)
    ) {
      setCancelPersonnelId((prevState) => requestedBySelectedOnCancel?.value);
      setIsAuthFailOnCancel(false);
      setIsPersonnelAuthModalVisible(false);
      setCancelButtonClicked(true);

      setRequestedBySelectedOnCancel("");
      setEnteredChallengeResponseOnCancel("");
      setEnteredAuthNumOnCancel("");

      resetModal();
    } else {
      setCancelPersonnelId((prevState) => "");
      setErrorMessage(t("vaultItemized.authFailedAlert"));
      setIsAuthFailOnCancel(true);
    }
  };
  const handleRequestedByChangeOnCancel = (event, newValue) => {
    newValue = newValue || "";
    setRequestedBySelectedOnCancel(newValue);

    if (newValue === "") {
      setPersonnelAuthDetailsOnCancel({});
      setEnteredChallengeResponseOnCancel("");
      setEnteredAuthNumOnCancel("");
      setAuthButtonEnabled(false);
      setGetPersonnelSecurity(false);
    }
  };

  useEffect(() => {
    if (personnelAuth?.length > 0 && requestedBySelectedOnCancel !== "") {
      setPersonnelAuthDetailsOnCancel(personnelAuth);
      setGetPersonnelSecurity(true);
    }
  }, [personnelAuth, requestedBySelectedOnCancel]);

  useEffect(() => {
    if (getPersonnelSecurity) {
      const fetchData = async () => {
        try {
          const response = await getPersonnelSecurityDetails;

          if (response && response?.isSuccess) {
            setGetPersonnelSecurity(false);

            if (response?.data && response?.data[0]?.result) {
              if (response?.data[0]?.result === Y_YES) {
                setAuthButtonEnabled(true);
              } else {
                setAuthButtonEnabled(false);
              }
            }

            if (response?.data && response?.data[0]?.error) {
              setIsAuthFailOnCancel(true);
              setErrorMessage(findErrorMessage(response?.data[0]?.error));
            } else {
              setIsAuthFailOnCancel(false);
              setErrorMessage("");
            }
          } else if (!response?.isSuccess && response?.data) {
            setIsAuthFailOnCancel(true);
            setErrorMessage(findErrorMessage(response?.data[0]?.error));
          }
        } catch (e) {
          setIsAuthFailOnCancel(true);
          setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        }
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [
    personnelAuthDetailsOnCancel,
    getPersonnelSecurityDetails,
    getPersonnelSecurity
  ]);

  const findErrorMessage = (error) => {
    let relatedErrorMessage = "";

    switch (error) {
      case ERROR_TYPES.FAILED:
        relatedErrorMessage = t("vaultItemized.failedError");
        break;
      case ERROR_TYPES.EXCEPTION:
        relatedErrorMessage = t("vaultItemized.errorOccurred");
        break;
      case ERROR_TYPES.ISSUE:
        relatedErrorMessage = t("vaultItemized.somethingWrong");
        break;
      default:
        relatedErrorMessage = error;
        break;
    }

    return relatedErrorMessage;
  };

  const resetModal = () => {
    setPersonnelAuthDetailsOnCancel({});
    setAuthButtonEnabled(false);
    setGetPersonnelSecurity(false);

    setIsAuthFailOnCancel(false);
    setRequestedBySelectedOnCancel("");
    setEnteredChallengeResponseOnCancel("");
    setEnteredAuthNumOnCancel("");

    setIsPersonnelAuthModalVisible(false);
  };

  useEffect(() => {
    isPersonnelAuthModalVisible && setAuthFailedCancel(true);
  }, [isPersonnelAuthModalVisible]);

  return (
    <>
      <Modal
        open={isPersonnelAuthModalVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          id="selectMediaModalCard"
          sx={
            requestedBySelectedOnCancel !== "" &&
            personnelAuthDetailsOnCancel[0]?.secret_question !== ""
              ? MDRVaultItemizedStyles.personnelAuthModalStyle
              : MDRVaultItemizedStyles.personnelAuthModalStyleCollapse
          }
        >
          {/* ------------------ Title Section ------------------ */}

          <Typography
            variant="h6"
            sx={MDRVaultItemizedStyles.mainTitle_SelectMedia}
            color="text.secondary"
            gutterBottom
          >
            {t("vaultItemized.personnelAuthentication")}
          </Typography>

          <Divider />
          {/* ------------------ Body Section ------------------ */}

          {authFailedCancel && (
            <Box sx={MDRVaultItemizedStyles.personnelAuthModalAlert}>
              <Alert
                severity="warning"
                icon={<img src={WarningIcon} alt="error" />}
                id="authWarning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    id="authWarningCloseBtn"
                    size="small"
                    onClick={() => {
                      setAuthFailedCancel(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {t("vaultItemized.cancelNoAuthMsg")}
              </Alert>
            </Box>
          )}

          {isAuthFailOnCancel && (
            <Box sx={MDRVaultItemizedStyles.personnelAuthModalAlert}>
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setIsAuthFailOnCancel(false);
                      setErrorMessage("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMessage}
              </Alert>
            </Box>
          )}

          <CardContent>
            <Stack gap="20px">
              <Box sx={MDRVaultItemizedStyles.PersonnelAuthModal_CardBox}>
                <Autocomplete
                  id="requestedBySelection"
                  options={["", ...personnels] || []}
                  sx={
                    MDRVaultItemizedStyles.PersonnelAuthModal_CardBox1_AutoComplete
                  }
                  value={requestedBySelectedOnCancel}
                  onChange={handleRequestedByChangeOnCancel}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("vaultItemized.requestedByLabel")}
                      InputLabelProps={
                        MDRVaultItemizedStyles.PersonnelAuthModal_InputLabelProps
                      }
                      value={requestedBySelectedOnCancel?.label || ""}
                    />
                  )}
                />
              </Box>

              <Typography variant="subtitle2">
                {t("vaultItemized.personnelAuthInstructions")}
              </Typography>

              <Stack gap="10px" direction="row">
                <Box sx={MDRVaultItemizedStyles.PersonnelAuthModal_CardBox}>
                  <TextField
                    disabled={
                      !personnelAuthDetailsOnCancel[0]?.auth_number ||
                      Boolean(enteredChallengeResponseOnCancel)
                    }
                    id="authNumber"
                    label={t("vaultItemized.authNumber")}
                    fullWidth
                    sx={
                      MDRVaultItemizedStyles.PersonnelAuthModal_CardBox_AuthNumber_TF
                    }
                    value={enteredAuthNumOnCancel}
                    onChange={(event) =>
                      setEnteredAuthNumOnCancel(event.target.value)
                    }
                    InputLabelProps={
                      MDRVaultItemizedStyles.PersonnelAuthModal_InputLabelProps
                    }
                  />
                </Box>

                {requestedBySelectedOnCancel !== "" &&
                  personnelAuthDetailsOnCancel[0]?.secret_question !== "" && (
                    <Box>
                      <TextField
                        id="challengeQuestion"
                        label={t("vaultItemized.challengeQuestion")}
                        fullWidth
                        variant="standard"
                        InputProps={{
                          disableUnderline: true
                        }}
                        style={
                          MDRVaultItemizedStyles.PersonnelAuthModal_CardBox_Question_TF
                        }
                        value={
                          personnelAuthDetailsOnCancel[0]?.secret_question || ""
                        }
                        InputLabelProps={
                          MDRVaultItemizedStyles.PersonnelAuthModal_InputLabelProps
                        }
                      />
                    </Box>
                  )}

                {requestedBySelectedOnCancel !== "" &&
                  personnelAuthDetailsOnCancel[0]?.secret_question !== "" && (
                    <Box>
                      <TextField
                        disabled={
                          !personnelAuthDetailsOnCancel[0]?.secret_answer ||
                          Boolean(enteredAuthNumOnCancel)
                        }
                        label={t("vaultItemized.challengeResponse")}
                        id="challengeResponse"
                        type="password"
                        fullWidth
                        sx={
                          MDRVaultItemizedStyles.PersonnelAuthModal_CardBox_Answer_TF
                        }
                        value={enteredChallengeResponseOnCancel}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (inputValue?.length <= RESPONSE_LIMIT) {
                            setEnteredChallengeResponseOnCancel(inputValue);
                          }
                        }}
                        InputLabelProps={
                          MDRVaultItemizedStyles.PersonnelAuthModal_InputLabelProps
                        }
                      />
                    </Box>
                  )}
              </Stack>
            </Stack>
          </CardContent>

          {!isAuthFailOnCancel && (
            <Box sx={MDRVaultItemizedStyles.PersonnelAuthModal_BoxHeight}></Box>
          )}

          <Divider />
          {/* ------------------ Footer Section ------------------ */}

          <CardActions sx={MDRVaultItemizedStyles.cardActionsStyle_PRSPopUP}>
            <Button
              id="selectMediaModalCancelBtn"
              variant="outlined"
              onClick={resetModal}
              sx={MDRVaultItemizedStyles.buttonStyle_PRSPopUP}
            >
              {t("vaultItemized.cancel")}
            </Button>

            <Stack direction="row" gap="20px">
              <Button
                disabled={
                  !authButtonEnabled || requestedBySelectedOnCancel === ""
                }
                variant="contained"
                style={MDRVaultItemizedStyles.PersonnelAuthModal_AuthButton}
                onClick={handleAuthenticateOnCancel}
                id="authenticateBtn"
              >
                {t("vaultItemized.authenticate")}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default PersonnelAuthModal;
