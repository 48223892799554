import { Stack } from "@mui/material";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";
import CustomerServiceInformation from "components/customer-information/customer-service-information/CustomerServiceInformation";

const CustomerServiceInformationPage = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("customerServiceInformation.pageTitle")} />
      <CustomerServiceInformation />
    </Stack>
  );
};

export default CustomerServiceInformationPage;
