import spacing from "styles/spacing";

const alertBox = {
  marginBottom: spacing.horizontalMargin20
};
const titleStyle = {
  color: "var(--color-im-grey-900)"
};
const dataTableStyles = {
  MUIDataTableHeadCell: {
    styleOverrides: {
      root: {
        padding: "16px 24px !important"
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "16px 24px !important"
      }
    }
  }
};
export const GroupEmployeeStyles = {
  alertBox,
  titleStyle,
  dataTableStyles
};
