export const IRMHolydaysStyles = {
  fontStyles: {
    color: "var(--color-dashboard-text)",
    fontFamily: "Roboto",
    fontSize: "1.8rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "5%"
  },
  headerDivStyles: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "1rem"
  },
  rowHeight: "32px",
  margin: {
    marginBottom: "20px"
  },
  colSpace: "3rem",
  marginBottom: "1rem"
};
