import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetPossibleMatchesListQuery } from "services/api";
import { ERROR_TYPES } from "utils/constants";
import {
  COLUMNS_POSSIBLE_MATCHES,
  DEFAULT_TABLE_SORT_POSSIBLE_MATCHES,
  POSSIBLE_MATCH_COUNT,
  TABLE_ROWS_PER_PAGE_POSSIBLE_MATCHES,
  TABLE_ROWS_PER_PAGE_POSSIBLE_MATCHES_DEFAULT
} from "../Constants";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

const useFetchPossibleMatchList = ({
  mainDistrictId,
  customerSelected,
  volserList,
  selectedVolser,
  followUp
}) => {
  return useGetPossibleMatchesListQuery({
    mainDistrictId: mainDistrictId,
    customerId: customerSelected,
    volserList: volserList,
    selectedVolser: selectedVolser,
    followUp: followUp
  });
};

const PossibleMatchesTable = (props) => {
  const { t } = useTranslation();

  const validateOpenMediaList = useFetchPossibleMatchList({
    mainDistrictId: props.mainDistrictId,
    customerSelected: props.customerSelected.value,
    volserList: props.tableMedia?.map((item) => item?.volser),
    selectedVolser: props.selectedMedia.volser,
    followUp: props.selectedMedia.follow_up
  });

  function removeSpaces(inputString) {
    // to remove spaces
    return inputString.replace(/\s/g, "");
  }

  const [tableValidatedData, setTableValidatedData] = useState([]);

  const [responded, setResponded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setResponded(false);

      try {
        const response = await validateOpenMediaList;
        setTableValidatedData([]);

        if (response?.isSuccess) {
          setResponded(true);
          if (response && response?.data) {
            const newData = response?.data.map((item) => ({
              ...item,
              volser: removeSpaces(item.volser)
            }));

            if (
              newData?.length === POSSIBLE_MATCH_COUNT &&
              props.errorMessage === ""
            ) {
              props.setErrorMessage(t("vaultItemized.moreThan50"));
              props.setIsError(true);
            }

            if (
              newData?.length !== POSSIBLE_MATCH_COUNT &&
              props.errorMessage !== ""
            ) {
              props.setErrorMessage("");
              props.setIsError(false);
            }

            setTableValidatedData(newData);
          }

          if (response?.data && response?.data[0]?.error) {
            props.setIsError(true);
            props.setErrorMessage(findErrorMessage(response?.data[0]?.error));
          }
        } else if (!response?.isSuccess && response?.data) {
          setResponded(true);
          props.setIsError(true);
          props.setErrorMessage(findErrorMessage(response?.data[0]?.error));
        }
      } catch (e) {
        setResponded(true);
        props.setIsError(true);
        props.setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      }
    };

    if (props.possibleMatchesModalVisible && !responded) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [
    props,
    validateOpenMediaList,
    props.possibleMatchesModalVisible,
    responded
  ]);

  const columns = [
    { id: COLUMNS_POSSIBLE_MATCHES.checkbox, label: "" },
    {
      id: COLUMNS_POSSIBLE_MATCHES.media,
      label: t("vaultItemized.mediaLabelTable")
    },
    {
      id: COLUMNS_POSSIBLE_MATCHES.type,
      label: t("vaultItemized.mediaTypeLabelTable")
    },
    {
      id: COLUMNS_POSSIBLE_MATCHES.status,
      label: t("vaultItemized.statusLabelTable")
    }
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  const [sorting, setSorting] = useState({
    ...DEFAULT_TABLE_SORT_POSSIBLE_MATCHES,
    direction: "asc"
  });

  const filteredListExcludingTableMedia = useMemo(() => {
    return tableValidatedData;

    // eslint-disable-next-line
  }, [tableValidatedData]);

  const handleCheckboxChange = (row) => {
    setSelectedRows((prevSelectedRows) => {
      // Extracting an array of volser values from previously selected rows
      const selectedRowIds = prevSelectedRows?.map((selectedRow) =>
        String(selectedRow.volser)
      );

      // Checking if the current row's volser is already in the selectedRowIds array
      if (selectedRowIds?.includes(String(row.volser))) {
        // If the current row is already selected, remove it from the selectedRows
        return prevSelectedRows?.filter(
          (selectedRow) => String(selectedRow.volser) !== String(row.volser)
        );
      } else {
        // If the current row is not selected, find the corresponding media object
        const mediaObject = tableValidatedData?.find(
          (media) => String(media.volser) === String(row.volser)
        );

        // If the media object is found, add it to the selectedRows array
        return mediaObject
          ? [...prevSelectedRows, mediaObject]
          : [...prevSelectedRows];
      }
    });
  };

  useEffect(() => {
    props.setSelectedRowsMedia(selectedRows);
    // eslint-disable-next-line
  }, [selectedRows]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    TABLE_ROWS_PER_PAGE_POSSIBLE_MATCHES_DEFAULT
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortedList = useMemo(() => {
    const isAsc = sorting.direction === "asc";
    const columnId = sorting.column;

    return [...filteredListExcludingTableMedia]?.sort((a, b) => {
      if (columnId === COLUMNS_POSSIBLE_MATCHES.media) {
        return isAsc
          ? a.volser?.localeCompare(b.volser)
          : b.volser?.localeCompare(a.volser);
      } else if (columnId === COLUMNS_POSSIBLE_MATCHES.type) {
        return isAsc
          ? a.media_type_descr?.localeCompare(b.media_type_descr)
          : b.media_type_descr?.localeCompare(a.media_type_descr);
      } else if (columnId === COLUMNS_POSSIBLE_MATCHES.status) {
        return isAsc
          ? a.media_exchange_status?.localeCompare(b.media_exchange_status)
          : b.media_exchange_status?.localeCompare(a.media_exchange_status);
      }

      return 0;
    });
  }, [sorting, filteredListExcludingTableMedia]);

  useEffect(() => {
    props.setTableData(sortedList);
    // eslint-disable-next-line
  }, [sortedList]);

  const findErrorMessage = (error) => {
    let relatedErrorMessage = "";

    switch (error) {
      case ERROR_TYPES.FAILED:
        relatedErrorMessage = t("vaultItemized.failedError");
        break;
      case ERROR_TYPES.EXCEPTION:
        relatedErrorMessage = t("vaultItemized.errorOccurred");
        break;
      case ERROR_TYPES.ISSUE:
        relatedErrorMessage = t("vaultItemized.somethingWrong");
        break;
      default:
        relatedErrorMessage = error;
        break;
    }

    return relatedErrorMessage;
  };

  return (
    <Paper
      id="selectPossibleMatchesTable"
      style={MDRVaultItemizedStyles.PossibleMatchesTable_Paper}
    >
      <TableContainer
        style={
          props.isError
            ? MDRVaultItemizedStyles.PossibleMatchesTable_TableContainer
            : MDRVaultItemizedStyles.PossibleMatchesTable_TableContainer_NoError
        }
      >
        <Table>
          <TableHead
            style={MDRVaultItemizedStyles.PossibleMatchesTable_TableHead}
          >
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  id={`matchSort${index}`}
                  key={column.id}
                  align="left"
                  sx={{
                    width: index === 0 ? "30px" : undefined,
                    ...MDRVaultItemizedStyles.TableHeader_Typo
                  }}
                  size="small"
                >
                  <span
                    style={MDRVaultItemizedStyles.PossibleMatchesTable_span}
                    onClick={() =>
                      setSorting((prevSorting) => ({
                        column: column.id,
                        direction:
                          prevSorting.column === column.id &&
                          prevSorting.direction === "asc"
                            ? "desc"
                            : "asc"
                      }))
                    }
                  >
                    {column.label}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? sortedList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : sortedList
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={MDRVaultItemizedStyles.possibleMatchTableRowMuiCustom}
              >
                <TableCell
                  size="small"
                  sx={MDRVaultItemizedStyles.PossibleMatchesTable_TableCell}
                  id={`checkbox${index}`}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label=""
                    sx={
                      MDRVaultItemizedStyles.PossibleMatchesTable_FormControlLabel
                    }
                    onChange={() => handleCheckboxChange(row)}
                  />
                </TableCell>
                <TableCell size="small">{row.volser}</TableCell>
                <TableCell size="small">{row.media_type_descr}</TableCell>
                <TableCell size="small">{row.media_exchange_status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={MDRVaultItemizedStyles.PossibleMatchesTable_TablePagination}
        rowsPerPageOptions={TABLE_ROWS_PER_PAGE_POSSIBLE_MATCHES}
        component="div"
        count={filteredListExcludingTableMedia?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default PossibleMatchesTable;
