import { Stack } from "@mui/material";
import EventSearchLayout from "components/disaster-module/event-search/EventSearchLayout";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const EventSearch = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={i18n.t("disasterRecovery.eventSearch.pageTitle")} />
      <EventSearchLayout />
    </Stack>
  );
};

export default EventSearch;
