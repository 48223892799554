import i18n from "i18n";

export const hintTextColor = {
  addOns: "var(--color-add-ons)",
  specials: "var(--color-specials)",
  reRuns: "var(--color-reruns)",
  dr: "var(--color-dr)",
  destruction: "var(--color-destruction)"
};

export const sourceTree = {
  unassigned: "UA",
  currentRun: "CR"
};

export const nodeTypes = {
  time: "T",
  date: "D",
  parentCustomer: "L",
  customer: "C",
  request: "Q",
  run: "R",
  container: "M"
};

export const slotNames = {
  onCall: "On Call",
  onCallTime: "9999"
};

export const vendorTypes = {
  ironMountain: i18n.t("runManagement.ironMountain"),
  thirdPartyVendor: i18n.t("runManagement.thirdPartyVendor")
};

export const rootIds = {
  unassignedTasks: 500000,
  currentRunTasks: 3500000
};

export const requestType = {
  mediaDestruction: "-1",
  scheduledService: "1",
  addOn: "2",
  reRun: "3",
  standardSpecial: "4",
  cricitalSpecial: "5",
  disasterRecovery: "6"
};

export const globalAttributeTypeId = "118";
export const systemId = "60";
export const systemFunctionId = "54";
export const prepareForRun = "9";

export const mergeTypeValues = {
  none: 100,
  mergeCustomerOnly: 1,
  tlMergeCsl2t01: 5,
  tlMergeCslNoCustomer: 2,
  tlMergeCsl1t02: 4
};

export const customerTypes = {
  parentCustomer: 1,
  independentCustomer: 2,
  dependentCustomer: 3
};

export const modalMinWidth = "60%";

export const spaceCode = "Space";

export const reportTypes = {
  runSequence: i18n.t("runManagement.runSequence"),
  runSequenceWithDirections: i18n.t("runManagement.runSequenceWithDirections")
};

export const pickReportTab = "1";
export const arrivalReportTab = "2";
