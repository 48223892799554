import { FormControl, ThemeProvider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import {
  DEFAULT_LOCALE,
  localizationeEsPE,
  number100,
  weekFr
} from "utils/constants";
import { ReactComponent as DisabledDatePickerIcon } from "../../../assets/images/DatePickerDisableIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../../assets/images/DatePickerIcon.svg";
import { datePickerFieldTheme } from "./DatePickerField.Styles";

function DatePickerField({
  helperText,
  error,
  onBlur,
  locale,
  todayText,
  ...props
}) {
  const [newLoacal, setNewLocal] = useState(null);
  const { currentBranch } = useSelector(selectAuth);
  useEffect(() => {
    // importing the locale file based on locale prop
    if (locale !== null) {
      import(`dayjs/locale/${locale}.js`)
        .then(() => {
          setNewLocal(locale);
        })
        .catch((error) => {
          dayjs.locale(DEFAULT_LOCALE);
        });
    }
  }, [locale]);
  return (
    <ThemeProvider theme={datePickerFieldTheme}>
      <FormControl fullWidth>
        {[number100].includes(currentBranch?.district_id) &&
        localizationeEsPE === locale ? (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={locale || DEFAULT_LOCALE}
          >
            <DatePicker
              {...props}
              slots={{
                openPickerIcon: props.disabled
                  ? DisabledDatePickerIcon
                  : DatePickerIcon,
                day: props.highlightScheduleDays
              }}
              onBlur={onBlur}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error,
                  helperText,
                  value: props?.value
                },
                actionBar: {
                  actions: todayText ? todayText : ["today"]
                }
              }}
              dayOfWeekFormatter={(day) => `${weekFr[day]}`}
            />
          </LocalizationProvider>
        ) : (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={newLoacal || DEFAULT_LOCALE}
          >
            <DatePicker
              {...props}
              slots={{
                openPickerIcon: props.disabled
                  ? DisabledDatePickerIcon
                  : DatePickerIcon,
                day: props.highlightScheduleDays
              }}
              onBlur={onBlur}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error,
                  helperText,
                  value: props?.value
                },
                actionBar: {
                  actions: todayText ? todayText : ["today"]
                }
              }}
            />
          </LocalizationProvider>
        )}
      </FormControl>
    </ThemeProvider>
  );
}

export default DatePickerField;
