import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { vendorMangementStyles } from "./VendorMangementStyles";

import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useState } from "react";
import { notAvailableValue } from "utils/constants";
import { vendorManagementConstants } from "utils/constants/vendorManagementContants";
const VendorManagementModal = ({
  isCreating,
  isUpdating,
  isDeleting,
  isViewing,
  handleCancelClick,
  handleSaveClickOnModal,
  vendorManagementForm,
  countryList,
  stateList,
  selectedRow,
  saveClicked,
  vendorName,
  throwModalError,
  throwErrorMessage,
  setThrowModalError,
  getLocaleData,
  selectedCountry,
  statesData,
  provinces,
  setProvinces
}) => {
  const { t } = useTranslation();
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const handleInputChange = (name, value) => {
    vendorManagementForm.setFieldValue(name, value);
    if (name === vendorManagementConstants.countryId && value) {
      getLocaleData(value);
      vendorManagementForm.setFieldValue(
        vendorManagementConstants.stateProvinceId,
        ""
      );

      const states = statesData
        ?.filter((state) => state.country_id === value?.value)
        .map((state) => ({
          value: state.id,
          label: state.name
        }));
      states.sort((a, b) => {
        if (a.label === notAvailableValue) return -1;
        if (b.label === notAvailableValue) return 1;
        return a.label.localeCompare(b.label);
      });
      setProvinces(states);
    }
    if (typeof value === "string" && value.trim() !== "") {
      setIsFieldUpdated(true);
    }
  };

  const province = stateList?.find(
    (state) => state?.value === selectedRow?.state_province_id
  );

  const selectedProvince = stateList.find(
    (province) =>
      province.value === vendorManagementForm?.values?.stateProvinceId
  );

  const handleNoClick = () => {
    setShowConfirmPopup(false);
    handleCancelClick();
    setIsFieldUpdated(false);
  };

  const handleYesClick = () => {
    if (showConfirmPopup) {
      setShowConfirmPopup(false);
      handleSaveClickOnModal();
      setIsFieldUpdated(false);
    }
  };

  return (
    <>
      <CommonModal
        open={isCreating || isUpdating || isViewing || isDeleting}
        title={
          (isCreating && t("vendorManagement.createNewTitle")) ||
          (isUpdating && t("vendorManagement.editTitle")) ||
          (isViewing && t("vendorManagement.viewTitle")) ||
          (isDeleting && t("common.confirm"))
        }
        body={
          <>
            {throwModalError && (
              <Collapse in={throwModalError}>
                <Alert
                  severity="error"
                  sx={{ marginBottom: spacing.margingTop20 }}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowModalError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}
            {isFieldUpdated && showConfirmPopup && (
              <ConfirmPopup
                modalPopupOpen={showConfirmPopup}
                message={""}
                handleCancel={() => {
                  showConfirmPopup && setShowConfirmPopup(false);
                }}
                handleNo={handleNoClick}
                handleYes={handleYesClick}
                showCancel={showConfirmPopup}
                showNo={true}
              />
            )}
            {isDeleting ? (
              <Stack direction="row" gap={spacing.gap}>
                <p>
                  {t("vendorManagement.deleteConfirmation", {
                    name: vendorName
                  })}
                </p>
              </Stack>
            ) : isViewing ? (
              <Grid container spacing={spacing.gap}>
                <Grid item xs={4}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.name")}
                  </Typography>
                  <Typography variant="body1">{selectedRow?.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.contact")}
                  </Typography>
                  <Typography variant="body1">
                    {selectedRow?.contact_name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.account")}
                  </Typography>
                  <Typography variant="body1">
                    {selectedRow?.account_number}
                  </Typography>
                </Grid>

                <Grid item xs={4} spacing={spacing.gap}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.city")}
                  </Typography>
                  <Typography variant="body1">{selectedRow?.city}</Typography>
                </Grid>
                <Grid item xs={8} padding={spacing.gap}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.address")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={vendorMangementStyles.typographyStyles}
                  >
                    {selectedRow?.address}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.province")}
                  </Typography>
                  <Typography variant="body1">{province?.label}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.postalCode")}
                  </Typography>
                  <Typography variant="body1">{selectedRow?.zip}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.country")}
                  </Typography>
                  <Typography variant="body1">
                    {selectedCountry?.label}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.phone1")}
                  </Typography>
                  <Typography variant="body1">
                    {selectedRow?.business_phone1}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.phone2")}
                  </Typography>
                  <Typography variant="body1">
                    {selectedRow?.business_phone2}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="caption" component="small">
                    {t("vendorManagement.comment")}
                  </Typography>
                  <Typography variant="body1">
                    {selectedRow?.comment}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={spacing.gap}>
                {/* form row starts */}
                <Grid item xs={4}>
                  <TextField
                    label={t("vendorManagement.name")}
                    id="vendor-name"
                    fullWidth
                    required={isCreating || isUpdating}
                    sx={
                      isUpdating
                        ? {
                            ...vendorMangementStyles.fieldStyle,
                            ...vendorMangementStyles.inputBorderHide
                          }
                        : { ...vendorMangementStyles.fieldStyle }
                    }
                    disabled={null}
                    value={vendorManagementForm?.values?.name}
                    onChange={(event, newValue) =>
                      handleInputChange("name", event.target.value)
                    }
                    InputLabelProps={{
                      shrink:
                        vendorManagementForm?.value?.name ||
                        isUpdating ||
                        undefined
                      // focused: !isUpdating || undefined
                    }}
                    InputProps={{
                      readOnly: isUpdating || undefined,
                      disableUnderline: isUpdating || undefined
                    }}
                    helperText={
                      saveClicked && !vendorManagementForm?.values?.name
                        ? t("vendorManagement.nameRequired")
                        : null
                    }
                    FormHelperTextProps={{
                      sx: vendorMangementStyles.colorStyle
                    }}
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={t("vendorManagement.contact")}
                    id="contact"
                    fullWidth
                    sx={vendorMangementStyles.fieldStyle}
                    disabled={null}
                    value={vendorManagementForm?.values?.contactName}
                    onChange={(event, newValue) =>
                      handleInputChange("contactName", event.target.value)
                    }
                    InputLabelProps={{
                      shrink:
                        vendorManagementForm?.value?.contactName || undefined
                    }}
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={t("vendorManagement.account")}
                    id="account"
                    fullWidth
                    sx={vendorMangementStyles.fieldStyle}
                    disabled={null}
                    value={vendorManagementForm?.values?.accountNumber}
                    onChange={(event, newValue) =>
                      handleInputChange("accountNumber", event.target.value)
                    }
                    required
                    InputLabelProps={{
                      shrink:
                        vendorManagementForm?.value?.accountNumber || undefined
                    }}
                    helperText={
                      saveClicked &&
                      !vendorManagementForm?.values?.accountNumber
                        ? t("vendorManagement.accountRequired")
                        : null
                    }
                    FormHelperTextProps={{
                      sx: vendorMangementStyles.colorStyle
                    }}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                {/* form row start */}
                <Grid item xs={8}>
                  <TextField
                    label={t("vendorManagement.address")}
                    id="address"
                    fullWidth
                    disabled={null}
                    sx={vendorMangementStyles.fieldStyle}
                    value={vendorManagementForm?.values?.address}
                    onChange={(event, newValue) =>
                      handleInputChange("address", event.target.value)
                    }
                    InputLabelProps={{
                      shrink: vendorManagementForm?.value?.address || undefined
                    }}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={t("vendorManagement.city")}
                    id="vendor-city"
                    fullWidth
                    sx={vendorMangementStyles.fieldStyle}
                    disabled={null}
                    value={vendorManagementForm?.values?.city}
                    onChange={(event, newValue) =>
                      handleInputChange("city", event.target.value)
                    }
                    InputLabelProps={{
                      shrink: vendorManagementForm?.value?.city || undefined
                    }}
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>

                {/* form row start */}

                <Grid item xs={4}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="vendor-country"
                    name="countryId"
                    disabled={null}
                    options={countryList}
                    size="medium"
                    value={
                      vendorManagementForm?.values.countryId || selectedCountry
                    }
                    onChange={(event, newValue) =>
                      handleInputChange("countryId", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={t("vendorManagement.country")}
                        helperText={
                          saveClicked &&
                          !vendorManagementForm?.values?.countryId &&
                          !selectedCountry
                            ? t("vendorManagement.countryRequired")
                            : null
                        }
                        FormHelperTextProps={{
                          sx: vendorMangementStyles.colorStyle
                        }}
                      />
                    )}
                    sx={vendorMangementStyles.fieldStyle}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={t("vendorManagement.postalCode")}
                    id="zip"
                    fullWidth
                    sx={vendorMangementStyles.fieldStyle}
                    disabled={null}
                    value={vendorManagementForm?.values?.zip}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const isValid = /^[a-zA-Z0-9\s-]+$/.test(inputValue);
                      if (isValid) {
                        handleInputChange("zip", inputValue);
                      } else {
                        event.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink:
                        vendorManagementForm?.value?.postal_code || undefined
                    }}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="vendor-province"
                    name={t("vendorManagement.province")}
                    disabled={!vendorManagementForm?.values.localeId}
                    options={provinces}
                    size="medium"
                    value={
                      vendorManagementForm?.values.stateProvinceId ===
                      vendorManagementConstants.zero
                        ? null
                        : selectedProvince ||
                          vendorManagementForm?.values.stateProvinceId
                    }
                    onChange={(event, newValue) =>
                      handleInputChange("stateProvinceId", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("vendorManagement.province")}
                      />
                    )}
                    sx={vendorMangementStyles.fieldStyle}
                  />
                </Grid>

                {/* form row start */}
                <Grid item xs={4}>
                  <TextField
                    label={t("vendorManagement.phone1")}
                    id="phone1"
                    fullWidth
                    sx={vendorMangementStyles.fieldStyle}
                    disabled={null}
                    value={vendorManagementForm?.values?.businessPhone1}
                    onChange={(event, newValue) =>
                      handleInputChange("businessPhone1", event.target.value)
                    }
                    required
                    InputLabelProps={{
                      shrink:
                        vendorManagementForm?.value?.businessPhone1 || undefined
                    }}
                    helperText={
                      saveClicked &&
                      !vendorManagementForm?.values?.businessPhone1
                        ? t("vendorManagement.phoneRequired")
                        : null
                    }
                    FormHelperTextProps={{
                      sx: vendorMangementStyles.colorStyle
                    }}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={t("vendorManagement.phone2")}
                    id="phone2"
                    fullWidth
                    sx={vendorMangementStyles.fieldStyle}
                    disabled={null}
                    value={vendorManagementForm?.values?.businessPhone2}
                    onChange={(event, newValue) =>
                      handleInputChange("businessPhone2", event.target.value)
                    }
                    InputLabelProps={{
                      shrink:
                        vendorManagementForm?.value?.businessPhone2 || undefined
                    }}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>

                {/* form row start */}
                <Grid item xs={12}>
                  <TextField
                    label={t("vendorManagement.comment")}
                    id="description-modal"
                    fullWidth
                    multiline
                    disabled={null}
                    rows={3}
                    value={vendorManagementForm?.values?.comment}
                    onChange={(event, newValue) =>
                      handleInputChange("comment", event.target.value)
                    }
                    // onBlur={null}
                    InputLabelProps={{
                      shrink: vendorManagementForm?.value?.comment || undefined
                    }}
                    inputProps={{ maxLength: 2048 }}
                    sx={vendorMangementStyles.textAreaStyle}
                    onKeyDown={(e) => {
                      // Prevent typing if the length of the comment exceeds 2048 characters
                      if (
                        e.target.value.length >= 2048 &&
                        e.key !== "Backspace"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      // Get the pasted text
                      const pastedText = e.clipboardData.getData("text/plain");
                      // Check if pasted text will exceed the character limit
                      if (e.target.value.length + pastedText.length > 2048) {
                        // Prevent pasting if it will exceed the character limit
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </>
        }
        buttons={
          <>
            <Button
              id="CancelBtn-modal"
              variant={isViewing ? "contained" : "outlined"}
              onClick={() => {
                if ((isUpdating || isCreating) && isFieldUpdated) {
                  setShowConfirmPopup(true);
                } else {
                  setShowConfirmPopup(false);
                  handleCancelClick();
                }
              }}
              sx={null}
            >
              {isDeleting
                ? t("common.no")
                : isViewing
                  ? t("common.close")
                  : t("common.cancel")}
            </Button>
            {!isViewing && (
              <Button
                id="saveBtn-modal"
                variant="contained"
                disabled={null}
                onClick={handleSaveClickOnModal}
                sx={null}
              >
                {isDeleting
                  ? t("common.yes")
                  : isCreating
                    ? t("common.save")
                    : t("common.update")}
              </Button>
            )}
          </>
        }
      />
    </>
  );
};
export default VendorManagementModal;
