import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import {
  CircularLoaderFullPage,
  DatePickerField,
  SelectField
} from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useFormik } from "formik";
import { useCallbackPrompt } from "hooks";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  getCountries,
  getDistricts,
  getEmailDomains,
  getEmployeeDetailsByEmail,
  getEmployeeDetailsById,
  getJobTitles,
  getLocaleById,
  getMessageFromCode,
  getOperationalCenters
} from "services/api/query";
import {
  cancelExitProcedure,
  resetRouteState,
  selectAuth,
  selectRoute,
  setHaveChanges
} from "store/slices";
import spacing from "styles/spacing";
import {
  DAYJS_LOCALES,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  FIREBASE_FIELD_NAMES,
  N_CHECKER,
  ROLE_ACCESS_CODES,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  messageCode
} from "utils/constants";
import {
  empFailureCode,
  empMsgCode,
  fieldNames,
  generalManager,
  manager,
  operationManager,
  payrollLengthParam,
  phnType,
  systemAdmin,
  terminatedCode
} from "utils/constants/employee/EmployeeDetailConstant";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getSortedData
} from "utils/helpers";
import { employeeDetailStyles } from "./EmployeeDetailStyle";
import {
  convertToString,
  formattedDate,
  getCountryPhoneCode,
  getFormData,
  isEmailValid,
  isNumValid,
  isTextValid,
  setDateValue
} from "./helper";

const EmployeeDetailLayout = () => {
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [opCenterOptions, setOpCenterOptions] = useState([]);
  const [domainOptions, setDomainOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [searchedBranchOptions, setSearchedBranchOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [securityGroupOptions, setSecurityGroupOptions] = useState([]);
  const [securityGroup, setSecurityGroup] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [localeByBranch, setLocaleByBranch] = useState(DEFAULT_LOCALE);
  const [email, setEmail] = useState(null);
  const [domain, setDomain] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [countryPhnCode, setCountryPhnCode] = useState(null);
  const [isEmergencyContact, setIsEmergencyContact] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [activeBranch, setActiveBranch] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [description, setDescription] = useState(null);
  const [payrollIdLength, setPayrollIdLength] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [opCenterMsg, setOpCenterMsg] = useState(null);
  const [opCenterId, setOpCenterId] = useState(null);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [isSavedEndDate, setIsSavedEndDate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [employeeId, setEmployeeId] = useState(searchParams.get("id"));
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [formCopy, setFormCopy] = useState(null);
  const [localeId, setLocaleId] = useState(null);
  const [locationDetails, setLocationDetails] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const districtId = getAuthenticatedUserBranch();
  const { auth, currentBranch, roleAccess } = useSelector(selectAuth);
  const hasAccess301 = roleAccess.includes(ROLE_ACCESS_CODES.CODE_301);
  const hasAccess303 = roleAccess.includes(ROLE_ACCESS_CODES.CODE_303);
  const hasAccess217 = roleAccess.includes(ROLE_ACCESS_CODES.CODE_217);
  const hasAccess280 = roleAccess.includes(ROLE_ACCESS_CODES.CODE_280);
  // Handle navigation with unsaved changes
  const [exitAfterSave, setExitAfterSave] = useState(false);
  const { haveChanges } = useSelector(selectRoute);
  const dispatch = useDispatch();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges);
  const _ = require("lodash");

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  //form initialize using useFormik
  const employeeDetailForm = useFormik({
    initialValues: getFormData()
  });

  useEffect(() => {
    if (
      (employeeId &&
        employeeDetailForm.values[fieldNames.localeId] &&
        !employeeDetailForm.values[fieldNames.phoneCode]) ||
      (!employeeId &&
        !employeeDetailForm.values[fieldNames.phoneCode] &&
        locationDetails)
    ) {
      setDefaultCountry();
    }

    let isEqualForm = _.isEqual(employeeDetailForm?.values, formCopy);
    if (!isEqualForm) {
      if (showConfirmPopup) {
        setShowConfirmPopup(false);
      }
      dispatch(setHaveChanges(true));
      setIsUnsavedChanges(true);
    } else {
      dispatch(setHaveChanges(false));
      setIsUnsavedChanges(false);
    }
    //eslint-disable-next-line
  }, [employeeDetailForm.values, formCopy]);
  useEffect(() => {
    mainDistrictId && getMasterInfo();
    //eslint-disable-next-line
  }, [mainDistrictId]);

  useEffect(() => {
    if (allLocations.length > 0) {
      const selectedLocationDetails = allLocations.filter(
        (location) => location.district_id === districtId
      );
      setLocaleByBranch(
        DAYJS_LOCALES[selectedLocationDetails[0]?.iso_locale_code]
      );
      setLocationDetails(selectedLocationDetails[0]);
    }
    //eslint-disable-next-line
  }, [allLocations]);

  useEffect(() => {
    setSelectedBranch([districtId]);
    getPayrollIdLength();
    //eslint-disable-next-line
  }, [districtId]);

  useEffect(() => {
    if (securityGroupOptions.length) {
      employeeDetailForm.setFieldValue(fieldNames.defaultBranchId, districtId);
      employeeDetailForm.setFieldValue(
        fieldNames.loggedInEmployeeId,
        auth?.user?.employee_id
      );
      if (employeeId) {
        setEmployeeId(employeeId);
        getEmployeeData();
      } else {
        let form = {
          ..._.cloneDeep(employeeDetailForm.values),
          [fieldNames.defaultBranchId]: districtId,
          [fieldNames.loggedInEmployeeId]: auth?.user?.employee_id
        };
        setFormCopy(form);
      }
    }
    //eslint-disable-next-line
  }, [securityGroupOptions]);

  useEffect(() => {
    if (!employeeId && locationDetails) {
      setDefaultCountry();
    }
    //eslint-disable-next-line
  }, [locationDetails]);

  useEffect(() => {
    const data = securityGroup.find(
      (item) =>
        item.security_group_id ===
        employeeDetailForm.values[fieldNames.securityGroupId]
    );
    setDescription(data?.descr);
    //eslint-disable-next-line
  }, [employeeDetailForm.values[fieldNames.securityGroupId]]);

  useEffect(() => {
    startDate &&
      employeeDetailForm.setFieldValue(
        fieldNames.employmentStartDate,
        formattedDate(startDate)
      );
    endDate &&
      employeeDetailForm.setFieldValue(
        fieldNames.employmentEndDate,
        formattedDate(endDate)
      );
    //eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    if (email && domain) {
      let fullEmail = `${email}@${domain}`;
      employeeDetailForm.setFieldValue(fieldNames.businessEmailAddr, fullEmail);
      let fieldName = FIREBASE_FIELD_NAMES.BUSINESS_EMAIL_ADDR;

      checkIfEmailExists(
        fullEmail,
        fieldName,
        t("employeeDetail.businessEmailErrorMsg")
      );
    }
    //eslint-disable-next-line
  }, [email, domain]);

  useEffect(() => {
    employeeDetailForm.setFieldValue(
      fieldNames.emergencyContactFlag,
      isEmergencyContact ? Y_CHECKER : N_CHECKER
    );
    //eslint-disable-next-line
  }, [isEmergencyContact]);

  useEffect(() => {
    employeeDetailForm.setFieldValue(
      fieldNames.assignedBranchList,
      convertToString(selectedBranch)
    );
    employeeDetailForm.setFieldValue(
      fieldNames.activeBranchList,
      convertToString(activeBranch)
    );
    //eslint-disable-next-line
  }, [selectedBranch, activeBranch]);

  useEffect(() => {
    throwError && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [throwError]);

  useEffect(() => {
    !searchText && setSearchedBranchOptions(branchOptions);
    //eslint-disable-next-line
  }, [searchText]);

  const checkIfEmailExists = async (email, fieldName, errorMsg) => {
    const emailData = await getEmployeeDetailsByEmail(email, fieldName);
    const data = emailData.filter(
      (item) => item.code.trim() !== terminatedCode
    );
    if (
      (!employeeId && data.length) ||
      (employeeId && employeeId !== data[0]?.employee_id && data.length)
    ) {
      setThrowErrorMessage(errorMsg);
      setThrowError(true);
      setIsError(true);
    } else {
      setThrowErrorMessage(null);
      setThrowError(false);
      setIsError(false);
    }
  };

  const setFormCountryPhnCode = (countryPhnCode) => {
    employeeDetailForm.setFieldValue(
      fieldNames.country,
      getCountryPhoneCode(countryPhnCode)[0]?.trim()
    );
    employeeDetailForm.setFieldValue(
      fieldNames.phoneCode,
      getCountryPhoneCode(countryPhnCode)[1]?.trim()
    );
  };

  const setDefaultCountry = async () => {
    let localeData;
    if (employeeId) {
      localeData = await getLocaleById(
        employeeDetailForm.values[fieldNames.localeId],
        FIREBASE_FIELD_NAMES.LOCALE_ID
      );
    } else {
      localeData = await getLocaleById(
        locationDetails.iso_locale_code,
        FIREBASE_FIELD_NAMES.ISO_CODE
      );
    }
    let country = countryOptions.filter((item) => {
      return item.id === localeData[0]?.country_id;
    });

    let localeValue = localeData[0].locale_id;
    setLocaleId(localeValue);
    employeeDetailForm.setFieldValue(fieldNames.localeId, localeValue);

    let code = country[0]?.value;
    setCountryPhnCode(code);
    code && setFormCountryPhnCode(code);

    let form = {
      ..._.cloneDeep(employeeDetailForm.values),
      [fieldNames.country]: code && getCountryPhoneCode(code)[0]?.trim(),
      [fieldNames.phoneCode]: code && getCountryPhoneCode(code)[1]?.trim(),
      [fieldNames.localeId]: localeValue
    };
    setFormCopy(form);
  };

  const setDateInfo = async (employeeData) => {
    let startDateValue = await setDateValue(
      employeeData[fieldNames.employmentStartDate]
    );
    employeeData[fieldNames.employmentStartDate] &&
      setStartDate(startDateValue);
    let endDateValue = await setDateValue(
      employeeData[fieldNames.employmentEndDate]
    );
    employeeData[fieldNames.employmentEndDate] && setEndDate(endDateValue);
  };

  const setContactInfo = (employeeData) => {
    let fullEmail = employeeData[fieldNames.businessEmailAddr]?.split("@");
    let email = fullEmail[0];
    setEmail(email);
    let domain = fullEmail[1].trim();
    setDomain(domain);

    employeeData[fieldNames.emergencyContactFlag] === Y_CHECKER
      ? setIsEmergencyContact(true)
      : setIsEmergencyContact(false);
  };

  const setBranchInfo = (employeeData) => {
    setSelectedBranch(employeeData[fieldNames.assignedBranchList]?.split(","));
    setActiveBranch(employeeData[fieldNames.activeBranchList]?.split(","));
  };

  const getEmployeeData = async () => {
    const employeeDetails = await getEmployeeDetailsById(employeeId);
    setEmployeeDetails(employeeDetails[0]);
    let employeeData = getFormData(employeeDetails[0]);
    employeeDetailForm.setValues(employeeData);
    employeeDetailForm.setFieldValue(
      fieldNames.loggedInEmployeeId,
      auth?.user?.employee_id
    );
    setDateInfo(employeeData);

    setContactInfo(employeeData);

    setBranchInfo(employeeData);
    setOpCenterId(employeeData[fieldNames.operationalCenterId]);
    let form = _.cloneDeep(employeeDetailForm.values);
    setFormCopy(form);

    employeeData[fieldNames.employmentEndDate] && setIsSavedEndDate(true);
  };

  const getOptions = (response, value, label) => {
    const options = response.map((res) => ({
      value: res[value],
      label: res[label].trim()
    }));
    return options;
  };

  const getResponseData = async (requestBody, resultCount, url) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(
      response,
      resultCount,
      response.data.docId
    );
    return data;
  };

  const getPayrollIdLength = async () => {
    const reqBody = JSON.stringify({
      main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
      system_id: payrollLengthParam.systemId,
      setting_type_id: payrollLengthParam.settingTypeId
    });
    const data = await getResponseData(
      reqBody,
      1,
      `${CF_URLS.employeeDetail.getPayrollIdLength}`
    );
    let idArray = [];
    for (let i of data?.data[0] || []) {
      idArray.push(i.Value);
    }
    setPayrollIdLength(idArray);
  };

  const getMasterInfo = async () => {
    try {
      setIsLoading(true);
      const jobResult = await getJobTitles(districtId);
      const jobData = getSortedData(jobResult, "title", "asc");
      const jobResponse = getOptions(jobData, "job_title_id", "title");
      setJobTitleOptions(jobResponse);

      const opCenterResult = await getOperationalCenters(districtId);
      const opCenterData = getSortedData(opCenterResult, "name", "asc");
      const opCenterResponse = getOptions(
        opCenterData,
        "operational_center_id",
        "name"
      );
      setOpCenterOptions(opCenterResponse);

      const domainResult = await getEmailDomains();
      const domainResponse = getOptions(domainResult, "descr", "descr");
      setDomainOptions(domainResponse);

      const branchResult = await getDistricts();
      const branchData = getSortedData(branchResult, "name", "asc");
      const branchResponse = getOptions(branchData, "district_id", "name");
      setBranchOptions(branchResponse);
      setSearchedBranchOptions(branchResponse);

      const countryResult = await getCountries();
      const countryData = getSortedData(countryResult, "name", "asc");
      const countryResponse = countryData.map((res) => ({
        value: `${res.iso2_code} - ${res.phone_country_code}`,
        label: `${res.name} - ${res.phone_country_code}`,
        id: res.country_id
      }));
      setCountryOptions(countryResponse);

      const locationReqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      const locationsData = await getResponseData(
        locationReqBody,
        1,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.COMMON.getalllocations}`
      );

      const securityGroupReqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        filter_flag: 0
      });
      const securityGroupData = await getResponseData(
        securityGroupReqBody,
        1,
        `${CF_URLS.securityGroupEmployee.getSecurityGroupDetails}`
      );

      if (locationsData?.data[0] || securityGroupData?.data[0]) {
        setAllLocations(locationsData?.data[0]);
        if (securityGroupData?.data[0]) {
          let filteredData = securityGroupData?.data[0];
          if (!hasAccess217 || !hasAccess280) {
            filteredData = securityGroupData?.data[0].filter((item) =>
              !hasAccess217
                ? item.code.trim() !== systemAdmin
                : item.code.trim() !== manager
            );
          }

          const securityGroupList = getSortedData(filteredData, "code", "asc");
          const securityGroupResponse = getOptions(
            securityGroupList,
            "security_group_id",
            "descr"
          );
          setSecurityGroup(filteredData);
          setSecurityGroupOptions(securityGroupResponse);
        }
      } else if (locationsData?.error || securityGroupData?.error) {
        setThrowErrorMessage(locationsData.error);
        setThrowError(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const handleTextChange = async (e) => {
    const { name, value } = e.target;
    if (
      name === fieldNames.firstName ||
      name === fieldNames.middleName ||
      name === fieldNames.lastName
    ) {
      if (!isTextValid(value)) {
        return;
      }
    }
    if (name === fieldNames.extention || name === fieldNames.payrollId) {
      if (!isNumValid(value)) {
        return;
      }
    }
    employeeDetailForm.setFieldValue(name, value);
    if (name === fieldNames.jobTitleId) {
      const data = jobTitleOptions.find(
        (item) =>
          item.label === operationManager || item.label === generalManager
      );
      data ? setIsEmergencyContact(true) : setIsEmergencyContact(false);
    }
    if (value && name === fieldNames.homeEmailAddr) {
      let fieldName = FIREBASE_FIELD_NAMES.HOME_EMAIL_ADDR;
      checkIfEmailExists(
        value,
        fieldName,
        t("employeeDetail.homeEmailErrorMsg")
      );
    }
    if (name === fieldNames.operationalCenterId && employeeId) {
      const errorMsg = await getMessageFromCode(String(empMsgCode.opCenter));
      if (errorMsg) {
        let message = errorMsg[0]?.descr;
        setOpCenterMsg(message);
      }
    }
  };

  const handleDateChange = (value, fieldName) => {
    if (fieldName === fieldNames.startDate) {
      setStartDate(value);
    } else if (fieldName === fieldNames.endDate) {
      setEndDate(value);
    }
  };

  const getLocaleData = async (code) => {
    let country = countryOptions.filter((item) => {
      return item.value === code;
    });
    const localeData = await getLocaleById(
      country[0].id,
      FIREBASE_FIELD_NAMES.COUNTRY_ID
    );
    let employeeData = localeData[0];
    setLocaleId(employeeData.locale_id);
    employeeDetailForm.setFieldValue(
      [fieldNames.localeId],
      employeeData.locale_id
    );
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === fieldNames.email) {
      if (isEmailValid(value)) {
        setEmail(value);
      }
    } else if (name === fieldNames.domain) {
      setDomain(value);
    } else if (name === fieldNames.countryPhnCode) {
      setCountryPhnCode(value);
      setFormCountryPhnCode(value);
      getLocaleData(value);
    }
  };

  const handleCopyPaste = (e) => {
    e.preventDefault();
  };

  const handleAssignBranchChange = (value) => {
    let selectList = [...selectedBranch];
    let activeList = [...activeBranch];
    if (!selectList?.includes(value)) {
      selectList.push(value);
    } else {
      selectList.splice(selectList.indexOf(value), 1);
      activeList.indexOf(value) !== -1 &&
        activeList.splice(activeList.indexOf(value), 1);
    }
    setSelectedBranch(selectList);
    setActiveBranch(activeList);
    if (selectList.length === branchOptions.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  };

  const handleActiveBranchChange = (value) => {
    let activeList = [...activeBranch];
    if (!activeList?.includes(value)) {
      activeList.push(value);
    } else {
      activeList.splice(activeList.indexOf(value), 1);
    }
    setActiveBranch(activeList);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    let branches = JSON.parse(JSON.stringify(branchOptions));
    if (value.trim()) {
      const result = branches.filter((data) => {
        return data?.label?.toLowerCase().trim().includes(value?.toLowerCase());
      });
      setSearchedBranchOptions(result);
    } else {
      setSearchedBranchOptions(branchOptions);
    }
    setSearchText(value);
  };

  const handleSelectAllChange = (e) => {
    let isChecked = e.target.checked;
    let branches = JSON.parse(JSON.stringify(branchOptions));
    let selected = [];
    if (isChecked) {
      branches.filter((item) => {
        return selected.push(item.value);
      });
    } else {
      setActiveBranch([]);
    }
    setSelectedBranch(selected);
    setIsSelectAll(isChecked);
  };

  const getError = async (msgCode, fieldName, msg) => {
    const errorMsg = await getMessageFromCode(String(msgCode));
    if (errorMsg) {
      let message;
      if (msgCode) {
        message = errorMsg[0]?.descr.replace("|", fieldName).replace("|", msg);
      } else {
        message = errorMsg[0]?.descr.replace("|", fieldName);
      }
      setThrowErrorMessage(message);
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const onSaveClick = async () => {
    try {
      handleConfirmationOnYes();
      setThrowError(false);
      employeeId ? setIsNew(false) : setIsNew(true);
      let formValues = employeeDetailForm.values;
      let fieldName;
      if (
        hasAccess301 &&
        (!formValues[fieldNames.firstName] ||
          !formValues[fieldNames.lastName] ||
          !startDate ||
          !formValues[fieldNames.operationalCenterId] ||
          !formValues[fieldNames.securityGroupId])
      ) {
        if (!formValues[fieldNames.firstName]) {
          fieldName = t("employeeDetail.firstNameMsg");
        } else if (!formValues[fieldNames.lastName]) {
          fieldName = t("employeeDetail.lastNameMsg");
        } else if (!startDate) {
          fieldName = t("employeeDetail.startDateMsg");
        } else if (!formValues[fieldNames.operationalCenterId]) {
          fieldName = t("employeeDetail.opCenterMsg");
        } else if (!formValues[fieldNames.securityGroupId]) {
          fieldName = t("employeeDetail.securityGroupMsg");
        }
        getError(messageCode.requiredField, fieldName);
      } else if (!countryPhnCode) {
        fieldName = t("employeeDetail.countryMsg");
        getError(messageCode.requiredField, fieldName);
      } else if (
        hasAccess301 &&
        endDate &&
        new Date(endDate).getTime() < new Date(startDate).getTime()
      ) {
        getError(empMsgCode.endDate);
      } else if (hasAccess301 && !formValues[fieldNames.jobTitleId]) {
        getError(empMsgCode.jobTitle);
      } else if (hasAccess301 && !formValues[fieldNames.payrollId]) {
        getError(empMsgCode.payrollId);
      } else if (
        !employeeId &&
        formValues[fieldNames.payrollId].length < Math.min(...payrollIdLength)
      ) {
        getError(empMsgCode.payrollIdLengthNew, Math.min(...payrollIdLength));
      } else if (
        employeeId &&
        formValues[fieldNames.payrollId].length !==
          formCopy[fieldNames.payrollId].length &&
        formValues[fieldNames.payrollId].length < Math.min(...payrollIdLength)
      ) {
        getError(
          empMsgCode.payrollIdLengthUpdate,
          Math.min(...payrollIdLength)
        );
      } else if (
        formValues[fieldNames.extention] &&
        isNaN(formValues[fieldNames.extention])
      ) {
        fieldName = t("employeeDetail.ext");
        getError(empMsgCode.ext, fieldName);
      } else if (isEmergencyContact && !formValues[fieldNames.homePhone]) {
        fieldName = t("employeeDetail.homePhoneMsg");
        getError(empMsgCode.homePhone, fieldName);
      } else if (!email || !domain) {
        fieldName = !email
          ? t("employeeDetail.emailMsg")
          : t("employeeDetail.domainMsg");
        getError(empMsgCode.homePhone, fieldName);
      } else {
        // Phone format checking
        setIsLoading(true);
        let phoneReqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          business_phone: formValues[fieldNames.businessPhone] || "",
          cellular_phone: formValues[fieldNames.cellularPhone] || "",
          pager: formValues[fieldNames.pagerPhone] || "",
          home_phone: formValues[fieldNames.homePhone] || "",
          locale_id: localeId
        });

        let phoneData = await getResponseData(
          phoneReqBody,
          2,
          `${CF_URLS.employeeDetail.validatePhoneFormat}`
        );

        let errorType = phoneData?.data[0]?.[0]?.Bad_Format_Field;
        let errorMsg = phoneData?.data[0]?.[0]?.Bad_Format_Message;
        if (errorType !== phnType.valid) {
          if (errorType === phnType.business) {
            fieldName = t("employeeDetail.businessPhone");
          } else if (errorType === phnType.cellular) {
            fieldName = t("employeeDetail.cellularMsg");
          } else if (errorType === phnType.pager) {
            fieldName = t("employeeDetail.pager");
          } else if (errorType === phnType.home) {
            fieldName = t("employeeDetail.homePhone");
          }
          getError(empMsgCode.phoneFormat, fieldName, errorMsg);
          return;
        }

        // Validate employee changes
        if (employeeId) {
          let empReqBody = JSON.stringify({
            main_district_id: mainDistrictId,
            employee_id: employeeId,
            pay_type_id: employeeDetails.pay_type_id,
            operational_center_id: formValues[fieldNames.operationalCenterId],
            work_center_id: employeeDetails.work_center_id,
            work_category_id: employeeDetails.work_center_id
          });

          let empData = await getResponseData(
            empReqBody,
            1,
            `${CF_URLS.employeeDetail.validateEmployeeChanges}`
          );

          let errorCode = empData?.data[0]?.[0]?.return_code;
          if (errorCode === empFailureCode[0]) {
            getError(empMsgCode.empFailure1);
            return;
          } else if (errorCode === empFailureCode[1]) {
            getError(empMsgCode.empFailure2);
            return;
          }
        }

        // Insert employee
        let requestBody = JSON.stringify({
          ...employeeDetailForm.values,
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
        });

        let data = await getResponseData(
          requestBody,
          3,
          `${CF_URLS.employeeDetail.insertUpdate}`
        );
        let updateData = !employeeId ? data?.data[0][0] : data?.data[2][0];
        let form;
        if (updateData) {
          setEmployeeDetails(updateData);
          if (!employeeId) {
            setEmployeeId(updateData?.employee_id);
            employeeDetailForm.setFieldValue(
              fieldNames.employeeId,
              updateData?.employee_id
            );
            employeeDetailForm.setFieldValue(
              fieldNames.localeId,
              updateData?.locale_id
            );
            form = {
              ..._.cloneDeep(employeeDetailForm.values),
              [fieldNames.employeeId]: updateData?.employee_id,
              [fieldNames.localeId]: updateData?.locale_id
            };
          } else {
            form = _.cloneDeep(employeeDetailForm.values);
          }
          setFormCopy(form);
          dispatch(setHaveChanges(false));
          setIsUnsavedChanges(false);
          setShowConfirmPopup(false);
          setIsSavedEndDate(true);
        } else if (data?.error) {
          setThrowErrorMessage(data.error);
          setThrowError(true);
        }
        setIsLoading(false);
        window.scrollTo(0, 0);
        setIsShowSuccessPopup(true);
        if (exitAfterSave) {
          confirmNavigation();
          setExitAfterSave(false);
          return;
        }
      }
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const resetForm = () => {
    searchParams.delete("id");
    setSearchParams(searchParams);

    employeeDetailForm.resetForm();
    setFormCopy(null);
    setEmployeeId(null);
    setEmail(null);
    setDomain(null);
    setStartDate(null);
    setEndDate(null);
    setCountryPhnCode(null);
    setIsEmergencyContact(null);
    setSelectedBranch([districtId]);
    setActiveBranch([]);
    setSearchText(null);
    setDescription(null);
    setIsSelectAll(false);

    employeeDetailForm.setFieldValue(fieldNames.defaultBranchId, districtId);
    employeeDetailForm.setFieldValue(
      fieldNames.loggedInEmployeeId,
      auth?.user?.employee_id
    );
    window.scrollTo(0, 0);
  };

  const onNewClick = () => {
    if (isUnsavedChanges) {
      setShowConfirmPopup(true);
    } else {
      resetForm();
    }
  };

  const handleNoClick = () => {
    if (showConfirmPopup || showPrompt) {
      resetForm();
      handleConfirmationOnNo();
    } else {
      employeeDetailForm.setFieldValue(
        fieldNames.operationalCenterId,
        opCenterId
      );
      setOpCenterMsg(null);
    }
  };

  const handleYesClick = () => {
    if (showConfirmPopup || showPrompt) {
      setShowConfirmPopup(false);
      onSaveClick();
    } else {
      setOpCenterMsg(null);
    }
  };

  // handle confirmation popup "Yes" click event handler
  const handleConfirmationOnYes = () => {
    setExitAfterSave(false);
    if (showPrompt) {
      setExitAfterSave(true);
      cancelNavigation();
    }
    dispatch(cancelExitProcedure());
  };

  // handle confirmation popup "No" event handler
  const handleConfirmationOnNo = () => {
    dispatch(resetRouteState());
    if (showPrompt) {
      confirmNavigation();
    }
  };

  // if navigation triggered by browser button
  const handleConfirmationOnCancel = () => {
    if (showPrompt) {
      cancelNavigation();
    }
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      {/*Success message popup*/}
      {isShowSuccessPopup && (
        <SuccessPopup
          message={
            isNew
              ? t("employeeDetail.empCreateSuccess")
              : t("employeeDetail.empUpdateSuccess")
          }
          close={setIsShowSuccessPopup}
        />
      )}
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            sx={employeeDetailStyles.errorStyle}
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}

      <Grid container spacing={spacing.gap}>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.firstName}
            label={t("employeeDetail.firstName")}
            size="medium"
            variant="outlined"
            fullWidth
            value={employeeDetailForm.values[fieldNames.firstName]}
            onChange={handleTextChange}
            disabled={!hasAccess301}
            inputProps={{
              maxLength: 30
            }}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.firstName]
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.middleName}
            label={t("employeeDetail.middleName")}
            size="medium"
            variant="outlined"
            fullWidth
            value={employeeDetailForm.values[fieldNames.middleName]}
            onChange={handleTextChange}
            disabled={!hasAccess301}
            inputProps={{
              maxLength: 30
            }}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.middleName]
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.lastName}
            label={t("employeeDetail.lastName")}
            size="medium"
            variant="outlined"
            fullWidth
            value={employeeDetailForm.values[fieldNames.lastName]}
            onChange={handleTextChange}
            disabled={!hasAccess301}
            inputProps={{
              maxLength: 30
            }}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.lastName]
            }}
          />
        </Grid>
      </Grid>

      <Divider sx={employeeDetailStyles.dividerStyle} />

      {/* Employment Section */}
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("employeeDetail.employment")}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <DatePickerField
            name={fieldNames.startDate}
            label={t("employeeDetail.startDate")}
            required
            locale={localeByBranch || DEFAULT_LOCALE}
            value={startDate}
            onChange={(value) => handleDateChange(value, fieldNames.startDate)}
            disabled={!hasAccess301}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <DatePickerField
            name={fieldNames.endDate}
            label={t("employeeDetail.endDate")}
            required
            locale={localeByBranch || DEFAULT_LOCALE}
            value={endDate}
            onChange={(value) => handleDateChange(value, fieldNames.endDate)}
            disabled={
              !hasAccess301 ||
              !employeeId ||
              (employeeId && endDate && isSavedEndDate)
            }
          />
        </Grid>
      </Grid>

      <Divider sx={employeeDetailStyles.dividerStyle} />

      {/* General Information Section */}
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {t("employeeDetail.generalInformation")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <SelectField
            name={fieldNames.jobTitleId}
            label={t("employeeDetail.jobTitle")}
            options={jobTitleOptions}
            value={employeeDetailForm.values[fieldNames.jobTitleId]}
            onChange={handleTextChange}
            disabled={!hasAccess301}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <SelectField
            name={fieldNames.operationalCenterId}
            label={t("employeeDetail.defaultOperationalCenter")}
            options={opCenterOptions}
            value={employeeDetailForm.values[fieldNames.operationalCenterId]}
            onChange={handleTextChange}
            disabled={!hasAccess301}
          />
        </Grid>
      </Grid>

      <Divider sx={employeeDetailStyles.dividerStyle} />

      {/* Contact Information Section */}
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {t("employeeDetail.contactInformation")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.email}
            label={t("employeeDetail.businessEmail")}
            size="medium"
            variant="outlined"
            fullWidth
            value={email || ""}
            onChange={handleFormChange}
            onCut={handleCopyPaste}
            onCopy={handleCopyPaste}
            onPaste={handleCopyPaste}
            disabled={!employeeId && !hasAccess301}
            inputProps={{
              maxLength: 35
            }}
            InputProps={{
              endAdornment: (
                <IconButton sx={employeeDetailStyles.iconColor}>@</IconButton>
              )
            }}
            InputLabelProps={{ shrink: email }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <SelectField
            name={fieldNames.domain}
            label={t("employeeDetail.domain")}
            options={domainOptions}
            value={domain}
            onChange={handleFormChange}
            disabled={!employeeId && !hasAccess301}
            notched={domain}
            shrink={domain}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <SelectField
            name={fieldNames.countryPhnCode}
            label={t("employeeDetail.countryOrPhoneCode")}
            options={countryOptions}
            value={countryPhnCode}
            onChange={handleFormChange}
            disabled={!employeeId && !hasAccess301}
            notched={countryPhnCode}
            shrink={countryPhnCode}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.businessPhone}
            label={t("employeeDetail.businessPhone")}
            size="medium"
            variant="outlined"
            sx={employeeDetailStyles.phoneStyle}
            value={employeeDetailForm.values[fieldNames.businessPhone]}
            onChange={handleTextChange}
            disabled={!employeeId && !hasAccess301}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.businessPhone]
            }}
          />
          <TextField
            name={fieldNames.extention}
            label={t("employeeDetail.ext")}
            size="medium"
            variant="outlined"
            sx={employeeDetailStyles.extStyle}
            value={employeeDetailForm.values[fieldNames.extention]}
            onChange={handleTextChange}
            disabled={!employeeId && !hasAccess301}
            inputProps={{
              maxLength: 4
            }}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.extention]
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={spacing.gap}
        marginTop={spacing.verticalMargin10}
      >
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.cellularPhone}
            label={t("employeeDetail.cellular")}
            size="medium"
            variant="outlined"
            fullWidth
            value={employeeDetailForm.values[fieldNames.cellularPhone]}
            onChange={handleTextChange}
            disabled={!employeeId && !hasAccess301}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.cellularPhone]
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.pagerPhone}
            label={t("employeeDetail.pager")}
            size="medium"
            variant="outlined"
            fullWidth
            value={employeeDetailForm.values[fieldNames.pagerPhone]}
            onChange={handleTextChange}
            disabled={!employeeId && !hasAccess301}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.pagerPhone]
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.homeEmailAddr}
            label={t("employeeDetail.homeEmail")}
            size="medium"
            variant="outlined"
            fullWidth
            value={employeeDetailForm.values[fieldNames.homeEmailAddr]}
            onChange={handleTextChange}
            disabled={!employeeId && !hasAccess301}
            inputProps={{
              maxLength: 70
            }}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.homeEmailAddr]
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.homePhone}
            label={
              isEmergencyContact
                ? t("employeeDetail.homePhoneReq")
                : t("employeeDetail.homePhone")
            }
            size="medium"
            variant="outlined"
            fullWidth
            value={employeeDetailForm.values[fieldNames.homePhone]}
            onChange={handleTextChange}
            disabled={!employeeId && !hasAccess301}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.homePhone]
            }}
          />
        </Grid>
      </Grid>

      <Divider sx={employeeDetailStyles.dividerStyle} />

      {/* Payroll Section */}
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("employeeDetail.payroll")}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <TextField
            name={fieldNames.payrollId}
            label={t("employeeDetail.payrollId")}
            size="medium"
            variant="outlined"
            fullWidth
            value={employeeDetailForm.values[fieldNames.payrollId]}
            onChange={handleTextChange}
            disabled={!hasAccess301}
            InputLabelProps={{
              shrink: employeeDetailForm.values[fieldNames.payrollId]
            }}
            inputProps={{
              maxLength: Math.max(...payrollIdLength)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={isEmergencyContact}
                onChange={(e) => setIsEmergencyContact(e?.target?.checked)}
                disabled={!employeeId && !hasAccess301}
              />
            }
            label={t("employeeDetail.emergencyContact")}
          />
        </Grid>
      </Grid>

      <Divider sx={employeeDetailStyles.dividerStyle} />

      {/* Assign Branch Section */}
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {t("employeeDetail.assignBranch")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <SelectField
            name={fieldNames.defaultBranchId}
            label={t("employeeDetail.defaultBranch")}
            options={branchOptions}
            value={employeeDetailForm.values[fieldNames.defaultBranchId]}
            onChange={handleTextChange}
            disabled={!hasAccess301}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={spacing.gap}
        marginTop={spacing.verticalMargin10}
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={spacing.gap}>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <TextField
                    label={t("menu.search")}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={searchText || ""}
                    onChange={handleSearchChange}
                    disabled={!hasAccess301}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={isSelectAll}
                        onChange={(e) => handleSelectAllChange(e)}
                        disabled={!hasAccess301}
                      />
                    }
                    label={t("common.selectAll")}
                  />
                </Grid>
                <Typography sx={employeeDetailStyles.countTextStyle}>
                  {selectedBranch?.length || 0}{" "}
                  {t("employeeDetail.branchesSelected")}
                </Typography>
              </Grid>
              <Divider sx={employeeDetailStyles.dividerStyle} />
              <Grid
                container
                spacing={spacing.gap}
                sx={employeeDetailStyles.branchSectionstyle}
              >
                {searchedBranchOptions.map((item) => (
                  <Grid item xs={12} md={6} lg={3} xl={3} key={item.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedBranch?.includes(item.value)}
                          onChange={() => handleAssignBranchChange(item.value)}
                          disabled={!hasAccess301}
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={activeBranch?.includes(item.value)}
                          onChange={() => handleActiveBranchChange(item.value)}
                          disabled={
                            !selectedBranch?.includes(item.value) ||
                            !hasAccess301
                          }
                        />
                      }
                    />
                    {item.label}
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Typography sx={employeeDetailStyles.hintTextStyle}>
        {t("employeeDetail.branchHintText")}
      </Typography>

      <Divider sx={employeeDetailStyles.dividerStyle} />

      {/* Security Section */}
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("menu.security")}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <SelectField
            name={fieldNames.securityGroupId}
            label={t("employeeDetail.securityGroup")}
            options={securityGroupOptions}
            value={employeeDetailForm.values[fieldNames.securityGroupId]}
            onChange={handleTextChange}
            disabled={!hasAccess303 || (!employeeId && !hasAccess301)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <TextField
            label={t("common.description")}
            size="medium"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={description || ""}
            disabled
            InputLabelProps={{ shrink: description }}
          />
        </Grid>
      </Grid>

      <Divider sx={employeeDetailStyles.dividerStyle} />

      <Stack direction="row" spacing={spacing.verticalMargin20}>
        <Button
          id="new-button"
          variant="outlined"
          color="primary"
          size="medium"
          type="button"
          onClick={onNewClick}
          disabled={!hasAccess301}
        >
          {t("common.new")}
        </Button>
        <Button
          id="save-button"
          variant="contained"
          color="primary"
          size="medium"
          type="button"
          onClick={onSaveClick}
          disabled={
            isError || !isUnsavedChanges || (!employeeId && !hasAccess301)
          }
        >
          {t("common.save")}
        </Button>
      </Stack>

      {/* Confimation popup */}
      {(opCenterMsg || showConfirmPopup || showPrompt) && (
        <ConfirmPopup
          modalPopupOpen={opCenterMsg || showConfirmPopup || showPrompt}
          message={!showConfirmPopup || !showPrompt ? opCenterMsg : ""}
          handleCancel={() => {
            showConfirmPopup && setShowConfirmPopup(false);
            handleConfirmationOnCancel();
          }}
          handleNo={handleNoClick}
          handleYes={handleYesClick}
          showCancel={showConfirmPopup || showPrompt}
          showNo={true}
        />
      )}
    </>
  );
};
export default EmployeeDetailLayout;
