import {
  Button,
  Grid,
  Paper,
  SvgIcon,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as Check } from "assets/images/CheckFilled.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { ReactComponent as EditIconBeforeTheSave } from "assets/images/EditIconBeforeSave.svg";
import { t } from "i18next";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  VALUE_EMPTY_STRING,
  YES
} from "utils/constants";
import { branchOutagePickListCompletionStyles } from "./BranchOutagePickListStyles";

const { default: MUIDataTable } = require("mui-datatables");

const BranchOutagePickListTable = ({
  pickListData,
  selectedRow,
  isModalOpen,
  handleRowClick,
  handleRowSelect,
  isFindClicked,
  isPrintShipListBtnEnabled,
  handlePrintShipBtn
}) => {
  const columns = [
    {
      name: VALUE_EMPTY_STRING,
      label: VALUE_EMPTY_STRING,
      options: {
        customBodyRender: (value, tableMeta) => {
          const isSelectedRow =
            selectedRow && selectedRow.rowIndex === tableMeta.rowIndex;
          const isEdited = pickListData[tableMeta.rowIndex]?.isEdited;
          const iconComponent =
            isSelectedRow && isModalOpen
              ? Check
              : isEdited
                ? EditIconBeforeTheSave
                : EditIcon;

          const competed = tableMeta.rowData[4];
          return competed !== YES ? (
            <Button
              onClick={() => {
                handleRowClick();
              }}
            >
              <SvgIcon component={iconComponent} />
            </Button>
          ) : null;
        }
      }
    },
    {
      name: "customer_number",
      label: t("branchOutagePickList.customer")
    },
    {
      name: "logical_vault_code",
      label: t("branchOutagePickList.logicalVault")
    },
    {
      name: "list",
      label: t("branchOutagePickList.list")
    },
    {
      name: "completeList",
      label: t("branchOutagePickList.completeList")
    },
    {
      name: "assignTransportList",
      label: t("branchOutagePickList.assignTransportToList"),
      options: {
        customBodyRender: (value) => {
          return (
            <div
              style={{
                ...branchOutagePickListCompletionStyles.wrapStyles,
                maxWidth: "200px"
              }}
            >
              {value}
            </div>
          );
        }
      }
    },
    {
      name: "batch_id",
      label: t("branchOutagePickList.batch")
    },
    {
      name: "data_row_count",
      label: t("branchOutagePickList.count")
    },
    {
      name: "formattedServiceDate",
      label: t("branchOutagePickList.serviceDate")
    },
    {
      name: "task_status",
      label: t("branchOutagePickList.taskStatus")
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: pickListData?.length && true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="contained"
            size="medium"
            type="button"
            disabled={!isPrintShipListBtnEnabled}
            onClick={handlePrintShipBtn}
          >
            {t("branchOutagePickList.printShipList")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          pickListData?.length === 0 && isFindClicked
            ? `${t("common.noRecordsFound")}`
            : pickListData?.length === 0 &&
              `${t("common.tableTextWithoutInputs")}`
      }
    },

    onRowClick: handleRowSelect,

    setRowProps: (row, dataIndex) => {
      const rowData = pickListData[dataIndex];
      const completedFlag = rowData ? rowData.completeList : null;

      return {
        style: {
          backgroundColor:
            dataIndex === selectedRow?.rowIndex
              ? branchOutagePickListCompletionStyles?.selectedColor
              : completedFlag === YES
                ? branchOutagePickListCompletionStyles?.completeColor
                : VALUE_EMPTY_STRING
        }
      };
    }
  };
  return (
    <>
      <Paper sx={branchOutagePickListCompletionStyles?.tablePaper}>
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={pickListData}
            title={
              isFindClicked && (
                <Typography
                  style={branchOutagePickListCompletionStyles.titleStyle}
                >
                  {pickListData.length} {t("common.recordsFound")}
                </Typography>
              )
            }
          />
        </TableContainer>
      </Paper>
    </>
  );
};
export default BranchOutagePickListTable;
