import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { ReactComponent as ArrowDownDisabled } from "assets/images/ArrowDownDisabled.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/ArrowDown.svg";
function SelectField({
  label,
  labelId,
  options,
  error,
  helperText,
  notched,
  shrink,
  loading = false,
  required = false,
  onChange,
  objectSelection,
  ...props
}) {
  return (
    <FormControl fullWidth required={required}>
      <InputLabel id={labelId} error={error} shrink={shrink}>
        {label}
      </InputLabel>
      <Select
        IconComponent={props.disabled ? ArrowDownDisabled : ArrowDown}
        notched={notched}
        labelId={labelId}
        label={label}
        error={error}
        {...props}
        onChange={(e) => {
          if (objectSelection) {
            const selectedValue = e.target.value;
            const selectedOption = options.find(
              (option) => option.value === selectedValue
            );

            onChange(e, selectedOption);
          } else {
            onChange(e);
          }
        }}
      >
        {loading && <MenuItem disabled>Loading...</MenuItem>}
        {!loading &&
          options?.map((option, index) => (
            <MenuItem key={index} value={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
      </Select>

      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default SelectField;
