import { Stack } from "@mui/material";
import OpenMediaAuditDetailScreen from "components/open-media-processing/open-media-audit-detail/OpenMediaAuditDetailScreen";
import { PageTitle } from "components/shared";
import { useState } from "react";
import i18n from "../../i18n";

const OpenMediaAuditDetail = () => {
  const [isEmail, setIsEmail] = useState(false);
  const [isPossibleMatch, setIsPossibleMatch] = useState(false);

  const handleTitle = () => {
    if (isEmail) {
      return i18n.t("containerAuditDetails.emailRecipients");
    } else if (isPossibleMatch) {
      return i18n.t("openMediaAuditDetail.openMediaAuditPossibleMatches");
    } else {
      return i18n.t("menu.openMediaAuditDetail");
    }
  };
  return (
    <Stack>
      <PageTitle title={handleTitle()} />
      <OpenMediaAuditDetailScreen
        setIsEmail={setIsEmail}
        isEmail={isEmail}
        isPossibleMatch={isPossibleMatch}
        setIsPossibleMatch={setIsPossibleMatch}
      />
    </Stack>
  );
};
export default OpenMediaAuditDetail;
