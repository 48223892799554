import { createSlice } from "@reduxjs/toolkit";

const customerScheduleSlice = createSlice({
  name: "customerSchedule",
  initialState: {
    viewModeId: null
  },
  reducers: {
    setViewModeId(state, action) {
      state.viewModeId = action?.payload;
    }
  }
});

export const { setViewModeId } = customerScheduleSlice.actions;

export default customerScheduleSlice.reducer;
