import i18n from "i18n";
import { maxDate } from "utils/constants/disaster-module/EventSearchConstants";
import * as yup from "yup";

export const filterSchema = yup.object().shape({
  fromDate: yup
    .date()
    .nullable()
    .typeError(i18n.t("disasterRecovery.eventSearch.invalidDate"))
    .test(
      "valid-date",
      i18n.t("disasterRecovery.eventSearch.invalidDate"),
      function (toDate, context) {
        const fromDate = context?.parent?.fromDate;
        const normalizeDate = (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return (
          fromDate &&
          !isNaN(fromDate.getTime()) &&
          normalizeDate(fromDate) >=
            normalizeDate(new Date(i18n.t("disasterRecovery.eventSearch.minimumDate"))) &&
          (!toDate ||
            normalizeDate(fromDate) <=
              normalizeDate(new Date(maxDate)))
        );
      }
    ),
  toDate: yup
    .date()
    .nullable()
    .typeError(i18n.t("disasterRecovery.eventSearch.invalidDate"))
    .test(
      "valid-date",
      i18n.t("disasterRecovery.eventSearch.invalidDate"),
      function (fromDate, context) {
        const toDate = context?.parent?.toDate;

        const normalizeDate = (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate());

        return (
          toDate &&
          !isNaN(toDate.getTime()) &&
          normalizeDate(toDate) >=
            normalizeDate(new Date(i18n.t("disasterRecovery.eventSearch.minimumDate"))) &&
          (!fromDate ||
            normalizeDate(toDate) <=
              normalizeDate(new Date(maxDate)))
        );
      }
    )
    .test("custom-validation", function (toDate, context) {
      const fromDate = context?.parent?.fromDate;
      const normalizeDate = (date) =>
        new Date(date.getFullYear(), date.getMonth(), date.getDate());

      if (
        !fromDate ||
        !toDate ||
        (normalizeDate(toDate) instanceof Date &&
          normalizeDate(fromDate) <= normalizeDate(toDate))
      ) {
        return true;
      }

      return this.createError({
        path: this.path,
        message: i18n.t("disasterRecovery.eventSearch.endDateGreaterThanStartDate")
      });
    })
});
