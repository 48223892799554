import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  TableContainer,
  Typography
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import AccountSummaryTable from "./AccountSummaryTable";
import ContainerDiscrepancySummary from "./ContainerDiscrepancySummary";
import CustomerWithDiscrepancyDetectedMostFrequently from "./CustomerWithDiscrepancyDetectedMostFrequently";
import OpenMediaDiscrepancySummary from "./OpenMediaDiscrepancySummary";
import OpenMediaOtherExceptions from "./OpenMediaOtherExceptions";

const VaultTables = ({
  accountSummeryList,
  oMDiscrepancySummaryList,
  oMDiscrepancyOtherExList,
  containerDiscrepancySummaryList,
  customerfrequentlyList,
  accordionShow,
  handleOmExceptionDetails,
  handleDiscrepancySearch
}) => {
  const [accordionExpanded, setAccordionExpanded] = useState({
    accountSummery: accountSummeryList?.length !== 0,
    oMDiscrepancySummary: oMDiscrepancySummaryList?.length !== 0,
    oMDiscrepancyOtherEx: oMDiscrepancyOtherExList?.length !== 0,
    containerDiscrepancySummary: containerDiscrepancySummaryList?.length !== 0,
    customerfrequently: customerfrequentlyList?.length !== 0
  });
  useEffect(() => {
    setAccordionExpanded({
      accountSummery: accountSummeryList?.length !== 0,
      oMDiscrepancySummary: oMDiscrepancySummaryList?.length !== 0,
      oMDiscrepancyOtherEx: oMDiscrepancyOtherExList?.length !== 0,
      containerDiscrepancySummary:
        containerDiscrepancySummaryList?.length !== 0,
      customerfrequently: customerfrequentlyList?.length !== 0
    });

    //eslint-disable-next-line
  }, [
    accountSummeryList?.length,
    oMDiscrepancySummaryList?.length,
    oMDiscrepancyOtherExList?.length,
    containerDiscrepancySummaryList?.length,
    customerfrequentlyList?.length
  ]);

  const getSortedVaultReport = (arr) => {
    if (arr.length === 0) {
      return [];
    }

    return arr.toSorted((a, b) => {
      if (
        a["customer_number"].toLowerCase() < b["customer_number"].toLowerCase()
      ) {
        return 1;
      }
      if (
        a["customer_number"].toLowerCase() > b["customer_number"].toLowerCase()
      ) {
        return -1;
      }
      return 0;
    });
  };

  return (
    <Grid item md={12} lg={12} xs={12}>
      {accordionShow?.accountSummery && (
        <Accordion
          expanded={accordionExpanded?.accountSummery}
          onChange={() => {
            setAccordionExpanded({
              ...accordionExpanded,
              accountSummery: !accordionExpanded?.accountSummery
            });
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t("reports.vaultStatusReport.accountSummary")}
          </AccordionSummary>
          <AccordionDetails>
            <Paper>
              <TableContainer>
                <AccountSummaryTable
                  tableData={getSortedVaultReport(accountSummeryList)}
                />
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </Accordion>
      )}
      {accordionShow?.oMDiscrepancySummary && (
        <Accordion
          expanded={accordionExpanded?.oMDiscrepancySummary}
          onChange={() => {
            setAccordionExpanded({
              ...accordionExpanded,
              oMDiscrepancySummary: !accordionExpanded?.oMDiscrepancySummary
            });
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t("reports.vaultStatusReport.openMediaDiscrepancySummary")}
          </AccordionSummary>
          <AccordionDetails>
            <Typography> {t("reports.vaultStatusReport.text2")}</Typography>
            <Paper>
              <TableContainer>
                <OpenMediaDiscrepancySummary
                  tableData={getSortedVaultReport(oMDiscrepancySummaryList)}
                  handleDiscrepancySearch={handleDiscrepancySearch}
                />
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </Accordion>
      )}
      {accordionShow?.oMOtherException && (
        <Accordion
          expanded={accordionExpanded?.oMDiscrepancyOtherEx}
          onChange={() => {
            setAccordionExpanded({
              ...accordionExpanded,
              oMDiscrepancyOtherEx: !accordionExpanded?.oMDiscrepancyOtherEx
            });
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t("reports.vaultStatusReport.openMediaOtherExceptions")}
          </AccordionSummary>
          <AccordionDetails>
            <Paper>
              <TableContainer>
                <OpenMediaOtherExceptions
                  tableData={getSortedVaultReport(oMDiscrepancyOtherExList)}
                  handleOmExceptionDetails={handleOmExceptionDetails}
                />
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </Accordion>
      )}
      {accordionShow?.contianer && (
        <Accordion
          expanded={accordionExpanded?.containerDiscrepancySummary}
          onChange={() => {
            setAccordionExpanded({
              ...accordionExpanded,
              containerDiscrepancySummary:
                !accordionExpanded?.containerDiscrepancySummary
            });
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t("reports.vaultStatusReport.containerDiscrepancySummary")}
          </AccordionSummary>
          <AccordionDetails>
            <Typography> {t("reports.vaultStatusReport.text2")}</Typography>
            <Paper>
              <TableContainer>
                <ContainerDiscrepancySummary
                  tableData={getSortedVaultReport(
                    containerDiscrepancySummaryList
                  )}
                  handleDiscrepancySearch={handleDiscrepancySearch}
                />
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </Accordion>
      )}
      {accordionShow?.customerfrequently && (
        <Accordion
          expanded={accordionExpanded?.customerfrequently}
          onChange={() => {
            setAccordionExpanded({
              ...accordionExpanded,
              customerfrequently: !accordionExpanded?.customerfrequently
            });
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t(
              "reports.vaultStatusReport.customerWithDiscrepancyDetectedMostFrequently"
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography> {t("reports.vaultStatusReport.text3")}</Typography>
            <Paper>
              <TableContainer>
                <CustomerWithDiscrepancyDetectedMostFrequently
                  tableData={getSortedVaultReport(customerfrequentlyList)}
                />
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </Accordion>
      )}
    </Grid>
  );
};

export default VaultTables;
