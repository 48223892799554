import { multiSectionDigitalClockSectionClasses } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker as TimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { timePickerStyles } from "./TimePickerStyles";

const TimePickerField = (props) => {
  const { label, value, setValue, required = false } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        format="HH:mm:ss"
        slotProps={{
          textField: { required },
          layout: {
            sx: {
              [`.${multiSectionDigitalClockSectionClasses.root}:after`]:
                timePickerStyles.rootAfter // Using the imported styles
            }
          }
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default TimePickerField;
