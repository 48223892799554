export const CancelAuthenticationModalStyles = {
  CancelModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1140px",
    bgcolor: "background.paper",
    borderRadius: "9px",
    boxShadow: 24
  },
  CancelTitle: {
    color: "var(--color-im-dark-blue)",
    fontSize: "24px",
    margin: "20px",
    fontWeight: "400"
  },
  CancelSubTitle: {
    fontSize: "24px",
    fontWeight: "400"
  },
  CancelModal: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
    paddingLeft: "1em",
    paddingRight: "1em",
    borderTop: 1,
    borderBottom: 1,
    borderColor: "#0000001F"
  },
  CancelModalTextField: {
    width: "310px",
    height: "76px",
    gap: "20px"
  },
  CancelModalButton: {
    margin: "1em"
  },
  TextTransformNone: {
    textTransform: "initial",
    fontWeight: "500",
    borderColor: "1px solid rgba(0,0,0,0.5)"
  }
};
