import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";
import OpenMediaTableRewrite from "../tables/OpenMediaTable";

const OpenMediaSection = (props) => {
  const { t } = useTranslation();
  return (
    <Stack gap="20px" sx={MDRVaultItemizedStyles.openMediaSection_MainStack}>
      <Box sx={MDRVaultItemizedStyles.openMediaSection_MainBox}>
        <Typography variant="h5">{t("vaultItemized.openMedia")}</Typography>
      </Box>

      <OpenMediaTableRewrite {...props} />
    </Stack>
  );
};

export default OpenMediaSection;
