import { CF_URLS } from "services/api/endpoints";
import { DISTRICT_ID_GET_ALL_LOCATIONS, ERROR_TYPES } from "utils/constants";
import {
  exchangeStatusId,
  systemIdSixty
} from "utils/constants/reports-module/Reports";
import { findErrorMessage, getResponseData, printReport } from "utils/helpers";

// sp Name : rp_sched_expiry_sp
export const postExpiryData = async (mainDistrictId, expiry_date) => {
  let reqBody = {
    main_district_id: mainDistrictId,
    expiry_date: expiry_date
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.customerScheduleExpiry,
    4
  );
  return res;
};

// sp Name : customer_l10m_sp

export const customerSearch = async (mainDistrictId, expiry_date) => {
  let reqBody = {
    main_district_id: mainDistrictId,
    expiry_date: expiry_date
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.customerSearch,
    4
  );
  return res;
};
// sp Name : sb_fs_get_temporary_transport_l1_sp

export const temporaryTransport = async (mainDistrictId) => {
  let reqBody = {
    main_district_id: mainDistrictId
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.listactiveTransports,
    1
  );
  return res;
};

// sp Name : sb_fs_get_last_gen_run_date_sp

export const lastGenRunDate = async (mainDistrictId) => {
  let reqBody = {
    main_district_id: mainDistrictId
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.lastGenRunDate,
    1
  );
  return res;
};

// sp Name: sb_fs_get_run_dates_sp
export const getRunDates = async (mainDistrictId) => {
  let reqBody = {
    main_district_id: mainDistrictId
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.getRunDates,
    2
  );
  return res;
};

//sb_fs_generate_arcvms_library_sp
export const openMediaContainerInfo = async (mainDistrictId, data) => {
  const extractContent = data?.extractContent;
  const extractInventoryBy = data?.extractInventoryBy;
  const customers = data?.customers;
  const customerLabels = customers.label.split("-")?.[0]?.trim(" ");

  let reqBody = {
    main_district_id: mainDistrictId,
    extract_by_id: String(extractInventoryBy?.id),
    extract_by_value: customerLabels,
    extract_content_id: String(extractContent?.id)
  };

  let res = await getResponseData(
    reqBody,
    CF_URLS.reports.openMediaContainerInfo,
    2
  );
  return res;
};

//  Sp Name: sb_fs_run_l2_sp

export const listRunsByDate = async (mainDistrictId, run_date) => {
  let reqBody = {
    main_district_id: mainDistrictId,
    run_date: run_date,
    exchange_status_id: exchangeStatusId
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.listRunsByDate,
    2
  );
  return res;
};

// component Name: billing Activity

export const getSettingsValueReportDropdown = async () => {
  let reqBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    system_id: systemIdSixty
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.getSettingValues,
    1
  );
  return res;
};
//holiday service sp
export const getHolidayService = async (mainDistrictId) => {
  let reqBody = {
    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
    district_id: mainDistrictId
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.getRequestHelp,
    1
  );
  return res;
};

// common download function
// integrates
// 1 : sb_fs_wrapper_rp_terminate_sales_sp

export const reportsDownloader = async (updateFun, reqBody, url) => {
  try {
    updateFun?.setIsLoading(true);
    let reqBodyString = JSON.stringify(reqBody);
    let res = await printReport(reqBodyString, `${url}`);
    if (!res.success) {
      updateFun.setThrowErrorMessage(res.error);
      updateFun.setThrowError(true);
    }
    updateFun?.setIsLoading(false);
  } catch (e) {
    updateFun?.setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    updateFun?.setThrowError(true);
  } finally {
    updateFun?.setIsLoading(false);
  }
};

//roadnet adhoc sp
export const getRoadNetAdhocData = async (
  district_id,
  branch_name,
  dateTime
) => {
  let reqBody = {
    main_district_id: district_id,
    branch_name: branch_name,
    published_datetime: dateTime
  };
  let reqBodyString = JSON.stringify(reqBody);
  let res = await getResponseData(
    reqBodyString,
    CF_URLS.reports.getRoadNetAdhocData,
    1
  );
  return res;
};
