import { Box, Checkbox, Divider, Grid, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS, VALUE_N } from "utils/constants";
import { buildType } from "utils/constants/disaster-module/EventSearchConstants";
import { disasterRecoveryStyles } from "../DisasterRecoveryStyles";

const OpenMediaContainerGrid = ({
  customerLocations,
  title,
  subTitle,
  includeNonIM = false,
  openMediaList,
  containerList,
  setOpenMediaList,
  setContainerList,
  page
}) => {
  const [isIncludeIM, setIsIncludeIM] = useState(true);
  const handleOpenMediaSelect = (tableMeta) => {
    const updatedOpenMediaList = openMediaList.map((openMedia) => {
      if (openMedia.volser === tableMeta.rowData[1]) {
        openMedia.selected = !openMedia.selected;
      }
      return openMedia;
    });
    setOpenMediaList(updatedOpenMediaList);
  };
  const selectedOpenMediaCount = useMemo(
    () => openMediaList?.filter((item) => item.selected).length,
    [openMediaList]
  ); // selected open media count

  const selectedContainers = useMemo(
    () => containerList?.filter((item) => item.selected).length,
    [containerList]
  );

  const handleContainerSelect = (tableMeta) => {
    const updatedContainerList = containerList.map((container) => {
      if (container.container_number === tableMeta.rowData[1]) {
        container.selected = !container.selected;
      }
      return container;
    });
    setContainerList(updatedContainerList);
  };

  const handleNonIM = (e) => {
    setIsIncludeIM(e.target.checked);
  };

  useEffect(() => {
    if (isIncludeIM) {
      const updatedOpenMediaList = openMediaList?.map((openMedia) => {
        if (openMedia.at_irm_flag === VALUE_N) {
          openMedia.selected = true;
        }
        return openMedia;
      });
      setOpenMediaList(updatedOpenMediaList);
      if (page === buildType.landingPage) {
        const updatedContainerList = containerList?.map((container) => {
          if (container.at_irm_flag === VALUE_N) {
            container.selected = true;
          }
          return container;
        });
        setContainerList(updatedContainerList);
      }
    } else {
      const updatedOpenMediaList = openMediaList?.map((openMedia) => {
        if (openMedia.at_irm_flag === VALUE_N) {
          openMedia.selected = false;
        }
        return openMedia;
      });
      setOpenMediaList(updatedOpenMediaList);
      if (page === buildType.landingPage) {
        const updatedContainerList = containerList?.map((container) => {
          if (container.at_irm_flag === VALUE_N) {
            container.selected = false;
          }
          return container;
        });
        setContainerList(updatedContainerList);
      }
    }
    // eslint-disable-next-line
  }, [isIncludeIM, page]);

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    tableBodyMaxHeight: "500px",
    pagination: 0 & true,
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    },
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          {/* Requested open media count */}
          <Typography sx={disasterRecoveryStyles.tableStyles.selectedCount}>
            {selectedOpenMediaCount}{" "}
            {t("disasterRecovery.eventDetail.openMediaSelected")}
          </Typography>
        </Grid>
      </>
    )
  };

  const optionsContainer = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    tableBodyHeight: "200px",
    tableBodyMaxHeight: "200px",
    pagination: 0 & true,
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    },
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Typography sx={disasterRecoveryStyles.tableStyles.selectedCount}>
            {selectedContainers}{" "}
            {t("disasterRecovery.eventDetail.containerSelected")}
          </Typography>
        </Grid>
      </>
    )
  };
  const columnOpenMedia = [
    {
      name: "selected",
      label: t("disasterRecovery.eventDetail.manualEntry.selected"),
      options: {
        filter: true,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Checkbox
              checked={value === true}
              onClick={() => handleOpenMediaSelect(tableMeta)}
            />
          );
        },
        customHeadLabelRender: (columnMeta) => <div>{columnMeta.label}</div>
      }
    },
    {
      name: "volser",
      label: t("disasterRecovery.eventDetail.manualEntry.openMedia")
    },
    {
      name: "om_status",
      label: t("disasterRecovery.eventDetail.manualEntry.status")
    },
    {
      name: "short_descr",
      label: t("disasterRecovery.eventDetail.manualEntry.mediaType")
    },
    {
      name: "drp_code",
      label: t("disasterRecovery.eventDetail.manualEntry.drpCode")
    },
    {
      name: "om_descr",
      label: t("disasterRecovery.eventDetail.manualEntry.description")
    }
  ];

  const columnContainer = [
    {
      name: "selected",
      label: t("disasterRecovery.eventDetail.manualEntry.selected"),
      options: {
        filter: true,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Checkbox
              checked={value === true}
              onClick={() => handleContainerSelect(tableMeta)}
            />
          );
        },
        customHeadLabelRender: (columnMeta) => <div>{columnMeta.label}</div>
      }
    },
    {
      name: "container_number",
      label: t("disasterRecovery.eventDetail.manualEntry.containerNumber")
    },
    {
      name: "exchange_status_type",
      label: t("disasterRecovery.eventDetail.manualEntry.status")
    },
    {
      name: "media_type_descr",
      label: t("disasterRecovery.eventDetail.manualEntry.mediaType")
    }
  ];
  return (
    <>
      <Stack
        gap={spacing.gap}
        sx={disasterRecoveryStyles.requestGeneratorStyles.rightPanelWrapper}
      >
        <Typography
          sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
          mb={spacing.gap10}
        >
          {title}
        </Typography>
        <Typography
          mb={spacing.gap10}
          sx={disasterRecoveryStyles.requestGeneratorStyles.subTitle}
        >
          {subTitle}
        </Typography>
        {includeNonIM && (
          <Box sx={disasterRecoveryStyles.tableStyles.displayFlex}>
            <Checkbox checked={isIncludeIM} onChange={handleNonIM} />
            <Typography
              sx={disasterRecoveryStyles.requestGeneratorStyles.subTitle}
              mt={spacing.gap10}
            >
              {t("disasterRecovery.eventDetail.includeMeidaNotIM")}
            </Typography>
          </Box>
        )}
        <Divider />
        <Typography
          sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
          mb={spacing.gap10}
        >
          {t("disasterRecovery.eventDetail.rgOpenMedia")}
        </Typography>
        <MUIDataTable
          options={options}
          data={openMediaList}
          columns={columnOpenMedia}
        />
        {page === buildType.landingPage && (
          <>
            <Typography
              sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
              mb={spacing.gap10}
            >
              {t("disasterRecovery.eventDetail.containers")}
            </Typography>
            <MUIDataTable
              options={optionsContainer}
              data={containerList}
              columns={columnContainer}
            />
          </>
        )}
      </Stack>
    </>
  );
};
export default OpenMediaContainerGrid;
