import {
  mediaDestructionGetAllRequestStatus,
  mediaDestructionGetAllRequestTypes,
  mediaDestructionRequestSearch
} from "services/common";
import { TAG_TYPES } from "utils/constants";

import { baseApi } from "../baseApi";

/**
 * @module destructionRequestApi destruction request search RTK Query endpoints
 */
export const destructionRequestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    mediaDestructionRequestSearch: builder.mutation({
      queryFn: async (props) => {
        try {
          const response = await mediaDestructionRequestSearch(props);
          return {
            data: response
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.mediaDestructionRequestSearch]
    }),
    mediaDestructionGetAllRequestTypes: builder.query({
      queryFn: async () => {
        try {
          const response = await mediaDestructionGetAllRequestTypes();
          return {
            data: response?.map(({ descr, destruct_request_type_id }) => ({
              label: descr,
              value: destruct_request_type_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.destructionRequestTypes]
    }),

    mediaDestructionGetAllRequestStatus: builder.query({
      queryFn: async () => {
        try {
          const response = await mediaDestructionGetAllRequestStatus();
          return {
            data: response?.map(({ descr, destruct_request_status_id }) => ({
              label: descr,
              value: destruct_request_status_id
            }))
          };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.destructionRequestStatus]
    })
  })
});
