import { Stack } from "@mui/material";
import Transport from "components/container-processing/reassian-transport/Transport";

import { PageTitle } from "components/shared";
import i18n from "i18n";
const ReassianTransport = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("reassignTransportContents.title")} />
      <Transport />
    </Stack>
  );
};
export default ReassianTransport;
