export const CustomerOnHoldStyles = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    height: "auto",
    maxHeight: "19rem"
  },
  title: {
    color: "var(--color-dashboard-text)",
    fontFamily: "Roboto",
    fontSize: "1.625rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "1.5rem auto 1.8rem auto"
  },
  alertMargin: {
    marginBottom: "20px"
  },
  listContainer: {
    alignItems: "flex-start",
    overflow: "auto",
    marginBottom: "1.5rem"
  },
  menuItem: {
    paddingLeft: 0,
    marginRight: "6.38rem",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  item: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center"
  }
};
