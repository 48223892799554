import { Stack } from "@mui/material";
import { PageTitle } from "components/shared";
import VaultConfiguration from "components/vault-configuration/VaultConfiguration";
import i18n from "i18n";

const VaultConfigurationScreen = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={i18n.t("vaultConfiguration.title")} />
      <VaultConfiguration />
    </Stack>
  );
};
export default VaultConfigurationScreen;
