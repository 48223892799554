export const entryCode = "5";
export const requestGeneratorServiceCode = "-1";
export const possibleMatchesCompare = "Possible Matches";

export const severity = {
  error: "error",
  warning: "warning"
};

export const startsTR = "TR";
export const theContaonerOf = "the container or ";
export const delivered = "delivered";
export const transport = "transport";
export const orTransport = "or transport ";
export const theContainer = "the container";
