/* eslint-disable eqeqeq */
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { DatePickerField } from "components/core";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { DEFAULT_LOCALE, DestructionStatus } from "utils/constants";
import { ReactComponent as ArrowDown } from "../../../../../assets/images/ArrowDown.svg";
import { ReactComponent as ArrowDownDisabled } from "../../../../../assets/images/ArrowDownDisabled.svg";
import { ReactComponent as ClosedIcon } from "../../../../../assets/images/CloseIcon.svg";
import { MediaDestructionRequestDeliveryFormStyles } from "../MediaDestructionRequestDeliveryFormStyles";

const SitadDeliveryForm = ({
  sitadModifiedDate,
  destructionRqDetails,
  trackingRequirement,
  sitadForm,
  mdrdForm,
  preferredDeliveryDateDef,
  showScheduleServiceForm,
  serviceLocationSelected,
  serviceLocation,
  handleServiceLocationChange,
  minDate,
  maxDate,
  localeByBranch,
  sitadFormDisable,
  sitadModDate
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { authInfo } = useSelector(selectAuth);

  return (
    <Box
      id="request_delivery_29"
      display="flex"
      flexDirection="column"
      gap={spacing.verticalMargin20}
    >
      {/* Create SITAD Delivery title  */}

      <Grid id="request_delivery_80" item>
        <Typography
          id="typography3_Del"
          color="primary"
          sx={{
            ...MediaDestructionRequestDeliveryFormStyles.HeaderTitle
          }}
        >
          {t("mediaDestructionDelivery.createSITADTitle")}
        </Typography>
      </Grid>

      {/* Date and perform section */}

      {sitadModifiedDate && (
        <Grid id="request_delivery_82" item>
          <Grid
            id="request_delivery_112"
            container
            gap={spacing.verticalMargin20}
          >
            <Typography
              id="request_delivery_83"
              color="primary"
              sx={{
                ...MediaDestructionRequestDeliveryFormStyles.subSentence
              }}
            >
              {t("mediaDestructionDelivery.modifiedDate")}
              {sitadModDate}
            </Typography>
            {id ? (
              destructionRqDetails?.logged_employee_name !== "" && (
                <Typography
                  id="request_delivery_113"
                  sx={{
                    ...MediaDestructionRequestDeliveryFormStyles.subSentence
                  }}
                >
                  {t("mediaDestructionDelivery.performedBy")}
                  {destructionRqDetails?.logged_employee_name}
                </Typography>
              )
            ) : (
              <Typography
                id="request_delivery_113"
                sx={{
                  ...MediaDestructionRequestDeliveryFormStyles.subSentence
                }}
              >
                {t("mediaDestructionDelivery.performedBy")}
                {authInfo?.displayName}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}

      <Grid
        id="request_delivery_84"
        display="flex"
        flexDirection="row"
        alignItems="start"
        gap={spacing.verticalMargin20}
      >
        {/* Tracking Requirements field */}

        <Grid id="request_delivery_85" item>
          <Autocomplete
            id="request_delivery_86"
            disablePortal
            options={["", ...trackingRequirement]}
            size="medium"
            sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
            clearIcon={<ClosedIcon />}
            popupIcon={<ArrowDownDisabled />}
            value={trackingRequirement[0].label}
            disabled
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="trackingRequirements"
                id="request_delivery_114"
                label={t("mediaDestructionDelivery.trackingRequrment")}
                value={sitadForm.values?.trackingRequirement}
                error={
                  sitadForm.touched.trackingRequirements &&
                  Boolean(mdrdForm.errors.trackingRequirements)
                }
                helperText={
                  sitadForm.touched.trackingRequirements &&
                  sitadForm.errors.trackingRequirements
                }
                required
              />
            )}
            onChange={(e, value) =>
              sitadForm.handleChange({
                target: {
                  name: "trackingRequirements",
                  value: value?.value
                }
              })
            }
          />
        </Grid>

        {/* Delivery Date field */}

        <Grid id="request_delivery_87" item>
          <DatePickerField
            id="request_delivery_88"
            name="preferredDeliveryDate"
            label={t("mediaDestructionDelivery.preferredDeliveryDate")}
            sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
            locale={localeByBranch || DEFAULT_LOCALE}
            value={
              destructionRqDetails
                ? dayjs(preferredDeliveryDateDef)
                : sitadForm.values.preferredDeliveryDate
            }
            minDate={minDate}
            maxDate={maxDate}
            disabled={
              showScheduleServiceForm ||
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Cancelled
              ) ||
              sitadFormDisable
            }
            onChange={(selectedDate) =>
              sitadForm.handleChange({
                target: {
                  name: "preferredDeliveryDate",
                  value: selectedDate
                }
              })
            }
            error={
              !id
                ? sitadForm.touched?.preferredDeliveryDate &&
                  Boolean(sitadForm.errors?.preferredDeliveryDate)
                : null
            }
            onBlur={!id ? sitadForm.handleBlur : null}
            helperText={
              !id
                ? sitadForm.touched?.preferredDeliveryDate &&
                  sitadForm.errors?.preferredDeliveryDate
                : null
            }
            required
          />
        </Grid>

        {/* Service Location Field */}

        <Grid id="request_delivery_89" item>
          {/* Service Location Input */}

          <Autocomplete
            id="request_delivery_90"
            disablePortal
            size="medium"
            value={serviceLocationSelected.current}
            clearIcon={<ClosedIcon />}
            popupIcon={
              showScheduleServiceForm ||
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Cancelled
              ) ? (
                <ArrowDownDisabled />
              ) : (
                <ArrowDown /> || sitadFormDisable
              )
            }
            sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
            options={["", ...serviceLocation]}
            required
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value && option?.label === value?.label
            }
            onChange={handleServiceLocationChange}
            disabled={
              showScheduleServiceForm ||
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Cancelled
              ) ||
              sitadFormDisable
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id="request_delivery_115"
                name="serviceLocation"
                label={t("mediaDestructionDelivery.serviceLocation")}
                value={sitadForm.values?.serviceLocation}
                error={
                  sitadForm.touched?.serviceLocation &&
                  Boolean(sitadForm.errors?.serviceLocation)
                }
                onBlur={sitadForm.handleBlur}
                helperText={
                  sitadForm.touched?.serviceLocation &&
                  sitadForm.errors?.serviceLocation
                }
                required
              />
            )}
          />
        </Grid>

        {/* Requested by Label */}

        {destructionRqDetails?.authorized_personnel_name && (
          <Grid id="request_delivery_91" item>
            <Typography
              id="request_delivery_92"
              variant="subtitle1"
              sx={MediaDestructionRequestDeliveryFormStyles.BranchText}
            >
              {t("mediaDestructionDelivery.requestedBy")}
            </Typography>
            <Typography
              id="request_delivery_93"
              sx={MediaDestructionRequestDeliveryFormStyles.SubTitle}
            >
              {destructionRqDetails?.authorized_personnel_name}
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Delivery instruction field */}

      <Grid id="request_delivery_94" item xs={6}>
        <TextField
          id="request_delivery_95"
          name="deliveryInstructions"
          label={t("mediaDestructionDelivery.deliveryInstruction")}
          size="medium"
          fullWidth
          disabled={showScheduleServiceForm || Boolean(id) || sitadFormDisable}
          sx={MediaDestructionRequestDeliveryFormStyles.InstructionFieldSize}
          rows={2}
          variant="outlined"
          multiline
          value={sitadForm.values?.deliveryInstructions}
          onChange={sitadForm.handleChange}
          error={
            sitadForm.touched?.deliveryInstructions &&
            Boolean(sitadForm.errors?.deliveryInstructions)
          }
          onBlur={sitadForm.handleBlur}
          helperText={
            sitadForm.touched?.deliveryInstructions &&
            sitadForm.errors?.deliveryInstructions
          }
          required
        />
      </Grid>
    </Box>
  );
};

export default SitadDeliveryForm;
