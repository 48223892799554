import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { NonEditableField } from "components/shared";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { MediaDestructionRequestDeliveryFormStyles } from "../MediaDestructionRequestDeliveryFormStyles";
const AuthorizationFormFields = ({
  mdrdForm,
  personnelAuthrizationData,
  handleAuthenticate,
  authFail
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Authorization form fields*/}

      {/* Personal Authentication Helper Text */}
      <Grid id="request_delivery_103">
        <Typography id="request_delivery_99" variant="body2">
          {t("mediaDestructionDelivery.authSectionInstructions")}
        </Typography>
      </Grid>

      {/* Auth Number field */}

      <Grid
        id="request_delivery_55"
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={spacing.verticalMargin20}
      >
        <TextField
          id="request_delivery_57"
          name="authNumber"
          label={t("mediaDestructionDelivery.authNumber")}
          size="medium"
          variant="outlined"
          sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
          value={mdrdForm.values.authNumber}
          onChange={mdrdForm.handleChange}
          error={
            mdrdForm.touched.authNumber && Boolean(mdrdForm.errors.authNumber)
          }
          helperText={mdrdForm.touched.authNumber && mdrdForm.errors.authNumber}
          disabled={Boolean(mdrdForm.values?.authorizationChallengeResponse)}
        />

        {mdrdForm.values?.authorizationChallengeQuestion !== "" && (
          <>
            {/* Authorization Challenge Question field */}
            <NonEditableField
              value={mdrdForm.values?.authorizationChallengeQuestion}
              label={t("mediaDestructionDelivery.challengeQuestions")}
              id={"request_delivery_59"}
            />

            {/* Authorization Challenge Response field */}
            <TextField
              id="request_delivery_61"
              name="authorizationChallengeResponse"
              label={t("mediaDestructionDelivery.challengeResponse")}
              size="medium"
              sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
              variant="outlined"
              type="password"
              value={mdrdForm.values.authorizationChallengeResponse}
              onChange={mdrdForm.handleChange}
              error={
                mdrdForm.touched.authorizationChallengeResponse &&
                Boolean(mdrdForm.errors.authorizationChallengeResponse)
              }
              helperText={
                mdrdForm.touched.authorizationChallengeResponse &&
                mdrdForm.errors.authorizationChallengeResponse
              }
              disabled={
                Boolean(!personnelAuthrizationData?.secretAnswer) ||
                Boolean(mdrdForm.values?.authNumber)
              }
            />
          </>
        )}
      </Grid>

      {/* Authenticate Button */}
      <Grid id="request_delivery_119" item xs={12}>
        <Grid
          id="request_delivery_110"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={spacing.verticalMargin20}
        >
          <Button
            id="request_delivery_63"
            variant="contained"
            color="primary"
            size="medium"
            sx={{ textTransform: "none" }}
            onClick={handleAuthenticate}
          >
            {t("mediaDestructionDelivery.authenticate")}
          </Button>

          {authFail && (
            <Alert
              id="request_delivery_122"
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
            >
              {t("mediaDestructionDelivery.authenticationError")}
            </Alert>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AuthorizationFormFields;
