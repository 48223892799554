import { Container } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { Signin } from "components/authentication";
import { FullCircularLoader } from "components/core";
import { ROOT } from "routing/Paths";
import { authentication } from "services/firebase";
import { selectAuth } from "store/slices";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path;
  const { auth, isError } = useSelector(selectAuth);
  const [isLoading, setIsLoading] = useState(true);

  // init page
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authentication, (currentUser) => {
      // check whether the user have an existing session
      if (currentUser) {
        navigate(redirectPath || ROOT, { replace: true });
      } else {
        if (!isError) {
          dispatch({ type: "Reset" });
        }
      }
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* If not authorized render Signin, otherwise navigate to requested route */}
      {Object.keys(auth?.user)?.length ? (
        <Navigate to={location.state?.path || "/"} replace />
      ) : (
        // show app loading
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            background: "#f5f5f5"
          }}
        >
          {isLoading ? <FullCircularLoader loading={isLoading} /> : <Signin />}
        </Container>
      )}
    </>
  );
}

export default Login;
