/* Yup validation schemas goes here */
import i18n from "i18n";
import * as yup from "yup";

/* default pricing usage report  Schema  */
export const defaultPricingSchema = yup.object().shape({
  selectedBranch: yup
    .string()
    .required(i18n.t("defaultPricingUsageReport.branchError"))
});
