export const templateLimit = 99;
export const brackets = "[] and <>";
export const labelTemplateConstants = {
  X: "X",
  V: "V",
  m: "M",
  t: "T",
  objecBracket: "{}",
  objecBracketOpen: "{",
  objecBracketClose: "}",
  global_attribute_type_id: "94",
  system_id: "60",
  cust: "CUST",
  mt: "MT",
  customerNumber: "Customer Number",
  description: "description",
  labelTemplateId: "labelTemplateId",
  backspace: "Backspace",
  labelTemplate: "Label Template",
  mediaType: "Media type"
};
export const testLabelConstant = {
  global_attribute_type_id: "95",
  system_id: "60"
};
export const imeEnv = "ime";
export const imnaEnv = "imna";
export const mediaTYpeLength = 2;
export const mediaTYpeImnaLength = 1;
