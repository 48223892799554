export const personalValidationStyles = {
  fieldSize: {
    minWidth: "310px"
  },
  direction: {
    column: "column",
    row: "row"
  },
  gap: {
    gap: "20px"
  }
};
