import dayjs from "dayjs";
import { t } from "i18next";
export const buttonList = [
  {
    label: t("customerSchedule.clearButtonLabel"),
    variant: "outlined",
    disabled: true
  },
  {
    label: t("customerSchedule.printButtonLabel"),
    variant: "outlined",
    disabled: true
  },
  {
    label: t("customerSchedule.svcWindowsButtonLabel"),
    variant: "outlined",
    disabled: true
  },
  {
    label: t("customerSchedule.generateButtonLabel"),
    variant: "outlined",
    disabled: true
  },
  {
    label: t("customerSchedule.saveButtonLabel"),
    variant: "contained",
    disabled: true
  }
];

export const initialValues = {
  customerField: "",
  from: dayjs(),
  clsField: false,
  serviceFrequency: "",
  weekDayList: [],
  week: "",
  weekDay: "",
  day: "",
  businessDay: false,
  comment: "",
  rotationScheduleExists: false,
  mbDayOfNthWeekRSExist: false,
  toDate: dayjs().add(2, t("customerSchedule.year")),
  onCallCustomer: false,
  defaultCslField: true,
  bSvcTripsExist: null,
  timestamp: null,
  customerService: null,
  lastDate: dayjs()
};

export const fieldName = {
  customerField: t("customerSchedule.customerField"),
  clsField: t("customerSchedule.clsField"),
  serviceFrequency: t("customerSchedule.serviceFrequencyField"),
  weekDayList: t("customerSchedule.weekDayList"),
  week: t("customerSchedule.weekField"),
  weekDay: t("customerSchedule.weekDayField"),
  day: t("customerSchedule.day"),
  businessDay: t("customerSchedule.businessDayField"),
  comment: t("customerSchedule.commentField"),
  rotationScheduleExists: t("customerSchedule.rotationScheduleExists"),
  mbDayOfNthWeekRSExist: t("customerSchedule.mbDayOfNthWeekRSExist"),
  from: t("customerSchedule.from"),
  onCallCustomer: t("customerSchedule.onCallCustomer"),
  customerService: t("customerSchedule.customerService"),
  bSvcTripsExist: t("customerSchedule.bSvcTripsExist"),
  timestamp: t("customerSchedule.timestamp"),
  lastDate: t("customerSchedule.lastDate")
};

export const daysPerWeek = t("customerSchedule.daysPerWeek");
export const everyOtherWeek = t("customerSchedule.everyOtherWeek");
export const monthlyByWeek = t("customerSchedule.monthlyByWeek");
export const monthlyByDay = t("customerSchedule.monthlyByDay");
export const onCall = t("customerSchedule.onCall");
export const listOfDates = t("customerSchedule.listOfDates");
export const dayItem = t("customerSchedule.day");
export const monthItem = t("customerSchedule.month");
export const yearItem = t("customerSchedule.year");
export const dateItem = t("customerSchedule.date");
export const weekItem = t("customerSchedule.weekField");
export const monthYearFormat = "MM/YYYY";
export const yearMonthFormat = "YYYYMM";
export const severityError = "error";
export const severityWarning = "warning";
export const reg = /[0-9]/;

export const weekOfMonthList = [
  {
    id: t("customerSchedule.first"),
    week_of_month_code: t("customerSchedule.first")
  },
  {
    id: t("customerSchedule.second"),
    week_of_month_code: t("customerSchedule.second")
  },
  {
    id: t("customerSchedule.third"),
    week_of_month_code: t("customerSchedule.third")
  },
  {
    id: t("customerSchedule.fourth"),
    week_of_month_code: t("customerSchedule.fourth")
  },
  {
    id: t("customerSchedule.last"),
    week_of_month_code: t("customerSchedule.last")
  }
];
export const serviceFrequencyList = [
  {
    id: t("customerSchedule.daysPerWeek"),
    service_frequency_code: t("customerSchedule.daysPerWeek")
  },
  {
    id: t("customerSchedule.everyOtherWeek"),
    service_frequency_code: t("customerSchedule.everyOtherWeek")
  },
  {
    id: t("customerSchedule.monthlyByWeek"),
    service_frequency_code: t("customerSchedule.monthlyByWeek")
  },
  {
    id: t("customerSchedule.monthlyByDay"),
    service_frequency_code: t("customerSchedule.monthlyByDay")
  },
  {
    id: t("customerSchedule.onCall"),
    service_frequency_code: t("customerSchedule.onCall")
  },
  {
    id: t("customerSchedule.listOfDates"),
    service_frequency_code: t("customerSchedule.listOfDates")
  }
];

export const skipDay = [0, 6];
export const mainDistrictId = "50";
export const systemId = "60";
export const attributeTypeId = "130";

//will be changed in future
export const errorInvalidDate = t("customerSchedule.errorInvalidDate");
export const errorMessage1 = t("customerSchedule.errorMessage1");
export const errorMessage2 = t("customerSchedule.errorMessage2");
export const validationError = t("customerSchedule.validationError");
export const validationError2 = t("customerSchedule.validationError2");

export const dayIndex = {
  on_sunday: "1",
  on_monday: "2",
  on_tuesday: "3",
  on_wednesday: "4",
  on_thursday: "5",
  on_friday: "6",
  on_saturday: "7"
};

export const dayName = {
  mon: t("customerSchedule.monday"),
  sun: t("customerSchedule.sunday"),
  thu: t("customerSchedule.thursday"),
  sat: t("customerSchedule.saturday"),
  tue: t("customerSchedule.tuesday"),
  wed: t("customerSchedule.wednesday"),
  fri: t("customerSchedule.friday")
};
