export const createDistributionListTableColumns = [
  { id: "i", label: "", visible: true },
  {
    id: "mediaNumber",
    label: "MEDIA NUMBER",
    visible: true,
  },
  {
    id: "returnDate",
    label: "RETURN DATE",
    visible: true,
  },
  {
    id: "slot",
    label: "SLOT",
    visible: true,
  },

  {
    id: "drpCode",
    label: "DRP CODE",
    visible: true,
  },
  {
    id: "description",
    label: "DESCRIPTION",
    visible: true,
  },
];

export const SYSTEM_FUNCTION_ID_SAVE_OPEN_MEDIA = "51";

export const TXT = ".txt";
export const CSV = ".csv";
export const replaceValueFromMessageDescr = "| |";
export const TXT_CSV_MESSAGEID = "80350";
