import { Stack } from "@mui/material";
import { PageTitle } from "components/shared";
import RouteDetailsLayout from "components/transportation-module/route-details/RouteDetailsLayout";
import i18n from "../../../i18n";

const RouteDetails = (props) => {
  return (
    <Stack>
      <PageTitle title={i18n.t("menu.routeDetail")} />
      <RouteDetailsLayout />
    </Stack>
  );
};

export default RouteDetails;
