const possibleMatchTableRowMuiCustom = {
  "& .css-dsuxgy-MuiTableCell-root": {
    padding: "0px 16px"
  }
};

const openMediaTableRowMuiCustom = {
  "&.Mui-selected": {
    backgroundColor: "#D2F0FC"
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#D2F0FC"
  }
};

const openMediaSection_MainStack = {
  boxShadow:
    "0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
  borderRadius: "9px",
  marginBottom: "5px"
};

const openMediaSection_MainBox = { paddingTop: "20px", paddingLeft: "20px" };

const cardStyle_PRSPopUP = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  boxShadow: 24
};

const mainTitle_PRSPopUP = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "32px",
  letterSpacing: "0.15px",
  color: "#1E417A",
  display: "flex",
  padding: "10px 24px",
  alignItems: "center",
  alignSelf: "stretch",
  width: "552px"
};

const cardContentStyle_PRSPopUP = {
  display: "flex",
  height: "335px",
  padding: "20px 16px 16px 16px",
  flexDirection: "column",
  alignItems: "flex-start",
  flexShrink: 0,
  alignSelf: "stretch",
  rowGap: "20px"
};

const demoContainerStyle_PRSPopUP = {
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",
  alignSelf: "stretch",
  width: "568px"
};

const cardActionsStyle_PRSPopUP = {
  display: "flex",
  padding: "16px",
  alignItems: "flex-end",
  gap: "8px",
  alignSelf: "stretch"
};

const autocompleteStyle_PRSPopUP = {
  display: "flex",
  width: "270px",
  flexDirection: "column",
  alignItems: "flex-start"
};

const radioGroupStyle_PRSPopUP = {
  display: "flex",
  alignItems: "flex-start",
  gap: "20px",
  alignSelf: "stretch",
  width: "570px"
};

const buttonStyle_PRSPopUP = {
  display: "flex",
  padding: "6px 16px !important",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "unset",
  marginLeft: "0px !important"
};

const cardStyle_DRMPopUP = {
  ...cardStyle_PRSPopUP,
  width: "550px !important"
};

const SelectMediaModal_PopUp = {
  ...cardStyle_PRSPopUP,
  width: "750px !important"
};

const mainTitle_SelectMedia = {
  ...mainTitle_PRSPopUP,
  width: "100% !important"
};

const personnelAuthModalAlert = { padding: "10px" };

const personnelAuthModalStyle = {
  ...cardStyle_PRSPopUP,
  width: "unset !important"
};

const personnelAuthModalStyleCollapse = {
  ...cardStyle_PRSPopUP,
  width: "unset !important"
};

const InvalidMediasSnapBarContainer = {
  position: "relative"
};

const InvalidMediasSnapBarSpan = {
  color: "#014361",
  fontWeight: 500,
  paddingLeft: "10px"
};

const InvalidMediasSnapBarContainerStack = {
  position: "relative",
  width: "500px",
  maxHeight: "200px",
  backgroundColor: "#E5F6FD",
  color: "blue",
  borderRadius: "4px"
};

const InvalidMediasSnapBar_SecondBox = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px"
};

const InvalidMediasSnapBar_ErrorIcon = { color: "#1462AD" };
const InvalidMediasSnapBar_CloseIcon = { color: "#014361" };

const InvalidMediasSnapBar_Title = { paddingLeft: "40px" };

const InvalidMediasSnapBar_TableContainer = {
  paddingLeft: "40px",
  paddingRight: "30px",
  paddingBottom: "10px",
  paddingTop: "20px",
  overflowX: "auto",
  backgroundColor: "#E5F6FD",
  "&::-webkit-scrollbar": {
    backgroundColor: "#E5F6FD"
  },
  boxShadow: "unset"
};

const InvalidMediasSnapBar_Table = { tableLayout: "fixed", minWidth: "100%" };

const InvalidMediasSnapBar_TableCell = {
  borderBottom: "none",
  padding: "0px",
  width: "90%",
  overflowY: "auto",
  color: "#014361",
  fontWeight: 500,
  "&::-webkit-scrollbar": {
    backgroundColor: "#E5F6FD"
  }
};

const InvalidMediasSnapBar_TableCell_error = {
  borderBottom: "none",
  padding: "0px",
  width: "10%"
};

const InvalidMediasSnapBar_Box = {
  height: "30px",
  width: "100%",
  backgroundColor: "transparent"
};

const PersonnelAuthModal_CardBox = { width: "310px" };
const PersonnelAuthModal_CardBox1_AutoComplete = { width: "100%" };
const PersonnelAuthModal_InputLabelProps = {
  shrink: true,
  style: { zIndex: 0 }
};

const PersonnelAuthModal_CardBox_AuthNumber_TF = {
  height: "56px"
};
const PersonnelAuthModal_CardBox_Question_TF = {
  width: "300px",
  height: "60px"
};
const PersonnelAuthModal_CardBox_Answer_TF = {
  ...PersonnelAuthModal_CardBox_Question_TF
};

const PersonnelAuthModal_BoxHeight = { height: "40px" };
const PersonnelAuthModal_AuthButton = { textTransform: "none" };

const EmailAndWeight_ContainerBox = { width: "310px" };
const EmailAndWeight_Email_TF = { height: "56px" };
const EmailAndWeight_InputLabelProps = { shrink: true, style: { zIndex: 0 } };

const EmailAndWeight_Weight_Box = { width: "200px" };
const EmailAndWeight_Weight_TF = { height: "56px" };

const EmailAndWeight_Unit_Box = { width: "120px" };
const EmailAndWeight_Unit = { height: "56px" };

const PersonnelAuthentication_AuthNum = { width: "310px" };
const PersonnelAuthentication_InputLabelProps = {
  shrink: true,
  style: { zIndex: 0 }
};
const PersonnelAuthentication_AuthNum_TF = { height: "56px" };
const PersonnelAuthentication_Question = { width: "300px", height: "60px" };
const PersonnelAuthentication_Answer = {
  width: "300px",
  height: "60px"
};
const PersonnelAuthentication_AuthButton = { textTransform: "none" };

const ReadOnlyFields_TF = { width: "100%", height: "56px" };
const ReadOnlyFields_InputLabelProps = { shrink: true, style: { zIndex: 0 } };

const CustomerSelectionVault_TF = {
  width: "100%"
};
const CustomerSelectionVault_InputLabelProps = {
  shrink: true,
  style: { zIndex: 0 }
};

const MUI_Autocomplete_Icon_Styles = {
  "& .MuiAutocomplete-endAdornment": {
    right: "16px !important"
  }
};

const PersonnelSelectionVault_Box = { width: "310px" };
const PersonnelSelectionVault_InputLabelProps = {
  shrink: true,
  style: { zIndex: 0 }
};
const PersonnelSelectionVault_TF = {
  width: "100%",
  ...MUI_Autocomplete_Icon_Styles
};
const PersonnelSelectionVault_Auth = { color: "#4CAF50", display: "flex" };

const OpenMediaTable_Box1 = { padding: "20px" };
const OpenMediaTable_MediaNumber = { width: "300px" };
const OpenMediaTable_InputLabelProps = { shrink: true };
const OpenMediaTable_Button = { textTransform: "none" };
const OpenMediaTable_Input = { display: "none" };
const OpenMediaTable_Label = { marginTop: "20px" };
const OpenMediaTable_Paper = {
  position: "relative",
  overflowX: "auto",
  boxShadow: "unset",
  borderRadius: "0 0 9px 9px !important"
};
const OpenMediaTable_Alert = { paddingLeft: "20px", paddingRight: "20px" };
const OpenMediaTable_div = { width: "100%" };
const OpenMediaTable_TableContainer = { height: "300px", overflowY: "auto" };
const OpenMediaTable_TableHead = {
  position: "sticky",
  top: 0,
  backgroundColor: "#f5f5f5"
};
const TableHeader_Typo = {
  color: "rgba(0,0,0,0.60)",
  fontWeight: 700
};
const OpenMediaTable_Box_TH = { cursor: "pointer", width: "fit-content" };
const OpenMediaTable_TableCell_First = {
  minWidth: "0 !important",
  display: "flex",
  gap: "20px"
};
const OpenMediaTable_TableCell_HeightBox = { height: "100%" };
const OpenMediaTable_TableCell_FollowUp = {
  display: "flex",
  gap: "10px",
  alignItems: "center"
};
const OpenMediaTable_TableCell_FollowUp_Btn = {
  minWidth: "0 !important",
  display: "flex",
  alignItems: "center"
};
const OpenMediaTable_TablePagination = { position: "sticky", bottom: 0 };

const PossibleMatchesTable_Paper = { position: "relative" };
const PossibleMatchesTable_Error = { marginBottom: "5px" };
const PossibleMatchesTable_TableContainer = {
  height: "250px",
  overflowY: "auto"
};
const PossibleMatchesTable_TableContainer_NoError = {
  height: "300px",
  overflowY: "auto"
};
const PossibleMatchesTable_TableHead = {
  position: "sticky",
  top: 0,
  backgroundColor: "#f5f5f5",
  zIndex: 1
};
const PossibleMatchesTable_span = { cursor: "pointer", width: "fit-content" };
const PossibleMatchesTable_TableCell = { minWidth: "0 !important" };
const PossibleMatchesTable_FormControlLabel = {
  marginLeft: "18px",
  marginRight: "0px !important",
  "& .MuiCheckbox-root": {
    padding: 0
  }
};
const PossibleMatchesTable_TablePagination = { position: "sticky", bottom: 0 };

const MDRVaultItemizedForm_Stack1 = {
  justifyContent: "space-between",
  alignItems: "center"
};
const MDRVaultItemizedForm_Id = { fontSize: "26px", color: "#1A325D" };

const MDRVaultItemizedForm_Customer = { width: "310px" };
const MDRVaultItemizedForm_Status = { width: "100%" };
const MDRVaultItemizedForm_InputLabelProps = {
  shrink: true,
  style: { zIndex: 0 }
};
const MDRVaultItemizedForm_Comment = {
  width: "100%",
  height: "126px"
};
const MDRVaultItemizedForm_BtnStack = {
  marginTop: "12px"
};
const MDRVaultItemizedForm_Btn = { textTransform: "none" };
const tableContainer = {
  "& td": {
    padding: "0 16px !important",
    height: "42px !important"
  },
  "& th": {
    padding: "0 16px !important",
    height: "42px !important"
  }
};

export const MDRVaultItemizedStyles = {
  possibleMatchTableRowMuiCustom,
  MUI_Autocomplete_Icon_Styles,
  openMediaSection_MainStack,
  openMediaSection_MainBox,
  MDRVaultItemizedForm_Stack1,
  MDRVaultItemizedForm_Id,
  MDRVaultItemizedForm_Customer,
  MDRVaultItemizedForm_Status,
  MDRVaultItemizedForm_InputLabelProps,
  MDRVaultItemizedForm_Comment,
  MDRVaultItemizedForm_BtnStack,
  MDRVaultItemizedForm_Btn,
  autocompleteStyle_PRSPopUP,
  cardActionsStyle_PRSPopUP,
  cardContentStyle_PRSPopUP,
  cardStyle_PRSPopUP,
  demoContainerStyle_PRSPopUP,
  mainTitle_PRSPopUP,
  radioGroupStyle_PRSPopUP,
  buttonStyle_PRSPopUP,
  cardStyle_DRMPopUP,
  SelectMediaModal_PopUp,
  mainTitle_SelectMedia,
  personnelAuthModalStyle,
  personnelAuthModalAlert,
  personnelAuthModalStyleCollapse,
  InvalidMediasSnapBarContainer,
  InvalidMediasSnapBarContainerStack,
  InvalidMediasSnapBar_SecondBox,
  InvalidMediasSnapBar_ErrorIcon,
  InvalidMediasSnapBar_CloseIcon,
  InvalidMediasSnapBar_Title,
  InvalidMediasSnapBar_TableContainer,
  InvalidMediasSnapBarSpan,
  InvalidMediasSnapBar_Table,
  InvalidMediasSnapBar_TableCell,
  InvalidMediasSnapBar_TableCell_error,
  InvalidMediasSnapBar_Box,
  PersonnelAuthModal_CardBox,
  PersonnelAuthModal_CardBox1_AutoComplete,
  PersonnelAuthModal_InputLabelProps,
  PersonnelAuthModal_CardBox_AuthNumber_TF,
  PersonnelAuthModal_CardBox_Question_TF,
  PersonnelAuthModal_CardBox_Answer_TF,
  PersonnelAuthModal_BoxHeight,
  PersonnelAuthModal_AuthButton,
  EmailAndWeight_ContainerBox,
  EmailAndWeight_Email_TF,
  EmailAndWeight_InputLabelProps,
  EmailAndWeight_Weight_Box,
  EmailAndWeight_Weight_TF,
  EmailAndWeight_Unit_Box,
  EmailAndWeight_Unit,
  PersonnelAuthentication_AuthNum,
  PersonnelAuthentication_AuthNum_TF,
  PersonnelAuthentication_InputLabelProps,
  PersonnelAuthentication_Question,
  PersonnelAuthentication_Answer,
  PersonnelAuthentication_AuthButton,
  ReadOnlyFields_TF,
  ReadOnlyFields_InputLabelProps,
  CustomerSelectionVault_TF,
  CustomerSelectionVault_InputLabelProps,
  PersonnelSelectionVault_Box,
  PersonnelSelectionVault_InputLabelProps,
  PersonnelSelectionVault_TF,
  PersonnelSelectionVault_Auth,
  OpenMediaTable_Box1,
  openMediaTableRowMuiCustom,
  OpenMediaTable_Alert,
  TableHeader_Typo,
  OpenMediaTable_MediaNumber,
  OpenMediaTable_InputLabelProps,
  OpenMediaTable_Button,
  OpenMediaTable_Input,
  OpenMediaTable_Label,
  OpenMediaTable_Paper,
  OpenMediaTable_div,
  OpenMediaTable_TableContainer,
  OpenMediaTable_TableHead,
  OpenMediaTable_Box_TH,
  OpenMediaTable_TableCell_First,
  OpenMediaTable_TableCell_HeightBox,
  OpenMediaTable_TableCell_FollowUp,
  OpenMediaTable_TableCell_FollowUp_Btn,
  OpenMediaTable_TablePagination,
  PossibleMatchesTable_Paper,
  PossibleMatchesTable_Error,
  PossibleMatchesTable_TableContainer,
  PossibleMatchesTable_TableContainer_NoError,
  PossibleMatchesTable_TableHead,
  PossibleMatchesTable_span,
  PossibleMatchesTable_TableCell,
  PossibleMatchesTable_FormControlLabel,
  PossibleMatchesTable_TablePagination,
  tableContainer
};
