import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import {
  setError,
  updateFormValues
} from "store/slices/container-processing/containerAuditDetails";
import { ALL_SELECTION, ERROR_TYPES } from "utils/constants";
import {
  getOptions,
  getResponseData
} from "utils/constants/open-media-processing/OpenMediaAuditConstants";
import { findErrorMessage } from "utils/helpers";

const LocationForm = ({
  setLoading,
  currentBranch,
  auditDetailForm,
  viewMode,
  viewData,
  containerAuditId,
  setIsSaved,
  clearAuthForm
}) => {
  const allSelection = ALL_SELECTION?.value;
  const [fromVaultList, setFromVaultList] = useState([ALL_SELECTION]);
  const [fromRowList, setFromRowList] = useState([ALL_SELECTION]);
  const [fromRackList, setFromRackList] = useState([ALL_SELECTION]);
  const [fromShelfList, setFromShelfList] = useState([ALL_SELECTION]);
  const [disableMode, setDisableMode] = useState({
    rowVault: true,
    rackVault: true,
    shelfVault: true
  });
  const { formValues } = useSelector((state) => state.containerAuditDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetLocationRowData();
    //eslint-disable-next-line
  }, []);
  const handleGetLocationRowData = async () => {
    dispatch(setLoading(true));
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getphysicalvaults,
        1
      );
      let obj = {};
      for (let key in dataSets.data) {
        dataSets.data[key].forEach((item) => {
          obj[item.physical_vault_id] = {
            physical_vault_id: item.physical_vault_id,
            physical_vault_number: item.physical_vault_number,
            timestamp: item.timestamp
          };
        });
      }
      if (!Object.values(obj).length && !viewData?.container_audit_id) {
        return;
      }
      let setArray = getOptions(
        [...Object.values(obj)],
        "physical_vault_id",
        "physical_vault_number"
      ).sort((a, b) => a?.label - b?.label);
      if (setArray?.length === 1) {
        const fromNewData = {
          ...formValues,
          vault_from: setArray[0]?.value,
          vault_to: setArray[0]?.value
        };
        dispatch(updateFormValues(fromNewData));
      }
      setFromVaultList([ALL_SELECTION, ...setArray]);
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetRowData = async (id) => {
    dispatch(setLoading(true));
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id,
      physical_vault_id: id
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getrows,
        1
      );
      let obj = {};
      for (let key in dataSets.data) {
        dataSets.data[key].forEach((item) => {
          obj[item.row_id] = {
            row_id: item.row_id,
            row_number: item.row_number
          };
        });
      }

      if (!Object.values(obj).length && !viewData?.container_audit_id) {
        return;
      }
      let setArray = getOptions(
        [...Object.values(obj)],
        "row_id",
        "row_number"
      ).sort((a, b) => a?.label - b?.label);
      if (setArray?.length === 1) {
        const fromNewData = {
          ...formValues,
          row_from: setArray[0]?.value,
          row_to: setArray[0]?.value
        };
        dispatch(updateFormValues(fromNewData));
      }
      if (setArray.length) {
        setDisableMode({ ...disableMode, rowVault: false });
      }
      setFromRowList([ALL_SELECTION, ...setArray]);
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetRackList = async (id, rowId) => {
    dispatch(setLoading(true));
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id,
      physical_vault_id: id,
      row_id: rowId
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getracks,
        1
      );
      let obj = {};
      for (let key in dataSets.data) {
        dataSets.data[key].forEach((item) => {
          obj[item.rack_id] = {
            rack_id: item.rack_id,
            rack_number: item.rack_number
          };
        });
      }
      if (!Object.values(obj).length && !viewData?.container_audit_id) {
        return;
      }
      let setArray = getOptions(
        [...Object.values(obj)],
        "rack_id",
        "rack_number"
      ).sort((a, b) => a?.label - b?.label);
      if (setArray?.length === 1) {
        const fromNewData = {
          ...formValues,
          rack_from: setArray[0]?.value,
          rack_to: setArray[0]?.value
        };
        dispatch(updateFormValues(fromNewData));
      }
      if (setArray.length) {
        setDisableMode({ ...disableMode, rackVault: false });
      }
      setFromRackList([ALL_SELECTION, ...setArray]);
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleGetShelfVault = async (id, rowId, rackId) => {
    dispatch(setLoading(true));
    const reqBody = JSON.stringify({
      main_district_id: currentBranch?.district_id,
      physical_vault_id: id,
      row_id: rowId,
      rack_id: rackId
    });
    try {
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.auditdetail?.getshelvesonarack,
        1
      );
      let obj = {};
      for (let key in dataSets.data) {
        dataSets.data[key].forEach((item) => {
          obj[item.shelf_id] = {
            shelf_id: item.shelf_id,
            shelf_number: item.shelf_number
          };
        });
      }
      if (!Object.values(obj).length && !viewData?.container_audit_id) {
        return;
      }
      let setArray = getOptions(
        [...Object.values(obj)],
        "shelf_id",
        "shelf_number"
      ).sort((a, b) => a?.label - b?.label);
      if (setArray?.length === 1) {
        const fromNewData = {
          ...formValues,
          shelf_from: setArray[0]?.value,
          shelf_to: setArray[0]?.value
        };
        dispatch(updateFormValues(fromNewData));
      }
      if (setArray.length) {
        setDisableMode({ ...disableMode, shelfVault: false });
      }
      setFromShelfList([ALL_SELECTION, ...setArray]);
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleChangesValue = (e, key) => {
    setIsSaved(false);
    const { value, name } = e.target;
    let fromNewData = {};
    if (["vault_from", "vault_to"].includes(name)) {
      setDisableMode({ rowVault: true, rackVault: true, shelfVault: true });
      fromNewData = {
        ...formValues,
        [name]: value,
        row_from: allSelection,
        row_to: allSelection,
        rack_from: allSelection,
        rack_to: allSelection,
        shelf_from: allSelection,
        shelf_to: allSelection
      };
    } else if (["row_from", "row_to"].includes(name)) {
      setDisableMode({ ...disableMode, rackVault: true, shelfVault: true });
      fromNewData = {
        ...formValues,
        [name]: value,
        rack_from: allSelection,
        rack_to: allSelection,
        shelf_from: allSelection,
        shelf_to: allSelection
      };
    } else if (["rack_from", "rack_to"].includes(name)) {
      setDisableMode({ ...disableMode, shelfVault: true });
      fromNewData = {
        ...formValues,
        [name]: value,
        shelf_from: allSelection,
        shelf_to: allSelection
      };
    } else {
      fromNewData = {
        ...formValues,
        [name]: value
      };
    }

    dispatch(updateFormValues(fromNewData));
    clearAuthForm();
  };

  useEffect(() => {
    const { vault_from, vault_to, row_from, row_to, rack_from, rack_to } =
      formValues;
    if (
      vault_from === vault_to &&
      vault_from !== allSelection &&
      vault_to !== allSelection &&
      disableMode?.rowVault
    ) {
      handleGetRowData(vault_from);
    }
    if (
      disableMode?.rackVault &&
      row_from === row_to &&
      row_from !== allSelection &&
      row_to !== allSelection
    ) {
      handleGetRackList(vault_from, row_to);
    }
    if (
      disableMode?.shelfVault &&
      rack_from === rack_to &&
      rack_from !== allSelection &&
      rack_to !== allSelection
    ) {
      handleGetShelfVault(vault_from, row_to, rack_from);
    }
    //eslint-disable-next-line
  }, [formValues]);
  return (
    <>
      <Grid container spacing={2} lg={12} margin={0}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          marginTop={2}
          marginBottom={2}
        >
          <Divider />
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={fromVaultList?.length === 1 || viewMode}
          >
            <InputLabel id="from-vault">
              {t("containerAuditDetails.fromVault")}
            </InputLabel>
            <Select
              labelId="from-vault"
              id="location-from-vault"
              name="vault_from"
              value={formValues?.vault_from}
              label={t("containerAuditDetails.fromVault")}
              onChange={handleChangesValue}
            >
              {fromVaultList?.map((item) => (
                <MenuItem
                  value={item?.value}
                  key={item?.value}
                  name={item?.label}
                >
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl
            fullWidth
            disabled={fromVaultList?.length === 1 || viewMode}
          >
            <InputLabel id="to">
              {t("containerAuditDetails.FormVaultTo")}
            </InputLabel>
            <Select
              labelId="to"
              id="location-to"
              name="vault_to"
              value={formValues?.vault_to}
              label={t("containerAuditDetails.FormVaultTo")}
              onChange={handleChangesValue}
            >
              {fromVaultList?.map((item) => (
                <MenuItem
                  value={item?.value}
                  key={item?.value}
                  name={item?.label}
                >
                  {" "}
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={disableMode?.rowVault || viewMode}>
            <InputLabel id="from-row">
              {t("containerAuditDetails.fromRow")}
            </InputLabel>
            <Select
              labelId="from-row"
              id="location-from-row"
              name="row_from"
              value={formValues?.row_from}
              label={t("containerAuditDetails.fromRow")}
              onChange={handleChangesValue}
            >
              {fromRowList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={disableMode?.rowVault || viewMode}>
            <InputLabel id="from-to-row">
              {t("containerAuditDetails.formRowTo")}
            </InputLabel>
            <Select
              labelId="from-to-row"
              id="location-from-to-row"
              name="row_to"
              value={formValues?.row_to}
              label={t("containerAuditDetails.formRowTo")}
              onChange={handleChangesValue}
            >
              {fromRowList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={disableMode?.rackVault || viewMode}>
            <InputLabel id="from-rack">
              {t("containerAuditDetails.fromRack")}
            </InputLabel>
            <Select
              labelId="from-rack"
              id="from-rack"
              name="rack_from"
              value={formValues?.rack_from}
              label={t("containerAuditDetails.formRack")}
              onChange={handleChangesValue}
            >
              {fromRackList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={disableMode?.rackVault || viewMode}>
            <InputLabel id="from-to-rack">
              {t("containerAuditDetails.formRackTo")}
            </InputLabel>
            <Select
              labelId="from-to-rack"
              id="location-from-to-rack"
              name="rack_to"
              value={formValues?.rack_to}
              label={t("containerAuditDetails.formRackTo")}
              onChange={handleChangesValue}
            >
              {fromRackList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={disableMode?.shelfVault || viewMode}>
            <InputLabel id="from-shelf">
              {t("containerAuditDetails.fromShelf")}
            </InputLabel>
            <Select
              labelId="from-shelf"
              id="from-shelf-id"
              name="shelf_from"
              value={formValues?.shelf_from}
              label={t("containerAuditDetails.fromShelf")}
              onChange={handleChangesValue}
            >
              {fromShelfList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FormControl fullWidth disabled={disableMode?.shelfVault || viewMode}>
            <InputLabel id="from-shelf-to">
              {t("containerAuditDetails.formShelfTo")}
            </InputLabel>
            <Select
              labelId="from-shelf-to"
              id="from-shelf-to-id"
              name="shelf_to"
              value={formValues?.shelf_to}
              label={t("containerAuditDetails.formShelfTo")}
              onChange={handleChangesValue}
            >
              {fromShelfList?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {" "}
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default LocationForm;
