import {
  Alert,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { CircularLoaderFullPage, DatePickerField } from "components/core";

import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import { ERROR_TYPES, VALUE_EMPTY_STRING } from "utils/constants";
import { uploadErrorsMinimumDate } from "utils/constants/reports-module/Reports";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";
import { lastGenRunDate } from "../Services";

const UploadErrorsHistory = ({
  isOpen,
  onCloseModal,
  setCommonDownloadModal,
  getCallbackData
}) => {
  const { localeByBranch, currentBranch } = useSelector(selectAuth);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastGenDate, setLastGenDate] = useState(null);
  const today = dayjs();
  const [selectedFromDate, setSelectedFromDate] = useState(today);
  const [selectedToDate, setSelectedToDate] = useState(today);
  const [isClearDisable, setIsClearDisable] = useState(true);
  // print button functionality
  const handlePrint = () => {
    const fromDate = selectedFromDate?.toISOString().split("T")[0];
    const toDate = selectedToDate?.toISOString().split("T")[0];
    const propData = {
      fromDate: fromDate,
      toDate: toDate
    };
    getCallbackData(propData);
    setCommonDownloadModal(true);
  };

  // Handle date change
  const handleDateChange = (date, isFromDate) => {
    if (isFromDate) {
      setSelectedFromDate(date);
      setIsClearDisable(false);
    } else {
      setSelectedToDate(date);
      setIsClearDisable(false);
    }
  };

  const serviceInoformationlastGenRun = async () => {
    try {
      setIsLoading(true);
      let res = await lastGenRunDate(mainDistrictId);
      if (res) {
        setLastGenDate(res.data[0][0]);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    serviceInoformationlastGenRun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClear = () => {
    setSelectedToDate(today);
    setSelectedFromDate(today);
    setIsClearDisable(true);
  };
  return (
    <>
      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.uploadErrorsHistory.title")}
        body={
          <>
            {isLoading && <CircularLoaderFullPage loading={isLoading} />}
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}
            <Typography>
              {t("reports.uploadErrorsHistory.runDateRange")}
            </Typography>
            <br />
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <DatePickerField
                    name="serviceDate"
                    label={t("reports.uploadErrorsHistory.from")}
                    value={selectedFromDate}
                    minDate={dayjs(uploadErrorsMinimumDate)}
                    maxDate={
                      lastGenDate
                        ? dayjs(lastGenDate?.last_gen_run_date)
                        : today
                    }
                    onChange={(date) => handleDateChange(date, true)}
                    locale={localeByBranch}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <DatePickerField
                    name="serviceDate"
                    label={t("reports.uploadErrorsHistory.to")}
                    value={selectedToDate}
                    minDate={dayjs(uploadErrorsMinimumDate)}
                    maxDate={
                      lastGenDate
                        ? dayjs(lastGenDate?.last_gen_run_date)
                        : today
                    }
                    onChange={(date) => handleDateChange(date, false)}
                    locale={localeByBranch}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button
              variant="outlined"
              disabled={isClearDisable}
              onClick={handleClear}
            >
              {t("common.clear")}
            </Button>

            <Button variant="contained" onClick={handlePrint}>
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default UploadErrorsHistory;
