import { Grid } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";

const AccountSummaryTable = ({ tableData }) => {
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch:
          (!tableData || tableData.length === 0) && `${t("common.noDataFound")}`
      }
    }
  };
  const columns = [
    {
      name: "customer_number",
      label: t("reports.vaultStatusReport.customer"),
      options: {
        customBodyRender: (value) => {
          return value?.trim() ? value : t("common.unknown");
        }
      }
    },
    {
      name: "media_type_descr",
      label: t("reports.vaultStatusReport.media")
    },

    {
      name: "inbound_count",
      label: t("reports.vaultStatusReport.ofInbound")
    },

    {
      name: "outbound_count",
      label: t("reports.vaultStatusReport.ofOutbound")
    },
    {
      name: "audited_count",
      label: t("reports.vaultStatusReport.ofMediaAudited")
    },
    {
      name: "destruction_count",
      label: t("reports.vaultStatusReport.ofDestruction")
    },
    {
      name: "unresolved_count",
      label: t("reports.vaultStatusReport.ofUnresolvedDiscrepancy")
    }
  ];
  return (
    <Grid item xs={12} lg={12} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={tableData ? [...tableData] : []}
        options={options}
      />
    </Grid>
  );
};

export default AccountSummaryTable;
