import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { FullCircularLoader, Title } from "components/core";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLoginMutation } from "services/api";
import useAuthService from "services/authService";
import { selectAuth } from "store/slices";
import SBLogo from "../../assets/images/SB_Logo.svg";
import GoogleLogo from "../../assets/images/google_logo.svg";
import { Styles } from "./Signin.Styles";
import { signinSchema } from "./schema";

function Signin() {
  const { t } = useTranslation();
  const [result] = useLoginMutation();
  const authService = useAuthService();
  const [loginError, setLoginError] = useState("");
  const [showHoverEffect, setShowHoverEffect] = useState(false);
  const [loading, setLoading] = useState(false);
  const { error } = useSelector(selectAuth);

  const handleSigninWithGoogle = async () => {
    setLoading((prev) => true);
    const user = await authService.signInFunction("", "", "google");
    if (user?.msg) {
      setLoginError(user?.msg);
    }
    setLoading((prev) => false);
  };

  return (
    <Box sx={Styles.boxStyles}>
      <div style={Styles.sbLogoStyls}>
        <img alt="sb_logo" src={SBLogo} />
      </div>

      <button
        style={showHoverEffect ? Styles.hoverEffect : Styles.materialButton}
        onClick={() => handleSigninWithGoogle()}
        onMouseEnter={() => setShowHoverEffect(true)}
        onMouseLeave={() => setShowHoverEffect(false)}
      >
        <span style={Styles.materialIcon}>
          <img
            alt="google_logo"
            src={GoogleLogo}
            style={Styles.googleImgStyles}
          />
        </span>
        <span>{t("signin.googleSigninButton")}</span>
      </button>

      <Grid
        item
        sm={12}
        md={12}
        xl={12}
        sx={{ ...Styles.separatorContainer, ...Styles.signInOrDiv }}
      >
        <Grid sx={Styles.lineSeparator}></Grid>
        <Typography sx={Styles.separatorBody}>Or</Typography>
        <Grid sx={Styles.lineSeparator}></Grid>
      </Grid>
      <br />
      {loading && <FullCircularLoader />}
      <Formik
        initialValues={{
          userId: "",
          password: ""
        }}
        validationSchema={signinSchema}
        onSubmit={async (values) => {
          setLoading((prev) => !prev);
          const user = await authService.signInFunction(
            values?.userId,
            values?.password,
            "email"
          );
          setLoginError(user?.msg);
          setLoading((prev) => !prev);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Stack spacing={2}>
              <Field
                name="userId"
                as={TextField}
                label="Email"
                fullWidth
                variant="outlined"
                error={Boolean(errors.userId) && touched.userId}
                helperText={touched.userId && errors.userId}
              />
              <Field
                name="password"
                as={TextField}
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                error={Boolean(errors.password) && touched.password}
                helperText={touched.password && errors.password}
              />
            </Stack>
            <Stack spacing={2}>
              <div style={Styles.loginButtonDivStyles}>
                <Button
                  type="submit"
                  variant="contained"
                  style={Styles.loginButton}
                >
                  Login
                </Button>
              </div>

              {(result?.data?.success === false || loginError || error) && (
                <Title
                  variant="body1"
                  gutterBottom
                  styles={{ color: "#d32f2f" }}
                  text={result?.data?.message || loginError || error}
                />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default Signin;
