import { Button, Grid, Paper, TableContainer, Typography } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { Styles } from "./Styles";

const DataTable = ({
  resultSet,
  handlePrintClick,
  handleRowSelection,
  selectedRows,
  handleRetrieveClick,
  isFindEnabled
}) => {
  const columns = [
    {
      name: "number",
      label: t("disasterRecovery.instructionFiles.customerLabel"),
      options: {
        sort: true
      }
    },
    {
      name: "source",
      label: t("disasterRecovery.instructionFiles.sourceLabel"),
      options: {
        sort: true
      }
    },
    {
      name: "dr_instruction_name",
      label: t("disasterRecovery.instructionFiles.drLabel"),
      options: {
        sort: true
      }
    },
    {
      name: "last_mod_date",
      label: t("disasterRecovery.instructionFiles.postedLabel"),
      options: {
        sort: true
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: resultSet?.length && true,
    onRowSelectionChange: handleRowSelection,
    selectableRowsOnClick: true,
    selectToolbarPlacement: "none",
    rowsSelected: selectedRows,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    setRowProps: (row, dataIndex) => {
      const isSelected = selectedRows?.includes(dataIndex);
      return {
        style: {
          backgroundColor: isSelected && "var(--color-im-light-blue-100)"
        }
      };
    },
    customToolbar: () => (
      <>
        {resultSet?.length ? (
          <Grid
            item
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            gap={spacing.verticalMargin20}
          >
            <Button
              variant="outlined"
              size="medium"
              type="button"
              onClick={() => handlePrintClick()}
            >
              {t("common.print")}
            </Button>
            {selectedRows?.length ? (
              <Button
                variant="outlined"
                size="medium"
                type="button"
                onClick={() => {
                  handleRetrieveClick();
                }}
              >
                {t("disasterRecovery.instructionFiles.retrieveFile")}
              </Button>
            ) : (
              ""
            )}
          </Grid>
        ) : (
          ""
        )}
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          resultSet?.length === 0 && !isFindEnabled
            ? `${t("common.noRecordsFound")}`
            : `${t("disasterRecovery.eventSearch.noRecordsFound")}`
      }
    }
  };

  return (
    <>
      <Paper sx={Styles.tableStyles?.tablePaper}>
        <div style={Styles.widthFull}>
          <TableContainer>
            <MUIDataTable
              columns={columns}
              options={options}
              data={resultSet}
              title={
                <>
                  {resultSet?.length ? (
                    <Typography style={Styles.tableStyles.titleStyle}>
                      {resultSet?.length} {t("common.recordsFound")}
                    </Typography>
                  ) : (
                    ""
                  )}
                </>
              }
            />
          </TableContainer>
        </div>
      </Paper>
    </>
  );
};

export default DataTable;
