import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from "@mui/material";
import { useFormikContext } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetGlobalAttributeValueByTypeIdQuery } from "services/api";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  GLOBAL_ATTRIBUTE_VALUES,
  NO,
  SYSTEM_ID_SecureBaseWeb,
  YES
} from "utils/constants";
import { formatDateByLocale } from "utils/helpers";
import {
  CSV,
  TXT
} from "../../media-destruction-request-vault-itemized/Constants";
import {
  EmptyContentMessageId,
  RequestStatusIds,
  TXT_CSV_MESSAGEID,
  maxFileLength,
  maxLengthMessageId,
  preparedForRunMessagId,
  regexToCheckAlphaNumeric,
  replaceValueFromMessageDescr
} from "../Constants";
import { MDRPickupFormStyles } from "../MediaDestructionRequestPickup.style";
const MDRPickupUploadFile = ({
  itemCount,
  setItemCount,
  triggerMessage,
  setIsError,
  setErrorMessage,
  findErrorMessage,
  dateUploaded,
  setDateUploaded,
  setVolserList,
  IsOtherRequestValid,
  otherRequestStatusId,
  localeByBranch
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const importRef = useRef(null);
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [error, setError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(false);
  const [visibleTable, setVisibleTable] = useState(false);
  const [onAccept, setOnAccept] = useState(false);
  const [volserVal, setVolserVal] = useState("");
  const handelReject = () => {
    setVisibleTable(false);
  };

  useEffect(() => {
    if (formik.values.uploadFileRequired) {
      setError(true);
      setFormErrorMessage(t("pickup.importFileRequired"));
      formik.setFieldValue("uploadFileRequired", false);
      setFileName("");
    }
    // eslint-disable-next-line
  }, [formik.values.uploadFileRequired]);

  useEffect(() => {
    if (formik.values.cfs_data_row_count) {
      setOnAccept(true);
      setDateUploaded(formik.values.cfs_created_datetime_ltz);
      setQuantity(formik.values.cfs_data_row_count);
    }
    // eslint-disable-next-line
  }, [formik.values.cfs_data_row_count]);

  const dataTableTheme = createTheme({
    overrides: {
      MuiPaper: {
        root: {
          boxShadow: "none"
        }
      }
    }
  });
  const {
    data: globalAttributeValue = "",
    isLoading: isLoadingGlobalAttribute,
    isError: isGlobalAttributeError
  } = useGetGlobalAttributeValueByTypeIdQuery({
    id: GLOBAL_ATTRIBUTE_VALUES.VALUE_94,
    system_id: SYSTEM_ID_SecureBaseWeb
  });

  const VOLSER_LIMIT_VALIDATION =
    globalAttributeValue !== "" ||
    !isGlobalAttributeError ||
    !isLoadingGlobalAttribute
      ? Number(globalAttributeValue)
      : 0;
  const columns = [
    {
      name: "sequence",
      label: t("pickup.sequenceLabel")
    },
    {
      name: "item_number",
      label: t("pickup.itemNumberLabel")
    },
    {
      name: "valid",
      label: t("pickup.validLabel")
    }
  ];
  const dataTableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    elevation: 0,
    responsive: "vertical",
    tableBodyHeight: "356px",
    tableBodyMaxHeight: "356px",
    tabelBodyMaxWidth: "100%",
    setRowProps: (row) => ({
      style: {
        ...MDRPickupFormStyles.heightStyle,
        backgroundColor: row[2] === NO && "var(--color-im-light-red)"
      }
    })
  };
  //handle message
  const handleMessage = (messageId, replaceValue) => {
    setVisibleTable(false);
    triggerMessage({
      message_id: messageId
    })
      .then((data) => {
        if (data.data[0].descr.includes(replaceValueFromMessageDescr)) {
          setFormErrorMessage(
            data.data[0].descr.replace(
              replaceValueFromMessageDescr,
              replaceValue
            )
          );
        } else {
          setFormErrorMessage(data.data[0].descr);
        }
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      });
  };
  // handle file import event handler
  const handleFileImport = async (event) => {
    const file = event.target.files[0];
    setError(false);
    setFileName(file.name);
    if (file) {
      //   Check if the selected file has a .txt or .csv extension
      if (
        !(
          file.name.toLowerCase().endsWith(TXT) ||
          file.name.toLowerCase().endsWith(CSV)
        )
      ) {
        setError(true);
        handleMessage(TXT_CSV_MESSAGEID);
        // Clear the file input
        importRef.current.value = null;
        return;
      } else {
        try {
          const reader = new FileReader();
          reader.onload = (e) => {
            const content = e.target.result;
            if (!content.trim()) {
              // Content is empty, trigger the modal here
              setError(true);
              handleMessage(EmptyContentMessageId);
            } else {
              // Content is not empty, process the file content
              processFileContent(content);
            }
            // Reset the input value to trigger the onChange event next time
            importRef.current.value = null;
          };
          reader.readAsText(file);
        } catch (error) {
          setError(true);
          setFormErrorMessage(t("mediaRequestDetail.errorReadingTheFile"));
        }
      }
    }
  };
  // read file content and validate open medias
  const processFileContent = (content) => {
    const lines = content.split("\n");
    const newData = [];
    const invalids = [];
    const valid = [];
    let volserList = "";
    setTotalCount(lines.length);
    if (lines.length >= maxFileLength) {
      setError(true);
      handleMessage(maxLengthMessageId, maxFileLength);
    } else {
      setError(false);
      setFormErrorMessage("");
      setVisibleTable(true);
      lines.forEach((line, index) => {
        const trimmedLine = line?.trim();
        const volser = trimmedLine;
        //Except Empty Values
        if (volser !== "") {
          let obj = {
            sequence: index + 1,
            item_number: trimmedLine
          };
          if (
            trimmedLine.length <= VOLSER_LIMIT_VALIDATION &&
            regexToCheckAlphaNumeric.test(trimmedLine)
          ) {
            const val = { ...obj, valid: YES };
            newData.push(val);
            valid.push(val);
          } else {
            const val = { ...obj, valid: NO };
            newData.push(val);
            invalids.push(val);
          }
          volserList += volser.padEnd(20);
        }
      });

      setData(newData);
      setValidCount(valid.length);
      setInvalidCount(invalids.length);
      setVolserVal(volserList);
    }
  };
  //ready only field
  const readOnlyField = (label, value) => {
    return (
      <Grid item xs={3}>
        <TextField
          size="medium"
          variant="standard"
          fullWidth
          InputProps={{
            readOnly: true,
            disableUnderline: true
          }}
          InputLabelProps={{
            shrink: true,
            focused: false
          }}
          label={label}
          id={label.toLowerCase().replace(/ /g, "-")}
          value={value}
        />
      </Grid>
    );
  };
  //handle accept button
  const handleAccept = async () => {
    const otherRequestStatus = IsOtherRequestValid(otherRequestStatusId);
    if (otherRequestStatus) {
      setOnAccept(true);
      setVisibleTable(false);
      const uploadDate = await formatDateByLocale(
        Date.now(),
        localeByBranch,
        false
      );
      setDateUploaded(uploadDate);
      setQuantity(validCount);
      setVolserList(volserVal);
      setItemCount(validCount);
      setError(false);
    } else {
      setError(true);
      handleMessage(preparedForRunMessagId);
    }
  };
  //file details
  const viewingFileDetails = (
    fileName,
    totalCount,
    validItemCount,
    invalidItemCount,
    style
  ) => {
    return (
      <Grid container spacing={spacing.verticalMargin20} sx={style}>
        {readOnlyField(t("pickup.fileName"), fileName)}
        {readOnlyField(t("pickup.itemCount"), totalCount)}
        {readOnlyField(t("pickup.validItemCount"), validItemCount)}
        {readOnlyField(t("pickup.inValidItemCount"), invalidItemCount)}
      </Grid>
    );
  };

  return (
    formik.values.schedulePreferredPickupDate && (
      <>
        <Stack spacing={spacing.horizontalMargin20}>
          <Stack direction={"row"} alignItems="center">
            <Stack spacing={spacing.horizontalMargin8}>
              <Box>
                <Typography
                  variant="caption"
                  id="upload file"
                  sx={MDRPickupFormStyles.BranchLabel}
                >
                  {t("pickup.uploadFileLabel")}
                </Typography>
              </Box>
              <Box>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  disabled={
                    formik.values.statusId === RequestStatusIds.Cancelled ||
                    formik.values.statusId === RequestStatusIds.InProcess ||
                    formik.values.statusId === RequestStatusIds.Completed ||
                    formik.values.statusId === RequestStatusIds.PendingReview ||
                    formik.values.statusId ===
                      RequestStatusIds.PendingConfirmation
                  }
                >
                  {t("pickup.upload")}
                  <input
                    ref={importRef}
                    type="file"
                    accept=".txt, .csv"
                    style={MDRPickupFormStyles.uploadFileStyle}
                    onChange={handleFileImport}
                  />
                </Button>
              </Box>
              <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                {error ? (
                  <FormHelperText error>{formErrorMessage}</FormHelperText>
                ) : (
                  <FormHelperText>
                    {t("pickup.defaultMessageforImport")}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                {error && fileName !== "" ? (
                  <Stack direction="row">
                    <Box sx={MDRPickupFormStyles.FieldWidthMedium}>
                      <FormHelperText error>{fileName}</FormHelperText>
                    </Box>
                    <IconButton
                      aria-label={t("common.close")}
                      size="small"
                      onClick={() => {
                        setError(false);
                        setFormErrorMessage("");
                      }}
                    >
                      <CloseIcon fontSize="inherit" color="error" />
                    </IconButton>
                  </Stack>
                ) : null}
              </Box>
            </Stack>
            {onAccept && (
              <>
                <Box>
                  {/* date Uploaded */}
                  {readOnlyField(t("pickup.dateUploaded"), dateUploaded)}
                </Box>
                <Box>
                  {/* Quantity */}
                  {readOnlyField(t("mediaRequestDetail.quantity"), quantity)}
                </Box>
              </>
            )}
          </Stack>
        </Stack>
        {visibleTable && (
          <Grid item>
            <Card id="upload-file">
              <CardHeader
                id="card-header"
                title={t("pickup.importedFileDetail")}
              />
              <CardContent id="content" sx={MDRPickupFormStyles.cardContent}>
                <Stack spacing={spacing.horizontalMargin20}>
                  <Box>
                    {viewingFileDetails(
                      fileName,
                      totalCount,
                      validCount,
                      invalidCount,
                      MDRPickupFormStyles.gridDetails
                    )}
                  </Box>

                  {/* Open media table */}
                  <Box>
                    <ThemeProvider theme={dataTableTheme}>
                      <MUIDataTable
                        id="mui-table"
                        data={data}
                        columns={columns}
                        options={dataTableOptions}
                      />
                    </ThemeProvider>
                  </Box>
                  <Stack sx={MDRPickupFormStyles.instructionMain}>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={MDRPickupFormStyles.instruction}
                      >
                        {t("pickup.instruction1")}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={MDRPickupFormStyles.instruction}
                      >
                        {t("pickup.instruction2")}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={MDRPickupFormStyles.instruction}
                      >
                        {t("pickup.instruction3")}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction={"row"}
                    sx={MDRPickupFormStyles.bottomButtons}
                  >
                    {/* Reject button */}
                    <Box sx={MDRPickupFormStyles.rejectButton}>
                      <Button
                        variant="outlined"
                        onClick={handelReject}
                        id="reject-button"
                      >
                        {t("pickup.reject")}
                      </Button>
                    </Box>

                    {/* Accept button */}
                    <Button
                      variant="contained"
                      disabled={invalidCount > 0}
                      onClick={handleAccept}
                      id="accept-button"
                    >
                      {t("pickup.accept")}
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        )}
      </>
    )
  );
};

export default MDRPickupUploadFile;
