import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Tooltip
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { ReactComponent as CollapseAll } from "assets/images/CollapseAll.svg";
import { ReactComponent as ExpandAll } from "assets/images/ExpandAll.svg";
import { ReactComponent as Find } from "assets/images/Find.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader, SelectField } from "components/core";
import Typography from "components/shared/Typography";
import AsyncConfirm from "components/shared/confirm-popup/AsyncConfirmPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GENERAL,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH,
  RUN_MANAGEMENT
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import {
  getCustomersQueryData,
  getEmployeeDetails,
  getMessageFromCode
} from "services/api/query";
import { selectAuth, setCurrentBranch } from "store/slices";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  N_CHECKER,
  ROLE_ACCESS_CODES,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  dateFormat,
  dateFormatWithoutTime,
  errorMsgs
} from "utils/constants";
import { general } from "utils/constants/container-processing/ContainerProcessing";
import {
  arrivalReportTab,
  hintTextColor,
  pickReportTab,
  systemFunctionId,
  vendorTypes
} from "utils/constants/transportation/RunManagementConstants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getSortedData
} from "utils/helpers";
import FindCustomerModal from "./FindCustomerModal";
import { runManagementStyles } from "./RunManagementStyle";
import RunModal from "./RunModal";
import RunPrintModal from "./RunPrintModal";
import RunTreeView from "./RunTreeView";
import UnassignedTreeView from "./UnassignedTreeView";
import {
  getErrorMessage,
  getListOfExpandedNodes,
  getListOfExpandedNodesNewIds,
  getOpenNodes,
  getResponseData,
  getRunTreeData,
  getUnassignedTreeData
} from "./helper";
import "./treeStyle.css";

const RunManagement = () => {
  const { date } = useParams();
  const [runDateOptions, setRunDateOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [runData, setRunData] = useState([]);
  const [unassingnedData, setUnassignedData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [isShowRunModal, setIsShowRunModal] = useState(false);
  const [editRunInfo, setEditRunInfo] = useState(null);
  const [isShowFindCustomerModal, setIsShowFindCustomerModal] = useState(false);
  const [isRunExpand, setIsRunExpand] = useState(false);
  const [isUnassignedExpand, setIsUnassignedExpand] = useState(false);
  const [selectedNodeIdMain, setSelectedNodeIdMain] = useState(null);
  const [selectedNodeIdSub, setSelectedNodeIdSub] = useState(null);
  const [currentRunInfoMain, setCurrentRunInfoMain] = useState(null);
  const [currentRunInfoSub, setCurrentRunInfoSub] = useState(null);
  const [unassignedTreeData, setUnassignedTreeData] = useState();
  const [runTreeData, setRunTreeData] = useState();
  // Handle Open State of Main Run Tree
  const [expandIdsMain, setExpandIdsMain] = useState([]);
  const [runTreeExpandedNodesMain, setRunTreeExpandedNodesMain] = useState([]);
  // Handle Open State of Sub Run Tree
  const [expandIdsSub, setExpandIdsSub] = useState([]);
  const [runTreeExpandedNodesSub, setRunTreeExpandedNodesSub] = useState([]);

  const [selectedRunId, setSelectedRunId] = useState(null);
  const [isShowPrintModal, setIsShowPrintModal] = useState(false);
  const [getConfirmation, Confirmation] = AsyncConfirm();
  const { roleAccess, localeByBranch } = useSelector(selectAuth);
  const isUserHasPermission = roleAccess.includes(ROLE_ACCESS_CODES.CODE_50);
  const navigate = useNavigate();

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      setCurrentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const initalRunState = {
    runDate: null,
    selectedRunDate: null
  };

  const runDateForm = useFormik({
    initialValues: initalRunState
  });

  useEffect(() => {
    throwError && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [throwError]);

  useEffect(() => {
    if (mainDistrictId) {
      getInitialData();
    }
    //eslint-disable-next-line
  }, [mainDistrictId]);

  useEffect(() => {
    openOldNodes();
    //eslint-disable-next-line
  }, [runTreeData]);

  useEffect(() => {
    if (unassingnedData.length === 0) {
      setUnassignedTreeData(unassingnedData);
    } else if (unassingnedData.length > 0 && customerData.length > 0) {
      setUnassignedTreeData(
        getUnassignedTreeData(unassingnedData, customerData, localeByBranch)
      );
    }
    //eslint-disable-next-line
  }, [customerData, unassingnedData]);

  useEffect(() => {
    if (runData.length === 0) {
      setRunTreeData(runData);
    } else if (runData.length > 0 && customerData.length > 0) {
      setRunTreeData(getRunTreeData(runData, customerData, driverData));
    }
    //eslint-disable-next-line
  }, [customerData, runData]);

  useEffect(() => {
    if (date && runDateOptions?.length) {
      const difDate = runDateOptions?.find(
        (d) => d?.value.split("T")[0] === date
      );
      runDateChangeBasedOnParams(difDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, runDateOptions]);

  const showError = () => {
    setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    setThrowError(true);
  };

  const getInitialData = async () => {
    await Promise.all([getVendorDetails(), getRunDates()]);
  };

  const handleOpenCustomer = (customerName) => {
    let openNodeData = getOpenNodes(runTreeData, customerName);
    setIsRunExpand(false);
    setExpandIdsMain(openNodeData.openNodeList);
    window.setTimeout(function () {
      document.getElementById(openNodeData.scrollToId).scrollIntoView();
    }, 1000);
  };

  const getRunDates = async () => {
    try {
      setIsLoading(true);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        include_run_gen_date: isUserHasPermission ? Y_CHECKER : N_CHECKER
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.runManagement.getUpdatableRuns}`,
        1
      );
      let runDates = data?.data[0];
      let dateOptions = [];
      if (runDates.length) {
        await import(`dayjs/locale/${localeByBranch}.js`);
        let sortedData = getSortedData(runDates, "run_date", "asc");
        sortedData.forEach(async (date) =>
          dateOptions.push({
            value: date.run_date,
            label: dayjs
              .utc(date.run_date)
              .locale(localeByBranch)
              .format(dateFormatWithoutTime),
            flag: date.flag
          })
        );
        setRunDateOptions(dateOptions);
      } else if (data?.error) {
        showError();
      }
      vehicleData.length > 0 &&
        driverData.length > 0 &&
        vendorData.length > 0 &&
        setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      showError();
    }
  };

  const handleRunDateChange = async (e) => {
    let value = e.target?.value;
    setIsLoading(true);
    setThrowError(false);
    let dateObj = runDateOptions.find((date) => date.value === value);
    runDateForm.setFieldValue("runDate", value);
    if (dateObj.flag !== errorMsgs.noError) {
      let message = await getErrorMessage(errorMsgs.errorCode20385);
      setIsLoading(false);
      let selection = await getConfirmation(message);
      if (selection) {
        setIsLoading(true);
        let requestBody = JSON.stringify({
          main_district_id: mainDistrictId,
          service_date: dayjs.utc(value).format(dateFormat.universalFormat),
          system_function_id: systemFunctionId
        });
        let data = await getResponseData(
          requestBody,
          `${CF_URLS.runManagement.generateScheduledRun}`,
          1
        );
        if (data?.data[0]) {
          getRunDates();
          setData(value);
        }
      } else {
        runDateForm.setFieldValue(
          "runDate",
          runDateForm?.values?.selectedRunDate
        );
        return;
      }
    } else {
      runDateForm.setFieldValue("selectedRunDate", value);
      setData(value);
    }
    setIsRunExpand(false);
    setExpandIdsMain([]);
    setRunTreeExpandedNodesMain([]);
    setExpandIdsSub([]);
    setRunTreeExpandedNodesSub([]);
  };

  const reset = () => {
    setThrowError(false);
    setIsLoading(true);
    setExpandIdsMain([]);
    setExpandIdsSub([]);
    setSelectedNodeIdMain(null);
    setSelectedNodeIdSub(null);
    setCurrentRunInfoMain(null);
    setCurrentRunInfoSub(null);
    setSelectedRunId(null);
  };

  const setData = async (date) => {
    reset();
    !customerData.length && getCustomerData();
    await Promise.all([
      !unassingnedData.length && getUnassignedRequests(),
      getAassignedRequests(date)
    ]);
    setIsLoading(false);
  };

  const getCustomerData = async () => {
    try {
      let data = await getCustomersQueryData(mainDistrictId);
      setCustomerData(data);
    } catch (e) {
      setIsLoading(false);
      showError();
    }
  };

  const getUnassignedRequests = async () => {
    try {
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.runManagement.getUnassignedRequests}`,
        2
      );
      let runDetails = data?.data[1];
      if (runDetails.length) {
        setUnassignedData(runDetails);
      } else if (data?.error) {
        showError();
      }
    } catch (e) {
      setIsLoading(false);
      showError();
    }
  };

  const getAassignedRequests = async (runDate) => {
    try {
      reset();
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        service_date: dayjs.utc(runDate).format(dateFormat.universalFormat)
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.runManagement.getAssignedRequests}`,
        2
      );
      let runDetails = data?.data[1];
      if (runDetails) {
        setRunData(runDetails);
      } else if (data?.error) {
        showError();
      }
    } catch (e) {
      setIsLoading(false);
      showError();
    }
  };

  const getVehicleData = async (requestBody) => {
    let vehicleData = await getResponseData(
      requestBody,
      `${CF_URLS.runManagement.getVehicleDetails}`,
      1
    );
    vehicleData = getSortedData(vehicleData?.data[0], "vehicle_number", "asc");
    const vehicleResult = vehicleData.map((res) => ({
      value: res.vehicle_number,
      label: `${res.vehicle_number} - ${res.vehicle_type_code}`
    }));
    setVehicleData(vehicleResult);
    vehicleData?.error && showError();
  };

  const getDriverData = async () => {
    const driverData = await getEmployeeDetails();
    const driverResult = driverData?.map((res) => {
      return {
        active_flag: res?.active_flag,
        employee_id: res?.employee_id,
        employee_name: `${res?.last_name}, ${res?.first_name}`,
        locale_id: res?.locale_id,
        label: `${res?.last_name}, ${res?.first_name}`,
        value: res?.employee_id
      };
    });
    setDriverData(driverResult);
    driverData?.error && showError();
  };

  const getVendorData = async (requestBody) => {
    let vendorData = await getResponseData(
      requestBody,
      `${CF_URLS.runManagement.getVendorDetails}`,
      1
    );
    vendorData = getSortedData(vendorData?.data[0], "name", "asc");
    const vendorResult = vendorData.map((res) => ({
      value: res.transportation_vendor_id,
      label: res.name
    }));
    setVendorData(vendorResult);
    vendorData?.error && showError();
  };

  const getVendorDetails = async () => {
    try {
      setIsLoading(true);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId
      });
      await Promise.all([
        getVehicleData(requestBody),
        getDriverData(),
        getVendorData(requestBody)
      ]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      showError();
    }
  };

  const handleNewClick = async () => {
    setThrowError(false);
    setEditRunInfo(null);
    setIsShowRunModal(true);
  };

  const getError = async (msgCode, fieldName) => {
    const errorMsg = await getMessageFromCode(String(msgCode));
    if (errorMsg) {
      let message = errorMsg[0]?.descr.replace("|", fieldName);
      setThrowErrorMessage(message);
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const saveRun = async (runForm) => {
    try {
      setIsLoading(true);
      let requestBody = {
        main_district_id: mainDistrictId,
        service_rep_employee_id:
          runForm?.values.vendorType === vendorTypes.ironMountain
            ? runForm?.values.driver?.value
            : "",
        vehicle_number:
          runForm?.values.vendorType === vendorTypes.ironMountain
            ? runForm?.values.vehicle
            : "",
        transportation_vendor_id:
          runForm?.values.vendorType === vendorTypes.thirdPartyVendor
            ? runForm?.values.vendor
            : ""
      };
      let url;
      if (editRunInfo) {
        requestBody = JSON.stringify({
          ...requestBody,
          run_id: runForm?.values.runId
        });
        url = `${CF_URLS.runManagement.updateRun}`;
      } else {
        requestBody = JSON.stringify({
          ...requestBody,
          run_date: dayjs
            .utc(runDateForm?.values?.runDate)
            .format(dateFormat.universalFormat),
          run_name: runForm?.values.runName,
          scheduled_depart_time: runForm?.values.serviceTime.replace(":", "")
        });
        url = `${CF_URLS.runManagement.createRun}`;
      }
      let data = await getResponseData(requestBody, url, 1);
      if (data?.data[0]) {
        await getAassignedRequests(runDateForm?.values?.runDate);
        setIsShowRunModal(false);
        setEditRunInfo(null);
      } else if (data?.error) {
        showError();
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      showError();
    }
  };

  const handleRefreshClick = async () => {
    reset();
    await getUnassignedRequests();
    await getAassignedRequests(runDateForm?.values?.runDate);
    setIsLoading(false);
  };

  const openOldNodes = () => {
    if (runTreeExpandedNodesMain.length > 0) {
      let newExpandedIDs = getListOfExpandedNodesNewIds(
        runTreeData,
        runTreeExpandedNodesMain
      );
      setExpandIdsMain(newExpandedIDs);
    }
    if (runTreeExpandedNodesSub.length > 0) {
      let newExpandedIDs = getListOfExpandedNodesNewIds(
        runTreeData,
        runTreeExpandedNodesSub
      );
      setExpandIdsSub(newExpandedIDs);
    }
  };

  const updateOpenNodesMain = (items) => {
    setRunTreeExpandedNodesMain(getListOfExpandedNodes(runTreeData, items));
  };
  const updateOpenNodesSub = (items) => {
    setRunTreeExpandedNodesSub(getListOfExpandedNodes(runTreeData, items));
  };

  const handleRedirection = (tab) => {
    let runDate = dayjs
      .utc(runDateForm?.values?.runDate)
      .format(dateFormat.universalFormat);
    let path =
      tab === pickReportTab ? `${runDate}/${pickReportTab}` : arrivalReportTab;
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${GENERAL.replace(
        GENERAL,
        `${general}/${path}`
      )}`
    );
  };

  const handlePrint = async () => {
    setThrowError(false);
    if (!selectedRunId) {
      setIsLoading(true);
      await getError(errorMsgs.errorCode20458);
      setIsLoading(false);
    } else {
      setIsShowPrintModal(true);
    }
  };

  const runDateChangeBasedOnParams = async (difDate) => {
    setIsLoading(true);
    setThrowError(false);
    runDateForm.setFieldValue("runDate", difDate?.value);
    if (difDate.flag !== errorMsgs.noError) {
      let message = await getErrorMessage(errorMsgs.errorCode20385);
      setIsLoading(false);
      let selection = await getConfirmation(message);
      if (selection) {
        setIsLoading(true);
        let requestBody = JSON.stringify({
          main_district_id: mainDistrictId,
          service_date: dayjs
            .utc(difDate?.value)
            .format(dateFormat.universalFormat),
          system_function_id: systemFunctionId
        });
        let data = await getResponseData(
          requestBody,
          `${CF_URLS.runManagement.generateScheduledRun}`,
          1
        );
        if (data?.data[0]) {
          getRunDates();
          window.history.replaceState(
            null,
            "",
            `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${RUN_MANAGEMENT}`
          );
          setData(difDate?.value);
        }
      } else {
        runDateForm.setFieldValue(
          "runDate",
          runDateForm?.values?.selectedRunDate
        );
        return;
      }
    } else {
      runDateForm.setFieldValue("selectedRunDate", difDate?.value);
      window.history.replaceState(
        null,
        "",
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${RUN_MANAGEMENT}`
      );
      setData(difDate?.value);
    }
    setIsRunExpand(false);
    setExpandIdsMain([]);
    setRunTreeExpandedNodesMain([]);
    setExpandIdsSub([]);
    setRunTreeExpandedNodesSub([]);
  };

  const runTreeProps = {
    runData,
    runTreeData,
    customerData,
    driverData,
    runDate: runDateForm?.values?.runDate,
    getError,
    setIsLoading,
    isRunExpand,
    setThrowError,
    setThrowErrorMessage,
    showError,
    unassignedTreeData,
    mainDistrictId,
    getUnassignedRequests,
    getAassignedRequests,
    selectedNodeIdMain,
    setSelectedNodeIdMain,
    selectedNodeIdSub,
    setSelectedNodeIdSub,
    localeByBranch,
    setIsShowRunModal,
    setEditRunInfo
  };

  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      {throwError && !isShowRunModal && !isShowPrintModal && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            sx={runManagementStyles.errorIconStyle}
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Typography sx={runManagementStyles.errorStyle}>
              {throwErrorMessage}
            </Typography>
          </Alert>
        </Collapse>
      )}
      <Grid container sx={runManagementStyles.searchGridStyle}>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <SelectField
            id="run-date"
            label={t("runManagement.runDate")}
            value={runDateForm?.values?.runDate}
            onChange={handleRunDateChange}
            options={runDateOptions}
            shrink={runDateForm?.values?.runDate}
            notched={runDateForm?.values?.runDate}
          />
          <Box sx={runManagementStyles.hintStyle}>
            {Object.keys(hintTextColor).map((key) => (
              <span style={runManagementStyles.hintTextStyle} key={key}>
                <div
                  style={{
                    ...runManagementStyles.hintCircleStyle,
                    backgroundColor: hintTextColor[key]
                  }}
                ></div>
                <span>{t(`runManagement.${key}`)}</span>
              </span>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid container columnSpacing={"2rem"}>
        <Grid item>
          <Card sx={runManagementStyles.runCardStyle}>
            <CardContent sx={runManagementStyles.runCardContentStyle}>
              <Typography
                variant="h5"
                sx={runManagementStyles.runCardTitleStyle}
              >
                {t("runManagement.currentRun")}
                {runData.length > 0 && (
                  <Box sx={runManagementStyles.iconBoxStyle}>
                    {!isRunExpand ? (
                      <Tooltip title={t("common.expandAll")}>
                        <Box
                          sx={runManagementStyles.iconStyle}
                          onClick={() => {
                            setExpandIdsMain([]);
                            setExpandIdsSub([]);
                            setIsRunExpand(true);
                          }}
                        >
                          <ExpandAll />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("common.collapseAll")}>
                        <Box
                          sx={runManagementStyles.iconStyle}
                          onClick={() => {
                            setExpandIdsMain([]);
                            setExpandIdsSub([]);
                            setIsRunExpand(false);
                          }}
                        >
                          <CollapseAll />
                        </Box>
                      </Tooltip>
                    )}
                    <Tooltip title={t("runManagement.findCustomer")}>
                      <Box
                        sx={{
                          ...runManagementStyles.iconStyle,
                          ...runManagementStyles.findCustomerStyle
                        }}
                        onClick={() => setIsShowFindCustomerModal(true)}
                      >
                        <Find />
                      </Box>
                    </Tooltip>
                  </Box>
                )}
              </Typography>
              <Typography
                sx={{
                  ...runManagementStyles.runCardHintStyle,
                  visibility: currentRunInfoMain ? "visible" : "hidden"
                }}
              >
                {t("runManagement.currentRun") + ": " + currentRunInfoMain}
              </Typography>
              <RunTreeView
                {...runTreeProps}
                mainTree={true}
                setCurrentRunInfoMain={setCurrentRunInfoMain}
                expandIds={expandIdsMain}
                setSelectedRunId={setSelectedRunId}
                updateOpenNodes={updateOpenNodesMain}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={runManagementStyles.runCardStyle}>
            <CardContent sx={runManagementStyles.runCardContentStyle}>
              <Typography
                variant="h5"
                sx={runManagementStyles.runCardTitleStyle}
              >
                {t("runManagement.currentRun")}
              </Typography>
              <Typography
                sx={{
                  ...runManagementStyles.runCardHintStyle,
                  visibility: currentRunInfoSub ? "visible" : "hidden"
                }}
              >
                {t("runManagement.currentRun") + ": " + currentRunInfoSub}
              </Typography>
              <RunTreeView
                {...runTreeProps}
                mainTree={false}
                setCurrentRunInfoSub={setCurrentRunInfoSub}
                expandIds={expandIdsSub}
                setSelectedRunId={null}
                updateOpenNodes={updateOpenNodesSub}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card
            sx={{
              ...runManagementStyles.runCardStyle,
              ...runManagementStyles.unassignedCardStyle
            }}
          >
            <CardContent sx={runManagementStyles.runCardContentStyle}>
              <Typography
                variant="h5"
                sx={runManagementStyles.runCardTitleStyle}
              >
                {t("runManagement.unassignedRequests")}
                {unassingnedData.length > 0 && (
                  <Box sx={runManagementStyles.iconBoxStyle}>
                    {!isUnassignedExpand ? (
                      <Tooltip title={t("common.expandAll")}>
                        <Box
                          sx={runManagementStyles.iconStyle}
                          onClick={() => {
                            setIsUnassignedExpand(true);
                          }}
                        >
                          <ExpandAll />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("common.collapseAll")}>
                        <Box
                          sx={runManagementStyles.iconStyle}
                          onClick={() => {
                            setIsUnassignedExpand(false);
                          }}
                        >
                          <CollapseAll />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                )}
              </Typography>
              <Typography sx={runManagementStyles.runCardHintStyle}>
                {t("runManagement.dragHintText")}
              </Typography>
              <UnassignedTreeView
                unassignedTreeData={unassignedTreeData}
                customerData={customerData}
                isUnassignedExpand={isUnassignedExpand}
                mainDistrictId={mainDistrictId}
                localeByBranch={localeByBranch}
                setIsLoading={setIsLoading}
                setThrowError={setThrowError}
                setThrowErrorMessage={setThrowErrorMessage}
              />
              <Typography sx={runManagementStyles.runCardHintStyle}>
                {t("runManagement.detailsHintText")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <Stack
        direction="row"
        spacing={spacing.verticalMargin20}
        sx={runManagementStyles.buttonRowStyle}
      >
        <Button
          id="new-run-button"
          variant="outlined"
          color="primary"
          size="medium"
          type="button"
          onClick={handleNewClick}
          disabled={!runDateForm?.values?.runDate}
        >
          {t("runManagement.newRun")}
        </Button>
        <Button
          id="print-run-button"
          variant="outlined"
          color="primary"
          size="medium"
          type="button"
          onClick={handlePrint}
          disabled={!runDateForm?.values?.runDate}
        >
          {t("runManagement.printRun")}
        </Button>
        <Button
          id="print-picklist-button"
          variant="outlined"
          color="primary"
          size="medium"
          type="button"
          onClick={() => handleRedirection(pickReportTab)}
          disabled={!runDateForm?.values?.runDate}
        >
          {t("runManagement.printPicklist")}
        </Button>
        <Button
          id="run-report-button"
          variant="outlined"
          color="primary"
          size="medium"
          type="button"
          onClick={() => handleRedirection(arrivalReportTab)}
          disabled={!runDateForm?.values?.runDate}
        >
          {t("runManagement.runArrivalRpt")}
        </Button>
        <Button
          id="refresh-button"
          variant="contained"
          color="primary"
          size="medium"
          type="button"
          onClick={handleRefreshClick}
          disabled={!runDateForm?.values?.runDate}
        >
          {t("common.refresh")}
        </Button>
      </Stack>
      {isShowRunModal && (
        <RunModal
          isShowRunModal={isShowRunModal}
          vehicleData={vehicleData}
          driverData={driverData}
          vendorData={vendorData}
          setIsShowRunModal={setIsShowRunModal}
          setIsLoading={setIsLoading}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
          setThrowError={setThrowError}
          getError={getError}
          runData={runData}
          editRunInfo={editRunInfo}
          saveRun={saveRun}
        />
      )}
      {isShowFindCustomerModal && (
        <FindCustomerModal
          isShowFindCustomerModal={isShowFindCustomerModal}
          setIsShowFindCustomerModal={setIsShowFindCustomerModal}
          setThrowError={setThrowError}
          runTreeData={runTreeData}
          openCustomer={handleOpenCustomer}
        />
      )}
      {isShowPrintModal && (
        <RunPrintModal
          isShowPrintModal={isShowPrintModal}
          setIsShowPrintModal={setIsShowPrintModal}
          selectedRunId={selectedRunId}
          currentRunInfoMain={currentRunInfoMain}
          mainDistrictId={mainDistrictId}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
          setThrowError={setThrowError}
          setIsLoading={setIsLoading}
        />
      )}

      {/* Confimation popup */}
      <Confirmation />
    </>
  );
};
export default RunManagement;
