import styled from "@emotion/styled";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { ReactComponent as CustomerIcon } from "assets/images/CustomerIcon.svg";
import { ReactComponent as DriverIcon } from "assets/images/DriverIcon.svg";
import { ReactComponent as NotepadIcon } from "assets/images/NotepadIcon.svg";
import { t } from "i18next";
import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { VALUE_EMPTY_STRING, errorMsgs } from "utils/constants";
import {
  distribution,
  typeCustomer,
  typeLogicalVault
} from "utils/constants/open-media-processing/WorkManagement";
import { WorkManagementTreeStyles } from "../WorkManagementStyles";

const StyledTreeItem = styled(TreeItem)({
  "& .MuiTreeItem-iconContainer": WorkManagementTreeStyles.iconStyles
});

const VerifyTree = forwardRef(
  (
    {
      verifyList,
      expanded,
      setExpanded,
      isFindClicked,
      setSelectedNode,
      verifyAndCommit,
      setWarning,
      tempTree,
      setTempTree
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      executeHandleExpand: handleExpand,
      executeHandleClick: handleClick
    }));

    const clickTimer = useRef(null);
    // Tree expand event Handler
    const handleExpand = useCallback((event, nodeId, isExpanded) => {
      // To only expand when click on the expand icon
      if (event.target.closest(".MuiTreeItem-iconContainer")) {
        setExpanded((prev) =>
          isExpanded ? [...prev, nodeId] : prev.filter((id) => id !== nodeId)
        );
      }

      // eslint-disable-next-line
    }, []);

    const handleClick = (e, id, nodeId) => {
      if (tempTree.click) {
        id = e.props.idx;
        nodeId = e.props.nid;
        e = e.props.event;

        setTempTree({
          click: false,
          clickObj: null
        });
      }

      if (
        verifyAndCommit?.commit === false &&
        verifyAndCommit?.verify === true
      ) {
        setTempTree({
          click: true,
          clickObj: { props: { event: e, idx: id, nid: nodeId } }
        });
        setWarning(errorMsgs?.errorCode25161);
        return;
      }

      if (e.target.closest(".MuiTreeItem-iconContainer")) {
        return;
      }

      if (clickTimer.current) {
        clearTimeout(clickTimer.current);
        clickTimer.current = null;
        nodeDoubleClick(e, id, nodeId);
      } else {
        clickTimer.current = setTimeout(() => {
          setSelectedNode(nodeId);

          clickTimer.current = null;
        }, 250);
      }
    };

    const nodeDoubleClick = (e, id, nodeId) => {
      clearTimeout(clickTimer.current);
      setExpanded((prev) =>
        prev.includes(nodeId)
          ? prev.filter((id) => id !== nodeId)
          : [...expanded, nodeId]
      );

      setSelectedNode(nodeId);
    };

    const getThirdLevelChildren = (records) => {
      return records
        ? records.flatMap((record) =>
            record.data.map((dataEn, i) => ({
              nodeId: record.nodeId,
              label: record.key,
              type: distribution,
              icon:
                dataEn.verification_status_id === VALUE_EMPTY_STRING
                  ? NotepadIcon
                  : DriverIcon,
              id: record.key + dataEn?.created_datetime
            }))
          )
        : [];
    };

    const getSecondLevelChildren = (customer_number) => {
      return verifyList
        .filter((c) => c.customer_number === customer_number)[0]
        ?.logical_vaults?.map((lv, i) => ({
          nodeId: lv.nodeId,
          label: lv.lv,
          type: typeLogicalVault,
          id: lv.lv + lv.records[0]?.key,
          children: getThirdLevelChildren(lv.records)
        }));
    };

    const treeData = [
      {
        nodeId: t("workManagement.verifyTab"),
        label: t("workManagement.verifyTab"),
        children: verifyList?.map((customer, i) => ({
          nodeId: customer.nodeId,
          label: customer.customer_number,
          id: customer.customer_number + customer.logical_vaults[0]?.lv,
          type: typeCustomer,
          icon: CustomerIcon,
          children: getSecondLevelChildren(customer.customer_number)
        }))
      }
    ];
    const renderTreeItems = (nodes) => {
      return nodes.map((node) => (
        <>
          <StyledTreeItem
            key={node.nodeId}
            itemId={node.nodeId}
            onClick={(e) => handleClick(e, node.id, node.nodeId)}
            slots={{
              collapseIcon: () => (
                <ExpandMoreIcon id={`collapse-icon-${node.nodeId}`} />
              ),
              expandIcon: () => (
                <ChevronRightIcon id={`expand-icon-${node.nodeId}`} />
              )
            }}
            label={
              <Box sx={WorkManagementTreeStyles.nodeLableContainer}>
                <Button
                  sx={{ minWidth: 0 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event, node.id, node.nodeId);
                  }}
                >
                  {node.icon && (
                    <SvgIcon
                      component={node.icon}
                      fontSize={WorkManagementTreeStyles.iconSize}
                      sx={WorkManagementTreeStyles.iconStyle}
                    />
                  )}
                </Button>
                {node.label}
              </Box>
            }
          >
            {node.children && renderTreeItems(node.children)}
          </StyledTreeItem>
        </>
      ));
    };

    return (
      <Box>
        {isFindClicked ? (
          <SimpleTreeView
            expandedItems={expanded}
            onItemExpansionToggle={handleExpand}
          >
            {renderTreeItems(treeData)}
          </SimpleTreeView>
        ) : null}
      </Box>
    );
  }
);

VerifyTree.displayName = "VerifyTree";

export default VerifyTree;
