import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useEffect, useReducer, useState } from "react";
import { getMessageFromCode } from "services/api/query";
import { inforeducer, initialInfoState } from "store/slices/work-management";
import spacing from "styles/spacing";
import { errorMsgs } from "utils/constants";
import {
  actionTypes,
  defaultInsertSize20,
  modalCase,
  sortOrderOptions
} from "utils/constants/open-media-processing/WorkManagement";
import { WorkManagementStyles } from "../WorkManagementStyles";

// get the error msg from firestore msg collection
const getTheFirestoreErrorMessage = async (msgId) => {
  const msg = await getMessageFromCode(msgId);
  return { errorMsg: msg[0]?.descr };
};

const PickigActionModals = ({
  openPrintModal,
  insertList,
  validTasks,
  invalidForCancelReportMsg,
  setOpenPrintModal,
  onHandleNoFromCancelReportModal,
  onHandleYesFromCancelReportModal,
  fastPickList,
  selectedSort,
  setselectedSort,
  setSelectedInsertSize,
  selectedInsertSize,
  insertPerList,
  setInsertPerList,
  isByVault,
  setIsByVault,
  selectedFastPick,
  setSelectedFastPick,
  selectedPick,
  onClickYesFromSplitList,
  showCombinedList,
  bIncludeNotInInventory,
  splitLoading,
  cancelBatchMessage,
  onCLickYesFromInvalidCancelReport,
  cancelLoading,
  setIsDownloadPopupVisible,
  combinedList,
  setCombinedList,
  onPrintPickReportButtonClick
}) => {
  const getDisabledState = () => {
    return isByVault || selectedFastPick;
  };

  useEffect(() => {
    insertList?.length &&
      setSelectedInsertSize(
        insertList.find((size) => size.value === defaultInsertSize20)
      );
    //eslint-disable-next-line
  }, [insertList]);

  const [state, dispatch] = useReducer(inforeducer, initialInfoState);
  const [errorMessage, setErrorMessage] = useState("");

  const showConfirmForThis = async (errCode, replacePipe) => {
    const res = await getTheFirestoreErrorMessage(errCode);
    let message = res.errorMsg;
    if (replacePipe) {
      replacePipe.forEach((replaceValue) => {
        message = message.replace("|", replaceValue);
      });
    }
    if (message)
      dispatch({
        type: actionTypes.setInfo,
        code: errCode,
        show: true,
        message
      });
  };
  const reset = (skipFastPick) => {
    setselectedSort(sortOrderOptions[0]);
    setSelectedInsertSize(
      insertList?.find((size) => size.value === defaultInsertSize20)
    );
    setInsertPerList("");
    if (!skipFastPick) setSelectedFastPick(null);
  };
  const onChangeByVault = (e) => {
    let checked = e.target.checked;
    if (checked) {
      reset(false);
    }
    setIsByVault(checked);
  };

  const onChangeFastPick = (val) => {
    reset(true);
    setSelectedFastPick(val);
  };

  const validateInsertPerList = (val) => {
    // Check if the value is a whole number between 1 and 500
    let num = parseInt(val, 10);
    if (Number.isInteger(num) && num >= 1 && num <= 500) {
      return true;
    } else {
      return false;
    }
  };

  const handleOkButtonClickFromSplitList = async () => {
    const { errorCode80421, errorCode80422, errorCode63771, errorCode63764 } =
      errorMsgs;

    if (isByVault) {
      await showConfirmForThis(errorCode80421);
    } else if (selectedFastPick) {
      await showConfirmForThis(errorCode80422);
    } else {
      let isInsertPerListValid = validateInsertPerList(insertPerList);
      if (!isInsertPerListValid) {
        let { errorMsg } = await getTheFirestoreErrorMessage(errorCode63764);
        setErrorMessage(errorMsg);
        return;
      }
      let mediaCount = selectedPick?.data_row_count;
      let numberOfItemsPerList =
        parseInt(selectedInsertSize.value, 10) * parseInt(insertPerList, 10);

      let numberOfLists;

      if (numberOfItemsPerList > mediaCount) {
        numberOfLists = 1;
      } else {
        numberOfLists = Math.floor(mediaCount / numberOfItemsPerList);
        if (mediaCount % numberOfItemsPerList !== 0) {
          numberOfLists++;
        }
      }
      await showConfirmForThis(errorCode63771, [
        numberOfLists,
        numberOfItemsPerList
      ]);
    }
  };

  const closeAndReset = () => {
    resetErrorMessage();
    reset();
    setIsByVault(false);
  };

  const resetErrorMessage = () => {
    dispatch({ type: actionTypes.reset });
  };

  const getConfirmModalContent = () => {
    return (
      <>
        <Button
          variant="contained"
          onClick={() => onClickYesFromSplitList(closeAndReset)}
          sx={WorkManagementStyles.compareButton}
          disabled={splitLoading}
        >
          {t("common.yes")}

          {splitLoading ? (
            <CircularProgress
              size={18}
              sx={WorkManagementStyles.compareButtonLoader}
            />
          ) : null}
        </Button>
        <Button
          variant="outlined"
          onClick={resetErrorMessage}
          disabled={splitLoading}
        >
          {t("common.no")}
        </Button>
      </>
    );
  };

  const getBody = () => {
    switch (openPrintModal) {
      case modalCase.printPickRpt:
        return (
          <>
            <Stack direction="row" gap={spacing.gap}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="sort-by"
                name="sort-by"
                size="medium"
                fullWidth
                defaultValue={sortOrderOptions[0]}
                value={selectedSort}
                options={sortOrderOptions}
                onChange={(e, val) => setselectedSort(val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("workManagement.picking.labelSortBy")}
                  />
                )}
                sx={WorkManagementStyles.commonStyles.widthFixed}
              />
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="insert-size"
                name="insert-size"
                size="medium"
                fullWidth
                defaultValue={insertList.find(
                  (size) => size.value === defaultInsertSize20
                )}
                value={selectedInsertSize}
                options={insertList}
                onChange={(e, val) => setSelectedInsertSize(val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("workManagement.picking.labelInsertSize")}
                  />
                )}
                sx={WorkManagementStyles.commonStyles.widthFixed}
              />
            </Stack>
            {showCombinedList ? (
              <Stack
                alignItems="flex-start"
                mt={spacing.verticalMargin20}
                mb={spacing.horizontalMargin8}
                ml={spacing.horizontalMargin8}
              >
                <FormControlLabel
                  sx={WorkManagementStyles.formControllabelSx}
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={combinedList}
                      onChange={(e) => setCombinedList(e?.target?.checked)}
                    />
                  }
                  label={t("workManagement.picking.labelCombineLists")}
                />
              </Stack>
            ) : null}
          </>
        );
      case modalCase.cancelRpt:
        return (
          <>
            <Typography mb={spacing.gap}>{cancelBatchMessage}</Typography>
            {validTasks?.map((task) => (
              <Typography key={task.batch_id}>Batch {task.batch_id}</Typography>
            ))}
          </>
        );

      case modalCase.invalidCancelRpt:
        return (
          <>
            <Alert
              severity="warning"
              style={WorkManagementStyles.alertPadding}
              icon={
                <img
                  style={WorkManagementStyles.alertSize}
                  src={ErrorIcon}
                  alt="error"
                />
              }
            >
              {cancelBatchMessage}
            </Alert>

            {invalidForCancelReportMsg.map((msg) => (
              <Typography my={spacing.gap10} ml={spacing.gap10} key={msg}>
                {msg}
              </Typography>
            ))}
          </>
        );

      case modalCase.splitList:
        return (
          <>
            {errorMessage && (
              <Box mb={spacing.gap}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setErrorMessage("");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                >
                  {errorMessage}
                </Alert>
              </Box>
            )}

            <Stack direction="row" gap={spacing.gap}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                id="sort-by"
                name="sort-by"
                size="medium"
                fullWidth
                disableClearable={true}
                disabled={getDisabledState()}
                defaultValue={sortOrderOptions[0]}
                value={selectedSort}
                options={sortOrderOptions}
                onChange={(e, val) => setselectedSort(val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("workManagement.picking.labelSortBy")}
                  />
                )}
                sx={WorkManagementStyles.commonStyles.widthFixed}
              />
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="insert-size"
                name="insert-size"
                size="medium"
                fullWidth
                disableClearable={true}
                disabled={getDisabledState()}
                value={selectedInsertSize}
                options={insertList}
                onChange={(e, val) => setSelectedInsertSize(val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("workManagement.picking.labelInsertSize")}
                  />
                )}
                sx={WorkManagementStyles.commonStyles.widthFixed}
              />
            </Stack>

            <Stack direction="row" gap={spacing.gap} mt={spacing.gap}>
              <TextField
                label={t("workManagement.picking.labelInsertsPerList")}
                name="insertsPerList"
                size="medium"
                disabled={getDisabledState()}
                value={insertPerList}
                onChange={(e) => {
                  setErrorMessage("");
                  setInsertPerList(e.target.value);
                }}
                sx={WorkManagementStyles.commonStyles.widthFixed}
                fullWidth
                type="number"
                InputLabelProps={{
                  shrink: insertPerList
                }}
              />
            </Stack>
            <Box my={spacing.gap}>
              <Divider />
            </Box>
            <Stack
              alignItems="flex-start"
              mt={spacing.verticalMargin20}
              mb={spacing.horizontalMargin8}
              ml={spacing.horizontalMargin8}
            >
              <FormControlLabel
                sx={WorkManagementStyles.formControllabelSx}
                control={
                  <Checkbox
                    checked={isByVault}
                    onChange={(e) => onChangeByVault(e)}
                    color="primary"
                  />
                }
                label={t("workManagement.picking.labelByVault")}
              />
            </Stack>
            <Box my={spacing.gap}>
              <Divider />
            </Box>
            <Stack direction="row" gap={spacing.gap} mt={spacing.gap}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="fast-pick"
                name="fast-pick"
                size="medium"
                fullWidth
                disabled={isByVault}
                value={selectedFastPick}
                options={fastPickList || []}
                onChange={(e, val) => onChangeFastPick(val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("workManagement.picking.labelFastPick")}
                  />
                )}
                sx={WorkManagementStyles.commonStyles.widthFixed}
              />
            </Stack>
          </>
        );

      default:
        return null;
    }
  };

  const getButtons = () => {
    switch (openPrintModal) {
      case modalCase.printPickRpt:
        return (
          <>
            <Button variant="outlined" onClick={() => setOpenPrintModal("")}>
              {t("common.close")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onPrintPickReportButtonClick();
              }}
            >
              {t("common.print")}
            </Button>
          </>
        );

      case modalCase.cancelRpt:
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => onHandleYesFromCancelReportModal()}
              disabled={cancelLoading}
              sx={WorkManagementStyles.compareButton}
            >
              {t("common.yes")}{" "}
              {cancelLoading ? (
                <CircularProgress
                  size={18}
                  sx={WorkManagementStyles.compareButtonLoader}
                />
              ) : null}
            </Button>
            <Button
              variant="contained"
              disabled={cancelLoading}
              onClick={() => onHandleNoFromCancelReportModal()}
            >
              {t("common.no")}
            </Button>
          </>
        );
      case modalCase.invalidCancelRpt:
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => onCLickYesFromInvalidCancelReport()}
            >
              {t("common.yes")}
            </Button>
            <Button variant="contained" onClick={() => setOpenPrintModal("")}>
              {t("common.no")}
            </Button>
          </>
        );

      case modalCase.splitList:
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenPrintModal("");
                closeAndReset();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleOkButtonClickFromSplitList()}
            >
              {t("common.ok")}
            </Button>
          </>
        );

      default:
        break;
    }
  };

  const getTitle = () => {
    switch (openPrintModal) {
      case modalCase.printPickRpt:
        return t("workManagement.picking.modalTitlePrintPickRpt");

      case modalCase.cancelRpt:
        return t("workManagement.picking.modalTitleConfirm");

      case modalCase.invalidCancelRpt:
        return t("workManagement.picking.modalTitleConfirm");

      case modalCase.splitList:
        return t("workManagement.picking.modalTitleSplitPickList");
      default:
        break;
    }
  };
  return (
    <>
      <CommonModal
        open={state.showConfirmModal}
        buttons={getConfirmModalContent()}
        title={t("workManagement.picking.modalTitleSplitPickList")}
        body={<Typography>{state.infoMessage}</Typography>}
      />
      <CommonModal
        open={openPrintModal}
        title={getTitle()}
        body={getBody()}
        buttons={getButtons()}
      />
    </>
  );
};

export default PickigActionModals;
