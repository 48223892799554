import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import i18n from "i18n";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import CloseFilledIcon from "assets/images/CloseFilled.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, SearchField } from "components/core";
import ModalPopup from "components/core/modal-components/ModalPopup";
import { useCallbackPrompt } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  assignTransportToRequest,
  getMessageByIdFromMessage,
  loadMediaRequestContainers,
  loadMediaRequestTransports
} from "services/common";
import {
  selectAuth,
  selectMediaRequest,
  setContainers,
  setTransports
} from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  N_CHECKER,
  Y_CHECKER
} from "utils/constants";
import {
  CUSTOMER_TYPE_D,
  MESSAGE_25334
} from "utils/constants/request-module/MediaRequestDetailConstants";
import { MediaRequestDetailFormStyles } from "../MediaRequestDetailForm.Styles";
import { assignTransportModalStyles } from "./AssignTransportModal.Styles";
import DownloadReportModal from "./PrintReportModal";

const dataTableOptions = {
  ...DEFAULT_DATA_TABLE_OPTIONS,
  selectableRows: "none",
  jumpToPage: false,
  pagination: false,
  responsive: "vertical",
  tableBodyHeight: "230px",
  tableBodyMaxHeight: "230px",
  textLabels: {
    body: {
      noMatch: i18n.t("mediaRequestDetail.noItemsToDisplay")
    }
  }
};

const AssignTransportModal = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ show: false, message: "" });
  const [saveChangeModalVisible, setSaveChangeModalVisible] = useState(false); // state for save changes modal
  const dispatch = useDispatch();
  const { transports } = useSelector(selectMediaRequest); // select media request slice
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [assignTransportLoading, setAssignTransportLoading] = useState(false); // loading state when assigning transports to request

  const { currentBranch } = useSelector(selectAuth);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [assignedTransportUpdates, setAssignedTransportUpdates] = useState([]);
  const [availableTransportUpdates, setAvailableTransportUpdates] = useState(
    []
  );

  const [transportChanges, setTransportChanges] = useState([]);
  const haveChanges = useMemo(
    () => transportChanges.length > 0,
    [transportChanges]
  ); // memo for modal have changes or not
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges); // browser back and forward blocker hook

  // column definition of the insert configurations data table
  const tableColumnsDefinition = [
    {
      name: "container_id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() => handleRemoveTransportOnClick(Number(value))}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "number",
      label: t("mediaRequestDetail.assignedTransports")
    }
  ];

  /**
   *
   * @name handleRemoveTransportOnClick
   * @description remove transport from the assigned transport table
   *
   * @param {number} transportId id of the transport
   */
  const handleRemoveTransportOnClick = (transportId) => {
    // find the transport object from the assigned transport list
    const transport = assignedTransportUpdates?.find(
      ({ container_id }) => Number(container_id) === transportId
    );

    if (transport) {
      // add to available transport state
      setAvailableTransportUpdates((prevState) =>
        prevState.concat([transport])
      );
      // remove from assigned transport state
      setAssignedTransportUpdates((prevState) =>
        prevState.filter(
          ({ container_id }) => Number(container_id) !== transportId
        )
      );
      setTransportChanges((prevState) => {
        if (
          transports.assigned.findIndex(
            ({ container_id }) => Number(container_id) === transportId
          ) >= 0
        ) {
          // add the item to the transport changes as a new item marked for deletion
          return prevState.concat([
            {
              ...transport,
              remove_flag: Y_CHECKER
            }
          ]);
        } else {
          // if the item doesn't exists in the request's assigned transports
          const existingRecordIndex = prevState.findIndex(
            ({ container_id, remove_flag }) =>
              Number(container_id) === transportId && remove_flag === N_CHECKER
          );
          // if the item already exists in the changes marked for assigning
          let newState = [...prevState];
          if (existingRecordIndex >= 0) {
            // remove existing item
            newState.splice(existingRecordIndex, 1);
          }

          return newState;
        }
      });
    }
  };

  // Assign transports form
  const assignTransportForm = useFormik({
    initialValues: { transport_id: 0, transport_number: "" },
    onSubmit: (values, _) => {
      const transportId = Number(values.transport_id);
      if (values?.transport_id !== undefined && values?.transport_id !== "") {
        // find the transport object from the available transport list
        const transport = availableTransportUpdates?.find(
          ({ container_id }) => Number(container_id) === transportId
        );

        if (transport) {
          // add to assigned transport state
          setAssignedTransportUpdates((prevState) =>
            prevState.concat([transport])
          );
          // remove from available transport state
          setAvailableTransportUpdates((prevState) =>
            prevState.filter(
              ({ container_id }) => Number(container_id) !== transportId
            )
          );
          setTransportChanges((prevState) => {
            if (
              transports.assigned.findIndex(
                ({ container_id }) => Number(container_id) === transportId
              ) < 0
            ) {
              // add the item to the transport changes as a new item for assigning
              return prevState.concat([
                {
                  ...transport,
                  remove_flag: N_CHECKER
                }
              ]);
            } else {
              // if the item exists in the request's assigned transports
              const existingRecordIndex = prevState.findIndex(
                ({ container_id, remove_flag }) =>
                  Number(container_id) === transportId &&
                  remove_flag === Y_CHECKER
              );

              // if the item already exists in the changes marked for deletion
              let newState = [...prevState];
              if (existingRecordIndex >= 0) {
                // remove existing item
                newState.splice(existingRecordIndex, 1);
              }

              return newState;
            }
          });
          // clear error message
          setError((prevState) => ({ show: false, message: "" }));
          setThrowError(false);
          // reset form
          assignTransportForm.resetForm();
        }
      }
    }
  });

  /**
   * @name handleOnSave
   * @description Assign transport event handler
   *
   * @param {boolean} closeModal close assign transport modal after changes are saved
   *
   **/
  const handleOnSave = (closeModal = false) => {
    setAssignTransportLoading((prevState) => true);
    const requestedTransports = transportChanges.map(
      ({ container_id, remove_flag }) => ({ container_id, remove_flag })
    );
    assignTransportToRequest({
      requestId: props.requestId,
      requestTransports: requestedTransports
    })
      .then(async (resp) => {
        let errors = "";
        // loop through the transport response
        resp.forEach(({ container_number, error_code, error_msg }, index) => {
          // concat the error message and tranport number error
          if (error_code !== 0 && error_msg.toString().length > 0) {
            errors += `${container_number} ${error_msg} `;
          }
        });

        const transports = await loadMediaRequestTransports({
          requestId: props.requestId,
          customerId: props.customerId
        }); // fetch request's transports
        const containers = await loadMediaRequestContainers({
          requestId: props.requestId,
          secureSyncFlag: N_CHECKER
        }); // fetch request's containers

        // show error message if there is any errors
        if (errors.toString().length > 0) {
          // error message 25334
          const message25334 = await getMessageByIdFromMessage({
            message_id: MESSAGE_25334
          });
          const errorMessage = message25334[0]?.descr
            .toString()
            .replace("|", errors.trim());
          setError((prevState) => ({ show: true, message: errorMessage }));
        }

        if (showPrompt && closeModal) {
          // continue with the blocked navigation
          confirmNavigation();
        } else {
          // update transports saved in redux
          dispatch(setTransports(transports));
          // update transports in the local state
          setTransportsToLocalState(transports);
          // update containers saved in redux
          dispatch(
            setContainers(
              containers.map((cont) => ({
                id: cont.container_id,
                container_number: cont.number,
                status: cont.exchange_status
              }))
            )
          );
          // clear transport changes state
          setTransportChanges((prevState) => []);

          setAssignTransportLoading((prevState) => false);
          if (closeModal) {
            // close assign transport modal
            props.onClose(false);
          }
        }
      })
      .catch((err) => setError((prevState) => ({ show: true, message: err })));
  };

  // set assigned and available transports to local states to display in the modal and do the changes locally
  const setTransportsToLocalState = ({ assigned, available }) => {
    setAssignedTransportUpdates((prevState) =>
      assigned.map(({ container_id, number, customer_type }) => ({
        container_id,
        number,
        customer_type
      }))
    );
    setAvailableTransportUpdates((prebState) => available);
  };

  // modal close event handler
  const handleModalClose = (_) => {
    if (!haveChanges) {
      // modal doesn't have changes, then close the modal
      props.onClose(false);
    } else {
      // modal have changes that are not saved
      setSaveChangeModalVisible((prevState) => true);
    }
  };

  // sort transport numbers in ascending order
  const sortTransportsASC = (a, b) => {
    // parse numbers to number
    const numA = Number(a?.slice(2));
    const numB = Number(b?.slice(2));

    // sort numerically
    return numA - numB;
  };

  // init available and assigned transports to the local state
  useEffect(() => {
    setTransportsToLocalState(transports);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      id="assignTransportsModal"
      open={props.show}
      aria-labelledby="assign-transports-modal-title"
      aria-describedby="assign-transports-modal-description"
    >
      <>
        {isLoading && <CircularLoaderFullPage loading={isLoading} />}

        <Card
          id="assignTransportsModalCard"
          sx={assignTransportModalStyles.cardStyle_PRSPopUP}
        >
          <Typography
            id="assignTransportsModalTitle"
            variant="h6"
            sx={assignTransportModalStyles.mainTitle_PRSPopUP}
            color="secondary"
            gutterBottom
          >
            {t("mediaRequestDetail.assignTransports")}
          </Typography>

          {props.onClose && !assignTransportLoading && (
            <IconButton
              aria-label="modal-close"
              sx={assignTransportModalStyles.modalCloseButton}
              onClick={handleModalClose}
              disableRipple
            >
              <img
                src={CloseFilledIcon}
                alt="close icon"
                style={assignTransportModalStyles.modalCloseIcon}
              />
            </IconButton>
          )}

          <Divider />

          <CardContent
            id="assignTransportsModalCardContent"
            sx={assignTransportModalStyles.cardContentStyle_PRSPopUP}
          >
            {/* error message */}
            {error.show && (
              <Alert
                id="assignTransportsModalErrorAlert"
                severity="warning"
                onClose={() =>
                  setError((prevState) => ({ show: false, message: "" }))
                }
              >
                {error.message}
              </Alert>
            )}

            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  sx={{ marginBottom: spacing.horizontalMargin20 }}
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Card
              id="assignTransportsModalCard1"
              sx={assignTransportModalStyles.innerCard}
            >
              <Grid
                id="assignTransportsModalGrid4"
                container
                spacing={2}
                sx={{ padding: spacing.verticalMargin16 }}
              >
                {/* Available transports */}
                <Grid id="assignTransportsModalGrid5" item>
                  <SearchField
                    id="deliveryMediaTabContainerTransportNumber17"
                    name="transport_id"
                    label={t("mediaRequestDetail.transportNumber")}
                    required
                    size="small"
                    noOptionsText={t(
                      "mediaRequestDetail.noTransportsAvailableForAssignment"
                    )}
                    options={availableTransportUpdates
                      ?.map(({ container_id, number }) => ({
                        label: number,
                        value: container_id
                      }))
                      ?.sort((a, b) => sortTransportsASC(a.number, b.number))}
                    loading={false}
                    value={assignTransportForm.values.transport_number}
                    onChange={(_, value) => {
                      assignTransportForm.setFieldValue(
                        "transport_id",
                        Number(value?.value)
                      );
                      assignTransportForm.setFieldValue(
                        "transport_number",
                        value?.label
                      );
                    }}
                    error={Boolean(assignTransportForm.errors.transport_id)}
                    helperText={assignTransportForm.errors.transport_id}
                    sx={{ width: "200px" }}
                  />
                </Grid>

                {/* Add button */}
                <Grid id="assignTransportsModalGrid6" item>
                  <Button
                    id="deliveryMediaTabContainerAddTransport18"
                    variant="outlined"
                    size="medium"
                    disabled={
                      !assignTransportForm.values?.transport_id
                    }
                    onClick={assignTransportForm.handleSubmit}
                    sx={{ textTransform: "initial" }}
                  >
                    {t("mediaRequestDetail.add")}
                  </Button>
                </Grid>
              </Grid>

              {/* Table need to go inside this */}
              <Grid id="assignTransportsModalGrid7" container spacing={2}>
                <Grid id="assignTransportsModalGrid8" item xs={12}>
                  <MUIDataTable
                    id="assignTransportsModalDataTable"
                    data={assignedTransportUpdates
                      ?.sort((a, b) => sortTransportsASC(a.number, b.number))
                      ?.map((trans) => ({
                        ...trans,
                        number: `${trans.number} ${
                          trans?.customer_type?.trim() === CUSTOMER_TYPE_D
                            ? "*"
                            : ""
                        }`
                      }))}
                    columns={tableColumnsDefinition}
                    options={dataTableOptions}
                  />
                </Grid>
              </Grid>
            </Card>
          </CardContent>
          <Grid id="assignTransportsModalGrid9" container>
            <Typography
              variant="body2"
              sx={{ marginX: "auto", marginBottom: spacing.horizontalMargin8 }}
            >
              {t("mediaRequestDetail.temporaryTransportHelperText")}
            </Typography>
          </Grid>
          <Divider />
          <CardActions
            id="assignTransportsModalCard1Actions"
            sx={assignTransportModalStyles.cardActionsStyle_PRSPopUP}
          >
            {/* Close button */}
            <Button
              id="assignTransportsModalCloseButton"
              variant="outlined"
              sx={assignTransportModalStyles.buttonStyle_PRSPopUP}
              type="button"
              onClick={handleModalClose}
            >
              {t("common.close")}
            </Button>

            {/* Print button */}
            <Button
              id="assignTransportsModalPrintButton"
              sx={assignTransportModalStyles.buttonStyle_PRSPopUP}
              disabled={haveChanges || assignedTransportUpdates.length === 0}
              variant="outlined"
              color="primary"
              size="medium"
              type="button"
              onClick={() => setPrintModalVisible((prevState) => !prevState)}
            >
              {t("common.print")}
            </Button>

            {/* Save button */}
            <Button
              id="assignTransportsModalSaveButton"
              variant="contained"
              sx={assignTransportModalStyles.buttonStyle_PRSPopUP}
              disabled={!haveChanges}
              type="submit"
              onClick={() => handleOnSave()}
            >
              {!saveChangeModalVisible &&
                !showPrompt &&
                assignTransportLoading && (
                  <CircularProgress
                    color="primary"
                    sx={{
                      ...MediaRequestDetailFormStyles.ButtonCircularProgress,
                      marginRight: ".5rem"
                    }}
                  />
                )}
              {t("common.save")}
            </Button>
          </CardActions>
        </Card>

        {/* Print transport labels modal */}
        {printModalVisible && (
          <DownloadReportModal
            isVisible={printModalVisible}
            setIsVisible={setPrintModalVisible}
            mainDistrictId={currentBranch?.district_id}
            setIsLoading={setIsLoading}
            requestId={props.requestId}
            throwError={throwError}
            setThrowError={setThrowError}
            throwErrorMessage={throwErrorMessage}
            setThrowErrorMessage={setThrowErrorMessage}
          />
        )}

        {/* Confirm save changes modal */}
        <ModalPopup
          title={t("mediaRequestDetail.assignTransports")}
          alertMessage={t("mediaRequestDetail.wantToSaveChanges")}
          modalPopupOpen={saveChangeModalVisible || showPrompt}
          modalButton={[
            <Button
              id="assignTransportsModalSaveChangesCancelButton"
              key="cancel"
              variant="outlined"
              onClick={() => {
                if (saveChangeModalVisible) {
                  setSaveChangeModalVisible((prevState) => false);
                }

                if (showPrompt) {
                  cancelNavigation();
                }
              }}
            >
              {t("common.cancel")}
            </Button>,
            <Button
              id="assignTransportsModalSaveChangesNoButton"
              key="no"
              variant="outlined"
              onClick={() => {
                if (saveChangeModalVisible) {
                  setSaveChangeModalVisible((prevState) => false);
                  props.onClose(false);
                }

                if (showPrompt) {
                  confirmNavigation();
                }
              }}
            >
              {t("common.no")}
            </Button>,
            <Button
              id="assignTransportsModalSaveChangesSaveButton"
              key="yes"
              variant="contained"
              onClick={() => handleOnSave(true)}
            >
              {(saveChangeModalVisible || showPrompt) &&
                assignTransportLoading && (
                  <CircularProgress
                    color="primary"
                    sx={{
                      ...MediaRequestDetailFormStyles.ButtonCircularProgress,
                      marginRight: ".5rem"
                    }}
                  />
                )}
              {t("common.yes")}
            </Button>
          ]}
        />
      </>
    </Modal>
  );
};

AssignTransportModal.propTypes = {
  show: PropTypes.bool.isRequired, // show/hide modal state
  requestId: PropTypes.number, // ID of the media request
  customerId: PropTypes.string, // ID of the customer
  requestStatus: PropTypes.string.isRequired, // id of the request
  onClose: PropTypes.func // handle modal close event
};

export default AssignTransportModal;
