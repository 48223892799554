import { Grid, Link } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { DetailsStyles } from "./Styles";

const OpenMediaOtherExceptions = ({ tableData, handleOmExceptionDetails }) => {
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch:
          (!tableData || tableData.length === 0) && `${t("common.noDataFound")}`
      }
    }
  };
  const columns = [
    {
      name: "customer_number",
      label: t("reports.vaultStatusReport.customer")
    },
    {
      name: "detected_in",
      label: t("reports.vaultStatusReport.detectedIn")
    },
    {
      name: "logical_vault_code",
      label: t("reports.vaultStatusReport.logicalVault")
    },
    {
      name: "exception_type",
      label: t("reports.vaultStatusReport.exceptionType")
    },
    {
      name: "exception_count",
      label: t("reports.vaultStatusReport.exceptionsCount"),
      options: {
        setCellProps: (value) => ({
          style: {
            ...DetailsStyles.linkStyles,
            ...DetailsStyles.columnStylesm
          }
        }),
        customBodyRender: (value, tableMeta) => {
          const rowData = tableData[tableMeta.rowIndex];
          return (
            <Link
              onClick={() => {
                handleOmExceptionDetails(rowData);
              }}
            >
              {value}
            </Link>
          );
        }
      }
    }
  ];
  return (
    <Grid item xs={12} lg={12} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={tableData ? [...tableData] : []}
        options={options}
      />
    </Grid>
  );
};

export default OpenMediaOtherExceptions;
