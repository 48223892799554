import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Typography
} from "@mui/material";
import {
  ReactComponent as CloseIcon,
  ReactComponent as DeleteIcon
} from "assets/images/CloseIcon.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  VALUE_EMPTY_STRING,
  errorMsgs,
  falseValue,
  type,
  unauthorized
} from "utils/constants";
import { buildType } from "utils/constants/disaster-module/EventSearchConstants";
import { zero } from "utils/constants/disaster-module/InboundDateConst";
import {
  containerNumber,
  volser
} from "utils/constants/disaster-module/logicalVaultRequest";
import {
  possibleMatchesCompare,
  severity
} from "utils/constants/disaster-module/ManualEntryConst";
import {
  formatDateByLocale,
  getAuthenticatedUserBranch,
  getDuplicates,
  getResponseData,
  getTheFirestoreErrorMessage
} from "utils/helpers";
import { disasterRecoveryStyles } from "../DisasterRecoveryStyles";
import AddContainer from "./AddContainer";
import AddMedia from "./AddMedia";
import PossibleMatchesModal from "./PossibleMatches";

const ManualEntry = ({
  customerLocations,
  setAddOpenMedia,
  setAddContainer,
  RequestForm
}) => {
  const [subTitle, setSubTitle] = useState(VALUE_EMPTY_STRING);
  const [title, setTitle] = useState(VALUE_EMPTY_STRING);
  const [openMediaData, setOpenMediaData] = useState([]);
  const [containerData, setContainerData] = useState([]);
  const [openAddMedia, setOpenAddMedia] = useState(false);
  const [openAddContainer, setOpenAddContainer] = useState(false);
  const [errorType, setErrorType] = useState(severity.error);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(VALUE_EMPTY_STRING);
  const [openPossibleMatches, setOpenPossibleMatches] =
    useState(VALUE_EMPTY_STRING);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [selectedContainer, setSelectedContainer] =
    useState(VALUE_EMPTY_STRING);
  const [selectedVolser, setSelectedVolser] = useState(VALUE_EMPTY_STRING);
  const districtId = getAuthenticatedUserBranch();
  const fileRef = useRef(null);
  const { localeByBranch } = useSelector(selectAuth);
  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setErrorMessage(t("printTapeLabel.systemError"));
    }
  };

  useEffect(() => {
    if (errorMessage) {
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }, [errorMessage]);

  const handleError = async (errorCode, replaceText) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);
    if (errorMsg) {
      if (replaceText) {
        if (typeof replaceText === type.string) {
          errorMsg = errorMsg.replaceAll("|", replaceText);
        } else if (Array.isArray(replaceText)) {
          replaceText.forEach((text) => {
            errorMsg = errorMsg.replace("|", text);
          });
        }
      }
      setErrorMessage(errorMsg);
    }
    setLoading(false);
  };

  const getTitle = async () => {
    const manualEntryTitle = await getMessageFromCode(errorMsgs.errorCode26102);
    setTitle(manualEntryTitle[0]?.descr);
    const manualEntrySubTitle = await getMessageFromCode(
      errorMsgs.errorCode26103
    );
    setSubTitle(manualEntrySubTitle[0]?.descr);
  };

  useEffect(() => {
    getTitle();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    validateFile(file);
  };

  const validateFile = async (file) => {
    if (!file) return;
    const validFormats = ["text/plain", "text/csv"];
    if (!validFormats.includes(file?.type)) {
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode26044
      );
      setErrorMessage(errorMsg);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      validateContent(content);
    };
    reader.readAsText(file);
  };

  const validateContent = async (content) => {
    const validDelimiters = ["\r", "\n", "\r\n"];
    const containsValidDelimiter = validDelimiters.some((delimiter) =>
      content.includes(delimiter)
    );
    if (!containsValidDelimiter) {
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode26044
      );
      setErrorMessage(errorMsg);
    } else {
      let volserArray = content.split(/\r\n|\r|\n/).filter(Boolean);
      const volserList = volserArray.reduce(
        (acc, next) => acc + next.toString().padEnd(20, " "),
        VALUE_EMPTY_STRING
      );
      await validateAndAdd(volserList);
      fileRef.current.value = VALUE_EMPTY_STRING;
      let duplicates = getDuplicates(volserArray);
      if (duplicates.length) {
        setErrorType(severity.warning);
        let duplicatesString = duplicates.map(
          (dup) =>
            `${dup} - ${t(
              "disasterRecovery.eventDetail.manualEntry.duplicate"
            )}`
        );
        setErrorMessage(
          `${t("disasterRecovery.eventDetail.manualEntry.recordsNotImported")}
            ${duplicatesString}
        `
        );
      } else {
        setErrorType(severity.error);
      }
    }
  };

  const validateAndAdd = async (list) => {
    try {
      setErrorMessage("");
      setLoading(true);
      const reqbody = {
        main_district_id: districtId,
        customer_id: customerLocations[0]?.value,
        volser_list: list,
        volsers_in_mdr_list: VALUE_EMPTY_STRING
      };
      let response = await getResponseData(
        reqbody,
        CF_URLS.disasterRecovery.manualEntry.validateOpenMedia,
        2
      );
      let data = response.data;

      let mdrList =
        data[1][0]?.volsers_in_mdr_list || data[0][0]?.volsers_in_mdr_list;
      if (mdrList) {
        let { errorMsg } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode64032
        );
        setErrorMessage(
          `${t(
            "disasterRecovery.eventDetail.manualEntry.destroyedMediaHeading"
          )}\n${mdrList?.replaceAll(",", ", ")} - ${errorMsg}`
        );
        setOpenPossibleMatches(VALUE_EMPTY_STRING);
        if (data[0][0]?.volsers_in_mdr_list) {
          setLoading(false);
          return;
        }
      } else if (data[1] && data[1][0]?.error && data[1][0]?.error !== zero) {
        handleError(data[1][0]?.error);
        setOpenPossibleMatches(VALUE_EMPTY_STRING);
        setLoading(false);
        return;
      }
      if (data[0].length && data[0][0]?.volser) {
        setLoading(false);
        let omList = [...openMediaData, ...response.data[0]].filter(
          (om) => om.volser?.trim() !== openPossibleMatches?.trim()
        );

        const seen = new Set();
        const uniqueVolserArray = omList.filter((item) => {
          if (seen.has(item.volser)) {
            return false;
          } else {
            seen.add(item.volser);
            return true;
          }
        });

        let uniqueArrayWithDateFormat = [];

        uniqueVolserArray.forEach(async (om) => {
          if (om.follow_up_date) {
            let formatedDate = await getFormatedDate(om.follow_up_date);
            uniqueArrayWithDateFormat.push({
              ...om,
              follow_up_date: formatedDate
            });
          } else {
            uniqueArrayWithDateFormat.push(om);
          }
        });
        setOpenMediaData(
          uniqueArrayWithDateFormat.map((om) => ({ ...om, selected: true }))
        );
        RequestForm.setFieldValue("openMedia", uniqueArrayWithDateFormat);
        setOpenPossibleMatches(VALUE_EMPTY_STRING);
      } else {
        handleError(errorMsgs.errorCode10015);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoading(false);
    }
  };
  const addOmFromPossibleMatches = async (list) => {
    let omList = list
      .map((om) => om.volser.toString().padEnd(20, " "))
      .join(VALUE_EMPTY_STRING);
    await validateAndAdd(omList);
  };

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    pagination: true,
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    },
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            onClick={() => setOpenAddMedia(true)}
          >
            {t("common.new")}
          </Button>
          <input
            type="file"
            accept=".txt,.csv"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-input"
            ref={fileRef}
          />
          <label htmlFor="file-input">
            <Button variant="outlined" size="medium" component="span">
              {t("disasterRecovery.eventDetail.import")}
            </Button>
          </label>
        </Grid>
      </>
    )
  };

  const optionsContainer = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    pagination: true,
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    },
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            onClick={() => setOpenAddContainer(true)}
          >
            {t("common.new")}
          </Button>
        </Grid>
      </>
    )
  };

  const handleOMDelete = (rowIndex, rowData) => {
    let omNumber = rowData[1]?.trim();
    const updatedData = openMediaData.filter(
      (om) => om.volser?.trim() !== omNumber
    );
    setOpenMediaData(updatedData);
    RequestForm.setFieldValue("openMedia", updatedData);
  };

  const handleOmEdit = (rowIndex, rowData) => {
    setIsUpdateMode(true);
    setSelectedVolser(rowData[1]?.trim());
    setOpenAddMedia(true);
  };

  const handleContainerEdit = (rowIndex, rowData) => {
    setIsUpdateMode(true);
    setSelectedContainer(rowData[1]?.trim());
    setOpenAddContainer(true);
  };

  const handleContainerDelete = (rowIndex, rowData) => {
    let containerNumber = rowData[1]?.trim();
    const updatedData = containerData.filter(
      (c) => c.number?.trim() !== containerNumber
    );
    setContainerData(updatedData);
    RequestForm.setFieldValue("container", updatedData);
  };

  const getFormatedDate = async (date) => {
    return await formatDateByLocale(
      date,
      localeByBranch || DEFAULT_LOCALE,
      false
    );
  };

  const columnOpenMedia = [
    {
      name: "selected",
      label: t("disasterRecovery.eventDetail.manualEntry.selected"),
      options: {
        filter: true,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = tableMeta.rowData;
          return (
            <Box>
              <IconButton
                color="primary"
                onClick={() => handleOmEdit(rowIndex, rowData)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => handleOMDelete(rowIndex, rowData)}
              >
                <SvgIcon component={DeleteIcon} />
              </IconButton>
            </Box>
          );
        },
        customHeadLabelRender: () => null
      }
    },
    {
      name: "volser",
      label: t("disasterRecovery.eventDetail.manualEntry.openMedia")
    },
    {
      name: "om_status",
      label: t("disasterRecovery.eventDetail.manualEntry.status")
    },
    {
      name: "follow_up",
      label: "Follow-Up",
      options: {
        customBodyRender: (value, tableData) => {
          let selectedOM = tableData?.rowData?.[1];
          let followUpDate =
            openMediaData.find((om) => om.volser === selectedOM)
              ?.follow_up_date || "";
          return value === possibleMatchesCompare ? (
            <Button
              variant="text"
              sx={{ textTransform: "capitalize" }}
              onClick={() => setOpenPossibleMatches(selectedOM)}
            >
              {t("disasterRecovery.eventDetail.manualEntry.passibleMatches")}
            </Button>
          ) : (
            value + " " + followUpDate
          );
        }
      }
    },
    {
      name: "short_descr",
      label: t("disasterRecovery.eventDetail.manualEntry.mediaType")
    },
    {
      name: "drp_code",
      label: t("disasterRecovery.eventDetail.manualEntry.drpCode")
    },
    {
      name: "om_descr",
      label: t("disasterRecovery.eventDetail.manualEntry.description")
    }
  ];

  const columnContainer = [
    {
      name: "selected",
      label: t("disasterRecovery.eventDetail.manualEntry.selected"),
      options: {
        filter: true,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = tableMeta.rowData;
          return (
            <Box>
              <IconButton
                color="primary"
                onClick={() => handleContainerEdit(rowIndex, rowData)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => handleContainerDelete(rowIndex, rowData)}
              >
                <SvgIcon component={DeleteIcon} />
              </IconButton>
            </Box>
          );
        },
        customHeadLabelRender: () => null
      }
    },
    {
      name: "number",
      label: t("disasterRecovery.eventDetail.manualEntry.containerNumber")
    },
    {
      name: "exchange_status_type",
      label: t("disasterRecovery.eventDetail.manualEntry.status")
    }
  ];

  const handelAddRequest = () => {
    addAllSelectedOpenMedia();
  };

  const addAllSelectedOpenMedia = () => {
    setAddOpenMedia((prev) =>
      getUniqueByProperty([...prev, ...openMediaData], volser)
    );

    setAddContainer((prev) =>
      getUniqueByProperty([...prev, ...containerData], containerNumber)
    );
    setOpenMediaData([]);
    RequestForm.setFieldValue("openMedia", []);
    setContainerData([]);
    RequestForm.setFieldValue("container", []);
  };

  const getUniqueByProperty = (array, prop) => {
    const seen = new Set();
    return array.filter((item) => {
      const trimmedNumber = item[prop]?.trim();
      if (seen.has(trimmedNumber)) {
        return false;
      } else {
        seen.add(trimmedNumber);
        return true;
      }
    });
  };

  const handelReviewAndGenerate = () => {
    addAllSelectedOpenMedia();
    RequestForm.setFieldValue("buildRequest", buildType.landingPage);
  };

  const hasRecords = !!openMediaData.length || !!containerData.length;

  useEffect(() => {
    if (hasRecords) {
      RequestForm.setFieldValue("hasManualEntryDateToAdd", true);
    } else {
      RequestForm.setFieldValue("hasManualEntryDateToAdd", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMediaData, containerData]);
  return (
    <Box ml={spacing.gap}>
      {loading && <CircularLoaderFullPage loading={loading} />}
      <Box mb={spacing.gap10}>
        {!!errorMessage && (
          <Collapse in={!!errorMessage}>
            <Alert
              severity={errorType}
              icon={
                <img
                  src={ErrorIcon}
                  alt="error"
                  style={disasterRecoveryStyles.commonStyles.errorIcon}
                />
              }
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorMessage(VALUE_EMPTY_STRING);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage.split("\n").map((errorText) => (
                <p key={errorText}>{errorText}</p>
              ))}
            </Alert>
          </Collapse>
        )}
      </Box>
      <Stack
        gap={spacing.gap10}
        sx={disasterRecoveryStyles.requestGeneratorStyles.rightPanelWrapper}
      >
        <Typography
          sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
        >
          {t("disasterRecovery.eventDetail.manualEntryImport")}
        </Typography>
        <Typography
          mb={spacing.gap10}
          sx={disasterRecoveryStyles.requestGeneratorStyles.subTitle}
        >
          {title}
          <p>{subTitle}</p>
        </Typography>
      </Stack>
      <Box my={spacing.gap}>
        <Divider />
      </Box>
      <Stack gap={spacing.gap}>
        <Typography
          sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
          mb={spacing.gap10}
        >
          {t("disasterRecovery.eventDetail.rgOpenMedia")}
        </Typography>
        <MUIDataTable
          options={options}
          columns={columnOpenMedia}
          data={openMediaData.sort((a, b) => a.volser.localeCompare(b.volser))}
          title={
            <Typography>
              {openMediaData.length +
                " " +
                t("disasterRecovery.eventDetail.rgOpenMedia")}
            </Typography>
          }
        />
        <Typography
          sx={disasterRecoveryStyles.requestGeneratorStyles.subHeading}
          mb={spacing.gap10}
        >
          {t("disasterRecovery.eventDetail.containers")}
        </Typography>
        <MUIDataTable
          options={optionsContainer}
          columns={columnContainer}
          data={containerData}
          title={
            <Typography>
              {containerData.length +
                " " +
                t("disasterRecovery.eventDetail.containers")}
            </Typography>
          }
        />
      </Stack>

      <Box
        sx={disasterRecoveryStyles.tableStyles.displayFlex}
        gap={spacing.gap}
        mt={spacing.gap}
      >
        <>
          <Button
            variant="outlined"
            id="add-request"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            disabled={!hasRecords}
            onClick={handelAddRequest}
          >
            {t("disasterRecovery.eventDetail.addToRequest")}
          </Button>
          <Button
            variant="contained"
            id="review-generate-request"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            onClick={handelReviewAndGenerate}
          >
            {t("disasterRecovery.eventDetail.reviewGenerateRequest")}
          </Button>
        </>
      </Box>

      {openAddMedia ? (
        <AddMedia
          openAddMedia={openAddMedia}
          onClose={() => setOpenAddMedia(false)}
          openMediaData={openMediaData}
          setOpenMediaData={setOpenMediaData}
          value={falseValue}
          customerLocations={customerLocations}
          handleError={handleError}
          isUpdateMode={isUpdateMode}
          setIsUpdateMode={setIsUpdateMode}
          selectedVolser={selectedVolser}
          RequestForm={RequestForm}
          getFormatedDate={getFormatedDate}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {openAddContainer ? (
        <AddContainer
          open={openAddContainer}
          onClose={() => setOpenAddContainer(false)}
          value={falseValue}
          customerLocations={customerLocations}
          setContainerData={setContainerData}
          containerData={containerData}
          handleError={handleError}
          setLoading={setLoading}
          setErrorMessage={setErrorMessage}
          isUpdateMode={isUpdateMode}
          setIsUpdateMode={setIsUpdateMode}
          selectedContainer={selectedContainer}
          RequestForm={RequestForm}
        />
      ) : null}

      {openPossibleMatches ? (
        <PossibleMatchesModal
          open={!!openPossibleMatches}
          onClose={() => setOpenPossibleMatches(VALUE_EMPTY_STRING)}
          customer={customerLocations[0]}
          openMediaData={openMediaData}
          selectedOM={openPossibleMatches}
          addOmFromPossibleMatches={addOmFromPossibleMatches}
          setLoading={setLoading}
        />
      ) : null}
    </Box>
  );
};
export default ManualEntry;
