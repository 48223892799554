import { Alert, Box, Button, IconButton, TextField } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import spacing from "styles/spacing";
import { ERROR_TYPES, errorMsgs, VALUE_EMPTY_STRING } from "utils/constants";
import { zero } from "utils/constants/disaster-module/InboundDateConst";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getTheFirestoreErrorMessage
} from "utils/helpers";

const {
  default: CommonModal
} = require("components/shared/common-modal/CommonModal");

const AddMedia = ({
  openAddMedia,
  onClose,
  openMediaData,
  setOpenMediaData,
  customerLocations,
  handleError,
  selectedVolser,
  isUpdateMode,
  setIsUpdateMode,
  RequestForm,
  getFormatedDate,
  setErrorMessage
}) => {
  const [media, setMedia] = useState(VALUE_EMPTY_STRING);
  const [loading, setLoading] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const districtId = getAuthenticatedUserBranch();

  useEffect(() => {
    if (isUpdateMode && selectedVolser) {
      setMedia(selectedVolser);
    } else {
      setMedia(VALUE_EMPTY_STRING);
    }
  }, [isUpdateMode, selectedVolser]);

  const checkForDuplicate = (volser) => {
    return !!openMediaData.find((c) => c.volser?.trim() === volser?.trim())
      ?.volser;
  };

  const AddOpenMedia = async () => {
    try {
      setErrorMessage("");
      let hasDuplicate = checkForDuplicate(media);
      if (hasDuplicate) {
        let { errorMsg } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode20382
        );
        setThrowError(true);
        setThrowErrorMessage(
          errorMsg.replace(
            "|",
            t("disasterRecovery.eventDetail.manualEntry.openMedia")
          )
        );
        return;
      }
      setLoading(true);
      let volserList = media
        .split(",")
        .reduce(
          (acc, next) => acc + next.toString().padEnd(20, " "),
          VALUE_EMPTY_STRING
        );
      const reqbody = {
        main_district_id: districtId,
        customer_id: customerLocations[0]?.value,
        volser_list: volserList,
        volsers_in_mdr_list: VALUE_EMPTY_STRING
      };

      let response = await getResponseData(
        reqbody,
        `${CF_URLS?.disasterRecovery.manualEntry.validateOpenMedia}`,
        2
      );
      let data = response.data;

      let mdrList =
        data[1][0]?.volsers_in_mdr_list || data[0][0]?.volsers_in_mdr_list;
      if (mdrList) {
        let { errorMsg } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode64032
        );
        setErrorMessage(
          `${t(
            "disasterRecovery.eventDetail.manualEntry.destroyedMediaHeading"
          )}\n${mdrList?.replaceAll(",", ", ")} - ${errorMsg}`
        );
        if (data[0][0]?.volsers_in_mdr_list) {
          setLoading(false);
          onClose();
          return;
        }
      } else if (data[1] && data[1][0]?.error && data[1][0]?.error !== zero) {
        handleError(data[1][0]?.error);
        onClose();
        setLoading(false);
        return;
      }
      if (data[0].length && data[0][0]?.volser) {
        setLoading(false);
        if (isUpdateMode) {
          let records = openMediaData.map((om) =>
            om?.volser?.trim() === selectedVolser
              ? {
                  ...response.data[0][0],
                  selected: true
                }
              : om
          );
          setOpenMediaData(records);
          RequestForm.setFieldValue("openMedia", records);

          setIsUpdateMode(false);
        } else {
          const seen = new Set();
          const uniqueVolserArray = [
            ...openMediaData,
            ...response.data[0].map((om) => ({ ...om, selected: true }))
          ].filter((item) => {
            if (seen.has(item.volser)) {
              return false;
            } else {
              seen.add(item.volser);
              return true;
            }
          });
          let uniqueArrayWithDateFormat = [];

          for (const om of uniqueVolserArray) {
            if (om.follow_up_date) {
              let formatedDate = await getFormatedDate(om.follow_up_date);
              uniqueArrayWithDateFormat.push({
                ...om,
                follow_up_date: formatedDate
              });
            } else {
              uniqueArrayWithDateFormat.push(om);
            }
          }
          setOpenMediaData(
            uniqueArrayWithDateFormat.map((om) => ({ ...om, selected: true }))
          );

          RequestForm.setFieldValue("openMedia", uniqueArrayWithDateFormat);

          setMedia(VALUE_EMPTY_STRING);
        }
        onClose();
      } else {
        handleError(errorMsgs.errorCode10015);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const handleMediaChange = (val) => {
    const segments = val.split(",");
    const validSegments = segments.map((segment) => segment.slice(0, 20));
    const newValue = validSegments.join(",");
    setMedia(newValue);
  };

  const handlePaste = (event) => {
    const pasteText = event.clipboardData.getData("text");
    const segments = (media + pasteText).split(",");
    const validSegments = segments.map((segment) => segment.slice(0, 20));
    const newValue = validSegments.join(",");
    setMedia(newValue);
    event.preventDefault();
  };

  return (
    <CommonModal
      open={openAddMedia}
      title={
        isUpdateMode
          ? t("disasterRecovery.eventDetail.manualEntry.updateMedia")
          : t("disasterRecovery.eventDetail.manualEntry.newMedia")
      }
      body={
        <>
          {loading && <CircularLoaderFullPage loading={loading} />}
          {throwError && (
            <Box mb={spacing.gap}>
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <GridCloseIcon />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Box>
          )}

          <TextField
            label={t("disasterRecovery.eventDetail.manualEntry.openMedia")}
            variant="outlined"
            fullWidth
            value={media}
            onChange={(e) => handleMediaChange(e.target.value)}
            onPaste={handlePaste}
          />
        </>
      }
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button variant="contained" disabled={!media} onClick={AddOpenMedia}>
            {isUpdateMode
              ? t("common.update")
              : t("disasterRecovery.eventDetail.manualEntry.validateAndAdd")}
          </Button>
        </>
      }
    />
  );
};
export default AddMedia;
