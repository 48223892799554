export const IssuranceStyles = {
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  printerInfo: {
    fontSize: "12px",
    color: "var(--color-im-grey-400)"
  },
  direction: {
    column: "column",
    row: "row"
  },
  gap: {
    gap: "20px"
  },
  fieldSize: {
    minWidth: "310px"
  },
  textBoarderHide: {
    border: "none"
  }
};
