import { Button } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { ConfrimPopupStyles } from "components/shared/confirm-popup/ConfirmPopupStyles";
import { t } from "i18next";

const CustomerServiceInformationUnsavedChangesModal = ({
  showUnsavedChangesModal,
  onCancelClickInUnsavedChanges,
  handleOnSave,
  onNoClickInUnsavedChanges
}) => {
  return (
    <CommonModal
      open={showUnsavedChangesModal}
      title={t("common.confirm")}
      body={t("common.saveChangesNow")}
      minWidth={ConfrimPopupStyles.dialogModal}
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={() => onCancelClickInUnsavedChanges()}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => onNoClickInUnsavedChanges()}
          >
            {t("common.no")}
          </Button>
          <Button variant="contained" onClick={() => handleOnSave()}>
            {t("common.yes")}
          </Button>
        </>
      }
    />
  );
};

export default CustomerServiceInformationUnsavedChangesModal;
