/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Stack } from "@mui/material";
import BillCycleField from "components/core/bill-cycle-field/BillCycleField";
import { BillDateField } from "components/core/bill-date-field/BillDateField";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { dates } from "utils/constants/biling-module";

const BillingSummarySearch = ({
  handleFind,
  searchPanelForm,
  findEnable,
  handleReset,
  allLocations
}) => {
  const { t } = useTranslation();
  //min date state
  const minDate = dayjs(dates.minDate);
  //today date state
  const today = dayjs();
  //max date state
  const maxDate = dayjs(dates.maxDate);
  //handle clear button
  const handleClearButton = () => {
    searchPanelForm?.resetForm();
    handleReset();
  };

  //handle function for bill date change
  const handleBillDateChange = (newValue) => {
    searchPanelForm?.setFieldValue("billDateEom", newValue);
    handleReset();
  };

  //handle function for date picker change
  const handleDatePickerChange = (newValue) => {
    searchPanelForm?.setFieldValue("billDateOto", newValue);
    handleReset();
  };

  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        gap={spacing.verticalMargin20}
      >
        <Grid container spacing={spacing.verticalMargin20}>
          {/*Bill cycle common component*/}
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <BillCycleField
              selectedValue={searchPanelForm?.values?.billCycle}
              handleOnChange={(event) => {
                searchPanelForm?.setFieldValue("billCycle", event.target.value);
                handleReset();
              }}
              disabledField={false}
              filterParams={["1", "3"]}
            />
          </Grid>
          {/*Bill date common component*/}
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <BillDateField
              billCycle={searchPanelForm?.values?.billCycle}
              minDate={minDate}
              maxDate={maxDate}
              today={today}
              form={searchPanelForm}
              defaultValue={today}
              handleDatePickerChange={handleDatePickerChange}
              handleBillDateChange={handleBillDateChange}
              value={searchPanelForm?.values?.billDateOto}
              allLocations={allLocations}
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={spacing.verticalMargin20}>
          {/*Clear button*/}

          <Button
            id="search-panel-clear-button"
            variant="outlined"
            type="reset"
            onClick={handleClearButton}
          >
            {t("enterpriseBillingSummary.clearButton")}
          </Button>

          {/*Find Button*/}

          <Button
            id="search-find-button"
            variant="contained"
            type="submit"
            onClick={handleFind}
            disabled={!findEnable || !searchPanelForm.values?.billCycle}
          >
            {t("enterpriseBillingSummary.findButton")}
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default BillingSummarySearch;
