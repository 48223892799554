import { Stack } from "@mui/system";
import { CustomerBillingInformationScreen } from "components/billing-module";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const CustomerBillingInformation = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("menu.customerBillingInformation")} />
      {/* Add your compoenent here */}
      <CustomerBillingInformationScreen />
    </Stack>
  );
};

export default CustomerBillingInformation;
