import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ArrowDownDisabled } from "assets/images/ArrowDownDisabled.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CustomersField } from "components/core/customer-field/CustomersField";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useTranslation } from "react-i18next";
import { ERROR_TYPES, VALUE_EMPTY_STRING, Y_CHECKER } from "utils/constants";
import { descriptionTFInputProps } from "utils/constants/biling-module/BillingRecurringChargeConstants";

import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  billCycleInvoiceId,
  defaultUnitPrice,
  messageCodes,
  transactionTypes,
  unitPriceMaxBase
} from "utils/constants/biling-module/BillingTransactionsConstants";
import {
  decimalSymbolOfNumber,
  findErrorMessage,
  formatNumber,
  isValidUnitPriceNumInput,
  numberFilter
} from "utils/helpers";
import { billingTransactionsStyles } from "./BillingTransactionsStyles";

const BillingTransactionsModal = ({
  customers,
  selectedRow,
  isUpdating,
  isCreating,
  // findCatalogItemByCode,
  handleCancelClick,
  servicesList,
  selectedService,
  catalogItemsListNew,
  customerSelectedNew,
  setCustomerSelectedNew,
  selectedServiceNew,
  setSelectedServiceNew,
  newClassificationList,
  handleSaveClickOnModal,
  setSelectedCatalogItemNew,
  selectedCatalogItemNew,
  selectedClassificationNew,
  setSelectedClassificationNew,
  quantity,
  setQuantity,
  descriptionSection,
  setDescriptionSection,
  throwErrorModal,
  setThrowErrorModal,
  throwErrorMessageModal,
  setThrowErrorMessageModal,
  showWarningModal,
  setShowWarningModal,
  warningMessageModal,
  setWarningMessageModal,
  getCatalogItems,
  selectedBillCycle,

  modalElementVisible,
  setModalElementVisible,
  modalElementEnable,
  setModalElementEnable,
  newlyCreated,
  unitPrice,
  setUnitPrice,
  valuePO,
  setValuePO,
  printAdj,
  setPrintAdj,
  initialElementValues,
  modalElementValue,
  setModalElementValue,
  elementValues,
  sumOfAdj,
  isOneYAdj,
  unitPrincePrecision
}) => {
  const { t } = useTranslation();
  const { localeByBranch } = useSelector(selectAuth);

  const [checkedModalStatus, setCheckedModalStatus] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  const [msg20554, setmsg20554] = useState(VALUE_EMPTY_STRING);
  const [approved, setApproved] = useState(false);

  const [localUnitPrice, setLocalUnitPrice] = useState(
    formatNumber(defaultUnitPrice, unitPrincePrecision)
  );

  useEffect(() => {
    setLocalUnitPrice(
      formatNumber(
        modalElementValue?.unitPrice,
        unitPrincePrecision,
        !isCreating?.new && selectedRow?.unit_price
      ) || formatNumber(defaultUnitPrice, unitPrincePrecision)
    );
    // eslint-disable-next-line
  }, [modalElementValue?.unitPrice]);

  const handleUnitPriceChange = (event) => {
    const input = event.target.value;
    if (isValidUnitPriceNumInput(input, unitPrincePrecision)) {
      setLocalUnitPrice(input);
    }

    if (input) {
      const number = parseFloat(input);

      const maxBase = unitPriceMaxBase;
      const maxLimit = parseFloat(
        `${maxBase}.${"9".repeat(Number(unitPrincePrecision))}`
      );

      if (number > maxLimit) {
        setIsSaveEnabled(false);
      } else {
        setIsSaveEnabled(true);
      }
    }
  };

  const handleUnitPriceBlur = () => {
    if (localUnitPrice !== VALUE_EMPTY_STRING) {
      const formattedNumber =
        parseFloat(localUnitPrice).toFixed(unitPrincePrecision);
      setUnitPrice(formattedNumber);
    }
  };

  useEffect(() => {
    if (checkedModalStatus) {
      setModalElementValue(elementValues);

      /**
       * Description mandatory  for all billing cycle.
       * quantity mandatory for OTO and Hold OTO.
       */

      if (
        Number(selectedBillCycle?.value) === billCycleInvoiceId.oto ||
        Number(selectedBillCycle?.value) === billCycleInvoiceId.hOto
      ) {
        if (
          quantity !== VALUE_EMPTY_STRING &&
          descriptionSection !== VALUE_EMPTY_STRING
        ) {
          setIsSaveEnabled(true);
        } else {
          setIsSaveEnabled(false);
        }
      }

      if (Number(selectedBillCycle?.value) === billCycleInvoiceId.eom) {
        if (descriptionSection === VALUE_EMPTY_STRING) {
          setIsSaveEnabled(false);
        } else {
          setIsSaveEnabled(true);
        }
      }

      if (isCreating?.new && selectedClassificationNew === VALUE_EMPTY_STRING) {
        setModalElementEnable((prev) => {
          let latest = { ...prev, catalogItem: false, description: false };
          return latest;
        });
      }

      if (isCreating?.adjust) {
        /**
         * Get parent quantity.
         * Calculate sum of adjusted quantity including current adjust
         * If parent Quantity - Sum of adjusted quantity < 0
         * Display message. 20554
         */

        const totalAdjQuantity = sumOfAdj
          ? Number(quantity) + Number(sumOfAdj)
          : 0;

        const parentQuantity = selectedRow?.current_quantity?.trim();

        if (
          sumOfAdj &&
          quantity?.length > 0 &&
          Number(parentQuantity) - -Number(totalAdjQuantity) < 0
        ) {
          setIsSaveEnabled(false);
          setThrowErrorMessage(
            msg20554[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);

          /**
           * If there are multiple adjust records are available for same parent only one record can be PrintAdj =”Y”
           * Display message 26052
           */
        } else if (isOneYAdj && printAdj) {
          setIsSaveEnabled(false);
          setThrowErrorMessage(
            t("billingTransactions.printAdjError") ||
              findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          setThrowErrorMessage(VALUE_EMPTY_STRING);
          setThrowError(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    selectedServiceNew,
    customerSelectedNew,
    selectedClassificationNew,
    selectedCatalogItemNew,
    quantity,
    descriptionSection,
    unitPrice,
    valuePO,
    printAdj,
    selectedRow,
    checkedModalStatus,
    sumOfAdj,
    isOneYAdj
  ]);

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    const value20554 = await getMessageFromCode(messageCodes?.value20554);
    setmsg20554(value20554);
  };

  // values for elements
  useEffect(() => {
    setCheckedModalStatus(false);

    if (isUpdating && selectedRow.current_quantity !== VALUE_EMPTY_STRING) {
      setModalElementEnable((prev) => {
        let latest = { ...prev, quantity: false };
        return latest;
      });
    }

    // setModalElementValue((prev) => initialValues);

    if (isCreating?.new) {
      setUnitPrice(formatNumber(defaultUnitPrice, unitPrincePrecision));
      setValuePO(VALUE_EMPTY_STRING);
      setPrintAdj(false);
      setCheckedModalStatus(true);
    }

    if (isCreating?.adjust) {
      setCustomerSelectedNew((prev) => selectedRow?.customer_number);
      setSelectedCatalogItemNew((prev) => selectedRow?.catalog_item_code);

      setPrintAdj((prev) => false);

      setCheckedModalStatus(true);
    }

    if (isCreating?.repeat) {
      setCustomerSelectedNew((prev) => selectedRow?.customer_number);
      setDescriptionSection((prev) => newlyCreated?.bill_trans_descr);
      setUnitPrice((prev) => selectedRow?.unit_price);

      setCheckedModalStatus(true);
    }

    if (isUpdating) {
      setCustomerSelectedNew((prev) => selectedRow?.customer_number);
      setSelectedCatalogItemNew((prev) => selectedRow?.catalog_item_code);

      setQuantity((prev) => selectedRow?.current_quantity);
      setDescriptionSection((prev) => selectedRow?.bill_trans_descr);

      setUnitPrice((prev) => selectedRow?.unit_price);
      setValuePO((prev) => selectedRow?.po_number);

      setPrintAdj(
        (prev) => selectedRow?.print_adjustments_flag === Y_CHECKER || false
      );

      setCheckedModalStatus(true);
    }

    // eslint-disable-next-line
  }, [
    isCreating?.new,
    isCreating?.adjust,
    isCreating?.repeat,
    isUpdating,
    selectedRow
  ]);

  const resetValues = () => {
    setSelectedServiceNew(VALUE_EMPTY_STRING);
    setCustomerSelectedNew(VALUE_EMPTY_STRING);
    setSelectedClassificationNew(VALUE_EMPTY_STRING);
    setSelectedCatalogItemNew(VALUE_EMPTY_STRING);
    setQuantity(VALUE_EMPTY_STRING);
    setDescriptionSection(VALUE_EMPTY_STRING);
    setUnitPrice(formatNumber(defaultUnitPrice, unitPrincePrecision));
    setValuePO(VALUE_EMPTY_STRING);
    setPrintAdj(false);
  };

  const handleYes = () => {
    setApproved(true);
    setWarningMessageModal((prev) => VALUE_EMPTY_STRING);
    setShowWarningModal((prev) => false);
  };

  const handleCustomerChange = (event, newValue) => {
    if (newValue === null) {
      setCustomerSelectedNew(modalElementValue?.customer);
    } else {
      setSelectedClassificationNew(VALUE_EMPTY_STRING);
      setSelectedCatalogItemNew(VALUE_EMPTY_STRING);
      setDescriptionSection(VALUE_EMPTY_STRING);

      setCustomerSelectedNew((prev) => newValue);
      setModalElementEnable((prev) => {
        let latest = { ...prev, classification: true };
        return latest;
      });
    }
  };

  // quantity field keyUp event handler
  const handleQuantityOnKeyUp = (event, decimalPointPrecision) => {
    const decimalSeparator = decimalSymbolOfNumber(localeByBranch);
    const charCode = event.which || event.keyCode;

    // allow integer or decimal or backspace
    if (numberFilter(event.key, localeByBranch) && event.keyCode !== 13) {
      if (
        event.target.value.length > 0 &&
        charCode === decimalSeparator.charCodeAt(0) &&
        event.target.value.indexOf(event.key) >= 0
      ) {
        return event.preventDefault();
      }

      if (event.target.value.length > 0) {
        const res = event.target.value.split(decimalSeparator);
        if (
          event.keyCode !== 8 &&
          event.keyCode !== 13 &&
          res.length > 1 &&
          res[1].length >= Number(decimalPointPrecision)
        ) {
          return event.preventDefault();
        }
      }
    } // if TransactionType is Adj and key is minus
    else if (
      charCode === 45 &&
      selectedRow?.transaction_type_code?.trm() === "Adj"
    ) {
      // multiple negetive
      if (
        event.target.value.length > 0 &&
        charCode === 45 &&
        event.target.value.indexOf(event.key) > -1
      ) {
        return event.preventDefault();
      }
    } else if (event.keyCode !== 13) {
      return event.preventDefault();
    }
  };

  // quantity field change event handler
  const handleQuantityOnChange = (event) => {
    if (event.target?.value) {
      setQuantity((prevState) => event.target.value);
    } else {
      setQuantity((prevState) => VALUE_EMPTY_STRING);
    }
  };

  const customersFieldProps = {
    options: customers,
    value: modalElementValue?.customer,
    disabled: !modalElementEnable?.customer,
    handleOnChange: handleCustomerChange,
    required: true,
    sx: billingTransactionsStyles.commonStyles.widthFixed,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  return (
    <>
      {showWarningModal && (
        <ConfirmPopup
          modalPopupOpen={showWarningModal}
          handleCancel={() => {
            setWarningMessageModal((prev) => VALUE_EMPTY_STRING);
            setShowWarningModal((prev) => false);
            setApproved(false);

            isUpdating && setDescriptionSection(selectedRow?.bill_trans_descr);

            isCreating?.new &&
              setDescriptionSection(selectedCatalogItemNew?.label);
            isCreating?.repeat &&
              setDescriptionSection(newlyCreated?.bill_trans_descr);
          }}
          showCancel={true}
          showNo={false}
          handleYes={handleYes}
          message={warningMessageModal}
        />
      )}

      <CommonModal
        open={
          isCreating?.new ||
          isCreating?.adjust ||
          isCreating?.repeat ||
          isUpdating
        }
        title={
          (isCreating?.new && t("billingTransactions.createNewTitle")) ||
          (isCreating?.adjust && t("billingTransactions.adjustTitle")) ||
          (isCreating?.repeat && t("billingTransactions.repeatTitle")) ||
          (isUpdating && t("billingTransactions.editTitle"))
        }
        body={
          <Box
            gap={spacing.gap}
            sx={
              billingTransactionsStyles.formCreateAndUpdateChargeStyles
                .cardContentStack
            }
          >
            {/* Error Alert */}
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Grid container spacing={2}>
              {/* ---------- Service Selection ----------  */}
              {modalElementVisible?.service && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="service-modal-filter"
                    name={t("billingTransactions.service")}
                    disabled={!modalElementEnable?.service}
                    options={servicesList}
                    size="medium"
                    value={modalElementValue?.service}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("billingTransactions.serviceModal")}
                      />
                    )}
                    onChange={(e, newValue) => {
                      setSelectedServiceNew(
                        newValue || modalElementValue?.service
                      );

                      setCustomerSelectedNew(VALUE_EMPTY_STRING);
                      setSelectedClassificationNew(VALUE_EMPTY_STRING);
                      setSelectedCatalogItemNew(VALUE_EMPTY_STRING);
                      setQuantity(VALUE_EMPTY_STRING);
                      setDescriptionSection(VALUE_EMPTY_STRING);
                    }}
                    sx={billingTransactionsStyles.commonStyles.widthFixed}
                  />
                </Grid>
              )}

              {/* ---------- Customer selection ----------   */}
              {modalElementVisible?.customer && (
                <Grid item xs={12} sm={6}>
                  <CustomersField {...customersFieldProps} />
                </Grid>
              )}

              {/* ---------- Classification Selection ----------  */}
              {modalElementVisible?.classification && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="classification-modal-filter"
                    disabled={!modalElementEnable?.classification}
                    name={t("billingTransactions.classification")}
                    options={newClassificationList}
                    size="medium"
                    value={modalElementValue?.classification}
                    fullWidth
                    onChange={(event, newValue) => {
                      setSelectedClassificationNew(
                        (prev) => newValue || VALUE_EMPTY_STRING
                      );

                      setSelectedCatalogItemNew(VALUE_EMPTY_STRING);
                      setDescriptionSection(VALUE_EMPTY_STRING);

                      if (newValue) {
                        setModalElementEnable((prev) => {
                          let latest = { ...prev, catalogItem: true };
                          return latest;
                        });
                      } else {
                        setModalElementEnable((prev) => {
                          let latest = { ...prev, catalogItem: false };
                          return latest;
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("billingTransactions.classificationModal")}
                      />
                    )}
                    sx={billingTransactionsStyles.commonStyles.widthFixed}
                  />
                </Grid>
              )}

              {/* ---------- Catalog item selection ----------   */}
              {modalElementVisible?.catalogItem && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    clearIcon={<CloseIcon />}
                    id="catalogItem-modal-list"
                    name={t("billingTransactions.catalogItem")}
                    options={catalogItemsListNew}
                    size="medium"
                    value={modalElementValue?.catalogItem}
                    fullWidth
                    disabled={!modalElementEnable?.catalogItem}
                    popupIcon={
                      isUpdating ||
                      (!isUpdating &&
                        selectedCatalogItemNew === VALUE_EMPTY_STRING) ? (
                        <ArrowDownDisabled />
                      ) : (
                        <ArrowDown />
                      )
                    }
                    onChange={(e, value) => {
                      setApproved(false);
                      if (value) {
                        setSelectedCatalogItemNew((prev) => value);
                        setDescriptionSection((prev) => value?.label);
                        isCreating?.new &&
                          setModalElementEnable((prev) => {
                            let latest = { ...prev, description: true };

                            return latest;
                          });
                      } else {
                        setSelectedCatalogItemNew(VALUE_EMPTY_STRING);
                        setDescriptionSection(VALUE_EMPTY_STRING);
                        isCreating?.new &&
                          setModalElementEnable((prev) => {
                            let latest = { ...prev, description: false };
                            return latest;
                          });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("billingTransactions.catalogItemModal")}
                      />
                    )}
                    sx={billingTransactionsStyles.commonStyles.widthFixed}
                  />
                </Grid>
              )}

              {/* ---------- Quantity selection ----------   */}
              {modalElementVisible?.quantity && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={
                      Number(selectedBillCycle?.value) ===
                        billCycleInvoiceId.oto ||
                      Number(selectedBillCycle?.value) ===
                        billCycleInvoiceId.hOto
                        ? t("billingTransactions.quantityMando")
                        : t("billingTransactions.quantity")
                    }
                    id="quantity-modal"
                    fullWidth
                    sx={billingTransactionsStyles.commonStyles.widthFixed}
                    disabled={!modalElementEnable?.quantity}
                    inputProps={{
                      maxLength: 11,
                      inputMode: "numeric"
                    }}
                    value={quantity}
                    onKeyDown={(e) =>
                      handleQuantityOnKeyUp(e, unitPrincePrecision)
                    }
                    onChange={handleQuantityOnChange}
                    InputLabelProps={
                      billingTransactionsStyles.formCreateAndUpdateChargeStyles
                        .tfInputLabelPtops
                    }
                  />
                </Grid>
              )}

              {/* ---------- Unit Price selection ----------   */}
              {modalElementVisible?.unitPrice && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("billingTransactions.unitPrice")}
                    id="unitPrice-modal"
                    fullWidth
                    sx={billingTransactionsStyles.commonStyles.widthFixed}
                    disabled={!modalElementEnable?.unitPrice}
                    value={localUnitPrice}
                    onBlur={handleUnitPriceBlur}
                    onChange={handleUnitPriceChange}
                    InputLabelProps={
                      billingTransactionsStyles.formCreateAndUpdateChargeStyles
                        .tfInputLabelPtops
                    }
                  />
                </Grid>
              )}
              {/* ---------- PO selection ----------   */}
              {modalElementVisible?.po && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("billingTransactions.po")}
                    id="po-modal"
                    fullWidth
                    sx={billingTransactionsStyles.commonStyles.widthFixed}
                    disabled={!modalElementEnable?.po}
                    value={valuePO}
                    onChange={(e, value) => {
                      setValuePO(e?.target?.value || VALUE_EMPTY_STRING);
                    }}
                    InputLabelProps={
                      billingTransactionsStyles.formCreateAndUpdateChargeStyles
                        .tfInputLabelPtops
                    }
                  />
                </Grid>
              )}

              {/* ---------- Description selection ----------   */}
              {modalElementVisible?.description && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("billingTransactions.description")}
                    id="description-modal"
                    fullWidth
                    multiline
                    sx={{
                      ...billingTransactionsStyles
                        .formCreateAndUpdateChargeStyles.textFieldSX,
                      ...billingTransactionsStyles.modal.description
                    }}
                    inputProps={descriptionTFInputProps}
                    disabled={!modalElementEnable?.description}
                    rows={2}
                    value={descriptionSection}
                    onChange={(e, value) => {
                      setDescriptionSection((prev) => e.target.value);
                    }}
                    onBlur={() => {
                      if (
                        selectedRow?.bill_trans_descr === descriptionSection
                      ) {
                        return;
                      } else if (
                        !isCreating?.adjust &&
                        selectedRow?.transaction_type_code?.trim() !==
                          transactionTypes.adj &&
                        selectedCatalogItemNew?.label !== descriptionSection
                      ) {
                        setWarningMessageModal(
                          t("billingTransactions.descriptionWarning")
                        );
                        if (!approved) {
                          setShowWarningModal(true);
                        }
                      }
                    }}
                    InputLabelProps={
                      billingTransactionsStyles.formCreateAndUpdateChargeStyles
                        .tfInputLabelPtops
                    }
                  />
                </Grid>
              )}

              {/* ---------- Print Adj selection ----------   */}
              {modalElementVisible?.printAdj && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  paddingTop={billingTransactionsStyles.printAdjPadding}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!modalElementEnable?.printAdj}
                          checked={modalElementValue?.printAdj}
                          onChange={(event) => {
                            setPrintAdj((prev) => !prev);
                          }}
                        />
                      }
                      label={t("billingTransactions.printAdj")}
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          </Box>
        }
        buttons={
          <>
            <Button
              id="CancelBtn-modal"
              variant="outlined"
              onClick={() => {
                resetValues();
                handleCancelClick();
              }}
              sx={
                billingTransactionsStyles.formCreateAndUpdateChargeStyles
                  .buttonSX
              }
            >
              {t("common.cancel")}
            </Button>

            {/* Description mandatory for all billing cycle. 
            quantity mandatory for OTO and Hold OTO. */}
            <Button
              id="saveBtn-modal"
              variant="contained"
              // Billing Cycle == EOM Invoice Id (1)
              disabled={!isSaveEnabled}
              onClick={handleSaveClickOnModal}
              sx={
                billingTransactionsStyles.formCreateAndUpdateChargeStyles
                  .buttonSX
              }
            >
              {t("common.save")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default BillingTransactionsModal;
