import DownloadPopup from "components/core/modal-components/DownloadPopup";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  VALUE_N,
  dateFormat,
  downloadReportOptions
} from "utils/constants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";

export default function DownloadReportModal({
  isVisible,
  setIsVisible,
  mainDistrictId,
  setIsLoading,
  formValues,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage,
  reportType,
  spFromDate,
  spToDate,
  spCustomer
}) {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const reqBody =
        reportType !== t("requestSearch.special")
          ? JSON.stringify({
              main_district_id: mainDistrictId,
              user_name: getUserName(auth?.user),
              report_format: selectedValue?.type?.toUpperCase(),
              service_date_from: dayjs(formValues.values.fromDate).format(
                dateFormat.universalFormat
              ),
              service_date_to: dayjs(formValues.values.toDate).format(
                dateFormat.universalFormat
              ),
              route_code:
                formValues.values.routeName !== 0
                  ? formValues.values.routeName?.toString().trimEnd()
                  : VALUE_EMPTY_STRING,
              request_type_id:
                formValues.values.requestType !== 0
                  ? formValues.values.requestType?.toString()
                  : VALUE_EMPTY_STRING,
              request_status_id:
                formValues.values.requestStatus !== 0
                  ? formValues.values.requestStatus?.toString()
                  : VALUE_EMPTY_STRING,
              customer_id:
                formValues.values.customer === t("requestSearch.all")
                  ? VALUE_EMPTY_STRING
                  : formValues.values.customer?.value?.toString()?.trim(),
              request_id: VALUE_EMPTY_STRING,
              not_completed_flag: VALUE_N,
              limit_row_count_flag: VALUE_N,
              customer_number:
                formValues.values.customer === t("requestSearch.all")
                  ? VALUE_EMPTY_STRING
                  : formValues.values.customer?.number?.toString()?.trim()
            })
          : JSON.stringify({
              main_district_id: mainDistrictId,
              user_name: getUserName(auth?.user),
              report_format: selectedValue?.type?.toUpperCase(),
              from_date: spFromDate,
              to_date: spToDate,
              customer_id:
                spCustomer === t("requestSearch.all") ||
                spCustomer.value === t("requestSearch.all")
                  ? 0
                  : spCustomer?.value?.toString()?.trim(),
              customer_number:
                spCustomer === t("requestSearch.all") ||
                spCustomer.value === t("requestSearch.all")
                  ? VALUE_EMPTY_STRING
                  : spCustomer?.number?.toString()?.trim()
            });
      let reportResponse = await printReport(
        reqBody,
        reportType !== t("requestSearch.special")
          ? `${CF_URLS.printReports.request.requestSearchReport}`
          : `${CF_URLS.printReports.request.requestSearchSpecialReport}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setThrowError(false);
    setIsVisible(false);
  };

  return (
    <DownloadPopup
      title={t("common.downloadReport")}
      showPrintModal={isVisible}
      downloadOptions={downloadReportOptions}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
    />
  );
}
