export const cardStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  width: "1000px !important"
};

export const popupTitle = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "32px",
  letterSpacing: "0.15px",
  color: "var(--color-paper)",
  display: "flex",
  padding: "16px 16px",
  alignItems: "center",
  alignSelf: "stretch",
  width: "552px"
};

export const cardContentStyles = {
  padding: "16px 16px"
};

export const cardActionStyles = {
  padding: "16px 16px"
};

export const customerGridStyles = {
  display: "flex",
  justifyContent: "flex-end"
};

export const customerButtonStyles = {
  textTransform: "none",
  textDecoration: "underline"
};

export const countTextStyle = {
  position: "absolute",
  right: "90px",
  fontWeight: "700",
  marginTop: "25px"
};

export const dividerStyle = {
  margin: "25px 0"
};

export const branchSectionstyle = {
  maxHeight: "300px",
  overflow: "auto"
};
