import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  CONTAINER_PROCESSING_RESEARCH,
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  CORRECTIVE_ACTION_REQUESTS_SEARCH,
  CREATE_DISTRIBUTION_LIST,
  DISCREPANCY_MANAGEMENT,
  GENERAL,
  MEDIA_REQUEST_DETAIL,
  OPEN_MEDIA_PROCESSING_RESEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH,
  REPORTS_MODULE_BASE_PATH,
  REPORTS_MODULE_VAULT_PATH,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_SEARCH,
  RESCHEDULE_SERVICE,
  RUN_MANAGEMENT,
  SCHEDULE,
  VAULT_STATUS_REPORT,
  WORK_MANAGEMENT
} from "routing/Paths";
import * as Icons from "../../assets/images";
import { TabShortcutsStyles } from "./TabShortcutsStyles";

const iconAltText = [
  "Container Processing",
  "Open Media Research",
  "Request Search ",
  "Create Distribution List",
  "Discrepancy Search",
  "Open Media Work Management",
  "Run Management",
  "Reschedule Service",
  "Corrective Action Request Search",
  "Vault Status Report",
  "Container Research",
  "Customer Schedule",
  "Media Request",
  "Corrective Action Request Detail"
];

const routerLink = [
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${GENERAL}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_PROCESSING_RESEARCH}`,
  `${REQUEST_MODULE_BASE_PATH}/${REQUEST_SEARCH}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${CREATE_DISTRIBUTION_LIST}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${RUN_MANAGEMENT}`,
  `${REQUEST_MODULE_BASE_PATH}/${RESCHEDULE_SERVICE}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`,
  `${REPORTS_MODULE_BASE_PATH}/${REPORTS_MODULE_VAULT_PATH}/${VAULT_STATUS_REPORT}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_PROCESSING_RESEARCH}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SCHEDULE}`,
  `${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}`,
  `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`
];

const TabShortcuts = () => {
  const { t } = useTranslation();
  const icons = [
    Icons.ContainerProcessingIcon,
    Icons.OpenMediaResearchIcon,
    Icons.RequestSearchIcon,
    Icons.CreateDistributionListIcon,
    Icons.DiscrepancySearchIcon,
    Icons.OpenMediaWorkManagementIcon,
    Icons.RunManagementIcon,
    Icons.RescheduleServiceIcon,
    Icons.CorrectiveActionRequestSearchIcon,
    Icons.VaultStatusIcon,
    Icons.ContainerResearchIcon,
    Icons.CustomerScheduleIcon,
    Icons.MediaRequestIcon,
    Icons.CorrectiveActionRequestDetailIcon
  ];
  return (
    <Grid id="tab_shortcuts_main_grid1" container spacing={5}>
      <Grid id="tab_shortcuts_main_grid2" item xs={12}>
        <Box
          id="tab_shortcuts_main_box"
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography variant="h4">{t("dashboard.welcomeTitle")}</Typography>
        </Box>
      </Grid>
      <Grid
        id="tab_shortcuts_grid3"
        item
        container
        sx={{
          justifyContent: "flex-start"
        }}
      >
        {icons.map((Icon, index) => (
          <Grid
            id="tab_shortcuts_grid4"
            item
            key={index}
            xs={1.9}
            mr={6}
            mb={3}
          >
            <Box
              id="tab_shortcuts_nav_box"
              sx={{ cursor: "pointer" }}
              display="flex"
              flexDirection="row"
              gap={1}
            >
              <Icon id="tab_shortcuts_nav_icon" alt={iconAltText[index]} />

              <Typography
                id="tab_shortcuts_nav_texts"
                sx={TabShortcutsStyles.navigateTitle}
                as={Link}
                to={routerLink[index]}
              >
                {iconAltText[index]}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TabShortcuts;
