import { Button, Typography } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const TimeoutWarningModal = ({
  isModalOpen,
  onCloseModal,
  remainingTimeInSec
}) => {
  const remainingSec = String(Math.floor(remainingTimeInSec % 60)).padStart(
    2,
    "0"
  );

  return (
    <CommonModal
      open={isModalOpen}
      title={t("timeout.sessionTimeout")}
      body={
        <Typography variant="subtitle1">
          {t("timeout.timeoutMessage")}
          <b>
            {" "}
            {remainingSec} {t("timeout.seconds")}
          </b>
        </Typography>
      }
      buttons={
        <Button variant="contained" onClick={onCloseModal}>
          {t("timeout.stayLoogedIn")}
        </Button>
      }
    />
  );
};
export default TimeoutWarningModal;
