import { t } from "i18next";

export const programTypes = {
  container: "container",
  openMedia: "openMedia",
  c: "C",
  o: "O"
};

export const severity = {
  error: "error",
  warning: "warning"
};

export const zero = "0";
export const systemId = "60";
export const attributeTypeId = "9";

export const openMediaColumnsList = [
  {
    name: "openMedia",
    label: t("disasterRecovery.eventDetail.rgOpenMedia")
  },
  {
    name: "status",
    label: t("disasterRecovery.eventDetail.status")
  },
  {
    name: "mediaType",
    label: t("disasterRecovery.eventDetail.mediaType")
  },
  {
    name: "drpCode",
    label: t("disasterRecovery.eventDetail.DRPCode")
  },
  {
    name: "description",
    label: t("disasterRecovery.eventDetail.description")
  }
];

export const containerColumnsList = [
  {
    name: "containerNumber",
    label: t("disasterRecovery.eventDetail.containerNumber")
  },
  {
    name: "status",
    label: t("disasterRecovery.eventDetail.status")
  },
  {
    name: "description",
    label: t("disasterRecovery.eventDetail.mediaType")
  }
];

export const volser = "volser";
export const containerNumber = "container_number";
