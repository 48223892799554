import { t } from "i18next";
export const preferencesTypeIds = ["41", "42"];
export const preferenceXML = "<ObjectValues>|</ObjectValues>";
export const preferenceXMLValues =
  '<ObjectValue Object_Id="|" Object_Value="|" Version=""/>';
export const padStartLength = 10;
export const preferenceTypeMap = {
  41: "Pay Invoice",
  42: "Comments"
};

export const programSecurityCode356 = 356;
export const filterOptions = [
  { label: t("preferences.payInvoice.all"), value: "all" },
  { label: t("preferences.comments"), value: "41" },
  { label: t("preferences.payInvoice.payInvoice"), value: "42" }
];
export const systemId = "69";
export const zero = "0";
export const all = "all";
export const hyphen = "-";

export const dataIndex = {
  date: "date",
  imEmployee: "imEmployee",
  authorizedBy: "authorizedBy",
  changedValues: "changedValues",
  name: "name",
  previousValue: "previousValue",
  newValue: "newValue"
};
export const filterPopupId = "filter-popover";
export const save = "save";
export const edit = "edit";
export const awaited = "await";
