import { Button, Typography } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { authSection } from "utils/constants";

const PersonnelDetailResetAuthModal = ({
  showResetAuthPopup,
  setShowResetAuthPopup,
  onResetAuthYesClick,
  personalDetailForm,
  valuePersonnelAuth,
  employeeEmail
}) => {
  return (
    <CommonModal
      open={showResetAuthPopup}
      title={t("common.confirm")}
      body={
        <>
          <Typography>{t("personnelDetail.resetAuthMsgOne")}</Typography>
          <Typography>
            {t("personnelDetail.resetAuthMsgTwo")}{" "}
            {valuePersonnelAuth === authSection?.bypass ? (employeeEmail +", "+ personalDetailForm?.values?.businessEmail) : personalDetailForm?.values?.businessEmail }
          </Typography>
        </>
      }
      buttons={
        <>
          <Button variant="outlined" onClick={() => onResetAuthYesClick()}>
            {t("common.yes")}
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowResetAuthPopup(false)}
          >
            {t("common.no")}
          </Button>
        </>
      }
    />
  );
};

export default PersonnelDetailResetAuthModal;
