/* 
Authentication
*/
export const LOGIN = "/AuthenticationService/Login";

/* 
Service wrapper
*/
export const WRAPPER_ENDPOINT = "/SecureBase/GetPickHubServicerResult";

/* 
Header
*/
export const REQUEST_HEADER = {
  url: WRAPPER_ENDPOINT,
  method: "POST"
};

// Request Base URL
const requestBaseUrl = process.env.REACT_APP_CF_URL_MODULE_REQUEST;

//Billing Base Url
const billingBaseUrl = process.env.REACT_APP_CF_URL_MODULE_BILLING;
const authorizationBaseUrl = process.env.REACT_APP_CF_URL_MODULE_AUTHORIZATION;
const notificationBaseUrl = process.env.REACT_APP_CF_URL_NOTIFICATION;

// documents download base url
const downloadDocuments = `${process.env.REACT_APP_CF_URL_DOCUMENTS}/document`;

//Security Base Url
const securityBaseUrl = process.env.REACT_APP_CF_URL_MODULE_SECURITY;

//Vendor Management Base Url
const vendorManagementBaseUrl = `${process.env.REACT_APP_CF_URL_MODULE_TRANSPORT}/vendormanagement`;
//Employee Base Url
const employeeBaseUrl = process.env.REACT_APP_CF_URL_MODULE_EMPLOYEE;

//Transport Base Url
const transportBaseUrl = process.env.REACT_APP_CF_URL_MODULE_TRANSPORT;

//Open Media
const openMediaProcessingUrl = process.env.REACT_APP_CF_URL_MODULE_OPENMEDIA;
const openMediaProcessingVaultValidation =
  process.env.REACT_APP_CF_URL_MODULE_LOGICAL_VAULT;

const vaultStatusReport = process.env.REACT_APP_CF_URL_VAULT_STATUS_REPORT;

// Label Printing URL
const labelPrintingBaseUrl = process.env.REACT_APP_CF_URL_LABEL_PRINTING;

//CIAM User
const ciamUserBaseUrl = process.env.REACT_APP_CF_URL_CIAM_USER;

//Customer File Search Base Url
const customerFileSearchBaseUrl = `${process.env.REACT_APP_CF_URL_MODULE_OPENMEDIA}/customerfilesearch`;

// Container Processing Research Transport module Base Url
const containerProcessingBaseUrl =
  process.env.REACT_APP_CF_URL_CONTAINER_PROCESSING;

// CORRECTIVE ACTION REQUEST MODULE BASE URL
const correctiveActionRequestUrl =
  process.env.REACT_APP_CF_URL_CORRECTIVE_ACTION_REQUEST;

//customer Information Base Url
const customerInformationBaseUrl =
  process.env.REACT_APP_CF_URL_MODULE_CUSTOMERINFORMATION;

//Disaster Recovery Base Url
const disasterRecoveryBasrUrl = `${process.env.REACT_APP_CF_URL_MODULE_DISASTER}`;

const logicalVaultBaseUrl = `${process.env.REACT_APP_CF_URL_MODULE_LOGICAL_VAULT}`;
//Discrepancy Search Base Url
const discrepancyManagementBaseUrl = `${process.env.REACT_APP_CF_URL_MODULE_DISCREPANCY_MANAGEMENT}/discrepancymanagement`;
// Vault Configuration
const vaultConfigurationUrl =
  process.env.REACT_APP_CF_URL_MODULE_VAULT_CONFIGURATION;

// Dashboard
const dashboardUrl = process.env.REACT_APP_CF_URL_DASHBOARD;

// Reports
const reportsUrl = process.env.REACT_APP_CF_URL_REPORTS;

//CORRECTIVE_ACTION_REQUEST
const correctiveActionRequest =
  process.env.REACT_APP_CF_URL_CORRECTIVE_ACTION_REQUEST;
const moduleCustomerMoveUrl = process.env.REACT_APP_CF_URL_MODULE_CUSTOMER_MOVE;

//Notification Base Url
const notificationConfigBaseUrl = `${process.env.REACT_APP_CF_URL_NOTIFICATION_CONFIG}/notification`;
const requestHelpUrl = process.env.REACT_APP_CF_URL_HELP_INFORMATION;
//Label

// CF URLS
export const CF_URLS = {
  COMMON: {
    getalllocations: "/request/getalllocations"
  },
  authentication: {
    getSessionId: `${securityBaseUrl}/security/initiatesession`
  },
  requestDetail: {
    getScheduledServiceDates: `${requestBaseUrl}/request/returnrequestidandscheduleddateforcustomer`,
    getServiceAuthComments: `${requestBaseUrl}/request/getserviceauthcomments`,
    checkAuthorization: `${requestBaseUrl}/request/determineauthorizationrequirement`
  },
  REQUEST_VAULT_ITEMIZED: {
    destruct_media_validate_sp: "/destruction/verifyvolsersstatus",
    destruct_validate_select_media_sp:
      "/destruction/validatevolserandreturnduplicates",
    destruct_request_i_sp: "/destruction/createnewmediadestructionrequest",
    destruct_request_u2_sp: "/destruction/updatemediadestruction",
    check_security_access_sp: "/request/checksuserhasaccess",
    destruct_request_cancel_sp: "/destruction/canceldestructrequest",
    destruct_request_detail_l_sp: "/destruction/getdestructrequestdetail"
  },
  billingRecurringCharge: {
    fetchRecurringTransaction: `${billingBaseUrl}/chargesearch/fetchrecurringtransl1`,
    getCatalogItems: `${billingBaseUrl}/chargesearch/getrecurringcatalogitems`,
    updateRecurringCharge: `${billingBaseUrl}/charge/updaterecurringtrans`,
    insertRecurringCharge: `${billingBaseUrl}/charge/insertrecurringtrans`,
    cancelRecurringCharge: `${billingBaseUrl}/charge/cancelrecurringtransactions`,
    getCurrentEOMBillingDate: `${billingBaseUrl}/billingstatus/returnsdateofcurrenteombillingcycle`
  },
  autorization: {
    authorizationCodes: `${authorizationBaseUrl}/authorization/authorizationcodes`
  },
  billingTransactions: {
    getBillingInfoExcludeHoldOTO: `${billingBaseUrl}/status/getbillinginfoexcludeholdoto`,
    getBillingInterfaceLogon: `${billingBaseUrl}/status/billinginterfacelogon`,

    getSettingValues: `${billingBaseUrl}/transactions/getsettingvalues`,
    execBillingCycleL1ByDistrict: `${billingBaseUrl}/status/execbillingcyclel1bydistrict`,
    getCurrentBranchBillingSystem: `${billingBaseUrl}/status/getcurrentbranchbillingsystem`,

    getOTOManualPricingFlag: `${billingBaseUrl}/transactions/getotomanualpricingflag`,
    executeBillingRunL3: `${billingBaseUrl}/status/executebillingrunl3`,

    checkBillRunPhaseL1: `${billingBaseUrl}/status/checkbillrunphasel1`,
    getBillingClassificationCodes: `${billingBaseUrl}/transactions/getbillingclassificationcodes`,

    getCustomerContractCatalogItems: `${billingBaseUrl}/transactions/getcustomercontractcatalogitems`,

    billTransL1ByDistrict: `${billingBaseUrl}/transactions/billtransl1bydistrict`,
    executeBillTransL2InBranchDB: `${billingBaseUrl}/transactions/executebilltransl2inbranchdb`,
    executeInterfaceTransactionInBranchDB: `${billingBaseUrl}/transactions/executeinterfacetransactioninbranchdb`,

    checkBillRunPhaseL3: `${billingBaseUrl}/status/checkbillrunphasel3`,
    rollbackBillingByDistricDatePhaseCycle: `${billingBaseUrl}/status/rollbackbillingbydistricdatephasecycle`,

    getPricingContractBasedOnCycle: `${billingBaseUrl}/transactions/getpricingcontractbasedoncycle`,

    checkBillRunPhaseL4: `${billingBaseUrl}/transactions/checkbillrunphasel4`,
    insertInterfaceTransByDistrict: `${billingBaseUrl}/transactions/insertinterfacetransbydistrict`,
    billTransI2ByDistrict: `${billingBaseUrl}/transactions/billtransi2bydistrict`,

    execInterfaceTransUpdateByDistrict: `${billingBaseUrl}/transactions/execinterfacetransupdatebydistrict`,
    updateBillingTransactionByDistrict: `${billingBaseUrl}/transactions/updatebillingtransactionbydistrict`,

    getCatalogItemL1: `${billingBaseUrl}/transactions/getcatalogiteml1`
  },
  customerBillingInformation: {
    remoteCustomerBillinfo: `${billingBaseUrl}/customerinfo/remotecustomerbillinfo`,
    getEmployeeData: `${billingBaseUrl}/runmanagement/getemployeedata`,
    insertPricingContractTtemdesc: `${billingBaseUrl}/customerinfo/insertpricingcontractitemdesc`,
    updateCustomerBillinginfo: `${billingBaseUrl}/customerinfo/updatecustomerbillinginfo`
  },
  billingSummary: {
    getBillingSummary: `${billingBaseUrl}/enterprisesummary/executebillingrunl1`,
    buildRAFile: `${billingBaseUrl}/enterprisebilling/enterprisebillingsummary`,
    archiveFile: `${billingBaseUrl}/enterprisebilling/enterprisebillingsummaryarchive`,
    billingActiveStatus: `${billingBaseUrl}/status/executebillingrunl3`
  },
  BillingCommon: {
    BillRun: `${billingBaseUrl}/status/executebillingrunl2`
  },
  capacityBillingConfigurationSearch: {
    allCapacityBillingSuffixes: `${billingBaseUrl}/searchgen/getallcapacitybillingsuffixes`,
    capacityBillingCatalogItemCode: `${billingBaseUrl}/searchconfig/getcapacitybillingcatalogitemcode`,
    getTableData: `${billingBaseUrl}/searchconfig/getallpricingcontractcodes`,
    capacityBillingConfiguration: `${billingBaseUrl}/detailconfig/getcapacitybillingconfiguration`,
    saveCapacityBillingConfiguration: `${billingBaseUrl}/detailconfig/savecapacitybillingconfiguration`,
    pricingContracts: `${billingBaseUrl}/pricingsearch/searchpricingcontractcodes`
  },
  billingErrorLog: {
    getBillingInfo: `${billingBaseUrl}/status/getbillinginfoexcludeholdoto`,
    getErrorLog: `${billingBaseUrl}/errlog/fetchinterfacetranserrorlog`,
    checkError: `${billingBaseUrl}/errlog/billcheckerrorl1`,
    saveErrorLog: `${billingBaseUrl}/errlog/bulkupdateinterfacetrans`
  },
  billingStatus: {
    getbillinginfoexcludeholdoto: `${billingBaseUrl}/status/getbillinginfoexcludeholdoto`,
    billinginterfacelogon: `${billingBaseUrl}/status/billinginterfacelogon`,
    execbillingcyclel1bydistrict: `${billingBaseUrl}/status/execbillingcyclel1bydistrict`,
    executebillingrunl2: `${billingBaseUrl}/status/executebillingrunl2`,
    checkbillrunphasel1: `${billingBaseUrl}/status/checkbillrunphasel1`,
    getbillingphasel1: `${billingBaseUrl}/status/getbillingphasel1`,
    eombillingphase1: `${billingBaseUrl}/status/eombillingphase1`,
    eombillingphase2: `${billingBaseUrl}/status/eombillingphase2`,
    eombillingphase4: `${billingBaseUrl}/status/eombillingphase4step1`,
    eombillingphase5: `${billingBaseUrl}/status/eombillingphase5step1`,
    eombillingphase5step3: `${billingBaseUrl}/status/eombillingphase5step3`,
    eombillingphase6: `${billingBaseUrl}/status/eombillingphase6`,
    eombillingphase7: `${billingBaseUrl}/status/eombillingphase7`,
    eombillingphaserollback: `${billingBaseUrl}/status/eombillingphaserollback`,
    insertbillingrunphase: `${billingBaseUrl}/status/insertbillingrunphase`,
    eombillingphasecomplete: `${billingBaseUrl}/status/eombillingphasecomplete`,
    billingstatusphasefind: `${billingBaseUrl}/status/billingstatusphasefind`,
    billingcoordinatormail: `${billingBaseUrl}/status/billingcoordinatormail`,
    billingResetFunctionality: `${billingBaseUrl}/status/billingresetfunctionality`,
    billingResetPhase3okClick: `${billingBaseUrl}/status/billingresetPhase3okclick`
  },
  notification: {
    sendMail: `${notificationBaseUrl}/sendmail/send`
  },
  billingRules: {
    getCatalogItem: `${billingBaseUrl}/interfacerules/getcatalogiteml2`,
    getIdentifierInfo: `${billingBaseUrl}/interfacerules/getbillingidentifierinfo`
  },
  defaultPricingUsageReport: {
    getPastSixMonthSendDates: `${billingBaseUrl}/pricingreport/getpastsixmonthsenddates`,
    reportDefaultCatalogBilling: `${billingBaseUrl}/pricingreport/reportdefaultcatalogbilling`,
    billingInterfaceLogon: `${billingBaseUrl}/status/billinginterfacelogon`
  },
  capacityBillingGenerationSearch: {
    getAllCapacityBillingSuffixes: `${billingBaseUrl}/searchgen/getallcapacitybillingsuffixes`,
    getCapacityBillingConfigSuffix: `${billingBaseUrl}/searchgen/getcapacitybillingconfigsuffix`,
    insertCapacityBillingConfigSuffix: `${billingBaseUrl}/detailgen/insertcapacitybillingconfigsuffix`
  },
  routeDetails: {
    getRouteCodes: `${transportBaseUrl}/routedetails/getroutebycode`,
    selectedCodeRouteDetails: `${transportBaseUrl}/routedetails/getrouteschedule`,
    getVehicleDetails: `${transportBaseUrl}/routedetails/getvehicledetails`,
    getRouteTypes: `${transportBaseUrl}/routedetails/getroutetype`,
    getVendors: `${transportBaseUrl}/routedetails/gettransportationvendor`,
    getRouteCustomerCountByTime: `${transportBaseUrl}/routedetails/getroutecustomercountbytime`,
    saveNewRouteHeaderInfo: `${transportBaseUrl}/runmanagement/insertroute`,
    saveNewRouteScheduleInfo: `${transportBaseUrl}/routedetails/insertrouteschedule`,
    updateRouteHeaderInfo: `${transportBaseUrl}/runmanagement/updateroute1`,
    updateRouteScheduleInfo: `${transportBaseUrl}/routedetails/updaterouteschedule`,
    checkForAssignedCustomers: `${transportBaseUrl}/routedetails/getroutecustomercount`,
    deleteRouteSchedule: `${transportBaseUrl}/routedetails/deleterouteschedule`
  },
  securityGroupEmployee: {
    getSecurityGroupEmployeeDetails: `${securityBaseUrl}/security/employeeinformationwithhelpofsecuritygroupid`,
    getSecurityGroupDetails: `${securityBaseUrl}/security/securitygroupinformation`
  },
  securityGroupManagement: {
    getSecurityGroupDetails: `${securityBaseUrl}/security/programsecurityinformation`
  },
  securityEmployeeData: {
    rootCauseEmployeeUrl: `${securityBaseUrl}/security/employeedataandreplacementsecurebaselogic`
  },
  vendorManagement: {
    getVendorNames: `${vendorManagementBaseUrl}/retrievetransportationvendor`,
    insertTransportVendor: `${vendorManagementBaseUrl}/inserttransportationvendor`,
    updateTransportVendor: `${vendorManagementBaseUrl}/updatetransportationvendor`,
    deleteTransportVendor: `${vendorManagementBaseUrl}/deletetransportationvendor`
  },
  employeeSearch: {
    getLoadingValues: `${employeeBaseUrl}/employeedetails/loadinformationtoglobalarray`
  },
  employeeDetail: {
    insertUpdate: `${employeeBaseUrl}/employeedetails/employeinsertupdate`,
    validatePhoneFormat: `${employeeBaseUrl}/employeedetails/validateemployeephoneformat`,
    getPayrollIdLength: `${employeeBaseUrl}/employeedetails/getnumofdigitspayrollid`,
    validateEmployeeChanges: `${employeeBaseUrl}/employeedetails/validateemployeechanges`,
    getLinkedEmpidList: `${employeeBaseUrl}/employeedetails/getlinkedempidlist`
  },
  runManagement: {
    getUpdatableRuns: `${transportBaseUrl}/runmanagement/getupdatableruns`,
    generateScheduledRun: `${transportBaseUrl}/runmanagement/generatescheduledruns`,
    getUnassignedRequests: `${transportBaseUrl}/runmanagement/getunassignedrequests`,
    getAssignedRequests: `${transportBaseUrl}/runmanagement/getassignedrequests`,
    getVehicleDetails: `${transportBaseUrl}/runmanagement/getvehicledetails`,
    getVendorDetails: `${transportBaseUrl}/runmanagement/gettransportationvendor`,
    createRun: `${transportBaseUrl}/runmanagement/createrun`,
    updateRun: `${transportBaseUrl}/runmanagement/updateruninformation`,
    deleteRun: `${transportBaseUrl}/runmanagement/deleterun`,
    getRequestSummary: `${transportBaseUrl}/runmanagement/retrieverequestsummary`,
    assignLocationToRun: `${transportBaseUrl}/runmanagement/assignservicerequeststorunlocations`,
    assignCslToRun: `${transportBaseUrl}/runmanagement/redeliveryassigncsl`,
    assignCustomerToRun: `${transportBaseUrl}/runmanagement/assigncustomerstorunpositions`,
    assignDeliveryRequestToRun: `${transportBaseUrl}/runmanagement/assignredeliveryorpickuprequest`,
    assignServiceRequestToRun: `${transportBaseUrl}/runmanagement/assignservicerequesttonewrunposition`,
    assignContainerToRun: `${transportBaseUrl}/runmanagement/assigncontainertonewrunposition`,
    reAssignLocationToRun: `${transportBaseUrl}/runmanagement/reassignlocationtonewrun`,
    reAssignCustomerToRun: `${transportBaseUrl}/runmanagement/reassigncustomertonewrun`,
    reAssignDeliveryRequestToRun: `${transportBaseUrl}/runmanagement/reassignrequesttonewrunposition`,
    reAssignContainerToRun: `${transportBaseUrl}/runmanagement/reassigncontainertonewrunposition`,
    getEmailAddress: `${transportBaseUrl}/runmanagement/returninternalemail`
  },
  personnalSearch: {
    searchDetails: `${authorizationBaseUrl}/authorization/summaryleveldata`
  },
  printReports: {
    billing: {
      enterpriseBillingSummaryReport: `${downloadDocuments}/genaratebillingreport/enterprisebillingsummaryreport`,
      billingVarianceReport: `${downloadDocuments}/genaratebillingreport/billingvariancereport`,
      zeroCountReport: `${downloadDocuments}/genaratebillingreport/zerocountitemreport`,
      interfaceRulesReport: `${downloadDocuments}/genaratebillingreport/billinginterfacerulesreport`,
      defaultPricingReport: `${downloadDocuments}/genaratebillingreport/defaultpricingusagereport`,
      capacityBillingConfigurationReport: `${downloadDocuments}/genaratebillingreport/capacitybillingconfigurationsreport`,
      capacityBillingGenerationReport: `${downloadDocuments}/genaratebillingreport/configurationgenerationsreport`,
      billingTransactionsReport: `${downloadDocuments}/genaratebillingreport/billingtransactionsreport`,
      errorLogReport: `${downloadDocuments}/genaratebillingreport/billingtransactionserror`,
      recurringChargeReport: `${downloadDocuments}/genaratebillingreport/recurringchargesreport`,
      customerInfoReport: `${downloadDocuments}/genaratebillingreport/pricingcontractslogicalvaultsandmedia`,
      billingStatusReport: `${downloadDocuments}/genaratebillingreport/billingstatus`,
      handlingCountsReport: `${downloadDocuments}/genaratebillingreport/openmediahandlingcounts`,
      vaultingCountsReport: `${downloadDocuments}/genaratebillingreport/openmediavaultingcounts`,
      countSheetReport: `${downloadDocuments}/genaratebillingreport/countsheetmisc`,
      transCountSheetReport: `${downloadDocuments}/genaratebillingreport/transparentcountsheet`,
      recurringChargeReviewReport: `${downloadDocuments}/genaratebillingreport/recurringchargereviewreport`,
      openMediaHandlingReviewReport: `${downloadDocuments}/genaratebillingreport/hrhandlessrsreports`
    },
    request: {
      requestSearchReport: `${downloadDocuments}/requestmanagementreport/requestsearchreport`,
      requestSearchSpecialReport: `${downloadDocuments}/requestmanagementreport/getspecialreport`,
      requestDetailReport: `${downloadDocuments}/requestmanagementreport/requestdetailreport`,
      mdrSearchReport: `${downloadDocuments}/requestmanagementreport/mediadestructionrequestsearchreport`,
      mdrDeliveryReport: `${downloadDocuments}/requestmanagementreport/sitaddeliveryreport`,
      mdrPickupReport: `${downloadDocuments}/requestmanagementreport/pickupdistructiondetails`,
      mrdAssignTransportReport: `${downloadDocuments}/requestmanagementreport/gettransportlabels`,
      mrdAssignTransportA4Report: `${downloadDocuments}/requestmanagementreport/gettransportlabelsa4`,
      mdrVaultItemizedStdReport: `${downloadDocuments}/requestmanagementreport/destructiondetailreport`,
      mdrVaultItemizedPickReport: `${downloadDocuments}/requestmanagementreport/pickreportlocation`,
      mdrVaultItemizedPickPotraitReport: `${downloadDocuments}/requestmanagementreport/pickreportlocationpotrait`
    },
    transportation: {
      runSequenceReport: `${downloadDocuments}/transportation/runsequencereport`,
      runSequenceReportDirections: `${downloadDocuments}/transportation/runsequencereportdirections`,
      routeDetailReport: `${downloadDocuments}/transportation/routedetailreport`
    },
    personnelSearch: {
      downloadReport: `${downloadDocuments}/reportauth/personnelsearchresultreport`
    },
    openMediaProcessing: {
      shipListreport: `${downloadDocuments}/openmedia/pickreportshiplist`,
      customerFileSearchReport: `${downloadDocuments}/openmedia/customerfilesectionsearch`,
      distributionReport: `${downloadDocuments}/openmedia/omdistributionreport`,
      shipListReport: `${downloadDocuments}/openmedia/pickreportshiplist`,
      pickLocationLReport: `${downloadDocuments}/openmedia/pickreportsortl`,
      pickLocationPReport: `${downloadDocuments}/openmedia/pickreportsortp`,
      pickMediaLReport: `${downloadDocuments}/openmedia/pickreportsortmedia`,
      pickMediaPReport: `${downloadDocuments}/openmedia/pickreportsortmediatwo`,
      omResearchReport: `${downloadDocuments}/openmedia/omresearch`,
      getopenmediareport: `${downloadDocuments}/openmedia/getopenmediareport`,
      auditSummaryReport: `${downloadDocuments}/openmedia/omauditsummaryreport`,
      closeContainerContent: `${downloadDocuments}/openmedia/closecontainercontent`,
      openMediaResearch: `${downloadDocuments}/openmedia/openmediaresearch`,
      openMediaRequiringDistribution: `${downloadDocuments}/openmedia/openmediarequiringredistributionreport`
    },
    containerProcessing: {
      containerAuditSummeryReport: `${downloadDocuments}/containerprocessing/getcontaineraudisummery`,
      reassignTransportReport: `${downloadDocuments}/openmedia/reporttransportlabel`,
      runPrep: {
        getmediarunreport: `${downloadDocuments}/containerprocessing/getmediarunreport`,
        runreport: `${downloadDocuments}/containerprocessing/runreport` // Directions report
      },
      rpMediaProcessingPick: `${downloadDocuments}/containerprocessing/containerpicksetonereport`,
      distributionReport: `${downloadDocuments}/containerprocessing/reportdist`,
      incompleteStopsReport: `${downloadDocuments}/containerprocessing/incompletestops`,
      issuance: `${downloadDocuments}/containerprocessing/getissuecontainer`,
      containerAuditDetails: `${downloadDocuments}/containerprocessing/getcontainerauditdetails`,
      research: {
        containerSummary: `${downloadDocuments}/containerprocessing/containerresearchreport`,
        containerSummaryWithCMI: `${downloadDocuments}/containerprocessing/containercmisummery`,
        containerTransport: `${downloadDocuments}/containerprocessing/containertransport`
      }
    },
    customerInformation: {
      serviceInformation: `${downloadDocuments}/customerinfo/customerserviceinformationreport`,
      routeManagement: `${downloadDocuments}/customerinfo/getroutedetails`,
      customerSheduleReport: `${downloadDocuments}/customerinfo/customershedulereport`,
      serviceWindows: `${downloadDocuments}/customerinfo/customerservicewindows`,
      customerPreferencDetailsReport: `${downloadDocuments}/customerinfo/customerpreferencdetailsreport`,
      labelTemplateReport: `${downloadDocuments}/customerinfo/labeltemplatereport`,
      customerPreferencesReport: `${downloadDocuments}/customerinfo/customerpreferencesreport`,
      addOnCutOffDeadlineReport: ` ${downloadDocuments}/customerinfo/addoncutoffdeadlinereport`,
      customerdeliverypickupemailnotification: `${downloadDocuments}/customerinfo/customerdeliverypickupemailnotification`,
      donotCallToResolveDiscrepanciesReport: `${downloadDocuments}/customerinfo/donotcalltoresolvediscrepanciesreport`,
      holidayServiceReport: `${downloadDocuments}/customerinfo/holidayservicereport`,
      minimumEmptytransportsRequiredatCustomersiteReport: `${downloadDocuments}/customerinfo/minimumemptytransportsrequiredatcustomersitereport`,
      payinvoiceLinkReport: `${downloadDocuments}/customerinfo/payinvoicelinkreport`,
      placeholdOnMediaDestructionRequestsReport: `${downloadDocuments}/customerinfo/placeholdonmediadestructionrequestsreport`,
      scanonsiteReport: `${downloadDocuments}/customerinfo/scanonsitereport`,
      serviceIndicatorReport: `${downloadDocuments}/customerinfo/serviceindicatorreport`
    },
    auditdetail: {
      omauditdetailsreport: `${downloadDocuments}/openmedia/omauditdetailsreport`,
      auditposiblematchreport: `${downloadDocuments}/openmedia/auditposiblematchreport`,
      incompleteStopsReport: `${downloadDocuments}/containerprocessing/incompletestops`
    },
    vaultConfiguration: {
      summeryReport: `${downloadDocuments}/vaultconfiguration/vaultconfigurationsummeryreport`,
      unassignShelvesReport: `${downloadDocuments}/vaultconfiguration/unassignshelvesreport`,
      vaultCapacityHistoryReport: `${downloadDocuments}/vaultconfiguration/vaultcapacityhistoryreport`,
      availableCapacityReport: `${downloadDocuments}/vaultconfiguration/availableCapacityReport`,
      rackingNearCapacityReport: `${downloadDocuments}/vaultconfiguration/rackingnearcapacityreport`,
      vaultConfigurationSummeryReport: `${downloadDocuments}/vaultconfiguration/vaultconfigurationsummeryreporttwo`,
      containerIncorrectShelfReport: `${downloadDocuments}/vaultconfiguration/containerincorrectshelfreport`,
      vaultConfigurationDetailReport: `${downloadDocuments}/vaultconfiguration/vaultconfigurationdetailreport`,
      logicalVaultSwithinCorrectShelfDurationReport: `${downloadDocuments}/vaultconfiguration/logicalvaultswithincorrectshelfdurationreport`,
      logicalVaultnoReviewHourlyReport: `${downloadDocuments}/vaultconfiguration/logicalvaultnoreviewhourlyreport`,
      inboundOutboundInventoryDetailReport: `${downloadDocuments}/vaultconfiguration/inboundoutboundinventorydetailreport`,
      nonContiguousLogicalVaultsReport: `${downloadDocuments}/vaultconfiguration/noncontiguouslogicalvaultsreport`,
      customerMapReport: `${downloadDocuments}/vaultconfiguration/containerslottingphysicalvaultcustomermapreport`,
      openMediaRackingNearCapacity: `${downloadDocuments}/vaultconfiguration/openmediarackingnearcapacity`,
      inboundOutboundSummary: `${downloadDocuments}/vaultconfiguration/inboundoutboundinventorysummaryreport`,
      inboundOutboundSummaryIme: `${downloadDocuments}/vaultconfiguration/inboundoutboundinventorysummaryreportime`,
      configDetailReportContainer: `${downloadDocuments}/vaultconfiguration/vaultconfigurationdetailteportcontainer`,
      inboundOutboundDetailIme: `${downloadDocuments}/vaultconfiguration/inboundoutbounddetailimereport`,
      vaultCapacityDetail: `${downloadDocuments}/vaultconfiguration/vaultcapacitydetailreport`
    },
    logicalVaultDetail: {
      printVault: `${downloadDocuments}/vaultconfiguration/logicalvaultdetailreport`
    },
    discrepancy: {
      discrepancyDetailReport: `${downloadDocuments}/discrepancymanagementreport/discrepancydetailreport`,
      additionalinformationreport: `${downloadDocuments}/discrepancymanagementreport/additionalinformationreport`,
      discrepancySearchReport: `${downloadDocuments}/discrepancymanagementreport/discrepancysearchreport`
    },
    reports: {
      displayTerminatedSalesPerson: `${downloadDocuments}/genaratebillingreport/rsterminatesalesreport`,
      reportTransportLocation: `${downloadDocuments}/openmedia/reporttransportlocation`,
      temporyTransportHistoryReport: `${downloadDocuments}/openmedia/temporytransporthistoryreport`,
      customerScheduleExpirationReport: `${downloadDocuments}/reportauth/customerscheduleexpirationreport`,
      customerAuthorizationListReport: `${downloadDocuments}/reportauth/customerauthorizationlistreport`,
      inHouseAuthorizationList: `${downloadDocuments}/reportauth/inhouseauthorizationlist`,
      runsAuthorizationListReport: ` ${downloadDocuments}/reportauth/runsauthorizationlistreport`,
      inHouseAuthorizationListuReport: `${downloadDocuments}/reportauth/inhouseauthorizationlistureport`,
      containerinventorysummaryreport: `${downloadDocuments}/openmedia/containerinventorysummaryreport`,
      containerInventoryDetailReport: `${downloadDocuments}/containerprocessing/containerinventorydetailreport`,
      runsAuthorizationListUpdate: `${downloadDocuments}/reportauth/runsauthorizationlist`,
      uploadErrors: `${downloadDocuments}/openmedia/uploaderrors`,
      emptyTransportDeficit: `${downloadDocuments}/openmedia/emptytransportdeficit`,
      runArrivalReport: `${downloadDocuments}/openmedia/runarrivalreport`,
      reportDist: `${downloadDocuments}/containerprocessing/reportdist`,
      vaultStatusReport: `${downloadDocuments}/openmedia/vaultstatusreport`,
      dailybillableactivitycount: `${downloadDocuments}/genaratebillingreport/dailybillableactivitycount`,
      disasterrecoveryactivitycountsreport: `${downloadDocuments}/genaratebillingreport/disasterrecoveryactivitycountsreport`,
      scanAtCustomerActivityCountsReport: `${downloadDocuments}/genaratebillingreport/scanatcustomeractivitycountsreport`,
      monthlyBillableActivityCountsReport: `${downloadDocuments}/genaratebillingreport/monthlybillableactivitycountsreport`,
      dailyBillableActivityCountsbyLogicalVaultReport: `${downloadDocuments}/genaratebillingreport/dailybillableactivitycountsbylogicalvaultreport`,
      monthlyManagedContainerContentsCountReport: `${downloadDocuments}/genaratebillingreport/monthlymanagedcontainercontentscountreport`
    },
    todoReports: {
      customerWithOutPricingContact: `${downloadDocuments}/customerinfo/customerwithoutpricingcontact`,
      transportOnHoldReport: `${downloadDocuments}/customerinfo/transportonholdreport`,
      transportsPending: `${downloadDocuments}/customerinfo/containerstodoreport`,
      containersPendingDistribution: `${downloadDocuments}/customerinfo/pendingcontainerdistributioneport`,
      customersRequiringAuthPersonal: `${downloadDocuments}/customerinfo/customerauthcountreport`,
      customersCatalogueItemsMissing: `${downloadDocuments}/customerinfo/missingcatelogitemreport`,
      openMediaMissingInventoryFiles: `${downloadDocuments}/openmedia/openmediamissinginventoryfiles`,
      openMediaRackingNearCapacity: `${downloadDocuments}/openmedia/openmediarackingnearcapacity`
    },
    correctiveAction: {
      correctiveActionSummary: `${downloadDocuments}/carreport/correctiveactionrequestsearchresults`,
      correctiveActionRequestDetail: `${downloadDocuments}/carreport/correctiveactionrequestdetail`
    },
    employeReports: {
      employecontactreport: `${downloadDocuments}/customerinfo/employecontactreport`
    },
    customerMove: {
      reconcilliationReport: `${downloadDocuments}/carreport/customermoveopenmediareconciliationreport`
    },
    disasterRecovery: {
      instructionFilesReport: `${downloadDocuments}/carreport/disasterrecoveryinstructionfilesreport`,
      getEventSearchPrint: `${downloadDocuments}/carreport/disasterrecoveryeventreport`,
      getViewDRMediaPrint: `${downloadDocuments}/carreport/drmediareport`
    }
  },
  personnelDetail: {
    personnelrecordinsertion: `${authorizationBaseUrl}/authorization/personnelrecordinsertion`,
    personnelinformationandpassedpersonnelid: `${authorizationBaseUrl}/authorization/personnelinformationandpassedpersonnelid`,
    updatepersonnelprofile: `${authorizationBaseUrl}/authorization/updatepersonnelprofile`,
    customerpersonnelupdate: `${authorizationBaseUrl}/authorization/customerpersonnelsetting`,
    customeridandpersonnelauthorization: `${authorizationBaseUrl}/authorization/customeridandpersonnelauthorization`,
    setssouser: `${authorizationBaseUrl}/authorization/setssouser`,
    userupdatesso: `${authorizationBaseUrl}/authorization/updatessouser`,
    userremovesso: `${authorizationBaseUrl}/authorization/removessouser`,
    createSSOUser: `${ciamUserBaseUrl}/user/add`,
    checkSSOUserExist: `${ciamUserBaseUrl}/user/getbyid`,
    updateSSOUser: `${ciamUserBaseUrl}/user/update`,
    resetAuthNumber: `${authorizationBaseUrl}/authorization/resetauthnumber`,
    listOfCustomers: `${authorizationBaseUrl}/authorization/listofcustomers`,
    listExamplePhoneNumbers: `${authorizationBaseUrl}/authorization/listexamplephonenumbers`
  },
  openMediaProcessing: {
    getLocalDateTimeForTimeZone: `${openMediaProcessingUrl}/pickordistributionreport/getlocaldatetimefortimezone`,
    getLastRunDate: `${openMediaProcessingUrl}/pickordistributionreport/getlastrundate`,
    populateDailyPickingTasks: `${openMediaProcessingUrl}/pickreport/populatedailypickingtasks`,
    validateAssignTransports: `${openMediaProcessingUrl}/branchoutagepicklist/botransportvalidate`,
    completeOutagePickList: `${openMediaProcessingUrl}/branchoutagepicklist/completebranchoutagepicklist`,
    branchOutagePickList: `${openMediaProcessingUrl}/distributionreport/populatedailydistributiontasks`,
    logicalVaultAuditSearch: `${openMediaProcessingUrl}/research/getlogicalvaultsforcustomer`,
    createDistributionList: `${openMediaProcessingUrl}/createdistributionlist/insertcustomerfilesectionanddistributionitem`,
    logicalVaultValidation: `${openMediaProcessingVaultValidation}/logicalvault/logicalvault`,
    slotValidation: `${openMediaProcessingVaultValidation}/logicalvault/slotassign`,
    shipListReportsForBatchIds: `${openMediaProcessingUrl}/branchoutagepicklist/getshiplistreportsforbatchids`,
    workManagement: {
      picking: {
        getLastGeneretedDate: `${openMediaProcessingUrl}/pickordistributionreport/getlastrundate`,
        getrunlist: `${openMediaProcessingUrl}/pickreport/getrunsfordatewithstatuslessthanprepared`,
        getrunlistforCompletedBatches: `${openMediaProcessingUrl}/pickreport/getrunsbydateandstatus`,
        getDailyPickList: `${openMediaProcessingUrl}/pickreport/populatedailypickingtasks`,
        getPopulateDailyDistributionTasks: `${openMediaProcessingUrl}/distributionreport/populatedailydistributiontasks`,
        updateServiceDate: `${openMediaProcessingUrl}/pickreport/requestforspecificday`,
        getInsertSizeList: `${openMediaProcessingUrl}/pickordistributionreport/getsizesfordistrict`,
        cancelBatch: `${openMediaProcessingUrl}/auditdetail/cancelbatch`,
        pickingcustomerfilesection: `${openMediaProcessingUrl}/workmanagement/pickingcustomerfilesection`,
        pickingcustomerfilesectionrecieves: `${openMediaProcessingUrl}/workmanagement/pickingcustomerfilesectionrecieves`,
        pickingcustomerfilesectionandsplits: `${openMediaProcessingUrl}/workmanagement/pickingcustomerfilesectionandsplits`,
        getsizesfordistrict: `${openMediaProcessingUrl}/pickordistributionreport/getsizesfordistrict`,
        getCapacity: `${openMediaProcessingUrl}/workmanagement/getcustomerlogicalvalultsbylocation`,
        logicalVualtCheck: `${openMediaProcessingUrl}/distributionreport/getauditedlogicalvaultsforbatch`,
        assignSlot: `${openMediaProcessingUrl}/workmanagement/imorcaassignedopenmediaitems`,
        checkAuditInProgress: `${openMediaProcessingUrl}/pickreport/checkauditinprogressforcustomerids`,
        processAndBatchPickList: `${openMediaProcessingUrl}/pickreport/processandbatchpicklists`
      },
      inventory: {
        fetchInventoryFile: `${openMediaProcessingUrl}/inventory/fetchinventoryfile`,
        performinventorymediacomparison: `${openMediaProcessingUrl}/inventory/performinventorymediacomparison`
      },
      verify: {
        getOpenMediaBatchAndCustomerFileSection: `${openMediaProcessingUrl}/verify/getopenmediabatchandcustomerfilesection`,
        verifydistributionlistsagainstmediabatches: `${openMediaProcessingUrl}/verify/verifydistributionlistsagainstmediabatches`,
        skipverificationformediabatches: `${openMediaProcessingUrl}/verify/skipverificationformediabatches`,
        commitverificationresultsandupdatemediaattributes: `${openMediaProcessingUrl}/verify/commitverificationresultsandupdatemediaattributes`
      }
    },
    auditdetail: {
      getActiveLogicalVaultsList: `${openMediaProcessingUrl}/auditdetail/avticelvlist`,
      inventorydropdowns: `${openMediaProcessingUrl}/common/inventorydropdowns`,
      getshelvesonarack: `${openMediaProcessingUrl}/auditdetail/getshelvesonarack`,
      getcompleteddistributionlistsforcustomer: `${openMediaProcessingUrl}/auditdetail/getcompleteddistributionlistsforcustomer`,
      getphysicalvaults: `${openMediaProcessingUrl}/auditdetail/getphysicalvaults`,
      getrows: `${openMediaProcessingUrl}/auditdetail/getrows`,
      getracks: `${openMediaProcessingUrl}/auditdetail/getracks`,
      getauditdetails: `${openMediaProcessingUrl}/auditdetail/getauditdetails`,
      omauditcreate: `${openMediaProcessingUrl}/auditdetail/omauditcreate`,
      getomauditpagedetailsinfo: `${openMediaProcessingUrl}/auditdetail/getomauditpagedetailsinfo`,
      updateomaudit: `${openMediaProcessingUrl}/auditdetail/updateomaudit`,
      cancelomaudit: `${openMediaProcessingUrl}/auditdetail/cancelomaudit`,
      getpersonnellist: `${openMediaProcessingUrl}/auditdetail/getpersonnellist`,
      getsystemglobalattributes: `${openMediaProcessingUrl}/auditdetail/getsystemglobalattributes`,
      getcorpsettings: `${openMediaProcessingUrl}/auditdetail/getcorpsettings`,
      matchlistinsertion: `${openMediaProcessingUrl}/auditdetail/matchlistinsertion`,
      openmediaauditpublish: `${openMediaProcessingUrl}/auditdetail/openmediaauditpublish`,
      getcustomdata: `${openMediaProcessingUrl}/auditdetail/getcustomdata`,
      activeomtypelist: `${openMediaProcessingUrl}/auditdetail/activeomtypelist`,
      getlabeltemplates: `${openMediaProcessingUrl}/auditdetail/getlabeltemplates`,
      matchlist: `${openMediaProcessingUrl}/auditdetail/matchlist`,
      matchomauditdetails: `${openMediaProcessingUrl}/auditdetail/matchomauditdetails`,
      retrieveactivelogicalvaultsforcustomer: `${openMediaProcessingUrl}/auditdetail/retrieveactivelogicalvaultsforcustomer`
    },
    research: {
      getlogicalvaultsforcustomer: `${openMediaProcessingUrl}/research/getlogicalvaultsforcustomer`,
      getdrpcodes: `${openMediaProcessingUrl}/research/getdrpcodes`,
      getexchangestatusvalues: `${openMediaProcessingUrl}/research/getexchangestatusvalues`,
      getclosedcontainercontents: `${openMediaProcessingUrl}/research/getclosedcontainercontents`,
      getopenmediaandmatrixforcustomer: `${openMediaProcessingUrl}/research/getopenmediaandmatrixforcustomer`,
      getopenmediahistory: `${openMediaProcessingUrl}/research/getopenmediahistory`,
      createinventorytextfile: `${openMediaProcessingUrl}/research/createinventorytextfile`,
      getlogicalvaultcodesforaudits: `${openMediaProcessingUrl}/research/getlogicalvaultcodesforaudits`,
      updateOpenMediaAttributesAndAssociations: `${openMediaProcessingUrl}/research/updateopenmediaattributesandassociations`,
      searchCustomerandVolserList: `${openMediaProcessingUrl}/research/searchcustomerandvolserlist`
    }
  },
  labelPrinterSetup: {
    printerNamelist: `${labelPrintingBaseUrl}/labelprinting/labelprinterrows`,
    labelStockList: `${labelPrintingBaseUrl}/labelprinting/labelstockdimensions`,
    labelprinters: `${labelPrintingBaseUrl}/labelprinting/labelprinters`,
    updatePrinter: `${labelPrintingBaseUrl}/labelprinting/updatelabelprinterrow`,
    addPrinter: `${labelPrintingBaseUrl}/print/addprinter`,
    updateCloudPrinter: `${labelPrintingBaseUrl}/print/updateprinter`,
    deletePrinter: `${labelPrintingBaseUrl}/labelprinting/deletelabelprinterrow`,
    deleteCloudPrinter: `${labelPrintingBaseUrl}/print/deleteprinter`,
    cloudPrint: `${labelPrintingBaseUrl}/print/print`
  },
  personnalHistory: {
    getPersonalHistory: `${authorizationBaseUrl}/authorization/changehistoryofpersonnelrecord`
  },
  printTapeLabel: {
    getLabelPrinter: `${labelPrintingBaseUrl}/labelprinting/labelprinterrows`,
    getLabelFormat: `${labelPrintingBaseUrl}/labelprinting/labelformats`,
    audit: `${labelPrintingBaseUrl}/labelprinting/labelprintaudit`,
    print: `${labelPrintingBaseUrl}/print/print`
  },
  customerFileSearch: {
    getactivedistricts: `${customerFileSearchBaseUrl}/getactivedistricts`,
    getcustomerfiledata: `${customerFileSearchBaseUrl}/getcustomerfilesectionsfordaterange`,
    updatefilesectionstatus: `${customerFileSearchBaseUrl}/updatefilesectionstatus`,
    reactivatefilesection: `${customerFileSearchBaseUrl}/reactivatefilesection`,
    deactivatefilesection: `${customerFileSearchBaseUrl}/deactivatefilesection`
  },
  picking: {
    populatedailypickingtasks: `${openMediaProcessingUrl}/pickreport/populatedailypickingtasks`
  },
  openMediaAuditSearch: {
    inventorydropdowns: `${openMediaProcessingUrl}/common/inventorydropdowns`,
    openmediaauditsearch: `${openMediaProcessingUrl}/auditdetail/openmediaauditsearch`
  },
  containerProcessing: {
    general: {
      distribution: {
        returnbatchrecords: `${containerProcessingBaseUrl}/distribution/returnbatchrecords`,
        populatecontainerprocessingdistributiontab: `${containerProcessingBaseUrl}/distribution/populatecontainerprocessingdistributiontab`,
        validatedistributionnotarrival: `${containerProcessingBaseUrl}/distribution/validatedistributionnotarrival`,
        validatebatchimport: `${containerProcessingBaseUrl}/distribution/validatebatchimport`,
        validatebatchimporttwo: `${containerProcessingBaseUrl}/distribution/validatebatchimporttwo`,
        uploaderrors: `${containerProcessingBaseUrl}/distribution/uploaderrors`,
        distributeallcontainersbatch: `${containerProcessingBaseUrl}/distribution/distributeallcontainersbatch`,
        notarrivalbatch: `${containerProcessingBaseUrl}/distribution/notarrivalbatch`,
        getcommondata: `${containerProcessingBaseUrl}/distribution/getcommondata`
      }
    },
    reassaignTransport: {
      checkcustomeractivetransports: `${containerProcessingBaseUrl}/incompletestops/checkcustomeractivetransports`,
      getalltransportlist: `${containerProcessingBaseUrl}/transportlist/getalltransportlist`,
      reassigntransportcontents: `${containerProcessingBaseUrl}/incompletestops/reassigntransportcontents`
    },
    getRunDates: `${containerProcessingBaseUrl}/incompletestops/getrundates`,
    requestPrintUpdateSp: `${containerProcessingBaseUrl}/picking/updateprinterflag`,
    getLocalDateTime: `${containerProcessingBaseUrl}/transportlist/getlocaldatetime`,
    getReturnlistofruns: `${containerProcessingBaseUrl}/picking/returnlistofruns`,
    getConditionalFlag: `${containerProcessingBaseUrl}/picking/returnconditionflags`,
    getDetermineExchangeStatusId: `${containerProcessingBaseUrl}/picking/determineexchangestatusid`,
    getRunRule: `${containerProcessingBaseUrl}/picking/runrule`,
    getReturnRun: `${containerProcessingBaseUrl}/runprep/returncontainerlistingruninfo`,
    getDetermineUnassociatedContainer: `${containerProcessingBaseUrl}/picking/determineunassociatedcontainer`,
    getPerformMediaLifeCycleFunctions: `${containerProcessingBaseUrl}/picking/performmedialifecyclefunctions`,
    returncontaineraudits: `${containerProcessingBaseUrl}/incompletestops/returncontaineraudits`,
    mediaTypeAndDescription: `${containerProcessingBaseUrl}/incompletestops/returnmediatypeanddescription`,
    runDates: `${containerProcessingBaseUrl}/incompletestops/getrundates`,
    localDateTime: `${containerProcessingBaseUrl}/transportlist/getlocaldatetime`,
    runs: `${containerProcessingBaseUrl}/picking/returnlistofruns`,
    getAllContainersForCustomer: `${containerProcessingBaseUrl}/incompletestops/getallcontainersforcustomer`,
    selectContainerTransactionsInfo: `${containerProcessingBaseUrl}/incompletestops/selectcontainertransactioninfo`,
    checkRunRule: `${containerProcessingBaseUrl}/picking/runrule`,
    getCommonDistrictSettings: `${containerProcessingBaseUrl}/distribution/getcommondata`,
    runPrep: {
      getrundates: `${containerProcessingBaseUrl}/incompletestops/getrundates`,
      getlocaldatetime: `${containerProcessingBaseUrl}/transportlist/getlocaldatetime`,
      returnlistofruns: `${containerProcessingBaseUrl}/picking/returnlistofruns`,
      determineexchangestatusid: `${containerProcessingBaseUrl}/picking/determineexchangestatusid`,
      vehicle: `${containerProcessingBaseUrl}/runprep/vehicle`,
      returnemployeedata: `${containerProcessingBaseUrl}/runprep/returnemployeedata`,
      runrule: `${containerProcessingBaseUrl}/picking/runrule`,
      returnunresolvedmissingdiscrepancies: `${containerProcessingBaseUrl}/runprep/returnunresolvedmissingdiscrepancies`,
      validatepicklists: `${containerProcessingBaseUrl}/picking/validatepicklists`,
      returncontainerlistingruninfo: `${containerProcessingBaseUrl}/runprep/returncontainerlistingruninfo`,
      retrieveallrundata: `${containerProcessingBaseUrl}/runprep/retrieveallrundata`,
      addawarenessofotherrequests: `${containerProcessingBaseUrl}/runprep/addawarenessofotherrequests`,
      getemployeedata: `${containerProcessingBaseUrl}/runprep/getemployeedata`,
      updaterunrecord: `${containerProcessingBaseUrl}/runprep/updaterunrecord`,
      runprep: `${containerProcessingBaseUrl}/transportlist/runprep`,
      updaterunstatus: `${containerProcessingBaseUrl}/runprep/updaterunstatus`
    },
    updateContainerAttributes: `${containerProcessingBaseUrl}/incompletestops/modifycontainerattributes`,
    returnCorrectiveActionRequests: `${containerProcessingBaseUrl}/incompletestops/returncorrectiveactionrequests`,
    returnactivedistricts: `${containerProcessingBaseUrl}/incompletestops/returnactivedistricts`,
    createContainerInventory: `${containerProcessingBaseUrl}/incompletestops/createcontainerinventory`,
    containerTransactionInsertion: `${containerProcessingBaseUrl}/label/insertcontainertransaction`,
    getLVMediaTypes: `${containerProcessingBaseUrl}/research/getlvmediatypes`,
    modifyContainerMediaType: `${containerProcessingBaseUrl}/incompletestops/modifycontainermediatype`
  },
  containerIssurance: {
    getLocalDateTime: `${containerProcessingBaseUrl}/transportlist/getlocaldatetime`,
    getMediaTypes: `${containerProcessingBaseUrl}/incompletestops/selectmediatypes`,
    getContainerIssuranceData: `${containerProcessingBaseUrl}/incompletestops/selectallcontainers`,
    getAllPrinters: `${containerProcessingBaseUrl}/transportlist/getcontainerissurence`,
    labelPrintAudit: `${containerProcessingBaseUrl}/incompletestops/insertlabelprintaudit`,
    containerUpdate: `${containerProcessingBaseUrl}/transportlist/containerupdate`,
    runRule: `${containerProcessingBaseUrl}/picking/runrule`,
    getBatchId: `${containerProcessingBaseUrl}/transportlist/batchinsert`,
    requestTwoUpdate: `${containerProcessingBaseUrl}/transportlist/requesttwoupdate`
  },
  containerAuditDetails: {
    savecontainerAuditDetail: `${containerProcessingBaseUrl}/incompletestops/createcontaineraudit`,
    getContainerAuditDetail: `${containerProcessingBaseUrl}/incompletestops/loadcontainerauditdetail`,
    updateContainerAuditDetail: `${containerProcessingBaseUrl}/incompletestops/updatecontaineraudit`,
    cancelContainerAudit: `${containerProcessingBaseUrl}/incompletestops/cancelcontaineraudit`,
    publishcontaineraudit: `${containerProcessingBaseUrl}/incompletestops/publishcontaineraudit`
  },
  incompleteStops: {
    getLocalDateTime: `${containerProcessingBaseUrl}/transportlist/getlocaldatetime`,
    findallincompletestops: `${containerProcessingBaseUrl}/incompletestops/findallincompletestops`,
    getServiceDates: `${containerProcessingBaseUrl}/runprep/getincompleteruns`,
    updateRunPositionCompletion: `${containerProcessingBaseUrl}/incompletestops/updaterunpositioncompletion`
  },
  customerServiceInformation: {
    getCustomerAndLocationInfo: `${customerInformationBaseUrl}/serviceinfo/customerandlocationinfo`,
    getActiveCatelogItemCode: `${customerInformationBaseUrl}/serviceinfo/activecatelogitemcode`,
    returnEmployeeData: `${containerProcessingBaseUrl}/runprep/returnemployeedata`,
    onHoldCustomer: `${customerInformationBaseUrl}/serviceinfo/getactivebranches`,
    inactiveCustomer: `${customerInformationBaseUrl}/serviceinfo/getcustomerbelongs`,
    saveCustomerDetails: `${customerInformationBaseUrl}/serviceinfo/containerlocationisertorupdate`,
    customerphysicalvaulttebleinsert: `${customerInformationBaseUrl}/serviceinfo/customerphysicalvaulttebleinsert`,
    customerphysicalvaulttebledelete: `${customerInformationBaseUrl}/serviceinfo/customerphysicalvaulttebledelete`,
    getSettingValues: `${customerInformationBaseUrl}/serviceinfo/getsettingvalues`,
    activeManageContainerCode: `${customerInformationBaseUrl}/serviceinfo/activemanagecontainercode`,
    getActivecustnumtodo: `${dashboardUrl}/dashboard/getactivecustnumtodo`
  },
  customerInformationSchedule: {
    customerLocationRowCount: `${customerInformationBaseUrl}/serviceinfo/customerlocationrowcount`,
    rotationScheduleExistsForCustomerId: `${customerInformationBaseUrl}/serviceinfo/rotationscheduleexistsforcustomerid`,
    allRotationSchedulesNames: `${customerInformationBaseUrl}/serviceinfo/allrotationschedulesnames`,
    isCustomerIdExist: `${customerInformationBaseUrl}/serviceinfo/iscustomeridexist`,
    customerServiceFrequencyInformation: `${customerInformationBaseUrl}/serviceinfo/customerservicefrequencyinformation`,
    cslsReturns: `${customerInformationBaseUrl}/serviceinfo/cslsreturns`,
    getCslChildren: `${customerInformationBaseUrl}/schedule/getcslchildren`,
    saveSchedule: `${customerInformationBaseUrl}/serviceinfo/insertsrequestedservicedatesandservicefrequencyrecord`,
    commentFieldUpdation: `${customerInformationBaseUrl}/serviceinfo/commentfieldupdation`,
    customeridValidRequsets: `${customerInformationBaseUrl}/serviceinfo/customeridvalidrequsets`,
    customerScheduleAndRouteCustomerSchedule: `${customerInformationBaseUrl}/serviceinfo/customerscheduleandroutecustomerschedule`,
    customerServiceFrequencyInformationOfCustomer: `${customerInformationBaseUrl}/serviceinfo/customerservicefrequencyinformationofcustomer`,
    promptuserTrueOrFalse: `${customerInformationBaseUrl}/serviceinfo/promptusertrueorfalse`,
    lastGenrunDate: `${customerInformationBaseUrl}/serviceinfo/lastgenrundate`,
    getCslCustinForTodo: `${dashboardUrl}/dashboard/getcslcustinfortodo`,
    getCslcustinForTodoSelectedCustomer: `${dashboardUrl}/dashboard/getcslcustinfortodoselectedcustomer`
  },
  disasterRecovery: {
    getEventTypes: `${disasterRecoveryBasrUrl}/disasterrecovery/dreventtype`,
    getStatusItems: `${disasterRecoveryBasrUrl}/disasterrecovery/dreventstatus`,
    fetchEventInformation: `${disasterRecoveryBasrUrl}/disasterrecovery/displaysdreventinformation`,
    customeridanddrInstruction: `${disasterRecoveryBasrUrl}/disasterrecovery/customeridanddrinstruction`,
    drinstructionSandDrevents: `${disasterRecoveryBasrUrl}/disasterrecovery/drinstructionsanddrevents`,
    getEmployeeData: `${disasterRecoveryBasrUrl}/disasterrecovery/employeedatasearch`,
    getPersonnelList: `${disasterRecoveryBasrUrl}/disasterrecovery/declareandrevisedrplan`,
    getAuthorizedby: `${requestBaseUrl}/request/authorizedby`,
    getSingleDRDetails: `${disasterRecoveryBasrUrl}/disasterrecovery/detailedinformationsingledr`,
    getDrInstructionDetails: `${disasterRecoveryBasrUrl}/disasterrecovery/drinstructionsanddrevents`,
    insertDrEvent: `${disasterRecoveryBasrUrl}/disasterrecovery/dreventinsert`,
    updateDrEvent: `${disasterRecoveryBasrUrl}/disasterrecovery/updatesingledrevents`,
    getmediastatussummary: `${disasterRecoveryBasrUrl}/disasterrecovery/getmediastatussummary`,
    mediaInboundComplete: `${disasterRecoveryBasrUrl}/disasterrecovery/mediainboundcomplete`,
    getCustomerLocations: `${requestBaseUrl}/request/getservicelocations`,
    getfilesectionnamesforcustlist: `${disasterRecoveryBasrUrl}/disasterrecovery/getfilesectionnamesforcustlist`,
    getCustomerlistMediaData: `${disasterRecoveryBasrUrl}/disasterrecovery/customerlistradiobutton`,
    getVaultMeadiaType: `${disasterRecoveryBasrUrl}/disasterrecovery/getvaultmeadiatype`,
    createRequest: `${disasterRecoveryBasrUrl}/disasterrecovery/createrequest`,
    manualEntry: {
      validateOpenMedia: `${disasterRecoveryBasrUrl}/disasterrecovery/omvalidatelist`,
      validateContainer: `${disasterRecoveryBasrUrl}/disasterrecovery/selectcontainercontainernumber`
    },
    getOMorContByInboundDate: `${disasterRecoveryBasrUrl}/disasterrecovery/getcontombyinbdate`,
    getListcontOmbyVault: `${disasterRecoveryBasrUrl}/disasterrecovery/getlistcontombyvault`,
    sendEmail: `${disasterRecoveryBasrUrl}/disasterrecovery/sendemail`,
    getFileSelectionName: `${disasterRecoveryBasrUrl}/disasterrecovery/getlistoffilesection`,
    getOmDrForFileSelection: `${disasterRecoveryBasrUrl}/disasterrecovery/getomindrforfilesection`,
    drEventPhoneFormat: `${disasterRecoveryBasrUrl}/disasterrecovery/dreventpnoneformat`
  },
  drpCode: {
    getDrpDropdown: `${disasterRecoveryBasrUrl}/disasterrecovery/getdrpforcustindistrict`,
    getDrpList: `${disasterRecoveryBasrUrl}/disasterrecovery/getthelistofomaccosiate`
  },
  routeManagement: {
    getUnassignedCslCount: `${customerInformationBaseUrl}/schedule/getunassignedcslcount`,
    getUnassignedCustomers: `${customerInformationBaseUrl}/routemanagement/returnunassignroutecustomerschedule`,
    getAssignedRoutes: `${customerInformationBaseUrl}/routemanagement/getallroutecustschedule`,
    insertRoutes: `${customerInformationBaseUrl}/schedule/insertroutecustomerscheduleandrequest`,
    updateRoutes: `${customerInformationBaseUrl}/routemanagement/updateroutecustomerschedule`
  },
  customerPreferences: {
    accessingEmployeeDistricts: `${customerInformationBaseUrl}/preferences/accessingemployeedistricts`,
    personnellist: `${customerInformationBaseUrl}/preferences/personnellist`,
    updatevalutstatusreportpreferences: `${customerInformationBaseUrl}/preferences/updatevalutstatusreportpreferences`,
    getPreferenceValues: `${customerInformationBaseUrl}/preferences/preferencevalues`,
    getCustomerPreferenceHistory: `${customerInformationBaseUrl}/preferences/getcustomerpreferencehistory`,
    updateCustomerPreferences: `${customerInformationBaseUrl}/preferences/updatecustomerpreferences`,
    getDestructionRequestStatus: `${customerInformationBaseUrl}/preferences/getdestructionrequestandfilesectionstatuscount`,
    getHistoryData: `${customerInformationBaseUrl}/preferences/getcustomerpreferencehistory`,
    rotationScheduleExistsForCustomerId: `${customerInformationBaseUrl}/serviceinfo/rotationscheduleexistsforcustomerid`,
    generatesNewSetOfDates: `${customerInformationBaseUrl}/serviceinfo/generatesnewsetofdates`,
    getTransportationVendor: `${customerInformationBaseUrl}/preferences/gettransportationvendor`
  },
  labelTemplateSearch: {
    getReturnLabelTemplates: `${customerInformationBaseUrl}/labeltemplate/getlabeltemplate`
  },
  labelTemplateMaintanence: {
    saveTemplate: `${customerInformationBaseUrl}/labeltemplate/returnlabeltemplate`,
    assignCustomers: `${customerInformationBaseUrl}/labeltemplate/assignlabeltemplatetocustomer`,
    unAssignCustomers: `${customerInformationBaseUrl}/labeltemplate/unassignlabeltemplate`,
    deleteTemplate: `${customerInformationBaseUrl}/labeltemplate/removelabeltemplate`
  },
  serviceWindows: {
    customerFrequencyExist: `${customerInformationBaseUrl}/serviceinfo/iscustomeridexist`,
    customerServiceFrequency: `${customerInformationBaseUrl}/serviceinfo/customerservicefrequencyinformation`,
    customerScheduleRoutes: `${customerInformationBaseUrl}/serviceinfo/customerscheduleandroutecustomerschedule`,
    customerScheduleInsert: `${customerInformationBaseUrl}/serviceinfo/customersheduleinsert`,
    customerGetServiceLocations: `${customerInformationBaseUrl}/serviceinfo/routecustomerschedule`,
    customerScheduleUpdate: `${customerInformationBaseUrl}/serviceinfo/updatecustomershedule`,
    customerScheduleDelete: `${customerInformationBaseUrl}/servicewindows/deletesinglecustomerscheduleandassociateddata`,
    getActiveCustnumWithNoScheduletodo: `${dashboardUrl}/dashboard/getactivecustnumwithnoscheduletodo`
  },
  logicalVaultDetail: {
    vaultNameDetails: `${logicalVaultBaseUrl}/logicalvault/listvaultsandcodes`,
    logicalVaultInfo: `${logicalVaultBaseUrl}/logicalvault/getlogicalvaultdetails`,
    logicalVaultSelectedMediaTypes: `${logicalVaultBaseUrl}/logicalvault/listassociatedmediatypes`,
    logicalVaultAvailableMediaTypes: `${logicalVaultBaseUrl}/logicalvault/getmediatypeforlogicalvault`,
    logicalVaultSelectMediaByProgramType: `${logicalVaultBaseUrl}/logicalvault/selectedmediatypebyprogramtype`,
    handlingCatalogItems: `${logicalVaultBaseUrl}/logicalvault/gethandlingcatalogitemsforcustomer`,
    activeCatalogItems: `${logicalVaultBaseUrl}/logicalvault/getactivecatalogitems`,
    createLogicalVaultRecord: `${logicalVaultBaseUrl}/logicalvault/createlogicalvaultrecord`,
    updateLogicalVaultRecord: `${logicalVaultBaseUrl}/logicalvault/updatelogicalvaulttable`,
    addMediaTypes: `${logicalVaultBaseUrl}/logicalvault/addlogvaultmediatypeassociation`,
    deleteMediaTypes: `${logicalVaultBaseUrl}/logicalvault/deletelogvaultmediatypeassociation`,
    deleteLogicalVault: `${logicalVaultBaseUrl}/logicalvault/deletelogicalvault`,
    renameLogicalVault: `${logicalVaultBaseUrl}/logicalvault/renamelogicalvaultcode`,
    checkOpenMediaAudits: `${logicalVaultBaseUrl}/logicalvault/checkopenmediaaudits`
  },
  discrepancyManagement: {
    getOpenMediaDiscrepanciessbwebandss: `${discrepancyManagementBaseUrl}/openmediadiscrepanciessbwebandss`,
    getOpenMediaLoadCantainerDiscrepancydetails: `${discrepancyManagementBaseUrl}/loadcontainerdiscrepancydetails`,
    operationsservicereference: `${discrepancyManagementBaseUrl}/operationsservicereference`,
    logicalVaults: `${discrepancyManagementBaseUrl}/activelogicalvaults`,
    listofruns: `${discrepancyManagementBaseUrl}/listofruns`,
    discrepancmanagementpageforsbweb: `${discrepancyManagementBaseUrl}/discrepancmanagementpageforsbweb`,
    correctiveactionrequesttypetabledata: `${discrepancyManagementBaseUrl}/correctiveactionrequesttypetabledata`,
    personnelinformation: `${discrepancyManagementBaseUrl}/personnelinformation`,
    openmediadiscrepancy: `${discrepancyManagementBaseUrl}/openmediadiscrepancy`,
    containerdiscupdate: `${discrepancyManagementBaseUrl}/containerdiscupdate`,
    loadcontainerdiscrepancydetails: `${discrepancyManagementBaseUrl}/loadcontainerdiscrepancydetails`,
    containeruploaderror: `${discrepancyManagementBaseUrl}/containeruploaderror`
  },
  vaultConfiguration: {
    listPhysicalVaultsInBranch: `${vaultConfigurationUrl}/vaultconfiguration/physicalvaultlist`,
    listRowsInVaultOrBranch: `${vaultConfigurationUrl}/vaultconfiguration/rowcontinedphysicalcault`,
    listRacksInRowOrVault: `${vaultConfigurationUrl}/vaultconfiguration/listofracks`,
    getallshelvesonrack: `${vaultConfigurationUrl}/vaultconfiguration/getallshelvesonrack`,
    shelfPropertiesList: `${vaultConfigurationUrl}/vaultconfiguration/shelfpropertieslist`,
    formFactorsWithShelf: `${vaultConfigurationUrl}/vaultconfiguration/formfactorswithshelf`,
    formFactors: `${vaultConfigurationUrl}/vaultconfiguration/formfactors`,
    physicalvault: `${vaultConfigurationUrl}/vaultconfiguration/physicalvault`,
    newrowcreation: `${vaultConfigurationUrl}/vaultconfiguration/newrowcreation`,
    createnewrack: `${vaultConfigurationUrl}/vaultconfiguration/createnewrack`,
    newshelfcreation: `${vaultConfigurationUrl}/vaultconfiguration/newshelfcreation`,
    deletePhysicalVault: `${vaultConfigurationUrl}/vaultconfiguration/deleteexistingphysicalvaultand`,
    deleteRow: `${vaultConfigurationUrl}/vaultconfiguration/deleteexistingrow`,
    deleteRack: `${vaultConfigurationUrl}/vaultconfiguration/deleteexstingrack`,
    shelfDeletion: `${vaultConfigurationUrl}/vaultconfiguration/shelfdeletion`,
    releaseVault: `${vaultConfigurationUrl}/vaultconfiguration/releasevault`,
    releaseRow: `${vaultConfigurationUrl}/vaultconfiguration/releaserow`,
    releaseRack: `${vaultConfigurationUrl}/vaultconfiguration/releaserack`,
    releaseShelf: `${vaultConfigurationUrl}/vaultconfiguration/releaseshelf`,
    shelpupdation: `${vaultConfigurationUrl}/vaultconfiguration/shelpupdation`,
    insertDeleteFormFactorShelf: `${vaultConfigurationUrl}/vaultconfiguration/insertdeleteformfactorshelf`,
    activeLogicalVaults: `${vaultConfigurationUrl}/vaultconfiguration/activelogicalvaults`,
    logicalvaultsForCustomers: `${vaultConfigurationUrl}/vaultconfiguration/logicalvaultsforcustomers`,
    checkAuditActive: `${vaultConfigurationUrl}/vaultconfiguration/checkauditactive`,
    physicalVaultUpdation: `${vaultConfigurationUrl}/vaultconfiguration/physicalvaultupdation`,
    existingRackUpdation: `${vaultConfigurationUrl}/vaultconfiguration/existinrackupdation`,
    existingRowUpdation: `${vaultConfigurationUrl}/vaultconfiguration/existingrowupdation`,
    getGlobalAttributeValue: `${logicalVaultBaseUrl}/logicalvault/getglobalattributevalue`
  },
  assignShelves: {
    getPhysicalVualt: `${openMediaProcessingVaultValidation}/logicalvault/listphysicalvaultsinbranch`,
    getRowList: `${openMediaProcessingVaultValidation}/logicalvault/listrowsinvaultorbranch`,
    getRack: `${openMediaProcessingVaultValidation}/logicalvault/listracksinroworvault`,
    getAssignedShelves: `${openMediaProcessingVaultValidation}/logicalvault/listassignedshelves`,
    getAvailableShelves: `${openMediaProcessingVaultValidation}/logicalvault/listavailableshelves`,
    saveShelves: `${openMediaProcessingVaultValidation}/logicalvault/saveshelfchanges`,
    releaseSLots: `${openMediaProcessingVaultValidation}/logicalvault/releaseshelfslots`,
    shelfDeletion: `${vaultConfigurationUrl}/vaultconfiguration/shelfdeletion`
  },
  customerSearchInformation: {
    getactivedistricts: `${customerInformationBaseUrl}/preferences/getactivedistricts`,
    releaseSLots: `${openMediaProcessingVaultValidation}/logicalvault/releaseshelfslots`,
    returncustomermoveprocess: `${moduleCustomerMoveUrl}/move/returncustomermoveprocess`,
    verifyemployeeid: `${moduleCustomerMoveUrl}/move/verifyemployeeid`,
    getcustomermoverelateddata: `${moduleCustomerMoveUrl}/move/getcustomermoverelateddata`,
    updateStatus: `${moduleCustomerMoveUrl}/move/updateactive`
  },
  dashboard: {
    todo: {
      todoHighPriority: `${dashboardUrl}/dashboard/returnsaggregatesfortodohighpriorityscreen`,
      todoCustomer: `${dashboardUrl}/dashboard/returnsaggregatesfortodocustomerscreen`,
      getStartAndEndDate: `${requestBaseUrl}/request/getstartandenddate`,
      todoOpenMedia: `${dashboardUrl}/dashboard/returnsaggregatesfortodoopenmediascreen`,
      todoContainers: `${dashboardUrl}/dashboard/returnsaggregatesfortodocontainersscreen`,
      todoRequestRuns: `${dashboardUrl}/dashboard/returnsaggregatesfortodorequest`,
      todoRequestRunsGetStartAndEndDate: `${dashboardUrl}/dashboard/getstartenddatefromrequest`,
      todoRequestRunsGetRequestData: `${requestBaseUrl}/request/getrequestdatatodo`,
      todoCustomerTOConfirm: `${requestBaseUrl}/destruction/getdestructmediarequests`,
      todoRunsNotCompleted: `${dashboardUrl}/dashboard/getminrundatetodo`,
      todoFindallincompletestops: `${containerProcessingBaseUrl}/incompletestops/findallincompletestops`,
      returncustomerlocationinfo: `${containerProcessingBaseUrl}/incompletestops/returncustomerlocationinfo`,
      todoDrEventsInProgress: `${disasterRecoveryBasrUrl}/disasterrecovery/getdreventinfotodo`,
      todoCarDiscrepency: `${dashboardUrl}/dashboard/returnsaggregatesfortodocarscreen`,
      getCarsSignOffUrl: `${correctiveActionRequest}/details/getallcarids`
    },
    onHoldCustomers: `${dashboardUrl}/dashboard/getonholdcustomerstodo`,
    insertNewFeedBack: `${dashboardUrl}/dashboard/insertnewfeedback`
  },
  customerMoveDetail: {
    sourceBranchUrl: `${customerInformationBaseUrl}/preferences/getactivedistricts`,
    customerMoveUserAuthorityUrl: `${moduleCustomerMoveUrl}/move/verifyemployeeid`,
    checkIsCustomerMoveExistsUrl: `${moduleCustomerMoveUrl}/move/getcustomermoverelateddata`,
    returnCustomerMoveProcessUrl: `${moduleCustomerMoveUrl}/move/returncustomermoveprocess`,
    customerMoveEventIUrl: `${moduleCustomerMoveUrl}/move/populatesource`,
    customerMoveEventLUrl: `${moduleCustomerMoveUrl}/move/getcustomermoveeventdetails`,
    generateReconcilliationUrl: `${moduleCustomerMoveUrl}/move/genaratereconciliation`,
    getCustomerMoveWrapUrl: `${moduleCustomerMoveUrl}/move/getcustomermoverelateddatafromotherdistict`,
    customerValidationWrapLUrl: `${moduleCustomerMoveUrl}/move/validatecmucustomer`
  },
  correctiveActionRequest: {
    failurePointUrl: `${correctiveActionRequestUrl}/search/fillfailurepointcombofromdb`,
    rootCauseUrl: `${correctiveActionRequestUrl}/search/fillrootcausecombofromdb`,
    statusUrl: `${correctiveActionRequestUrl}/search/fillstatuscombofromdb`,
    typesUrl: `${correctiveActionRequestUrl}/search/loadcarsearchformandpopulatedefaultfiltercriteria`,
    carSearchUrl: `${correctiveActionRequestUrl}/search/performcarsummarysearchandreturnmatchingcars`,
    getType: `${correctiveActionRequest}/details/fillstatuscombofromdb`,
    sendEmail: `${correctiveActionRequest}/details/sendcaremail`,
    insertCar: `${correctiveActionRequest}/details/insertnewcar`,
    updateResolution: `${correctiveActionRequest}/details/updatecarresolutionininvestigatedstatus`,
    getCarDetails: `${correctiveActionRequest}/details/displaycorrectiveactionrequestdetailspage`,
    validatepicklists: `${correctiveActionRequest}/details/validatevolsersinvspicklist`,
    validateContainer: `${correctiveActionRequest}/details/containerrecord`,
    fillRootCauseCombo: `${correctiveActionRequestUrl}/search/fillrootcausecombofromdb`,
    correctiveActionRequesttypefailurePoints: `${correctiveActionRequestUrl}/details/correctiveactionrequesttypefailurepoints`,
    searchEmployeeData: `${correctiveActionRequestUrl}/details/searchemployeedata`,
    validateEmployeePassword: `${correctiveActionRequestUrl}/details/validateemployeepassword`,
    updatecarininitiatedstatus: `${correctiveActionRequestUrl}/details/updatecarininitiatedstatus`
  },
  reports: {
    customerScheduleExpiry: `${reportsUrl}/customerscheduleexpiration/customerscheduleexpiry`,
    customerSearch: `${reportsUrl}/common/customersearch`,
    listactiveTransports: `${reportsUrl}/temporarytransport/listactivetransports`,
    lastGenRunDate: `${customerInformationBaseUrl}/serviceinfo/lastgenrundate`,
    getRunDates: `${reportsUrl}/containerdistributionlist/getrundates`,
    listRunsByDate: `${reportsUrl}/runarrival/listrunsbydate`,
    getSettingValues: `${reportsUrl}/billableactivitycounts/getsettingvalues`,
    getRequestHelp: `${requestHelpUrl}/help/customerpreferencevaluesandhistoryinformation`,
    getRoadNetAdhocData: `${reportsUrl}/vaultmanagement/roadNetadhocrequest`,
    openMediaContainerInfo: `${reportsUrl}/createinventoryextract/openmediacontainerinfo`,
    billingActivityCountExport: `${reportsUrl}/billableactivitycounts/dailybillablecounts`,
    monthlyBillableActivityCountsExport: `${reportsUrl}/vaultmanagement/getcontainerandopenmediavaultcount`,
    getCapacityBillingCount: `${reportsUrl}/vaultmanagement/getcapacitybillingcount`,
    getCapacityBillingCountAll: `${reportsUrl}/vaultmanagement/getcapacitybillingcountbranch`
  },

  notificationConfig: {
    getDropdownListValues: `${notificationConfigBaseUrl}/getdropdownlistvalues`,
    insertRecordIntoAlert: `${notificationConfigBaseUrl}/insertrecordintoalert`,
    getallvalidemployeeresult: `${notificationConfigBaseUrl}/getallvalidemployeeresult`
  },
  vaultStatusReport: {
    queryvsrcustomerswithmostfrequentdiscrepancies: `${openMediaProcessingUrl}/auditdetail/queryvsrcustomerswithmostfrequentdiscrepancies`,
    getvsraccountanddiscrepancysummary: `${openMediaProcessingUrl}/auditdetail/getvsraccountanddiscrepancysummary`,
    getvsropenmediaotherexceptions: `${openMediaProcessingUrl}/auditdetail/getvsropenmediaotherexceptions`,
    queryvsrcustomerswithmostdiscrepancies: `${openMediaProcessingUrl}/auditdetail/queryvsrcustomerswithmostdiscrepancies`,
    queryvsrcustomerswithmostduplicatediscrepanciesatdistribution: `${openMediaProcessingUrl}/auditdetail/queryvsrcustomerswithmostduplicatediscrepanciesatdistribution`,
    queryvsrcustomerswithmosttransactions: `${openMediaProcessingUrl}/auditdetail/queryvsrcustomerswithmosttransactions`,
    exceptiondetails: `${vaultStatusReport}/status/exceptiondetails`,
    exceptiondetailsothervaultstatusreport: `${vaultStatusReport}/status/exceptiondetailsothervaultstatusreport`
  }
};
