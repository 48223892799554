import { Button } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";

const CustomerServiceInformationAccountClosureModal = ({
  accountChangeConfirmationModalShow,
  onNoClick,
  accountChangeConfirmationMsg,
  onOkClick
}) => {
  return (
    <CommonModal
      open={accountChangeConfirmationModalShow}
      title={"Confirm"}
      body={`${accountChangeConfirmationMsg}`}
      buttons={
        <>
          <Button variant="outlined" onClick={() => onOkClick()}>
            {t("common.yes")}
          </Button>
          <Button variant="contained" onClick={() => onNoClick()}>
            {t("common.no")}
          </Button>
        </>
      }
    />
  );
};

export default CustomerServiceInformationAccountClosureModal;
