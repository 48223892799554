import i18n from "i18n";
import { datesForCorrectiveSearch } from "utils/constants";
import * as yup from "yup";

export const filterSchema = yup.object().shape({
  fromDate: yup
    .date()
    .nullable()
    .typeError(i18n.t("correctiveActionRequestSearch.invalidDate"))
    .test(
      "valid-date",
      i18n.t("correctiveActionRequestSearch.invalidDate"),
      function (fromDate) {
        const normalizeDate = (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate());
        
        if (!fromDate || isNaN(fromDate.getTime())) return true;
        
        return (
          normalizeDate(fromDate) >=
            normalizeDate(new Date(datesForCorrectiveSearch.minDate)) &&
          normalizeDate(fromDate) <= normalizeDate(new Date())
        );
      }
    ),
  
  toDate: yup
    .date()
    .nullable()
    .typeError(i18n.t("correctiveActionRequestSearch.invalidDate")) 
    .test(
      "valid-date",
      i18n.t("correctiveActionRequestSearch.invalidDate"), 
      function (toDate, context) {
        const fromDate = context?.parent?.fromDate;
        const normalizeDate = (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate());

        if (!toDate || isNaN(toDate.getTime())) return true;

        if (fromDate && !isNaN(fromDate.getTime())) {
          if (normalizeDate(toDate) < normalizeDate(fromDate)) {
            return this.createError({
              path: "toDate",
              message: i18n.t("correctiveActionRequestSearch.endDateGreaterThanStartDate"),
            });
          }
        }

        return (
          normalizeDate(toDate) >=
            normalizeDate(new Date(datesForCorrectiveSearch.minDate)) &&
          normalizeDate(toDate) <= normalizeDate(new Date())
        );
      }
    )
});