import { Grid } from "@mui/material";
import { t } from "i18next";
import ToDoItem from "./ToDoItem";

const HighPriority = ({
  openMediaMissingAtPicking,
  requestsNotCompleted,
  unassignedRequests,
  containersMissingatStaging,
  containersMissingatArrival,
  inventoryPickedError,
  carsSignOffCount,
  onCardClick
}) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.openMediaMissingAtPicking")}
          number={openMediaMissingAtPicking}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.requestsNotCompleted")}
          number={requestsNotCompleted}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.unassignedRequests")}
          number={unassignedRequests}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.carsRequiringSignOff")}
          number={carsSignOffCount}
          onCardClick={onCardClick}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.containersMissingatStaging")}
          number={containersMissingatStaging}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.containersMissingatArrival")}
          number={containersMissingatArrival}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.inventoryPickedError")}
          number={inventoryPickedError}
          onCardClick={onCardClick}
        />
      </Grid>
    </>
  );
};

export default HighPriority;
