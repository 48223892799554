import { createSlice } from "@reduxjs/toolkit";
import { ALL_SELECTION } from "utils/constants";

const initialFormValues = {
  auditId: "",
  auditType: "",
  dueDate: "",
  customer: "",
  requestedPersonnelId: "",
  loggedInEmployeeId: "",
  vault_from: ALL_SELECTION?.value,
  vault_to: ALL_SELECTION?.value,
  row_from: ALL_SELECTION?.value,
  row_to: ALL_SELECTION?.value,
  rack_from: ALL_SELECTION?.value,
  rack_to: ALL_SELECTION?.value,
  shelf_from: ALL_SELECTION?.value,
  shelf_to: ALL_SELECTION?.value,
  comment: "",
  requestedBy: ""
};

const initialAuthValues = {
  branch: "",
  customer: "",
  personnel: "",
  authNumber: "",
  challengeQuestion: "",
  challengeResponse: ""
};

const containerAuditDetailsSlice = createSlice({
  name: "containerAuditDetails",
  initialState: {
    formValues: initialFormValues,
    authFormValues: initialAuthValues,
    searchResults: [],
    isLoading: false,
    throwError: false,
    throwErrorMsg: "",
    isAuthenticated: false,
    throwWarning: false,
    throwWarningMsg: "",
    isContainerReload: true
  },
  reducers: {
    fetchDataRequest(state) {
      state.isLoading = true;
      state.throwError = null;
    },
    fetchDataSuccess(state, action) {
      state.isLoading = false;
      state.searchResults = action.payload;
    },
    fetchDataFailure(state, action) {
      state.isLoading = false;
      state.throwError = true;
      state.throwErrorMsg = action.payload;
    },

    updateFormValues(state, action) {
      state.formValues = { ...state.formValues, ...action.payload };
    },

    updateAuthFormValues(state, action) {
      state.authFormValues = { ...state.authFormValues, ...action.payload };
    },
    resetForm(state) {
      state.formValues = initialFormValues;
      state.searchResults = [];
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = "";
    },
    resetAuthForm(state) {
      state.authFormValues = initialAuthValues;
      state.searchResults = [];
      state.isLoading = false;
      state.throwError = false;
      state.throwErrorMsg = "";
    },
    setSearchResults(state, action) {
      state.searchResults = action.payload;
      state.throwError = false;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.throwError = true;
      state.throwErrorMsg = action.payload;
    },
    setWarning(state, action) {
      state.throwWarning = true;
      state.throwWarningMsg = action.payload;
    },
    clearError(state) {
      state.throwError = false;
      state.throwErrorMsg = "";
    },
    clearWarning(state) {
      state.throwWarning = false;
      state.throwWarningMsg = "";
    },
    updateAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },
    setIsContainerReload(state, action) {
      state.isContainerReload = action?.payload;
    }
  }
});

export const {
  updateFormValues,
  resetForm,
  setSearchResults,
  setLoading,
  setError,
  clearError,
  fetchDataRequest,
  fetchDataSuccess,
  fetchDataFailure,
  updateAuthFormValues,
  resetAuthForm,
  updateAuthentication,
  setWarning,
  clearWarning,
  setIsContainerReload
} = containerAuditDetailsSlice.actions;

export default containerAuditDetailsSlice.reducer;
