export const MAIN_DIV_STYLES = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "100px"
};

export const SB_LOGO_WIDTH = {
  width: "260px"
};

export const RESTRICTED_IMG_WIDTH = {
  width: "200px",
  marginTop: "40px"
};

export const RESTRICTED_TEXT = {
  marginTop: "40px"
};

export const RESTRICTED_DESCRIPTION = {
  width: "620px",
  textAlign: "center",
  marginTop: "40px"
};

export const RESTRICTED_MSG = {
  marginTop: "40px"
};
