import dayjs from "dayjs";
import { getDocs, query, where } from "firebase/firestore";
import AxiosInstance from "services/axiosService";
import {
  api_results_collection_resultset0,
  api_results_collection_resultset1,
  api_results_collection_resultset2,
  media_type_collection,
  message_by_code_collection
} from "services/firebase";
import {
  DUPLICATES_TXT,
  EXCHANGE_LOCATION_TYPE_SL,
  FIREBASE_FIELD_NAMES,
  N_CHECKER,
  POSSIBLE_MATCHES_TXT,
  Y_CHECKER
} from "utils/constants";
import {
  MESSAGE_63857,
  NO_LONGER_TIED_TO_REQUEST,
  SF_ASSIGN_CODE,
  SF_REMOVE_CODE,
  SYSTEM_FUNCTION_ID_SAVE
} from "utils/constants/request-module/MediaRequestDetailConstants";
import {
  getAuthenticatedUserBranch,
  getAuthenticatedUserId,
  getLanguageId
} from "utils/helpers";

/**
 * @module requestDetail request media detail feature specific requests module
 */
export const requestDetail = {
  /**
   * Check authorization of a request
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.requestTypeId id of the selected requestTypeId
   * @param {string} props.requestActionId id of the selected requestActionId
   * @param {string} props.customerId id of the selected customerId
   * @param {string} props.personnelId id of the selected personnelId
   * @returns
   */
  checkRequestAuthorization: async ({
    requestTypeId,
    requestActionId,
    customerId,
    personnelId
  }) => {
    var requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_type_id: requestTypeId,
      request_action_id: requestActionId,
      customer_id: customerId,
      personnel_id: personnelId
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/determineauthorizationrequirement`,
      requestBody
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },

  /**
   * Get scheduled service dates in a date range based on the customer
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @param {string} props.fromDate search from date
   * @param {string} props.toDate search to date
   * @returns
   */
  getScheduledServiceDates: async ({
    customerId,
    fromDate,
    toDate,
    addonCutoffFlag
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      from_date: fromDate,
      to_date: toDate,
      reschedule_flag: N_CHECKER,
      addon_cutoff_flag: addonCutoffFlag
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnrequestidandscheduleddateforcustomer`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => {
          response.push(doc.data());
        });
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get service auth comments based on the customer
   *
   * @param {string} customerId id of the selected customer
   * @returns
   */
  getServiceAuthComments: async (customerId) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      exchange_location_type_code: EXCHANGE_LOCATION_TYPE_SL,
      service_frequency_flag: Y_CHECKER
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getserviceauthcomments`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response[0];
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get pricing contract active flag for Managed container visibility
   *
   * @param {string} customerId id of the selected customer
   * @returns
   */
  getPricingContractFlag: async (customerId) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId?.customerId || ""
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnspricingcontractitemlist`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response[0];
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get addon cutoff values of the customer
   *
   * @param {string} customerId id of the selected customer
   * @returns
   */
  getAddonCutoffValues: async (customerId) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getaddoncutoffvaluesforcustomer`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => response.push(doc.data()));
      } else {
        response.push({
          num_day_of_week_code: 0,
          addoncutoffflag: "false",
          previousdayflag: "false",
          tomorrow_service_count: 0
        });
      }
      // return the results
      return response[0];
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get billing system of the current branch
   *
   * @returns
   */
  getBranchBillingSystem: async () => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      district_id: getAuthenticatedUserBranch()
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_BILLING}/status/getcurrentbranchbillingsystem`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get eligible service locations of the customer
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @param {string} props.dayOfWeekCode day of week code
   * @returns
   */
  getEligibleRequestLocations: async ({ customerId, dayOfWeekCode }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      day_Of_Week: dayOfWeekCode
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/geteligiblereqlocations`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get all trip types based on customer and request type
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.customerId id of the selected customer
   * @param {string} props.requestTypeId id of the selected request type
   * @returns
   */
  getAllTripTypes: async ({ customerId, requestTypeId }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      request_type_id: requestTypeId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/gettriptypesfordistrictandrequesttype`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get all service locations of a customer
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @returns
   */
  getAllServiceLocations: async ({ customerId }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getservicelocations`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get all logical valuts of a customer
   *
   * @param {object} props
   * @param {string} props.customerId id of the customer
   * @param {string} props.mediaProgramType media program type
   * @returns
   */
  getAllLogicalVaults: async ({ customerId, mediaProgramType }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      media_program_type: mediaProgramType
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getlogicalvaultrecords`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   *
   * @param {object} props
   * @param {number} props.customerId id of the customer
   * @param {string} props.volserList volsers list
   * @param {string} props.isSSIEFlag is_SSIE flag
   * @param {number} props.localeId Locale ID
   * @param {string} props.atIronMtnStatus At iron mountain status
   * @param {string} props.inTransitStatus In transti status
   * @param {string} porps.mediaNotFoundStatus Media not found status
   * @param {string} props.possibleMatchesStatus Possible matches status
   * @param {string} props.duplicatesStatus Duplicates status
   * @param {string} props.sentToText Sent to text
   * @param {string} props.findMdrFlag Find MDR flag
   * @param {string} props.mediaInMdrStatus Media in MDR status
   * @param {string} props.mediaInMdrFollowup Media in MDR followup
   * @param {string} props.volsersInMdr Volsers in MDR
   * @returns
   */
  validateDeliverMediaOpenMediaNumber: async ({
    customerId,
    volserList,
    atIronMtnStatus = "",
    inTransitStatus = "",
    notAtIronMtnStatus = "",
    mediaNotFoundStatus = "",
    sentToText = "",
    mediaInMdrStatus = "",
    mediaInMdrFollowup = ""
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      volser_list: volserList,
      is_ssie_flag: N_CHECKER,
      locale_id: "1",
      language_locale_id: "1",
      at_iron_mtn_status: atIronMtnStatus,
      in_transit_status: inTransitStatus,
      not_at_iron_mtn_status: notAtIronMtnStatus,
      media_not_found_status: mediaNotFoundStatus,
      possible_matches_status: POSSIBLE_MATCHES_TXT,
      duplicates_status: DUPLICATES_TXT,
      sent_to_text: sentToText,
      find_mdr_flag: Y_CHECKER,
      media_in_mdr_status: mediaInMdrStatus,
      media_in_mdr_followup: mediaInMdrFollowup
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/validatevolsersinpicklist`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = { medias: [], volsersInMdr: [], errors: [] };
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        const collectionSnap1 = await getDocs(
          api_results_collection_resultset1(cfResponse.docId)
        );
        const collectionSnap2 = await getDocs(
          api_results_collection_resultset2(cfResponse.docId)
        );

        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.medias.push(doc.data()));
        collectionSnap1.forEach((doc) =>
          response.volsersInMdr.push(doc.data())
        );
        collectionSnap2.forEach((doc) => response.errors.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get possible matches of a volser
   *
   * @param {object} props
   * @param {number} props.customerId id of the customer
   * @param {string} props.volserList volsers list
   * @param {string} props.selectedVolser selected volser
   * @param {string} props.followUp follow up state of the volser needs to be checked (Duplicates/Possible Matches)
   * @param {number} props.maxRowsReturned max rows returned
   * @returns
   */
  getOpenMediaPossibleMatches: async ({
    customerId,
    volserList,
    selectedVolser,
    followUp = POSSIBLE_MATCHES_TXT,
    maxRowsReturned = ""
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      volser_list: volserList,
      selected_volser: selectedVolser,
      follow_up: followUp,
      max_rows_returned: maxRowsReturned,
      is_ssie_flag: Y_CHECKER,
      language_locale_id: getLanguageId()
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnsdeuplicatevolserdetails`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Validate the entered container number is exists and return the data for that container
   *
   * @param {object} props
   * @param {string} props.customerId id of the customer
   * @param {string} props.containerNumber container number
   * @param {string} props.logicalVaultId id of the logical vault
   * @param {string} props.mediaProgramTypeId id of the media program type
   * @param {string} props.secureSyncFlag flag for SecureSync
   * @param {string} props.redeliveryFlag flag for redelivery
   * @param {string} props.newRequestTypeId id of the request type
   * @returns
   */
  validateDeliverMediaContainerNumber: async ({
    customerId,
    containerNumber,
    logicalVaultId = "",
    mediaProgramTypeId = "",
    redeliveryFlag = N_CHECKER,
    newRequestTypeId = ""
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      container_number: containerNumber,
      secure_sync_flag: N_CHECKER,
      logical_vault_id: logicalVaultId,
      media_program_type_id: mediaProgramTypeId,
      redelivery_flag: redeliveryFlag ? Y_CHECKER : N_CHECKER,
      new_request_type_id: newRequestTypeId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getcontainerrecordwithnumbercustomervaultl6`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = { container: [], message: [] };
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        const collectionSnap2 = await getDocs(
          api_results_collection_resultset1(cfResponse.docId)
        );

        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.container.push(doc.data()));
        collectionSnap2.forEach((doc) => response.message.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get required inserts count of a media type
   *
   * @param {object} props
   * @param {string} props.mediaTypeId id of the media_type
   * @param {string} props.mediaProgramTypeId id of the media_program_type
   * @returns
   */
  getRequiredInsertsCount: async ({ mediaTypeId, mediaProgramTypeId }) => {
    let result = [];
    const q = query(
      media_type_collection,
      where(FIREBASE_FIELD_NAMES.MEDIA_TYPE_ID, "==", mediaTypeId),
      where(
        FIREBASE_FIELD_NAMES.MEDIA_PROGRAM_TYPE_ID,
        "==",
        mediaProgramTypeId
      )
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      result.push({ id: doc?.id, ...doc?.data() });
    });
    return result;
  },

  /**
   * Validate the entered container number is available for deleting
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @param {string} props.containerNumber container number to be validated
   * @param {string} props.secureSyncFlag flag for SecureSync
   * @returns
   */
  validateDeleteContainerNumber: async ({
    customerId,
    containerNumber,
    secureSyncFlag = N_CHECKER
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      container_number: containerNumber,
      secure_sync_flag: secureSyncFlag
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getallcontainerrecordswithnumbercustomervaultl7`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = { container: [], message: [] };
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        const collectionSnap2 = await getDocs(
          api_results_collection_resultset1(cfResponse.docId)
        );

        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.container.push(doc.data()));
        collectionSnap2.forEach((doc) => response.message.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get deletable containers for a specific customer
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @param {string} props.secureSyncFlag flag for SecureSync
   * @returns
   */
  getAllDeletableContainers: async ({
    customerId,
    secureSyncFlag = N_CHECKER
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      secure_sync_flag: secureSyncFlag
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getallcontainerrecordswithnumbercustomervaultl8`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Save a media request
   *
   * @param {object} props
   * @returns
   */
  saveMediaRequest: async ({
    requestActionId,
    requestTypeId,
    openMediaList,
    reRunComment,
    regularRateSpecialFlag = false,
    redeliveryFlag = false,
    tripTypeId,
    authBypassReason,
    dayOfWeekCode,
    requestStatus,
    serviceLocation,
    serviceDate,
    ironMountainComment,
    customerComment,
    customerId,
    personnelId,
    confirmPersonnelId,
    comment,
    noChargeSpecialFlag = false,
    noChargeSpecialComment = "",
    parentRequestId = 0,
    requestMediaIu,
    requestNewItem,
    container
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      detail_flag: Y_CHECKER,
      request_type_id: String(requestTypeId),
      request_action_id: String(requestActionId),
      system_function_id: SYSTEM_FUNCTION_ID_SAVE,
      open_media_list: openMediaList,
      last_mod_user: "",
      re_run_comment: reRunComment,
      regular_rate_special_flag: regularRateSpecialFlag ? Y_CHECKER : N_CHECKER,
      dr_event_id: "",
      dr_site_name: "",
      redelivery_flag: redeliveryFlag ? Y_CHECKER : N_CHECKER,
      trip_type_id: String(tripTypeId),
      auth_bypass_reason: authBypassReason,
      page_number: "",
      no_of_records: "",
      request: [
        {
          request_type_id: String(requestTypeId),
          request_action_id: String(requestActionId),
          day_of_week_code: dayOfWeekCode,
          exchange_location_type_code: EXCHANGE_LOCATION_TYPE_SL,
          service_date: dayjs(serviceDate).format("YYYY/MM/DD"),
          employee_id: getAuthenticatedUserId(),
          employee_comment: ironMountainComment,
          customer_id: String(customerId),
          personnel_id: String(personnelId),
          personnel_comment: customerComment,
          confirm_personnel_id: confirmPersonnelId,
          comment: comment, // other tab comment
          non_billable_special_flag: noChargeSpecialFlag
            ? Y_CHECKER
            : N_CHECKER,
          non_billable_special_comment: noChargeSpecialComment,
          run_position_id: "",
          request_status_id: String(requestStatus),
          csl_customer_id: Number(serviceLocation),
          parent_request_id: Number(parentRequestId) // request id of the already scheduled service
        }
      ],
      request_media_iu: requestMediaIu,
      request_new_item: requestNewItem,
      container: container
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/controldatamodificationsforrequest`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = null;
      if (cfResponse.hasResults) {
        let request = [];
        let errors = [];
        // fetch the document's resultset0 which CF returns
        const collectionSnap0 = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot0 and add it to a variable
        collectionSnap0.forEach((doc) => request.push(doc.data()));

        // fetch the document's resultset1 which CF returns
        const collectionSnap1 = await getDocs(
          api_results_collection_resultset1(cfResponse.docId)
        );
        //loop through the snapshot1 and add it to a variable
        collectionSnap1.forEach((doc) => errors.push(doc.data()));

        // if resultset 1 has errors throw
        if (errors.length > 0) {
          const errorCode = errors[0]?.error;
          let result = [];
          const q = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", errorCode)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          if (errorCode === MESSAGE_63857) {
            // Replace "|" with the content of response objects dup_customer_media_descr_list
            const appendedDescr = result[0]?.descr?.replace(
              "|",
              request[0]?.dup_customer_media_descr_list
            );
            errors?.push(appendedDescr);
          }

          throw Error(errors[1]);
        } else {
          response = request[0];
        }
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Find a media request by request id
   *
   * @param {number} requestId id of the media request
   * @returns
   */
  findMediaRequest: async (requestId) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: requestId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getsinglerequestdata`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        // return the results
        return response[0];
      } else {
        return null;
      }
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Fetch all the open medias of a media request
   *
   * @param {object} props
   * @param {number} props.requestId id of the media request
   * @returns
   */
  loadMediaRequestOpenMedias: async ({ requestId }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: requestId,
      language_locale_id: getLanguageId()
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnslistofopenmediaofarequest`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Fetch all the containers of a media request
   *
   * @param {object} props
   * @param {number} props.requestId id of the media request
   * @param {number} props.secureSyncFlag secure sync flag
   * @param {string} props.noLongerTiedToRequestStatus no longer tied to request status
   * @returns
   */
  loadMediaRequestContainers: async ({
    requestId,
    secureSyncFlag = N_CHECKER,
    noLongerTiedToRequestStatus = NO_LONGER_TIED_TO_REQUEST
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: requestId,
      language_locale_id: getLanguageId(),
      secure_sync_flag: secureSyncFlag,
      no_longer_tied_to_request_status: noLongerTiedToRequestStatus
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnscontainerinfolistofarequest`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Fetch all the transports assigned to the media request and available for assigning
   *
   * @param {object} props
   * @param {number} props.requestId id of the media request
   * @param {number} props.customerId id of the customer
   * @returns
   */
  loadMediaRequestTransports: async ({ requestId, customerId }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: requestId,
      customer_id: customerId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/findtransportsforrequest`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = { assigned: [], available: [] };
      if (cfResponse.hasResults) {
        // fetch the document's resultset1 which CF returns
        const collectionSnap0 = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot1 and add it to a variable
        collectionSnap0.forEach((doc) => response.assigned.push(doc.data()));
        // fetch the document's resultset1 which CF returns
        const collectionSnap1 = await getDocs(
          api_results_collection_resultset1(cfResponse.docId)
        );
        //loop through the snapshot1 and add it to a variable
        collectionSnap1.forEach((doc) => response.available.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Fetch all the containers/locks/transports of a media request
   *
   * @param {number} props.requestId id of the media request
   * @returns
   */
  loadMediaRequestIssueContainers: async (requestId) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: requestId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnsallnewitemsofarequest`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = {
        containers: [],
        inserts: []
      };
      if (cfResponse.hasResults) {
        // fetch the document's resultset0 which CF returns
        const collectionSnap0 = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot0 and add it to a variable
        collectionSnap0.forEach((doc) => response.containers.push(doc.data()));
        // fetch the document's resultset1 which CF returns
        const collectionSnap1 = await getDocs(
          api_results_collection_resultset1(cfResponse.docId)
        );
        //loop through the snapshot1 and add it to a variable
        collectionSnap1.forEach((doc) => response.inserts.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Fetch all deleted containers of a media request
   *
   * @param {number} props.requestId id of the media request
   * @returns
   */
  loadMediaRequestDeleteContainers: async ({
    requestId,
    secureSyncFlag = N_CHECKER,
    noLongerTiedToRequestStatus = NO_LONGER_TIED_TO_REQUEST
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: requestId,
      language_locale_id: getLanguageId(),
      secure_sync_flag: secureSyncFlag,
      no_longer_tied_to_request_status: noLongerTiedToRequestStatus
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnscontainerlistofarequest`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Assign District Transports to a request
   *
   * @param {object} props
   * @param {string} props.requestId id of the selected district
   * @param {object} props.requestTransports transport assigning to the request or removing
   * @returns
   */
  assignTransportToRequest: async ({ requestId, requestTransports }) => {
    var requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: String(requestId),
      employee_id: getAuthenticatedUserId(),
      sf_assign_code: SF_ASSIGN_CODE,
      sf_remove_code: SF_REMOVE_CODE,
      request_transport: requestTransports
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/assigndistricttransportstocustomer`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Check a request can be canceled
   *
   * @param {string} id id of the request
   * @returns
   */
  checkCancelRequest: async (id) => {
    var requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: id,
      secure_sync_flag: N_CHECKER,
      language_locale_id: getLanguageId(),
      no_longer_tied_to_request_status: NO_LONGER_TIED_TO_REQUEST
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getnextscheduledreturndate`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Cancel media request
   *
   * @param {object} props
   * @param {string} requestId id of the request
   * @param {string} cancelPersonnelId id of the personnel authorized the cancel request
   * @param {string} cancelPersonnelId id of the personnel authorized `the cancel request
   * @returns
   */
  cancelMediaRequest: async ({
    requestId,
    cancelPersonnelId,
    cancelBypassReason,
    timestamp
  }) => {
    var requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: requestId,
      cancel_personnel_id: cancelPersonnelId,
      cancel_employee_id: getAuthenticatedUserId(),
      timestamp: timestamp,
      secure_sync_flag: N_CHECKER,
      last_mod_user: "",
      auth_bypass_reason: cancelBypassReason,
      is_holiday_pref_cancel_flag: N_CHECKER,
      return_results: "0"
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/processrequestcancellation`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * check for discrepancies
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   */
  checkDiscrepancies: async ({ customerId }) => {
    var requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/checkfordiscrepancies`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Update a media request
   *
   * @param {object} props
   * @returns
   */
  updateMediaRequest: async ({
    requestId,
    requestStatus,
    confirmedBy,
    noChargeSpecialComment,
    noChargeSpecialFlag,
    ironMountainComment,
    customerComment,
    timestamp,
    lastModUser = "",
    reRunComment,
    regularRateSpecialFlag = false,
    tripType
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_id: requestId,
      request_status_id: requestStatus,
      confirm_personnel_id: confirmedBy,
      non_billable_special_comment: noChargeSpecialComment,
      non_billable_special_flag: noChargeSpecialFlag ? Y_CHECKER : N_CHECKER,
      employee_comment: ironMountainComment,
      personnel_comment: customerComment,
      request_ts: timestamp,
      secure_sync_flag: N_CHECKER,
      last_mod_user: lastModUser,
      re_run_comment: reRunComment,
      regular_rate_special_flag: regularRateSpecialFlag ? Y_CHECKER : N_CHECKER,
      trip_type_id: Number(tripType) > 0 ? tripType : ""
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/updateanexistingrequest`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = null;
      if (cfResponse.hasResults) {
        let request = [];
        // fetch the document's resultset0 which CF returns
        const collectionSnap0 = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot0 and add it to a variable
        collectionSnap0.forEach((doc) => request.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get bypass reason of an existing request
   *
   * @param {string} authenticationBypassId authentication bypass id of an existing request
   * @returns
   */
  getBypassReason: async (authenticationBypassId) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      authentication_bypass_id: authenticationBypassId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await AxiosInstance.post(
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getbypasscommentbyid`,
      requestBody
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset0 which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot0 and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }

      // return the results
      return response[0];
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  }
};
