export const PersonnelAuthenticationFormStyles = {
  AuthSuccess: {
    display: "flex",
    flexDirection: "row"
  },
  SuccessColor: {
    color: "#4CAF50"
  },
  Button: {
    borderRadius: "8px",
    mt: "5px",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
  }
};
