/* 
Exported hooks from the API for consumption in components
* Exported hooks from the API modules for consumption in components
*/
import { authApi } from "./authApi";
import { billingInterfaceRulesApi } from "./billing-module/billingInterfaceRulesApi";
import { commonBillingApi } from "./billing-module/commonBillingApi";
import { commonApi } from "./commonApi";
import { destructionRequestApi } from "./request-module/destructionRequestSearchApi";
import { mediaDestructionApi } from "./request-module/mediaDestructionApi";
import { requestDetailApi } from "./request-module/requestDetailApi";
import { requestDetailDeliveryApi } from "./request-module/requestDetailDeliveryApi";
import { requestSearchApi } from "./request-module/requestSearchApi";
import { rescheduleServiceApi } from "./request-module/rescheduleServiceApi";
import { vaultItemizedApi } from "./request-module/vaultItemizedApi";

//Common API
export const {
  useGetAuthorizationPersonnelsAuthenticationsMutation,
  useGetMediaTypesMutation,
  useGetServiceLocationByCustomerMutation,
  useGetDestructRequestDetailsQuery,
  useGetDestructRequestDetailsByMutation,
  useGetCustomerDataByIdMutation,
  useGetMessageByIdMutation,
  useGetPersonnelAuthCustMutation,
  useGetAuthNumberMutation,
  useGetAuthorizationDetailMutation,
  useGetRequestStatusesMutation,
  useGetValidateChallengeResponseMutation,
  useGetGlobalAttributeValueByIdQuery,
  useGetGlobalAttributeValueByTypeIdQuery,
  useGetMessageByIdFromMessageMutation,
  useGetLocaleByBranchMutation
} = commonApi;

// Request Search API
export const {
  useGetAllCustomersQuery,
  useGetAllRequestStatusesQuery,
  useGetAllRequestTypesQuery,
  useGetAllRoutesQuery,
  useSearchRequestMutation
} = requestSearchApi;

// Request Detail API
export const {
  useCheckRequestAuthorizationMutation,
  useGetServiceLocationsMutation,
  useGetTripTypesMutation,
  useGetAllCustomersWithDetailsQuery,
  useGetOpenMediaPossibleMatchesMutation,
  useGetRequiredInsertsCountMutation,
  useGetScheduledServiceDatesMutation,
  useGetServiceAuthCommentsMutation,
  useGetAddonCutoffValuesMutation,
  useGetBranchBillingSystemQuery,
  useGetEligibleRequestLocationsMutation,
  useGetPricingContractFlagMutation,
  useSaveMediaRequestMutation,
  useGetTransportNumbersQuery,
  useGetLogicalVaultsMutation,
  useGetInsertTypesQuery,
  useGetLockTypesQuery,
  useValidateDeliverMediaOpenMediaNumberMutation,
  useValidateDeliverMediaContainerNumberMutation,
  useValidateDeleteContainerNumberMutation,
  useGetAllDeletableContainersMutation,
  useGetAuthorizationPersonnelsMutation,
  useFindMediaRequestMutation,
  useLoadMediaRequestOpenMediasMutation,
  useLoadMediaRequestContainersMutation,
  useLoadMediaRequestTransportsMutation,
  useLoadMediaRequestIssueContainersMutation,
  useLoadMediaRequestDeleteContainersMutation,
  useAssignTransportToRequestMutation,
  useCheckCancelRequestMutation,
  useCancelMediaRequestMutation,
  useUpdateMediaRequestMutation,
  useCheckDiscrepanciesMutation
} = requestDetailApi;

// Destruction Request Search API
export const {
  useMediaDestructionRequestSearchMutation,
  useMediaDestructionGetAllRequestStatusQuery,
  useMediaDestructionGetAllRequestTypesQuery
} = destructionRequestApi;
// Request Delivery Detail API
export const {
  useGetServiceLocationQuery,
  useGetCustomerMdrDetailsQuery,
  useGetAllPersonnelsQuery,
  useGetAllDestructionStatusTypesQuery,
  usePostNewDestructRequestMutation,
  useGetDesReqCommentMutation,
  usePostUpdateDestructRequestMutation,
  useCancelDestructRequestMutation,
  useDestructRequestCommentInsertMutation,
  useDestructRequestCommentDeleteMutation,
  useGetServiceLocationByCustomerTriggerMutation,
  useVerifyPersonnelPermissionStatusMutation
} = requestDetailDeliveryApi;

// Login API
export const { useGetAllBranchesQuery, useLoginMutation } = authApi;
//RescheduleService API
export const {
  useGetScheduleDateMutation,
  useGetCustLocationInfoMutation,
  useGetRequestInfoMutation,
  useGetRouteInfoMutation,
  useGetRunInfoMutation,
  useGetCslInfoMutation,
  useAssignTransportsToCustomerMutation,
  useRescheduleRequestsMutation
} = rescheduleServiceApi;

// Vault-Itemized API
export const {
  useGetAllPersonnelQuery,
  useGetPersonnelAuthQuery,
  useGetAllDestructMediaQuery,
  useGetUnitOfMeasureQuery,
  useGetDestructRequestDetailQuery,
  useGetAllDestructMediaTypesQuery,
  useGetValidateOpenMediaListQuery,
  useGetPossibleMatchesListQuery,
  useCreateMDRVaultItemizedMutation,
  useGetExistingVaultItemizedMDRMutation,
  useCancelMDRVaultItemizedMutation,
  useUpdateMDRVaultItemizedMutation,
  useGetPersonnelSecurityQuery
} = vaultItemizedApi;

export const { useGetAllPersonnelsDetailsQuery } = commonApi;
//MDR-pickup
export const {
  useGetTrackingRequirementsQuery,
  useGetDispositionServicesQuery,
  useGetBoxTypesQuery,
  useGetCustomerInfoQuery,
  useGetDestructDetailsMutation,
  useGetSpecialHandlingMutation,
  useGetBoxTypesCountMutation,
  useGetRequestCommentsMutation,
  useGetUnitOfMeasuresQuery,
  useGetCustomerServiceLocationQuery,
  useSaveScheduleRequestMutation,
  useCancelRequestMutation,
  useDeleteRequestBoxTypeCountMutation,
  useInsertRequestBoxTypeCountMutation,
  useSaveSitadRequestMutation,
  useDeleteRequestCommentMutation,
  useGetRunRequestStatusMutation,
  useInsertRequestCommentMutation,
  useInsertRequestSpecialHandlingMutation,
  useGetPersonnelAuthCustomerMutation,
  useGetPersonnelCustomerMutation,
  useVerifyPersonnelPermissionMutation,
  useUploadFileMutation
} = mediaDestructionApi;

/************* Billing Module *********************/

export const {
  useGetAllClassificationsQuery,
  useGetAllCatalogItemsMutation,
  useGetAllIdentifierInfoMutation
} = billingInterfaceRulesApi;

export const { useGetAllCommissionTypeQuery, useGetAllBillCycleQuery } =
  commonBillingApi;
