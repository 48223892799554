import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { PageTitle } from "components/shared";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import { errorMsgs } from "utils/constants";
import {
  imeEnv,
  imnaEnv,
  labelTemplateConstants,
  mediaTYpeImnaLength,
  mediaTYpeLength
} from "utils/constants/customer-service-information/LabelTemplate";
import { labelTemplateMaintanceStyles } from "./LabelTemplateMaintenanceStyles";

const TestLabelTemplate = () => {
  const navigate = useNavigate();
  const [customerNumber, setCustomerNumber] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [testString, setTestString] = useState("");
  const [testResults, setTestResults] = useState({
    customerNumber: "",
    mediaType: "",
    volser: ""
  });
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const { template } = JSON.parse(
    localStorage.getItem(labelTemplateConstants.labelTemplateId)
  );
  const labelTemplate = template?.template;
  const handleTestString = (e) => {
    setTestString(e.target.value);
  };
  const handleCustomerNumberChange = (e) => {
    setCustomerNumber(e.target.value);
    setMediaType("");
    setTestString("");
    setTestResults({ customerNumber: "", mediaType: "", volser: "" });
  };
  const handleMediaTypeChange = (e) => {
    setMediaType(e.target.value);
    setTestString("");
    setTestResults({ customerNumber: "", mediaType: "", volser: "" });
  };
  const parseOnlyMT = (template) => {
    const specialMappings = {
      MT: "M"
    };
    let result = "";
    let i = 0;
    while (i < template?.length) {
      if (
        i < template?.length - 1 &&
        template[i] === "M" &&
        template[i + 1] === "T"
      ) {
        result += specialMappings[labelTemplateConstants.mt];
        i += 2; // Skip the next character
      } else {
        result += template[i];
        i++;
      }
    }
    return result;
  };
  function parseTemplate(template) {
    const bracketRegex = /\[(.*?)\]/;
    const matchBarcket = bracketRegex.exec(template);
    // Define the mapping for special cases
    const specialMappings = {
      CUST: customerNumber?.length,
      MT:
        mediaType?.length === 1
          ? labelTemplateConstants.m
          : labelTemplateConstants.mt, // Treat 'MT' as 'M'
      "{}": labelTemplateConstants.X, // Treat '{}' as 'X'
      "<>": labelTemplateConstants.X, // Treat '<>' as 'X'
      "[]": labelTemplateConstants.X // Treat '[]' as 'X'
      // No generic mapping for content inside angle brackets; handle dynamically
    };

    // Initialize result string
    let result = "";
    let i = 0;

    // Parse the template and replace special cases
    while (i < template?.length) {
      if (
        i < template?.length - 1 &&
        template[i] === "{" &&
        template[i + 1] === "}"
      ) {
        result += specialMappings["{}"];
        i += 2; // Skip the next character
      } else if (
        i < template?.length - 1 &&
        template[i] === labelTemplateConstants.m &&
        template[i + 1] === labelTemplateConstants.t
      ) {
        result += specialMappings[labelTemplateConstants.mt];
        i += 2; // Skip the next character
      } else if (i < template?.length - 1 && template[i] === "<") {
        // Find the closing bracket '>'
        const closingBracketIndex = template.indexOf(">", i);
        if (closingBracketIndex !== -1) {
          // Treat the entire content inside '<>' as one character
          result += ""; // Or any single character placeholder you choose
          i = closingBracketIndex + 1; // Skip past the closing bracket
        } else {
          // If there's no closing '>', treat it as a normal character
          result += template[i];
          i++;
        }
      } else if (i < template?.length - 1 && template[i] === "[") {
        // Find the closing bracket '>'
        const closingBracketIndex = template.indexOf("]", i);
        if (closingBracketIndex !== -1) {
          // Treat the entire content inside '<>' as one character
          result += `${matchBarcket[1]}`; // Or any single character placeholder you choose
          i = closingBracketIndex + 1; // Skip past the closing bracket
        } else {
          // If there's no closing '>', treat it as a normal character
          result += template[i];
          i++;
        }
      } else if (template[i] === "[" && template[i + 1] === "]") {
        result += specialMappings["[]"];
        i += 2; // Skip the next character
        continue;
      } else if (template[i] === "<" && template[i + 1] === ">") {
        result += specialMappings["<>"];
        i += 2; // Skip the next character
      } else {
        result += template[i];
        i++;
      }
    }

    // Adjust the result length to be exactly 8 characters
    if (labelTemplate.includes(labelTemplateConstants.cust)) {
      result = result.replace(labelTemplateConstants.cust, customerNumber);
      return result;
    } else {
      return result;
    }
  }
  function removePipes(input) {
    let indices = [];

    // Find all occurrences of the '|' character and store their indices
    for (let i = 0; i < input.length; i++) {
      if (input[i] === "|") {
        indices.push(i);
      }
    }

    // Display the start and end indices of '|'
    const startIndex = indices[0];
    const endIndex = indices[indices.length - 1];
    // Remove all '|' characters from the string
    let result = input.replace(/\|/g, "");

    return { result, startIndex, endIndex };
  }
  function removeFromIndexRange(input, startIndex, endIndex) {
    // Extract the part before the startIndex and the part after the endIndex
    const result =
      input.substring(0, startIndex) + input.substring(endIndex + 1);
    return result;
  }
  function extractValueAfterMT(templateValue, value) {
    const templatetest = templateValue.includes(labelTemplateConstants.cust);
    if (templateValue.includes(labelTemplateConstants.mt)) {
      let template;
      if (templatetest) {
        template = templateValue?.replace(
          labelTemplateConstants.cust,
          customerNumber
        );
      } else {
        template = templateValue;
      }
      if (template.includes(labelTemplateConstants.objecBracket)) {
        const getIndex = removePipes(
          template.replace(labelTemplateConstants.mt, "").replace(/{}/g, "|")
        );
        value = removeFromIndexRange(
          value,
          getIndex?.startIndex,
          getIndex?.endIndex
        );
      }
      let position = 0;
      let i = 0;

      // Traverse the template to determine the position after 'MT'
      while (i < template?.length) {
        if (
          i < template?.length - 1 &&
          template[i] === labelTemplateConstants.m &&
          template[i + 1] === labelTemplateConstants.t
        ) {
          // Skip past 'MT'
          i += 2;
          break; // Stop searching after finding 'MT'
        } else if (
          i < template?.length - 1 &&
          template[i] === labelTemplateConstants.objecBracketOpen &&
          template[i + 1] === labelTemplateConstants.objecBracketClose
        ) {
          // Special sequence '{}', count it as one character
          position += 1;
          i += 2; // Skip '{}'
        } else {
          // Regular character
          position += 1;
          i++;
        }
      }

      // Ensure position is within bounds of the input value
      if (position >= value?.length) {
        return ""; // No value to extract if position is beyond the input length
      }

      // Extract the substring from the input value starting from the position after 'MT'
      const extractedValue = value && value?.substring(position);
      // Remove non-alphanumeric characters from the extracted value
      return extractedValue.replace(/[^A-Za-z0-9]/g, "");
    } else {
      let position = 0;
      let template;
      if (templatetest) {
        template = templateValue?.replace(
          labelTemplateConstants.cust,
          customerNumber
        );
      } else {
        template = templateValue;
      }
      // Check for 'CUST' in templateValue and determine position
      if (templateValue.includes(labelTemplateConstants.cust)) {
        // Find the position right after 'CUST'
        position =
          templateValue.indexOf(labelTemplateConstants.cust) +
          labelTemplateConstants.cust?.length;
        return value.indexOf(customerNumber) !== -1
          ? value.slice(value.indexOf(customerNumber) + customerNumber.length)
          : null;
      }
      if (template.includes(labelTemplateConstants.objecBracket)) {
        const getIndex = removePipes(
          template.replace(labelTemplateConstants.mt, "").replace(/{}/g, "|")
        );

        value = removeFromIndexRange(
          value,
          getIndex?.startIndex,
          getIndex?.endIndex
        );
      }
      // Ensure position is within bounds of the input value
      if (position >= value?.length) {
        return ""; // No value to extract if position is beyond the input length
      }
      // Extract the substring from the input value starting from the position after 'CUST'
      const extractedValue = value && value?.substring(position);
      // Remove non-alphanumeric characters from the extracted value
      return extractedValue.replace(/[^A-Za-z0-9]/g, "");
    }
  }

  const handleEvaluate = (templateValue, testString) => {
    const templatetest = templateValue.includes(labelTemplateConstants.cust);
    let template;
    if (templatetest) {
      template = templateValue?.replace(
        labelTemplateConstants.cust,
        customerNumber
      );
    } else {
      template = templateValue;
    }
    const regex = /<(\d+)>/g;
    let match;
    let finalResult = testString;
    const bracketRegex = /\[(.*?)\]/;
    const matchBarcket = bracketRegex.exec(template);

    if (matchBarcket) {
      return finalResult;
    }

    if ((match = regex.exec(template)) !== null) {
      const index = match[1]; // e.g., 9
      const index000 = template.indexOf("<");
      const indexV = template.indexOf("V");
      if (index000 !== -1 && indexV !== -1) {
        if (index000 < indexV) {
          finalResult = index + finalResult;
        } else {
          finalResult = finalResult + index;
        }
      }

      return finalResult;
    } else if (template.includes(labelTemplateConstants.objecBracket)) {
      const placeholderIndex = parseOnlyMT(template).indexOf(
        labelTemplateConstants.objecBracket
      );
      const finalResult = testString?.substring(0, placeholderIndex);
      return finalResult;
    } else {
      return testString;
    }
  };
  // Test button logic
  const handleTest = useCallback(async () => {
    const index = testString.indexOf(customerNumber);
    let cleanTestString;
    if (index !== -1) {
      cleanTestString = testString?.substring(index);
    }
    // Reset results and errors

    // Ensure customer number is provided
    let cleanedCustomerNumber = customerNumber.trim();
    let bHasCustomerNumber = false;
    let iCustomerNumberTemplateIndex = -1;

    // Regex patterns
    const sPATTERN_LEADING_ZEROS = /^0+/;
    const sPATTERN_LEADING_CUSTOMER_NUMBER = /^CUST/;
    const sPATTERN_CUST_OUTSIDE_INS_LIT_MODIFIERS = /[^<[]CUST/;
    const sPATTERN_VALID_IGNORE = /\{\}/g;
    // Remove leading zeros from expected customer number
    cleanedCustomerNumber = cleanedCustomerNumber.replace(
      sPATTERN_LEADING_ZEROS,
      ""
    );

    // Check if customer is in the template
    if (labelTemplate.match(sPATTERN_LEADING_CUSTOMER_NUMBER)) {
      iCustomerNumberTemplateIndex = 0;
      bHasCustomerNumber = true;
    } else if (labelTemplate.match(sPATTERN_CUST_OUTSIDE_INS_LIT_MODIFIERS)) {
      const match = labelTemplate.match(
        sPATTERN_CUST_OUTSIDE_INS_LIT_MODIFIERS
      );
      if (match && match?.length > 0) {
        bHasCustomerNumber = true;
        iCustomerNumberTemplateIndex = match.index + 1;
      }
    }

    // Customer number validation and extraction
    if (bHasCustomerNumber) {
      // Validate if customer number field is required and empty
      if (cleanedCustomerNumber?.length === 0) {
        const err = await getMessageFromCode(errorMsgs.errorCode20259);
        setThrowError(true);
        setThrowErrorMessage(
          err[0]?.descr.replace("|", labelTemplateConstants.customerNumber)
        );

        return;
      }
      let iCustomerNumberLabelIndex =
        iCustomerNumberTemplateIndex -
        (labelTemplate.match(sPATTERN_VALID_IGNORE)?.length || 0);

      // Adjust for leading zeros
      let sPaddedCustomerNumber = cleanTestString?.substring(
        iCustomerNumberLabelIndex
      );
      iCustomerNumberLabelIndex +=
        sPaddedCustomerNumber?.length -
        sPaddedCustomerNumber?.replace(sPATTERN_LEADING_ZEROS, "")?.length;
      // Find the index of the customer number in the teststring

      // Set parsed customer number
      let sParsedCustomerNumber = cleanTestString?.substring(
        iCustomerNumberLabelIndex,
        Math.min(
          cleanedCustomerNumber?.length,
          cleanTestString?.length - iCustomerNumberLabelIndex
        )
      );
      // Customer number validation
      if (cleanedCustomerNumber !== sParsedCustomerNumber) {
        const err = await getMessageFromCode(errorMsgs.errorCode25128);
        setThrowError(true);
        setThrowErrorMessage(err[0]?.descr);
        return;
      }
      // Process Media Type

      if (labelTemplate.includes(labelTemplateConstants.mt)) {
        // Extract and validate the media type from the test string

        if (!mediaType) {
          const err = await getMessageFromCode(errorMsgs.errorCode20259);
          setThrowError(true);
          setThrowErrorMessage(
            err[0]?.descr.replace("|", labelTemplateConstants.mediaType)
          );

          return;
        }
      }
      if (mediaType) {
        const removeCustomer = cleanTestString?.replace(customerNumber, "");
        if (!removeCustomer.includes(mediaType)) {
          const err = await getMessageFromCode(errorMsgs.errorCode25130);
          setThrowError(true);
          setThrowErrorMessage(
            err[0]?.descr.replace("|", labelTemplateConstants.mediaType)
          );
          return;
        }
      }
      const bracketRegex = /\[(.*?)\]/;
      const matchBarcket = bracketRegex.exec(labelTemplate);
      const test = testString?.includes(matchBarcket && matchBarcket[1]);

      if (matchBarcket && matchBarcket?.length > 0 && !test) {
        const err = await getMessageFromCode(errorMsgs.errorCode25130);
        setThrowError(true);
        setThrowErrorMessage(err[0]?.descr.replace("|", matchBarcket[1]));
        return;
      }
      // Validate the length of the test string
      const templateLength = parseTemplate(labelTemplate)?.length;
      if (cleanTestString?.length !== templateLength) {
        const err = await getMessageFromCode(errorMsgs.errorCode25129);
        setThrowError(true);
        setThrowErrorMessage(err[0]?.descr);
        return;
      }
      if (
        process.env.REACT_APP_CF_REGION === imeEnv &&
        mediaType?.length >= mediaTYpeLength
      ) {
        const err = await getMessageFromCode(errorMsgs.errorCode25129);
        setThrowError(true);
        setThrowErrorMessage(err[0]?.descr);
        return;
      }
      if (
        process.env.REACT_APP_CF_REGION === imnaEnv &&
        mediaType?.length === mediaTYpeImnaLength
      ) {
        const err = await getMessageFromCode(errorMsgs.errorCode25129);
        setThrowError(true);
        setThrowErrorMessage(err[0]?.descr);
        return;
      }
      // Test successful
      setTestResults({
        customerNumber: cleanedCustomerNumber,
        mediaType: mediaType,
        volser: handleEvaluate(
          labelTemplate,
          extractValueAfterMT(
            labelTemplate,
            cleanTestString.replace(mediaType, "")
          )
        ) // Assuming volser is the entire test string for simplicity
      });
    } else {
      if (labelTemplate.includes(labelTemplateConstants.mt)) {
        // Extract and validate the media type from the test string

        if (!mediaType) {
          const err = await getMessageFromCode(errorMsgs.errorCode20259);
          setThrowError(true);
          setThrowErrorMessage(
            err[0]?.descr.replace("|", labelTemplateConstants.mediaType)
          );

          return;
        }
      }
      if (mediaType) {
        const removeCustomer = cleanTestString?.replace(customerNumber, "");
        if (!removeCustomer.includes(mediaType)) {
          const err = await getMessageFromCode(errorMsgs.errorCode25130);
          setThrowError(true);
          setThrowErrorMessage(
            err[0]?.descr.replace("|", labelTemplateConstants.mediaType)
          );
          return;
        }
      }
      const bracketRegex = /\[(.*?)\]/;
      const matchBarcket = bracketRegex.exec(labelTemplate);
      const test = testString?.includes(matchBarcket && matchBarcket[1]);

      if (matchBarcket && matchBarcket?.length > 0 && !test) {
        const err = await getMessageFromCode(errorMsgs.errorCode25130);
        setThrowError(true);
        setThrowErrorMessage(err[0]?.descr.replace("|", matchBarcket[1]));
        return;
      }
      // Validate the length of the test string
      const templateLength = parseTemplate(labelTemplate)?.length;
      if (cleanTestString?.length !== templateLength) {
        const err = await getMessageFromCode(errorMsgs.errorCode25129);
        setThrowError(true);
        setThrowErrorMessage(err[0]?.descr);
        return;
      }

      // Test successful
      setTestResults({
        customerNumber: cleanedCustomerNumber,
        mediaType: mediaType,
        volser: labelTemplate.includes(labelTemplateConstants.mt)
          ? handleEvaluate(
              labelTemplate,
              extractValueAfterMT(
                labelTemplate,
                cleanTestString.replace(mediaType, "")
              )
            )
          : handleEvaluate(labelTemplate, cleanTestString) // Assuming volser is the entire test string for simplicity
      });
    }
    setThrowError(false);
    //eslint-disable-next-line
  }, [customerNumber, mediaType, testString, labelTemplate]);
  return (
    <>
      {throwError && (
        <Box my={spacing.gap}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Box>
      )}
      <Stack>
        <PageTitle title={`${t("labelTemplateMaintenance.testLabelTitle")}`} />
        <br />
        <Box>
          <Typography variant="h5" component="h1" gutterBottom>
            {t("labelTemplateMaintenance.subTitle")}
          </Typography>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <Typography
                variant="body2"
                sx={labelTemplateMaintanceStyles.maintanence.colorTitle}
              >
                {t("labelTemplateMaintenance.labelTemplate")}
              </Typography>
              <Typography variant="body1">{labelTemplate}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={t("labelTemplateMaintenance.customerNumber")}
                variant="outlined"
                value={customerNumber}
                disabled={!labelTemplate?.includes(labelTemplateConstants.cust)}
                onChange={handleCustomerNumberChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={t("labelTemplateMaintenance.mediaType")}
                variant="outlined"
                onChange={(e) => handleMediaTypeChange(e)}
                value={mediaType}
                disabled={!labelTemplate?.includes(labelTemplateConstants.mt)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              sx={labelTemplateMaintanceStyles.maintanence.helper}
            >
              <TextField
                fullWidth
                label={t("labelTemplateMaintenance.testString")}
                variant="outlined"
                helperText={t("labelTemplateMaintenance.actualBarcodeLabel")}
                value={testString}
                onChange={handleTestString}
              />
            </Grid>
          </Grid>

          <Divider sx={labelTemplateMaintanceStyles.testLabelDivider} />

          <Typography variant="h6" gutterBottom>
            {t("labelTemplateMaintenance.testResult")}
          </Typography>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Typography variant="body2">
                {" "}
                {t("labelTemplateMaintenance.customerNum")}
              </Typography>
              <Typography variant="body1">
                {testResults.customerNumber ? testResults.customerNumber : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {" "}
                {t("labelTemplateMaintenance.mediaType")}
              </Typography>
              <Typography variant="body1">
                {testResults.mediaType ? testResults.mediaType : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {" "}
                {t("labelTemplateMaintenance.volser")}
              </Typography>
              <Typography variant="body1">
                {testResults.volser ? testResults.volser : "-"}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={4} display="flex" gap={2}>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              {" "}
              {t("common.cancel")}
            </Button>
            <Button
              variant="outlined"
              disabled={
                customerNumber?.length === 0 &&
                mediaType?.length === 0 &&
                testString?.length === 0
              }
              onClick={() => {
                setCustomerNumber("");
                setTestResults("");
                setMediaType("");
                setTestString("");
              }}
            >
              {t("common.clear")}
            </Button>
            <Button variant="contained" color="primary" onClick={handleTest}>
              {t("labelTemplateMaintenance.test")}
            </Button>
          </Box>
        </Box>
      </Stack>
    </>
  );
};
export default TestLabelTemplate;
