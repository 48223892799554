import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfrimPopupStyles } from "./ConfirmPopupStyles";

const AsyncConfirm = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState({ resolver: null });
  const [message, setMessage] = useState(null);

  const createPromise = () => {
    let resolver;
    return [
      new Promise((resolve, reject) => {
        resolver = resolve;
      }),
      resolver
    ];
  };

  const getConfirmation = async (text) => {
    setMessage(text);
    setOpen(true);
    const [promise, resolve] = await createPromise();
    setResolver({ resolve });
    return promise;
  };

  const onClick = async (status) => {
    setOpen(false);
    resolver.resolve(status);
  };

  const Confirmation = () => (
    <Paper elevation={24}>
      <Dialog
        open={open}
        fullWidth={true}
        PaperProps={{
          sx: {
            ...ConfrimPopupStyles.dialogModal
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={ConfrimPopupStyles.paddingTitleStyle}
        >
          {/*Modal title*/}
          <Typography sx={ConfrimPopupStyles.titleStyle}>
            {t("common.confirm")}
          </Typography>
        </DialogTitle>
        <Divider />
        {/* ------------------ Body Section ------------------ */}{" "}
        <DialogContent sx={ConfrimPopupStyles.cardContentStyles}>
          {/*Body section text*/}
          <Typography
            sx={ConfrimPopupStyles.alertMessage}
            id="modal-description"
          >
            {message}
          </Typography>{" "}
        </DialogContent>
        <Divider />
        {/* ------------------ Footer Section ------------------ */}{" "}
        <DialogActions sx={ConfrimPopupStyles.modalButton}>
          <Button variant="outlined" onClick={() => onClick(false)}>
            {t("common.no")}
          </Button>
          <Button variant="contained" onClick={() => onClick(true)}>
            {t("common.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );

  return [getConfirmation, Confirmation];
};

export default AsyncConfirm;
