import spacing from "styles/spacing";

const alertBox = {
  marginBottom: spacing.horizontalMargin20
};
const printModalRadio = {
  display: "flex",
  flexDirection: "row",
  gap: spacing.horizontalMargin20
};
const boldRow = {
  fontWeight: 1000
};
export const EmployeeSearchStyles = {
  alertBox,
  printModalRadio,
  boldRow
};
