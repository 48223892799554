export const hyphen = "-";
export const preferenceTypeId = "21";
export const padStartLength = 10;

export const dataIndex = {
  changeHistoryDatetime: "change_history_datetime",
  authorizedBy: "authorized_By",
  oldValue: "old_value",
  newValue: "new_value"
};
