const cardStylePopUP = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  boxShadow: 24
};

const mainTitlePopUP = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "32px",
  letterSpacing: "0.15px",
  color: "#1E417A",
  display: "flex",
  padding: "16px 24px",
  alignItems: "center",
  alignSelf: "stretch",
  width: "552px"
};

const cardActionsStylePopUP = {
  display: "flex",
  padding: "16px",
  alignItems: "flex-end",
  gap: "8px",
  alignSelf: "stretch"
};

const buttonStylePopUP = {
  display: "flex",
  padding: "6px 16px !important",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "unset",
  marginLeft: "0px !important"
};

const cardStyleDRMPopUP = {
  ...cardStylePopUP,
  width: "550px !important"
};

const formControlLabel = {
  width: "270px"
};

export const PrintPickModalStyles = {
  cardActionsStylePopUP,
  mainTitlePopUP,
  buttonStylePopUP,
  cardStyleDRMPopUP,
  formControlLabel
};
