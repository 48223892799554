export const SystemRequestStyles = {
  fontStyles: {
    color: "var(--color-dashboard-text)",
    fontFamily: "Roboto",
    fontSize: "1.8rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "25px",
    textAlign: "center"
  },
  headerDivStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    width: "100%",
    marginTop: "25px"
  },
  cardGridStyles: {
    padding: "4px",
    display: "flex",
    justifyContent: "center"
  },
  cardStylesSelected: {
    width: "100px",
    height: "120px",
    padding: "4px",
    cursor: "pointer",
    transition: "border 0.3s ease",
    border: "2px solid var(--color-system-request-selected)"
  },
  cardStylesNotSelected: {
    width: "100px",
    height: "120px",
    padding: "4px",
    cursor: "pointer",
    transition: "border 0.3s ease"
  },
  cardMediaStyles: { objectFit: "contain", marginTop: "10px" },
  cardContentStyles: {
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  cardTypographyStyles: { fontWeight: "bold", fontSize: "0.875rem" },
  sendButtonGrid: { display: "flex", alignItems: "center" }
};
