import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button, Divider, IconButton, Stack } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import AuthenticationSection from "components/shared/auth/AuthenticationSection";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADMIN_MODULE_AUTH_BASE_PATH,
  ADMIN_MODULE_BASE_PATH,
  PERSONAL_DETAIL,
  PERSONAL_HISTORY,
  PERSONAL_SEARCH
} from "routing/Paths";
import {
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import {
  callToAPI,
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  getCountries,
  getCustomersQueryData,
  getDistricts,
  getENVDetails,
  getFormatsBasedOnCountry,
  getLocaleById,
  getMessageFromCode,
  getProgramSecurityList
} from "services/api/query";
import {
  selectAuth,
  selectPersonnel,
  setAuthenticationState
} from "store/slices";
import spacing from "styles/spacing";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  FIREBASE_FIELD_NAMES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  attribute_id,
  authSection,
  authType,
  confirmation,
  errorMsgs,
  globalAttributeTypeIdCustomer,
  globalAttributeTypeIdURL,
  initPw,
  numberConstants,
  roletypes,
  secureSyncUser,
  security_program_id_108,
  security_program_id_118,
  security_program_id_222,
  systemIdCustomer,
  systemIdURL,
  system_id
} from "utils/constants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getGlobalAttributeValue,
  getLanguageId,
  getSortedData,
  sendEmail
} from "utils/helpers";
import PDCustomerDetailsSection from "./PDCustomerDetailsSection";
import PDIdentificationSection from "./PDIdentificationSection";
import PersonnelDetailRemoveCustomerAssociation from "./PersonnelDetailRemoveCustomerAssociation";
import PersonnelDetailResetAuthModal from "./PersonnelDetailResetAuthModal";
import { personnelDetailStyles } from "./PersonnelDetailStyles";
import PersonnelDetailTable from "./PersonnelDetailTable";
import RemoveCustomerModal from "./RemoveCustomerModal";

const PersonnelDetailLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticationState } = useSelector(selectPersonnel);
  const { personnalID } = useParams();

  const { currentBranch, authInfo, auth } = useSelector(selectAuth);
  const languageLocaleId = getLanguageId();
  const [allBranches, setAllBranches] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [valuePersonnelAuth, setValuePersonnelAuth] = useState(
    authSection?.personnelAuth
  ); // personnel authentication or bypass
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const [isBypassError, setIsBypassError] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customerPermissionList, setCustomerPermissionList] = useState([]);
  const [persCustomerPermissionList, setPersCustomerPermissionList] = useState(
    []
  );
  const [showResetAuthPopup, setShowResetAuthPopup] = useState(false);
  const [showRemoveCustomerModal, setShowRemoveCustomerModal] = useState(false);
  const [showAuthenticationSection, setShowAuthenticationSection] =
    useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const [isAddingANewCustomer, setIsAddingANewCustomer] = useState(false);
  const [isCustomerSelectedFromTheTable, setIsCustomerSelectedFromTheTable] =
    useState(false);
  const [isEditClick, setIsEditClick] = useState(false);
  const [successInsertMessage, setSuccessInsertMessage] = useState("");
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [infoInsertMessage, setInfoInsertMessage] = useState("");
  const [messageInfo, setMessageInfo] = useState(false);
  const [requiredFieldValidations, setRequiredFieldValidations] = useState({
    firstName: false,
    lastName: false,
    businessEmail: false,
    businessPhone: false,
    customerDetailCustomer: false,
    middleName: false,
    homeEmail: false,
    confirmHomeEmail: false,
    confirmBusinessEmail: false,
    homePhone: false,
    cellular: false,
    selectedCountry: false,
    pager: false,
    pagerPin: false,
    extension: false,
    businessFax: false
  });
  const [
    personnelDetailRemoveCustomerAssociationModalShow,
    setPersonnelDetailRemoveCustomerAssociationModalShow
  ] = useState(false);
  const [isPersonnelEditable, setIsPersonnelEditable] = useState(true);
  const [isInteractionEditable, setIsInteractionEditable] = useState(true);
  const [isDREditable, setIsDREditable] = useState(true);
  const [isAuthClick, setIsAuthClick] = useState(false);
  const [personnelCustomerIds, setPersonnelCustomerIds] = useState([]);
  const [isCustAssocToPersonnel, setIsCustAssocToPersonnel] = useState(false);
  const [personnelCustomerList, setPersonnelCustomerList] = useState([]);
  const [originalCustomerList, setOriginalCustomerList] = useState([]);
  const [removeCustomer, setRemoveCustomer] = useState(false);

  const programSecurityListArray = auth?.user?.program_list
    .split(",")
    .map(Number);

  useEffect(() => {
    if (isEditClick) {
      isFildesDissabled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditClick]);

  let initialValues = {
    // auth section
    branch: "",
    customer: "",
    personnel: "",
    authNumber: "",
    challengeQuestion: "",
    challengeResponse: "",
    bypassedBy: "",
    bypassReason: "",

    // identification section
    userId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    homeEmail: "",
    confirmHomeEmail: "",
    businessEmail: "",
    confirmBusinessEmail: "",
    homePhone: "",
    cellular: "",
    selectedCountry: "",
    pager: "",
    pagerPin: "",
    isSecureSyncEnabled: false,

    // customer details section
    customerEmployeeNumber: "",
    businessPhone: "",
    extension: "",
    businessFax: "",
    customerDetailSelectedCountry: "",
    isContractOwnerChecked: false,
    isDiscrepancyContactChecked: false,
    customerDetailBranch: "",
    customerDetailCustomer: "",

    //dra
    isDRAOneChecked: false,
    isDRATwoChecked: false,
    isDRAThreeChecked: false,
    isDRAFourChecked: false,

    //ia
    isIAOneChecked: false,
    isIATwoChecked: false,
    isIAThreeChecked: false,
    isIAFourChecked: false,
    isIAFiveChecked: false,
    isIASixChecked: false,

    timeStamp: ""
  };

  const personalDetailForm = useFormik({
    initialValues: initialValues
  });

  useEffect(() => {
    if (authenticationState) {
      personalDetailForm.setFieldValue(
        "customer",
        authenticationState.customer
      );
      personalDetailForm.setFieldValue(
        "personnel",
        authenticationState.personnel
      );
      personalDetailForm.setFieldValue(
        "authNumber",
        authenticationState.authNumber
      );
      personalDetailForm.setFieldValue(
        "branch",
        authenticationState.authenticationBranch || authenticationState.branch
      );
      personalDetailForm.setFieldValue(
        "challengeQuestion",
        authenticationState.challengeQuestion
      );
      personalDetailForm.setFieldValue(
        "challengeResponse",
        authenticationState.challengeResponse
      );
      setIsAuthenticated(true);
    }
    //eslint-disable-next-line
  }, [authenticationState]);

  useEffect(() => {
    if (!isPersonnelEditable) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(t("personnelDetail.insufficientRights"));
    }
    //eslint-disable-next-line
  }, [isPersonnelEditable]);

  // search button click
  const onSearchClick = () => {
    navigate(
      `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_AUTH_BASE_PATH}/${PERSONAL_SEARCH}`
    );
  };

  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    setIsLoading((prev) => true);
    try {
      const branches = await getDistricts();
      let bran = branches?.map((b) => {
        return {
          ...b,
          label: b?.name,
          value: b?.district_id
        };
      });
      const branchData = getSortedData(bran, "name", "asc");
      setAllBranches(branchData);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // handle branch change
  const handleBranchChange = (e) => {
    personalDetailForm.setFieldValue("branch", e.target?.value);
  };

  // personnel authentication type change
  const handlePersonnelAuthChange = (event) => {
    setValuePersonnelAuth(event.target.value);
    personalDetailForm.setFieldValue("personnel", "");
  };

  // get customers based on the selected branch
  const getCustomersBasedOnTheSelectedBranch = async (branch) => {
    setIsLoading((prev) => true);
    try {
      const custList = await getCustomersQueryData(branch);
      let modCux = custList?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number} - ${cus?.name}`,
          value: cus?.id
        };
      });
      setAllCustomers(modCux);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // customer selection in auth section
  const handleCustomerChange = (event, newValue) => {
    personalDetailForm.setFieldValue("customer", newValue);
  };

  // handle Personnel selection in auth section
  const handlePersonnelChange = (selectedValue) => {
    personalDetailForm.setFieldValue("personnel", selectedValue);
  };

  // selected personnel auth details
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: personalDetailForm.values?.personnel?.value
  });

  // authentication click
  const onAuthenticateClick = async () => {
    setIsLoading(true);
    setIsAuthenticationError(false);
    personnalID && setIsAuthClick(true);
    if (
      !personalDetailForm.values?.authNumber?.length &&
      !personalDetailForm.values?.challengeResponse?.length
    ) {
      setIsAuthenticationError(true);
      setIsLoading(false);
      return;
    }
    if (personalDetailForm.values?.authNumber?.length) {
      if (
        personalDetailForm.values?.authNumber === personnelAuth[0]?.auth_number
      ) {
        if (personnalID) {
          setIsEditClick(true);
        }
        setIsAuthenticated(true);
        setIsLoading(false);
        return;
      } else {
        setIsAuthenticationError(true);
        setIsLoading(false);
        return;
      }
    }

    // authenticate with challenge response
    if (personalDetailForm.values?.challengeResponse?.length) {
      let hashCode = await generateSHA256Hash(
        personalDetailForm.values?.challengeResponse?.trim().toUpperCase()
      );

      //challenge response validation
      let validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: String(currentBranch?.district_id),
        language_locale_id: languageLocaleId,
        personnel_id: personalDetailForm.values?.personnel?.value,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          return data[0]?.is_match_flag;
        })
        .catch((e) => {
          setShowErrorAlert(true);
          setShowErrorAlertMsg(e.error);
          setIsLoading(false);
          return;
        });
      if (validateChallengeResponse === Y_CHECKER) {
        if (personnalID) {
          setIsEditClick(true);
        }
        setIsAuthenticated(true);
        setIsLoading(false);
        return;
      } else {
        setIsAuthenticationError(true);
        setIsLoading(false);
        return;
      }
    }
  };

  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  const onBypassClick = () => {
    if (!personalDetailForm?.values?.bypassReason) {
      setIsBypassError(true);
      return;
    }
    if (personnalID) {
      setIsEditClick(true);
    }
    setIsBypassError(false);
    setIsAuthenticated(true);
  };

  const handleEnableSecureSyncChange = (event) => {
    personalDetailForm.setFieldValue(
      "isSecureSyncEnabled",
      event.target.checked
    );
  };

  const getCountryData = async () => {
    const countryResult = await getCountries();
    const countryData = getSortedData(countryResult, "name", "asc");
    const countryResponse = countryData.map((res) => ({
      ...res,
      value: `${res.country_id}`,
      label: `${res.name} - ${res.phone_country_code}`,
      id: res.country_id
    }));

    setAllCountries(countryResponse);
  };

  // handle country
  const onCountryChange = (event, customer) => {
    personalDetailForm.handleChange({
      target: { name: "selectedCountry", value: customer }
    });
  };

  // handle country in customer detail
  const onCustomerDetailsCountryChange = (event, customer) => {
    personalDetailForm.handleChange({
      target: { name: "customerDetailSelectedCountry", value: customer }
    });
  };

  const handleChangeContractOwner = (event) => {
    personalDetailForm.setFieldValue(
      "isContractOwnerChecked",
      event.target.checked
    );
  };

  const handleChangeDiscrepancyContact = (event) => {
    personalDetailForm.setFieldValue(
      "isDiscrepancyContactChecked",
      event.target.checked
    );
  };

  const handleChangeDRAOne = (event) => {
    personalDetailForm.setFieldValue("isDRAOneChecked", event.target.checked);
  };

  const handleChangeDRATwo = (event) => {
    personalDetailForm.setFieldValue("isDRATwoChecked", event.target.checked);
  };

  const handleChangeDRAThree = (event) => {
    personalDetailForm.setFieldValue("isDRAThreeChecked", event.target.checked);
  };

  const handleChangeDRAFour = (event) => {
    personalDetailForm.setFieldValue("isDRAFourChecked", event.target.checked);
  };

  const handleChangeIAOne = (event) => {
    personalDetailForm.setFieldValue("isIAOneChecked", event.target.checked);
  };

  const handleChangeIATwo = (event) => {
    personalDetailForm.setFieldValue("isIATwoChecked", event.target.checked);
  };

  const handleChangeIAThree = (event) => {
    personalDetailForm.setFieldValue("isIAThreeChecked", event.target.checked);
  };

  const handleChangeIAFour = (event) => {
    personalDetailForm.setFieldValue("isIAFourChecked", event.target.checked);
  };

  const handleChangeIAFive = (event) => {
    personalDetailForm.setFieldValue("isIAFiveChecked", event.target.checked);
  };

  const handleChangeIASix = (event) => {
    personalDetailForm.setFieldValue("isIASixChecked", event.target.checked);
  };

  const sendAuthorizationEmail = async (removedCustomers) => {
    // Send Authorization Changed Email
    const isSecureSyncUser = personalDetailForm?.values?.isSecureSyncEnabled;

    const {
      isDRAOneChecked,
      isIATwoChecked,
      isDRATwoChecked,
      isDRAThreeChecked,
      isDRAFourChecked,
      isIAOneChecked,
      isIAThreeChecked,
      isIAFourChecked,
      isIAFiveChecked,
      isIASixChecked
    } = personalDetailForm?.values || {};

    const newAuthorizations = [];

    // Define a mapping for authorization types and their formatted names
    const authorizationTypes = [
      {
        key: "Disaster Recovery",
        values: [1, 2, 3, 4],
        isChecked: [
          isDRAOneChecked,
          isDRATwoChecked,
          isDRAThreeChecked,
          isDRAFourChecked
        ]
      },
      {
        key: "Interaction",
        values: ["A", "B", "C", "D", "E", "R"],
        isChecked: [
          isIAOneChecked,
          isIATwoChecked,
          isIAThreeChecked,
          isIAFourChecked,
          isIAFiveChecked,
          isIASixChecked
        ]
      }
    ];

    // Iterate over authorization types and push checked values to newAuthorizations
    authorizationTypes.forEach(({ key, values, isChecked }) => {
      const formattedNames = [];
      values.forEach((formattedName, index) => {
        if (isChecked[index]) {
          formattedNames.push(formattedName);
        }
      });
      if (formattedNames.length > 0) {
        newAuthorizations.push({
          uniqueId: key,
          formattedName: formattedNames.join("")
        });
      }
    });
    // Recipients

    let recipients = [];
    // Logged In Employee
    const loggedInEmail = auth?.user?.business_email_addr;
    const loggedInName =
      auth?.user?.lower_first_name + auth?.user?.lower_last_name;
    const loggedInEmployee = {
      emailAddress: loggedInEmail,
      name: loggedInName
    };
    //Personnel
    const personnelEmail = personalDetailForm?.values?.businessEmail;
    const personalName =
      personalDetailForm?.values?.firstName +
      "," +
      personalDetailForm?.values?.lastName;
    const loggedInPersonnel = {
      emailAddress: personnelEmail,
      name: personalName
    };
    recipients.push(loggedInPersonnel);
    if (valuePersonnelAuth === authSection?.bypass) {
      recipients.push(loggedInEmployee);
    }

    // get the subject
    let emailSubject = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode80247
    );
    const mailType = "confirmation";
    const actionText = isSecureSyncUser
      ? await getMessageFromCode(errorMsgs.errorCode80248)
      : await getMessageFromCode(errorMsgs.errorCode80488);

    const footerText = isSecureSyncUser
      ? await getMessageFromCode(errorMsgs.errorCode80243)
      : await getMessageFromCode(errorMsgs.errorCode80408);
    const footerText2 = isSecureSyncUser
      ? await getMessageFromCode(errorMsgs.errorCode80408)
      : "";

    let customerNumbers = VALUE_EMPTY_STRING;
    if (removedCustomers) {
      customerNumbers = VALUE_EMPTY_STRING;
    } else {
      if (selectedCustomers.length !== 0) {
        const customerNumberList = selectedCustomers?.map(
          (customer) => customer.customer_number
        );
        customerNumbers = customerNumberList.join(", ");
      } else {
        customerNumbers =
          personalDetailForm?.values?.customerDetailCustomer?.number;
      }
    }
    const isNewOrReset = false;
    const nonSecureSyncLogin = !isSecureSyncUser;

    const mailBody = await generateConfirmationMailBody({
      actionText,
      footerText,
      footerText2,
      customerNumbers,
      newAuthorizations,
      isNewOrReset,
      nonSecureSyncLogin,
      personnelEmail,
      loggedInEmployee,
      mailType,
      personalName,
      removedCustomers
    });

    // email sending
    const isEmailSend = await emailSendingTask(
      recipients,
      emailSubject?.errorMsg,
      mailBody
    );
    if (isEmailSend) {
      setIsLoading((prev) => false);
    } else {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode64080
      );

      setShowErrorAlertMsg(
        errMsg?.errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setShowErrorAlert(true);
      setIsLoading((prev) => false);
      return;
    }
  };

  const sendCustomerRemovalEmail = async ({ recipients, successOnes }) => {
    const isSecureSyncUser = personalDetailForm?.values?.isSecureSyncEnabled;

    const newAuthorizations = null;

    //Personnel
    const personalName =
      personalDetailForm?.values?.firstName +
      "," +
      personalDetailForm?.values?.lastName;

    const personnelEmail = personalDetailForm?.values?.businessEmail;
    // Logged In Employee
    const loggedInEmail = auth?.user?.business_email_addr;

    // get the subject
    const subJectCode = isSecureSyncUser
      ? errorMsgs.errorCode64110
      : errorMsgs.errorCode80486;
    let emailSubject = await getTheFirestoreErrorMessage(subJectCode);

    const actionCode = isSecureSyncUser
      ? errorMsgs.errorCode64082
      : errorMsgs.errorCode80487;
    const actionText = await getMessageFromCode(actionCode);

    const footerText = isSecureSyncUser
      ? await getMessageFromCode(errorMsgs.errorCode80243)
      : await getMessageFromCode(errorMsgs.errorCode80408);
    const footerText2 = isSecureSyncUser
      ? await getMessageFromCode(errorMsgs.errorCode80408)
      : null;

    const removedCustomers = successOnes;

    const isNewOrReset = false;
    const nonSecureSyncLogin = !isSecureSyncUser;

    const mailBody = await generateConfirmationMailBody({
      actionText,
      footerText,
      footerText2,
      removedCustomers,
      newAuthorizations,
      isNewOrReset,
      nonSecureSyncLogin,
      loggedInEmail,
      personalName,
      personnelEmail,
      mailType: "confirmation"
    });
    // email sending
    const isEmailSend = await emailSendingTask(
      recipients,
      emailSubject?.errorMsg,
      mailBody
    );
    if (isEmailSend) {
      setIsLoading((prev) => false);
    } else {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode64080
      );

      setShowErrorAlertMsg(
        errMsg?.errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setShowErrorAlert(true);
      setIsLoading((prev) => false);
      return;
    }
  };

  // save button click
  const onSaveClick = async () => {
    setIsLoading(true);
    setRequiredFieldValidations({
      firstName: false,
      lastName: false,
      businessEmail: false,
      businessPhone: false,
      customerDetailCustomer: false,
      middleName: false,
      homeEmail: false,
      confirmHomeEmail: false,
      confirmBusinessEmail: false,
      homePhone: false,
      cellular: false,
      selectedCountry: false,
      pager: false,
      pagerPin: false,
      extension: false,
      businessFax: false
    });
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    try {
      if (isAddingANewCustomer) {
        const validations = await saveValidationSchema();

        if (validations?.length > 0) {
          setShowErrorAlert(true);
          setShowErrorAlertMsg(validations);
          setIsLoading(false);
          return;
        }
        const customerIdList = await createCustomerIdList();
        const drValues = await createDRValues();
        const iaValues = await createIAValues();
        const customerLocalID = await getLocaleById(
          personalDetailForm?.values?.customerDetailSelectedCountry?.country_id,
          FIREBASE_FIELD_NAMES?.COUNTRY_ID
        );
        let requestBody = JSON.stringify({
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          requesting_personnel_id: personalDetailForm?.values?.personnel?.value,
          personnel_id: personnalID,
          customer_id_list: customerIdList,
          business_phone: personalDetailForm?.values?.businessPhone,
          business_phone_ext: personalDetailForm?.values?.extension,
          business_fax: personalDetailForm?.values?.businessFax,
          business_locale_id: customerLocalID[0]?.locale_id,
          employee_number: personalDetailForm?.values?.customerEmployeeNumber,
          contract_owner_flag: personalDetailForm?.values
            ?.isContractOwnerChecked
            ? Y_CHECKER
            : N_CHECKER,
          discrepancy_contact_flag: personalDetailForm?.values
            ?.isDiscrepancyContactChecked
            ? Y_CHECKER
            : N_CHECKER,
          update_contacts: Y_CHECKER,
          dr_auth_code: drValues,
          dr_auth_action: Y_CHECKER,
          int_auth_code: iaValues,
          int_auth_action: Y_CHECKER,
          last_mod_user: auth?.user?.login,
          employee_id: auth?.user?.id,
          auth_bypass_reason: personalDetailForm?.values?.bypassReason
        });
        const personnelData = await getResponseData(
          requestBody,
          `${CF_URLS.personnelDetail?.customerpersonnelupdate}`,
          1
        );
        let personnelDataResponse = personnelData?.data[0]; // get error code
        let successOnes = [];
        let failedOnes = [];
        for (let index = 0; index < personnelDataResponse.length; index++) {
          const element = personnelDataResponse[index];
          if (element?.error_code === errorMsgs?.noError) {
            successOnes?.push(element?.customer_id);
          } else {
            failedOnes?.push(element?.error_code);
          }
        }
        if (failedOnes?.length) {
          const errMsg = await getTheFirestoreErrorMessage(failedOnes[0]);
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setIsLoading(false);
          return;
        }

        const infoCode = personnelDataResponse[0]?.info_code;

        const valuesArray = infoCode.split(",").map(Number);

        const hasGreaterThanZero = valuesArray.some((value) => value > 0);
        const hasMultipleValues = valuesArray.length > 1;

        if (hasGreaterThanZero || hasMultipleValues) {
          const valuesToProcess = valuesArray.filter((value) => value > 0);

          let infoMsgs = [];

          for (const value of valuesToProcess) {
            const infoMsg = await getTheFirestoreErrorMessage(`${value}`);

            let msg = infoMsg?.errorMsg;
            msg = msg.replace("|", selectedCustomers?.length);
            infoMsgs?.push(msg);
          }

          setInfoInsertMessage(infoMsgs);
          setMessageInfo(true);
        }

        const localID = await getLocaleById(
          personalDetailForm?.values?.selectedCountry?.country_id,
          FIREBASE_FIELD_NAMES?.COUNTRY_ID
        );
        // Updating an existing personnel
        let reqBody = {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          personnel_id: personnalID,
          employee_id: auth?.user?.id,
          first_name: personalDetailForm?.values?.firstName,
          middle_name: personalDetailForm?.values?.middleName,
          last_name: personalDetailForm?.values?.lastName,
          personnel_locale_id: localID[0]?.locale_id,
          cellular_phone: personalDetailForm?.values?.cellular,
          home_phone: personalDetailForm?.values?.homePhone,
          pager_phone: personalDetailForm?.values?.pager,
          pager_pin_number: personalDetailForm?.values?.pagerPin,
          home_email_addr: personalDetailForm?.values?.homeEmail,
          personnel_ts: personalDetailForm?.values?.timeStamp,
          last_mod_user: auth?.user?.login,
          requesting_personnel_id: personalDetailForm?.values?.personnel?.value,
          system_id: "",
          auth_bypass_reason: personalDetailForm?.values?.bypassReason,
          business_email_addr: personalDetailForm?.values?.businessEmail,
          is_secure_sync_user: personalDetailForm?.values?.isSecureSyncEnabled
            ? secureSyncUser?.yes
            : secureSyncUser?.no
        };
        const data = await getResponseData(
          reqBody,
          `${CF_URLS.personnelDetail?.updatepersonnelprofile}`,
          2
        );
        let personnelRecordUpdateHasError = data?.data[0]; // get error code
        if (
          Object.keys(personnelRecordUpdateHasError[0])?.includes(
            "Personnel_Timestamp"
          )
        ) {
          const successMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode64095
          );
          let msg = successMsg?.errorMsg;
          msg = msg.replace("|", t("personnelDetail.thisrecord"));
          const currentDate = new Date().toLocaleString();
          msg = msg.replace("|", currentDate);
          setSuccessInsertMessage(msg);
          setMessageSuccess(true);
          setSelectedCustomers([]);
        }
        if (Object.keys(personnelRecordUpdateHasError[0])?.includes("error")) {
          const errMsg = await getTheFirestoreErrorMessage(
            personnelRecordUpdateHasError[0]?.error
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setIsLoading(false);
          return;
        }

        sendAuthorizationEmail();
        setIsLoading(false);

        if (valuePersonnelAuth === authSection?.bypass) {
          setIsAuthenticated(false);
          setShowAuthenticationSection(false);
          personalDetailForm.setFieldValue("isDRAOneChecked", false);
          personalDetailForm.setFieldValue("isDRATwoChecked", false);
          personalDetailForm.setFieldValue("isDRAThreeChecked", false);
          personalDetailForm.setFieldValue("isDRAFourChecked", false);
          personalDetailForm.setFieldValue("isIAOneChecked", false);
          personalDetailForm.setFieldValue("isIATwoChecked", false);
          personalDetailForm.setFieldValue("isIAThreeChecked", false);
          personalDetailForm.setFieldValue("isIAFourChecked", false);
          personalDetailForm.setFieldValue("isIAFiveChecked", false);
          personalDetailForm.setFieldValue("isIASixChecked", false);
          personalDetailForm.setFieldValue("isContractOwnerChecked", false);
          personalDetailForm.setFieldValue(
            "isDiscrepancyContactChecked",
            false
          );
          personalDetailForm.setFieldValue("customerDetailCustomer", "");
          personalDetailForm.setFieldValue("customerEmployeeNumber", "");
          personalDetailForm.setFieldValue("businessPhone", "");
          personalDetailForm.setFieldValue("extension", "");
          personalDetailForm.setFieldValue("businessFax", "");
          setIsEditClick(false);
        }
        setIsAddingANewCustomer(false);
        getNavigatedPersonnelDetails(personnalID);
      } else if (isEditClick || personnalID) {
        // update an existing personnel
        const validations = await saveValidationSchema();

        if (validations?.length > 0) {
          setShowErrorAlert(true);
          setShowErrorAlertMsg(validations);
          setIsLoading(false);
          return;
        }
        const localID = await getLocaleById(
          personalDetailForm?.values?.selectedCountry?.country_id,
          FIREBASE_FIELD_NAMES?.COUNTRY_ID
        );

        let reqBody = {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          personnel_id: personnalID,
          employee_id: auth?.user?.id,
          first_name: personalDetailForm?.values?.firstName,
          middle_name: personalDetailForm?.values?.middleName,
          last_name: personalDetailForm?.values?.lastName,
          personnel_locale_id: localID[0]?.locale_id,
          cellular_phone: personalDetailForm?.values?.cellular,
          home_phone: personalDetailForm?.values?.homePhone,
          pager_phone: personalDetailForm?.values?.pager,
          pager_pin_number: personalDetailForm?.values?.pagerPin,
          home_email_addr: personalDetailForm?.values?.homeEmail,
          personnel_ts: personalDetailForm?.values?.timeStamp,
          last_mod_user: auth?.user?.login,
          requesting_personnel_id: personalDetailForm?.values?.personnel?.value,
          system_id: "",
          auth_bypass_reason: personalDetailForm?.values?.bypassReason,
          business_email_addr: personalDetailForm?.values?.businessEmail,
          is_secure_sync_user: personalDetailForm?.values?.isSecureSyncEnabled
            ? secureSyncUser?.yes
            : secureSyncUser?.no
        };

        const data = await getResponseData(
          reqBody,
          `${CF_URLS.personnelDetail?.updatepersonnelprofile}`,
          4
        );

        let personnelRecordUpdateHasError = data?.data[0]; // get error code

        /**
         * @description AUTHORIZATION LEVEL UPDATE EMAIL
         */
        sendAuthorizationEmail();

        if (Object.keys(personnelRecordUpdateHasError[0])?.includes("error")) {
          const errMsg = await getTheFirestoreErrorMessage(
            personnelRecordUpdateHasError[0]?.error
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setIsLoading(false);
          return;
        }

        personalDetailForm.setFieldValue(
          "timeStamp",
          personnelRecordUpdateHasError[0]?.Personnel_Timestamp
        );

        const successMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode64095
        );
        let msg = successMsg?.errorMsg;
        msg = msg.replace("|", t("personnelDetail.identificationDetails"));
        const currentDate = new Date().toLocaleString();
        msg = msg.replace("|", currentDate);
        setSuccessInsertMessage(msg);
        setMessageSuccess(true);
        setSelectedCustomers([]);

        // integrate the sso login part
        if (personalDetailForm?.values?.isSecureSyncEnabled) {
          try {
            const checkSSOUser = {
              userId:
                personalDetailForm?.values?.userId ||
                personalDetailForm?.values?.businessEmail
            };

            const ssoUserResponse = await callToAPI(
              checkSSOUser,
              CF_URLS.personnelDetail?.checkSSOUserExist
            );

            const region = process.env.REACT_APP_CF_REGION;
            const environment = process.env.REACT_APP_CF_ENV;

            let pathString = "";

            if (
              process.env.REACT_APP_ENV === "prod" ||
              process.env.REACT_APP_ENV === "nonprod"
            ) {
              pathString = `sb-${region}`;
            } else {
              pathString = `sb-${environment}-${region}`;
            }

            const envs = await getENVDetails();

            const selectedUserEnv = envs[pathString];

            if (ssoUserResponse?.response?.data?.isSuccess === false) {
              let ssoReqBody = {
                login: personalDetailForm?.values?.businessEmail,
                firstName: personalDetailForm?.values?.firstName,
                lastName: personalDetailForm?.values?.lastName,
                middleName: personalDetailForm?.values?.middleName,
                email: personalDetailForm?.values?.businessEmail,
                appAccess: {
                  appsAdd: [
                    {
                      tenantId: parseInt(selectedUserEnv?.TenantId),
                      tenantAppId: parseInt(selectedUserEnv?.TenantAppId),
                      appCode: selectedUserEnv?.AppCode
                    }
                  ]
                }
              };

              const response = await callToAPI(
                ssoReqBody,
                CF_URLS.personnelDetail?.createSSOUser
              );

              if (response?.data?.result?.id) {
                let ssoLoginRequest = {
                  main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                  personnel_id: personnalID,
                  ciam_uid: response?.data?.result?.id,
                  rc: ""
                };

                const data = await getResponseData(
                  ssoLoginRequest,
                  `${CF_URLS.personnelDetail?.setssouser}`,
                  1
                );

                const ssoLoginRequestResponse = data?.data[0];

                if (ssoLoginRequestResponse[0]?.error === errorMsgs?.noError) {
                  const successMsg = await getTheFirestoreErrorMessage(
                    errorMsgs?.errorCode80483
                  );
                  setSuccessInsertMessage(successMsg?.errorMsg);
                  setMessageSuccess(true);
                  setIsLoading(false);
                } else {
                  setShowErrorAlert(true);
                  setShowErrorAlertMsg(
                    t("personnelDetail.unableToSetSSOLogin")
                  );
                  setIsLoading(false);
                }
              } else {
                let ssoLoginRequest = {
                  main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                  personnel_id: personnalID,
                  error_msg: response?.response?.data?.error?.errorMessage,
                  rc: ""
                };

                await getResponseData(
                  ssoLoginRequest,
                  `${CF_URLS.personnelDetail?.userupdatesso}`,
                  1
                );

                const errMsg = await getTheFirestoreErrorMessage(
                  errorMsgs?.errorCode80482
                );
                setShowErrorAlert(true);
                setShowErrorAlertMsg(errMsg?.errorMsg);
                setIsLoading(false);
              }
            } else {
              let res = ssoUserResponse?.data?.result?.appAccess?.apps?.filter(
                (e) =>
                  e.tenantId === parseInt(selectedUserEnv?.TenantId) &&
                  e.tenantAppId === parseInt(selectedUserEnv?.TenantAppId) &&
                  e.appCode === selectedUserEnv?.AppCode
              );

              const updateUserBody = {
                userId: ssoUserResponse?.data?.result?.id,
                firstName: personalDetailForm?.values?.firstName,
                lastName: personalDetailForm?.values?.lastName,
                middleName: personalDetailForm?.values?.middleName,
                email: personalDetailForm?.values?.businessEmail,
                appAccess: !res?.length
                  ? {
                      appsAdd: [
                        {
                          tenantId: parseInt(selectedUserEnv?.TenantId),
                          tenantAppId: parseInt(selectedUserEnv?.TenantAppId),
                          appCode: selectedUserEnv?.AppCode
                        }
                      ]
                    }
                  : {}
              };

              const response = await callToAPI(
                updateUserBody,
                CF_URLS.personnelDetail?.updateSSOUser
              );

              if (response?.data?.result?.id) {
                let ssoLoginDSP = {
                  main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                  ciam_uid: ssoUserResponse?.data?.result?.id,
                  is_ciam_error: N_CHECKER,
                  rc: ""
                };

                const data = await getResponseData(
                  ssoLoginDSP,
                  `${CF_URLS.personnelDetail?.userremovesso}`,
                  1
                );

                const ssoRemoveRequestResponse = data?.data[0];

                if (ssoRemoveRequestResponse[0]?.error === errorMsgs?.noError) {
                  const successMsg = await getTheFirestoreErrorMessage(
                    errorMsgs?.errorCode80483
                  );
                  setSuccessInsertMessage(successMsg?.errorMsg);
                  setMessageSuccess(true);
                  setIsLoading(false);
                } else {
                  setShowErrorAlert(true);
                  setShowErrorAlertMsg(
                    t("personnelDetail.unableToSetSSOLogin")
                  );
                  setIsLoading(false);
                }
              } else {
                let ssoLoginRequest = {
                  main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                  personnel_id: ssoUserResponse?.data?.result?.id,
                  error_msg: response?.response?.data?.error?.errorMessage,
                  rc: ""
                };

                await getResponseData(
                  ssoLoginRequest,
                  `${CF_URLS.personnelDetail?.userupdatesso}`,
                  1
                );

                setShowErrorAlert(true);
                setShowErrorAlertMsg(t("personnelDetail.unableToSetSSOLogin"));
                setIsLoading(false);
              }
            }
          } catch (error) {
            setShowErrorAlert(true);
            setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
            setIsLoading(false);
          }
        }

        if (
          personalDetailForm?.values?.customerDetailCustomer.customer_id ||
          selectedCustomers?.length
        ) {
          const customerIdList = await createCustomerIdList();
          const drValues = await createDRValues();
          const iaValues = await createIAValues();
          const customerLocalID = await getLocaleById(
            personalDetailForm?.values?.customerDetailSelectedCountry
              ?.country_id,
            FIREBASE_FIELD_NAMES?.COUNTRY_ID
          );
          let requestBody = JSON.stringify({
            main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
            requesting_personnel_id:
              personalDetailForm?.values?.personnel?.value,
            personnel_id: personnalID,
            customer_id_list: customerIdList,
            business_phone: personalDetailForm?.values?.businessPhone,
            business_phone_ext: personalDetailForm?.values?.extension,
            business_fax: personalDetailForm?.values?.businessFax,
            business_locale_id: customerLocalID[0]?.locale_id,
            employee_number: personalDetailForm?.values?.customerEmployeeNumber,
            contract_owner_flag: personalDetailForm?.values
              ?.isContractOwnerChecked
              ? Y_CHECKER
              : N_CHECKER,
            discrepancy_contact_flag: personalDetailForm?.values
              ?.isDiscrepancyContactChecked
              ? Y_CHECKER
              : N_CHECKER,
            update_contacts: Y_CHECKER,
            dr_auth_code: drValues,
            dr_auth_action: Y_CHECKER,
            int_auth_code: iaValues,
            int_auth_action: Y_CHECKER,
            last_mod_user: auth?.user?.login,
            employee_id: auth?.user?.id,
            auth_bypass_reason: personalDetailForm?.values?.bypassReason
          });
          const personnelData = await getResponseData(
            requestBody,
            `${CF_URLS.personnelDetail?.customerpersonnelupdate}`,
            1
          );

          let personnelDataHasError = personnelData?.data[0]; // get error code

          if (personnelDataHasError[0]?.error_code !== errorMsgs?.noError) {
            const errMsg = await getTheFirestoreErrorMessage(
              personnelDataHasError[0]?.error_code
            );
            setShowErrorAlert(true);
            setShowErrorAlertMsg(errMsg?.errorMsg);
            setIsLoading(false);
            getNavigatedPersonnelDetails(personnalID);
            return;
          }

          const infoCode = personnelDataHasError[0]?.info_code;

          const valuesArray = infoCode.split(",").map(Number);

          const hasGreaterThanZero = valuesArray.some((value) => value > 0);
          const hasMultipleValues = valuesArray.length > 1;

          if (hasGreaterThanZero || hasMultipleValues) {
            const valuesToProcess = valuesArray.filter((value) => value > 0);

            let infoMsgs = [];

            for (const value of valuesToProcess) {
              const infoMsg = await getTheFirestoreErrorMessage(`${value}`);

              let msg = infoMsg?.errorMsg;
              msg = msg.replace("|", selectedCustomers?.length);
              infoMsgs?.push(msg);
            }

            setInfoInsertMessage(infoMsgs);
            setMessageInfo(true);
          }

          const successMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode64095
          );
          let msg = successMsg?.errorMsg;
          msg = msg.replace("|", t("personnelDetail.thisrecord"));
          const currentDate = new Date().toLocaleString();
          msg = msg.replace("|", currentDate);
          setSuccessInsertMessage(msg);
          setMessageSuccess(true);
          setSelectedCustomers([]);
          setIsCustomerSelectedFromTheTable(false);
          setIsEditClick(false);

          /**
           * @description AUTHORIZATION LEVEL UPDATE EMAIL
           */
          sendAuthorizationEmail();
        }

        if (valuePersonnelAuth === authSection?.bypass) {
          setIsAuthenticated(false);
          setShowAuthenticationSection(false);
          personalDetailForm.setFieldValue("isDRAOneChecked", false);
          personalDetailForm.setFieldValue("isDRATwoChecked", false);
          personalDetailForm.setFieldValue("isDRAThreeChecked", false);
          personalDetailForm.setFieldValue("isDRAFourChecked", false);
          personalDetailForm.setFieldValue("isIAOneChecked", false);
          personalDetailForm.setFieldValue("isIATwoChecked", false);
          personalDetailForm.setFieldValue("isIAThreeChecked", false);
          personalDetailForm.setFieldValue("isIAFourChecked", false);
          personalDetailForm.setFieldValue("isIAFiveChecked", false);
          personalDetailForm.setFieldValue("isIASixChecked", false);
          personalDetailForm.setFieldValue("isContractOwnerChecked", false);
          personalDetailForm.setFieldValue(
            "isDiscrepancyContactChecked",
            false
          );
          personalDetailForm.setFieldValue("customerDetailCustomer", "");
          personalDetailForm.setFieldValue("customerEmployeeNumber", "");
          personalDetailForm.setFieldValue("businessPhone", "");
          personalDetailForm.setFieldValue("extension", "");
          personalDetailForm.setFieldValue("businessFax", "");
        }

        getNavigatedPersonnelDetails(personnalID);
        setIsLoading(false);
      } else {
        // new personnel detail save
        const validations = await saveValidationSchema();

        if (validations?.length > 0) {
          setShowErrorAlert(true);
          setShowErrorAlertMsg(validations);
          setIsLoading(false);
          return;
        }

        const customerIdList = await createCustomerIdList();
        const drValues = await createDRValues();
        const iaValues = await createIAValues();
        const localID = await getLocaleById(
          personalDetailForm?.values?.selectedCountry?.country_id,
          FIREBASE_FIELD_NAMES?.COUNTRY_ID
        );
        const customerLocalID = await getLocaleById(
          personalDetailForm?.values?.customerDetailSelectedCountry?.country_id,
          FIREBASE_FIELD_NAMES?.COUNTRY_ID
        );
        let reqBody = {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          customer_id_list: customerIdList,
          first_name: personalDetailForm?.values?.firstName,
          middle_name: personalDetailForm?.values?.middleName,
          last_name: personalDetailForm?.values?.lastName,
          password: initPw?.initialPw,
          personnel_locale_id: localID[0]?.locale_id,
          cellular_phone: personalDetailForm?.values?.cellular,
          home_phone: personalDetailForm?.values?.homePhone,
          pager_phone: personalDetailForm?.values?.pager,
          pager_pin_number: personalDetailForm?.values?.pagerPin,
          home_email_addr: personalDetailForm?.values?.homeEmail,
          business_locale_id: customerLocalID[0]?.locale_id,
          business_email_addr: personalDetailForm?.values?.businessEmail,
          business_fax: personalDetailForm?.values?.businessFax,
          business_phone: personalDetailForm?.values?.businessPhone,
          business_phone_ext: personalDetailForm?.values?.extension,
          employee_number: personalDetailForm?.values?.customerEmployeeNumber,
          contract_owner_flag: personalDetailForm?.values
            ?.isContractOwnerChecked
            ? Y_CHECKER
            : N_CHECKER,
          discrepancy_contact_flag: personalDetailForm?.values
            ?.isDiscrepancyContactChecked
            ? Y_CHECKER
            : N_CHECKER,
          update_contacts: Y_CHECKER,
          dr_auth_code: drValues, // dr values
          dr_auth_action: Y_CHECKER,
          int_auth_code: iaValues, // auth codes
          int_auth_action: Y_CHECKER,
          last_mod_user: "", // no need to pass from fe
          last_mod_date: "", // no need to pass from fe
          login_to_assign: "", // no need to pass from fe
          new_personnel_id: "", // no need to pass from fe
          requesting_personnel_id: personalDetailForm?.values?.personnel?.value,
          employee_id: auth?.user?.id,
          auth_bypass_reason: personalDetailForm?.values?.bypassReason,
          is_secure_sync_user: personalDetailForm?.values?.isSecureSyncEnabled
            ? secureSyncUser?.yes
            : secureSyncUser?.no
        };

        const data = await getResponseData(
          reqBody,
          `${CF_URLS.personnelDetail?.personnelrecordinsertion}`,
          3
        );

        let personnelrecordinsertionHasError = data?.data[0]; // get personnel save error code 1
        let personnelrecordinsertionError = data?.data[1]; // get customer save error code 2

        if (
          personnelrecordinsertionHasError[0]?.error_code !== errorMsgs?.noError
        ) {
          let msgArray = [];
          const errMsg1 = await getTheFirestoreErrorMessage(
            personnelrecordinsertionHasError[0]?.error_code
          );
          msgArray?.push(errMsg1?.errorMsg);

          let successOnes = [];

          for (
            let index = 0;
            index < personnelrecordinsertionError.length;
            index++
          ) {
            const element = personnelrecordinsertionError[index];

            if (element?.error_code === errorMsgs?.noError) {
              successOnes?.push(element?.customer_id);
            } else {
              const errMsg = await getTheFirestoreErrorMessage(
                element?.error_code
              );
              msgArray?.push(errMsg?.errorMsg);
            }
          }

          setShowErrorAlert(true);
          setShowErrorAlertMsg(msgArray);
          setIsLoading(false);
          return;
        }

        const successMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode80169
        );
        let msg = successMsg?.errorMsg;
        const currentDate = new Date().toLocaleString();
        msg = msg.replace("{0}", currentDate);
        setSuccessInsertMessage(msg);
        setMessageSuccess(true);
        setSelectedCustomers([]);
        setIsCustomerSelectedFromTheTable(false);

        // integrate the sso login part
        if (personalDetailForm?.values?.isSecureSyncEnabled) {
          try {
            const checkSSOUser = {
              userId: personalDetailForm?.values?.businessEmail
            };

            const ssoUserResponse = await callToAPI(
              checkSSOUser,
              CF_URLS.personnelDetail?.checkSSOUserExist
            );

            const region = process.env.REACT_APP_CF_REGION;
            const environment = process.env.REACT_APP_CF_ENV;

            let pathString = "";

            if (
              process.env.REACT_APP_ENV === "prod" ||
              process.env.REACT_APP_ENV === "nonprod"
            ) {
              pathString = `sb-${region}`;
            } else {
              pathString = `sb-${environment}-${region}`;
            }

            const envs = await getENVDetails();

            const selectedUserEnv = envs[pathString];

            if (ssoUserResponse?.response?.data?.isSuccess === false) {
              let ssoReqBody = {
                login: personalDetailForm?.values?.businessEmail,
                firstName: personalDetailForm?.values?.firstName,
                lastName: personalDetailForm?.values?.lastName,
                middleName: personalDetailForm?.values?.middleName,
                email: personalDetailForm?.values?.businessEmail,
                appAccess: {
                  appsAdd: [
                    {
                      tenantId: parseInt(selectedUserEnv?.TenantId),
                      tenantAppId: parseInt(selectedUserEnv?.TenantAppId),
                      appCode: selectedUserEnv?.AppCode
                    }
                  ]
                }
              };

              const response = await callToAPI(
                ssoReqBody,
                CF_URLS.personnelDetail?.createSSOUser
              );

              if (response?.data?.result?.id) {
                let ssoLoginRequest = {
                  main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                  personnel_id:
                    personnelrecordinsertionHasError[0]?.personnel_id,
                  ciam_uid: response?.data?.result?.id,
                  rc: ""
                };

                const data = await callToAPI(
                  ssoLoginRequest,
                  `${CF_URLS.personnelDetail?.setssouser}`,
                  1
                );

                if (data?.data?.hasResults !== false) {
                  const successMsg = await getTheFirestoreErrorMessage(
                    errorMsgs?.errorCode80483
                  );
                  setSuccessInsertMessage(successMsg?.errorMsg);
                  setMessageSuccess(true);
                  setIsLoading(false);
                } else {
                  let ssoLoginRequest = {
                    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                    personnel_id:
                      personnelrecordinsertionHasError[0]?.personnel_id,
                    error_msg: t("personnelDetail.unableToSetSSOLogin"),
                    rc: ""
                  };

                  await getResponseData(
                    ssoLoginRequest,
                    `${CF_URLS.personnelDetail?.userupdatesso}`,
                    1
                  );

                  const errMsg = await getTheFirestoreErrorMessage(
                    errorMsgs?.errorCode80482
                  );
                  setShowErrorAlert(true);
                  setShowErrorAlertMsg(errMsg?.errorMsg);
                  setIsLoading(false);
                }
              } else {
                let ssoLoginRequest = {
                  main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                  personnel_id:
                    personnelrecordinsertionHasError[0]?.personnel_id,
                  error_msg: response?.response?.data?.error?.errorMessage,
                  rc: ""
                };

                await getResponseData(
                  ssoLoginRequest,
                  `${CF_URLS.personnelDetail?.userupdatesso}`,
                  1
                );

                const errMsg = await getTheFirestoreErrorMessage(
                  errorMsgs?.errorCode80482
                );
                setShowErrorAlert(true);
                setShowErrorAlertMsg(errMsg?.errorMsg);
                setIsLoading(false);
              }
            } else {
              let res = ssoUserResponse?.data?.result?.appAccess?.apps?.filter(
                (e) =>
                  e.tenantId === parseInt(selectedUserEnv?.TenantId) &&
                  e.tenantAppId === parseInt(selectedUserEnv?.TenantAppId) &&
                  e.appCode === selectedUserEnv?.AppCode
              );

              if (res?.length) {
                let ssoLoginRequest = {
                  main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                  personnel_id:
                    personnelrecordinsertionHasError[0]?.personnel_id,
                  error_msg: t("personnelDetail.userAlreadyExists"),
                  rc: ""
                };

                await getResponseData(
                  ssoLoginRequest,
                  `${CF_URLS.personnelDetail?.userupdatesso}`,
                  1
                );

                const errMsg = await getTheFirestoreErrorMessage(
                  errorMsgs?.errorCode80490
                );
                setShowErrorAlert(true);
                setShowErrorAlertMsg(errMsg?.errorMsg);
                setIsLoading(false);
              } else {
                const updateUserBody = {
                  userId: ssoUserResponse?.data?.result?.id,
                  firstName: personalDetailForm?.values?.firstName,
                  lastName: personalDetailForm?.values?.lastName,
                  middleName: personalDetailForm?.values?.middleName,
                  email: personalDetailForm?.values?.businessEmail,
                  appAccess: {
                    appsAdd: [
                      {
                        tenantId: parseInt(selectedUserEnv?.TenantId),
                        tenantAppId: parseInt(selectedUserEnv?.TenantAppId),
                        appCode: selectedUserEnv?.AppCode
                      }
                    ]
                  }
                };

                const response = await callToAPI(
                  updateUserBody,
                  CF_URLS.personnelDetail?.updateSSOUser
                );

                if (response?.data?.result?.id) {
                  let ssoLoginDSP = {
                    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                    personnel_id:
                      personnelrecordinsertionHasError[0]?.personnel_id,
                    ciam_uid: ssoUserResponse?.data?.result?.id,
                    rc: ""
                  };

                  const data = await getResponseData(
                    ssoLoginDSP,
                    `${CF_URLS.personnelDetail?.setssouser}`,
                    1
                  );

                  const ssoRemoveRequestResponse = data?.data[0];

                  if (
                    ssoRemoveRequestResponse[0]?.error === errorMsgs?.noError
                  ) {
                    const successMsg = await getTheFirestoreErrorMessage(
                      errorMsgs?.errorCode80483
                    );
                    setSuccessInsertMessage(successMsg?.errorMsg);
                    setMessageSuccess(true);
                    setIsLoading(false);
                  } else {
                    setShowErrorAlert(true);
                    setShowErrorAlertMsg(
                      t("personnelDetail.unableToSetSSOLogin")
                    );
                    setIsLoading(false);
                  }
                } else {
                  let ssoLoginRequest = {
                    main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
                    personnel_id:
                      personnelrecordinsertionHasError[0]?.personnel_id,
                    error_msg: response?.response?.data?.error?.errorMessage,
                    rc: ""
                  };

                  await getResponseData(
                    ssoLoginRequest,
                    `${CF_URLS.personnelDetail?.userupdatesso}`,
                    1
                  );

                  setShowErrorAlert(true);
                  setShowErrorAlertMsg(
                    t("personnelDetail.unableToSetSSOLogin")
                  );
                  setIsLoading(false);
                }
              }
            }
          } catch (error) {
            setShowErrorAlert(true);
            setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
            setIsLoading(false);
            return;
          }
        }

        /**
         * @description SEND IDENTIFICATION MAIL
         */
        const isSecureSyncUser =
          personalDetailForm?.values?.isSecureSyncEnabled;

        let recipients = [];

        // Logged In Employee
        const loggedInEmail = auth?.user?.business_email_addr;
        const loggedInName =
          auth?.user?.lower_first_name + " " + auth?.user?.lower_last_name;
        const loggedInEmployee = {
          emailAddress: loggedInEmail,
          name: loggedInName
        };
        //Personnel
        const personnelEmail = personalDetailForm?.values?.businessEmail;
        const personalName =
          personalDetailForm?.values?.firstName +
          "," +
          personalDetailForm?.values?.lastName;
        const loggedInPersonnel = {
          emailAddress: personnelEmail,
          name: personalName
        };
        recipients.push(loggedInPersonnel);
        if (valuePersonnelAuth === authSection?.bypass) {
          recipients.push(loggedInEmployee);
        }

        // get the subject
        const subJectCode = isSecureSyncUser
          ? errorMsgs.errorCode80229
          : errorMsgs.errorCode80484;
        let emailSubject = await getTheFirestoreErrorMessage(subJectCode);
        const actionCode = isSecureSyncUser
          ? errorMsgs.errorCode80232
          : errorMsgs.errorCode80485;
        const actionText = await getMessageFromCode(actionCode);
        const actionText2 = isSecureSyncUser
          ? await getMessageFromCode(errorMsgs.errorCode80233)
          : null;
        const footerCode = isSecureSyncUser
          ? errorMsgs.errorCode80243
          : errorMsgs.errorCode80408;
        const footerText = await getMessageFromCode(footerCode);
        const footerText2 = isSecureSyncUser
          ? await getMessageFromCode(errorMsgs.errorCode80408)
          : null;
        let customerNumbers = VALUE_EMPTY_STRING;
        if (selectedCustomers.length !== 0) {
          const customerNumberList = selectedCustomers?.map(
            (customer) => customer.customer_number
          );
          customerNumbers = customerNumberList.join(", ");
        } else {
          customerNumbers =
            personalDetailForm?.values?.customerDetailCustomer?.number;
        }
        const newAuthorizations = null;
        const isNewOrReset = true;
        const nonSecureSyncLogin = !isSecureSyncUser;
        const mailType = "confirmation";
        const mailBody = await generateConfirmationMailBody({
          actionText,
          actionText2,
          footerText,
          footerText2,
          customerNumbers,
          newAuthorizations,
          isNewOrReset,
          nonSecureSyncLogin,
          personnelEmail,
          personalName,
          loggedInEmployee,
          mailType
        });

        // email sending
        const isEmailSend = await emailSendingTask(
          recipients,
          emailSubject.errorMsg,
          mailBody
        );
        if (isEmailSend) {
          setIsLoading((prev) => false);
        } else {
          const errMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode64080
          );

          setShowErrorAlertMsg(
            errMsg?.errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setShowErrorAlert(true);
          setIsLoading((prev) => false);
          return;
        }
        // Send Auth Details Email
        if (!isSecureSyncUser) {
          const authNumber = personnelrecordinsertionHasError[0]?.auth_number;
          const authPin = personnelrecordinsertionHasError[0]?.auth_pin;
          let emailSubjectAuth = await getTheFirestoreErrorMessage(
            errorMsgs.errorCode80491
          );

          const footerTextAuth = await getMessageFromCode(
            errorMsgs.errorCode80408
          );

          const mailBodyAuth = await generateConfirmationMailBody({
            footerText: footerTextAuth,
            loggedInEmployee,
            personnelEmail,
            mailType: "auth",
            authNumber,
            authPin
          });
          // email sending
          const isEmailSendAuth = await emailSendingTask(
            recipients,
            emailSubjectAuth?.errorMsg,
            mailBodyAuth
          );
          if (isEmailSendAuth) {
            setIsLoading((prev) => false);
          } else {
            setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
            setShowErrorAlert(true);
            setIsLoading((prev) => false);
            return;
          }
        }
        // if isSecureSyncEnabled === true
        // else isSecureSyncEnabled === false

        if (valuePersonnelAuth === authSection?.personnelAuth) {
          setIsEditClick(true);
          navigate(
            `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_AUTH_BASE_PATH}/${PERSONAL_DETAIL}/${personnelrecordinsertionHasError[0]?.personnel_id}`
          );
          setIsLoading(false);
        } else {
          personalDetailForm.setFieldValue("challengeQuestion", "");
          personalDetailForm.setFieldValue("challengeResponse", "");
          personalDetailForm.setFieldValue("customer", "");
          personalDetailForm.setFieldValue("selectedCustomer", "");
          personalDetailForm.setFieldValue("personnel", "");
          personalDetailForm.setFieldValue("authNumber", "");

          personalDetailForm.setFieldValue("isDRAOneChecked", false);
          personalDetailForm.setFieldValue("isDRATwoChecked", false);
          personalDetailForm.setFieldValue("isDRAThreeChecked", false);
          personalDetailForm.setFieldValue("isDRAFourChecked", false);
          personalDetailForm.setFieldValue("isIAOneChecked", false);
          personalDetailForm.setFieldValue("isIATwoChecked", false);
          personalDetailForm.setFieldValue("isIAThreeChecked", false);
          personalDetailForm.setFieldValue("isIAFourChecked", false);
          personalDetailForm.setFieldValue("isIAFiveChecked", false);
          personalDetailForm.setFieldValue("isIASixChecked", false);
          personalDetailForm.setFieldValue("isContractOwnerChecked", false);
          personalDetailForm.setFieldValue(
            "isDiscrepancyContactChecked",
            false
          );
          personalDetailForm.setFieldValue("customerDetailCustomer", "");
          personalDetailForm.setFieldValue("customerEmployeeNumber", "");
          personalDetailForm.setFieldValue("businessPhone", "");
          personalDetailForm.setFieldValue("extension", "");
          personalDetailForm.setFieldValue("businessFax", "");

          if (personnalID) {
            setIsEditClick(false);
          }
          setIsAuthenticated((prev) => false);
          navigate(
            `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_AUTH_BASE_PATH}/${PERSONAL_DETAIL}/${personnelrecordinsertionHasError[0]?.personnel_id}`
          );
        }
        setIsLoading(false);
      }
      setIsLoading(false);
      return;
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const createCustomerIdList = () => {
    let xmlString = `<?xml version="1.0" encoding="utf-8"?><Customers>`;
    if (selectedCustomers?.length) {
      selectedCustomers.forEach((customer) => {
        xmlString += `<Customer><Id>${customer.customer_id}</Id><AssociationVersion><![CDATA[${customer?.version}]]></AssociationVersion><DRAuthorizationVersion><![CDATA[${customer?.drTimeStamp}]]></DRAuthorizationVersion><InteractionVersion><![CDATA[${customer?.interactionAuthTimeStamp}]]></InteractionVersion></Customer>`;
      });
    } else {
      const cux = customersList?.filter(
        (c) =>
          c?.customer_id ===
          personalDetailForm?.values?.customerDetailCustomer.customer_id
      );
      if (cux?.length) {
        xmlString += `<Customer><Id>${cux[0]?.customer_id}</Id><AssociationVersion><![CDATA[${cux[0]?.version}]]></AssociationVersion><DRAuthorizationVersion><![CDATA[${cux[0]?.drTimeStamp}]]></DRAuthorizationVersion><InteractionVersion><![CDATA[${cux[0]?.interactionAuthTimeStamp}]]></InteractionVersion></Customer>`;
      } else {
        xmlString += `<Customer><Id>${personalDetailForm?.values?.customerDetailCustomer.customer_id}</Id><AssociationVersion><![CDATA[]]></AssociationVersion><DRAuthorizationVersion><![CDATA[]]></DRAuthorizationVersion><InteractionVersion><![CDATA[]]></InteractionVersion></Customer>`;
      }
    }
    xmlString += "</Customers>";
    return xmlString;
  };

  const createCustomerIdListByPassingCustomers = (selectedCustomersList) => {
    let xmlString = `<?xml version="1.0" encoding="utf-8"?><Customers>`;
    selectedCustomersList.forEach((customer) => {
      xmlString += `<Customer><Id>${customer.customer_id}</Id><AssociationVersion><![CDATA[${customer?.version}]]></AssociationVersion><DRAuthorizationVersion><![CDATA[${customer?.drTimeStamp}]]></DRAuthorizationVersion><InteractionVersion><![CDATA[${customer?.interactionAuthTimeStamp}]]></InteractionVersion></Customer>`;
    });
    xmlString += "</Customers>";
    return xmlString;
  };

  const createDRValues = () => {
    let resultString = "";

    if (personalDetailForm?.values?.isDRAOneChecked) {
      resultString += "1";
    }
    if (personalDetailForm?.values?.isDRATwoChecked) {
      resultString += "2";
    }
    if (personalDetailForm?.values?.isDRAThreeChecked) {
      resultString += "3";
    }
    if (personalDetailForm?.values?.isDRAFourChecked) {
      resultString += "4";
    }

    return resultString;
  };

  const createIAValues = () => {
    let resultString = "";

    if (personalDetailForm?.values?.isIAOneChecked) {
      resultString += "A";
    }
    if (personalDetailForm?.values?.isIATwoChecked) {
      resultString += "B";
    }
    if (personalDetailForm?.values?.isIAThreeChecked) {
      resultString += "C";
    }
    if (personalDetailForm?.values?.isIAFourChecked) {
      resultString += "D";
    }
    if (personalDetailForm?.values?.isIAFiveChecked) {
      resultString += "E";
    }
    if (personalDetailForm?.values?.isIASixChecked) {
      resultString += "R";
    }

    return resultString;
  };

  // validation schema
  const saveValidationSchema = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const specialCharacterRegex =
      /^([a-zA-Z .,‘']{0,29}[A-Za-z][a-zA-Z .,‘']{0,29})$/;
    const phoneRegex = /^(([0-9 .-]|\(|\)){1,25})$/;
    const pagerPinRegex = /^([A-Za-z0-9]{1,10})$/;
    const extentionRegex = /^(\d{1,6})$/;

    let errorFields = [];
    let requiredFieldValidations = {
      firstName: false,
      lastName: false,
      businessEmail: false,
      businessPhone: false,
      customerDetailCustomer: false,
      middleName: false,
      homeEmail: false,
      confirmHomeEmail: false,
      confirmBusinessEmail: false,
      homePhone: false,
      cellular: false,
      selectedCountry: false,
      pager: false,
      pagerPin: false,
      extension: false,
      businessFax: false
    };

    if (isAddingANewCustomer && authSection?.bypass) {
      const cux = customersList?.filter(
        (c) =>
          c?.customer_id ===
          personalDetailForm?.values?.customerDetailCustomer.customer_id
      );

      if (cux?.length) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode64108
        );
        let cust = errMsg?.errorMsg.replace(/\|/g, cux[0]?.customer);
        errorFields.push(cust);
      }
    }

    // first name
    if (personalDetailForm?.values?.firstName?.trim() === "") {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let firstNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("personnelDetail.fnameLabel")
      );
      errorFields.push(firstNameRequiredError);
      requiredFieldValidations.firstName = true;
    } else if (
      !specialCharacterRegex.test(personalDetailForm?.values?.firstName?.trim())
    ) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode80103
      );
      let firstNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("personnelDetail.fnameLabel")
      );
      errorFields.push(firstNameRequiredError);
      requiredFieldValidations.firstName = true;
    }

    // middle name
    if (personalDetailForm?.values?.middleName?.trim() !== "") {
      if (
        !specialCharacterRegex.test(
          personalDetailForm?.values?.middleName?.trim()
        )
      ) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode64093
        );
        let middleNameRequiredError = errMsg?.errorMsg.replace(
          /\|/g,
          t("personnelDetail.midnameLabel")
        );
        errorFields.push(middleNameRequiredError);
        requiredFieldValidations.middleName = true;
      }
    }

    // last name
    if (personalDetailForm?.values?.lastName?.trim() === "") {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let lastNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("personnelDetail.lnameLabelLabel")
      );
      errorFields.push(lastNameRequiredError);
      requiredFieldValidations.lastName = true;
    } else if (
      !specialCharacterRegex.test(personalDetailForm?.values?.lastName?.trim())
    ) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode80103
      );
      let firstNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("personnelDetail.lnameLabelLabel")
      );
      errorFields.push(firstNameRequiredError);
      requiredFieldValidations.lastName = true;
    }

    // business Email
    if (personalDetailForm?.values?.businessEmail?.trim() === "") {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let businessEmailRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("personnelDetail.businessEmailLabel")
      );
      errorFields.push(businessEmailRequiredError);
      requiredFieldValidations.businessEmail = true;
    } else if (
      !emailRegex.test(personalDetailForm?.values?.businessEmail?.trim())
    ) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode63581
      );
      let businessEmailRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("personnelDetail.businessEmailLabel")
      );
      errorFields.push(businessEmailRequiredError);
      requiredFieldValidations.businessEmail = true;
    }

    // business Email === confirm Business Email
    if (!personnalID || (personnalID && isEditClick)) {
      if (
        personalDetailForm?.values?.businessEmail?.trim() !==
        personalDetailForm?.values?.confirmBusinessEmail?.trim()
      ) {
        errorFields.push(t("personnelDetail.confirmBusinessEmail"));
        requiredFieldValidations.confirmBusinessEmail = true;
      }
    }

    // home Email
    if (personalDetailForm?.values?.homeEmail?.trim() !== "") {
      if (!emailRegex.test(personalDetailForm?.values?.homeEmail?.trim())) {
        errorFields.push(t("personnelDetail.invalidFeildHomeEmail"));
      }
    }

    // home Email === confirm Home Email
    if (!personnalID) {
      if (
        personalDetailForm?.values?.homeEmail?.trim().toLowerCase() !==
        personalDetailForm?.values?.confirmHomeEmail?.trim().toLowerCase()
      ) {
        errorFields.push(t("personnelDetail.confirmHomeEmail"));
        requiredFieldValidations.confirmHomeEmail = true;
      }
    }

    // home phone
    if (personalDetailForm?.values?.homePhone?.trim() !== "") {
      if (!phoneRegex.test(personalDetailForm?.values?.homePhone?.trim())) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode63688
        );
        let homePhoneRequiredError = errMsg?.errorMsg;

        homePhoneRequiredError = homePhoneRequiredError?.replace(
          /\|/,
          t("personnelDetail.homePhoneLabel")
        );
        const format = await getFormatsBasedOnCountry(
          personalDetailForm?.values?.selectedCountry?.country_id,
          "1"
        );
        homePhoneRequiredError = homePhoneRequiredError?.replace(
          /\|/,
          format[1]?.format_example
        );
        homePhoneRequiredError =
          homePhoneRequiredError + ` ${format[0]?.format_example}`;
        errorFields.push(homePhoneRequiredError);
        requiredFieldValidations.homePhone = true;
      }
    }

    // celluler
    if (personalDetailForm?.values?.cellular?.trim() !== "") {
      if (!phoneRegex.test(personalDetailForm?.values?.cellular?.trim())) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode63688
        );
        let cellularRequiredError = errMsg?.errorMsg;
        cellularRequiredError = cellularRequiredError?.replace(
          /\|/,
          t("personnelDetail.cellularLabel")
        );
        const format = await getFormatsBasedOnCountry(
          personalDetailForm?.values?.selectedCountry?.country_id,
          "2"
        );
        cellularRequiredError = cellularRequiredError?.replace(
          /\|/,
          format[0]?.format_example
        );
        errorFields.push(cellularRequiredError);
        requiredFieldValidations.cellular = true;
      }
    }

    // pager
    if (personalDetailForm?.values?.pager?.trim() !== "") {
      if (!phoneRegex.test(personalDetailForm?.values?.pager?.trim())) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode63688
        );
        let pagerRequiredError = errMsg?.errorMsg;

        pagerRequiredError = pagerRequiredError?.replace(
          /\|/,
          t("personnelDetail.pagerLabel")
        );
        const format = await getFormatsBasedOnCountry(
          personalDetailForm?.values?.selectedCountry?.country_id,
          "1"
        );
        pagerRequiredError = pagerRequiredError?.replace(
          /\|/,
          format[1]?.format_example
        );
        pagerRequiredError =
          pagerRequiredError + ` ${format[0]?.format_example}`;
        errorFields.push(pagerRequiredError);
        requiredFieldValidations.pager = true;
      }
    }

    // pager pin
    if (personalDetailForm?.values?.pagerPin?.trim() !== "") {
      if (!pagerPinRegex.test(personalDetailForm?.values?.pagerPin?.trim())) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode20360
        );
        let pagerRequiredError = errMsg?.errorMsg.replace(
          /\|/,
          t("personnelDetail.pagerLabel")
        );
        errorFields.push(pagerRequiredError);
        requiredFieldValidations.pagerPin = true;
      }
    }

    if (!isEditClick) {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      };

      const format = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.listExamplePhoneNumbers}`,
        2
      );

      const countryFormat = format?.data[0]?.find(
        (c) =>
          c?.phone_country_code ===
          personalDetailForm?.values?.customerDetailSelectedCountry
            ?.phone_country_code
      );
      let sortedAttributeType = format?.data[1]?.sort(
        (a, b) => a?.length - b?.length
      );

      const attributeType = sortedAttributeType?.find(
        (f) =>
          f?.country_id === countryFormat?.country_id &&
          f?.country_attribute_type_id === attribute_id
      );

      const attributeTypeForLength = sortedAttributeType
        ?.filter(
          (f) =>
            f?.country_id === countryFormat?.country_id &&
            f?.country_attribute_type_id === attribute_id
        )
        .map((item) => item.length);

      const lengthEqual = await isLengthEqualTo(
        personalDetailForm?.values?.businessPhone,
        attributeTypeForLength
      );

      // customer Detail Customer
      if (
        valuePersonnelAuth === authSection?.bypass &&
        (personalDetailForm?.values?.customerDetailCustomer === "" ||
          personalDetailForm?.values?.customerDetailCustomer === null)
      ) {
        errorFields.push(t("personnelDetail.requiredFeildCustomer"));
        requiredFieldValidations.customerDetailCustomer = true;
      }

      if (
        valuePersonnelAuth === authSection?.personnelAuth &&
        !selectedCustomers?.length
      ) {
        errorFields.push(t("personnelDetail.requiredFeildCustomer"));
      }

      // business Phone
      if (personalDetailForm?.values?.businessPhone?.trim() === "") {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode20354
        );
        let businessEmailRequiredError = errMsg?.errorMsg.replace(
          /\|/g,
          t("personnelDetail.businessPhoneLabel")
        );
        errorFields.push(businessEmailRequiredError);
        requiredFieldValidations.businessPhone = true;
      } else if (!lengthEqual) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode63688
        );
        let businessPhoneRequiredError = errMsg?.errorMsg;

        businessPhoneRequiredError = businessPhoneRequiredError?.replace(
          /\|/,
          t("personnelDetail.businessPhoneLabel")
        );
        businessPhoneRequiredError = businessPhoneRequiredError?.replace(
          /\|/,
          attributeType?.format_example
        );
        errorFields.push(businessPhoneRequiredError);
        requiredFieldValidations.businessPhone = true;
      }

      // extention
      if (personalDetailForm?.values?.extension?.trim() !== "") {
        if (
          !extentionRegex.test(personalDetailForm?.values?.extension?.trim())
        ) {
          const errMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode64081
          );
          let extRequiredError = errMsg?.errorMsg.replace(
            /\|/,
            t("personnelDetail.extLabel")
          );
          errorFields.push(extRequiredError);
          requiredFieldValidations.extension = true;
        }
      }

      // business fax
      if (personalDetailForm?.values?.businessFax?.trim() !== "") {
        if (!phoneRegex.test(personalDetailForm?.values?.businessFax?.trim())) {
          const errMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode63688
          );
          let cellularRequiredError = errMsg?.errorMsg;
          cellularRequiredError = cellularRequiredError?.replace(
            /\|/,
            t("personnelDetail.businessFaxLabel")
          );
          const format = await getFormatsBasedOnCountry(
            personalDetailForm?.values?.customerDetailSelectedCountry
              ?.country_id,
            "1"
          );
          cellularRequiredError = cellularRequiredError?.replace(
            /\|/,
            format[1]?.format_example
          );
          cellularRequiredError =
            cellularRequiredError + ` ${format[0]?.format_example}`;
          errorFields.push(cellularRequiredError);
          requiredFieldValidations.businessFax = true;
        }
      }

      // Interaction Authorization and DR Authorization
      if (
        valuePersonnelAuth === authSection?.bypass &&
        (personalDetailForm?.values?.customerDetailCustomer !== "" ||
          personalDetailForm?.values?.customerDetailCustomer !== null)
      ) {
        if (
          !personalDetailForm?.values?.isIAOneChecked &&
          !personalDetailForm?.values?.isIATwoChecked &&
          !personalDetailForm?.values?.isIAThreeChecked &&
          !personalDetailForm?.values?.isIAFourChecked &&
          !personalDetailForm?.values?.isIAFiveChecked &&
          !personalDetailForm?.values?.isIASixChecked &&
          !personalDetailForm?.values?.isDRAOneChecked &&
          !personalDetailForm?.values?.isDRATwoChecked &&
          !personalDetailForm?.values?.isDRAThreeChecked &&
          !personalDetailForm?.values?.isDRAFourChecked
        ) {
          errorFields.push(t("personnelDetail.authLevelMsg"));
        }
      }

      // Interaction Authorization and DR Authorization
      if (
        valuePersonnelAuth === authSection?.personnelAuth &&
        selectedCustomers?.length !== 0
      ) {
        if (
          !personalDetailForm?.values?.isIAOneChecked &&
          !personalDetailForm?.values?.isIATwoChecked &&
          !personalDetailForm?.values?.isIAThreeChecked &&
          !personalDetailForm?.values?.isIAFourChecked &&
          !personalDetailForm?.values?.isIAFiveChecked &&
          !personalDetailForm?.values?.isIASixChecked &&
          !personalDetailForm?.values?.isDRAOneChecked &&
          !personalDetailForm?.values?.isDRATwoChecked &&
          !personalDetailForm?.values?.isDRAThreeChecked &&
          !personalDetailForm?.values?.isDRAFourChecked
        ) {
          errorFields.push(t("personnelDetail.authLevelMsg"));
        }
      }

      // Interaction Authorization B and R selected in same time
      if (
        personalDetailForm?.values?.isIATwoChecked &&
        personalDetailForm?.values?.isIASixChecked
      ) {
        errorFields.push(t("personnelDetail.brError"));
      }
    }

    setRequiredFieldValidations((prev) => requiredFieldValidations);
    return errorFields;
  };

  const stripNonNumbers = (input) => {
    return input.replace(/[^0-9]/g, "");
  };

  const isLengthEqualTo = async (input, length) => {
    const stripped = await stripNonNumbers(input);
    return length?.includes(stripped?.length?.toString());
  };

  const checkProgramIdPermission = async (customerPermissions, programId) => {
    // Map to extract role_ids for the current customer
    let roleIds = customerPermissions.map((role) => role.role_id);

    //Add role_ids to uniqueRoleIds array, avoiding duplicates
    let uniqueIds = [...new Set(roleIds)];
    const data = await getProgramSecurityList(
      uniqueIds,
      personalDetailForm?.values?.branch,
      programId
    );
    return data;
  };

  const checkIfInteractionDrPermission = async (customerPermissions) => {
    setIsLoading(true);
    const idRelatedValuesFor222 = await checkProgramIdPermission(
      customerPermissions,
      security_program_id_222
    );

    const idRelatedValuesFor118 = await checkProgramIdPermission(
      customerPermissions,
      security_program_id_118
    );

    idRelatedValuesFor118?.length > 0
      ? setIsInteractionEditable(true)
      : setIsInteractionEditable(false);

    idRelatedValuesFor222?.length > 0
      ? setIsDREditable(true)
      : setIsDREditable(false);

    setIsLoading(false);
  };

  const checkIfPersonnelHasPermission = async (customerPermissions) => {
    let personnalPermission = true;
    if (valuePersonnelAuth === authSection?.personnelAuth) {
      const idRelatedValuesFor108 = await checkProgramIdPermission(
        customerPermissions,
        security_program_id_108
      );

      const idRelatedValuesFor222 = await checkProgramIdPermission(
        customerPermissions,
        security_program_id_222
      );

      let pesonnelEditableCount =
        idRelatedValuesFor108?.length + idRelatedValuesFor222?.length;

      if (pesonnelEditableCount > 0) {
        personnalPermission = true;
      } else {
        personnalPermission = false;
      }
      setIsPersonnelEditable(personnalPermission);
    }

    if (personnalID && !personnalPermission && isAuthClick) {
      setIsAuthClick(false);
      onNewButtonClick();
      return;
    }
    return personnalPermission;
  };

  const getInitialPersonnelDetails = async (id, type) => {
    setIsLoading(true);
    setPersonnelCustomerList([]);
    try {
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: id,
        customer_facing: N_CHECKER
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.personnelinformationandpassedpersonnelid}`,
        4
      );

      let customerAssociation = data?.data[1];
      let customerPermissions = data?.data[2];
      let isPersonnelEditable = true;

      if (id !== personnalID) {
        isPersonnelEditable =
          await checkIfPersonnelHasPermission(customerPermissions);
      }

      let cuxList = customerAssociation?.map((c) => {
        const permission = customerPermissions?.filter(
          (p) => p?.customer_id === c.customer_id
        );

        return {
          ...c,
          customer: c?.customer_number,
          name: c?.customer_name,
          branch: c?.district_name,
          interactionAuth: permission.find(
            (p) => p.roletype === roletypes?.roleTypeOne
          )?.code,
          drAuth: permission.find((p) => p.roletype === roletypes?.roleTypeTwo)
            ?.code,
          businessPhone: c?.business_phone,
          extension: c?.business_phone_ext,
          drTimeStamp: permission.find(
            (p) => p.roletype === roletypes?.roleTypeTwo
          )?.timestamp,
          interactionAuthTimeStamp: permission.find(
            (p) => p.roletype === roletypes?.roleTypeOne
          )?.timestamp
        };
      });

      let persCustList = [];
      for (let i = 0; i < cuxList.length; i++) {
        let permissionArray = customerPermissions.filter((item) => {
          return item.customer_id === cuxList[i].customer_id;
        });
        const idRelatedValuesFor222 = await checkProgramIdPermission(
          permissionArray,
          security_program_id_222
        );

        const idRelatedValuesFor118 = await checkProgramIdPermission(
          permissionArray,
          security_program_id_118
        );

        (idRelatedValuesFor118.length > 0 ||
          idRelatedValuesFor222.length > 0) &&
          persCustList.push(cuxList[i]);
      }

      if (isPersonnelEditable) {
        if (!type) {
          setCustomerPermissionList(customerPermissions);
          setCustomersList(persCustList);
          setOriginalCustomerList(persCustList);
        }
      } else {
        setCustomersList([]);
        setOriginalCustomerList([]);
      }

      if (personnalID && id === personalDetailForm.values.personnel.value) {
        setPersCustomerPermissionList(customerPermissions);
        personnalID === personalDetailForm.values.personnel.value
          ? setPersonnelCustomerList(cuxList)
          : setPersonnelCustomerList(persCustList);
      }
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getNavigatedPersonnelDetails = async (personnalID) => {
    setIsLoading(true);
    setCustomersList([]);
    setOriginalCustomerList([]);
    try {
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: personnalID,
        customer_facing: N_CHECKER
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.personnelinformationandpassedpersonnelid}`,
        4
      );

      let authDetails = data?.data[0];
      let customerAssociation = data?.data[1];
      let customerRollPermissions = data?.data[2];
      let secureSyncUser = data?.data[3];

      if (!authDetails?.length) {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
        setIsLoading(false);
        return;
      }

      personalDetailForm.setFieldValue("userId", authDetails[0]?.login);
      personalDetailForm.setFieldValue("firstName", authDetails[0]?.first_name);
      personalDetailForm.setFieldValue(
        "middleName",
        authDetails[0]?.middle_name
      );
      personalDetailForm.setFieldValue("lastName", authDetails[0]?.last_name);
      personalDetailForm.setFieldValue(
        "homeEmail",
        authDetails[0]?.home_email_addr
      );
      personalDetailForm.setFieldValue(
        "businessEmail",
        authDetails[0]?.business_email_addr
      );
      personalDetailForm.setFieldValue("homePhone", authDetails[0]?.home_phone);
      personalDetailForm.setFieldValue(
        "cellular",
        authDetails[0]?.cellular_phone
      );
      personalDetailForm.setFieldValue("countryId", authDetails[0]?.country_id);
      personalDetailForm.setFieldValue("pager", authDetails[0]?.pager_phone);
      personalDetailForm.setFieldValue(
        "pagerPin",
        authDetails[0]?.pager_pin_number
      );
      personalDetailForm.setFieldValue(
        "isSecureSyncEnabled",
        secureSyncUser[0]?.is_securesync_login === Y_CHECKER ? true : false
      );
      personalDetailForm.setFieldValue("timeStamp", authDetails[0]?.timestamp);

      let cuxList = customerAssociation?.map((c) => {
        const permission = customerRollPermissions?.filter(
          (p) => p?.customer_id === c.customer_id
        );

        return {
          ...c,
          customer: c?.customer_number,
          name: c?.customer_name,
          branch: c?.district_name,
          interactionAuth: permission.find((p) => p.roletype === "1")?.code,
          drAuth: permission.find((p) => p.roletype === "2")?.code,
          businessPhone: c?.business_phone,
          extension: c?.business_phone_ext,
          drTimeStamp: permission.find((p) => p.roletype === "2")?.timestamp,
          interactionAuthTimeStamp: permission.find((p) => p.roletype === "1")
            ?.timestamp
        };
      });

      cuxList.sort((a, b) => {
        return a.customer.trim().localeCompare(b.customer.trim());
      });

      setCustomersList(cuxList);
      setOriginalCustomerList(cuxList);
      setCustomerPermissionList(customerRollPermissions);
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const onAddCustomerClick = async () => {
    if (isAuthenticated) {
      setIsAddingANewCustomer(true);
      setIsEditClick(false);
    } else {
      setShowAuthenticationSection(true);
      if (personnalID) {
        setIsEditClick(false);
      }
    }
    setIsLoading(false);
  };

  const onResetAuthClick = () => {
    if (isAuthenticated) {
      setShowResetAuthPopup(true);
    } else {
      setShowAuthenticationSection(true);
      if (personnalID) {
        setIsEditClick(false);
      }
    }
  };

  // branch change
  const onBranchChange = (branch) => {
    personalDetailForm.setFieldValue(
      "customerDetailBranch",
      branch?.target?.value
    );
    getCustomersBasedOnTheSelectedBranch(branch?.target?.value?.id);
  };

  // Handle Customer
  const onCustomerChange = (event, customer, name) => {
    personalDetailForm.setFieldValue("customerDetailCustomer", customer);
    if (name === "customerDetailCustomer") {
      setRequiredFieldValidations((prev) => ({
        ...prev,
        customerDetailCustomer: false
      }));
    }
  };

  const onCustomerNameSelect = (value, row) => {
    // remove customer values before we set new
    //customer details
    personalDetailForm.setFieldValue("customerDetailBranch", "");
    personalDetailForm.setFieldValue("customerDetailCustomer", "");
    personalDetailForm.setFieldValue("businessPhone", "");
    personalDetailForm.setFieldValue("extension", "");
    personalDetailForm.setFieldValue("businessFax", "");
    personalDetailForm.setFieldValue("customerDetailSelectedCountry", "");
    personalDetailForm.setFieldValue("isContractOwnerChecked", false);
    personalDetailForm.setFieldValue("isDiscrepancyContactChecked", false);
    personalDetailForm.setFieldValue("customerEmployeeNumber", "");

    //IA
    personalDetailForm.setFieldValue("isIAOneChecked", false);
    personalDetailForm.setFieldValue("isIATwoChecked", false);
    personalDetailForm.setFieldValue("isIAThreeChecked", false);
    personalDetailForm.setFieldValue("isIAFourChecked", false);
    personalDetailForm.setFieldValue("isIAFiveChecked", false);
    personalDetailForm.setFieldValue("isIASixChecked", false);

    //DR
    personalDetailForm.setFieldValue("isDRAOneChecked", false);
    personalDetailForm.setFieldValue("isDRATwoChecked", false);
    personalDetailForm.setFieldValue("isDRAThreeChecked", false);
    personalDetailForm.setFieldValue("isDRAFourChecked", false);

    setIsCustomerSelectedFromTheTable(true);

    let roleTypeOne = customerPermissionList?.filter(
      (permission) =>
        permission?.customer_id === row?.customer_id &&
        permission?.roletype === roletypes?.roleTypeOne
    );

    const interactionAuthorization = roleTypeOne[0]?.code?.trim();

    personalDetailForm.setFieldValue(
      "customerEmployeeNumber",
      row?.employee_number
    );

    personalDetailForm.setFieldValue(
      "interactionAuthorizationTimeStamp",
      roleTypeOne[0]?.timestamp
    );

    if (interactionAuthorization?.includes("A")) {
      personalDetailForm.setFieldValue(
        "isIAOneChecked",
        interactionAuthorization.includes("A")
      );
    }

    if (interactionAuthorization?.includes("B")) {
      personalDetailForm.setFieldValue(
        "isIATwoChecked",
        interactionAuthorization.includes("B")
      );
    }

    if (interactionAuthorization?.includes("C")) {
      personalDetailForm.setFieldValue(
        "isIAThreeChecked",
        interactionAuthorization.includes("C")
      );
    }

    if (interactionAuthorization?.includes("D")) {
      personalDetailForm.setFieldValue(
        "isIAFourChecked",
        interactionAuthorization.includes("D")
      );
    }

    if (interactionAuthorization?.includes("E")) {
      personalDetailForm.setFieldValue(
        "isIAFiveChecked",
        interactionAuthorization.includes("E")
      );
    }

    if (interactionAuthorization?.includes("R")) {
      personalDetailForm.setFieldValue(
        "isIASixChecked",
        interactionAuthorization.includes("R")
      );
    }

    let roleTypeTwo = customerPermissionList?.filter(
      (permission) =>
        permission?.customer_id === row?.customer_id &&
        permission?.roletype === roletypes?.roleTypeTwo
    );

    const drAuthorization = roleTypeTwo[0]?.code?.trim();

    personalDetailForm.setFieldValue(
      "drAuthorizationTimeStamp",
      roleTypeTwo[0]?.timestamp
    );

    if (drAuthorization?.includes("1")) {
      personalDetailForm.setFieldValue(
        "isDRAOneChecked",
        drAuthorization?.includes("1")
      );
    }

    if (drAuthorization?.includes("2")) {
      personalDetailForm.setFieldValue(
        "isDRATwoChecked",
        drAuthorization?.includes("2")
      );
    }

    if (drAuthorization?.includes("3")) {
      personalDetailForm.setFieldValue(
        "isDRAThreeChecked",
        drAuthorization?.includes("3")
      );
    }

    if (drAuthorization?.includes("4")) {
      personalDetailForm.setFieldValue(
        "isDRAFourChecked",
        drAuthorization?.includes("4")
      );
    }

    const brnach = allBranches?.find(
      (branch) => branch?.id === row?.district_id
    );
    const country = allCountries?.find(
      (country) => country?.id === row?.country_id
    );
    const customer = customersList
      ?.filter((cust) => cust?.customer_id === row?.customer_id)
      ?.map((customer) => {
        return {
          label: customer?.customer_name,
          value: customer?.customer_name,
          ...customer
        };
      });
    personalDetailForm.setFieldValue("customerDetailBranch", brnach);
    personalDetailForm.setFieldValue("customerDetailCustomer", customer[0]);
    personalDetailForm.setFieldValue("businessPhone", row?.business_phone);
    personalDetailForm.setFieldValue("extension", row?.business_phone_ext);
    personalDetailForm.setFieldValue("businessFax", row?.business_fax);
    personalDetailForm.setFieldValue("customerDetailSelectedCountry", country);
    personalDetailForm.setFieldValue(
      "isContractOwnerChecked",
      row?.contract_owner_flag === Y_CHECKER ? true : false
    );
    personalDetailForm.setFieldValue(
      "isDiscrepancyContactChecked",
      row?.discrepancy_contact_flag === Y_CHECKER ? true : false
    );
  };

  const onEditClick = () => {
    if (isAuthenticated) {
      setIsEditClick(true);
    } else {
      setShowAuthenticationSection(true);
      if (personnalID) {
        setIsEditClick(false);
      }
    }
  };

  // reset auth yes click
  const onResetAuthYesClick = async () => {
    try {
      setShowResetAuthPopup(false);
      setIsLoading(true);
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: personnalID,
        requesting_personnel_id: personalDetailForm?.values?.personnel?.value,
        last_mod_user: auth?.user?.login,
        new_auth_number: "",
        auth_bypass_reason: personalDetailForm?.values?.bypassReason,
        employee_id: auth?.user?.id
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.resetAuthNumber}`,
        2
      );

      let resetAuthHasError = data?.data[0]; // get error code

      if (data?.data[1][0]?.error === errorMsgs?.noError) {
        /**
        @description
        *  EMAIL SEND FOR RESET AUTH*/
        const isSecureSyncUser =
          personalDetailForm?.values?.isSecureSyncEnabled;

        // get the subject
        let emailSubject = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode80246
        );

        // Recipients
        let recipients = [];
        // Logged In Employee
        const loggedInEmail = auth?.user?.business_email_addr;
        const loggedInName =
          auth?.user?.lower_first_name + auth?.user?.lower_last_name;
        const loggedInEmployee = {
          emailAddress: loggedInEmail,
          name: loggedInName
        };

        //Personnel
        const personnelEmail = personalDetailForm?.values?.businessEmail;
        const personalName =
          personalDetailForm?.values?.firstName +
          "," +
          personalDetailForm?.values?.lastName;
        const loggedInPersonnel = {
          emailAddress: personnelEmail,
          name: personalName
        };
        recipients.push(loggedInPersonnel);
        if (valuePersonnelAuth === authSection?.bypass) {
          recipients.push(loggedInEmployee);
        }

        const actionCode = isSecureSyncUser
          ? errorMsgs.errorCode80242
          : errorMsgs.errorCode80489;
        const actionText = await getMessageFromCode(actionCode);
        const footerCode = isSecureSyncUser
          ? errorMsgs.errorCode80243
          : errorMsgs.errorCode80408;
        const footerText = await getMessageFromCode(footerCode);
        const footerText2 = isSecureSyncUser
          ? await getMessageFromCode(errorMsgs.errorCode80408)
          : null;

        const customerNumbers = VALUE_EMPTY_STRING;
        const newAuthorizations = null;
        const isNewOrReset = false;
        const nonSecureSyncLogin = !isSecureSyncUser;
        const mailBody = await generateConfirmationMailBody({
          actionText,
          footerText,
          customerNumbers,
          newAuthorizations,
          isNewOrReset,
          nonSecureSyncLogin,
          personnelEmail,
          loggedInEmployee,
          footerText2,
          personalName,
          mailType: "confirmation"
        });
        // email sending
        const isEmailSend = await emailSendingTask(
          recipients,
          emailSubject.errorMsg,
          mailBody
        );
        if (!isEmailSend) {
          const errMsg = await getTheFirestoreErrorMessage(
            errorMsgs?.errorCode64077
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
        }

        const successMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode64079
        );
        setIsLoading(false);
        setSuccessInsertMessage(successMsg?.errorMsg);
        setMessageSuccess(true);
        setSelectedCustomers([]);
        setIsCustomerSelectedFromTheTable(false);
      } else if (resetAuthHasError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          resetAuthHasError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading(false);
        setSelectedCustomers([]);
        setIsCustomerSelectedFromTheTable(false);
      }

      if (valuePersonnelAuth === authSection?.bypass) {
        setIsAuthenticated(false);
        setShowAuthenticationSection(false);
        if (personnalID) {
          setIsEditClick(false);
        }
      }
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const onAuthenticatedCallerCliked = () => {
    if (isAuthenticated) {
      dispatch(setAuthenticationState(null));
      personalDetailForm.setFieldValue("challengeQuestion", "");
      personalDetailForm.setFieldValue("challengeResponse", "");
      personalDetailForm.setFieldValue("customer", "");
      personalDetailForm.setFieldValue("selectedCustomer", "");
      personalDetailForm.setFieldValue("personnel", "");
      personalDetailForm.setFieldValue("authNumber", "");
      personalDetailForm.setFieldValue("bypassReason", "");
      if (personnalID) {
        setIsEditClick(false);
      }
      setIsAuthenticated((prev) => !prev);
      setRequiredFieldValidations({
        firstName: false,
        lastName: false,
        businessEmail: false,
        businessPhone: false,
        customerDetailCustomer: false,
        middleName: false,
        homeEmail: false,
        confirmHomeEmail: false,
        confirmBusinessEmail: false,
        homePhone: false,
        cellular: false,
        selectedCountry: false,
        pager: false,
        pagerPin: false,
        extension: false,
        businessFax: false
      });
    } else if (showAuthenticationSection) {
      setShowAuthenticationSection(false);
      setRequiredFieldValidations({
        firstName: false,
        lastName: false,
        businessEmail: false,
        businessPhone: false,
        customerDetailCustomer: false,
        middleName: false,
        homeEmail: false,
        confirmHomeEmail: false,
        confirmBusinessEmail: false,
        homePhone: false,
        cellular: false,
        selectedCountry: false,
        pager: false,
        pagerPin: false,
        extension: false,
        businessFax: false
      });
    } else {
      setShowAuthenticationSection(true);
      if (personnalID) {
        setIsEditClick(false);
      }
      setRequiredFieldValidations({
        firstName: false,
        lastName: false,
        businessEmail: false,
        businessPhone: false,
        customerDetailCustomer: false,
        middleName: false,
        homeEmail: false,
        confirmHomeEmail: false,
        confirmBusinessEmail: false,
        homePhone: false,
        cellular: false,
        selectedCountry: false,
        pager: false,
        pagerPin: false,
        extension: false,
        businessFax: false
      });
    }
    setIsPersonnelEditable(true);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
  };

  const handleCustomersSelectionAll = (selectedRow) => {
    let roleTypeOne = customerPermissionList?.filter(
      (permission) =>
        permission?.customer_id === selectedRow?.customer_id &&
        permission?.roletype === "1"
    );

    const interactionAuthorization = roleTypeOne[0]?.code?.trim();

    personalDetailForm.setFieldValue(
      "interactionAuthorizationTimeStamp",
      roleTypeOne[0]?.timestamp
    );

    if (interactionAuthorization?.includes("A")) {
      personalDetailForm.setFieldValue(
        "isIAOneChecked",
        interactionAuthorization?.includes("A")
      );
    }

    if (interactionAuthorization?.includes("B")) {
      personalDetailForm.setFieldValue(
        "isIATwoChecked",
        interactionAuthorization?.includes("B")
      );
    }

    if (interactionAuthorization?.includes("C")) {
      personalDetailForm.setFieldValue(
        "isIAThreeChecked",
        interactionAuthorization?.includes("C")
      );
    }

    if (interactionAuthorization?.includes("D")) {
      personalDetailForm.setFieldValue(
        "isIAFourChecked",
        interactionAuthorization?.includes("D")
      );
    }

    if (interactionAuthorization?.includes("E")) {
      personalDetailForm.setFieldValue(
        "isIAFiveChecked",
        interactionAuthorization?.includes("E")
      );
    }

    if (interactionAuthorization?.includes("R")) {
      personalDetailForm.setFieldValue(
        "isIASixChecked",
        interactionAuthorization?.includes("R")
      );
    }

    let roleTypeTwo = customerPermissionList?.filter(
      (permission) =>
        permission?.customer_id === selectedRow?.customer_id &&
        permission?.roletype === "2"
    );

    const drAuthorization = roleTypeTwo[0]?.code?.trim();

    personalDetailForm.setFieldValue(
      "drAuthorizationTimeStamp",
      roleTypeTwo[0]?.timestamp
    );

    if (drAuthorization?.includes("1")) {
      personalDetailForm.setFieldValue(
        "isDRAOneChecked",
        drAuthorization?.includes("1")
      );
    }

    if (drAuthorization?.includes("2")) {
      personalDetailForm.setFieldValue(
        "isDRATwoChecked",
        drAuthorization?.includes("2")
      );
    }

    if (drAuthorization?.includes("3")) {
      personalDetailForm.setFieldValue(
        "isDRAThreeChecked",
        drAuthorization?.includes("3")
      );
    }

    if (drAuthorization?.includes("4")) {
      personalDetailForm.setFieldValue(
        "isDRAFourChecked",
        drAuthorization?.includes("4")
      );
    }

    const brnach = allBranches?.find(
      (branch) => branch?.id === selectedRow?.district_id
    );
    const country = allCountries?.find(
      (country) => country?.id === selectedRow?.country_id
    );
    const customer = customersList
      ?.filter((cust) => cust?.customer_id === selectedRow?.customer_id)
      ?.map((customer) => {
        return {
          label: customer?.customer_name,
          value: customer?.customer_name,
          ...customer
        };
      });
    personalDetailForm.setFieldValue("customerDetailBranch", brnach);
    personalDetailForm.setFieldValue("customerDetailCustomer", customer[0]);
    personalDetailForm.setFieldValue(
      "businessPhone",
      selectedRow?.business_phone
    );
    personalDetailForm.setFieldValue(
      "extension",
      selectedRow?.business_phone_ext
    );
    personalDetailForm.setFieldValue("businessFax", selectedRow?.business_fax);
    personalDetailForm.setFieldValue("customerDetailSelectedCountry", country);
    personalDetailForm.setFieldValue(
      "isContractOwnerChecked",
      selectedRow?.contract_owner_flag === Y_CHECKER ? true : false
    );
    personalDetailForm.setFieldValue(
      "isDiscrepancyContactChecked",
      selectedRow?.discrepancy_contact_flag === Y_CHECKER ? true : false
    );
  };

  const onCustomerSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedCustomers([...customersList]);
      setIsCustomerSelectedFromTheTable(true);
      customersList.forEach((x) => {
        handleCustomersSelectionAll(x);
      });
    } else {
      setSelectedCustomers([]);
      setIsCustomerSelectedFromTheTable(false);
    }
  };

  const onCustomerSelect = (e, tableMeta) => {
    if (e.target.checked) {
      const selectedRow = tableMeta?.tableData[tableMeta?.rowIndex];
      // Push the selected customer to selectedCustomers array
      setSelectedCustomers((prevSelectedCustomers) => [
        ...prevSelectedCustomers,
        selectedRow
      ]);
      setIsCustomerSelectedFromTheTable(true);
    } else {
      const deselectedRow = tableMeta?.tableData[tableMeta?.rowIndex];
      // Remove the deselected customer from selectedCustomers array
      setSelectedCustomers((prevSelectedCustomers) =>
        prevSelectedCustomers.filter((customer) => customer !== deselectedRow)
      );
      if (!selectedCustomers?.length) {
        setIsCustomerSelectedFromTheTable(false);
      }
    }
  };

  const onRemoveCustomerClick = () => {
    setShowRemoveCustomerModal(true);
  };

  const onRemoveCustomerYesClick = async () => {
    let customerAllowedToRemove = customersList?.filter(
      (cus) => cus?.email_receipt_recipient_flag === Y_CHECKER
    );
    if (
      customerAllowedToRemove?.length === 0 &&
      !personalDetailForm?.values?.businessEmail
    ) {
      customerRemoval(customersList);
    } else {
      setShowRemoveCustomerModal(false);
      setPersonnelDetailRemoveCustomerAssociationModalShow(true);
    }
  };

  const onPersonnelDetailRemoveCustomerAssociation = async () => {
    customerRemoval(selectedCustomers);
  };

  const customerRemoval = async (customerList) => {
    // customersList is a global state,So changed the parameter name
    setIsLoading(true);
    setPersonnelDetailRemoveCustomerAssociationModalShow(false);
    try {
      const customerIdList =
        await createCustomerIdListByPassingCustomers(customerList);

      let reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: personnalID,
        customer_id_list: customerIdList,
        system_id: system_id,
        employee_id: auth?.user?.id,
        requesting_personnel_id: personalDetailForm.values?.personnel?.value,
        auth_bypass_reason: personalDetailForm?.values?.bypassReason
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.customeridandpersonnelauthorization}`,
        2
      );

      let customerRemoveResponse = data?.data[0];
      const custAssociations = data?.data[1];

      let successOnes = [];
      let drCustomerList = [];
      let interactionCustomerList = [];
      let failedCustomersList = [];

      for (let index = 0; index < customerRemoveResponse.length; index++) {
        const element = customerRemoveResponse[index];

        if (element?.error_code === errorMsgs?.noError) {
          successOnes?.push(element?.customer_id);
        } else if (element?.error_code === errorMsgs?.errorCode63625) {
          drCustomerList?.push(element?.customer_id);
        } else if (element?.error_code === errorMsgs?.errorCode63626) {
          interactionCustomerList?.push(element?.customer_id);
        } else {
          failedCustomersList?.push(element?.customer_id);
        }
      }
      if (
        successOnes?.length +
          drCustomerList?.length +
          interactionCustomerList?.length ===
        0
      ) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode80247
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading(false);
        setShowRemoveCustomerModal(false);
        setPersonnelDetailRemoveCustomerAssociationModalShow(false);
        return;
      } else if (successOnes?.length === customerRemoveResponse?.length) {
        setIsLoading(false);
        setShowRemoveCustomerModal(false);

        // Send Email notifying Customer association removal

        let recipients = [];
        //Personnel
        const personnelEmail = personalDetailForm?.values?.businessEmail;
        const personalName =
          personalDetailForm?.values?.firstName +
          "," +
          personalDetailForm?.values?.lastName;
        // Logged In Employee
        const loggedInEmail = auth?.user?.business_email_addr;
        const loggedInName =
          auth?.user?.lower_first_name + " " + auth?.user?.lower_last_name;

        if (isAuthenticated) {
          const employee = {
            emailAddress: personnelEmail,
            name: personalName
          };
          recipients.push(employee);
          if (
            successOnes.length > 0 &&
            successOnes.length < customersList.length
          ) {
            sendCustomerRemovalEmail({ recipients, successOnes });
          }
        } else {
          const loggedInPersonnel = {
            emailAddress: loggedInEmail,
            name: loggedInName
          };
          recipients.push(loggedInPersonnel);
          if (successOnes.length > 0) {
            sendCustomerRemovalEmail({ recipients, successOnes });
          }
        }
      } else if (drCustomerList?.length > 0) {
        const removedCustomers = drCustomerList;
        // email -
        setPersonnelDetailRemoveCustomerAssociationModalShow(false);
        sendAuthorizationEmail(removedCustomers);
      } else if (interactionCustomerList?.length > 0) {
        const removedCustomers = interactionCustomerList;
        // email -
        setPersonnelDetailRemoveCustomerAssociationModalShow(false);
        sendAuthorizationEmail(removedCustomers);
      }

      // remove ss
      if (
        personalDetailForm?.values?.isSecureSyncEnabled &&
        custAssociations[0]?.is_customer_associations === numberConstants?.zero
      ) {
        const region = process.env.REACT_APP_CF_REGION;
        const environment = process.env.REACT_APP_CF_ENV;

        let pathString = "";

        if (
          process.env.REACT_APP_ENV === "prod" ||
          process.env.REACT_APP_ENV === "nonprod"
        ) {
          pathString = `sb-${region}`;
        } else {
          pathString = `sb-${environment}-${region}`;
        }

        const envs = await getENVDetails();

        const selectedUserEnv = envs[pathString];

        const checkSSOUser = {
          userId:
            personalDetailForm?.values?.userId ||
            personalDetailForm?.values?.businessEmail
        };

        const ssoUserResponse = await callToAPI(
          checkSSOUser,
          CF_URLS.personnelDetail?.checkSSOUserExist
        );

        if (ssoUserResponse?.data?.isSuccess) {
          const userId = ssoUserResponse?.data?.result?.id;
          const userLogin = ssoUserResponse?.data?.result?.login;

          let removeObject = {
            userId: userId,
            firstName: personalDetailForm?.values?.firstName,
            lastName: personalDetailForm?.values?.lastName,
            email: personalDetailForm?.values?.businessEmail,
            login: userLogin,
            appAccess: {
              appsRemove: [
                {
                  tenantId: parseInt(selectedUserEnv?.TenantId),
                  tenantAppId: parseInt(selectedUserEnv?.TenantAppId),
                  appCode: selectedUserEnv?.AppCode
                }
              ]
            }
          };

          const response = await callToAPI(
            removeObject,
            CF_URLS.personnelDetail?.updateSSOUser
          );

          if (response?.data?.result?.id) {
            let ssoLoginDSP = {
              main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
              ciam_uid: ssoUserResponse?.data?.result?.id,
              is_ciam_error: N_CHECKER,
              rc: ""
            };

            const data = await getResponseData(
              ssoLoginDSP,
              `${CF_URLS.personnelDetail?.userremovesso}`,
              1
            );

            const ssoRemoveRequestResponse = data?.data[0];

            if (ssoRemoveRequestResponse[0]?.error === errorMsgs?.noError) {
              const successMsg = await getTheFirestoreErrorMessage(
                errorMsgs?.errorCode80483
              );
              setSuccessInsertMessage(successMsg?.errorMsg);
              setMessageSuccess(true);
              setIsLoading(false);
            } else {
              const errMsg = await getTheFirestoreErrorMessage(
                errorMsgs?.errorCode80482
              );
              setShowErrorAlert(true);
              setShowErrorAlertMsg(errMsg?.errorMsg);
              setIsLoading(false);
            }
          } else {
            let ssoLoginRequest = {
              main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
              personnel_id: personnalID,
              error_msg: response?.response?.data?.error?.errorMessage,
              rc: ""
            };

            await callToAPI(
              ssoLoginRequest,
              `${CF_URLS.personnelDetail?.userupdatesso}`,
              1
            );

            const errMsg = await getTheFirestoreErrorMessage(
              errorMsgs?.errorCode80482
            );
            setShowErrorAlert(true);
            setShowErrorAlertMsg(errMsg?.errorMsg);
            setIsLoading(false);
          }
        }
      }

      if (valuePersonnelAuth === authSection?.bypass) {
        if (personnalID) {
          setIsEditClick(false);
        }
        setIsAuthenticated((prev) => false);
        setShowAuthenticationSection(false);
      }

      personalDetailForm.setFieldValue("isDRAOneChecked", false);
      personalDetailForm.setFieldValue("isDRATwoChecked", false);
      personalDetailForm.setFieldValue("isDRAThreeChecked", false);
      personalDetailForm.setFieldValue("isDRAFourChecked", false);
      personalDetailForm.setFieldValue("isIAOneChecked", false);
      personalDetailForm.setFieldValue("isIATwoChecked", false);
      personalDetailForm.setFieldValue("isIAThreeChecked", false);
      personalDetailForm.setFieldValue("isIAFourChecked", false);
      personalDetailForm.setFieldValue("isIAFiveChecked", false);
      personalDetailForm.setFieldValue("isIASixChecked", false);
      personalDetailForm.setFieldValue("isContractOwnerChecked", false);
      personalDetailForm.setFieldValue("isDiscrepancyContactChecked", false);
      personalDetailForm.setFieldValue("customerDetailCustomer", "");
      personalDetailForm.setFieldValue("customerEmployeeNumber", "");
      personalDetailForm.setFieldValue("businessPhone", "");
      personalDetailForm.setFieldValue("extension", "");
      personalDetailForm.setFieldValue("businessFax", "");

      setRemoveCustomer(true);
      setIsCustomerSelectedFromTheTable(false);
      setSelectedCustomers([]);
      setCustomersList([]);
      setOriginalCustomerList([]);
      getNavigatedPersonnelDetails(personnalID);
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const onResetButtonClick = async () => {
    await getNavigatedPersonnelDetails(personnalID);
    onCustomerNameSelect("", customersList[0]);
    setIsEditClick(false);
    setSelectedCustomers([]);
    setIsCustomerSelectedFromTheTable(false);
    setIsAddingANewCustomer(false);
  };

  // new button click
  const onNewButtonClick = async () => {
    setIsLoading(true);
    setCustomersList([]);
    setOriginalCustomerList([]);
    setRequiredFieldValidations({
      firstName: false,
      lastName: false,
      businessEmail: false,
      businessPhone: false,
      customerDetailCustomer: false,
      middleName: false,
      homeEmail: false,
      confirmHomeEmail: false,
      confirmBusinessEmail: false,
      homePhone: false,
      cellular: false,
      selectedCountry: false,
      pager: false,
      pagerPin: false,
      extension: false,
      businessFax: false
    });
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");

    //personnel details
    personalDetailForm.setFieldValue("userId", "");
    personalDetailForm.setFieldValue("firstName", "");
    personalDetailForm.setFieldValue("middleName", "");
    personalDetailForm.setFieldValue("lastName", "");
    personalDetailForm.setFieldValue("homeEmail", "");
    personalDetailForm.setFieldValue("confirmHomeEmail", "");
    personalDetailForm.setFieldValue("businessEmail", "");
    personalDetailForm.setFieldValue("confirmBusinessEmail", "");
    personalDetailForm.setFieldValue("homePhone", "");
    personalDetailForm.setFieldValue("cellular", "");
    personalDetailForm.setFieldValue("pager", "");
    personalDetailForm.setFieldValue("pagerPin", "");
    personalDetailForm.setFieldValue("isSecureSyncEnabled", false);

    //customer details
    personalDetailForm.setFieldValue("customerDetailCustomer", "");
    personalDetailForm.setFieldValue("businessPhone", "");
    personalDetailForm.setFieldValue("extension", "");
    personalDetailForm.setFieldValue("businessFax", "");
    personalDetailForm.setFieldValue("isContractOwnerChecked", false);
    personalDetailForm.setFieldValue("isDiscrepancyContactChecked", false);
    personalDetailForm.setFieldValue("customerEmployeeNumber", "");

    //IA
    personalDetailForm.setFieldValue("isIAOneChecked", false);
    personalDetailForm.setFieldValue("isIATwoChecked", false);
    personalDetailForm.setFieldValue("isIAThreeChecked", false);
    personalDetailForm.setFieldValue("isIAFourChecked", false);
    personalDetailForm.setFieldValue("isIAFiveChecked", false);
    personalDetailForm.setFieldValue("isIASixChecked", false);

    //DR
    personalDetailForm.setFieldValue("isDRAOneChecked", false);
    personalDetailForm.setFieldValue("isDRATwoChecked", false);
    personalDetailForm.setFieldValue("isDRAThreeChecked", false);
    personalDetailForm.setFieldValue("isDRAFourChecked", false);

    if (personnalID) {
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_AUTH_BASE_PATH}/${PERSONAL_DETAIL}`
      );
    } else {
      await getInitialPersonnelDetails(
        personalDetailForm.values.personnel.value
      );
    }

    setIsLoading(false);
  };

  // handle first name field values
  const handleFirstNameField = (e) => {
    const { name, value } = e.target;
    personalDetailForm.setFieldValue(name, value);
    if (name === "firstName") {
      // Set firstName validation to false on change
      setRequiredFieldValidations((prev) => ({
        ...prev,
        firstName: false
      }));
    }
  };

  // handle last name field values
  const handleLastNameField = (e) => {
    const { name, value } = e.target;
    personalDetailForm.setFieldValue(name, value);
    if (name === "lastName") {
      // Set firstName validation to false on change
      setRequiredFieldValidations((prev) => ({
        ...prev,
        lastName: false
      }));
    }
  };

  // handle business email field values
  const handlebusinessEmailField = (e) => {
    const { name, value } = e.target;
    personalDetailForm.setFieldValue(name, value);
    if (name === "businessEmail") {
      // Set firstName validation to false on change
      setRequiredFieldValidations((prev) => ({
        ...prev,
        businessEmail: false
      }));
    }
  };

  // handle business email field values
  const handlebusinessPhoneField = (e) => {
    const { name, value } = e.target;
    personalDetailForm.setFieldValue(name, value);
    if (name === "businessPhone") {
      // Set firstName validation to false on change
      setRequiredFieldValidations((prev) => ({
        ...prev,
        businessPhone: false
      }));
    }
  };

  // handle field values
  const handleFieldChanges = (e, fieldName) => {
    const { name, value } = e.target;
    personalDetailForm.setFieldValue(fieldName, value);
    if (name === fieldName) {
      setRequiredFieldValidations((prev) => ({
        ...prev,
        [fieldName]: false
      }));
    }
  };

  const generateConfirmationMailBody = async ({
    actionText,
    footerText,
    newAuthorizations,
    isNewOrReset,
    nonSecureSyncLogin,
    personnelEmail,
    mailType,
    customerNumbers,
    personalName,
    footerText2,
    actionText2,
    removedCustomers,
    authNumber,
    authPin
  }) => {
    // Define formatRow and formatTableSectionStart

    const formatRow = (data) =>
      `<tr><td>${data[0]}</td><td>${data[1]}</td></tr>`;
    const formatTableSectionStart = (title) =>
      `<p><strong>${title}</strong><br/><table border='1' cellspacing='1' cellpadding='1'>`;

    const msg = await getTheFirestoreErrorMessage(errorMsgs.errorCode64111);
    // Get customerServiceResponse from global_attribute collection
    const customerServiceResponse = await getGlobalAttributeValue(
      currentBranch?.value,
      systemIdCustomer,
      globalAttributeTypeIdCustomer
    );
    let secureSyncURLText = "";
    // Define HTML body with styles
    let body =
      '<html><head><style type="text/css">p, a, body, td {font-family: Arial, Helvetica, sans-serif;font-size: x-small;}</style></head><body>';

    body += msg?.errorMsg;

    if (mailType === confirmation) {
      // Define secureSyncURL
      const secureSyncURL = await getGlobalAttributeValue(
        currentBranch?.value,
        systemIdURL,
        globalAttributeTypeIdURL
      );

      // Remove leading HTTP or HTTPS, trailing /,
      secureSyncURLText = secureSyncURL?.replace(/^(https?:\/\/)|(\/.*)$/g, "");
    }
    if (actionText) {
      body += "<p>" + actionText[0]?.descr + "</p>";
    }
    if (actionText2) {
      body += "<p>" + actionText2[0]?.descr + "</p>";
    }
    if (mailType === confirmation) {
      // Start Identification Details section
      body += formatTableSectionStart(
        t("personnelDetail.identificationDetails")
      );
      // Append customerNumbers if available
      if (customerNumbers?.length > 0) {
        body += formatRow([
          t("personnelDetail.accountNumbers"),
          customerNumbers
        ]);
      }
      if (removedCustomers?.length > 0) {
        const filteredCustomers = customersList.filter((customer) =>
          removedCustomers.includes(customer.customer_id)
        );
        const customerNumberList = filteredCustomers.map(
          (customer) => customer.customer_number
        );
        const commaSeparatedList = customerNumberList.join(", ");
        body += formatRow([
          t("personnelDetail.accountNumbers"),
          commaSeparatedList
        ]);
      }
      body += formatRow([t("personnelDetail.name"), personalName]);

      if (!nonSecureSyncLogin) {
        // Append user name if not nonSecureSyncLogin
        body += formatRow([t("personnelDetail.userName"), personnelEmail]);
      }
      if (isNewOrReset) {
        // Append email if isNewOrReset
        body += formatRow([t("personnelDetail.email"), personnelEmail]);
      }

      // End Identification Details section
      body += "</table></p>";
    }
    if (newAuthorizations != null) {
      body += formatTableSectionStart(t("personnelDetail.authorizationLevels"));

      newAuthorizations.forEach((level) => {
        body += formatRow([level.uniqueId, level.formattedName + "&nbsp;"]);
      });
      body += "</table></p>";
    }
    if (mailType === authType) {
      body += formatTableSectionStart(t("personnelDetail.authDetails"));
      body += formatRow([t("personnelDetail.authNumber"), authNumber]);
      body += formatRow([t("personnelDetail.authPin"), authPin]);
      body += "</table></p>";
    }
    // Start Additional Details section
    body += formatTableSectionStart(t("personnelDetail.additionalDetails"));

    // Append Modified By
    const modifiedBy =
      valuePersonnelAuth === authSection?.bypass
        ? t("personnelDetail.ironMountain")
        : `${personnelAuth[0]?.last_name}, ${personnelAuth[0]?.first_name} ${personnelAuth[0]?.middle_name}`;
    body += formatRow([t("personnelDetail.modifiedBy"), modifiedBy]);

    // Append Iron Mountain Customer Response
    body += formatRow([
      t("personnelDetail.ironMountainCustomerResponse"),
      customerServiceResponse
    ]);
    if (!nonSecureSyncLogin && mailType === confirmation) {
      body += formatRow([
        t("personnelDetail.secureSyncWebsite"),
        secureSyncURLText
      ]);
    }
    body += "</table></p>";

    // Append footerText
    body += "<p>" + footerText[0]?.descr + "</p>";
    if (footerText2) {
      body += "<p>" + footerText2[0]?.descr + "</p>";
    }

    // End HTML body
    body += "</body></html>";
    return body;
  };

  // email sending task
  const emailSendingTask = async (recipients, emailSubject, mailBody) => {
    setIsLoading((prev) => true);
    try {
      const response = await sendEmail(recipients, mailBody, emailSubject);
      if (response?.data?.isSuccess) {
        // email sent
        setIsLoading((prev) => false);
        return true;
      } else {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // call to cloud function and get data from firestore
  const getResponseData = async (requestBody, url, count) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(
      response,
      count,
      response.data.docId
    );
    return data;
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  const isShowAuthenticationSection = () => {
    if (showAuthenticationSection) {
      return true;
    }

    if (personnalID) {
      return false;
    }

    return true;
  };

  const isShowDetailsSection = () => {
    if (isAuthenticated) {
      return true;
    }

    if (showAuthenticationSection) {
      return false;
    }

    if (personnalID) {
      return true;
    }

    return false;
  };

  const isTableShow = () => {
    if (
      personnalID &&
      isAddingANewCustomer &&
      valuePersonnelAuth !== authSection?.bypass
    ) {
      return true;
    }

    if (
      personnalID &&
      isAddingANewCustomer &&
      valuePersonnelAuth === authSection?.bypass
    ) {
      return false;
    }

    if (personnalID) {
      return true;
    }

    if (valuePersonnelAuth === authSection?.bypass) {
      return false;
    }

    return true;
  };

  const isCustomerDetailsShow = () => {
    if (personnalID && isAddingANewCustomer) {
      return true;
    }

    if (removeCustomer && valuePersonnelAuth === authSection?.personnelAuth) {
      return true;
    }

    if (personnalID && selectedCustomers.length) {
      return true;
    }

    if (personnalID && !isCustomerSelectedFromTheTable) {
      return false;
    }

    if (personnalID && isCustomerSelectedFromTheTable) {
      return true;
    }

    if (personnalID && !selectedCustomers.length) {
      return false;
    }

    return true;
  };

  const isFildesDissabled = () => {
    if (!programSecurityListArray?.includes(314)) {
      return true;
    }

    if (isEditClick) {
      return false;
    }

    if (isAddingANewCustomer) {
      return false;
    }

    if (!isAuthenticated) {
      return true;
    }

    if (valuePersonnelAuth === authSection?.bypass) {
      return false;
    }
    return false;
  };

  const isPersonnelDisabled = () => {
    if (
      personnalID &&
      personnalID === personalDetailForm?.values?.personnel?.value
    ) {
      return false;
    } else if (!isPersonnelEditable) {
      return true;
    }
    return isFildesDissabled();
  };

  // button showing logics
  const isResetButtonShow = () => {
    if (personnalID && isEditClick) {
      return false;
    }

    if (personnalID) {
      return false;
    }

    return false;
  };

  const isNewButtonShow = () => {
    if (!programSecurityListArray?.includes(314)) {
      return true;
    }

    if (!isPersonnelEditable) {
      return true;
    }

    return false;
  };

  const isEditButtonShow = () => {
    if (personnalID && !programSecurityListArray?.includes(314)) {
      return true;
    }

    if (!personnalID) {
      return false;
    }

    return true;
  };

  const isAddCustomerButtonShow = () => {
    if (personnalID && !programSecurityListArray?.includes(314)) {
      return true;
    }

    if (personnalID) {
      return false;
    }

    if (!isPersonnelEditable) {
      return true;
    }

    return true;
  };

  const isRemoveCutomerButtonShow = () => {
    if (!selectedCustomers.length) {
      return true;
    }

    if (selectedCustomers.length > 0) {
      if (
        personnalID &&
        personnalID === personalDetailForm?.values?.personnel?.value
      ) {
        return true;
      }

      if (
        (!isInteractionEditable || !isDREditable) &&
        !isCustAssocToPersonnel
      ) {
        return true;
      }

      if (!personnalID) {
        return true;
      }

      return false;
    }

    if (personnalID) {
      return false;
    }

    return true;
  };

  const isResetAuthButtonShow = () => {
    if (personnalID && !programSecurityListArray?.includes(314)) {
      return true;
    }

    if (personnalID) {
      return false;
    }

    return true;
  };

  const isHistoryButtonShow = () => {
    if (personnalID) {
      return false;
    }

    return true;
  };

  const isSaveButtonShow = () => {
    if (!programSecurityListArray?.includes(314)) {
      return true;
    }

    if (isFildesDissabled() || !isPersonnelEditable) {
      return true;
    }

    if (isEditClick) {
      return false;
    }

    if (personnalID && !isAuthenticated) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    showErrorAlert && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [showErrorAlert]);

  // get all the branches of the region
  useEffect(() => {
    getAllBarnchesFromFireStore();
  }, []);

  // set the default branch into the auth section
  useEffect(() => {
    if (allBranches && allBranches?.length) {
      setIsLoading((prev) => true);
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      personalDetailForm.setFieldValue(
        "branch",
        selectedLocationDetails?.district_id
      );
      setIsLoading((prev) => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBranches]);

  // get customers for the selected branch
  useEffect(() => {
    if (personalDetailForm?.values?.branch) {
      getCustomersBasedOnTheSelectedBranch(personalDetailForm?.values?.branch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailForm?.values?.branch]);

  // get challange question
  useEffect(() => {
    if (personnelAuth?.length) {
      personalDetailForm.setFieldValue(
        "challengeQuestion",
        personnelAuth[0]?.challenge_question
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelAuth]);

  useEffect(() => {
    if (valuePersonnelAuth === authSection?.bypass) {
      personalDetailForm.setFieldValue("bypassedBy", authInfo?.displayName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuePersonnelAuth]);

  useEffect(() => {
    if (isAuthenticated && !personnalID) {
      getCountryData();
      getInitialPersonnelDetails(personalDetailForm.values.personnel.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, personnalID]);

  useEffect(() => {
    if (personnalID) {
      getCountryData();
      getNavigatedPersonnelDetails(personnalID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnalID]);

  useEffect(() => {
    if (isAuthenticated && isAddingANewCustomer && personnalID) {
      personnalID
        ? getNavigatedPersonnelDetails(personnalID)
        : getInitialPersonnelDetails(personalDetailForm.values.personnel.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingANewCustomer, isAuthenticated]);

  // this is to check after authentication if personnel has rights
  // store personnel customers
  useEffect(() => {
    if (
      isAuthenticated &&
      valuePersonnelAuth === authSection?.personnelAuth &&
      personnalID &&
      personalDetailForm.values.personnel?.value
    ) {
      getInitialPersonnelDetails(
        personalDetailForm.values.personnel.value,
        true
      );
    }
    if (!isAuthenticated) {
      setPersonnelCustomerIds([]);
      setPersonnelCustomerList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailForm.values.personnel.value, isAuthenticated, personnalID]);

  useEffect(() => {
    if (personnalID && !personalDetailForm.values.personnel?.value) {
      setPersonnelCustomerList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailForm.values.personnel.value]);

  useEffect(() => {
    if (isAuthenticated && valuePersonnelAuth === authSection?.personnelAuth) {
      dispatch(setAuthenticationState(personalDetailForm.values));
    } else if (isAuthenticated && valuePersonnelAuth === authSection?.bypass) {
      setSelectedCustomers([]);
      personalDetailForm.setFieldValue("customerDetailCustomer", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (selectedCustomers.length !== 0) {
      if (
        isAuthenticated &&
        valuePersonnelAuth === authSection?.personnelAuth
      ) {
        let permission = persCustomerPermissionList.concat(
          customerPermissionList
        );
        const commonPermissionList = permission?.filter((item1) =>
          selectedCustomers?.some(
            (item2) => item1.customer_id === item2.customer_id
          )
        );
        checkIfInteractionDrPermission(commonPermissionList);
      }
      for (let element of selectedCustomers) {
        if (!personnelCustomerIds.includes(element.customer_id)) {
          setIsCustAssocToPersonnel(false);
          return;
        }
      }
      setIsCustAssocToPersonnel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomers]);

  // merging unique customers of authorized and editing personnel
  useEffect(() => {
    if (personnelCustomerList?.length > 0 && customersList?.length > 0) {
      let persCustomerList = JSON.parse(JSON.stringify(personnelCustomerList));
      const personnelCustomer = persCustomerList.filter(
        (item2) =>
          !customersList.some(
            (item1) => item1.customer_id === item2.customer_id
          )
      );

      let persCustList = [];
      for (let i = 0; i < personnelCustomer.length; i++) {
        let keyList = Object.keys(personnelCustomer[i]);
        let obj = {};
        for (let j = 0; j < keyList.length; j++) {
          let key = keyList[j];
          if (
            key === "branch" ||
            key === "name" ||
            key === "country_id" ||
            key === "country_name" ||
            key === "customer" ||
            key === "customer_id" ||
            key === "customer_name" ||
            key === "customer_number" ||
            key === "default_locale_id" ||
            key === "district_id" ||
            key === "district_name"
          ) {
            obj[key] = personnelCustomer[i][key];
          } else {
            obj[key] = null;
          }
        }
        persCustList.push(obj);
      }

      const mergedArray = customersList.concat(persCustList);
      const sortedCustomerList = getSortedData(mergedArray, "customer", "asc");
      const custList = personnelCustomerList?.map((data) => data.customer_id);
      setPersonnelCustomerIds(custList);
      setCustomersList(sortedCustomerList);
    } else {
      setCustomersList(originalCustomerList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelCustomerList?.length, customersList?.length]);

  // if authorized and editing personnel do not have common customer, redirect to new
  useEffect(() => {
    if (
      originalCustomerList.length !== 0 &&
      personnelCustomerList.length !== 0
    ) {
      const commonCustomer = originalCustomerList?.filter((item1) =>
        personnelCustomerList?.some(
          (item2) => item1.customer_id === item2.customer_id
        )
      );
      if (commonCustomer?.length === 0) {
        onNewButtonClick();
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalCustomerList, personnelCustomerList]);

  const handleHistoryButtonClick = () => {
    const props = {
      firstName: personalDetailForm?.values?.firstName,
      lastName: personalDetailForm?.values?.lastName
    };
    navigate(
      `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_AUTH_BASE_PATH}/${PERSONAL_HISTORY}/${personnalID}`,
      { state: props }
    );
  };

  useEffect(() => {
    if (valuePersonnelAuth === authSection?.bypass) {
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      personalDetailForm.setFieldValue(
        "customerDetailBranch",
        selectedLocationDetails
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuePersonnelAuth]);

  useEffect(() => {
    if (allCountries?.length && personnelAuth) {
      const country = allCountries?.filter(
        (c) => c?.id === personnelAuth[0]?.locale_id
      );
      if (country?.length) {
        personalDetailForm.handleChange({
          target: { name: "selectedCountry", value: country[0] }
        });
        personalDetailForm.handleChange({
          target: { name: "customerDetailSelectedCountry", value: country[0] }
        });
      } else {
        const country = allCountries?.filter(
          (c) => c?.id === auth?.user?.locale_id
        );
        personalDetailForm.handleChange({
          target: { name: "selectedCountry", value: country[0] }
        });
        personalDetailForm.handleChange({
          target: { name: "customerDetailSelectedCountry", value: country[0] }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelAuth, allCountries]);

  useEffect(() => {
    if (allCountries?.length && personalDetailForm?.values?.countryId) {
      const country = allCountries?.filter(
        (c) => c?.id === personalDetailForm?.values?.countryId
      );
      personalDetailForm.handleChange({
        target: { name: "selectedCountry", value: country[0] }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCountries, personalDetailForm?.values?.countryId]);

  useEffect(() => {
    if (isAuthenticated && !programSecurityListArray?.includes(314)) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(t("personnelDetail.insufficientRights"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      {/*Success message popup*/}
      {messageSuccess && (
        <SuccessPopup
          message={successInsertMessage}
          close={setMessageSuccess}
        />
      )}
      {isLoading && <FullCircularLoader />}
      <form>
        <Stack gap={spacing.gap}>
          <AuthenticationCallerButton
            isAuthenticated={isAuthenticated}
            onAuthenticatedCallerCliked={onAuthenticatedCallerCliked}
          />

          {showErrorAlert && (
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowErrorAlert(false);
                    setShowErrorAlertMsg("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {Array.isArray(showErrorAlertMsg)
                ? showErrorAlertMsg.map((message, index) => (
                    <>
                      {`${index + 1}) ${message}`}
                      <br />
                    </>
                  ))
                : showErrorAlertMsg}
            </Alert>
          )}

          {messageInfo && (
            <Alert
              severity="warning"
              icon={<img src={ErrorIcon} alt="warning" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMessageInfo(false);
                    setInfoInsertMessage("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {Array.isArray(infoInsertMessage)
                ? infoInsertMessage.map((message, index) => (
                    <>
                      {`${index + 1}) ${message}`}
                      <br />
                    </>
                  ))
                : infoInsertMessage}
            </Alert>
          )}

          {isShowAuthenticationSection() && (
            <>
              {/* Personnel Authentication Section */}
              {!isAuthenticated && (
                <>
                  <AuthenticationSection
                    allBranches={allBranches}
                    handleBranchChange={handleBranchChange}
                    pageFormDetails={personalDetailForm}
                    valuePersonnelAuth={valuePersonnelAuth}
                    handlePersonnelAuthChange={handlePersonnelAuthChange}
                    allCustomers={allCustomers}
                    handleCustomerChange={handleCustomerChange}
                    handlePersonnelChange={handlePersonnelChange}
                    onAuthenticateClick={onAuthenticateClick}
                    isAuthenticationError={isAuthenticationError}
                    isAuthenticated={isAuthenticated}
                    onBypassClick={onBypassClick}
                    programSecurityListArray={programSecurityListArray}
                    isBypassError={isBypassError}
                  />
                </>
              )}
            </>
          )}

          {isAuthenticated && (
            <>
              <SuccessAuthSection
                personalDetailForm={personalDetailForm}
                valuePersonnelAuth={valuePersonnelAuth}
              />
              <Divider />
            </>
          )}

          {isShowDetailsSection() && (
            <>
              {/* Identification Section */}
              <PDIdentificationSection
                personalDetailForm={personalDetailForm}
                handleEnableSecureSyncChange={handleEnableSecureSyncChange}
                allCountries={allCountries}
                onCountryChange={onCountryChange}
                isFildesDissabled={isFildesDissabled}
                isPersonnelDisabled={isPersonnelDisabled}
                requiredFieldValidations={requiredFieldValidations}
                handleFirstNameField={handleFirstNameField}
                handleLastNameField={handleLastNameField}
                handlebusinessEmailField={handlebusinessEmailField}
                handleFieldChanges={handleFieldChanges}
              />
              <Divider />

              {/* Customer Details Section */}
              {isCustomerDetailsShow() && (
                <>
                  <PDCustomerDetailsSection
                    personalDetailForm={personalDetailForm}
                    allCountries={allCountries}
                    onCustomerDetailsCountryChange={
                      onCustomerDetailsCountryChange
                    }
                    handleChangeContractOwner={handleChangeContractOwner}
                    handleChangeDiscrepancyContact={
                      handleChangeDiscrepancyContact
                    }
                    handleChangeDRAOne={handleChangeDRAOne}
                    handleChangeDRATwo={handleChangeDRATwo}
                    handleChangeDRAThree={handleChangeDRAThree}
                    handleChangeDRAFour={handleChangeDRAFour}
                    handleChangeIAOne={handleChangeIAOne}
                    handleChangeIATwo={handleChangeIATwo}
                    handleChangeIAThree={handleChangeIAThree}
                    handleChangeIAFour={handleChangeIAFour}
                    handleChangeIAFive={handleChangeIAFive}
                    handleChangeIASix={handleChangeIASix}
                    valuePersonnelAuth={valuePersonnelAuth}
                    allBranches={allBranches}
                    onBranchChange={onBranchChange}
                    allCustomers={allCustomers}
                    onCustomerChange={onCustomerChange}
                    isFildesDissabled={isFildesDissabled}
                    isPersonnelDisabled={isPersonnelDisabled}
                    isInteractionEditable={isInteractionEditable}
                    isDREditable={isDREditable}
                    handlebusinessPhoneField={handlebusinessPhoneField}
                    requiredFieldValidations={requiredFieldValidations}
                    personnalID={personnalID}
                    isAuthenticated={isAuthenticated}
                    selectedCustomers={selectedCustomers}
                    isAddingANewCustomer={isAddingANewCustomer}
                    handleFieldChanges={handleFieldChanges}
                  />
                  <Divider />
                </>
              )}

              {/* Table Section */}
              {isTableShow() && (
                <>
                  <PersonnelDetailTable
                    customersList={customersList}
                    onCustomerNameSelect={onCustomerNameSelect}
                    personnalID={personnalID}
                    onCustomerSelect={onCustomerSelect}
                    onCustomerSelectAll={onCustomerSelectAll}
                    selectedCustomers={selectedCustomers}
                    isAuthenticated={isAuthenticated}
                    personnelCustomerIds={personnelCustomerIds}
                    valuePersonnelAuth={valuePersonnelAuth}
                    isEditClick={isEditClick}
                  />
                  <Divider />
                </>
              )}

              {/* Button Section */}
              <Box sx={personnelDetailStyles.buttonStyles.containerBox}>
                <Button
                  variant="outlined"
                  disabled={isResetButtonShow()}
                  onClick={
                    !personnalID
                      ? () => onNewButtonClick()
                      : () => onResetButtonClick()
                  }
                >
                  {t("common.reset")}
                </Button>
                <Button
                  variant="outlined"
                  disabled={isNewButtonShow()}
                  onClick={() => onNewButtonClick()}
                >
                  {t("common.new")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => onEditClick()}
                  disabled={isEditButtonShow()}
                >
                  {t("common.edit")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => onAddCustomerClick()}
                  disabled={isAddCustomerButtonShow()}
                >
                  {t("personnelDetail.addCustBtn")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => onRemoveCustomerClick()}
                  disabled={isRemoveCutomerButtonShow()}
                >
                  {t("personnelDetail.removeCustBtn")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => onResetAuthClick()}
                  disabled={isResetAuthButtonShow()}
                >
                  {t("personnelDetail.resetAuthNumBtn")}
                </Button>
                <Button variant="outlined" onClick={() => onSearchClick()}>
                  {t("common.search")}
                </Button>
                <Button
                  variant="outlined"
                  disabled={isHistoryButtonShow()}
                  onClick={() => handleHistoryButtonClick()}
                >
                  {t("personnelDetail.historyBtn")}
                </Button>
                <Button
                  variant="contained"
                  disabled={isSaveButtonShow()}
                  onClick={() => onSaveClick()}
                >
                  {t("common.save")}
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </form>

      <PersonnelDetailResetAuthModal
        showResetAuthPopup={showResetAuthPopup}
        setShowResetAuthPopup={setShowResetAuthPopup}
        onResetAuthYesClick={onResetAuthYesClick}
        personalDetailForm={personalDetailForm}
        valuePersonnelAuth={valuePersonnelAuth}
        employeeEmail={auth?.user?.business_email_addr}
      />

      <RemoveCustomerModal
        showRemoveCustomerModal={showRemoveCustomerModal}
        setShowRemoveCustomerModal={setShowRemoveCustomerModal}
        onRemoveCustomerYesClick={onRemoveCustomerYesClick}
      />

      <PersonnelDetailRemoveCustomerAssociation
        personnelDetailRemoveCustomerAssociationModalShow={
          personnelDetailRemoveCustomerAssociationModalShow
        }
        setPersonnelDetailRemoveCustomerAssociationModalShow={
          setPersonnelDetailRemoveCustomerAssociationModalShow
        }
        onPersonnelDetailRemoveCustomerAssociation={
          onPersonnelDetailRemoveCustomerAssociation
        }
      />
    </>
  );
};

export default PersonnelDetailLayout;
