/* eslint-disable eqeqeq */
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { DatePickerField } from "components/core";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { DEFAULT_LOCALE, DestructionStatus } from "utils/constants";
import { ReactComponent as ArrowDown } from "../../../../../assets/images/ArrowDown.svg";
import { ReactComponent as ArrowDownDisabled } from "../../../../../assets/images/ArrowDownDisabled.svg";
import { ReactComponent as ClosedIcon } from "../../../../../assets/images/CloseIcon.svg";
import { MediaDestructionRequestDeliveryFormStyles } from "../MediaDestructionRequestDeliveryFormStyles";

const ScheduleServiceForm = ({
  scheduleServiceForm,
  destructionRqDetails,
  scheduleServiceModifiedDate,
  serviceLocation,
  scheduleTrackingRequirement,
  minDate,
  maxDate,
  formSubmitted,
  scheduleFormFields,
  scheduleDeliveryDateDef,
  scheduleServiceLocationSelected,
  handleScheduleServiceLocationChange,
  localeByBranch,
  scheduleModDate
}) => {
  const { t } = useTranslation();
  return (
    <Box
      id="request_delivery_31"
      display="flex"
      flexDirection="column"
      gap={spacing.verticalMargin20}
    >
      {/* Schedule Service title  */}

      <Grid id="request_delivery_32" item>
        <Grid id="request_delivery_33" item xs={12}>
          <Typography
            id="request_delivery_34"
            color="primary"
            sx={{
              ...MediaDestructionRequestDeliveryFormStyles.HeaderTitle
            }}
          >
            {t("mediaDestructionDelivery.scheduleService")}
          </Typography>
        </Grid>
      </Grid>

      {/*Schedule Date and perform section */}

      {scheduleServiceModifiedDate && (
        <Grid id="request_delivery_35" item>
          <Grid
            id="request_delivery_36"
            container
            gap={spacing.verticalMargin20}
          >
            <Typography
              id="request_delivery_37"
              color="primary"
              sx={{
                ...MediaDestructionRequestDeliveryFormStyles.subSentence
              }}
            >
              {t("mediaDestructionDelivery.modifiedDate")}
              {scheduleModDate}
            </Typography>
            <Typography
              id="request_delivery_38"
              sx={{
                ...MediaDestructionRequestDeliveryFormStyles.subSentence
              }}
            >
              {t("mediaDestructionDelivery.performedBy")}
              {destructionRqDetails?.assignment_employee_name}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        id="request_delivery_39"
        display="flex"
        flexDirection="row"
        gap={spacing.verticalMargin20}
      >
        {/* Schedule Tracking Requirements field */}

        <Grid id="request_delivery_40" item>
          <Autocomplete
            id="request_delivery_41"
            disablePortal
            size="medium"
            options={["", ...scheduleTrackingRequirement]}
            sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
            clearIcon={<ClosedIcon />}
            popupIcon={<ArrowDownDisabled />}
            value={scheduleTrackingRequirement[0].label}
            disabled
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                id="request_delivery_42"
                {...params}
                name="scheduleTrackingRequirements"
                label={t("mediaDestructionDelivery.trackingRequrment")}
                value={scheduleServiceForm.values?.scheduleTrackingRequirement}
                error={
                  scheduleServiceForm.touched.scheduleTrackingRequirements &&
                  Boolean(
                    scheduleServiceForm.errors.scheduleTrackingRequirements
                  )
                }
                helperText={
                  scheduleServiceForm.touched.scheduleTrackingRequirements &&
                  scheduleServiceForm.errors.scheduleTrackingRequirements
                }
                required
              />
            )}
            onChange={(e, value) =>
              scheduleServiceForm.handleChange({
                target: {
                  name: "scheduleTrackingRequirements",
                  value: value?.value
                }
              })
            }
          />
        </Grid>

        {/* Schedule Delivery Date field */}

        <Grid id="request_delivery_43" item>
          <DatePickerField
            id="request_delivery_44"
            name="scheduleDeliveryDate"
            label={t("mediaDestructionDelivery.scheduleDeliveryDate")}
            required
            sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
            locale={localeByBranch || DEFAULT_LOCALE}
            disabled={
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Scheduled
              ) ||
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Complete
              ) ||
              formSubmitted ||
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Cancelled
              ) ||
              !scheduleFormFields
            }
            value={
              destructionRqDetails
                ? destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Authorized
                  ? scheduleServiceForm.values.scheduleDeliveryDate
                  : dayjs(scheduleDeliveryDateDef)
                : scheduleServiceForm.values.scheduleDeliveryDate
            }
            minDate={minDate}
            maxDate={maxDate}
            onChange={(selectedDate) =>
              scheduleServiceForm.handleChange({
                target: {
                  name: "scheduleDeliveryDate",
                  value: selectedDate
                }
              })
            }
            error={
              destructionRqDetails?.destruct_request_status_id ==
              DestructionStatus.Scheduled
                ? false
                : scheduleServiceForm.touched.scheduleDeliveryDate &&
                  Boolean(scheduleServiceForm.errors.scheduleDeliveryDate)
            }
            helperText={
              scheduleServiceForm.touched.scheduleDeliveryDate &&
              scheduleServiceForm.errors.scheduleDeliveryDate
            }
          />
        </Grid>
        <Grid id="request_delivery_45" item>
          {/*Schedule Service Location Input */}
          <Autocomplete
            id="request_delivery_46"
            disablePortal
            size="medium"
            value={scheduleServiceLocationSelected}
            sx={MediaDestructionRequestDeliveryFormStyles.FieldSize}
            clearIcon={<ClosedIcon />}
            popupIcon={
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Scheduled
              ) ||
              formSubmitted ||
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Cancelled
              ) ||
              !scheduleFormFields ? (
                <ArrowDownDisabled />
              ) : (
                <ArrowDown />
              )
            }
            options={[
              scheduleServiceLocationSelected,
              ...serviceLocation.filter(
                (serviceLocation) =>
                  serviceLocation?.value !==
                  scheduleServiceLocationSelected?.value
              )
            ]}
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value && option?.label === value?.label
            }
            disabled={
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Scheduled
              ) ||
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Complete
              ) ||
              formSubmitted ||
              Boolean(
                destructionRqDetails?.destruct_request_status_id ==
                  DestructionStatus.Cancelled
              ) ||
              !scheduleFormFields
            }
            onChange={handleScheduleServiceLocationChange}
            renderInput={(params) => (
              <TextField
                {...params}
                name="scheduleServiceLocation"
                label={t("mediaDestructionDelivery.serviceLocation")}
                id="request_delivery_116"
                value={scheduleServiceForm?.values?.scheduleServiceLocation}
                error={
                  scheduleServiceForm.touched?.scheduleServiceLocation &&
                  Boolean(scheduleServiceForm.errors?.scheduleServiceLocation)
                }
                onBlur={scheduleServiceForm.handleBlur}
                helperText={
                  scheduleServiceForm.touched?.scheduleServiceLocation &&
                  scheduleServiceForm.errors?.scheduleServiceLocation
                }
                onChange={(event) => event.target.value}
                required
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Schedule Service Delivery instruction field */}

      <Grid id="request_delivery_96" item>
        <TextField
          id="request_delivery_97"
          name="scheduleDeliveryInstructions"
          label={t("mediaDestructionDelivery.deliveryInstruction")}
          size="medium"
          fullWidth
          sx={MediaDestructionRequestDeliveryFormStyles.InstructionFieldSize}
          disabled={
            Boolean(
              destructionRqDetails?.destruct_request_status_id ==
                DestructionStatus.Scheduled
            ) ||
            Boolean(
              destructionRqDetails?.destruct_request_status_id ==
                DestructionStatus.Complete
            ) ||
            formSubmitted ||
            Boolean(
              destructionRqDetails?.destruct_request_status_id ==
                DestructionStatus.Cancelled
            ) ||
            !scheduleFormFields
          }
          variant="outlined"
          multiline
          rows={2}
          value={scheduleServiceForm.values?.scheduleDeliveryInstructions}
          onChange={scheduleServiceForm.handleChange}
          error={
            scheduleServiceForm.touched?.scheduleDeliveryInstructions &&
            Boolean(scheduleServiceForm.errors?.scheduleDeliveryInstructions)
          }
          onBlur={scheduleServiceForm.handleBlur}
          helperText={
            scheduleServiceForm.touched?.scheduleDeliveryInstructions &&
            scheduleServiceForm.errors?.scheduleDeliveryInstructions
          }
          required
        />
      </Grid>
    </Box>
  );
};

export default ScheduleServiceForm;
