import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROOT } from "routing/Paths";
import SB_LOGO from "../../assets/images/SB_Logo.svg";
import RESTRICTED_IMG from "../../assets/images/restricted_img.svg";
import {
  MAIN_DIV_STYLES,
  RESTRICTED_DESCRIPTION,
  RESTRICTED_IMG_WIDTH,
  RESTRICTED_MSG,
  RESTRICTED_TEXT,
  SB_LOGO_WIDTH
} from "./UnauthorizedPage.Styles";

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    const redirectTimer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft === 1) {
          clearInterval(redirectTimer);
          navigate(ROOT);
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000); // Update every 1 second

    return () => clearInterval(redirectTimer); // Clear the timer when the component unmounts or when the redirection occurs
  }, [navigate]);

  return (
    <div style={MAIN_DIV_STYLES}>
      <img alt="sb_logo" src={SB_LOGO} style={SB_LOGO_WIDTH} />
      <img
        alt="restricted_img"
        src={RESTRICTED_IMG}
        style={RESTRICTED_IMG_WIDTH}
      />
      <Typography variant="h4" style={RESTRICTED_TEXT}>
        {t("unauthorized.accessRestricted")}
      </Typography>

      <div style={RESTRICTED_DESCRIPTION}>
        <Typography>{t("unauthorized.accessRestrictedDescription")}</Typography>
      </div>

      <Typography variant="h6" style={RESTRICTED_MSG}>
        {`${t("unauthorized.accessRestrictedMsg")} ${timeLeft} ${t(
          "unauthorized.seconds"
        )}`}
      </Typography>
    </div>
  );
};

export default UnauthorizedPage;
