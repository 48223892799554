import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  SvgIcon,
  TableContainer,
  TextField
} from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_DATA_TABLE_OPTIONS, errorMsgs } from "utils/constants";
import { MediaListModalStyles } from "./MediaListModalStyles";

import ErrorIcon from "assets/images/warning-2.svg";

import { ReactComponent as DeleteIcon } from "assets/images/CloseIcon.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import {
  actionTypes,
  disabledOpacity,
  enabledOpacity
} from "utils/constants/open-media-processing/Research";

const MediaListModal = ({ open, onClose, mediaList, dispatch }) => {
  const { t } = useTranslation();
  const [mediaNumber, setMediaNumber] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [error, setError] = useState(false);
  const [mediaNumberList, setMediaNumberList] = useState(mediaList);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  useEffect(() => {
    setMediaNumberList(mediaList);
  }, [mediaList]);

  const handleAddMedia = async () => {
    if (mediaNumber.trim() === "") {
      setError(true);
      return;
    }

    if (
      mediaNumberList.findIndex(
        (item) => item.number.toLowerCase() === mediaNumber.toLowerCase()
      ) > -1
    ) {
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode20382
      );

      setThrowError(true);
      setThrowErrorMessage(errorMsg.replace("|", t("openMediaResearch.media")));
      return;
    }
    if (editIndex !== null) {
      const updatedMediaList = mediaNumberList.map((item, index) =>
        index === editIndex ? { number: mediaNumber } : item
      );
      setMediaNumberList(updatedMediaList);
      setEditIndex(null);
    } else {
      setMediaNumberList([...mediaNumberList, { number: mediaNumber }]);
    }
    setMediaNumber("");
    setError(false);
    setThrowError(false);
    setThrowErrorMessage(null);
  };

  const handleEditMedia = (index) => {
    setMediaNumber(mediaNumberList[index].number);
    setEditIndex(index);
    setError(false);
  };

  const handleRemoveMedia = (index) => {
    setMediaNumberList(mediaNumberList.filter((_, i) => i !== index));
  };

  const handleClear = () => {
    setMediaNumber("");
    setEditIndex(null);
    setMediaNumberList([]);
    setError(false);
  };

  const handleSave = async () => {
    if (mediaNumberList.length === 0) {
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode20259
      );
      setThrowError(true);
      setThrowErrorMessage(
        errorMsg.replace("|", t("openMediaResearch.mediaNumberMsg"))
      );
      return;
    }
    dispatch({ type: actionTypes.changeMediaList, payload: mediaNumberList });
    setThrowError(false);
    onClose();
  };

  const handleCancel = () => {
    setMediaNumber("");
    setEditIndex(null);
    setMediaNumberList([]);
    setThrowError(false);

    onClose();
  };

  const handleBlur = () => {
    if (mediaNumber.trim() === "") {
      setError(true);
    }
  };

  const columns = [
    {
      name: "",
      options: {
        setCellProps: () => ({ style: MediaListModalStyles.editColumn }),
        customBodyRender: (value, tableMeta, updateValue) => {
          let { rowIndex } = tableMeta;
          const isDisabled = rowIndex === editIndex;
          const buttonOpacity = isDisabled ? disabledOpacity : enabledOpacity;
          return (
            <>
              <Button
                disabled={rowIndex === editIndex}
                onClick={() => handleEditMedia(rowIndex)}
                sx={{ opacity: buttonOpacity }}
              >
                <SvgIcon component={EditIcon} />
              </Button>
              <Button
                disabled={rowIndex === editIndex}
                onClick={() => handleRemoveMedia(rowIndex)}
                sx={{ opacity: buttonOpacity }}
              >
                <SvgIcon component={DeleteIcon} />
              </Button>
            </>
          );
        }
      }
    },
    {
      name: "number",
      label: t("openMediaResearch.mediaNumberMsg")
    }
  ];
  return (
    <CommonModal
      open={open}
      onClose={onClose}
      title={t("openMediaResearch.mediaList")}
      body={
        <>
          {throwError && (
            <Alert
              severity="warning"
              style={MediaListModalStyles.alertPadding}
              icon={
                <img
                  style={MediaListModalStyles.alertSize}
                  src={ErrorIcon}
                  alt="error"
                />
              }
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                    setThrowErrorMessage(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          )}
          <Box component={Paper}>
            <Box
              display="flex"
              mb={2}
              p={2}
              gap={spacing.gap}
              alignItems="center"
            >
              <TextField
                label={t("openMediaResearch.mediaNumberreq")}
                value={mediaNumber}
                onChange={(e) => setMediaNumber(e.target.value)}
                onBlur={handleBlur}
                error={error}
                helperText={error ? t("openMediaResearch.mediaNumberErr") : ""}
                inputProps={{ maxLength: 20 }}
              />
              <Button variant="outlined" onClick={handleAddMedia} ml={2} p={3}>
                {t(
                  editIndex !== null
                    ? t("common.update")
                    : t("openMediaResearch.add")
                )}
              </Button>
            </Box>
            <Box
              sx={{
                ...MediaListModalStyles.tableStyles.customScrollbar
              }}
            >
              <TableContainer>
                <MUIDataTable
                  columns={columns}
                  options={{
                    ...DEFAULT_DATA_TABLE_OPTIONS,
                    pagination: 0,
                    textLabels: {
                      body: { noMatch: "" }
                    }
                  }}
                  data={mediaNumberList}
                />
              </TableContainer>
            </Box>
          </Box>
        </>
      }
      buttons={
        <>
          <Button
            id="CancelBtn-modal"
            variant="outlined"
            onClick={handleCancel}
            sx={MediaListModalStyles.buttonSX}
          >
            {t("common.cancel")}
          </Button>
          <Button
            id="ClearBtn-modal"
            variant="outlined"
            onClick={handleClear}
            sx={MediaListModalStyles.buttonSX}
          >
            {t("common.clear")}
          </Button>

          <Button
            id="saveBtn-modal"
            variant="contained"
            sx={MediaListModalStyles.buttonSX}
            onClick={handleSave}
          >
            {t("common.ok")}
          </Button>
        </>
      }
    />
  );
};

export default MediaListModal;
