export const customerScheduleStyle = {
  mainDivStyles: {
    marginTop: "20px"
  },
  errorStyle: {
    marginBottom: "20px"
  },
  iconStyle: { width: 20 }
};
export const calendarUi = {
  buttonStyle: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    backgroundColor: "transparent",
    outline: 0,
    border: 0,
    margin: 0,
    padding: 0,
    cursor: "pointer",
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    width: "40px",
    height: "36px",
    borderRadius: "50%"
  },
  buttonActiveStyle: {
    backgroundColor: "var(--color-im-mid-blue)",
    color: "var(--color-white)"
  }
};
