import { Stack } from "@mui/material";
import PersonnelDetailHistory from "components/authorization/personnel-detail/PersonnelDetailHistory";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";
const PersonnelHistory = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={i18n.t("menu.personalHistory")} />
      <PersonnelDetailHistory />
    </Stack>
  );
};

export default PersonnelHistory;
