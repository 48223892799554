import { Box, CircularProgress, Fade } from "@mui/material";
import { CircularLoaderStyles } from "./CircularLoader.Styles";
const CircularLoader = (props) => {
  const { loading, children } = props;
  return (
    <Box sx={CircularLoaderStyles.root}>
      {children}
      <Fade in={loading} unmountOnExit>
        <Box sx={CircularLoaderStyles.loader}>
          <CircularProgress color="primary" />
        </Box>
      </Fade>
    </Box>
  );
};

export default CircularLoader;
