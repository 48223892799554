import { Stack } from "@mui/material";
import PlaceHoldOnMediaDestructionRequests from "components/customer-information/preferences/place-hold-on-media-destruction-requests/PlaceHoldOnMediaDestructionRequests";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const PlaceHoldOnMediaDestructionRequestsScreen = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("preferences.detailsTitle")}`} />
        <PlaceHoldOnMediaDestructionRequests />
      </Stack>
    </>
  );
};

export default PlaceHoldOnMediaDestructionRequestsScreen;
