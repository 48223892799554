import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { PageTitle } from "components/shared";
import i18n from "i18n";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { downloadReportOptions, ERROR_TYPES } from "utils/constants";
import { findErrorMessage } from "utils/helpers";
import { distributionErrorReport } from "./Services";
const UploadErrorsPage = ({
  options,
  data,
  mainDistrictId,
  userName,
  setErrorScreen,
  reportFormat,
  setTableGridData,
  setSelectedDoc,
  batchId,
  setIsLoading
}) => {
  const [downloadModal, setDownloadModal] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const downloadHandler = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      let res = await distributionErrorReport(
        mainDistrictId,
        userName,
        batchId,
        reportFormat
      );
      if (!res.success) {
        setThrowErrorMessage(res.error);
        setThrowError(true);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePrint = () => {
    setDownloadModal(true);
  };
  const columns = [
    {
      name: "Run_Name",
      label: t("containerProcessing.distribution.run")
    },
    {
      name: "Customer_Number",
      label: t("containerProcessing.distribution.customerHash")
    },
    {
      name: "Container",
      label: t("containerProcessing.distribution.container")
    },
    {
      name: "Return_Date",
      label: t("containerProcessing.distribution.returnDate")
    },
    {
      name: "Personnel",
      label: t("containerProcessing.distribution.personnel")
    }
  ];
  return (
    <>
      {downloadModal && (
        <DownloadPopup
          title={t("containerProcessing.distribution.downloadReport")}
          showPrintModal={setDownloadModal}
          downloadOptions={downloadReportOptions}
          onCancleClick={() => setDownloadModal(false)}
          onDownloadClick={downloadHandler}
          setSelectedValue={setSelectedDoc}
          setThrowError={setThrowError}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
        />
      )}
      <PageTitle
        title={i18n.t("containerProcessing.distribution.uploadErrors")}
      />
      <Typography sx={ContainerProcessingStyles.marginTop25}>
        {" "}
        {t("containerProcessing.distribution.description")}
      </Typography>
      <Box sx={ContainerProcessingStyles.marginTop15}>
        <Paper>
          <Box sx={ContainerProcessingStyles.tableRecords}>
            <Typography>
              {data.length > 0 ? data.length : 0}{" "}
              {t("containerProcessing.distribution.recordsFound")}
            </Typography>
            <Box sx={ContainerProcessingStyles.marginLeftAuto}>
              <Button variant="outlined" onClick={handlePrint}>
                {t("common.print")}
              </Button>
            </Box>
          </Box>
          <TableContainer>
            <MUIDataTable columns={columns} options={options} data={data} />
          </TableContainer>
        </Paper>
      </Box>

      <Button
        variant="outlined"
        sx={ContainerProcessingStyles.marginTop25}
        onClick={() => {
          setErrorScreen(false);
          setTableGridData([]);
        }}
      >
        {t("common.cancel")}
      </Button>
    </>
  );
};

export default UploadErrorsPage;
