import { Box, Divider } from "@mui/material";
import AuditAuthForm from "components/shared/auth/AuditAuthSection";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { getCustomersQueryData, getDistricts } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  clearError,
  setAuthTypeChange,
  setBypassMesage,
  setDefaultBranchValues,
  setError,
  setShowAuthSection,
  updateDiscrepancyAuthFormValues,
  updatePreferencesAuthentication
} from "store/slices/report-module/authForm";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getLanguageId,
  getSortedData
} from "utils/helpers";
import { DetailsStyles } from "./Styles";

const AuthForm = ({ setLoading, showBypass = true }) => {
  const [allBranches, setAllBranches] = useState([]);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const { discrepancyAuthFormValues, isAuthenticated, showAuthSection } =
    useSelector((state) => state.vaultStatusReport);

  const [isBypassError, setIsBypassError] = useState(false);
  const { currentBranch, auth } = useSelector(selectAuth);
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});
  const dispatch = useDispatch();

  let initialAuthValues = {
    customer: VALUE_EMPTY_STRING,
    personnel: VALUE_EMPTY_STRING,
    authNumber: VALUE_EMPTY_STRING,
    challengeQuestion: VALUE_EMPTY_STRING,
    challengeResponse: VALUE_EMPTY_STRING,
    bypassMessage: VALUE_EMPTY_STRING
  };

  // selected personnel auth details
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: discrepancyAuthFormValues?.personnel?.value
  });
  const languageLocaleId = getLanguageId();

  const programSecurityListArray = auth?.user?.program_list
    .split(",")
    .map(Number);

  const preferencesAuthForm = useFormik({
    initialValues: discrepancyAuthFormValues
  });

  useEffect(() => {
    getAllBarnchesFromFireStore();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (personnelAuth?.length) {
      const updates = {
        ...discrepancyAuthFormValues,
        challengeQuestion: personnelAuth[0]?.challenge_question
      };
      dispatch(updateDiscrepancyAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [personnelAuth]);
  // set the default branch into the auth section
  useEffect(() => {
    if (allBranches && allBranches?.length) {
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      const updates = {
        branch: selectedLocationDetails?.district_id,
        isAuthenticate: true
      };
      dispatch(updateDiscrepancyAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [allBranches]);

  // get customers for the selected branch
  useEffect(() => {
    if (currentBranch) {
      getCustomersBasedOnTheSelectedBranch(currentBranch?.district_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch]);
  useEffect(() => {
    if (discrepancyAuthFormValues?.branch) {
      if (!isAuthenticated) {
        dispatch(updateDiscrepancyAuthFormValues(initialAuthValues));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discrepancyAuthFormValues?.branch]);

  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    setLoading(true);
    try {
      const branches = await getDistricts();
      let bran = branches?.map((b) => {
        return {
          ...b,
          label: b?.name,
          value: b?.district_id
        };
      });
      const branchData = getSortedData(bran, "name", "asc");
      branchData.unshift({
        label: "",
        value: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      setAllBranches(branchData);
      setLoading(false);
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.EXCEPTION)));
      setLoading(false);
    }
  };
  // get customers based on the selected branch
  const getCustomersBasedOnTheSelectedBranch = async (branch) => {
    setLoading(true);
    try {
      const custList = await getCustomersQueryData(branch);
      let modCux = custList?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number} - ${cus?.name}`,
          value: cus?.id
        };
      });
      setAllCustomers(modCux);
      setLoading(false);
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      setLoading(false);
    }
  };
  const handleAuthFieldsChange = (name, value) => {
    const updatedValues = {
      ...discrepancyAuthFormValues,
      [name]: value
    };
    dispatch(updateDiscrepancyAuthFormValues(updatedValues));
  };

  // handle branch change
  const handleBranchChange = (e) => {
    const updatedValues = {
      branch: e.target.value,
      customer: VALUE_EMPTY_STRING,
      personnel: VALUE_EMPTY_STRING
    };

    getCustomersBasedOnTheSelectedBranch(e.target.value);
    dispatch(updateDiscrepancyAuthFormValues(updatedValues));
  };
  // customer selection in auth section
  const handleCustomerChange = (event, newValue) => {
    dispatch(clearError());
    const updatedValues = {
      ...discrepancyAuthFormValues,
      customer: newValue,
      personnel: VALUE_EMPTY_STRING
    };
    preferencesAuthForm.setValues(updatedValues);
    dispatch(updateDiscrepancyAuthFormValues(updatedValues));
  };
  // handle Personnel selection in auth section
  const handlePersonnelChange = (selectedValue) => {
    const updatedValues = {
      ...discrepancyAuthFormValues,
      personnel: selectedValue
    };
    dispatch(updateDiscrepancyAuthFormValues(updatedValues));
  };

  const handlePersonnelAuthChange = (selectedValue) => {
    const updatedValues = {
      ...discrepancyAuthFormValues,
      personnel: VALUE_EMPTY_STRING,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING,
      personnelAuthValue: selectedValue.target.value
    };
    dispatch(setAuthTypeChange(selectedValue.target.value));
    dispatch(updateDiscrepancyAuthFormValues(updatedValues));
    dispatch(updatePreferencesAuthentication(false));
    setIsAuthenticationError(false);
    dispatch(clearError());
  };

  const onAuthenticatedCallerCliked = async () => {
    if (isAuthenticated) {
      dispatch(updateDiscrepancyAuthFormValues(initialAuthValues));
      dispatch(updatePreferencesAuthentication(false));
      dispatch(setShowAuthSection(false));
      dispatch(
        setDefaultBranchValues({
          isAuthenticated: false,
          authenticatateBranch: []
        })
      );
    } else {
      dispatch(clearError());
      dispatch(setShowAuthSection(true));
    }
  };

  // authentication click
  const onAuthenticateClick = async () => {
    setLoading(true);
    setIsAuthenticationError(false);
    try {
      // Check if either authNumber or challengeResponse is provided
      if (
        !discrepancyAuthFormValues?.authNumber?.length &&
        !discrepancyAuthFormValues?.challengeResponse?.length
      ) {
        setIsAuthenticationError(true);
        return;
      }
      // Authenticate with authNumber
      if (discrepancyAuthFormValues?.authNumber?.length) {
        if (
          discrepancyAuthFormValues.authNumber === personnelAuth[0]?.auth_number
        ) {
          dispatch(updatePreferencesAuthentication(true));
          dispatch(setShowAuthSection(false));
        } else {
          setIsAuthenticationError(true);
        }
      }

      // Authenticate with challengeResponse
      if (discrepancyAuthFormValues?.challengeResponse?.length) {
        const hashCode = await generateSHA256Hash(
          discrepancyAuthFormValues.challengeResponse.trim().toUpperCase()
        );
        const validateChallengeResponse =
          await triggerValidateChallengeResponse({
            main_district_id: String(currentBranch?.district_id),
            language_locale_id: languageLocaleId,
            personnel_id: discrepancyAuthFormValues?.personnel?.value,
            challenge_response: hashCode
          });

        if (validateChallengeResponse?.data[0]?.is_match_flag === Y_CHECKER) {
          dispatch(updatePreferencesAuthentication(true));
          dispatch(setShowAuthSection(false));
        } else {
          setIsAuthenticationError(true);
        }
      }
      preferencesAuthForm.setValues(discrepancyAuthFormValues);
      let updateData = {
        branch: discrepancyAuthFormValues?.branch,
        customer: discrepancyAuthFormValues?.customer,
        customerName: discrepancyAuthFormValues.customer.number,
        findButtonDisable: false,
        personnel_id: discrepancyAuthFormValues?.personnel?.value
      };
      dispatch(setDefaultBranchValues(updateData));
      dispatch(updateDiscrepancyAuthFormValues(updateData));
    } catch (error) {
      setIsAuthenticationError(true);
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      setLoading(false);
    }
  };

  const onCancelAuthCliked = () => dispatch(setShowAuthSection(false));

  const onBypassClick = () => {
    if (!discrepancyAuthFormValues.bypassMessage) {
      setIsBypassError(true);
      return;
    }
    setIsBypassError(false);
    dispatch(updatePreferencesAuthentication(true));
  };

  return (
    <>
      <Box sx={DetailsStyles.authCallerWrapper}>
        <AuthenticationCallerButton
          isAuthenticated={isAuthenticated}
          onAuthenticatedCallerCliked={onAuthenticatedCallerCliked}
        />
      </Box>

      {showAuthSection && (
        <>
          {/* Personnel Authentication Section */}
          {!isAuthenticated && (
            <AuditAuthForm
              allBranches={allBranches}
              handleBranchChange={handleBranchChange}
              pageFormDetails={preferencesAuthForm}
              valuePersonnelAuth={discrepancyAuthFormValues?.personnelAuthValue}
              handlePersonnelAuthChange={handlePersonnelAuthChange}
              authCustomers={allCustomers}
              handleCustomerChange={handleCustomerChange}
              handlePersonnelChange={handlePersonnelChange}
              onAuthenticateClick={onAuthenticateClick}
              isAuthenticationError={isAuthenticationError}
              isAuthenticated={isAuthenticated}
              programSecurityListArray={programSecurityListArray}
              authFormValues={discrepancyAuthFormValues}
              handleChange={handleAuthFieldsChange}
              allCustomers={allCustomers}
              onCancelAuthCliked={onCancelAuthCliked}
              disableBranch={false}
              onBypassClick={onBypassClick}
              isBypassError={isBypassError}
              showBypass={showBypass}
              handleByPassMessage={(values) =>
                dispatch(setBypassMesage(values))
              }
            />
          )}
        </>
      )}

      {isAuthenticated && (
        <>
          <SuccessAuthSection
            personalDetailForm={preferencesAuthForm}
            valuePersonnelAuth={discrepancyAuthFormValues?.personnelAuthValue}
            handlePersonnelOnChange={handlePersonnelAuthChange}
            disablePersonnel={true}
          />
          <Divider />
        </>
      )}
    </>
  );
};
export default AuthForm;
