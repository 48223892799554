export const NotificationStyles = {
  checkBoxStyle: {
    margin: "9px 0px "
  },
  mainDivStyles: {
    marginTop: "20px"
  },
  boxWidth: { width: "310px" },
  inputLabelProps: { shrink: true, style: { zIndex: 0 } },
  flex: { display: "flex" },
  btn: { textTransform: "none", margin: "10px" },
  boxStyle: { display: "flex", gap: "20px" },
  alertWidth: { minWidth: "600px" },
  actionColumnStyle: { textAlign: "left", width: "80px" },
  columnStyle: { minWidth: "250px" },
  selectedRowColor: "var(--color-light-orange)",
  filterOverlay: { width: "500px", left: "calc(50% - 250px)" }
};
