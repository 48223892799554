export const employeeDetailStyles = {
  errorStyle: {
    marginBottom: "20px"
  },
  phoneStyle: {
    width: "calc(70% - 20px)",
    marginRight: "20px"
  },
  extStyle: {
    width: "30%"
  },
  dividerStyle: {
    margin: "25px 0"
  },
  countTextStyle: {
    position: "absolute",
    right: "90px",
    fontWeight: "700",
    marginTop: "25px"
  },
  branchSectionstyle: {
    maxHeight: "300px",
    overflow: "auto"
  },
  hintTextStyle: {
    fontSize: "12px",
    color: "#00000099",
    marginTop: "10px"
  },
  iconColor: {
    color: "var(--color-im-grey-900)"
  }
};
