import { Stack } from "@mui/material";
import ViewDrMedia from "components/disaster-module/event-detail/view-dr-media/ViewDrMedia";
import { PageTitle } from "components/shared";
import i18n from "i18n";
import { useParams } from "react-router-dom";

const EventViewDrMedia = () => {
  const { drId, customerName, customerId } = useParams();
  return (
    <Stack spacing={2}>
      <PageTitle
        title={`${i18n.t(
          "disasterRecovery.viewDrMediaPage.titleP1"
        )} ${drId} ${i18n.t(
          "disasterRecovery.viewDrMediaPage.titleP2"
        )} ${customerName}`}
      />
      <ViewDrMedia drId={drId} customerId={customerId} />
    </Stack>
  );
};

export default EventViewDrMedia;
