/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  downloadReportOptions,
  ERROR_TYPES,
  errorMsgs,
  filterTypes,
  numberConstants,
  printReportTypes,
  reportFilterType,
  reportPrintType
} from "utils/constants";
import { eventStatus } from "utils/constants/disaster-module/EventSearchConstants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import { drPrintMediaStyles } from "../DisasterRecoveryStyles";

const DrEventPrintMedia = ({
  open,
  form,
  setIsLoading,
  statusId,
  setPrintModal,
  drId,
  sessonId,
  drRequestPageData
}) => {
  const { currentBranch, auth } = useSelector(selectAuth);
  const [customerResultSendText, setCustomerResultSendText] = useState(null);
  const [emailDef, setEmailDef] = useState(null);
  const [emailError, setEmailError] = useState({ error: false, message: "" });
  //error message showing states
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [downloadReport, setDownloadReport] = useState({
    open: false,
    reportUrl: null,
    requestBody: null
  });
  //warning message showing states
  const [throwWarning, setThrowWarning] = useState(false);
  const [throwWarningMessage, setThrowWarningMessage] = useState(null);

  //handle email change
  const handleEmailChange = (e) => {
    setEmailDef(e.target.value);
    form.handleChange({
      target: { name: "printEmail", value: e.target.value }
    });
    if (e.target.value.length > 155) {
      setEmailError((pre) => ({
        ...pre,
        error: true,
        message: t("disasterRecovery.viewDrMediaPage.emailLength")
      }));
    }
  };
  useEffect(() => {
    if (emailDef) {
      setEmailError({ error: false, message: "" });
    }
  }, [emailDef]);

  // selected download type
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);

  //show warning message according to error code without changing message
  const getMessageBycode = async (code) => {
    setIsLoading(true);
    const customerResultSend = await getMessageFromCode(String(code));
    setIsLoading(false);
    if (customerResultSend) {
      setCustomerResultSendText(customerResultSend[0]?.descr);
    }
  };

  //reset page
  const resetPage = () => {
    setEmailDef(null);
    setEmailError({ error: false, message: "" });
    setThrowError(false);
    setThrowErrorMessage(false);
    setThrowWarning(false);
    setThrowWarningMessage(null);
  };

  //handle close print modal
  const handleClosePrintModal = () => {
    setPrintModal(false);
    resetPage();
  };

  //handle print button click
  const handlePrintClick = () => {
    setDownloadReport((pre) => ({ ...pre, open: true }));
    handleClosePrintModal();
  };
  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        district_id: getAuthenticatedUserBranch(),
        dr_event_id: drRequestPageData?.dr_event_id,
        session_guid: sessonId,
        filter:
          form.values?.filter === filterTypes.all
            ? reportFilterType.all
            : form.values?.filter === filterTypes.notReturn
              ? reportFilterType.notReturn
              : reportFilterType.return,
        type:
          form.values?.reportType === printReportTypes.drMedia
            ? reportPrintType.drMedia
            : reportPrintType.reconciliation,
        district_name: currentBranch?.name,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.disasterRecovery.getViewDRMediaPrint}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //handle download print cancel
  const handleDownloadReportCancel = () => {
    setDownloadReport((pre) => ({ ...pre, open: false }));
    setThrowErrorMessage(null);
    setThrowError(false);
  };

  //handle send email
  const handleSendEmail = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        dr_event_id: drId,
        email_list: form.values.printEmail,
        session_guid: sessonId
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.sendEmail}`,
        1
      );
      if (data?.data) {
        const returnCode = data?.data[0][0].error;
        if (returnCode === numberConstants.zero) {
          handleClosePrintModal();
        } else if (returnCode === errorMsgs.errorCode20237) {
          const warningMsg = await getMessageFromCode(
            String(errorMsgs.errorCode20237)
          );
          setThrowWarning(true);
          setThrowWarningMessage(warningMsg[0]?.descr);
        }
      } else if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMessageBycode(errorMsgs.errorCode26170);
  }, []);

  return (
    <>
      {/* Download print report Popup */}
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={downloadReport.open}
        downloadOptions={downloadReportOptions}
        onCancleClick={handleDownloadReportCancel}
        onDownloadClick={handleDownload}
        setSelectedValue={selectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
      <Modal
        id="print-modal"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="print-modal-box1" sx={drPrintMediaStyles.printModalContainer}>
          {/*Print Modal title*/}
          <Typography
            id="print-modal-title"
            variant="h6"
            sx={drPrintMediaStyles.printModalTitle}
          >
            {t("disasterRecovery.viewDrMediaPage.printDrMedia")}
          </Typography>

          <Stack
            id="print-modal-stack"
            direction="column"
            spacing={spacing.horizontalMargin20}
            sx={drPrintMediaStyles.printModalBody}
          >
            {throwError && (
              <Collapse in={throwError}>
                {/*Error alert box*/}
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}
            {throwWarning && (
              <Collapse in={throwWarning}>
                {/*Warinng alert box*/}
                <Alert
                  severity="warning"
                  icon={<img src={WarningIcon} alt="warning" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowWarning(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography sx={drPrintMediaStyles.alertIcon}>
                    {throwWarningMessage}
                  </Typography>
                </Alert>
              </Collapse>
            )}
            {/*Print modal subtitle*/}
            <Grid item>
              <Typography>{t("common.report")}</Typography>
            </Grid>

            <Grid id="radio-grid-print-modal" item>
              {/*Print modal radio buttons*/}
              <FormControl id="radio-control">
                <RadioGroup
                  id="radio-group-print-modal"
                  name="reportType"
                  row
                  value={form?.values?.reportType}
                  onChange={form?.handleChange}
                >
                  <FormControlLabel
                    id="radio-form-control-1"
                    value={printReportTypes.drMedia}
                    control={<Radio />}
                    label={t("disasterRecovery.viewDrMediaPage.drMedieReport")}
                  />
                  <FormControlLabel
                    id="radio-form-control-2"
                    value={printReportTypes.reconciliation}
                    control={<Radio />}
                    label={t(
                      "disasterRecovery.viewDrMediaPage.reconciliationResultsReport"
                    )}
                    disabled={
                      !(
                        statusId === eventStatus.reconciled ||
                        statusId === eventStatus.complete
                      )
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/*Customer result send title*/}
            <Grid item>
              <Typography>
                {t("disasterRecovery.viewDrMediaPage.customerResultsSend")}
              </Typography>
            </Grid>
            {/*Customer result send text*/}
            <Grid item>
              <Typography sx={drPrintMediaStyles.customerResultText}>
                {customerResultSendText}
              </Typography>
            </Grid>
            {/*Print modal email field*/}
            <Grid
              id="print-modal-email"
              item
              sx={drPrintMediaStyles.printModalBranchField}
            >
              <TextField
                id="print-modal-email"
                name="printEmail"
                label={t("disasterRecovery.viewDrMediaPage.emailAddress")}
                fullWidth
                disabled={
                  form.values.reportType !== printReportTypes.reconciliation
                }
                value={emailDef}
                onChange={(e) => handleEmailChange(e)}
                error={emailError.error}
                helperText={emailError.message}
                required
              />
            </Grid>
          </Stack>

          <Stack
            id="print-buttons"
            direction="row"
            spacing={spacing.horizontalMargin20}
            sx={drPrintMediaStyles.printModalButton}
          >
            {/* Close button */}
            <Button
              id="print-close-btn"
              variant="outlined"
              size="medium"
              type="button"
              onClick={handleClosePrintModal}
            >
              {t("enterpriseBillingSummary.close")}
            </Button>
            {/* Send button */}
            <Button
              id="send-btn"
              variant="contained"
              size="medium"
              type="submit"
              disabled={!form?.values?.printEmail}
              onClick={handleSendEmail}
            >
              {t("common.send")}
            </Button>
            {/* Download button */}
            <Button
              id="print-btn"
              variant="contained"
              size="medium"
              type="submit"
              onClick={handlePrintClick}
            >
              {t("common.print")}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default DrEventPrintMedia;
