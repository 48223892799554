import SendIcon from "@mui/icons-material/Send";
import {
  Alert,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";

import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import dayjs from "dayjs";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { getFeedbackRecipient, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import { setLastFeedBack } from "store/slices/dashboard";
import {
  dateFormatWithTime,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  errorMsgs,
  FEEDBACKRECEIPTID
} from "utils/constants";

import SuccessPopup from "components/core/success-popup/SuccessPopup";
import { systemRequests } from "utils/constants/dahboard/SystemRequest";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getResponseData,
  sendEmail
} from "utils/helpers";
import SadFaceSvg from "../../../assets/images/system-request/SadFaceEmoji.svg";
import SmileyFaceSvg from "../../../assets/images/system-request/SlightlySmilingFaceEmoji.svg";
import SmilingFaceSvg from "../../../assets/images/system-request/SmilingFaceEmoji.svg";
import VerySadFaceSvg from "../../../assets/images/system-request/VerySadEmoji.svg";
import { SystemRequestStyles } from "./SystemRequest.style";

const SystemRequests = () => {
  const [selectedCard, setSelectedCard] = useState(systemRequests.request);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const { auth, localeByBranch } = useSelector(selectAuth);
  const [mailSuccess, setMailSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const stateStoreData = useSelector((state) => state.dashboardRequest);
  const [successInsertMessage, setSuccessInsertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const cardData = [
    { name: t("dashboard.request"), img: SmileyFaceSvg },
    { name: t("dashboard.complaint"), img: VerySadFaceSvg },
    { name: t("dashboard.compliment"), img: SmilingFaceSvg },
    { name: t("dashboard.urgent"), img: SadFaceSvg }
  ];

  const handleCardClick = (name, index) => {
    setSelectedCard(name);
    setSelectedCardIndex(index); // Update selectedCard to the clicked card's name
  };
  const handleMessageChange = (e) => {
    const val = e.target.value;
    setMessage(val);
    setThrowError(false);
  };
  // sp Name: email_group_l2_sp
  const sendEmailGroup = async () => {
    try {
      setIsLoading(true);
      let res = await getFeedbackRecipient(FEEDBACKRECEIPTID);
      const date = dayjs
        .utc()
        .locale(localeByBranch)
        .format(dateFormatWithTime);
      const empolyeeName = auth?.user?.first_name + " " + auth?.user?.last_name;
      const comments = message;
      let mailSubject = `${t("dashboard.emailSubject")}`;
      let emailAddress = [
        {
          emailAddress: res[0]?.address,
          name: ""
        }
      ];
      let mailBody = `${mailSubject} ${selectedCard} ${t(
        "dashboard.from"
      )} ${empolyeeName}  ${t("dashboard.on")} ${date}\n\n\n${comments}`;

      const response = await sendEmail(emailAddress, mailBody, mailSubject);

      if (response?.data?.isSuccess) {
        setMailSuccess(response?.data?.isSuccess);
        let errorMsg = await getMessageFromCode(errorMsgs.errorCode10227);
        let replacedText = errorMsg[0]?.descr.replace(
          "|",
          t("dashboard.systemRequest")
        );
        setSuccessInsertMessage(replacedText);
        setMessage("");
      } else {
        let errorMsg = await getMessageFromCode(errorMsgs.errorCode20237);
        setThrowErrorMessage(errorMsg[0]?.descr);
        setThrowError(true);
      }
      setIsLoading(false);
    } catch {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // sp Name: sb_fs_feedback_i_sp
  const formatFeedbackText = (text) => {
    return text
      .replace(/'/g, String.fromCharCode(96))
      .replace(/\t/g, "        "); // Replace tabs with 8 spaces
  };

  const today = dayjs();
  const dateFormate = dateTimeFormatByLocale(today, localeByBranch);
  const formatedDate = dateFormate.split(" ")[0];

  const postFeedBack = async (comments) => {
    let reqBodyString = {
      main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
      feedback_type: selectedCardIndex,
      user_id: auth?.user?.id,
      feedback_datetime: formatedDate,
      comment: formatFeedbackText(comments),
      program_id: "",
      last_mod_user: `${auth?.user?.first_name} ${auth?.user?.last_name}`
    };
    try {
      setIsLoading(true);
      let res = await getResponseData(
        reqBodyString,
        CF_URLS.dashboard.insertNewFeedBack,
        1
      );
      if (res) {
        if (res?.data[0][0]?.feedback_id.length > 0) {
          await sendEmailGroup();
        } else {
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode20532);
          setThrowErrorMessage(errorMsg[0]?.descr);
          setThrowError(true);
        }
      }

      setIsLoading(false);
    } catch {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSendClick = async () => {
    if (!message) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode10091);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("dashboard.systemRequest")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
    } else if (message === stateStoreData?.lastFeedBackText) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode10092);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        t("dashboard.systemRequest")
      );
      setThrowErrorMessage(replacedText);
      setThrowError(true);
    } else {
      await postFeedBack(message);
    }

    dispatch(setLastFeedBack(message));
  };

  const feedBackData = () => {
    switch (selectedCard) {
      case systemRequests.request:
        return t("dashboard.somethingMissing");
      case systemRequests.complaint:
        return t("dashboard.sorryIdontLike");
      case systemRequests.compliment:
        return t("dashboard.GoodStuffIreallyLike");
      case systemRequests.urgent:
        return t("dashboard.HeyWeveGotAProblem");
      default:
        return t("dashboard.somethingMissing");
    }
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}

      {mailSuccess && (
        <SuccessPopup message={successInsertMessage} close={setMailSuccess} />
      )}
      <Grid container spacing={2}>
        <Grid container spacing={1} justifyContent="center">
          <div style={SystemRequestStyles.headerDivStyles}>
            <Typography sx={SystemRequestStyles.fontStyles}>
              {t("dashboard.systemRequests")}
            </Typography>
          </div>
        </Grid>

        <Grid container spacing={1} justifyContent="center">
          {cardData.map((d, index) => (
            <Grid item key={index} style={SystemRequestStyles.cardGridStyles}>
              <Card
                onClick={() => handleCardClick(d.name, index + 1)}
                style={
                  selectedCard === d.name
                    ? SystemRequestStyles.cardStylesSelected
                    : SystemRequestStyles.cardStylesNotSelected
                }
              >
                <CardMedia
                  component="img"
                  alt={d.name}
                  height="60"
                  image={d.img}
                  style={SystemRequestStyles.cardMediaStyles}
                />
                <CardContent style={SystemRequestStyles.cardContentStyles}>
                  <Typography
                    variant="caption"
                    component="div"
                    noWrap
                    style={SystemRequestStyles.cardTypographyStyles}
                  >
                    {d.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Grid container item xs={12} md={12}>
          <Typography variant="h5">{feedBackData()}</Typography>
        </Grid>

        <Grid item xs={12} md={10}>
          <TextField
            label={t("dashboard.message")}
            id="message"
            value={message}
            onChange={handleMessageChange}
            fullWidth
            multiline
            rows={15}
          />
        </Grid>

        <Grid item xs={12} md={2} style={SystemRequestStyles?.sendButtonGrid}>
          <IconButton color="primary" size="medium" onClick={handleSendClick}>
            <SendIcon variant="contained" fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default SystemRequests;
