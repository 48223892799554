/* Yup validation schemas goes here */
import i18n from "i18n";
import * as yup from "yup";

/* Capacity Billing Generation Search schema */
export const capacityBillingGenerationSchema = yup.object().shape({
  generationAddNew: yup
    .string()
    .required(i18n.t("generationSearchDetail.generationRequired")),
  conversionFactor: yup
    .string()
    .required(i18n.t("generationSearchDetail.conversionFactor"))
});
