import CloseIcon from "@mui/icons-material/Close";
import { Alert, Collapse, IconButton, Stack } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { CircularLoaderFullPage } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { downloadReportOptions, ERROR_TYPES } from "utils/constants";
import {
  operationalModeId,
  printPdfLabel,
  severityError
} from "utils/constants/discrepancy-management/DiscrepancyUpdate";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";
import AuthForm from "./AuthForm";
import DiscrepancyUpdate from "./DiscrepancyUpdate";
import { DetailsStyles } from "./Styles";

const DiscrepancyUpdateLayout = () => {
  const { showAuthSection, isAuthenticated } = useSelector(
    (state) => state.discrepancyUpdate
  );
  const [isLoading, setIsLoading] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [severity] = useState(severityError);
  const [printData, setPrintData] = useState(null);
  const { currentBranch, auth } = useSelector(selectAuth);
  useEffect(() => {
    if (throwErrorMessage) {
      throwErrorMessage && window.scrollTo(0, 0);
    }

    //eslint-disable-next-line
  }, [throwErrorMessage]);

  const handlePrint = async () => {
    try {
      setIsLoading(true);
      const requestBody = JSON.stringify({
        main_district_id: currentBranch?.value,
        operational_mode_id: operationalModeId,
        user_name: getUserName(auth?.user),
        report_format: downloadReportOptions[0]?.type?.toUpperCase(),
        ...printData,
        ...printPdfLabel
      });
      const url = CF_URLS.printReports.discrepancy.additionalinformationreport;
      let reportResponse = await printReport(requestBody, url);
      if (!reportResponse?.success) {
        setThrowErrorMessage(reportResponse?.error);
      }
      setShowPrintModal(false);
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
      setShowPrintModal(false);
    }
  };
  return (
    <Stack gap={spacing.gap}>
      <CircularLoaderFullPage loading={isLoading} />
      <AuthForm setLoading={setIsLoading} />
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={showPrintModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => {
          setPrintData(null);
          setShowPrintModal(false);
        }}
        onDownloadClick={() => {
          handlePrint();
        }}
      />
      {throwErrorMessage && (
        <Collapse in={!!throwErrorMessage}>
          <Alert
            severity={severity}
            sx={DetailsStyles.errorStyle}
            icon={
              <img
                src={severity === severityError ? ErrorIcon : WarningIcon}
                alt="error"
                style={DetailsStyles.iconStyle}
              />
            }
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}

      {(!showAuthSection || isAuthenticated) && (
        <DiscrepancyUpdate
          setIsLoading={setIsLoading}
          setThrowErrorMessage={setThrowErrorMessage}
          isLoading={isLoading}
          handlePrint={(value) => {
            setPrintData(value);
            setShowPrintModal(true);
          }}
        />
      )}
    </Stack>
  );
};
export default DiscrepancyUpdateLayout;
