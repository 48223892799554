import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import BranchField from "components/core/branch-field/BranchField";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateNotificationConfigFormValues } from "store/slices/administration/notificationConfig";
import spacing from "styles/spacing";
import { VALUE_EMPTY_STRING } from "utils/constants";
import { NotificationStyles } from "./NotificationStyles";
const NotificationModal = ({
  isCreating,
  handleCancelClick,
  handleAddClick,
  filteredEmployeeOptions,
  setFilteredEmployeeOptions
}) => {
  const { t } = useTranslation();
  const {
    notificationConfigFormValues,
    notificationAuthFormValues,
    branchOptions,
    employeeOptions,
    actionTypes
  } = useSelector((state) => state.notificationConfig);
  const dispatch = useDispatch();

  return (
    <>
      <CommonModal
        open={isCreating}
        title={t("notifications.addEmployee")}
        body={
          <>
            {
              <>
                <Grid container spacing={spacing.gap}>
                  <Grid item xs={6}>
                    <BranchField
                      options={branchOptions}
                      disabled={!notificationConfigFormValues?.notification}
                      onChange={(e, value) => {
                        const updatedValues = {
                          branch: e.target.value,
                          employee: VALUE_EMPTY_STRING,
                          action: VALUE_EMPTY_STRING
                        };

                        dispatch(
                          updateNotificationConfigFormValues(updatedValues)
                        );

                        const filteredEmps =
                          employeeOptions &&
                          employeeOptions?.filter(
                            (action) => action.district_id === e.target.value
                          );
                        setFilteredEmployeeOptions(filteredEmps);
                      }}
                      value={
                        notificationAuthFormValues?.branch
                          ? notificationAuthFormValues?.branch
                          : notificationConfigFormValues?.branch?.value
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      componentsProps={{
                        clearIndicator: ArrowDown
                      }}
                      popupIcon={<ArrowDown />}
                      clearIcon={null}
                      id="employee-types"
                      name={t("notifications.employee")}
                      disabled={!notificationConfigFormValues?.branch}
                      options={filteredEmployeeOptions}
                      size="medium"
                      value={notificationConfigFormValues?.employee}
                      onChange={(e, value) => {
                        const updatedValues = {
                          employee: value,
                          action: VALUE_EMPTY_STRING
                        };

                        dispatch(
                          updateNotificationConfigFormValues(updatedValues)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("notifications.selectEmployee")}
                          required
                        />
                      )}
                      sx={NotificationStyles.fieldStyle}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={spacing.gap}>
                  <Grid
                    id="selectAction"
                    item
                    xs={6}
                    marginTop={spacing.margingTop20}
                  >
                    <Autocomplete
                      componentsProps={{
                        clearIndicator: ArrowDown
                      }}
                      popupIcon={<ArrowDown />}
                      clearIcon={null}
                      id="selectAction"
                      name={t("notifications.selectAction")}
                      disabled={!notificationConfigFormValues?.employee}
                      options={actionTypes}
                      size="medium"
                      value={notificationConfigFormValues?.action}
                      onChange={(e, value) => {
                        const updatedValues = {
                          action: value
                        };
                        dispatch(
                          updateNotificationConfigFormValues(updatedValues)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("notifications.selectAction")}
                          required
                        />
                      )}
                      sx={NotificationStyles.fieldStyle}
                    />
                  </Grid>
                </Grid>
              </>
            }
          </>
        }
        buttons={
          <>
            {
              <>
                <Button
                  id="saveBtn-modal"
                  variant="outlined"
                  disabled={null}
                  onClick={handleCancelClick}
                  sx={null}
                >
                  {t("common.cancel")}
                </Button>

                <Button
                  id="addBtn-modal"
                  variant="contained"
                  onClick={handleAddClick}
                  sx={null}
                  disabled={!notificationConfigFormValues?.action}
                >
                  {t("common.add")}
                </Button>
              </>
            }
          </>
        }
      />
    </>
  );
};
export default NotificationModal;
