import { ReactComponent as BriefCase } from "assets/images/BriefCase.svg";
import { ReactComponent as Calendar } from "assets/images/Calendar.svg";
import { ReactComponent as Clock } from "assets/images/Clock.svg";
import { ReactComponent as DoubleUser } from "assets/images/DoubleUser.svg";
import { ReactComponent as Notes } from "assets/images/Notes.svg";
import { ReactComponent as Truck } from "assets/images/Truck.svg";
import { ReactComponent as User } from "assets/images/User.svg";
import { nodeTypes } from "utils/constants/transportation/RunManagementConstants";

export const TreeIcon = ({ type }) => {
  switch (type) {
    case nodeTypes.date:
      return <Calendar />;
    case nodeTypes.run:
      return <Truck />;
    case nodeTypes.time:
      return <Clock />;
    case nodeTypes.parentCustomer:
      return <DoubleUser />;
    case nodeTypes.customer:
      return <User />;
    case nodeTypes.request:
      return <Notes />;
    case nodeTypes.container:
      return <BriefCase />;
    default:
      return null;
  }
};
