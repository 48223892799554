import { ThemeProvider, useTheme } from "@emotion/react";
import { Typography, createTheme } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  NO,
  YES,
  Y_CHECKER
} from "utils/constants";
import { GroupEmployeeStyles } from "./GroupEmployeeStyles";
const GroupEmployeeTable = ({ searchResults, isResetGrid }) => {
  const globalTheme = useTheme();
  const dataTableTheme = createTheme(globalTheme, {
    components: GroupEmployeeStyles.dataTableStyles
  });
  //Table columns
  const columns = [
    {
      name: "employee_name",
      label: t("securityGroupEmployee.employeeCol")
    },
    {
      name: "active_flag",
      label: t("securityGroupEmployee.activeCol"),
      options: {
        customBodyRender: (value) => (value === Y_CHECKER ? YES : NO)
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  return (
    <ThemeProvider theme={dataTableTheme}>
      <MUIDataTable
        columns={columns}
        options={options}
        data={searchResults}
        title={
          !isResetGrid && (
            <Typography style={GroupEmployeeStyles.titleStyle}>
              {searchResults?.length} {t("common.recordsFound")}
            </Typography>
          )
        }
      />
    </ThemeProvider>
  );
};

export default GroupEmployeeTable;
