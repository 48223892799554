import spacing from "styles/spacing";

const cardStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  width: "550px !important"
};

const popupTitle = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "32px",
  letterSpacing: "0.15px",
  color: "#1E417A",
  display: "flex",
  padding: "16px 16px",
  alignItems: "center",
  alignSelf: "stretch",
  width: "552px"
};

const cardContentStyles = {
  padding: "16px 16px"
};

const cardActionStyles = {
  padding: "16px 16px"
};

const modalButton = {
  padding: "16px 16px",
  marginRight: "auto"
};

const paddingTitleStyle = { padding: "16px 16px 16px 16px" };

const alertMessage = {
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "166%" /* 19.92px */,
  letterSpacing: "0.4px",
  color: "var(--color-im-grey-900)"
};
const titleStyle = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "160%",
  letterSpacing: "0.15px",
  color: "var(--color-im-dark-blue)"
};
const dialogModal = {
  width: "500px",
  maxWidth: "100%"
};
const alertBox = {
  marginBottom: spacing.verticalMargin20
};
const alertTextStyle = {
  paddingLeft: "20px"
};
export const popupStyles = {
  cardStyle,
  popupTitle,
  cardContentStyles,
  cardActionStyles,
  paddingTitleStyle,
  alertMessage,
  modalButton,
  titleStyle,
  dialogModal,
  alertBox,
  alertTextStyle
};
