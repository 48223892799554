import spacing from "styles/spacing";
const fieldSize = {
  width: "310px",
  height: "56px"
};
const alertBox = {
  marginBottom: spacing.horizontalMargin20
};
const dataTableStyles = {
  MUIDataTableBodyCell: {
    styleOverrides: {
      root: {
        height: "42px",
        lineHeight: "30px",
        padding: "0 !important"
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        height: "52px"
      }
    }
  },
  MUIDataTableHeadCell: {
    styleOverrides: {
      root: {
        height: "56px",
        padding: "16px 24px !important"
      }
    }
  }
};
const titleBox = {
  padding: "16px 0px"
};
const titleStyle = {
  fontSize: "24px",
  fontWeight: 400,
  lineHeight: "32px",
  textAlign: "left"
};

const headerText = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(0, 0, 0, 0.6)"
};

const cellStylesGray = {
  background: "var(--color-im-grey-50)",
  height: "42px",
  padding: "0px 24px !important",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px"
};

const cellStylesBlue = {
  background: "none",
  height: "42px",
  padding: "0px 24px !important",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px"
};

export const GroupManagementStyles = {
  fieldSize,
  alertBox,
  titleStyle,
  cellStylesBlue,
  cellStylesGray,
  dataTableStyles,
  headerText,
  titleBox
};
