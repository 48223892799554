/* Yup validation schemas goes here */

import i18n from "i18n";
import * as yup from "yup";

export const employeeSearchFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(30, i18n.t("employeeSearch.firstNameLengthError")),
  lastName: yup.string().max(30, i18n.t("employeeSearch.LastNameLengthError"))
});
