// Constants that are used in MDR pickup

export const RequestStatusIds = {
  None: 0,
  PendingConfirmation: 1,
  Rejected: 2,
  Authorized: 3,
  Scheduled: 4,
  Cancelled: 5,
  InProcess: 6,
  Completed: 7,
  PendingReview: 8
};
export const OtherRequestStatus = {
  Scheduled: 1,
  NeedsAuthorization: 2,
  Canceled: 3,
  Complete: 4,
  NeedsScheduling: 5,
  FutureScheduling: 8,
  Deleted: 9
};
export const CommentType = {
  SpecialHandling: 1,
  General: 2,
  Pickup: 3
};

export const ProgramId = {
  PickupCreateEmployee: 295, //New Button and Create/Save request
  PickupCreatePersonnel: 344, //Create Personnel
  PickupCancelEmployee: 291, //Cancel Button
  PickupCancelPersonnel: 339, //Cancel process post auth
  PickupScheduleEmployee: 343, //Schedule section
  ReviewSection: 340, //Review section
  ReadytoBill: 340 //Ready to bill button
};
export const SecureSyncSysytemId = 69;

export const BypassId = 98;

// constants used for filtering the values
export const TrackingRequirementIds = {
  VaultItemized: 1,
  PickupBulk: 2,
  Delivery: 3,
  PickupItemized: 4,
  PickupScanonSite: 5,
  PickupInteractiveSiteScan: 6
};

export const schedulePickupInstructionsCommentTypeId = "3";

export const ReviewCommentTypeId = "2";

export const DispositionServiceCommentTypeId = "1";

// error message code for insufficient auth and customer on hold
export const InSufficientAuthorizationMessageId = "64097";

export const CustomerOnHoldMessageId = "63992";

export const maxFileLength = 2500;

export const maxLengthMessageId = "63529";

export const replaceValueFromMessageDescr = "| |";

export const TXT_CSV_MESSAGEID = "80350";

export const EmptyContentMessageId = "26069";

export const preparedForRunMessagId = "80398";

export const regexToCheckAlphaNumeric = /^[a-zA-Z0-9]*$/;

export const DateFormat = "DD/MM/YYYY";

export const dateFormatWithHoursAndMin = "DD/MM/YYYY HH:mm";

export const WEIGHT_MIN_MAX_INPUTPROPS = {
  min: 0,
  max: 999999999
};
