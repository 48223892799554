import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseFilledIcon from "assets/images/CloseFilled.svg";
import RemoveRedEyeFilledIcon from "assets/images/RemoveRedEyeFilled.svg";
import { CircularLoader } from "components/core";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import {
  useGetMessageByIdFromMessageMutation,
  useLoadMediaRequestContainersMutation,
  useLoadMediaRequestOpenMediasMutation,
  useValidateDeliverMediaContainerNumberMutation,
  useValidateDeliverMediaOpenMediaNumberMutation
} from "services/api";
import { getMessageByIdFromMessage } from "services/common";
import {
  addContainer,
  addOpenMedia,
  addOpenMediaSelectedFromPossibleMatches,
  addOpenMedias,
  selectMediaRequest,
  setContainers,
  setErrorMessage,
  setOpenMedias
} from "store/slices";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DUPLICATES_TXT,
  POSSIBLE_MATCHES_TXT,
  REQUEST_ACTIONS,
  REQUEST_STATUS,
  Y_CHECKER
} from "utils/constants";
import {
  COTAINER_EXHCANGE_STATUS_TYPE,
  MESSAGE_26022,
  MESSAGE_64028,
  MESSAGE_64032,
  MESSAGE_80105
} from "utils/constants/request-module/MediaRequestDetailConstants";
import {
  limitFormikTextFieldLength,
  validateUserHasProgramId
} from "utils/helpers";
import { MediaRequestDetailFormStyles } from "../media-request-detail-form/MediaRequestDetailForm.Styles";
import { DeliverMediaStyles } from "./DeliverMediaTab.Styles";
import ImportUnsupportedMediaFileModal from "./ImportUnsupportedMediaFileModal";
import OpenMediaPossibleMatchesModal from "./OpenMediaPossibleMatchesModal";
import { openMediaValidationSchema } from "./schema";

// Open media grid column definition view-only
const viewOpenMediasColumnsDefinition = [
  {
    name: "volser",
    label: i18n.t("mediaRequestDetail.mediaCaps")
  },
  {
    name: "descr",
    label: i18n.t("mediaRequestDetail.statusCaps")
  }
];

// Containers grid column definition view-only
const viewContainersColumnsDefinition = [
  {
    name: "container_number",
    label: i18n.t("mediaRequestDetail.containerNumberCaps")
  },
  {
    name: "status",
    label: i18n.t("mediaRequestDetail.statusCaps")
  }
];

const DeliverMediaTab = (props) => {
  const [duplicateAlert, setDuplicateAlert] = useState({
    show: false,
    title: "",
    message: ""
  });
  const [destroyedMediasAlert, setDestroyedMediasAlert] = useState({
    show: false,
    title: "",
    message: ""
  });
  const [fileImporting, setFileImporting] = useState(false);
  const [containerMessage, setContainerMessage] = useState({
    show: false,
    title: "",
    message: ""
  });
  const { t } = useTranslation();
  const { refetch, actionId, deliverMedia } = useSelector(selectMediaRequest); // select media request slice
  const dispatch = useDispatch(); // redux actions dispatcher
  const [message26022, setMessage26022] = useState("");
  const [disableOpenMedia, setDisableOpenMedia] = useState(false);
  const [containerRunId, setContainerRunId] = useState(0);

  // Open media grid column definition
  const openMediasColumnsDefinition = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() => {
              dispatch(
                setOpenMedias(
                  deliverMedia.openMedias.filter(
                    (_, index) => index !== tableMeta.rowIndex
                  )
                )
              );
            }}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "volser",
      label: t("mediaRequestDetail.mediaCaps")
    },
    {
      name: "status",
      label: t("mediaRequestDetail.statusCaps")
    },
    {
      name: "follow_up",
      label: t("mediaRequestDetail.followUpCaps"),
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <>
            {value}

            {/* have possible matches or duplicates */}
            {value !== "" &&
              (value?.trim() === POSSIBLE_MATCHES_TXT ||
                value?.trim() === DUPLICATES_TXT) && (
                <IconButton
                  id={`btnPossibleMatches${tableMeta.rowIndex}`}
                  aria-label="possibleMatches"
                  sx={{
                    marginLeft: "20px !important",
                    ...MediaRequestDetailFormStyles.TableIconButton
                  }}
                  onClick={() => {
                    setPossibleMatchesOrDuplicates((prevState) =>
                      value?.trim()
                    );
                    setPossibleMatchesModalVisible((prevState) => true);
                    setSelectedMedia(
                      (prevState) =>
                        deliverMedia.openMedias[tableMeta.rowIndex]?.volser
                    );
                  }}
                >
                  <img src={RemoveRedEyeFilledIcon} alt="eye icon" />
                </IconButton>
              )}
          </>
        )
      }
    }
  ];

  // Containers grid column definition
  const containersColumnsDefinition = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() => {
              dispatch(
                setContainers(
                  deliverMedia.containers.filter(
                    (_, index) => index !== tableMeta.rowIndex
                  )
                )
              );
            }}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "container_number",
      label: i18n.t("mediaRequestDetail.containerNumberCaps")
    },
    {
      name: "status",
      label: i18n.t("mediaRequestDetail.statusCaps")
    }
  ];

  const [
    loadOpenMedias,
    {
      isSuccess: openMediaFound,
      isLoading: openMediaLoading,
      reset: resetOpenMedias
    }
  ] = useLoadMediaRequestOpenMediasMutation(); // api call for fetching open media list of an existing request
  const [
    loadContainers,
    {
      isSuccess: containersFound,
      isLoading: containersLoading,
      reset: resetContainers
    }
  ] = useLoadMediaRequestContainersMutation(); // api call for fetching container list of an existing request
  const [getMessageById] = useGetMessageByIdFromMessageMutation();

  useEffect(() => {
    getMessageById({ message_id: MESSAGE_26022 })
      .unwrap()
      .then((resp) => setMessage26022(resp[0]?.descr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // disable open media if the redelivery checkbox is checked
  useEffect(() => {
    setDisableOpenMedia((prevState) => Boolean(props.isRedelivery));
  }, [props.isRedelivery]);

  // Add open media
  const openMediaFileImportRef = useRef(null);
  const [selectedMedia, setSelectedMedia] = useState("");
  const [possibleMatchesModalVisible, setPossibleMatchesModalVisible] =
    useState(false); // show modal for open medias
  const [possibleMatchesOrDuplicates, setPossibleMatchesOrDuplicates] =
    useState(POSSIBLE_MATCHES_TXT); // possible matches or duplicates should be visible in the modal

  const [
    unsupportedMediaFileModalVisible,
    setUnsupportedMediaFileModalVisible
  ] = useState(false);
  const [validateOpenMediaNumber, { isLoading: validatingOpenMedia }] =
    useValidateDeliverMediaOpenMediaNumberMutation(); // validate open media number api

  // Add open medias
  const openMediaForm = useFormik({
    initialValues: { volser: "" },
    validationSchema: openMediaValidationSchema,
    onSubmit: (values, _) => {
      if (openMediaForm.isValid && !validatingOpenMedia) {
        if (
          deliverMedia.openMedias.findIndex(
            (media) => media.volser.trim() === values.volser.trim()
          ) < 0
        ) {
          validateOpenMediaNumber({
            customerId: props.customerId,
            volserList: values.volser.padEnd(20, " ")
          })
            .unwrap()
            .then(async (resp) => {
              if (resp?.error) {
                openMediaForm.setFieldError(
                  t("mediaRequestDetail.volser"),
                  resp.error
                );
                setDuplicateAlert((prevState) => ({
                  show: false,
                  title: "",
                  message: ""
                }));
                setDestroyedMediasAlert((prevState) => ({
                  show: false,
                  title: "",
                  message: ""
                }));
              } else if (resp?.volsersInMdr) {
                const volsersInMdr = String(resp.volsersInMdr);
                // if media were requested that are on an MDR or in a destroyed status
                // return a comma-delimited list of the media numbers
                setDuplicateAlert((prevState) => ({
                  show: false,
                  title: "",
                  message: ""
                }));
                setDestroyedMediasAlert((prevState) => ({
                  show: false,
                  title: "",
                  message: ""
                }));
                openMediaForm.resetForm();
                if (volsersInMdr.length > 0) {
                  const message64028 = await getMessageByIdFromMessage({
                    message_id: MESSAGE_64028
                  });
                  await showDestroyedMediaMessage(
                    message64028[0]?.descr,
                    volsersInMdr
                  );
                }
              } else {
                openMediaForm.resetForm();
                setDuplicateAlert((prevState) => ({
                  show: false,
                  title: "",
                  message: ""
                }));
                setDestroyedMediasAlert((prevState) => ({
                  show: false,
                  title: "",
                  message: ""
                }));
                dispatch(addOpenMedia(resp.medias[0]));
              }
            });
        } else {
          openMediaForm.setFieldError(
            t("mediaRequestDetail.volser"),
            t("mediaRequestDetail.mediaAlreadyExists")
          );
        }
      }
    }
  });

  // Add containers
  const [
    validateDeliverContainerNumber,
    { isLoading: validatingContainerNumber }
  ] = useValidateDeliverMediaContainerNumberMutation(); // validate container number api
  const containersForm = useFormik({
    initialValues: { container_id: "" },
    onSubmit: (values, _) => {
      if (containersForm.isValid && !validatingContainerNumber) {
        if (
          deliverMedia.containers.findIndex(
            (cont) =>
              cont.container_number.trim() === values.container_id.trim()
          ) < 0
        ) {
          validateDeliverContainerNumber({
            customerId: props.customerId,
            newRequestTypeId: props.requestType,
            containerNumber: values.container_id,
            redeliveryFlag: props.isRedelivery
          })
            .unwrap()
            .then((resp) => {
              if (resp?.error) {
                containersForm.setFieldError("container_id", resp?.error);
              } else {
                if (props.isRedelivery === Y_CHECKER) {
                  switch (resp?.status) {
                    //We only want Containers with an exchange status of pickup
                    case COTAINER_EXHCANGE_STATUS_TYPE.PICKUP:
                      if (deliverMedia.containers.length === 0) {
                        setContainerRunId((prevState) =>
                          Number(resp?.run_id || 0)
                        );
                      }

                      //All Containers in an exchange status of pickup must be on the same run
                      if (containerRunId !== Number(resp?.run_id || 0)) {
                        getMessageByIdFromMessage({
                          message_id: MESSAGE_80105
                        }).then((resp) => {
                          setContainerMessage((prevState) => ({
                            show: true,
                            message: resp,
                            title: ""
                          }));
                        });
                      }
                      break;
                    default:
                      //We dont want Containers in any other status
                      if (
                        resp?.status !== COTAINER_EXHCANGE_STATUS_TYPE.PICKUP
                      ) {
                        containersForm.setFieldError(
                          "container_id",
                          `${i18n.t("mediaRequestDetail.incorrectStatus")} ${
                            resp?.status
                          }`
                        );
                      }
                      break;
                  }
                }
                containersForm.resetForm();
                dispatch(addContainer(resp));
              }
            });
        } else {
          containersForm.setFieldError(
            "container_id",
            t("mediaRequestDetail.containerAlreadyExists")
          );
        }
      }
    }
  });

  const dataTableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "vertical",
    tableBodyHeight: "230px",
    tableBodyMaxHeight: "230px",
    setRowProps: (row) => ({
      style: {
        height: "32px"
      }
    }),
    textLabels: {
      body: {
        noMatch:
          openMediaLoading || containersLoading ? (
            <CircularLoader loading={openMediaLoading || containersLoading} />
          ) : (
            t("mediaRequestDetail.noItemsToDisplay")
          )
      }
    }
  };

  // read file content and validate open medias
  const processFileContent = (content) => {
    const lines = content.split("\n");
    const newData = [];
    const invalids = [];
    const duplicates = [];
    let volserList = "";

    lines.forEach((line) => {
      const trimmedLine = line?.trim();
      const volser = trimmedLine;
      if (trimmedLine.length <= 20) {
        // Check if volser is already in the table
        const isMediaNumberInTable = deliverMedia.openMedias?.some(
          (item) => item.volser.trim() === volser
        );

        // Check if volser is already in the file
        const isNewDataContainsMediaNumber = newData?.some(
          (item) => item.trim() === volser
        );

        //Except Empty Values
        if (volser !== "") {
          newData.push(volser);
          if (isNewDataContainsMediaNumber || isMediaNumberInTable) {
            if (duplicates.findIndex((media) => media.trim() === volser) < 0) {
              duplicates.push(volser);
            }
          } else {
            volserList += volser.padEnd(20);
          }
        }
      } else {
        if (invalids.findIndex((media) => media.trim() === volser) < 0) {
          invalids.push(volser);
        }
      }
    });

    setDuplicateAlert((prevState) => ({ show: false, title: "", message: "" }));
    // display import exceptions message
    if (duplicates.length > 0 || invalids.length > 0) {
      const duplicatesMsg = duplicates
        .map((dup) => ` ${dup} - Duplicate`)
        .join(", ");
      const invalidsMsg = invalids.map((inv) => ` ${inv} - Invalid`).join(", ");
      const conjunction =
        duplicatesMsg.length > 0 && invalidsMsg.length > 0 ? "," : "";

      setDuplicateAlert({
        show: true,
        title: t("mediaRequestDetail.fileImportExceptionTitle"),
        message: `${duplicatesMsg}${conjunction}${invalidsMsg}`
      });
    }

    validateOpenMediaNumber({
      customerId: props.customerId,
      volserList: volserList
    })
      .unwrap()
      .then(async (resp) => {
        if (resp?.error) {
          // error occured
          setFileImporting((prevState) => false);
          dispatch(setErrorMessage(resp.data.error));
        } else if (resp?.volsersInMdr) {
          const volsersInMdr = String(resp.volsersInMdr);
          // if media were requested that are on an MDR or in a destroyed status
          // return a comma-delimited list of the media numbers
          setDestroyedMediasAlert((prevState) => ({
            show: false,
            title: "",
            message: ""
          }));
          if (volsersInMdr.length > 0) {
            const message64028 = await getMessageByIdFromMessage({
              message_id: MESSAGE_64028
            });
            await showDestroyedMediaMessage(
              message64028[0]?.descr,
              volsersInMdr
            );
          }
          setFileImporting((prevState) => false);
        } else {
          // open medias found
          setFileImporting((prevState) => false);
          dispatch(addOpenMedias(resp.medias));
        }
      });
  };

  // handle file import event handler
  const handleFileImport = async (event) => {
    if (!fileImporting) {
      // file imports are not in process
      const file = event.target.files[0];

      if (file) {
        setFileImporting((prevState) => true);
        try {
          const reader = new FileReader();
          reader.onload = (e) => {
            const content = e.target.result;
            if (!content.trim()) {
              setFileImporting((prevState) => false);
              // Content is empty, trigger the modal here
              setUnsupportedMediaFileModalVisible((prevState) => true);
            } else {
              // Content is not empty, process the file content
              processFileContent(content);
            }
            // Reset the input value to trigger the onChange event next time
            openMediaFileImportRef.current.value = null;
          };
          reader.readAsText(file);
        } catch (error) {
          setFileImporting((prevState) => false);
          dispatch(
            setErrorMessage(t("mediaRequestDetail.errorReadingTheFile"), error)
          );
        }
      }
    }
  };

  // build message for destroyed open medias
  const showDestroyedMediaMessage = async (title, volsers) => {
    const message64032 = await getMessageByIdFromMessage({
      message_id: MESSAGE_64032
    });
    const volsersInMdrMessage = String(volsers)
      .split(",")
      .map((volser) => ` ${volser} - ${message64032[0]?.descr}`)
      .join(", ");
    setDestroyedMediasAlert((prevState) => ({
      show: true,
      title: title.replace("|", ""),
      message: volsersInMdrMessage
    }));
  };

  // possible matches close event handler
  const handlePossibleMatchesOnClose = () => {
    setPossibleMatchesModalVisible((prevState) => false);
    setSelectedMedia((prevState) => "");
  };

  // possible matches submit event handler
  const handlePossibleMatchesOnSubmit = (selectedOpenMedias) => {
    // construct the volser list with the selected open medias
    const selectedVolsers = selectedOpenMedias
      .map((media) => media.volser.padEnd(20, " "))
      .toString()
      .replaceAll(",", "");

    // validate the constructed volser list
    validateOpenMediaNumber({
      customerId: props.customerId,
      volserList: selectedVolsers
    })
      .unwrap()
      .then((resp) => {
        dispatch(
          addOpenMediaSelectedFromPossibleMatches({
            selectedVolser: selectedMedia,
            medias: resp.medias
          })
        );
        handlePossibleMatchesOnClose();
      });
  };

  // intercept event and convert value of the change event to uppercase and return the event
  const convertValuesToUpperCaseEvent = (event) => {
    const { id, name, value } = event.target;

    // Return the modified event to use with a event handler
    return {
      ...event,
      target: {
        id,
        name,
        value: value.toUpperCase()
      }
    };
  };

  // clear the selected open media and containers when the customer changes
  useEffect(() => {
    if (Number(props.customerId) > 0 && !props.requestId) {
      openMediaForm.resetForm();
      dispatch(setOpenMedias([]));
      containersForm.resetForm();
      dispatch(setContainers([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customerId]);

  // fetch open media and containers of the media request if a request_id passed as a prop
  useEffect(() => {
    if (
      (Number(props.requestId) > 0 &&
        actionId === REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID) ||
      refetch
    ) {
      loadOpenMedias({ requestId: props.requestId })
        .unwrap()
        .then((resp) => dispatch(setOpenMedias(resp)));
      loadContainers({ requestId: props.requestId })
        .unwrap()
        .then((resp) => {
          dispatch(setContainers(resp));
        });
    } else {
      resetOpenMedias();
      resetContainers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestId, props.customerId, refetch]);

  return (
    <Grid id="deliveryMediaGrid1" container columnSpacing={"3rem"}>
      {/* Add Open Media Card */}
      <Grid id="deliveryMediaGrid2" item xs={6}>
        {validateUserHasProgramId(204) ? (
          <Card id="deliveryMediaCard3">
            <CardHeader
              id="deliveryMediaCardHeader4"
              title={t("mediaRequestDetail.addOpenMedia")}
            />

            {/* Duplicate message display area */}
            {duplicateAlert.show && (
              <Grid id="mediaRequestDetailGrid1" item>
                <Alert
                  id="mediaRequestDetailErrorAlert"
                  severity="warning"
                  color="warning"
                  onClose={() =>
                    setDuplicateAlert({ show: false, title: "", message: "" })
                  }
                  sx={{ borderRadius: "9px" }}
                >
                  {Boolean(duplicateAlert?.title) && (
                    <AlertTitle>{duplicateAlert.title}</AlertTitle>
                  )}
                  {duplicateAlert.message}
                </Alert>
              </Grid>
            )}

            {/* Destroyed medias message display area */}
            {destroyedMediasAlert.show && (
              <Grid id="mediaRequestDetailGrid1" item>
                <Alert
                  id="mediaRequestDetailErrorAlert"
                  severity="warning"
                  color="warning"
                  onClose={() =>
                    setDestroyedMediasAlert({
                      show: false,
                      title: "",
                      message: ""
                    })
                  }
                  sx={{ borderRadius: "9px" }}
                >
                  {Boolean(destroyedMediasAlert?.title) && (
                    <AlertTitle>{destroyedMediasAlert.title}</AlertTitle>
                  )}
                  {destroyedMediasAlert.message}
                </Alert>
              </Grid>
            )}

            <CardContent
              id="deliveryMediaCardContent5"
              sx={DeliverMediaStyles.CardContent}
            >
              <Box id="deliveryMediaBox6" sx={DeliverMediaStyles.Box1}>
                {/* Open media number field */}
                <TextField
                  id="deliveryMediaTabOpenMediaMediaNumber7"
                  disabled={
                    openMediaFound || containersFound || disableOpenMedia
                  }
                  name="volser"
                  label={t("mediaRequestDetail.mediaNumber")}
                  required
                  size="small"
                  value={openMediaForm.values.volser}
                  onChange={(event) =>
                    limitFormikTextFieldLength(
                      openMediaForm,
                      convertValuesToUpperCaseEvent(event),
                      20
                    )
                  }
                  error={
                    openMediaForm.touched.volser &&
                    Boolean(openMediaForm.errors.volser)
                  }
                  helperText={openMediaForm.errors.volser}
                  sx={{ width: "18.75rem" }}
                />
                {/* Validate & add button */}
                <Button
                  id="deliveryMediaTabOpenMediaValidate8"
                  disabled={
                    !openMediaForm.values?.volser ||
                    openMediaFound ||
                    containersFound ||
                    Number(props.requestStatus) === REQUEST_STATUS.CANCELED ||
                    Number(props.requestStatus) === REQUEST_STATUS.DELETED ||
                    Number(props.requestStatus) === REQUEST_STATUS.COMPLETE
                  }
                  variant="outlined"
                  size="medium"
                  onClick={openMediaForm.handleSubmit}
                  sx={{ textTransform: "initial" }}
                >
                  {Boolean(openMediaForm.values?.volser) &&
                    validatingOpenMedia && (
                      <CircularProgress
                        color="primary"
                        sx={{
                          ...MediaRequestDetailFormStyles.ButtonCircularProgress,
                          marginRight: ".5rem"
                        }}
                      />
                    )}
                  {t("mediaRequestDetail.validateAndAdd")}
                </Button>

                {/* Import button */}
                <Button
                  id="deliveryMediaTabOpenMediaImport9"
                  component="label"
                  disabled={
                    openMediaFound ||
                    containersFound ||
                    disableOpenMedia ||
                    Number(props.requestStatus) === REQUEST_STATUS.CANCELED ||
                    Number(props.requestStatus) === REQUEST_STATUS.DELETED ||
                    Number(props.requestStatus) === REQUEST_STATUS.COMPLETE
                  }
                  variant="outlined"
                  size="medium"
                  sx={{
                    textTransform: "initial"
                  }}
                >
                  {fileImporting && (
                    <CircularProgress
                      color="primary"
                      sx={{
                        ...MediaRequestDetailFormStyles.ButtonCircularProgress,
                        display: "flex",
                        marginRight: ".5rem"
                      }}
                    />
                  )}
                  {t("mediaRequestDetail.import")}
                  <input
                    ref={openMediaFileImportRef}
                    type="file"
                    accept=".txt, .csv"
                    style={{ display: "none" }}
                    onChange={handleFileImport}
                  />
                </Button>
              </Box>
              <Box sx={DeliverMediaStyles.RequestedBox}>
                {/* Requested open media count */}
                <Typography sx={DeliverMediaStyles.Requested}>
                  {deliverMedia.openMedias.length}{" "}
                  {t("mediaRequestDetail.requested")}
                </Typography>
              </Box>

              {/* Open media table */}
              <MUIDataTable
                id="deliveryMediaTabOpenMediaDataTable10"
                data={deliverMedia.openMedias}
                columns={
                  props.requestId
                    ? viewOpenMediasColumnsDefinition
                    : openMediasColumnsDefinition
                }
                options={dataTableOptions}
              />
            </CardContent>
          </Card>
        ) : (
          <Alert severity="warning">{message26022}</Alert>
        )}
      </Grid>

      {/* Add Container Card */}
      <Grid id="deliveryMediaGrid11" item xs={6}>
        <Card id="deliveryMediaCard11">
          <CardHeader
            id="deliveryMediaCardHeader12"
            title={t("mediaRequestDetail.addContainer")}
          />

          {/* Message display area */}
          {containerMessage.show && (
            <Grid id="mediaRequestDetailGrid20" item>
              <Alert
                id="mediaRequestDetailErrorAlert"
                severity="warning"
                color="warning"
                onClose={() =>
                  setContainerMessage({ show: false, title: "", message: "" })
                }
                sx={{ borderRadius: "9px" }}
              >
                {Boolean(containerMessage?.title) && (
                  <AlertTitle>{containerMessage.title}</AlertTitle>
                )}
                {containerMessage.message}
              </Alert>
            </Grid>
          )}

          <CardContent
            id="deliveryMediaCardContent13"
            sx={DeliverMediaStyles.CardContent}
          >
            <Box
              id="deliveryMediaBox14"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                margin: "1rem",
                "> button": { marginLeft: "1rem" }
              }}
            >
              <TextField
                id="deliveryMediaTabContainerContainerNumber15"
                disabled={props.pageIsViewOnly}
                name="container_id"
                label={t("mediaRequestDetail.containerNumber")}
                required
                size="small"
                value={containersForm.values.container_id}
                onChange={(event) =>
                  limitFormikTextFieldLength(
                    containersForm,
                    convertValuesToUpperCaseEvent(event),
                    18
                  )
                }
                error={
                  containersForm.touched.container_id &&
                  Boolean(containersForm.errors.container_id)
                }
                helperText={containersForm.errors.container_id}
                sx={{ width: "18.75rem" }}
              />
              <Button
                id="deliveryMediaTabContainerValidate16"
                variant="outlined"
                size="medium"
                disabled={
                  !containersForm.values?.container_id ||
                  props.pageIsViewOnly
                }
                onClick={containersForm.handleSubmit}
                sx={{ textTransform: "initial" }}
              >
                {validatingContainerNumber && (
                  <CircularProgress
                    color="primary"
                    sx={{
                      ...MediaRequestDetailFormStyles.ButtonCircularProgress,
                      marginRight: ".5rem"
                    }}
                  />
                )}
                {t("mediaRequestDetail.validateAndAdd")}
              </Button>
            </Box>
            <Box sx={DeliverMediaStyles.RequestedBox}>
              {/* Requested container count */}
              <Typography sx={DeliverMediaStyles.Requested}>
                {deliverMedia.containers.length}{" "}
                {t("mediaRequestDetail.requested")}
              </Typography>
            </Box>
            <MUIDataTable
              id="deliveryMediaTabContainerDataTable19"
              data={deliverMedia.containers}
              columns={
                props.requestId
                  ? viewContainersColumnsDefinition
                  : containersColumnsDefinition
              }
              options={dataTableOptions}
            />
          </CardContent>
        </Card>
      </Grid>

      {/* Possible Matches modal */}
      {possibleMatchesModalVisible && (
        <OpenMediaPossibleMatchesModal
          show={possibleMatchesModalVisible}
          customerId={props.customerId}
          volserList={deliverMedia.openMedias
            .map((media) => media.volser.padEnd(20, " "))
            .toString()
            .replaceAll(",", "")}
          followUp={possibleMatchesOrDuplicates}
          isLoading={validatingOpenMedia}
          selectedMedia={selectedMedia}
          onClose={handlePossibleMatchesOnClose}
          onSubmit={handlePossibleMatchesOnSubmit}
        />
      )}

      {/* Unsupported media file info message */}
      {unsupportedMediaFileModalVisible && (
        <ImportUnsupportedMediaFileModal
          isVisible={unsupportedMediaFileModalVisible}
          onOk={() => {
            setUnsupportedMediaFileModalVisible((prevState) => false);
            window.scrollTo({ top: 0 });
          }}
        />
      )}
    </Grid>
  );
};

// Props definition of the component
DeliverMediaTab.propTypes = {
  customerId: PropTypes.number.isRequired, // ID of the customer
  requestId: PropTypes.number, // ID of the media request
  requestType: PropTypes.number, // ID of the request type
  requestStatus: PropTypes.number, // ID of the request status
  isRedelivery: PropTypes.bool, // if the request is a redelivery
  pageIsViewOnly: PropTypes.bool // If the media request detail is saved
};

export default DeliverMediaTab;
