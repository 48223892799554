import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Paper,
  TableContainer,
  Typography
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { numberTwo } from "utils/constants";
import ContainerAuditTable from "./ContainerAuditTable";
import OpenMediaTable from "./OpenMediaTable";
import { DiscrepencyStyles } from "./discrepancyStyles";

const DiscrepencyTables = ({
  setSelectedRow,
  selectedRow,
  setSelectedId,
  selectedId,
  selectedContainerRow,
  setSelectedContainerRow,
  selectedContainerId,
  setSelectedContainerId,
  handleNavigationClick,
  maxRecordMsg,
  hasPermission326,
  hasPermission327,
  isOpenMediaSelected,
  setIsOpenMediaSelected,
  customerMediaIndentifiersFlag,
  setCustomerMediaIndentifiersFlag,
  branchOwnedText,
  hasUpdatePermission
}) => {
  const { openMediaData, containerData, dontCallLabel } = useSelector(
    (state) => state.discrepancySearch
  );
  const [omExpended, setOmExpended] = useState(false);
  const [containerExpended, setContainerExpended] = useState(false);
  useEffect(() => {
    const shouldExpandOM = openMediaData?.length > 0;
    const shouldExpandContainer = containerData?.length > 0;
    setOmExpended(shouldExpandOM);
    setContainerExpended(shouldExpandContainer);
  }, [openMediaData, containerData]);
  const tableProps = {
    setSelectedRow,
    selectedRow,
    setSelectedId,
    selectedId,
    selectedContainerRow,
    setSelectedContainerRow,
    selectedContainerId,
    setSelectedContainerId,
    handleNavigationClick,
    maxRecordMsg,
    hasPermission326,
    hasPermission327,
    isOpenMediaSelected,
    setIsOpenMediaSelected,
    customerMediaIndentifiersFlag,
    setCustomerMediaIndentifiersFlag,
    branchOwnedText,
    hasUpdatePermission
  };
  const onChangeAccordionExpand = (e, expanded, type) => {
    if (type === t("discrepancyManagement.openMedia")) {
      setOmExpended(expanded);
    }
    if (type === t("discrepancyManagement.container")) {
      setContainerExpended(expanded);
    }
  };
  return (
    <>
      <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
        <Divider />
      </Grid>

      <Accordion
        expanded={omExpended}
        onChange={(e, expanded) =>
          onChangeAccordionExpand(
            e,
            expanded,
            t("discrepancyManagement.openMedia")
          )
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
        >
          {`${t("discrepancyManagement.openMedia")} (${
            openMediaData?.length || 0
          })`}
        </AccordionSummary>
        <AccordionDetails
          sx={
            omExpended
              ? DiscrepencyStyles.maxHeightStyles
              : DiscrepencyStyles.displayAccordionStyles
          }
        >
          <>
            {dontCallLabel && (
              <Typography sx={{ mb: numberTwo }}>{dontCallLabel}</Typography>
            )}
            <Paper>
              <TableContainer>
                <OpenMediaTable tableData={openMediaData} {...tableProps} />
              </TableContainer>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={containerExpended}
        onChange={(e, expanded) =>
          onChangeAccordionExpand(
            e,
            expanded,
            t("discrepancyManagement.container")
          )
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
        >
          {`${t("discrepancyManagement.container")} (${
            containerData?.length || 0
          })`}
        </AccordionSummary>
        <AccordionDetails
          sx={
            containerExpended
              ? DiscrepencyStyles.maxHeightStyles
              : DiscrepencyStyles.displayAccordionStyles
          }
        >
          <>
            <Paper>
              <TableContainer>
                <ContainerAuditTable
                  tableData={containerData}
                  {...tableProps}
                />
              </TableContainer>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DiscrepencyTables;
