import { Button, Grid, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";
import { CircularLoaderFullPage } from "components/core";
import dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  dayMonthYearFormat
} from "utils/constants";
import { OpenMediaAuditSearchStyles } from "./OpenMediaAuditSearchStyles";

const OpenMediaAuditSearchTable = ({
  setSelectedRow,
  isResetGrid,
  setSelectedId,
  isLoading,
  searchResults,
  isUpdating,
  isNewVisible,
  handleNavigationClick,
  handlePrintButtonClick,
  maxRecordMsg
}) => {
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(null);

  const hasMedia = searchResults.some((item) => item.media_audit_status_descr);
  const handleRowClick = (value, tableMeta) => {
    setSelectedId(value?.om_audit_id);
    setSelectedIndex(tableMeta?.rowIndex);
    setSelectedRow(searchResults[tableMeta?.rowIndex]);
    handleNavigationClick(value?.om_audit_id);
  };

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={searchResults?.length === 0}
            onClick={handlePrintButtonClick}
          >
            {t("common.print")}
          </Button>
          {isNewVisible && (
            <Button
              variant="outlined"
              size="medium"
              type="button"
              disabled={null}
              onClick={() => handleNavigationClick("")}
            >
              {t("common.new")}
            </Button>
          )}
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    },
    selectableRowsOnClick: true,
    onRowClick: handleRowClick,
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor:
          isUpdating && dataIndex === selectedIndex
            ? OpenMediaAuditSearchStyles?.SelectedRowColor
            : ""
      }
    })
  };

  const columns = [
    {
      name: "om_audit_id",
      label: t("openMediaAuditSearch.auditId"),

      options: {
        setCellProps: () => ({
          style: OpenMediaAuditSearchStyles.actionColumnStyle
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = searchResults[tableMeta.rowIndex];

          return (
            <>
              <Button
                onClick={() => {
                  handleRowClick(rowData, tableMeta);
                }}
              >
                <SvgIcon component={ViewIcon} />
              </Button>
              {value}
            </>
          );
        }
      }
    },
    {
      name: "audit_type",
      label: t("openMediaAuditSearch.auditType")
    },
    {
      name: "audit_status",
      label: t("openMediaAuditSearch.auditStatus")
    },
    {
      name: "due_date",
      label: t("openMediaAuditSearch.dueDate"),
      options: {
        customBodyRender: (value) => {
          const formattedDate = value
            ? dayjs.utc(value).format(dayMonthYearFormat)
            : "";
          return formattedDate;
        }
      }
    },
    {
      name: "customer_number",
      label: t("openMediaAuditSearch.customer")
    },
    {
      name: "requested_by_personnel",
      label: t("openMediaAuditSearch.personnel")
    },
    {
      name: "audit_date_ltz",
      label: t("openMediaAuditSearch.auditDate"),
      options: {
        customBodyRender: (value) => {
          const formattedDate = value
            ? dayjs.utc(value).format(dayMonthYearFormat)
            : "";
          return formattedDate;
        }
      }
    },
    {
      name: "scan_count",
      label: t("openMediaAuditSearch.scanCount")
    },
    {
      name: "exception_count",
      label: t("openMediaAuditSearch.exceptions")
    }
  ];

  if (hasMedia) {
    columns.splice(1, 0, {
      name: "media_audit_status_descr",
      label: t("openMediaAuditSearch.result")
    });
  }

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      <Grid
        display={"flex"}
        direction={"column"}
        gap={spacing.verticalMargin20}
      >
        <Grid item xs={12} lg={3} marginTop={spacing.margingTop20}>
          <MUIDataTable
            columns={columns}
            options={options}
            data={searchResults}
            title={
              !isResetGrid ? (
                <Typography style={OpenMediaAuditSearchStyles.titleStyle}>
                  {maxRecordMsg ? (
                    maxRecordMsg
                  ) : (
                    <>
                      {searchResults?.length} {t("common.recordsFound")}
                    </>
                  )}
                </Typography>
              ) : (
                ""
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OpenMediaAuditSearchTable;
