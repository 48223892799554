import { Autocomplete, TextField } from "@mui/material";
import { t } from "i18next";
import { useGetAllPersonnelQuery } from "services/api";
import { ReactComponent as ArrowDown } from "../../../assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/CloseIcon.svg";
//The handlePersonnelOnChange prop is used when changing the Autocomplete dropdown values.
//The selectedPersonnel prop is used for display the drop down values
export const PersonnelsField = ({
  handlePersonnelOnChange,
  selectedPersonnel,
  customerId,
  form,
  label,
  disablePersonnel = false
}) => {
  const { data: personnels = [] } = useGetAllPersonnelQuery({
    customerId: customerId
  });
  return (
    <Autocomplete
      clearIcon={<CloseIcon />}
      popupIcon={<ArrowDown />}
      id="common-personal-field"
      disablePortal
      size="medium"
      value={selectedPersonnel}
      disabled={form?.errors?.error || !customerId || disablePersonnel}
      getOptionLabel={(option) => (option?.label ? option?.label : "")}
      options={personnels?.map((personnel, index) => ({
        ...personnel,
        key: index
      }))}
      isOptionEqualToValue={(option, value) => true}
      renderInput={(params) => (
        <TextField
          id="commonPersonnel"
          {...params}
          name="Personnel"
          label={label ? label : t("common.authorizedBy")}
          fullWidth
        />
      )}
      onChange={(event, newValue) => handlePersonnelOnChange(newValue)}
    />
  );
};
