import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ErrorIcon from "assets/images/warning-2.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getOpenMediaPossibleMatches } from "services/common";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS, ERROR_TYPES } from "utils/constants";
import { findErrorMessage } from "utils/helpers";
import { disasterRecoveryStyles } from "../DisasterRecoveryStyles";

const PossibleMatchesModal = ({
  open,
  onClose,
  customer,
  openMediaData,
  selectedOM,
  addOmFromPossibleMatches,
  setLoading
}) => {
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [matchingList, setMatchingList] = useState([]);

  const routeParams = useParams();
  const eventID = routeParams?.id;

  const pageTitle = useMemo(() => {
    return t("disasterRecovery.eventDetail.manualEntry.possibleMatchesHeader", {
      eventID,
      customerNumber: customer.label
    });

    //eslint-disable-next-line
  }, [customer]);

  const handleChangeSelection = (e, number) => {
    let checked = e.target.checked;
    let updatedIndex = matchingList.findIndex((item) => item.volser === number);
    let updatedOM = [...matchingList];
    updatedOM[updatedIndex].selected = checked;
    setMatchingList(updatedOM);
  };

  const findPossibleMatches = async () => {
    try {
      setLoading(true);
      let volserList = openMediaData.map((om) => om.volser.toString());
      let list = await getOpenMediaPossibleMatches({
        customerId: customer.value,
        volserList: volserList.join(","),
        selectedVolser: selectedOM
      });

      list = list
        .filter((om) => !volserList.includes(om.volser?.padEnd(20, " ")))
        .sort((a, b) => a.volser.localeCompare(b.volser));

      setMatchingList(list.map((om) => ({ ...om, selected: false })));
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) findPossibleMatches();
    // eslint-disable-next-line
  }, [open]);

  const handleClickOk = () => {
    let selectedList = matchingList.filter((om) => om.selected);
    addOmFromPossibleMatches(selectedList);
  };
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    responsive: "standard",
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    pagination: true,
    textLabels: {
      body: {
        noMatch: ""
      }
    }
  };

  const columnOpenMedia = [
    {
      name: "selected",
      label: t("disasterRecovery.eventDetail.manualEntry.selected"),
      options: {
        filter: true,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          let volser = tableMeta?.rowData[1];

          return (
            <Checkbox
              sx={disasterRecoveryStyles.manualEntry.checkbox}
              checked={value}
              onChange={(e) => handleChangeSelection(e, volser)}
            />
          );
        },
        customHeadLabelRender: () => null
      }
    },
    {
      name: "volser",
      label: "Media Number"
    },
    {
      name: "logical_vault_code",
      label: "Logical vault",
      options: {
        customBodyRender: (val) => val.trim()
      }
    },
    {
      name: "media_type_descr",
      label: "Media Type"
    },
    {
      name: "media_exchange_status",
      label: "Status"
    }
  ];

  const hasSelected = matchingList.filter((om) => om.selected)?.length > 0;
  return (
    <CommonModal
      open={open}
      title={pageTitle}
      body={
        <>
          {throwError && (
            <Box mb={spacing.gap}>
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <GridCloseIcon />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Box>
          )}
          <Grid container spacing={2}>
            <Box sx={disasterRecoveryStyles.manualEntry.tableContainer}>
              <Box sx={disasterRecoveryStyles.manualEntry.instuctions}>
                <ul>
                  <li>
                    <Typography>
                      {t(
                        "disasterRecovery.eventDetail.manualEntry.matchesInstructionOne",
                        { number: selectedOM }
                      )}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      {t(
                        "disasterRecovery.eventDetail.manualEntry.matchesInstructionTwo",
                        { number: selectedOM }
                      )}
                    </Typography>
                  </li>
                </ul>
              </Box>
              <MUIDataTable
                options={options}
                columns={columnOpenMedia}
                data={matchingList}
                title={
                  <Typography variant="subtitle1">
                    {matchingList.length} {t("common.recordsFound")}
                  </Typography>
                }
              />
            </Box>
          </Grid>
        </>
      }
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleClickOk}
            disabled={!hasSelected}
          >
            {t("common.add")}
          </Button>
        </>
      }
    />
  );
};
export default PossibleMatchesModal;
