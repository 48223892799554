import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { PageTitle } from "components/shared";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth, selectRoute, setHaveChanges } from "store/slices";
import spacing from "styles/spacing";

import { useCallbackPrompt } from "hooks";
import {
  bulk,
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  errorMsgs,
  local,
  N_CHECKER,
  no_of_records,
  SELECT,
  VALUE_ALL,
  VALUE_EMPTY_STRING,
  VALUE_Y,
  Y_CHECKER
} from "utils/constants";
import {
  A,
  active,
  defaultPageCount,
  longTerm,
  R
} from "utils/constants/assign-shelves/AssignShelves";
import { zero } from "utils/constants/customer-information/PayInvoiceFilter";
import { findErrorMessage, getResponseData } from "utils/helpers";
import AssignShelvesModal from "./AssignShelvesModal";

const {
  labelTemplateMaintanceStyles
} = require("components/customer-information/label-template-maintenance/LabelTemplateMaintenanceStyles");

const AssignShelves = ({ tableData }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const params = useLocation();
  const { code, logical_vault_id, long_term_flag } = params.state.vaultDetails;
  const { currentBranch } = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageCount);
  const [rowsPerPageAssign, setRowsPerPageAssign] = useState(defaultPageCount);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [physicalVaultData, setPhysicalVualtData] = useState([]);
  const [physicalVault, setPhysicalVault] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [row, setRow] = useState(null);
  const [rackData, setRackData] = useState([]);
  const [assignedShelves, setAssignedShelves] = useState([]);
  const [availableShelves, setAvailableShelves] = useState([]);
  const [rack, setRack] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAssignRows, setSelectedAssignRows] = useState([]);
  const [enableSave, setEnableSave] = useState(true);
  const [selectedIds, setSelectIds] = useState([]);
  const [enableShelves, setEnableShelves] = useState(true);
  const [enableReleaseSlot, setEnableReleaseSlot] = useState(true);
  const [releaseData, setReleaseData] = useState([]);
  const [cancelClick, setCancelClick] = useState(false);
  const [releaseClick, setReleaseClick] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [openReleaseAlertModalOne, setOpenReleaseAlertModalOne] =
    useState(false);
  const [openReleaseAlertModaltwo, setOpenReleaseAlertModaltwo] =
    useState(false);
  const [openChangesModal, setOpenChangesModal] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [checkAvailShelves, setCheckAvailShelves] = useState([]);
  const [checkAssignShelves, setCheckAssignShelves] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAssignAll, setCheckAssignAll] = useState(false);
  const [openAssignShelvesModal, setOpenAssignShelvesModal] = useState(false);
  const [editdata, setEditData] = useState({});
  const [ogAssignData, setOgAssignData] = useState([]);
  const [ogAvailableData, setOgAvailableData] = useState([]);
  const [editAssignData, setEditAssignData] = useState({
    location: "",
    duration: "",
    capacity: "",
    startingSlot: ""
  });
  const [warnings, setWarnings] = useState("");
  const { haveChanges } = useSelector(selectRoute);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges); // browser back and forward blocker hook
  const handleRowSelection = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    setSelectedRows((prev) => rowsSelected);
  };
  const handleAssignRowSelection = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    setSelectedAssignRows((prev) => rowsSelected);
  };
  const constructValue = (shelf, type, storeType) => {
    if (storeType === bulk) {
      const typeAdd = shelf?.map((obj) => {
        obj.type = type;
        return obj;
      });
      setSelectIds([...selectedIds, ...typeAdd]);
    } else {
      shelf.type = type || "";
      setSelectIds([...selectedIds, shelf]);
    }
  };
  const selectedAvailabledata = availableShelves
    ?.filter(
      (av) => !assignedShelves?.some((as) => av.location === as.location)
    )
    ?.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.shelf_id === value.shelf_id && t.location === value.location
        )
    );
  const selectedAssignedData = assignedShelves;
  const addToAssignShelves = () => {
    const checkDulicate = selectedAssignedData.filter((item1) =>
      checkAvailShelves.some((item2) => item1.location === item2.location)
    );
    let assigndata = checkAvailShelves?.map((obj) => {
      obj.local = local;
      return obj;
    });
    if (assigndata?.length > 0) {
      if (checkDulicate.length === 0) {
        if (assigndata.length > 0) {
          setCheckAll(false);
          setSaveChanges(true);
          dispatch(setHaveChanges(true));

          constructValue(assigndata, A, bulk);
          setEnableSave(false);
          setAssignedShelves([...assignedShelves, ...assigndata]);
          setCheckAvailShelves([]);
        } else {
          setSaveChanges(true);
          dispatch(setHaveChanges(true));

          constructValue(availableShelves[selectedRows[0]], A);
          setEnableSave(false);
          setAssignedShelves([
            ...assignedShelves,
            selectedAvailabledata[selectedRows[0]]
          ]);
        }
      }
    }
  };
  const removeFromAssignShelves = async () => {
    if (checkAssignShelves?.length > 0) {
      if (checkAssignShelves?.some((obj) => obj?.media_count > 0)) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode25101);
        setThrowError(true);
        setThrowErrorMessage(
          errDesc[0]?.descr?.replace(
            "|",
            assignedShelves[selectedAssignRows[0]]?.location
          )
        );
        setOpenReleaseAlertModaltwo(false);
      } else {
        const removeDuplicates = (arrA, arrB) => {
          return arrA.filter(
            (itemA) => !arrB.some((itemB) => itemA.location === itemB.location)
          );
        };
        if (checkAssignShelves.length > 0) {
          setSaveChanges(true);
          dispatch(setHaveChanges(true));

          setAssignedShelves(
            removeDuplicates(assignedShelves, checkAssignShelves)
          );
          constructValue(checkAssignShelves, R, bulk);
          setAvailableShelves([...availableShelves, ...checkAssignShelves]);
          setCheckAssignShelves([]);
          setEnableSave(false);
          setCheckAssignAll(false);
        } else {
          setSaveChanges(true);
          dispatch(setHaveChanges(true));

          let removedElement = assignedShelves.splice(
            selectedAssignRows[0],
            1
          )[0];
          setAssignedShelves(assignedShelves);
          constructValue(removedElement, R);
          setAvailableShelves([...availableShelves, removedElement]);
          setEnableSave(false);
        }
      }
    }
  };

  const releaseSlotsCheck = async () => {
    if (assignedShelves.length > 0) {
      if (saveChanges) {
        setOpenChangesModal(true);
      } else {
        if (checkAssignShelves.length > 0) {
          setReleaseClick(true);
          releaseSlots();
        }
      }
    }
  };
  const releaseSlots = async () => {
    const warningDesc = t("assignShelves.releaseWarning");
    setWarnings(warningDesc);
    setOpenReleaseAlertModalOne(true);
  };
  const releaseYes = async () => {
    if (checkAssignShelves.length > 0) {
      const warningDesc = await getMessageFromCode(errorMsgs.errorCode25110);
      setWarnings(warningDesc[0]?.descr);
      setOpenReleaseAlertModalOne(false);
      setOpenReleaseAlertModaltwo(true);
    }
  };

  const fetchReleaseSlot = async (value) => {
    try {
      setLoading(true);
      const reqbody = {
        main_district_id: currentBranch?.value,
        shelf_list: [...new Set(releaseData)]
          ?.map((o) => o.shelf_id)
          ?.map((shelf) => shelf.toString().padEnd(10, " "))
          .join(""),
        remove_from_logical_vault: value
      };
      let response = await getResponseData(
        reqbody,
        `${CF_URLS?.assignShelves.releaseSLots}`,
        1
      );
      if (response.data[0][0]?.error === no_of_records) {
        const success = await getMessageFromCode(errorMsgs.errorCode62512);
        setMessageSuccess(
          success[0]?.descr?.replace("|", t("assignShelves.selectedShelves"))
        );
        setCheckAssignShelves([]);
        fetchShelves();
        setReleaseData([]);
        setOpenReleaseAlertModaltwo(false);
        setThrowError(false);
        setThrowErrorMessage(null);
        setReleaseClick(false);
        setCheckAssignAll(false);
      } else {
        setLoading(false);
        const errDesc = await getMessageFromCode(response.data[0][0]?.error);
        setThrowError(true);
        setThrowErrorMessage(errDesc[0]?.descr);
        setOpenReleaseAlertModaltwo(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    onRowSelectionChange: handleRowSelection,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    rowsSelected: selectedRows,
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: (obj) => {
      setRowsPerPage(obj); // Update the state when rows per page changes
    },
    setRowProps: (row, dataIndex) => {
      const isSelected = selectedRows?.includes(dataIndex);
      return {
        style: {
          backgroundColor: isSelected && "var(--color-im-light-blue-100)"
        }
      };
    },
    pagination: true,
    elevation: 0,
    textLabels: {
      body: {
        noMatch:
          tableData?.length === 0
            ? `${t("common.noRecordsFound")}`
            : tableData?.length === 0 && `${t("common.tableTextWithoutInputs")}`
      }
    },

    onRowClick: () => {}
  };

  const assignOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    onRowSelectionChange: handleAssignRowSelection,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    rowsSelected: selectedAssignRows,
    rowsPerPage: rowsPerPageAssign,
    onChangeRowsPerPage: (obj) => {
      setRowsPerPageAssign(obj); // Update the state when rows per page changes
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      const isSelected = selectedAssignRows?.includes(dataIndex);
      // Conditionally style the row based on `media_count`
      if (assignedShelves[rowIndex]?.media_count > 0) {
        // Assuming `media_count` is the 3rd column in the row
        return {
          style: {
            backgroundColor: isSelected
              ? "var(--color-im-light-blue-100)"
              : "rgba(1,0,0,0.1)"
          }
        };
      }
      return {};
    },

    pagination: true,
    elevation: 0,
    textLabels: {
      body: {
        noMatch:
          tableData?.length === 0
            ? `${t("common.noRecordsFound")}`
            : tableData?.length === 0 && `${t("common.tableTextWithoutInputs")}`
      }
    },

    onRowClick: () => {}
  };
  const columns = [
    {
      name: VALUE_EMPTY_STRING,
      label: VALUE_EMPTY_STRING,
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Checkbox
              checked={
                selectedAvailabledata[tableMeta.rowIndex]?.location ===
                checkAvailShelves.find(
                  (obj) =>
                    obj?.location ===
                    selectedAvailabledata[tableMeta.rowIndex]?.location
                )?.location
              }
              onChange={() => {
                selectMultipleSHelves(
                  selectedAvailabledata[tableMeta.rowIndex]
                );
              }}
            />
          );
        }
      }
    },
    {
      name: "location",
      label: t("assignShelves.location")
    },
    {
      name: "duration",
      label: t("assignShelves.duration"),
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography>
              {selectedAvailabledata[tableMeta.rowIndex]?.long_term_flag ===
              VALUE_Y
                ? longTerm
                : active}
            </Typography>
          );
        }
      }
    },
    {
      name: "capacity",
      label: t("assignShelves.capacity")
    }
  ];
  const assignColumns = [
    {
      name: VALUE_EMPTY_STRING,
      label: VALUE_EMPTY_STRING,
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Box>
              <Checkbox
                checked={
                  selectedAssignedData[tableMeta.rowIndex]?.location ===
                  checkAssignShelves.find(
                    (obj) =>
                      obj?.location ===
                      selectedAssignedData[tableMeta.rowIndex]?.location
                  )?.location
                }
                onChange={() => {
                  selectMultipleAssignSHelves(
                    selectedAssignedData[tableMeta.rowIndex]
                  );
                }}
              />
              <Button
                disabled={
                  selectedAssignedData[tableMeta.rowIndex].media_count > 0
                }
                onClick={() => {
                  selectedAssignedData[tableMeta.rowIndex].duration =
                    long_term_flag === VALUE_Y ? longTerm : active;
                  setEditData(selectedAssignedData[tableMeta.rowIndex]);
                  setOpenAssignShelvesModal(true);
                }}
              >
                <SvgIcon component={EditIcon} />
              </Button>
            </Box>
          );
        }
      }
    },
    {
      name: "location",
      label: t("assignShelves.location")
    },
    {
      name: "duration",
      label: t("assignShelves.duration"),
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography>
              {selectedAssignedData[tableMeta.rowIndex]?.long_term_flag ===
              VALUE_Y
                ? longTerm
                : active}{" "}
            </Typography>
          );
        }
      }
    },
    {
      name: "capacity",
      label: t("assignShelves.capacity")
    },
    {
      name: "first_slot_number",
      label: t("assignShelves.slot")
    }
  ];
  const selectMultipleSHelves = (val) => {
    const findShelves = checkAvailShelves.findIndex(
      (obj) => obj?.location === val?.location
    );
    if (findShelves === -1) {
      setCheckAvailShelves([...checkAvailShelves, val]);
    } else {
      const removeSHelves = checkAvailShelves?.splice(findShelves, 1);
      setCheckAvailShelves(checkAvailShelves);
      return removeSHelves;
    }
    // setSelectIds
  };
  const selectMultipleAssignSHelves = (val) => {
    // Find the index of the shelf by comparing the 'location'
    const findShelves = checkAssignShelves.findIndex(
      (obj) => obj?.location === val?.location
    );

    if (findShelves === -1) {
      // Shelf not found, add to both arrays
      setReleaseData([...releaseData, val]);
      setCheckAssignShelves([...checkAssignShelves, val]);
    } else {
      // Shelf found, remove from checkAssignShelves by filtering it out
      const updatedCheckAssignShelves = checkAssignShelves.filter(
        (obj) => obj?.location !== val?.location
      );

      // Update checkAssignShelves with the new array
      setCheckAssignShelves(updatedCheckAssignShelves);

      // Update releaseData (assuming the goal is to keep only the updated shelves)
      setReleaseData([...updatedCheckAssignShelves]);
    }
  };
  const getPhysicalVualt = async () => {
    try {
      setLoading(true);
      const reqbody = {
        main_district_id: currentBranch?.value || null
      };
      let response = await getResponseData(
        reqbody,
        `${CF_URLS?.assignShelves.getPhysicalVualt}`,
        1
      );
      if (response.data[0]) {
        setLoading(false);
        setPhysicalVualtData(
          [
            ...response.data[0],
            {
              physical_vault_id: "",
              physical_vault_number: SELECT
            }
          ]?.sort((a, b) =>
            a?.physical_vault_number?.localeCompare(b?.physical_vault_number)
          )
        );
      }
      setPhysicalVault({
        physical_vault_id: "",
        physical_vault_number: SELECT
      });
      setRack({
        rack_id: "",
        rack_number: VALUE_ALL
      });
      setRow({
        row_id: "",
        row_number: VALUE_ALL
      });
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const getRowList = async (val) => {
    try {
      setLoading(true);
      const reqbody = {
        main_district_id: currentBranch?.value,
        physical_vault_id:
          val?.physical_vault_id ||
          (physicalVault && physicalVault?.physical_vault_id)
      };

      let response = await getResponseData(
        reqbody,
        `${CF_URLS?.assignShelves.getRowList}`,
        1
      );
      if (response.data[0]) {
        setLoading(false);
        setRowData(
          [
            ...response.data[0],
            {
              row_id: "",
              row_number: VALUE_ALL
            }
          ]?.sort((a, b) => a?.row_number?.localeCompare(b?.row_number))
        );
        setRow({
          row_id: "",
          row_number: VALUE_ALL
        });
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getRackList = async (val) => {
    try {
      setLoading(true);
      const reqbody = {
        main_district_id: currentBranch?.value,
        physical_vault_id: physicalVault && physicalVault?.physical_vault_id,
        row_id: val?.row_id || row
      };

      let response = await getResponseData(
        reqbody,
        `${CF_URLS?.assignShelves.getRack}`,
        1
      );
      if (response.data[0]) {
        setLoading(false);
        setRackData(
          [
            ...response.data[0],
            {
              rack_id: "",
              rack_number: VALUE_ALL
            }
          ]?.sort((a, b) => a?.rack_number?.localeCompare(b?.rack_number))
        );
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const handlePhysicalVault = (_, val) => {
    setRack({
      rack_id: "",
      rack_number: VALUE_ALL
    });
    setRow({
      row_id: "",
      row_number: VALUE_ALL
    });
    if (val?.physical_vault_id === "") {
      setEnableShelves(true);
    } else {
      setEnableShelves(false);
    }
    setEnableReleaseSlot(true);
    setPhysicalVault(val);
    getRowList(val);
    setAssignedShelves([]);
    setAvailableShelves([]);
    setCheckAssignShelves([]);
    setCheckAvailShelves([]);
    setSelectIds([]);
  };

  const acceptRows = () => {
    const findIndexRow = assignedShelves?.findIndex(
      (obj) => obj?.location === editdata?.location
    );
    assignedShelves[findIndexRow].first_slot_number =
      editAssignData?.startingSlot;
    if (assignedShelves[findIndexRow]?.local === local) {
      const findLocIndex = selectedIds?.findIndex(
        (obj) => obj?.location === assignedShelves[findIndexRow]?.location
      );
      if (findLocIndex !== -1) {
        selectedIds[findLocIndex].first_slot_number =
          editAssignData?.startingSlot;
        setSelectIds(selectedIds);
      }
    } else {
      constructValue(assignedShelves, " ", bulk);
    }
    setAssignedShelves(assignedShelves);
    setEnableSave(false);
    dispatch(setHaveChanges(true));
    setOpenAssignShelvesModal(false);
  };
  const handleRow = (_, val) => {
    setEnableReleaseSlot(true);
    setEnableShelves(false);
    setRow(val);
    getRackList(val);
    setAssignedShelves([]);
    setAvailableShelves([]);
    setCheckAssignShelves([]);
    setCheckAvailShelves([]);
    setSelectIds([]);
  };
  const handleRack = (_, val) => {
    setEnableReleaseSlot(true);
    setEnableShelves(false);
    setRack(val);
    setAssignedShelves([]);
    setAvailableShelves([]);
    setCheckAssignShelves([]);
    setCheckAvailShelves([]);
    setSelectIds([]);
  };

  const fetchShelves = async () => {
    try {
      setLoading(true);
      const reqbody = {
        main_district_id: currentBranch?.value,
        physical_vault_id: physicalVault && physicalVault?.physical_vault_id,
        row_id: row?.row_id,
        logical_vault_id: logical_vault_id,
        rack_id: rack?.rack_id
      };

      let responseAssign = await getResponseData(
        reqbody,
        `${CF_URLS?.assignShelves.getAssignedShelves}`,
        2
      );
      let responseAvailable = await getResponseData(
        reqbody,
        `${CF_URLS?.assignShelves.getAvailableShelves}`,
        2
      );
      if (responseAssign.data[0][0]?.error) {
        setLoading(false);
        const errDesc = await getMessageFromCode(
          responseAssign.data[0][0]?.error
        );
        setThrowError(true);
        setThrowErrorMessage(errDesc[0]?.descr);
      } else {
        setAssignedShelves(responseAssign.data[0]);
        setOgAssignData(responseAssign.data[0]);
      }

      if (responseAvailable.data[0][0]?.error) {
        setLoading(false);
        const errDesc = await getMessageFromCode(
          responseAvailable.data[0][0]?.error
        );
        setThrowError(true);
        setThrowErrorMessage(errDesc[0]?.descr);
      } else {
        setAvailableShelves(responseAvailable.data[0]);
        setOgAvailableData(responseAvailable.data[0]);
        setEnableShelves(true);
        setEnableReleaseSlot(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  useEffect(() => {
    getPhysicalVualt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkShelfDuration = async () => {
    try {
      setLoading(true);
      const logicalVaultLongTermFlag = long_term_flag; // Assuming logicalVaultDuration has a similar flag
      let mismatchFound = false;

      for (let shelf of selectedAssignedData) {
        if (shelf.long_term_flag !== logicalVaultLongTermFlag) {
          mismatchFound = true;
          break;
        }
      }

      if (mismatchFound) {
        const message = await getMessageFromCode(errorMsgs.errorCode25236);
        let msg;
        if (logicalVaultLongTermFlag === VALUE_Y) {
          msg = message[0]?.descr?.replace("|", active).replace("|", longTerm);
        } else {
          msg = message[0]?.descr?.replace("|", longTerm).replace("|", active);
        }
        setThrowError(true);
        setThrowErrorMessage(msg);
      }
      let shelfList = "";
      selectedIds.forEach((item) => {
        if (item.type === A) {
          // Check in assignShelves
          const assignedShelf = ogAssignData.find(
            (shelf) =>
              shelf.shelf_id === item.shelf_id &&
              shelf.location === item.location &&
              shelf.first_slot_number === item.first_slot_number
          );
          if (assignedShelf) {
            // If match is found, set type to an empty string
            item.type = "";
          }
        } else if (item.type === R) {
          // Check in availableShelves
          const availableShelf = ogAvailableData.find(
            (shelf) =>
              shelf.shelf_id === item.shelf_id &&
              shelf.location === item.location &&
              shelf.first_slot_number === item.first_slot_number
          );
          if (availableShelf) {
            // If match is found, set type to an empty string
            item.type = "";
          }
        }
      });
      for (let shelf of selectedIds) {
        let shelfString =
          shelf.shelf_id.padEnd(10) +
          logical_vault_id.padEnd(10) +
          shelf?.first_slot_number?.trim().padEnd(10) +
          shelf?.timestamp?.replace("0x", "").padEnd(16) +
          shelf?.type?.padEnd(1);
        shelfList += shelfString;
      }
      const reqBody = {
        main_district_id: currentBranch?.value,
        shelf_list: shelfList
      };
      let responseAvailable = await getResponseData(
        reqBody,
        `${CF_URLS?.assignShelves.saveShelves}`,
        2
      );
      if (responseAvailable.data[0][0]?.error === zero) {
        setEnableSave(true);
        fetchShelves();
        setSelectIds([]);
        setSaveChanges(false);
        dispatch(setHaveChanges(false));
        confirmNavigation();
        if (releaseClick) {
          releaseSlots();
        }
        if (cancelClick) {
          navigate(-1);
        }
        setOpenChangesModal(false);
      } else {
        const errDesc = await getMessageFromCode(
          responseAvailable.data[0][0]?.error
        );
        fetchShelves();
        setLoading(false);
        setThrowError(true);
        setThrowErrorMessage(errDesc[0]?.descr);
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setSelectIds([]);
      setCheckAssignShelves([]);
    }
  };
  const handleConfirmationOnNo = () => confirmNavigation();

  // handle confirmation popup "onCancel" event handler
  const handleConfirmationOnCancel = () => cancelNavigation();
  return (
    <Stack>
      {loading && <CircularLoaderFullPage loading={loading} />}
      {throwError && (
        <Box my={spacing.gap}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Box>
      )}
      {messageSuccess && (
        <Box my={spacing.gap}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => setMessageSuccess(false)}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {messageSuccess}
          </Alert>
        </Box>
      )}

      <PageTitle title={`${t("assignShelves.title")}`} />
      <br />
      <Box>
        <Typography variant="h5" component="h1" gutterBottom>
          {t("assignShelves.logicalVault")}
        </Typography>
        <br />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography
              variant="body2"
              sx={labelTemplateMaintanceStyles.maintanence.colorTitle}
            >
              {t("assignShelves.name")}
            </Typography>
            <Typography variant="body1">{code}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="body2"
              sx={labelTemplateMaintanceStyles.maintanence.colorTitle}
            >
              {t("assignShelves.duration")}
            </Typography>
            <Typography variant="body1">
              {long_term_flag === VALUE_Y ? longTerm : active}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              clearIcon={<GridCloseIcon />}
              id="physicalVault"
              name="physicalVault"
              options={physicalVaultData}
              onChange={handlePhysicalVault}
              getOptionLabel={(option) => option.physical_vault_number}
              value={physicalVault || null}
              size="medium"
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("assignShelves.physicalVault")}
                />
              )}
            />{" "}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              clearIcon={<GridCloseIcon />}
              id="row"
              name="row"
              options={rowData}
              disabled={!physicalVault?.physical_vault_id}
              onChange={handleRow}
              getOptionLabel={(option) => option.row_number}
              value={row}
              size="medium"
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t("assignShelves.row")} />
              )}
            />{" "}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              clearIcon={<GridCloseIcon />}
              id="row"
              name="rack"
              disabled={!row?.row_id}
              options={rackData}
              onChange={handleRack}
              getOptionLabel={(option) => option.rack_number}
              value={rack}
              size="medium"
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t("assignShelves.rack")} />
              )}
            />{" "}
          </Grid>
        </Grid>
        <br />
        <Divider />
        <Box py={3}>
          <Grid container justifyContent="center">
            {/* Shelves Available Section */}
            <Grid item xs={5.5}>
              <Paper elevation={0}>
                <Box pt={2}>
                  <Box display="flex" alignItems="center" ml={2}>
                    <Checkbox
                      checked={checkAll}
                      onChange={() => {
                        const data = selectedAvailabledata.slice(
                          0,
                          rowsPerPage
                        );
                        if (checkAvailShelves.length === 0) {
                          setCheckAll(true);
                          setCheckAvailShelves(data);
                        } else {
                          setCheckAll(false);
                          setCheckAvailShelves([]);
                        }
                      }}
                    />
                    <Box ml={2}>
                      <Typography variant="subtitle1">
                        {t("assignShelves.shelvesAvailable")}
                      </Typography>
                      <Typography variant="body2">
                        {checkAvailShelves?.length > 0
                          ? checkAvailShelves?.length
                          : no_of_records}
                        /
                        {selectedAvailabledata?.length > 0
                          ? selectedAvailabledata?.length
                          : no_of_records}{" "}
                        {t("assignShelves.selected")}
                      </Typography>
                    </Box>
                  </Box>
                  <br />
                  <MUIDataTable
                    options={options}
                    columns={columns}
                    data={selectedAvailabledata?.sort((a, b) =>
                      a?.location?.localeCompare(b?.location)
                    )}
                  />
                </Box>
              </Paper>
            </Grid>

            {/* Arrow Buttons Section */}
            <Grid
              item
              xs={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Stack justifyContent="center" gap={spacing.gap}>
                <Button variant="outlined" onClick={() => addToAssignShelves()}>
                  <ArrowRight />
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => removeFromAssignShelves()}
                >
                  <ArrowLeft />
                </Button>
              </Stack>
            </Grid>

            {/* Assigned Shelves Section */}
            <Grid item xs={5.5}>
              <Paper elevation={0}>
                <Box pt={2}>
                  <Stack pb={2} mx={1} direction={"flex"}>
                    <Box display="flex" alignItems="center" ml={2}>
                      <Checkbox
                        checked={checkAssignAll}
                        onChange={() => {
                          const data = selectedAssignedData.slice(
                            0,
                            rowsPerPageAssign
                          );
                          if (checkAssignShelves.length === 0) {
                            setCheckAssignAll(true);
                            setCheckAssignShelves(data);
                            setReleaseData(data);
                          } else {
                            setCheckAssignAll(false);
                            setCheckAssignShelves([]);
                            setReleaseData([]);
                          }
                        }}
                      />
                      <Box ml={2}>
                        <Typography variant="subtitle1">
                          {t("assignShelves.assignedShelves")}{" "}
                        </Typography>
                        <Typography variant="body2">
                          {checkAssignShelves?.length > 0
                            ? checkAssignShelves?.length
                            : no_of_records}
                          /
                          {selectedAssignedData?.length > 0
                            ? selectedAssignedData?.length
                            : no_of_records}{" "}
                          {t("assignShelves.selected")}
                        </Typography>
                      </Box>
                    </Box>
                    <br />
                  </Stack>
                  <MUIDataTable
                    options={assignOptions}
                    columns={assignColumns}
                    data={selectedAssignedData?.sort((a, b) =>
                      a?.location?.localeCompare(b?.location)
                    )}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt={4} display="flex" gap={2}>
        <Button
          variant="outlined"
          onClick={() => {
            setCancelClick(true);
            if (saveChanges) {
              setOpenChangesModal(true);
            } else {
              navigate(-1);
            }
          }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => releaseSlotsCheck()}
          disabled={enableReleaseSlot}
        >
          {t("assignShelves.releaseSlots")}
        </Button>
        <Button
          variant="outlined"
          disabled={enableShelves}
          onClick={() => fetchShelves()}
        >
          {t("assignShelves.shelves")}
        </Button>
        <Button
          disabled={enableSave}
          onClick={() => checkShelfDuration()}
          variant="contained"
        >
          {t("common.save")}
        </Button>
      </Box>
      {openReleaseAlertModalOne && (
        <ConfirmPopup
          modalPopupOpen={openReleaseAlertModalOne}
          message={warnings}
          handleNo={() => {
            setOpenReleaseAlertModalOne(false);
            setReleaseClick(false);
          }}
          handleYes={() => releaseYes()}
          showCancel={false}
          showNo={true}
        />
      )}

      {openReleaseAlertModaltwo && (
        <ConfirmPopup
          modalPopupOpen={openReleaseAlertModaltwo}
          message={warnings}
          handleNo={() => fetchReleaseSlot(N_CHECKER)}
          handleYes={() => fetchReleaseSlot(Y_CHECKER)}
          showCancel={false}
          showNo={true}
        />
      )}
      {openChangesModal && (
        <ConfirmPopup
          modalPopupOpen={openChangesModal}
          message={t("containerProcessing.picking.saveErr")}
          handleYes={() => checkShelfDuration()}
          handleCancel={() => setOpenChangesModal(false)}
          handleNo={() => {
            if (cancelClick) {
              navigate(-1);
            }
            if (releaseClick) {
              releaseSlots();
            }
            setSaveChanges(false);
            dispatch(setHaveChanges(false));

            setEnableSave(true);
            setOpenChangesModal(false);
          }}
          showCancel={true}
          showNo={true}
        />
      )}
      {showPrompt && (
        <ConfirmPopup
          modalPopupOpen={showPrompt}
          handleYes={() => checkShelfDuration()}
          handleNo={handleConfirmationOnNo}
          showNo={true}
          handleCancel={handleConfirmationOnCancel}
          showCancel={true}
        />
      )}
      {openAssignShelvesModal && (
        <AssignShelvesModal
          isAssignModalOpen={openAssignShelvesModal}
          onClose={() => setOpenAssignShelvesModal(false)}
          selectedShelves={editdata}
          editAssignData={editAssignData}
          setEditAssignData={setEditAssignData}
          acceptRows={acceptRows}
        />
      )}
    </Stack>
  );
};

export default AssignShelves;
