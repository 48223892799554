import spacing from "styles/spacing";

export const BoxStyle = {
  display: "flex",
  flexDirection: "column"
};

export const BypassStyle = {
  borderRadius: "8px",
  mt: "5px",
  boxShadow:
    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
};

export const MDRPickupFormStyles = {
  heightStyle: { height: "32px" },
  instruction: {
    width: "378px",
    color: "var(--color-im-grey)"
  },
  uploadFileStyle: {
    display: "none"
  },
  tooltipStyle: {
    marginTop: "15px"
  },
  instructionMain: {
    paddingLeft: spacing.verticalMargin16,
    paddingRight: spacing.verticalMargin16
  },
  bottomButtons: {
    paddingLeft: spacing.verticalMargin16,
    marginBottom: "20px !important"
  },
  rejectButton: {
    marginRight: spacing.horizontalMargin8
  },
  readOnlyTextField: {
    width: "310px",
    display: "flex",
    flexDirection: "column",
    pt: "0px"
  },
  cardContent: {
    padding: "0px",
    "&:last-child": { paddingBottom: 0 }
  },
  overflow: {
    overflow: "hidden"
  },
  DatePickerField: {
    width: "100%",
    borderRadius: "8px"
  },
  weightStyle: {
    width: "160px"
  },
  gridDetails: {
    paddingLeft: spacing.verticalMargin16,
    paddingRight: spacing.verticalMargin16
  },
  weightDropdownStyle: {
    width: "120px"
  },
  cancelWraning: {
    mt: spacing.horizontalMargin20
  },
  arrow: {
    color: "var(--color-im-grey-38)",
    opacity: "0.9"
  },
  cutomTooltipStyle: {
    background: "var(--color-im-grey-38)",
    opacity: "0.9",
    borderRadius: "9px",
    maxWidth: "264px",
    padding: "4px 8px",
    color: "#FFF",
    /* components/tooltip-label */
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "14px" /* 140% */
  },
  radioGroup: {
    ml: "11px"
  },
  radioButton: {
    marginRight: "20px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHght: "150%" /* 24px */,
    letterSpacing: "0.15px"
  },
  dialogModal: {
    width: "1140px",
    maxWidth: "100%"
  },
  FieldWidthMedium: {
    width: "310px"
  },
  pickupInstructions: {
    width: "640px"
  },
  challengeBox: {
    width: "475px"
  },
  blueColor: {
    color: "var(--color-im-dark-blue)"
  },
  trackingRequirement: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px"
  },
  successBox: {
    display: "flex",
    alignItems: "center"
  },
  StatusHeading: {
    opacity: "60%",
    fontSize: "12px",
    letterSpacing: "0.15px",
    fontFamily: "Inter"
  },
  StatusValue: {
    fonstSize: "14px",
    color: "var(--color-back)",
    opacity: 0.8,
    fontFamily: "Inter",
    letterSpacing: "0.15px"
  },
  DestructionIdTest: {
    fontSize: "26px",
    textAlign: "right"
  },
  Heading: {
    color: "var(--color-black )"
  },
  Tooltip: {
    width: "242px",
    display: "flex",
    alignContent: "center",
    flexDirection: "column"
  },
  TextDesign: {
    marginTop: "10px"
  },
  TooltipDespoition: {
    minWidth: "50px"
  },
  TitleStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  personnelTitleStyle: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  personnelLabelBox: {
    ...BoxStyle,
    alignItems: "flex-start",
    gap: "5.5px",
    alignSelf: "stretch",
    paddingTop: "7px",
    marginLeft: "30px"
  },
  BranchLabelBox: {
    ...BoxStyle,
    width: "310px"
  },
  BranchLabelBoxNonTextField: {
    ...BoxStyle,
    alignItems: "flex-start",
    gap: "5.5px",
    alignSelf: "stretch",
    paddingTop: "7px"
  },
  BranchLabel: {
    color: "var(--color-back)",
    opacity: 0.6,
    fontFamily: "Inter",
    lineHeight: "12px" /* 100% */,
    letterSpacing: "0.15px"
  },
  branchName: {
    color: "var(--color-back)",
    opacity: 0.8,
    fontFamily: "Inter",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.15px"
  },
  statusBox: {
    ...BoxStyle,
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "310px"
  },
  notifyViaEmail: {
    width: "606px"
  },
  buttonStyle: {
    marginBottom: "20px",
    marginTop: "12px"
  },
  widthPercentage: { width: "100%" },
  bypassButton: {
    ...BypassStyle
  },
  cancelButton: {
    ...BypassStyle,
    mr: "5px"
  },
  successWithCheckIcon: { display: "flex", flexDirection: "row" },
  SuccessColor: { color: "#4CAF50" },
  failedAuthMsg: { marginBottom: "20px" },
  paddingStyle: { padding: "20px 16px 20px 16px" },
  paddingTitleStyle: { padding: "16px 16px 16px 16px" },
  dividerPadding: { marginLeft: "-16px", marginRight: "-16px" },
  fontStyle: { fontFamily: "Inter" }
};
