import { Stack } from "@mui/material";
import DiscrepancyUpdateLayout from "components/discrepancy-management/discrepancy-update/DiscrepancyUpdateLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";

const DiscrepancyUpdate = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("discrepancyUpdate.title")} />
      <DiscrepancyUpdateLayout />
    </Stack>
  );
};

export default DiscrepancyUpdate;
