import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField
} from "@mui/material";
import { FullCircularLoader, SelectField } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import ModalPopup from "components/core/modal-components/ModalPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getEmployeeDetails, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  downloadReportOptions,
  errorMsgs
} from "utils/constants";
import {
  tableConstants,
  transportationConstants
} from "utils/constants/transportation-module/TransportationConstants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getUserName,
  printReport
} from "utils/helpers";
import RouteDetailsEditableModal from "./RouteDetailsEditableModal";
import RouteTimeTable from "./RouteTimeTable";
import { RouteTimeTableStyles } from "./RouteTimeTableStyles.Styles";
import { RouteTransportationInformation } from "./RouteTransportationInformation";

const RouteDetailsLayout = () => {
  const [currentFormState, setCurrentFormState] = useState(
    transportationConstants.formStatus.initial
  );
  const [selectedRouteForEdit, setSelectedRouteForEdit] = useState(null);
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [selectedRouteCode, setSelectedRouteCode] = useState(null);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [routeTypes, setRouteTypes] = useState({ data: [], isLoading: false });
  const [routeCodeList, setRouteCodeList] = useState({
    data: [],
    isLoading: false
  });
  const [selectedRouteCodeDetails, setSelectedRouteCodeDetails] = useState({
    data: null,
    isLoading: false
  });
  const [routeScheduleData, setRouteScheduleData] = useState({
    data: [],
    isLoading: false
  });
  const [routeTableData, setRouteTableData] = useState(
    tableConstants.defaultTableRowData
  );
  const [transportationMode, setTransportationMode] = useState(
    transportationConstants.mode.ironMountain
  );
  const [isNewBtnClicked, setNewBtnClicked] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleList, setVehicleList] = useState({
    data: [],
    isLoading: false
  });
  const [vendorsList, setVendorsList] = useState({
    data: [],
    isLoading: false
  });
  const [employees, setEmployees] = useState({ data: [], isLoading: false });
  const [employeeData, setEmployeeData] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState(null);
  const [scheduleError, setScheduleError] = useState(null);
  const [scheduleErrorMsg, setScheduleErrorMsg] = useState(null);
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [routeCodeSelected, setRouteCodeSelected] = useState(null);
  const { currentBranch, auth } = useSelector(selectAuth);

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  useEffect(() => {
    if (mainDistrictId) {
      fetchRouteCodesList();
      fetchVehicleDetails();
      fetchRouteTypes();
      fetchVendorsList();
      getEmployeesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainDistrictId]);

  useEffect(() => {
    if (throwError || isShowSuccessPopup) {
      window.scrollTo(0, 0);
    }
    //eslint-disable-next-line
  }, [throwError, throwErrorMessage, isShowSuccessPopup]);

  const getResponseData = async (requestBody, url, resultSets) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(
      response,
      resultSets,
      response.data.docId
    );
    return data;
  };

  const initialRouteValues = {
    routeCode: "",
    name: "",
    routeTypeCode: transportationConstants?.defaultRouteType?.value,
    minutesPerStopGoal: "",
    secondsPerContainerGoal: "",
    transportationVendorId: ""
  };

  const routeScheduleFormValues = {
    dayOfWeekCode: "",
    vehicleNumber: "",
    startTime: "",
    departTime: "",
    arriveTime: "",
    stopTime: "",
    serviceRepEmployeeId: ""
  };
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(false);
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    routeDetailsForm.setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    if (currentFormState === transportationConstants.formStatus.search) {
      setCurrentFormState(transportationConstants.formStatus.edit);
    }

    if (typeof value === "string" && value.trim() !== "") {
      setIsFieldUpdated(true);
    }
  };

  const routeDetailsForm = useFormik({
    initialValues: initialRouteValues,
    onSubmit: handleSubmit,
    handleChange: handleFieldChange
  });

  const routeScheduleForm = useFormik({
    initialValues: routeScheduleFormValues,
    onSubmit: handleSubmit
  });

  const convertTimeForTableView = (time) => {
    if (!time || !dayjs(time, "HHmm").isValid()) {
      return t("routeDetails.invalidTime");
    }
    return dayjs(time, "HHmm").format("HH:mm");
  };

  /**
   * @description fetch the route code type list
   */
  const fetchRouteTypes = async () => {
    setIsLoading(true);
    setRouteTypes({ ...routeTypes, isLoading: true });
    try {
      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId
      });
      const data = await getResponseData(
        requestBody,
        `${CF_URLS.routeDetails.getRouteTypes}`,
        1
      );

      if (!data?.error && data?.data[0] && data?.data[0]?.length > 0) {
        const formattedRouteTypeOptArr = data?.data[0].map((rType) => ({
          value: rType.route_type_code.trim(),
          label: rType.route_type_code.trim()
        }));
        formattedRouteTypeOptArr.sort((a, b) => a.label.localeCompare(b.label));
        setRouteTypes({ data: formattedRouteTypeOptArr, isLoading: false });
      } else {
        setRouteTypes({ ...routeTypes, isLoading: false });
      }
      setIsLoading(false);
    } catch (error) {
      setRouteTypes({ ...routeTypes, isLoading: false });
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
      setIsLoading(false);
    }
  };

  /**
   * @description fetch route code list
   */
  const fetchRouteCodesList = async () => {
    setRouteCodeList({ ...routeCodeList, isLoading: true });
    try {
      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId
      });
      const data = await getResponseData(
        requestBody,
        `${CF_URLS.routeDetails.getRouteCodes}`,
        1
      );

      if (!data?.error && data?.data[0]?.length > 0) {
        const formattedRouteCodeArr = data.data[0].map((route) => ({
          value: route.route_code,
          label: route.route_code
        }));

        formattedRouteCodeArr.sort((a, b) => a.label.localeCompare(b.label));
        setRouteCodeList({ data: formattedRouteCodeArr, isLoading: false });
      } else {
        setRouteCodeList({ ...routeCodeList, isLoading: false });
      }
    } catch (error) {
      setRouteCodeList({ ...routeCodeList, isLoading: false });
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    }
  };

  const handleRouteCodeChange = (e) => {
    const selectedValue = e.target.value;

    if (
      currentFormState === transportationConstants.formStatus.edit &&
      isFieldUpdated
    ) {
      setRouteCodeSelected(selectedValue);
      setShowConfirmPopup(true);
    } else {
      setSelectedRouteCode(selectedValue);
      setRouteTableData(tableConstants.defaultTableRowData);
      routeDetailsForm.setValues(initialRouteValues);
      setCurrentFormState(transportationConstants.formStatus.search);
      fetchRouteDetails(selectedValue);
      setThrowError(false);
      setModalError(false);
      setScheduleError(false);
    }
  };

  /**
   * @description fetch selected route details
   */
  const fetchRouteDetails = async (routeCode) => {
    setIsLoading(true);
    try {
      setRouteScheduleData({ ...routeScheduleData, isLoading: true });
      setSelectedRouteCodeDetails({
        ...selectedRouteCodeDetails,
        isLoading: true
      });

      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        route_code: routeCode
      });

      const data = await getResponseData(
        requestBody,
        `${CF_URLS.routeDetails.selectedCodeRouteDetails}`,
        2
      );
      setIsLoading(false);
      if (!data?.error) {
        let mode;
        if (data?.data[0]?.length > 0) {
          const selectedRoute = data.data[0][0];
          setSelectedRouteCodeDetails({
            data: selectedRoute,
            isLoading: false
          });
          const updatedValues = {
            name: selectedRoute?.name,
            routeTypeCode: selectedRoute?.route_type_code,
            minutesPerStopGoal: selectedRoute?.minutes_per_stop_goal,
            secondsPerContainerGoal: selectedRoute?.seconds_per_container_goal,
            transportationVendorId: selectedRoute?.transportation_vendor_id,
            timestamp: selectedRoute?.timestamp
          };

          routeDetailsForm.setValues(updatedValues);
          mode =
            selectedRoute.transportation_vendor_id === ""
              ? transportationConstants.mode.ironMountain
              : transportationConstants.mode.thirdParty;
          setTransportationMode(mode);
        }

        const selectedRoute = data?.data[1]?.map((row) => ({
          ...row,
          isEdited: false
        }));

        setRouteScheduleData({ data: selectedRoute, isLoading: false });
        if (selectedRoute?.length > 0) {
          const fillableGridObj =
            mode === transportationConstants.mode.ironMountain
              ? [...tableConstants.defaultTableRowData]
              : [...tableConstants.defaultTableRowDataThirdParty];

          let gridObj = JSON.parse(JSON.stringify(fillableGridObj));
          let serviceEmployees = employees?.data;
          let selectedEmployee = null;
          const populatedTableDataArr = gridObj.map((routeTableItem) => {
            const matchedScheduleItem = selectedRoute.find(
              (scheduleItem) =>
                scheduleItem.day_of_week_code ===
                routeTableItem.day_of_week_code
            );

            if (matchedScheduleItem) {
              if (
                serviceEmployees &&
                matchedScheduleItem.service_rep_employee_id
              ) {
                selectedEmployee = serviceEmployees.filter(
                  (emp) =>
                    emp.value === matchedScheduleItem.service_rep_employee_id
                );
              }
              routeTableItem.start_time = convertTimeForTableView(
                matchedScheduleItem.start_time
              );
              routeTableItem.depart_time = convertTimeForTableView(
                matchedScheduleItem.depart_time
              );
              routeTableItem.arrive_time = convertTimeForTableView(
                matchedScheduleItem.arrive_time
              );
              routeTableItem.stop_time = convertTimeForTableView(
                matchedScheduleItem.stop_time
              );
              routeTableItem.vehicle_number =
                matchedScheduleItem.vehicle_number;
              routeTableItem.service_rep_employee_id =
                matchedScheduleItem.service_rep_employee_id;
              routeTableItem.service_rep_employee =
                selectedEmployee && selectedEmployee[0]?.label;
              routeTableItem.timestamp = matchedScheduleItem.timestamp;
            }
            return routeTableItem;
          });
          setRouteTableData(populatedTableDataArr);
        }
      } else {
        // Handle error response
        setThrowErrorMessage(findErrorMessage(data.error));
        setThrowError(true);
      }
    } catch (error) {
      // Handle exceptions
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    }
  };

  /**
   * @descriptionfetch transportation vendor list
   */

  const fetchVendorsList = async () => {
    setVendorsList({ ...routeTypes, isLoading: true });
    try {
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.routeDetails.getVendors}`,
        1
      );
      if (!data?.error && data?.data[0] && data?.data[0]?.length > 0) {
        const formattedVendorList = data.data[0].map((vendor) => ({
          value: vendor.transportation_vendor_id,
          label: vendor.name
        }));

        formattedVendorList.sort((a, b) => a.label.localeCompare(b.label));
        setVendorsList({ data: formattedVendorList, isLoading: false });
      } else {
        setVendorsList({ ...routeTypes, isLoading: false });
      }
    } catch (error) {
      setVendorsList({ ...routeTypes, isLoading: false });
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    }
  };

  /**
   * @description fetches the vehicle data list which populates the vehicle drop down on edit
   */
  const fetchVehicleDetails = async () => {
    setVehicleList({ ...vehicleList, isLoading: true });
    try {
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.routeDetails.getVehicleDetails}`,
        1
      );

      if (!data?.error && data?.data[0]?.length > 0) {
        setVehicles(data?.data[0]);
        const formattedOptions = data?.data[0].map((vehicle) => ({
          label: `${vehicle.vehicle_number} ${vehicle.vehicle_type_code}`,
          value: vehicle.vehicle_number
        }));
        formattedOptions.sort((a, b) => a.label.localeCompare(b.label));
        setVehicleList({ data: formattedOptions, isLoading: false });
      } else {
        setVehicleList({ ...vehicleList, isLoading: false });
      }
    } catch (error) {
      setVehicleList({ ...vehicleList, isLoading: false });
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    }
  };

  // loading employee data from firestore => employee collection
  const getEmployeesData = async () => {
    try {
      const result = await getEmployeeDetails();
      const response = result?.map((res) => {
        return {
          label: `${res?.last_name}, ${res?.first_name}`,
          value: res?.employee_id
        };
      });

      response.sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeData(result);
      setEmployees({ data: response, isLoading: false });
    } catch (error) {
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    }
  };

  //check for bad drivers
  const getEmployeeInfo = async (employeeId) => {
    try {
      const employee = employeeData.find(
        (emp) => emp.employee_id === employeeId?.value
      );
      const isActive = employee?.active_flag === "Y" ? true : false;
      return { active: isActive };
    } catch (error) {
      setThrowErrorMessage(error || findErrorMessage(ERROR_TYPES.ISSUE));
      return { active: false };
    }
  };

  const isSaveButtonDisabled = () => {
    if (
      currentFormState === transportationConstants.formStatus.search ||
      currentFormState === transportationConstants.formStatus.initial
    ) {
      return true;
    } else if (
      currentFormState === transportationConstants.formStatus.edit ||
      currentFormState === transportationConstants.formStatus.new
    ) {
      return false;
    } else {
      return true;
    }
  };
  const isDeleteBtnDisabled = () => {
    if (
      currentFormState === transportationConstants.formStatus.initial ||
      currentFormState === transportationConstants.formStatus.edit ||
      currentFormState === transportationConstants.formStatus.new
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isNewBtnDisabled = () => {
    if (
      currentFormState === transportationConstants.formStatus.search ||
      currentFormState === transportationConstants.formStatus.initial
    ) {
      return false;
    } else if (isNewBtnClicked) {
      return true;
    } else {
      return false;
    }
  };

  const isCancelBtnDisabled = () => {
    if (
      currentFormState === transportationConstants.formStatus.search ||
      currentFormState === transportationConstants.formStatus.initial
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPrintButtonDisabled = () => {
    if (currentFormState === transportationConstants.formStatus.search) {
      return false;
    } else {
      return true;
    }
  };

  const isEditIconDisabled = () => {
    const {
      routeCode,
      name,
      routeTypeCode,
      secondsPerContainerGoal,
      minutesPerStopGoal,
      transportationVendorId
    } = routeDetailsForm.values;
    if (currentFormState === transportationConstants.formStatus.new) {
      if (transportationMode === transportationConstants.mode.thirdParty) {
        if (
          !routeCode ||
          !name ||
          !routeTypeCode ||
          !secondsPerContainerGoal ||
          !transportationVendorId
        )
          return true;
      } else if (
        !routeCode ||
        !name ||
        !routeTypeCode ||
        !secondsPerContainerGoal ||
        !minutesPerStopGoal
      )
        return true;
    } else {
      return false;
    }
    return false;
  };
  const handleNewBtnClick = () => {
    if (currentFormState === transportationConstants.formStatus.edit) {
      if (isFieldUpdated) {
        setShowConfirmPopup(true);
      }
      return;
    }
    setThrowError(false);
    setRouteTableData(tableConstants.defaultTableRowData);
    setCurrentFormState(transportationConstants.formStatus.new);
    setNewBtnClicked(true);
    setSelectedRouteCode(null);
    routeDetailsForm.setValues(initialRouteValues);
    routeScheduleForm.setValues(routeScheduleFormValues);
    setTransportationMode(transportationConstants.mode.ironMountain);
  };

  const handleCancelBtnClicked = () => {
    setThrowError(false);
    if (currentFormState === transportationConstants.formStatus.edit) {
      if (!isFieldUpdated) {
        routeDetailsForm.setValues(initialRouteValues);
        routeScheduleForm.setValues(routeScheduleFormValues);
        setSelectedRouteCode(null);
        setCurrentFormState(transportationConstants.formStatus.initial);
      }
      if (isFieldUpdated) {
        setShowConfirmPopup(true);
      }
    }
    if (currentFormState === transportationConstants.formStatus.new) {
      if (!isFieldUpdated) {
        setCurrentFormState(transportationConstants.formStatus.initial);
        setRouteTableData(tableConstants.defaultTableRowData);
        routeDetailsForm.setValues(initialRouteValues);
        routeScheduleForm.setValues(routeScheduleFormValues);
        setSelectedRouteCode(null);
        setNewBtnClicked(false);
      }
      if (isFieldUpdated) {
        setShowConfirmPopup(true);
      }
    }
  };

  const saveSingleRouteSchedule = async (scheduleInfoBody) => {
    try {
      const response = await getResponseData(
        scheduleInfoBody,
        `${CF_URLS.routeDetails.saveNewRouteScheduleInfo}`,
        1
      );
      return response.data;
    } catch (error) {
      return { error: error.message };
    }
  };

  const editSingleRouteSchedule = async (scheduleInfoBody, isInsert) => {
    const endpoint = isInsert
      ? `${CF_URLS.routeDetails.updateRouteScheduleInfo}`
      : `${CF_URLS.routeDetails.saveNewRouteScheduleInfo}`;
    try {
      const response = await getResponseData(scheduleInfoBody, endpoint, 1);
      return response.data;
    } catch (error) {
      return { error: error.message };
    }
  };

  const convertTimeToSaveFormat = (time) => {
    return time ? dayjs(time, "HH:mm").format("HHmm") : "";
  };

  const handleSaveButtonValidation = () => {
    const {
      routeCode,
      name,
      routeTypeCode,
      secondsPerContainerGoal,
      minutesPerStopGoal,
      transportationVendorId
    } = routeDetailsForm.values;

    try {
      const isDuplicateLabel = routeCodeList?.data?.some(
        (item) => item.label.trim() === name.trim()
      );
      if (
        isDuplicateLabel &&
        currentFormState === transportationConstants.formStatus.new
      ) {
        errorMsg(errorMsgs.errorCode10085);
        return true;
      }
      if (routeCode === "") {
        errorMsg(errorMsgs.errorCode20295, t("routeDetails.code"));
        return true;
      }
      if (routeCode?.length > 6) {
        errorMsg(errorMsgs.errorCode20289, "6");
        return true;
      }
      if (!name || name?.trim() === "") {
        errorMsg(errorMsgs.errorCode20295, t("routeDetails.name"));
        return true;
      }
      if (routeTypeCode === "") {
        errorMsg(errorMsgs.errorCode20295, t("routeDetails.routeType"));
        return true;
      }
      if (transportationMode === transportationConstants.mode.ironMountain) {
        if (minutesPerStopGoal === "") {
          errorMsg(errorMsgs.errorCode20295, t("routeDetails.stopGoal"));
          return true;
        }

        if (
          parseInt(minutesPerStopGoal) < transportationConstants.numbers.zero ||
          parseInt(minutesPerStopGoal) >=
            transportationConstants.numbers.thousand
        ) {
          errorMsg(
            errorMsgs.errorCode20547,
            t("routeDetails.stopGoal"),
            t(transportationConstants.numbers.zero),
            t(transportationConstants.numbers.thousand)
          );
          return true;
        }
      }
      if (!secondsPerContainerGoal || secondsPerContainerGoal === "") {
        errorMsg(errorMsgs.errorCode20295, t("routeDetails.containerGoal"));
        return true;
      }
      if (
        parseInt(secondsPerContainerGoal) <
          transportationConstants.numbers.zero ||
        parseInt(secondsPerContainerGoal) >=
          transportationConstants.numbers.thousand
      ) {
        errorMsg(
          errorMsgs.errorCode20547,
          t("routeDetails.containerGoal"),
          t(transportationConstants.numbers.zero),
          t(transportationConstants.numbers.thousand)
        );
        return true;
      }
      if (transportationMode === transportationConstants.mode.thirdParty) {
        if (!transportationVendorId || transportationVendorId === "") {
          errorMsg(errorMsgs.errorCode20295, t("routeDetails.vendor"));
          return true;
        }
      }

      const hasDepartTime = routeTableData?.some((row) => {
        return row.depart_time !== "";
      });
      if (!hasDepartTime) {
        errorMsg(errorMsgs.errorCode20296, t("routeDetails.routeType"));
        return true;
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(error || findErrorMessage(ERROR_TYPES.ISSUE));
      return true;
    }
    return false;
  };

  /**
   * @description insert or update route and schedule
   */

  const handleSaveEditHeader = async () => {
    try {
      let StopGoal = routeDetailsForm.values.minutesPerStopGoal;
      let containerGoal = routeDetailsForm.values.secondsPerContainerGoal;
      const params = {
        name: routeDetailsForm.values.name,
        route_type_code: routeDetailsForm.values.routeTypeCode,
        minutes_per_stop_goal: StopGoal,
        seconds_per_container_goal: containerGoal,
        transportation_vendor_id: routeDetailsForm.values.transportationVendorId
      };
      let headerInfoBody = {};
      if (currentFormState === transportationConstants.formStatus.new) {
        headerInfoBody = JSON.stringify({
          main_district_id: mainDistrictId,
          route_code: routeDetailsForm.values.routeCode,
          ...params
        });
      } else {
        const { timestamp } = routeDetailsForm.values;
        headerInfoBody = JSON.stringify({
          main_district_id: mainDistrictId,
          route_code: selectedRouteCode,
          ts1: timestamp,
          ...params
        });
      }
      let headerEndpoint =
        currentFormState === transportationConstants.formStatus.new
          ? CF_URLS.routeDetails.saveNewRouteHeaderInfo
          : CF_URLS.routeDetails.updateRouteHeaderInfo;

      let headerResponse = await getResponseData(
        headerInfoBody,
        headerEndpoint,
        1
      );

      setIsLoading(false);
      if (headerResponse.error) {
        setIsLoading(false);
        errorMsg(headerResponse.error);
        return;
      }
      return headerResponse;
    } catch (error) {
      setIsLoading(false);
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    }
  };

  const handleAllPromisesSaveEdit = async (promises) => {
    try {
      const results = await Promise.all(promises);
      const allSuccess = !results.some((resultArray) => {
        const errorObject = resultArray[0];
        return errorObject && errorObject.error;
      });

      if (allSuccess) {
        if (currentFormState === transportationConstants.formStatus.new) {
          setSuccessMessage(t("routeDetails.insertSuccess"));
        } else {
          setSuccessMessage(t("routeDetails.updateSuccess"));
          setSelectedRouteCode(routeDetailsForm.values?.routeCode);
        }
        setIsShowSuccessPopup(true);
        setThrowErrorMessage(null);
        setIsLoading(false);
        setNewBtnClicked(false);
        fetchRouteCodesList();
        routeDetailsForm.setValues(initialRouteValues);
        routeScheduleForm.setValues(routeScheduleFormValues);
        setCurrentFormState(transportationConstants.formStatus.initial);
      } else {
        const errorResult = results.find((result) => result && result.error);

        if (errorResult && errorResult.error === errorMsgs.errorCode532) {
          errorMsg(errorMsgs.errorCode10001);
          setThrowError(true);
        } else if (
          errorResult &&
          errorResult.error === errorMsgs.errorCode547
        ) {
          errorMsg(errorMsgs.errorCode20301);
          setThrowError(true);
        } else {
          errorMsg(
            errorResult
              ? errorResult.error
              : findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        }
      }
    } catch (error) {
      // Handle error if any promise rejects
      setThrowErrorMessage(error || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSaveEditRoute = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const validationError = handleSaveButtonValidation();
      if (validationError) {
        setThrowError(true);
        return;
      }
      if (currentFormState === transportationConstants.formStatus.edit) {
        const canUpdateRoute = await checkCustomersAndHandleError(
          t("routeDetails.updated")
        );

        if (canUpdateRoute) {
          setThrowError(true);
          return;
        }
      }

      if (transportationMode === transportationConstants.mode.ironMountain) {
        const hasOverlapTime = routeTableData?.some((row) => {
          if (
            row.arrive_time === "" ||
            row.depart_time === "" ||
            row.stop_time === ""
          ) {
            return false;
          }

          return (
            row.arrive_time <= row.depart_time ||
            row.stop_time <= row.arrive_time
          );
        });
        if (hasOverlapTime) {
          const msg = await errorMsg(errorMsgs.errorCode20297, "");
          setModalErrorMsg(msg);
          setModalError(true);
          return;
        }
      }
      const editedRows = routeTableData?.filter(
        (row) => row.depart_time !== ""
      );
      if (editedRows.length > 0) {
        let isTrue = false;
        editedRows.map(async (row) => {
          const arriveMinuteValue =
            row.arrive_time && dayjs(row.arrive_time, "HHmm").minute();
          const departMinuteValue =
            row.depart_time && dayjs(row.depart_time, "HHmm").minute();
          const stopMinuteValue =
            row.stop_time && dayjs(row.stop_time, "HHmm").minute();
          const startMinuteValue =
            row.start_time && dayjs(row.start_time, "HHmm").minute();
          if (
            (row.arrive_time && arriveMinuteValue % 15 !== 0) ||
            (row.depart_time && departMinuteValue % 15 !== 0) ||
            (row.stop_time && stopMinuteValue % 15 !== 0) ||
            (row.start_time && startMinuteValue % 15 !== 0)
          ) {
            errorMsg(errorMsgs.errorCode20445);
            setThrowError(true);
            isTrue = true;
            return null;
          }
        });
        if (isTrue) {
          return;
        }
      }
      const headerResponse = await handleSaveEditHeader();
      if (headerResponse?.data[0][0]?.timestamp) {
        if (editedRows.length > 0) {
          setIsLoading(true);
          const promises = editedRows.map(async (row) => {
            const arriveMinuteValue =
              row.arrive_time && dayjs(row.arrive_time, "HHmm").minute();
            const departMinuteValue =
              row.depart_time && dayjs(row.depart_time, "HHmm").minute();
            const stopMinuteValue =
              row.stop_time && dayjs(row.stop_time, "HHmm").minute();
            const startMinuteValue =
              row.start_time && dayjs(row.start_time, "HHmm").minute();
            if (
              (row.arrive_time && arriveMinuteValue % 15 !== 0) ||
              (row.depart_time && departMinuteValue % 15 !== 0) ||
              (row.stop_time && stopMinuteValue % 15 !== 0) ||
              (row.start_time && startMinuteValue % 15 !== 0)
            ) {
              errorMsg(errorMsgs.errorCode20445);
              setThrowError(true);
              return null;
            }

            row.arrive_time = convertTimeToSaveFormat(row.arrive_time);
            row.depart_time = convertTimeToSaveFormat(row.depart_time);
            row.stop_time = convertTimeToSaveFormat(row.stop_time);
            row.start_time = convertTimeToSaveFormat(row.start_time);
            const { day, timestamp, ...rest } = row;
            let routeCode = routeDetailsForm.values.routeCode;
            let scheduleInfoBody = JSON.stringify({
              main_district_id: mainDistrictId,
              route_code:
                currentFormState === transportationConstants.formStatus.new
                  ? routeCode
                  : selectedRouteCode,
              ...rest
            });

            const isInsert = routeScheduleData?.data?.find(
              (route) => route.day_of_week_code === row.day_of_week_code
            );

            if (currentFormState === transportationConstants.formStatus.new) {
              return saveSingleRouteSchedule(scheduleInfoBody);
            } else {
              const editPayload = JSON.stringify({
                main_district_id: mainDistrictId,
                route_code: selectedRouteCode,
                ...rest,
                ts1: timestamp
              });
              return editSingleRouteSchedule(editPayload, isInsert);
            }
          });
          if (promises) {
            Promise.all(promises).then((results) => {
              const filteredPromises = results.filter(
                (result) => result !== null
              );
              if (filteredPromises.length > 0) {
                handleAllPromisesSaveEdit(filteredPromises);
              }
            });
          }
        }
      } else if (
        headerResponse?.data[0][0]?.error === errorMsgs.errorCode2627
      ) {
        errorMsg(errorMsgs.errorCode10085);
        setThrowError(true);
      } else {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } catch (error) {
      setThrowErrorMessage(error || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const handleSaveEditRouteOnYes = async () => {
    setIsLoading(true);
    try {
      let routeCode = routeDetailsForm.values.routeCode;
      const editedRows = routeTableData?.filter(
        (row) => row.depart_time !== ""
      );
      if (editedRows.length > 0) {
        let isTrue = false;
        editedRows.map(async (row) => {
          const arriveMinuteValue =
            row.arrive_time && dayjs(row.arrive_time, "HHmm").minute();
          const departMinuteValue =
            row.depart_time && dayjs(row.depart_time, "HHmm").minute();
          const stopMinuteValue =
            row.stop_time && dayjs(row.stop_time, "HHmm").minute();
          const startMinuteValue =
            row.start_time && dayjs(row.start_time, "HHmm").minute();
          if (
            (row.arrive_time && arriveMinuteValue % 15 !== 0) ||
            (row.depart_time && departMinuteValue % 15 !== 0) ||
            (row.stop_time && stopMinuteValue % 15 !== 0) ||
            (row.start_time && startMinuteValue % 15 !== 0)
          ) {
            errorMsg(errorMsgs.errorCode20445);
            setThrowError(true);
            isTrue = true;
            return null;
          }
        });
        if (isTrue) {
          return;
        }
      }
      const headerResponse = await handleSaveEditHeader();
      if (headerResponse?.data[0][0]?.timestamp) {
        if (editedRows.length > 0) {
          setIsLoading(true);
          const promises = editedRows.map(async (row) => {
            const arriveMinuteValue =
              row.arrive_time && dayjs(row.arrive_time, "HHmm").minute();
            const departMinuteValue =
              row.depart_time && dayjs(row.depart_time, "HHmm").minute();
            const stopMinuteValue =
              row.stop_time && dayjs(row.stop_time, "HHmm").minute();
            const startMinuteValue =
              row.start_time && dayjs(row.start_time, "HHmm").minute();
            if (
              (row.arrive_time && arriveMinuteValue % 15 !== 0) ||
              (row.depart_time && departMinuteValue % 15 !== 0) ||
              (row.stop_time && stopMinuteValue % 15 !== 0) ||
              (row.start_time && startMinuteValue % 15 !== 0)
            ) {
              errorMsg(errorMsgs.errorCode20445);
              setThrowError(true);
              return null;
            }

            row.arrive_time = convertTimeToSaveFormat(row.arrive_time);
            row.depart_time = convertTimeToSaveFormat(row.depart_time);
            row.stop_time = convertTimeToSaveFormat(row.stop_time);
            row.start_time = convertTimeToSaveFormat(row.start_time);
            row.start_time = convertTimeToSaveFormat(row.start_time);
            const { day, timestamp, ...rest } = row;
            let scheduleInfoBody = JSON.stringify({
              main_district_id: mainDistrictId,
              route_code:
                currentFormState === transportationConstants.formStatus.new
                  ? routeCode
                  : selectedRouteCode,
              ...rest
            });
            const isInsert = routeScheduleData?.data?.find(
              (route) => route.day_of_week_code === row.day_of_week_code
            );

            if (currentFormState === transportationConstants.formStatus.new) {
              return saveSingleRouteSchedule(scheduleInfoBody);
            } else {
              const editPayload = JSON.stringify({
                main_district_id: mainDistrictId,
                route_code: selectedRouteCode,
                ts1: timestamp,
                ...rest
              });
              return editSingleRouteSchedule(editPayload, isInsert);
            }
          });
          if (promises) {
            Promise.all(promises).then((results) => {
              const filteredPromises = results.filter(
                (result) => result !== null
              );
              if (filteredPromises.length > 0) {
                handleAllPromisesSaveEdit(filteredPromises);
              }
            });
          }
        }
      } else if (
        headerResponse?.data[0][0]?.error === errorMsgs.errorCode2627
      ) {
        errorMsg(errorMsgs.errorCode10085);
        setThrowError(true);
      } else {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } catch (error) {
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
      setIsLoading(false);
    }
  };

  // Check if route has assigned customers
  const checkCustomersAndHandleError = async (action) => {
    setIsLoading(true);
    try {
      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        route_code: selectedRouteCode
      });
      const routeDetailsResponse = await getResponseData(
        requestBody,
        `${CF_URLS.routeDetails.checkForAssignedCustomers}`,
        1
      );
      const distinctCustomerCount =
        routeDetailsResponse.data[0][0]?.distinct_customer_count;
      if (distinctCustomerCount > transportationConstants.numbers.zero) {
        errorMsg(
          errorMsgs.errorCode20366,
          distinctCustomerCount,
          `${t("routeDetails.updateText")} ${action}`
        );
        return true;
      } else if (routeDetailsResponse?.error) {
        setThrowError(true);
        setThrowErrorMessage(routeDetailsResponse.error);
      }
      return false;
    } catch (error) {
      setThrowErrorMessage(error || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // Delete route schedule
  const handleDeleteRoute = async () => {
    try {
      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        route_code: selectedRouteCode
      });
      const canDeleteRoute = await checkCustomersAndHandleError(
        t("routeDetails.deleted")
      );

      if (canDeleteRoute) {
        setThrowError(true);
        setConfirmDeleteModalOpen(false);
        return;
      }
      setIsLoading(true);
      const deleteRouteResponse = await getResponseData(
        requestBody,
        `${CF_URLS.routeDetails.deleteRouteSchedule}`,
        1
      );
      setIsLoading(false);
      const deleteData = deleteRouteResponse?.data[0][0];
      const err =
        deleteData?.error === errorMsgs.errorCode547
          ? errorMsgs.errorCode20301
          : deleteRouteResponse?.data[0][0]?.error;
      if (err) {
        errorMsg(err);
        setThrowError(true);
      } else if (deleteRouteResponse.error) {
        errorMsg(deleteRouteResponse.error);
        setThrowError(true);
      } else {
        setConfirmDeleteModalOpen(false);
        setCurrentFormState(transportationConstants.formStatus.initial);
        setTransportationMode(transportationConstants.ironMountain);
        setRouteTableData(tableConstants.defaultTableRowData);
        routeDetailsForm.setValues(initialRouteValues);
        setSelectedRouteCode(null);
        fetchRouteCodesList();
        setNewBtnClicked(false);
        setSuccessMessage(t("routeDetails.deleteSuccess"));
        setIsShowSuccessPopup(true);
      }
    } catch (error) {
      setThrowErrorMessage(error || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  /**
   * @description  to update the schedule table data
   */
  const handleUpdateScheduleRowData = async () => {
    const cloneOfScheduleTableData = [...routeTableData];
    const parsedScheduleTableData = JSON.parse(
      JSON.stringify(cloneOfScheduleTableData)
    );
    const {
      dayOfWeekCode,
      arriveTime,
      startTime,
      departTime,
      stopTime,
      serviceRepEmployeeId,
      vehicleNumber
    } = routeScheduleForm.values;

    const vehicleTypeCode = vehicles?.find(
      (vehicle) => vehicle?.vehicle_number === vehicleNumber
    );

    for (const route of parsedScheduleTableData) {
      if (dayOfWeekCode === route.day_of_week_code) {
        if (transportationMode === transportationConstants.mode.ironMountain) {
          route.start_time = convertTimeForTableView(startTime);
          route.depart_time = convertTimeForTableView(departTime);
          route.arrive_time = convertTimeForTableView(arriveTime);
          route.stop_time = convertTimeForTableView(stopTime);
          route.vehicle_type_code = vehicleTypeCode?.vehicle_type_code;
          route.vehicle_number = vehicleNumber;
          route.service_rep_employee_id = serviceRepEmployeeId?.value;
          route.service_rep_employee = serviceRepEmployeeId?.label;
          route.isEdited = true;
        } else {
          route.depart_time = convertTimeForTableView(departTime);
          route.start_time = "";
          route.arrive_time = "";
          route.stop_time = "";
          route.vehicle_type_code = "";
          route.vehicle_number = "";
          route.service_rep_employee_id = "";
          route.service_rep_employee = "";
          route.isEdited = true;
        }
        if (transportationMode === transportationConstants.mode.thirdParty) {
          if (!departTime || departTime === "") {
            const msg = await errorMsg(
              errorMsgs.errorCode20295,
              t("routeDetails.departTime")
            );
            setScheduleErrorMsg(msg);
            setScheduleError(true);
            return true;
          }
        } else {
          if (!startTime || !departTime || !arriveTime || !stopTime) {
            const msg = await errorMsg(
              errorMsgs.errorCode20365,
              t("routeDetails.allTimes")
            );
            setScheduleErrorMsg(msg);
            setScheduleError(true);
            return;
          }
          if (serviceRepEmployeeId && serviceRepEmployeeId !== "") {
            const employeeInfo = await getEmployeeInfo(serviceRepEmployeeId);
            if (!employeeInfo?.active) {
              const msg = await errorMsg(
                errorMsgs.errorCode20504,
                t("routeDetails.badDrivers")
              );
              setScheduleError(true);
              setScheduleErrorMsg(msg);
              return;
            }
          }
        }
      }
    }
    routeScheduleForm.setValues(routeScheduleFormValues);
    setSelectedRouteForEdit(false);
    setRouteTableData(parsedScheduleTableData);
    setScheduleError(false);

    // Set current form state to edit
    if (currentFormState === transportationConstants.formStatus.search) {
      setCurrentFormState(transportationConstants.formStatus.edit);
    }
  };

  //for error msg
  const getTheFirestoreErrorMessage = async (msgId) => {
    try {
      const msg = await getMessageFromCode(msgId);
      return { errorMsg: msg[0]?.descr };
    } catch (error) {
      setThrowErrorMessage(error);
      setThrowError(true);
    }
  };

  const errorMsg = async (errorCode, fieldName, fieldName2, fieldName3) => {
    setIsLoading(true);
    const errorObj = await getTheFirestoreErrorMessage(errorCode);
    let errorString;
    if (fieldName && fieldName2 && fieldName3) {
      errorString = errorObj?.errorMsg
        ?.replace("|", fieldName)
        ?.replace("|", fieldName2)
        ?.replace("|", fieldName3);
    } else if (fieldName && fieldName2) {
      errorString = errorObj?.errorMsg
        ?.replace("|", fieldName)
        ?.replace("|", fieldName2);
    } else if (fieldName) {
      errorString = errorObj?.errorMsg.replaceAll("|", fieldName);
    } else {
      errorString = errorObj?.errorMsg || findErrorMessage(ERROR_TYPES.ISSUE);
    }
    setThrowErrorMessage(errorString);
    setIsLoading(false);
    return errorString;
  };

  const handleCancel = () => {
    setModalError(false);
    setModalErrorMsg(null);
    setThrowError(false);
  };
  const handleYes = () => {
    if (modalError) setModalError(false);
    handleSaveEditRouteOnYes();
  };

  //handle download
  const handleDownload = async () => {
    const formattedMode = transportationMode?.replace("_", " ").toUpperCase();
    try {
      setIsLoading(true);
      setThrowError(false);
      let url;
      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        route_code: selectedRouteCode,
        transportaion: formattedMode,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });

      url = CF_URLS.printReports.transportation.routeDetailReport;

      let reportResponse = await printReport(requestBody, url);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleNoClick = () => {
    setShowConfirmPopup(false);
    setCurrentFormState(transportationConstants.formStatus.initial);
    setRouteTableData(tableConstants.defaultTableRowData);
    routeDetailsForm.setValues(initialRouteValues);
    routeScheduleForm.setValues(routeScheduleFormValues);
    setSelectedRouteCode(null);
    setNewBtnClicked(false);
    setIsFieldUpdated(false);
  };
  const handleCancelClick = () => {
    setShowConfirmPopup(false);
  };

  const handleYesClick = () => {
    if (showConfirmPopup) {
      setShowConfirmPopup(false);
      if (routeCodeSelected) {
        setSelectedRouteCode(routeCodeSelected);
        setRouteTableData(tableConstants.defaultTableRowData);
        routeDetailsForm.setValues(initialRouteValues);
        setCurrentFormState(transportationConstants.formStatus.search);
        fetchRouteDetails(routeCodeSelected);
        setThrowError(false);
        setModalError(false);
        setScheduleError(false);
      } else {
        handleSaveEditRoute();
      }
      setIsFieldUpdated(false);
    }
  };

  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      {isShowSuccessPopup && !isLoading && (
        <SuccessPopup message={successMessage} close={setIsShowSuccessPopup} />
      )}
      {throwError && !modalError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            sx={RouteTimeTableStyles.marginBottom2EM}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}

      {modalError && (
        <ConfirmPopup
          modalPopupOpen={modalError}
          handleCancel={handleCancel}
          handleNo={handleCancel}
          handleYes={handleYes}
          showCancel={true}
          showNo={true}
          message={modalErrorMsg}
        />
      )}
      <form onSubmit={routeDetailsForm.handleSubmit}>
        <Grid container spacing={spacing.gap}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h3>{t("routeDetails.route")}</h3>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {currentFormState === transportationConstants.formStatus.new ? (
              <FormControl fullWidth>
                <TextField
                  name="routeCode"
                  label={t("routeDetails.code")}
                  value={routeDetailsForm.values?.routeCode}
                  onChange={handleFieldChange}
                  required
                />
              </FormControl>
            ) : (
              <SelectField
                name="routeCode"
                label={t("routeDetails.code")}
                options={routeCodeList?.data}
                loading={routeCodeList?.isLoading}
                value={routeDetailsForm.values.routeCode || selectedRouteCode}
                onChange={(event) => handleRouteCodeChange(event)}
                disabled={routeCodeList.isLoading}
                required
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                name="name"
                required
                label={t("routeDetails.name")}
                value={routeDetailsForm.values.name}
                InputLabelProps={{ shrink: !!routeDetailsForm.values.name }}
                onChange={handleFieldChange}
                inputProps={{ maxLength: 30 }}
                disabled={
                  !routeDetailsForm.values.routeCode && !selectedRouteCode
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <SelectField
                name="routeTypeCode"
                label={t("routeDetails.routeType")}
                options={routeTypes?.data}
                loading={routeTypes?.isLoading}
                value={routeDetailsForm.values.routeTypeCode}
                onChange={handleFieldChange}
                disabled={
                  routeTypes.isLoading ||
                  (!routeDetailsForm.values.routeCode && !selectedRouteCode)
                }
                required
                shrink={routeDetailsForm.values.routeTypeCode}
                notched={routeDetailsForm.values.routeTypeCode}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                type="text"
                required
                InputLabelProps={{
                  shrink: !!routeDetailsForm.values.minutesPerStopGoal
                }}
                name="minutesPerStopGoal"
                label={t("routeDetails.stopGoal")}
                value={routeDetailsForm.values.minutesPerStopGoal}
                onChange={handleFieldChange}
                disabled={
                  transportationMode ===
                    transportationConstants.mode.thirdParty ||
                  (!routeDetailsForm.values.routeCode && !selectedRouteCode)
                }
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                }}
                inputProps={{ maxLength: 5 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                type="text"
                name="secondsPerContainerGoal"
                required
                label={t("routeDetails.containerGoal")}
                value={routeDetailsForm.values.secondsPerContainerGoal}
                onChange={handleFieldChange}
                InputLabelProps={{
                  shrink: !!routeDetailsForm.values.secondsPerContainerGoal
                }}
                disabled={
                  !routeDetailsForm.values.routeCode && !selectedRouteCode
                }
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                }}
                inputProps={{ maxLength: 5 }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Stack marginTop={spacing.margingTop20}>
          {" "}
          <Divider />
        </Stack>
        <RouteTransportationInformation
          currentFormState={currentFormState}
          isNewRoute={isNewBtnClicked}
          transportationMode={transportationMode}
          setTransportationMode={setTransportationMode}
          vendorsList={vendorsList}
          transportationVendorId={
            routeDetailsForm?.values?.transportationVendorId
          }
          routeScheduleForm={routeScheduleForm}
          handleFieldValueChange={handleFieldChange}
          routeDetailsForm={routeDetailsForm}
        />

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {currentFormState !== transportationConstants.formStatus.initial && (
            <Paper>
              <RouteTimeTable
                setSelectedRouteForEdit={setSelectedRouteForEdit}
                routeScheduleData={routeScheduleData}
                routeTableData={routeTableData}
                setRouteTableData={setRouteTableData}
                transportationMode={transportationMode}
                routeScheduleForm={routeScheduleForm}
                vehicleList={vehicleList}
                employees={employees}
                selectedRouteForEdit={selectedRouteForEdit}
                isEditIconDisabled={isEditIconDisabled}
              />
            </Paper>
          )}
        </Grid>
        <Stack
          direction={"row"}
          mt={2}
          spacing={2}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Button
            id="Delete"
            variant="outlined"
            onClick={() => {
              setConfirmDeleteModalOpen(true);
            }}
            disabled={isDeleteBtnDisabled()}
          >
            {t("common.delete")}
          </Button>
          <Button
            id="Print"
            variant="outlined"
            onClick={() => {
              setShowPrintModal(true);
            }}
            disabled={isPrintButtonDisabled()}
          >
            {t("common.print")}
          </Button>
          <Button
            id="New"
            variant="outlined"
            onClick={() => handleNewBtnClick()}
            disabled={isNewBtnDisabled()}
          >
            {t("common.new")}
          </Button>
          <Button
            id="Cancel"
            variant="outlined"
            onClick={handleCancelBtnClicked}
            disabled={isCancelBtnDisabled()}
          >
            {t("common.cancel")}
          </Button>
          <Button
            id="save"
            variant="contained"
            onClick={() => handleSaveEditRoute()}
            disabled={isSaveButtonDisabled()}
          >
            {t("common.save")}
          </Button>
        </Stack>
        {selectedRouteForEdit && (
          <RouteDetailsEditableModal
            currentFormState={currentFormState}
            selectedRouteForEdit={selectedRouteForEdit}
            setSelectedRouteForEdit={setSelectedRouteForEdit}
            routeScheduleForm={routeScheduleForm}
            routeDetailsForm={routeDetailsForm}
            vehicleList={vehicleList}
            handleUpdateScheduleRowData={handleUpdateScheduleRowData}
            transportationMode={transportationMode}
            employees={employees}
            selectedRouteCode={selectedRouteCode}
            modalErrorMsg={modalErrorMsg}
            setModalErrorMsg={setModalErrorMsg}
            modalError={modalError}
            setModalError={setModalError}
            scheduleError={scheduleError}
            setScheduleError={setScheduleError}
            setScheduleErrorMsg={setScheduleErrorMsg}
            scheduleErrorMsg={scheduleErrorMsg}
            routeScheduleFormValues={routeScheduleFormValues}
            setCurrentFormState={setCurrentFormState}
            setIsFieldUpdated={setIsFieldUpdated}
          />
        )}
        <ModalPopup
          title="Confirm"
          modalPopupOpen={isConfirmDeleteModalOpen}
          alertMessage={t("routeDetails.deleteRouteConfirmation")}
          modalButton={[
            <Button
              variant="outlined"
              onClick={() => setConfirmDeleteModalOpen(false)}
              key={t("common.no")}
            >
              {t("common.no")}
            </Button>,

            <Button
              onClick={() => handleDeleteRoute()}
              variant="contained"
              key={t("common.yes")}
            >
              {t("common.yes")}
            </Button>
          ]}
        />
        <DownloadPopup
          title={t("menu.routeDetail")}
          showPrintModal={showPrintModal}
          downloadOptions={downloadReportOptions}
          onCancleClick={() => setShowPrintModal(false)}
          onDownloadClick={handleDownload}
          setSelectedValue={setSelectedValue}
          setThrowError={setThrowError}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
        />
        {isFieldUpdated && showConfirmPopup && (
          <ConfirmPopup
            modalPopupOpen={showConfirmPopup}
            message={""}
            handleCancel={handleCancelClick}
            handleNo={handleNoClick}
            handleYes={handleYesClick}
            showCancel={showConfirmPopup}
            showNo={true}
          />
        )}
      </form>
    </>
  );
};
export default RouteDetailsLayout;
