import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { t } from "i18next";
import {
  filterOptions,
  filterPopupId
} from "utils/constants/customer-information/ScanOnSite";
import { PreferencesStyles } from "../PreferencesStyles";

const FilterModal = ({
  open,
  anchorEl,
  filterValue,
  handleChange,
  handleReset,
  handleClose
}) => {
  const id = open ? filterPopupId : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Stack sx={PreferencesStyles.filterContainer}>
          <IconButton
            onClick={handleClose}
            sx={PreferencesStyles.closeIconButton}
          >
            <Close />
          </IconButton>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="body2">{t("preferences.filters")}</Typography>
            <Button onClick={handleReset}>{t("common.reset")}</Button>
          </Stack>
          <Autocomplete
            disableClearable={true}
            options={filterOptions}
            sx={PreferencesStyles.filterAutoComplete}
            getOptionLabel={(option) => option.label}
            value={filterValue}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("preferences.changeddValues")}
                variant="standard"
              />
            )}
          />
        </Stack>
      </Popover>
    </>
  );
};

export default FilterModal;
