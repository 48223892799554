import { Grid, Link } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { DetailsStyles } from "./Styles";

const ContainerDiscrepancySummary = ({
  tableData,
  handleDiscrepancySearch
}) => {
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch:
          (!tableData || tableData.length === 0) && `${t("common.noDataFound")}`
      }
    }
  };
  const columns = [
    {
      name: "customer_number",
      label: t("reports.vaultStatusReport.customer"),
      options: {
        setCellProps: (value) => ({
          style: {
            ...DetailsStyles.linkStyles,
            ...DetailsStyles.columnStylesm
          }
        }),
        customBodyRender: (value, tableMeta) => {
          const rowData = tableData[tableMeta.rowIndex];
          return (
            <Link
              onClick={() => {
                handleDiscrepancySearch({
                  ...rowData,
                  type: t("discrepancyManagement.container")
                });
              }}
            >
              {value?.trim() ? value : t("common.unknown")}
            </Link>
          );
        }
      }
    },
    {
      name: "detected_in",
      label: t("reports.vaultStatusReport.detectedIn")
    },

    {
      name: "logical_vault_code",
      label: t("reports.vaultStatusReport.logicalVault")
    },

    {
      name: "missing_naim_count",
      label: t("reports.vaultStatusReport.missing")
    },
    {
      name: "unexpected_count",
      label: t("reports.vaultStatusReport.unexpected")
    },
    {
      name: "no_return_date_count",
      label: t("reports.vaultStatusReport.noValidReturnDate")
    },
    {
      name: "incomplete_stop_count",
      label: t("reports.vaultStatusReport.incompleteStop")
    },
    {
      name: "unresolved_count",
      label: t("reports.vaultStatusReport.ofUnresolvedDiscrepancy")
    }
  ];
  return (
    <Grid item xs={12} lg={12} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={tableData ? [...tableData] : []}
        options={options}
      />
    </Grid>
  );
};

export default ContainerDiscrepancySummary;
