export const WorkManagementStyles = {
  workManagementTabBox1: { width: "100%" },
  tablePaper: {
    width: "100%",
    overflow: "hidden",
    height: "100%"
  },
  completeColor: "var(--color-im-mid-green)",
  selectedColor: "var(--color-im-light-blue-100)",
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  treeCard: { height: "100%" },
  alertPadding: { marginBottom: "16px" },
  treeCardContent: {
    overflow: "auto",
    minHeight: "calc(500px + 64px)"
  },
  tabBox: { width: "100%" },
  tabBox1: { borderBottom: 1, borderColor: "divider" },
  selectSx: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  getTableWrapperStyles: (isDataExist) => {
    return {
      "& .MuiTableHead-root": {
        pointerEvents: isDataExist ? "auto" : "none",
        opacity: isDataExist ? "1" : "0.5"
      }
    };
  },
  capacityColor: (isDataExist) => {
    return {
      color: isDataExist ? "var(--color-im-red)" : "var(--color-im-green)"
    };
  },
  formControllabelSx: {
    display: "inline-block",
    "& span": { verticalAlign: "middle" }
  },
  commonStyles: {
    widthFixed: { width: "310px" }
  },
  compareButtonLoader: {
    color: "var(--color-white)"
  },
  compareButton: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  alertSize: { width: "100%" }
};

export const WorkManagementTreeStyles = {
  checkboxColor: "var(--color-im-mid-blue)",
  iconStyles: {
    color: "var(--color-im-mid-blue)",

    "& svg": {
      fontSize: "32px",
      fontWeight: 400
    }
  },
  textColorRed: { color: "var(--color-im-red)" },
  iconStyle: {
    mr: 1
  },
  loaderSize: 16,
  iconSize: "small",
  nodeLableContainer: {
    display: "flex",
    alignItems: "center"
  },
  chevronIcon: {
    color: "var(--color-im-mid-blue)",
    position: "absolute",
    left: "-25px",
    fontSize: "18px"
  }
};

export const InventoryStyles = {
  descriptionContainer: {
    flex: 1,
    "& input": {
      paddingLeft: 0
    },
    "& label": {
      marginLeft: "-14px"
    }
  },
  textFieldNoBorder: {
    border: "none",
    "& fieldset": { border: "none" },
    pointerEvents: "none",
    width: "250px"
  },
  errorBox: {
    marginY: "15px"
  },
  containerGrid: {
    marginTop: "10px"
  },
  clearFindWrapper: {
    flexDirection: "row",
    marginTop: "20px",
    gap: 2
  },
  mainGridWrapper: {
    marginTop: "32px"
  },
  compareButtonLoader: {
    color: "var(--color-white)"
  },
  compareButton: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  }
};

export const WorkManagementTableStyles = {
  toolBar: {
    marginBottom: "-34px",
    flexWrap: "wrap",
    "& button": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      margin: "5px !important"
    }
  }
};

export const InventoryTabStyles = {
  tree: {
    card: { height: "1355px", overflow: "auto" }
  },
  buttonList: { mr: "20px", mt: "20px", mb: "20px" },
  selectTable: {
    title: { p: "15px" },
    tableContainer: { height: "400px", overflowY: "auto" }
  },
  table: {
    btnBox: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      p: "20px"
    }
  }
};

export const VerifyTabStyles = {
  paper: { position: "relative" },
  tableHead: {
    position: "sticky",
    top: 0,
    backgroundColor: "var(--color-white-100)",
    zIndex: 1
  },
  tableHeaderTypo: {
    color: "rgba(0,0,0,0.60)",
    fontWeight: 700
  },
  span: { cursor: "pointer", width: "fit-content" },
  possibleMatchTableRowMuiCustom: {
    "& .css-dsuxgy-MuiTableCell-root": {
      padding: "0px 16px"
    }
  },
  tableCell: { minWidth: "0 !important" },
  formControlLabel: {
    marginRight: "0px !important",
    "& .MuiCheckbox-root": {
      padding: 0
    }
  },
  tablePagination: { position: "sticky", bottom: 0 },
  tableFooterBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 2
  },
  tableFooterTypo: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43
  },
  tableFooterFormControl: { marginRight: 2 },
  tableFooterSelect: {
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0
    }
  },
  tree: {
    card: { height: "1355px", overflow: "auto" }
  },
  buttonList: { mr: "20px", mt: "20px", mb: "20px" },
  selectTable: {
    title: { p: "15px" },
    tableContainer: { height: "400px", overflowY: "auto" }
  },
  table: {
    btnBox: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      p: "20px"
    }
  }
};
