import { Box, Divider, Stack } from "@mui/material";
import AuditAuthForm from "components/shared/auth/AuditAuthSection";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  PREFERENCES
} from "routing/Paths";
import {
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import {
  getCustomersQueryData,
  getDistricts,
  getPersonnelCustomer
} from "services/api/query";
import { selectAuth } from "store/slices";
import {
  clearError,
  setAuthTypeChange,
  setDefaultBranchValues,
  setError,
  setLoading,
  setShowAuthSection,
  updateAuthFormValues,
  updatePreferencesAuthentication
} from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getLanguageId,
  getSortedData
} from "utils/helpers";
import { PreferencesStyles } from "../PreferencesStyles";

const PreferencesAuth = ({ showBypass }) => {
  const [allBranches, setAllBranches] = useState([]);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);

  const [allCustomers, setAllCustomers] = useState([]);
  const {
    authFormValues,
    isAuthenticated,
    showAuthSection,
    defaultAuthBranchValues
  } = useSelector((state) => state.customerPreferences);

  const [isBypassError, setIsBypassError] = useState(false);

  const { currentBranch, auth } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const languageLocaleId = getLanguageId();
  const navigate = useNavigate();

  let initialAuthValues = {
    customer: VALUE_EMPTY_STRING,
    personnel: VALUE_EMPTY_STRING,
    authNumber: VALUE_EMPTY_STRING,
    challengeQuestion: VALUE_EMPTY_STRING,
    challengeResponse: VALUE_EMPTY_STRING,
    bypassMessage: VALUE_EMPTY_STRING
  };

  // selected personnel auth details
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: authFormValues?.personnel?.value
  });
  const programSecurityListArray = auth?.user?.program_list
    .split(",")
    .map(Number);

  const preferencesAuthForm = useFormik({
    initialValues: authFormValues
  });

  useEffect(() => {
    getAllBarnchesFromFireStore();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (personnelAuth?.length) {
      const updates = {
        ...authFormValues,
        challengeQuestion: personnelAuth[0]?.challenge_question
      };
      dispatch(updateAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [personnelAuth]);
  // set the default branch into the auth section
  useEffect(() => {
    if (allBranches && allBranches?.length) {
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      const updates = {
        branch: selectedLocationDetails?.district_id,
        isAuthenticate: true
      };
      dispatch(updateAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [allBranches]);

  // get customers for the selected branch
  useEffect(() => {
    if (currentBranch) {
      getCustomersBasedOnTheSelectedBranch(currentBranch?.district_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch]);
  useEffect(() => {
    if (authFormValues?.branch) {
      if (!isAuthenticated) {
        dispatch(updateAuthFormValues(initialAuthValues));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authFormValues?.branch]);

  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    dispatch(setLoading(true));
    try {
      const branches = await getDistricts();
      let bran = branches?.map((b) => {
        return {
          ...b,
          label: b?.name,
          value: b?.district_id
        };
      });
      const branchData = getSortedData(bran, "name", "asc");
      branchData.unshift({
        label: "",
        value: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      setAllBranches(branchData);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.EXCEPTION)));
      dispatch(setLoading(false));
    }
  };
  // get customers based on the selected branch
  const getCustomersBasedOnTheSelectedBranch = async (branch) => {
    dispatch(setLoading(true));
    try {
      const custList = await getCustomersQueryData(branch);
      let modCux = custList?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number} - ${cus?.name}`,
          value: cus?.id
        };
      });
      setAllCustomers(modCux);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      dispatch(setLoading(false));
    }
  };
  const handleAuthFieldsChange = (name, value) => {
    const updatedValues = {
      ...authFormValues,
      [name]: value
    };
    dispatch(updateAuthFormValues(updatedValues));
  };

  // handle branch change
  const handleBranchChange = (e) => {
    const updatedValues = {
      branch: e.target.value,
      customer: VALUE_EMPTY_STRING,
      personnel: VALUE_EMPTY_STRING
    };

    getCustomersBasedOnTheSelectedBranch(e.target.value);
    dispatch(updateAuthFormValues(updatedValues));
  };
  // customer selection in auth section
  const handleCustomerChange = (event, newValue) => {
    dispatch(clearError());
    const updatedValues = {
      ...authFormValues,
      customer: newValue,
      personnel: VALUE_EMPTY_STRING
    };
    preferencesAuthForm.setValues(updatedValues);
    dispatch(updateAuthFormValues(updatedValues));
  };
  // handle Personnel selection in auth section
  const handlePersonnelChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue
    };
    dispatch(updateAuthFormValues(updatedValues));
  };

  const handlePersonnelAuthChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: VALUE_EMPTY_STRING,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING,
      personnelAuthValue: selectedValue.target.value
    };
    dispatch(setAuthTypeChange(selectedValue.target.value));
    dispatch(updateAuthFormValues(updatedValues));
    dispatch(updatePreferencesAuthentication(false));
    setIsAuthenticationError(false);
    dispatch(clearError());
  };

  const navigateToMainScreen = () => {
    let path = `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${PREFERENCES}`;
    navigate(path);
  };

  const onAuthenticatedCallerCliked = async () => {
    if (isAuthenticated) {
      dispatch(updateAuthFormValues(initialAuthValues));
      dispatch(updatePreferencesAuthentication(false));
      dispatch(setShowAuthSection(false));
      dispatch(
        setDefaultBranchValues({
          isAuthenticated: false,
          authenticatateBranch: []
        })
      );
    } else {
      dispatch(clearError());
      dispatch(setShowAuthSection(true));
    }
  };
  const getPersonnelCustomers = async (selectedValue) => {
    const customerList = await getPersonnelCustomer(
      "personnel_id",
      selectedValue ? selectedValue.value : authFormValues?.personnel?.value
    );

    const list = customerList.map((i) => i.customer_id);

    return list;
  };
  // authentication click
  const onAuthenticateClick = async () => {
    dispatch(setLoading(true));
    setIsAuthenticationError(false);
    try {
      // Check if either authNumber or challengeResponse is provided
      if (
        !authFormValues?.authNumber?.length &&
        !authFormValues?.challengeResponse?.length
      ) {
        setIsAuthenticationError(true);
        return;
      }

      const customersList = await getPersonnelCustomers();

      // Authenticate with authNumber
      if (authFormValues?.authNumber?.length) {
        if (authFormValues.authNumber === personnelAuth[0]?.auth_number) {
          dispatch(updatePreferencesAuthentication(true));
          if (
            !customersList.includes(defaultAuthBranchValues?.customer?.value)
          ) {
            navigateToMainScreen();
          }
        } else {
          setIsAuthenticationError(true);
        }
      }

      // Authenticate with challengeResponse
      if (authFormValues?.challengeResponse?.length) {
        const hashCode = await generateSHA256Hash(
          authFormValues.challengeResponse.trim().toUpperCase()
        );

        const validateChallengeResponse =
          await triggerValidateChallengeResponse({
            main_district_id: String(currentBranch?.district_id),
            language_locale_id: languageLocaleId,
            personnel_id: authFormValues?.personnel?.value,
            challenge_response: hashCode
          });

        if (validateChallengeResponse?.data[0]?.is_match_flag === Y_CHECKER) {
          dispatch(updatePreferencesAuthentication(true));
          if (
            !customersList.includes(defaultAuthBranchValues?.customer?.value)
          ) {
            navigateToMainScreen();
          }
        } else {
          setIsAuthenticationError(true);
        }
      }
      preferencesAuthForm.setValues(authFormValues);
      let updateData = {
        branch: authFormValues?.branch,
        customer: authFormValues?.customer,
        customerName: authFormValues.customer.number,
        findButtonDisable: false,
        personnel_id: authFormValues?.personnel?.value
      };
      dispatch(setDefaultBranchValues(updateData));
    } catch (error) {
      setIsAuthenticationError(true);
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onCancelAuthCliked = () => dispatch(setShowAuthSection(false));

  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  const onBypassClick = () => {
    if (!authFormValues.bypassMessage) {
      setIsBypassError(true);
      return;
    }
    setIsBypassError(false);
    dispatch(updatePreferencesAuthentication(true));
  };

  return (
    <>
      <Stack gap={spacing.gap}>
        <Box sx={PreferencesStyles.authCallerWrapper}>
          <AuthenticationCallerButton
            isAuthenticated={isAuthenticated}
            onAuthenticatedCallerCliked={onAuthenticatedCallerCliked}
          />
        </Box>

        {showAuthSection && (
          <>
            {/* Personnel Authentication Section */}
            {!isAuthenticated && (
              <AuditAuthForm
                allBranches={allBranches}
                handleBranchChange={handleBranchChange}
                pageFormDetails={preferencesAuthForm}
                valuePersonnelAuth={authFormValues?.personnelAuthValue}
                handlePersonnelAuthChange={handlePersonnelAuthChange}
                authCustomers={allCustomers}
                handleCustomerChange={handleCustomerChange}
                handlePersonnelChange={handlePersonnelChange}
                onAuthenticateClick={onAuthenticateClick}
                isAuthenticationError={isAuthenticationError}
                isAuthenticated={isAuthenticated}
                programSecurityListArray={programSecurityListArray}
                authFormValues={authFormValues}
                handleChange={handleAuthFieldsChange}
                allCustomers={allCustomers}
                onCancelAuthCliked={onCancelAuthCliked}
                disableBranch={false}
                onBypassClick={onBypassClick}
                isBypassError={isBypassError}
                showBypass={showBypass}
              />
            )}
          </>
        )}

        {isAuthenticated && (
          <>
            <SuccessAuthSection
              personalDetailForm={preferencesAuthForm}
              valuePersonnelAuth={authFormValues?.personnelAuthValue}
              handlePersonnelOnChange={handlePersonnelAuthChange}
              disablePersonnel={true}
            />
            <Divider />
          </>
        )}
      </Stack>
    </>
  );
};
export default PreferencesAuth;
