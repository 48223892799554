import { Stack } from "@mui/material";
import { MediaRequestPickupDetail } from "../../components/features/request-module/media-destruction-request-pickup/index";
function MedDestReqPickupDetails() {
  return (
    <Stack spacing={2}>
      <MediaRequestPickupDetail />
    </Stack>
  );
}

export default MedDestReqPickupDetails;
