import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { popupStyles } from "components/core/modal-components/Popup.Style";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  downloadReportOptions,
  ERROR_TYPES,
  N_CHECKER,
  operationTypes
} from "utils/constants";
import { zeroChecker } from "utils/constants/container-processing/Distribution";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getLanguageId,
  getUserName,
  printReport
} from "utils/helpers";

const PreferencesReoprtDownloadModal = ({
  title,
  showPrintModal,
  setCloseModal,
  setIsLoading,
  customerId,
  preferenceTypeId,
  url,
  topTextFieldReportsPayload,
  preferenceTypesPayloadForReport
}) => {
  const { t } = useTranslation();
  const selectedValue = downloadReportOptions;
  const [value, setValue] = useState(selectedValue[0]);
  const [inputValue, setInputValue] = useState("");
  const { auth } = useSelector(selectAuth);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const districtId = getAuthenticatedUserBranch();

  // download button click in download modal
  const onDownloadClick = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: districtId,
        customer_id: customerId,
        preference_type_id_list: preferenceTypeId,
        SuppressEmployee: N_CHECKER,
        SuppressPersonnel: N_CHECKER,
        AttributeCount: zeroChecker,
        user_name: getUserName(auth?.user),
        report_format: value?.type?.toUpperCase(),
        preferences: topTextFieldReportsPayload,
        locale_id: getLanguageId(),
        operation_types: [...operationTypes],
        preference_types: preferenceTypesPayloadForReport
      });

      let reportResponse = await printReport(reqBody, url);

      if (!reportResponse.success) {
        setThrowError(true);
        setThrowErrorMessage(reportResponse.error);
        setIsLoading(false);
      }
    } catch (e) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={showPrintModal}
      onClose={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={popupStyles.cardStyle}>
        {/* modal header start */}
        <Typography
          variant="h6"
          sx={popupStyles.popupTitle}
          color="text.secondary"
          gutterBottom
        >
          {title}
        </Typography>
        {/* modal header start */}

        <Divider />

        {/* modal content start */}
        <CardContent sx={popupStyles.cardContentStyles}>
          <Collapse in={throwError}>
            {/*Error alert box*/}
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" width="20" />}
              sx={popupStyles.alertBox}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <Typography sx={popupStyles.alertTextStyle}>
                {throwErrorMessage}
              </Typography>
            </Alert>
          </Collapse>
          <Autocomplete
            id="downloadFileType"
            options={selectedValue}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            disableClearable={true}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("common.exportType")} />
            )}
          />
        </CardContent>
        {/* modal content end */}

        <Divider />

        {/* modal footer start */}
        <CardActions sx={popupStyles.cardActionStyles}>
          <Button
            variant="outlined"
            onClick={() => {
              setCloseModal(false);
              setThrowError(false);
              setThrowErrorMessage("");
            }}
          >
            {t("common.close")}
          </Button>
          <Button variant="contained" onClick={() => onDownloadClick()}>
            {t("common.download")}
          </Button>
        </CardActions>
        {/* modal footer start */}
      </Card>
    </Modal>
  );
};

export default PreferencesReoprtDownloadModal;
