import {
  Button,
  Grid,
  Paper,
  SvgIcon,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";
import { t } from "i18next";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  VALUE_EMPTY_STRING,
  YES
} from "utils/constants";
import { labelTemplateStyles } from "./LabelTemplateSearchStyles";
const { default: MUIDataTable } = require("mui-datatables");

const LabelTemplateTable = ({
  selectedRow,
  tableData,
  isModalOpen,
  handleRowClick,
  handleRowSelection,
  isFindClicked,
  handleRowSelect,
  setShowPrintModal
}) => {
  const columns = [
    {
      name: VALUE_EMPTY_STRING,
      label: VALUE_EMPTY_STRING,
      options: {
        customBodyRender: (value, tableMeta) => {
          const competed = tableMeta.rowData[4];
          return competed !== YES ? (
            <Button
              onClick={() => {
                handleRowSelection(value, tableMeta);
              }}
            >
              <SvgIcon component={ViewIcon} />
            </Button>
          ) : null;
        }
      }
    },
    {
      name: "template",
      label: t("labelTemplateSearch.labelTemplate")
    },
    {
      name: "descr",
      label: t("labelTemplateSearch.description")
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    onRowSelectionChange: handleRowSelection,
    pagination: tableData?.length > 0 && true,
    sort: true,
    textLabels: {
      body: {
        noMatch:
          tableData?.length === 0 && isFindClicked
            ? `${t("common.noRecordsFound")}`
            : tableData?.length === 0 && `${t("common.tableTextWithoutInputs")}`
      }
    },
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={tableData.length === 0}
            onClick={() => setShowPrintModal(true)}
          >
            {t("common.print")}
          </Button>
        </Grid>
      </>
    )
  };
  return (
    <>
      <Paper sx={labelTemplateStyles?.tablePaper}>
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={tableData}
            title={
              tableData.length > 0 && (
                <Typography style={labelTemplateStyles.titleStyle}>
                  {tableData.length} {t("common.recordsFound")}
                </Typography>
              )
            }
          />
        </TableContainer>
      </Paper>
    </>
  );
};
export default LabelTemplateTable;
