export const systemFunctionId = { find: "8", print: "9" };
export const rpType = {
  runReport: "rr",
  withDirections: "rrd"
};
export const processTypes = {
  tab: "Tab",
  serviceDate: "ServiceDate",
  run: "Run",
  clear: "Clear"
};
export const accessCode313 = "313";
export const currentStatus = {
  staged: "Staged"
};
export const currentStatusId7 = "7";
export const enterKey = "Enter";
