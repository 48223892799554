import { Stack } from "@mui/material";
import OtherRequest from "components/disaster-module/event-detail/other-request/OtherRequest";
import { PageTitle } from "components/shared";
import { useMemo, useState } from "react";
import i18n from "../../i18n";

const EventRequestGeneratorOther = () => {
  const [routeData, setRouteData] = useState({
    eventId: "",
    customerNumber: ""
  });
  const pageTitle = useMemo(() => {
    return i18n
      .t("disasterRecovery.eventDetail.otherRequestGeneratorTitle")
      .replace("|", routeData.eventId)
      .replace("|", routeData.customerNumber);
  }, [routeData]); // constructed page title

  return (
    <Stack spacing={2}>
      <PageTitle title={pageTitle} />
      <OtherRequest setRouteData={setRouteData} />
    </Stack>
  );
};

export default EventRequestGeneratorOther;
