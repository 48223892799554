import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useBlocker from "./useBlocker";

/**
 * `useCallbackPrompt` returns `showPrompt`, `confirmNavigation`, `cancelNavigation` property and methods initialized based on the passed argument
 *  (`enable`). The returned array will gives one property and two methods.
 *
 * Usage note: if you need to show a confirmation popup before leaving the current route pass `true` as the argument value.
 *
 * Example: `const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true)`
 *
 * @param {boolean} enable block the browser navigationg or not
 * @returns [`showPrompt`: show/hide confirmation popup, `confirmNavigation`: execute the forward navigation, `cancelNavigation`: stop the navigation and close the confirmation popup]
 */
const useCallbackPrompt = (enable) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  // cancel the forward navigation
  const cancelNavigation = useCallback(() => {
    setShowPrompt((prevState) => false);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // in if condition we are checking next location and current location are equals or not
      if (
        !confirmedNavigation &&
        (nextLocation?.location?.pathname !== location?.pathname ||
          (nextLocation?.location?.pathname === location?.pathname &&
            nextLocation?.location?.search !== location?.search))
      ) {
        setShowPrompt((prevState) => true);
        setLastLocation((prevState) => nextLocation);
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location]
  );

  // go ahead with the navigation
  const confirmNavigation = useCallback(() => {
    setShowPrompt((prevState) => false);
    setConfirmedNavigation((prevState) => true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname, {
        state: { ...lastLocation.location.state }
      });

      // Clean-up state on confirmed navigation
      setConfirmedNavigation((prevState) => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, enable);

  return [showPrompt, confirmNavigation, cancelNavigation];
};

export default useCallbackPrompt;
