import { useTheme } from "@emotion/react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  createTheme,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  TextField,
  ThemeProvider,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { DEFAULT_LOCALE } from "@mui/x-date-pickers";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon, {
  ReactComponent as errorIcon
} from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  DISCREPANCY_MANAGEMENT,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH
} from "routing/Paths";
import { useGetAllCustomersQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { getEmployeeDetails, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  container,
  dateFormatWithoutTime,
  DEFAULT_DATA_TABLE_OPTIONS,
  dmyDateFormatTime,
  downloadReportOptions,
  ERROR_TYPES,
  errorMsgs,
  local,
  N_CHECKER,
  NO,
  openMedia,
  transport,
  unknown,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER,
  YES,
  ymdDateFormatTime,
  ymdDateFormatTimeSec
} from "utils/constants";
import {
  auto,
  bill,
  correctiveActionRequestId1,
  correctiveActionRequestId10,
  correctiveActionRequestId11,
  correctiveActionRequestId12,
  correctiveActionRequestId14,
  correctiveActionRequestId15,
  correctiveActionRequestId16,
  correctiveActionRequestId17,
  correctiveActionRequestId18,
  correctiveActionRequestId2,
  correctiveActionRequestId3,
  correctiveActionRequestId4,
  correctiveActionRequestId5,
  correctiveActionRequestId6,
  correctiveActionRequestId7,
  correctiveActionRequestId8,
  doNotBill,
  ecmtCONTAINER,
  ecmtTRANSPORT,
  inherit,
  mediaProgramTypeId,
  modalTextLimit,
  none
} from "utils/constants/corrective-action-request";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getSortedData,
  getUserName,
  printReport,
  sendEmail
} from "utils/helpers";
import { default as AddCommentsModal } from "./AddCommentsModal";
import AddMediaModal from "./AddMediaModal";
import AddResolutionCommentModal from "./AddResolutionCommentsModal";
import { CorrectiveActionRequestStyles } from "./CorrectiveActionRequestStyles";
import CustomerComments from "./CustomerCommentModal";

const CorrectiveActionRequestDetail = () => {
  const globalTheme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const record = useMemo(() => {
    // Initialize record logic here
    return (
      location.state?.record ||
      location?.state?.propsData ||
      location.state ||
      {}
    ); // Replace with your logic
  }, [location]);
  const { currentBranch, auth, localeByBranch, roleAccess } =
    useSelector(selectAuth);
  const [customer, setCustomer] = useState(null);
  const [typeData, setTypeData] = useState([]);
  const [isOpenAddCommentModal, setIsOpenCommentModal] = useState(false);
  const [isOpenResolutionCommentModal, setIsOpenResolutionCommentModal] =
    useState(false);
  const [isOpenMediaModal, setIsOpenMediaModal] = useState(false);
  const [carId, setCarId] = useState("");
  const [saveBtnStatus, setSaveBtnStatus] = useState(true);
  const [openMediaList, setOpenMediaList] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [reRunData, setReRunData] = useState([]);
  const [carFormData, setCarData] = useState({
    detectedDate: dayjs(new Date()),
    notify_account_owner_flag: VALUE_N,
    customer_display_flag: VALUE_N,
    type: null,
    manually_created_car_flag: VALUE_Y
  });
  const [selectBillable, setSelectBillable] = useState(doNotBill);
  const [throwError, setThrowError] = useState(false);
  const [openDiscrepancyModal, setOpenDiscrepenceModal] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [billCommentData, setBillCommentData] = useState([]);

  const [billComentList, setBillCommentList] = useState([]);
  const [resolutionCommentList, setResolutionCommentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openChangesModal, setOpenChangesModal] = useState(false);
  const [newClick, setNewCLick] = useState(false);
  const [disableResolution, setDisableResolution] = useState(true);
  const [disableInvestigationComplete, setDisableInvestigationComplete] =
    useState(true);
  const [disableIdentification, setDisableIdentification] = useState(false);
  const [disableCarForm, setDisableCarForm] = useState(false);
  const [disableType, setDisableType] = useState(true);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [localDateTime, setLocalDateTime] = useState({});
  const [isModalInvestigationVisible, setIsModalInvestigationVisible] =
    useState(false);
  const [isModalRootCauseVisible, setIsModalRootCauseVisible] = useState(false);
  const [isModalEmployeeSignoffVisible, setIsModalEmployeeSignoffVisible] =
    useState(false);
  const [showInvestigationComment, setShowInvestigationComment] = useState([]);
  const [addInvestigationComment, setAddInvestigationComment] = useState("");

  const [failurePointData, setFailurePointData] = useState([]);
  const [rootCauseData, setRootCauseData] = useState([]);
  const [isEmployeeSelected, setIsEmployeeSelected] = useState(false);
  const [searchEmployee, setSearchEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [enableEmployeeSignoffButton, setEnableEmployeeSignoffButton] =
    useState(true);
  const [isDuplicateEmp, setIsDuplicateEmp] = useState(false);
  const [rootCauseEmployees, setRootCauseEmployees] = useState([]);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [employeeSignOffcommentsData, setEmployeeSignoffCommentsData] =
    useState([]);
  const [failurePoint, setFailurePoint] = useState(null);
  const [rootCause, setRootCause] = useState(null);
  const [customerComments, setCustomerComments] = useState(null);
  const [openCustomerCommentModal, setOpenCustomerCommentModal] =
    useState(false);
  const [isBillable, setIsBillable] = useState(false);
  const pdfFormat = downloadReportOptions[0].type.toLocaleUpperCase();

  const tableRef = useRef(null);

  useEffect(() => {
    if (throwErrorMessage) {
      throwErrorMessage && window.scrollTo(0, 0);
    }

    //eslint-disable-next-line
  }, [throwErrorMessage]);
  const handleBillChange = (event) => {
    setIsBillable(true);
    setSelectBillable(event.target.value);
    setSaveBtnStatus(false);
    setBillCommentList([
      ...commentList,
      {
        comment: `${auth?.user?.first_name}, ${auth?.user?.last_name} ${dayjs
          .utc(date?.local_getdate)
          .format(ymdDateFormatTime)} :${t(
          "correctiveActionRequest.resolutionOption"
        )} ${
          event.target.value === bill
            ? t("correctiveActionRequest.bill")
            : t("correctiveActionRequest.doNotBill")
        }\r\n`,
        local: local
      }
    ]);
  };
  const date = localDateTime;
  useEffect(() => {
    if (roleAccess.length > 0) {
      if (currentBranch?.value) {
        fetchSearchEmpData();
        fetchFillRootCauseCombo();
      }
      if (carFormData?.carTypeId && currentBranch?.value) {
        fetchRequestTypeFailurePoint();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch?.value, carFormData?.carTypeId]);

  useEffect(() => {
    setFailurePoint(
      failurePointData?.find((e) => {
        return e.failure_point_descr === carFormData?.failure_point_descr;
      })
    );
    setRootCause(
      rootCauseData?.find((e) => {
        return e.root_cause_descr === carFormData?.root_cause_descr;
      })
    );

    if (
      carFormData?.root_cause_descr === t("correctiveActionRequest.employee")
    ) {
      setIsEmployeeSelected(true);
    } else {
      setIsEmployeeSelected(false);
    }
  }, [
    carFormData?.failure_point_descr,
    carFormData?.root_cause_descr,
    failurePointData,
    rootCauseData
  ]);
  const fetchFillRootCauseCombo = useCallback(async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        show_active_only_flag: Y_CHECKER
      };
      const rootCauseResponse = await getResponseData(
        reqBody,
        CF_URLS.correctiveActionRequest.fillRootCauseCombo,
        2
      );

      const mappedRootCause = rootCauseResponse?.data?.[0]?.map((res) => ({
        root_cause_id: res?.root_cause_id,
        root_cause_descr: res?.root_cause_descr
      }));
      const rootCauseResult = getSortedData(
        mappedRootCause,
        "root_cause_descr",
        "asc"
      );
      setRootCauseData(rootCauseResult);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  }, [currentBranch?.value]);
  const fetchRequestTypeFailurePoint = useCallback(async () => {
    setLoading(true);
    const failurePointReqBody = {
      main_district_id: currentBranch?.value,
      corrective_action_request_type_id: carFormData?.carTypeId
    };

    try {
      const res = await getResponseData(
        failurePointReqBody,
        CF_URLS.correctiveActionRequest
          .correctiveActionRequesttypefailurePoints,
        2
      );
      let transformedBranch = res.data?.["0"]?.map((b) => {
        return {
          ...b,
          label: b?.failure_point_descr.trim(),
          value: b?.failure_point_id
        };
      });

      const failurPointSortedData = getSortedData(
        transformedBranch,
        "label",
        "asc"
      );
      setFailurePointData(failurPointSortedData);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  }, [carFormData?.carTypeId, currentBranch?.value]);

  const fetchSearchEmpData = useCallback(async () => {
    setLoading(true);
    const searchEmpReqBody = {
      main_district_id: currentBranch?.value,
      Corrective_action_request_type_id: correctiveActionRequestId1,
      name_partial: "",
      filter_by_op_center: N_CHECKER,
      active_only: Y_CHECKER,
      logged_in_employee_id: auth?.user?.id
    };

    try {
      const res = await getResponseData(
        searchEmpReqBody,
        CF_URLS.correctiveActionRequest.searchEmployeeData,
        1
      );
      let searchEmp = res?.data?.[0]?.map((b) => {
        return {
          ...b,
          label: b?.employee_name.trim(),
          value: b?.locale_id
        };
      });
      const searchEmpdata = getSortedData(searchEmp, "label", "asc");
      setSearchEmployee(searchEmpdata);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  }, [auth?.user?.id, currentBranch?.value]);

  const fetchValidateEmployeePassword = useCallback(
    async (employeeData, index, employeeSignOffcommentsData) => {
      setLoading(true);
      const validateEmpReqBody = {
        main_district_id: currentBranch?.value,
        car_id: carFormData?.carId,
        employee_id: auth.user.id,
        password: "",
        employee_comment: employeeSignOffcommentsData
      };

      try {
        const res = await getResponseData(
          validateEmpReqBody,
          CF_URLS.correctiveActionRequest.validateEmployeePassword,
          2
        );

        const signOffData = dayjs(res?.data?.[0]?.[0]?.signoff_datetime).format(
          dmyDateFormatTime
        );

        const updatedSignoffdata = [...employeeData];

        updatedSignoffdata[index] = {
          ...updatedSignoffdata[index],
          signoff_datetime: signOffData
        };
        setRootCauseEmployees([...updatedSignoffdata]);
        setSaveBtnStatus(true);
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
        setSaveBtnStatus(false);
      } finally {
        setLoading(false);
      }
    },
    [auth.user.id, currentBranch?.value, carFormData?.carId]
  );

  const commonDataTableTheme = createTheme(globalTheme, {
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: "15px"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: "12px"
          }
        }
      }
    }
  });

  const handleRowClick = useCallback(
    (_, rowMeta) => {
      const index = rowMeta.dataIndex;
      setSelectedIndex(index);

      const selectedGridEntry = rootCauseEmployees[index];

      const isMatched =
        selectedGridEntry?.root_cause_employee_id === auth.user.employee_id;
      if (isMatched && saveBtnStatus && !selectedGridEntry?.signoff_datetime) {
        setEnableEmployeeSignoffButton(false);
      } else {
        setEnableEmployeeSignoffButton(true);
      }
    },
    [rootCauseEmployees, auth, saveBtnStatus]
  );

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch: `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (_, dataIndex) => ({
      style: {
        backgroundColor:
          dataIndex === selectedIndex && !disableInvestigationComplete
            ? "var(--color-im-light-blue-100)"
            : ""
      }
    }),
    onRowClick: handleRowClick,

    onChangePage: (currentPage) => {
      // Scroll to the top of the table (or desired element)
      if (tableRef.current) {
        window.scrollTo({
          top: tableRef.current.offsetTop,
          behavior: "smooth"
        });
      }
    }
  };
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  const handleDeleteRow = (rowIndex) => {
    setRootCauseEmployees((prevData) =>
      prevData.filter((_, index) => index !== rowIndex)
    );
    setSelectedIndex(null);
    setSaveBtnStatus(false);
  };
  const columns = [
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const data = rootCauseEmployees[rowIndex];
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <IconButton
                disabled={data?.signoff_datetime}
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                sx={{
                  color: data?.signoff_datetime
                    ? "rgba(255, 255, 255, 0.5)"
                    : inherit,
                  pointerEvents: data?.signoff_datetime ? none : auto
                }}
                onClick={() => handleDeleteRow(rowIndex)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          );
        }
      }
    },
    {
      name: "root_cause_employee_name",
      label: t("correctiveActionRequest.rootCauseEmployee")
    },
    {
      name: "signoff_datetime",
      label: t("correctiveActionRequest.signOffDate")
    },
    {
      name: "employee_comments",
      label: t("correctiveActionRequest.employeeComments")
    }
  ];

  const columnOpenMediaWithoutStatus = [
    {
      name: "media_number",
      label: t("correctiveActionRequest.media")
    },
    {
      name: "type",
      label: t("correctiveActionRequest.typeKey")
    },
    {
      name: "resolution",
      label: t("correctiveActionRequest.resolution")
    },

    {
      name: "personnel_contacted_name",
      label: t("correctiveActionRequest.personalContacted")
    },
    {
      name: "display_to_customer_datetime",
      label: t("correctiveActionRequest.displayToCustomer"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {dateTimeFormatByLocale(
                  openMediaList[tableMeta.rowIndex]
                    ?.display_to_customer_datetime,
                  localeByBranch || DEFAULT_LOCALE
                )}
              </Typography>
            </div>
          );
        }
      }
    }
  ];
  const columnOpenMedia = [
    {
      name: "media_number",
      label: t("correctiveActionRequest.media")
    },
    {
      name: "type",
      label: t("correctiveActionRequest.typeKey")
    },
    {
      name: "resolution",
      label: t("correctiveActionRequest.resolution")
    },
    {
      name: "status",
      label: t("correctiveActionRequest.status")
    },
    {
      name: "personnel_contacted_name",
      label: t("correctiveActionRequest.personalContacted")
    },
    {
      name: "display_to_customer_datetime",
      label: t("correctiveActionRequest.displayToCustomer"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {dateTimeFormatByLocale(
                  openMediaList[tableMeta.rowIndex]
                    ?.display_to_customer_datetime,
                  localeByBranch || DEFAULT_LOCALE
                )}
              </Typography>
            </div>
          );
        }
      }
    }
  ];
  const columnsDuplicate = [
    {
      name: "volser",
      label: t("correctiveActionRequest.media")
    },
    {
      name: "detected_datetime",
      label: t("correctiveActionRequest.detectedDateKey"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {dayjs(reRunData[tableMeta.rowIndex]?.task_datetime)
                  .utc()

                  .locale(localeByBranch)
                  .format(dateFormatWithoutTime)}
              </Typography>
            </div>
          );
        }
      }
    },
    {
      name: "task_datetime",
      label: t("correctiveActionRequest.task"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {t("correctiveActionRequest.distribution")}{" "}
                {dayjs(reRunData[tableMeta.rowIndex]?.task_datetime)
                  .utc()

                  .locale(localeByBranch)
                  .format(dateFormatWithoutTime)}
              </Typography>
            </div>
          );
        }
      }
    },
    {
      name: "additional_information",
      label: t("correctiveActionRequest.additionalInformation")
    }
  ];
  const columnContainerReRun = [
    {
      name: "run_name",
      label: t("correctiveActionRequest.runName")
    },
    {
      name: "uploaded_by",
      label: t("correctiveActionRequest.uploadedBy")
    },
    {
      name: "media_number",
      label: t("correctiveActionRequest.mediaNumber")
    },
    {
      name: "last_known_location",
      label: t("correctiveActionRequest.lastKnownLocation")
    },
    {
      name: "date_in",
      label: t("correctiveActionRequest.dateIn"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {reRunData[tableMeta.rowIndex]?.date_in &&
                  dayjs(reRunData[tableMeta.rowIndex]?.date_in)
                    .utc()

                    .locale(localeByBranch)
                    .format(dateFormatWithoutTime)}
              </Typography>
            </div>
          );
        }
      }
    },
    {
      name: "distributing_emp",
      label: t("correctiveActionRequest.distributionEmployee")
    },
    {
      name: "resolution",
      label: t("correctiveActionRequest.resolution")
    },
    {
      name: "where_found",
      label: t("correctiveActionRequest.whereFound")
    },
    {
      name: "display_to_customer_datetime",
      label: t("correctiveActionRequest.displayToCustomer"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {dateTimeFormatByLocale(
                  openMediaList[tableMeta.rowIndex]
                    ?.display_to_customer_datetime,
                  localeByBranch || DEFAULT_LOCALE
                )}
              </Typography>
            </div>
          );
        }
      }
    }
  ];

  const columnWithoutIdentification = [
    {
      name: "media_number",
      label: t("correctiveActionRequest.mediaNumber")
    },
    {
      name: "last_known_location",
      label: t("correctiveActionRequest.lastKnownLocation")
    },
    {
      name: "where_found",
      label: t("correctiveActionRequest.whereFound")
    },
    {
      name: "date_in",
      label: t("correctiveActionRequest.dateIn"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {reRunData[tableMeta.rowIndex]?.date_in &&
                  dayjs(reRunData[tableMeta.rowIndex]?.date_in)
                    .utc()

                    .locale(localeByBranch)
                    .format(dateFormatWithoutTime)}
              </Typography>
            </div>
          );
        }
      }
    },
    {
      name: "distributing_emp",
      label: t("correctiveActionRequest.distributionEmployee")
    },
    {
      name: "resolution",
      label: t("correctiveActionRequest.resolution")
    },

    {
      name: "personnel_contacted",
      label: t("correctiveActionRequest.personnelContacted")
    },
    {
      name: "display_to_customer_datetime",
      label: t("correctiveActionRequest.displayToCustomer"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {dateTimeFormatByLocale(
                  openMediaList[tableMeta.rowIndex]
                    ?.display_to_customer_datetime,
                  localeByBranch || DEFAULT_LOCALE
                )}
              </Typography>
            </div>
          );
        }
      }
    }
  ];
  const columnOpenMediaAudit = [
    {
      name: "customer_number",
      label: t("correctiveActionRequest.customer")
    },
    {
      name: "open_media_number",
      label: t("correctiveActionRequest.media#")
    },
    {
      name: "logical_vault_code",
      label: t("correctiveActionRequest.logicalVault")
    },
    {
      name: "scanned_barcode",
      label: t("correctiveActionRequest.scannedBarcode")
    },
    {
      name: "scanned_location",
      label: t("correctiveActionRequest.scanLocation")
    },
    {
      name: "reconciled_exchange_status",
      label: t("correctiveActionRequest.status")
    },
    {
      name: "match_template",
      label: t("correctiveActionRequest.matchTemplate")
    },
    {
      name: "batch_id",
      label: t("correctiveActionRequest.batchId")
    },
    {
      name: "redistribution_req_flag",
      label: t("correctiveActionRequest.redistributionReq"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>{value === VALUE_Y ? YES : NO}</Typography>
            </div>
          );
        }
      }
    }
  ];
  const columnInventoryPickError = [
    {
      name: "media_number",
      label: t("correctiveActionRequest.mediaNumber")
    },
    {
      name: "batch_customer",
      label: t("correctiveActionRequest.customer")
    },
    {
      name: "transport_number",
      label: t("correctiveActionRequest.transportNumber")
    },
    {
      name: "scanned",
      label: t("correctiveActionRequest.scanned")
    },
    {
      name: "run_name",
      label: t("correctiveActionRequest.runName")
    },
    {
      name: "run_date",
      label: t("correctiveActionRequest.runDate")
    }
  ];
  const columnContainerAudit = [
    {
      name: "customer_number",
      label: t("correctiveActionRequest.customer")
    },
    {
      name: "media_number",
      label: t("correctiveActionRequest.mediaNumber")
    },
    {
      name: "sb_location",
      label: t("correctiveActionRequest.sbLocation")
    },
    {
      name: "employee_name",
      label: t("correctiveActionRequest.scanLocation")
    },
    {
      name: "exception_reason",
      label: t("correctiveActionRequest.exceptionReason")
    },
    {
      name: "current_status",
      label: t("correctiveActionRequest.status")
    },
    {
      name: "employee_name",
      label: t("correctiveActionRequest.employee")
    }
  ];
  const columnContainerMissing = [
    {
      name: "run_name",
      label: t("correctiveActionRequest.runName")
    },
    {
      name: "uploaded_by",
      label: t("correctiveActionRequest.uploadedBy")
    },
    {
      name: "media_number",
      label: t("correctiveActionRequest.mediaNumber")
    },

    {
      name: "resolution",
      label: t("correctiveActionRequest.resolution")
    },
    {
      name: "where_found",
      label: t("correctiveActionRequest.whereFound")
    },
    {
      name: "personnel_contacted",
      label: t("correctiveActionRequest.personalContacted")
    },
    {
      name: "display_to_customer_datetime",
      label: t("correctiveActionRequest.displayToCustomer"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={CorrectiveActionRequestStyles.tableFlex}>
              <Typography>
                {dateTimeFormatByLocale(
                  openMediaList[tableMeta.rowIndex]
                    ?.display_to_customer_datetime,
                  localeByBranch || DEFAULT_LOCALE
                )}
              </Typography>
            </div>
          );
        }
      }
    }
  ];
  const handleChange = (e, newValue) => {
    setSaveBtnStatus(false);
    setCustomer(newValue);
    setDisableType(false);
    getType();
  };

  const handleDetectedDate = (e) => {
    setSaveBtnStatus(false);
    setCarData((prev) => ({
      ...prev,
      detectedDate: dayjs(e)
    }));
  };
  const handleType = (_, val) => {
    setSaveBtnStatus(false);
    if (
      val?.corrective_action_request_type_id === correctiveActionRequestId2 ||
      val?.corrective_action_request_type_id === correctiveActionRequestId1
    ) {
      setCarData((prev) => ({
        ...prev,
        type: val,
        displayToCustomer: VALUE_Y
      }));
    } else {
      setCarData((prev) => ({
        ...prev,
        type: val,
        displayToCustomer: VALUE_N
      }));
    }
  };
  const handleDisplayToCustomer = (_, val) => {
    setSaveBtnStatus(false);
    if (carFormData?.displayToCustomer === VALUE_Y) {
      setCarData((prev) => ({
        ...prev,
        displayToCustomer: VALUE_N
      }));
    } else {
      setCarData((prev) => ({
        ...prev,
        displayToCustomer: VALUE_Y
      }));
    }
  };
  const handleNotifyAccountOwner = (_, val) => {
    setSaveBtnStatus(false);
    if (carFormData?.notifyAccountOwner === VALUE_Y) {
      setCarData((prev) => ({
        ...prev,
        notifyAccountOwner: VALUE_N
      }));
    } else {
      setCarData((prev) => ({
        ...prev,
        notifyAccountOwner: VALUE_Y
      }));
    }
  };
  const fetchLocalDateTime = async () => {
    try {
      const requestBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch()
      });
      const res = await getResponseData(
        requestBody,
        `${CF_URLS.containerProcessing.localDateTime}`,
        1
      );
      setLocalDateTime(res?.data[0][0]);
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setLoading(false);
      return false;
    }
  };

  const createEmailBody = (result, media) => {
    let emailBody = `${t(
      "correctiveActionRequest.correctiveActionRequestDetail"
    )}\n\n`;
    emailBody += `${t(
      "correctiveActionRequest.detectedDate"
    )}: ${dateTimeFormatByLocale(
      result?.ltz_detected_datetime,
      localeByBranch || DEFAULT_LOCALE
    )}\n`;
    emailBody += `${t("correctiveActionRequest.carId")}: ${result?.car_id}\n${t(
      "correctiveActionRequest.customer"
    )}: ${result?.customer_number}\n${t("correctiveActionRequest.status")}: ${
      result?.car_status_descr
    }\nType: ${result?.car_type_descr}\n`;
    emailBody += `${t(
      "correctiveActionRequest.loggedDate"
    )}: ${dateTimeFormatByLocale(
      result?.ltz_logged_datetime,
      localeByBranch || DEFAULT_LOCALE
    )}\n`;
    emailBody += `${t("correctiveActionRequest.displayCarToCustomer")}: ${
      result?.display_to_customer_flag === VALUE_Y
        ? t("common.yes")
        : t("common.no")
    }\n${t("correctiveActionRequest.loggedBy")}: ${
      result?.logged_by_employee
    }\n\n`;
    emailBody += `${t("correctiveActionRequest.identificationPhase")}:\n`;
    const openMediaData = media?.filter((obj) => obj?.type === openMedia);
    const containerMedia = media?.filter((obj) => obj?.type === container);
    if (openMediaData?.length > 0) {
      emailBody += `${t("correctiveActionRequest.openMediaCount")}: ${
        openMediaData?.length
      }\n`;
    }
    if (containerMedia?.length > 0) {
      emailBody += `${t("correctiveActionRequest.counterCount")}: ${
        containerMedia?.length
      }\n`;
    }
    emailBody += `${t("correctiveActionRequest.comments")}: ${
      result?.why_customer_called
    }\n\n`;

    //  Investigation phase
    emailBody += `${t("correctiveActionRequest.investigationPhase")}:\n`;

    if (result?.investigation_comment) {
      emailBody += `${t("correctiveActionRequest.investigationComment")}: ${
        result?.investigation_comment
      }\n\n`;
    }
    if (result?.root_cause_descr) {
      emailBody += `${t("correctiveActionRequest.rootCauseDesc")}: ${
        result?.root_cause_descr
      }\n\n`;
    }
    if (result?.failure_point_descr) {
      emailBody += `${t("correctiveActionRequest.failurePointDesc")}: ${
        result?.failure_point_descr
      }\n\n`;
    }
    if (result?.employee_id_list) {
      emailBody += `${t("correctiveActionRequest.employeeList")}: ${
        result?.employee_id_list
      }\n\n`;
    }

    //Resolution phase
    if (result?.resolution_comment) {
      emailBody += `${t("correctiveActionRequest.resolutionPhase")} -${t(
        "correctiveActionRequest.resolvedBy"
      )}:\n${result?.resolution_comment || ""}\n\n`;
    }
    emailBody += `${t("correctiveActionRequest.customerComments")}: ${
      result?.CustomerComment || ""
    }\n`;

    return emailBody;
  };
  // email sending task
  const emailSendingTask = async (result, media) => {
    setLoading((prev) => true);
    try {
      let recipients = [];
      // Logged In Employee
      const loggedInEmail = auth?.user?.business_email_addr;
      const loggedInName =
        auth?.user?.lower_first_name + auth?.user?.lower_last_name;
      const loggedInEmployee = {
        emailAddress: loggedInEmail,
        name: loggedInName
      };
      recipients?.push(loggedInEmployee);
      const emailBody = createEmailBody(result, media);
      const response = await sendEmail(
        recipients,
        emailBody,
        `${t("correctiveActionRequest.carCustomer")} ${result?.customer_number}`
      );
      if (response?.data?.isSuccess) {
        // email sent
        setLoading(false);
        return true;
      } else {
        setThrowError(true);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setLoading(false);
        return false;
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setLoading(false);
      return false;
    }
  };
  const getType = useCallback(async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        display_manual_only_flag: record?.car_id ? VALUE_N : VALUE_Y
      };
      let response = await getResponseData(
        reqBody,
        `${CF_URLS?.correctiveActionRequest.getType}`,
        1
      );
      if (response.data[0]) {
        setTypeData(
          response.data[0]?.sort((a, b) =>
            a?.corrective_action_request_type_descr?.localeCompare(
              b?.corrective_action_request_type_descr
            )
          )
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  }, [currentBranch, record?.car_id]);
  const hanleSave = async () => {
    try {
      setLoading(true);
      if (!customer.value) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
        setThrowError(true);
        setThrowErrorMessage(
          errDesc[0]?.descr?.replace("|", t("correctiveActionRequest.customer"))
        );
        setOpenChangesModal(false);
        setLoading(false);
      } else if (!carFormData?.type) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
        setThrowError(true);
        setThrowErrorMessage(
          errDesc[0]?.descr?.replace("|", t("correctiveActionRequest.type"))
        );
        setLoading(false);
        setOpenChangesModal(false);
      } else if (commentList?.length === 0) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
        setThrowError(true);
        setThrowErrorMessage(
          errDesc[0]?.descr?.replace(
            "|",
            t("correctiveActionRequest.identificationPhase")
          )
        );
        setOpenChangesModal(false);
        setLoading(false);
      } else {
        const reqBody = {
          main_district_id: currentBranch?.value,
          customer_id: customer?.value,
          corrective_action_request_type_id:
            carFormData?.type?.corrective_action_request_type_id,
          detected_datetime: dayjs(carFormData?.detectedDate).format(
            ymdDateFormatTimeSec
          ),
          logging_employee_id: auth?.user?.id,
          task_type_id: "",
          task_id: "",
          identification_comment: commentList
            ?.map((o) => o?.comment)
            ?.map((obj) => obj?.toString() + "\r\n\r\n")
            .join("")
            ?.replace(/'/g, "`"),
          customer_display_flag: carFormData?.displayToCustomer
            ? carFormData?.displayToCustomer
            : VALUE_N,
          notify_account_owner_flag: carFormData?.notifyAccountOwner
            ? carFormData?.notifyAccountOwner
            : VALUE_N,
          manual_create_flag: VALUE_Y,
          om_list: openMediaList
            ?.filter((obj) => obj?.type === openMedia)
            ?.map((o) => o?.media_number)
            ?.map((obj) => obj?.toString().padEnd(20, " "))
            .join(""),
          container_list: openMediaList
            ?.filter((obj) => obj?.type === container)
            ?.map((o) => o?.media_number)
            ?.map((obj) => obj?.toString().padEnd(20, " "))
            .join(""),
          billable_flag: selectBillable,
          logging_personnel_id: ""
        };
        let response = await getResponseData(
          reqBody,
          `${CF_URLS?.correctiveActionRequest.insertCar}`,
          4
        );
        if (response.data[0][0]?.corrective_action_request_id) {
          setLoading(false);
          if (newClick) {
            setCarData({
              detectedDate: dayjs(new Date()),
              notify_account_owner_flag: VALUE_N,
              customer_display_flag: VALUE_N,
              type: null,
              manually_created_car_flag: VALUE_Y
            });
            setCarId("");
            setMediaData([]);
            setCustomerComments("");
            setCustomer(null);
            setCommentList([]);
            setNewCLick(false);
            setOpenChangesModal(false);
            setThrowError(false);
            setThrowErrorMessage(null);
            setDisableType(true);
            setDisableCarForm(false);
            navigate(
              `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
              { state: null }
            );
          } else {
            setCarId(response.data[0][0]?.corrective_action_request_id);
          }
          setSaveBtnStatus(true);
          setDisableType(true);
          setDisableCarForm(true);
        } else {
          const errDescr = await getMessageFromCode(response.data[0][0]?.error);
          setLoading(false);
          setThrowError(true);
          setThrowErrorMessage(errDescr[0]?.descr?.replace("|", carId));
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getStatus = (id) => {
    if (id === mediaProgramTypeId) {
      return openMedia;
    } else if (id === ecmtCONTAINER) {
      return container;
    } else if (id === ecmtTRANSPORT) {
      return transport;
    } else {
      return unknown;
    }
  };

  const getCarDetails = async () => {
    try {
      if (!(record?.car_id || carId)) return;
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        car_id: record?.car_id || carId
      };
      const response = await getResponseData(
        reqBody,
        `${CF_URLS?.correctiveActionRequest.getCarDetails}`,
        5
      );
      const result = response.data[0][0];
      const rootCauseEmployeeList = response.data[2].map((item) => {
        return {
          ...item,
          signoff_datetime: item.signoff_datetime
            ? dayjs(item.signoff_datetime).format(dmyDateFormatTime)
            : ""
        };
      });
      const mappedEmployeeListResult = getSortedData(
        rootCauseEmployeeList,
        "root_cause_employee_name",
        "asc"
      );
      if (result) {
        setCarData({
          ...carFormData,
          carId: result?.car_id,
          carStatusId: result?.car_status_id,
          carTypeId: result?.car_type_id,
          loggedEBy: result?.logged_by_employee,
          loggedDate: result?.ltz_logged_datetime,
          notifyAccountOwner: result?.notify_account_owner_flag,
          manually_created_car_flag: result?.manually_created_car_flag,
          displayToCustomer: result?.display_to_customer_flag,
          detectedDate: dayjs(result?.ltz_detected_datetime),
          car_timestamp: result?.car_timestamp,
          status: result?.car_status_descr,
          why_customer_called: result?.why_customer_called,
          resolution_comment: result?.resolution_comment,
          type: typeData?.find(
            (obj) =>
              obj?.corrective_action_request_type_id === result?.car_type_id
          ),
          root_cause_descr: result?.root_cause_descr,
          failure_point_descr: result?.failure_point_descr,
          investigation_comment: result?.investigation_comment,
          employee_id_list: result?.employee_id_list
        });

        if (result?.car_status_id === correctiveActionRequestId1) {
          setDisableInvestigationComplete(false);
          if (result?.car_type_id === correctiveActionRequestId14) {
            setDisableIdentification(true);
          }
        } else if (result?.car_status_id === correctiveActionRequestId2) {
          setDisableIdentification(true);
          setDisableInvestigationComplete(true);
          setDisableResolution(false);
          setSaveBtnStatus(true);
        } else if (result?.car_status_id === correctiveActionRequestId3) {
          setDisableResolution(true);
          setDisableIdentification(true);
          setDisableInvestigationComplete(true);
        }

        setCustomer(
          customers?.find((obj) => obj?.value === result?.customer_id)
        );
        setCommentList(
          result?.why_customer_called?.split("\r\n\r\n")?.map((obj) => {
            return { comment: obj };
          })
        );
        setBillCommentData(
          result?.why_customer_called?.split("\r\n")?.map((obj) => {
            return { comment: obj };
          })
        );
        setShowInvestigationComment(
          result?.investigation_comment
            ?.split("\r\n\r\n")
            .filter((item) => item !== "")
            ?.map((obj) => {
              return obj;
            })
        );
        setRootCauseEmployees([...mappedEmployeeListResult]);

        setOpenMediaList(
          response.data[3]?.map((obj) => {
            obj.type = getStatus(obj?.media_program_type_id);
            return obj;
          })
        );
      }
      if (response.data[1][0]?.comments) {
        setCustomerComments(response.data[1][0]?.comments);
      }
      if (result?.resolution_comment) {
        setResolutionCommentList(
          result?.resolution_comment?.split("\r\n\r\n")?.map((obj) => {
            return { comment: obj };
          })
        );
      }
      if (response.data[4][0]) {
        setReRunData(response.data[4]);
        setSelectBillable(
          response.data[4][0]?.billable_flag === VALUE_Y ? bill : doNotBill
        );
      }
      if (carId) {
        emailSendingTask(
          result,
          response.data[3]?.map((obj) => {
            obj.type = getStatus(obj?.media_program_type_id);
            return obj;
          })
        );
      }
      setLoading(false);
      setThrowError(false);
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  // loading employee data from firestore => employee collection
  const getEmployeesData = async () => {
    setLoading(true);
    try {
      const result = await getEmployeeDetails();
      const response = result?.map((res) => {
        return {
          label: `${res?.last_name}, ${res?.first_name}`,
          value: res?.employee_id
        };
      });
      response.sort((a, b) => a.label.localeCompare(b.label));

      const currentUserId = auth?.user?.id;
      const employee = result.find((emp) => emp.employee_id === currentUserId);

      if (employee) {
        const { last_name, first_name, middle_name } = employee;
        const middleInitial = middle_name ? `${middle_name.charAt(0)}.` : "";
        const formattedName = `${last_name}, ${first_name} ${middleInitial}`;
        setSelectedEmployeeName(formattedName);
      } else {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode26078);
        setThrowError(true);
        setThrowErrorMessage(errDesc[0]?.descr);
      }
      setLoading(false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  useEffect(() => {
    if (currentBranch?.value) {
      !newClick && getCarDetails();
    }
    fetchLocalDateTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, typeData, carId]);
  useEffect(() => {
    if (record?.car_id) {
      setCarId(record?.car_id);
      getType();
      setDisableCarForm(true);
    }
  }, [record, getType]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [newClick]);
  const newCar = () => {
    if (!saveBtnStatus) {
      setNewCLick(true);
      setOpenChangesModal(true);
    } else {
      window.scrollTo(0, 0);
      setCarData({
        detectedDate: dayjs(new Date()),
        notify_account_owner_flag: VALUE_N,
        customer_display_flag: VALUE_N,
        type: null,
        manually_created_car_flag: VALUE_Y
      });
      setCustomerComments("");
      setCarId("");
      setCustomer(null);
      setCommentList([]);
      setResolutionCommentList([]);
      setOpenMediaList([]);
      setMediaData([]);
      setSaveBtnStatus(true);
      setDisableCarForm(false);
      setRootCauseEmployees([]);
      setShowInvestigationComment([]);
      setFailurePoint(null);
      setRootCause(null);
      setDisableIdentification(false);
      setDisableInvestigationComplete(true);
      setDisableResolution(true);
      setDisableType(true);
      setMediaData([]);
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
        { state: null }
      );
    }
  };

  const handleInvestigationPhase = async (val) => {
    try {
      setLoading(true);
      const missingDates = rootCauseEmployees?.every(
        (item) => item.signoff_datetime.length
      );

      if (
        !missingDates &&
        val === VALUE_Y &&
        rootCause?.root_cause_descr === t("correctiveActionRequest.employee")
      ) {
        setThrowError(true);
        setThrowErrorMessage(t("correctiveActionRequest.investicateText"));
        setLoading(false);
        return;
      }
      if (!isBillable) {
        if (
          !showInvestigationComment?.length ||
          showInvestigationComment.filter((item) => item.trim() !== "")
            .length === 0
        ) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
          setThrowError(true);
          setThrowErrorMessage(
            errDesc[0]?.descr?.replace(
              "|",
              t("correctiveActionRequest.investigationPhase")
            )
          );
          setOpenChangesModal(false);
          setLoading(false);
        } else if (!failurePoint) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
          setThrowError(true);
          setThrowErrorMessage(
            errDesc[0]?.descr?.replace(
              "|",
              t("correctiveActionRequest.failurePointError")
            )
          );
          setOpenChangesModal(false);
          setLoading(false);
        } else if (!rootCause) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
          setThrowError(true);
          setThrowErrorMessage(
            errDesc[0]?.descr?.replace(
              "|",
              t("correctiveActionRequest.rootCauseError")
            )
          );
          setOpenChangesModal(false);
          setLoading(false);
        } else if (
          rootCause?.root_cause_descr ===
            t("correctiveActionRequest.employee") &&
          rootCauseEmployees?.length === 0
        ) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode80078);
          setThrowError(true);
          setThrowErrorMessage(errDesc[0]?.descr);
          setLoading(false);
        } else {
          setLoading(true);
          const reqBody = {
            main_district_id: currentBranch?.value,
            car_id: carFormData?.carId,
            failure_point_id: failurePoint.failure_point_id,
            root_cause_id: rootCause.root_cause_id,
            investigation_comment: showInvestigationComment
              ?.map((obj) => obj?.toString() + "\r\n\r\n")
              .join("")
              ?.replace(/'/g, "`"),
            employee_id_list: rootCauseEmployees
              ?.map((o) => o?.root_cause_employee_id)
              ?.map((obj) => obj?.toString().padEnd(10, " "))
              .join(""),
            investigation_complete_flag: val || "",
            display_customer_flag: carFormData?.displayToCustomer
              ? carFormData?.displayToCustomer
              : VALUE_N,
            notify_account_owner_flag: carFormData?.notifyAccountOwner
              ? carFormData?.notifyAccountOwner
              : VALUE_N,
            car_timestamp: carFormData?.car_timestamp,
            identification_comment: commentList
              ?.map((o) => o?.comment)
              ?.map((obj) => obj?.toString() + "\r\n\r\n")
              .join(" ")
              ?.replace(/'/g, "`"),
            investigation_employee_id: "",
            om_list: openMediaList
              ?.filter((obj) => obj?.type === openMedia)
              ?.map((o) => o?.media_number)
              ?.map((obj) => obj?.toString().padEnd(20, " "))
              .join(""),
            container_list: openMediaList
              ?.filter((obj) => obj?.type === container)
              ?.map((o) => o?.media_number)
              ?.map((obj) => obj?.toString().padEnd(20, " "))
              .join(""),
            billable_flag: VALUE_N
          };
          let response = await getResponseData(
            reqBody,
            `${CF_URLS?.correctiveActionRequest.updatecarininitiatedstatus}`,
            2
          );

          if (response.data[0][0]?.new_car_status_id) {
            if (newClick) {
              setCarData({
                detectedDate: dayjs(new Date()),
                notify_account_owner_flag: VALUE_N,
                customer_display_flag: VALUE_N,
                type: null,
                manually_created_car_flag: VALUE_Y
              });
              setCarId("");
              setOpenMediaList([]);
              setMediaData([]);
              setCustomerComments("");
              setCustomer(null);
              setCommentList([]);
              setNewCLick(false);
              setOpenChangesModal(false);
              setThrowError(false);
              setThrowErrorMessage(null);
              setDisableType(true);
              setDisableCarForm(false);
              setRootCauseEmployees([]);
              setShowInvestigationComment([]);
              setFailurePoint(null);
              setRootCause(null);
              setSelectedIndex(null);
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
                { state: null }
              );
            } else {
              getCarDetails();
            }
            setLoading(false);
            setSaveBtnStatus(true);
          } else {
            if (response?.data?.[1]?.[0]?.error === errorMsgs.errorCode63679) {
              const errDesc = await getMessageFromCode(
                response.data[1][0]?.error
              );
              setThrowError(true);
              setThrowErrorMessage(errDesc[0]?.descr);
              setLoading(false);
            } else if (
              response?.data?.[1]?.[0].error === errorMsgs.errorCode63680
            ) {
              const errDesc = await getMessageFromCode(
                response.data[1][0]?.error
              );
              setThrowError(true);
              setThrowErrorMessage(errDesc[0]?.descr);
              setLoading(false);
            } else if (
              response?.data?.[1]?.[0].error === errorMsgs.errorCode59028
            ) {
              const errDescr = await getMessageFromCode(
                response.data[1][0]?.error
              );
              setLoading(false);
              setThrowError(true);
              setThrowErrorMessage(
                errDescr[0]?.descr?.replace(
                  "|",
                  t("correctiveActionRequest.car")
                )
              );
            }
            setLoading(false);
          }
        }
      } else {
        const reqBody = {
          main_district_id: currentBranch?.value,
          car_id: carFormData?.carId,
          failure_point_id: failurePoint?.failure_point_id,
          root_cause_id: rootCause?.root_cause_id && rootCause?.root_cause_id,
          investigation_comment: showInvestigationComment
            ?.map((obj) => obj?.toString() + "\r\n\r\n")
            .join("")
            ?.replace(/'/g, "`"),
          employee_id_list: rootCauseEmployees
            ?.map((o) => o?.root_cause_employee_id)
            ?.map((obj) => obj?.toString().padEnd(10, " "))
            .join(""),
          investigation_complete_flag: val || "",
          display_customer_flag: carFormData?.displayToCustomer
            ? carFormData?.displayToCustomer
            : VALUE_N,
          notify_account_owner_flag: carFormData?.notifyAccountOwner
            ? carFormData?.notifyAccountOwner
            : VALUE_N,
          car_timestamp: carFormData?.car_timestamp,
          identification_comment: billComentList
            ?.map((o) => o?.comment)
            ?.map((obj) => obj?.toString() + "\r\n\r\n")
            .join("")
            ?.replace(/'/g, "`"),
          investigation_employee_id: "",
          om_list: openMediaList
            ?.filter((obj) => obj?.type === openMedia)
            ?.map((o) => o?.media_number)
            ?.map((obj) => obj?.toString().padEnd(20, " "))
            .join(""),
          container_list: openMediaList
            ?.filter((obj) => obj?.type === container)
            ?.map((o) => o?.media_number)
            ?.map((obj) => obj?.toString().padEnd(20, " "))
            .join(""),
          billable_flag: selectBillable === bill ? VALUE_Y : VALUE_N
        };
        let response = await getResponseData(
          reqBody,
          `${CF_URLS?.correctiveActionRequest.updatecarininitiatedstatus}`,
          2
        );
        setIsBillable(false);
        if (response.data[0][0]?.new_car_status_id) {
          if (newClick) {
            setCarData({
              detectedDate: dayjs(new Date()),
              notify_account_owner_flag: VALUE_N,
              customer_display_flag: VALUE_N,
              type: null,
              manually_created_car_flag: VALUE_Y
            });
            setCarId("");
            setOpenMediaList([]);
            setMediaData([]);
            setCustomerComments("");
            setCustomer(null);
            setCommentList([]);
            setNewCLick(false);
            setOpenChangesModal(false);
            setThrowError(false);
            setThrowErrorMessage(null);
            setDisableType(true);
            setDisableCarForm(false);
            setRootCauseEmployees([]);
            setShowInvestigationComment([]);
            setFailurePoint(null);
            setRootCause(null);
            setSelectedIndex(null);
            navigate(
              `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
              { state: null }
            );
          } else {
            getCarDetails();
          }
          setLoading(false);
          setSaveBtnStatus(true);
        } else {
          if (response?.data?.[1]?.[0]?.error === errorMsgs.errorCode63679) {
            const errDesc = await getMessageFromCode(
              response.data[1][0]?.error
            );
            setThrowError(true);
            setThrowErrorMessage(errDesc[0]?.descr);
            setLoading(false);
          } else if (
            response?.data?.[1]?.[0].error === errorMsgs.errorCode63680
          ) {
            const errDesc = await getMessageFromCode(
              response.data[1][0]?.error
            );
            setThrowError(true);
            setThrowErrorMessage(errDesc[0]?.descr);
            setLoading(false);
          } else if (
            response?.data?.[1]?.[0].error === errorMsgs.errorCode59028
          ) {
            const errDescr = await getMessageFromCode(
              response.data[1][0]?.error
            );
            setLoading(false);
            setThrowError(true);
            setThrowErrorMessage(
              errDescr[0]?.descr?.replace("|", t("correctiveActionRequest.car"))
            );
          }
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const savePahse = () => {
    if (carFormData?.carStatusId === correctiveActionRequestId1) {
      handleInvestigationPhase();
    } else if (carFormData?.carStatusId === correctiveActionRequestId2) {
      handleResolutionUpdate();
    } else {
      hanleSave();
    }
  };

  const handleResolutionUpdate = async (val) => {
    try {
      setLoading(true);
      if (!isBillable) {
        if (resolutionCommentList?.length === 0) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
          setThrowError(true);
          setThrowErrorMessage(
            errDesc[0]?.descr?.replace(
              "|",
              t("correctiveActionRequest.resolutionPhase")
            )
          );
          setOpenChangesModal(false);
          setLoading(false);
        } else {
          const reqBody = {
            main_district_id: currentBranch?.value,
            car_id: carId,
            resolution_comment: resolutionCommentList
              ?.map((o) => o?.comment)
              ?.map((obj) => obj?.toString() + "\r\n\r\n")
              .join("")
              ?.replace(/'/g, "`"),
            resolution_complete_flag: val,
            resolution_employee_id: "",
            display_customer_flag: carFormData?.displayToCustomer
              ? carFormData?.displayToCustomer
              : VALUE_N,
            notify_account_owner_flag: carFormData?.notifyAccountOwner
              ? carFormData?.notifyAccountOwner
              : VALUE_N,
            car_timestamp: carFormData?.car_timestamp,
            identification_comment: commentList
              ?.map((o) => o?.comment)
              ?.map((obj) => obj?.toString() + "\r\n\r\n")
              .join("")
              ?.replace(/'/g, "`"),
            billable_flag: selectBillable === bill ? VALUE_Y : VALUE_N
          };
          let response = await getResponseData(
            reqBody,
            `${CF_URLS?.correctiveActionRequest.updateResolution}`,
            4
          );
          if (response.data[0][0]?.new_car_status_id) {
            if (newClick) {
              setCarData({
                detectedDate: dayjs(new Date()),
                notify_account_owner_flag: VALUE_N,
                customer_display_flag: VALUE_N,
                type: null,
                manually_created_car_flag: VALUE_Y
              });
              setCarId("");
              setOpenMediaList([]);
              setMediaData([]);
              setCustomerComments("");
              setCustomer(null);
              setCommentList([]);
              setResolutionCommentList([]);
              setNewCLick(false);
              setOpenChangesModal(false);
              setThrowError(false);
              setThrowErrorMessage(null);
              setDisableType(true);
              setDisableCarForm(false);
              setDisableResolution(true);
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
                { state: null }
              );
            } else {
              getCarDetails();
            }
            emailSendingTask();
            setLoading(false);

            setSaveBtnStatus(true);
          } else {
            const errDescr = await getMessageFromCode(
              response.data[0][0]?.error
            );
            setLoading(false);
            if (response.data[0][0]?.error === errorMsgs?.errorCode63920) {
              setOpenDiscrepenceModal(true);
              setThrowErrorMessage(errDescr[0]?.descr?.replace("|", carId));
            } else {
              setThrowError(true);
              setThrowErrorMessage(errDescr[0]?.descr?.replace("|", carId));
            }
          }
        }
      } else {
        const reqBody = {
          main_district_id: currentBranch?.value,
          car_id: carId,
          resolution_comment: resolutionCommentList
            ?.map((o) => o?.comment)
            ?.map((obj) => obj?.toString() + "\r\n\r\n")
            .join("")
            ?.replace(/'/g, "`"),
          resolution_complete_flag: val,
          resolution_employee_id: "",
          display_customer_flag: carFormData?.displayToCustomer
            ? carFormData?.displayToCustomer
            : VALUE_N,
          notify_account_owner_flag: carFormData?.notifyAccountOwner
            ? carFormData?.notifyAccountOwner
            : VALUE_N,
          car_timestamp: carFormData?.car_timestamp,
          identification_comment: billComentList
            ?.map((o) => o?.comment)
            ?.map((obj) => obj?.toString() + "\r\n\r\n")
            .join("")
            ?.replace(/'/g, "`"),
          billable_flag: VALUE_N
        };
        let response = await getResponseData(
          reqBody,
          `${CF_URLS?.correctiveActionRequest.updateResolution}`,
          4
        );
        if (response.data[0][0]?.new_car_status_id) {
          if (newClick) {
            setCarData({
              detectedDate: dayjs(new Date()),
              notify_account_owner_flag: VALUE_N,
              customer_display_flag: VALUE_N,
              type: null,
              manually_created_car_flag: VALUE_Y
            });
            setCarId("");
            setOpenMediaList([]);
            setMediaData([]);
            setCustomerComments("");
            setCustomer(null);
            setCommentList([]);
            setResolutionCommentList([]);
            setNewCLick(false);
            setOpenChangesModal(false);
            setThrowError(false);
            setThrowErrorMessage(null);
            setDisableType(true);
            setDisableCarForm(false);
            setDisableResolution(true);
            navigate(
              `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
              { state: null }
            );
          } else {
            getCarDetails();
          }
          emailSendingTask();
          setLoading(false);
          setSaveBtnStatus(true);
        } else {
          const errDescr = await getMessageFromCode(response.data[0][0]?.error);
          setLoading(false);
          if (response.data[0][0]?.error === errorMsgs?.errorCode63920) {
            setOpenDiscrepenceModal(true);
            setThrowErrorMessage(errDescr[0]?.descr?.replace("|", carId));
          } else {
            setThrowError(true);
            setThrowErrorMessage(errDescr[0]?.descr?.replace("|", carId));
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const handleInvestigationComment = () => {
    setSaveBtnStatus(false);
    if (addInvestigationComment?.length) {
      const newComment = `${auth?.user?.first_name}, ${
        auth?.user?.last_name
      } ${dayjs
        .utc(date?.local_getdate)
        .format(ymdDateFormatTime)}: ${addInvestigationComment}`;
      setShowInvestigationComment((prevComments) => [
        ...prevComments,
        newComment
      ]);
    }
    setIsModalInvestigationVisible(false);
    setAddInvestigationComment("");
  };
  const handleButtonClick = async () => {
    const commentLength = showInvestigationComment
      ?.map((obj) => obj?.toString())
      .join("")?.length;
    if (commentLength > modalTextLimit) {
      const errMsg = await getMessageFromCode(errorMsgs.errorCode63919);
      setThrowError(true);
      setThrowErrorMessage(errMsg[0]?.descr);
    } else {
      setIsModalInvestigationVisible(true);
      setAddInvestigationComment("");
    }
  };

  const handleAddRootCauseEmp = () => {
    setIsModalRootCauseVisible(true);
  };
  const handleEmployeeSignoff = async () => {
    await getEmployeesData();
    setIsModalEmployeeSignoffVisible(true);
  };
  const handleSignoffAddComment = async () => {
    const updatedEmployee = [...rootCauseEmployees];
    updatedEmployee[selectedIndex] = {
      ...updatedEmployee[selectedIndex],
      employee_comments: employeeSignOffcommentsData
    };
    await fetchValidateEmployeePassword(
      updatedEmployee,
      selectedIndex,
      employeeSignOffcommentsData
    );
    setEmployeeSignoffCommentsData("");
    setIsModalEmployeeSignoffVisible(false);
  };

  const addRootCauseData = () => {
    const isDuplicate = rootCauseEmployees.some(
      (employee) =>
        employee.root_cause_employee_id === selectedEmployee.employee_id
    );

    if (isDuplicate) {
      setThrowError(true);
      setThrowErrorMessage(t("correctiveActionRequest.duplicateEmployee"));
      setIsModalRootCauseVisible(false);
      setIsDuplicateEmp(true);
      return;
    }

    if (selectedEmployee) {
      const newEmployeeData = {
        ...selectedEmployee,
        root_cause_employee_name: selectedEmployee.employee_name,
        root_cause_employee_id: selectedEmployee?.employee_id,
        employee_comments: "",
        signoff_datetime: ""
      };

      setRootCauseEmployees([...rootCauseEmployees, newEmployeeData]);
      setSaveBtnStatus(false);

      setIsModalRootCauseVisible(false);
      setSelectedEmployee("");
      setIsDuplicateEmp(false);
    }
  };
  const handleSelectionChange = (event, value) => {
    setSaveBtnStatus(false);
    setFailurePoint(value);
  };
  const maxCount = (max, comment) => {
    const result = max - comment;
    const safeResult = result < 0 ? 0 : result; // Ensures result does not go negative
    return safeResult;
  };

  const handleDetailsReportDownload = async () => {
    try {
      const carId = carFormData?.carId?.padStart(10, " ");
      const requestBody = {
        main_district_id: currentBranch?.district_id,
        district_id: currentBranch?.district_id,
        car_id_list: carId,
        user_name: getUserName(auth?.user),
        report_format: pdfFormat,
        district_name: currentBranch?.name
      };

      setLoading(true);
      const reqBody = JSON.stringify(requestBody);

      const reportResponse = await printReport(
        reqBody,
        CF_URLS.printReports.correctiveAction.correctiveActionRequestDetail
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <CircularLoaderFullPage loading={loading} />}
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage(null);
                }}
              >
                <GridCloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}

      <br />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
          >
            {" "}
            {t("correctiveActionRequest.carId")}
          </Typography>
          <Typography variant="body1">
            {carFormData?.carId ? carFormData?.carId : "-"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
          >
            {" "}
            {t("correctiveActionRequest.status")}
          </Typography>
          <Typography variant="body1">
            {carFormData?.status ? carFormData?.status : "-"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
          >
            {" "}
            {t("correctiveActionRequest.loggedDate")}
          </Typography>
          <Typography variant="body1">
            {carFormData?.loggedDate
              ? dateTimeFormatByLocale(
                  carFormData?.loggedDate,
                  localeByBranch || DEFAULT_LOCALE
                )
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
          >
            {" "}
            {t("correctiveActionRequest.loggedBy")}
          </Typography>
          <Typography variant="body1">
            {carFormData?.loggedEBy ? carFormData?.loggedEBy : "-"}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <DatePickerField
            id="detected date"
            name="detectedDate"
            label={t("correctiveActionRequest.detectedDate")}
            maxDate={dayjs(new Date())}
            locale={localeByBranch}
            value={carFormData?.detectedDate}
            disabled={disableCarForm}
            onChange={(val) => handleDetectedDate(val)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CustomersField
            options={customers}
            disabled={disableCarForm}
            value={customer}
            handleOnChange={handleChange}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            clearIcon={<GridCloseIcon />}
            id="type"
            name="type"
            size="medium"
            options={typeData}
            fullWidth
            value={carFormData?.type}
            onChange={handleType}
            disabled={disableType}
            getOptionLabel={(option) =>
              option.corrective_action_request_type_descr
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("correctiveActionRequest.type")}
              />
            )}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3} sx={{ display: "flex" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={carFormData?.displayToCustomer === VALUE_Y}
                  onChange={handleDisplayToCustomer}
                />
              }
              label={t("correctiveActionRequest.displayToCustomer")}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={carFormData?.notifyAccountOwner === VALUE_Y}
                  onChange={handleNotifyAccountOwner}
                />
              }
              label={t("correctiveActionRequest.notifyAccountOwner")}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={CorrectiveActionRequestStyles.titleStyle}
        >
          {t("correctiveActionRequest.identificationPhase")}
        </Typography>
      </Grid>

      {(carFormData?.type?.corrective_action_request_type_id ===
        correctiveActionRequestId1 ||
        carFormData?.type?.corrective_action_request_type_id ===
          correctiveActionRequestId11 ||
        carFormData?.type?.corrective_action_request_type_id ===
          correctiveActionRequestId2 ||
        carFormData?.type?.corrective_action_request_type_id ===
          correctiveActionRequestId8 ||
        (carFormData?.type?.corrective_action_request_type_id ===
          correctiveActionRequestId3 &&
          carFormData?.manually_created_car_flag === VALUE_Y) ||
        carFormData?.type?.corrective_action_request_type_id ===
          correctiveActionRequestId10 ||
        carFormData?.type?.corrective_action_request_type_id ===
          correctiveActionRequestId12 ||
        carFormData?.type?.corrective_action_request_type_id ===
          correctiveActionRequestId14) && (
        <Card>
          <CardHeader
            action={
              <Button
                sx={CorrectiveActionRequestStyles.cardHeader}
                variant="outlined"
                color="primary"
                disabled={
                  carFormData?.type?.corrective_action_request_type_id !==
                    correctiveActionRequestId2 &&
                  carFormData?.type?.corrective_action_request_type_id !==
                    correctiveActionRequestId3 &&
                  carFormData?.type?.corrective_action_request_type_id !==
                    correctiveActionRequestId1 &&
                  carFormData?.type?.corrective_action_request_type_id !==
                    correctiveActionRequestId8
                }
                onClick={() => setIsOpenMediaModal(true)}
              >
                {t("correctiveActionRequest.addMediaToCar")}
              </Button>
            }
          />
          <CardContent sx={CorrectiveActionRequestStyles.treeCardContent}>
            <div ref={tableRef}>
              <MUIDataTable
                columns={
                  carFormData?.type?.corrective_action_request_type_id ===
                    correctiveActionRequestId2 ||
                  carFormData?.type?.corrective_action_request_type_id ===
                    correctiveActionRequestId3 ||
                  carFormData?.type?.corrective_action_request_type_id ===
                    correctiveActionRequestId1 ||
                  carFormData?.type?.corrective_action_request_type_id ===
                    correctiveActionRequestId8
                    ? columnOpenMedia
                    : columnOpenMediaWithoutStatus
                }
                options={options}
                data={openMediaList}
              />
            </div>
          </CardContent>
        </Card>
      )}
      <br />

      {carFormData?.type?.corrective_action_request_type_id ===
      correctiveActionRequestId4 ? (
        <>
          <div ref={tableRef}>
            <MUIDataTable
              columns={columnContainerReRun}
              options={options}
              data={reRunData?.sort((a, b) =>
                a.media_number.localeCompare(b.media_number)
              )}
            />
          </div>
        </>
      ) : carFormData?.type?.corrective_action_request_type_id ===
        correctiveActionRequestId5 ? (
        <div ref={tableRef}>
          <MUIDataTable
            columns={columnContainerMissing}
            options={options}
            data={reRunData}
          />
        </div>
      ) : carFormData?.type?.corrective_action_request_type_id ===
        correctiveActionRequestId18 ? (
        <>
          <div ref={tableRef}>
            <MUIDataTable
              columns={columnsDuplicate}
              options={options}
              data={reRunData}
            />
          </div>
        </>
      ) : carFormData?.type?.corrective_action_request_type_id ===
        correctiveActionRequestId17 ? (
        <>
          <div ref={tableRef}>
            <MUIDataTable
              columns={columnOpenMediaAudit}
              options={options}
              data={reRunData}
            />
          </div>
        </>
      ) : carFormData?.type?.corrective_action_request_type_id ===
        correctiveActionRequestId16 ? (
        <>
          <div ref={tableRef}>
            <MUIDataTable
              columns={columnInventoryPickError}
              options={options}
              data={reRunData}
            />
          </div>
        </>
      ) : carFormData?.type?.corrective_action_request_type_id ===
        correctiveActionRequestId15 ? (
        <>
          <div ref={tableRef}>
            <MUIDataTable
              columns={columnContainerAudit}
              options={options}
              data={reRunData}
            />
          </div>
        </>
      ) : carFormData?.type?.corrective_action_request_type_id ===
          correctiveActionRequestId3 &&
        carFormData?.manually_created_car_flag === VALUE_N ? (
        <>
          <div ref={tableRef}>
            <MUIDataTable
              columns={columnWithoutIdentification}
              options={options}
              data={reRunData?.sort((a, b) =>
                a.media_number.localeCompare(b.media_number)
              )}
            />
          </div>
        </>
      ) : carFormData?.type?.corrective_action_request_type_id ===
        correctiveActionRequestId6 ? (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.requestId")}
              </Typography>
              <Typography variant="body1">
                {reRunData[0]?.request_id ? reRunData[0]?.request_id : "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.authorizedby")}
              </Typography>
              <Typography variant="body1">
                {reRunData[0]?.authorized_by
                  ? reRunData[0]?.authorized_by
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.rerunReqOption")}
              </Typography>
              <Typography variant="body1">
                {reRunData[0]?.request_action
                  ? reRunData[0]?.request_action
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.whyCustomerCalled")}
              </Typography>
              <Typography variant="body1">
                {carFormData?.why_customer_called
                  ? carFormData?.why_customer_called
                  : "-"}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.otherTabInfo")}
              </Typography>
              <Typography variant="body1">
                {reRunData[0]?.other_tab_info
                  ? reRunData[0]?.other_tab_info
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : carFormData?.type?.corrective_action_request_type_id ===
        correctiveActionRequestId7 ? (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.runDate")}
              </Typography>
              <Typography variant="body1">
                {reRunData[0]?.run_date
                  ? dayjs(reRunData[0]?.run_date)
                      .utc()

                      .locale(localeByBranch)
                      .format(dateFormatWithoutTime)
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.run")}
              </Typography>
              <Typography variant="body1">
                {reRunData[0]?.run_name ? reRunData[0]?.run_name : "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.uploadedBy")}
              </Typography>
              <Typography variant="body1">
                {reRunData[0]?.uploaded_by ? reRunData[0]?.uploaded_by : "-"}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {" "}
                {t("correctiveActionRequest.serviceTime")}
              </Typography>
              <Typography variant="body1">
                {reRunData[0]?.service_time ? reRunData[0]?.service_time : "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <RadioGroup
                row
                value={selectBillable}
                onChange={handleBillChange}
              >
                <FormControlLabel
                  value={bill}
                  control={<Radio />}
                  label={t("correctiveActionRequest.bill")}
                />
                <FormControlLabel
                  value={doNotBill}
                  control={<Radio />}
                  label={t("correctiveActionRequest.doNotBill")}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <br />
          <Card>
            <Box sx={CorrectiveActionRequestStyles.cardScroll}>
              <CardContent sx={CorrectiveActionRequestStyles.treeCardContent}>
                <Typography
                  variant="body2"
                  sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
                >
                  {t("correctiveActionRequest.incompleteStopReason")}
                </Typography>
                <br />
                <Grid item xs={12} sm={12}>
                  {billCommentData?.map((obj) => {
                    return (
                      <>
                        <Typography>{obj?.comment}</Typography>
                        <br />
                      </>
                    );
                  })}
                </Grid>
              </CardContent>
            </Box>
          </Card>
        </>
      ) : (
        <Card>
          <CardHeader
            sx={CorrectiveActionRequestStyles.carHead}
            action={
              <Button
                sx={CorrectiveActionRequestStyles.cardHeader}
                variant="outlined"
                color="primary"
                disabled={disableIdentification}
                onClick={async () => {
                  const commentLength = commentList
                    ?.map((o) => o?.comment)
                    ?.map((obj) => obj?.toString())
                    .join("")?.length;
                  if (commentLength > modalTextLimit) {
                    const errMsg = await getMessageFromCode(
                      errorMsgs.errorCode63919
                    );
                    setThrowError(true);
                    setThrowErrorMessage(errMsg[0]?.descr);
                  } else {
                    setIsOpenCommentModal(true);
                  }
                }}
              >
                {t("correctiveActionRequest.addIdentificatioComment")}
              </Button>
            }
          />
          <Box sx={CorrectiveActionRequestStyles.cardScroll}>
            <CardContent sx={CorrectiveActionRequestStyles.treeCardContent}>
              <Typography
                variant="body2"
                sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
              >
                {t("correctiveActionRequest.identificationComments")}
              </Typography>
              <br />
              <Grid item xs={12} sm={12}>
                {commentList?.map((obj) => {
                  return (
                    <>
                      <Typography>{obj?.comment}</Typography>
                      <br />
                    </>
                  );
                })}
              </Grid>
            </CardContent>
          </Box>
        </Card>
      )}
      <br />

      <Divider />
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={CorrectiveActionRequestStyles.titleStyle}
        >
          {t("correctiveActionRequest.investigationPhase")}
        </Typography>
      </Grid>
      <br />
      <Card>
        <CardHeader
          sx={CorrectiveActionRequestStyles.carHead}
          action={
            <Button
              sx={CorrectiveActionRequestStyles.cardHeader}
              variant="outlined"
              color="primary"
              onClick={handleButtonClick}
              disabled={disableInvestigationComplete}
            >
              {t("correctiveActionRequest.addInvesticationComment")}
            </Button>
          }
        />
        <Box sx={CorrectiveActionRequestStyles.cardScroll}>
          <CardContent sx={CorrectiveActionRequestStyles.treeCardContent}>
            <Typography
              variant="body2"
              sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
            >
              {t("correctiveActionRequest.investicateRevealed")}
            </Typography>
            <br />
            <Grid item xs={12} sm={12}>
              {showInvestigationComment.length > 0 && (
                <>
                  {showInvestigationComment.map((comment) => {
                    return (
                      <>
                        <Typography>{comment}</Typography>
                        <br />
                      </>
                    );
                  })}
                </>
              )}
            </Grid>
          </CardContent>
        </Box>
      </Card>
      <br />

      {/* <Typography>{t("correctiveActionRequest.investicateText")}</Typography> */}
      <br />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            clearIcon={<GridCloseIcon />}
            id="type"
            name="type"
            size="medium"
            value={failurePoint}
            options={failurePointData}
            onChange={handleSelectionChange}
            getOptionLabel={(option) => option.failure_point_descr}
            fullWidth
            disabled={disableInvestigationComplete}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("correctiveActionRequest.failurePoint")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            clearIcon={<GridCloseIcon />}
            id="type"
            name="type"
            size="medium"
            value={rootCause}
            options={rootCauseData}
            getOptionLabel={(option) => option.root_cause_descr}
            onChange={(_, value) => {
              setSaveBtnStatus(false);
              if (
                value &&
                value.root_cause_descr === t("correctiveActionRequest.employee")
              ) {
                setIsEmployeeSelected(value);
                setRootCause(value);
              } else {
                setIsEmployeeSelected(null);
                setRootCauseEmployees([]);
                setRootCause(value);
              }
            }}
            disabled={disableInvestigationComplete}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("correctiveActionRequest.rootCause")}
              />
            )}
          />
        </Grid>
      </Grid>

      <br />
      {isEmployeeSelected && (
        <Card>
          <CardHeader
            action={
              <>
                <Button
                  sx={CorrectiveActionRequestStyles.cardHeader}
                  variant="outlined"
                  color="primary"
                  onClick={handleAddRootCauseEmp}
                  disabled={disableInvestigationComplete}
                >
                  {t("correctiveActionRequest.addRootCauseEmployee")}
                </Button>

                <Button
                  sx={CorrectiveActionRequestStyles.employeeSignOff}
                  variant="outlined"
                  onClick={handleEmployeeSignoff}
                  disabled={enableEmployeeSignoffButton}
                >
                  {t("correctiveActionRequest.employeeSignOff")}
                </Button>
              </>
            }
          />
          <CardContent sx={CorrectiveActionRequestStyles.treeCardContent}>
            <ThemeProvider theme={commonDataTableTheme}>
              <MUIDataTable
                columns={columns}
                options={options}
                data={rootCauseEmployees}
              />
            </ThemeProvider>
          </CardContent>
        </Card>
      )}

      <br />
      <Stack mt={spacing.gap} gap={spacing.gap} direction="row">
        <Button
          variant="outlined"
          onClick={() => handleInvestigationPhase(VALUE_Y)}
          disabled={disableInvestigationComplete}
        >
          {t("correctiveActionRequest.investigationComplete")}
        </Button>
      </Stack>
      <br />

      <Divider />
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={CorrectiveActionRequestStyles.titleStyle}
        >
          {t("correctiveActionRequest.resolutionPhase")}
        </Typography>
      </Grid>
      <br />
      <Card>
        <CardHeader
          sx={CorrectiveActionRequestStyles.carHead}
          action={
            <Button
              sx={CorrectiveActionRequestStyles.cardHeader}
              variant="outlined"
              color="primary"
              disabled={disableResolution}
              onClick={async () => {
                const commentLength = resolutionCommentList
                  ?.map((o) => o?.comment)
                  ?.map((obj) => obj?.toString())
                  .join("")?.length;

                if (commentLength > modalTextLimit) {
                  const errMsg = await getMessageFromCode(
                    errorMsgs.errorCode63919
                  );
                  setThrowError(true);
                  setThrowErrorMessage(errMsg[0]?.descr);
                } else {
                  setIsOpenResolutionCommentModal(true);
                }
              }}
            >
              {t("correctiveActionRequest.addResolutionPhase")}
            </Button>
          }
        />
        <Box sx={CorrectiveActionRequestStyles.cardScroll}>
          <CardContent sx={CorrectiveActionRequestStyles.treeCardContent}>
            <Typography
              variant="body2"
              sx={CorrectiveActionRequestStyles.maintanence.colorTitle}
            >
              {t("correctiveActionRequest.resolvedBy")}
            </Typography>
            <br />
            {resolutionCommentList?.map((obj) => {
              return (
                <>
                  <Typography>{obj?.comment}</Typography>
                  <br />
                </>
              );
            })}
          </CardContent>
        </Box>
      </Card>
      <Stack mt={spacing.gap} gap={spacing.gap} direction="row">
        <Button
          variant="outlined"
          onClick={() => handleResolutionUpdate(VALUE_Y)}
          disabled={disableResolution}
        >
          {" "}
          {t("correctiveActionRequest.resolutionComplete")}
        </Button>
      </Stack>
      <br />
      <Divider />
      <br />
      <Stack mt={spacing.gap} gap={spacing.gap} direction="row">
        {customerComments && <SvgIcon component={errorIcon} inheritViewBox />}
        <Button
          variant="outlined"
          disabled={!customerComments}
          onClick={() => setOpenCustomerCommentModal(true)}
        >
          {" "}
          {t("correctiveActionRequest.customerComments")}
        </Button>
        <Button
          variant="outlined"
          disabled={!carId}
          onClick={() => setShowPrintModal(true)}
        >
          {t("common.print")}
        </Button>

        <Button variant="outlined" onClick={newCar}>
          {t("common.new")}
        </Button>

        <Button
          variant="contained"
          disabled={saveBtnStatus}
          onClick={() => savePahse()}
        >
          {t("common.save")}
        </Button>
      </Stack>

      {
        <AddCommentsModal
          isAddCommentModal={isOpenAddCommentModal}
          setSaveBtnStatus={setSaveBtnStatus}
          onClose={() => setIsOpenCommentModal(false)}
          carFormData={carFormData}
          setCarData={setCarData}
          commentList={commentList}
          setCommentList={setCommentList}
          getDate={localDateTime}
        />
      }
      {
        <CustomerComments
          isAddCommentModal={openCustomerCommentModal}
          setSaveBtnStatus={setSaveBtnStatus}
          onClose={() => setOpenCustomerCommentModal(false)}
          commentList={customerComments}
        />
      }
      {
        <AddResolutionCommentModal
          isAddCommentModal={isOpenResolutionCommentModal}
          setSaveBtnStatus={setSaveBtnStatus}
          onClose={() => setIsOpenResolutionCommentModal(false)}
          resolutionCommentList={resolutionCommentList}
          setResolvedCommentList={setResolutionCommentList}
          getDate={localDateTime}
        />
      }
      {
        <AddMediaModal
          setSaveBtnStatus={setSaveBtnStatus}
          customer={customer}
          isOpenMediaModal={isOpenMediaModal}
          openMediaList={openMediaList}
          setOpenMediaList={setOpenMediaList}
          mediaData={mediaData}
          setMediaData={setMediaData}
          onClose={() => setIsOpenMediaModal(false)}
        />
      }
      {openChangesModal && (
        <ConfirmPopup
          modalPopupOpen={openChangesModal}
          message={t("containerProcessing.picking.saveErr")}
          handleNo={() => {
            setOpenChangesModal(false);
            if (newClick) {
              setCarData({
                detectedDate: dayjs(new Date()),
                notify_account_owner_flag: VALUE_N,
                customer_display_flag: VALUE_N,
                type: null,
                manually_created_car_flag: VALUE_Y
              });
              setCarId("");
              setOpenMediaList([]);
              setCustomerComments("");
              setCustomer(null);
              setCommentList([]);
              setMediaData([]);
              setSaveBtnStatus(true);
              setNewCLick(false);
              setDisableCarForm(false);
              setDisableIdentification(false);
              setRootCauseEmployees([]);
              setShowInvestigationComment([]);
              setFailurePoint(null);
              setRootCause(null);
              setResolutionCommentList([]);
              setDisableInvestigationComplete(true);
              setDisableResolution(true);
              setIsEmployeeSelected(false);
              setDisableType(true);
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
                { state: null }
              );
            }
          }}
          handleYes={() => savePahse()}
          handleCancel={() => {
            setOpenChangesModal(false);
          }}
          showCancel={true}
          showNo={true}
        />
      )}
      <DownloadPopup
        throwError={throwError}
        setThrowError={setThrowError}
        throwErrorMessage={throwErrorMessage}
        setThrowErrorMessage={setThrowErrorMessage}
        title={t("common.downloadReport")}
        showPrintModal={showPrintModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={() => setShowPrintModal((prev) => !prev)}
        onDownloadClick={handleDetailsReportDownload}
      />

      <CommonModal
        open={isModalInvestigationVisible}
        title={t("correctiveActionRequest.addInvesticationComment")}
        body={
          <Box
            display="flex"
            flexDirection="column"
            gap={20}
            sx={CorrectiveActionRequestStyles.modalStyle}
          >
            <TextField
              multiline
              label="Comments"
              rows={5}
              maxRows={8}
              variant="outlined"
              inputProps={{
                maxLength: maxCount(
                  modalTextLimit,
                  showInvestigationComment
                    ?.map((o) => o)
                    ?.map((obj) => obj?.toString())
                    .join("")?.length
                )
              }}
              fullWidth
              value={addInvestigationComment}
              onChange={(e) => {
                setAddInvestigationComment(e.target.value);
              }}
            />
          </Box>
        }
        buttons={
          <>
            <Button
              variant="outlined"
              onClick={handleInvestigationComment}
              disabled={!addInvestigationComment}
            >
              {t("common.add")}
            </Button>
            <Button
              variant="contained"
              onClick={() => setIsModalInvestigationVisible(false)}
            >
              {t("common.cancel")}
            </Button>
          </>
        }
      />

      <CommonModal
        open={isModalRootCauseVisible}
        title={t("correctiveActionRequest.addRootCauseEmployee")}
        body={
          <Box display="flex" flexDirection="column" gap={20}>
            <Stack>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                clearIcon={<GridCloseIcon />}
                id="type"
                name="type"
                size="medium"
                options={searchEmployee}
                onChange={(_, newValue) => {
                  setSelectedEmployee(newValue);
                  setIsDuplicateEmp(false);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("correctiveActionRequest.employeeName")}
                  />
                )}
                renderOption={(props, item) => (
                  <li {...props} key={item.employee_id}>
                    {item.employee_name}
                  </li>
                )}
              />
            </Stack>
          </Box>
        }
        buttons={
          <>
            <Button
              variant="outlined"
              onClick={addRootCauseData}
              disabled={!selectedEmployee || isDuplicateEmp}
            >
              {t("common.add")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsModalRootCauseVisible(false);
              }}
            >
              {t("common.cancel")}
            </Button>
          </>
        }
      />
      <CommonModal
        open={openDiscrepancyModal}
        title={t("common.confirm")}
        body={<Typography>{throwErrorMessage}</Typography>}
        buttons={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(
                  `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
                  {
                    state: { containerAuditId: null }
                  }
                );
              }}
            >
              {t("common.ok")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenDiscrepenceModal(false);
                navigate(
                  `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
                  {
                    state: { containerAuditId: null }
                  }
                );
              }}
            >
              {t("common.cancel")}
            </Button>
          </>
        }
      />
      <CommonModal
        open={isModalEmployeeSignoffVisible}
        title={t("correctiveActionRequest.employeeSignOff")}
        body={
          <Box display="flex" flexDirection="column" gap={20}>
            <Stack>
              <Typography variant="body2">
                {t("correctiveActionRequest.employee")}
              </Typography>
              <Typography variant="body1">{selectedEmployeeName}</Typography>
              <br />

              <TextField
                multiline
                label="Employee Comments"
                rows={5}
                maxRows={8}
                inputProps={{
                  maxLength: 512
                }}
                onChange={(e) => setEmployeeSignoffCommentsData(e.target.value)}
              />
            </Stack>
          </Box>
        }
        buttons={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                handleSignoffAddComment();
                setSaveBtnStatus(false);
                setEnableEmployeeSignoffButton(true);
                setSelectedIndex(null);
              }}
            >
              {t("common.save")}
            </Button>
            <Button
              variant="contained"
              onClick={() => setIsModalEmployeeSignoffVisible(false)}
            >
              {t("common.cancel")}
            </Button>
          </>
        }
      />
    </>
  );
};
export default CorrectiveActionRequestDetail;
