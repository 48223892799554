import { Stack } from "@mui/material";
import { PageTitle } from "components/shared";
import VendorManagementLayout from "components/transportation-module/vendor-management/VendorManagementLayout";
import i18n from "../../i18n";

const VendorManagementPage = () => {
  return (
    <Stack>
    <PageTitle title={i18n.t("vendorManagement.pageTitle")} />
      <VendorManagementLayout /> 
    </Stack>
  );
};

export default VendorManagementPage;
