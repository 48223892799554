import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { AddCircleIcon } from "assets/images";
import CloseFilledIcon from "assets/images/CloseFilled.svg";
import PencilIcon from "assets/images/pencil.svg";
import { CircularLoader, SearchField } from "components/core";
import { useFormik } from "formik";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { useGetRequiredInsertsCountMutation } from "services/api";
import { getMessageByIdFromMessage } from "services/common";
import {
  addIssueContainerOrTransport,
  removeIssueContainerOrTransport,
  selectMediaRequest,
  updateIssueContainerOrTransport
} from "store/slices";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  MEDIA_PROGRAM_TYPES,
  N_CHECKER
} from "utils/constants";
import {
  MESSAGE_10187,
  NOT_APPLICABLE
} from "utils/constants/request-module/MediaRequestDetailConstants";
import { limitFormikTextFieldMaxValue } from "utils/helpers";
import { MediaRequestDetailFormStyles } from "../../media-request-detail-form/MediaRequestDetailForm.Styles";
import { IssueContainersStyles } from "../IssueContainersTab.Styles";
import SelectInsertsModal from "../select-inserts-modal/SelectInsertsModal";

const formInitialValues = {
  logical_vault_code: "",
  media_type_id: 0,
  media_type_descr: "",
  insert_configuration: "",
  quantity: 0,
  lock_type_code: "",
  customer_owned_container_flag: N_CHECKER,
  customer_media_descr_list: "",
  is_managed_container_flag: N_CHECKER
};

// column definition of the empty transports data grid view only
const viewEmptyTransportsColumnsDefinition = [
  {
    name: "media_type_descr",
    label: "MEDIA TYPE"
  },
  {
    name: "insert_configuration",
    label: "INSERT CONFIGURATION",
    options: {
      customBodyRender: (value) => (value ? value : NOT_APPLICABLE)
    }
  },
  {
    name: "quantity",
    label: "TRANSPORT QTY."
  }
];

const EmptyTransports = (props) => {
  const { t } = useTranslation();
  const { issueContainers } = useSelector(selectMediaRequest); // select media request slice
  const dispatch = useDispatch(); // redux actions dispatcher
  const [selectedRow, setSelectedRow] = useState(-1);
  const [openInsertConfigModal, setOpenInsertConfigModal] = useState(false);
  const [insertConfigs, setInsertConfigs] = useState([]);
  const message10187 = useMemo(async () => {
    const resp = await getMessageByIdFromMessage({
      message_id: MESSAGE_10187
    });
    return resp;
  }, []); // message 10187

  const [
    getRequiredInsertsCount,
    { data: requiredInsertsCount = 0, reset: resetRequiredInsertsCount }
  ] = useGetRequiredInsertsCountMutation();

  const dataTableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    selectableRows: t("mediaRequestDetail.none"),
    jumpToPage: false,
    pagination: false,
    tableBodyHeight: "230px",
    tableBodyMaxHeight: "230px",
    textLabels: {
      body: {
        noMatch: props.isLoading ? (
          <CircularLoader loading={props.isLoading} />
        ) : (
          i18n.t("mediaRequestDetail.noItemsToDisplay")
        )
      }
    }
  };

  // set row data to the form
  const handleEditRow = (rowIndex) => {
    const data = issueContainers.containers[rowIndex];
    emptyTransportForm.setValues({
      ...formInitialValues,
      media_type_id: data.media_type_id,
      media_type_descr: data.media_type_descr,
      insert_configuration: data?.insert_configuration,
      quantity: data.quantity
    });
    setInsertConfigs(data.insertConfigs);
    setSelectedRow(rowIndex);
  };

  // column definition of the empty transports data grid
  const emptyTransportsColumnsDefinition = [
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnEdit${value}`}
            aria-label="edit"
            onClick={() => handleEditRow(tableMeta.rowIndex)}
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={PencilIcon} alt="edit icon" />
          </IconButton>
        )
      }
    },
    {
      name: "id",
      label: " ",
      options: {
        setCellProps: () => ({ style: { width: "24px" } }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnRemove${value}`}
            aria-label="remove"
            onClick={() =>
              dispatch(removeIssueContainerOrTransport(tableMeta.rowIndex))
            }
            sx={MediaRequestDetailFormStyles.TableIconButton}
          >
            <img src={CloseFilledIcon} alt="close icon" />
          </IconButton>
        )
      }
    },
    {
      name: "media_type_descr",
      label: t("mediaRequestDetail.mediaTypeCaps")
    },
    {
      name: "insert_configuration",
      label: t("mediaRequestDetail.insertConfigCaps"),
      options: {
        customBodyRender: (value) => (value ? value : NOT_APPLICABLE)
      }
    },
    {
      name: "quantity",
      label: t("mediaRequestDetail.transportQtyCaps")
    }
  ];

  /* Add an empty transport form schema */
  const emptyTransportsValidationSchema = Yup.object().shape({
    media_type_id: Yup.number()
      .moreThan(0, i18n.t("mediaRequestDetail.selectAMediaType"))
      .required(i18n.t("mediaRequestDetail.thisFieldIsRequired")),
    insert_configuration: Yup.string().test(
      "insertConfigurationRequired",
      i18n.t("mediaRequestDetail.insertConfigurationRequired"),
      (value) => {
        if (requiredInsertsCount > 0) {
          return value !== undefined && value !== null && value !== "";
        }
        return true;
      }
    ),
    quantity: Yup.number()
      .moreThan(0, message10187[0]?.descr)
      .required(message10187[0]?.descr)
  });

  const emptyTransportForm = useFormik({
    initialValues: formInitialValues,
    validationSchema: emptyTransportsValidationSchema,
    onSubmit: (values, formikHelpers) => {
      if (emptyTransportForm.isValid) {
        if (selectedRow < 0) {
          dispatch(
            addIssueContainerOrTransport({
              ...values,
              insertConfigs: insertConfigs,
              mediaIdentifiers: [],
              id: Math.round(Math.random() * 100)
            })
          );
        } else {
          dispatch(
            updateIssueContainerOrTransport({
              index: selectedRow,
              data: {
                ...values,
                insertConfigs: insertConfigs,
                mediaIdentifiers: []
              }
            })
          );
          setSelectedRow(-1);
        }
        setInsertConfigs([]);
        resetRequiredInsertsCount();
        formikHelpers.resetForm();
      }
    }
  });

  // check whether the item requires inserts
  useEffect(() => {
    // check whether the item requires inserts
    if (Number(emptyTransportForm.values?.media_type_id) > 0) {
      getRequiredInsertsCount({
        mediaTypeId: emptyTransportForm.values?.media_type_id,
        mediaProgramTypeId: MEDIA_PROGRAM_TYPES.TRANSPORT.ID
      })
        .unwrap()
        .then(async (resp) => {
          if (Number(resp) === 0) {
            await emptyTransportForm.setFieldValue(
              "insert_configuration",
              NOT_APPLICABLE
            );
            setInsertConfigs((prevState) => []);
          }

          if (Number(resp) > 0 && selectedRow < 0) {
            await emptyTransportForm.setFieldValue("insert_configuration", "");
            setInsertConfigs((prevState) => []);
            // if the row is new then show the insert configuration modal automatically
            setOpenInsertConfigModal((prevState) => true);
          }
        });
    } else {
      emptyTransportForm.setFieldValue("insert_configuration", "");
      setInsertConfigs((prevState) => []);
      resetRequiredInsertsCount();
    }

    // clear next field values when a field is cleared, fire the dirty event when a value is added
    if (Number(emptyTransportForm.values?.media_type_id) === 0) {
      emptyTransportForm.resetForm();
      props.onFormDirty(false);
    } else {
      props.onFormDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptyTransportForm.values?.media_type_id]);

  // submit form if the tab is changed and the form is dirty
  useEffect(() => {
    if (props.startValidation) {
      emptyTransportForm.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.startValidation]);

  return (
    <>
      {/* Add new empty media form */}
      <Grid
        id="issueEmptyTransportsGrid1"
        container
        sx={{
          padding: "1rem"
        }}
      >
        <Grid
          id="issueEmptyTransportsGrid2"
          item
          container
          columnSpacing={"1rem"}
          sx={{ marginLeft: "-1rem" }}
        >
          {/* Media Type field */}
          <Grid id="issueEmptyTransportGrid3" item xs={3}>
            <SearchField
              id="issueEmptyTransportsMediaType"
              disabled={
                Boolean(props?.requestId) ||
                Boolean(emptyTransportForm.values?.logical_vault_code === 0)
              }
              size="small"
              options={issueContainers.mediaTypes.transport}
              name="media_type_id"
              label={t("mediaRequestDetail.mediaType")}
              required
              value={emptyTransportForm.values?.media_type_descr}
              error={
                emptyTransportForm.touched.media_type_id &&
                Boolean(emptyTransportForm.errors.media_type_id)
              }
              helperText={
                emptyTransportForm.touched.media_type_id &&
                emptyTransportForm.errors.media_type_id
              }
              onChange={(_, value) => {
                emptyTransportForm.setFieldValue(
                  "media_type_id",
                  value?.value || 0
                );
                emptyTransportForm.setFieldValue(
                  "media_type_descr",
                  value?.label || ""
                );
              }}
            />
          </Grid>

          {/* Insert Configuration */}
          <Grid id="issueEmptyTransportsGrid4" item xs={3}>
            <TextField
              id="issueEmptyTransportsInsertConfiguration"
              name="insert_configuration"
              disabled
              label={t("mediaRequestDetail.insertConfig")}
              placeholder={t("mediaRequestDetail.insertConfig")}
              value={emptyTransportForm.values?.insert_configuration}
              size="small"
              fullWidth
              required={requiredInsertsCount > 0}
              error={
                emptyTransportForm.touched.insert_configuration &&
                Boolean(emptyTransportForm.errors.insert_configuration)
              }
              helperText={
                emptyTransportForm.touched.insert_configuration &&
                emptyTransportForm.errors.insert_configuration
              }
              InputProps={{
                endAdornment: requiredInsertsCount > 0 && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setOpenInsertConfigModal((prevState) => true)
                      }
                      disabled={
                        Boolean(props?.requestId) || requiredInsertsCount <= 0
                      }
                    >
                      <SvgIcon viewBox="0 0 24 24">
                        <AddCircleIcon
                          {...IssueContainersStyles.AddCircleIconFill}
                        />
                      </SvgIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          {/* Transport quantity field */}
          <Grid id="issueEmptyTransportsGrid5" item xs={3}>
            <TextField
              id="issueEmptyTransportsQuantity"
              disabled={
                Boolean(props?.requestId) ||
                Boolean(emptyTransportForm.values?.insert_configuration === "")
              }
              name="quantity"
              type="number"
              label={t("mediaRequestDetail.transportQty")}
              size="small"
              fullWidth
              required
              value={emptyTransportForm.values?.quantity}
              error={
                emptyTransportForm.touched.quantity &&
                Boolean(emptyTransportForm.errors.quantity)
              }
              helperText={
                emptyTransportForm.touched.quantity &&
                emptyTransportForm.errors.quantity
              }
              onChange={(event) =>
                limitFormikTextFieldMaxValue(emptyTransportForm, event, 150)
              }
            />
          </Grid>

          {/* Add button */}
          <Grid id="issueEmptyTransportsGrid6" item>
            <Button
              id="issueEmptyTransportsGridAddButton"
              sx={{ height: "100%" }}
              disabled={Boolean(props?.requestId)}
              variant="outlined"
              onClick={() => emptyTransportForm.handleSubmit()}
            >
              {selectedRow < 0
                ? t("mediaRequestDetail.add")
                : t("mediaRequestDetail.update")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Containers table */}
      <MUIDataTable
        id="issueEmptyTransportsDataTable"
        columns={
          props.requestId
            ? viewEmptyTransportsColumnsDefinition
            : emptyTransportsColumnsDefinition
        }
        data={issueContainers.containers}
        options={dataTableOptions}
      />

      {/* Insert Configurations modal */}
      {openInsertConfigModal && (
        <SelectInsertsModal
          open={openInsertConfigModal}
          requiredQuantity={Number(requiredInsertsCount)}
          selectedInsertConfigs={insertConfigs}
          onClose={(_) => setOpenInsertConfigModal((prevState) => !prevState)}
          onSubmit={(configs) => {
            setInsertConfigs(configs);
            emptyTransportForm.setFieldValue(
              "insert_configuration",
              configs
                .map((item) => `${item.quantity} - ${item.insert_type}`)
                .toString()
                .replaceAll(",", " ")
            );
            setOpenInsertConfigModal((prevState) => false);
          }}
        />
      )}
    </>
  );
};

EmptyTransports.propTypes = {
  requestId: PropTypes.number, // ID of the media request
  isLoading: PropTypes.bool, // loading transports of the media request
  onFormDirty: PropTypes.bool, // if form is dirty event
  startValidation: PropTypes.bool // start form validation or not
};

export default EmptyTransports;
