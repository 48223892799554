export const AuditStyles = {
  checkBoxStyle: {
    margin: "9px"
  },
  mainDivStyles: {
    marginTop: "20px"
  },
  boxWidth: { width: "310px" },
  inputLabelProps: { shrink: true, style: { zIndex: 0 } },
  flex: { display: "flex" },
  btn: { textTransform: "none", margin: "5px 10px" },
  boxStyle: { display: "flex", gap: "20px" },
  alertWidth: { minWidth: "600px" },
  actionColumnStyle: { textAlign: "left" }
};
