export const nodeTypes = {
  branch: "branch",
  vault: "vault",
  row: "row",
  rack: "rack",
  shelf: "shelf"
};

export const newTypes = {
  vault: "newVault",
  row: "newRow",
  rack: "newRack",
  shelf: "newShelf"
};

export const nodeIdPrefix = {
  vault: "vault_",
  row: "row_",
  rack: "rack_",
  shelf: "shelf_"
};

export const uniqueId = "uniqueId";
export const dataProperties = {
  rowId: "row_id",
  rowNumber: "row_number",
  rackId: "rack_id",
  rackNumber: "rack_number",
  shelfId: "shelf_id",
  shelfNumber: "shelf_number"
};
export const accessProperties = {
  physicalVaultNumber: "physicalVaultNumber",
  rowNumber: "rowNumber",
  rackNumber: "rackNumber",
  shelfNumber: "shelfNumber"
};

export const programTypes = {
  container: "container",
  openMedia: "openMedia",
  c: "C",
  m: "M"
};

export const durations = {
  longTerm: "longTerm",
  active: "active"
};

export const capacityMax = {
  three: "3",
  four: "4"
};

export const vaultAndShelfMaxLenght = "2";
export const rowAndRackMaxLength = "3";
export const vaultAndShelfMax = 99;
export const rowAndRackMax = 999;
export const paddZero = "0";
export const number = "Number";
export const id = "Id";
export const unassigned = "<Unassigned>";
export const idCustomer = "customer";
export const idProgramType = "programType";
export const idCapacity = "capacity";
export const typeInsert = "insert";
export const typeDelete = "delete";
export const branch = "<Branch>";
export const zero = "0";
export const vaultConfigurationPayloadProp = "vault_configuration";
