export const labelSourceOptions = [
  {
    label: "Single Label",
    value: "single_label"
  },
  {
    label: "Range",
    value: "range"
  },
  {
    label: "File",
    value: "file"
  }
];

export const printTapeLabelTableColumns = [
  {
    id: "customer",
    label: "CUSTOMER",
    visible: true
  },
  {
    id: "type",
    label: "TYPE",
    visible: true
  },
  {
    id: "mediaNumber",
    label: "MEDIA NUMBER",
    visible: true
  },
  {
    id: "status",
    label: "STATUS",
    visible: true
  }
];

export const fieldNames = {
  printerName: "printerName",
  printerInfo: "printerInfo",
  labelSource: "labelSource",
  customerNumber: "customerNumber",
  mediaNumber: "mediaNumber",
  mediaType: "mediaType",
  quantity: "quantity",
  mediaNumberPrefix: "mediaNumberPrefix",
  mediaNumberIncrementer: "mediaNumberIncrementer",
  mediaNumberSuffix: "mediaNumberSuffix"
};
