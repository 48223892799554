import { getMessageFromCode } from "services/api/query";

export const preferenceServiceIndicatorTypeMap = {
  23: "Service Indicator Type",
  25: "3rd Party Shipper",
  26: "Service Level",
  28: "shipper #",
  27: "Bill to Customer"
};
export const filterIndicatorOptions = [
  { label: "All", value: "all" },
  { label: "Service type", value: "23" },
  { label: "Service Level", value: "26" },
  { label: "3rd Party Shipper", value: "25" },
  { label: "Shipper #", value: "27" },
  { label: "Bill to Customer", value: "28" }
];
export const preferencesServiceIndicatorTypeIds = [
  "23",
  "25",
  "26",
  "27",
  "28"
];
export const systemId = "69";
export const getTheFirestoreErrorMessage = async (msgId) => {
  const msg = await getMessageFromCode(msgId);
  return { errorMsg: msg[0]?.descr };
};
export const all = "all";
export const filterPopupId = "filter-popover";
export const zero = "0";
export const permissionId322 = "322";
export const permissionId325 = "325";
export const thirdPartyId = "3";
export const thirdParty = "thirParty";
export const interBranch = "2";
export const preferenceXML = "<ObjectValues>|</ObjectValues>";
export const preferenceXMLValues =
  '<ObjectValue Object_Id="|" Object_Value="|" Version=""/>';
