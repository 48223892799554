export const CircularLoaderStyles = {
  root: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "&>*": {
      flexGrow: 1
    }
  },
  loader: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0px",
    left: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    background: "rgba(255, 255, 255, 0.7)",
    zIndex: 9999,
    borderRadius: 4
  }
};
