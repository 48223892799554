import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  C,
  downloadReportOptions,
  E,
  ERROR_TYPES,
  N_CHECKER,
  numberConstants,
  systemName,
  Y_CHECKER
} from "utils/constants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";
import { EmployeeSearchStyles } from "./EmployeeSearchStyles";
const EmployeeSearchPrintModal = ({
  isVisiblePrint,
  setIsVisiblePrint,
  employeeSearchForm,
  setLoading
}) => {
  const { t } = useTranslation();
  const { currentBranch, auth } = useSelector(selectAuth);
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  const handleDownloadClose = () => {
    setIsDownloadModalOpen(false);
    setThrowError(false);
    setThrowErrorMessage(false);
  };

  const handleDownload = async () => {
    const { values } = employeeSearchForm;
    try {
      setLoading(true);
      const requestBody = JSON.stringify({
        main_district_id: currentBranch?.value,
        first_name: values?.firstName?.trim(),
        last_name: values?.lastName?.trim(),
        district_id:
          values?.district === ALL_SELECTION?.value
            ? numberConstants.zero
            : values?.district,
        job_title_id:
          values?.jobTitle === ALL_SELECTION?.value
            ? numberConstants.zero
            : values?.jobTitle,
        time_type_id: numberConstants.zero,
        cost_center_id: numberConstants.zero,
        emergency_contact_flag: values?.emergencyContact
          ? Y_CHECKER
          : N_CHECKER,
        system_name: systemName,
        report_type:
          employeeSearchForm.values.printType ===
          t("employeeSearch.printEmergencyContact")
            ? E
            : C,
        operational_center_id:
          values?.defaultOperationalCenter === ALL_SELECTION?.value
            ? numberConstants.zero
            : values?.defaultOperationalCenter,
        work_center_id: numberConstants.zero,
        work_category_id: numberConstants.zero,
        supervisor_id: numberConstants.zero,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      const url = CF_URLS.printReports.employeReports.employecontactreport;
      let reportResponse = await printReport(requestBody, url);
      if (!reportResponse?.success) {
        setThrowErrorMessage(reportResponse?.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePrintClose = () => {
    setIsVisiblePrint(false);
    employeeSearchForm.setFieldValue(
      "printType",
      t("employeeSearch.printEmergencyContact")
    );
  };
  return (
    <>
      <CommonModal
        open={isVisiblePrint}
        title={t("employeeSearch.printModalTitle")}
        body={
          <Grid display={"flex"} direction={"column"}>
            <Grid item>
              <Typography>{t("common.printReportTitle")}</Typography>
            </Grid>
            <Grid item gap={spacing.horizontalMargin20}>
              <FormControl id="radio-control">
                <RadioGroup
                  id="radio-group"
                  name="printType"
                  column
                  aria-labelledby="Media Request Authorization Type"
                  value={employeeSearchForm.values.printType}
                  onChange={employeeSearchForm.handleChange}
                  sx={EmployeeSearchStyles.printModalRadio}
                >
                  <FormControlLabel
                    id="radio-form-control-1"
                    value={t("employeeSearch.printEmergencyContact")}
                    control={<Radio />}
                    label={t("employeeSearch.printRadioEmergencyContact")}
                  />

                  <FormControlLabel
                    id="radio-form-control-2"
                    value={t("employeeSearch.printContact")}
                    control={<Radio />}
                    label={t("employeeSearch.printRadioContact")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={handlePrintClose}>
              {t("common.close")}
            </Button>
            <Button
              variant="contained"
              onClick={() => setIsDownloadModalOpen(true)}
            >
              {t("common.print")}
            </Button>
          </>
        }
      ></CommonModal>

      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={isDownloadModalOpen}
        downloadOptions={downloadReportOptions}
        onCancleClick={handleDownloadClose}
        onDownloadClick={handleDownload}
        setSelectedValue={setSelectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
    </>
  );
};

export default EmployeeSearchPrintModal;
