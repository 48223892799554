import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, Grid, IconButton, Typography } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { DatePickerField } from "components/core";
import { labelPrinterSetupStyles } from "components/label-printing/label-printer-setup/LabelPrinterSetupStyles";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { callToCloudFunction } from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { errorMsgs } from "utils/constants";
import {
  iconType1,
  notStarted
} from "utils/constants/open-media-processing/WorkManagement";
import { getAuthenticatedUserBranch } from "utils/helpers";
import { WorkManagementStyles } from "../WorkManagementStyles";

const PickingUpdateModal = ({
  isModalOpen,
  handleModalClose,
  fetchSecondLevel,
  selectedRow
}) => {
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [serviceDate, setServiceDate] = useState("");
  const [serviceDateDisable, setServiceDateDisable] = useState(false);

  const editButtonStatus = useCallback(async () => {
    if (
      selectedRow.task_status === notStarted &&
      selectedRow.icon_type_id === iconType1
    ) {
      setServiceDateDisable(false);
    } else {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode25354);
      setThrowError(true);
      setThrowErrorMessage(errDesc[0].descr);
      setServiceDateDisable(true);
    }
  }, [selectedRow]);
  useEffect(() => {
    setServiceDate(selectedRow?.request_service_date);
    editButtonStatus();
  }, [
    selectedRow.task_status,
    selectedRow.icon_type_id,
    selectedRow?.request_service_date,
    editButtonStatus
  ]);

  const updatePick = async () => {
    try {
      const districtId = getAuthenticatedUserBranch();
      const reqBody = {
        main_district_id: districtId,
        customer_id: selectedRow?.customer_id,
        service_date: serviceDate
      };
      const response = await callToCloudFunction(
        reqBody,
        `${CF_URLS.openMediaProcessing?.workManagement.picking.updateServiceDate}`
      );
      if (response.data.isSuccess) {
        fetchSecondLevel(selectedRow?.customer_id, true);
        handleModalClose();
      }
    } catch (error) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode25395);
      setThrowError(true);
      setThrowErrorMessage(errDesc);
    }
  };

  const handleFilterServiceDate = (e) => {
    setServiceDate((prev) => dayjs(e));
  };
  return (
    <>
      <CommonModal
        open={isModalOpen}
        title={t("workManagement.picking.editWorkManagement")}
        body={
          <>
            {throwError && (
              <Alert
                severity="warning"
                style={WorkManagementStyles.alertPadding}
                icon={
                  <img
                    style={labelPrinterSetupStyles.alertSize}
                    src={ErrorIcon}
                    alt="error"
                  />
                }
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("workManagement.picking.customer")}
                </Typography>
                <Typography variant="subtitle1">
                  {selectedRow?.customer_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("workManagement.picking.list")}
                </Typography>
                <Typography variant="subtitle1">
                  {selectedRow?.request_type_created_dateTime}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <DatePickerField
                  id="serviceDateId"
                  name="serviceDate"
                  disabled={serviceDateDisable}
                  label={t("branchOutagePickList.date")}
                  value={dayjs(serviceDate)}
                  onChange={(val) => handleFilterServiceDate(val)}
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button onClick={handleModalClose} variant="outlined">
              {t("common.cancel")}
            </Button>
            <Button
              disabled={serviceDateDisable}
              onClick={() => updatePick()}
              variant="contained"
            >
              {t("common.update")}
            </Button>
          </>
        }
      />
    </>
  );
};
export default PickingUpdateModal;
