import { Grid, Typography } from "@mui/material";
import { CircularLoaderFullPage } from "components/core";
import dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS, numberConstants } from "utils/constants";
import { personnelDetailStyles } from "./PersonnelDetailStyles";

const PersonnelHistoryTable = ({
  isResetGrid,
  isLoading,
  historyData,
  handleChangePage,
  totalRecords,
  personalHistoryForm,
  firstName,
  lastName
}) => {
  const { t } = useTranslation();
  const personnalName = `${lastName}, ${firstName}`;
  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    download: true,
    search: true,
    filter: true,
    viewColumns: true,
    onChangePage: handleChangePage,
    count: totalRecords,
    pagination: true,
    rowsPerPage: numberConstants.tweleve,
    textLabels: {
      body: {
        noMatch:
          totalRecords === numberConstants.zero &&
          (!isResetGrid
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`)
      }
    },
    selectableRowsOnClick: true
  };

  const columns = [
    {
      name: "change_datetime",
      label: t("personalHistory.date"),
      options: {
        customBodyRender: (value) => {
          return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
        },
        filter: false // Disable filter for this column
      }
    },
    {
      name: "customer_number",
      label: t("personalHistory.customer"),
      options: {
        filter: true, // Disable filter for this column
        filterOptions: {
          fullWidth: true
        }
      }
    },

    {
      name: "change_history_type_description",
      label: t("personalHistory.details"),
      options: {
        filter: false // Disable filter for this column
      }
    },
    {
      name: "old_value",
      label: t("personalHistory.previousValue"),
      options: {
        filter: false // Disable filter for this column
      }
    },
    {
      name: "new_value",
      label: t("personalHistory.newValue"),
      options: {
        filter: false // Disable filter for this column
      }
    },
    {
      name: "authorized",
      label: t("personalHistory.authorizedPersonnel"),
      options: {
        filter: false // Disable filter for this column
      }
    },
    {
      name: "employee_first_name",
      label: t("personalHistory.imEmployee"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
        filter: false // Disable filter for this column
      }
    },
    {
      name: "reason",
      label: t("personalHistory.byePassReason"),
      options: {
        filter: false // Disable filter for this column
      }
    }
  ];
  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      <Grid
        display={"flex"}
        direction={"column"}
        gap={spacing.verticalMargin20}
      >
        <Grid item xs={12} lg={3} marginTop={spacing.margingTop20}>
          <h3>
            {t("personalHistory.personnelHistoryTitle", {
              name: personnalName
            })}
          </h3>
        </Grid>
        <Grid item xs={12} lg={3}>
          <MUIDataTable
            columns={columns}
            options={options}
            sx={
              personnelDetailStyles.personalHistoryStyles
                .historyTableFilterStyles
            }
            data={historyData}
            title={
              !isResetGrid ? (
                <Typography>
                  {totalRecords} {t("common.recordsFound")}
                </Typography>
              ) : (
                ""
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
export default PersonnelHistoryTable;
