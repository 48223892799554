import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as FloatingPlus } from "assets/images/Plus.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SvgIcon } from "@mui/material";
import { useEffect } from "react";
import {
  LABEL_TEMPLATE_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  TEST_LABEL_TEMPLATE
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES, errorMsgs, VALUE_EMPTY_STRING } from "utils/constants";
import {
  brackets,
  labelTemplateConstants
} from "utils/constants/customer-service-information/LabelTemplate";
import {
  findErrorMessage,
  getGlobalAttributeValue,
  getResponseData
} from "utils/helpers";
import LabelAssignCustomerModal from "./LabelAssignCustomerModal";
import { labelTemplateMaintanceStyles } from "./LabelTemplateMaintenanceStyles";

const LabelTemplateMaintenanceScreen = () => {
  const navigate = useNavigate();
  const [labelTemplate, setLabelTemplate] = useState("");
  const [isSaveTestEnabled, setSaveTestEnabled] = useState(false);
  const [isTestEnabled, setTestEnabled] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [openChangesModal, setOpenChangesModal] = useState(false);
  const [description, setDescription] = useState("");
  const { currentBranch } = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [labelId, setLabelId] = useState("");
  const [assignedCustomers, setAssignedCustomers] = useState([]);
  const [displayText, setDisaplyText] = useState(VALUE_EMPTY_STRING);
  const [deleteBtnStatus, setDeleteBtnStatus] = useState(true);
  const [deleteAlertModal, setDeleteAlertModal] = useState(false);
  const [deleteAlertMsg, setDeleteAlertMsg] = useState("");
  const [cancelClick, setCancelClick] = useState(false);
  const [disabelLabelTemplate, setDisabelLabelTemplate] = useState(false);
  const [disableCustomer, setDisableCustomer] = useState(true);
  const [editStatus, setEditStatus] = useState(false);

  let getLabelTemplate = JSON.parse(
    localStorage.getItem(labelTemplateConstants.labelTemplateId)
  );
  const testLabelTemplate = async () => {
    let labels = {};
    labels.customers = assignedCustomers;
    labels.template = { template: labelTemplate };
    labels.descr = await labelValidation();
    labels.isEdit = editStatus ? true : false;
    localStorage.setItem(
      labelTemplateConstants.labelTemplateId,
      JSON.stringify(labels)
    );
    if (await labelValidation()) {
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${TEST_LABEL_TEMPLATE}`
      );
    }
  };

  const cancel = () => {
    setCancelClick(true);
    if (!isSaveTestEnabled) {
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${LABEL_TEMPLATE_SEARCH}`,
        {
          state: { findCall: true }
        }
      );
    } else {
      setOpenChangesModal(true);
    }
  };

  useEffect(() => {
    if (getLabelTemplate?.template?.template) {
      setLabelTemplate(getLabelTemplate?.template?.template);
      setLabelId(getLabelTemplate?.template?.label_template_id);
      setDisableCustomer(false);
      setAssignedCustomers(getLabelTemplate?.customers);
      setDescription(getLabelTemplate?.descr);
      setTestEnabled(true);
      if (getLabelTemplate?.isEdit) {
        setSaveTestEnabled(false);
        setEditStatus(true);
      } else {
        setSaveTestEnabled(true);
        setEditStatus(false);
      }
      if (getLabelTemplate?.customers?.length > 1) {
        setDeleteBtnStatus(true);
        setDisabelLabelTemplate(true);
      } else {
        setDeleteBtnStatus(false);
      }
    }
    //eslint-disable-next-line
  }, []);
  const handleLabelTemplateChange = (e) => {
    setTestEnabled(true);
    setSaveTestEnabled(true);
    setLabelTemplate(e.target.value);
  };
  const getGlobalAttribute = async () => {
    return await getGlobalAttributeValue(
      currentBranch?.value,
      labelTemplateConstants.system_id,
      labelTemplateConstants.global_attribute_type_id
    );
  };

  const deleteTemplate = async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        label_template_id: labelId
      };

      const deleteData = await getResponseData(
        reqBody,
        CF_URLS.labelTemplateMaintanence.deleteTemplate,
        1
      );
      if (deleteData.data[0]) {
        setDescription("");
        setIsAssignModalOpen(false);
        setLoading(false);
        setThrowError(false);
        setDeleteAlertModal(false);
        setOpenChangesModal(false);
        setLabelId("");
        setLabelTemplate("");
        setAssignedCustomers([]);
        setSaveTestEnabled(false);
        setTestEnabled(false);
        setDeleteBtnStatus(true);
        localStorage.clear();
        setThrowError(false);
        setThrowErrorMessage(null);
      } else {
        setLoading(false);
        setThrowError(true);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const labelValidation = async () => {
    // Perform all validations before proceeding
    if (!labelTemplate) {
      setLoading(false);
      const err = await getMessageFromCode(errorMsgs.errorCode20259);
      setThrowError(true);
      setThrowErrorMessage(
        err[0]?.descr.replace("|", labelTemplateConstants.labelTemplate)
      );

      return;
    }

    // Updated Regex patterns without unnecessary escapes
    const sPATTERN_INVALID_CHARS = /[^0-9A-Za-z{}[\]<>]/; // Valid
    const sPATTERN_REQUIRED_CHARS = /[<>]/; // No escape needed for []
    const sPATTERN_IGNORE_NO_CHARS = /\{[^}]+\}/; // Valid
    if (sPATTERN_INVALID_CHARS.test(labelTemplate)) {
      setLoading(false);
      const err = await getMessageFromCode(errorMsgs.errorCode25087);
      const errReplace = err[0]?.descr.replace(
        "|",
        labelTemplate.match(sPATTERN_INVALID_CHARS).input
      );
      setThrowError(true);
      setThrowErrorMessage(errReplace);

      return;
    }

    if (sPATTERN_REQUIRED_CHARS.test(labelTemplate)) {
      if (!labelTemplate.match(sPATTERN_REQUIRED_CHARS)) {
        setLoading(false);

        const err = await getMessageFromCode(errorMsgs.errorCode25125);
        const errReplace = err[0]?.descr.replace("|", brackets);
        setThrowError(true);
        setThrowErrorMessage(errReplace);

        return;
      }
    }

    if (sPATTERN_IGNORE_NO_CHARS.test(labelTemplate)) {
      setLoading(false);

      const err = await getMessageFromCode(errorMsgs.errorCode25124);
      const errReplace = err[0]?.descr.replace(
        "|",
        labelTemplate.match(sPATTERN_IGNORE_NO_CHARS)
      );
      setThrowError(true);
      setThrowErrorMessage(errReplace);

      return;
    }
    const nestedBracketPattern =
      //eslint-disable-next-line
      /(\{[^\}]*[\[\]<>][^\}]*\})|(\[[^\]]*[{}<>][^\]]*\])|(<[^>]*[{}\[\]][^>]*>)/;

    if (nestedBracketPattern.test(labelTemplate)) {
      setLoading(false);

      const err = await getMessageFromCode(errorMsgs.errorCode25123);
      const errReplace = err[0]?.descr;
      setThrowError(true);
      setThrowErrorMessage(errReplace);

      return;
    }
    const openClosePairs = {
      "<": ">",
      "{": "}",
      "[": "]"
    };

    for (const openBracket in openClosePairs) {
      const closeBracket = openClosePairs[openBracket];
      const openCount = (
        labelTemplate.match(new RegExp(`\\${openBracket}`, "g")) || []
      ).length;
      const closeCount = (
        labelTemplate.match(new RegExp(`\\${closeBracket}`, "g")) || []
      ).length;

      if (openCount !== closeCount) {
        setLoading(false);

        const err = await getMessageFromCode(errorMsgs.errorCode25239);
        const errReplace = err[0]?.descr.replace("|", openBracket);
        setThrowError(true);
        setThrowErrorMessage(errReplace);
        setSaveTestEnabled(false);
        setTestEnabled(false);
        return;
      }
    }
    let volserMaxLength = await getGlobalAttribute();
    const validInsertPattern = /<([0-9A-Za-z]+)>/g;
    const validLiteralPattern = /\[([0-9A-Za-z]+)\]/g;
    const validIgnorePattern = /\{\}/g;
    let iVolserMinFirstIndex = Infinity;

    let iVolserBaseLen = 0;
    let strippedTemplate = labelTemplate
      .replace(validInsertPattern, "")
      .replace(validLiteralPattern, "")
      .replace(validIgnorePattern, "");

    // Disallowed characters outside brackets validation
    const outsideBracketContent = strippedTemplate
      .replace(/<[^>]+>/g, "") // Remove content inside <>
      .replace(/\[[^\]]+\]/g, "") // Remove content inside []
      .replace(/\{[^}]+\}/g, ""); // Remove content inside {}
    if (outsideBracketContent.replace(/CUST|MT|V/g, "").length > 0) {
      setLoading(false);
      const err = await getMessageFromCode(errorMsgs.errorCode25126);
      const errReplace = err[0]?.descr;
      setThrowError(true);
      setThrowErrorMessage(errReplace);
      return;
    }
    // Process valid inserts
    const insertMatches = [
      ...(labelTemplate?.matchAll(validInsertPattern) || [])
    ];
    insertMatches.forEach((match) => {
      const matchIndex = match.index;
      if (matchIndex < iVolserMinFirstIndex) {
        iVolserMinFirstIndex = matchIndex;
      }
    });

    // Process valid literals
    const literalMatches = [
      ...(labelTemplate?.matchAll(validLiteralPattern) || [])
    ];
    literalMatches.forEach((match) => {
      const matchIndex = match.index;
      if (matchIndex < iVolserMinFirstIndex) {
        iVolserMinFirstIndex = matchIndex;
      }
    });

    // Remove all modifiers from the text
    let modifiedText = labelTemplate
      .replace(validInsertPattern, "")
      .replace(validLiteralPattern, "");

    // Count the number of 'V's in the remaining text
    iVolserBaseLen = (modifiedText.match(/V/g) || []).length;

    // Update the minimum index if 'V' is found
    if (iVolserBaseLen > 0) {
      iVolserMinFirstIndex = Math.min(
        iVolserMinFirstIndex,
        modifiedText.indexOf(labelTemplateConstants.V)
      );
    }
    if (iVolserBaseLen < 1 || iVolserBaseLen > volserMaxLength) {
      setLoading(false);

      const err = await getMessageFromCode(errorMsgs.errorCode25122);
      const errReplace = err[0]?.descr.replace("|", volserMaxLength);
      setThrowError(true);
      setThrowErrorMessage(errReplace);

      return;
    }

    // Ensure 'CUST' and 'MT' appear only once and in the correct order
    const custCount = (labelTemplate.match(/CUST/g) || []).length;
    const mtCount = (labelTemplate.match(/MT/g) || []).length;
    if (custCount > 1 || mtCount > 1) {
      setLoading(false);

      const err = await getMessageFromCode(errorMsgs.errorCode25246);
      const errReplace = err[0]?.descr.replace(
        "|",
        custCount > 1
          ? t("labelTemplateSearch.cust")
          : t("labelTemplateSearch.mt")
      );
      setThrowError(true);
      setThrowErrorMessage(errReplace);

      return;
    }

    if (
      strippedTemplate.includes(t("labelTemplateSearch.mt")) &&
      strippedTemplate.includes(t("labelTemplateSearch.cust")) &&
      strippedTemplate.indexOf(t("labelTemplateSearch.cust")) >
        strippedTemplate.indexOf(t("labelTemplateSearch.mt"))
    ) {
      setLoading(false);
      const err = await getMessageFromCode(errorMsgs.errorCode25127);
      const errReplace = err[0]?.descr;
      setThrowError(true);
      setThrowErrorMessage(errReplace);

      return;
    }

    if (strippedTemplate.includes(t("labelTemplateSearch.cust"))) {
      if (!labelTemplate.startsWith(t("labelTemplateSearch.cust"))) {
        setLoading(false);
        const err = await getMessageFromCode(errorMsgs.errorCode25127);
        const errReplace = err[0]?.descr;
        setThrowError(true);
        setThrowErrorMessage(errReplace);
        return;
      }
    }
    const indexL1 = labelTemplate.indexOf("[");
    const indexMT = labelTemplate.indexOf(t("labelTemplateSearch.mt"));
    if (indexL1 !== -1 && indexMT !== -1 && indexL1 < indexMT) {
      setLoading(false);
      const err = await getMessageFromCode(errorMsgs.errorCode25127);
      const errReplace = err[0]?.descr;
      setThrowError(true);
      setThrowErrorMessage(errReplace);
      return;
    }

    let newDescription = "";
    if (strippedTemplate.includes(labelTemplateConstants.cust))
      newDescription += t("labelTemplateMaintenance.customer+");
    if (strippedTemplate.includes(labelTemplateConstants.mt))
      newDescription += t("labelTemplateMaintenance.mediaType+");

    //eslint-disable-next-line
    const bracketRegexlessthansymbol = /\<(.*?)\>/;
    //eslint-disable-next-line
    const bracketRegexArraySymbol = /\[(.*?)\]/;
    const labelHaslessthansymbol = labelTemplate.includes("<")
      ? bracketRegexlessthansymbol.exec(labelTemplate)[1]?.length
      : 0;
    const labelHasArraysymbol = labelTemplate.includes("[")
      ? bracketRegexArraySymbol.exec(labelTemplate)[1]?.length
      : 0;
    const volserLen =
      labelHaslessthansymbol + labelHasArraysymbol + iVolserBaseLen;
    newDescription += `volser(${volserLen})`;
    setDescription(newDescription);
    return newDescription;
  };

  const handleSaveClick = async () => {
    try {
      setLoading(true);
      setSaveTestEnabled(true);
      setTestEnabled(true);
      if (await labelValidation()) {
        const reqBody = {
          main_district_id: currentBranch?.value,
          template: labelTemplate,
          descr: description || (await labelValidation())
        };

        const saveData = await getResponseData(
          reqBody,
          CF_URLS.labelTemplateMaintanence.saveTemplate,
          1
        );
        if (saveData.data[0][0]?.error) {
          setDeleteBtnStatus(false);
          setLoading(false);
          const errdescr = await getMessageFromCode(saveData.data[0][0]?.error);
          const errMsg =
            saveData.data[0][0]?.error === errorMsgs.errorCode25237
              ? errdescr[0]?.descr.replace("|", labelTemplate)
              : saveData.data[0][0]?.error === errorMsgs.errorCode10085
                ? errdescr[0]?.descr
                : findErrorMessage(ERROR_TYPES.ISSUE);

          setThrowError(true);
          setThrowErrorMessage(errMsg);
          setOpenChangesModal(false);
        } else if (saveData?.data[0]?.error === 1) {
          setLoading(false);
          setThrowError(true);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          setOpenChangesModal(false);
        } else {
          setLoading(false);
          setSaveTestEnabled(false);
          setTestEnabled(true);
          setEditStatus(true);
          setLabelId(saveData.data[0][0].label_template_id);
          setOpenChangesModal(false);
          setThrowError(false);
          setThrowErrorMessage(null);
          setDisableCustomer(false);

          if (cancelClick) {
            navigate(
              `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${LABEL_TEMPLATE_SEARCH}`,
              {
                state: { findCall: true }
              }
            );
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const handleKeyPress = (e) => {
    setSaveTestEnabled(true);
    setTestEnabled(true);
    const { key } = e;
    if (key === "{") {
      e.preventDefault();
      setLabelTemplate((prev) => prev + "{}");
    } else if (/[a-z]/.test(key)) {
      e.preventDefault();
      setLabelTemplate((prev) => prev + key.toUpperCase());
    } else if (key === " ") {
      e.preventDefault();
      // eslint-disable-next-line no-useless-escape
    } else if (
      // eslint-disable-next-line no-useless-escape
      !/[0-9A-Z{}\[\]<>]/.test(key) &&
      key !== labelTemplateConstants.backspace
    ) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    const formattedNumbers =
      assignedCustomers.length > 0 &&
      assignedCustomers
        ?.map((item) => item.number.trim()) // Trim any whitespace
        ?.sort(); // Sort the numbers
    let displayString;
    if (formattedNumbers?.length > 4) {
      displayString = `${formattedNumbers.slice(0, 4).join(",")} ...`;
    } else {
      displayString =
        formattedNumbers.length > 0 ? formattedNumbers?.join(",") : "";
    }
    setDisaplyText(displayString);
  }, [assignedCustomers]);

  const newLabelTemplate = () => {
    if (!isSaveTestEnabled) {
      localStorage.clear();
      setLabelTemplate("");
      setAssignedCustomers([]);
      setDisabelLabelTemplate(false);
      setSaveTestEnabled(false);
      setTestEnabled(false);
      setDeleteBtnStatus(true);
      setDescription("");
      setDisableCustomer(true);
    } else {
      setOpenChangesModal(true);
    }
  };
  const deleteAlert = async () => {
    const errDesc = await getMessageFromCode(errorMsgs.errorCode62518);
    setDeleteAlertMsg(
      errDesc[0]?.descr?.replace("|", labelTemplateConstants.labelTemplate)
    );
    setDeleteAlertModal(true);
  };
  return (
    <>
      {loading && <CircularLoaderFullPage loading={loading} />}

      {throwError && (
        <Box my={spacing.gap}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Box>
      )}
      <Box>
        <Typography variant="h5" component="h1" gutterBottom>
          {t("labelTemplateMaintenance.subTitle")}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={t("labelTemplateMaintenance.labelTemplate")}
              variant="outlined"
              value={labelTemplate}
              inputProps={{ maxLength: 255 }}
              onKeyPress={handleKeyPress}
              onChange={(e) => handleLabelTemplateChange(e)}
              disabled={disabelLabelTemplate}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              {t("labelTemplateMaintenance.description")}
            </Typography>
            <Typography variant="body2"> {description}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={t("labelTemplateMaintenance.assignedCustomers")}
              variant="outlined"
              value={displayText}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={disableCustomer}
                      onClick={() => setIsAssignModalOpen(true)}
                    >
                      <SvgIcon component={FloatingPlus} />
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={labelTemplateMaintanceStyles.dividerMargin}
        ></Grid>

        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            {t("labelTemplateSearch.symbol")}
          </Typography>
          <Typography variant="body2">
            {t("labelTemplateSearch.cust")} {t("labelTemplateSearch.customer")}{" "}
            &nbsp;&nbsp;{t("labelTemplateSearch.mt")} ={" "}
            {t("labelTemplateSearch.mediaType")} &nbsp;&nbsp;{" "}
            {t("labelTemplateSearch.v")} = {t("labelTemplateSearch.volser")}
            &nbsp;&nbsp; {`{}`} = {t("labelTemplateSearch.ignorePosition")}{" "}
            &nbsp;&nbsp; &lt;&gt; = {t("labelTemplateSearch.insertCharacters")}{" "}
            &nbsp;&nbsp; [] ={t("labelTemplateSearch.requiredCharacters")}
          </Typography>
        </Box>

        <Box mt={4} display="flex" gap={2}>
          <Button variant="outlined" onClick={() => cancel()}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="outlined"
            disabled={!isTestEnabled}
            onClick={() => testLabelTemplate()}
          >
            {t("labelTemplateMaintenance.test")}
          </Button>
          <Button
            variant="outlined"
            disabled={deleteBtnStatus}
            onClick={() => deleteAlert()}
          >
            {t("common.delete")}
          </Button>
          <Button variant="contained" onClick={() => newLabelTemplate()}>
            {" "}
            {t("common.new")}
          </Button>
          <Button
            variant="contained"
            disabled={!isSaveTestEnabled}
            onClick={() => handleSaveClick()}
          >
            {t("common.save")}
          </Button>
        </Box>
      </Box>
      {openChangesModal && (
        <ConfirmPopup
          modalPopupOpen={openChangesModal}
          message={t("containerProcessing.picking.saveErr")}
          handleNo={() => {
            if (cancelClick) {
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${LABEL_TEMPLATE_SEARCH}`,
                {
                  state: { findCall: true }
                }
              );
            }
            setDeleteBtnStatus(true);
            setDescription("");
            setLabelTemplate("");
            setSaveTestEnabled(false);
            setTestEnabled(false);
            setOpenChangesModal(false);
            localStorage.clear();
            setAssignedCustomers([]);
            setDisabelLabelTemplate(false);
            setDisableCustomer(true);
          }}
          handleYes={() => handleSaveClick()}
          handleCancel={() => {
            setOpenChangesModal(false);
            setCancelClick(false);
          }}
          showCancel={true}
          showNo={true}
        />
      )}
      {deleteAlertModal && (
        <ConfirmPopup
          modalPopupOpen={deleteAlertModal}
          message={deleteAlertMsg}
          handleNo={() => {
            setDeleteAlertModal(false);
          }}
          handleYes={() => deleteTemplate()}
          showNo={true}
        />
      )}
      <LabelAssignCustomerModal
        deleteAlertModal={deleteAlertModal}
        setDeleteAlertModal={setDeleteAlertModal}
        setDeleteAlertMsg={setDeleteAlertMsg}
        isAssignModalOpen={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        labelId={labelId}
        assignedCustomers={assignedCustomers}
        setAssignedCustomers={setAssignedCustomers}
      />
    </>
  );
};
export default LabelTemplateMaintenanceScreen;
