import { Button, FormControl, FormLabel, Grid, TextField } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useEffect, useState } from "react";

const VaultRenameModal = ({
  title,
  bodyOpen,
  currentVaultName,
  handleRenameClose,
  handleRenameSave
}) => {
  const [newText, setNewText] = useState("");
  const handleNewNameChange = (e) => {
    setNewText(e.target.value?.toUpperCase());
  };

  const handleSave = () => {
    handleRenameSave(newText);
  };

  useEffect(() => {
    setNewText("");
  }, [bodyOpen]);

  return (
    <CommonModal
      open={bodyOpen}
      body={
        <>
          <Grid container spacing={2} alignItems="">
            <Grid item md={12} lg={6} xs={24}>
              <FormControl>
                <FormLabel>
                  {t("logicalVaultDetails.currentVaultName")}
                </FormLabel>
                {currentVaultName}
              </FormControl>
            </Grid>
            <Grid item md={12} lg={6} xs={24}>
              <FormControl>
                <FormLabel id="newVaultLabel" required>
                  {t("logicalVaultDetails.newVaultName")}
                </FormLabel>
                <TextField
                  id="newVaultName"
                  name={"newVaultName"}
                  value={newText}
                  onChange={handleNewNameChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      }
      title={title}
      buttons={
        <>
          <Button onClick={handleRenameClose} variant="outlined">
            {t("logicalVaultDetails.close")}
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={!newText?.trim()}
          >
            {t("logicalVaultDetails.save")}
          </Button>
        </>
      }
    />
  );
};

export default VaultRenameModal;
