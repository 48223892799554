import { t } from "i18next";

export const preferencesTypeIds = ["35", "36", "37", "38"];

export const preferenceXML = "<ObjectValues>|</ObjectValues>";
export const preferenceXMLValues =
  '<ObjectValue Object_Id="|" Object_Value="|" Version=""/>';
export const padStartLength = 10;
export const preferenceTypeMap = {
  35: t("preferences.scanOnSitePickup"),
  36: t("preferences.interactiveScan"),
  37: t("preferences.scanOnSiteDeliveery"),
  38: t("preferences.comments")
};

export const permissionId322 = "322";
export const permissionId325 = "325";
export const thirdPartyId = "3";

export const filterOptions = [
  { label: t("preferences.all"), value: "all" },
  { label: t("preferences.comments"), value: "38" },
  { label: t("preferences.interactiveScan"), value: "36" },
  { label: t("preferences.scanOnSiteDeliveery"), value: "37" },
  { label: t("preferences.scanOnSitePickup"), value: "35" }
];

export const systemId = 69;
export const zero = "0";
export const all = "all";
export const dataIndex = {
  date: "date",
  imEmployee: "imEmployee",
  authorizedBy: "authorizedBy",
  changedValues: "changedValues",
  name: "name",
  previousValue: "previousValue",
  newValue: "newValue"
};
export const filterPopupId = "filter-popover";
