import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Typography
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { useTranslation } from "react-i18next";
import { ConfrimPopupStyles } from "./ConfirmPopupStyles";

const ConfirmPopup = ({
  modalPopupOpen,
  handleCancel,
  handleNo,
  handleYes,
  showCancel,
  showNo,
  message,
  showOk,
  throwWarning,
  throwWarningMessage,
  setThrowWarning,
  throwError,
  setThrowError,
  throwErrorMessage
}) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={24}>
      <Dialog
        open={modalPopupOpen}
        fullWidth={true}
        PaperProps={{
          sx: {
            ...ConfrimPopupStyles.dialogModal
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={ConfrimPopupStyles.paddingTitleStyle}
        >
          {/*Modal title*/}
          <Typography sx={ConfrimPopupStyles.titleStyle}>
            {t("common.confirm")}
          </Typography>
        </DialogTitle>
        <Divider />
        {/* ------------------ Body Section ------------------ */}{" "}
        <DialogContent sx={ConfrimPopupStyles.cardContentStyles}>
          {/* Error alert popup */}
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              sx={ConfrimPopupStyles.alert}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
          {/*Warning Alert*/}
          <Collapse in={throwWarning}>
            <Alert
              severity="warning"
              icon={<img src={WarningIcon} alt="warning" />}
              sx={ConfrimPopupStyles.alert}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowWarning(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwWarningMessage}
            </Alert>
          </Collapse>
          {/*Body section text*/}
          <Typography
            sx={ConfrimPopupStyles.alertMessage}
            id="modal-description"
          >
            {message || t("common.saveChangesNow")}
          </Typography>{" "}
        </DialogContent>
        <Divider />
        {/* ------------------ Footer Section ------------------ */}{" "}
        <DialogActions sx={ConfrimPopupStyles.modalButton}>
          {/*Cancel button*/}
          {showCancel && (
            <Button variant="outlined" onClick={() => handleCancel()}>
              {t("common.cancel")}
            </Button>
          )}
          {/*No button*/}
          {showNo && (
            <Button
              variant="outlined"
              onClick={() => {
                handleNo();
              }}
            >
              {t("common.no")}
            </Button>
          )}
          {/*Yes button*/}

          <Button
            variant="contained"
            onClick={() => {
              handleYes();
            }}
          >
            {showOk ? t("common.ok") : t("common.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ConfirmPopup;
