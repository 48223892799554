import { ReactComponent as Clock } from "assets/images/Clock.svg";
import { ReactComponent as DoubleUser } from "assets/images/DoubleUser.svg";
import { ReactComponent as Truck } from "assets/images/Truck.svg";
import { ReactComponent as User } from "assets/images/User.svg";
import { nodeTypes } from "utils/constants/customer-service-information/RouteManagementConstants";

export const TreeIcon = ({ type }) => {
  switch (type) {
    case nodeTypes.route:
      return <Truck />;
    case nodeTypes.time:
      return <Clock />;
    case nodeTypes.parentCustomer:
      return <DoubleUser />;
    case nodeTypes.customer:
      return <User />;
    default:
      return null;
  }
};
