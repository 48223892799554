export const CustomerFileStyles = {
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  textStyle: {
    width: "100%",
    marginBottom: "20px"
  },
  fieldStyle: {
    marginTop: "10px"
  },
  inputBorderHide: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none"
      }
    }
  },
  textAreaStyle: {
    marginTop: "10px"
  },
  PrintModalButton: {
    margin: "1em 0em"
  },
  colorStyle: { color: "var(--color-im-red)" },
  actionColumnStyle: { minWidth: "150px" },
  disabledIconsOpacity: { opacity: "0.5" },
  SelectedRowColor: "var(--color-im-light-blue-100)"
};
