export const HorizontalTabsStyles = {
  Container: ({ disabled, styles = {} }) => ({
    ...styles,
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    rowGap: "1.25rem",
    cursor: disabled ? "default" : "auto",
    pointerEvents: disabled ? "none" : "auto",
    opacity: disabled ? "0.5" : "1",
  }),
  TextTransformNone: { textTransform: "initial" },
};
