/**
 * Rules for routes:
 * 1. all routes should be declared here as constants and then re-used across the app.
 * 2. routes should not contain any uppercase letters unless required/requested by client.
 * 3. all routes should be in "kebab-case"
 */

export const ROOT = "/";
export const LOGIN = "/login";
export const MEDIA_DEST_REQ_PICKUP_DETAIL = "/media-destruction-pickup-detail";

// administration module items start
export const ADMIN_MODULE_BASE_PATH = "/admin";
export const PAYROLL = "payroll";
export const ADMIN_MODULE_BILLING_BASE_PATH = "billing";
export const BILLING_STATUS = "status";
export const BILLING_TRANSACTIONS = "transactions";
export const BILLING_ERROR_LOG = "errorLog";
export const ENTERPRISE_BILLING_SUMMARY = "enterpriseSummary";
export const MAINTAIN_BILLING_RULES = "rules";
export const CUSTOMER_BILLING_INFORMATION = "customerInformation";
export const DEFAULT_PRICING_USE_REPORT = "defaultPricingReport";
export const RECURRING_CHANGE_SEARCH = "recurringSearch";
export const RECURRING_CHANGE_DETAIL = "recurringSearch/detail";
export const CAPACITY_BILLING_CONFIGURATION_SEARCH =
  "capacityConfigurationSearch";
export const CAPACITY_BILLING_GENERATION_SEARCH = "capacityGenerationSearch";
export const ADMIN_MODULE_AUTH_BASE_PATH = "auth";
export const PERSONAL_DETAIL = "personalDetail";
export const PERSONAL_SEARCH = "personalSearch";
export const PERSONAL_VALIDATION = "personalValidation";
export const PERSONAL_HISTORY = "personalHistory";
export const ADMIN_MODULE_LABEL_BASE_PATH = "label";
export const PRINT_TAPE_LABEL = "print";
export const LABEL_PRINTER_SETUP = "printerSetup";
export const ADMIN_MODULE_SECURITY_BASE_PATH = "security";
export const EMPLOYEE = "employee";
export const GROUP_EMPLOYEE = "groupEmployee";
export const GROUP_MANAGEMENT = "groupManagement";
export const EMPLOYEE_DETAIL = "employeeDetail";
export const EMPLOYEE_SEARCH = "employeeSearch";
export const NOTIFICATION_CONFIGURATION = "notificationConfig";
export const CUSTOMER_MOVE_SEARCH = "moveSearch";
export const CUSTOMER_MOVE_DETAIL = "moveDetail";
// administration module items end

// requests module items start
export const REQUEST_MODULE_BASE_PATH = "/requests";
export const REQUEST_SEARCH = "search";
export const MEDIA_REQUEST_DETAIL = "media";
export const RESCHEDULE_SERVICE = "reschedule";
export const MEDIA_DESTRUCTION_REQUEST_SEARCH = "searchDestruction";
export const REQUEST_MODULE_DESTRUCTION_BASE_PATH = "destruction";
export const MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP = "pickup";
export const MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY = "delivery";
export const MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY_ID = "delivery/:id";
export const MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED = "vault";
// requests module items end

// operations module items start
export const OPERATIONS_MODULE_BASE_PATH = "/operations";
export const OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH = "customerInfo";
export const SERVICE_INFORMATION = "serviceInfo";
export const SCHEDULE = "schedule";
export const SERVICE_WINDOWS = "serviceWindows";
export const ROUTE_MANAGEMENT = "routes";
export const LABEL_TEMPLATE_SEARCH = "labelSearch";
export const LABEL_TEMPLATE_MAINTENANCE = "labelMaintenance";
export const TEST_LABEL_TEMPLATE = "testLabelTemplate";
export const PREFERENCES = "preferences";
export const PAYINVOICE = "payInvoice";
export const OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH = "disasterRecovery";
export const INSTRUCTION_FILES = "instructionFiles";
export const INSTRUCTION_FILES_CUSTOMER = "instructionFiles/:customerId";
export const EVENT = "event";
export const VIEW_DR_MEDIA = "event/viewdrmedia";
export const EVENT_REQUEST_GENERATOR_DELIVERY = "event/deliveryRequest/:id/:customerId/:customerNumber";
export const EVENT_REQUEST_GENERATOR_OTHER =
  "event/otherRequest/:id/:customerId";
export const EVENT_SEARCH = "eventSearch";
export const DISCREPANCY_MANAGEMENT = "discrepancyManagement";
export const DISCREPANCY_MANAGEMENT_UPDATE = "discrepancyManagementUpdate";
export const DISCREPANCY_MANAGEMENT_DETAIL = "discrepancyManagementDetail";
export const OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH = "logicalVault";
export const LOGICAL_VAULT_DETAILS = "details";
export const ASSIGN_SHELVES = "assignShelves";
export const LOGICAL_VAULT_AUDIT_SEARCH = "auditSearch";
export const OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH =
  "containerProcessing";
export const GENERAL = "general";
export const CONTAINER_ISSUANCE = "issuance";
export const CONTAINER_PROCESSING_RESEARCH = "research";
export const CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL =
  "transactionDetail";
export const REASSIGN_TRANSPORT_CONTENTS = "reassignContents";
export const CONTAINER_INVENTORY_AUDIT_SEARCH = "inventoryAuditSearch";
export const CONTAINER_INVENTORY_AUDIT_DETAIL = "inventoryAuditDetail";
export const CONTAINER_INVENTORY_VIEW_CAR = "viewCar";
export const CONTAINER_INVENTORY_VIEW_DISCREPANCIES = "viewDiscrepancies";
export const OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH =
  "openMediaProcessing";
export const WORK_MANAGEMENT = "workManagement";
export const OPEN_MEDIA_PROCESSING_RESEARCH = "research";
export const OPEN_MEDIA_PROCESSING_RESEARCH_DETAILS = "research/details/:id";
export const BRANCH_OUTAGE_PICK_LIST_COMPLETION = "branchOutageList";
export const CREATE_DISTRIBUTION_LIST = "createDistributionList";
export const CUSTOMER_FILE_SEARCH = "customerFileSearch";
export const OPEN_MEDIA_AUDIT_SEARCH = "openMediaAuditSearch";
export const OPEN_MEDIA_AUDIT_DETAIL = "openMediaAuditDetail";
export const VIEW_CAR = "viewCar";
export const View_Discrepancies = "viewDiscrepancies";
export const OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH =
  "correctiveActionRequests";
export const CORRECTIVE_ACTION_REQUESTS_DETAIL = "detail";
export const CORRECTIVE_ACTION_REQUESTS_SEARCH = "search";
export const OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH = "transportation";
export const ROUTE_DETAIL = "routes";
export const RUN_MANAGEMENT = "run";
export const VENDOR_MANAGEMENT = "vendors";
export const VAULT_CONFIGURATION = "vaultConfig";
export const PREFERENCES_EMAIL_NOTIFICATION = "pickupDeliveryEmailNotification";
export const PREFERENCES_DONT_CALL_DESC = "dontCallResolveDescrepencies";
export const PREFERENCES_PLACE_HOLD_ON_MEDIA_DESTRUCTION_REQUESTS =
  "placeHoldOnMediaDestructionRequests";
export const PREFERENCES_SCAN_ON_SITE = "scanOnSite";
export const PREFERENCES_HOLIDAY_SERVICE = "holidayService";
export const PREFERENCES_SERVICE_INDICATOR = "serviceIndicator";
export const PREFERENCES_ADD_ONCUTOFF_DEADLINE = "addOnCutoffDeadline";
export const PREFERENCES_MINIMUM_EMPTY_TRANSPORTS_REQUIRED_AT_CUSTOMER_SITE =
  "minimumEmptyTransports";
// operations module items end

// reports module items start
export const REPORTS_MODULE_BASE_PATH = "/reports";
export const REPORTS_MODULE_ADMIN_BASE_PATH = "admin";
export const CREATE_INVENTORY_EXTRACT = "inventoryExtract";
export const CUSTOMER_SCHEDULE_EXPIRATION = "scheduleExpiration";
export const CUSTOMER_AUTHORIZATION_FORM = "customerAuthForm";
export const CUSTOMER_AUTHORIZATION_LIST = "customerAuthList";
export const IN_HOUSE_AUTHORIZATION_LIST = "inHouseAuthList";
export const IN_HOUSE_AUTHORIZATION_UPDATE = "inHouseAuthUpdate";
export const RUNS_AUTHORIZATION_LIST = "runsAuthList";
export const RUNS_AUTHORIZATION_UPDATE = "runsAuthUpdate";
export const UNASSIGNED_ACCOUNT_OWNER = "unassignedAccountOwner";
export const REPORTS_MODULE_BILLING_ACTIVITY_PATH = "billing";
export const BILLING_ACTIVITY_COUNTS = "activityCounts";
export const REPORTS_MODULE_CONTAINER_PATH = "container";
export const CONTAINER_INVENTORY = "inventory";
export const TRANSPORT_LOCATIONS = "transportLocations";
export const TEMP_TRANSPORT_HISTORY = "transportHistory";
export const EMPTY_TRANSPORT_DEFICIT = "emptyTransportDeficit";
export const REPORTS_EMPLOYEE = "employee";
export const REPORTS_MODULE_VAULT_PATH = "vault";
export const CONTAINER_DISTRIBUTION_LIST_HISTORY =
  "containerDistributionHistory";
export const VAULT_MANAGEMENT = "management";
export const VAULT_STATUS_REPORT = "statusReport";
export const UPLOAD_ERRORS_HISTORY = "uploadErrorsHistory";
export const RUN_ARRIVAL = "runArrival";
// reports module items end

export const UNDER_CONSTRUCTION = "/under-construction";
export const NOT_FOUND = "/not-found";
