/* Yup validation schemas goes here */
import i18n from "i18n";
import * as yup from "yup";

/* Signin form schema */
export const signinSchema = yup.object().shape({
  userId: yup
    .string()
    .email(i18n.t("signin.invalidEmail"))
    .required(i18n.t("signin.requiredFieldEmail")),
  password: yup.string().required(i18n.t("signin.requiredFieldPassword"))
});
