import { createSlice } from "@reduxjs/toolkit";

// slice initial state
const initialState = {
  authenticationState: null
};

/**
 * @name personnelSlice
 */
export const personnelSlice = createSlice({
  name: "personnelSlice",
  initialState,
  reducers: {
    setAuthenticationState: (state, { payload }) => {
      state.authenticationState = payload;
    }
  }
});

export default personnelSlice.reducer;
