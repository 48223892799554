import { Stack } from "@mui/material";
import BillingSummaryLayout from "components/billing-module/billing-summary/BillingSummaryLayout";
import { PageTitle } from "components/shared";
import i18n from "i18n";

const EnterpriseBillingSummary = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("enterpriseBillingSummary.pageTitle")} />
      <BillingSummaryLayout />
    </Stack>
  );
};

export default EnterpriseBillingSummary;
