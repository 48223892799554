import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TableContainer,
  Typography
} from "@mui/material";
import { FullCircularLoader } from "components/core";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  clearError,
  setDontCallHistory,
  setError,
  setIsRefreshRequired
} from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  NO,
  N_CHECKER,
  YES,
  Y_CHECKER,
  dateFormatWithTime,
  dateFormatWithoutTime,
  errorMsgs,
  numberConstants
} from "utils/constants";
import {
  getPreferenceTypesPayloadForReport,
  preferencesTypeIds
} from "utils/constants/customer-information/CustomerPreference";
import { getResponseData } from "utils/helpers";
import PreferencesLayout from "./preferences-main/PreferencesLayout";
import PreferencesReoprtDownloadModal from "./PreferencesReoprtDownloadModal";
import { PreferencesStyles, emailStyles } from "./PreferencesStyles";

const DontCallResolveDesc = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { localeByBranch } = useSelector(selectAuth);
  const [isDownload, setIsDownload] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { dontCallHistory, defaultAuthBranchValues, isAuthenticated } =
    useSelector((state) => state.customerPreferences);
  const { prefenceFilteredTableData, customerName } = defaultAuthBranchValues;

  const { state = {} } = useLocation();
  let hasRun = false;
  const [preference, setPreference] = useState({});

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: true,
    textLabels: {
      body: {
        noMatch: (
          <Box pb={spacing.gap}>
            <Typography style={PreferencesStyles.noDataText}>
              {t("common.noRecordsFound")}
            </Typography>
          </Box>
        )
      }
    }
  };

  const convertDatebyLocale = (date, format) => {
    let selectedFormat = format ? format : dateFormatWithoutTime;
    import(`dayjs/locale/${localeByBranch}.js`);
    const formattedDate = date
      ? dayjs.utc(date).locale(localeByBranch).format(selectedFormat)
      : "";
    return formattedDate;
  };

  const columns = [
    {
      name: "date",
      label: t("preferences.dateLabel"),
      options: {
        customBodyRender: (value) =>
          convertDatebyLocale(value, dateFormatWithTime)
      }
    },
    {
      name: "authBY",
      label: t("preferences.authorizedByLabel")
    },
    {
      name: "oldValue",
      label: t("preferences.previousValueLabel")
    },
    {
      name: "newValue",
      label: t("preferences.newValueLabel")
    }
  ];

  useEffect(() => {
    if (!hasRun) {
      // eslint-disable-next-line
      hasRun = true;
      getDontCallHistoryData();
    }
    if (isAuthenticated) {
      setExpanded(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (prefenceFilteredTableData.length > 0) filterPreferenceData();
    // eslint-disable-next-line
  }, [prefenceFilteredTableData]);

  function filterPreferenceData() {
    let preference = prefenceFilteredTableData?.find(
      (preference) =>
        preference.preference_type_id === preferencesTypeIds.dontCallResolveDesc
    );
    if (Object.keys(preference)?.length) setPreference(preference);
  }

  const getTableData = async (list) => {
    try {
      const filtered =
        list[0] &&
        list[0]?.map((e) => {
          const filterByHistoryId =
            list[1] &&
            list[1].filter(
              (f) => f?.change_history_id === e?.change_history_id
            );

          e.preference_type_id = "";
          if (filterByHistoryId.length !== 0) {
            e.preference_type_id = filterByHistoryId[0].preference_type_id;
            const findNewValue = filterByHistoryId.filter(
              (r) => r.new_value === Y_CHECKER || r.new_value === N_CHECKER
            );
            e.new_value =
              findNewValue.length > 0 && findNewValue[0].new_value === Y_CHECKER
                ? YES +
                  filterByHistoryId
                    .filter(
                      (r) =>
                        r.new_value !== Y_CHECKER &&
                        r.new_value !== N_CHECKER &&
                        r.new_value !== ""
                    )
                    .map((r) => ` - ${r.new_value}`)
                    .join("")
                : findNewValue[0]?.new_value === N_CHECKER
                  ? NO
                  : "";

            const findOldValue = filterByHistoryId.filter(
              (r) => r.old_value === Y_CHECKER || r.old_value === N_CHECKER
            );

            e.old_value =
              findOldValue.length > 0 && findOldValue[0].old_value === Y_CHECKER
                ? YES +
                  filterByHistoryId
                    .filter(
                      (r) =>
                        r.old_value !== Y_CHECKER && r.old_value !== N_CHECKER
                    )
                    .map((r) => ` - ${r.old_value}`)
                    .join("")
                : findOldValue[0]?.old_value === N_CHECKER
                  ? NO
                  : "";
          }

          return e;
        });
      return filtered;
    } catch (e) {
      dispatch(setError(ERROR_TYPES.ISSUE));
      setLoading(false);
    }
  };

  const getDontCallHistoryData = async () => {
    dispatch(clearError());
    setLoading(true);
    try {
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: state?.customer_id,
        preference_type_id_list:
          preferencesTypeIds.dontCallResolveDesc.padStart(
            numberConstants.ten,
            " "
          )
      };

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.customerPreferences.getCustomerPreferenceHistory,
        2
      );

      // if there is an error showing the error and exit from the process
      if (dataSets[0]?.error && dataSets[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getMessageFromCode(dataSets[0]?.error);

        dispatch(setError(errMsg?.errorMsg || ERROR_TYPES.ISSUE));
        setLoading(false);
        return;
      }
      if (dataSets && dataSets?.data) {
        let emailData = dataSets?.data;
        const updatedData = await getTableData(emailData);
        const flattenedData = updatedData?.map((item, index) => {
          return {
            date: item.change_history_datetime,
            authBY: `${item.personnel_first_name} ,
            ${item.personnel_last_name}`,
            oldValue: item.old_value,
            newValue: item.new_value
          };
        });

        flattenedData?.sort((a, b) => b?.date?.localeCompare(a?.date));
        dispatch(setDontCallHistory(flattenedData));
      }
      setLoading(false);
    } catch (e) {
      dispatch(setError(ERROR_TYPES.ISSUE));
      setLoading(false);
    }
  };

  const goBack = () => {
    dispatch(setIsRefreshRequired(true));
    navigate(-1);
  };

  const handleChange = (panel) => {
    setExpanded((prev) => !prev);
  };
  const topTextFieldReportsPayload = [
    {
      key: t("preferences.customerPreferenceTypes18"),
      value: preference?.preference_value === Y_CHECKER ? YES : NO
    }
  ];

  const filteredPreferenceTypes = useMemo(() => {
    return getPreferenceTypesPayloadForReport().filter(
      (type) =>
        type.preference_type_id === preferencesTypeIds.dontCallResolveDesc
    );
  }, []);

  return (
    <>
      <PreferencesLayout>
        {isLoading && <FullCircularLoader />}

        <PreferencesReoprtDownloadModal
          topTextFieldReportsPayload={topTextFieldReportsPayload}
          url={
            CF_URLS.printReports.customerInformation
              .donotCallToResolveDiscrepanciesReport
          }
          title={t("common.downloadReport")}
          showPrintModal={isDownload}
          setCloseModal={setIsDownload}
          setIsLoading={setLoading}
          customerId={state?.customer_id}
          preferenceTypeId={preferencesTypeIds.dontCallResolveDesc.padStart(
            numberConstants.ten,
            " "
          )}
          preferenceTypesPayloadForReport={filteredPreferenceTypes}
        />

        <Stack
          direction="column"
          gap={spacing.verticalMargin20}
          mb={spacing.verticalMargin32}
          mt={spacing.verticalMargin16}
        >
          <Grid container spacing={spacing.horizontalMargin20}>
            <Grid item xs={12}>
              <Box mb={spacing.verticalMargin20}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("common.customer")}
                </Typography>

                <Typography variant="subtitle1">{customerName}</Typography>
              </Box>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" style={PreferencesStyles.titleStyle}>
                {t("preferences.dontCallTitle")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box mb={spacing.verticalMargin20}>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("preferences.settings")}
                </Typography>{" "}
                <Typography variant="subtitle1">
                  {preference?.preference_value === Y_CHECKER ? YES : NO}
                </Typography>
              </Box>
              <Divider sx={emailStyles.mbStyle20} />
            </Grid>
          </Grid>
          <Accordion
            defaultExpanded={expanded}
            onChange={handleChange}
            sx={PreferencesStyles.accordian}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12-content"
            >
              <Typography
                variant="subtitle1"
                style={PreferencesStyles.subTitleStyle}
              >
                {t("preferences.history")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer sx={PreferencesStyles.tableContainer}>
                <MUIDataTable
                  options={options}
                  columns={columns}
                  data={dontCallHistory}
                />
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Divider sx={emailStyles.dividerStyle} />
          <Stack mt={spacing.gap} gap={spacing.gap} direction="row">
            <Button
              id="back-button"
              variant="outlined"
              type="button"
              onClick={goBack}
              sx={PreferencesStyles.backButton}
            >
              <ChevronLeftIcon /> {t("common.back")}
            </Button>

            <Button
              disabled={null}
              id="print-button"
              variant="outlined"
              type="button"
              onClick={() => {
                setIsDownload(true);
              }}
            >
              {t("containerAuditDetails.print")}
            </Button>
          </Stack>
        </Stack>
      </PreferencesLayout>
    </>
  );
};
export default DontCallResolveDesc;
