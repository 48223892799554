import { Button, Grid, Stack, TextField } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { customerBillingInformationStyles } from "./CustomerBillingInformationStyles";

const CustomerBillingInformationModal = ({
  isModalOpen,
  handleModalClose,
  selectedRow,
  onDescriptionChange,
  onSaveClickOnModal
}) => {
  return (
    <>
      <CommonModal
        open={isModalOpen}
        title={t("customerBillingInformation.updateBillingInfo")}
        body={
          <>
            <Stack
              direction={customerBillingInformationStyles?.Direction?.column}
              gap={customerBillingInformationStyles?.Gap?.gap}
            >
              <Stack
                direction={customerBillingInformationStyles?.Direction?.row}
                gap={customerBillingInformationStyles?.Gap?.gap}
              >
                <Grid sx={customerBillingInformationStyles?.FieldSize}>
                  <TextField
                    id="classification"
                    name="classification"
                    label={t("customerBillingInformation.classification")}
                    size="medium"
                    variant="standard"
                    sx={customerBillingInformationStyles?.TextBoarderHide}
                    value={selectedRow?.classification || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid sx={customerBillingInformationStyles?.FieldSize}>
                  <TextField
                    id="catalog-item"
                    name="catalogItem"
                    label={t("customerBillingInformation.catalogItem")}
                    size="medium"
                    variant="standard"
                    sx={customerBillingInformationStyles?.TextBoarderHide}
                    value={selectedRow?.catalogItem || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>
              </Stack>

              <Stack
                direction={customerBillingInformationStyles?.Direction?.row}
                gap={customerBillingInformationStyles?.Gap?.gap}
              >
                <Grid sx={customerBillingInformationStyles?.FieldSize}>
                  <TextField
                    id="catalog"
                    name="catalog"
                    label={t("customerBillingInformation.catalog")}
                    size="medium"
                    variant="standard"
                    sx={customerBillingInformationStyles?.TextBoarderHide}
                    value={selectedRow?.catalog || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid sx={customerBillingInformationStyles?.FieldSize}>
                  <TextField
                    id="description"
                    name="description"
                    label={t("customerBillingInformation.description")}
                    size="medium"
                    variant="outlined"
                    value={selectedRow?.description || ""}
                    fullWidth
                    onChange={(e) => onDescriptionChange(e?.target?.value)}
                  />
                </Grid>
              </Stack>
            </Stack>
          </>
        }
        buttons={
          <>
            <Button onClick={handleModalClose} variant="outlined">
              {t("common.cancelEdit")}
            </Button>
            <Button
              onClick={onSaveClickOnModal}
              variant="contained"
              disabled={selectedRow?.description === ""}
            >
              {t("common.update")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default CustomerBillingInformationModal;
