import { Stack } from "@mui/material";
import OpenMediaResearch from "components/open-media-processing/research/OpenMediaResearch";
import { PageTitle } from "components/shared";
import i18n from "../../i18n";

const OpenMediaResearchPage = () => {
  return (
    <Stack>
      <PageTitle title={i18n.t("openMediaResearch.PageTitle")} />
      <OpenMediaResearch />
    </Stack>
  );
};

export default OpenMediaResearchPage;
