/* Yup validation schemas goes here */
import i18n from "i18n";
import * as Yup from "yup";

/* Open Media schema */
export const openMediaValidationSchema = Yup.object().shape({
  volser: Yup.string().required(
    i18n.t("mediaRequestDetail.mediaNumberIsRequired")
  )
});
