import { Stack } from "@mui/material";
import CorrectiveActionRequestSearchComponent from "components/corrective-action-request/CorrectiveActionRequestSearch";
import { PageTitle } from "components/shared";
import i18n from "i18n";

const CorrectiveActionRequestSearchPage = () => {
    return (
        <>
            <Stack sx={{ marginTop: 4 }}>
                <PageTitle title={i18n.t("correctiveActionRequestSearch.title")} />
                <CorrectiveActionRequestSearchComponent />
            </Stack>
        </>
    );
};
export default CorrectiveActionRequestSearchPage;
