/* Yup validation schemas goes here */
import dayjs from "dayjs";
import { maxCount } from "utils/constants";
import * as yup from "yup";
import i18n from "../../../../i18n";
/* pickup schema */
export const pickupSchema = yup.object().shape({
  customer: yup.object().required(i18n.t("pickup.customerFieldRequiredError")),
  bypassReason: yup.string().required(i18n.t("pickup.bypassReason")),
  personnel: yup
    .string()
    .required(i18n.t("pickup.personnelFieldRequiredError")),
  serviceLocation: yup
    .string()
    .required(i18n.t("pickup.serviceLocationFieldRequiredError")),
  scheduleServiceLocation: yup
    .string()
    .required(i18n.t("pickup.serviceLocationFieldRequiredError")),
  trackingNumber: yup
    .string()
    .required(i18n.t("pickup.trackingNumberFieldRequiredError"))
    .nullable()
    .max(50, i18n.t("pickup.TrackingLengthError")),
  notifyViaEmail: yup
    .string()
    .required(i18n.t("pickup.emailFieldRequiredError"))
    .test("valid-emails", i18n.t("pickup.emailValidError"), (value) => {
      const emailArray = value?.split(";").map((email) => email?.trim());
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailArray?.every((email) => emailRegex?.test(email));
    }),
  dispositionServices: yup
    .array()
    .of(yup.string())
    .min(1, i18n.t("pickup.dispositionService")) // Adjust the minimum number of selections
    .required(i18n.t("pickup.dispositionService"))
    .test(
      "atLeastOneSelected",
      i18n.t("pickup.dispositionService"),
      function (value) {
        return value && value.length > 0;
      }
    ),
  weight: yup
    .number()
    .typeError(i18n.t("pickup.valueMustBeNumber"))
    .test("non-negative-and-max", i18n.t("pickup.invalidValue"), (value) => {
      if (isNaN(value) || value < 0 || value >= 999999999) {
        return false; // Validation fails
      }
      return true; // Validation passes
    })
    .test(
      "test-unitofmeasurei-is-entered",
      i18n.t("pickup.weightError"),
      (value, context) => {
        const selectedUnitOfMeasure = context?.parent?.selectedUnitOfMeasure;
        if (value <= 0 && selectedUnitOfMeasure !== "0") {
          return false; // Validation fails
        }
        return true; // Validation passes
      }
    ),
  selectedUnitOfMeasure: yup
    .string()
    .test(
      "test-weight-is-entered",
      i18n.t("pickup.unitOfMeasureFieldRequiredError"),
      (value, context) => {
        const weight = context?.parent?.weight;
        if (value === "0" && weight > 0) {
          return false; // Validation fails
        }
        return true; // Validation passes
      }
    ),
  pickupInstructions: yup
    .string()
    .required(i18n.t("pickup.pickupInstructionsFieldRequiredError"))
    .max(256, i18n.t("pickup.schedulePickupInstructionsLengthError")),
  reviewComments: yup
    .string()
    .required(i18n.t("pickup.reviewcommentsFieldRequiredError"))
    .max(256, i18n.t("pickup.schedulePickupInstructionsLengthError")),
  schedulePickupInstructions: yup
    .string()
    .required(i18n.t("pickup.pickupInstructionsFieldRequiredError"))
    .max(256, i18n.t("pickup.schedulePickupInstructionsLengthError")),
  dispositionServiceComments: yup
    .string()
    .required(i18n.t("pickup.dispositionServiceCommentFieldRequiredError"))
    .max(256, i18n.t("pickup.scheduleDispositionServiceCommentLengthError")),

  preferredPickupDate: yup
    .date()
    .required(i18n.t("pickup.dateFieldRequiredError"))
    .typeError(i18n.t("pickup.invalidDate"))
    .test("customer-validation", i18n.t("pickup.invalidDate"), (value) => {
      return (
        !value ||
        ((dayjs(value).isSame(dayjs(), "day") ||
          dayjs(value).isAfter(dayjs())) &&
          (dayjs(value).isSame(dayjs("05-31-2079"), "day") ||
            dayjs(value).isBefore(dayjs("05-31-2079"))))
      );
    }),
  schedulePreferredPickupDate: yup
    .date()
    .required(i18n.t("pickup.dateFieldRequiredError"))
    .typeError(i18n.t("pickup.invalidDate"))
    .test("customer-validation", i18n.t("pickup.invalidDate"), (value) => {
      return (
        !value ||
        ((dayjs(value).isSame(dayjs(), "day") ||
          dayjs(value).isAfter(dayjs())) &&
          (dayjs(value).isSame(dayjs("05-31-2079"), "day") ||
            dayjs(value).isBefore(dayjs("05-31-2079"))))
      );
    }),

  boxTypesVal: yup.array().of(
    yup.object().shape({
      value: yup
        .number()
        .typeError(i18n.t("pickup.valueMustBeNumber"))
        .test(
          "non-negative-and-max-three-digits",
          i18n.t("pickup.invalidValue"),
          (value) => {
            if (isNaN(value) || value < 0 || value > maxCount) {
              return false; // Validation fails
            }
            return true; // Validation passes
          }
        )
        .nullable()
    })
  ),
  boxTypeValSchedule: yup.array().of(
    yup.object().shape({
      value: yup
        .number()
        .typeError(i18n.t("pickup.valueMustBeNumber"))
        .test(
          "non-negative-and-max-three-digits",
          i18n.t("pickup.invalidValue"),
          (value) => {
            if (isNaN(value) || value < 0 || value > maxCount) {
              return false; // Validation fails
            }
            return true; // Validation passes
          }
        )
        .nullable()
    })
  ),
  boxTypeValReviewApprove: yup.array().of(
    yup.object().shape({
      value: yup
        .number()
        .typeError(i18n.t("pickup.valueMustBeNumber"))
        .test(
          "non-negative-and-max-three-digits",
          i18n.t("pickup.invalidValue"),
          (value) => {
            if (isNaN(value) || value < 0 || value > maxCount) {
              return false; // Validation fails
            }
            return true; // Validation passes
          }
        )
        .nullable()
    })
  )
});
