import { t } from "i18next";

export const missing = "Missing";
export const missingValue = "4";
export const checkedValue = "-1";
export const systemfunctionid3 = "3";
export const systemfunctionid2 = "2";
export const all = "all";
export const staged = "Staged";
export const generatedPicklist = "Generated Picklist";
export const verifiedPicklist = "Verified Picking";
export const checkValue = "0";
export const location = "Location";
export const formFactor = "Form Factor";
export const notId = ":id";
export const general = "general";

export const customer = "Customer";

export const printFormats = [
  {
    name: t("containerProcessing.picking.locationprint")
  },
  {
    name: t("containerProcessing.picking.formFactor")
  },
  {
    name: t("containerProcessing.picking.customerId")
  }
];
