export const billingCycleCodeCheckEOM = "EOM";

/*Capacity Billing Configuration Search Constants*/
export const regionDistrict = "50";

export const issuedType = {
  one: 1,
  zero: 0
};

export const active = {
  active: "1",
  notActive: "0"
};

export const catalogItemCode = "VAULTFS000";
export const billingRunStatus = Object.freeze({
  notInitiated: 1,
  inProcess: 2,
  ready: 3,
  complete: 4,
  archived: 5
});

export const billingCycle = Object.freeze({
  eom: "EOM",
  oto: "OTO"
});

/*Enterprise Billing Summary Dates*/
export const dates = Object.freeze({
  minDate: "1996-01-01",
  maxDate: "2079-05-31"
});

export const billEndTime = "12:00:00 AM";

/*Enterprise Billing Summary print types*/
export const billingSummaryPrintTypes = Object.freeze({
  enterpriseBillingSummary: "printEnterpriseBillingSummary",
  billingVariance: "billingVariance",
  zeroCountReport: "zeroCountReport"
});

/*Billing Status report types*/
export const billingStatusReports = {
  billingStatus: "billingStatus",
  handlingCounts: "handlingCounts",
  vaultingCounts: "vaultingCounts",
  countSheet: "countSheet",
  transCountSheet: "transCountSheet",
  recurringChargeReview: "recurringChargeReview",
  openMediaHandlingReview: "openMediaHandlingReview",
  billingVariance: "billingVariance",
  zeroCount: "zeroCount"
};
