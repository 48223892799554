import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TableContainer,
  TextField
} from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getMessageFromCode } from "services/api/query";
import {
  clearError,
  setError
} from "store/slices/container-processing/containerAuditDetails";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  errorMsgs,
  numberConstants
} from "utils/constants";
import {
  addNewEmail,
  emailRegex,
  severityError
} from "utils/constants/container-processing/ContainerAuditConstants";

const EmailPublish = ({
  handleBack,
  emailRecipientsList,
  handleSend,
  setEmailRecipientsList,
  setSeverity,
  throwError
}) => {
  const [addEmail, setAddEmail] = useState("");

  const dispatch = useDispatch();
  const columns = [
    {
      name: "first_name",
      label: t("containerAuditDetails.name")
    },
    {
      name: "business_email_addr",
      label: t("containerAuditDetails.address")
    }
  ];
  const data = [...emailRecipientsList];
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    search: true,
    textLabels: data?.length && {
      body: {
        noMatch:
          (emailRecipientsList.length === Number(numberConstants.zero) &&
            t("containerAuditDetails.address")) ||
          t("containerAuditDetails.name")
      }
    }
  };

  const handleSelectedList = (data) => {
    let result = emailRecipientsList.map((item, index) => {
      return {
        ...item,
        isCheck:
          data?.personnel_id === item?.personnel_id
            ? !item?.isCheck
            : item?.isCheck
      };
    });
    setEmailRecipientsList([...result]);
  };

  const handleAddEmail = async () => {
    if (addEmail.trim() && emailRegex.test(addEmail.trim())) {
      let indexValue = emailRecipientsList.findIndex(
        (item) => item?.business_email_addr.trim() === addEmail.trim()
      );
      if (indexValue >= 0) {
        const temObj = {
          ...emailRecipientsList[indexValue],
          isCheck: true
        };
        emailRecipientsList.slice(indexValue, 1, temObj);
      } else {
        emailRecipientsList.unshift({
          isCheck: true,
          personnel_id: emailRecipientsList.length + addNewEmail,
          business_email_addr: addEmail.trim(),
          first_name: t("openMediaAuditDetail.addedRecipient")
        });
      }
      setEmailRecipientsList([...emailRecipientsList]);
      setAddEmail("");
    } else {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80176);
      dispatch(setError(errorMsg[0]?.descr));
      setSeverity(severityError);
    }
  };
  return (
    <>
      <Paper>
        <TableContainer>
          <MUIDataTable
            data={data}
            columns={[
              {
                name: "row_id",
                label: " ",
                options: {
                  filter: false,
                  sort: false,
                  download: false,
                  viewColumns: true,
                  customBodyRender: (value, tableMeta) => {
                    const { tableData, rowIndex } = tableMeta;
                    let data = tableData[rowIndex];
                    return (
                      <>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  handleSelectedList(data);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                                disabled={!data?.business_email_addr}
                                checked={data?.isCheck}
                              />
                            }
                          />
                        </FormGroup>
                      </>
                    );
                  },
                  customHeadLabelRender: (columnMeta) => (
                    <div>{columnMeta.label}</div>
                  )
                }
              },
              ...columns
            ]}
            options={options}
          />
        </TableContainer>
      </Paper>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item md={6} lg={3} xs={12}>
          <FormControl fullWidth variant="outlined">
            <>
              <TextField
                type="text"
                label={t("containerAuditDetails.addEmail")}
                value={addEmail}
                onChange={(e) => {
                  setAddEmail(e.target.value);
                  dispatch(clearError(false));
                }}
                error={throwError}
                inputMode="email"
              />
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          <Button
            variant="outlined"
            onClick={() => {
              handleAddEmail();
            }}
          >
            {t("containerAuditDetails.add")}
          </Button>
        </Grid>
      </Grid>
      <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
        <Divider />
      </Grid>
      <Grid
        item
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
        gap={spacing.verticalMargin20}
      >
        <Button
          variant="outlined"
          onClick={() => {
            handleBack();
          }}
        >
          {t("containerAuditDetails.cancel")}{" "}
        </Button>
        <Button variant="contained" onClick={() => handleSend()}>
          {t("containerAuditDetails.send")}{" "}
        </Button>
      </Grid>
    </>
  );
};

export default EmailPublish;
