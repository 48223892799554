// Constants that are used in vault itemized

export const MDRVaultItemizedStatusIds = {
  edrsNew: 0,
  edrsPendingConfirmation: 1,
  edrsRejected: 2,
  edrsAuthorized: 3,
  edrsScheduled: 4,
  edrsCancelled: 5,
  edrsInProcess: 6,
  edrsCompleted: 7,
  edrsPendingReview: 8
};

export const defaultDestructRequestId = 0;
export const STATUS_CHAR_LIMIT = 40;

export const destructRequestTypeIdForVaultItemized = 1;

export const Y_YES = "Y";
export const RESPONSE_LIMIT = 30;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const EMAIL_LIMIT = 1055;

export const COLUMN_IDS_OPEN_MEDIA = {
  i: "i",
  media: "media",
  status: "status",
  followUp: "followUp"
};

export const COLUMNS_POSSIBLE_MATCHES = {
  checkbox: "checkbox",
  media: "media",
  type: "type",
  status: "status"
};

export const DEFAULT_TABLE_SORT = {
  column: "status",
  direction: "desc"
};

export const DEFAULT_TABLE_SORT_POSSIBLE_MATCHES = {
  column: "media",
  direction: "desc"
};

export const INVALID_LABEL = "Invalid";
export const MEDIA_NUMBER_LIMIT = 20;
export const MEDIA_LIMIT = 30000;
export const TABLE_ROWS_PER_PAGE = [25, 50, 100];
export const TABLE_ROWS_PER_PAGE_POSSIBLE_MATCHES = [25, 50, 100];
export const TABLE_ROWS_PER_PAGE_DEFAULT = 25;
export const TABLE_ROWS_PER_PAGE_POSSIBLE_MATCHES_DEFAULT = 25;

export const COMMENT_LIMIT = 255;
export const ONE_SECOND_IN_MILLISECONDS = 1000;

export const NOT_ELIGIBLE_LABEL = "Not Eligible";
export const TXT = ".txt";
export const CSV = ".csv";

export const POSSIBLE_MATCH_COUNT = 50;
