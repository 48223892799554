import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loadingMiddleware from "middleware/loadingMiddleware";
import { baseApi } from "services/api/baseApi";
import loadingSlice from "./slices/Core/loadingSlice";
import routeSlice from "./slices/Core/routeSlice";
import notificationConfigSlice from "./slices/administration/notificationConfig";
import authReducer from "./slices/authSlice";
import personnelReducer from "./slices/authorization/personnelSlice";
import containerAuditSearchSlice from "./slices/container-processing";
import containerAuditDetailsSlice from "./slices/container-processing/containerAuditDetails.js";
import containerProcessingResearchSlice from "./slices/container-processing/researchSlice";
import customerPreferencesSlice from "./slices/customer-preferences";
import customerScheduleSlice from "./slices/customer-schedule";
import dashboardSlice from "./slices/dashboard";
import eventSearchSlice from "./slices/disater-module/eventSearch";
import discrepancySearchSlice from "./slices/discrepancy-search";
import discrepancyUpdateSlice from "./slices/discrepancy-update";
import openMediaAuditDetailsSlice from "./slices/open-media-audit-detail";
import openMediaAuditSearchSlice from "./slices/open-media-audit-search";
import openMediResearchSlice from "./slices/open-media-research/openMediaResearchSlice";
import vaultStatusReportSlice from "./slices/report-module/authForm";
import destructionDetailDeliverySlice from "./slices/request-module/destructionDetailDeliverySlice";
import mediaRequestDetailSlice from "./slices/request-module/mediaRequestDetailSlice";
/**
 * @name appReducer
 * @description Combine all the reducers from slices, RTK Query API
 **/
const appReducer = combineReducers({
  auth: authReducer,
  requestDetailDelivery: destructionDetailDeliverySlice,
  mediaRequestDetail: mediaRequestDetailSlice,
  loading: loadingSlice,
  route: routeSlice,
  personnel: personnelReducer,
  openMediaAuditSearch: openMediaAuditSearchSlice,
  containerAuditSearch: containerAuditSearchSlice,
  containerAuditDetails: containerAuditDetailsSlice,
  openMediaAuditDetails: openMediaAuditDetailsSlice,
  openMediResearch: openMediResearchSlice,
  containerProcessingResearch: containerProcessingResearchSlice,
  customerPreferences: customerPreferencesSlice,
  discrepancySearch: discrepancySearchSlice,
  customerSchedule: customerScheduleSlice,
  discrepancyUpdate: discrepancyUpdateSlice,
  notificationConfig: notificationConfigSlice,
  dashboardRequest: dashboardSlice,
  vaultStatusReport: vaultStatusReportSlice,
  eventSearch: eventSearchSlice,
  [baseApi.reducerPath]: baseApi.reducer
});

const rootReducer = (state, action) => {
  if (action.type === "Reset") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([baseApi.middleware, loadingMiddleware])
});

export default store;
