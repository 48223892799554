import {
  Button,
  Card,
  CardContent,
  Divider,
  Modal,
  Stack,
  Typography
} from "@mui/material";
import IRMLogo from "assets/images/IRM_Logo.svg";
import IRMLogoWithText from "assets/images/LogoFullColor.svg";
import { COMMON_CARD_STYLES } from "components/shared/common-modal/CommonModalStyles";
import { t } from "i18next";
const AboutSecureBase = ({ openAbout, setOpenAbout }) => {
  return (
    <>
      <>
        <Modal open={openAbout} disableRestoreFocus={true}>
          <Card
            id="aboutsecurebase-modal-card"
            sx={{
              ...COMMON_CARD_STYLES.card
            }}
          >
            {/* ---------- Title Section ----------  */}
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={COMMON_CARD_STYLES.cardTitle}
            >
              <img alt="IRM Logo" src={IRMLogo} id="aboutsecurebase-logo" />
              <Typography variant="h6" gutterBottom>
                {t("menu.aboutSB")}
              </Typography>
            </Stack>

            <Divider />

            {/* ---------- Body Section ----------   */}
            <CardContent>
              <Stack direction="column" spacing={2}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    alt="IRM Logo with text"
                    src={IRMLogoWithText}
                    id="aboutsecurebaset-logo-with-text"
                  />
                </Stack>
                <Stack direction="column" spacing={1} alignItems="flex-start">
                  <Typography>{t("secureBaseAbout.appName")}</Typography>
                  <Typography>{t("secureBaseAbout.version")}</Typography>
                  <Typography>{t("secureBaseAbout.copyright")}</Typography>
                </Stack>
                <Stack direction="column" spacing={1} alignItems="flex-start">
                  <Typography>{t("secureBaseAbout.warning")}</Typography>
                </Stack>
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <Button
                    variant="outlined"
                    onClick={() => setOpenAbout((pre) => false)}
                  >
                    {t("common.close")}
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
            <Divider />
          </Card>
        </Modal>
      </>
    </>
  );
};

export default AboutSecureBase;
