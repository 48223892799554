import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import i18n from "i18n";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_LOCALE,
  createDistributionListTableConstants,
  dateFormat,
  errorMsgs,
  maximumDate
} from "utils/constants";
import { getResponseData } from "utils/helpers";
import { createDistributionModalFormSchema } from "../Schema";
import { createDisributionListStyles } from "./CreateDisributionListStyles";
import { getRandomNumber } from "./CreateDistributionListTable";

const CreateDistributionListModal = ({
  open,
  closeModal,
  isLogicalVaultSelected,
  setTableData,
  isSlotAvailable,
  mediaValidation,
  isEdit,
  tableData,
  setIsEdit,
  editRowData,
  setEditRowData,
  vaultId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [throwMediaErrorMessage, setThrowMediaErrorMessage] = useState(null);
  const [throwSlotErrorMessage, setThrowSlotErrorMessage] = useState(null);
  const [throwDrpErrorMessage, setThrowDrpErrorMessage] = useState(null);
  const [throwDescriptionErrorMessage, setThrowDescriptionErrorMessage] =
    useState(null);
  const [throwError, setThrowError] = useState(false);
  const [, setThrowModalError] = useState(null);
  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);

  const { currentBranch } = useSelector(selectAuth);

  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [dialogMessage, setDialogMessage] = useState("");

  // check whether media number is existing
  const handleAddMediaNumberAndSlot = () => {
    let isValidObj = {
      mediaMessage: "",
      isValidMedia: true,
      slotMessage: "",
      isValidSlot: true
    };
    if (formValues.mediaNumber !== "") {
      const isMediaNumberInTable = tableData?.some(
        (item, idx) =>
          String(item.mediaNumber).toUpperCase() ===
          String(formValues.mediaNumber).toUpperCase()
      );
      if (isMediaNumberInTable) {
        isValidObj.mediaMessage = i18n.t(
          "createDistributionList.mediaNumberDuplicacyError"
        );
        isValidObj.isValidMedia = false;
      }
    }

    if (formValues.slot !== "") {
      const isSlotInTable = tableData?.some(
        (item, idx) =>
          String(item.slot).toUpperCase() ===
          String(formValues.slot).toUpperCase()
      );
      if (isSlotInTable) {
        isValidObj.slotMessage = i18n.t(
          "createDistributionList.slotDuplicacyError"
        );
        isValidObj.isValidSlot = false;
      }
    }
    return isValidObj;
  };

  // Modal Form values handle
  const [formValues, setFormValues] = useState({
    mediaNumber: "",
    returnDate: null,
    slot: "",
    drpCode: "",
    description: ""
  });

  useEffect(() => {
    setFormValues({
      mediaNumber: editRowData?.mediaNumber,
      returnDate: editRowData.returnDate
        ? dayjs(editRowData?.returnDate).format(dateFormat.universalFormat)
        : null,
      slot: editRowData?.slot,
      drpCode: editRowData?.drpCode,
      description: editRowData?.description
    });
    // eslint-disable-next-line
  }, [isEdit && editRowData.length && editRowData]);

  const [localeByBranch] = useState(DEFAULT_LOCALE);

  const { t } = useTranslation();

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e?.target?.name]: e?.target?.value
    });
  };

  // checking is media number exists in tableData
  const compareMediaNumbers = useCallback((tableData, newData) => {
    return tableData.some(
      (t) =>
        String(t.mediaNumber).toUpperCase() === String(newData).toUpperCase()
    );
  }, []);

  // check slot number exists
  const compareSlots = useCallback((tableData, newData) => {
    if (
      newData?.trim() === "" ||
      newData === null ||
      newData?.trim() === undefined
    )
      return false;
    return tableData.some(
      (t) =>
        t.slot?.trim() !== "" &&
        String(t.slot).toUpperCase() === String(newData).toUpperCase()
    );
  }, []);

  const validateFields = (values) => {
    let errors = {
      mediaNumber: false,
      slot: false,
      drpCode: false,
      description: false
    };
    let isMediaAndSlotExists = handleAddMediaNumberAndSlot();
    if (!isMediaAndSlotExists.isValidMedia) {
      setThrowMediaErrorMessage(isMediaAndSlotExists.mediaMessage);
      errors.mediaNumber = true;
    }
    if (!isMediaAndSlotExists.isValidSlot) {
      setThrowSlotErrorMessage(isMediaAndSlotExists.slotMessage);
      errors.slot = true;
    }
    if (!values.mediaNumber) {
      setThrowMediaErrorMessage(
        i18n.t("createDistributionList.mediaNumberRequiredError")
      );
      errors.mediaNumber = true;
    } else if (values.mediaNumber?.length > 20) {
      setThrowMediaErrorMessage(mediaValidation.message);
      errors.mediaNumber = true;
    } else {
      setThrowMediaErrorMessage("");
      errors.mediaNumber = false;
    }
    if (
      values?.slot?.length &&
      values.slot?.length > 6 &&
      isLogicalVaultSelected
    ) {
      setThrowSlotErrorMessage(
        i18n.t("createDistributionList.slotValidationError")
      );
      errors.slot = true;
    } else {
      setThrowSlotErrorMessage("");
      errors.slot = false;
    }

    if (values?.drpCode?.length > 10) {
      setThrowDrpErrorMessage(
        i18n.t("createDistributionList.drpCodeMaxLengthError")
      );
      errors.drpCode = true;
    } else {
      setThrowDrpErrorMessage("");
      errors.drpCode = false;
    }

    if (values.description?.length > 80) {
      setThrowDescriptionErrorMessage(
        i18n.t("createDistributionList.descriptionMaxLengthError")
      );
      errors.description = true;
    } else {
      setThrowDescriptionErrorMessage("");
      errors.description = false;
    }

    return errors;
  };

  const handleBlur = async (e) => {
    const { name, value } = e.target;

    // temporary object to hold the formValues
    let updatedFormValues = { ...formValues };

    if (
      name === createDistributionListTableConstants.slot &&
      value &&
      value?.length > 0
    ) {
      if (value?.length < 6) {
        let newSlot = ("000000" + value).slice(-6);
        updatedFormValues = {
          ...updatedFormValues,
          slot: newSlot
        };
        setFormValues(updatedFormValues);
      } else {
        updatedFormValues = {
          ...updatedFormValues,
          slot: value
        };
        setFormValues(updatedFormValues);
      }
      let reqBody = JSON.stringify({
        logical_vault_id: isLogicalVaultSelected ? vaultId : "",
        slot_number: updatedFormValues.slot,
        main_district_id: currentBranch?.id
      });
      setIsLoading(true);
      try {
        let slotResp = await getResponseData(
          reqBody,
          `${CF_URLS.openMediaProcessing.slotValidation}`,
          1
        );
        let errorCode = slotResp.data[0][0]?.error;
        if (errorCode) {
          if (errorCode === errorMsgs.errorCode25197) {
            const messageResponse = await getMessageFromCode(errorCode);
            setThrowError(false);
            setOpenDialogBox(true);
            setDialogMessage(
              messageResponse[0]?.descr.replace("|", updatedFormValues.slot)
            );
          } else if (errorCode === errorMsgs.errorCode25097) {
            await getMessageFromCode(errorMsgs.errorCode25097)
              .then((res) => {
                setThrowError(true);
                setOpenDialogBox(false);
                setThrowModalError(res[0]?.descr);
              })
              .catch((e) => {
                setThrowError(true);
                setOpenDialogBox(false);
                setThrowModalError(e);
              });
          } else {
            setThrowError(true);
            setThrowMediaErrorMessage(t(`common.systemErrorMessage`));
          }
        }
      } catch (e) {
        setThrowError(false);
        setOpenDialogBox(false);
        setDialogMessage(null);
        setThrowModalError(e);
      }
      setIsLoading(false);

      const { mediaNumber, slot, drpCode, description } =
        validateFields(formValues);
      if (mediaNumber || description || slot || drpCode || description) {
        setThrowError(true);
      } else {
        setThrowError(false);
      }
    }
  };

  // add button event
  const handleSaveClickModal = (e) => {
    e.preventDefault();
    setOpenDialogBox(false);
    try {
      setIsLoading(true);
      const { mediaNumber, slot, drpCode, description } =
        validateFields(formValues);
      if (mediaNumber || slot || drpCode || description) {
        setThrowError(true);
        setIsLoading(false);
        return;
      } else {
        setIsLoading(false);
        setThrowError(false);
        let newData = [];
        newData.push(formValues);
        if (compareMediaNumbers(tableData, formValues.mediaNumber)) {
          setThrowError(true);
          setThrowMediaErrorMessage(
            i18n.t("createDistributionList.mediaNumberDuplicacyError")
          );
          return;
        } else if (compareSlots(tableData, formValues?.slot)) {
          setThrowError(true);
          setThrowSlotErrorMessage(
            i18n.t("createDistributionList.slotDuplicacyError")
          );
          return;
        }
        setThrowError(false);
        setTableData((prev) => [
          ...prev,
          { ...formValues, index: getRandomNumber() }
        ]);
        closeModal();
        setFormValues({
          mediaNumber: "",
          returnDate: null,
          slot: "",
          drpCode: "",
          description: ""
        });
      }
    } catch (err) {
      setThrowError(true);
      setThrowModalError(err);
    }
  };

  const isOpenDialogue = () =>
    openDialogBox && Boolean(formValues.slot?.length);

  // handle update modal event
  const handleUpdateClickModal = (e) => {
    e.preventDefault();
    setOpenDialogBox(false);
    try {
      setIsLoading(true);
      const { mediaNumber, slot, drpCode, description } =
        validateFields(formValues);
      if (mediaNumber || description || slot || drpCode || description) {
        setThrowError(true);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setThrowError(false);
      let newData = [];
      newData.push(formValues);

      // check for media number duplicacy
      if (
        compareMediaNumbers(
          tableData.filter((data) => data.index !== editRowData.index),
          formValues.mediaNumber
        )
      ) {
        setThrowError(true);
        setThrowMediaErrorMessage(
          i18n.t("createDistributionList.mediaNumberDuplicacyError")
        );
        return;
      }

      // check for slot duplicacy if vault is selected
      else if (
        compareSlots(
          tableData.filter((data, index) => data.index !== editRowData.index),
          formValues.slot
        ) &&
        isLogicalVaultSelected
      ) {
        setThrowError(true);
        setThrowSlotErrorMessage(
          i18n.t("createDistributionList.slotDuplicacyError")
        );
        return;
      }
      setThrowError(false);

      setTableData((prev) => {
        let updatedRowData = [...prev].map((data) =>
          data.index === editRowData.index
            ? { ...formValues, index: editRowData.index }
            : data
        );
        return updatedRowData;
      });
      closeModal();
      setIsEdit(false);
      setEditRowData({});
      setFormValues({
        mediaNumber: "",
        returnDate: null,
        slot: "",
        drpCode: "",
        description: ""
      });
    } catch (err) {
      setThrowError(true);
      setThrowModalError(err);
    }
  };

  // handling modal data change event
  const handleDateChange = (e) => {
    let formatDate = e ? dayjs(e).format(dateFormat.universalFormat) : null;
    setFormValues({
      ...formValues,
      returnDate: formatDate
    });
  };

  // slot inputprops
  const slotTFInputProps = {
    maxLength: 6,
    inputMode: "numeric",
    pattern: "[0-6]*"
  };

  // media Number inputprops
  const mediaNumberTFInputProps = {
    maxLength: 20,
    inputMode: "alphanumeric",
    pattern: "/^[A-Z0-9]*$/"
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      {isShowSuccessPopup && !isLoading && (
        <SuccessPopup
          message={t("common.updateMessage")}
          close={setIsShowSuccessPopup}
        />
      )}

      <CommonModal
        open={open || isEdit}
        onClose={closeModal}
        title={
          isEdit
            ? t("createDistributionList.updateCreateDistributionList")
            : t("createDistributionList.newCreateDistributionList")
        }
        body={
          <Formik
            initialValues={formValues}
            validationSchema={createDistributionModalFormSchema}
            validateOnBlur={true}
          >
            {({ errors, touched }) => (
              <Form>
                <Box
                  gap={spacing.gap}
                  sx={
                    createDisributionListStyles.createDistributionModal
                      .cardContentStack
                  }
                >
                  {openDialogBox && isOpenDialogue() && (
                    <Collapse in={openDialogBox && isOpenDialogue()}>
                      <Alert
                        severity="warning"
                        icon={<img src={WarningIcon} alt="warning" />}
                        sx={createDisributionListStyles.errorStyle}
                        action={
                          <IconButton
                            aria-label={t("common.close")}
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenDialogBox(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {dialogMessage}
                      </Alert>
                    </Collapse>
                  )}

                  {throwError && (
                    <Collapse in={throwError}>
                      <Alert
                        severity="error"
                        icon={<img src={ErrorIcon} alt="error" />}
                        sx={createDisributionListStyles.errorStyle}
                        action={
                          <IconButton
                            aria-label={t("common.close")}
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setThrowError(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        &nbsp;&nbsp;&nbsp;
                        {throwMediaErrorMessage?.length
                          ? throwMediaErrorMessage
                          : null}
                        &nbsp;
                        {throwSlotErrorMessage?.length
                          ? throwSlotErrorMessage
                          : null}
                        &nbsp;
                        {throwDrpErrorMessage?.length
                          ? throwDrpErrorMessage
                          : null}
                        &nbsp;
                        {throwDescriptionErrorMessage?.length
                          ? throwDescriptionErrorMessage
                          : null}
                        &nbsp;&nbsp;&nbsp;
                      </Alert>
                    </Collapse>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("createDistributionList.mediaNumber")}
                        id="mediaNumber-modal"
                        fullWidth
                        name={"mediaNumber"}
                        value={formValues.mediaNumber}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            [e.target.name]: ("" + e.target.value)
                              .trim()
                              .toUpperCase()
                          })
                        }
                        variant="outlined"
                        error={touched.mediaNumber && !!errors.mediaNumber}
                        helperText={touched.mediaNumber && errors.mediaNumber}
                        onBlur={(e) => handleBlur(e)}
                        sx={createDisributionListStyles.widthFixed}
                        InputLabelProps={
                          createDisributionListStyles.createDistributionModal
                            .tfInputLabelPtops
                        }
                        inputProps={mediaNumberTFInputProps}
                        style={createDisributionListStyles.textStyle}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <DatePickerField
                        label={t("createDistributionList.returnDate")}
                        id="returnDate-modal"
                        name="returnDate"
                        minDate={dayjs(new Date())}
                        maxDate={dayjs(maximumDate)}
                        locale={localeByBranch}
                        value={
                          formValues.returnDate !== null
                            ? dayjs(formValues.returnDate)
                            : null
                        }
                        onChange={(date) => handleDateChange(date)}
                        fullWidth
                        variant="outlined"
                        sx={createDisributionListStyles.widthFixed}
                        InputLabelProps={
                          createDisributionListStyles.createDistributionModal
                            .tfInputLabelPtops
                        }
                        style={createDisributionListStyles.textStyle}
                      />
                    </Grid>
                    {isSlotAvailable && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="number"
                          label={t("createDistributionList.slot")}
                          id="slot-modal"
                          fullWidth
                          name={"slot"}
                          value={formValues.slot}
                          onChange={(e) => handleChange(e)}
                          variant="outlined"
                          onBlur={(e) => handleBlur(e)}
                          inputProps={slotTFInputProps}
                          sx={createDisributionListStyles.widthFixed}
                          error={!!errors.slot}
                          helperText={errors.slot}
                          InputLabelProps={
                            createDisributionListStyles.createDistributionModal
                              .tfInputLabelPtops
                          }
                          style={createDisributionListStyles.textStyle}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("createDistributionList.drpCode")}
                        id="drpCode-modal"
                        fullWidth
                        name={"drpCode"}
                        value={formValues.drpCode}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            [e.target.name]: ("" + e.target.value)
                              .trim()
                              .toUpperCase()
                          })
                        }
                        variant="outlined"
                        onBlur={(e) => handleBlur(e)}
                        sx={createDisributionListStyles.widthFixed}
                        error={!!errors.drpCode}
                        helperText={errors.drpCode}
                        InputLabelProps={
                          createDisributionListStyles.createDistributionModal
                            .tfInputLabelPtops
                        }
                        style={createDisributionListStyles.textStyle}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        label={t("common.description")}
                        id="description-modal"
                        fullWidth
                        multiline
                        name={"description"}
                        value={formValues.description}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        variant="outlined"
                        error={!!errors.description}
                        helperText={errors.description}
                        sx={{
                          ...createDisributionListStyles.modal.description
                        }}
                        rows={2}
                        InputLabelProps={
                          createDisributionListStyles.createDistributionModal
                            .tfInputLabelPtops
                        }
                        style={createDisributionListStyles.textStyle}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        }
        buttons={
          <>
            <Button
              id="CancelBtn-modal"
              variant="outlined"
              onClick={() => {
                closeModal();
                setIsEdit(false);
                setEditRowData({});
                setFormValues({
                  mediaNumber: "",
                  returnDate: null,
                  slot: "",
                  drpCode: "",
                  description: ""
                });
                setThrowError(false);
              }}
              sx={createDisributionListStyles.createDistributionModal.buttonSX}
            >
              {t("common.cancel")}
            </Button>

            {isEdit === true ? (
              <Button
                id="updateBtn-modal"
                variant="contained"
                onClick={(e) => handleUpdateClickModal(e)}
                sx={
                  createDisributionListStyles.createDistributionModal.buttonSX
                }
              >
                {t("createDistributionList.update")}
              </Button>
            ) : (
              <Button
                id="saveBtn-modal"
                variant="contained"
                onClick={(e) => handleSaveClickModal(e)}
                sx={
                  createDisributionListStyles.createDistributionModal.buttonSX
                }
              >
                {t("createDistributionList.add")}
              </Button>
            )}
          </>
        }
      />
    </>
  );
};

export default CreateDistributionListModal;
