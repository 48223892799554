import { Stack } from "@mui/material";
import { SearchFilter } from "components/features/request-module";

function MediaRequestSearch() {
  return (
    <Stack spacing={2}>
      <SearchFilter />
    </Stack>
  );
}

export default MediaRequestSearch;
