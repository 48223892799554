import spacing from "styles/spacing";

export const personalSearchStyles = {
  tablePaper: {
    width: "100%",
    overflow: "hidden"
  },
  titleStyle: {
    color: "var(--color-im-grey-900)"
  },
  displayFlex: {
    display: "flex"
  },
  textColor: {
    color: "var(--color-im-green)"
  },
  gapSpacing: "20px",
  directionRow: "row",
  directionColumn: "column",
  noBorder: {
    border: "none"
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    rowGap: spacing?.verticalMargin20
  }
};
