import { Stack } from "@mui/material";
import PayInvoice from "components/customer-information/preferences/pay-invoice/PayInvoice";
import { PageTitle } from "components/shared";
import { t } from "i18next";

const PayInvoiceScreen = () => {
  return (
    <>
      <Stack>
        <PageTitle title={`${t("preferences.detailsTitle")}`} />
        <PayInvoice />
      </Stack>
    </>
  );
};

export default PayInvoiceScreen;
