import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Modal,
  Typography
} from "@mui/material";
import { COMMON_CARD_STYLES } from "./CommonModalStyles";

const CommonModal = ({ open, title, body, buttons, minWidth }) => {
  return (
    <>
      <Modal open={open} disableRestoreFocus={true}>
        <Card
          id="common-modal-card"
          sx={{
            ...COMMON_CARD_STYLES.card,
            minWidth: minWidth ? minWidth : COMMON_CARD_STYLES.card.minWidth
          }}
        >
          {/* ---------- Title Section ----------  */}
          <Typography
            variant="h6"
            sx={COMMON_CARD_STYLES.cardTitle}
            gutterBottom
          >
            {title}
          </Typography>
          <Divider />

          {/* ---------- Body Section ----------   */}
          <CardContent>{body}</CardContent>

          <Divider />

          {/* ---------- Footer Section ----------   */}
          <CardActions sx={COMMON_CARD_STYLES.cardActions}>
            {buttons}
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default CommonModal;
